import { Button } from 'components/common';
import { Flex, Grid, GridItem, Heading } from '@chakra-ui/react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { IPoint, ITask } from '../../types';
import { useRequest } from 'hooks';
import { generateUUID } from 'lib/utils/generateUUID';
import { IMapObject } from 'features/PelsMap/types';

interface IProps {
  noise: boolean | undefined;
  state: ITask | undefined;
  regionsFinder: Map<string, string>;
}

export const TableModal = ({ noise, state, regionsFinder }: IProps) => {
  const [browserId] = useState(generateUUID());

  const printPage = useCallback((event) => {
    const prtContent: HTMLElement = document?.getElementById('PRINT')?.cloneNode(true) as HTMLElement;
    const image = new Image();
    image.src = event.detail.uri;
    prtContent.appendChild(image);
    const WinPrint: any = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');
    WinPrint.document.write(prtContent.innerHTML);
    setTimeout(() => {
      WinPrint.focus();
      WinPrint.print();
      WinPrint.close();
    }, 1000);
  }, []);

  const territory = `${state?.regions
    ?.map((region: string) => regionsFinder.get(region))
    .filter(Boolean)
    .join('/')}${state?.territoryName ? ` (${state?.territoryName})` : ''}`;

  const objForMap = useMemo(() => {
    const points: IMapObject = {
      features: [],
      uid: state?.id || Math.random().toString(),
      layerTemplateId: '1121430612',
    };
    state?.points.forEach((point: any) => {
      if (!point.dotLatitude && !point.dotLongitude && !point.evalObjCoordinateArray) {
        return;
      }
      // @ts-ignore
      points.features.push({
        geometry: {
          // @ts-ignore
          coordinates: point.evalObjCoordinateArray
            ? JSON.parse(point.evalObjCoordinateArray)
            : [point.dotLongitude, point.dotLatitude],
          // @ts-ignore
          type: point.evalObjCoordinateArray ? 'Polygon' : 'Point',
        },
        // @ts-ignore
        properties: { id: point.id || point.temporaryId, name: point.pointNumber },
        // @ts-ignore
        type: 'Feature',
      });
    });
    return points;
  }, [state]);

  const { post } = useRequest('/mapsclient/v10/sendCommand');

  const sendCommand = useCallback(() => {
    if (!state) { return; }
    post({
      browserId,
      command: 'query',
      data: objForMap,
    });
  }, [browserId, objForMap, post, state]);

  const getImage = useCallback(() => {
    post({
      browserId,
      command: 'screenshot',
      data: objForMap,
    });
  }, [browserId, objForMap, post]);

  useEffect(() => {
    window.document.addEventListener('socket-server-connected', sendCommand, false);
    window.document.addEventListener('map-export-image', printPage, false);
    return () => {
      window.document.removeEventListener('socket-server-connected', sendCommand, false);
      window.document.removeEventListener('map-export-image', printPage, false);
    };
  }, [printPage, sendCommand]);

  return (
    <>
      <Grid gap={10}>
        <Heading>{`Задание на проведение исследования ${noise ? 'шумовой' : 'атмосферной'} обстановки`}</Heading>
        {state ? (
          <>
            <Grid gap={3} marginTop={10} id="PRINT">
              <div style={{ marginBottom: '10px' }}>
                <b>Дата:</b>
                <br />
                <p style={{ marginLeft: 10 }}>{state.startDate}</p>
              </div>
              <div style={{ marginBottom: '10px' }}>
                <b>Территория:</b>
                <br />
                <p style={{ marginLeft: 10 }}>{territory}</p>
              </div>
              <div style={{ marginBottom: '10px' }}>
                <b>Смена:</b>
                <br />
                <p style={{ marginLeft: 10 }}>{state.shift}</p>
              </div>
              <div style={{ marginBottom: '25px' }}>
                <b>Приоритет:</b>
                <br />
                <p style={{ marginLeft: 10 }}>{state.priority}</p>
              </div>
              {state.points.map((point: IPoint, index: number) => (
                <React.Fragment key={index}>
                  <div style={{ marginBottom: '15px' }}>
                    <b>
                      <p>{`${point.evalObjCoordinateArray !== '' ? 'Область обследования' : 'Точка наблюдения'}`}</p>
                    </b>
                  </div>
                  <div style={{ marginLeft: '25px' }}>
                    <div style={{ marginBottom: '10px' }}>
                      <b>{point.evalObjCoordinateArray !== '' ? 'Номер области' : 'Номер точки'}</b>
                      <br />
                      <p style={{ marginLeft: 10 }}>{point.pointNumber}</p>
                    </div>
                    <div style={{ marginBottom: '10px' }}>
                      <b>{point.evalObjCoordinateArray !== '' ? 'Адрес области' : 'Адрес точки'}</b>
                      <br />
                      <p style={{ marginLeft: 10 }}>{point.address}</p>
                    </div>
                    {point.evalObjCoordinateArray !== '' ? (
                      <>
                        <div style={{ marginBottom: '10px' }}>
                          <b>Массив координат контура:</b>
                          <br />
                          <p style={{ marginLeft: 10 }}>{point.evalObjCoordinateArray}</p>
                        </div>
                      </>
                    ) : (
                      <>
                        <div style={{ marginBottom: '10px' }}>
                          <b>Координаты:</b>
                          <br />
                          <p
                            style={{ marginLeft: 10 }}
                          >
                            {`Широта: ${point.dotLatitude}, Долгота: ${point.dotLongitude}`}
                          </p>
                        </div>
                      </>
                    )}
                    <div style={{ marginBottom: '15px' }}>
                      <b>Время выполнения (Мин):</b>
                      <br />
                      <p style={{ marginLeft: 10 }}>{point.expectedTime}</p>
                    </div>
                    <div style={{ marginBottom: '15px' }}>
                      <b>Описание:</b>
                      <br />
                      <p style={{ marginLeft: 10 }}>{point.comment}</p>
                    </div>
                  </div>
                </React.Fragment>
              ))}
            </Grid>
            <Flex>
              <Flex width={600} height={600} direction="column">
                <div style={{ marginBottom: '20px' }}>
                  <b>Координаты на карте:</b>
                </div>
                <iframe
                  id="iframe"
                  title="map"
                  width="100%"
                  height="100%"
                  src={`/api/mapsclient/v10/widget/#/?ui=borderless&browserId=${browserId}`}
                />
              </Flex>
            </Flex>
            <Grid>
              <GridItem marginLeft="auto">
                <Button onClick={() => getImage()}>напечатать</Button>
              </GridItem>
            </Grid>
          </>
        ) : null}
      </Grid>
    </>
  );
};
