import { useMemo, useState } from 'react';
import { Box, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { DefaultText, FieldsFormControls, FormHeader, LoaderItem } from 'components/common';
import { IParameterItem, ParameterRootPath, paramsLocale } from '../consts';
import { ParamCharacteristics } from './Tabs/ParamCharacteristics';
import { SubsystemTab } from './Tabs/SubsystemTab';
import { useFormikContext } from 'formik';
import { useQuery } from 'hooks/useQuery';
import { TDictionariesArray } from 'models/dictionaries';
import { useEnhancedNotification } from 'hooks/useEnhancedNotification';
import { useGoBackWithFallback } from 'lib/utils/goBackWithFallback';

interface Props {
  isEditMode?: boolean;
  dictionaries?: TDictionariesArray;
  isLoading: boolean;
  editedObject?: any
}

export const ParamsFields = ({ isEditMode, dictionaries, isLoading, editedObject }: Props) => {
  const { onValidationError } = useEnhancedNotification();

  const { handleSubmit, errors } = useFormikContext<IParameterItem>();
  const { openAttachmentsTab } = useQuery();
  const [tabIndex, setTabIndex] = useState(openAttachmentsTab ? 2 : 0);

  const { backWithFallback } = useGoBackWithFallback();

  const title = useMemo(() => (isEditMode ? paramsLocale.paramsEditing : paramsLocale.paramsAdding), [isEditMode]);

  if (isLoading) {
    return <LoaderItem />;
  }

  const onNextTab = () => setTabIndex((prev) => prev + 1);
  const onCancel = () => backWithFallback(ParameterRootPath);

  return (
    <Box flexDirection="column">
      <Tabs index={tabIndex} onChange={(index) => setTabIndex(index)}>
        <FormHeader onBackClick={onCancel} header={title}>
          <TabList>
            {[paramsLocale.mainAttributes, paramsLocale.subsystems].map((tab, i) => (
              <Tab key={i}>
                <DefaultText cursor="pointer">{tab}</DefaultText>
              </Tab>
            ))}
          </TabList>
        </FormHeader>
        <TabPanels>
          <TabPanel>
            <ParamCharacteristics dictionaries={dictionaries} isEditMode={isEditMode} editedObject={editedObject} />
            <Box marginTop={10} width="30%">
              <FieldsFormControls onCancel={onCancel} handleSubmit={handleSubmit} onNextTab={onNextTab} />
            </Box>
          </TabPanel>
          <TabPanel>
            <SubsystemTab dictionaries={dictionaries} isEditMode={isEditMode} />
            <Box marginTop={10} width="30%">
              <FieldsFormControls
                onCancel={onCancel}
                handleSubmit={() => {
                  if (Object.keys(errors).length > 0) {
                    onValidationError();
                  }
                  handleSubmit();
                }}
                showSave={!isEditMode}
              />
            </Box>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};
