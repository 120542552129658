import { Flex } from '@chakra-ui/react';
import { RoleItem } from 'pages/admin/models/RoleItem';
import { CustomTable } from 'pages/admin/components/CustomTable';
import { UserFormObj } from '../UserFormObj';
import { useRequest } from 'hooks/useRequest';
import { PaginatedItems } from 'pages/admin/models/PaginatedItems';
import { baseAdminUrl } from 'pages/admin';
import { SourceItem } from 'pages/admin/models/SourceItem';
import { DefaultTableCellSize } from 'components/table/DefaultTableCellSize';

interface Props {
  values: UserFormObj;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  rolesRef: any;
}

export const UserRolesForm: React.FC<Props> = ({ values, setFieldValue, rolesRef }) => {
  const { get: getRoles } = useRequest<PaginatedItems<RoleItem>>(`${baseAdminUrl}/role`);

  const setChecked = (data: any[]) => data.map((role: RoleItem) => ({
    ...role,
    tableData: {
      checked: Boolean(values.roles.find((rr: string) => role.id === rr)),
    },
  }));

  const changeUserRoles = (data: SourceItem[]) => {
    setFieldValue('roles', data.map((row: SourceItem) => row.id));
  };

  const columns = [
    {
      title: 'Роль',
      field: 'name',
    },
    {
      title: 'Описание',
      field: 'description',
      render: (row: any) => <DefaultTableCellSize maxWidth={600}>{row.description}</DefaultTableCellSize>
    },
    {
      title: 'Удалена',
      field: 'deleted',
      render: (rowdata: RoleItem) => (
        <input type="checkbox" checked={rowdata.deleted} readOnly />
      )
    }
  ];

  return (
    <Flex direction="column">
      <CustomTable
        tableRef={rolesRef}
        useObjQuery
        style={{ marginLeft: 0 }}
        queryFunc={getRoles}
        postLoadFunc={setChecked}
        options={{
          search: true,
          selection: true,
          showTitle: true,
          toolbar: true,
          paging: true,
        }}
        columns={columns}
        title="Текущие роли"
        onSelectionChanged={changeUserRoles}
      />
    </Flex>
  );
};
