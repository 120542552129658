import { FormWrapper } from 'components/common';
import { Box, Grid } from '@chakra-ui/react';
import React, { Fragment } from 'react';
import { useFormikContext } from 'formik';
import { IReportProtocolForm } from 'pages/analytics/Raids/types';
import { IRenderFieldInterface, useFormField } from 'hooks/forms/useFormField';
import { AnalyticsPelAtmosphereSchema } from 'models/schemas/analytics/analyticsPelAtmosphereSchema';
import appLocale from 'constants/appLocale';
import { ANALYTICS_STATUS } from 'pages/analytics/forms/const';

export const AnalyticsPelAtmosphereMeteoProbe = ({ isEditMode }: { isEditMode?: boolean }) => {
  const { values } = useFormikContext<IReportProtocolForm>();

  const { renderField } = useFormField<IReportProtocolForm>({
    schema: AnalyticsPelAtmosphereSchema,
    labelLocalization: { ...appLocale.analytics.fields, ...appLocale.analytics.pel.fields },
  });

  // while status is hidden assume status as draft
  let fieldsIsDisabled = values.status === ANALYTICS_STATUS.APPROVED && !!isEditMode;
  fieldsIsDisabled = false;

  const fields: IRenderFieldInterface<IReportProtocolForm>[] = [
    { name: 'sampleRegNumber', isDisabled: fieldsIsDisabled },
    { name: 'sampleActNumber', isDisabled: fieldsIsDisabled },
    { name: 'researchStartDatetime', type: 'datetimeinput', isDisabled: fieldsIsDisabled },
    { name: 'researchEndDatetime', type: 'datetimeinput', isDisabled: fieldsIsDisabled },
  ];

  return (
    <Box flexDirection="column">
      <FormWrapper>
        <Grid marginTop="30px" gap="15px">
          {fields.map((field) => (
            <Fragment key={field.name}>{renderField(field)}</Fragment>
          ))}
        </Grid>
      </FormWrapper>
    </Box>
  );
};
