import { IDayResponse, IManagerPeriod } from '../Manager/types';
import { useUpdateBreadcrumbs } from '../../features/breadcrumbs-provider';
import { useEnhancedNotification, useRequest } from '../../hooks';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import moment from 'moment/moment';
import { DateInput } from '../../components/common';
import { Box, Flex, Spinner, TabList, Tabs, Text } from '@chakra-ui/react';
import { StyledTabSmall } from '../../components/common/StyledTab/StyledTabSmall';
import ManagerDataPanel from '../Manager/components/ManagerDataPanel';
import { Askza, Emissions, Meteo, Water } from '../home/icons';
import { LinksCard } from '../Manager/components/LinksCard';
import { periodLocale } from 'pages/Manager/const';
import { Field, FormikProvider, useFormik } from 'formik';
import { createDateToRequestWithTimezone, createUTCTimestampMS } from 'lib/create-date';
import { airSentrySubtitles, graphValidationSchema, meteoSentrySubtitles, waterSentrySubtitles, weekMS } from './const';
import ManagerDataGraph from 'pages/Manager/components/ManagerDataGraph';
import { SentryDashboardMap } from './SentryDashboardMap';
import { SentryNotifications } from './SentryNotifications';
import subsystemIdsMap from 'constants/subsystemIdsMap';
import ManagerDataGraphInversion from 'pages/Manager/components/ManagerDataGraphInversion';

const sentryPeriods: IManagerPeriod[] = ['now', 'custom'];

const SentryDashBoard = () => {
  useUpdateBreadcrumbs([{ breadcrumb: 'Информационная панель ЛК СОК', path: '/sentry/dashboard' }]);
  const { onGetError } = useEnhancedNotification();
  const [period, setPeriod] = useState<IManagerPeriod>('now');
  const [date, setDate] = useState<string | string[]>();
  const [data, setData] = useState<IDayResponse>();

  const onSuccess = useCallback(
    (data: IDayResponse | null) => {
      if (!data) {
        return;
      }
      setData(data);

      if (period !== 'custom') {
        setDate(data.date);
      }
    },
    [period],
  );

  const { get, isLoading } = useRequest<IDayResponse>(
    '/reporting/v10/dashboard/indicators/summary/duty-officer',
    onSuccess,
    onGetError,
  );

  const dateNow = useMemo(() => new Date().getTime(), []);
  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    initialValues: {
      dateFrom: createDateToRequestWithTimezone(dateNow - weekMS),
      dateTo: createDateToRequestWithTimezone(dateNow),
    },
    onSubmit: console.log,
  });

  const graphFormik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    initialValues: {
      from: dateNow - weekMS,
      to: dateNow,
    },
    validationSchema: graphValidationSchema,
    onSubmit: console.log,
  });

  useEffect(() => {
    setDate(undefined);
    if (period !== 'custom') {
      void get({ params: { period } });
    }
  }, [get, period]);

  useEffect(() => {
    setDate(undefined);
    if (period === 'custom' && formik.values.dateTo && formik.values.dateFrom) {
      void get({ params: { ...formik.values, period } });
    }
  }, [get, period, formik.values]);

  const formattedDate = useMemo(() => {
    if (!date) return null;

    if (Array.isArray(date)) {
      return `${moment(date[0]).format('DD.MM.YYYY')} - ${moment(date[1]).format('DD.MM.YYYY')}`;
    }

    return moment(date).format('DD.MM.YYYY, dddd, состояние на HH:mm');
  }, [date]);

  const lkodAirUrl = useMemo(() => {
    if (
      !graphFormik.values.from ||
      !graphFormik.values.to ||
      graphFormik.values.to - graphFormik.values.from > weekMS
    ) {
      return null;
    }

    return `/reporting/v10/dashboard/charts/lkod/air?from=${graphFormik.values.from}&to=${graphFormik.values.to}`;
  }, [graphFormik.values]);

  return (
    <Flex flexDirection="column" pb="50px">
      <Flex mx="auto" flexDirection="column" width="100%" px="16px">
        <Flex pt="20px" justifyContent="space-between">
          <Text mb="16px" fontSize="24px" lineHeight="30px" fontWeight="700">
            Личный кабинет оперативного дежурного службы оперативного контроля
          </Text>
        </Flex>

        <Flex justifyContent="space-between" alignItems="center">
          <Tabs index={sentryPeriods.indexOf(period)} onChange={(i) => setPeriod(sentryPeriods[i])}>
            <TabList border="none">
              {sentryPeriods.map((period, index) => (
                <StyledTabSmall
                  key={period}
                  borderTopLeftRadius={index === 0 ? '4px' : undefined}
                  borderBottomLeftRadius={index === 0 ? '4px' : undefined}
                  borderTopRightRadius={index === sentryPeriods.length - 1 ? '4px' : undefined}
                  borderBottomRightRadius={index === sentryPeriods.length - 1 ? '4px' : undefined}
                  borderRight={index === sentryPeriods.length - 1 ? '' : 'none'}
                >
                  {periodLocale[period]}
                </StyledTabSmall>
              ))}
            </TabList>
          </Tabs>

          {(date || isLoading) && (
            <Text color="#208CFF">{isLoading ? <Spinner color="PrimaryButton.500" /> : formattedDate}</Text>
          )}
        </Flex>

        {period === 'custom' && (
          <Flex mt="16px" maxWidth="500px" gap="8px">
            <FormikProvider value={formik}>
              <Field
                as={DateInput}
                name="dateFrom"
                label="От"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  formik.setFieldValue('dateFrom', createDateToRequestWithTimezone(e.target.value));
                }}
                showTimeInput
              />

              <Field
                as={DateInput}
                name="dateTo"
                label="До"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  formik.setFieldValue('dateTo', createDateToRequestWithTimezone(e.target.value));
                }}
                showTimeInput
              />
            </FormikProvider>
          </Flex>
        )}

        <Flex mt="16px" gap="16px">
          {data?.air && (
            <ManagerDataPanel
              title="Воздух"
              subtitle="Основные вещества"
              data={data?.air.flat()}
              icon={<Askza />}
              subsystem="air"
              period={period}
              rowSubtitle={airSentrySubtitles}
            />
          )}

          {data?.meteo && (
            <ManagerDataPanel
              title="Метео"
              subtitle=" "
              data={data?.meteo}
              icon={<Meteo />}
              subsystem="meteo"
              isMeteo
              period={period}
              rowSubtitle={meteoSentrySubtitles}
            />
          )}
        </Flex>

        {data?.airOrganic && period === 'custom' && (
          <Flex mt="20px">
            <ManagerDataPanel
              title="Воздух"
              subtitle="Органические вещества"
              data={data?.airOrganic.flat()}
              icon={<Askza />}
              subsystem="air"
              cols={7}
              period={period}
              rowSubtitle={airSentrySubtitles}
            />
          </Flex>
        )}

        <Flex mt="20px" gap="16px">
          {data?.water && period === 'custom' && (
            <Box width="100%">
              <ManagerDataPanel
                title="Вода"
                data={data?.water}
                icon={<Water />}
                subsystem="water"
                period={period}
                rowSubtitle={waterSentrySubtitles}
              />
            </Box>
          )}

          {data?.airOrganic && period === 'now' && (
            <Box width="100%">
              <ManagerDataPanel
                title="Воздух"
                subtitle="Органические вещества"
                data={data?.airOrganic.flat()}
                icon={<Askza />}
                subsystem="air"
                cols={7}
                period={period}
                rowSubtitle={airSentrySubtitles}
              />
            </Box>
          )}

          {data?.emissions && (
            <Box width="100%" maxWidth="calc(50% - 8px)">
              <ManagerDataPanel
                title="Промвыбросы"
                data={data?.emissions}
                icon={<Emissions />}
                subsystem="emissions"
                isLoading={isLoading}
              />
            </Box>
          )}
        </Flex>
      </Flex>

      <Box my="32px" width="100%" background="#D4D9DC" height="1px" />

      <SentryDashboardMap />

      <Box my="32px" width="100%" background="#D4D9DC" height="1px" />

      <Flex mx="auto" flexDirection="column" width="100%" px="16px">
        <Flex gap="8px" maxWidth="600px">
          <FormikProvider value={graphFormik}>
            <Field
              as={DateInput}
              name="from"
              label="От"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                graphFormik.setFieldValue('from', createUTCTimestampMS(e.target.value));
              }}
              showTimeInput
              error={graphFormik.errors.from}
            />
            <Field
              as={DateInput}
              name="to"
              label="До"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                graphFormik.setFieldValue('to', createUTCTimestampMS(e.target.value));
              }}
              showTimeInput
              ml="8px"
              error={graphFormik.errors.to}
            />
          </FormikProvider>
        </Flex>

        <ManagerDataGraph
          title="Воздух"
          subtitle="Основные вещества"
          icon={<Askza />}
          url={lkodAirUrl}
          height="300px"
          yAxisTitle="Доли ПДК"
        />

        <ManagerDataGraphInversion
          title="Метео"
          subtitle="Инверсия"
          icon={<Meteo />}
          url="/reporting/v10/dashboard/charts/common/air-inversion/msz4"
          height="300px"
        />
      </Flex>

      <Flex mt="40px" mx="auto" flexDirection="column" width="100%" px="16px">
        <SentryNotifications />
      </Flex>

      <Flex mt="40px" mx="auto" flexDirection="column" width="100%" px="16px">
        <LinksCard
          title="Аналитическая отчетность"
          links={[
            {
              name: 'Отчёт оперативного дежурного службы оперативного контроля об экологической обстановке в городе Москве за заданный период времени по установленной форме',
              path: `/reports-analytics/duty-office/${subsystemIdsMap.dutyOfficer}/SOKEnvironmentalJournal`,
            },
            {
              name: 'Отчет по выявленным при помощи видеокамер фактам сжигания.',
              path: `/reports-analytics/duty-office/${subsystemIdsMap.dutyOfficer}/fire-journal`,
            },
          ]}
        />
      </Flex>
    </Flex>
  );
};

export default SentryDashBoard;
