import { useRequest } from 'hooks';
import { useCallback } from 'react';

export interface IUxMetadata {
  filterDTO: any;
  hiddenColumns: string[];
  selectedIds?: string[];
}

interface IUseUxMetadata {
  uxMetadata: IUxMetadata | null;
  getUxMetadata: () => Promise<IUxMetadata | null>
  saveUxMetadata: (filters: any) => Promise<void>
  resetUxMetadata: () => Promise<void>
  isUxMetadataLoading: boolean;
}

export function useUxMetadata(subsystem: string, module: string, key: string = 'default'): IUseUxMetadata {
  const {
    get,
    post,
    result: uxMetadata,
    isLoading: isUxMetadataLoading
  } = useRequest<IUxMetadata>('/admin/v10/ux-metadata');

  const getUxMetadata = useCallback(async () => {
    const response = await get({
      params: {
        subsystem,
        module,
        key
      },
    });

    if (!response || 'error' in response || Object.keys(response).length === 0) {
      return null;
    }

    return response as unknown as IUxMetadata | null;
  }, [subsystem, key, module, get]);

  const saveUxMetadata = useCallback(async (filters?: IUxMetadata) => {
    await post(filters, {
      params: {
        subsystem,
        module,
        key
      },
    });
  }, [subsystem, module, key, post]);

  const resetUxMetadata = useCallback(async () => saveUxMetadata(), [saveUxMetadata]);

  return {
    uxMetadata,
    getUxMetadata,
    saveUxMetadata,
    resetUxMetadata,
    isUxMetadataLoading,
  };
}
