import { Box, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';

import { DefaultText, FormHeader } from 'components/common';

import { TDictionariesArray } from 'models';

import { MeteoPackageFormTabs } from 'pages/data/MASKSH/Data/Meteo/MeteoPackageForm/Form/Tabs';
import { tabsMeteoLocaleList } from 'pages/data/MASKSH/consts';
import { useGoBackWithFallback } from 'lib/utils/goBackWithFallback';

interface INoisePackageFields {
  dictionaries: {
    data: TDictionariesArray;
  } | null;
  isLoading: boolean;
  isEditMode?: boolean;
  listPath?: string;
}

export const MeteoPackageFormFields = ({ listPath, isLoading, isEditMode, dictionaries }: INoisePackageFields) => {
  const { backWithFallback } = useGoBackWithFallback();
  const header = `${isEditMode ? 'Редактирование' : 'Добавление'} результатов обследования`;

  return (
    <Box flexDirection="column">
      <Tabs>
        <FormHeader
          onBackClick={() => backWithFallback(listPath)}
          header={header}
        >
          <TabList>
            {tabsMeteoLocaleList.map((tab, i) => (
              <Tab key={i}>
                <DefaultText cursor="pointer">{tab}</DefaultText>
              </Tab>
            ))}
          </TabList>
        </FormHeader>
        <TabPanels>
          <TabPanel>
            <MeteoPackageFormTabs.Common dictionaries={dictionaries} isLoading={isLoading} isEditMode={isEditMode} />
          </TabPanel>
          <TabPanel>
            <MeteoPackageFormTabs.Self />
          </TabPanel>
          <TabPanel>
            <MeteoPackageFormTabs.Params isLoading={isLoading} listPath={listPath} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};
