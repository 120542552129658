import React, { useCallback, useMemo } from 'react';
import { map } from 'lodash';
import { Button } from '@chakra-ui/react';
import { SubsystemHeader, useDefaultFilters } from 'components/common';
import { Modal } from 'components/common/Modals';
import { MaterialTable } from 'features/material-table/MaterialTableMini';
import { useAvailability, useDisclosure, useHandleDeleteData } from 'hooks';
import { useAskzaDictionary } from 'hooks/askza/useAskzaDictionary';
import { AtmosphereMonitoringItem } from 'hooks/atmosphere/Monitoring/useAtmosphereMonitoringList';
import { useList } from 'hooks/useList';
import { getSelectOptionsFromDictionaryByName } from 'features/get-select-options-from-dictionary';
import { AtmosphereFilter } from './ASKZAFilter';
import { useHistory } from 'react-router-dom';
import { Column } from '@material-table/core';
import { addFiltersToColumns } from 'lib/utils/tableFilter';
import useGetListItemByIds from 'hooks/useGetListItemByIds';
import useCalculations from 'hooks/useCalculations';
import { operations } from 'lib/calculations/operations';
import atmosphereColumns from 'components/common/ASKZACommon/atmoshphereColumns';
import { PropActions } from 'features/material-table/components/Actions';
import { UploadFileModalContent } from 'features/UploadFileDragAndDrop/UploadFileModalContent';
import { usePeriodsDict } from 'features/PeriodsButtons/usePeriodsDict';
import { useGraphFilter } from 'hooks/useGraphFilter';
import subsystemIdsMap from '../../../constants/subsystemIdsMap';
import { createUTCTime } from '../../../lib/create-date';
import { ExtensionToMimeTypeEnum } from 'lib/utils/files/getFileExtensionByMimeType';
import { DeleteIcon } from '../../icons';
import { DEFAULT_PAGE_SIZE_NEW_TABLE } from '../../../constants/tables';
import { FullPageWrapper } from '../../table/FullPageWrapper';

type UploadType = {
  name: string;
  value: string;
};

interface IASKZA {
  title: string;
  rootPath: string;
  apiRootPath: string;
  types: UploadType[];
  exportSubsystemName: string;
  askzaType: string;
  subsystemId: string;
  checkSourcePriv?: boolean;
}

export const ASKZA: React.FC<IASKZA> = ({
  title,
  rootPath,
  apiRootPath,
  types,
  exportSubsystemName,
  askzaType,
  subsystemId,
  checkSourcePriv = false
}) => {
  const { isWrite, isExecute, isNoRights, allAvailable, isAvailable } = useAvailability(subsystemId);
  const history = useHistory();
  const { result: dictionaries } = useAskzaDictionary(askzaType);

  const { stationsSelect } = useMemo(() => {
    const forSelect = getSelectOptionsFromDictionaryByName(dictionaries?.data, 'stations');

    return { stationsSelect: forSelect };
  }, [dictionaries]);

  const { parametersSelect } = useMemo(() => {
    const forSelect = getSelectOptionsFromDictionaryByName(dictionaries?.data, 'parameters');

    return {
      parametersSelect: forSelect,
    };
  }, [dictionaries]);

  const { isOpen: isOpenLoad, onClose: onCloseLoad, onOpen: onOpenLoad } = useDisclosure();

  const selectionKey: keyof AtmosphereMonitoringItem = 'id';

  const {
    refetch,
    isLoading: isLoadingAtmosphereMonitoringList,
    response,
    toggleOrder,
    setFilters,
    onPageChange,
    filterDTO,
    downloadAsFile,
    onSearchText,
    saveFilters,
    selectedIndexes,
    renderOnlySelectedCheckbox,
    onSubmitFilters,
    onSelectionChange,
    selectedIds,
    materialTableProps,
  } = useList<AtmosphereMonitoringItem, keyof AtmosphereMonitoringItem>(`${apiRootPath}/list`, {
    initialOrderFieldKey: '-datetime',
    exportSubsystemName,
    preventFirstFetch: true,
    selectionKey,
    saveFiltersState: true,
    downloadUrl: {
      [ExtensionToMimeTypeEnum.xlsx]: `${apiRootPath}/list/export`,
      [ExtensionToMimeTypeEnum.csv]: `${apiRootPath}/list/export`,
    },
    defaultPageSize: DEFAULT_PAGE_SIZE_NEW_TABLE,
    resetDataOnNextRequest: true,
  });

  const { ModalComponent, handleOpenModal } = useHandleDeleteData({ refetch });

  const graphFilters = useDefaultFilters({
    filters: filterDTO.filters,
    dateKey: 'datetime',
    paramId: 'parameterId',
    stationId: 'sourceId',
    initialData: 'initialData',
  });

  const {
    GraphFilter,
    graphDisclosure,
    graphOnOpen,
    filtersFromGraphic,
    setFiltersFromGraphic,
    isGraphLoading,
    setGraphInitialFilters,
  } = useGraphFilter<keyof AtmosphereMonitoringItem>({
    filters: filterDTO.filters,
    setFilters: onSubmitFilters,
    graphFilters,
    selectionKey,
  });

  // calculations
  const { applyCalculation, isCalculationLoading, renderDriftPreview, calculatePreview, renderPreview } =
    useCalculations({
      onSuccess: refetch,
      apiPath: apiRootPath,
      previewPath: apiRootPath,
      columns: atmosphereColumns,
    });

  // data processing
  const { getItems, isLoading: isItemsLoading } = useGetListItemByIds<AtmosphereMonitoringItem>({
    url: `${apiRootPath}/list`,
    idKey: selectionKey,
  });
  const onSubmitDataProcessing = useCallback(
    async (values) => {
      const selectedData = await getItems(selectedIds, filterDTO.filters);

      const obj = {
        ...values,
        // TODO: add types to operandIds
        // @ts-ignore
        ids: map(selectedData, (row) => row[`${values.operandId}Value`]?.measuringId),
      };
      if (subsystemId === subsystemIdsMap.meteoASKZA || values.operationId === 'delete') {
        void calculatePreview(
          {
            value: values.value,
            valueType: values.operandId.toUpperCase(),
            operation: values.operationId,
            criteria: filterDTO,
          },
          obj,
        );
        return;
      }
      void applyCalculation(obj);
    },
    [applyCalculation, calculatePreview, filterDTO, getItems, selectedIds, subsystemId],
  );

  const dataProcessing = useMemo(() => {
    const calculationFields = [
      { value: 'avg', name: 'avg' },
      { value: 'min', name: 'min' },
      { value: 'max', name: 'max' },
    ];

    return {
      operands: calculationFields,
      operations,
      onSubmit: onSubmitDataProcessing,
    };
  }, [onSubmitDataProcessing]);

  const columnsWithFilters = useMemo<Array<Column<AtmosphereMonitoringItem>>>(
    () => addFiltersToColumns({ filters: filterDTO.filters, setFilters: onSubmitFilters, columns: atmosphereColumns }),
    [filterDTO.filters, onSubmitFilters],
  );

  const isLoading = useMemo(
    () => isCalculationLoading || isItemsLoading || isLoadingAtmosphereMonitoringList || isGraphLoading,
    [isCalculationLoading, isItemsLoading, isLoadingAtmosphereMonitoringList, isGraphLoading],
  );

  const initialActions = useMemo<PropActions<AtmosphereMonitoringItem[]>>(
    () => [
      {
        position: 'row',
        action: (row: AtmosphereMonitoringItem) => ({
          icon: () => <DeleteIcon />,
          onClick: () => handleOpenModal({ url: `${apiRootPath}/${row.id}` }),
          tooltip: 'Удалить',
          hidden: checkSourcePriv ? !row.isEditable : false,
        }),
      },
    ],
    [apiRootPath, checkSourcePriv, handleOpenModal],
  );

  // @ts-ignore
  const actions = useMemo<PropActions<any[]>>(() => {
    if (allAvailable || isNoRights || isWrite) {
      return initialActions;
    }
    if (!(allAvailable || isNoRights || isWrite)) {
      return initialActions.filter((action: any) => action.type !== 'delete');
    }
  }, [isWrite, initialActions, isNoRights, allAvailable]);

  const { result: periods } = usePeriodsDict(`auth/v10/user/period/${subsystemId}`);

  const parametersKeys = useMemo(() => {
    const sourceId = filterDTO?.filters.find((item) => item.key === 'sourceId')?.value as string;
    const sourceIds = sourceId ? [sourceId] : stationsSelect.map((item) => item.value);
    const dateFrom =
      (filterDTO?.filters.find((item) => item.key === 'datetime' && item.operation === 'GTE')?.value as string) || '';
    const dateTo =
      (filterDTO?.filters.find((item) => item.key === 'datetime' && item.operation === 'LTE')?.value as string) || '';
    return {
      sourceIds,
      dateFrom: createUTCTime(dateFrom),
      dateTo: createUTCTime(dateTo),
    };
  }, [filterDTO?.filters, stationsSelect]);

  return (
    <FullPageWrapper>
      <SubsystemHeader
        title={title}
        onUploadClick={isAvailable ? onOpenLoad : undefined}
        addButtonProps={{
          onClick: () => history.push(`${rootPath}/add`),
          isDisabled: !isAvailable,
        }}
        filtersComponent={
          <AtmosphereFilter
            periods={periods}
            filtersFromGraphic={filtersFromGraphic}
            setFiltersFromGraphic={setFiltersFromGraphic}
            stations={stationsSelect}
            parameters={parametersSelect}
            isLoading={isLoading}
            isDisabled={isLoading}
            filters={filterDTO?.filters || []}
            onSubmit={(values) => onSubmitFilters(Object.values(values))}
            setGraphInitialFilters={setGraphInitialFilters}
          >
            {renderOnlySelectedCheckbox}
          </AtmosphereFilter>
        }
      />

      <Modal isOpen={isOpenLoad} onClose={onCloseLoad} header="Загрузка данных из файла">
        <UploadFileModalContent
          createUploadPath={() => `${apiRootPath}/load`}
          types={types}
          onClose={onCloseLoad}
          refetch={refetch}
        />
      </Modal>

      {!graphDisclosure.isOpen && (
        <MaterialTable
          isNotExecute={isExecute || isNoRights}
          filterDTO={filterDTO}
          data={response?.data}
          meta={response?.meta}
          toggleOrder={toggleOrder}
          onPageChange={onPageChange}
          dataProcessing={dataProcessing}
          columns={columnsWithFilters}
          actions={actions}
          options={{
            search: true,
          }}
          isLoading={isLoading}
          onSearch={onSearchText}
          downloadAsFile={downloadAsFile}
          selectedIndexes={selectedIndexes}
          onSelectionChange={onSelectionChange}
          topBarLeftContent={
            <Button variant="solid" size="sm" colorScheme="PrimaryButton" onClick={graphOnOpen}>
              Открыть график
            </Button>
          }
          saveFilters={saveFilters}
          setFilters={setFilters}
          processingParamsUrl="/meteo/v10/meteo/dictionaries/dependent-parameters"
          parametersKeys={parametersKeys}
          isWrite={isWrite}
          {...materialTableProps}
          showTotalCount
        />
      )}

      {ModalComponent}

      {renderDriftPreview}

      {GraphFilter}

      {renderPreview}
    </FullPageWrapper>
  );
};
