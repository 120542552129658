import React, { useMemo } from 'react';
import { FieldsForm } from './FieldsForm';
import { FormikProvider, useFormik, useFormikContext } from 'formik';
import { TDictionariesArray } from 'models/dictionaries';
import { useRequest } from 'hooks/useRequest';
import { useNotifications } from 'hooks/useNotifications';
import {
  ADD_INCIDENT_SUCCESS,
  AppealValues,
  Complaint,
  EDIT_INCIDENT_ERROR,
  getIncidentEditEndpoint,
} from 'pages/citizenAppeal';
import { createTouchedErrors } from 'features/create-touched-errors';
import { IncidentShema } from 'models/schemas';
import { getSelectRichOptionsFromDictionaryByName } from 'features/get-select-options-from-dictionary';

interface Props {
  dictionaries?: TDictionariesArray;
  setIncident: (incident: Complaint | null) => void;
  complaint: Complaint | null;
  isWrite?: boolean
}

export const EditIncidentForm = ({ dictionaries, setIncident, complaint, isWrite }: Props) => {
  const { values: appealValues } = useFormikContext<AppealValues>();

  const { successNotify, errorNotify } = useNotifications();

  const onSuccess = (incident: Complaint | null) => {
    successNotify({
      key: 'file/edit/success',
      message: ADD_INCIDENT_SUCCESS,
    });
    setIncident(incident);
  };

  const onError = () =>
    errorNotify({
      key: 'file/edit/error',
      message: EDIT_INCIDENT_ERROR,
    });

  const { put } = useRequest(getIncidentEditEndpoint(complaint?.complaintId), onSuccess, onError);
  const assignmentStatuses = useMemo(
    () => getSelectRichOptionsFromDictionaryByName(dictionaries, 'assignmentStatuses'),
    [dictionaries]
  );

  const initialEmptyValues = useMemo(() => ({
    assignmentExecutor: '',
    complaintStatusId: '',
    complaintComment: '',
    complaintId: '',
    incidents: []
  }), []);

  const initialValues = useMemo(() => {
    const editedValues: any = { ...initialEmptyValues };
    if (complaint) {
      const {
        assignmentExecutor,
        complaintComment,
        complaintId,
        incidents,
        complaintStatusId
      } = complaint;
      return {
        assignmentExecutor,
        complaintStatusId: complaintStatusId || assignmentStatuses?.find((status) => status.name === 'В работе')?.value,
        complaintComment,
        complaintId,
        incidents,
      };
    }
    return editedValues;
  }, [complaint, initialEmptyValues, assignmentStatuses]);

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    validationSchema: IncidentShema(dictionaries),
    initialValues,
    onSubmit: (values: any) => {
      put(values);
    },
  });

  return (
    <FormikProvider
      value={{
        ...formik,
        errors: createTouchedErrors(formik.errors, formik.touched),
      }}
    >
      <FieldsForm
        appealValues={appealValues}
        dictionaries={dictionaries}
        isWrite={isWrite}
      />
    </FormikProvider>
  );
};
