import { AttachmentsTabContent, Button, DateInput, FormWrapper, Input, Select } from 'components/common';
import { Box, Flex, Grid, Heading } from '@chakra-ui/react';
import {
  getSelectOptionsFromDictionaryByName,
  getSelectOptionsFromDictionaryByNameWithParent,
} from 'features/get-select-options-from-dictionary';
import { Field, useFormikContext } from 'formik';
import { MeasurementRecord, MeasuringItem } from 'hooks/geo/Measuring/useMeasuringList';
import { createDateFromResponse, createDateToRequestWithTimezone } from 'lib/create-date';
import React, { useEffect, useMemo } from 'react';
import { useObjectType } from 'hooks/useObjectType';
import { FieldFormProps } from 'types/forms';
import subsystemIdsMap from 'constants/subsystemIdsMap';
import { useAvailability } from 'hooks';

const FieldsForm = ({ onCancel, dictionaries, media, onMediaChange, uploadItemId }: FieldFormProps) => {
  const { isAvailable } = useAvailability(subsystemIdsMap.geoecologyRoot);

  const { handleSubmit, setFieldValue, values, errors, initialValues } = useFormikContext<MeasuringItem>();
  const isEditMode = location.pathname.includes('/edit');

  const types = useMemo(
    () => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'types', false, initialValues.waterSourceType),
    [dictionaries?.data, initialValues.waterSourceType],
  );

  const watersources = useMemo(() => {
    const dictionary = getSelectOptionsFromDictionaryByNameWithParent(dictionaries?.data, 'watersources');

    if (values.waterSourceType) {
      return dictionary.filter(({ parent }) => parent === values.waterSourceType);
    }

    return dictionary;
  }, [dictionaries?.data, values.waterSourceType]);

  const objectType = useObjectType(dictionaries);

  const selectedParentType = useMemo(
    () => types.find((source) => source.value === values.waterSourceType)?.parent,
    [values.waterSourceType, types],
  );

  const hideDebit = useMemo(() => ['DRAW_WELL', 'WELL'].includes(selectedParentType || ''), [selectedParentType]);
  const hideDepth = useMemo(() => ['SPRING'].includes(selectedParentType || ''), [selectedParentType]);

  useEffect(() => {
    if (hideDebit) {
      setFieldValue('debit.value', '');
    }
    if (hideDepth) {
      setFieldValue('depth.value', '');
    }
  }, [hideDebit, hideDepth, setFieldValue]);

  const submitIsDisabled =
    [values.debit, values.depth, values.temperature].filter((item) => item && item.value).length === 0;

  return (
    <Box flexDirection="column">
      <FormWrapper>
        <Grid marginTop="30px" gap="15px">
          <Heading variant="h2">Данные обследования</Heading>
          <DateInput
            name="measuringDate"
            label="Дата обследования"
            value={createDateFromResponse(values.measuringDate)}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setFieldValue('measuringDate', createDateToRequestWithTimezone(e.target.value))
            }
            isDisabled={isEditMode}
            showTimeInput={false}
          />
          <Field
            as={Select}
            name="waterSourceType"
            label="Тип пункта наблюдения"
            value={values.waterSourceType}
            options={types}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              setFieldValue('waterSourceType', e.target.value);
              setFieldValue('sourceId', '');
            }}
            error={errors.waterSourceType}
            isDisabled={isEditMode}
          />
          <Field
            as={Select}
            name="sourceId"
            label="Пункт наблюдения"
            value={values.sourceId}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              setFieldValue('sourceId', e.target.value);
              setFieldValue('waterSourceType', watersources.find((el) => el.value === e.target.value)?.parent);
            }}
            options={watersources}
            error={errors.sourceId}
            isDisabled={isEditMode}
          />

          {!hideDepth && (
            <Field
              as={Input}
              name="depth.value"
              label="Глубина до уровня, м"
              value={values.depth ? values.depth?.value : ''}
              isDisabled={!isAvailable}
              error={(errors.depth && (errors?.depth as MeasurementRecord).value) || ''}
            />
          )}

          {!hideDebit && (
            <Field
              as={Input}
              name="debit.value"
              label="Дебит, л/с"
              value={values.debit ? values.debit?.value : ''}
              isDisabled={!isAvailable}
              error={errors.debit && (errors?.debit as MeasurementRecord).value}
            />
          )}
          <Field
            as={Input}
            name="temperature.value"
            label={`Температура, ${'\u00b0'}C`}
            value={values.temperature ? values.temperature?.value : ''}
            isDisabled={!isAvailable}
            error={errors.temperature && (errors?.temperature as MeasurementRecord).value}
          />

          {onMediaChange && isAvailable && (
            <AttachmentsTabContent
              objectType={objectType}
              media={media}
              onMediaChange={onMediaChange}
              uploadItemId={uploadItemId}
            />
          )}

          <Flex marginBottom={30}>
            <Button onClick={onCancel} marginRight="auto">
              Отмена
            </Button>
            <Button onClick={() => handleSubmit()} variant="solid" isDisabled={submitIsDisabled || !isAvailable}>
              Сохранить
            </Button>
          </Flex>
        </Grid>
      </FormWrapper>
    </Box>
  );
};

export default React.memo(FieldsForm);
