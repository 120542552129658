import { Modal } from 'components/common/Modals/Modal';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Column } from '@material-table/core';
import { MaterialTable } from 'features/material-table/MaterialTableMini';
import { useRequest } from 'hooks';
import { Box, Button, Flex, Heading, Text } from '@chakra-ui/react';
import { createLocalDateCustomFormat } from 'lib/create-date';
import { ProtocolData } from 'models/protocol/protocol';
import appLocale, { protocolOperations } from 'constants/appLocale';

interface Props {
  id: string;
  isOpen: boolean;
  onClose: () => void;
  date?: any;
  additionalData?: { value: any; label: string }[];
  baseUrl?: string;
  previewColumns?: Column<any>[];
  secondBaseUrl?: string;
}

export function ProtocolModal({
  id,
  isOpen,
  onClose,
  date,
  additionalData,
  baseUrl,
  previewColumns,
  secondBaseUrl,
}: Props) {
  const { result, get, isLoading } = useRequest<ProtocolData[]>(
    `${baseUrl || '/geoecology/v10/geoecology'}/values/history/${id}`,
  );
  const { get: getMethod, isLoading: isPostLoading } = useRequest('');
  // TODO: add typings for diffrent previews
  const [preview, setPreview] = useState<any>();

  useEffect(() => {
    void get();
  }, [get]);

  const getUserName = useCallback<(data: ProtocolData) => string>((data) => {
    let name = data.userName || '';

    if (data.userMiddleName) {
      name += `${name ? ' ' : ''}${data.userMiddleName}`;
    }
    if (data.userSurname) {
      name += `${name ? ' ' : ''}${data.userSurname}`;
    }

    return name;
  }, []);

  const _result = useMemo(() => result?.reverse() || [], [result]);

  const columns = useMemo<Column<ProtocolData>[]>(
    () => [
      {
        title: 'Версия',
        field: 'version',
        width: 40,
      },
      {
        title: 'Дата и время обработки',
        field: 'editDate',
        render: (row) => (row.editDate ? createLocalDateCustomFormat(row.editDate, 'HH:mm, DD.MM.YYYY') : '-'),
        width: 200,
      },
      {
        title: 'Исполнитель',
        field: 'userName',
        render: (data) => getUserName(data),
        width: 200,
      },
      {
        title: 'Операция',
        field: 'operation',
        render: (row) => appLocale.common.protocolOperations[row.operation] || '-',
      },
      {
        title: 'Значение',
        field: 'currValue',
      },
      {
        title: 'Предыдущее значение',
        field: 'prevValue',
      },
    ],
    [getUserName],
  );

  const reset = useCallback(async () => {
    await getMethod({ url: `${secondBaseUrl || '/geoecology/v10/geoecology'}/values/reset/${id}` });
    await get();
  }, [getMethod, secondBaseUrl, id, get]);

  const undo = useCallback(async () => {
    await getMethod({ url: `${secondBaseUrl || '/geoecology/v10/geoecology'}/values/undo/${id}` });
    await get();
  }, [getMethod, secondBaseUrl, id, get]);

  const restore = useCallback(async () => {
    await getMethod({ url: `${baseUrl || '/geoecology/v10/geoecology'}/values/restore/${id}` });
    setPreview(null);
    await get();
  }, [get, id, getMethod, baseUrl]);

  const restorePreview = useCallback(async () => {
    const preview = await getMethod({ url: `${baseUrl || '/geoecology/v10/geoecology'}/values/restore/${id}/preview` });
    setPreview(preview);
  }, [id, getMethod, baseUrl]);

  const renderHeader = useMemo(
    () => (
      <Box marginBottom={4}>
        <Flex marginBottom={2}>
          <Heading size="md" marginRight="auto">
            История записи
          </Heading>
          <Flex>
            {additionalData &&
              additionalData.map((data) => (
                <Box px={2}>
                  <Text fontSize="sm" fontWeight="400">
                    {data.label}
                  </Text>
                  <Text fontSize="sm" fontWeight="400">
                    {data.value}
                  </Text>
                </Box>
              ))}
          </Flex>
        </Flex>

        {!isLoading && (
          <Flex>
            <Flex direction="column" paddingRight={4}>
              {date && typeof date === 'string' && (
                <Text fontSize="sm" fontWeight="400">
                  Дата и время измерения:&nbsp;
                  {createLocalDateCustomFormat(date, 'DD.MM.YYYY, HH:mm')}
                </Text>
              )}
              {result ? (
                <>
                  <Text fontSize="sm" fontWeight="400">
                    Номер источника:&nbsp;
                    {result[0]?.sourceCode}
                  </Text>
                  <Text fontSize="sm" fontWeight="400">
                    Наименование источника:&nbsp;
                    {result[0]?.sourceName}
                  </Text>
                  <Text fontSize="sm" fontWeight="400">
                    Наименование параметра:&nbsp;
                    {result[0]?.parameterName}
                  </Text>
                  <Text fontSize="sm" fontWeight="400">
                    Единица измерения:&nbsp;
                    {result[0]?.unitName}
                  </Text>
                  <Text fontSize="sm" fontWeight="400">
                    Наименование подсистемы:&nbsp;
                    {result[0]?.subsystemName}
                  </Text>
                  <Text fontSize="sm" fontWeight="400">
                    Наименование модуля:&nbsp;
                    {result[0]?.moduleName}
                  </Text>
                </>
              ) : null}
            </Flex>
            <Flex marginLeft="auto">
              {_result[0]?.operation === 'delete' ? (
                <Text
                  cursor="pointer"
                  color="PrimaryButton.500"
                  fontSize="md"
                  onClick={previewColumns ? restorePreview : restore}
                >
                  Восстановить
                </Text>
              ) : (
                <>
                  <Button
                    disabled={_result.length < 2}
                    cursor="pointer"
                    color="PrimaryButton.500"
                    fontSize="md"
                    onClick={reset}
                  >
                    {protocolOperations.reset}
                  </Button>
                  <Button
                    disabled={_result.length < 2}
                    cursor="pointer"
                    color="PrimaryButton.500"
                    fontSize="md"
                    marginLeft={4}
                    onClick={undo}
                  >
                    {protocolOperations.undo}
                  </Button>
                </>
              )}
            </Flex>
          </Flex>
        )}
      </Box>
    ),
    [_result, additionalData, date, isLoading, reset, result, undo, restorePreview, restore, previewColumns],
  );

  if (preview && previewColumns) {
    return (
      <Modal
        isOpen
        header="Предварительный просмотр восстановления удаленных записей"
        onClose={() => setPreview(null)}
        closeButtonPosition="contentTop"
      >
        <Box minW={700} maxW="80vw">
          <Flex my={2} gap={4}>
            <Button onClick={restore}>Применить</Button>
            <Button onClick={() => setPreview(null)}>Отмена</Button>
          </Flex>

          <MaterialTable
            options={{
              selection: false,
            }}
            isLoading={isLoading || isPostLoading}
            columns={previewColumns}
            data={preview}
          />
        </Box>
      </Modal>
    );
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} header={renderHeader} closeButtonPosition="contentTop">
      <Box minW={700} maxW="80vw">
        <MaterialTable isLoading={isLoading || isPostLoading} columns={columns} data={_result} />
      </Box>
    </Modal>
  );
}

export const restorePreviewColumns: Record<string, Column<any>[]> = {
  askza: [
    {
      title: 'Версия',
      field: 'valueVersion',
      width: 40,
    },
    {
      title: 'Дата и время удаления',
      field: 'editDate',
      render: ({ deletedDateTime }: { deletedDateTime: string }) =>
        (deletedDateTime ? createLocalDateCustomFormat(deletedDateTime, 'HH:mm, DD.MM.YYYY') : '-'),
      width: 200,
    },
    {
      title: 'Станция',
      field: 'stationName',
    },
    {
      title: 'Параметр',
      field: 'parameterName',
    },
    {
      title: 'Тип значения',
      field: 'parameterType',
    },
    {
      title: 'Значение',
      field: 'value',
    },
  ],
  water: [
    {
      title: 'Версия',
      field: 'valueVersion',
      width: 40,
    },
    {
      title: 'Дата и время удаления',
      field: 'editDate',
      render: ({ deletedDateTime }: { deletedDateTime: string }) =>
        (deletedDateTime ? createLocalDateCustomFormat(deletedDateTime, 'HH:mm, DD.MM.YYYY') : '-'),
      width: 200,
    },
    {
      title: 'Станция',
      field: 'stationName',
    },
    {
      title: 'Параметр',
      field: 'parameterName',
    },
    {
      title: 'Значение',
      field: 'value',
    },
  ],
  meteoProfilemer: [
    {
      title: 'Версия',
      field: 'valueVersion',
      width: 40,
    },
    {
      title: 'Дата и время удаления',
      field: 'editDate',
      render: ({ deletedDateTime }: { deletedDateTime: string }) =>
        (deletedDateTime ? createLocalDateCustomFormat(deletedDateTime, 'HH:mm, DD.MM.YYYY') : '-'),
      width: 200,
    },
    {
      title: 'Станция',
      field: 'stationName',
    },
    {
      title: 'Параметр',
      field: 'parameterName',
    },
    {
      title: 'Высота',
      field: 'height',
    },
    {
      title: 'Значение',
      field: 'value',
    },
  ],
  aisWeather: [
    {
      title: 'Версия',
      field: 'valueVersion',
      width: 40,
    },
    {
      title: 'Дата и время удаления',
      field: 'editDate',
      render: ({ deletedDateTime }: { deletedDateTime: string }) =>
        (deletedDateTime ? createLocalDateCustomFormat(deletedDateTime, 'HH:mm, DD.MM.YYYY') : '-'),
      width: 200,
    },
    {
      title: 'Дата и время значения',
      field: 'valueDateTime',
      render: ({ valueDateTime }: { valueDateTime: string }) =>
        (valueDateTime ? createLocalDateCustomFormat(valueDateTime, 'HH:mm, DD.MM.YYYY') : '-'),
      width: 200,
    },
    {
      title: 'Мачта',
      field: 'stationName',
    },
    {
      title: 'Высота',
      field: 'height',
    },
    {
      title: 'Параметр',
      field: 'parameterName',
    },
    {
      title: 'Значение',
      field: 'value',
    },
  ],
  forecast: [
    {
      title: 'Версия',
      field: 'valueVersion',
      width: 40,
    },
    {
      title: 'Дата и время удаления',
      field: 'editDate',
      render: ({ deletedDateTime }: { deletedDateTime: string }) =>
        (deletedDateTime ? createLocalDateCustomFormat(deletedDateTime, 'HH:mm, DD.MM.YYYY') : '-'),
      width: 200,
    },
    {
      title: 'Дата и время протокола',
      field: 'valueDateTime',
      render: ({ valueDateTime }: { valueDateTime: string }) =>
        (valueDateTime ? createLocalDateCustomFormat(valueDateTime, 'HH:mm, DD.MM.YYYY') : '-'),
      width: 200,
    },
    {
      title: 'Параметр',
      field: 'parameterName',
    },
    {
      title: 'Значение',
      field: 'value',
    },
  ],
  geoGroundwater: [
    {
      title: 'Версия',
      field: 'valueVersion',
      width: 40,
    },
    {
      title: 'Дата и время удаления',
      field: 'editDate',
      render: ({ deletedDateTime }: { deletedDateTime: string }) =>
        (deletedDateTime ? createLocalDateCustomFormat(deletedDateTime, 'HH:mm, DD.MM.YYYY') : '-'),
      width: 200,
    },
    {
      title: 'Дата и время значения',
      field: 'valueDateTime',
      render: ({ valueDateTime }: { valueDateTime: string }) =>
        (valueDateTime ? createLocalDateCustomFormat(valueDateTime, 'HH:mm, DD.MM.YYYY') : '-'),
      width: 200,
    },
    {
      title: 'Тип пункта наблюдения',
      field: 'sourceType',
    },
    {
      title: 'Пункт наблюдения',
      field: 'sourceCode',
    },
    {
      title: 'Параметр',
      field: 'parameterName',
    },
    {
      title: 'Значение',
      field: 'value',
    },
  ],
  geoDangerouseProcesses: [
    {
      title: 'Версия',
      field: 'valueVersion',
      width: 40,
    },
    {
      title: 'Дата и время удаления',
      field: 'editDate',
      render: ({ deletedDateTime }: { deletedDateTime: string }) =>
        (deletedDateTime ? createLocalDateCustomFormat(deletedDateTime, 'HH:mm, DD.MM.YYYY') : '-'),
      width: 200,
    },
    {
      title: 'Дата и время значения',
      field: 'valueDateTime',
      render: ({ valueDateTime }: { valueDateTime: string }) =>
        (valueDateTime ? createLocalDateCustomFormat(valueDateTime, 'HH:mm, DD.MM.YYYY') : '-'),
      width: 200,
    },
    {
      title: 'Участок',
      field: 'areaCode',
    },
    {
      title: 'Точка наблюдения',
      field: 'pointCode',
    },
    {
      title: 'Параметр',
      field: 'parameterName',
    },
    {
      title: 'Значение',
      field: 'value',
    },
  ],
  geoDangerouseProcessesInclinometers: [
    {
      title: 'Версия',
      field: 'valueVersion',
      width: 40,
    },
    {
      title: 'Дата и время удаления',
      field: 'editDate',
      render: ({ deletedDateTime }: { deletedDateTime: string }) =>
        (deletedDateTime ? createLocalDateCustomFormat(deletedDateTime, 'HH:mm, DD.MM.YYYY') : '-'),
      width: 200,
    },
    {
      title: 'Дата и время значения',
      field: 'valueDateTime',
      render: ({ valueDateTime }: { valueDateTime: string }) =>
        (valueDateTime ? createLocalDateCustomFormat(valueDateTime, 'HH:mm, DD.MM.YYYY') : '-'),
      width: 200,
    },
    {
      title: 'Участок',
      field: 'areaCode',
    },
    {
      title: 'Точка наблюдения',
      field: 'pointCode',
    },
    {
      title: 'Глубина пробы',
      field: 'probeHeightDepth',
    },
    {
      title: 'Параметр',
      field: 'parameterName',
    },
    {
      title: 'Значение',
      field: 'value',
    },
  ],
};
