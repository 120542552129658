import { SubsystemHeader } from 'components/common';
import React from 'react';
import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import { ReportList } from './ReportList';
import appLocale from 'constants/appLocale';
import { useHistory } from 'react-router';
import { FullPageWrapper } from '../../../components/table/FullPageWrapper';

export const Reports: React.FC = () => {
  useUpdateBreadcrumbs([
    { breadcrumb: appLocale.admin.main, path: '/admin' },
    {
      breadcrumb: appLocale.admin.reports,
      path: '/admin/report',
    },
  ]);

  const { push } = useHistory();

  return (
    <FullPageWrapper>
      <SubsystemHeader
        title="Управление отчетами"
        addButtonProps={{ onClick: () => push('/admin/report/add') }}
      />

      <ReportList />
    </FullPageWrapper>
  );
};
