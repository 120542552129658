import { ChangeEvent, useCallback, useState } from 'react';
import { Flex, FormControl, FormLabel, Heading, Radio, RadioGroup, Stack, Switch } from '@chakra-ui/react';
import { IChart } from '../../types';

interface IScaleYManage {
  chart: undefined | IChart;
}
export const AxisYManage = ({ chart }: IScaleYManage) => {
  const setAutoManageScale = useCallback(() => {
    const chartZoom = chart?.options?.plugins?.zoom?.zoom;
    if (chartZoom) {
      chartZoom.mode = 'x';
      chartZoom.overScaleMode = undefined;
    }
  }, [chart]);

  const setManualManageScale = useCallback(() => {
    const chartZoom = chart?.options?.plugins?.zoom?.zoom;
    if (chartZoom) {
      if (chartZoom.mode === 'x') {
        chartZoom.mode = 'xy';
      }
      chartZoom.overScaleMode = 'y';
    }
  }, [chart]);

  const setAll = useCallback(() => {
    const zoomPlugin = chart?.options?.plugins?.zoom;
    if (zoomPlugin?.pan) {
      if (zoomPlugin.pan.mode === 'x') {
        zoomPlugin.pan.mode = 'xy';
      }
      zoomPlugin.pan.overScaleMode = undefined;
    }
  }, [chart]);

  const setOne = useCallback(() => {
    const zoomPlugin = chart?.options?.plugins?.zoom;
    if (zoomPlugin?.pan) {
      if (zoomPlugin.pan.mode === 'x') {
        zoomPlugin.pan.mode = 'xy';
      }
      zoomPlugin.pan.overScaleMode = 'y';
    }
  }, [chart]);

  const setNone = useCallback(() => {
    const zoomPlugin = chart?.options?.plugins?.zoom;
    if (zoomPlugin?.pan) {
      if (zoomPlugin.pan.mode === 'y') {
        zoomPlugin.pan.enabled = false;
      }
      if (zoomPlugin.pan.mode === 'xy') {
        zoomPlugin.pan.mode = 'x';
      }
      zoomPlugin.pan.overScaleMode = undefined;
    }
  }, [chart]);

  const [isCheckedAutoZoom, setCheckedAutoZoom] = useState(true);

  const handleChangeZoom = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setCheckedAutoZoom(event.target.checked);
      if (event.target.checked) {
        setAutoManageScale();
      } else {
        setManualManageScale();
      }
    },
    [setAutoManageScale, setManualManageScale]
  );
  const [value, setValue] = useState<string>('none');

  const handleChangeRadio = useCallback((nextValue: string) => {
    setValue(nextValue);
    if (nextValue === 'none') setNone();
    if (nextValue === 'all') setAll();
    if (nextValue === 'one') setOne();
  }, [setAll, setNone, setOne]);
  return (
    <>
      <Flex flexDirection="row" alignItems="center" flexWrap="wrap" mt={5}>
        <Heading size="xs" width="100%" mb={1}>
          Перемещение
        </Heading>
        <RadioGroup onChange={handleChangeRadio} value={value}>
          <Stack direction="row">
            <Radio value="none">Блокировать перемещение</Radio>
            <Radio value="all">Перемещать сразу все оси</Radio>
            <Radio value="one">Перемещать оси по одной</Radio>
          </Stack>
        </RadioGroup>
        <Heading size="xs" width="100%" mb={1} mt={3}>
          Зум
        </Heading>
        <FormControl display="flex" alignItems="center" mb={3}>
          <FormLabel htmlFor="pan" mb="0">
            Автоматическое масштабирование (в случае отключения управление ведется скролом над осями)
          </FormLabel>
          <Switch isChecked={isCheckedAutoZoom} id="pan" onChange={handleChangeZoom} />
        </FormControl>

      </Flex>
    </>
  );
};
