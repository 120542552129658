import { useHistory } from 'react-router-dom';
import { useCallback, useMemo } from 'react';

export function useGoBackWithFallback() {
  const { push, goBack, length } = useHistory();

  // TODO: improve condition
  const canGoBack = useMemo(() => length > 1, [length]);

  const backWithFallback = useCallback((fallback?: string) => {
    if (canGoBack) {
      goBack();
      return;
    }

    if (fallback) {
      push(fallback);
    }
  }, [goBack, canGoBack, push]);

  return { backWithFallback };
}
