import { BottomSoilApiPaths } from 'pages/data/Water/BottomSoil/consts/apiPaths';
import { apiPathSurfaceWater } from 'pages/data/Water/SurfaceWater/apiPath';
import subsystemIdsMap from 'constants/subsystemIdsMap';

export const ANALYTICS_LAB_PATHS = {
  reagents: {
    snow: {
      add: '/analytics/reagents/snow/add',
      edit: '/analytics/reagents/snow/edit',
    },
    soil: {
      add: '/analytics/reagents/soil/add',
      edit: '/analytics/reagents/soil/edit',
    },
    washout: {
      add: '/analytics/reagents/washout/add',
      edit: '/analytics/reagents/washout/edit',
    },
    vegetation: {
      add: '/analytics/reagents/vegetation/add',
      edit: '/analytics/reagents/vegetation/edit',
    },
  },
  water: {
    bottomSoil: {
      add: '/analytics/water/bottom-soil/add',
      edit: '/analytics/water/bottom-soil/edit',
    },
    surfaceWaters: {
      add: '/analytics/water/surface-waters/add',
      edit: '/analytics/water/surface-waters/edit',
    },
  },
  raids: {
    pel: {
      add: '/analytics/raids/pel/add',
      edit: '/analytics/raids/pel/edit',
    },
    ai: {
      add: '/analytics/raids/ai/add',
      edit: '/analytics/raids/ai/edit',
    },
    pelNoise: {
      add: '/analytics/raids/pel-noise/add',
      edit: '/analytics/raids/pel-noise/edit',
    },
  },
};

export enum SubsystemName {
  'reagentsSnow',
  'reagentsSoil',
  'reagentsVegetation',
  'reagentsWashout',
  'waterBottomSoil',
  'waterSurfaceWater',
  'pelNoise',
  'pelAtmosphere',
}

export const getSubsystemNameById = (id: string): SubsystemName => {
  const map: Record<string, SubsystemName> = {
    [subsystemIdsMap.pgrSnow]: SubsystemName.reagentsSnow,
    [subsystemIdsMap.pgrSoil]: SubsystemName.reagentsSoil,
    [subsystemIdsMap.pgrVegetative]: SubsystemName.reagentsVegetation,
    [subsystemIdsMap.pgrWashouts]: SubsystemName.reagentsWashout,
    '0908c0ec-6353-4b7d-83d7-39d6c04cb8a4': SubsystemName.waterSurfaceWater,
    '7760b8a1-8759-4d3e-83a5-82236a1abc44': SubsystemName.waterBottomSoil,
    'ec2d0631-3b60-4f44-9abb-21acd1362cf6': SubsystemName.pelNoise,
    '255fbe02-0543-4b86-b62a-3cbbb6c44710': SubsystemName.pelAtmosphere,
  };

  if (!map[id]) {
    console.error(`no subsystem map for ${id}`);
  }

  return map[id];
};

interface TableActionsPaths {
  delete: (id: string) => string;
  edit: (id: string) => string;
  download: (id: string) => string;
}

export const getPathsBySubsystemName = (name: SubsystemName): TableActionsPaths | null => {
  const templatePath = '/reporting/v10/templates/fill';

  const paths: Record<SubsystemName, TableActionsPaths> = {
    [SubsystemName.reagentsSnow]: {
      delete: (id: string) => `/deicing/v10/deicing/snow/${id}`,
      edit: (id: string) => `${ANALYTICS_LAB_PATHS.reagents.snow.edit}/${id}`,
      download: (id: string) => `${templatePath}/snow/${id}`
    },
    [SubsystemName.reagentsSoil]: {
      delete: (id: string) => `/deicing/v10/deicing/soil/${id}`,
      edit: (id: string) => `${ANALYTICS_LAB_PATHS.reagents.soil.edit}/${id}`,
      download: (id: string) => `${templatePath}/soil/${id}`
    },
    [SubsystemName.reagentsVegetation]: {
      delete: (id: string) => `/deicing/v10/deicing/vegetative/${id}`,
      edit: (id: string) => `${ANALYTICS_LAB_PATHS.reagents.vegetation.edit}/${id}`,
      download: (id: string) => `${templatePath}/vegetative/${id}`
    },
    [SubsystemName.reagentsWashout]: {
      delete: (id: string) => `/deicing/v10/deicing/washout/${id}`,
      edit: (id: string) => `${ANALYTICS_LAB_PATHS.reagents.washout.edit}/${id}`,
      download: (id: string) => `${templatePath}/washout/${id}`
    },
    [SubsystemName.waterSurfaceWater]: {
      delete: (id: string) => `${apiPathSurfaceWater.root}/${id}`,
      edit: (id: string) => `${ANALYTICS_LAB_PATHS.water.surfaceWaters.edit}/${id}`,
      download: (id: string) => `${templatePath}/surfacewater/${id}`
    },
    [SubsystemName.waterBottomSoil]: {
      delete: (id: string) => BottomSoilApiPaths.getOrDelete(id),
      edit: (id: string) => `${ANALYTICS_LAB_PATHS.water.bottomSoil.edit}/${id}`,
      download: (id: string) => `${templatePath}/bottomsoil/${id}`
    },
    [SubsystemName.pelNoise]: {
      delete: (id: string) => `/appeal/v10/protocols/${id}`,
      edit: (id: string) => `/analytics/raids/pel-noise/edit/${id}`,
      download: (id: string) => `${templatePath}/pelNoise/${id}`
    },
    [SubsystemName.pelAtmosphere]: {
      delete: (id: string) => `/appeal/v10/protocols/${id}`,
      edit: (id: string) => `/analytics/raids/pel/edit/${id}`,
      download: (id: string) => `${templatePath}/pelAtmosphere/${id}`
    },
  };

  if (!paths[name]) {
    console.error(`no subsystem routes for ${name}`);
  }

  return paths[name] || null;
};

export const getPathsBySubsystemId = (id: string) => getPathsBySubsystemName(getSubsystemNameById(id));

export const getSubsystemHumanReadableName = (name: SubsystemName): string => {
  const paths: Record<SubsystemName, string> = {
    [SubsystemName.reagentsSnow]: 'воздействие ПГР на снег',
    [SubsystemName.reagentsSoil]: 'воздействие ПГР на почву',
    [SubsystemName.reagentsVegetation]: 'воздействие ПГР на вегетативные части',
    [SubsystemName.reagentsWashout]: 'воздействие ПГР на смывы',
    [SubsystemName.waterSurfaceWater]: 'Поверхностные воды',
    [SubsystemName.waterBottomSoil]: 'Донный грунт',
    [SubsystemName.pelNoise]: 'ПЭЛ - Шум',
    [SubsystemName.pelAtmosphere]: 'ПЭЛ - Воздух',
  };

  if (!paths[name]) {
    console.error(`no subsystem routes for ${name}`);
  }

  return paths[name];
};

export const getSubsystemHumanReadableNameById =
  (id: string) => getSubsystemHumanReadableName(getSubsystemNameById(id));
