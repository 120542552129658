import { NSIRootPath } from 'pages/dictionaries/const/routes';

export const NSIWaterApiPath = '/nsi/v10/sources/water';

export const NSIWaterRootPath = `${NSIRootPath}/water`;

const NSIWaterSitesRoot = `${NSIWaterRootPath}/sites`;

export const NSIWaterSitesPaths = {
  root: NSIWaterSitesRoot,
  add: `${NSIWaterSitesRoot}/add`,
  edit: (id: string) => `${NSIWaterSitesRoot}/edit/${id}`,
};

const NSIWaterGatesRoot = `${NSIWaterRootPath}/gates`;

export const NSIWaterGatesPaths = {
  root: NSIWaterGatesRoot,
  add: `${NSIWaterGatesRoot}/add`,
  edit: (id: string) => `${NSIWaterGatesRoot}/edit/${id}`,
};

const NSIWaterReservoirsRoot = `${NSIWaterRootPath}/reservoirs`;

export const NSIWaterReservoirsPaths = {
  root: NSIWaterReservoirsRoot,
  add: `${NSIWaterReservoirsRoot}/add`,
  edit: (id: string) => `${NSIWaterReservoirsRoot}/edit/${id}`,
};
