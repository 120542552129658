import React from 'react';
import * as yup from 'yup';
import { createTouchedErrors } from 'features/create-touched-errors';
import { FormikProvider, useFormik } from 'formik';
import { DeviceVerification } from 'pages/equipment/Devices/types';
import { FormFields } from 'pages/equipment/Devices/components/VerificationForm/FormFields';
import { requiredError } from 'models/schemas/constans';

export const validationSchema = yup.object().shape({
  name: yup.string().nullable().required(requiredError),
  date: yup.string().nullable().required(requiredError),
  dateStart: yup.string().nullable().required(requiredError),
  dateExpired: yup.string().nullable().required(requiredError),
});

interface VerificationFormProps {
  initialValues: Partial<DeviceVerification>;
  onSubmit: (values: Partial<DeviceVerification>) => void;
  onCancel: () => void;
}

export const VerificationForm = ({ initialValues, onSubmit, onCancel }: VerificationFormProps) => {
  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    validationSchema,
    initialValues,
    onSubmit,
  });

  return (
    <FormikProvider
      value={{
        ...formik,
        errors: createTouchedErrors(formik.errors, formik.touched),
      }}
    >
      <FormFields onCancel={onCancel} />
    </FormikProvider>
  );
};
