import { Grid } from '@chakra-ui/react';
import { Field, FormikContextType, FormikErrors, FormikValues, useFormikContext } from 'formik';
import { Input, Select } from 'components/common';
import React from 'react';
import { TDictionaryItemDictionary } from '../../../../../../models';
import { AsyncAddressSelect } from 'components/common/AsyncAddressSelect/AsyncAddressSelect';

interface IProps {
  districts: TDictionaryItemDictionary[];
  regions: TDictionaryItemDictionary[];
}

export const Location:React.FC<IProps> = ({ districts, regions }) => {
  const { errors }: FormikContextType<FormikErrors<FormikValues>> = useFormikContext();
  return (
    <Grid gap={2} templateColumns="repeat(1, 450px)">
      <Field as={Select} name="rayonId" label="Район" options={districts} error={errors?.rayonId} />
      <Field as={Select} name="okrugId" label="Округ" options={regions} error={errors?.okrugId} />
      <Field as={Input} name="landmark" label="Адресный ориентир" />
      <Field as={AsyncAddressSelect} name="address" label="Адрес" />
      <Field as={Input} name="latitude" label="Широта" />
      <Field as={Input} label="Долгота" name="longitude" />
    </Grid>
  );
};
