import { Flex } from '@chakra-ui/react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useLocation } from 'react-router';

const useStyles = makeStyles(() => ({
  root: {
    flexDirection: 'column',
    '@media print': {
      height: 'initial'
    },
    position: 'sticky'
  },
}));

export const FullPageWrapper: React.FC = ({ children }) => {
  const classes = useStyles();
  const [scrollPosition, setScrollPosition] = useState(0);
  const location = useLocation();

  const handleScroll = useCallback(() => {
    const position = window.scrollY;
    setScrollPosition(position);
  }, []);

  useEffect(() => {
    handleScroll();
  }, [location, handleScroll]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  const offset = useMemo(() => (scrollPosition - 60 < 0 ? scrollPosition - 60 : 0), [scrollPosition]);

  return (
    <Flex
      className={classes.root}
      height={`calc(100vh + ${offset}px)`}
      top={`${offset}px`}
    >
      {children}
    </Flex>
  );
};
