import { FormWrapper, Input } from 'components/common';
import { Field, useFormikContext } from 'formik';
import React, { FC } from 'react';
import { NSIEmissionsListItem } from 'pages/dictionaries/NSIEmissions/consts/types';
import { Box, Grid } from '@chakra-ui/react';
import isRequiredInSchema from 'lib/utils/forms/isReqiuredInSchema';
import { NsiEmissionsSourcesSchema } from 'models/schemas/nsiEmissions/nsiEmissionsSources';

export const LocationTab: FC = ({ children }) => {
  const { errors } = useFormikContext<NSIEmissionsListItem>();

  return (
    <Box flexDirection="column">
      <FormWrapper>
        <Grid marginTop="30px" gap="15px" marginBottom={20}>
          <Field
            as={Input}
            name="landmark"
            label="Адресный ориентир"
            error={errors.landmark}
            isRequired={isRequiredInSchema(NsiEmissionsSourcesSchema, 'landmark')}
          />
          <Field
            as={Input}
            name="latitude"
            label="Широта"
            error={errors.latitude}
            isRequired={isRequiredInSchema(NsiEmissionsSourcesSchema, 'latitude')}
          />
          <Field
            as={Input}
            name="longitude"
            label="Долгота"
            error={errors.longitude}
            isRequired={isRequiredInSchema(NsiEmissionsSourcesSchema, 'longitude')}
          />
          {children}
        </Grid>
      </FormWrapper>
    </Box>
  );
};
