import { useRequest } from 'hooks/useRequest';
import { useCallback, useEffect } from 'react';
import { DeviceVerification } from 'pages/equipment/Devices/types';

function useGetRequest<T>(url: string) {
  const { get, isLoading, result, error } = useRequest<T>(url);
  useEffect(() => {
    get();
  }, [get]);

  const refetch = useCallback(() => {
    get();
  }, [get]);

  return {
    isLoading,
    result,
    error,
    refetch,
  };
}

interface UseDeviceResult {
  data: DeviceVerification[];
  isLoading: boolean;
  refetch: () => void;
}

export function useDeviceVerifications(deviceId: string): UseDeviceResult {
  const {
    result,
    isLoading,
    refetch,
  } = useGetRequest<DeviceVerification[]>(
    `/equipment/v10/equipments/verifications/device/${deviceId}`,
  );

  return {
    data: result ?? [],
    isLoading,
    refetch
  };
}
