import { TFilterOperations } from 'models';
import { ProtocolOperation } from 'models/protocol/protocol';

export const protocolOperations: Record<ProtocolOperation, string> = {
  set: 'Приравнять',
  minus: 'Вычесть',
  plus: 'Прибавить',
  init: 'Инициализация',
  multiply: 'Умножение',
  drift: 'Дрейф',
  divide: 'Деление',
  reset: 'Вернуть исходное значение',
  undo: 'Отменить последнюю операцию',
  delete: 'Удаление',
  restore: 'Отмена удаления',
};

const filterOperationsLocale: {
  long: Record<TFilterOperations, string>;
  short: Record<TFilterOperations, string>;
} = {
  long: {
    EQ: 'Равно',
    NE: 'Не равно',
    GT: 'Больше',
    LT: 'Меньше',
    GTE: 'Больше или равно',
    LTE: 'Меньше или равно',
    BETWEEN: 'В интервале',
    NOT_BETWEEN: 'Не в интервале',
    CONTAINS: 'Содержит',
    NOT_CONTAINS: 'Не содержит',
    LIKE: '',
    STARTS_WITH: '',
    ENDS_WITH: '',
    IN: '',
    NOT_IN: '',
  },
  short: {
    EQ: '=',
    NE: '!=',
    GT: '>',
    LT: '<',
    GTE: '>=',
    LTE: '<=',
    BETWEEN: 'В интервале',
    NOT_BETWEEN: 'Не в интервале',
    CONTAINS: 'Содержит',
    NOT_CONTAINS: 'Не содержит',
    LIKE: '',
    STARTS_WITH: '',
    ENDS_WITH: '',
    IN: '',
    NOT_IN: '',
  },
};

const appLocale = {
  common: {
    data: 'Данные',
    attachments: 'Вложения',
    generalInformation: 'Общие сведения',
    indicators: 'Показатели',
    links: 'Примечания',
    from: 'От',
    to: 'До',
    area: 'Округ',
    district: 'Район',
    status: 'Статус',
    region: 'Округ',
    operations: filterOperationsLocale,
    dataProtocol: 'Протокол обработки данных',
    protocolOperations,
    showDeleted: 'Показать удаленные',
    showOnlyChangedRows: 'Только скорректированные строки',
    location: 'Местоположение',
    chimney: 'Дымоход',
    signatories: 'Подписанты',
    areaCode: 'Код участка',
  },
  actions: {
    save: 'Сохранить',
    addFiles: 'Добавить файлы',
    next: 'Далее',
    cancel: 'Отмена',
    addRecord: 'Добавить запись',
    uploadFromFile: 'Загрузить из файла',
    preview: 'Просмотр',
    clear: 'Очистить',
    generate: 'Сформировать',
    upload: 'Загрузить'
  },
  subsystems: {
    askza: 'АСКЗА',
    statements: 'Ведомости',
    platforms: 'Площадки',
    geoecologicalprocesses: 'Геоэкологические процессы',
    greenplants: 'Зеленые насаждения',
    greenPlantsData: 'Данные по зеленым насаждениям',
    greenPlantsAreas: 'Площадки',
    masksh: 'М-АСКШ',
    meteo: 'Метео',
    subsoil: 'Недропользование',
    reagents: 'ПГР',
    soil: 'Мониторинг состояния почв',
    nsiSoil: 'Почва',
    atmosphericAir: 'Атмосферный воздух',
    greenspaces: 'Зеленые насаждения',
    greenspacesGrass: 'Газоны',
    greenspacesTree: 'Деревья/Кустарники',
    greenspacesPlatforms: 'Ведомости площадок',
    analyticsLab: 'Аналитическая лаборатория',
    analyticsReports: 'Аналитическая отчетность',
    water: 'Водные объекты',
    cartography: 'Картографическая информация',
    cartographyShort: 'Картография',
    bottomSoil: 'Донный грунт',
    wpz: 'Состояние дна, берегов и водоохранных зон',
    groundwater: 'Подземные воды',
    industrialEmissions: 'Мониторинг пром. выбросов',
    burningRegistrationJournal: 'Журнал регистрации факта сжигания',
    incidentRecording: 'Учет происшествий',
  },
  protocols: {
    titleShort: 'Протоколы обработки данных',
    title: 'Протоколы обработки данных (Каталог)',
    askza: 'Автоматическая станция контроля загрязнения воздуха',
    askzaShost: 'АСКЗА',
    askzv: 'Автоматическая станция контроля загрязнения воды',
    askzvShort: 'АСКЗВ',
  },
  mainPage: {
    reports: 'Отчеты',
    nsi: 'НСИ',
    handbookNSI: 'Справочники НСИ',
    common: 'Общие',
    admin: 'Администрирование',
    ftp: 'FTP',
    user_action_journal: 'Журнал действий пользователей',
  },
  equipmentPage: {
    equipment: 'Измерительные приборы',
    devices: 'Приборы',
    stations: 'Станции',
    stationsFilter: 'Наименование прибора - Серийный номер  - Инвентарный номер',
    models: 'Модели',
    reports: 'Отчеты',
    table: 'Таблицы',
    diagram: 'Диаграммы',
  },
  fileUploadModal: {
    file_upload_default_success: 'Ошибок не обнаружено.',
    file_upload_default_error: 'Обнаружены ошибки',
    file_validate_success: 'Ошибок не обнаружено. Файл валидный',
    file_validate_error: 'Обнаружены ошибки. Файл не валидный',
    file_apply_success: 'Ошибок не обнаружено. Файл загружен',
  },
  pgr: {
    effects_on_greenplants: 'Воздействие на зелёные насаждения',
  },
  fileUpload: {
    save_confirm_text: 'Для добавления файлов необходимо сохранить запись. Сохранить?',
  },
  analytics: {
    add_document_heading: 'Выберите вид исследования',
    probeDate: 'Дата отбора пробы',
    protocolNumber: 'Номер протокола',
    area: 'Округ',
    district: 'Район',
    subsystem: 'Подсистема',
    combinedCharts: {
      title: 'Совмещение графиков',
      askzaEmissions: {
        title: 'Совмещение данных АСКЗА с данными промвыбросов',
      },
      askzaMeteo: {
        title: 'График зависимости концентраций ЗВ от направления скорости ветра на высоте',
      },
      askzaProfilemer: {
        title: 'Совмещение данных с АСКЗА с данными профилемера',
      },
      askzaInversion: {
        title: 'График зависимости концентраций ЗВ от параметров инверсии температуры',
      },
      askzaPrecipitation: {
        title: 'График зависимости концентраций ЗВ от осадков',
      },
      profilemer: {
        title: 'Совмещение данных с АСКЗА с данными профилемера',
      },
    },
    fields: {
      actNumber: 'Акт отбора пробы №',
      address: 'Место отбора пробы (адрес)',
      consumerId: 'Заказчик',
      contractorId: 'Измерения проведены',
      customerId: 'Заказчик',
      depthId: 'Глубина отбора пробы',
      distance2RoadId: 'Расстояние от дороги',
      date: 'Дата/время отбора пробы',
      document: 'Нормативный документ на метод отбора пробы',
      explanation: 'Дополнительная информация',
      explanationFromConsumer: 'Дополнительная информация от Заказчика',
      extraInfoValue: 'Дополнительная информация',
      laboratory: 'Аналитическая Лаборатория',
      latitude: 'Широта',
      longitude: 'Долгота',
      methodology: 'Нормативный документ на метод отбора пробы',
      organizationId: 'Отбор пробы выполнен',
      protocol: 'Номер протокола',
      protocolDate: 'Дата протокола',
      protocolNumber: 'Номер протокола',
      researchObject: 'Объект исследований',
      researchBasis: 'Основание для проведения исследований',
      researchDateEnd: 'Дата окончания исследований',
      researchDateStart: 'Дата начала исследований',
      researchStartDatetime: 'Дата и время начала измерений',
      researchEndDatetime: 'Дата и время окончания измерений',
      sampleAct: 'Акт отбора пробы №',
      sampleActNumber: 'Акт отбора проб атмосферного воздуха №',
      sampleAddress: 'Место проведения измерений',
      sampleDate: 'Дата/время получения пробы',
      sampleLaboratoryNumber: 'Регистрационный номер пробы в лаборатории',
      sampleNumber: 'Шифр пробы',
      samplePeriodEnd: 'Дата/время окончания отбора проб',
      samplePeriodStart: 'Дата/время начала отбора проб',
      samplePlace: 'Точка отбора пробы',
      sampleReceiveDate: 'Дата/время получения пробы',
      sampleRegNumber: 'Регистрационный номер',
      sampleRegistryNumber: 'Регистрационный номер пробы',
      selectionDate: 'Дата/время отбора пробы',
      sourceNameId: 'Площадка',
      status: 'Статус',
      supportingDocuments: 'Сопроводительная документация',
      sampleStartDatetime: 'Дата и время начала отбора пробы',
      sampleEndDatetime: 'Дата и время окончания отбора пробы',
      sampleReceiveDatetime: 'Дата/время доставки пробы',
      waterObjectId: 'Водный объект',
      sourceId: 'Створ наблюдения',
    },
    veg: {
      fields: {
        sourceNameId: 'Место отбора пробы (адрес)',
        distance2RoadId: 'Точка отбора пробы',
      },
    },
    washout: {
      fields: {
        areaId: 'Место отбора пробы (адрес)',
        pointId: 'Точка отбора пробы',
      },
    },
    pel: {
      fields: {
        samplePlace: 'Точка проведения измерений',
      },
    },
    pelAi: {
      fields: {
        samplePlace: 'Точка отбора пробы',
        contractorId: 'Отбор пробы выполнен',
        address: 'Место отбора пробы',
        researchStartDatetime: 'Дата начала исследований',
        researchEndDatetime: 'Дата окончания исследований',
      },
    },
    bottomSoil: {
      fields: {
        sampleDate: 'Дата/время отбора пробы',
      },
    },
    pgrSnow: {
      fields: {
        sampleLaboratoryNumber: 'Регистрационный номер пробы',
        areaId: 'Место отбора пробы (адрес)',
        pointId: 'Точка отбора пробы',
      },
    },
  },
  admin: {
    main: 'Администрирование',
    journal: 'Журнал действий пользователей',
    department: 'Управление отделами',
    periods: 'Управление периодами',
    reports: 'Управление отчетами',
    rights: 'Управление привилегиями',
    roles: 'Управление ролями',
    users: 'Управление пользователями',
    ftp: 'FTP',
    ftpSettings: 'Настройки FTP',
    ftpProtocolSettings: 'Настройки обмена данными по FTP-протоколу',
    ftpServers: 'FTP-серверы',
    ftpCatalogs: 'Каталоги',
    ftpDataPatterns: 'Шаблоны данных',
    apiToken: 'API токены',
    ftpUpload: 'Выгрузка данных на FTP',
    EXD: 'ЕХД',
    dataEntryLog: 'Журнал поступления данных от измерительных станций',
    notification: 'Настройки уведомлений',
    noticeLog: 'Журнал уведомлений',
    NODUploadLog: 'Журнал выгрузки НОД',
    passwordRequests: 'Запросы на смену пароля',
    passwordComplexitySettings: 'Настройки сложности паролей',
  },
  bottomSoil: {
    title: 'Мониторинг состояния донного грунта',
    consumer: 'Заказчик',
    organization: 'Организация (отбор пробы выполнен)',
    waterObject: 'Водный объект',
    protocolNumber: 'Номер протокола',
    protocolDate: 'Дата протокола',
    sampleDate: 'Дата отбора пробы',
    source: 'Створ наблюдения',
    organizations: 'Организация',
    addProtocolResults: 'Добавление результатов протокола',
    editProtocolResults: 'Редактирование результатов протокола',
    coordinates: 'Координаты измерения',
    latitude: 'Широта',
    longitude: 'Долгота',

    chrome: 'Хром, подвижная форма, мг/кг',
    copper: 'Медь, подвижная форма, мг/кг',
    lead: 'Свинец, подвижная форма, мг/кг',
    manganese: 'Марганец, подвижная форма, мг/кг',
    mercury: 'Ртуть, валовое содержание, мг/кг',
    nickel: 'Никель, подвижная форма, мг/кг',
    organicSubstance: 'Органическое вещество, %',
    petrochemicals: 'Нефтепродукты, мг/кг',
    zinc: 'Цинк, подвижная форма, мг/кг',
    phIndex: 'Водородный показатель, ед. рН',
    district: 'Район',
    region: 'Округ',
    oopt: 'ООПТ',
  },
  reports: {
    main: 'Отчеты',
    dataUpload: 'Выгрузка данных по 1 станции',
    statisticalCharacteristics: ' Статистические характеристики по 1 станции',
    complexityFactor: 'Расчет коэффициента комплексности загрязненности (УКИЗВ)',
    urbanAreasSurvey: 'Дендро-фитопатологическое обследование городских насаждений',
    greenPlants3: 'Состояние зеленых насаждений по 3 балльной шкале',
    greenPlants6: 'Состояние зеленых насаждений по 6 балльной шкале',
    greenPlantsState: 'Динамика состояния зеленых насаждений',
    greenPlantsKind: 'Видовой состав зеленых насаждений',
    greenPlantsAge: 'Возрастной состав зеленых насаждений',
    lawnsReport: 'Состояние газонов на 130 ППН',
    greenPlantsDecor: 'Декоративность зеленых насаждений',
    greenPlantsTrees: 'Аварийные деревья',
    diseaseReport: 'Болезни',
    emergencyAreas: 'Количество площадок с аварийными деревьями',
    pests: 'Вредители',
    districtPests: 'Вредители по округам',
    districtDiseases: 'Болезни по округам',
  },
  wpz: {
    title: 'Водоохранные зоны',
    area: 'Участок',
    areaCode: 'Код участка',
    date: 'Дата обследования',
    filthiness: 'Степень захламления %',
    densityErosionalPattern: 'Густота эрозионной сети, км²',
    meadowedArea: 'Залуженные участки, км²',
    scrubArea: 'Участки под кустарниковой растительностью, км²',
    treeAndScrubArea: 'Участки под древесной и древесно-кустарниковой растительностью, км²',
    siteConditions: 'Оценка ВЗ',
    oopt: 'ООПТ',
    formTitle: 'Протокол обследования водоохранной зоны',
    addition_research_result: 'Добавление результатов обследования',
    edit_research_result: 'Редактирование результатов обследования',
  },
  groundCoast: {
    area: 'Участок',
    areaCode: 'Код участка',
    date: 'Дата обследования',
    title: 'Дно и берега',
    tabs: {
      foreignObjects: 'Инородные объекты',
    },
    dangerProcessDescriptionTitle: 'Опасные процессы на участке наблюдения',
    dangerProcessDescription: 'Описание опасного процесса',
    siteCondition: 'Состояние участка',
    garbageMaxSize: 'Габариты максимального объекта',
    foreignObjectsFound: 'Обнаруженные инородные объекты',
    coastlinePosition: 'Положение береговой линии',
    latitude: 'Координата X',
    longitude: 'Координата Y',
    altitude: 'Координата Z',
    groundMark: 'Отметка уровня дна',
    stvor: 'Створ наблюдения',
    oopt: 'ООПТ',
    siteConditions: 'Оценка ДБ',
    formTitle: 'Протокол обследования дна и берегов',
  },
  pel: {
    main: 'Рейды ПЭЛ',
    raidList: 'Журнал рейдов',
    atmosphereRaidList: 'Журнал рейдов Воздух',
    atmosphereReport: 'Отчет об исследовании загрязнений воздуха',
    noiseRaidList: 'Журнал рейдов Шум',
    noiseReport: 'Отчет об исследовании шумовой обстановки',
    formTitle: 'Протокол обследования дна и берегов',
  },
  analyticsReports: {
    admin: 'Администрирование',
    nod: 'НОД',
    airStations: 'Автоматические станции контроля загрязнения атмосферы',
    atmosphereData: 'Данные передвижной лаборатории контроля загрязнения атмосферного воздуха',
    soil: 'Состояние почв на основании суммарного показателя загрязнения',
    greenplants: 'Состояние зеленых насаждений (по данным наблюдения на контрольных площадках)',
    danger: 'Ориентировочная оценочная шкала опасности загрязнения почв по суммарному показателю загрязнения',
    actions: {
      makeReport: 'Сформировать отчет',
    },
    dataPublishing: 'Публикация данных',
    questionnaire: 'Опросник',
    noiseMonitoring: 'Результаты мониторинга уровней шума по данным передвижной экологической лаборатории',
    waterMonitoring: 'Среднемесячные концентрации анализируемых параметров в водных объектах',
    geoSpring: 'Состояние родников',
    waterObjects: 'Водные объекты',
    askzaMonthAvg: 'Среднемесячные показатели загрязнения атмосферного воздуха',
    waterObservation: 'Створы наблюдений',
    waterParameters: 'Параметры качества водных объектов',
    airQuality: 'Параметры загрязнения атмосферного воздуха',
    geoDangerProccess: 'Участки проявления оползневых процессов',
    journalNmu: 'Неблагоприятные метеорологические условия',
    SOKEnvironmentalJournal:
      'Отчет оперативного дежурного службы оперативного контроля об экологической обстановке в городе Москве за заданный период времени по установленной форме',
    SOKFireJournal: 'Отчет по выявленным при помощи видеокамер фактам сжигания'
  },
  manager: {
    main: 'ЛК Руководителя',
    title: 'Экологическая ситуация города Москвы',
  },
  nsi: {
    askza: {
      title: 'Размещение измерительных станций',
    },
    reagents: {
      title: 'Противогололедные реагенты',
      ground: {
        title: 'Вегетативные части/Смывы/Снег/Почвы',
      },
      lawns: {
        title: 'Газоны',
      },
      trees: {
        title: 'Деревья/Кустарники',
      },
      sites: {
        title: 'Площадки размещения',
      },
    },
    water: {
      title: 'Мониторинг водных объектов',
      shortTitle: 'Водные объекты',
      sites: {
        title: 'Участки наблюдения на водных объектах',
      },
      gates: {
        title: 'Створы наблюдения за водными объектами',
      },
      reservoirs: {
        title: 'Водные объекты',
      },
    },
    dangerScale: {
      title: 'Ориентировочная оценочная шкала опасности загрязнения почв по суммарному показателю загрязнения',
      shortTitle: 'Шкала опасности загрязнения почв',
      fields: {
        dutyScale: 'Категория загрязнения почв',
        dangerScale: 'Уровень загрязнения почв',
        range: 'Диапазон показателя загрязнения',
        rangeStart: 'Диапазон показателя загрязнения - От',
        rangeEnd: 'Диапазон показателя загрязнения - До',
        rangeStartInclusive: 'Включительно',
        rangeEndInclusive: 'Включительно',
      },
    },
    MASKSH: {
      title: 'М-АСКШ',
      permissibleNoiseLevel: {
        title: 'Допустимые уровни шума'
      }
    }
  },
  burningRegistrationJournal: {
    incidentDateTime: 'Дата',
    fixationStartDateTime: 'Время начала фиксации',
    fixationEndDateTime: 'Время окончания фиксации',
    cameraId: 'Номер камеры',
    address: 'Ориентировочный адрес источника',
    admAreaId: 'Округ',
    districtId: 'Район',
    identifyInfoId: 'Выявленная информация',
    actions: 'Результаты патрулирования местности',
    actionsOther: 'Прочие предпринятые меры',
    informationRecipient: 'Информация направлена',
    executorId: 'Ответственный исполнитель СОК УЭО',
    isRepeat: 'Повторный факт',
    type: 'Вид камеры',
    comment: 'Примечания',
    latitude: 'Широта',
    longitude: 'Долгота',
    cameraName: 'Название и район камеры',
  },
  incidentRecording: {
    incidentDateTime: 'Дата и время',
    address: 'Адрес',
    admAreaId: 'Округ',
    districtId: 'Район',
    incidentTypeId: 'Тип происшествия',
    sourceTypeId: 'Источник информации',
    actions: 'Предпринятые меры',
    description: 'Описание происшествия',
  },
  pdkExcessJournal: {
    fields: {
      date: 'Дата и время',
      sourceId: 'Источник',
      attractingAi: 'Привелечение сотрудников АИ',
      comment: 'Комментарий',
      attractingOther: 'Привлечение сотрудников других служб',
      otherReporting: 'Тип происшествия',
      areaId: 'Округ',
      description: 'Источник информации о происшествии',
      address: 'Адрес',
      stationId: 'Станция',
      additionParamId: 'Параметр',
    },
  },
};

export default appLocale;
