import { Button, FormWrapper, Input, Select } from 'components/common';
import { AddIcon, DeleteIcon } from 'components/icons';
import {
  getSelectOptionsFromDictionaryByName,
  getSelectOptionsFromDictionaryByNameWithTransformation,
} from 'features/get-select-options-from-dictionary';
import { Field, FieldArray, useFormikContext } from 'formik';
import { ParametersItem, QualityItem } from 'hooks/geo/Quality/useQualityList';
import { TDictionariesArray } from 'models/dictionaries';
import React, { FC, useMemo } from 'react';
import { Box, Flex, Grid } from '@chakra-ui/react';

interface ParametersTabProps {
  dictionaries: {
    data: TDictionariesArray;
  } | null;
  isWrite?: boolean;
}

export const ParametersTab: FC<ParametersTabProps> = ({ dictionaries, children, isWrite }) => {
  const { handleChange, setFieldValue, values, errors } = useFormikContext<QualityItem>();

  const parameterCodes = useMemo(
    () => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'parameter'),
    [dictionaries],
  );

  const parameterNames = useMemo(
    () => getSelectOptionsFromDictionaryByNameWithTransformation(dictionaries?.data, 'parameter', 'title'),
    [dictionaries],
  );

  return (
    <Box flexDirection="column">
      {/* <FormHeader
        url="/data/geoecological-processes/monitoring/water/quality"
        header="Добавление результатов обследования"
      >
        <TabList>
          {[appLocale.common.generalInformation, appLocale.common.indicators].map((tab, i) => {
            return (
              <DefaultText cursor="pointer" key={i}>
                {tab}
              </DefaultText>
            );
          })}
        </TabList>
      </FormHeader> */}
      <FormWrapper>
        <Grid marginTop="30px" gap="15px" marginBottom={20}>
          <FieldArray name="measures">
            {({ remove, push }) => (
              <Box flexDirection="column">
                {values.measures.length > 0 &&
                  values.measures.map((paramItem, index) => (
                    <Flex key={paramItem.id} marginBottom={30} alignItems="start">
                      <Grid gap="15px" flexGrow={1} marginRight={15}>
                        <Field
                          as={Select}
                          name={`measures.${index}.code`}
                          label="Код показателя"
                          value={values.measures[index].code}
                          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                            setFieldValue(`measures.${index}.name`, e.target.value);
                            setFieldValue(`measures.${index}.code`, e.target.value);
                            setFieldValue(`measures.${index}.paramId`, e.target.value);
                          }}
                          options={parameterCodes}
                          error={
                            errors &&
                            errors.measures &&
                            errors.measures[index] &&
                            (errors.measures[index] as ParametersItem).code
                          }
                        />
                        <Field
                          as={Select}
                          name={`measures.${index}.name`}
                          label="Показатель"
                          value={values.measures[index].name}
                          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                            setFieldValue(`measures.${index}.name`, e.target.value);
                            setFieldValue(`measures.${index}.code`, e.target.value);
                            setFieldValue(`measures.${index}.paramId`, e.target.value);
                          }}
                          options={parameterNames}
                          error={
                            errors &&
                            errors.measures &&
                            errors.measures[index] &&
                            (errors.measures[index] as ParametersItem).name
                          }
                        />
                        <Field
                          as={Input}
                          name={`measures.${index}.value`}
                          label="Значение"
                          value={values.measures[index].value}
                          onChange={handleChange}
                          error={
                            errors &&
                            errors.measures &&
                            errors.measures[index] &&
                            (errors.measures[index] as ParametersItem).value
                          }
                        />
                      </Grid>
                      {isWrite && (
                        <Button leftIcon={<DeleteIcon />} onClick={() => remove(index)} variant="ghost" size="sm" />
                      )}
                    </Flex>
                  ))}
                {isWrite && (
                  <Button
                    leftIcon={<AddIcon />}
                    onClick={() =>
                      push({
                        code: '',
                        name: '',
                        value: '',
                        id: null,
                        paramId: '',
                      })
                    }
                    variant="ghost"
                    size="sm"
                  >
                    Добавить значение
                  </Button>
                )}
              </Box>
            )}
          </FieldArray>
          {children}
        </Grid>
      </FormWrapper>
    </Box>
  );
};
