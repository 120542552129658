import { Route, Switch } from 'react-router-dom';
import { AnalyticsReportsList } from 'pages/analyticsReports/AnalyticsReportsList';
import { AnalyticsReportsNODList } from 'pages/analyticsReports/NOD/AnalyticsReportsNODList';
import NODSoil from 'pages/analyticsReports/NOD/NODSoil/NODSoil';
import NODGreenplants from 'pages/analyticsReports/NOD/NODGreenplants/NODGreenplants';
import NODDanger from 'pages/analyticsReports/NOD/NODDanger/NODDanger';
import { ReportList } from 'pages/analyticsReports/Reports/ReportList';
import { PGRReports } from 'pages/analyticsReports/Reports/PGRReoprts';
import { Questionnaire } from 'pages/analyticsReports/DataPublishing/Questionnaire';
import { DataPublishingList } from 'pages/analyticsReports/DataPublishing/DataPublishingList';
import { WaterReports } from 'pages/analyticsReports/Reports/WaterReports';
import { ASKZAEmissions, ASKZAMeteo, CombinedChartsList, Complaints } from 'pages/analyticsReports/CombinedCharts';
import { WaterShoreAndProtection } from 'pages/analyticsReports/Reports/WaterShoreAndProtection/WaterShoreAndProtection';
import ASKZAProfilemer from 'pages/analyticsReports/CombinedCharts/ASKZAProfilemer/ASKZAProfilemer';
import ASKZAInversion from 'pages/analyticsReports/CombinedCharts/ASKZAInversion/ASKZAInversion';
import ASKZAPrecipitation from 'pages/analyticsReports/CombinedCharts/ASKZAPrecipitation/ASKZAPrecipitation';
import { NODAtmpsphere } from 'pages/analyticsReports/NOD/NODAtmpsphere/NODAtmpsphere';
import { NODNoise } from 'pages/analyticsReports/NOD/NODNoise/NODNoise';
import { NODWaterConcentraion } from 'pages/analyticsReports/NOD/NODWaterConcentration/NODWaterConcentration';
import { NODAirStations } from 'pages/analyticsReports/NOD/NODAirStations/NODAirStations';
import { NODGeoSpring } from 'pages/analyticsReports/NOD/NODGeoSpring/NODGeoSpring';
import { NODWaterObjects } from 'pages/analyticsReports/NOD/NODWaterObjects/NODWaterObjects';
import { NODASKZAMonth } from 'pages/analyticsReports/NOD/NODASKZAMonth/NODASKZAMonth';
import { NODWaterObservation } from 'pages/analyticsReports/NOD/NODWaterObservation/NODWaterObservation';
import { NODWaterParameters } from 'pages/analyticsReports/NOD/NODWaterParameters/NODWaterParameters';
import { NODAirQuality } from 'pages/analyticsReports/NOD/NODAirQuality/NODAirQuality';
import { NODGeoDangerProcess } from 'pages/analyticsReports/NOD/NODGeoDangerProcess/NODGeoDangerProcess';
import { NODJournalNmu } from 'pages/analyticsReports/NOD/NODJournalNmu/NODJournalNmu';
import {
  AnalyticReportsAdminBreadcrumb,
  AnalyticReportsAppealsBreadcrumb,
  AnalyticReportsASKZABreadcrumb,
  AnalyticReportsASKZVBreadcrumb,
  AnalyticReportsBottomAndShoreBreadcrumb,
  AnalyticReportsBottomSoilBreadcrumb,
  AnalyticReportsBulletinsBreadcrumb,
  AnalyticReportsDutyOfficeBreadcrumb,
  AnalyticReportsEmissionsBreadcrumb,
  AnalyticReportsGreenplantsBreadcrumb,
  AnalyticReportsMASKSHBreadcrumb,
  AnalyticReportsMeteoBreadcrumb,
  AnalyticReportsPELBreadcrumb,
  AnalyticReportsPGRGreenplantsBreadcrumb,
  AnalyticReportsPGRSoilBreadcrumb,
  AnalyticReportsSnowBreadcrumb,
  AnalyticReportsSoilBreadcrumb,
  AnalyticReportsSurfaceWaterBreadcrumb,
  AnalyticReportsVegsBreadcrumb,
  AnalyticReportsWashoutsBreadcrumb,
  AnalyticReportsWaterProtectionZoneBreadcrumb,
} from '../analyticsReports/consts/Breadcrumbs';
import { GreenplantsReportList } from 'pages/analyticsReports/Reports/Greenplants/GreenplantsReportList';
import { SOKReportList } from 'pages/analyticsReports/Reports/SOKReportList/SOKReportList';
import SOKFireJournalReport from 'pages/analyticsReports/Reports/SOKReportList/SOKFireJournalReport/SOKFireJournalReport';
import { SOKEnvironmentalJournal } from '../analyticsReports/Reports/SOKReportList/SOKEnvironmentalJournal';
import { LineByLineView } from '../admin/NODUploadLog/LineByLineView';

export const AnalyticsReportsRoutes = () => (
  <Switch>
    <Route path="/reports-analytics" exact component={AnalyticsReportsList} />

    {/* совмещение графиков */}
    <Route path="/reports-analytics/chart-combination" exact component={CombinedChartsList} />
    <Route path="/reports-analytics/chart-combination/askza-emissions" exact component={ASKZAEmissions} />
    <Route path="/reports-analytics/chart-combination/askza-meteo" exact component={ASKZAMeteo} />
    <Route path="/reports-analytics/chart-combination/askza-profilimer" exact component={ASKZAProfilemer} />
    <Route path="/reports-analytics/chart-combination/askza-inversion" exact component={ASKZAInversion} />
    <Route path="/reports-analytics/chart-combination/askza-precipitation" exact component={ASKZAPrecipitation} />
    <Route path="/reports-analytics/chart-combination/complaints" exact component={Complaints} />

    {/* Администрирование */}
    <Route
      path="/reports-analytics/admin/:subsystemId"
      exact
      render={() => <ReportList breadcrumb={AnalyticReportsAdminBreadcrumb} />}
    />

    {/* Публикация данных */}
    <Route path="/reports-analytics/data-publishing" exact component={DataPublishingList} />
    <Route path="/reports-analytics/data-publishing/questionnaire" exact component={Questionnaire} />

    {/* АСКЗА */}
    <Route
      path="/reports-analytics/atmosphere/:subsystemId"
      exact
      render={() => <ReportList breadcrumb={AnalyticReportsASKZABreadcrumb} />}
    />

    {/* Бюллетени */}
    <Route
      path="/reports-analytics/bulletins/:subsystemId"
      exact
      render={() => <ReportList breadcrumb={AnalyticReportsBulletinsBreadcrumb} />}
    />

    {/* Водные объекты */}
    <Route path="/reports-analytics/water" exact component={WaterReports} />
    <Route
      path="/reports-analytics/water/askzv/:subsystemId"
      exact
      render={() => <ReportList breadcrumb={AnalyticReportsASKZVBreadcrumb} />}
    />
    <Route
      path="/reports-analytics/water/bottom-soil/:subsystemId"
      exact
      render={() => <ReportList breadcrumb={AnalyticReportsBottomSoilBreadcrumb} />}
    />
    <Route
      path="/reports-analytics/water/surface/:subsystemId"
      exact
      render={() => <ReportList breadcrumb={AnalyticReportsSurfaceWaterBreadcrumb} />}
    />
    <Route path="/reports-analytics/water/water-state/protections" exact component={WaterShoreAndProtection} />
    <Route
      path="/reports-analytics/water/water-state/protections/waterProtectionZone/:subsystemId"
      exact
      render={() => <ReportList breadcrumb={AnalyticReportsWaterProtectionZoneBreadcrumb} />}
    />
    <Route
      path="/reports-analytics/water/water-state/protections/bottomAndShore/:subsystemId"
      exact
      render={() => <ReportList breadcrumb={AnalyticReportsBottomAndShoreBreadcrumb} />}
    />

    {/* Зеленые насаждения */}
    <Route
      path="/reports-analytics/greenplants/:subsystemId"
      exact
      render={() => <GreenplantsReportList breadcrumb={AnalyticReportsGreenplantsBreadcrumb} />}
    />
    <Route
      path="/reports-analytics/greenplants/:subsystemId/state"
      exact
      render={() => <GreenplantsReportList showState breadcrumb={AnalyticReportsGreenplantsBreadcrumb} />}
    />

    {/* Личный кабинет оперативного дежурного СОК */}
    <Route
      path="/reports-analytics/duty-office/:subsystemId"
      exact
      render={() => <SOKReportList breadcrumb={AnalyticReportsDutyOfficeBreadcrumb} />}
    />

    <Route
      path="/reports-analytics/duty-office/:subsystemId/fire-journal"
      exact
      render={() => <SOKFireJournalReport />}
    />

    <Route
      path="/reports-analytics/duty-office/:subsystemId/SOKEnvironmentalJournal"
      exact
      render={() => <SOKEnvironmentalJournal />}
    />

    {/* М-АСКШ */}
    <Route
      path="/reports-analytics/masksh/:subsystemId"
      exact
      render={() => <ReportList breadcrumb={AnalyticReportsMASKSHBreadcrumb} />}
    />

    {/* Метео */}
    <Route
      path="/reports-analytics/meteo/:subsystemId"
      exact
      render={() => <ReportList breadcrumb={AnalyticReportsMeteoBreadcrumb} />}
    />

    {/* НОД */}
    <Route path="/reports-analytics/nod" exact component={AnalyticsReportsNODList} />
    <Route path="/reports-analytics/nod/soil" exact component={NODSoil} />
    <Route path="/reports-analytics/nod/greenplants" exact component={NODGreenplants} />
    <Route path="/reports-analytics/nod/danger" exact component={NODDanger} />
    <Route path="/reports-analytics/nod/atmosphere-data" exact component={NODAtmpsphere} />
    <Route path="/reports-analytics/nod/melnoise" exact component={NODNoise} />
    <Route path="/reports-analytics/nod/water-concentration" exact component={NODWaterConcentraion} />
    <Route path="/reports-analytics/nod/stations" exact component={NODAirStations} />
    <Route path="/reports-analytics/nod/geo-spring" exact component={NODGeoSpring} />
    <Route path="/reports-analytics/nod/water-objects" exact component={NODWaterObjects} />
    <Route path="/reports-analytics/nod/atmosphere-pollution" exact component={NODASKZAMonth} />
    <Route path="/reports-analytics/nod/water-observation-point" exact component={NODWaterObservation} />
    <Route path="/reports-analytics/nod/water-quality-parameters" exact component={NODWaterParameters} />
    <Route path="/reports-analytics/nod/air-quality-parameters" exact component={NODAirQuality} />
    <Route path="/reports-analytics/nod/geo-danger-proccess" exact component={NODGeoDangerProcess} />
    <Route path="/reports-analytics/nod/journal-nmu" exact component={NODJournalNmu} />
    <Route path="/reports-analytics/nod/:reportName/view/:id" exact component={LineByLineView} />

    {/* Метео */}
    <Route
      path="/reports-analytics/meteo/:subsystemId"
      exact
      render={() => <ReportList breadcrumb={AnalyticReportsMeteoBreadcrumb} />}
    />

    {/* ПГР */}
    <Route path="/reports-analytics/pgr" exact component={PGRReports} />
    <Route
      path="/reports-analytics/pgr/vegs/:subsystemId"
      exact
      render={() => <ReportList breadcrumb={AnalyticReportsVegsBreadcrumb} />}
    />
    <Route
      path="/reports-analytics/pgr/greenplants/:subsystemId"
      exact
      render={() => <ReportList breadcrumb={AnalyticReportsPGRGreenplantsBreadcrumb} />}
    />
    <Route
      path="/reports-analytics/pgr/soil/:subsystemId"
      exact
      render={() => <ReportList breadcrumb={AnalyticReportsPGRSoilBreadcrumb} />}
    />
    <Route
      path="/reports-analytics/pgr/washouts/:subsystemId"
      exact
      render={() => <ReportList breadcrumb={AnalyticReportsWashoutsBreadcrumb} />}
    />
    <Route
      path="/reports-analytics/pgr/snow/:subsystemId"
      exact
      render={() => <ReportList breadcrumb={AnalyticReportsSnowBreadcrumb} />}
    />

    {/* Почвы */}
    <Route
      path="/reports-analytics/soil/:subsystemId"
      exact
      render={() => <ReportList breadcrumb={AnalyticReportsSoilBreadcrumb} />}
    />

    {/* Промвыбросы */}
    <Route
      path="/reports-analytics/emissions/:subsystemId"
      exact
      render={() => <ReportList breadcrumb={AnalyticReportsEmissionsBreadcrumb} />}
    />

    {/* ПЭЛ */}
    <Route
      path="/reports-analytics/pel/:subsystemId"
      exact
      render={() => <ReportList breadcrumb={AnalyticReportsPELBreadcrumb} />}
    />

    {/* Учет обращений граждан */}
    <Route
      path="/reports-analytics/appeals/:subsystemId"
      exact
      render={() => <ReportList breadcrumb={AnalyticReportsAppealsBreadcrumb} />}
    />
  </Switch>
);
