import { RenderTree } from 'features/WithSideBar/types';
import appLocale from 'constants/appLocale';
import subsystemIdsMap from 'constants/subsystemIdsMap';
import { Soil } from '../../home/icons';

export const soilMenu: RenderTree = {
  id: '/data/soil',
  name: appLocale.subsystems.soil,
  url: '/data/soil',
  subsystemId: subsystemIdsMap.soilpollutionRoot,
  homepageIcon: <Soil />,
};
