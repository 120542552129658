import React, { useMemo, useState } from 'react';
import { SUCCESS_MESSAGES } from 'constants/messages';
import { useNotifications } from 'hooks/useNotifications';
import { useRequest } from 'hooks/useRequest';
import { useHistory, useParams } from 'react-router-dom';
import { useStation } from 'pages/equipment/Stations/hooks/useStation';
import { StationForm } from 'pages/equipment/Stations/components/StationForm';
import { StationLayout } from 'pages/equipment/Stations/components/StationLayout';
import { StationFormContainer } from 'pages/equipment/Stations/components/StationForm/StationFormContainer';
import { StationDevices } from 'pages/equipment/Stations/components/StationDevices';
import { useEquipmentStationsBreadcrumbs } from './hooks/useEquipmentStationsBreadcrumbs';
import { MaterialTable } from 'features/material-table/MaterialTableMini';
import { useEnhancedNotification, useList } from 'hooks';
import { addFiltersToColumns, ColumnWithFilter } from 'lib/utils/tableFilter';
import { TableFilterTypeEnum } from 'components/table/filters/TableFilter';
import { Column } from '@material-table/core';
import { IParamsItem } from './types';
import { useStationDevices } from './hooks/useStationDevices';
import { useDictionary } from 'hooks/useDictionary';
import { LoaderItem } from 'components/common';

export const EditStation = () => {
  const { push } = useHistory();
  useEquipmentStationsBreadcrumbs();
  const { successNotify } = useNotifications();
  const { onErrorEdit } = useEnhancedNotification();
  const [value, setValue] = useState(0);
  const handleChange = (newValue: number) => {
    setValue(newValue);
  };

  const onSuccess = () => {
    successNotify({
      key: 'file/edit/success',
      message: SUCCESS_MESSAGES.EDIT,
    });
    push('/equipment/stations');
  };

  const { put } = useRequest('/equipment/v10/equipments/stations', onSuccess, onErrorEdit);

  const { data, isLoading } = useStation();

  const { id: stationId } = useParams<{ id: string }>();
  const {
    response,
    isLoading: isParamsLoading,
    toggleOrder,
    onPageChange,
    filterDTO,
    onSearchText,
    setFilters,
    errorText,
    downloadAsFile,
    refetch,
    materialTableProps,
  } = useList<IParamsItem, keyof IParamsItem>(`/equipment/v10/equipments/stations/${stationId}/parameters`, {
    initialOrderFieldKey: 'parameterFullName',
    selectionKey: 'id',
  });
  const columns = useMemo<ColumnWithFilter<IParamsItem>[]>(
    () => [
      {
        title: 'Наименование',
        field: 'parameterFullName',
        filterType: TableFilterTypeEnum.string,
      },
      {
        title: 'Краткое наименование',
        field: 'parameterName',
        filterType: TableFilterTypeEnum.string,
      },
      {
        title: 'Единица измерения',
        field: 'unitFullName',
        filterType: TableFilterTypeEnum.string,
      },
    ],
    [],
  );

  const columnsWithFilters = useMemo<Array<Column<IParamsItem>>>(
    () => addFiltersToColumns({ filters: filterDTO.filters, setFilters, columns }),
    [columns, filterDTO.filters, setFilters],
  );

  const { data: devicesData, isLoading: isDevicesLoading, refresh } = useStationDevices(stationId);

  const { dictionaries } = useDictionary({ url: '/equipment/v10/equipments/stations/dictionaries' });

  if (isLoading || isParamsLoading || isDevicesLoading || !data) {
    return <LoaderItem />;
  }

  return (
    <StationLayout
      title={value === 2 ? 'Параметры' : `Редактирование ${value ? 'прибора' : 'станции'}`}
      station={
        <StationFormContainer>
          <StationForm initialValues={data} onSubmit={put} dictionaries={dictionaries} isEditMode />
        </StationFormContainer>
      }
      devices={
        <StationDevices data={devicesData} isLoading={isDevicesLoading} refresh={refresh} refetchParams={refetch} />
      }
      parameters={
        <MaterialTable
          filterDTO={filterDTO}
          data={response?.data}
          meta={response?.meta}
          columns={columnsWithFilters}
          toggleOrder={toggleOrder}
          onPageChange={onPageChange}
          isLoading={isLoading}
          onSearch={onSearchText}
          errorText={errorText}
          downloadAsFile={downloadAsFile}
          {...materialTableProps}
        />
      }
      value={value}
      handleChange={handleChange}
    />
  );
};
