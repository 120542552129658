import React, { useMemo, useState } from 'react';
import { DateInput, FormWrapper, Select } from 'components/common';
import { Box, Grid, Heading } from '@chakra-ui/react';
import {
  getSelectOptionsFromDictionaryByName,
  getSelectOptionsFromDictionaryByNameWithParent,
} from 'features/get-select-options-from-dictionary';
import { Field, useFormikContext } from 'formik';
import { QualityItem } from 'hooks/geo/Quality/useQualityList';
import { createDateFromResponse, createDateToRequestWithTimezone } from 'lib/create-date';
import { TDictionariesArray } from 'models/dictionaries';

interface CommonTabProps {
  dictionaries: {
    data: TDictionariesArray;
  } | null;
}

export const CommonTab = ({ dictionaries }: CommonTabProps) => {
  const { setFieldValue, values, errors, initialValues } = useFormikContext<QualityItem>();
  const isEditMode = location.pathname.includes('/edit');
  const [selectedWaterSourceType, setSelectedWaterSourceType] = useState('');

  const types = useMemo(
    () => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'types', false, initialValues.waterSourceType),
    [dictionaries?.data, initialValues.waterSourceType],
  );

  const watersources = useMemo(() => {
    const dictionary = getSelectOptionsFromDictionaryByNameWithParent(dictionaries?.data, 'watersources');

    if (selectedWaterSourceType) {
      return dictionary.filter(({ parent }) => parent === selectedWaterSourceType);
    }

    return dictionary;
  }, [dictionaries?.data, selectedWaterSourceType]);

  return (
    <Box flexDirection="column">
      <FormWrapper>
        <Grid marginTop="30px" gap="15px">
          <Heading variant="h2">Данные обследования</Heading>
          <DateInput
            name="measuringDate"
            label="Дата обследования"
            value={createDateFromResponse(values.measuringDate)}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setFieldValue('measuringDate', createDateToRequestWithTimezone(e.target.value))
            }
            showTimeInput={false}
            isDisabled={isEditMode}
            error={errors.measuringDate}
          />
          <Field
            as={Select}
            name="waterSourceType"
            label="Тип пункта наблюдения"
            value={values.waterSourceType}
            options={types}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              setFieldValue('waterSourceType', e.target.value);
              setSelectedWaterSourceType(e.target.value);
              setFieldValue('sourceId', '');
            }}
            error={errors.waterSourceType}
            isDisabled={isEditMode}
          />
          <Field
            as={Select}
            name="sourceId"
            label="Пункт наблюдения"
            value={values.sourceId}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              setFieldValue('sourceId', e.target.value);
              setFieldValue('waterSourceType', watersources.find((el) => el.value === e.target.value)?.parent);
            }}
            options={watersources}
            error={errors.sourceId}
            isDisabled={isEditMode}
          />
        </Grid>
      </FormWrapper>
    </Box>
  );
};
