type FilePreviewMeasuresFiltersValues = 'all' | 'errors' | 'valid';

type FilePreviewMeasuresFilter = {
  label: string;
  value: FilePreviewMeasuresFiltersValues;
};

const filePreviewMeasuresFilters: FilePreviewMeasuresFilter[] = [
  { label: 'Все', value: 'all' },
  { label: 'С ошибками', value: 'errors' },
  { label: 'Без ошибок', value: 'valid' },
];

export type { FilePreviewMeasuresFiltersValues, FilePreviewMeasuresFilter };
export { filePreviewMeasuresFilters };
