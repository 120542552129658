import { ErrorDescriptions } from 'features/error-descriptions-v-stack';
import { memo } from 'react';
import { LabelText, PortalPopover } from 'components/common';
import useGeoDangerProcessPointData from 'hooks/geo/DangerProcess/useGeoDangerProcessPointData';
import { Stack } from '@chakra-ui/react';
import { dangerProcessPopoverProps } from '../constants';

interface DangerProcessPointPortalPopoverProps {
  label?: string;
  id: string;
}

export const DangerProcessPointPortalPopover: React.FC<DangerProcessPointPortalPopoverProps> = memo(({ label, id }) => {
  const { get, result, error, isLoading } = useGeoDangerProcessPointData();

  return (
    <PortalPopover
      onOpen={() => get({ params: { id } })}
      label={label}
      {...dangerProcessPopoverProps}
      isLoading={isLoading}
      style={{
        fontSize: 'inherit',
        lineHeight: 'inherit',
      }}
    >
      <Stack spacing="15px" padding="20px" fontSize="16px" lineHeight="18px" whiteSpace="initial" wordBreak="break-all">
        {result?.code && <LabelText label="Код точки:">{result.code}</LabelText>}
        {result?.latitude && <LabelText label="Широта:">{result.latitude}</LabelText>}
        {result?.longitude && <LabelText label="Долгота:">{result?.longitude}</LabelText>}
        {error && 'descriptions' in error && <ErrorDescriptions spacing={15} descriptions={error.descriptions} />}
      </Stack>
    </PortalPopover>
  );
});
