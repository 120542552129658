import { MaterialTable } from 'features/material-table/MaterialTableMini';
import { useUploadFileProvider } from 'features/UploadedFileProvider';
import { Column } from '@material-table/core';
import { useMemo } from 'react';
import { MeteoDataProfilimerValues } from './MeteoDataProfilimerValues';
import { MeteoDataProfilimerPrivewItem } from './types';

interface MeteoDataProfilimersPreviewProps {
  typeId: string;
}

const MeteoDataProfilimersPreview: React.FC<MeteoDataProfilimersPreviewProps> = () => {
  const { preview } = useUploadFileProvider<MeteoDataProfilimerPrivewItem>();

  const columns = useMemo<Column<MeteoDataProfilimerPrivewItem>[]>(
    () => [
      {
        title: 'Дата и время',
        field: 'date',
        render: (row) => row.date,
      },
      {
        title: 'Температурный профиль',
        field: 'values',
        render: (row) => <MeteoDataProfilimerValues values={row.values} />,
      },
    ],
    [],
  );

  return (
    <MaterialTable
      data={preview?.result?.measures}
      columns={columns}
    />
  );
};

export { MeteoDataProfilimersPreview };
