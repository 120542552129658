import { Flex, FlexProps } from '@chakra-ui/react';
import { createElement } from 'react';

export const ToolbarIconWrap = (props: FlexProps) =>
  createElement(Flex, {
    alignItems: 'center',
    justifyContent: 'center',
    width: '20px',
    height: '20px',
    ...props,
  });
