import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Box, Divider, Grid, Heading, Icon } from '@chakra-ui/react';
import { Button, IFilterValues, Modal } from 'components/common';
import { Chart } from './Chart';
import { Filters } from './Filters';
import { useDataForChart } from '../hooks/useDataForChart';
import { TableIcon } from 'components/icons';
import { ChartNoData } from '../../components/ChartNoData';

interface IGraphicModal {
  isOpen: boolean;
  onClose: () => void;
  header?: string;
  defaultFilters: IFilterValues;
  setFiltersFromGraphic?: (filters: IFilterValues) => void;
  subSystemId: string;
  templateId: string;
}

/**
 * @summary Компонент для модалок с чартом
 */
export const ChartModal = ({
  isOpen,
  onClose,
  header = 'Графическое представление данных',
  defaultFilters,
  setFiltersFromGraphic,
  subSystemId,
  templateId,
}: IGraphicModal) => {
  const [filters, setFilters] = useState<IFilterValues | null>(null);

  // механизм для обновления селектов даты
  const [startDateToUpdate, setStartDateToUpdate] = useState<string | null>(null);
  const [stopDateToUpdate, setStopDateToUpdate] = useState<string | null>(null);

  // механизм для обновления данныъ в data
  const [shouldUpdate, setShouldUpdate] = useState(false);

  const { data, setData, isLoading } = useDataForChart({ filters, shouldUpdate, setShouldUpdate });
  // effect for  clear data
  useEffect(() => {
    setFilters(defaultFilters);
    return () => {
      setData(null);
    };
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  []);

  const isHaveData = !!(data && filters);

  return (
    <Modal isOpen={isOpen} onClose={onClose} width="100%">
      <Grid width="100%">
        <Heading mb={2} as="h2" size="lg">
          {header}
        </Heading>
        {setFiltersFromGraphic && (
          <Box>
            <Button
              mb={2}
              colorScheme="black"
              variant="outline"
              leftIcon={<Icon as={TableIcon} />}
              onClick={() => {
                setFiltersFromGraphic(filters ?? { stopDate: '', startDate: '' });
                onClose();
              }}
            >
              Табличное представление
            </Button>
          </Box>
        )}

        <Filters
          startDateToUpdate={startDateToUpdate}
          stopDateToUpdate={stopDateToUpdate}
          setStop={setStopDateToUpdate}
          setStart={setStartDateToUpdate}
          setShouldUpdate={setShouldUpdate}
          isLoading={isLoading}
          initialValues={defaultFilters}
          setFilters={setFilters}
          subSystemId={subSystemId}
          templateId={templateId}
        />
        <Divider />
        {isHaveData ? (
          <Chart
            filters={filters}
            setFilters={setFilters as Dispatch<SetStateAction<IFilterValues>>}
            data={data}
            setStop={setStopDateToUpdate}
            setStart={setStartDateToUpdate}
          />
        ) : <ChartNoData isLoading={isLoading} />
        }
      </Grid>
    </Modal>
  );
};
