import { TDictionariesArray } from 'models/dictionaries';
import { useEffect } from 'react';
import { useRequest } from 'hooks/useRequest';
import { useUtilDictionaries } from 'hooks/useUtilDictionaries';
import { ApiError } from '../models';

interface IUseDictionary {
  url: string;
  successHandler?: () => void;
  errorHandler?: (error: ApiError | null) => void;
}

export function useDictionary<T extends string>({ url, successHandler, errorHandler }: IUseDictionary) {
  const { get, isLoading, result, error } = useRequest<{
    data: TDictionariesArray<T>;
  }>(url, successHandler, errorHandler);

  const utilDictionaries = useUtilDictionaries(result?.data ?? []);

  useEffect(() => {
    const controller = new AbortController();
    void get({ signal: controller.signal });

    return () => {
      controller.abort();
    };
  }, [get]);

  return { isDictionariesLoading: isLoading, dictionaries: result, error, ...utilDictionaries };
}
