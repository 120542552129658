import { IGroundCoastFormDto } from 'pages/data/Water/WPZRoot/GroundCoast/consts/types';

export const groundCoastInitialEmptyValues: IGroundCoastFormDto = {
  areaId: '',
  date: '',
  garbageTypeList: [],
  waterObjectId: null,
  siteConditionId: null,
  siteCondition: '',
  dangerProcessDescription: '',
  groundMark: '',
  stvorList: [],
  garbageMaxSize: 0,
  district: '',
  region: '',
  oopt: '',
};
