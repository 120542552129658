import * as yup from 'yup';
import { ValidationError } from 'yup';
import { lessThanDate, maxSymbols, moreThanDate, requiredError } from '../constans';
import moment from 'moment';

export const AnalyticsPelAtmosphereSchema = yup.object()
  .shape({
    protocolNumber: yup.string()
      .required(requiredError)
      .nullable()
      .max(15, maxSymbols(15)),
    protocolDate: yup.string()
      .typeError(requiredError)
      .required(requiredError),
    customerId: yup.string()
      .required(requiredError)
      .nullable(),
    researchObject: yup.string()
      .required(requiredError)
      .nullable()
      .max(255, maxSymbols(255)),
    sampleAddress: yup.string()
      .required(requiredError)
      .nullable()
      .max(511, maxSymbols(511)),
    samplePlace: yup.string()
      .required(requiredError)
      .nullable()
      .max(255, maxSymbols(255)),
    contractorId: yup.string()
      .required(requiredError)
      .nullable(),
    sampleActNumber: yup.string()
      .required(requiredError)
      .nullable()
      .max(63, maxSymbols(63)),
    sampleRegNumber: yup.string()
      .required(requiredError)
      .nullable()
      .max(15, maxSymbols(15)),
    researchEndDatetime: yup.string()
      .typeError(requiredError)
      .required(requiredError),
    researchStartDatetime: yup.string()
      .typeError(requiredError)
      .required(requiredError),
    extraInfoValue: yup.string()
      .max(511, maxSymbols(511)),
    status: yup.string()
      .required(requiredError)
      .nullable(),
    sign: yup
      .object()
      .shape({
        signers: yup.array()
          .of(
            yup.object()
              .shape({
                action: yup.string()
                  .required(requiredError)
                  .nullable(),
                position: yup.string()
                  .required(requiredError)
                  .nullable()
                  .max(511, maxSymbols(511)),
                name: yup.string()
                  .required(requiredError)
                  .nullable()
                  .max(255, maxSymbols(255)),
              }),
          ),
      })
      .nullable(),
  })
  .test((values) => {
    if (values.researchStartDatetime && values.researchEndDatetime && moment(values.researchStartDatetime)
      .isAfter(values.researchEndDatetime)) {
      const dateErrors = [
        new ValidationError(lessThanDate('даты начала измерений'), '', 'researchEndDatetime'),
        new ValidationError(moreThanDate('даты окончания измерений'), '', 'researchStartDatetime'),
      ];
      return new ValidationError(dateErrors);
    }
    return true;
  });
