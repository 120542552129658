import React, { useCallback, useEffect, useState } from 'react';
import { createTouchedErrors } from 'features/create-touched-errors';
import { FormikProvider, useFormik } from 'formik';
import { useRequest } from 'hooks/useRequest';
import { useLocation, useParams } from 'react-router-dom';
import { ReagentsVegsRootPath } from 'pages/data/PGR';
import { VegStateSchema } from 'models/schemas/pgr';
import { initialEmptyValues } from './AddDeicingVegReport';
import FieldsForm from './FieldsForm';
import { IUploadMedia } from 'components/common/FileUploadDragAndDrop/types';
import { updateFiles } from 'api/services/media';
import { useObjectType } from 'hooks/useObjectType';
import { VegetationDeicingItem } from 'pages/data/PGR/GreenPlants/VegetativeState/useVegDicingList';
import { useEnhancedNotification } from 'hooks';
import { splitTypesAndValues } from 'lib/utils/forms/valueTypeHelper';
import { useGoBackWithFallback } from 'lib/utils/goBackWithFallback';
import { useDictionary } from 'hooks/useDictionary';

export const EditDeicingVegsReport = () => {
  const { backWithFallback } = useGoBackWithFallback();
  const { id } = useParams<{ id: string }>();
  const { isDictionariesLoading, dictionaries } = useDictionary({ url: '/deicing/v10/deicing/vegetative/dictionaries' });
  const { onErrorEdit, onSuccessEdit } = useEnhancedNotification();
  const location = useLocation();
  const [media, setMedia] = useState<IUploadMedia[]>([]);
  const objectType = useObjectType(dictionaries);
  const [editedObject, setEditObject] = useState<VegetationDeicingItem>();

  const onItemLoad = useCallback((response: VegetationDeicingItem | null) => {
    if (response) {
      setEditObject(response);
    }
  }, []);

  const {
    get,
    isLoading: isItemLoading
  } = useRequest<VegetationDeicingItem>(`/deicing/v10/deicing/vegetative/pgr/${id}`, onItemLoad);

  useEffect(() => {
    if (location.state) {
      setEditObject(location.state);
    } else {
      void get();
    }
  }, [get, location.state]);

  const onSuccess = () => {
    updateFiles(id, objectType, media)
      .then(() => {
        onSuccessEdit();
        backWithFallback(`${ReagentsVegsRootPath}/main-data`);
      })
      .catch((error: Error) => {
        onErrorEdit(error.message);
      });
  };

  const { put, isLoading } = useRequest<VegetationDeicingItem, any>('/deicing/v10/deicing/vegetative', onSuccess, onErrorEdit);

  const initialValues = {
    ...initialEmptyValues,
    ...editedObject,
  };

  const onFormSubmit = useCallback((values: typeof initialValues) => {
    put({
      ...values,
      values: splitTypesAndValues(values.values),
    });
  }, [put]);

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    initialValues,
    onSubmit: onFormSubmit,
    validationSchema: VegStateSchema,
  });

  return (
    <FormikProvider
      value={{
        ...formik,
        errors: createTouchedErrors(formik.errors, formik.touched),
      }}
    >
      <FieldsForm
        dictionaries={dictionaries}
        onCancel={() => {
          backWithFallback(`${ReagentsVegsRootPath}/main-data`);
        }}
        isLoading={isDictionariesLoading || isItemLoading || isLoading}
        uploadItemId={id}
        onMediaChange={setMedia}
        media={media}
      />
    </FormikProvider>
  );
};
