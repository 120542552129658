import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Flex, Spinner, TabList, Tabs, Text } from '@chakra-ui/react';
import { IDayResponse, IManagerPeriod } from './types';
import ManagerDataPanel from './components/ManagerDataPanel';
import { Askza, Emissions, Meteo, Water } from '../home/icons';
import { useEnhancedNotification, useRequest } from '../../hooks';
import moment from 'moment';
import { LinksCard } from './components/LinksCard';
import { StyledTabSmall } from '../../components/common/StyledTab/StyledTabSmall';
import { useUpdateBreadcrumbs } from '../../features/breadcrumbs-provider';
import { airGraphSubtitles, airSubtitles, graphPeriods, meteoSubtitles, periodLocale, periods, waterSubtitles } from './const';
import ManagerDataGraph from './components/ManagerDataGraph';
import { ManagerDashboardMap } from './components/ManagerDashboardMap';
import ManagerDataGraphInversion from './components/ManagerDataGraphInversion';

const ManagerPageV2 = () => {
  useUpdateBreadcrumbs([{ breadcrumb: 'Личный кабинет руководителя', path: '/manager' }]);
  const { onGetError } = useEnhancedNotification();
  const [period, setPeriod] = useState<IManagerPeriod>('now');
  const [graphPeriod, setGraphPeriod] = useState<IManagerPeriod>('today');
  const [date, setDate] = useState<string | string[]>();
  const [data, setData] = useState<IDayResponse>();

  const onSuccess = useCallback((data: IDayResponse | null) => {
    if (!data) {
      return;
    }
    setData(data);
    setDate(data.date);
  }, []);

  const {
    get,
    isLoading,
  } = useRequest<IDayResponse>('/reporting/v10/dashboard/indicators/summary/manager', onSuccess, onGetError);

  useEffect(() => {
    setDate(undefined);
    void get({ params: { period } });
  }, [get, period]);

  const formattedDate = useMemo(() => {
    if (!date) return null;

    if (Array.isArray(date)) {
      return `${moment(date[0]).format('DD.MM.YYYY')} - ${moment(date[1]).format('DD.MM.YYYY')}`;
    }

    return moment(date).format('DD.MM.YYYY, dddd, состояние на HH:mm');
  }, [date]);

  return (
    <Flex flexDirection="column" pb="50px">
      <Flex mx="auto" flexDirection="column" width="100%" px="16px">
        <Flex pt="20px" justifyContent="space-between">
          <Text mb="16px" fontSize="24px" lineHeight="30px" fontWeight="700">
            Личный кабинет руководителя
          </Text>
        </Flex>

        <Flex justifyContent="space-between" alignItems="center">
          <Tabs index={periods.indexOf(period)} onChange={(i) => setPeriod(periods[i])}>
            <TabList
              border="none"
            >
              {periods.map((period, index) => (
                <StyledTabSmall
                  key={index}
                  borderTopLeftRadius={index === 0 ? '4px' : undefined}
                  borderBottomLeftRadius={index === 0 ? '4px' : undefined}
                  borderTopRightRadius={index === (periods.length - 1) ? '4px' : undefined}
                  borderBottomRightRadius={index === (periods.length - 1) ? '4px' : undefined}
                  borderRight={index === (periods.length - 1) ? '' : 'none'}
                >
                  {periodLocale[period]}
                </StyledTabSmall>
              ))}
            </TabList>
          </Tabs>

          {(date || isLoading) && (
            <Text color="#208CFF">{isLoading ? (<Spinner color="PrimaryButton.500" />) : formattedDate}</Text>
          )}
        </Flex>

        <Flex mt="16px" gap="16px">
          {data?.air && (
            <ManagerDataPanel
              title="Воздух"
              data={data?.air.flat()}
              icon={<Askza />}
              subsystem="air"
              period={period}
              rowSubtitle={airSubtitles}
            />
          )}

          {data?.meteo && period === 'now' && (
            <ManagerDataPanel
              title="Метео"
              data={data?.meteo}
              icon={<Meteo />}
              subsystem="meteo"
              isMeteo
              rowSubtitle={meteoSubtitles}
              period={period}
            />
          )}

        </Flex>

        {(data?.water || data?.emissions) && (
          <Flex mt="20px" gap="16px">
            {data?.water && (
              <Box width="100%">
                <ManagerDataPanel
                  title="Вода"
                  data={data?.water}
                  icon={<Water />}
                  subsystem="water"
                  period={period}
                  rowSubtitle={waterSubtitles}
                />
              </Box>
            )}

            {data?.emissions && (
              <Box width="100%" maxWidth="calc(50% - 8px)">
                <ManagerDataPanel
                  title="Промвыбросы"
                  data={data?.emissions}
                  icon={<Emissions />}
                  subsystem="emissions"
                  isLoading={isLoading}
                />
              </Box>
            )}
          </Flex>
        )}
      </Flex>

      <Box my="32px" width="100%" background="#D4D9DC" height="1px" />

      <Flex mx="auto" flexDirection="column" width="100%" px="16px">
        <ManagerDashboardMap />
      </Flex>

      <Flex mt="40px" mx="auto" flexDirection="column" width="100%" px="16px">
        <Flex justifyContent="space-between" alignItems="center" mb={2}>
          <Tabs index={graphPeriods.indexOf(graphPeriod)} onChange={(i) => setGraphPeriod(graphPeriods[i])}>
            <TabList
              border="none"
            >
              {graphPeriods.map((period, index) => (
                <StyledTabSmall
                  key={index}
                  borderTopLeftRadius={index === 0 ? '4px' : undefined}
                  borderBottomLeftRadius={index === 0 ? '4px' : undefined}
                  borderTopRightRadius={index === (graphPeriods.length - 1) ? '4px' : undefined}
                  borderBottomRightRadius={index === (graphPeriods.length - 1) ? '4px' : undefined}
                  borderRight={index === (graphPeriods.length - 1) ? '' : 'none'}
                >
                  {periodLocale[period]}
                </StyledTabSmall>
              ))}
            </TabList>
          </Tabs>
        </Flex>

        <Flex
          width="100%"
          gap="16px"
        >
          {graphPeriod === 'today' ? (
            <>
              <Box width="50%">
                <ManagerDataGraph
                  title="Воздух"
                  icon={<Askza />}
                  url={`/reporting/v10/dashboard/charts/lkr/air?period=${graphPeriod}`}
                  period={graphPeriod}
                  rowSubtitle={airGraphSubtitles}
                />
              </Box>

              <Box width="50%">
                <ManagerDataGraph
                  url="/reporting/v10/dashboard/charts/lkr/air/h2s"
                  yAxisTitle="Доли ПДК"
                  period={graphPeriod}
                />
              </Box>
            </>
          ) : (
            <ManagerDataGraph
              title="Воздух"
              icon={<Askza />}
              height="300px"
              url={`/reporting/v10/dashboard/charts/lkr/air?period=${graphPeriod}`}
              period={graphPeriod}
              rowSubtitle={airGraphSubtitles}
            />
          )}
        </Flex>

        {graphPeriod === 'today' && (
          <Flex>
            <ManagerDataGraphInversion
              title="Метео"
              subtitle="Инверсия"
              icon={<Meteo />}
              height="300px"
              url="/reporting/v10/dashboard/charts/common/air-inversion/msz4"
            />
          </Flex>
        )}
      </Flex>

      <Flex mt="40px" mx="auto" flexDirection="column" width="100%" px="16px">
        <LinksCard
          title="Аналитическая отчетность"
          links={[
            {
              name: 'Графики поступающих данных',
              path: '/external_reports/superset/dashboard/257'
            }, {
              name: 'Средние значения по станции за период',
              path: '/external_reports/superset/dashboard/216'
            }, {
              name: 'Среднее значение по параметру за период (с возможностью формирования отчета по нескольким параметрам)',
              path: '/external_reports/superset/dashboard/243'
            }, {
              name: 'Ежедневная справка',
              path: '/external_reports/superset/dashboard/245'
            },
          ]}
        />
      </Flex>
    </Flex>
  );
};

export default ManagerPageV2;
