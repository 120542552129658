import { SubsystemHeader } from 'components/common';
import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import { transformFiltersArray } from 'features/Filter/transformFiltersArray';

import { MaterialTable } from 'features/material-table/MaterialTableMini';
import { createDateToRequestWithTimezone, createLocalDateDDMMYYYYWithTime } from 'lib/create-date';
import React, { memo, useMemo } from 'react';

import { useList } from 'hooks/useList';
import { addFiltersToColumns, ColumnWithFilter } from 'lib/utils/tableFilter';
import { MeteoDataProfilemerApiRoot } from 'pages/data/Meteo/menu';
import { useMeteoDictionaries } from 'pages/data/Meteo/MeteoData/useMeteoDictionaries';
import { meteoLocales } from 'pages/data/Meteo/MeteoData/constants';
import {
  IProtocolMeteoDataItem,
  TProtocolMeteoDataFilterDTO,
  TProtocolMeteoDataFilterTypes,
} from 'pages/data/Meteo/Protocol/ProtocolMeteoData/types';
import { ProtocolMeteoDataFilter } from 'pages/data/Meteo/Protocol/ProtocolMeteoData/ProtocolMeteoDataFilter';
import { meteoProtocolsBreadcrumbs } from 'pages/data/Meteo/Protocol/const';
import useProtocolModal from 'hooks/useProtocolModal';
import VersionCell from 'components/table/VersionCell';
import { Column } from '@material-table/core';
import { TableFilterTypeEnum } from 'components/table/filters/TableFilter';
import subsystemIdsMap from 'constants/subsystemIdsMap';
import { useAvailability } from 'hooks';
import { usePeriodsDict } from 'features/PeriodsButtons/usePeriodsDict';
import { restorePreviewColumns } from 'components/common/Modals/ProtocolModal';
import { DEFAULT_PAGE_SIZE_NEW_TABLE } from '../../../../../constants/tables';
import { FullPageWrapper } from '../../../../../components/table/FullPageWrapper';

const ProtocolMeteoData: React.FC = memo(() => {
  const { isExecute, isNoRights, isAvailable } = useAvailability(subsystemIdsMap.meteoProfilemers);

  useUpdateBreadcrumbs([
    ...meteoProtocolsBreadcrumbs,
    {
      breadcrumb: 'Профилемеры',
      path: '/data/meteo/protocol/monitoring',
    },
  ]);

  const { stations } = useMeteoDictionaries();

  const {
    refetch,
    isLoading,
    response,
    toggleOrder,
    setFilters,
    onPageChange,
    filterDTO,
    downloadAsFile,
    onSearchText,
    saveFilters,
    errorText,
    materialTableProps,
  } = useList<IProtocolMeteoDataItem, TProtocolMeteoDataFilterTypes>(`${MeteoDataProfilemerApiRoot}/list/values`, {
    initialOrderFieldKey: '-valueDate',
    preventFirstFetch: true,
    defaultPageSize: DEFAULT_PAGE_SIZE_NEW_TABLE,
    resetDataOnNextRequest: true,
  });

  const { openModal, protocolModal } = useProtocolModal<IProtocolMeteoDataItem>({
    onClose: refetch,
    dateKey: 'valueDate',
    baseUrl: '/meteo/v10/profilemer',
    previewColumns: restorePreviewColumns.meteoProfilemer,
    secondBaseUrl: '/meteo/v10/profilemer'
  });

  const columns = useMemo<Array<ColumnWithFilter<IProtocolMeteoDataItem>>>(
    () => [
      {
        title: 'Версия',
        field: 'valueVersion',
        render: (row) => <>{isAvailable ? <VersionCell row={row} onClick={openModal} /> : row.valueVersion}</>,
        width: 50,
        filterType: TableFilterTypeEnum.number,
      },
      {
        title: meteoLocales.measuringDate,
        field: 'valueDate',
        render: (row) => createLocalDateDDMMYYYYWithTime(row.valueDate),
      },
      {
        title: meteoLocales.stationId,
        field: 'stationName',
        filterType: TableFilterTypeEnum.string,
      },
      {
        title: meteoLocales.heightId,
        field: 'height',
        render: (row) => (typeof row.height === 'number' ? row.height : 'Внешняя температура'),
        filterType: TableFilterTypeEnum.number,
      },
      {
        title: 'Параметр',
        field: 'parameterName',
        filterType: TableFilterTypeEnum.string,
      },
      {
        title: 'Значение',
        field: 'value',
        filterType: TableFilterTypeEnum.number,
      },
    ],
    [openModal, isAvailable]
  );

  const columnsWithFilters = useMemo<Array<Column<IProtocolMeteoDataItem>>>(
    () => addFiltersToColumns({ filters: filterDTO.filters, setFilters, columns }),
    [columns, filterDTO.filters, setFilters]
  );

  const { result: periods } = usePeriodsDict(`auth/v10/user/period/${subsystemIdsMap.meteoProfilemers}`);

  return (
    <FullPageWrapper>
      <SubsystemHeader
        title="Профилемеры"
        filtersComponent={
          <ProtocolMeteoDataFilter
            filters={filterDTO?.filters || []}
            periods={periods}
            stations={stations}
            isLoading={isLoading}
            onSubmit={({ dateFrom, dateTo, ...values }) => {
              setFilters([
                ...transformFiltersArray<TProtocolMeteoDataFilterDTO['filters']>(
                  Object.values({
                    ...values,
                    dateFrom: {
                      ...dateFrom,
                      value: createDateToRequestWithTimezone(String(dateFrom.value)),
                    },
                    dateTo: {
                      ...dateTo,
                      value: createDateToRequestWithTimezone(String(dateTo.value)),
                    },
                  })
                ),
              ]);
            }}
          />
        }
      />

      <MaterialTable
        saveFilters={saveFilters}
        isNotExecute={isExecute || isNoRights}
        data={response?.data}
        meta={response?.meta}
        toggleOrder={toggleOrder}
        onPageChange={onPageChange}
        filterDTO={filterDTO}
        isLoading={isLoading}
        columns={columnsWithFilters}
        downloadAsFile={downloadAsFile}
        setFilters={setFilters}
        options={{
          search: true,
        }}
        onSearch={onSearchText}
        errorText={errorText}
        {...materialTableProps}
        showTotalCount
      />

      {protocolModal}
    </FullPageWrapper>
  );
});

export { ProtocolMeteoData };
