import { FormikProvider, useFormik } from 'formik';
import { ILetter } from '../types/types';
import { LettersFormFields } from './LettersFormFields';
import { useNotifications, useRequest } from 'hooks';
import { ERROR_MESSAGES, SUCCESS_MESSAGES } from 'constants/messages';
import React, { useContext } from 'react';
import { TaskPrepareContext } from '../../TaskPrepareContext';

interface IProps {
  data?: ILetter;
  customClose: () => void;
  isDetailPanel?: boolean;
}

export const LettersForm = ({ data, customClose, isDetailPanel }: IProps) => {
  const { successNotify, errorNotify } = useNotifications();
  const { recievedTask: task } = useContext(TaskPrepareContext);

  const onSuccess = () => {
    customClose();
    successNotify({
      key: 'edit/success',
      message: data ? SUCCESS_MESSAGES.EDIT : SUCCESS_MESSAGES.CREATE,
    });
  };
  const onError = () => {
    errorNotify({
      key: 'edit/error',
      message: data ? ERROR_MESSAGES.EDIT : ERROR_MESSAGES.CREATE,
    });
  };

  const { put } = useRequest(`appeal/v10/letters/${data?.id}`, onSuccess, onError);
  const { post } = useRequest('appeal/v10/letters', onSuccess, onError);

  const initialValues = {
    territoryName: task?.territoryName,
    reason: data?.reason || '',
    executorUserId: data?.executorUserId || '',
    outgoingNumber: data?.outgoingNumber || '',
    createdAt: data?.createdAt || '',
    isDeleted: data?.isDeleted || '',
    outgoingDate: data?.outgoingDate || '',
    haveAnswer: data?.haveAnswer || '',
    incomingNumber: data?.incomingNumber || '',
    id: data?.id || '',
    edoAgreementDate: data?.edoAgreementDate || '',
    appealEssence: data?.appealEssence || '',
    authorityId: data?.authorityId || '',
    complaintIds: data?.complaints?.map((item) => item.id) || [],
    note: data?.note || '',
    answer: {
      imposed: data?.answer.imposed || '',
      takenActions: data?.answer.takenActions || '',
      collected: data?.answer.collected || '',
      outgoingNumber: data?.answer.outgoingNumber || '',
      content: data?.answer.content || '',
      outgoingDate: data?.answer.outgoingDate || '',
      stage: data?.answer.stage || '',
      incomingNumber: data?.answer.incomingNumber || '',
      incomingDate: data?.answer.incomingDate || '',
    },
    incomingDate: data?.incomingDate || '',
    edoAgreementNumber: data?.edoAgreementNumber || '',
    taskId: task?.id || '',
  };

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    initialValues,
    onSubmit: (values) => {
      if (data) {
        put(values);
      } else {
        post(values);
      }
    },
  });

  return (
    <FormikProvider
      value={{
        ...formik,
      }}
    >
      <LettersFormFields customClose={customClose} isDetailPanel={isDetailPanel} />
    </FormikProvider>
  );
};
