import { useRequest } from 'hooks/useRequest';
import { GeoDistrictData } from '../types';

export function useGeoDangerProcessDistrictData() {
  const { get, result, error, isLoading } = useRequest<GeoDistrictData>(
    '/geoecology/v10/geoecology/dangerprocess/districtdata'
  );
  return {
    get,
    result,
    error,
    isLoading,
  };
}
