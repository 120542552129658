import { useParams } from 'react-router-dom';
import { useContext, useMemo } from 'react';
import { UserInfoContext } from 'features/AuthProvider';
import { Flex, Heading, Link } from '@chakra-ui/react';
import { DefaultText, LoaderItem, PageHeader } from 'components/common';
import { useGetSubsystemReport } from '../useGetSubsystemReports';
import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import subsystemIdsMap, { subsystemCodesMap } from '../../../constants/subsystemIdsMap';
import { CitizenAppealsTableViewPath } from '../../citizenAppeal';
import { PELTableView } from '../../pel/constants';
import { useJournalLog } from 'lib/utils/journalLog/useJournalLog';

interface IProps {
  breadcrumb: { breadcrumb: string; path: string }[];
}

export const ReportList = ({ breadcrumb }: IProps) => {
  useUpdateBreadcrumbs(breadcrumb);
  const { logExternalUrl } = useJournalLog();
  const { userSubsystems, customRights, user } = useContext(UserInfoContext);
  const { subsystemId } = useParams<{ subsystemId: string }>();
  const { isReportLoading, subsystemReports } = useGetSubsystemReport(subsystemId, user?.id);

  const customRightsCodes = useMemo(() => customRights?.map((right) => right.code), [customRights]);

  const augmentedSystemReports = useMemo(() => {
    const reportsList = [];
    if (subsystemId === subsystemIdsMap.appealsRoot) {
      if (customRightsCodes?.find((right) => right.includes(subsystemCodesMap.citizenAppeals.appealsTableViewAir))) {
        reportsList.push({
          link: `${CitizenAppealsTableViewPath}/air`,
          name: 'Сводный отчет по поступившим жалобам (воздух)',
        });
      }
      if (customRightsCodes?.find((right) => right.includes(subsystemCodesMap.citizenAppeals.appealsTableViewNoise))) {
        reportsList.push({
          link: `${CitizenAppealsTableViewPath}/noise`,
          name: 'Сводный отчет по поступившим жалобам (шум)',
        });
      }
    }
    if (subsystemId === subsystemIdsMap.PELRoot) {
      if (customRightsCodes?.find((right) => right.includes(subsystemCodesMap.PEL.atmoReport))) {
        reportsList.push({
          link: `${PELTableView}/${1}`,
          name: 'Сводный отчет по исследованным территориям (воздух)',
        });
      }
      if (customRightsCodes?.find((right) => right.includes(subsystemCodesMap.PEL.noiseReport))) {
        reportsList.push({
          link: `${PELTableView}/${0}`,
          name: 'Сводный отчет по исследованным территориям (шум)',
        });
      }
    }
    if (subsystemReports) {
      reportsList.push(...subsystemReports);
    }
    return reportsList;
  }, [customRightsCodes, subsystemId, subsystemReports]);

  const subsystemReportsSorted = useMemo(
    () =>
      augmentedSystemReports?.sort((a, b) => {
        const nameA = a.name.toLowerCase();
        const nameB = b.name.toLowerCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      }),
    [augmentedSystemReports]
  );

  const subsystemName = userSubsystems?.find((subsystem) => subsystem.id === subsystemId)?.name || '';
  const module = userSubsystems?.find((subsystem) => subsystem.id === subsystemId)?.module || '';
  const fullName = `${subsystemName} ${module && module !== subsystemName ? `(${module})` : ''}`;

  if (isReportLoading) {
    return <LoaderItem />;
  }

  return (
    <Flex flexDirection="column">
      <PageHeader marginBottom={5}>
        <Heading>{`Отчеты ${fullName}`}</Heading>
      </PageHeader>
      {!subsystemReportsSorted?.length ? (
        <Flex justifyContent="center">
          <DefaultText fontWeight="bold" color="rgb(192,192,192)" textColor="rgb(192,192,192)">
            Данных по отчетам пока нет
          </DefaultText>
        </Flex>
      ) : (
        subsystemReportsSorted?.map((report) => (
          <Link
            key={report.link}
            href={report.link}
            onClick={() => logExternalUrl(report.link, report.name)}
            isExternal
            color="#208CFF"
            fontWeight="bold"
            marginLeft="10px"
            marginBottom="20px"
          >
            {report.name}
          </Link>
        ))
      )}
    </Flex>
  );
};
