import React, { useCallback } from 'react';
import { Box, Divider, Text } from '@chakra-ui/react';
import { MenuItem } from 'pages/home/components/MenuItem';
import { RightArrow } from 'pages/home/icons';
import { Link } from 'react-router-dom';
import { RenderTree } from 'features/WithSideBar/types';
import { useFilteredMenu } from 'hooks/useFilteredMenu';

export interface MenuInterface {
  firstItemExtraMarginTop?: boolean;
  header: {
    text: string;
    icon?: React.ReactNode;
    subsystemId?: string;
    url?: string;
    otherCode?: string;
  };
  items?: {
    text: string;
    path: string;
    icon?: React.ReactNode;
    count?: number;
    subsystemId?: string;
    otherCode?: string;
  }[];
  hasMorePath?: string;
  sliceIndex?: number;
}

interface Props {
  menu: RenderTree;
  mb?: number;
  firstItemExtraMarginTop?: boolean;
  sliceIndex?: number;
  hasMorePath?: string;
}

export const MenuColumn: React.FC<Props> = ({ mb = 0, menu, firstItemExtraMarginTop, sliceIndex, hasMorePath }) => {
  const { filteredMenu } = useFilteredMenu([menu]);

  const renderTreeChildrenToItems = useCallback<(children: RenderTree['children']) => MenuInterface['items']>(
    (children) =>
      children?.map((item) => ({
        text: item.name,
        path: item.url || '/',
        subsystemId: item.subsystemId,
        otherCode: item.otherCode,
        icon: item.homepageIcon,
      })) || [],
    []);

  const renderTreeToMenuInterface = useCallback(
    (tree: RenderTree) =>
      (tree
        ? {
            header: {
              text: tree.name,
              url: tree.url,
              subsystemId: tree.subsystemId,
              otherCode: tree.otherCode,
              icon: tree.homepageIcon,
            },
            items: tree.children ? renderTreeChildrenToItems(tree.children) : undefined,
          }
        : undefined),
    [renderTreeChildrenToItems]
  );

  const column = renderTreeToMenuInterface(filteredMenu[0]);

  const accessibleItems = sliceIndex && column?.items ? column?.items?.slice(0, sliceIndex) : column?.items;

  if (!column) {
    return null;
  }

  if (column && accessibleItems?.length === 0 && column.header?.text !== 'НСИ') {
    return null;
  }

  return (
    <Box
      mb={mb}
      boxShadow="0px 2px 5px rgba(0, 0, 0, 0.1)"
      borderRadius={2}
      pb={column.items ? 2.5 : 0}
      height="fit-content"
    >
      <Box px={5}>
        <MenuItem path={column.header.url} icon={column.header.icon} text={column.header.text} isBold />
      </Box>

      {column.items && (
        <>
          <Divider mb={2.5} />
          <Box px={5}>
            {accessibleItems?.map((item, index) => (
              <Box key={index} mt={firstItemExtraMarginTop && index === 0 ? 6 : 0}>
                <MenuItem icon={item.icon} text={item.text} path={item.path} count={item.count} />
                {!(index + 1 === column.items?.length && !hasMorePath) && <Divider />}
              </Box>
            ))}
            {hasMorePath && column.items.length ? (
              <Text mb="3px" mt="13px" color="text.primary">
                <Link to={hasMorePath} style={{ display: 'flex' }}>
                  См. все&nbsp;
                  <RightArrow style={{ display: 'inline-block' }} />
                </Link>
              </Text>
            ) : null}
          </Box>
        </>
      )}
    </Box>
  );
};
