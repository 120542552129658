import { Button } from 'components/common';
import { ArrowDownIcon } from 'components/icons';
import { useDisclosure } from 'hooks';
import { map, slice } from 'lodash';
import { MeteoDataProfilimerPrivewItem } from './types';
import { Box, Flex, Grid } from '@chakra-ui/react';

interface MeteoDataValuesProps {
  values: MeteoDataProfilimerPrivewItem['values'];
}

export const MeteoDataProfilimerValues: React.FC<MeteoDataValuesProps> = ({ values }) => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Flex>
      <Grid gridTemplateColumns="repeat(11, 1fr)" gap="20px" padding="20px 0px">
        {map(slice(values, 0, 10), (item, index) => (
          <Flex key={index} flexDirection="column">
            <Box>{`${item.key}M`}</Box>
            <Box>{item.value}</Box>
          </Flex>
        ))}
        <Flex>
          <Button
            variant="ghost"
            leftIcon={(
              <Flex width="100%" height="100%" justifyContent="center" alignItems="center">
                <div
                  style={{
                    background: '#007AFF',
                    width: 4,
                    height: 4,
                    borderRadius: '50%',
                  }}
                />
              </Flex>
            )}
            rightIcon={
              isOpen ? (
                <ArrowDownIcon
                  style={{
                    transform: 'rotate(180deg)',
                  }}
                />
              ) : (
                <ArrowDownIcon />
              )
            }
            onClick={(e) => {
              e.stopPropagation();
              onToggle();
            }}
          >
            еще
          </Button>
        </Flex>
        {isOpen &&
          map(slice(values, 10), (item, index) => (
            <Flex key={index} flexDirection="column">
              <Box>{`${item.key}M`}</Box>
              <Box>{item.value}</Box>
            </Flex>
          ))}
      </Grid>
    </Flex>
  );
};
