import appLocale from 'constants/appLocale';
import { createLocalDateDDMMYYYY } from 'lib/create-date';
import { GroundCoastListDTO } from 'pages/data/Water/WPZRoot/GroundCoast/consts/types';
import { ColumnWithFilter } from 'lib/utils/tableFilter';
import { TableFilterTypeEnum } from 'components/table/filters/TableFilter';

const columns: Array<ColumnWithFilter<GroundCoastListDTO>> = [
  {
    title: appLocale.groundCoast.date,
    field: 'date',
    render: (row) => createLocalDateDDMMYYYY(row.date || ''),
  },
  {
    title: appLocale.common.areaCode,
    field: 'areaCode',
    filterType: TableFilterTypeEnum.string,
  },
  {
    title: appLocale.common.region,
    field: 'region',
    filterType: TableFilterTypeEnum.string,
  },
  {
    title: appLocale.common.district,
    field: 'district',
    filterType: TableFilterTypeEnum.string,
  },
  {
    title: appLocale.groundCoast.oopt,
    field: 'oopt',
    filterType: TableFilterTypeEnum.string,
  },
  {
    title: appLocale.groundCoast.groundMark,
    field: 'groundMark',
    filterType: TableFilterTypeEnum.string,
  },
  {
    title: 'Номер створа',
    field: 'stvorCode',
    filterType: TableFilterTypeEnum.number,
  },
  {
    title: appLocale.groundCoast.coastlinePosition,
    field: 'coastlinePosition',
    filterType: TableFilterTypeEnum.number,
  },
  {
    title: appLocale.groundCoast.latitude,
    field: 'latitude',
    filterType: TableFilterTypeEnum.number,
  },
  {
    title: appLocale.groundCoast.longitude,
    field: 'longitude',
    filterType: TableFilterTypeEnum.number,
  },
  {
    title: appLocale.groundCoast.altitude,
    field: 'altitude',
    filterType: TableFilterTypeEnum.number,
  },
  {
    title: 'Инородные объекты, вид',
    field: 'garbageTypeList',
    render: (row) => row.garbageTypeList,
    filterType: TableFilterTypeEnum.string,
  },
  {
    title: appLocale.groundCoast.garbageMaxSize,
    field: 'garbageMaxSize',
    filterType: TableFilterTypeEnum.number,
  },
  {
    title: 'Описание опасного процесса',
    field: 'dangerProcessDescription',
    filterType: TableFilterTypeEnum.string,
  },
  {
    title: appLocale.groundCoast.siteConditions,
    field: 'siteCondition',
    filterType: TableFilterTypeEnum.string,
  },
];

export default columns;
