import React, { useMemo } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { MaterialTable } from 'features/material-table/MaterialTableMini';
import { CitizenAppealListItem } from '../../../consts/types';
import { TFiltersArray } from 'models';
import { getSelectRichOptionsFromDictionaryByName } from 'features/get-select-options-from-dictionary';
import { ComplaintInfo } from './ComplaintInfo';
import { ComplaintsFilter } from './ComplaintsFilter';
import { useList } from 'hooks';
import { SearchHeader } from './SearchHeader';
import { citizenAppealsColumns } from 'pages/citizenAppeal/CitizensAppealsRecord/citizenAppealsColumns';
import { useDictionary } from 'hooks/useDictionary';

export const ComplaintsSearch = () => {
  const { dictionaries } = useDictionary({ url: '/appeal/v10/appeals/dictionaries' });

  const areas = useMemo(() => getSelectRichOptionsFromDictionaryByName(dictionaries?.data, 'areas'), [dictionaries]);
  const districts = useMemo(
    () => getSelectRichOptionsFromDictionaryByName(dictionaries?.data, 'districts'),
    [dictionaries]
  );
  const incidentTypes = useMemo(
    () => getSelectRichOptionsFromDictionaryByName(dictionaries?.data, 'incidentTypes'),
    [dictionaries]
  );
  const noiseSourceTypeId = useMemo(
    () => getSelectRichOptionsFromDictionaryByName(dictionaries?.data, 'noiseSourceTypeId'),
    [dictionaries]
  );
  const odorSourceTypeId = useMemo(
    () => getSelectRichOptionsFromDictionaryByName(dictionaries?.data, 'odorSourceTypeId'),
    [dictionaries]
  );

  const {
    isLoading: isAppealListLoading,
    response,
    setFilters,
    toggleOrder,
    onPageChange,
    filterDTO,
    errorText,
  } = useList<CitizenAppealListItem>('appeal/v10/appeals/list', {
    initialOrderFieldKey: '-assignmentExecution',
  });

  return (
    <Flex flexDirection="column" width="100%">
      <SearchHeader>
        <ComplaintsFilter
          noiseSourceTypeId={noiseSourceTypeId}
          odorSourceTypeId={odorSourceTypeId}
          areas={areas}
          districts={districts}
          incidentTypes={incidentTypes}
          onSubmit={(values) => {
            const filters: TFiltersArray = Object.values(values);
            setFilters(filters);
          }}
          setFilters={setFilters}
        />
      </SearchHeader>

      <Box marginTop={15}>
        <MaterialTable
          data={response?.data}
          meta={response?.meta}
          filterDTO={filterDTO}
          options={{
            search: true,
            showTitle: true,
            toolbar: false,
            paging: true,
            selection: false,
          }}
          toggleOrder={toggleOrder}
          isLoading={isAppealListLoading}
          onPageChange={onPageChange}
          detailPanel={(data: any) => <ComplaintInfo complaintId={data.rowData.complaintId} />}
          columns={citizenAppealsColumns}
          errorText={errorText}
        />
      </Box>
    </Flex>
  );
};
