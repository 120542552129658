import { FormWrapper } from 'components/common';
import { Grid } from '@chakra-ui/react';
import React, { Fragment, useEffect, useMemo } from 'react';

import { getSelectOptionsFromDictionaryByName } from 'features/get-select-options-from-dictionary';
import { useFormikContext } from 'formik';
import { TDictionariesArray } from 'models/dictionaries';
import { SnowDeicingItem } from 'pages/data/PGR/Snow/useSnowDeicingList';
import { IRenderFieldInterface, useFormField } from 'hooks/forms/useFormField';
import appLocale from 'constants/appLocale';
import { ANALYTICS_STATUS } from 'pages/analytics/forms/const';
import { AnalyticsSnowSchema } from 'models/schemas/analytics/analyticsSnowSchema';

interface CommonTabProps {
  dictionaries: {
    data: TDictionariesArray;
  } | null;
  isEditMode?: boolean;
}

export const AnalyticsSnowCommonTab = ({ dictionaries, isEditMode }: CommonTabProps): JSX.Element => {
  const { values, setFieldValue, initialValues } = useFormikContext<SnowDeicingItem>();

  const { renderField } = useFormField<SnowDeicingItem>({
    schema: AnalyticsSnowSchema,
    labelLocalization: { ...appLocale.analytics.fields, ...appLocale.analytics.pgrSnow.fields },
  });

  const organizations = useMemo(
    () =>
      getSelectOptionsFromDictionaryByName(
        dictionaries?.data,
        'organizations',
        false,
        [initialValues.consumerId, initialValues.organizationId],
      ),
    [dictionaries?.data, initialValues.consumerId, initialValues.organizationId],
  );

  const areas = useMemo(() => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'areas'), [dictionaries]);

  const points = useMemo(
    () =>
      getSelectOptionsFromDictionaryByName(dictionaries?.data, 'points').filter(
        (item) => item.parent === values.areaId,
      ),
    [dictionaries, values.areaId],
  );

  useEffect(() => {
    if (values.pointId && dictionaries) {
      const point = points.find((item) => item.value === values.pointId);
      if (point?.parent !== values.areaId) {
        setFieldValue('pointId', null);
      }
    }
  }, [dictionaries, points, setFieldValue, values.areaId, values.pointId]);

  // while status is hidden assume status as draft
  let fieldsIsDisabled = values.status === ANALYTICS_STATUS.APPROVED && !!isEditMode;
  fieldsIsDisabled = false;

  const fields: IRenderFieldInterface<SnowDeicingItem>[] = [
    { name: 'protocolNumber', isDisabled: fieldsIsDisabled },

    { name: 'protocolDate', type: 'dateinput', isDisabled: fieldsIsDisabled },

    { name: 'consumerId', type: 'select', options: organizations, isDisabled: fieldsIsDisabled },

    { name: 'researchObject', isDisabled: fieldsIsDisabled },

    { name: 'areaId', type: 'select', options: areas, isDisabled: fieldsIsDisabled },

    { name: 'pointId', type: 'select', options: points, isDisabled: fieldsIsDisabled },

    { name: 'organizationId', type: 'select', options: organizations, isDisabled: fieldsIsDisabled },

    { name: 'methodology', isDisabled: fieldsIsDisabled },

    { name: 'sampleAct', isDisabled: fieldsIsDisabled },

    { name: 'sampleLaboratoryNumber', isDisabled: fieldsIsDisabled },

    { name: 'samplePeriodStart', type: 'datetimeinput', isDisabled: fieldsIsDisabled },

    { name: 'samplePeriodEnd', type: 'datetimeinput', isDisabled: fieldsIsDisabled },

    { name: 'sampleReceiveDate', type: 'datetimeinput', isDisabled: fieldsIsDisabled },

    { name: 'researchDateStart', type: 'dateinput', isDisabled: fieldsIsDisabled },

    { name: 'researchDateEnd', type: 'dateinput', isDisabled: fieldsIsDisabled },

    { name: 'explanation', isDisabled: fieldsIsDisabled },

    { name: 'researchBasis', isDisabled: fieldsIsDisabled },

    { name: 'supportingDocuments', isDisabled: fieldsIsDisabled },
  ];
  return (
    <FormWrapper>
      <Grid marginTop="30px" gap="15px">
        {fields.map((field) => (
          <Fragment key={field.name}>{renderField(field)}</Fragment>
        ))}
      </Grid>
    </FormWrapper>
  );
};
