import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import { SubsystemHeader } from 'components/common';
import { useHistory } from 'react-router-dom';
import { useList } from 'hooks';
import { MaterialTable } from 'features/material-table/MaterialTableMini';
import React, { useMemo } from 'react';
import { PropActions } from 'features/material-table/components/Actions';
import { Column } from '@material-table/core';
import { addFiltersToColumns, ColumnWithFilter } from 'lib/utils/tableFilter';
import { transformFiltersArray } from 'features/Filter/transformFiltersArray';
import appLocale from 'constants/appLocale';
import { NSIReagentsApiPath, NSIReagentsRootPath, NSIReagentsTreesPaths } from 'pages/dictionaries/NSIReagents/paths';
import { useDictionary } from 'hooks/useDictionary';
import {
  NSIReagentsDictionaryKeys,
  NSIReagentsListItem,
  NSIReagentsListType,
} from 'pages/dictionaries/NSIReagents/types';
import { TableFilterTypeEnum } from 'components/table/filters/TableFilter';
import NSIReagentsTreesFilters from 'pages/dictionaries/NSIReagents/NSIReagentsTrees/NSIReagentsTreesFilters';
import { subsystemCodesMap } from 'constants/subsystemIdsMap';
import { useCustomRights } from 'hooks/useCustomRights';
import { FullPageWrapper } from '../../../../components/table/FullPageWrapper';
import { DEFAULT_PAGE_SIZE_NEW_TABLE } from '../../../../constants/tables';
import { DefaultTableCellSize } from '../../../../components/table/DefaultTableCellSize';

const NSIReagentsTrees = () => {
  useUpdateBreadcrumbs([
    { breadcrumb: appLocale.nsi.reagents.title, path: NSIReagentsRootPath },
    { breadcrumb: appLocale.nsi.reagents.trees.title, path: NSIReagentsTreesPaths.root },
  ]);
  const { isWrite } = useCustomRights(subsystemCodesMap.nsi.pgr.flora);
  const { push } = useHistory();

  const {
    isDictionariesLoading,
    okrugs,
    rayons,
    statuses,
    areasForTrees,
    lifeBreeds,
    lifeForms,
    areaTypes,
    areaLocations,
  } = useDictionary<NSIReagentsDictionaryKeys>({
    url: `${NSIReagentsApiPath}/dictionaries`,
  });

  const {
    isLoading: isListLoading,
    response,
    toggleOrder,
    setFilters,
    onPageChange,
    filterDTO,
    onSearchText,
    downloadAsFile,
    errorText,
    materialTableProps,
  } = useList<NSIReagentsListItem>(`${NSIReagentsApiPath}/list/${NSIReagentsListType.tree}`, {
    initialOrderFieldKey: '-number',
    saveFiltersState: true,
    defaultPageSize: DEFAULT_PAGE_SIZE_NEW_TABLE,
    resetDataOnNextRequest: true,
  });

  const actions = useMemo<PropActions<NSIReagentsListItem[]>>(
    () => [
      {
        type: 'edit',
        onClick: (e, item: any) => {
          push(NSIReagentsTreesPaths.edit(item.id));
        },
        disabled: !isWrite,
        hidden: !isWrite,
      },
    ],
    [isWrite, push]
  );

  const columns = useMemo<ColumnWithFilter<NSIReagentsListItem>[]>(
    () => [
      {
        title: 'Номер площадки',
        field: 'parentAreaId',
        render: (row) => areasForTrees?.find((item) => item.value === row.parentAreaId)?.name,
      },
      {
        title: 'Тип территории',
        field: 'greenAreaTypeId',
        render: (row) => areaTypes?.find((item) => item.value === row.greenAreaTypeId)?.name,
      },
      {
        title: 'Номер насаждения',
        field: 'number',
      },
      {
        title: 'Порода',
        field: 'greenPlantLifeBreedId',
        render: (row) => lifeBreeds?.find((item) => item.value === row.greenPlantLifeBreedId)?.name,
      },
      {
        title: 'Жизненная форма',
        field: 'greenPlantLifeFormId',
        render: (row) => lifeForms?.find((item) => item.value === row.greenPlantLifeFormId)?.name,
      },
      {
        title: 'Местоположение растительной группировки',
        field: 'greenAreaLocationId',
        render: (row) => areaLocations?.find((item) => item.value === row.greenAreaLocationId)?.name,
      },
      {
        title: 'Округ',
        field: 'okrugId',
        render: (row) => okrugs?.find((item) => item.value === row.okrugId)?.name,
      },
      {
        title: 'Район',
        field: 'rayonId',
        render: (row) => rayons?.find((item) => item.value === row.rayonId)?.name,
      },
      {
        title: 'Адрес',
        field: 'address',
        render: (row) => <DefaultTableCellSize>{row.address}</DefaultTableCellSize>,
      },
      {
        title: 'Адресный ориентир',
        field: 'landmark',
        filterType: TableFilterTypeEnum.string,
        render: (row) => <DefaultTableCellSize>{row.landmark}</DefaultTableCellSize>,
      },
      {
        title: 'Статус',
        field: 'status',
        render: (row) => statuses?.find((item) => item.value === row.status)?.name,
      },
    ],
    [areaLocations, areaTypes, areasForTrees, lifeBreeds, lifeForms, okrugs, rayons, statuses]
  );

  const columnsWithFilters = useMemo<Array<Column<NSIReagentsListItem>>>(
    () => addFiltersToColumns({ filters: filterDTO.filters, setFilters, columns }),
    [columns, filterDTO.filters, setFilters]
  );

  const isLoading = useMemo(() => isListLoading || isDictionariesLoading, [isDictionariesLoading, isListLoading]);

  return (
    <FullPageWrapper>
      <SubsystemHeader
        title={appLocale.nsi.reagents.trees.title}
        addButtonProps={{
          onClick: () => push(NSIReagentsTreesPaths.add),
          isDisabled: !isWrite,
        }}
        filtersComponent={
          <NSIReagentsTreesFilters
            isLoading={isLoading}
            onSubmit={(val) => setFilters(transformFiltersArray(Object.values(val)))}
            okrugs={okrugs}
            rayons={rayons}
            statuses={statuses}
            areas={areasForTrees}
            areaTypes={areaTypes}
            lifeForms={lifeForms}
            lifeBreeds={lifeBreeds}
            filters={filterDTO.filters}
          />
        }
      />

      <MaterialTable
        data={response?.data}
        meta={response?.meta}
        toggleOrder={toggleOrder}
        filterDTO={filterDTO}
        onPageChange={onPageChange}
        columns={columnsWithFilters}
        actions={actions}
        isLoading={isLoading}
        setFilters={setFilters}
        options={{ search: true, selection: false }}
        onSearch={onSearchText}
        downloadAsFile={downloadAsFile}
        showTotalCount
        errorText={errorText}
        {...materialTableProps}
      />
    </FullPageWrapper>
  );
};

export default NSIReagentsTrees;
