import React, { useCallback, useMemo } from 'react';
import { COMMON_LOCALE } from 'constants/common';
import { Button, DateInput, Input, NumberInput, Select } from 'components/common';

import { Field, useFormikContext } from 'formik';
import { Station } from 'pages/equipment/Stations/types';
import { createLocalDateISO8601 } from 'lib/create-date';
import { Flex } from '@chakra-ui/react';
import { TDictionariesArray } from 'models';
import { getSelectOptionsFromDictionaryByName } from 'features/get-select-options-from-dictionary';

interface StationFormFieldsProps {
  onCancel: () => void;
  dictionaries: { data: TDictionariesArray } | null;
  isEditMode?: boolean
}

export const StationFormFields: React.FC<StationFormFieldsProps> = ({ dictionaries, onCancel, isEditMode }) => {
  const { handleSubmit, errors, setFieldValue, values, initialValues } = useFormikContext<Station>();
  const onSubmit = useCallback(() => {
    handleSubmit();
  }, [handleSubmit]);

  const statuses = useMemo(
    () => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'statuses', false, initialValues.stationStatus),
    [dictionaries?.data, initialValues.stationStatus],
  );
  const typesOptions = useMemo(() => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'types'), [dictionaries]);
  const ownersOptions = useMemo(
    () => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'owners', false, initialValues.stationOwner),
    [dictionaries?.data, initialValues.stationOwner],
  );

  return (
    <>
      <Field as={NumberInput} name="stationNumber" label="Номер станции" error={errors.stationNumber} isDisabled={isEditMode} />
      <Field as={Input} name="stationName" label="Название станции" error={errors.stationName} />
      <Field as={Select} name="stationStatus" label="Статус" options={statuses} error={errors.stationStatus} />
      <Field as={Select} name="stationType" label="Тип станции" options={typesOptions} error={errors.stationType} />
      {values.stationType === 'MEL' && <Field as={Input} name="registrationNumber" label="Регистрационный номер" />}
      <Field
        as={Select}
        name="stationOwner"
        label="Владелец станции"
        options={ownersOptions}
        error={errors.stationOwner}
      />
      <Field
        as={DateInput}
        name="stationStartOperation"
        label="Дата ввода в эксплуатацию"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setFieldValue('stationStartOperation', createLocalDateISO8601(e.target.value));
        }}
      />
      <Field
        as={DateInput}
        name="stationEndOperation"
        label="Дата вывода из эксплуатации"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setFieldValue('stationEndOperation', createLocalDateISO8601(e.target.value));
        }}
      />
      <Field as={Input} name="comment" label="Примечание" />
      <Flex>
        <Button onClick={onSubmit}>{COMMON_LOCALE.save}</Button>
        <Button onClick={onCancel} variant="ghost" marginRight={5}>
          {COMMON_LOCALE.cancel}
        </Button>
      </Flex>
    </>
  );
};
