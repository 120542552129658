import React, { useEffect, useMemo } from 'react';
import { Field, FieldArray, useFormikContext } from 'formik';
import { Box, Flex, Grid, Heading, Text } from '@chakra-ui/react';
import { DateInput, FormWrapper, Input } from 'components/common';
import { TDictionariesArray } from 'models/dictionaries';
import { getSelectOptionsFromDictionaryByNameWithParent } from 'features/get-select-options-from-dictionary';
import { createDateToRequestWithTimezone } from 'lib/create-date';
import RegistryDtos from 'pages/data/Meteo/AISWeather/AISWeather/Forms/RegistryDtos';
import { AISWeatherSaveItem } from 'hooks/aisWeather/useAISWeatherRecord';
import isRequiredInSchema from 'lib/utils/forms/isReqiuredInSchema';
import { aisWeatherSchema } from 'models/schemas/meteo/aisWeatherSchema';
import { get } from 'lodash';

interface CommonTabProps {
  dictionaries: {
    data: TDictionariesArray;
  } | null;
  name: string;
  stationIndex: number;
  stationId: string;
  isEdit: boolean;
  isAvailable?: boolean;
}

export default function MastYTab({ dictionaries, name, stationIndex, stationId, isEdit, isAvailable }: CommonTabProps) {
  const { errors, setFieldValue, values } = useFormikContext<AISWeatherSaveItem>();

  useEffect(() => {
    setFieldValue(`stations.${stationIndex}.stationId`, stationId);
  }, [setFieldValue, stationId, stationIndex]);

  const heights = useMemo(
    () =>
      getSelectOptionsFromDictionaryByNameWithParent(dictionaries?.data, 'heights').filter(
        (el) => el.parent === stationId
      ),
    [dictionaries, stationId]
  );

  const stationsError = get(errors.stations?.[stationIndex], 'registryDtos');

  return (
    <Box flexDirection="column">
      <FormWrapper>
        <Grid marginTop={30} gap={15} marginBottom={30}>
          <Heading variant="h2">
            Данные&nbsp;{name}
          </Heading>

          <Field
            as={DateInput}
            label="Дата и время"
            name="packageDatetime"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFieldValue('packageDatetime', createDateToRequestWithTimezone(e.target.value));
            }}
            showTimeInput
            isDisabled={isEdit}
            isRequired={isRequiredInSchema(aisWeatherSchema, 'packageDatetime')}
            error={errors.packageDatetime}
          />

          <Box flexDirection="column">
            <Grid margin="30px 0px 20px 0px" gap="15px">
              <FieldArray name="stationId">
                {() => (
                  <Box flexDirection="column">
                    {heights.length > 0 &&
                      Object.keys(values).length &&
                      heights.map((data, index) => {
                        const dotIndex = values.stations?.[stationIndex]?.registryDtos?.findIndex(
                          (item: any) => data.value === item.heightId
                        );
                        const currentIndex = isEdit ? dotIndex : index;
                        return (
                          <RegistryDtos
                            key={data.value}
                            id={data.value}
                            index={index}
                            stationIndex={stationIndex}
                            date={values.packageDatetime}
                          >
                            <Flex minHeight="74px" marginBottom="16px" alignItems="flex-start">
                              <Grid
                                gap="15px"
                                flexGrow={1}
                                marginRight={15}
                                alignItems="flex-start"
                                templateColumns="150px 250px 200px 250px"
                              >
                                <Text fontSize={18} fontWeight={700}>
                                  {`Высота ${data.name}`}
                                </Text>
                                <Field
                                  as={Input}
                                  name={`stations.${stationIndex}.registryDtos.${currentIndex}.windDirection`}
                                  label="Направление ветра, град пл угла"
                                  isDisabled={!isAvailable}
                                  error={get(stationsError, `${currentIndex}.windDirection`)}
                                />
                                <Field
                                  as={Input}
                                  name={`stations.${stationIndex}.registryDtos.${currentIndex}.instantaneousWind`}
                                  label="Скорость ветра МГН, м/c"
                                  isDisabled={!isAvailable}
                                  error={get(stationsError, `${currentIndex}.instantaneousWind`)}
                                />
                                <Field
                                  as={Input}
                                  name={`stations.${stationIndex}.registryDtos.${currentIndex}.maxWindSpeed`}
                                  label="Скорость ветра, МАКС 10"
                                  isDisabled={!isAvailable}
                                  error={get(stationsError, `${currentIndex}.maxWindSpeed`)}
                                />
                              </Grid>
                            </Flex>
                          </RegistryDtos>
                        );
                      })}
                  </Box>
                )}
              </FieldArray>
            </Grid>
          </Box>
        </Grid>
      </FormWrapper>
    </Box>
  );
}
