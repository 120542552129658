import { FormikProvider, useFormik } from 'formik';
import React, { useEffect } from 'react';
import { IFilterItem } from 'models';
import { AppealsTableViewFields } from './AppealsTableViewFields';
import { appealsInitialValues } from '../Consts/consts';
import { TDictionaries } from '../../../pel/PelRaidsTableView/types/types';
import { FiltersDatepickersSchema } from 'models/schemas/filtersDatepickersSchema/FiltersDatepickersSchema';
import combineFilters from 'lib/utils/combineFilters';

interface IProps {
  onSubmit: (values: { [p: string]: IFilterItem }) => void;
  type: number;
  appealsDictionaries: TDictionaries;
  filters: IFilterItem[];
  isLoading: boolean;
}

export const AppealsTableViewFilter = ({ onSubmit, type, appealsDictionaries, filters, isLoading }: IProps) => {
  const { resetForm, values, errors, handleReset, submitForm, setFieldValue, ...formik } = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    validationSchema: FiltersDatepickersSchema([
      { dateFromFieldName: 'complaintDateMin', dateToFieldName: 'complaintDateMax' },
      { dateFromFieldName: 'assigmentDateMin', dateToFieldName: 'assigmentDateMax' },
    ]),
    initialValues: appealsInitialValues,
    onSubmit,
  });

  useEffect(() => {
    resetForm();
  }, [type, resetForm]);

  useEffect(() => {
    const initialFilters = combineFilters(appealsInitialValues, filters);
    Object.keys(initialFilters).forEach((key) => {
      setFieldValue(`${key}.value`, initialFilters[key].value);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <FormikProvider
        value={{
          resetForm,
          values,
          errors,
          handleReset,
          submitForm,
          setFieldValue,
          ...formik,
        }}
      >
        <AppealsTableViewFields isLoading={isLoading} type={type} appealsDictionaries={appealsDictionaries} />
      </FormikProvider>
    </>
  );
};
