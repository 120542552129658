import { Field, useFormikContext } from 'formik';
import { IForecastAddItemEntity, IForecastMainForm } from 'pages/data/Meteo/Forecast/Data/consts/types';
import { Grid, Text } from '@chakra-ui/react';
import { Input } from 'components/common';
import { fieldsLocale } from 'pages/data/Meteo/Forecast/Data/consts/locale';

const ForecastItemWindForm = ({ entity }: IForecastAddItemEntity): JSX.Element => {
  const { values, handleChange, errors } = useFormikContext<IForecastMainForm>();

  return (
    <>
      <Text fontSize="xl" mt="25">
        Ветер
      </Text>
      <Grid marginTop="15px" gap="15px">
        <Field
          as={Input}
          name={`${entity}.windDirection`}
          label={fieldsLocale.windDirection}
          onChange={handleChange}
          error={errors[entity]?.windDirection}
        />
        <Field
          as={Input}
          name={`${entity}.windSpeed`}
          label={fieldsLocale.windSpeed}
          value={values[entity]?.windSpeed}
          onChange={handleChange}
          error={errors[entity]?.windSpeed}
        />
        <Field
          as={Input}
          name={`${entity}.windFlawValues`}
          label={fieldsLocale.windFlawValues}
          value={values[entity]?.windFlawValues}
          onChange={handleChange}
          error={errors[entity]?.windFlawValues}
        />
        <Field
          as={Input}
          name={`${entity}.windDirectionPSA`}
          label={fieldsLocale.windDirectionPSA}
          onChange={handleChange}
          error={errors[entity]?.windDirectionPSA}
        />
        <Field
          as={Input}
          name={`${entity}.windSpeedPSA`}
          label={fieldsLocale.windSpeedPSA}
          value={values[entity]?.windSpeedPSA}
          onChange={handleChange}
          error={errors[entity]?.windSpeedPSA}
        />
      </Grid>
    </>
  );
};

export { ForecastItemWindForm };
