import { createTouchedErrors } from 'features/create-touched-errors';
import { FormikProvider, useFormik } from 'formik';
import { useRequest } from 'hooks/useRequest';
import { useParams } from 'react-router-dom';
import FieldsForm from 'pages/PDKExcessJournal/Form/FieldsForm';
import { useEffect } from 'react';
import { initialEmptyValues } from './AddPDKExcessJournalItem';
import { ISOKJournalItem } from 'pages/PDKExcessJournal/types';
import { useEnhancedNotification } from 'hooks';
import { PdkJournalSchema } from 'models/schemas/pdk/pdkJournalSchema';
import { useGoBackWithFallback } from 'lib/utils/goBackWithFallback';
import { useDictionary } from 'hooks/useDictionary';

export const EditPDKExcessJournalItem = () => {
  const { backWithFallback } = useGoBackWithFallback();
  const { isDictionariesLoading, dictionaries } = useDictionary({ url: '/appeal/v10/sok/journal/dictionaries' });
  const { id } = useParams<{ id: string }>();
  const { onErrorEdit, onSuccessEdit } = useEnhancedNotification();
  const {
    isLoading: isDangerListLoading,
    result,
    get,
  } = useRequest<ISOKJournalItem>(`/appeal/v10/sok/journal/${id}`);

  useEffect(() => {
    void get();
  }, [get]);

  const initialValues = {
    ...initialEmptyValues,
    ...result,
  };

  const successHandler = () => {
    onSuccessEdit();
    backWithFallback('/pdk-excess-journal');
  };

  const { put } = useRequest<ISOKJournalItem>(`/appeal/v10/sok/journal/${id}`, successHandler, onErrorEdit);

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    initialValues,
    validationSchema: PdkJournalSchema,
    onSubmit: (values) => put(values),
  });

  return (
    <FormikProvider
      value={{
        ...formik,
        errors: createTouchedErrors(formik.errors, formik.touched),
      }}
    >
      <FieldsForm
        dictionaries={dictionaries}
        onCancel={() => backWithFallback('/pdk-excess-journal')}
        isLoading={isDictionariesLoading || isDangerListLoading}
        isEditMode
      />
    </FormikProvider>
  );
};
