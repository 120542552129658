import React, { FC, useEffect, useMemo } from 'react';
import { FormWrapper, Input } from 'components/common';
import { getSelectOptionsFromDictionaryByName } from 'features/get-select-options-from-dictionary';
import { Field, FieldArray, useFormikContext } from 'formik';
import { TDictionariesArray } from 'models/dictionaries';
import { WashoutDeicingItem } from '../useWashoutDicingList';
import { Box, Grid } from '@chakra-ui/react';
import { MoreLessNumberInput } from '../../../../../../components/common/Input/more-less-number-input';
import { get } from 'lodash';

interface ParametersTabProps {
  dictionaries: {
    data: TDictionariesArray;
  } | null;
  isWrite?: boolean;
  isAnalytics?: boolean;
}

export const ParametersTab: FC<ParametersTabProps> = ({ children, dictionaries, isWrite, isAnalytics }) => {
  const { values, setFieldValue } = useFormikContext<WashoutDeicingItem>();

  const parameters = useMemo(
    () => getSelectOptionsFromDictionaryByName(
      dictionaries?.data,
      'parameters1',
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getSelectOptionsFromDictionaryByName, dictionaries],
  );

  useEffect(() => {
    const valueParameterIds = [...values.parameters].map((val) => val.parameterId);

    parameters.forEach((dictVal, index) => {
      // @ts-ignore
      if (!valueParameterIds.includes(dictVal.parameterId)) {
        setFieldValue(`parameters[${index}]`, {
          ...dictVal,
          value: null,
          name: dictVal.name,
          parameterId: dictVal.parameterId,
        });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parameters]);

  return (
    <Box flexDirection="column">
      <FormWrapper>
        <Grid marginTop="30px" gap="15px" marginBottom={20}>
          <FieldArray name="parameters">
            {(props: any) => (
              <>
                {values.parameters.map((el, index) => {
                  const { errors } = props.form;
                  const label = parameters.find((item) => item.parameterId === el.parameterId)?.title;
                  const name = `parameters[${index}]`;
                  return (
                    <Field
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setFieldValue(`parameters[${index}].value`, e.target.value);
                      }}
                      key={index}
                      error={errors && errors.parameters && errors.parameters[index] && errors.parameters[index].value}
                      as={isAnalytics ? MoreLessNumberInput : Input}
                      isDisabled={!isWrite}
                      name={name}
                      label={label}
                      value={get(values, isAnalytics ? name : `${name}.value`)}
                    />
                  );
                })}
              </>
            )}
          </FieldArray>
          {children}
        </Grid>
      </FormWrapper>
    </Box>
  );
};
