import React, { useEffect, useState } from 'react';
import FormComponent from 'pages/data/Meteo/Forecast/Data/forms/FormComponent';
import { Flex, Text } from '@chakra-ui/react';
import { Button, DateInput, FormHeader, FormWrapper, LoaderItem } from 'components/common';
import { forecastFormInitialValues, IForecastMainForm } from 'pages/data/Meteo/Forecast/Data/consts/types';
import {
  lastDayPeriodDateTimeISO,
  lastNightPeriodDateTimeISO,
  todayPeriodDateTimeISO,
  tomorrowPeriodDateTimeISO,
  tonightPeriodDateTimeISO,
} from 'pages/data/Meteo/Forecast/Data/consts/methods';
import { useNotifications } from 'hooks/useNotifications';
import { ERROR_MESSAGES, SUCCESS_MESSAGES } from 'constants/messages';
import { useRequest } from 'hooks/useRequest';
import { FormikProvider, useFormik } from 'formik';
import { ForecastRootPath } from 'pages/data/Meteo/menu';
import { useGoBackWithFallback } from 'lib/utils/goBackWithFallback';

const ForecastAddItem = () => {
  const { backWithFallback } = useGoBackWithFallback();
  const [forecastDate, setForecastDate] = useState<Date | null>(new Date());
  const [secondStepForm, setSecondStepForm] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const currentDate = todayPeriodDateTimeISO(forecastDate);

  const updatedValues = (values: IForecastMainForm) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const key in values) {
      if (key === 'lastDay') {
        values[key].date = lastDayPeriodDateTimeISO(forecastDate);
      } else if (key === 'lastNight') {
        values[key].date = lastNightPeriodDateTimeISO(forecastDate);
      } else if (key === 'today') {
        values[key].date = todayPeriodDateTimeISO(forecastDate);
      } else if (key === 'tonight') {
        values[key].date = tonightPeriodDateTimeISO(forecastDate);
      } else if (key === 'tomorrow') {
        values[key].date = tomorrowPeriodDateTimeISO(forecastDate);
      }
    }

    return Object.assign(values, { id: null, date: currentDate });
  };

  const { successNotify, errorNotify } = useNotifications();

  const onSuccess = () => {
    successNotify({
      key: 'file/create/success',
      message: SUCCESS_MESSAGES.CREATE,
    });
    backWithFallback(ForecastRootPath);
  };

  const onError = () => {
    errorNotify({
      key: 'file/create/error',
      message: ERROR_MESSAGES.CREATE,
    });
  };

  const { post, isLoading } = useRequest('/meteo/v10/forecast', onSuccess, onError);
  const form = useFormik({
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    initialValues: forecastFormInitialValues,
    // TODO: uncomment when validation will be need
    // validationSchema: ForecastSchema,
    onSubmit: (values) => post(updatedValues(values)),
  });

  useEffect(() => {
    setIsDisabled(form.dirty);
  }, [form.dirty]);

  if (isLoading) {
    return <LoaderItem />;
  }

  if (secondStepForm && forecastDate) {
    return (
      <FormikProvider value={form}>
        <FormComponent forecastDate={forecastDate} isDisabled={isDisabled} />
      </FormikProvider>
    );
  }
  return (
    <>
      <Flex flexDirection="column">
        <FormHeader onBackClick={() => backWithFallback(ForecastRootPath)} header="Добавление протокола" gap={4} />
        <FormWrapper>
          <Flex flexDirection="column">
            <Text fontSize="xl" pt="45" pb="15">
              Общие сведения протокола
            </Text>
            <DateInput
              label="Дата протокола"
              value={forecastDate}
              showTimeInput={false}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setForecastDate(event.target.value ? new Date(event.target.value) : null)
              }
            />
            <Button variant="solid" colorScheme="PrimaryButton" mt="10" onClick={() => setSecondStepForm(true)}>
              Далее
            </Button>
          </Flex>
        </FormWrapper>
      </Flex>
    </>
  );
};

export { ForecastAddItem };
