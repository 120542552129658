export const fieldsLocale = {
  modelName: 'Модель',
  inventNumber: 'Инвентарный номер',
  serialNumber: 'Серийный номер',
  dateExpired: 'Срок истечения поверки',
  localDate: 'Дата ввода в эксплуатацию',
  status: 'Статус',
  stationName: 'Станция',
  stationAddress: 'Адрес станции',
};

export const reportsLocale = {
  measureDevices: 'Измерительные приборы',
  reports: 'Отчеты',
  table: 'Таблица',
  diagram: 'Диаграмма',
};

export const deviceVerificationLocale = {
  name: 'Номер свидетельства о поверке',
  date: 'Дата выдачи свидетельства',
  dateStart: 'Начало действия поверки',
  dateExpired: 'Окончание действия поверки',
  status: 'Статус',
};

export const statusLocale: Record<string, string> = {
  IDLE: 'истёк',
  INWORK: 'действует',
  BROKEN: 'не поверен',
};
