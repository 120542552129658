import React, { useCallback } from 'react';

import { useToast } from '@chakra-ui/react';
import { Notification, NotificationProps } from 'components/common/Notification';
import { LightModeWrapper } from 'components/common/LightModeWrapper/LightModeWrapper';

/* NOTIFICATIONS */

interface BaseNotification {
  message: string;
  key: string;
}

export const useNotifications = (duration = 5000) => {
  const toast = useToast();

  const baseNotify = useCallback(
    (notificationProps: NotificationProps & BaseNotification) => {
      if (!toast.isActive(notificationProps.key)) {
        toast({
          id: notificationProps.key,
          position: 'bottom-left',
          duration,
          render: ({ onClose }) => (
            <LightModeWrapper>
              {React.createElement(
                Notification,
                {
                  key: notificationProps.key,
                  variant: notificationProps.variant,
                  onClose: () => {
                    if (notificationProps.onClose) {
                      notificationProps.onClose();
                    }
                    onClose();
                  },
                },
                notificationProps.message,
              )}
            </LightModeWrapper>
          ),
        });
      }
    },
    [duration, toast],
  );

  const successNotify = useCallback(
    (notificationProps: Partial<NotificationProps> & BaseNotification) =>
      baseNotify({ ...notificationProps, variant: 'success' }),
    [baseNotify],
  );

  const errorNotify = useCallback(
    (notificationProps: Partial<NotificationProps> & BaseNotification) =>
      baseNotify({ ...notificationProps, variant: 'error' }),
    [baseNotify],
  );

  const warningNotify = useCallback(
    (notificationProps: Partial<NotificationProps> & BaseNotification) =>
      baseNotify({ ...notificationProps, variant: 'warning' }),
    [baseNotify],
  );

  return {
    baseNotify,
    successNotify,
    errorNotify,
    warningNotify,
  };
};
