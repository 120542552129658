import React from 'react';
import { PageHeader, RoutesList } from 'components/common';
import appLocale from 'constants/appLocale';
import { GeoecologicalRootPath } from 'pages/data/GeoecologicalProcesses/consts';
import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import { Flex, Heading } from '@chakra-ui/react';

export const GeologicalProcessesList: React.FC = () => {
  useUpdateBreadcrumbs([
    { breadcrumb: appLocale.common.data, path: '/data' },
    {
      breadcrumb: appLocale.subsystems.geoecologicalprocesses,
      path: `${GeoecologicalRootPath}`,
    },
  ]);

  return (
    <Flex flexDirection="column">
      <PageHeader>
        <Heading>{appLocale.subsystems.geoecologicalprocesses}</Heading>
      </PageHeader>
      <RoutesList
        routes={[
          {
            path: `${GeoecologicalRootPath}/monitoring/danger/dangerprocess`,
            breadcrumb: 'Опасные геологические процессы',
          },
          {
            path: `${GeoecologicalRootPath}/monitoring/water/examination`,
            breadcrumb: appLocale.subsystems.groundwater,
          },
          {
            path: `${GeoecologicalRootPath}/monitoring/protocol/water/examination`,
            breadcrumb: `${appLocale.common.dataProtocol} (${appLocale.subsystems.groundwater})`,
          },
          {
            path: `${GeoecologicalRootPath}/monitoring/protocol/danger/dangerprocess`,
            breadcrumb: `${appLocale.common.dataProtocol} (Опасные геологические процессы)`,
          },
        ]}
      />
    </Flex>
  );
};
