import { SubsystemHeader } from 'components/common';
import { useHistory } from 'react-router-dom';
import { memo } from 'react';
import appLocale from 'constants/appLocale';

interface SoilHeaderProps {
  onOpenLoad(): void;
  url: string;
  isWrite?: boolean;
}

export const SoilHeader: React.FC<SoilHeaderProps> = memo(({
  onOpenLoad,
  children,
  url,
  isWrite,
}) => {
  const history = useHistory();

  const handleAddButtonClick = () => history.push(`${history.location.pathname}${url}`);

  return (
    <SubsystemHeader
      title={appLocale.subsystems.soil}
      addButtonProps={{ onClick: handleAddButtonClick, isDisabled: !isWrite }}
      onUploadClick={isWrite ? onOpenLoad : undefined}
      filtersComponent={children}
    />
  );
});
