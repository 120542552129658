import { useDisclosure } from '@chakra-ui/react';
import React, { useCallback, useMemo, useState } from 'react';
import ForecastReportModal from 'pages/data/Meteo/Forecast/ForecastReport/ForecastReportModal';

function useForecastReportModal() {
  const [id, setId] = useState<string>();
  const disclosure = useDisclosure();

  const modalOnClose = useCallback(() => {
    disclosure.onClose();
    setId(undefined);
  }, [disclosure]);

  const forecastModal = useMemo(() => {
    if (!id) return null;

    return <ForecastReportModal id={id} isOpen={disclosure.isOpen} onClose={modalOnClose} />;
  }, [disclosure.isOpen, id, modalOnClose]);

  const openModal = useCallback<(id: string) => void>(
    (id) => {
      setId(id);
      disclosure.onOpen();
    },
  [disclosure]);
  return {
    openModal,
    forecastModal,
  };
}

export default useForecastReportModal;
