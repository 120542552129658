import { Route } from 'react-router-dom';
import { Forecast } from 'pages/data/Meteo/Forecast';
import { withCheckExistPreview } from 'features/UploadedFileProvider/withCheckExistPreview';
import { ForecastLoadPreview } from 'pages/data/Meteo/Forecast/Data/ForecastLoadPreview/ForecastLoadPreview';
import { ForecastAddItem } from 'pages/data/Meteo/Forecast/Data/ForecastAddItem';
import { ForecastEditItem } from 'pages/data/Meteo/Forecast/Data/ForecastEditItem';
import { ForecastRootPath, MeteoDataRootPath } from 'pages/data/Meteo/menu';
import ProtocolForecast from 'pages/data/Meteo/ProtocolForecast/ProtocolForecast';
import { ForecastMenu } from 'pages/data/Meteo/ForecastMenu';
import ForecastReport from 'pages/data/Meteo/Forecast/ForecastReport/ForecastReport';
import { WithDidMountResetUploadFile } from 'features/UploadedFileProvider/hocs';

const ForecastRoutes = () => (
  <>
    <Route exact path={`${MeteoDataRootPath}/forecast`} component={ForecastMenu} />

    <Route exact path={ForecastRootPath} render={() => <WithDidMountResetUploadFile Component={Forecast} />} />
    <Route
      exact
      path={`${ForecastRootPath}/load/preview/:typeId`}
      component={withCheckExistPreview(ForecastLoadPreview, ForecastRootPath)}
    />
    <Route
      exact
      path={`${ForecastRootPath}/add`}
      render={() => <WithDidMountResetUploadFile Component={ForecastAddItem} />}
    />
    <Route
      exact
      path={`${ForecastRootPath}/edit/:id`}
      render={() => <WithDidMountResetUploadFile Component={ForecastEditItem} />}
    />

    <Route exact path={`${MeteoDataRootPath}/forecast/protocol`} component={ProtocolForecast} />

    <Route exact path={`${MeteoDataRootPath}/forecast/data/report`} component={ForecastReport} />
  </>
);

export { ForecastRoutes };
