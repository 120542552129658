import { Flex, Grid, Tooltip } from '@chakra-ui/react';
import { Field, useFormikContext } from 'formik';
import { Button, DateInput, Select } from 'components/common';
import appLocale from 'constants/appLocale';
import React from 'react';
import { createUTCTime } from 'lib/create-date';
import { IFilterItem } from 'models';
import { ExtensionToMimeTypeEnum } from '../../../../../../../lib/utils/files/getFileExtensionByMimeType';

export const SOKEnvironmentalFormFields = () => {
  const { setFieldValue, errors, handleReset, submitForm } = useFormikContext<{ [p: string]: IFilterItem }>();
  return (
    <>
      <Grid margin={2} gap="12px" rowGap="8px" templateColumns="repeat(4, 1fr)">
        <Field
          as={DateInput}
          name="start"
          label={appLocale.common.from}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFieldValue('start', createUTCTime(e.target.value))}
          showTimeInput
          error={errors.start}
        />

        <Field
          as={DateInput}
          name="stop"
          label={appLocale.common.to}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFieldValue('stop', createUTCTime(e.target.value))}
          showTimeInput
          error={errors.stop}
        />

        <Field
          as={Select}
          name="fileType"
          label="Формат выгрузки"
          options={[
            { name: 'docx', value: ExtensionToMimeTypeEnum.docx },
            { name: 'pdf', value: ExtensionToMimeTypeEnum.pdf },
          ]}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFieldValue('fileType', e.target.value)}
          error={errors.fileType}
        />

        <Flex ml="auto" flexDirection="row" justifyContent="space-between" width="fit-content">
          <Tooltip backgroundColor="#9A9A9A" label="Отменить">
            <Button onClick={handleReset} variant="outline" color="PrimaryButton.500" padding={2}>
              Отменить
            </Button>
          </Tooltip>

          <Tooltip backgroundColor="#9A9A9A" label="Сформировать отчет">
            <Button marginLeft={4} variant="solid" colorScheme="PrimaryButton" onClick={submitForm} padding={2}>
              Сформировать отчет
            </Button>
          </Tooltip>
        </Flex>
      </Grid>
    </>
  );
};
