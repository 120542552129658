import appLocale from 'constants/appLocale';
import { subsystemCodesMap } from 'constants/subsystemIdsMap';
import { ReportSmall } from '../icons';

export const dataEntryLogMenu = {
  id: '/entryLogData',
  name: appLocale.admin.dataEntryLog,
  url: '/entryLogData',
  otherCode: subsystemCodesMap.dataEntryLog,
  icon: <ReportSmall />, // TODO: change
};
