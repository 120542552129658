import { Dispatch, ReactNode, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import TableSelectedFilter from 'components/table/filters/TableSelectedFilter';
import { TFiltersArray } from 'models';
import { useNotifications } from 'hooks/useNotifications';
import { cloneDeep } from 'lodash';

interface Props<K, T> {
  filters: TFiltersArray<K>;
  setFilters: (filters: TFiltersArray<K>, resetPagination?: boolean) => void;
  filterKey?: Extract<K, keyof T>;
  dataIds?: string[];
}

export interface IUseTableSelection<K, T> {
  selectedIds: string[] | undefined;
  setSelectedIds: Dispatch<SetStateAction<string[] | undefined>>;
  renderOnlySelectedCheckbox: ReactNode;
  resetSelection: (filters?: TFiltersArray<K>, resetSelectedIds?: boolean) => TFiltersArray<K>;
  onSelectionChange: (checkedRows: T[]) => void;
  selectedIndexes: number[];
}

function useTableSelection<K, T extends Partial<Record<Extract<K, keyof T>, any>>>({
  filters,
  setFilters,
  filterKey,
  dataIds,
}: Props<K, T>): IUseTableSelection<K, T> {
  const { errorNotify } = useNotifications();
  const initialSelectedIds = useMemo<string[] | undefined>(() => {
    const filterValue = filters?.find((f) => f.key === filterKey)?.value;
    if (filterValue && Array.isArray(filterValue)) {
      return filterValue as string[];
    }
    return undefined;
  }, [filterKey, filters]);
  const [selectedIds, setSelectedIds] = useState<string[] | undefined>(initialSelectedIds);
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if (!filters.find((item) => item.key === filterKey)) {
      setChecked(false);
    }
  }, [filters, filterKey]);

  const _onChange = useCallback(
    (checked: boolean) => {
      if (!filterKey) return;

      if (checked && (!selectedIds || selectedIds?.length === 0)) {
        errorNotify({
          key: 'no-selected-data',
          message: 'Отсутствуют выделенные строки'
        });
        return;
      }
      setChecked(checked);
      const newFilter = filters.filter((filter) => filter.key !== filterKey);

      newFilter.push({
        key: filterKey,
        operation: 'IN',
        value: checked ? selectedIds || [] : null,
      });
      setFilters(newFilter, true);
    },
    [errorNotify, filterKey, filters, selectedIds, setFilters]
  );

  const renderOnlySelectedCheckbox = useMemo<ReactNode>(
    () => <TableSelectedFilter onChange={_onChange} checked={checked || !!initialSelectedIds} />,
    [_onChange, checked, initialSelectedIds]
  );

  const resetSelection = useCallback<IUseTableSelection<K, T>['resetSelection']>(
    (resetToFilters, resetSelectedIds) => {
      const filter = cloneDeep(resetToFilters || filters);
      let newFilter = filter;

      if (resetSelectedIds !== false) {
        setChecked(false);
        setSelectedIds(undefined);
        newFilter = filter.filter((filter) => filter.key !== filterKey);
      }

      setFilters(newFilter);
      return newFilter;
    },
    [filterKey, filters, setFilters]
  );

  const onSelectionChange = useCallback<(data: T[]) => void>(
    (data) => {
      if (!filterKey) return;

      const checkedRowsIds = data && data.map((row) => row[filterKey]);
      const _selectedIds = filterSelectedRows(checkedRowsIds, dataIds, selectedIds);
      setSelectedIds(_selectedIds);
    },
  [dataIds, filterKey, selectedIds]);

  const selectedIndexes = useMemo(
    () => dataIds?.map((id, index) => (selectedIds?.includes(id) ? index : -1)) || [],
    [dataIds, selectedIds]
  );

  return {
    selectedIndexes,
    selectedIds,
    setSelectedIds,
    renderOnlySelectedCheckbox,
    resetSelection,
    onSelectionChange
  };
}

export default useTableSelection;

function filterSelectedRows(checkedRowsIds: string[], dataIds: string[] = [], prevState?: string[]): string[] {
  let unCheckedIds: string[];
  if (checkedRowsIds.length === 0) {
    unCheckedIds = dataIds || [];
  } else {
    const checkedIds = checkedRowsIds;
    unCheckedIds = dataIds.filter((id) => !checkedIds.includes(id));
  }

  let state = [...(prevState || [])];
  // removing unchecked
  state = state.filter((id) => !unCheckedIds.includes(id));
  // add newly checked rows
  state.push(...checkedRowsIds);

  return Array.from(new Set(state));
}
