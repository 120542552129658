import moment from 'moment';
import { Flex } from '@chakra-ui/react';

interface IProps {
  date: string | null;
}

export const DateCell = ({ date }: IProps) => {
  const processedDate = moment(date);
  return (
    <>
      {date ? (
        <Flex flexDirection="column">
          <span>{processedDate.format('DD.MM.YYYY')}</span>
          <span>{processedDate.format('HH:mm:ss')}</span>
        </Flex>
      ) : null}
    </>
  );
};
