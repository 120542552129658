import React from 'react';
import { useClearUploadFile } from 'features/UploadedFileProvider';
import FileItemView from 'features/UploadFileDragAndDrop/DragAndDrop/FileItem/FileItemView';

export type FileItemProps = {
  name?: string;
  percentage?: number;
};

const _FileItem: React.FC<FileItemProps> = ({ name = '', percentage = 0 }) => {
  const clear = useClearUploadFile();
  return (
    <FileItemView name={name} percentage={percentage} onDelete={() => clear('preview', 'inputFileMetadata')} />
  );
};

export const FileItem = React.memo(_FileItem);
