import * as React from 'react';
import { Button as ChakraButton, ButtonProps as ChakraButtonProps, Flex, Spinner } from '@chakra-ui/react';
import arrowIcon from 'components/icons/traingle-arrow.svg';
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';
import { useBreadcrumbs } from 'features/breadcrumbs-provider';

export const BreadcrumbButton: React.FC<ChakraButtonProps & Omit<RouterLinkProps, 'to'>> = (props) => (
  <ChakraButton
    variant="link"
    p={0}
    width="fit-content"
    display="flex"
    alignItems="center"
    fontSize="16px"
    fontWeight="normal"
    position="relative"
    paddingLeft="10px"
    marginRight="5px"
    textDecoration="none"
    cursor="pointer"
    _before={{
      content: "''",
      position: 'absolute',
      left: '0',
      top: '50%',
      transform: 'translateY(-50%) rotate(180deg)',
      width: '10px',
      height: '10px',
      background: `url(${arrowIcon})`,
    }}
    spinner={<Spinner size="sm" color="spinner.primary" />}
    {...props}
  />
);

export const Breadcrumb: React.FC<ChakraButtonProps & RouterLinkProps> = (props) => (
  <BreadcrumbButton
    as={RouterLink}
    {...props}
  />
);

export const Breadcrumbs: React.FC = () => {
  const { breadcrumbs } = useBreadcrumbs();

  return (
    <Flex alignItems="center">
      {breadcrumbs?.map(({ breadcrumb, isLoading, path }, i) => (
        <Breadcrumb key={i} to={path} isLoading={isLoading}>
          {breadcrumb}
        </Breadcrumb>
      ))}
    </Flex>
  );
};
