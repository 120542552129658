import { Box, Flex, Grid, Stack } from '@chakra-ui/react';
import { Button, DateInput, FormHeader, FormWrapper, Input, Select } from 'components/common';
import { useUploadFileLoadTypes } from 'features/UploadedFileProvider/useUploadFileLoadTypes';
import { Field, FieldArray, FormikProps, FormikProvider, useFormik } from 'formik';
import { createDateToRequestWithTimezone } from 'lib/create-date';
import { map, sortBy } from 'lodash';
import React, { useCallback, useMemo, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { MeteoDataAddItemRequest, MeteoDataMeasurement, useMeteoDataAddItem } from '../useMeteoDataAddItem';
import { useMeteoDictionaries } from '../useMeteoDictionaries';
import { MeteoDataApiRoot, MeteoDataMonitoringDataPath } from 'pages/data/Meteo/menu';
import { meteoDataSchema } from 'models/schemas/meteo/meteoData/meteoData';
import { createTouchedErrors } from 'features/create-touched-errors';
import { useEnhancedNotification } from 'hooks';
import { ERROR_MESSAGES } from 'constants/messages';
import { useGoBackWithFallback } from 'lib/utils/goBackWithFallback';
import { getFilteredOptionsByPrivileges } from 'features/getFilteredOptionsByPrivileges';
import { TDictionaryItemDictionary } from 'models';

interface MeteoDataAddItemForm extends MeteoDataAddItemRequest {
  measurements: (MeteoDataMeasurement & { name: number; parent: string })[];
}

const initialValues: MeteoDataAddItemForm & { extraMeasurement: number | string } = {
  dateTime: createDateToRequestWithTimezone(new Date(Date.now())),
  measurements: [],
  stationId: '',
  extraMeasurement: '',
  sourceTypeId: '6',
};

const MeteoDataAddItem: React.FC = () => {
  const { back } = useHistory();
  const { backWithFallback } = useGoBackWithFallback();
  const { onErrorCreate } = useEnhancedNotification();
  const { meteoDataAddItem, isLoading } = useMeteoDataAddItem();

  const innerRef = useRef<FormikProps<MeteoDataAddItemForm & { extraMeasurement: number | string }>>(null);

  const { values, handleChange, submitForm, errors, touched, setFieldTouched, setFieldValue, ...form } = useFormik<
    MeteoDataAddItemForm & { extraMeasurement: number | string }
  >({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    initialValues,
    validationSchema: meteoDataSchema,
    onSubmit: (values) => {
      submit(values);
    },
    innerRef,
  });

  const { result: sourceTypes, isLoading: isUploadLoading } = useUploadFileLoadTypes(`${MeteoDataApiRoot}/load/types`);

  const {
    stations,
    getItemsFromDictionaryByParent,
    getItemFromDictionaryByFieldValue,
    isLoading: isMeteoDictsLoading,
  } = useMeteoDictionaries('2');

  const filteredStations = useMemo(
    () => getFilteredOptionsByPrivileges(stations as TDictionaryItemDictionary[], 'isEditable'),
    [stations],
  );

  const setInitialMeasurements = useCallback(
    (stationId: string) => {
      setFieldValue(
        'measurements',
        map(
          // @ts-ignore
          sortBy(getItemsFromDictionaryByParent('heights', stationId), 'name'),
          (height) => ({
            ...height,
            value: null,
            sourceId: String(height.value),
            id: null,
          }),
        ),
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setFieldValue, getItemsFromDictionaryByParent],
  );

  const submit: (values: MeteoDataAddItemForm & { extraMeasurement: number | string }) => void = useCallback(
    (values) => {
      const station: any = getItemFromDictionaryByFieldValue(
        'stations',
        'value',
        // @ts-ignore
        values.stationId,
      );

      if (station && station.parent) {
        const { stationId, dateTime, measurements, extraMeasurement } = values;
        meteoDataAddItem({
          stationId,
          dateTime: createDateToRequestWithTimezone(dateTime),
          measurements: [
            ...map(measurements, ({ name: _name, parent: _parent, ...measurement }) => ({
              ...measurement,
            })),
            {
              id: null,
              value: extraMeasurement,
              sourceId: station.parent,
            },
          ],
        });
      } else {
        onErrorCreate(ERROR_MESSAGES.DICTIONARY_ERROR);
      }
    },
    [onErrorCreate, getItemFromDictionaryByFieldValue, meteoDataAddItem],
  );

  return (
    <Flex flexDirection="column">
      <FormHeader onBackClick={back} header="Добавление результата метеоизмерения" />

      <Box flexDirection="column">
        <FormikProvider
          value={{
            values,
            handleChange,
            submitForm,
            touched,
            setFieldTouched,
            setFieldValue,
            ...form,
            errors: createTouchedErrors(errors, touched),
          }}
        >
          <FormWrapper>
            <Grid marginTop="30px" gap="15px">
              <Field
                as={DateInput}
                name="dateTime"
                label="Дата и время"
                showTimeInput
                isDisabled={isLoading}
                error={errors?.dateTime}
              />

              <Field
                as={Select}
                name="sourceTypeId"
                label="Тип источника"
                options={sourceTypes?.types}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                  setFieldValue('sourceTypeId', e.target.value);
                }}
                // isDisabled={isLoading}
                // TODO: remove after realese 22.09
                isDisabled
                error={errors?.sourceTypeId}
              />

              <Field
                as={Select}
                name="stationId"
                label="Источник"
                options={filteredStations}
                isDisabled={!values.sourceTypeId || isLoading}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                  setInitialMeasurements(e.target.value);
                  setFieldValue('stationId', e.target.value);
                }}
                error={touched?.stationId && errors?.stationId}
              />
              {Boolean(values.stationId) && (
                <FieldArray
                  name="measurements"
                  render={(props) => (
                    <Stack spacing="20px">
                      {map(values.measurements, (height, index) => (
                        <Field
                          label={`Высота ${height.name}`}
                          as={Input}
                          key={index}
                          name={`measurements[${index}].value`}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            props.form.setFieldValue(`measurements[${index}].value`, e.target.value);
                          }}
                          isDisabled={isLoading}
                          // @ts-ignore
                          error={touched?.measurements?.[index]?.value && errors.measurements?.[index]?.value}
                        />
                      ))}
                      <Field
                        as={Input}
                        label="Внешняя температура"
                        name="extraMeasurement"
                        error={touched.extraMeasurement && errors?.extraMeasurement}
                      />
                    </Stack>
                  )}
                />
              )}
              <Flex justifyContent="space-between">
                <Button
                  variant="outline"
                  color="PrimaryButton.500"
                  onClick={() => backWithFallback(MeteoDataMonitoringDataPath)}
                  isLoading={isLoading}
                  isDisabled={isLoading}
                >
                  Отмена
                </Button>
                <Button
                  onClick={() => submitForm()}
                  variant="solid"
                  isLoading={isLoading || isUploadLoading || isMeteoDictsLoading}
                  isDisabled={isLoading}
                >
                  Сохранить
                </Button>
              </Flex>
            </Grid>
          </FormWrapper>
        </FormikProvider>
      </Box>
    </Flex>
  );
};

export { MeteoDataAddItem };
