import { SubsystemHeader } from 'components/common';
import appLocale from 'constants/appLocale';
import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import { PasswordRequestList } from './PasswordRequestList';
import React from 'react';
import { FullPageWrapper } from '../../../components/table/FullPageWrapper';

export const PasswordRequests: React.FC = () => {
  useUpdateBreadcrumbs([
    { breadcrumb: appLocale.admin.main, path: '/admin' },
    {
      breadcrumb: appLocale.admin.passwordRequests,
      path: '/admin/passwordRequest',
    },
  ]);

  return (
    <FullPageWrapper>
      <SubsystemHeader
        title="Управление запросами на смену пароля"
      />

      <PasswordRequestList />
    </FullPageWrapper>
  );
};
