import { ChartData } from 'chart.js';
import { IParam, IPointTypes, IServerData, ISourceData, PointTypes } from '../../types';
import { ApiError } from 'models';
import { ERROR_MESSAGES } from 'constants/messages';

// Предполагается, что у нас только 4 типа - мин, макс и мин или же абсолют
enum Widths {
  MIN = 1,
  AVG,
  ABS,
  MAX,
}
// Предполагается, что у нас будет 5 параметров максимум
const borderDashes = [[], [5, 5], [5, 5], [10, 10], [15, 15], [20, 20]];
// Предполагается, что у нас будет 5 источников максимум.
const borderColors = [
  'rgb(150, 10, 10)',
  'rgb(10, 150, 10)',
  'rgb(10, 10, 150)',
  'rgb(150, 150, 10)',
  'rgb(10, 200, 150)',
  'rgb(100, 100, 100)',
];
const backgroundColors = [
  'rgba(150, 10, 10, 0.3)',
  'rgba(10, 150, 10, 0.3)',
  'rgba(10, 10, 150, 0.3)',
  'rgba(150, 150, 10, 0.3)',
  'rgba(10, 200, 150, 0.3)',
  'rgba(100, 100, 100, 0.3)',
];

const createLabelForDataset = (source: string, parameter: string) => `${source} | ${parameter}`;
const getViewParams = (sourceIdx = 0, paramIdx = 0, type: IPointTypes) => ({
  borderColor: borderColors[paramIdx],
  backgroundColor: backgroundColors[paramIdx],
  borderDash: borderDashes[sourceIdx],
  borderWidth: Widths[type],
});

export const createDatasetObject = (source: ISourceData, param: IParam, sourceIdx = 0, parameters: string[]) => {
  const index = parameters?.findIndex?.((item) => item === param.id);
  const idxView = index === -1 ? 0 : index;

  const yAxisID = index === -1 || !index ? 'y' : `y${index}`;
  const commonLabel = createLabelForDataset(source.sourceName, param.name);

  const results = [];
  if (param.maxValues?.length) {
    const viewOptions = getViewParams(sourceIdx, idxView, PointTypes.MAX);
    results.push({
      label: `${commonLabel} | ${PointTypes.MAX}`,
      data: param.maxValues,
      hidden: false,
      typeId: PointTypes.MAX,
      paramId: param.id,
      paramName: param.name,
      paramUnit: param.unit,
      sourceId: source.sourceId,
      datasetId: `${source.sourceId}_${param.id}_${PointTypes.MAX}`,
      yAxisID,
      ...viewOptions,
    });
  }
  if (param.minValues?.length) {
    const viewOptions = getViewParams(sourceIdx, idxView, PointTypes.MIN);
    results.push({
      label: `${commonLabel} | ${PointTypes.MIN}`,
      data: param.minValues,
      hidden: false,
      typeId: PointTypes.MIN,
      paramId: param.id,
      paramName: param.name,
      paramUnit: param.unit,
      sourceId: source.sourceId,
      datasetId: `${source.sourceId}_${param.id}_${PointTypes.MIN}`,
      param,
      yAxisID,
      ...viewOptions,
    });
  }
  if (param.avgValues?.length) {
    const viewOptions = getViewParams(sourceIdx, idxView, PointTypes.AVG);
    results.push({
      label: `${commonLabel} | ${PointTypes.AVG}`,
      data: param.avgValues,
      paramId: param.id,
      paramName: param.name,
      paramUnit: param.unit,
      sourceId: source.sourceId,
      datasetId: `${source.sourceId}_${param.id}_${PointTypes.AVG}`,
      hidden: false,
      typeId: PointTypes.AVG,
      param,
      yAxisID,
      ...viewOptions,
    });
  }
  if (param.absValues?.length) {
    const viewOptions = getViewParams(sourceIdx, idxView, PointTypes.ABS);
    results.push({
      label: `${commonLabel} | ${PointTypes.ABS}`,
      data: param.absValues,
      hidden: false,
      typeId: PointTypes.ABS,
      paramId: param.id,
      paramName: param.name,
      paramUnit: param.unit,
      sourceId: source.sourceId,
      datasetId: `${source.sourceId}_${param.id}_${PointTypes.ABS}`,
      param,
      yAxisID,
      ...viewOptions,
    });
  }
  return results;
};

export const getDatasetsFromServerData = (
  data: IServerData | null | ApiError,
  parameters: string[]
): ChartData['datasets'] => {
  if (Array.isArray(data)) {
    if (data.length) {
      const resultArr: ChartData<'line'>['datasets'] = [];
      data.forEach((source, sourceIdx) => {
        source.parameters.forEach((param) => {
          const results = createDatasetObject(source, param, sourceIdx, parameters);
          // @ts-ignore
          resultArr.push(...results);
        });
      });
      return resultArr;
    }
    throw new Error(ERROR_MESSAGES.NO_DATA);
  }

  throw new Error(ERROR_MESSAGES.GET_DATA);
};
