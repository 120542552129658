import React, { useContext, useMemo } from 'react';
import { LoaderItem, PageHeader, RoutesList } from 'components/common';
import { Flex, Heading } from '@chakra-ui/react';
import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import appLocale from 'constants/appLocale';
import { combinedChatsMenu } from 'pages/analyticsReports/reportsAnalyticsMenu';
import { useRoutesList } from 'hooks/useRoutesList';
import { UserInfoContext } from 'features/AuthProvider';

const CombinedChartsList: React.FC = () => {
  useUpdateBreadcrumbs([
    { breadcrumb: appLocale.subsystems.analyticsReports, path: '/reports-analytics' },
    { breadcrumb: appLocale.analytics.combinedCharts.title, path: '/reports-analytics/chart-combination' },
  ]);

  const routes = useRoutesList(combinedChatsMenu);

  const { chartCombinationReports, isChartCombinationReportsLoading } = useContext(UserInfoContext);

  const sortedRoutes = useMemo(
    () =>
      [
        ...routes,
        ...(chartCombinationReports?.map((report) => ({
          path: report.link,
          isExternal: true,
          breadcrumb: report.name.trim(),
        })) || []),
      ].sort((a, b) => {
        const nameA = a.breadcrumb.toLowerCase();
        const nameB = b.breadcrumb.toLowerCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      }),
    [chartCombinationReports, routes]
  );

  if (isChartCombinationReportsLoading) {
    return <LoaderItem />;
  }

  return (
    <Flex flexDirection="column">
      <PageHeader>
        <Heading>{appLocale.analytics.combinedCharts.title}</Heading>
      </PageHeader>
      <RoutesList routes={sortedRoutes} />
      {/* {subsystemReportsSorted?.map((report) => (
        <Link
          href={report.link}
          isExternal
          color="#208CFF"
          marginLeft="80px"
          marginBottom="20px"
        >
          {report.name}
        </Link>
      ))} */}
    </Flex>
  );
};

export default CombinedChartsList;
