import { reduce } from 'lodash';
import { IFilterItem } from 'models/filter';

interface Filters {
  [key: string]: Partial<IFilterItem>;
}

const createInitialValuesToFormik = <F extends Filters>(_initialValues: F) =>
  reduce(
    Object.entries(_initialValues),
    (acc, current) => {
      const [key, obj] = current;
      return { ...acc, [key]: obj?.value ?? null };
    },
    {} as Record<keyof F, F[keyof F]['value']>,
  );

const createValuesToRequest = <F extends Filters, T extends ReturnType<typeof createInitialValuesToFormik>>(
  _initialValues: F,
  values: T,
) =>
    reduce(
      Object.entries(values),
      (acc, current) => {
        const [key, value] = current;

        return {
          ...acc,
          [key]: { ..._initialValues[key], value },
        };
      },
      {} as F,
    );

export { createInitialValuesToFormik, createValuesToRequest };
