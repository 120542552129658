import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { Button, Input, LoaderItem } from 'components/common';
import {
  Alert,
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  FormControl,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';
import { useRequest } from 'hooks';
import { baseAdminUserChangePasswordUrl } from '../consts';
import { DisplayUserItem } from 'pages/admin/models/UserItem';
import { ApiError } from 'models';
import { formatErrorMessage } from 'lib/utils/formatErrorMessage';
import { LightModeWrapper } from '../../../../components/common/LightModeWrapper/LightModeWrapper';

const passwordsNotMatchMessage = 'Пароли не совпадают!';

export const ChangeUserPasswordDialog = forwardRef((props, ref) => {
  const cancelRef = useRef<any>();
  const [changePasswordTitle, setChangePasswordTitle] = useState('');
  const [currentUser, setCurrentUser] = useState('');
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [repeatPasswordError, setRepeatPasswordError] = useState('');
  const [serverError, setServerError] = useState(null);

  const { post: changeUserPassword, isLoading } = useRequest(baseAdminUserChangePasswordUrl);
  const {
    isOpen: isOpenChangePassword,
    onOpen: onOpenPasswordChange,
    onClose: onClosePasswordChange
  } = useDisclosure();
  const {
    isOpen: isOpenAlert,
    onOpen: onOpenAlert,
    onClose: onCloseAlert
  } = useDisclosure();

  useImperativeHandle(ref, () => ({
    open(user: DisplayUserItem) {
      setChangePasswordTitle(`Изменение пароля для пользователя: ${user.code}`);
      setCurrentUser(user.id || '');
      setServerError(null);
      onOpenPasswordChange();
    }
  }));

  const changePassword = async () => {
    const result: any | ApiError | null = await changeUserPassword({ password }, {
      url: `${baseAdminUserChangePasswordUrl}/${currentUser}`
    });
    const error = formatErrorMessage(result) || undefined;
    if (error == null) {
      onClosePasswordChange();
      onOpenAlert();
    }
    setServerError(null);
  };

  const validatePassword = () => {
    if (password !== repeatPassword) {
      setPasswordError(passwordsNotMatchMessage);
      setRepeatPasswordError(passwordsNotMatchMessage);
      return;
    }
    setRepeatPasswordError('');
    setPasswordError('');
  };

  if (isLoading) {
    return (
      <LoaderItem />
    );
  }
  return (
    <div>
      <LightModeWrapper>
        <AlertDialog
          motionPreset="slideInBottom"
          onClose={onCloseAlert}
          isOpen={isOpenAlert}
          leastDestructiveRef={cancelRef}
          isCentered
        >

          <AlertDialogContent>
            <AlertDialogHeader>Пароль изменен</AlertDialogHeader>
            <AlertDialogBody>
              Пароль был успешно изменен
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onCloseAlert}>ОК</Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
        <Modal isOpen={isOpenChangePassword} onClose={onClosePasswordChange}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{changePasswordTitle}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <FormControl m={2}>
                <Input
                  name="pwd"
                  label="Пароль"
                  type="password"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
                  onBlur={validatePassword}
                  error={passwordError}
                />
              </FormControl>
              <FormControl m={2}>
                <Input
                  name="confpwd"
                  label="Подтверждение пароля"
                  type="password"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setRepeatPassword(e.target.value)}
                  onBlur={validatePassword}
                  error={repeatPasswordError}
                />
              </FormControl>
              {serverError != null && <Alert status="error" mt={2}>{serverError}</Alert>}
            </ModalBody>
            <ModalFooter>
              <Button mr={2} onClick={changePassword}>Изменить пароль</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </LightModeWrapper>
    </div>
  );
});
