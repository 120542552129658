import { SubsystemHeader } from 'components/common';
import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import { transformFiltersArray } from 'features/Filter/transformFiltersArray';

import { MaterialTable } from 'features/material-table/MaterialTableMini';
import { createDateToRequestWithTimezone, createLocalDateDDMMYYYYWithTime } from 'lib/create-date';
import React, { memo, useMemo } from 'react';

import { useList } from 'hooks/useList';
import { addFiltersToColumns, ColumnWithFilter } from 'lib/utils/tableFilter';
import { meteoLocales } from 'pages/data/Meteo/MeteoData/constants';
import { meteoProtocolsBreadcrumbs } from 'pages/data/Meteo/Protocol/const';
import useProtocolModal from 'hooks/useProtocolModal';
import VersionCell from 'components/table/VersionCell';
import { ProtocolActinometryFilter } from 'pages/data/Meteo/Protocol/ProtocolActinometry/ProtocolActinometryFilter';
import {
  IProtocolActinometryItem,
  TProtocolActinometryFilterDTO,
  TProtocolActinometryFilterTypes,
} from 'pages/data/Meteo/Protocol/ProtocolActinometry/types';
import { getSelectOptionsFromDictionaryByName } from 'features/get-select-options-from-dictionary';
import { useAskzaDictionary } from 'hooks/askza/useAskzaDictionary';
import { Column } from '@material-table/core';
import { TableFilterTypeEnum } from 'components/table/filters/TableFilter';
import subsystemIdsMap from 'constants/subsystemIdsMap';
import { useAvailability } from 'hooks';
import { usePeriodsDict } from 'features/PeriodsButtons/usePeriodsDict';
import { DEFAULT_PAGE_SIZE_NEW_TABLE } from '../../../../../constants/tables';
import { FullPageWrapper } from '../../../../../components/table/FullPageWrapper';

const ProtocolActinometry: React.FC = memo(() => {
  const { isExecute, isNoRights, isAvailable } = useAvailability(subsystemIdsMap.meteoActinometry);

  useUpdateBreadcrumbs([
    ...meteoProtocolsBreadcrumbs,
    {
      breadcrumb: 'Актинометрия',
      path: '/data/meteo/protocol/actinometry',
    },
  ]);

  const { result: dictionaries } = useAskzaDictionary('9');
  const stationsSelect = useMemo(
    () => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'stations'),
    [dictionaries]
  );
  const parametersSelect = useMemo(
    () => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'parameters'),
    [dictionaries]
  );

  const {
    refetch,
    isLoading,
    response,
    toggleOrder,
    setFilters,
    onPageChange,
    filterDTO,
    downloadAsFile,
    onSearchText,
    saveFilters,
    errorText,
    materialTableProps,
  } = useList<IProtocolActinometryItem, TProtocolActinometryFilterTypes>('/meteo/v10/meteo/actinometers/list/values', {
    initialOrderFieldKey: '-valueDate',
    preventFirstFetch: true,
    defaultPageSize: DEFAULT_PAGE_SIZE_NEW_TABLE,
    resetDataOnNextRequest: true,
  });

  const { openModal, protocolModal } = useProtocolModal<IProtocolActinometryItem>({
    onClose: refetch,
    dateKey: 'valueDate',
    secondBaseUrl: '/meteo/v10/actinometers'
  });

  const columns = useMemo<Array<ColumnWithFilter<IProtocolActinometryItem>>>(
    () => [
      {
        title: 'Версия',
        field: 'valueVersion',
        render: (row) => <>{isAvailable ? <VersionCell row={row} onClick={openModal} /> : row.valueVersion}</>,
        width: 50,
        filterType: TableFilterTypeEnum.number,
      },
      {
        title: meteoLocales.measuringDate,
        field: 'valueDate',
        render: (row) => createLocalDateDDMMYYYYWithTime(row.valueDate),
      },
      {
        title: meteoLocales.stationId,
        field: 'sourceName',
        filterType: TableFilterTypeEnum.string,
      },
      {
        title: 'Параметр',
        field: 'parameterName',
        filterType: TableFilterTypeEnum.string,
      },
      {
        title: 'Значение',
        field: 'value',
        filterType: TableFilterTypeEnum.number,
      },
    ],
    [openModal, isAvailable]
  );

  const columnsWithFilters = useMemo<Array<Column<IProtocolActinometryItem>>>(
    () => addFiltersToColumns({ filters: filterDTO.filters, setFilters, columns }),
    [columns, filterDTO.filters, setFilters]
  );

  const { result: periods } = usePeriodsDict(`auth/v10/user/period/${subsystemIdsMap.meteoActinometry}`);

  return (
    <FullPageWrapper>
      <SubsystemHeader
        title="Актинометрия"
        filtersComponent={
          <ProtocolActinometryFilter
            filters={filterDTO?.filters || []}
            periods={periods}
            stations={stationsSelect}
            parameters={parametersSelect}
            isLoading={isLoading}
            onSubmit={({ dateFrom, dateTo, ...values }) => {
              setFilters([
                ...transformFiltersArray<TProtocolActinometryFilterDTO['filters']>(
                  Object.values({
                    ...values,
                    dateFrom: {
                      ...dateFrom,
                      value: createDateToRequestWithTimezone(String(dateFrom.value)),
                    },
                    dateTo: {
                      ...dateTo,
                      value: createDateToRequestWithTimezone(String(dateTo.value)),
                    },
                  })
                ),
              ]);
            }}
          />
        }
      />

      <MaterialTable
        saveFilters={saveFilters}
        isNotExecute={isExecute || isNoRights}
        data={response?.data}
        meta={response?.meta}
        toggleOrder={toggleOrder}
        onPageChange={onPageChange}
        filterDTO={filterDTO}
        isLoading={isLoading}
        columns={columnsWithFilters}
        downloadAsFile={downloadAsFile}
        setFilters={setFilters}
        options={{
          search: true,
        }}
        onSearch={onSearchText}
        errorText={errorText}
        {...materialTableProps}
        showTotalCount
      />

      {protocolModal}
    </FullPageWrapper>
  );
});

export { ProtocolActinometry };
