import { IFilterValues } from 'components/common';
import { Modal } from 'components/common/Modals';
import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import { transformFiltersArray } from 'features/Filter/transformFiltersArray';
import { MaterialTable } from 'features/material-table/MaterialTableMini';

import { useAvailability, useDisclosure, useHandleDeleteData } from 'hooks';
import { createDateToRequestForFilter } from 'lib/create-date';

import React, { memo, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import {
  createGreenPlantsAddUrl,
  createGreenPlantsEditUrl,
  GreenPlantsApiRootPath,
  GreenPlantsFloraUrl,
  GreenPlantsRootPath, GreenPlantsStatementsUrl,
} from 'pages/routes/GreenPlants';

import { UploadFileModalContent } from 'features/UploadFileDragAndDrop/UploadFileModalContent';
import { useList } from 'hooks/useList';
import appLocale from 'constants/appLocale';
import { GreenPlantsDataHeader } from '../components';
import { useFloraListColumns } from './useFloraListColumns';
import { IFloraItemDto, TFloraFilterDTO, TFloraFilterType } from './useFloraList';
import { useFloraDictionaries } from './useFloraDictionaries';
import { FloraFilter } from './FloraFilter';
import { DeleteIcon, EditIcon, ImageIcon } from 'components/icons';
import useFilePreviewModalWrapperData from 'components/common/FilePreviewModal/hooks/useFilePreviewModalWrapperData';
import FilePreviewModalWrapper from 'components/common/FilePreviewModal/FilePreviewModalWrapper';
import { Column } from '@material-table/core';
import { addFiltersToColumns } from 'lib/utils/tableFilter';
import subsystemIdsMap from 'constants/subsystemIdsMap';
import { PropActions } from 'features/material-table/components/Actions';
import { usePeriodsDict } from 'features/PeriodsButtons/usePeriodsDict';
import { stringArrayToOptions } from 'features/stringArrayToOptions';
import { dictParametersToMap, parameterToParameterIdMap } from 'features/parametersMap';
import { getKeyByValue } from 'lib/utils/findKeyByValue';
import { DEFAULT_PAGE_SIZE_NEW_TABLE } from '../../../../constants/tables';
import { FullPageWrapper } from '../../../../components/table/FullPageWrapper';

const Flora: React.FC = memo(() => {
  const { isWrite, isReadOnly, isExecute, isNoRights, allAvailable } = useAvailability(
    subsystemIdsMap.greenplantsFlora,
  );
  const { push } = useHistory();
  const { open, ...filePreviewData } = useFilePreviewModalWrapperData();

  useUpdateBreadcrumbs([
    { breadcrumb: appLocale.common.data, path: '/data' },
    {
      breadcrumb: appLocale.subsystems.greenplants,
      path: GreenPlantsRootPath,
    },
    {
      breadcrumb: appLocale.subsystems.statements,
      path: GreenPlantsStatementsUrl,
    },
    {
      breadcrumb: 'Деревья и кустарники',
      path: GreenPlantsFloraUrl,
    },
  ]);

  const { dictionaries } = useFloraDictionaries();

  const {
    refetch,
    response,
    isLoading: isListLoading,
    filterDTO,
    toggleOrder,
    setFilters,
    onPageChange,
    downloadAsFile,
    saveFilters,
    onSearchText,
    errorText,
    selectedIndexes,
    renderOnlySelectedCheckbox,
    onSubmitFilters,
    onSelectionChange,
    materialTableProps,
  } = useList<IFloraItemDto, TFloraFilterType>(`${GreenPlantsApiRootPath}/flora/list`, {
    initialOrderFieldKey: '-date',
    preventFirstFetch: true,
    exportSubsystemName: 'zn_flora',
    selectionKey: 'registryId',
    saveFiltersState: true,
    defaultPageSize: DEFAULT_PAGE_SIZE_NEW_TABLE,
    resetDataOnNextRequest: true,
  });

  const { ModalComponent: ConfirmDeleteModal, handleOpenModal } = useHandleDeleteData({ refetch });

  const { isOpen: isOpenLoad, onClose: onCloseLoad, onOpen: onOpenLoad } = useDisclosure();

  const selectOptions = useMemo(() => {
    const parameters = dictParametersToMap(dictionaries.plantParameters);
    const map = new Map();
    map.set(getKeyByValue(parameterToParameterIdMap, 'plantAge'), stringArrayToOptions(parameters.plantAge?.values));
    return map;
  }, [dictionaries.plantParameters]);

  const columns = useFloraListColumns(dictionaries.plantParameters);
  const columnsWithFilters = useMemo<Array<Column<IFloraItemDto>>>(
    () => addFiltersToColumns({ filters: filterDTO.filters, setFilters, columns, selectOptions }),
    [columns, filterDTO.filters, selectOptions, setFilters],
  );

  const [filtersFromGraphic, setFiltersFromGraphic] = useState<Partial<IFilterValues> | null>(null);

  const initialActions = useMemo<PropActions<IFloraItemDto[]>>(
    () => [
      {
        position: 'row',
        action: (row: any) => ({
          icon: () => <EditIcon />,
          onClick: () => push(createGreenPlantsEditUrl('flora', row.registryId)),
          tooltip: 'Редактировать',
          hidden: !row.isEditable,
        }),
      },
      {
        position: 'row',
        action: (row: any) => ({
          icon: () => <DeleteIcon />,
          onClick: () => handleOpenModal({ url: `${GreenPlantsApiRootPath}/flora/${row.registryId}` }),
          tooltip: 'Удалить',
          hidden: !row.isEditable,
        }),
      },
      {
        position: 'row',
        action: (row: any) => ({
          icon: () => <ImageIcon />,
          onClick: () => open(row.registryId, row.objectType),
          tooltip: 'Показать изображения',
          hidden: !row.objectType,
        }),
      },
    ],
    [push, handleOpenModal, open],
  );

  // @ts-ignore
  const actions = useMemo<PropActions<any[]>>(() => {
    if (allAvailable || isNoRights || isWrite) {
      return initialActions;
    }
    if (!(allAvailable || isNoRights || isWrite)) {
      return initialActions.filter((action: any) => action.type !== 'delete');
    }
  }, [isWrite, initialActions, isNoRights, allAvailable]);

  const { result: periods } = usePeriodsDict(`auth/v10/user/period/${subsystemIdsMap.greenplantsFlora}`);

  return (
    <FullPageWrapper>
      <GreenPlantsDataHeader
        allAvailable={allAvailable}
        isWrite={isWrite}
        isReadonly={isReadOnly}
        isExecute={isExecute}
        isNoRights={isNoRights}
        onOpenLoad={onOpenLoad}
        handleAdd={() => {
          push(createGreenPlantsAddUrl('flora'));
        }}
      >
        <FloraFilter
          periods={periods}
          filtersFromGraphic={filtersFromGraphic}
          setFiltersFromGraphic={setFiltersFromGraphic}
          dictionaries={dictionaries}
          isLoading={isListLoading}
          filters={filterDTO.filters}
          onSubmit={({ dateFrom, dateTo, ...values }) => {
            const filters = transformFiltersArray<TFloraFilterDTO['filters']>(
              Object.values({
                ...values,
                dateFrom: {
                  ...dateFrom,
                  value: createDateToRequestForFilter(String(dateFrom.value), 'start'),
                },
                dateTo: {
                  ...dateTo,
                  value: createDateToRequestForFilter(String(dateTo.value), 'end'),
                },
                disabled: {
                  key: 'deleted',
                  operation: 'EQ',
                  value: false,
                },
              }),
            );
            onSubmitFilters(filters);
          }}
        >
          {renderOnlySelectedCheckbox}
        </FloraFilter>
      </GreenPlantsDataHeader>

      <Modal isOpen={isOpenLoad} onClose={onCloseLoad} header="Загрузка данных из файла">
        <UploadFileModalContent
          createTypesPath={() => `${GreenPlantsApiRootPath}/flora/load/types`}
          createUploadPath={() => `${GreenPlantsApiRootPath}/flora/load`}
          types={[
            {
              name: 'Данные о состоянии деревьев и кустарников',
              value: 'flora',
            },
          ]}
          onClose={onCloseLoad}
          refetch={refetch}
        />
      </Modal>

      <MaterialTable
        isNotExecute={isExecute || isNoRights}
        data={response?.data}
        meta={response?.meta}
        filterDTO={filterDTO}
        isLoading={isListLoading}
        options={{
          search: true,
        }}
        onSearch={onSearchText}
        toggleOrder={toggleOrder}
        onPageChange={onPageChange}
        columns={columnsWithFilters}
        actions={actions}
        downloadAsFile={downloadAsFile}
        selectedIndexes={selectedIndexes}
        onSelectionChange={onSelectionChange}
        saveFilters={saveFilters}
        setFilters={setFilters}
        errorText={errorText}
        {...materialTableProps}
        showTotalCount
      />

      {ConfirmDeleteModal}

      <FilePreviewModalWrapper {...filePreviewData} />
    </FullPageWrapper>
  );
});

export { Flora };
