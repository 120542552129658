import { AnalyticsData } from 'pages/data/analytics-data';
import subsystemIdsMap from 'constants/subsystemIdsMap';

export const SnowAnalytics = () => (
  <AnalyticsData
    title="Мониторинг воздействия ПГР на снег"
    rootPath="/data/reagents/snow"
    subsystemId={subsystemIdsMap.pgrSnow}
    mainDataTitle="Свои протоколы"
    analyticsDataTitle="Протоколы аналитической лаборатории"
  />
);
