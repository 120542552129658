import { Field, useFormikContext } from 'formik';
import { IForecastAddItemEntity, IForecastMainForm } from 'pages/data/Meteo/Forecast/Data/consts/types';
import React, { useMemo } from 'react';
import { getSelectOptionsFromDictionaryByName } from 'features/get-select-options-from-dictionary';
import { Grid, Text } from '@chakra-ui/react';
import { Input, Select } from 'components/common';
import { fieldsLocale } from 'pages/data/Meteo/Forecast/Data/consts/locale';

const ForecastItemGeneralInfoForm = ({ entity, dictionary, editedObject }: IForecastAddItemEntity): JSX.Element => {
  const { values, errors, handleChange } = useFormikContext<IForecastMainForm>();
  const dictData = dictionary?.data;

  const activeWeatherIds = useMemo(
    () =>
      getSelectOptionsFromDictionaryByName(dictData, 'activeWeather', false, editedObject?.[entity]?.activeWeatherIds),
    [dictData, editedObject, entity],
  );

  const umcIdentificationId = useMemo(
    () =>
      getSelectOptionsFromDictionaryByName(
        dictData,
        'umcIdentification',
        false,
        editedObject?.[entity]?.umcIdentificationId,
      ),
    [dictData, editedObject, entity],
  );

  return (
    <>
      <Text fontSize="xl" mt="25">
        Общие сведения
      </Text>
      <Grid marginTop="15px" gap="15px">
        <Field
          as={Input}
          name={`${entity}.meteorologicalSituation`}
          label={fieldsLocale.meteorologicalSituation}
          value={values[entity]?.meteorologicalSituation}
          onChange={handleChange}
          error={errors[entity]?.meteorologicalSituation}
        />
        <Field
          as={Input}
          name={`${entity}.surfacePressure`}
          label={fieldsLocale.surfacePressure}
          value={values[entity]?.surfacePressure}
          onChange={handleChange}
          error={errors[entity]?.surfacePressure}
          type="number"
        />
        <Field
          as={Input}
          name={`${entity}.cloudCoverage`}
          multiple
          label={fieldsLocale.cloudCoverage}
          onChange={handleChange}
          error={errors[entity]?.cloudCoverage}
        />
        <Field
          as={Input}
          name={`${entity}.wetness`}
          label={fieldsLocale.wetness}
          value={values[entity]?.wetness}
          onChange={handleChange}
          error={errors[entity]?.wetness}
          type="number"
        />
        <Field
          as={Select}
          name={`${entity}.activeWeatherIds`}
          multiple
          label={fieldsLocale.activeWeatherIds}
          options={activeWeatherIds}
          value={values[entity]?.activeWeatherIds?.filter((val) => !!val)} // HACK: при создании записи с [] возвращается [null] уточняю у аналитика почему так
          onChange={handleChange}
          error={errors[entity]?.activeWeatherIds}
        />
        <Field
          as={Input}
          name={`${entity}.phenomena`}
          label={fieldsLocale.phenomena}
          onChange={handleChange}
          error={errors[entity]?.phenomena}
        />
        <Field
          as={Select}
          name={`${entity}.umcIdentificationId`}
          label={fieldsLocale.umcIdentificationId}
          options={umcIdentificationId}
          value={values[entity]?.umcIdentificationId}
          onChange={handleChange}
          error={errors[entity]?.umcIdentificationId}
        />
      </Grid>
    </>
  );
};

export { ForecastItemGeneralInfoForm };
