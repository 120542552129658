import { TablePagination } from '@material-ui/core';
import React, { memo, useCallback, useMemo, useState } from 'react';
import { TablePaginationProps } from '@material-ui/core/TablePagination/TablePagination';
import { Box, Button, HStack, Input } from '@chakra-ui/react';
import tableLocalization from 'features/material-table/components/const/tableLocalization';
import {
  TablePaginationActions,
} from 'pages/citizenAppeal/RaidJournal/RaidTable/tablePagination/TablePaginationActions';
import { DEFAULT_PAGE_OPTIONS_NEW_TABLE } from 'constants/tables';

type IProps = TablePaginationProps & {
  showTotalCount?: boolean;
};

const Pagination = ({ showTotalCount, ...props }: IProps) => {
  const [page, setPage] = useState<string>('');
  const isValidPage = useCallback<(page: string) => number | false>(
    (page) => {
      if (!page) return false;
      try {
        if (parseInt(page) > props.count) {
          setPage(String(props.count));
          page = String(props.count);
        }
        const numPage = Math.ceil(parseInt(page) / props.rowsPerPage) - 1;
        if (numPage < 0) {
          return false;
        }
        return numPage;
      } catch (e) {
        return false;
      }
    },
    [props]);

  const onGoToPage = useCallback(
    (e) => {
      const isValid = isValidPage(page);
      if (isValid !== false) {
        props.onPageChange(e, isValid);
      } else {
        setPage('');
      }
    },
    [isValidPage, page, props],
  );

  const defaultLocalization = useMemo(() => tableLocalization({ showTotalCount }), [showTotalCount]);

  const labelDisplayedRows = useCallback(({
    from,
    to,
    count,
  }) => <>{`${from}-${to}${showTotalCount ? ` из ${count}` : ''}`}</>, [showTotalCount]);

  const defaultRowsPerPageOptions = useMemo(() => DEFAULT_PAGE_OPTIONS_NEW_TABLE.map((n) => ({
    value: n,
    label: `${n} ${defaultLocalization.pagination?.labelRowsSelect}`,
  })), [defaultLocalization.pagination?.labelRowsSelect]);

  return (
    <td style={{ display: 'flex', alignItems: 'center' }}>
      <TablePagination
        component={Box}
        labelRowsPerPage=""
        labelDisplayedRows={labelDisplayedRows}
        rowsPerPageOptions={defaultRowsPerPageOptions}
        {...props}
        // TODO: remove later when pagination will work correctly
        ActionsComponent={() => <TablePaginationActions
          showTotalCount={showTotalCount}
          onPageChange={props.onPageChange}
          limit={props.rowsPerPage}
          page={props.page}
          count={props.count}
          rowsPerPage={props.rowsPerPage}
        />}
        style={{
          borderBottom: 'none',
          padding: '0',
          ...props.style,
        }}
        SelectProps={{
          ...props.SelectProps,
          MenuProps: {
            ...props.SelectProps?.MenuProps,
            style: { zIndex: 1600 }
          }
        }}
      />

      {showTotalCount && (
        <HStack maxW="320px">
          <Input
            type="number"
            width={100}
            size="sm"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPage(e.target.value)}
          />
          <Button disabled={isValidPage(page) === false} size="sm" onClick={onGoToPage}>
            Перейти
          </Button>
        </HStack>
      )}
    </td>
  );
};

export default memo(Pagination);
