import { Field, useFormikContext } from 'formik';
import { IReportProtocolForm } from 'pages/analytics/Raids/types';
import { Button, FormWrapper, Input } from 'components/common';
import React, { useCallback } from 'react';
import { Box, Flex, Grid, Heading } from '@chakra-ui/react';
import { AddIcon, DeleteIcon } from 'components/icons';
import { get } from 'lodash';

export const LinksTab = () => {
  const { errors, values, setFieldValue } = useFormikContext<IReportProtocolForm>();

  const addSignator = useCallback(() => {
    const newSigners = values.links?.links || [];
    newSigners?.push({
      position: '',
      content: '',
    });
    setFieldValue('links.links', newSigners);
  }, [setFieldValue, values.links]);

  const removeSignator = useCallback(
    (index: number) => {
      const newSigners = values.links?.links;
      newSigners?.splice(index, 1);
      setFieldValue('links.links', newSigners);
    },
    [setFieldValue, values.links]
  );

  return (
    <Box>
      <FormWrapper>
        <Grid marginTop={5} templateColumns="repeat(1, 1fr)">
          <Heading size="md" marginBottom={5}>
            Примечания
          </Heading>
          {values.links?.links?.map((_, index: number) => (
            <Box key={index} marginBottom={5}>
              <Grid gap={4}>
                <Flex alignItems="baseline">
                  <Heading size="md" marginBottom={2} marginTop={5}>
                    {`Примечание ${index + 1}`}
                  </Heading>
                  <Button
                    marginLeft="10px"
                    leftIcon={<DeleteIcon />}
                    variant="ghost"
                    color="PrimaryButton.500"
                    size="sm"
                    p={0}
                    onClick={() => removeSignator(index)}
                  />
                </Flex>

                <Field
                  as={Input}
                  error={get(errors, `links.links[${index}].position`)}
                  label="Номер примечания"
                  name={`links.links[${index}].position`}
                />

                <Field
                  as={Input}
                  error={get(errors, `links.links[${index}].content`)}
                  label="Текст примечания"
                  name={`links.links[${index}].content`}
                />
              </Grid>
            </Box>
          ))}

          <Button
            alignSelf="left"
            marginRight="auto"
            leftIcon={<AddIcon />}
            variant="ghost"
            color="PrimaryButton.500"
            size="sm"
            p={0}
            onClick={() => addSignator()}
          >
            Добавить примечание
          </Button>
        </Grid>
      </FormWrapper>
    </Box>
  );
};
