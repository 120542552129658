import { Box, Flex, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { Button, DefaultText, FormHeader, LoaderItem } from 'components/common';
import React, { useCallback, useMemo } from 'react';
import { GeneralInformationTab } from 'pages/dictionaries/NSIEmissions/NSIEmissionsSources/NSIEmissionsSourcesForms/GeneralInformationTab';
import { LocationTab } from 'pages/dictionaries/NSIEmissions/NSIEmissionsSources/NSIEmissionsSourcesForms/LocationTab';
import { useFormikContext } from 'formik';
import { QualityItem } from 'hooks/geo/Quality/useQualityList';
import appLocale from 'constants/appLocale';
import { FieldFormProps } from 'types/forms';
import { ChimneyTab } from './ChimneyTab';
import { useEnhancedNotification } from 'hooks';

const NSIEmissionsSourcesFormFields = ({ onCancel, dictionaries, isLoading, isEditMode }: FieldFormProps) => {
  const { handleSubmit, errors } = useFormikContext<QualityItem>();
  const { onValidationError } = useEnhancedNotification();

  const onSubmit = useCallback(() => {
    if (Object.keys(errors).length > 0) {
      onValidationError();
    }
    handleSubmit();
  }, [errors, handleSubmit, onValidationError]);

  const renderControls = useMemo(
    () => (
      <Flex>
        <Button onClick={onCancel} marginRight="auto">
          Отмена
        </Button>
        <Button onClick={onSubmit} variant="solid" isDisabled={isLoading}>
          Сохранить
        </Button>
      </Flex>
    ),
    [onCancel, onSubmit, isLoading],
  );

  const title = isEditMode ? 'Редактирование источника' : 'Добавление источника';

  if (isLoading) {
    return <LoaderItem />;
  }

  return (
    <Box flexDirection="column">
      <Tabs>
        <FormHeader onBackClick={onCancel} header={title}>
          <TabList>
            {[appLocale.common.generalInformation, appLocale.common.location, appLocale.common.chimney].map(
              (tab, i) => (
                <Tab key={i}>
                  <DefaultText cursor="pointer">{tab}</DefaultText>
                </Tab>
              ),
            )}
          </TabList>
        </FormHeader>
        <TabPanels>
          <TabPanel>
            <GeneralInformationTab dictionaries={dictionaries} isEditMode={isEditMode}>
              {renderControls}
            </GeneralInformationTab>
          </TabPanel>
          <TabPanel>
            <LocationTab>{renderControls}</LocationTab>
          </TabPanel>
          <TabPanel>
            <ChimneyTab>{renderControls}</ChimneyTab>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default React.memo(NSIEmissionsSourcesFormFields);
