import React, { useMemo } from 'react';
import { FormWrapper, Input, Select } from 'components/common';

import { getSelectOptionsFromDictionaryByName } from 'features/get-select-options-from-dictionary';
import { Field, useFormikContext } from 'formik';
import { TDictionariesArray } from 'models/dictionaries';
import { NSIEmissionsListItem } from 'pages/dictionaries/NSIEmissions/consts/types';
import { Box, Grid } from '@chakra-ui/react';

interface CommonTabProps {
  dictionaries: {
    data: TDictionariesArray;
  } | null;
  editedObject?: any;
  isEditMode?: boolean
}

export const GeneralInformationTab = ({ dictionaries, editedObject, isEditMode }: CommonTabProps) => {
  const { setFieldValue, values, errors } = useFormikContext<NSIEmissionsListItem>();
  const industryTypes = useMemo(
    () =>
      getSelectOptionsFromDictionaryByName(dictionaries?.data, 'industryTypes', false, editedObject?.industryTypeId),
    [dictionaries?.data, editedObject?.industryTypeId],
  );

  const statuses = useMemo(() => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'statuses'), [dictionaries]);

  return (
    <Box flexDirection="column">
      <FormWrapper>
        <Grid marginTop="30px" gap="15px">
          <Field as={Input} name="name" label="Наименование" error={errors.name} isRequired />

          <Field as={Input} name="shortName" label="Краткое наименование" error={errors.shortName} />

          <Field as={Input} name="number" label="Номер" error={errors.number} isRequired isDisabled={isEditMode} />

          <Field
            as={Select}
            name="industryTypeId"
            label="Тип предприятия"
            value={values.industryTypeId}
            options={industryTypes}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              setFieldValue('industryTypeId', e.target.value);
            }}
            error={errors.industryTypeId}
          />

          <Field
            as={Select}
            name="status"
            label="Статус"
            value={values.status}
            options={statuses}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              setFieldValue('status', e.target.value);
            }}
            error={errors.status}
            isRequired
          />

          <Field as={Input} name="description" label="Примечание" error={errors.description} />
        </Grid>
      </FormWrapper>
    </Box>
  );
};
