import { DateInput } from 'components/common';
import { Flex, Grid } from '@chakra-ui/react';
import { Field, useFormikContext } from 'formik';
import appLocale from 'constants/appLocale';
import React from 'react';
import { createLocalDateISO8601 } from 'lib/create-date';
import { IFilterItem } from 'models';
import FilterFormControls from 'components/form/FilterFormControls';

export const Filter = () => {
  const { submitForm, setFieldValue, handleReset, errors } = useFormikContext<{ [p: string]: IFilterItem }>();

  return (
    <Flex>
      <Grid width="100%" maxWidth="1200px" gap={5} templateColumns="repeat(2, 0.25fr)">
        <Field
          as={DateInput}
          name="minDate.value"
          label={appLocale.common.from}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setFieldValue('minDate.value', createLocalDateISO8601(e.target.value))
          }
          error={errors.minDate?.value}
        />

        <Field
          as={DateInput}
          name="maxDate.value"
          label={appLocale.common.to}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setFieldValue('maxDate.value', createLocalDateISO8601(e.target.value))
          }
          error={errors.maxDate?.value}
        />
      </Grid>

      <FilterFormControls
        onReset={(e) => {
          handleReset(e);
          setTimeout(submitForm, 0);
        }}
        onSubmit={submitForm}
      />
    </Flex>
  );
};
