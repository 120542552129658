import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { Box, Flex } from '@chakra-ui/react';
import moment from 'moment';
import 'chartjs-adapter-moment';
import 'moment/locale/ru';
import { useEnhancedNotification, useRequest } from 'hooks';
import { IFormattedGraphData, IGraphResponse, IGraphSettings } from 'pages/analyticsReports/CombinedCharts/types';
import {
  formatGraphDataForRender,
  makeGraphOptions,
  makeInitialGraphSettings,
} from 'pages/analyticsReports/CombinedCharts/graphUtils';
import { initialGraphOptions } from 'pages/analyticsReports/CombinedCharts/const';
import GraphFilter, {
  IGraphFilterForm,
} from 'pages/data/GeoecologicalProcesses/GeologicalProcessesGroundwater/Measuring/Graph/GraphFilter';
import { IPeriod } from 'features/PeriodsButtons/types';

moment.locale('ru');

interface IGraph {
  periods?: IPeriod[] | null;
  initialFilters?: any;
  selectedIds?: string[];
}

const Graph = ({ periods, initialFilters, selectedIds }: IGraph) => {
  const { onGetError } = useEnhancedNotification();
  const [data, setData] = useState<{ datasets: IFormattedGraphData[] } | null>(null);
  const [options, setOptions] = useState(initialGraphOptions);
  const [graphSettings, setGraphSettings] = useState<IGraphSettings | null>(null);

  const chartRef = useRef<any>();

  const onSuccess = useCallback((graphResponse: IGraphResponse | null) => {
    if (!graphResponse) return;
    // set default graph settings from response
    setGraphSettings(
      makeInitialGraphSettings({
        graphResponse,
      })
    );
  }, []);

  const {
    post,
    result,
    isLoading: isGraphLoading,
  } = useRequest<IGraphResponse>('/reporting/v10/graphs/groundwater', onSuccess, onGetError);

  useEffect(() => {
    if (result && graphSettings) {
      setData({
        datasets: formatGraphDataForRender({ graphResponse: result, graphSettings }),
      });
      // set graph options
      setOptions(makeGraphOptions({ graphResponse: result, graphSettings }));
    } else {
      setData(null);
      setOptions(initialGraphOptions);
    }
  }, [graphSettings, result]);

  const buildGraph = useCallback(
    (values: IGraphFilterForm) => {
      setGraphSettings(null);
      setData(null);
      void post(values);
    },
    [post]
  );

  const chart = useMemo(() => {
    if (!data) return null;

    return (
      <>
        <Line
          /* @ts-ignore */
          options={options}
          id="LineChart"
          data={data}
          ref={chartRef}
          height="200px"
          width="200px"
        />
        <div id="legend-container" />
      </>
    );
  }, [data, options]);

  return (
    <Flex px="16px" flexDirection="column" height="calc(90vh - 60px - 32px)" position="sticky" flex={1}>
      <GraphFilter
        initialFilters={initialFilters}
        selectedIds={selectedIds}
        periods={periods}
        onSubmit={buildGraph}
        isGraphLoading={isGraphLoading}
      />

      <Box flex={1} position="relative">
        {chart}
      </Box>
    </Flex>
  );
};

export default Graph;
