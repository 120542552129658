import { ChangeEvent, useState } from 'react';
import { IPeriodName } from '../types';

export const useSelectPeriod = () => {
  const [period, setPeriod] = useState<IPeriodName | null>(null);

  const handleChangePeriod = (period: ChangeEvent<HTMLSelectElement>) => {
    setPeriod(period?.target?.value as IPeriodName);
  };

  return {
    period,
    handleChangePeriod,
  };
};
