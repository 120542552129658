import moment from 'moment';

export const createDateToRequest = (inp?: moment.MomentInput) => {
  if (moment(inp).isValid()) {
    return moment(inp).format('YYYY-MM-DDTHH:mm:ssZZ');
  }

  return null;
};

export const createDateToRequestWithTimezone = (inp?: moment.MomentInput) => {
  if (moment(inp).isValid()) {
    return moment(inp).format();
  }

  return null;
};

export const createDateToRequestForFilter = (inp?: moment.MomentInput, type: 'start' | 'end' = 'start') => {
  if (moment(inp).isValid()) {
    if (type === 'start') {
      return moment(inp).startOf('day').format();
    }

    return moment(inp).endOf('day').format();
  }

  return null;
};

export const createDateFromResponse = (inp?: moment.MomentInput) => {
  if (moment(inp).isValid()) {
    return moment(inp).toDate();
  }
  return null;
};

export const createLocalDateDDMMYYYY = (datestring: string) => {
  if (moment(datestring).isValid()) {
    return moment(datestring).format('DD.MM.YYYY');
  }

  return null;
};

export const createDateDDMMYYYWithBlank = (datestring: string) => {
  if (moment(datestring).isValid()) {
    return moment(datestring).format('DD-MM-yyyy');
  }

  return null;
};

export const createLocalDateDDMMYYYYWithTime = (datestring: string | number | null) => {
  if (!datestring) return null;

  if (moment(datestring).isValid()) {
    return moment(datestring).format('DD.MM.YYYY HH:mm:ss');
  }
  return null;
};

export const createLocalTimeFromDate = (datestring: string) => {
  if (moment(datestring).isValid()) {
    return moment(datestring).format('HH:mm:ss');
  }
  return null;
};

export const createLocalDateISO8601 = (datestring: string) => {
  if (moment(datestring).isValid()) {
    return moment(datestring).format('YYYY-MM-DD');
  }
  return null;
};

export const createUTCTime = (datestring: string) => {
  if (moment(datestring).isValid()) {
    return moment.utc(datestring).format('YYYY-MM-DDTHH:mm:ss[Z]');
  }
  return null;
};

export const createLocalDateCustomFormat = (datestring: string | number | null, format: string) => {
  if (!datestring) return null;

  if (moment(datestring).isValid()) {
    return moment(datestring).format(format);
  }
  return null;
};

export const createLocalDateYYYY = (datestring: string) => {
  if (moment(datestring).isValid()) {
    return moment(datestring).format('YYYY');
  }

  return null;
};

export const createUTCTimestampMS = (datestring: string) => {
  if (moment(datestring).isValid()) {
    return moment(datestring).unix() * 1000;
  }

  return null;
};
