import { Button } from '@chakra-ui/react';
import { authAppPaths } from 'pages/auth/appPaths';
import { useHistory } from 'react-router';

export const ChangePasswordButton: React.FC = () => {
  const { push } = useHistory();
  const handleClick = () => {
    push(authAppPaths.changePassword);
  };

  return (
    <Button mr={4} variant="solid" size="xs" lineHeight={1} onClick={handleClick}>
      Изменить пароль
    </Button>
  );
};
