import { NSIRootPath } from 'pages/dictionaries/const/routes';

export const ParameterRootPath = `${NSIRootPath}/parameters/parameter`;
export const AddParamPath = `${ParameterRootPath}/add`;
export const EditParamPath = `${ParameterRootPath}/edit/:id`;

export const paramApiRootPath = '/nsi/v10/parameters';
export const paramListApiPath = `${paramApiRootPath}/list/parameters`;
export const createParamApiPath = `${paramApiRootPath}/create/parameter`;
export const updateParamApiPath = `${paramApiRootPath}/update/parameter`;
export const getParamApiPath = (id: string) => `${paramApiRootPath}/get/parameter/${id}`;
export const dictionariesApiPath = `${paramApiRootPath}/dictionaries`;
