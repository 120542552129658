import * as yup from 'yup';
import { numberError, requiredError } from '../../constans';

export const meteoDataSchema = yup.object().shape({
  dateTime: yup.string().nullable().required(requiredError),
  sourceTypeId: yup.string().required(requiredError),
  stationId: yup.string().typeError(requiredError).required(requiredError),
  extraMeasurement: yup.number().typeError(numberError).required(requiredError),
  measurements: yup.array().of(
    yup.object().shape({
      value: yup.string().required(requiredError).typeError(requiredError),
    })
  ),
});
