import React, { useEffect, useMemo, useState } from 'react';
import { Button, DefaultText, Input, Select } from 'components/common';
import { Flex, Grid, GridItem, Radio, RadioGroup } from '@chakra-ui/react';
import { Field, FieldArray, useFormikContext } from 'formik';
import { DataSourcesList } from 'pages/admin/FTP';
import { statuses } from '../../../consts';
import { AddIcon, DeleteIcon, EyeIcon } from 'components/icons';
import { useCatalogDictionary } from '../useCatalogDictionary';
import { get } from 'lodash';

interface Props {
  onCancel: () => void;
}

export const FieldsForm = ({ onCancel }: Props) => {
  const [show, setShow] = useState(false);

  const { handleSubmit, errors, setFieldValue, values } = useFormikContext<DataSourcesList>();
  const isEditMode = location.pathname.includes('/edit');

  const passwordType = useMemo(() => (show ? 'text' : 'password'), [show]);

  const { servers } = useCatalogDictionary();

  useEffect(() => {
    values.servers.map((item, index) => {
      setFieldValue(`servers.${index}.order`, index + 1);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setFieldValue, values.servers.length]);

  return (
    <Flex maxWidth="700px" padding="20px 130px" flexDirection="column">
      <Grid gap={4} templateColumns="repeat(1, 1fr)">
        <GridItem>
          <RadioGroup
            display="flex"
            alignItems="center"
            value={String(values.direction)}
            onChange={(value: string) => {
              setFieldValue('direction', value === 'true');
            }}
          >
            <Radio marginRight={10} value="false">
              Чтение
            </Radio>
            <Radio value="true">Запись</Radio>
          </RadioGroup>
        </GridItem>
        <GridItem>
          <Field as={Input} name="name" label="Наименование источника" disabled={isEditMode} error={errors?.name} />
        </GridItem>
        <GridItem>
          <Field
            as={Select}
            name="status"
            value={String(values.status)}
            options={statuses}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFieldValue('status', e.target.value === 'true');
            }}
            label="Статус"
            error={errors?.status}
          />
        </GridItem>
        {/* Пока скрыть - не сохраняется на бекенде */}
        {/* <GridItem> */}
        {/*  <Field */}
        {/*    as={Input} */}
        {/*    name="ftpServer" */}
        {/*    label="FTP-сервер" */}
        {/*    error={errors?.status} */}
        {/*  /> */}
        {/* </GridItem> */}
        <GridItem>
          <Field as={Input} name="ftpPath" label="Путь к каталогу" error={errors?.ftpPath} />
        </GridItem>

        {values.direction ? (
          <GridItem>
            <Field
              key="cron"
              as={Input}
              name="cron"
              label="Расписание запуска"
              error={errors?.cron}
              value={values.cron}
            />
          </GridItem>
        ) : (
          <GridItem>
            <Field
              key="ftpCheckDuring"
              as={Input}
              name="ftpCheckDuring"
              label="Периодичность запуска, сек"
              error={errors?.ftpCheckDuring}
              value={values.ftpCheckDuring}
            />
          </GridItem>
        )}
      </Grid>

      <DefaultText fontWeight="bold" fontSize="20px">
        Авторизация
      </DefaultText>

      <Grid gap={4} templateColumns="repeat(1, 1fr)">
        <GridItem>
          <Field as={Input} name="authLogin" label="Логин" error={errors?.authLogin} />
        </GridItem>
        <GridItem>
          <Field
            as={Input}
            name="authPass"
            type={passwordType}
            label="Пароль"
            error={errors?.authPass}
            InputRightElement={
              <Button
                leftIcon={<EyeIcon />}
                variant="ghost"
                onClick={() => {
                  setShow(!show);
                }}
              />
            }
          />
        </GridItem>
      </Grid>

      <DefaultText fontWeight="bold" fontSize="20px">
        Серверы
      </DefaultText>

      <FieldArray name="servers">
        {({ remove, push }) => (
          <>
            {values.servers.length > 0 &&
              values.servers.map((item, index) => (
                <Flex mt={2} mb={5} key={index} justifyContent="space-between">
                  <Grid gap={4} templateColumns="repeat(1, 1fr)" flexGrow={1}>
                    <GridItem>
                      <Field
                        as={Select}
                        name={`servers.${index}.serverId`}
                        value={values.servers[index].serverId}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          setFieldValue(`servers.${index}.serverId`, e.target.value);
                        }}
                        options={servers}
                        label="Сервер"
                        // @ts-ignore
                        error={get(errors?.servers?.[index], 'serverId')}
                      />
                    </GridItem>
                    <GridItem>
                      <Field
                        as={Input}
                        name={`servers.${index}.order`}
                        value={values.servers[index].order}
                        disabled
                        label="Приоритет сервера"
                      />
                    </GridItem>
                  </Grid>
                  {values.servers.length > 1 && (
                    <Button leftIcon={<DeleteIcon />} onClick={() => remove(index)} variant="ghost" size="md" />
                  )}
                </Flex>
              ))}
            <Button
              leftIcon={<AddIcon />}
              marginRight="auto"
              onClick={() =>
                push({
                  serverId: null,
                  order: values.servers.length + 1,
                })
              }
              variant="ghost"
              size="sm"
            >
              Добавить сервер
            </Button>
          </>
        )}
      </FieldArray>

      <Flex marginTop={4}>
        <Button marginRight={3} onClick={onCancel}>
          Отмена
        </Button>
        <Button onClick={() => handleSubmit()}>Сохранить</Button>
      </Flex>
    </Flex>
  );
};
