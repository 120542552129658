import { ERROR_MESSAGES, SUCCESS_MESSAGES } from 'constants/messages';
import { createTouchedErrors } from 'features/create-touched-errors';
import { FormikProvider, useFormik } from 'formik';
import { useNotifications } from 'hooks/useNotifications';
import { useRequest } from 'hooks/useRequest';
import { UnitsSchema } from 'models';
import { createUnitApiPath, IMeasureUnitItem, UnitRootPath } from '../consts';
import { UnitFields } from './UnitFields';
import { useGoBackWithFallback } from 'lib/utils/goBackWithFallback';

const initialValues = {
  id: '',
  name: '',
  shortName: '',
  code: '',
  localName: '',
  localCode: '',
  intCode: '',
  intName: '',
  errors: [],
};

export const AddUnit = () => {
  const { backWithFallback } = useGoBackWithFallback();

  const { successNotify, errorNotify } = useNotifications();
  const onSuccess = () => {
    successNotify({
      key: 'file/add/success',
      message: SUCCESS_MESSAGES.CREATE,
    });
    backWithFallback(UnitRootPath);
  };

  const onError = () => {
    errorNotify({
      key: 'file/add/error',
      message: ERROR_MESSAGES.CREATE,
    });
  };
  const { post } = useRequest<IMeasureUnitItem, any>(createUnitApiPath, onSuccess, onError);

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    validationSchema: UnitsSchema,
    initialValues,
    onSubmit: (values) => {
      post(values);
    },
  });

  return (
    <FormikProvider
      value={{
        ...formik,
        errors: createTouchedErrors(formik.errors, formik.touched),
      }}
    >
      <UnitFields />
    </FormikProvider>
  );
};
