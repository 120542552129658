import { RoutesList, SubsystemHeader } from 'components/common';
import { WaterWPZListRoot } from '../routes';
import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import appLocale from 'constants/appLocale';
import { Flex } from '@chakra-ui/react';
import React from 'react';
import { useRoutesList } from 'hooks/useRoutesList';
import { WPZ_Menu } from '../routesConfig';

export const WPZRoot = () => {
  useUpdateBreadcrumbs([
    { breadcrumb: 'Данные', path: '/data' },
    {
      breadcrumb: 'Водные объекты',
      path: '/data/water',
    },
    {
      breadcrumb: appLocale.subsystems.wpz,
      path: WaterWPZListRoot,
    },
  ]);
  const routes = useRoutesList(WPZ_Menu);
  return (
    <Flex flexDirection="column">
      <SubsystemHeader title={appLocale.subsystems.wpz} />
      <RoutesList routes={routes} />
    </Flex>
  );
};
