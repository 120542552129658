export const appealApiRootPath = 'appeal/v10';
export const taskFormerApiPath = `${appealApiRootPath}/tasks`;

export const CitizensAppealsPath = '/citizens-appeals';
export const PlanningPath = `${CitizensAppealsPath}/add/planning`;
export const Appeals = `${CitizensAppealsPath}/appeals`;
export const AddAppealPath = `${Appeals}/add`;
export const RaidJournalPath = `${CitizensAppealsPath}/raid-journal`;
export const RaidJournalPathNoise = `${RaidJournalPath}/noise`;
export const RaidJournalPathAtmo = `${RaidJournalPath}/air`;
export const TaskFormerPath = `${Appeals}/task-preparing`;
export const PELRaidsRoutesPath = `${Appeals}/PEL-routes`;
export const VideoCamerasPath = `${CitizensAppealsPath}/video-cameras`;
export const RaidJournalAtmoAdd = `${RaidJournalPath}/air/add`;
export const RaidJournalNoiseAdd = `${RaidJournalPath}/noise/add`;
export const CitizenAppealsTableViewPath = `${CitizensAppealsPath}/citizen-appeals-table-view`;
export const CitizenAppealsTableViewEditPath = `${CitizenAppealsTableViewPath}/task-preparing/view`;

export const ADD_APPEAL_SUCCESS = 'Обращение успешно сохранено';
export const ADD_APPEAL_ERROR = 'Ошибка при сохранении обращения';
export const EDIT_APPEAL_ERROR = 'Ошибка при редактировании обращения';

export const ADD_INCIDENT_SUCCESS = 'Происшествие успешно сохранено';
export const ADD_INCIDENT_ERROR = 'Ошибка при сохранении происшествия';
export const EDIT_INCIDENT_ERROR = 'Ошибка при редактировании происшествия';

export const ADD_RESPONSE_SUCCESS = 'Ответ успешно сохранен';
export const ADD_RESPONSE_ERROR = 'Ошибка при сохранении ответа';
export const EDIT_RESPONSE_ERROR = 'Ошибка при редактировании происшествия';
export const ADD_MEDIA_SUCCESS = 'Документы успешно загружены';
export const ADD_MEDIA_ERROR = 'Ошибка при загрузке документов';
export const NOISE_FILTER_BUTTON = 'Учет шумовой обстановки';
export const ATMO_FILTER_BUTTON = 'чет загрязнения атмосферы';

export const RAID_JOURNAL = 'Журнал рейдов';
export const TABLE_VIEW = 'Табличное отображение данных рейдов ПЭЛ';
export const APPEALS_TABLE_VIEW = 'Табличное отображение данных учета обращений граждан';

export const taskFormAtmoBreadcrumb = [
  {
    breadcrumb: 'Учет обращений граждан',
    path: CitizensAppealsPath,
  },
  {
    breadcrumb: 'Журнал рейдов',
    path: RaidJournalPathAtmo,
  },
  {
    breadcrumb: 'Подготовка заданий',
    path: RaidJournalAtmoAdd,
  },
];

export const taskFormNoiseBreadcrumb = [
  {
    breadcrumb: 'Учет обращений граждан',
    path: CitizensAppealsPath,
  },
  {
    breadcrumb: 'Журнал рейдов',
    path: RaidJournalPathNoise,
  },
  {
    breadcrumb: 'Подготовка заданий',
    path: RaidJournalNoiseAdd,
  },
];

export const editJournalAtmoBreadCrumbs = [
  {
    breadcrumb: 'Учет обращений граждан',
    path: CitizensAppealsPath,
  },
  {
    breadcrumb: 'Журнал рейдов',
    path: RaidJournalPathAtmo,
  },
];
export const editJournalNoiseBreadCrumbs = [
  {
    breadcrumb: 'Учет обращений граждан',
    path: CitizensAppealsPath,
  },
  {
    breadcrumb: 'Журнал рейдов',
    path: RaidJournalPathNoise,
  },
];
export const raidSuccess = 'DONE';
export const raisNotSuccess = '4489eb3f-ad49-4eb1-acc4-0947a5e3a8c7';
export const draftStatus = 'DRAFT';
export const inProgressStatus = 'IN_WORK';
export const raidPlanned = 'PLANNED';
export const researchTypeNoise = 'c94886b9-7c1e-4b46-b290-51fe0e94060f';
export const researchTypeAir = 'cb495098-c36d-4374-8fd0-a3579df288c9';
export const researchPoint = '0da24406-cd09-4391-bbfa-c7648a53ec7f';
export const garbageBurningPoint = '22061dc1-4152-4c8c-8f55-d2b8b71a73dd';
export const observationPlace = 'c1555e6e-3397-46da-8d3c-9ab2cf0d2217';
export const noiseSourceType = 'c94886b9-7c1e-4b46-b290-51fe0e94060f';
