import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import useAutocomplete from '@material-ui/lab/useAutocomplete';

import { debounce, find, get, map } from 'lodash';

import {
  FormControl,
  FormControlProps,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  List,
  ListIcon,
  ListItem,
  Spinner,
  Stack,
  Text,
  usePopper,
} from '@chakra-ui/react';

import { SelectedCheckboxIcon, UnselectedCheckboxIcon } from 'components/icons';
import { SelectClearIcon, SelectExpandIcon } from 'components/common/Select/components';
import { FormikFieldProps } from 'components/common/types';
import { AsyncSelectProps, SelectOption, SelectProps } from 'components/common/Select/types';
import { useRequest } from 'hooks';
import arrUniqueByKey from 'lib/utils/arrUniqueByKey';

interface AddressOption {
  id: string;
  value: string;
  extend: {
    admAreaId: string;
    districtId: string;
    admAreaText: string;
    admAreaEhdId: string;
    districtText: string;
    districtEhdId: string;
  };
}

export interface IAsyncAddressSelectOnChange extends React.ChangeEvent<HTMLSelectElement> {
  target: React.ChangeEvent<HTMLSelectElement>['target'] & {
    extend?: AddressOption['extend'];
  };
}

type TAsyncAddressSelectOption = SelectOption & Partial<AddressOption>;
export const AsyncAddressSelect: React.FC<AsyncSelectProps & Partial<FormikFieldProps<AsyncSelectProps>> & { isRequired?: FormControlProps['isRequired'] }> = memo(
  ({
    label,
    name,
    error,
    isDisabled = false,
    defaultValue,
    value,
    onChange,
    isCleared = true,
    arrowColor = 'select.arrow.default',
    clearColor = 'select.clear.default',
    iconWrapperProps = {},
    isRequired,
  }) => {
    const [touched, setTouched] = useState(false);
    const [selectedOption, setSelectedOption] = useState<TAsyncAddressSelectOption | null>(null);
    const [loadedOptions, setLoadedOptions] = useState<TAsyncAddressSelectOption[]>([]);
    const popper = usePopper({
      gutter: 1,
    });

    const options = useMemo(() => {
      const options = [...loadedOptions];
      if (selectedOption) {
        options.push(selectedOption);
      }
      return arrUniqueByKey(options, 'name');
    }, [selectedOption, loadedOptions]);

    const { get: loadItems, isLoading } = useRequest<AddressOption[]>(
      '/ehd/v10/dictionary/addresses',
    );

    const formatOption = useCallback<(item: AddressOption) => TAsyncAddressSelectOption>((item) => ({
      ...item,
      value: item.value,
      name: item.value,
    }), []);

    const loadValue = useCallback(async (value: string) => {
      const result = await loadItems({ params: { value, limit: 1 } });
      if (Array.isArray(result)) {
        if (result[0] && result[0].value === value) {
          setSelectedOption(formatOption(result[0]));
          if (onChange) {
            onChange({ target: { ...formatOption(result[0]), name } });
          }
          return;
        }
        // если запись о адресе не найдена в гис ехд - оставить старое значение
        setSelectedOption({ value, name: value });
      }
    }, [loadItems, formatOption, onChange, name]);

    useEffect(() => {
      const option = options.find((option) => option.name === value);
      if (value && !option && !isLoading) {
        void loadValue(value);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [options, loadValue, value]);

    const getOptionLabel = useCallback(
      (option: string | null): string => {
        const opt = find(options, (opt) => opt.value.toString() === option);

        return opt ? String(opt.name) : '';
      },
      [options],
    );

    const handleChange = useCallback(
      (e: React.ChangeEvent<Record<string, unknown>>, v: string | null) => {
        if (onChange) {
          const option = options.find((option) => option.name === v);
          if (option) {
            setSelectedOption(option);
          }
          return onChange({
            ...e,
            target: { ...e.target, id: name, value: v, name, extend: option?.extend },
          });
        }
      },
      [name, onChange, options],
    );

    const _value = useMemo(() => {
      if (options && !options.length) {
        return null;
      }

      if (typeof value === 'string' && value !== '') {
        return value;
      }

      return null;
    }, [options, value]);

    const {
      getRootProps,
      getInputLabelProps,
      getInputProps,
      getOptionProps,
      groupedOptions,
      setAnchorEl,
      getClearProps,
    } = useAutocomplete<string | null>({
      defaultValue,
      value: _value,
      options: map(options, (opt) => String(opt.value)),
      id: name,
      getOptionLabel,
      onChange: handleChange,
      openOnFocus: true,
    });

    const isValidExistValue = useCallback((val: SelectProps['value']) => {
      if (typeof val === 'string' && val !== '') {
        return true;
      }

      return false;
    }, []);

    const inputRightElementRef = useRef<HTMLDivElement | null>(null);

    const inputRigthElementOffsetWidh = inputRightElementRef.current?.offsetWidth;

    const search = useMemo(() => debounce(async (event: React.ChangeEvent<HTMLInputElement>) => {
      const data = await loadItems({ params: { value: event.target.value, limit: 50 } });
      setTouched(true);
      if (Array.isArray(data)) {
        let opts: TAsyncAddressSelectOption[] = [];
        if (data) {
          opts = data.map(formatOption);
        }
        setLoadedOptions(opts);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, 500, { trailing: true, leading: false }), []);

    const errorText = error || ((options.length === 0 && !isLoading && touched) ? 'Адрес не найден' : null);

    return (
      <FormControl
        {...getRootProps()}
        width="100%"
        height="max-content"
        _focusWithin={{ label: { transform: 'translate(0px, 0px)' } }}
        isInvalid={Boolean(errorText)}
        ref={popper.referenceRef}
        isRequired={isRequired}
      >
        <FormLabel
          {...getInputLabelProps()}
          position="absolute"
          margin={0}
          pointerEvents="none"
          zIndex={10}
          left={4}
          top={1}
          color="select.label.default"
          fontSize="13px"
          transform={`translate(0px, ${isValidExistValue(value) ? '0px' : '11px'})`}
        >
          {label}
        </FormLabel>
        <InputGroup ref={setAnchorEl}>
          <Input
            {...getInputProps()}
            isDisabled={isDisabled}
            size="md"
            height="45px"
            backgroundColor="select.background.default"
            borderRadius="2px"
            textOverflow="ellipsis"
            overflow="hidden"
            focusBorderColor="select.border.primary"
            errorBorderColor="select.border.error"
            paddingEnd={`${inputRigthElementOffsetWidh}px`}
            paddingInlineEnd={`${inputRigthElementOffsetWidh}px`}
            fontSize="16px"
            lineHeight="20px"
            paddingTop="18px"
            onChange={(event) => {
              // @ts-ignore
              getInputProps().onChange(event);
              search(event);
            }}
          />
          <InputRightElement ref={inputRightElementRef} width="fit-content" height="100%">
            <Stack direction="row" spacing={2} pl={2} pr={4} alignItems="center" {...iconWrapperProps}>
              {isLoading ? <Spinner size="sm" color="spinner.primary" /> : (
                <>
                  {!isDisabled && isCleared && value && (
                    <SelectClearIcon mr={2} clearColor={clearColor} {...getClearProps()} />
                  )}

                  <SelectExpandIcon cursor="pointer" arrowColor={arrowColor} />
                </>
              )}
            </Stack>
          </InputRightElement>
        </InputGroup>

        {(groupedOptions.length > 0) && (
          <List
            width="100%"
            position="absolute"
            backgroundColor="#fff"
            overflow="auto"
            maxHeight="250px"
            borderRadius="4px"
            boxShadow="0 2px 8px rgba(0, 0, 0, 0.15)"
            zIndex={11}
            ref={popper.popperRef}
          >
            {map(groupedOptions, (option, index) => {
              const optionsProps = getOptionProps({ option, index });
              return (
                <ListItem
                  {...optionsProps}
                  py={2}
                  px={4}
                  minHeight="40px"
                  cursor="pointer"
                  display="flex"
                  alignItems="center"
                  _hover={{ background: 'select.list.hover' }}
                  _selected={{ background: 'select.list.selected' }}
                >
                  <ListIcon>
                    {get(optionsProps, 'aria-selected') ? <SelectedCheckboxIcon /> : <UnselectedCheckboxIcon />}
                  </ListIcon>
                  <Text
                    style={{
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      lineHeight: '1rem',
                    }}
                    _hover={{
                      height: 'auto',
                      whiteSpace: 'normal !important',
                    }}
                  >
                    {getOptionLabel(option)}
                  </Text>
                </ListItem>
              );
            })}
          </List>
        )}

        <FormErrorMessage>{errorText}</FormErrorMessage>
      </FormControl>
    );
  },
);
