import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { Loader } from 'components/common/Loader';
import React, { useCallback, useEffect, useState } from 'react';
import { Box } from '@chakra-ui/react';
import { VariableSizeList as List } from 'react-window';

interface Props {
  file: File;
  open: boolean;
}

const PDF_VIEW_WIDTH = 580;
const PDF_VIEW_PADDING_BOTTOM = 16;

const FilePreviewPDFSlide = ({ file, open }:Props) => {
  const [pageNumber, setPageNumber] = useState(0);
  const [pdfObject, setPdfObject] = useState<any>();
  const [pageHeights, setPageHeights] = useState<number[]>();

  const processPages = useCallback(async () => {
    setPageNumber(pdfObject.numPages);
    const arr = Array.from(Array(pdfObject.numPages).keys());
    const pages = await Promise.all(arr.map((index) => pdfObject.getPage(index + 1)));

    setPageHeights(pages.map((page) => {
      const { view: [,, pdfPageWidth, pdfPageHeight] } = page._pageInfo;
      return Math.ceil((PDF_VIEW_WIDTH / pdfPageWidth) * pdfPageHeight) + PDF_VIEW_PADDING_BOTTOM;
    }));
  }, [pdfObject]);

  useEffect(() => {
    if (pdfObject) {
      void processPages();
    }
  }, [pdfObject, processPages]);

  return (
    <Box
      height="600px"
      overflowY="auto"
      display="flex"
      justifyContent="center"
    >
      {open && (
        <Document
          file={file}
          onLoadSuccess={setPdfObject}
          loading={<Loader containerProps={{ position: 'absolute' }} />}
        >
          {pageHeights && (
            <List
              width={600}
              height={600}
              itemSize={((index) => pageHeights[index])}
              itemCount={pageNumber}
            >
              {({ style, index }) => (
                <div key={index} style={style}>
                  <Page
                    pageNumber={index + 1}
                    width={PDF_VIEW_WIDTH}
                    renderAnnotationLayer={false}
                    loading=""
                  />
                </div>
              )}
            </List>
          )}
        </Document>
      )}

    </Box>
  );
};

export default FilePreviewPDFSlide;
