import React from 'react';
import { PageHeader, RoutesList } from 'components/common';
import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import appLocale from 'constants/appLocale';
import { GreenPlantsAreasFlora, GreenPlantsRootPath, GreenPlantsStatementsUrl } from 'pages/routes/GreenPlants';
import { Flex, Heading } from '@chakra-ui/react';

export const GreenPlantsList: React.FC = () => {
  useUpdateBreadcrumbs([
    { breadcrumb: appLocale.common.data, path: '/data' },
    {
      breadcrumb: appLocale.subsystems.greenplants,
      path: GreenPlantsRootPath,
    },
  ]);

  return (
    <Flex flexDirection="column">
      <PageHeader>
        <Heading>{appLocale.subsystems.greenplants}</Heading>
      </PageHeader>
      <RoutesList
        routes={[
          {
            path: GreenPlantsStatementsUrl,
            breadcrumb: 'Ведомости',
          },
          {
            path: GreenPlantsAreasFlora,
            breadcrumb: 'Площадки',
          },
        ]}
      />
    </Flex>
  );
};
