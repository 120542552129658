import { Flex, Grid } from '@chakra-ui/react';
import { Field, useFormikContext } from 'formik';
import { DateInput, Select } from 'components/common';
import appLocale from 'constants/appLocale';
import React from 'react';
import { createUTCTime } from 'lib/create-date';
import FilterFormControls from 'components/form/FilterFormControls';
import { IFilterItem, TDictionariesArray } from 'models';
import { getSelectOptionsFromDictionaryByName } from 'features/get-select-options-from-dictionary';

interface IProps {
  dictionaries: { data: TDictionariesArray<string> } | null;
}

export const NotificationFormFields = ({ dictionaries }: IProps) => {
  const { submitForm, setFieldValue, handleReset, values, errors } = useFormikContext<{ [p: string]: IFilterItem }>();
  const subsystems = getSelectOptionsFromDictionaryByName(dictionaries?.data, 'subsystems');
  const users = getSelectOptionsFromDictionaryByName(dictionaries?.data, 'users');
  const types = getSelectOptionsFromDictionaryByName(dictionaries?.data, 'types');
  const channels = getSelectOptionsFromDictionaryByName(dictionaries?.data, 'channels');
  return (
    <>
      <Flex justifyContent="space-between">
        <Flex direction="column" flexGrow={1}>
          <Grid gap="12px" rowGap="8px" templateColumns="repeat(4, 0.25fr)">
            <Field
              as={DateInput}
              name="startDate.value"
              label={appLocale.common.from}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setFieldValue('startDate.value', createUTCTime(e.target.value))
              }
              showTimeInput
              error={errors.startDate?.value}
            />

            <Field
              as={DateInput}
              name="endDate.value"
              label={appLocale.common.to}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setFieldValue('endDate.value', createUTCTime(e.target.value))
              }
              showTimeInput
              error={errors.endDate?.value}
            />
            <Field
              as={Select}
              name="user.value"
              label="Пользователь"
              options={users}
              value={values.user.value}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFieldValue('user.value', e.target.value)}
            />

            <Field
              as={Select}
              name="subsystem.value"
              label="Подсистема"
              options={subsystems}
              value={values.subsystem.value}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFieldValue('subsystem.value', e.target.value)}
            />
            <Field
              as={Select}
              name="type.value"
              label="Тип уведомления"
              options={types}
              value={values.type.value}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFieldValue('type.value', e.target.value)}
            />

            <Field
              as={Select}
              name="method.value"
              label="Способ уведомления"
              options={channels}
              value={values.method.value}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFieldValue('method.value', e.target.value)}
              multiple
            />
          </Grid>
        </Flex>
        <FilterFormControls
          onReset={(e) => {
            handleReset(e);
            setTimeout(submitForm, 0);
          }}
          onSubmit={submitForm}
        />
      </Flex>
    </>
  );
};
