import { AnalyticsData } from 'pages/data/analytics-data';
import { ReagentsSoilRootPath } from 'pages/data/PGR/consts';
import subsystemIdsMap from 'constants/subsystemIdsMap';

export const PGRAnalytics = () => (
  <AnalyticsData
    title="Мониторинг воздействия ПГР на почву"
    rootPath={ReagentsSoilRootPath}
    subsystemId={subsystemIdsMap.pgrSoil}
    mainDataTitle="Данные"
    analyticsDataTitle="Протоколы аналитической лаборатории"
  />
);
