import { useDisclosure } from '@chakra-ui/hooks';
import { PageHeader } from 'components/common';
import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import { RightHeader } from './RightHeader';
import { RightList } from './RightList';
import appLocale from 'constants/appLocale';
import { Flex } from '@chakra-ui/react';

export const Rights: React.FC = () => {
  useUpdateBreadcrumbs([
    { breadcrumb: appLocale.admin.main, path: '/admin' },
    {
      breadcrumb: appLocale.admin.rights,
      path: '/admin/right',
    },
  ]);
  const { onOpen: onOpenLoad } = useDisclosure();

  return (
    <Flex flexDirection="column">
      <PageHeader>
        <RightHeader onOpenLoad={onOpenLoad} url="/add" />
      </PageHeader>
      <RightList />
    </Flex>
  );
};
