import { useHistory } from 'react-router-dom';
import React, { useCallback, useEffect, useState } from 'react';
import { IUploadMedia } from 'components/common/FileUploadDragAndDrop/types';
import { FormikProvider, useFormik } from 'formik';
import { createTouchedErrors } from 'features/create-touched-errors';
import { ApiError } from 'models';
import { useEnhancedNotification, useNotifications, useRequest } from 'hooks';
import { useObjectType } from 'hooks/useObjectType';
import { formatErrorMessage } from 'lib/utils/formatErrorMessage';
import { ERROR_MESSAGES } from 'constants/messages';
import { updateFiles } from 'api/services/media';
import { initialEmptyValues } from 'pages/analytics/Raids/initialEmptyValues';
import { LoaderItem } from 'components/common';
import { IReportProtocolForm, ReportProtocolGetDto } from 'pages/analytics/Raids/types';
import { formatFormForRequest } from 'pages/analytics/Raids/utils';
import {
  AnalyticsAiAtmosphereFormFields,
} from 'pages/analytics/Raids/Pel/PelAiAtmoshphere/AnalyticsAiAtmosphereFormFields';
import { ANALYTICS_LAB_PATHS } from 'pages/analytics/consts/AnalyticsTableRoutes';
import { AnalyticsPelAiAtmosphereSchema } from 'models/schemas/analytics/analyticsPelAiAtmosphereSchema';
import { generateUUID } from 'lib/utils/generateUUID';
import { useGoBackWithFallback } from 'lib/utils/goBackWithFallback';
import { useDictionary } from 'hooks/useDictionary';

const AnalyticsAiAtmosphereAdd = () => {
  const [browserId] = useState(generateUUID());
  const { location, replace } = useHistory<{ observationPointId: string; taskId: string }>();
  const { backWithFallback } = useGoBackWithFallback();
  const [media, setMedia] = useState<IUploadMedia[]>([]);

  const { isDictionariesLoading, objectType, dictionaries } = useDictionary({ url: '/appeal/v10/protocols/dictionaries' });
  const _objectType = useObjectType(objectType);

  const { onSuccessCreate } = useEnhancedNotification();
  const { errorNotify } = useNotifications();

  const onError = useCallback(
    (error: ApiError | string | null) => {
      const message = formatErrorMessage(error);
      errorNotify({
        key: message || 'file/add/error',
        message: message || ERROR_MESSAGES.CREATE,
      });
    },
    [errorNotify]
  );

  const onSuccess = (response: ReportProtocolGetDto | null) => {
    if (response?.id) {
      onSuccessCreate();
      updateFiles(response.id, _objectType, media)
        .then(() => {
          backWithFallback('/analytics');
        })
        .catch((error: Error) => {
          onError(error.message);
        });
    }
  };

  const { post } = useRequest('', onSuccess, onError);

  const formOnSubmit = useCallback(
    (values: IReportProtocolForm) => {
      void post(formatFormForRequest(values), { url: '/appeal/v10/protocols/ai/air' });
    },
    [post]
  );

  const formik = useFormik<IReportProtocolForm>({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    validationSchema: AnalyticsPelAiAtmosphereSchema,
    initialValues: {
      ai: true,
      ...initialEmptyValues,
      ...location.state,
    },
    onSubmit: formOnSubmit,
  });

  const { get, result: protocolForPoint } = useRequest<ReportProtocolGetDto>('');

  useEffect(() => {
    if (formik.values.observationPointId) {
      void get({ url: `/appeal/v10/points/${formik.values.observationPointId}/ai/protocols` });
    }
  }, [formik.values.observationPointId, get]);

  useEffect(() => {
    if (protocolForPoint) {
      const url = protocolForPoint.ai ? ANALYTICS_LAB_PATHS.raids.ai.edit : ANALYTICS_LAB_PATHS.raids.pel.edit;
      replace(`${url}/${protocolForPoint.id}`);
    }
  }, [protocolForPoint, replace]);

  if (isDictionariesLoading) {
    return <LoaderItem />;
  }

  return (
    <div>
      <FormikProvider
        value={{
          ...formik,
          errors: createTouchedErrors(formik.errors, formik.touched),
        }}
      >
        <AnalyticsAiAtmosphereFormFields
          dictionaries={dictionaries}
          media={media}
          onMediaChange={setMedia}
          objectType={_objectType}
          isLoading={isDictionariesLoading}
          browserId={browserId}
        />
      </FormikProvider>
    </div>
  );
};

export default AnalyticsAiAtmosphereAdd;
