import { SettingsSmall } from 'features/WithSideBar/icons';
import { RenderTree } from 'features/WithSideBar/types';
import appLocale from 'constants/appLocale';
import { ftpMenu } from './FTP/consts/consts';
import { subsystemCodesMap } from 'constants/subsystemIdsMap';
import { adminNODLogPath } from './NODUploadLog/consts';
import { Settings } from '../home/icons';

export const duplicateKeyError = 'duplicate key value';
export const invalidValue = 'invalid input syntax';
const baseUrl =
  // TODO: Commented because i use a production api also in dev mode
  // process.env.NODE_ENV !== 'production'
  //   ? 'http://localhost:8080'
  //   : '/admin/v10';
  '/admin/v10';

export const baseAdminUrl = `${baseUrl}/admin`;

export const AdminMenu: RenderTree = {
  id: '/admin',
  name: appLocale.admin.main,
  url: '/admin',
  otherCode: subsystemCodesMap.admin,
  icon: <SettingsSmall />,
  homepageIcon: <Settings />,
  children: [
    {
      id: '/admin/journal',
      name: appLocale.admin.journal,
      url: '/admin/journal',
    },
    {
      id: '/admin/dataEntryLog',
      name: appLocale.admin.dataEntryLog,
      url: '/admin/dataEntryLog',
    },
    {
      id: adminNODLogPath,
      name: appLocale.admin.NODUploadLog,
      url: adminNODLogPath,
    },
    {
      id: '/admin/department',
      name: appLocale.admin.department,
      url: '/admin/department',
    },
    {
      id: '/admin/period',
      name: appLocale.admin.periods,
      url: '/admin/period',
    },
    {
      id: '/admin/report',
      name: appLocale.admin.reports,
      url: '/admin/report',
    },
    {
      id: '/admin/user',
      name: appLocale.admin.users,
      url: '/admin/user',
    },
    {
      id: '/admin/role',
      name: appLocale.admin.roles,
      url: '/admin/role',
    },

    ftpMenu,

    {
      id: '/admin/apiToken',
      name: appLocale.admin.apiToken,
      url: '/admin/apiToken',
    },
    {
      id: '/admin/EXD',
      name: appLocale.admin.EXD,
      url: '/admin/EXD',
    },
    {
      id: '/admin/notification',
      name: appLocale.admin.notification,
      url: '/admin/notification',
    },
    {
      id: '/admin/noticeLog',
      name: appLocale.admin.noticeLog,
      url: '/admin/noticeLog',
    },
    {
      id: '/admin/passwordRequest',
      name: appLocale.admin.passwordRequests,
      url: '/admin/passwordRequest',
    },
    {
      id: '/admin/passwordComplexitySettings',
      name: appLocale.admin.passwordComplexitySettings,
      url: '/admin/passwordComplexitySettings',
    },
  ],
};
