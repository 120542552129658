import { SubsystemHeader } from 'components/common';
import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import { PeriodList } from './PeriodList';
import appLocale from 'constants/appLocale';
import React from 'react';
import { FullPageWrapper } from '../../../components/table/FullPageWrapper';
import { useHistory } from 'react-router';

export const Periods: React.FC = () => {
  useUpdateBreadcrumbs([
    { breadcrumb: appLocale.admin.main, path: '/admin' },
    {
      breadcrumb: appLocale.admin.periods,
      path: '/admin/period',
    },
  ]);

  const { push } = useHistory();

  return (
    <FullPageWrapper>
      <SubsystemHeader
        title="Управление периодами"
        addButtonProps={{ onClick: () => push('/admin/period/add') }}
      />

      <PeriodList />
    </FullPageWrapper>
  );
};
