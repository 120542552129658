import { NSIRootPath } from '../../const/routes';

export const GreenspacesRootPath = `${NSIRootPath}/greenspaces`;

export const GreenspacesAreaPath = `${GreenspacesRootPath}/area`;
export const GreenspacesLawnPath = `${GreenspacesRootPath}/lawn`;
export const GreenspacesTreePath = `${GreenspacesRootPath}/tree`;

export const GreenspacesAddAreaPath = `${GreenspacesAreaPath}/add`;
export const GreenspacesAddLawnPath = `${GreenspacesLawnPath}/add`;
export const GreenspacesAddTreePath = `${GreenspacesTreePath}/add`;

export const GreenspacesEditAreaPath = `${GreenspacesAreaPath}/edit/:id`;
export const GreenspacesEditLawnPath = `${GreenspacesLawnPath}/edit/:id`;
export const GreenspacesEditTreePath = `${GreenspacesTreePath}/edit/:id`;

export const GreenspacesAPIPath = `${NSIRootPath}/v10/sources/greenplants`;
export const GreenspacesDictPath = `${GreenspacesAPIPath}/dictionaries`;
