export const fieldsLocale: { [key: string]: string } = {
  territoryType: 'Тип территории',
  address: 'Адрес',
  distance: 'Расстояние от дороги, м.',
  areaName: 'Площадка',
  pointId: 'Расстояние от края проезжей части, м.',
  date: 'Дата отбора',
  protocol: 'Протокол',
  protocolNumber: 'Номер протокола',
  protocolDate: 'Дата протокола',
  district: 'Округ',
  areaId: 'Участок',
  region: 'Район',
};
