import { ASKZVMeasurement } from './Tables/Measurements';
import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import appLocale from 'constants/appLocale';

export const ProtocolsWaterASKZV = () => {
  useUpdateBreadcrumbs([
    {
      breadcrumb: appLocale.protocols.title,
      path: '/protocols',
    },
    { breadcrumb: appLocale.subsystems.water, path: '/protocols/water' },
    { breadcrumb: appLocale.protocols.askzv, path: '/protocols/water/askzv' },
  ]);

  return <ASKZVMeasurement />;
};
