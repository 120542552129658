import { Filter } from 'features/Filter';
import { createInitialValuesToFormik, createValuesToRequest } from 'features/Filter/utils';
import React, { useMemo } from 'react';
import { IFilterItem, TDictionariesArray, TFilterDTO, TFiltersArray } from 'models';
import { IFilterValues } from 'components/common';
import { getSelectOptionsFromDictionaryByNameWithTransformation } from 'features/get-select-options-from-dictionary';
import combineFilters from 'lib/utils/combineFilters';
import { transformFiltersArray } from 'features/Filter/transformFiltersArray';
import { createDateToRequestWithTimezone } from 'lib/create-date';
import { IPeriod } from 'features/PeriodsButtons/types';
import { SchemaForCustomFilter } from 'models/schemas/filtersDatepickersSchema/FiltersDatepickersSchema';
import { AtmosphereMonitoringProtocolItem } from 'pages/protocols/ASKZA/types';
import appLocale from 'constants/appLocale';

type FilterListInitialValues = Record<
  'dateTimeMin' | 'dateTimeMax' | 'paramId' | 'stationId' | 'deleted' | 'periodId' | 'value',
  TFilterDTO<keyof AtmosphereMonitoringProtocolItem>['filters'][number]
>;

const initialValues: FilterListInitialValues = {
  dateTimeMin: {
    key: 'valueDate',
    operation: 'GTE',
    value: null,
  },
  dateTimeMax: {
    key: 'valueDate',
    operation: 'LTE',
    value: null,
  },
  paramId: {
    key: 'parameterId',
    operation: 'EQ',
    value: null,
  },
  stationId: {
    key: 'stationId',
    operation: 'EQ',
    value: null,
  },
  value: {
    key: 'value',
    operation: 'EQ',
    value: '',
  },
  deleted: {
    key: 'deleted',
    operation: 'EQ',
    value: false,
  },
  periodId: {
    key: 'periodId',
    operation: 'EQ',
    value: null,
  },
};

interface FilterProps {
  dictionaries: {
    data: TDictionariesArray;
  } | null;
  isLoading: boolean;
  onSubmit: (values: TFiltersArray<keyof AtmosphereMonitoringProtocolItem>) => void;
  filtersFromGraphic: Partial<IFilterValues> | null;
  setFiltersFromGraphic: (value: Partial<IFilterValues> | null) => void;
  filters: IFilterItem[];
  periods: IPeriod[] | null;
  savedPeriodId?: string;
}

const initialValuesToFormik = createInitialValuesToFormik(initialValues);

function AtmosphereFilter({
  setFiltersFromGraphic,
  filtersFromGraphic,
  dictionaries,
  isLoading,
  onSubmit,
  children,
  filters,
  periods,
  savedPeriodId,
}: React.PropsWithChildren<FilterProps>) {
  const stationsName = useMemo(
    () => getSelectOptionsFromDictionaryByNameWithTransformation(dictionaries?.data, 'stations', 'name'),
    [dictionaries]
  );

  const paramNumbers = useMemo(
    () => getSelectOptionsFromDictionaryByNameWithTransformation(dictionaries?.data, 'params', 'title'),
    [dictionaries]
  );

  const presetValues = useMemo<{ key: string; value: IFilterItem['value'] }[]>(() => {
    const preset: { key: string; value: IFilterItem['value'] }[] = [];
    const initialFilters = combineFilters(initialValues, filters);
    Object.keys(initialFilters).forEach((key) => {
      preset.push({ key: `${key}`, value: initialFilters[key].value });
    });
    return preset;
  }, [filters]);

  return (
    <Filter<typeof initialValuesToFormik>
      savedPeriodId={savedPeriodId}
      dateFieldFrom="dateTimeMin"
      dateFieldTo="dateTimeMax"
      periods={periods}
      isLoading={isLoading}
      isDisabled={isLoading}
      setFiltersFromGraphic={setFiltersFromGraphic}
      filtersFromGraphic={filtersFromGraphic}
      onSubmit={(values) => {
        const { dateTimeMin, dateTimeMax, ...rest } = createValuesToRequest(initialValues, values);
        const filters = transformFiltersArray<TFilterDTO<keyof AtmosphereMonitoringProtocolItem>['filters']>(
          Object.values({
            ...rest,
            dateTimeMin: {
              ...dateTimeMin,
              value: createDateToRequestWithTimezone(String(dateTimeMin.value)),
            },
            dateTimeMax: {
              ...dateTimeMax,
              value: createDateToRequestWithTimezone(String(dateTimeMax.value)),
            },
          })
        );
        onSubmit(filters);
      }}
      initialValues={initialValuesToFormik}
      mainFields={[
        {
          type: 'DATE',
          name: 'dateTimeMin',
          label: 'От',
          showTimeInput: true,
        },
        {
          type: 'DATE',
          name: 'dateTimeMax',
          label: 'До',
          showTimeInput: true,
        },
        {
          type: 'SELECT',
          name: 'stationId',
          label: 'Станция',
          options: stationsName,
        },
        {
          type: 'SELECT',
          name: 'paramId',
          label: 'Параметр',
          options: paramNumbers,
        },
        {
          type: 'TEXT',
          name: 'value',
          label: 'Значение',
        },
        {
          type: 'CHECKBOX',
          name: 'deleted',
          label: appLocale.common.showDeleted,
        },
      ]}
      presetValues={presetValues}
      validationSchema={() =>
        SchemaForCustomFilter([{ dateFromFieldName: 'dateTimeMin', dateToFieldName: 'dateTimeMax' }])
      }
    >
      {children}
    </Filter>
  );
}

export { AtmosphereFilter };
