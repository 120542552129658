import { NSIWaterApiPath, NSIWaterRootPath, NSIWaterSitesPaths } from 'pages/dictionaries/NSIWater/paths';
import { useDictionary } from 'hooks/useDictionary';
import { SubsystemHeader } from 'components/common';
import appLocale from 'constants/appLocale';
import { MaterialTable } from 'features/material-table/MaterialTableMini';
import React, { useMemo } from 'react';
import { useList } from 'hooks';
import { PropActions } from 'features/material-table/components/Actions';
import { addFiltersToColumns, ColumnWithFilter } from 'lib/utils/tableFilter';
import { Column } from '@material-table/core';
import { useHistory } from 'react-router-dom';
import { NSIWaterItem, NSIWaterItemType } from 'pages/dictionaries/NSIWater/types';
import NSIWaterSitesFilters from 'pages/dictionaries/NSIWater/NSIWaterSites/NSIWaterSitesFilters';
import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import { subsystemCodesMap } from 'constants/subsystemIdsMap';
import { useCustomRights } from 'hooks/useCustomRights';
import { getSelectOptionsFromDictionaryByName } from 'features/get-select-options-from-dictionary';
import { FullPageWrapper } from '../../../../components/table/FullPageWrapper';
import { DefaultTableCellSize } from '../../../../components/table/DefaultTableCellSize';
import { DEFAULT_PAGE_SIZE_NEW_TABLE } from '../../../../constants/tables';

const NSIWaterSites = () => {
  const { push } = useHistory();
  useUpdateBreadcrumbs([
    { breadcrumb: appLocale.nsi.water.title, path: NSIWaterRootPath },
    { breadcrumb: appLocale.nsi.water.sites.title, path: NSIWaterSitesPaths.root },
  ]);

  const { isWrite } = useCustomRights(subsystemCodesMap.nsi.waterObjects.observationSites);

  const { dictionaries, isDictionariesLoading } = useDictionary({ url: `${NSIWaterApiPath}/dictionaries` });

  const waterAreasSubsystems = useMemo(
    () => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'waterAreasSubsystems'),
    [dictionaries?.data],
  );
  const rayons = useMemo(
    () => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'rayons'),
    [dictionaries?.data],
  );
  const okrugs = useMemo(
    () => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'okrugs'),
    [dictionaries?.data],
  );
  const statuses = useMemo(
    () => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'statuses'),
    [dictionaries?.data],
  );

  const {
    isLoading: isListLoading,
    response,
    toggleOrder,
    setFilters,
    onPageChange,
    filterDTO,
    onSearchText,
    downloadAsFile,
    errorText,
    // selection is not implemented on backend
    // renderOnlySelectedCheckbox,
    onSubmitFilters,
    onSelectionChange,
    selectedIndexes,
    materialTableProps,
  } = useList<NSIWaterItem, keyof NSIWaterItem>(`${NSIWaterApiPath}/${NSIWaterItemType.area}/list`, {
    initialOrderFieldKey: '-subsystemId',
    saveFiltersState: true,
    selectionKey: 'id',
    defaultPageSize: DEFAULT_PAGE_SIZE_NEW_TABLE,
    resetDataOnNextRequest: true,
  });

  const actions = useMemo<PropActions<NSIWaterItem[]>>(
    () => [
      {
        type: 'edit',
        onClick: (e, item: any) => {
          push(NSIWaterSitesPaths.edit(item.id));
        },
        disabled: !isWrite,
        hidden: !isWrite,
      },
    ],
    [isWrite, push],
  );

  const columns = useMemo<ColumnWithFilter<NSIWaterItem>[]>(
    () => [
      {
        title: 'Модуль подсистемы',
        field: 'subsystemName',
      },
      {
        title: 'Наименование участка',
        field: 'name',
        render: (row) => <DefaultTableCellSize>{row.name}</DefaultTableCellSize>,
      },
      {
        title: 'Наименование водоема',
        field: 'waterName',
      },
      {
        title: 'Код участка',
        field: 'code',
      },
      {
        title: 'Округ',
        field: 'okrugId',
        render: (row) => okrugs?.find((item) => item.value === row.okrugId)?.name,
      },
      {
        title: 'Район',
        field: 'rayonId',
        render: (row) => rayons?.find((item) => item.value === row.rayonId)?.name,
      },
    ],
    [okrugs, rayons],
  );

  const columnsWithFilters = useMemo<Array<Column<NSIWaterItem>>>(
    () => addFiltersToColumns({ filters: filterDTO.filters, setFilters, columns }),
    [columns, filterDTO.filters, setFilters],
  );

  const isLoading = useMemo(() => isListLoading || isDictionariesLoading, [isDictionariesLoading, isListLoading]);

  return (
    <FullPageWrapper>
      <SubsystemHeader
        title={appLocale.nsi.water.sites.title}
        addButtonProps={{
          onClick: () => push(NSIWaterSitesPaths.add),
          isDisabled: !isWrite,
        }}
        filtersComponent={
          <NSIWaterSitesFilters
            subsystems={waterAreasSubsystems}
            okrugs={okrugs}
            rayons={rayons}
            statuses={statuses}
            isLoading={isLoading}
            onSubmit={(values) => onSubmitFilters(Object.values(values))}
            filters={filterDTO.filters}
          >
            {/* {renderOnlySelectedCheckbox} */}
          </NSIWaterSitesFilters>
        }
      />

      <MaterialTable
        data={response?.data}
        meta={response?.meta}
        toggleOrder={toggleOrder}
        filterDTO={filterDTO}
        onPageChange={onPageChange}
        columns={columnsWithFilters}
        actions={actions}
        isLoading={isLoading}
        setFilters={setFilters}
        options={{ search: true, selection: false }}
        onSearch={onSearchText}
        onSelectionChange={onSelectionChange}
        selectedIndexes={selectedIndexes}
        downloadAsFile={downloadAsFile}
        showTotalCount
        errorText={errorText}
        {...materialTableProps}
      />
    </FullPageWrapper>
  );
};

export default NSIWaterSites;
