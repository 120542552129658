import React from 'react';
import { Box, LightMode, useTheme } from '@chakra-ui/react';

export const LightModeWrapper: React.FC = ({ children }) => {
  const theme = useTheme();
  return (
    <LightMode>
      {/* HACK: override global default chakra colors */}
      <Box
        height="100%"
        background={theme.colors.white}
        color={theme.colors.black}
        __css={{
          '*, *::before, &::after': {
            borderColor: theme.colors.gray[200],
          },
        }}
      >
        {children}
      </Box>
    </LightMode>
  );
};
