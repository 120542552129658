import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { normalizeExistParameters } from 'features/normalizeExistParameters';
import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import { createGreenPlantsEditUrl, GreenPlantsFloraUrl, GreenPlantsRootPath } from 'pages/routes/GreenPlants';
import appLocale from 'constants/appLocale';
import { useFloraDictionaries } from '../useFloraDictionaries';
import { useFloraListItem } from '../useFloraListItem';
import { GreenPlantsForm } from '../../Form/GreenPlantsForm';
import { greenPlantsFormInitialValues, GreenPlantsFormTypes, TGreenPlantsForm } from '../../Form/types';
import { useFloraEditItem } from './useFloraEditItem';
import { IUploadMedia } from 'components/common/FileUploadDragAndDrop/types';
import { useNotifications } from 'hooks';
import { updateFiles, UploadObjectType } from 'api/services/media';
import { ERROR_MESSAGES } from 'constants/messages';
import { useGoBackWithFallback } from '../../../../../lib/utils/goBackWithFallback';

const withEditFlora = (Component: typeof GreenPlantsForm) =>
  memo(() => {
    const type = 'flora';
    const { registryId } = useParams<{
      registryId: string;
    }>();
    const defaultIndex = Object.values(GreenPlantsFormTypes).indexOf(type);
    const { get: getPlants, result: flora, isLoading: isLoadingFlora } = useFloraListItem(registryId);
    const [media, setMedia] = useState<IUploadMedia[]>([]);
    const { errorNotify } = useNotifications();
    const history = useHistory();
    const { backWithFallback } = useGoBackWithFallback();

    useEffect(() => {
      getPlants();
    }, [getPlants]);

    useUpdateBreadcrumbs([
      { breadcrumb: appLocale.common.data, path: '/data' },
      {
        breadcrumb: appLocale.subsystems.greenplants,
        path: GreenPlantsRootPath,
      },
      {
        breadcrumb: 'Деревья и кустарники',
        path: GreenPlantsFloraUrl,
      },
      {
        breadcrumb: 'Редактирование обследования',
        path: createGreenPlantsEditUrl('flora', flora?.registryId ?? ''),
        isLoading: isLoadingFlora,
      },
    ]);

    const { editFlora, isLoading: isLoadingEdit } = useFloraEditItem();

    const { dictionaries, isLoading: isLoadingDictionaries } = useFloraDictionaries();
    const floraParams = flora?.parameters;
    const parameters = useMemo(
      () => normalizeExistParameters(floraParams, dictionaries.plantParameters, dictionaries.areaParameters),
      [dictionaries.areaParameters, dictionaries.plantParameters, floraParams],
    );

    const initialFlora = flora ? { ...flora, parameters } : greenPlantsFormInitialValues.flora;

    const onError = useCallback(
      (message?: string | null) => {
        errorNotify({
          key: message || 'file/create/error',
          message: message || ERROR_MESSAGES.CREATE,
        });
      },
      [errorNotify],
    );

    const onSubmit = useCallback(
      async (values: Partial<TGreenPlantsForm>) => {
        await editFlora(values.flora);

        if (dictionaries?.objectType) {
          await updateFiles(registryId, dictionaries?.objectType[0].value as UploadObjectType, media)
            .then(() => {
              backWithFallback(GreenPlantsFloraUrl);
            })
            .catch((error: Error) => {
              onError(error.message);
              history.push(`${GreenPlantsFloraUrl}/edit/${registryId}?openAttachmentsTab=true`);
            });
        }
      },
      [backWithFallback, dictionaries?.objectType, editFlora, history, media, onError, registryId],
    );

    return (
      <Component
        header="Редактирование результатов"
        defaultIndex={defaultIndex}
        initialValues={{
          ...greenPlantsFormInitialValues,
          flora: initialFlora,
        }}
        isEditMode
        type={type}
        isSubmitting={isLoadingEdit || isLoadingFlora || isLoadingDictionaries}
        onSubmit={onSubmit}
        floraDictionaries={dictionaries}
        media={media}
        setMedia={setMedia}
        registryId={registryId}
      />
    );
  });

export { withEditFlora };
