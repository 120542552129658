import { FormControl, FormErrorMessage, FormLabel, InputGroup, StackProps } from '@chakra-ui/react';
import { Select, SelectProps } from '@chakra-ui/select';
import { isString, map } from 'lodash';
import React, { ReactNode, useRef } from 'react';
import { ArrowDownIcon } from 'components/icons';
import { SelectOption } from '../types';
import { FormikFieldProps } from '../../types';

export interface SystemSelectProps extends SelectProps {
  options?: SelectOption[];
  label?: ReactNode;
  isDisabled?: boolean;
  arrowColor?: string;
  clearColor?: string;
  iconWrapperProps?: StackProps;
}

const SystemSelect: React.FC<SystemSelectProps & Partial<FormikFieldProps>> = ({
  label,
  size = 'lg',
  options = [],
  multiple,
  error,
  ...props
}) => {
  const labelRef = useRef<HTMLLabelElement | null>(null);

  return (
    <FormControl isInvalid={Boolean(error)} _focusWithin={{ label: { transform: 'translate(0px, 0px)' } }}>
      <FormLabel
        ref={labelRef}
        position="absolute"
        margin={0}
        pointerEvents="none"
        zIndex={10}
        left={4}
        top={1}
        color="select.label.default"
        fontSize="14px"
        transform={`translate(0px, ${isString(props.value) && props.value !== '' ? '0px' : '15px'})`}
      >
        {label}
      </FormLabel>
      <InputGroup>
        <Select
          size={size}
          background="select.background.default"
          borderRadius="2px"
          focusBorderColor="select.border.primary"
          errorBorderColor="select.border.error"
          icon={<ArrowDownIcon />}
          {...props}
        >
          {map(options, (option, index) => (
            <option key={index} value={option.value}>
              {option.name}
            </option>
          ))}
        </Select>
      </InputGroup>
      <FormErrorMessage>{error}</FormErrorMessage>
    </FormControl>
  );
};

export { SystemSelect };
