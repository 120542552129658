import { Modal } from 'components/common/Modals';
import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import React from 'react';
import { useAvailability, useDisclosure } from 'hooks';
import { useList } from 'hooks/useList';
import appLocale from 'constants/appLocale';
import { GeoecologicalRootPath } from 'pages/data/GeoecologicalProcesses/consts';
import { DangerFilter } from './DangerFilter';
import { DangerProcessTable } from './DangerProcessTable';
import { DangerouslyGeologicalProcessesHeader } from '../components';
import {
  DangerProcessItem,
} from 'pages/data/GeoecologicalProcesses/DangerouslyGeologicalProcesses/DangerProcess/types';
import { UploadFileModalContent } from 'features/UploadFileDragAndDrop/UploadFileModalContent';
import subsystemIdsMap from 'constants/subsystemIdsMap';
import { usePeriodsDict } from 'features/PeriodsButtons/usePeriodsDict';
import { useDictionary } from 'hooks/useDictionary';
import { DEFAULT_PAGE_SIZE_NEW_TABLE } from '../../../../../constants/tables';
import { FullPageWrapper } from '../../../../../components/table/FullPageWrapper';

export const DangerProcess = () => {
  const { isReadOnly, isNoRights, isWrite, isExecute, allAvailable } = useAvailability(subsystemIdsMap.geoecologyRoot);

  useUpdateBreadcrumbs([
    { breadcrumb: appLocale.common.data, path: '/data' },
    {
      breadcrumb: appLocale.subsystems.geoecologicalprocesses,
      path: GeoecologicalRootPath,
    },
    {
      breadcrumb: 'Опасные геологические процессы',
      path: `${GeoecologicalRootPath}/monitoring/danger/dangerprocess`,
    },
  ]);

  const { isOpen: isOpenLoad, onClose: onCloseLoad, onOpen: onOpenLoad } = useDisclosure();

  const {
    refetch,
    isLoading: isLoadingDangerProccessList,
    response,
    toggleOrder,
    setFilters,
    onPageChange,
    filterDTO,
    downloadAsFile,
    saveFilters,
    errorText,
    selectedIndexes,
    renderOnlySelectedCheckbox,
    onSubmitFilters,
    onSelectionChange,
    materialTableProps,
  } = useList<DangerProcessItem, keyof DangerProcessItem>('/geoecology/v10/geoecology/dangerprocess/list', {
    initialOrderFieldKey: '-measuringDate',
    preventFirstFetch: true,
    exportSubsystemName: 'dangerprocess',
    saveFiltersState: true,
    selectionKey: 'measuringId',
    defaultPageSize: DEFAULT_PAGE_SIZE_NEW_TABLE,
    resetDataOnNextRequest: true,
  });

  const { dictionaries } = useDictionary({ url: '/geoecology/v10/geoecology/dangerprocess/dictionaries' });

  const { result: periods } = usePeriodsDict(`auth/v10/user/period/${subsystemIdsMap.dangerprocesses}`);
  return (
    <FullPageWrapper>
      <DangerouslyGeologicalProcessesHeader
        allAvailable={allAvailable}
        isReadonly={isReadOnly}
        isExecute={isExecute}
        isNoRights={isNoRights}
        isWrite={isWrite}
        onOpenLoad={onOpenLoad}
        active="dangerprocess"
      >
        <DangerFilter
          periods={periods}
          filters={filterDTO?.filters || []}
          isLoading={isLoadingDangerProccessList}
          isDisabled={isLoadingDangerProccessList}
          dictionaries={dictionaries}
          onSubmit={(values) => {
            onSubmitFilters(Object.values(values));
          }}
        >
          {renderOnlySelectedCheckbox}
        </DangerFilter>
      </DangerouslyGeologicalProcessesHeader>

      <Modal isOpen={isOpenLoad} onClose={onCloseLoad} header="Загрузка данных из файла">
        <UploadFileModalContent
          createTypesPath={() => '/geoecology/v10/geoecology/dangerprocess/load/types'}
          createUploadPath={() => '/geoecology/v10/geoecology/dangerprocess/load'}
          types={[
            {
              name: 'Описание проявлений ОГП',
              value: 'dangerprocess',
            },
          ]}
          onClose={onCloseLoad}
          refetch={refetch}
        />
      </Modal>

      <DangerProcessTable
        allAvailable={allAvailable}
        isExecute={isExecute}
        isWrite={isWrite}
        isReadonly={isReadOnly}
        isNoAction={isNoRights}
        setFilters={setFilters}
        toggleOrder={toggleOrder}
        onPageChange={onPageChange}
        filterDTO={filterDTO}
        filters={filterDTO.filters}
        refetchCB={refetch}
        dangerProccessList={response}
        downloadAsFile={downloadAsFile}
        onSelectionChange={onSelectionChange}
        selectedIndexes={selectedIndexes}
        isLoading={isLoadingDangerProccessList}
        saveFilters={saveFilters}
        errorText={errorText}
        materialTableProps={materialTableProps}
      />
    </FullPageWrapper>
  );
};
