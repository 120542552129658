import * as React from 'react';
import { memo, useMemo } from 'react';
import {
  Flex,
  Modal as ChakraModal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalProps as ChakraModalProps,
  Stack,
} from '@chakra-ui/react';
import { CrossIcon } from 'components/icons';

import { Button } from '../Button';
import { LightModeWrapper } from '../LightModeWrapper/LightModeWrapper';

export interface ModalProps extends ChakraModalProps {
  header?: React.ReactNode;
  showCloseButton?: boolean;
  width?: string;
  customCloseButton?: React.ReactNode;
  closeButtonPosition?: 'top' | 'contentTop';
  closeButtonText?: string;
}

export const Modal: React.FC<ModalProps> = memo(
  ({
    onClose,
    isOpen,
    width = 'auto',
    header,
    showCloseButton = true,
    children,
    size = 'full',
    customCloseButton,
    closeButtonPosition = 'top',
    closeButtonText = 'Закрыть',
    ...props
  }) => {
    const renderCloseButton = useMemo(() => {
      if (!showCloseButton) return null;

      return customCloseButton ? (
        <>{customCloseButton}</>
      ) : (
        <Button
          variant="ghost"
          left={closeButtonPosition === 'top' ? '180px' : 0}
          padding={closeButtonPosition === 'contentTop' ? 0 : '2'}
          width="fit-content"
          leftIcon={<CrossIcon />}
          onClick={onClose}
        >
          {closeButtonText}
        </Button>
      );
    }, [closeButtonPosition, closeButtonText, customCloseButton, onClose, showCloseButton]);

    return (
      <ChakraModal onClose={onClose} size={size} isOpen={isOpen} closeOnOverlayClick={false} {...props}>
        <LightModeWrapper>
          <ModalOverlay />
          <ModalContent background="panel.background.default">
            {closeButtonPosition === 'top' && renderCloseButton}
            <ModalBody>
              <Flex mt={8} justifyContent="center" width="100%" alignItems="flex-start">
                <Stack width={width} background="panel.background.primary" padding={8}>
                  {showCloseButton && closeButtonPosition === 'contentTop' && renderCloseButton}
                  <ModalHeader p={0}>{header}</ModalHeader>
                  <Flex>{children}</Flex>
                </Stack>
              </Flex>
            </ModalBody>
          </ModalContent>
        </LightModeWrapper>
      </ChakraModal>
    );
  }
);
