import { extendTheme, theme } from '@chakra-ui/react';
import type { Styles } from '@chakra-ui/theme-tools';
import { mode } from '@chakra-ui/theme-tools';
import ProximaWoff from './fonts/proxima/proxima_regular.woff';
import ProximaWoff2 from './fonts/proxima/proxima_regular.woff2';

const styles: Styles = {
  global: (props) => ({
    '*': {
      boxSizing: 'border-box',
    },
    'html, body, #root': {
      minHeight: '100%',
    },
    '@font-face': {
      fontFamily: 'Proxima',
      src: `local('Proxima Nova'),
      local('Proxima Nova Regular'),
      url(${ProximaWoff}) format('woff2'),
       url(${ProximaWoff2}) format('woff')`,
      fontWeight: 400,
      fontStyle: 'normal',
    },
    input: {
      '&:-webkit-autofill': {
        boxShadow: '0 0 0 30px white inset !important',
      },
    },
    body: {
      fontFamily: 'Proxima',
      color: mode('gray.800', 'white')(props),
      bg: mode('white', 'blue.900')(props),
      margin: 0,
      padding: 0,
      '-webkit-font-smoothing': 'auto',
    },
    '&::-webkit-scrollbar': {
      border: '1px solid #F5F5F5',
      borderTop: 'none',
      width: '12px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#F5F5F5',
      borderRadius: '6px',
      border: '3px solid transparent',
      backgroundClip: 'content-box',
    },
    '@media print': {
      '#sidebar': {
        display: 'none',
      },
      '#topline': {
        display: 'none',
      },
      '#content-wrapper': {
        width: '100%',
        zIndex: 1,
      },
      '#menu-list-menuList': {
        display: 'none',
      },
    },
  }),
};

export const chakraTheme = extendTheme({
  styles,
  initialColorMode: 'light',
  useSystemColorMode: false,

  fonts: { body: 'Proxima', heading: 'Proxima' },

  components: {
    Button: { baseStyle: { _focus: { boxShadow: 'none' } } },
    Select: {
      ...theme.components.Select,
      sizes: {
        md: {
          addon: { height: '40px' },
          field: { height: '40px' },
        },
        lg: {
          addon: { height: '60px' },
          field: { height: '60px' },
        },
      },
      defaultProps: {
        background: 'transparent',
      },
    },
  },
  colors: {
    ...theme.colors,
    error: { 500: '#FF3434' },
    PrimaryButton: { 500: '#208CFF' },
    ErrorButton: { 500: '#FF3434' },
    DefaultButton: { 500: '#FFFFFF' },
    text: {
      primary: '#208CFF',
      secondary: '#9A9A9A',
      error: '#FF3434',
      default: '#000000',
    },
    input: {
      background: { default: '#fff' },
      label: { default: '#9FABBC' },
      border: {
        primary: '#208CFF',
        secondary: '#9A9A9A',
        error: '#FF3434',
        default: '#000000',
      },
    },
    border: { default: '#D4D9DC' },
    icon: {
      primary: '#208CFF',
      secondary: '#9A9A9A',
      error: '#FF3434',
      default: '#000000',
    },
    panel: {
      background: { default: '#FAFBFB', primary: '#fff' },
      border: { default: '#D6E1EB' },
      text: {
        primary: '#208CFF',
        secondary: '#9A9A9A',
        error: '#FF3434',
        default: '#000000',
      },
    },
    spinner: {
      primary: '#208CFF',
      secondary: '#9A9A9A',
      error: '#FF3434',
      default: '#000000',
    },
    menu: {
      default: '#fff',
      hover: '#F2F8FF',
      selected: '#F2F8FF',
      active: '#c8e5ff',
    },
    select: {
      background: { default: '#fff' },
      label: { default: '#9FABBC' },
      border: {
        primary: '#208CFF',
        secondary: '#9A9A9A',
        error: '#FF3434',
        default: '#000000',
      },
      arrow: { default: '#D4D9DC', focused: '#208CFF' },
      clear: { default: '#FF3434' },
      list: {
        hover: '#F2F8FF',
        selected: '#F5FAFF',
      },
    },
    blue: {
      900: '#2F3741',
    },
  },
});
