import { Flex, Grid, GridItem } from '@chakra-ui/react';
import { Field, FormikProvider, useFormik } from 'formik';
import { Button, Input } from 'components/common';
import { Textarea } from '@chakra-ui/textarea';
import React, { useEffect } from 'react';
import { RoleSchema } from 'models/schemas/admin/role';
import { RoleItem } from '../../models/RoleItem';
import { baseAdminRoleUrl } from '../const';
import { useNotifications, useRequest } from 'hooks';
import { ERROR_MESSAGES, SUCCESS_MESSAGES } from 'constants/messages';
import { useHistory } from 'react-router-dom';

interface IProps {
  id?: string;
}

const initialValues = {
  id: undefined,
  code: '',
  name: '',
  description: '',
  deleted: false,
};

export const RolePage = ({ id }: IProps) => {
  const { get, result: currentRole } = useRequest<RoleItem>(`${baseAdminRoleUrl}/${id}`);
  const history = useHistory();

  useEffect(() => {
    if (id) {
      get();
    }
  }, [get, id]);

  const { successNotify, errorNotify } = useNotifications();

  const successHandler = (newRole: RoleItem | null) => {
    successNotify({
      key: 'file/create/success',
      message: id ? SUCCESS_MESSAGES.EDIT : SUCCESS_MESSAGES.CREATE,
    });
    if (!id && newRole) {
      history.push(`edit/${newRole.id}`);
    }
  };

  const errorHandler = (error: any) => {
    errorNotify({
      key: 'file/create/error',
      message: `${ERROR_MESSAGES.EDIT}. ${typeof error === 'string' ? error : ''}`,
    });
  };

  const { put: updateRole } = useRequest(`${baseAdminRoleUrl}/${id}`, successHandler, errorHandler);
  const { post: createRole } = useRequest(baseAdminRoleUrl, successHandler, errorHandler);

  const initialRoleValues = {
    initialValues,
    ...currentRole,
  };

  const {
    values,
    errors,
    getFieldMeta,
    getFieldHelpers,
    getFieldProps,
    handleReset,
    submitForm,
    handleChange,
    setFieldValue,
    ...formik
  } = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: false,
    validateOnBlur: true,
    initialValues: initialRoleValues,
    validationSchema: RoleSchema,
    onSubmit: (values) => (id ? updateRole(values) : createRole(values)),
  });

  return (
    <FormikProvider
      value={{
        values,
        errors,
        getFieldMeta,
        getFieldHelpers,
        getFieldProps,
        handleChange,
        handleReset,
        submitForm,
        setFieldValue,
        ...formik,
      }}
    >
      <Grid marginTop="20px" gap="15px" templateColumns="repeat(3, 1fr)">
        <GridItem>
          <Field as={Input} label="Код" name="code" error={errors.code} value={values.code} onChange={handleChange} />
        </GridItem>
        <GridItem>
          <Field
            as={Input}
            label="Название роли"
            name="name"
            error={errors.name}
            value={values.name}
            onChange={handleChange}
          />
        </GridItem>
        <GridItem>
          <Field
            as={Textarea}
            placeholder="Описание роли"
            name="description"
            error={errors.description}
            value={values.description}
            onChange={handleChange}
          />
        </GridItem>
      </Grid>
      <Flex>
        <Button onClick={() => submitForm()} variant="solid">
          Сохранить
        </Button>
        <Button marginLeft={10} onClick={() => history.push('/admin/role')}>Отмена</Button>
      </Flex>
    </FormikProvider>
  );
};
