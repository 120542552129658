import { DateInput, Select, SelectOption } from 'components/common';
import { Grid, GridItem } from '@chakra-ui/react';
import { Field, useFormikContext } from 'formik';
import appLocale from 'constants/appLocale';
import React from 'react';
import { createUTCTime } from 'lib/create-date';
import { IFilterItem, TDictionaryItemDictionary } from 'models';
import FilterFormControls from 'components/form/FilterFormControls';

interface IProps {
  industries: SelectOption[];
  parameters: TDictionaryItemDictionary[];
}

export const PDVFormFields = ({ industries, parameters }: IProps) => {
  const { submitForm, setFieldValue, handleReset, values, errors } = useFormikContext<{ [p: string]: IFilterItem }>();
  return (
    <>
      <Grid gap="12px" rowGap="8px" templateColumns="repeat(5, 0.2fr)">
        <GridItem>
          <Field
            as={DateInput}
            name="startDate.value"
            label={appLocale.common.from}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setFieldValue('startDate.value', createUTCTime(e.target.value))
            }
            showTimeInput
            error={errors.startDate?.value}
          />
        </GridItem>
        <GridItem>
          <Field
            as={DateInput}
            name="endDate.value"
            label={appLocale.common.to}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setFieldValue('endDate.value', createUTCTime(e.target.value))
            }
            showTimeInput
            error={errors.endDate?.value}
          />
        </GridItem>
        <GridItem>
          <Field
            as={Select}
            name="industryId.value"
            label="Предприятие"
            options={industries}
            value={values.industryId.value}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFieldValue('industryId.value', e.target.value);
              if (!e.target.value) {
                setFieldValue('sourceId.value', null);
              }
            }}
          />
        </GridItem>
        <GridItem>
          <Field
            as={Select}
            name="parameterId.value"
            label="Параметр"
            options={parameters.map((item) => ({
              ...item,
              name: item.title,
              value: item.parameterId,
            }))}
            value={values.parameterId.value}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFieldValue('parameterId.value', e.target.value)}
          />
        </GridItem>
        <GridItem rowStart={1} colStart={6}>
          <FilterFormControls
            onReset={(e) => {
              handleReset(e);
              setTimeout(submitForm, 0);
            }}
            onSubmit={submitForm}
          />
        </GridItem>
      </Grid>
    </>
  );
};
