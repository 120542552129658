import { ITask } from '../../../types';
import TableCell from '@material-ui/core/TableCell';
import { Tooltip } from '@material-ui/core';
import { CompareIconBlue, DeleteIcon, EditIcon, PrintIcon, ViewIcon2 } from 'components/icons';
import React, { useMemo, useState } from 'react';
import { useDisclosure, useNotifications, useRequest } from 'hooks';
import { ERROR_MESSAGES, SUCCESS_MESSAGES } from 'constants/messages';
import { useHistory } from 'react-router-dom';
import { TableModal } from '../../TableModal';
import { Modal } from 'components/common';
import { getSelectOptionsFromDictionaryByName } from 'features/get-select-options-from-dictionary';
import { dictionaryToMap } from 'lib/utils/dictionaryToMap';
import { PELAtmoAdd, PELNoiseAdd } from '../../../../../pel/constants';
import { draftStatus, inProgressStatus, raidSuccess } from 'pages/citizenAppeal/consts/consts';
import { TDictionariesArray } from 'models';
import { Box } from '@chakra-ui/react';
import { AxiosRequestConfig } from 'axios';

interface IProps {
  count: number;
  task: ITask;
  noise?: boolean;
  tasksDictionaries: { data: TDictionariesArray } | null;
  refetch: () => void;
  handleOpenModal?: (removeOptions: AxiosRequestConfig<any>) => void;
  isWrite: boolean;
}

export const PELTaskLine = ({
  count,
  task,
  noise,
  tasksDictionaries,
  refetch,
  handleOpenModal,
  isWrite,
}: IProps) => {
  const [state, setState] = useState<ITask>();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const history = useHistory();
  const reportInfo = { ...task, tabIndex: 2 };
  const { successNotify, errorNotify } = useNotifications();

  const regionsFinder = useMemo(() => {
    const forSelect = getSelectOptionsFromDictionaryByName(tasksDictionaries?.data, 'regions');
    return dictionaryToMap(forSelect);
  }, [tasksDictionaries]);

  const onSuccess = () => {
    refetch();
    successNotify({
      key: 'planned/success',
      message: SUCCESS_MESSAGES.PLANNED,
    });
  };
  const onError = () => {
    errorNotify({
      key: 'planned/error',
      message: ERROR_MESSAGES.PLANNED,
    });
  };
  const { post } = useRequest('', onSuccess, onError);

  const takeToWork = (taskId: string) => {
    post(null, {
      url: `/appeal/v10/tasks/${taskId}/add-to-raid`,
    });
  };
  return (
    <>
      {task.status === inProgressStatus && isWrite ? (
        <TableCell style={{ borderColor: 'transparent' }} rowSpan={count}>
          <Tooltip title="Запланировать">
            <CompareIconBlue onClick={() => takeToWork(task.id)} />
          </Tooltip>
        </TableCell>
      ) : (
        <TableCell style={{ borderColor: 'transparent' }}>
          <Box width="22px" />
        </TableCell>
      )}

      {!(task.status === inProgressStatus || task.status === draftStatus) && isWrite ? (
        <TableCell style={{ borderColor: 'transparent' }} rowSpan={count}>
          <Tooltip title="Отчет">
            <ViewIcon2 onClick={() => history.push(noise ? PELNoiseAdd : PELAtmoAdd, reportInfo)} />
          </Tooltip>
        </TableCell>
      ) : (
        <TableCell style={{ borderColor: 'transparent' }}>
          <Box width="22px" />
        </TableCell>
      )}

      {task.status !== raidSuccess && isWrite ? (
        <TableCell style={{ borderColor: 'transparent' }} rowSpan={count}>
          <Tooltip title="Редактировать">
            <EditIcon onClick={() => history.push(noise ? PELNoiseAdd : PELAtmoAdd, task)} />
          </Tooltip>
        </TableCell>
      ) : (
        <TableCell style={{ borderColor: 'transparent' }}>
          <Box width="22px" />
        </TableCell>
      )}
      {handleOpenModal && !task.deleted && isWrite ? (
        <TableCell rowSpan={count} style={{ borderColor: 'transparent' }}>
          <Tooltip title="Удалить">
            <DeleteIcon onClick={() => handleOpenModal({ url: `/appeal/v10/tasks/${task.id}` })} />
          </Tooltip>
        </TableCell>
      ) : (
        <TableCell rowSpan={count} style={{ borderColor: 'transparent' }} />
      )}

      <TableCell style={{ borderColor: 'transparent' }} rowSpan={count}>
        <Tooltip title="Смотреть текст задания">
          <PrintIcon
            onClick={() => {
              setState(task);
              onOpen();
            }}
          />
        </Tooltip>
        <Modal isOpen={isOpen} onClose={onClose}>
          <TableModal noise={noise} state={state} regionsFinder={regionsFinder} />
        </Modal>
      </TableCell>
    </>
  );
};
