import { IFilterItem } from 'models';

export const initialValues: Record<string, IFilterItem<string>> = {
  territoryName: {
    key: 'territoryName',
    operation: 'CONTAINS',
    value: '',
  },
  districts: {
    key: 'districts',
    operation: 'IN',
    value: '',
  },
  regions: {
    key: 'regions',
    operation: 'IN',
    value: '',
  },
  dateTimeMin: {
    key: 'startDate',
    operation: 'GTE',
    value: null,
  },
  dateTimeMax: {
    key: 'startDate',
    operation: 'LTE',
    value: null,
  },
  protocolNumber: {
    key: 'reportPoints.protocolNumber',
    operation: 'EQ',
    value: '',
  },
  pelProtocolNumber: {
    key: 'reportPoints.pelProtocolNumber',
    operation: 'EQ',
    value: '',
  },
  deleted: {
    key: 'deleted',
    operation: 'EQ',
    value: false,
  }
};
