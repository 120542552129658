import { SubsystemHeader } from 'components/common';
import appLocale from 'constants/appLocale';
import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import { useHandleDeleteData, useList, useRequest } from 'hooks';
import { NotificationSettingsItem } from 'pages/admin/models/NotificationSettingsItem';
import { baseAdminNotificationUrl } from 'pages/admin/Notification/const';
import { MaterialTable } from 'features/material-table/MaterialTableMini';
import { NotificationType } from 'pages/admin/models/NotificationType';
import { NotificationSource } from 'pages/admin/models/NotificationSource';
import { Column } from '@material-table/core';
import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { tableIcons } from 'pages/admin/components/CustomTable';
import { NotificationFilter } from 'pages/admin/Notification/NotificationFilter';
import { DEFAULT_PAGE_SIZE_NEW_TABLE } from '../../../constants/tables';
import { FullPageWrapper } from '../../../components/table/FullPageWrapper';
import { DefaultTableCellSize } from '../../../components/table/DefaultTableCellSize';

export const Notifications: React.FC = () => {
  useUpdateBreadcrumbs([
    { breadcrumb: appLocale.admin.main, path: '/admin' },
    {
      breadcrumb: appLocale.admin.notification,
      path: '/admin/notification',
    },
  ]);
  const history = useHistory();

  const methods = useMemo(() => {
    const map = new Map<string, string>();
    Object.entries(NotificationType)
      .forEach((v) => map.set(v[0], v[1]));
    return map;
  }, []);

  const sources = useMemo(() => {
    const map = new Map<string, string>();
    Object.entries(NotificationSource)
      .forEach((v) => map.set(v[0], v[1]));
    return map;
  }, []);

  const { post: uneraseNotificationSetting, isLoading: isNotificationSettingsUnerases } =
    useRequest<NotificationSettingsItem>(baseAdminNotificationUrl);

  const {
    response,
    filterDTO,
    onPageChange,
    isLoading: isListLoading,
    toggleOrder,
    onSearchText,
    onSubmitFilters,
    refetch,
  } = useList<NotificationSettingsItem>(baseAdminNotificationUrl, {
    fetchMethod: 'GET',
    defaultPageSize: DEFAULT_PAGE_SIZE_NEW_TABLE,
    resetDataOnNextRequest: true,
  });

  const { ModalComponent: ConfirmDeleteModal, handleOpenModal } = useHandleDeleteData({ refetch });

  const columns: Column<NotificationSettingsItem>[] = useMemo(() => [
    {
      title: 'Роль',
      field: 'roleName',
      defaultSort: 'asc',
    },
    {
      title: 'Тип уведомления',
      field: 'sources',
      render: (rowdata: NotificationSettingsItem) => {
        const displayValue = rowdata.sources.map((s: string) => sources.get(s))
          .join(',');
        return <DefaultTableCellSize maxWidth={300}>{`{${displayValue}}`}</DefaultTableCellSize>;
      },
    },
    {
      title: 'Подсистема',
      field: 'subsystemName',
    },
    {
      title: 'Способы уведомления',
      field: 'methods',
      render: (rowdata: NotificationSettingsItem) => {
        const displayValue = rowdata.methods.map((m: string) => methods.get(m))
          .join(',');
        return <DefaultTableCellSize maxWidth={300}>{`{${displayValue}}`}</DefaultTableCellSize>;
      },
    },
    {
      title: 'Удален',
      field: 'deleted',
      render: (rowdata: NotificationSettingsItem) => <input type="checkbox" checked={rowdata.deleted} readOnly />,
      width: 70,
    },
  ], [methods, sources]);

  const isLoading = useMemo(() => (
    isNotificationSettingsUnerases || isListLoading
  ), [isListLoading, isNotificationSettingsUnerases]);

  return (
    <FullPageWrapper>
      <SubsystemHeader
        title="Управление уведомлениями"
        addButtonProps={{ onClick: () => history.push(`${history.location.pathname}/add`) }}
        filtersComponent={
          <NotificationFilter
            isLoading={isLoading}
            onSubmit={(values) => onSubmitFilters(Object.values(values))}
            filters={filterDTO.filters}
          />
        }
      />

      <MaterialTable
        filterDTO={filterDTO}
        data={response?.data}
        meta={response?.meta}
        toggleOrder={toggleOrder}
        onPageChange={onPageChange}
        columns={columns}
        options={{ search: true, selection: false }}
        actions={[
          {
            type: 'edit',
            onClick: (event: any, item: NotificationSettingsItem) =>
              history.push(`/admin/notification/edit/${item.id}`),
          },
          {
            position: 'row',
            action: (row) => ({
              icon: tableIcons.Delete,
              tooltip: 'Удалить',
              onClick: () => {
                if (!row?.id) return;
                handleOpenModal({ url: `${baseAdminNotificationUrl}/${row.id}` });
              },
              hidden: row.deleted,
            }),
          },
          {
            position: 'row',
            action: (row) => ({
              icon: tableIcons.Reload,
              tooltip: 'Снять пометку на удаление',
              onClick: async () => {
                if (row?.id == null) return;
                const { id } = row;
                await uneraseNotificationSetting(undefined, {
                  url: `${baseAdminNotificationUrl}/${id}/unerase`,
                });
                refetch();
              },
              hidden: !row.deleted,
            }),
          },
        ]}
        isLoading={isLoading}
        onSearch={onSearchText}
        showTotalCount
      />

      {ConfirmDeleteModal}
    </FullPageWrapper>
  );
};
