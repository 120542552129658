import React, { useCallback, useMemo, useState } from 'react';
import { MaterialTable } from 'features/material-table/MaterialTableMini';
import { IInstructionRecord } from 'pages/data/MASKSH/consts/types';
import { Column } from '@material-table/core';
import { InstructionsFilters } from 'pages/data/MASKSH/Instructions/components/InstructionsFilters';
import { SubsystemHeader } from 'components/common';
import { useDisclosure } from '@chakra-ui/hooks';
import { DeleteRowModal } from 'pages/data/MASKSH/components/DeleteRowModal';
import {
  MASKSHApiInstruction,
  MASKSHApiInstructionsDictionaries,
  MASKSHApiInstructionsList,
  MASKSHInstructions,
  MASKSHInstructionsAdd,
  MASKSHRoot,
} from 'pages/data/MASKSH/consts/menu';
import { TruncatedStringPopoverCell } from 'components/table/TruncatedStringPopoverCell';
import { useHistory } from 'react-router';
import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import { useList } from 'hooks/useList';
import { DateTimeTableCell } from 'pages/data/MASKSH/Data/components/DateTimeTableCell';
import appLocale from 'constants/appLocale';
import { ImageIcon } from 'components/icons';
import FilePreviewModalWrapper from 'components/common/FilePreviewModal/FilePreviewModalWrapper';
import useFilePreviewModalWrapperData from 'components/common/FilePreviewModal/hooks/useFilePreviewModalWrapperData';
import { fieldInstructionLocale as fieldLabel, tableLocale } from 'pages/data/MASKSH/consts';
import { createLocalDateDDMMYYYY } from 'lib/create-date';
import { usePeriodsDict } from 'features/PeriodsButtons/usePeriodsDict';
import subsystemIdsMap from 'constants/subsystemIdsMap';
import { useAvailability } from 'hooks/useAvailability';
import { PropActions } from 'features/material-table/components/Actions';
import { addFiltersToColumns } from 'lib/utils/tableFilter';
import { TableFilterTypeEnum } from 'components/table/filters/TableFilter';
import { useDictionary } from 'hooks/useDictionary';
import { DEFAULT_PAGE_SIZE_NEW_TABLE } from '../../../../constants/tables';
import { FullPageWrapper } from '../../../../components/table/FullPageWrapper';
import { DefaultTableCellSize } from 'components/table/DefaultTableCellSize';

export const Instructions: React.FC = () => {
  useUpdateBreadcrumbs([
    { breadcrumb: appLocale.common.data, path: '/data' },
    {
      breadcrumb: appLocale.subsystems.masksh,
      path: MASKSHRoot,
    },
    {
      breadcrumb: 'Поручения',
      path: MASKSHInstructions,
    },
  ]);
  const { push } = useHistory();
  const [removeItemId, setRemoveItemId] = useState('');
  const { open, ...filePreviewData } = useFilePreviewModalWrapperData();
  const { dictionaries } = useDictionary({ url: MASKSHApiInstructionsDictionaries });
  const { isNoRights, isExecute, isWrite, allAvailable } = useAvailability(subsystemIdsMap.masksh);

  const {
    refetch,
    isLoading,
    response,
    setFilters,
    toggleOrder,
    onPageChange,
    filterDTO,
    downloadAsFile,
    onSearchText,
    saveFilters,
    errorText,
    selectedIndexes,
    renderOnlySelectedCheckbox,
    onSubmitFilters,
    onSelectionChange,
    materialTableProps,
  } = useList<IInstructionRecord, keyof IInstructionRecord>(MASKSHApiInstructionsList, {
    initialOrderFieldKey: '-instructionDate',
    exportSubsystemName: 'masksh_instructions',
    preventFirstFetch: true,
    selectionKey: 'id',
    saveFiltersState: true,
    defaultPageSize: DEFAULT_PAGE_SIZE_NEW_TABLE,
    resetDataOnNextRequest: true,
  });

  const columns = useMemo<Array<Column<IInstructionRecord>>>(
    () => [
      {
        title: fieldLabel.instructionDate,
        field: 'instructionDate',
        render: (row) => createLocalDateDDMMYYYY(row.instructionDate),
      },
      {
        title: fieldLabel.number,
        field: 'number',
        filterType: TableFilterTypeEnum.string,
      },
      {
        title: fieldLabel.description,
        field: 'description',
        render: (row) => <TruncatedStringPopoverCell value={row.description} />,
        filterType: TableFilterTypeEnum.string,
      },
      {
        title: fieldLabel.name,
        field: 'name',
        filterType: TableFilterTypeEnum.string,
        render: (row) => <DefaultTableCellSize>{row.name}</DefaultTableCellSize>,
      },
      {
        title: fieldLabel.regionId,
        field: 'region',
        filterType: TableFilterTypeEnum.string,
      },
      {
        title: fieldLabel.districtId,
        field: 'district',
        filterType: TableFilterTypeEnum.string,
      },
      {
        title: fieldLabel.address,
        field: 'address',
        filterType: TableFilterTypeEnum.string,
        render: (row) => <DefaultTableCellSize>{row.address}</DefaultTableCellSize>,
      },
      {
        title: fieldLabel.noiseSource,
        field: 'noiseSourceType',
        filterType: TableFilterTypeEnum.string,
      },
      {
        title: fieldLabel.stationId,
        field: 'stationName',
        filterType: TableFilterTypeEnum.string,
      },
      {
        title: fieldLabel.latitude,
        field: 'latitude',
        filterType: TableFilterTypeEnum.number,
      },
      {
        title: fieldLabel.longitude,
        field: 'longitude',
        filterType: TableFilterTypeEnum.number,
      },
      {
        title: fieldLabel.placementAddress,
        field: 'stationAddress',
        filterType: TableFilterTypeEnum.string,
        render: (row) => <DefaultTableCellSize>{row.stationAddress}</DefaultTableCellSize>,
      },
      {
        title: fieldLabel.startDate,
        field: 'startDate',
        render: (row) => <DateTimeTableCell inline datestring={row.startDate} />,
      },
      {
        title: fieldLabel.stopDate,
        field: 'stopDate',
        render: (row) => <DateTimeTableCell inline datestring={row.stopDate} />,
      },
      {
        title: fieldLabel.territoryType,
        field: 'territoryType',
        filterType: TableFilterTypeEnum.string,
      },
    ],
    [],
  );

  const columnsWithFilters = useMemo<Array<Column<IInstructionRecord>>>(
    () => addFiltersToColumns({ filters: filterDTO.filters, setFilters, columns }),
    [filterDTO.filters, setFilters, columns],
  );

  const deleteItemDisclosure = useDisclosure();
  const onRowDelete = useCallback(
    (_, rowData: IInstructionRecord | IInstructionRecord[]) => {
      if (rowData && !Array.isArray(rowData)) {
        setRemoveItemId(rowData.id);
        deleteItemDisclosure.onOpen();
      }
    },
    [deleteItemDisclosure],
  );

  const { result: periods } = usePeriodsDict(`auth/v10/user/period/${subsystemIdsMap.masksh}`);
  const isAvailable = allAvailable || isNoRights || isWrite;

  const initialActions = useMemo<PropActions<IInstructionRecord[]>>(
    () => [
      {
        type: 'delete',
        onClick: onRowDelete,
      },
      {
        type: 'edit',
        onClick: (e, item: any) => {
          push(`${MASKSHInstructions}/edit/${item.id}`, item);
        },
      },
      {
        type: 'view',
        onClick: (_e, item: any) => {
          push(`${MASKSHInstructions}/view/${item.id}`, item);
        },
      },
      {
        position: 'row',
        action: (row: any) => ({
          icon: () => <ImageIcon />,
          onClick: () => open(row.documentId, row.objectType),
          tooltip: 'Показать изображения',
          hidden: !row.objectType,
        }),
      },
    ],
    [onRowDelete, open, push],
  );

  // @ts-ignore
  const actions = useMemo<PropActions<any[]>>(() => {
    if (allAvailable || isNoRights || isWrite) {
      return initialActions;
    }
    if (!(allAvailable || isNoRights || isWrite)) {
      return initialActions.filter((action: any) => action.type !== 'delete' && action.type !== 'edit');
    }
  }, [isWrite, initialActions, isNoRights, allAvailable]);

  return (
    <FullPageWrapper>
      <SubsystemHeader
        title={tableLocale.title}
        addButtonProps={{
          onClick: () => push(MASKSHInstructionsAdd),
          isDisabled: !isAvailable,
        }}
        addButtonText={tableLocale.instructions.add}
        filtersComponent={
          <InstructionsFilters
            filters={filterDTO?.filters}
            periods={periods}
            dictionaries={dictionaries}
            onSubmit={(values) => {
              onSubmitFilters(Object.values(values));
            }}
          >
            {renderOnlySelectedCheckbox}
          </InstructionsFilters>
        }
      />

      <MaterialTable
        isNotExecute={isExecute || isNoRights}
        saveFilters={saveFilters}
        columns={columnsWithFilters}
        data={response?.data}
        meta={response?.meta}
        filterDTO={filterDTO}
        toggleOrder={toggleOrder}
        onPageChange={onPageChange}
        actions={actions}
        options={{
          search: true,
        }}
        isLoading={isLoading}
        onSearch={onSearchText}
        downloadAsFile={downloadAsFile}
        errorText={errorText}
        selectedIndexes={selectedIndexes}
        onSelectionChange={onSelectionChange}
        {...materialTableProps}
      />

      <DeleteRowModal
        {...deleteItemDisclosure}
        id={removeItemId}
        url={`${MASKSHApiInstruction}/${removeItemId}`}
        refetch={refetch}
      />

      <FilePreviewModalWrapper {...filePreviewData} />
    </FullPageWrapper>
  );
};
