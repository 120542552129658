import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { MaterialTable } from 'features/material-table/MaterialTableMini';
import { useList } from 'hooks/useList';
import FilePreviewModalWrapper from 'components/common/FilePreviewModal/FilePreviewModalWrapper';
import useFilePreviewModalWrapperData from 'components/common/FilePreviewModal/hooks/useFilePreviewModalWrapperData';
import { useBreadcrumbsWaterASKZV, useMeasurementsTableColumns } from '../hooks';
import { IASKZVPackage } from '../types';
import { apiPathAskzv } from '../apiPath';
import { parametersIds } from '../constants';
import { WaterASKZVFilters } from 'pages/data/Water/ASKZV/WaterASKZVFilters';
import useCalculations from 'hooks/useCalculations';
import { apiPathsWaterRoot } from 'pages/data/Water/apiPath';
import { operations } from 'lib/calculations/operations';
import { Column } from '@material-table/core';
import { addFiltersToColumns } from 'lib/utils/tableFilter';
import { usePeriodsDict } from 'features/PeriodsButtons/usePeriodsDict';
import subsystemIdsMap from 'constants/subsystemIdsMap';
import { Button } from '@chakra-ui/react';
import { useGraphFilter } from 'hooks/useGraphFilter';
import { useDefaultFilters } from 'components/common';
import { WaterASKZVHeader } from '../WaterASKZVHeader';
import { TableTabs } from 'pages/data/MASKSH/Data/components/TableTabs';
import {
  WaterRouteAskzvAddMeasurements,
  WaterRouteAskzvMeasurments,
  WaterRouteAskzvPackages,
} from 'pages/data/Water/routes';
import { useDictionary } from 'hooks/useDictionary';
import { getSelectOptionsFromDictionaryByName } from 'features/get-select-options-from-dictionary';
import { createUTCTime } from 'lib/create-date';
import { useAvailability } from 'hooks';
import { DEFAULT_PAGE_SIZE_NEW_TABLE } from '../../../../../constants/tables';
import { FullPageWrapper } from 'components/table/FullPageWrapper';

export const ASKZVMeasurement = () => {
  useBreadcrumbsWaterASKZV();

  const { isWrite, isExecute } = useAvailability(subsystemIdsMap.waterAskzv);

  const { dictionaries, isDictionariesLoading } = useDictionary({ url: apiPathAskzv.dict });
  const sources = useMemo(() => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'sources'), [dictionaries]);
  const fire = useMemo(() => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'fire'), [dictionaries]);
  const door = useMemo(() => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'door'), [dictionaries]);
  const event = useMemo(() => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'event'), [dictionaries]);
  const parameters = useMemo(
    () => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'parameters'),
    [dictionaries],
  );

  const selectionKey: keyof IASKZVPackage = 'id';

  const {
    isLoading: isItemsLoading,
    response,
    toggleOrder,
    refetch,
    onPageChange,
    filterDTO,
    downloadAsFile,
    onSearchText,
    saveFilters,
    errorText,
    onSubmitFilters,
    selectedIndexes,
    renderOnlySelectedCheckbox,
    onSelectionChange,
    setFilters,
    materialTableProps,
    selectedIds,
  } = useList<IASKZVPackage, keyof IASKZVPackage>(apiPathAskzv.measurements, {
    initialOrderFieldKey: 'measuringDatetime',
    exportSubsystemName: 'askzv',
    preventFirstFetch: true,
    selectionKey,
    saveFiltersState: true,
    defaultPageSize: DEFAULT_PAGE_SIZE_NEW_TABLE,
    resetDataOnNextRequest: true,
  });

  const columns = useMeasurementsTableColumns();

  const columnsWithFilters = useMemo<Array<Column<IASKZVPackage>>>(
    () => addFiltersToColumns({ filters: filterDTO.filters, setFilters, columns }),
    [columns, filterDTO.filters, setFilters],
  );

  // calculations
  const { calculatePreview, isCalculationLoading, renderDriftPreview, renderPreview } = useCalculations({
    previewPath: `${apiPathsWaterRoot}/water/askzv/values`,
    apiPath: `${apiPathsWaterRoot}/water/askzv`,
    driftPath: `${apiPathsWaterRoot}/water/askzv/values`,
    columns,
    refetch,
  });

  // data processing
  const onSubmitDataProcessing = useCallback(
    (values) => {
      void calculatePreview(
        {
          value: values.value,
          operation: values.operationId,
          criteria: filterDTO,
        },
        {
          ...values,
          ids: selectedIds || [],
        },
      );
    },
    [calculatePreview, filterDTO, selectedIds],
  );

  const parametersKeys = useMemo(() => {
    const dateFrom =
      (filterDTO?.filters.find((item) => item.key === 'measuringDatetime' && item.operation === 'GTE')
        ?.value as string) || '';
    const dateTo =
      (filterDTO?.filters.find((item) => item.key === 'measuringDatetime' && item.operation === 'LTE')
        ?.value as string) || '';
    return {
      sourceId: (filterDTO?.filters.find((item) => item.key === 'sourceId')?.value as string) || null,
      dateFrom: createUTCTime(dateFrom),
      dateTo: createUTCTime(dateTo),
    };
  }, [filterDTO.filters]);

  const dataProcessing = useMemo(() => {
    const calculationFields = [{ value: 'value', name: 'Значение' }];

    return {
      operands: calculationFields,
      operations,
      onSubmit: onSubmitDataProcessing,
    };
  }, [onSubmitDataProcessing]);

  const { open, ...filePreviewData } = useFilePreviewModalWrapperData();

  const [enhancedData, setEnhancedData] = useState<any[]>();
  const responseData = response?.data;

  useEffect(() => {
    if (responseData && event && fire && door) {
      const newData = responseData.map((dataRow) => {
        if (dataRow.parameterId === parametersIds.doorId) {
          const foundItem = door.find((dict) => dict.value === dataRow.value);
          if (foundItem) {
            dataRow.value = foundItem.name;
          }
        }
        if (dataRow.parameterId === parametersIds.fireId) {
          const foundItem = fire.find((dict) => dict.value === dataRow.value);
          if (foundItem) {
            dataRow.value = foundItem.name;
          }
        }
        if (dataRow.parameterId === parametersIds.eventId) {
          const foundItem = event.find((dict) => dict.value === dataRow.value);
          if (foundItem) {
            dataRow.value = foundItem.name;
          }
        }

        return dataRow;
      });
      setEnhancedData(newData);
    } else {
      setEnhancedData(undefined);
    }
  }, [door, event, fire, responseData]);

  const graphFilters = useDefaultFilters({
    filters: filterDTO.filters,
    dateKey: 'measuringDatetime',
    paramId: 'parameterId',
    stationId: 'sourceId',
    initialData: 'initialData',
  });

  const {
    GraphFilter,
    graphDisclosure,
    graphOnOpen,
    filtersFromGraphic,
    setFiltersFromGraphic,
    isGraphLoading,
    setGraphInitialFilters,
  } = useGraphFilter<keyof IASKZVPackage>({
    filters: filterDTO.filters,
    setFilters: onSubmitFilters,
    graphFilters,
    selectionKey,
    selectedKey: 'valueIds',
  });

  const isLoading = useMemo(
    () => isCalculationLoading || isItemsLoading || isDictionariesLoading || isGraphLoading,
    [isCalculationLoading, isDictionariesLoading, isGraphLoading, isItemsLoading],
  );

  const { result: periods } = usePeriodsDict(`auth/v10/user/period/${subsystemIdsMap.waterAskzv}`);

  return (
    <FullPageWrapper>
      <WaterASKZVHeader
        addPath={WaterRouteAskzvAddMeasurements}
        refetch={refetch}
        navigationTabs={
          <TableTabs
            tabs={[
              {
                name: 'Значения измерений',
                path: WaterRouteAskzvMeasurments,
              },
              {
                name: 'Пакеты',
                path: WaterRouteAskzvPackages,
              },
            ]}
          />
        }
        isWrite={isWrite}
        filtersComponent={
          <WaterASKZVFilters
            filters={filterDTO?.filters || []}
            periods={periods}
            parameters={parameters}
            isPackagesView={false}
            sources={sources}
            isLoading={isLoading}
            isDisabled={isLoading}
            onSubmit={(values) => onSubmitFilters(Object.values(values))}
            filtersFromGraphic={filtersFromGraphic}
            setFiltersFromGraphic={setFiltersFromGraphic}
            setGraphInitialFilters={setGraphInitialFilters}
          >
            {renderOnlySelectedCheckbox}
          </WaterASKZVFilters>
        }
      />

      {GraphFilter}

      {!graphDisclosure.isOpen && (
        <MaterialTable
          saveFilters={saveFilters}
          filterDTO={filterDTO}
          data={enhancedData}
          meta={response?.meta}
          columns={columnsWithFilters}
          toggleOrder={toggleOrder}
          onPageChange={onPageChange}
          isLoading={isLoading}
          options={{
            search: true,
          }}
          onSearch={onSearchText}
          dataProcessing={dataProcessing}
          downloadAsFile={downloadAsFile}
          selectedIndexes={selectedIndexes}
          onSelectionChange={onSelectionChange}
          topBarLeftContent={
            <Button variant="solid" size="sm" colorScheme="PrimaryButton" onClick={graphOnOpen}>
              Открыть график
            </Button>
          }
          errorText={errorText}
          processingParamsUrl="/water/v10/water/askzv/dictionaries/dependent-parameters"
          parametersKeys={parametersKeys}
          isNotExecute={isExecute}
          isWrite={isWrite}
          {...materialTableProps}
          showTotalCount
        />
      )}

      {renderDriftPreview}

      {renderPreview}

      <FilePreviewModalWrapper {...filePreviewData} />
    </FullPageWrapper>
  );
};
