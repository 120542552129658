import { RenderTree } from 'features/WithSideBar/types';
import appLocale from 'constants/appLocale';
import subsystemIdsMap from 'constants/subsystemIdsMap';
import { Subsoil } from '../../home/icons';

export const subsoilMenu: RenderTree = {
  id: '/data/subsoil',
  name: appLocale.subsystems.subsoil,
  url: '/data/subsoil',
  subsystemId: subsystemIdsMap.subsoilRoot,
  homepageIcon: <Subsoil />,
};
