import { Button, Checkbox, FormWrapper, Input, LoaderItem } from 'components/common';
import { Box, Flex, Grid } from '@chakra-ui/react';
import { Field, useFormikContext } from 'formik';
import { useNotifications } from 'hooks';
import React from 'react';
import { PasswordComplexitySettingsItem } from '../models/PasswordComplexitySettingsItem';

export interface FieldFormProps {
  onCancel: () => void;
  isLoading: boolean | undefined;
}

const FieldsForm: React.FC<FieldFormProps> = ({ onCancel, isLoading }) => {
  const {
    values,
    errors,
    handleSubmit,
    isValid,
  } = useFormikContext<PasswordComplexitySettingsItem>();
  const { errorNotify } = useNotifications();

  if (isLoading) {
    return <LoaderItem />;
  }
  return (
    <Box flexDirection="column">
      <FormWrapper>
        <Grid marginTop={5} marginBottom={5} gap={2}>
          <Field
            as={Checkbox}
            label="Использовать политику задания сложности пароля"
            name="enabledComplexityCheck"
            checked={values.enabledComplexityCheck}
            error={errors?.enabledComplexityCheck}
          />
          {values.enabledComplexityCheck && (
            <div>
              <Field
                as={Checkbox}
                label="Запретить символы-разделители"
                name="checkWhiteSpaces"
                checked={values.checkWhiteSpaces}
                error={errors?.checkWhiteSpaces}
              />
              <Field
                as={Checkbox}
                label="Запретить использовать имя пользователя/логин"
                name="checkUserName"
                checked={values.checkUserName}
                error={errors?.checkUserName}
              />
              <Field
                as={Checkbox}
                label="Запретить использовать повторяющиеся символы"
                name="checkRepeatCharacters"
                checked={values.checkRepeatCharacters}
                error={errors?.checkRepeatCharacters}
              />
              <Field
                as={Checkbox}
                label="Запретить использовать email"
                name="checkEmail"
                checked={values.checkEmail}
                error={errors?.checkEmail}
              />
              <Field
                as={Input}
                type="number"
                label="Минимальная длина пароля"
                name="minLength"
                value={values.minLength}
                error={errors?.minLength}
              />
              <Field
                as={Input}
                type="number"
                label="Максимальная длина пароля"
                name="maxLength"
                value={values.maxLength}
                error={errors?.maxLength}
              />
              <Field
                as={Input}
                type="text"
                label="Регулярное выражение, для недопустимых паролей"
                name="illegalRegex"
                value={values.illegalRegex}
                error={errors?.illegalRegex}
              />
              <Field
                as={Input}
                type="text"
                label="Регулярное выражение, задающее допустимые пароли"
                name="allowedRegexp"
                value={values.allowedRegexp}
                error={errors?.allowedRegexp}
              />
              <Field
                as={Input}
                type="text"
                label="Допустимые символы в пароле"
                name="legalCharacters"
                value={values.legalCharacters}
                error={errors?.legalCharacters}
              />
              <Field
                as={Input}
                type="text"
                label="Недопустимые символы в пароле"
                name="illegalCharacters"
                value={values.illegalCharacters}
                error={errors?.illegalCharacters}
              />
            </div>
          )}
          <Flex>
            <Button
              onClick={() => {
                if (!isValid) {
                  errorNotify({
                    key: 'password-complexity-setting-save-error',
                    message: 'Пожалуйста, проверьте правильность введенных данных',
                  });
                }
                handleSubmit();
              }}
              variant="solid"
              margin={3}
            >
              Сохранить
            </Button>
            <Button onClick={onCancel} margin={3} paddingLeft={6} paddingRight={6}>
              Отмена
            </Button>
          </Flex>
        </Grid>
      </FormWrapper>
    </Box>
  );
};

export default React.memo(FieldsForm);
