import { ComponentType, PropsWithChildren } from 'react';
import { Redirect } from 'react-router-dom';
import { useUploadFileProvider } from '.';

function withCheckExistPreview(Component: ComponentType, path: string) {
  return (props: PropsWithChildren<any>) => {
    const { preview } = useUploadFileProvider();

    if (preview?.result?.measures.length) {
      return <Component {...props} />;
    }

    return <Redirect to={path} />;
  };
}

export { withCheckExistPreview };
