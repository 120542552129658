import React, { useState } from 'react';
import { AttachmentsTabContent, Button, DateInput, Input, Modal, Select, Textarea } from 'components/common';
import { Flex, Grid, GridItem } from '@chakra-ui/react';
import { Field, FormikProvider, useFormik } from 'formik';
import { DictOptions } from '../../../consts/types';
import { AnswerValues, AppealValues, Complaint } from 'pages/citizenAppeal';
import { createDateFromResponse, createLocalDateISO8601 } from 'lib/create-date';
import { createTouchedErrors } from 'features/create-touched-errors';
import { useDisclosure } from 'hooks';
import { useRequest } from 'hooks/useRequest';
import { ResponseAnswer } from './ResponseAnswer';
import { useNotifications } from 'hooks/useNotifications';
import { PrepareAnswer } from 'models/schemas';
import { blobToDocFile } from 'lib/utils/files/blobToFile';
import { ExtensionToMimeTypeEnum } from 'lib/utils/files/getFileExtensionByMimeType';
import { DownloadIcon } from 'components/icons';
import { IUploadMedia } from 'components/common/FileUploadDragAndDrop/types';
import { UploadObjectType } from 'api/services/media';
import { UploadFileType } from 'components/common/FileUploadDragAndDrop/FileUploadDragAndDrop';

interface Props {
  setFieldValue: (name: string, value: any) => void;
  values: AnswerValues;
  index: number;
  assignmentKinds: DictOptions[];
  appealValues: AppealValues;
  complaint: Complaint | null;
  incident: Complaint | null;
  errors: any;
  onMediaChange: (item: IUploadMedia[]) => void;
  media?: IUploadMedia[];
  uploadItemId?: string;
  isWrite?: boolean
}

export const AnswersForm = ({
  setFieldValue,
  values,
  index,
  assignmentKinds,
  complaint,
  incident,
  appealValues,
  errors,
  onMediaChange,
  media = [],
  uploadItemId,
  isWrite
}: Props) => {
  const [currentMedia, setCurrentMedia] = useState<IUploadMedia[]>(media);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isAttachmentsOpen, onOpen: onAttachemtOpen, onClose: onAttachmenClose } = useDisclosure();
  const { successNotify, errorNotify } = useNotifications();

  const onSuccess = (response: any) => {
    successNotify({
      key: 'file/add/success',
      message: 'Документ - ответ успешно подготовлен (см. вложения)',
    });
    onClose();

    const file = blobToDocFile(response, `Ответ ${values.answers[index].answerDocNumber}.docx`);
    const uploadedFile: IUploadMedia = {
      file,
      onSaveMediaAction: 'upload'
    };
    const updatedMedia = [
      ...currentMedia,
      uploadedFile
    ];
    setCurrentMedia(updatedMedia);
    onMediaChange(updatedMedia);
  };

  const onError = () => {
    errorNotify({
      key: 'file/add/error',
      message: 'Ошибка при подготовке ответа',
    });
  };

  const { post, isLoading } = useRequest('appeal/v10/appeals/answers/prepared', onSuccess, onError);

  const prepareResponseValues = {
    assignmentExecutor: incident?.assignmentExecutor,
    complaintCitizenEmail: complaint?.complaintCitizenEmail,
    complaintCitizenFio: complaint?.complaintCitizenFio,
    complaintDate: complaint?.complaintDate,
    complaintId: complaint?.complaintId,
    complaintNumber: complaint?.complaintNumber,
    complaintOriginId: appealValues.complaintOriginId,
    complaintText: '',
    templateAnswer: values.answers[index].answerText,
    employeeFio: '',
    employeePosition: '',
    templateQuestion: '',
    templateType: 'WITH_HEADER',
    assigmentExecutorPhone: ''
  };

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    validationSchema: PrepareAnswer,
    initialValues: prepareResponseValues,
    onSubmit: (values: any) => {
      post(values, {
        headers: {
          ACCEPT: ExtensionToMimeTypeEnum.docx
        },
        responseType: 'blob'
      });
      setFieldValue(`answers[${index}].answerText`, formik.values.templateAnswer);
    },
  });
  return (
    <>
      <Grid marginLeft={2} gap={3} templateRows="repeat(4, 1fr)" templateColumns="repeat(4, 1fr)">
        <GridItem rowStart={1} rowEnd={1} colStart={1} colEnd={3}>
          <Field
            as={Select}
            label="Вид поручения"
            options={assignmentKinds}
            name={`answers[${index}].answerAssignmentKind`}
            error={errors.answers?.[index]?.answerAssignmentKind}
            isDisabled={!isWrite}
          />
        </GridItem>
        <GridItem rowStart={1} rowEnd={1} colStart={3} colSpan={2}>
          <Field
            as={Input}
            label="В адрес"
            name={`answers[${index}].answerAddress`}
            error={errors.answers?.[index]?.answerAddress}
            isDisabled={!isWrite}
          />
        </GridItem>
        <GridItem rowStart={2} rowEnd={2} colStart={1} colEnd={3}>
          <Field
            as={Input}
            label="Согл. №"
            name={`answers[${index}].answerAgreementSet`}
            error={errors.answers?.[index]?.answerAgreementSet}
            isDisabled={!isWrite}
          />
        </GridItem>
        <GridItem rowStart={2} rowEnd={2} colStart={3} colSpan={2}>
          <Field
            as={DateInput}
            name={`answers[${index}].answerAgreementDate`}
            label="Дата согла"
            value={createDateFromResponse(values.answers[index].answerAgreementDate)}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFieldValue(`answers[${index}].answerAgreementDate`, createLocalDateISO8601(e.target.value));
            }}
            error={errors.answers?.[index]?.answerAgreementDate}
            isDisabled={!isWrite}
          />
        </GridItem>
        <GridItem rowStart={3} rowEnd={3} colStart={1} colEnd={3}>
          <Field
            as={Input}
            label="Реквизиты письма: №"
            name={`answers[${index}].answerDocNumber`}
            error={errors.answers?.[index]?.answerDocNumber}
            isDisabled={!isWrite}
          />
        </GridItem>
        <GridItem rowStart={3} rowEnd={3} colStart={3} colSpan={2}>
          <Field
            as={DateInput}
            name={`answers[${index}].answerDocDate`}
            label="Реквизиты письма: Дата"
            value={createDateFromResponse(values.answers[index].answerDocDate)}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFieldValue(`answers[${index}].answerDocDate`, createLocalDateISO8601(e.target.value));
            }}
            error={errors.answers?.[index]?.answerDocDate}
            isDisabled={!isWrite}
          />
        </GridItem>
        {/* <GridItem rowStart={4} rowEnd={4} colStart={1} colSpan={2}>
          <Field
            as={Input}
            name={`answers[${index}].answerUrl`}
            label="Ссылка на ответ"
            error={errors.answers?.[index]?.answerUrl}
          />
        </GridItem>
        <GridItem rowStart={4} rowEnd={4} colStart={3} colSpan={1}>
          <Button onClick={() => {
            if (values.answers[index].answerUrl) {
              window.open(values.answers[index].answerUrl, '_blank');
            }
          }}
          >
            Перейти
          </Button>
        </GridItem> */}
        <GridItem rowStart={4} rowEnd={4} colStart={1} colSpan={4}>
          <Field
            as={Textarea}
            placeholder="Содержание ответа"
            name={`answers[${index}].answerText`}
            error={errors.answers?.[index]?.answerText}
            isDisabled={!isWrite}
          />
        </GridItem>
        <GridItem rowStart={5} rowEnd={5} colStart={1} colSpan={1}>
          <Flex alignItems="center">
            <Button marginLeft={3} color="PrimaryButton.500" variant="ghost" onClick={onOpen} isDisabled={!isWrite}>Подготовить ответ</Button>
            <Button
              leftIcon={<DownloadIcon />}
              variant="link"
              onClick={() => onAttachemtOpen()}
            >
              Вложения
            </Button>
          </Flex>
        </GridItem>
        {/* <GridItem rowStart={5} rowEnd={5} colStart={4} colSpan={1}>
          <Button onClick={() => onAttachemtOpen()}>Вложения</Button>
        </GridItem> */}
      </Grid>
      <Modal
        isOpen={isAttachmentsOpen}
        onClose={onAttachmenClose}
      >
        <AttachmentsTabContent
          objectType={UploadObjectType.ANSWER}
          onMediaChange={setCurrentMedia}
          media={currentMedia}
          uploadItemId={uploadItemId}
          uploadFileTypes={[UploadFileType.doc, UploadFileType.image, UploadFileType.pdf]}
          description="Вы можете прикрепить документ-ответ:"
          isWrite={isWrite}
        >
          <Flex>
            <Button marginRight={3} onClick={() => onAttachmenClose()}>Отмена</Button>
            <Button
              onClick={() => {
                onMediaChange(currentMedia);
                onAttachmenClose();
              }}
              isDisabled={!currentMedia.length || !isWrite}
            >
              Сохранить
            </Button>
          </Flex>
        </AttachmentsTabContent>
      </Modal>
      <Modal
        width="60%"
        isOpen={isOpen}
        onClose={onClose}
      >
        <FormikProvider
          value={{
            ...formik,
            errors: createTouchedErrors(formik.errors, formik.touched),
          }}
        >
          <ResponseAnswer
            isLoading={isLoading}
            onClose={onClose}
            complaintComment={incident?.complaintComment}
            complaint={complaint}
          />
        </FormikProvider>
      </Modal>
    </>
  );
};
