import { FormWrapper, Input, Select } from 'components/common';
import React, { FC, useMemo } from 'react';
import { Field, useFormikContext } from 'formik';
import appLocale from 'constants/appLocale';
import { WPZDto, WPZParameterKeys } from 'pages/data/Water/WPZRoot/WPZ/consts/types';
import { TDictionariesArray } from 'models';
import { getSelectOptionsFromDictionaryByName } from 'features/get-select-options-from-dictionary';
import { Box, Grid } from '@chakra-ui/react';

interface Props {
  dictionaries: {
    data: TDictionariesArray;
  } | null;
  editedObject?: Partial<WPZDto>;
}

export const ParametersTab: FC<Props> = ({ children, dictionaries, editedObject }) => {
  const { values, errors } = useFormikContext<WPZDto>();

  const siteConditions = useMemo(
    () =>
      getSelectOptionsFromDictionaryByName(dictionaries?.data, 'siteConditions', false, editedObject?.siteConditionId),
    [dictionaries?.data, editedObject?.siteConditionId],
  );

  return (
    <Box flexDirection="column">
      <FormWrapper>
        <Grid marginTop="30px" gap="15px" marginBottom={20}>
          {WPZParameterKeys.map((parameterName) => (
            <Field
              key={parameterName}
              as={Input}
              label={appLocale.wpz[parameterName]}
              name={parameterName}
              value={values[parameterName]}
              error={errors[parameterName]}
            />
          ))}

          <Field
            as={Select}
            name="siteConditionId"
            options={siteConditions}
            label={appLocale.wpz.siteConditions}
            error={errors?.siteConditionId}
          />

          {children}
        </Grid>
      </FormWrapper>
    </Box>
  );
};
