import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { normalizeExistParameters } from 'features/normalizeExistParameters';
import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import { createGreenPlantsEditUrl, DeicingGreenPlantsRootPath, DeicingStatements } from 'pages/routes/PGRGreenPlants';
import appLocale from 'constants/appLocale';
import { useLawnsDictionaries } from '../useLawnsDictionaries';
import { useLawnsEditItem } from './useLawnsEditItem';
import { useLawnListItem } from '../useLawnListItem';
import { GreenPlantsForm } from '../../Form/GreenPlantsForm';
import { greenPlantsFormInitialValues, GreenPlantsFormTypes } from '../../Form/types';
import { TGreenPlantsForm } from 'pages/data/GreenPlants/Form/types';
import { updateFiles } from 'api/services/media';
import { ERROR_MESSAGES } from 'constants/messages';
import { useObjectType } from 'hooks/useObjectType';
import { IUploadMedia } from 'components/common/FileUploadDragAndDrop/types';
import { useNotifications } from 'hooks';
import { ReagentsRootPath } from '../../../../consts';
import { useGoBackWithFallback } from 'lib/utils/goBackWithFallback';

const withEditLawns = (Component: typeof GreenPlantsForm) => () => {
  const { backWithFallback } = useGoBackWithFallback();
  const type = 'lawns';
  const [media, setMedia] = useState<IUploadMedia[]>([]);
  const { errorNotify } = useNotifications();
  const { id } = useParams<{
    id: string;
  }>();
  const { replace } = useHistory();

  const defaultIndex = Object.values(GreenPlantsFormTypes)
    .indexOf(type);

  const { get: getLawn, result: lawn, isLoading: isLoadingLawn } = useLawnListItem(id);

  useEffect(() => {
    getLawn();
  }, [getLawn]);

  useUpdateBreadcrumbs([
    { breadcrumb: appLocale.common.data, path: '/data' },
    {
      breadcrumb: appLocale.subsystems.reagents,
      path: ReagentsRootPath,
    },
    {
      breadcrumb: 'Воздействие на зеленые насаждения',
      path: DeicingGreenPlantsRootPath,
    },
    {
      breadcrumb: 'Ведомости',
      path: DeicingStatements,
    },
    {
      breadcrumb: 'Газоны',
      path: `${DeicingStatements}/lawns`,
    },
    {
      breadcrumb: 'Редактирование результатов',
      path: createGreenPlantsEditUrl('lawns', lawn?.registryId ?? ''),
      isLoading: isLoadingLawn,
    },
  ]);

  const { editLawns, isLoading } = useLawnsEditItem();

  const { dictionaries, result, isLoading: isLoadingDicts } = useLawnsDictionaries();
  const objectType = useObjectType(result);

  const lawnParams = lawn?.parameters;
  const parameters = useMemo(
    () => normalizeExistParameters(lawnParams, dictionaries.lawnParameters, dictionaries.areaParameters),
    [dictionaries.areaParameters, dictionaries.lawnParameters, lawnParams],
  );

  const initialLawn = lawn ? { ...lawn, parameters } : greenPlantsFormInitialValues.lawns;

  const onError = useCallback(
    (message?: string | null) => {
      errorNotify({
        key: message || 'file/create/error',
        message: message || ERROR_MESSAGES.CREATE,
      });
    },
    [errorNotify],
  );

  const onSubmit = useCallback(
    async (values: Partial<TGreenPlantsForm>) => {
      await editLawns(values.lawns);

      await updateFiles(id, objectType, media)
        .then(() => {
          backWithFallback(`${DeicingStatements}/lawns`);
        })
        .catch((error: Error) => {
          onError(error.message);
          replace(`${DeicingStatements}/lawns/edit/${id}?openAttachmentsTab=true`);
        });
    },
    [editLawns, id, objectType, media, backWithFallback, onError, replace],
  );

  return (
    <Component
      header="Редактирование результатов"
      defaultIndex={defaultIndex}
      initialValues={{ ...greenPlantsFormInitialValues, lawns: initialLawn }}
      type={type}
      isSubmitting={isLoading || isLoadingLawn || isLoadingDicts}
      onSubmit={onSubmit}
      lawnsDictionaries={dictionaries}
      media={media}
      setMedia={setMedia}
      registryId={id}
      backUrl={`${DeicingStatements}/${type}`}
    />
  );
};

export { withEditLawns };
