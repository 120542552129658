import { Disclosure, IWithFileDownload, useRequest } from 'hooks';
import React, { useCallback, useMemo, useRef } from 'react';
import {
  ArrowDownIcon,
  CrossIcon,
  CsvIcon,
  DataProcessingIcon,
  DeleteIcon,
  DownloadIcon,
  ExcelIcon,
  PrintIcon,
  SelectedCheckboxIcon,
  TableIcon,
  UnselectedCheckboxIcon,
  UploadIcon,
  ViewIcon,
} from 'components/icons';
import { Button, SearchBar } from 'components/common';
import { Column } from '@material-table/core';
import { map } from 'lodash';
import { Flex, Icon, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import { ToolbarIconWrap } from './styles';
import { ExtensionToMimeTypeEnum } from 'lib/utils/files/getFileExtensionByMimeType';
import { TFilterDTO } from 'models';

export interface ToolBarAction {
  icon: React.ReactElement;
  onClick: () => void;
  label: string;
  disabled?: boolean;
}

interface ToolbarProps<Data extends any[]> {
  downloadAsFile?: IWithFileDownload;
  getHeaders?: (type: ExtensionToMimeTypeEnum, headersXLSXUrl?: string) => Promise<any>;
  headersXLSXUrl?: string;
  isDataProcessing?: boolean;
  columns: Array<Column<Data[number]>>;
  handleHiddenColumn: (column: Column<Data[number]>, next: boolean) => void;
  toggleExtendedFilters: () => void;
  showExtendedFiltersControl: boolean;
  topBarLeftContent?: React.ReactNode;
  search?: boolean;
  onSearch?: (searchString: string, searchFields: string[]) => void;
  Dropdowns?: React.ComponentType<{
    isOpen: Disclosure['isOpen'];
    onOpen: Disclosure['onOpen'];
    onClose: Disclosure['onClose'];
    onToggle: Disclosure['onToggle'];
  }>;
  children?: React.ComponentType<{
    isOpen: Disclosure['isOpen'];
    onOpen: Disclosure['onOpen'];
    onClose: Disclosure['onClose'];
    onToggle: Disclosure['onToggle'];
  }>;
  saveFilters?: () => void;
  downloadTemplate?: string[];

  handleOpenTableDataProcessing?: () => void;

  filterDTO?: TFilterDTO;
  toolbarActions?: ToolBarAction[];
  isNotExecute?: boolean;
  downloadCSV?: (type: ExtensionToMimeTypeEnum) => void;
  onOpenUploadModal?: () => void;
  removeChecked?: () => void;
  menuDisclosure: Disclosure;
  hideColumnsDisclosure: Disclosure;
  downloadFileTypeDisclosure: Disclosure;
  uploadFileTypeDisclosure: Disclosure;
  changedColumnTitle: string;
  setChangedColumnTitle: React.Dispatch<React.SetStateAction<string>>;
  isWrite?: boolean;
  printLoggerUrl?: string;
}

const ToolbarComponent = <Data extends any[]>({
  downloadAsFile,
  getHeaders,
  headersXLSXUrl,
  isDataProcessing = true,
  handleOpenTableDataProcessing,
  handleHiddenColumn,
  columns,
  Dropdowns,
  children,
  toggleExtendedFilters,
  showExtendedFiltersControl,
  topBarLeftContent,
  saveFilters,
  toolbarActions,
  search,
  onSearch,
  downloadTemplate,
  filterDTO,
  isNotExecute,
  downloadCSV,
  onOpenUploadModal,
  removeChecked,
  menuDisclosure,
  hideColumnsDisclosure,
  downloadFileTypeDisclosure,
  uploadFileTypeDisclosure,
  changedColumnTitle,
  setChangedColumnTitle,
  isWrite,
  printLoggerUrl,
}: React.PropsWithChildren<ToolbarProps<Data>>) => {
  const { isOpen: isOpenMenu, onOpen: onOpenMenu, onClose: onCloseMenu, onToggle: onToggleMenu } = menuDisclosure;
  const {
    isOpen: isOpenChangeColumns,
    onOpen: onOpenChangeColumns,
    onClose: onCloseChangeColumns,
  } = hideColumnsDisclosure;

  const {
    isOpen: isOpenDownloadFileType,
    onOpen: onOpenDownloadFileType,
    onClose: onCloseDownloadFileType,
  } = downloadFileTypeDisclosure;

  const {
    isOpen: isOpenUploadFileType,
    onOpen: onOpenUploadFileType,
    onClose: onCloseUploadFileType,
  } = uploadFileTypeDisclosure;

  const handleOpenChangeFileType = useCallback(
    (isUpload: boolean = false) => {
      onCloseMenu();
      if (isUpload) {
        onOpenUploadFileType();
      } else {
        onOpenDownloadFileType();
      }
    },
    [onCloseMenu, onOpenDownloadFileType, onOpenUploadFileType],
  );

  const handleCloseChangeFileType = useCallback(
    (type: ExtensionToMimeTypeEnum) => {
      onCloseMenu();
      onCloseDownloadFileType();
      if (downloadAsFile) {
        downloadAsFile.downloadAsFile(type);
      }
    },
    [downloadAsFile, onCloseDownloadFileType, onCloseMenu],
  );

  const handleCloseUploadFileType = async (type: ExtensionToMimeTypeEnum) => {
    if (getHeaders && onOpenUploadModal) {
      getHeaders(type, type === ExtensionToMimeTypeEnum.xlsx ? headersXLSXUrl : undefined);
      onOpenUploadModal();
    }
  };

  const handleCloseCancelChangeFileType = useCallback(
    (isUpload: boolean = false) => {
      onOpenMenu();
      if (isUpload) {
        onCloseUploadFileType();
      } else {
        onCloseDownloadFileType();
      }
    },
    [onCloseDownloadFileType, onCloseUploadFileType, onOpenMenu],
  );

  /* TOGGLE HIDE COLUMNS */
  const handleOpenChangeColumns = useCallback(() => {
    onCloseMenu();
    onCloseDownloadFileType();
    onOpenChangeColumns();
  }, [onCloseDownloadFileType, onCloseMenu, onOpenChangeColumns]);

  const handleCloseCancelChangeColumns = useCallback(() => {
    onOpenMenu();
    onCloseChangeColumns();
    setChangedColumnTitle('');
  }, [onCloseChangeColumns, onOpenMenu, setChangedColumnTitle]);

  const renderComponents = useCallback(
    (
      Component?: React.ComponentType<{
        isOpen: Disclosure['isOpen'];
        onOpen: Disclosure['onOpen'];
        onClose: Disclosure['onClose'];
        onToggle: Disclosure['onToggle'];
      }>,
    ) => {
      if (!Component) {
        return null;
      }

      const component = React.createElement(Component, {
        isOpen: isOpenMenu,
        onOpen: onOpenMenu,
        onClose: onCloseMenu,
        onToggle: onToggleMenu,
      });

      if (!React.isValidElement(Component)) {
        return null;
      }

      return component;
    },
    [isOpenMenu, onCloseMenu, onOpenMenu, onToggleMenu],
  );

  const mainMenuRef = useRef<any>();
  const changeFileRef = useRef<any>();
  const changeColumnHidden = useRef<any>();
  const sendToMailRef = useRef<any>();

  const { post: printLogger } = useRequest('');

  const handlePrint = useCallback(() => {
    if (printLoggerUrl) {
      printLogger({ ...filterDTO }, { url: printLoggerUrl });
    }
    // const root = document.querySelector('#root') as HTMLDivElement;
    window.print();
  }, [filterDTO, printLogger, printLoggerUrl]);

  const handleSearch = (searchString: string) =>
    onSearch &&
    columns &&
    onSearch(
      searchString,
      columns.map((c: any) => c?.field),
    );
  const onClearSearch = () => {
    if (onSearch) {
      onSearch('', []);
    }
  };

  const templateDownload = (name: string) => {
    const filePath = `/templates/${name}.csv`;
    const a = document.createElement('a');
    a.href = filePath;
    a.download = `${name}.csv`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const isAllColumnChecked = useMemo(() => columns.every((item) => !item.hidden), [columns]);

  return (
    <Flex backgroundColor="panel.background.default" order={1} px={1} py={1}>
      {topBarLeftContent && (
        <Flex marginRight={4} flex={1} alignItems="center">
          {topBarLeftContent}
        </Flex>
      )}

      {search && (
        <Flex flex={1} justifyContent={topBarLeftContent ? 'center' : 'flex-start'}>
          <SearchBar onClearSearch={onClearSearch} onSearch={handleSearch} filterDTO={filterDTO} />
        </Flex>
      )}

      <Flex marginLeft={4} flex={1} justifyContent="flex-end">
        {isNotExecute && (
          <>
            <Button
              height="30px"
              ref={mainMenuRef}
              onClick={onOpenMenu}
              variant="ghost"
              color="text.primary"
              rightIcon={
                <ToolbarIconWrap>
                  <ArrowDownIcon />
                </ToolbarIconWrap>
              }
            >
              Действия
            </Button>
            <Menu
              id="menuList"
              boundary="scrollParent"
              isOpen={isOpenMenu}
              onClose={() => {
                setChangedColumnTitle('');
                return onCloseMenu();
              }}
            >
              <MenuButton ref={mainMenuRef} onClick={(e) => e.stopPropagation()} />
              <MenuList zIndex={11}>
                <MenuItem onClick={handlePrint} icon={<PrintIcon />}>
                  Распечатать страницу
                </MenuItem>

                {getHeaders && (
                  <MenuItem ref={sendToMailRef} onClick={() => handleOpenChangeFileType(true)} icon={<UploadIcon />}>
                    Отправить на почту
                  </MenuItem>
                )}

                {downloadAsFile && (
                  <MenuItem ref={changeFileRef} onClick={() => handleOpenChangeFileType()} icon={<DownloadIcon />}>
                    Выгрузить
                  </MenuItem>
                )}
                {downloadTemplate &&
                  downloadTemplate.map((name, index) => (
                    <MenuItem key={index} icon={<DownloadIcon />} onClick={() => templateDownload(name)}>
                      {`Выгрузить шаблон ${name}`}
                    </MenuItem>
                  ))}
                {downloadCSV && (
                  <MenuItem icon={<CsvIcon />} onClick={() => downloadCSV('text/csv' as ExtensionToMimeTypeEnum)}>
                    Выгрузить CSV
                  </MenuItem>
                )}

                {isDataProcessing && (
                  <MenuItem onClick={handleOpenTableDataProcessing} isDisabled={!isWrite} icon={<DataProcessingIcon />}>
                    Обработать данные
                  </MenuItem>
                )}

                {columns && (
                  <MenuItem ref={changeColumnHidden} onClick={handleOpenChangeColumns} icon={<TableIcon />}>
                    Скрыть/показать столбцы
                  </MenuItem>
                )}

                {showExtendedFiltersControl && (
                  <MenuItem onClick={toggleExtendedFilters} icon={<TableIcon />}>
                    Расширенный фильтр
                  </MenuItem>
                )}

                {saveFilters && (
                  <MenuItem onClick={saveFilters} icon={<ViewIcon />}>
                    Сохранить представление
                  </MenuItem>
                )}

                {removeChecked && (
                  <MenuItem onClick={removeChecked} icon={<DeleteIcon />}>
                    Удалить выбранные
                  </MenuItem>
                )}

                {toolbarActions?.map((action, index) => (
                  <MenuItem isDisabled={action.disabled} key={index} onClick={action.onClick} icon={action.icon}>
                    {action.label}
                  </MenuItem>
                ))}

                {renderComponents(Dropdowns)}

                <MenuItem onClick={onCloseMenu} icon={<CrossIcon />}>
                  Отмена
                </MenuItem>
              </MenuList>
            </Menu>

            <Menu isOpen={isOpenDownloadFileType} onClose={onCloseDownloadFileType}>
              <MenuButton ref={changeFileRef} onClick={(e) => e.stopPropagation()} />
              <MenuList zIndex={11}>
                {downloadAsFile?.types?.includes(ExtensionToMimeTypeEnum.xlsx) && (
                  <MenuItem
                    onClick={() => handleCloseChangeFileType(ExtensionToMimeTypeEnum.xlsx)}
                    icon={<ExcelIcon />}
                  >
                    Microsoft Excel (xlsx)
                  </MenuItem>
                )}

                {downloadAsFile?.types?.includes(ExtensionToMimeTypeEnum.csv) && (
                  <MenuItem onClick={() => handleCloseChangeFileType(ExtensionToMimeTypeEnum.csv)} icon={<CsvIcon />}>
                    Текстовый (csv)
                  </MenuItem>
                )}

                {downloadAsFile?.types?.includes(ExtensionToMimeTypeEnum.pdf) && (
                  <MenuItem
                    onClick={() => handleCloseChangeFileType(ExtensionToMimeTypeEnum.pdf)}
                    icon={<Icon as={PrintIcon} />}
                  >
                    PDF
                  </MenuItem>
                )}

                <MenuItem onClick={() => handleCloseCancelChangeFileType()} icon={<CrossIcon />}>
                  Отмена
                </MenuItem>
              </MenuList>
            </Menu>

            <Menu isOpen={isOpenUploadFileType} onClose={onCloseUploadFileType}>
              <MenuButton ref={sendToMailRef} onClick={(e) => e.stopPropagation()} />
              <MenuList zIndex={11}>
                <MenuItem onClick={() => handleCloseUploadFileType(ExtensionToMimeTypeEnum.xlsx)} icon={<ExcelIcon />}>
                  Microsoft Excel (xlsx)
                </MenuItem>

                <MenuItem onClick={() => handleCloseUploadFileType(ExtensionToMimeTypeEnum.csv)} icon={<CsvIcon />}>
                  Текстовый (csv)
                </MenuItem>

                <MenuItem
                  onClick={() => handleCloseUploadFileType(ExtensionToMimeTypeEnum.pdf)}
                  icon={<Icon as={PrintIcon} />}
                >
                  PDF
                </MenuItem>

                <MenuItem onClick={() => handleCloseCancelChangeFileType(true)} icon={<CrossIcon />}>
                  Отмена
                </MenuItem>
              </MenuList>
            </Menu>
          </>
        )}
        {columns && (
          <Menu isOpen={isOpenChangeColumns} onClose={onCloseChangeColumns} closeOnSelect={false} strategy="fixed">
            <MenuButton ref={changeColumnHidden} onClick={(e) => e.stopPropagation()} />
            <MenuList overflowY="auto" maxHeight={300} zIndex={11}>
              <MenuItem
                onClick={() => {
                  if (isAllColumnChecked) {
                    columns.forEach((column) => handleHiddenColumn(column, true));
                  } else {
                    columns.forEach((column) => handleHiddenColumn(column, false));
                  }
                  setChangedColumnTitle('');
                }}
                icon={isAllColumnChecked ? <SelectedCheckboxIcon /> : <UnselectedCheckboxIcon />}
                marginBottom={5}
              >
                Выбрать все
              </MenuItem>

              {map(columns, (column, index) => (
                <MenuItem
                  key={index}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleHiddenColumn(column, !column.hidden);
                  }}
                  icon={column.hidden ? <UnselectedCheckboxIcon /> : <SelectedCheckboxIcon />}
                  autoFocus={column.title === changedColumnTitle}
                >
                  <div style={{ lineHeight: '16px' }}>{column.title}</div>
                </MenuItem>
              ))}
              <MenuItem onClick={handleCloseCancelChangeColumns} icon={<CrossIcon />}>
                Отмена
              </MenuItem>
            </MenuList>
          </Menu>
        )}
        {renderComponents(children)}
      </Flex>
    </Flex>
  );
};

const Toolbar = ToolbarComponent;

export { Toolbar };
