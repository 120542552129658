import { Button, Modal, Select } from 'components/common';
import { Flex, Heading } from '@chakra-ui/react';
import appLocale from 'constants/appLocale';
import React, { ChangeEvent, useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { SurfaceWaterLocales } from 'pages/data/Water/SurfaceWater/locales';
import { ANALYTICS_LAB_PATHS } from 'pages/analytics/consts/AnalyticsTableRoutes';

const list = [
  {
    name: SurfaceWaterLocales.title,
    value: ANALYTICS_LAB_PATHS.water.surfaceWaters.add,
  },
  {
    name: appLocale.bottomSoil.title,
    value: ANALYTICS_LAB_PATHS.water.bottomSoil.add,
  },
  {
    name: 'Мониторинг воздействия ПГР на ЗН. Смывы',
    value: ANALYTICS_LAB_PATHS.reagents.washout.add,
  },
  {
    name: 'Мониторинг воздействия ПГР на ЗН. Вегетативные части',
    value: ANALYTICS_LAB_PATHS.reagents.vegetation.add,
  },
  {
    name: 'Мониторинг воздействия ПГР на снег',
    value: ANALYTICS_LAB_PATHS.reagents.snow.add,
  },
  {
    name: 'Мониторинг воздействия ПГР на почву',
    value: ANALYTICS_LAB_PATHS.reagents.soil.add,
  },
  {
    name: 'Мониторинг загрязнения окружающей среды посредством рейдов ПЭЛ. Атмосфера',
    value: ANALYTICS_LAB_PATHS.raids.pel.add,
  },
  {
    name: 'Мониторинг загрязнения окружающей среды посредством рейдов ПЭЛ-АИ. Атмосфера',
    value: ANALYTICS_LAB_PATHS.raids.ai.add,
  },
  {
    name: 'Мониторинг уровней шума на городских территориях',
    value: ANALYTICS_LAB_PATHS.raids.pelNoise.add,
  },
];

interface Props {
  isOpen: boolean
  onClose: () => void
}

const AnalyticsSelectSubsystemModal = ({ isOpen, onClose }: Props) => {
  const [value, setValue] = useState();
  const history = useHistory();

  const onNextClick = useCallback(() => {
    if (value) {
      history.push(value);
    }
  }, [history, value]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Flex flexDirection="column">
        <Heading size="lg" mb={6}>{appLocale.analytics.add_document_heading}</Heading>

        <Select
          options={list}
          onChange={(event: ChangeEvent<any>) => setValue(event.target.value)}
          value={value}
        />

        <Flex mt={6} justifyContent="space-between">
          <Button
            onClick={onClose}
            variant="outline"
            color="PrimaryButton.500"
          >
            {appLocale.actions.cancel}
          </Button>
          <Button
            variant="solid"
            isDisabled={!value}
            onClick={onNextClick}
          >
            {appLocale.actions.next}
          </Button>
        </Flex>

      </Flex>
    </Modal>
  );
};

export default AnalyticsSelectSubsystemModal;
