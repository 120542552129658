import React, { memo, useMemo } from 'react';
import { Column } from '@material-table/core';

import { SubsystemHeader } from 'components/common';
import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import { MaterialTable } from 'features/material-table/MaterialTableMini';
import { transformFiltersArray } from 'features/Filter/transformFiltersArray';
import { createDateToRequestForFilter, createLocalDateDDMMYYYY } from 'lib/create-date';
import { useList } from 'hooks/useList';
import appLocale from 'constants/appLocale';
import {
  IProtocolForecastItem,
  TProtocolForecastFilterTypes,
  TProtocolForecastTFilterDTO,
} from 'pages/data/Meteo/ProtocolForecast/types';
import { forecastLocales } from '../Forecast/Data/consts/locale';
import { addFiltersToColumns, ColumnWithFilter } from 'lib/utils/tableFilter';
import { TableFilterTypeEnum } from 'components/table/filters/TableFilter';
import ProtocolForecastFilter from 'pages/data/Meteo/ProtocolForecast/ProtocolForecastFilter';
import VersionCell from 'components/table/VersionCell';
import useProtocolModal from 'hooks/useProtocolModal';
import subsystemIdsMap from 'constants/subsystemIdsMap';
import { useAvailability } from 'hooks';
import { restorePreviewColumns } from 'components/common/Modals/ProtocolModal';
import { DEFAULT_PAGE_SIZE_NEW_TABLE } from '../../../../constants/tables';
import { FullPageWrapper } from '../../../../components/table/FullPageWrapper';

const ProtocolForecast = memo(() => {
  const { isExecute, isNoRights, isAvailable } = useAvailability(subsystemIdsMap.forecast);

  useUpdateBreadcrumbs([
    { breadcrumb: appLocale.common.data, path: '/data' },
    {
      breadcrumb: appLocale.subsystems.meteo,
      path: '/data/meteo',
    },
    {
      breadcrumb: `${appLocale.common.dataProtocol} (Прогноз)`,
      path: '/data/meteo/forecast',
    },
  ]);

  const {
    refetch,
    isLoading,
    response,
    toggleOrder,
    setFilters,
    onPageChange,
    filterDTO,
    onSearchText,
    errorText,
    materialTableProps,
  } = useList<IProtocolForecastItem,
    TProtocolForecastFilterTypes>('/meteo/v10/forecast/list/values', {
      initialOrderFieldKey: '-valueDate',
      defaultPageSize: DEFAULT_PAGE_SIZE_NEW_TABLE,
      resetDataOnNextRequest: true,
    });

  const { openModal, protocolModal } = useProtocolModal<IProtocolForecastItem>({
    onClose: refetch,
    dateKey: 'valueDate',
    baseUrl: '/meteo/v10/forecast',
    previewColumns: restorePreviewColumns.forecast,
    secondBaseUrl: '/meteo/v10/forecast'
  });

  const columns = useMemo<Array<ColumnWithFilter<IProtocolForecastItem>>>(
    () => [
      {
        title: 'Версия',
        field: 'valueVersion',
        render: (row) => <>{isAvailable ? <VersionCell row={row} onClick={openModal} /> : row.valueVersion}</>,
        width: 50,
        filterType: TableFilterTypeEnum.number,
      },
      {
        title: 'Дата',
        field: 'valueDate',
        render: (row) => createLocalDateDDMMYYYY(row.valueDate),
      },
      {
        title: 'Дата прогноза',
        field: 'forecastDate',
        render: (row) => createLocalDateDDMMYYYY(row.forecastDate),
      },
      {
        title: forecastLocales.packageNumber,
        field: 'packageNumber',
        filterType: TableFilterTypeEnum.number,
      },
      {
        title: forecastLocales.nominalDocNumber,
        field: 'nominalDocNumber',
        filterType: TableFilterTypeEnum.string,
      },
      {
        title: 'Параметр',
        field: 'parameterName',
        filterType: TableFilterTypeEnum.string,
      },
      {
        title: 'Значение',
        field: 'value',
        filterType: TableFilterTypeEnum.string,
      },
    ],
    [openModal, isAvailable]
  );

  const columnsWithFilters = useMemo<Array<Column<IProtocolForecastItem>>>(
    () => addFiltersToColumns({ filters: filterDTO.filters, setFilters, columns }),
    [columns, filterDTO.filters, setFilters]
  );

  return (
    <FullPageWrapper>
      <SubsystemHeader
        title={`${appLocale.common.dataProtocol} (Прогноз)`}
        filtersComponent={
          <ProtocolForecastFilter
            isLoading={isLoading}
            onSubmit={({ dateFrom, dateTo, ...values }) => {
              setFilters(
                transformFiltersArray<TProtocolForecastTFilterDTO['filters']>(
                  Object.values({
                    ...values,
                    dateFrom: {
                      ...dateFrom,
                      value: createDateToRequestForFilter(String(dateFrom.value), 'start'),
                    },
                    dateTo: {
                      ...dateTo,
                      value: createDateToRequestForFilter(String(dateTo.value), 'end'),
                    },
                  })
                )
              );
            }}
          />
        }
      />

      <MaterialTable
        isNotExecute={isExecute || isNoRights}
        data={response?.data}
        meta={response?.meta}
        toggleOrder={toggleOrder}
        onPageChange={onPageChange}
        filterDTO={filterDTO}
        isLoading={isLoading}
        columns={columnsWithFilters}
        setFilters={setFilters}
        onSearch={onSearchText}
        options={{
          search: true,
        }}
        errorText={errorText}
        {...materialTableProps}
        showTotalCount
      />

      {protocolModal}
    </FullPageWrapper>
  );
});

export default ProtocolForecast;
