import moment, { DurationInputArg1, DurationInputArg2 } from 'moment';
import { TimeUnit } from 'chart.js/types/adapters';
import { IPeriodName, ISubSystem } from './types';

export const enum decimationAlgorithm {
  lttb = 'lttb',
  'min-max' = 'min-max',
  none = 'none',
}

export const enum scaleYMode {
  auto = 'auto',
  manual = 'manual',
}

export enum FilterTypes {
  'sources' = 'sources',
  'parameters' = 'parameters'
}

const second = 1000;
const minute = second * 60;
const hour = minute * 60;
const day = hour * 24;

export interface IPeriod {
  value: IPeriodName,
  name: string,
  amount: DurationInputArg1,
  // units - для формы
  units: DurationInputArg2,
  // units - для chart js
  unit: TimeUnit,
  divider: number,
  stepSize: number,
  // скрываем ли в селекте периода для графика.
  isShowInFilter: boolean,
  isShowInPeriod: boolean,
  tickCounts: number;
  getMinMaxObject: (value: number) => { min:number, max:number }
}
type IPeriods = Record<IPeriodName, IPeriod>;

export const periods:IPeriods = {
  oneMinute: {
    value: 'oneMinute',
    name: '1 минута',
    amount: 1,
    units: 'minutes',
    unit: 'minute',
    tickCounts: 60,
    divider: 1,
    stepSize: 1,
    isShowInPeriod: true,
    isShowInFilter: true,
    getMinMaxObject: (minTime: number) => {
      const min = moment(minTime).startOf('minute').valueOf();
      const max = min + 60 * minute;
      return {
        min,
        max,
      };
    },
  },
  twentyMinutes: {
    value: 'twentyMinutes',
    name: '20 минут',
    amount: 20,
    units: 'minutes',
    unit: 'minute',
    tickCounts: 72,
    divider: 2,
    stepSize: 10,
    isShowInPeriod: true,
    isShowInFilter: true,
    getMinMaxObject: (minTime: number) => {
      const min = moment(minTime).startOf('minute').valueOf();
      const max = min + (12 * hour);
      return {
        min,
        max,
      };
    },
  },
  oneHour: {
    value: 'oneHour',
    name: 'Час',
    amount: 1,
    units: 'hours',
    unit: 'minute',
    tickCounts: 48,
    divider: 2,
    stepSize: 30,
    isShowInPeriod: true,
    isShowInFilter: true,
    getMinMaxObject: (minTime: number) => {
      const min = moment(minTime).startOf('hour').valueOf();
      const max = min + day;
      return {
        min,
        max,
      };
    },
  },
  oneDay: {
    value: 'oneDay',
    name: 'День',
    amount: 1,
    units: 'days',
    unit: 'hour',
    tickCounts: 28,
    divider: 4,
    stepSize: 6,
    isShowInPeriod: true,
    isShowInFilter: true,
    getMinMaxObject: (minTime: number) => {
      const min = moment(minTime).startOf('day').valueOf();
      const max = moment(min).add(1, 'w').valueOf();
      return {
        min,
        max,
      };
    },
  },
  oneWeek: {
    value: 'oneWeek',
    name: '1 неделя',
    amount: 1,
    units: 'weeks',
    unit: 'day',
    tickCounts: 28,
    divider: 7,
    stepSize: 1,
    isShowInPeriod: true,
    isShowInFilter: true,
    getMinMaxObject: (minTime: number) => {
      const min = moment(minTime).startOf('week').valueOf();
      const max = moment(min).add(4, 'w').valueOf();
      return {
        min,
        max,
      };
    },
  },
  oneMonth: {
    value: 'oneMonth',
    name: 'Месяц',
    amount: 1,
    units: 'months',
    unit: 'month',
    tickCounts: 30,
    divider: 1,
    stepSize: 1,
    isShowInPeriod: true,
    isShowInFilter: true,
    getMinMaxObject: (minTime: number) => {
      const min = moment(minTime).startOf('month').valueOf();
      const max = moment(min).add(4, 'M').valueOf();
      return {
        min,
        max,
      };
    },
  },
  quarter: {
    value: 'quarter',
    name: 'Квартал',
    amount: 1,
    units: 'quarter',
    unit: 'month',
    tickCounts: 12,
    divider: 4,
    stepSize: 1,
    isShowInPeriod: true,
    isShowInFilter: true,
    getMinMaxObject: (minTime: number) => {
      const min = moment(minTime).startOf('quarter').valueOf();
      const max = moment(min).add(1, 'y').valueOf();
      return {
        min,
        max,
      };
    },
  },
  sixMonth: {
    value: 'sixMonth',
    name: '6 месяцев',
    amount: 6,
    units: 'months',
    unit: 'month',
    tickCounts: 12,
    divider: 4,
    stepSize: 1,
    isShowInPeriod: false,
    isShowInFilter: true,
    getMinMaxObject: (minTime: number) => {
      const min = moment(minTime).startOf('quarter').valueOf();
      const max = moment(min).add(1, 'y').valueOf();
      return {
        min,
        max,
      };
    },
  },
  oneYear: {
    value: 'oneYear',
    name: 'Год',
    amount: 1,
    units: 'years',
    unit: 'month',
    tickCounts: 12,
    divider: 1,
    stepSize: 1,
    isShowInPeriod: true,
    isShowInFilter: true,
    getMinMaxObject: (minTime: number) => {
      const min = moment(minTime).startOf('year').valueOf();
      const max = moment(min).add(3, 'y').valueOf();
      return {
        min,
        max,
      };
    },
  },
};

// перенес айди подсистем в subsystemIdsMap, templates в graphicsTemplateIdsMap
/**
 * подсистемы жестко будут прибиты гвоздями и заклеены синей изоленой на фронте
 * убрать позднее, после того, как во все системы будут встроены графики
 */
/* eslint-disable @typescript-eslint/no-unused-vars */
const subSystems: ISubSystem[] = [
  // {
  //   // пока их нет
  //   subsystemId: '4933be86-eaa2-48f4-ae54-9fb12fcec808',
  //   subsystemName: 'Водные объекты',
  //   templateId: '8f34e8e2-80d2-4cc8-9a78-9a14e7aef913',
  //   templateName: 'Данные Вода (поверхностные воды)',
  // },
  // {
  //   subsystemId: 'c0d4ed31-1b9c-4882-9110-20b629824449',
  //   subsystemName: 'Геоэкопроцессы - ОГП',
  //   templateId: '7bcb751e-83d3-48aa-8584-a05ef013c8ba',
  //   templateName: 'Данные измерений инклинометров',
  // },

  // {
  //   subsystemId: 'dd4dcdce-ddfe-40f9-8d9c-a99509c9c77c',
  //   subsystemName: 'Почвы',
  //   templateId: '5159e70e-f338-4b9a-9ff3-3c2c74cd4ca9',
  //   templateName: 'Данные о загрязнениях почв',
  // },
  {
    // пока ПВ игнорируем
    subsystemId: 'f4538a63-505f-4613-9926-39ea9e88dd63',
    subsystemName: 'Геоэкопроцессы - ПВ',
    templateId: '5b330d16-ae81-459b-ba91-9bdebd071c48',
    templateName: 'Данные качества подземных вод',
  },
  {

    subsystemId: 'f4538a63-505f-4613-9926-39ea9e88dd63',
    subsystemName: 'Геоэкопроцессы - ПВ',
    templateId: '7c2b0b1a-a76e-4eb8-a66d-b86c28b8f1d3',
    templateName: 'Данные состояния водоисточников (Родники)',
  },
  {
    subsystemId: 'f4538a63-505f-4613-9926-39ea9e88dd63',
    subsystemName: 'Геоэкопроцессы - ПВ',
    templateId: 'a5c2a00a-4092-432b-9caa-da24443afd09',
    templateName: 'Данные состояния водоисточников (Скважины)',
  },
  {
    subsystemId: 'f4538a63-505f-4613-9926-39ea9e88dd63',
    subsystemName: 'Геоэкопроцессы - ПВ',
    templateId: '2e378680-31e5-46b7-943c-d59635cef02e',
    templateName: 'Данные состояния водоисточников (Колодцы)',
  },
  // {
  //   subsystemId: 'a7d53b30-2299-4bb1-8829-6f91d2fc531e',
  //   subsystemName: 'ПГР',
  //   templateId: '7e717101-ad1c-474a-81d7-e79007cca35f',
  //   templateName: 'Данные ПГР (вегетатив)',
  // },
  // {
  //   subsystemId: 'c0d4ed31-1b9c-4882-9110-20b629824449',
  //   subsystemName: 'Геоэкопроцессы - ОГП',
  //   templateId: '8edd0530-116e-4d82-bb2c-d6712361c8b9',
  //   templateName: 'Данные измерений реперов и марок',
  // },
  // {
  //   // пока их нет
  //   subsystemId: '4933be86-eaa2-48f4-ae54-9fb12fcec808',
  //   subsystemName: 'Водные объекты',
  //   templateId: '9ad2e560-c12d-4fa5-a788-d7185fbd0fa8',
  //   templateName: 'Данные АСКЗВ',
  // },
  // {
  //   subsystemId: 'a7d53b30-2299-4bb1-8829-6f91d2fc531e',
  //   subsystemName: 'ПГР',
  //   templateId: '5b330d16-ae81-459b-ba91-9bdebd071c47',
  //   templateName: 'Данные о состоянии газонов',
  // },
  // {
  //   subsystemId: 'a7d53b30-2299-4bb1-8829-6f91d2fc531e',
  //   subsystemName: 'ПГР',
  //   templateId: '3ba24f92-64fc-4795-adaf-797dfe83c356',
  //   templateName: 'Данные ПГР (почва)',
  // },

  // {
  //   subsystemId: 'a7d53b30-2299-4bb1-8829-6f91d2fc531e',
  //   subsystemName: 'ПГР',
  //   templateId: '3d48cd90-ddab-4512-a9ed-adf3c8633c05',
  //   templateName: 'Данные ПГР (снег)',
  // },
  // {
  //   subsystemId: 'a4e55d07-e9e5-47fc-99bb-3ffab7223dac',
  //   subsystemName: 'Зеленые насаждения - ДК',
  //   templateId: '695290c8-9bef-49b5-b3b4-b12400c72e10',
  //   templateName: 'Данные о состоянии деревьев и кустарников',
  // },
  // {
  //   subsystemId: 'a7d53b30-2299-4bb1-8829-6f91d2fc531e',
  //   subsystemName: 'ПГР',
  //   templateId: 'db31bd3f-dec9-47e7-a8f1-94c31ad68bd5',
  //   templateName: 'Данные ПГР (смывы)',
  // },
  // {
  //   subsystemId: '34db26e0-e4af-47ec-9f53-4b6c9a2ce8a2',
  //   subsystemName: 'Зеленые насаждения - Г',
  //   templateId: '5b330d16-ae81-459b-ba91-9bdebd071c47',
  //   templateName: 'Данные о состоянии газонов',
  // },

  // {
  //   subsystemId: 'c0d4ed31-1b9c-4882-9110-20b629824449',
  //   subsystemName: 'Геоэкопроцессы - ОГП',
  //   templateId: '2c3d028e-a003-444a-927a-d0d5b2738468',
  //   templateName: 'Описание проявлений ОГП',
  // },

  // {
  //   subsystemId: 'e57faf23-98d8-4187-b4d1-0f479285c422',
  //   subsystemName: 'Выбросы',
  //   templateId: 'a0b39559-ad68-4e37-804f-07d54fc2752c',
  //   templateName: 'Данные промышленных выбросов',
  // },
  // {
  //   subsystemId: 'cd2b86a3-7c05-4814-9a44-8560070b2a2b',
  //   subsystemName: 'Метео - ОБЩИЙ',
  //   templateId: 'e9b46f68-2864-4c0e-a361-d9d75dba9e60',
  //   templateName: 'Данные автоматических станций контроля',
  // },
  {
    // остальное по метео пока игнорируем.
    // метео профилимеры
    subsystemId: 'cd2b86a3-7c05-4814-9a44-8560070b2a2b',
    subsystemName: 'Метео - ОБЩИЙ',
    templateId: '72993ff7-c581-4573-9966-2304a3938f1e',
    templateName: 'Данные температурных профилей',
  },
  {
    subsystemId: 'cd2b86a3-7c05-4814-9a44-8560070b2a2b',
    subsystemName: 'Метео - ОБЩИЙ',
    templateId: 'ffd9f9a3-10f3-49b6-8bab-0a08ab06c175',
    templateName: 'Данные АИС Погода: обобщенные данные',
  },
  {
    subsystemId: 'cd2b86a3-7c05-4814-9a44-8560070b2a2b',
    subsystemName: 'Метео - ОБЩИЙ',
    templateId: 'e14cb146-c099-4d12-8446-cb2c8c34c9c2',
    templateName: 'Данные АИС Погода: для высоты 0 метров',
  },
  {
    subsystemId: 'cd2b86a3-7c05-4814-9a44-8560070b2a2b',
    subsystemName: 'Метео - ОБЩИЙ',
    templateId: '8173c268-cd1f-4736-a033-6cb109ef8c93',
    templateName: 'Данные АИС Погода: для высот 85 - 503 метров',
  },
  // {
  //   subsystemId: 'a7d53b30-2299-4bb1-8829-6f91d2fc531e',
  //   subsystemName: 'ПГР',
  //   templateId: '695290c8-9bef-49b5-b3b4-b12400c72e10',
  //   templateName: 'Данные о состоянии деревьев и кустарников',
  // },

];
