import { createTouchedErrors } from 'features/create-touched-errors';
import { FormikProvider, useFormik } from 'formik';
import { useNotifications } from 'hooks/useNotifications';
import { useRequest } from 'hooks/useRequest';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { ERROR_MESSAGES, SUCCESS_MESSAGES } from 'constants/messages';
import { InclinometerSchema } from 'models/schemas/dangerprocess/danger';
import { GeoecologicalRootPath } from 'pages/data/GeoecologicalProcesses/consts';
import FieldsForm from './FieldsForm';
import React, { useCallback, useState } from 'react';
import { IUploadMedia } from 'components/common/FileUploadDragAndDrop/types';
import { updateFiles } from 'api/services/media';
import { LoaderItem } from 'components/common';
import { InclinometerItem } from 'hooks/geo/Inclinometer/useGeoInclinometerList';
import { useObjectType } from 'hooks/useObjectType';
import { formatErrorMessage } from 'lib/utils/formatErrorMessage';
import { useGoBackWithFallback } from 'lib/utils/goBackWithFallback';
import { useDictionary } from 'hooks/useDictionary';

export const initialEmptyValues = {
  data: [],
  valDateTime: moment().format(),
  areaId: '',
  pointId: '',
};

export const AddInclinometerResult = () => {
  const { backWithFallback } = useGoBackWithFallback();
  const { isDictionariesLoading, dictionaries } = useDictionary({
    url: '/geoecology/v10/geoecology/inclinometer/dictionaries',
  });
  const history = useHistory();
  const { successNotify, errorNotify } = useNotifications();
  const [media, setMedia] = useState<IUploadMedia[]>([]);
  const objectType = useObjectType(dictionaries);

  const onSuccess = (response: InclinometerItem[] | null) => {
    if (!response || !response[0].packageId) {
      onError();
      return;
    }

    successNotify({
      key: 'file/delete/success',
      message: SUCCESS_MESSAGES.CREATE,
    });

    updateFiles(response[0].packageId, objectType, media)
      .then(() => {
        backWithFallback(`${GeoecologicalRootPath}/monitoring/danger/inclinometer`);
      })
      .catch((error: Error) => {
        onError(error.message);
        history.replace(
          `${GeoecologicalRootPath}/monitoring/danger/inclinometer/edit/row/${response[0].packageId}`,
          response,
        );
      });
  };

  const onError = useCallback(
    (message?: string | null) => {
      errorNotify({
        key: 'file/create/error',
        message: formatErrorMessage(message) || ERROR_MESSAGES.CREATE,
      });
    },
    [errorNotify],
  );

  const { post, isLoading } = useRequest<InclinometerItem[], any>(
    '/geoecology/v10/geoecology/inclinometer',
    onSuccess,
    onError,
  );

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    initialValues: initialEmptyValues,
    validationSchema: InclinometerSchema,
    onSubmit: (values) => post(values),
  });

  if (isDictionariesLoading || isLoading) {
    return <LoaderItem />;
  }

  return (
    <FormikProvider
      value={{
        ...formik,
        errors: createTouchedErrors(formik.errors, formik.touched),
      }}
    >
      <FieldsForm
        dictionaries={dictionaries}
        onCancel={() => {
          backWithFallback(`${GeoecologicalRootPath}/monitoring/danger/inclinometer`);
        }}
        media={media}
        onMediaChange={setMedia}
      />
    </FormikProvider>
  );
};
