import { RenderTree } from '../features/WithSideBar/types';
import { useFilteredMenu } from './useFilteredMenu';

export const useRoutesList = (menu: RenderTree) => {
  const routes: Array<{ path: string; breadcrumb: string; isExternal?: boolean }> = [];

  const { filteredMenu } = useFilteredMenu([menu]);

  filteredMenu[0]?.children?.forEach(({ url, name }) =>
    routes.push({
      path: url || '',
      breadcrumb: name,
    }));
  return routes;
};
