import { Box, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { AttachmentsTabContent, FormWrapper, LoaderItem, TabListHeader } from 'components/common';
import React, { useCallback, useMemo } from 'react';
import { useLocation } from 'react-router';
import { COMMON_LOCALE } from 'constants/common';
import { AnalyticsDeicingVegCommonTab } from 'pages/analytics/forms/VegetativeState/AnalyticsDeicingVegCommonTab';
import { useQuery } from 'hooks/useQuery';
import { useAvailability, useEnhancedNotification } from 'hooks';
import { useFormikContext } from 'formik';
import { VegetationDeicingItem } from 'pages/data/PGR/GreenPlants/VegetativeState/useVegDicingList';
import { useObjectType } from 'hooks/useObjectType';
import appLocale from 'constants/appLocale';
import { FieldFormProps } from 'types/forms';
import subsystemIdsMap from 'constants/subsystemIdsMap';
import { ParametersTab } from 'pages/data/PGR/GreenPlants/VegetativeState/Forms/ParametersTab';
import { AnalyticsFormWithStatusHeader } from 'pages/analytics/components/AnalyticsFormWithStatusHeader';
import { getSelectOptionsFromDictionaryByName } from 'features/get-select-options-from-dictionary';
import FormControls from 'components/common/FormControls';
import { SignatoriesTab } from 'pages/analytics/components/SignatoriesTab';
import { LinksTab } from 'pages/analytics/components/LinksTab';

const AnalyticsDeicingVegFieldsForm = ({
  onCancel,
  dictionaries,
  isLoading,
  media,
  onMediaChange,
  uploadItemId,
}: FieldFormProps) => {
  const { isAvailable } = useAvailability(subsystemIdsMap.pgrRoot);

  const location = useLocation();
  const isEditMode = location.pathname.includes('/edit');
  const title = isEditMode ? COMMON_LOCALE.text.edit_protocol_result : COMMON_LOCALE.text.add_protocol_result;
  const { openAttachmentsTab } = useQuery();
  const { submitForm, errors, setFieldTouched } = useFormikContext<VegetationDeicingItem>();

  const { onValidationError } = useEnhancedNotification();
  const objectType = useObjectType(dictionaries);

  const onFormSubmit = useCallback(async () => {
    await submitForm();
    if (Object.keys(errors).length > 0) {
      onValidationError();
      Object.keys(errors).forEach((key) => setFieldTouched(key, true));
    }
  }, [errors, onValidationError, setFieldTouched, submitForm]);

  const statuses = useMemo(() => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'statuses'), [dictionaries]);

  if (isLoading) {
    return <LoaderItem />;
  }

  return (
    <Box flexDirection="column">
      <Tabs defaultIndex={openAttachmentsTab ? 2 : 0}>
        <TabListHeader
          onBackClick={onCancel}
          customHeader={<AnalyticsFormWithStatusHeader header={title} statuses={statuses} />}
          tabNames={[
            appLocale.common.generalInformation,
            appLocale.common.indicators,
            appLocale.common.links,
            appLocale.common.signatories,
            appLocale.common.attachments,
          ]}
        />
        <TabPanels>
          <TabPanel>
            <AnalyticsDeicingVegCommonTab dictionaries={dictionaries} />
          </TabPanel>
          <TabPanel>
            <ParametersTab isWrite={isAvailable} dictionaries={dictionaries} isAnalytic>
              <FormControls onCancel={onCancel} handleSubmit={onFormSubmit} submitIsDisabled={!isAvailable} />
            </ParametersTab>
          </TabPanel>

          <TabPanel>
            <LinksTab />
          </TabPanel>

          <TabPanel>
            <SignatoriesTab dictionaries={dictionaries} />
          </TabPanel>

          <TabPanel>
            <FormWrapper>
              {isAvailable && (
                <AttachmentsTabContent
                  objectType={objectType}
                  media={media}
                  onMediaChange={onMediaChange}
                  uploadItemId={uploadItemId}
                >
                  <FormControls onCancel={onCancel} handleSubmit={onFormSubmit} submitIsDisabled={!isAvailable} />
                </AttachmentsTabContent>
              )}
            </FormWrapper>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default AnalyticsDeicingVegFieldsForm;
