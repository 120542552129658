import {
  addSubstancePath,
  ISubstanceValues,
  pdkLocales,
  pdkRootPath,
  substanceListApiPath,
  substancesPath,
} from '../consts';
import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import { MaterialTable } from 'features/material-table/MaterialTableMini';
import { useHistory } from 'react-router';
import { useList } from 'hooks';
import appLocale from 'constants/appLocale';
import { SubsystemHeader } from 'components/common/SubsystemHeader';
import React, { useMemo } from 'react';
import { addFiltersToColumns, ColumnWithFilter } from 'lib/utils/tableFilter';
import { Column } from '@material-table/core';
import { TableFilterTypeEnum } from 'components/table/filters/TableFilter';
import { subsystemCodesMap } from 'constants/subsystemIdsMap';
import { NSIRootPath } from 'pages/dictionaries/const/routes';
import { useCustomRights } from 'hooks/useCustomRights';
import { FullPageWrapper } from '../../../../components/table/FullPageWrapper';
import { DEFAULT_PAGE_SIZE_NEW_TABLE } from '../../../../constants/tables';

export const Substances = () => {
  const history = useHistory();

  const { isWrite } = useCustomRights(subsystemCodesMap.nsi.pdk.substances);

  useUpdateBreadcrumbs([
    {
      breadcrumb: appLocale.mainPage.nsi,
      path: NSIRootPath,
    },
    {
      breadcrumb: pdkLocales.main,
      path: pdkRootPath,
    },
    {
      breadcrumb: pdkLocales.substances,
      path: substancesPath,
    },
  ]);

  const {
    isLoading,
    response,
    toggleOrder,
    onPageChange,
    onSearchText,
    filterDTO,
    setFilters,
    downloadAsFile,
    materialTableProps,
  } = useList<ISubstanceValues>(substanceListApiPath, {
    saveFiltersState: true,
    defaultPageSize: DEFAULT_PAGE_SIZE_NEW_TABLE,
    resetDataOnNextRequest: true,
  });

  const columns = useMemo<Array<ColumnWithFilter<ISubstanceValues>>>(
    () => [
      {
        title: pdkLocales.substancesColumns.substanceName,
        field: 'chemName',
        filterName: 'chemName',
        filterType: TableFilterTypeEnum.string,
      },
      {
        title: pdkLocales.substancesColumns.shortName,
        field: 'chemShortName',
        filterName: 'chemShortName',
        filterType: TableFilterTypeEnum.string,
      },
      {
        title: pdkLocales.substancesColumns.formule,
        field: 'chemFormula',
        filterName: 'chemFormula',
        filterType: TableFilterTypeEnum.string,
      },
      {
        title: pdkLocales.substancesColumns.dangerClass,
        field: 'dangerClass',
        filterType: TableFilterTypeEnum.string,
      },
    ],
    []
  );

  const columnsWithFilters = useMemo<Array<Column<ISubstanceValues>>>(
    () =>
      addFiltersToColumns({
        filters: filterDTO.filters,
        setFilters,
        columns,
      }),
    [filterDTO.filters, setFilters, columns]
  );

  return (
    <FullPageWrapper>
      <SubsystemHeader
        title={pdkLocales.substances}
        addButtonProps={{
          onClick: () => {
            history.push(addSubstancePath);
          },
          isDisabled: !isWrite,
        }}
      />
      <MaterialTable
        data={response?.data || []}
        meta={response?.meta}
        isLoading={isLoading}
        filterDTO={filterDTO}
        columns={columnsWithFilters}
        toggleOrder={toggleOrder}
        onPageChange={onPageChange}
        options={{
          search: true,
        }}
        onSearch={onSearchText}
        actions={[
          {
            type: 'edit',
            onClick: (_e, item) => {
              if (!Array.isArray(item)) {
                history.push(`${substancesPath}/edit/${item.id}`, item);
              }
            },
            disabled: !isWrite,
            hidden: !isWrite,
          },
        ]}
        downloadAsFile={downloadAsFile}
        showTotalCount
        {...materialTableProps}
      />
    </FullPageWrapper>
  );
};
