import { CrossIcon, ErrorLogoIcon, SuccessLogoIcon, WarningLogoIcon } from 'components/icons';
import { AlertStatus, Flex, Grid, IconButton } from '@chakra-ui/react';
import React, { memo } from 'react';

export interface NotificationProps {
  variant: AlertStatus;
  onClose?: () => void;
}

export const Notification: React.FC<NotificationProps> = memo(({ variant, onClose, children }) => (
  <Grid
    gridTemplateColumns="40px minmax(200px, 420px) 40px"
    padding="20px 30px"
    alignItems="center"
    backgroundColor="#FFFFFF"
    boxShadow="0px 1px 3px rgba(0, 0, 0, 0.3)"
    borderRadius={4}
    zIndex={1401}
  >
    <Flex>
      {variant === 'success' && <SuccessLogoIcon />}
      {variant === 'warning' && <WarningLogoIcon />}
      {variant === 'error' && <ErrorLogoIcon />}
    </Flex>
    <Flex>{children}</Flex>
    <Flex>
      <IconButton aria-label="close-snackbar" onClick={onClose} style={{ minWidth: 40, maxWidth: 40 }}>
        <CrossIcon />
      </IconButton>
    </Flex>
  </Grid>
));
