import appLocale from 'constants/appLocale';

export const PDV_ApiPath = 'industrialemissions/v10/pdv';

export const Industrial_PDV_NoSource_RootPath = '/data/industrial-emissions/no_soure_pdv';
export const Industrial_PDV_NoSource_AddPath = `${Industrial_PDV_NoSource_RootPath}/add`;
export const Industrial_PDV_NoSource_EditPath = `${Industrial_PDV_NoSource_RootPath}/edit`;

export const Industrial_PDV_NoSource_Breadcrumbs = [
  { breadcrumb: appLocale.common.data, path: '/data' },
  {
    breadcrumb: 'Мониторинг пром. выбросов',
    path: '/data/industrial-emissions',
  },
  {
    breadcrumb: 'ПДВ по предприятиям',
    path: Industrial_PDV_NoSource_RootPath,
  },
];
