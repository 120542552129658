import { Field, FormikProvider, useFormik } from 'formik';
import React, { useCallback, useMemo } from 'react';
import { DateInput, Select } from 'components/common';
import { Grid, GridItem } from '@chakra-ui/react';
import { createDateToRequestWithTimezone, createLocalDateISO8601 } from 'lib/create-date';
import { COMMON_LOCALE } from 'constants/common';
import moment from 'moment';
import { PeriodsFilters } from 'pages/data/Water/SurfaceWater/components/PeriodFilters';
import * as yup from 'yup';
import { requiredError } from 'models/schemas/constans';
import { getSelectOptionsFromDictionaryByNameWithParent } from 'features/get-select-options-from-dictionary';
import appLocale from 'constants/appLocale';
import { FiltersDatepickersSchema } from 'models/schemas/filtersDatepickersSchema/FiltersDatepickersSchema';
import FilterFormControls from 'components/form/FilterFormControls';
import { useDictionary } from 'hooks/useDictionary';
import { createTouchedErrors } from '../../../../features/create-touched-errors';

interface FilterProps {
  onSubmit: (values: any) => void;
  isStations?: boolean;
  isLoading: boolean;
}

const initialValues = {
  dateStart: {
    key: 'dateStart',
    operation: 'GT',
    value: null,
  },
  dateEnd: {
    key: 'dateEnd',
    operation: 'LT',
    value: null,
  },
  id: {
    key: 'deviceId',
    operation: 'EQ',
    value: null,
  },
};

export const EquipmentReportsDiagramFilters = ({ onSubmit, isStations, isLoading }: FilterProps) => {
  const { dictionaries, isDictionariesLoading } = useDictionary({ url: '/reporting/v10/reports/dictionaries' });
  const {
    values,
    errors,
    getFieldMeta,
    getFieldHelpers,
    isValid,
    getFieldProps,
    handleReset,
    submitForm,
    setFieldValue,
    validateForm,
    touched,
    ...formik
  } = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: true,
    initialValues,
    validationSchema: FiltersDatepickersSchema([{ dateFromFieldName: 'dateStart', dateToFieldName: 'dateEnd' }], {
      dateStart: yup.object({
        value: yup.string().nullable().required(requiredError),
      }),
      dateEnd: yup.object({
        value: yup.string().nullable().required(requiredError),
      }),
      id: yup.object({
        value: yup.string().nullable().required(requiredError),
      }),
    }),
    onSubmit,
  });
  const stations = getSelectOptionsFromDictionaryByNameWithParent(dictionaries?.data, 'stations');
  const devices = getSelectOptionsFromDictionaryByNameWithParent(dictionaries?.data, 'devices');

  const formatDate = useCallback(
    (date: string) => (isStations ? createLocalDateISO8601(date) : createDateToRequestWithTimezone(date)),
    [isStations],
  );

  const filterByPeriod = async (amount: number, dimensionValue: any) => {
    const fromDate = moment().subtract(amount, dimensionValue).format();
    const toDate = moment().format();

    await setFieldValue('dateStart.value', formatDate(fromDate));

    await setFieldValue('dateEnd.value', formatDate(toDate));

    await validateForm();
  };

  const touchedErrors = useMemo(() => createTouchedErrors(errors, touched), [errors, touched]);

  return (
    <>
      <PeriodsFilters filterByPeriod={filterByPeriod} />
      <FormikProvider
        value={{
          values,
          errors,
          getFieldMeta,
          getFieldHelpers,
          getFieldProps,
          isValid,
          handleReset,
          submitForm,
          setFieldValue,
          validateForm,
          touched,
          ...formik,
        }}
      >
        <Grid mt={5} gap={3} maxWidth="100%" templateColumns={`repeat(${isStations ? 3 : 2}, 225px) 1fr`}>
          <GridItem>
            <Field
              as={DateInput}
              name="dateStart.value"
              label={COMMON_LOCALE.from}
              value={values.dateStart.value}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setFieldValue('dateStart.value', formatDate(e.target.value));
                setTimeout(() => formik.setFieldTouched('dateStart.value'));
              }}
              error={touchedErrors.dateStart?.value}
            />
          </GridItem>
          <GridItem>
            <Field
              as={DateInput}
              name="dateEnd.value"
              label={COMMON_LOCALE.to}
              value={values.dateEnd.value}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setFieldValue('dateEnd.value', formatDate(e.target.value));
                setTimeout(() => formik.setFieldTouched('dateEnd.value'));
              }}
              error={touchedErrors.dateEnd?.value}
            />
          </GridItem>
          {isStations ? (
            <GridItem>
              <Field
                as={Select}
                label={isStations ? appLocale.equipmentPage.stations : appLocale.equipmentPage.devices}
                options={isStations ? stations : devices}
                name="id.value"
                error={touchedErrors.id?.value}
              />
            </GridItem>
          ) : null}

          <GridItem ml="auto">
            <FilterFormControls
              onReset={(e) => {
                handleReset(e);
                setTimeout(submitForm, 0);
              }}
              onSubmit={submitForm}
              isLoading={isLoading || isDictionariesLoading}
              isDisabled={isLoading || isDictionariesLoading}
            />
          </GridItem>
        </Grid>
        {!isStations ? (
          <Grid mt={5} gap={3} maxWidth="100%" templateColumns="repeat(1, 1fr)">
            <GridItem>
              <Field
                as={Select}
                label={isStations ? appLocale.equipmentPage.stations : appLocale.equipmentPage.stationsFilter}
                options={isStations ? stations : devices}
                name="id.value"
                error={touchedErrors.id?.value}
              />
            </GridItem>
          </Grid>
        ) : null}
      </FormikProvider>
    </>
  );
};
