import { memo } from 'react';
import { MeteoDataAisPogodaPreview } from './MeteoDataAisPogodaPreview/MeteoDataAisPogodaPreview';
import { MeteoDataAskzaPreview } from './MeteoDataAskzaPreview/MeteoDataAskzaPreview';
import { MeteoDataOtherOstankinoPreview } from './MeteoDataOtherOstankinoPreview/MeteoDataOtherOstankinoPreview';
import { MeteoDataPackagesPreview } from './MeteoDataPackagesPreview.tsx';
import { MeteoDataProfilimersPreview } from './MeteoDataProfilimersPreview/MeteoDataProfilimersPreview';
import { MeteoDataZeroOstankinoPreview } from './MeteoDataZeroOstankinoPreview/MeteoDataZeroOstankinoPreview';

enum MeteoDataTemplates {
  'MeteoDataAskzaPreview' = 1,
  'MeteoDataProfilimersPreview' = 2,
  'MeteoDataZeroOstankinoPreview' = 3,
  'MeteoDataOtherOstankinoPreview' = 4,
  'MeteoDataAisPogodaPreview' = 5,
  'MeteoDataPackagesPreview' = 6,
}

const getTemplate = (typeId: number) => {
  switch (typeId) {
    case MeteoDataTemplates.MeteoDataProfilimersPreview: {
      return memo(MeteoDataProfilimersPreview);
    }
    case MeteoDataTemplates.MeteoDataAskzaPreview: {
      return memo(MeteoDataAskzaPreview);
    }

    case MeteoDataTemplates.MeteoDataZeroOstankinoPreview: {
      return memo(MeteoDataZeroOstankinoPreview);
    }
    case MeteoDataTemplates.MeteoDataOtherOstankinoPreview: {
      return memo(MeteoDataOtherOstankinoPreview);
    }
    case MeteoDataTemplates.MeteoDataAisPogodaPreview: {
      return memo(MeteoDataAisPogodaPreview);
    }

    case MeteoDataTemplates.MeteoDataPackagesPreview: {
      return memo(MeteoDataPackagesPreview);
    }

    default:
      return null;
  }
};

export { getTemplate };
