import { apiPathsWaterRoot } from 'pages/data/Water/apiPath';
import { askzvListViews } from 'pages/data/Water/consts';

const waterRoot = `${apiPathsWaterRoot}/water/askzv`;

export const apiPathAskzv = {
  root: waterRoot,
  measurements: `${waterRoot}/value_list`,
  packages: `${waterRoot}/list`,
  apply: `${waterRoot}/apply`,
  preview: `${waterRoot}/preview`,
  calculations: `${waterRoot}/calculations`,
  types: `${waterRoot}/types`,
  validate: `${waterRoot}/validate`,
  list: (view: askzvListViews) => `${waterRoot}/list/${view}`,
  dict: `${waterRoot}/dictionaries`,
  station: (stationId: string) => `${waterRoot}/dictionaries/stations/${stationId}`,
};
