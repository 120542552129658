import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useLocation } from 'react-router';
import { FormikProvider, useFormik } from 'formik';
import { useNotifications } from 'hooks/useNotifications';
import { useRequest } from 'hooks/useRequest';
import { createTouchedErrors } from 'features/create-touched-errors';
import { ERROR_MESSAGES, SUCCESS_MESSAGES } from 'constants/messages';
import { InsctructionForm, MASKSHApiPackageInstruction, MASKSHInstructions } from 'pages/data/MASKSH/consts';
import { useMASKSHDictionary } from 'pages/data/MASKSH/hooks';
import { initialEmptyValues } from 'pages/data/MASKSH/Instructions/InstructionsForm/configs';
import { InstructionForm } from 'pages/data/MASKSH/Instructions/InstructionsForm/Form';
import { IUploadMedia } from 'components/common/FileUploadDragAndDrop/types';
import { useUpdateFiles } from 'api/services/media';
import { useObjectType } from 'hooks/useObjectType';
import { useInstructionsRecord } from 'pages/data/MASKSH/Instructions/InstructionsForm/useInstructionsRecord';
import { LoaderItem } from 'components/common';
import { instructionForm } from 'models/schemas/MASKSH/instructionForm';
import { ApiError } from 'models';
import { formatErrorMessage } from 'lib/utils/formatErrorMessage';
import { useGoBackWithFallback } from 'lib/utils/goBackWithFallback';

export const EditInstruction = () => {
  const { backWithFallback } = useGoBackWithFallback();
  const { isLoading, result } = useMASKSHDictionary('instruction');
  const { successNotify, errorNotify } = useNotifications();
  const location = useLocation();
  const [media, setMedia] = useState<IUploadMedia[]>([]);
  const { updateFiles, isLoading: isUpdateFilesLoading } = useUpdateFiles();
  const objectType = useObjectType(result);
  const isViewMode = location.pathname.includes('view');

  const { packageId } = useParams<{ packageId: string }>();
  const { result: editedObject, isLoading: isRecordLoading, error } = useInstructionsRecord(packageId);

  useEffect(() => {
    if (error) {
      errorNotify({
        key: 'MASKSH/get/error',
        message: ERROR_MESSAGES.GET,
      });
      backWithFallback(MASKSHInstructions);
    }
  }, [error, errorNotify, backWithFallback]);

  const onSuccess = () => {
    if (editedObject) {
      updateFiles(editedObject.documentId, objectType, media)
        .then(() => {
          successNotify({
            key: 'Instruction/add/success',
            message: SUCCESS_MESSAGES.EDIT,
          });
          backWithFallback(MASKSHInstructions);
        })
        .catch((error: ApiError) => {
          onError(error);
        });
    }
  };

  const onError = useCallback(
    (error?: ApiError | null) => {
      errorNotify({
        key: formatErrorMessage(error) || 'file/delete/error',
        message: formatErrorMessage(error) || ERROR_MESSAGES.CREATE,
      });
    },
    [errorNotify]
  );

  const initialValues = useMemo(() => ({ ...initialEmptyValues, ...editedObject }), [editedObject]);

  const { put } = useRequest(MASKSHApiPackageInstruction, onSuccess, onError);

  const useFormikProps = useMemo(
    () => ({
      enableReinitialize: true,
      validateOnChange: true,
      validateOnMount: true,
      validateOnBlur: true,
      initialValues,
      validationSchema: instructionForm,
      onSubmit: (values: InsctructionForm) => {
        void put(values);
      },
    }),
    [initialValues, put]
  );

  const formik = useFormik(useFormikProps);

  return (
    <>
      {isLoading || isRecordLoading || isUpdateFilesLoading ? (
        <>
          <LoaderItem />
        </>
      ) : (
        <div>
          <FormikProvider
            value={{
              ...formik,
              errors: createTouchedErrors(formik.errors, formik.touched),
            }}
          >
            <InstructionForm
              isEditMode
              isViewMode={isViewMode}
              dictionaries={result}
              media={media}
              setMedia={setMedia}
              uploadItemId={editedObject?.documentId}
              url={location.state?.url}
            />
          </FormikProvider>
        </div>
      )}
    </>
  );
};
