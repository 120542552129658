export const requiredError = 'Должно быть указано';

export const numberError = 'Необходимо указать число';
export const integerNumberError = 'Необходимо указать целое число';
export const numberWithSignError = 'Значение может содержать только знаки "<", ">" и/или число';
export const numberWithTextSignError = 'Значение может содержать только знаки "<", ">", "более ", "менее ", и/или число';

export const multipleNumberError = (value: number) => `Значение должно быть кратно ${value}`;

export const atLeastOneError = 'Необходимо указать хотя бы одно значение';

export const lessThanDate = (value: string) => `Не может быть раньше ${value}`;
export const atLeastOneField = 'Необходимо заполнить одно из полей';
export const moreThanDate = (value: string) => `Не может быть позже ${value}`;
export const shouldBeMoreThan = (number: number) => `Должно быть больше ${number}`;
export const shouldBeLessThan = (number: number) => `Должно быть меньше ${number}`;
export const shouldBeMoreThanEq = (number: number) => `Должно быть больше или равно ${number}`;
export const shouldBeLessThanEq = (number: number) => `Должно быть меньше или равно ${number}`;
export const incidentTypeError = 'Необходимо указать тему обращения';
export const urlError = 'Некорректный URL';
export const maxSymbols = (value: number) => `Превышение допустимого количества символов (${value})`;

export const acceptableRange = (from: number, to: number) => `Должно быть от ${from} до ${to}`;
export const sumError = (value: number) => `Сумма значений не должна превышать ${value}`;

export const uniqueError = 'Значение должно быть уникальным';

export const DateToLessThanFrom = 'Дата от не может быть больше даты до';

export const noSurroundingWhitespacesError = 'Поле не должно содержать пробелов в начале и конце';

export const stationNameValidationError = 'Допустимые символы: "АБВ", "абв", "123", " ", ".",  ",", "«»", "№", "()"';
