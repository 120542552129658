import React from 'react';
import { PageHeader, RoutesList } from 'components/common';
import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import appLocale from 'constants/appLocale';
import { ForecastRootPath } from 'pages/data/Meteo/menu';
import { Flex, Heading } from '@chakra-ui/react';

export const ForecastMenu: React.FC = () => {
  useUpdateBreadcrumbs([
    { breadcrumb: appLocale.common.data, path: '/data' },
    { breadcrumb: appLocale.subsystems.meteo, path: '/data/meteo' },
    {
      breadcrumb: 'Прогноз',
      path: '/data/meteo/forecast',
    },
  ]);
  return (
    <Flex flexDirection="column">
      <PageHeader>
        <Heading>Прогноз</Heading>
      </PageHeader>
      <RoutesList
        routes={[
          {
            path: ForecastRootPath,
            breadcrumb: 'Прогноз',
          },
          {
            path: '/data/meteo/forecast/protocol',
            breadcrumb: `${appLocale.common.dataProtocol} (Прогноз)`,
          },
        ]}
      />
    </Flex>
  );
};
