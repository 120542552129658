import { Flex, FlexProps, Spinner } from '@chakra-ui/react';

const Container = (props: FlexProps) => (
  <Flex
    position="fixed"
    width="100%"
    height="100%"
    top={0}
    left={0}
    alignItems="center"
    justifyContent="center"
    zIndex={100}
    backgroundColor="rgba(255, 255, 255, 0.8)"
    {...props}
  />
);

interface Props {
  containerProps?: FlexProps
}

export const Loader = ({ containerProps }: Props) => (
  <Container {...containerProps}>
    <Spinner color="spinner.primary" />
  </Container>
);
