import moment from 'moment';

export const createYesterdayDateDDMMYYYY = (today: Date | undefined) =>
  moment(today).add(-1, 'days').format('DD.MM.YYYY');

export const createTodayDateDDMMYYYY = (today: Date | undefined) => moment(today).format('DD.MM.YYYY');

export const createTomorrowDateDDMMYYYY = (today: Date | undefined) =>
  moment(today).add(1, 'days').format('DD.MM.YYYY');

export const convertTodayDateToISO = (today: Date) => moment(today).toISOString();

export const convertTomorrowDateToISO = (today: Date) => moment(today).add(1, 'days').toISOString();

export const lastDayPeriodDateTimeISO = (today: Date | null) =>
  moment(today).add(-1, 'days').set({ hour: 9, minute: 0, second: 0 }).format('YYYY-MM-DDTHH:mmZ');
export const lastNightPeriodDateTimeISO = (today: Date | null) =>
  moment(today).add(-1, 'days').set({ hour: 21, minute: 0, second: 0 }).format('YYYY-MM-DDTHH:mmZ');
export const todayPeriodDateTimeISO = (today: Date | null) =>
  moment(today).set({ hour: 9, minute: 0, second: 0 }).format('YYYY-MM-DDTHH:mmZ');
export const tonightPeriodDateTimeISO = (today: Date | null) =>
  moment(today).set({ hour: 21, minute: 0, second: 0 }).format('YYYY-MM-DDTHH:mmZ');
export const tomorrowPeriodDateTimeISO = (today: Date | null) =>
  moment(today).add(1, 'days').set({ hour: 9, minute: 0, second: 0 }).format('YYYY-MM-DDTHH:mmZ');
