import { FormWrapper } from 'components/common';
import { Box, Grid } from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import { createUTCTime } from 'lib/create-date';
import React, { Fragment, useEffect, useMemo } from 'react';
import { FieldFormProps } from 'types/forms';
import subsystemIdsMap from 'constants/subsystemIdsMap';
import { useAvailability } from 'hooks';
import {
  BurningRegistrationJournalFormSchema
} from 'models/schemas/burningRegistrationJournal/BurningRegistrationJournalFormSchema';
import { BurningRegistrationJournalItem } from 'pages/burningRegistrationJournal/types';
import appLocale from 'constants/appLocale';
import { IRenderFieldInterface, useFormField } from 'hooks/forms/useFormField';
import { getSelectOptionsFromDictionaryByName } from 'features/get-select-options-from-dictionary';

export const BurningRegistrationJournalFormFields = ({
  children,
  dictionaries,
}: React.PropsWithChildren<FieldFormProps>) => {
  const { isAvailable } = useAvailability(subsystemIdsMap.dutyOfficer);

  const { values, setFieldValue, initialValues } = useFormikContext<BurningRegistrationJournalItem>();

  const identifyInfo = useMemo(
    () => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'identifyInfo', false, initialValues.identifyInfoId),
    [dictionaries?.data, initialValues.identifyInfoId],
  );
  const cameras = useMemo(() => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'cameras'), [dictionaries]);
  const districts = useMemo(
    () => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'districts', false, initialValues.districtId),
    [dictionaries?.data, initialValues.districtId],
  );
  const admAreas = useMemo(
    () => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'admAreas', false, initialValues.admAreaId),
    [dictionaries?.data, initialValues.admAreaId],
  );

  const executors = useMemo(
    () => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'executors'),
    [dictionaries],
  );
  const cameraTypes = useMemo(
    () => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'cameraTypes', false, initialValues.type),
    [dictionaries?.data, initialValues.type],
  );

  useEffect(() => {
    if (cameras?.length > 0 && values.cameraId) {
      const camera = cameras.find((cam) => cam.value === values.cameraId);
      if (!camera || camera.type !== values.type) {
        setFieldValue('cameraId', null);
      }
    }
    // eslint-disable-next-line
  }, [setFieldValue, values.type, cameras]);

  const { renderField } = useFormField<BurningRegistrationJournalItem>({
    schema: BurningRegistrationJournalFormSchema,
    labelLocalization: appLocale.burningRegistrationJournal,
  });

  const fields: IRenderFieldInterface<BurningRegistrationJournalItem>[] = [
    {
      name: 'incidentDateTime',
      type: 'dateinput',
      props: { dateFormatter: createUTCTime },
      isDisabled: !isAvailable,
    },
    {
      name: 'fixationStartDateTime',
      type: 'datetimeinput',
      props: { dateFormatter: createUTCTime },
      isDisabled: !isAvailable,
    },
    {
      name: 'fixationEndDateTime',
      type: 'datetimeinput',
      props: { dateFormatter: createUTCTime },
      isDisabled: !isAvailable,
    },
    { name: 'type', type: 'select', options: cameraTypes, isDisabled: !isAvailable },
    {
      name: 'cameraId',
      type: 'select',
      options: cameras?.filter((c) => c.type === values.type),
      isDisabled: !isAvailable || !values.type,
    },
    { name: 'cameraName', isDisabled: !isAvailable },
    { name: 'admAreaId', type: 'select', options: admAreas, isDisabled: !isAvailable },
    { name: 'districtId', type: 'select', options: districts, isDisabled: !isAvailable },
    { name: 'latitude', isDisabled: !isAvailable },
    { name: 'longitude', isDisabled: !isAvailable },
    {
      name: 'address',
      type: 'input',
      isDisabled: !isAvailable,
    },
    { name: 'identifyInfoId', type: 'select', options: identifyInfo, isDisabled: !isAvailable },
    { name: 'actions', type: 'input', isDisabled: !isAvailable },
    { name: 'actionsOther', type: 'input', isDisabled: !isAvailable },
    { name: 'informationRecipient', type: 'input', isDisabled: !isAvailable },
    { name: 'executorId', type: 'select', options: executors, isDisabled: !isAvailable },
    { name: 'isRepeat', type: 'checkbox', isDisabled: !isAvailable },
    { name: 'comment', isDisabled: !isAvailable },
  ];

  return (
    <Box flexDirection="column">
      <FormWrapper>
        <Grid marginTop="30px" gap="15px">
          {fields.map((field) => (
            <Fragment key={field.name}>{renderField(field)}</Fragment>
          ))}

          {children}
        </Grid>
      </FormWrapper>
    </Box>
  );
};
