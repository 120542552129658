import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { usePaginatedLoader } from '../../typedLoader';
import { SubsystemItem } from '../../models/SubsystemItem';
import { baseAdminUrl } from 'pages/admin';
import { RoleWithRightsItem } from '../../models/RoleItem';
import { baseAdminRoleUrl } from '../const';
import { RightItem } from '../../models/RightItem';
import { useNotifications, useRequest } from 'hooks';
import { ERROR_MESSAGES, SUCCESS_MESSAGES } from 'constants/messages';
import { Field, FormikProvider, useFormik } from 'formik';
import { createTouchedErrors } from 'features/create-touched-errors';
import { Select } from 'components/common';
import FieldsForm from './FieldsForm';
import { IRight, IRightsData } from '../types';
import { useRoleRights } from '../useRoleRights';

interface IProps {
  id: string;
}

export const PrivelegesPage = ({ id }: IProps) => {
  const [selectedSubsystem, setSubsystem] = useState('');
  const tableRef: any = useRef();
  const history = useHistory();

  const { result: subsystems } = usePaginatedLoader<SubsystemItem>(`${baseAdminUrl}/subsystem/roots`);

  const { isLoading: isRoleRightsLoading, result: roleRightObjs } = useRoleRights<IRightsData>(
    `${baseAdminRoleUrl}/${id}/right`,
  );

  useEffect(() => {
    if (roleRightObjs) {
      tableRef?.current?.onQueryChange();
    }
  }, [roleRightObjs]);

  const initialValues: RoleWithRightsItem = {
    rights: roleRightObjs?.data?.filter((r: IRight) => r.hasRight).map((r: RightItem) => r.id) || [],
    templates: [],
  } as RoleWithRightsItem;

  const { successNotify, errorNotify } = useNotifications();

  const successHandler = () => {
    successNotify({
      key: 'file/create/success',
      message: SUCCESS_MESSAGES.EDIT,
    });
  };
  const errorHandler = (error: any) => {
    errorNotify({
      key: 'file/create/error',
      message: `${ERROR_MESSAGES.EDIT}. ${typeof error === 'string' ? error : ''}`,
    });
  };

  const { post: setRights } = useRequest(`${baseAdminRoleUrl}/${id}/right`, successHandler, errorHandler);

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    initialValues,
    onSubmit: (values) => {
      setRights({ data: values.rights });
    },
  });

  const subsystemOptions =
    subsystems?.data.map((subsystem) => ({
      value: subsystem.id,
      name: subsystem.name,
    })) || [];

  return (
    <FormikProvider
      value={{
        ...formik,
        errors: createTouchedErrors(formik.errors, formik.touched),
      }}
    >
      <Field
        as={Select}
        label="Поиск по подсистеме"
        name="subsystem"
        value={selectedSubsystem}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setSubsystem(e.target.value);
          tableRef?.current?.onQueryChange();
        }}
        options={subsystemOptions}
      />

      <FieldsForm
        tableRef={tableRef}
        selectedSubsystem={selectedSubsystem}
        onCancel={() => history.push('/admin/role')}
        isLoading={isRoleRightsLoading}
        url={`${baseAdminRoleUrl}/${id}/right`}
      />
    </FormikProvider>
  );
};
