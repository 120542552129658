import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import {
  APPEALS_TABLE_VIEW,
  CitizenAppealsTableViewEditPath,
  CitizenAppealsTableViewPath,
  CitizensAppealsPath,
} from '../consts/consts';
import { SubsystemHeader } from 'components/common';
import { useDisclosure } from '@chakra-ui/react';
import { MaterialTable } from 'features/material-table/MaterialTableMini';
import React, { useMemo } from 'react';
import { useList } from 'hooks';
import { AppealsTableViewFilter } from './AppealsTableViewFilter';
import { atmoColumns } from './Consts/consts';
import { Column } from '@material-table/core';
import { addFiltersToColumns } from 'lib/utils/tableFilter';
import { IAppealsTableViewResponse } from './Types/Types';
import { EyeViewIcon, ViewIcon2 } from 'components/icons';
import { useHistory } from 'react-router-dom';
import { SendToMailModal } from 'components/common/GraphicDropdownMenu/SendToMailModal';
import { useCustomRights } from 'hooks/useCustomRights';
import { subsystemCodesMap } from 'constants/subsystemIdsMap';
import { NavTabList } from 'features/NavTabList';
import { useDictionary } from 'hooks/useDictionary';
import { DEFAULT_PAGE_SIZE_NEW_TABLE } from '../../../constants/tables';
import { FullPageWrapper } from '../../../components/table/FullPageWrapper';

export const CitizenAppealsTableViewAir = () => {
  const { isWrite, isExecute } = useCustomRights(subsystemCodesMap.citizenAppeals.appealsTableViewAir);
  useUpdateBreadcrumbs([
    {
      breadcrumb: 'Учет обращений граждан',
      path: CitizensAppealsPath,
    },
    {
      breadcrumb: APPEALS_TABLE_VIEW,
      path: `${CitizenAppealsTableViewPath}/air`,
    },
  ]);

  const { isRead: isNoiseRead } = useCustomRights(subsystemCodesMap.citizenAppeals.appealsTableViewNoise);
  const { isRead: isAtmoRead } = useCustomRights(subsystemCodesMap.citizenAppeals.appealsTableViewAir);

  const {
    onPageChange,
    filterDTO,
    toggleOrder,
    setFilters,
    response,
    isLoading: isListLoading,
    downloadAsFile,
    getHeaders,
    headers,
    resetHeaders,
    errorText,
    onSearchText,
    materialTableProps,
  } = useList('appeal/v10/raid-type/air/list', {
    saveFiltersState: true,
    defaultPageSize: DEFAULT_PAGE_SIZE_NEW_TABLE,
    resetDataOnNextRequest: true,
  });

  const {
    isDictionariesLoading,
    dictionaries: appealsDictionaries
  } = useDictionary({ url: '/appeal/v10/appeals/dictionaries' });

  const columnsWithFilters = useMemo<Array<Column<IAppealsTableViewResponse>>>(
    () => addFiltersToColumns({ filters: filterDTO.filters, setFilters, columns: atmoColumns }),
    [filterDTO.filters, setFilters],
  );

  const history = useHistory();

  const { isOpen: isOpenUploadModal, onOpen: onOpenUploadModal, onClose: onCloseUploadModal } = useDisclosure();

  const isLoading = useMemo(() => isListLoading || isDictionariesLoading, [isListLoading, isDictionariesLoading]);

  return (
    <FullPageWrapper>
      {!isAtmoRead && !isNoiseRead ? null : (
        <>
          <SubsystemHeader
            title={APPEALS_TABLE_VIEW}
            filtersComponent={
              <AppealsTableViewFilter
                onSubmit={(values) => setFilters(Object.values(values))}
                type={1}
                appealsDictionaries={appealsDictionaries}
                filters={filterDTO.filters}
                isLoading={isLoading}
              />
            }
            navigationTabs={
              <NavTabList
                tabs={[
                  {
                    label: 'Сводный отчет по поступившим жалобам (шум)',
                    path: `${CitizenAppealsTableViewPath}/noise`,
                    isDisabled: !isNoiseRead,
                  },
                  {
                    label: 'Сводный отчет по поступившим жалобам (воздух)',
                    path: `${CitizenAppealsTableViewPath}/air`,
                    isDisabled: !isAtmoRead,
                  },
                ]}
              />
            }
          />

          <MaterialTable
            isNotExecute={isExecute}
            options={{
              search: true,
            }}
            data={response?.data}
            meta={response?.meta}
            toggleOrder={toggleOrder}
            onPageChange={onPageChange}
            filterDTO={filterDTO}
            columns={columnsWithFilters}
            isLoading={isListLoading}
            downloadAsFile={downloadAsFile}
            getHeaders={getHeaders}
            onOpenUploadModal={onOpenUploadModal}
            actions={[
              {
                position: 'row',
                action: (row) => ({
                  icon: () => <ViewIcon2 />,
                  onClick: () => history.push(`${CitizenAppealsTableViewPath}/edit/${row.id}`, { isWrite }),
                  tooltip: 'Просмотреть запись',
                }),
              },
              {
                position: 'row',
                action: (row) => ({
                  icon: () => <EyeViewIcon />,
                  onClick: () =>
                    history.push(`${CitizenAppealsTableViewEditPath}/air`, {
                      id: row.taskId,
                      tabIndex: 0,
                    }),
                  tooltip: 'Просмотреть задание',
                  hidden: !row.taskId || !isWrite,
                }),
              },
            ]}
            errorText={errorText}
            onSearch={onSearchText}
            {...materialTableProps}
          />

          <SendToMailModal
            onClose={onCloseUploadModal}
            headers={headers}
            isOpen={isOpenUploadModal}
            resetHeaders={resetHeaders}
            filename={APPEALS_TABLE_VIEW}
          />
        </>
      )}
    </FullPageWrapper>
  );
};
