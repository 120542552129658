import * as Yup from 'yup';
import { ERRORS } from 'constants/errors';

export const initialEmptyValues = {
  // источник измерения - эти данные нельзя редактировать
  stationId: '',
  stationName: '',
  longitude: '',
  latitude: '',
  measuringDatetime: '',
  // измеряемые параметры
  waterTemperature: '',
  phIndex: '',
  electricalConductivity: '',
  dissolvedOxygen: '',
  chemicalOxygenDemand: '',
  ammoniumIonicConcentration: '',
  ironConcentration: '',
  manganeseConcentration: '',
  nitriteIonsConcentration: '',
  phosphateIonsConcentration: '',
  // технологические параметры
  waterLevel: '',
  pressure1: '',
  pressure2: '',
  flow: '',
  probeNumber: '',
  eventId: '',
  doorId: '',
  fireId: '',
};

export const validationSchema = Yup.object().shape({
  stationId: Yup.string().required(ERRORS.required),
  measuringDatetime: Yup.string().required(ERRORS.required),
});
