import { useCallback, useState } from 'react';
import { useRequest } from 'hooks/useRequest';
import { useParams } from 'react-router-dom';
import { Modal } from 'components/common/Modals';
import { useDisclosure } from 'hooks';
import { Button } from 'components/common';
import { StationDevicesTable, StationDevicesTableProps } from 'pages/equipment/Stations/components/StationDevicesTable';
import { AddDevices } from 'pages/equipment/Stations/components/AddDevices';
import { StationDeviceItem } from 'pages/equipment/Stations/types';
import { RemoveDevice } from 'pages/equipment/Stations/components/RemoveDevice';
import { COMMON_LOCALE } from 'constants/common';
import { Flex } from '@chakra-ui/react';
import { useGoBackWithFallback } from 'lib/utils/goBackWithFallback';

type OnDelete = StationDevicesTableProps['onDelete'];

interface IProps {
  data: StationDeviceItem[] | undefined;
  isLoading: boolean;
  refresh: () => void;
  refetchParams: () => void;
}

export const StationDevices = ({ data, isLoading, refresh, refetchParams }: IProps) => {
  const { id: stationId } = useParams<{ id: string }>();
  const { backWithFallback } = useGoBackWithFallback();
  const onBack = useCallback(() => {
    backWithFallback('/equipment/stations');
  }, [backWithFallback]);

  const { isOpen, onClose, onOpen } = useDisclosure();
  const onSuccess = () => {
    refresh();
    refetchParams();
    onClose();
  };
  const { post, isLoading: isSubmitLoading } = useRequest('/equipment/v10/equipments/stations/devices', onSuccess);

  // delete parameter
  const [deleteItem, setDeleteItem] = useState<StationDeviceItem | undefined>();
  const { isOpen: isOpenDelete, onClose: onCloseDelete, onOpen: onOpenDelete } = useDisclosure();
  const onDelete = useCallback<OnDelete>(
    (item) => {
      setDeleteItem(item);
      onOpenDelete();
    },
    [onOpenDelete]
  );
  const onCancelDelete = useCallback(() => {
    setDeleteItem(undefined);
    onCloseDelete();
  }, [onCloseDelete]);
  const onSuccessDelete = useCallback(() => {
    setDeleteItem(undefined);
    onCloseDelete();
    refresh();
    refetchParams();
  }, [onCloseDelete, refetchParams, refresh]);

  return (
    <>
      <StationDevicesTable data={data} isLoading={isLoading} onDelete={onDelete} />

      <Flex marginTop={5}>
        <Button onClick={onOpen}>Добавить приборы</Button>
        <Button onClick={onBack} variant="ghost" marginRight={5}>
          {COMMON_LOCALE.cancel}
        </Button>
      </Flex>

      <Modal isOpen={isOpen} onClose={onClose} header="Прибор">
        <AddDevices stationId={stationId} onSubmit={post} onCancel={onClose} isSubmitLoading={isSubmitLoading} />
      </Modal>

      <Modal isOpen={isOpenDelete} onClose={onCancelDelete} header="Снять прибор">
        {!deleteItem ? null : <RemoveDevice item={deleteItem} onCancel={onCancelDelete} onSuccess={onSuccessDelete} />}
      </Modal>
    </>
  );
};
