import { useLogout } from 'api/services/auth';
import { Button } from '@chakra-ui/react';
import { useContext } from 'react';
import { UserInfoContext } from 'features/AuthProvider';
// import { useUserRights } from 'hooks';

export const LogoutButton = () => {
  // const { refetch: resetRights } = useUserRights();
  const { resetUser, resetRoles } = useContext(UserInfoContext);

  const { logout, isLoading } = useLogout();

  const handleClick = async () => {
    await logout();
    resetUser();
    resetRoles();
    // if (resetRights) {
    //   resetRights();
    // }
  };

  return (
    <Button isLoading={isLoading} mr={4} variant="solid" size="xs" lineHeight={1} onClick={handleClick}>
      Выйти
    </Button>
  );
};
