import { BreadcrumbButton, PageHeader } from 'components/common';
import { Heading } from '@chakra-ui/react';
import { FormikProvider, useFormik } from 'formik';
import { createTouchedErrors } from 'features/create-touched-errors';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useNotifications, useRequest } from 'hooks';
import { ERROR_MESSAGES, SUCCESS_MESSAGES } from 'constants/messages';
import { AddNMUFormFields } from './FormFields';
import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import { NsiNMUSchema } from 'models/schemas/nsiEmissions/NsiNMUSchema';
import { NMU_ApiPath, NSI_NMU_AddPath, NSI_NMU_Breadcrumbs, NSI_NMU_EditPath, NSI_NMU_RootPath } from '../consts';
import { NMUListItem } from '../types';
import { useGoBackWithFallback } from 'lib/utils/goBackWithFallback';

export const AddNMU = () => {
  const { id } = useParams<{ id: string }>();
  useUpdateBreadcrumbs([
    ...NSI_NMU_Breadcrumbs,
    {
      breadcrumb: `${id ? 'Редактирование' : 'Добавление'} периода НМУ`,
      path: id ? `${NSI_NMU_EditPath}/${id}` : NSI_NMU_AddPath,
    },
  ]);
  const { successNotify, errorNotify } = useNotifications();
  const { backWithFallback } = useGoBackWithFallback();

  const onSuccess = () => {
    successNotify({
      key: `nmu/${id ? 'edit' : 'add'}/success`,
      message: id ? SUCCESS_MESSAGES.EDIT : SUCCESS_MESSAGES.CREATE,
    });
    backWithFallback(NSI_NMU_RootPath);
  };
  const onError = () =>
    errorNotify({
      key: `nmu/${id ? 'edit' : 'add'}/error`,
      message: id ? ERROR_MESSAGES.EDIT : ERROR_MESSAGES.CREATE,
    });

  const { post } = useRequest(NMU_ApiPath, onSuccess, onError);
  const { get, result } = useRequest<NMUListItem>(`${NMU_ApiPath}/${id}`);
  const { put } = useRequest(`${NMU_ApiPath}/${id}`, onSuccess, onError);

  useEffect(() => {
    if (id) {
      get();
    }
  }, [id, get]);

  const initialValues = {
    startDate: result?.startDate || '',
    endDate: result?.endDate || '',
    deleted: result?.deleted || false,
    grade: result?.grade || '',
    id: result?.id || null,
  };

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    initialValues,
    validationSchema: NsiNMUSchema,
    onSubmit: (values) => {
      if (id) {
        put(values);
      } else {
        post(values);
      }
    },
  });

  return (
    <>
      <PageHeader>
        <BreadcrumbButton onClick={() => backWithFallback(NSI_NMU_RootPath)}>Вернуться назад</BreadcrumbButton>
        <Heading marginLeft={4}>
          {`${id ? 'Редактирование' : 'Добавление'}
          периода НМУ`}
        </Heading>
      </PageHeader>
      <FormikProvider
        value={{
          ...formik,
          errors: createTouchedErrors(formik.errors, formik.touched),
        }}
      >
        <AddNMUFormFields />
      </FormikProvider>
    </>
  );
};
