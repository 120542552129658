import { Grid } from '@chakra-ui/react';
import { FormWrapper, Input, Select } from 'components/common';
import React, { useCallback, useMemo } from 'react';
import { Field, useFormikContext } from 'formik';
import { ApiError, TDictionariesArray } from 'models';
import { getSelectOptionsFromDictionaryByName } from 'features/get-select-options-from-dictionary';
import { IContent, IFTPPattern, ISource } from '../Consts/types';
import { AxiosRequestConfig } from 'axios';
import { dataPatternRootPath } from '../Consts/consts';

interface IProps {
  dictionaries: { data: TDictionariesArray } | null;
  children: React.ReactNode;
  getAllSources: (config?: AxiosRequestConfig | undefined) => Promise<ApiError | ISource[] | null>;
}

export const DataPatternMainTab = ({ dictionaries, children, getAllSources }: IProps) => {
  const { setFieldValue, errors } = useFormikContext<IFTPPattern>();

  const subsystems = useMemo(
    () => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'subsystems'),
    [dictionaries],
  );

  const onSubsystemChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setFieldValue('subsystemId', e.target.value);
    getAllSources({ url: `${dataPatternRootPath}/dictionary/sources/${e.target.value}` }).then((response) => {
      if (Array.isArray(response)) {
        const content: IContent = {};
        response.forEach((item) => {
          content[item.id] = item.parameters.map((parameter) => parameter.parameterId);
        });
        setFieldValue('content', content);
      }
    });
  }, [getAllSources, setFieldValue]);

  return (
    <FormWrapper mt="30px">
      <Grid gap="20px">
        <Field
          as={Input}
          name="templateName"
          label="Наименование шаблона данных"
          error={errors.templateName}
          isRequired
        />
        <Field
          as={Select}
          name="subsystemId"
          label="Наименование подсистемы"
          options={subsystems}
          onChange={onSubsystemChange}
          error={errors.subsystemId}
          isRequired
        />
        {children}
      </Grid>
    </FormWrapper>
  );
};
