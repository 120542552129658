import { Grid } from '@chakra-ui/react';
import { Field } from 'formik';
import { Input } from 'components/common';
import React from 'react';

export const OtherInformation = () => (
  <Grid gap={2} templateColumns="repeat(1, 450px)">
    <Field as={Input} name="description" label="Примечание" />
  </Grid>
);
