import { ERROR_MESSAGES, SUCCESS_MESSAGES } from 'constants/messages';
import { useNotifications } from 'hooks/useNotifications';
import { useRequest } from 'hooks/useRequest';
import { DeviceForm } from 'pages/equipment/Devices/components/DeviceForm';
import { Device } from 'pages/equipment/Devices/types';
import { DeviceLayout } from 'pages/equipment/Devices/components/DeviceLayout';
import { FormContainer } from 'pages/equipment/Devices/components/DeviceForm/FormContainer';
import { useEquipmentDeviceBreadcrumbs } from './hooks/useEquipmentDeviceBreadcrumbs';
import { useState } from 'react';
import { IUploadMedia } from 'components/common/FileUploadDragAndDrop/types';
import { updateFiles, UploadObjectType } from 'api/services/media';
import { useEnhancedNotification } from 'hooks';
import { useGoBackWithFallback } from 'lib/utils/goBackWithFallback';
import { useHistory } from 'react-router-dom';

export const AddDevice: React.FC = () => {
  const { backWithFallback } = useGoBackWithFallback();
  const { push } = useHistory();
  const { onErrorCreate } = useEnhancedNotification();
  useEquipmentDeviceBreadcrumbs();

  const [uploadItemId, setItemId] = useState('');
  const [media, setMedia] = useState<IUploadMedia[]>([]);

  const { successNotify, errorNotify } = useNotifications();
  const onSuccess = (data: Device | null) => {
    const url = data ? `/equipment/devices/edit/${data.id}/true` : '/equipment/devices';
    if (data) {
      setItemId(data.id);
      successNotify({
        key: 'file/edit/success',
        message: SUCCESS_MESSAGES.CREATE,
      });
      updateFiles(data.id, UploadObjectType.EQUIPMENT_DEVICE, media)
        .then(() => {
          push(url);
        })
        .catch(() => {
          onErrorCreate();
        });
    } else {
      onErrorCreate();
    }
  };
  const onError = () =>
    errorNotify({
      key: 'file/edit/error',
      message: ERROR_MESSAGES.CREATE,
    });
  const { post } = useRequest('/equipment/v10/equipments/instruments', onSuccess, onError);

  const initialValues: Partial<Device> = {
    deviceName: '',
    equipmentInvent: '',
    equipmentSerial: '',
    localDate: null,
    modelIds: [],
    stationId: '',
    status: '',
    supplierId: '',
    warrantyEndDate: null,
  };

  return (
    <DeviceLayout
      title="Добавление прибора"
      onMediaChange={setMedia}
      media={media}
      uploadItemId={uploadItemId && uploadItemId}
      onCancel={() => {
        backWithFallback('/equipment/devices');
      }}
      onSubmit={post}
      initialValues={initialValues}
      device={
        <FormContainer>
          <DeviceForm />
        </FormContainer>
      }
    />
  );
};
