import { api } from 'api/config';
import { AxiosError, AxiosRequestConfig } from 'axios';
import { ApiError } from 'models';
import React, { useCallback, useEffect } from 'react';
import { IncomingHttpHeaders } from 'http';
import { HTTP_CODES } from 'constants/api';

type Data = Record<string, unknown> | unknown[];

interface UseRequestParametrs<TData = Data, Error = ApiError> {
  url: string;
  successHandler?: (data: TData | null) => void;
  errorHandler?: (error: Error | null) => void;
}

interface UseRequestReturnType<Data = Record<string, unknown>, Error = ApiError> {
  get: (config?: AxiosRequestConfig) => Promise<Data | Error | null>;
  post: <Body = Record<string, unknown> | FormData>(
    body?: Body,
    config?: AxiosRequestConfig,
    sendToMail?: boolean
  ) => Promise<Data | Error | null>;
  put: <Body = Record<string, unknown>>(body?: Body, config?: AxiosRequestConfig) => Promise<Data | Error | null>;
  remove: (config?: AxiosRequestConfig) => Promise<Data | Error | null>;
  isLoading: boolean;
  result: Data | null;
  error: Error | { descriptions: { message: string }[] } | null;
  reset: () => void;
  headers?: IncomingHttpHeaders | null;
  removeArray?: (body: string[], config: AxiosRequestConfig) => Promise<Data | Error | null>;
}

export const useRequest = <TData = Data, TError = ApiError>(
  url: UseRequestParametrs<TData, TError>['url'],
  successHandler?: UseRequestParametrs<TData, TError>['successHandler'],
  errorHandler?: UseRequestParametrs<TData, TError>['errorHandler']
): UseRequestReturnType<TData, TError> => {
  const [result, setResult] = React.useState<TData | null>(null);
  const [headers, setHeaders] = React.useState<IncomingHttpHeaders | null>(null);

  const [error, setError] = React.useState<TError | null>(null);

  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const ifDefineSuccessHandler = useCallback(
    (data: typeof result, headers?: IncomingHttpHeaders) => {
      setIsLoading(false);

      if (headers) {
        setHeaders(headers);
      }

      if (data) {
        setResult(data);

        if (typeof successHandler === 'function') {
          successHandler(data);
        }
      } else {
        setResult(null);

        if (typeof successHandler === 'function') {
          successHandler(null);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [successHandler]
  );

  const ifDefineErrorHandler = useCallback(
    (e: AxiosError<typeof error>) => {
      setIsLoading(false);

      let errorData: any = e.response?.data || null;

      if (e.response?.status === HTTP_CODES.BAD_GATEWAY || e.response?.status === HTTP_CODES.GATEWAY_TIMEOUT) {
        errorData = { descriptions: [{ message: `${e.response?.status} ${e.response?.statusText}` }] };
      }

      setError(errorData);

      if (typeof errorHandler === 'function') {
        errorHandler(errorData);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [errorHandler]
  );

  const get: UseRequestReturnType<typeof result, typeof error>['get'] = useCallback(
    async (config) => {
      setIsLoading(true);

      try {
        const { data } = await api.request<typeof result>({
          method: 'GET',
          url,
          withCredentials: true,
          ...config,
        });

        ifDefineSuccessHandler(data);

        if (data) {
          return data;
        }

        return null;
      } catch (e: unknown) {
        const err = e as AxiosError<typeof error>;

        ifDefineErrorHandler(err);

        if (err?.response?.data) {
          return err?.response?.data;
        }
        return null;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ifDefineErrorHandler, ifDefineSuccessHandler, url]
  );

  const post: UseRequestReturnType<typeof result, typeof error>['post'] = useCallback(
    async (body, config) => {
      setIsLoading(true);

      try {
        const { data, headers } = await api.request<typeof result>({
          method: 'POST',
          url,
          data: body instanceof FormData ? body : { ...body },
          withCredentials: true,
          ...config,
        });

        ifDefineSuccessHandler(data, headers);

        if (data) {
          return data;
        }

        return null;
      } catch (e: unknown) {
        const err = e as AxiosError<typeof error>;

        ifDefineErrorHandler(err);

        if (err?.response?.data) {
          return err?.response?.data;
        }
        return null;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ifDefineErrorHandler, ifDefineSuccessHandler, url]
  );

  const put: UseRequestReturnType<typeof result, typeof error>['put'] = useCallback(
    async (body, config) => {
      setIsLoading(true);

      try {
        const { data } = await api.request<typeof result>({
          method: 'PUT',
          url,
          data: {
            ...body,
          },
          withCredentials: true,
          ...config,
        });

        ifDefineSuccessHandler(data);

        if (data) {
          return data;
        }

        return null;
      } catch (e: unknown) {
        const err = e as AxiosError<typeof error>;

        ifDefineErrorHandler(err);

        if (err?.response?.data) {
          return err?.response?.data;
        }
        return null;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ifDefineErrorHandler, ifDefineSuccessHandler, url]
  );

  const remove: UseRequestReturnType<typeof result, typeof error>['remove'] = useCallback(
    async (config) => {
      setIsLoading(true);

      try {
        const { data } = await api.request<typeof result>({
          method: 'DELETE',
          url,
          withCredentials: true,
          ...config,
        });

        ifDefineSuccessHandler(data);

        if (data) {
          return data;
        }

        return null;
      } catch (e: unknown) {
        const err = e as AxiosError<typeof error>;

        ifDefineErrorHandler(err);

        if (err?.response?.data) {
          return err?.response?.data;
        }
        return null;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ifDefineErrorHandler, ifDefineSuccessHandler, url]
  );

  const removeArray: UseRequestReturnType<typeof result, typeof error>['removeArray'] = useCallback(
    async (body, config) => {
      setIsLoading(true);

      try {
        const { data } = await api.request<typeof result>({
          method: 'DELETE',
          url,
          withCredentials: true,
          data: body,
          ...config,
        });

        ifDefineSuccessHandler(data);

        if (data) {
          return data;
        }

        return null;
      } catch (e: unknown) {
        const err = e as AxiosError<typeof error>;

        ifDefineErrorHandler(err);

        if (err?.response?.data) {
          return err?.response?.data;
        }
        return null;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ifDefineErrorHandler, ifDefineSuccessHandler, url]
  );

  const reset = useCallback(() => {
    setIsLoading(false);
    setResult(null);
    setError(null);
    setHeaders(null);
  }, []);

  useEffect(
    () => () => {
      reset();
    },
    [reset]
  );

  return {
    get,
    post,
    put,
    remove,
    isLoading,
    result,
    error,
    reset,
    headers,
    removeArray,
  };
};

export type { UseRequestReturnType, UseRequestParametrs };
