import { useCallback, useEffect, useMemo, useState } from 'react';
import { useFormikContext } from 'formik';
import { useRequest } from 'hooks';
import { IContextValues, IMapObject } from './types';
import { TDictionaryItemDictionary } from '../../models';

interface IProps {
  browserId: string;
  uid: string;
  isReports?: boolean;
  pointTypeId?: TDictionaryItemDictionary[];
}

export const MapForPels = ({ browserId, uid, isReports, pointTypeId }: IProps) => {
  const { values, setFieldValue } = useFormikContext<IContextValues>();
  const [mapResponse, setMapResponse] = useState<IMapObject | null>(null);
  const [connected, setConnected] = useState<boolean>(false);

  const latitude = isReports ? 'pointLatitude' : 'dotLatitude';
  const longitude = isReports ? 'pointLongitude' : 'dotLongitude';
  const coordinateArray = isReports ? 'pointCoordinateArray' : 'evalObjCoordinateArray';

  const { post } = useRequest('/mapsclient/v10/sendCommand');

  const isJSONString = (str: string) => {
    try {
      JSON.parse(str);
    } catch {
      return false;
    }
    return true;
  };

  const areaId = useMemo(() => pointTypeId?.find((item) => item.name === 'Область обследования')?.value, [pointTypeId]);

  const pointsForMap = useMemo(() => {
    const points: IMapObject = {
      features: [],
      uid,
      layerTemplateId: '1121430612',
    };
    if (values.points) {
      values.points.forEach((point) => {
        if (!point[latitude] && !point[longitude] && !point[coordinateArray]) {
          return;
        }
        if ((point.pointTypeId || point.typeId) === areaId && !isJSONString(point[coordinateArray])) {
          return;
        }
        if (
          ((point.pointTypeId || point.typeId) !== areaId) &&
          !(Number.isFinite(+point[latitude]) && Number.isFinite(+point[longitude]))
        ) {
          return;
        }
        // @ts-ignore
        points.features.push({
          geometry: {
            // @ts-ignore
            coordinates: point[coordinateArray]
              ? JSON.parse(point[coordinateArray])
              : [point[longitude], point[latitude]],
            // @ts-ignore
            type: point[coordinateArray] ? 'Polygon' : 'Point',
          },
          // @ts-ignore
          properties: { id: point.id || point.temporaryId, name: point.pointNumber },
          // @ts-ignore
          type: 'Feature',
        });
      });
    }

    return points;
  }, [areaId, coordinateArray, latitude, longitude, uid, values.points]);

  const handleEvent = useCallback(
    (event) => {
      setMapResponse(event.detail);
    },
    [setMapResponse]
  );

  const sendCommand = useCallback(() => {
    post({
      browserId,
      command: 'query',
      data: pointsForMap,
    });
  }, [browserId, post, pointsForMap]);

  useEffect(() => {
    const timeout = setTimeout(() => !connected || sendCommand(), 2000);
    return () => clearTimeout(timeout);
  }, [connected, pointsForMap, sendCommand]);

  useEffect(() => {
    if (mapResponse) {
      values.points.forEach((point: any, index: number) => {
        const responsePoint = mapResponse.features.find(
          (metaPoint: any) => (point.id ? point.id : point.temporaryId) === metaPoint.properties.id
        );
        if (!responsePoint) {
          setFieldValue(`points[${index}].${coordinateArray}`, null);
          setFieldValue(`points[${index}].${latitude}`, null);
          setFieldValue(`points[${index}].${longitude}`, null);
        }
        if (responsePoint?.geometry.type === 'Polygon') {
          setFieldValue(
            `points[${index}].${coordinateArray}`,
            JSON.stringify(responsePoint?.geometry.coordinates) || null
          );
        } else {
          setFieldValue(`points[${index}].${latitude}`, responsePoint?.geometry.coordinates[1] || null);
          setFieldValue(`points[${index}].${longitude}`, responsePoint?.geometry.coordinates[0] || null);
        }
      });
      setMapResponse(null);
    }
  }, [coordinateArray, latitude, longitude, mapResponse, setFieldValue, values.points]);

  useEffect(() => {
    window.document.addEventListener('map-update-layer-data', handleEvent, false);
    window.document.addEventListener('socket-server-connected', () => setConnected(true), false);
    return () => {
      window.document.removeEventListener('map-update-layer-data', handleEvent, false);
      window.document.removeEventListener('socket-server-connected', () => setConnected(false), false);
    };
  }, [handleEvent, sendCommand]);

  return (
    <>
      <iframe
        title="map"
        width="100%"
        height="100%"
        src={`/api/mapsclient/v10/widget/#/?ui=borderless&browserId=${browserId}`}
      />
    </>
  );
};
