import { ANALYTICS_STATUS } from 'pages/analytics/forms/const';

export const initialNoiseEmptyValues: any = {
  address: '',
  conclusion: '',
  customerId: '',
  meteoValues: {},
  methodology: '',
  noiseCharacterValue: '',
  noiseSourcesAndCharacterValue: '',
  protocolDate: '',
  protocolNumber: '',
  purpose: '',
  // deviceId: '',
  sign: {
    signers: [
      {
        name: '',
        position: '',
      },
    ],
  },
  laboratory: true,
  status: ANALYTICS_STATUS.DRAFT,
};
