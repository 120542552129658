import { getSelectOptionsFromDictionaryByNameWithParent } from 'features/get-select-options-from-dictionary';
import { TDictionariesArray, TDictionaryItemDictionary } from 'models';
import { UploadObjectType } from 'api/services/media';

export const useObjectType = (
  dictionaries:
    | {
        data: TDictionariesArray;
      }
    | TDictionaryItemDictionary<string | (Record<string, any> & string), string | (Record<string, any> & string)>[]
    | undefined
    | null
): UploadObjectType | null => {
  let objectType = null;
  if (!dictionaries) {
    return objectType;
  }
  if ('data' in dictionaries) {
    objectType = getSelectOptionsFromDictionaryByNameWithParent(dictionaries?.data, 'objectType')[0]?.value;
  } else {
    objectType = dictionaries[0].value;
  }

  return (objectType as UploadObjectType) ?? null;
};
