import React from 'react';
import { PageHeader, RoutesList } from 'components/common';
import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import { meteoProtocolMenu } from 'pages/data/Meteo/menu';
import { meteoProtocolsBreadcrumbs } from 'pages/data/Meteo/Protocol/const';
import appLocale from 'constants/appLocale';
import { Flex, Heading } from '@chakra-ui/react';
import { useRoutesList } from 'hooks/useRoutesList';

export const MeteoProtocolMenu = () => {
  useUpdateBreadcrumbs(meteoProtocolsBreadcrumbs);
  const routes = useRoutesList(meteoProtocolMenu);
  return (
    <Flex flexDirection="column">
      <PageHeader>
        <Heading>{`${appLocale.common.dataProtocol} (Метеоданные)`}</Heading>
      </PageHeader>
      <RoutesList routes={routes} />
    </Flex>
  );
};
