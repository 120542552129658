import { Breadcrumb, PageHeader } from 'components/common';
import { LoadPreviewToolbar } from 'features/LoadPreviewToolbar';
import { useUploadFileProvider } from 'features/UploadedFileProvider';

import { useInitializerUploadSettings } from 'features/UploadedFileProvider/UploadFileSettings';
import React, { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { getTemplate } from './Views/getTemplate';
import { MeteoDataApiRoot, MeteoDataMonitoringDataPath } from 'pages/data/Meteo/menu';
import { Flex, Heading } from '@chakra-ui/react';

function MeteoDataLoadPreview() {
  const { typeId } = useParams<{ typeId: string }>();

  const { preview } = useUploadFileProvider();

  useInitializerUploadSettings(
    useMemo(() => {
      if (typeId) {
        return {
          typesPath: `${MeteoDataApiRoot}/load/types`,
          previewPath: `${MeteoDataApiRoot}/load/preview/${typeId}`,
          validatePath: `${MeteoDataApiRoot}/load/validate/${typeId}`,
          applyPath: `${MeteoDataApiRoot}/load/apply/${typeId}`,
          redirectPath: MeteoDataMonitoringDataPath,
        };
      }

      return {
        typesPath: `${MeteoDataApiRoot}/load/types`,
        previewPath: '',
        validatePath: '',
        applyPath: '',
        redirectPath: MeteoDataMonitoringDataPath,
      };
    }, [typeId])
  );

  const { validate, apply } = useUploadFileProvider();

  const onSubmit = useCallback(() => {
    if (apply?.handleApplyFilePreview) {
      apply.handleApplyFilePreview();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apply?.handleApplyFilePreview]);

  const Component = getTemplate(Number(typeId));

  return (
    <Flex flexDirection="column">
      <PageHeader>
        <Breadcrumb to={MeteoDataMonitoringDataPath}>Вернуться назад</Breadcrumb>
        <Heading variant="h1">Предварительный просмотр загружаемых данных</Heading>
        <LoadPreviewToolbar
          onSubmit={onSubmit}
          isLoading={Boolean(validate?.isLoading) || Boolean(apply?.isLoading)}
          fileName={preview?.result?.fileName ?? ''}
        />
      </PageHeader>
      {Component && <Component typeId={typeId} />}
    </Flex>
  );
}

export { MeteoDataLoadPreview };
