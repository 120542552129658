import { DateInput, Select } from 'components/common';
import { Flex, Grid } from '@chakra-ui/react';
import { Field, useFormikContext } from 'formik';
import appLocale from 'constants/appLocale';
import React from 'react';
import { createUTCTime } from 'lib/create-date';
import { IFilterItem } from 'models';
import FilterFormControls from 'components/form/FilterFormControls';

const grades = [
  {
    name: 1,
    value: 'nmu_1',
  },
  {
    name: 2,
    value: 'nmu_2',
  },
  {
    name: 3,
    value: 'nmu_3',
  },
];

export const NMUFormFields = () => {
  const { submitForm, setFieldValue, handleReset, values, errors } = useFormikContext<{ [p: string]: IFilterItem }>();
  return (
    <Flex>
      <Grid width="100%" maxWidth="1200px" gap={5} templateColumns="repeat(3, 220px)">
        <Field
          as={DateInput}
          name="startDate.value"
          label={appLocale.common.from}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setFieldValue('startDate.value', createUTCTime(e.target.value))
          }
          showTimeInput
          error={errors.startDate?.value}
        />

        <Field
          as={DateInput}
          name="endDate.value"
          label={appLocale.common.to}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setFieldValue('endDate.value', createUTCTime(e.target.value))
          }
          showTimeInput
          error={errors.endDate?.value}
        />

        <Field
          as={Select}
          name="grade.value"
          label="Степень НМУ"
          options={grades}
          value={values.grade.value}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFieldValue('grade.value', e.target.value)}
        />
      </Grid>

      <FilterFormControls
        onReset={(e) => {
          handleReset(e);
          setTimeout(submitForm, 0);
        }}
        onSubmit={submitForm}
      />
    </Flex>
  );
};
