import { colors } from 'components/theme/colors';
import { ArrowRight } from 'components/icons';
import { makeStyles } from '@material-ui/core/styles';
import { FC } from 'react';
import { Box } from '@chakra-ui/react';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
  arrow: {
    display: 'flex',
    width: 72,
    height: '100%',
    position: 'absolute',
    left: 0,
    top: 0,
    zIndex: 1,
    opacity: 0.3,
    backgroundColor: colors.grey[400],
    alignItems: 'center',
    '&:hover': {
      opacity: 0.8,
    },
  },
  arrowRight: {
    left: 'initial',
    right: 0,
  },
}));

interface Props {
  onClick: () => void,
  left?: boolean,
  hasNext: boolean
}

const CarouselArrow: FC<Props> = ({ onClick, hasNext, left = false }) => {
  const classes = useStyles();
  return (
    <Box
      onClick={onClick}
      className={clsx(classes.arrow, { [classes.arrowRight]: !left })}
      visibility={hasNext ? 'initial' : 'hidden'}
    >
      <ArrowRight style={{ transform: `rotate(${left ? 180 : 0}deg)` }} />
    </Box>
  );
};

export default CarouselArrow;
