import appLocale from 'constants/appLocale';
import { NSIRootPath } from 'pages/dictionaries/const/routes';
import {
  NSIEmissionsIndustriesRootPath,
  NSIEmissionsRootPath,
  NSIEmissionsSourcesRootPath,
} from 'pages/dictionaries/NSIEmissions/consts/paths';

export const NSIEmissionsListBreadcrumbs = [
  {
    breadcrumb: appLocale.mainPage.nsi,
    path: NSIRootPath,
  },
  {
    breadcrumb: 'Промышленные выбросы в атмосферу',
    path: NSIEmissionsRootPath,
  },
];

export const NSIEmissionsSourcesBreadcrumbs = [
  ...NSIEmissionsListBreadcrumbs,
  {
    breadcrumb: 'Источники загрязнения воздуха',
    path: NSIEmissionsSourcesRootPath,
  },
];

export const NSIEmissionsIndustriesBreadcrumbs = [
  ...NSIEmissionsListBreadcrumbs,
  {
    breadcrumb: 'Предприятия',
    path: NSIEmissionsIndustriesRootPath,
  },
];
