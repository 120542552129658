export const getDictionariesIds = (ids: string[], parameters: Record<string, any>) => {
  const dictionaryIds: string[] = [];
  // TODO: simplify
  ids.forEach((id) => {
    Object.keys(parameters).forEach((dictionaryItemId) => {
      if (id === dictionaryItemId) {
        dictionaryIds.push(dictionaryItemId);
      }
    });
  });
  return dictionaryIds;
};

export const getDictionaryId = (id: string | null, parameters: Record<string, any>) => {
  let dictId = '';
  Object.keys(parameters).forEach((dictionaryItemId) => {
    if (dictionaryItemId === id) {
      dictId = dictionaryItemId;
    }
  });
  return dictId;
};
