import { Flex, Grid } from '@chakra-ui/react';
import { Field, FormikProvider, useFormik } from 'formik';
import { createTouchedErrors } from 'features/create-touched-errors';
import { Button, Select, SelectOption } from 'components/common';
import React, { useCallback, useEffect, useMemo } from 'react';
import PeriodSelect, {
  LineChartDateForm,
} from 'pages/analyticsReports/CombinedCharts/LineChartComponents/PeriodSelect';
import useGraphDictionaries from 'pages/data/GeoecologicalProcesses/GeologicalProcessesGroundwater/Measuring/Graph/useGraphDictionaries';
import { IPeriod } from 'features/PeriodsButtons/types';
import { createUTCTime } from 'lib/create-date';

export interface IGraphFilterForm extends LineChartDateForm {
  typeId: string | null;
  sourceId: string | null;
  parameterIds: string[] | null;
  selectedRegistryIds?: string[] | null;
}

const initialEmptyValues: IGraphFilterForm = {
  dateFrom: null,
  dateTo: null,
  period: null,
  typeId: null,
  parameterIds: [],
  sourceId: null,
  selectedRegistryIds: null,
};

interface IProps {
  onSubmit: (values: IGraphFilterForm) => void;
  isGraphLoading: boolean;
  periods?: IPeriod[] | null;
  initialFilters?: Partial<IGraphFilterForm>;
  selectedIds?: string[];
}

const GraphFilter = ({ onSubmit, isGraphLoading, periods, initialFilters, selectedIds }: IProps) => {
  // @ts-ignore
  const initialValues: IGraphFilterForm = initialFilters
    ? {
      ...initialEmptyValues,
      ...initialFilters,
      parameterIds: initialFilters.parameterIds || initialEmptyValues.parameterIds,
      dateFrom: initialFilters.dateFrom ? createUTCTime(initialFilters.dateFrom) : null,
      dateTo: initialFilters.dateTo ? createUTCTime(initialFilters.dateTo) : null,
      selectedRegistryIds: selectedIds,
    }
    : initialEmptyValues;

  const formik = useFormik<IGraphFilterForm>({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    initialValues,
    onSubmit: (values) => {
      onSubmit(values);
    },
  });

  const { post, groundwaterDictionary, isDictionariesLoading } = useGraphDictionaries({
    url: '/reporting/v10/graphs/groundwater/dictionaries',
  });

  useEffect(() => {
    if (initialFilters) {
      void loadInitialGraph();
    }
    // eslint-disable-next-line
  }, []);

  const loadInitialGraph = async () => {
    await post({
      dateFrom: formik.values.dateFrom,
      dateTo: formik.values.dateTo,
    });

    await formik.submitForm();
    // выбранные точки показываются только при первом запросе
    await formik.setFieldValue('selectedRegistryIds', null);
  };

  const requestDictionaries = useCallback(() => {
    formik.setFieldValue('typeId', null);
    formik.setFieldValue('sourceId', null);
    formik.setFieldValue('parameterIds', []);

    void post({
      dateFrom: formik.values.dateFrom,
      dateTo: formik.values.dateTo,
    });
  }, [formik, post]);

  const types = useMemo<SelectOption[] | undefined>(
    () =>
      groundwaterDictionary?.map((item) => ({
        name: item.typeName,
        value: item.typeId,
      })),
    [groundwaterDictionary]
  );

  const sources = useMemo<SelectOption[] | undefined>(
    () =>
      groundwaterDictionary
        ?.find((item) => item.typeId === formik.values.typeId)
        ?.sources.map((item) => ({
          name: item.sourceName,
          value: item.sourceId,
        })),
    [formik.values.typeId, groundwaterDictionary]
  );

  const parameters = useMemo<SelectOption[] | undefined>(
    () =>
      groundwaterDictionary
        ?.find((item) => item.typeId === formik.values.typeId)
        ?.sources.find((source) => source.sourceId === formik.values.sourceId)
        ?.parameters.map((item) => ({
          name: item.parameterName,
          value: item.parameterId,
        })),
    [formik.values.sourceId, formik.values.typeId, groundwaterDictionary]
  );

  return (
    <Flex py={2} alignItems="center" width="100%">
      <FormikProvider
        value={{
          ...formik,
          errors: createTouchedErrors(formik.errors, formik.touched),
        }}
      >
        <Flex flexDirection="column" width="100%">
          <PeriodSelect
            disableType
            requestDictionaries={requestDictionaries}
            isDictionariesLoading={isDictionariesLoading}
            periods={periods}
            errors={formik.errors}
          />

          {groundwaterDictionary && (
            <Flex mt={2} alignItems="center" width="100%">
              <Grid
                border="1px solid #000"
                p={2}
                backgroundColor="rgb(223, 237, 214)"
                gap="16px"
                templateColumns="repeat(3, 1fr)"
                mr={2}
              >
                <Field
                  as={Select}
                  name="typeId"
                  label="Тип пункта наблюдения"
                  options={types}
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                    formik.setFieldValue('typeId', e.target.value);
                    formik.setFieldValue('sourceId', null);
                    formik.setFieldValue('parameterIds', []);
                  }}
                  isDisabled={isDictionariesLoading}
                />

                <Field
                  as={Select}
                  name="sourceId"
                  label="Пункт наблюдения"
                  options={sources}
                  isDisabled={!formik.values.typeId}
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                    formik.setFieldValue('sourceId', e.target.value);
                    formik.setFieldValue('parameterIds', []);
                  }}
                />

                <Field
                  as={Select}
                  name="parameterIds"
                  label="Параметр"
                  options={parameters}
                  multiple
                  isDisabled={
                    !formik.values.sourceId
                  }
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                    if (Array.isArray(e.target.value)) {
                      const values = [...e.target.value];
                      if (values.length > 3) {
                        values.splice(0, 1);
                      }

                      formik.setFieldValue('parameterIds', values);
                    }
                  }}
                />
              </Grid>

              <Button
                isDisabled={!formik.values.parameterIds}
                ml="auto"
                onClick={formik.submitForm}
                variant="solid"
                isLoading={isDictionariesLoading || isGraphLoading}
              >
                Построить график
              </Button>
            </Flex>
          )}
        </Flex>
      </FormikProvider>
    </Flex>
  );
};

export default GraphFilter;
