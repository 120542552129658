import React, { useEffect, useMemo, useState } from 'react';
import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { DefaultText, FormHeader, LoaderItem } from 'components/common';
import { EditAppealForm } from './Appeal/EditAppealForm';
import {
  ADD_APPEAL_SUCCESS,
  Appeals,
  CitizensAppealsPath,
  Complaint,
  EDIT_APPEAL_ERROR,
  getComplaintEndpoint,
} from 'pages/citizenAppeal';
import { EditIncidentForm } from './Incident/EditIncidentForm';
import { EditResponseForm } from './Response/EditResponseForm';
import { FormikProvider, useFormik } from 'formik';
import { useRequest } from 'hooks/useRequest';
import { createTouchedErrors } from 'features/create-touched-errors';
import { useNotifications } from 'hooks/useNotifications';
import { ComplaintSchema } from 'models/schemas';
import { useLocation, useParams } from 'react-router-dom';
import { useGoBackWithFallback } from 'lib/utils/goBackWithFallback';
import { useDictionary } from 'hooks/useDictionary';

const initialBradcrumbs = [
  {
    breadcrumb: 'Учет обращений граждан',
    path: CitizensAppealsPath,
  },
  {
    breadcrumb: 'Обращения граждан',
    path: Appeals,
  },
];

interface IProps {
  breadcrumbs?: { path: string; breadcrumb: string }[];
}

const EditAppeal = ({ breadcrumbs = initialBradcrumbs }: IProps) => {
  useUpdateBreadcrumbs(breadcrumbs);
  const { backWithFallback } = useGoBackWithFallback();
  const [incident, setIncident] = useState<Complaint | null>(null);
  const params = useParams<{ id: string }>();
  const { state } = useLocation();
  const isWrite = state?.isWrite;

  const { successNotify, errorNotify } = useNotifications();
  const { dictionaries } = useDictionary({ url: '/appeal/v10/appeals/dictionaries' });

  const { get, result, isLoading } = useRequest<Complaint>(getComplaintEndpoint(params.id));

  const onSuccess = () => {
    successNotify({
      key: 'file/edit/success',
      message: ADD_APPEAL_SUCCESS,
    });
  };
  const onError = () =>
    errorNotify({
      key: 'file/edit/error',
      message: EDIT_APPEAL_ERROR,
    });

  const { put } = useRequest(getComplaintEndpoint(params.id), onSuccess, onError);

  const initialEmptyValues = useMemo(
    () => ({
      assignmentDate: '',
      assignmentDeadline: '',
      assignmentExecution: '',
      assignmentNumber: '',
      assignmentUrl: '',
      complaintAnswerOnPaper: false,
      complaintCitizenAddress: '',
      complaintCitizenEmail: '',
      complaintCitizenFio: '',
      complaintCitizenPhone: '',
      complaintDate: '',
      complaintId: '',
      complaintNumber: '',
      complaintOriginId: '',
      complaintText: '',
      questions: [''],
    }),
    [],
  );

  const initialValues = useMemo(() => {
    const editedValues: any = { ...initialEmptyValues };
    if (result) {
      Object.entries(result).forEach((resItem: [string, any]) => {
        const key = resItem[0];
        const val = resItem[1];
        if (editedValues[key] !== undefined) {
          editedValues[key] = val;
        }
      });
      editedValues.questions = result.questions.map((question: any) => question.questionText);
    }
    return editedValues;
  }, [result, initialEmptyValues]);

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    validationSchema: ComplaintSchema,
    initialValues,
    onSubmit: (values: any) => {
      put(values);
    },
  });

  useEffect(() => {
    if (params.id) {
      get();
    }
  }, [get, params.id]);

  if (isLoading) {
    return <LoaderItem />;
  }

  return (
    <>
      <FormikProvider
        value={{
          ...formik,
          errors: createTouchedErrors(formik.errors, formik.touched),
        }}
      >
        <Tabs>
          <FormHeader
            onBackClick={() => backWithFallback(breadcrumbs[breadcrumbs?.length - 1].path)}
            header="Учет обращений граждан"
          >
            <TabList>
              <Tab>
                <DefaultText>Обращение</DefaultText>
              </Tab>
              <Tab>
                <DefaultText>Происшествие</DefaultText>
              </Tab>
              <Tab>
                <DefaultText>Ответ</DefaultText>
              </Tab>
            </TabList>
          </FormHeader>
          <TabPanels>
            <TabPanel>
              <EditAppealForm dictionaries={dictionaries?.data} isWrite={isWrite} />
            </TabPanel>
            <TabPanel>
              <EditIncidentForm
                dictionaries={dictionaries?.data}
                complaint={result}
                setIncident={setIncident}
                isWrite={isWrite}
              />
            </TabPanel>
            <TabPanel>
              <EditResponseForm
                dictionaries={dictionaries?.data}
                incident={incident || result}
                complaint={result}
                isWrite={isWrite}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </FormikProvider>
    </>
  );
};

export { EditAppeal };
