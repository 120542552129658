import React, { useEffect } from 'react';
import { useFormikContext } from 'formik';

import { AISWeatherSaveItem } from 'hooks/aisWeather/useAISWeatherRecord';

interface RegistryDtosProps {
  children: React.ReactElement;
  index: number;
  stationIndex: number;
  id: string;
  date: string;
}

export default function RegistryDtos({ index, date, stationIndex, id, children }: RegistryDtosProps) {
  const { setFieldValue } = useFormikContext<AISWeatherSaveItem>();

  useEffect(() => {
    setFieldValue(`stations.${stationIndex}.registryDtos.${index}.heightId`, id);
  }, [id, index, setFieldValue, stationIndex]);

  useEffect(() => {
    setFieldValue(`stations.${stationIndex}.registryDtos.${index}.datetime`, date);
  }, [date, index, setFieldValue, stationIndex]);

  return children;
}
