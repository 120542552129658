import React, { memo } from 'react';
import { MaterialTable } from 'features/material-table/MaterialTableMini';
import { useHistory } from 'react-router';
import { SubsystemHeader } from 'components/common';
import { StationsTableFilter } from 'pages/equipment/Stations/components/StationsTable/StationsTableFilter';
import { TFiltersArray } from 'models';
import { useList } from 'hooks';
import { stationsTableColumns } from 'pages/equipment/Stations/components/StationsTable/stationsTableColumns';
import { useCustomRights } from 'hooks/useCustomRights';
import { subsystemCodesMap } from 'constants/subsystemIdsMap';
import { FullPageWrapper } from '../../../../../components/table/FullPageWrapper';
import { DEFAULT_PAGE_SIZE_NEW_TABLE } from '../../../../../constants/tables';

export const StationsTable = memo(() => {
  const { isWrite } = useCustomRights(subsystemCodesMap.equipment.device);
  const history = useHistory();
  const {
    response,
    isLoading,
    toggleOrder,
    onPageChange,
    filterDTO,
    setFilters,
    onSearchText,
    errorText,
    downloadAsFile,
    materialTableProps,
  } = useList('/equipment/v10/equipments/stations/list', {
    initialOrderFieldKey: 'stationNumber',
    saveFiltersState: true,
    defaultPageSize: DEFAULT_PAGE_SIZE_NEW_TABLE,
    resetDataOnNextRequest: true,
  });

  return (
    <FullPageWrapper>
      <SubsystemHeader
        title="Станции"
        addButtonProps={{
          onClick: () => history.push('/equipment/stations/add'),
          disabled: !isWrite,
        }}
        filtersComponent={
          <StationsTableFilter
            onSubmit={(values) => {
              const filters: TFiltersArray = Object.values(values);
              setFilters(filters);
            }}
            isDisabled={isLoading}
            isLoading={isLoading}
            filters={filterDTO.filters}
          />
        }
      />

      <MaterialTable
        filterDTO={filterDTO}
        data={response?.data}
        meta={response?.meta}
        columns={stationsTableColumns}
        toggleOrder={toggleOrder}
        onPageChange={onPageChange}
        actions={[
          {
            type: 'edit',
            onClick: (e, item) => {
              if (Array.isArray(item)) {
                return;
              }

              history.push(`/equipment/stations/edit/${item.stationId}/false`, item);
            },
            hidden: !isWrite,
          },
        ]}
        options={{
          search: true,
          selection: false
        }}
        isLoading={isLoading}
        onSearch={onSearchText}
        errorText={errorText}
        downloadAsFile={downloadAsFile}
        {...materialTableProps}
      />
    </FullPageWrapper>
  );
});
