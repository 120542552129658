import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import appLocale from 'constants/appLocale';
import { SubsystemHeader } from 'components/common';
import React, { FC, useMemo } from 'react';
import { NSIRootPath } from '../../const/routes';
import { useHistory } from 'react-router-dom';
import { GreenspacesTreeFilter } from './Filter';
import { MaterialTable } from 'features/material-table/MaterialTableMini';
import { getSelectOptionsFromDictionaryByName } from 'features/get-select-options-from-dictionary';
import { useList } from 'hooks';
import { GreenspacesDictPath, GreenspacesTreePath } from '../const';
import { subsystemCodesMap } from 'constants/subsystemIdsMap';
import { Column } from '@material-table/core';
import { useCustomRights } from 'hooks/useCustomRights';
import { useDictionary } from 'hooks/useDictionary';
import { FullPageWrapper } from '../../../../components/table/FullPageWrapper';
import { DefaultTableCellSize } from '../../../../components/table/DefaultTableCellSize';
import { DEFAULT_PAGE_SIZE_NEW_TABLE } from 'constants/tables';

export const GreenspacesTree: FC = () => {
  useUpdateBreadcrumbs([
    {
      breadcrumb: appLocale.mainPage.nsi,
      path: NSIRootPath,
    },
    {
      breadcrumb: appLocale.subsystems.greenspacesTree,
      path: GreenspacesTreePath,
    },
  ]);

  const { isWrite } = useCustomRights(subsystemCodesMap.nsi.greenplants.flora);

  const history = useHistory();

  const {
    response,
    setFilters,
    onPageChange,
    isLoading,
    toggleOrder,
    filterDTO,
    onSearchText,
    downloadAsFile,
    errorText,
    materialTableProps,
  } = useList('/nsi/v10/sources/greenplants/list/tree', {
    initialOrderFieldKey: '-areaNumber',
    saveFiltersState: true,
    defaultPageSize: DEFAULT_PAGE_SIZE_NEW_TABLE,
    resetDataOnNextRequest: true,
  });

  const handleAddButtonClick = () => history.push(`${GreenspacesTreePath}/add`);
  const tableEditButtonClick = (id: string) => history.push(`${GreenspacesTreePath}/edit/${id}`);

  const { dictionaries } = useDictionary({ url: GreenspacesDictPath });

  const statuses = useMemo(() => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'statuses'), [dictionaries]);
  const lifeBreeds = useMemo(
    () => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'lifeBreeds'),
    [dictionaries],
  );
  const lifeForms = useMemo(
    () => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'lifeForms'),
    [dictionaries],
  );
  const areaTypes = useMemo(
    () => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'areaTypes'),
    [dictionaries],
  );
  const rayons = useMemo(() => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'rayons'), [dictionaries]);
  const okrugs = useMemo(() => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'okrugs'), [dictionaries]);
  const areaLocations = useMemo(
    () => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'areaLocations'),
    [dictionaries],
  );

  const columns = useMemo<Column<any>[]>(
    () => [
      {
        title: 'Номер участка',
        field: 'areaNumber',
      },
      {
        title: 'Номер насаждения',
        field: 'number',
      },
      {
        title: 'Порода',
        field: 'lifeBreed', // этого поля нет в дто, но сортируется по нему
        render: (row) => lifeBreeds.find((source) => source.value === row.greenPlantLifeBreedId)?.name,
      },
      {
        title: 'Жизненная форма',
        field: 'lifeForm', // этого поля нет в дто, но сортируется по нему
        render: (row) => lifeForms.find((source) => source.value === row.greenPlantLifeFormId)?.name,
      },
      {
        title: 'Округ',
        field: 'okrug', // этого поля нет в дто, но сортируется по нему
        render: (row) => okrugs.find((source) => source.value === row.okrugId)?.name,
      },
      {
        title: 'Район',
        field: 'rayon', // этого поля нет в дто, но сортируется по нему
        render: (row) => rayons.find((source) => source.value === row.rayonId)?.name,
      },
      {
        title: 'Адрес',
        field: 'address',
        render: (row) => <DefaultTableCellSize>{row.address}</DefaultTableCellSize>,
      },
      {
        title: 'Адресный ориентир',
        field: 'landmark',
        render: (row) => <DefaultTableCellSize>{row.landmark}</DefaultTableCellSize>,
      },
      {
        title: 'Месторасположение площадки',
        field: 'areaLocation', // этого поля нет в дто, но сортируется по нему
        render: (row) => areaLocations.find((source) => source.value === row.greenAreaLocationId)?.name,
      },
      {
        title: 'Тип территории насаждений',
        field: 'areaType', // этого поля нет в дто, но сортируется по нему
        render: (row) => areaTypes.find((source) => source.value === row.greenAreaTypeId)?.name,
      },
      {
        title: 'Статус',
        field: 'status',
        render: (row) => statuses.find((source) => source.value === row.status)?.name,
      },
    ],
    [areaLocations, areaTypes, lifeBreeds, lifeForms, okrugs, rayons, statuses],
  );

  return (
    <FullPageWrapper>
      <SubsystemHeader
        title={appLocale.subsystems.greenspacesTree}
        filtersComponent={
          <GreenspacesTreeFilter
            setFilters={setFilters}
            lifeBreeds={lifeBreeds}
            lifeForms={lifeForms}
            statuses={statuses}
            isLoading={isLoading}
            filters={filterDTO.filters}
          />
        }
        addButtonProps={{ onClick: handleAddButtonClick, isDisabled: !isWrite }}
      />

      <MaterialTable
        data={response?.data}
        meta={response?.meta}
        onSearch={onSearchText}
        filterDTO={filterDTO}
        toggleOrder={toggleOrder}
        onPageChange={onPageChange}
        downloadAsFile={downloadAsFile}
        isLoading={isLoading}
        options={{
          search: true,
        }}
        columns={columns}
        actions={[
          {
            type: 'edit',
            onClick: (_e, row) => {
              tableEditButtonClick(row.id);
            },
            disabled: !isWrite,
            hidden: !isWrite,
          },
        ]}
        showTotalCount
        errorText={errorText}
        {...materialTableProps}
      />
    </FullPageWrapper>
  );
};
