import { registerPluginsChartJs } from 'components/common/Graphics/ChartLine/helpers';
import { useChartRef } from 'components/common/Graphics/hooks/useChartRef';
import { useMinMaxDate } from 'components/common/Graphics/hooks/useMinMaxDate';
import { useOptionsForChart } from 'components/common/Graphics/ChartGantt/hooks/useOptionsForChart';
import React, { useEffect, useRef } from 'react';
import { Box } from '@chakra-ui/react';
import { Line } from 'react-chartjs-2';
import { GanttChartPlugins } from 'components/common/Graphics/ChartGantt/plugins';
import { GanttChartActions } from 'components/common/Graphics/ChartGantt/components/GanttChartActions';

interface EquipmentChartProps {
  data: any;
  startDate?: any;
  stopDate?: any;
}

registerPluginsChartJs();

const EquipmentChart = ({ startDate, stopDate, data }: EquipmentChartProps) => {
  const { controlChartRef, chart } = useChartRef();
  const { minDate, maxDate, updateData } = useMinMaxDate();
  const options = useOptionsForChart({ updateData });
  const isWasUsed = useRef(false);

  useEffect(() => {
    if (!isWasUsed.current && startDate && stopDate && chart) {
      // @ts-ignore
      chart.options.scales.x.min = new Date(startDate).getTime();
      // @ts-ignore
      chart.options.scales.x.max = new Date(stopDate).getTime();

      // @ts-ignore
      chart.options.scales.x.time.tooltipFormat = 'DD.MM.YYYY';

      chart.update('active');

      isWasUsed.current = true;
    }
  }, [chart, startDate, stopDate]);

  useEffect(() => {
    if (data) {
      // @ts-ignore
      updateData(chart);
    }
  }, [chart, data, updateData]);

  return (
    <Box p={4}>
      {/* @ts-ignore */}
      <Line id="LineChart" plugins={GanttChartPlugins} ref={controlChartRef} data={data} options={options} />
      <div id="legend-container" />
      <GanttChartActions minDate={minDate} maxDate={maxDate} />
    </Box>
  );
};

export default EquipmentChart;
