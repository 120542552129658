import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import appLocale from 'constants/appLocale';
import { SubsystemHeader } from 'components/common';
import React, { FC, useMemo } from 'react';
import { NSIRootPath } from '../const/routes';
import { AtmosphericAirDictPath, AtmosphericAirRootPath } from './consts';
import { useHistory } from 'react-router-dom';
import { AtmosphereAirFilter } from './Filter';
import { MaterialTable } from 'features/material-table/MaterialTableMini';
import { getSelectOptionsFromDictionaryByName } from 'features/get-select-options-from-dictionary';
import { useList } from 'hooks';
import { subsystemCodesMap } from 'constants/subsystemIdsMap';
import { Column } from '@material-table/core';
import { useCustomRights } from 'hooks/useCustomRights';
import { useDictionary } from 'hooks/useDictionary';
import { DEFAULT_PAGE_SIZE_NEW_TABLE } from '../../../constants/tables';
import { FullPageWrapper } from '../../../components/table/FullPageWrapper';
import { DefaultTableCellSize } from '../../../components/table/DefaultTableCellSize';

export const AtmosphericAir: FC = () => {
  useUpdateBreadcrumbs([
    {
      breadcrumb: appLocale.mainPage.nsi,
      path: NSIRootPath,
    },
    {
      breadcrumb: appLocale.nsi.askza.title,
      path: AtmosphericAirRootPath,
    },
  ]);

  const history = useHistory();

  const { isWrite } = useCustomRights(subsystemCodesMap.nsi.ASKZA);

  const {
    response,
    setFilters,
    onPageChange,
    isLoading,
    toggleOrder,
    filterDTO,
    onSearchText,
    errorText,
    downloadAsFile,
    materialTableProps,
  } = useList('/nsi/v10/sources/atmosphere/list', {
    initialOrderFieldKey: '-startDate',
    saveFiltersState: true,
    defaultPageSize: DEFAULT_PAGE_SIZE_NEW_TABLE,
    resetDataOnNextRequest: true,
  });

  const handleAddButtonClick = () => history.push(`${AtmosphericAirRootPath}/add`);
  const tableEditButtonClick = (id: string) => history.push(`${AtmosphericAirRootPath}/edit/${id}`);

  const { dictionaries } = useDictionary({ url: AtmosphericAirDictPath });

  const districts = useMemo(() => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'rayons'), [dictionaries]);
  const regions = useMemo(() => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'okrugs'), [dictionaries]);
  const statuses = useMemo(() => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'statuses'), [dictionaries]);
  const stations = useMemo(() => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'stations'), [dictionaries]);

  const preparedStations = useMemo(
    () =>
      stations.map((station) => ({
        ...station,
        name: station.name.split(') ')[1],
      })),
    [stations],
  );

  const columns = useMemo<Column<any>[]>(
    () => [
      {
        title: 'Начало действия',
        field: 'startDate',
        render: (row) => (row.startDate ? new Date(row.startDate).toLocaleDateString() : null),
      },
      {
        title: 'Окончание действия',
        field: 'stopDate',
        render: (row) => (row.stopDate ? new Date(row.stopDate).toLocaleDateString() : null),
      },
      {
        title: 'Наименование станции',
        field: 'name',
      },
      {
        title: 'Номер станции',
        field: 'number',
      },
      {
        title: 'Округ',
        field: 'okrugId',
        render: (row) => regions.find((source) => source.value === row.okrugId)?.name,
      },
      {
        title: 'Район',
        field: 'rayonId',
        render: (row) => districts.find((source) => source.value === row.rayonId)?.name,
      },
      {
        title: 'Адрес',
        field: 'address',
        render: (row) => <DefaultTableCellSize>{row.address}</DefaultTableCellSize>,
      },
      {
        title: 'Адресный ориентир',
        field: 'landmark',
        render: (row) => <DefaultTableCellSize>{row.landmark}</DefaultTableCellSize>,
      },
      {
        title: 'Статус',
        field: 'status',
        render: (row) => statuses.find((source) => source.value === row.status)?.name,
      },
    ],
    [districts, regions, statuses],
  );

  return (
    <FullPageWrapper>
      <SubsystemHeader
        title={appLocale.nsi.askza.title}
        addButtonProps={{
          onClick: handleAddButtonClick,
          isDisabled: !isWrite,
        }}
        filtersComponent={
          <AtmosphereAirFilter
            setFilters={setFilters}
            districts={districts}
            regions={regions}
            statuses={statuses}
            stations={preparedStations}
            isLoading={isLoading}
            filters={filterDTO.filters}
          />
        }
      />

      <MaterialTable
        data={response?.data}
        meta={response?.meta}
        onSearch={onSearchText}
        filterDTO={filterDTO}
        toggleOrder={toggleOrder}
        onPageChange={onPageChange}
        isLoading={isLoading}
        downloadAsFile={downloadAsFile}
        options={{
          search: true,
        }}
        columns={columns}
        actions={[
          {
            type: 'edit',
            onClick: (_e, row) => {
              tableEditButtonClick(row.id);
            },
            disabled: !isWrite,
            hidden: !isWrite,
          },
        ]}
        showTotalCount
        errorText={errorText}
        {...materialTableProps}
      />
    </FullPageWrapper>
  );
};
