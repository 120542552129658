import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { COMMON_LOCALE } from 'constants/common';
import { Button, Checkbox, Input, NumberInput, Select } from 'components/common';
import { Field, useFormikContext } from 'formik';
import { DeviceModelParameter, Options } from 'pages/equipment/Models/types';
import { parametersLocale } from '../../locale';
import { Flex, Heading } from '@chakra-ui/react';
import { TDictionariesArray } from 'models';
import { getSelectOptionsFromDictionaryByName } from 'features/get-select-options-from-dictionary';

interface FormFieldsProps {
  onCancel: () => void;
  parametersOptions?: Options;
  dictionaries: { data: TDictionariesArray<string> } | null;
}

export const FormFields: React.FC<FormFieldsProps> = (props) => {
  const [minMaxDisabled, setMinMaxDisabled] = useState(false);
  const { handleSubmit, values, errors, setFieldValue, initialValues, setFieldTouched } =
    useFormikContext<DeviceModelParameter>();

  const purpose = useMemo(
    () => getSelectOptionsFromDictionaryByName(props.dictionaries?.data, 'purpose', false, initialValues.purposeId),
    [initialValues.purposeId, props.dictionaries?.data],
  );

  const units = useMemo(
    () => getSelectOptionsFromDictionaryByName(props.dictionaries?.data, 'units'),
    [props.dictionaries?.data],
  );

  const onSubmit = useCallback(() => {
    handleSubmit();
  }, [handleSubmit]);

  // min/max is available in case AVG is set
  useEffect(() => {
    setMinMaxDisabled(!values.averagePeriod);
    if (!values?.averagePeriod) {
      setFieldValue('genMin', null);
      setFieldValue('genMax', null);
    }
  }, [values, setFieldValue]);

  return (
    <>
      <Field as={NumberInput} name="orderNo" label={parametersLocale.orderNo} error={errors.orderNo} />

      <Field
        as={Select}
        name="parameterId"
        label={parametersLocale.parameterName}
        options={props.parametersOptions}
        error={errors.parameterId}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          const code = props.parametersOptions?.find((item) => item.value === e.target.value)?.title || '';
          setFieldValue('parameterId', e.target.value);
          setFieldValue('parameterCode', code);
          setTimeout(() => {
            setFieldTouched('parameterId');
            setFieldTouched('parameterCode');
          }, 0);
        }}
      />

      {/* just for view. not editable */}
      <Field
        as={Input}
        name="parameterCode"
        label={parametersLocale.parameterCode}
        isDisabled
        error={errors.parameterCode}
      />

      <Field as={Select} name="purposeId" label={parametersLocale.purpose} options={purpose} error={errors.purposeId} />

      <Field as={Select} name="unitId" label={parametersLocale.unit} options={units} error={errors.unitId} />

      <Field as={Input} name="accuracy" label={parametersLocale.accuracy} error={errors.accuracy} />

      <Field
        as={NumberInput}
        name="averagePeriod"
        label={parametersLocale.averagePeriod}
        error={errors.averagePeriod}
      />

      <Flex>
        <Field
          as={Checkbox}
          name="genMin"
          label={parametersLocale.genMin}
          isDisabled={minMaxDisabled}
          checked={values.genMin}
          error={errors.genMin}
        />
        <Field
          as={Checkbox}
          name="genMax"
          label={parametersLocale.genMax}
          isDisabled={minMaxDisabled}
          checked={values.genMax}
          error={errors.genMax}
        />
      </Flex>

      <Flex flexDirection="column">
        <Heading size="xs" width="100%" mb={1}>
          Диапазон измеряемых значений
        </Heading>

        <Flex style={{ gap: '16px' }}>
          <Field as={Input} type="number" name="valueFrom" label={parametersLocale.from} error={errors.valueFrom} />

          <Field as={Input} type="number" name="valueTo" label={parametersLocale.to} error={errors.valueTo} />
        </Flex>
      </Flex>

      <Flex>
        <Button onClick={onSubmit}>{COMMON_LOCALE.save}</Button>
        <Button onClick={props.onCancel} variant="ghost" marginRight={5}>
          {COMMON_LOCALE.cancel}
        </Button>
      </Flex>
    </>
  );
};
