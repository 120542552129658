import * as yup from 'yup';
import { ValidationError } from 'yup';
import { lessThanDate, maxSymbols, moreThanDate, requiredError } from '../constans';
import moment from 'moment';

export const AnalyticsPelAiAtmosphereSchema = yup.object()
  .shape({
    protocolNumber: yup.string()
      .required(requiredError)
      .nullable()
      .max(15, maxSymbols(15)),
    protocolDate: yup.string()
      .typeError(requiredError)
      .required(requiredError),
    customerId: yup.string()
      .required(requiredError)
      .nullable(),
    researchObject: yup.string()
      .required(requiredError)
      .nullable()
      .max(255, maxSymbols(255)),
    address: yup.string()
      .required(requiredError)
      .nullable()
      .max(511, maxSymbols(511)),
    samplePlace: yup.string()
      .required(requiredError)
      .nullable()
      .max(255, maxSymbols(255)),
    contractorId: yup.string()
      .required(requiredError)
      .nullable(),
    methodology: yup.string()
      .required(requiredError)
      .nullable()
      .max(511, maxSymbols(511)),
    sampleActNumber: yup.string()
      .required(requiredError)
      .nullable()
      .max(63, maxSymbols(63)),
    sampleRegNumber: yup.string()
      .required(requiredError)
      .nullable()
      .max(15, maxSymbols(15)),
    sampleStartDatetime: yup.string()
      .typeError(requiredError)
      .required(requiredError),
    sampleEndDatetime: yup.string()
      .typeError(requiredError)
      .required(requiredError),
    sampleReceiveDatetime: yup.string()
      .typeError(requiredError)
      .required(requiredError),
    researchEndDatetime: yup.string()
      .typeError(requiredError)
      .required(requiredError),
    researchStartDatetime: yup.string()
      .typeError(requiredError)
      .required(requiredError),
    extraInfoValue: yup.string()
      .max(511, maxSymbols(511)),
    researchBasis: yup.string()
      .max(255, maxSymbols(255)),
    supportingDocuments: yup.string()
      .nullable()
      .max(255, maxSymbols(255)),
    status: yup.string()
      .required(requiredError)
      .nullable(),
    sign: yup
      .object()
      .shape({
        signers: yup.array()
          .of(
            yup.object()
              .shape({
                action: yup.string()
                  .required(requiredError)
                  .nullable(),
                position: yup.string()
                  .required(requiredError)
                  .nullable()
                  .max(511, maxSymbols(511)),
                name: yup.string()
                  .required(requiredError)
                  .nullable()
                  .max(255, maxSymbols(255)),
              }),
          ),
      })
      .nullable(),
  })
  .test((values) => {
    const dateErrors: ValidationError[] = [];
    if (values.researchStartDatetime && values.researchEndDatetime && moment(values.researchStartDatetime)
      .isAfter(values.researchEndDatetime)) {
      dateErrors.push(
        new ValidationError(lessThanDate('даты начала исследований'), '', 'researchEndDatetime'),
        new ValidationError(moreThanDate('даты окончания исследований'), '', 'researchStartDatetime'),
      );
    }

    if (values.sampleStartDatetime && values.sampleEndDatetime && moment(values.sampleStartDatetime)
      .isAfter(values.sampleEndDatetime)) {
      dateErrors.push(
        new ValidationError(lessThanDate('даты начала исследований'), '', 'sampleEndDatetime'),
        new ValidationError(moreThanDate('даты окончания исследований'), '', 'sampleStartDatetime'),
      );
    }

    if (dateErrors.length > 0) {
      return new ValidationError(dateErrors);
    }
    return true;
  });
