import * as yup from 'yup';
import { numberError, requiredError } from '../constans';

export const GroundwaterMeasuringSchema = yup.object().shape({
  measuringDate: yup.string().typeError(requiredError).required(requiredError),
  sourceId: yup.string().typeError(requiredError).required(requiredError),
  waterSourceType: yup.string().required(requiredError),
  temperature: yup
    .object()
    .shape({
      measuringId: yup.string(),
      value: yup.number().notOneOf([NaN], numberError).typeError(numberError).nullable(),
    })
    .nullable(),
  depth: yup
    .object()
    .shape({
      measuringId: yup.string(),
      value: yup
        .number()
        .moreThan(-1, 'Должно быть положительным')
        .notOneOf([NaN], numberError)
        .typeError(numberError)
        .nullable(),
    })
    .nullable(),
  debit: yup
    .object()
    .shape({
      measuringId: yup.string(),
      value: yup.number().notOneOf([NaN], numberError).typeError(numberError).nullable(),
    })
    .nullable(),
});

export const GroundwaterMeasuringEditSchema = yup.object().shape({
  measuringDate: yup.string().required(requiredError),
  sourceCode: yup.string().required(requiredError),
  waterSourceType: yup.string().required(requiredError),
  temperature: yup.object().shape({
    measuringId: yup.string(),
  }),
  depth: yup.object().shape({
    measuringId: yup.string(),
  }),
  debit: yup.object().shape({
    measuringId: yup.string(),
  }),
});
