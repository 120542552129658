import TableCell from '@material-ui/core/TableCell';
import { TableSortLabel } from '@material-ui/core';
import TableRow from '@material-ui/core/TableRow';
import React from 'react';

interface IProps {
  toggleOrder: (key: string) => void;
  orders: string[];
  noise?: boolean;
  PEL?: boolean;
}

export const RaidTableHead = ({ orders, toggleOrder, noise, PEL }: IProps) => {
  const DirectionDetect = (order: string, name: string) => {
    if (order === name) {
      return 'asc';
    }
    if (order === `-${name}`) {
      return 'desc';
    }
  };

  return (
    <TableRow>
      <TableCell>
        <TableSortLabel
          hideSortIcon
          active={!!DirectionDetect(orders[0], 'day')}
          direction={DirectionDetect(orders[0], 'day')}
          onClick={() => toggleOrder('day')}
        >
          День недели
        </TableSortLabel>
      </TableCell>
      <TableCell>
        <TableSortLabel
          hideSortIcon
          active={!!DirectionDetect(orders[0], 'startDate')}
          direction={DirectionDetect(orders[0], 'startDate')}
          onClick={() => toggleOrder('startDate')}
        >
          Дата
        </TableSortLabel>
      </TableCell>
      <TableCell>Время суток</TableCell>
      <TableCell>№ рейда</TableCell>
      <TableCell>№ задания</TableCell>
      <TableCell>Округ</TableCell>
      <TableCell>Район</TableCell>
      <TableCell>Территория</TableCell>
      <TableCell>Основание</TableCell>
      <TableCell>Статус</TableCell>
      {!PEL ? (
        <>
          <TableCell>Адрес точки</TableCell>
          {!noise ? (
            <>
              <TableCell>Протокол ПЭЛ</TableCell>
              <TableCell>протокол АИ</TableCell>
              <TableCell>Внутренний номер протокола</TableCell>
            </>
          ) : (
            <>
              <TableCell>Номер протокола</TableCell>
            </>
          )}
          <TableCell colSpan={4} />
        </>
      ) : (
        <>
          <TableCell colSpan={4} />
        </>
      )}
    </TableRow>
  );
};
