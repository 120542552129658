export const paramIds = {
  stationWork: '32895435-2b9e-4ac9-a534-be38da052cce',
  oktaveLevel: 'c8732812-9bb1-42da-b73d-7fa66fa66ebc',
  measurmentLevel: 'f3f40035-8ef3-4486-b2a3-122f1a9b7190',
  ipAddress: 'ef29192e-2582-40c5-bccf-8bf3d3269b4b',
  tempInside: '005e6f18-e82d-4fba-a5f4-d98ab641c32e',
  voltage: '9186bf51-6ed0-4c3e-8892-db5155d65b4f',
  firstChannel: '5c02e33a-9835-42f9-af36-1e972038c03c',
  secondChannel: '6e7dfd9c-0c84-4f1e-9f14-942e4d8fe32d',
  thirdChannel: 'fb1e827a-fbee-4d93-8d7a-fdb5691aea98',
  fourthChannel: '691d48be-4712-4502-9567-aa14b9249f22',
  doorOpening: 'f4a28806-c65d-4d11-96c1-1c316b545ea4'
};
