import { Button, LoaderItem } from 'components/common';
import { Box, Flex } from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import React from 'react';
import { RoleWithRightsItem } from '../../models/RoleItem';
import { RoleRights } from './RoleRights';
import { SourceItem } from 'pages/admin/models/SourceItem';

export interface FieldFormProps {
  onCancel: () => void;
  isLoading: boolean;
  selectedSubsystem: string;
  tableRef: any;
  url: string;
}

const FieldsForm = ({ onCancel, isLoading, selectedSubsystem, tableRef, url }: FieldFormProps) => {
  const { setFieldValue, values, handleSubmit } = useFormikContext<RoleWithRightsItem>();

  if (isLoading) {
    return <LoaderItem />;
  }

  const onChangeRights = (data: SourceItem[], uncheckedData: SourceItem[]) => {
    const uncheckedDataIds = uncheckedData.map((r) => r.id);
    const checkedDataIds = data.map((r) => r.id);
    const rightsToUpdate = [...values.rights, ...checkedDataIds].filter((id) => !uncheckedDataIds.includes(id));
    setFieldValue('rights', Array.from(new Set(rightsToUpdate)));
  };

  return (
    <Box flexDirection="column">
      <RoleRights
        tableRef={tableRef}
        selectedSubsystem={selectedSubsystem}
        values={values}
        isLoading={isLoading}
        onSelectedRows={onChangeRights}
        url={url}
      />
      <Flex>
        <Button onClick={() => handleSubmit()} variant="solid">
          Сохранить
        </Button>
        <Button onClick={onCancel} marginLeft={10}>
          Отмена
        </Button>
      </Flex>
    </Box>
  );
};

export default React.memo(FieldsForm);
