import React from 'react';
import { PageHeader, RoutesList } from 'components/common';
import appLocale from 'constants/appLocale';
import { ReagentsGreenPlantsRootPath, ReagentsRootPath, ReagentsSoilRootPath } from 'pages/data/PGR/consts';
import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import { COMMON_LOCALE } from 'constants/common';
import { Flex, Heading } from '@chakra-ui/react';

export const PGRMenu: React.FC = () => {
  useUpdateBreadcrumbs([
    { breadcrumb: COMMON_LOCALE.data, path: '/data' },
    {
      breadcrumb: appLocale.subsystems.reagents,
      path: ReagentsRootPath,
    },
  ]);

  return (
    <Flex flexDirection="column">
      <PageHeader>
        <Heading>{appLocale.subsystems.reagents}</Heading>
      </PageHeader>
      <RoutesList
        routes={[
          {
            breadcrumb: 'Воздействие на зеленые насаждения',
            path: ReagentsGreenPlantsRootPath,
          },
          {
            path: `${ReagentsSoilRootPath}/main-data`,
            breadcrumb: 'Воздействие на почву',
          },
          {
            path: `${ReagentsRootPath}/snow/main-data`,
            breadcrumb: 'Воздействие на снег',
          },
        ]}
      />
    </Flex>
  );
};
