import React, { useCallback, useMemo } from 'react';
import * as yup from 'yup';
import { DeviceFormFields } from 'pages/equipment/Devices/components/DeviceForm/DeviceFormFields';
import { ERRORS } from 'constants/errors';
import { useDictionary } from 'hooks/useDictionary';
import { useHistory } from 'react-router-dom';
import { useFormikContext } from 'formik';
import { Device } from '../../types';
import { getSelectOptionsFromDictionaryByName } from 'features/get-select-options-from-dictionary';

export const validationSchema: any = yup.object().shape({
  equipmentSerial: yup.string().required(ERRORS.required),
  equipmentInvent: yup.string().required(ERRORS.required),
  status: yup.string().nullable().required(ERRORS.required),
  deviceName: yup.string().nullable().required(ERRORS.required),
});

interface DeviceFormProps {
  isDisabled?: boolean;
}

export const DeviceForm: React.FC<DeviceFormProps> = ({ isDisabled = false }) => {
  const { push } = useHistory();
  const { initialValues } = useFormikContext<Device>();
  const { dictionaries } = useDictionary({
    url: '/equipment/v10/equipments/instruments/dictionaries',
  });
  const models = useMemo(
    () => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'models'),
    [dictionaries?.data],
  );
  const statuses = useMemo(
    () => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'statuses', false, initialValues.status),
    [dictionaries?.data, initialValues.status],
  );
  const stations = useMemo(
    () => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'stations'),
    [dictionaries?.data],
  );
  const suppliers = useMemo(
    () => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'suppliers', false, initialValues.supplierId),
    [dictionaries?.data, initialValues.supplierId],
  );

  const onCancel = useCallback(() => {
    push('/equipment/devices');
  }, [push]);

  return (
    <DeviceFormFields
      onCancel={onCancel}
      isDisabled={isDisabled}
      modelsOptions={models}
      statusesOptions={statuses}
      stations={stations}
      suppliers={suppliers}
    />
  );
};
