import React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

interface Props extends IconProps {
  icon: React.ReactNode;
}

export const StyledIcon: React.FC<Props> = (props) => (
  <Icon boxSize={6} {...props}>
    {props.icon}
  </Icon>
);
