import { Field, FormikProvider, useFormik } from 'formik';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { DateInput, IFilterValues, Select, useSetFiltersFromGraphic } from 'components/common';
import { Flex, Grid } from '@chakra-ui/react';
import { createDateFromResponse, createDateToRequestForFilter, createDateToRequestWithTimezone } from 'lib/create-date';
import { TDictionariesArray } from 'models/dictionaries';
import { getSelectOptionsFromDictionaryByName } from 'features/get-select-options-from-dictionary';
import combineFilters from 'lib/utils/combineFilters';
import { IFilterItem } from 'models';
import { IPeriod } from 'features/PeriodsButtons/types';
import { PeriodsButtons } from 'features/PeriodsButtons';
import { usePeriods } from 'features/PeriodsButtons/usePeriods';
import { FiltersDatepickersSchema } from 'models/schemas/filtersDatepickersSchema/FiltersDatepickersSchema';
import FilterFormControls from 'components/form/FilterFormControls';

interface FilterProps {
  isLoading?: boolean;
  isDisabled?: boolean;
  filtersFromGraphic: Partial<IFilterValues> | null;
  setFiltersFromGraphic: (value: Partial<IFilterValues> | null) => void;
  dictionaries: {
    data: TDictionariesArray;
  } | null;
  onSubmit: (values: any) => void;
  filters: IFilterItem[];
  periods: IPeriod[] | null;
}

const initialValues: Record<string, IFilterItem> = {
  protocolDateMin: {
    key: 'protocolDate',
    operation: 'GTE',
    value: null,
  },
  protocolDateMax: {
    key: 'protocolDate',
    operation: 'LTE',
    value: null,
  },
  regionId: {
    key: 'regionId',
    operation: 'EQ',
    value: null,
  },
  districtId: {
    key: 'districtId',
    operation: 'EQ',
    value: null,
  },
  funcZoneId: {
    key: 'funcZoneId',
    operation: 'EQ',
    value: null,
  },
  // showDisabled - value равно '' если чекбокс включен и нужно получить с
  // Backend все значения (этот объект удалиться при отправке)
  // value равно false - если нужно показать только неудаленные записи
  showDisabled: {
    key: 'deleted',
    operation: 'EQ',
    value: false,
  },
  periodId: {
    key: 'periodId',
    operation: 'EQ',
    value: null,
  },
};

export const SoilFilter: FC<FilterProps> = ({
  setFiltersFromGraphic,
  filtersFromGraphic,
  isLoading,
  isDisabled,
  onSubmit,
  dictionaries,
  children,
  filters,
  periods,
  ...props
}) => {
  const savedPeriodId = useMemo(
    () => (filters.find((item) => item.key === 'periodId')?.value as string) || '',
    [filters]
  );

  const [periodId, setPeriodId] = useState<string>(savedPeriodId);

  const { getDatesFromPeriod, dates } = usePeriods(periodId, periods);

  const submitPeriod = (id: string) => {
    const dates = getDatesFromPeriod(id);
    setFieldValue('periodId.value', id);
    setFieldValue('protocolDateMin.value', createDateToRequestWithTimezone(dates[0]));
    setFieldValue('protocolDateMax.value', createDateToRequestWithTimezone(dates[1]));
    setFieldTouched('protocolDateMin.value');
    setPeriodId(id);
  };

  const {
    values,
    errors,
    getFieldMeta,
    getFieldHelpers,
    getFieldProps,
    handleReset,
    submitForm,
    setFieldValue,
    setFieldTouched,
    ...formik
  } = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    validationSchema: FiltersDatepickersSchema([
      { dateFromFieldName: 'protocolDateMin', dateToFieldName: 'protocolDateMax' },
    ]),
    initialValues,
    onSubmit,
  });

  useEffect(() => {
    const initialFilters = combineFilters(initialValues, filters);
    Object.keys(initialFilters).forEach((key) => {
      setFieldValue(`${key}.value`, initialFilters[key].value);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const regions = useMemo(() => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'regions'), [dictionaries]);

  const districts = useMemo(
    () => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'districts'),
    [dictionaries]
  );

  const functionalZones = useMemo(
    () => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'functionalZones'),
    [dictionaries]
  );

  useSetFiltersFromGraphic({
    dateKeyMin: 'protocolDateMin.value',
    dateKeyMax: 'protocolDateMax.value',
    setFiltersFromGraphic,
    filtersFromGraphic,
    setFieldValue,
  });

  return (
    <FormikProvider
      value={{
        values,
        errors,
        getFieldMeta,
        getFieldHelpers,
        getFieldProps,
        handleReset,
        submitForm,
        setFieldValue,
        setFieldTouched,
        ...formik,
      }}
    >
      <Flex flexDirection="row" justifyContent="space-between">
        <Grid gap="8px" gridTemplateColumns="repeat(4, 175px) 225px" {...props}>
          <Field
            as={DateInput}
            maxDate={dates?.[1]}
            minDate={dates?.[0]}
            isDisabled={!periodId}
            name="protocolDateMin.value"
            label="От"
            value={
              typeof values.protocolDateMin?.value === 'string' && createDateFromResponse(values.protocolDateMin.value)
            }
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFieldValue('protocolDateMin.value', createDateToRequestForFilter(e.target.value));
            }}
            showTimeInput={false}
            error={errors.protocolDateMin?.value}
          />
          <Field
            as={DateInput}
            maxDate={dates?.[1]}
            minDate={dates?.[0]}
            isDisabled={!periodId}
            name="protocolDateMax.value"
            label="До"
            value={
              typeof values.protocolDateMax?.value === 'string' && createDateFromResponse(values.protocolDateMax.value)
            }
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFieldValue('protocolDateMax.value', createDateToRequestForFilter(e.target.value, 'end'));
            }}
            showTimeInput={false}
            error={errors.protocolDateMax?.value}
          />
          <Field
            as={Select}
            name="regionId.value"
            label="Округ"
            options={regions}
            value={values.regionId.value}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFieldValue('regionId.value', e.target.value);
            }}
            error={errors.regionId?.value}
          />
          <Field
            as={Select}
            name="districtId.value"
            label="Район"
            options={districts}
            value={values.districtId.value}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFieldValue('districtId.value', e.target.value);
            }}
            error={errors.districtId?.value}
          />
          <Field
            as={Select}
            name="funcZoneId.value"
            label="Функциональная зона"
            options={functionalZones}
            value={values.funcZoneId.value}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFieldValue('funcZoneId.value', e.target.value);
            }}
            error={errors.funcZoneId?.value}
          />
          {children}
        </Grid>

        <FilterFormControls
          onReset={(e) => {
            handleReset(e);
            setPeriodId('');
            setTimeout(submitForm, 0);
          }}
          onSubmit={submitForm}
          isDisabled={isDisabled}
          isLoading={isLoading}
        />
      </Flex>

      <PeriodsButtons periodId={periodId} periods={periods} submitPeriod={submitPeriod} />
    </FormikProvider>
  );
};
