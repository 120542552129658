import { Flex } from '@chakra-ui/react';
import moment from 'moment';

// todo check similar components
export const DateTimeTableCell = ({ datestring, inline }: { datestring: string, inline?: boolean }) => {
  const dateMoment = moment(datestring);
  const time = datestring ? dateMoment.format('HH:mm:ss') : '';
  const date = datestring ? dateMoment.format('DD.MM.YYYY') : '';

  if (inline) {
    return <>{date} {time}</>;
  }

  return (
    <Flex flexDirection="column">
      <span>{time}</span>
      <span>{date}</span>
    </Flex>
  );
};
