const root = '/equipment';
const reportsRoot = `${root}/reports`;
const stationsRoot = `${root}/stations`;
const modelsRoot = `${root}/models`;
const devicesRoot = `${root}/devices`;

const createBaseSetOfRoutes = (root: string) => ({
  root,
  edit: `${root}/edit/:id`,
  getEditRoute: (id: string | number) => `${root}/edit/:${id}`,
  add: `${root}/add`
});

export const EquipmentsAppRoutes = {
  root,
  models: createBaseSetOfRoutes(modelsRoot),
  devices: createBaseSetOfRoutes(devicesRoot),
  stations: createBaseSetOfRoutes(stationsRoot),
  reports: {
    root: reportsRoot,
    table: `${reportsRoot}/table`,
    diagram: `${reportsRoot}/diagram`,
    diagramDevices: `${reportsRoot}/diagram/devices`,
    diagramStations: `${reportsRoot}/diagram/stations`
  }
} as const;
