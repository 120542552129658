import { Flex } from '@chakra-ui/react';
import { baseAdminRightUrl } from './consts';
import { useHistory } from 'react-router';
import { usePaginatedLoader } from '../typedLoader';
import { RightItem } from '../models/RightItem';
import { CustomTable, tableIcons } from '../components/CustomTable';

export const RightList: React.FC = () => {
  const history = useHistory();

  const { get: getRightList } = usePaginatedLoader<RightItem>(baseAdminRightUrl);

  const columns = [
    {
      title: 'Внешний код',
      field: 'code',
      defaultSort: 'asc',
    },
    {
      title: 'Наименование',
      field: 'name',
    },
    {
      title: 'Описание',
      field: 'description',
    },
    {
      title: 'Действие',
      field: 'actionName',
    },
    {
      title: 'Объект',
      field: 'objectName',
    },
    {
      title: 'Тип объекта',
      field: 'objectTypeName',
    },
    {
      title: 'Подсистема',
      field: 'subsystemName',
    },
  ];

  return (
    <Flex flexDirection="column">
      <CustomTable
        queryFunc={getRightList}
        onRemove={(item: any) => ({
          url: `${baseAdminRightUrl}/${item.id}`,
        })}
        options={{
          search: true,
          showTitle: true,
          toolbar: true,
          paging: true,
          selection: false,
          editable: true,
          canRemove: true,
        }}
        title="Список привилегий"
        columns={columns}
        actions={[
          {
            icon: tableIcons.Edit,
            tooltip: 'Редактировать',
            onClick: (event: any, item: any) => history.push(`/admin/right/edit/${item.id}`),
          },
        ]}
      />
    </Flex>
  );
};
