export const pdkRootPath = '/nsi/dictionary/pdk';
export const substancesPath = `${pdkRootPath}/substances`;
export const consentrationPath = `${pdkRootPath}/concentration`;

export const addSubstancePath = `${substancesPath}/add`;
export const editSubstancePath = `${substancesPath}/edit/:id`;
export const addConcentrationPath = `${consentrationPath}/add`;
export const editConcentrationPath = `${consentrationPath}/edit/:id`;

const pdkApiRoot = 'nsi/v10/pdk';
export const substanceApiPath = `${pdkApiRoot}/substance`;
export const substanceListApiPath = `${substanceApiPath}/list`;

export const concentrationApiPath = `${pdkApiRoot}/substance/pdk`;
export const concentrationListApi = `${concentrationApiPath}/list`;
