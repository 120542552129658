import { useDisclosure } from 'hooks/index';
import { IFilterValues, IFilterValuesDefault } from 'components/common';
import { Flex } from '@chakra-ui/react';
import React, { useCallback, useState } from 'react';
import { useDataForChart } from 'components/common/Graphics/ChartLine/hooks/useDataForChart';
import { createDateToRequestWithTimezone } from 'lib/create-date';
import { Chart } from 'features/Filter/Chart';
import { IFilterItem } from 'models';

interface IUseGraphFilter<T> {
  filters: IFilterItem<T>[];
  setFilters: (filters: IFilterItem<T>[]) => void;
  graphFilters: IFilterValuesDefault;
  selectionKey?: T;
  selectedKey?: 'registryIds' | 'valueIds';
}

function useGraphFilter<T>({ filters, graphFilters, setFilters, selectionKey, selectedKey = 'registryIds' }: IUseGraphFilter<T>) {
  const graphDisclosure = useDisclosure();
  const [initialFilters, setInitialFilters] = useState<IFilterItem<T>[] | null>(null);
  const [filtersFromGraphic, setFiltersFromGraphic] = useState<Partial<IFilterValues> | null>(null);
  const selectedIds = selectionKey ? filters.find((item) => item.key === selectionKey)?.value : undefined;

  const {
    data,
    isLoading: isGraphLoading,
    setData,
  } = useDataForChart({
    // @ts-ignore
    filters: graphFilters,
    shouldUpdate: graphDisclosure.isOpen,
    selectedIds: Array.isArray(selectedIds) ? selectedIds.map(String) : undefined,
    selectedKey,
  });

  const onGraphFilterChange = useCallback((data: [number, number]) => {
    setFiltersFromGraphic({
      startDate: createDateToRequestWithTimezone(data[0]) || undefined,
      stopDate: createDateToRequestWithTimezone(data[1]) || undefined,
    });
  }, []);

  const graphOnOpen = useCallback(() => {
    graphDisclosure.onOpen();
  }, [graphDisclosure]);

  const onInitialFilter = useCallback(() => {
    if (initialFilters) {
      setFilters(initialFilters);
    }
  }, [initialFilters, setFilters]);

  const chartOnClose = useCallback(() => {
    graphDisclosure.onClose();
    setData(null);
  }, [graphDisclosure, setData]);

  const GraphFilter = graphDisclosure.isOpen ? (
    <Flex flexDirection="column" minHeight={0} p="8px 16px" flexGrow={1}>
      <Chart
        onClose={chartOnClose}
        filters={graphFilters}
        onGraphFilterChange={onGraphFilterChange}
        data={data}
        isLoading={isGraphLoading}
        onInitialFilter={initialFilters ? onInitialFilter : undefined}
      />
    </Flex>
  ) : null;

  return {
    onGraphFilterChange,
    GraphFilter,
    graphOnOpen,
    graphDisclosure,
    filtersFromGraphic,
    setFiltersFromGraphic,
    isGraphLoading,
    setGraphInitialFilters: setInitialFilters,
  };
}

export { useGraphFilter };
