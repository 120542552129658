import { IFilterItem, TDictionariesArray } from 'models';
import { Field, useFormikContext } from 'formik';
import React, { useMemo } from 'react';
import { getSelectOptionsFromDictionaryByName } from 'features/get-select-options-from-dictionary';
import { Checkbox, Input, Select } from 'components/common';
import { Grid, GridItem } from '@chakra-ui/react';
import FilterFormControls from 'components/form/FilterFormControls';

interface IProps {
  dictionaries: { data: TDictionariesArray } | null;
}

export const DataPatternFormFields = ({ dictionaries }: IProps) => {
  const { submitForm, handleReset, values } = useFormikContext<{ [p: string]: IFilterItem }>();
  const subsystems = useMemo(
    () => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'subsystems'),
    [dictionaries],
  );
  return (
    <>
      <Grid gap={5} templateColumns="repeat(2, 1fr)">
        <Field as={Input} name="templateName.value" label="Наименование" />

        <Field as={Select} name="subsystemId.value" label="Подсистема" options={subsystems} />

        <Field as={Checkbox} name="deleted.value" checked={values.deleted.value} label="Показать удаленные" />

        <GridItem rowStart={1} colStart={3}>
          <FilterFormControls
            onReset={(e) => {
              handleReset(e);
              setTimeout(submitForm, 0);
            }}
            onSubmit={submitForm}
          />
        </GridItem>
      </Grid>
    </>
  );
};
