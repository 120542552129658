import { Grid, GridItem } from '@chakra-ui/react';
import { Field, FormikContextType, FormikErrors, FormikValues, useFormikContext } from 'formik';
import { Input, Select } from 'components/common';
import React from 'react';
import { TDictionaryItemDictionary } from 'models';

interface IProps {
  statuses: TDictionaryItemDictionary[];
  aquifers: TDictionaryItemDictionary[];
  waterTypes: TDictionaryItemDictionary[];
  isEditMode: boolean;
}

export const GeneralInformation = ({ statuses, aquifers, waterTypes, isEditMode }: IProps) => {
  const { errors }: FormikContextType<FormikErrors<FormikValues>> = useFormikContext();
  return (
    <Grid gap={2} templateRows="repeat(6, 1fr)" templateColumns="repeat(6, 2fr)">
      <GridItem rowStart={1} rowEnd={1} colStart={1} colSpan={3}>
        <Field as={Select} name="objectType" label="Тип объекта" options={waterTypes} error={errors?.objectType} />
      </GridItem>
      <GridItem rowStart={2} rowEnd={2} colStart={1} colSpan={3}>
        <Field as={Input} name="name" label="Название объекта" />
      </GridItem>
      <GridItem rowStart={3} rowEnd={3} colStart={1} colSpan={3}>
        <Field as={Input} label="Номер объекта" name="number" error={errors?.number} isDisabled={isEditMode} />
      </GridItem>
      <GridItem rowStart={4} rowEnd={4} colStart={1} colSpan={3}>
        <Field as={Select} name="aquiferId" label="Водоносный горизонт" options={aquifers} />
      </GridItem>
      <GridItem rowStart={5} rowEnd={5} colStart={1} colSpan={3}>
        <Field as={Select} name="status" label="Статус" options={statuses} error={errors?.status} />
      </GridItem>
    </Grid>
  );
};
