import { Field, FormikProvider, useFormik } from 'formik';

import { ContentContainer, DateInput, Select } from 'components/common';
import { createDateFromResponse, createDateToRequestWithTimezone } from 'lib/create-date';
import { COMMON_LOCALE } from 'constants/common';
import appLocale from 'constants/appLocale';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { Flex, Grid, SimpleGrid } from '@chakra-ui/react';
import { IPeriod } from 'features/PeriodsButtons/types';
import { PeriodsButtons } from 'features/PeriodsButtons';
import { usePeriods } from 'features/PeriodsButtons/usePeriods';
import combineFilters from 'lib/utils/combineFilters';
import { IFilterItem, TDictionaryItemDictionary, TFiltersArray } from 'models';
import { MomentInput } from 'moment';
import { FiltersDatepickersSchema } from 'models/schemas/filtersDatepickersSchema/FiltersDatepickersSchema';
import FilterFormControls from 'components/form/FilterFormControls';

const initialEmptyValues: Record<string, IFilterItem<string>> = {
  protocolDateMin: {
    key: 'date',
    operation: 'GTE',
    value: null,
  },
  protocolDateMax: {
    key: 'date',
    operation: 'LTE',
    value: null,
  },
  regionId: {
    key: 'regionId',
    operation: 'EQ',
    value: null,
  },
  areaId: {
    key: 'areaId',
    operation: 'EQ',
    value: null,
  },

  stvorId: {
    key: 'stvorId',
    operation: 'EQ',
    value: null,
  },
  showDisabled: {
    key: 'deleted',
    operation: 'EQ',
    value: false,
  },
  periodId: {
    key: 'periodId',
    operation: 'EQ',
    value: null,
  },
};

interface IProps {
  isLoading: boolean;
  isDisabled: boolean;
  regions: TDictionaryItemDictionary[] | undefined;
  areas: TDictionaryItemDictionary[];
  stvory: TDictionaryItemDictionary[] | undefined;
  onSubmit: (values: any) => void;
  periods: IPeriod[] | null;
  filters?: TFiltersArray;
}

export const GroundCoastTableFilters: FC<IProps> = ({
  isLoading,
  isDisabled,
  onSubmit,
  regions,
  areas,
  stvory,
  children,
  periods,
  filters = [],
}) => {
  const savedPeriodId = useMemo(
    () => (filters.find((item) => item.key === 'periodId')?.value as string) || '',
    [filters],
  );

  const [periodId, setPeriodId] = useState<string>(savedPeriodId);

  const { getDatesFromPeriod, dates } = usePeriods(periodId, periods);

  const submitPeriod = (id: string) => {
    const dates = getDatesFromPeriod(id);
    setFieldValue('periodId.value', id);
    setFieldValue('protocolDateMin.value', createDateToRequestWithTimezone(dates[0]));
    setFieldValue('protocolDateMax.value', createDateToRequestWithTimezone(dates[1]));
    setFieldTouched('protocolDateMin.value');
    setPeriodId(id);
  };

  const { values, errors, handleReset, submitForm, setFieldValue, setValues, setFieldTouched, ...formik } = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    validationSchema: FiltersDatepickersSchema([
      { dateFromFieldName: 'protocolDateMin', dateToFieldName: 'protocolDateMax' },
    ]),
    initialValues: initialEmptyValues,
    onSubmit,
  });

  useEffect(() => {
    setValues(combineFilters(initialEmptyValues, filters));
  }, [filters, setValues]);

  const filteredStvory = useMemo(
    () => stvory?.filter((stvor) => stvor.parent === values.areaId.value).sort((a, b) => (a.name > b.name ? 1 : -1)),
    [stvory, values.areaId],
  );

  return (
    <ContentContainer margin={0} padding={1}>
      <FormikProvider
        value={{
          values,
          errors,
          handleReset,
          submitForm,
          setFieldValue,
          setValues,
          setFieldTouched,
          ...formik,
        }}
      >
        <Grid gap="12px" rowGap="8px">
          <Flex flexDirection="row" justifyContent="space-between">
            <SimpleGrid gap="12px" rowGap="8px" templateColumns="repeat(2, 220px) 150px 150px 190px" columns={5}>
              <Field
                as={DateInput}
                name="dateTimeMin.value"
                label={COMMON_LOCALE.from}
                value={createDateFromResponse(values.protocolDateMin.value as MomentInput)}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setFieldValue('protocolDateMin.value', createDateToRequestWithTimezone(e.target.value));
                }}
                error={errors.protocolDateMin?.value}
                width={200}
                showTimeInput
                maxDate={dates?.[1]}
                minDate={dates?.[0]}
                isDisabled={!periodId}
              />

              <Field
                as={DateInput}
                name="dateTimeMax.value"
                label={COMMON_LOCALE.to}
                value={createDateFromResponse(values.protocolDateMax.value as MomentInput)}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setFieldValue('protocolDateMax.value', createDateToRequestWithTimezone(e.target.value));
                }}
                error={errors.protocolDateMax?.value}
                showTimeInput
                maxDate={dates?.[1]}
                minDate={dates?.[0]}
                isDisabled={!periodId}
              />

              <Field
                as={Select}
                options={regions}
                isDisabled={!regions?.length}
                name="regionId.value"
                label={appLocale.common.region}
              />

              <Field
                as={Select}
                options={
                  areas.map((item) => ({
                    ...item,
                    name: item.areaCode || '',
                  }))}
                isDisabled={!areas?.length}
                name="areaId.value"
                label={appLocale.groundCoast.areaCode}
                onChange={(event: React.ChangeEvent<any>) => {
                  setFieldValue('areaId.value', event.target.value);
                  setFieldValue('stvorId.value', null);
                }}
              />

              <Field
                as={Select}
                options={filteredStvory}
                isDisabled={!filteredStvory?.length}
                name="stvorId.value"
                label={appLocale.groundCoast.stvor}
              />

              {children}
            </SimpleGrid>

            <FilterFormControls
              onReset={(e) => {
                handleReset(e);
                setPeriodId('');
                setTimeout(() => submitForm(), 0);
              }}
              onSubmit={submitForm}
              isDisabled={isDisabled}
              isLoading={isLoading}
            />
          </Flex>

          <PeriodsButtons periodId={periodId} periods={periods} submitPeriod={submitPeriod} />
        </Grid>
      </FormikProvider>
    </ContentContainer>
  );
};
