import React, { useMemo, useState } from 'react';
import { FTPHeader } from '../components';
import { ServersFilter } from './Filter/ServersFilter';
import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import appLocale from 'constants/appLocale';
import { configError, FTPRootPath, FTPSettings, ServersConfig } from 'pages/admin/FTP';
import { baseAdminUrl } from 'pages/admin';
import { ConfirmModal } from 'components/common/Modals';
import { BLOCK_CONFIRMATION, BLOCK_ERROR, BLOCK_SUCCESS, UNBLOCK_ERROR, UNBLOCK_SUCCESS } from 'constants/messages';
import { useRequest } from 'hooks/useRequest';
import { useNotifications } from 'hooks/useNotifications';

import { useHistory } from 'react-router-dom';
import { useDisclosure, useHandleDeleteData, useList } from 'hooks';
import { MaterialTable } from 'features/material-table/MaterialTableMini';
import { BlockIcon, UnlockIcon } from 'components/icons';
import { useServerDictionary } from './useServerDictionary';
import { Column } from '@material-table/core';
import { dictionaryToMap } from 'lib/utils/dictionaryToMap';
import { DEFAULT_PAGE_SIZE_NEW_TABLE } from '../../../../../constants/tables';
import { FullPageWrapper } from '../../../../../components/table/FullPageWrapper';

export const FTPServers = () => {
  const history = useHistory();
  const [blockItem, setBlockItem] = useState<ServersConfig>();

  const { result: dictionaries, isLoading: isDictLoading, status } = useServerDictionary();
  const statusMap = dictionaryToMap(status);

  useUpdateBreadcrumbs([
    {
      breadcrumb: appLocale.admin.main,
      path: '/admin',
    },
    {
      breadcrumb: appLocale.admin.ftp,
      path: FTPRootPath,
    },
    {
      breadcrumb: appLocale.admin.ftpSettings,
      path: `${FTPSettings}/servers`,
    },
  ]);
  const { successNotify, errorNotify } = useNotifications();

  const onBlockSuccess = () => {
    refetch();
    successNotify({
      key: `file/block/success/${blockItem?.active}`,
      message: blockItem?.active ? BLOCK_SUCCESS : UNBLOCK_SUCCESS,
    });
  };

  const onBlockError = (error: any) => {
    /* Заглушка под определенную ошибку */
    if (error.descriptions[0].message === configError) {
      onBlockSuccess();
      return;
    }
    errorNotify({
      key: `file/block/error/${blockItem?.active}`,
      message: blockItem?.active ? BLOCK_ERROR : UNBLOCK_ERROR,
    });
  };
  const { isOpen: isBlockOpen, onOpen: onBlockOpen, onClose: onBlockClose } = useDisclosure();

  const { put, isLoading: isBlockLoading } = useRequest(`${baseAdminUrl}/ftp-config`, onBlockSuccess, onBlockError);

  const onBlock = () => {
    const item = { ...blockItem };
    item.active = !item.active;
    put(item);
    onBlockClose();
  };

  const {
    refetch,
    isLoading: isListLoading,
    response,
    toggleOrder,
    setFilters,
    onPageChange,
    filterDTO,
  } = useList<ServersConfig>(`${baseAdminUrl}/ftp-config/list`, {
    initialOrderFieldKey: '-name',
    defaultPageSize: DEFAULT_PAGE_SIZE_NEW_TABLE,
    resetDataOnNextRequest: true,
  });

  const { handleOpenModal, ModalComponent } = useHandleDeleteData({ refetch });

  const isLoading = useMemo(
    () => isBlockLoading || isDictLoading || isListLoading,
    [isBlockLoading, isDictLoading, isListLoading]
  );

  const columns = useMemo<Column<ServersConfig>[]>(
    () => [
      {
        title: 'Наименование сервера',
        field: 'name',
      },
      {
        title: 'Статус',
        field: 'active',
        render: (row) => statusMap.get(String(row.active)),
      },
      {
        title: 'Адрес сервера',
        field: 'ip',
      },
      {
        title: 'Порт',
        field: 'port',
      },
      {
        title: 'Таймаут',
        field: 'timeout',
      },
    ],
    [statusMap]
  );

  return (
    <FullPageWrapper>
      <FTPHeader
        active="servers"
        url="/add"
        filtersComponent={
          <ServersFilter
            onSubmit={(values) => {
              setFilters(Object.values(values));
            }}
            isLoading={isLoading}
            dictionaries={dictionaries}
          />
        }
      />

      <MaterialTable
        filterDTO={filterDTO}
        data={response?.data}
        meta={response?.meta}
        totalCount={response?.meta.totalCount}
        toggleOrder={toggleOrder}
        onPageChange={onPageChange}
        isLoading={isLoading}
        title="FTP-серверы"
        columns={columns}
        actions={[
          {
            type: 'edit',
            onClick: (_e, item) => {
              history.push(`${FTPSettings}/servers/edit/${item.id}`, item);
            },
          },
          {
            type: 'delete',
            onClick: (_e, item) => {
              if (item.id) {
                handleOpenModal({ url: `${baseAdminUrl}/ftp-config/${item.id}` });
              }
            },
          },
          {
            position: 'row',
            action: (row) => ({
              icon: () => (row.active ? <BlockIcon /> : <UnlockIcon />),
              onClick: (_e: any, item: any) => {
                onBlockOpen();
                setBlockItem(item);
              },
              tooltip: row.active ? 'Заблокировать' : 'Разблокировать',
            }),
          },
        ]}
        options={{ selection: false }}
      />

      {ModalComponent}

      <ConfirmModal
        isOpen={isBlockOpen}
        onClose={onBlockClose}
        onClick={onBlock}
        header={!blockItem?.active ? BLOCK_CONFIRMATION.ACTIVE : BLOCK_CONFIRMATION.BLOCKED}
      />
    </FullPageWrapper>
  );
};
