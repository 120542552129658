import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { Button } from 'components/common';
import { Flex, FormControl, FormLabel, Heading, Switch } from '@chakra-ui/react';
import { TimeScaleOptions } from 'chart.js';

import { IChart } from '../../types';
import { SelectPercent } from './SelectPercent';
import { useSelectPercent } from '../hooks/useSelectPercent';
import { SelectPeriod } from '../../components/SelectPeriod';
import { useSelectPeriod } from '../../hooks/useSelectPeriod';
import { periods } from '../../consts';

interface IChartManageButtons {
  chart: undefined | IChart;
  updateData: (chart: IChart) => void;
}

export const AxisXManage = ({ chart, updateData }: IChartManageButtons) => {
  const { percent, handleChangePercent } = useSelectPercent();
  const { period, handleChangePeriod } = useSelectPeriod();

  const setPeriod = useCallback(() => {
    const XAxis = chart?.options?.scales?.x as TimeScaleOptions;

    if (chart && XAxis && period) {
      const { divider, stepSize, unit, getMinMaxObject } = periods[period];
      const range = getMinMaxObject(chart.scales.x.min ?? 0);
      XAxis.time.unit = unit;
      XAxis.time.stepSize = stepSize;
      XAxis.ticks.callback = (val, i) => (i % divider === 0 ? val : '');
      chart.zoomScale('x', range, 'normal');
      updateData(chart);
    }
  }, [chart, period, updateData]);

  useEffect(() => {
    if (chart) {
      updateData(chart);
    }
  }, [chart, updateData]);

  const setPan = useCallback(
    (value: boolean) => {
      const zoomPlugin = chart?.options?.plugins?.zoom;
      if (chart && zoomPlugin?.pan) {
        if (zoomPlugin.pan.mode === 'x') {
          zoomPlugin.pan.enabled = value;
        } else if (zoomPlugin.pan.mode === 'y') {
          if (value) {
            zoomPlugin.pan.enabled = value;
            zoomPlugin.pan.mode = 'xy';
          }
        } else { // если mode === xy;
          if (value) {
            zoomPlugin.pan.enabled = value;
          } else {
            zoomPlugin.pan.mode = 'y';
          }
        }
        chart.update('normal');
      }
    },
    [chart]
  );

  const setZoom = useCallback(
    (value: boolean) => {
      const zoomPlugin = chart?.options?.plugins?.zoom?.zoom;
      if (chart && zoomPlugin?.wheel && zoomPlugin?.drag) {
        // zoomPlugin.drag.enabled = value;
        if (zoomPlugin.mode === 'xy') {
          if (value) {
            zoomPlugin.wheel.enabled = value;
            zoomPlugin.drag.enabled = value;
          } else {
            zoomPlugin.mode = 'y';
          }
        } else if (zoomPlugin.mode === 'y') {
          if (value) {
            zoomPlugin.wheel.enabled = value;
            zoomPlugin.drag.enabled = value;
            zoomPlugin.mode = 'xy';
          }
        } else { // кейс, если zoomPlugin.mode === 'x' - просто включаем/выключаем
          zoomPlugin.wheel.enabled = value;
          zoomPlugin.drag.enabled = value;
        }

        chart.update('normal');
      }
    },
    [chart]
  );

  const handleClickMove = useCallback(
    (direction?: string) => {
      if (chart) {
        const currentWidth = chart.scales.x.width ?? 1;
        let movingLength = currentWidth * Number(percent);
        if (direction === 'forward') {
          movingLength *= -1;
        }

        chart.pan({ x: movingLength }, undefined, 'active');
        chart.options.plugins?.zoom?.pan?.onPanComplete?.({ chart });
      }
    },
    [chart, percent]
  );
  const [isCheckedPan, setIsCheckedPan] = useState(true);
  const [isCheckedZoom, setIsCheckedZoom] = useState(true);

  const handleChangePan = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setIsCheckedPan(event.target.checked);
      setPan(event.target.checked);
    },
    [setIsCheckedPan, setPan]
  );

  const handleChangeZoom = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setIsCheckedZoom(event.target.checked);
      setZoom(event.target.checked);
    },
    [setZoom, setIsCheckedZoom]
  );

  const handleResetScale = () => {
    const XAxis = chart?.options?.scales?.x as TimeScaleOptions;
    if (chart && XAxis) {
      XAxis.time.unit = false;
      XAxis.time.stepSize = 1;
      XAxis.ticks.callback = (value) => value;
      chart.update('show');
      updateData(chart);
    }
  };

  return (
    <div>
      <Flex flexDirection="row" alignItems="center" flexWrap="wrap">
        <Heading size="sm" width="100%" mb={1}>
          Сместиться по времени
        </Heading>
        <Flex flexDirection="row" alignItems="center" width="100%" flexWrap="wrap" mb={3}>
          <Button size="lg" m={1} ml={0} onClick={() => handleClickMove('backward')}>
            Назад
          </Button>
          <SelectPercent value={percent} onChange={handleChangePercent} />
          <Button
            size="lg"
            ml={4}
            m={1}
            onClick={() => {
              handleClickMove('forward');
            }}
          >
            Вперед
          </Button>
        </Flex>

        <Flex flexDirection="row" alignItems="center" mb={3}>
          <Button size="lg" m={1} ml={0} onClick={handleResetScale}>
            Сбросить
          </Button>
          <Button m={1} size="lg" px={12} onClick={setPeriod}>
            Поставить деление:
          </Button>
          <SelectPeriod value={period} onChange={handleChangePeriod} predicate="isShowInPeriod" />
        </Flex>
        <FormControl display="flex" alignItems="center" my={3}>
          <FormLabel htmlFor="pan" mb="0">
            Ручное перемещение
          </FormLabel>
          <Switch isChecked={isCheckedPan} id="pan" onChange={handleChangePan} />
        </FormControl>
        <FormControl display="flex" alignItems="center" mb={1}>
          <FormLabel htmlFor="zoom" mb="0">
            Ручное зумирование
          </FormLabel>
          <Switch isChecked={isCheckedZoom} id="zoom" onChange={handleChangeZoom} />
        </FormControl>
      </Flex>
    </div>
  );
};
