import React, { Fragment, useEffect, useMemo } from 'react';
import { useFormikContext } from 'formik';
import appLocale from 'constants/appLocale';
import { Flex, Grid } from '@chakra-ui/react';
import { IAnalyticsLabSurfaceWater } from 'pages/analytics/forms/SurfaceWater/types';
import { ANALYTICS_STATUS } from 'pages/analytics/forms/const';
import { AnalyticsSurfaceWaterSchema } from 'models/schemas/analytics/analyticsSurfaceWaterSchema';
import { IRenderFieldOrGroup, useFormField } from 'hooks/forms/useFormField';
import { TDictionariesArray } from 'models';
import { getSelectOptionsFromDictionaryByName } from 'features/get-select-options-from-dictionary';
import { SourceInfo } from '../../../data/Water/SurfaceWater/types';

interface ICommonTab {
  isLoading?: boolean;
  isEditMode?: boolean;
  dictionaries: {
    data: TDictionariesArray;
  } | null;
  editedObject?: SourceInfo | null;
}

export const AnalyticsSurfaceWaterCommonTab = ({ isLoading, isEditMode, dictionaries, editedObject }: ICommonTab) => {
  const { values, setFieldValue } = useFormikContext<IAnalyticsLabSurfaceWater>();

  const { render } = useFormField<IAnalyticsLabSurfaceWater>({
    schema: AnalyticsSurfaceWaterSchema,
    labelLocalization: appLocale.analytics.fields,
  });

  const organizations = useMemo(
    () =>
      getSelectOptionsFromDictionaryByName(
        dictionaries?.data,
        'organizations',
        false,
        editedObject?.organizationId || editedObject?.consumerId,
      ),
    [dictionaries?.data, editedObject?.consumerId, editedObject?.organizationId],
  );

  const waterObjects = useMemo(
    () => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'waterObjects'),
    [dictionaries],
  );

  const sources = useMemo(
    () =>
      getSelectOptionsFromDictionaryByName(dictionaries?.data, 'sources').filter(
        (item) => item.parent === values.waterObjectId,
      ),
    [dictionaries, values.waterObjectId],
  );

  useEffect(() => {
    if (values.sourceId && dictionaries) {
      const source = sources.find((item) => item.value === values.sourceId);
      if (source?.parent !== values.waterObjectId) {
        setFieldValue('sourceId', null);
      }
    }
  }, [dictionaries, setFieldValue, sources, values.sourceId, values.waterObjectId]);

  // while status is hidden assume status as draft
  let fieldsIsDisabled = values.status === ANALYTICS_STATUS.APPROVED && !!isEditMode;
  fieldsIsDisabled = !!isLoading;

  const fields: IRenderFieldOrGroup<IAnalyticsLabSurfaceWater>[] = [
    { name: 'protocolNumber', isDisabled: fieldsIsDisabled },

    { name: 'protocolDate', type: 'dateinput', isDisabled: fieldsIsDisabled },

    { name: 'consumerId', type: 'select', options: organizations, isDisabled: fieldsIsDisabled },

    { name: 'researchBasis', isDisabled: fieldsIsDisabled },

    { name: 'researchObject', isDisabled: fieldsIsDisabled },

    {
      group: true,
      name: appLocale.analytics.fields.address,
      children: [
        { name: 'waterObjectId', type: 'select', options: waterObjects, isDisabled: fieldsIsDisabled },

        { name: 'sourceId', type: 'select', options: sources, isDisabled: fieldsIsDisabled || !values.waterObjectId },
      ],
    },

    { name: 'samplePlace', isDisabled: fieldsIsDisabled },

    { name: 'organizationId', type: 'select', options: organizations, isDisabled: fieldsIsDisabled },

    { name: 'document', isDisabled: fieldsIsDisabled },

    { name: 'actNumber', isDisabled: fieldsIsDisabled },

    { name: 'sampleRegistryNumber', isDisabled: fieldsIsDisabled },

    { name: 'samplePeriodStart', type: 'datetimeinput', isDisabled: fieldsIsDisabled },

    { name: 'samplePeriodEnd', type: 'datetimeinput', isDisabled: fieldsIsDisabled },

    { name: 'sampleDate', type: 'datetimeinput', isDisabled: fieldsIsDisabled },

    { name: 'researchDateStart', type: 'dateinput', isDisabled: fieldsIsDisabled },

    { name: 'researchDateEnd', type: 'dateinput', isDisabled: fieldsIsDisabled },

    { name: 'explanation', isDisabled: fieldsIsDisabled },

    { name: 'supportingDocuments', isDisabled: fieldsIsDisabled },
  ];

  return (
    <Flex marginLeft={10} marginTop="30px">
      <Grid width="600px" gap="15px">
        {fields.map((field) => (
          <Fragment key={field.name}>{render(field)}</Fragment>
        ))}
      </Grid>
    </Flex>
  );
};
