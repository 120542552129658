export const fieldsLocale: { [key: string]: string } = {
  territoryType: 'Тип территории',
  territoryNumber: 'Площадка',
  address: 'Адрес',
  distance2Road: 'Расстояние от дороги, м.',
  pointId: 'Расстояние от края проезжей части, м.',
  sourceName: 'Площадка',
  selectionDate: 'Дата отбора',
  protocol: 'Протокол',
  protocolNumber: 'Номер протокола',
  protocolDate: 'Дата протокола',
  okrug: 'Округ',
  rayon: 'Район',
};

export const feParamId = '3f58e002-5c16-446d-a058-044eafbcf740';
export const mnParamId = '45aadc51-9d3a-48f0-b732-866264d0c719';
export const feMnParamId = 'a8bb8315-5adf-4ae8-8159-5783aef94138';
