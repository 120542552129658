import { IFilterItem } from 'models';

export const filtersInitialValues: Record<string, IFilterItem<string>> = {
  dateTimeMin: {
    key: 'sampleDate',
    operation: 'GTE',
    value: null,
  },
  dateTimeMax: {
    key: 'sampleDate',
    operation: 'LTE',
    value: null,
  },
  waterObjectId: {
    key: 'waterObjectId',
    operation: 'EQ',
    value: null,
  },
  sourceId: {
    key: 'sourceId',
    operation: 'EQ',
    value: null,
  },
  regionId: {
    key: 'regionId',
    operation: 'EQ',
    value: null,
  },
  districtId: {
    key: 'districtId',
    operation: 'EQ',
    value: null,
  },
  organizationId: {
    key: 'organizationId',
    operation: 'EQ',
    value: null,
  },
  showDisabled: {
    key: 'deleted',
    operation: 'EQ',
    value: false,
  },
  periodId: {
    key: 'periodId',
    operation: 'EQ',
    value: null,
  },
};
