import appLocale from 'constants/appLocale';
import subsystemIdsMap from 'constants/subsystemIdsMap';

export const AnalyticReportsAdminBreadcrumb = [
  { breadcrumb: appLocale.subsystems.analyticsReports, path: '/reports-analytics' },
  { breadcrumb: appLocale.admin.main, path: `/reports-analytics/admin/${subsystemIdsMap.admin}` },
];

export const AnalyticReportsASKZABreadcrumb = [
  { breadcrumb: appLocale.subsystems.analyticsReports, path: '/reports-analytics' },
  { breadcrumb: appLocale.subsystems.askza, path: `/reports-analytics/atmosphere/${subsystemIdsMap.atmosphere}` },
];

export const AnalyticReportsBulletinsBreadcrumb = [
  { breadcrumb: appLocale.subsystems.analyticsReports, path: '/reports-analytics' },
  { breadcrumb: 'Бюллетени', path: `/reports-analytics/bulletins/${subsystemIdsMap.bulletins}` },
];

export const AnalyticReportsASKZVBreadcrumb = [
  { breadcrumb: appLocale.subsystems.analyticsReports, path: '/reports-analytics' },
  { breadcrumb: appLocale.subsystems.water, path: '/reports-analytics/water' },
  { breadcrumb: 'АСКЗВ', path: `/reports-analytics/water/askzv/${subsystemIdsMap.waterAskzv}` },
];

export const AnalyticReportsBottomSoilBreadcrumb = [
  { breadcrumb: appLocale.subsystems.analyticsReports, path: '/reports-analytics' },
  { breadcrumb: appLocale.subsystems.water, path: '/reports-analytics/water' },
  { breadcrumb: 'Донный грунт', path: `/reports-analytics/water/bottom-soil/${subsystemIdsMap.bottomSoil}` },
];

export const AnalyticReportsSurfaceWaterBreadcrumb = [
  { breadcrumb: appLocale.subsystems.analyticsReports, path: '/reports-analytics' },
  { breadcrumb: appLocale.subsystems.water, path: '/reports-analytics/water' },
  { breadcrumb: 'Поверхностные воды', path: `/reports-analytics/water/surface/${subsystemIdsMap.surfaceWater}` },
];

export const AnalyticReportsWaterProtectionZoneBreadcrumb = [
  { breadcrumb: appLocale.subsystems.analyticsReports, path: '/reports-analytics' },
  { breadcrumb: appLocale.subsystems.water, path: '/reports-analytics/water' },
  { breadcrumb: appLocale.subsystems.wpz, path: '/reports-analytics/water/water-state/protections' },
  {
    breadcrumb: 'Водоохранные зоны',
    path: `/reports-analytics/water/water-state/protections/waterProtectionZone/${subsystemIdsMap.waterProtectionZone}`,
  },
];

export const AnalyticReportsBottomAndShoreBreadcrumb = [
  { breadcrumb: appLocale.subsystems.analyticsReports, path: '/reports-analytics' },
  { breadcrumb: appLocale.subsystems.water, path: '/reports-analytics/water' },
  { breadcrumb: appLocale.subsystems.wpz, path: '/reports-analytics/water/water-state/protections' },
  {
    breadcrumb: 'Дно и берега',
    path: `/reports-analytics/water/water-state/protections/bottomAndShore/${subsystemIdsMap.groundcoast}`,
  },
];

export const AnalyticReportsGreenplantsBreadcrumb = [
  { breadcrumb: appLocale.subsystems.analyticsReports, path: '/reports-analytics' },
  { breadcrumb: 'Зеленые насаждения', path: `/reports-analytics/greenplants/${subsystemIdsMap.greenPlantsRoot}` },
];

export const AnalyticReportsDutyOfficeBreadcrumb = [
  { breadcrumb: appLocale.subsystems.analyticsReports, path: '/reports-analytics' },
  {
    breadcrumb: 'Личный кабинет оперативного дежурного СОК',
    path: `/reports-analytics/duty-office/${subsystemIdsMap.dutyOfficer}`,
  },
];

export const AnalyticReportsMASKSHBreadcrumb = [
  { breadcrumb: appLocale.subsystems.analyticsReports, path: '/reports-analytics' },
  { breadcrumb: 'М-АСКШ', path: `/reports-analytics/masksh/${subsystemIdsMap.maskshRoot}` },
];

export const AnalyticReportsMeteoBreadcrumb = [
  { breadcrumb: appLocale.subsystems.analyticsReports, path: '/reports-analytics' },
  { breadcrumb: 'Метео', path: `/reports-analytics/meteo/${subsystemIdsMap.meteoRoot}` },
];

export const AnalyticReportsVegsBreadcrumb = [
  { breadcrumb: appLocale.subsystems.analyticsReports, path: '/reports-analytics' },
  { breadcrumb: appLocale.subsystems.reagents, path: '/reports-analytics/pgr' },
  { breadcrumb: 'Вегетативные части', path: `/reports-analytics/pgr/vegs/${subsystemIdsMap.pgrVegetative}` },
];

export const AnalyticReportsPGRGreenplantsBreadcrumb = [
  { breadcrumb: appLocale.subsystems.analyticsReports, path: '/reports-analytics' },
  { breadcrumb: appLocale.subsystems.reagents, path: '/reports-analytics/pgr' },
  { breadcrumb: 'Зеленые насаждения', path: `/reports-analytics/pgr/greenplants/${subsystemIdsMap.pgrFlora}` },
];

export const AnalyticReportsPGRSoilBreadcrumb = [
  { breadcrumb: appLocale.subsystems.analyticsReports, path: '/reports-analytics' },
  { breadcrumb: appLocale.subsystems.reagents, path: '/reports-analytics/pgr' },
  { breadcrumb: 'Почвы', path: `/reports-analytics/pgr/soil/${subsystemIdsMap.pgrSoil}` },
];

export const AnalyticReportsWashoutsBreadcrumb = [
  { breadcrumb: appLocale.subsystems.analyticsReports, path: '/reports-analytics' },
  { breadcrumb: appLocale.subsystems.reagents, path: '/reports-analytics/pgr' },
  { breadcrumb: 'Смывы', path: `/reports-analytics/pgr/washouts/${subsystemIdsMap.pgrWashouts}` },
];

export const AnalyticReportsSnowBreadcrumb = [
  { breadcrumb: appLocale.subsystems.analyticsReports, path: '/reports-analytics' },
  { breadcrumb: appLocale.subsystems.reagents, path: '/reports-analytics/pgr' },
  { breadcrumb: 'Снежный покров', path: `/reports-analytics/pgr/snow/${subsystemIdsMap.pgrSnow}` },
];

export const AnalyticReportsSoilBreadcrumb = [
  { breadcrumb: appLocale.subsystems.analyticsReports, path: '/reports-analytics' },
  { breadcrumb: 'Почвы', path: `/reports-analytics/soil/${subsystemIdsMap.soilpollution}` },
];

export const AnalyticReportsEmissionsBreadcrumb = [
  { breadcrumb: appLocale.subsystems.analyticsReports, path: '/reports-analytics' },
  { breadcrumb: 'Промвыбросы', path: `/reports-analytics/emissions/${subsystemIdsMap.emissions}` },
];

export const AnalyticReportsPELBreadcrumb = [
  { breadcrumb: appLocale.subsystems.analyticsReports, path: '/reports-analytics' },
  { breadcrumb: 'ПЭЛ', path: `/reports-analytics/pel/${subsystemIdsMap.pelRoot}` },
];

export const AnalyticReportsAppealsBreadcrumb = [
  { breadcrumb: appLocale.subsystems.analyticsReports, path: '/reports-analytics' },
  { breadcrumb: 'Учет обращений граждан', path: `/reports-analytics/appeals/${subsystemIdsMap.appealsRoot}` },
];
