import { Button } from 'components/common';
import { memo } from 'react';
import { COMMON_LOCALE } from 'constants/common';
import { Flex } from '@chakra-ui/react';

interface Props {
  onCancel: () => void;
  handleSubmit: () => void;
  submitIsDisabled?: boolean;
}

const FormControls = ({
  onCancel,
  handleSubmit,
  submitIsDisabled
}: Props) => (
  <Flex mt={4}>
    <Button onClick={onCancel} marginRight="auto">
      {COMMON_LOCALE.cancel}
    </Button>

    <Button
      onClick={handleSubmit}
      variant="solid"
      disabled={submitIsDisabled}
    >
      {COMMON_LOCALE.save}
    </Button>
  </Flex>
);

export default memo(FormControls);
