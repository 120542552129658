import * as yup from 'yup';
import { lessThanDate, maxSymbols, moreThanDate, numberError, requiredError, uniqueError } from '../constans';
import { ValidationError } from 'yup';
import moment from 'moment';

export const AnalyticsPGRSoilSchema = yup
  .object()
  .shape({
    protocol: yup.string().required(requiredError).nullable().max(15, maxSymbols(15)),
    protocolDate: yup.string().typeError(requiredError).required(requiredError),
    consumerId: yup.string().required(requiredError).nullable(),
    researchBasis: yup.string().required(requiredError).nullable().max(511, maxSymbols(511)),
    researchObject: yup.string().required(requiredError).nullable().max(255, maxSymbols(255)),
    address: yup.string().required(requiredError).nullable().max(511, maxSymbols(511)),
    samplePlace: yup.string().required(requiredError).nullable().max(255, maxSymbols(255)),
    organizationId: yup.string().required(requiredError).nullable(),
    methodology: yup.string().required(requiredError).nullable().max(511, maxSymbols(511)),
    sampleAct: yup.string().required(requiredError).nullable().max(63, maxSymbols(63)),
    sampleLaboratoryNumber: yup.string().required(requiredError).nullable().max(15, maxSymbols(15)),
    selectionDate: yup.string().typeError(requiredError).required(requiredError),
    sampleReceiveDate: yup.string().typeError(requiredError).required(requiredError),
    researchDateStart: yup.string().typeError(requiredError).required(requiredError),
    researchDateEnd: yup.string().typeError(requiredError).required(requiredError),
    explanation: yup.string(),
    distance2RoadId: yup.string().required(requiredError).nullable(),
    depthId: yup.string().required(requiredError).nullable(),
    supportingDocuments: yup.string().nullable().max(255, maxSymbols(255)),
    sourceNameId: yup.string().required(requiredError).nullable(),

    status: yup.string().required(requiredError).nullable(),

    values: yup.array()
      .of(
        yup.object()
          .shape({
            parameterId: yup.string()
              .nullable()
              .required(requiredError),
            value: yup.number()
              .typeError(numberError)
              .nullable()
              .positive('Должно быть положительным')
              .required(requiredError),
            index: yup.number()
              .typeError(numberError)
              .nullable()
              .positive('Должно быть положительным')
              .required(requiredError),
          }),
      ),

    sign: yup
      .object()
      .shape({
        signers: yup.array().of(
          yup.object().shape({
            action: yup.string().required(requiredError).nullable(),
            position: yup.string().required(requiredError).nullable().max(511, maxSymbols(511)),
            name: yup.string().required(requiredError).nullable().max(255, maxSymbols(255)),
          })
        ),
      })
      .nullable(),
  })
  .test((values) => {
    if (values.values && values.values.length > 0) {
      const indexes = values.values.map((v) => v.index);

      if (indexes.length > new Set(indexes).size) {
        const fieldErrors = new Array(indexes.length)
          .fill(null)
          .map((v, i) => new yup.ValidationError(uniqueError, '', `values.${i}.index`));
        if (fieldErrors.length > 0) {
          return new yup.ValidationError(fieldErrors);
        }
      }
    }

    if (values.researchDateStart && values.researchDateEnd && moment(values.researchDateStart)
      .isAfter(values.researchDateEnd)) {
      const dateErrors = [
        new ValidationError(lessThanDate('даты начала исследований'), '', 'researchDateEnd'),
        new ValidationError(moreThanDate('даты окончания исследований'), '', 'researchDateStart'),
      ];
      return new ValidationError(dateErrors);
    }

    return true;
  });
