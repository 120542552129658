import { Box, BoxProps } from '@chakra-ui/react';

const Truncate: React.FC<BoxProps> = (props) => <Box {...props} />;

Truncate.defaultProps = {
  display: 'inline-block',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
};

export { Truncate };
