import { Route } from 'react-router-dom';
import { AddAISWeather } from 'pages/data/Meteo/AISWeather/AISWeather/Forms/AddAISWeather';
import { EditAISWeather } from 'pages/data/Meteo/AISWeather/AISWeather/Forms/EditAISWeather';
import { AISWeather } from 'pages/data/Meteo/AISWeather/AISWeather';
import { AISWeatherRootPath } from 'pages/data/Meteo/menu';
import { WithDidMountResetUploadFile } from 'features/UploadedFileProvider/hocs';

const AISWeatherRoutes = () => (
  <>
    <Route exact path={`${AISWeatherRootPath}/add`} component={AddAISWeather} />
    <Route exact path={`${AISWeatherRootPath}/edit/row/:id`} component={EditAISWeather} />
    <Route exact path={AISWeatherRootPath} render={() => <WithDidMountResetUploadFile Component={AISWeather} />} />
  </>
);

export { AISWeatherRoutes };
