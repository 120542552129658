import { Route } from 'react-router';
import React from 'react';
import { MASKSHMenu } from 'pages/data/MASKSH/MASKSHMenu';
import {
  MASKSHInstructions,
  MASKSHInstructionsAdd,
  MASKSHInstructionsEdit,
  MASKSHInstructionsView,
  MASKSHMeteo,
  MASKSHMeteoAdd,
  MASKSHMeteoEdit,
  MASKSHMeteoPackages,
  MASKSHNoise,
  MASKSHNoiseAdd,
  MASKSHNoiseEdit,
  MASKSHNoisePackages,
  MASKSHRoot,
  MASKSHTechnical,
  MASKSHTechnicalPackages,
} from '../data/MASKSH/consts';
import { AddMeteoPackageForm } from '../data/MASKSH/Data/Meteo/MeteoPackageForm/Add';
import { EditMeteoPackageForm } from '../data/MASKSH/Data/Meteo/MeteoPackageForm/Edit';
import { AddNoisePackageForm } from '../data/MASKSH/Data/Noise/NoisePackageForm/Add';
import { EditNoisePackageForm } from '../data/MASKSH/Data/Noise/NoisePackageForm/Edit';
import { Instructions, Meteo, Noise, Technical } from '../data/MASKSH';
import { AddInstruction } from '../data/MASKSH/Instructions/InstructionsForm/Add';
import { EditInstruction } from '../data/MASKSH/Instructions/InstructionsForm/Edit';
import { NoisePackages } from 'pages/data/MASKSH/Data/Noise/NoisePackages';
import { MeteoPackages } from 'pages/data/MASKSH/Data/Meteo/MeteoPackages';
import { TechnicalPackages } from 'pages/data/MASKSH/Data/Technical/TechnicalPackages';

const MASKSHRoutes = () => (
  <>
    <Route exact path={MASKSHRoot} component={MASKSHMenu} />

    <Route exact path={MASKSHNoise} component={Noise} />
    <Route exact path={MASKSHNoisePackages} component={NoisePackages} />

    <Route exact path={MASKSHMeteo} component={Meteo} />
    <Route exact path={MASKSHMeteoPackages} component={MeteoPackages} />

    <Route exact path={MASKSHTechnical} component={Technical} />
    <Route exact path={MASKSHTechnicalPackages} component={TechnicalPackages} />

    <Route exact path={MASKSHInstructions} component={Instructions} />
    <Route exact path={MASKSHInstructionsAdd} component={AddInstruction} />
    <Route exact path={MASKSHInstructionsEdit} component={EditInstruction} />
    <Route exact path={MASKSHInstructionsView} component={EditInstruction} />

    <Route exact path={MASKSHNoiseAdd} render={() => <AddNoisePackageForm />} />
    <Route
      exact
      path={`${MASKSHNoiseAdd}/packages`}
      render={() => <AddNoisePackageForm listPath={MASKSHNoisePackages} />}
    />
    <Route
      exact
      path={`${MASKSHNoiseEdit}/:packageId`}
      component={EditNoisePackageForm}
    />
    <Route
      exact
      path={`${MASKSHNoiseEdit}/packages/:packageId`}
      render={() => <EditNoisePackageForm listPath={MASKSHNoisePackages} />}
    />

    <Route exact path={MASKSHMeteoAdd} render={() => <AddMeteoPackageForm />} />
    <Route
      exact
      path={`${MASKSHMeteoEdit}/:packageId`}
      render={() => <EditMeteoPackageForm />}
    />
    <Route
      exact
      path={`${MASKSHMeteoAdd}/packages`}
      render={() => <AddMeteoPackageForm listPath={MASKSHMeteoPackages} />}
    />
    <Route
      exact
      path={`${MASKSHMeteoEdit}/packages/:packageId`}
      render={() => <EditMeteoPackageForm listPath={MASKSHMeteoPackages} />}
    />
  </>
);

export { MASKSHRoutes };
