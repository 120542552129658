import React from 'react';
import { PageHeader, RoutesList } from 'components/common';
import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import appLocale from 'constants/appLocale';
import { meteoDataMenu } from 'pages/data/Meteo/menu';
import { Flex, Heading } from '@chakra-ui/react';
import { useRoutesList } from 'hooks/useRoutesList';

export const MeteoDataMenu: React.FC = () => {
  useUpdateBreadcrumbs([
    { breadcrumb: appLocale.common.data, path: '/data' },
    { breadcrumb: appLocale.subsystems.meteo, path: '/data/meteo' },
    {
      breadcrumb: 'Метеоданные',
      path: '/data/meteo/data',
    },
  ]);
  const routes = useRoutesList(meteoDataMenu);
  return (
    <Flex flexDirection="column">
      <PageHeader>
        <Heading>Метеоданные</Heading>
      </PageHeader>
      <RoutesList routes={routes} />
    </Flex>
  );
};
