import { useCallback, useEffect } from 'react';
import { useRequest } from 'hooks/useRequest';
import { useEnhancedNotification } from 'hooks/useEnhancedNotification';
import { AISWeatherItem } from 'pages/data/Meteo/AISWeather/AISWeather/types';

export interface AISWeatherSaveItem {
  data: Array<Record<string, any>>;
  valDateTime: string;
  areaId: string;
  pointId: string;
  date?: string;
  packageDatetime: string;
  packageDatetimeResult: string;
  datetime: string;
  stations?: AISWeatherItem['stations'];
}

export function useAISWeatherRecord(packageId: string) {
  const { onGetError } = useEnhancedNotification();
  const { get, isLoading, result, error } = useRequest<AISWeatherSaveItem>(
    `/meteo/v10/ais/package/${packageId}`,
    undefined,
    onGetError
  );

  const getAISWeatherRecord = useCallback(() => get(), [get]);

  useEffect(() => {
    getAISWeatherRecord();
  }, [getAISWeatherRecord]);

  return {
    getAISWeatherRecord,
    isLoading,
    result,
    error,
  };
}
