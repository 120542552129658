import { TFilterOperations, TFiltersArray } from 'models';
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { Box } from '@chakra-ui/react';
import { Button, Select, SelectOption } from 'components/common';

interface Props<Keys> {
  onSubmit: (operand: TFilterOperations, firstValue: string) => void;
  filter?: TFiltersArray<Keys>;
  onClear: () => void;
  selectOptions: SelectOption[];
}

function TableSelectFilter<Keys = string>({ onSubmit, onClear, filter, selectOptions }: Props<Keys>) {
  const operand: TFilterOperations = 'EQ';
  const [value, setValue] = useState('');

  useEffect(() => {
    if (filter && filter[0]) {
      setValue(filter[0].value ? String(filter[0].value) : '');
    }
  }, [filter]);

  const onSubmitClick = useCallback(() => {
    if (operand && value) {
      onSubmit(operand as TFilterOperations, value);
    }
  }, [onSubmit, operand, value]);

  return (
    <Box>
      <Box display="flex">
        <Select
          label="Значение"
          value={value}
          options={selectOptions}
          onChange={(event: ChangeEvent<HTMLInputElement>) => setValue(event.target.value)}
        />
      </Box>

      <Box display="flex" width="100%" marginTop={4}>
        <Button onClick={onClear} marginRight="auto">
          Сбросить
        </Button>

        <Button disabled={!operand || !value} onClick={onSubmitClick} variant="solid">
          Применить
        </Button>
      </Box>
    </Box>
  );
}

export default TableSelectFilter;
