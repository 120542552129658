import { useCallback, useMemo } from 'react';
import { useNotifications } from './useNotifications';
import { ERROR_MESSAGES, SUCCESS_MESSAGES } from '../constants/messages';
import { ApiError } from 'models';
import { formatErrorMessage } from 'lib/utils/formatErrorMessage';

/**
 * @summary hook for common use cases of notification in app
 */
export const useEnhancedNotification = () => {
  const { errorNotify, successNotify } = useNotifications();

  const onSuccessCreate = useCallback((multiple?: boolean) => {
    successNotify({
      key: 'add/success',
      message: multiple ? SUCCESS_MESSAGES.CREATE_MULTIPLE : SUCCESS_MESSAGES.CREATE,
    });
  }, [successNotify]);

  const onErrorCreate = useCallback(
    (message?: string | null | ApiError) => {
      const msg = formatErrorMessage(message);
      errorNotify({
        key: 'add/error',
        message: msg || ERROR_MESSAGES.CREATE,
      });
    },
    [errorNotify]
  );

  const onSuccessEdit = useCallback((msg?: string | null) => {
    successNotify({
      key: 'edit/success',
      message: msg || SUCCESS_MESSAGES.EDIT,
    });
  }, [successNotify]);

  const onErrorEdit = useCallback(
    (message?: string | null | ApiError) => {
      const msg = formatErrorMessage(message);
      errorNotify({
        key: 'edit/error',
        message: msg || ERROR_MESSAGES.EDIT,
      });
    },
    [errorNotify]
  );

  const onSuccessDelete = useCallback((multiple?: boolean) => {
    successNotify({
      key: 'delete/success',
      message: multiple ? SUCCESS_MESSAGES.DELETE_MULTIPLE : SUCCESS_MESSAGES.DELETE,
    });
  }, [successNotify]);

  const onErrorDelete = useCallback(
    (message?: string | null | ApiError) => {
      const msg = formatErrorMessage(message);
      errorNotify({
        key: 'delete/error',
        message: msg || ERROR_MESSAGES.DELETE,
      });
    }, [errorNotify]
  );

  const onValidationError = useCallback(() => {
    errorNotify({
      key: 'validation-error',
      message: 'Пожалуйста, проверьте введенные данные',
    });
  }, [errorNotify]);

  const onDownloadError = useCallback(() => {
    errorNotify({
      key: 'download-error',
      message: ERROR_MESSAGES.DOWNLOAD,
    });
  }, [errorNotify]);

  const onGetError = useCallback(
    (message?: string | null | ApiError) => {
      const msg = formatErrorMessage(message);
      errorNotify({
        key: 'get-error',
        message: msg || ERROR_MESSAGES.GET,
      });
    }, [errorNotify]
  );

  const onGetDictError = useCallback(
    (message?: string | null | ApiError) => {
      const msg = formatErrorMessage(message);
      errorNotify({
        key: 'getDict-error',
        message: msg || ERROR_MESSAGES.GET_DICT_ERROR,
      });
    }, [errorNotify]
  );

  return useMemo(
    () => ({
      onSuccessCreate,
      onErrorCreate,
      onSuccessEdit,
      onErrorEdit,
      onSuccessDelete,
      onErrorDelete,
      onValidationError,
      onDownloadError,
      onGetError,
      onGetDictError
    }),
    [
      onDownloadError,
      onErrorCreate,
      onErrorDelete,
      onErrorEdit,
      onGetError,
      onSuccessCreate,
      onSuccessDelete,
      onSuccessEdit,
      onValidationError,
      onGetDictError
    ]
  );
};
