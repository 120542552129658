import { DateInput, FormWrapper, Input, Select } from 'components/common';
import { Box, Grid, Heading } from '@chakra-ui/react';
import React, { useMemo } from 'react';

import {
  getSelectOptionsFromDictionaryByName,
  getSelectRichOptionsFromDictionaryByName,
} from 'features/get-select-options-from-dictionary';
import { Field, useFormikContext } from 'formik';
import { createDateFromResponse, createDateToRequestWithTimezone } from 'lib/create-date';
import { TDictionariesArray } from 'models/dictionaries';
import { SnowDeicingItem } from '../useSnowDeicingList';
import { fieldsLocale } from '../consts';
import { getFilteredOptionsByPrivileges } from 'features/getFilteredOptionsByPrivileges';

interface CommonTabProps {
  dictionaries: {
    data: TDictionariesArray;
  } | null;
  isEditMode?: boolean;
}

export const CommonTab = ({ dictionaries, isEditMode }: CommonTabProps): JSX.Element => {
  const { setFieldValue, values, errors, initialValues } = useFormikContext<SnowDeicingItem>();

  const okrugs = useMemo(() => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'districts'), [dictionaries]);

  const rayons = getSelectOptionsFromDictionaryByName(dictionaries?.data, 'regions');
  const filteredRayons = useMemo(() => {
    if (values.regionId) {
      return rayons.filter((item) => item.parent === values.regionId);
    }
    return rayons;
  }, [rayons, values.regionId]);

  const areas = useMemo(
    () =>
      getFilteredOptionsByPrivileges(
        getSelectRichOptionsFromDictionaryByName(dictionaries?.data, 'areas'),
        'isEditable', 'value', values.areaId
      ).map(({ title: name, ...item }) => ({
        ...item,
        name: String(name),
      })),
    [dictionaries?.data, values.areaId],
  );

  const filteredAreas = useMemo(() => {
    if (values.districtId) {
      return areas.filter((item) => item.parent === values.districtId);
    }

    const filteredRayonsIds = filteredRayons.map((region) => region.value);
    return areas.filter((item) => item.parent && filteredRayonsIds.includes(item.parent));
  }, [areas, filteredRayons, values.districtId]);

  const probeOffsets = useMemo(() => {
    const points = getSelectRichOptionsFromDictionaryByName(dictionaries?.data, 'points');

    if (values.areaId) {
      return points.filter((el) => el.parent === values.areaId);
    }

    return points;
  }, [dictionaries, values.areaId]);

  const territoryTypes = useMemo(
    () =>
      getSelectOptionsFromDictionaryByName(dictionaries?.data, 'territoryTypes', false, initialValues.territoryType),
    [dictionaries?.data, initialValues.territoryType],
  );

  const stages = useMemo(
    () => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'stages', false, initialValues.stageId),
    [dictionaries?.data, initialValues.stageId],
  );

  const seasons = useMemo(
    () => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'seasons', false, initialValues.seasonId),
    [dictionaries?.data, initialValues.seasonId],
  );

  return (
    <Box flexDirection="column">
      <FormWrapper>
        <Grid marginTop="30px" gap="15px">
          <Heading variant="h2">Данные обследования</Heading>
          <Field
            as={Select}
            name="seasonId"
            options={seasons}
            label={fieldsLocale.season}
            isDisabled={isEditMode}
            error={errors?.season}
          />
          <Field
            as={Select}
            name="stageId"
            options={stages}
            label={fieldsLocale.stage}
            isDisabled={isEditMode}
            error={errors?.stage}
          />
          <DateInput
            name="date"
            label={fieldsLocale.date}
            value={createDateFromResponse(values.date)}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setFieldValue('date', createDateToRequestWithTimezone(e.target.value))
            }
            showTimeInput
            isDisabled={isEditMode}
            error={errors?.date}
          />
          <Field
            as={Input}
            label={fieldsLocale.protocolNumber}
            name="protocolNumber"
            value={values.protocolNumber}
            disabled={isEditMode}
            error={errors?.protocolNumber}
          />

          <Field
            as={Select}
            name="regionId"
            label={fieldsLocale.district}
            options={okrugs}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              setFieldValue('regionId', e.target.value);
              if (!e.target.value) {
                setFieldValue('districtId', '');
                setFieldValue('areaId', '');
                setFieldValue('address', '');
                setFieldValue('territoryType', '');
              }
            }}
            error={errors?.region}
            isDisabled={values.areaId}
          />

          <Field
            as={Select}
            name="districtId"
            label={fieldsLocale.region}
            options={isEditMode ? rayons : filteredRayons}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              const district = filteredRayons.find((item) => item.value === e.target.value)?.parent || '';

              setFieldValue('districtId', e.target.value);
              setFieldValue('regionId', district);

              if (!e.target.value) {
                setFieldValue('areaId', '');
                setFieldValue('address', '');
                setFieldValue('territoryType', '');
              }
            }}
            error={errors?.district}
            isDisabled={values.areaId}
          />

          <Field
            as={Select}
            name="areaId"
            label={fieldsLocale.areaId}
            value={values.areaId}
            options={isEditMode ? areas : filteredAreas}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              const areaItem = filteredAreas.find((el) => el.value === e.target.value);
              const district = filteredRayons.find((item) => item.value === areaItem?.parent)?.parent || '';
              const region = filteredRayons.find((item) => item.value === areaItem?.parent)?.value || '';

              setFieldValue('areaId', areaItem?.value || '');
              setFieldValue('districtId', region);
              setFieldValue('regionId', district);
              setFieldValue('address', areaItem?.address || '');
              setFieldValue('territoryType', areaItem?.territoryTypeWithSubtype || '');
              // pointId options depends on areaId
              setFieldValue('pointId', '');
            }}
            isDisabled={isEditMode}
            error={errors?.areaId || (!isEditMode && !filteredAreas.length && 'Участки в данном районе отсутствуют')}
          />

          <Field as={Input} name="address" label={fieldsLocale.address} disabled error={errors?.address} />
          <Field
            as={Select}
            name="territoryType"
            options={territoryTypes}
            label="Тип территории (Подтип территории)"
            isDisabled
            error={errors?.territoryType}
          />

          <Field
            as={Select}
            name="pointId"
            options={probeOffsets}
            label={fieldsLocale.pointId}
            isDisabled={isEditMode || !values.areaId}
            error={errors?.pointId}
          />
        </Grid>
      </FormWrapper>
    </Box>
  );
};
