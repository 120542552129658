import { useRequest } from 'hooks/useRequest';
import { ILawnDto } from './useLawnsList';
import { TBaseGreenPlantsFormProperties } from '../Form/types';
import { ReagentsApiGreenplants } from 'pages/data/PGR/consts';

const useLawnListItem = (registryId: TBaseGreenPlantsFormProperties<string>['registryId']) => {
  const { get, result, isLoading, error } = useRequest<ILawnDto>(`${ReagentsApiGreenplants}/lawns/${registryId}`);

  return { get, result, isLoading, error };
};

export { useLawnListItem };
