import appLocale from 'constants/appLocale';
import { ReportSmall } from 'features/WithSideBar/icons';
import { subsystemCodesMap } from 'constants/subsystemIdsMap';

export const analyticsLabMenu = {
  name: appLocale.subsystems.analyticsLab,
  id: '/analytics',
  url: '/analytics',
  icon: <ReportSmall />, // TODO: change icon
  otherCode: subsystemCodesMap.analyticsLab, // Корневая подсистема
};
