import React, { useMemo } from 'react';
import { Flex } from '@chakra-ui/react';
import { InclinometerItem, InclinometerItems } from 'hooks/geo/Inclinometer/useGeoInclinometerList';
import { createLocalDateDDMMYYYY } from 'lib/create-date';
import { MaterialTable } from 'features/material-table/MaterialTableMini';
import { useHistory } from 'react-router';
import { IUseListMaterialTableProps, IWithFileDownload, useHandleDeleteData } from 'hooks';
import { TDictionariesArray, TFilterDTO, TFiltersArray } from 'models';
import { GeoecologicalRootPath } from 'pages/data/GeoecologicalProcesses/consts';
import { DeleteIcon, EditIcon, ImageIcon } from 'components/icons';
import FilePreviewModalWrapper from 'components/common/FilePreviewModal/FilePreviewModalWrapper';
import useFilePreviewModalWrapperData from 'components/common/FilePreviewModal/hooks/useFilePreviewModalWrapperData';
import { addFiltersToColumns, ColumnWithFilter } from 'lib/utils/tableFilter';
import { Column } from '@material-table/core';
import { TableFilterTypeEnum } from 'components/table/filters/TableFilter';
import { templatesObject } from 'constants/templates';
import { PropActions } from 'features/material-table/components/Actions';
import { getSelectOptionsFromDictionaryByName } from 'features/get-select-options-from-dictionary';
import { parametersNames } from '../LoadPreview/types';

interface InclinometerTableProps {
  inclinometerList: InclinometerItems | null;
  refetch: () => void;
  isLoading: boolean;
  toggleOrder: (fieldKey: string) => void;
  onPageChange: (page: number, pageSize: number) => void;
  downloadAsFile: IWithFileDownload;
  filters: TFilterDTO<keyof InclinometerItem>['filters'];
  setFilters: (filters: TFiltersArray<keyof InclinometerItem>) => void;
  filterDTO: TFilterDTO;
  onSelectionChange: (checkedRows: InclinometerItem[]) => void;
  selectedIndexes: number[];
  isReadonly?: boolean;
  isExecute?: boolean;
  isWrite?: boolean;
  isNoRights?: boolean;
  allAvailable?: boolean;
  saveFilters: () => void;
  dictionaries?: TDictionariesArray;
  errorText?: string;
  materialTableProps: IUseListMaterialTableProps;
}

export const InclinometerTable: React.FC<InclinometerTableProps> = ({
  inclinometerList,
  refetch,
  isLoading,
  toggleOrder,
  onPageChange,
  downloadAsFile,
  setFilters,
  filterDTO,
  selectedIndexes,
  onSelectionChange,
  filters,
  isExecute,
  isWrite,
  isNoRights,
  allAvailable,
  saveFilters,
  dictionaries,
  errorText,
  materialTableProps,
}) => {
  const history = useHistory();
  const { open, ...filePreviewData } = useFilePreviewModalWrapperData();

  const parameters = useMemo(() => getSelectOptionsFromDictionaryByName(dictionaries, 'parameters'), [dictionaries]);

  const { ModalComponent, handleOpenModal } = useHandleDeleteData({ refetch });
  const columns = useMemo<ColumnWithFilter<InclinometerItem>[]>(
    () => [
      {
        title: 'Дата',
        field: 'valDateTime',
        render: (row) => <Flex>{createLocalDateDDMMYYYY(row.valDateTime)}</Flex>,
      },
      {
        title: 'Участок',
        field: 'areaNumber',
        filterType: TableFilterTypeEnum.number,
      },
      {
        title: 'Пункт наблюдения',
        field: 'pointNumber',
        filterType: TableFilterTypeEnum.number,
      },
      {
        title: 'Глубина замера, м',
        field: 'probeHeightDepth',
        filterType: TableFilterTypeEnum.number,
      },
      ...parameters.map((param) => ({
        title: param.name,
        field: parametersNames[param.value],
      })),
    ],
    [parameters],
  );

  const columnsWithFilters = useMemo<Array<Column<InclinometerItem>>>(
    () => addFiltersToColumns({ filters, setFilters, columns }),
    [columns, filters, setFilters],
  );

  const initialActions: PropActions<InclinometerItem[]> = useMemo(() => (
    [
      {
        position: 'row',
        action: (row: any) => ({
          icon: () => <EditIcon />,
          onClick: () =>
            history.push(`${GeoecologicalRootPath}/monitoring/danger/inclinometer/edit/row/${row.id}`, row),
          tooltip: 'Редактировать',
          hidden: !row.isEditable,
        }),
      },
      {
        position: 'row',
        action: (row: any) => ({
          icon: () => <DeleteIcon />,
          onClick: () =>
            handleOpenModal({
              url: `/geoecology/v10/geoecology/inclinometer/${row.id}`,
            }),
          tooltip: 'Удалить',
          hidden: !row.isEditable,
        }),
      },
      {
        position: 'row',
        action: (row: any) => ({
          icon: () => <ImageIcon />,
          onClick: () => open(row.packageId, row.objectType),
          tooltip: 'Показать изображения',
          hidden: !row.objectType,
        }),
      },
    ]),
    [handleOpenModal, history, open]);

  // @ts-ignore
  const actions = useMemo<PropActions<any[]>>(() => {
    if (allAvailable || isNoRights || isWrite) {
      return initialActions;
    }
    if (!(allAvailable || isNoRights || isWrite)) {
      return initialActions.filter((action: any) => action.type !== 'delete');
    }
  }, [isWrite, initialActions, isNoRights, allAvailable]);

  return (
    <>
      <MaterialTable
        saveFilters={saveFilters}
        isNotExecute={isExecute || isNoRights}
        onSelectionChange={onSelectionChange}
        selectedIndexes={selectedIndexes}
        data={inclinometerList?.data}
        meta={inclinometerList?.meta}
        toggleOrder={toggleOrder}
        filterDTO={filterDTO}
        onPageChange={onPageChange}
        columns={columnsWithFilters}
        actions={actions}
        isLoading={isLoading}
        downloadAsFile={downloadAsFile}
        setFilters={setFilters}
        downloadTemplate={[templatesObject.inclinometers]}
        errorText={errorText}
        {...materialTableProps}
        showTotalCount
      />

      {ModalComponent}

      <FilePreviewModalWrapper {...filePreviewData} />
    </>
  );
};
