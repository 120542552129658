import { memo, useCallback, useMemo, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { normalizeExistParameters } from 'features/normalizeExistParameters';
import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import { GreenPlantsFloraUrl, GreenPlantsPlatformUrl } from 'pages/routes/GreenPlants';
import appLocale from 'constants/appLocale';
import { useFloraDictionaries } from '../../Flora/useFloraDictionaries';
import { useLawnsDictionaries } from '../../Lawns/useLawnsDictionaries';
import { GreenPlantsForm } from '../../Form/GreenPlantsForm';
import { greenPlantsFormInitialValues, GreenPlantsFormTypes, TGreenPlantsForm } from '../../Form/types';
import { IUploadMedia } from 'components/common/FileUploadDragAndDrop/types';
import { useNotifications } from 'hooks';
import { updateFiles, UploadObjectType } from 'api/services/media';
import { ERROR_MESSAGES } from 'constants/messages';
import { useAreaEditItem } from './useAreaEditItem';
import { useGoBackWithFallback } from '../../../../../lib/utils/goBackWithFallback';

const withEditArea = (Component: typeof GreenPlantsForm) =>
  memo(() => {
    const { id, type: entityType } = useParams<{
      id: string;
      type: keyof typeof GreenPlantsFormTypes;
    }>();

    const location = useLocation();
    const { backWithFallback } = useGoBackWithFallback();
    const { state: editedObject } = location;

    const type = 'area';
    const defaultIndex = Object.values(GreenPlantsFormTypes).indexOf(type);
    const [media, setMedia] = useState<IUploadMedia[]>([]);
    const { errorNotify } = useNotifications();
    const history = useHistory();

    useUpdateBreadcrumbs([
      { breadcrumb: appLocale.common.data, path: '/data' },
      {
        breadcrumb: appLocale.subsystems.greenplants,
        path: GreenPlantsPlatformUrl,
      },
      {
        breadcrumb: 'Площадки',
        path: `${GreenPlantsPlatformUrl}/areas/${entityType}`,
      },
    ]);

    const { editArea, isLoading: isLoadingEdit } = useAreaEditItem(entityType);
    const { dictionaries, isLoading: isLoadingDictionaries } = useFloraDictionaries();
    const { dictionaries: lawnsDictionaries, isLoading } = useLawnsDictionaries();

    const areaParams = editedObject?.parameters;
    const parameters = useMemo(
      () => normalizeExistParameters(areaParams, dictionaries.plantParameters, dictionaries.areaParameters),
      [dictionaries.areaParameters, dictionaries.plantParameters, areaParams]
    );

    const initialFlora = editedObject ? { ...editedObject, parameters } : greenPlantsFormInitialValues.area;

    const onError = useCallback(
      (message?: string | null) => {
        errorNotify({
          key: message || 'file/create/error',
          message: message || ERROR_MESSAGES.CREATE,
        });
      },
      [errorNotify]
    );

    const onSubmit = useCallback(
      async (values: Partial<TGreenPlantsForm>) => {
        await editArea(values.area);

        if (dictionaries?.objectType) {
          await updateFiles(id, dictionaries?.objectType[0].value as UploadObjectType, media)
            .then(() => {
              backWithFallback(`${GreenPlantsPlatformUrl}/${entityType}/areas`);
            })
            .catch((error: Error) => {
              onError(error.message);
              history.push(`${GreenPlantsFloraUrl}/edit/${id}?openAttachmentsTab=true`);
            });
        }
      },
      [editArea, dictionaries?.objectType, id, media, backWithFallback, entityType, onError, history]
    );

    return (
      <Component
        url={`${GreenPlantsPlatformUrl}/${entityType}/areas`}
        isEditMode
        areaEntity={entityType}
        header="Редактирование результатов"
        defaultIndex={defaultIndex}
        initialValues={{
          ...greenPlantsFormInitialValues,
          area: initialFlora,
        }}
        type={type}
        isSubmitting={isLoadingEdit || isLoadingDictionaries || isLoading}
        onSubmit={onSubmit}
        floraDictionaries={dictionaries}
        lawnsDictionaries={lawnsDictionaries}
        media={media}
        setMedia={setMedia}
        registryId={id}
      />
    );
  });

export { withEditArea };
