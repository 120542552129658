import { IComplaint, IIncident } from '../types/types';
import { DateCell } from 'components/table/DateCell';

export const attachedAppealsColumns = [
    {
      title: 'Номер обращения',
      field: 'number',
    },
    {
      title: 'Номер поручения',
      field: 'assignment.number',
    },
    {
      title: 'Дата обращения',
      field: 'date',
      render: (row: IComplaint) => <DateCell date={row.date} />,
    },
    {
      title: 'ФИО Заявителя',
      field: 'citFio',
    },
    {
      title: 'Адрес заявителя',
      field: 'citAddress',
    },
    {
      title: 'Адрес происшествия',
      render: (row: IComplaint) => (
        <>
          {row.incidents?.map((item: IIncident) => item.address).join((', '))}
        </>
      ),
      sorting: false,
    },
    {
      title: 'Срок',
      field: 'assignment.assignmentExecution',
      render: (row: IComplaint) => <DateCell date={row.assignment.assignmentExecution} />,
    },
    {
      title: 'Статус обращения',
      field: 'statusName',
    },
  ];

export const AttachedAppealsInitialValues: any = {
  complaintMinDate: {
    key: 'date',
    operation: 'GTE',
    value: '',
  },
  complaintMaxDate: {
    key: 'date',
    operation: 'LTE',
    value: '',
  },
  executionDateMin: {
    key: 'assignment.execution',
    operation: 'GTE',
    value: '',
  },
  executionDateMax: {
    key: 'assignment.execution',
    operation: 'LTE',
    value: '',
  },
  complaintThemeId: {
    key: 'complaintThemeId',
    operation: 'EQ',
    value: '',
  },
  odorTypeId: {
    key: 'odorTypeId',
    operation: 'EQ',
    value: '',
  },
  odorSourceTypeId: {
    key: 'odorSourceTypeId',
    operation: 'EQ',
    value: '',
  },
  noiseSourceTypeId: {
    key: 'noiseSourceTypeId',
    operation: 'EQ',
    value: '',
  },
  number: {
    key: 'number',
    operation: 'EQ',
    value: '',
  },
  assignmentNumber: {
    key: 'assignment.number',
    operation: 'EQ',
    value: '',
  },
  citFio: {
    key: 'citFio',
    operation: 'CONTAINS',
    value: '',
  },
  citAddress: {
    key: 'citAddress',
    operation: 'CONTAINS',
    value: '',
  },
  incidentAddress: {
    key: 'incidents.address',
    operation: 'CONTAINS',
    value: '',
  },
};
