import { ITableContext, ITask } from '../../../types';
import React, { useContext } from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { raidRowCounter } from '../Utils/Utils';
import { TaskLine } from '../TaskLine';
import { TableContext } from 'pages/citizenAppeal/RaidJournal/RaidTable/CustomRaidTable';

interface IProps {
  shift: string;
  tasks: ITask[];
  number: number;
  id: string;
}

export const RaidLine = ({ shift, tasks, number, id }: IProps) => {
  const { PEL } = useContext<ITableContext>(TableContext);
  const raidsRowSpan = raidRowCounter(tasks, PEL);
  return (
    <React.Fragment key={id}>
      <TableRow>
        <TableCell rowSpan={raidsRowSpan}>{shift}</TableCell>
        <TableCell rowSpan={raidsRowSpan}>{number}</TableCell>
      </TableRow>
      {tasks.map((task: ITask, index) => (
        <TaskLine key={index} task={task} />
      ))}
    </React.Fragment>
  );
};
