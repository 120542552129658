import { Filter } from 'features/Filter';
import { createInitialValuesToFormik, createValuesToRequest } from 'features/Filter/utils';
import React, { useMemo } from 'react';
import { IFilterItem, TFilterDTO } from 'models';
import { SelectOption } from 'components/common';
import combineFilters from 'lib/utils/combineFilters';

type TNSIReagentsSitesFilterTypes =
  | 'name'
  | 'subsystemId'
  | 'code'
  | 'okrugId'
  | 'rayonId'
  | 'address'
  | 'status'
  | 'deleted';
type TNSIReagentsSitesFilterDTO = TFilterDTO<TNSIReagentsSitesFilterTypes>;
type FilterInitialValues = Record<TNSIReagentsSitesFilterTypes, TNSIReagentsSitesFilterDTO['filters'][number]>;

const initialValues: FilterInitialValues = {
  name: {
    key: 'name',
    operation: 'CONTAINS',
    value: '',
  },
  subsystemId: {
    key: 'subsystemId',
    operation: 'EQ',
    value: null,
  },
  code: {
    key: 'code',
    operation: 'EQ',
    value: '',
  },
  okrugId: {
    key: 'okrugId',
    operation: 'EQ',
    value: null,
  },
  rayonId: {
    key: 'rayonId',
    operation: 'EQ',
    value: null,
  },
  address: {
    key: 'address',
    operation: 'CONTAINS',
    value: '',
  },
  status: {
    key: 'status',
    operation: 'EQ',
    value: null,
  },
  deleted: {
    key: 'deleted',
    operation: 'EQ',
    value: false,
  },
};

interface Props {
  isLoading: boolean;
  onSubmit: (values: FilterInitialValues) => void;
  okrugs?: SelectOption[];
  rayons?: SelectOption[];
  statuses?: SelectOption[];
  subsystems?: SelectOption[];
  filters: TFilterDTO['filters'];
}

const initialValuesToFormik = createInitialValuesToFormik(initialValues);

function NSIWaterSitesFilters({
  isLoading,
  onSubmit,
  okrugs = [],
  rayons = [],
  statuses = [],
  subsystems = [],
  filters,
  children,
}: React.PropsWithChildren<Props>) {
  const presetValues = useMemo<{ key: string; value: IFilterItem['value'] }[]>(() => {
    const preset: { key: string; value: IFilterItem['value'] }[] = [];
    const initialFilters = combineFilters(initialValues, filters);
    Object.keys(initialFilters).forEach((key) => {
      preset.push({ key: `${key}`, value: initialFilters[key].value });
    });
    return preset;
  }, [filters]);

  return (
    <Filter<typeof initialValuesToFormik>
      isLoading={isLoading}
      isDisabled={isLoading}
      onSubmit={(values) => {
        onSubmit(createValuesToRequest(initialValues, values));
      }}
      initialValues={initialValuesToFormik}
      mainFields={[
        {
          type: 'TEXT',
          name: 'name',
          label: 'Наименование',
        },
        {
          type: 'SELECT',
          name: 'subsystemId',
          label: 'Модуль подсистемы',
          options: subsystems,
        },
        {
          type: 'TEXT',
          name: 'code',
          label: 'Код участка',
        },
        {
          type: 'SELECT',
          name: 'okrugId',
          label: 'Округ',
          options: okrugs,
        },
        {
          type: 'SELECT',
          name: 'rayonId',
          label: 'Район',
          options: rayons,
        },
        {
          type: 'TEXT',
          name: 'address',
          label: 'Адрес',
        },
        {
          type: 'SELECT',
          name: 'status',
          label: 'Статус',
          options: statuses,
        },
      ]}
      presetValues={presetValues}
    >
      {children}
    </Filter>
  );
}

export default NSIWaterSitesFilters;
