import * as Yup from 'yup';
import { checkIsValidPair } from './ChartLine/helpers';
import { ERRORS } from 'constants/errors';

const errorText = {
  parameters: {
    min: 'Выберите как минимум один параметр',
    max: 'Параметров не больше 5',
  },
  sources: {
    min: 'Выберите как минимум один источник',
    max: 'Источников не больше 5',
  },
};

export const validationSchema = Yup.object().shape({
  startDate: Yup.string().required(ERRORS.required),
  stopDate: Yup.string()
    .required(ERRORS.required)
    .test({
      name: 'stopDate',
      test: (stopDate, obj) => {
        const startDate = obj?.parent?.startDate;
        return checkIsValidPair(startDate, stopDate);
      },
      message: 'Параметр "До" не может быть раннее или равен "От"',
    }),
  parameters: Yup.array()
    .min(1, errorText.parameters.min)
    .max(5, errorText.parameters.max)
    .of(Yup.string())
    .required(ERRORS.required),
  sources: Yup.array()
    .min(1, errorText.sources.min)
    .max(5, errorText.sources.max)
    .of(Yup.string())
    .required(ERRORS.required),
});
