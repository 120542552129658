import { TDictionaryItemDictionary } from 'models';
import { Grid, GridItem, Heading } from '@chakra-ui/react';
import { Field, useFormikContext } from 'formik';
import React, { ChangeEvent, memo, useMemo } from 'react';
import { cloneDeep, get } from 'lodash';
import { MoreLessNumberInput } from '../../../../../components/common/Input/more-less-number-input';

interface IProps {
  parameterIds: string[];
  parametersDictionary: TDictionaryItemDictionary[];
  prefix: string;
  hideHeading?: boolean;
  isDisabled?: boolean;
}

const fieldNames = ['31,5', '63', '125', '250', '500', '1000', '2000', ' 4000', '8000', 'Уровень звука'];

const NoiseLevels = ({ parameterIds, parametersDictionary, prefix, hideHeading, isDisabled }: IProps) => {
  const { values, setFieldValue } = useFormikContext();

  const onFieldChange = (parameterId: string, value: string) => {
    const parameters = cloneDeep(get(values, prefix) || []);
    const parametersItemIndex = parameters?.findIndex((item: any) => item.parameterId === parameterId);

    if (parametersItemIndex >= 0) {
      setFieldValue(`${prefix}[${parametersItemIndex}].value`, value);
    } else {
      const dictItem = parametersDictionary.find((item) => item.value === parameterId);

      if (!dictItem) return;

      const newValue = {
        name: dictItem.name,
        parameterId: dictItem.value,
        type: dictItem.type,
        value,
        valueAsString: String(value)
      };
      parameters.push(newValue);

      setFieldValue(prefix, [...parameters]);
    }
  };

  const getValue = (parameterId: string) => {
    const parameter = get(values, prefix)?.find((item: any) => item.parameterId === parameterId);
    if (parameter) {
      return parameter;
    }
    return '';
  };

  const parameters = useMemo(() => cloneDeep(get(values, prefix) || []), [values, prefix]);

  return (
    <Grid width="100%" gap={2} templateColumns="repeat(5, 1fr)">
      {!hideHeading && (
        <GridItem colSpan={5}>
          <Heading size="xs">
            Уровни звукового давления в дБ в октавных полосах со среднегеометрическими частотами (в Гц)
          </Heading>
        </GridItem>
      )}

      {parameterIds.map((parameterId, index) => {
        const parametersItemIndex = parameters?.findIndex((item: any) => item.parameterId === parameterId);

        return (<Field
          key={parameterId}
          name={parametersItemIndex >= 0 ? `${prefix}[${parametersItemIndex}]` : undefined}
          value={getValue(parameterId)}
          as={MoreLessNumberInput}
          label={`${fieldNames[index]} ${(index !== 9 ? 'Гц' : '')}`}
          onChange={(event: ChangeEvent<HTMLInputElement>) => onFieldChange(parameterId, event.target.value)}
          isDisabled={isDisabled}
        />);
      })}
    </Grid>
  );
};

export default memo(NoiseLevels);
