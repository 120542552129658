import React from 'react';
import * as yup from 'yup';
import { createTouchedErrors } from 'features/create-touched-errors';
import { FormikProvider, useFormik } from 'formik';
import { DeviceModel } from 'pages/equipment/Models/types';
import { FormFields } from 'pages/equipment/Models/components/DeviceModelForm/FormFields';
import { requiredError } from 'models/schemas/constans';

export const validationSchema = yup.object().shape({
  modelName: yup.string().required(requiredError),
  vendor: yup.string().required(requiredError),
});

interface DeviceModelFormProps {
  initialValues: Partial<DeviceModel>;
  onSubmit: (values: { deviceIds: string[]; modelName: string; sensorType: string; vendor: string }) => void;
  isDisabled: boolean;
  onCancel: () => void;
}

export const DeviceModelForm: React.FC<DeviceModelFormProps> = ({ initialValues, ...props }) => {
  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    validationSchema,
    initialValues,
    onSubmit: (values: any) => {
      const valuesToEdit = props.isDisabled
        ? {
            deviceIds: values.deviceIds || [],
            sensorType: values.sensorType,
            modelName: values.modelName,
            vendor: values.vendor,
          }
        : values;

      props.onSubmit(valuesToEdit);
    },
  });

  return (
    <FormikProvider
      value={{
        ...formik,
        errors: createTouchedErrors(formik.errors, formik.touched),
      }}
    >
      <FormFields onCancel={props.onCancel} isDisabled={props.isDisabled} />
    </FormikProvider>
  );
};
