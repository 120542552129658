import { Field, FormikProvider, useFormik } from 'formik';
import React, { useEffect, useMemo } from 'react';
import { Input, Select } from 'components/common';
import { Flex, Grid } from '@chakra-ui/react';
import { TDictionariesArray } from 'models/dictionaries';
import {
  getSelectOptionsFromDictionaryByName,
  getSelectOptionsFromDictionaryByNameWithTransformation,
} from 'features/get-select-options-from-dictionary';
import { IFilterItem } from 'models';
import combineFilters from 'lib/utils/combineFilters';
import FilterFormControls from 'components/form/FilterFormControls';

interface FilterProps {
  isLoading?: boolean;
  isDisabled?: boolean;
  dictionaries: {
    data: TDictionariesArray;
  } | null;
  onSubmit: (values: any) => void;
  filters: IFilterItem[];
}

const initialValues: Record<string, IFilterItem> = {
  fieldAreaId: {
    key: 'fieldAreaId',
    operation: 'EQ',
    value: null,
  },
  subsoilUserId: {
    key: 'subsoilUserId',
    operation: 'EQ',
    value: null,
  },
  areaNumber: {
    key: 'areaId',
    operation: 'EQ',
    value: null,
  },
  showDisabled: {
    key: 'deleted',
    operation: 'EQ',
    value: false,
  },
  // showDisabled - value равно '' если чекбокс включен и нужно получить с Backend все значения (этот объект удалиться при отправке)
  // value равно false - если нужно показать только неудаленные записи
  licenceNumber: {
    key: 'licenceNumber',
    operation: 'CONTAINS',
    value: null,
  },
};

export const SubsoilFilter = ({ isLoading, isDisabled, onSubmit, dictionaries, filters, ...props }: FilterProps) => {
  const {
    values,
    errors,
    getFieldMeta,
    getFieldHelpers,
    getFieldProps,
    handleReset,
    submitForm,
    setFieldValue,
    ...formik
  } = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    initialValues,
    onSubmit,
  });

  const fieldAreas = useMemo(
    () => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'fieldAreas'),
    [dictionaries]
  );

  const users = useMemo(() => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'users'), [dictionaries]);

  const areas = useMemo(
    () => getSelectOptionsFromDictionaryByNameWithTransformation(dictionaries?.data, 'areas', 'number'),
    [dictionaries]
  );

  useEffect(() => {
    const initialFilters = combineFilters(initialValues, filters);
    Object.keys(initialFilters).forEach((key) => {
      setFieldValue(`${key}.value`, initialFilters[key].value);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormikProvider
      value={{
        values,
        errors,
        getFieldMeta,
        getFieldHelpers,
        getFieldProps,
        handleReset,
        submitForm,
        setFieldValue,
        ...formik,
      }}
    >
      <Flex flexDirection="row" justifyContent="space-between">
        <Grid gap="12px" rowGap="8px" gridTemplateColumns="repeat(2, 225px) 250px 250px" {...props}>
          <Field
            as={Select}
            name="areaNumber.value"
            label="Участок недр"
            value={values.areaNumber.value}
            options={areas}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFieldValue('areaNumber.value', e.target.value);
            }}
          />
          <Field
            as={Select}
            name="subsoilUserId.value"
            label="Недропользователь"
            value={values.subsoilUserId.value}
            options={users}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFieldValue('subsoilUserId.value', e.target.value);
            }}
          />
          <Field
            as={Select}
            name="fieldAreaId.value"
            label="Участок месторождения"
            value={values.fieldAreaId.value}
            options={fieldAreas}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFieldValue('fieldAreaId.value', e.target.value);
            }}
          />
          <Field as={Input} name="licenceNumber.value" label="Номер лицензии" value={values.licenceNumber.value} />
        </Grid>

        <FilterFormControls
          onReset={(e) => {
            handleReset(e);
            void submitForm();
          }}
          onSubmit={submitForm}
          isLoading={isLoading}
          isDisabled={isDisabled}
        />
      </Flex>
    </FormikProvider>
  );
};
