import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import { createGreenPlantsAddUrl, GreenPlantsLawnsUrl, GreenPlantsRootPath } from 'pages/routes/GreenPlants';
import appLocale from 'constants/appLocale';
import { GreenPlantsForm } from '../../Form/GreenPlantsForm';
import { greenPlantsFormInitialValues, GreenPlantsFormTypes, TGreenPlantsForm } from '../../Form/types';
import { useLawnsDictionaries } from '../useLawnsDictionaries';
import { useLawnsAddItem } from './useLawnAddItem';
import { useCallback, useState } from 'react';
import { ERROR_MESSAGES } from 'constants/messages';
import { IUploadMedia } from 'components/common/FileUploadDragAndDrop/types';
import { useNotifications } from 'hooks';
import { useHistory } from 'react-router-dom';
import { updateFiles } from 'api/services/media';
import { useObjectType } from 'hooks/useObjectType';
import { useGoBackWithFallback } from '../../../../../lib/utils/goBackWithFallback';

const withAddLawns = (Component: typeof GreenPlantsForm) => () => {
  const type = 'lawns';
  const [media, setMedia] = useState<IUploadMedia[]>([]);
  const { errorNotify } = useNotifications();
  const history = useHistory();
  const { backWithFallback } = useGoBackWithFallback();

  useUpdateBreadcrumbs([
    { breadcrumb: appLocale.common.data, path: '/data' },
    {
      breadcrumb: appLocale.subsystems.greenplants,
      path: GreenPlantsRootPath,
    },
    {
      breadcrumb: 'Газоны',
      path: GreenPlantsLawnsUrl,
    },
    {
      breadcrumb: 'Добавление обследования',
      path: createGreenPlantsAddUrl('lawns'),
    },
  ]);

  const onError = useCallback(
    (message?: string | null) => {
      errorNotify({
        key: message || 'file/create/error',
        message: message || ERROR_MESSAGES.CREATE,
      });
    },
    [errorNotify]
  );

  const defaultIndex = Object.values(GreenPlantsFormTypes).indexOf(type);

  const { addLawns, isLoading } = useLawnsAddItem();

  const { dictionaries, result, isLoading: isDictsLoading } = useLawnsDictionaries();

  const objectType = useObjectType(result);

  const onSubmit = useCallback(
    async (values: Partial<TGreenPlantsForm>) => {
      const response = await addLawns(values.lawns);

      if ('registryId' in response) {
        await updateFiles(response.registryId, objectType, media)
          .then(() => {
            backWithFallback(GreenPlantsLawnsUrl);
          })
          .catch((error: Error) => {
            onError(error.message);
            history.push(`${GreenPlantsLawnsUrl}/edit/${response.registryId}?openAttachmentsTab=true`, response);
          });
      }
    },
    [addLawns, backWithFallback, history, media, objectType, onError]
  );

  return (
    <Component
      header="Добавление результатов"
      defaultIndex={defaultIndex}
      initialValues={greenPlantsFormInitialValues}
      type={type}
      isSubmitting={isLoading || isDictsLoading}
      onSubmit={onSubmit}
      lawnsDictionaries={dictionaries}
      media={media}
      setMedia={setMedia}
    />
  );
};

export { withAddLawns };
