import { FormHeader, LoaderItem } from 'components/common';
import { createTouchedErrors } from 'features/create-touched-errors';
import { FormikProvider, useFormik } from 'formik';
import { useRequest } from 'hooks/useRequest';
import { DangerProcessSchema } from 'models/schemas/dangerprocess/danger';
import moment from 'moment';

import { useHistory } from 'react-router-dom';
import { GeoecologicalRootPath } from 'pages/data/GeoecologicalProcesses/consts';
import FieldsForm from './FieldsForm';
import React, { useState } from 'react';
import { IUploadMedia } from 'components/common/FileUploadDragAndDrop/types';
import { updateFiles } from 'api/services/media';
import { useObjectType } from 'hooks/useObjectType';
import { DangerProcessItem } from 'pages/data/GeoecologicalProcesses/DangerouslyGeologicalProcesses/DangerProcess/types';
import { useDictionary } from 'hooks/useDictionary';
import { useGoBackWithFallback } from 'lib/utils/goBackWithFallback';
import { useEnhancedNotification } from 'hooks';

export const initialEmptyValues = {
  areaCode: '',
  areaId: '',
  deleted: false,
  fileIds: [],
  measuringDate: moment().format(),
  pointCode: '',
  pointId: '',
  value: '',
};

export const AddDangerProcessReport = () => {
  const { backWithFallback } = useGoBackWithFallback();
  const history = useHistory();
  const { dictionaries, isDictionariesLoading } = useDictionary({
    url: '/geoecology/v10/geoecology/dangerprocess/dictionaries',
  });
  const { onErrorCreate, onSuccessCreate } = useEnhancedNotification();
  const [media, setMedia] = useState<IUploadMedia[]>([]);
  const objectType = useObjectType(dictionaries);

  const successHandler = (response: DangerProcessItem | null) => {
    if (!response || !response.measuringId) {
      onErrorCreate();
      return;
    }

    onSuccessCreate();

    updateFiles(response.measuringId, objectType, media)
      .then(() => {
        backWithFallback(`${GeoecologicalRootPath}/monitoring/danger/dangerprocess`);
      })
      .catch((error: Error) => {
        onErrorCreate(error.message);
        history.replace(
          `${GeoecologicalRootPath}/monitoring/danger/dangerprocess/edit/row/${response.measuringId}`,
          response,
        );
      });
  };

  const { post, isLoading: isPostLoading } = useRequest<DangerProcessItem, any>(
    '/geoecology/v10/geoecology/dangerprocess/entry/',
    successHandler,
    onErrorCreate,
  );

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    initialValues: initialEmptyValues,
    validationSchema: DangerProcessSchema,
    onSubmit: (values) => post(values),
  });

  if (isDictionariesLoading || isPostLoading) {
    return <LoaderItem />;
  }

  return (
    <FormikProvider
      value={{
        ...formik,
        errors: createTouchedErrors(formik.errors, formik.touched),
      }}
    >
      <FormHeader
        onBackClick={() => backWithFallback(`${GeoecologicalRootPath}/monitoring/danger/dangerprocess`)}
        header="Добавление результатов измерения"
      />
      <FieldsForm
        dictionaries={dictionaries}
        onCancel={() => backWithFallback(`${GeoecologicalRootPath}/monitoring/danger/dangerprocess`)}
        onMediaChange={setMedia}
        media={media}
      />
    </FormikProvider>
  );
};
