import React from 'react';
import { FormikProvider, useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import { FieldsForm } from './FieldsForm';
import { FTPRootPath, UploadConfig } from 'pages/admin/FTP';
import { baseAdminUrl } from 'pages/admin';
import { useRequest } from 'hooks/useRequest';
import { FormHeader } from 'components/common';
import { Box } from '@chakra-ui/react';
import { UploadConfigSchema } from 'models/schemas/admin/ftpUpload';
import { useEnhancedNotification } from 'hooks';
import { createTouchedErrors } from 'features/create-touched-errors';

export const initialEmptyValues: Partial<UploadConfig> = {
  exchangeId: '',
  name: '',
  status: true,
  templateId: '',
  isDeleted: false,
};

export const AddFTPUpload = () => {
  const { onSuccessCreate, onErrorCreate } = useEnhancedNotification();

  const onSuccess = () => {
    history.push(`${FTPRootPath}/upload`);
    onSuccessCreate();
  };

  const { post } = useRequest(`${baseAdminUrl}/upload-config`, onSuccess, onErrorCreate);

  const history = useHistory();

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: true,
    validationSchema: UploadConfigSchema,
    initialValues: initialEmptyValues,
    onSubmit: (values) => {
      void post(values);
    },
  });

  return (
    <FormikProvider
      value={{
        ...formik,
        errors: createTouchedErrors(formik.errors, formik.touched),
      }}
    >
      <Box>
        <FormHeader url={`${FTPRootPath}/upload`} header="Добавление нового набора" />
        <FieldsForm
          onCancel={() => {
            history.push(`${FTPRootPath}/upload`);
          }}
        />
      </Box>
    </FormikProvider>
  );
};
