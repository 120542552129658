import { useEffect } from 'react';
import { useRequest } from 'hooks';
import { apiAuthPaths } from 'api/services/auth';

export type TSubsystem = {
  actions: string[];
  id: string;
  module: string;
  name: string;
  parentId: string;
};

export const useGetSubsystemRights = (subsystemId?: string) => {
  const { get, isLoading: isRightLoading, result: userRights } =
    useRequest<TSubsystem | null>(`${apiAuthPaths.user.subsystem}${subsystemId ? `/${subsystemId}` : ''}`);

  useEffect(() => {
    get();
  }, [get]);

  return {
    get,
    userRights,
    isRightLoading
  };
};
