export const paramsIds = {
  equivalentNoise: '77976cf9-8db5-4c4a-a476-35a5b1b978cb',
  noiseMin: 'a9fb8aad-2b4f-411a-a63e-767a2282d825',
  noiseMinDate: '4e1cd6ab-ccef-41fc-a8f9-7fe1dc4a60ae',
  maxNoise: 'f795cab4-7032-4f65-b588-e110f8f9ab23',
  maxNoiseDate: '7a96180a-23f0-402d-8775-4c1aba8b9840',
  gts31: 'cda689a9-ef9e-414d-a8f7-8755fed1991f',
  gts63: 'f835e4ae-df21-4ac9-839b-6b444e0cec2b',
  gts125: '2faa0edc-a17b-4714-a82c-ac1bc6d19567',
  gts250: 'ff1de322-b61e-40f4-b482-a3f396444250',
  gts500: '4b2b3b21-5ebf-42a4-b1fe-536571c64448',
  gts1000: '3e592e54-266a-46ef-9a5f-64e0179d2444',
  gts2000: 'b1fddec2-358f-4160-a7e7-b460170860ab',
  gts4000: 'c5efd944-53da-4938-b385-725bb2396d74',
  gts8000: 'd897fc2f-3f65-4cb0-ad6b-2184887ee8b0'
};
