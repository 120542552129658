import moment from 'moment';

export const initialEmptyValues = {
  address: '',
  name: '',
  stationId: '',
  instructionDate: moment().format(),
  number: '',
  startDate: moment().format(),
  stopDate: moment().format(),
};
