import { WPZDto, WPZParameterKeys } from 'pages/data/Water/WPZRoot/WPZ/consts/types';
import appLocale from 'constants/appLocale';
import { createLocalDateDDMMYYYY } from 'lib/create-date';
import { ColumnWithFilter } from 'lib/utils/tableFilter';
import { TableFilterTypeEnum } from 'components/table/filters/TableFilter';

const columns: Array<ColumnWithFilter<WPZDto>> = [
  {
    title: appLocale.bottomSoil.waterObject,
    field: 'waterObject',
    filterType: TableFilterTypeEnum.string,
  },
  {
    title: appLocale.wpz.date,
    field: 'date',
    render: (row) => createLocalDateDDMMYYYY(row.date || ''),
  },
  {
    title: appLocale.common.areaCode,
    field: 'areaCode',
    filterType: TableFilterTypeEnum.string,
  },
  {
    title: appLocale.common.area,
    field: 'region',
    filterType: TableFilterTypeEnum.string,
  },
  {
    title: appLocale.common.district,
    field: 'district',
    filterType: TableFilterTypeEnum.string,
  },
  {
    title: appLocale.wpz.oopt,
    field: 'oopt',
    filterType: TableFilterTypeEnum.string,
  },
  ...WPZParameterKeys.map((key) => ({
    title: appLocale.wpz[key],
    field: key,
    filterType: TableFilterTypeEnum.number,
  })),

  {
    title: appLocale.wpz.siteConditions,
    field: 'siteCondition',
    filterType: TableFilterTypeEnum.select,
  },
];

export default columns;
