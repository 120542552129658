import { As, FormControlProps, forwardRef } from '@chakra-ui/react';
import React, { memo, useCallback, useMemo } from 'react';
import { FormikFieldProps } from '../types';
import { Input, InputProps } from './input';

export const TemperatureInput = memo(
  forwardRef<InputProps & Partial<FormikFieldProps> & { disabled?: boolean; isRequired?: FormControlProps['isRequired']; mask?: string },
    As<InputProps & Partial<FormikFieldProps>>>(
    (
      { value, ...props },
      ref,
    ) => {
      const val = useMemo(() => {
        if (!value) return '';
        if (String(value).includes('-')) {
          return String(value)
            .replace('-', 'минус ')
            .replace('.', ',');
        }

        if (String(value) === '0') {
          return String(value).replace('.', ',');
        }

        if (String(value)[0]?.match(/\d/)) {
          return `плюс ${String(value).replace('.', ',')}`;
        }

        return String(value).replace('.', ',');
      }, [value]);

      const isValid = useCallback((val: string) => String(val)?.match(/(плюс|минус)\s(\d+)(,\d+)?$/), []);
      const valid = useMemo(() => isValid(val), [val, isValid]);

      const handleChange = useCallback(
        (innerValue: string) => {
          let value = innerValue;

          if (isValid(value)) {
            value = innerValue
              .replace(',', '.')
              .replace('плюс ', '')
              .replace('минус ', '-');
          }

          if (props.onChange) {
            props.onChange({
              target: { value, name: props.name },
            } as React.BaseSyntheticEvent);
          }
        },
        [props, isValid]
      );

      return (
        <Input
          ref={ref}
          {...props}
          error={props.error || (!valid && value && value !== '0' ? 'Перед значением температуры введите "плюс " или "минус "' : '')}
          value={val}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e.target.value)}
        />
      );
    },
  ),
);
