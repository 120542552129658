import { IFilterItem } from 'models';
import { TableFilterTypeEnum } from 'components/table/filters/TableFilter';
import { createLocalDateDDMMYYYY, createLocalDateDDMMYYYYWithTime } from 'lib/create-date';
import { IAppealsTableViewResponse } from '../Types/Types';
import { TruncatedStringPopoverCell } from '../../../../components/table/TruncatedStringPopoverCell';
import React from 'react';
import { DefaultTableCellSize } from '../../../../components/table/DefaultTableCellSize';
import { ColumnWithFilter } from '../../../../lib/utils/tableFilter';

export const appealsInitialValues: { [s: string]: IFilterItem } = {
  complaintDateMin: {
    key: 'complaintDate',
    operation: 'GTE',
    value: '',
  },
  complaintDateMax: {
    key: 'complaintDate',
    operation: 'LTE',
    value: '',
  },
  assigmentDateMin: {
    key: 'assignmentDate',
    operation: 'GTE',
    value: '',
  },
  assigmentDateMax: {
    key: 'assignmentDate',
    operation: 'LTE',
    value: '',
  },
  isReplied: {
    key: 'isReplied',
    operation: 'EQ',
    value: '',
  },
  isRenovation: {
    key: 'isRenovation',
    operation: 'EQ',
    value: '',
  },
  isDuplicate: {
    key: 'isDuplicate',
    operation: 'EQ',
    value: '',
  },
  isRaidNotNeed: {
    key: 'isRaidNotNeed',
    operation: 'EQ',
    value: '',
  },
  isRedirect: {
    key: 'isRedirect',
    operation: 'EQ',
    value: '',
  },
  isRaidComplete: {
    key: 'isRaidComplete',
    operation: 'EQ',
    value: '',
  },
  isNmu: {
    key: 'isNmu',
    operation: 'EQ',
    value: '',
  },
  noiseSourceTypeId: {
    key: 'noiseSourceTypeId',
    operation: 'EQ',
    value: null,
  },
  odorSourceTypeId: {
    key: 'odorSourceTypeId',
    operation: 'EQ',
    value: null,
  },
  odorTypeId: {
    key: 'odorTypeId',
    operation: 'EQ',
    value: null,
  },
  sourceId: {
    key: 'sourceId',
    operation: 'EQ',
    value: null,
  },
  originId: {
    key: 'originId',
    operation: 'EQ',
    value: null,
  },
  districtId: {
    key: 'districtId',
    operation: 'EQ',
    value: null,
  },
  regionId: {
    key: 'regionId',
    operation: 'EQ',
    value: null,
  },
};

const generalColumns: ColumnWithFilter<IAppealsTableViewResponse>[] = [
  {
    title: 'Номер поручения',
    field: 'assignmentNumber',
    filterType: TableFilterTypeEnum.string,
  },
  {
    title: 'Дата поручения',
    field: 'assignmentDate',
    render: (row: IAppealsTableViewResponse) => createLocalDateDDMMYYYY(row.assignmentDate),
  },
  {
    title: 'Номер обращения',
    field: 'complaintNumber',
    filterType: TableFilterTypeEnum.string,
  },
  {
    title: 'Дата обращения',
    field: 'complaintDate',
    render: (row: IAppealsTableViewResponse) => createLocalDateDDMMYYYYWithTime(row.complaintDate),
  },
  {
    title: 'Основание',
    field: 'originName',
    filterType: TableFilterTypeEnum.string,
    render: (row) => <DefaultTableCellSize>{row.originName}</DefaultTableCellSize>,
  },
  {
    title: 'ФИО заявителя',
    field: 'citFio',
    filterType: TableFilterTypeEnum.string,
  },
  {
    title: 'Адрес заявителя',
    field: 'citAddress',
    filterType: TableFilterTypeEnum.string,
    render: (row) => <DefaultTableCellSize>{row.citAddress}</DefaultTableCellSize>,
  },
  {
    title: 'Контакты заявителя (телефон)',
    field: 'citPhone',
    filterType: TableFilterTypeEnum.string,
  },
  {
    title: 'Контакты заявителя (email)',
    field: 'citEmail',
    filterType: TableFilterTypeEnum.string,
  },
  {
    title: 'Адрес происшествия',
    field: 'incAddress',
    filterType: TableFilterTypeEnum.string,
    render: (row) => <DefaultTableCellSize>{row.incAddress}</DefaultTableCellSize>,
  },
];

const generalColumns2 = [
  {
    title: 'Повторная жалоба',
    field: 'isDuplicateText',
  },
  {
    title: 'Исполнитель',
    field: 'assignmentExecutor',
    filterType: TableFilterTypeEnum.string,
  },
  {
    title: 'Ответ направлен',
    field: 'isRepliedText',
  },
  {
    title: 'Перенаправление',
    field: 'isRedirectText',
  },
  {
    title: 'Рейд не нужен',
    field: 'isRaidNotNeedText',
  },
  {
    title: 'Рейд был',
    field: 'isRaidCompleteText',
  },
];

export const noiseColumns = [
  ...generalColumns,
  {
    title: 'Название объекта',
    field: 'territoryName',
    filterType: TableFilterTypeEnum.string,
  },
  {
    title: 'Округ происшествия',
    field: 'districtName',
    filterType: TableFilterTypeEnum.string,
  },
  {
    title: 'Район происшествия',
    field: 'regionName',
    filterType: TableFilterTypeEnum.string,
  },
  {
    title: 'Тип источника шума',
    field: 'noiseSourceTypeName',
    filterType: TableFilterTypeEnum.string,
  },
  {
    title: 'Суть обращения',
    field: 'complaintText',
    filterType: TableFilterTypeEnum.string,
    render: (row: any) => <TruncatedStringPopoverCell value={row.complaintText} isLargeText />,
  },
  {
    title: 'Реновация',
    field: 'isRenovationText',
  },
  ...generalColumns2,
];

export const atmoColumns = [
  ...generalColumns,
  {
    title: 'Округ',
    field: 'districtName',
    filterType: TableFilterTypeEnum.string,
  },
  {
    title: 'Район',
    field: 'regionName',
    filterType: TableFilterTypeEnum.string,
  },
  {
    title: 'Тип запаха',
    field: 'odorTypeName',
    filterType: TableFilterTypeEnum.string,
  },
  {
    title: 'Тип источника',
    field: 'odorSourceTypeName',
    filterType: TableFilterTypeEnum.string,
  },
  {
    title: 'Предполагаемый источник',
    field: 'sourceName',
    filterType: TableFilterTypeEnum.string,
  },
  {
    title: 'НМУ',
    field: 'isNmuText',
  },
  {
    title: 'Содержание обращения',
    field: 'complaintText',
    filterType: TableFilterTypeEnum.string,
    render: (row: any) => <TruncatedStringPopoverCell value={row.complaintText} isLargeText />,
  },
  ...generalColumns2,
];

const airUrls = 'air/list';
const noiseUrls = 'noise/list';
export const CitizenGeneralUrls = [noiseUrls, airUrls];
