import { SubsystemHeader } from 'components/common';
import { FC } from 'react';
import { ButtonProps } from '@chakra-ui/react';

interface ParametersHeaderProps {
  addButtonProps?: ButtonProps;
  title: string;
}

export const ParametersHeader: FC<ParametersHeaderProps> = ({ addButtonProps, children, title }) => (
  <SubsystemHeader
    title={title}
    addButtonProps={addButtonProps}
    addButtonText={addButtonProps?.title}
    filtersComponent={children}
  />
);
