import { Field, useFormikContext } from 'formik';
import { Input, Select, SelectOption } from 'components/common';
import React, { useCallback } from 'react';
import { TDictionaryItemDictionary } from 'models';

interface IProps {
  field: TDictionaryItemDictionary;
  isDisabled: boolean;
  prefix?: string;
  isRequired?: boolean;
}

const FormFieldFromDictionary = ({ prefix = 'parameters', field, isDisabled, isRequired }: IProps) => {
  const { setFieldValue, values, errors } = useFormikContext<any>();

  const getOptions = useCallback((field, value) => {
    const options: SelectOption[] = [];

    const fieldValues: Record<string, TDictionaryItemDictionary> | null =
      field.values && !Array.isArray(field.values) ? field.values : null;

    if (fieldValues) {
      Object.keys(fieldValues).forEach((key) => {
        const option = fieldValues[key];
        if (!option.deleted || value === option.value || value?.includes(option.value)) {
          options.push({ value: key, name: `${fieldValues[key].name || '-'}${option.deleted ? ' (удал.)' : ''}` });
        }
      });
    }
    return options;
  }, []);

  // @ts-ignore
  const error = field.parameterId && errors.parameters?.[field.parameterId];
  const name = prefix ? `${prefix}.${field.parameterId}[0]` : field.parameterId;
  let value = values[prefix]?.[`${field.parameterId}`]?.[0]?.value;

  if (!name) {
    return null;
  }

  const onChange = (value: any) => {
    const updateObject = {
      ...(field.parameterId ? values[prefix][field.parameterId]?.[0] : {}),
      value,
      parameterId: field.parameterId,
    };
    if (field.fieldType === 'DIC') {
      updateObject.dictionaryItemId = value;
    }
    setFieldValue(`${prefix}.${field.parameterId}`, [updateObject]);
  };

  switch (field.fieldType) {
    case 'NUMERIC':
      return (
        <Field
          as={Input}
          name={name}
          label={field.title}
          error={error}
          type="number"
          isDisabled={isDisabled}
          value={value || ''}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            onChange(event.target.value);
          }}
          isRequired={isRequired}
        />
      );
    case 'BOOL':
      return (
        <Field
          as={Select}
          name={name}
          label={field.title}
          options={[
            { value: true, name: 'Да' },
            { value: false, name: 'Нет' },
          ]}
          onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
            onChange(event.target.value);
          }}
          isDisabled={isDisabled}
          value={value || null}
          error={error}
          isRequired={isRequired}
        />
      );
    case 'DIC':
      value = values[prefix]?.[`${field.parameterId}`]?.[0]?.dictionaryItemId || null;
      return (
        <Field
          as={Select}
          name={name}
          label={field.title}
          options={getOptions(field, value)}
          isDisabled={isDisabled}
          error={error}
          value={value}
          onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
            onChange(event.target.value);
          }}
          isRequired={isRequired}
        />
      );
    case 'DICLIST':
      value = values[prefix]?.[`${field.parameterId}`]
        ?.filter((value: any) => !!value.dictionaryItemId)
        ?.map((values: any) => values.dictionaryItemId);

      return (
        <Field
          as={Select}
          name={name}
          label={field.title}
          options={getOptions(field, value)}
          isDisabled={isDisabled}
          error={error}
          multiple
          onChange={(event: { target: { value: string[] | null } }) => {
            const newValues = event.target.value || [];
            setFieldValue(
              `${prefix}.${field.parameterId}`,
              newValues.map((value) => ({
                value,
                dictionaryItemId: value,
                parameterId: field.parameterId,
              })),
            );
          }}
          value={value || null}
          isRequired={isRequired}
        />
      );
    default:
      return null;
  }
};

export default FormFieldFromDictionary;
