import { TOption } from './toption';

export enum ObjectType {
  REPORT = 'REPORT',
  DICTIONARY = 'DICTIONARY',
  SUBSYSTEM = 'SUBSYSTEM',
}

export const objectTypes: TOption[] = [
  {
    value: ObjectType.REPORT,
    name: 'Отчет',
  },
  {
    value: ObjectType.DICTIONARY,
    name: 'Справочник',
  },
  {
    value: ObjectType.SUBSYSTEM,
    name: 'Подсистема',
  },
];
