import { Route } from 'react-router-dom';
import ManagerPageV2 from '../Manager/ManagerPageV2';

const ManagerRoutes = () => (
  <>
    <Route exact path="/manager" component={ManagerPageV2} />
  </>
);

export default ManagerRoutes;
