import { Field, useFormikContext } from 'formik';
import { DefaultText, FieldsFormControls, FormHeader, FormWrapper, Input, LoaderItem, Select } from 'components/common';
import { FieldFormProps } from 'types/forms';
import React, { useMemo } from 'react';
import { Box, Grid, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import appLocale from 'constants/appLocale';
import NSIReagentsGroundGeneralInformationTab from 'pages/dictionaries/NSIReagents/NSIReagentsGround/NSIReagentsGroundForms/NSIReagentsGroundGeneralInformationTab';
import { getSelectOptionsFromDictionaryByName } from 'features/get-select-options-from-dictionary';
import { NSIReagentsListItem } from '../../types';

const NSIReagentsGroundFormFields = ({
  onCancel,
  dictionaries,
  isLoading,
  isEditMode,
  editedObject,
}: FieldFormProps) => {
  const { handleSubmit, values, errors } = useFormikContext<NSIReagentsListItem>();

  const title = isEditMode ? 'Редактирование источника' : 'Добавление источника';

  const okrugs = useMemo(() => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'okrugs'), [dictionaries]);
  const rayons = useMemo(() => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'rayons'), [dictionaries]);
  const pgrAreaTypesWithSubtypes = useMemo(
    () => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'pgrAreaTypesWithSubtypes'),
    [dictionaries],
  );

  const filteredRayons = useMemo(() => {
    if (values.okrugId && rayons) {
      return rayons.filter((item) => item.parent === values.okrugId);
    }
    return rayons;
  }, [rayons, values.okrugId]);

  const combinedTypes = useMemo(
    () =>
      pgrAreaTypesWithSubtypes.map((item) => ({
        name: `${item.name} (${item.parent})`,
        value: item.value,
      })),
    [pgrAreaTypesWithSubtypes],
  );

  if (isLoading) {
    return <LoaderItem />;
  }

  return (
    <Box flexDirection="column">
      <Tabs>
        <FormHeader onBackClick={onCancel} header={title}>
          <TabList>
            {[appLocale.common.generalInformation, appLocale.common.location].map((tab, i) => (
              <Tab key={i}>
                <DefaultText cursor="pointer">{tab}</DefaultText>
              </Tab>
            ))}
          </TabList>
        </FormHeader>
        <TabPanels>
          <TabPanel>
            <NSIReagentsGroundGeneralInformationTab
              isEditMode={isEditMode}
              dictionaries={dictionaries}
              editedObject={editedObject}
            >
              <FieldsFormControls onCancel={onCancel} handleSubmit={handleSubmit} />
            </NSIReagentsGroundGeneralInformationTab>
          </TabPanel>
          <TabPanel>
            <Box flexDirection="column">
              <FormWrapper>
                <Grid marginTop="30px" gap="15px" marginBottom={20}>
                  <Field as={Select} name="okrugId" label="Округ" options={okrugs} error={errors.okrugId} />
                  <Field as={Select} name="rayonId" label="Район" options={filteredRayons} error={errors.rayonId} />
                  <Field as={Input} name="address" label="Адрес" error={errors.address} />
                  <Field as={Input} name="landmark" label="Адресный ориентир" error={errors.landmark} />
                  <Field
                    as={Select}
                    name="pgrAreaTypeId"
                    label="Тип территории (Подтип территории)"
                    options={combinedTypes}
                    error={errors.pgrAreaTypeId}
                  />
                  <Field as={Input} name="latitude" label="Широта" error={errors.latitude} />
                  <Field as={Input} name="longitude" label="Долгота" error={errors.longitude} />
                  <FieldsFormControls onCancel={onCancel} handleSubmit={handleSubmit} showSave />
                </Grid>
              </FormWrapper>
            </Box>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default NSIReagentsGroundFormFields;
