import React, { memo, useMemo } from 'react';
import { Button } from 'components/common';
import { Box, Flex, Text } from '@chakra-ui/react';
import FileItemViewPDFPreview from 'features/UploadFileDragAndDrop/DragAndDrop/FileItem/FileItemViewPDFPreview';
import { Reports } from 'pages/home/icons';
import downloadFile from 'lib/utils/files/downloadFile';

interface Props {
  name: string;
  percentage: number;
  onDelete: () => void;
  file?: File;
  onFileClick?: () => void;
  isWrite?: boolean
}

const FileItemView = ({
  name,
  onDelete,
  percentage,
  file,
  onFileClick,
  isWrite = true
}: Props) => {
  const renderPreview = useMemo(() => {
    if (file?.type === 'application/pdf') {
      return <FileItemViewPDFPreview file={file} />;
    }
    if (file?.type.startsWith('image/')) {
      return <img alt={name} src={URL.createObjectURL(file)} />;
    }
    return <Flex justifyContent="center"><Reports /></Flex>;
  }, [file, name]);

  return (
    <Box width="100%">
      <Flex width="inherit" flexDirection="row" alignItems="center" justifyContent="space-between">
        {file && (
        <Box onClick={onFileClick} width="20%">
          {renderPreview}
        </Box>
        )}
        <Flex
          width={file ? '80%' : '100%'}
          pl={file ? 4 : 0}
          fontSize={16}
          lineHeight="20px"
          alignItems="center"
          justifyContent="space-between"
        >
          <Text isTruncated width="inherit" whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
            {name}
          </Text>

          <Flex>
            {file && (
              <Button
                variant="link"
                colorScheme="PrimaryButton"
                onClick={() => downloadFile(file, name)}
                p={2}
                hidden={!name}
              >
                Скачать
              </Button>
            )}

            <Button
              variant="link"
              colorScheme="ErrorButton"
              onClick={onDelete}
              p={2}
              hidden={!name}
              isDisabled={!isWrite}
            >
              Удалить
            </Button>
          </Flex>
        </Flex>
      </Flex>

      <Box borderRadius={3} width="100%" height={2} margin="7px 0 4px 0" color="menu.hover">
        <Box
          width={`${percentage}%`}
          height={2}
          borderRadius={3}
          transition="width 500ms ease-in-out"
          backgroundColor="menu.active"
        />
      </Box>
    </Box>
  );
};

export default memo(FileItemView);
