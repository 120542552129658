import React from 'react';
import { createLocalDateDDMMYYYY } from 'lib/create-date';
import { Flex } from '@chakra-ui/react';
import { TableFilterTypeEnum } from 'components/table/filters/TableFilter';
import { DateCell } from 'components/table/DateCell';
import { BooleanCell } from 'components/table/BooleanCell';
import { IResponse } from '../types/types';
import { DefaultTableCellSize } from 'components/table/DefaultTableCellSize';

export const noiseTerritoryColumns = [
  {
    title: 'Дата проведения рейда',
    field: 'raidDate',
    render: (row: IResponse) => createLocalDateDDMMYYYY(row.raidDate),
  },
  {
    title: 'Время суток',
    field: 'raidShiftName',
    filterType: TableFilterTypeEnum.string,
  },
  {
    title: 'Основание',
    field: 'taskReasonName',
    filterType: TableFilterTypeEnum.string,
  },
  {
    title: 'Округ',
    field: 'taskDistrictNames',
    render: (row: IResponse) => row.taskDistrictNames?.join(', '),
    filterType: TableFilterTypeEnum.string,
    sorting: false,
  },
  {
    title: 'Район',
    field: 'taskRegionNames',
    render: (row: IResponse) => row.taskRegionNames?.join(', '),
    filterType: TableFilterTypeEnum.string,
    sorting: false,
  },
  {
    title: 'Адрес измерения/обследования',
    field: 'pointAddress',
    filterType: TableFilterTypeEnum.string,
  },
  {
    title: 'Адрес объекта',
    field: 'territoryAddress',
    filterType: TableFilterTypeEnum.string,
  },
  {
    title: 'Наименование объекта',
    field: 'taskTerritoryName',
    filterType: TableFilterTypeEnum.string,
  },
  {
    title: 'Тип исследования',
    field: 'researchTypeName',
  },
  {
    title: '№ протокола',
    field: 'pelProtocolNumber',
    filterType: TableFilterTypeEnum.string,
  },
  {
    title: 'Дата протокола',
    field: 'pelProtocolDate',
    render: (row: IResponse) => createLocalDateDDMMYYYY(row.pelProtocolDate),
  },
  {
    title: 'Тип источника шума',
    field: 'noiseSourceType',
    filterType: TableFilterTypeEnum.string,
  },
  {
    title: 'Нарушения',
    field: 'isViolationText',
  },
  {
    title: 'Выявлены превышения',
    field: 'hasExcessText',
  },
  {
    title: 'Наличие протокола',
    field: 'hasPelProtocolText',
  },
  {
    title: 'Тип территории',
    field: 'territoryType',
    filterType: TableFilterTypeEnum.string,
    render: (row: any) => <DefaultTableCellSize maxWidth={300}>{row.territoryType}</DefaultTableCellSize>,
  },
  {
    title: 'Реновация',
    field: 'isRenovationText',
  },
  {
    title: '№ Рейда',
    field: 'raidNumber',
    filterType: TableFilterTypeEnum.number,
  },
  {
    title: 'Номер задания',
    field: 'taskNumber',
    filterType: TableFilterTypeEnum.string,
  },
  {
    title: 'Срок исполнения',
    field: 'taskDeadlineDate',
    render: (row: IResponse) => createLocalDateDDMMYYYY(row.taskDeadlineDate),
  },
];

export const atmoTerritoryColumns = [
  {
    title: 'Дата проведения рейда',
    field: 'raidDate',
    render: (row: IResponse) => createLocalDateDDMMYYYY(row.raidDate),
  },
  {
    title: 'Основание',
    field: 'taskReasonName',
    filterType: TableFilterTypeEnum.string,
  },
  {
    title: 'Округ',
    field: 'taskDistrictNames',
    render: (row: IResponse) => row.taskDistrictNames?.join(', '),
    filterType: TableFilterTypeEnum.string,
    sorting: false,
  },
  {
    title: 'Район',
    field: 'taskRegionNames',
    render: (row: IResponse) => row.taskRegionNames?.join(', '),
    filterType: TableFilterTypeEnum.string,
    sorting: false,
  },
  {
    title: 'Наименование территории',
    field: 'taskTerritoryName',
    filterType: TableFilterTypeEnum.string,
  },
  {
    title: 'Тип обследования',
    field: 'taskTypeName',
    filterType: TableFilterTypeEnum.string,
  },
  {
    title: 'Наличие протокола',
    field: 'hasPelProtocolText',
  },
  {
    title: 'Выявлены превышения',
    field: 'hasExcessText',
  },
  {
    title: '№ Рейда',
    field: 'raidNumber',
    filterType: TableFilterTypeEnum.number,
  },
  {
    title: 'Номер задания',
    field: 'taskNumber',
    filterType: TableFilterTypeEnum.string,
  },
  {
    title: 'Срок исполнения',
    field: 'taskDeadlineDate',
    render: (row: IResponse) => createLocalDateDDMMYYYY(row.taskDeadlineDate),
  },
];

export const lettersColumns = [
  {
    title: 'Исполнитель',
    field: 'executorUserName',
    filterType: TableFilterTypeEnum.string,
  },
  {
    title: 'Наименование территории',
    field: 'taskTerritoryName',
    filterType: TableFilterTypeEnum.string,
  },
  {
    title: 'Привязанные жалобы',
    field: 'complaints',
    cellStyle: {
      width: 250,
      minWidth: 250,
    },
    render: (row: IResponse) =>
      row.complaints?.map((complaint) => (
        <Flex flexDirection="column">
          <span>{`№: ${complaint.number}, от: ${createLocalDateDDMMYYYY(complaint.date)}`}</span>
        </Flex>
      )),
  },
  {
    title: 'Нарушение (Основание)',
    field: 'reason',
    filterType: TableFilterTypeEnum.string,
  },
  {
    title: 'Название организации',
    field: 'authorityName',
    filterType: TableFilterTypeEnum.string,
    render: (row: any) => <DefaultTableCellSize>{row.authorityName}</DefaultTableCellSize>,
  },
  {
    title: '№ Согла',
    field: 'edoAgreementNumber',
    filterType: TableFilterTypeEnum.number,
  },
  {
    title: 'Дата Согла',
    field: 'edoAgreementDate',
    render: (row: IResponse) => createLocalDateDDMMYYYY(row.edoAgreementDate),
  },
  {
    title: 'Вх. Номер письма',
    field: 'incomingNumber',
    filterType: TableFilterTypeEnum.number,
  },
  {
    title: 'Вх. Дата письма',
    field: 'incomingDate',
    render: (row: IResponse) => createLocalDateDDMMYYYY(row.incomingDate),
  },
  {
    title: 'Исх. Номер письма',
    field: 'outgoingNumber',
    filterType: TableFilterTypeEnum.number,
  },
  {
    title: 'Исх. Дата письма',
    field: 'outgoingDate',
    render: (row: IResponse) => createLocalDateDDMMYYYY(row.outgoingDate),
  },
  {
    title: 'Суть обращения',
    field: 'appealEssence',
    filterType: TableFilterTypeEnum.string,
  },
  {
    title: 'Ответ',
    field: 'haveAnswer',
    render: (row: IResponse) => <BooleanCell data={row.haveAnswer} />,
  },
  {
    title: 'Вх. Номер ответа',
    field: 'answerIncomingNumber',
    filterType: TableFilterTypeEnum.number,
  },
  {
    title: 'Вх. Дата ответа',
    field: 'answerIncomingDate',
    render: (row: IResponse) => createLocalDateDDMMYYYY(row.answerIncomingDate),
  },
  {
    title: 'Исх. Номер ответа',
    field: 'answerOutgoingNumber',
    filterType: TableFilterTypeEnum.number,
  },
  {
    title: 'Исх. Дата ответа',
    field: 'answerOutgoingDate',
    render: (row: IResponse) => createLocalDateDDMMYYYY(row.answerOutgoingDate),
  },
  {
    title: 'Содержание ответа',
    field: 'answerContent',
    filterType: TableFilterTypeEnum.string,
  },
  {
    title: 'Принятые меры',
    field: 'answerTakenActions',
    filterType: TableFilterTypeEnum.string,
  },
  {
    title: 'Наложено',
    field: 'answerImposed',
    filterType: TableFilterTypeEnum.string,
  },
  {
    title: 'Взыскано',
    field: 'answerCollected',
    filterType: TableFilterTypeEnum.string,
  },
  {
    title: 'Стадия',
    field: 'answerStage',
    filterType: TableFilterTypeEnum.string,
  },
];

export const dotNoiseColumns = [
  {
    title: 'Дата выезда',
    field: 'raidDate',
    render: (row: IResponse) => createLocalDateDDMMYYYY(row.raidDate),
  },
  {
    title: 'Время суток',
    field: 'raidShiftName',
    filterType: TableFilterTypeEnum.string,
  },
  {
    title: 'Округ',
    field: 'taskDistrictNames',
    render: (row: IResponse) => row.taskDistrictNames?.join(', '),
    filterType: TableFilterTypeEnum.string,
    sorting: false,
  },
  {
    title: 'Район',
    field: 'taskRegionNames',
    render: (row: IResponse) => row.taskRegionNames?.join(', '),
    filterType: TableFilterTypeEnum.string,
    sorting: false,
  },
  {
    title: 'Адрес измерения/обследования',
    field: 'territoryAddress',
    filterType: TableFilterTypeEnum.string,
  },
  {
    title: 'Наименование территории',
    field: 'taskTerritoryName',
    filterType: TableFilterTypeEnum.string,
  },
  {
    title: 'Адрес объекта',
    field: 'pointAddress',
    filterType: TableFilterTypeEnum.string,
  },
  {
    title: 'Тип рейда',
    field: 'taskTypeName',
    filterType: TableFilterTypeEnum.string,
  },
  {
    title: 'Тип источника',
    field: 'noiseSourceTypeName',
    filterType: TableFilterTypeEnum.string,
  },
  {
    title: 'Номер протокола',
    field: 'protocolNumber',
    filterType: TableFilterTypeEnum.number,
  },
  {
    title: 'Дата протокола',
    field: 'protocolDate',
    render: (row: IResponse) => createLocalDateDDMMYYYY(row.protocolDate),
  },
  {
    title: 'Результат рейда',
    field: 'raidResultName',
    filterType: TableFilterTypeEnum.string,
  },
  {
    title: 'Нарушение',
    field: 'isViolationText',
    render: (row: IResponse) => <BooleanCell data={row.isViolation} />,
  },
  {
    title: 'Основание',
    field: 'taskReasonName',
    filterType: TableFilterTypeEnum.string,
  },
  {
    title: 'Тип территории',
    field: 'territoryTypeName',
    filterType: TableFilterTypeEnum.string,
  },
  {
    title: 'Широта',
    field: 'pointLatitude',
    filterType: TableFilterTypeEnum.number,
  },
  {
    title: 'Долгота',
    field: 'pointLongitude',
    filterType: TableFilterTypeEnum.number,
  },
  {
    title: 'Наличие протокола',
    field: 'isSetProtocol',
    render: (row: IResponse) => <BooleanCell data={row.isSetProtocol} />,
  },
  {
    title: 'Повтор',
    field: 'isRepeat',
    render: (row: IResponse) => <BooleanCell data={row.isRepeat} />,
  },
  {
    title: 'Реновация',
    field: 'isRenovation',
    render: (row: IResponse) => <BooleanCell data={row.isRenovation} />,
  },
  {
    title: 'Заказчик',
    field: 'customer',
    filterType: TableFilterTypeEnum.string,
  },
  {
    title: 'Генподрядчик',
    field: 'generalContractor',
    filterType: TableFilterTypeEnum.string,
  },
  {
    title: 'Объект строительства',
    field: 'constructionObject',
    filterType: TableFilterTypeEnum.string,
  },
  {
    title: 'Велись работы',
    field: 'isWorkDone',
    render: (row: IResponse) => <BooleanCell data={row.isWorkDone} />,
  },
  {
    title: 'Инф. Щит',
    field: 'isInformationBoard',
    render: (row: IResponse) => <BooleanCell data={row.isInformationBoard} />,
  },
  {
    title: 'Инф. Щит ДПИООС',
    field: 'isInformationBoardNMEPD',
    render: (row: IResponse) => <BooleanCell data={row.isInformationBoardNMEPD} />,
  },
  {
    title: 'Сроки работ',
    field: 'workTiming',
    render: (row: IResponse) => createLocalDateDDMMYYYY(row.workTiming),
  },
  {
    title: 'Дата и время начала измерений',
    field: 'measurementTimeFrom',
    render: (row: IResponse) => <DateCell date={row.measurementTimeFrom} />,
  },
  {
    title: 'Дата и время окончания измерений',
    field: 'measurementTimeTo',
    render: (row: IResponse) => <DateCell date={row.measurementTimeTo} />,
  },
  {
    title: 'Период в минутах ',
    field: 'measurementTimeDiffInMin',
    filterType: TableFilterTypeEnum.number,
  },
  {
    title: 'Номер рейда',
    field: 'raidNumber',
    filterType: TableFilterTypeEnum.number,
  },
  {
    title: 'Номер задания',
    field: 'taskNumber',
    filterType: TableFilterTypeEnum.number,
  },
  {
    title: 'Номер точки',
    field: 'pointNumber',
    filterType: TableFilterTypeEnum.number,
  },
  {
    title: 'Срок исполнения',
    field: 'taskDeadlineDate',
    render: (row: IResponse) => createLocalDateDDMMYYYY(row.taskDeadlineDate),
  },
  {
    title: 'Комментарий',
    field: 'report',
    filterType: TableFilterTypeEnum.string,
    render: (row: any) => <DefaultTableCellSize maxWidth={300}>{row.report}</DefaultTableCellSize>,
  },
  {
    title: 'Исполнитель',
    field: 'protocolExecutorUserName',
    filterType: TableFilterTypeEnum.string,
  },
];

export const dotAtmoColumns = [
  {
    title: 'Дата выезда',
    field: 'raidDate',
    render: (row: IResponse) => createLocalDateDDMMYYYY(row.raidDate),
  },
  {
    title: 'Время суток',
    field: 'raidShiftName',
    filterType: TableFilterTypeEnum.string,
  },
  {
    title: 'Основание ',
    field: 'taskReasonName',
    filterType: TableFilterTypeEnum.string,
  },
  {
    title: 'Тип рейда',
    field: 'pointTypeName',
  },
  {
    title: 'Округ',
    field: 'taskDistrictNames',
    render: (row: IResponse) => row.taskDistrictNames?.join(', '),
    sorting: false,
  },
  {
    title: 'Район',
    field: 'taskRegionNames',
    render: (row: IResponse) => row.taskRegionNames?.join(', '),
    sorting: false,
  },
  {
    title: 'Наименование территории',
    field: 'taskTerritoryName',
    filterType: TableFilterTypeEnum.string,
  },
  {
    title: 'Адрес точки',
    field: 'pointAddress',
    filterType: TableFilterTypeEnum.string,
  },
  {
    title: 'Внутренний номер протокола',
    field: 'protocolNumber',
    filterType: TableFilterTypeEnum.number,
  },
  {
    title: 'Номер протокола ПЭЛ',
    field: 'pelProtocolNumber',
    filterType: TableFilterTypeEnum.number,
  },
  {
    title: 'Дата протокола ПЭЛ ',
    field: 'pelProtocolDate',
    render: (row: IResponse) => createLocalDateDDMMYYYY(row.pelProtocolDate),
  },
  {
    title: 'Номер протокола АИ',
    field: 'aiProtocolNumber',
    filterType: TableFilterTypeEnum.number,
  },
  {
    title: 'Дата протокола АИ ',
    field: 'aiProtocolDate',
    render: (row: IResponse) => createLocalDateDDMMYYYY(row.aiProtocolDate),
  },
  {
    title: 'Результат рейда',
    field: 'raidResultName',
    filterType: TableFilterTypeEnum.string,
  },
  {
    title: 'Нарушение (Превышение есть) ',
    field: 'hasExcessText',
  },
  {
    title: '№ ПЭЛ ',
    field: 'pelNumber',
    filterType: TableFilterTypeEnum.number,
  },
  {
    title: 'Широта',
    field: 'pointLatitude',
    filterType: TableFilterTypeEnum.number,
  },
  {
    title: 'Долгота',
    field: 'pointLongitude',
    filterType: TableFilterTypeEnum.number,
  },
  {
    title: 'Номер рейда',
    field: 'raidNumber',
    filterType: TableFilterTypeEnum.number,
  },
  {
    title: 'Номер задания',
    field: 'taskNumber',
    filterType: TableFilterTypeEnum.number,
  },
  {
    title: 'Номер точки',
    field: 'pointNumber',
    filterType: TableFilterTypeEnum.number,
  },
  {
    title: 'Срок исполнения ',
    field: 'taskDeadlineDate',
    render: (row: IResponse) => createLocalDateDDMMYYYY(row.taskDeadlineDate),
  },
  {
    title: 'Дата и время начала измерений ',
    field: 'measurementTimeFrom',
    render: (row: IResponse) => <DateCell date={row.measurementTimeFrom} />,
  },
  {
    title: 'Дата и время окончания измерений',
    field: 'measurementTimeTo',
    render: (row: IResponse) => <DateCell date={row.measurementTimeTo} />,
  },
  {
    title: 'Комментарий ',
    field: 'report',
    filterType: TableFilterTypeEnum.string,
    render: (row: any) => <DefaultTableCellSize maxWidth={300}>{row.report}</DefaultTableCellSize>,
  },
  {
    title: 'Исполнитель ',
    field: 'protocolExecutorUserName',
    filterType: TableFilterTypeEnum.string,
  },
  {
    title: 'Наличие протокола ПЭЛ',
    field: 'isSetPelProtocol',
    render: (row: IResponse) => <BooleanCell data={row.isSetPelProtocol} />,
  },
  {
    title: 'Наличие протокола АИ',
    field: 'isSetAiProtocol',
    render: (row: IResponse) => <BooleanCell data={row.isSetAiProtocol} />,
  },
];

const airUrls = ['air/reports/list', 'air/report-points/list', 'air/letters/list'];
const noiseUrls = ['noise/reports/list', 'noise/report-points/list', 'noise/letters/list'];
export const PelGeneralUrls = [noiseUrls, airUrls];
