import { Box, Flex, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { Button, DefaultText, FormHeader, LoaderItem } from 'components/common';
import React, { memo, useMemo } from 'react';
import { GeneralInformationTab } from './GeneralInformationTab';
import { LocationTab } from './LocationTab';
import { useFormikContext } from 'formik';
import appLocale from 'constants/appLocale';
import { FieldFormProps } from 'types/forms';

const NSIEmissionsIndustriesFormFields = ({
  onCancel,
  dictionaries,
  isLoading,
  isEditMode,
  editedObject,
}: FieldFormProps) => {
  const { handleSubmit } = useFormikContext();

  const renderControls = useMemo(
    () => (
      <Flex>
        <Button onClick={onCancel} marginRight="auto">
          Отмена
        </Button>
        <Button onClick={() => handleSubmit()} variant="solid" isDisabled={isLoading}>
          Сохранить
        </Button>
      </Flex>
    ),
    [handleSubmit, onCancel, isLoading],
  );

  const title = isEditMode ? 'Редактирование предприятия' : 'Добавление предприятия';

  if (isLoading) {
    return <LoaderItem />;
  }

  return (
    <Box flexDirection="column">
      <Tabs>
        <FormHeader onBackClick={onCancel} header={title}>
          <TabList>
            {[appLocale.common.generalInformation, appLocale.common.location].map((tab, i) => (
              <Tab key={i}>
                <DefaultText cursor="pointer">{tab}</DefaultText>
              </Tab>
            ))}
          </TabList>
        </FormHeader>
        <TabPanels>
          <TabPanel>
            <GeneralInformationTab dictionaries={dictionaries} editedObject={editedObject} isEditMode={isEditMode} />
          </TabPanel>
          <TabPanel>
            <LocationTab dictionaries={dictionaries}>{renderControls}</LocationTab>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default memo(NSIEmissionsIndustriesFormFields);
