import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import { NSIEmissionsIndustriesBreadcrumbs } from 'pages/dictionaries/NSIEmissions/consts/breadcrumbs';
import { SubsystemHeader } from 'components/common';
import { useHistory } from 'react-router-dom';
import {
  NSIEmissionsApiPath,
  NSIEmissionsIndustriesAddPath,
  NSIEmissionsIndustriesEditPath,
} from 'pages/dictionaries/NSIEmissions/consts/paths';
import { useList } from 'hooks';
import { MaterialTable } from 'features/material-table/MaterialTableMini';
import React, { useMemo } from 'react';
import { NSIEmissionsListItem } from 'pages/dictionaries/NSIEmissions/consts/types';
import { PropActions } from 'features/material-table/components/Actions';
import { Column } from '@material-table/core';
import { addFiltersToColumns } from 'lib/utils/tableFilter';
import { transformFiltersArray } from 'features/Filter/transformFiltersArray';
import NSIEmissionsIndustriesFilters
  from 'pages/dictionaries/NSIEmissions/NSIEmissionsIndustries/NSIEmissionsIndustriesFilters/NSIEmissionsIndustriesFilters';
import { subsystemCodesMap } from 'constants/subsystemIdsMap';
import { useCustomRights } from 'hooks/useCustomRights';
import { useDictionary } from 'hooks/useDictionary';
import { getSelectOptionsFromDictionaryByName } from 'features/get-select-options-from-dictionary';
import { FullPageWrapper } from '../../../../components/table/FullPageWrapper';
import { DEFAULT_PAGE_SIZE_NEW_TABLE } from '../../../../constants/tables';

const NSIEmissionsIndustries = () => {
  useUpdateBreadcrumbs(NSIEmissionsIndustriesBreadcrumbs);
  const { push } = useHistory();
  const { dictionaries, isDictionariesLoading } = useDictionary({ url: `${NSIEmissionsApiPath}/dictionaries` });
  const { isWrite } = useCustomRights(subsystemCodesMap.nsi.industrialEmissions.enterprises);

  const industryTypes = useMemo(
    () => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'industryTypes'),
    [dictionaries?.data],
  );
  const okrugs = useMemo(
    () => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'okrugs'),
    [dictionaries?.data],
  );
  const rayons = useMemo(
    () => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'rayons'),
    [dictionaries?.data],
  );
  const statuses = useMemo(
    () => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'statuses'),
    [dictionaries?.data],
  );

  const {
    isLoading: isLoadingList,
    response,
    toggleOrder,
    setFilters,
    onPageChange,
    filterDTO,
    onSearchText,
    downloadAsFile,
    errorText,
    materialTableProps,
  } = useList<NSIEmissionsListItem>(`${NSIEmissionsApiPath}/list/industry`, {
    initialOrderFieldKey: '-number',
    saveFiltersState: true,
    defaultPageSize: DEFAULT_PAGE_SIZE_NEW_TABLE,
    resetDataOnNextRequest: true,
  });

  const actions = useMemo<PropActions<NSIEmissionsListItem[]>>(
    () => [
      {
        type: 'edit',
        onClick: (e, item: any) => {
          push(`${NSIEmissionsIndustriesEditPath}/${item.id}`);
        },
        disabled: !isWrite,
        hidden: !isWrite,
      },
    ],
    [isWrite, push],
  );

  const columns = useMemo<Column<NSIEmissionsListItem>[]>(
    () => [
      {
        title: 'Номер',
        field: 'number',
      },
      {
        title: 'Наименование',
        field: 'name',
      },
      {
        title: 'Тип предприятия',
        field: 'industryTypeId',
        render: (row) => industryTypes?.find((item) => item.value === row.industryTypeId)?.name,
      },
      {
        title: 'Округ',
        field: 'okrugId',
        render: (row) => okrugs?.find((item) => item.value === row.okrugId)?.name,
      },
      {
        title: 'Район',
        field: 'rayonId',
        render: (row) => rayons?.find((item) => item.value === row.rayonId)?.name,
      },
      {
        title: 'Адрес',
        field: 'address',
      },
      {
        title: 'Статус',
        field: 'status',
        render: (row) => statuses?.find((status) => status.value === row.status)?.name,
      },
    ],
    [industryTypes, okrugs, rayons, statuses],
  );

  const columnsWithFilters = useMemo<Array<Column<NSIEmissionsListItem>>>(
    () => addFiltersToColumns({ filters: filterDTO.filters, setFilters, columns }),
    [columns, filterDTO.filters, setFilters],
  );

  const isLoading = useMemo(() => isLoadingList || isDictionariesLoading, [isDictionariesLoading, isLoadingList]);

  return (
    <FullPageWrapper>
      <SubsystemHeader
        title="Предприятия"
        addButtonProps={{
          onClick: () => push(NSIEmissionsIndustriesAddPath),
          isDisabled: !isWrite,
        }}
        filtersComponent={
          <NSIEmissionsIndustriesFilters
            isLoading={isLoading}
            onSubmit={(val) => setFilters(transformFiltersArray(Object.values(val)))}
            okrugs={okrugs}
            rayons={rayons}
            statuses={statuses}
            industryTypes={industryTypes}
            filters={filterDTO.filters}
          />
        }
      />

      <MaterialTable
        data={response?.data}
        meta={response?.meta}
        toggleOrder={toggleOrder}
        filterDTO={filterDTO}
        onPageChange={onPageChange}
        columns={columnsWithFilters}
        actions={actions}
        isLoading={isLoading}
        setFilters={setFilters}
        options={{ search: true, selection: false }}
        onSearch={onSearchText}
        downloadAsFile={downloadAsFile}
        showTotalCount
        errorText={errorText}
        {...materialTableProps}
      />
    </FullPageWrapper>
  );
};

export default NSIEmissionsIndustries;
