import { Checkbox, DateInput, Select } from 'components/common';

import { Flex, Grid, GridItem } from '@chakra-ui/react';
import { Field, useFormikContext } from 'formik';
import React from 'react';
import { createUTCTime } from 'lib/create-date';
import { IFilterItem } from 'models';
import { getSelectOptionsFromDictionaryByName } from 'features/get-select-options-from-dictionary';
import { TDictionaries } from '../../../../pel/PelRaidsTableView/types/types';
import FilterFormControls from 'components/form/FilterFormControls';

interface IProps {
  type: number;
  appealsDictionaries: TDictionaries;
  isLoading: boolean;
}

export const AppealsTableViewFields = ({ type, appealsDictionaries, isLoading }: IProps) => {
  const { values, submitForm, setFieldValue, handleReset, errors } = useFormikContext<{ [p: string]: IFilterItem }>();
  const odorTypes = getSelectOptionsFromDictionaryByName(appealsDictionaries?.data, 'odorTypes');
  const sources = getSelectOptionsFromDictionaryByName(appealsDictionaries?.data, 'sources');
  const noiseSourceTypeId = getSelectOptionsFromDictionaryByName(appealsDictionaries?.data, 'noiseSourceTypeId');
  const odorSourceTypeId = getSelectOptionsFromDictionaryByName(appealsDictionaries?.data, 'odorSourceTypeId');
  const areas = getSelectOptionsFromDictionaryByName(appealsDictionaries?.data, 'areas');
  const districts = getSelectOptionsFromDictionaryByName(appealsDictionaries?.data, 'districts');
  const complaintOrigins = getSelectOptionsFromDictionaryByName(appealsDictionaries?.data, 'complaintOrigins');
  return (
    <Flex direction="row" justifyContent="space-between">
      <Flex direction="column" flexGrow={1}>
        <Grid gap="12px" rowGap="8px" templateColumns="repeat(4, 1fr)">
          <GridItem>
            <Field
              as={DateInput}
              name="complaintDateMin.value"
              label="Дата обращения от"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setFieldValue('complaintDateMin.value', createUTCTime(e.target.value))
              }
              showTimeInput
              error={errors.appealDateMin?.value}
            />
          </GridItem>
          <GridItem>
            <Field
              as={DateInput}
              name="complaintDateMax.value"
              label="Дата обращения до"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setFieldValue('complaintDateMax.value', createUTCTime(e.target.value))
              }
              showTimeInput
              error={errors.appealDateMax?.value}
            />
          </GridItem>
          <GridItem>
            <Field
              as={DateInput}
              name="assigmentDateMin.value"
              label="Дата поручения от"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setFieldValue('assigmentDateMin.value', createUTCTime(e.target.value))
              }
              error={errors.assigmentDateMin?.value}
            />
          </GridItem>
          <GridItem>
            <Field
              as={DateInput}
              name="assigmentDateMax.value"
              label="Дата поручения до"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setFieldValue('assigmentDateMax.value', createUTCTime(e.target.value))
              }
              error={errors.assigmentDateMax?.value}
            />
          </GridItem>
        </Grid>
        <Grid templateColumns="repeat(4, 1fr)" marginTop={2} gap="12px" rowGap="8px">
          <Field as={Select} name="originId.value" label="Основание" options={complaintOrigins} />
          <Field as={Select} name="districtId.value" label={`Округ ${!type ? 'происшествия' : ''}`} options={areas} />
          <Field as={Select} name="regionId.value" label={`Район ${!type ? 'происшествия' : ''}`} options={districts} />

          {type ? (
            <>
              <Field as={Select} name="odorTypeId.value" label="Тип запаха" options={odorTypes} />
              <Field as={Select} name="odorSourceTypeId.value" label="Тип источника" options={odorSourceTypeId} />
              <Field as={Select} name="sourceId.value" label="Предполагаемый источник" options={sources} />
            </>
          ) : (
            <>
              <Field
                as={Select}
                name="noiseSourceTypeId.value"
                label="Тип источника шума"
                options={noiseSourceTypeId}
              />
            </>
          )}

          {type ? (
            <>
              <GridItem>
                <Field as={Checkbox} checked={values.isNmu.value} name="isNmu.value" label="НМУ" />
              </GridItem>
            </>
          ) : (
            <>
              <GridItem>
                <Field as={Checkbox} checked={values.isRenovation.value} name="isRenovation.value" label="Реновация" />
              </GridItem>
              <GridItem>
                <Field
                  as={Checkbox}
                  checked={values.isDuplicate.value}
                  name="isDuplicate.value"
                  label="Повторная жалоба"
                />
              </GridItem>
            </>
          )}
          <GridItem>
            <Field as={Checkbox} checked={values.isReplied.value} name="isReplied.value" label="Ответ направлен" />
          </GridItem>
          <GridItem>
            <Field as={Checkbox} checked={values.isRedirect.value} name="isRedirect.value" label="Перенаправление" />
          </GridItem>
          <GridItem>
            <Field
              as={Checkbox}
              checked={values.isRaidNotNeed.value}
              name="isRaidNotNeed.value"
              label="Рейд не нужен"
            />
          </GridItem>
          <GridItem>
            <Field as={Checkbox} checked={values.isRaidComplete.value} name="isRaidComplete.value" label="Рейд был" />
          </GridItem>
        </Grid>
      </Flex>

      <FilterFormControls
        onReset={(e) => {
          handleReset(e);
          setTimeout(submitForm, 0);
        }}
        onSubmit={submitForm}
        isLoading={isLoading}
      />
    </Flex>
  );
};
