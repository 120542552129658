import { createTouchedErrors } from 'features/create-touched-errors';
import { FormikProvider, useFormik } from 'formik';
import { useRequest } from 'hooks/useRequest';
import { useEnhancedNotification } from 'hooks';
import NSIWaterGatesFormFields
  from 'pages/dictionaries/NSIWater/NSIWaterGates/NSIWaterGatesForms/NSIWaterGatesFormFields';
import { useDictionary } from 'hooks/useDictionary';
import { NSIWaterApiPath, NSIWaterGatesPaths } from 'pages/dictionaries/NSIWater/paths';
import initialNSIWaterGatesEmptyValues
  from 'pages/dictionaries/NSIWater/NSIWaterGates/NSIWaterGatesForms/initialNSIWaterGatesEmptyValues';
import { NsiWaterGatesSchema } from 'models/schemas/nsiWater/nsiWaterGatesSchema';
import { NSIWaterDictionaryKeys, NSIWaterItemType } from 'pages/dictionaries/NSIWater/types';
import { useGoBackWithFallback } from 'lib/utils/goBackWithFallback';

const NSIWaterGatesAdd = () => {
  const { backWithFallback } = useGoBackWithFallback();
  const { onSuccessCreate, onErrorCreate } = useEnhancedNotification();

  const { isDictionariesLoading, dictionaries } = useDictionary<NSIWaterDictionaryKeys>({
    url: `${NSIWaterApiPath}/dictionaries`,
  });

  const onSuccess = () => {
    onSuccessCreate();
    backWithFallback(NSIWaterGatesPaths.root);
  };

  const { post, isLoading } = useRequest(`${NSIWaterApiPath}/create`, onSuccess, onErrorCreate);

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    initialValues: initialNSIWaterGatesEmptyValues,
    validationSchema: NsiWaterGatesSchema,
    onSubmit: (values) => post({ ...values, type: NSIWaterItemType.stvor }),
  });

  return (
    <FormikProvider
      value={{
        ...formik,
        errors: createTouchedErrors(formik.errors, formik.touched),
      }}
    >
      <NSIWaterGatesFormFields
        dictionaries={dictionaries}
        onCancel={() => {
          backWithFallback(NSIWaterGatesPaths.root);
        }}
        isLoading={isDictionariesLoading || isLoading}
      />
    </FormikProvider>
  );
};

export default NSIWaterGatesAdd;
