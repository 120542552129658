import { useContext, useMemo } from 'react';
import { UserInfoContext } from 'features/AuthProvider';

export const useCustomRights = (reportCode: string) => {
  const { customRights } = useContext(UserInfoContext);

  const isExecute = useMemo(
    () => !!customRights?.find((right) => right.code.includes(reportCode) && right.action === 'EXECUTE'),
    [customRights, reportCode]
  );
  const isRead = useMemo(
    () => !!customRights?.find((right) => right.code.includes(reportCode) && right.action === 'READ'),
    [customRights, reportCode]
  );
  const isWrite = useMemo(
    () => !!customRights?.find((right) => right.code.includes(reportCode) && right.action === 'WRITE'),
    [customRights, reportCode]
  );

  return {
    isExecute,
    isRead,
    isWrite,
  };
};
