import { Box, Flex, Grid, GridItem, Heading } from '@chakra-ui/react';
import { Field, FieldArray, useFormikContext } from 'formik';
import { Input } from '../../../Input';
import { Button } from '../../../Button';
import { AddIcon, DeleteIcon } from '../../../../icons';
import React from 'react';

interface IProps {
  uploadFile: () => void;
  sendMediaId: () => void;
  uploadType?: string | null;
  customClose: () => void;
  isDisabled?: boolean;
}

export interface IFormikUploadValues {
  recipientsEmails: string[];
  template: string;
  attachmentsContents: { name: string; content: string; }[] | null;
  attachmentsReferences: { name: string; }[] | null;
}

export const FormFields = ({ uploadFile, sendMediaId, uploadType, customClose, isDisabled }: IProps) => {
  const { values, errors, touched } = useFormikContext<IFormikUploadValues>();

  return (
    <Flex flexDirection="column">
      <Box mb={4}>
        <Heading mb={2} size="sm">
          Введите адрес, или несколько адресов почты, для выгрузки графика
        </Heading>
        <FieldArray name="recipientsEmails">
          {({ push, handleRemove }) => (
            <Flex direction="column" gap={5}>
              {values.recipientsEmails?.map((item, index) => (
                <Grid templateColumns="repeat(6, 0.17fr)" marginTop={5} key={index}>
                  <GridItem colSpan={5}>
                    <Field
                      as={Input}
                      name={`recipientsEmails[${index}]`}
                      label={`Адрес ${index + 1}`}
                      // @ts-ignore
                      error={touched?.recipientsEmails?.[index] && errors?.recipientsEmails?.[index]}
                    />
                  </GridItem>
                  {values.recipientsEmails.length > 1 && (
                    <GridItem>
                      <Button
                        marginLeft="10px"
                        leftIcon={<DeleteIcon />}
                        variant="ghost"
                        color="PrimaryButton.500"
                        size="lg"
                        p={0}
                        onClick={handleRemove(index)}
                      />
                    </GridItem>
                  )}
                </Grid>
              ))}
              <Button
                alignSelf="left"
                marginTop={5}
                marginRight="auto"
                leftIcon={<AddIcon />}
                variant="ghost"
                color="PrimaryButton.500"
                size="sm"
                p={0}
                onClick={() => push('')}
              >
                Добавить адрес
              </Button>
            </Flex>
          )}
        </FieldArray>
      </Box>
      <Flex justifyContent="space-between" marginTop={10}>
        <Button type="reset" onClick={customClose} variant="solid">
          Отменить
        </Button>

        <Button
          onClick={() => {
            if (uploadType) {
              uploadFile();
            } else {
              sendMediaId();
            }
          }}
          variant="solid"
          isDisabled={uploadType ? false : isDisabled}
        >
          Отправить
        </Button>
      </Flex>
    </Flex>
  );
};
