import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import appLocale from 'constants/appLocale';
import { NSIRootPath } from '../../const/routes';
import {
  GeoecologyDictPath,
  GeologicalProcessesPath,
  markAndReperColumns,
  observationAndLandmarkColumns,
  tabComparisonObject,
} from '../constants/constants';
import { SubsystemHeader } from 'components/common';
import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { DangerProcessesTab } from './DangerProcessesTab';
import { getSelectOptionsFromDictionaryByName } from 'features/get-select-options-from-dictionary';
import { IRowData } from '../constants/types';
import { Link, useParams } from 'react-router-dom';
import { useHistory } from 'react-router';
import { useDisclosure } from 'hooks';
import { subsystemCodesMap } from 'constants/subsystemIdsMap';
import { useCustomRights } from 'hooks/useCustomRights';
import { useDictionary } from 'hooks/useDictionary';
import { FullPageWrapper } from 'components/table/FullPageWrapper';

export const DangerGeologicalProcesses: FC = () => {
  const { type } = useParams<{ type: string }>();
  const history = useHistory();
  useUpdateBreadcrumbs([
    {
      breadcrumb: appLocale.mainPage.nsi,
      path: NSIRootPath,
    },
    {
      breadcrumb: 'Опасные геоэкологические процессы',
      path: `${GeologicalProcessesPath}/${type}`,
    },
  ]);

  const { isWrite: isAreaWrite, isRead: isAreaRead } = useCustomRights(
    subsystemCodesMap.nsi.geologicalProcesses.observationArea,
  );
  const { isWrite: isPointWrite, isRead: isPointRead } = useCustomRights(
    subsystemCodesMap.nsi.geologicalProcesses.observationPoint,
  );
  const { isWrite: isInclinometerWrite, isRead: isInclinometerRead } = useCustomRights(
    subsystemCodesMap.nsi.geologicalProcesses.inclinometer,
  );
  const { isWrite: isMarkWrite, isRead: isMarkRead } = useCustomRights(subsystemCodesMap.nsi.geologicalProcesses.mark);
  const { isWrite: isReperWrite, isRead: isReperRead } = useCustomRights(
    subsystemCodesMap.nsi.geologicalProcesses.reper,
  );

  const { isOpen: isOpenLoad, onClose: onCloseLoad, onOpen: onOpenLoad } = useDisclosure();

  const { dictionaries } = useDictionary({ url: GeoecologyDictPath });
  const districts = useMemo(() => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'rayons'), [dictionaries]);
  const regions = useMemo(() => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'okrugs'), [dictionaries]);
  const statuses = useMemo(() => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'statuses'), [dictionaries]);
  const areas = useMemo(() => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'areas'), [dictionaries]);

  const readPrivilegesComparisonObject: { [key: string]: boolean } = useMemo(
    () => ({
      observationArea: isAreaRead,
      observationPoint: isPointRead,
      inclinometer: isInclinometerRead,
      mark: isMarkRead,
      reper: isReperRead,
    }),
    [isAreaRead, isInclinometerRead, isMarkRead, isPointRead, isReperRead],
  );

  const writePrivilegesComparisonObject: { [key: string]: boolean } = useMemo(
    () => ({
      observationArea: isAreaWrite,
      observationPoint: isPointWrite,
      inclinometer: isInclinometerWrite,
      mark: isMarkWrite,
      reper: isReperWrite,
    }),
    [isAreaWrite, isInclinometerWrite, isMarkWrite, isPointWrite, isReperWrite],
  );

  const firstEnableTab = useMemo(
    () => Object.keys(readPrivilegesComparisonObject).find((item) => readPrivilegesComparisonObject[item]),
    [readPrivilegesComparisonObject],
  );

  useEffect(() => {
    if (!readPrivilegesComparisonObject[type] && firstEnableTab) {
      history.push(`${GeologicalProcessesPath}/${firstEnableTab}`);
    }
  }, [firstEnableTab, history, readPrivilegesComparisonObject, type]);

  const tableCommonColumns = [
    {
      title: 'Округ',
      field: 'okrugName',
    },
    {
      title: 'Район',
      field: 'rayonName',
    },
    {
      title: 'Адрес',
      field: 'address',
    },
  ];

  const observationAreaColumns = [
    {
      title: 'Номер участка',
      field: 'number',
    },
    {
      title: 'Тип процесса',
      field: 'areaTypeName',
    },
    {
      title: 'Название участка',
      field: 'name',
    },
    {
      title: 'Площадь участка',
      field: 'area',
    },
    ...tableCommonColumns,
  ];

  const observationPointColumns = [
    {
      title: 'Номер точки наблюдения',
      field: 'number',
    },
    ...observationAndLandmarkColumns,
    ...tableCommonColumns,
    {
      title: 'Статус',
      field: 'status',
      render: (row: any) => row.statusName,
    },
  ];

  const inclinometerColumns = [
    {
      title: 'Инклинометр',
      field: 'number',
      render: (row: IRowData) => (
        <Link style={{ color: 'blue' }} to={`${GeologicalProcessesPath}/inclinometers/${row.number}/${row.id}`}>
          {row.number}
        </Link>
      ),
    },
    ...observationAndLandmarkColumns,
    ...tableCommonColumns,
    {
      title: 'Статус',
      field: 'status',
      render: (row: any) => row.statusName,
    },
  ];

  const markColumns = [
    ...markAndReperColumns,
    ...tableCommonColumns,
    {
      title: 'Статус',
      field: 'status',
      render: (row: any) => row.statusName,
    },
  ];

  const reperColumns = [
    ...markAndReperColumns,
    ...tableCommonColumns,
    {
      title: 'Статус',
      field: 'status',
      render: (row: any) => row.statusName,
    },
  ];

  const renderSubsystemHeader = useCallback(
    (filters: React.ReactNode) => (
      <SubsystemHeader
        title="Опасные геологические процессы"
        onUploadClick={onOpenLoad}
        isUploadDisabled={!writePrivilegesComparisonObject[type]}
        addButtonProps={{
          onClick: () => history.push(`${GeologicalProcessesPath}/${type}/add`),
          isDisabled: !writePrivilegesComparisonObject[type],
        }}
        navigationTabs={
          <TabList>
            <Tab isDisabled={!isAreaRead}>Участки наблюдения</Tab>
            <Tab isDisabled={!isPointRead}>Точки наблюдения</Tab>
            <Tab isDisabled={!isInclinometerRead}>Инклинометры</Tab>
            <Tab isDisabled={!isMarkRead}>Марки</Tab>
            <Tab isDisabled={!isReperRead}>Реперы</Tab>
          </TabList>
        }
        filtersComponent={filters}
      />
    ),
    [
      onOpenLoad,
      writePrivilegesComparisonObject,
      type,
      isAreaRead,
      isPointRead,
      isInclinometerRead,
      isMarkRead,
      isReperRead,
      history,
    ],
  );

  return (
    <FullPageWrapper>
      <Tabs
        isLazy
        index={tabComparisonObject[type]}
        onChange={(index) => history.push(`${GeologicalProcessesPath}/${Object.keys(tabComparisonObject)[index]}`)}
      >
        <TabPanels>
          <TabPanel p={0}>
            <DangerProcessesTab
              type="observationArea"
              tableColumns={observationAreaColumns}
              districts={districts}
              regions={regions}
              statuses={statuses}
              areas={areas}
              isOpenLoad={isOpenLoad}
              onCloseLoad={onCloseLoad}
              isWrite={isAreaWrite}
              isDelete={isAreaWrite}
              renderSubsystemHeader={renderSubsystemHeader}
            />
          </TabPanel>
          <TabPanel p={0}>
            <DangerProcessesTab
              type="observationPoint"
              tableColumns={observationPointColumns}
              districts={districts}
              regions={regions}
              statuses={statuses}
              areas={areas}
              isOpenLoad={isOpenLoad}
              onCloseLoad={onCloseLoad}
              isWrite={isPointWrite}
              isDelete={isPointWrite}
              renderSubsystemHeader={renderSubsystemHeader}
            />
          </TabPanel>
          <TabPanel p={0}>
            <DangerProcessesTab
              type="inclinometer"
              tableColumns={inclinometerColumns}
              districts={districts}
              regions={regions}
              statuses={statuses}
              areas={areas}
              isOpenLoad={isOpenLoad}
              onCloseLoad={onCloseLoad}
              isWrite={isInclinometerWrite}
              isDelete={isInclinometerWrite}
              renderSubsystemHeader={renderSubsystemHeader}
            />
          </TabPanel>
          <TabPanel p={0}>
            <DangerProcessesTab
              type="mark"
              tableColumns={markColumns}
              districts={districts}
              regions={regions}
              statuses={statuses}
              areas={areas}
              isOpenLoad={isOpenLoad}
              onCloseLoad={onCloseLoad}
              isWrite={isMarkWrite}
              isDelete={isMarkWrite}
              renderSubsystemHeader={renderSubsystemHeader}
            />
          </TabPanel>
          <TabPanel p={0}>
            <DangerProcessesTab
              type="reper"
              tableColumns={reperColumns}
              districts={districts}
              regions={regions}
              statuses={statuses}
              areas={areas}
              isOpenLoad={isOpenLoad}
              onCloseLoad={onCloseLoad}
              isWrite={isReperWrite}
              isDelete={isReperWrite}
              renderSubsystemHeader={renderSubsystemHeader}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </FullPageWrapper>
  );
};
