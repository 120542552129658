import axios from 'axios';

const commonConfig = {
  headers: {
    'Content-Type': 'application/json',
  },
};

const hostUrl = process.env.NODE_ENV !== 'development' ? process.env.REACT_APP_API_URL : '';

export const api = axios.create({
  baseURL: `${hostUrl}/api`,
  ...commonConfig,
});
