import { createContext, FC, useMemo } from 'react';
import {
  IRole,
  IUser,
  RoleCode,
  useGetUserCustomRights,
  useGetUserInfo,
  useGetUserReport,
  useGetUserRoles,
} from 'api/services/auth';
import { ReportItem } from 'pages/admin/models/ReportItem';
import { RightItem } from 'pages/admin/models/RightItem';
import { TSubsystemItem, useUserSubsystems } from 'hooks';
import { TReport, useGetSubsystemReport } from '../../pages/analyticsReports/useGetSubsystemReports';
import subsystemIdsMap from '../../constants/subsystemIdsMap';

type TContext = {
  id?: string | null;
  refetchRoles: () => void;
  resetRoles: () => void;
  resetUser: () => void;
  isAdmin: boolean;
  isManager: boolean;
  isSentry: boolean;
  isUser: boolean;
  userRoles: IRole[];
  user: IUser | null;
  isRolesLoading: boolean;
  reports: ReportItem[] | null;
  userSubsystems: TSubsystemItem[] | null;
  isReportLoading: boolean;
  isSubsystemLoading: boolean;
  customRights: RightItem[] | null;
  isRightLoading: boolean;
  chartCombinationReports: TReport[] | null;
  isChartCombinationReportsLoading: boolean;
};

export const UserInfoContext = createContext<TContext>({
  id: null,
  isAdmin: false,
  isManager: false,
  isSentry: false,
  isUser: false,
  resetUser: () => {
  },
  resetRoles: () => {
  },
  refetchRoles: () => {
  },
  userRoles: [],
  user: null,
  isRolesLoading: false,
  reports: [],
  userSubsystems: [],
  isReportLoading: false,
  isSubsystemLoading: false,
  customRights: [],
  isRightLoading: false,
  chartCombinationReports: [],
  isChartCombinationReportsLoading: false,
});
// TODO: разобрать на mobx store
export const UserInfoProvider: FC = ({ children }) => {
  const { result: userRoles, get: refetchRoles, reset: resetRoles, isRolesLoading } = useGetUserRoles();
  const { result: user, reset: resetUser } = useGetUserInfo(userRoles);
  const { result: userSubsystems, isLoading: isSubsystemLoading } = useUserSubsystems(user?.id);
  const { result: reports, isReportLoading } = useGetUserReport(userRoles);
  const { result: customRights, isRightLoading } = useGetUserCustomRights(user?.id);
  const { subsystemReports: chartCombinationReports, isReportLoading: isChartCombinationReportsLoading } =
    useGetSubsystemReport(subsystemIdsMap.chartCombination, user?.id);

  const isAdmin = useMemo(() => Boolean(userRoles?.some((r) => r.code === RoleCode.ADMIN)), [userRoles]);
  const isUser = useMemo(() => Boolean(userRoles?.some((r) => r.code === RoleCode.USER)), [userRoles]);
  const isManager = useMemo(() => Boolean(userRoles?.some((r) => r.code === RoleCode.MANAGER)), [userRoles]);
  const isSentry = useMemo(() => Boolean(userRoles?.some((r) => r.code === RoleCode.SENTRY)), [userRoles]);

  return (
    <UserInfoContext.Provider
      value={{
        id: user?.id,
        isAdmin,
        isManager,
        isSentry,
        isUser,
        resetUser,
        userRoles: userRoles || [],
        user,
        resetRoles,
        refetchRoles,
        isRolesLoading,
        reports,
        userSubsystems,
        isReportLoading,
        isSubsystemLoading,
        customRights,
        isRightLoading,
        chartCombinationReports,
        isChartCombinationReportsLoading,
      }}
    >
      {children}
    </UserInfoContext.Provider>
  );
};
