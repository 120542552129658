import { EquipmentReportsDiagramFilters } from './EquipmentReportDiagramFilters';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { TFiltersArray } from 'models';
import { SubsystemHeader } from 'components/common';
import { NavTabList } from 'features/NavTabList';
import { EquipmentsAppRoutes } from '../../equipmentsAppRoutes';
import { useHistory, useLocation } from 'react-router-dom';
import { useDataForChartGanttDevices } from 'components/common/Graphics/ChartGantt/hooks/useDataForChartGanttDevices';
import EquipmentChart from 'pages/equipment/Reports/DiagramComponents/EquipmentChart';
import { ChartNoData } from 'components/common/Graphics/components/ChartNoData';
import { createUTCTime } from 'lib/create-date';
import { Box, Button, Flex } from '@chakra-ui/react';
import { MaterialTable } from 'features/material-table/MaterialTableMini';
import { deviceColumns, stationColumns } from 'pages/equipment/Reports/DiagramComponents/columns';
import { useHandleDeleteData, useRequest } from 'hooks';
import { apiPaths } from 'components/common/Graphics/apiPaths';
import { ExtensionToMimeTypeEnum } from 'lib/utils/files/getFileExtensionByMimeType';
import { downloadFileFromResponse } from 'lib/utils/files/downloadFile';
import { IServerDataTable } from 'components/common/Graphics/ChartGantt/types';
import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import { EquipmentLocale } from '../../locale';
import appLocale from 'constants/appLocale';
import { useCustomRights } from '../../../../hooks/useCustomRights';
import { subsystemCodesMap } from '../../../../constants/subsystemIdsMap';

export const EquipmentReportsDiagram = () => {
  useUpdateBreadcrumbs([
    {
      breadcrumb: EquipmentLocale.root,
      path: EquipmentsAppRoutes.root,
    },
    {
      breadcrumb: appLocale.equipmentPage.reports,
      path: EquipmentsAppRoutes.reports.root,
    },
    {
      breadcrumb: appLocale.equipmentPage.diagram,
      path: EquipmentsAppRoutes.reports.diagramDevices,
    },
  ]);
  const [showGraph, setShowGraph] = useState(false);
  const [order, setOrder] = useState('-dateFrom');
  const [filters, setFilters] = useState<TFiltersArray>([]);

  const location = useLocation();
  // TODO: change to prop
  const isStations = location.pathname.includes('/stations');
  const type = isStations ? 'station' : 'device';
  const key = isStations ? 'stationId' : 'deviceId';
  const body = useMemo(
    () =>
      (filters[2]?.value && filters[0].value && filters[1].value
        ? {
          dateStart: createUTCTime(filters[0].value as string),
          dateEnd: createUTCTime(filters[1].value as string),
          [key]: filters[2].value,
        }
        : null),
    [filters, key]
  );

  const { isLoading, setData, data } = useDataForChartGanttDevices({ type, body });
  useEffect(() => () => setData(null), [setData]);

  // table data START
  const tableUrl = type === 'device' ? apiPaths.device.tableById : apiPaths.station.tableById;
  const { post, result } = useRequest<IServerDataTable>(tableUrl);
  useEffect(() => {
    if (body) {
      void post({ ...body, order });
    }
  }, [body, post, order]);

  const { post: download } = useRequest('');
  const downloadAsFile = useCallback(
    async (fileType: ExtensionToMimeTypeEnum) => {
      const response = await download({ ...body, order }, { url: tableUrl, headers: { ACCEPT: fileType }, responseType: 'blob' });
      downloadFileFromResponse({ response, name: 'export_devices', type: fileType });
    },
    [body, download, order, tableUrl]
  );

  const toggleOrder = useCallback(
    (fieldKey: string) => {
      if (order[0] === '-') {
        setOrder(fieldKey);
      } else {
        setOrder(`-${fieldKey}`);
      }
    },
    [order]
  );
  // table data END

  const { ModalComponent, handleOpenModal } = useHandleDeleteData({ refetch: () => post(body) });
  const { isRead: isDeviceRead } = useCustomRights(subsystemCodesMap.equipment.device);
  const { isRead: isStationRead } = useCustomRights(subsystemCodesMap.equipment.station);

  const initialTab = useMemo(() => (isDeviceRead ? 0 : 1), [isDeviceRead]);

  const history = useHistory();

  useEffect(() => {
    if (!isDeviceRead && !isStations) {
      history.push(EquipmentsAppRoutes.reports.diagramStations);
    }
  }, [history, isDeviceRead, isStations]);

  return (
    <>
      {!isStationRead && !isDeviceRead ? null : (
        <>
          <SubsystemHeader
            title="Диаграммы"
            navigationTabs={
              <NavTabList
                tabs={[
                  {
                    label: 'Приборы',
                    path: EquipmentsAppRoutes.reports.diagramDevices,
                    isDisabled: !isDeviceRead,
                  },
                  {
                    label: 'Станции',
                    path: EquipmentsAppRoutes.reports.diagramStations,
                    isDisabled: !isStationRead,
                  },
                ]}
                tabIndex={initialTab}
              />
            }
            filtersComponent={
              <EquipmentReportsDiagramFilters
                onSubmit={(values: any) => {
                  const filters: TFiltersArray = Object.values(values);
                  setFilters(filters);
                }}
                isStations={isStations}
                isLoading={isLoading}
              />
            }
            defaultFiltersOpen
          />

          {!showGraph && (
            <MaterialTable
              filterDTO={{
                orders: [order],
                limit: result?.meta?.limit || 0,
                offset: result?.meta?.offset || 0,
                filters: [],
              }}
              columns={isStations ? stationColumns : deviceColumns}
              data={result?.data}
              isLoading={isLoading}
              options={{ selection: false }}
              topBarLeftContent={
                <Button variant="solid" size="sm" colorScheme="PrimaryButton" onClick={() => setShowGraph(true)}>
                  Открыть диаграмму
                </Button>
              }
              toggleOrder={toggleOrder}
              downloadAsFile={{
                downloadAsFile,
                types: Object.values(ExtensionToMimeTypeEnum),
              }}
              actions={[
                {
                  type: 'delete',
                  onClick: (_e, item) => {
                    handleOpenModal({ url: `/equipment/v10/equipments/stations/placements/${item.placementId}` });
                  },
                  hidden: (isStations && !isStationRead) || (!isStations && !isDeviceRead),
                },
              ]}
            />
          )}

          {showGraph && (
            <>
              <Flex alignItems="center" backgroundColor="#FAFBFB" minWidth="100%" height="60px" padding="10px 20px">
                <Button p={4} variant="solid" size="sm" colorScheme="PrimaryButton" onClick={() => setShowGraph(false)}>
                  Открыть таблицу
                </Button>
              </Flex>

              {data ? (
                <EquipmentChart
                  startDate={filters[0]?.value}
                  stopDate={filters[1]?.value}
                  data={filters[2]?.value ? data : null}
                />
              ) : (
                <Box p={4}>
                  <ChartNoData isLoading={isLoading} />
                </Box>
              )}
            </>
          )}

          {ModalComponent}
        </>
      )}
    </>
  );
};
