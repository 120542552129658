import { Flex } from '@chakra-ui/react';
import React from 'react';
import { IForecastAddItemEntity } from 'pages/data/Meteo/Forecast/Data/consts/types';
import { ForecastItemGeneralInfoForm } from 'pages/data/Meteo/Forecast/Data/forms/ForecastItemsForm/ForecastItemGeneralInfoForm';
import { ForecastItemWindForm } from 'pages/data/Meteo/Forecast/Data/forms/ForecastItemsForm/ForecastItemWindForm';
import { ForecastItemAirTForm } from 'pages/data/Meteo/Forecast/Data/forms/ForecastItemsForm/ForecastItemAirTForm';

const ForecastItemsForm = ({ entity, dictionary, editedObject }: IForecastAddItemEntity): JSX.Element => (
  <Flex flexDirection="column">
    {/* General information section */}
    <ForecastItemGeneralInfoForm entity={entity} dictionary={dictionary} editedObject={editedObject} />

    {/* Wind section */}
    <ForecastItemWindForm entity={entity} dictionary={dictionary} />

    {/* Air temperature section */}
    <ForecastItemAirTForm entity={entity} dictionary={dictionary} />
  </Flex>
);

export { ForecastItemsForm };
