import * as yup from 'yup';
import { requiredError, shouldBeLessThan, shouldBeMoreThan } from '../constans';
import subsystemIdsMap from 'constants/subsystemIdsMap';

export const NsiWaterGatesSchema = yup.object().shape({
  subsystemId: yup.string().nullable().required(requiredError),
  areaId: yup
    .string()
    .when('subsystemId', (subsystemId) =>
      (subsystemId === subsystemIdsMap.bottomSoil || subsystemId === subsystemIdsMap.surfaceWater
        ? yup.string().nullable()
        : yup.string().nullable().required(requiredError))),
  waterId: yup.string().nullable().required(requiredError),
  name: yup.string().nullable().required(requiredError),
  shortName: yup.string().nullable().required(requiredError),
  number: yup.number().nullable(),
  status: yup.string().nullable().required(requiredError),
  orderNumber: yup.number().nullable(),

  okrugId: yup.string().nullable(),
  rayonId: yup.string().nullable(),
  address: yup.string().nullable(),
  landmark: yup.string().nullable(),
  latitude: yup
    .number()
    .lessThan(90, shouldBeLessThan(90))
    .moreThan(-90, shouldBeMoreThan(-90))
    .typeError('Допустимые символы: "123", "."')
    .nullable()
    .required(requiredError),
  longitude: yup
    .number()
    .lessThan(100, shouldBeLessThan(100))
    .moreThan(-100, shouldBeMoreThan(-100))
    .typeError('Допустимые символы: "123", "."')
    .nullable()
    .required(requiredError),
});
