import appLocale from 'constants/appLocale';
import subsystemIdsMap from 'constants/subsystemIdsMap';
import { Meteo } from '../../home/icons';

export const MeteoDataRootPath = '/data/meteo';
export const MeteoDataMonitoringRootPath = `${MeteoDataRootPath}/data`;
export const AISWeatherRootPath = `${MeteoDataMonitoringRootPath}/ais-weather`;
export const MeteoDataMonitoringDataPath = `${MeteoDataMonitoringRootPath}/monitoring`;
export const MeteoDataProfilemerApiRoot = '/meteo/v10/profilemer';
export const MeteoDataApiRoot = '/meteo/v10/meteo';
export const ForecastRootPath = '/data/meteo/forecast/data';
export const ForecastBaseURL = '/meteo/v10/forecast';

export const meteoDataMenu = {
  name: 'Метеоданные',
  id: MeteoDataMonitoringRootPath,
  url: MeteoDataMonitoringRootPath,
  children: [
    {
      name: 'Профилемеры',
      id: MeteoDataMonitoringDataPath,
      url: MeteoDataMonitoringDataPath,
      subsystemId: subsystemIdsMap.meteoProfilemers,
    },
    {
      name: 'АИС Погода',
      id: AISWeatherRootPath,
      url: AISWeatherRootPath,
      subsystemId: subsystemIdsMap.meteoAis,
    },
    {
      name: 'Актинометрия',
      id: '/data/meteo/data/actinometry',
      url: '/data/meteo/data/actinometry',
      subsystemId: subsystemIdsMap.meteoActinometry,
    },
    {
      name: 'АСКЗА',
      id: '/data/meteo/data/askza',
      url: '/data/meteo/data/askza',
      subsystemId: subsystemIdsMap.meteoASKZA,
    },
    {
      name: 'МСЗ-4',
      id: '/data/meteo/data/msz4',
      url: '/data/meteo/data/msz4',
      subsystemId: subsystemIdsMap.meteoMSZ4,
    },
  ],
};

export const meteoProtocolMenu = {
  name: `${appLocale.common.dataProtocol} ( Метеоданные )`,
  id: `${MeteoDataRootPath}/protocol`,
  url: `${MeteoDataRootPath}/protocol`,
  children: [
    {
      name: 'Профилемеры',
      id: `${MeteoDataRootPath}/protocol/monitoring`,
      url: `${MeteoDataRootPath}/protocol/monitoring`,
      subsystemId: subsystemIdsMap.meteoProfilemers,
    },
    {
      name: 'АИС Погода',
      id: `${MeteoDataRootPath}/protocol/ais-weather`,
      url: `${MeteoDataRootPath}/protocol/ais-weather`,
      subsystemId: subsystemIdsMap.meteoAis,
    },
    {
      name: 'Актинометрия',
      id: '/data/meteo/protocol/actinometry',
      url: '/data/meteo/protocol/actinometry',
      subsystemId: subsystemIdsMap.meteoActinometry,
    },
    {
      name: 'АСКЗА',
      id: '/data/meteo/protocol/askza',
      url: '/data/meteo/protocol/askza',
      subsystemId: subsystemIdsMap.meteoASKZA,
    },
    {
      name: 'МСЗ-4',
      id: '/data/meteo/protocol/msz4',
      url: '/data/meteo/protocol/msz4',
      subsystemId: subsystemIdsMap.meteoMSZ4,
    },
  ],
};

export const meteoMenu = {
  name: appLocale.subsystems.meteo,
  id: MeteoDataRootPath,
  url: MeteoDataRootPath,
  subsystemId: subsystemIdsMap.meteoRoot,
  homepageIcon: <Meteo />,
  children: [
    meteoDataMenu,
    meteoProtocolMenu,
    {
      name: 'Прогноз',
      id: '/data/meteo/forecast',
      url: '/data/meteo/forecast',
      subsystemId: subsystemIdsMap.forecast,
      children: [
        {
          name: 'Прогноз',
          id: ForecastRootPath,
          url: ForecastRootPath,
        },
        {
          name: `${appLocale.common.dataProtocol} (Прогноз)`,
          id: '/data/meteo/forecast/protocol',
          url: '/data/meteo/forecast/protocol',
        },
      ],
    },
    {
      name: 'Журнал НМУ',
      id: '/data/meteo/nmu',
      url: '/data/meteo/nmu',
      subsystemId: subsystemIdsMap.meteoNMU,
    },
  ],
};

export default meteoMenu;
