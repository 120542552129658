import { SelectedCheckboxIcon, UnselectedCheckboxIcon } from 'components/icons';
import { createLocalDateDDMMYYYY } from 'lib/create-date';
import { map } from 'lodash';
import { Column } from '@material-table/core';
import { TDictionaryItemDictionary } from 'models';
import React, { useMemo } from 'react';
import { ILawnDto } from './useLawnsList';
import { lawnsTypesMap } from 'pages/data/GreenPlants/Lawns/useLawnsListColumns';
import { parameterToParameterIdMap } from 'features/parametersMap';
import { TableFilterTypeEnum } from 'components/table/filters/TableFilter';
import { DefaultTableCellSize } from 'components/table/DefaultTableCellSize';
import { ColumnWithFilter } from 'lib/utils/tableFilter';

export const useLawnsListColumns = (
  dictionary?: TDictionaryItemDictionary<string | (Record<any, string> & string),
    string | (Record<any, string> & string)>[],
) => {
  const extra = useMemo(
    () => map<TDictionaryItemDictionary<string | Record<string, any>>, Column<ILawnDto>>(dictionary, (item) => {
      const name = item.parameterId && parameterToParameterIdMap[item.parameterId];
      return {
        title: item.title,
        field: `${item.parameterId}`,
        render(row) {
          if (item.parameterId) {
            let value = '';
            let checkboxEl: JSX.Element | null = null;
            if (Array.isArray(row.parameters[item.parameterId])) {
              // @ts-ignore
              const values = row.parameters[item.parameterId].map((param) => param.value)
                // @ts-ignore
                .filter((val) => val !== 'true' && val !== 'false')
                .join(', ');
              value = values;
              // @ts-ignore
              const checkboxes = row.parameters[item.parameterId].filter((rowParam: any) => rowParam.value === 'true' || rowParam.value === 'false');
              if (checkboxes.length > 0) {
                checkboxEl = checkboxes[0].value === 'true' ?
                  React.createElement(SelectedCheckboxIcon) : React.createElement(UnselectedCheckboxIcon);
              }
            }
            if (checkboxEl) {
              return checkboxEl;
            }
            return <DefaultTableCellSize>{value}</DefaultTableCellSize>;
          }
        },
        filterType: name && lawnsTypesMap[name],
      };
    }), [dictionary],
  );

  return useMemo<Array<ColumnWithFilter<ILawnDto>>>(
    () => [
      {
        title: 'Дата обследования',
        field: 'date',
        render: (row) => {
          if (row.date) {
            return createLocalDateDDMMYYYY(row.date);
          }
          return '-';
        },
      },
      {
        title: '№ Площадки',
        field: 'areaNumber',
        render: (row) => (row.areaNumber ? row.areaNumber : '-'),
      },
      {
        title: 'Округ',
        field: 'okrugName',
      },
      {
        title: 'Район',
        field: 'rayonName',
      },
      {
        title: 'Адрес',
        field: 'areaAddress',
        filterType: TableFilterTypeEnum.string,
        render: (row) => <DefaultTableCellSize>{(row.areaAddress ? row.areaAddress : '-')}</DefaultTableCellSize>,
      },
      {
        title: 'Тип территории',
        field: 'areaTypeName',
      },
      ...extra,
    ],
    [extra],
  );
};
