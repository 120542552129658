import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import appLocale from 'constants/appLocale';
import { SubsystemHeader } from 'components/common';
import { FC, useMemo } from 'react';
import { NSIRootPath } from '../const/routes';
import { NSISoilAPIPath, NSISoilDictPath, NSISoilRootPath } from './consts';
import { useHistory } from 'react-router-dom';
import { NSISoilFilter } from './Filter';
import { MaterialTable } from 'features/material-table/MaterialTableMini';
import { getSelectOptionsFromDictionaryByName } from 'features/get-select-options-from-dictionary';
import { useList } from 'hooks';
import { INSISoilItem } from 'pages/dictionaries/Soil/consts/types';
import { Column } from '@material-table/core';
import { subsystemCodesMap } from 'constants/subsystemIdsMap';
import { useCustomRights } from 'hooks/useCustomRights';
import { useDictionary } from 'hooks/useDictionary';
import { FullPageWrapper } from '../../../components/table/FullPageWrapper';
import { DEFAULT_PAGE_SIZE_NEW_TABLE } from '../../../constants/tables';

export const NSISoil: FC = () => {
  useUpdateBreadcrumbs([
    {
      breadcrumb: appLocale.mainPage.nsi,
      path: NSIRootPath,
    },
    {
      breadcrumb: appLocale.subsystems.nsiSoil,
      path: NSISoilRootPath,
    },
  ]);

  const history = useHistory();

  const { isWrite } = useCustomRights(subsystemCodesMap.nsi.soil);

  const {
    response,
    setFilters,
    onPageChange,
    isLoading,
    toggleOrder,
    filterDTO,
    onSearchText,
    downloadAsFile,
    materialTableProps,
  } = useList<INSISoilItem>(`${NSISoilAPIPath}/list`, {
    initialOrderFieldKey: 'number',
    saveFiltersState: true,
    defaultPageSize: DEFAULT_PAGE_SIZE_NEW_TABLE,
    resetDataOnNextRequest: true,
  });

  const handleAddButtonClick = () => history.push(`${NSISoilRootPath}/add`);
  const tableEditButtonClick = (id: string) => history.push(`${NSISoilRootPath}/edit/${id}`);

  const { dictionaries } = useDictionary({ url: NSISoilDictPath });

  const statuses = useMemo(() => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'statuses'), [dictionaries]);
  const rayons = useMemo(() => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'rayons'), [dictionaries]);
  const okrugs = useMemo(() => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'okrugs'), [dictionaries]);
  const sourceTypes = useMemo(
    () => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'sourceTypes'),
    [dictionaries],
  );
  const funcZones = useMemo(
    () => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'funcZones'),
    [dictionaries],
  );
  const funcZoneTypes = useMemo(
    () => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'funcZoneTypes'),
    [dictionaries],
  );

  const columns: Column<INSISoilItem>[] = [
    {
      title: 'Уникальный номер точки',
      field: 'number',
    },
    {
      title: 'Наименования',
      field: 'name',
    },
    {
      title: 'Округ',
      field: 'okrugId',
      render: (row) => okrugs.find((o) => o.value === row.okrugId)?.name,
    },
    {
      title: 'Район',
      field: 'rayonId',
      render: (row) => rayons.find((o) => o.value === row.rayonId)?.name,
    },
    {
      title: 'Адрес',
      field: 'address',
    },
    {
      title: 'Тип источника наблюдения за почвой',
      field: 'soilSourceTypeId',
      render: (row) => sourceTypes.find((o) => o.value === row.soilSourceTypeId)?.name,
    },
    {
      title: 'Функциональная зона',
      field: 'soilFuncZoneId',
      render: (row) => funcZones.find((o) => o.value === row.soilFuncZoneId)?.name,
    },
    {
      title: 'Тип функциональной зоны',
      field: 'soilFuncZoneTypeId',
      render: (row) => funcZoneTypes.find((o) => o.value === row.soilFuncZoneTypeId)?.name,
    },
    {
      title: 'Статус',
      field: 'status',
      render: (row) => statuses.find((source) => source.value === row.status)?.name,
    },
  ];

  return (
    <FullPageWrapper>
      <SubsystemHeader
        addButtonProps={{
          onClick: handleAddButtonClick,
          isDisabled: !isWrite,
        }}
        title={appLocale.subsystems.nsiSoil}
        filtersComponent={
          <NSISoilFilter
            dictionaries={dictionaries}
            setFilters={setFilters}
            isLoading={isLoading}
            filters={filterDTO.filters}
          />
        }
      />
      <MaterialTable
        data={response?.data}
        meta={response?.meta}
        onSearch={onSearchText}
        filterDTO={filterDTO}
        toggleOrder={toggleOrder}
        onPageChange={onPageChange}
        isLoading={isLoading}
        options={{
          search: true,
        }}
        columns={columns}
        actions={[
          {
            type: 'edit',
            onClick: (_e, row) => {
              tableEditButtonClick(row.id);
            },
            disabled: !isWrite,
            hidden: !isWrite,
          },
        ]}
        downloadAsFile={downloadAsFile}
        showTotalCount
        {...materialTableProps}
      />
    </FullPageWrapper>
  );
};
