import React, { memo, useMemo, useState } from 'react';
import { MaterialTable } from 'features/material-table/MaterialTableMini';
import { DeviceModel } from 'pages/equipment/Models/types';
import {
  DeviceModelsTableFilter
} from 'pages/equipment/Models/components/DeviceModelsTable/DeviceModelsTableFilter/DeviceModelsTableFilter';
import { TFiltersArray } from 'models';
import { ConfirmModal, SubsystemHeader } from 'components/common';
import { useDisclosure, useList, useNotifications, useRequest } from 'hooks';
import { useHistory } from 'react-router-dom';
import { useCustomRights } from 'hooks/useCustomRights';
import { subsystemCodesMap } from 'constants/subsystemIdsMap';
import { fieldsLocale } from '../../locale';
import { Column } from '@material-table/core';
import { TruncatedStringPopoverCell } from 'components/table/TruncatedStringPopoverCell';
import { DeleteIcon } from 'components/icons';
import { ERROR_MESSAGES, REMOVE_CONFIRMATION, SUCCESS_MESSAGES } from 'constants/messages';
import { DEFAULT_PAGE_SIZE_NEW_TABLE } from '../../../../../constants/tables';
import { DefaultTableCellSize } from '../../../../../components/table/DefaultTableCellSize';

export interface DeviceModelsTableProps {
  onEdit: (item: DeviceModel) => void;
}

export const DeviceModelsTable: React.FC<DeviceModelsTableProps> = memo((props) => {
  const [removedItem, setRemovedItem] = useState<string>('');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { successNotify, errorNotify } = useNotifications();
  const { isWrite } = useCustomRights(subsystemCodesMap.equipment.model);
  const { push } = useHistory();
  const {
    response,
    isLoading,
    toggleOrder,
    onPageChange,
    filterDTO,
    setFilters,
    onSearchText,
    errorText,
    refetch,
    materialTableProps,
  } = useList('/equipment/v10/equipments/models/list', {
    initialOrderFieldKey: 'modelName',
    saveFiltersState: true,
    defaultPageSize: DEFAULT_PAGE_SIZE_NEW_TABLE,
    resetDataOnNextRequest: true,
  });

  const onSuccess = () => {
    onClose();
    refetch();
    successNotify({
      key: 'equipment/delete/success',
      message: SUCCESS_MESSAGES.DELETE,
    });
  };
  const onError = () => {
    onClose();
    errorNotify({
      key: 'equipment/delete/error',
      message: ERROR_MESSAGES.DELETE,
    });
  };

  const { remove } = useRequest('', onSuccess, onError);

  const columns = useMemo<Column<any>[]>(
    () => [
      {
        title: fieldsLocale.modelName,
        field: 'modelName',
      },
      {
        title: fieldsLocale.deviceNames,
        field: 'deviceNames',
        render: (row) => <TruncatedStringPopoverCell value={row.deviceNames.join(', ')} isLargeText />,
      },
      {
        title: fieldsLocale.sensorType,
        field: 'sensorTypeName',
      },
      {
        title: fieldsLocale.characteristic,
        field: 'characteristic',
        render: (row) => <DefaultTableCellSize>{row.characteristic.join(', ')}</DefaultTableCellSize>,
        sorting: false,
      },
      {
        title: fieldsLocale.vendor,
        field: 'vendor',
        render: (row) => <DefaultTableCellSize>{row.vendor}</DefaultTableCellSize>,
      },
    ],
    []
  );

  return (
    <>
      <SubsystemHeader
        title="Модели"
        addButtonProps={{
          onClick: () => push('/equipment/models/add'),
          disabled: !isWrite,
        }}
        filtersComponent={
          <DeviceModelsTableFilter
            onSubmit={(values) => {
              const filters: TFiltersArray = Object.values(values);
              setFilters(filters);
            }}
            isDisabled={isLoading}
            isLoading={isLoading}
            filters={filterDTO.filters}
          />
        }
      />

      <MaterialTable
        filterDTO={filterDTO}
        data={response?.data}
        meta={response?.meta}
        columns={columns}
        toggleOrder={toggleOrder}
        onPageChange={onPageChange}
        actions={[
          {
            type: 'edit',
            onClick: (e, item) => {
              if (Array.isArray(item)) {
                return;
              }

              props.onEdit(item);
            },
            hidden: !isWrite,
          },
          {
            position: 'row',
            action: (row) => ({
              icon: () => <DeleteIcon />,
              onClick: () => {
                onOpen();
                setRemovedItem(row.id);
              },
              tooltip: 'Удалить',
              hidden: !isWrite || !row.canDelete,
            }),
          },
        ]}
        options={{
          search: true,
          selection: false,
        }}
        isLoading={isLoading}
        onSearch={onSearchText}
        errorText={errorText}
        {...materialTableProps}
      />
      <ConfirmModal
        isOpen={isOpen}
        onClose={onClose}
        onClick={() =>
          remove({
            url: `/equipment/v10/equipments/models/${removedItem}`,
          })
        }
        header={REMOVE_CONFIRMATION}
      />
    </>
  );
});

DeviceModelsTable.displayName = 'DeviceModelsTable';
