import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ButtonGraphicLineReporting, IFilterValues, SubsystemHeader, useDefaultFilters } from 'components/common';
import { MaterialTable } from 'features/material-table/MaterialTableMini';
import { AtmosphereMonitoringItem } from 'hooks/atmosphere/Monitoring/useAtmosphereMonitoringList';
import { map } from 'lodash';
import moment from 'moment';
import { useList } from 'hooks/useList';
import { Column } from '@material-table/core';
import { addFiltersToColumns, ColumnWithFilter } from 'lib/utils/tableFilter';
import { TableFilterTypeEnum } from 'components/table/filters/TableFilter';
import useGetListItemByIds from 'hooks/useGetListItemByIds';
import { operations } from 'lib/calculations/operations';
import useCalculations from 'hooks/useCalculations';
import subsystemIdsMap from 'constants/subsystemIdsMap';
import graphicsTemplateIdsMap from 'constants/graphicsTemplateIdsMap';
import { AtmosphereFilter } from './AtmisphereFilter';
import { TAtmosphereMonitoringFilterType } from 'pages/data/Atmosphere/Monitoring/types';
import { Modal } from 'components/common/Modals';
import { TDictionaryItemDictionary, TFiltersArray } from 'models';
import { Flex } from '@chakra-ui/react';
import { useDictionary } from 'hooks/useDictionary';

interface Props {
  stationId: string | null;
  fromDate?: string | null;
  toDate?: string | null;
  isOpen: boolean;
  onClose: () => void;
  stations?: TDictionaryItemDictionary[];
}

export const PelFTPData = ({ stationId, fromDate, toDate, isOpen, onClose, stations }: Props) => {
  const { dictionaries } = useDictionary({ url: '/atmosphere/v10/atmosphere/dictionaries' });

  const initialFilters: TFiltersArray<TAtmosphereMonitoringFilterType> = useMemo(() => (
      [
        {
          key: 'avg.valDateTime',
          operation: 'GTE',
          value: fromDate || null,
        },
        {
          key: 'avg.valDateTime',
          operation: 'LTE',
          value: toDate || null,
        },
        {
          key: 'stationId',
          operation: 'EQ',
          value: stationId || '',
        },
        {
          key: 'deleted',
          operation: 'EQ',
          value: false,
        },
      ]),
    [stationId, fromDate, toDate]);

  const {
    refetch,
    isLoading: isLoadingAtmosphereMonitoringList,
    response,
    toggleOrder,
    setFilters,
    onPageChange,
    filterDTO,
    downloadAsFile,
    saveFilters,
    onSearchText,
    errorText,
    selectedIndexes,
    renderOnlySelectedCheckbox,
    onSubmitFilters,
    onSelectionChange,
    selectedIds,
  } = useList<AtmosphereMonitoringItem, TAtmosphereMonitoringFilterType>('/atmosphere/v10/atmosphere/list', {
    initialOrderFieldKey: '-avg.valDateTime',
    exportSubsystemName: 'askza',
    preventFirstFetch: true,
    selectionKey: 'id',
  });

  useEffect(() => {
    if ((stationId || toDate || fromDate) && isOpen) {
      setFilters(initialFilters);
    }
  }, [isOpen, stationId, setFilters, toDate, fromDate, initialFilters]);

  const formatter = useMemo(
    () =>
      new Intl.NumberFormat('en-US', {
        style: 'decimal',
        maximumFractionDigits: 20,
      }),
    []
  );

  const columns = useMemo<Array<ColumnWithFilter<AtmosphereMonitoringItem>>>(
    () => [
      {
        title: 'Дата и время',
        field: 'avg.valDateTime',
        render: (row) => {
          const date = moment(row.avg.valDateTime);

          return (
            <Flex flexDirection="column">
              <span>{date.format('HH:mm:ss')}</span>
              <span>{date.format('DD.MM.YYYY')}</span>
            </Flex>
          );
        },
      },
      {
        title: 'Станция',
        field: 'stationName',
        filterType: TableFilterTypeEnum.string,
      },
      {
        title: 'Параметр',
        field: 'paramName',
        filterType: TableFilterTypeEnum.string,
      },
      {
        title: 'Значение max',
        field: 'max.valNumeric', // сортируется и фильтруется по этому ключу
        render: (row) => (row.max?.strValue ? formatter.format(row.max.strValue) : '-'),
        filterType: TableFilterTypeEnum.number,
        currencySetting: {
          maximumFractionDigits: -1,
        },
      },
      {
        title: 'Значение avg',
        field: 'avg.valNumeric', // сортируется и фильтруется по этому ключу
        render: (row) => (row.avg?.strValue ? formatter.format(row.avg.strValue) : '-'),
        filterType: TableFilterTypeEnum.number,
      },
      {
        title: 'Значение min',
        field: 'min.valNumeric', // сортируется и фильтруется по этому ключу
        render: (row) => (row.min?.strValue ? formatter.format(row.min.strValue) : '-'),
        filterType: TableFilterTypeEnum.number,
        currencySetting: {
          maximumFractionDigits: 1e15,
        },
      },
    ],
    [formatter]
  );

  // calculations
  const { isCalculationLoading, applyCalculation, renderDriftPreview } = useCalculations({
    onSuccess: refetch,
    apiPath: '/atmosphere/v10/atmosphere',
    columns,
  });

  // data processing
  const { getItems, isLoading: isItemsLoading } = useGetListItemByIds<AtmosphereMonitoringItem>({
    url: '/atmosphere/v10/atmosphere/list',
    idKey: 'id',
  });
  const onSubmitDataProcessing = useCallback(
    async (values) => {
      const selectedData = await getItems(selectedIds);
      applyCalculation({
        ...values,
        // TODO: add types to operandIds
        // @ts-ignore
        ids: map(selectedData, (row) => row[values.operandId].id),
      });
    },
    [applyCalculation, getItems, selectedIds]
  );
  const dataProcessing = useMemo(() => {
    const calculationFields = [
      { value: 'avg', name: 'avg' },
      { value: 'min', name: 'min' },
      { value: 'max', name: 'max' },
    ];

    return {
      operands: calculationFields,
      operations,
      onSubmit: onSubmitDataProcessing,
    };
  }, [onSubmitDataProcessing]);

  const columnsWithFilters = useMemo<Array<Column<AtmosphereMonitoringItem>>>(
    () => addFiltersToColumns({ filters: filterDTO.filters, setFilters, columns }),
    [columns, filterDTO.filters, setFilters]
  );

  const defaultFilters = useDefaultFilters({ filters: filterDTO.filters, dateKey: 'avg.valDateTime' });

  const [filtersFromGraphic, setFiltersFromGraphic] = useState<Partial<IFilterValues> | null>(null);

  const isLoading = useMemo(
    () => isCalculationLoading || isItemsLoading || isLoadingAtmosphereMonitoringList,
    [isCalculationLoading, isItemsLoading, isLoadingAtmosphereMonitoringList]
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Flex flexDirection="column">
        <SubsystemHeader
          title="Данные FTP по станциям ПЭЛ"
          defaultFiltersOpen
          filtersComponent={(
            <AtmosphereFilter
              dictionaries={dictionaries}
              stations={stations}
              isLoading={isLoading}
              onSubmit={onSubmitFilters}
              filtersFromGraphic={filtersFromGraphic}
              setFiltersFromGraphic={setFiltersFromGraphic}
              filters={initialFilters}
            >
              {renderOnlySelectedCheckbox}
            </AtmosphereFilter>
          )}
        />

        <MaterialTable
          data={response?.data}
          meta={response?.meta}
          toggleOrder={toggleOrder}
          onPageChange={onPageChange}
          filterDTO={filterDTO}
          columns={columnsWithFilters}
          options={{
            search: true,
          }}
          isLoading={isLoading}
          onSearch={onSearchText}
          dataProcessing={dataProcessing}
          downloadAsFile={downloadAsFile}
          selectedIndexes={selectedIndexes}
          onSelectionChange={onSelectionChange}
          saveFilters={saveFilters}
          setFilters={setFilters}
          topBarLeftContent={(
            <ButtonGraphicLineReporting
              subSystemId={subsystemIdsMap.atmosphere}
              templateId={graphicsTemplateIdsMap.atmosphere}
              defaultFilters={defaultFilters}
              setFiltersFromGraphic={setFiltersFromGraphic}
            />
          )}
          errorText={errorText}
        />
        {renderDriftPreview}
      </Flex>
    </Modal>
  );
};
