import * as yup from 'yup';
import { acceptableRange, numberError, numberWithSignError, numberWithTextSignError } from 'models/schemas/constans';

export const percentage = yup
  .number()
  .typeError(numberError)
  .nullable()
  .max(100, acceptableRange(0, 100))
  .min(0, acceptableRange(0, 100));

export const numberWithSign = yup
  .string()
  .matches(new RegExp('^[\\<,\\>]?([0-9]*[.])?[0-9]+$'), numberWithSignError)
  .nullable();

export const numberWithTextSign = yup
  .string()
  .matches(new RegExp('^((более |менее )|[\\<\\>])?([0-9]*[.])?[0-9]+$'), numberWithTextSignError)
  .nullable();
