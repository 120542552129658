import { SubsystemHeader } from 'components/common';
import { NavTabList } from 'features/NavTabList';
import { DeicingGreenPlantsRootPath } from 'pages/routes/PGRGreenPlants';

import React from 'react';

interface AreasDataHeaderProps {
  handleAdd(): void;
  isWrite?: boolean;
  isNoRights?: boolean;
  allAvailable?: boolean;
}
export const AreaHeader: React.FC<AreasDataHeaderProps> = ({
  handleAdd,
  isWrite,
  isNoRights,
  allAvailable,
  children
}) => {
  const isAvailable = allAvailable || isNoRights || isWrite;
  return (
    <SubsystemHeader
      title="Площадки"
      addButtonProps={{ onClick: handleAdd, isDisabled: !isAvailable }}
      navigationTabs={(
        <NavTabList
          tabs={[
            {
              label: 'Деревья и кустарники',
              path: `${DeicingGreenPlantsRootPath}/areas/flora`,
            },
            {
              label: 'Газоны',
              path: `${DeicingGreenPlantsRootPath}/areas/lawns`,
            },
          ]}
        />
      )}
      filtersComponent={children}
    />
  );
};
