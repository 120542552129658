export const colors = {
  common: {
    white: '#fff',
    black: '#000',
  },
  blue: {
    main: '#208CFF',
    light: '#7DBCFF',
    soft: '#c8e5ff',
    lighten: '#F2F8FF',
    dark: '#2B333C',
    darker: '#2C4055',
    darkest: '#2F3741',
  },
  grey: {
    50: '#FAFBFB',
    70: '#F5F5F5',
    80: '#F5F6F7',
    100: '#E5E5E5',
    200: '#D4D9DC',
    300: '#D6E1EB',
    400: '#BCC8D1',
    500: '#9FABBC',
    600: '#9A9A9A',
    700: '#6E7478',
    800: '#45505C',
    900: '#212639',
  },
  orange: {
    main: '#FF7712',
    warning: '#FF7712',
  },
  red: {
    main: '#FF3434',
    error: '#FF3434',
  },
  green: {
    main: '#37B466',
    success: '#37B466',
  },
};

export type Colors = typeof colors;
