import { AttachmentsTabContent, Button, DateInput, FormWrapper, Input, Select } from 'components/common';
import { Box, Flex, Grid, Heading } from '@chakra-ui/react';
import {
  getSelectOptionsFromDictionaryByName,
  getSelectOptionsFromDictionaryByParent,
} from 'features/get-select-options-from-dictionary';
import { Field, useFormikContext } from 'formik';
import { createDateFromResponse, createDateToRequestWithTimezone } from 'lib/create-date';
import React, { useMemo } from 'react';
import { useObjectType } from 'hooks/useObjectType';
import { FieldFormProps } from 'types/forms';
import { DangerProcessItem } from 'pages/data/GeoecologicalProcesses/DangerouslyGeologicalProcesses/DangerProcess/types';
import subsystemIdsMap from 'constants/subsystemIdsMap';
import { useAvailability } from 'hooks';
import { getFilteredOptionsByPrivileges } from 'features/getFilteredOptionsByPrivileges';

const FieldsForm = ({ onCancel, dictionaries, media, onMediaChange, uploadItemId, isEditMode }: FieldFormProps) => {
  const { isAvailable } = useAvailability(subsystemIdsMap.greenPlantsRoot);

  const { handleChange, setFieldValue, values, errors, handleSubmit } = useFormikContext<DangerProcessItem>();
  const objectType = useObjectType(dictionaries);

  const areas = useMemo(
    () =>
      getFilteredOptionsByPrivileges(
        getSelectOptionsFromDictionaryByName(dictionaries?.data, 'areas'),
        'isEditable',
        'value',
        values.areaId,
      ),
    [dictionaries?.data, values.areaId],
  );

  const points = useMemo(
    () => getSelectOptionsFromDictionaryByParent(dictionaries?.data, values.areaId),
    [dictionaries?.data, values.areaId],
  );

  return (
    <Box flexDirection="column">
      <FormWrapper>
        <Grid marginTop="30px" gap="15px">
          <Heading variant="h2">Источник измерения</Heading>
          <DateInput
            name="measuringDate"
            label="Дата обследования"
            value={createDateFromResponse(values.measuringDate)}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const time = createDateToRequestWithTimezone(e.target.value);
              setFieldValue('measuringDate', time);
            }}
            isDisabled={isEditMode}
            showTimeInput={false}
            error={errors.measuringDate}
          />
          <Field
            as={Select}
            name="areaId"
            label="Участок наблюдения"
            value={values.areaId}
            options={areas}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              setFieldValue('areaId', e.target.value);
              setFieldValue('pointId', null);
            }}
            isDisabled={isEditMode}
            error={errors.areaId}
          />
          <Field
            as={Select}
            name="pointId"
            label="Точка наблюдения"
            value={values.pointId}
            options={points}
            onChange={handleChange}
            isDisabled={isEditMode}
            error={errors.pointId}
          />
          <Field
            as={Input}
            name="value"
            label="Описание точки наблюдения"
            value={values.value}
            isDisabled={!isAvailable}
            onChange={handleChange}
            error={errors.value}
          />

          {onMediaChange && isAvailable && (
            <AttachmentsTabContent
              objectType={objectType}
              media={media}
              onMediaChange={onMediaChange}
              uploadItemId={uploadItemId}
            />
          )}

          <Flex mb={4}>
            <Button onClick={onCancel} marginRight="auto">
              Отмена
            </Button>
            <Button isDisabled={!isAvailable} onClick={() => handleSubmit()} variant="solid">
              Сохранить
            </Button>
          </Flex>
        </Grid>
      </FormWrapper>
    </Box>
  );
};

export default React.memo(FieldsForm);
