import { subsystemCodesMap } from 'constants/subsystemIdsMap';
import { ReportSmall } from 'features/WithSideBar/icons';

export const sentryMenu = {
  id: '/sentry/dashboard',
  name: 'Информационная панель ЛК СОК',
  url: '/sentry/dashboard',
  otherCode: subsystemCodesMap.sentryDashboard,
  icon: <ReportSmall />,
};
