import { FilterItem } from 'features/Filter/FilterFields';
import React from 'react';
import { Filter } from 'features/Filter';
import { createInitialValuesToFormik } from 'features/Filter/utils';
import appLocale from 'constants/appLocale';
import { IFilterItem } from 'models';

interface Props {
  mainFields?: FilterItem[];
  onSubmit: (values: any) => void;
  onClear: () => void;
  presetValues: { key: string; value: IFilterItem['value'] }[];
  initialValues: any;
  validationSchema?: any;
}

export const NODFilter: React.FC<Props> = ({
  children,
  mainFields,
  onSubmit,
  onClear,
  presetValues,
  initialValues,
  validationSchema,
}) => {
  const initialValuesToFormik = createInitialValuesToFormik(initialValues);
  return (
    <Filter
      presetValues={presetValues || []}
      initialValues={initialValuesToFormik}
      onSubmit={onSubmit}
      onClear={onClear}
      isValidationOnClick
      mainFields={mainFields}
      customSubmitButtons={{
        resetTitle: appLocale.actions.clear,
        submitTitle: appLocale.analyticsReports.actions.makeReport,
      }}
      validationSchema={validationSchema}
    >
      {children}
    </Filter>
  );
};
