import { Button, DateInput, FormWrapper, LoaderItem, Select } from 'components/common';
import { Box, Flex, Grid, Heading } from '@chakra-ui/react';
import { getSelectOptionsFromDictionaryByNameWithTransformation } from 'features/get-select-options-from-dictionary';
import { Field, useFormikContext } from 'formik';
import { createDateFromResponse, createDateToRequestWithTimezone } from 'lib/create-date';
import React, { useMemo } from 'react';
import { FieldFormProps } from 'types/forms';
import isRequiredInSchema from 'lib/utils/forms/isReqiuredInSchema';
import { ASKZACommonSchema } from 'models/schemas/atmosphere/askzaCommon';
import { getFilteredOptionsByPrivileges } from 'features/getFilteredOptionsByPrivileges';
import { TDictionaryItemDictionary } from 'models';
import { CommaNumberInput } from '../../Input/comma-number-input';

export interface MeasurementRecord {
  id: string;
  datetime: string;
  value: null | number;
}

export interface AtmosphereMonitoringItem {
  avg: MeasurementRecord;
  max: MeasurementRecord;
  min: MeasurementRecord;
  parameterId: string;
  stationId: string;
}

const FieldsForm = ({ onCancel, dictionaries, isLoading, checkSourcePriv = false }: FieldFormProps) => {
  const { errors, setFieldValue, values, handleSubmit } = useFormikContext<AtmosphereMonitoringItem>();

  const stationsName = useMemo(() => {
    const dict = getSelectOptionsFromDictionaryByNameWithTransformation(
      dictionaries?.data,
      'stations',
      'name',
    ) as TDictionaryItemDictionary[];
    if (checkSourcePriv) {
      return getFilteredOptionsByPrivileges(dict, 'isEditable', 'value', values.stationId);
    }
    return dict;
  }, [checkSourcePriv, dictionaries?.data, values.stationId]);

  const paramNumbers = useMemo(() => {
    const dict = getSelectOptionsFromDictionaryByNameWithTransformation(
      dictionaries?.data,
      'parameters',
      'name',
    ) as TDictionaryItemDictionary[];
    if (checkSourcePriv) {
      return getFilteredOptionsByPrivileges(dict, 'isEditable', 'value', values.parameterId);
    }
    return dict;
  }, [checkSourcePriv, dictionaries?.data, values.parameterId]);

  if (isLoading) {
    return <LoaderItem />;
  }

  return (
    <Box flexDirection="column">
      <FormWrapper>
        <Grid marginTop="30px" gap="15px">
          <Heading variant="h2">Источник измерения</Heading>
          <Field
            as={Select}
            name="stationId"
            label="Источник"
            options={stationsName}
            isRequired={isRequiredInSchema(ASKZACommonSchema, 'stationId')}
            error={errors.stationId}
          />

          <Heading variant="h2">Измеряемый параметр</Heading>
          <Field
            as={Select}
            name="parameterId"
            label="Параметр"
            options={paramNumbers}
            isRequired={isRequiredInSchema(ASKZACommonSchema, 'parameterId')}
            error={errors.parameterId}
          />

          <Heading variant="h2">Результат измерения</Heading>

          <Field as={CommaNumberInput} name="avg.value" label="Значение среднее" error={errors.avg?.value} />
          <DateInput
            name="avg.datetime"
            label="Дата"
            value={createDateFromResponse(values.avg.datetime)}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setFieldValue('avg.datetime', createDateToRequestWithTimezone(e.target.value))
            }
            showTimeInput
            isRequired={isRequiredInSchema(ASKZACommonSchema, 'avg.fields.datetime')}
            error={errors.avg?.datetime}
          />

          <Field as={CommaNumberInput} name="min.value" label="Значение минимальное" error={errors.min?.value} />
          <DateInput
            name="min.datetime"
            label="Дата"
            value={createDateFromResponse(values.min.datetime)}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setFieldValue('min.datetime', createDateToRequestWithTimezone(e.target.value))
            }
            showTimeInput
            isRequired={isRequiredInSchema(ASKZACommonSchema, 'min.fields.datetime')}
            error={errors.min?.datetime}
          />

          <Field as={CommaNumberInput} name="max.value" label="Значение максимальное" error={errors.max?.value} />
          <DateInput
            name="max.datetime"
            label="Дата"
            value={createDateFromResponse(values.max.datetime)}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setFieldValue('max.datetime', createDateToRequestWithTimezone(e.target.value))
            }
            showTimeInput
            isRequired={isRequiredInSchema(ASKZACommonSchema, 'max.fields.datetime')}
            error={errors.max?.datetime}
          />

          <Flex>
            <Button onClick={onCancel} marginRight={20}>
              Отмена
            </Button>
            <Button onClick={() => handleSubmit()} variant="solid">
              Сохранить
            </Button>
          </Flex>
        </Grid>
      </FormWrapper>
    </Box>
  );
};

export default React.memo(FieldsForm);
