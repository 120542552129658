import { useHandleDeleteData, useList, useRequest } from 'hooks';
import React, { useCallback, useEffect, useMemo } from 'react';
import { SubsystemHeader } from 'components/common';
import { ListResponse } from 'models/api/list';
import { INotification } from 'pages/Notifications/types';
import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import { Box, Flex } from '@chakra-ui/react';
import { MaterialTable } from 'features/material-table/MaterialTableMini';
import { tableIcons } from 'pages/admin/components/CustomTable';
import { NotificationFilter } from 'pages/Notifications/NotificationFilter';
import { SubsystemItem } from 'pages/admin/models/SubsystemItem';
import { TOption } from 'pages/admin/models/toption';
import { Column } from '@material-table/core';
import { TruncatedStringPopoverCell } from 'components/table/TruncatedStringPopoverCell';
import { createLocalDateDDMMYYYYWithTime } from 'lib/create-date';
import { NotificationSource } from 'pages/admin/models/NotificationSource';
import { FullPageWrapper } from '../../components/table/FullPageWrapper';
import { DefaultTableCellSize } from '../../components/table/DefaultTableCellSize';

interface IProps {
  defaultPageSize?: number;
  isDashboard?: boolean;
  defaultType?: keyof typeof NotificationSource;
  onReadCallback?: (type: keyof typeof NotificationSource) => void;
  loadUnreadNotifications: () => void;
}

const Notifications = ({
  defaultPageSize = 25,
  isDashboard = false,
  defaultType,
  onReadCallback,
  loadUnreadNotifications,
}: IProps) => {
  useUpdateBreadcrumbs(!isDashboard ? [] : undefined);

  const {
    result: subsystems,
    get: getSubsystems,
    isLoading: isSubsystemsLoading,
  } = useRequest<Record<'data', SubsystemItem[]>>('/admin/v10/admin/subsystem');

  const subsystemOptions: TOption[] = useMemo(
    () =>
      subsystems?.data.map(
        (s: SubsystemItem) =>
        ({
          value: s.id,
          name: `${s.name} ${s.module ? `(${s.module})` : ''}`,
        } as TOption),
      ) || [],
    [subsystems?.data],
  );

  const subsystemsMap = useMemo(() => {
    const map = new Map<string, string>();
    subsystemOptions.forEach((v) => map.set(v.value, v.name));
    return map;
  }, [subsystemOptions]);

  useEffect(() => {
    getSubsystems();
  }, [getSubsystems]);

  const {
    response,
    filterDTO,
    onPageChange,
    isLoading: isListLoading,
    toggleOrder,
    onSearchText,
    onSubmitFilters,
    refetch,
  } = useList<INotification>('/notification/v10/notifications', {
    initialOrderFieldKey: '-date',
    defaultPageSize,
    preventFirstFetch: !!defaultType,
    disableUxMetadata: true,
  });

  useEffect(() => {
    if (defaultType) {
      onSubmitFilters([
        ...filterDTO.filters.filter((item) => item.key !== 'type'),
        {
          key: 'type',
          operation: 'EQ',
          value: defaultType,
        },
      ]);
    } else {
      onSubmitFilters(filterDTO.filters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { ModalComponent: ConfirmDeleteModal, handleOpenModal } = useHandleDeleteData({
    refetch: () => {
      refetch();
      loadUnreadNotifications();
    },
  });
  const { put } = useRequest<ListResponse<INotification>>('');
  const onRead = useCallback(
    async (id: string) => {
      await put(
        {
          id,
          isRead: true,
        },
        { url: `/notification/v10/notifications/${id}` },
      );

      if (onReadCallback && defaultType) {
        onReadCallback(defaultType);
      }

      refetch();
    },
    [put, refetch, onReadCallback, defaultType],
  );

  const columns = useMemo<Column<INotification>[]>(
    () => [
      {
        title: 'Время уведомления',
        field: 'date',
        render: (row) => createLocalDateDDMMYYYYWithTime(row.date),
        width: 142,
      },
      {
        title: 'Тип уведомления',
        field: 'type',
        width: 142,
      },
      {
        title: 'Подсистема',
        field: 'subsystemId',
        render: (row) => <DefaultTableCellSize>{subsystemsMap.get(row.subsystemId)}</DefaultTableCellSize>,
        width: 132,
      },
      {
        title: 'Содержание',
        field: 'message',
        render: (row) => <TruncatedStringPopoverCell
          value={row.message}
          maxWidth={isDashboard ? 400 : 500}
          isLargeText
          width={10}
          noOfLines={3}
          flex={1}
        />,
      },
      {
        title: 'Прочитано',
        field: 'isRead',
        width: 110,
        render: (row) =>
        (row.isRead ? (
          'Прочитано'
        ) : (
          <Box
            color="#37B466"
            backgroundColor="rgba(55, 180, 102, 0.1)"
            borderRadius="2px"
            padding="4px 8px"
            width="fit-content"
          >
            Новое!
          </Box>
        )),
      },
    ],
    [subsystemsMap, isDashboard],
  );

  const isLoading = useMemo(() => isListLoading || isSubsystemsLoading, [isListLoading, isSubsystemsLoading]);

  const table = (
    <>
      <SubsystemHeader
        title={isDashboard ? ' ' : 'Уведомления'}
        filtersComponent={
          <NotificationFilter
            isLoading={isLoading}
            onSubmit={(values: any) => {
              onSubmitFilters(Object.values(values));
            }}
            filters={filterDTO.filters}
            subsystemOptions={subsystemOptions}
            defaultType={defaultType}
          />
        }
        titleSize={isDashboard ? 'md' : undefined}
      />

      <MaterialTable
        columns={columns}
        data={response?.data}
        meta={response?.meta}
        filterDTO={filterDTO}
        toggleOrder={toggleOrder}
        onPageChange={onPageChange}
        options={{ search: true, selection: false }}
        actions={[
          {
            position: 'row',
            action: (row) => ({
              icon: tableIcons.TickCircled,
              tooltip: 'Отметить прочитанным',
              onClick: () => {
                if (!row?.id) return;
                onRead(row.id);
              },
              hidden: row.isRead,
            }),
          },
          {
            position: 'row',
            action: (row) => ({
              icon: tableIcons.Delete,
              tooltip: 'Удалить',
              onClick: () => {
                if (!row?.id) return;
                handleOpenModal({ url: `/notification/v10/notifications/${row.id}` });
              },
              hidden: row.deleted,
            }),
          },
        ]}
        isLoading={isLoading}
        onSearch={onSearchText}
        showTotalCount
        errorText={response?.data.length === 0 ? 'Нет уведомлений' : undefined}
      />

      {ConfirmDeleteModal}
    </>
  );

  if (!isDashboard) {
    return <FullPageWrapper>{table}</FullPageWrapper>;
  }

  return (
    <Flex flexDirection="column" minHeight="100%">
      {table}
    </Flex>
  );
};

export default Notifications;
