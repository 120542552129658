import {
  addConcentrationPath,
  concentrationListApi,
  consentrationPath,
  IConcentraionItem,
  pdkLocales,
  statuses,
} from '../consts';
import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import { MaterialTable } from 'features/material-table/MaterialTableMini';
import { useHistory } from 'react-router';
import { SubsystemHeader } from 'components/common/SubsystemHeader';
import { useList } from 'hooks';
import { Column } from '@material-table/core';
import { TableFilterTypeEnum } from 'components/table/filters/TableFilter';
import React, { useMemo } from 'react';
import { addFiltersToColumns, ColumnWithFilter } from 'lib/utils/tableFilter';
import { subsystemCodesMap } from 'constants/subsystemIdsMap';
import { useCustomRights } from 'hooks/useCustomRights';
import { EditIcon, EyeViewIcon } from 'components/icons';
import { nsiPdkBreadcrumbs } from '../consts/breadcrumbs';
import { NSIPdkConcentrationFilter } from './NSIPdkConcentrationFilter';
import { Box } from '@chakra-ui/react';
import { FullPageWrapper } from '../../../../components/table/FullPageWrapper';
import { DEFAULT_PAGE_SIZE_NEW_TABLE } from '../../../../constants/tables';
import { DefaultTableCellSize } from '../../../../components/table/DefaultTableCellSize';

export const AllowedConcentration = () => {
  useUpdateBreadcrumbs(nsiPdkBreadcrumbs);
  const history = useHistory();

  const { isWrite } = useCustomRights(subsystemCodesMap.nsi.pdk.pdk);

  const {
    isLoading,
    response,
    toggleOrder,
    onPageChange,
    onSearchText,
    filterDTO,
    setFilters,
    downloadAsFile,
    onSubmitFilters,
    materialTableProps,
  } = useList<IConcentraionItem>(concentrationListApi, {
    saveFiltersState: true,
    defaultPageSize: DEFAULT_PAGE_SIZE_NEW_TABLE,
    resetDataOnNextRequest: true,
  });

  const columns = useMemo<Array<ColumnWithFilter<IConcentraionItem>>>(
    () => [
      {
        title: pdkLocales.pdkStartDate,
        field: 'pdkStartDate',
        render: (row) => <Box whiteSpace="nowrap">{row.pdkStartDate}</Box>,
      },
      {
        title: pdkLocales.pdkEndDate,
        field: 'pdkEndDate',
        render: (row) => <Box whiteSpace="nowrap">{row.pdkEndDate}</Box>,
      },
      {
        title: pdkLocales.substancesColumns.substanceName,
        field: 'name',
        filterName: 'name',
        filterType: TableFilterTypeEnum.string,
      },
      {
        title: pdkLocales.distributionEnvironment,
        field: 'natEnvironment',
        filterName: 'natEnvironment',
        filterType: TableFilterTypeEnum.string,
      },
      {
        title: pdkLocales.substancesColumns.formule,
        field: 'formula',
        filterName: 'formula',
        filterType: TableFilterTypeEnum.string,
      },
      {
        title: pdkLocales.pdkKind,
        field: 'pdkKind',
        filterName: 'pdkKind',
        filterType: TableFilterTypeEnum.string,
        render: (row) => <DefaultTableCellSize>{row.pdkKind}</DefaultTableCellSize>,
      },
      {
        title: pdkLocales.pdkMin,
        field: 'pdkMin',
        filterName: 'pdkMin',
        filterType: TableFilterTypeEnum.number,
      },
      {
        title: pdkLocales.pdkMax,
        field: 'pdkMax',
        filterName: 'pdkMax',
        filterType: TableFilterTypeEnum.number,
      },
      {
        title: pdkLocales.measurmentUnit,
        field: 'pdkUnit',
        filterName: 'pdkUnit',
        filterType: TableFilterTypeEnum.string,
      },
      {
        title: pdkLocales.substancesColumns.status,
        field: 'pdk_status',
        render: (row: any) => statuses.find((status) => status.value === row.pdk_status)?.name,
        filterName: 'pdk_status',
        filterType: TableFilterTypeEnum.select,
      },
    ],
    [],
  );

  const fieldsToSearch = ['name', 'formula', 'pdkMin', 'pdkMax', 'pdkUnit', 'pdk_status', 'natEnvironment', 'pdkKind'];

  const selectOptions = useMemo(() => {
    const map = new Map();
    map.set('pdk_status', statuses);
    return map;
  }, []);

  const columnsWithFilters = useMemo<Array<Column<IConcentraionItem>>>(
    () =>
      addFiltersToColumns({
        filters: filterDTO.filters,
        setFilters,
        columns,
        selectOptions,
      }),
    [filterDTO.filters, selectOptions, setFilters, columns],
  );

  return (
    <FullPageWrapper>
      <SubsystemHeader
        title={pdkLocales.concentration}
        addButtonProps={{
          onClick: () => {
            history.push(addConcentrationPath);
          },
          isDisabled: !isWrite,
        }}
        filtersComponent={
          <NSIPdkConcentrationFilter
            onSubmit={(values) => onSubmitFilters(values)}
            isLoading={isLoading}
            filters={filterDTO.filters}
          />
        }
      />
      <MaterialTable
        data={response?.data || []}
        meta={response?.meta}
        isLoading={isLoading}
        filterDTO={filterDTO}
        columns={columnsWithFilters}
        toggleOrder={toggleOrder}
        onPageChange={onPageChange}
        fieldsToSearch={fieldsToSearch}
        options={{
          search: true,
        }}
        onSearch={onSearchText}
        actions={[
          {
            position: 'row',
            action: (row) => ({
              icon: () => <EyeViewIcon />,
              tooltip: 'Архив',
              onClick: async () => {
                history.push(`${addConcentrationPath}?archive=true`, { id: row.id });
              },
              hidden: !isWrite || row.pdk_status !== 'CURRENT',
            }),
          },
          {
            position: 'row',
            action: (row) => ({
              icon: () => <EditIcon />,
              tooltip: 'Редактирование',
              onClick: async () => {
                history.push(`${consentrationPath}/edit/${row.id}`);
              },
              hidden: !isWrite || row.deleted,
            }),
          },
        ]}
        downloadAsFile={downloadAsFile}
        showTotalCount
        {...materialTableProps}
      />
    </FullPageWrapper>
  );
};
