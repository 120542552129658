import { Checkbox, DateInput, Input, Select } from 'components/common';
import { Field, useFormikContext } from 'formik';
import { Flex, Grid, GridItem } from '@chakra-ui/react';
import React, { useMemo } from 'react';
import { getSelectRichOptionsFromDictionaryByName } from 'features/get-select-options-from-dictionary';
import appLocale from 'constants/appLocale';
import { createLocalDateISO8601 } from 'lib/create-date';
import { IFilterItem, TDictionariesArray } from 'models';
import FilterFormControls from 'components/form/FilterFormControls';

interface IProps {
  dictionaries: { data: TDictionariesArray } | null;
}

export const PElRaidsFilterFormFields = ({ dictionaries }: IProps) => {
  const { values, submitForm, setFieldValue, handleReset, errors } = useFormikContext<{ [p: string]: IFilterItem }>();
  const districts = useMemo(
    () => getSelectRichOptionsFromDictionaryByName(dictionaries?.data, 'districts'),
    [dictionaries],
  );
  const regions = useMemo(
    () => getSelectRichOptionsFromDictionaryByName(dictionaries?.data, 'regions'),
    [dictionaries],
  );

  const taskStatuses = useMemo(
    () =>
      getSelectRichOptionsFromDictionaryByName(dictionaries?.data, 'taskStatuses'),
    [dictionaries],
  );

  return (
    <>
      <Flex>
        <Grid width="100%" maxWidth="1200px" gap={5} templateColumns="repeat(4, 0.25fr)">
          <GridItem>
            <Field as={Select} name="districts.value" label={appLocale.common.area} options={districts} multiple />
          </GridItem>

          <GridItem>
            <Field as={Select} name="regions.value" label={appLocale.common.district} options={regions} multiple />
          </GridItem>

          <GridItem colSpan={2}>
            <Field
              as={Input}
              value={values.territoryName.value || ''}
              name="territoryName.value"
              label="Наименование территории"
            />
          </GridItem>

          <GridItem>
            <Field
              as={DateInput}
              name="dateTimeMin.value"
              label={appLocale.common.from}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setFieldValue('dateTimeMin.value', createLocalDateISO8601(e.target.value))
              }
              error={errors.dateTimeMin?.value}
            />
          </GridItem>

          <GridItem>
            <Field
              as={DateInput}
              name="dateTimeMax.value"
              label={appLocale.common.to}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setFieldValue('dateTimeMax.value', createLocalDateISO8601(e.target.value))
              }
              error={errors.dateTimeMax?.value}
            />
          </GridItem>

          <Field as={Select} name="status.value" label={appLocale.common.status} options={taskStatuses} />
        </Grid>

        <FilterFormControls
          onReset={(e) => {
            handleReset(e);
            setTimeout(submitForm, 0);
          }}
          onSubmit={submitForm}
        />
      </Flex>
      <Field as={Checkbox} name="deleted.value" label="Показать удаленные" checked={values.deleted.value} />
    </>
  );
};
