import { Flex, Menu, MenuButton, MenuItem, MenuList, useDisclosure } from '@chakra-ui/react';
import { Button } from '../Button';
import { ToolbarIconWrap } from 'features/material-table/components/Toolbar/styles';
import { ArrowDownIcon, CrossIcon, DownloadIcon, EyeViewIcon, ImageIcon, PrintIcon } from '../../icons';
import React, { useRef, useState } from 'react';
import html2canvas from 'html2canvas';
import { jsPDF as JsPDF } from 'jspdf';
import { SendToMailModal } from './SendToMailModal';

interface IProps {
  viewOnOpen: () => void;
  isDisabled: boolean;
  filename: string;
}

export const GraphicDropdownMenu = ({ viewOnOpen, isDisabled, filename }: IProps) => {
  const { isOpen: isOpenMenu, onOpen: onOpenMenu, onClose: onCloseMenu } = useDisclosure();
  const mainMenuRef = useRef<any>();
  const changeFileRefDownload = useRef<any>();
  const changeFileRefUpload = useRef<any>();
  const [uploadType, setUploadType] = useState<string | null>(null);
  const { onOpen, onClose, isOpen } = useDisclosure();

  const { isOpen: isOpenDownloadFileType, onOpen: onOpenDownloadFileType, onClose: onCloseDownloadFileType }
    = useDisclosure();

  const {
    isOpen: isOpenUploadFileType,
    onOpen: onOpenUploadFileType,
    onClose: onCloseUploadFileType,
  } = useDisclosure();

  const handleOpenChangeFileType = React.useCallback((download: boolean) => {
    onCloseMenu();
    if (download) {
      onOpenDownloadFileType();
    } else {
      onOpenUploadFileType();
    }
  }, [onCloseMenu, onOpenDownloadFileType, onOpenUploadFileType]);

  const handleCloseChangeFileType = React.useCallback(
    (type: string, download: boolean) => {
      onCloseMenu();
      if (download) {
        onCloseDownloadFileType();
        html2canvas(document.getElementById('LineChart') as HTMLElement).then((canvas) => {
          const img = canvas.toDataURL('image/png', 1);
          if (type === 'pdf') {
            const pdf = new JsPDF({ orientation: 'landscape' });
            const width = pdf.internal.pageSize.getWidth();

            pdf.addImage(img, 'png', 5, 5, width - 10, 140);
            pdf.save(`${filename}.pdf`);
          } else {
            const link = document.createElement('a');
            link.href = img;
            link.download = `${filename}.png`;
            link.click();
          }
        });
      } else {
        setUploadType(type);
        onOpen();
      }
    },
    [filename, onCloseDownloadFileType, onCloseMenu, onOpen]
  );

  const handleCloseCancelChangeFileType = React.useCallback(() => {
    onOpenMenu();
    onCloseDownloadFileType();
  }, [onCloseDownloadFileType, onOpenMenu]);

  return (
    <Flex backgroundColor="panel.background.default" order={1} px={5} py={2.5}>
      <Flex marginLeft={4} flex={1} justifyContent="flex-end">
        <>
          <Button
            isDisabled={isDisabled}
            ref={mainMenuRef}
            onClick={onOpenMenu}
            variant="ghost"
            color="text.primary"
            rightIcon={(
              <ToolbarIconWrap>
                <ArrowDownIcon />
              </ToolbarIconWrap>
            )}
          >
            Действия
          </Button>
          <Menu id="menuList" boundary="scrollParent" isOpen={isOpenMenu} onClose={onCloseMenu}>
            <MenuButton ref={mainMenuRef} onClick={(e) => e.stopPropagation()} />
            <MenuList zIndex={11}>
              <MenuItem onClick={() => handleOpenChangeFileType(true)} icon={<DownloadIcon />}>
                Выгрузить
              </MenuItem>
              <MenuItem onClick={() => handleOpenChangeFileType(false)} icon={<DownloadIcon />}>
                Отправить на почту
              </MenuItem>
              <MenuItem onClick={viewOnOpen} icon={<EyeViewIcon />}>
                Настроить вид
              </MenuItem>
              <MenuItem onClick={onCloseMenu} icon={<CrossIcon />}>
                Отмена
              </MenuItem>
            </MenuList>
          </Menu>

          <Menu isOpen={isOpenDownloadFileType} onClose={onCloseDownloadFileType}>
            <MenuButton ref={changeFileRefDownload} onClick={(e) => e.stopPropagation()} />
            <MenuList zIndex={11}>
              <MenuItem onClick={() => handleCloseChangeFileType('png', true)} icon={<ImageIcon />}>
                png
              </MenuItem>

              <MenuItem
                onClick={() => handleCloseChangeFileType('pdf', true)}
                icon={<PrintIcon />}
              >
                PDF
              </MenuItem>

              <MenuItem onClick={handleCloseCancelChangeFileType} icon={<CrossIcon />}>
                Отмена
              </MenuItem>
            </MenuList>
          </Menu>

          <Menu isOpen={isOpenUploadFileType} onClose={onCloseUploadFileType}>
            <MenuButton ref={changeFileRefUpload} onClick={(e) => e.stopPropagation()} />
            <MenuList zIndex={11}>
              <MenuItem onClick={() => handleCloseChangeFileType('png', false)} icon={<ImageIcon />}>
                png
              </MenuItem>

              <MenuItem
                onClick={() => handleCloseChangeFileType('pdf', false)}
                icon={<PrintIcon />}
              >
                PDF
              </MenuItem>

              <MenuItem onClick={handleCloseCancelChangeFileType} icon={<CrossIcon />}>
                Отмена
              </MenuItem>
            </MenuList>
          </Menu>
        </>
      </Flex>
      <SendToMailModal
        onClose={onClose}
        uploadType={uploadType}
        setUploadType={setUploadType}
        filename={filename}
        isOpen={isOpen}
      />
    </Flex>
  );
};
