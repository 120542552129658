import ApiTokenForm from 'pages/admin/ApiToken/Forms/ApiTokenForm';
import { FormHeader } from 'components/common';
import { useHistory } from 'react-router-dom';
import { FormikProvider, useFormik } from 'formik';
import { useEnhancedNotification, useRequest } from 'hooks';
import { createTouchedErrors } from 'features/create-touched-errors';
import { adminApiTokenSchema } from 'models/schemas/admin/apiToken';

const initialEmptyValues = {
  description: '',
  expires: '',
  active: true,
  type: '',
};

const ApiTokenAdd = () => {
  const { push } = useHistory();
  const { onErrorCreate, onSuccessCreate } = useEnhancedNotification();
  const { post, isLoading } = useRequest('/admin/v10/admin/api-token', () => {
    onSuccessCreate();
    push('/admin/apiToken');
  }, onErrorCreate);

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    initialValues: initialEmptyValues,
    onSubmit: (values) => post(values),
    validationSchema: adminApiTokenSchema,
  });

  return (
    <FormikProvider
      value={{
        ...formik,
        errors: createTouchedErrors(formik.errors, formik.touched),
      }}
    >
      <FormHeader url="/admin/apiToken" header="Добавление записи" />
      <ApiTokenForm onCancel={() => push('/admin/apiToken')} isLoading={isLoading} />
    </FormikProvider>
  );
};

export default ApiTokenAdd;
