import { TOption } from './toption';

export enum RightAction {
  READ = 'READ',
  WRITE = 'WRITE',
  EXECUTE = 'EXECUTE',
}

export const objectActions: TOption[] = [
  {
    value: RightAction.READ,
    name: 'Чтение',
  },
  {
    value: RightAction.WRITE,
    name: 'Запись',
  },
  {
    value: RightAction.EXECUTE,
    name: 'Выполнение',
  },
];
