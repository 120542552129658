import { useRequest } from 'hooks/useRequest';
import { useUtilDictionaries } from 'hooks/useUtilDictionaries';
import { TDictionariesArray } from 'models';
import { useEffect, useMemo } from 'react';
import { GreenPlantsApiRootPath } from 'pages/routes/GreenPlants';
import { cloneDeep, get, set } from 'lodash';
import { parameterToParameterIdMap } from '../../../../features/parametersMap';

type TDictionaries = 'areaParameters' | 'plantParameters' | 'areas' | 'objectType' | 'rayons' | 'okrugs' | 'areaType';

type TFloraAreasParametersDictionariesItemNames =
  | 'lawnType'
  | 'beautification'
  | 'hasFlowerGarden'
  | 'surroundingBuilding'
  | 'lawnStateType'
  | 'lawnState'
  | 'flowerGardenState'
  | 'treeCount'
  | 'shrubCount'
  | 'emergencyElementCount'
  | 'Comment';

type TFloraPlantsParametersDictionariesItemNames =
  | 'diseases'
  | 'plantAge'
  | 'pests'
  | 'decorativeness'
  | 'stateCategory'
  | 'detailedStateCategory'
  | 'plantGroupLocation'
  | 'foliagePercentage'
  | 'otherDamage'
  | 'recommendations'
  | 'plantGroupType'
  | 'entomophages'
  | 'other'
  | 'emergency'
  | 'treeTrunkTiltAngle'
  | 'averageDiameter'
  | 'stemHeight'
  | 'plantHeight'
  | 'plantGroupCount'
  | 'isFirstPlanted'
  | 'diameter';

type TFLoraDictionariesNames = TFloraAreasParametersDictionariesItemNames | TFloraPlantsParametersDictionariesItemNames;

type TFloraDictionariesArray = TDictionariesArray<TDictionaries, TFLoraDictionariesNames>;

type TFLoraDictionaries = Record<TDictionaries, TFloraDictionariesArray[number]['dictionary']>;

const nestedDictsKeys = ['areaParameters', 'plantParameters'];

const useFloraDictionaries = () => {
  const { get: getDictionaries, isLoading, result, error } = useRequest<{
    data: TFloraDictionariesArray;
  }>(`${GreenPlantsApiRootPath}/flora/dictionaries`);

  const {
    getItemFromDictionaryByFieldValue,
    getItemsFromDictionaryByFieldValue,
    getItemsFromDictionaryByParent,
    ...dictionaries
  } = useUtilDictionaries(result?.data ?? []);

  const formattedDictionaries = useMemo(() => {
    const dicts = cloneDeep(dictionaries);
    Object.keys(dicts).forEach((dictionaryKey) => {
      if (nestedDictsKeys.includes(dictionaryKey)) {
        const dict = get(dicts, dictionaryKey).map((dict: any) => (
          {
            ...dict,
            name: parameterToParameterIdMap[dict.parameterId],
            dictionary: Object.values(dict.values),
          }
        ));
        set(dicts, dictionaryKey, dict);
      }
    });

    return dicts;
  }, [dictionaries]);

  useEffect(() => {
    void getDictionaries();
  }, [getDictionaries]);

  return {
    getItemFromDictionaryByFieldValue,
    getItemsFromDictionaryByFieldValue,
    getItemsFromDictionaryByParent,
    isLoading,
    dictionaries: formattedDictionaries,
    result,
    error,
  };
};

export { useFloraDictionaries };

export type {
  TFLoraDictionariesNames,
  TFloraDictionariesArray,
  TFloraPlantsParametersDictionariesItemNames,
  TFloraAreasParametersDictionariesItemNames,
  TFLoraDictionaries,
};
