import React, { memo, useCallback, useEffect } from 'react';
import { Field, useFormikContext } from 'formik';
import { concat, get } from 'lodash';
import { Flex, Stack } from '@chakra-ui/react';
import { Checkbox, Select, Textarea } from 'components/common';
import { dictParametersToMap } from 'features/parametersMap';
import type { TFLoraDictionaries } from 'pages/data/GreenPlants/Flora/useFloraDictionaries';

import type { IFloraTabPanelProps } from './types';
import { TGreenPlantsForm } from 'pages/data/GreenPlants/Form/types';
import { getDictionariesIds } from 'pages/data/GreenPlants/utils';
import {
  getSelectOptionsFromDictionaryByName
} from '../../../../../../../../features/get-select-options-from-dictionary';

interface TFloraDamageFormProps {
  entity: IFloraTabPanelProps['entity'];
  isLoading: IFloraTabPanelProps['isLoading'];
  areaParameters: TFLoraDictionaries['areaParameters'];
  plantParameters: TFLoraDictionaries['plantParameters'];
}

const FloraDamageForm: React.FC<TFloraDamageFormProps> = memo(
  ({ entity, isLoading, areaParameters = [], plantParameters = [] }) => {
    const { values, setFieldValue } = useFormikContext<TGreenPlantsForm>();

    const parameters = dictParametersToMap(concat(areaParameters, plantParameters));
    const parameterId = parameters?.emergency?.parameterId;
    const evalue = values?.flora?.parameters?.emergency?.value;
    useEffect(() => {
      /**
       * отдельный хук для для нормализации значения в чекбоксе
       */
        // если нет значения
      const shouldSetDefaultValueInCheckbox = evalue == null && parameterId;
      if (shouldSetDefaultValueInCheckbox) {
        setFieldValue(`${entity}.parameters.emergency.value`, false);
        setFieldValue(`${entity}.parameters.emergency.parameterId`, parameterId);
      }
      // если есть значение
      const stringValue = String(evalue);
      if (stringValue === 'Да') {
        setFieldValue(`${entity}.parameters.emergency.value`, true);
      }
      if (stringValue === 'Нет') {
        setFieldValue(`${entity}.parameters.emergency.value`, false);
      }
    }, [entity, parameterId, setFieldValue, evalue]);

    const getOptionsByName = useCallback((name: string) => getSelectOptionsFromDictionaryByName(
      [...areaParameters, ...plantParameters],
      name,
      false,
      get(values, `${entity}.parameters.${name}.ids`)
    ), [areaParameters, plantParameters, values, entity]);

    return (
      <Stack>
        <Field
          isDisabled={isLoading}
          as={Select}
          options={getOptionsByName('diseases')}
          name={`${entity}.parameters.diseases.dictionaryItemId`}
          label={parameters.diseases?.title}
          multiple // @default true / java coders does not saving list of value
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
            if (Array.isArray(e.target.value)) {
              setFieldValue(`${entity}.parameters.diseases.dictionaryItemId`,
                getDictionariesIds(e.target.value, parameters.diseases.values));
              setFieldValue(`${entity}.parameters.diseases.value`, e.target.value);
              setFieldValue(`${entity}.parameters.diseases.parameterId`, parameters.diseases?.parameterId);
            }
          }}
        />
        <Field
          isDisabled={isLoading}
          as={Select}
          options={getOptionsByName('pests')}
          name={`${entity}.parameters.pests.dictionaryItemId`}
          label={parameters.pests?.title}
          multiple // @default true / java coders does not saving list of value
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
            if (Array.isArray(e.target.value)) {
              setFieldValue(`${entity}.parameters.pests.dictionaryItemId`,
                getDictionariesIds(e.target.value, parameters.pests.values));
              setFieldValue(`${entity}.parameters.pests.value`, e.target.value);
              setFieldValue(`${entity}.parameters.pests.parameterId`, parameters.pests?.parameterId);
            }
          }}
        />
        <Field
          isDisabled={isLoading}
          as={Select}
          name={`${entity}.parameters.entomophages.dictionaryItemId`}
          label={parameters.entomophages?.title}
          options={getOptionsByName('entomophages')}
          multiple // @default true / java coders does not saving list of value
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
            if (Array.isArray(e.target.value)) {
              setFieldValue(`${entity}.parameters.entomophages.dictionaryItemId`,
                getDictionariesIds(e.target.value, parameters.entomophages.values));
              setFieldValue(`${entity}.parameters.entomophages.value`, e.target.value);
              setFieldValue(`${entity}.parameters.entomophages.parameterId`, parameters.entomophages?.parameterId);
            }
          }}
        />
        <Field
          isDisabled={isLoading}
          as={Select}
          name={`${entity}.parameters.recommendations.dictionaryItemId`}
          label={parameters.recommendations?.title}
          options={getOptionsByName('recommendations')}
          multiple // @default true / java coders does not saving list of value
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
            if (Array.isArray(e.target.value)) {
              setFieldValue(`${entity}.parameters.recommendations.dictionaryItemId`,
                getDictionariesIds(e.target.value, parameters.recommendations.values));
              setFieldValue(`${entity}.parameters.recommendations.value`, e.target.value);
              setFieldValue(
                `${entity}.parameters.recommendations.parameterId`,
                parameters.recommendations?.parameterId,
              );
            }
          }}
        />
        <Flex>
          <Field
            isDisabled={isLoading}
            as={Checkbox}
            name={`${entity}.parameters.emergency.value[0]`}
            checked={Array.isArray(values.flora.parameters?.emergency?.value)
              ? values.flora.parameters?.emergency?.value[0] === 'true'
              : values.flora.parameters?.emergency?.value === 'true'}
            label={parameters.emergency?.title}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              if (e?.target) {
                setFieldValue(`${entity}.parameters.emergency.value`, [e.target.checked ? 'true' : 'false']);
                setFieldValue(`${entity}.parameters.emergency.parameterId`, parameters.emergency?.parameterId);
              }
            }}
          />
        </Flex>
        <Field
          isDisabled={isLoading}
          as={Select}
          name={`${entity}.parameters.otherDamage.dictionaryItemId`}
          label={parameters.otherDamage?.title}
          options={getOptionsByName('otherDamage')}
          multiple // @default true / java coders does not saving list of value
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
            if (Array.isArray(e.target.value)) {
              setFieldValue(`${entity}.parameters.otherDamage.dictionaryItemId`,
                getDictionariesIds(e.target.value, parameters.otherDamage.values));
              setFieldValue(`${entity}.parameters.otherDamage.value`, e.target.value);
              setFieldValue(`${entity}.parameters.otherDamage.parameterId`, parameters.otherDamage?.parameterId);
            }
          }}
        />
        <Field
          isDisabled={isLoading}
          as={Textarea}
          name={`${entity}.parameters.other.value[0]`}
          label={parameters.other?.title}
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
            if (e?.target) {
              setFieldValue(`${entity}.parameters.other.value`, [e.target.value]);
              setFieldValue(`${entity}.parameters.other.parameterId`, parameters.other?.parameterId);
            }
          }}
        />
      </Stack>
    );
  },
);

export { FloraDamageForm };
