import { ReportSmall } from 'features/WithSideBar/icons';
import subsystemIdsMap from 'constants/subsystemIdsMap';

export const managerMenu = {
  name: 'Экологическая ситуация',
  url: '/manager',
  id: '/manager',
  icon: <ReportSmall />,
  subsystemId: subsystemIdsMap.manager,
};
