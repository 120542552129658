import { useMemo } from 'react';
import { ERROR_MESSAGES, SUCCESS_MESSAGES } from 'constants/messages';
import { createTouchedErrors } from 'features/create-touched-errors';
import { FormikProvider, useFormik } from 'formik';
import { useNotifications } from 'hooks/useNotifications';
import { useRequest } from 'hooks/useRequest';
import { IParameterItem, ParameterRootPath, updateParamApiPath, useParamItem, useParamsDictionaries } from '../consts';
import { ParamsSchema } from 'models';
import { ParamsFields } from './ParamsFields';
import { useParams } from 'react-router-dom';
import { LoaderItem } from 'components/common';
import { getSelectOptionsFromDictionaryByName } from 'features/get-select-options-from-dictionary';
import { useGoBackWithFallback } from 'lib/utils/goBackWithFallback';

const initialEmptyValues = {
  id: '',
  name: '',
  fullName: '',
  code: '',
  dictionary: '',
  purpose: '',
  status: 'ACTIVE',
  fieldType: 'NUMERIC',
  relations: [
    {
      subsystemId: '',
      moduleId: '',
      unitId: '',
      unitDescription: '',
      accuracy: '',
      aliases: [''],
      inFile: true,
      inListing: true,
      fieldType: '',
      type: '',
    },
  ],
};

export const EditParameter = () => {
  const { result: dictionaries, isLoading: isDictLoading } = useParamsDictionaries();

  const { backWithFallback } = useGoBackWithFallback();

  const { id } = useParams<{
    id: string;
  }>();

  const { result, isLoading } = useParamItem(id);

  const { successNotify, errorNotify } = useNotifications();

  const onSuccess = () => {
    successNotify({
      key: 'file/edit/success',
      message: SUCCESS_MESSAGES.EDIT,
    });
    backWithFallback(ParameterRootPath);
  };

  const onError = (error: IParameterItem) => {
    const message = error.errors.map((error) => error.error).join('.') || '';
    errorNotify({
      key: 'file/edit/error',
      message: `${ERROR_MESSAGES.EDIT}.\n ${message}`,
    });
  };

  const { put } = useRequest<IParameterItem, any>(updateParamApiPath, onSuccess, onError);
  const subsystems = useMemo(
    () => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'subsystems'),
    [dictionaries],
  );
  const modules = useMemo(() => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'modules'), [dictionaries]);

  const initialValues: any = useMemo(() => {
    const editedObject: any = { ...initialEmptyValues, ...result };
    editedObject.relations.forEach((relation: any) => {
      if (!subsystems.find((item) => item.value === relation.subsystemId) && relation.moduleId) {
        relation.subsystemId = modules.find((module) => module.value === relation.moduleId)?.parent || '';
      }
    });

    return editedObject;
  }, [modules, result, subsystems]);

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnMount: false,
    validateOnBlur: false,
    validationSchema: ParamsSchema,
    initialValues,
    onSubmit: (values) => {
      put(values);
    },
  });

  if (isLoading) {
    return <LoaderItem />;
  }

  return (
    <FormikProvider
      value={{
        ...formik,
        errors: createTouchedErrors(formik.errors, formik.touched),
      }}
    >
      <ParamsFields
        isEditMode
        dictionaries={dictionaries?.data}
        isLoading={isDictLoading}
        editedObject={initialValues}
      />
    </FormikProvider>
  );
};
