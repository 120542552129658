import { Field, FormikProvider, useFormik } from 'formik';
import { Checkbox, DateInput, IFilterValues, Select, useSetFiltersFromGraphic } from 'components/common';
import { Flex, Grid } from '@chakra-ui/react';
import { createDateFromResponse, createDateToRequestWithTimezone } from 'lib/create-date';
import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { IPeriod } from 'features/PeriodsButtons/types';
import { PeriodsButtons } from 'features/PeriodsButtons';
import { usePeriods } from 'features/PeriodsButtons/usePeriods';
import combineFilters from 'lib/utils/combineFilters';
import { IFilterItem, TFiltersArray } from 'models';
import { MomentInput } from 'moment';
import { FiltersDatepickersSchema } from '../../../../models/schemas/filtersDatepickersSchema/FiltersDatepickersSchema';
import FilterFormControls from 'components/form/FilterFormControls';

interface FilterProps {
  isLoading?: boolean;
  isDisabled?: boolean;
  onSubmit: (values: any) => void | any;
  stations: Array<any>;
  parameters: Array<any>;
  filtersFromGraphic: Partial<IFilterValues> | null;
  setFiltersFromGraphic: (value: Partial<IFilterValues> | null) => void;
  periods: IPeriod[] | null;
  filters?: TFiltersArray;
  setGraphInitialFilters?: (filters: any) => void;
}

const initialEmptyValues: Record<string, IFilterItem<string>> = {
  dateTimeMin: {
    key: 'datetime',
    operation: 'GTE',
    value: '',
  },
  dateTimeMax: {
    key: 'datetime',
    operation: 'LTE',
    value: '',
  },
  paramId: {
    key: 'parameterId',
    operation: 'EQ',
    value: null,
  },
  stationId: {
    key: 'sourceId',
    operation: 'EQ',
    value: null,
  },
  // showDisabled - value равно '' если чекбокс включен и нужно получить с Backend все значения (этот объект удалиться при отправке)
  // value равно false - если нужно показать только неудаленные записи
  showDisabled: {
    key: 'deleted',
    operation: 'EQ',
    value: false,
  },
  periodId: {
    key: 'periodId',
    operation: 'EQ',
    value: null,
  },
  initialData: {
    key: 'initialData',
    operation: 'EQ',
    value: false,
  },
};

export const AtmosphereFilter: FC<FilterProps> = ({
  isLoading,
  isDisabled,
  onSubmit,
  stations,
  setFiltersFromGraphic,
  filtersFromGraphic,
  parameters,
  children,
  periods,
  filters = [],
  setGraphInitialFilters,
  ...props
}) => {
  const shouldSetGraphInitialFilters = useRef(false);
  const savedPeriodId = useMemo(
    () => (filters.find((item) => item.key === 'periodId')?.value as string) || '',
    [filters]
  );

  const [periodId, setPeriodId] = useState<string>(savedPeriodId);

  const { getDatesFromPeriod, dates } = usePeriods(periodId, periods);

  const submitPeriod = (id: string) => {
    const dates = getDatesFromPeriod(id);
    setFieldValue('periodId.value', id);
    setFieldValue('dateTimeMin.value', createDateToRequestWithTimezone(dates[0]));
    setFieldValue('dateTimeMax.value', createDateToRequestWithTimezone(dates[1]));
    setFieldTouched('dateTimeMin.value');
    setPeriodId(id);
  };

  const { values, errors, handleReset, submitForm, setFieldValue, setFieldTouched, setValues, ...formik } = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    validationSchema: FiltersDatepickersSchema([{ dateFromFieldName: 'dateTimeMin', dateToFieldName: 'dateTimeMax' }]),
    initialValues: initialEmptyValues,
    onSubmit: (values) => {
      const filters = onSubmit(values);
      if (filters && shouldSetGraphInitialFilters.current && setGraphInitialFilters) {
        shouldSetGraphInitialFilters.current = false;
        setGraphInitialFilters(filters);
      }
    },
  });

  useEffect(() => {
    setValues(combineFilters(initialEmptyValues, filters));
  }, [filters, setValues]);

  useSetFiltersFromGraphic({
    dateKeyMin: 'dateTimeMin.value',
    dateKeyMax: 'dateTimeMax.value',
    setFiltersFromGraphic,
    filtersFromGraphic,
    setFieldValue,
    onChange: submitForm,
  });

  return (
    <FormikProvider
      value={{
        values,
        errors,
        handleReset,
        submitForm,
        setFieldValue,
        setValues,
        setFieldTouched,
        ...formik,
      }}
    >
      <Flex flexDirection="row" justifyContent="space-between">
        <Grid gap="12px" rowGap="8px" gridTemplateColumns="repeat(2, 230px) 225px 225px" {...props}>
          <Field
            as={DateInput}
            name="dateTimeMin.value"
            label="От"
            value={createDateFromResponse(values.dateTimeMin.value as MomentInput)}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFieldValue('dateTimeMin.value', createDateToRequestWithTimezone(e.target.value));
            }}
            showTimeInput
            error={errors.dateTimeMin?.value}
            maxDate={dates?.[1]}
            minDate={dates?.[0]}
            isDisabled={!periodId}
          />
          <Field
            as={DateInput}
            name="dateTimeMax.value"
            label="До"
            value={createDateFromResponse(values.dateTimeMax.value as MomentInput)}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFieldValue('dateTimeMax.value', createDateToRequestWithTimezone(e.target.value));
            }}
            showTimeInput
            error={errors.dateTimeMax?.value}
            maxDate={dates?.[1]}
            minDate={dates?.[0]}
            isDisabled={!periodId}
          />
          <Field
            as={Select}
            name="stationId.value"
            label="Станция"
            options={stations}
            value={values.stationId.value}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFieldValue('stationId.value', e.target.value);
            }}
            error={errors.stationId?.value}
          />
          <Field
            as={Select}
            name="paramId.value"
            label="Параметр"
            options={parameters}
            value={values.paramId.value}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFieldValue('paramId.value', e.target.value);
            }}
            error={errors.paramId?.value}
          />

          <Field
            as={Checkbox}
            name="initialData.value"
            label="Исходные данные"
            checked={values.initialData?.value}
            height={45}
            padding={0}
          />

          {children}
        </Grid>

        <FilterFormControls
          onReset={(e) => {
            handleReset(e);
            setPeriodId('');
            setTimeout(submitForm, 0);
          }}
          onSubmit={() => {
            shouldSetGraphInitialFilters.current = true;
            submitForm();
          }}
          isDisabled={isDisabled}
          isLoading={isLoading}
        />
      </Flex>

      <PeriodsButtons periodId={periodId} periods={periods} submitPeriod={submitPeriod} />
    </FormikProvider>
  );
};
