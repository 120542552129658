import React, { useRef } from 'react';
import { IWithFileDownload, useDisclosure } from 'hooks';
import { Button } from 'components/common';
import { ToolbarIconWrap } from 'features/material-table/components/Toolbar/styles';
import { ArrowDownIcon, CrossIcon, CsvIcon, DownloadIcon, ExcelIcon, PrintIcon } from 'components/icons';
import { Flex, Icon, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import { Divider } from '@material-ui/core';
import { ExtensionToMimeTypeEnum } from 'lib/utils/files/getFileExtensionByMimeType';

interface IProps {
  downloadAsFile?: IWithFileDownload;
  isExecute: boolean;
}

export const TableMenu = ({ downloadAsFile, isExecute }: IProps) => {
  const { onClose: onMenuClose, onOpen: onMenuOpen, isOpen: isMenuOpen } = useDisclosure();
  const {
    isOpen: isOpenDownloadFileType,
    onOpen: onOpenDownloadFileType,
    onClose: onCloseDownloadFileType,
  } = useDisclosure();

  const mainMenuRef = useRef<any>();
  const changeFileRef = useRef<any>();

  const handleOpenChangeFileType = React.useCallback(() => {
    onMenuClose();
    onOpenDownloadFileType();
  }, [onMenuClose, onOpenDownloadFileType]);

  const handleCloseChangeFileType = React.useCallback(
    (type: ExtensionToMimeTypeEnum) => {
      onMenuClose();
      onCloseDownloadFileType();
      if (downloadAsFile) {
        downloadAsFile.downloadAsFile(type);
      }
    },
    [downloadAsFile, onCloseDownloadFileType, onMenuClose]
  );

  const handleCloseCancelChangeFileType = React.useCallback(() => {
    onMenuOpen();
    onCloseDownloadFileType();
  }, [onCloseDownloadFileType, onMenuOpen]);

  return (
    <Flex
      backgroundColor="#fafafa"
      borderTopRadius="4px"
      borderBottom="none"
    >
      <Flex marginLeft="auto" flex={1} justifyContent="flex-end">
        <Button
          ref={mainMenuRef}
          onClick={onMenuOpen}
          variant="ghost"
          color="text.primary"
          rightIcon={
            <ToolbarIconWrap>
              <ArrowDownIcon />
            </ToolbarIconWrap>
          }
          isDisabled={!isExecute}
        >
          Действия
        </Button>
        <Menu id="menuList" boundary="scrollParent" isOpen={isMenuOpen} onClose={onMenuClose}>
          <MenuButton ref={mainMenuRef} onClick={(e) => e.stopPropagation()} />
          <MenuList zIndex={11}>
            <MenuItem icon={<PrintIcon />} onClick={() => window.print()}>
              Распечатать страницу
            </MenuItem>
            <MenuItem ref={changeFileRef} icon={<DownloadIcon />} onClick={handleOpenChangeFileType}>
              Выгрузить
            </MenuItem>
            <Divider />
            <MenuItem onClick={onMenuClose} icon={<CrossIcon />}>
              Отмена
            </MenuItem>
          </MenuList>
        </Menu>
        <Menu isOpen={isOpenDownloadFileType} onClose={onCloseDownloadFileType}>
          <MenuButton ref={changeFileRef} onClick={(e) => e.stopPropagation()} />
          <MenuList zIndex={11}>
            <MenuItem onClick={() => handleCloseChangeFileType(ExtensionToMimeTypeEnum.xlsx)} icon={<ExcelIcon />}>
              Microsoft Excel (xlsx)
            </MenuItem>

            <MenuItem onClick={() => handleCloseChangeFileType(ExtensionToMimeTypeEnum.csv)} icon={<CsvIcon />}>
              Текстовый (csv)
            </MenuItem>

            <MenuItem
              onClick={() => handleCloseChangeFileType(ExtensionToMimeTypeEnum.pdf)}
              icon={<Icon as={PrintIcon} />}
            >
              PDF
            </MenuItem>

            <MenuItem onClick={handleCloseCancelChangeFileType} icon={<CrossIcon />}>
              Отмена
            </MenuItem>
          </MenuList>
        </Menu>
      </Flex>
    </Flex>
  );
};
