import { getInitialFormTabInitialEmptyValues } from 'components/common/LocationFormTab/locationFormTabInitialEmptyValues';
import { NSIWaterItem } from 'pages/dictionaries/NSIWater/types';

const initialNSIWaterGatesEmptyValues: Partial<NSIWaterItem> = {
  subsystemId: null,
  areaId: null,
  waterId: null,
  name: '',
  shortName: '',
  number: '',
  stvorTypeId: null,
  orderNumber: '',
  status: null,
  startDate: null,
  stopDate: null,

  ...getInitialFormTabInitialEmptyValues(),
};

export default initialNSIWaterGatesEmptyValues;
