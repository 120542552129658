import { useRequest } from 'hooks/useRequest';
import { useEffect } from 'react';
import { TDictionaryItemDictionary } from 'models';

export function useAdminDictionaries() {
  const { get, isLoading, result, error } = useRequest<{
    data: TDictionaryItemDictionary[];
  }>('/admin/v10/admin/user');

  useEffect(() => {
    get();
  }, [get]);

  return { isLoading, result, error };
}
