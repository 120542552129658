import { DefaultText, FieldsFormControls } from 'components/common';
import { Flex, Grid, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { SourceTab } from './Tabs/SourceTab';
import { MeasurementsTab } from './Tabs/MeasurementsTab';
import { TechTab } from './Tabs/TechTab';
import { useFormikContext } from 'formik';
import { IFormIASKZVPackage } from '../types';
import { useHistory } from 'react-router';
import { useCallback, useState } from 'react';
import { DictionariesItem } from 'models';
import { WaterRouteAskzvRoot } from 'pages/data/Water/routes';
import { useGoBackWithFallback } from 'lib/utils/goBackWithFallback';

interface IProps {
  isLoading: boolean;
  isEdit?: boolean;
  sources: DictionariesItem;
  fire: DictionariesItem;
  door: DictionariesItem;
  event: DictionariesItem;
  isAddFromFile?: boolean;
  previewPath?: string;
}

const tabsNames = ['Источник измерения', 'Измеряемые параметры', 'Технологические параметры'];

export const ASKZVFormFields = ({
  previewPath,
  isLoading,
  isAddFromFile,
  isEdit,
  sources,
  fire,
  event,
  door,
}: IProps) => {
  const { backWithFallback } = useGoBackWithFallback();
  const { submitForm, isValid } = useFormikContext<IFormIASKZVPackage>();
  const { push } = useHistory();
  const [tabIndex, setTabIndex] = useState(0);

  const handleCancel = useCallback(() => {
    if (isAddFromFile) {
      push(previewPath);
      return;
    }

    backWithFallback(WaterRouteAskzvRoot);
  }, [previewPath, backWithFallback, isAddFromFile, push]);

  const onNextTab = () => setTabIndex((prev) => prev + 1);

  return (
    <Grid padding="0px 110px" gap={8}>
      <Tabs index={tabIndex} onChange={setTabIndex} isLazy mt={4} variant="enclosed">
        <TabList mb="1em">
          {tabsNames.map((tab, i) => (
            <Tab key={i}>
              <DefaultText cursor="pointer">{tab}</DefaultText>
            </Tab>
          ))}
        </TabList>

        <TabPanels maxWidth="600px">
          <TabPanel>
            <SourceTab isEdit={isEdit} sources={sources} isLoading={isLoading} />
          </TabPanel>
          <TabPanel>
            <MeasurementsTab />
          </TabPanel>
          <TabPanel>
            <TechTab isLoading={isLoading} door={door} event={event} fire={fire} />
          </TabPanel>
        </TabPanels>

        <Flex ml={4} mt={2}>
          <FieldsFormControls
            onCancel={handleCancel}
            handleSubmit={submitForm}
            isSaveDisabled={!isValid || isLoading}
            onNextTab={tabIndex < 2 ? onNextTab : undefined}
            showSave={tabIndex === 2}
          />
        </Flex>
      </Tabs>
    </Grid>
  );
};
