import { Button, FormWrapper, Input, LoaderItem } from 'components/common';
import { Box, Flex, Grid } from '@chakra-ui/react';
import { Field, useFormikContext } from 'formik';
import React from 'react';
import { PeriodItem } from '../../models/PeriodItem';

export interface FieldFormProps {
  onCancel: () => void;
  isLoading: boolean;
}

const FieldsForm = ({ onCancel, isLoading }: FieldFormProps) => {
  const { handleSubmit, errors } = useFormikContext<PeriodItem>();

  if (isLoading) {
    return <LoaderItem />;
  }
  return (
    <Box flexDirection="column">
      <FormWrapper>
        <Grid marginTop="30px" gap="15px">
          <Field as={Input} name="code" label="Код" error={errors.code} />
          <Field as={Input} name="name" label="Наименование" error={errors.name} />
          <Field as={Input} name="script" label="Значение" error={errors.script} />
          <Flex>
            <Button onClick={onCancel} marginRight={20}>
              Отмена
            </Button>
            <Button onClick={() => handleSubmit()} variant="solid">
              Сохранить
            </Button>
          </Flex>
        </Grid>
      </FormWrapper>
    </Box>
  );
};

export default React.memo(FieldsForm);
