import { useMemo } from 'react';
import { ERROR_MESSAGES, SUCCESS_MESSAGES } from 'constants/messages';
import { createTouchedErrors } from 'features/create-touched-errors';
import { FormikProvider, useFormik } from 'formik';
import { useNotifications } from 'hooks/useNotifications';
import { useRequest } from 'hooks/useRequest';
import { SubstanceFields } from './SubstanceFields';
import { SubstanceSchema } from 'models/schemas';
import { useLocation } from 'react-router';
import { ISubstanceValues, substanceApiPath, substancesPath } from '../../consts';
import { getSelectOptionsFromDictionaryByName } from 'features/get-select-options-from-dictionary';
import { LoaderItem } from 'components/common';
import { useGoBackWithFallback } from 'lib/utils/goBackWithFallback';
import { useDictionary } from 'hooks/useDictionary';

export const initialEmptyValues = {
  chemName: '',
  chemFormula: '',
  chemShortmane: '',
  dangerClass: '',
  notChemical: false,
  id: '',
  paramId: [],
};

export const EditSubstance = () => {
  const { backWithFallback } = useGoBackWithFallback();
  const location = useLocation();
  const editedObject = location.state;

  const { dictionaries, isDictionariesLoading } = useDictionary({ url: '/nsi/v10/substance/dictionaries' });
  const dangerClasses = getSelectOptionsFromDictionaryByName(dictionaries?.data, 'dangerClasses');

  const { successNotify, errorNotify } = useNotifications();

  const onSuccess = () => {
    successNotify({
      key: 'file/edit/success',
      message: SUCCESS_MESSAGES.EDIT,
    });
    backWithFallback(substancesPath);
  };

  const onError = () => {
    errorNotify({
      key: 'file/edit/error',
      message: ERROR_MESSAGES.EDIT,
    });
  };

  const { put } = useRequest<ISubstanceValues, any>(
    `${substanceApiPath}/${editedObject?.id}/dto`, onSuccess, onError
  );

  const initialValues = useMemo(() => {
    const values = { ...initialEmptyValues, ...editedObject, paramValue: editedObject?.chemDesc };
    values.dangerClass = dangerClasses.find((item) => item.name === values.dangerClass)?.value;
    return values;
  }, [dangerClasses, editedObject]);

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    validationSchema: SubstanceSchema,
    initialValues,
    onSubmit: (values) => {
      put(values);
    },
  });

  if (isDictionariesLoading) {
    return (
      <LoaderItem />
    );
  }

  return (
    <FormikProvider
      value={{
        ...formik,
        errors: createTouchedErrors(formik.errors, formik.touched),
      }}
    >
      <SubstanceFields isEditMode dictionaries={dictionaries?.data} editedObject={editedObject} />
    </FormikProvider>
  );
};
