import { Box, Flex, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { AttachmentsTabContent, Button, DefaultText, FormHeader, FormWrapper, LoaderItem } from 'components/common';
import React, { useCallback, useMemo } from 'react';
import { useLocation } from 'react-router';
import { COMMON_LOCALE } from 'constants/common';
import { CommonTab } from './CommonTab';
import { ParametersTab } from './ParametersTab';
import { useQuery } from 'hooks/useQuery';
import { useAvailability, useEnhancedNotification } from 'hooks';
import { useFormikContext } from 'formik';
import { VegetationDeicingItem } from 'pages/data/PGR/GreenPlants/VegetativeState/useVegDicingList';
import { useObjectType } from 'hooks/useObjectType';
import appLocale from 'constants/appLocale';
import { FieldFormProps } from 'types/forms';
import subsystemIdsMap from 'constants/subsystemIdsMap';

const FieldsForm = ({
  onCancel,
  dictionaries,
  isLoading,
  media,
  onMediaChange,
  uploadItemId,
}: FieldFormProps) => {
  const { isAvailable } = useAvailability(subsystemIdsMap.pgrRoot);

  const location = useLocation();
  const isEditMode = location.pathname.includes('/edit');
  const title = useMemo(
    () => (isEditMode ? COMMON_LOCALE.text.edit_research_result : COMMON_LOCALE.text.addition_research_result),
    [isEditMode],
  );
  const { openAttachmentsTab } = useQuery();
  const { handleSubmit, errors } = useFormikContext<VegetationDeicingItem>();
  const { onValidationError } = useEnhancedNotification();
  const objectType = useObjectType(dictionaries);

  const onSubmit = useCallback(() => {
    if (Object.keys(errors).length > 0) {
      onValidationError();
    }
    handleSubmit();
  }, [errors, handleSubmit, onValidationError]);

  const renderControls = useMemo(
    () => (
      <Flex>
        <Button onClick={onCancel} marginRight="auto">
          {COMMON_LOCALE.cancel}
        </Button>

        <Button
          isDisabled={!isAvailable}
          onClick={onSubmit}
          variant="solid"
        >
          {COMMON_LOCALE.save}
        </Button>
      </Flex>
    ),
    [onCancel, isAvailable, onSubmit],
  );

  if (isLoading) {
    return <LoaderItem />;
  }

  return (
    <Box flexDirection="column">
      <Tabs defaultIndex={openAttachmentsTab ? 2 : 0}>
        <FormHeader onBackClick={onCancel} header={title}>
          <TabList>
            {[appLocale.common.generalInformation, appLocale.common.indicators, appLocale.common.attachments].map(
              (tab, i) => (
                <Tab key={i}>
                  <DefaultText cursor="pointer">{tab}</DefaultText>
                </Tab>
              ),
            )}
          </TabList>
        </FormHeader>
        <TabPanels>
          <TabPanel>
            <CommonTab dictionaries={dictionaries} />
          </TabPanel>
          <TabPanel>
            <ParametersTab isWrite={isAvailable} dictionaries={dictionaries}>
              {renderControls}
            </ParametersTab>
          </TabPanel>
          <TabPanel>
            <FormWrapper>
              {isAvailable && (
                <AttachmentsTabContent
                  objectType={objectType}
                  media={media}
                  onMediaChange={onMediaChange}
                  uploadItemId={uploadItemId}
                >
                  {renderControls}
                </AttachmentsTabContent>
              )}
            </FormWrapper>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default React.memo(FieldsForm);
