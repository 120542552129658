import { FormWrapper, Input } from 'components/common';
import { Field, FieldArray, useFormikContext } from 'formik';
import appLocale from 'constants/appLocale';
import { Box, Button, Flex, Grid, Heading } from '@chakra-ui/react';
import React, { FC } from 'react';
import { IGroundCoastFormDto } from 'pages/data/Water/WPZRoot/GroundCoast/consts/types';
import { DeleteIcon } from 'components/icons';

const ForeignObjectsTab: FC = ({ children }) => {
  const { values, errors, setFieldValue } = useFormikContext<IGroundCoastFormDto>();

  const onAddClick = () => {
    setFieldValue('garbageTypeList', [...values.garbageTypeList, '']);
  };

  const removeGarbage = (index: number) => {
    const newArray = [...values.garbageTypeList];
    newArray.splice(index, 1);
    setFieldValue('garbageTypeList', newArray);
  };

  return (
    <Box flexDirection="column">
      <FormWrapper>
        <Grid marginTop="30px" gap="15px" marginBottom={20}>
          <Field
            as={Input}
            type="number"
            name="garbageMaxSize"
            label={appLocale.groundCoast.garbageMaxSize}
            error={errors?.garbageMaxSize}
          />

          <Heading>{appLocale.groundCoast.foreignObjectsFound}</Heading>

          <FieldArray name="garbageTypeList">
            {() => (
              <Grid gap={2}>
                {values.garbageTypeList.map((el, index) => (
                  <Flex key={index} alignItems="center">
                    <Field as={Input} name={`garbageTypeList[${index}]`} label="Тип мусора" />

                    <Button leftIcon={<DeleteIcon />} onClick={() => removeGarbage(index)} variant="ghost" />
                  </Flex>
                ))}
              </Grid>
            )}
          </FieldArray>
          <Button onClick={onAddClick}>Добавить</Button>

          {children}
        </Grid>
      </FormWrapper>
    </Box>
  );
};

export default ForeignObjectsTab;
