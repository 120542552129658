import { AxiosRequestConfig } from 'axios';
import { FilePreviewErrors } from 'models';
import * as React from 'react';
import { Flex } from '@chakra-ui/react';
import { useUploadFileInputFileMetadata } from 'features/UploadedFileProvider';
import { InputFileMetadataController } from '../InputFileMetadataController';
import { createInputFileMetadata } from '../utils';
import { DragAndDropController } from './controller';

export interface DragAndDropProps {
  isDisabled: boolean;
  errors?: FilePreviewErrors;
  onChangeAsFormData: (formData: FormData, config: AxiosRequestConfig) => void;
}

export const DragAndDrop: React.FC<DragAndDropProps> = ({ isDisabled, errors = [], onChangeAsFormData }) => {
  const { inputFileMetadata, setInputFileMetadata } = useUploadFileInputFileMetadata();

  const handleChangeFile = React.useCallback(
    (file: File) => {
      if (typeof setInputFileMetadata === 'function') {
        setInputFileMetadata(createInputFileMetadata(file));
      }

      const formData = new FormData();

      formData.append('file', file);

      onChangeAsFormData(formData, {
        onUploadProgress: (progressEvent): void => {
          const { loaded, total } = progressEvent;

          const percent = Math.floor((loaded * 100) / total);

          if (typeof setInputFileMetadata === 'function') {
            setInputFileMetadata((prevState) => ({
              ...prevState,
              percentage: percent,
              loaded,
              isLoading: true,
            }));
          }
        },
      });
    },
    [onChangeAsFormData, setInputFileMetadata],
  );

  return (
    <Flex flexDirection="column">
      <DragAndDropController isDisabled={isDisabled} onChange={handleChangeFile} />
      <InputFileMetadataController inputFileMetadata={inputFileMetadata} errors={errors} />
    </Flex>
  );
};
