import { RenderTree } from 'features/WithSideBar/types';
import appLocale from 'constants/appLocale';
import subsystemIdsMap from 'constants/subsystemIdsMap';
import { Reagents } from '../../home/icons';

export const ReagentsRootPath = '/data/reagents';

export const ReagentsApiRootPath = '/deicing/v10/deicing';
export const ReagentsSoilApiRoot = `${ReagentsApiRootPath}/soil`;
export const ReagentsSoilApiList = `${ReagentsSoilApiRoot}/list`;
export const ReagentsSoilApiDictionaries = `${ReagentsSoilApiRoot}/dictionaries`;
export const ReagentsSoilApiLoadPreview = `${ReagentsSoilApiRoot}/load/preview`;
export const ReagentsSoilApiLoadValidate = `${ReagentsSoilApiRoot}/load/validate`;
export const ReagentsSoilApiLoadApply = `${ReagentsSoilApiRoot}/load/apply`;
export const ReagentsApiGreenplants = '/deicing/v10/greenplants';

export const ReagentsSoilRootPath = `${ReagentsRootPath}/soil`;
export const ReagentsSoilAddPath = `${ReagentsSoilRootPath}/add`;

export const ReagentsGreenPlantsRootPath = `${ReagentsRootPath}/greenplants`;
export const ReagentsWashoutRootPath = `${ReagentsGreenPlantsRootPath}/washout`;
export const ReagentsWashoutAddPath = `${ReagentsWashoutRootPath}/add`;
export const ReagentsVegsRootPath = `${ReagentsGreenPlantsRootPath}/vegs`;
export const ReagentsVegsAddPath = `${ReagentsVegsRootPath}/add`;

export const DeicingGreenPlantsRootPath = `${ReagentsRootPath}/greenplants`;
export const DeicingStatements = `${DeicingGreenPlantsRootPath}/statements`;

export const GreenPlantsFloraUrl = `${DeicingStatements}/flora`;
export const GreenPlantsLawnsUrl = `${DeicingStatements}/lawns`;

export const statements = {
  id: DeicingStatements,
  url: DeicingStatements,
  name: 'Ведомости',
  children: [
    {
      id: GreenPlantsFloraUrl,
      url: GreenPlantsFloraUrl,
      name: 'Деревья и кустарники',
      subsystemId: subsystemIdsMap.pgrFlora,
    },
    {
      id: GreenPlantsLawnsUrl,
      url: GreenPlantsLawnsUrl,
      name: 'Газоны',
      subsystemId: subsystemIdsMap.pgrLawn,
    },
  ],
};

export const reagentsMenu: RenderTree = {
  id: ReagentsRootPath,
  name: appLocale.subsystems.reagents,
  url: ReagentsRootPath,
  subsystemId: subsystemIdsMap.pgrRoot,
  homepageIcon: <Reagents />,
  children: [
    {
      id: ReagentsGreenPlantsRootPath,
      url: ReagentsGreenPlantsRootPath,
      name: 'Воздействие на зеленые насаждения',
      children: [
        {
          id: ReagentsWashoutRootPath,
          url: `${ReagentsWashoutRootPath}/main-data`,
          name: 'Смывы',
          subsystemId: subsystemIdsMap.pgrWashouts,
        },
        {
          id: ReagentsVegsRootPath,
          url: `${ReagentsVegsRootPath}/main-data`,
          name: 'Вегетативные части зеленых насаждений',
          subsystemId: subsystemIdsMap.pgrVegetative,
        },
        statements,
        {
          id: `${DeicingGreenPlantsRootPath}/areas`,
          url: `${DeicingGreenPlantsRootPath}/areas`,
          name: 'Площадки',
          children: [
            {
              id: `${DeicingGreenPlantsRootPath}/areas/flora`,
              url: `${DeicingGreenPlantsRootPath}/areas/flora`,
              name: 'Деревья и кустарники',
              subsystemId: subsystemIdsMap.pgrFlora,
            },
            {
              id: `${DeicingGreenPlantsRootPath}/areas/lawns`,
              url: `${DeicingGreenPlantsRootPath}/areas/lawns`,
              name: 'Газоны',
              subsystemId: subsystemIdsMap.pgrLawn,
            },
          ],
        },
      ],
    },
    {
      id: ReagentsSoilRootPath,
      url: `${ReagentsSoilRootPath}/main-data`,
      name: 'Воздействие на почву',
      subsystemId: subsystemIdsMap.pgrSoil,
    },
    {
      id: `${ReagentsRootPath}/snow`,
      url: `${ReagentsRootPath}/snow/main-data`,
      name: 'Воздействие на снег',
      subsystemId: subsystemIdsMap.pgrSnow,
    },
  ],
};
