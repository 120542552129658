import { useCallback, useEffect, useState } from 'react';
import { IChart } from '../types';
import Moment from 'moment';
import { timeFormats } from 'constants/timeFormats';

export const useMinMaxDate = () => {
  const [maxDate, setMaxDate] = useState<null | string>(null);
  const [minDate, setMinDate] = useState<null | string>(null);
  const [minMaxTs, setMinMaxTs] = useState<[number, number] | null>(null);

  const updateData = useCallback((chart: IChart) => {
    if (chart?.scales?.x) {
      const { min, max } = chart.scales.x;
      setMinMaxTs([min, max]);
      setMinDate(Moment(min).format(timeFormats.fullDate));
      setMaxDate(Moment(max).format(timeFormats.fullDate));
    }
  }, []);

  // effect for  clear data
  useEffect(
    () => () => {
      setMaxDate(null);
      setMinDate(null);
    },
    [setMaxDate, setMinDate]
  );

  return {
    maxDate,
    setMaxDate,
    minDate,
    setMinDate,
    updateData,
    minMaxTs,
  };
};
