import { Field, FormikProvider, useFormik } from 'formik';
import React, { useEffect, useMemo, useState } from 'react';
import { Checkbox, DateInput, Select } from 'components/common';
import { Flex, Grid } from '@chakra-ui/react';
import { createDateFromResponse, createDateToRequestForFilter, createDateToRequestWithTimezone } from 'lib/create-date';
import { TDictionariesArray } from 'models/dictionaries';
import {
  getSelectOptionsFromDictionaryByName,
  getSelectOptionsFromDictionaryByNameWithParent,
} from 'features/get-select-options-from-dictionary';
import { FilterCheckbox } from 'features/Filter/components';
import { IPeriod } from 'features/PeriodsButtons/types';
import { PeriodsButtons } from 'features/PeriodsButtons';
import { usePeriods } from 'features/PeriodsButtons/usePeriods';
import combineFilters from 'lib/utils/combineFilters';
import { IFilterItem, TFiltersArray } from 'models';
import { MomentInput } from 'moment';
import appLocale from 'constants/appLocale';
import { FiltersDatepickersSchema } from 'models/schemas/filtersDatepickersSchema/FiltersDatepickersSchema';
import FilterFormControls from 'components/form/FilterFormControls';

interface FilterProps {
  isLoading?: boolean;
  isDisabled?: boolean;
  dictionaries: {
    data: TDictionariesArray;
  } | null;
  onSubmit: (values: any) => void;
  periods: IPeriod[] | null;
  filters?: TFiltersArray;
}

const initialEmptyValues: Record<string, IFilterItem<string>> = {
  measuringDateMin: {
    key: 'valueDate',
    operation: 'GTE',
    value: null,
  },
  measuringDateMax: {
    key: 'valueDate',
    operation: 'LTE',
    value: null,
  },
  areaId: {
    key: 'areaId',
    operation: 'EQ',
    value: '',
  },
  pointId: {
    key: 'pointId',
    operation: 'EQ',
    value: '',
  },
  // showDisabled - value равно '' если чекбокс включен и нужно получить с Backend все значения (этот объект удалиться при отправке)
  // value равно false - если нужно показать только неудаленные записи
  deleted: {
    key: 'deleted',
    operation: 'EQ',
    value: false,
  },
  periodId: {
    key: 'periodId',
    operation: 'EQ',
    value: null,
  },
  showOnlyChangedRows: {
    key: 'showOnlyChangedRows',
    operation: 'EQ',
    value: false,
  },
};

export const InclinometerFilter = ({
  isLoading,
  isDisabled,
  onSubmit,
  dictionaries,
  periods,
  filters = [],
  ...props
}: FilterProps) => {
  const savedPeriodId = useMemo(
    () => (filters.find((item) => item.key === 'periodId')?.value as string) || '',
    [filters]
  );

  const [periodId, setPeriodId] = useState<string>(savedPeriodId);

  const { getDatesFromPeriod, dates } = usePeriods(periodId, periods);

  const submitPeriod = (id: string) => {
    const dates = getDatesFromPeriod(id);
    setFieldValue('periodId.value', id);
    setFieldValue('measuringDateMin.value', createDateToRequestWithTimezone(dates[0]));
    setFieldValue('measuringDateMax.value', createDateToRequestWithTimezone(dates[1]));
    setFieldTouched('measuringDateMin.value');
    setPeriodId(id);
  };

  const { values, errors, setValues, handleReset, submitForm, setFieldValue, setFieldTouched, ...formik } = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    validationSchema: FiltersDatepickersSchema([
      { dateFromFieldName: 'measuringDateMin', dateToFieldName: 'measuringDateMax' },
    ]),
    initialValues: initialEmptyValues,
    onSubmit,
  });

  useEffect(() => {
    setValues(combineFilters(initialEmptyValues, filters));
  }, [filters, setValues]);

  const areas = useMemo(() => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'areas'), [dictionaries]);

  const points = useMemo(() => {
    const points = getSelectOptionsFromDictionaryByNameWithParent(dictionaries?.data, 'points');

    if (values.areaId.value) {
      return points.filter((item) => item.parent === values.areaId.value);
    }

    return points;
  }, [dictionaries, values.areaId.value]);

  return (
    <FormikProvider
      value={{
        values,
        errors,
        setValues,
        handleReset,
        submitForm,
        setFieldValue,
        setFieldTouched,
        ...formik,
      }}
    >
      <Flex flexDirection="row" justifyContent="space-between">
        <Grid gap="12px" rowGap="8px" gridTemplateColumns="repeat(2, 175px) 225px 175px" {...props}>
          <Field
            as={DateInput}
            maxDate={dates?.[1]}
            minDate={dates?.[0]}
            isDisabled={!periodId}
            name="measuringDateMin.value"
            label="От"
            value={createDateFromResponse(values.measuringDateMin.value as MomentInput)}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFieldValue('measuringDateMin.value', createDateToRequestForFilter(e.target.value));
            }}
            showTimeInput={false}
            error={errors.measuringDateMin?.value}
          />
          <Field
            as={DateInput}
            maxDate={dates?.[1]}
            minDate={dates?.[0]}
            isDisabled={!periodId}
            name="measuringDateMax.value"
            label="До"
            value={createDateFromResponse(values.measuringDateMax.value as MomentInput)}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFieldValue('measuringDateMax.value', createDateToRequestForFilter(e.target.value, 'end'));
            }}
            showTimeInput={false}
            error={errors.measuringDateMax?.value}
          />
          <Field
            as={Select}
            name="areaId.value"
            label="Участок"
            options={areas}
            value={values.areaId.value}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFieldValue('areaId.value', e.target.value);
            }}
            error={errors.areaId?.value}
          />
          <Field
            as={Select}
            name="pointId.value"
            label="Точка"
            options={points}
            value={values.pointId.value}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFieldValue('pointId.value', e.target.value);
            }}
            error={errors.pointId?.value}
          />
        </Grid>

        <FilterFormControls
          onReset={(e) => {
            handleReset(e);
            setPeriodId('');
            setTimeout(submitForm, 0);
          }}
          onSubmit={submitForm}
          isDisabled={isDisabled}
          isLoading={isLoading}
        />
      </Flex>

      <Flex alignItems="center" height={45} gap={4}>
        <Field
          as={FilterCheckbox}
          name="showOnlyChangedRows.value"
          label={appLocale.common.showOnlyChangedRows}
          checked={values.showOnlyChangedRows.value}
        />

        <Field as={Checkbox} name="deleted.value" label="Показать удаленные" />
      </Flex>

      <PeriodsButtons periodId={periodId} periods={periods} submitPeriod={submitPeriod} />
    </FormikProvider>
  );
};
