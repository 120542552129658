import { LoaderItem, TabListHeader } from 'components/common';
import { Box, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import React from 'react';
import { FieldFormProps } from 'types/forms';
import subsystemIdsMap from 'constants/subsystemIdsMap';
import { useAvailability } from 'hooks';
import { IPDKExcessLogDto } from 'pages/PDKExcessJournal/types';
import { CommonTab } from 'pages/PDKExcessJournal/Form/CommonTab';
import { ExcessTab } from 'pages/PDKExcessJournal/Form/ExcessTab';
import FormControls from 'components/common/FormControls';

const FieldsForm = ({ onCancel, dictionaries, isLoading, isEditMode }: FieldFormProps) => {
  const { isAvailable } = useAvailability(subsystemIdsMap.pdkExcessJournal);

  const { handleSubmit } = useFormikContext<IPDKExcessLogDto>();

  if (isLoading) {
    return <LoaderItem />;
  }

  return (
    <Box flexDirection="column">
      <Tabs>
        <TabListHeader
          header={isEditMode ? 'Редактирование записи' : 'Добавление записи'}
          onBackClick={onCancel}
          tabNames={['Общие', 'Превышения']}
        />

        <TabPanels>
          <TabPanel>
            <CommonTab dictionaries={dictionaries} isEditMode={!!isEditMode}>
              <FormControls
                handleSubmit={handleSubmit}
                submitIsDisabled={!isAvailable || isLoading}
                onCancel={onCancel}
              />
            </CommonTab>
          </TabPanel>

          <TabPanel>
            <ExcessTab dictionaries={dictionaries} isEditMode={!!isEditMode}>
              <FormControls
                handleSubmit={handleSubmit}
                submitIsDisabled={!isAvailable || isLoading}
                onCancel={onCancel}
              />
            </ExcessTab>
          </TabPanel>

        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default React.memo(FieldsForm);
