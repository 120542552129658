import { FormikProvider, useFormik } from 'formik';
import React, { useEffect, useMemo } from 'react';
import { RaidFormFields } from '../RaidFormFields';
import { initialValues } from './const';
import { IFilterItem, TDictionariesArray } from 'models';
import { FiltersDatepickersSchema } from 'models/schemas/filtersDatepickersSchema/FiltersDatepickersSchema';
import combineFilters from 'lib/utils/combineFilters';

interface IRaidFilter {
  onSubmit: (values: { [p: string]: IFilterItem }) => void;
  dictionaries: { data: TDictionariesArray } | null;
  isAir: boolean;
  filters: IFilterItem[];
  type: string;
}

export const RaidFilter = ({ onSubmit, dictionaries, isAir, filters, type }: IRaidFilter) => {
  const initialEmptyValues: Record<string, IFilterItem<string>> = useMemo(() => ({
    ...initialValues,
    type: {
      key: 'typeId',
      operation: 'EQ',
      value: type,
    },
  }), [type]);

  const { setValues, ...formik } = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    validationSchema: FiltersDatepickersSchema([
      { dateFromFieldName: 'dateTimeMin', dateToFieldName: 'dateTimeMax' },
    ]),
    initialValues: initialEmptyValues,
    onSubmit,
  });

  useEffect(() => {
    setValues(combineFilters(initialEmptyValues, filters));
  }, [filters, initialEmptyValues, setValues]);

  return (
    <>
      <FormikProvider
        value={{ setValues, ...formik }}
      >
        <RaidFormFields dictionaries={dictionaries} isAir={isAir} />
      </FormikProvider>
    </>
  );
};
