import { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FormikProvider, useFormik } from 'formik';

import { createTouchedErrors } from 'features/create-touched-errors';
import { useRequest } from 'hooks/useRequest';

import { SurfaceWaterFormFields } from './Fields';
import { initialEmptyValues } from './configs';
import { apiPathSurfaceWater } from '../apiPath';
import { WaterRouteSurfaceWatersRoot } from '../../routes';
import { useSourceInfo } from '../hooks';
import { useEnhancedNotification } from 'hooks';
import { WaterSurfaceSchema } from 'models/schemas/water';
import { ApiError } from 'models';
import { IUploadMedia } from 'components/common/FileUploadDragAndDrop/types';
import { useObjectType } from 'hooks/useObjectType';
import { updateFiles } from 'api/services/media';
import { formatErrorMessage } from 'lib/utils/formatErrorMessage';
import { useNotifications } from 'hooks/useNotifications';
import { ERROR_MESSAGES } from 'constants/messages';
import { LoaderItem } from 'components/common';
import { useGoBackWithFallback } from 'lib/utils/goBackWithFallback';
import { useDictionary } from 'hooks/useDictionary';
import { getSelectOptionsFromDictionaryByName } from 'features/get-select-options-from-dictionary';

export const EditSurfaceWaterForm = () => {
  const { backWithFallback } = useGoBackWithFallback();
  const { id } = useParams<{ id: string }>();

  const [media, setMedia] = useState<IUploadMedia[]>([]);

  const { result: waterSourceInfo, isLoading: isWaterLoading } = useSourceInfo(`${apiPathSurfaceWater.root}/${id}`);

  const { dictionaries, isDictionariesLoading } = useDictionary({ url: apiPathSurfaceWater.dict });
  const sources = useMemo(
    () => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'sources'),
    [dictionaries?.data],
  );
  const waterObjects = useMemo(
    () => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'waterObjects'),
    [dictionaries?.data],
  );

  const organizations = useMemo(
    () =>
      getSelectOptionsFromDictionaryByName(dictionaries?.data, 'organizations', false, [
        waterSourceInfo?.organizationId,
        waterSourceInfo?.consumerId,
      ]),
    [dictionaries?.data, waterSourceInfo?.consumerId, waterSourceInfo?.organizationId],
  );
  const parameters = useMemo(
    () => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'parameters'),
    [dictionaries?.data],
  );

  const initialValues = useMemo(() => {
    const values: any = { ...initialEmptyValues, ...waterSourceInfo };
    values.values = parameters?.map((param) => ({
      id:
        waterSourceInfo?.values.find((item) => item.parameterId === param.value && item.unitId === param.parent)?.id ||
        null,
      parameterId: param.value,
      value:
        waterSourceInfo?.values.find((val: any) => val.parameterId === param.value && val.unitId === param.parent)
          ?.value || '',
      unitId: param.parent || '',
      recalculated: waterSourceInfo?.values.find(
        (item) => item.parameterId === param.value && item.unitId === param.parent
      )?.recalculated
    }));
    return values;
  }, [parameters, waterSourceInfo]);

  const { onSuccessEdit } = useEnhancedNotification();
  const { errorNotify } = useNotifications();
  const objectType = useObjectType(dictionaries);

  const onError = useCallback(
    (error: ApiError | string | null) => {
      const message = formatErrorMessage(error);
      errorNotify({
        key: message || 'file/edit/error',
        message: message || ERROR_MESSAGES.EDIT,
      });
    },
    [errorNotify],
  );

  const onSuccess = useCallback(() => {
    onSuccessEdit();
    updateFiles(id, objectType, media)
      .then(() => {
        backWithFallback(`${WaterRouteSurfaceWatersRoot}/main-data`);
      })
      .catch((error: Error) => {
        onError(error.message);
      });
  }, [onSuccessEdit, id, objectType, media, backWithFallback, onError]);

  const { put, isLoading: isPutLoading } = useRequest(apiPathSurfaceWater.root, onSuccess, onError);

  const useFormikProps = useMemo(
    () => ({
      enableReinitialize: true,
      validateOnChange: true,
      validateOnMount: true,
      validateOnBlur: true,
      validationSchema: WaterSurfaceSchema,
      initialValues,
      onSubmit: (values: any) => {
        const valuesToPost = { ...values };
        valuesToPost.values = valuesToPost.values.filter((val: any) => Boolean(val?.value));
        void put(valuesToPost);
      },
    }),
    [initialValues, put],
  );

  const formik = useFormik(useFormikProps);

  if (isDictionariesLoading || isWaterLoading || isPutLoading) {
    return <LoaderItem />;
  }

  return (
    <div>
      <FormikProvider
        value={{
          ...formik,
          errors: createTouchedErrors(formik.errors, formik.touched),
        }}
      >
        <SurfaceWaterFormFields
          parameters={parameters}
          isEditMode
          sources={sources}
          waterObjects={waterObjects}
          organizations={organizations}
          isLoading={isDictionariesLoading || isWaterLoading}
          media={media}
          onMediaChange={setMedia}
          objectType={objectType}
          uploadItemId={id}
        />
      </FormikProvider>
    </div>
  );
};
