import React, { useEffect, useMemo } from 'react';
import { Field, FieldArray, useFormikContext } from 'formik';
import { Box, Flex, Grid, Heading, Text } from '@chakra-ui/react';
import { Button, DateInput, FormWrapper, Input } from 'components/common';
import { TDictionariesArray } from 'models/dictionaries';
import { getSelectOptionsFromDictionaryByNameWithParent } from 'features/get-select-options-from-dictionary';
import { createDateToRequestWithTimezone } from 'lib/create-date';
import RegistryDtos from 'pages/data/Meteo/AISWeather/AISWeather/Forms/RegistryDtos';
import { AISWeatherSaveItem } from 'hooks/aisWeather/useAISWeatherRecord';
import isRequiredInSchema from 'lib/utils/forms/isReqiuredInSchema';
import { aisWeatherSchema } from 'models/schemas/meteo/aisWeatherSchema';
import { get } from 'lodash';

interface CommonTabProps {
  dictionaries: {
    data: TDictionariesArray;
  } | null;
  name: string;
  stationIndex: number;
  stationId: string;
  onCancel: () => void;
  isEdit: boolean;
  isAvailable?: boolean;
}

export default function ASTab({
  dictionaries,
  name,
  stationIndex,
  stationId,
  onCancel,
  isEdit,
  isAvailable,
}: CommonTabProps) {
  const zeroHeightIndex = 0;
  const zeroHeightId = '23c963bd-4f0e-4ab0-b7fb-5db41a8b63d2';
  const { errors, setFieldValue, handleSubmit, values } = useFormikContext<AISWeatherSaveItem>();

  useEffect(() => {
    setFieldValue(`stations.${stationIndex}.stationId`, stationId);
  }, [setFieldValue, stationId, stationIndex]);

  const heights = useMemo<any>(
    () =>
      getSelectOptionsFromDictionaryByNameWithParent(dictionaries?.data, 'heights')
        .filter((el) => el.parent === stationId)
        .sort((a, b) => (parseInt(a.name) > parseInt(b.name) ? 1 : -1)),
    [dictionaries, stationId],
  );

  const stationsError = get(errors.stations?.[stationIndex], 'registryDtos');

  return (
    <Box flexDirection="column">
      <FormWrapper>
        <Grid marginTop={30} gap={15} marginBottom={30}>
          <Heading variant="h2">Данные&nbsp;{name}</Heading>

          <Grid templateColumns="repeat(2, 1fr)" gap="15px">
            <Field
              as={DateInput}
              dateFormat="MM/dd/yyyy hh:mm:ss"
              label="Дата и время"
              name="packageDatetimeResult"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setFieldValue('packageDatetimeResult', createDateToRequestWithTimezone(e.target.value));
              }}
              isDisabled={isEdit || !isAvailable}
              isRequired={isRequiredInSchema(aisWeatherSchema, 'packageDatetimeResult')}
              error={errors.packageDatetimeResult}
              showTimeInputWithSeconds
            />

            <Field
              as={DateInput}
              label="Дата и время пакета"
              name="datetime"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setFieldValue('datetime', createDateToRequestWithTimezone(e.target.value));
              }}
              showTimeInput
              isDisabled={isEdit}
              isRequired={isRequiredInSchema(aisWeatherSchema, 'datetime')}
              error={errors.datetime}
            />
          </Grid>

          <Box flexDirection="column">
            <Grid margin="30px 0px 20px 0px" gap="15px">
              <FieldArray name="stationId">
                {() => (
                  <>
                    <Box flexDirection="column">
                      {heights.length > 0 &&
                        Object.keys(values).length &&
                        heights.map((data: any, index: any) => {
                          if (data.value === zeroHeightId) return null;

                          const dotIndex = values.stations?.[stationIndex]?.registryDtos?.findIndex(
                            (item: any) => data.value === item?.heightId,
                          );

                          const currentIndex = isEdit ? dotIndex : index;
                          return (
                            <RegistryDtos
                              date={values.packageDatetimeResult}
                              key={data.value}
                              id={data.value}
                              index={index}
                              stationIndex={stationIndex}
                            >
                              <Flex minHeight="74px" marginBottom="16px" alignItems="flex-start">
                                <Grid
                                  gap="15px"
                                  flexGrow={1}
                                  marginRight={15}
                                  alignItems="flex-start"
                                  templateColumns="150px 250px 200px 200px 250px"
                                >
                                  <Text fontSize={18} fontWeight={700}>{`Высота ${data.name}`}</Text>
                                  <Field
                                    as={Input}
                                    name={`stations.${stationIndex}.registryDtos.${currentIndex}.airTemperature`}
                                    label="Температура воздуха, град Целс"
                                    isDisabled={!isAvailable}
                                    error={get(stationsError, `${currentIndex}.airTemperature`)}
                                  />
                                  <Field
                                    as={Input}
                                    name={`stations.${stationIndex}.registryDtos.${isEdit ? dotIndex : index}.wetness`}
                                    label="Влажность, %"
                                    isDisabled={!isAvailable}
                                    error={get(stationsError, `${currentIndex}.wetness`)}
                                  />
                                  <Field
                                    as={Input}
                                    name={`stations.${stationIndex}.registryDtos.${currentIndex}.instantaneousWind`}
                                    label="Скорость ветра МГН, м/c"
                                    isDisabled={!isAvailable}
                                    error={get(stationsError, `${currentIndex}.instantaneousWind`)}
                                  />
                                  <Field
                                    as={Input}
                                    name={`stations.${stationIndex}.registryDtos.${currentIndex}.windDirection`}
                                    label="Направление ветра, град пл угла"
                                    isDisabled={!isAvailable}
                                    error={get(stationsError, `${currentIndex}.windDirection`)}
                                  />
                                </Grid>
                              </Flex>
                            </RegistryDtos>
                          );
                        })}
                    </Box>

                    {heights.find((item: any) => item.value === zeroHeightId) && (
                      <Box flexDirection="column">
                        <RegistryDtos
                          date={values.packageDatetimeResult}
                          id={heights[zeroHeightIndex]?.value}
                          index={zeroHeightIndex}
                          stationIndex={stationIndex}
                        >
                          <Flex marginBottom="16px" alignItems="flex-start">
                            <Text width={150} marginRight={15} fontSize={18} fontWeight={700}>
                              {`Высота ${heights[zeroHeightIndex]?.name}`}
                            </Text>
                            <Grid
                              gap="15px"
                              flexGrow={1}
                              marginRight={15}
                              alignItems="flex-start"
                              templateRows="repeat(3, 60px)"
                              templateColumns="repeat(3, 1fr)"
                            >
                              <Field
                                as={Input}
                                name={`stations.${stationIndex}.registryDtos.${zeroHeightIndex}.airTemperature`}
                                label="Температура воздуха, град Целс"
                                isDisabled={!isAvailable}
                                error={get(stationsError, `${zeroHeightIndex}.airTemperature`)}
                              />
                              <Field
                                as={Input}
                                name={`stations.${stationIndex}.registryDtos.${zeroHeightIndex}.wetness`}
                                label="Влажность, %"
                                isDisabled={!isAvailable}
                                error={get(stationsError, `${zeroHeightIndex}.wetness`)}
                              />
                              <Field
                                as={Input}
                                name={`stations.${stationIndex}.registryDtos.${zeroHeightIndex}.windDirection`}
                                label="Направление ветра, град пл угла"
                                isDisabled={!isAvailable}
                                error={get(stationsError, `${zeroHeightIndex}.windDirection`)}
                              />
                              <Field
                                as={Input}
                                name={`stations.${stationIndex}.registryDtos.${zeroHeightIndex}.instantaneousWind`}
                                label="Скорость ветра МГН, м/c"
                                isDisabled={!isAvailable}
                                error={get(stationsError, `${zeroHeightIndex}.instantaneousWind`)}
                              />
                              <Field
                                as={Input}
                                name={`stations.${stationIndex}.registryDtos.${zeroHeightIndex}.airPressure`}
                                label="Атмосферное давление, мм рт ст"
                                isDisabled={!isAvailable}
                                error={get(stationsError, `${zeroHeightIndex}.airPressure`)}
                              />
                              <Field
                                as={Input}
                                name={`stations.${stationIndex}.registryDtos.${zeroHeightIndex}.surfaceTemperature`}
                                label="Температура почвы, град Целс"
                                isDisabled={!isAvailable}
                                error={get(stationsError, `${zeroHeightIndex}.surfaceTemperature`)}
                              />
                              <Field
                                as={Input}
                                name={`stations.${stationIndex}.registryDtos.${zeroHeightIndex}.phenomena`}
                                label="Явление погоды"
                                isDisabled={!isAvailable}
                                error={get(stationsError, `${zeroHeightIndex}.phenomena`)}
                              />
                              <Field
                                as={Input}
                                name={`stations.${stationIndex}.registryDtos.${zeroHeightIndex}.visibility`}
                                label="Видимость, м"
                                isDisabled={!isAvailable}
                                error={get(stationsError, `${zeroHeightIndex}.visibility`)}
                              />
                              <Field
                                as={Input}
                                name={`stations.${stationIndex}.registryDtos.${zeroHeightIndex}.cloudHeight`}
                                label="Высота облачности, м"
                                isDisabled={!isAvailable}
                                error={get(stationsError, `${zeroHeightIndex}.cloudHeight`)}
                              />
                            </Grid>
                          </Flex>
                        </RegistryDtos>
                      </Box>
                    )}
                  </>
                )}
              </FieldArray>
              <Flex>
                <Button onClick={onCancel} marginRight={20}>
                  Отмена
                </Button>
                <Button isDisabled={!isAvailable} onClick={() => handleSubmit()} variant="solid">
                  Сохранить
                </Button>
              </Flex>
            </Grid>
          </Box>
        </Grid>
      </FormWrapper>
    </Box>
  );
}
