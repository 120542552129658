import { createTouchedErrors } from 'features/create-touched-errors';
import { FormikProvider, useFormik } from 'formik';
import { useRequest } from 'hooks/useRequest';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { QualitySchema } from 'models/schemas/groundwaters/quality';
import { GeoecologicalRootPath } from 'pages/data/GeoecologicalProcesses/consts';
import FieldsForm from './FieldsForm';
import React, { useState } from 'react';
import { IUploadMedia } from 'components/common/FileUploadDragAndDrop/types';
import { QualityItem } from 'hooks/geo/Quality/useQualityList';
import { useObjectType } from 'hooks/useObjectType';
import { useUpdateFiles } from 'api/services/media';
import { useGoBackWithFallback } from 'lib/utils/goBackWithFallback';
import { useDictionary } from 'hooks/useDictionary';
import { useEnhancedNotification } from 'hooks';
import { LoaderItem } from '../../../../../../components/common';

export const initialEmptyValues = {
  measuringDate: moment().format(),
  waterSourceType: '',
  waterSourceTypeName: '',
  id: '',
  sourceCode: '',
  sourceId: '',
  measures: [],
};

export const AddQualityResult = () => {
  const { backWithFallback } = useGoBackWithFallback();
  const { isDictionariesLoading, dictionaries } = useDictionary({
    url: '/geoecology/v10/geoecology/watersource/quality/dictionaries',
  });
  const history = useHistory();
  const { onSuccessCreate, onErrorCreate } = useEnhancedNotification();
  const [media, setMedia] = useState<IUploadMedia[]>([]);
  const { updateFiles, isLoading } = useUpdateFiles();

  const objectType = useObjectType(dictionaries);

  const onSuccess = (response: QualityItem | null) => {
    if (!response) {
      onErrorCreate();
      return;
    }

    updateFiles(response.id, objectType, media)
      .then(() => {
        onSuccessCreate();
        backWithFallback(`${GeoecologicalRootPath}/monitoring/water/quality`);
      })
      .catch((error: Error) => {
        onErrorCreate(error.message);
        history.replace(
          `${GeoecologicalRootPath}/monitoring/water/quality/edit/row/${response.id}?openAttachmentsTab=true`,
          response,
        );
      });
  };

  const { post, isLoading: isPostLoading } = useRequest<QualityItem, any>(
    '/geoecology/v10/geoecology/watersource/quality',
    onSuccess,
    onErrorCreate,
  );

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    initialValues: initialEmptyValues,
    validationSchema: QualitySchema,
    onSubmit: (values) => post(values),
  });

  if (isPostLoading || isDictionariesLoading || isLoading) {
    return <LoaderItem />;
  }

  return (
    <FormikProvider
      value={{
        ...formik,
        errors: createTouchedErrors(formik.errors, formik.touched),
      }}
    >
      <FieldsForm
        dictionaries={dictionaries}
        onCancel={() => {
          backWithFallback(`${GeoecologicalRootPath}/monitoring/water/quality`);
        }}
        media={media}
        onMediaChange={setMedia}
      />
    </FormikProvider>
  );
};
