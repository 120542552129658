import { Route } from 'react-router';
import React from 'react';
import { DictionariesList } from '../dictionaries/Dictionaries';
import { Dictionary } from '../dictionaries/Dictionary';
import { EditDictionaryItem } from '../dictionaries/Dictionary/DictionaryForms/EditDictionaryItem';
import { DictionaryAddItem } from '../dictionaries/Dictionary/DictionaryForms/DictionaryAddItem';
import { NSIRootPath } from '../dictionaries/const/routes';
import { AddUnit } from 'pages/dictionaries/MeasureUnits/Forms/AddUnit';
import { UnitAddRootPath, UnitEditRootPath, UnitRootPath } from 'pages/dictionaries/MeasureUnits/consts';
import { EditUnit } from 'pages/dictionaries/MeasureUnits/Forms/EditUnit';
import { MeasureUnits } from 'pages/dictionaries/MeasureUnits/MeasureUnits';
import { Parameters } from 'pages/dictionaries/Parameters';
import { AddParamPath, EditParamPath, ParameterRootPath } from 'pages/dictionaries/Parameters/consts';
import { AddParameter } from 'pages/dictionaries/Parameters/Forms/AddParameter';
import { EditParameter } from 'pages/dictionaries/Parameters/Forms/EditParameter';
import { AtmosphericAir } from '../dictionaries/AtmosphericAir';
import { EditAtmosphericAirData } from '../dictionaries/AtmosphericAir/EditAtmosphericAirData';
import {
  AtmosphericAirAddRootPath,
  AtmosphericAirEditRootPath,
  AtmosphericAirRootPath,
} from '../dictionaries/AtmosphericAir/consts';
import {
  GreenspacesAddAreaPath,
  GreenspacesAddLawnPath,
  GreenspacesAddTreePath,
  GreenspacesAreaPath,
  GreenspacesEditAreaPath,
  GreenspacesEditLawnPath,
  GreenspacesEditTreePath,
  GreenspacesLawnPath,
  GreenspacesTreePath,
} from 'pages/dictionaries/Greenspaces/const';
import { GreenspacesGrass } from 'pages/dictionaries/Greenspaces/GreenspacesGrass';
import { GreenspacesPlatforms } from 'pages/dictionaries/Greenspaces/GreenspacesPlatforms';
import { GreenspacesTree } from 'pages/dictionaries/Greenspaces/GreenspacesTree';
import { EditGreenspacesGrassData } from 'pages/dictionaries/Greenspaces/GreenspacesGrass/EditGreenspacesGrassData';
import { EditGreenspacesTreeData } from 'pages/dictionaries/Greenspaces/GreenspacesTree/EditGreenspacesTreeData';
import { EditGreenspacesPlatformsData } from 'pages/dictionaries/Greenspaces/GreenspacesPlatforms/EditGreenspacesPlatformsData';
import { NSISoil } from 'pages/dictionaries/Soil';
import { EditNSISoilData } from 'pages/dictionaries/Soil/EditNSISoilData';
import { NSISoilAddRootPath, NSISoilEditRootPath, NSISoilRootPath } from 'pages/dictionaries/Soil/consts';
import {
  NSIEmissionsIndustriesAddPath,
  NSIEmissionsIndustriesEditPath,
  NSIEmissionsIndustriesRootPath,
  NSIEmissionsRootPath,
  NSIEmissionsSourcesAddPath,
  NSIEmissionsSourcesEditPath,
  NSIEmissionsSourcesRootPath,
} from 'pages/dictionaries/NSIEmissions/consts/paths';
import { NSIEmissionsList } from 'pages/dictionaries/NSIEmissions/NSIEmissionsList';
import NSIEmissionsSources from 'pages/dictionaries/NSIEmissions/NSIEmissionsSources/NSIEmissionsSources';
import { Switch } from 'react-router-dom';
import NSIEmissionsSourcesAdd from 'pages/dictionaries/NSIEmissions/NSIEmissionsSources/NSIEmissionsSourcesForms/NSIEmissionsSourcesAdd';
import NSIEmissionsSourcesEdit from 'pages/dictionaries/NSIEmissions/NSIEmissionsSources/NSIEmissionsSourcesForms/NSIEmissionsSourcesEdit';
import NSIEmissionsIndustries from 'pages/dictionaries/NSIEmissions/NSIEmissionsIndustries/NSIEmissionsIndustries';
import NSIEmissionsIndustriesEdit from 'pages/dictionaries/NSIEmissions/NSIEmissionsIndustries/NSIEmissionsIndustriesForms/NSIEmissionsIndustriesEdit';
import NSIEmissionsIndustriesAdd from 'pages/dictionaries/NSIEmissions/NSIEmissionsIndustries/NSIEmissionsIndustriesForms/NSIEmissionsIndustriesAdd';
import {
  NSIReagentsGroundPaths,
  NSIReagentsLawnsPaths,
  NSIReagentsRootPath,
  NSIReagentsSitesPaths,
  NSIReagentsTreesPaths,
} from 'pages/dictionaries/NSIReagents/paths';
import NSIReagentsList from 'pages/dictionaries/NSIReagents/NSIReagentsList';
import NSIReagentsGround from 'pages/dictionaries/NSIReagents/NSIReagentsGround/NSIReagentsGround';
import NSIReagentsGroundAdd from 'pages/dictionaries/NSIReagents/NSIReagentsGround/NSIReagentsGroundForms/NSIReagentsGroundAdd';
import NSIReagentsGroundEdit from 'pages/dictionaries/NSIReagents/NSIReagentsGround/NSIReagentsGroundForms/NSIReagentsGroundEdit';
import NSIReagentsLawns from 'pages/dictionaries/NSIReagents/NSIReagentsLawns/NSIReagentsLawns';
import NSIReagentsLawnsAdd from 'pages/dictionaries/NSIReagents/NSIReagentsLawns/NSIReagentsLawnsForms/NSIReagentsLawnsAdd';
import NSIReagentsLawnsEdit from 'pages/dictionaries/NSIReagents/NSIReagentsLawns/NSIReagentsLawnsForms/NSIReagentsLawnsEdit';
import NSIReagentsTreesAdd from 'pages/dictionaries/NSIReagents/NSIReagentsTrees/NSIReagentsTreesForms/NSIReagentsTreesAdd';
import NSIReagentsTreesEdit from 'pages/dictionaries/NSIReagents/NSIReagentsTrees/NSIReagentsTreesForms/NSIReagentsTreesEdit';
import NSIReagentsTrees from 'pages/dictionaries/NSIReagents/NSIReagentsTrees/NSIReagentsTrees';
import NSIReagentsSites from 'pages/dictionaries/NSIReagents/NSIReagentsSites/NSIReagentsSites';
import NSIReagentsSitesAdd from 'pages/dictionaries/NSIReagents/NSIReagentsSites/NSIReagentsSitesForms/NSIReagentsSitesAdd';
import NSIReagentsSitesEdit from 'pages/dictionaries/NSIReagents/NSIReagentsSites/NSIReagentsSitesForms/NSIReagentsSitesEdit';
import NSIWaterList from 'pages/dictionaries/NSIWater/NSIWaterList';
import {
  NSIWaterGatesPaths,
  NSIWaterReservoirsPaths,
  NSIWaterRootPath,
  NSIWaterSitesPaths,
} from 'pages/dictionaries/NSIWater/paths';
import NSIWaterSites from 'pages/dictionaries/NSIWater/NSIWaterSites/NSIWaterSites';
import NSIWaterSitesAdd from 'pages/dictionaries/NSIWater/NSIWaterSites/NSIWaterSitesForms/NSIWaterSitesAdd';
import NSIWaterSitesEdit from 'pages/dictionaries/NSIWater/NSIWaterSites/NSIWaterSitesForms/NSIWaterSitesEdit';
import NSIWaterReservoirs from 'pages/dictionaries/NSIWater/NSIWaterReservoirs/NSIWaterReservoirs';
import NSIWaterReservoirsAdd from 'pages/dictionaries/NSIWater/NSIWaterReservoirs/NSIWaterReservoirsForms/NSIWaterReservoirsAdd';
import NSIWaterReservoirsEdit from 'pages/dictionaries/NSIWater/NSIWaterReservoirs/NSIWaterReservoirsForms/NSIWaterReservoirsEdit';
import NSIWaterGates from 'pages/dictionaries/NSIWater/NSIWaterGates/NSIWaterGates';
import NSIWaterGatesAdd from 'pages/dictionaries/NSIWater/NSIWaterGates/NSIWaterGatesForms/NSIWaterGatesAdd';
import NSIWaterGatesEdit from 'pages/dictionaries/NSIWater/NSIWaterGates/NSIWaterGatesForms/NSIWaterGatesEdit';
import { NSI_NMU_AddPath, NSI_NMU_EditPath, NSI_NMU_RootPath } from '../dictionaries/NMU/consts';
import { NMU } from '../dictionaries/NMU';
import { AddNMU } from '../dictionaries/NMU/AddNMU';
import { NSIDangerScale } from 'pages/dictionaries/NSIDangerScale/NSIDangerScale';
import { NSIDangerScalePaths } from 'pages/dictionaries/NSIDangerScale/const';
import { NSIDangerScaleForm } from 'pages/dictionaries/NSIDangerScale/NSIDangerScaleForm/NSIDangerScaleForm';
import { PermissibleNoiseLevel } from '../dictionaries/MASKSH/PermissibleNoiseLevel';
import {
  permissibleNoiseAddLevelPath,
  permissibleNoiseEditLevelPath,
  permissibleNoiseLevelPath,
} from '../dictionaries/MASKSH/PermissibleNoiseLevel/consts/consts';
import { EditPermissibleNoiseLevel } from '../dictionaries/MASKSH/PermissibleNoiseLevel/EditPermissibleNoiseLevel/EditPermissibleNoiseLevel';

const NSIRoutes: React.FC = () => (
  <Switch>
    <Route exact path={NSIRootPath} component={DictionariesList} />
    <Route exact path={UnitRootPath} component={MeasureUnits} />
    <Route exact path={UnitAddRootPath} component={AddUnit} />
    <Route exact path={UnitEditRootPath} component={EditUnit} />
    <Route exact path={ParameterRootPath} component={Parameters} />
    <Route exact path={AddParamPath} component={AddParameter} />
    <Route exact path={EditParamPath} component={EditParameter} />

    <Route exact path={AtmosphericAirRootPath} component={AtmosphericAir} />
    <Route exact path={AtmosphericAirAddRootPath} component={EditAtmosphericAirData} />
    <Route exact path={AtmosphericAirEditRootPath} component={EditAtmosphericAirData} />

    <Route exact path={GreenspacesLawnPath} component={GreenspacesGrass} />
    <Route exact path={GreenspacesAddLawnPath} component={EditGreenspacesGrassData} />
    <Route exact path={GreenspacesEditLawnPath} component={EditGreenspacesGrassData} />

    <Route exact path={GreenspacesAreaPath} component={GreenspacesPlatforms} />
    <Route exact path={GreenspacesAddAreaPath} component={EditGreenspacesPlatformsData} />
    <Route exact path={GreenspacesEditAreaPath} component={EditGreenspacesPlatformsData} />

    <Route exact path={GreenspacesTreePath} component={GreenspacesTree} />
    <Route exact path={GreenspacesAddTreePath} component={EditGreenspacesTreeData} />
    <Route exact path={GreenspacesEditTreePath} component={EditGreenspacesTreeData} />

    <Route exact path={NSISoilRootPath} component={NSISoil} />
    <Route exact path={NSISoilAddRootPath} component={EditNSISoilData} />
    <Route exact path={NSISoilEditRootPath} component={EditNSISoilData} />

    {/* МАСКШ Допустимые уровни шума */}
    <Route exact path={permissibleNoiseLevelPath} component={PermissibleNoiseLevel} />
    <Route exact path={permissibleNoiseAddLevelPath} component={EditPermissibleNoiseLevel} />
    <Route exact path={permissibleNoiseEditLevelPath} component={EditPermissibleNoiseLevel} />

    {/* Emissions */}
    <Route exact path={NSIEmissionsRootPath} component={NSIEmissionsList} />

    {/* Emissions sources (objects) */}
    <Route exact path={NSIEmissionsSourcesRootPath} component={NSIEmissionsSources} />
    <Route exact path={NSIEmissionsSourcesAddPath} component={NSIEmissionsSourcesAdd} />
    <Route exact path={`${NSIEmissionsSourcesEditPath}/:id`} component={NSIEmissionsSourcesEdit} />

    {/* Emissions industries */}
    <Route exact path={NSIEmissionsIndustriesRootPath} component={NSIEmissionsIndustries} />
    <Route exact path={NSIEmissionsIndustriesAddPath} component={NSIEmissionsIndustriesAdd} />
    <Route exact path={`${NSIEmissionsIndustriesEditPath}/:id`} component={NSIEmissionsIndustriesEdit} />

    {/* NMU */}
    <Route exact path={NSI_NMU_RootPath} component={NMU} />
    <Route exact path={NSI_NMU_AddPath} component={AddNMU} />
    <Route exact path={`${NSI_NMU_EditPath}/:id`} component={AddNMU} />

    {/* Противогололедные реагенты (ПГР) */}
    <Route exact path={NSIReagentsRootPath} component={NSIReagentsList} />

    <Route exact path={NSIReagentsGroundPaths.root} component={NSIReagentsGround} />
    <Route exact path={NSIReagentsGroundPaths.add} component={NSIReagentsGroundAdd} />
    <Route exact path={NSIReagentsGroundPaths.edit(':id')} component={NSIReagentsGroundEdit} />

    <Route exact path={NSIReagentsLawnsPaths.root} component={NSIReagentsLawns} />
    <Route exact path={NSIReagentsLawnsPaths.add} component={NSIReagentsLawnsAdd} />
    <Route exact path={NSIReagentsLawnsPaths.edit(':id')} component={NSIReagentsLawnsEdit} />

    <Route exact path={NSIReagentsTreesPaths.root} component={NSIReagentsTrees} />
    <Route exact path={NSIReagentsTreesPaths.add} component={NSIReagentsTreesAdd} />
    <Route exact path={NSIReagentsTreesPaths.edit(':id')} component={NSIReagentsTreesEdit} />

    <Route exact path={NSIReagentsSitesPaths.root} component={NSIReagentsSites} />
    <Route exact path={NSIReagentsSitesPaths.add} component={NSIReagentsSitesAdd} />
    <Route exact path={NSIReagentsSitesPaths.edit(':id')} component={NSIReagentsSitesEdit} />

    {/* Мониторинг водных объектов */}
    <Route exact path={NSIWaterRootPath} component={NSIWaterList} />

    <Route exact path={NSIWaterSitesPaths.root} component={NSIWaterSites} />
    <Route exact path={NSIWaterSitesPaths.add} component={NSIWaterSitesAdd} />
    <Route exact path={NSIWaterSitesPaths.edit(':id')} component={NSIWaterSitesEdit} />

    <Route exact path={NSIWaterReservoirsPaths.root} component={NSIWaterReservoirs} />
    <Route exact path={NSIWaterReservoirsPaths.add} component={NSIWaterReservoirsAdd} />
    <Route exact path={NSIWaterReservoirsPaths.edit(':id')} component={NSIWaterReservoirsEdit} />

    <Route exact path={NSIWaterGatesPaths.root} component={NSIWaterGates} />
    <Route exact path={NSIWaterGatesPaths.add} component={NSIWaterGatesAdd} />
    <Route exact path={NSIWaterGatesPaths.edit(':id')} component={NSIWaterGatesEdit} />

    {/* Ориентировочная оценочная шкала опасности загрязнения почв по суммарному показателю загрязнения */}
    <Route exact path={NSIDangerScalePaths.root} component={NSIDangerScale} />
    <Route exact path={NSIDangerScalePaths.add} component={NSIDangerScaleForm} />
    <Route exact path={NSIDangerScalePaths.edit(':id')} component={NSIDangerScaleForm} />

    <Route exact path={`${NSIRootPath}/:dictionaryId`} component={Dictionary} />
    <Route exact path={`${NSIRootPath}/:dictionaryId/add`} component={DictionaryAddItem} />
    <Route exact path={`${NSIRootPath}/:dictionaryId/:itemId/edit`} component={EditDictionaryItem} />
  </Switch>
);

export { NSIRoutes };
