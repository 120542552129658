import { useRequest } from 'hooks';
import { useEffect } from 'react';
import { IMainReport } from './types';
import { appealRoot, raidPlanned, raidSuccess } from 'pages/citizenAppeal';

export const useMainReport = (taskId?: string, taskStatus?: string) => {
  const { get, result, isLoading } = useRequest<IMainReport>(`${appealRoot}/tasks/${taskId}/main-report`);

  useEffect(() => {
    if ((taskId && taskStatus === raidPlanned) || (taskId && taskStatus === raidSuccess)) {
      get();
    }
  }, [get, taskId, taskStatus]);

  return {
    result,
    isLoading,
    get: () => {
      if ((taskId && taskStatus === raidPlanned) || (taskId && taskStatus === raidSuccess)) {
        get();
      }
    },
  };
};
