import { ERROR_MESSAGES, SUCCESS_MESSAGES } from 'constants/messages';
import { createTouchedErrors } from 'features/create-touched-errors';
import { FormikProvider, useFormik } from 'formik';
import { useNotifications } from 'hooks/useNotifications';
import { useRequest } from 'hooks/useRequest';
import { createParamApiPath, IParameterItem, ParameterRootPath, useParamsDictionaries } from '../consts';
import { ParamsSchema } from 'models';
import { ParamsFields } from './ParamsFields';
import { useGoBackWithFallback } from 'lib/utils/goBackWithFallback';

const initialValues = {
  id: '',
  name: '',
  fullName: '',
  code: '',
  dictionary: '',
  purpose: '',
  status: 'ACTIVE',
  fieldType: 'NUMERIC',
  relations: [
    {
      subsystemId: '',
      moduleId: '',
      unitId: '',
      unitDescription: '',
      accuracy: '',
      aliases: [''],
      inFile: true,
      inListing: true,
      fieldType: '',
      type: '',
    },
  ],
};

export const AddParameter = () => {
  const { result: dictionaries, isLoading: isDictLoading } = useParamsDictionaries();

  const { backWithFallback } = useGoBackWithFallback();

  const { successNotify, errorNotify } = useNotifications();
  const onSuccess = () => {
    successNotify({
      key: 'file/add/success',
      message: SUCCESS_MESSAGES.CREATE,
    });
    backWithFallback(ParameterRootPath);
  };

  const onError = () => {
    errorNotify({
      key: 'file/add/error',
      message: ERROR_MESSAGES.CREATE,
    });
  };
  const { post } = useRequest<IParameterItem, any>(createParamApiPath, onSuccess, onError);

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnMount: false,
    validateOnBlur: false,
    validationSchema: ParamsSchema,
    initialValues,
    onSubmit: (values) => {
      post(values);
    },
  });

  return (
    <FormikProvider
      value={{
        ...formik,
        errors: createTouchedErrors(formik.errors, formik.touched),
      }}
    >
      <ParamsFields dictionaries={dictionaries?.data} isLoading={isDictLoading} />
    </FormikProvider>
  );
};
