import React, { useEffect, useMemo } from 'react';
import { Box, Flex, Grid, GridItem } from '@chakra-ui/react';
import { Field } from 'formik';
import { Checkbox, DefaultText, Input, LoaderItem, Textarea } from 'components/common';
import { useRequest } from 'hooks/useRequest';
import { Complaint, getComplaintEndpoint } from 'pages/citizenAppeal';

interface Props {
  complaintId: string;
}

export const ComplaintInfo = ({ complaintId }: Props) => {
  const { get, result: complaint, isLoading } = useRequest<Complaint>(getComplaintEndpoint(complaintId));

  useEffect(() => {
    if (complaintId) {
      get();
    }
  }, [complaintId, get]);

  const answersText = useMemo(() => {
    let text = '';
    complaint?.answers?.forEach((answer) => {
      text += `Ответ №${answer.answerDocNumber}: ${answer.answerText} \n`;
    });
    return text;
  }, [complaint]);

  if (isLoading) {
    return <LoaderItem />;
  }

  return (
    <Box marginTop={3}>
      <Grid
        marginLeft={6}
        gap={2}
        marginBottom={2}
        templateRows={`repeat(${4}, 1fr)`}
        templateColumns="repeat(5, 1fr)"
      >
        <GridItem rowStart={1} rowEnd={1} colStart={1} colSpan={2}>
          <Field
            as={Input}
            name="complaintCitizenFio"
            value={complaint?.complaintCitizenFio}
            label="ФИО Заявителя"
            disabled
          />
        </GridItem>
        <GridItem rowStart={1} rowEnd={1} colStart={3} colSpan={1}>
          <Field
            as={Input}
            name="complaintCitizenEmail"
            value={complaint?.complaintCitizenEmail}
            label="Контакты (aaa@mail.ru)"
            disabled
          />
        </GridItem>
        <GridItem rowStart={1} rowEnd={1} colStart={4} colSpan={1}>
          <Field
            as={Input}
            name="complaintCitizenPhone"
            value={complaint?.complaintCitizenPhone}
            label="Контакты: +7(916) 111 1111"
            disabled
          />
        </GridItem>
        <GridItem rowStart={1} rowEnd={1} colStart={5} colSpan={1}>
          <Field
            as={Checkbox}
            label="Ответ на бумаге"
            name="answerOnPaper"
            isDisabled
            checked={complaint?.complaintAnswerOnPaper}
          />
        </GridItem>
        <GridItem rowStart={2} rowEnd={2} colStart={1} colSpan={4}>
          <Field
            as={Input}
            name="complaintCitizenAddress"
            value={complaint?.complaintCitizenAddress}
            label="Адрес заявителя"
            disabled
          />
        </GridItem>
        <GridItem rowStart={3} rowEnd={3} colStart={1} colSpan={4}>
          <Field
            as={Textarea}
            name="complaintText"
            value={complaint?.complaintText}
            label="Содержание обращения"
            disabled
          />
        </GridItem>
        <GridItem rowStart={4} rowEnd={4} colStart={1} colSpan={4}>
          <Field
            as={Textarea}
            name="answerText"
            value={answersText}
            label="Содержание ответа"
            disabled
          />
        </GridItem>
      </Grid>
      {
        complaint?.incidents?.map((incident, idx) => (
          <>
            <DefaultText fontWeight="bold">
              {`Происшествие ${idx + 1}`}
            </DefaultText>
            <Grid
              templateRows="repeat(1, 1fr)"
              templateColumns="repeat(5, 1fr)"
            >
              <GridItem rowStart={1} rowEnd={1} colStart={1} colSpan={4}>
                <Field
                  as={Textarea}
                  name={`incidents[${idx}].incidentAddress`}
                  value={incident.incidentAddress}
                  label="Адрес происшествия"
                  disabled
                />
              </GridItem>
              <GridItem rowStart={1} rowEnd={1} colStart={5} colSpan={1}>
                <Flex flexDirection="column" alignSelf="center">
                  <Field
                    as={Checkbox}
                    label="Повторная жалоба"
                    name={`incidents[${idx}].incidentRepetitionSign`}
                    isDisabled
                    checked={incident.incidentRepetitionSign}
                  />
                  <Field
                    as={Checkbox}
                    name={`incidents[${idx}].incidentCompletionSign`}
                    checked={incident.incidentCompletionSign}
                    isDisabled
                    label="Рейд был"
                  />
                </Flex>
              </GridItem>
            </Grid>
          </>
        ))
      }
    </Box>
  );
};
