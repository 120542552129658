import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import {
  CitizensAppealsPath,
  RAID_JOURNAL,
  RaidJournalAtmoAdd,
  RaidJournalPathAtmo,
  RaidJournalPathNoise,
} from '../consts/consts';
import React, { useEffect, useMemo } from 'react';
import { CustomRaidTable } from './RaidTable';
import { useList } from 'hooks/useList';
import { RaidFilter } from './RaidFilter';
import { LoaderItem, SubsystemHeader } from 'components/common';
import { getSelectOptionsFromDictionaryByName } from 'features/get-select-options-from-dictionary';
import { IFilterItem, TFiltersArray } from 'models';
import { useHistory } from 'react-router-dom';
import { IDay } from './types';
import { subsystemCodesMap } from 'constants/subsystemIdsMap';
import { useCustomRights } from 'hooks/useCustomRights';
import { NavTabList } from 'features/NavTabList';
import { useDictionary } from 'hooks/useDictionary';
import { useHandleDeleteData } from 'hooks';
import { FullPageWrapper } from 'components/table/FullPageWrapper';

export const RaidJournalAtmo = () => {
  const { isWrite, isExecute } = useCustomRights(subsystemCodesMap.citizenAppeals.raidsAir);
  useUpdateBreadcrumbs([
    {
      breadcrumb: 'Учет обращений граждан',
      path: CitizensAppealsPath,
    },
    {
      breadcrumb: 'Журнал Рейдов',
      path: RaidJournalPathAtmo,
    },
  ]);

  const history = useHistory();

  const { isRead: isNoiseRead } = useCustomRights(subsystemCodesMap.citizenAppeals.raidsNoise);
  const { isRead: isAtmoRead } = useCustomRights(subsystemCodesMap.citizenAppeals.raidsAir);

  const { setFilters, onPageChange, refetch, filterDTO, response, isLoading, toggleOrder, downloadAsFile } =
    useList<IDay>('/appeal/v10/raids/list', {
      preventFirstFetch: true,
      initialOrderFieldKey: '-startDate',
      downloadUrl: '/appeal/v10/raids/list/appeals-export',
      saveFiltersState: true,
      resetDataOnNextRequest: true,
    });

  const { isDictionariesLoading, dictionaries: tasksDictionaries } = useDictionary({
    url: '/appeal/v10/tasks/dictionaries',
  });

  const taskResearchTypes = useMemo(
    () => getSelectOptionsFromDictionaryByName(tasksDictionaries?.data, 'taskResearchTypes'),
    [tasksDictionaries],
  );

  const atmoID = taskResearchTypes.find((type) => type.name === 'Воздух')?.value;

  useEffect(() => {
    if (atmoID) {
      setFilters([
        ...filterDTO.filters,
        {
          key: 'typeId',
          operation: 'EQ',
          value: atmoID,
        },
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [atmoID]);

  const onSubmit = (values: { [s: string]: IFilterItem }) => {
    const filters: TFiltersArray = Object.values(values);
    setFilters(filters);
  };

  const { handleOpenModal, ModalComponent } = useHandleDeleteData({ refetch });

  return (
    <>
      {!isAtmoRead && !isNoiseRead ? null : (
        <>
          {isDictionariesLoading || !atmoID ? (
            <LoaderItem />
          ) : (
            <FullPageWrapper>
              <SubsystemHeader
                title={RAID_JOURNAL}
                addButtonProps={{
                  onClick: () => history.push(RaidJournalAtmoAdd),
                  isDisabled: !isWrite
                }}
                filtersComponent={
                  <RaidFilter
                    type={atmoID}
                    onSubmit={onSubmit}
                    dictionaries={tasksDictionaries}
                    isAir
                    filters={filterDTO.filters}
                  />
                }
                navigationTabs={
                  <NavTabList
                    tabs={[
                      {
                        label: 'Учет шумовой обстановки',
                        path: RaidJournalPathNoise,
                        isDisabled: !isNoiseRead,
                      },
                      {
                        label: 'Учет загрязнения атмосферы',
                        path: RaidJournalPathAtmo,
                        isDisabled: !isAtmoRead,
                      },
                    ]}
                  />
                }
              />

              <CustomRaidTable
                isWrite={isWrite}
                isExecute={isExecute}
                tasksDictionaries={tasksDictionaries}
                data={response}
                onPageChange={onPageChange}
                noise={false}
                page={filterDTO.offset / filterDTO.limit}
                limit={filterDTO.limit}
                toggleOrder={toggleOrder}
                orders={filterDTO.orders}
                downloadAsFile={downloadAsFile}
                refetch={refetch}
                handleOpenModal={handleOpenModal}
                isLoading={isLoading}
              />

              {ModalComponent}
            </FullPageWrapper>
          )}
        </>
      )}
    </>
  );
};
