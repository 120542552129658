import { useContext, useMemo } from 'react';
import { UserInfoContext } from 'features/AuthProvider';

export const useNodRights = (reportCode: string) => {
  const { customRights } = useContext(UserInfoContext);

  const isExecute = useMemo(() =>
    !!customRights
      ?.find((right) => right.code.includes(reportCode) && right.action === 'EXECUTE'), [customRights, reportCode]);

  return {
    isExecute
  };
};
