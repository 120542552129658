import { useDisclosure } from '@chakra-ui/hooks';
import { Modal } from 'components/common/Modals';
import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import { QualityItem } from 'hooks/geo/Quality/useQualityList';
import { TFiltersArray } from 'models';
import React, { useMemo } from 'react';
import { useList } from 'hooks/useList';
import appLocale from 'constants/appLocale';
import { GeoecologicalRootPath } from 'pages/data/GeoecologicalProcesses/consts';
import { GeologicalProcessesGroundwaterHeader } from '../components';
import { QualityFilter } from './QualityFilter';
import { QualityTable } from './Table';
import { UploadFileModalContent } from 'features/UploadFileDragAndDrop/UploadFileModalContent';
import { getSelectOptionsFromDictionaryByNameWithTransformation } from 'features/get-select-options-from-dictionary';
import subsystemIdsMap from 'constants/subsystemIdsMap';
import { useAvailability } from 'hooks';
import { usePeriodsDict } from 'features/PeriodsButtons/usePeriodsDict';
import { useDictionary } from 'hooks/useDictionary';
import { DEFAULT_PAGE_SIZE_NEW_TABLE } from '../../../../../constants/tables';
import { FullPageWrapper } from '../../../../../components/table/FullPageWrapper';

export const Quality = () => {
  const { isWrite, isReadOnly, isExecute, isNoRights, allAvailable } = useAvailability(subsystemIdsMap.geoecologyRoot);

  useUpdateBreadcrumbs([
    { breadcrumb: appLocale.common.data, path: '/data' },
    {
      breadcrumb: appLocale.subsystems.geoecologicalprocesses,
      path: GeoecologicalRootPath,
    },
    {
      breadcrumb: appLocale.subsystems.groundwater,
      path: `${GeoecologicalRootPath}/monitoring/water/examination`,
    },
  ]);

  const { isOpen: isOpenLoad, onClose: onCloseLoad, onOpen: onOpenLoad } = useDisclosure();

  const {
    refetch,
    isLoading: isQualityListLoading,
    response,
    setFilters,
    toggleOrder,
    onPageChange,
    filterDTO,
    downloadAsFile,
    saveFilters,
    errorText,
    selectedIndexes,
    renderOnlySelectedCheckbox,
    onSubmitFilters,
    onSelectionChange,
    materialTableProps,
  } = useList<QualityItem, keyof QualityItem>('/geoecology/v10/geoecology/watersource/quality/list', {
    initialOrderFieldKey: '-measuringDate',
    preventFirstFetch: true,
    exportSubsystemName: 'quality',
    saveFiltersState: true,
    selectionKey: 'id',
    defaultPageSize: DEFAULT_PAGE_SIZE_NEW_TABLE,
    resetDataOnNextRequest: true,
  });

  const { dictionaries, isDictionariesLoading } = useDictionary({ url: '/geoecology/v10/geoecology/watersource/quality/dictionaries' });

  const dataDict = dictionaries?.data;
  const parameters = useMemo(
    () => getSelectOptionsFromDictionaryByNameWithTransformation(dataDict, 'parameter', 'title'),
    [dataDict]
  );

  const { result: periods } = usePeriodsDict(`auth/v10/user/period/${subsystemIdsMap.geoGroundWater}`);

  return (
    <FullPageWrapper>
      <GeologicalProcessesGroundwaterHeader
        allAvailable={allAvailable}
        isWrite={isWrite}
        isNoRights={isNoRights}
        onOpenLoad={onOpenLoad}
        active="quality"
      >
        <QualityFilter
          filters={filterDTO?.filters || []}
          periods={periods}
          isLoading={isQualityListLoading}
          isDisabled={isQualityListLoading}
          dictionaries={dictionaries}
          onSubmit={(values) => {
            const filters: TFiltersArray<keyof QualityItem> = Object.values(values);
            onSubmitFilters(filters);
          }}
        >
          {renderOnlySelectedCheckbox}
        </QualityFilter>
      </GeologicalProcessesGroundwaterHeader>

      <Modal isOpen={isOpenLoad} onClose={onCloseLoad} header="Загрузка данных из файла">
        <UploadFileModalContent
          createTypesPath={() => '/geoecology/v10/geoecology/watersource/quality/load/types'}
          createUploadPath={() => '/geoecology/v10/geoecology/watersource/quality/load'}
          types={[
            {
              name: 'Данные качества подземных вод',
              value: 'quality',
            },
          ]}
          onClose={onCloseLoad}
          refetch={refetch}
        />
      </Modal>

      <QualityTable
        allAvailable={allAvailable}
        isExecute={isExecute}
        isWrite={isWrite}
        isReadonly={isReadOnly}
        isNoRights={isNoRights}
        setFilters={setFilters}
        filters={filterDTO.filters}
        refetch={refetch}
        qualityList={response}
        parameters={parameters}
        toggleOrder={toggleOrder}
        filterDTO={filterDTO}
        onPageChange={onPageChange}
        downloadAsFile={downloadAsFile}
        selectedIndexes={selectedIndexes}
        onSelectionChange={onSelectionChange}
        saveFilters={saveFilters}
        isLoading={isQualityListLoading || isDictionariesLoading}
        errorText={errorText}
        materialTableProps={materialTableProps}
      />
    </FullPageWrapper>
  );
};
