import { useAvailability, useDisclosure, useHandleDeleteData, useList } from 'hooks';
import { WPZDto } from 'pages/data/Water/WPZRoot/WPZ/consts/types';
import { WaterWPZApiPaths } from 'pages/data/Water/WPZRoot/WPZ/consts/apiPaths';
import { MaterialTable } from 'features/material-table/MaterialTableMini';
import React, { useMemo } from 'react';
import columns from 'pages/data/Water/WPZRoot/WPZ/WPZTable/columns';
import { WPZTableFilters } from 'pages/data/Water/WPZRoot/WPZ/WPZTable/WPZTableFilters';
import { PropActions } from 'features/material-table/components/Actions';
import { WaterRoutesWPZ } from 'pages/data/Water/routes';
import { DeleteIcon, EditIcon, ImageIcon } from 'components/icons';
import FilePreviewModalWrapper from 'components/common/FilePreviewModal/FilePreviewModalWrapper';
import useFilePreviewModalWrapperData from 'components/common/FilePreviewModal/hooks/useFilePreviewModalWrapperData';
import { useHistory } from 'react-router-dom';
import { Modal, SubsystemHeader } from 'components/common';
import appLocale from 'constants/appLocale';
import WPZSwitch from 'pages/data/Water/WPZRoot/WPZSwitch';
import { Column } from '@material-table/core';
import { addFiltersToColumns } from 'lib/utils/tableFilter';
import { usePeriodsDict } from 'features/PeriodsButtons/usePeriodsDict';
import subsystemIdsMap from 'constants/subsystemIdsMap';
import { getSelectOptionsFromDictionaryByName } from 'features/get-select-options-from-dictionary';
import { UploadFileModalContent } from 'features/UploadFileDragAndDrop/UploadFileModalContent';
import { useDictionary } from 'hooks/useDictionary';
import { DEFAULT_PAGE_SIZE_NEW_TABLE } from '../../../../../../constants/tables';
import { FullPageWrapper } from '../../../../../../components/table/FullPageWrapper';

export const WPZTable = () => {
  const history = useHistory();

  const { dictionaries, isDictionariesLoading } = useDictionary({ url: WaterWPZApiPaths.dictionaries });

  const regions = useMemo(
    () => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'regions'),
    [dictionaries?.data],
  );
  const areas = useMemo(() => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'areas'), [dictionaries?.data]);
  const districts = useMemo(
    () => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'districts'),
    [dictionaries?.data],
  );
  const siteConditions = useMemo(
    () => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'siteConditions'),
    [dictionaries],
  );

  const { open, ...filePreviewData } = useFilePreviewModalWrapperData();
  const { isWrite, isNoRights, allAvailable, isExecute, isDelete } = useAvailability(
    subsystemIdsMap.waterProtectionZone,
  );

  const {
    toggleOrder,
    onPageChange,
    response,
    setFilters,
    filterDTO,
    refetch,
    isLoading: isItemsLoading,
    downloadAsFile,
    onSearchText,
    saveFilters,
    errorText,
    selectedIndexes,
    renderOnlySelectedCheckbox,
    onSubmitFilters,
    onSelectionChange,
    materialTableProps,
  } = useList<WPZDto, keyof WPZDto>(WaterWPZApiPaths.list, {
    initialOrderFieldKey: '-date',
    preventFirstFetch: true,
    selectionKey: 'id',
    saveFiltersState: true,
    defaultPageSize: DEFAULT_PAGE_SIZE_NEW_TABLE,
    resetDataOnNextRequest: true,
  });

  const isLoading = useMemo(() => isDictionariesLoading || isItemsLoading, [isDictionariesLoading, isItemsLoading]);

  const { ModalComponent: ConfirmDeleteModal, handleOpenModal } = useHandleDeleteData({ refetch });

  const actions = useMemo<PropActions<WPZDto[]>>(
    () => [
      {
        position: 'row',
        action: (row) => ({
          icon: () => <EditIcon />,
          onClick: () => history.push(`${WaterRoutesWPZ.edit(row.id)}`),
          tooltip: 'Редактировать',
          hidden: !isWrite || !row.isEditable,
        }),
      },
      {
        position: 'row',
        action: (row) => ({
          icon: () => <DeleteIcon />,
          onClick: () => handleOpenModal({ url: WaterWPZApiPaths.getOrDelete(row.id) }),
          tooltip: 'Удалить',
          hidden: !isDelete || !row.isEditable,
        }),
      },
      {
        position: 'row',
        action: (row) => ({
          icon: () => <ImageIcon />,
          onClick: () => open(row.id, row.objectType),
          tooltip: 'Показать изображения',
          hidden: !row.objectType,
        }),
      },
    ],
    [handleOpenModal, history, isDelete, isWrite, open],
  );

  const selectOptions = useMemo(() => {
    const map = new Map();
    map.set('siteConditionId', siteConditions);
    return map;
  }, [siteConditions]);

  const columnsWithFilters = useMemo<Array<Column<WPZDto>>>(
    () =>
      addFiltersToColumns({
        filters: filterDTO.filters,
        setFilters,
        columns,
        selectOptions,
      }),
    [filterDTO.filters, selectOptions, setFilters],
  );

  const { result: periods } = usePeriodsDict(`auth/v10/user/period/${subsystemIdsMap.waterProtectionZone}`);

  const { isOpen: isOpenLoad, onClose: onCloseLoad, onOpen: onOpenLoad } = useDisclosure();
  const isAvailable = allAvailable || isNoRights || isWrite;

  return (
    <FullPageWrapper>
      <SubsystemHeader
        title={appLocale.subsystems.wpz}
        addButtonProps={{
          onClick: () => history.push(WaterRoutesWPZ.add),
          isDisabled: !isWrite
        }}
        navigationTabs={<WPZSwitch activePath={WaterRoutesWPZ.root} />}
        filtersComponent={
          <WPZTableFilters
            filters={filterDTO?.filters || []}
            periods={periods}
            isLoading={isLoading}
            isDisabled={isLoading}
            regions={regions}
            areas={areas}
            districts={districts}
            onSubmit={(FilterValues) => {
              onSubmitFilters(Object.values(FilterValues));
            }}
          >
            {renderOnlySelectedCheckbox}
          </WPZTableFilters>
        }
        onUploadClick={isAvailable ? onOpenLoad : undefined}
      />

      <MaterialTable
        isNotExecute={isExecute}
        saveFilters={saveFilters}
        toggleOrder={toggleOrder}
        onPageChange={onPageChange}
        filterDTO={filterDTO}
        data={response?.data}
        meta={response?.meta}
        columns={columnsWithFilters}
        actions={actions}
        options={{
          search: true,
        }}
        isLoading={isLoading}
        onSearch={onSearchText}
        downloadAsFile={downloadAsFile}
        selectedIndexes={selectedIndexes}
        onSelectionChange={onSelectionChange}
        errorText={errorText}
        {...materialTableProps}
        showTotalCount
      />

      {ConfirmDeleteModal}

      <FilePreviewModalWrapper {...filePreviewData} />

      <Modal isOpen={isOpenLoad} onClose={onCloseLoad} header="Загрузка данных из файла">
        <UploadFileModalContent
          createTypesPath={() => '/water/v10/water/wpz/types'}
          createUploadPath={() => '/water/v10/water/wpz/apply'}
          onClose={onCloseLoad}
          refetch={refetch}
        />
      </Modal>
    </FullPageWrapper>
  );
};
