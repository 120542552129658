import React, { useEffect, useMemo, useState } from 'react';
import { MaterialTable } from 'features/material-table/MaterialTableMini';
import { useList } from 'hooks/useList';
import FilePreviewModalWrapper from 'components/common/FilePreviewModal/FilePreviewModalWrapper';
import useFilePreviewModalWrapperData from 'components/common/FilePreviewModal/hooks/useFilePreviewModalWrapperData';
import { useMeasurementsTableColumns } from '../hooks';
import { IASKZVPackage } from '../types';
import { apiPathAskzv } from '../apiPath';
import { parametersIds } from '../constants';
import { WaterASKZVFilters } from '../WaterASKZVFilters';
import { Column } from '@material-table/core';
import { addFiltersToColumns } from 'lib/utils/tableFilter';
import { usePeriodsDict } from 'features/PeriodsButtons/usePeriodsDict';
import subsystemIdsMap from 'constants/subsystemIdsMap';
import useProtocolModal from 'hooks/useProtocolModal';
import { SubsystemHeader } from 'components/common';
import appLocale from 'constants/appLocale';
import { useDictionary } from 'hooks/useDictionary';
import { getSelectOptionsFromDictionaryByName } from 'features/get-select-options-from-dictionary';
import { restorePreviewColumns } from 'components/common/Modals/ProtocolModal';
import { DEFAULT_PAGE_SIZE_NEW_TABLE } from '../../../../../constants/tables';
import { FullPageWrapper } from '../../../../../components/table/FullPageWrapper';

export const ASKZVMeasurement = () => {
  const { dictionaries, isDictionariesLoading } = useDictionary({ url: apiPathAskzv.dict });
  const sources = useMemo(() => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'sources'), [dictionaries]);
  const fire = useMemo(() => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'fire'), [dictionaries]);
  const door = useMemo(() => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'door'), [dictionaries]);
  const event = useMemo(() => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'event'), [dictionaries]);
  const parameters = useMemo(
    () => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'parameters'),
    [dictionaries]
  );

  const {
    isLoading: isItemsLoading,
    response,
    toggleOrder,
    refetch,
    setFilters,
    onPageChange,
    filterDTO,
    downloadAsFile,
    onSearchText,
    saveFilters,
    errorText,
    selectedIndexes,
    renderOnlySelectedCheckbox,
    onSelectionChange,
    onSubmitFilters,
    materialTableProps,
  } = useList<IASKZVPackage, keyof IASKZVPackage>(apiPathAskzv.measurements, {
    initialOrderFieldKey: '-datetime',
    exportSubsystemName: 'askzv',
    preventFirstFetch: true,
    selectionKey: 'id',
    defaultPageSize: DEFAULT_PAGE_SIZE_NEW_TABLE,
    resetDataOnNextRequest: true,
  });

  const { openModal, protocolModal } = useProtocolModal<IASKZVPackage>({
    onClose: refetch,
    dateKey: 'datetime',
    previewColumns: restorePreviewColumns.water,
    baseUrl: '/water/v10/water/askzv',
    secondBaseUrl: '/water/v10/water/askzv'
  });

  const columns = useMeasurementsTableColumns({ openModal });

  const columnsWithFilters = useMemo<Array<Column<IASKZVPackage>>>(
    () => addFiltersToColumns({ filters: filterDTO.filters, setFilters, columns }),
    [columns, filterDTO.filters, setFilters]
  );

  const { open, ...filePreviewData } = useFilePreviewModalWrapperData();

  const [enhancedData, setEnhancedData] = useState<any[] | null>(null);

  const responseData = response?.data;

  useEffect(() => {
    if (responseData && event && fire && door) {
      const newData = responseData.map((dataRow) => {
        if (dataRow.parameterId === parametersIds.doorId) {
          const foundItem = door.find((dict) => dict.value === dataRow.value);
          if (foundItem) {
            dataRow.value = foundItem.name;
          }
        }
        if (dataRow.parameterId === parametersIds.fireId) {
          const foundItem = fire.find((dict) => dict.value === dataRow.value);
          if (foundItem) {
            dataRow.value = foundItem.name;
          }
        }
        if (dataRow.parameterId === parametersIds.eventId) {
          const foundItem = event.find((dict) => dict.value === dataRow.value);
          if (foundItem) {
            dataRow.value = foundItem.name;
          }
        }

        return dataRow;
      });
      setEnhancedData(newData);
    } else {
      setEnhancedData(null);
    }
  }, [door, event, fire, responseData]);

  const isLoading = useMemo(() => isItemsLoading || isDictionariesLoading, [isDictionariesLoading, isItemsLoading]);

  const { result: periods } = usePeriodsDict(`auth/v10/user/period/${subsystemIdsMap.waterRoot}`);

  return (
    <FullPageWrapper>
      <SubsystemHeader
        title={appLocale.protocols.askzv}
        filtersComponent={
          <WaterASKZVFilters
            filters={filterDTO?.filters || []}
            periods={periods}
            parameters={parameters}
            sources={sources}
            isLoading={isLoading}
            isDisabled={isLoading}
            onSubmit={(values) => onSubmitFilters(Object.values(values))}
          >
            {renderOnlySelectedCheckbox}
          </WaterASKZVFilters>
        }
      />

      <MaterialTable
        saveFilters={saveFilters}
        filterDTO={filterDTO}
        data={enhancedData || undefined}
        meta={response?.meta}
        columns={columnsWithFilters}
        toggleOrder={toggleOrder}
        onPageChange={onPageChange}
        isLoading={isLoading}
        options={{
          search: true,
        }}
        onSearch={onSearchText}
        downloadAsFile={downloadAsFile}
        selectedIndexes={selectedIndexes}
        onSelectionChange={onSelectionChange}
        errorText={errorText}
        {...materialTableProps}
      />

      <FilePreviewModalWrapper {...filePreviewData} />

      {protocolModal}
    </FullPageWrapper>
  );
};
