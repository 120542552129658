import { createTouchedErrors } from 'features/create-touched-errors';
import { FormikProvider, useFormik } from 'formik';
import { useRequest } from 'hooks/useRequest';
import { useHistory, useParams } from 'react-router-dom';
import FieldsForm from 'pages/analytics/forms/BottomSoil/AnalyticsBottomSoilFieldsForm';
import { useCallback, useEffect, useState } from 'react';
import { updateFiles } from 'api/services/media';
import { IUploadMedia } from 'components/common/FileUploadDragAndDrop/types';
import { useObjectType } from 'hooks/useObjectType';
import { IBottomSoilDTO } from 'pages/data/Water/BottomSoil/consts/types';
import { BottomSoilApiPaths } from 'pages/data/Water/BottomSoil/consts/apiPaths';
import { bottomSoilInitialEmptyValues } from './initialEmptyValues';
import { useEnhancedNotification } from 'hooks';
import { AnalyticsBottomSoilSchema } from 'models/schemas/analytics/analyticsBottomSoilSchema';
import { useGoBackWithFallback } from 'lib/utils/goBackWithFallback';
import { useDictionary } from 'hooks/useDictionary';

export const AnalyticsBottomSoilEdit = () => {
  const history = useHistory();
  const { backWithFallback } = useGoBackWithFallback();
  const { id } = useParams<{ id: string }>();
  const { dictionaries, isDictionariesLoading } = useDictionary({ url: BottomSoilApiPaths.dictionaries });
  const objectType = useObjectType(dictionaries);
  const { onSuccessEdit, onErrorEdit } = useEnhancedNotification();
  const [media, setMedia] = useState<IUploadMedia[]>([]);
  const [editedObject, setEditedObject] = useState<IBottomSoilDTO>({
    ...bottomSoilInitialEmptyValues,
    ...history.location.state,
  });

  const onItemLoad = useCallback((response) => {
    if (response) {
      setEditedObject(response);
    }
  }, []);

  const { get, isLoading } = useRequest<IBottomSoilDTO>(
    `${BottomSoilApiPaths.getOrDelete(id)}?laboratory=true`,
    onItemLoad,
  );

  useEffect(() => {
    if (!history.location.state) {
      void get();
    }
  }, [get, history.location.state]);

  const onSuccess = useCallback(() => {
    onSuccessEdit();

    updateFiles(editedObject.id, objectType, media)
      .then(() => {
        backWithFallback('/analytics');
      })
      .catch((error: Error) => {
        onErrorEdit(error.message);
      });
  }, [backWithFallback, editedObject.id, media, objectType, onErrorEdit, onSuccessEdit]);

  const { put } = useRequest<IBottomSoilDTO>(BottomSoilApiPaths.root, onSuccess, onErrorEdit);

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    initialValues: editedObject,
    onSubmit: (values) => {
      void put(values);
    },
    validationSchema: AnalyticsBottomSoilSchema,
  });

  return (
    <FormikProvider
      value={{
        ...formik,
        errors: createTouchedErrors(formik.errors, formik.touched),
      }}
    >
      <FieldsForm
        dictionaries={dictionaries}
        onCancel={() => {
          backWithFallback('/analytics');
        }}
        isLoading={isDictionariesLoading || isLoading}
        onMediaChange={setMedia}
        media={media}
        uploadItemId={id}
        isEditMode
        editedObject={editedObject}
      />
    </FormikProvider>
  );
};
