import { useList } from 'hooks/useList';
import { IFloraAreaItem, TFloraAreaFilterDTO, TFloraAreaFilterType } from '../types';
import {
  createGreenPlantsAddUrl,
  GreenPlantsApiRootPath,
  GreenPlantsAreasFlora,
  GreenPlantsPlatformUrl,
  GreenPlantsRootPath,
} from 'pages/routes/GreenPlants';
import { useAreaFloraListColumns } from './useAreaListColumns';
import { useFloraDictionaries } from '../../Flora/useFloraDictionaries';
import { AreaHeader } from '../components/AreaHeader';
import { useAvailability, useHandleDeleteData } from 'hooks';
import subsystemIdsMap from 'constants/subsystemIdsMap';
import { PropActions } from 'features/material-table/components/Actions';
import React, { useMemo } from 'react';
import { useHistory } from 'react-router';
import { AreaFloraFilter } from '../components/AreaFloraFilter';
import { transformFiltersArray } from 'features/Filter/transformFiltersArray';
import { createDateToRequestForFilter } from 'lib/create-date';
import { MaterialTable } from 'features/material-table/MaterialTableMini';
import { usePeriodsDict } from 'features/PeriodsButtons/usePeriodsDict';
import { DeleteIcon, EditIcon } from 'components/icons';
import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import appLocale from 'constants/appLocale';
import { DEFAULT_PAGE_SIZE_NEW_TABLE } from '../../../../../constants/tables';
import { FullPageWrapper } from '../../../../../components/table/FullPageWrapper';

export const FloraAreas = () => {
  useUpdateBreadcrumbs([
    { breadcrumb: appLocale.common.data, path: '/data' },
    {
      breadcrumb: appLocale.subsystems.greenplants,
      path: GreenPlantsRootPath,
    },
    {
      breadcrumb: appLocale.subsystems.platforms,
      path: GreenPlantsPlatformUrl,
    },
    {
      breadcrumb: 'Деревья и кустарники',
      path: GreenPlantsAreasFlora,
    },
  ]);
  const history = useHistory();
  const { isWrite, isExecute, isNoRights, allAvailable } = useAvailability(subsystemIdsMap.greenplantsFlora);
  const {
    refetch,
    response,
    isLoading: isListLoading,
    filterDTO,
    toggleOrder,
    setFilters,
    onPageChange,
    downloadAsFile,
    saveFilters,
    onSearchText,
    errorText,
    selectedIndexes,
    renderOnlySelectedCheckbox,
    onSubmitFilters,
    onSelectionChange,
    materialTableProps,
  } = useList<IFloraAreaItem, TFloraAreaFilterType>(`${GreenPlantsApiRootPath}/flora/areas/list`, {
    initialOrderFieldKey: '-date',
    preventFirstFetch: true,
    exportSubsystemName: 'zn_areas',
    selectionKey: 'registryId',
    saveFiltersState: true,
    defaultPageSize: DEFAULT_PAGE_SIZE_NEW_TABLE,
    resetDataOnNextRequest: true,
  });

  const { dictionaries } = useFloraDictionaries();
  const columns = useAreaFloraListColumns(dictionaries.areaParameters);

  const { ModalComponent, handleOpenModal } = useHandleDeleteData({ refetch });

  const initialActions = useMemo<PropActions<IFloraAreaItem[]>>(
    () => [
      {
        position: 'row',
        action: (row: IFloraAreaItem) => ({
          icon: () => <EditIcon />,
          onClick: () => history.push(`${GreenPlantsRootPath}/areas/flora/edit/${row.registryId}`, row),
          tooltip: 'Редактировать',
          hidden: !row.isEditable,
        }),
      },
      {
        position: 'row',
        action: (row: IFloraAreaItem) => ({
          icon: () => <DeleteIcon />,
          onClick: () => handleOpenModal({ url: `${GreenPlantsApiRootPath}/flora/areas/${row.registryId}` }),
          tooltip: 'Удалить',
          hidden: !row.isEditable,
        }),
      },
    ],
    [history, handleOpenModal],
  );

  const actions = useMemo<PropActions<any[]>>(() => {
    if (allAvailable || isNoRights || isWrite) {
      return initialActions;
    }

    return initialActions.filter((action: any) => action.type !== 'delete');
  }, [isWrite, initialActions, isNoRights, allAvailable]);

  const { result: periods } = usePeriodsDict(`auth/v10/user/period/${subsystemIdsMap.greenplantsFlora}`);

  return (
    <FullPageWrapper>
      <AreaHeader
        allAvailable={allAvailable}
        isWrite={isWrite}
        isNoRights={isNoRights}
        handleAdd={() => {
          history.push(createGreenPlantsAddUrl('area'), { type: 'flora' });
        }}
      >
        <AreaFloraFilter
          periods={periods}
          dictionaries={dictionaries}
          isLoading={isListLoading}
          filters={filterDTO.filters}
          onSubmit={({ dateFrom, dateTo, ...values }) => {
            const filters = transformFiltersArray<TFloraAreaFilterDTO['filters']>(
              Object.values({
                ...values,
                dateFrom: {
                  ...dateFrom,
                  value: createDateToRequestForFilter(String(dateFrom.value), 'start'),
                },
                dateTo: {
                  ...dateTo,
                  value: createDateToRequestForFilter(String(dateTo.value), 'end'),
                },
                disabled: {
                  key: 'deleted',
                  operation: 'EQ',
                  value: false,
                },
              }),
            );
            onSubmitFilters(filters);
          }}
        >
          {renderOnlySelectedCheckbox}
        </AreaFloraFilter>
      </AreaHeader>
      <MaterialTable
        actions={actions}
        isNotExecute={isExecute || isNoRights}
        data={response?.data}
        meta={response?.meta}
        columns={columns}
        filterDTO={filterDTO}
        isLoading={isListLoading}
        toggleOrder={toggleOrder}
        onPageChange={onPageChange}
        onSearch={onSearchText}
        downloadAsFile={downloadAsFile}
        selectedIndexes={selectedIndexes}
        onSelectionChange={onSelectionChange}
        saveFilters={saveFilters}
        setFilters={setFilters}
        errorText={errorText}
        {...materialTableProps}
        showTotalCount
      />
      {ModalComponent}
    </FullPageWrapper>
  );
};
