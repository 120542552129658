import { ChangeEvent, useMemo } from 'react';
import { Field, useFormikContext } from 'formik';
import { COMMON_LOCALE } from 'constants/common';
import { AttachmentsTabContent, Button, DateInput, FormWrapper, Input, Select, Textarea } from 'components/common';
import { Box, Grid, Heading } from '@chakra-ui/react';
import { getSelectRichOptionsFromDictionaryByName } from 'features/get-select-options-from-dictionary';
import { createDateToRequestWithTimezone as createDateToRequest } from 'lib/create-date';
import type { TDictionariesArray } from 'models';

import { fieldInstructionLocale as fieldLabel, InsctructionForm, MASKSHInstructions } from 'pages/data/MASKSH/consts';
import { IUploadMedia } from 'components/common/FileUploadDragAndDrop/types';
import { useObjectType } from 'hooks/useObjectType';
import { UploadFileType } from 'components/common/FileUploadDragAndDrop/FileUploadDragAndDrop';
import { useGoBackWithFallback } from 'lib/utils/goBackWithFallback';

interface IProps {
  dictionaries: {
    data: TDictionariesArray;
  } | null;
  media: IUploadMedia[];
  setMedia: (media: IUploadMedia[]) => void;
  uploadItemId?: string;
  isViewMode?: boolean;
  isExists?: boolean;
}

export const InstructionFormFields = ({
  dictionaries, media, setMedia, uploadItemId, isViewMode, isExists
}: IProps) => {
  const { backWithFallback } = useGoBackWithFallback();
  const { setFieldValue, values, submitForm, errors } = useFormikContext<InsctructionForm>();
  const objectType = useObjectType(dictionaries);
  const stations = useMemo(
    () => getSelectRichOptionsFromDictionaryByName(dictionaries?.data, 'stations')
    .filter((it) => isExists || !it.deleted),
    [dictionaries, isExists],
  );
  const regions = useMemo(
    () => getSelectRichOptionsFromDictionaryByName(dictionaries?.data, 'regions')
    .filter((it) => isExists || !it.deleted),
    [dictionaries, isExists],
  );
  const districts = useMemo(
    () => getSelectRichOptionsFromDictionaryByName(dictionaries?.data, 'districts')
    .filter((it) => isExists || !it.deleted),
    [dictionaries, isExists],
  );

  const noiseSourceType = useMemo(
    () =>
      getSelectRichOptionsFromDictionaryByName(dictionaries?.data, 'noiseSourceType')
      .filter((it) => isExists || !it.deleted),
    [dictionaries, isExists],
  );

  const territoryType = useMemo(
    () => getSelectRichOptionsFromDictionaryByName(dictionaries?.data, 'territoryType')
    .filter((it) => isExists || !it.deleted),
    [dictionaries, isExists],
  );

  const wayType = useMemo(
    () => getSelectRichOptionsFromDictionaryByName(dictionaries?.data, 'wayType')
    .filter((it) => isExists || !it.deleted),
    [dictionaries, isExists],
  );

  const filteredDistrictByRegionId = useMemo(() => {
    let result = districts;
    if (values?.regionId) {
      result = districts.filter((item) => item?.parent === values?.regionId);
    }
    return result;
  }, [districts, values.regionId]);

  return (
    <FormWrapper>
      <Grid gap={2} pb={4}>
        <Heading mb={1} mt={4} size="sm">
          Реквизиты поручения
        </Heading>
        <Field
          as={DateInput}
          name="instructionDate"
          label={fieldLabel.instructionDate}
          value={values.instructionDate}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setFieldValue('instructionDate', createDateToRequest(e.target.value))
          }
          error={errors.instructionDate}
          isDisabled={isViewMode}
        />

        <Field
          as={Input}
          label={fieldLabel.number}
          name="number"
          value={values.number}
          error={errors.number}
          isDisabled={isViewMode}
        />
        <Field
          as={Textarea}
          label={fieldLabel.description}
          name="description"
          value={values.description}
          isDisabled={isViewMode}
        />
        <Heading mb={1} mt={2} size="sm">
          Описание контролируемого объекта
        </Heading>
        <Field
          as={Input}
          label={fieldLabel.name}
          name="name"
          value={values.name}
          error={errors.name}
          isDisabled={isViewMode}
        />
        <Field
          as={Select}
          name="regionId"
          label={fieldLabel.regionId}
          value={values.regionId}
          options={regions}
          onChange={(e: ChangeEvent<HTMLSelectElement>) => {
            setFieldValue('regionId', e.target.value);
          }}
          isDisabled={!regions?.length || isViewMode}
        />
        <Field
          as={Select}
          name="districtId"
          label={fieldLabel.districtId}
          value={values.districtId}
          options={filteredDistrictByRegionId}
          onChange={(e: ChangeEvent<HTMLSelectElement>) => {
            setFieldValue('districtId', e.target.value);
          }}
          isDisabled={!districts?.length || !values?.regionId || isViewMode}
        />
        <Field as={Input} label={fieldLabel.address} name="address" value={values.address} isDisabled={isViewMode} />
        <Field
          as={Select}
          label={fieldLabel.noiseSource}
          name="noiseSourceTypeId"
          value={values.noiseSourceTypeId}
          options={noiseSourceType}
          onChange={(e: ChangeEvent<HTMLSelectElement>) => {
            setFieldValue('noiseSourceTypeId', e.target.value);
          }}
          isDisabled={isViewMode}
        />
        <Heading mb={1} mt={2} size="sm">
          Сведения о станции, размещенной на контролируемом объекте в рамках данного поручения
        </Heading>
        <Field
          as={Select}
          name="stationId"
          label={fieldLabel.stationId}
          value={values.stationId}
          options={stations}
          onChange={(e: ChangeEvent<HTMLSelectElement>) => {
            setFieldValue('stationId', e.target.value);
          }}
          error={errors.stationId}
          isDisabled={!stations?.length || isViewMode}
        />
        <Field as={Input} label={fieldLabel.latitude} name="latitude" value={values.latitude} isDisabled={isViewMode} />
        <Field
          as={Input}
          label={fieldLabel.longitude}
          name="longitude"
          value={values.longitude}
          isDisabled={isViewMode}
        />
        <Field
          as={Input}
          label={fieldLabel.placementAddress}
          name="stationAddress"
          value={values.stationAddress}
          isDisabled={isViewMode}
        />
        <Field
          as={DateInput}
          name="startDate"
          label={fieldLabel.startDate}
          value={values.startDate}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setFieldValue('startDate', createDateToRequest(e.target.value))
          }
          error={errors.startDate}
          showTimeInput
          isDisabled={isViewMode}
        />
        <Field
          as={DateInput}
          name="stopDate"
          label={fieldLabel.stopDate}
          value={values.stopDate}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setFieldValue('stopDate', createDateToRequest(e.target.value))
          }
          showTimeInput
          isDisabled={isViewMode}
        />
        <Field
          as={Select}
          label={fieldLabel.territoryType}
          name="territoryTypeId"
          value={values.territoryTypeId}
          options={territoryType}
          onChange={(e: ChangeEvent<HTMLSelectElement>) => {
            setFieldValue('territoryTypeId', e.target.value);
          }}
          isDisabled={isViewMode}
        />
        <Field
          as={Select}
          label={fieldLabel.wayType}
          name="wayTypeId"
          value={values.wayTypeId}
          options={wayType}
          onChange={(e: ChangeEvent<HTMLSelectElement>) => {
            setFieldValue('wayTypeId', e.target.value);
          }}
          isDisabled={isViewMode}
        />
        <Field as={Input} label={fieldLabel.note} name="comment" value={values.comment} isDisabled={isViewMode} />
        {!isViewMode && (
          <>
            <AttachmentsTabContent
              objectType={objectType}
              media={media}
              onMediaChange={setMedia}
              uploadItemId={uploadItemId}
              uploadFileTypes={[UploadFileType.image, UploadFileType.pdf, UploadFileType.doc]}
            />
            <Box mt={3}>
              <Button
                onClick={() => {
                  backWithFallback(MASKSHInstructions);
                }}
                variant="solid"
                marginRight={20}
              >
                {COMMON_LOCALE.cancel}
              </Button>
              <Button onClick={submitForm} variant="solid">
                {COMMON_LOCALE.save}
              </Button>
            </Box>
          </>
        )}
      </Grid>
    </FormWrapper>
  );
};
