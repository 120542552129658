import { GreenPlantsApiRootPath, GreenPlantsRootPath } from 'pages/routes/GreenPlants';
import { useNotifications } from 'hooks/useNotifications';
import { useRequest } from 'hooks/useRequest';
import { forEach, reduce, values } from 'lodash';
import { ApiError } from 'models';
import { useCallback } from 'react';
import { SUCCESS_MESSAGES } from 'constants/messages';
import { TGreenPlantsForm, TParamsToPost } from 'pages/data/GreenPlants/Form/types';

const useLawnsEditItem = () => {
  const { successNotify, errorNotify } = useNotifications();

  const { result, isLoading, error, put } = useRequest<any, ApiError & { descriptions: Array<{ message: string }> }>(
    `${GreenPlantsApiRootPath}/lawns`,
    () => {
      successNotify({
        key: `${GreenPlantsRootPath}/lawns/edit/success`,
        message: SUCCESS_MESSAGES.EDIT,
      });
    },
    (error) => {
      forEach(error?.descriptions, (desc) => {
        errorNotify({
          key: `${GreenPlantsRootPath}/lawns/edit/error`,
          message: desc.message,
        });
      });
    }
  );

  const editLawns = useCallback(
    (request: Partial<TGreenPlantsForm>['lawns']) => {
      const parameters = reduce(
        values(request?.parameters),
        (acc, current) => {
          if (current) {
            if (current.parameterId) {
              let currentValues = [];
              if (Array.isArray(current.value)) {
                currentValues = current.value.map((val, idx) => {
                  const values: TParamsToPost = {
                    parameterId: current.parameterId,
                    value: val,
                  };
                  if (current.dictionaryItemId && current.dictionaryItemId[idx]) {
                    values.dictionaryItemId = current.dictionaryItemId[idx];
                  }
                  if (current.ids && current.ids[idx]) {
                    values.id = current.ids[idx];
                  }
                  return values;
                });
              } else {
                currentValues = [
                  {
                    id: current.ids ? current.ids[0] : null,
                    dictionaryItemId: current.dictionaryItemId ? current.dictionaryItemId[0] : null,
                    value: current.value,
                    parameterId: current.parameterId,
                  },
                ];
                if (current.dictionaryItemId && current.dictionaryItemId[0]) {
                  const [dictionaryItemId] = current.dictionaryItemId;
                  currentValues[0].dictionaryItemId = dictionaryItemId;
                }
              }

              return { ...acc, [current.parameterId]: currentValues };
            }
          }
          return acc;
        },
        {}
      );

      return put({ ...request, parameters });
    },
    [put]
  );

  return { editLawns, result, isLoading, error };
};

export { useLawnsEditItem };
