import React, { useCallback, useState } from 'react';
import { FieldsForm } from './FieldsForm';
import { FormikProvider, useFormik, useFormikContext } from 'formik';
import {
  ADD_MEDIA_ERROR,
  ADD_MEDIA_SUCCESS,
  ADD_RESPONSE_ERROR,
  ADD_RESPONSE_SUCCESS,
  AnswerValues,
  AppealValues,
  Complaint,
} from 'pages/citizenAppeal';
import { TDictionariesArray } from 'models/dictionaries';
import { useRequest } from 'hooks/useRequest';
import { useNotifications } from 'hooks/useNotifications';
import { createTouchedErrors } from 'features/create-touched-errors';
import { answerAdd, getAnswerEditEndpoint } from '../../../consts/apiPaths';
import { IUploadMedia } from 'components/common/FileUploadDragAndDrop/types';
import { updateFiles, UploadObjectType } from 'api/services/media';

interface Props {
  dictionaries?: TDictionariesArray;
  incident: Complaint | null;
  complaint: Complaint | null;
}

export const AddResponseForm = ({ dictionaries, incident, complaint }: Props) => {
  const [media, setMedia] = useState<IUploadMedia[][]>([[]]);
  const { values: appealValues } = useFormikContext<AppealValues>();
  const { successNotify, errorNotify } = useNotifications();
  const [answer, setAnswer] = useState<AnswerValues | null>(null);

  const onMediaSuccess = useCallback(() => {
    successNotify({
      key: 'file/add-media/success',
      message: ADD_MEDIA_SUCCESS,
    });
  }, [successNotify]);

  const onMediaError = useCallback((message: string) => {
    errorNotify({
      key: 'file/add-media/error',
      message: `${ADD_MEDIA_ERROR}. ${message}`
    });
  }, [errorNotify]);

  const onSuccess = (answer: AnswerValues | null) => {
    successNotify({
      key: 'file/add/success',
      message: ADD_RESPONSE_SUCCESS,
    });
    if (answer) {
      setAnswer(answer);
    }
  };

  const onError = () =>
    errorNotify({
      key: 'file/add/error',
      message: ADD_RESPONSE_ERROR,
    });

  const { post } = useRequest(answerAdd, onSuccess, onError);
  const { put } = useRequest(getAnswerEditEndpoint(complaint?.complaintId), onSuccess, onError);

  const initialEmptyValues = {
    answers: [
      {
        answerAddress: '',
        answerAgreementDate: '',
        answerAgreementSet: '',
        answerAssignmentKind: '',
        answerDocDate: '',
        answerDocNumber: '',
        answerId: null,
        answerStoreId: null,
        answerText: '',
        answerUrl: ''
      }
    ],
    complaintAnswered: false,
    complaintId: appealValues.complaintId,
    complaintStatusId: incident?.complaintStatusId
  };

  const onSave = useCallback(async (saveAnswer: () => any) => {
    const response = await saveAnswer();
    if (response && response.answers) {
      response?.answers?.forEach((answer: any, idx: number) => {
        if (media[idx]) {
          updateFiles(answer.answerId, UploadObjectType.ANSWER, media[idx])
            .then((response?: IUploadMedia[]) => {
              setMedia((prevState) => {
                const newMedia = [...prevState];
                if (response) {
                  newMedia[idx] = response;
                }
                return newMedia;
              });
              onMediaSuccess();
            })
            .catch((error: Error) => {
              onMediaError(error.message);
            });
        }
      });
    }
  }, [media, onMediaError, onMediaSuccess]);

  const onSaveAnswer = (values: any) => {
    if (answer) {
      onSave(() => put(values));
    } else {
      onSave(() => post(values));
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    initialValues: { ...initialEmptyValues, ...answer },
    onSubmit: (values: any) => {
      onSaveAnswer(values);
    }
  });

  return (
    <FormikProvider
      value={{
        ...formik,
        errors: createTouchedErrors(formik.errors, formik.touched),
      }}
    >
      <FieldsForm
        setMedia={setMedia}
        media={media}
        appealValues={appealValues}
        dictionaries={dictionaries}
        incident={incident}
        complaint={complaint}
      />
    </FormikProvider>
  );
};
