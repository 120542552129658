import {
  Appeals,
  APPEALS_TABLE_VIEW,
  CitizenAppealsTableViewEditPath,
  CitizenAppealsTableViewPath,
  CitizensAppealsPath,
} from '../../consts/consts';

export const CitizenAppelasTableViewBreadcrumb = [
  {
    breadcrumb: 'Учет обращений граждан',
    path: CitizensAppealsPath,
  },
  {
    breadcrumb: APPEALS_TABLE_VIEW,
    path: CitizenAppealsTableViewPath,
  },
  {
    breadcrumb: 'Обращения граждан',
    path: Appeals,
  },
];

export const CitizenAppelasTableViewAirTaskBreadcrumb = [
  {
    breadcrumb: 'Учет обращений граждан',
    path: CitizensAppealsPath
  },
  {
    breadcrumb: APPEALS_TABLE_VIEW,
    path: `${CitizenAppealsTableViewPath}/air`,
  },
  {
    breadcrumb: 'Подготовка заданий',
    path: `${CitizenAppealsTableViewEditPath}/air`,
  },
];

export const CitizenAppelasTableViewNoiseTaskBreadcrumb = [
  {
    breadcrumb: 'Учет обращений граждан',
    path: CitizensAppealsPath
  },
  {
    breadcrumb: APPEALS_TABLE_VIEW,
    path: `${CitizenAppealsTableViewPath}/noise`,
  },
  {
    breadcrumb: 'Подготовка заданий',
    path: `${CitizenAppealsTableViewEditPath}/noise`,
  },
];
