import { useState } from 'react';
import type { TFiltersArray } from 'models';
import { SubsystemHeader } from 'components/common';
import { EquipmentReportTableFilter } from './EquipmentReportTableFilter';

import { EquipmentReportTableView } from './EquipmentReportTableView';
import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import { EquipmentLocale } from '../../locale';
import { EquipmentsAppRoutes } from '../../equipmentsAppRoutes';
import appLocale from 'constants/appLocale';
import { useDictionary } from 'hooks/useDictionary';
import { FullPageWrapper } from '../../../../components/table/FullPageWrapper';

export const EquipmentReportTable = () => {
  useUpdateBreadcrumbs([
    {
      breadcrumb: EquipmentLocale.root,
      path: EquipmentsAppRoutes.root,
    },
    {
      breadcrumb: appLocale.equipmentPage.reports,
      path: EquipmentsAppRoutes.reports.root,
    },
    {
      breadcrumb: appLocale.equipmentPage.table,
      path: EquipmentsAppRoutes.reports.table,
    },
  ]);
  const [filters, setFilters] = useState<TFiltersArray>([]);
  const { statuses } = useDictionary({ url: '/equipment/v10/equipments/instruments/dictionaries' });

  return (
    <FullPageWrapper>
      <SubsystemHeader
        title="Отчеты, таблица"
        filtersComponent={
          <EquipmentReportTableFilter
            statuses={statuses}
            onSubmit={(values) => {
              const filters: TFiltersArray = Object.values(values);
              setFilters(filters);
            }}
          />
        }
      />

      <EquipmentReportTableView statuses={statuses} filters={filters} />
    </FullPageWrapper>
  );
};
