import { createGreenPlantsAddUrl, DeicingGreenPlantsRootPath, GreenPlantsFloraUrl } from 'pages/routes/PGRGreenPlants';
import { GreenPlantsForm } from '../../Form/GreenPlantsForm';
import {
  greenPlantsFormInitialValues,
  GreenPlantsFormTypes,
  TGreenPlantsForm,
} from 'pages/data/GreenPlants/Form/types';
import { useFloraDictionaries } from '../../Flora/useFloraDictionaries';
import { useCallback, useState } from 'react';
import { updateFiles } from 'api/services/media';
import { IUploadMedia } from 'components/common/FileUploadDragAndDrop/types';
import { ERROR_MESSAGES } from 'constants/messages';
import { useNotifications } from 'hooks';
import { useHistory, useLocation } from 'react-router-dom';
import { useObjectType } from 'hooks/useObjectType';
import { useAddArea } from './useAddArea';
import { useLawnsDictionaries } from '../../Lawns/useLawnsDictionaries';
import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import appLocale from 'constants/appLocale';
import { ReagentsRootPath } from '../../../../consts';
import { useGoBackWithFallback } from 'lib/utils/goBackWithFallback';

const withAddArea = (Component: typeof GreenPlantsForm) => () => {
  useUpdateBreadcrumbs([
    { breadcrumb: appLocale.common.data, path: '/data' },
    {
      breadcrumb: appLocale.subsystems.reagents,
      path: ReagentsRootPath,
    },
    {
      breadcrumb: 'Воздействие на зеленые насаждения',
      path: DeicingGreenPlantsRootPath,
    },
    {
      breadcrumb: 'Площадки',
      path: `${DeicingGreenPlantsRootPath}/areas`,
    },
    {
      breadcrumb: 'Добавление результатов',
      path: createGreenPlantsAddUrl('areas' as 'area'),
    },
  ]);
  const { backWithFallback } = useGoBackWithFallback();
  const location = useLocation();
  const { state } = location;
  const entityType = state?.type || 'flora';

  const [areaEntity, setAreaEntity] = useState<keyof typeof GreenPlantsFormTypes>(entityType);
  const type = 'area';

  const defaultIndex = Object.values(GreenPlantsFormTypes).indexOf(type);
  const { addArea, isLoading } = useAddArea(areaEntity);

  const { dictionaries, result, isLoading: isFloraDictsLoading } = useFloraDictionaries();
  const { dictionaries: lawnsDictionaries, isLoading: isLawnsDictsLoading } = useLawnsDictionaries();

  const objectType = useObjectType(result);
  const [media, setMedia] = useState<IUploadMedia[]>([]);
  const { errorNotify } = useNotifications();
  const history = useHistory();

  const onError = useCallback(
    (message?: string | null) => {
      errorNotify({
        key: message || 'file/create/error',
        message: message || ERROR_MESSAGES.CREATE,
      });
    },
    [errorNotify]
  );

  const onSubmit = useCallback(
    async (values: Partial<TGreenPlantsForm>) => {
      const response = await addArea(values.area);

      if ('registryId' in response && dictionaries?.objectType) {
        await updateFiles(response.registryId, objectType, media)
          .then(() => {
            backWithFallback(`${DeicingGreenPlantsRootPath}/areas/${areaEntity}`);
          })
          .catch((error: Error) => {
            onError(error.message);
            history.replace(`${GreenPlantsFloraUrl}/edit/${response.registryId}?openAttachmentsTab=true`, response);
          });
      }
    },
    [backWithFallback, areaEntity, addArea, dictionaries.objectType, history, media, objectType, onError]
  );

  return (
    <Component
      backUrl={`${DeicingGreenPlantsRootPath}/areas/${areaEntity}`}
      header="Добавление результатов"
      defaultIndex={defaultIndex}
      initialValues={greenPlantsFormInitialValues}
      type={type}
      isSubmitting={isLoading || isFloraDictsLoading || isLawnsDictsLoading}
      onSubmit={onSubmit}
      floraDictionaries={dictionaries}
      lawnsDictionaries={lawnsDictionaries}
      media={media}
      setMedia={setMedia}
      areaEntity={areaEntity}
      setAreaEntity={setAreaEntity}
    />
  );
};

export { withAddArea };
