import { ForecastHeader } from 'pages/data/Meteo/Forecast/ForecastHeader';
import ForecastReportFilter from 'pages/data/Meteo/Forecast/ForecastReport/ForecastReportFilter';
import { transformFiltersArray } from 'features/Filter/transformFiltersArray';
import { createLocalDateDDMMYYYY, createLocalDateDDMMYYYYWithTime, createLocalDateISO8601 } from 'lib/create-date';
import { useAvailability, useList } from 'hooks';
import { MaterialTable } from 'features/material-table/MaterialTableMini';
import React, { useMemo } from 'react';
import { addFiltersToColumns, ColumnWithFilter } from 'lib/utils/tableFilter';
import { TableFilterTypeEnum } from 'components/table/filters/TableFilter';
import { Column } from '@material-table/core';
import {
  IForecastReportListItem,
  TForecastReportFilterDTO,
  TForecastReportFilterTypes,
} from 'pages/data/Meteo/Forecast/ForecastReport/types';
import useForecastReportModal from 'pages/data/Meteo/Forecast/ForecastReport/useForecastReportModal';
import { useDisclosure } from '@chakra-ui/react';
import ForecastReportAddReportModal from 'pages/data/Meteo/Forecast/ForecastReport/ForecastReportAddReportModal';
import subsystemIdsMap from 'constants/subsystemIdsMap';
import { DEFAULT_PAGE_SIZE_NEW_TABLE } from '../../../../../constants/tables';
import { FullPageWrapper } from '../../../../../components/table/FullPageWrapper';

const ForecastReport = () => {
  const { isExecute, isNoRights, isAvailable } = useAvailability(subsystemIdsMap.forecast);

  const {
    isLoading,
    response,
    toggleOrder,
    setFilters,
    onPageChange,
    filterDTO,
    refetch,
    onSearchText,
    errorText,
  } = useList<IForecastReportListItem,
    TForecastReportFilterTypes>('/meteo/v10/forecast/report/list', {
    initialOrderFieldKey: '-reportDatetime',
    defaultPageSize: DEFAULT_PAGE_SIZE_NEW_TABLE,
    resetDataOnNextRequest: true,
  });

  const columns = useMemo<Array<ColumnWithFilter<IForecastReportListItem>>>(
    () => [
      {
        title: 'Дата и время отчета',
        field: 'reportDatetime',
        render: (row) => createLocalDateDDMMYYYYWithTime(row.reportDatetime),
      },
      {
        title: 'Дата прогноза',
        field: 'forecastDate',
        render: (row) => createLocalDateDDMMYYYY(row.forecastDate),
      },
      {
        title: 'Период',
        field: 'period',
        filterType: TableFilterTypeEnum.string,
      },
      {
        title: 'Статус',
        field: 'status',
        filterType: TableFilterTypeEnum.string,
      },
      {
        title: 'Пользователь',
        field: 'user',
        filterType: TableFilterTypeEnum.string,
      },
    ],
    []
  );

  const columnsWithFilters = useMemo<Array<Column<IForecastReportListItem>>>(
    () => addFiltersToColumns({ filters: filterDTO.filters, setFilters, columns }),
    [columns, filterDTO.filters, setFilters]
  );

  const { openModal, forecastModal } = useForecastReportModal();

  const addReportDisclosure = useDisclosure();

  return (
    <FullPageWrapper>
      <ForecastHeader
        isAvailable={isAvailable}
        addButtonProps={{
          title: 'Добавить расчет сравнения',
          onClick: addReportDisclosure.onOpen,
          isDisabled: !isAvailable,
        }}
      >
        <ForecastReportFilter
          isLoading={isLoading}
          onSubmit={({ dateFrom, dateTo, ...values }) => {
            setFilters(
              transformFiltersArray<TForecastReportFilterDTO['filters']>(
                Object.values({
                  ...values,
                  dateFrom: {
                    ...dateFrom,
                    value: createLocalDateISO8601(String(dateFrom.value)),
                  },
                  dateTo: {
                    ...dateTo,
                    value: createLocalDateISO8601(String(dateTo.value)),
                  },
                })
              )
            );
          }}
          filters={filterDTO.filters}
        />
      </ForecastHeader>

      <MaterialTable
        isNotExecute={isExecute || isNoRights}
        data={response?.data}
        meta={response?.meta}
        toggleOrder={toggleOrder}
        onPageChange={onPageChange}
        filterDTO={filterDTO}
        isLoading={isLoading}
        columns={columnsWithFilters}
        actions={[
          {
            type: 'view',
            onClick: (_e, item) => {
              if (!Array.isArray(item)) {
                openModal(item.id);
              }
            },
          },
        ]}
        setFilters={setFilters}
        onSearch={onSearchText}
        options={{
          search: true,
        }}
        errorText={errorText}
      />

      {forecastModal}

      <ForecastReportAddReportModal
        isOpen={addReportDisclosure.isOpen}
        onClose={addReportDisclosure.onClose}
        refetch={refetch}
      />
    </FullPageWrapper>
  );
};

export default ForecastReport;
