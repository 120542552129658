import React from 'react';
import { Box, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { DefaultText, FormHeader } from 'components/common';

interface DeviceModelLayoutProps {
  title: string;
  onBackClick: () => void;
  model: React.ReactNode;
  parameters?: React.ReactNode;
  tabIndex?: number;
  setTabIndex?: (newValue: number) => void;
}

export const DeviceModelLayout: React.FC<DeviceModelLayoutProps> = ({
  setTabIndex,
  tabIndex,
  onBackClick,
  parameters,
  model,
  title,
}) => (
  <Box flexDirection="column">
    <Tabs index={tabIndex} onChange={setTabIndex}>
      <FormHeader onBackClick={onBackClick} header={title}>
        <TabList>
          <Tab>
            <DefaultText>Модель</DefaultText>
          </Tab>
          <Tab isDisabled={!parameters}>
            <DefaultText>Параметры</DefaultText>
          </Tab>
        </TabList>
      </FormHeader>
      <TabPanels>
        <TabPanel px={2}>{model}</TabPanel>
        <TabPanel px={2}>{parameters}</TabPanel>
      </TabPanels>
    </Tabs>
  </Box>
);
