import * as yup from 'yup';
import { ValidationError } from 'yup';
import { numberError, requiredError, shouldBeLessThanEq, shouldBeMoreThanEq } from 'models/schemas/constans';

export const nsiDangerScaleSchema = yup.object()
  .shape({
    dangerScale: yup.string()
      .nullable()
      .required(requiredError),
    dutyScale: yup.string()
      .nullable()
      .required(requiredError),
    rangeStart: yup.number()
      .typeError(numberError)
      .nullable()
      .required(requiredError)
      .max(99999, shouldBeLessThanEq(99999))
      .min(0, shouldBeMoreThanEq(0)),
    rangeEnd: yup.number()
      .typeError(numberError)
      .nullable()
      .required(requiredError)
      .max(99999, shouldBeLessThanEq(99999))
      .min(0, shouldBeMoreThanEq(0)),
  })
  .test((values) => {
    if (values.rangeStart && values.rangeEnd && values.rangeEnd < values.rangeStart) {
      return new ValidationError('Не может быть меньше начала диапазона', '', 'rangeEnd');
    }

    return true;
  });
