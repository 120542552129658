import { Box, Flex, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { AttachmentsTabContent, Button, DefaultText, FormHeader, FormWrapper, LoaderItem } from 'components/common';
import React, { useMemo } from 'react';
import { COMMON_LOCALE } from 'constants/common';
import { CommonTab } from './CommonTab';
import { ParametersTab } from './ParametersTab';
import { SoilDeicingItem } from '../../consts';
import { useQuery } from 'hooks/useQuery';
import { useAvailability, useEnhancedNotification } from 'hooks';
import { useFormikContext } from 'formik';
import { useObjectType } from 'hooks/useObjectType';
import { FieldFormProps } from 'types/forms';
import subsystemIdsMap from 'constants/subsystemIdsMap';

const FieldsForm = ({
  onCancel,
  dictionaries,
  isLoading,
  isEditMode = false,
  media,
  onMediaChange,
  uploadItemId,
}: FieldFormProps) => {
  const { isAvailable } = useAvailability(subsystemIdsMap.pgrRoot);

  const title = useMemo(
    () => (isEditMode ? COMMON_LOCALE.text.edit_research_result : COMMON_LOCALE.text.addition_research_result),
    [isEditMode]
  );
  const { openAttachmentsTab } = useQuery();
  const { handleSubmit, errors } = useFormikContext<SoilDeicingItem>();
  const { onValidationError } = useEnhancedNotification();

  const objectType = useObjectType(dictionaries);

  const renderControls = useMemo(
    () => (
      <Flex mt={4}>
        <Button onClick={onCancel} marginRight="auto">
          {COMMON_LOCALE.cancel}
        </Button>

        <Button
          onClick={() => {
            if (Object.keys(errors).length > 0) {
              onValidationError();
            }
            handleSubmit();
          }}
          variant="solid"
          isDisabled={!isAvailable}
        >
          {COMMON_LOCALE.save}
        </Button>
      </Flex>
    ),
    [errors, handleSubmit, onCancel, onValidationError, isAvailable]
  );

  if (isLoading) {
    return <LoaderItem />;
  }

  return (
    <Box flexDirection="column">
      <Tabs defaultIndex={openAttachmentsTab ? 2 : 0}>
        <FormHeader onBackClick={onCancel} header={title}>
          <TabList>
            {[COMMON_LOCALE.commonData, COMMON_LOCALE.indicators, 'Вложения'].map((tab, i) => (
              <Tab key={i}>
                <DefaultText cursor="pointer">{tab}</DefaultText>
              </Tab>
            ))}
          </TabList>
        </FormHeader>
        <TabPanels>
          <TabPanel>
            <CommonTab isEditMode={isEditMode} dictionaries={dictionaries} />
          </TabPanel>
          <TabPanel>
            <ParametersTab isWrite={isAvailable} dictionaries={dictionaries}>
              {renderControls}
            </ParametersTab>
          </TabPanel>
          <TabPanel>
            <FormWrapper>
              {isAvailable && (
                <AttachmentsTabContent
                  objectType={objectType}
                  media={media}
                  onMediaChange={onMediaChange}
                  uploadItemId={uploadItemId}
                >
                  {renderControls}
                </AttachmentsTabContent>
              )}
            </FormWrapper>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default React.memo(FieldsForm);
