import { TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { StyledTab } from 'components/common';
import NoiseReport from 'pages/pel/NoiseReport';
import GarbageJournal from 'pages/pel/GarbageJournal';
import AtmosphereReport from 'pages/pel/BurningJournal';
import { FormikProps } from 'formik';
import { IMainReport } from 'pages/pel/NoiseReport/types';
import { IUploadMedia } from 'components/common/FileUploadDragAndDrop/types';
import { ReportNames } from 'pages/citizenAppeal';
import { noiseResearchTypeId } from '../../consts';

interface Props {
  mainReportFormik: FormikProps<IMainReport>;
  garbageReportFormik: FormikProps<IMainReport>;
  burningReportFormik: FormikProps<IMainReport>;
  reportIndex: number;
  setReportIndex: (idx: number) => void;
  reportTypeId?: string;
  taskId?: string;
  media: Record<ReportNames, IUploadMedia[][]>;
  setMedia: (media: Record<ReportNames, IUploadMedia[][]>) => void;
  backPath: string;
  browserId: string;
}
export const CompletionReports = ({
  mainReportFormik,
  garbageReportFormik,
  burningReportFormik,
  reportIndex,
  setReportIndex,
  reportTypeId,
  taskId,
  media,
  setMedia,
  backPath,
  browserId,
}: Props) => (
  <Tabs index={reportIndex} onChange={setReportIndex} isLazy>
    <TabList>
      <StyledTab>Основной отчет</StyledTab>
      <StyledTab hidden={reportTypeId === noiseResearchTypeId}>Журнал навалов мусора</StyledTab>
      <StyledTab hidden={reportTypeId === noiseResearchTypeId}>Журнал гарей</StyledTab>
    </TabList>
    <TabPanels>
      <TabPanel>
        {reportTypeId === noiseResearchTypeId ? (
          <NoiseReport
            media={media.main}
            onMediaSave={(item: IUploadMedia[], idx: number) => {
              const { atmosphere, garbage, main } = media;
              const allMedia = [...main];
              allMedia[idx] = item;
              setMedia({
                atmosphere,
                garbage,
                main: [...allMedia],
              });
              mainReportFormik.handleSubmit();
            }}
            taskId={taskId}
            formik={mainReportFormik}
            onSave={() => mainReportFormik.handleSubmit()}
            backPath={backPath}
            browserId={browserId}
          />
        ) : (
          <AtmosphereReport
            media={media.main}
            onMediaSave={(item: IUploadMedia[], idx: number) => {
              const { atmosphere, garbage, main } = media;
              const allMedia = [...main];
              allMedia[idx] = item;
              setMedia({
                atmosphere,
                garbage,
                main: [...allMedia],
              });
              mainReportFormik.handleSubmit();
            }}
            taskId={taskId}
            formik={mainReportFormik}
            onSave={() => mainReportFormik.handleSubmit()}
            backPath={backPath}
            browserId={browserId}
          />
        )}
      </TabPanel>
      <TabPanel>
        <GarbageJournal
          media={media.garbage}
          onMediaSave={(item: IUploadMedia[], idx: number) => {
            const { atmosphere, garbage, main } = media;
            const allMedia = [...garbage];
            allMedia[idx] = item;
            setMedia({
              main,
              atmosphere,
              garbage: [...allMedia],
            });
            garbageReportFormik.handleSubmit();
          }}
          type="garbage"
          formik={garbageReportFormik}
          onSave={() => garbageReportFormik.handleSubmit()}
          backPath={backPath}
          browserId={browserId}
        />
      </TabPanel>
      <TabPanel>
        <GarbageJournal
          media={media.atmosphere}
          onMediaSave={(item: IUploadMedia[], idx: number) => {
            const { atmosphere, garbage, main } = media;
            const allMedia = [...atmosphere];
            allMedia[idx] = item;
            setMedia({
              main,
              garbage,
              atmosphere: [...allMedia],
            });
            burningReportFormik.handleSubmit();
          }}
          type="odor"
          formik={burningReportFormik}
          onSave={() => burningReportFormik.handleSubmit()}
          backPath={backPath}
          browserId={browserId}
        />
      </TabPanel>
    </TabPanels>
  </Tabs>
);
