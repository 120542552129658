import * as yup from 'yup';
import { numberError, requiredError } from '../constans';

export const ServerSchema = yup.object().shape({
  name: yup.string().required(requiredError),
  active: yup.string().typeError(requiredError).required(requiredError),
  ip: yup.string().required(requiredError),
  port: yup.number().typeError(numberError).required(requiredError),
  timeout: yup.number().typeError(numberError).required(requiredError),
});

export const DataSourceSchema = yup
  .object()
  .shape({
    name: yup.string().nullable().required(requiredError),
    status: yup.boolean().nullable().required(requiredError),
    ftpPath: yup.string().nullable().required(requiredError),
    authPass: yup.string().nullable().required(requiredError),
    authLogin: yup.string().nullable().required(requiredError),
    servers: yup.array().of(
      yup.object().shape({
        serverId: yup.string().typeError(requiredError).required(requiredError),
      })
    ),
  })
  .test((values) => {
    const errors: yup.ValidationError[] = [];
    const table: any = {};
    values?.servers?.forEach(({ serverId }, index) => {
      if (serverId) {
        if (table[serverId]) {
          errors.push(new yup.ValidationError('Данный сервер выбран ранее', '', `servers.${index}.serverId`));
        } else {
          table[serverId] = 1;
        }
      }
    });
    if (errors.length > 0) {
      return new yup.ValidationError(errors);
    }
    return true;
  });
