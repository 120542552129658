import type { ChangeEvent } from 'react';
import { useMemo } from 'react';
import { Field, useFormikContext } from 'formik';
import { COMMON_LOCALE } from 'constants/common';
import { DateInput, FormWrapper, Input, Select } from 'components/common';
import { createDateToRequestWithTimezone as createDateToRequest } from 'lib/create-date';
import type { TDictionariesArray } from 'models';

import { getSelectRichOptionsFromDictionaryByName } from 'features/get-select-options-from-dictionary';
import { fieldsNoiseLocale, INoisePackageForm } from 'pages/data/MASKSH/consts';
import { Grid, Heading } from '@chakra-ui/react';
import { getFilteredOptionsByPrivileges } from 'features/getFilteredOptionsByPrivileges';

interface IProps {
  isLoading: boolean;
  isEditMode?: boolean;
  dictionaries: {
    data: TDictionariesArray;
  } | null;
}
export const NoisePackageFormCommonTab = ({ isLoading, isEditMode, dictionaries }: IProps) => {
  const { setFieldValue, values, errors } = useFormikContext<INoisePackageForm>();
  const stations = useMemo(
    () =>
      getFilteredOptionsByPrivileges(
        getSelectRichOptionsFromDictionaryByName(dictionaries?.data, 'stations'),
        'isEditable',
        'value',
        values.sourceId,
      ),
    [dictionaries?.data, values.sourceId],
  );

  return (
    <FormWrapper>
      <Grid gap={2}>
        <Heading mb={2} size="md">
          {COMMON_LOCALE.commonData}
        </Heading>
        <Field
          as={DateInput}
          name="date"
          label={fieldsNoiseLocale.date}
          value={values.date}
          onChange={(e: ChangeEvent<HTMLInputElement>) => setFieldValue('date', createDateToRequest(e.target.value))}
          showTimeInput
          isDisabled={isLoading || isEditMode}
          isRequired
          error={errors.date}
        />
        <Field
          as={Select}
          name="sourceId"
          label={fieldsNoiseLocale.sourceId}
          value={values.sourceId}
          options={stations}
          onChange={(e: ChangeEvent<HTMLSelectElement>) => {
            setFieldValue('sourceId', e.target.value);
          }}
          isDisabled={isLoading || isEditMode || !stations?.length}
          error={errors.sourceId}
          isRequired
        />
        <Heading mt={5} mb={2} size="md">
          {COMMON_LOCALE.configuration}
        </Heading>
        <Field as={Input} label={fieldsNoiseLocale.operationMode} name="operationMode" value={values.operationMode} error={errors.operationMode} />
        <Field
          as={Input}
          label={fieldsNoiseLocale.calculationMode}
          name="calculationMode"
          value={values.calculationMode}
          error={errors.calculationMode}
        />
      </Grid>
    </FormWrapper>
  );
};
