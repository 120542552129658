import * as yup from 'yup';
import { requiredError } from '../constans';

export const instructionForm = yup.object().shape({
  instructionDate: yup.string().nullable().required(requiredError),
  number: yup.string().nullable().required(requiredError),
  name: yup.string().nullable().required(requiredError),
  stationId: yup.string().nullable().required(requiredError),
  startDate: yup.string().nullable().required(requiredError),
});
