import { useRequest } from 'hooks/useRequest';

interface IASKZAPrecipitationDictionaryParameter {
  parameterCode: string;
  parameterId: string;
  parameterName: string;
}

interface IASKZAPrecipitationDictionarySource {
  sourceId: string;
  sourceName: string;
  sourceNumber: number;
  parameters: IASKZAPrecipitationDictionaryParameter[];
}

export interface IASKZAPrecipitationDictionariesResponse {
  askzaDictionary: IASKZAPrecipitationDictionarySource[];
  precipitationDictionary: IASKZAPrecipitationDictionarySource[];
}

interface IUseDictionary {
  url: string;
}

function useASKZAPrecipitationDictionaries({ url }: IUseDictionary) {
  const { post, isLoading, result } = useRequest<IASKZAPrecipitationDictionariesResponse>(url);

  return {
    post,
    isDictionariesLoading: isLoading,
    askzaDictionary: result?.askzaDictionary,
    precipitationDictionary: result?.precipitationDictionary,
  };
}

export default useASKZAPrecipitationDictionaries;
