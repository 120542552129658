import { createTouchedErrors } from 'features/create-touched-errors';
import { FormikProvider, useFormik } from 'formik';
import { useRequest } from 'hooks/useRequest';
import FieldsForm from './NSIEmissionsSourcesFormFields';
import { NSIEmissionsApiPath, NSIEmissionsSourcesRootPath } from 'pages/dictionaries/NSIEmissions/consts/paths';
import { useEnhancedNotification } from 'hooks';
import { NsiEmissionsSourcesSchema } from 'models/schemas/nsiEmissions/nsiEmissionsSources';
import moment from 'moment';
import { useGoBackWithFallback } from 'lib/utils/goBackWithFallback';
import { useDictionary } from 'hooks/useDictionary';

export const initialEmptyValues = {
  name: '',
  shortName: '',
  number: '',
  industryId: null,
  status: null,
  startDate: moment(),
  stopDate: moment(),
  landmark: '',
  latitude: '',
  longitude: '',
  flues: [
    {
      name: '',
      shortName: '',
      number: '',
      startDate: '',
      stopDate: '',
      description: '',
      deleted: false,
      canDeleted: true,
      canRestore: false,
      status: 'ACTIVE',
    },
  ],
};

export const formatNSIEmissionSourceNumber = (number: string | null) => {
  if (number === '' || number === null) {
    return null;
  }
  if (number.length < 4) {
    return Array(4 - number.length + 1).join('0') + number;
  }
  return number;
};

const NSIEmissionsSourcesAdd = () => {
  const { dictionaries, isDictionariesLoading } = useDictionary({ url: `${NSIEmissionsApiPath}/dictionaries` });
  const { backWithFallback } = useGoBackWithFallback();
  const { onSuccessCreate, onErrorCreate } = useEnhancedNotification();

  const onSuccess = () => {
    onSuccessCreate();
    backWithFallback(NSIEmissionsSourcesRootPath);
  };

  const { post, isLoading } = useRequest(`${NSIEmissionsApiPath}/create`, onSuccess, onErrorCreate);

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    initialValues: initialEmptyValues,
    validationSchema: NsiEmissionsSourcesSchema,
    onSubmit: (values) =>
      post({ ...values, number: formatNSIEmissionSourceNumber(String(values.number)), type: 'object' }),
  });

  return (
    <FormikProvider
      value={{
        ...formik,
        errors: createTouchedErrors(formik.errors, formik.touched),
      }}
    >
      <FieldsForm
        dictionaries={dictionaries}
        onCancel={() => {
          backWithFallback(NSIEmissionsSourcesRootPath);
        }}
        isLoading={isDictionariesLoading || isLoading}
      />
    </FormikProvider>
  );
};

export default NSIEmissionsSourcesAdd;
