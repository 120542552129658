import React, { FC, useMemo } from 'react';
import { FormWrapper, Input, Select } from 'components/common';

import { getSelectOptionsFromDictionaryByName } from 'features/get-select-options-from-dictionary';
import { Field, useFormikContext } from 'formik';
import { TDictionariesArray } from 'models/dictionaries';
import { NSIReagentsListItem } from 'pages/dictionaries/NSIReagents/types';
import { Box, Grid } from '@chakra-ui/react';

interface IProps {
  dictionaries: {
    data: TDictionariesArray;
  } | null;
  isEditMode?: boolean;
  editedObject?: any;
}

const NSIReagentsSitesGeneralInformationTab: FC<IProps> = ({ dictionaries, children, isEditMode, editedObject }) => {
  const { setFieldValue, values, errors } = useFormikContext<NSIReagentsListItem>();

  const statuses = useMemo(() => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'statuses'), [dictionaries]);
  const pgrAreaTypes = useMemo(
    () => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'pgrAreaTypes', false, editedObject?.pgrAreaTypeId),
    [dictionaries?.data, editedObject?.pgrAreaTypeId],
  );
  const areaTypes = useMemo(
    () => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'areaTypes', false, editedObject?.greenAreaTypeId),
    [dictionaries?.data, editedObject?.greenAreaTypeId],
  );
  const areaLocations = useMemo(
    () =>
      getSelectOptionsFromDictionaryByName(
        dictionaries?.data,
        'areaLocations',
        false,
        editedObject?.greenAreaLocationId,
      ),
    [dictionaries?.data, editedObject?.greenAreaLocationId],
  );
  const subsystems = useMemo(
    () => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'subsystems'),
    [dictionaries],
  );
  const organizations = useMemo(
    () => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'organizations'),
    [dictionaries],
  );

  return (
    <Box flexDirection="column">
      <FormWrapper>
        <Grid marginTop="30px" gap="15px">
          <Field
            as={Select}
            name="subsystemId"
            label="Выбрать модуль подсистемы"
            value={values.subsystemId}
            options={subsystems}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              setFieldValue('subsystemId', e.target.value);
            }}
            error={errors.subsystemId}
          />

          <Field
            as={Input}
            type="number"
            name="number"
            label="Номер площадки"
            error={errors.number}
            isDisabled={isEditMode}
          />

          <Field as={Input} name="name" label="Наименование" error={errors.name} />

          <Field
            as={Select}
            name="greenAreaTypeId"
            label="Тип территории насаждений"
            options={areaTypes}
            error={errors.greenAreaTypeId}
          />

          <Field
            as={Select}
            name="greenAreaLocationId"
            label="Месторасположение площадки"
            options={areaLocations}
            error={errors.greenAreaLocationId}
          />

          <Field
            as={Select}
            name="pgrAreaTypeId"
            label="Тип территории"
            options={pgrAreaTypes}
            error={errors.pgrAreaTypeId}
          />

          <Field
            as={Select}
            name="status"
            label="Статус"
            value={values.status}
            options={statuses}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              setFieldValue('status', e.target.value);
            }}
            error={errors.status}
          />
          <Field as={Select} name="organizationId" label="Организация" options={organizations} />

          {children}
        </Grid>
      </FormWrapper>
    </Box>
  );
};

export default NSIReagentsSitesGeneralInformationTab;
