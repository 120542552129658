import React, { useMemo } from 'react';
import { Button, Input, LoaderItem, Select } from 'components/common';
import { Flex, Grid, GridItem } from '@chakra-ui/react';
import { Field, useFormikContext } from 'formik';
import { ServersConfig } from 'pages/admin/FTP';
import { useServerDictionary } from '../useServerDictionary';
import { getSelectOptionsFromDictionaryByName } from 'features/get-select-options-from-dictionary';

interface Props {
  onCancel: () => void;
}

export const FieldsForm = ({ onCancel }: Props) => {
  const { handleSubmit, errors, values } = useFormikContext<ServersConfig>();
  const isEditMode = location.pathname.includes('/edit');

  const { result: dictionaries, isLoading: isDictLoading } = useServerDictionary();
  const statuses = useMemo(() => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'status'), [dictionaries]);

  if (isDictLoading) {
    return <LoaderItem />;
  }

  return (
    <>
      <Grid marginLeft={20} marginTop={3} gap={4} templateRows="repeat(5, 1fr)" templateColumns="repeat(3, 1fr)">
        <GridItem rowStart={1} rowEnd={1} colStart={1} colSpan={1}>
          <Field
            as={Input}
            name="name"
            disabled={isEditMode}
            label="Наименование сервера"
            error={errors?.name}
          />
        </GridItem>
        <GridItem rowStart={2} rowEnd={2} colStart={1} colSpan={1}>
          <Field
            as={Select}
            name="active"
            options={statuses}
            value={String(values.active)}
            label="Статус"
            error={errors?.active}
          />
        </GridItem>
        <GridItem rowStart={3} rowEnd={3} colStart={1} colSpan={1}>
          <Field
            as={Input}
            name="ip"
            label="Адрес сервера"
            error={errors?.ip}
          />
        </GridItem>
        <GridItem rowStart={4} rowEnd={4} colStart={1} colSpan={1}>
          <Field
            as={Input}
            name="port"
            label="Порт сервера"
            error={errors?.port}
          />
        </GridItem>
        <GridItem rowStart={5} rowEnd={5} colStart={1} colSpan={1}>
          <Field
            as={Input}
            name="timeout"
            label="Таймаут"
            error={errors?.timeout}
          />
        </GridItem>
      </Grid>
      <Flex marginLeft={20} marginTop={4}>
        <Button marginRight={3} onClick={onCancel}>Отмена</Button>
        <Button onClick={() => handleSubmit()}>Сохранить</Button>
      </Flex>
    </>
  );
};
