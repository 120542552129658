import React, { useMemo } from 'react';
import { TFilterDTO, TFiltersArray } from 'models';
import { IUseListMaterialTableProps, IWithFileDownload } from 'hooks';

import { createLocalDateDDMMYYYY } from 'lib/create-date';
import { MaterialTable } from 'features/material-table/MaterialTableMini';
import { Column } from '@material-table/core';
import FilePreviewModalWrapper from 'components/common/FilePreviewModal/FilePreviewModalWrapper';
import { ImageIcon } from 'components/icons';
import useFilePreviewModalWrapperData from 'components/common/FilePreviewModal/hooks/useFilePreviewModalWrapperData';
import {
  GeologicalGroundwaterMeasuringObservationPointData,
} from 'pages/data/GeoecologicalProcesses/GeologicalProcessesGroundwater/Monitoring/types';
import { addFiltersToColumns, ColumnWithFilter } from 'lib/utils/tableFilter';
import {
  CellObservationPoint,
} from 'pages/data/GeoecologicalProcesses/GeologicalProcessesGroundwater/components/CellObservationPoint';
import {
  MonitoringValueItem,
  MonitoringValueItems,
} from 'pages/data/GeoecologicalProcesses/GeologicalProcessesGroundwater/Protocol/types';
import useProtocolModal from 'hooks/useProtocolModal';
import VersionCell from 'components/table/VersionCell';
import { restorePreviewColumns } from 'components/common/Modals/ProtocolModal';

interface ProtocolTableProps {
  monitoringList?: MonitoringValueItems | null;
  refetch: () => void;
  toggleOrder: (fieldKey: string) => void;
  onPageChange: (page: number, pageSize: number) => void;
  downloadAsFile: IWithFileDownload;
  filters: TFilterDTO['filters'];
  setFilters: (filters: TFiltersArray) => void;
  filterDTO: TFilterDTO;
  isExecute?: boolean;
  isAvailable?: boolean;
  saveFilters: () => void;
  isLoading: boolean;
  errorText?: string;
  baseUrl?: string;
  materialTableProps: IUseListMaterialTableProps;
}

export const ProtocolTable: React.FC<ProtocolTableProps> = ({
  monitoringList,
  toggleOrder,
  onPageChange,
  downloadAsFile,
  filters,
  setFilters,
  refetch,
  filterDTO,
  isExecute,
  isAvailable,
  saveFilters,
  isLoading,
  errorText,
  baseUrl,
  materialTableProps,
}) => {
  const { open, ...filePreviewData } = useFilePreviewModalWrapperData();

  const { openModal, protocolModal } = useProtocolModal<MonitoringValueItem>({
    onClose: refetch,
    dateKey: 'valueDate',
    baseUrl,
    previewColumns: restorePreviewColumns.geoGroundwater,
    secondBaseUrl: '/geoecology/v10/geoecology/watersource/monitoring'
  });

  const columns = useMemo<Array<ColumnWithFilter<MonitoringValueItem>>>(
    () => [
      {
        title: 'Версия',
        field: 'valueVersion',
        render: (row) => (
          <>
            {
              isAvailable ? (
                <VersionCell row={row} onClick={openModal} />
              ) : row.valueVersion
            }
          </>
        ),
      },
      {
        title: 'Дата',
        field: 'valueDate',
        render: (row) => createLocalDateDDMMYYYY(row.valueDate),
      },
      {
        title: 'Тип пункта наблюдения',
        field: 'waterSourceTypeName',
      },
      {
        title: 'Пункт наблюдения',
        field: 'sourceCode',
        render: (row) =>
          CellObservationPoint<GeologicalGroundwaterMeasuringObservationPointData>({
            label: row.sourceCode,
            id: row.sourceId,
            url: '/geoecology/v10/geoecology/watersource/monitoring/watersourcedata',
          }),
      },
      {
        title: 'Параметр',
        field: 'parameterName',
        cellStyle: {
          width: '210px',
        },
      },
      {
        title: 'Значение',
        field: 'value',
        cellStyle: {
          width: '210px',
        },
      },
    ],
    [openModal, isAvailable]
  );

  const columnsWithFilters = useMemo<Array<Column<MonitoringValueItem>>>(
    () => addFiltersToColumns({ filters, setFilters, columns }),
    [columns, filters, setFilters]
  );

  return (
    <>
      <MaterialTable
        saveFilters={saveFilters}
        isNotExecute={isExecute}
        data={monitoringList?.data}
        meta={monitoringList?.meta}
        toggleOrder={toggleOrder}
        onPageChange={onPageChange}
        filterDTO={filterDTO}
        columns={columnsWithFilters}
        actions={[
          {
            position: 'row',
            action: (row) => ({
              icon: () => <ImageIcon />,
              onClick: () => open(row.id, row.objectType),
              tooltip: 'Показать изображения',
              hidden: !row.objectType,
            }),
          },
        ]}
        downloadAsFile={downloadAsFile}
        isLoading={isLoading}
        errorText={errorText}
        {...materialTableProps}
        showTotalCount
      />

      {protocolModal}

      <FilePreviewModalWrapper {...filePreviewData} />
    </>
  );
};
