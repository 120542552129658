import { TruncatedStringPopoverCell } from '../../../../components/table/TruncatedStringPopoverCell';
import React from 'react';

export const NODAirQuality = [
  {
    title: 'Код',
    field: 'ehdCode',
    width: 50,
  },
  {
    title: 'Название параметра',
    field: 'parameterName',
  },
  {
    title: 'Единица измерения',
    field: 'unitOfMeasure',
  },
  {
    title: 'ПДКмр/ОБУВ',
    field: 'pdkMrAsil',
  },
  {
    title: 'ПДКсс',
    field: 'pdkSs',
  },
  {
    title: 'Класс опасности',
    field: 'dangerClass',
  },
];

export const NODAirStations = [
  {
    title: 'Код',
    field: 'ehdCode',
    width: 50,
  },
  {
    title: 'Название станции',
    field: 'stationName',
  },
  {
    title: 'Назначение станции',
    field: 'stationPurpose',
  },
  {
    title: 'Характеристика зоны наблюдения',
    field: 'surveillanceZoneCharacteristics',
  },
  {
    title: 'Административный округ',
    field: 'admArea',
    width: 50,
  },
  {
    title: 'Адресный ориентир',
    field: 'address',
    width: 50,
  },
  {
    title: 'Долгота в WGS-84',
    field: 'longitudeWGS84',
  },
  {
    title: 'Широта в WGS-84',
    field: 'latitudeWGS84',
  },
  {
    title: 'Год ввода в эксплуатацию',
    field: 'startUpYear',
  },
  {
    title: 'Работает/не работает',
    field: 'inWorking',
  },
];

export const NODASKZAMonth = [
  {
    title: 'Код',
    field: 'ehdCode',
    width: 50,
  },
  {
    title: 'Период измерения',
    field: 'period',
  },
  {
    title: 'Название станции',
    field: 'stationName',
  },
  {
    title: 'Характеристика зоны наблюдения',
    field: 'surveillanceZoneCharacteristics',
  },
  {
    title: 'Административный округ',
    field: 'admArea',
    width: 50,
  },
  {
    title: 'Район',
    field: 'district',
    width: 50,
  },
  {
    title: 'Адресный ориентир',
    field: 'location',
  },
  {
    title: 'Измеряемый параметр',
    field: 'parameter',
  },
  {
    title: 'Среднее значение в абсолютных единицах',
    field: 'monthlyAverage',
  },
  {
    title: 'Среднее значение в долях ПДКсс',
    field: 'monthlyAveragePDKss',
  },
];

export const NODAtmpsphere = [
  {
    title: 'Код',
    field: 'ehdCode',
    width: 50,
  },
  {
    title: 'Дата выезда',
    field: 'date',
  },
  {
    title: 'Округ',
    field: 'admArea',
  },
  {
    title: 'Район',
    field: 'district',
  },
  {
    title: 'Долгота в WGS-84',
    field: 'longitudeWGS84',
    width: 50,
  },
  {
    title: 'Широта в WGS-84',
    field: 'latitudeWGS84',
    width: 50,
  },
  {
    title: 'Описание местоположения контрольной точки',
    field: 'location',
  },
  {
    title: 'Результаты выезда',
    field: 'results',
    render: (row: any) => <TruncatedStringPopoverCell value={row.results} isLargeText />,
  },
];

export const NODDanger = [
  {
    title: 'Код',
    field: 'ehdCode',
    width: 50,
  },
  {
    title: 'Категория загрязнения почв',
    field: 'pollutionCategory',
  },
  {
    title: 'Уровень загрязнения почв',
    field: 'pollutionLevel',
  },
  {
    title: 'Величина показателя загрязнения',
    field: 'indexValue',
  },
];

export const NODGeoDangerProcess = [
  {
    title: 'Код',
    field: 'ehdCode',
    width: 50,
  },
  {
    title: 'Название участка мониторинга',
    field: 'sector',
  },
  {
    title: 'Период наблюдений',
    field: 'period',
  },
  {
    title: 'Административный округ',
    field: 'admArea',
  },
  {
    title: 'Расположение участка мониторинга',
    field: 'location',
  },
  {
    title: 'Широта в WGS-84',
    field: 'longitudeWGS84',
  },
  {
    title: 'Долгота в WGS-84',
    field: 'latitudeWGS84',
  },
];

export const NODGeoSpring = [
  {
    title: 'Код',
    field: 'ehdCode',
    width: 50,
  },
  {
    title: 'Номер родника',
    field: 'springNumber',
    width: 50,
  },
  {
    title: 'Название родника',
    field: 'springName',
  },
  {
    title: 'Период измерения',
    field: 'period',
  },
  {
    title: 'Административный округ',
    field: 'admArea',
  },
  {
    title: 'Район',
    field: 'district',
  },
  {
    title: 'Расположение родника',
    field: 'location',
  },
  {
    title: 'Состояние родника',
    field: 'condition',
  },
  {
    title: 'Долгота в WGS-84',
    field: 'longitudeWGS84',
  },
  {
    title: 'Широта в WGS-84',
    field: 'latitudeWGS84',
  },
];

export const NODGreenplants = [
  {
    title: 'Код',
    field: 'ehdCode',
    width: 50,
  },
  {
    title: 'Год',
    field: 'period',
    width: 50,
  },
  {
    title: 'Административный округ',
    field: 'admArea',
  },
  {
    title: 'Район',
    field: 'district',
  },
  {
    title: 'Адрес',
    field: 'address',
  },
  {
    title: 'Хорошее состояние, %',
    field: 'goodCondition',
  },
  {
    title: 'Удовлетворительное состояние, %',
    field: 'middleCondition',
  },
  {
    title: 'Неудовлетворительное состояние, %',
    field: 'badCondition',
  },
];

export const NODJournalNMU = [
  {
    title: 'Код',
    field: 'ehdCode',
    width: 50,
  },
  {
    title: 'Год',
    field: 'year',
  },
  {
    title: 'Месяц',
    field: 'month',
  },
  {
    title: 'Количество дней с НМУ',
    field: 'amountOfDays',
  },
];

export const NODNoise = [
  {
    title: 'Код',
    field: 'ehdCode',
    width: 50,
  },
  {
    title: 'Дата выезда',
    field: 'date',
  },
  {
    title: 'Округ',
    field: 'admArea',
  },
  {
    title: 'Район',
    field: 'district',
  },
  {
    title: 'Долгота в WGS-84',
    field: 'longitudeWGS84',
    width: 50,
  },
  {
    title: 'Широта в WGS-84',
    field: 'latitudeWGS84',
    width: 50,
  },
  {
    title: 'Месторасположение контрольной точки',
    field: 'location',
  },
  {
    title: 'Тип территории',
    field: 'territoryType',
  },
  {
    title: 'Категория обращения по шуму',
    field: 'noiseCategory',
  },
  {
    title: 'Результаты выезда',
    field: 'results',
    render: (row: any) => <TruncatedStringPopoverCell value={row.results} isLargeText />,
  },
];

export const NODSoil = [
  {
    title: 'Код',
    field: 'ehdCode',
    width: 50,
  },
  {
    title: 'Год',
    field: 'year',
    width: 50,
  },
  {
    title: 'Административный округ',
    field: 'admArea',
  },
  {
    title: 'Район',
    field: 'district',
  },
  {
    title: 'Адрес',
    field: 'address',
  },
  {
    title: 'Расчетное значение показателя загрязнения',
    field: 'estimatedIndexValue',
  },
  {
    title: 'Категория загрязнения почв',
    field: 'pollutionCategory',
  },
  {
    title: 'Уровень загрязнения почв',
    field: 'pollutionLevel',
  },
];

export const NODWaterConcentration = [
  {
    title: 'Код',
    field: 'ehdCode',
  },
  {
    title: 'Период измерения',
    field: 'period',
  },
  {
    title: 'Тип водного объекта',
    field: 'freshwaterBodyType',
  },
  {
    title: 'Название водного объекта',
    field: 'freshwaterBodyName',
  },
  {
    title: 'Название створа наблюдений',
    field: 'samplingSiteName',
  },
  {
    title: 'Параметр',
    field: 'parameterName',
  },
  {
    title: 'Среднемесячная концентрация',
    field: 'avgConcentration',
  },
  {
    title: 'Среднемесячная концентрация в долях ПДКрыб-хоз',
    field: 'avgConcentrationForFish',
  },
  {
    title: 'Среднемесячная концентрация в долях ПДКкульт-быт',
    field: 'avgConcentrationForRecreation',
  },
  {
    title: 'Код створа',
    field: 'idSs',
  },
];

export const NODWaterObjects = [
  {
    title: 'Код',
    field: 'ehdCode',
    width: 50,
  },
  {
    title: 'Тип водного объекта',
    field: 'freshwaterBodyType',
  },
  {
    title: 'Название водного объекта',
    field: 'freshwaterBodyName',
  },
  {
    title: 'Уровень государственного надзора в области использования и охраны водных ресурсов',
    field: 'jurisdictionType',
  },
];

export const NODWaterObservations = [
  {
    title: 'Код',
    field: 'ehdCode',
    width: 50,
  },
  {
    title: 'Тип водного объекта',
    field: 'freshwaterBodyType',
  },
  {
    title: 'Название водного объекта',
    field: 'freshwaterBodyName',
  },
  {
    title: 'Название створа наблюдений',
    field: 'samplingSiteName',
  },
  {
    title: 'Долгота в WGS-84',
    field: 'longitudeWGS84',
  },
  {
    title: 'Широта в WGS-84',
    field: 'latitudeWGS84',
  },
];

export const NODWaterParams = [
  {
    title: 'Код',
    field: 'ehdCode',
    width: 50,
  },
  {
    title: 'Название параметра',
    field: 'parameterName',
  },
  {
    title: 'Единица измерения',
    field: 'unitOfMeasure',
  },
  {
    title: 'ПДКр-х',
    field: 'pdkRh',
  },
  {
    title: 'ПДКк-б',
    field: 'pdkKb',
  },
];
