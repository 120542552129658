import { memo } from 'react';
import { Button, ButtonProps } from 'components/common';
import {
  Flex,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverProps,
  PopoverTrigger,
  Text,
  useDisclosure,
} from '@chakra-ui/react';

export interface PortalPopoverProps extends ButtonProps {
  label?: string;
  onOpen?: () => void;
  PopoverProps?: Partial<PopoverProps>;
}

export const PortalPopover: React.FC<PortalPopoverProps> =
  memo(({ PopoverProps = {}, label, children, onOpen, ...props }) => {
    const { isOpen, onOpen: onOpenHandler, onClose } = useDisclosure();

    return (
      <Flex position="relative" alignItems="center" width="100%" height="100%">
        <Popover isLazy isOpen={isOpen} onClose={onClose} {...PopoverProps}>
          <PopoverTrigger>
            <Button
              onClick={(e) => {
                e.stopPropagation();
                if (onOpen) {
                  onOpen();
                }
                onOpenHandler();
              }}
              minWidth="0px"
              color="PrimaryButton.500"
              {...props}
            >
              <Text maxWidth="240px" isTruncated>
                {label}
              </Text>
            </Button>
          </PopoverTrigger>
          <PopoverContent onClick={(e) => e.stopPropagation()}>
            <PopoverArrow />
            <PopoverCloseButton />
            <PopoverBody>{children}</PopoverBody>
          </PopoverContent>
        </Popover>
      </Flex>
    );
  });
