import { Flex } from '@chakra-ui/react';
import { ArrowDownIcon } from 'components/icons';
import React from 'react';
import { Box } from '@material-ui/core';

interface Props<T> {
  row: T;
  onClick: (row: T) => void;
}

function VersionCell<T extends { valueVersion: number; valueId: string }>({ row, onClick }: Props<T>) {
  const onRowClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    onClick(row);
  };

  return (
    <Flex style={{ color: '#208CFF', alignItems: 'center' }} onClick={onRowClick}>
      {row.valueVersion}
      <Box marginLeft={0.5}>
        <ArrowDownIcon />
      </Box>
    </Flex>
  );
}

export default VersionCell;
