import { Field, useFormikContext } from 'formik';
import { useMemo } from 'react';
import { Input, Select } from 'components/common';
import { TDictionariesArray } from 'models/dictionaries';
import { getSelectOptionsFromDictionaryByName } from 'features/get-select-options-from-dictionary';
import { IParameterItem, paramsLocale } from '../../consts';
import { typesDict } from 'pages/dictionaries/Parameters/consts';
import { Grid } from '@chakra-ui/react';

interface Props {
  dictionaries?: TDictionariesArray;
  isEditMode?: boolean;
  editedObject: any;
}

export const ParamCharacteristics = ({ dictionaries, isEditMode, editedObject }: Props) => {
  const { errors, values } = useFormikContext<IParameterItem>();

  const purposes = useMemo(
    () => getSelectOptionsFromDictionaryByName(dictionaries, 'purposes', false, editedObject?.purpose),
    [dictionaries, editedObject?.purpose],
  );

  const paramDictionaries = useMemo(
    () => getSelectOptionsFromDictionaryByName(dictionaries, 'dictionaries'),
    [dictionaries],
  );

  return (
    <Grid gap={3} width="70%">
      <Field as={Input} name="fullName" label={paramsLocale.naming} error={errors?.fullName} isDisabled={isEditMode} />
      <Field as={Input} name="name" label={paramsLocale.name} error={errors?.name} isDisabled={isEditMode} />
      <Field as={Input} name="code" label={paramsLocale.code} error={errors?.code} isDisabled={isEditMode} />
      <Field as={Select} name="purpose" label={paramsLocale.purpose} options={purposes} isDisabled={isEditMode} />
      <Field
        as={Select}
        name="fieldType"
        label={paramsLocale.type}
        options={typesDict}
        error={errors?.fieldType}
        isDisabled={isEditMode}
      />
      <Field
        as={Select}
        name="dictionary"
        isDisabled={(values.fieldType !== 'DIC' && values.fieldType !== 'DICLIST') || isEditMode}
        label={paramsLocale.dictionary}
        options={paramDictionaries}
      />
    </Grid>
  );
};
