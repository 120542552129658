import React, { useMemo, useState } from 'react';
import { Field, useFormikContext } from 'formik';
import { Button, DateInput, FormHeader, Input, Select } from 'components/common';
import { Box, Flex, Grid } from '@chakra-ui/react';
import { consentrationPath, IConcentraionItem, pdkLocales, statuses } from 'pages/dictionaries/NSIPdk/consts';
import { getSelectOptionsFromDictionaryByName } from 'features/get-select-options-from-dictionary';
import { TDictionariesArray } from 'models';
import { createLocalDateISO8601 } from 'lib/create-date';
import { useGoBackWithFallback } from 'lib/utils/goBackWithFallback';
import FormControls from 'components/common/FormControls';

interface Props {
  isEditMode?: boolean;
  dictionaries?: TDictionariesArray;
  editedObject?: IConcentraionItem | null;
}

export const ConcentrationFields = ({ isEditMode, dictionaries, editedObject }: Props) => {
  const { backWithFallback } = useGoBackWithFallback();

  const { handleSubmit, errors, setFieldValue } = useFormikContext<IConcentraionItem>();
  const title = useMemo(
    () => (isEditMode ? pdkLocales.common.substanceEditing : pdkLocales.common.substanceAdding),
    [isEditMode],
  );

  const chemicals = useMemo(() => getSelectOptionsFromDictionaryByName(dictionaries, 'chemicals'), [dictionaries]);
  const naturalEnvironments = useMemo(
    () =>
      getSelectOptionsFromDictionaryByName(
        dictionaries,
        'naturalEnvironment',
        false,
        editedObject?.natural_environment,
      ),
    [dictionaries, editedObject?.natural_environment],
  );
  const pdkKinds = useMemo(
    () => getSelectOptionsFromDictionaryByName(dictionaries, 'pdkKind', false, editedObject?.pdkKind),
    [dictionaries, editedObject?.pdkKind],
  );
  const pdkUnits = useMemo(() => getSelectOptionsFromDictionaryByName(dictionaries, 'pdkUnit'), [dictionaries]);

  const onCancel = () => {
    backWithFallback(consentrationPath);
  };

  const [isDisabled, setIsDisabled] = useState<boolean>(!!isEditMode);

  return (
    <>
      <FormHeader onBackClick={onCancel} header={title} />

      <Flex justifyContent="space-between" padding="0px 102.5px" width="1200px" maxWidth="100%">
        <Grid gap={3} width="50%" margin={5}>
          <Field
            as={Select}
            name="chemical_id"
            label={pdkLocales.substancesColumns.substanceName}
            error={errors?.chemical_id}
            options={chemicals}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const chemDesc = chemicals.find((chem) => chem.value === e.target.value)?.name;
              setFieldValue('chemical_id', e.target.value);
              setFieldValue('chem_desc', chemDesc);
            }}
            isDisabled={isEditMode}
          />
          <Field
            as={Select}
            name="natural_environment"
            label={pdkLocales.naturalEnvironment}
            error={errors?.natural_environment}
            options={naturalEnvironments}
            isDisabled={isEditMode}
          />
          <Field
            as={Select}
            name="pdkKind"
            label={pdkLocales.pdkKind}
            error={errors?.pdkKind}
            options={pdkKinds}
            isDisabled={isEditMode}
          />
          <Field
            as={Select}
            name="pdkUnit"
            label={pdkLocales.measurmentUnit}
            error={errors?.pdkUnit}
            options={pdkUnits}
            isDisabled={isEditMode}
          />
          <Field as={Input} name="pdkMin" label={pdkLocales.pdkMin} error={errors?.pdkMin} isDisabled={isDisabled} />
          <Field as={Input} name="pdkMax" label={pdkLocales.pdkMax} error={errors?.pdkMax} isDisabled={isDisabled} />
          <Field
            as={Input}
            name="pdk_document"
            label={pdkLocales.pdkDocument}
            error={errors?.pdk_document}
            isDisabled={isDisabled}
          />
          <Field as={Input} name="prim" label={pdkLocales.pdkPrim} isDisabled={isDisabled} />
          <Field
            as={Select}
            name="pdk_status"
            label={pdkLocales.substancesColumns.status}
            error={errors?.pdk_status}
            options={statuses}
            isDisabled
          />
          <FormControls onCancel={onCancel} handleSubmit={handleSubmit} />
          <Button onClick={() => setIsDisabled(false)} variant="solid" disabled={!isDisabled}>
            Изменить значение ПДК
          </Button>
        </Grid>

        <Box width={250} marginTop={5} marginLeft={3} marginRight="auto">
          <Box marginBottom={3}>
            <Field
              as={DateInput}
              name="pdkStartDate"
              label={pdkLocales.pdkStartDate}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setFieldValue('pdkStartDate', createLocalDateISO8601(e.target.value));
              }}
              error={errors?.pdkStartDate}
            />
          </Box>
          <Field
            as={DateInput}
            name="pdkEndDate"
            label={pdkLocales.pdkEndDate}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFieldValue('pdkEndDate', createLocalDateISO8601(e.target.value));
            }}
            error={errors?.pdkEndDate}
          />
        </Box>
      </Flex>
    </>
  );
};
