import React, { useMemo } from 'react';
import { Field, FieldArray, useFormikContext } from 'formik';
import { Box, Flex, Grid, GridItem } from '@chakra-ui/react';
import { Button, DefaultText, Input, Select, Textarea } from 'components/common';
import { TDictionariesArray } from 'models/dictionaries';
import { Appeals, AppealValues, Complaint, IncidentValues } from 'pages/citizenAppeal';
import { getSelectOptionsFromDictionaryByName } from 'features/get-select-options-from-dictionary';
import { Modal } from 'components/common/Modals';
import { useDisclosure } from 'hooks';
import { ComplaintsSearch } from '../ComplaintsSearch';
import { AddIcon, DeleteIcon } from 'components/icons';
import { IncidentForms } from './IncidentForms';
import { SearchOutlined } from '@material-ui/icons';
import { useGoBackWithFallback } from 'lib/utils/goBackWithFallback';

interface Props {
  dictionaries?: TDictionariesArray;
  appealValues: AppealValues;
  isWrite?: boolean;
}

export const FieldsForm = ({ dictionaries, appealValues, isWrite = true }: Props) => {
  const { backWithFallback } = useGoBackWithFallback();
  const { initialValues } = useFormikContext<Complaint>();

  const areas = useMemo(() => getSelectOptionsFromDictionaryByName(dictionaries, 'areas'), [dictionaries]);
  const assignmentStatuses = useMemo(
    () =>
      getSelectOptionsFromDictionaryByName(dictionaries, 'assignmentStatuses', false, initialValues.complaintStatusId),
    [dictionaries, initialValues.complaintStatusId],
  );
  const districts = useMemo(() => getSelectOptionsFromDictionaryByName(dictionaries, 'districts'), [dictionaries]);
  const incidentTypes = useMemo(
    () =>
      getSelectOptionsFromDictionaryByName(
        dictionaries,
        'incidentTypes',
        false,
        initialValues.incidents.map((item) => item.incidentTypeId),
      ),
    [dictionaries, initialValues.incidents],
  );
  const odorTypes = useMemo(
    () =>
      getSelectOptionsFromDictionaryByName(
        dictionaries,
        'odorTypes',
        false,
        initialValues.incidents.map((item) => item.incidentOdorTypeId),
      ),
    [dictionaries, initialValues.incidents],
  );
  const sources = useMemo(
    () =>
      getSelectOptionsFromDictionaryByName(
        dictionaries,
        'sources',
        false,
        initialValues.incidents.map((item) => item.incidentSourceId),
      ),
    [dictionaries, initialValues.incidents],
  );
  const noiseSourceTypeId = useMemo(
    () =>
      getSelectOptionsFromDictionaryByName(
        dictionaries,
        'noiseSourceTypeId',
        false,
        initialValues.incidents.map((item) => item.incidentNoiseSourceTypeId),
      ),
    [dictionaries, initialValues],
  );
  const odorSourceTypeId = useMemo(
    () =>
      getSelectOptionsFromDictionaryByName(
        dictionaries,
        'odorSourceTypeId',
        false,
        initialValues.incidents.map((item) => item.incidentOdorSourceTypeId),
      ),
    [dictionaries, initialValues.incidents],
  );

  const { isOpen, onOpen, onClose } = useDisclosure();

  const { values, handleSubmit, errors } = useFormikContext<IncidentValues>();

  return (
    <Box marginRight="40px" width="80%">
      <DefaultText marginLeft={2} marginBottom={2} fontWeight="bold" fontSize="18px">
        Исполнитель
      </DefaultText>
      <Grid
        marginLeft={3}
        marginBottom={3}
        marginTop={5}
        gap={3}
        templateRows="repeat(1, 1fr)"
        templateColumns="repeat(4, 1fr)"
      >
        <GridItem colStart={1} colEnd={3} rowStart={1} rowEnd={1}>
          <Field
            as={Input}
            label="ФИО Исполнителя"
            name="assignmentExecutor"
            error={errors.assignmentExecutor}
            isDisabled={!isWrite}
          />
        </GridItem>
        <GridItem colStart={3} colSpan={1} rowStart={1} rowEnd={1}>
          <Field
            as={Select}
            options={assignmentStatuses}
            label="Статус"
            name="complaintStatusId"
            error={errors.complaintStatusId}
            isDisabled={!isWrite}
          />
        </GridItem>
      </Grid>
      <DefaultText marginLeft={2} marginBottom={2} fontWeight="bold" fontSize="18px">
        Заявитель
      </DefaultText>
      <Grid
        marginLeft={3}
        marginBottom={3}
        marginTop={5}
        gap={3}
        templateRows="repeat(2, 1fr)"
        templateColumns="repeat(4, 1fr)"
      >
        <GridItem colStart={1} colEnd={3} rowStart={1} rowEnd={1}>
          <Field
            as={Input}
            label="ФИО Заявителя"
            disabled
            value={appealValues.complaintCitizenFio}
            name="complaintCitizenFio"
            isDisabled={!isWrite}
          />
        </GridItem>
        <GridItem colStart={3} colSpan={1} rowStart={1} rowEnd={1}>
          <Field as={Input} label="Контакты: aaa@mail.ru" disabled value={appealValues.complaintCitizenEmail} isDisabled={!isWrite} />
        </GridItem>
        <GridItem colStart={4} colSpan={1} rowStart={1} rowEnd={1}>
          <Field as={Input} label="Контакты: +7(916) 111 1111" disabled value={appealValues.complaintCitizenPhone} isDisabled={!isWrite} />
        </GridItem>
        <GridItem colStart={1} colSpan={4} rowStart={2} rowEnd={2}>
          <Field as={Input} label="Адрес заявителя" disabled value={appealValues.complaintCitizenAddress} isDisabled={!isWrite} />
        </GridItem>
      </Grid>
      <DefaultText marginLeft={2} marginBottom={2} fontWeight="bold" fontSize="18px">
        Содержание обращения:
      </DefaultText>
      <Grid
        marginLeft={2}
        marginBottom={3}
        marginTop={5}
        gap={3}
        templateRows="repeat(1, 1fr)"
        templateColumns="repeat(4, 1fr)"
      >
        <GridItem rowStart={1} rowEnd={1} colStart={1} colSpan={4}>
          <Field as={Textarea} placeholder="Текст обращения" disabled value={appealValues.complaintText} isDisabled={!isWrite} />
        </GridItem>
      </Grid>
      {appealValues.questions.length > 0 && (
        <DefaultText marginLeft={2} marginBottom={2} fontWeight="bold" fontSize="18px">
          Вопрос ЭДО
        </DefaultText>
      )}
      <Grid
        marginLeft={2}
        marginBottom={3}
        marginTop={5}
        gap={3}
        templateRows="repeat(2, 1fr)"
        templateColumns="repeat(4, 1fr)"
      >
        <FieldArray name="questions">
          {() => (
            <Box flexDirection="column" width="100%">
              {appealValues.questions &&
                appealValues.questions.map((value, idx) => (
                  <Flex alignItems="start">
                    <Box marginBottom={3} width="800px">
                      <Field
                        disabled
                        as={Input}
                        name={`questions[${idx}]`}
                        label={`Вопрос ${idx + 1}`}
                        value={appealValues.questions[idx]}
                        isDisabled={!isWrite}
                      />
                    </Box>
                  </Flex>
                ))}
            </Box>
          )}
        </FieldArray>
        <GridItem marginLeft={0} rowStart={2} rowEnd={2} colStart={1} colEnd={2} marginBottom={5}>
          <Button leftIcon={<SearchOutlined />} color="PrimaryButton.500" variant="ghost" onClick={onOpen}>
            Поиск других обращений
          </Button>
        </GridItem>
      </Grid>
      <FieldArray name="incidents">
        {({ push, remove }) => (
          <Box flexDirection="column" width="100%">
            {values.incidents &&
              values.incidents.map((value, idx) => (
                <>
                  <Grid gap={1} templateRows="repeat(1, 1fr)" templateColumns="repeat(3, 340px)">
                    <GridItem rowStart={1} rowEnd={1} colStart={1} colSpan={1}>
                      <Flex>
                        <DefaultText marginLeft={2} marginBottom={2} fontWeight="bold" fontSize="18px">
                          {`Происшествие - ${idx + 1}`}
                        </DefaultText>
                        <Button leftIcon={<DeleteIcon />} onClick={() => remove(idx)} variant="ghost" size="sm" isDisabled={!isWrite} />
                      </Flex>
                    </GridItem>
                  </Grid>
                  <IncidentForms
                    noiseSourceTypeId={noiseSourceTypeId}
                    odorSourceTypeId={odorSourceTypeId}
                    incidentTypes={incidentTypes}
                    areas={areas}
                    sources={sources}
                    districts={districts}
                    odorTypes={odorTypes}
                    index={idx}
                    citizenAddress={appealValues.complaintCitizenAddress}
                    errors={errors}
                    isWrite={isWrite}
                  />
                </>
              ))}
            <Grid gap={1} templateRows="repeat(1, 1fr)" templateColumns="repeat(3, 340px)">
              <GridItem rowStart={1} rowEnd={1} colStart={1} colSpan={1}>
                <Button
                  marginBottom={10}
                  color="PrimaryButton.500"
                  leftIcon={<AddIcon />}
                  onClick={() =>
                    push({
                      incidentAddress: '',
                      incidentAreaId: '',
                      incidentCompletionSign: false,
                      incidentDistrictId: '',
                      incidentId: null,
                      incidentLatitude: '',
                      incidentLongitude: '',
                      incidentNmuSign: false,
                      incidentNoNeedingSign: false,
                      incidentNoiseSourceTypeId: null,
                      incidentOdorSourceTypeId: null,
                      incidentPlanningSign: false,
                      incidentRedirectionSign: false,
                      incidentRenovationSign: false,
                      incidentRepetitionSign: false,
                      incidentSourceId: '',
                      incidentTypeId: '',
                      incidentOdorTypeId: null,
                    })
                  }
                  variant="ghost"
                  size="sm"
                  isDisabled={!isWrite}
                >
                  Добавить происшествие
                </Button>
              </GridItem>
            </Grid>
          </Box>
        )}
      </FieldArray>
      <Grid gap={3} templateRows="repeat(2, 1fr)" templateColumns="repeat(3, 4fr)">
        <GridItem rowStart={1} rowEnd={1} colStart={1} colSpan={3}>
          <Field
            as={Textarea}
            name="complaintComment"
            label="Комментарии к обращению"
            error={errors.complaintComment}
            isDisabled={!isWrite}
          />
        </GridItem>
        <Flex>
          <Button marginRight={3} onClick={() => backWithFallback(Appeals)}>
            Отмена
          </Button>
          <Button onClick={() => handleSubmit()} isDisabled={!isWrite}>Сохранить</Button>
        </Flex>
      </Grid>
      <Modal isOpen={isOpen} onClose={onClose} width="100%">
        <ComplaintsSearch />
      </Modal>
    </Box>
  );
};
