import React from 'react';
import { RoutesList, SubsystemHeader } from 'components/common';
import { DeicingGreenPlantsRootPath, DeicingStatements } from 'pages/routes/PGRGreenPlants';
import appLocale from 'constants/appLocale';
import { ReagentsRootPath, ReagentsVegsRootPath, ReagentsWashoutRootPath } from '..';
import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import { COMMON_LOCALE } from 'constants/common';
import { Flex } from '@chakra-ui/react';

export const PGRGreenPlantsList: React.FC = () => {
  useUpdateBreadcrumbs([
    { breadcrumb: COMMON_LOCALE.data, path: '/data' },
    {
      breadcrumb: appLocale.subsystems.reagents,
      path: ReagentsRootPath,
    },
    {
      breadcrumb: 'Воздействие на зеленые насаждения',
      path: DeicingGreenPlantsRootPath,
    },
  ]);

  return (
    <Flex flexDirection="column">
      <SubsystemHeader title="Воздействие на зеленые насаждения" />
      <RoutesList
        routes={[
          {
            path: `${ReagentsWashoutRootPath}/main-data`,
            breadcrumb: 'Смывы',
          },
          {
            path: `${ReagentsVegsRootPath}/main-data`,
            breadcrumb: 'Вегетативные части зеленых насаждений',
          },
          {
            path: DeicingStatements,
            breadcrumb: 'Ведомости',
          },
          {
            path: `${DeicingGreenPlantsRootPath}/areas/flora`,
            breadcrumb: 'Площадки',
          },
        ]}
      />
    </Flex>
  );
};
