import { Filter } from 'features/Filter';
import { createInitialValuesToFormik, createValuesToRequest } from 'features/Filter/utils';
import React, { useMemo } from 'react';
import { IFilterItem, TFilterDTO } from 'models';
import { SelectOption } from 'components/common';
import combineFilters from 'lib/utils/combineFilters';

type TNSIEmissionsSourcesFilterTypes = 'name' | 'okrug' | 'rayon' | 'address' | 'status' | 'deleted' | 'industryId';
type TNSIEmissionsSourcesFilterDTO = TFilterDTO<TNSIEmissionsSourcesFilterTypes>;
type FilterInitialValues = Record<'name' | 'okrug' | 'rayon' | 'address' | 'status' | 'deleted' | 'industryId',
  TNSIEmissionsSourcesFilterDTO['filters'][number]>;

const initialValues: FilterInitialValues = {
  name: {
    key: 'name',
    operation: 'CONTAINS',
    value: '',
  },
  okrug: {
    key: 'okrug',
    operation: 'EQ',
    value: null,
  },
  rayon: {
    key: 'rayon',
    operation: 'EQ',
    value: null,
  },
  address: {
    key: 'address',
    operation: 'CONTAINS',
    value: '',
  },
  industryId: {
    key: 'industryId',
    operation: 'EQ',
    value: null,
  },
  status: {
    key: 'status',
    operation: 'EQ',
    value: null,
  },
  deleted: {
    key: 'deleted',
    operation: 'EQ',
    value: false,
  },
};

interface Props {
  isLoading: boolean;
  onSubmit: (values: FilterInitialValues) => void;
  okrugs?: SelectOption[];
  rayons?: SelectOption[];
  statuses?: SelectOption[];
  industries?: SelectOption[];
  filters: TFilterDTO['filters'];
}

const initialValuesToFormik = createInitialValuesToFormik(initialValues);

function NSIEmissionsSourcesFilters({
  isLoading,
  onSubmit,
  children,
  okrugs = [],
  rayons = [],
  statuses = [],
  industries = [],
  filters,
}: React.PropsWithChildren<Props>) {
  const presetValues = useMemo<{ key: string, value: IFilterItem['value'] }[]>(() => {
    const preset: { key: string, value: IFilterItem['value'] }[] = [];
    const initialFilters = combineFilters(initialValues, filters);
    Object.keys(initialFilters).forEach((key) => {
      preset.push({ key: `${key}`, value: initialFilters[key].value });
    });
    return preset;
  }, [filters]);

  return (
    <Filter<typeof initialValuesToFormik>
      isLoading={isLoading}
      isDisabled={isLoading}
      onSubmit={(values) => {
        onSubmit(createValuesToRequest(initialValues, values));
      }}
      initialValues={initialValuesToFormik}
      mainFields={[
        {
          type: 'TEXT',
          name: 'name',
          label: 'Наименование',
        },
        {
          type: 'SELECT',
          name: 'okrug',
          label: 'Округ',
          options: okrugs,
        },
        {
          type: 'SELECT',
          name: 'rayon',
          label: 'Район',
          options: rayons,
        },
        {
          type: 'SELECT',
          name: 'status',
          label: 'Статус',
          options: statuses,
        },
        {
          type: 'TEXT',
          name: 'address',
          label: 'Адрес',
        },
        {
          type: 'SELECT',
          name: 'industryId',
          label: 'Предприятие',
          options: industries,
        },
        // {
        //   type: 'CHECKBOX',
        //   name: 'deleted',
        //   label: appLocale.common.showDeleted,
        // },
      ]}
      presetValues={presetValues}
    >
      {children}
    </Filter>
  );
}

export default NSIEmissionsSourcesFilters;
