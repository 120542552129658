import { Stack, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { AttachmentsTabContent, StyledTab } from 'components/common';
import React, { memo } from 'react';
import { AreaForm } from '../Common';
import { FloraDamageForm } from './FloraDamageForm';
import { FloraMeasurementsForm } from './FloraMeasurementsForm';
import { FloraNumberForm } from './FloraNumberForm';
import { FloraPointsForm } from './FloraPointsForm';
import { IFloraTabPanelProps } from './types';
import { UploadObjectType } from 'api/services/media';
import { useQuery } from 'hooks/useQuery';
import { TGreenPlantsForm } from 'pages/data/GreenPlants/Form/types';
import { useFormikContext } from 'formik';

export const FloraTabPanel: React.FC<IFloraTabPanelProps> = memo(
  ({
    entity,
    floraDictionaries = { areas: [], plantParameters: [], areaParameters: [], objectType: [] },
    plants,
    isLoading,
    children,
    media,
    setMedia,
    registryId,
    area,
  }) => {
    const { areaParameters = [], plantParameters = [], objectType = [] } = floraDictionaries;
    const { openAttachmentsTab } = useQuery();

    const { values } = useFormikContext<TGreenPlantsForm>();

    if (entity === 'flora') {
      return (
        <Tabs defaultIndex={openAttachmentsTab ? 5 : 0}>
          <TabList>
            <StyledTab>Площадка</StyledTab>
            <StyledTab isDisabled={!values[entity].areaId}>Номер насаждения</StyledTab>
            <StyledTab isDisabled={!values[entity].areaId}>Измерения</StyledTab>
            <StyledTab isDisabled={!values[entity].areaId}>Баллы</StyledTab>
            <StyledTab isDisabled={!values[entity].areaId}>Повреждения</StyledTab>
            <StyledTab isDisabled={!values[entity].areaId}>Вложения</StyledTab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <AreaForm area={area} entity={entity} isLoading={isLoading} dictionaries={floraDictionaries} />
              {children}
            </TabPanel>
            <TabPanel>
              <FloraNumberForm entity="flora" plants={plants} isLoading={isLoading} />
              {children}
            </TabPanel>
            <TabPanel>
              <FloraMeasurementsForm
                entity="flora"
                areaParameters={areaParameters}
                plantParameters={plantParameters}
                isLoading={isLoading}
              />
              {children}
            </TabPanel>
            <TabPanel>
              <FloraPointsForm
                entity="flora"
                areaParameters={areaParameters}
                plantParameters={plantParameters}
                isLoading={isLoading}
              />
              {children}
            </TabPanel>
            <TabPanel>
              <Stack spacing="30px">
                <FloraDamageForm
                  entity="flora"
                  areaParameters={areaParameters}
                  plantParameters={plantParameters}
                  isLoading={isLoading}
                />
                {children}
              </Stack>
            </TabPanel>
            <TabPanel>
              <Stack spacing="30px">
                <AttachmentsTabContent
                  objectType={objectType[0]?.value as UploadObjectType}
                  media={media}
                  onMediaChange={setMedia}
                  uploadItemId={registryId}
                />
                {children}
              </Stack>
            </TabPanel>
          </TabPanels>
        </Tabs>
      );
    }

    return null;
  },
);
