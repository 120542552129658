import { DateInput, FormWrapper, Input, Select } from 'components/common';
import { Box, Grid, Heading } from '@chakra-ui/react';
import React, { useMemo } from 'react';

import {
  getSelectOptionsFromDictionaryByName,
  getSelectRichOptionsFromDictionaryByName,
} from 'features/get-select-options-from-dictionary';
import { Field, useFormikContext } from 'formik';
import { createDateFromResponse, createDateToRequestWithTimezone } from 'lib/create-date';
import { TDictionariesArray } from 'models/dictionaries';

import { useLocation } from 'react-router';
import { VegetationDeicingItem } from '../useVegDicingList';
import { fieldsLocale } from '../consts';
import { getFilteredOptionsByPrivileges } from 'features/getFilteredOptionsByPrivileges';

interface CommonTabProps {
  dictionaries: {
    data: TDictionariesArray;
  } | null;
}

export const CommonTab = ({ dictionaries }: CommonTabProps) => {
  const { setFieldValue, values, errors } = useFormikContext<VegetationDeicingItem>();
  const location = useLocation();
  const isEditMode = location.pathname.includes('/edit');

  const okrugs = useMemo(() => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'okrugs'), [dictionaries]);

  const filteredRayons = useMemo(() => {
    const rayons = getSelectOptionsFromDictionaryByName(dictionaries?.data, 'rayons');
    if (values.okrugId) {
      return rayons.filter((item) => item.parent === values.okrugId);
    }
    return rayons;
  }, [dictionaries, values]);

  const areas = useMemo(
    () =>
      getFilteredOptionsByPrivileges(
        getSelectRichOptionsFromDictionaryByName(dictionaries?.data, 'areas'),
        'isEditable', 'value', values.sourceNameId
      ),
    [dictionaries?.data, values.sourceNameId],
  );

  const filteredAreas = useMemo(() => {
    if (values.rayonId) {
      return areas.filter((item) => item.parent === values.rayonId);
    }
    return areas;
  }, [areas, values.rayonId]);

  const distances2RoadExt = useMemo(
    () =>
      getSelectOptionsFromDictionaryByName(dictionaries?.data, 'distances2RoadExt').filter(
        (item) => values.sourceNameId && item.parent === values.sourceNameId,
      ),
    [dictionaries, values.sourceNameId],
  );

  const territoryTypes = [{ name: values.territoryType, value: values.territoryType }];

  return (
    <Box flexDirection="column">
      <FormWrapper>
        <Grid marginTop="30px" gap="15px">
          <Heading variant="h2">Данные обследования</Heading>
          <DateInput
            name="selectionDate"
            label={fieldsLocale.selectionDate}
            value={createDateFromResponse(values.selectionDate)}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setFieldValue('selectionDate', createDateToRequestWithTimezone(e.target.value))
            }
            showTimeInput={false}
            isDisabled={isEditMode}
            error={errors?.selectionDate}
          />
          <Field
            as={Input}
            label={fieldsLocale.protocolNumber}
            name="protocol"
            disabled={isEditMode}
            error={errors?.protocol}
            isClearable={!isEditMode}
          />
          <DateInput
            name="protocolDate"
            label={fieldsLocale.protocolDate}
            value={createDateFromResponse(values.protocolDate)}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setFieldValue('protocolDate', createDateToRequestWithTimezone(e.target.value))
            }
            showTimeInput={false}
            isDisabled={isEditMode}
            error={errors?.protocolDate}
          />
          <Field
            as={Select}
            name="sourceNameId"
            label={fieldsLocale.sourceName}
            options={filteredAreas}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              const areaItem = filteredAreas.find((el) => el.value === e.target.value);
              const okrugId = filteredRayons.find((item) => item.value === areaItem?.parent)?.parent || '';
              const rayonId = filteredRayons.find((item) => item.value === areaItem?.parent)?.value || '';

              setFieldValue('sourceNameId', areaItem?.value);
              setFieldValue('rayonId', rayonId);
              setFieldValue('okrugId', okrugId);
              setFieldValue('address', areaItem?.address || '');
              setFieldValue('territoryType', areaItem?.territoryType || '');
              setFieldValue('distance2RoadId', null);
            }}
            isDisabled={isEditMode}
            error={errors?.sourceNameId}
          />
          <Field
            as={Select}
            name="okrugId"
            label="Округ"
            options={okrugs}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              setFieldValue('okrugId', e.target.value);
              if (!e.target.value) {
                setFieldValue('rayonId', '');
                setFieldValue('sourceNameId', '');
                setFieldValue('address', '');
                setFieldValue('territoryType', '');
              }
            }}
            isDisabled={isEditMode}
            error={errors?.okrugId}
          />
          <Field
            as={Select}
            name="rayonId"
            options={filteredRayons}
            label="Район"
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              const okrugId = filteredRayons.find((item) => item.value === e.target.value)?.parent || '';

              setFieldValue('rayonId', e.target.value);
              setFieldValue('okrugId', okrugId);

              if (!e.target.value) {
                setFieldValue('sourceNameId', '');
                setFieldValue('address', '');
                setFieldValue('territoryType', '');
              }
            }}
            isDisabled={isEditMode}
            error={errors?.rayonId}
          />
          <Field
            as={Input}
            name="address"
            label={fieldsLocale.address}
            disabled
            error={errors?.address}
            isClearable={false}
          />
          <Field
            as={Select}
            name="territoryType"
            options={territoryTypes}
            label={fieldsLocale.territoryType}
            isDisabled
            error={errors?.territoryType}
          />
          <Field
            as={Select}
            name="distance2RoadId"
            options={distances2RoadExt}
            label={fieldsLocale.pointId}
            isDisabled={isEditMode || !values.sourceNameId}
            error={errors?.distance2RoadId}
          />
        </Grid>
      </FormWrapper>
    </Box>
  );
};
