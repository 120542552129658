import * as yup from 'yup';
import { maxSymbols, requiredError, shouldBeLessThan, shouldBeMoreThan } from '../constans';

export const NsiEmissionsSourcesSchema = yup.object().shape({
  status: yup.string().nullable().required(requiredError),
  industryId: yup.string().nullable().required(requiredError),
  number: yup.string().nullable().max(4, maxSymbols(4)).required(requiredError),
  flues: yup.array().of(
    yup.object().shape({
      name: yup.string().required(requiredError).nullable(),
      shortName: yup.string().required(requiredError).nullable(),
      number: yup.string().required(requiredError).nullable(),
      startDate: yup.string().required(requiredError).nullable(),
      stopDate: yup.string().required(requiredError).nullable(),
    }),
  ),
  latitude: yup
    .number()
    .lessThan(90, shouldBeLessThan(90))
    .moreThan(-90, shouldBeMoreThan(-90))
    .typeError('Допустимые символы: "123", "."'),
  longitude: yup
    .number()
    .lessThan(100, shouldBeLessThan(100))
    .moreThan(-100, shouldBeMoreThan(-100))
    .typeError('Допустимые символы: "123", "."'),
});
