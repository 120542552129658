import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import appLocale from 'constants/appLocale';
import { NSIRootPath } from '../../../const/routes';
import { GreenspacesAPIPath, GreenspacesDictPath, GreenspacesTreePath } from '../../const';
import { BreadcrumbButton, Button, ContentContainer, FormWrapper, PageHeader } from 'components/common';
import { Flex, Heading, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import React, { FC, useEffect, useMemo } from 'react';
import { GeneralInformation } from './GeneralInformation';
import { Location } from './Location';
import { useParams } from 'react-router-dom';
import { FormikProvider, FormikValues, useFormik } from 'formik';
import * as yup from 'yup';
import { requiredError } from 'models/schemas/constans';
import { useEnhancedNotification, useNotifications, useRequest, UseRequestReturnType } from 'hooks';
import { getSelectOptionsFromDictionaryByName } from 'features/get-select-options-from-dictionary';
import { SUCCESS_MESSAGES } from 'constants/messages';
import { useGoBackWithFallback } from 'lib/utils/goBackWithFallback';
import { useDictionary } from 'hooks/useDictionary';

export const EditGreenspacesTreeData: FC = () => {
  useUpdateBreadcrumbs([
    {
      breadcrumb: appLocale.mainPage.nsi,
      path: NSIRootPath,
    },
    {
      breadcrumb: appLocale.subsystems.greenspacesTree,
      path: GreenspacesTreePath,
    },
  ]);

  const { id } = useParams<{ id: string }>();

  const { backWithFallback } = useGoBackWithFallback();

  const { successNotify } = useNotifications();
  const { onErrorCreate, onErrorEdit } = useEnhancedNotification();

  const onSuccess = () => {
    successNotify({
      key: 'greenplants/edit/success',
      message: id ? SUCCESS_MESSAGES.EDIT : SUCCESS_MESSAGES.CREATE,
    });
    backWithFallback(`${GreenspacesTreePath}`);
  };

  const { result, get }: UseRequestReturnType = useRequest(`${GreenspacesAPIPath}/tree/${id}`);
  const { post } = useRequest(`${GreenspacesAPIPath}/create`, onSuccess, onErrorCreate);
  const { put } = useRequest(GreenspacesAPIPath, onSuccess, onErrorEdit);

  useEffect(() => {
    if (id) {
      get();
    }
  }, [id, get]);

  const onSubmit = (values: FormikValues) => {
    if (id) {
      put({ ...values, id, type: 'tree' });
    } else {
      post({ ...values, type: 'tree' });
    }
  };

  const validationSchema = yup.object().shape({
    status: yup.string().nullable().required(requiredError),
    number: yup.string().nullable().required(requiredError),
    parentAreaId: yup.string().nullable().required(requiredError),
    greenPlantLifeBreedId: yup.string().nullable().required(requiredError),
    greenPlantLifeFormId: yup.string().nullable().required(requiredError),
  });

  const { dictionaries } = useDictionary({ url: GreenspacesDictPath });

  const statuses = useMemo(() => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'statuses'), [dictionaries]);
  const lifeBreeds = useMemo(
    () =>
      getSelectOptionsFromDictionaryByName(
        dictionaries?.data,
        'lifeBreeds',
        false,
        result?.greenPlantLifeBreedId as string,
      ),
    [dictionaries?.data, result?.greenPlantLifeBreedId],
  );
  const lifeForms = useMemo(
    () =>
      getSelectOptionsFromDictionaryByName(
        dictionaries?.data,
        'lifeForms',
        false,
        result?.greenPlantLifeFormId as string,
      ),
    [dictionaries?.data, result?.greenPlantLifeFormId],
  );
  const areasForTrees = useMemo(
    () => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'areasForTrees'),
    [dictionaries],
  );

  const initialValues = {
    ...result,
    number: result?.number || '',
    parentAreaId: result?.parentAreaId || '',
    status: result?.status || '',
    latitude: result?.latitude || '',
    longitude: result?.longitude || '',
    landmark: result?.landmark || '',
    startDate: result?.startDate || '',
    stopDate: result?.stopDate || '',
    greenPlantLifeBreedId: result?.greenPlantLifeBreedId || '',
    greenPlantLifeFormId: result?.greenPlantLifeFormId || '',
    greenStartDate: result?.greenStartDate || '',
    description: result?.description || '',
    id: id || null,
  };

  const {
    values,
    errors,
    getFieldMeta,
    getFieldHelpers,
    getFieldProps,
    handleReset,
    submitForm,
    setFieldValue,
    ...formik
  } = useFormik({
    validationSchema,
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: false,
    validateOnBlur: true,
    initialValues,
    onSubmit,
  });

  return (
    <>
      <ContentContainer margin={0} padding={0}>
        <FormikProvider
          value={{
            values,
            errors,
            getFieldMeta,
            getFieldHelpers,
            getFieldProps,
            handleReset,
            submitForm,
            setFieldValue,
            ...formik,
          }}
        >
          <PageHeader>
            <BreadcrumbButton onClick={() => backWithFallback(GreenspacesTreePath)}>Вернуться назад</BreadcrumbButton>
            <Flex>
              <Heading marginLeft={4}>{`${id ? 'Редактирование' : 'Добавление'} справочных данных`}</Heading>
              <Flex marginLeft="auto">
                <Button marginRight={5} onClick={() => backWithFallback(GreenspacesTreePath)}>
                  Отмена
                </Button>
                <Button onClick={submitForm}>Сохранить</Button>
              </Flex>
            </Flex>
          </PageHeader>
          <Tabs marginTop={10}>
            <TabList>
              <Tab>Общие сведения</Tab>
              <Tab>Местоположение</Tab>
            </TabList>
            <FormWrapper>
              <TabPanels>
                <TabPanel>
                  <GeneralInformation
                    statuses={statuses}
                    lifeBreeds={lifeBreeds}
                    lifeForms={lifeForms}
                    areasForTrees={areasForTrees}
                    isEditMode={!!id}
                  />
                </TabPanel>
                <TabPanel>
                  <Location />
                </TabPanel>
              </TabPanels>
            </FormWrapper>
          </Tabs>
        </FormikProvider>
      </ContentContainer>
    </>
  );
};
