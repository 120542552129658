import { Column } from '@material-table/core';
import { IApiToken } from 'pages/admin/ApiToken/types';
import { createLocalDateDDMMYYYY } from 'lib/create-date';

const adminApiTokenColumns: Column<IApiToken>[] = [
  {
    title: 'Описание',
    field: 'description',
  },
  {
    title: 'Действителен до',
    field: 'expires',
    render: (row) => createLocalDateDDMMYYYY(row.expires),
  },
  {
    title: 'Токен',
    field: 'token',
  },
  {
    title: 'Тип токена',
    field: 'type',
  },
  {
    title: 'Статус',
    field: 'active',
    render: (row) => (row.active ? 'Активен' : 'Не активен'),
  },
];
export default adminApiTokenColumns;
