import { IFilterValues } from 'components/common';
import { Modal } from 'components/common/Modals';
import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import { MaterialTable } from 'features/material-table/MaterialTableMini';

import { useAvailability, useDisclosure } from 'hooks';

import React, { memo, useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { DeicingGreenPlantsRootPath, DeicingStatements, GreenPlantsFloraUrl } from 'pages/routes/PGRGreenPlants';
import { UploadFileModalContent } from 'features/UploadFileDragAndDrop/UploadFileModalContent';
import { useList } from 'hooks/useList';
import appLocale from 'constants/appLocale';
import { GreenPlantsDataHeader } from '../components';
import { useFloraListColumns } from './useFloraListColumns';
import { IFloraItemDto, TFloraFilterType } from './useFloraList';
import { useFloraDictionaries } from './useFloraDictionaries';
import { FloraFilter } from './FloraFilter';
import { DeleteGreenPlantsItem } from '../DeleteGreenPlantsItem';
import { DeleteIcon, EditIcon, ImageIcon } from 'components/icons';
import FilePreviewModalWrapper from 'components/common/FilePreviewModal/FilePreviewModalWrapper';
import useFilePreviewModalWrapperData from 'components/common/FilePreviewModal/hooks/useFilePreviewModalWrapperData';
import subsystemIdsMap from 'constants/subsystemIdsMap';
import { PropActions } from 'features/material-table/components/Actions';
import { usePeriodsDict } from 'features/PeriodsButtons/usePeriodsDict';
import { ReagentsApiGreenplants } from 'pages/data/PGR/consts';
import { dictParametersToMap, parameterToParameterIdMap } from 'features/parametersMap';
import { getKeyByValue } from 'lib/utils/findKeyByValue';
import { stringArrayToOptions } from 'features/stringArrayToOptions';
import { Column } from '@material-table/core';
import { addFiltersToColumns } from 'lib/utils/tableFilter';
import { DEFAULT_PAGE_SIZE_NEW_TABLE } from '../../../../../../constants/tables';
import { FullPageWrapper } from '../../../../../../components/table/FullPageWrapper';

// TODO: all url paths are wrong in this subsystem
const Flora: React.FC = memo(() => {
  const { isReadOnly, isWrite, isExecute, isNoRights, allAvailable, isDelete } = useAvailability(
    subsystemIdsMap.pgrFlora,
  );

  const history = useHistory();

  useUpdateBreadcrumbs([
    { breadcrumb: appLocale.common.data, path: '/data' },
    {
      breadcrumb: appLocale.subsystems.reagents,
      path: GreenPlantsFloraUrl,
    },
    {
      breadcrumb: appLocale.pgr.effects_on_greenplants,
      path: DeicingGreenPlantsRootPath,
    },
    {
      breadcrumb: 'Ведомости',
      path: DeicingStatements,
    },
    {
      breadcrumb: 'Деревья и кустарники',
      path: GreenPlantsFloraUrl,
    },
  ]);

  const { open, ...filePreviewData } = useFilePreviewModalWrapperData();
  const { dictionaries } = useFloraDictionaries();

  const {
    refetch,
    response,
    isLoading: isListLoading,
    filterDTO,
    toggleOrder,
    setFilters,
    onPageChange,
    downloadAsFile,
    onSearchText,
    saveFilters,
    errorText,
    selectedIndexes,
    renderOnlySelectedCheckbox,
    onSubmitFilters,
    onSelectionChange,
    materialTableProps,
  } = useList<IFloraItemDto, TFloraFilterType>(`${ReagentsApiGreenplants}/flora/list`, {
    initialOrderFieldKey: '-date',
    preventFirstFetch: true,
    exportSubsystemName: 'pgr_flora',
    saveFiltersState: true,
    selectionKey: 'registryId',
    defaultPageSize: DEFAULT_PAGE_SIZE_NEW_TABLE,
    resetDataOnNextRequest: true,
  });

  const { isOpen: isOpenLoad, onClose: onCloseLoad, onOpen: onOpenLoad } = useDisclosure();

  const columns = useFloraListColumns(dictionaries.plantParameters);

  const selectOptions = useMemo(() => {
    const parameters = dictParametersToMap(dictionaries.plantParameters);
    const map = new Map();
    map.set(getKeyByValue(parameterToParameterIdMap, 'plantAge'), stringArrayToOptions(parameters.plantAge?.values));
    return map;
  }, [dictionaries.plantParameters]);

  const columnsWithFilters = useMemo<Array<Column<IFloraItemDto>>>(
    () => addFiltersToColumns({ filters: filterDTO.filters, setFilters, columns, selectOptions }),
    [columns, filterDTO.filters, selectOptions, setFilters],
  );

  const deleteFloraDisclosure = useDisclosure();

  const [deleteFloraId, setDeleteFloraId] = useState<string | null>(null);

  const onRowDelete = useCallback(
    (_, rowData: IFloraItemDto | IFloraItemDto[]) => {
      if (rowData && !Array.isArray(rowData)) {
        setDeleteFloraId(rowData.registryId);
        deleteFloraDisclosure.onOpen();
      }
    },
    [deleteFloraDisclosure],
  );

  const [filtersFromGraphic, setFiltersFromGraphic] = useState<Partial<IFilterValues> | null>(null);

  const initialActions = useMemo<PropActions<IFloraItemDto[]>>(
    () => [
      {
        position: 'row',
        action: (row: IFloraItemDto) => ({
          icon: () => <EditIcon />,
          onClick: () => history.push(`${DeicingStatements}/flora/edit/${row.registryId}`),
          tooltip: 'Редактировать',
          hidden: !isWrite || !row.isEditable,
        }),
      },
      {
        position: 'row',
        action: (row: IFloraItemDto) => ({
          icon: () => <DeleteIcon />,
          onClick: () => onRowDelete(undefined, row),
          tooltip: 'Удалить',
          hidden: !isDelete || !row.isEditable,
        }),
      },
      {
        position: 'row',
        action: (row: any) => ({
          icon: () => <ImageIcon />,
          onClick: () => open(row.registryId, row.objectType),
          tooltip: 'Показать изображения',
          hidden: !row.objectType,
        }),
      },
    ],
    [isWrite, onRowDelete, isDelete, history, open],
  );

  const { result: periods } = usePeriodsDict(`auth/v10/user/period/${subsystemIdsMap.pgrFlora}`);

  return (
    <FullPageWrapper>
      <GreenPlantsDataHeader
        allAvailable={allAvailable}
        isWrite={isWrite}
        isReadonly={isReadOnly}
        isExecute={isExecute}
        isNoRights={isNoRights}
        onOpenLoad={onOpenLoad}
        handleAdd={() => {
          history.push(`${DeicingStatements}/flora/add`);
        }}
        filtersComponent={
          <FloraFilter
            filters={filterDTO?.filters || []}
            periods={periods}
            filtersFromGraphic={filtersFromGraphic}
            setFiltersFromGraphic={setFiltersFromGraphic}
            dictionaries={dictionaries}
            isLoading={isListLoading}
            onSubmit={(values) => {
              onSubmitFilters(Object.values(values));
            }}
          >
            {renderOnlySelectedCheckbox}
          </FloraFilter>
        }
      />

      <Modal isOpen={isOpenLoad} onClose={onCloseLoad} header="Загрузка данных из файла">
        <UploadFileModalContent
          createTypesPath={() => `${ReagentsApiGreenplants}/flora/load/types`}
          createUploadPath={() => `${ReagentsApiGreenplants}/flora/load`}
          types={[
            {
              name: 'Данные о состоянии деревьев и кустарников',
              value: 'flora',
            },
          ]}
          onClose={onCloseLoad}
          refetch={refetch}
        />
      </Modal>
      <MaterialTable
        saveFilters={saveFilters}
        isNotExecute={isExecute || isNoRights}
        data={response?.data}
        meta={response?.meta}
        filterDTO={filterDTO}
        isLoading={isListLoading}
        options={{
          search: true,
        }}
        onSearch={onSearchText}
        toggleOrder={toggleOrder}
        onPageChange={onPageChange}
        columns={columnsWithFilters}
        actions={initialActions}
        downloadAsFile={downloadAsFile}
        onSelectionChange={onSelectionChange}
        selectedIndexes={selectedIndexes}
        errorText={errorText}
        {...materialTableProps}
        showTotalCount
      />
      <DeleteGreenPlantsItem type="flora" id={deleteFloraId} refetch={refetch} {...deleteFloraDisclosure} />

      <FilePreviewModalWrapper {...filePreviewData} />
    </FullPageWrapper>
  );
});

export { Flora };
