import { Grid } from '@chakra-ui/react';
import { Field, FormikContextType, FormikErrors, FormikValues, useFormikContext } from 'formik';
import { DateInput, Input, Select } from 'components/common';
import React from 'react';
import { TDictionaryItemDictionary } from 'models';
import { createDateFromResponse, createDateToRequestForFilter, createDateToRequestWithTimezone } from 'lib/create-date';

interface IProps {
  statuses: TDictionaryItemDictionary[];
  lifeForms: TDictionaryItemDictionary[];
  lifeBreeds: TDictionaryItemDictionary[];
  areasForTrees: TDictionaryItemDictionary[];
  isEditMode: boolean;
}

export const GeneralInformation = ({ statuses, lifeForms, lifeBreeds, areasForTrees, isEditMode }: IProps) => {
  const { errors, setFieldValue, values }: FormikContextType<FormikErrors<FormikValues>> = useFormikContext();

  return (
    <Grid gap={2} templateColumns="repeat(1, 1fr)">
      <Field as={Input} name="number" label="Номер насаждения" error={errors?.number} isDisabled={isEditMode} />
      <Field
        as={Select}
        name="parentAreaId"
        label="Номер площадки"
        options={areasForTrees}
        error={errors?.parentAreaId}
        isDisabled={isEditMode}
      />
      <Field
        as={Select}
        name="greenPlantLifeBreedId"
        label="Порода"
        options={lifeBreeds}
        error={errors?.greenPlantLifeBreedId}
      />
      <Field
        as={Select}
        name="greenPlantLifeFormId"
        label="Жизненная форма"
        options={lifeForms}
        error={errors?.greenPlantLifeFormId}
      />
      <Field as={Select} name="status" label="Статус" options={statuses} error={errors?.status} />

      <DateInput
        name="greenStartDate"
        label="Дата"
        value={createDateFromResponse(values.greenStartDate as unknown as string)}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setFieldValue('greenStartDate', createDateToRequestForFilter(e.target.value, 'end'))
        }
        error={errors.greenStartDate}
      />

      <DateInput
        name="startDate"
        label="Начало действия"
        value={createDateFromResponse(values.startDate as unknown as string)}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setFieldValue('startDate', createDateToRequestWithTimezone(e.target.value))
        }
        error={errors.startDate}
        showTimeInput
      />

      <DateInput
        name="stopDate"
        label="Окончание действия"
        value={createDateFromResponse(values.stopDate as unknown as string)}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setFieldValue('stopDate', createDateToRequestWithTimezone(e.target.value))
        }
        error={errors.stopDate}
        showTimeInput
      />
    </Grid>
  );
};
