import { AnalyticsData } from 'pages/data/analytics-data';
import subsystemIdsMap from 'constants/subsystemIdsMap';
import { ReagentsVegsRootPath } from 'pages/data/PGR';

export const VegetativeAnalytics = () => (
  <AnalyticsData
    title="Мониторинг воздействия ПГР на вегетативные части зеленых насаждений"
    rootPath={ReagentsVegsRootPath}
    subsystemId={subsystemIdsMap.pgrVegetative}
    mainDataTitle="Данные"
    analyticsDataTitle="Протоколы аналитической лаборатории"
  />
);
