export enum NotificationSource {
  DATA = 'Данные',
  PDK = 'ПДК',
  MOSECOM = 'Сайт МОСЭКОМониторинга',
  SENSOR = 'Датчик',
  FTP = 'FTP',
  NMU = 'Метео',
  COMPLAINT = 'Жалоба',
  USER = 'Служебные',
  BOUNDARY = 'Граничные значения',
}
