import { RenderTree } from 'features/WithSideBar/types';
import subsystemIdsMap from 'constants/subsystemIdsMap';
import { Industrial_PDV_RootPath } from './PDV/consts';
import { Industrial_PDV_NoSource_RootPath } from 'pages/data/IndustrialEmissions/PDVNoSource/consts';
import { Askza } from '../../home/icons';

export const IndustrialRootPath = '/data/industrial-emissions';
export const MonitoringDataPath = `${IndustrialRootPath}/monitoring`;
export const TodayRoot = `${MonitoringDataPath}/today`;
export const TwoDaysRoot = `${MonitoringDataPath}/two-days`;
export const WeekRoot = `${MonitoringDataPath}/week`;
export const MonthRoot = `${MonitoringDataPath}/month`;

export const industrialMenu: RenderTree = {
  id: IndustrialRootPath,
  name: 'Мониторинг пром. выбросов',
  url: IndustrialRootPath,
  subsystemId: subsystemIdsMap.emissionsRoout,
  homepageIcon: <Askza />,
  children: [
    {
      id: MonitoringDataPath,
      url: MonitoringDataPath,
      name: 'Данные мониторинга',
      subsystemId: subsystemIdsMap.emissions,
    },
    {
      name: 'ПДВ по источникам выбросов',
      id: Industrial_PDV_RootPath,
      url: Industrial_PDV_RootPath,
      subsystemId: subsystemIdsMap.emissions,
    },
    {
      name: 'ПДВ по предприятиям',
      id: Industrial_PDV_NoSource_RootPath,
      url: Industrial_PDV_NoSource_RootPath,
      subsystemId: subsystemIdsMap.emissions,
    },
  ],
};
