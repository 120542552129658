import * as yup from 'yup';
import { numberError, requiredError } from '../constans';

export const AtmosphereMonitoringSchema = yup.object().shape({
  paramId: yup.string().nullable().required(requiredError),
  stationId: yup.string().nullable().required(requiredError),
  avg: yup.object().shape({
    valDateTime: yup.string().nullable().required(requiredError),
    strValue: yup.number().notOneOf([NaN], numberError).typeError(numberError).required(requiredError),
    // version: yup.number(),
  }),
  max: yup.object().shape({
    valDateTime: yup.string().nullable().required(requiredError),
    strValue: yup.number().notOneOf([NaN], numberError).typeError(numberError).required(requiredError),
    // version: yup.number(),
  }),
  min: yup.object().shape({
    valDateTime: yup.string().nullable().required(requiredError),
    strValue: yup.number().notOneOf([NaN], numberError).typeError(numberError).required(requiredError),
    // version: yup.number(),
  }),
});
