import { useRequest } from 'hooks/useRequest';
import { useEffect } from 'react';

export function useRoleRights<T>(address: string) {
  const { get, isLoading, result, error } = useRequest<T>(address);
  useEffect(() => {
    get({
      params: {
        limit: -1,
      },
    });
  }, [get]);

  return {
    get,
    isLoading,
    result,
    error,
  };
}
