import { IReportPoint, ITableContext, ITask } from '../../../types';
import React, { useContext, useMemo } from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { getSelectOptionsFromDictionaryByName } from 'features/get-select-options-from-dictionary';
import { dictionaryToMap } from 'lib/utils/dictionaryToMap';
import { TableContext } from 'pages/citizenAppeal/RaidJournal/RaidTable/CustomRaidTable';
import { RowButtons } from '../RowButtons';
import { PointLine } from '../PointLine';

interface IProps {
  task: ITask;
}

export const TaskLine = ({ task }: IProps) => {
  const { tasksDictionaries, noise } = useContext<ITableContext>(TableContext);
  const taskRowSpan = (task.reportPoints?.length || 1) + 1;

  const districtsFinder = useMemo(() => {
    const forSelect = getSelectOptionsFromDictionaryByName(tasksDictionaries?.data, 'districts');
    return dictionaryToMap(forSelect);
  }, [tasksDictionaries]);

  const regionsFinder = useMemo(() => {
    const forSelect = getSelectOptionsFromDictionaryByName(tasksDictionaries?.data, 'regions');
    return dictionaryToMap(forSelect);
  }, [tasksDictionaries]);

  const districts = task.districts
    ?.map((district: string) => districtsFinder.get(district))
    .filter(Boolean)
    .join(', ');

  const regions = task.regions
    ?.map((region: string) => regionsFinder.get(region))
    .filter(Boolean)
    .join(', ');

  return (
    <React.Fragment key={task.id}>
      <TableRow>
        <TableCell rowSpan={taskRowSpan}>{task.number}</TableCell>
        <TableCell rowSpan={taskRowSpan}>{districts}</TableCell>
        <TableCell rowSpan={taskRowSpan}>{regions}</TableCell>
        <TableCell rowSpan={taskRowSpan}>{task.territoryName}</TableCell>
        <TableCell rowSpan={taskRowSpan}>{task.reason}</TableCell>
        <TableCell rowSpan={taskRowSpan}>{task.statusName}</TableCell>
      </TableRow>

      <>
        {task.reportPoints?.length ? (
          task.reportPoints?.map((point: IReportPoint, index) => (
            <TableRow key={point.pointNumber}>
              <PointLine point={point} />
              {!index ? <RowButtons taskRowSpan={taskRowSpan} task={task} /> : null}
            </TableRow>
          ))
        ) : (
          <TableRow>
            <TableCell colSpan={2} />
            {!noise ? (
              <>
                <TableCell colSpan={2} />
              </>
            ) : null}
            <RowButtons taskRowSpan={taskRowSpan} task={task} />
          </TableRow>
        )}
      </>
    </React.Fragment>
  );
};
