import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import { EquipmentLocale } from '../../locale';
import { EquipmentsAppRoutes } from '../../equipmentsAppRoutes';
import { stationsLocale } from '../locale';

export const useEquipmentStationsBreadcrumbs = () => {
  useUpdateBreadcrumbs([
    {
      breadcrumb: EquipmentLocale.root,
      path: EquipmentsAppRoutes.root,
    },
    {
      breadcrumb: stationsLocale.stations,
      path: EquipmentsAppRoutes.stations.root,
    },
  ]);
};
