export const COMMON_LOCALE = {
  data: 'Данные',
  from: 'От',
  to: 'До',
  clear: 'Очистить',
  apply: 'Применить',
  indicators: 'Показатели',
  configuration: 'Конфигурация',
  email: 'Email',
  password: 'Пароль',
  organization: 'Организация',
  login: 'Логин',
  loginAction: 'Войти',
  cancelAction: 'Отмена',
  resetPasswordAction: 'Сбросить пароль',
  resetPassword: 'Смена/Восстановление пароля',
  wsoLogin: 'Войти через WSO',
  cancel: 'Отмена',
  save: 'Сохранить',
  commonData: 'Общие сведения',
  coordinates: 'Координаты',
  attachments: 'Вложения',
  controlledObject: 'Контролируемый объект',
  region: 'Округ',
  district: 'Район',
  uploadDataFromFile: 'Загрузка данных из файла',
  week: 'За неделю',
  month: 'За месяц',
  year: 'За год',
  twoYears: 'За 2 года',
  text: {
    preview_of_uploaded_data: 'Предварительный просмотр загружаемых данных',
    addition_research_result: 'Добавление результата исследования',
    edit_research_result: 'Редактирование результата исследования',
    add_protocol_result: 'Добавление результатов протокола',
    edit_protocol_result: 'Редактирование результатов протокола',
  },
  signatories: 'Подписанты'
};
