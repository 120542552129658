import { useEffect, useState } from 'react';
import { Flex, Heading, Input, Link, Stack, Text } from '@chakra-ui/react';

import { Button } from 'components/common';
import { decimationAlgorithm } from '../../consts';
import { IChart } from '../../types';

interface IDataDecimationManage {
  chart: undefined | IChart;
}

export const DataDecimation = ({ chart }: IDataDecimationManage) => {
  const [lttbCount, setLttbCount] = useState(250);
  const [lttbCountCustom, setLttbCountCustom] = useState(100);

  const [optimizationType, setOptimizationType] = useState<keyof typeof decimationAlgorithm>(decimationAlgorithm.none);

  useEffect(() => {
    const decimation = chart?.options?.plugins?.decimation;
    if (decimation) {
      if (optimizationType === decimationAlgorithm.none) {
        decimation.enabled = false;
      } else {
        decimation.algorithm = optimizationType;

        if (decimation.algorithm === decimationAlgorithm.lttb) {
          decimation.samples = lttbCount;
        }
        decimation.enabled = true;
      }
      chart.update();
    }
  }, [lttbCount, optimizationType, chart]);

  return (
    <Flex mt={4} flexDirection="column">
      <Heading size="md">Оптимизация количества точек</Heading>
      <Text mb={2}>
        Для корректного отображения большого количества точек на графике можно использовать механизмы для уменьшения их
        количества. Используемые методы можно изучить &nbsp;
        <Link href="https://www.chartjs.org/docs/latest/configuration/decimation.html#decimation-algorithms" isExternal>
          здесь
        </Link>
      </Text>
      <Stack wrap="wrap" direction="row" mb={4}>
        <Button
          onClick={() => {
            setOptimizationType(decimationAlgorithm.none);
          }}
        >
          Отключить оптимизацию
        </Button>
        <Button
          onClick={() => {
            setOptimizationType(decimationAlgorithm['min-max']);
          }}
          marginLeft={20}
        >
          Min/Max оптимизация
        </Button>
        <Button
          onClick={() => {
            setOptimizationType(decimationAlgorithm.lttb);
            setLttbCount(50);
          }}
          marginLeft={20}
        >
          Оптимизация LTTB (50)
        </Button>
        <Button
          onClick={() => {
            setOptimizationType(decimationAlgorithm.lttb);
            setLttbCount(250);
          }}
          marginLeft={20}
        >
          Оптимизация LTTB (250)
        </Button>
      </Stack>
      <Stack direction="row" mb={4}>
        <Input
          value={lttbCountCustom}
          onChange={(e) => {
            const value = typeof parseInt(e.target.value) === 'number' ? parseInt(e.target.value) : 100;
            setLttbCountCustom(value);
          }}
          min={1}
          type="number"
          max={1000}
          width={215}
          // label="Степень оптимизации LTTB"
          step={1}
        />
        <Button
          onClick={() => {
            setOptimizationType(decimationAlgorithm.lttb);
            setLttbCount(lttbCountCustom || 1);
          }}
          marginLeft={20}
        >
          Оптимизация LTTB (
          {lttbCountCustom || 1}
          )
        </Button>
      </Stack>
    </Flex>
  );
};
