import { Button } from 'components/common';
import { Flex, Tooltip } from '@chakra-ui/react';
import { ReactComponent as CloseIcon } from 'features/Filter/icons/close.svg';
import { ReactComponent as SubmitIcon } from 'features/Filter/icons/tick.svg';
import React from 'react';

interface IProps {
  onReset: (event?: React.MouseEvent<HTMLButtonElement>) => void;
  onSubmit: (event?: React.MouseEvent<HTMLButtonElement>) => void;
  isLoading?: boolean;
  isDisabled?: boolean;
}

const FilterFormControls = ({ onReset, onSubmit, isLoading, isDisabled }: IProps) => (
  <Flex paddingLeft={2} marginLeft="auto" flexDirection="row" justifyContent="space-between" width="fit-content">
    <Tooltip backgroundColor="#9A9A9A" label="Отменить">
      <Button
        onClick={onReset}
        isLoading={isLoading}
        isDisabled={isDisabled}
        variant="outline"
        color="PrimaryButton.500"
        padding={0}
        height={45}
        width={45}
      >
        <CloseIcon />
      </Button>
    </Tooltip>

    <Tooltip backgroundColor="#9A9A9A" label="Применить">
      <Button
        marginLeft={4}
        variant="solid"
        colorScheme="PrimaryButton"
        onClick={onSubmit}
        isLoading={isLoading}
        isDisabled={isDisabled}
        padding={0}
        height={45}
        width={45}
      >
        <SubmitIcon />
      </Button>
    </Tooltip>
  </Flex>
);

export default FilterFormControls;
