import React, { useLayoutEffect } from 'react';
import { useClearUploadFile } from '.';

// TODO: file upload that uses context should be removed completely

const WithDidMountResetUploadFile: React.FC<{ Component: any }> = ({ Component }) => {
  const clear = useClearUploadFile();

  useLayoutEffect(() => {
    clear('inputFileMetadata', 'preview', 'uploadFileSettings');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Component />;
};

export { WithDidMountResetUploadFile };
