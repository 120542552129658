import { COMMON_LOCALE } from 'constants/common';

export const fieldsNoiseLocale: Record<string, string> = {
  sourceId: 'Станция / Место наблюдения',
  date: 'Дата и время измерения',
  operationMode: 'Режим работы станции',
  calculationMode: 'Режим расчета уровней по октавам',

  noise: 'Шум',
  noiseMin: 'Минимальный уровень звука',
  noiseMinDate: 'Время минимального уровня звука',
  noiseMax: 'Максимальный уровень звука',
  noiseMaxDate: 'Время максимального уровня звука',
  noiseEquivalent: 'Эквивалентный уровень звука',

  octave1Value: 'Измеренные значения на 31,5 Гц',
  octave2Value: 'Измеренные значения на 63 Гц',
  octave3Value: 'Измеренные значения на 125 Гц',
  octave4Value: 'Измеренные значения на 250 Гц',
  octave5Value: 'Измеренные значения на 500 Гц',
  octave6Value: 'Измеренные значения на 1000 Гц',
  octave7Value: 'Измеренные значения на 2000 Гц',
  octave8Value: 'Измеренные значения на 4000 Гц',
  octave9Value: 'Измеренные значения на 8000 Гц',

  ip: 'IP адрес',
  insideTemperature: 'Температура внутри станции',
  voltage: 'Напряжение на входе UPS',
  coefficient1: 'Коэффициент первого канала',
  coefficient2: 'Коэффициент второго канала',
  coefficient3: 'Коэффициент третьего канала',
  coefficient4: 'Коэффициент четвертого канала',
  door: 'Открытие двери на станции',
};

export const fieldsMeteoLocale: Record<string, string> = {
  sourceId: 'Идентификатор станции',
  date: 'Дата и время измерения',
  period: 'Период измерения в минутах',
  temperatureAvg: 'Среднее значение температуры за период',
  temperatureMax: 'Максимальное значение температуры',
  temperatureMaxDate: 'Время максимального значения температуры',
  temperatureMin: 'Минимальное значение температуры',
  temperatureMinDate: 'Время минимального значения температуры',
  airPressure: 'Атмосферное давление',
  rainfall: 'Осадки',
  humidityAvg: 'Среднее значение влажности за период',
  humidityMin: 'Минимальное значение влажности',
  humidityMinDate: 'Время минимального значения влажности',
  humidityMax: 'Максимальное значение влажности',
  humidityMaxDate: 'Время максимального значения влажности',
  windDirection: 'Векторная сумма параметра за период',
  windSpeedAvg: 'Средняя скорость ветра за период',
  windSpeedMin: 'Минимальная скорость ветра',
  windSpeedMinDate: 'Время минимального значения скорости ветра',
  windSpeedMax: 'Максимальная скорость ветра',
  ip: 'IP адрес',
  windSpeedMaxDate: 'Время максимального значения скорости ветра',
  insideTemperature: 'Температура внутри станции',
  voltage: 'Напряжение на входе UPS',
  coefficient1: 'Коэффициент первого канала',
  coefficient2: 'Коэффициент второго канала',
  coefficient3: 'Коэффициент третьего канала',
  coefficient4: 'Коэффициент четвертого канала',
  door: 'Открытие двери на станции',
  temperature: 'Температура',
  humidity: 'Влажность',
  windSpeed: 'Ср. скорость ветра',
};

export const fieldInstructionLocale: Record<string, string> = {
  address: 'Адрес объекта',
  name: 'Наименование объекта',
  coordinates: 'Координаты установки размещенной станции',
  description: 'Описание задания',
  districtId: 'Идентификатор района',
  stationId: 'Идентификатор размещенной станции',
  instructionDate: 'Дата поручения',
  number: 'Номер поручения',
  regionId: 'Идентификатор округа',
  startDate: 'Дата начала измерения',
  stopDate: 'Дата окончания измерения',
  type: 'Тип поручения',
  noiseSource: 'Источник шума',
  placementAddress: 'Адрес размещенной станции',
  territoryType: 'Тип территории',
  note: 'Примечание',
  latitude: 'Широта',
  longitude: 'Долгота',
  wayType: 'Категория автомобильной дороги'
};

export const tabsLocale = {
  commonData: COMMON_LOCALE.commonData,
  noise: 'Шум',
  selfDiagnostic: 'Самодиагностика',
  octaves: 'Октавы',
  indicators: COMMON_LOCALE.indicators,
};
export const tabsNoiseLocaleList = [
  tabsLocale.commonData,
  tabsLocale.noise,
  tabsLocale.selfDiagnostic,
  tabsLocale.octaves,
];

export const tabsMeteoLocaleList = [tabsLocale.commonData, tabsLocale.selfDiagnostic, tabsLocale.indicators];

export const tableLocale = {
  title: 'Контроль шума мобильными автоматическими станциями (М-АСКШ)',
  instructions: {
    add: 'Добавить поручение',
  },
  columns: {
    recieved: 'Источник',
    user: 'Пользователь',
    packageNumber: 'Номер пакета',
    packageDate: 'Дата/время формирования пакета',
    packageProcessed: 'Дата/время обработки пакета',
    stationId: '№ станции',
    placeId: 'Место наблюдения',
    parameterId: 'Параметр',
    unitId: 'Ед. измерения',
    measureDate: 'Дата/время измерения',
    valueStr: 'Значение',
    type: 'Тип измерения',
    packageDateTime: 'Дата и время пакета',
    sourceId: 'Место наблюдения',
    dateTime: 'Дата и время',
    min: 'min',
    max: 'max',
    avg: 'avg',
    instructionDate: 'Дата/время поручения',
    instructionNumber: 'Номер поручения'
  },
};
