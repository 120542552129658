import { useRequest } from 'hooks/useRequest';
import { useCallback, useEffect, useMemo } from 'react';
import { IDictionary, IDictionaryResponse } from '../const/types';
import { NSIApiRouteRoot } from '../const/routes';

export function useNSIDictionary(id: string): {
  dictionary: IDictionary | null;
  isLoading: boolean;
} {
  const { get, isLoading, result } = useRequest<IDictionaryResponse>(`${NSIApiRouteRoot}/${id}`);

  const getDictionary = useCallback(async () => get(), [get]);

  const dictionary: IDictionary | null = useMemo(() => {
    if (result) {
      const _dictionary: IDictionary = {
        ...result,
        schema: JSON.parse(result.schema),
      };
      return _dictionary;
    }
    return null;
  }, [result]);

  useEffect(() => {
    void getDictionary();
  }, [getDictionary]);

  return { dictionary, isLoading };
}
