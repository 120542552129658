import { Field, useFormikContext } from 'formik';
import { Checkbox, FieldsFormControls, Input, Select } from 'components/common';
import { Box, Grid } from '@chakra-ui/react';
import { ISubstanceValues, pdkLocales } from 'pages/dictionaries/NSIPdk/consts';
import { TDictionariesArray } from 'models/dictionaries';
import { getSelectOptionsFromDictionaryByName } from 'features/get-select-options-from-dictionary';
import { useMemo } from 'react';

interface Props {
  dictionaries?: TDictionariesArray;
  isEditMode?: boolean;
  onNextTab: () => void;
  onCancel: () => void;
  editedObject?: ISubstanceValues | null;
}

export const SubstanceСharacteristics = ({ dictionaries, isEditMode, onNextTab, onCancel, editedObject }: Props) => {
  const { values, errors, handleSubmit } = useFormikContext<ISubstanceValues>();

  const allDangerClasses = useMemo(
    () => getSelectOptionsFromDictionaryByName(dictionaries, 'dangerClasses'),
    [dictionaries],
  );
  const dangerClasses = useMemo(
    () =>
      getSelectOptionsFromDictionaryByName(
        dictionaries,
        'dangerClasses',
        false,
        allDangerClasses.find((item) => item.name === editedObject?.dangerClass)?.value,
      ),
    [allDangerClasses, dictionaries, editedObject?.dangerClass],
  );

  return (
    <>
      <Grid width="60%" gap={3}>
        <Field as={Input} name="chemName" label={pdkLocales.substancesColumns.substanceName} error={errors?.chemName} />
        <Field as={Input} name="chemFormula" label={pdkLocales.substancesColumns.formule} error={errors?.chemFormula} />
        <Field
          as={Input}
          name="chemShortName"
          label={pdkLocales.substancesColumns.shortName}
          error={errors?.chemShortName}
        />
        <Field
          as={Select}
          name="dangerClass"
          label={pdkLocales.substancesColumns.dangerClass}
          error={errors?.dangerClass}
          options={dangerClasses}
        />
        <Field
          as={Checkbox}
          name="notChemical"
          checked={values.notChemical}
          label={pdkLocales.substancesColumns.notChemical}
        />
      </Grid>
      <Box width="20%">
        <FieldsFormControls
          onCancel={onCancel}
          handleSubmit={handleSubmit}
          onNextTab={!isEditMode ? onNextTab : undefined}
          showSave={isEditMode}
        />
      </Box>
    </>
  );
};
