import { FormHeader } from 'components/common';
import { ERROR_MESSAGES, SUCCESS_MESSAGES } from 'constants/messages';

import { createTouchedErrors } from 'features/create-touched-errors';
import { FormikProvider, useFormik } from 'formik';
import { useNotifications } from 'hooks/useNotifications';
import { useRequest } from 'hooks/useRequest';

import { useLocation } from 'react-router-dom';
import { initialEmptyValues } from './initialEmptyValues';
import FieldsForm from './FieldsForm';
import { SubsoilSchema } from 'models/schemas/subsoil/subsoil';
import { useGoBackWithFallback } from 'lib/utils/goBackWithFallback';
import { useDictionary } from 'hooks/useDictionary';

export const EditSubsoilReport = () => {
  const { backWithFallback } = useGoBackWithFallback();
  const location = useLocation();

  const editedObject = location.state;

  const { isDictionariesLoading, dictionaries } = useDictionary({ url: '/subsoil/v10/subsoils/dictionaries' });

  const { successNotify, errorNotify } = useNotifications();

  const onSuccess = () => {
    successNotify({
      key: 'file/create/success',
      message: SUCCESS_MESSAGES.EDIT,
    });
    backWithFallback('/data/subsoil');
  };
  const onError = () =>
    errorNotify({
      key: 'file/create/error',
      message: ERROR_MESSAGES.EDIT,
    });

  const { put, isLoading: isPutLoading } = useRequest('/subsoil/v10/subsoils', onSuccess, onError);

  const initialValues = {
    ...initialEmptyValues,
    ...editedObject,
  };

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    initialValues,
    validationSchema: SubsoilSchema,
    onSubmit: (values) => put(values),
  });

  return (
    <FormikProvider
      value={{
        ...formik,
        errors: createTouchedErrors(formik.errors, formik.touched),
      }}
    >
      <FormHeader onBackClick={() => backWithFallback('/data/subsoil')} header="Редактирование результатов измерения" />
      <FieldsForm
        dictionaries={dictionaries}
        onCancel={() => backWithFallback('/data/subsoil')}
        isLoading={isDictionariesLoading || isPutLoading}
        editedObject={editedObject}
      />
    </FormikProvider>
  );
};
