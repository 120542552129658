import type * as ChartJs from 'chart.js';
import { Chart, ChartEvent, DefaultDataPoint, LegendItem, LegendOptions } from 'chart.js';
import { ZoomPluginOptions } from 'chartjs-plugin-zoom/types/options';

// вписываем типы в одном месте, где можем настроить типы графиков
export type IChart = Chart<'line', DefaultDataPoint<'line'>>;
export type IChartData = ChartJs.ChartData;
export type IChartOptions = ChartJs.ChartOptions;
export type IChartOptionsPlugins = IChartOptions['plugins'];
export type IChartOptionsPluginsLegend = LegendOptions<'line'>;
export type IChartOptionsScales = IChartOptions['scales'];
export type IChartPluginsZoom = ZoomPluginOptions;
export type IChartChartEvent = ChartEvent;
export type IChartLegendItem = LegendItem;

export enum PointTypes {
  MIN = 'MIN',
  MAX = 'MAX',
  ABS = 'ABS',
  AVG = 'AVG',
}

export interface IFilterValues {
  startDate: string;
  stopDate: string;
  parameters?: string[];
  sources?: string[];
  registryIds?: string[];
  valueIds?: string[];
  initialData?: boolean;
}

export type IFilterValuesDefault = Pick<IFilterValues, 'stopDate' | 'startDate'>;

export type IPointTypes = keyof typeof PointTypes;

export interface IPoint {
  x: number;
  y: number;
}

export interface IPointWithType {
  type: string;
}

export interface IParam {
  id: string;
  name: string;
  unit: string;
  yAxisID?: string;
  values: IPointWithType[];
  minValues: IPointWithType[];
  maxValues: IPointWithType[];
  avgValues: IPointWithType[];
  absValues: IPointWithType[];
}

export interface ISourceData {
  sourceId: string;
  sourceName: string;
  parameters: IParam[];
}

export type IServerData = ISourceData[];

export interface ISubSystem {
  subsystemId: string;
  subsystemName: string;
  templateId: string;
  templateName: string;
}

export type IPeriodName =
  | 'oneMinute'
  | 'twentyMinutes'
  | 'oneHour'
  | 'oneDay'
  | 'oneWeek'
  | 'oneMonth'
  | 'quarter'
  | 'sixMonth'
  | 'oneYear';
