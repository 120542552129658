import { FormHeader } from 'components/common';

import { TDictionariesArray } from 'models';

import { MASKSHInstructions } from 'pages/data/MASKSH/consts';
import { InstructionFormFields } from 'pages/data/MASKSH/Instructions/InstructionsForm/Form/InstructionFormFields';
import { IUploadMedia } from 'components/common/FileUploadDragAndDrop/types';
import { Box } from '@chakra-ui/react';
import { useGoBackWithFallback } from 'lib/utils/goBackWithFallback';

interface InstructionFields {
  dictionaries: {
    data: TDictionariesArray;
  } | null;
  isEditMode?: boolean;
  media: IUploadMedia[];
  setMedia: (media: IUploadMedia[]) => void;
  uploadItemId?: string;
  isViewMode?: boolean;
  url?: string;
}

export const InstructionForm = ({
  dictionaries,
  isEditMode,
  media,
  setMedia,
  uploadItemId,
  isViewMode,
  url,
}: InstructionFields) => {
  const { backWithFallback } = useGoBackWithFallback();
  // eslint-disable-next-line no-nested-ternary
  const header = `${isEditMode ? (isViewMode ? 'Просмотр' : 'Редактирование') : 'Добавление'} поручения`;

  return (
    <Box flexDirection="column">
      <FormHeader onBackClick={() => backWithFallback(url || MASKSHInstructions)} header={header} />
      <InstructionFormFields
        isViewMode={isViewMode}
        isExists={isEditMode}
        dictionaries={dictionaries}
        media={media}
        setMedia={setMedia}
        uploadItemId={uploadItemId}
      />
    </Box>
  );
};
