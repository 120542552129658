import { useRequest } from 'hooks/useRequest';
import { useCallback, useEffect, useMemo } from 'react';
import { IDictionaryItem, IServDictionaryItem } from '../const/types';
import { NSIApiRouteRoot } from '../const/routes';

export function useNSIDictionaryItem(
  dictionaryId: string,
  itemId: string
): {
  item: IDictionaryItem | null;
  isLoading: boolean;
} {
  const { get, result, isLoading } = useRequest<IServDictionaryItem>(
    `${NSIApiRouteRoot}/${dictionaryId}/item/${itemId}`
  );

  const getDictionaryItems = useCallback(async () => get(), [get]);

  const item: IDictionaryItem | null = useMemo(() => {
    if (result) {
      return {
        ...result,
        aliases: result.aliases || [],
        value: result.value || {},
      };
    }
    return null;
  }, [result]);

  useEffect(() => {
    void getDictionaryItems();
  }, [getDictionaryItems]);

  return { item, isLoading };
}
