import React, { FC, useMemo } from 'react';
import { Box, Flex, Grid, Text } from '@chakra-ui/react';
import { Input } from 'components/common';
import { Field, FieldArray, useFormikContext } from 'formik';
import { InclinometerRecord, InclinometerSaveItem } from 'hooks/geo/Inclinometer/useGeoInclinometerList';
import { TDictionariesArray } from 'models/dictionaries';
import { getSelectOptionsFromDictionaryByName } from 'features/get-select-options-from-dictionary';
import { parametersNames } from '../LoadPreview/types';

interface ParametersTabProps {
  dictionaries: {
    data: TDictionariesArray;
  } | null;
  isWrite?: boolean;
}

export const ParametersTab: FC<ParametersTabProps> = ({ children, isWrite, dictionaries }) => {
  const { values, errors } = useFormikContext<InclinometerSaveItem>();
  const parameters = useMemo(() => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'parameters'), [dictionaries]);
  return (
    <Box flexDirection="column">
      <Grid margin="30px 0px 20px 110px" gap="15px">
        <FieldArray name="data">
          {() => (
            <Box flexDirection="column">
              {values.data.length > 0 &&
              values.pointId &&
              values.data.map((data, index) => (
                <Flex key={data.depthId} marginBottom={30} alignItems="start">
                  <Grid
                    gap="15px"
                    flexGrow={1}
                    marginRight={15}
                    alignItems="baseline"
                    templateColumns="150px 200px 200px 200px"
                  >
                    <Text fontSize={18} fontWeight={700}>{`Глубина ${data.depth}, м`}</Text>
                    {
                      parameters.map((param) => (
                        <Field
                          as={Input}
                          name={`data.${index}.${parametersNames[param.value]}`}
                          label={param.name}
                          isDisabled={!isWrite}
                          error={
                            errors &&
                            errors.data &&
                            errors.data[index] &&
                            // @ts-ignore
                            (errors?.data[index] as unknown as InclinometerRecord)[parametersNames[param.value]]
                          } // TODO:-> Refactor in future
                        />
                      ))
                    }
                  </Grid>
                </Flex>
              ))}
            </Box>
          )}
        </FieldArray>
        {children}
      </Grid>
    </Box>
  );
};
