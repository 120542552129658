import { devicesStatuses } from './consts';
import { IDatasetGantt, IGanttChartType, IServerDataGantt } from './types';
import { ERROR_MESSAGES } from 'constants/messages';

const createViewObject = (backgroundColor = 'gray') => ({
  borderColor: backgroundColor,
  backgroundColor,
  borderWidth: 50,
  pointHitRadius: 50,
  pointRadius: 0,
  pointBorderWidth: 0,
  pointHoverRadius: 0,
});

export function modifyDataFromServer(
  data:IServerDataGantt, type:IGanttChartType
) {
  if (data?.dataSet) {
    if (data.dataSet.length) {
      const newData: { datasets: IDatasetGantt[] } = {
        datasets: []
      };
      // @ts-ignore
      newData.datasets = data.dataSet.map((item) => {
        // @ts-ignore
        const y = type === 'device' ? item.station ?? 'Склад' : item.deviceInventName ?? 'Не указано';
        // @ts-ignore
        const status = type === 'device' ? item.statusId : item.deviceStatusId;
        const newItem = {
          borderWidth: 50,
          pointBorderWidth: 4,
          pointHoverRadius: 4,
          data: [{
            x: new Date(item.data[0]).getTime(),
            y
          }, {
            // убрать обертку, когда бэкендер сделает красиво
            x: new Date(item.data[1]).getTime(),
            y
          }
          ],
        };

        // TODO: find out why there can be no status
        // @ts-ignore
        return status ? Object.assign(newItem, createViewObject(devicesStatuses[status]?.color)) : newItem;
      });

      return newData;
    }
    throw new Error(ERROR_MESSAGES.NO_DATA);
  }
  throw new Error(ERROR_MESSAGES.GET_DATA);
}
