import React, { useCallback, useState } from 'react';
import { GreenPlantsForm } from '../../Form/GreenPlantsForm';
import { greenPlantsFormInitialValues, GreenPlantsFormTypes } from '../../Form/types';
import { useFloraAddItem } from './useFloraAddItem';
import { useFloraDictionaries } from '../useFloraDictionaries';
import { TGreenPlantsForm } from 'pages/data/GreenPlants/Form/types';
import { updateFiles } from 'api/services/media';
import { ERROR_MESSAGES } from 'constants/messages';
import { useObjectType } from 'hooks/useObjectType';
import { IUploadMedia } from 'components/common/FileUploadDragAndDrop/types';
import { useNotifications } from 'hooks';
import { useHistory } from 'react-router-dom';
import {
  createGreenPlantsAddUrl,
  DeicingGreenPlantsRootPath,
  DeicingStatements,
  GreenPlantsFloraUrl,
} from 'pages/routes/PGRGreenPlants';
import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import appLocale from 'constants/appLocale';
import { ReagentsRootPath } from '../../../../consts';
import { useGoBackWithFallback } from 'lib/utils/goBackWithFallback';

const withAddFlora =
  (Component: typeof GreenPlantsForm): React.FC =>
    // eslint-disable-next-line react/display-name
    () => {
      const { backWithFallback } = useGoBackWithFallback();
      const type = 'flora';
      const defaultIndex = Object.values(GreenPlantsFormTypes)
        .indexOf(type);
      const { addFlora, isLoading } = useFloraAddItem();
      const { dictionaries, result, isLoading: isLoadingDicts } = useFloraDictionaries();
      const objectType = useObjectType(result);
      const [media, setMedia] = useState<IUploadMedia[]>([]);
      const { errorNotify } = useNotifications();
      const history = useHistory();

      useUpdateBreadcrumbs([
        { breadcrumb: appLocale.common.data, path: '/data' },
        {
          breadcrumb: appLocale.subsystems.reagents,
          path: ReagentsRootPath,
        },
        {
          breadcrumb: 'Воздействие на зеленые насаждения',
          path: DeicingGreenPlantsRootPath,
        },
        {
          breadcrumb: 'Ведомости',
          path: DeicingStatements,
        },
        {
          breadcrumb: 'Деревья и кустарники',
          path: GreenPlantsFloraUrl,
        },
        {
          breadcrumb: 'Добавление результатов',
          path: createGreenPlantsAddUrl('flora'),
        },
      ]);

      const onError = useCallback(
        (message?: string | null) => {
          errorNotify({
            key: message || 'file/create/error',
            message: message || ERROR_MESSAGES.CREATE,
          });
        },
        [errorNotify],
      );

      const onSubmit = useCallback(
        async (values: Partial<TGreenPlantsForm>) => {
          const response = await addFlora(values.flora);

          if ('registryId' in response) {
            await updateFiles(response.registryId, objectType, media)
              .then(() => {
                backWithFallback(GreenPlantsFloraUrl);
              })
              .catch((error: Error) => {
                onError(error.message);
                history.replace(
                  `${DeicingStatements}/flora/edit/${response.registryId}?openAttachmentsTab=true`,
                  response,
                );
              });
          }
        },
        [addFlora, backWithFallback, history, media, objectType, onError],
      );

      return (
        <Component
          header="Добавление результатов"
          defaultIndex={defaultIndex}
          initialValues={greenPlantsFormInitialValues}
          type={type}
          isSubmitting={isLoading || isLoadingDicts}
          onSubmit={onSubmit}
          floraDictionaries={dictionaries}
          media={media}
          setMedia={setMedia}
          backUrl={`${DeicingStatements}/${type}`}
        />
      );
    };
export { withAddFlora };
