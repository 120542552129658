import { RoutesList } from 'components/common';
import { EquipmentsAppRoutes } from '../../equipmentsAppRoutes';
import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import { EquipmentLocale } from '../../locale';
import appLocale from 'constants/appLocale';
import { useRoutesList } from 'hooks/useRoutesList';
import { equipmentReportsMenu } from '../../equipmentLeftMenu';

export const EquipmentReportsList = () => {
  useUpdateBreadcrumbs([
    {
      breadcrumb: EquipmentLocale.root,
      path: EquipmentsAppRoutes.root,
    },
    {
      breadcrumb: appLocale.equipmentPage.reports,
      path: EquipmentsAppRoutes.reports.root,
    },
  ]);
  const routes = useRoutesList(equipmentReportsMenu);
  return <RoutesList routes={routes} />;
};
