export const AISTabs = [
  'АИС Погода, южная мачта',
  'АИС Погода, северо-восточная мачта',
  'АИС Погода, северо-западная мачта',
  'АИС Погода, башня Останкино',
  'АИС Погода',
];

export const AISTabsNames = new Map<any, string>(Object.entries({
  'АИС Погода, южная мачта': 'Мачта Ю',
  'АИС Погода, северо-восточная мачта': 'Мачта СВ',
  'АИС Погода, северо-западная мачта': 'Мачта СЗ',
  'АИС Погода, башня Останкино': 'Башня',
  'АИС Погода': 'Сводные',
}));
