import { geoecologicalProcessesMenu } from 'pages/data/GeoecologicalProcesses';
import { soilMenu } from 'pages/data/Soil';
import { atmosphereMenu } from 'pages/data/Atmosphere';
import { industrialMenu } from 'pages/data/IndustrialEmissions';
import { subsoilMenu } from 'pages/data/Subsoil';
import { reagentsMenu } from 'pages/data/PGR';
import { maskshMenu } from 'pages/data/MASKSH/consts/menu';
import { AdminMenu } from 'pages/admin';
import { FolderSmall } from 'features/WithSideBar/icons';
import appLocale from 'constants/appLocale';
import { RenderTree } from 'features/WithSideBar/types';
import { equipmentLeftMenu } from 'pages/equipment/equipmentLeftMenu';
import { waterRoutesConfigMenu } from 'pages/data/Water';
import meteoMenu from 'pages/data/Meteo/menu';
import { reportsAnalyticsMenu } from 'pages/analyticsReports/reportsAnalyticsMenu';
import { nsiMenu } from 'features/WithSideBar/menus/nsiMenu';
import { pelRaidsMenu } from 'features/WithSideBar/menus/pelRaidsMenu';
import { sentryJournalMenu } from 'features/WithSideBar/menus/sentryJournalMenu';
import { pdkExcessMenu } from 'features/WithSideBar/menus/pdkExcessMenu';
import { managerMenu } from 'features/WithSideBar/menus/managerMenu';
import { citizenAppealsMenu } from 'features/WithSideBar/menus/citizenAppealsMenu';
import { cartographyMenu } from 'features/WithSideBar/menus/cartographyMenu';
import { analyticsLabMenu } from 'features/WithSideBar/menus/analyticsLabMenu';
import { protocolsMenu } from 'features/WithSideBar/menus/protocolsMenu';
import { burningRegistrationJournalMenu } from 'features/WithSideBar/menus/burningRegistrationJournalMenu';
import { incidentRecordingMenu } from 'features/WithSideBar/menus/incidentRecordingMenu';
import React from 'react';
import { Folder } from 'pages/home/icons';
import { dataEntryLogMenu } from './menus/dataEnryLogMenu';
import { sentryMenu } from './menus/sentryMenu';
import { greenplantsMenu } from './menus/greenplantsMenu';

export const dataMenu: RenderTree = {
  name: appLocale.common.data,
  id: '/data',
  icon: <FolderSmall />,
  homepageIcon: <Folder />,
  url: '/data',
  children: [
    atmosphereMenu,
    waterRoutesConfigMenu,
    geoecologicalProcessesMenu,
    greenplantsMenu,
    maskshMenu,
    meteoMenu,
    industrialMenu,
    subsoilMenu,
    reagentsMenu,
    soilMenu,
  ],
};

const menu: Array<RenderTree> = [
  AdminMenu,
  reportsAnalyticsMenu,
  analyticsLabMenu,
  dataMenu,
  protocolsMenu,
  equipmentLeftMenu,
  cartographyMenu,
  nsiMenu,
  citizenAppealsMenu,
  managerMenu,
  pdkExcessMenu,
  sentryJournalMenu,
  sentryMenu,
  pelRaidsMenu,
  burningRegistrationJournalMenu,
  incidentRecordingMenu,
  dataEntryLogMenu,
];

export { menu };
