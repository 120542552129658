import { Button, Input } from 'components/common';
import { Flex, Grid, Heading } from '@chakra-ui/react';
import { LightModeWrapper } from 'components/common/LightModeWrapper/LightModeWrapper';
import { Field, Form, FormikProvider, useFormik } from 'formik';
import { initialChangePasswordValues as initialValues } from 'pages/auth/config';
import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { apiAuthPaths } from 'api/services/auth';
import { useNotifications, useRequest } from 'hooks';
import { ApiError } from '../../models';
import { formatErrorMessage } from '../../lib/utils/formatErrorMessage';

export const ChangePasswordDialog: React.FC = () => {
  const { errorNotify, successNotify } = useNotifications();
  const onError = (message?: string | null | ApiError) => {
    errorNotify({
      key: 'login/error',
      message: formatErrorMessage(message) || 'Ошибка при изменении пароля',
    });
  };
  const onSuccess = () => {
    successNotify({
      key: 'login/success',
      message: 'Пароль успешно изменен',
    });
    push('/');
  };
  const { post: changePassword, isLoading } = useRequest(apiAuthPaths.user.changePassword, onSuccess, onError);

  const { push } = useHistory();
  const useFormikProps = useMemo(
    () => ({
      enableReinitialize: true,
      validateOnChange: false,
      validateOnMount: false,
      validateOnBlur: true,
      initialValues,
      onSubmit: (values: any) => {
        if (values.password !== values.oldPassword) {
          changePassword(values);
        }
      },
    }),
    [changePassword],
  );

  const { submitForm, values, setFieldValue, errors, touched, ...formik } = useFormik(useFormikProps);
  const handleCancel = () => {
    push('/');
  };

  const canSave =
    values.password && values.oldPassword && values.repeatPassword && values.password === values.repeatPassword;
  return (
    <Flex
      minHeight="100vh"
      display="flex"
      alignItems="center"
      flexDir="column"
      bgPosition="center"
      bgRepeat="no-repeat"
      bgSize="cover"
    >
      <Flex direction="column" minHeight="100vh" maxW="500px" justifyContent="space-between">
        <Flex flexGrow={1} alignItems="center" justifyContent="center">
          <LightModeWrapper>
            <Flex maxH="520px" direction="column" minWidth="375px" bgColor="white" p={10}>
              <Flex mb={8} alignItems="center" justifyContent="center">
                <Heading my={2} size="lg" color="black">
                  Изменить пароль учетной записи.
                </Heading>
              </Flex>
              <FormikProvider
                value={{
                  submitForm,
                  values,
                  setFieldValue,
                  errors,
                  touched,
                  ...formik,
                }}
              >
                <Form>
                  <Flex direction="column">
                    <Grid gap={2}>
                      <Field
                        as={Input}
                        label="Текущий пароль"
                        name="oldPassword"
                        type="password"
                        error={errors.oldPassword}
                      />
                      <Field
                        variant="outline"
                        as={Input}
                        label="Новый пароль"
                        name="password"
                        type="password"
                        error={errors.password}
                      />
                      <Field
                        variant="outline"
                        as={Input}
                        label="Повторение пароля"
                        name="repeatPassword"
                        type="password"
                        error={errors.repeatPassword}
                      />
                      <LightModeWrapper>
                        <Button
                          mt={2}
                          variant="solid"
                          colorScheme="PrimaryButton"
                          onClick={submitForm}
                          isLoading={isLoading}
                          isFullWidth
                          disabled={!canSave}
                        >
                          Сохранить изменения
                        </Button>
                        <Button mt={2} variant="outline" onClick={handleCancel} isFullWidth>
                          Отмена
                        </Button>
                      </LightModeWrapper>
                    </Grid>
                  </Flex>
                </Form>
              </FormikProvider>
            </Flex>
          </LightModeWrapper>
        </Flex>
      </Flex>
    </Flex>
  );
};
