import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import { devicesLocale } from '../consts/locale';
import { EquipmentLocale } from '../../locale';
import { EquipmentsAppRoutes } from '../../equipmentsAppRoutes';

export const useEquipmentDeviceBreadcrumbs = () => {
  useUpdateBreadcrumbs([
    {
      breadcrumb: EquipmentLocale.root,
      path: EquipmentsAppRoutes.root,
    },
    {
      breadcrumb: devicesLocale.devices,
      path: EquipmentsAppRoutes.devices.root,
    },
  ]);
};
