import { useEnhancedNotification, useRequest } from 'hooks';
import { useCallback } from 'react';

const JournalLogEvents = {
    openSuperset: (url: string, name: string) => ({
        message: `Осуществлен переход по ссылке ${url}`,
        level: 'INFO',
        type: 'PROTOKOL_USER_ACTION',
        subsystem: 'Аналитическая отчетность',
        module: 'Superset',
        function: `Переход к построению отчета "${name}"`,
    }),
};

export const useJournalLog = () => {
    const { onErrorCreate } = useEnhancedNotification();
    const { post } = useRequest('/admin/v10/admin/journal', undefined, onErrorCreate);

    const logExternalUrl = useCallback((url, name) => {
        if (url.includes('/external_reports/superset')) {
            void post(JournalLogEvents.openSuperset(url, name));
        }
    }, [post]);

    return {
        logExternalUrl
    };
};
