import { Filter } from 'features/Filter';
import { createInitialValuesToFormik, createValuesToRequest } from 'features/Filter/utils';
import React, { useMemo } from 'react';
import { IFilterItem, TFilterDTO } from 'models';
import combineFilters from 'lib/utils/combineFilters';
import { transformFiltersArray } from 'features/Filter/transformFiltersArray';
import { NotificationSource } from 'pages/admin/models/NotificationSource';
import { SelectOption } from 'components/common';
import appLocale from 'constants/appLocale';
import { TOption } from 'pages/admin/models/toption';
import { INotification } from 'pages/Notifications/types';

type FilterListInitialValues = Record<'type' | 'channels' | 'deleted' | 'subsystemId' | 'isRead' | 'dateTimeMin' | 'dateTimeMax',
  TFilterDTO<keyof INotification>['filters'][number]>;

interface FilterProps {
  isLoading: boolean;
  onSubmit: (values: TFilterDTO<keyof INotification>['filters']) => void;
  filters: IFilterItem[];
  subsystemOptions: TOption[];
  defaultType?: keyof typeof NotificationSource;
}

const initialValues: (defaultType?: string) => FilterListInitialValues = (defaultType) => ({
  dateTimeMin: {
    key: 'date',
    operation: 'GTE',
    value: null,
  },
  dateTimeMax: {
    key: 'date',
    operation: 'LTE',
    value: null,
  },
  subsystemId: {
    key: 'subsystemId',
    operation: 'EQ',
    value: null,
  },
  type: {
    key: 'type',
    operation: 'EQ',
    value: defaultType || null,
  },
  channels: {
    key: 'channels',
    operation: 'IN',
    value: null,
  },
  isRead: {
    key: 'isRead',
    operation: 'EQ',
    value: false,
  },
  deleted: {
    key: 'deleted',
    operation: 'EQ',
    value: false,
  },
});

function NotificationFilter({
  isLoading,
  onSubmit,
  children,
  filters,
  subsystemOptions,
  defaultType,
}: React.PropsWithChildren<FilterProps>) {
  const initialValuesToFormik = createInitialValuesToFormik(initialValues(defaultType));
  const type = useMemo(() => {
    const map: SelectOption[] = [];
    Object.entries(NotificationSource)
      .forEach((v) => map.push({
        name: v[1],
        value: v[0],
      }));
    return map;
  }, []);

  const presetValues = useMemo<{ key: string, value: IFilterItem['value'] }[]>(() => {
    const preset: { key: string, value: IFilterItem['value'] }[] = [];
    const initialFilters = combineFilters(initialValues(defaultType), filters);
    Object.keys(initialFilters)
      .forEach((key) => {
        preset.push({ key: `${key}`, value: initialFilters[key].value });
      });
    return preset;
  }, [filters, defaultType]);

  return (
    <Filter<typeof initialValuesToFormik>
      isLoading={isLoading}
      isDisabled={isLoading}
      onSubmit={(values) => {
        const v = createValuesToRequest(initialValues(defaultType), values);
        const filters = transformFiltersArray<TFilterDTO<keyof INotification>['filters']>(
          Object.values(v),
        );
        onSubmit(filters);
      }}
      initialValues={initialValuesToFormik}
      mainFields={[
        {
          type: 'DATE',
          name: 'dateTimeMin',
          label: 'От',
          showTimeInput: true,
        },
        {
          type: 'DATE',
          name: 'dateTimeMax',
          label: 'До',
          showTimeInput: true,
        },
        {
          type: 'SELECT',
          name: 'subsystemId',
          label: 'Подсистема',
          options: subsystemOptions,
        },
        {
          type: 'SELECT',
          name: 'type',
          label: 'Тип уведомления',
          options: type,
          isDisabled: !!defaultType,
        },
        {
          type: 'CHECKBOX',
          name: 'isRead',
          label: 'Показать прочитанные',
        },
        {
          type: 'CHECKBOX',
          name: 'deleted',
          label: appLocale.common.showDeleted,
        },
      ]}
      presetValues={presetValues}
    >
      {children}
    </Filter>
  );
}

export { NotificationFilter };
