import { IChart, PointTypes } from '../types';
import { getOrCreateLegendList } from '../helpers';

export const htmlLegendPlugin = {
  id: 'htmlLegend',
  afterUpdate(chart: IChart, args: any, options: { containerID: string; }) {
    const ul = getOrCreateLegendList(chart, options.containerID);
    const legendItems = chart.options.plugins?.legend?.labels?.generateLabels?.(chart);
    // Remove old legend items
    while (ul.firstChild) {
      ul.firstChild.remove();
    }
    // пока это черновой вариант, хз, как эта штука будет апдейтится.
    // если устаканятся требования - можно убрать дубляжи.
    const text = document.createElement('p');
    text.style.color = 'black';
    text.textContent = 'Включить все:';
    text.style.cursor = 'pointer';
    text.style.margin = '0';
    text.style.padding = '0';

    if (legendItems?.length) {
      text.onclick = () => {
        legendItems.forEach((legendItem) => {
          if (legendItem.datasetIndex !== undefined) {
            chart.setDatasetVisibility(legendItem.datasetIndex, true);
          }
        });
        chart.update();
      };
    }
    ul.appendChild(text);
    // Reuse the built-in legendItems generator

    const variants = [
      {
        label: 'минимальные',
        value: PointTypes.MIN,
      },
      {
        label: 'максимальные',
        value: PointTypes.MAX,
      },
      {
        label: 'средние',
        value: PointTypes.AVG,
      },
      // {
      //   label: 'абсолютные',
      //   value: PointTypes.ABS,
      // },
    ];

    variants.map((variant) => {
      const li = document.createElement('li');
      li.style.alignItems = 'center';
      li.style.cursor = 'pointer';
      li.style.display = 'flex';
      li.style.flexDirection = 'row';
      li.style.marginLeft = '10px';
      if (legendItems) {
        li.onclick = () => {
          legendItems.forEach((legendItem) => {
            if (legendItem.text.includes(variant.value) && legendItem.datasetIndex !== undefined) {
              // @ts-ignore
              chart.setDatasetVisibility(legendItem.datasetIndex, true);
            }
          });
          chart.update();
        };
      }
      const textContainer = document.createElement('p');
      textContainer.style.color = 'black';
      textContainer.style.margin = '0';
      textContainer.style.padding = '0';
      const text = document.createTextNode(variant.label);
      textContainer.appendChild(text);
      li.appendChild(textContainer);
      ul.appendChild(li);
    });

    const text1 = document.createElement('p');
    text1.style.color = 'black';
    text1.textContent = 'Выключить все:';
    text1.style.cursor = 'pointer';
    text1.style.margin = '0';
    text1.style.padding = '0 0 0 15px';
    if (legendItems?.length) {
      text1.onclick = () => {
        legendItems.forEach((legendItem) => {
          if (legendItem.datasetIndex !== undefined) {
            chart.setDatasetVisibility(legendItem.datasetIndex, false);
          }
        });
        chart.update();
      };
    }
    ul.appendChild(text1);
    variants.map((variant) => {
      const li = document.createElement('li');
      li.style.alignItems = 'center';
      li.style.cursor = 'pointer';
      li.style.display = 'flex';
      li.style.flexDirection = 'row';
      li.style.marginLeft = '10px';
      if (legendItems) {
        li.onclick = () => {
          legendItems.forEach((legendItem) => {
            if (legendItem.text.includes(variant.value) && legendItem.datasetIndex !== undefined) {
              // @ts-ignore
              chart.setDatasetVisibility(legendItem.datasetIndex, false);
            }
          });
          chart.update();
        };
      }
      const textContainer = document.createElement('p');
      textContainer.style.color = 'black';
      textContainer.style.margin = '0';
      textContainer.style.padding = '0';
      const text = document.createTextNode(variant.label);
      textContainer.appendChild(text);
      li.appendChild(textContainer);
      ul.appendChild(li);
    });
  },
};

export const LineChartPlugins = [htmlLegendPlugin];
