import { createTouchedErrors } from 'features/create-touched-errors';
import { FormikProvider, useFormik } from 'formik';
import { AddDeviceToStation } from 'pages/equipment/Stations/types';
import { FormFields } from 'pages/equipment/Stations/components/DeviceForm/FormFields';

interface DeviceFormProps {
  initialValues: Partial<AddDeviceToStation>;
  onSubmit: (values: Partial<AddDeviceToStation>) => void;
  onCancel: () => void;
  submitIsDisabled: boolean;
  isSubmitLoading: boolean;
}

export const DeviceForm = ({ initialValues, onCancel,
  submitIsDisabled, onSubmit, isSubmitLoading
}: DeviceFormProps) => {
  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    initialValues,
    onSubmit,
  });

  return (
    <FormikProvider
      value={{
        ...formik,
        errors: createTouchedErrors(formik.errors, formik.touched),
      }}
    >
      <FormFields
        onCancel={onCancel}
        submitIsDisabled={submitIsDisabled}
        isSubmitLoading={isSubmitLoading}
      />
    </FormikProvider>
  );
};
