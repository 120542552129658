import { filter, find, map } from 'lodash';
import {
  DictionariesItem,
  TDictionariesArray,
  TDictionaryItemDictionary,
  TDictionaryItemDictionaryKeys,
} from 'models/dictionaries';
import { SelectOption } from 'components/common';

export const getSelectOptionsFromDictionaryByName = (
  dictionary: TDictionariesArray | null = [],
  name: string,
  showDeleted: boolean = true,
  exceptionElementId: string | (string | null | undefined)[] | null | undefined = '',
  key: keyof TDictionaryItemDictionary = 'value',
) => {
  const options = map(
    find(dictionary, (item) => item.name === name)?.dictionary?.filter((di: any) => di.deleted == null || !di.deleted),
    ({ name, value, parent, ...rest }) => ({
      name: String(name),
      value: String(value),
      parent: String(parent),
      ...rest,
    }),
  );

  if (showDeleted) {
    return options;
  }

  return options
    .filter(
      (item) =>
        item.deleted !== true ||
        (typeof exceptionElementId === 'string' && item[key] === exceptionElementId) ||
        (Array.isArray(exceptionElementId) && exceptionElementId.includes(item[key] as unknown as string)),
    )
    .map(({ name, deleted, ...rest }) => {
      const item = {
        name: `${name}${deleted ? ' (удал.)' : ''}`,
        ...rest,
      };
      if (rest.title) {
        item.title = `${rest.title || ''}${deleted ? ' (удал.)' : ''}`;
      }
      return item;
    });
};

export const getSelectOptionsFromDictionaryByParent = (dictionary: TDictionariesArray | null = [], parent?: string) =>
  map(
    filter(find(dictionary, (item) => item.name === 'points')?.dictionary, (item) => item.parent === parent),
    ({ name, value }) => ({ name: String(name), value: String(value) }),
  );

export const getSelectOptionsFromDictionaryByNameWithParent = (
  dictionary: TDictionariesArray | null = [],
  name: string,
) =>
  map(find(dictionary, (item) => item.name === name)?.dictionary, ({ name, value, parent, pointTypeId, ...rest }) => ({
    ...rest,
    name: String(name),
    value: String(value),
    parent: String(parent),
    pointTypeId: String(pointTypeId),
  }));

export const getSelectRichOptionsFromDictionaryByName = (dictionary: TDictionariesArray | null = [], name: string) =>
  map(find(dictionary, (item) => item.name === name)?.dictionary, (parent) => ({
    ...parent,
  }));

export const getSelectOptionsWithParentArray = (
  dictionary: TDictionariesArray<string, string, string, any[]> | null = [],
  name: string,
) =>
  map(find(dictionary, (item) => item.name === name)?.dictionary, (parent) => ({
    ...parent,
  }));

/**
 * This function should be used if we need transformation for keys in source and finish objects.
 * @param dictionary - array with dictionaries
 * @param name - name of dictionary
 * @param sourceFieldName
 * @returns
 */
export const getSelectOptionsFromDictionaryByNameWithTransformation = (
  dictionary: TDictionariesArray | null = [],
  name: string,
  sourceFieldName: TDictionaryItemDictionaryKeys,
): SelectOption[] =>
  map(find(dictionary, (item) => item.name === name)?.dictionary, (item) => ({
    ...item,
    name: String(item[sourceFieldName]),
    value: String(item.value),
  }));

export const getSelectOptionsFromDictionary = (dictionary?: DictionariesItem['dictionary']): SelectOption[] =>
  (dictionary
    ? map(dictionary, (item) => ({
        name: String(item.name),
        value: String(item.value),
      }))
    : []);
