import React from 'react';
import { AddDeicingSnowReport } from 'pages/data/PGR/Snow/Forms/AddDeicingSnowReport';
import { EditDeicingSnowReport } from 'pages/data/PGR/Snow/Forms/EditDeicingSnowReport';
import { Snow } from 'pages/data/PGR/Snow/Snow';
import { Route } from 'react-router';
import { Soil } from 'pages/data/PGR/Soil';
import { PGRGreenPlantsList } from 'pages/data/PGR/GreenPlants/PGRGreenPlantsList';
import { Washout } from 'pages/data/PGR/GreenPlants/Washout/Washouts';
import { VegetativeState } from 'pages/data/PGR/GreenPlants/VegetativeState/VegetativeState';
import { AddDeicingWashoutReport } from 'pages/data/PGR/GreenPlants/Washout/Forms/AddDeicingWashoutReport';
import { EditDeicingWashoutReport } from 'pages/data/PGR/GreenPlants/Washout/Forms/EditDeicingWashoutReport';
import { EditDeicingVegsReport } from 'pages/data/PGR/GreenPlants/VegetativeState/Forms/EditDeicingVegsReport';
import { PGRMenu } from 'pages/data/PGR/PGRMenu';
import { AddDeicingVegReport } from '../data/PGR/GreenPlants/VegetativeState/Forms/AddDeicingVegReport';
import { EditDeicingSoilReport } from '../data/PGR/Soil/components/Forms/EditDeicingSoilReport';
import { AddSoilDeicingReport } from '../data/PGR/Soil/components/Forms/AddSoilDeicingReport';
import {
  ReagentsGreenPlantsRootPath,
  ReagentsRootPath,
  ReagentsSoilAddPath,
  ReagentsSoilRootPath,
  ReagentsVegsAddPath,
  ReagentsVegsRootPath,
  ReagentsWashoutAddPath,
  ReagentsWashoutRootPath,
} from '../data/PGR';
import { PGRAnalytics } from 'pages/data/PGR/Soil/PGRAnalytics';
import { SnowAnalytics } from 'pages/data/PGR/Snow/SnowAnalytics';
import { VegetativeAnalytics } from 'pages/data/PGR/GreenPlants/VegetativeState/VegetativeAnalytics';
import { WashoutsAnalytics } from 'pages/data/PGR/GreenPlants/Washout/WashoutsAnalytics';
import { WithDidMountResetUploadFile } from 'features/UploadedFileProvider/hocs';

const ReagentsRoutes = (): JSX.Element => (
  <>
    <Route exact path={ReagentsRootPath} component={PGRMenu} />

    <Route exact path={`${ReagentsRootPath}/snow/main-data`} component={Snow} />
    <Route exact path={`${ReagentsRootPath}/snow/analytics-data`} component={SnowAnalytics} />

    <Route exact path={`${ReagentsRootPath}/snow/add`} component={AddDeicingSnowReport} />

    <Route path={`${ReagentsRootPath}/snow/edit/row/:id`} component={EditDeicingSnowReport} />

    <Route
      exact
      path={`${ReagentsSoilRootPath}/main-data`}
      render={() => <WithDidMountResetUploadFile Component={Soil} />}
    />
    <Route exact path={`${ReagentsSoilRootPath}/analytics-data`} component={PGRAnalytics} />

    <Route
      exact
      path={ReagentsSoilAddPath}
      render={() => <WithDidMountResetUploadFile Component={AddSoilDeicingReport} />}
    />

    <Route path={`${ReagentsSoilRootPath}/edit/row/:id`} component={EditDeicingSoilReport} />

    <Route exact path={ReagentsGreenPlantsRootPath} component={PGRGreenPlantsList} />

    <Route exact path={`${ReagentsWashoutRootPath}/main-data`} component={Washout} />
    <Route exact path={`${ReagentsWashoutRootPath}/analytics-data`} component={WashoutsAnalytics} />
    <Route exact path={`${ReagentsVegsRootPath}/main-data`} component={VegetativeState} />
    <Route exact path={`${ReagentsVegsRootPath}/analytics-data`} component={VegetativeAnalytics} />
    <Route exact path={ReagentsWashoutAddPath} component={AddDeicingWashoutReport} />
    <Route exact path={ReagentsVegsAddPath} component={AddDeicingVegReport} />

    <Route path={`${ReagentsWashoutRootPath}/edit/row/:id`} component={EditDeicingWashoutReport} />
    <Route path={`${ReagentsVegsRootPath}/edit/row/:id`} component={EditDeicingVegsReport} />
  </>
);

export { ReagentsRoutes };
