import { useNotifications, useRequest } from '../../hooks';
import { ERROR_MESSAGES } from '../../constants/messages';
import { useCallback, useEffect, useLayoutEffect } from 'react';
import { api } from '../config';
import { HTTP_CODES } from '../../constants';
import { useHistory } from 'react-router-dom';
import { ReportItem } from 'pages/admin/models/ReportItem';
import { RightItem } from 'pages/admin/models/RightItem';
import moment from 'moment';
import { ApiError } from '../../models';
import { formatErrorMessage } from '../../lib/utils/formatErrorMessage';
import { authAppPaths } from 'pages/auth/appPaths';

// todo
// пока всё в одном месте. Если будет понятно, как всё это реализовать - раскидаем.
const apiAuthBase = '/auth/v10';

export const apiAuthPaths = {
  linkLogin: '/api/auth/v10/login',
  login: `${apiAuthBase}/login`,
  logout: `${apiAuthBase}/logout`,
  user: {
    info: `${apiAuthBase}/user/info`,
    role: `${apiAuthBase}/user/role`,
    report: `${apiAuthBase}/user/report`,
    right: `${apiAuthBase}/user/right`,
    checkPassword: `${apiAuthBase}/user/check_password`,
    changePassword: `${apiAuthBase}/user/change_password`,
    validatePassword: `${apiAuthBase}/user/validate_password`,
    subsystem: `${apiAuthBase}/user/subsystem`,
    rightByType: `${apiAuthBase}/user/right_by_type`
  },
  password: {
    resetPassword: `${apiAuthBase}/password/reset_password`,
    changePassword: `${apiAuthBase}/password/change_password`,
    validatePassword: `${apiAuthBase}/password/validate_password`,
  },
};
// список неполный
export enum RoleCode {
  'ADMIN' = 'ADMIN',
  'USER' = 'USER',
  'MANAGER' = 'MANAGER',
  'SENTRY' = 'SENTRY',
  'NOD_READER' = 'NOD_READER',
}

export type IRoleCode = keyof typeof RoleCode;

export interface IRole {
  code: IRoleCode;
  id: string;
  name: string;
}

export interface IUser {
  code: string;
  created: string;
  deactivated: Date | null;
  departmentId: string;
  departmentName: string;
  id: string;
  middleName: string;
  name: string;
  status: string;
  surName: string;
  type: string;
  inactiveTimeout: number;
}

export const useExpireNotify = (deactivationDate: Date | null) => {
  const { errorNotify } = useNotifications();

  const isDeactivationTomorrow =
    moment().add(1, 'days').format('YYYY-MM-DD') === moment(deactivationDate).format('YYYY-MM-DD');
  const formatedDate = moment(deactivationDate).format('DD.MM.YYYY');

  const notify = () => {
    if (deactivationDate && isDeactivationTomorrow) {
      errorNotify({
        key: 'login/add/info',
        message: `Срок действия учетной записи истекает ${formatedDate}`,
      });
    }
  };

  useEffect(() => {
    if (deactivationDate) {
      notify();
    }
    // eslint-disable-next-line
  }, [deactivationDate]);
};

export const useLogin = () => {
  const { errorNotify } = useNotifications(10000);
  const { push } = useHistory();
  const onError = (message?: string | null | ApiError) => {
    errorNotify({
      key: `noisePackage/add/${new Date()}`,
      message: formatErrorMessage(message) || ERROR_MESSAGES.UNAUTHORIZED,
    });
  };

  const successHandler = useCallback(() => {
    push('/', { shouldUpdate: true });
  }, [push]);

  const { post: login, get: wsoLogin, isLoading } = useRequest(apiAuthPaths.login, successHandler, onError);
  return { login, wsoLogin, isLoading };
};

export const useLogout = () => {
  const { push } = useHistory();

  const handler = useCallback(() => {
    push('/login');
  }, [push]);

  const { post: logout, isLoading } = useRequest(apiAuthPaths.logout, handler, handler);

  return { logout, isLoading };
};

export const useGetUserRoles = () => {
  const { get, result, error, reset, isLoading: isRolesLoading } = useRequest<IRole[]>(apiAuthPaths.user.role);

  useEffect(() => {
    void get();
  }, [get]);

  return {
    result,
    error,
    get,
    reset,
    isRolesLoading,
  };
};

export const useGetUserInfo = (role: any) => {
  const { get, result, error, reset } = useRequest<IUser>(apiAuthPaths.user.info);

  useEffect(() => {
    if (role) {
      get();
    }
  }, [get, role]);

  return {
    result,
    error,
    get,
    reset,
  };
};

export const useGetUserReport = (role: any) => {
  const { get, result, error, reset, isLoading: isReportLoading } = useRequest<ReportItem[]>(apiAuthPaths.user.report);

  useEffect(() => {
    if (role) {
      get();
    }
  }, [get, role]);

  return {
    result,
    error,
    get,
    reset,
    isReportLoading,
  };
};

export const useGetUserCustomRights = (role: any) => {
  const { get, result, error, reset, isLoading: isRightLoading } = useRequest<RightItem[]>(
    `${apiAuthPaths.user.rightByType}/CUSTOM`
  );

  useEffect(() => {
    if (role) {
      get();
    }
  }, [get, role]);

  return {
    result,
    error,
    get,
    reset,
    isRightLoading,
  };
};

const unAuthtedRoutes = [
  `^${authAppPaths.resetPassword}`,
  `^${authAppPaths.changePasswordByEmail.replace(':link', '.+')}`,
];
export const useAxiosSetup = () => {
  const { push } = useHistory();
  useLayoutEffect(() => {
    api.interceptors.response.use(
      (r) => r,
      (error) => {
        // ignore auth errors on password forms
        if (!unAuthtedRoutes.some((route) => window.location.pathname.match(route))) {
          if (error.response.status === HTTP_CODES.UNAUTHORIZED) {
            push('/login');
          }
          if (error.response.status === HTTP_CODES.FORBIDDEN) {
            push('/forbidden');
          }
          return Promise.reject(error);
        }
        return Promise.reject(error);
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export const hasAdminRole = (roles: IRole[]) => roles?.some((r) => r.code === RoleCode.ADMIN);
