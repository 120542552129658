import { useParams } from 'react-router-dom';
import { useContext, useMemo } from 'react';
import { UserInfoContext } from 'features/AuthProvider';
import { Flex, Heading, Link } from '@chakra-ui/react';
import { DefaultText, LoaderItem, PageHeader } from 'components/common';
import { useGetSubsystemReport } from '../../useGetSubsystemReports';
import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import subsystemIdsMap from 'constants/subsystemIdsMap';
import { useJournalLog } from 'lib/utils/journalLog/useJournalLog';

interface IProps {
  breadcrumb: { breadcrumb: string; path: string }[];
  showState?: boolean;
}

const stateItemCodes = [
  'NSI_OBSHHIJ.MONITORING_SOSTOYANIYA_ZELENYХ_NASAZHDENIJ.SOSTOYANIE_ZELENYХ_NASAZHDENIJ_PO_3_BALLNOJ_SHKALE',
  'NSI_OBSHHIJ.MONITORING_SOSTOYANIYA_ZELENYХ_NASAZHDENIJ.SOSTOYANIE_ZELENYХ_NASAZHDENIJ_PO_6_BALLNOJ_SHKALE',
  'NSI_OBSHHIJ.MONITORING_SOSTOYANIYA_ZELENYХ_NASAZHDENIJ.DINAMIKA_SOSTOYANIYA_ZELENYХ_NASAZHDENIJ',
];

export const GreenplantsReportList = ({ breadcrumb, showState }: IProps) => {
  useUpdateBreadcrumbs(
    showState
      ? [
        ...breadcrumb,

        {
          breadcrumb: 'Состояние зелёных насаждений (по 3-бальной и 6-бальной шкале)',
          path: `/reports-analytics/greenplants/${subsystemIdsMap.greenPlantsRoot}/state`,
        },
      ]
      : breadcrumb,
  );
  const { logExternalUrl } = useJournalLog();

  const { userSubsystems, user } = useContext(UserInfoContext);
  const { subsystemId } = useParams<{ subsystemId: string }>();
  const { isReportLoading, subsystemReports } = useGetSubsystemReport(subsystemId, user?.id);

  const isAdditionalLinkAvailable = useMemo(
    () => !!subsystemReports?.find((item) => stateItemCodes.includes(item.code)),
    [subsystemReports],
  );

  const subsystemReportsSorted = useMemo(() => {
    const reports = subsystemReports;
    if (Array.isArray(reports)) {
      if (isAdditionalLinkAvailable) {
        reports.push({
          name: 'Состояние зеленых насаждений (по 3-балльной и 6-балльной шкале)',
          link: `${window.location.href}/state`,
          code: '',
        });
      }
      return reports
        .sort((a, b) => {
          const nameA = a.name.toLowerCase();
          const nameB = b.name.toLowerCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        })
        .filter((item) => (showState ? stateItemCodes.includes(item.code) : !stateItemCodes.includes(item.code)));
    }
  }, [subsystemReports, isAdditionalLinkAvailable, showState]);

  const subsystemName = userSubsystems?.find((subsystem) => subsystem.id === subsystemId)?.name || '';
  const module = userSubsystems?.find((subsystem) => subsystem.id === subsystemId)?.module || '';
  const fullName = `${subsystemName} ${module && module !== subsystemName ? `(${module})` : ''}`;

  if (isReportLoading) {
    return <LoaderItem />;
  }

  return (
    <Flex flexDirection="column">
      <PageHeader marginBottom={5}>
        <Heading>{`Отчеты ${fullName}`}</Heading>
      </PageHeader>
      {!subsystemReports?.length ? (
        <Flex justifyContent="center">
          <DefaultText fontWeight="bold" color="rgb(192,192,192)" textColor="rgb(192,192,192)">
            Данных по отчетам пока нет
          </DefaultText>
        </Flex>
      ) : (
        <>
          {subsystemReportsSorted?.map((report) => (
            <Link
              key={report.link}
              href={report.link}
              onClick={() => logExternalUrl(report.link, report.name)}
              isExternal
              color="#208CFF"
              fontWeight="bold"
              marginLeft="10px"
              marginBottom="20px"
            >
              {report.name}
            </Link>
          ))}
        </>
      )}
    </Flex>
  );
};
