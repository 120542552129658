import React, { useCallback, useMemo } from 'react';
import { SubsystemHeader, useDefaultFilters } from 'components/common';
import { Button } from '@chakra-ui/react';
import { Modal } from 'components/common/Modals';
import { MaterialTable } from 'features/material-table/MaterialTableMini';
import { useInitializerUploadSettings } from 'features/UploadedFileProvider/UploadFileSettings';
import { useAvailability, useDisclosure, useEnhancedNotification, useHandleDeleteData } from 'hooks';
import { AtmosphereMonitoringItem } from 'hooks/atmosphere/Monitoring/useAtmosphereMonitoringList';
import { map } from 'lodash';
import moment from 'moment';
import { useList } from 'hooks/useList';
import { Column } from '@material-table/core';
import { useHistory } from 'react-router-dom';
import { UploadFileModalContent } from 'features/UploadFileDragAndDrop/UploadFileModalContent';
import { addFiltersToColumns, ColumnWithFilter } from 'lib/utils/tableFilter';
import { TableFilterTypeEnum } from 'components/table/filters/TableFilter';
import useGetListItemByIds from 'hooks/useGetListItemByIds';
import { operations } from 'lib/calculations/operations';
import useCalculations from 'hooks/useCalculations';
import subsystemIdsMap from 'constants/subsystemIdsMap';
import { AtmosphereFilter } from 'pages/data/Atmosphere/Monitoring/AtmosphereFilter/AtmosphereFilter';
import { TAtmosphereMonitoringFilterType } from 'pages/data/Atmosphere/Monitoring/types';
import { PropActions } from 'features/material-table/components/Actions';
import { usePeriodsDict } from 'features/PeriodsButtons/usePeriodsDict';
import { createUTCTime } from 'lib/create-date';
import { useGraphFilter } from 'hooks/useGraphFilter';
import { getSelectRichOptionsFromDictionaryByName } from 'features/get-select-options-from-dictionary';
import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import appLocale from 'constants/appLocale';
import { useDictionary } from 'hooks/useDictionary';
import { DeleteIcon } from 'components/icons';
import { ExtensionToMimeTypeEnum } from 'lib/utils/files/getFileExtensionByMimeType';
import { FullPageWrapper } from '../../../../components/table/FullPageWrapper';
import { DEFAULT_PAGE_SIZE_NEW_TABLE } from '../../../../constants/tables';

export const AtmosphereMonitoring = () => {
  const { isWrite, isExecute, isNoRights, allAvailable, isAvailable } = useAvailability(subsystemIdsMap.atmosphere);

  const history = useHistory();

  useUpdateBreadcrumbs([
    { breadcrumb: appLocale.common.data, path: '/data' },
    {
      breadcrumb: appLocale.subsystems.askza,
      path: '/data/atmosphere',
    },
    {
      breadcrumb: 'Данные мониторинга',
      path: '/data/atmosphere/monitoring',
    },
  ]);

  useInitializerUploadSettings(
    useMemo(
      () => ({
        typesPath: '/atmosphere/v10/atmosphere/load/types',
        previewPath: '/atmosphere/v10/atmosphere/load/preview',
        validatePath: '/atmosphere/v10/atmosphere/load/validate',
        applyPath: '/atmosphere/v10/atmosphere/load/apply',
        redirectPath: '/data/atmosphere/monitoring',
      }),
      [],
    ),
  );

  const { onGetDictError } = useEnhancedNotification();

  const { dictionaries } = useDictionary({
    url: '/atmosphere/v10/atmosphere/dictionaries',
    errorHandler: onGetDictError,
  });

  const { isOpen: isOpenLoad, onClose: onCloseLoad, onOpen: onOpenLoad } = useDisclosure();

  const filterKey: keyof AtmosphereMonitoringItem = 'id';

  const {
    refetch,
    isLoading: isLoadingAtmosphereMonitoringList,
    response,
    toggleOrder,
    setFilters,
    onPageChange,
    filterDTO,
    downloadAsFile,
    saveFilters,
    onSearchText,
    errorText,
    selectedIds,
    renderOnlySelectedCheckbox,
    selectedIndexes,
    onSelectionChange,
    onSubmitFilters,
    materialTableProps,
  } = useList<AtmosphereMonitoringItem, TAtmosphereMonitoringFilterType>('/atmosphere/v10/atmosphere/list', {
    initialOrderFieldKey: '-avg.valDateTime',
    exportSubsystemName: 'askza',
    preventFirstFetch: true,
    selectionKey: filterKey,
    saveFiltersState: true,
    downloadUrl: {
      [ExtensionToMimeTypeEnum.xlsx]: '/atmosphere/v10/atmosphere/list/export',
      [ExtensionToMimeTypeEnum.csv]: '/atmosphere/v10/atmosphere/list/export',
    },
    defaultPageSize: DEFAULT_PAGE_SIZE_NEW_TABLE,
    resetDataOnNextRequest: true,
  });

  const { ModalComponent: ConfirmDeleteModal, handleOpenModal } = useHandleDeleteData({ refetch });

  const formatter = useMemo(
    () =>
      new Intl.NumberFormat('en-US', {
        style: 'decimal',
        maximumFractionDigits: 20,
      }),
    [],
  );

  const columns = useMemo<Array<ColumnWithFilter<AtmosphereMonitoringItem>>>(
    () => [
      {
        title: 'Дата и время',
        field: 'avg.valDateTime',
        render: (row) => {
          const date = moment(row.avg.valDateTime);
          return date.format('DD.MM.YYYY HH:mm:ss');
        },
      },
      {
        title: 'Станция',
        field: 'stationName',
        filterType: TableFilterTypeEnum.string,
      },
      {
        title: 'Параметр',
        field: 'paramName',
        filterType: TableFilterTypeEnum.string,
      },
      {
        title: 'Ед. измерения',
        field: 'unitName',
        filterType: TableFilterTypeEnum.string,
      },
      {
        title: 'Значение max',
        field: 'max.valNumeric', // сортируется и фильтруется по этому ключу
        render: (row) => (row.max?.strValue ? formatter.format(row.max.strValue) : '-'),
        filterType: TableFilterTypeEnum.number,
        currencySetting: {
          maximumFractionDigits: -1,
        },
      },
      {
        title: 'Значение avg',
        field: 'avg.valNumeric', // сортируется и фильтруется по этому ключу
        render: (row) => (row.avg?.strValue ? formatter.format(row.avg.strValue) : '-'),
        filterType: TableFilterTypeEnum.number,
      },
      {
        title: 'Значение min',
        field: 'min.valNumeric', // сортируется и фильтруется по этому ключу
        render: (row) => (row.min?.strValue ? formatter.format(row.min.strValue) : '-'),
        filterType: TableFilterTypeEnum.number,
        currencySetting: {
          maximumFractionDigits: 1e15,
        },
      },
    ],
    [formatter],
  );

  // calculations
  const {
    calculatePreview,
    renderPreview,
    isCalculationLoading,
    renderDriftPreview
  } = useCalculations({
    onSuccess: refetch,
    apiPath: '/atmosphere/v10/atmosphere',
    previewPath: '/atmosphere/v10/atmosphere',
    refetch,
    columns,
  });
  // data processing
  const { getItems, isLoading: isItemsLoading } = useGetListItemByIds<AtmosphereMonitoringItem>({
    url: '/atmosphere/v10/atmosphere/list',
    idKey: filterKey,
  });

  const onSubmitDataProcessing = useCallback(
    async (values) => {
      const selectedData = await getItems(selectedIds, filterDTO.filters);

      void calculatePreview({
        value: values.value,
        valueType: values.operandId ? values.operandId.toUpperCase() : undefined,
        operation: values.operationId,
        criteria: filterDTO,
      },
        {
          ...values,
          // TODO: add types to operandIds
          // @ts-ignore
          ids: map(selectedData, (row) => row[values.operandId]?.id),
        });
    },
    [getItems, selectedIds, filterDTO, calculatePreview],
  );
  const dataProcessing = useMemo(() => {
    const calculationFields = [
      { value: 'avg', name: 'avg' },
      { value: 'min', name: 'min' },
      { value: 'max', name: 'max' },
    ];

    return {
      operands: calculationFields,
      operations,
      onSubmit: onSubmitDataProcessing,
    };
  }, [onSubmitDataProcessing]);

  const columnsWithFilters = useMemo<Array<Column<AtmosphereMonitoringItem>>>(
    () => addFiltersToColumns({ filters: filterDTO.filters, setFilters, columns }),
    [columns, filterDTO.filters, setFilters],
  );

  const initialActions = useMemo<PropActions<AtmosphereMonitoringItem[]>>(
    () => [
      {
        position: 'row',
        action: (row: AtmosphereMonitoringItem) => ({
          icon: () => <DeleteIcon />,
          onClick: () =>
            handleOpenModal({ url: `/atmosphere/v10/atmosphere?ids=${[row.avg.id, row.min.id, row.max.id]}` }),
          tooltip: 'Удалить',
          hidden: !row.isEditable,
        }),
      },
    ],
    [handleOpenModal],
  );

  const actions = useMemo<PropActions<AtmosphereMonitoringItem[]>>(() => {
    if (allAvailable || isNoRights || isWrite) {
      return initialActions;
    }

    return initialActions.filter((action: any) => action.type !== 'delete');
  }, [isWrite, initialActions, isNoRights, allAvailable]);

  const { result: periods } = usePeriodsDict(`auth/v10/user/period/${subsystemIdsMap.atmosphere}`);

  const parametersKeys = useMemo(() => {
    const dateFrom =
      (filterDTO?.filters.find((item) => item.key === 'avg.valDateTime' && item.operation === 'GTE')
        ?.value as string) || '';
    const dateTo =
      (filterDTO?.filters.find((item) => item.key === 'avg.valDateTime' && item.operation === 'LTE')
        ?.value as string) || '';
    return {
      sourceId: (filterDTO?.filters.find((item) => item.key === 'stationId')?.value as string) || null,
      dateFrom: createUTCTime(dateFrom),
      dateTo: createUTCTime(dateTo),
    };
  }, [filterDTO.filters]);

  const getFilterSourceValue = useCallback((value: any) => {
    const stations = getSelectRichOptionsFromDictionaryByName(dictionaries?.data, 'stations');
    return stations.find((s) => s.value === value)?.sourceId;
  }, [dictionaries]);

  const graphFilters = useDefaultFilters({
    filters: filterDTO.filters,
    dateKey: 'avg.valDateTime',
    filterSourceValue: getFilterSourceValue,
    initialData: 'initialData',
  });

  const {
    GraphFilter,
    graphDisclosure,
    graphOnOpen,
    filtersFromGraphic,
    setFiltersFromGraphic,
    isGraphLoading,
    setGraphInitialFilters,
  } = useGraphFilter<TAtmosphereMonitoringFilterType>({
    filters: filterDTO.filters,
    setFilters: onSubmitFilters,
    graphFilters,
    selectionKey: filterKey,
  });

  const isLoading = useMemo(
    () => isCalculationLoading || isItemsLoading || isLoadingAtmosphereMonitoringList || isGraphLoading,
    [isCalculationLoading, isItemsLoading, isLoadingAtmosphereMonitoringList, isGraphLoading],
  );

  return (
    <FullPageWrapper>
      <SubsystemHeader
        title="Данные мониторинга состояния атмосферного воздуха"
        onUploadClick={isAvailable ? onOpenLoad : undefined}
        addButtonProps={{
          onClick: () => history.push('/data/atmosphere/monitoring/add'),
          isDisabled: !isAvailable,
        }}
        filtersComponent={
          <AtmosphereFilter
            savedPeriodId={filterDTO?.filters.find((item) => item.key === 'periodId')?.value as string}
            periods={periods}
            dictionaries={dictionaries}
            isLoading={isLoading}
            onSubmit={(values) => onSubmitFilters(values)}
            setGraphInitialFilters={setGraphInitialFilters}
            filtersFromGraphic={filtersFromGraphic}
            setFiltersFromGraphic={setFiltersFromGraphic}
            filters={filterDTO.filters}
          >
            {renderOnlySelectedCheckbox}
          </AtmosphereFilter>
        }
      />

      <Modal isOpen={isOpenLoad} onClose={onCloseLoad} header="Загрузка данных из файла">
        <UploadFileModalContent
          createTypesPath={() => '/atmosphere/v10/atmosphere/load/types'}
          createUploadPath={() => '/atmosphere/v10/atmosphere/load/preview'}
          types={[
            {
              name: 'Данные автоматических станций контроля',
              value: 'askza',
            },
          ]}
          onClose={onCloseLoad}
          refetch={refetch}
        />
      </Modal>

      {!graphDisclosure.isOpen && (
        <MaterialTable
          isNotExecute={isExecute || isNoRights}
          data={response?.data}
          meta={response?.meta}
          toggleOrder={toggleOrder}
          onPageChange={onPageChange}
          filterDTO={filterDTO}
          columns={columnsWithFilters}
          actions={actions}
          options={{
            search: true,
          }}
          isLoading={isLoading}
          onSearch={onSearchText}
          dataProcessing={dataProcessing}
          downloadAsFile={downloadAsFile}
          selectedIndexes={selectedIndexes}
          onSelectionChange={onSelectionChange}
          processingParamsUrl="/atmosphere/v10/atmosphere/dictionaries/dependent-parameters"
          parametersKeys={parametersKeys}
          saveFilters={saveFilters}
          setFilters={setFilters}
          topBarLeftContent={
            <Button variant="solid" size="sm" colorScheme="PrimaryButton" onClick={graphOnOpen}>
              Открыть график
            </Button>
          }
          errorText={errorText}
          isWrite={isWrite}
          {...materialTableProps}
          showTotalCount
        />
      )}

      {GraphFilter}

      {ConfirmDeleteModal}

      {renderDriftPreview}

      {renderPreview}
    </FullPageWrapper>
  );
};
