import { Radar } from 'react-chartjs-2';
import { Box, Flex } from '@chakra-ui/react';
import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import appLocale from 'constants/appLocale';

// Radial chart test - remove later
const Complaints = () => {
  useUpdateBreadcrumbs([
    { breadcrumb: appLocale.subsystems.analyticsReports, path: '/reports-analytics' },
    { breadcrumb: appLocale.analytics.combinedCharts.title, path: '/reports-analytics/chart-combination' },
    { breadcrumb: appLocale.analytics.combinedCharts.askzaInversion.title, path: '/reports-analytics/chart-combination/complaints' },
  ]);
  const data = {
    labels: ['С', 'ССВ', 'CВ', 'ВСВ', 'В', 'С', 'ССВ', 'CВ', 'ВСВ', 'В', 'С', 'ССВ', 'CВ', 'ВСВ', 'В', 'С', 'ССВ', 'CВ', 'ВСВ', 'В'],
    datasets: [
      {
        label: '# of Votes',
        data: [2, 2, 3, 4, 5, 6, 7, 1, 2, 3, 4, 5, 6, 7, 1, 2, 3, 4, 5, 6, 7, 1, 2, 3, 4, 5, 6, 7, 1, 2, 3, 4, 5, 6, 7],
        backgroundColor: 'rgba(255, 99, 132, 0)',
        borderColor: 'rgba(255, 99, 132, 1)',
        borderWidth: 1,
      },
    ],
  };

  return (
    <Box>
      <Flex width={600} height={600}>
        <Radar
          data={data}
          options={{
            scales: {
              r: {
                suggestedMin: 0,
                suggestedMax: 10
              }
            }
          }}
        />
      </Flex>
    </Box>
  );
};
export default Complaints;
