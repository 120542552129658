import { useRequest } from 'hooks/useRequest';
import { useEffect } from 'react';
import { MeteoDataApiRoot } from 'pages/data/Meteo/menu';

import { TDictionariesArray } from 'models/dictionaries';

type DictionariesNames =
  | 'sources'
  | 'sourceTypes'
  | 'parameters'
  | 'heights'
  | 'calculationOperations'
  | 'calculationFields'
  | 'allHeights'
  | 'stations';

type SourceTypes = 'ASKZA' | 'ASKZV' | 'PROFILEMER' | 'NOISE' | 'INCLINOMETR' | 'ACTINOMETR' | 'VIRTUAL';

export type IMeteoIDictionaries = {
  data: TDictionariesArray<DictionariesNames, SourceTypes, SourceTypes>;
};

export function useAskzaDictionary(type: string) {
  const { get, isLoading, result, error } = useRequest<IMeteoIDictionaries>(`${MeteoDataApiRoot}/dictionaries/${type}`);

  useEffect(() => {
    get();
  }, [get]);

  return { isLoading, result, error };
}
