import { Box, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { AttachmentsTabContent, DefaultText, FormHeader, FormWrapper } from 'components/common';
import React, { useCallback, useMemo } from 'react';
import { CommonTab } from './tabs/CommonTab';
import { ParametersTab } from './tabs/ParametersTab';
import { useQuery } from 'hooks/useQuery';
import FormControls from 'components/common/FormControls';
import { useEnhancedNotification } from 'hooks';
import { useFormikContext } from 'formik';
import { useObjectType } from 'hooks/useObjectType';
import appLocale from 'constants/appLocale';
import { FieldFormProps } from 'types/forms';
import ForeignObjectsTab from 'pages/data/Water/WPZRoot/GroundCoast/GroundCoastForms/tabs/ForeignObjectsTab';
import { IGroundCoastFormDto } from 'pages/data/Water/WPZRoot/GroundCoast/consts/types';

const FieldsForm = ({
  onCancel,
  dictionaries,
  isEditMode,
  media,
  onMediaChange,
  uploadItemId,
  editedObject,
}: FieldFormProps) => {
  const title = useMemo(
    () => (isEditMode ? appLocale.wpz.edit_research_result : appLocale.wpz.addition_research_result),
    [isEditMode],
  );

  const { openAttachmentsTab } = useQuery();
  const { onValidationError } = useEnhancedNotification();
  const { handleSubmit, errors, values } = useFormikContext<IGroundCoastFormDto>();
  const objectType = useObjectType(dictionaries);

  const onFormSubmit = useCallback(() => {
    if (Object.keys(errors).length > 0) {
      onValidationError();
    }
    handleSubmit();
  }, [errors, handleSubmit, onValidationError]);

  return (
    <Box flexDirection="column">
      <Tabs defaultIndex={openAttachmentsTab ? 2 : 0}>
        <FormHeader onBackClick={onCancel} header={title}>
          <TabList>
            {[
              appLocale.common.generalInformation,
              appLocale.common.indicators,
              appLocale.groundCoast.tabs.foreignObjects,
              appLocale.common.attachments,
            ].map((tab, i) => (
              <Tab key={i} isDisabled={i > 0 && !values.areaId}>
                <DefaultText cursor="pointer">{tab}</DefaultText>
              </Tab>
            ))}
          </TabList>
        </FormHeader>

        <TabPanels>
          <TabPanel>
            <CommonTab isEditMode={isEditMode} dictionaries={dictionaries} />
          </TabPanel>

          <TabPanel>
            <ParametersTab isEditMode={isEditMode} dictionaries={dictionaries} editedObject={editedObject} />
          </TabPanel>

          <TabPanel>
            <ForeignObjectsTab>
              <FormControls onCancel={onCancel} handleSubmit={onFormSubmit} submitIsDisabled={false} />
            </ForeignObjectsTab>
          </TabPanel>

          <TabPanel>
            <FormWrapper>
              <AttachmentsTabContent
                objectType={objectType}
                media={media}
                onMediaChange={onMediaChange}
                uploadItemId={uploadItemId}
              >
                <FormControls onCancel={onCancel} handleSubmit={onFormSubmit} submitIsDisabled={false} />
              </AttachmentsTabContent>
            </FormWrapper>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default React.memo(FieldsForm);
