import { Radio, RadioGroup, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { StyledTab } from 'components/common';
import { AreaForm } from '../Common';
import { TAreaTabPanelProps } from './types';
import { GreenPlantsFormTypes } from '../../types';
import { AreaMeasurments } from './AreaMeasurments';
import { LawnsAreaMeasurments } from './LawnAreaMeasurments';

export const AreaTabPanel: React.FC<TAreaTabPanelProps> = ({
  floraDictionaries = { areas: [], plantParameters: [], areaParameters: [], objectType: [] },
  lawnsDictionaries = {
    areas: [],
    areaParameters: [],
  },
  area,
  isLoading,
  children,
  entity,
  setEntity,
  isEditMode,
}) => {
  const dictionaries: any = {
    flora: {
      areas: floraDictionaries?.areas || [],
      areaParameters: floraDictionaries?.areaParameters || [],
    },
    lawns: {
      areas: lawnsDictionaries?.areas || [],
      areaParameters: lawnsDictionaries?.areaParameters || [],
    },
  };

  return (
    <>
      <RadioGroup
        isDisabled={isEditMode}
        value={entity}
        onChange={(value: keyof typeof GreenPlantsFormTypes) => {
          if (setEntity) {
            setEntity(value);
          }
        }}
      >
        <Radio value="flora" marginRight={3}>
          Деревья и кустарники
        </Radio>
        <Radio value="lawns">Газоны</Radio>
      </RadioGroup>
      <Tabs marginTop={3}>
        <TabList>
          <StyledTab>Площадка</StyledTab>
          <StyledTab>Измерения</StyledTab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <AreaForm entity="area" area={area} areas={dictionaries[entity].areas} isLoading={isLoading} />
            {children}
          </TabPanel>
          <TabPanel>
            {entity === 'flora' ? (
              <AreaMeasurments entity="area" areaParameters={dictionaries.flora.areaParameters} />
            ) : (
              <LawnsAreaMeasurments entity="area" areaParameters={dictionaries.lawns.areaParameters} />
            )}
            {children}
          </TabPanel>
        </TabPanels>
      </Tabs>
    </>
  );
};
