import { FormHeader } from 'components/common';
import { ERROR_MESSAGES, SUCCESS_MESSAGES } from 'constants/messages';
import { createTouchedErrors } from 'features/create-touched-errors';
import { FormikProvider, useFormik } from 'formik';
import { useNotifications } from 'hooks/useNotifications';
import { useRequest } from 'hooks/useRequest';
import { ReportSchema } from 'models/schemas/admin/report';
import { ReportItem } from 'pages/admin/models/ReportItem';
import { useHistory } from 'react-router-dom';
import { baseAdminReportUrl } from '../consts';
import FieldsForm from './FieldsForm';

const initialValues: ReportItem = {
  id: undefined,
  code: '',
  name: '',
  deleted: false,
  subsystemId: undefined,
  subsystemName: '',
  link: '',
};

export const AddReportForm = () => {
  const history = useHistory();

  const { successNotify, errorNotify } = useNotifications();

  const successHandler = () => {
    successNotify({
      key: 'file/create/success',
      message: SUCCESS_MESSAGES.CREATE,
    });
    history.push('/admin/report');
  };
  const errorHandler = (error: any) => {
    errorNotify({
      key: 'file/create/error',
      message: `${ERROR_MESSAGES.CREATE}. ${typeof error === 'string' ? error : ''}`,
    });
  };

  const { post: createReport, isLoading } = useRequest(baseAdminReportUrl, successHandler, errorHandler);

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    initialValues,
    validationSchema: ReportSchema,
    onSubmit: (values) => createReport(values),
  });

  return (
    <FormikProvider
      value={{
        ...formik,
        errors: createTouchedErrors(formik.errors, formik.touched),
      }}
    >
      <FormHeader url="/admin/report" header="Добавление отчета" />
      <FieldsForm onCancel={() => history.push('/admin/report')} isLoading={isLoading} />
    </FormikProvider>
  );
};
