import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router';
import { FormikProvider, useFormik } from 'formik';

import { Loader } from 'components/common';
import { useNotifications } from 'hooks/useNotifications';
import { useRequest } from 'hooks/useRequest';
import { createTouchedErrors } from 'features/create-touched-errors';
import { ERROR_MESSAGES, SUCCESS_MESSAGES } from 'constants/messages';

import { MeteoPackageFormFields } from 'pages/data/MASKSH/Data/Meteo/MeteoPackageForm/Form';
import { MASKSHApiRootPackageMeteo, MASKSHMeteo } from 'pages/data/MASKSH/consts';
import { useGetMeteoPackage, useMASKSHDictionary } from 'pages/data/MASKSH/hooks';
import { initialEmptyValues } from 'pages/data/MASKSH/Data/Meteo/MeteoPackageForm/configs';
import { ApiError } from 'models';
import { formatErrorMessage } from 'lib/utils/formatErrorMessage';
import { useGoBackWithFallback } from 'lib/utils/goBackWithFallback';
import { meteoSchema } from 'models/schemas/MASKSH/meteoSchema';
import { replaceEmptyStringsWithNull } from 'lib/utils/replaceEmptyStringsWithNull';

interface IProps {
  listPath?: string;
}

export const EditMeteoPackageForm = ({ listPath = MASKSHMeteo }: IProps) => {
  const { backWithFallback } = useGoBackWithFallback();
  const { isLoading, result } = useMASKSHDictionary('meteo');
  const { successNotify, errorNotify } = useNotifications();
  const { packageId } = useParams<{ packageId: string }>();

  const { isLoading: isLoadingPackage, result: editedObject, error } = useGetMeteoPackage(packageId);

  useEffect(() => {
    if (!packageId || error) {
      errorNotify({
        key: 'noisePackage/add/error',
        message: ERROR_MESSAGES.GET,
      });
      backWithFallback(listPath);
    }
  }, [listPath, error, errorNotify, packageId, backWithFallback]);

  const onSuccess = () => {
    successNotify({
      key: 'noisePackage/add/success',
      message: SUCCESS_MESSAGES.EDIT,
    });
    backWithFallback(listPath);
  };

  const onError = (error: ApiError | string | null) => {
    const message = formatErrorMessage(error);
    errorNotify({
      key: message || 'noisePackage/add/error',
      message: message || ERROR_MESSAGES.EDIT,
    });
  };

  const initialValues = useMemo(() => ({ ...initialEmptyValues, ...editedObject }), [editedObject]);

  const { put, isLoading: isPutLoading } = useRequest(MASKSHApiRootPackageMeteo, onSuccess, onError);

  const useFormikProps = useMemo(
    () => ({
      enableReinitialize: true,
      validateOnChange: true,
      validateOnMount: true,
      validateOnBlur: true,
      initialValues,
      validationSchema: meteoSchema,
      onSubmit: (values: any) => {
        const { errors, ...payload } = values;
        void put(replaceEmptyStringsWithNull(payload));
      },
    }),
    [initialValues, put],
  );

  const formik = useFormik(useFormikProps);

  return (
    <div>
      {isLoadingPackage ? (
        <Loader />
      ) : (
        <FormikProvider
          value={{
            ...formik,
            errors: createTouchedErrors(formik.errors, formik.touched),
          }}
        >
          <MeteoPackageFormFields
            listPath={listPath}
            isEditMode
            isLoading={isLoading || isPutLoading || isLoadingPackage}
            dictionaries={result}
          />
        </FormikProvider>
      )}
    </div>
  );
};
