import { useRequest } from 'hooks/useRequest';

interface IGraphDictionaryType {
  typeId: string;
  typeName: string;
  sources: IGraphDictionarySource[];
}

interface IGraphDictionarySource {
  sourceId: string;
  sourceName: string;
  parameters: IGraphDictionaryParameter[];
}

interface IGraphDictionaryParameter {
  parameterCode: string;
  parameterId: string;
  parameterName: string;
}

export interface IGraphDictionariesResponse {
  groundwaterDictionary: IGraphDictionaryType[];
}

interface IUseDictionary {
  url: string;
}

function useGraphDictionaries({ url }: IUseDictionary) {
  const { post, isLoading, result } = useRequest<IGraphDictionariesResponse>(url);

  return {
    post,
    isDictionariesLoading: isLoading,
    groundwaterDictionary: result?.groundwaterDictionary,
  };
}

export default useGraphDictionaries;
