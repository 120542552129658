import React, { useCallback, useState } from 'react';
import { createTouchedErrors } from 'features/create-touched-errors';
import { FormikProvider, useFormik } from 'formik';
import { useRequest } from 'hooks/useRequest';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { ReagentsVegsRootPath } from 'pages/data/PGR';
import { VegStateSchema } from 'models/schemas/pgr';
import FieldsForm from './FieldsForm';
import { IUploadMedia } from 'components/common/FileUploadDragAndDrop/types';
import { VegetationDeicingItem } from 'pages/data/PGR/GreenPlants/VegetativeState/useVegDicingList';
import { updateFiles } from 'api/services/media';
import { useObjectType } from 'hooks/useObjectType';
import { useEnhancedNotification } from 'hooks';
import { splitTypesAndValues } from 'lib/utils/forms/valueTypeHelper';
import { useGoBackWithFallback } from 'lib/utils/goBackWithFallback';
import { useDictionary } from 'hooks/useDictionary';

export const initialEmptyValues = {
  address: '',
  distance2RoadId: null,
  distance2Road: '',
  okrug: '',
  okrugId: null,
  protocol: '',
  protocolDate: moment().format(),
  rayon: '',
  rayonId: null,
  registryId: '',
  selectionDate: moment().format(),
  sourceNameId: null,
  territoryType: null,
  values: [],
};

export const AddDeicingVegReport = () => {
  const { isDictionariesLoading, dictionaries } = useDictionary({ url: '/deicing/v10/deicing/vegetative/dictionaries' });
  const { backWithFallback } = useGoBackWithFallback();
  const history = useHistory();
  const { onErrorCreate, onSuccessCreate } = useEnhancedNotification();
  const [media, setMedia] = useState<IUploadMedia[]>([]);
  const objectType = useObjectType(dictionaries);

  const onSuccess = (response: VegetationDeicingItem | null) => {
    if (response) {
      onSuccessCreate();

      updateFiles(response.registryId, objectType, media)
        .then(() => {
          backWithFallback(`${ReagentsVegsRootPath}/main-data`);
        })
        .catch((error: Error) => {
          onErrorCreate(error.message);
          history.replace(`${ReagentsVegsRootPath}/edit/row/${response.registryId}?openAttachmentsTab=true`, response);
        });
    } else {
      onErrorCreate();
    }
  };

  const { post, isLoading } = useRequest<VegetationDeicingItem, any>('/deicing/v10/deicing/vegetative', onSuccess, onErrorCreate);

  const onSubmit = useCallback((values: typeof initialEmptyValues) => {
    post({
      ...values,
      values: splitTypesAndValues(values.values),
    });
  }, [post]);

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    initialValues: initialEmptyValues,
    onSubmit,
    validationSchema: VegStateSchema,
  });

  return (
    <FormikProvider
      value={{
        ...formik,
        errors: createTouchedErrors(formik.errors, formik.touched),
      }}
    >
      <FieldsForm
        dictionaries={dictionaries}
        onCancel={() => {
          backWithFallback(`${ReagentsVegsRootPath}/main-data`);
        }}
        isLoading={isDictionariesLoading || isLoading}
        onMediaChange={setMedia}
        media={media}
      />
    </FormikProvider>
  );
};
