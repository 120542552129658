import { SubsystemHeader } from 'components/common';
import { NavTabList } from 'features/NavTabList';
import { ForecastRootPath, MeteoDataRootPath } from 'pages/data/Meteo/menu';
import { FC } from 'react';
import { ButtonProps } from '@chakra-ui/react';

interface ForecastHeaderProps {
  onOpenLoad?: () => void;
  addButtonProps?: ButtonProps;
  isAvailable?: boolean;
}

export const ForecastHeader: FC<ForecastHeaderProps> = ({ addButtonProps, onOpenLoad, children, isAvailable }) => (
  <SubsystemHeader
    title="Прогноз"
    addButtonProps={addButtonProps}
    addButtonText={addButtonProps?.title}
    onUploadClick={isAvailable ? onOpenLoad : undefined}
    navigationTabs={(
      <NavTabList
        tabs={[
          {
            label: 'Данные прогноза',
            path: `${ForecastRootPath}`,
          },
          {
            label: 'Отчет о результатах сравнения',
            path: `${MeteoDataRootPath}/forecast/data/report`,
          },
        ]}
      />
  )}
    filtersComponent={children}
  />
);
