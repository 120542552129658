import {
  As,
  FormControl,
  FormErrorMessage,
  FormLabel,
  forwardRef,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  NumberInput as ChakraNumberInput,
  NumberInputField as ChakraNumberInputField,
  Stack,
} from '@chakra-ui/react';
import { isNull } from 'lodash';
import isNumber from 'lodash/isNumber';
import { memo, ReactNode } from 'react';

import { FormikFieldProps } from '../types';
import { SelectClearIcon } from 'components/common/Select/components';
import { useFormikContext } from 'formik';

export interface NumberInputProps {
  label?: string;
  InputLeftElement?: ReactNode;
  InputRightElement?: ReactNode;
}

export const NumberInput = memo(
  forwardRef<NumberInputProps & Partial<FormikFieldProps>, As<any>>(
    (
      {
        InputRightElement: inputRightElement,
        InputLeftElement: inputLeftElement,
        label,
        isInvalid,
        errorMessage,
        error,
        size = 'lg',
        p = 4,
        value = '',
        onChange,
        isDisabled,
        ...props
      },
      ref,
    ) => {
      const numbetInputValue = isNull(value) ? '' : value;

      const { setFieldValue } = useFormikContext();

      return (
        <Stack>
          <FormControl isDisabled={isDisabled} isInvalid={Boolean(isInvalid) || Boolean(error)}>
            <FormLabel
              position="absolute"
              margin={0}
              pointerEvents="none"
              zIndex={10}
              left={p}
              top={1}
              color="input.label.default"
              fontSize="14px"
              transform={
                isNumber(Number(props.value)) || Boolean(props.value) ? 'translate(0px, 0)' : 'translate(0px, 16px)'
              }
              _focus={{ transform: 'translate(0px, 0px)' }}
            >
              {label}
            </FormLabel>
            <InputGroup>
              {inputLeftElement && (
                <InputLeftElement fontSize="1.5em" height="100%">
                  {inputLeftElement}
                </InputLeftElement>
              )}
              <ChakraNumberInput width="100%" ref={ref} size={size} p={0} value={numbetInputValue}>
                <ChakraNumberInputField
                  height="60px"
                  backgroundColor="input.background.default"
                  borderRadius="2px"
                  focusBorderColor="input.border.primary"
                  p={p}
                  value={value}
                  onChange={(event) => {
                    if (onChange) {
                      const parse = parseInt(event.target.value);

                      const eventTargetValue = isNaN(parse) ? null : parse;

                      onChange({
                        ...event,
                        target: {
                          ...event.target,
                          name: props.name,
                          value: eventTargetValue,
                        },
                      });
                    }
                  }}
                  {...props}
                />
              </ChakraNumberInput>
              {inputRightElement && (
                <InputRightElement fontSize="1.5em" height="100%">
                  {inputRightElement}
                </InputRightElement>
              )}
              <InputRightElement width="fit-content" height="100%">
                <Stack direction="row" spacing={2} pl={2} pr="25px" alignItems="center">
                  {!isNull(value) && (
                    <SelectClearIcon
                      clearColor="red"
                      onClick={() => {
                        if (props.name) {
                          setFieldValue(props.name, '');
                        }
                      }}
                    />
                  )}
                </Stack>
              </InputRightElement>
            </InputGroup>
            <FormErrorMessage>{errorMessage || error}</FormErrorMessage>
          </FormControl>
        </Stack>
      );
    },
  ),
);
