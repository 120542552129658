import * as yup from 'yup';
import { requiredError } from '../constans';
import { latitudeValidation } from 'models/schemas/location';

export const NsiReagentsTreesSchema = yup.object().shape({
  number: yup.number().nullable().required(requiredError),
  parentAreaId: yup.string().nullable().required(requiredError),
  greenAreaTypeId: yup.string().nullable().required(requiredError),
  greenPlantLifeBreedId: yup.string().nullable().required(requiredError),
  greenPlantLifeFormId: yup.string().nullable().required(requiredError),
  greenAreaLocationId: yup.string().nullable().required(requiredError),
  status: yup.string().nullable().required(requiredError),

  landmark: yup.string().nullable().required(requiredError),
  ...latitudeValidation,
});
