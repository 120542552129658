import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useRef } from 'react';
import { Field, FormikProvider, useFormik } from 'formik';
import moment, { DurationInputArg1, DurationInputArg2 } from 'moment';
import { Flex, Grid, Spinner } from '@chakra-ui/react';
import { createTouchedErrors } from 'features/create-touched-errors';
import { COMMON_LOCALE } from 'constants/common';
import { createDateFromResponse, createUTCTime } from 'lib/create-date';
import { Button, DateInput, Select } from 'components/common';
import { IFilterValues } from '../../types';
import { validationSchema } from '../../validationSchema';
import { useFiltersOptions } from '../hooks/useFiltersOptions';

import { CustomPeriodSet } from '../../components/CustomPeriodSet';
import { FilterTypes } from '../../consts';

interface Interface {
  setFilters: Dispatch<SetStateAction<IFilterValues | null>>;
  initialValues: IFilterValues;
  isLoading: boolean;
  subSystemId: string;
  templateId: string;
  setShouldUpdate: (value: boolean) => void;
  startDateToUpdate: string | null;
  stopDateToUpdate: string | null;
  setStop: (value: string | null) => void;
  setStart: (value: string | null) => void;
}

export const Filters = ({
  setFilters,
  initialValues,
  isLoading,
  subSystemId,
  templateId,
  setShouldUpdate,
  setStop,
  setStart,
  stopDateToUpdate,
  startDateToUpdate,
}: Interface) => {
  const useFormikProps = useMemo(
    () => ({
      enableReinitialize: true,
      validateOnChange: true,
      validateOnMount: true,
      validateOnBlur: true,
      initialValues: { startDate: initialValues.startDate, stopDate: initialValues.stopDate },
      validationSchema,
      onSubmit: (values: IFilterValues) => {
        setFilters(values);
        setShouldUpdate(true);
      },
    }),
    [initialValues, setFilters, setShouldUpdate]
  );

  const { values, isValid, validateField, validateForm, setFieldValue, submitForm, ...formik } =
    useFormik<IFilterValues>(useFormikProps);

  useEffect(() => {
    if (startDateToUpdate) {
      setFieldValue('startDate', startDateToUpdate, true);
      setStart(null);
    }
  }, [setFieldValue, setStart, startDateToUpdate]);

  useEffect(() => {
    if (stopDateToUpdate) {
      setFieldValue('stopDate', stopDateToUpdate, true);
      setStop(null);
    }
  }, [setFieldValue, setStop, stopDateToUpdate]);

  const handleSetCustomDate = useCallback(
    (amount: DurationInputArg1, unit: DurationInputArg2) => {
      setFieldValue('startDate', createUTCTime(moment.utc().subtract(amount, unit).format()));
      setFieldValue('stopDate', createUTCTime(moment.utc().format()));
      // подобная фича нужна для корректной валидации дат - они имеют относительную привязку.
      setTimeout(() => {
        void validateField('startDate');
        void validateField('stopDate');
      });
    },
    [setFieldValue, validateField]
  );

  const {
    clarifiedSources,
    clarifiedParams,
    getClarifyOptions,
    isLoadingSources,
    isLoadingParams
  } = useFiltersOptions({
    subSystemId,
    templateId,
    from: values.startDate,
    to: values.stopDate,
    paramsIds: values.parameters,
    sourcesIds: values.sources,
  });

  const isUsedParams = useRef<boolean>(false);
  const isUsedSources = useRef<boolean>(false);

  useEffect(() => {
    if (!isUsedParams.current && clarifiedParams?.length) {
      const paramId = initialValues?.parameters?.[0];
      if (paramId) {
        const hasValue = clarifiedParams.some((param) => param.value === paramId);
        if (hasValue) {
          setFieldValue('parameters', [paramId], true);
        }
      }
      // @ts-ignore
      isUsedParams.current = true;
    }
    if (!isUsedSources.current && clarifiedSources?.length) {
      const sourceId = initialValues?.sources?.[0];
      if (sourceId) {
        const hasValue = clarifiedSources.some((param) => param.value === sourceId);
        if (hasValue) {
          setFieldValue('sources', [sourceId], true);
        }
      }
      isUsedSources.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues, clarifiedParams, clarifiedSources]);

  useEffect(() => {
    void validateField('startDate');
    void validateField('stopDate');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const RightIconLoad = useMemo(() => (isLoading ? <Spinner /> : <></>), [isLoading]);

  const clarifyOptionsByType = useCallback((clarifiedType: keyof typeof FilterTypes) => {
    getClarifyOptions(clarifiedType);
  }, [getClarifyOptions]);

  const RightIconSources = isLoadingSources ? <Spinner /> : <></>;
  const RightIconParams = isLoadingParams ? <Spinner /> : <></>;

  return (
    <div>
      <FormikProvider
        value={{
          submitForm,
          setFieldValue,
          isValid,
          validateForm,
          validateField,
          values,
          ...formik,
          errors: createTouchedErrors(formik.errors, formik.touched),
        }}
      >
        <Flex width="100%" my={2} flexDirection="row" justifyContent="space-between">
          <Grid
            width="100%"
            rowGap="10px"
            columnGap="5px"
            templateRows="repeat(2, auto)"
            templateColumns="repeat(3, 1fr)"
          >
            <CustomPeriodSet handleSetCustomDate={handleSetCustomDate} />
            <Field
              as={DateInput}
              name="startDate"
              label={COMMON_LOCALE.from}
              value={createDateFromResponse(values.startDate)}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setFieldValue('startDate', createUTCTime(e.target.value));
              }}
              isDisabled={isLoading}
              showTimeInput
            />
            <Field
              as={DateInput}
              name="stopDate"
              label={COMMON_LOCALE.to}
              isDisabled={isLoading}
              value={createDateFromResponse(values.stopDate)}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setFieldValue('stopDate', createUTCTime(e.target.value));
              }}
              showTimeInput
            />
            <Flex>
              <Button
                p={5}
                pl={6}
                rightIcon={RightIconSources}
                mr={2}
                onClick={() => clarifyOptionsByType(FilterTypes.sources)}
              >
                Уточнить
                <br />
                источники
              </Button>
              <Field
                as={Select}
                name="sources"
                label="Источники (не более 5)"
                options={clarifiedSources}
                multiple
                isDisabled={isLoading || isLoadingSources}
              />
            </Flex>

            <Flex>
              <Button
                rightIcon={RightIconParams}
                mr={2}
                p={5}
                pl={6}
                onClick={() => clarifyOptionsByType(FilterTypes.parameters)}
              >
                Уточнить
                <br />
                параметры
              </Button>
              <Field
                as={Select}
                name="parameters"
                label="Параметры  (не более 5)"
                options={clarifiedParams}
                multiple
                isDisabled={isLoading || isLoadingParams}
              />
            </Flex>

            <Flex justifyContent="flex-end">
              <Button rightIcon={RightIconLoad} onClick={submitForm} isDisabled={isLoading || !isValid}>
                {COMMON_LOCALE.apply}
              </Button>
            </Flex>
          </Grid>
        </Flex>
      </FormikProvider>
    </div>
  );
};
