import { FC, memo, useCallback, useMemo } from 'react';
import { UploadObjectType, useGetMediaById } from 'api/services/media';
import FilePreviewModal from 'components/common/FilePreviewModal/FilePreviewModal';

interface Props {
  id?: string;
  objectType: UploadObjectType | null;
  onClose: () => void;
}

const FilePreviewModalWrapper: FC<Props> = ({
  id,
  objectType,
  onClose,
}) => {
  const { uploadedFiles, isLoading, reset } = useGetMediaById(objectType, id);
  const isOpen = useMemo(() => (Boolean(id && objectType)), [id, objectType]);
  const media = useMemo(() => uploadedFiles.map((item) => item.file), [uploadedFiles]);

  const _onClose = useCallback(() => {
    reset();
    onClose();
  }, [onClose, reset]);

  return (
    <FilePreviewModal onClose={_onClose} isOpen={isOpen} media={media} isLoading={isLoading} />
  );
};

export default memo(FilePreviewModalWrapper);
