import { useRequest } from 'hooks/useRequest';

interface IASKZAMeteoDictionaryParameter {
  parameterCode: string;
  parameterId: string;
  parameterName: string;
}

interface IASKZAMeteoDictionarySource {
  sourceId: string;
  sourceName: string;
  sourceNumber: number;
  parameters: IASKZAMeteoDictionaryParameter[];
}

interface IASKZAMeteoDictionaryArea {
  sourceId: string;
  sourceName: string;
  sourceNumber: number;
  parameters: IASKZAMeteoDictionaryParameter[];
}

export interface IASKZAMeteoDictionariesResponse {
  askzaDictionary: IASKZAMeteoDictionarySource[];
  meteoDictionary: IASKZAMeteoDictionaryArea[];
}

interface IUseDictionary {
  url: string;
}

function useASKZAMeteoDictionaries({ url }: IUseDictionary) {
  const { post, isLoading, result } = useRequest<IASKZAMeteoDictionariesResponse>(url);

  return {
    post,
    isDictionariesLoading: isLoading,
    askzaDictionary: result?.askzaDictionary,
    meteoDictionary: result?.meteoDictionary,
  };
}

export default useASKZAMeteoDictionaries;
