export const paramIds = {
  temp: '8f3df7a1-5dd4-4371-9996-8f3f26f9f8f1',
  tempMinDate: '3378ce16-7a7b-4c73-a99a-156167be5231',
  tempMaxDate: '43b229b6-6d1e-4f48-8f70-625c1c76fb46',
  moisture: 'ca6547b1-55ef-48bf-a9bc-de4a816b4efd',
  windSpeed: '81e151cf-43e2-482b-8819-c22b942dd55e',
  atmospherePressure: '2b1f214a-10c1-485a-a654-7b021aab2511',
  windDirection: '155b4c86-67ef-4bce-a735-4729dc5a7c10',
  precipitation: '68eb2cf3-5ddf-4d39-ad37-bb964d9c36c9',
};
