const subsystemIdsMap = {
  // admin
  admin: 'e2573480-c336-11ed-afa1-0242ac120002',
  // pgr
  pgrRoot: 'a7d53b30-2299-4bb1-8829-6f91d2fc531e', // Корневая
  pgrSnow: 'e12dcf82-fd9a-471f-9f07-269f3da93a16',
  pgrWashouts: '5df27801-09af-4bc0-9792-c5f3197101ca',
  pgrVegetative: '1d6a6d4b-3417-42a0-9532-acb93be9f9e0',
  pgrSoil: '615d9af9-4be7-4eda-a1c0-a68232d55214',
  pgrLawn: '71108caa-4b8f-4ef8-9939-4c61b155cf3e',
  pgrFlora: 'fc2ba361-a836-43dc-9e03-d660530694d5',

  // greenplants
  greenPlantsRoot: '2de3b803-f4ab-4cf8-8ddc-a15ee15cd6ec',
  greenplantsFlora: 'a4e55d07-e9e5-47fc-99bb-3ffab7223dac',
  greenplantsLawns: '34db26e0-e4af-47ec-9f53-4b6c9a2ce8a2',

  // water
  waterRoot: '4933be86-eaa2-48f4-ae54-9fb12fcec808',
  surfaceWater: '0908c0ec-6353-4b7d-83d7-39d6c04cb8a4',
  bottomSoil: '7760b8a1-8759-4d3e-83a5-82236a1abc44',
  waterAskzv: 'dc8c01da-20ca-4501-9ada-1fdab86bfb2f',
  groundcoast: '491fd4dd-aed8-4892-8e54-e700e0f0fcf7',
  waterProtectionZone: '201c5c7b-3f8d-4468-9a4e-86fe7a0ef3d2',

  // pel
  pelRoot: '8bd0ac05-3f0a-42d1-95fa-14a6d9d41b81',

  // eqipment
  equipmentRoot: 'f5920e09-8aad-41ad-99e5-faa030ce61c4',

  // analytic lab
  analyticsLab: 'bdad7820-afac-4939-b5d9-aaa77177ec94',

  // analytics Аналитическая отчетность
  analyticsRoot: '9a850eb9-0f10-4e84-aa0d-43d1a45659a8',

  // appeals
  appealsRoot: '97bfb838-5cc0-4f93-9586-6bf71ef372d5',

  // PEL Root
  PELRoot: '8bd0ac05-3f0a-42d1-95fa-14a6d9d41b81',
  PELAtmo: '255fbe02-0543-4b86-b62a-3cbbb6c44710',
  PELNoise: 'ec2d0631-3b60-4f44-9abb-21acd1362cf6',

  // emissions
  emissionsRoout: '13b02cb3-55dd-40a1-826b-fe056d0f3888',
  emissions: 'e57faf23-98d8-4187-b4d1-0f479285c422',
  // dangerprocesses - одна для всех 3-х
  dangerprocesses: 'c0d4ed31-1b9c-4882-9110-20b629824449',
  geoGroundWater: 'f4538a63-505f-4613-9926-39ea9e88dd63',
  // Atmosphere monitoring - ASKZA
  atmosphere: '9fe13590-97e2-47b4-a525-b84dec7864ac',

  // soil
  soilpollutionRoot: '0043d1e0-d3ad-4922-b067-78220da33c9c',
  soilpollution: 'dd4dcdce-ddfe-40f9-8d9c-a99509c9c77c',

  // subsoil
  subsoilRoot: 'ff0f6811-4109-49bf-9c45-6b04a5ee2713',

  // geoecology
  geoecologyRoot: '0cf66717-d5d6-466d-b80b-e253fade1aaf',

  // meteo
  meteoRoot: 'cd2b86a3-7c05-4814-9a44-8560070b2a2b',
  meteoProfilemers: 'ef9490c8-f69a-46a4-ad34-5b98f4c1fed8',
  meteoASKZA: '9a5d56f5-9e7b-4f04-bbf6-e9d5da9427c1',
  meteoAis: '1fe7f753-cd94-490c-9a14-138208e67390',
  meteoMSZ4: '700d2f46-2e4b-4639-a7a6-0edd9ba806be',
  meteoActinometry: '12a1beac-990d-4185-b7bc-2576d2b2089e',
  meteoNMU: '622c9c71-37e3-4e28-8547-8d375a07b279',

  // forecast
  forecast: 'f21157ae-d254-4b82-b94d-6591b9a99d33',

  // masksh
  maskshRoot: '37982ec8-a5ca-4699-9591-eaf05e207bcf',
  masksh: '0c440b34-d181-4c35-ab7e-5e086956542e',

  // dictionaries НСИ
  dictionaries: '090d88c8-1ac3-498e-b08d-da8a5059d57d',
  // НСИ - Мониторгинг водных объектов
  NSIWaterObjects: '577ef1a6-e42b-427a-8e4a-4d57a5d0679d', // Водные объекты
  NSIWaterGates: 'ab1cac08-683f-4350-aadc-c81a3b598eac', // Створы наблюдения за водными объектами
  NSIWaterSites: '420b30f7-1429-471d-b033-5ea037b3df0f', // Участки наблюдения на водных объектах
  // НСИ - Геоэкологические процессы
  NSIGroundwater: '0072d528-b6c9-4a5e-b188-9e883a2f33b3',
  NSIAreas: '807ba19c-4097-4844-807c-4ae0e34ec466',
  NSIPoints: '350b9c40-9dba-4a8d-81f5-ef6652e4de66',
  NSIInclinometers: 'cddbfb4b-51fb-419b-aa6e-5b41c9b6babf',
  NSIMarks: '50fc5221-d081-4c8b-b706-9729618eaf13',
  NSIRepers: '2209b659-c4ad-44f2-a2ac-caf21bb0334f',
  // НСИ - Размещение измерительных станций
  NSIAtmosphericAir: 'c0d6b0ae-7fe0-48dd-83bb-df305e24a01a',
  // НСИ - ЗН
  NSIGreenPlantsLawn: '5d257ab4-1693-42de-b4ce-bd49748ee833',
  NSIGreenPlantsTree: '9a6aa7bd-1c75-4dcf-a2f0-f58504116e2e',
  NSIGreenPlantsArea: 'cf04b538-7d42-4cc1-959d-60fc4e827b75',
  // НСИ - Почва
  NSISoil: 'b8ca042e-3c6d-46fb-95b2-507a0513665a',
  // НСИ - Единицы измерения
  NSIUnits: 'f6ad8707-4180-42ee-8849-90965dbbcde3',
  // НСИ - Параметры измерений
  NSIParams: 'aa6e467d-c519-48cf-b8c5-b3c3184474b6',
  // НСИ - ПДК
  NSIPDKSubstances: '45b98ecd-6d3f-4895-b182-3b702acdc47f',
  NSIPDKConcentration: '70fb12a6-90c5-40fd-8c0b-18e37f41eb7e',
  // НСИ - ПДВ
  NSIPDVEmissionsSources: '3ba6c5bd-4b81-4f1e-8138-1f45224993f6',
  NSIPDVEmissionsIndustries: 'fb1a953c-63c4-4f42-bcdf-5f5609c0d1b9',
  // НСИ - ПГР
  NSIPGRGroundRoot: '570e4a07-92ba-43e9-87fa-9f2027429c43',
  NSIPGRLawns: '6a47f271-a1ce-4ee8-ba23-d2208619c629',
  NSIPGRTrees: 'a0f9cdba-2b31-42e5-a96a-7a3614a16341',
  NSIPGRSites: '5096cae9-a0fb-4892-bcf1-75aa5bdecd13',
  // НСИ - НМУ
  NSINMUPeriods: '5d1b5148-ab39-4dc1-9935-9fa909a7d156',
  // картография
  cartography: 'ef6dc822-d64e-47cd-8873-a81c45985754',

  // кабинет руководителя
  manager: '090d88c8-1ac3-498e-b08d-da8a5059d57e',

  // кабинет оперативного дежурного
  sentry: 'e35ce65a-a3d9-4725-8f9e-a91f4729699f',

  // ПЭЛ рейды
  palRaids: 'ec2d0631-3b60-4f44-9abb-21acd1362cf6',

  // Журнал превышения ПДК
  pdkExcessJournal: '12fd88c8-1ac3-498e-b08d-da8a5059da7e',

  // Журнал поступления данных
  sentryJournal: '11fd88c8-1ac3-498e-b08d-da8a5059d57e',
  dataPublication: '21930544-c79d-400d-a3cb-f8d36590b47f',
  dutyOfficer: '8429289e-11b3-4f95-94fe-203d1f0b29ba',
  bulletins: '5795b1a5-6a6a-4314-9478-44defe0da44e',
  chartCombination: 'be8c69bd-9365-4429-8efa-88ef4e9ece23',
};

export const subsystemCodesMap = {
  admin: 'CUSTOM__OTHER_ADMIN',
  analyticReports: {
    administration: 'CUSTOM__REPORTS_ANALYTICS_ADMIN',
    dataPublishing: {
      dataPublishingRoot: 'CUSTOM__REPORTS_ANALYTICS_DATA_PUBLISHING',
      questionnaire: 'CUSTOM__REPORTS_ANALYTICS_QUESTIONNAIRE',
    },
    ASKZA: 'CUSTOM__REPORTS_ANALYTICS_ATMOSPHERE',
    bulletins: 'CUSTOM__REPORTS_ANALYTICS_BULLETINS',
    waterObjects: {
      waterObjectsRoot: 'CUSTOM__REPORTS_ANALYTICS_WATER',
      ASKZV: 'CUSTOM__REPORTS_ANALYTICS_ASKZV',
      bottomSoil: 'CUSTOM__REPORTS_ANALYTICS_BOTTOM_SOIL',
      surfaceWater: 'CUSTOM__REPORTS_ANALYTICS_SURFACE',
      wpz: {
        wpzRoot: 'CUSTOM__REPORTS_ANALYTICS_PROTECTIONS',
        wpz: 'CUSTOM__REPORTS_ANALYTICS_WATERPROTECTIONZONE',
        bottomAndShore: 'CUSTOM__REPORTS_ANALYTICS_BOTTOMANDSHORE',
      },
    },
    greenPlants: 'CUSTOM__REPORTS_ANALYTICS_GREENPLANTS',
    dutyOfficer: 'CUSTOM__REPORTS_ANALYTICS_DUTY_OFFICE',
    MASKSH: 'CUSTOM__REPORTS_ANALYTICS_MASKSH',
    meteo: 'CUSTOM__REPORTS_ANALYTICS_METEO',
    PGR: {
      pgrRoot: 'CUSTOM__REPORTS_ANALYTICS_PGR',
      vegetative: 'CUSTOM__REPORTS_ANALYTICS_PGR_VEGS',
      greenplants: 'CUSTOM__REPORTS_ANALYTICS_PGR_GREENPLANTS',
      soil: 'CUSTOM__REPORTS_ANALYTICS_PGR_SOIL',
      washout: 'CUSTOM__REPORTS_ANALYTICS_PGR_WASHOUTS',
      snow: 'CUSTOM__REPORTS_ANALYTICS_PGR_SNOW',
    },
    soil: 'CUSTOM__REPORTS_ANALYTICS_SOIL',
    emissions: 'CUSTOM__REPORTS_ANALYTICS_EMISSIONS',
    PEL: 'CUSTOM__REPORTS_ANALYTICS_PEL',
    combineCharts: {
      combineChartsRoot: 'CUSTOM__REPORTS_ANALYTICS_CHART_COMBINATION',
      meteo: 'CUSTOM__REPORTS_ANALYTICS_CHART_COMBINATION_ASKZA_METEO',
      precipitation: 'CUSTOM__REPORTS_ANALYTICS_CHART_COMBINATION_ASKZA_PRECIPITATION',
      inversion: 'CUSTOM__REPORTS_ANALYTICS_CHART_COMBINATION_ASKZA_INVERSION',
      appeals: 'CUSTOM__REPORTS_ANALYTICS_CHART_COMBINATION_APPEALS',
      emissions: 'CUSTOM__REPORTS_ANALYTICS_CHART_COMBINATION_ASKZA_EMISSIONS',
      profilemers: 'CUSTOM__REPORTS_ANALYTICS_CHART_COMBINATION_ASKZA_PROFILIMER',
    },
    appeals: 'CUSTOM__REPORTS_ANALYTICS_APPEALS',
  },
  analyticsLab: 'CUSTOM__SUBSYSTEM_ANALYTICS',
  nodReportsOtherCodes: {
    nodRoot: 'ANАLYTIC_REPORT_NOD',
    automaticAirStations: 'AUTOMATIC_STATIONS_CONTROL_AERO_POLLUTION',
    waterObjects: 'CUSTOM.WATER_OBJECTS',
    atmosphereData: 'DATA_MOBILE_LAB_OF_CONTROL_AIR_POLLUTION',
    nmu: 'ADVERSE_METEO_CONDITIONS',
    danger: 'APPROXIMATE_ESTIMATED_SCALE_DANGER_OF_SOIL_POLLUTION_BY_TOTAL_POLLUTION_INDEX',
    airQuality: 'AIR_POLLUTION_PARAMETERS',
    waterParams: 'WATER_OBJECTS_QUALITY_PARAMS',
    noiseMonitoring: 'RESULTS_OF_MONITORING_NOISE_LEVELS_BY_DATA_MOBILE_ECO_LAB',
    greenplants: 'STATE_OF_GREEN_PLANTS_BY_CONTROL_AREAS_OBSERVATION',
    soil: 'SOILS_STATE_BY_SUMMARY_POLLUTION',
    geoSpring: 'SPRING_STATE',
    waterMonitoring: 'AVERAGE_MOUNTHLY_CONTENTRATION_OF_ANALYZING_PARAMETERS_WATER_OBJECTS',
    askzaMonthAvg: 'AVERAGE_MOUNTHLY_VALUES_OF_AIR_POLLUTION',
    geoDanger: 'AREAS_OF_MANIFESTATION_OF_LANDSLIDE_PROCESSES',
    waterObservation: 'OBSERVATION_SITES',
  },
  dataProcessingProtocol: {
    ASKZA: 'CUSTOM__SUBSYSTEM_ASKZA_PROTOCOL',
    ASKZV: 'CUSTOM__SUBSYSTEM_WATER_PROTOCOL',
  },
  equipment: {
    model: 'CUSTOM__DICTIONARY_MODELS',
    reports: 'CUSTOM__OTHER_EQUIPMENTS_REPORTS',
    diagrams: 'CUSTOM__REPORTS_EQUIPMENTS_DIAGRAMS',
    tables: 'CUSTOM__REPORTS_EQUIPMENTS_TABLES',
    device: 'CUSTOM__DICTIONARY_DEVISES',
    station: 'CUSTOM__DICTIONARY_STATIONS',
  },
  cartography: 'CUSTOM__SUBSYSTEM_CARTOGRAPHY',
  nsi: {
    nsiRoot: 'CUSTOM__OTHER_NSI',
    waterObjects: {
      waterObjectsRoot: 'DISPLAY_MONITORING_WATER_OBJECTS',
      observationSites: 'CUSTOM__DICTIONARY_NSI_OBSERVATION_SITES_ON_WATER_OBJS',
      observationLines: 'CUSTOM__DICTIONARY_NSI_OBSERVATION_FOLDS_ON_WATER_OBJS',
      waterObjects: 'CUSTOM__DICTIONARY_NSI_WATER_OBJS',
    },
    geologicalProcesses: {
      geologicalRoot: 'DISPLAY_GEO_ECO_PROCESSES',
      dangerGeologicalProcesses: 'DISPLAY_DANGEROUS_GEO_ECO_PROCESSES',
      observationArea: 'CUSTOM__DICTIONARY_NSI_DANGEROUS_GEO_PROCS_OBSERVATION_SITES',
      observationPoint: 'CUSTOM__DICTIONARY_NSI_DANGEROUS_GEO_PROCS_OBSERVATION_POINTS',
      inclinometer: 'CUSTOM__DICTIONARY_NSI_DANGEROUS_GEO_PROCS_OBSERVATION_INCLINOMETERS',
      mark: 'CUSTOM__DICTIONARY_NSI_DANGEROUS_GEO_PROCS_OBSERVATION_MARKS',
      reper: 'CUSTOM__DICTIONARY_NSI_DANGEROUS_GEO_PROCS_OBSERVATION_REPERS',
      groundWater: 'CUSTOM__DICTIONARY_NSI_UNDEGROUND_WATERS',
    },
    ASKZA: 'CUSTOM__DICTIONARY_NSI_PLACEMENT_MEASUREMENT_STATIONS',
    greenplants: {
      greenplantsRoot: 'CUSTOM__OTHER_NSI_GREEN_PLANTS',
      lawns: 'CUSTOM__DICTIONARY_NSI_GREEN_PLANTING_LAWNS',
      flora: 'CUSTOM__DICTIONARY_NSI_GREEN_PLANTING_TREES',
      sites: 'CUSTOM__DICTIONARY_NSI_GREEN_PLANTING_AREAS',
    },
    soil: 'CUSTOM__DICTIONARY_NSI_SOIL',
    dangerScale: 'CUSTOM__DICTIONARY_NSI_SOIL_SCALE',
    units: 'CUSTOM__DICTIONARY_NSI_MEASUREMENT_UNITS',
    params: 'CUSTOM__DICTIONARY_NSI_MEASUREMENT_PARAMETERS',
    pdk: {
      pdkRoot: 'DISPLAY_PDK_CATALOG',
      substances: 'CUSTOM__DICTIONARY_NSI_SUBSTANCES',
      pdk: 'CUSTOM__DICTIONARY_NSI_MAXIMUM_PERMISSIBLE_CONCENTRATION',
    },
    industrialEmissions: {
      industrialEmissionsRoot: 'DISPLAY_INDUSTRIAL_ATMOSPHERE_EMISSIONS',
      sourses: 'CUSTOM__DICTIONARY_NSI_SOURCES_OF_AIR_POLLUTION',
      enterprises: 'CUSTOM__DICTIONARY_NSI_ENTERPRISES',
    },
    pgr: {
      pgrRoot: 'DISPLAY_DEICING_REAGENTS',
      vegetative: 'CUSTOM__DICTIONARY_NSI_PGR_VEGETATIVE_SNOW_SOILS',
      lawns: 'CUSTOM__DICTIONARY_NSI_PGR_LAWNS',
      flora: 'CUSTOM__DICTIONARY_NSI_PGR_TREES',
      sites: 'CUSTOM__DICTIONARY_NSI_PGR_AREAS',
    },
    nmu: 'CUSTOM__DICTIONARY_NSI_NMU_PERIODS',
    MASKSH: {
      MASKSHRoot: 'CUSTOM__NSI_M_ASKSH',
      PermissibleNoiseLevel: 'CUSTOM__NSI_M_ASKSH_NOISE_LEVEL',
    },
  },
  citizenAppeals: {
    appeals: 'CUSTOM__OTHER_APPEAL_COMPLAINTS',
    raidsRoot: 'CUSTOM__OTHER_APPEALS_RAIDS_TABLEVIEW',
    raidsNoise: 'CUSTOM__OTHER_APPEAL_RAIDS_NOISE',
    raidsAir: 'CUSTOM__OTHER_APPEAL_RAIDS_AIR',
    appealsCameras: 'CUSTOM__DICTIONARY_APPEALS_CAMERAS',
    appealsTableView: 'CUSTOM__OTHER_APPEALS_COMPLAINTS_TABLEVIEW',
    appealsTableViewAir: 'CUSTOM__OTHER_APPEALS_RAIDS_AIR_TABLEVIEW',
    appealsTableViewNoise: 'CUSTOM__OTHER_APPEALS_RAIDS_NOISE_TABLEVIEW',
  },
  pdkExcessJournal: 'CUSTOM__OTHER_PDK',
  sentryJournal: 'CUSTOM__OTHER_USER_JOURNAL',
  sentryDashboard: 'CUSTOM__DASHBOARD_READ',
  PEL: {
    pelNoise: 'CUSTOM__OTHER_PEL_RAIDS_NOISE',
    pelAtmo: 'CUSTOM__OTHER_PEL_RAIDS_AIR',
    pelTableView: 'CUSTOM__OTHER_PEL_RAIDS_TABLEVIEW',
    noiseReport: 'CUSTOM__OTHER_PEL_RAID_TERRITORIES_NOISE',
    atmoReport: 'CUSTOM__OTHER_PEL_RAID_TERRITORIES_AIR',
  },
  burningRegistrationJournal: 'CUSTOM__OTHER_CAMERA_FIRE',
  incidentsRecording: 'CUSTOM__OTHER_INCIDENTS',
  dataEntryLog: 'CUSTOM__OTHER_JOURNAL_OF_DATA_RECEIVE_ON_MEASUREMENT_STATIONS',
  SOK: {
    fireJournal: 'CUSTOM_REPORT_ON_THE_FACTS_OF_BURNING_REVEALED_WITH_THE_HELP_OF_VIDEO_CAMERAS',
    EnvJournal: 'CUSTOM_REPORT_OF_THE_OPERATIONAL_DUTY_SERVICE_OF_OPERATIONAL_CONTROL_ON_THE_ENVIRONMENTAL_SITUATION_IN_THE_CITY_OF_MOSCOW_FOR_A_GIVEN_PERIOD_OF_TIME_IN_THE_PRESCRIBED_FORM'
  }
};

export default subsystemIdsMap;
