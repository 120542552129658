import { FormWrapper } from 'components/common';
import { Flex, Grid } from '@chakra-ui/react';
import React, { useMemo } from 'react';
import { ISOKJournalItem } from 'pages/PDKExcessJournal/types';
import { getSelectOptionsFromDictionaryByName } from 'features/get-select-options-from-dictionary';
import { TDictionariesArray } from 'models';
import { useFormField } from 'hooks/forms/useFormField';
import appLocale from 'constants/appLocale';
import { PdkJournalSchema } from 'models/schemas/pdk/pdkJournalSchema';
import { useFormikContext } from 'formik';

interface IProps {
  dictionaries: {
    data: TDictionariesArray;
  } | null;
  isEditMode: boolean;
}

export const CommonTab = ({ dictionaries, children, isEditMode }: React.PropsWithChildren<IProps>) => {
  const { values, initialValues } = useFormikContext<ISOKJournalItem>();

  const admAreas = useMemo(() => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'admAreas'), [dictionaries]);
  const reasons = useMemo(
    () => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'reasons', false, initialValues.otherReporting),
    [dictionaries?.data, initialValues.otherReporting],
  );
  const stations = useMemo(() => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'stations'), [dictionaries]);

  const { renderField } = useFormField<ISOKJournalItem>({
    schema: PdkJournalSchema,
    labelLocalization: appLocale.pdkExcessJournal.fields,
  });

  return (
    <FormWrapper>
      <Grid marginTop="30px" gap="15px">
        {renderField({
          name: 'date',
          type: 'datetimeinput',
          isDisabled: isEditMode && values.type !== 'MANUAL',
        })}

        {renderField({
          name: 'address',
          type: 'input',
          isDisabled: isEditMode && values.type !== 'MANUAL',
        })}

        {renderField({
          name: 'areaId',
          type: 'select',
          isDisabled: isEditMode && values.type !== 'MANUAL',
          options: admAreas,
        })}

        {renderField({
          name: 'stationId',
          type: 'select',
          isDisabled: isEditMode && values.type !== 'MANUAL',
          options: stations,
        })}

        {renderField({
          name: 'otherReporting',
          type: 'select',
          isDisabled: !!values.type && !['MANUAL', 'INCIDENTS'].includes(values.type),
          options: reasons,
        })}

        <Flex align="center" justifyContent="space-evenly">
          {renderField({ name: 'comment', isDisabled: false })}
          {renderField({
            name: 'attractingAi',
            isDisabled: !isEditMode || (!!values.type && !['IMMEDIATE_RAID', 'SCHEDULED_RAID'].includes(values.type)),
            type: 'checkbox',
          })}
        </Flex>

        <Flex align="center" justifyContent="space-evenly">
          {renderField({ name: 'description', type: 'input', isDisabled: values.type === 'ASKZA' })}
          {renderField({
            name: 'attractingOther',
            isDisabled: !isEditMode || (!!values.type && !['IMMEDIATE_RAID', 'SCHEDULED_RAID'].includes(values.type)),
            type: 'checkbox',
          })}
        </Flex>

        {children}
      </Grid>
    </FormWrapper>
  );
};
