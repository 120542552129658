export enum FilterOperation {
  EQ, NE, GT, LT, GTE, LTE, LIKE, STARTS_WITH, ENDS_WITH, CONTAINS, NOT_CONTAINS, IN, NOT_IN, BETWEEN, NOT_BETWEEN
}

export class SearchFilter {
  key: string = '';

  operation: FilterOperation = FilterOperation.EQ;

  value: any = null;

  constructor(key: string, operation: FilterOperation, value: any) {
    this.key = key;
    this.operation = operation;
    this.value = value;
  }
}

export class SearchCriteria {
  offset?: number = 0;

  limit?: number = 1000;

  searchString?: string = '';

  filters?: SearchFilter[] = [];

  orders?: string[] = [];

  searchFields?: string[] = [];
}
