import React, { useRef } from 'react';
import { useDisclosure } from 'hooks';
import { Button } from 'components/common';
import { ToolbarIconWrap } from 'features/material-table/components/Toolbar/styles';
import { ArrowDownIcon, CrossIcon, PrintIcon } from 'components/icons';
import { Flex, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import { Divider } from '@material-ui/core';

export const TableMenu = () => {
  const { onClose: onMenuClose, onOpen: onMenuOpen, isOpen: isMenuOpen } = useDisclosure();
  const mainMenuRef = useRef<any>();

  return (
    <Flex backgroundColor="panel.background.default" order={1} paddingTop={5}>
      <Flex marginLeft="auto" flex={1} justifyContent="flex-end">
        <Button
          ref={mainMenuRef}
          onClick={onMenuOpen}
          variant="ghost"
          color="text.primary"
          rightIcon={
            <ToolbarIconWrap>
              <ArrowDownIcon />
            </ToolbarIconWrap>
          }
        >
          Действия
        </Button>
        <Menu id="menuList" boundary="scrollParent" isOpen={isMenuOpen} onClose={onMenuClose}>
          <MenuButton ref={mainMenuRef} onClick={(e) => e.stopPropagation()} />
          <MenuList zIndex={11}>
            <MenuItem icon={<PrintIcon />} onClick={() => window.print()}>
              Распечатать страницу
            </MenuItem>
            <Divider />
            <MenuItem onClick={onMenuClose} icon={<CrossIcon />}>
              Отмена
            </MenuItem>
          </MenuList>
        </Menu>
      </Flex>
    </Flex>
  );
};
