import * as yup from 'yup';
import { requiredError } from '../constans';
import { numberWithSign } from 'models/schemas/numbers';

export const WashoutSchema = yup.object().shape({
  date: yup.string().typeError(requiredError).required(requiredError),
  protocolNumber: yup.string().required(requiredError),
  protocolDate: yup.string().typeError(requiredError).required(requiredError),
  areaId: yup.string().typeError(requiredError).required(requiredError),
  district: yup.string().nullable().required(requiredError),
  region: yup.string().nullable().required(requiredError),
  address: yup.string().required(requiredError),
  territoryType: yup.string().typeError(requiredError).required(requiredError),
  pointId: yup.string().typeError(requiredError).required(requiredError),
  parameters: yup.array().of(
    yup.object().shape({
      value: numberWithSign,
    })
  ),
});
