import { ITableContext, ITask } from '../../../types';
import TableCell from '@material-ui/core/TableCell';
import { RaidJournalAtmoAdd, RaidJournalNoiseAdd, raidPlanned, raidSuccess } from '../../../../consts/consts';
import { Tooltip } from '@material-ui/core';
import { DeleteIcon, EditIcon, ViewIcon2 } from 'components/icons';
import React, { useContext, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { TableContext } from 'pages/citizenAppeal/RaidJournal/RaidTable/CustomRaidTable';

interface IProps {
  taskRowSpan: number;
  task: ITask;
}

export const RowButtons = ({ taskRowSpan, task }: IProps) => {
  const history = useHistory();
  const reportInfo = { id: task.id, tabIndex: 2 };
  const { noise, handleOpenModal, isWrite } = useContext<ITableContext>(TableContext);

  const editPath = useMemo(() => (noise ? RaidJournalNoiseAdd : RaidJournalAtmoAdd), [noise]);

  return (
    <>
      {task.status !== raidSuccess && isWrite ? (
        <TableCell rowSpan={taskRowSpan} style={{ paddingTop: 0, paddingBottom: 0 }}>
          <Tooltip title="Редактировать">
            <EditIcon onClick={() => history.push(editPath, { id: task.id })} />
          </Tooltip>
        </TableCell>
      ) : (
        <TableCell rowSpan={taskRowSpan} />
      )}
      {(task.status === raidSuccess || task.status === raidPlanned) && isWrite ? (
        <TableCell rowSpan={taskRowSpan} style={{ paddingTop: 0, paddingBottom: 0 }}>
          <Tooltip title="Отчет">
            <ViewIcon2 onClick={() => history.push(editPath, reportInfo)} />
          </Tooltip>
        </TableCell>
      ) : (
        <TableCell rowSpan={taskRowSpan} />
      )}
      {handleOpenModal && !task.deleted && isWrite ? (
        <TableCell rowSpan={taskRowSpan} style={{ paddingTop: 0, paddingBottom: 0 }}>
          <Tooltip title="Удалить">
            <DeleteIcon onClick={() => handleOpenModal({ url: `/appeal/v10/tasks/${task.id}` })} />
          </Tooltip>
        </TableCell>
      ) : (
        <TableCell rowSpan={taskRowSpan} />
      )}
    </>
  );
};
