export const fieldsLocale: { [key: string]: string } = {
  stationNumber: 'Номер станции',
  stationName: 'Название станции',
  stationStatus: 'Статус станции',
  placement: 'Размещение',
};

export const stationsLocale = {
  measureDevices: 'Измерительные приборы',
  stations: 'Станции',
};

export const stationDevicesLocale = {
  inventoryNumber: 'Инвентарный номер',
  modelName: 'Модель',
  serialNumber: 'Серийный номер',
};

export const devicesLocale = {
  dateStart: 'Время установки приборов',
  dateEnd: 'Время снятия приборов',
};
