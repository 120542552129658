import { FC } from 'react';
import { Box } from '@chakra-ui/react';

interface IProps {
  maxWidth?: number
}

export const DefaultTableCellSize: FC<IProps> = ({ children, maxWidth = 200 }) => (
  <Box
    maxWidth={maxWidth}
    wordBreak="break-word"
    width="max-content"
    whiteSpace="initial"
  >
    {children}
  </Box>
);
