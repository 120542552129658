import React, { useEffect, useMemo } from 'react';
import { Line } from 'react-chartjs-2';
import { Button, Divider, Flex } from '@chakra-ui/react';

import moment from 'moment';
import 'chartjs-adapter-moment';
import 'moment/locale/ru';
import { IChartData, IFilterValues } from 'components/common';
import { registerPluginsChartJs } from 'components/common/Graphics/ChartLine/helpers';
import { useChartRef } from 'components/common/Graphics/hooks/useChartRef';
import { useMinMaxDate } from 'components/common/Graphics/hooks/useMinMaxDate';
import { useYScales } from 'components/common/Graphics/ChartLine/hooks/useYScales';
import { useOptionsForChart } from 'components/common/Graphics/ChartLine/hooks/useOptionsForChart';
import { TimeBoundaries } from 'components/common/Graphics/components/TimeBoundaries';
import { LineChartPlugins } from 'components/common/Graphics/ChartLine/plugins';
import { ChartNoData } from 'components/common/Graphics/components/ChartNoData';
import { timeFormats } from 'constants/timeFormats';
import { get } from 'lodash';

moment.locale('ru');

interface IChartProps {
  data: IChartData | null;
  filters: IFilterValues;
  onGraphFilterChange: (data: [number, number]) => void;
  onClose: () => void;
  isLoading: boolean;
  onInitialFilter?: () => void;
}

/* eslint-disable */
registerPluginsChartJs();

export const Chart = ({ data, filters, onGraphFilterChange, onClose, isLoading, onInitialFilter }: IChartProps) => {
  const { controlChartRef } = useChartRef();
  const { updateData, minDate, setMaxDate, setMinDate, maxDate, minMaxTs } = useMinMaxDate();

  useEffect(() => {
    if (minMaxTs) {
      onGraphFilterChange(minMaxTs);
    }
  }, [minMaxTs]);

  useEffect(() => {
    if (data) {
      const dataset = data.datasets[0];
      const min = get(dataset, 'data[0].x');
      const maxIndex = dataset.data.length - 1;
      const max = get(dataset, `data[${maxIndex}].x`);
      setMinDate(moment(min).format(timeFormats.fullDate));
      setMaxDate(moment(max).format(timeFormats.fullDate));
    } else {
      setMaxDate(null);
      setMinDate(null);
    }
  }, [data]);

  const yScales = useYScales({ data, filters });
  const options = useOptionsForChart({ updateData, yScales });

  const buttons = useMemo(() => {
    return <>
      <Flex justifyContent={'space-between'}>
        <Button width={'min-content'} mb={2} variant="solid" size="sm" colorScheme="PrimaryButton" onClick={onClose}>
          Открыть таблицу
        </Button>

        {onInitialFilter && (
          <Button
            isLoading={isLoading}
            ml={2}
            variant="solid"
            size="sm"
            colorScheme="PrimaryButton"
            onClick={onInitialFilter}
          >
            Вернуть исходный фильтр
          </Button>
        )}
      </Flex>
    </>;
  }, [onInitialFilter, isLoading]);

  const LineComponent = useMemo(
    () => (
      <>
        {buttons}
        {/* @ts-ignore */}
        <Line style={{ maxHeight: '680px' }} height={680} width={'100%'} id="LineChart" plugins={LineChartPlugins} ref={controlChartRef} data={data} options={options} />
        <div id="legend-container" />
      </>
    ),
    [controlChartRef, data, options, buttons]
  );

  return (
    <>
      {data && filters ? (
        <>
          {LineComponent}
          <Divider />
          <TimeBoundaries minDate={minDate} maxDate={maxDate} />
        </>
      ) : (
        <>
          {buttons}

          <ChartNoData title="Выберите период, cтанцию, параметр и запросите данные" isLoading={isLoading} />
        </>
      )}
    </>
  );
};
