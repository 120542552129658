import { useMemo } from 'react';
import { TDictionariesArray } from 'models/dictionaries';
import { getSelectOptionsFromDictionaryByName } from 'features/get-select-options-from-dictionary';
import { Field, FieldArray, useFormikContext } from 'formik';
import { Button, Checkbox, Input, Select } from 'components/common';
import { IParameterItem, paramsLocale } from '../../consts';
import { AddIcon, DeleteIcon } from 'components/icons';
import { Box, Flex } from '@chakra-ui/react';

interface Props {
  dictionaries?: TDictionariesArray;
  index: number;
  errors: any;
  isEditMode?: boolean;
}

export const SubsystemItem = ({ dictionaries, index, errors, isEditMode }: Props) => {
  const { values, setFieldValue } = useFormikContext<IParameterItem>();

  const subsystems = useMemo(() => getSelectOptionsFromDictionaryByName(dictionaries, 'subsystems'), [dictionaries]);
  const modulesFiltered = useMemo(() => {
    const modules = getSelectOptionsFromDictionaryByName(dictionaries, 'modules');
    if (values.relations[index].subsystemId) {
      return modules.filter((module) => module.parent === values.relations[index].subsystemId);
    }
    return modules;
  }, [dictionaries, index, values]);
  const units = useMemo(() => getSelectOptionsFromDictionaryByName(dictionaries, 'units'), [dictionaries]);

  return (
    <Flex width="70%" bg="GhostWhite" padding="1rem">
      <Flex width="50%" flexDirection="column" gridGap={1} marginRight={5}>
        <Field
          as={Select}
          label={paramsLocale.subsystem}
          name={`relations[${index}].subsystemId`}
          options={subsystems}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setFieldValue(`relations[${index}].subsystemId`, e.target.value);
            if (e.target.value !== values.relations[index].subsystemId) {
              setFieldValue(`relations[${index}].moduleId`, '');
            }
          }}
          error={errors?.relations && errors?.relations[index] && errors?.relations[index]?.subsystemId}
          isDisabled={isEditMode}
        />
        <Field
          as={Select}
          label={paramsLocale.unitId}
          name={`relations[${index}].unitId`}
          options={units}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setFieldValue(`relations[${index}].unitId`, e.target.value);
            const description = units.find((item) => item.value === e.target.value)?.parent || '';
            setFieldValue(`relations[${index}].unitDescription`, description);
          }}
          error={errors?.relations && errors?.relations[index] && errors?.relations[index]?.unitId}
          isDisabled={isEditMode}
        />
        <Field
          as={Input}
          label={paramsLocale.accuracy}
          name={`relations[${index}].accuracy`}
          error={errors?.relations && errors?.relations[index] && errors?.relations[index]?.accuracy}
          isDisabled={isEditMode}
        />
        <FieldArray name={`relations[${index}].aliases`}>
          {({ push, remove }) => (
            <Box>
              {values.relations[index]?.aliases.map((item, idx) => (
                <Flex marginBottom={1}>
                  <Field
                    as={Input}
                    name={`relations[${index}].aliases[${idx}]`}
                    label={`${idx + 1} ${paramsLocale.codificationVariant}`}
                    isDisabled={isEditMode}
                  />

                  {!isEditMode && (
                    <Button
                      leftIcon={<DeleteIcon />}
                      onClick={() => remove(idx)}
                      variant="ghost"
                      size="sm"
                      color="PrimaryButton.500"
                    />
                  )}
                </Flex>
              ))}

              {!isEditMode && (
                <Button
                  alignSelf="flex-end"
                  leftIcon={<AddIcon />}
                  color="PrimaryButton.500"
                  onClick={() => push('')}
                  variant="ghost"
                  size="sm"
                >
                  {paramsLocale.codificationAdding}
                </Button>
              )}
            </Box>
          )}
        </FieldArray>
      </Flex>
      <Flex width="50%" flexDirection="column" gridGap={1}>
        <Field
          as={Select}
          name={`relations[${index}].moduleId`}
          label={paramsLocale.module}
          options={modulesFiltered}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            const rootSubsystemId = modulesFiltered.find((module) => module.value === e.target.value)?.parent || '';

            setFieldValue(`relations[${index}].moduleId`, e.target.value);
            setFieldValue(`relations[${index}].subsystemId`, rootSubsystemId);
          }}
          error={errors?.relations && errors?.relations[index] && errors?.relations[index]?.moduleId}
          isDisabled={isEditMode}
        />
        <Field
          as={Input}
          name={`relations[${index}].unitDescription`}
          label={paramsLocale.unitDescription}
          isDisabled={isEditMode}
        />
        <Field
          as={Checkbox}
          label={paramsLocale.inFile}
          name={`relations[${index}].inFile`}
          checked={values.relations[index].inFile}
          isDisabled
        />
        <Field
          as={Checkbox}
          label={paramsLocale.inListing}
          name={`relations[${index}].inListing`}
          checked={values.relations[index].inListing}
          isDisabled={isEditMode}
        />
        {/* ВРЕМЕННО ЗАКОММЕНТИРОВАННЫЕ ЧЕКБОКСЫ (ПОНАДОБЯТСЯ ПОЗЖЕ) */}
        {/* <Field
          as={Checkbox}
          label={paramsLocale.inProtocol}
          name={`relations[${index}].inProtocol`}
          checked={values.relations[index].inProtocol}
        />
        <Field
          as={Checkbox}
          label={paramsLocale.inExport}
          name={`relations[${index}].inExport`}
          checked={values.relations[index].inExport}
        />
        <Field
          as={Checkbox}
          label={paramsLocale.inOutside}
          name={`relations[${index}].inOutside`}
          checked={values.relations[index].inOutside}
        /> */}
      </Flex>
    </Flex>
  );
};
