import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import { ConfirmModal, SubsystemHeader } from 'components/common';
import { MaterialTable } from 'features/material-table/MaterialTableMini';
import React, { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDisclosure, useList, useNotifications, useRequest } from 'hooks';
import { Column } from '@material-table/core';
import { createLocalDateDDMMYYYYWithTime } from 'lib/create-date';
import { NMUFilter } from './Filter';
import { ERROR_MESSAGES, REMOVE_CONFIRMATION, SUCCESS_MESSAGES } from 'constants/messages';
import { PropActions } from 'features/material-table/components/Actions';
import { NMUListItem } from './types';
import { NMU_ApiPath, NSI_NMU_AddPath, NSI_NMU_Breadcrumbs, NSI_NMU_EditPath } from './consts';
import { subsystemCodesMap } from 'constants/subsystemIdsMap';
import { useCustomRights } from 'hooks/useCustomRights';
import { FullPageWrapper } from '../../../components/table/FullPageWrapper';
import { DEFAULT_PAGE_SIZE_NEW_TABLE } from '../../../constants/tables';

export const NMU = () => {
  useUpdateBreadcrumbs(NSI_NMU_Breadcrumbs);
  const { isWrite } = useCustomRights(subsystemCodesMap.nsi.nmu);
  const { push } = useHistory();
  const [removedItem, setRemovedItem] = useState<string>('');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { successNotify, errorNotify } = useNotifications();

  const {
    isLoading,
    response,
    toggleOrder,
    setFilters,
    onPageChange,
    filterDTO,
    onSearchText,
    downloadAsFile,
    refetch,
    errorText,
    selectedIndexes,
    renderOnlySelectedCheckbox,
    onSubmitFilters,
    onSelectionChange,
    materialTableProps,
  } = useList<NMUListItem, keyof NMUListItem>(`${NMU_ApiPath}/list`, {
    initialOrderFieldKey: '-startDate',
    saveFiltersState: true,
    selectionKey: 'id',
    defaultPageSize: DEFAULT_PAGE_SIZE_NEW_TABLE,
    resetDataOnNextRequest: true,
  });

  const onSuccess = () => {
    onClose();
    refetch();
    successNotify({
      key: 'NMU/delete/success',
      message: SUCCESS_MESSAGES.DELETE,
    });
  };
  const onError = () => {
    onClose();
    errorNotify({
      key: 'NMU/delete/error',
      message: ERROR_MESSAGES.DELETE,
    });
  };

  const { remove } = useRequest('', onSuccess, onError);

  const actions = useMemo<PropActions<NMUListItem[]>>(
    () => [
      {
        type: 'edit',
        onClick: (_e, item: any) => {
          push(`${NSI_NMU_EditPath}/${item.id}`);
        },
        disabled: !isWrite,
        hidden: !isWrite,
      },
      {
        type: 'delete',
        onClick: (_e, item: any) => {
          onOpen();
          setRemovedItem(item.id);
        },
        disabled: !isWrite,
        hidden: !isWrite,
      },
    ],
    [isWrite, onOpen, push],
  );

  const columns = useMemo<Column<NMUListItem>[]>(
    () => [
      {
        title: 'Дата и время начала',
        field: 'startDate',
        render: (row) => createLocalDateDDMMYYYYWithTime(row.startDate),
      },
      {
        title: 'Дата и время окончания',
        field: 'endDate',
        render: (row) => createLocalDateDDMMYYYYWithTime(row.endDate),
      },
      {
        title: 'Степень НМУ',
        field: 'grade',
        render: (row) => row.grade[4],
      },
    ],
    [],
  );

  return (
    <FullPageWrapper>
      <SubsystemHeader
        title="Периоды НМУ"
        addButtonProps={{
          onClick: () => push(NSI_NMU_AddPath),
          isDisabled: !isWrite,
        }}
        filtersComponent={
          <NMUFilter
            onSubmitFilters={onSubmitFilters}
            filters={filterDTO.filters}
          >
            {renderOnlySelectedCheckbox}
          </NMUFilter>
        }
      />

      <MaterialTable
        data={response?.data}
        meta={response?.meta}
        toggleOrder={toggleOrder}
        filterDTO={filterDTO}
        onPageChange={onPageChange}
        columns={columns}
        actions={actions}
        isLoading={isLoading}
        setFilters={setFilters}
        options={{ selection: true, search: true }}
        onSearch={onSearchText}
        downloadAsFile={downloadAsFile}
        onSelectionChange={onSelectionChange}
        selectedIndexes={selectedIndexes}
        showTotalCount
        errorText={errorText}
        {...materialTableProps}
      />

      <ConfirmModal
        isOpen={isOpen}
        onClose={onClose}
        onClick={() =>
          remove({
            url: `${NMU_ApiPath}/${removedItem}`,
          })
        }
        header={REMOVE_CONFIRMATION}
      />
    </FullPageWrapper>
  );
};
