import { IChart } from '../types';
import { getOrCreateLegendList } from '../helpers';
import { devicesStatuses } from './consts';
import * as Chart from 'chart.js';

export const htmlLegendPlugin = {
  id: 'htmlLegend',
  afterUpdate(chart: IChart, args: any, options: { containerID: string }) {
    const ul = getOrCreateLegendList(chart, options.containerID);
    const legendItems = chart.options.plugins?.legend?.labels?.generateLabels?.(chart);
    // Remove old legend items
    while (ul.firstChild) {
      ul.firstChild.remove();
    }

    const items = Object.values(devicesStatuses);

    items.forEach((item) => {
      const li = document.createElement('li');
      li.style.alignItems = 'center';
      li.style.cursor = 'pointer';
      li.style.display = 'flex';
      li.style.flexDirection = 'row';
      li.style.marginLeft = '10px';
      if (legendItems) {
        li.onclick = () => {
          legendItems.forEach((legendItem) => {
            if (legendItem.text?.includes(item.id) && legendItem.datasetIndex) {
              // @ts-ignore
              chart.setDatasetVisibility(legendItem.datasetIndex, !chart.isDatasetVisible(legendItem.datasetIndex));
            }
            chart.update();
          });
        };
      }
      // Color box
      const boxSpan = document.createElement('span');
      boxSpan.style.background = item.color;
      boxSpan.style.borderColor = item.color;
      boxSpan.style.borderWidth = `${1}px`;
      boxSpan.style.display = 'inline-block';
      boxSpan.style.height = '20px';
      boxSpan.style.marginRight = '10px';
      boxSpan.style.width = '20px';
      // Text
      const textContainer = document.createElement('p');
      textContainer.style.color = 'black';
      textContainer.style.margin = '0';
      textContainer.style.padding = '0';

      const text = document.createTextNode(item.name);
      textContainer.appendChild(text);

      li.appendChild(boxSpan);
      li.appendChild(textContainer);
      ul.appendChild(li);
    });
  },
};
// @ts-ignore
export const GanttChartPlugins: Chart.PluginOptionsByType<'line'> = [htmlLegendPlugin];
