import { Field, FormikProvider, useFormik } from 'formik';
import React, { FC, useEffect, useMemo, useState } from 'react';

import { DateInput, IFilterValues, Select, useSetFiltersFromGraphic } from 'components/common';
import { createDateFromResponse, createDateToRequestForFilter, createDateToRequestWithTimezone } from 'lib/create-date';
import { TDictionariesArray } from 'models/dictionaries';
import {
  getSelectOptionsFromDictionaryByName,
  getSelectRichOptionsFromDictionaryByName,
} from 'features/get-select-options-from-dictionary';
import { COMMON_LOCALE } from 'constants/common';
import { fieldsLocale } from '../../consts';
import { IFilterItem } from 'models';
import combineFilters from 'lib/utils/combineFilters';
import { IPeriod } from 'features/PeriodsButtons/types';
import { PeriodsButtons } from 'features/PeriodsButtons';
import { usePeriods } from 'features/PeriodsButtons/usePeriods';
import { FiltersDatepickersSchema } from 'models/schemas/filtersDatepickersSchema/FiltersDatepickersSchema';
import FilterFormControls from 'components/form/FilterFormControls';
import { Flex, Grid } from '@chakra-ui/react';

interface FilterProps {
  isLoading?: boolean;
  isDisabled?: boolean;
  filtersFromGraphic: Partial<IFilterValues> | null;
  setFiltersFromGraphic: (value: Partial<IFilterValues> | null) => void;
  dictionaries: {
    data: TDictionariesArray;
  } | null;
  onSubmit: (values: any) => void;
  filters: IFilterItem[];
  periods: IPeriod[] | null;
}

const initialValues: Record<string, IFilterItem> = {
  dateTimeMin: {
    key: 'selectionDate',
    operation: 'GTE',
    value: null,
  },
  dateTimeMax: {
    key: 'selectionDate',
    operation: 'LTE',
    value: null,
  },
  sourceName: {
    key: 'sourceNameId',
    operation: 'IN',
    value: null,
  },
  rayon: {
    key: 'rayonId',
    operation: 'EQ',
    value: null,
  },
  okrug: {
    key: 'okrugId',
    operation: 'EQ',
    value: null,
  },
  // showDisabled - value равно '' если чекбокс включен и нужно получить с Backend все значения (этот объект удалиться при отправке)
  // value равно false - если нужно показать только неудаленные записи
  showDisabled: {
    key: 'deleted',
    operation: 'EQ',
    value: false,
  },
  periodId: {
    key: 'periodId',
    operation: 'EQ',
    value: null,
  },
};

export const SoilFilter: FC<FilterProps> = ({
  setFiltersFromGraphic,
  filtersFromGraphic,
  isLoading,
  isDisabled,
  onSubmit,
  dictionaries,
  periods,
  filters,
  children,
  ...props
}) => {
  const savedPeriodId = useMemo(
    () => (filters.find((item) => item.key === 'periodId')?.value as string) || '',
    [filters]
  );

  const [periodId, setPeriodId] = useState<string>(savedPeriodId);

  const { getDatesFromPeriod, dates } = usePeriods(periodId, periods);

  const submitPeriod = (id: string) => {
    const dates = getDatesFromPeriod(id);
    setFieldValue('periodId.value', id);
    setFieldValue('dateTimeMin.value', createDateToRequestWithTimezone(dates[0]));
    setFieldValue('dateTimeMax.value', createDateToRequestWithTimezone(dates[1]));
    setFieldTouched('dateTimeMin.value');
    setPeriodId(id);
  };

  const {
    values,
    errors,
    getFieldMeta,
    getFieldHelpers,
    getFieldProps,
    handleReset,
    submitForm,
    setFieldValue,
    setFieldTouched,
    ...formik
  } = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    validationSchema: FiltersDatepickersSchema([{ dateFromFieldName: 'dateTimeMin', dateToFieldName: 'dateTimeMax' }]),
    initialValues,
    onSubmit,
  });

  useEffect(() => {
    const initialFilters = combineFilters(initialValues, filters);
    Object.keys(initialFilters).forEach((key) => {
      setFieldValue(`${key}.value`, initialFilters[key].value);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useSetFiltersFromGraphic({
    dateKeyMin: 'dateTimeMin.value',
    dateKeyMax: 'dateTimeMax.value',
    setFiltersFromGraphic,
    filtersFromGraphic,
    setFieldValue,
  });

  const okrugs = useMemo(() => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'okrugs'), [dictionaries]);

  const rayons = useMemo(() => {
    const dict = getSelectRichOptionsFromDictionaryByName(dictionaries?.data, 'rayons');

    if (values.okrug.value) {
      return dict.filter((el) => el.parent === values.okrug.value);
    }

    return dict;
  }, [dictionaries, values.okrug.value]);

  const areas = useMemo(() => {
    const dict = getSelectRichOptionsFromDictionaryByName(dictionaries?.data, 'areas').map(
      ({ title: name, ...item }) => ({
        ...item,
        name: String(name),
      }),
    );

    if (values.rayon.value) {
      return dict.filter((el) => el.parent === values.rayon.value);
    }

    return dict;
  }, [dictionaries, values.rayon.value]);

  return (
    <FormikProvider
      value={{
        values,
        errors,
        getFieldMeta,
        getFieldHelpers,
        getFieldProps,
        handleReset,
        submitForm,
        setFieldValue,
        setFieldTouched,
        ...formik,
      }}
    >
      <Flex flexDirection="row" justifyContent="space-between">
        <Grid gap="12px" rowGap="8px" gridTemplateColumns="repeat(2, 175px) 225px 225px 175px" {...props}>
          <Field
            as={DateInput}
            maxDate={dates?.[1]}
            minDate={dates?.[0]}
            isDisabled={!periodId}
            name="dateTimeMin.value"
            label={COMMON_LOCALE.from}
            value={typeof values.dateTimeMin?.value === 'string' && createDateFromResponse(values.dateTimeMin.value)}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFieldValue('dateTimeMin.value', createDateToRequestForFilter(e.target.value));
            }}
            showTimeInput={false}
            error={errors.dateTimeMin?.value}
          />
          <Field
            as={DateInput}
            maxDate={dates?.[1]}
            minDate={dates?.[0]}
            isDisabled={!periodId}
            name="dateTimeMax.value"
            label={COMMON_LOCALE.to}
            value={typeof values.dateTimeMax?.value === 'string' && createDateFromResponse(values.dateTimeMax.value)}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFieldValue('dateTimeMax.value', createDateToRequestForFilter(e.target.value, 'end'));
            }}
            showTimeInput={false}
            error={errors.dateTimeMax?.value}
          />

          <Field
            as={Select}
            name="okrug.value"
            label={fieldsLocale.region}
            options={okrugs}
            value={values.okrug.value}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFieldValue('okrug.value', e.target.value);
            }}
            error={errors.okrug?.value}
          />

          <Field
            as={Select}
            name="rayon.value"
            label={fieldsLocale.district}
            options={rayons}
            value={values.rayon.value}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFieldValue('rayon.value', e.target.value);
              setFieldValue('sourceName.value', null);
            }}
            error={errors.rayon?.value}
          />

          <Field
            as={Select}
            multiple
            name="sourceName.value"
            label={fieldsLocale.territoryNumber}
            options={areas}
            value={values.sourceName.value}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFieldValue('sourceName.value', e.target.value);
            }}
            error={errors.sourceName?.value}
          />
        </Grid>

        <FilterFormControls
          onReset={(e) => {
            handleReset(e);
            setPeriodId('');
            setTimeout(submitForm, 0);
          }}
          onSubmit={submitForm}
          isDisabled={isDisabled}
          isLoading={isLoading}
        />
      </Flex>
      {children}
      <PeriodsButtons periodId={periodId} periods={periods} submitPeriod={submitPeriod} />
    </FormikProvider>
  );
};
