import React, { useCallback } from 'react';
import { FormikProvider, useFormik } from 'formik';
import { SOKEnvironmentalFormFields } from './formFields/SOKEnvironmentalFormFields';
import { useNotifications, useRequest } from 'hooks';
import { ExtensionToMimeTypeEnum } from 'lib/utils/files/getFileExtensionByMimeType';
import { SchemaForCustomFilter } from 'models/schemas/filtersDatepickersSchema/FiltersDatepickersSchema';
import * as yup from 'yup';
import { requiredError } from 'models/schemas/constans';
import { createTouchedErrors } from 'features/create-touched-errors';
import { formatErrorMessage } from 'lib/utils/formatErrorMessage';
import { downloadFileFromResponse } from 'lib/utils/files/downloadFile';

const initialValues = {
  start: null,
  stop: null,
  fileType: null,
};

export const SOKEnvironmentalFilter = () => {
  const { errorNotify } = useNotifications();
  const onSuccess = (response: any) => {
    downloadFileFromResponse({
      response,
      name: 'export_sok_journal',
      addDate: true,
      type: response.type,
    });
  };

  const onError = (message: string | null) => {
    const msg = formatErrorMessage(message);
    errorNotify({
      key: 'report/generate/error',
      message: msg || 'Ошибка при формировании отчета',
    });
  };
  const { post: download } = useRequest('', onSuccess, onError);
  const downloadAsFile = useCallback(
    async (values) => {
      download(
        { ...values },
        {
          url:
            values.fileType === ExtensionToMimeTypeEnum.docx
              ? 'appeal/v10/sok/report/generate'
              : 'appeal/v10/sok/report/generate.pdf',
          headers: { ACCEPT: values.fileType },
          responseType: 'blob',
        },
      );
    },
    [download],
  );
  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: false,
    validateOnBlur: false,
    validationSchema: SchemaForCustomFilter([{ dateFromFieldName: 'start', dateToFieldName: 'stop' }], {
      start: yup.string().nullable().required(requiredError),
      stop: yup.string().nullable().required(requiredError),
      fileType: yup.string().nullable().required(requiredError),
    }),
    initialValues,
    onSubmit: (values) => {
      void downloadAsFile(values);
    },
  });

  return (
    <FormikProvider
      value={{
        ...formik,
        errors: createTouchedErrors(formik.errors, formik.touched),
      }}
    >
      <SOKEnvironmentalFormFields />
    </FormikProvider>
  );
};
