import { TFilterOperations, TFiltersArray } from 'models';
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { Box } from '@chakra-ui/react';
import OperandSelector from 'components/table/filters/components/OperandSelector';
import { Button, Input } from 'components/common';

interface Props<Keys> {
  onSubmit: (operand: TFilterOperations, firstValue: string) => void;
  filter?: TFiltersArray<Keys>;
  onClear: () => void;
}

function TableStringFilter<Keys = string>({ onSubmit, onClear, filter }: Props<Keys>) {
  const [operand, setOperand] = useState<TFilterOperations>();
  const [value, setValue] = useState('');

  useEffect(() => {
    if (filter && filter[0]) {
      setOperand(filter[0].operation as TFilterOperations);
      setValue(String(filter[0].value));
    }
  }, [filter]);

  const onSubmitClick = useCallback(() => {
    if (operand && value) {
      onSubmit(operand as TFilterOperations, value);
    }
  }, [onSubmit, operand, value]);

  return (
    <Box>
      <Box display="flex">
        <OperandSelector options={['CONTAINS', 'NOT_CONTAINS']} selectedOperand={operand} onChange={setOperand} />
        <Input
          isClearable={false}
          marginLeft={4}
          onChange={(event: ChangeEvent<HTMLInputElement>) => setValue(event.target.value)}
          value={value}
        />
      </Box>

      <Box display="flex" width="100%" marginTop={4}>
        <Button onClick={onClear} marginRight="auto">
          Сбросить
        </Button>

        <Button disabled={!operand || !value} onClick={onSubmitClick} variant="solid">
          Применить
        </Button>
      </Box>
    </Box>
  );
}

export default TableStringFilter;
