import { useCallback, useMemo, useState } from 'react';
import { MaterialTable } from 'features/material-table/MaterialTableMini';
import { fieldsLocale, messages, statuses } from 'pages/equipment/Devices/consts';
import { Device } from 'pages/equipment/Devices/types';
import { useRequest } from 'hooks/useRequest';
import { useNotifications } from 'hooks/useNotifications';
import { ERROR_MESSAGES, SUCCESS_MESSAGES } from 'constants/messages';
import { useHistory } from 'react-router';
import { createOrderKey } from 'pages/equipment/Devices/lib';
import { ConfirmModal, SubsystemHeader } from 'components/common';
import { TFiltersArray } from 'models';
import { DeviceTableFilter } from 'pages/equipment/Devices/components/DeviceTable/DeviceTableFilter/DeviceTableFilter';
import { useHandleDeleteData, useList } from 'hooks';
import { createLocalDateDDMMYYYY } from 'lib/create-date';
import { useDisclosure } from 'hooks/useDisclosure';
import { ImageIcon, StatusIcon } from 'components/icons';
import { addFiltersToColumns, ColumnWithFilter } from 'lib/utils/tableFilter';
import { TableFilterTypeEnum } from 'components/table/filters/TableFilter';
import { Column } from '@material-table/core';
import useFilePreviewModalWrapperData from 'components/common/FilePreviewModal/hooks/useFilePreviewModalWrapperData';
import FilePreviewModalWrapper from 'components/common/FilePreviewModal/FilePreviewModalWrapper';
import { useCustomRights } from 'hooks/useCustomRights';
import { subsystemCodesMap } from 'constants/subsystemIdsMap';
import { FullPageWrapper } from '../../../../../components/table/FullPageWrapper';
import { DEFAULT_PAGE_SIZE_NEW_TABLE } from '../../../../../constants/tables';
import { DefaultTableCellSize } from '../../../../../components/table/DefaultTableCellSize';

type OnClick = (item: Device & { deviceId: string }) => void;
type OnClickWihNoItem = () => void;
type TUpdateStatus = {
  id: string;
  checkStatus?: string;
};

export const DeviceTable = () => {
  const { isWrite } = useCustomRights(subsystemCodesMap.equipment.device);
  const [updatedStatus, setUpdatedStatus] = useState<TUpdateStatus | null>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const history = useHistory();
  const {
    response,
    isLoading,
    toggleOrder,
    onPageChange,
    filterDTO,
    refetch,
    setFilters,
    errorText,
    materialTableProps,
  } = useList<Device>(
    '/equipment/v10/equipments/instruments/list',
    {
      initialOrderFieldKey: createOrderKey<Device>('checkDateEnd', 'descending'),
      saveFiltersState: true,
      defaultPageSize: DEFAULT_PAGE_SIZE_NEW_TABLE,
      resetDataOnNextRequest: true,
    }
  );
  const { successNotify, errorNotify } = useNotifications();

  const { open, ...filePreviewData } = useFilePreviewModalWrapperData();

  const onUpdateSuccess = () => {
    successNotify({
      key: 'file/update/success',
      message: SUCCESS_MESSAGES.UPDATE_STATUS,
    });
    refetch();
  };

  const onUpdateError = () => {
    errorNotify({
      key: 'file/update/error',
      message: ERROR_MESSAGES.UPDATE_STATUS,
    });
  };

  const { get: updateStatus } = useRequest(
    `/equipment/v10/equipments/verifications/${updatedStatus?.id}/${updatedStatus?.checkStatus}`,
    onUpdateSuccess,
    onUpdateError
  );

  const { ModalComponent: ConfirmDeleteModal, handleOpenModal } = useHandleDeleteData({ refetch });

  const onEdit = useCallback<OnClick>(
    (item) => {
      history.push(`/equipment/devices/edit/${item.deviceId}/false`);
    },
    [history]
  );

  const onUpdate = useCallback<OnClickWihNoItem>(() => {
    if (updatedStatus?.id && updatedStatus?.checkStatus) {
      updateStatus();
    }
  }, [updateStatus, updatedStatus]);

  // material table uses id as default key, here they can be identical
  const data = useMemo(
    () =>
      response?.data.map(({ id, ...row }) => ({
        ...row,
        deviceId: id,
      })),
    [response]
  );

  const columns = useMemo<Array<ColumnWithFilter<Device>>>(
    () => [
      {
        title: fieldsLocale.inventNumber,
        field: 'equipmentInvent',
        filterType: TableFilterTypeEnum.string,
      },
      {
        title: fieldsLocale.deviceName,
        field: 'deviceName',
        filterType: TableFilterTypeEnum.string,
        render: (row) => <DefaultTableCellSize>{row.deviceName}</DefaultTableCellSize>
      },
      {
        title: fieldsLocale.statusName,
        field: 'statusName',
        filterType: TableFilterTypeEnum.string,
      },
      {
        title: fieldsLocale.modelName,
        field: 'modelName',
        filterType: TableFilterTypeEnum.string,
        render: (row) => {
          const modelNames = row.models || [];
          return modelNames.map((model) => model.name).join(', ');
        },
        sorting: false,
      },
      {
        title: fieldsLocale.serialNumber,
        field: 'equipmentSerial',
        filterType: TableFilterTypeEnum.string,
      },
      {
        title: fieldsLocale.stationName,
        field: 'stationName',
        filterType: TableFilterTypeEnum.string,
      },
      {
        title: fieldsLocale.dateExpired,
        field: 'checkDateEnd',
        render: (row) => (row.checkDateEnd ? createLocalDateDDMMYYYY(row.checkDateEnd) : ''),
      },
      {
        title: fieldsLocale.checkStatus,
        field: 'checkStatusName',
        filterType: TableFilterTypeEnum.string,
      },
    ],
    []
  );

  const columnsWithFilters = useMemo<Array<Column<Device>>>(
    () =>
      addFiltersToColumns({
        filters: filterDTO.filters,
        setFilters,
        columns,
      }),
    [filterDTO.filters, setFilters, columns]
  );
  return (
    <FullPageWrapper>
      <SubsystemHeader
        title="Приборы"
        addButtonProps={{
          onClick: () => history.push('/equipment/devices/add'),
          disabled: !isWrite,
        }}
        filtersComponent={
          <DeviceTableFilter
            onSubmit={(values) => {
              const filters: TFiltersArray = Object.values(values);
              setFilters(filters);
            }}
            isDisabled={isLoading}
            isLoading={isLoading}
            filters={filterDTO.filters}
          />
        }
      />

      <MaterialTable
        filterDTO={filterDTO}
        data={data}
        meta={response?.meta}
        columns={columnsWithFilters}
        toggleOrder={toggleOrder}
        onPageChange={onPageChange}
        actions={[
          {
            type: 'edit',
            onClick: (e, item) => {
              if (Array.isArray(item)) {
                return;
              }

              onEdit(item);
            },
            hidden: !isWrite,
          },
          {
            type: 'delete',
            onClick: (e, item) => {
              if (Array.isArray(item)) {
                return;
              }
              handleOpenModal({ url: `/equipment/v10/equipments/instruments/${item.deviceId}` });
            },
            hidden: !isWrite,
          },
          {
            position: 'row',
            action: (row) => ({
              icon: () => <StatusIcon />,
              onClick: () => {
                const { deviceId, checkStatus } = row;
                const newStatus = Object.keys(statuses).find((status) => status !== checkStatus);
                setUpdatedStatus({
                  id: deviceId,
                  checkStatus: newStatus,
                });
                onOpen();
              },
              tooltip: 'Обновить статус поверки',
              hidden: !row.checkStatus,
            }),
          },
          {
            position: 'row',
            action: (row) => ({
              icon: () => <ImageIcon />,
              onClick: () => open(row.deviceId, row.objectType),
              tooltip: 'Показать изображения',
              hidden: !row.objectType,
            }),
          },
        ]}
        isLoading={isLoading}
        errorText={errorText}
        options={{ selection: false }}
        {...materialTableProps}
      />

      {ConfirmDeleteModal}

      <ConfirmModal
        isOpen={isOpen}
        onClose={onClose}
        onClick={() => {
          onUpdate();
          onClose();
        }}
        header={messages.update}
      />
      <FilePreviewModalWrapper {...filePreviewData} />
    </FullPageWrapper>
  );
};
