export const h2sAndSulfidesMgD3 = '51f4e6b2-76b6-4cdf-98ad-50ea15d27b3e';
export const h2sAndSulfideMkgD3 = '334b11d9-d663-416f-b132-c59a8b3355e6';
export const hdsAndSulfidesId = '8620c18b-1dd6-41b2-953f-802d5cf14a22';

export interface ISurfaceWaterParameters {
  aluminium: number;

  ammoniumIon: number;

  anionicSurfactants: number;

  arsenic: number;

  biologicalOxygenDemand5: number;

  cadmium: number;

  calcium: number;

  chemicalOxygenDemand: number;

  waterTemperature: number,

  dissolvedOxygen: number,

  chlorideIon: number;

  chrome: number;

  chrome6: number;

  cobalt: number;

  copper: number;

  diaphaneity: number;

  fluorideIon: number;

  formaldehyde: number;

  h2sAndSulfides: number;

  h2sAndSulfidesMgDm3: number;
  iron: number;

  lead: number;

  magnesium: number;

  manganese: number;

  molybdenum: number;

  nickel: number;

  nitrateIon: number;

  nitriteIon: number;

  petrochemicals: number;

  phIndex: number;

  phenols: number;

  phosphateIon: number;

  potassium: number;

  selenium: number;

  smell20: string;

  smell60: string;

  sodium: number;

  solid: number;

  sulfateIon: number;

  suspendedSubstance: number;

  zinc: number;

  ammoniumIonAzot: string;
  nitrinAzot: string;
  nitratAzot: string;
  phosphatePhosfor: string;
}

type TParam = {
  id: string
  parameterId: string
  parameterName: string
  value: string
  unitId: string
  recalculated?: boolean
};

export interface ISurfaceWaterLoadData extends ISurfaceWaterParameters {
  id: string;
  protocolDate: string;
  protocolNumber: string;
  probeDate: string;
  waterSourceSourceId: string,
  sourceId: string;
  consumerId: string,
  organizationId: string,
  waterObjectSourceId: string;
  sampleDate: string;
  region: string;
  district: string;
  waterObjectId: string;
  // координаты
  longitude: string,
  latitude: string,
  values: TParam[]
  deleted: boolean
}

export interface SourceInfo {
  district: string;
  id: string;
  latitude: number;
  longitude: number;
  region: string,
  waterObject: string;
  values: TParam[];
  organizationId: string;
  consumerId: string
}
export type WaterSurfaceParameters = {
  phIndex: string
  suspendedSubstance: string
  chemicalOxygenDemand: string
  biologicalOxygenDemand5: string
  ammoniumIon: string
  nitriteIon: string
  nitrateIon: string
  sulfateIon: string
  chlorideIon: string
  fluorideIon: string
  petrochemicals: string
  phosphateIon: string
  aluminium: string
  iron: string
  cadmium: string
  potassium: string
  calcium: string
  cobalt: string
  magnesium: string
  manganese: string
  copper: string
  molybdenum: string
  arsenic: string
  sodium: string
  nickel: string
  lead: string
  selenium: string
  chrome: string
  chrome6: string
  zinc: string
  h2sAndSulfides: string
  h2sAndSulfidesMgDm3: string
  phenols: string
  formaldehyde: string
  anionicSurfactants: string
  solid: string
  smell20: string
  smell60: string
  diaphaneity: string
  ammoniumIonAzot: string
  nitrinAzot: string
  nitratAzot: string
  phosphatePhosfor: string
};

export const WaterSurfaceParametersKeys: Array<keyof WaterSurfaceParameters> = [
  'phIndex',
  'suspendedSubstance',
  'chemicalOxygenDemand',
  'biologicalOxygenDemand5',
  'ammoniumIon',
  'nitriteIon',
  'nitrateIon',
  'sulfateIon',
  'chlorideIon',
  'fluorideIon',
  'petrochemicals',
  'phosphateIon',
  'aluminium',
  'iron',
  'cadmium',
  'potassium',
  'calcium',
  'cobalt',
  'magnesium',
  'manganese',
  'copper',
  'molybdenum',
  'arsenic',
  'sodium',
  'nickel',
  'lead',
  'selenium',
  'chrome',
  'chrome6',
  'zinc',
  'h2sAndSulfides',
  'h2sAndSulfidesMgDm3',
  'phenols',
  'formaldehyde',
  'anionicSurfactants',
  'solid',
  'smell20',
  'smell60',
  'diaphaneity',
  'ammoniumIonAzot',
  'nitrinAzot',
  'nitratAzot',
  'phosphatePhosfor'
];

export type TCount = {
  rightLabel: string;
  leftLabel: string;
  leftFormula: (value: number) => number;
  rightFormula: (value: number) => number;
  leftIndex?: number;
  leftUnit?: string;
  rightUnit?: string;
  rightParam?: string;
  leftParam?: string;
};

export interface TParamProps {
  h2sAndSulfides: TCount;
  ammoniumIon: TCount;
  nitriteIon: TCount;
  nitrateIon: TCount;
  phosphateIon: TCount;
}
