import appLocale from 'constants/appLocale';
import { RenderTree } from 'features/WithSideBar/types';

export const FTPRootPath = '/admin/ftp';
export const FTPSettings = `${FTPRootPath}/settings`;

export const statuses = [{ name: 'Активен', value: true }, { name: 'Заблокирован', value: false }];
export const configError = 'Ошибка при попытке обновить конфигурацию сервиса интеграции';

export const ftpMenu: RenderTree = {
  id: FTPRootPath,
  name: appLocale.admin.ftp,
  url: FTPRootPath,
  children: [
    {
      id: `${FTPSettings}`,
      url: `${FTPSettings}/servers`,
      name: appLocale.admin.ftpSettings,
    },
    {
      id: `${FTPRootPath}/upload`,
      url: `${FTPRootPath}/upload`,
      name: appLocale.admin.ftpUpload,
    },
    {
      id: `${FTPRootPath}/dataPatterns`,
      url: `${FTPRootPath}/dataPatterns`,
      name: appLocale.admin.ftpDataPatterns,
    },
  ],
};

export const getArraySelectOptions = (name: string, data: any[]) => {
  const optionsSet = new Set(data
    .map((item) => item[name])
    .filter((item) => item !== '' && (typeof item === 'string' || typeof item === 'number')));

  return Array
    .from(optionsSet)
    .map((item) => ({ name: item, value: item }));
};
