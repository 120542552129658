import { SelectOption } from 'components/common';
import { Filter } from 'features/Filter';
import { createInitialValuesToFormik, createValuesToRequest } from 'features/Filter/utils';
import React, { memo, useMemo } from 'react';

import { TMeteoDataTFilterDTO } from '../types';
import { IPeriod } from 'features/PeriodsButtons/types';
import { IFilterItem } from 'models';
import combineFilters from 'lib/utils/combineFilters';
import { SchemaForCustomFilter } from 'models/schemas/filtersDatepickersSchema/FiltersDatepickersSchema';

type FilterMeteoListInitialValues = Record<
  'dateFrom' | 'dateTo' | 'stationId' | 'periodId' | 'initialData',
  TMeteoDataTFilterDTO['filters'][number]
>;

const filterLables = {
  dateFrom: 'От', // string
  dateTo: 'До', // string
  limit: 'Количество записей', // number
  offset: 'Отступ', // number
  parameterId: 'Параметр', // string
  showDeleted: 'Показать удаленные', // boolean
  sortBy: 'Поле сортировки', // string
  sortOrder: 'Порядок сортировки', // string
  stationId: 'Станция', // string
  sourceType: 'Тип источника', // string
};

const initialValues: FilterMeteoListInitialValues = {
  periodId: {
    key: 'periodId',
    operation: 'EQ',
    value: null,
  },
  dateFrom: {
    key: 'measureDate',
    operation: 'GTE',
    value: '',
  },
  dateTo: {
    key: 'measureDate',
    operation: 'LTE',
    value: '',
  },
  stationId: { key: 'stationId', operation: 'EQ', value: null },
  initialData: {
    key: 'initialData',
    operation: 'EQ',
    value: false,
  },
};

interface MeteoDataFilterProps {
  stations?: SelectOption[];
  isLoading: boolean;
  onSubmit: (values: FilterMeteoListInitialValues) => void;
  periods: IPeriod[] | null;
  filters: IFilterItem[];
}

const initialValuesToFormik = createInitialValuesToFormik(initialValues);

function MeteoDataFilterComponent({
  stations = [],
  isLoading,
  onSubmit,
  children,
  periods,
  filters
}: React.PropsWithChildren<MeteoDataFilterProps>) {
  const presetValues = useMemo<{ key: string, value: IFilterItem['value'] }[]>(() => {
    const preset: { key: string, value: IFilterItem['value'] }[] = [];
    const initialFilters = combineFilters(initialValues, filters);
    Object.keys(initialFilters).forEach((key) => {
      preset.push({ key: `${key}`, value: initialFilters[key].value });
    });
    return preset;
  }, [filters]);

  return (
    <Filter<typeof initialValuesToFormik>
      dateFieldFrom="dateFrom"
      dateFieldTo="dateTo"
      periods={periods}
      isLoading={isLoading}
      isDisabled={isLoading}
      onSubmit={(values) => {
        onSubmit(createValuesToRequest(initialValues, values));
      }}
      initialValues={initialValuesToFormik}
      savedPeriodId={filters.find((item) => item.key === 'periodId')?.value as string || ''}
      mainFields={[
        {
          type: 'DATE',
          name: 'dateFrom',
          label: filterLables.dateFrom,
          showTimeInput: true,
        },
        {
          type: 'DATE',
          name: 'dateTo',
          label: filterLables.dateTo,
          showTimeInput: true,
        },
        {
          type: 'SELECT',
          name: 'stationId',
          label: filterLables.stationId,
          options: stations,
        },
        {
          type: 'CHECKBOX',
          name: 'initialData',
          label: 'Исходные данные',
        },
      ]}
      validationSchema={() =>
        SchemaForCustomFilter([{ dateFromFieldName: 'dateFrom', dateToFieldName: 'dateTo' }])
      }
      presetValues={presetValues}
    >
      {children}
    </Filter>
  );
}

const MeteoDataFilter = memo(MeteoDataFilterComponent);

export { MeteoDataFilter };
