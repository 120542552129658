import {
  Appeals,
  APPEALS_TABLE_VIEW,
  CitizenAppealsTableViewPath,
  CitizensAppealsPath,
  RaidJournalPath,
  RaidJournalPathNoise,
  VideoCamerasPath,
} from 'pages/citizenAppeal/consts/consts';
import { CitizenIcon } from 'features/WithSideBar/icons';
import { subsystemCodesMap } from 'constants/subsystemIdsMap';

export const citizenAppealsMenu = {
  name: 'Учет обращений граждан',
  url: CitizensAppealsPath,
  id: CitizensAppealsPath,
  icon: <CitizenIcon />,
  children: [
    {
      name: 'Обращения граждан',
      url: Appeals,
      id: Appeals,
      otherCode: subsystemCodesMap.citizenAppeals.appeals,
    },
    {
      name: 'Журнал рейдов',
      url: RaidJournalPathNoise,
      id: RaidJournalPath,
      otherCode: subsystemCodesMap.citizenAppeals.raidsRoot
    },
    {
      name: 'Учет камер видеонаблюдения',
      url: VideoCamerasPath,
      id: VideoCamerasPath,
      otherCode: subsystemCodesMap.citizenAppeals.appealsCameras
    },
    {
      name: APPEALS_TABLE_VIEW,
      url: `${CitizenAppealsTableViewPath}/noise`,
      id: CitizenAppealsTableViewPath,
      otherCode: subsystemCodesMap.citizenAppeals.appealsTableView
    },
  ],
};
