import React from 'react';
import { PageHeader, RoutesList } from 'components/common';
import appLocale from 'constants/appLocale';
import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import { Flex, Heading } from '@chakra-ui/react';

export const AtmosphereList: React.FC = () => {
  useUpdateBreadcrumbs([
    { breadcrumb: appLocale.common.data, path: '/data' },
    {
      breadcrumb: appLocale.subsystems.askza,
      path: '/data/atmosphere',
    },
  ]);
  return (
    <Flex flexDirection="column">
      <PageHeader>
        <Heading>{appLocale.subsystems.askza}</Heading>
      </PageHeader>
      <RoutesList
        routes={[
          {
            path: '/data/atmosphere/monitoring',
            breadcrumb: 'Данные мониторинга',
          },
          // {
          //   path: '/data/atmosphere/protocol',
          //   breadcrumb: 'Протокол обработки данных',
          // },
        ]}
      />
    </Flex>
  );
};
