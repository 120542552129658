import React, { useMemo } from 'react';
import { IFilterItem } from 'models';
import combineFilters from 'lib/utils/combineFilters';
import { createInitialValuesToFormik, createValuesToRequest } from 'features/Filter/utils';
import { Filter } from 'features/Filter';
import appLocale from 'constants/appLocale';

const initialValues: Record<string, IFilterItem> = {
  dangerScale: {
    key: 'dangerScale',
    operation: 'CONTAINS',
    value: '',
  },
  dutyScale: {
    key: 'dutyScale',
    operation: 'CONTAINS',
    value: '',
  },
  rangeStart: {
    key: 'rangeStart',
    operation: 'GTE',
    value: null,
  },
  rangeEnd: {
    key: 'rangeEnd',
    operation: 'LTE',
    value: null,
  },
  deleted: {
    key: 'deleted',
    operation: 'EQ',
    value: false,
  },
};

const initialValuesToFormik = createInitialValuesToFormik(initialValues);

interface IProps {
  onSubmit: (values: any) => void;
  isLoading: boolean;
  filters: IFilterItem[];
}

export const NSIDangerScaleFilter = ({ onSubmit, isLoading, filters, children }: React.PropsWithChildren<IProps>) => {
  const presetValues = useMemo<{ key: string; value: IFilterItem['value'] }[]>(() => {
    const preset: { key: string; value: IFilterItem['value'] }[] = [];
    const initialFilters = combineFilters(initialValues, filters);
    Object.keys(initialFilters).forEach((key) => {
      preset.push({ key: `${key}`, value: initialFilters[key].value });
    });
    return preset;
  }, [filters]);

  return (
    <Filter<typeof initialValuesToFormik>
      isLoading={isLoading}
      isDisabled={isLoading}
      onSubmit={(values) => {
        onSubmit(createValuesToRequest(initialValues, values));
      }}
      initialValues={initialValuesToFormik}
      mainFields={[
        {
          type: 'TEXT',
          name: 'dangerScale',
          label: appLocale.nsi.dangerScale.fields.dangerScale,
        },
        {
          type: 'TEXT',
          name: 'dutyScale',
          label: appLocale.nsi.dangerScale.fields.dutyScale,
        },
        {
          type: 'NUMBER',
          name: 'rangeStart',
          label: appLocale.nsi.dangerScale.fields.rangeStart,
        },
        {
          type: 'NUMBER',
          name: 'rangeEnd',
          label: appLocale.nsi.dangerScale.fields.rangeEnd,
        },
        {
          type: 'CHECKBOX',
          name: 'deleted',
          label: appLocale.common.showDeleted,
        },
      ]}
      presetValues={presetValues}
    >
      {children}
    </Filter>
  );
};
