import * as yup from 'yup';
import { ValidationError } from 'yup';
import { requiredError } from '../constans';
import { FormikValues } from 'formik';

export const GroundwaterMonitoringSchema = yup
  .object()
  .shape({
    measuringDate: yup.string().required(requiredError),
    waterSourceType: yup.string().required(requiredError).typeError(requiredError),
    sourceId: yup.string().required(requiredError).typeError(requiredError),
    ecologicalState: yup.object().shape({
      value: yup.string().nullable(),
    }),
    capturingState: yup.object().shape({
      value: yup.string().nullable(),
    }),
  })
  // @ts-ignore
  .test((values: FormikValues) => {
    if (!values.ecologicalState.value && !values.capturingState.value) {
      return new ValidationError([
        new ValidationError(requiredError, '', 'ecologicalState.value'),
        new ValidationError(requiredError, '', 'capturingState.value'),
      ]);
    }
  });
