import { LettersForm } from './LettersForm';
import { MaterialTable } from 'features/material-table/MaterialTableMini';
import React, { useContext, useMemo } from 'react';
import { Button, Modal } from 'components/common';
import { useDisclosure, useHandleDeleteData, useList } from 'hooks';
import { ILetter } from './types/types';
import { AddIcon } from 'components/icons';
import moment from 'moment';
import { getSelectOptionsFromDictionaryByName } from 'features/get-select-options-from-dictionary';
import { TaskPrepareContext } from '../TaskPrepareContext';
import { Close } from '@material-ui/icons';
import { ITaskFormer } from '../types';
import { Flex, Grid, Heading } from '@chakra-ui/react';

interface IProps {
  task: ITaskFormer;
}

export const Letters = ({ task }: IProps) => {
  const { dictionaries } = useContext(TaskPrepareContext);

  const {
    response,
    toggleOrder,
    onPageChange,
    filterDTO,
    refetch,
    errorText,
  } = useList<ILetter>(
    `appeal/v10/tasks/${task?.id}/letters`
  );

  const { isOpen, onOpen, onClose } = useDisclosure();
  const { handleOpenModal, ModalComponent } = useHandleDeleteData({ refetch });

  const customClose = () => {
    onClose();
    refetch();
  };

  const authorities = useMemo(
    () => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'authorities'),
    [dictionaries]
  );

  return (
    <>
      <Button
        leftIcon={<AddIcon />}
        variant="ghost"
        color="PrimaryButton.500"
        size="sm"
        p={0}
        marginBottom={10}
        onClick={onOpen}
      >
        Создать новое письмо
      </Button>

      <Grid gap={5}>
        <MaterialTable
          data={response?.data}
          meta={response?.meta}
          toggleOrder={toggleOrder}
          onPageChange={onPageChange}
          filterDTO={filterDTO}
          columns={[
            {
              title: 'Структура',
              field: 'authorityId',
              render: (row) => authorities.find((authority) => authority.value === row.authorityId)?.name,
            },
            {
              title: 'Нарушение',
              field: 'reason',
            },
            {
              title: '№ Согла / Дата',
              field: 'edoAgreementNumber',
              render: (row: ILetter) => {
                const date = moment(row.edoAgreementDate);
                const edoAgreement = `${row.edoAgreementNumber ? `${row.edoAgreementNumber} /` : ''}${row.edoAgreementDate ? date.format('DD.MM.YYYY') : ''}`;
                return (
                  <Flex flexDirection="row">
                    <span>{edoAgreement}</span>
                  </Flex>
                );
              },
            },
            {
              title: 'Входящий № / дата',
              field: 'incomingNumber',
              render: (row: ILetter) => {
                const date = moment(row.incomingDate);
                const incoming = `${row.incomingNumber ? `${row.incomingNumber} /` : ''}${row.incomingDate ? date.format('DD.MM.YYYY') : ''}`;
                return (
                  <Flex flexDirection="row">
                    <span>{incoming}</span>
                  </Flex>
                );
              },
            },
          ]}
          detailPanel={(rowData) => (
            <Grid marginTop={5} marginBottom={5} width="100%">
              <LettersForm data={rowData.rowData} customClose={onClose} isDetailPanel />
            </Grid>
          )}
          actions={[
            {
              type: 'delete',
              onClick: (_e, item) => {
                handleOpenModal({
                  url: `appeal/v10/letters/${item.id}`,
                });
              },
            },
          ]}
          errorText={errorText}
        />

        {ModalComponent}

        <Modal
          isOpen={isOpen}
          onClose={onClose}
          width="100%"
          customCloseButton={(
            <Button
              onClick={onClose}
              marginTop={5}
              marginRight="auto"
              variant="ghost"
              color="gray"
              marginLeft="20px"
              leftIcon={<Close />}
            >
              Закрыть
            </Button>
          )}
        >
          <Grid width="100%">
            <Heading marginBottom={10}>Создание нового письма</Heading>
            <LettersForm customClose={customClose} />
          </Grid>
        </Modal>
      </Grid>
    </>
  );
};
