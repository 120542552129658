import { DateInput, FormWrapper, Select } from 'components/common';
import { Box, Grid, Heading } from '@chakra-ui/react';
import {
  getSelectOptionsFromDictionaryByName,
  getSelectOptionsFromDictionaryByNameWithParent,
} from 'features/get-select-options-from-dictionary';
import { Field, useFormikContext } from 'formik';
import { InclinometerSaveItem } from 'hooks/geo/Inclinometer/useGeoInclinometerList';
import { createDateFromResponse, createDateToRequestWithTimezone } from 'lib/create-date';
import { TDictionariesArray } from 'models/dictionaries';
import React, { useMemo } from 'react';
import { getFilteredOptionsByPrivileges } from 'features/getFilteredOptionsByPrivileges';

interface CommonTabProps {
  dictionaries: {
    data: TDictionariesArray;
  } | null;
}

export const CommonTab = ({ dictionaries }: CommonTabProps) => {
  const { setFieldValue, values, errors } = useFormikContext<InclinometerSaveItem>();
  const isEditMode = location.pathname.includes('/edit');

  const areas = useMemo(
    () =>
      getFilteredOptionsByPrivileges(
        getSelectOptionsFromDictionaryByName(dictionaries?.data, 'areas'),
        'isEditable',
        'value',
        values.areaId,
      ).map((item) => ({
        ...item,
        name: item.status === 'DISABLED' ? `${item.name} (выкл)` : item.name,
      })),
    [dictionaries?.data, values.areaId],
  );

  const points = useMemo(() => {
    const dictionary = getSelectOptionsFromDictionaryByNameWithParent(dictionaries?.data, 'points');

    if (values.areaId) {
      return dictionary.filter(({ parent }) => parent === values.areaId);
    }

    return dictionary.map((item) => ({
      ...item,
      name: item.status === 'DISABLED' ? `${item.name} (выкл)` : item.name,
    }));
  }, [dictionaries?.data, values.areaId]);

  const depthsArray = useMemo(
    () => getSelectOptionsFromDictionaryByNameWithParent(dictionaries?.data, 'depths'),
    [dictionaries],
  );

  return (
    <Box flexDirection="column">
      <FormWrapper>
        <Grid marginTop="30px" gap="15px">
          <Heading variant="h2">Данные обследования</Heading>
          <DateInput
            name="valDateTime"
            label="Дата обследования"
            value={createDateFromResponse(values.valDateTime)}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setFieldValue('valDateTime', createDateToRequestWithTimezone(e.target.value))
            }
            showTimeInput={false}
            isDisabled={isEditMode}
          />
          <Field
            as={Select}
            name="areaId"
            label="Участок наблюдения"
            value={values.areaId}
            options={areas}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              setFieldValue('areaId', e.target.value);
              setFieldValue('pointId', '');
            }}
            error={errors.areaId}
            isDisabled={isEditMode}
          />
          <Field
            as={Select}
            name="pointId"
            label="Точка наблюдения"
            value={values.pointId}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              setFieldValue('pointId', e.target.value);
              const newDepths = depthsArray
                .filter((item) => item.parent === e.target.value)
                .sort((a, b) => Number(a.name) - Number(b.name));

              setFieldValue(
                'data',
                newDepths.map((el) => ({
                  accuracy: '',
                  xOffset: '',
                  yOffset: '',
                  depthId: el.value,
                  depth: el.name,
                })),
              );
            }}
            options={points}
            error={errors.pointId}
            isDisabled={isEditMode}
          />
        </Grid>
      </FormWrapper>
    </Box>
  );
};
