import { PortalPopoverProps } from 'components/common';

export const dangerProcessPopoverProps: PortalPopoverProps = {
  variant: 'ghost',
  textDecoration: 'underline',
  textDecorationStyle: 'dotted',
  padding: 0,
  PopoverProps: {
    placement: 'bottom-start',
  },
};
