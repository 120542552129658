import { useCallback, useMemo } from 'react';
import { IPeriod } from './types';
import { IFilterItem } from 'models';

export function usePeriods(periodId: IFilterItem['value'], periods: IPeriod[] | null | undefined) {
  const getDatesFromPeriod = useCallback(
    (periodId: IFilterItem['value']) => {
      const selectedPeriod = periods?.find((period) => period.id === periodId);

      if (selectedPeriod) {
        return [selectedPeriod.begin, selectedPeriod.end];
      }
      return [null, null];
    },
    [periods]
  );

  const dates = useMemo(() => getDatesFromPeriod(periodId), [getDatesFromPeriod, periodId]);

  return { getDatesFromPeriod, dates };
}
