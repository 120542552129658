import { useState } from 'react';

export const useSelectPercent = () => {
  // приходится в процент кидать строку, потому что типизация такая))))))0)0
  const [percent, setPercent] = useState('0.1');
  const handleChangePercent = (period: any) => {
    setPercent(period?.target?.value);
  };

  return {
    percent,
    handleChangePercent,
  };
};
