import { createTouchedErrors } from 'features/create-touched-errors';
import { FormikProvider, useFormik } from 'formik';
import { useRequest } from 'hooks/useRequest';
import { useParams } from 'react-router-dom';
import FieldsForm from './NSIEmissionsSourcesFormFields';
import { NSIEmissionsApiPath, NSIEmissionsSourcesRootPath } from 'pages/dictionaries/NSIEmissions/consts/paths';
import { useEnhancedNotification } from 'hooks';
import { NsiEmissionsSourcesSchema } from 'models/schemas/nsiEmissions/nsiEmissionsSources';
import { useEffect } from 'react';
import {
  formatNSIEmissionSourceNumber,
  initialEmptyValues,
} from 'pages/dictionaries/NSIEmissions/NSIEmissionsSources/NSIEmissionsSourcesForms/NSIEmissionsSourcesAdd';
import { useGoBackWithFallback } from 'lib/utils/goBackWithFallback';
import { useDictionary } from 'hooks/useDictionary';

const NSIEmissionsSourcesEdit = () => {
  const { backWithFallback } = useGoBackWithFallback();
  const { id } = useParams<{ id: string }>();
  const { dictionaries, isDictionariesLoading } = useDictionary({ url: `${NSIEmissionsApiPath}/dictionaries` });
  const { isLoading: isItemLoading, result, get } = useRequest(`${NSIEmissionsApiPath}/object/${id}`);

  useEffect(() => {
    void get();
  }, [get]);

  const { onSuccessEdit, onErrorEdit } = useEnhancedNotification();

  const onSuccess = () => {
    onSuccessEdit();
    backWithFallback(NSIEmissionsSourcesRootPath);
  };

  const { put, isLoading } = useRequest(NSIEmissionsApiPath, onSuccess, onErrorEdit);

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    initialValues: { ...initialEmptyValues, ...result },
    validationSchema: NsiEmissionsSourcesSchema,
    onSubmit: (values) =>
      put({ ...values, number: formatNSIEmissionSourceNumber(String(values.number)), type: 'object' }),
  });

  return (
    <FormikProvider
      value={{
        ...formik,
        errors: createTouchedErrors(formik.errors, formik.touched),
      }}
    >
      <FieldsForm
        dictionaries={dictionaries}
        onCancel={() => {
          backWithFallback(NSIEmissionsSourcesRootPath);
        }}
        isLoading={isDictionariesLoading || isLoading || isItemLoading}
        isEditMode
        editedObject={result}
      />
    </FormikProvider>
  );
};

export default NSIEmissionsSourcesEdit;
