import React, { useMemo } from 'react';
import { Field, FieldArray, Form, useFormikContext } from 'formik';
import { Box, Flex, Grid, GridItem, Heading, Stack } from '@chakra-ui/react';
import { Button, DateInput, Input, LoaderItem, Select } from 'components/common';
import GarbageJournalObservationPlace from './GarbageJournalObservationPlace';
import { GarbageJournal } from '../types';
import { IMainObservationPlace } from '../../NoiseReport/types';
import { getSelectRichOptionsFromDictionaryByName } from 'features/get-select-options-from-dictionary';
import { appealRoot, tasksDictionariesPath } from 'pages/citizenAppeal';
import { AddIcon } from 'components/icons';
import { IUploadMedia } from 'components/common/FileUploadDragAndDrop/types';
import { MapForPels } from '../../../../features/PelsMap/MapForPels';
import { createUTCTime } from 'lib/create-date';
import { useGoBackWithFallback } from 'lib/utils/goBackWithFallback';
import { useDictionary } from 'hooks/useDictionary';

interface Props {
  type: string;
  onSave: () => void;
  media: IUploadMedia[][];
  onMediaSave: (media: IUploadMedia[], idx: number) => void;
  backPath: string;
  browserId: string;
}
const GarbageJournalForm = ({ type, onSave, onMediaSave, media, backPath, browserId }: Props) => {
  const { backWithFallback } = useGoBackWithFallback();
  const { values, handleChange, errors, setFieldValue } = useFormikContext<GarbageJournal>();
  const { dictionaries, isDictionariesLoading: isPelDictsLoading } = useDictionary({ url: tasksDictionariesPath });
  const districts = useMemo(
    () => getSelectRichOptionsFromDictionaryByName(dictionaries?.data, 'districts'),
    [dictionaries]
  );
  const regions = useMemo(
    () => getSelectRichOptionsFromDictionaryByName(dictionaries?.data, 'regions'),
    [dictionaries]
  );

  const filteredRegions = useMemo(
    () => regions.filter((item) => item.parent === values.taskDistrictIds[0]),
    [regions, values.taskDistrictIds]
  );

  const {
    raidShiftId,
    garbagePileTypeId,
    burningSmellTypeId,
    pointTypeId,
    isDictionariesLoading: isReportDictsLoading,
  } = useDictionary({ url: `${appealRoot}/reports/dictionaries` });

  const createPoint = (): IMainObservationPlace => ({
    pointAddress: '',
    workTiming: '',
    id: '',
    pointLongitude: '',
    isRepeat: false,
    aiProtocolNumber: '',
    pointNumber: '',
    pelProtocolDate: '',
    isInformationBoard: false,
    isInformationBoardNMEPD: false,
    isSetPelProtocol: false,
    constructionObject: '',
    pelProtocolNumber: '',
    protocolExecutorUserId: '',
    report: '',
    measurementTimeTo: null,
    objectTypeId: '',
    aiProtocolDate: '',
    isRenovation: false,
    pointLatitude: '',
    generalContractor: '',
    raidResultId: '',
    isSetAiProtocol: false,
    isWorkDone: false,
    hasExcess: false,
    territoryTypeId: '',
    isViolation: false,
    pointCoordinateArray: '',
    protocolNumber: '',
    measurementTimeFrom: null,
    pointTypeId: '',
    protocolDate: '',
    noiseCharacterId: '',
    customer: '',
    pelStationNumber: null,
    temporaryId: '',
    raidResultText: '',
  });

  return (
    <Form>
      {isPelDictsLoading || isReportDictsLoading ? (
        <LoaderItem />
      ) : (
        <>
          <Heading as="h3" size="md">
            Территория наблюдения:
          </Heading>

          <Grid templateColumns="1fr 2fr" gap={10} marginY={5}>
            <GridItem>
              <Stack direction="column" spacing={5}>
                <Field as={Input} name="raidNumber" label="№ рейда" onChange={handleChange} error={false} />
                <Field
                  as={DateInput}
                  name="raidDate"
                  label="Дата проведения рейда"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setFieldValue(e.target.name, createUTCTime(e.target.value))
                  }
                  error={false}
                />
                <Field
                  as={Select}
                  name="raidShiftId"
                  label="Смена"
                  options={raidShiftId}
                  onChange={handleChange}
                  error={false}
                />
                <Field
                  as={Select}
                  name="taskDistrictIds[0]"
                  label="Округ"
                  options={districts}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setFieldValue('taskDistrictIds[0]', e.target.value);
                    setFieldValue('taskRegionIds[0]', null);
                  }}
                  error={false}
                />
                <Field
                  as={Select}
                  name="taskRegionIds[0]"
                  label="Район"
                  options={filteredRegions}
                  onChange={handleChange}
                  error={false}
                />
              </Stack>
            </GridItem>
            <Box ml={2} width="auto" height="600px">
              <MapForPels browserId={browserId} uid={values.id} isReports pointTypeId={pointTypeId} />
            </Box>
          </Grid>

          <FieldArray name="points">
            {({ push, handleRemove }) => (
              <Stack direction="column" spacing={5}>
                {values.points?.map((point, i) => (
                  <GarbageJournalObservationPlace
                    media={media[i]}
                    onMediaSave={onMediaSave}
                    type={type}
                    garbagePileTypeId={garbagePileTypeId}
                    burningSmellTypeId={burningSmellTypeId}
                    name={`points[${i}]`}
                    key={i}
                    onRemovePoint={handleRemove(i)}
                    values={point}
                    index={i}
                    handleChange={handleChange}
                    browserId={browserId}
                    uid={values.id}
                    errors={errors}
                  />
                ))}
                <Grid>
                  <GridItem justifySelf="flex-start">
                    <Button
                      leftIcon={<AddIcon />}
                      variant="ghost"
                      color="PrimaryButton.500"
                      size="sm"
                      p={0}
                      marginBottom={10}
                      onClick={() => push({ ...createPoint(), temporaryId: Math.random() })}
                    >
                      Добавить место наблюдения
                    </Button>
                  </GridItem>
                </Grid>
              </Stack>
            )}
          </FieldArray>
          <Flex marginTop={5}>
            <Button marginRight={5} onClick={() => backWithFallback(backPath)}>
              Отмена
            </Button>
            <Button onClick={onSave}>Сохранить</Button>
          </Flex>
        </>
      )}
    </Form>
  );
};

export default GarbageJournalForm;
