import { useMemo } from 'react';
import { useGetSubsystemRights } from './useGetSubsystemRights';

export const useAvailability = (subsystemId: string) => {
  const { userRights, isRightLoading } = useGetSubsystemRights(subsystemId);

  const isReadOnly = useMemo(() => Boolean(userRights?.actions.find((action) => action === 'READ')), [userRights]);

  const isWrite = useMemo(() => Boolean(userRights?.actions.find((action) => action === 'WRITE')), [userRights]);

  const isExecute = useMemo(() => Boolean(userRights?.actions.find((action) => action === 'EXECUTE')), [userRights]);

  const isDelete = useMemo(() => Boolean(userRights?.actions.find((action) => action === 'WRITE')), [userRights]);

  const isNoRights = useMemo(() => (!isExecute && !isWrite && !isReadOnly), [isExecute, isReadOnly, isWrite]);

  const allAvailable = useMemo(() => (isExecute && isWrite && isReadOnly), [isExecute, isReadOnly, isWrite]);
  const isAvailable = useMemo(() => (allAvailable || isNoRights || isWrite), [allAvailable, isNoRights, isWrite]);

  return {
    isReadOnly,
    isWrite,
    isExecute,
    isDelete,
    isNoRights,
    allAvailable,
    isAvailable,
    isRightLoading,
  };
};
