import { NSIRootPath } from 'pages/dictionaries/const/routes';

export const NSIReagentsApiPath = '/nsi/v10/sources/deicing';

export const NSIReagentsRootPath = `${NSIRootPath}/reagents`;

export const NSIReagentsGroundRootPath = `${NSIReagentsRootPath}/ground`;

export const NSIReagentsGroundPaths = {
  root: NSIReagentsGroundRootPath,
  add: `${NSIReagentsGroundRootPath}/add`,
  edit: (id: string) => `${NSIReagentsGroundRootPath}/edit/${id}`,
};

export const NSIReagentsLawnsRootPath = `${NSIReagentsRootPath}/lawns`;

export const NSIReagentsLawnsPaths = {
  root: NSIReagentsLawnsRootPath,
  add: `${NSIReagentsLawnsRootPath}/add`,
  edit: (id: string) => `${NSIReagentsLawnsRootPath}/edit/${id}`,
};

export const NSIReagentsFloraRootPath = `${NSIReagentsRootPath}/trees`;

export const NSIReagentsTreesPaths = {
  root: NSIReagentsFloraRootPath,
  add: `${NSIReagentsFloraRootPath}/add`,
  edit: (id: string) => `${NSIReagentsFloraRootPath}/edit/${id}`,
};

export const NSIReagentsSitesRootPath = `${NSIReagentsRootPath}/sites`;

export const NSIReagentsSitesPaths = {
  root: NSIReagentsSitesRootPath,
  add: `${NSIReagentsSitesRootPath}/add`,
  edit: (id: string) => `${NSIReagentsSitesRootPath}/edit/${id}`,
};
