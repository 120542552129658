import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import appLocale from 'constants/appLocale';
import { NSIRootPath } from '../../const/routes';
import { AtmosphericAirAPIPath, AtmosphericAirDictPath, AtmosphericAirRootPath } from '../consts/paths';
import { BreadcrumbButton, Button, PageHeader } from 'components/common';
import { Flex, Heading, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import React, { FC, useEffect, useMemo } from 'react';
import { GeneralInformation } from './GeneralInformation';
import { Location } from './Location';
import { useParams } from 'react-router-dom';
import { FormikProvider, FormikValues, useFormik } from 'formik';
import { useEnhancedNotification, useRequest, UseRequestReturnType } from 'hooks';
import { getSelectOptionsFromDictionaryByName } from 'features/get-select-options-from-dictionary';
import { createTouchedErrors } from 'features/create-touched-errors';
import { nsiAtmosphericAirSchema } from 'models/schemas/nsi/nsiAtmosphericAirSchema';
import { useGoBackWithFallback } from 'lib/utils/goBackWithFallback';
import { useDictionary } from 'hooks/useDictionary';

export const EditAtmosphericAirData: FC = () => {
  const { onSuccessEdit, onSuccessCreate, onErrorCreate, onErrorEdit } = useEnhancedNotification();
  const { backWithFallback } = useGoBackWithFallback();

  useUpdateBreadcrumbs([
    {
      breadcrumb: appLocale.mainPage.nsi,
      path: NSIRootPath,
    },
    {
      breadcrumb: appLocale.nsi.askza.title,
      path: AtmosphericAirRootPath,
    },
  ]);

  const { id } = useParams<{ id: string }>();

  const onSuccess = () => {
    if (id) {
      onSuccessEdit();
    } else {
      onSuccessCreate();
    }

    backWithFallback(`${AtmosphericAirRootPath}`);
  };

  const { result, get }: UseRequestReturnType = useRequest(`${AtmosphericAirAPIPath}/${id}`);
  const { post } = useRequest(`${AtmosphericAirAPIPath}/create`, onSuccess, onErrorCreate);
  const { put } = useRequest(AtmosphericAirAPIPath, onSuccess, onErrorEdit);

  useEffect(() => {
    if (id) {
      get();
    }
  }, [id, get]);

  const onSubmit = (values: FormikValues) => {
    if (id) {
      put({ ...values, id });
    } else {
      post({ ...values });
    }
  };

  const initialValues = {
    number: result?.number || '',
    name: result?.name || '',
    status: result?.status || '',
    latitude: result?.latitude || '',
    longitude: result?.longitude || '',
    okrugId: result?.okrugId || null,
    rayonId: result?.rayonId || null,
    regionId: result?.regionId || null,
    landmark: result?.landmark || '',
    address: result?.address || '',
    startDate: result?.startDate || null,
    stopDate: result?.stopDate || null,
    areaTypeId: result?.areaTypeId || null,
    stationType: result?.stationType || null,
    stationId: result?.stationId || null,
    step: result?.step || null,
    numberOfPoints: result?.numberOfPoints || null,
    id: id || null,
  };

  const { dictionaries } = useDictionary({ url: AtmosphericAirDictPath });

  const districts = useMemo(() => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'rayons'), [dictionaries]);
  const regions = useMemo(() => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'okrugs'), [dictionaries]);
  const statuses = useMemo(() => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'statuses'), [dictionaries]);
  const types = useMemo(
    () => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'types', false, initialValues.areaTypeId as string),
    [dictionaries?.data, initialValues.areaTypeId],
  );
  const stations = useMemo(() => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'stations'), [dictionaries]);
  const stationTypes = useMemo(
    () => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'stationTypes'),
    [dictionaries],
  );

  const {
    values,
    errors,
    getFieldMeta,
    getFieldHelpers,
    getFieldProps,
    handleReset,
    submitForm,
    setFieldValue,
    ...formik
  } = useFormik({
    validationSchema: nsiAtmosphericAirSchema,
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: false,
    validateOnBlur: true,
    initialValues,
    onSubmit,
  });

  return (
    <FormikProvider
      value={{
        values,
        errors: createTouchedErrors(errors, formik.touched),
        getFieldMeta,
        getFieldHelpers,
        getFieldProps,
        handleReset,
        submitForm,
        setFieldValue,
        ...formik,
      }}
    >
      <PageHeader>
        <BreadcrumbButton onClick={() => backWithFallback(AtmosphericAirRootPath)}>Вернуться назад</BreadcrumbButton>
        <Flex>
          <Heading marginLeft={4}>{`${id ? 'Редактирование' : 'Добавление'} справочных данных`}</Heading>
          <Flex marginLeft="auto">
            <Button marginRight={5} onClick={() => backWithFallback(AtmosphericAirRootPath)}>
              Отмена
            </Button>
            <Button onClick={submitForm}>Сохранить</Button>
          </Flex>
        </Flex>
      </PageHeader>
      <Tabs marginTop={10}>
        <TabList>
          <Tab>Общие сведения</Tab>
          <Tab>Местоположение</Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <GeneralInformation
              isEditMode={!!id}
              stationTypes={stationTypes}
              statuses={statuses}
              types={types}
              stations={stations}
            />
          </TabPanel>

          <TabPanel>
            <Location districts={districts} regions={regions} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </FormikProvider>
  );
};
