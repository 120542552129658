import { FormikProvider, useFormik } from 'formik';
import React, { useEffect } from 'react';
import { IFilterItem, TDictionariesArray, TFiltersArray } from 'models';
import { TableViewFilterFields } from './TableViewFilterFields';
import { initialValues } from './consts';
import { FiltersDatepickersSchema } from '../../../../models/schemas/filtersDatepickersSchema/FiltersDatepickersSchema';
import combineFilters from 'lib/utils/combineFilters';

interface ITableViewFilter {
  onSubmit: (values: { [p: string]: IFilterItem }) => void;
  raidsDictionaries: { data: TDictionariesArray } | null;
  subType: number;
  type: number;
  filters: TFiltersArray;
  isLoading: boolean;
}

export const TableViewFilter = ({
  onSubmit,
  raidsDictionaries,
  subType,
  type,
  filters,
  isLoading,
}: ITableViewFilter) => {
  const { setValues, resetForm, ...formik } = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    validationSchema: FiltersDatepickersSchema([
      { dateFromFieldName: 'edoAgreementDateMin', dateToFieldName: 'edoAgreementDateMax' },
      { dateFromFieldName: 'pelProtocolDateFrom', dateToFieldName: 'pelProtocolDateTo' },
      { dateFromFieldName: 'aiProtocolDateFrom', dateToFieldName: 'aiProtocolDateTo' },
      { dateFromFieldName: 'startMeasurementsFrom', dateToFieldName: 'startMeasurementsTo' },
      { dateFromFieldName: 'endMeasurementsFrom', dateToFieldName: 'endMeasurementsTo' },
      { dateFromFieldName: 'protocolDateFrom', dateToFieldName: 'protocolDateTo' },
      { dateFromFieldName: 'raidDateTimeMin', dateToFieldName: 'raidDateTimeMax' },
      { dateFromFieldName: 'executionPeriodMin', dateToFieldName: 'executionPeriodMax' },
      { dateFromFieldName: 'incomingLetterDateMin', dateToFieldName: 'incomingLetterDateMax' },
      { dateFromFieldName: 'outgoingLetterDateMin', dateToFieldName: 'outgoingLetterDateMax' },
      { dateFromFieldName: 'incomingAnswerDateMin', dateToFieldName: 'incomingAnswerDateMax' },
      { dateFromFieldName: 'outgoingAnswerDateMin', dateToFieldName: 'outgoingAnswerDateMax' },
    ]),
    initialValues,
    onSubmit,
  });

  useEffect(() => {
    resetForm();
  }, [subType, type, resetForm]);

  useEffect(() => {
    setValues(combineFilters(initialValues, filters));
  }, [filters, setValues]);

  return (
    <>
      <FormikProvider
        value={{
          setValues,
          resetForm,
          ...formik,
        }}
      >
        <TableViewFilterFields
          raidsDictionaries={raidsDictionaries}
          subType={subType}
          type={type}
          isLoading={isLoading}
        />
      </FormikProvider>
    </>
  );
};
