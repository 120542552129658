import { Flex, Input, Text } from '@chakra-ui/react';
import { Button } from 'components/common';
import * as React from 'react';
import { ERROR_MESSAGES } from 'constants/messages';
import { useNotifications } from 'hooks';

export type DragAndDropControllerProps = {
  isDisabled: boolean;
  onChange: (file: File) => void;
  acceptedFileExtensions?: string;
  acceptedFileTypes?: string[];
  isLoading?: boolean;
};
const _DragAndDropController: React.FC<DragAndDropControllerProps> = ({
  isDisabled,
  onChange,
  acceptedFileExtensions = '.csv, .xls, .xlsx, .txt, .7zip, .zip, .rar, .7z, .xml',
  acceptedFileTypes,
  isLoading
}) => {
  const [isDragging, setIsDragging] = React.useState(false);
  const { errorNotify } = useNotifications();

  const handleDragIn = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    if (event.dataTransfer.items && event.dataTransfer.items.length > 0) {
      setIsDragging(true);
    }
  };

  const handleDragOut = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();

    setIsDragging(false);
  };

  const handleDrag = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    event.dataTransfer.clearData();
  };

  const onChangeFileInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsDragging(false);
    event.preventDefault();
    const dataFiles = Array.from(event.target.files as ArrayLike<File>);
    onChange(dataFiles[0]);
    // setFile(dataFiles[0]);
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragging(false);
    if (isDisabled) {
      return;
    }
    if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
      const dataFiles = Array.from(event.dataTransfer.files as ArrayLike<File>);

      if (acceptedFileTypes && !acceptedFileTypes.includes(dataFiles[0].type)) {
        errorNotify({
          key: dataFiles[0].name,
          message: ERROR_MESSAGES.FILE_EXTENSION_NOT_ALLOWED,
        });
      } else {
        onChange(dataFiles[0]);
      }
      // setFile(dataFiles[0]);
    }
  };

  return (
    <Flex
      width="100%"
      margin="10px 0px"
      position="relative"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      boxSizing="border-box"
      padding=" 17px 0"
      opacity={isDragging ? 0.9 : 1}
      background={isDragging ? 'menu.active' : 'menu.hover'}
      onDrop={handleDrop}
      onDragEnter={handleDragIn}
      onDragLeave={handleDragOut}
      onDragOver={handleDrag}
    >
      <Input
        type="file"
        id="fileElem"
        accept={acceptedFileExtensions}
        width="100%"
        height="100%"
        top="0px"
        left="0px"
        position="absolute"
        opacity={0}
        // multiple
        onChange={onChangeFileInput}
        onClick={(e): void => {
          (e.target as HTMLInputElement).value = '';
        }}
        isDisabled={isDisabled}
        _disabled={{ opacity: 0 }}
      />
      <Button
        as="label"
        margin="10px 0 10px 0"
        textAlign="center"
        color="panel.text.secondary"
        zIndex={1}
        htmlFor="fileElem"
        isDisabled={isDisabled}
        isLoading={isLoading}
        padding="0 7px"
        cursor="pointer"
        fontSize="16px"
        _hover={{ color: 'text.default' }}
        background="transparent"
      >
        <Text>
          <Text as="span" display="block" color="text.primary">
            Загрузите документ
          </Text>
          <Text as="span" display="block">
            или перенесите в эту область
          </Text>
        </Text>
      </Button>
    </Flex>
  );
};

export const DragAndDropController = React.memo(_DragAndDropController);
