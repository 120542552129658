import { Filter } from 'features/Filter';
import { createInitialValuesToFormik, createValuesToRequest } from 'features/Filter/utils';
import React, { memo, useMemo, useState } from 'react';

import { TLawnsDictionaries } from '../../Lawns/useLawnsDictionaries';
import { TFloraAreaFilterDTO } from '../types';
import { GreenPlantsAreaLabels } from '../../Form/Tabs/Common/constants';
import { IFilterItem } from 'models';
import combineFilters from 'lib/utils/combineFilters';
import { IPeriod } from 'features/PeriodsButtons/types';
import { SchemaForCustomFilter } from 'models/schemas/filtersDatepickersSchema/FiltersDatepickersSchema';
import { selectOptionNaturalSort } from 'lib/utils/naturalSort';

type FilterMeteoListInitialValues = Record<
  'dateFrom' | 'dateTo' | 'areaId' | 'okrugId' | 'rayonId' | 'areaTypeId',
  TFloraAreaFilterDTO['filters'][number]
>;

const initialValues: FilterMeteoListInitialValues = {
  dateFrom: {
    // @ts-ignore
    key: 'date',
    operation: 'GTE',
    value: '',
  },
  dateTo: {
    // @ts-ignore
    key: 'date',
    operation: 'LTE',
    value: '',
  },
  areaId: { key: 'areaId', operation: 'IN', value: null },
  okrugId: {
    key: 'okrugId',
    operation: 'IN',
    value: null,
  },
  rayonId: {
    key: 'rayonId',
    operation: 'IN',
    value: null,
  },
  areaTypeId: {
    key: 'areaTypeId',
    operation: 'IN',
    value: null,
  },
  periodId: {
    key: 'periodId',
    operation: 'EQ',
    value: null,
  },
  disabled: {
    key: 'deleted',
    operation: 'EQ',
    value: false,
  },
};

interface AreaFloraFilterProps {
  dictionaries: Partial<TLawnsDictionaries>;
  isLoading: boolean;
  onSubmit: (values: FilterMeteoListInitialValues) => void;
  filters: IFilterItem[];
  periods: IPeriod[] | null;
}

const initialValuesToFormik = createInitialValuesToFormik(initialValues);

function AreaLawnFilterComponent({ dictionaries,
  isLoading,
  onSubmit,
  children,
  periods,
  filters
}: React.PropsWithChildren<AreaFloraFilterProps>) {
  const { areas = [], rayons = [], okrugs = [], areaType = [] } = dictionaries;

  const [okrugIds, setOkrugIds] = useState<string[]>([]);
  const [rayonIds, setRayonIds] = useState<string[]>([]);

  const presetValues = useMemo<{ key: string, value: IFilterItem['value']; }[]>(() => {
    const preset: { key: string, value: IFilterItem['value']; }[] = [];
    const initialFilters = combineFilters(initialValues, filters);
    Object.keys(initialFilters).forEach((key) => {
      preset.push({ key: `${key}`, value: initialFilters[key].value });
    });
    return preset;
  }, [filters]);

  const filteredRayons = useMemo(() => {
    if (okrugIds && okrugIds.length > 0) {
      return rayons.filter((i) => okrugIds.includes(i.parent || ''));
    }
    return rayons;
  }, [rayons, okrugIds]);

  const filteredAreas = useMemo(() => {
    if (rayonIds && rayonIds.length > 0) {
      return areas.filter((i) => rayonIds.includes(i.parent || ''));
    } if (okrugIds && okrugIds.length > 0) {
      const rIds = filteredRayons.map((i) => i.value);
      return areas.filter((i) => rIds.includes(i.parent || ''));
    }
    return areas;
  }, [areas, rayonIds, okrugIds, filteredRayons]);

  return (
    <Filter<typeof initialValuesToFormik>
      savedPeriodId={filters.find((item) => item.key === 'periodId')?.value as string || ''}
      dateFieldFrom="dateFrom"
      dateFieldTo="dateTo"
      periods={periods}
      isLoading={isLoading}
      isDisabled={isLoading}
      onSubmit={(values) => {
        onSubmit(createValuesToRequest(initialValues, values));
      }}
      onValues={(values) => {
        // @ts-ignore
        setOkrugIds(values.okrugId || []);

        // @ts-ignore
        setRayonIds(values.rayonId || []);
      }}
      initialValues={initialValuesToFormik}
      mainFields={[
        {
          type: 'DATE',
          name: 'dateFrom',
          label: 'От',
          showTimeInput: false,
        },
        {
          type: 'DATE',
          name: 'dateTo',
          label: 'До',
          showTimeInput: false,
        },
        {
          type: 'MULTI_SELECT',
          name: 'areaTypeId',
          label: 'Тип территории',
          options: selectOptionNaturalSort(areaType),
        },
        {
          type: 'MULTI_SELECT',
          name: 'okrugId',
          label: 'Округ',
          options: selectOptionNaturalSort(okrugs),
          resetOnChange: ['rayonId', 'areaId'],

        },
        {
          type: 'MULTI_SELECT',
          name: 'rayonId',
          label: 'Район',
          options: selectOptionNaturalSort(filteredRayons),
          resetOnChange: ['areaId'],
        },
        {
          type: 'MULTI_SELECT',
          name: 'areaId',
          label: GreenPlantsAreaLabels.areaNumber,
          options: selectOptionNaturalSort(filteredAreas),
        },
      ]}
      presetValues={presetValues}
      validationSchema={() =>
        SchemaForCustomFilter([{ dateFromFieldName: 'dateFrom', dateToFieldName: 'dateTo' }])
      }
    >
      {children}
    </Filter>
  );
}

const AreaLawnsFilter = memo(AreaLawnFilterComponent);

export { AreaLawnsFilter };
