import { subsystemCodesMap } from 'constants/subsystemIdsMap';
import { ReportSmall } from 'features/WithSideBar/icons';
import { BurningRegistrationJournalPaths } from 'pages/burningRegistrationJournal/const';
import appLocale from 'constants/appLocale';

export const burningRegistrationJournalMenu = {
  id: BurningRegistrationJournalPaths.root,
  name: appLocale.subsystems.burningRegistrationJournal,
  url: BurningRegistrationJournalPaths.root,
  otherCode: subsystemCodesMap.burningRegistrationJournal,
  icon: <ReportSmall />, // TODO: change
};
