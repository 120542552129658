import { Modal } from 'components/common/Modals';
import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import { SubsoilItem } from 'hooks/subsoil/useSubsoilList';
import { useAvailability, useDisclosure, useHandleDeleteData } from 'hooks';
import { useList } from 'hooks/useList';
import appLocale from 'constants/appLocale';
import { SubsoilFilter } from './SubsoilFilter';
import { SubsoilHeader } from '../components';
import { UploadFileModalContent } from 'features/UploadFileDragAndDrop/UploadFileModalContent';
import { MaterialTable } from 'features/material-table/MaterialTableMini';
import React, { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { addFiltersToColumns } from 'lib/utils/tableFilter';
import { QualityItem } from 'hooks/geo/Quality/useQualityList';
import { Column } from '@material-table/core';
import subsoilColumns from 'pages/data/Subsoil/Subsoil/subsoilColumns';
import subsystemIdsMap from 'constants/subsystemIdsMap';
import { PropActions } from 'features/material-table/components/Actions';
import moment from 'moment';
import { useDictionary } from 'hooks/useDictionary';
import { DEFAULT_PAGE_SIZE_NEW_TABLE } from '../../../../constants/tables';
import { FullPageWrapper } from '../../../../components/table/FullPageWrapper';

export const Subsoil = () => {
  const { isReadOnly, isWrite, isExecute, isNoRights, allAvailable } = useAvailability(subsystemIdsMap.subsoilRoot);

  const history = useHistory();
  const { isOpen: isOpenLoad, onClose: onCloseLoad, onOpen: onOpenLoad } = useDisclosure();
  const { dictionaries } = useDictionary({ url: '/subsoil/v10/subsoils/dictionaries' });

  useUpdateBreadcrumbs([
    { breadcrumb: appLocale.common.data, path: '/data' },
    {
      breadcrumb: appLocale.subsystems.subsoil,
      path: '/data/subsoil',
    },
  ]);

  const {
    refetch,
    isLoading: isSubsoilListLoading,
    response,
    setFilters,
    toggleOrder,
    onPageChange,
    filterDTO,
    downloadAsFile,
    saveFilters,
    onSearchText,
    errorText,
    materialTableProps,
  } = useList<SubsoilItem>('/subsoil/v10/subsoils/search', {
    initialOrderFieldKey: '-licenceRegistrationDate',
    exportSubsystemName: 'subsoil',
    preventFirstFetch: true,
    saveFiltersState: true,
    defaultPageSize: DEFAULT_PAGE_SIZE_NEW_TABLE,
    resetDataOnNextRequest: true,
  });

  const { ModalComponent: DeleteModal, handleOpenModal } = useHandleDeleteData({ refetch });

  const columnsWithFilters = useMemo<Array<Column<QualityItem>>>(
    () => addFiltersToColumns({ filters: filterDTO.filters, setFilters, columns: subsoilColumns }),
    [filterDTO.filters, setFilters]
  );

  const initialActions = useMemo<PropActions<SubsoilItem[]>>(
    () => [
      {
        type: 'edit',
        onClick: (_e, item) => {
          if (!Array.isArray(item)) {
            history.push(`/data/subsoil/edit/row/${item.id}`, item);
          }
        },
      },
      {
        type: 'delete',
        onClick: (_e, item) => {
          if (!Array.isArray(item)) {
            handleOpenModal({ url: `/subsoil/v10/subsoils/${item.id}` });
          }
        },
      },
    ],
    [history, handleOpenModal]
  );
  // @ts-ignore
  const actions = useMemo<PropActions<any[]>>(() => {
    if (allAvailable || isNoRights || isWrite) {
      return initialActions;
    }
    if (!(allAvailable || isNoRights || isWrite)) {
      return initialActions.filter((action: any) => action.type !== 'delete');
    }
  }, [isWrite, initialActions, isNoRights, allAvailable]);

  const isRowHighLighted = useCallback((row: QualityItem) => {
    if (row.dataProcessing && moment(row.dataProcessing).isValid()) {
      return moment().diff(moment(row.dataProcessing), 'minutes') <= 10;
    }
    return false;
  }, []);

  return (
    <FullPageWrapper>
      <SubsoilHeader
        isWrite={isWrite}
        isReadonly={isReadOnly}
        isExecute={isExecute}
        allAvailable={allAvailable}
        isNoRights={isNoRights}
        onOpenLoad={onOpenLoad}
        url="/add"
      >
        <SubsoilFilter
          isLoading={isSubsoilListLoading}
          isDisabled={isSubsoilListLoading}
          dictionaries={dictionaries}
          onSubmit={(values) => {
            setFilters(Object.values(values));
          }}
          filters={filterDTO.filters}
        />
      </SubsoilHeader>
      <Modal isOpen={isOpenLoad} onClose={onCloseLoad} header="Загрузка данных из файла">
        <UploadFileModalContent
          createTypesPath={() => '/subsoil/v10/subsoils/load/types'}
          createUploadPath={() => '/subsoil/v10/subsoils/load/load'}
          types={[
            {
              name: 'Данные о лицензиях недропользования',
              value: 'subsoils',
            },
          ]}
          onClose={onCloseLoad}
          refetch={refetch}
        />
      </Modal>

      <MaterialTable
        isNotExecute={isExecute || isNoRights}
        toggleOrder={toggleOrder}
        onPageChange={onPageChange}
        filterDTO={filterDTO}
        data={response?.data}
        meta={response?.meta}
        columns={columnsWithFilters}
        actions={actions}
        downloadAsFile={downloadAsFile}
        saveFilters={saveFilters}
        setFilters={setFilters}
        options={{
          search: true,
          rowStyle: (rowData) => ({
            // @ts-ignore TODO: add typings
            backgroundColor: isRowHighLighted(rowData) ? 'rgb(185 255 194)' : '#fff',
          }),
        }}
        onSearch={onSearchText}
        isLoading={isSubsoilListLoading}
        errorText={errorText}
        {...materialTableProps}
        showTotalCount
      />
      {DeleteModal}
    </FullPageWrapper>
  );
};
