import { Box, Flex, Grid, GridItem, Heading, Stack } from '@chakra-ui/react';
import { Button, DefaultText, Input, Select, Textarea } from 'components/common';
import { Field, useFormikContext } from 'formik';
import { TDictionaryItemDictionary } from 'models/dictionaries';
import { IUploadMedia } from 'components/common/FileUploadDragAndDrop/types';
import { useDisclosure } from 'hooks/useDisclosure';
import { UploadObjectType } from 'api/services/media';
import { TaskAttachments } from './TaskAttachments';
import React, { useCallback, useMemo, useState } from 'react';
import { AddIcon, DeleteIcon, EditIcon } from 'components/icons';
import { useRequest } from 'hooks';
import { IPointsItem, ITaskFormer } from './types';

interface Props {
  index: number;
  pointTypes: TDictionaryItemDictionary[];
  isResearchTypeAir: boolean;
  odorSourceTypeId: TDictionaryItemDictionary[];
  noiseSourceTypeId: TDictionaryItemDictionary[];
  onMediaSave: (media: IUploadMedia[], idx: number) => void;
  uploadId?: string;
  media?: IUploadMedia[];
  remove: (index: number) => void;
  browserId: string;
  uid: string;
  point: IPointsItem;
  onMediaDelete: (index: number) => void;
}

export const ObservationPlace = ({
  index,
  pointTypes,
  isResearchTypeAir,
  noiseSourceTypeId,
  odorSourceTypeId,
  onMediaSave,
  uploadId,
  media = [],
  remove,
  browserId,
  uid,
  point,
  onMediaDelete,
}: Props) => {
  const [currentMedia, setCurrentMedia] = useState<IUploadMedia[]>(media);
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { post } = useRequest('/mapsclient/v10/sendCommand');
  const pointType = useMemo(
    () => pointTypes.find((type) => type.value === point.typeId)?.name,
    [pointTypes, point.typeId]
  );

  const { errors, touched } = useFormikContext<ITaskFormer>();

  const startEditing = useCallback(() => {
    post({
      browserId,
      command: 'startEditing',
      data: {
        uid,
        layerTemplateId: '1121430612',
      },
    });
  }, [browserId, post, uid]);

  const addFeature = useCallback(
    (id, name) => {
      post({
        browserId,
        command: 'addFeature',
        data: {
          uid,
          layerTemplateId: '1121430612',
          geometryType: 'Point',
          feature: {
            type: 'Feature',
            properties: {
              id,
              name,
            },
          },
        },
      });
    },
    [browserId, post, uid]
  );

  return (
    <Box bg="GhostWhite" padding="1rem" marginTop={4}>
      <Flex alignItems="center">
        <Heading as="h3" size="md">
          {`${index + 1}. Место наблюдения - точка измерения`}
        </Heading>
        <Button
          leftIcon={<DeleteIcon />}
          variant="ghost"
          color="PrimaryButton.500"
          size="sm"
          p={0}
          marginLeft={5}
          onClick={() => {
            onMediaDelete(index);
            remove(index);
          }}
        />
        <Button
          leftIcon={<EditIcon />}
          variant="ghost"
          color="PrimaryButton.500"
          size="sm"
          p={0}
          marginLeft="auto"
          onClick={() => {
            window.scrollTo(0, 0);
            startEditing();
          }}
          isDisabled={!(point.dotLongitude || point.dotLatitude)}
        >
          Редактировать координаты на карте
        </Button>
        <Button
          leftIcon={<AddIcon />}
          variant="ghost"
          color="PrimaryButton.500"
          size="sm"
          p={0}
          marginLeft={5}
          onClick={() => {
            window.scrollTo(0, 0);
            addFeature(point.id || point.temporaryId, point.pointNumber);
          }}
          isDisabled={!!point.dotLongitude || !!point.dotLatitude}
        >
          Добавить координаты на карте
        </Button>
      </Flex>
      <Grid templateColumns="repeat(4, 1fr)" gap={10} marginTop={5}>
        <GridItem>
          <Field
            as={Input}
            name={`points[${index}].pointNumber`}
            label="№ точки"
            error={
              errors.points &&
              touched.points &&
              touched?.points[index]?.pointNumber &&
              // @ts-ignore
              errors?.points[index]?.pointNumber
            }
          />
        </GridItem>
        <Flex alignItems="center">
          <DefaultText fontWeight="bold">{`Тип выезда: ${pointType || ''}`}</DefaultText>
        </Flex>
        <GridItem>
          <Field
            as={Select}
            name={isResearchTypeAir ? `points[${index}].odorSourceTypeId` : `points[${index}].noiseSourceTypeId`}
            label="Тип источника"
            options={isResearchTypeAir ? odorSourceTypeId : noiseSourceTypeId}
          />
        </GridItem>
        <GridItem>
          <Field
            as={Input}
            name={`points[${index}].expectedTime`}
            label="Ориентировочное время выполнения (мин.)"
            error={
              errors.points &&
              touched.points &&
              touched?.points[index]?.expectedTime &&
              // @ts-ignore
              errors?.points[index]?.expectedTime
            }
          />
        </GridItem>
      </Grid>

      <Grid templateColumns="2fr 1fr 1fr" gap={10} marginTop={5}>
        <GridItem>
          <Field as={Input} name={`points[${index}].address`} label="Адрес" />
        </GridItem>
        <GridItem>
          <Field
            as={Input}
            name={`points[${index}].dotLatitude`}
            label="Широта"
            error={
              errors.points &&
              touched.points &&
              touched?.points[index]?.dotLatitude &&
              // @ts-ignore
              errors?.points[index]?.dotLatitude
            }
          />
        </GridItem>
        <GridItem>
          <Field
            as={Input}
            name={`points[${index}].dotLongitude`}
            label="Долгота"
            error={
              errors.points &&
              touched.points &&
              touched?.points[index]?.dotLongitude &&
              // @ts-ignore
              errors?.points[index]?.dotLongitude
            }
          />
        </GridItem>
      </Grid>

      {/* <Grid templateColumns="2fr 2fr 1fr" marginTop={5} gap={10} alignItems="center">
        <GridItem>
          <Field as={Input} label="Ссылка на объект НАБЛЮДЕНИЯ на карте" name={`points[${index}].observationObjectUrl`} />
        </GridItem>
        <GridItem>
          <Button>Смотреть на карте</Button>
        </GridItem>
      </Grid> */}

      <Stack direction="column" spacing={5} marginTop={5}>
        <Field as={Textarea} label="Постановка задачи" name={`points[${index}].comment`} />
      </Stack>
      <Button variant="ghost" color="PrimaryButton.500" size="sm" p={0} marginTop={5} onClick={() => onOpen()}>
        Вложения
      </Button>
      <TaskAttachments
        onClose={onClose}
        isOpen={isOpen}
        objectType={UploadObjectType.OBSERVATION_POINT}
        media={currentMedia}
        setMedia={setCurrentMedia}
        uploadItemId={uploadId}
        handleSubmit={() => {
          onMediaSave(currentMedia, index);
        }}
      />
    </Box>
  );
};
