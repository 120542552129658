import appLocale from 'constants/appLocale';
import { BurningRegistrationJournalItem } from 'pages/burningRegistrationJournal/types';
import { ColumnWithFilter } from 'lib/utils/tableFilter';
import { TableFilterTypeEnum } from 'components/table/filters/TableFilter';
import { DefaultTableCellSize } from 'components/table/DefaultTableCellSize';
import { createLocalDateDDMMYYYYWithTime } from 'lib/create-date';

export const BurningRegistrationJournalRootPath = '/burning-registration-journal';
export const BurningRegistrationJournalApiRoot = '/appeal/v10/camera-fire';

export const BurningRegistrationJournalPaths = {
  root: BurningRegistrationJournalRootPath,
  add: `${BurningRegistrationJournalRootPath}/add`,
  edit: (id: string) => `${BurningRegistrationJournalRootPath}/edit/${id}`
};

export const burningRegistrationJournalBreadcrumbs = [
  {
    breadcrumb: appLocale.subsystems.burningRegistrationJournal,
    path: BurningRegistrationJournalRootPath,
  },
];

export const burningRegistrationJournalColumns: ColumnWithFilter<BurningRegistrationJournalItem>[] = [
  {
    title: appLocale.burningRegistrationJournal.incidentDateTime,
    field: 'incidentDateTime',
    render: (row) => createLocalDateDDMMYYYYWithTime(row.incidentDateTime),
  },
  {
    title: appLocale.burningRegistrationJournal.cameraId,
    field: 'cameraNumber',
    filterType: TableFilterTypeEnum.number,
  },
  {
    title: appLocale.burningRegistrationJournal.address,
    field: 'address',
    filterType: TableFilterTypeEnum.string,
  },
  {
    title: appLocale.burningRegistrationJournal.admAreaId,
    field: 'admAreaName',
  },
  {
    title: appLocale.burningRegistrationJournal.districtId,
    field: 'districtName',
  },
  {
    title: appLocale.burningRegistrationJournal.identifyInfoId,
    field: 'identifyInfo',
    filterType: TableFilterTypeEnum.string,
  },
  {
    title: appLocale.burningRegistrationJournal.actions,
    field: 'actions',
    filterType: TableFilterTypeEnum.string,
    render: (row) => <DefaultTableCellSize>{row.actions}</DefaultTableCellSize>,
  },
  {
    title: appLocale.burningRegistrationJournal.actionsOther,
    field: 'actionsOther',
    filterType: TableFilterTypeEnum.string,
    render: (row) => <DefaultTableCellSize>{row.actionsOther}</DefaultTableCellSize>,
  },
];
