import React, { createElement } from 'react';
import { Grid, GridProps } from '@chakra-ui/react';
import { ContentContainer } from './Container';

export const StyledPageHeader = (props: GridProps) =>
  createElement(Grid, {
    padding: '12.5px',
    backgroundColor: 'panel.background.default',
    borderBottom: '1px solid',
    borderBottomColor: 'panel.border.default',
    ...props,
  });

export const PageHeader: React.FC<GridProps> = ({ children, ...props }) =>
  React.createElement(
    StyledPageHeader,
    props,
    React.createElement(
      ContentContainer,
      {
        margin: 0,
        padding: 0,
        display: 'grid',
        gridRowGap: '20px',
      },
      children,
    ),
  );
