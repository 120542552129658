import { createLocalDateDDMMYYYY } from 'lib/create-date';
import { useHistory } from 'react-router';
import { DisplayUserItem, UserItem } from '../models/UserItem';
import { tableIcons } from '../components/CustomTable';
import { ChangeUserPasswordDialog } from './forms/ChangeUserPasswordDialog';
import React, { useRef } from 'react';
import { validateEmail } from 'lib/utils/validators';
import { ResetUserPasswordDialog } from './forms/ResetUserPasswordDialog';
import { useHandleDeleteData, useList, useRequest } from 'hooks';
import { baseAdminUserUrl } from './consts';
import { DEFAULT_PAGE_SIZE_NEW_TABLE } from '../../../constants/tables';
import { MaterialTable } from '../../../features/material-table/MaterialTableMini';
import { Column } from '@material-table/core';

export const UserList: React.FC = () => {
  const history = useHistory();

  const {
    isLoading,
    response,
    toggleOrder,
    setFilters,
    onPageChange,
    filterDTO,
    onSearchText,
    selectedIndexes,
    onSelectionChange,
    refetch,
  } = useList<DisplayUserItem, keyof DisplayUserItem>(baseAdminUserUrl, {
    initialOrderFieldKey: '-code',
    defaultPageSize: DEFAULT_PAGE_SIZE_NEW_TABLE,
    resetDataOnNextRequest: true,
    fetchMethod: 'GET',
    initialDeleted: null,
  });

  const { post: uneraseUser, isLoading: isBusy } = useRequest(baseAdminUserUrl);

  const columns: Column<DisplayUserItem>[] = [
    {
      title: 'Логин',
      field: 'code',
    },
    {
      title: 'Имя',
      field: 'name',
    },
    {
      title: 'Фамилия',
      field: 'surName',
    },
    {
      title: 'Отчество',
      field: 'middleName',
    },
    {
      title: 'Дата создания',
      field: 'dateAccountCreation',
      render: (row: UserItem) => (row.dateAccountCreation ? createLocalDateDDMMYYYY(row.dateAccountCreation) : null),
      defaultSort: 'desc',
    },
    {
      title: 'Дата деактивации',
      field: 'dateAccountDeactivation',
      render: (row: UserItem) =>
        (row.dateAccountDeactivation ? createLocalDateDDMMYYYY(row.dateAccountDeactivation) : null),
    },
    {
      title: 'Отдел',
      field: 'departmentName',
    },
    {
      title: 'Статус',
      field: 'statusName',
    },
    {
      title: 'Тип записи',
      field: 'typeName',
    },
    {
      title: 'Email',
      field: 'email',
    },
    {
      title: 'Телефон',
      field: 'phone',
    },
    {
      title: 'Удален',
      field: 'deleted',
      render: (rowdata: UserItem) => <input type="checkbox" checked={rowdata.deleted} readOnly />,
    },
  ];

  const passwordChangerRef = useRef<any>();
  const passwordResetRef = useRef<any>();

  const resetPassword = (user: DisplayUserItem) => {
    if (validateEmail(user.email, {})) {
      passwordResetRef?.current?.resetPassword(user);
    }
  };

  const { ModalComponent: ConfirmDeleteModal, handleOpenModal } = useHandleDeleteData({ refetch });

  return (
    <>
      <ChangeUserPasswordDialog ref={passwordChangerRef} />
      <ResetUserPasswordDialog ref={passwordResetRef} />

      <MaterialTable
        data={response?.data}
        meta={response?.meta}
        toggleOrder={toggleOrder}
        filterDTO={filterDTO}
        onPageChange={onPageChange}
        columns={columns}
        actions={[
          {
            type: 'edit',
            onClick: (_, row) => {
              if (!Array.isArray(row) && row.id) {
                history.push(`/admin/user/edit/${row.id}`);
              }
            },
          },
          {
            position: 'row',
            action: (item) => ({
              icon: tableIcons.Reload,
              tooltip: 'Снять пометку на удаление',
              onClick: () => {
                if (item?.id == null) return;
                const { id } = item;
                void uneraseUser(undefined, {
                  url: `${baseAdminUserUrl}/${id}/unerase`,
                }).then(() => refetch());
              },
              hidden: !item.deleted,
            })
          },
          {
            position: 'row',
            action: (item) => ({
              icon: tableIcons.Search,
              tooltip: 'Сбросить пароль',
              onClick: () => resetPassword(item),
            })
          },
          {
            position: 'row',
            action: (item) => ({
              icon: tableIcons.Delete,
              tooltip: 'Удалить',
              onClick: () => {
                handleOpenModal({
                  url: `${baseAdminUserUrl}/${item.id}`,
                });
              },
              hidden: item.deleted,
            })
          },
        ]}
        isLoading={isLoading || isBusy}
        setFilters={setFilters}
        options={{ selection: false, search: true }}
        onSearch={onSearchText}
        onSelectionChange={onSelectionChange}
        selectedIndexes={selectedIndexes}
        showTotalCount
      />

      {ConfirmDeleteModal}
    </>
  );
};
