import React, { memo } from 'react';
import { Button, SubsystemHeader } from 'components/common';

import { useHistory } from 'react-router-dom';
import { HStack } from '@chakra-ui/react';
import { GeoecologicalRootPath } from 'pages/data/GeoecologicalProcesses/consts';
import appLocale from 'constants/appLocale';

interface DangerouslyGeologicalProcessesHeaderProps {
  active: string;
  onOpenLoad?: () => void;
}

export const dangerouslyGeologicalProcessesHeaderTabs = [
  {
    label: 'Обследование',
    value: 'dangerprocess',
  },
  {
    label: 'Реперы и марки',
    value: 'reper',
  },
  {
    label: 'Инклинометры',
    value: 'inclinometer',
  },
];

export const DangerouslyGeologicalProcessesProtocolHeader: React.FC<DangerouslyGeologicalProcessesHeaderProps> = memo(
  ({ onOpenLoad, active, children }) => {
    const history = useHistory();

    return (
      <SubsystemHeader
        onUploadClick={onOpenLoad}
        title={`${appLocale.common.dataProtocol} (ОГП)`}
        key={active}
        navigationTabs={(
          <HStack spacing="0px">
            {dangerouslyGeologicalProcessesHeaderTabs.map(({ label, value }) => (
              <Button
                key={value}
                colorScheme="blue"
                backgroundColor={value === active ? 'PrimaryButton.500' : 'white'}
                color={value === active ? 'white' : 'PrimaryButton.500'}
                variant={value === active ? 'solid' : 'outline'}
                onClick={() => history.push(`${GeoecologicalRootPath}/monitoring/protocol/danger/${value}`)}
                height="30px"
              >
                {label}
              </Button>
            ))}
          </HStack>
        )}
        filtersComponent={children}
      />
    );
  }
);
