import { DateInput, FormWrapper, Input, Select } from 'components/common';
import { Box, Grid, Heading } from '@chakra-ui/react';
import React, { useMemo } from 'react';
import { Field, useFormikContext } from 'formik';
import { createDateFromResponse, createUTCTime } from 'lib/create-date';
import { IReportProtocolForm } from 'pages/analytics/Raids/types';
import { getSelectOptionsFromDictionaryByName } from 'features/get-select-options-from-dictionary';
import { TDictionariesArray } from 'models';
import { ANALYTICS_STATUS } from 'pages/analytics/forms/const';

interface IProps {
  dictionaries: {
    data: TDictionariesArray;
  } | null;
  isEditMode?: boolean;
}

export const AnalyticsPelNoiseGeneralInformationTab = ({ dictionaries, isEditMode }: IProps) => {
  const { values, handleChange, setFieldValue, initialValues } = useFormikContext<IReportProtocolForm>();

  const organizations = useMemo(
    () =>
      getSelectOptionsFromDictionaryByName(dictionaries?.data, 'organizations', false, [
        initialValues.contractorId,
        initialValues.customerId,
      ]),
    [dictionaries?.data, initialValues.contractorId, initialValues.customerId],
  );

  // while status is hidden assume status as draft
  let fieldsIsDisabled = values.status === ANALYTICS_STATUS.APPROVED && !!isEditMode;
  fieldsIsDisabled = false;

  return (
    <Box flexDirection="column">
      <FormWrapper>
        <Grid marginTop="30px" gap="15px">
          <Heading variant="h2">Данные обследования</Heading>

          <Field
            as={Input}
            name="protocolNumber"
            label="Номер протокола"
            value={values.protocolNumber}
            onChange={handleChange}
            isDisabled={fieldsIsDisabled}
          />

          <DateInput
            label="Дата протокола"
            name="protocolDate"
            value={values.protocolDate ? createDateFromResponse(values.protocolDate) : null}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setFieldValue('protocolDate', createUTCTime(e.target.value))
            }
            isDisabled={fieldsIsDisabled}
          />

          <Field as={Select} name="customerId" options={organizations} label="Заказчик" isDisabled={fieldsIsDisabled} />

          <Field
            as={Input}
            name="address"
            label="Место проведения измерений"
            value={values.address}
            onChange={handleChange}
            isDisabled={fieldsIsDisabled}
          />

          <Field
            as={Input}
            name="purpose"
            label="Цель проведения измерений"
            value={values.purpose}
            onChange={handleChange}
            isDisabled={fieldsIsDisabled}
          />

          <Field
            as={Input}
            name="methodology"
            label="Методика измерений"
            value={values.methodology}
            onChange={handleChange}
            isDisabled={fieldsIsDisabled}
          />
        </Grid>
      </FormWrapper>
    </Box>
  );
};
