import { FormHeader } from 'components/common';
import { ERROR_MESSAGES, SUCCESS_MESSAGES } from 'constants/messages';
import { createTouchedErrors } from 'features/create-touched-errors';
import { FormikProvider, useFormik } from 'formik';
import { useNotifications } from 'hooks/useNotifications';
import { useRequest } from 'hooks/useRequest';
import { RightSchema } from 'models/schemas/admin/right';
import { useHistory } from 'react-router-dom';
import { baseAdminRightUrl } from '../consts';
import FieldsForm from './FieldsForm';

const initialValues = {
  code: '',
  name: '',
  description: '',
  action: '',
  objectId: null,
  objectType: null,
  subsystemId: null,
  deleted: false,
};

export const AddRightForm = () => {
  const history = useHistory();
  const { successNotify, errorNotify } = useNotifications();

  const successHandler = () => {
    successNotify({
      key: 'file/create/success',
      message: SUCCESS_MESSAGES.CREATE,
    });
    history.push('/admin/right');
  };
  const errorHandler = (error: any) => {
    errorNotify({
      key: 'file/create/error',
      message: `${ERROR_MESSAGES.CREATE}. ${typeof error === 'string' ? error : ''}`,
    });
  };

  const { post: createRight, isLoading } = useRequest(baseAdminRightUrl, successHandler, errorHandler);

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    initialValues,
    validationSchema: RightSchema,
    onSubmit: (values) => createRight(values),
  });

  return (
    <FormikProvider
      value={{
        ...formik,
        errors: createTouchedErrors(formik.errors, formik.touched),
      }}
    >
      <FormHeader url="/admin/right" header="Добавление привилегии" />
      <FieldsForm onCancel={() => history.push('/admin/right')} isLoading={isLoading} />
    </FormikProvider>
  );
};
