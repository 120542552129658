import { cloneDeep, map } from 'lodash';
import { ChangeEvent, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { MenuDropdowns } from 'features/WithSideBar/components/MenuDropdowns';
import { menu } from 'features/WithSideBar/consts';
import { Flex } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';
import { RenderTree } from 'features/WithSideBar/types';
import { UserInfoContext } from '../../AuthProvider';
import { LoaderItem } from 'components/common';
import { useFilteredMenu } from 'hooks/useFilteredMenu';

interface Props {
  isOpen: boolean;
}

export const LeftSideMenu = ({ isOpen }: Props) => {
  const { filteredMenu } = useFilteredMenu(menu);
  const history = useHistory();
  const location = useLocation();

  const { isSubsystemLoading, isRightLoading } = useContext(UserInfoContext);

  const [expanded, setExpanded] = useState<string[]>([]);
  const [selectedNodeId, setSelectedNodeId] = useState('');

  const flattenRenderTree = useCallback((tree: RenderTree, idsArray: string[] = []): string[][] => {
    if (tree.children) {
      return tree.children
        .map((node) => flattenRenderTree(node, [...idsArray, tree.id]))
        .reduce((prev, curr, index, arr) => arr.flat(1));
    }
    return [[...idsArray, tree.id]];
  }, []);

  const flatRoutes = useMemo(
    () =>
      cloneDeep(menu)
        .map((node) => flattenRenderTree(node))
        .flat(1),
    [flattenRenderTree]
  );

  const searchKey = useCallback(
    (pathname: string) => {
      const filter = (arr: string[][], index = 0): string[] => {
        const found: string[][] = [];

        arr.forEach((b) => {
          if (pathname.startsWith(b[index])) {
            found.push(b);
          }
        });

        if (found.length > 1) {
          return filter(found, index + 1);
        }
        return index === 0 ? found[0] || [] : found[0] || arr[0];
      };

      const path = filter(flatRoutes);

      const selected = path.filter((x) => pathname.startsWith(x));
      const selectedId = selected[selected.length - 1];

      setExpanded(Array.from(new Set([...expanded, ...path])));
      setSelectedNodeId(selectedId);
    },
    [expanded, flatRoutes]
  );

  useEffect(() => {
    if (location.pathname !== '/') {
      searchKey(location.pathname);
    } else {
      setSelectedNodeId('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const handleToggle = useCallback((_: ChangeEvent<{}>, nodeIds: string[]) => {
    setExpanded(nodeIds);
  }, []);

  const handleHistory = useCallback(
    (url: string | undefined, isExternal?: boolean) => () => {
      if (isExternal && url) {
        window.open(url, '_blank');
      } else if (url && !(url === history.location.pathname)) {
        history.push(url);
      }
    },
    [history]
  );

  if (isSubsystemLoading || isRightLoading) {
    return <LoaderItem />;
  }

  return (
    <Flex flexDirection="column" width="100%">
      {map(filteredMenu, (tree) => (
        <Flex key={tree.id} marginTop="4px">
          <MenuDropdowns
            tree={tree}
            expanded={expanded}
            selected={selectedNodeId}
            handleToggle={handleToggle}
            handleHistory={handleHistory}
            isOpen={isOpen}
          />
        </Flex>
      ))}
    </Flex>
  );
};
