import appLocale from 'constants/appLocale';
import { Icon } from '@chakra-ui/react';
import { MapSharp } from '@material-ui/icons';
import { subsystemCodesMap } from 'constants/subsystemIdsMap';

export const cartographyMenu = {
  name: appLocale.subsystems.cartographyShort,
  id: '/cartography',
  url: '/cartography',
  icon: <Icon as={MapSharp} style={{ width: '18px' }} />, // TODO: change icon
  otherCode: subsystemCodesMap.cartography,
};
