import { useRequest } from 'hooks/useRequest';
import { GreenPlantsApiRootPath } from 'pages/routes/GreenPlants';
import { useEffect } from 'react';
import { TBaseGreenPlantsFormProperties } from './types';
import { TDictionariesArray } from 'models';

type TArea = {
  organization: string | null;
  organizationName: string | null;
  address: string | null;
  code: string | null;
  dateStart: string | null;
  district: string | null;
  id: string | null;
  landmark: string | null;
  location: string | null;
  number: number | null;
  region: string | null;
  square: number | null;
  type: string | null;
  dictionaries: { data: TDictionariesArray<'lawns'> } | null;
};

const useAreaById = (areaId: TBaseGreenPlantsFormProperties<string>['areaId']) => {
  const { get, result, isLoading, error } = useRequest<TArea>(`${GreenPlantsApiRootPath}/flora/areas/${areaId}`);

  useEffect(() => {
    if (areaId) {
      get();
    }
  }, [areaId, get]);

  return { result, isLoading, error };
};

export type { TArea };

export { useAreaById };
