import { Divider, Flex, Text } from '@chakra-ui/react';

interface ITimeBoundaries {
  minDate: string | null;
  maxDate: string | null;
}
export const TimeBoundaries = ({ minDate, maxDate }: ITimeBoundaries) => {
  const hasTime = !!minDate && !!maxDate;
  return (
    <div>
      {hasTime && (
        <>
          <Flex justifyContent="space-between" py={2}>
            <Text fontSize="lg">{minDate}</Text>
            <Text fontSize="lg">{maxDate}</Text>
          </Flex>
          <Divider />
        </>
      )}
    </div>
  );
};
