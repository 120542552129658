import { DateInput, FormWrapper, Input, Select } from 'components/common';
import { Box, Grid } from '@chakra-ui/react';
import React, { useCallback, useMemo, useState } from 'react';
import { Field, useFormikContext } from 'formik';
import { createDateFromResponse, createDateToRequestWithTimezone } from 'lib/create-date';
import { IBottomSoilDTO, WaterObjectResponse } from 'pages/data/Water/BottomSoil/consts/types';
import appLocale from 'constants/appLocale';
import { TDictionariesArray } from 'models';
import { getSelectOptionsFromDictionaryByName } from 'features/get-select-options-from-dictionary';
import { useRequest } from 'hooks';
import { BottomSoilApiPaths } from 'pages/data/Water/BottomSoil/consts/apiPaths';
import { getFilteredOptionsByPrivileges } from 'features/getFilteredOptionsByPrivileges';

interface CommonTabProps {
  dictionaries: {
    data: TDictionariesArray;
  } | null;
  isEditMode?: boolean;
  editedObject?: IBottomSoilDTO;
}

export const CommonTab = ({ isEditMode, dictionaries, editedObject }: CommonTabProps): JSX.Element => {
  const { setFieldValue, values, errors, setFieldTouched } = useFormikContext<IBottomSoilDTO>();
  const [sourcesIds, setSourcesIds] = useState<string[]>([]);

  const waterObjects = useMemo(
    () => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'waterObjects'),
    [dictionaries],
  );

  const filteredWaterObjects = useMemo(
    () => getFilteredOptionsByPrivileges(waterObjects, 'isEditable', 'value', values.waterObjectId),
    [values.waterObjectId, waterObjects],
  );

  const onWaterObjectsSuccess = useCallback((response: WaterObjectResponse[] | null) => {
    if (response) {
      setSourcesIds(response.map((source) => source.id));
    }
  }, []);

  const onSourceDataSuccess = useCallback(
    (response: WaterObjectResponse | null) => {
      if (response) {
        setFieldValue('latitude', response.latitude || '');
        setFieldValue('longitude', response.longitude || '');
        setFieldValue('district', response.district || '');
        setFieldValue('region', response.region || '');
      }
    },
    [setFieldValue],
  );

  const { get: getWaterObjectSources } = useRequest<WaterObjectResponse[]>('', onWaterObjectsSuccess);
  const { get: getSourceData } = useRequest<WaterObjectResponse>('', onSourceDataSuccess);

  const waterObjectOnChange = useCallback(
    async (e) => {
      setFieldValue('waterObject', e.target.value);
      setFieldValue('sourceId', null);

      if (e.target.value) {
        void getWaterObjectSources({
          url: BottomSoilApiPaths.getWaterObjectSources(e.target.value),
        });
      } else {
        setSourcesIds([]);
      }
      setTimeout(() => {
        setFieldTouched('waterObject');
        setFieldTouched('sourceId');
      });
    },
    [getWaterObjectSources, setFieldTouched, setFieldValue],
  );

  const sources = useMemo(() => {
    const sources = getSelectOptionsFromDictionaryByName(dictionaries?.data, 'sources');
    const filteredSources = getFilteredOptionsByPrivileges(sources, 'isEditable', 'value', values.sourceId);
    return isEditMode ? filteredSources : filteredSources.filter((source) => sourcesIds.includes(source.value));
  }, [dictionaries?.data, isEditMode, sourcesIds, values.sourceId]);

  const organizations = useMemo(
    () =>
      getSelectOptionsFromDictionaryByName(dictionaries?.data, 'organizations', false, [
        editedObject?.organizationId,
        editedObject?.consumerId,
      ]),
    [dictionaries?.data, editedObject?.consumerId, editedObject?.organizationId],
  );

  const onSourceSelect = useCallback(
    async (event) => {
      const { value } = event.target;
      setFieldValue('sourceId', value);

      if (value) {
        void getSourceData({
          url: BottomSoilApiPaths.getSourceData(value),
        });
      } else {
        setFieldValue('latitude', '');
        setFieldValue('longitude', '');
        setFieldValue('district', '');
        setFieldValue('region', '');
      }
    },
    [getSourceData, setFieldValue],
  );

  return (
    <Box flexDirection="column">
      <FormWrapper>
        <Grid marginTop="30px" gap="15px">
          <Field
            as={Input}
            label={appLocale.bottomSoil.protocolNumber}
            name="protocolNumber"
            value={values.protocolNumber}
            disabled={isEditMode}
            error={errors?.protocolNumber}
          />
          <Field
            as={DateInput}
            name="sampleDate"
            label={appLocale.bottomSoil.sampleDate}
            value={createDateFromResponse(values.sampleDate)}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFieldTouched(e.target.name);
              setFieldValue('sampleDate', createDateToRequestWithTimezone(e.target.value), true);
            }}
            error={errors?.sampleDate}
            onBlur={(e: any) => {
              setFieldTouched(e.target.name);
            }}
            showTimeInput
          />

          <Field
            as={Select}
            name="waterObject"
            options={filteredWaterObjects}
            label={appLocale.bottomSoil.waterObject}
            onChange={waterObjectOnChange}
            isDisabled={isEditMode}
            error={errors?.waterObject}
          />

          <Field
            as={Select}
            name="sourceId"
            options={sources}
            label={appLocale.bottomSoil.source}
            isDisabled={isEditMode || !values.waterObject}
            onChange={onSourceSelect}
            error={errors?.sourceId}
          />

          <Field
            as={Input}
            label={appLocale.bottomSoil.region}
            name="region"
            value={values.region}
            disabled
            error={errors?.region}
          />

          <Field
            as={Input}
            label={appLocale.bottomSoil.district}
            name="district"
            value={values.district}
            disabled
            error={errors?.district}
          />

          <Field
            as={Select}
            name="consumerId"
            options={organizations}
            label={appLocale.bottomSoil.consumer}
            isDisabled={isEditMode}
            error={errors?.consumerId}
          />

          <Field
            as={Select}
            name="organizationId"
            options={organizations}
            label={appLocale.bottomSoil.organizations}
            isDisabled={isEditMode}
            error={errors?.organizationId}
          />
        </Grid>
      </FormWrapper>
    </Box>
  );
};
