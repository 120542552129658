import { ERROR_MESSAGES, SUCCESS_MESSAGES } from 'constants/messages';
import { createTouchedErrors } from 'features/create-touched-errors';
import { FormikProvider, useFormik } from 'formik';
import { useNotifications } from 'hooks/useNotifications';
import { useRequest } from 'hooks/useRequest';
import { useLocation, useParams } from 'react-router-dom';
import { initialEmptyValues } from './initialEmptyValues';
import AnalyticsSnowFieldsForm from 'pages/analytics/forms/Snow/AnalyticsSnowFieldsForm';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { IUploadMedia } from 'components/common/FileUploadDragAndDrop/types';
import { updateFiles } from 'api/services/media';
import { useObjectType } from 'hooks/useObjectType';
import { SnowDeicingItem } from 'pages/data/PGR/Snow/useSnowDeicingList';
import { AnalyticsSnowSchema } from 'models/schemas/analytics/analyticsSnowSchema';
import { useGoBackWithFallback } from 'lib/utils/goBackWithFallback';
import { useDictionary } from 'hooks/useDictionary';

export const AnalyticsEditDeicingSnowReport = () => {
  const { id } = useParams<{ id: string }>();
  const { isDictionariesLoading, dictionaries } = useDictionary({ url: '/deicing/v10/deicing/snow/dictionaries' });
  const { backWithFallback } = useGoBackWithFallback();
  const { successNotify, errorNotify } = useNotifications();
  const location = useLocation();
  const [media, setMedia] = useState<IUploadMedia[]>([]);
  const [editedObject, setEditObject] = useState<SnowDeicingItem>();
  const objectType = useObjectType(dictionaries);

  const onItemLoad = useCallback((response: SnowDeicingItem | null) => {
    if (response) {
      setEditObject(response);
    }
  }, []);

  const { get, isLoading: isItemLoading } = useRequest<SnowDeicingItem>(
    `/deicing/v10/deicing/snow/${id}?laboratory=true`,
    onItemLoad
  );

  useEffect(() => {
    if (location.state) {
      setEditObject(location.state);
    } else {
      void get();
    }
  }, [get, location.state]);

  const initialValues = useMemo(
    () => ({
      ...initialEmptyValues,
      ...(editedObject || {}),
    }),
    [editedObject]
  );

  const onSuccess = () => {
    successNotify({
      key: 'file/edit/success',
      message: SUCCESS_MESSAGES.EDIT,
    });

    updateFiles(id, objectType, media)
      .then(() => {
        backWithFallback('/analytics');
      })
      .catch((error: Error) => {
        onError(error.message);
      });
  };

  const onError = useCallback(
    (message?: string | null) => {
      errorNotify({
        key: message || 'file/add/error',
        message: message || ERROR_MESSAGES.CREATE,
      });
    },
    [errorNotify]
  );

  const { put } = useRequest<SnowDeicingItem, any>('/deicing/v10/deicing/snow', onSuccess, onError);

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    initialValues,
    onSubmit: (values) => put(values),
    validationSchema: AnalyticsSnowSchema,
  });

  return (
    <FormikProvider
      value={{
        ...formik,
        errors: createTouchedErrors(formik.errors, formik.touched),
      }}
    >
      <AnalyticsSnowFieldsForm
        isEditMode
        dictionaries={dictionaries}
        onCancel={() => {
          backWithFallback('/analytics');
        }}
        isLoading={isDictionariesLoading || isItemLoading}
        uploadItemId={id}
        onMediaChange={setMedia}
        media={media}
      />
    </FormikProvider>
  );
};
