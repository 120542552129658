import { Filter } from 'features/Filter';
import { createInitialValuesToFormik, createValuesToRequest } from 'features/Filter/utils';
import React, { useMemo } from 'react';
import { IFilterItem, TFilterDTO } from 'models';
import { SelectOption } from 'components/common';
import combineFilters from 'lib/utils/combineFilters';

type TNSIReagentsGroundFilterTypes =
  | 'parentAreaId'
  | 'greenAreaTypeId'
  | 'okrugId'
  | 'rayonId'
  | 'address'
  | 'deleted'
  | 'status'
  | 'number'
  | 'greenPlantLifeBreedId'
  | 'greenPlantLifeFormId';
type TNSIReagentsGroundFilterDTO = TFilterDTO<TNSIReagentsGroundFilterTypes>;
type FilterInitialValues = Record<TNSIReagentsGroundFilterTypes, TNSIReagentsGroundFilterDTO['filters'][number]>;

const initialValues: FilterInitialValues = {
  number: {
    key: 'number',
    operation: 'EQ',
    value: '',
  },
  greenPlantLifeBreedId: {
    key: 'greenPlantLifeBreedId',
    operation: 'EQ',
    value: null,
  },
  greenPlantLifeFormId: {
    key: 'greenPlantLifeFormId',
    operation: 'EQ',
    value: null,
  },
  parentAreaId: {
    key: 'parentAreaId',
    operation: 'EQ',
    value: null,
  },
  greenAreaTypeId: {
    key: 'greenAreaTypeId',
    operation: 'EQ',
    value: null,
  },
  okrugId: {
    key: 'okrugId',
    operation: 'EQ',
    value: null,
  },
  rayonId: {
    key: 'rayonId',
    operation: 'EQ',
    value: null,
  },
  address: {
    key: 'address',
    operation: 'CONTAINS',
    value: '',
  },
  status: {
    key: 'status',
    operation: 'EQ',
    value: null,
  },
  deleted: {
    key: 'deleted',
    operation: 'EQ',
    value: false,
  },
};

interface Props {
  isLoading: boolean;
  onSubmit: (values: FilterInitialValues) => void;
  okrugs?: SelectOption[];
  rayons?: SelectOption[];
  statuses?: SelectOption[];
  areas?: SelectOption[];
  areaTypes?: SelectOption[];
  lifeForms?: SelectOption[];
  lifeBreeds?: SelectOption[];
  filters: TFilterDTO['filters'];
}

const initialValuesToFormik = createInitialValuesToFormik(initialValues);

function NSIReagentsTreesFilters({
  isLoading,
  onSubmit,
  children,
  okrugs = [],
  rayons = [],
  statuses = [],
  areas = [],
  areaTypes = [],
  lifeForms = [],
  lifeBreeds = [],
  filters,
}: React.PropsWithChildren<Props>) {
  const presetValues = useMemo<{ key: string; value: IFilterItem['value'] }[]>(() => {
    const preset: { key: string; value: IFilterItem['value'] }[] = [];
    const initialFilters = combineFilters(initialValues, filters);
    Object.keys(initialFilters).forEach((key) => {
      preset.push({ key: `${key}`, value: initialFilters[key].value });
    });
    return preset;
  }, [filters]);

  return (
    <Filter<typeof initialValuesToFormik>
      isLoading={isLoading}
      isDisabled={isLoading}
      onSubmit={(values) => {
        onSubmit(createValuesToRequest(initialValues, values));
      }}
      initialValues={initialValuesToFormik}
      mainFields={[
        {
          type: 'SELECT',
          name: 'parentAreaId',
          label: 'Номер площадки',
          options: areas,
        },
        {
          type: 'SELECT',
          name: 'greenAreaTypeId',
          label: 'Тип территории',
          options: areaTypes,
        },
        {
          type: 'TEXT',
          name: 'number',
          label: 'Номер растения',
        },
        {
          type: 'SELECT',
          name: 'greenPlantLifeBreedId',
          label: 'Вид растения',
          options: lifeBreeds,
        },
        {
          type: 'SELECT',
          name: 'greenPlantLifeFormId',
          label: 'Жизненная форма',
          options: lifeForms,
        },
        {
          type: 'SELECT',
          name: 'okrugId',
          label: 'Округ',
          options: okrugs,
        },
        {
          type: 'SELECT',
          name: 'rayonId',
          label: 'Район',
          options: rayons,
        },
        {
          type: 'TEXT',
          name: 'address',
          label: 'Адрес',
        },
        {
          type: 'SELECT',
          name: 'status',
          label: 'Статус',
          options: statuses,
        },
      ]}
      presetValues={presetValues}
    >
      {children}
    </Filter>
  );
}

export default NSIReagentsTreesFilters;
