import React, { FC, useEffect, useMemo, useState } from 'react';
import { Checkbox, FormWrapper, Input } from 'components/common';
import { getSelectOptionsFromDictionaryByName } from 'features/get-select-options-from-dictionary';
import { Field, FieldArray, useFormikContext } from 'formik';
import { TDictionariesArray } from 'models/dictionaries';
import { VegetationDeicingItem } from '../useVegDicingList';
import { feMnParamId, feParamId, mnParamId } from 'pages/data/PGR/GreenPlants/VegetativeState/consts';
import { Box, Flex, Grid } from '@chakra-ui/react';
import { CommaNumberInput } from 'components/common/Input/comma-number-input';
import { MoreLessNumberInput } from '../../../../../../components/common/Input/more-less-number-input';
import { get } from 'lodash';

interface ParametersTabProps {
  dictionaries: {
    data: TDictionariesArray;
  } | null;
  isWrite?: boolean;
  isAnalytic?: boolean;
}

export const ParametersTab: FC<ParametersTabProps> = ({ children, dictionaries, isWrite, isAnalytic = false }) => {
  const [isRecount, setIsRecount] = useState(true);

  const { values, setFieldValue } = useFormikContext<VegetationDeicingItem>();

  const excludedIds = useMemo(() => (isAnalytic ? ['a8bb8315-5adf-4ae8-8159-5783aef94138'] : []), [isAnalytic]);

  const parameters = useMemo(
    () => getSelectOptionsFromDictionaryByName(
      dictionaries?.data,
      'parameters',
    ).filter((item) => (typeof item.parameterId === 'string' && !excludedIds.includes(item.parameterId))),
    [dictionaries?.data, excludedIds],
  );

  useEffect(() => {
    const valueParameterIds = [...values.values].map((val) => val.parameterId);

    parameters.forEach((dictVal, index) => {
      if (!valueParameterIds.includes(dictVal.parameterId)) {
        setFieldValue(`values[${index}]`, {
          value: null,
          name: dictVal.name,
          parameterId: dictVal.parameterId,
        });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parameters]);

  const feIdx = useMemo(() => values.values.findIndex((item) => item.parameterId === feParamId), [values]);
  const mnIdx = useMemo(() => values.values.findIndex((item) => item.parameterId === mnParamId), [values]);
  const feMnIdx = useMemo(() => values.values.findIndex((item) => item.parameterId === feMnParamId), [values]);

  const paramsProps: Record<string, any> = {
    [feParamId]: {
      onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
        setFieldValue(`values[${feIdx}].value`, e.target.value);
        const fnValue = Number(e.target.value) || 0;
        const mnValue = Number(values.values[mnIdx].value) || 0;
        recountValue(fnValue, mnValue);
      },
    },
    [mnParamId]: {
      onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
        setFieldValue(`values[${mnIdx}].value`, e.target.value);
        const mnValue = Number(e.target.value) || 0;
        const fnValue = Number(values.values[feIdx].value) || 0;
        recountValue(fnValue, mnValue);
      },
    },
    [feMnParamId]: {
      onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
        setFieldValue(`values[${feMnIdx}].value`, e.target.value);
      },
    },
  };

  const recountValue = (fnVal: number, mnVal: number) => {
    if (isRecount) {
      if (fnVal && mnVal) {
        const val = (fnVal / mnVal).toFixed(2);
        setFieldValue(`values[${feMnIdx}].value`, val);
      } else setFieldValue(`values[${feMnIdx}].value`, '');
    }
  };

  return (
    <Box flexDirection="column">
      <FormWrapper>
        <Grid marginTop="30px" gap="15px" marginBottom={20}>
          <FieldArray name="values" validateOnChange>
            {(props: any) => (
              <>
                {values.values.map((el, index) => {
                  const { errors } = props.form;
                  const label = parameters.find((item) => item.parameterId === el.parameterId)?.title;
                  const paramId = el.parameterId || '';

                  if (el.parameterId !== feMnParamId) {
                    const name = `values[${index}]`;
                    return (
                      <Field
                        key={index}
                        as={isAnalytic ? MoreLessNumberInput : Input}
                        error={errors && errors.values && errors.values[index] && errors.values[index].value}
                        name={name}
                        label={label}
                        isDisabled={!isWrite}
                        onChange={paramsProps[paramId] ? paramsProps[paramId].onChange :
                          (e: React.ChangeEvent<HTMLInputElement>) => {
                            setFieldValue(`values[${index}].value`, e.target.value);
                          }}
                        value={get(values, isAnalytic ? name : `${name}.value`)}
                      />
                    );
                  }
                  return (
                    <Flex key={index} flexDirection="column">
                      <Field
                        as={isAnalytic ? CommaNumberInput : Input}
                        error={errors && errors.values && errors.values[index] && errors.values[feMnIdx].value}
                        name={`values[${feMnIdx}].value`}
                        label={label}
                        isDisabled={!isWrite}
                        onChange={paramsProps[feMnParamId].onChange}
                      />
                      <Checkbox
                        marginTop={2}
                        checked={isRecount}
                        label="Пересчет значения"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          setIsRecount(e.target.checked);
                        }}
                      />
                    </Flex>
                  );
                })}
              </>
            )}
          </FieldArray>
          {children}
        </Grid>
      </FormWrapper>
    </Box>
  );
};
