import { ToolBarAction } from 'features/material-table/components/Toolbar';
import { useCallback } from 'react';
import { useNotifications, useRequest } from 'hooks';
import { formatErrorMessage } from 'lib/utils/formatErrorMessage';
import { ApiError, TFilterDTO } from 'models';
import { UploadIcon } from 'components/icons';

interface ToolBarActions {
  toolBarActions: ToolBarAction[];
}

export function useToolbarActions(type: number, filterDTO: TFilterDTO, disabled: boolean): ToolBarActions {
  const { successNotify, errorNotify } = useNotifications();
  const onSendSuccess = useCallback(() => {
    successNotify({
      key: 'sent/success',
      message: 'Отчет отправлен',
    });
  }, [successNotify]);

  const onSendError = useCallback(
    (error?: ApiError | null) => {
      errorNotify({
        key: 'sent/error',
        message: formatErrorMessage(error) || 'Ошибка отправки отчета',
      });
    },
    [errorNotify]
  );

  const { post } = useRequest('', onSendSuccess, onSendError);
  const sendToEHD = useCallback(() => {
    void post(filterDTO, { url: `ehd/v10/v1/catalog-uploader/reports/${type}` });
  }, [post, type, filterDTO]);

  return {
    toolBarActions: [
      {
        icon: <UploadIcon />,
        onClick: sendToEHD,
        label: 'Отправить в ГИС ЕХД',
        disabled
      },
    ]
  };
}
