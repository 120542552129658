import React from 'react';
import { citizenAppealsMenu } from 'features/WithSideBar/menus/citizenAppealsMenu';
import { PageHeader, RoutesList } from 'components/common';
import { Flex, Heading } from '@chakra-ui/react';
import { useRoutesList } from 'hooks/useRoutesList';

export const CitizenAppealsMenu: React.FC = () => {
  const routes = useRoutesList(citizenAppealsMenu);
  return (
    <Flex flexDirection="column">
      <PageHeader>
        <Heading>Учет обращений граждан</Heading>
      </PageHeader>
      <RoutesList routes={routes} />
    </Flex>
  );
};
