import { useCallback } from 'react';
import { MaterialTable } from 'features/material-table/MaterialTableMini';
import { stationDevicesLocale } from '../../locale';
import { StationDeviceItem } from 'pages/equipment/Stations/types';

export interface DevicesTableProps {
  data?: StationDeviceItem[];
  isLoading: boolean;
  onSelect: (ids: string[]) => void;
  selectedIndexes: number[];
}

export const DevicesTable = ({ data, onSelect, isLoading, selectedIndexes }: DevicesTableProps) => {
  const onSelectionChange = useCallback((items: StationDeviceItem[]) => {
    const ids = items.map((x) => x.id);
    onSelect(ids);
  }, [onSelect]);

  return (
    <MaterialTable
      data={data}
      columns={[
        {
          title: stationDevicesLocale.inventoryNumber,
          field: 'invent',
        },
        {
          title: stationDevicesLocale.modelName,
          field: 'name',
        },
        {
          title: stationDevicesLocale.serialNumber,
          field: 'serial',
        },
      ]}
      isLoading={isLoading}
      onSelectionChange={onSelectionChange}
      selectedIndexes={selectedIndexes}
      options={{
        selection: true,
      }}
    />
  );
};
