import { useEffect, useCallback } from 'react';
import { useRequest } from 'hooks/useRequest';
import { DeviceModelParameter } from 'pages/equipment/Models/types';

export function useMutateParameter(modelId: string, onOk: () => void) {
  const { put } = useRequest('/equipment/v10/equipments/parameters', onOk);
  const { post } = useRequest(`/equipment/v10/equipments/parameters/model/${modelId}`, onOk);

  const mutate = useCallback(
    (item: Partial<DeviceModelParameter>) => {
      if (item.id) {
        put(item);
      } else {
        post(item);
      }
    },
    [put, post]
  );

  return mutate;
}

interface UseParameterResult {
  data: DeviceModelParameter | Partial<DeviceModelParameter> | null;
  isLoading: boolean;
}

export function useParameter(modelId: string, id?: string): UseParameterResult {
  const { result: data, isLoading, get } = useRequest<DeviceModelParameter>(
    `/equipment/v10/equipments/parameters/${id}`,
  );

  useEffect(() => {
    if (id) {
      get();
    }
  }, [id, get]);

  return {
    data,
    isLoading,
  };
}
