import { useRequest } from 'hooks/useRequest';
import { useCallback, useEffect, useState } from 'react';
import { api } from 'api/config';
import { IUploadMedia } from 'components/common/FileUploadDragAndDrop/types';
import { compact } from 'lodash';

export enum UploadObjectType {
  REGISTRY = 'REGISTRY',
  VALUE = 'VALUE',
  PACKAGE = 'PACKAGE',
  DOCUMENT = 'DOCUMENT',
  ANSWER = 'ANSWER',
  OBSERVATION_POINT = 'OBSERVATION_POINT',
  REPORT_OBSERVATION_POINT = 'REPORT_OBSERVATION_POINT',
  JOURNAL_OBSERVATION_POINT = 'JOURNAL_OBSERVATION_POINT',
  EQUIPMENT_DEVICE = 'EQUIPMENT_DEVICE',
  CAMERA_FIRE = 'CAMERA_FIRE',
}

interface MediaItem {
  id: string,
  name: string,
  length: number
  contentType: string,
  originName?: string;
}

interface UseGetMediaInterface {
  uploadedFiles: IUploadMedia[]
  isLoading: boolean
  reset: () => void
}

export const useGetMediaById = (objectType: UploadObjectType | null, id?: string): UseGetMediaInterface => {
  const [uploadedFiles, setUploadedFiles] = useState<IUploadMedia[]>([]);
  const [loading, setLoading] = useState(false);

  const {
    get,
    result,
    isLoading,
  } = useRequest<MediaItem[]>(`/media/v10/media/${id}?objectType=${objectType}`);

  useEffect(() => {
    if (id && objectType) {
      void get();
    }
  }, [get, id, objectType]);

  useEffect(() => {
    void load();

    async function load() {
      if (result) {
        setLoading(true);
        const res = await Promise.all(result.map((mediaItem) => getFileById(mediaItem)));
        const filteredImages: IUploadMedia[] = compact(res);
        setUploadedFiles(filteredImages);
        setLoading(false);
      }
    }
  }, [result]);

  const reset = useCallback(() => {
    setUploadedFiles([]);
  }, []);

  return {
    uploadedFiles,
    isLoading: isLoading || loading,
    reset
  };
};

export const getFileById = async (mediaItem: MediaItem): Promise<IUploadMedia | null> => {
  let data = null;

  try {
    const response = await api.request<string>({
      method: 'GET',
      url: `/media/v10/media/download/${mediaItem.id}`,
      responseType: 'blob',
    });
    if (response.data) {
      data = response.data as unknown as Blob;
    }
  } catch (error) {
    return null;
  }

  return data !== null ? {
    file: new File([data], mediaItem.name, { type: data.type }),
    id: mediaItem.id,
    originName: mediaItem.originName,
    name: mediaItem.name,
  } : data;
};

export const uploadFile = async (id: string, objectType: UploadObjectType, file: File) => {
  const formData = new FormData();

  formData.append('file', file);
  formData.append('objectId', id);
  formData.append('objectType', objectType);

  return api.request({
    method: 'POST',
    url: '/media/v10/media/upload',
    data: formData,
  });
};

export const deleteFile = async (id: string) => api.request<string>({
  method: 'DELETE',
  url: `/media/v10/media/${id}`,
});

export const updateFiles = async (id: string, objectType: UploadObjectType | null, media: IUploadMedia[]) => {
  const uploadPromises: Promise<any>[] = [];
  const deletePromises: Promise<any>[] = [];

  if (!objectType) return;
  const deleteItems = [...media].filter((item) => item.onSaveMediaAction === 'delete');
  const uploadItems = [...media].filter((item) => item.onSaveMediaAction === 'upload');

  deleteItems.forEach((item) => {
    if (item.id) {
      deletePromises.push(deleteFile(item.id));
    }
  });

  uploadItems.forEach((item) => {
    uploadPromises.push(uploadFile(id, objectType, item.file));
  });

  if (uploadPromises.length === 0 && deletePromises.length === 0) {
    return;
  }

  await Promise.all(deletePromises);

  return Promise.all(uploadPromises)
    .then((response: any) => uploadItems.map((item, idx) => {
      if (item.onSaveMediaAction === 'upload') {
        return {
          file: item.file,
          id: response[idx].data.id
        };
      }
      if (response[idx].data === '') {
        return null;
      }
      return item;
    })
      .filter((item) => item !== null && item !== undefined) as IUploadMedia[])
    .catch((error) => {
      console.error(error);
      throw new Error('Ошибка при загруке файлов');
    });
};

export const useUpdateFiles = () => {
  const [isLoading, setIsLoading] = useState(false);

  const _updateFiles = useCallback(async (id: string, objectType: UploadObjectType | null, media: IUploadMedia[]) => {
    setIsLoading(true);

    return updateFiles(id, objectType, media).finally(() => {
      setIsLoading(false);
    });
  }, []);

  return {
    updateFiles: _updateFiles,
    isLoading
  };
};
