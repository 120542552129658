import React, { useCallback, useMemo } from 'react';
import { COMMON_LOCALE } from 'constants/common';
import { Button, Input, Select } from 'components/common';
import { Field, useFormikContext } from 'formik';
import { DeviceModel } from 'pages/equipment/Models/types';
import { fieldsLocale } from 'pages/equipment/Models/locale';
import { Box, Flex } from '@chakra-ui/react';
import { useDictionary } from 'hooks/useDictionary';
import { getSelectOptionsFromDictionaryByName } from 'features/get-select-options-from-dictionary';

interface FormFieldsProps {
  isDisabled: boolean;
  onCancel: () => void;
}

export const FormFields: React.FC<FormFieldsProps> = ({ isDisabled, onCancel }) => {
  const { handleSubmit, errors, initialValues } = useFormikContext<DeviceModel>();
  const onSubmit = useCallback(() => {
    handleSubmit();
  }, [handleSubmit]);

  const { dictionaries } = useDictionary({ url: '/equipment/v10/equipments/models/dictionaries' });
  const types = useMemo(
    () => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'types', false, initialValues.sensorType),
    [dictionaries?.data, initialValues.sensorType],
  );
  const devises = useMemo(
    () => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'devises'),
    [dictionaries?.data],
  );

  return (
    <Flex flexDirection="column" gap={3}>
      <Field as={Input} name="modelName" label="Название модели" error={errors.modelName} isDisabled={isDisabled} />
      <Field as={Input} name="vendor" label="Производитель" error={errors.vendor} isDisabled={isDisabled} />
      <Field as={Select} name="sensorType" label={fieldsLocale.sensorType} error={errors.sensorType} options={types} />
      <Box maxWidth="480px">
        <Field as={Select} multiple name="deviceIds" label="Приборы" options={devises} />
      </Box>
      <Flex>
        <Button onClick={onSubmit}>{COMMON_LOCALE.save}</Button>
        <Button onClick={onCancel} variant="ghost" marginRight={5}>
          {COMMON_LOCALE.cancel}
        </Button>
      </Flex>
    </Flex>
  );
};
