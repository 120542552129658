import { Flex } from '@chakra-ui/react';
import React from 'react';

interface Props {
  file: File;
}

const FilePreviewImageSlide = ({ file }: Props) => (
  <Flex height="100%">
    <img
      alt="carousel slide"
      src={URL.createObjectURL(file)}
      style={{ maxHeight: 600, objectFit: 'contain' }}
    />
  </Flex>
);
export default FilePreviewImageSlide;
