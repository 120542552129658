import { FormikProvider, useFormik } from 'formik';
import { FiltersDatepickersSchema } from 'models/schemas/filtersDatepickersSchema/FiltersDatepickersSchema';
import React from 'react';
import { initialValues } from '../const';
import { FormFields } from './formFields';

interface FilterProps {
  isLoading?: boolean;
  isDisabled?: boolean;
  onSubmit: (values: any) => void;
}

export const ActivityLogFilter = ({ isLoading, isDisabled, onSubmit }: FilterProps) => {
  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    validationSchema: FiltersDatepickersSchema([{ dateFromFieldName: 'startDate', dateToFieldName: 'endDate' }]),
    initialValues,
    onSubmit: (values) => {
      onSubmit({ ...values, type: { ...values.type, value: values.type?.value ? null : 'PROTOKOL_USER_ACTION' } });
    },
  });

  return (
    <FormikProvider
      value={{
        ...formik,
      }}
    >
      <FormFields isLoading={isLoading} isDisabled={isDisabled} />
    </FormikProvider>
  );
};
