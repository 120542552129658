import { ERROR_MESSAGES, SUCCESS_MESSAGES } from 'constants/messages';
import { useNotifications } from 'hooks/useNotifications';
import { useRequest } from 'hooks/useRequest';
import { DeviceModelForm } from 'pages/equipment/Models/components/DeviceModelForm';
import { DeviceModel } from 'pages/equipment/Models/types';
import { DeviceModelLayout } from 'pages/equipment/Models/components/DeviceModelLayout';
import { FormContainer } from 'pages/equipment/Models/components/DeviceModelForm/FormContainer';
import { useEquipmentModelsBreadcrumbs } from './hooks/useEquipmentModelsBreadcrumbs';
import { useGoBackWithFallback } from 'lib/utils/goBackWithFallback';
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

export const AddDeviceModel = () => {
  const { backWithFallback } = useGoBackWithFallback();
  const { push } = useHistory();
  useEquipmentModelsBreadcrumbs();

  const { successNotify, errorNotify } = useNotifications();
  const onSuccess = (data: DeviceModel | null) => {
    successNotify({
      key: 'file/edit/success',
      message: SUCCESS_MESSAGES.CREATE,
    });
    const url = data ? `/equipment/models/edit/${data.id}/true` : '/equipment/models';
    push(url);
  };
  const onError = () =>
    errorNotify({
      key: 'file/edit/error',
      message: ERROR_MESSAGES.CREATE,
    });
  const { post } = useRequest('/equipment/v10/equipments/models', onSuccess, onError);

  const initialValues: Partial<DeviceModel> = {
    modelName: undefined,
    vendor: undefined,
  };

  const onCancel = useCallback(() => {
    backWithFallback('/equipment/models');
  }, [backWithFallback]);

  return (
    <DeviceModelLayout
      title="Добавление модели"
      onBackClick={onCancel}
      model={
        <FormContainer>
          <DeviceModelForm initialValues={initialValues} onSubmit={post} onCancel={onCancel} isDisabled={false} />
        </FormContainer>
      }
    />
  );
};
