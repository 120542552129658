const graphicsTemplateIdsMap = {
  // pgr
  pgrSnow: '3d48cd90-ddab-4512-a9ed-adf3c8633c05',
  pgrWashouts: 'db31bd3f-dec9-47e7-a8f1-94c31ad68bd5',
  pgrVegetative: '7e717101-ad1c-474a-81d7-e79007cca35f',
  pgrSoil: '3ba24f92-64fc-4795-adaf-797dfe83c356',
  pgrLawn: '5b330d16-ae81-459b-ba91-9bdebd071c47',
  pgrFlora: '695290c8-9bef-49b5-b3b4-b12400c72e10',

  // greenplants
  greenplantsFlora: '695290c8-9bef-49b5-b3b4-b12400c72e10',
  greenplantsLawns: '5b330d16-ae81-459b-ba91-9bdebd071c47',

  // dangerprocesses
  dangerProcess: '2c3d028e-a003-444a-927a-d0d5b2738468',
  reper: '8edd0530-116e-4d82-bb2c-d6712361c8b9',
  inclinometer: '7bcb751e-83d3-48aa-8584-a05ef013c8ba',

  // Atmosphere monitoring - ASKZA
  atmosphere: '89a3dea0-f35e-4c4b-a029-e1a1d32f9a1e',

  // emissions
  emissions: 'a0b39559-ad68-4e37-804f-07d54fc2752c',

  // soil
  soilpollution: '5159e70e-f338-4b9a-9ff3-3c2c74cd4ca9',

  // forecast
  forecast: '889fea37-6735-411c-a96d-34a90eaa8386',

  // meteo
  meteoASKZA: '89a3dea0-f35e-4c4b-a029-e1a1d32f9a1e',

  // masksh
  maskshNoise: 'ce963408-d323-4b8b-878a-76b793d90766',
  maskshMeteo: 'b96e9fdd-a6b2-4215-b018-ab5ceba9d8d4',
};

export default graphicsTemplateIdsMap;
