import { ApiError } from 'models';
import { useRequest } from 'hooks/useRequest';
import { useEffect } from 'react';
import { StationDeviceItem } from 'pages/equipment/Stations/types';

interface Data {
  id: string;
  isDeleted: boolean;
  name: string;
  serial: string;
  invent: string;
  modelId: string;
  stationId: string | null;
  models: StationDeviceItem['models'];
}

interface UseAvailableDevicesResult {
  data: Data[];
  isLoading: boolean;
  error:
  | null
  | ApiError
  | {
    descriptions: Array<{ message: string }>;
  };
}

export function useAvailableDevices(): UseAvailableDevicesResult {
  const { result, isLoading, error, get } = useRequest<Data[]>('/equipment/v10/equipments/stations/devices');

  useEffect(() => {
    get();
  }, [get]);

  return {
    data: result ?? [],
    isLoading,
    error,
  };
}
