import { useCallback, useState } from 'react';

export const useForceUpdate = () => {
  /* eslint-disable @typescript-eslint/no-unused-vars */
  const [_, setState] = useState(1);

  const forceUpdate = useCallback(() => {
    setState((num) => ++num);
  }, [setState]);

  return forceUpdate;
};
