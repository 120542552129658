export const fieldsLocale = {
  modelName: 'Название модели',
  vendor: 'Производитель',
  characteristic: 'Параметры',
  sensorType: 'Тип датчика',
  deviceNames: 'Приборы',
};

export const modelsLocale = {
  measureDevices: 'Измерительные приборы',
  models: 'Модели',
};

export const parametersTableFields = {
  parameterName: 'Название параметра',
  parameterCode: 'Идентификатор параметра (код)',
  purpose: 'Назначение',
  accuracy: 'Точность (погрешность)',
  unit: 'Единица измерения',
  averagePeriod: 'Период AVG',
  genMin: 'Мин',
  genMax: 'Макс',
};

export const parametersLocale = {
  accuracy: 'Точность (погрешность) измерения параметра',
  orderNo: 'Порядок следования',
  parameterName: 'Название параметра',
  parameterCode: 'Код параметра',
  purpose: 'Назначение параметра',
  unit: 'Единица измерения',
  averagePeriod: 'Период усреднения',
  genMin: 'Формирует MIN',
  genMax: 'Формирует MAX',
  from: 'От',
  to: 'До',
};
