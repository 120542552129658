import { useRequest } from 'hooks';
import { useEffect } from 'react';
import { MASKSHApiInstruction } from 'pages/data/MASKSH/consts';
import { IInstructionRecord } from 'pages/data/MASKSH/Instructions/InstructionsForm/types';

export function useInstructionsRecord(packageId: string) {
  const { get, isLoading, result, error } = useRequest<IInstructionRecord>(`${MASKSHApiInstruction}/${packageId}`);

  useEffect(() => {
    get();
  }, [get]);

  return { isLoading, result, error };
}
