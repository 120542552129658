import { NMUFormFields } from './FormFields';
import { FormikProvider, useFormik } from 'formik';
import { IFilterItem, TFiltersArray } from 'models';
import React, { useEffect } from 'react';
import { Grid } from '@chakra-ui/react';
import { NMUListItem } from '../types';
import { FiltersDatepickersSchema } from 'models/schemas/filtersDatepickersSchema/FiltersDatepickersSchema';
import combineFilters from 'lib/utils/combineFilters';

const initialValues: Record<string, IFilterItem<string>> = {
  startDate: {
    key: 'startDate',
    operation: 'GTE',
    value: '',
  },
  endDate: {
    key: 'endDate',
    operation: 'LTE',
    value: '',
  },
  grade: {
    key: 'grade',
    operation: 'EQ',
    value: '',
  },
  deleted: {
    key: 'deleted',
    operation: 'EQ',
    value: false,
  },
};

interface IProps {
  onSubmitFilters: (filters: TFiltersArray<keyof NMUListItem>) => void;
  children: React.ReactNode;
  filters: IFilterItem[];
}

export const NMUFilter = ({ onSubmitFilters, children, filters }: IProps) => {
  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    initialValues,
    validationSchema: FiltersDatepickersSchema([{ dateFromFieldName: 'startDate', dateToFieldName: 'endDate' }]),
    onSubmit: (values: any) => {
      const filters: TFiltersArray<keyof NMUListItem> = Object.values(values);
      onSubmitFilters(filters);
    },
  });

  useEffect(() => {
    const initialFilters = combineFilters(initialValues, filters);
    Object.keys(initialFilters).forEach((key) => {
      formik.setFieldValue(`${key}.value`, initialFilters[key].value);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormikProvider
      value={{
        ...formik,
      }}
    >
      <NMUFormFields />
      <Grid marginTop={5}>{children}</Grid>
    </FormikProvider>
  );
};
