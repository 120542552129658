import { DragAndDrop } from 'features/UploadFileDragAndDrop/DragAndDrop/DragAndDrop';
import { Field, FormikProvider, useFormik } from 'formik';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Select } from 'components/common';
import { useUploadFileProvider } from 'features/UploadedFileProvider';
import appLocale from 'constants/appLocale';
import { Flex } from '@chakra-ui/react';

// : TODO допилить проброс typeId в реквест preview при необходимости

export interface UploadFileDragAndDropProps {
  previewPagePath?: string;
  onChangeType?: (typeId: string) => void;

  onClose(): void;
  parametersSave?: (preview: any) => void;
  typesToExclude?: number[];
}

const RootComponent: React.FC<UploadFileDragAndDropProps> = ({
  previewPagePath,
  onChangeType,
  onClose,
  parametersSave,
  typesToExclude = null // Костыль для парсинга АИС Погоды
}) => {
  const { types, preview } = useUploadFileProvider();

  const extTypes = useMemo(() =>
    types?.result?.types
      .filter((type) => {
        if (typesToExclude) {
          return !typesToExclude.includes(Number(type.value));
        }
        return type;
      }) ?? [],
  [types, typesToExclude]);

  const formik = useFormik<{ typeId: string }>({
    initialValues: { typeId: '' },
    onSubmit: () => undefined,
  });

  const history = useHistory();

  const forwardPreviewPage = useCallback(() => {
    if (previewPagePath) {
      history.push(previewPagePath);
    }
  }, [history, previewPagePath]);

  useEffect(() => {
    if (extTypes.length === 1) {
      formik.setFieldValue('typeId', extTypes[0].value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.setFieldValue, extTypes]);

  useEffect(() => {
    if (typeof onChangeType === 'function') {
      onChangeType(formik.values.typeId);
    }
  }, [formik.values.typeId, onChangeType]);

  return (
    <Flex flexDirection="column" width="500px">
      <FormikProvider value={formik}>
        <Field
          as={Select}
          label="Шаблон"
          value={extTypes.length === 1 && !extTypes[0].name ? '' : formik.values.typeId?.toString()}
          name="typeId"
          options={extTypes}
          isDisabled={extTypes.length === 1}
        />
      </FormikProvider>
      <DragAndDrop
        errors={preview?.result?.errors}
        onChangeAsFormData={(file, config) => {
          if (preview?.getFilePreview) {
            file.append('type', formik.values.typeId || '');
            preview.getFilePreview(file, config);
          }
        }}
        isDisabled={!formik.values.typeId}
      />

      <Flex>
        <Button onClick={onClose} variant="outline" color="PrimaryButton.500">
          {appLocale.actions.cancel}
        </Button>
        {!parametersSave ? (
          <Button
            variant="outline"
            color="PrimaryButton.500"
            isDisabled={!preview?.result?.measures?.filter((item) => Boolean(item)).length}
            onClick={forwardPreviewPage}
            ml="auto"
          >
            {appLocale.actions.preview}
          </Button>
        ) : (
          <Button
            onClick={() => parametersSave(preview)}
            variant="outline"
            color="PrimaryButton.500"
            isDisabled={Boolean(!preview?.result)}
            ml="auto"
          >
            {appLocale.actions.upload}
          </Button>
        )}
        {/* TODO: uncomment */}
        {/* <Button */}
        {/*  ml={2} */}
        {/*  isDisabled={ */}
        {/*    Boolean(!preview?.result?.measures?.length) || */}
        {/*    preview?.result?.hasErrors */}
        {/*  } */}
        {/*  onClick={saveWithoutPreview} */}
        {/* > */}
        {/*  Сохранить */}
        {/* </Button> */}
      </Flex>
    </Flex>
  );
};

const UploadFileDragAndDrop = RootComponent;

export { UploadFileDragAndDrop };
