import { TableFilterTypeEnum } from 'components/table/filters/TableFilter';
import React from 'react';
import { ColumnWithFilter } from 'lib/utils/tableFilter';
import { AtmosphereMonitoringItem } from 'hooks/atmosphere/Monitoring/useAtmosphereMonitoringList';
import { DateTimeTableCell } from '../../../pages/data/Water/components/DateTimeTableCell';

const atmosphereColumns: ColumnWithFilter<AtmosphereMonitoringItem>[] = [
  {
    title: 'Дата и время',
    field: 'datetime',
    render: (row) => <DateTimeTableCell inline datestring={row.datetime} />,
  },
  {
    title: 'Станция',
    field: 'sourceName',
    filterType: TableFilterTypeEnum.string,
  },
  {
    title: 'Параметр',
    field: 'parameterName',
    filterType: TableFilterTypeEnum.string,
  },
  {
    title: 'Значение max',
    field: 'maxValue.value',
    filterType: TableFilterTypeEnum.number,
  },
  {
    title: 'Значение avg',
    field: 'avgValue.value',
    filterType: TableFilterTypeEnum.number,
  },
  {
    title: 'Значение min',
    field: 'minValue.value',
    filterType: TableFilterTypeEnum.number,
  },
];

export default atmosphereColumns;
