import { TBaseGreenPlantsFormProperties } from '../../types';

const GreenPlantsAreaLabels: Record<keyof TBaseGreenPlantsFormProperties<string>, string> = {
  areaId: 'Уникальный номер площадки',
  date: 'Дата и время',
  registryId: 'Регистрационный ID',
  parameters: 'Параметры',
  deleted: 'Признак удалено',
  areaNumber: 'Уникальный номер площадки',
  plantForm: 'Жизненная форма',
  plantType: 'Вид растения',
  plantId: '',
  plantNumber: '№ растения',
  lawnId: '',
  lawnName: '№ Газона',
  address: 'Адрес',
  landmark: 'Ориентир',
  square: 'Площадь, кв.м',
  organization: 'Наблюдающая организация'
};

export { GreenPlantsAreaLabels };
