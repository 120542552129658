import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import appLocale from 'constants/appLocale';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { Box, Button, Flex, useDisclosure } from '@chakra-ui/react';
import moment from 'moment';
import 'chartjs-adapter-moment';
import 'moment/locale/ru';
import ASKZAMeteoFilter, {
  IASKZAMeteoFilterForm,
} from 'pages/analyticsReports/CombinedCharts/ASKZAMeteo/ASKZAMeteoFilter';
import { Modal } from 'components/common';
import { useRequest } from 'hooks';
import { IFormattedGraphData, IGraphResponse, IGraphSettings } from 'pages/analyticsReports/CombinedCharts/types';
import {
  formatGraphDataForRender,
  makeGraphOptions,
  makeInitialGraphSettings,
} from 'pages/analyticsReports/CombinedCharts/graphUtils';
import GraphSettingsForm from 'pages/analyticsReports/CombinedCharts/GraphSettingsForm';
import { initialGraphOptionWithCustomFormat } from 'pages/analyticsReports/CombinedCharts/const';
import { GraphicDropdownMenu } from 'components/common/GraphicDropdownMenu';
import { ASKZAMeteoDiagram } from './ASKZAMeteoDiagram';

moment.locale('ru');

const datasetAxisIndex = {
  0: 1,
  1: 2,
  2: 3,
};
const maxPdkIndex = [2];
const ASKZAMeteo = () => {
  useUpdateBreadcrumbs([
    { breadcrumb: appLocale.subsystems.analyticsReports, path: '/reports-analytics' },
    { breadcrumb: appLocale.analytics.combinedCharts.title, path: '/reports-analytics/chart-combination' },
    {
      breadcrumb: appLocale.analytics.combinedCharts.askzaMeteo.title,
      path: '/reports-analytics/chart-combination/askza-meteo',
    },
  ]);
  const [diagramOpen, setDiagramOpen] = useState(false);
  const [data, setData] = useState<{ datasets: IFormattedGraphData[] } | null>(null);
  const [options, setOptions] = useState({ ...initialGraphOptionWithCustomFormat, spanGaps: false });
  const [graphSettings, setGraphSettings] = useState<IGraphSettings | null>(null);

  const chartRef = useRef<any>();

  const onClick = useCallback((event: any) => {
    // TODO: возможно тут в будущем будет переход на запись
    console.log(event);
  }, []);

  const settingsModalDisclosure = useDisclosure();

  const onSuccess = useCallback((graphResponse: IGraphResponse[] | null) => {
    if (!graphResponse) return;
    // set default graph settings from response
    setGraphSettings(
      makeInitialGraphSettings({
        graphResponse: graphResponse[0],
        maxPdkIndex,
      }),
    );
  }, []);

  const {
    post,
    result,
    isLoading: isGraphLoading,
  } = useRequest<IGraphResponse[]>('/reporting/v10/graphs/askza-meteo', onSuccess);

  useEffect(() => {
    if (result && result[0] && graphSettings) {
      setData({
        datasets: formatGraphDataForRender({
          graphResponse: result[0],
          datasetAxisIndex,
          graphSettings,
          maxPdkIndex,
        }),
      });
      // set graph options
      setOptions(
        makeGraphOptions({
          graphResponse: result[0],
          datasetAxisIndex,
          graphSettings,
          initialOptions: { ...initialGraphOptionWithCustomFormat, spanGaps: false }
        })
      );
    } else {
      setData(null);
      setOptions({ ...initialGraphOptionWithCustomFormat, spanGaps: false });
    }
  }, [graphSettings, result]);

  const buildGraph = useCallback(
    (values: IASKZAMeteoFilterForm) => {
      setGraphSettings(null);
      setData(null);
      void post(values);
    },
    [post],
  );

  const chart = useMemo(() => {
    if (!data) return null;

    return (
      <>
        <Line
          /* @ts-ignore */
          options={options}
          id="LineChart"
          data={data}
          ref={chartRef}
          getElementAtEvent={onClick}
          height="200px"
          width="200px"
          // @ts-ignore
          type="line"
        />
        <div id="legend-container" />
      </>
    );
  }, [data, onClick, options]);

  const diagramOnOpen = () => {
    setDiagramOpen((prev) => !prev);
  };

  return (
    <Flex px="16px" flexDirection="column" height="calc(100vh - 60px - 32px)" position="sticky" top="60px">
      <ASKZAMeteoFilter onSubmit={buildGraph} isGraphLoading={isGraphLoading} />

      <Flex background="#FAFBFB" pl="8px" width="100%" justifyContent="space-between" alignItems="center" pr="20px">
        <Button variant="solid" size="sm" colorScheme="PrimaryButton" onClick={diagramOnOpen} isDisabled={!result}>
          Открыть&nbsp;
          {diagramOpen ? 'график' : 'диаграмму'}
        </Button>

        <GraphicDropdownMenu
          viewOnOpen={settingsModalDisclosure.onOpen}
          isDisabled={!result || !graphSettings}
          filename={appLocale.analytics.combinedCharts.askzaMeteo.title}
        />
      </Flex>

      <Box flex={1} position="relative">
        {diagramOpen ? <ASKZAMeteoDiagram response={result?.[1]} /> : chart}
      </Box>

      {graphSettings && (
        <Modal isOpen={settingsModalDisclosure.isOpen} onClose={settingsModalDisclosure.onClose}>
          <GraphSettingsForm
            onClose={settingsModalDisclosure.onClose}
            setGraphSettings={setGraphSettings}
            graphSettings={graphSettings}
          />
        </Modal>
      )}
    </Flex>
  );
};

export default ASKZAMeteo;
