import { InputFileMetadata } from 'features/UploadedFileProvider/types';

const ACCEPT = ['csv', 'xlsx', 'xls', 'txt'];

export function createInputFileMetadata(file: File): InputFileMetadata {
  let inputFileMetadata: InputFileMetadata = {};
  const { size } = file;
  const expansion = file.name.split('.')[file.name.split('.').length - 1];
  let rejected = false;
  if (size > 1024 * 1024 * 10 || ACCEPT.indexOf(expansion) === -1) {
    rejected = true;
  }
  inputFileMetadata = {
    frontId: 0,
    name: file.name,
    size,
    expansion,
    rejected,
    percentage: 0,
    loaded: 0,
    isLoading: false,
  };

  return inputFileMetadata;
}

// function getFrontId(): any {
//   const set = new Set<number>();
//   return function generateId(): any {
//     const id = Math.round(Math.random() * 1e5);
//     if (set.has(id)) {
//       generateId();
//     } else {
//       set.add(id);
//       return id;
//     }
//   };
// }
