import { MaterialTable } from 'features/material-table/MaterialTableMini';
import { stationDevicesLocale } from '../../locale';
import { StationDeviceItem } from 'pages/equipment/Stations/types';
import { useMemo } from 'react';
import { Column } from '@material-table/core';

export interface StationDevicesTableProps {
  data?: StationDeviceItem[];
  isLoading: boolean;
  onDelete: (item: StationDeviceItem) => void;
}

export const StationDevicesTable = ({ data, onDelete, isLoading }: StationDevicesTableProps) => {
  const columns = useMemo<Column<StationDeviceItem>[]>(() => ([
    {
      title: stationDevicesLocale.inventoryNumber,
      field: 'inventoryNumber',
    },
    {
      title: stationDevicesLocale.modelName,
      field: 'models',
      render: (row) => row.models?.map((model) => model.name)
        .join(', '),
    },
    {
      title: stationDevicesLocale.serialNumber,
      field: 'serialNumber',
    },
  ]), []);

  return <MaterialTable
    data={data || []}
    columns={columns}
    actions={[
      {
        type: 'delete',
        onClick: (e, item) => {
          if (Array.isArray(item)) {
            return;
          }
          onDelete(item);
        },
      },
    ]}
    isLoading={isLoading}
  />;
};
