import React from 'react';
import { PageHeader, RoutesList } from 'components/common';
import { Flex, Heading } from '@chakra-ui/react';
import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import appLocale from 'constants/appLocale';
import { nodMenu } from '../reportsAnalyticsMenu';
import { useRoutesList } from 'hooks/useRoutesList';

export const AnalyticsReportsNODList: React.FC = () => {
  useUpdateBreadcrumbs([
    { breadcrumb: appLocale.subsystems.analyticsReports, path: '/reports-analytics' },
    { breadcrumb: appLocale.analyticsReports.nod, path: '/reports-analytics/nod' },
  ]);

  const routes = useRoutesList(nodMenu);

  return (
    <Flex flexDirection="column">
      <PageHeader>
        <Heading>{appLocale.analyticsReports.nod}</Heading>
      </PageHeader>
      <RoutesList routes={routes} />
    </Flex>
  );
};
