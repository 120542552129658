import { ASKZA as ASKZACommon } from 'components/common/ASKZACommon/ASKZA';
import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import appLocale from 'constants/appLocale';
import React from 'react';
import subsystemIdsMap from 'constants/subsystemIdsMap';

export const ASKZA: React.FC = () => {
  useUpdateBreadcrumbs([
    { breadcrumb: appLocale.common.data, path: '/data' },
    {
      breadcrumb: appLocale.subsystems.meteo,
      path: '/data/meteo',
    },
    {
      breadcrumb: 'Метеоданные',
      path: '/data/meteo/data',
    },
    {
      breadcrumb: 'Данные АСКЗА',
      path: '/data/meteo/data/askza',
    },
  ]);

  return (
    <ASKZACommon
      title="Данные АСКЗА"
      rootPath="/data/meteo/data/askza"
      apiRootPath="/meteo/v10/meteo/askza"
      askzaType="6"
      types={[
        {
          value: '1',
          name: 'Набор параметров АСКЗА (Метео)',
        },
      ]}
      exportSubsystemName="meteo_askza"
      subsystemId={subsystemIdsMap.meteoASKZA}
      checkSourcePriv
    />
  );
};
