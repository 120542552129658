import React, { useCallback, useState } from 'react';
import { ERROR_MESSAGES, SUCCESS_MESSAGES } from 'constants/messages';
import { createTouchedErrors } from 'features/create-touched-errors';
import { FormikProvider, useFormik } from 'formik';
import { useNotifications } from 'hooks/useNotifications';
import { useRequest } from 'hooks/useRequest';
import { useHistory } from 'react-router-dom';
import FieldsForm from 'pages/analytics/forms/Washout/AnalyticsWashoutFieldsForm';
import { IUploadMedia } from 'components/common/FileUploadDragAndDrop/types';
import { updateFiles } from 'api/services/media';
import { WashoutDeicingItem } from 'pages/data/PGR/GreenPlants/Washout/useWashoutDicingList';
import { useObjectType } from 'hooks/useObjectType';
import { ANALYTICS_LAB_PATHS } from 'pages/analytics/consts/AnalyticsTableRoutes';
import { initialEmptyValues } from './initialEmptyValues';
import { AnalyticsWashoutSchema } from 'models/schemas/analytics/analyticsWashoutSechema';
import { useGoBackWithFallback } from 'lib/utils/goBackWithFallback';
import { useDictionary } from 'hooks/useDictionary';
import { useEnhancedNotification } from 'hooks/useEnhancedNotification';

export const AnalyticsAddDeicingWashoutReport = () => {
  const { isDictionariesLoading, dictionaries } = useDictionary({ url: '/deicing/v10/deicing/washout/dictionaries' });
  const history = useHistory();
  const { backWithFallback } = useGoBackWithFallback();
  const { successNotify, errorNotify } = useNotifications();
  const [media, setMedia] = useState<IUploadMedia[]>([]);
  const objectType = useObjectType(dictionaries);
  const { onErrorCreate } = useEnhancedNotification();

  const onSuccess = (response: WashoutDeicingItem | null) => {
    if (response) {
      successNotify({
        key: 'file/add/success',
        message: SUCCESS_MESSAGES.CREATE,
      });

      updateFiles(response.registryId, objectType, media)
        .then(() => {
          backWithFallback('/analytics');
        })
        .catch((error: Error) => {
          onError(error.message);
          history.replace(
            `${ANALYTICS_LAB_PATHS.reagents.washout.edit}/${response.registryId}?openAttachmentsTab=true`,
            response
          );
        });
    } else {
      onError();
    }
  };

  const onError = useCallback(
    (message?: string | null) => {
      errorNotify({
        key: message || 'file/add/error',
        message: message || ERROR_MESSAGES.CREATE,
      });
    },
    [errorNotify]
  );

  const { post } = useRequest<WashoutDeicingItem, any>('/deicing/v10/deicing/washout', onSuccess, onErrorCreate);

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    initialValues: initialEmptyValues,
    onSubmit: (values) => post(values),
    validationSchema: AnalyticsWashoutSchema,
  });

  return (
    <FormikProvider
      value={{
        ...formik,
        errors: createTouchedErrors(formik.errors, formik.touched),
      }}
    >
      <FieldsForm
        dictionaries={dictionaries}
        onCancel={() => {
          backWithFallback('/analytics');
        }}
        isLoading={isDictionariesLoading}
        onMediaChange={setMedia}
        media={media}
      />
    </FormikProvider>
  );
};
