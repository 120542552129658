import { useRequest, UseRequestReturnType } from 'hooks/useRequest';
import { useCallback, useEffect } from 'react';
import { StationDeviceItem } from 'pages/equipment/Stations/types';

interface UseStationDevicesResult {
  data?: StationDeviceItem[];
  isLoading: boolean;
  error: UseRequestReturnType['error'];
  refresh: () => void;
}

/**
 * Get list of Devices assigned to Station
 */
export function useStationDevices(stationId: string): UseStationDevicesResult {
  const { get, isLoading, result, error } = useRequest<StationDeviceItem[]>(
    `/equipment/v10/equipments/stations/devices/${stationId}`,
  );

  useEffect(() => {
    get();
  }, [get]);

  const refresh = useCallback(() => {
    get();
  }, [get]);

  return {
    data: result ?? [],
    refresh,
    isLoading,
    error,
  };
}
