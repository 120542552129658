import React from 'react';
import { Flora, Lawns } from 'pages/data/GreenPlants';
import { GreenPlantsFormTypes } from 'pages/data/GreenPlants/Form/types';
import { Route } from 'react-router-dom';
import { AddLawns } from 'pages/data/GreenPlants/Lawns/AddLawns/AddLawns';
import { AddFlora } from 'pages/data/GreenPlants/Flora/AddFlora/AddFlora';
import { EditFlora } from 'pages/data/GreenPlants/Flora/EditFlora/EditFlora';
import { EditLawns } from 'pages/data/GreenPlants/Lawns/EditLawns/EditLawns';
import { GreenPlantsList } from 'pages/data/GreenPlants/GreenPlantsList';
import { AddArea } from 'pages/data/GreenPlants/Areas';
import { FloraAreas } from 'pages/data/GreenPlants/Areas/FloraAreas/FloraAreas';
import { EditArea } from 'pages/data/GreenPlants/Areas/EditArea/EditArea';
import { LawnAreas } from 'pages/data/GreenPlants/Areas/LawnAreas/LawnAreas';
import { SatementsList } from 'pages/data/GreenPlants/StatementsList';
import { WithDidMountResetUploadFile } from 'features/UploadedFileProvider/hocs';
import { PlatformList } from '../data/GreenPlants/platformList';

const GreenPlantsApiRootPath = '/greenplants/v10/greenplants';
const GreenPlantsRootPath = '/data/greenplants';

const createGreenPlantsEditUrl = (type: keyof typeof GreenPlantsFormTypes, id: string) =>
  `${GreenPlantsRootPath}/${type}/edit/${id}`;

const createGreenPlantsAddUrl = (type: keyof typeof GreenPlantsFormTypes) => `${GreenPlantsRootPath}/${type}/add/`;
const GreenPlantsStatementsUrl = `${GreenPlantsRootPath}/statements`;
const GreenPlantsPlatformUrl = `${GreenPlantsRootPath}/platforms`;
const GreenPlantsFloraUrl = `${GreenPlantsStatementsUrl}/flora`;

const GreenPlantsLawnsUrl = `${GreenPlantsStatementsUrl}/lawns`;
const GreenPlantsAreasFlora = `${GreenPlantsPlatformUrl}/flora/areas`;
const GreenPlantsAreasLawns = `${GreenPlantsPlatformUrl}/lawns/areas`;
const GreenPlantsRoutes: React.FC = () => (
  <>
    <Route exact path={GreenPlantsFloraUrl} render={() => <WithDidMountResetUploadFile Component={Flora} />} />
    <Route exact path={GreenPlantsLawnsUrl} render={() => <WithDidMountResetUploadFile Component={Lawns} />} />
    <Route exact path={GreenPlantsAreasFlora} render={() => <WithDidMountResetUploadFile Component={FloraAreas} />} />
    <Route exact path={GreenPlantsAreasLawns} render={() => <WithDidMountResetUploadFile Component={LawnAreas} />} />
    <Route
      exact
      path={`${GreenPlantsRootPath}/lawns/add`}
      render={() => <WithDidMountResetUploadFile Component={AddLawns} />}
    />
    <Route
      exact
      path={`${GreenPlantsRootPath}/flora/add`}
      render={() => <WithDidMountResetUploadFile Component={AddFlora} />}
    />
    <Route
      exact
      path={`${GreenPlantsRootPath}/area/add`}
      render={() => <WithDidMountResetUploadFile Component={AddArea} />}
    />
    <Route
      exact
      path={`${GreenPlantsRootPath}/flora/edit/:registryId`}
      render={() => <WithDidMountResetUploadFile Component={EditFlora} />}
    />
    <Route
      exact
      path={`${GreenPlantsRootPath}/lawns/edit/:id`}
      render={() => <WithDidMountResetUploadFile Component={EditLawns} />}
    />
    <Route exact path={`${GreenPlantsRootPath}/areas/:type/edit/:id`} component={EditArea} />
    <Route exact path={GreenPlantsRootPath} component={GreenPlantsList} />
    <Route exact path={GreenPlantsStatementsUrl} component={SatementsList} />
    <Route exact path={GreenPlantsPlatformUrl} component={PlatformList} />
  </>
);

export {
  GreenPlantsRootPath,
  GreenPlantsFloraUrl,
  GreenPlantsLawnsUrl,
  GreenPlantsApiRootPath,
  GreenPlantsRoutes,
  createGreenPlantsEditUrl,
  createGreenPlantsAddUrl,
  GreenPlantsStatementsUrl,
  GreenPlantsAreasLawns,
  GreenPlantsAreasFlora,
  GreenPlantsPlatformUrl,
};
