import { ParameterType } from 'pages/data/PGR/types';

const typeToString = (type: ParameterType) => {
  switch (type) {
    case 'LESS':
      return '<';
    case 'MORE':
      return '>';
    case 'ABS':
    default:
      return '';
  }
};

export const addTypeToValue = (type: ParameterType, value: string | number) => typeToString(type) + value;

export const extractType = (value: string | null): [ParameterType, string | null] => {
  let type: ParameterType = 'ABS';
  if (value && value.length > 0) {
    const firstSymbol = value[0];
    switch (firstSymbol) {
      case '>':
        type = 'MORE';
        value = value.slice(1);
        break;
      case '<':
        type = 'LESS';
        value = value.slice(1);
        break;
    }
  }

  return [type, value];
};

export const splitTypesAndValues = (values: { value: string; }[]): { value: number | null, type: ParameterType }[] =>
  values.map(
    (v) => {
      const [type, value] = extractType(v.value);
      return {
        ...v,
        value: value !== null ? parseFloat(value) : value,
        type,
      };
    },
  );
