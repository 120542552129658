import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router';
import { FormikProvider, useFormik } from 'formik';

import { useNotifications } from 'hooks/useNotifications';
import { useRequest } from 'hooks/useRequest';
import { createTouchedErrors } from 'features/create-touched-errors';
import { ERROR_MESSAGES, SUCCESS_MESSAGES } from 'constants/messages';
import { Loader } from 'components/common';

import { NoisePackageFormFields } from 'pages/data/MASKSH/Data/Noise/NoisePackageForm/Form';
import { MASKSHApiRootPackageNoise, MASKSHNoise } from 'pages/data/MASKSH/consts';
import { useGetNoisePackage, useMASKSHDictionary } from 'pages/data/MASKSH/hooks';
import { initialEmptyValues } from 'pages/data/MASKSH/Data/Noise/NoisePackageForm/configs';
import { useGoBackWithFallback } from 'lib/utils/goBackWithFallback';
import { noiseSchema } from 'models/schemas/MASKSH/noiseSchema';
import { replaceEmptyStringsWithNull } from 'lib/utils/replaceEmptyStringsWithNull';

interface IProps {
  listPath?: string;
}

export const EditNoisePackageForm = ({ listPath = MASKSHNoise }: IProps) => {
  const { backWithFallback } = useGoBackWithFallback();
  const { isLoading, result } = useMASKSHDictionary('noise');
  const { successNotify, errorNotify } = useNotifications();
  const { packageId } = useParams<{ packageId: string }>();
  const { isLoading: isLoadingPackage, result: editedObject, error } = useGetNoisePackage(packageId);

  useEffect(() => {
    if (!packageId || error) {
      errorNotify({
        key: 'noisePackage/add/error',
        message: ERROR_MESSAGES.GET,
      });
      backWithFallback(listPath);
    }
  }, [error, errorNotify, packageId, listPath, backWithFallback]);

  const onError = () => {
    errorNotify({
      key: 'noisePackage/add/error',
      message: ERROR_MESSAGES.EDIT,
    });
  };
  const onSuccess = () => {
    successNotify({
      key: 'noisePackage/add/success',
      message: SUCCESS_MESSAGES.EDIT,
    });
    backWithFallback(listPath);
  };

  const initialValues = useMemo(() => ({ ...initialEmptyValues, ...editedObject }), [editedObject]);

  const { put, isLoading: isPutLoading } = useRequest(MASKSHApiRootPackageNoise, onSuccess, onError);

  const useFormikProps = useMemo(
    () => ({
      enableReinitialize: true,
      validateOnChange: true,
      validateOnMount: true,
      validateOnBlur: true,
      initialValues,
      validationSchema: noiseSchema,
      onSubmit: (values: any) => {
        const { errors, ...payload } = values;
        void put(replaceEmptyStringsWithNull(payload));
      },
    }),
    [initialValues, put],
  );

  const formik = useFormik(useFormikProps);

  return (
    <div>
      {isLoadingPackage || isLoading || isPutLoading ? (
        <Loader />
      ) : (
        <FormikProvider
          value={{
            ...formik,
            errors: createTouchedErrors(formik.errors, formik.touched),
          }}
        >
          <NoisePackageFormFields
            listPath={listPath}
            isLoading={isLoading || isLoadingPackage || isPutLoading}
            dictionaries={result}
            isEditMode
          />
        </FormikProvider>
      )}
    </div>
  );
};
