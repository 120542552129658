import { Button, DateInput, FormWrapper, Input } from 'components/common';
import { Box, Flex, Grid, Heading } from '@chakra-ui/react';
import { Field, FieldArray, useFormikContext } from 'formik';
import React from 'react';
import { NSIEmissionsListItem } from '../../consts/types';
import { createDateFromResponse, createDateToRequestWithTimezone } from 'lib/create-date';
import { AddIcon, BlockIcon, DeleteIcon, ReloadIcon } from 'components/icons';
import { get } from 'lodash';
import { generateUUID } from 'lib/utils/generateUUID';

export const ChimneyTab: React.FC = ({ children }) => {
  const { setFieldValue, values, errors, touched } = useFormikContext<NSIEmissionsListItem>();

  return (
    <Box flexDirection="column">
      <FormWrapper>
        <FieldArray name="flues">
          {({ remove, push }) => (
            <Box flexDirection="column" width="100%">
              {values?.flues?.map((item, index) => {
                const isDisabled = item.status === 'DISABLED';
                return (
                  <Grid key={item.id || generateUUID()} marginBottom={10} gap={5}>
                    <Flex alignItems="center">
                      <Heading size="md">{`Дымоход ${index + 1}`}</Heading>
                      {item.canDeleted ? (
                        <Button
                          marginLeft="10px"
                          alignSelf="left"
                          marginRight="auto"
                          leftIcon={<DeleteIcon />}
                          variant="ghost"
                          color="PrimaryButton.500"
                          size="sm"
                          p={0}
                          onClick={() => remove(index)}
                        />
                      ) : null}
                      {item.canRestore || item.deleted ? (
                        <Button
                          marginLeft="10px"
                          alignSelf="left"
                          marginRight="auto"
                          leftIcon={<ReloadIcon />}
                          variant="ghost"
                          color="PrimaryButton.500"
                          size="sm"
                          p={0}
                          onClick={() => {
                            setFieldValue(`flues[${index}].status`, 'ACTIVE');
                            setFieldValue(`flues[${index}].canRestore`, false);
                          }}
                        >
                          Восстановить
                        </Button>
                      ) : null}
                      {!item.canRestore && !item.canDeleted ? (
                        <Button
                          marginLeft="10px"
                          alignSelf="left"
                          marginRight="auto"
                          leftIcon={<BlockIcon />}
                          variant="ghost"
                          color="PrimaryButton.500"
                          size="sm"
                          p={0}
                          onClick={() => {
                            setFieldValue(`flues[${index}].status`, 'DISABLED');
                            setFieldValue(`flues[${index}].canRestore`, true);
                          }}
                        >
                          Заблокировать
                        </Button>
                      ) : null}
                    </Flex>
                    <Field
                      as={Input}
                      name={`flues[${index}].number`}
                      label="Номер"
                      disabled={isDisabled}
                      error={touched?.flues?.[index]?.number && get(errors?.flues?.[index], 'number')}
                      isRequired
                    />
                    <Field
                      as={Input}
                      name={`flues[${index}].name`}
                      label="Наименование"
                      disabled={isDisabled}
                      error={touched?.flues?.[index]?.name && get(errors?.flues?.[index], 'name')}
                      isRequired
                    />
                    <Field as={Input} name={`flues[${index}].description`} label="Примечание" disabled={isDisabled} />
                    <Field
                      as={Input}
                      name={`flues[${index}].shortName`}
                      label="Краткое наименование (код нативный)"
                      disabled={isDisabled}
                      error={touched?.flues?.[index]?.shortName && get(errors?.flues?.[index], 'shortName')}
                      isRequired
                    />
                    <DateInput
                      name={`flues[${index}].startDate`}
                      label="Начало действия"
                      value={createDateFromResponse(values.flues[index].startDate)}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setFieldValue(`flues[${index}].startDate`, createDateToRequestWithTimezone(e.target.value))
                      }
                      isDisabled={isDisabled}
                      error={touched?.flues?.[index]?.startDate ? get(errors?.flues?.[index], 'startDate') : undefined}
                      isRequired
                    />
                    <DateInput
                      name={`flues[${index}].stopDate`}
                      label="Окончание действия"
                      value={createDateFromResponse(values.flues[index].stopDate)}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setFieldValue(`flues[${index}].stopDate`, createDateToRequestWithTimezone(e.target.value))
                      }
                      isDisabled={isDisabled}
                      error={touched?.flues?.[index]?.stopDate ? get(errors?.flues?.[index], 'stopDate') : undefined}
                      isRequired
                    />
                  </Grid>
                );
              })}
              <Button
                alignSelf="left"
                marginRight="auto"
                marginBottom={10}
                leftIcon={<AddIcon />}
                variant="ghost"
                color="PrimaryButton.500"
                size="sm"
                p={0}
                onClick={() =>
                  push({
                    name: null,
                    shortName: null,
                    number: null,
                    startDate: null,
                    stopDate: null,
                    description: null,
                    deleted: false,
                    canDeleted: true,
                    canRestore: false,
                    status: 'ACTIVE',
                  })
                }
              >
                Добавить дымоход
              </Button>
              {children}
            </Box>
          )}
        </FieldArray>
      </FormWrapper>
    </Box>
  );
};
