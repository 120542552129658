import React, { ChangeEvent, useCallback, useEffect, useMemo } from 'react';
import { Field, useFormikContext } from 'formik';
import { DateInput, Input, Select } from 'components/common';
import { Grid } from '@chakra-ui/react';
import { useRequest } from 'hooks';
import { createDateToRequestWithTimezone } from 'lib/create-date';
import { SurfaceWaterLocales } from '../../locales';
import { ISurfaceWaterLoadData, SourceInfo } from '../../types';
import { apiPathSurfaceWater } from '../../apiPath';
import { DictionariesItem, TDictionaryItemDictionary } from 'models';
import { getFilteredOptionsByPrivileges } from 'features/getFilteredOptionsByPrivileges';

interface ICommonTab {
  isLoading?: boolean;
  isEditMode?: boolean;
  sources: TDictionaryItemDictionary[];
  waterObjects: TDictionaryItemDictionary[];
  organizations: DictionariesItem;
}

export const CommonTab = ({
  isLoading,
  isEditMode,
  sources,
  waterObjects,
  organizations,
}: ICommonTab) => {
  const { values, setFieldValue, errors, setFieldTouched } = useFormikContext<ISurfaceWaterLoadData>();

  const filteredWaterObjects = useMemo(
    () => getFilteredOptionsByPrivileges(waterObjects, 'isEditable', 'value', values.waterObjectId),
    [values.waterObjectId, waterObjects],
  );
  const filteredSources = useMemo(
    () => getFilteredOptionsByPrivileges(sources, 'isEditable', 'value', values.sourceId),
    [sources, values.sourceId],
  );

  const sourcesOptions = useMemo(() => {
    if (values.waterObjectId) {
      return filteredSources?.filter((el) => el.parent === values.waterObjectId);
    }

    return filteredSources;
  }, [values.waterObjectId, filteredSources]);

  const { get } = useRequest<SourceInfo>(apiPathSurfaceWater.sourceInfo(values.sourceId || ''));

  const getSources = useCallback(async () => {
    const source: any = await get();
    const { region, latitude, longitude, district } = source;
    setFieldValue('region', region);
    setFieldValue('latitude', latitude);
    setFieldValue('longitude', longitude);
    setFieldValue('district', district);
  }, [get, setFieldValue]);

  useEffect(() => {
    if (values.sourceId) {
      getSources();
    }
  }, [values.sourceId, getSources]);

  return (
    <Grid gap={2} width="60%" marginLeft={10}>
      <Field
        as={Input}
        label={SurfaceWaterLocales.protocolNumber}
        name="protocolNumber"
        error={errors?.protocolNumber}
        disabled={isEditMode}
      />
      <Field
        as={DateInput}
        name="sampleDate"
        label={SurfaceWaterLocales.sampleDate}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          setFieldTouched(e.target.name);
          setFieldValue('sampleDate', createDateToRequestWithTimezone(e.target.value));
        }}
        showTimeInput
        isDisabled={isLoading || isEditMode}
        error={errors?.sampleDate}
      />
      {/* водный объект */}
      <Field
        as={Select}
        name="waterObjectId"
        label={SurfaceWaterLocales.waterObjectTypeId}
        options={filteredWaterObjects}
        isDisabled={isLoading || isEditMode}
        error={errors?.waterObjectId}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setFieldValue('sourceId', '');
          setFieldValue('region', '');
          setFieldValue('district', '');
          setFieldValue('waterObjectId', e.target.value);
        }}
      />
      {/* место отбора пробы, которое зависит от водного объекта */}
      <Field
        as={Select}
        name="sourceId"
        label={SurfaceWaterLocales.source}
        options={sourcesOptions}
        isDisabled={isLoading || isEditMode}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          if (!e.target.value) {
            setFieldValue('region', '');
            setFieldValue('district', '');
          }
          setFieldValue('sourceId', e.target.value);
        }}
        error={errors?.sourceId}
      />

      {/* тут инпуты disabled для адреса */}
      <Field
        disabled
        as={Input}
        label={SurfaceWaterLocales.region}
        name="region"
      />
      {/* конец тут инпуты disabled для адреса */}
      <Field
        disabled
        as={Input}
        label={SurfaceWaterLocales.district}
        name="district"
      />
      <Field
        as={Select}
        name="consumerId"
        label={SurfaceWaterLocales.consumerId}
        options={organizations}
        isDisabled={isLoading}
      />
      <Field
        as={Select}
        name="organizationId"
        label={SurfaceWaterLocales.organizationId}
        options={organizations}
        isDisabled={isLoading}
      />
    </Grid>
  );
};
