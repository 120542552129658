import { createTouchedErrors } from 'features/create-touched-errors';
import { FormikProvider, useFormik } from 'formik';
import { useRequest } from 'hooks/useRequest';
import { useEnhancedNotification } from 'hooks';
import NSIReagentsGroundFormFields
  from 'pages/dictionaries/NSIReagents/NSIReagentsGround/NSIReagentsGroundForms/NSIReagentsGroundFormFields';
import { useDictionary } from 'hooks/useDictionary';
import { NSIReagentsDictionaryKeys, NSIReagentsListType } from 'pages/dictionaries/NSIReagents/types';
import { NSIReagentsApiPath, NSIReagentsGroundPaths } from 'pages/dictionaries/NSIReagents/paths';
import initialNSIReagentsGroundEmptyValues
  from 'pages/dictionaries/NSIReagents/NSIReagentsGround/NSIReagentsGroundForms/initialNSIReagentsGroundEmptyValues';
import { NsiReagentsPgrSchema } from 'models/schemas/nsiReagents/nsiReagentsPgrSchema';
import { useGoBackWithFallback } from 'lib/utils/goBackWithFallback';

const NSIReagentsGroundAdd = () => {
  const { backWithFallback } = useGoBackWithFallback();
  const { onSuccessCreate, onErrorCreate } = useEnhancedNotification();

  const { isDictionariesLoading, dictionaries } = useDictionary<NSIReagentsDictionaryKeys>({
    url: `${NSIReagentsApiPath}/dictionaries`,
  });

  const onSuccess = () => {
    onSuccessCreate();
    backWithFallback(NSIReagentsGroundPaths.root);
  };

  const { post, isLoading } = useRequest(`${NSIReagentsApiPath}/create`, onSuccess, onErrorCreate);

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    initialValues: initialNSIReagentsGroundEmptyValues,
    validationSchema: NsiReagentsPgrSchema,
    onSubmit: (values) => post({ ...values, type: NSIReagentsListType.pgr }),
  });

  return (
    <FormikProvider
      value={{
        ...formik,
        errors: createTouchedErrors(formik.errors, formik.touched),
      }}
    >
      <NSIReagentsGroundFormFields
        dictionaries={dictionaries}
        onCancel={() => {
          backWithFallback(NSIReagentsGroundPaths.root);
        }}
        isLoading={isDictionariesLoading || isLoading}
      />
    </FormikProvider>
  );
};

export default NSIReagentsGroundAdd;
