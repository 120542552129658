import { As, FormControlProps, forwardRef, } from '@chakra-ui/react';
import React, { memo, useCallback } from 'react';
import { FormikFieldProps } from '../types';
import { Input, InputProps } from './input';

export const CommaNumberInput = memo(
  forwardRef<
    InputProps & Partial<FormikFieldProps> & { disabled?: boolean; isRequired?: FormControlProps['isRequired']; mask?: string },
    As<InputProps & Partial<FormikFieldProps>>>(
    (
      props,
      ref,
    ) => {
      const handleChange = useCallback(
        (innerValue: string) => {
          const value = innerValue.replace(/[^0-9.,]/g, '');
          if (props.onChange) {
            props.onChange({
              target: { value: value.replace(/,+/, '.'), name: props.name },
            } as React.BaseSyntheticEvent);
          }
        },
        [props],
      );

      return (
        <Input
          ref={ref}
          {...props}
          value={props.value ? String(props.value).replace(/\.+/, ',') : ''}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e.target.value)}
        />
      );
    },
  ),
);
