import * as yup from 'yup';

export const yearValidationSchema = yup.object().shape({
  year: yup.string().typeError('Необходимо указать год').required('Необходимо указать год'),
});

export const yearAndMonthValidationSchema = yup.object().shape({
  year: yup.string().typeError('Необходимо указать год').required('Необходимо указать год'),
  month: yup.string().typeError('Необходимо указать месяц').required('Необходимо указать месяц'),
});
