import { createTouchedErrors } from 'features/create-touched-errors';
import { FormikProvider, useFormik } from 'formik';
import { useRequest } from 'hooks/useRequest';
import { useEnhancedNotification } from 'hooks';
import { useDictionary } from 'hooks/useDictionary';
import { NSIReagentsDictionaryKeys, NSIReagentsListType } from 'pages/dictionaries/NSIReagents/types';
import { NSIReagentsApiPath, NSIReagentsLawnsPaths } from 'pages/dictionaries/NSIReagents/paths';
import initialNSIReagentsLawnsEmptyValues
  from 'pages/dictionaries/NSIReagents/NSIReagentsLawns/NSIReagentsLawnsForms/initialNSIReagentsLawnsEmptyValues';
import NSIReagentsLawnsFormFields
  from 'pages/dictionaries/NSIReagents/NSIReagentsLawns/NSIReagentsLawnsForms/NSIReagentsLawnsFormFields';
import { NsiReagentsLawnsSchema } from 'models/schemas/nsiReagents/nsiReagentsLawnsSchema';
import { useGoBackWithFallback } from 'lib/utils/goBackWithFallback';

const NSIReagentsLawnsAdd = () => {
  const { backWithFallback } = useGoBackWithFallback();
  const { onSuccessCreate, onErrorCreate } = useEnhancedNotification();

  const { isDictionariesLoading, dictionaries } = useDictionary<NSIReagentsDictionaryKeys>({
    url: `${NSIReagentsApiPath}/dictionaries`,
  });

  const onSuccess = () => {
    onSuccessCreate();
    backWithFallback(NSIReagentsLawnsPaths.root);
  };

  const { post, isLoading } = useRequest(`${NSIReagentsApiPath}/create`, onSuccess, onErrorCreate);

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    initialValues: initialNSIReagentsLawnsEmptyValues,
    validationSchema: NsiReagentsLawnsSchema,
    onSubmit: (values) => post({ ...values, type: NSIReagentsListType.lawn }),
  });

  return (
    <FormikProvider
      value={{
        ...formik,
        errors: createTouchedErrors(formik.errors, formik.touched),
      }}
    >
      <NSIReagentsLawnsFormFields
        dictionaries={dictionaries}
        onCancel={() => {
          backWithFallback(NSIReagentsLawnsPaths.root);
        }}
        isLoading={isDictionariesLoading || isLoading}
      />
    </FormikProvider>
  );
};

export default NSIReagentsLawnsAdd;
