import { useRequest } from 'hooks';
import { useCallback, useEffect } from 'react';
import { TFilterDTO } from 'models';

const rootURL = '/admin/v10/ux-metadata';

export type FilterType<T> = {
  data: TFilterDTO<keyof T>;
};

export function useSavedFilters<T>(subsystem: string, module: string, key?: string) {
  const { get, post, result: filters, isLoading: isFiltersLoading } = useRequest<FilterType<T>>(rootURL);
  useEffect(() => {
    void get({
      params: {
        subsystem,
        module,
        key: key || 'default'
      },
    });
  }, [get, module, key, subsystem]);

  const saveFilters = useCallback(
    (filters: FilterType<T> | null) => {
      post(filters, {
        params: {
          subsystem,
          module,
          key: key || 'default',
        },
      });
    },
    [post, module, key, subsystem]
  );

  return {
    filters,
    saveFilters,
    isFiltersLoading,
  };
}
