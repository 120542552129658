import { Button, FormHeader } from 'components/common';
import { Flex } from '@chakra-ui/react';

export const UserSyncForm = () => (
  <Flex>
    <FormHeader url="/admin/user" header="Синхронизация пользователей с WSO2" />
    <Button variant="ghost" size="sm" onClick={() => {}} p={0}>
      Отмена
    </Button>
    <Button variant="ghost" color="PrimaryButton.500" size="sm" onClick={() => {}} p={0}>
      Сохранить
    </Button>
  </Flex>
);
