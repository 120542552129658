import { Box, Flex, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { AttachmentsTabContent, Button, DefaultText, FormHeader, FormWrapper } from 'components/common';
import { InclinometerSaveItem } from 'hooks/geo/Inclinometer/useGeoInclinometerList';
import React, { useMemo } from 'react';
import { CommonTab } from './CommonTab';
import { ParametersTab } from './ParametersTab';
import { useFormikContext } from 'formik';
import { useObjectType } from 'hooks/useObjectType';
import appLocale from 'constants/appLocale';
import { FieldFormProps } from 'types/forms';
import subsystemIdsMap from 'constants/subsystemIdsMap';
import { useAvailability } from 'hooks';

const FieldsForm = ({ onCancel, dictionaries, media, onMediaChange, uploadItemId, isEditMode }: FieldFormProps) => {
  const { isAvailable } = useAvailability(subsystemIdsMap.geoecologyRoot);

  const { values, handleSubmit } = useFormikContext<InclinometerSaveItem>();
  const objectType = useObjectType(dictionaries);

  const submitIsDisabled = useMemo(
    () => [...values.data].filter((val) => !!val.accuracy || !!val.xOffset || !!val.yOffset).length === 0,
    [values.data],
  );

  const renderControls = useMemo(
    () => (
      <Flex>
        <Button onClick={onCancel} marginRight={20}>
          Отмена
        </Button>
        <Button isDisabled={!isAvailable || submitIsDisabled} onClick={() => handleSubmit()} variant="solid">
          Сохранить
        </Button>
      </Flex>
    ),
    [handleSubmit, onCancel, submitIsDisabled, isAvailable],
  );

  return (
    <Box flexDirection="column">
      <Tabs>
        <FormHeader
          onBackClick={onCancel}
          header={!isEditMode ? 'Добавление результатов обследования' : 'Редактирование результатов обследования'}
        >
          <TabList>
            <Tab>
              <DefaultText>{appLocale.common.generalInformation}</DefaultText>
            </Tab>
            <Tab isDisabled={!values.pointId}>
              <DefaultText>{appLocale.common.indicators}</DefaultText>
            </Tab>
            <Tab isDisabled={!values.pointId}>
              <DefaultText>{appLocale.common.attachments}</DefaultText>
            </Tab>
          </TabList>
        </FormHeader>
        <TabPanels>
          <TabPanel>
            <CommonTab dictionaries={dictionaries} />
          </TabPanel>
          <TabPanel>
            <ParametersTab isWrite={isAvailable} dictionaries={dictionaries}>
              {renderControls}
            </ParametersTab>
          </TabPanel>

          <TabPanel>
            <FormWrapper>
              {isAvailable && (
                <AttachmentsTabContent
                  objectType={objectType}
                  media={media}
                  onMediaChange={onMediaChange}
                  uploadItemId={uploadItemId}
                >
                  {renderControls}
                </AttachmentsTabContent>
              )}
            </FormWrapper>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default React.memo(FieldsForm);
