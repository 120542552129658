import appLocale from 'constants/appLocale';

export const PDV_ApiPath = 'industrialemissions/v10/pdv';

export const Industrial_PDV_RootPath = '/data/industrial-emissions/PDV';
export const Industrial_PDV_AddPath = `${Industrial_PDV_RootPath}/add`;
export const Industrial_PDV_EditPath = `${Industrial_PDV_RootPath}/edit`;

export const Industrial_PDV_Breadcrumbs = [
  { breadcrumb: appLocale.common.data, path: '/data' },
  {
    breadcrumb: 'Мониторинг пром. выбросов',
    path: '/data/industrial-emissions',
  },
  {
    breadcrumb: 'ПДВ по источникам выбросов',
    path: Industrial_PDV_RootPath,
  },
];
