import appLocale from 'constants/appLocale';
import { LoaderItem, SubsystemHeader } from 'components/common';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useHandleDeleteData, useList } from 'hooks';
import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import {
  getSelectOptionsFromDictionaryByName,
} from 'features/get-select-options-from-dictionary';
import { IFilterItem } from 'models';
import { useHistory } from 'react-router-dom';
import { IDay } from '../../../citizenAppeal/RaidJournal/types';
import { PELAtmoAdd, PELNoiseAdd } from '../../constants';
import { Box } from '@chakra-ui/react';
import { PELRaidsFilter } from 'pages/pel/Raids/PELRaidsJournal/PELRaidsFilter';
import { useDictionary } from 'hooks/useDictionary';
import { MaterialTable } from '../../../../features/material-table-old';
import { Column } from '@material-table/core';
import { PELTaskLine } from '../../../citizenAppeal/RaidJournal/RaidTable/TableFragments/PELTaskLine';
import { addFiltersToColumns, ColumnWithFilter } from '../../../../lib/utils/tableFilter';
import { TableFilterTypeEnum } from '../../../../components/table/filters/TableFilter';
import { FullPageWrapper } from '../../../../components/table/FullPageWrapper';
import { subsystemCodesMap } from 'constants/subsystemIdsMap';
import { useCustomRights } from 'hooks/useCustomRights';

interface IProps {
  noise?: boolean;
  breadcrumb: { breadcrumb: string; path: string }[];
}

export const PELRaidsJournal = ({ noise, breadcrumb }: IProps) => {
  const { isWrite, isExecute } = useCustomRights(
    noise ? subsystemCodesMap.PEL.pelNoise : subsystemCodesMap.PEL.pelAtmo,
  );
  useUpdateBreadcrumbs(breadcrumb);

  const history = useHistory();

  const { setFilters, onPageChange, refetch, filterDTO, response, toggleOrder, downloadAsFile, isLoading } =
    useList<IDay>('/appeal/v10/raids/list', {
      preventFirstFetch: true,
      initialOrderFieldKey: '-startDate',
      downloadUrl: '/appeal/v10/raids/list/pel-export',
      saveFiltersState: true,
    });

  const { isDictionariesLoading, dictionaries: tasksDictionaries } = useDictionary({
    url: '/appeal/v10/tasks/dictionaries',
  });

  const taskResearchTypes = useMemo(
    () => getSelectOptionsFromDictionaryByName(tasksDictionaries?.data, 'taskResearchTypes'),
    [tasksDictionaries],
  );

  const noiseId = taskResearchTypes.find((type) => type.name === 'Шум')?.value;
  const atmoID = taskResearchTypes.find((type) => type.name === 'Воздух')?.value;

  useEffect(() => {
    if (noiseId && atmoID) {
      setFilters([
        ...filterDTO.filters,
        {
          key: 'typeId',
          operation: 'EQ',
          value: noise ? noiseId : atmoID,
        },
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [atmoID, noiseId, noise]);

  const onSubmit = (values: { [s: string]: IFilterItem }) => {
    const filters = Object.values(values);
    setFilters(filters);
  };

  const { handleOpenModal, ModalComponent } = useHandleDeleteData({ refetch });

  const renderTask = useCallback(
    (task, last) => (
      <Box
        borderBottom={last ? 'none' : '1px solid #E2E8F0'}
        minWidth="74px"
        display="flex"
        overflow="visible"
        flexShrink={0}
        alignItems="center"
      >
        <Box width="90px" pr={8} flexShrink={0}>
          {task.number}
        </Box>
        <Box width="90px" pr={8} flexShrink={0}>
          {task.districtNames?.join(',')}
        </Box>
        <Box width="150px" pr={8} flexShrink={0}>
          {task.regionNames?.join(',')}
        </Box>
        <Box width="146px" pr={8} flexShrink={0}>
          {task.territoryName}
        </Box>
        <Box width="140px" pr={8} flexShrink={0}>
          {task.reason}
        </Box>
        <Box width="140px" flexShrink={0} pr={8}>
          {task.statusName}
        </Box>
        <Box width="270px" flexShrink={0}>
          <PELTaskLine
            isWrite={isWrite}
            noise={noise}
            tasksDictionaries={tasksDictionaries}
            refetch={refetch}
            task={task}
            count={1}
            handleOpenModal={handleOpenModal}
          />
        </Box>
      </Box>
    ),
    [isWrite, noise, tasksDictionaries, refetch, handleOpenModal],
  );

  const renderRaid = useCallback(
    (raid) => (
      <Box width="100%" display="flex" overflow="visible" alignItems="center">
        <Box width="100%" flexShrink={0}>
          {raid.shift}
        </Box>
        <Box width="74px" ml="18px" flexShrink={0}>
          {raid.number}
        </Box>
        <Box display="flex" flexDirection="column" flexShrink={0} width="fit-content">
          {raid.tasks.map((item: any, index: number) => renderTask(item, index === raid.tasks.length - 1))}
        </Box>
      </Box>
    ),
    [renderTask],
  );

  const columns = useMemo<ColumnWithFilter<any>[]>(
    () => [
      {
        field: 'day',
        title: 'День недели',
        filterType: TableFilterTypeEnum.string,
      },
      {
        field: 'startDate',
        title: 'Дата',
        render: (row) => new Date(row.date).toLocaleDateString(),
      },
      {
        field: 'shift',
        title: 'Время суток',
        minWidth: 74,
        render: (row) => <>{row.raids.map(renderRaid)}</>,
        filterType: TableFilterTypeEnum.string,
        sorting: false,
      },
      {
        field: 'raidNumber',
        title: '№ рейда',
        width: 74,
        render: () => null,
        filterType: TableFilterTypeEnum.number,
        sorting: false,
      },
      {
        field: 'number',
        title: '№ задания',
        width: 90,
        render: () => null,
        filterType: TableFilterTypeEnum.number,
        sorting: false,
      },
      {
        field: 'districtName',
        title: 'Округ',
        width: 90,
        render: () => null,
        filterType: TableFilterTypeEnum.string,
        sorting: false,
      },
      {
        field: 'regionName',
        title: 'Район',
        width: 150,
        render: () => null,
        filterType: TableFilterTypeEnum.string,
        sorting: false,
      },
      {
        field: 'territoryName',
        title: 'Территория',
        width: 146,
        render: () => null,
        filterType: TableFilterTypeEnum.string,
        sorting: false,
      },
      {
        field: 'reasonName',
        title: 'Основание',
        width: 140,
        render: () => null,
        filterType: TableFilterTypeEnum.string,
        sorting: false,
      },
      {
        field: 'statusName',
        title: 'Статус',
        width: 140,
        render: () => null,
        filterType: TableFilterTypeEnum.string,
        sorting: false,
      },
      {
        field: 'actions',
        title: '',
        width: 270,
        render: () => null,
        sorting: false,
      },
    ],
    [renderRaid],
  );

  const columnsWithFilters = useMemo<Array<Column<any>>>(
    () => addFiltersToColumns({ filters: filterDTO.filters, setFilters, columns }),
    [columns, filterDTO.filters, setFilters],
  );

  if (!atmoID || !noiseId || isDictionariesLoading) {
    return <LoaderItem />;
  }

  return (
    <FullPageWrapper>
      <SubsystemHeader
        title={noise ? appLocale.pel.noiseRaidList : appLocale.pel.atmosphereRaidList}
        addButtonProps={{
          onClick: () => history.push(noise ? PELNoiseAdd : PELAtmoAdd),
          isDisabled: !isWrite,
        }}
        filtersComponent={
          <PELRaidsFilter
            typeId={noise ? noiseId : atmoID}
            onSubmit={onSubmit}
            dictionaries={tasksDictionaries}
            filters={filterDTO.filters}
          />
        }
      />

      <Box width="100%" flexGrow={1} minHeight={0}>
        <MaterialTable
          isNotExecute={isExecute}
          data={response?.data}
          meta={response?.meta}
          toggleOrder={toggleOrder}
          onPageChange={onPageChange}
          filterDTO={filterDTO}
          columns={columnsWithFilters}
          isLoading={isLoading || isDictionariesLoading}
          options={{
            selection: false,
            tableLayout: 'fixed',
          }}
          downloadAsFile={downloadAsFile}
        />
        {ModalComponent}
      </Box>
    </FullPageWrapper>
  );
};
