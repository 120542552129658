import { useMemo } from 'react';
import { IChartData, IChartOptionsScales, IFilterValues } from '../../types';

interface Interface {
  data: IChartData | null;
  filters: IFilterValues;
}
export const useYScales = ({ data }: Interface): IChartOptionsScales => {
  const yScales = useMemo(() => {
    const result = {};

    if (data?.datasets) {
      data.datasets.forEach((dataset) => {
        //  либа не подразумевает расширения, поэтому кастомные свойства
        // приходится так оформлять
        // @ts-ignore
        if (!result[dataset.yAxisID]) {
          // @ts-ignore
          result[dataset.yAxisID] = {
            display: 'auto',
            type: 'linear',
            title: {
              display: true,
              color: dataset.borderColor,
              // @ts-ignore
              text: `${dataset?.paramName ? `${dataset.paramName} | ` : ''}${dataset?.paramUnit ?? ''}`,
            },
          };
        }
      });
    }

    return result;
  }, [data]);

  return yScales;
};
