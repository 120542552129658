import { useRequest } from 'hooks/useRequest';
import { useEffect } from 'react';
import { ForecastBaseURL } from 'pages/data/Meteo/menu';

export function useForecastRecord(id: string) {
  const { get, isLoading, result, error } = useRequest(`${ForecastBaseURL}/${id}`);

  useEffect(() => {
    get();
  }, [get]);

  return { isLoading, result, error };
}
