import { Field, FormikProvider, useFormik } from 'formik';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Flex, Grid } from '@chakra-ui/react';
import { DateInput, IFilterValues, Select, useSetFiltersFromGraphic } from 'components/common';
import { createDateFromResponse, createDateToRequestForFilter, createDateToRequestWithTimezone } from 'lib/create-date';
import { TDictionariesArray, TDictionaryItemDictionary } from 'models/dictionaries';
import { getSelectOptionsFromDictionaryByName } from 'features/get-select-options-from-dictionary';
import { fieldsLocale } from '../consts';
import { IFilterItem } from 'models';
import combineFilters from 'lib/utils/combineFilters';
import { IPeriod } from 'features/PeriodsButtons/types';
import { PeriodsButtons } from 'features/PeriodsButtons';
import { usePeriods } from 'features/PeriodsButtons/usePeriods';
import { FiltersDatepickersSchema } from 'models/schemas/filtersDatepickersSchema/FiltersDatepickersSchema';
import FilterFormControls from 'components/form/FilterFormControls';

interface FilterProps {
  isLoading?: boolean;
  isDisabled?: boolean;
  filtersFromGraphic: Partial<IFilterValues> | null;
  setFiltersFromGraphic: (value: Partial<IFilterValues> | null) => void;
  dictionaries: {
    data: TDictionariesArray;
  } | null;
  onSubmit: (values: any) => void;
  filters: IFilterItem[];
  periods: IPeriod[] | null;
}

const initialValues: Record<string, IFilterItem> = {
  dateTimeMin: {
    key: 'selectionDate',
    operation: 'GTE',
    value: null,
  },
  dateTimeMax: {
    key: 'selectionDate',
    operation: 'LTE',
    value: null,
  },
  okrug: {
    key: 'okrugId',
    operation: 'IN',
    value: null,
  },
  rayon: {
    key: 'rayonId',
    operation: 'IN',
    value: null,
  },
  sourceNameId: {
    key: 'sourceNameId',
    operation: 'IN',
    value: null,
  },
  // showDisabled - value равно '' если чекбокс включен и нужно получить с Backend все значения (этот объект удалиться при отправке)
  // value равно false - если нужно показать только неудаленные записи
  showDisabled: {
    key: 'deleted',
    operation: 'EQ',
    value: false,
  },
  periodId: {
    key: 'periodId',
    operation: 'EQ',
    value: null,
  },
};

export const VegetativeFilter: React.FC<FilterProps> = ({
  isLoading,
  isDisabled,
  onSubmit,
  dictionaries,
  setFiltersFromGraphic,
  filtersFromGraphic,
  filters,
  periods,
  children,
  ...props
}) => {
  const savedPeriodId = useMemo(
    () => (filters.find((item) => item.key === 'periodId')?.value as string) || '',
    [filters]
  );

  const [periodId, setPeriodId] = useState<string>(savedPeriodId);

  const { getDatesFromPeriod, dates } = usePeriods(periodId, periods);

  const submitPeriod = (id: string) => {
    const dates = getDatesFromPeriod(id);
    setFieldValue('periodId.value', id);
    setFieldValue('dateTimeMin.value', createDateToRequestWithTimezone(dates[0]));
    setFieldValue('dateTimeMax.value', createDateToRequestWithTimezone(dates[1]));
    setFieldTouched('dateTimeMin.value');
    setPeriodId(id);
  };

  const {
    values,
    errors,
    getFieldMeta,
    getFieldHelpers,
    getFieldProps,
    handleReset,
    submitForm,
    setFieldValue,
    setFieldTouched,
    ...formik
  } = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    validationSchema: FiltersDatepickersSchema([{ dateFromFieldName: 'dateTimeMin', dateToFieldName: 'dateTimeMax' }]),
    initialValues,
    onSubmit,
  });

  useEffect(() => {
    const initialFilters = combineFilters(initialValues, filters);
    Object.keys(initialFilters).forEach((key) => {
      setFieldValue(`${key}.value`, initialFilters[key].value);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const areas = useMemo(() => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'areas').map(
    ({ title: name, ...item }) => ({
      ...item,
      name: String(name),
    }),
  ), [dictionaries]);

  const okrugs = useMemo(() => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'okrugs'), [dictionaries]);
  const rayons = useMemo(() => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'rayons'), [dictionaries]);

  useSetFiltersFromGraphic({
    dateKeyMin: 'dateTimeMin.value',
    dateKeyMax: 'dateTimeMax.value',
    setFiltersFromGraphic,
    filtersFromGraphic,
    setFieldValue,
  });

  const filteredRayons = useMemo(() => {
    if (Array.isArray(values.okrug.value)) {
      return rayons.slice().filter((item) => (values.okrug.value as string[])?.includes(item.parent as string));
    }
    return rayons;
  }, [rayons, values.okrug.value]);

  const filteredAreas = useMemo(() => {
    if (Array.isArray(values.rayon.value)) {
      return areas.slice().filter((item) => (values.rayon.value as string[])?.includes(item.parent as string));
    }
    return areas;
  }, [areas, values.rayon.value]);

  const comparisonFunc = useCallback(
    (fieldName: string, editableValue, dependentField, dependentDict: TDictionaryItemDictionary[]) => {
      if (Array.isArray(editableValue) && editableValue.length && Array.isArray(dependentField)) {
        setFieldValue(
          fieldName,
          dependentField
            .slice()
            .filter((value) =>
              editableValue.includes(dependentDict.find((dictItem) => dictItem.value === value)?.parent as string))
        );
      } else {
        setFieldValue(fieldName, null);
      }
    },
    [setFieldValue]
  );

  useEffect(() => {
    comparisonFunc('rayon.value', values.okrug.value, values.rayon.value, rayons);
    // eslint-disable-next-line
  }, [values.okrug.value]);

  useEffect(() => {
    comparisonFunc('sourceNameId.value', values.rayon.value, values.sourceNameId.value, areas);
    // eslint-disable-next-line
  }, [values.rayon.value]);

  return (
    <FormikProvider
      value={{
        values,
        errors,
        getFieldMeta,
        getFieldHelpers,
        getFieldProps,
        handleReset,
        submitForm,
        setFieldValue,
        setFieldTouched,
        ...formik,
      }}
    >
      <Flex flexDirection="row" justifyContent="space-between">
        <Grid gap="12px" rowGap="8px" gridTemplateColumns="repeat(5, 1fr)" {...props}>
          <Field
            as={DateInput}
            maxDate={dates?.[1]}
            minDate={dates?.[0]}
            isDisabled={!periodId}
            name="dateTimeMin.value"
            label="От"
            value={typeof values.dateTimeMin?.value === 'string' && createDateFromResponse(values.dateTimeMin.value)}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFieldValue('dateTimeMin.value', createDateToRequestForFilter(e.target.value));
            }}
            showTimeInput={false}
            error={errors.dateTimeMin?.value}
          />
          <Field
            as={DateInput}
            maxDate={dates?.[1]}
            minDate={dates?.[0]}
            isDisabled={!periodId}
            name="dateTimeMax.value"
            label="До"
            value={typeof values.dateTimeMax?.value === 'string' && createDateFromResponse(values.dateTimeMax.value)}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFieldValue('dateTimeMax.value', createDateToRequestForFilter(e.target.value, 'end'));
            }}
            showTimeInput={false}
            error={errors.dateTimeMax?.value}
          />
          <Field as={Select} name="okrug.value" label={fieldsLocale.okrug} options={okrugs} multiple />
          <Field as={Select} name="rayon.value" label={fieldsLocale.rayon} options={filteredRayons} multiple />

          <Field
            multiple
            as={Select}
            name="sourceNameId.value"
            label={fieldsLocale.sourceName}
            options={filteredAreas}
          />
        </Grid>

        <FilterFormControls
          onReset={(e) => {
            handleReset(e);
            setPeriodId('');
            setTimeout(submitForm, 0);
          }}
          onSubmit={submitForm}
          isLoading={isLoading}
          isDisabled={isDisabled}
        />
      </Flex>
      {children}
      <PeriodsButtons periodId={periodId} periods={periods} submitPeriod={submitPeriod} />
    </FormikProvider>
  );
};
