import { Grid } from '@chakra-ui/react';
import { Field, FormikContextType, FormikErrors, FormikValues, useFormikContext } from 'formik';
import { DateInput, Input, Select } from 'components/common';
import React from 'react';
import { TDictionaryItemDictionary } from 'models';
import isRequiredInSchema from 'lib/utils/forms/isReqiuredInSchema';
import { nsiAtmosphericAirSchema } from 'models/schemas/nsi/nsiAtmosphericAirSchema';

interface IProps {
  statuses: TDictionaryItemDictionary[];
  types: TDictionaryItemDictionary[];
  stations: TDictionaryItemDictionary[];
  stationTypes: TDictionaryItemDictionary[];
  isEditMode?: boolean;
}

export const GeneralInformation = ({ statuses, types, stations, stationTypes, isEditMode }: IProps) => {
  const { errors, values, setFieldValue }: FormikContextType<FormikErrors<FormikValues>> = useFormikContext();

  return (
    <Grid gap={2} templateColumns="repeat(1, 450px)">
      <Field
        as={Input}
        name="name"
        label="Наименование станции"
        isRequired={isRequiredInSchema(nsiAtmosphericAirSchema, 'name')}
        error={errors?.name}
      />

      <Field
        as={Input}
        type="number"
        name="number"
        label="Номер станции"
        error={errors.number}
        isRequired={isRequiredInSchema(nsiAtmosphericAirSchema, 'number')}
        isDisabled={isEditMode}
      />

      <Field
        as={Select}
        name="stationType"
        label="Тип станции"
        options={stationTypes}
        error={errors?.stationType}
        onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
          setFieldValue('stationType', event.target.value);
          setFieldValue('step', '');
          setFieldValue('numberOfPoints', '');
        }}
        isRequired={isRequiredInSchema(nsiAtmosphericAirSchema, 'stationType')}
        isDisabled={isEditMode}
      />

      {values.stationType === 'PROFILEMER' && (
        <>
          <Field as={Input} name="step" label="Шаг" type="number" isDisabled={isEditMode} />
          <Field as={Input} name="numberOfPoints" label="Количество точек" type="number" isDisabled={isEditMode} />
        </>
      )}

      <Field
        as={Select}
        name="stationId"
        label="Станция (Инвентарный номер)"
        options={stations}
        error={errors?.stationId}
        isRequired={isRequiredInSchema(nsiAtmosphericAirSchema, 'stationId')}
      />

      <Field
        as={Select}
        name="areaTypeId"
        label="Тип обследуемой территории"
        options={types}
        error={errors?.areaTypeId}
        isRequired={isRequiredInSchema(nsiAtmosphericAirSchema, 'areaTypeId')}
      />

      <Field
        as={Select}
        name="status"
        label="Статус"
        options={statuses}
        error={errors?.status}
        isRequired={isRequiredInSchema(nsiAtmosphericAirSchema, 'status')}
      />

      <Field
        as={DateInput}
        name="startDate"
        label="Начало действия"
        error={errors?.startDate}
        isRequired={isRequiredInSchema(nsiAtmosphericAirSchema, 'startDate')}
      />

      <Field
        as={DateInput}
        name="stopDate"
        label="Окончания действия"
        error={errors?.stopDate}
        isRequired={isRequiredInSchema(nsiAtmosphericAirSchema, 'stopDate')}
      />
    </Grid>
  );
};
