import React from 'react';
import { MaterialTable } from 'features/material-table/MaterialTableMini';
import { DeviceModelParameter } from 'pages/equipment/Models/types';
import { parametersTableFields } from '../locale';
import { Checkbox } from 'components/common';
import { Field } from 'formik';

export interface DeviceModelParametersTableProps {
  data?: any;
  isLoading: boolean;
  onEdit: (item: DeviceModelParameter) => void;
  onDelete: (item: DeviceModelParameter) => void;
}

export const DeviceModelParametersTable: React.FC<DeviceModelParametersTableProps> = (props) => (
  <MaterialTable
    data={props.data}
    columns={[
      {
        title: parametersTableFields.parameterName,
        field: 'parameterName',
      },
      {
        title: parametersTableFields.parameterCode,
        field: 'parameterCode',
      },
      {
        title: parametersTableFields.unit,
        field: 'unit',
      },
      {
        title: parametersTableFields.accuracy,
        field: 'accuracy',
      },
      {
        title: parametersTableFields.averagePeriod,
        field: 'averagePeriod',
      },
      {
        title: parametersTableFields.genMin,
        field: 'genMin',
        render: (row) => (
          <Field
            as={Checkbox}
            checked={row.genMin}
            isDisabled
          />
        ),
      },
      {
        title: parametersTableFields.genMax,
        field: 'genMax',
        render: (row) => (
          <Field
            as={Checkbox}
            checked={row.genMax}
            isDisabled
          />
        ),
      },
      {
        title: parametersTableFields.purpose,
        field: 'purpose',
      },
    ]}
    actions={[
      {
        type: 'edit',
        onClick: (e, item) => {
          if (Array.isArray(item)) {
            return;
          }
          props.onEdit(item);
        },
      },
      {
        type: 'delete',
        onClick: (e, item) => {
          if (Array.isArray(item)) {
            return;
          }
          props.onDelete(item);
        },
      },
    ]}
    isLoading={props.isLoading}
    options={{
      selection: false,
    }}
  />
);
