import { Button, PageHeader } from 'components/common';
import { AddIcon } from 'components/icons';
import { useHistory } from 'react-router-dom';
import { memo } from 'react';
import { Flex, Heading } from '@chakra-ui/react';

interface RightHeaderProps {
  onOpenLoad(): void;
  url: string;
}

export const RightHeader: React.FC<RightHeaderProps> = memo(({ children, url }) => {
  const history = useHistory();

  const handleAddButtonClick = () => history.push(`${history.location.pathname}${url}`);

  return (
    <>
      <PageHeader>
        <Heading>Управление привилегиями</Heading>
        <Flex>
          <Button
            leftIcon={<AddIcon />}
            variant="ghost"
            color="PrimaryButton.500"
            size="sm"
            onClick={handleAddButtonClick}
            p={0}
          >
            Добавить запись
          </Button>
        </Flex>
      </PageHeader>
      {children}
    </>
  );
});
