import { Stack } from '@chakra-ui/react';
import { Input, Select } from 'components/common';
import { Field, useFormikContext } from 'formik';
import React, { useMemo } from 'react';
import { GreenPlantsFormTypes, TGreenPlantsForm } from '../../types';
import { GreenPlantsAreaLabels } from './constants';
import { TArea } from '../../useAreaById';
import { useLocation } from 'react-router';
import isRequiredInSchema from 'lib/utils/forms/isReqiuredInSchema';
import { useGreenPlantsSchema } from 'models/schemas/greenplants';
import { getSelectOptionsFromDictionaryByName } from 'features/get-select-options-from-dictionary';
import { getFilteredOptionsByPrivileges } from 'features/getFilteredOptionsByPrivileges';
import { TDictionaryItemDictionary } from 'models';

interface FormProps {
  entity: keyof typeof GreenPlantsFormTypes;
  isLoading: boolean;
  areas: TDictionaryItemDictionary[];
  area: TArea | null;
}

const AreaForm: React.FC<FormProps> = ({ entity, isLoading, area, areas }) => {
  const ctx = useFormikContext<TGreenPlantsForm>();

  const filteredAreas = useMemo(
    () => getFilteredOptionsByPrivileges(areas as TDictionaryItemDictionary[], 'isEditable', 'value', ctx.values[entity].areaId),
    [areas, ctx.values, entity],
  );

  const location = useLocation();
  const isEditMode = location.pathname.includes('/edit');
  const { GreenPlantsSchema } = useGreenPlantsSchema(entity);

  const lawnsDict = useMemo(
    () => getSelectOptionsFromDictionaryByName(area?.dictionaries?.data, 'lawns'),
    [area?.dictionaries],
  );

  return (
    <Stack>
      <Field
        isDisabled={isLoading || isEditMode}
        as={Select}
        name={`${entity}.areaId`}
        label={GreenPlantsAreaLabels.areaId}
        options={filteredAreas}
        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
          ctx.setFieldValue(`${entity}.areaId`, e.target.value, true);
          ctx.setFieldValue(`${entity}.plantId`, null);
          ctx.setFieldValue(`${entity}.lawnId`, null);
          setTimeout(() => ctx.validateField(`${entity}.areaId`), 0);
        }}
        isRequired={isRequiredInSchema(GreenPlantsSchema, `${entity}.fields.areaId`)}
        error={ctx.errors?.[entity]?.areaId}
      />

      {entity === 'lawns' && (
        <Field
          isDisabled={isLoading || isEditMode || !area}
          as={Select}
          name={`${entity}.lawnId`}
          label={GreenPlantsAreaLabels.lawnName}
          options={lawnsDict}
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
            ctx.setFieldValue(`${entity}.lawnId`, e.target.value, true);
          }}
          isRequired={isRequiredInSchema(GreenPlantsSchema, `${entity}.fields.lawnId`)}
          error={ctx.errors?.[entity]?.lawnId}
        />
      )}

      <Input isDisabled readOnly value={ctx.values[entity].areaId ? area?.code || '-' : ''} label="Код площадки" />
      <Input isDisabled readOnly value={ctx.values[entity].areaId ? area?.district || '-' : ''} label="Округ" />
      <Input isDisabled readOnly value={ctx.values[entity].areaId ? area?.region || '-' : ''} label="Район" />
      <Input isDisabled readOnly value={ctx.values[entity].areaId ? area?.address || '-' : ''} label="Адрес" />
      <Input
        isDisabled
        readOnly
        value={ctx.values[entity].areaId ? area?.landmark || '-' : ''}
        label="Адресный ориентир"
      />
      <Input
        isDisabled
        readOnly
        value={ctx.values[entity].areaId ? area?.dateStart || '-' : ''}
        label="Год формирования площадки"
      />
      <Input
        isDisabled
        readOnly
        value={ctx.values[entity].areaId ? area?.type || '-' : ''}
        label="Тип территории насаждений"
      />
      <Input
        isDisabled
        readOnly
        value={ctx.values[entity].areaId ? area?.location || '-' : ''}
        label="Местоположение площадки"
      />
    </Stack>
  );
};

export { AreaForm };
