import React, { useState } from 'react';
import { useRequest } from 'hooks/useRequest';
import { FormikProvider, useFormik } from 'formik';
import { createTouchedErrors } from 'features/create-touched-errors';
import { useLocation } from 'react-router-dom';
import { GroundwaterMeasuringSchema } from 'models/schemas/groundwaters/measuring';
import { FormHeader, LoaderItem } from 'components/common';
import { GeoecologicalRootPath } from 'pages/data/GeoecologicalProcesses/consts';
import { initialEmptyValues } from './AddMeasuringResult';
import FieldsForm from './FieldsForm';
import { IUploadMedia } from 'components/common/FileUploadDragAndDrop/types';
import { MeasuringItem } from 'hooks/geo/Measuring/useMeasuringList';
import { updateFiles } from 'api/services/media';
import { useObjectType } from 'hooks/useObjectType';
import { useGoBackWithFallback } from 'lib/utils/goBackWithFallback';
import { useDictionary } from 'hooks/useDictionary';
import { useEnhancedNotification } from 'hooks';

export const EditMeasuringResult = () => {
  const { backWithFallback } = useGoBackWithFallback();
  const location = useLocation();
  const editedObject = location.state;
  const { isDictionariesLoading, dictionaries } = useDictionary({
    url: '/geoecology/v10/geoecology/watersource/measuring/dictionaries',
  });
  const [media, setMedia] = useState<IUploadMedia[]>([]);

  const objectType = useObjectType(dictionaries);

  const initialValues = {
    ...initialEmptyValues,
    ...editedObject,
  };

  const { onSuccessEdit, onErrorEdit } = useEnhancedNotification();

  const onSuccess = (response: MeasuringItem | null) => {
    if (!response) {
      onErrorEdit();
      return;
    }

    onSuccessEdit();

    updateFiles(editedObject.id, objectType, media)
      .then(() => {
        backWithFallback(`${GeoecologicalRootPath}/monitoring/water/measuring`);
      })
      .catch((error: Error) => {
        onErrorEdit(error.message);
      });
  };

  const { put, isLoading: isPutLoading } = useRequest(
    '/geoecology/v10/geoecology/watersource/measuring',
    onSuccess,
    onErrorEdit,
  );

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    initialValues,
    validationSchema: GroundwaterMeasuringSchema,
    onSubmit: (values) => put(values),
  });

  if (isPutLoading || isDictionariesLoading) {
    return <LoaderItem />;
  }

  return (
    <FormikProvider
      value={{
        ...formik,
        errors: createTouchedErrors(formik.errors, formik.touched),
      }}
    >
      <FormHeader
        onBackClick={() => backWithFallback(`${GeoecologicalRootPath}/monitoring/water/measuring`)}
        header="Добавление результатов обследования"
      />
      <FieldsForm
        dictionaries={dictionaries}
        onCancel={() => backWithFallback(`${GeoecologicalRootPath}/monitoring/water/measuring`)}
        media={media}
        onMediaChange={setMedia}
        uploadItemId={editedObject.id}
      />
    </FormikProvider>
  );
};
