import { FormHeader } from 'components/common';
import { ERROR_MESSAGES, SUCCESS_MESSAGES } from 'constants/messages';
import { createTouchedErrors } from 'features/create-touched-errors';
import { FormikProvider, useFormik } from 'formik';
import { useNotifications } from 'hooks/useNotifications';
import { useRequest } from 'hooks/useRequest';
import { RightSchema } from 'models/schemas/admin/right';

import { useHistory, useParams } from 'react-router';
import { RightItem } from '../../models/RightItem';
import { useTypedLoader } from '../../typedLoader';
import { baseAdminRightUrl } from '../consts';
import FieldsForm from './FieldsForm';

export const EditRightForm = () => {
  const history = useHistory();

  const { id } = useParams<{
    id: string;
  }>();

  const { isLoading: isRightLoading, result: currentRight } = useTypedLoader<RightItem>(`${baseAdminRightUrl}/${id}`);
  const initialValues = { ...currentRight };

  const { successNotify, errorNotify } = useNotifications();

  const successHandler = () => {
    successNotify({
      key: 'file/create/success',
      message: SUCCESS_MESSAGES.EDIT,
    });
    history.push('/admin/right');
  };
  const errorHandler = (error: any) => {
    errorNotify({
      key: 'file/create/error',
      message: `${ERROR_MESSAGES.EDIT}. ${typeof error === 'string' ? error : ''}`,
    });
  };

  const { put: updateRight } = useRequest(`${baseAdminRightUrl}/${id}`, successHandler, errorHandler);

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    initialValues,
    validationSchema: RightSchema,
    onSubmit: (values) => updateRight(values),
  });

  return (
    <FormikProvider
      value={{
        ...formik,
        errors: createTouchedErrors(formik.errors, formik.touched),
      }}
    >
      <FormHeader url="/admin/right" header={`Редактирование привилегии: '${currentRight?.name}'`} />
      <FieldsForm onCancel={() => history.push('/admin/right')} isLoading={isRightLoading} />
    </FormikProvider>
  );
};
