import { Grid, GridItem } from '@chakra-ui/react';
import { Field, FormikContextType, FormikErrors, FormikValues, useFormikContext } from 'formik';
import { Input, Select } from 'components/common';
import React from 'react';
import { TDictionaryItemDictionary } from 'models';
import { AsyncAddressSelect } from 'components/common/AsyncAddressSelect/AsyncAddressSelect';

interface IProps {
  districts: TDictionaryItemDictionary[];
  regions: TDictionaryItemDictionary[];
}

export const Location = ({ districts, regions }: IProps) => {
  const { errors }: FormikContextType<FormikErrors<FormikValues>> = useFormikContext();

  return (
    <>
      <Grid maxWidth={700} gap={2} templateColumns="repeat(1, 1fr)">
        <GridItem>
          <Field as={Select} name="rayonId" label="Район" options={districts} error={errors.rayonId} />
        </GridItem>
        <GridItem>
          <Field as={Select} name="okrugId" label="Округ" options={regions} error={errors.okrugId} />
        </GridItem>
        <GridItem>
          <Field as={Input} name="landmark" label="Адресный ориентир" error={errors.landmark} />
        </GridItem>
        <GridItem>
          <Field as={AsyncAddressSelect} name="address" label="Адрес" error={errors.address} />
        </GridItem>
        <GridItem>
          <Field as={Input} name="latitude" label="Широта" error={errors.latitude} />
        </GridItem>
        <GridItem>
          <Field as={Input} name="longitude" label="Долгота" error={errors.longitude} />
        </GridItem>
      </Grid>
    </>
  );
};
