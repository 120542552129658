import React, { useEffect } from 'react';
import { createTouchedErrors } from 'features/create-touched-errors';
import { FormikProvider, useFormik } from 'formik';
import { useHistory, useParams } from 'react-router-dom';
import { FieldsForm } from './FieldsForm';
import { DataSourcesList, FTPRootPath, UploadConfig } from 'pages/admin/FTP';
import { baseAdminUrl } from 'pages/admin';
import { useRequest } from 'hooks/useRequest';
import { FormHeader } from 'components/common';
import { Box } from '@chakra-ui/react';
import { useEnhancedNotification } from 'hooks';
import { Complaint } from 'pages/citizenAppeal';
import { UploadConfigSchema } from 'models/schemas/admin/ftpUpload';

export const initialEmptyValues: Partial<UploadConfig> = {
  exchangeId: '',
  name: '',
  status: true,
  templateId: '',
  isDeleted: false,
};

export const EditFTPUpload = () => {
  const history = useHistory();
  const { onSuccessEdit, onErrorEdit } = useEnhancedNotification();
  const { id } = useParams<{ id: string; }>();
  const { get, result } = useRequest<Complaint>(`${baseAdminUrl}/upload-config/${id}`);

  useEffect(() => {
    void get();
  }, [get]);

  const onSuccess = () => {
    history.push(`${FTPRootPath}/upload`);
    onSuccessEdit();
  };

  const { put } = useRequest<DataSourcesList, any>(`${baseAdminUrl}/upload-config`, onSuccess, onErrorEdit);

  const initialValues = {
    ...initialEmptyValues,
    ...result
  };

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    validationSchema: UploadConfigSchema,
    initialValues,
    onSubmit: (values) => {
      void put(values);
    },
  });

  return (
    <FormikProvider
      value={{
        ...formik,
        errors: createTouchedErrors(formik.errors, formik.touched),
      }}
    >
      <Box>
        <FormHeader url={`${FTPRootPath}/upload`} header="Редактирование набора" />
        <FieldsForm
          onCancel={() => {
            history.push(`${FTPRootPath}/upload`);
          }}
          isEditMode
        />
      </Box>
    </FormikProvider>
  );
};
