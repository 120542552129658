import React, { useCallback } from 'react';
import { COMMON_LOCALE } from 'constants/common';
import { Button, DateInput, Input } from 'components/common';
import { Field, useFormikContext } from 'formik';
import { DeviceVerification } from 'pages/equipment/Devices/types';
import { createLocalDateISO8601 } from 'lib/create-date';
import { deviceVerificationLocale } from 'pages/equipment/Devices/consts';
import { Flex } from '@chakra-ui/react';

interface FormFieldsProps {
  onCancel: () => void;
}

export const FormFields: React.FC<FormFieldsProps> = (props) => {
  const { handleSubmit, errors, setFieldValue } = useFormikContext<DeviceVerification>();
  const onSubmit = useCallback(() => {
    handleSubmit();
  }, [handleSubmit]);

  return (
    <>
      <Field
        as={Input}
        name="name"
        label={deviceVerificationLocale.name}
        error={errors.name}
      />
      <Field
        as={DateInput}
        name="date"
        label={deviceVerificationLocale.date}
        error={errors.date}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setFieldValue('date', createLocalDateISO8601(e.target.value));
        }}
      />
      <Field
        as={DateInput}
        name="dateStart"
        label={deviceVerificationLocale.dateStart}
        error={errors.dateStart}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setFieldValue('dateStart', createLocalDateISO8601(e.target.value));
        }}
      />
      <Field
        as={DateInput}
        name="dateExpired"
        label={deviceVerificationLocale.dateExpired}
        error={errors.dateExpired}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setFieldValue('dateExpired', createLocalDateISO8601(e.target.value));
        }}
      />
      <Flex marginTop={5}>
        <Button onClick={onSubmit}>{COMMON_LOCALE.save}</Button>
        <Button onClick={props.onCancel} variant="ghost" marginRight={5}>
          {COMMON_LOCALE.cancel}
        </Button>
      </Flex>
    </>
  );
};
