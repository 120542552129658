import React, { useMemo } from 'react';
import { TFilterDTO, TFiltersArray } from 'models';
import { IUseListMaterialTableProps, IWithFileDownload } from 'hooks';
import { createLocalDateDDMMYYYY } from 'lib/create-date';
import { MaterialTable } from 'features/material-table/MaterialTableMini';
import { Column } from '@material-table/core';
import { addFiltersToColumns, ColumnWithFilter } from 'lib/utils/tableFilter';
import { TableFilterTypeEnum } from 'components/table/filters/TableFilter';
import { ValueItem, ValueItems } from 'pages/data/GeoecologicalProcesses/DangerouslyGeologicalProcesses/Protocol/types';
import VersionCell from 'components/table/VersionCell';
import useProtocolModal from 'hooks/useProtocolModal';
import { DefaultTableCellSize } from 'components/table/DefaultTableCellSize';

interface ReperTableProps {
  list: ValueItems | null;
  refetch: () => void;
  toggleOrder: (fieldKey: string) => void;
  onPageChange: (page: number, pageSize: number) => void;
  downloadAsFile: IWithFileDownload;
  filters: TFilterDTO['filters'];
  setFilters: (filters: TFiltersArray) => void;
  columns?: ColumnWithFilter<ValueItem>[];
  materialTableProps: IUseListMaterialTableProps;
  filterDTO: TFilterDTO;
  isExecute?: boolean;
  isAvailable?: boolean;
  saveFilters: () => void;
  isLoading: boolean;
  errorText?: string;
  baseUrl?: string;
  previewColumns?: Column<any>[];
}

const ProtocolTable: React.FC<ReperTableProps> = ({
  list,
  refetch,
  toggleOrder,
  onPageChange,
  downloadAsFile,
  filters,
  setFilters,
  columns,
  filterDTO,
  isExecute,
  isAvailable,
  saveFilters,
  isLoading,
  errorText,
  baseUrl,
  previewColumns,
  materialTableProps,
}) => {
  const { openModal, protocolModal } = useProtocolModal<ValueItem>({
    onClose: refetch,
    dateKey: 'valueDate',
    baseUrl,
    previewColumns,
    secondBaseUrl: '/geoecology/v10/geoecology/dangerprocess'
  });

  const _columns = useMemo<Array<ColumnWithFilter<ValueItem>>>(
    () => {
      if (columns) {
        return [{
          title: 'Версия',
          field: 'valueVersion',
          render: (row) => (
            <>
              {
                isAvailable ? (
                  <VersionCell row={row} onClick={openModal} />
                ) : row.valueVersion
              }
            </>
          ),
        }, ...columns];
      }
      return [
        {
          title: 'Версия',
          field: 'valueVersion',
          render: (row) => (
            <>
              {
                isAvailable ? (
                  <VersionCell row={row} onClick={openModal} />
                ) : row.valueVersion
              }
            </>
          ),
        },
        {
          title: 'Дата',
          field: 'valueDate',
          render: (row) => createLocalDateDDMMYYYY(row.valueDate),
        },
        {
          title: 'Участок',
          field: 'areaCode',
          filterType: TableFilterTypeEnum.number,
        },
        {
          title: 'Точка наблюдения',
          field: 'pointCode',
          filterType: TableFilterTypeEnum.number,
        },
        {
          title: 'Параметр',
          field: 'parameterName',
          filterType: TableFilterTypeEnum.string,
          cellStyle: {
            width: '210px',
          },
        },
        {
          title: 'Значение',
          field: 'value',
          filterType: TableFilterTypeEnum.string,
          render: (row) => <DefaultTableCellSize maxWidth={300}>{row.value}</DefaultTableCellSize>,
        },
      ];
    },
    [columns, openModal, isAvailable]
  );

  const columnsWithFilters = useMemo<Array<Column<ValueItem>>>(
    () => addFiltersToColumns({ filters, setFilters, columns: _columns }),
    [_columns, filters, setFilters]
  );

  return (
    <>
      <MaterialTable
        saveFilters={saveFilters}
        isNotExecute={isExecute}
        toggleOrder={toggleOrder}
        onPageChange={onPageChange}
        filterDTO={filterDTO}
        data={list?.data}
        meta={list?.meta}
        columns={columnsWithFilters}
        downloadAsFile={downloadAsFile}
        isLoading={isLoading}
        errorText={errorText}
        {...materialTableProps}
        showTotalCount
      />
      {protocolModal}
    </>
  );
};

export default ProtocolTable;
