import React, { memo, useCallback, useMemo } from 'react';
import { ReperItems, ReperRowItem } from 'hooks/geo/Reper/useGeoReperList';
import { TFilterDTO, TFiltersArray } from 'models';
import { IUseListMaterialTableProps, IWithFileDownload, useHandleDeleteData } from 'hooks';
import { createLocalDateDDMMYYYY } from 'lib/create-date';
import { MaterialTable } from 'features/material-table/MaterialTableMini';
import { useHistory } from 'react-router';
import { REMOVE_PACK_CONFIRMATION } from 'constants/messages';
import { GeoecologicalRootPath } from 'pages/data/GeoecologicalProcesses/consts';
import { DeleteIcon, EditIcon, ImageIcon } from 'components/icons';
import { Column } from '@material-table/core';
import { PropActions } from 'features/material-table/components/Actions';
import FilePreviewModalWrapper from 'components/common/FilePreviewModal/FilePreviewModalWrapper';
import useFilePreviewModalWrapperData from 'components/common/FilePreviewModal/hooks/useFilePreviewModalWrapperData';
import { addFiltersToColumns } from 'lib/utils/tableFilter';
import { TableFilterTypeEnum } from 'components/table/filters/TableFilter';
import { templatesObject } from 'constants/templates';

interface ReperTableProps {
  reperList: ReperItems | null;
  refetch: () => void;
  toggleOrder: (fieldKey: string) => void;
  onPageChange: (page: number, pageSize: number) => void;
  downloadAsFile: IWithFileDownload;
  setFilters: (filters: TFiltersArray<keyof ReperRowItem>) => void;
  filterDTO: TFilterDTO;
  filters: TFilterDTO<keyof ReperRowItem>['filters'];
  onSelectionChange: (checkedRows: ReperRowItem[]) => void;
  selectedIndexes: number[];
  isLoading: boolean;
  isReadonly?: boolean;
  isExecute?: boolean;
  isWrite?: boolean;
  isNoAction?: boolean;
  allAvailable?: boolean;
  saveFilters: () => void;
  errorText?: string;
  materialTableProps: IUseListMaterialTableProps;
}

const _ReperTable: React.FC<ReperTableProps> = ({
  reperList,
  refetch,
  filters,
  toggleOrder,
  onPageChange,
  downloadAsFile,
  setFilters,
  filterDTO,
  onSelectionChange,
  selectedIndexes,
  isExecute,
  isWrite,
  isNoAction,
  allAvailable,
  isLoading,
  saveFilters,
  errorText,
  materialTableProps,
}) => {
  const history = useHistory();
  const { open, ...filePreviewData } = useFilePreviewModalWrapperData();

  const { ModalComponent, handleOpenModal } = useHandleDeleteData({ refetch, modalTitle: REMOVE_PACK_CONFIRMATION });
  const openImageModal = useCallback(
    (item: ReperRowItem) => {
      if (item.objectType) {
        open(item.registryId, item.objectType);
      }
    },
    [open],
  );

  const columns = useMemo<Column<ReperRowItem>[]>(
    () => [
      {
        title: 'Пакет',
        field: 'packNumber',
        filterType: TableFilterTypeEnum.number,
      },
      {
        title: 'Дата измерения',
        field: 'valDateTime',
        render: (row) => createLocalDateDDMMYYYY(row.valDateTime),
      },
      {
        title: 'Участок',
        field: 'areaNumber',
        filterType: TableFilterTypeEnum.number,
      },
      {
        title: 'Пункт наблюдения',
        field: 'pointNumber',
        filterType: TableFilterTypeEnum.number,
      },
      {
        title: 'Тип пункта',
        field: 'sourceTypeName',
        filterType: TableFilterTypeEnum.string,
      },
      {
        title: 'Точность по X, мм',
        field: 'accuracyX',
        filterType: TableFilterTypeEnum.number,
      },
      {
        title: 'Точность по Y, мм',
        field: 'accuracyY',
        filterType: TableFilterTypeEnum.number,
      },
      {
        title: 'Точность по Z, мм',
        field: 'accuracyZ',
        filterType: TableFilterTypeEnum.number,
      },
      {
        title: 'Геодезическая координата X, м',
        field: 'geodeticCoordinateX',
        filterType: TableFilterTypeEnum.number,
      },
      {
        title: 'Геодезическая координата Y, м',
        field: 'geodeticCoordinateY',
        filterType: TableFilterTypeEnum.number,
      },
      {
        title: 'Абсолютная отметка Z, м',
        field: 'absoluteMarkZ',
        filterType: TableFilterTypeEnum.number,
      },
    ],
    [],
  );

  const columnsWithFilters = useMemo<Array<Column<ReperRowItem>>>(
    () => addFiltersToColumns({ filters, setFilters, columns }),
    [columns, filters, setFilters],
  );

  const initialActions = useMemo<PropActions<ReperRowItem[]>>(
    () => [
      {
        position: 'row',
        action: (row) => ({
          icon: () => <EditIcon />,
          onClick: () =>
            history.push(`${GeoecologicalRootPath}/monitoring/danger/reper/edit/row/${row.registryId}`, row),
          tooltip: 'Редактировать',
          hidden: !row.isEditable,
        }),
      },
      {
        position: 'row',
        action: (row) => ({
          icon: () => <DeleteIcon />,
          onClick: () =>
            handleOpenModal({
              url: `/geoecology/v10/geoecology/reper/registry/${row.registryId}`,
            }),
          tooltip: 'Удалить',
          hidden: !row.isEditable,
        }),
      },
      {
        position: 'row',
        action: (row) => ({
          icon: () => <ImageIcon />,
          onClick: () => openImageModal(row),
          tooltip: 'Показать изображения',
          hidden: !row.objectType,
        }),
      },
    ],
    [handleOpenModal, history, openImageModal],
  );

  // @ts-ignore
  const actions = useMemo<PropActions<any[]>>(() => {
    if (allAvailable || isNoAction || isWrite) {
      return initialActions;
    }
    if (!(allAvailable || isNoAction || isWrite)) {
      return initialActions.filter((action: any) => action.type !== 'delete');
    }
  }, [isWrite, initialActions, isNoAction, allAvailable]);

  return (
    <>
      <MaterialTable
        saveFilters={saveFilters}
        isNotExecute={isExecute || isNoAction}
        toggleOrder={toggleOrder}
        onPageChange={onPageChange}
        filterDTO={filterDTO}
        data={reperList?.data}
        meta={reperList?.meta}
        columns={columnsWithFilters}
        actions={actions}
        downloadAsFile={downloadAsFile}
        setFilters={setFilters}
        downloadTemplate={[templatesObject.repersAndMarks]}
        onSelectionChange={onSelectionChange}
        selectedIndexes={selectedIndexes}
        isLoading={isLoading}
        errorText={errorText}
        {...materialTableProps}
        showTotalCount
      />

      {ModalComponent}

      <FilePreviewModalWrapper {...filePreviewData} />
    </>
  );
};

export const ReperTable = memo(_ReperTable);
