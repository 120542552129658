import { Box, Divider } from '@chakra-ui/react';

import { PrintChartButton } from '../../components/PrintChartButton';
import { TimeBoundaries } from '../../components/TimeBoundaries';

interface IGanttChartActions {
  minDate: string | null;
  maxDate: string | null;
}

export const GanttChartActions = ({ minDate, maxDate }:IGanttChartActions) => (
  <>
    <Divider my={2} />
    <Box>
      <PrintChartButton chartId="LineChart" />
    </Box>
    <Divider mt={2} />
    <TimeBoundaries minDate={minDate} maxDate={maxDate} />
  </>
);
