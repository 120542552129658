import React from 'react';
import { PageHeader, RoutesList } from 'components/common';
import appLocale from 'constants/appLocale';
import { FTPRootPath, FTPSettings } from './consts/consts';
import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import { Flex, Heading } from '@chakra-ui/react';

export const FTPMenu: React.FC = () => {
  useUpdateBreadcrumbs([
    { breadcrumb: appLocale.admin.main, path: '/admin' },
    {
      breadcrumb: appLocale.admin.ftp,
      path: FTPRootPath,
    },
  ]);
  return (
    <Flex flexDirection="column">
      <PageHeader>
        <Heading>{appLocale.admin.ftp}</Heading>
      </PageHeader>
      <RoutesList
        routes={[
          {
            path: `${FTPSettings}/servers`,
            breadcrumb: appLocale.admin.ftpSettings,
          },
          {
            path: `${FTPRootPath}/upload`,
            breadcrumb: appLocale.admin.ftpUpload,
          },
          {
            breadcrumb: appLocale.admin.ftpDataPatterns,
            path: `${FTPRootPath}/dataPatterns`,
          },
        ]}
      />
    </Flex>
  );
};
