import { useNotifications } from 'hooks/useNotifications';
import { forEach } from 'lodash';
import { FilePreviewErrors } from 'models';
import { useCallback } from 'react';
import appLocale from 'constants/appLocale';

const useUploadFileNotify = () => {
  const { successNotify, errorNotify } = useNotifications();

  return useCallback(
    <
      Response extends {
        errors?: FilePreviewErrors | null;
        hasErrors?: boolean;
      }
    >(
      operation: string,
      successMessage = appLocale.fileUploadModal.file_upload_default_success,
      errorMessage = appLocale.fileUploadModal.file_upload_default_error
    ) =>
      function (response: Response) {
        if (response?.errors?.length) {
          forEach(response.errors, ({ error }) => {
            errorNotify({
              key: `upload/file/${operation}`,
              message: error,
            });
          });
          return;
        }

        if (response.hasErrors) {
          errorNotify({
            key: `upload/file/${operation}`,
            message: errorMessage,
          });
        } else {
          successNotify({
            key: `upload/file/${operation}`,
            message: successMessage,
          });
        }
      },
    [errorNotify, successNotify]
  );
};

export { useUploadFileNotify };
