import React from 'react';
import { ChevronLeft, ChevronRight, FirstPage, LastPage } from '@material-ui/icons';
import { Icons } from '@material-table/core';

const tableIcons: Icons<any> = {
  FirstPage: React.forwardRef(() => <FirstPage />),
  LastPage: React.forwardRef(() => <LastPage />),
  NextPage: React.forwardRef(() => <ChevronRight />),
  PreviousPage: React.forwardRef(() => <ChevronLeft />),
  SortArrow: React.forwardRef((props, ref) => <div ref={ref} {...props} />),
  DetailPanel: React.forwardRef(() => <ChevronRight />),
};

export default tableIcons;
