import { useCallback, useEffect } from 'react';
import { useRequest } from '../../useRequest';
import { InclinometerSaveItem } from './useGeoInclinometerList';

export function useGeoInclinometerRecord(pointId: string, valDateTime: string) {
  const { get, isLoading, result, error } = useRequest<InclinometerSaveItem>(
    `/geoecology/v10/geoecology/inclinometer/${pointId}?date=${valDateTime}`
  );

  const getGeoecologyInclinometerRecord = useCallback(() => get(), [get]);

  useEffect(() => {
    getGeoecologyInclinometerRecord();
  }, [getGeoecologyInclinometerRecord]);

  return {
    getGeoecologyInclinometerRecord,
    isLoading,
    result,
    error,
  };
}
