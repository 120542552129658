import React, { memo, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TreeView from '@material-ui/lab/TreeView';
import { ChevronDown } from 'features/WithSideBar/icons';
import { Box, Flex, Text, useColorMode } from '@chakra-ui/react';
import { StyledTreeItem } from 'features/WithSideBar/components/StyledTreeItem';
import { RenderTree } from 'features/WithSideBar/types';

interface MenuDropdownsProps {
  tree: RenderTree;
  expanded: string[];
  selected: string | undefined;
  handleToggle: (e: React.ChangeEvent<{}>, nodeIds: string[]) => void;
  handleHistory: (url: string | undefined, isExternal?: boolean) => (e: React.ChangeEvent<{}>) => void;
  isOpen: boolean;
}

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
}));

const _MenuDropdowns: React.FC<MenuDropdownsProps> = ({
  tree,
  expanded,
  selected,
  handleToggle,
  handleHistory,
  isOpen,
}) => {
  const classes = useStyles();
  const colorMode = useColorMode();

  const renderTree = React.useCallback(
    (nodes: RenderTree) => {
      const children = isOpen ? nodes.children : null;

      return (
        <StyledTreeItem
          key={nodes.id}
          nodeId={nodes.id}
          label={(
            <Flex alignItems="center">
              {nodes.icon ? (
                <Box mr={isOpen ? 4 : 0} ml={isOpen ? 0 : 0.5}>
                  {nodes.icon}
                </Box>
              ) : (
                <Box width="32px" flexShrink={0} />
              )}

              {isOpen ? (
                <Text fontSize="0.875rem" lineHeight="1.125rem">
                  {nodes.name}
                </Text>
              ) : null}
            </Flex>
          )}
          isCollapse={Boolean(children?.length)}
          onLabelClick={handleHistory(nodes.url, nodes.isExternal)}
          isOpen={isOpen}
        >
          {Array.isArray(children) ? children.map((node) => renderTree(node)) : null}
        </StyledTreeItem>
      );
    },
    [handleHistory, isOpen],
  );

  const isSelected = useMemo<string | undefined>(() => {
    // when sidebar is collapsed 1st level should be highlighted
    if (isOpen) {
      return selected;
    }
    return selected ? `/${selected.split('/')[1]}` : '';
  }, [isOpen, selected]);

  const chevronStyles = {
    color: colorMode.colorMode === 'light' ? 'rgba(0, 0, 0, 0.85)' : '#FFFFFF',
  };

  return (
    <TreeView
      classes={{
        root: classes.root,
      }}
      defaultCollapseIcon={<ChevronDown style={{ ...chevronStyles, transform: 'rotate(180deg)' }} />}
      defaultExpandIcon={<ChevronDown style={chevronStyles} />}
      expanded={expanded}
      selected={isSelected || ''}
      multiSelect={false}
      disableSelection
      onNodeToggle={handleToggle}
    >
      {renderTree(tree)}
    </TreeView>
  );
};

export const MenuDropdowns = memo(_MenuDropdowns);
