import { Stack } from '@chakra-ui/react';
import { Select } from 'components/common';
import { dictParametersToMap } from 'features/parametersMap';
import { Field, useFormikContext } from 'formik';
import { concat, get } from 'lodash';
import { TFLoraDictionaries } from 'pages/data/GreenPlants/Flora/useFloraDictionaries';
import React, { memo, useCallback } from 'react';
import { TGreenPlantsForm } from 'pages/data/GreenPlants/Form/types';
import { IFloraTabPanelProps } from './types';
import { getDictionaryId } from 'pages/data/GreenPlants/utils';
import {
  getSelectOptionsFromDictionaryByName
} from '../../../../../../../../features/get-select-options-from-dictionary';

interface TFloraPointsFormProps {
  entity: IFloraTabPanelProps['entity'];
  isLoading: IFloraTabPanelProps['isLoading'];
  areaParameters: TFLoraDictionaries['areaParameters'];
  plantParameters: TFLoraDictionaries['plantParameters'];
}

const FloraPointsForm: React.FC<TFloraPointsFormProps> = memo(
  ({ entity, isLoading, areaParameters = [], plantParameters = [] }) => {
    const ctx = useFormikContext<TGreenPlantsForm>();

    const parameters = dictParametersToMap(concat(areaParameters, plantParameters));

    const getOptionsByName = useCallback((name: string) => getSelectOptionsFromDictionaryByName(
      [...areaParameters, ...plantParameters],
      name,
      false,
      get(ctx.values, `${entity}.parameters.${name}.ids`)
    ), [areaParameters, plantParameters, ctx.values, entity]);

    const detailedStateCategoryTostateCategoryMap: Record<string, string> = {
      'a847693c-d7f4-48b6-bc5b-b2088300896a': 'df4ec55a-6afd-450c-a5e4-8f1496a71d64',
      '24b29245-834c-493c-92aa-d751eb0a2849': '4f5d9dfa-13f0-4e90-84f9-144d8b9d58e5',
      '04e1e24a-a85c-4380-a5df-259a2b7ec29d': '4f5d9dfa-13f0-4e90-84f9-144d8b9d58e5',
      '6d97abe6-0cbd-4444-84c8-96c3c103a2ba': '6d4ae24b-a395-406e-900a-3ff47b8f3b7d',
      '4c249c76-18ef-4798-9ad6-89d66d40afe1': '6d4ae24b-a395-406e-900a-3ff47b8f3b7d',
      'c47899d5-9667-424d-a6f6-54236ac99370': '6d4ae24b-a395-406e-900a-3ff47b8f3b7d',
    };

    return (
      <Stack>
        <Field
          isDisabled={isLoading}
          as={Select}
          name={`${entity}.parameters.foliagePercentage.dictionaryItemId[0]`}
          label={parameters.foliagePercentage?.title}
          options={getOptionsByName('foliagePercentage')}
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
            if (e?.target) {
              ctx.setFieldValue(`${entity}.parameters.foliagePercentage.dictionaryItemId`, [
                getDictionaryId(e.target.value, parameters.foliagePercentage?.values),
              ]);
              ctx.setFieldValue(`${entity}.parameters.foliagePercentage.value`, [e.target.value]);
              ctx.setFieldValue(
                `${entity}.parameters.foliagePercentage.parameterId`,
                parameters.foliagePercentage?.parameterId
              );
            }
          }}
        />
        <Field
          isDisabled={isLoading}
          as={Select}
          name={`${entity}.parameters.detailedStateCategory.dictionaryItemId[0]`}
          label={parameters.detailedStateCategory?.title}
          options={getOptionsByName('detailedStateCategory')}
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
            if (e?.target) {
              ctx.setFieldValue(`${entity}.parameters.detailedStateCategory.dictionaryItemId`, [
                getDictionaryId(e.target.value, parameters.detailedStateCategory?.values),
              ]);
              ctx.setFieldValue(`${entity}.parameters.detailedStateCategory.value`, [e.target.value]);
              ctx.setFieldValue(
                `${entity}.parameters.detailedStateCategory.parameterId`,
                parameters.detailedStateCategory?.parameterId
              );

              const stateCatValue = e.target.value ? detailedStateCategoryTostateCategoryMap[e.target.value] : null;
              ctx.setFieldValue(`${entity}.parameters.stateCategory.dictionaryItemId`, [
                getDictionaryId(stateCatValue, parameters.stateCategory.values)
              ]);
              ctx.setFieldValue(`${entity}.parameters.stateCategory.value`, [stateCatValue]);
              ctx.setFieldValue(
                `${entity}.parameters.stateCategory.parameterId`,
                parameters.stateCategory?.parameterId,
              );
            }
          }}
        />
        <Field
          isDisabled
          as={Select}
          name={`${entity}.parameters.stateCategory.dictionaryItemId[0]`}
          label={parameters.stateCategory?.title}
          options={getOptionsByName('stateCategory')}
        />
        <Field
          isDisabled={isLoading}
          as={Select}
          name={`${entity}.parameters.decorativeness.dictionaryItemId[0]`}
          label={parameters.decorativeness?.title}
          options={getOptionsByName('decorativeness')}
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
            if (e?.target) {
              ctx.setFieldValue(`${entity}.parameters.decorativeness.dictionaryItemId`, [
                getDictionaryId(e.target.value, parameters.decorativeness?.values),
              ]);
              ctx.setFieldValue(`${entity}.parameters.decorativeness.value`, [e.target.value]);
              ctx.setFieldValue(
                `${entity}.parameters.decorativeness.parameterId`,
                parameters.decorativeness?.parameterId
              );
            }
          }}
        />
      </Stack>
    );
  }
);

export { FloraPointsForm };
