import { useLocation, useParams } from 'react-router-dom';
import { MaterialTable } from 'features/material-table/MaterialTableMini';
import React, { useMemo } from 'react';
import { useList } from 'hooks';
import { NODLogUrl } from '../consts';
import { createLocalDateDDMMYYYYWithTime } from 'lib/create-date';
import {
  NODAirQuality,
  NODAirStations,
  NODASKZAMonth,
  NODAtmpsphere,
  NODDanger,
  NODGeoDangerProcess,
  NODGeoSpring,
  NODGreenplants,
  NODJournalNMU,
  NODNoise,
  NODSoil,
  NODWaterConcentration,
  NODWaterObjects,
  NODWaterObservations,
  NODWaterParams,
} from './consts';
import { TruncatedStringPopoverCell } from 'components/table/TruncatedStringPopoverCell';
import { SubsystemHeader } from 'components/common';
import { Filter } from './Filter';
import { useDictionary } from 'hooks/useDictionary';
import { FullPageWrapper } from '../../../../components/table/FullPageWrapper';

export const LineByLineView = () => {
  const { id } = useParams<{ id: string }>();
  const { state } = useLocation();
  const {
    isLoading,
    response,
    toggleOrder,
    setFilters,
    onPageChange,
    filterDTO,
    onSearchText,
    downloadAsFile,
    selectedIndexes,
    renderOnlySelectedCheckbox,
    onSubmitFilters,
    onSelectionChange,
  } = useList<any>(`${NODLogUrl}/details/${id}`, {
    initialOrderFieldKey: '-uploadDatetime',
    selectionKey: 'globalMappingId',
  });

  const { dictionaries } = useDictionary({ url: '/ehd/v10/catalog-uploader-history/dictionaries' });

  const columnMaker = useMemo(() => {
    switch (state.reportNumber) {
      case 1:
        return NODAirStations;
      case 2:
        return NODWaterObjects;
      case 3:
        return NODAtmpsphere;
      case 4:
        return NODJournalNMU;
      case 5:
        return NODDanger;
      case 6:
        return NODAirQuality;
      case 7:
        return NODWaterParams;
      case 8:
        return NODNoise;
      case 9:
        return NODGreenplants;
      case 10:
        return NODSoil;
      case 11:
        return NODGeoSpring;
      case 12:
        return NODWaterConcentration;
      case 13:
        return NODASKZAMonth;
      case 14:
        return NODWaterObservations;
      case 15:
        return NODGeoDangerProcess;
      default:
        return [];
    }
  }, [state.reportNumber]);

  const columns = useMemo<any>(
    () => [
      {
        title: 'Дата и время выгрузки',
        field: 'uploadDatetime',
        render: (row: any) => createLocalDateDDMMYYYYWithTime(row.uploadDatetime),
      },
      ...columnMaker,
      {
        title: 'Ruid',
        field: 'ehdSystemObjectId',
      },
      {
        title: 'ЕХД ИД',
        field: 'ehdGlobalId',
      },
      {
        title: 'Статус',
        field: 'uploadStatusName',
        render: (row: any) => <TruncatedStringPopoverCell value={row.uploadStatusDescription} isLargeText />,
      },
    ],
    [columnMaker]
  );

  return (
    <FullPageWrapper>
      <SubsystemHeader
        title={`Отчет о выгрузке НОД "${state.reportName}"`}
        backPath={state.breadcrumbs && state.breadcrumbs[state.breadcrumbs.length - 1].path}
        filtersComponent={
          <Filter dictionaries={dictionaries} onSubmitFilters={onSubmitFilters}>
            {renderOnlySelectedCheckbox}
          </Filter>
        }
      />
      <MaterialTable
        data={response?.data}
        meta={response?.meta}
        toggleOrder={toggleOrder}
        filterDTO={filterDTO}
        onPageChange={onPageChange}
        columns={columns}
        isLoading={isLoading}
        setFilters={setFilters}
        options={{
          selection: true,
          rowStyle: (rowData) => ({
            // @ts-ignore TODO: add typings
            color: rowData.uploadStatus === 3 ? '#F00' : '#000',
          }),
        }}
        onSearch={onSearchText}
        downloadAsFile={downloadAsFile}
        onSelectionChange={onSelectionChange}
        selectedIndexes={selectedIndexes}
      />
    </FullPageWrapper>
  );
};
