import React from 'react';
import { PageHeader, RoutesList } from 'components/common';
import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import appLocale from 'constants/appLocale';
import { pgrMenu } from '../../reportsAnalyticsMenu';
import { Flex, Heading } from '@chakra-ui/react';
import { useRoutesList } from 'hooks/useRoutesList';

export const PGRReports = () => {
  useUpdateBreadcrumbs([
    { breadcrumb: appLocale.subsystems.analyticsReports, path: '/reports-analytics' },
    { breadcrumb: appLocale.subsystems.reagents, path: '/reports-analytics/pgr' },
  ]);

  const routes = useRoutesList(pgrMenu);

  return (
    <Flex flexDirection="column">
      <PageHeader>
        <Heading>Отчеты ПГР</Heading>
      </PageHeader>
      <RoutesList routes={routes} />
    </Flex>
  );
};
