import { SubsystemHeader } from 'components/common';
import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import { UserList } from './UserList';
import appLocale from 'constants/appLocale';
import { useHistory } from 'react-router';
import React from 'react';
import { FullPageWrapper } from '../../../components/table/FullPageWrapper';

export const Users: React.FC = () => {
  useUpdateBreadcrumbs([
    { breadcrumb: appLocale.admin.main, path: '/admin' },
    {
      breadcrumb: appLocale.admin.users,
      path: '/admin/user',
    },
  ]);

  const { push } = useHistory();

  return (
    <FullPageWrapper>
      <SubsystemHeader
        title="Управление пользователями"
        addButtonProps={{ onClick: () => push('/admin/user/add') }}
      />

      <UserList />
    </FullPageWrapper>
  );
};
