import { Field, FormikProvider, useFormik } from 'formik';
import React, { useEffect, useMemo, useState } from 'react';
import { Checkbox, DateInput, IFilterValues, Select } from 'components/common';
import { Flex, Grid } from '@chakra-ui/react';
import { createDateFromResponse, createDateToRequestWithTimezone } from 'lib/create-date';
import { TDictionariesArray } from 'models/dictionaries';
import {
  getSelectOptionsFromDictionaryByName,
  getSelectOptionsFromDictionaryByNameWithParent,
  getSelectOptionsFromDictionaryByNameWithTransformation,
} from 'features/get-select-options-from-dictionary';
import { PeriodsButtons } from 'features/PeriodsButtons';
import { IPeriod } from 'features/PeriodsButtons/types';
import { usePeriods } from 'features/PeriodsButtons/usePeriods';
import combineFilters from 'lib/utils/combineFilters';
import { IFilterItem, TFiltersArray } from 'models';
import { MomentInput } from 'moment';
import { FiltersDatepickersSchema } from 'models/schemas/filtersDatepickersSchema/FiltersDatepickersSchema';
import FilterFormControls from 'components/form/FilterFormControls';

interface FilterProps {
  isLoading?: boolean;
  isDisabled?: boolean;
  filtersFromGraphic: Partial<IFilterValues> | null;
  setFiltersFromGraphic: (value: Partial<IFilterValues> | null) => void;
  dictionaries: {
    data: TDictionariesArray;
  } | null;
  onSubmit: (values: any) => void;
  periods: IPeriod[] | null;
  filters?: TFiltersArray;
}

const initialEmptyValues: Record<string, IFilterItem<string>> = {
  periodDateMin: {
    key: 'periodDate',
    operation: 'GTE',
    value: '',
  },
  periodDateMax: {
    key: 'periodDate',
    operation: 'LTE',
    value: '',
  },
  areaId: {
    key: 'areaId',
    operation: 'EQ',
    value: null,
  },
  emissionId: {
    key: 'emissionId',
    operation: 'EQ',
    value: null,
  },
  paramId: {
    key: 'paramId',
    operation: 'EQ',
    value: null,
  },
  // showDisabled - value равно '' если чекбокс включен и нужно получить с Backend все значения (этот объект удалиться при отправке)
  // value равно false - если нужно показать только неудаленные записи
  showDisabled: {
    key: 'deleted',
    operation: 'EQ',
    value: false,
  },
  periodId: {
    key: 'periodId',
    operation: 'EQ',
    value: null,
  },
  emissionSourceId: {
    key: 'emissionSourceId',
    operation: 'EQ',
    value: '',
  },
  initialData: {
    key: 'initialData',
    operation: 'EQ',
    value: false,
  },
};

export const TodayFilter = ({
  isLoading,
  filtersFromGraphic,
  setFiltersFromGraphic,
  isDisabled,
  onSubmit,
  dictionaries,
  periods,
  filters = [],
  ...props
}: FilterProps) => {
  const savedPeriodId = useMemo(
    () => (filters.find((item) => item.key === 'periodId')?.value as string) || '',
    [filters],
  );

  const [periodId, setPeriodId] = useState<string>(savedPeriodId);

  const { getDatesFromPeriod, dates } = usePeriods(periodId, periods);

  const submitPeriod = (id: string) => {
    const dates = getDatesFromPeriod(id);
    setFieldValue('periodId.value', id);
    setFieldValue('periodDateMin.value', createDateToRequestWithTimezone(dates[0]));
    setFieldValue('periodDateMax.value', createDateToRequestWithTimezone(dates[1]));
    setFieldTouched('periodDateMin.value');
    setPeriodId(id);
  };

  const { values, setValues, handleReset, submitForm, setFieldValue, errors, setFieldTouched, ...formik } = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    validationSchema: FiltersDatepickersSchema([
      { dateFromFieldName: 'periodDateMin', dateToFieldName: 'periodDateMax' }
    ], {}, 5),
    initialValues: initialEmptyValues,
    onSubmit,
  });

  useEffect(() => {
    setValues(combineFilters(initialEmptyValues, filters));
  }, [filters, setValues]);

  useEffect(() => {
    if (filtersFromGraphic) {
      if (filtersFromGraphic.startDate) {
        setFieldValue('dateTimeMin.value', filtersFromGraphic.startDate, true);
      }
      if (filtersFromGraphic.stopDate) {
        setFieldValue('dateTimeMax.value', filtersFromGraphic.stopDate, true);
      }
    }
    setFiltersFromGraphic(null);
  }, [filtersFromGraphic, setFieldValue, setFiltersFromGraphic]);

  const enterprises = useMemo(
    () => getSelectOptionsFromDictionaryByNameWithTransformation(dictionaries?.data, 'areas', 'title'),
    [dictionaries],
  );

  const sources = useMemo(() => {
    const sourcesDict = getSelectOptionsFromDictionaryByNameWithParent(dictionaries?.data, 'points');

    if (values.areaId.value) {
      return sourcesDict.filter((item) => item.parent === values.areaId.value);
    }

    return sourcesDict;
  }, [dictionaries, values.areaId.value]);

  const ovenExit = useMemo(() => {
    const sourcesDict = getSelectOptionsFromDictionaryByNameWithParent(dictionaries?.data, 'points');

    if (values.emissionSourceId.value) {
      return sourcesDict.filter((item) => item.parent === values.emissionSourceId.value);
    }

    return sourcesDict;
  }, [dictionaries, values.emissionSourceId.value]);

  const parameters = useMemo(
    () =>
      getSelectOptionsFromDictionaryByName(dictionaries?.data, 'parameters').map((item) => ({
        ...item,
        name: item.title,
        value: item.parameterId,
      })),
    [dictionaries],
  );

  return (
    <FormikProvider
      value={{
        values,
        errors,
        setValues,
        handleReset,
        submitForm,
        setFieldValue,
        setFieldTouched,
        ...formik,
      }}
    >
      <Flex flexDirection="row" justifyContent="space-between">
        <Grid gap="12px" rowGap="8px" templateColumns="repeat(2, 230px) 1fr 1fr" {...props}>
          <Field
            as={DateInput}
            maxDate={dates?.[1]}
            minDate={dates?.[0]}
            isDisabled={!periodId}
            name="periodDateMin.value"
            label="От"
            value={createDateFromResponse(values.periodDateMin.value as MomentInput)}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFieldValue('periodDateMin.value', createDateToRequestWithTimezone(e.target.value));
            }}
            showTimeInput
            error={errors.periodDateMin?.value}
          />
          <Field
            as={DateInput}
            name="periodDateMax.value"
            label="До"
            value={createDateFromResponse(values.periodDateMax.value as MomentInput)}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFieldValue('periodDateMax.value', createDateToRequestWithTimezone(e.target.value));
            }}
            maxDate={dates?.[1]}
            minDate={dates?.[0]}
            isDisabled={!periodId}
            showTimeInput
            error={errors.periodDateMax?.value}
          />
          <Field
            as={Select}
            name="areaId.value"
            label="Предприятие"
            options={enterprises}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFieldValue('areaId.value', e.target.value);
              setFieldValue('emissionId.value', null);
            }}
          />
          <Field
            as={Select}
            name="emissionSourceId.value"
            label="Источник выброса"
            options={sources}
            isDisabled={!values.areaId.value}
          />
          <Field
            as={Select}
            name="emissionId.value"
            label="Выход из котла"
            options={ovenExit}
            isDisabled={!values.emissionSourceId.value}
          />
          <Field as={Select} name="paramId.value" label="Параметр" options={parameters} />

          <Field
            as={Checkbox}
            name="initialData.value"
            label="Исходные данные"
            checked={values.initialData?.value}
            height={45}
            padding={0}
          />
        </Grid>

        <FilterFormControls
          onReset={(e) => {
            handleReset(e);
            setPeriodId('');
            setTimeout(submitForm, 0);
          }}
          onSubmit={submitForm}
          isLoading={isLoading}
          isDisabled={isDisabled}
        />
      </Flex>
      <PeriodsButtons periodId={periodId} periods={periods} submitPeriod={submitPeriod} />
    </FormikProvider>
  );
};
