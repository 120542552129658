import { TreeItem, TreeItemProps } from '@material-ui/lab';
import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { colors } from 'components/theme/colors';
import { useColorMode } from '@chakra-ui/react';
import clsx from 'clsx';

interface StyledTreeItemThemeProps {
  isOpen: boolean;
  isCollapse: boolean;
}

const useTreeItemStyles = makeStyles<Theme, StyledTreeItemThemeProps>(() => ({
  root: {
    width: '100%',
    '&:hover > $content': {
      backgroundColor: 'transparent',
    },
    '&:focus > $content': {
      backgroundColor: 'transparent',
    },
    '&$selected > $content': {
      background: '#BCC8D1',
      borderRight: `4px solid ${colors.blue.main}`,
      color: '#2F3741',
    },
    '&:focus > $content $label': {
      backgroundColor: 'transparent',
    },
    '&:hover > $content $label': {
      backgroundColor: 'transparent',
    },
    '&$selected > $content $label': {
      backgroundColor: 'transparent',
    },
    '&:focus$selected > $content $label': {
      backgroundColor: 'transparent',
    },
    '&:hover$selected > $content $label': {
      backgroundColor: 'transparent',
    },
  },
  rootLight: {
    '&$selected > $content': {
      background: '#F5FAFF',
      color: colors.blue.main,
    },
  },
  content: ({ isOpen }) => ({
    borderRight: '4px solid transparent',
    paddingLeft: isOpen ? '16px' : '18px',
    paddingRight: isOpen ? '12px' : '0',
    minHeight: '40px',
    flexDirection: 'row-reverse',
    fontWeight: 'normal',
    marginBottom: 4,
  }),
  group: {
    marginLeft: -90,
    paddingLeft: 107,
    '& $content': {
      paddingLeft: 50,
      marginLeft: -50,
      width: 'calc(100% + 50px)',
    },
    '& root': {
      paddingLeft: 16,
    },
  },
  selected: {},
  label: ({ isCollapse }: StyledTreeItemThemeProps) => ({
    fontWeight: 'inherit',
    color: 'inherit',
    fontSize: isCollapse ? 16 : 14,
    paddingLeft: 0,
    userSelect: 'none',
    display: 'flex',
    alignItems: 'center',
    minHeight: 40,
  }),
}));

interface Props extends TreeItemProps {
  isCollapse: boolean;
  isOpen: boolean;
}

export const StyledTreeItem: React.FC<Props> = ({ isCollapse, isOpen, ...props }) => {
  const colorMode = useColorMode();

  const { root, rootLight, ...classes } = useTreeItemStyles({
    isCollapse,
    isOpen,
  });

  return (
    <TreeItem
      className={clsx(root, colorMode.colorMode === 'light' ? rootLight : '')}
      classes={{
        ...classes,
      }}
      {...props}
    />
  );
};
