import { Box, Flex } from '@chakra-ui/react';
import * as React from 'react';
import { useCallback, useMemo } from 'react';
import { colors } from 'components/theme/colors';

interface Props {
  isHours: boolean;
  value: string;
  onChange: (value: string) => void;
}

const CustomTimeInputField:React.FC<Props> = ({ isHours, value, onChange }) => {
  const max = useMemo(() => (isHours ? 23 : 59), [isHours]);

  const addClick = useCallback((value: string) => {
    if (value && parseInt(value) >= 0) {
      let numVal = parseInt(value) + 1;
      if (numVal > max) {
        numVal = max;
      }
      onChange(String(numVal));
      return;
    }
    onChange('1');
  }, [max, onChange]);

  const subtractClick = useCallback((value: string) => {
    if (value) {
      if (parseInt(value) > 0) {
        onChange(String(parseInt(value) - 1));
        return;
      }
      onChange('0');
      return;
    }
    onChange('0');
  }, [onChange]);

  const inputOnChange = useCallback((event) => {
    event.persist();
    const caretStart = event.target.selectionStart;
    const caretEnd = event.target.selectionEnd;

    const strVal = event.target.value.replace(/[^0-9]+/g, '').slice(0, 2);
    const numVal = parseInt(strVal);

    if (numVal) {
      if (numVal > max) {
        onChange(String(max));
        return;
      }
      if (numVal < 0) {
        onChange('0');
        return;
      }
    }

    onChange(strVal);
    setTimeout(() => event.target.setSelectionRange(caretStart, caretEnd), 0);
  }, [onChange, max]);

  return (
    <Flex alignItems="center" border={`1px solid ${colors.grey[200]}`} px={2} py="2px" borderRadius="4px">
      <input
        min={0}
        max={max}
        step={1}
        pattern="[0-9]*"
        inputMode="numeric"
        value={value || ''}
        placeholder="00"
        onChange={inputOnChange}
      />
      <Flex flexDirection="column">
        <Box
          onClick={() => addClick(value)}
          style={{
            width: 0,
            height: 0,
            borderBottom: `5px solid ${colors.grey[200]}`,
            borderRight: '5px solid transparent',
            borderLeft: '5px solid transparent',
            cursor: 'pointer',
            marginBottom: 2
          }}
        />
        <Box
          onClick={() => subtractClick(value)}
          style={{
            width: 0,
            height: 0,
            borderBottom: `5px solid ${colors.grey[200]}`,
            borderRight: '5px solid transparent',
            borderLeft: '5px solid transparent',
            cursor: 'pointer',
            transform: 'rotate(180deg)'
          }}
        />
      </Flex>
    </Flex>
  );
};

export default CustomTimeInputField;
