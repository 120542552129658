import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import appLocale from 'constants/appLocale';
import { NSIRootPath } from '../../const/routes';
import {
  permissibleNoiseAddLevelPath,
  permissibleNoiseLevelApiRoot,
  permissibleNoiseLevelDicts,
  permissibleNoiseLevelPath,
} from './consts/consts';
import { useHistory } from 'react-router';
import { useCustomRights } from 'hooks/useCustomRights';
import { subsystemCodesMap } from 'constants/subsystemIdsMap';
import { useHandleDeleteData, useList } from 'hooks';
import { MaterialTable } from 'features/material-table/MaterialTableMini';
import { DeleteIcon, EditIcon } from 'components/icons';
import React, { useMemo } from 'react';
import { addFiltersToColumns, ColumnWithFilter } from 'lib/utils/tableFilter';
import { Column } from '@material-table/core';
import { TableFilterTypeEnum } from 'components/table/filters/TableFilter';
import { IPermissibleNoiseLevelItem } from './types/types';
import { SubsystemHeader } from 'components/common';
import { getSelectOptionsFromDictionaryByName } from 'features/get-select-options-from-dictionary';
import { PermissibleNoiseLevelFilter } from './PermissibleNoiseLevelFilter/PermissibleNoiseLevelFilter';
import { useDictionary } from 'hooks/useDictionary';
import { FullPageWrapper } from '../../../../components/table/FullPageWrapper';
import { DEFAULT_PAGE_SIZE_NEW_TABLE } from '../../../../constants/tables';

export const PermissibleNoiseLevel = () => {
  useUpdateBreadcrumbs([
    { breadcrumb: appLocale.mainPage.nsi, path: NSIRootPath },
    { breadcrumb: appLocale.nsi.MASKSH.permissibleNoiseLevel.title, path: permissibleNoiseLevelPath },
  ]);
  const history = useHistory();
  const { isWrite } = useCustomRights(subsystemCodesMap.nsi.MASKSH.PermissibleNoiseLevel);

  const { dictionaries } = useDictionary({ url: permissibleNoiseLevelDicts });

  const territoryTypes = useMemo(
    () => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'territoryTypes'),
    [dictionaries],
  );

  const {
    response,
    isLoading,
    filterDTO,
    toggleOrder,
    onPageChange,
    refetch,
    setFilters,
    onSubmitFilters,
    downloadAsFile,
  } = useList<IPermissibleNoiseLevelItem>(`${permissibleNoiseLevelApiRoot}/list`, {
    initialOrderFieldKey: '-territoryName',
    defaultPageSize: DEFAULT_PAGE_SIZE_NEW_TABLE,
    resetDataOnNextRequest: true,
  });
  const { ModalComponent, handleOpenModal } = useHandleDeleteData({ refetch });

  const columns = useMemo<Array<ColumnWithFilter<IPermissibleNoiseLevelItem>>>(
    () => [
      {
        title: 'Наименование',
        field: 'territoryName',
      },
      {
        title: 'Тип территории',
        field: 'territoryTypeName',
      },
      {
        title: 'Наименование нормативного документа',
        field: 'documentName',
        filterType: TableFilterTypeEnum.string,
      },
      {
        title: 'Эквивалентные уровни звука, ночь',
        field: 'eqNight',
        filterType: TableFilterTypeEnum.number,
      },
      {
        title: 'Эквивалентные уровни звука, день',
        field: 'eqDay',
        filterType: TableFilterTypeEnum.number,
      },
      {
        title: 'Максимальные уровни звука, ночь',
        field: 'maxNight',
        filterType: TableFilterTypeEnum.number,
      },
      {
        title: 'Максимальные уровни звука, день',
        field: 'maxDay',
        filterType: TableFilterTypeEnum.number,
      },
    ],
    [],
  );

  const columnsWithFilters = useMemo<Array<Column<IPermissibleNoiseLevelItem>>>(
    () => addFiltersToColumns({ filters: filterDTO.filters, setFilters, columns }),
    [columns, filterDTO.filters, setFilters],
  );

  return (
    <FullPageWrapper>
      <SubsystemHeader
        title={appLocale.nsi.MASKSH.permissibleNoiseLevel.title}
        addButtonProps={{
          onClick: () => {
            history.push(permissibleNoiseAddLevelPath);
          },
          isDisabled: !isWrite,
        }}
        filtersComponent={
          <PermissibleNoiseLevelFilter
            isLoading={isLoading}
            onSubmit={(values) => onSubmitFilters(values)}
            filters={filterDTO.filters}
            territoryTypes={territoryTypes}
          />
        }
      />
      <MaterialTable
        data={response?.data || []}
        meta={response?.meta}
        isLoading={isLoading}
        filterDTO={filterDTO}
        toggleOrder={toggleOrder}
        onPageChange={onPageChange}
        columns={columnsWithFilters}
        actions={[
          {
            position: 'row',
            action: (row) => ({
              icon: () => <EditIcon />,
              tooltip: 'Редактирование',
              onClick: async () => {
                history.push(`${permissibleNoiseLevelPath}/${row.id}`);
              },
              disabled: !isWrite,
              hidden: !isWrite,
            }),
          },
          {
            position: 'row',
            action: (row) => ({
              icon: () => <DeleteIcon />,
              tooltip: 'Удалить',
              onClick: async () => {
                handleOpenModal({ url: `${permissibleNoiseLevelApiRoot}/${row.id}` });
              },
              disabled: !isWrite,
              hidden: !isWrite,
            }),
          },
        ]}
        showTotalCount
        options={{
          selection: false,
        }}
        downloadAsFile={downloadAsFile}
      />
      {ModalComponent}
    </FullPageWrapper>
  );
};
