import React, { useEffect, useMemo } from 'react';
import { LoaderItem, PageHeader, RoutesList } from 'components/common';
import { Flex, Heading } from '@chakra-ui/react';
import appLocale from 'constants/appLocale';
import { useRequest } from 'hooks/useRequest';
import { IDictionariesListResponse } from './const/types';
import { NSIApiRouteRoot, NSIRootPath } from './const/routes';
import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import { nsiMenu, nsiMASKSHMenu } from 'features/WithSideBar/menus/nsiMenu';
import { useRoutesList } from 'hooks/useRoutesList';

export const DictionariesList: React.FC = () => {
  useUpdateBreadcrumbs([
    {
      breadcrumb: appLocale.mainPage.nsi,
      path: NSIRootPath,
    },
  ]);
  // здесь будет список из 3-х cписков категорий, но пока список всех

  const { get, isLoading, result } = useRequest<IDictionariesListResponse>(NSIApiRouteRoot);

  useEffect(() => {
    void get();
  }, [get]);

  const sortableRoutes = useRoutesList(nsiMASKSHMenu);
  // temp sort by description
  // temp filter by deleted
  const rows = useMemo(() => {
    const r = (
      result?.data
        .filter((item) => !item.deleted) || []
    )
      .map((i) => ({
        path: `${NSIRootPath}/${i.id}`,
        breadcrumb: `${i.description}: ${i.name}`,
      }));

    r.push(...sortableRoutes);
    return r.sort((a, b) => (a.breadcrumb >= b.breadcrumb ? 1 : -1));
  }, [result, sortableRoutes]);

  const routes = useRoutesList(nsiMenu);

  const paths = [
    ...routes,
    ...rows,
  ];

  return (
    <Flex flexDirection="column">
      <PageHeader>
        <Heading>{appLocale.mainPage.nsi}</Heading>
      </PageHeader>

      {isLoading ? <LoaderItem /> : <RoutesList routes={paths.filter((item) => !!item.path)} />}
    </Flex>
  );
};
