import { Dispatch, SetStateAction, useCallback, useMemo, useState } from 'react';
import { Button, IFilterValues, IServerData } from 'components/common';
import { Box, Spinner } from '@chakra-ui/react';
import { IChart } from '../../types';
import { PrintChartButton } from '../../components/PrintChartButton';
import { useRequest } from 'hooks/useRequest';
import { apiPaths } from '../../apiPaths';
import moment from 'moment';
import { getDatasetsFromServerData } from '../helpers';
import { ERROR_MESSAGES } from 'constants/messages';
import { useNotifications } from 'hooks/useNotifications';
import { createUTCTime } from 'lib/create-date';

interface IChartManageButtons {
  chart: undefined | IChart;
  filters: IFilterValues;
  setFilters: Dispatch<SetStateAction<IFilterValues>>;
  setStop: (value: string) => void;
  setStart: (value: string) => void;
}

export const ChartMetaButtons = ({ chart, filters, setFilters, setStop, setStart }: IChartManageButtons) => {
  const { errorNotify } = useNotifications();

  const [isUpdating, setIsUpdating] = useState(false);
  const [isLoadingUp, setIsLoadingUp] = useState(false);

  const handleClickResetZoom = useCallback(() => {
    if (chart) {
      chart.resetZoom();
    }
  }, [chart]);

  const handleReduceData = useCallback(() => {
    const chartMin = chart?.scales!.x!.min;
    const chartMax = chart?.scales!.x!.max;

    setIsUpdating(true);
    if (chart?.data) {
      chart.data.datasets.forEach((dataset) => {
        // @ts-ignore
        dataset.data = dataset.data.filter((point) => point.x >= chartMin && point.x <= chartMax);
      });
      chart.data.datasets = chart.data.datasets.filter((dataset) => dataset.data.length > 0);
      const startDate = createUTCTime(chartMin as unknown as string);
      if (startDate) {
        setFilters((filters) => ({ ...filters, startDate }));
        setStart(startDate);
      }
      const stopDate = createUTCTime(chartMax as unknown as string);
      if (stopDate) {
        setFilters((filters) => ({ ...filters, stopDate }));
        setStop(stopDate);
      }
      chart.update('active');
      setIsUpdating(false);
    }
  }, [chart, setFilters, setStart, setStop]);

  const path = apiPaths.reporting.values(filters);
  const { get } = useRequest<IServerData>(path);

  const getPath = useCallback(
    (startDate, stopDate) => {
      const filtersCustom = { ...filters, startDate, stopDate };
      return apiPaths.reporting.values(filtersCustom);
    },
    [filters]
  );

  const handleLoadUpData = useCallback(async () => {
    const chartMin = chart?.scales?.x?.min;
    const chartMax = chart?.scales?.x?.max;

    const isMinAfterStartDate = moment(filters.startDate).isAfter(chartMin);
    const isMaxBeforeStopDate = moment(filters.stopDate).isBefore(chartMax);
    const isChartWithinFilterDates = !isMinAfterStartDate && !isMaxBeforeStopDate;

    if (!isChartWithinFilterDates && chartMin && chartMax && chart) {
      setIsLoadingUp(true);
      const updateAdditionalData = async (path: string, method: 'push' | 'unshift') => {
        try {
          const result = await get({ url: path });
          const newDatasets = getDatasetsFromServerData(result, filters.parameters as string[]);
          newDatasets.forEach((newDataset) => {
            if (chart.data.datasets?.length) {
              const index = chart?.data.datasets.findIndex(
                (existDataset) =>
                  // кастомные свойства, поэтому игнорим ts
                  // @ts-ignore
                  existDataset.datasetId === newDataset.datasetId
              );
              // ~x === x + 1
              if (~index) {
                chart.data.datasets[index].data[method](...newDataset.data);
              }
            }
          });
          chart.update('active');
        } catch (e) {
          const message = e instanceof Error ? e.message : ERROR_MESSAGES.GET_DATA;
          errorNotify({
            message,
            key: 'ewio02',
          });
        }
      };
      try {
        if (isMinAfterStartDate) {
          const startDate = createUTCTime(chartMin as unknown as string);
          if (startDate) {
            setFilters((filters) => ({ ...filters, startDate }));
            setStart(startDate);
          }

          const path = getPath(startDate, createUTCTime(filters.startDate));
          void updateAdditionalData(path, 'unshift');
        }
        if (isMaxBeforeStopDate) {
          const stopDate = createUTCTime(chartMax as unknown as string);
          if (stopDate) {
            setFilters((filters) => ({ ...filters, stopDate }));
            setStop(stopDate);
          }

          const path = getPath(createUTCTime(filters.stopDate), stopDate);
          void updateAdditionalData(path, 'push');
        }
      } catch (e) {
        console.warn('e', e);
      } finally {
        setIsLoadingUp(false);
      }
    }
  }, [
    chart,
    errorNotify,
    filters.parameters,
    filters.startDate,
    filters.stopDate,
    get,
    getPath,
    setFilters,
    setStart,
    setStop]);

  const RightIconDelete = useMemo(() => (isUpdating ? <Spinner /> : <></>), [isUpdating]);
  const RightIconLoadUp = useMemo(() => (isLoadingUp ? <Spinner /> : <></>), [isLoadingUp]);

  return (
    <Box my={5}>
      <Button onClick={handleClickResetZoom}>Сбросить зум</Button>
      <PrintChartButton chartId="LineChart" />
      <Button rightIcon={RightIconDelete} disabled={isUpdating} ml={5} onClick={handleReduceData}>
        Убрать точки
      </Button>
      <Button rightIcon={RightIconLoadUp} disabled={isLoadingUp} ml={5} onClick={handleLoadUpData}>
        Догрузить точки
      </Button>
    </Box>
  );
};
