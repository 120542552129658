import { useEffect } from 'react';

import { useRequest } from 'hooks/useRequest';
import { TDictionariesArray } from 'models';

import {
  filterKeysToPass,
  getMASKSHDictionary,
  INoisePackage,
  MASKSHApiInstructionsDictionaries,
  MASKSHApiPackageMeteo,
  MASKSHApiPackageNoise,
} from '../consts';
import { ParsedUrlQuery } from 'querystring';

export function useMASKSHDictionary(type: 'meteo' | 'noise' | 'tech' | 'instruction') {
  let path: string;
  if (type === 'instruction') {
    path = MASKSHApiInstructionsDictionaries;
  } else {
    path = getMASKSHDictionary(type);
  }
  const { get, isLoading, result, error } = useRequest<{
    data: TDictionariesArray;
  }>(path);

  useEffect(() => {
    void get();
  }, [get]);

  return { isLoading, result, error };
}

export function useGetNoisePackage(id: string) {
  const path = `${MASKSHApiPackageNoise}/${id}`;
  const { get, isLoading, result, error } = useRequest<{
    data: INoisePackage;
  }>(path);

  useEffect(() => {
    void get();
  }, [get]);

  return { isLoading, result, error };
}

export function useGetMeteoPackage(id: string) {
  const path = `${MASKSHApiPackageMeteo}/${id}`;
  const { get, isLoading, result, error } = useRequest<{
    data: INoisePackage;
  }>(path);

  useEffect(() => {
    void get();
  }, [get]);

  return { isLoading, result, error };
}

type IUseFilteredQuery = (
  query: ParsedUrlQuery
) => string | undefined;

export const getFilteredQuery: IUseFilteredQuery = (query) => {
  let result: string | undefined;

  if (query.q && !Array.isArray(query.q)) {
    try {
      const { limit, offset, filters, searchFields, searchString } = JSON.parse(query.q);
      const filteredFilters = filters.filter((f: { key: string }) => filterKeysToPass.includes(f.key));

      const allowedFilters = {
        limit,
        offset,
        filters: filteredFilters,
        searchFields,
        searchString,
        orders: [`-${filterKeysToPass[0]}`] // TODO: check if prev order is in filterKeysToPass
      };
      result = `?q=${encodeURIComponent(JSON.stringify(allowedFilters))}`;
    } catch (err) {
      console.error(err);
    }
  }

  return result;
};
