import { Grid } from '@chakra-ui/react';
import { Field, FormikContextType, FormikErrors, FormikValues, useFormikContext } from 'formik';
import { Input, Select } from 'components/common';
import { TDictionaryItemDictionary } from 'models';

interface IProps {
  statuses: TDictionaryItemDictionary[];
  areaTypes: TDictionaryItemDictionary[];
  areaLocations: TDictionaryItemDictionary[];
  subsystems: TDictionaryItemDictionary[];
  organizations: TDictionaryItemDictionary[];
  isEditMode: boolean;
}

export const GeneralInformation = ({
  statuses,
  areaTypes,
  areaLocations,
  subsystems,
  organizations,
  isEditMode,
}: IProps) => {
  const { errors }: FormikContextType<FormikErrors<FormikValues>> = useFormikContext();

  return (
    <Grid gap={2} templateColumns="repeat(1, 450px)">
      <Field
        as={Select}
        name="subsystemId"
        label="Выбрать модуль подсистемы"
        options={subsystems}
        error={errors.subsystemId}
      />
      <Field as={Input} name="number" label="Номер площадки" isDisabled={isEditMode} error={errors?.number} />
      <Field as={Input} name="name" label="Наименование" error={errors?.name} />
      <Field as={Select} name="status" label="Статус" options={statuses} error={errors?.status} />
      <Field
        as={Select}
        name="greenAreaTypeId"
        label="Тип территории насаждения"
        options={areaTypes}
        error={errors?.greenAreaTypeId}
      />
      <Field
        as={Select}
        name="greenAreaLocationId"
        label="Месторасположение площадки"
        options={areaLocations}
        error={errors?.greenAreaLocationId}
      />
      <Field as={Select} name="organizationId" label="Организация" options={organizations} />
      {/* Пока нет информации об этом поле */}
      {/* <GridItem rowStart={6} rowEnd={6} colStart={1} colSpan={3}> */}
      {/*  <Field */}
      {/*    as={Select} */}
      {/*    name="areaTypeId" */}
      {/*    label="Выбрать модуль подсистем" */}
      {/*    options={[]} */}
      {/*    error={errors?.areaTypeId} */}
      {/*  /> */}
      {/* </GridItem> */}
    </Grid>
  );
};
