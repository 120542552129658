import { useRequest, UseRequestReturnType } from 'hooks/useRequest';
import { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Station } from 'pages/equipment/Stations/types';

interface UseStationResult {
  data?: Station;
  isLoading: boolean;
  error: UseRequestReturnType['error'];
}

export function useStation(): UseStationResult {
  const location = useLocation();
  // TODO: refactor!!!
  const { id: stationId } = useParams<{ id: string }>();
  const { get, isLoading, result, error } = useRequest<Station>(`/equipment/v10/equipments/stations/${stationId}`);

  // useEffect(() => {
  //   get();
  //   if (!location.state) {
  //     get();
  //   }
  // }, [get, location]);
  useEffect(() => {
    get();
  }, [get]);

  return {
    data: result ?? location.state,
    isLoading: location.state ? false : isLoading,
    error,
  };
}
