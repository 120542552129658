import { RenderTree } from 'features/WithSideBar/types';
import {
  WaterRouteAskzvMeasurments,
  WaterRouteAskzvRoot,
  WaterRouteRoot,
  WaterRoutesBottomSoil,
  WaterRoutesGroundCoast,
  WaterRouteSurfaceWatersRoot,
  WaterRoutesWPZ,
  WaterWPZListRoot,
} from './routes';
import appLocale from 'constants/appLocale';
import subsystemIdsMap from 'constants/subsystemIdsMap';
import { Water } from '../../home/icons';

export const WPZ_Menu: RenderTree = {
  id: WaterWPZListRoot,
  url: WaterWPZListRoot,
  name: appLocale.subsystems.wpz,
  children: [
    {
      id: WaterRoutesGroundCoast.root,
      url: WaterRoutesGroundCoast.root,
      name: appLocale.groundCoast.title,
      subsystemId: subsystemIdsMap.groundcoast,
    },
    {
      id: WaterRoutesWPZ.root,
      url: WaterRoutesWPZ.root,
      name: appLocale.wpz.title,
      subsystemId: subsystemIdsMap.waterProtectionZone,
    },
  ],
};

export const waterRoutesConfigMenu: RenderTree = {
  id: WaterRouteRoot,
  name: 'Водные объекты',
  url: WaterRouteRoot,
  subsystemId: subsystemIdsMap.waterRoot,
  homepageIcon: <Water />,
  children: [
    {
      id: WaterRouteAskzvRoot,
      url: WaterRouteAskzvMeasurments,
      name: 'АСКЗВ',
      subsystemId: subsystemIdsMap.waterAskzv,
    },
    {
      id: WaterRoutesBottomSoil.root,
      url: `${WaterRoutesBottomSoil.root}/main-data`,
      name: appLocale.subsystems.bottomSoil,
      subsystemId: subsystemIdsMap.bottomSoil,
    },
    {
      id: WaterRouteSurfaceWatersRoot,
      url: `${WaterRouteSurfaceWatersRoot}/main-data`,
      name: 'Поверхностные воды',
      subsystemId: subsystemIdsMap.surfaceWater,
    },
    WPZ_Menu
  ],
};
