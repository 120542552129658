import { useHistory, useParams } from 'react-router-dom';
import { useDictionary } from 'hooks/useDictionary';
import { useNotifications, useRequest } from 'hooks';
import React, { useCallback, useEffect } from 'react';
import { FieldsFormControls, FormHeader, LoaderItem } from 'components/common';
import { FormikProvider, useFormik } from 'formik';
import { IFTPPattern, ISource } from '../Consts/types';
import { createTouchedErrors } from 'features/create-touched-errors';
import { Tab, TabList, TabPanel, TabPanels, Tabs, Box } from '@chakra-ui/react';
import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import appLocale from 'constants/appLocale';
import { FTPRootPath } from '../../consts';
import { DataPatternMainTab } from './DataPatternMainTab';
import { useGoBackWithFallback } from 'lib/utils/goBackWithFallback';
import { DataPatternParamsTab } from './DataPatternParamsTab';
import { ERROR_MESSAGES, SUCCESS_MESSAGES } from 'constants/messages';
import { dataPatternRootPath } from '../Consts/consts';
import { ftpDataPatternSchema } from 'models/schemas/admin/ftpDataPattern';

const initialValues = {
  active: true,
  content: {},
  created: '',
  deleted: false,
  id: '',
  subsystemId: '',
  templateName: '',
};

export const DataPatternEditForm = () => {
  const { id } = useParams<{ id: string }>();
  const { backWithFallback } = useGoBackWithFallback();
  const { successNotify, errorNotify } = useNotifications();
  const history = useHistory();

  useUpdateBreadcrumbs([
    {
      breadcrumb: appLocale.admin.main,
      path: '/admin',
    },
    {
      breadcrumb: appLocale.admin.ftp,
      path: FTPRootPath,
    },
    {
      breadcrumb: appLocale.admin.ftpDataPatterns,
      path: `${FTPRootPath}/dataPatterns`,
    },
    {
      breadcrumb: `${id ? 'Редактирование' : 'Добавление'} шаблона`,
      path: `${FTPRootPath}/dataPatterns/${id ? `edit/${id}` : 'add'}`,
    },
  ]);

  const { isLoading: isAllSourcesLoading, get: getAllSources, result: allSources } = useRequest<ISource[]>('');

  const onSuccess = useCallback(
    (data: IFTPPattern | null) => {
      if (data) {
        void getAllSources({ url: `${dataPatternRootPath}/dictionary/sources/${data.subsystemId}` });
      }
    },
    [getAllSources],
  );

  const onCreateSuccess = () => {
    history.push(`${FTPRootPath}/dataPatterns`);
    successNotify({
      key: 'dataPattern/add/success',
      message: SUCCESS_MESSAGES.CREATE,
    });
  };

  const onCreateError = () => {
    errorNotify({
      key: 'dataPattern/add/error',
      message: ERROR_MESSAGES.CREATE,
    });
  };

  const onUpdateSuccess = () => {
    history.push(`${FTPRootPath}/dataPatterns`);
    successNotify({
      key: 'dataPattern/update/success',
      message: SUCCESS_MESSAGES.EDIT,
    });
  };

  const onUpdateError = () => {
    errorNotify({
      key: 'dataPattern/update/error',
      message: ERROR_MESSAGES.EDIT,
    });
  };

  const { dictionaries, isDictionariesLoading } = useDictionary({ url: `${dataPatternRootPath}/dictionary` });
  const {
    isLoading: isPatternLoading,
    result: pattern,
    get: getPattern,
  } = useRequest<IFTPPattern>(`${dataPatternRootPath}/${id}`, onSuccess);
  const { post: createPattern } = useRequest<IFTPPattern>(dataPatternRootPath, onCreateSuccess, onCreateError);

  const { put: updatePattern } = useRequest<IFTPPattern>(dataPatternRootPath, onUpdateSuccess, onUpdateError);

  const form = useFormik<IFTPPattern>({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    initialValues: { ...initialValues, ...pattern },
    validationSchema: ftpDataPatternSchema,
    onSubmit: (values) => (id ? updatePattern(values) : createPattern(values)),
  });

  useEffect(() => {
    if (id) {
      void getPattern();
    }
  }, [getPattern, id]);

  if (isPatternLoading || isDictionariesLoading || isAllSourcesLoading) {
    return <LoaderItem />;
  }

  return (
    <FormikProvider
      value={{
        ...form,
        errors: createTouchedErrors(form.errors, form.touched),
      }}
    >
      <Box flexDirection="column">
        <Tabs isLazy>
          <FormHeader
            onBackClick={() => {
              backWithFallback(`${FTPRootPath}/dataPatterns`);
            }}
            header={`${id ? 'Редактирование' : 'Добавление'} шаблона данных`}
          >
            <TabList>
              <Tab>Общие сведения</Tab>
              <Tab isDisabled={!form.values.subsystemId}>Станции и параметры</Tab>
            </TabList>
          </FormHeader>
          <TabPanels>
            <TabPanel>
              <DataPatternMainTab dictionaries={dictionaries} getAllSources={getAllSources}>
                <FieldsFormControls
                  onCancel={() => backWithFallback(`${FTPRootPath}/dataPatterns`)}
                  handleSubmit={form.handleSubmit}
                  showSave
                />
              </DataPatternMainTab>
            </TabPanel>
            <TabPanel>
              <DataPatternParamsTab allSources={allSources}>
                <FieldsFormControls
                  onCancel={() => backWithFallback(`${FTPRootPath}/dataPatterns`)}
                  handleSubmit={form.handleSubmit}
                  showSave
                />
              </DataPatternParamsTab>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </FormikProvider>
  );
};
