import { AttachmentsTabContent, Button, DateInput, FormWrapper, Input, Select } from 'components/common';
import { Box, Flex, Grid, Heading } from '@chakra-ui/react';
import {
  getSelectOptionsFromDictionaryByName,
  getSelectOptionsFromDictionaryByNameWithParent,
} from 'features/get-select-options-from-dictionary';
import { Field, useFormikContext } from 'formik';
import { ReperItem } from 'hooks/geo/Reper/useGeoReperList';
import { createDateFromResponse, createDateToRequestWithTimezone } from 'lib/create-date';
import React, { useMemo } from 'react';
import { useObjectType } from 'hooks/useObjectType';
import { FieldFormProps } from 'types/forms';
import subsystemIdsMap from 'constants/subsystemIdsMap';
import { useAvailability } from 'hooks';
import { getFilteredOptionsByPrivileges } from 'features/getFilteredOptionsByPrivileges';

const FieldsForm = ({ onCancel, dictionaries, onMediaChange, media, uploadItemId, isEditMode }: FieldFormProps) => {
  const { isAvailable } = useAvailability(subsystemIdsMap.geoecologyRoot);

  const { setFieldValue, values, errors, handleSubmit, initialValues } = useFormikContext<ReperItem>();

  const params = [
    values.geodeticCoordinateX,
    values.geodeticCoordinateY,
    values.absoluteMarkZ,
    values.accuracyX,
    values.accuracyY,
    values.accuracyZ,
  ];

  const types = useMemo(
    () => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'types', false, initialValues.geoPSourceType),
    [dictionaries?.data, initialValues.geoPSourceType],
  );

  const areas = useMemo(
    () =>
      getFilteredOptionsByPrivileges(
        getSelectOptionsFromDictionaryByName(dictionaries?.data, 'areas'),
        'isEditable',
        'value',
        values.regArea,
      ),
    [dictionaries?.data, values.regArea],
  );

  const objectType = useObjectType(dictionaries);

  const points = useMemo(() => {
    const points = getSelectOptionsFromDictionaryByNameWithParent(dictionaries?.data, 'points');

    if (values.regArea && values.geoPSourceType) {
      return points.filter((item) => item.parent === values.regArea && item.pointTypeId === values.geoPSourceType);
    }

    return points;
  }, [dictionaries?.data, values.regArea, values.geoPSourceType]);

  const submitIsDisabled = [...params].filter((val) => !!val).length === 0;

  return (
    <Box flexDirection="column" mb={4}>
      <FormWrapper>
        <Grid marginTop="30px" gap="15px">
          <Heading variant="h2">Источник измерения</Heading>

          <Field
            as={Select}
            name="regArea"
            label="Участок наблюдения"
            options={areas}
            error={errors.regArea}
            isDisabled={isEditMode}
          />
          <Field
            as={Select}
            name="geoPSourceType"
            label="Тип пункта"
            options={types}
            error={errors.geoPSourceType}
            isDisabled={isEditMode}
          />
          <Field
            as={Select}
            name="regSource"
            label="Точка наблюдения"
            options={points}
            error={errors.regSource}
            isDisabled={isEditMode || !(values.regArea && values.geoPSourceType)}
          />
          <DateInput
            name="valDateTime"
            label="Дата обследования"
            value={createDateFromResponse(values.valDateTime)}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const time = createDateToRequestWithTimezone(e.target.value);
              setFieldValue('valDateTime', time);
            }}
            error={errors.valDateTime}
            showTimeInput={false}
            isDisabled={isEditMode}
          />
          <Field
            as={Input}
            name="geodeticCoordinateX"
            label="Геодезическая координата X"
            isDisabled={!isAvailable}
            error={errors.geodeticCoordinateX}
          />
          <Field
            as={Input}
            name="geodeticCoordinateY"
            label="Геодезическая координата Y"
            isDisabled={!isAvailable}
            error={errors.geodeticCoordinateY}
          />
          <Field
            isDisabled={!isAvailable}
            as={Input}
            name="absoluteMarkZ"
            label="Абсолютная отметка Z"
            error={errors.absoluteMarkZ}
          />
          <Field isDisabled={!isAvailable} as={Input} name="accuracyX" label="Точность X" error={errors.accuracyX} />
          <Field isDisabled={!isAvailable} as={Input} name="accuracyY" label="Точность Y" error={errors.accuracyY} />
          <Field isDisabled={!isAvailable} as={Input} name="accuracyZ" label="Точность Z" error={errors.accuracyZ} />

          {onMediaChange && isAvailable && (
            <AttachmentsTabContent
              objectType={objectType}
              media={media}
              onMediaChange={onMediaChange}
              uploadItemId={uploadItemId}
            />
          )}

          <Flex>
            <Button onClick={onCancel} marginRight="auto">
              Отмена
            </Button>
            <Button isDisabled={!isAvailable || submitIsDisabled} onClick={() => handleSubmit()} variant="solid">
              Сохранить
            </Button>
          </Flex>
        </Grid>
      </FormWrapper>
    </Box>
  );
};

export default React.memo(FieldsForm);
