import { GreenPlantsApiRootPath } from 'pages/routes/GreenPlants';
import { useRequest } from 'hooks/useRequest';
import { ILawnDto } from './useLawnsList';
import { TBaseGreenPlantsFormProperties } from '../Form/types';

const useLawnListItem = (registryId: TBaseGreenPlantsFormProperties<string>['registryId']) => {
  const { get, result, isLoading, error } = useRequest<ILawnDto>(`${GreenPlantsApiRootPath}/lawns/${registryId}`);

  return { get, result, isLoading, error };
};

export { useLawnListItem };
