import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import appLocale from 'constants/appLocale';
import { PEL_ROOT_PATH, RAID_REPORT_PATH } from '../constants';
import { generatePath, Redirect, useParams } from 'react-router-dom';

const RaidDetail: React.FunctionComponent = () => {
  const { id } = useParams<{ id: string }>();

  useUpdateBreadcrumbs([
    {
      breadcrumb: appLocale.pel.main,
      path: PEL_ROOT_PATH,
    },
  ]);

  return <Redirect to={generatePath(RAID_REPORT_PATH, { id })} />;
};

export default RaidDetail;
