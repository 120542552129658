import React from 'react';
import { FormHeader } from 'components/common';
import { ERROR_MESSAGES, SUCCESS_MESSAGES } from 'constants/messages';
import { createTouchedErrors } from 'features/create-touched-errors';
import { FormikProvider, useFormik } from 'formik';
import { useNotifications } from 'hooks/useNotifications';
import { useRequest } from 'hooks/useRequest';
import { PeriodSchema } from 'models/schemas/admin/period';
import { useHistory } from 'react-router-dom';
import { baseAdminPeriodUrl } from '../consts';
import FieldsForm from './FieldsForm';

const initialValues = {
  code: '',
  name: '',
  script: '',
  userId: null,
  subsystemId: null,
  deleted: false,
};

export const AddPeriodForm = (): JSX.Element => {
  const history = useHistory();
  const { successNotify, errorNotify } = useNotifications();

  const successHandler = () => {
    successNotify({
      key: 'file/create/success',
      message: SUCCESS_MESSAGES.CREATE,
    });
    history.push('/admin/period');
  };
  const errorHandler = (error: any) => {
    errorNotify({
      key: 'file/create/error',
      message: `${ERROR_MESSAGES.CREATE}. ${typeof error === 'string' ? error : ''}`,
    });
  };

  const validationErrorHandler = () => {
    const errorMessage = 'Некорректное значение периода.';
    formik.setErrors({ script: errorMessage });
  };

  const { post: createPeriod, isLoading } = useRequest(baseAdminPeriodUrl, successHandler, errorHandler);
  const { post: validatePeriod } = useRequest(`${baseAdminPeriodUrl}/validate`, undefined, validationErrorHandler);

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    initialValues,
    validationSchema: PeriodSchema,
    onSubmit: (values) => {
      createPeriod(values);
      validatePeriod({
        value: values.script
      });
    },
  });

  return (
    <FormikProvider
      value={{
        ...formik,
        errors: createTouchedErrors(formik.errors, formik.touched),
      }}
    >
      <FormHeader url="/admin/period" header="Добавление периода" />
      <FieldsForm onCancel={() => history.push('/admin/period')} isLoading={isLoading} />
    </FormikProvider>
  );
};
