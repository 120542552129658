import * as yup from 'yup';
import { requiredError } from '../constans';

export const RoleSchema = yup.object().shape({
  name: yup.string().required(requiredError),
  code: yup
    .string()
    .required(requiredError)
    .matches(/^[/^[A-z0-9]+$/, 'Допускаются только английские буквы'),
});
