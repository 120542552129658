import { useRequest } from 'hooks/useRequest';
import { TDictionariesArray } from 'models/dictionaries';
import { useEffect } from 'react';
import { baseAdminUrl } from 'pages/admin';
import { useUtilDictionaries } from 'hooks/useUtilDictionaries';

export function useFTPUploadDictionary() {
  const { get, isLoading, result, error } = useRequest<{
    data: TDictionariesArray;
  }>(`${baseAdminUrl}/upload-config/dictionary`);

  useEffect(() => {
    void get();
  }, [get]);

  const { exchanges, status, uploadTemplates } = useUtilDictionaries(result?.data ?? []);

  return { isLoading, result, error, exchanges, status, uploadTemplates };
}
