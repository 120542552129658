import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { Field, useFormikContext } from 'formik';
import { DateInput, Input, Select } from 'components/common';
import { DictionariesItem } from 'models';
import { createDateFromResponse, createDateToRequestWithTimezone } from 'lib/create-date';
import { useRequest } from 'hooks';

import { askzvLocales } from '../../locales';
import { IFormIASKZVPackage } from '../../types';
import { apiPathAskzv } from '../../apiPath';
import { Grid } from '@chakra-ui/react';

interface ITab {
  isLoading: boolean;
  isEdit?: boolean;
  sources: DictionariesItem;
}

interface IStation {
  id: string;
  latitude: string;
  longitude: string;
  stationNumber: number;
  stationName: string;
}
export const SourceTab = ({ isLoading, sources, isEdit }: ITab) => {
  const { setFieldValue, values, validateField } = useFormikContext<IFormIASKZVPackage>();
  const [isLoadingStation, setIsLoadingStation] = useState(false);

  const { get } = useRequest<IStation>('');
  const setStationData = useCallback(async (stationId:string) => {
    try {
      setFieldValue('stationId', stationId);
      setIsLoadingStation(true);
      const data = await get({ url: apiPathAskzv.station(stationId) });
      if (data &&
        'longitude' in data &&
        'latitude' in data &&
        'stationName' in data &&
        'stationNumber' in data) {
        setFieldValue('longitude', data.longitude);
        setFieldValue('latitude', data.latitude);
        setFieldValue('stationName', data.stationName);
        setFieldValue('stationNumber', data.stationNumber);
      }
    } catch (e) {
      console.warn(e);
    } finally {
      setIsLoadingStation(false);
    }
  }, [get, setFieldValue]);

  useEffect(() => {
    if (!values.latitude && !values.longitude && values.stationId) {
      setStationData(values.stationId);
    }
  }, [isEdit, setStationData, values.latitude, values.longitude, values.stationId]);

  const handleChangeStation = useCallback(
    async (e: ChangeEvent<HTMLSelectElement>) => {
      if (e.target.value) {
        setStationData(e.target.value);
      } else {
        setFieldValue('stationId', null, true);
        setFieldValue('longitude', '');
        setFieldValue('latitude', '');
        setFieldValue('stationNumber', '');
        setTimeout(() => {
          validateField('stationId');
        });
      }
    },
    [setFieldValue, setStationData, validateField]
  );

  return (
    <Grid gap={2}>
      <Field
        as={Select}
        name="stationId"
        label={askzvLocales.stationId}
        value={values.stationId}
        options={sources}
        onChange={handleChangeStation}
        isDisabled={isLoading || isLoadingStation || isEdit}
      />
      <Field
        as={Input}
        label={askzvLocales.stationNumber}
        name="stationName"
        value={values.stationNumber}
        isDisabled
      />
      <Field
        as={Input}
        label={askzvLocales.longitude}
        name="longitude"
        value={values.longitude}
        isDisabled
      />
      <Field
        as={Input}
        label={askzvLocales.latitude}
        isDisabled
        name="latitude"
        value={values.latitude}
      />
      <Field
        as={DateInput}
        name="measuringDatetime"
        label={askzvLocales.measuringDatetime}
        value={createDateFromResponse(values.measuringDatetime)}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          setFieldValue('measuringDatetime', createDateToRequestWithTimezone(e.target.value))
        }
        showTimeInput
        isDisabled={isLoading || isEdit}
      />
    </Grid>
  );
};
