import { Column } from '@material-table/core';
import { createLocalDateDDMMYYYY } from 'lib/create-date';

export const deviceColumns: Column<any>[] = [
  {
    title: 'Дата от',
    field: 'dateFrom',
    render: (row) => createLocalDateDDMMYYYY(row.dateFrom)
  },
  {
    title: 'Дата до',
    field: 'dateTo',
    render: (row) => createLocalDateDDMMYYYY(row.dateTo)
  },
  {
    title: 'Станция',
    field: 'station',
  },
  {
    title: 'Статус',
    field: 'status',
  },
];

export const stationColumns: Column<any>[] = [
  {
    title: 'Дата от',
    field: 'dateFrom',
    render: (row) => createLocalDateDDMMYYYY(row.dateFrom)
  },
  {
    title: 'Дата до',
    field: 'dateTo',
    render: (row) => createLocalDateDDMMYYYY(row.dateTo)
  },
  {
    title: 'Прибор',
    field: 'deviceInventName',
  },
  {
    title: 'Статус',
    field: 'deviceStatus',
  },
];
