import { ReportSmall } from 'features/WithSideBar/icons';
import { subsystemCodesMap } from 'constants/subsystemIdsMap';

export const pdkExcessMenu = {
  name: 'Журнал превышения ПДК',
  url: '/pdk-excess-journal',
  id: '/pdk-excess-journal',
  icon: <ReportSmall />,
  otherCode: subsystemCodesMap.pdkExcessJournal,
};
