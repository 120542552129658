import { Checkbox, Input } from 'components/common';
import { Grid } from '@chakra-ui/react';
import { Field, useFormikContext } from 'formik';
import React from 'react';
import { TDictionaryItemDictionary } from 'models';
import { CommaNumberInput } from '../../../../../components/common/Input/comma-number-input';
import { get } from 'lodash';

export interface ICountProps {
  rightLabel: string;
  leftLabel: string;
  setFieldValue: (name: string, val: any) => void;
  leftFormula: (value: number) => number;
  rightFormula: (value: number) => number;
  parameters: TDictionaryItemDictionary[];
  leftUnit?: string;
  rightUnit?: string;
  rightParam?: string;
  leftParam?: string;
  isAnalytics?: boolean;
}

export const CountForm = (
  {
    rightLabel,
    leftLabel,
    setFieldValue,
    leftFormula,
    rightFormula,
    parameters,
    leftUnit,
    rightUnit,
    leftParam,
    rightParam,
    isAnalytics,
  }: ICountProps
) => {
  const { values } = useFormikContext();

  const leftParamId = leftParam ||
    (parameters
      ?.find((param) => param.name === leftLabel)?.value);
  const rightParamId = rightParam ||
    (parameters
      ?.find((param) => param.name === rightLabel)?.value);

  const leftIdx = parameters?.findIndex((param) => {
    if (leftUnit) {
      return param.value === leftParamId && param.parent === leftUnit;
    }
    return param.value === leftParamId;
  });

  const rightIdx = parameters?.findIndex((param) => {
    if (rightUnit) {
      return param.value === rightParamId && param.parent === rightUnit;
    }
    return param.value === rightParamId;
  });

  const recountSecondParam = (value: string) => {
    const paramValue = Number(value) || 0;

    if (value === '') {
      setFieldValue(`values[${rightIdx}].value`, '');
    } else {
      setFieldValue(`values[${rightIdx}].value`,
        rightFormula(paramValue).toFixed(5));
    }

    const unitId = parameters?.find((param) => param.name === rightLabel)?.parent;
    setFieldValue(`values[${rightIdx}].parameterId`, rightParamId);
    setFieldValue(`values[${rightIdx}].unitId`, rightUnit || unitId);
    setFieldValue(`values[${rightIdx}].recalculated`, true);
    setFieldValue(`values[${leftIdx}].recalculated`, false);
  };

  const recountFirstParam = (value: string) => {
    const paramValue = Number(value) || 0;

    if (value === '') {
      setFieldValue(`values[${leftIdx}].value`, '');
    } else {
      setFieldValue(`values[${leftIdx}].value`,
        leftFormula(paramValue).toFixed(5));
    }

    const unitId = parameters?.find((param) => param.name === leftLabel)?.parent;
    setFieldValue(`values[${leftIdx}].parameterId`, leftParamId);
    setFieldValue(`values[${leftIdx}].unitId`, leftUnit || unitId);
    setFieldValue(`values[${leftIdx}].recalculated`, true);
    setFieldValue(`values[${rightIdx}].recalculated`, false);
  };

  const firstChecked = get(values, `values[${leftIdx}].recalculated`);
  const secondChecked = get(values, `values[${rightIdx}].recalculated`);

  // turned on by default
  const checked = firstChecked === undefined && secondChecked === undefined ? true : (firstChecked || secondChecked);

  return (
    <Grid gap={1} templateColumns="2fr 2fr" templateRows="repeat(1, 1fr)">
      <Field
        as={isAnalytics ? CommaNumberInput : Input}
        label={leftLabel}
        name={`values[${leftIdx}].value`}
        type={isAnalytics ? 'text' : 'number'}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setFieldValue(`values[${leftIdx}].value`, e.target.value);
          const unitId = parameters?.find((param) => param.name === leftLabel)?.parent;
          setFieldValue(`values[${leftIdx}].parameterId`, leftParamId);
          setFieldValue(`values[${leftIdx}].unitId`, leftUnit || unitId);
          if (checked) {
            recountSecondParam(e.target.value);
          }
        }}
      />
      <Field
        as={isAnalytics ? CommaNumberInput : Input}
        label={rightLabel}
        name={`values[${rightIdx}].value`}
        type={isAnalytics ? 'text' : 'number'}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setFieldValue(`values[${rightIdx}].value`, e.target.value);
          const unitId = parameters?.find((param) => param.name === rightLabel)?.parent;
          setFieldValue(`values[${rightIdx}].unitId`, rightUnit || unitId);
          setFieldValue(`values[${rightIdx}].parameterId`, rightParamId);

          if (checked) {
            recountFirstParam(e.target.value);
          }
        }}
      />
      <Checkbox
        checked={!!checked}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          if (e.target.checked) {
            const firstVal = get(values, `values[${leftIdx}].value`);
            const secondVal = get(values, `values[${rightIdx}].value`);

            if (firstVal) {
              recountSecondParam(firstVal);
            } else if (secondVal) {
              recountFirstParam(secondVal);
            }
          } else {
            setFieldValue(`values[${rightIdx}].recalculated`, false);
            setFieldValue(`values[${leftIdx}].recalculated`, false);
          }
        }}
        label="Пересчет значения"
      />
    </Grid>
  );
};
