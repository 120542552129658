import { AxiosRequestConfig } from 'axios';
import React from 'react';
import { Flex } from '@chakra-ui/react';

import { InputFileMetadata } from 'features/UploadedFileProvider/types';
import { createInputFileMetadata } from '../DragAndDrop/utils';
import { DragAndDropController } from '../DragAndDrop/DragAndDrop/controller';
import { InputFileMetadataController } from './InputFileMetadaContoller';

export interface DragAndDropProps {
  inputFileMetadata?: InputFileMetadata;
  setInputFileMetadata: React.Dispatch<React.SetStateAction<InputFileMetadata | undefined>>;
  isDisabled: boolean;
  onChangeAsFormData: (formData: FormData, config: AxiosRequestConfig) => void;
}

export const UploadFileModalContentDragAndDrop: React.FC<DragAndDropProps> = ({
  inputFileMetadata,
  setInputFileMetadata,
  isDisabled,
  onChangeAsFormData,
}) => {
  const handleChangeFile = React.useCallback(
    (file: File) => {
      if (typeof setInputFileMetadata === 'function') {
        setInputFileMetadata(createInputFileMetadata(file));
      }

      const formData = new FormData();

      formData.append('file', file);

      onChangeAsFormData(formData, {
        onUploadProgress: (progressEvent): void => {
          const { loaded, total } = progressEvent;

          const percent = Math.floor((loaded * 100) / total);

          if (typeof setInputFileMetadata === 'function') {
            setInputFileMetadata((prevState) => ({
              ...prevState,
              percentage: percent,
              loaded,
              isLoading: true,
            }));
          }
        },
      });
    },
    [onChangeAsFormData, setInputFileMetadata],
  );

  return (
    <Flex flexDirection="column">
      <DragAndDropController isDisabled={isDisabled} onChange={handleChangeFile} />
      <InputFileMetadataController inputFileMetadata={inputFileMetadata} setInputFileMetadata={setInputFileMetadata} />
    </Flex>
  );
};
