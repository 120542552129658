import React from 'react';
import { PageHeader, RoutesList } from 'components/common';
import { IndustrialRootPath, MonitoringDataPath } from '..';
import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import appLocale from 'constants/appLocale';
import { Industrial_PDV_RootPath } from '../PDV/consts';
import { Industrial_PDV_NoSource_RootPath } from 'pages/data/IndustrialEmissions/PDVNoSource/consts';
import { Flex, Heading } from '@chakra-ui/react';

export const IndustrialEmissionsList: React.FC = () => {
  useUpdateBreadcrumbs([
    { breadcrumb: appLocale.common.data, path: '/data' },
    {
      breadcrumb: 'Мониторинг пром. выбросов',
      path: IndustrialRootPath,
    },
  ]);

  return (
    <Flex flexDirection="column">
      <PageHeader>
        <Heading>Мониторинг промышленных выбросов в атмосферу</Heading>
      </PageHeader>
      <RoutesList
        routes={[
          {
            path: MonitoringDataPath,
            breadcrumb: 'Данные мониторинга',
          },
          {
            path: Industrial_PDV_RootPath,
            breadcrumb: 'ПДВ по источникам выбросов',
          },
          {
            path: Industrial_PDV_NoSource_RootPath,
            breadcrumb: 'ПДВ по предприятиям',
          },
        ]}
      />
    </Flex>
  );
};
