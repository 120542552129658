import * as yup from 'yup';
import { maxSymbols, numberError, requiredError } from '../constans';
import appLocale from 'constants/appLocale';

export const AnalyticsWashoutSchema = yup.object()
  .shape({
    protocolNumber: yup.string()
      .required(requiredError)
      .nullable()
      .max(15, maxSymbols(15)),
    protocolDate: yup.string()
      .typeError(requiredError)
      .required(requiredError),
    consumerId: yup.string()
      .required(requiredError)
      .nullable(),
    researchBasis: yup.string()
      .required(requiredError)
      .nullable()
      .max(511, maxSymbols(511)),
    researchObject: yup.string()
      .required(requiredError)
      .nullable()
      .max(255, maxSymbols(255)),
    supportingDocuments: yup.string()
      .required(requiredError)
      .nullable()
      .max(255, maxSymbols(255)),
    sampleNumber: yup.string()
      .required(requiredError)
      .nullable()
      .max(15, maxSymbols(15)),
    areaId: yup.string()
      .required(requiredError)
      .nullable(),
    pointId: yup.string()
      .when('areaId', {
        is: (val: string | null) => Boolean(val),
        then: (schema) => schema,
        otherwise: (schema) => schema.required(`Необходимо выбрать ${appLocale.analytics.washout.fields.areaId}`),
      })
      .required(requiredError)
      .nullable(),
    date: yup.string()
      .typeError(requiredError)
      .required(requiredError),
    organizationId: yup.string()
      .required(requiredError)
      .nullable(),
    explanationFromConsumer: yup.string()
      .required(requiredError)
      .nullable()
      .max(511, maxSymbols(511)),
    sampleReceiveDate: yup.string()
      .typeError(requiredError)
      .required(requiredError),
    researchDateStart: yup.string()
      .typeError(requiredError)
      .required(requiredError),
    researchDateEnd: yup.string()
      .typeError(requiredError)
      .required(requiredError),
    sampleLaboratoryNumber: yup.string()
      .required(requiredError)
      .nullable()
      .max(15, maxSymbols(15)),
    explanation: yup.string(),
    status: yup.string()
      .required(requiredError)
      .nullable(),
    parameters: yup.array()
      .of(
        yup.object()
          .shape({
            value: yup.number()
              .typeError(numberError)
              .nullable()
              .positive('Должно быть положительным'),
          }),
      ),
    sign: yup
      .object()
      .shape({
        signers: yup.array()
          .of(
            yup.object()
              .shape({
                action: yup.string()
                  .required(requiredError)
                  .nullable(),
                position: yup.string()
                  .required(requiredError)
                  .nullable()
                  .max(511, maxSymbols(511)),
                name: yup.string()
                  .required(requiredError)
                  .nullable()
                  .max(255, maxSymbols(255)),
              }),
          ),
      })
      .nullable(),
  });
