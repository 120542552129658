import * as yup from 'yup';
import { atLeastOneError, numberError, requiredError } from '../constans';

export const DangerProcessSchema = yup.object().shape({
  measuringDate: yup.string().nullable().required(requiredError),
  areaId: yup.string().nullable().required(requiredError),
  pointId: yup.string().nullable().required(requiredError),
  value: yup.string().required(requiredError),
});

export const ReperSchema = yup.object().shape({
  regArea: yup.string().nullable().required(requiredError),
  regSource: yup.string().nullable().required(requiredError),
  geoPSourceType: yup.string().nullable().required(requiredError),
  valDateTime: yup.string().nullable().required(requiredError),
  geodeticCoordinateX: yup.number().typeError(numberError).nullable(),
  geodeticCoordinateY: yup.number().typeError(numberError).nullable(),
  absoluteMarkZ: yup.number().typeError(numberError).nullable(),
  accuracyX: yup.number().typeError(numberError).nullable(),
  accuracyY: yup.number().typeError(numberError).nullable(),
  accuracyZ: yup.number().typeError(numberError).nullable(),
});

export const InclinometerSchema = yup.object().shape({
  valDateTime: yup.string().nullable().required(requiredError),
  areaId: yup.string().nullable().required(requiredError),
  pointId: yup.string().nullable().required(requiredError),
  data: yup.array().of(
    yup.object().shape({
      accuracy: yup.number().typeError(numberError).nullable(),
      xOffset: yup.number().typeError(numberError).nullable(),
      yOffset: yup.number().typeError(numberError).nullable(),
    }),
  ),
}).test((values) => {
  let lastIndex = 0;
  const length = values.data?.length || 0;

  for (let i = length - 1; i >= 0; i--) {
    const isAnyValue = (values && values?.data && values?.data[i].xOffset) ||
      (values && values?.data && values?.data[i].yOffset) ||
      (values && values?.data && values?.data[i].accuracy);

    if (isAnyValue) {
      lastIndex = i;
      break;
    }
  }

  const requiredData = values.data?.slice(0, lastIndex);

  const errors: yup.ValidationError[] = [];
  requiredData?.forEach((item, idx) => {
    const isError = !item.xOffset && !item.yOffset && !item.accuracy;
    if (isError) {
      const fieldErrors = [
        new yup.ValidationError(atLeastOneError, '', `data.${idx}.xOffset`),
        new yup.ValidationError(atLeastOneError, '', `data.${idx}.yOffset`),
        new yup.ValidationError(atLeastOneError, '', `data.${idx}.accuracy`)
      ];
      errors.push(...fieldErrors);
    }
  });

  if (errors.length > 0) {
    return new yup.ValidationError(errors);
  }
  return true;
});
