export const appealRoot = 'appeal/v10';

export const complaintsAdd = `${appealRoot}/appeals/complaints`;
export const incidentAdd = `${appealRoot}/appeals/incidents`;
export const answerAdd = `${appealRoot}/appeals/answers`;

export const raidsDictionariesPath = `${appealRoot}/raids/dictionaries`;
export const tasksDictionariesPath = `${appealRoot}/tasks/dictionaries`;

export const getComplaintEndpoint = (id?: string) => `${complaintsAdd}/${id}`;
export const getIncidentEditEndpoint = (id?: string) => `${incidentAdd}/${id}`;
export const getAnswerEditEndpoint = (id?: string) => `${answerAdd}/${id}`;
