import { ERROR_MESSAGES, SUCCESS_MESSAGES } from 'constants/messages';
import { createTouchedErrors } from 'features/create-touched-errors';
import { FormikProvider, useFormik } from 'formik';
import { useNotifications } from 'hooks/useNotifications';
import { useRequest } from 'hooks/useRequest';
import { useCallback, useMemo, useState } from 'react';

import { useLocation, useParams } from 'react-router-dom';
import { emptyInitialValues } from './AddSoilResult';
import FieldsForm from './FieldsForm';
import { IUploadMedia } from 'components/common/FileUploadDragAndDrop/types';
import { updateFiles } from 'api/services/media';
import { useObjectType } from 'hooks/useObjectType';
import { SoilFormValidationSchema } from 'models/schemas/soil/soil';
import { useGoBackWithFallback } from 'lib/utils/goBackWithFallback';
import { useDictionary } from 'hooks/useDictionary';

export const EditSoilResult = () => {
  const { backWithFallback } = useGoBackWithFallback();
  const location = useLocation();
  const [media, setMedia] = useState<IUploadMedia[]>([]);
  const {
    isDictionariesLoading,
    dictionaries
  } = useDictionary({ url: '/soilpollution/v10/soilpollution/dictionaries' });
  const objectType = useObjectType(dictionaries);
  const editedObject = location.state;
  const { id } = useParams<{ id: string }>();

  const filtered = Object.keys(editedObject)
    .filter((key) => Object.keys(emptyInitialValues).includes(key))
    .reduce((obj, key) => {
      // @ts-ignore
      obj[key] = editedObject[key];
      return obj;
    }, {});
  const initialValues = {
    ...emptyInitialValues,
    ...(filtered || {}),
  };

  const { successNotify, errorNotify } = useNotifications();

  const onError = useCallback(
    (message?: string | null) => {
      errorNotify({
        key: message || 'file/create/error',
        message: message || ERROR_MESSAGES.CREATE,
      });
    },
    [errorNotify]
  );

  const onSuccess = () => {
    successNotify({
      key: 'file/edit/success',
      message: SUCCESS_MESSAGES.EDIT,
    });

    updateFiles(id, objectType, media)
      .then(() => {
        backWithFallback('/data/soil');
      })
      .catch((error: Error) => {
        onError(error.message);
      });
  };

  const { put, isLoading } = useRequest('/soilpollution/v10/soilpollution', onSuccess, onError);

  const formik = useFormik({
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    validationSchema: SoilFormValidationSchema(),
    initialValues,
    onSubmit: (values) => put(values),
  });

  const errors = useMemo<typeof formik.errors>(() => {
    const e = createTouchedErrors(formik.errors, formik.touched);
    if (e.parameters) {
      if (!formik.submitCount) {
        Object.keys(e.parameters).forEach((id) => {
          // @ts-ignore
          if (formik.touched?.parameters && !formik.touched?.parameters[id]) {
            // @ts-ignore
            delete e.parameters[id];
          }
        });
      }

      Object.keys(e.parameters).forEach((id) => {
        // @ts-ignore
        if (e.parameters[id] && typeof e.parameters[id] !== 'string') {
          // @ts-ignore
          e.parameters[id] = e.parameters[id][0].value;
        }
      });
    }
    return e;
  }, [formik.touched, formik.errors, formik.submitCount]);

  return (
    <FormikProvider
      value={{
        ...formik,
        errors,
      }}
    >
      <FieldsForm
        uploadItemId={id}
        dictionaries={dictionaries}
        onCancel={() => {
          backWithFallback('/data/soil');
        }}
        isLoading={isDictionariesLoading || isLoading}
        onMediaChange={setMedia}
        media={media}
      />
    </FormikProvider>
  );
};
