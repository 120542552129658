import * as yup from 'yup';
import { requiredError, shouldBeLessThan, shouldBeMoreThan } from '../constans';

export const NsiWaterSitesSchema = yup.object().shape({
  name: yup.string().nullable().required(requiredError),
  code: yup.string().nullable().required(requiredError),
  areaSquare: yup.string().nullable().required(requiredError),
  status: yup.string().nullable().required(requiredError),
  subsystemId: yup.string().nullable().required(requiredError),
  waterId: yup.string().nullable().required(requiredError),

  okrugId: yup.string().nullable(),
  rayonId: yup.string().nullable(),
  address: yup.string().nullable(),
  landmark: yup.string().nullable(),
  latitudeStart: yup
    .number()
    .lessThan(90, shouldBeLessThan(90))
    .moreThan(-90, shouldBeMoreThan(-90))
    .typeError('Допустимые символы: "123", "."'),
  latitudeEnd: yup
    .number()
    .lessThan(90, shouldBeLessThan(90))
    .moreThan(-90, shouldBeMoreThan(-90))
    .typeError('Допустимые символы: "123", "."'),
  longitudeStart: yup
    .number()
    .lessThan(100, shouldBeLessThan(100))
    .moreThan(-100, shouldBeMoreThan(-100))
    .typeError('Допустимые символы: "123", "."'),
  longitudeEnd: yup
    .number()
    .lessThan(100, shouldBeLessThan(100))
    .moreThan(-100, shouldBeMoreThan(-100))
    .typeError('Допустимые символы: "123", "."'),
});
