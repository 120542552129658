import { SubsystemHeader } from 'components/common';
import React from 'react';
import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import { DepartmentList } from './DepartmentList';
import appLocale from 'constants/appLocale';
import { useHistory } from 'react-router';
import { FullPageWrapper } from '../../../components/table/FullPageWrapper';

export const Departments: React.FC = () => {
  useUpdateBreadcrumbs([
    { breadcrumb: appLocale.admin.main, path: '/admin' },
    {
      breadcrumb: appLocale.admin.department,
      path: '/admin/department',
    },
  ]);

  const { push } = useHistory();

  return (
    <FullPageWrapper>
      <SubsystemHeader
        title={appLocale.admin.department}
        addButtonProps={{ onClick: () => push('/admin/department/add') }}
      />

      <DepartmentList />
    </FullPageWrapper>
  );
};
