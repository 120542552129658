import React from 'react';
import { baseAdminPeriodUrl } from './consts';
import { useHistory } from 'react-router';
import { PeriodItem } from '../models/PeriodItem';
import { tableIcons } from '../components/CustomTable';
import { useHandleDeleteData, useList, useRequest } from 'hooks';
import { DEFAULT_PAGE_SIZE_NEW_TABLE } from '../../../constants/tables';
import { Column } from '@material-table/core';
import { MaterialTable } from '../../../features/material-table/MaterialTableMini';

export const PeriodList: React.FC = () => {
  const history = useHistory();

  const {
    isLoading,
    response,
    toggleOrder,
    setFilters,
    onPageChange,
    filterDTO,
    onSearchText,
    selectedIndexes,
    onSelectionChange,
    refetch,
  } = useList<PeriodItem, keyof PeriodItem>(baseAdminPeriodUrl, {
    initialOrderFieldKey: '-code',
    defaultPageSize: DEFAULT_PAGE_SIZE_NEW_TABLE,
    resetDataOnNextRequest: true,
    fetchMethod: 'GET',
    initialDeleted: null,
  });

  const { post: unerasePeriod, isLoading: isBusy } = useRequest(baseAdminPeriodUrl);

  const columns: Column<PeriodItem>[] = [
    {
      title: 'Внешний код',
      field: 'code',
    },
    {
      title: 'Наименование',
      field: 'name',
    },
    {
      title: 'Удален',
      field: 'deleted',
      render: (rowdata) => <input type="checkbox" checked={rowdata.deleted} readOnly />,
      width: 70,
    },
  ];

  const { ModalComponent: ConfirmDeleteModal, handleOpenModal } = useHandleDeleteData({ refetch });

  return (
    <>
      <MaterialTable
        data={response?.data}
        meta={response?.meta}
        toggleOrder={toggleOrder}
        filterDTO={filterDTO}
        onPageChange={onPageChange}
        columns={columns}
        actions={[
          {
            type: 'edit',
            onClick: (_, row) => {
              if (!Array.isArray(row) && row.id) {
                history.push(`/admin/period/edit/${row.id}`);
              }
            },
          },
          {
            position: 'row',
            action: (item) => ({
              icon: tableIcons.Reload,
              tooltip: 'Снять пометку на удаление',
              onClick: () => {
                if (item?.id == null) return;
                const { id } = item;
                void unerasePeriod(undefined, {
                  url: `${baseAdminPeriodUrl}/${id}/unerase`,
                }).then(() => refetch());
              },
              hidden: !item.deleted,
            })
          },
          {
            position: 'row',
            action: (item) => ({
              icon: tableIcons.Delete,
              tooltip: 'Удалить',
              onClick: () => {
                handleOpenModal({
                  url: `${baseAdminPeriodUrl}/${item.id}`,
                });
              },
              hidden: item.deleted,
            })
          },
        ]}
        isLoading={isLoading || isBusy}
        setFilters={setFilters}
        options={{ selection: false, search: true }}
        onSearch={onSearchText}
        onSelectionChange={onSelectionChange}
        selectedIndexes={selectedIndexes}
        showTotalCount
      />

      {ConfirmDeleteModal}
    </>
  );
};
