import { useEffect, useState } from 'react';
import { useLogout } from 'api/services/auth';

interface IProps {
  timeoutMs: number | null | undefined;
}
export const IdleTimeout = ({ timeoutMs }: IProps) => {
  const { logout } = useLogout();
  const [isInactive, setIsInactive] = useState(false);

  useEffect(() => {
    if (typeof timeoutMs === 'number' && timeoutMs) {
      let timeout: any;

      const resetTimer = () => {
        clearTimeout(timeout);
        timeout = setTimeout(() => setIsInactive(true), timeoutMs);
      };

      const events = ['mousedown', 'mousemove', 'keypress', 'scroll', 'touchstart'];

      events.forEach((event) => {
        document.addEventListener(event, resetTimer);
      });

      resetTimer();

      return () => {
        clearTimeout(timeout);
        events.forEach((event) => {
          document.removeEventListener(event, resetTimer);
        });
      };
    }
  }, [timeoutMs]);

  useEffect(() => {
    if (isInactive) {
      logout();
    }
  }, [isInactive, logout]);

  return null;
};
