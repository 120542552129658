import React, { FC, useCallback, useEffect, useState } from 'react';
import { Modal } from 'components/common/Modals';
import { Fade, Flex } from '@chakra-ui/react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { colors } from 'components/theme/colors';
import CarouselArrow from 'components/common/FilePreviewModal/components/CarouselArrow';
import FilePreviewPDFSlide from 'components/common/FilePreviewModal/components/FilePreviewPDFSlide';
import { makeStyles } from '@material-ui/styles';
import { Loader } from 'components/common/Loader';
import FilePreviewImageSlide from 'components/common/FilePreviewModal/components/FilePreviewImageSlide';
import FilePreviewMiscDoc from 'components/common/FilePreviewModal/components/FilePreviewMiscDoc';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  media: File[];
  defaultIndex?: number;
  title?: string;
  isLoading?: boolean;
}

const useStyles = makeStyles(() => (
  {
    carouselRoot: {
      width: '100%',
    },
    counter: {
      position: 'absolute',
      bottom: 0,
      left: 0,
      width: '72px',
      height: '40px',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: colors.grey[200],
      opacity: 0.8,
      zIndex: 2,
    }
  }
));

const FilePreviewModal: FC<Props> = ({
  isOpen,
  onClose,
  defaultIndex = 0,
  media = [],
  title,
  isLoading
}) => {
  const classes = useStyles();
  const [selectedSlide, setSelectedSlide] = useState<number>(0);

  const renderSlide = useCallback((file: File, index: number) => {
    if (file.type === 'application/pdf') {
      return <FilePreviewPDFSlide key={`${file.name}_${index}`} file={file} open={index === selectedSlide} />;
    }
    if (file.type.startsWith('image/')) {
      return <FilePreviewImageSlide key={`${file.name}_${index}`} file={file} />;
    }

    return <FilePreviewMiscDoc key={`${file.name}_${index}`} file={file} />;
  }, [selectedSlide]);

  useEffect(() => {
    setSelectedSlide(defaultIndex);
  }, [defaultIndex, isOpen]);

  const renderCounter = useCallback(() => (
    <Flex
      className={classes.counter}
    >
      {`${selectedSlide + 1}/${media.length}`}
    </Flex>
  ), [classes.counter, media.length, selectedSlide]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} header={title}>
      <Fade in={isOpen} delay={0.3}>
        {isLoading && <Loader />}
        <Flex style={{
          width: '80vw',
          height: '600px',
          margin: '0 auto',
          position: 'relative',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        >
          <Carousel
            className={classes.carouselRoot}
            selectedItem={selectedSlide}
            onChange={(value: number) => setSelectedSlide(value)}
            showThumbs={false}
            showStatus={false}
            showIndicators={false}
            renderArrowPrev={(clickHandler, hasPrev) => (
              <CarouselArrow
                onClick={clickHandler}
                hasNext={hasPrev}
                left
              />
            )}
            renderArrowNext={(clickHandler, hasNext) => (
              <CarouselArrow
                onClick={clickHandler}
                hasNext={hasNext}
              />
            )}
          >
            {media.map((file, index) => renderSlide(file, index))}
          </Carousel>
          {media.length !== 0 && renderCounter()}
        </Flex>
      </Fade>
    </Modal>
  );
};

export default (FilePreviewModal);
