import { Button, FormWrapper, Input, Select } from 'components/common';
import { Box, Flex, Grid, Heading } from '@chakra-ui/react';
import { Field, FieldArray, useFormikContext } from 'formik';
import { TDictionariesArray } from 'models/dictionaries';
import { COMMON_LOCALE } from 'constants/common';
import React, { useCallback, useMemo } from 'react';
import { getSelectOptionsFromDictionaryByName } from 'features/get-select-options-from-dictionary';
import { AddIcon, DeleteIcon } from 'components/icons';
import { get } from 'lodash';
import { SoilDeicingItem } from 'pages/data/PGR/Soil';
import { MoreLessNumberInput } from '../../../../components/common/Input/more-less-number-input';

interface ParametersTabProps {
  dictionaries: {
    data: TDictionariesArray;
  } | null;
  isWrite?: boolean;
}

export const AnalyticsSoilDeicingParametersTab: React.FC<ParametersTabProps> = ({
  children,
  dictionaries,
  isWrite,
}) => {
  const { values, errors, setFieldValue } = useFormikContext<SoilDeicingItem>();

  const allParameters = useMemo(
    () =>
      getSelectOptionsFromDictionaryByName(dictionaries?.data, 'allParameters').map((p) => ({
        ...p,
        name: p.title,
        value: p.parameterId,
      })),
    [dictionaries]
  );

  const parameterNames = useMemo(
    () =>
      getSelectOptionsFromDictionaryByName(dictionaries?.data, 'activeParameters').map((p) => ({
        ...p,
        name: p.title,
        value: p.parameterId,
      })),
    [dictionaries]
  );

  const getOptions = useCallback(
    (parameterId?: string) => {
      if (!parameterId) return parameterNames;

      const fieldInParameters = parameterNames.find((p) => p.parameterId === parameterId);
      if (fieldInParameters) {
        return parameterNames;
      }
      const fieldInAllParameters = allParameters.find((p) => p.parameterId === parameterId);
      if (fieldInAllParameters) {
        return [...parameterNames, fieldInAllParameters];
      }
      return [...parameterNames];
    },
    [allParameters, parameterNames]
  );

  return (
    <Box flexDirection="column">
      <FormWrapper>
        <Box marginTop="30px" gap="15px" marginBottom={20}>
          <Heading mb={2} size="md">
            {COMMON_LOCALE.indicators}
          </Heading>
          <FieldArray name="values">
            {({ remove, push }) => (
              <Box flexDirection="column">
                {values.values.length > 0 &&
                  values.values.map((paramItem, index) => (
                    <Flex key={paramItem.id} marginBottom={30} alignItems="start">
                      <Grid gap="15px" flexGrow={1} marginRight={15}>
                        <Field
                          as={Input}
                          name={`values.${index}.index`}
                          label="Номер"
                          error={get(errors?.values?.[index], 'index')}
                          type="number"
                          isRequired
                        />
                        <Field
                          as={Select}
                          name={`values.${index}.parameterId`}
                          label="Показатель, единица измерения"
                          value={values.values[index].name}
                          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                            setFieldValue(`values.${index}.parameterId`, e.target.value);
                            setFieldValue(`values.${index}.name`, e.target.value);
                            setFieldValue(`values.${index}.code`, e.target.value);
                          }}
                          options={getOptions(values.values[index].parameterId)}
                          error={get(errors?.values?.[index], 'parameterId')}
                          isRequired
                        />
                        <Field
                          as={MoreLessNumberInput}
                          name={`values.${index}`}
                          label="Полученное значение"
                          error={get(errors?.values?.[index], 'value')}
                          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                            setFieldValue(`values.${index}.value`, e.target.value);
                          }}
                          isRequired
                        />
                      </Grid>
                      {isWrite && (
                        <Button leftIcon={<DeleteIcon />} onClick={() => remove(index)} variant="ghost" size="sm" />
                      )}
                    </Flex>
                  ))}
                {isWrite && (
                  <Button
                    leftIcon={<AddIcon />}
                    onClick={() =>
                      push({
                        name: '',
                        value: '',
                        id: null,
                        parameterId: null,
                        index: String(values.values.length + 1),
                      })
                    }
                    variant="ghost"
                    size="sm"
                  >
                    Добавить значение
                  </Button>
                )}
              </Box>
            )}
          </FieldArray>
          {children}
        </Box>
      </FormWrapper>
    </Box>
  );
};
