export const initialEmptyValues = {
  sourceId: null,
  date: null,
  period: '',
  temperatureAvg: '',
  temperatureMax: '',
  temperatureMaxDate: null,
  temperatureMin: '',
  temperatureMinDate: null,
  airPressure: '',
  rainfall: '',
  humidityAvg: '',
  humidityMin: '',
  humidityMinDate: null,
  humidityMax: '',
  humidityMaxDate: null,
  windDirection: '',
  windSpeedAvg: '',
  windSpeedMin: '',
  windSpeedMinDate: null,
  windSpeedMax: '',
  ip: '',
  windSpeedMaxDate: null,
  insideTemperature: '',
  voltage: '',
  coefficient1: '',
  coefficient2: '',
  coefficient3: '',
  coefficient4: '',
  door: '',
};
