import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Flex, Stack, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { Button, DateInput, FormHeader, FormWrapper, Loader, StyledTab } from 'components/common';
import { createGreenPlantsAddUrl, GreenPlantsRootPath, GreenPlantsStatementsUrl } from 'pages/routes/GreenPlants';
import { Field, FormikProvider, useFormik } from 'formik';
import { values } from 'lodash';

import { useHistory } from 'react-router-dom';
import { createDateToRequestWithTimezone } from 'lib/create-date';
import { greenPlantsFormInitialValues, GreenPlantsFormTypes, TGreenPlantsForm } from './types';
import { usePlantsByAreaId } from './usePlantsByAreaId';
import { TFLoraDictionaries } from '../Flora/useFloraDictionaries';
import { TLawnsDictionaries } from '../Lawns/useLawnsDictionaries';
import { useAreaById } from './useAreaById';
import { FloraTabPanel } from './Tabs/Flora';
import { LawnsTabPanel } from './Tabs/Lawns';
import { GreenPlantsAreaLabels } from './Tabs/Common/constants';
import { IUploadMedia } from 'components/common/FileUploadDragAndDrop/types';
import subsystemIdsMap from 'constants/subsystemIdsMap';
import { useAvailability } from 'hooks';
import { AreaTabPanel } from './Tabs/Areas';
import { useGreenPlantsSchema } from 'models/schemas';
import { createTouchedErrors } from 'features/create-touched-errors';
import { useEnhancedNotification } from 'hooks/useEnhancedNotification';
import { useGoBackWithFallback } from '../../../../lib/utils/goBackWithFallback';

interface IGreenPlantsFormProps {
  url?: string;
  isEditMode?: boolean;
  header: string;
  defaultIndex: number;
  initialValues?: TGreenPlantsForm;
  onSubmit: (request: Partial<TGreenPlantsForm>) => Promise<any>;
  isSubmitting: boolean;
  type: keyof typeof GreenPlantsFormTypes;
  floraDictionaries?: Partial<TFLoraDictionaries>;
  lawnsDictionaries?: Partial<TLawnsDictionaries>;
  media?: IUploadMedia[];
  setMedia?: (media: IUploadMedia[]) => void;
  registryId?: string;
  areaEntity?: keyof typeof GreenPlantsFormTypes;
  setAreaEntity?: (key: keyof typeof GreenPlantsFormTypes) => void;
}

const GreenPlantsForm: React.FC<IGreenPlantsFormProps> = memo(
  ({
    url,
    isEditMode,
    header,
    defaultIndex,
    initialValues = greenPlantsFormInitialValues,
    onSubmit,
    isSubmitting,
    type,
    floraDictionaries,
    lawnsDictionaries,
    media,
    setMedia,
    registryId,
    setAreaEntity,
    areaEntity
  }) => {
    const { backWithFallback } = useGoBackWithFallback();
    const { onValidationError } = useEnhancedNotification();

    const { isAvailable } = useAvailability(subsystemIdsMap.greenPlantsRoot);

    const [currentIndex, setCurrentTabIndex] = useState<number>(defaultIndex);

    const entity = useMemo(
      () => values<keyof typeof GreenPlantsFormTypes>(GreenPlantsFormTypes)[currentIndex],
      [currentIndex],
    );

    const [isDisabled, setIsDisabled] = useState<boolean>(false);

    const { GreenPlantsSchema } = useGreenPlantsSchema(entity);

    const form = useFormik<TGreenPlantsForm>({
      enableReinitialize: true,
      validateOnChange: true,
      validateOnMount: true,
      validateOnBlur: true,
      validationSchema: GreenPlantsSchema,
      initialValues,
      onSubmit,
    });

    useEffect(() => {
      setIsDisabled(!!form.values[entity].areaId);
    }, [form.values, entity]);

    const { result: plants, isLoading: isLoadingPlants } = usePlantsByAreaId(form.values[entity].areaId);

    const { result: area, isLoading: isLoadingArea } = useAreaById(form.values[entity].areaId);

    const isLoading = useMemo(
      () => isLoadingArea || isLoadingPlants || isSubmitting,
      [isLoadingArea, isLoadingPlants, isSubmitting],
    );

    const history = useHistory();

    return (
      <FormikProvider
        value={{
          ...form,
          errors: createTouchedErrors(form.errors, form.touched),
        }}
      >
        <Flex flexDirection="column">
          {isLoading && <Loader />}
          <FormHeader
            onBackClick={() => backWithFallback(url || `${GreenPlantsStatementsUrl}/${type}`)}
            url={url || `${GreenPlantsStatementsUrl}/${type}`}
            header={header}
          />
          <FormWrapper gridTemplateColumns="1fr" mt="30px">
            <Stack>
              <Field
                as={DateInput}
                label={GreenPlantsAreaLabels.date}
                value={form.values[entity].date}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  form.setFieldValue(`${entity}.date`, createDateToRequestWithTimezone(e.target.value));
                }}
                isDisabled={isLoading || isEditMode}
              />
            </Stack>
            <Tabs
              defaultIndex={currentIndex}
              tabIndex={currentIndex}
              onChange={useCallback(setCurrentTabIndex, [setCurrentTabIndex])}
              mt="30px"
            >
              <TabList>
                <StyledTab
                  isDisabled={isDisabled}
                  onClick={useCallback(() => {
                    history.push(createGreenPlantsAddUrl('area'), { entityType: entity });
                  }, [history, entity])}
                >
                  Обследованиe площадки
                </StyledTab>
                <StyledTab
                  isDisabled={isDisabled}
                  onClick={useCallback(() => {
                    history.push(createGreenPlantsAddUrl('flora'));
                  }, [history])}
                >
                  Обследованиe насаждений
                </StyledTab>
                <StyledTab
                  isDisabled={isDisabled}
                  onClick={useCallback(() => {
                    history.push(createGreenPlantsAddUrl('lawns'));
                  }, [history])}
                >
                  Обследованиe газона
                </StyledTab>
              </TabList>
              <TabPanels mt="30px">
                <TabPanel>
                  <AreaTabPanel
                    isEditMode={isEditMode}
                    entity={areaEntity || 'flora'}
                    setEntity={setAreaEntity}
                    floraDictionaries={floraDictionaries}
                    lawnsDictionaries={lawnsDictionaries}
                    area={area}
                    plants={plants}
                    isLoading={isLoading}
                  >
                    <Flex mt={4} justifyContent="space-between">
                      <Button
                        colorScheme="PrimaryButton"
                        isLoading={isLoading}
                        isDisabled={isLoading}
                        onClick={() => backWithFallback(`${GreenPlantsRootPath}/${areaEntity}/areas`)}
                      >
                        Отмена
                      </Button>
                      <Button
                        colorScheme="PrimaryButton"
                        isLoading={isLoading}
                        isDisabled={isLoading || !isAvailable}
                        onClick={() => {
                          form.handleSubmit();
                          form.setFieldTouched('area.areaId');
                          setTimeout(() => {
                            setTimeout(() => {
                              if (form.errors?.area && Object.keys(form.errors.area)?.length > 0) {
                                onValidationError();
                              }
                            });
                          });
                          // eslint-disable-next-line react-hooks/exhaustive-deps
                        }}
                      >
                        Сохранить
                      </Button>
                    </Flex>
                  </AreaTabPanel>
                </TabPanel>
                <FloraTabPanel
                  entity={entity}
                  floraDictionaries={floraDictionaries}
                  area={area}
                  plants={plants}
                  isLoading={isLoading}
                  media={media}
                  setMedia={setMedia}
                  registryId={registryId}
                  isEditMode={isEditMode}
                >
                  <Flex mt={4} justifyContent="space-between">
                    <Button
                      colorScheme="PrimaryButton"
                      isLoading={isLoading}
                      isDisabled={isLoading}
                      onClick={() => backWithFallback(`${GreenPlantsStatementsUrl}/${type}`)}
                    >
                      Отмена
                    </Button>
                    <Button
                      colorScheme="PrimaryButton"
                      isLoading={isLoading}
                      isDisabled={isLoading || !isAvailable}
                      onClick={useCallback(() => {
                        if (Object.keys(form.errors).length > 0) {
                          onValidationError();
                        }
                        form.handleSubmit();
                        // eslint-disable-next-line react-hooks/exhaustive-deps
                      }, [form.handleSubmit, form.errors, onValidationError])}
                    >
                      Сохранить
                    </Button>
                  </Flex>
                </FloraTabPanel>
                <LawnsTabPanel
                  entity={entity}
                  lawnsDictionaries={lawnsDictionaries}
                  area={area}
                  isLoading={isLoading}
                  media={media}
                  setMedia={setMedia}
                  registryId={registryId}
                >
                  <Flex mt={4} justifyContent="space-between">
                    <Button
                      colorScheme="PrimaryButton"
                      isLoading={isLoading}
                      isDisabled={isLoading}
                      onClick={() => backWithFallback(`${GreenPlantsStatementsUrl}/${type}`)}
                    >
                      Отмена
                    </Button>
                    <Button
                      colorScheme="PrimaryButton"
                      isLoading={isLoading}
                      isDisabled={isLoading || !isAvailable}
                      onClick={() => {
                        form.handleSubmit();
                        form.setFieldTouched('lawns.areaId');
                        form.setFieldTouched('lawns.lawnId');
                        setTimeout(() => {
                          if (Object.keys(form.errors)?.length > 0) {
                            onValidationError();
                          }
                        });
                        // eslint-disable-next-line react-hooks/exhaustive-deps
                      }}
                    >
                      Сохранить
                    </Button>
                  </Flex>
                </LawnsTabPanel>
              </TabPanels>
            </Tabs>
          </FormWrapper>
        </Flex>
      </FormikProvider>
    );
  },
);

export type { IGreenPlantsFormProps };

export { GreenPlantsForm };
