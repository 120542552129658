import { Field, useFormikContext } from 'formik';
import { IReportProtocolForm } from 'pages/analytics/Raids/types';
import { Button, FormWrapper, Input, Select } from 'components/common';
import React, { useCallback, useMemo } from 'react';
import { Box, Flex, Grid, GridItem, Heading, Text } from '@chakra-ui/react';
import { AddIcon, DeleteIcon } from 'components/icons';
import { ISignator } from 'pages/analytics/Raids/PelNoise/types';
import { TDictionariesArray } from 'models';
import { getSelectOptionsFromDictionaryByName } from 'features/get-select-options-from-dictionary';
import { get } from 'lodash';

interface IProps {
  dictionaries: {
    data: TDictionariesArray;
  } | null;
}

export const SignatoriesTab = ({ dictionaries }: IProps) => {
  const { errors, values, setFieldValue } = useFormikContext<IReportProtocolForm>();

  const noiseParameters = useMemo(
    () => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'actions'),
    [dictionaries]
  );

  const addSignator = useCallback(() => {
    const newSigners = values.sign?.signers || [];
    newSigners?.push({
      name: '',
      position: '',
    });
    setFieldValue('sign.signers', newSigners);
  }, [setFieldValue, values.sign]);

  const removeSignator = useCallback(
    (index: number) => {
      const newSigners = values.sign?.signers;
      newSigners?.splice(index, 1);
      setFieldValue('sign.signers', newSigners);
    },
    [setFieldValue, values.sign]
  );

  return (
    <Box>
      <FormWrapper>
        <Grid marginTop={5} templateColumns="repeat(1, 1fr)">
          <Heading size="md" marginBottom={5}>
            Подписанты
          </Heading>
          {values.sign?.signers?.map((signator: ISignator, index: number) => (
            <Box key={index} marginBottom={5}>
              <Grid gap={4}>
                <Flex alignItems="baseline">
                  <Heading size="md" marginBottom={2} marginTop={5}>
                    {`Подписант ${index + 1}`}
                  </Heading>
                  <Button
                    marginLeft="10px"
                    leftIcon={<DeleteIcon />}
                    variant="ghost"
                    color="PrimaryButton.500"
                    size="sm"
                    p={0}
                    onClick={() => removeSignator(index)}
                  />
                </Flex>

                <Field
                  as={Select}
                  error={get(errors, `sign.signers[${index}].action`)}
                  isRequired
                  label="Заголовок"
                  name={`sign.signers[${index}].action`}
                  options={noiseParameters}
                />

                <Field
                  as={Input}
                  error={get(errors, `sign.signers[${index}].position`)}
                  isRequired
                  label="Должность"
                  name={`sign.signers[${index}].position`}
                />

                <GridItem>
                  <Field
                    as={Input}
                    error={get(errors, `sign.signers[${index}].name`)}
                    isRequired
                    label="И.О. Фамилия"
                    name={`sign.signers[${index}].name`}
                  />
                  <Text mt={1}>Формат заполнения: И.О. Фамилия</Text>
                </GridItem>
              </Grid>
            </Box>
          ))}

          <Button
            alignSelf="left"
            marginRight="auto"
            leftIcon={<AddIcon />}
            variant="ghost"
            color="PrimaryButton.500"
            size="sm"
            p={0}
            onClick={() => addSignator()}
          >
            Добавить подписанта
          </Button>
        </Grid>
      </FormWrapper>
    </Box>
  );
};
