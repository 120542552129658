export const initialEmptyValues = {
  date: null,
  sourceId: null,
  operationMode: '',
  calculationMode: '',
  noiseMin: '',
  noiseMinDate: null,
  noiseMax: '',
  noiseMaxDate: null,
  noiseEquivalent: '',
  octave1Value: '',
  octave2Value: '',
  octave3Value: '',
  octave4Value: '',
  octave5Value: '',
  octave6Value: '',
  octave7Value: '',
  octave8Value: '',
  octave9Value: '',
  ip: '',
  insideTemperature: '',
  voltage: '',
  coefficient1: '',
  coefficient2: '',
  coefficient3: '',
  coefficient4: '',
  door: '',
};
