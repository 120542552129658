import React from 'react';
import { FieldsForm } from './FieldsForm';
import { useFormikContext } from 'formik';
import { AppealValues } from 'pages/citizenAppeal';
import { TDictionariesArray } from 'models/dictionaries';

interface Props {
  dictionaries?: TDictionariesArray;
}

export const AddAppealForm = ({ dictionaries }: Props) => {
  const { values, handleSubmit, setFieldValue, errors } = useFormikContext<AppealValues>();
  return (
    <FieldsForm
      values={values}
      errors={errors}
      handleSubmit={handleSubmit}
      setFieldValue={setFieldValue}
      dictionaries={dictionaries}
    />
  );
};
