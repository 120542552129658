import { Column } from '@material-table/core';
import { MaterialTable } from 'features/material-table/MaterialTableMini';
import { Modal } from 'components/common/Modals';
import { DriftPreviewRequest } from 'lib/calculations/types';
import { useEnhancedNotification, useList, useRequest } from 'hooks';
import { AtmosphereMonitoringItem } from 'hooks/atmosphere/Monitoring/useAtmosphereMonitoringList';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button } from 'components/common/Button';
import { Flex } from '@chakra-ui/react';

interface Props<T extends object> {
  columns: Column<T>[];
  onClose: () => void;
  apiRoutes: {
    drift: string;
    driftPreview: string;
  };
  driftRequest?: DriftPreviewRequest;
}

function DriftPreview<T extends object>({ columns, driftRequest, onClose, apiRoutes }: Props<T>) {
  const [open, setOpen] = useState(false);
  const { onSuccessCreate } = useEnhancedNotification();
  const {
    isLoading: isListLoading,
    response,
    toggleOrder,
    onPageChange,
    filterDTO,
    error,
  } = useList<AtmosphereMonitoringItem, keyof AtmosphereMonitoringItem>(apiRoutes.driftPreview, {
    additionalRequestData: driftRequest,
  });

  useEffect(() => {
    if (error) {
      onClose();
    }
  }, [error, onClose]);

  useEffect(() => {
    if (response) {
      setOpen(true);
    }
  }, [response]);

  const onSuccess = useCallback(() => {
    setOpen(false);
    onClose();
    onSuccessCreate();
  }, [onClose, onSuccessCreate]);

  const { post, isLoading: isSubmitLoading } = useRequest(apiRoutes.drift, onSuccess);

  const onSubmit = useCallback(() => {
    void post(driftRequest);
  }, [driftRequest, post]);

  const isLoading = useMemo(() => isSubmitLoading || isListLoading, [isListLoading, isSubmitLoading]);

  return (
    <Modal isOpen={open} onClose={onClose}>
      <Flex flexDirection="column">
        <MaterialTable
          toggleOrder={toggleOrder}
          onPageChange={onPageChange}
          filterDTO={filterDTO}
          columns={columns}
          data={response?.data}
          meta={response?.meta}
          isLoading={isLoading}
          options={{ selection: false }}
        />
        <Flex marginTop={4} justifyContent="flex-end">
          <Button variant="outline" onClick={onClose} marginRight={2}>
            Отмена
          </Button>
          <Button disabled={isLoading} onClick={onSubmit} variant="solid">
            Применить
          </Button>
        </Flex>
      </Flex>
    </Modal>
  );
}

export default DriftPreview;
