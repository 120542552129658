import { forwardRef, useCallback, useState } from 'react';
import {
  BlockIcon,
  DeleteIcon,
  EditIcon,
  ReloadIcon,
  SelectedCheckboxIcon,
  TickCircledIcon,
  UnlockIcon,
  UnselectedCheckboxIcon,
} from 'components/icons';
import MaterialTable from '@material-table/core';
import {
  AddBox,
  ArrowDownward,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  FilterList,
  FirstPage,
  LastPage,
  Remove,
  SaveAlt,
  Search,
  ViewColumn,
} from '@material-ui/icons';
import tableLocalization from 'features/material-table/components/const/tableLocalization';
import { useNotifications } from 'hooks/useNotifications';
import { ERROR_MESSAGES, SUCCESS_MESSAGES } from 'constants/messages';
import { useRequest } from 'hooks/useRequest';
import { SearchCriteria } from 'models/api/SearchCriteria';
import { DEFAULT_PAGE_OPTIONS } from 'constants/tables';
import { makeStyles } from '@material-ui/core/styles';
import { miniTableStyle } from '../../../features/material-table/const';

export const tableIcons: any = {
  Add: forwardRef((props: any, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props: any, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props: any, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props: any, ref) => <DeleteIcon {...props} ref={ref} />),
  DetailPanel: forwardRef((props: any, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props: any, ref) => <EditIcon {...props} ref={ref} />),
  Export: forwardRef((props: any, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props: any, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props: any, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props: any, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props: any, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props: any, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props: any, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props: any, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props: any, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props: any, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props: any, ref) => <ViewColumn {...props} ref={ref} />),
  Block: forwardRef((props: any, ref) => <BlockIcon {...props} ref={ref} />),
  Unlock: forwardRef((props: any, ref) => <UnlockIcon {...props} ref={ref} />),
  Reload: forwardRef((props: any, ref) => <ReloadIcon {...props} ref={ref} />),
  TickCircled: forwardRef((props: any, ref) => <TickCircledIcon {...props} ref={ref} />),
};

export interface Props {
  data?: any;
  columns: any;
  options?: any;
  editable?: any;
  onSelectionChanged?: (row: any, uncheckedRows?: any) => void;
  detailPanel?: any;
  isLoading?: boolean;
  title?: string;
  actions?: any;
  queryFunc?: (params: any) => any;
  useObjQuery?: boolean;
  onRemove?: (params: any) => any;
  style?: any;
  cellEditable?: any;
  onPageChange?: (params: any) => void;
  onQueryChange?: ((query?: any | undefined) => void);
  tableRef?: any;
  localization?: any;
  onChangeColumns?: (columns: string[]) => void;
  calculateQueryFunc?: (query: any) => any;
  postLoadFunc?: (data: any[]) => any;
  showTotalCount?: boolean;
}

const useStyles = makeStyles(() => ({
  root: miniTableStyle(),
}));

// TODO: убрать эту обертку!
export const CustomTable: React.FC<Props> = (properties: Props) => {
  const classes = useStyles();
  const {
    data,
    columns,
    options,
    onSelectionChanged,
    isLoading,
    editable,
    title,
    actions,
    queryFunc,
    onRemove,
    style,
    cellEditable,
    onPageChange,
    onQueryChange,
    tableRef,
    localization,
    useObjQuery,
    onChangeColumns,
    calculateQueryFunc,
    postLoadFunc,
    showTotalCount
  } = properties;

  const defaultOptions = {
    actionsColumnIndex: -1,
    columnsButton: true,
    search: false,
    showTitle: true,
    showTextRowsSelected: false,
    toolbar: false,
    maxBodyHeight: 600,
    paging: false,
    selection: true,
    draggable: false,
    pageSize: data?.limit || 10,
    pageSizeOptions: DEFAULT_PAGE_OPTIONS,
    editable,
    headerSelectionProps: {
      color: 'primary',
      disableRipple: true,
      checkedIcon: <SelectedCheckboxIcon />,
      icon: <UnselectedCheckboxIcon />,
      indeterminate: false,
    },
    selectionProps: () => ({
      color: 'primary',
      disableRipple: true,
      checkedIcon: <SelectedCheckboxIcon />,
      icon: <UnselectedCheckboxIcon />,
    }),
    headerStyle: { zIndex: 1 }
  };

  const [viewColumns, setViewColumns] = useState(columns);
  const [selectedColumns, setSelectedColumns] = useState(columns.map((c: any) => c.field));
  const emitChangeColumns = useCallback(() => {
    if (onChangeColumns != null) {
      onChangeColumns(viewColumns.map((c: any) => c.field));
    }
  }, [onChangeColumns, viewColumns]);

  const targetOptions = Object.assign(defaultOptions, options);

  const onColumnsChanged = (col: any, hide: boolean) => {
    const cols = hide
      ? selectedColumns.filter((c: string) => c !== col.field)
      : [...selectedColumns, col.field];
    setSelectedColumns(cols);
    setViewColumns(viewColumns.map((currentCol: any) => {
      if (currentCol.field === col.field) {
        currentCol.hidden = hide;
      }
      return currentCol;
    }));
    emitChangeColumns();
  };

  const prepareQuery = calculateQueryFunc != null ? calculateQueryFunc : (query: any) => {
    if (useObjQuery != null && useObjQuery) {
      const pq = new SearchCriteria();
      pq.offset = query.page * query.pageSize;
      pq.limit = query.pageSize;
      if (query.search != null && query.search.trim() !== '') {
        pq.searchString = query.search;
      }
      if (query.orderDirection != null && query.orderBy?.field) {
        pq.orders = [`${query.orderDirection === 'desc' ? '-' : ''}${query.orderBy?.field}`];
      }
      if (selectedColumns != null && selectedColumns.length > 0) {
        pq.searchFields = selectedColumns.map((c: string) => c);
      }
      return JSON.stringify(pq);
    }

    const offset = query.page * query.pageSize;
    const limit = query.pageSize;
    const params: any = {
      offset,
      limit,
    };
    if (query.search != null && query.search.trim() !== '') {
      params.searchString = query.search;
    }
    if (query.orderDirection != null) {
      params.orderBy = `${query.orderDirection === 'desc' ? '-' : ''}${query.orderBy?.field}`;
    }
    if (selectedColumns != null && selectedColumns.length > 0) {
      params.searchFields = selectedColumns.map((c: string) => c).join(',');
    }
    return params;
  };

   const queryDataFunc =
    queryFunc == null
      ? undefined
      : async (query: any) => {
        const params = prepareQuery(query);
        const args: any = {
          params,
        };
        if (useObjQuery) {
          args.paramsSerializer = (params: any) => `params=${encodeURI(params)}`;
        }
        const response: any = await queryFunc(args);
        return {
          data: postLoadFunc ? postLoadFunc(response.data) : response.data,
          page: query.page,
          totalCount: response?.meta.totalCount
        };
      };

  const { successNotify, errorNotify } = useNotifications();
  const onSuccess = () => {
    successNotify({
      key: 'file/delete/success',
      message: SUCCESS_MESSAGES.DELETE,
    });
    const ref: any = tableRef?.current; // FIXME: обход линтера
    ref?.onQueryChange();
  };

  const onError = () => {
    errorNotify({
      key: 'file/delete/error',
      message: ERROR_MESSAGES.DELETE,
    });
  };

  const { remove } = useRequest('', onSuccess, onError);

  const deleteObjFunc = options?.canRemove
    ? {
      onRowDelete: async (item: any) => {
        const params = onRemove != null ? onRemove(item) : undefined;
        // eslint-disable-next-line eqeqeq
        if (params != undefined) {
          remove(params);
        }
      },
    }
    : {};
  const resultEditable = Object.assign(deleteObjFunc, editable || {});

  // HACK: needs tableRef to work
  const _onSelectionChanged = useCallback((data) => {
    if (!onSelectionChanged) return;
    const unchecked = tableRef?.current?.dataManager.data.filter((row: any) => !row.tableData.checked);
    onSelectionChanged(data, unchecked);
  }, [onSelectionChanged, tableRef]);

  console.log(style, targetOptions);
  return (
    <div className={classes.root}>
      <MaterialTable
        tableRef={tableRef}
        icons={tableIcons}
        isLoading={isLoading}
        onQueryChange={onQueryChange}
        data={queryDataFunc || data || []}
        title={title}
        columns={viewColumns}
        onPageChange={onPageChange}
        options={targetOptions}
        style={{
          width: 'inherit',
          padding: '0px 12px',
          margin: '12px',
          ...style,
        }}
        onSelectionChange={_onSelectionChanged}
        onChangeColumnHidden={onColumnsChanged}
        editable={resultEditable}
        actions={actions}
        localization={tableLocalization({ showTotalCount }) || localization}
        cellEditable={cellEditable}
      />
    </div>
  );
};
