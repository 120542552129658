import { Flex, Grid } from '@chakra-ui/react';
import { IFilterItem } from 'models';
import { useFormik, FormikProvider, Field } from 'formik';
import FilterFormControls from 'components/form/FilterFormControls';
import React from 'react';
import { Checkbox } from 'components/common';

interface IProps {
  setFilters: (filters: any) => void;
}

const initialValues: Record<string, IFilterItem> = {
  deleted: {
    key: 'deleted',
    operation: 'EQ',
    value: false,
  },
};

export const DictionaryFilter = ({ setFilters }: IProps) => {
  const { resetForm, submitForm, handleReset, ...formik } = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    initialValues,
    onSubmit: (values) => {
      const filters = Object.values(values).filter((item) => !(item.key === 'deleted' && item.value));
      setFilters(filters);
    },
  });

  return (
    <FormikProvider
      value={{
        resetForm,
        submitForm,
        handleReset,
        ...formik,
      }}
    >
      <Flex>
        <Grid gap={5} templateColumns="repeat(3, 1fr)">
          <Field as={Checkbox} name="deleted.value" label="Показать удаленные" />
        </Grid>
        <FilterFormControls
          onReset={(e) => {
            handleReset(e);
            void submitForm();
          }}
          onSubmit={submitForm}
        />
      </Flex>
    </FormikProvider>
  );
};
