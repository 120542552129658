import { ERROR_MESSAGES } from 'constants/messages';
import { createTouchedErrors } from 'features/create-touched-errors';
import { FormikProvider, useFormik } from 'formik';
import { useNotifications } from 'hooks/useNotifications';
import { useRequest } from 'hooks/useRequest';
import { useHistory } from 'react-router-dom';
import FieldsForm from './FieldsForm';
import React, { useCallback, useState } from 'react';
import { updateFiles } from 'api/services/media';
import { IUploadMedia } from 'components/common/FileUploadDragAndDrop/types';
import { useObjectType } from 'hooks/useObjectType';
import { WaterRoutesBottomSoil } from 'pages/data/Water/routes';
import { IBottomSoilDTO } from 'pages/data/Water/BottomSoil/consts/types';
import { ApiError } from 'models';
import { formatErrorMessage } from 'lib/utils/formatErrorMessage';
import { BottomSoilSchema } from 'models/schemas/water/bottomsoil';
import { BottomSoilApiPaths } from 'pages/data/Water/BottomSoil/consts/apiPaths';
import { bottomSoilInitialEmptyValues } from 'pages/data/Water/BottomSoil/BottomSoilForms/initialEmptyValues';
import { useEnhancedNotification } from 'hooks';
import { useGoBackWithFallback } from 'lib/utils/goBackWithFallback';
import { useDictionary } from 'hooks/useDictionary';
import { LoaderItem } from '../../../../../components/common';

export const BottomSoilAdd = () => {
  const { backWithFallback } = useGoBackWithFallback();
  const { onSuccessCreate } = useEnhancedNotification();
  const { dictionaries, isDictionariesLoading } = useDictionary({ url: BottomSoilApiPaths.dictionaries });

  const { replace } = useHistory();
  const { errorNotify } = useNotifications();
  const [media, setMedia] = useState<IUploadMedia[]>([]);

  const objectType = useObjectType(dictionaries);

  const onError = useCallback(
    (message?: ApiError | string | null) => {
      const msg = formatErrorMessage(message);
      errorNotify({
        key: msg || 'file/add/error',
        message: msg || ERROR_MESSAGES.CREATE,
      });
    },
    [errorNotify],
  );

  const onSuccess = useCallback(
    (response: IBottomSoilDTO | null) => {
      if (response) {
        onSuccessCreate();

        updateFiles(response.id, objectType, media)
          .then(() => {
            backWithFallback(`${WaterRoutesBottomSoil.root}/main-data`);
          })
          .catch((error: Error) => {
            onError(error.message);
            replace(WaterRoutesBottomSoil.edit(response.id));
          });
      } else {
        onError();
      }
    },
    [backWithFallback, media, objectType, onError, onSuccessCreate, replace],
  );

  const { post, isLoading: isPostLoading } = useRequest<IBottomSoilDTO>(BottomSoilApiPaths.root, onSuccess, onError);

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    initialValues: bottomSoilInitialEmptyValues,
    onSubmit: (values) => {
      void post(values);
    },
    validationSchema: BottomSoilSchema,
  });

  if (isPostLoading || isDictionariesLoading) {
    return <LoaderItem />;
  }

  return (
    <FormikProvider
      value={{
        ...formik,
        errors: createTouchedErrors(formik.errors, formik.touched),
      }}
    >
      <FieldsForm
        dictionaries={dictionaries}
        onCancel={() => {
          backWithFallback(`${WaterRoutesBottomSoil.root}/main-data`);
        }}
        isLoading={isDictionariesLoading}
        onMediaChange={setMedia}
        media={media}
        url={WaterRoutesBottomSoil.root}
      />
    </FormikProvider>
  );
};
