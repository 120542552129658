import { Box, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { AttachmentsTabContent, FormWrapper, LoaderItem, TabListHeader } from 'components/common';
import React, { useCallback, useMemo } from 'react';
import { useLocation } from 'react-router';
import { AnalyticsWashoutCommonTab } from 'pages/analytics/forms/Washout/AnalyticsWashoutCommonTab';
import { useQuery } from 'hooks/useQuery';
import { useFormikContext } from 'formik';
import { useAvailability, useEnhancedNotification } from 'hooks';
import { useObjectType } from 'hooks/useObjectType';
import appLocale from 'constants/appLocale';
import { FieldFormProps } from 'types/forms';
import subsystemIdsMap from 'constants/subsystemIdsMap';
import { AnalyticsFormWithStatusHeader } from 'pages/analytics/components/AnalyticsFormWithStatusHeader';
import { WashoutDeicingItem } from 'pages/data/PGR/GreenPlants/Washout/useWashoutDicingList';
import { ParametersTab } from 'pages/data/PGR/GreenPlants/Washout/Forms/ParametersTab';
import { getSelectOptionsFromDictionaryByName } from 'features/get-select-options-from-dictionary';
import FormControls from 'components/common/FormControls';
import { SignatoriesTab } from 'pages/analytics/components/SignatoriesTab';
import { LinksTab } from 'pages/analytics/components/LinksTab';

const AnalyticsWashoutFieldsForm = ({
  onCancel,
  dictionaries,
  isLoading,
  media,
  onMediaChange,
  uploadItemId,
}: FieldFormProps) => {
  const { isAvailable } = useAvailability(subsystemIdsMap.analyticsRoot);

  const location = useLocation();
  const isEditMode = location.pathname.includes('/edit');

  const { openAttachmentsTab } = useQuery();
  const { submitForm, values, errors, setFieldTouched } = useFormikContext<WashoutDeicingItem>();
  const { onValidationError } = useEnhancedNotification();
  const submitIsDisabled = useMemo(
    () => [...values.parameters].map((val) => val.value).filter((val) => !!val).length === 0,
    [values.parameters]
  );
  const objectType = useObjectType(dictionaries);

  const onFormSubmit = useCallback(async () => {
    await submitForm();
    if (Object.keys(errors).length > 0) {
      onValidationError();
      Object.keys(errors).forEach((key) => setFieldTouched(key, true));
    }
  }, [errors, onValidationError, setFieldTouched, submitForm]);

  const statuses = useMemo(() => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'statuses'), [dictionaries]);

  if (isLoading) {
    return <LoaderItem />;
  }

  const title = isEditMode ? appLocale.bottomSoil.editProtocolResults : appLocale.bottomSoil.addProtocolResults;

  return (
    <Box flexDirection="column">
      <Tabs defaultIndex={openAttachmentsTab ? 2 : 0}>
        <TabListHeader
          onBackClick={onCancel}
          customHeader={<AnalyticsFormWithStatusHeader header={title} statuses={statuses} />}
          tabNames={[
            appLocale.common.generalInformation,
            appLocale.common.indicators,
            appLocale.common.links,
            appLocale.common.signatories,
            appLocale.common.attachments,
          ]}
        />
        <TabPanels>
          <TabPanel>
            <AnalyticsWashoutCommonTab dictionaries={dictionaries} />
          </TabPanel>
          <TabPanel>
            <ParametersTab isWrite={isAvailable} dictionaries={dictionaries} isAnalytics>
              <FormControls
                onCancel={onCancel}
                handleSubmit={onFormSubmit}
                submitIsDisabled={!isAvailable || submitIsDisabled}
              />
            </ParametersTab>
          </TabPanel>

          <TabPanel>
            <LinksTab />
          </TabPanel>

          <TabPanel>
            <SignatoriesTab dictionaries={dictionaries} />
          </TabPanel>

          <TabPanel>
            <FormWrapper>
              {isAvailable && (
                <AttachmentsTabContent
                  objectType={objectType}
                  media={media}
                  onMediaChange={onMediaChange}
                  uploadItemId={uploadItemId}
                >
                  <FormControls
                    onCancel={onCancel}
                    handleSubmit={onFormSubmit}
                    submitIsDisabled={!isAvailable || submitIsDisabled}
                  />
                </AttachmentsTabContent>
              )}
            </FormWrapper>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default React.memo(AnalyticsWashoutFieldsForm);
