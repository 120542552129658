import { Field, FormikProvider, useFormik } from 'formik';
import React, { useEffect, useMemo, useState } from 'react';

import { DateInput, Input, Select } from 'components/common';
import { createDateFromResponse, createLocalDateISO8601 } from 'lib/create-date';
import { COMMON_LOCALE } from 'constants/common';
import { getSelectOptionsFromDictionaryByName } from 'features/get-select-options-from-dictionary';
import { IPeriod } from 'features/PeriodsButtons/types';
import { PeriodsButtons } from 'features/PeriodsButtons';
import { usePeriods } from 'features/PeriodsButtons/usePeriods';
import combineFilters from 'lib/utils/combineFilters';
import { IFilterItem, TDictionariesArray, TFiltersArray } from 'models';
import { MomentInput } from 'moment';
import { FiltersDatepickersSchema } from 'models/schemas/filtersDatepickersSchema/FiltersDatepickersSchema';
import FilterFormControls from 'components/form/FilterFormControls';
import { Flex, Grid, GridItem } from '@chakra-ui/react';

interface FilterProps {
  isLoading?: boolean;
  isDisabled?: boolean;
  dictionaries: {
    data: TDictionariesArray;
  } | null;
  onSubmit: (values: any) => void;
  periods: IPeriod[] | null;
  filters?: TFiltersArray;
}

const initialEmptyValues: Record<string, IFilterItem<string>> = {
  dateTimeMin: {
    key: 'instructionDate',
    operation: 'GTE',
    value: null,
  },
  dateTimeMax: {
    key: 'instructionDate',
    operation: 'LTE',
    value: null,
  },
  stationId: {
    key: 'stationId',
    operation: 'EQ',
    value: null,
  },
  number: {
    key: 'number',
    operation: 'EQ',
    value: '',
  },
  // showDisabled - value равно '' если чекбокс включен и нужно получить с Backend все значения (этот объект удалиться при отправке)
  // value равно false - если нужно показать только неудаленные записи
  showDisabled: {
    key: 'deleted',
    operation: 'EQ',
    value: false,
  },
  periodId: {
    key: 'periodId',
    operation: 'EQ',
    value: null,
  },
};

export const InstructionsFilters: React.FC<FilterProps> = ({
  isLoading,
  isDisabled,
  onSubmit,
  dictionaries,
  periods,
  filters = [],
  children,
  ...props
}) => {
  const savedPeriodId = useMemo(
    () => (filters.find((item) => item.key === 'periodId')?.value as string) || '',
    [filters],
  );

  const [periodId, setPeriodId] = useState<string>(savedPeriodId);

  const { getDatesFromPeriod, dates } = usePeriods(periodId, periods);

  const submitPeriod = (id: string) => {
    const dates = getDatesFromPeriod(id);
    setFieldValue('periodId.value', id);
    const minDate = dates[0] === null ? null : createLocalDateISO8601(dates[0]);
    const maxDate = dates[1] === null ? null : createLocalDateISO8601(dates[1]);
    setFieldValue('dateTimeMin.value', minDate);
    setFieldValue('dateTimeMax.value', maxDate);
    setFieldTouched('dateTimeMin.value');
    setPeriodId(id);
  };

  const { values, errors, setValues, handleReset, submitForm, setFieldValue, setFieldTouched, ...formik } = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    validationSchema: FiltersDatepickersSchema([{ dateFromFieldName: 'dateTimeMin', dateToFieldName: 'dateTimeMax' }]),
    initialValues: initialEmptyValues,
    onSubmit,
  });

  useEffect(() => {
    setValues(combineFilters(initialEmptyValues, filters));
  }, [filters, setValues]);

  const dictData = dictionaries?.data;
  const stations = useMemo(() => getSelectOptionsFromDictionaryByName(dictData, 'stations'), [dictData]);

  return (
    <FormikProvider
      value={{
        values,
        errors,
        setValues,
        handleReset,
        submitForm,
        setFieldValue,
        setFieldTouched,
        ...formik,
      }}
    >
      <Flex flexDirection="row" justifyContent="space-between">
        <Grid gap="12px" rowGap="8px" gridTemplateColumns="repeat(5, 1fr)" {...props}>
          <Field
            as={DateInput}
            name="dateTimeMin.value"
            label={COMMON_LOCALE.from}
            value={createDateFromResponse(values.dateTimeMin.value as MomentInput)}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFieldValue('dateTimeMin.value', createLocalDateISO8601(e.target.value));
            }}
            showTimeInput={false}
            error={errors.dateTimeMin?.value}
            maxDate={dates?.[1]}
            minDate={dates?.[0]}
            isDisabled={!periodId}
          />

          <Field
            as={DateInput}
            name="dateTimeMax.value"
            label={COMMON_LOCALE.to}
            value={createDateFromResponse(values.dateTimeMax.value as MomentInput)}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFieldValue('dateTimeMax.value', createLocalDateISO8601(e.target.value));
            }}
            showTimeInput={false}
            error={errors.dateTimeMax?.value}
            maxDate={dates?.[1]}
            minDate={dates?.[0]}
            isDisabled={!periodId}
          />
          <GridItem colSpan={2}>
            <Field as={Select} name="stationId.value" label="Идентификатор размещенной станции" options={stations} />
          </GridItem>
          <Field as={Input} name="number.value" label="Номер поручения" value={values.number.value} />

          {children}
        </Grid>

        <FilterFormControls
          onReset={(e) => {
            handleReset(e);
            setPeriodId('');
            setTimeout(submitForm, 0);
          }}
          onSubmit={submitForm}
          isDisabled={isDisabled}
          isLoading={isLoading}
        />
      </Flex>
      <PeriodsButtons periodId={periodId} periods={periods} submitPeriod={submitPeriod} />
    </FormikProvider>
  );
};
