import { ERROR_MESSAGES, SUCCESS_MESSAGES } from 'constants/messages';
import { createTouchedErrors } from 'features/create-touched-errors';
import { FormikProvider, useFormik } from 'formik';
import { useNotifications } from 'hooks/useNotifications';
import { useRequest } from 'hooks/useRequest';
import { concentrationApiPath, consentrationPath, IConcentraionItem } from '../../consts';
import { ConcentrationFields } from './ConcentrationFields';
import { PDKSchema } from 'models/schemas';
import { useEffect } from 'react';
import { LoaderItem } from 'components/common';
import { useGoBackWithFallback } from 'lib/utils/goBackWithFallback';
import { useParams } from 'react-router-dom';
import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import { nsiPdkBreadcrumbs } from 'pages/dictionaries/NSIPdk/consts/breadcrumbs';
import { useDictionary } from 'hooks/useDictionary';

export const EditConcentration = () => {
  useUpdateBreadcrumbs(nsiPdkBreadcrumbs);
  const { id } = useParams<{ id: string }>();
  const { backWithFallback } = useGoBackWithFallback();
  const { successNotify, errorNotify } = useNotifications();

  const onGetPDKError = (error: any) => {
    const message = error?.descriptions[0]?.message || '';
    errorNotify({
      key: 'file/edir/error',
      message: `${ERROR_MESSAGES.EDIT}. ${message}`,
    });
    backWithFallback(consentrationPath);
  };

  const {
    result,
    get,
    isLoading: isPDKLoading,
  } = useRequest<IConcentraionItem>(
    `nsi/v10/pdk/substance/pdk/getById/${id}`,
    undefined,
    onGetPDKError
  );

  useEffect(() => {
      get();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { dictionaries, isDictionariesLoading } = useDictionary({ url: '/nsi/v10/substance/dictionaries' });

  const initialValues = {
    chem_desc: '',
    chemical_id: '',
    id: '',
    isDeleted: false,
    natural_environment: '',
    pdkEndDate: '',
    pdkKind: '',
    pdkMax: 0,
    pdkMin: 0,
    pdkStartDate: '',
    pdk_document: '',
    pdk_status: '',
    prim: '',
  };

  const onSuccess = () => {
    successNotify({
      key: 'file/edit/success',
      message: SUCCESS_MESSAGES.CREATE,
    });
    backWithFallback(consentrationPath);
  };

  const onError = (error: any) => {
    const message = error?.descriptions[0]?.message || '';
    errorNotify({
      key: 'file/edir/error',
      message: `${ERROR_MESSAGES.EDIT}. ${message}`,
    });
  };

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    validationSchema: PDKSchema,
    initialValues: { ...initialValues, ...result },
    onSubmit: (values) => {
      put(values);
    },
  });

  const { put } = useRequest<IConcentraionItem, any>(
    `${concentrationApiPath}`,
    onSuccess,
    onError,
  );

  if (isDictionariesLoading) {
    return <LoaderItem />;
  }

  return (
    <>
      {isPDKLoading ? (
        <>
          <LoaderItem />
        </>
      ) : (
        <FormikProvider
          value={{
            ...formik,
            errors: createTouchedErrors(formik.errors, formik.touched),
          }}
        >
          <ConcentrationFields
            isEditMode
            dictionaries={dictionaries?.data}
            editedObject={result}
          />
        </FormikProvider>
      )}
    </>
  );
};
