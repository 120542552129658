import React, { memo, useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { Modal, SubsystemHeader } from 'components/common';

import { askzvLocales } from './locales';
import { useDisclosure } from '@chakra-ui/hooks';
import { UploadFileModalContent } from 'features/UploadFileDragAndDrop/UploadFileModalContent';
import { apiPathAskzv } from 'pages/data/Water/ASKZV/apiPath';

interface Props {
  navigationTabs?: React.ReactNode;
  filtersComponent?: React.ReactNode;
  addPath: string;
  refetch: () => void;
  isWrite: boolean;
}

export const WaterASKZVHeader = memo(({ refetch, addPath, filtersComponent, navigationTabs, isWrite }: Props) => {
  const { push } = useHistory();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleAdd = useCallback(() => {
    push(addPath);
  }, [addPath, push]);

  return (
    <>
      <SubsystemHeader
        title={askzvLocales.title}
        addButtonProps={{ onClick: handleAdd, isDisabled: !isWrite }}
        onUploadClick={onOpen}
        navigationTabs={navigationTabs}
        filtersComponent={filtersComponent}
        isUploadDisabled={!isWrite}
      />
      <Modal isOpen={isOpen} onClose={onClose} header="Загрузка данных из файла">
        <UploadFileModalContent
          createTypesPath={() => apiPathAskzv.types}
          createUploadPath={() => apiPathAskzv.load}
          onClose={onClose}
          refetch={refetch}
        />
      </Modal>
    </>
  );
});
