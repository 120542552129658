import { DateInput, FormWrapper, Input, Select } from 'components/common';
import { Box, Grid, Heading } from '@chakra-ui/react';
import { getSelectOptionsFromDictionaryByName } from 'features/get-select-options-from-dictionary';
import { Field, useFormikContext } from 'formik';
import { createDateFromResponse, createDateToRequestWithTimezone } from 'lib/create-date';
import { TDictionariesArray } from 'models/dictionaries';
import { FC, useMemo } from 'react';
import { useLocation } from 'react-router';
import { SoilListItem } from '../const/types';
import FormFieldFromDictionary from 'components/form/FormFieldFromDictionary';
import { getFilteredOptionsByPrivileges } from 'features/getFilteredOptionsByPrivileges';
import isRequiredInSchema from 'lib/utils/forms/isReqiuredInSchema';
import { SoilFormValidationSchema, soilSchemaRequiredIds } from 'models/schemas/soil/soil';

interface CommonTabProps {
  dictionaries: {
    data: TDictionariesArray;
  } | null;
}

export const CommonTab: FC<CommonTabProps> = ({ dictionaries, children }) => {
  const { setFieldValue, values, errors } = useFormikContext<SoilListItem>();

  const sourceOptions = useMemo(
    () =>
      getFilteredOptionsByPrivileges(getSelectOptionsFromDictionaryByName(dictionaries?.data, 'sources'), 'isEditable', 'value', values.sourceId),
    [dictionaries?.data, values.sourceId],
  );

  const monitoringParameters = useMemo(
    () => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'monitoringParameters'),
    [dictionaries],
  );

  const schema = SoilFormValidationSchema();

  const location = useLocation();
  const isEditMode = location.pathname.includes('/edit');

  return (
    <Box flexDirection="column">
      <FormWrapper>
        <Grid marginTop={30} gap={15} marginBottom={30}>
          <Heading variant="h2">Данные обследования</Heading>
          <Field
            as={Input}
            name="protocolNumber"
            label="Номер протокола"
            error={errors?.protocolNumber}
            disabled={isEditMode}
            isRequired={isRequiredInSchema(schema, 'protocolNumber')}
          />

          <DateInput
            name="protocolDate"
            label="Дата протокола"
            value={values.protocolDate}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setFieldValue('protocolDate', createDateToRequestWithTimezone(e.target.value))
            }
            showTimeInput={false}
            isDisabled={isEditMode}
            error={errors?.protocolDate}
            isRequired={isRequiredInSchema(schema, 'protocolDate')}
          />

          <Field
            as={Select}
            name="sourceId"
            label="Площадка"
            options={sourceOptions}
            disabled={isEditMode}
            isDisabled={isEditMode}
            error={errors?.sourceId}
            isRequired={isRequiredInSchema(schema, 'sourceId')}
          />

          <DateInput
            name="probeDate"
            label="Дата отбора пробы"
            value={createDateFromResponse(values.probeDate)}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setFieldValue('probeDate', createDateToRequestWithTimezone(e.target.value))
            }
            showTimeInput={false}
            isDisabled={isEditMode}
            error={errors?.probeDate}
            isRequired={isRequiredInSchema(schema, 'probeDate')}
          />

          {monitoringParameters.map((field) => (
            <FormFieldFromDictionary
              key={field.parameterId}
              field={field}
              isDisabled={isEditMode}
              isRequired={Boolean(field.parameterId && soilSchemaRequiredIds.includes(field.parameterId))}
            />
          ))}

          {children}
        </Grid>
      </FormWrapper>
    </Box>
  );
};
