import { Field, FormikProvider, useFormik } from 'formik';
import { DateInput, Input, Select } from 'components/common';
import { Flex, Grid } from '@chakra-ui/react';
import { createDateFromResponse, createDateToRequestWithTimezone } from 'lib/create-date';
import { COMMON_LOCALE } from 'constants/common';
import React, { useEffect } from 'react';
import { IFilterItem, TDictionaryItemDictionary, TFiltersArray } from 'models';
import { FiltersDatepickersSchema } from '../../models/schemas/filtersDatepickersSchema/FiltersDatepickersSchema';
import FilterFormControls from 'components/form/FilterFormControls';
import combineFilters from 'lib/utils/combineFilters';
import { MomentInput } from 'moment';
import { FilterCheckbox } from 'features/Filter/components';

const initialValues: Record<string, IFilterItem> = {
  dateTimeMin: {
    key: 'date',
    operation: 'GTE',
    value: '',
  },
  dateTimeMax: {
    key: 'date',
    operation: 'LTE',
    value: '',
  },
  fio: {
    key: 'fio',
    operation: 'CONTAINS',
    value: '',
  },
  areaId: {
    key: 'areaId',
    operation: 'EQ',
    value: null,
  },
  type: {
    key: 'type',
    operation: 'EQ',
    value: null,
  },
  deleted: {
    key: 'deleted',
    operation: 'EQ',
    value: false,
  },
};

interface FilterProps {
  isLoading?: boolean;
  isDisabled?: boolean;
  types: TDictionaryItemDictionary[] | undefined;
  admAreas: TDictionaryItemDictionary[] | undefined;
  onSubmit: (values: any) => void;
  filters: TFiltersArray;
}

export const Filter = ({
  isLoading,
  isDisabled,
  onSubmit,
  types,
  admAreas,
  filters,
  children,
}: React.PropsWithChildren<FilterProps>) => {
  const {
    setValues,
    handleReset,
    values,
    setFieldValue,
    errors,
    submitForm,
    ...formik
  } = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    validationSchema: FiltersDatepickersSchema([{ dateFromFieldName: 'dateTimeMin', dateToFieldName: 'dateTimeMax' }]),
    initialValues,
    onSubmit,
  });

  useEffect(() => {
    setValues(combineFilters(initialValues, filters));
  }, [filters, setValues]);

  const resetFilters = (e: any) => {
    handleReset(e);
    setTimeout(() => onSubmit(initialValues), 0);
  };

  return (
    <FormikProvider
      value={{
        values,
        errors,
        handleReset,
        submitForm,
        setFieldValue,
        setValues,
        ...formik,
      }}
    >
      <Flex>
        <Grid width="100%" maxWidth="1200px" gap={2} templateColumns="repeat(5, 1fr)">
          <Field
            as={DateInput}
            name="dateTimeMin.value"
            label={COMMON_LOCALE.from}
            value={createDateFromResponse(values.dateTimeMin.value as MomentInput)}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFieldValue('dateTimeMin.value', createDateToRequestWithTimezone(e.target.value));
            }}
            error={errors.dateTimeMin?.value}
            showTimeInput
          />

          <Field
            as={DateInput}
            name="dateTimeMax.value"
            label={COMMON_LOCALE.to}
            value={createDateFromResponse(values.dateTimeMax.value as MomentInput)}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFieldValue('dateTimeMax.value', createDateToRequestWithTimezone(e.target.value));
            }}
            error={errors.dateTimeMax?.value}
            showTimeInput
          />

          <Field as={Input} name="fio.value" label="Фамилия дежурного" />

          <Field as={Select} options={admAreas} isDisabled={!admAreas?.length} name="areaId.value" label="Округ" />

          <Field as={Select} options={types} isDisabled={!types?.length} name="type.value" label="Вид данных" />

          <Field
            as={FilterCheckbox}
            value={values.deleted.value}
            checked={values.deleted.value !== false}
            name="deleted.value"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFieldValue(
                'deleted.value',
                e.target.checked
              );
            }}
            label="Показать удаленные"
          />

          {children}
        </Grid>

        <FilterFormControls
          onReset={resetFilters}
          onSubmit={submitForm}
          isLoading={isLoading}
          isDisabled={isDisabled}
        />
      </Flex>
    </FormikProvider>
  );
};
