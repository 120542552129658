import { Route } from 'react-router-dom';
import { PDKExcessJournal } from 'pages/PDKExcessJournal';
import { AddPDKExcessJournalItem } from 'pages/PDKExcessJournal/Form/AddPDKExcessJournalItem';
import { EditPDKExcessJournalItem } from 'pages/PDKExcessJournal/Form/EditPDKExcessJournalItem';

const PdkExcessJournalRoutes = () => (
  <>
    <Route path="/pdk-excess-journal" exact component={PDKExcessJournal} />
    <Route path="/pdk-excess-journal/add" exact component={AddPDKExcessJournalItem} />
    <Route path="/pdk-excess-journal/edit/:id" exact component={EditPDKExcessJournalItem} />
  </>
);

export default PdkExcessJournalRoutes;
