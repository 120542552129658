export const SurfaceWaterLocales:Record<string, string> = {
  title: 'Мониторинг состояния поверхностных вод',
  waterObject: 'Водный объект',
  // названия параметров
  aluminium: 'Алюминий, мг/дм3',
  ammoniumIon: 'Аммоний-ион,  мг/дм3',
  ammoniumIonAzot: 'Азот аммонийный,  мг/дм3',
  anionicSurfactants: 'АПАВ, мг/дм3',
  arsenic: 'Мышьяк, мг/дм3',
  biologicalOxygenDemand5: 'БПК5, мг/дм3',
  cadmium: 'Кадмий, мг/дм3',
  calcium: 'Кальций, мг/дм3',
  chemicalOxygenDemand: 'ХПК, мгО/дм3',
  chlorideIon: 'Хлорид-ион, мг/дм3',
  chrome: 'Хром, мг/дм3',
  chrome6: 'Хром (VI), мг/дм3',
  cobalt: 'Кобальт, мг/дм3',
  copper: 'Медь, мг/кг',
  diaphaneity: 'Прозрачность, см',
  district: 'Район',
  waterTemperature: 'Температура воды, °С',
  dissolvedOxygen: 'Растворенный кислород, мг/дм3',
  fluorideIon: 'Фторид-ион, мг/дм3',
  formaldehyde: 'Формальдегид, мг/дм3',
  h2sAndSulfides: 'Сероводород и сульфиды,  мкг/дм3',
  h2sAndSulfidesMgDm3: 'Сероводород и сульфиды,  мг/дм3',
  iron: 'Железо, мг/дм3',
  lead: 'Свинец, мг/дм3',
  magnesium: 'Магний, мг/дм3',
  manganese: 'Марганец, мг/дм3',
  molybdenum: 'Молибден, мг/дм3',
  nickel: 'Никель, мг/дм3',
  nitrateIon: 'Нитрат-ион,  мг/дм3',
  nitratAzot: 'Азот нитратный,  мг/дм3',
  nitriteIon: 'Нитрит-ион,  мг/дм3',
  nitrinAzot: 'Азот нитритный,  мг/дм3',
  petrochemicals: 'Нефтепродукты, мг/дм3',
  phIndex: 'Водородный показатель, ед. рН',
  phenols: 'Фенолы, мг/дм3',
  phosphateIon: 'Фосфат-ион,  мг/дм3',
  phosphatePhosfor: 'Фосфор фосфатов,  мг/дм3',
  potassium: 'Калий, мг/дм3',
  selenium: 'Селен, мг/дм3',
  smell20: 'Запах при 20С, балл',
  smell60: 'Запах при 60С, балл',
  sodium: 'Натрий, мг/дм3',
  solid: 'Сухой остаток, мг/дм3',
  sulfateIon: 'Сульфат-ион, мг/дм3',
  suspendedSubstance: 'Взвешенные вещества, мг/дм3',
  zinc: 'Цинк, мг/дм3',
  // общие данные
  protocolNumber: 'Номер протокола',
  protocolDate: 'Дата протокола',
  sampleDate: 'Дата отбора пробы',
  waterObjectTypeId: 'Водный объект',
  source: 'Створ наблюдения',
  region: 'Округ',
  consumerId: 'Заказчик',
  organizationId: 'Организация, выполнившая отбор проб',
  organization: 'Организация',
  // координаты
  longitude: 'Долгота',
  latitude: 'Широта',

};
