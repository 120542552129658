const rootReporting = 'reporting/v10/reports';
const rootReportingDevice = `${rootReporting}/device`;
const rootReportingStation = `${rootReporting}/station`;

export const EquipmentReportsPathsAPI = {
  table: `${rootReporting}/table/device`,
  diagram: {
    station: {
      all: `${rootReportingStation}/chart-all`,
      oneById: `${rootReportingStation}/chart-by-id`
    },
    device: {
      all: `${rootReportingDevice}/chart-all`,
      oneById: `${rootReportingDevice}/chart-by-id`
    },
  }
};
