import { createTouchedErrors } from 'features/create-touched-errors';
import { FormikProps, FormikProvider } from 'formik';
import React from 'react';
import MainReportForm from './Form';
import { IMainReport } from './types';
import { IUploadMedia } from 'components/common/FileUploadDragAndDrop/types';

interface Props {
  formik: FormikProps<IMainReport>;
  taskId?: string;
  onSave: () => any;
  media: IUploadMedia[][];
  onMediaSave: (media: IUploadMedia[], idx: number) => void;
  backPath: string;
  browserId: string;
}

const NoiseReport = ({ formik, taskId, onSave, media, onMediaSave, backPath, browserId }: Props) => (
  <FormikProvider
    value={{
      ...formik,
      errors: createTouchedErrors(formik.errors, formik.touched),
    }}
  >
    <MainReportForm
      taskId={taskId}
      onSave={onSave}
      media={media}
      onMediaSave={onMediaSave}
      backPath={backPath}
      browserId={browserId}
    />
  </FormikProvider>
);

export default NoiseReport;
