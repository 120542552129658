import { Button, SubsystemHeader } from 'components/common';
import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import { MaterialTable } from 'features/material-table/MaterialTableMini';

import { Filter } from './Filter';
import { useAvailability, useEnhancedNotification, useHandleDeleteData, useList, useRequest } from 'hooks';
import React, { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { ISOKJournalItem } from 'pages/PDKExcessJournal/types';
import { Column } from '@material-table/core';
import { createLocalDateDDMMYYYYWithTime } from 'lib/create-date';
import subsystemIdsMap from 'constants/subsystemIdsMap';
import { ExtensionToMimeTypeEnum } from 'lib/utils/files/getFileExtensionByMimeType';
import { downloadFileFromResponse } from 'lib/utils/files/downloadFile';
import { Flex } from '@chakra-ui/react';
import { DeleteIcon } from 'components/icons';
import { useDictionary } from 'hooks/useDictionary';
import { addFiltersToColumns, ColumnWithFilter } from 'lib/utils/tableFilter';
import { TableFilterTypeEnum } from 'components/table/filters/TableFilter';
import { DEFAULT_PAGE_SIZE_NEW_TABLE } from '../../constants/tables';
import { FullPageWrapper } from '../../components/table/FullPageWrapper';

export const PDKExcessJournal = () => {
  useUpdateBreadcrumbs([{ breadcrumb: 'Журнал превышения ПДК', path: '/pdk-excess-journal' }]);
  const { onErrorDelete } = useEnhancedNotification();
  const history = useHistory();
  const { isExecute, isAvailable } = useAvailability(subsystemIdsMap.pdkExcessJournal);
  const { isDictionariesLoading, admAreas, types, reasons } = useDictionary({
    url: '/appeal/v10/sok/journal/dictionaries',
  });

  const {
    refetch,
    isLoading: isListLoading,
    response,
    toggleOrder,
    onPageChange,
    filterDTO,
    onSearchText,
    errorText,
    renderOnlySelectedCheckbox,
    selectedIds,
    onSelectionChange,
    selectedIndexes,
    onSubmitFilters,
    setFilters,
    materialTableProps,
  } = useList<ISOKJournalItem, keyof ISOKJournalItem>('/appeal/v10/sok/journal/list', {
    initialOrderFieldKey: '-date',
    saveFiltersState: true,
    selectionKey: 'id',
    defaultPageSize: DEFAULT_PAGE_SIZE_NEW_TABLE,
    resetDataOnNextRequest: true,
  });
  const { ModalComponent, handleOpenModal } = useHandleDeleteData({ refetch });
  const isLoading = useMemo(() => isDictionariesLoading || isListLoading, [isDictionariesLoading, isListLoading]);

  const { post: download } = useRequest('');
  const downloadAsFile = useCallback(
    async (fileType: ExtensionToMimeTypeEnum) => {
      const response = await download(
        { ...filterDTO, limit: -1, offset: 0 },
        {
          url:
            fileType === ExtensionToMimeTypeEnum.xlsx
              ? '/appeal/v10/sok/journal/export'
              : '/appeal/v10/sok/journal/list',
          headers: { ACCEPT: fileType },
          responseType: 'blob',
        },
      );
      downloadFileFromResponse({ response, name: 'export_sok_journal', type: fileType });
    },
    [download, filterDTO],
  );

  const { post } = useRequest('/appeal/v10/sok/journal', refetch, onErrorDelete);
  const deleteSelected = useCallback(() => {
    post({ ids: selectedIds || [] }, { url: '/appeal/v10/sok/journal/delete' });
  }, [post, selectedIds]);

  const restoreSelected = useCallback(() => {
    post({ ids: selectedIds || [] }, { url: '/appeal/v10/sok/journal/undelete' });
  }, [post, selectedIds]);

  const columns: ColumnWithFilter<ISOKJournalItem>[] = useMemo(
    () => [
      {
        title: '№ п/п',
        field: 'number',
        filterType: TableFilterTypeEnum.number,
        minWidth: 70,
      },
      {
        title: 'Дата',
        field: 'date',
        render: (row) => createLocalDateDDMMYYYYWithTime(row.date)?.split(' ')[0],
      },
      {
        title: 'Время',
        field: 'time', // needed for showing proper sorting arrows
        render: (row) => createLocalDateDDMMYYYYWithTime(row.date)?.split(' ')[1],
      },
      {
        title: 'Фамилия дежурного',
        field: 'fio',
        filterType: TableFilterTypeEnum.string,
      },
      {
        title: 'Округ',
        field: 'areaName',
        filterType: TableFilterTypeEnum.string,
      },
      {
        title: 'Адрес места происшествия',
        field: 'address',
        sorting: false,
        filterType: TableFilterTypeEnum.string,
      },
      {
        title: 'Описание источника информации о происшествии',
        field: 'description',
        sorting: false,
        filterType: TableFilterTypeEnum.string,
      },
      {
        title: 'Обращения граждан',
        field: 'citizenReporting',
        sorting: false,
        filterType: TableFilterTypeEnum.number,
      },
      {
        title: 'Другие источники',
        field: 'otherReporting',
        render: (row) => reasons?.find((item) => item.value === row.otherReporting)?.name,
        sorting: false,
        filterType: TableFilterTypeEnum.string,
      },
      {
        title: 'АСКЗА (наименование)',
        field: 'stationName',
        sorting: false,
        filterType: TableFilterTypeEnum.string,
      },
      {
        title: 'Сероводород/H2S (ПДК)',
        field: 'pdkH2s',
        sorting: false,
        filterType: TableFilterTypeEnum.number,
      },
      {
        title: 'Озон/O3 (ПДК)',
        field: 'pdkO3',
        sorting: false,
        filterType: TableFilterTypeEnum.number,
      },
      {
        title: 'Азота диоксид/NO2 (ПДК)',
        field: 'pdkNO2',
        sorting: false,
        filterType: TableFilterTypeEnum.number,
      },
      {
        title: 'Азота оксид/NO (ПДК)',
        field: 'pdkNO',
        sorting: false,
        filterType: TableFilterTypeEnum.number,
      },
      {
        title: 'Серы диоксид/SO2 (ПДК)',
        field: 'pdkSO2',
        sorting: false,
        filterType: TableFilterTypeEnum.number,
      },
      {
        title: 'Углерода монооксид/CO (ПДК)',
        field: 'pdkCO',
        sorting: false,
        filterType: TableFilterTypeEnum.number,
      },
      {
        title: 'РМ10 (ПДК)',
        field: 'pdkPM10',
        sorting: false,
        filterType: TableFilterTypeEnum.number,
      },
      {
        title: 'РМ2,5 (ПДК)',
        field: 'pdkPM25',
        sorting: false,
        filterType: TableFilterTypeEnum.number,
      },
      {
        title: 'Прочее (ПДК)',
        field: 'pdkOther',
        sorting: false,
        filterType: TableFilterTypeEnum.number,
      },
      {
        title: 'Аммиак/NH3 (ПДК)',
        field: 'pdkNH3',
        sorting: false,
        filterType: TableFilterTypeEnum.number,
      },
      // действия од сок
      {
        title: 'Привлечение ПЭЛ',
        field: 'attractingPel',
        // eslint-disable-next-line no-nested-ternary
        render: (row) => (row.attractingPel === null ? '-' : row.attractingPel ? 'п' : 'о'),
        sorting: false,
      },
      {
        title: 'Привлечение сотрудников АИ',
        field: 'attractingAi',
        render: (row) => (row.attractingAi ? '+' : '-'),
        sorting: false,
      },
      {
        title: 'Привлечение сотрудников др. служб',
        field: 'attractingOther',
        render: (row) => (row.attractingOther ? '+' : '-'),
        sorting: false,
      },
      // Использование ПЭЛ
      {
        title: '№ ПЭЛ',
        field: 'pelNumber',
        sorting: false,
        filterType: TableFilterTypeEnum.number,
      },
      {
        title: 'Дата выезда',
        field: 'pelDate',
        render: (row) => createLocalDateDDMMYYYYWithTime(row.pelDate),
        sorting: false,
      },
      {
        title: 'Время отбора проб воздуха',
        field: 'samplingTime',
        render: (row) => createLocalDateDDMMYYYYWithTime(row.samplingTime),
        sorting: false,
      },
      {
        title: 'Адрес контрольной точки',
        field: 'controlPointAddress',
        sorting: false,
        filterType: TableFilterTypeEnum.string,
      },
      {
        title: 'Углерода оксид/CO (ПЭЛ)',
        field: 'pelPdkCO',
        sorting: false,
        filterType: TableFilterTypeEnum.number,
      },
      {
        title: 'Азота оксид/NO (ПЭЛ)',
        field: 'pelPdkNO',
        sorting: false,
        filterType: TableFilterTypeEnum.number,
      },
      {
        title: 'Азота диоксид/NO2 (ПЭЛ)',
        field: 'pelPdkNO2',
        sorting: false,
        filterType: TableFilterTypeEnum.number,
      },
      {
        title: 'Сумма углеводородных соединений /CHx (в мг/м3) (ПЭЛ)',
        field: 'pelPdkCHx',
        sorting: false,
        filterType: TableFilterTypeEnum.number,
      },
      {
        title: 'Метан/CH4 (в мг/м3) (ПЭЛ)',
        field: 'pelPdkCH4',
        sorting: false,
        filterType: TableFilterTypeEnum.number,
      },
      {
        title: 'Безметановые углеводороды/CH- (в мг/м3) (ПЭЛ)',
        field: 'pelPdkCH',
        sorting: false,
        filterType: TableFilterTypeEnum.number,
      },
      {
        title: 'Серы диоксид/SO2 (ПЭЛ)',
        field: 'pelPdkSO2',
        sorting: false,
        filterType: TableFilterTypeEnum.number,
      },
      {
        title: 'Аммиак/NH3 (ПЭЛ)',
        field: 'pelPdkNH3',
        sorting: false,
        filterType: TableFilterTypeEnum.number,
      },
      {
        title: 'Сероводород/H2S (ПЭЛ)',
        field: 'pelPdkH2s',
        sorting: false,
        filterType: TableFilterTypeEnum.number,
      },
      {
        title: 'РМ10 (ПЭЛ)',
        field: 'pelPdkPM10',
        sorting: false,
        filterType: TableFilterTypeEnum.number,
      },
      {
        title: 'РМ2,5 (ПЭЛ)',
        field: 'pelPdkPM25',
        sorting: false,
        filterType: TableFilterTypeEnum.number,
      },
      {
        title: 'Комментарий',
        field: 'comment',
        sorting: false,
        filterType: TableFilterTypeEnum.string,
      },
    ],
    [reasons],
  );

  const columnsWithFilters = useMemo<Array<Column<ISOKJournalItem>>>(
    () => addFiltersToColumns({ filters: filterDTO.filters, setFilters, columns }),
    [columns, filterDTO.filters, setFilters],
  );

  return (
    <FullPageWrapper>
      <SubsystemHeader
        title="Журнал контроля превышения ПДК"
        filtersComponent={
          <Filter
            isDisabled={isLoading}
            types={types}
            admAreas={admAreas}
            onSubmit={(values) => {
              onSubmitFilters(Object.values(values));
            }}
            isLoading={isLoading}
            filters={filterDTO.filters}
          >
            {renderOnlySelectedCheckbox}
          </Filter>
        }
        addButtonProps={{
          onClick: () => history.push('/pdk-excess-journal/add'),
          isDisabled: !isAvailable,
        }}
      />

      <MaterialTable
        isNotExecute={isExecute}
        filterDTO={filterDTO}
        data={response?.data}
        meta={response?.meta}
        columns={columnsWithFilters}
        toggleOrder={toggleOrder}
        onPageChange={onPageChange}
        actions={[
          {
            type: 'edit',
            onClick: (_e, item) => {
              if (!Array.isArray(item)) {
                history.push(`/pdk-excess-journal/edit/${item.id}`);
              }
            },
          },
          {
            position: 'row',
            action: (row) => ({
              icon: () => <DeleteIcon />,
              onClick: () => handleOpenModal({ url: `/appeal/v10/sok/journal/delete/${row.id}`, method: 'POST' }),
              tooltip: 'Удалить',
              hidden: row.deleted || !isAvailable,
            }),
          },
        ]}
        downloadAsFile={{
          downloadAsFile,
          types: Object.values(ExtensionToMimeTypeEnum),
        }}
        options={{
          search: true,
        }}
        isLoading={isLoading}
        onSearch={onSearchText}
        errorText={errorText}
        onSelectionChange={onSelectionChange}
        selectedIndexes={selectedIndexes}
        {...materialTableProps}
      />

      <Flex align="center" my={2} px={2}>
        <Button height="30px" onClick={deleteSelected} marginRight={20} disabled={!isAvailable}>
          Удалить выбранные
        </Button>
        <Button height="30px" onClick={restoreSelected} variant="solid" disabled={!isAvailable}>
          Восстановить выбранные
        </Button>
      </Flex>

      {ModalComponent}
    </FullPageWrapper>
  );
};
