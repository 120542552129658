import { useCallback } from 'react';
import { COMMON_LOCALE } from 'constants/common';
import { Button, DateInput } from 'components/common';
import { Field, useFormikContext } from 'formik';
import { AddDeviceToStation } from 'pages/equipment/Stations/types';
import { devicesLocale } from '../../locale';
import { Flex } from '@chakra-ui/react';
import { createUTCTime } from 'lib/create-date';

interface FormFieldsProps {
  onCancel: () => void;
  submitIsDisabled: boolean;
  isSubmitLoading: boolean;
}

export const FormFields = ({ onCancel, submitIsDisabled, isSubmitLoading }: FormFieldsProps) => {
  const { handleSubmit, errors, setFieldValue } = useFormikContext<AddDeviceToStation>();
  const onSubmit = useCallback(() => {
    handleSubmit();
  }, [handleSubmit]);

  return (
    <div>
      <Field
        as={DateInput}
        name="dateStart"
        label={devicesLocale.dateStart}
        error={errors.dateStart}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setFieldValue('dateStart', createUTCTime(e.target.value));
        }}
      />
      <Flex marginTop={5}>
        <Button
          isLoading={isSubmitLoading}
          onClick={onSubmit}
          isDisabled={submitIsDisabled}
        >
          {COMMON_LOCALE.save}
        </Button>
        <Button onClick={onCancel} variant="ghost" marginRight={5}>
          {COMMON_LOCALE.cancel}
        </Button>
      </Flex>
    </div>
  );
};
