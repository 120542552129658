import { ConfirmModal, SubsystemHeader } from 'components/common';
import { MaterialTable } from 'features/material-table/MaterialTableMini';
import { ERROR_MESSAGES, REMOVE_CONFIRMATION, SUCCESS_MESSAGES } from 'constants/messages';
import React, { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDisclosure, useList, useNotifications, useRequest } from 'hooks';
import { Column } from '@material-table/core';
import { createLocalDateDDMMYYYYWithTime } from 'lib/create-date';
import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import appLocale from 'constants/appLocale';
import { adminNODLogPath, NODLogUrl } from './consts';
import { NODUploadLogFilter } from './NODUploadLogFilter';
import { IUploadItem } from './types';
import { useDictionary } from 'hooks/useDictionary';
import { DEFAULT_PAGE_SIZE_NEW_TABLE } from '../../../constants/tables';
import { FullPageWrapper } from '../../../components/table/FullPageWrapper';
import { DefaultTableCellSize } from '../../../components/table/DefaultTableCellSize';

export const NODUploadLog = () => {
  useUpdateBreadcrumbs([
    { breadcrumb: appLocale.admin.main, path: '/admin' },
    {
      breadcrumb: appLocale.admin.NODUploadLog,
      path: adminNODLogPath,
    },
  ]);
  const { push } = useHistory();
  const [removedItem, setRemovedItem] = useState<string>('');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { successNotify, errorNotify } = useNotifications();

  const { dictionaries } = useDictionary({ url: '/ehd/v10/catalog-uploader-history/dictionaries' });

  const {
    isLoading,
    response,
    toggleOrder,
    setFilters,
    onPageChange,
    filterDTO,
    onSearchText,
    downloadAsFile,
    refetch,
    selectedIndexes,
    renderOnlySelectedCheckbox,
    onSubmitFilters,
    onSelectionChange,
  } = useList<IUploadItem, keyof IUploadItem>(NODLogUrl, {
    initialOrderFieldKey: '-uploadDatetime',
    selectionKey: 'uploadId',
    defaultPageSize: DEFAULT_PAGE_SIZE_NEW_TABLE,
    resetDataOnNextRequest: true,
  });

  const onSuccess = () => {
    onClose();
    refetch();
    successNotify({
      key: 'NODUploadLog/delete/success',
      message: SUCCESS_MESSAGES.DELETE,
    });
  };
  const onError = () => {
    onClose();
    errorNotify({
      key: 'NODUploadLog/delete/error',
      message: ERROR_MESSAGES.DELETE,
    });
  };

  const { remove } = useRequest('', onSuccess, onError);

  const columns = useMemo<Column<IUploadItem>[]>(
    () => [
      {
        title: 'Дата и время выгрузки',
        field: 'uploadDatetime',
        render: (row) => createLocalDateDDMMYYYYWithTime(row.uploadDatetime),
      },
      {
        title: 'Название отчета НОД',
        field: 'reportName',
        render: (row) => <DefaultTableCellSize maxWidth={300}>{row.reportName}</DefaultTableCellSize>
      },
      {
        title: 'Статус выгрузки',
        field: 'uploadStatusName',
      },
      {
        title: 'Количество строк',
        field: 'count',
      },
      {
        title: 'Успешных строк',
        field: 'successCount',
      },
      {
        title: 'Неуспешных строк',
        field: 'failedCount',
      },
      {
        title: 'Строк в процессе',
        field: 'processCount',
      },
    ],
    []
  );
  return (
    <FullPageWrapper>
      <SubsystemHeader
        title={appLocale.admin.NODUploadLog}
        filtersComponent={
          <NODUploadLogFilter dictionaries={dictionaries} onSubmitFilters={onSubmitFilters}>
            {renderOnlySelectedCheckbox}
          </NODUploadLogFilter>
        }
      />

      <MaterialTable
        data={response?.data}
        meta={response?.meta}
        toggleOrder={toggleOrder}
        filterDTO={filterDTO}
        onPageChange={onPageChange}
        columns={columns}
        actions={[
          {
            type: 'view',
            onClick: (_e, item) => {
              push(`${adminNODLogPath}/view/${item.uploadId}`, {
                reportNumber: item.reportNumber,
                reportName: item.reportName,
              });
            },
          },
          {
            type: 'delete',
            onClick: (_e, item) => {
              onOpen();
              setRemovedItem(item.uploadId);
            },
          },
        ]}
        isLoading={isLoading}
        setFilters={setFilters}
        options={{ selection: true }}
        onSearch={onSearchText}
        downloadAsFile={downloadAsFile}
        onSelectionChange={onSelectionChange}
        selectedIndexes={selectedIndexes}
      />
      <ConfirmModal
        isOpen={isOpen}
        onClose={onClose}
        onClick={() =>
          remove({
            url: `${NODLogUrl}/${removedItem}`,
          })
        }
        header={REMOVE_CONFIRMATION}
      />
    </FullPageWrapper>
  );
};
