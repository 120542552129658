import { Stack } from '@chakra-ui/react';
import { Input, Select } from 'components/common';
import { Field, useFormikContext } from 'formik';
import { TFLoraDictionaries } from 'pages/data/GreenPlants/Flora/useFloraDictionaries';
import React, { useEffect, useMemo, useState } from 'react';
import { GreenPlantsFormTypes } from '../../types';
import { GreenPlantsAreaLabels } from './constants';
import { TGreenPlantsForm } from 'pages/data/GreenPlants/Form/types';
import { useLocation } from 'react-router';
import isRequiredInSchema from 'lib/utils/forms/isReqiuredInSchema';
import { useGreenPlantsSchema } from 'models/schemas/pgr';
import { getSelectOptionsFromDictionaryByName } from 'features/get-select-options-from-dictionary';
import { TArea } from '../../../../../../GreenPlants/Form/useAreaById';
import { getFilteredOptionsByPrivileges } from 'features/getFilteredOptionsByPrivileges';

interface FormProps {
  entity: keyof typeof GreenPlantsFormTypes;
  isLoading: boolean;
  dictionaries?: Partial<TFLoraDictionaries>;
  area: TArea | null;
}

const initialValues: Record<string, string> = {
  address: '',
  district: '',
  region: '',
  landmark: '',
  dateStart: '',
  type: '',
  location: '',
  code: '',
  square: '',
  organization: '',
};

const AreaForm: React.FC<FormProps> = ({ entity, isLoading, dictionaries = {}, area }) => {
  const [district, setDistrict] = useState('');
  const [region, setRegion] = useState('');
  const [areaItem, setAreaItem] = useState<any>(initialValues);

  const ctx = useFormikContext<TGreenPlantsForm>();
  const location = useLocation();
  const isEditMode = location.pathname.includes('/edit');

  const { GreenPlantsSchema } = useGreenPlantsSchema(entity);

  const { areas = [], okrugs = [], rayons = [] } = dictionaries;

  const filteredAreas = useMemo(
    () => getFilteredOptionsByPrivileges(areas, 'isEditable', 'value', ctx.values[entity].areaId),
    [areas, ctx.values, entity],
  );

  const filteredRayons = useMemo(() => {
    if (district) {
      return rayons.filter((item) => item.parent === district);
    }
    return rayons;
  }, [district, rayons]);

  useEffect(() => {
    if (isEditMode && areas.length && ctx.values[entity].areaId) {
      const area = areas?.find((item) => item.value === ctx.values[entity].areaId);
      const region = area?.parent || '';
      const district = filteredRayons?.find((item) => item.value === region)?.parent || '';
      setRegion(region);
      setDistrict(district);
      setAreaItem(area);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditMode, areas, ctx.values[entity].areaId]);

  const lawnsDict = useMemo(
    () => getSelectOptionsFromDictionaryByName(area?.dictionaries?.data, 'lawns'),
    [area?.dictionaries],
  );

  return (
    <Stack>
      <Field
        isDisabled={isLoading || isEditMode}
        as={Select}
        name={`${entity}.areaId`}
        label={GreenPlantsAreaLabels.areaId}
        options={filteredAreas}
        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
          const areaItem = areas.find((el) => el.value === e.target.value);
          const district = rayons.find((item) => item.value === areaItem?.parent)?.parent || '';
          const region = rayons.find((item) => item.value === areaItem?.parent)?.value || '';

          setAreaItem(areaItem);
          ctx.setFieldValue(`${entity}.areaId`, e.target.value);
          setDistrict(district);
          setRegion(region);
          ctx.setFieldValue(`${entity}.plantId`, null);
          ctx.setFieldValue(`${entity}.lawnId`, null);
          setTimeout(() => ctx.validateField(`${entity}.areaId`), 0);
        }}
        isRequired={isRequiredInSchema(GreenPlantsSchema, `${entity}.fields.areaId`)}
        error={ctx.touched.lawns?.areaId && ctx.errors?.[entity]?.areaId}
      />

      {entity === 'lawns' && (
        <Field
          isDisabled={isLoading || isEditMode || !area?.dictionaries}
          as={Select}
          name={`${entity}.lawnId`}
          label={GreenPlantsAreaLabels.lawnName}
          options={lawnsDict}
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
            ctx.setFieldValue(`${entity}.lawnId`, e.target.value, true);
          }}
          isRequired={isRequiredInSchema(GreenPlantsSchema, `${entity}.fields.lawnId`)}
          error={ctx.touched.lawns?.lawnId && ctx.errors?.[entity]?.lawnId}
        />
      )}

      <Input isDisabled readOnly value={areaItem?.code ?? '-'} label="Код площадки" />
      <Select
        options={okrugs}
        label="Округ"
        value={district}
        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
          setDistrict(e.target.value);
          if (!e.target.value) {
            setRegion('');
            ctx.setFieldValue(`${entity}.areaId`, '');
            ctx.setFieldValue(`${entity}.address`, '');
            ctx.setFieldValue(`${entity}.territoryType`, '');
          }
        }}
        isDisabled
      />
      <Select
        options={filteredRayons}
        label="Район"
        value={region}
        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
          const district = filteredRayons.find((item) => item.value === e.target.value)?.parent || '';
          setRegion(e.target.value);
          setDistrict(district);

          if (!e.target.value) {
            ctx.setFieldValue(`${entity}.areaId`, '');
            ctx.setFieldValue(`${entity}.address`, '');
            ctx.setFieldValue(`${entity}.territoryType`, '');
          }
        }}
        isDisabled
      />
      <Input isDisabled readOnly value={areaItem?.address ?? '-'} label="Адрес" />
      <Input isDisabled readOnly value={areaItem?.landmark ?? '-'} label="Адресный ориентир" />
      <Input isDisabled readOnly value={areaItem?.dateStart ?? '-'} label="Год формирования площадки" />
      <Input isDisabled readOnly value={areaItem?.type ?? '-'} label="Тип территории насаждений" />
      <Input isDisabled readOnly value={areaItem?.location ?? '-'} label="Местоположение площадки" />
    </Stack>
  );
};

export { AreaForm };
