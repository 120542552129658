import * as yup from 'yup';
import { requiredError } from 'models/schemas/constans';

export const CalculationsSchema = yup.object().shape({
    operandId: yup
        .string()
        .nullable()
        .when('operationId', (operationId) =>
            (operationId !== 'delete' ? yup.string().required(requiredError).nullable() : yup.string().nullable())),
    operationId: yup.string().required(requiredError).nullable(),
    value: yup
        .string()
        .nullable()
        .when('operationId', (operationId) =>
            (operationId !== 'drift' && operationId !== 'delete' ? yup.string().required(requiredError) : yup.string())),
    firstCoefficient: yup
        .string()
        .nullable()
        .when('operationId', (operationId) =>
            (operationId === 'drift' ? yup.string().required(requiredError) : yup.string())),
    secondCoefficient: yup
        .string()
        .nullable()
        .when('operationId', (operationId) =>
            (operationId === 'drift' ? yup.string().required(requiredError) : yup.string())),
});

export const parametersCalculationSchema = yup.object().shape({
    parameterIds: yup.array()
        .when('operationId', (operationId) =>
        (
            operationId !== 'drift' && operationId !== 'delete' ?
                yup.array().of(yup.string()).min(1) :
                yup.array().of(yup.string()).nullable()
        )),
    parameterId: yup
        .string()
        .nullable()
        .when('operationId', (operationId) =>
            (operationId === 'drift' ? yup.string().required(requiredError) : yup.string())),
    operationId: yup.string().required(requiredError).nullable(),
    value: yup
        .string()
        .nullable()
        .when('operationId', (operationId) =>
            (operationId !== 'drift' && operationId !== 'delete' ? yup.string().required(requiredError) : yup.string())),
    firstCoefficient: yup
        .string()
        .nullable()
        .when('operationId', (operationId) =>
            (operationId === 'drift' ? yup.string().required(requiredError) : yup.string())),
    secondCoefficient: yup
        .string()
        .nullable()
        .when('operationId', (operationId) =>
            (operationId === 'drift' ? yup.string().required(requiredError) : yup.string())),
});
