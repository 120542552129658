import { ERROR_MESSAGES, SUCCESS_MESSAGES } from 'constants/messages';
import { createTouchedErrors } from 'features/create-touched-errors';
import { FormikProvider, useFormik } from 'formik';
import { useNotifications } from 'hooks/useNotifications';
import { useRequest } from 'hooks/useRequest';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { SnowSchema } from 'models/schemas/pgr';
import { SnowDeicingItem } from 'pages/data/PGR/Snow/useSnowDeicingList';
import { ReagentsRootPath } from '../../consts';
import FieldsForm from './FieldsForm';
import { useCallback, useState } from 'react';
import { updateFiles } from 'api/services/media';
import { IUploadMedia } from 'components/common/FileUploadDragAndDrop/types';
import { useObjectType } from 'hooks/useObjectType';
import { ApiError } from 'models';
import { formatErrorMessage } from 'lib/utils/formatErrorMessage';
import { splitTypesAndValues } from 'lib/utils/forms/valueTypeHelper';
import { useGoBackWithFallback } from 'lib/utils/goBackWithFallback';
import { useDictionary } from 'hooks/useDictionary';

export const initialEmptyValues = {
  address: '', // Адресс
  areaId: '', // Площадка id
  areaName: '', // Площадка - название
  date: moment()
    .format(),
  deleted: false,
  distance: '',
  districtId: null, // Округ
  parameters: [],
  pointId: '',
  pointName: '',
  distanceId: '',
  protocolNumber: '',
  regionId: null, // Регион
  registryId: '',
  territoryType: '',
  stageId: '',
  seasonId: '',
};

export const AddDeicingSnowReport = () => {
  const listPath = `${ReagentsRootPath}/snow`;
  const editPath = `${ReagentsRootPath}/snow/edit/row`;
  const { backWithFallback } = useGoBackWithFallback();
  const {
    isDictionariesLoading,
    dictionaries,
  } = useDictionary({ url: '/deicing/v10/deicing/snow/dictionaries' });

  const history = useHistory();
  const {
    successNotify,
    errorNotify,
  } = useNotifications();
  const [media, setMedia] = useState<IUploadMedia[]>([]);
  const objectType = useObjectType(dictionaries);

  const onError = useCallback((message?: ApiError | string | null) => {
    errorNotify({
      key: formatErrorMessage(message) || 'file/add/error',
      message: formatErrorMessage(message) || ERROR_MESSAGES.CREATE,
    });
  }, [errorNotify]);

  const onSuccess = useCallback((response: SnowDeicingItem | null) => {
    if (response) {
      successNotify({
        key: 'file/add/success',
        message: SUCCESS_MESSAGES.CREATE,
      });

      updateFiles(response.registryId, objectType, media)
        .then(() => {
          backWithFallback(`${listPath}/main-data`);
        })
        .catch((error: Error) => {
          onError(error.message);
          history.replace(`${editPath}/${response.registryId}?openAttachmentsTab=true`, response);
        });
    } else {
      onError();
    }
  }, [backWithFallback, editPath, history, listPath, media, objectType, onError, successNotify]);

  const { post, isLoading } = useRequest<SnowDeicingItem, any>('/deicing/v10/deicing/snow', onSuccess, onError);

  const onSubmit = useCallback((values: typeof initialEmptyValues) => {
    post({
      ...values,
      parameters: splitTypesAndValues(values.parameters),
    });
  }, [post]);

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    initialValues: initialEmptyValues,
    onSubmit,
    validationSchema: SnowSchema,
  });

  return (
    <>
      <FormikProvider
        value={{
          ...formik,
          errors: createTouchedErrors(formik.errors, formik.touched),
        }}
      >
        <FieldsForm
          dictionaries={dictionaries}
          onCancel={() => {
            backWithFallback(`${ReagentsRootPath}/snow/main-data`);
          }}
          isLoading={isDictionariesLoading || isLoading}
          onMediaChange={setMedia}
          media={media}
        />
      </FormikProvider>
    </>
  );
};
