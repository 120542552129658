import { useCallback } from 'react';
import { COMMON_LOCALE } from 'constants/common';
import { Button, DateInput } from 'components/common';
import { Field, useFormikContext } from 'formik';
import { RemoveDeviceFromStation } from 'pages/equipment/Stations/types';
import { devicesLocale } from '../../locale';
import { createUTCTime } from 'lib/create-date';
import { Flex } from '@chakra-ui/react';

interface FormFieldsProps {
  onCancel: () => void;
  submitIsDisabled: boolean;
}

export const FormFields = ({ onCancel, submitIsDisabled }: FormFieldsProps) => {
  const { handleSubmit, errors, setFieldValue } = useFormikContext<RemoveDeviceFromStation>();
  const onSubmit = useCallback(() => {
    handleSubmit();
  }, [handleSubmit]);

  return (
    <>
      <Field
        as={DateInput}
        showTimeInput
        name="dateEnd"
        label={devicesLocale.dateEnd}
        error={errors.dateEnd}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setFieldValue('dateEnd', createUTCTime(e.target.value));
        }}
      />
      <Flex marginTop={5}>
        <Button onClick={onSubmit} isDisabled={submitIsDisabled}>{COMMON_LOCALE.apply}</Button>
        <Button onClick={onCancel} variant="ghost" marginRight={5}>
          {COMMON_LOCALE.cancel}
        </Button>
      </Flex>
    </>
  );
};
