import { get, reduce } from 'lodash';
import { TDictionaryItemDictionary } from 'models';

type TDictParametersMap<T extends TDictionaryItemDictionary<string | (Record<string, any> & string),
  string | (Record<string, any> & string)>[]> = Partial<Record<T[number]['name'], T[number]>>;

// форма рендерится по значениям этого объекта
// но имя теперь может менятся - а айди не должно
export const parameterToParameterIdMap: Record<any, any> = {
  'd25cdab9-25b5-4b23-b4cc-32c55599cb24': 'surroundingBuilding',
  '8a559f0d-78f0-43e9-ab69-ba0e5cd226ae': 'beautification',
  '00638a69-f1a0-4597-b3e5-6441aae6e95d': 'treeCount',
  'bf85cac7-db34-4430-868c-8be59e218898': 'shrubCount',
  '4e6b4df9-9dac-4428-978b-1bcb7dac944f': 'emergencyElementCount',
  '6b9432a2-5383-46ed-9329-1b10ab5ae10a': 'lawnType',
  '6b92e9a2-e0a2-4ada-858a-41b587b99389': 'area',
  '7597bb9f-cdc3-4053-a88b-740c8070536f': 'organization',
  'e6f357a4-5b32-44a1-8150-7a75b018ba0f': 'lawnState',
  '8874f313-6fdf-43e3-be44-c39caf497b94': 'lawnStateType',
  'ae04759d-4132-464f-9e94-e73e982fe9c3': 'hasFlowerGarden',
  'fa48c1f2-35a2-4121-ac93-9880a5c88a07': 'flowerGardenState',
  'b3b2f41d-59b4-44a3-bdc3-8694ba7cde61': 'Comment',
  'f8f73594-ab6b-47c8-904c-05a4783a9ee0': 'plantGroupType',
  '04312c2d-5a1f-4a96-a679-4ef26a989972': 'plantGroupLocation',
  'af8db386-1d78-446b-99e3-8c483d8acecd': 'plantGroupCount',
  'b43295e3-c92e-4bbd-9393-99e459239b95': 'plantAge',
  'a66a4faf-712c-4532-9b9f-4bc3a9e28b11': 'plantHeight',
  'a92d69db-7ee3-4fc2-b66a-e7c502b3055c': 'stemHeight',
  '4ed8704f-bc43-440e-b0c3-0058debeb00f': 'diameter',
  '1b54badb-c88b-4d78-9b8d-0c874282869f': 'averageDiameter',
  '89329ad6-8535-45b9-972b-496efd788be7': 'treeTrunkTiltAngle',
  '8249dd83-767c-4ace-a30a-8222b8b1950b': 'isFirstPlanted',
  '4e72c277-82ed-4f1a-b359-5ac888c70e56': 'foliagePercentage',
  '7869cc14-f601-4fd1-933d-34fbbce2cd25': 'detailedStateCategory',
  '36efd185-a34f-4cf9-b100-5d0abe321c6f': 'stateCategory',
  '12a3af22-47f1-427e-8f94-b6faf9e73dff': 'decorativeness',
  '6287efdf-f2d8-4678-9110-16ff03330372': 'diseases',
  'acb30c80-63cf-472b-b350-e1753ab17e3b': 'pests',
  'd85580df-79d1-47cf-982d-789ae6877a3d': 'entomophages',
  'fbfc193b-8166-4252-a1aa-37fef5cc0a58': 'emergency',
  '6df367fc-3871-4974-a134-217e333a18ea': 'otherDamage',
  'd14d4b1d-87d4-41b9-8cfa-4ddad3330d6c': 'other',
  'f05cb7bb-caf8-4cd1-b3b4-9d116304e8f8': 'recommendations',

  'e0fe28e4-01ea-42a5-b868-71158133df3e': 'cereals',
  'd0699986-89d3-4ab6-9a60-04f650300c13': 'howManyCereals',
  'f4b3187b-5660-4c21-aa6f-e5515fa0efac': 'countCereals',
  'd147eb7d-ab5e-4397-bcd6-55124b770bcb': 'weeds',
  'fb57ac30-a20a-431f-9ba9-5fc6ee7c2b74': 'howManyWeeds',
  '729faf35-eab3-4c54-bf7e-3922f95e82a3': 'countWeeds',
  '882c7c08-cb47-473f-9723-84fa93fc40dc': 'moss',
  '99d4efe8-8bb4-4a47-be8c-a6ffc9489f8c': 'howManyMosses',
  '0cb9de25-65a0-44be-b0b1-994f2c60ea94': 'countMosses',
  '2e7c8070-c79a-4702-8c6d-b5ab7cb6cbb5': 'projCover',
  '39e1a85f-ec49-4715-9e40-5e157c665428': 'baldPatches',
  'f82b2cc2-aee6-4b78-af93-6bf131f883a9': 'lawnColor',
  'afad49db-8e53-4ba3-bff2-e68030a880f3': 'squareWithYellowGrass',
  '6950e167-d369-490c-9a60-5ced9289acf4': 'denseGrass',
  '2f2775b5-7ce0-4815-9c7c-f8649eafa822': 'heightGrass',
  'fb0bacc9-faf8-4dc7-a374-7f90da4265b8': 'turfFelt',
  '52da32ce-2312-41dc-a4ee-4b78d65e49ad': 'diseases',
  '05fac208-7b75-426f-8e80-ebe2635d2283': 'howManyDiseases',
  '03477cb0-20da-48ec-a81d-5fd2702b2f57': 'pests',
  '9908ab9b-fecf-431d-951c-46b78acc2a15': 'howManyPests',
  '7467de23-0b13-44d2-ad3b-9466b40de465': 'otherDamage',
  '51bdccb8-e36c-4cb0-8eac-babbed149707': 'garbage',
  'c808e421-9cd8-463c-a9a6-2d739dbb5107': 'garbageScope',
  '61a563da-fb16-4d07-a959-492d7f551575': 'decorativeLawns',
  '0c2a204d-1872-42f9-ac33-638426dcc56b': 'microrelief',
  '3fd80da0-9f33-4003-be86-d79324971f53': 'recommendations'
};

const dictParametersToMap = <T extends TDictionaryItemDictionary<string | (Record<string, any> & string),
  string | (Record<string, any> & string)>[],
>(
  arr?: T,
  key: keyof T[number] = 'parameterId',
): Record<string, any> => // TODO: remove any
  reduce<T[number], TDictParametersMap<T>>(
    arr,
    (acc, current) => {
      if (current) {
        const k = get(current, key);

        if (k && typeof k === 'string') {
          return { ...acc, [parameterToParameterIdMap[k]]: current };
        }
      }

      return acc;
    },
    {},
  );

export type { TDictParametersMap };

export { dictParametersToMap };
