import { createTouchedErrors } from 'features/create-touched-errors';
import { FormikProvider, useFormik } from 'formik';
import { useRequest } from 'hooks/useRequest';
import { useParams } from 'react-router-dom';
import React, { useCallback, useEffect, useState } from 'react';
import { initialEmptyValues } from './initialEmptyValues';
import AnalyticsSoilDeicingFieldsForm from 'pages/analytics/forms/Soil/AnalyticsSoilDeicingFieldsForm';
import { IUploadMedia } from 'components/common/FileUploadDragAndDrop/types';
import { updateFiles } from 'api/services/media';
import { useObjectType } from 'hooks/useObjectType';
import { SoilDeicingItem } from 'pages/data/PGR/Soil/consts';
import { ReagentsSoilApiDictionaries, ReagentsSoilApiRoot } from 'pages/data/PGR';
import { AnalyticsPGRSoilSchema } from 'models/schemas/analytics/analyticsSoilSchema';
import { useEnhancedNotification } from 'hooks';
import { useGoBackWithFallback } from 'lib/utils/goBackWithFallback';
import { useDictionary } from 'hooks/useDictionary';

export const AnalyticsEditDeicingSoilReport = () => {
  const { id } = useParams<{ id: string }>();
  const { dictionaries, isDictionariesLoading } = useDictionary({ url: ReagentsSoilApiDictionaries });
  const { backWithFallback } = useGoBackWithFallback();
  const { onErrorEdit, onSuccessEdit } = useEnhancedNotification();
  const [media, setMedia] = useState<IUploadMedia[]>([]);
  const [editedObject, setEditObject] = useState<SoilDeicingItem>();
  const objectType = useObjectType(dictionaries);

  const onItemLoad = useCallback((response: SoilDeicingItem | null) => {
    if (response) {
      setEditObject({
        ...response,
        values: response.values.sort((a, b) => a.index - b.index),
      });
    }
  }, []);

  const { get, isLoading: isItemLoading } = useRequest<SoilDeicingItem>(
    `${ReagentsSoilApiRoot}/${id}?laboratory=true`,
    onItemLoad,
  );

  useEffect(() => {
    void get();
  }, [get]);

  const onSuccess = () => {
    onSuccessEdit();

    updateFiles(id, objectType, media)
      .then(() => {
        backWithFallback('/analytics');
      })
      .catch((error: Error) => {
        onErrorEdit(error.message);
      });
  };

  const { put } = useRequest(`${ReagentsSoilApiRoot}/protocol?laboratory=true`, onSuccess, onErrorEdit);

  const initialValues = {
    ...initialEmptyValues,
    ...editedObject,
  };

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    initialValues,
    onSubmit: (values) => put(values),
    validationSchema: AnalyticsPGRSoilSchema,
  });

  return (
    <FormikProvider
      value={{
        ...formik,
        errors: createTouchedErrors(formik.errors, formik.touched),
      }}
    >
      <AnalyticsSoilDeicingFieldsForm
        dictionaries={dictionaries}
        onCancel={() => {
          backWithFallback('/analytics');
        }}
        isLoading={isDictionariesLoading || isItemLoading}
        isEditMode
        uploadItemId={id}
        onMediaChange={setMedia}
        media={media}
      />
    </FormikProvider>
  );
};
