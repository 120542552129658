import React from 'react';
import { Route } from 'react-router-dom';
import { DangerProcess } from 'pages/data/GeoecologicalProcesses/DangerouslyGeologicalProcesses/DangerProcess';
import {
    AddDangerProcessReport,
} from 'pages/data/GeoecologicalProcesses/DangerouslyGeologicalProcesses/DangerProcess/Forms/AddDangerProcessReport';
import {
    EditDangerProcessReport,
} from 'pages/data/GeoecologicalProcesses/DangerouslyGeologicalProcesses/DangerProcess/Forms/EditDangerProcessReport';
import { Reper } from 'pages/data/GeoecologicalProcesses/DangerouslyGeologicalProcesses/Reper';
import {
    AddReperRecord,
} from 'pages/data/GeoecologicalProcesses/DangerouslyGeologicalProcesses/Reper/Forms/AddReperReport';
import {
    EditReperRecord,
} from 'pages/data/GeoecologicalProcesses/DangerouslyGeologicalProcesses/Reper/Forms/EditReperReport';
import { Inclinometer } from 'pages/data/GeoecologicalProcesses/DangerouslyGeologicalProcesses/Inclinometer';
import {
    AddInclinometerResult,
} from 'pages/data/GeoecologicalProcesses/DangerouslyGeologicalProcesses/Inclinometer/Forms/AddInclinometerResult';
import {
    EditInclinometerResult,
} from 'pages/data/GeoecologicalProcesses/DangerouslyGeologicalProcesses/Inclinometer/Forms/EditInclinometerReport';
import { GeoecologicalRootPath } from 'pages/data/GeoecologicalProcesses';
import { GeologicalProcessesList } from 'pages/data/GeoecologicalProcesses/GeologicalProcessesList';
import { Monitoring } from 'pages/data/GeoecologicalProcesses/GeologicalProcessesGroundwater/Monitoring';
import {
    AddMonitoringResult,
} from 'pages/data/GeoecologicalProcesses/GeologicalProcessesGroundwater/Monitoring/Forms/AddMonitoringResult';
import {
    EditMonitoringResult,
} from 'pages/data/GeoecologicalProcesses/GeologicalProcessesGroundwater/Monitoring/Forms/EditMonitoringResult';
import { Measuring } from 'pages/data/GeoecologicalProcesses/GeologicalProcessesGroundwater/Measuring';
import {
    AddMeasuringResult,
} from 'pages/data/GeoecologicalProcesses/GeologicalProcessesGroundwater/Measuring/Forms/AddMeasuringResult';
import {
    EditMeasuringResult,
} from 'pages/data/GeoecologicalProcesses/GeologicalProcessesGroundwater/Measuring/Forms/EditMeasuringResult';
import { Quality } from 'pages/data/GeoecologicalProcesses/GeologicalProcessesGroundwater/Quality';
import {
    AddQualityResult,
} from 'pages/data/GeoecologicalProcesses/GeologicalProcessesGroundwater/Quality/Forms/AddQualityResults';
import {
    EditQualityResult,
} from 'pages/data/GeoecologicalProcesses/GeologicalProcessesGroundwater/Quality/Forms/EditQualityResult';
import {
    ProtocolMonitoring,
} from 'pages/data/GeoecologicalProcesses/GeologicalProcessesGroundwater/Protocol/ProtocolMonitoring';
import {
    ProtocolMeasuring,
} from 'pages/data/GeoecologicalProcesses/GeologicalProcessesGroundwater/Protocol/ProtocolMeasuring';
import {
    ProtocolQuality,
} from 'pages/data/GeoecologicalProcesses/GeologicalProcessesGroundwater/Protocol/ProtocolQuality';
import { ProtocolReper } from 'pages/data/GeoecologicalProcesses/DangerouslyGeologicalProcesses/Protocol/Reper';
import {
    ProtocolDangerProcess,
} from 'pages/data/GeoecologicalProcesses/DangerouslyGeologicalProcesses/Protocol/DangerProcess';
import {
    ProtocolInclinometer,
} from 'pages/data/GeoecologicalProcesses/DangerouslyGeologicalProcesses/Protocol/Inclinometer';
import { WithDidMountResetUploadFile } from 'features/UploadedFileProvider/hocs';

// @ts-ignore
export const GeoecologicalProcesses: React.FC = () => (
  <>
    {/* DANGER PROCESS */}
    <Route path={`${GeoecologicalRootPath}/monitoring/danger/dangerprocess`} exact component={DangerProcess} />
    <Route
      path={`${GeoecologicalRootPath}/monitoring/danger/dangerprocess/add`}
      exact
      component={AddDangerProcessReport}
    />
    <Route
      path={`${GeoecologicalRootPath}/monitoring/danger/dangerprocess/edit/row/:id`}
      component={EditDangerProcessReport}
    />

    <Route
      path={`${GeoecologicalRootPath}/monitoring/protocol/danger/dangerprocess`}
      exact
      component={ProtocolDangerProcess}
    />

    {/* REPER */}
    <Route path={`${GeoecologicalRootPath}/monitoring/danger/reper`} exact component={Reper} />
    <Route path={`${GeoecologicalRootPath}/monitoring/danger/reper/add`} exact component={AddReperRecord} />
    <Route path={`${GeoecologicalRootPath}/monitoring/danger/reper/edit/row/:id`} component={EditReperRecord} />

    <Route path={`${GeoecologicalRootPath}/monitoring/protocol/danger/reper`} exact component={ProtocolReper} />

    {/*  INCLINOMETER */}
    <Route path={`${GeoecologicalRootPath}/monitoring/danger/inclinometer`} exact component={Inclinometer} />
    <Route
      path={`${GeoecologicalRootPath}/monitoring/danger/inclinometer/add`}
      exact
      component={AddInclinometerResult}
    />
    <Route
      path={`${GeoecologicalRootPath}/monitoring/danger/inclinometer/edit/row/:id`}
      component={EditInclinometerResult}
    />

    <Route path={`${GeoecologicalRootPath}`} exact component={GeologicalProcessesList} />

    <Route
      path={`${GeoecologicalRootPath}/monitoring/protocol/danger/inclinometer`}
      exact
      component={ProtocolInclinometer}
    />

    {/* GEOECOLOGY WATER MONITORING */}
    <Route
      path={`${GeoecologicalRootPath}/monitoring/water/examination`}
      exact
      render={() => <WithDidMountResetUploadFile Component={Monitoring} />}
    />

    <Route
      path={`${GeoecologicalRootPath}/monitoring/water/examination/add`}
      exact
      render={() => <WithDidMountResetUploadFile Component={AddMonitoringResult} />}
    />

    <Route
      path={`${GeoecologicalRootPath}/monitoring/water/examination/edit/row/:id`}
      component={EditMonitoringResult}
    />

    <Route
      path={`${GeoecologicalRootPath}/monitoring/protocol/water/examination`}
      exact
      component={ProtocolMonitoring}
    />

    {/* GEOECOLOGY WATER MEASURING */}

    <Route
      path={`${GeoecologicalRootPath}/monitoring/water/measuring`}
      exact
      render={() => <WithDidMountResetUploadFile Component={Measuring} />}
    />

    <Route
      path={`${GeoecologicalRootPath}/monitoring/water/measuring/add`}
      exact
      render={() => <WithDidMountResetUploadFile Component={AddMeasuringResult} />}
    />

    <Route
      path={`${GeoecologicalRootPath}/monitoring/water/measuring/edit/row/:id`}
      render={() => <WithDidMountResetUploadFile Component={EditMeasuringResult} />}
    />

    <Route
      path={`${GeoecologicalRootPath}/monitoring/water/measuring/load/preview/row/:id`}
      component={EditMonitoringResult}
    />

    <Route path={`${GeoecologicalRootPath}/monitoring/protocol/water/measuring`} exact component={ProtocolMeasuring} />

    {/* GEOECOLOGY QUALITY */}
    <Route
      path={`${GeoecologicalRootPath}/monitoring/water/quality`}
      exact
      render={() => <WithDidMountResetUploadFile Component={Quality} />}
    />

    <Route
      path={`${GeoecologicalRootPath}/monitoring/water/quality/add`}
      exact
      render={() => <WithDidMountResetUploadFile Component={AddQualityResult} />}
    />

    <Route
      path={`${GeoecologicalRootPath}/monitoring/water/quality/edit/row/:id`}
      render={() => <WithDidMountResetUploadFile Component={EditQualityResult} />}
    />

    <Route
      path={`${GeoecologicalRootPath}/monitoring/water/quality/load/preview/row/:id`}
      component={EditQualityResult}
    />

    <Route path={`${GeoecologicalRootPath}/monitoring/protocol/water/quality`} exact component={ProtocolQuality} />
  </>
);
