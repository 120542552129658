import { FormikErrors, FormikTouched } from 'formik';
import { reduce } from 'lodash';

export interface CreateTouchedErrors {
  <T extends { [key: string]: any; [key: number]: any }>(
    formikErrors: FormikErrors<T>,
    formikTouched: FormikTouched<T>,
    serverErrors?: FormikErrors<T>
  ): FormikErrors<T>;
}

export const createTouchedErrors: CreateTouchedErrors = (formikErrors, formikTouched, serverErrors = {}) =>
  reduce(
    Object.entries(formikErrors),
    (acc, [key, value]) => {
      if (serverErrors[key]) {
        return {
          ...acc,
          [key]: formikTouched[key] && serverErrors[key],
        };
      }

      return {
        ...acc,
        [key]: formikTouched[key] && value,
      };
    },
    formikErrors
  );
