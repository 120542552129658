import { TFilterOperations, TFiltersArray } from 'models';
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { Box } from '@chakra-ui/react';
import OperandSelector from 'components/table/filters/components/OperandSelector';
import { Button, Input } from 'components/common';

interface Props<Keys> {
  onSubmit: (operand: TFilterOperations, firstValue: string, secondValue?: string) => void;
  filter?: TFiltersArray<Keys>;
  onClear: () => void;
}

function TableNumberFilter<Keys = string>({ onSubmit, onClear, filter }: Props<Keys>) {
  const [operand, setOperand] = useState<TFilterOperations>();
  const [value, setValue] = useState('');
  const [secondValue, setSecondValue] = useState('');

  const isDoubleValue = useCallback((operand) => operand === 'BETWEEN' || operand === 'NOT_BETWEEN', []);

  useEffect(() => {
    if (filter && filter[0]) {
      setOperand(filter[0].operation as TFilterOperations);

      if (isDoubleValue(filter[0].operation) && Array.isArray(filter[0].value)) {
        const values = filter[0].value.map((val) => parseFloat(String(val)))
          .sort((a, b) => (a > b ? 1 : -1));
        setValue(String(values[0]));
        setSecondValue(String(values[1]));
      } else {
        setValue(String(filter[0].value));
      }
    }
  }, [filter, isDoubleValue]);

  const onSubmitClick = useCallback(() => {
    if (operand && isDoubleValue(operand)) {
      const values = [value, secondValue].sort();
      onSubmit(operand, values[0], values[1]);
      return;
    }

    if (operand && value) {
      onSubmit(operand, value);
    }
  }, [isDoubleValue, onSubmit, operand, secondValue, value]);

  const onOperandChange = useCallback(
    (operand: TFilterOperations) => {
      setOperand(operand);
      if (!isDoubleValue(operand)) {
        setSecondValue('');
      }
    },
    [isDoubleValue],
  );

  return (
    <Box>
      <Box display="flex">
        <OperandSelector
          options={['EQ', 'GT', 'GTE', 'LT', 'LTE', 'BETWEEN', 'NOT_BETWEEN']}
          selectedOperand={operand}
          onChange={onOperandChange}
        />
        <Input
          isClearable={false}
          marginLeft={4}
          onChange={(event: ChangeEvent<HTMLInputElement>) => setValue(event.target.value)}
          value={value}
          type="number"
        />
        <Input
          isClearable={false}
          marginLeft={4}
          onChange={(event: ChangeEvent<HTMLInputElement>) => setSecondValue(event.target.value)}
          value={secondValue}
          type="number"
          disabled={!isDoubleValue(operand)}
        />
      </Box>

      <Box display="flex" width="100%" marginTop={4}>
        <Button onClick={onClear} marginRight="auto">
          Сбросить
        </Button>

        <Button
          disabled={!operand || !value || (operand === 'BETWEEN' && (!value || !secondValue))}
          onClick={onSubmitClick}
          variant="solid"
        >
          Применить
        </Button>
      </Box>
    </Box>
  );
}

export default TableNumberFilter;
