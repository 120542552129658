import React, { useMemo } from 'react';
import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import { useList } from 'hooks/useList';
import appLocale from 'constants/appLocale';
import { GeoecologicalRootPath } from 'pages/data/GeoecologicalProcesses/consts';
import { ValueItem } from 'pages/data/GeoecologicalProcesses/DangerouslyGeologicalProcesses/Protocol/types';
import {
  DangerouslyGeologicalProcessesProtocolHeader,
} from 'pages/data/GeoecologicalProcesses/DangerouslyGeologicalProcesses/Protocol/components/DangerouslyGeologicalProcessesProtocolHeader';
import ProtocolTable
  from 'pages/data/GeoecologicalProcesses/DangerouslyGeologicalProcesses/Protocol/components/ProtocolTable';
import {
  InclinometerFilter,
} from 'pages/data/GeoecologicalProcesses/DangerouslyGeologicalProcesses/Protocol/Inclinometer/InclinometerFilter/Filter';
import { ColumnWithFilter } from 'lib/utils/tableFilter';
import { createLocalDateDDMMYYYY } from 'lib/create-date';
import { TableFilterTypeEnum } from 'components/table/filters/TableFilter';
import subsystemIdsMap from 'constants/subsystemIdsMap';
import { useAvailability } from 'hooks';
import { usePeriodsDict } from 'features/PeriodsButtons/usePeriodsDict';
import { useDictionary } from 'hooks/useDictionary';
import { restorePreviewColumns } from 'components/common/Modals/ProtocolModal';
import { FullPageWrapper } from '../../../../../../components/table/FullPageWrapper';
import { DEFAULT_PAGE_SIZE_NEW_TABLE } from '../../../../../../constants/tables';

export const ProtocolInclinometer = () => {
  const { isExecute, isNoRights, isAvailable } = useAvailability(subsystemIdsMap.geoecologyRoot);

  useUpdateBreadcrumbs([
    { breadcrumb: appLocale.common.data, path: '/data' },
    {
      breadcrumb: appLocale.subsystems.geoecologicalprocesses,
      path: GeoecologicalRootPath,
    },
    {
      breadcrumb: `${appLocale.common.dataProtocol} (ОГП)`,
      path: `${GeoecologicalRootPath}/monitoring/protocol/danger/inclinometer`,
    },
  ]);

  const { dictionaries } = useDictionary({ url: '/geoecology/v10/geoecology/inclinometer/dictionaries' });

  const {
    refetch,
    isLoading: isLoadingReperList,
    response,
    setFilters,
    toggleOrder,
    onPageChange,
    filterDTO,
    downloadAsFile,
    saveFilters,
    errorText,
    materialTableProps,
  } = useList<ValueItem>('/geoecology/v10/geoecology/inclinometer/list/values', {
    initialOrderFieldKey: '-valueDate',
    preventFirstFetch: true,
    defaultPageSize: DEFAULT_PAGE_SIZE_NEW_TABLE,
    resetDataOnNextRequest: true,
  });

  const columns = useMemo<Array<ColumnWithFilter<ValueItem>>>(
    () => [
      {
        title: 'Дата',
        field: 'valueDate',
        render: (row) => createLocalDateDDMMYYYY(row.valueDate),
      },
      {
        title: 'Участок',
        field: 'areaCode',
        filterType: TableFilterTypeEnum.number,
      },
      {
        title: 'Точка наблюдения',
        field: 'pointCode',
        filterType: TableFilterTypeEnum.number,
      },
      {
        title: 'Глубина пробы',
        field: 'probeHeightDepth',
        filterType: TableFilterTypeEnum.number,
      },
      {
        title: 'Параметр',
        field: 'parameterName',
        filterType: TableFilterTypeEnum.string,
        cellStyle: {
          width: '210px',
        },
      },
      {
        title: 'Значение',
        field: 'value',
        filterType: TableFilterTypeEnum.string,
        cellStyle: {
          width: '210px',
        },
      },
    ],
    []
  );

  const { result: periods } = usePeriodsDict(`auth/v10/user/period/${subsystemIdsMap.dangerprocesses}`);

  return (
    <FullPageWrapper>
      <DangerouslyGeologicalProcessesProtocolHeader active="inclinometer">
        <InclinometerFilter
          filters={filterDTO?.filters || []}
          periods={periods}
          isLoading={isLoadingReperList}
          isDisabled={isLoadingReperList}
          dictionaries={dictionaries}
          onSubmit={(values) => {
            setFilters(Object.values(values));
          }}
        />
      </DangerouslyGeologicalProcessesProtocolHeader>

      <ProtocolTable
        saveFilters={saveFilters}
        isAvailable={isAvailable}
        isExecute={isExecute || isNoRights}
        filters={filterDTO.filters}
        setFilters={setFilters}
        refetch={refetch}
        list={response}
        toggleOrder={toggleOrder}
        onPageChange={onPageChange}
        filterDTO={filterDTO}
        downloadAsFile={downloadAsFile}
        columns={columns}
        isLoading={isLoadingReperList}
        errorText={errorText}
        baseUrl="/geoecology/v10/geoecology/inclinometer"
        previewColumns={restorePreviewColumns.geoDangerouseProcessesInclinometers}
        materialTableProps={materialTableProps}
      />
    </FullPageWrapper>
  );
};
