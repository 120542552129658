import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import { Tab, TabList, Tabs, useDisclosure } from '@chakra-ui/react';
import { SubsystemHeader } from 'components/common';
import React, { useMemo } from 'react';
import { MaterialTable } from 'features/material-table/MaterialTableMini';
import { useList, useRequest } from 'hooks';
import { TableViewFilter } from './TableViewFilter';
import {
  atmoTerritoryColumns,
  dotAtmoColumns,
  dotNoiseColumns,
  lettersColumns,
  noiseTerritoryColumns,
  PelGeneralUrls,
} from './consts/consts';
import { Column } from '@material-table/core';
import { addFiltersToColumns } from 'lib/utils/tableFilter';
import { IResponse } from './types/types';
import { TABLE_VIEW } from '../../citizenAppeal';
import { useHistory, useParams } from 'react-router-dom';
import { ITask } from '../../citizenAppeal/RaidJournal/types';
import { ViewIcon2 } from 'components/icons';
import { PEL_ROOT_PATH, PELTableView, tableViewToReportPath } from '../constants';
import { SendToMailModal } from 'components/common/GraphicDropdownMenu/SendToMailModal';
import { subsystemCodesMap } from 'constants/subsystemIdsMap';
import { useCustomRights } from 'hooks/useCustomRights';
import { useDictionary } from 'hooks/useDictionary';
import { initialValues } from 'pages/pel/PelRaidsTableView/TableViewFilter/consts';
import { DEFAULT_PAGE_SIZE_NEW_TABLE } from '../../../constants/tables';
import { FullPageWrapper } from '../../../components/table/FullPageWrapper';

export const PelRaidsTableView = () => {
  const {
    isRead: isNoiseRead,
    isWrite: isNoiseWrite,
    isExecute: isNoiseExecute,
  } = useCustomRights(subsystemCodesMap.PEL.noiseReport);
  const {
    isRead: isAtmoRead,
    isWrite: isAtmoWrite,
    isExecute: isAtmoExecute,
  } = useCustomRights(subsystemCodesMap.PEL.atmoReport);
  const { type } = useParams<{ type: string }>();
  const initialTab = useMemo(() => (isNoiseRead ? 0 : 1), [isNoiseRead]);
  const [typeIndex, setTypeIndex] = React.useState(type ? +type : initialTab);
  const [subTypeIndex, setSubTypeIndex] = React.useState(0);

  useUpdateBreadcrumbs([
    {
      breadcrumb: 'Рейды ПЭЛ',
      path: PEL_ROOT_PATH,
    },
    {
      breadcrumb: TABLE_VIEW,
      path: `${PELTableView}/${typeIndex}`,
    },
  ]);

  const onTypeChange = (index: number) => {
    history.replace({
      pathname: `${PELTableView}/${index}`,
      search: '',
    });
    setTypeIndex(index);
    onSubmitFilters(Object.values(initialValues));
  };

  const onSubTypeChange = (index: number) => {
    history.replace({
      search: '',
    });
    setSubTypeIndex(index);
    onSubmitFilters(Object.values(initialValues));
  };

  const {
    onPageChange,
    filterDTO,
    toggleOrder,
    setFilters,
    response,
    isLoading,
    downloadAsFile,
    getHeaders,
    headers,
    resetHeaders,
    errorText,
    onSearchText,
    onSubmitFilters,
    materialTableProps,
  } = useList(`appeal/v10/raid-type/${PelGeneralUrls[typeIndex][subTypeIndex]}`, {
    saveFiltersState: true,
    defaultPageSize: DEFAULT_PAGE_SIZE_NEW_TABLE,
    resetDataOnNextRequest: true,
  });

  const { isDictionariesLoading: isReportsDictLoading, dictionaries: raidsDictionaries } = useDictionary({
    url: '/appeal/v10/reports/dictionaries',
  });

  const isDictLoading = useMemo(
    () => isReportsDictLoading,
    [isReportsDictLoading],
  );

  const columnMaker = (typeIndex: number, subTypeIndex: number) => {
    if (subTypeIndex === 2) {
      return lettersColumns;
    }
    if (subTypeIndex === 1) {
      if (typeIndex) {
        return dotAtmoColumns;
      }
      return dotNoiseColumns;
    }
    if (typeIndex) {
      return atmoTerritoryColumns;
    }
    return noiseTerritoryColumns;
  };

  const columns = useMemo(() => columnMaker(typeIndex, subTypeIndex), [typeIndex, subTypeIndex]);

  const columnsWithFilters = useMemo<Array<Column<IResponse>>>(
    () => addFiltersToColumns({ filters: filterDTO.filters, setFilters, columns }),
    [filterDTO.filters, setFilters, columns],
  );

  const history = useHistory();

  const toEdit = (task: ITask | null) => {
    if (task) {
      history.push(tableViewToReportPath, { ...task, tabIndex: subTypeIndex === 2 ? 3 : 2 });
    }
  };

  const { get } = useRequest('', toEdit);

  const { isOpen: isOpenUploadModal, onOpen: onOpenUploadModal, onClose: onCloseUploadModal } = useDisclosure();

  return (
    <FullPageWrapper>
      {!isAtmoRead && !isNoiseRead ? null : (
        <>
          <SubsystemHeader
            title={TABLE_VIEW}
            filtersComponent={
              <TableViewFilter
                onSubmit={(values) => onSubmitFilters(Object.values(values))}
                raidsDictionaries={raidsDictionaries}
                subType={subTypeIndex}
                type={typeIndex}
                filters={filterDTO.filters}
                isLoading={isDictLoading}
              />
            }
            navigationTabs={
              <>
                <Tabs margin={0} index={typeIndex} onChange={onTypeChange}>
                  <TabList>
                    <Tab isDisabled={!isNoiseRead}>Сводный отчет по исследованным территориям (шум)</Tab>
                    <Tab isDisabled={!isAtmoRead}>Сводный отчет по исследованным территориям (воздух)</Tab>
                  </TabList>
                </Tabs>
                <Tabs mx={2} my={2} index={subTypeIndex} onChange={onSubTypeChange}>
                  <TabList>
                    <Tab>Исследованные территории</Tab>
                    <Tab>Точки наблюдения</Tab>
                    <Tab>Письма</Tab>
                  </TabList>
                </Tabs>
              </>
            }
          />
          {(typeIndex === 0 && !isNoiseRead) || (typeIndex === 1 && !isAtmoRead) ? null : (
            <MaterialTable
              isNotExecute={typeIndex ? isAtmoExecute : isNoiseExecute}
              options={{
                search: true,
              }}
              data={response?.data}
              meta={response?.meta}
              toggleOrder={toggleOrder}
              onPageChange={onPageChange}
              filterDTO={filterDTO}
              columns={columnsWithFilters}
              isLoading={isLoading}
              downloadAsFile={downloadAsFile}
              getHeaders={getHeaders}
              onOpenUploadModal={onOpenUploadModal}
              actions={[
                {
                  position: 'row',
                  action: (row) => ({
                    icon: () => <ViewIcon2 />,
                    onClick: () => get({ url: `appeal/v10/tasks/${row.taskId}` }),
                    tooltip: 'Перейти к отчету',
                    hidden: !(typeIndex ? isAtmoWrite : isNoiseWrite),
                  }),
                },
              ]}
              errorText={errorText}
              onSearch={onSearchText}
              {...materialTableProps}
            />
          )}
          {headers ? (
            <SendToMailModal
              onClose={onCloseUploadModal}
              headers={headers}
              isOpen={isOpenUploadModal}
              resetHeaders={resetHeaders}
              filename={TABLE_VIEW}
            />
          ) : null}
        </>
      )}
    </FullPageWrapper>
  );
};
