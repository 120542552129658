import { FormWrapper, Input } from 'components/common';
import { Box, Flex, Grid, Heading } from '@chakra-ui/react';
import React, { useCallback, useMemo } from 'react';
import { useFormField } from 'hooks/forms/useFormField';
import { ISOKJournalItem } from 'pages/PDKExcessJournal/types';
import { PdkJournalSchema } from 'models/schemas/pdk/pdkJournalSchema';
import appLocale from 'constants/appLocale';
import { TDictionariesArray } from 'models';
import { Field, useFormikContext } from 'formik';
import { getSelectOptionsFromDictionaryByName } from 'features/get-select-options-from-dictionary';

interface IProps {
  dictionaries: {
    data: TDictionariesArray;
  } | null;
  isEditMode?: boolean;
}

export const ExcessTab = ({
  dictionaries,
  children,
  isEditMode,
}: React.PropsWithChildren<IProps>) => {
  const { values } = useFormikContext<ISOKJournalItem>();

  const parameters = useMemo(
    () => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'parameters'),
    [dictionaries],
  );
  const extparameters = useMemo(
    () => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'extparameters'),
    [dictionaries],
  );

  const { renderField } = useFormField<ISOKJournalItem>({
    schema: PdkJournalSchema,
    labelLocalization: {
      ...appLocale.pdkExcessJournal.fields,
      pdkH2s: 'Значение',
      pdkO3: 'Значение',
      pdkNO2: 'Значение',
      pdkNO: 'Значение',
      pdkSO2: 'Значение',
      pdkCO: 'Значение',
      pdkPM10: 'Значение',
      pdkPM25: 'Значение',
      pdkNH3: 'Значение',
      pdkOther: 'Значение',
    },
  });

  const renderParameterFields = useCallback((id: string) => {
    const parameter = parameters.find((param: any) => param.value === id);
    if (!parameter) return null;
    return (
      <>
        <Field
          as={Input}
          label="Единица измерения"
          value={parameter.unit}
          isDisabled
        />
        <Box
          width="150px"
        >
          <Field
            as={Input}
            label="ПДК"
            value={parameter.maxPdk}
            isDisabled
            width="100px"
          />
        </Box>
      </>
    );
  }, [parameters]);

  const renderParameter = useCallback((name: string, parameterName: keyof ISOKJournalItem, id: string) => (
    <>
      <Heading size="md">{name}</Heading>
      <Flex gap={2}>
        {renderField(
          { name: parameterName, type: 'numberInput', isDisabled: (!!isEditMode && values.type !== 'MANUAL') },
        )}
        {renderParameterFields(id)}
      </Flex>
    </>
  ), [isEditMode, renderField, renderParameterFields, values.type]);

  return (
    <FormWrapper>
      <Grid marginTop="30px" gap="15px">
        {renderParameter('H2S', 'pdkH2s', '48164673-4b33-420b-9b41-88abde2ed531')}
        {renderParameter('O3', 'pdkO3', '4fcfa653-2f7d-429e-be2f-d5f68ccc03f2')}
        {renderParameter('N02', 'pdkNO2', 'c34d1a1a-db67-4f9f-bee3-fb529f8deec4')}
        {renderParameter('NO', 'pdkNO', '3a1d940c-389e-4f46-a3a7-ca977f7ceb61')}
        {renderParameter('SO2', 'pdkSO2', 'ecc25730-3e84-4145-98a4-fde3c4e5f1a1')}
        {renderParameter('CO', 'pdkCO', 'b9cd50fa-f28c-467d-a984-c9ee2a7d5d60')}
        {renderParameter('PM10', 'pdkPM10', '3a6c939a-2c60-4982-93e5-a77168199645')}
        {renderParameter('PM2.5', 'pdkPM25', '2eed9d24-3bfd-490c-a366-695f367fd5b4')}
        {renderParameter('NH3', 'pdkNH3', '8cb45cf0-2492-4dd5-8c90-940e69564478')}

        <Heading size="md">Прочее</Heading>
        <Flex gap={2}>
          {renderField(
            { name: 'pdkOther', type: 'numberInput', isDisabled: (!!isEditMode && values.type !== 'MANUAL') },
          )}
          {renderField(
            {
              name: 'additionParamId',
              type: 'select',
              options: extparameters,
              isDisabled: (!!isEditMode && values.type !== 'MANUAL'),
            },
          )}

          <Box
            width="150px"
          >
            <Field
              as={Input}
              label="ПДК"
              value={extparameters?.find((p) => p.value === values.additionParamId)?.maxPdk}
              isDisabled
            />
          </Box>
        </Flex>

        {children}
      </Grid>
    </FormWrapper>
  );
};
