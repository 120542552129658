import React, { useMemo } from 'react';
import { Field, FieldArray, useFormikContext } from 'formik';
import { Box, Flex, Grid, GridItem } from '@chakra-ui/react';
import { Button, Checkbox, DefaultText, Input, Select } from 'components/common';
import { TDictionariesArray } from 'models/dictionaries';
import { AnswerValues, Appeals, AppealValues, Complaint } from 'pages/citizenAppeal';
import { getSelectOptionsFromDictionaryByName } from 'features/get-select-options-from-dictionary';
import { AnswersForm } from './AnswersForm';
import { AddIcon, DeleteIcon } from 'components/icons';
import { IUploadMedia } from 'components/common/FileUploadDragAndDrop/types';
import { useGoBackWithFallback } from 'lib/utils/goBackWithFallback';

interface Props {
  dictionaries?: TDictionariesArray;
  appealValues: AppealValues;
  incident: Complaint | null;
  complaint: Complaint | null;
  media: IUploadMedia[][];
  setMedia: (mediaItem: IUploadMedia[][]) => void;
  isWrite?: boolean;
}

export const FieldsForm = ({
  dictionaries,
  appealValues,
  incident,
  complaint,
  setMedia,
  media,
  isWrite = true,
}: Props) => {
  const { backWithFallback } = useGoBackWithFallback();

  const { setFieldValue, values, handleSubmit, errors, initialValues } = useFormikContext<AnswerValues>();

  const assignmentStatuses = useMemo(
    () =>
      getSelectOptionsFromDictionaryByName(dictionaries, 'assignmentStatuses', false, initialValues.complaintStatusId),
    [dictionaries, initialValues.complaintStatusId],
  );
  const assignmentKinds = useMemo(
    () =>
      getSelectOptionsFromDictionaryByName(
        dictionaries,
        'assignmentKinds',
        false,
        initialValues.answers.map((item) => item.answerAssignmentKind),
      ),
    [dictionaries, initialValues.answers],
  );

  const onMediaChange = (item: IUploadMedia[], idx: number) => {
    const allMedia = [...media];
    allMedia[idx] = item;
    setMedia(allMedia);
    handleSubmit();
  };

  return (
    <Box marginRight={4} width="80%">
      <DefaultText marginLeft={2} marginBottom={2} fontWeight="bold" fontSize="18px">
        Исполнитель
      </DefaultText>
      <Grid
        marginLeft={3}
        marginBottom={5}
        marginTop={5}
        gap={3}
        templateRows="repeat(1, 1fr)"
        templateColumns="repeat(4, 1fr)"
      >
        <GridItem colStart={1} colEnd={3} rowStart={1} rowEnd={1}>
          <Field
            as={Input}
            label="ФИО Исполнителя"
            value={incident?.assignmentExecutor}
            name="assignmentExecutorId"
            disabled
          />
        </GridItem>
        <GridItem colStart={3} colSpan={1} rowStart={1} rowEnd={1}>
          <Field
            as={Select}
            label="Статус"
            options={assignmentStatuses}
            name="complaintStatusId"
            isDisabled={!isWrite}
          />
        </GridItem>
      </Grid>
      <Grid
        marginLeft={3}
        marginBottom={3}
        marginTop={5}
        gap={3}
        templateRows="repeat(1, 1fr)"
        templateColumns="repeat(4, 1fr)"
      >
        <GridItem rowStart={1} rowEnd={1} colStart={1} colEnd={2}>
          <Field
            as={Checkbox}
            checked={incident?.complaintAnswerOnPaper}
            isDisabled
            label="Ответ на бумаге"
            name="answerOnPaper"
          />
        </GridItem>
      </Grid>
      <DefaultText marginLeft={2} marginBottom={2} fontWeight="bold" fontSize="18px">
        Подготовка ответа
      </DefaultText>
      <Grid marginBottom={4} gap={3} templateRows="repeat(1, 1fr)" templateColumns="repeat(4, 1fr)">
        {/* <GridItem rowStart={1} rowEnd={1} colStart={1} colSpan={1}>
          <Button>Обновить реквизиты ответа из МЭДО</Button>
        </GridItem> */}
        <GridItem rowStart={1} rowEnd={1} colStart={1} colSpan={1}>
          <Field
            as={Checkbox}
            checked={values.complaintAnswered}
            name="complaintAnswered"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFieldValue('complaintAnswered', e.target.checked)}
            label="Ответ направлен"
            isDisabled={!isWrite}
          />
        </GridItem>
      </Grid>
      <FieldArray name="answers">
        {({ push, remove }) => (
          <Box flexDirection="column" width="100%">
            {values.answers &&
              values.answers.map((answer, idx) => (
                <>
                  <Flex>
                    <DefaultText marginLeft={2} marginBottom={2} fontWeight="bold" fontSize="18px">
                      {`Ответ ${idx + 1}`}
                    </DefaultText>
                    <Button leftIcon={<DeleteIcon />} onClick={() => remove(idx)} variant="ghost" size="sm" isDisabled={!isWrite} />
                  </Flex>
                  <AnswersForm
                    onMediaChange={(item) => onMediaChange(item, idx)}
                    media={media[idx]}
                    errors={errors}
                    uploadItemId={answer.answerId}
                    complaint={complaint}
                    appealValues={appealValues}
                    assignmentKinds={assignmentKinds}
                    values={values}
                    incident={incident}
                    setFieldValue={setFieldValue}
                    index={idx}
                    isWrite={isWrite}
                  />
                </>
              ))}
            <Button
              leftIcon={<AddIcon />}
              color="PrimaryButton.500"
              onClick={() =>
                push({
                  answerAddress: '',
                  answerAgreementDate: '',
                  answerAgreementSet: '',
                  answerAssignmentKind: '',
                  answerDocDate: '',
                  answerDocNumber: '',
                  answerId: null,
                  answerStoreId: '',
                  answerText: '',
                  answerUrl: '',
                })
              }
              variant="ghost"
              isDisabled={!isWrite}
            >
              Добавить ответ
            </Button>
            <Grid marginTop={5} gap={3} templateRows="repeat(1, 1fr)" templateColumns="repeat(4, 1fr)">
              <GridItem rowStart={1} rowEnd={1} colStart={1} colSpan={2}>
                <Flex>
                  <Button marginRight={3} onClick={() => backWithFallback(Appeals)}>
                    Отмена
                  </Button>
                  <Button onClick={() => handleSubmit()} isDisabled={!isWrite}>Сохранить</Button>
                </Flex>
              </GridItem>
            </Grid>
          </Box>
        )}
      </FieldArray>
    </Box>
  );
};
