import { Field, FormikProvider, useFormik } from 'formik';
import React, { useEffect, useMemo, useState } from 'react';
import { DateInput, Select } from 'components/common';
import { Flex, Grid } from '@chakra-ui/react';
import { createDateFromResponse, createDateToRequestForFilter, createDateToRequestWithTimezone } from 'lib/create-date';
import { TDictionariesArray } from 'models/dictionaries';
import {
  getSelectOptionsFromDictionaryByName,
  getSelectOptionsFromDictionaryByNameWithParent,
} from 'features/get-select-options-from-dictionary';
import { IPeriod } from 'features/PeriodsButtons/types';
import { PeriodsButtons } from 'features/PeriodsButtons';
import { usePeriods } from 'features/PeriodsButtons/usePeriods';
import combineFilters from 'lib/utils/combineFilters';
import { IFilterItem, TFiltersArray } from 'models';
import { MomentInput } from 'moment';
import { FiltersDatepickersSchema } from 'models/schemas/filtersDatepickersSchema/FiltersDatepickersSchema';
import FilterFormControls from 'components/form/FilterFormControls';

interface FilterProps {
  isLoading?: boolean;
  isDisabled?: boolean;
  dictionaries: {
    data: TDictionariesArray;
  } | null;
  onSubmit: (values: any) => void;
  children: React.ReactNode;
  periods: IPeriod[] | null;
  filters?: TFiltersArray;
}

const initialEmptyValues: Record<string, IFilterItem<string>> = {
  measuringDateMin: {
    key: 'measuringDate',
    operation: 'GTE',
    value: null,
  },
  measuringDateMax: {
    key: 'measuringDate',
    operation: 'LTE',
    value: null,
  },
  sourceId: {
    key: 'sourceId',
    operation: 'EQ',
    value: null,
  },
  waterSourceType: {
    key: 'waterSourceType',
    operation: 'EQ',
    value: null,
  },
  // showDisabled - value равно '' если чекбокс включен и нужно получить с Backend все значения (этот объект удалиться при отправке)
  // value равно false - если нужно показать только неудаленные записи
  showDisabled: {
    key: 'deleted',
    operation: 'EQ',
    value: false,
  },
  periodId: {
    key: 'periodId',
    operation: 'EQ',
    value: null,
  },
};

export const QualityFilter = ({
  isLoading,
  isDisabled,
  onSubmit,
  dictionaries,
  children,
  periods,
  filters = [],
  ...props
}: FilterProps) => {
  const savedPeriodId = useMemo(
    () => (filters.find((item) => item.key === 'periodId')?.value as string) || '',
    [filters]
  );

  const [periodId, setPeriodId] = useState<string>(savedPeriodId);

  const { getDatesFromPeriod, dates } = usePeriods(periodId, periods);

  const submitPeriod = (id: string) => {
    const dates = getDatesFromPeriod(id);
    setFieldValue('periodId.value', id);
    setFieldValue('measuringDateMin.value', createDateToRequestWithTimezone(dates[0]));
    setFieldValue('measuringDateMax.value', createDateToRequestWithTimezone(dates[1]));
    setFieldTouched('measuringDateMin.value');
    setPeriodId(id);
  };

  const { values, errors, setValues, handleReset, submitForm, setFieldValue, setFieldTouched, ...formik } = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    validationSchema: FiltersDatepickersSchema([
      { dateFromFieldName: 'measuringDateMin', dateToFieldName: 'measuringDateMax' },
    ]),
    initialValues: initialEmptyValues,
    onSubmit,
  });

  useEffect(() => {
    setValues(combineFilters(initialEmptyValues, filters));
  }, [filters, setValues]);

  const types = useMemo(() => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'types'), [dictionaries]);

  const sources = useMemo(() => {
    const watersources = getSelectOptionsFromDictionaryByNameWithParent(dictionaries?.data, 'watersources');

    if (values.waterSourceType.value) {
      return watersources.filter((item) => item.parent === values.waterSourceType.value);
    }

    return watersources;
  }, [dictionaries, values.waterSourceType.value]);

  return (
    <FormikProvider
      value={{
        values,
        errors,
        setValues,
        handleReset,
        submitForm,
        setFieldValue,
        setFieldTouched,
        ...formik,
      }}
    >
      <Flex flexDirection="row" justifyContent="space-between">
        <Grid gap="12px" rowGap="8px" gridTemplateColumns="repeat(2, 175px) 225px 175px" {...props}>
          <Field
            as={DateInput}
            maxDate={dates?.[1]}
            minDate={dates?.[0]}
            isDisabled={!periodId}
            name="measuringDateMin.value"
            label="От"
            value={createDateFromResponse(values.measuringDateMin.value as MomentInput)}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFieldValue('measuringDateMin.value', createDateToRequestForFilter(e.target.value));
            }}
            showTimeInput={false}
            error={errors.measuringDateMin?.value}
          />
          <Field
            as={DateInput}
            maxDate={dates?.[1]}
            minDate={dates?.[0]}
            isDisabled={!periodId}
            name="measuringDateMax.value"
            label="До"
            value={createDateFromResponse(values.measuringDateMax.value as MomentInput)}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFieldValue('measuringDateMax.value', createDateToRequestForFilter(e.target.value, 'end'));
            }}
            showTimeInput={false}
            error={errors.measuringDateMax?.value}
          />
          <Field
            as={Select}
            name="waterSourceType.value"
            label="Тип пункта наблюдения"
            options={types}
            value={values.waterSourceType.value}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFieldValue('waterSourceType.value', e.target.value);
              setFieldValue('sourceId.value', null);
            }}
            error={errors.waterSourceType?.value}
          />
          <Field
            as={Select}
            name="sourceId.value"
            label="Пункт наблюдения"
            options={sources}
            value={values.sourceId.value}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFieldValue('sourceId.value', e.target.value);
            }}
            error={errors.sourceId?.value}
          />

          {children}
        </Grid>

        <FilterFormControls
          onReset={(e) => {
            handleReset(e);
            setPeriodId('');
            setTimeout(submitForm, 0);
          }}
          onSubmit={submitForm}
          isDisabled={isDisabled}
          isLoading={isLoading}
        />
      </Flex>
      <PeriodsButtons periodId={periodId} periods={periods} submitPeriod={submitPeriod} />
    </FormikProvider>
  );
};
