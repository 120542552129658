import { FormHeader, LoaderItem } from 'components/common';
import { createTouchedErrors } from 'features/create-touched-errors';
import { FormikProvider, useFormik } from 'formik';
import { useRequest } from 'hooks/useRequest';
import { DangerProcessSchema } from 'models/schemas/dangerprocess/danger';

import { useParams } from 'react-router-dom';
import { GeoecologicalRootPath } from 'pages/data/GeoecologicalProcesses/consts';
import FieldsForm from './FieldsForm';
import React, { useEffect, useState } from 'react';
import { IUploadMedia } from 'components/common/FileUploadDragAndDrop/types';
import { updateFiles } from 'api/services/media';
import { initialEmptyValues } from './AddDangerProcessReport';
import { useObjectType } from 'hooks/useObjectType';
import { DangerProcessItem } from 'pages/data/GeoecologicalProcesses/DangerouslyGeologicalProcesses/DangerProcess/types';
import { useDictionary } from 'hooks/useDictionary';
import { useGoBackWithFallback } from 'lib/utils/goBackWithFallback';
import { useEnhancedNotification } from 'hooks';

export const EditDangerProcessReport = () => {
  const { backWithFallback } = useGoBackWithFallback();
  const { dictionaries, isDictionariesLoading } = useDictionary({
    url: '/geoecology/v10/geoecology/dangerprocess/dictionaries',
  });
  const { id } = useParams<{ id: string }>();
  const [media, setMedia] = useState<IUploadMedia[]>([]);
  const objectType = useObjectType(dictionaries);

  const {
    isLoading: isDangerListLoading,
    result,
    get,
  } = useRequest<DangerProcessItem>(`/geoecology/v10/geoecology/dangerprocess/entry/${id}`);

  useEffect(() => {
    get();
  }, [get]);

  const initialValues = {
    ...initialEmptyValues,
    ...result,
  };

  const { onErrorEdit, onSuccessEdit } = useEnhancedNotification();

  const successHandler = () => {
    if (result?.measuringId) {
      onSuccessEdit();

      updateFiles(result?.measuringId, objectType, media)
        .then(() => {
          backWithFallback(`${GeoecologicalRootPath}/monitoring/danger/dangerprocess`);
        })
        .catch((error: Error) => {
          onErrorEdit(error.message);
        });
    }
  };

  const { put, isLoading: isPutLoading } = useRequest<DangerProcessItem>(
    `/geoecology/v10/geoecology/dangerprocess/entry/${id}`,
    successHandler,
    onErrorEdit,
  );

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    initialValues,
    validationSchema: DangerProcessSchema,
    onSubmit: (values) => put(values),
  });

  if (isDictionariesLoading || isPutLoading || isDangerListLoading) {
    return <LoaderItem />;
  }

  return (
    <FormikProvider
      value={{
        ...formik,
        errors: createTouchedErrors(formik.errors, formik.touched),
      }}
    >
      <FormHeader
        onBackClick={() => backWithFallback(`${GeoecologicalRootPath}/monitoring/danger/dangerprocess`)}
        header="Редактирование результатов измерения"
      />
      <FieldsForm
        dictionaries={dictionaries}
        onCancel={() => backWithFallback(`${GeoecologicalRootPath}/monitoring/danger/dangerprocess`)}
        media={media}
        onMediaChange={setMedia}
        isEditMode
        uploadItemId={result?.measuringId}
      />
    </FormikProvider>
  );
};
