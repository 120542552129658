import { ERROR_MESSAGES, SUCCESS_MESSAGES } from 'constants/messages';
import { createTouchedErrors } from 'features/create-touched-errors';
import { FormikProvider, useFormik } from 'formik';
import { useNotifications } from 'hooks/useNotifications';
import { useRequest } from 'hooks/useRequest';
import { useHistory } from 'react-router-dom';
import { SnowDeicingItem } from 'pages/data/PGR/Snow/useSnowDeicingList';
import FieldsForm from 'pages/analytics/forms/Snow/AnalyticsSnowFieldsForm';
import { useCallback, useState } from 'react';
import { updateFiles } from 'api/services/media';
import { IUploadMedia } from 'components/common/FileUploadDragAndDrop/types';
import { useObjectType } from 'hooks/useObjectType';
import { ApiError } from 'models';
import { formatErrorMessage } from 'lib/utils/formatErrorMessage';
import { ANALYTICS_LAB_PATHS } from 'pages/analytics/consts/AnalyticsTableRoutes';
import { initialEmptyValues } from './initialEmptyValues';
import { AnalyticsSnowSchema } from 'models/schemas/analytics/analyticsSnowSchema';
import { useGoBackWithFallback } from 'lib/utils/goBackWithFallback';
import { useDictionary } from 'hooks/useDictionary';

export const AnalyticsAddDeicingSnowReport = () => {
  const { isDictionariesLoading, dictionaries } = useDictionary({ url: '/deicing/v10/deicing/snow/dictionaries' });

  const history = useHistory();
  const { backWithFallback } = useGoBackWithFallback();
  const { successNotify, errorNotify } = useNotifications();
  const [media, setMedia] = useState<IUploadMedia[]>([]);
  const objectType = useObjectType(dictionaries);

  const onError = useCallback(
    (message?: ApiError | string | null) => {
      errorNotify({
        key: formatErrorMessage(message) || 'file/add/error',
        message: formatErrorMessage(message) || ERROR_MESSAGES.CREATE,
      });
    },
    [errorNotify]
  );

  const onSuccess = useCallback(
    (response: SnowDeicingItem | null) => {
      if (response) {
        successNotify({
          key: 'file/add/success',
          message: SUCCESS_MESSAGES.CREATE,
        });

        updateFiles(response.registryId, objectType, media)
          .then(() => {
            backWithFallback('/analytics');
          })
          .catch((error: Error) => {
            onError(error.message);
            history.replace(
              `${ANALYTICS_LAB_PATHS.reagents.snow.edit}/${response.registryId}?openAttachmentsTab=true`,
              response
            );
          });
      } else {
        onError();
      }
    },
    [backWithFallback, history, media, objectType, onError, successNotify]
  );

  const { post } = useRequest<SnowDeicingItem, any>('/deicing/v10/deicing/snow', onSuccess, onError);

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    initialValues: initialEmptyValues,
    onSubmit: (values) => post(values),
    validationSchema: AnalyticsSnowSchema,
  });

  return (
    <>
      <FormikProvider
        value={{
          ...formik,
          errors: createTouchedErrors(formik.errors, formik.touched),
        }}
      >
        <FieldsForm
          dictionaries={dictionaries}
          onCancel={() => {
            backWithFallback('/analytics');
          }}
          isLoading={isDictionariesLoading}
          onMediaChange={setMedia}
          media={media}
        />
      </FormikProvider>
    </>
  );
};
