import appLocale from 'constants/appLocale';
import { NSIRootPath } from 'pages/dictionaries/const/routes';
import { pdkLocales } from 'pages/dictionaries/NSIPdk/consts/locales';
import { consentrationPath, pdkRootPath } from 'pages/dictionaries/NSIPdk/consts/paths';

export const nsiPdkBreadcrumbs = [
  {
    breadcrumb: appLocale.mainPage.nsi,
    path: NSIRootPath,
  },
  {
    breadcrumb: pdkLocales.main,
    path: pdkRootPath,
  },
  {
    breadcrumb: pdkLocales.concentration,
    path: consentrationPath,
  },
];
