import { createTouchedErrors } from 'features/create-touched-errors';
import { FormikProvider, useFormik } from 'formik';
import { useRequest } from 'hooks/useRequest';
import FieldsForm from 'pages/PDKExcessJournal/Form/FieldsForm';
import { useEnhancedNotification } from 'hooks';
import { ISOKJournalItem } from 'pages/PDKExcessJournal/types';
import { PdkJournalSchema } from 'models/schemas/pdk/pdkJournalSchema';
import { useGoBackWithFallback } from 'lib/utils/goBackWithFallback';
import { useDictionary } from 'hooks/useDictionary';

export const initialEmptyValues = {
  date: null,
  pdkH2s: null,
  pdkO3: null,
  pdkNO2: null,
  pdkNO: null,
  pdkSO2: null,
  pdkCO: null,
  pdkPM10: null,
  pdkPM25: null,
  pdkNH3: null,
};

export const AddPDKExcessJournalItem = () => {
  const { backWithFallback } = useGoBackWithFallback();
  const { dictionaries, isDictionariesLoading } = useDictionary({ url: '/appeal/v10/sok/journal/dictionaries' });
  const { onErrorCreate, onSuccessCreate } = useEnhancedNotification();

  const { post } = useRequest<ISOKJournalItem, any>(
    '/appeal/v10/sok/journal',
    () => {
      onSuccessCreate();
      backWithFallback('/pdk-excess-journal');
    },
    onErrorCreate
  );

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    initialValues: initialEmptyValues,
    validationSchema: PdkJournalSchema,
    onSubmit: (values) => post(values),
  });

  return (
    <FormikProvider
      value={{
        ...formik,
        errors: createTouchedErrors(formik.errors, formik.touched),
      }}
    >
      <FieldsForm
        dictionaries={dictionaries}
        onCancel={() => backWithFallback('/pdk-excess-journal')}
        isLoading={isDictionariesLoading}
      />
    </FormikProvider>
  );
};
