import React from 'react';
import { FormWrapper } from 'components/common';
import { Box, Grid } from '@chakra-ui/react';

export const FormContainer: React.FC = (props) => (
  <Box flexDirection="column">
    <FormWrapper>
      <Grid marginTop={30} gap={15} marginBottom={30}>
        {props.children}
      </Grid>
    </FormWrapper>
  </Box>
);
