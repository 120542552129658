import * as yup from 'yup';
import { ValidationError } from 'yup';
import { DateToLessThanFrom } from '../constans';
import moment from 'moment';

interface FieldObject {
  dateFromFieldName: string;
  dateToFieldName: string;
}

export const FiltersDatepickersSchema = (
  fieldsObjectsArray: FieldObject[],
  additionalSchema?: any,
  maxDaysPeriod?: number,
) => {
  let schemaObject: any = {};
  fieldsObjectsArray.forEach((item) => {
    schemaObject[item.dateFromFieldName] = yup.object().shape({
      value: yup.string().nullable(),
    });
    schemaObject[item.dateToFieldName] = yup.object().shape({
      value: yup.string().nullable(),
    });
    schemaObject = { ...schemaObject, ...additionalSchema };
  });
  return yup
    .object()
    .shape(schemaObject)
    .test((values) => {
      const errors = fieldsObjectsArray
        .map((item) => {
          if (values[item.dateFromFieldName].value && values[item.dateToFieldName].value) {
            if (new Date(values[item.dateFromFieldName].value) > new Date(values[item.dateToFieldName].value)) {
              return new ValidationError(DateToLessThanFrom, '', `${item.dateFromFieldName}.value`);
            }
            if (
              maxDaysPeriod &&
              moment(values[item.dateToFieldName].value).diff(moment(values[item.dateFromFieldName].value), 'days') +
                1 >
                maxDaysPeriod
            ) {
              return new yup.ValidationError(
                `Задан слишком большой период для запроса. Задайте период менее ${maxDaysPeriod} дней`,
                '',
                `${item.dateToFieldName}.value`,
              );
            }
          }
        })
        .filter(Boolean);
      if (errors) {
        return new ValidationError(errors as yup.ValidationError[]);
      }
      return true;
    });
};

export const SchemaForCustomFilter = (fieldsObjectsArray: FieldObject[], additionalSchema?: any) => {
  let schemaObject: any = {};
  fieldsObjectsArray.forEach((item) => {
    schemaObject[item.dateFromFieldName] = yup.string().nullable();
    schemaObject[item.dateToFieldName] = yup.string().nullable();
    schemaObject = { ...schemaObject, ...additionalSchema };
  });
  return yup
    .object()
    .shape(schemaObject)
    .test((values) => {
      const errors = fieldsObjectsArray
        .map((item) => {
          if (values[item.dateFromFieldName] && values[item.dateToFieldName]) {
            if (new Date(values[item.dateFromFieldName]).getTime() > new Date(values[item.dateToFieldName]).getTime()) {
              return new ValidationError(DateToLessThanFrom, '', `${item.dateFromFieldName}`);
            }
          }
        })
        .filter(Boolean);
      if (errors) {
        return new ValidationError(errors as yup.ValidationError[]);
      }
      return true;
    });
};
