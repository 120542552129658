import { Stack } from '@chakra-ui/react';
import { Select } from 'components/common';
import { dictParametersToMap } from 'features/parametersMap';
import { Field, useFormikContext } from 'formik';
import { concat, get } from 'lodash';
import { TLawnsDictionaries } from 'pages/data/GreenPlants/Lawns/useLawnsDictionaries';
import React, { useCallback } from 'react';
import { TGreenPlantsForm } from 'pages/data/GreenPlants/Form/types';
import { ILawnsTabPanelProps } from './types';
import { getDictionariesIds, getDictionaryId } from 'pages/data/GreenPlants/utils';
import { getSelectOptionsFromDictionaryByName } from '../../../../../../features/get-select-options-from-dictionary';

interface TLawnsOtherFormmProps {
  entity: ILawnsTabPanelProps['entity'];
  isLoading: ILawnsTabPanelProps['isLoading'];
  areaParameters: TLawnsDictionaries['areaParameters'];
  lawnParameters: TLawnsDictionaries['lawnParameters'];
}

const LawnsOtherForm: React.FC<TLawnsOtherFormmProps> = (
  {
    entity,
    isLoading,
    lawnParameters = [],
    areaParameters = [],
  }
) => {
  const ctx = useFormikContext<TGreenPlantsForm>();

  const parameters = dictParametersToMap(concat(lawnParameters, areaParameters));

  const getOptionsByName = useCallback((name: string) => getSelectOptionsFromDictionaryByName(
    [...lawnParameters, ...areaParameters],
    name,
    false,
    get(ctx.values, `${entity}.parameters.${name}.ids`)
  ), [areaParameters, ctx.values, entity, lawnParameters]);

  return (
    <Stack>
      <Field
        isDisabled={isLoading}
        as={Select}
        options={getOptionsByName('decorativeLawns')}
        name={`${entity}.parameters.decorativeLawns.dictionaryItemId[0]`}
        label={parameters.decorativeLawns?.title}
        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
          if (e?.target) {
            ctx.setFieldValue(`${entity}.parameters.decorativeLawns.dictionaryItemId`, [
              getDictionaryId(e.target.value, parameters.decorativeLawns.values)
            ]);
            ctx.setFieldValue(`${entity}.parameters.decorativeLawns.value`, [e.target.value]);
            ctx.setFieldValue(
              `${entity}.parameters.decorativeLawns.parameterId`,
              parameters.decorativeLawns?.parameterId,
            );
          }
        }}
      />

      <Field
        isDisabled={isLoading}
        as={Select}
        options={getOptionsByName('microrelief')}
        name={`${entity}.parameters.microrelief.dictionaryItemId`}
        label={parameters.microrelief?.title}
        multiple
        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
          if (Array.isArray(e.target.value)) {
            ctx.setFieldValue(`${entity}.parameters.microrelief.dictionaryItemId`,
              getDictionariesIds(e.target.value, parameters.microrelief.values));
            ctx.setFieldValue(`${entity}.parameters.microrelief.value`, e.target.value);
            ctx.setFieldValue(`${entity}.parameters.microrelief.parameterId`, parameters.microrelief?.parameterId);
          }
        }}
      />

      <Field
        isDisabled={isLoading}
        as={Select}
        options={getOptionsByName('recommendations')}
        name={`${entity}.parameters.recommendations.dictionaryItemId`}
        label={parameters.recommendations?.title}
        multiple
        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
          if (Array.isArray(e.target.value)) {
            ctx.setFieldValue(`${entity}.parameters.recommendations.dictionaryItemId`,
              getDictionariesIds(e.target.value, parameters.recommendations.values));
            ctx.setFieldValue(`${entity}.parameters.recommendations.value`, e.target.value);
            ctx.setFieldValue(
              `${entity}.parameters.recommendations.parameterId`,
              parameters.recommendations?.parameterId,
            );
          }
        }}
      />
    </Stack>
  );
};

export { LawnsOtherForm };
