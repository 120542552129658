import { TOption } from '../models/toption';

export const userTypes: TOption[] = [
  {
    value: 'ADMINISTRATOR',
    name: 'Администратор',
  },
  {
    value: 'USER',
    name: 'Пользователь',
  },
  {
    value: 'REPORTER',
    name: 'Отчеты',
  },
  {
    value: 'STAFF',
    name: 'Обслуживающий персонал',
  },
];
