import { useFormikContext } from 'formik';
import { DefaultText, FieldsFormControls, FormHeader, LoaderItem } from 'components/common';
import { FieldFormProps } from 'types/forms';
import React, { useMemo } from 'react';
import { Box, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import appLocale from 'constants/appLocale';
import NSIWaterGatesGeneralInformationTab
  from 'pages/dictionaries/NSIWater/NSIWaterGates/NSIWaterGatesForms/NSIWaterGatesGeneralInformationTab';
import LocationFormTab from 'components/common/LocationFormTab/LocationFormTab';
import { getSelectOptionsFromDictionaryByName } from 'features/get-select-options-from-dictionary';
import { NSIWaterItem } from 'pages/dictionaries/NSIWater/types';

const NSIWaterGatesFormFields = ({ onCancel, dictionaries, isLoading, isEditMode, editedObject }: FieldFormProps) => {
  const { handleSubmit } = useFormikContext<NSIWaterItem>();

  const title = isEditMode ? 'Редактирование источника' : 'Добавление источника';

  const okrugs = useMemo(() => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'okrugs'), [dictionaries]);
  const rayons = useMemo(() => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'rayons'), [dictionaries]);

  if (isLoading) {
    return <LoaderItem />;
  }

  return (
    <Box flexDirection="column">
      <Tabs>
        <FormHeader onBackClick={onCancel} header={title}>
          <TabList>
            {[appLocale.common.generalInformation, appLocale.common.location].map((tab, i) => (
              <Tab key={i}>
                <DefaultText cursor="pointer">{tab}</DefaultText>
              </Tab>
            ))}
          </TabList>
        </FormHeader>
        <TabPanels>
          <TabPanel>
            <NSIWaterGatesGeneralInformationTab dictionaries={dictionaries} editedObject={editedObject}>
              <FieldsFormControls onCancel={onCancel} handleSubmit={handleSubmit} />
            </NSIWaterGatesGeneralInformationTab>
          </TabPanel>
          <TabPanel>
            <LocationFormTab<NSIWaterItem> okrugs={okrugs} rayons={rayons}>
              <FieldsFormControls onCancel={onCancel} handleSubmit={handleSubmit} showSave />
            </LocationFormTab>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default NSIWaterGatesFormFields;
