import { Column } from '@material-table/core';
import { createLocalDateDDMMYYYY } from 'lib/create-date';
import appLocale from 'constants/appLocale';
import { IAnalyticsItem } from 'pages/analytics/consts/types';

const AnalyticsTableColumns: Array<Column<IAnalyticsItem>> = [
  {
    title: appLocale.analytics.probeDate,
    field: 'date',
    render: (row) => (row.date ? createLocalDateDDMMYYYY(row.date) : '-'),
  },
  {
    title: appLocale.analytics.protocolNumber,
    field: 'protocolNumber',
    render: (row) => row.protocolNumber || '-',
  },
  {
    title: appLocale.analytics.district,
    field: 'region',
    render: (row) => row.region || '-',
  },
  {
    title: appLocale.analytics.area,
    field: 'district',
    render: (row) => row.district || '-',
  },
  {
    title: appLocale.analytics.subsystem,
    field: 'subsystemModuleName',
  },
];

export default AnalyticsTableColumns;
