import { IGraphSettings } from './types';
import { ChartOptions } from 'chart.js';

export const initialGraphSettingsEmptyValues: IGraphSettings = {
  title: '',
  graphs: [],
};

export const initialGraphOptions: ChartOptions = {
  animation: false,
  responsive: true,
  maintainAspectRatio: false,
  interaction: {
    mode: 'point',
    intersect: false,
  },
  plugins: {
    title: {
      display: true,
      text: 'График',
    },
    legend: {
      position: 'bottom',
      align: 'start',
    },
  },
  scales: {
    x: {
      type: 'time',
      time: {
        tooltipFormat: 'DD.MM.YYYY HH:mm:ss',
        minUnit: 'second',
        displayFormats: {
          second: 'mm:ss',
          minute: 'HH:mm',
          hour: 'DD.MM HH:mm',
          day: 'DD.MM HH:mm',
          week: 'DD.MM',
          month: 'MM.YYYY',
          quarter: 'MM.YYYY',
          year: 'YYYY',
        },
      },
    },
  },
};

export const initialGraphOptionWithCustomFormat: ChartOptions = {
  animation: false,
  responsive: true,
  maintainAspectRatio: false,
  interaction: {
    mode: 'nearest',
    intersect: false,
  },
  plugins: {
    title: {
      display: true,
      text: 'График',
    },
    legend: {
      position: 'bottom',
      align: 'start',
    },
  },
  scales: {
    x: {
      type: 'time',
      time: {
        tooltipFormat: 'DD.MM.YYYY HH:mm',
        minUnit: 'second',
        displayFormats: {
          second: 'DD.MM.YYYY HH:mm',
          minute: 'DD.MM.YYYY HH:mm',
          hour: 'DD.MM.YYYY HH:mm',
          day: 'DD.MM.YYYY HH:mm',
          week: 'DD.MM.YYYY HH:mm',
          month: 'DD.MM.YYYY HH:mm',
          quarter: 'DD.MM.YYYY HH:mm',
          year: 'DD.MM.YYYY HH:mm',
        },
      },
    },
  },
};
