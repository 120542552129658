import { FormikProvider, useFormik } from 'formik';
import React, { useEffect, useMemo } from 'react';
import { IFilterItem, TDictionariesArray } from 'models';
import { FiltersDatepickersSchema } from 'models/schemas/filtersDatepickersSchema/FiltersDatepickersSchema';
import { PElRaidsFilterFormFields } from 'pages/pel/Raids/PELRaidsJournal/PELRaidsFilterFormFields';
import combineFilters from 'lib/utils/combineFilters';

const initialValues: Record<string, IFilterItem> = {
  territoryName: {
    key: 'territoryName',
    operation: 'CONTAINS',
    value: '',
  },
  districts: {
    key: 'districtId',
    operation: 'IN',
    value: null,
  },
  regions: {
    key: 'regionId',
    operation: 'IN',
    value: null,
  },
  dateTimeMin: {
    key: 'startDate',
    operation: 'GTE',
    value: null,
  },
  dateTimeMax: {
    key: 'startDate',
    operation: 'LTE',
    value: null,
  },
  protocolNumber: {
    key: 'reportPoints.protocolNumber',
    operation: 'EQ',
    value: '',
  },
  pelProtocolNumber: {
    key: 'reportPoints.pelProtocolNumber',
    operation: 'EQ',
    value: '',
  },
  deleted: {
    key: 'deleted',
    operation: 'EQ',
    value: false,
  },
};

interface IProps {
  onSubmit: (values: { [p: string]: IFilterItem }) => void;
  dictionaries: { data: TDictionariesArray } | null;
  typeId: string;
  filters: IFilterItem[];
}

export const PELRaidsFilter = ({ onSubmit, dictionaries, typeId, filters }: IProps) => {
  const initialEmptyValues = useMemo<Record<string, IFilterItem>>(
    () => ({
      ...initialValues,
      typeId: {
        key: 'typeId',
        operation: 'EQ',
        value: typeId,
      },
      status: {
        key: 'status',
        operation: 'EQ',
        value: null,
      },
    }),
    [typeId],
  );

  const { setValues, ...formik } = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    validationSchema: FiltersDatepickersSchema([{ dateFromFieldName: 'dateTimeMin', dateToFieldName: 'dateTimeMax' }]),
    initialValues: initialEmptyValues,
    onSubmit,
  });

  useEffect(() => {
    setValues(combineFilters(initialEmptyValues, filters));
  }, [filters, initialEmptyValues, setValues]);

  return (
    <>
      <FormikProvider
        value={{
          setValues,
          ...formik,
        }}
      >
        <PElRaidsFilterFormFields dictionaries={dictionaries} />
      </FormikProvider>
    </>
  );
};
