const AISWeatherLocales = {
  createDate: 'Дата/время измерения',
  dateFrom: 'От',
  dateTo: 'До',
  packageNumber: 'Пакет',
  report: 'Отчет',
  source: 'Источник',
  height: 'Высота',
  airTemperature: 'Температура воздуха, град Целс',
  wetness: 'Влажность, %',
  dewPoint: 'Точка росы, Град Целс',
  windDirection: 'Направление ветра, град пл угла',
  instantaneousWind: 'Скорость ветра МГН, м/c',
  avgWindSpeed: 'Скорость ветра СP10, м/с',
  maxWindSpeed: 'Скорость ветра, МАКС 10',
  phenomena: 'Явление погоды',
  visibility: 'Видимость, м',
  cloudHeight: 'Высота облачности, м',
  airPressure: 'Атмосферное давление, мм рт ст',
  surfaceTemperature: 'Температура почвы, град Целс',
  processingDatetime: 'Дата и время обработки',
  number: 'Номер пакета',
  fileName: 'Имя файла пакета',
  date: 'Дата пакета'
};

export { AISWeatherLocales };
