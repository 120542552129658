import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import appLocale from 'constants/appLocale';
import { ATMO_RAID_LIST_PATH, NOISE_RAID_LIST_PATH, PEL_ROOT_PATH, PELTableView } from './constants';
import { PageHeader, RoutesList } from 'components/common';
import { Flex, Heading } from '@chakra-ui/react';
import { TABLE_VIEW } from 'pages/citizenAppeal';

const PlanningRaids: React.FunctionComponent = () => {
  useUpdateBreadcrumbs([
    {
      breadcrumb: appLocale.pel.main,
      path: PEL_ROOT_PATH,
    },
  ]);

  return (
    <Flex flexDirection="column">
      <PageHeader>
        <Heading>{appLocale.pel.main}</Heading>
      </PageHeader>
      <RoutesList
        routes={[
          {
            path: ATMO_RAID_LIST_PATH,
            breadcrumb: appLocale.pel.atmosphereRaidList,
          },
          {
            path: NOISE_RAID_LIST_PATH,
            breadcrumb: appLocale.pel.noiseRaidList,
          },
          {
            path: PELTableView,
            breadcrumb: TABLE_VIEW,
          },
        ]}
      />
    </Flex>
  );
};

export default PlanningRaids;
