import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { normalizeExistParameters } from 'features/normalizeExistParameters';
import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import { createGreenPlantsEditUrl } from 'pages/routes/GreenPlants';
import appLocale from 'constants/appLocale';
import { useFloraDictionaries } from '../useFloraDictionaries';
import { useFloraListItem } from '../useFloraListItem';
import { GreenPlantsForm } from '../../Form/GreenPlantsForm';
import { greenPlantsFormInitialValues, GreenPlantsFormTypes } from '../../Form/types';
import { useFloraEditItem } from './useFloraEditItem';
import { IUploadMedia } from 'components/common/FileUploadDragAndDrop/types';
import { TGreenPlantsForm } from 'pages/data/GreenPlants/Form/types';
import { updateFiles } from 'api/services/media';
import { useObjectType } from 'hooks/useObjectType';
import { ERROR_MESSAGES } from 'constants/messages';
import { useNotifications } from 'hooks';
import { DeicingGreenPlantsRootPath, DeicingStatements, GreenPlantsFloraUrl } from 'pages/routes/PGRGreenPlants';
import { useGoBackWithFallback } from 'lib/utils/goBackWithFallback';

const withEditFlora = (Component: typeof GreenPlantsForm): React.FC =>
  memo(() => {
    const { backWithFallback } = useGoBackWithFallback();
    const type = 'flora';
    const { replace } = useHistory();
    const { registryId } = useParams<{
      registryId: string;
    }>();
    const { errorNotify } = useNotifications();
    const defaultIndex = Object.values(GreenPlantsFormTypes).indexOf(type);
    const [media, setMedia] = useState<IUploadMedia[]>([]);
    const { get: getPlants, result: flora, isLoading: isLoadingFlora } = useFloraListItem(registryId);

    useEffect(() => {
      getPlants();
    }, [getPlants]);

    useUpdateBreadcrumbs([
      { breadcrumb: appLocale.common.data, path: '/data' },
      {
        breadcrumb: 'Воздействие на зеленые насаждения',
        path: DeicingGreenPlantsRootPath,
      },
      {
        breadcrumb: 'Ведомости',
        path: DeicingStatements,
      },
      {
        breadcrumb: 'Деревья и кустарники',
        path: GreenPlantsFloraUrl,
      },
      {
        breadcrumb: 'Редактирование результатов',
        path: createGreenPlantsEditUrl('flora', flora?.registryId ?? ''),
        isLoading: isLoadingFlora,
      },
    ]);

    const { editFlora, isLoading: isLoadingEdit } = useFloraEditItem();

    const { dictionaries, result, isLoading: isLoadingDictionaries } = useFloraDictionaries();
    const objectType = useObjectType(result);
    const floraParams = flora?.parameters;
    const parameters = useMemo(
      () => normalizeExistParameters(floraParams, dictionaries.plantParameters, dictionaries.areaParameters),
      [dictionaries.areaParameters, dictionaries.plantParameters, floraParams]
    );

    const initialFlora = flora ? { ...flora, parameters } : greenPlantsFormInitialValues.flora;

    const onError = useCallback(
      (message?: string | null) => {
        errorNotify({
          key: message || 'file/create/error',
          message: message || ERROR_MESSAGES.CREATE,
        });
      },
      [errorNotify]
    );

    const onSubmit = useCallback(
      async (values: Partial<TGreenPlantsForm>) => {
        await editFlora(values.flora);

        await updateFiles(registryId, objectType, media)
          .then(() => {
            backWithFallback(GreenPlantsFloraUrl);
          })
          .catch((error: Error) => {
            onError(error.message);
            replace(`${DeicingStatements}/flora/edit/${registryId}?openAttachmentsTab=true`);
          });
      },
      [backWithFallback, editFlora, media, objectType, onError, registryId, replace]
    );

    return (
      <Component
        header="Редактирование результатов"
        defaultIndex={defaultIndex}
        initialValues={{
          ...greenPlantsFormInitialValues,
          flora: initialFlora,
        }}
        type={type}
        isSubmitting={isLoadingEdit || isLoadingFlora || isLoadingDictionaries}
        onSubmit={onSubmit}
        floraDictionaries={dictionaries}
        media={media}
        setMedia={setMedia}
        registryId={registryId}
        backUrl={`${DeicingStatements}/${type}`}
        isEditMode
      />
    );
  });

export { withEditFlora };
