import { Box, Flex, Grid, GridItem, Heading } from '@chakra-ui/react';
import { Button, Checkbox, DateInput, Input, Modal, Select, Textarea } from 'components/common';
import { Field, FieldArrayRenderProps, FormikHandlers, useFormikContext } from 'formik';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { IMainObservationPlace, IMainReport } from '../../types';
import { TDictionaryItemDictionary } from 'models';
import { DisplayUserItem } from 'pages/admin/models/UserItem';
import { usePaginatedLoader } from 'pages/admin/typedLoader';
import { baseAdminUserUrl } from 'pages/admin/User/consts';
import {
  getPathsBySubsystemName,
  getSubsystemHumanReadableName,
  SubsystemName,
} from 'pages/analytics/consts/AnalyticsTableRoutes';
import { noiseId } from 'pages/pel/constants';
import { createLocalDateISO8601, createUTCTime } from 'lib/create-date';
import { AddIcon, DeleteIcon, DownloadIcon, EditIcon, ViewIcon2 } from 'components/icons';
import { IUploadMedia } from 'components/common/FileUploadDragAndDrop/types';
import { TaskAttachments } from 'pages/citizenAppeal/CitizensAppealsRecord/components/TaskAttachments';
import { useDisclosure } from 'hooks/useDisclosure';
import { UploadObjectType } from 'api/services/media';
import { useEnhancedNotification, useNotifications, useRequest } from 'hooks';
import { ReportProtocolGetDto } from 'pages/analytics/Raids/types';
import { ExtensionToMimeTypeEnum } from 'lib/utils/files/getFileExtensionByMimeType';
import { downloadFileFromResponse } from 'lib/utils/files/downloadFile';
import { Close } from '@material-ui/icons';
import {
  ReportSetProtocolModal,
} from '../../../../citizenAppeal/CitizensAppealsRecord/components/CompletionReports/reportSetProtocolModal';
import { TaskPrepareContext } from '../../../../citizenAppeal/CitizensAppealsRecord/components/TaskPrepareContext';
import { garbageBurningPoint } from '../../../../citizenAppeal';
import { CustomAutocomplete } from '../../../../../components/common/Autocomplete';
import { AsyncAddressSelect } from 'components/common/AsyncAddressSelect/AsyncAddressSelect';

interface ObservationPlaceProps {
  name: string;
  values: IMainObservationPlace;
  onRemovePoint: ReturnType<FieldArrayRenderProps['handleRemove']>;
  handleChange: FormikHandlers['handleChange'];
  noiseValues: IMainReport;
  pointTypeId?: TDictionaryItemDictionary[];
  territoryTypeId?: TDictionaryItemDictionary[];
  raidResultId?: string[];
  noiseSourceTypeId?: TDictionaryItemDictionary[];
  odorSourceTypeId?: TDictionaryItemDictionary[];
  taskId?: string;
  media?: IUploadMedia[];
  onMediaSave: (media: IUploadMedia[], idx: number) => void;
  index: number;
  errors: any;
  touched: any;
  browserId: string;
  uid: string;
}

const NoiseReportObservationPlace: React.FunctionComponent<ObservationPlaceProps> = ({
  name,
  values,
  onRemovePoint,
  handleChange,
  pointTypeId,
  territoryTypeId,
  raidResultId,
  noiseValues,
  noiseSourceTypeId,
  odorSourceTypeId,
  taskId,
  onMediaSave,
  media = [],
  index,
  errors,
  touched,
  browserId,
  uid,
}) => {
  const { errorNotify } = useNotifications();
  const { onDownloadError } = useEnhancedNotification();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [currentMedia, setCurrentMedia] = useState<IUploadMedia[]>(media);
  const { get: getUserList, result } = usePaginatedLoader<DisplayUserItem>(baseAdminUserUrl);
  const { setFieldValue } = useFormikContext<IMainReport>();
  const { noiseProtocolId, noisePointId, setNoiseProtocolId, setNoisePointId } = useContext(TaskPrepareContext);
  const protocolIdAdder = (field: string, id: string | null) => {
    setFieldValue(field, id);
  };
  useEffect(() => {
    getUserList();
  }, [getUserList]);

  useEffect(() => {
    if (values.pelNoiseProtocolId) {
      setNoisePointId(values.id);
      setNoiseProtocolId(values.pelNoiseProtocolId);
    }
  }, [setNoisePointId, setNoiseProtocolId, values.id, values.pelNoiseProtocolId]);

  const executors = useMemo(
    () =>
      result?.data.map((user) => ({
        name: user.fullName,
        value: user.id,
      })) || [],
    [result]
  );

  const onProtocolNotFound = useCallback(() => {
    errorNotify({
      message: 'Протокол не найден',
      key: 'error/protocol/notfound',
    });
  }, [errorNotify]);

  const onFileResponseSuccess = useCallback((response) => {
    downloadFileFromResponse({
      response,
      name: `Шаблон протокола_${getSubsystemHumanReadableName(SubsystemName.pelNoise)}`,
    });
  }, []);

  const { get: getProtocolForPoint } = useRequest<ReportProtocolGetDto>('', undefined, onProtocolNotFound);
  const { get: download } = useRequest<any>('', onFileResponseSuccess, onDownloadError);
  const onProtocolDownload = useCallback(
    async (pointId: string) => {
      const protocol = await getProtocolForPoint({ url: `/appeal/v10/points/${pointId}/pel/protocols` });
      if (protocol && 'id' in protocol && protocol.id) {
        await download({
          url: getPathsBySubsystemName(SubsystemName.pelNoise)?.download(protocol.id),
          headers: { ACCEPT: ExtensionToMimeTypeEnum.docx },
          responseType: 'blob',
        });
      }
    },
    [download, getProtocolForPoint]
  );

  const { isOpen: isProtocolsOpen, onOpen: onProtocolOpen, onClose: onProtocolClose } = useDisclosure();

  const customClose = () => {
    onProtocolClose();
  };

  const { successNotify: unbindSuccess, errorNotify: unbindError } = useNotifications();

  const successUnbindProtocol = () => {
    unbindSuccess({
      key: 'token/update/success',
      message: 'Протокол успешно отвязан',
    });
    protocolIdAdder(`${name}.pelNoiseProtocolId`, null);
    setNoisePointId('');
    setNoiseProtocolId('');
  };

  const errorUnbindProtocol = () => {
    unbindError({
      key: 'token/update/success',
      message: 'Ошибка при отвязке протокола',
    });
  };

  const isArea = useMemo(
    () => pointTypeId?.find((item) => item.value === values.pointTypeId)?.name === 'Область обследования',
    [pointTypeId, values.pointTypeId]
  );

  const { post } = useRequest('/mapsclient/v10/sendCommand');

  const startEditing = useCallback(() => {
    post({
      browserId,
      command: 'startEditing',
      data: {
        uid,
        layerTemplateId: '1121430612',
      },
    });
  }, [browserId, post, uid]);

  const addFeature = useCallback(
    (id, name) => {
      post({
        browserId,
        command: 'addFeature',
        data: {
          uid,
          layerTemplateId: '1121430612',
          geometryType: isArea ? 'Polygon' : 'Point',
          feature: {
            type: 'Feature',
            properties: {
              id,
              name,
            },
          },
        },
      });
    },
    [browserId, isArea, post, uid]
  );

  const { put: unbindProtocol } = useRequest('', successUnbindProtocol, errorUnbindProtocol);

  const isNoise = noiseValues.raidTypeId === noiseId;
  return (
    <Box bg="GhostWhite" padding="1rem">
      <Flex alignItems="center">
        <Heading as="h3" size="md">
          Место наблюдения
        </Heading>
        <Button
          leftIcon={<DeleteIcon />}
          variant="ghost"
          color="PrimaryButton.500"
          size="sm"
          p={0}
          marginLeft={5}
          onClick={onRemovePoint}
        />
        <Button
          leftIcon={<EditIcon />}
          variant="ghost"
          color="PrimaryButton.500"
          size="sm"
          p={0}
          marginLeft="auto"
          onClick={() => {
            window.scrollTo(0, 0);
            startEditing();
          }}
          isDisabled={isArea ? !values.pointCoordinateArray : !(values.pointLatitude || values.pointLongitude)}
        >
          Редактировать координаты на карте
        </Button>
        <Button
          leftIcon={<AddIcon />}
          variant="ghost"
          color="PrimaryButton.500"
          size="sm"
          p={0}
          marginLeft={5}
          onClick={() => {
            window.scrollTo(0, 0);
            addFeature(values.id || values.temporaryId, values.pointNumber);
          }}
          isDisabled={isArea ? !!values.pointCoordinateArray : !!values.pointLatitude || !!values.pointLongitude}
        >
          Добавить координаты на карте
        </Button>
      </Flex>
      <Grid gap={5} templateColumns="repeat(4, 1fr)" marginTop={5}>
        <Field
          as={Input}
          name={`${name}.pointNumber`}
          label="№ точки"
          onChange={handleChange}
          error={errors?.points && touched?.points[index]?.pointNumber && errors?.points[index]?.pointNumber}
        />
        <Field
          as={Select}
          name={`${name}.pointTypeId`}
          label="Тип выезда"
          options={pointTypeId}
          onChange={(e: any) => {
            setFieldValue(`${name}.pointLatitude`, '');
            setFieldValue(`${name}.pointLongitude`, '');
            setFieldValue(`${name}.pointCoordinateArray`, '');
            handleChange(e);
          }}
          error={errors?.points && touched?.points[index]?.pointTypeId && errors?.points[index]?.pointTypeId}
        />
        <Field
          as={Input}
          name={`${name}.protocolNumber`}
          label="№ протокола/рабочего листа"
          error={false}
          onChange={handleChange}
        />
        <Field
          as={DateInput}
          name={`${name}.protocolDate`}
          label="Дата протокола"
          error={errors?.points && touched?.points[index]?.protocolDate && errors?.points[index]?.protocolDate}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setFieldValue(`${name}.protocolDate`, createLocalDateISO8601(e.target.value))
          }
        />
      </Grid>
      <Grid gap={5} templateColumns="repeat(4, 1fr)" marginTop={5}>
        <Field
          as={DateInput}
          name={`${name}.measurementTimeFrom`}
          label="Время измерений с"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setFieldValue(e.target.name, createUTCTime(e.target.value))
          }
          error={false}
          showTimeInput
        />
        <Field
          as={DateInput}
          name={`${name}.measurementTimeTo`}
          label="Время измерений до"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setFieldValue(e.target.name, createUTCTime(e.target.value))
          }
          error={false}
          showTimeInput
        />
        <Field
          as={Select}
          name={isNoise ? `${name}.noiseSourceTypeId` : `${name}.odorSourceTypeId`}
          label="Тип источника"
          onChange={handleChange}
          options={isNoise ? noiseSourceTypeId : odorSourceTypeId}
          error={
            isNoise
              ? errors?.points && touched?.points[index]?.noiseSourceTypeId && errors?.points[index]?.noiseSourceTypeId
              : false
          }
        />
        <Field
          as={Select}
          name={`${name}.protocolExecutorUserId`}
          label="Исполнитель"
          error={false}
          options={executors}
          onChange={handleChange}
        />
      </Grid>
      <Grid templateColumns="repeat(4, 1fr)" gap={5} marginTop={5}>
        <GridItem colStart={1} colEnd={3}>
          <Field as={AsyncAddressSelect} name={`${name}.pointAddress`} label="Адрес стройки" error={false} onChange={handleChange} />
        </GridItem>
        {pointTypeId?.find((item) => item.value === values.pointTypeId)?.name !== 'Область обследования' ? (
          <>
            <Field
              as={Input}
              name={`${name}.pointLatitude`}
              label="Широта"
              onChange={handleChange}
              error={errors?.points && touched?.points[index]?.pointLatitude && errors?.points[index]?.pointLatitude}
            />
            <Field
              as={Input}
              name={`${name}.pointLongitude`}
              label="Долгота"
              onChange={handleChange}
              error={errors?.points && touched?.points[index]?.pointLongitude && errors?.points[index]?.pointLongitude}
            />
          </>
        ) : (
          <GridItem colStart={3} colEnd={5}>
            <Field
              as={Input}
              name={`${name}.pointCoordinateArray`}
              label="Массив координат контура"
              onChange={handleChange}
              error={
                errors?.points &&
                touched?.points[index]?.pointCoordinateArray &&
                errors?.points[index]?.pointCoordinateArray
              }
            />
          </GridItem>
        )}
      </Grid>
      <Grid gap={5} templateColumns="repeat(4, 1fr)" marginTop={5}>
        <Field
          as={Select}
          name={`${name}.territoryTypeId`}
          label="Тип территории"
          error={false}
          options={territoryTypeId}
          onChange={handleChange}
        />
        <Field as={Input} name={`${name}.customer`} label="Заказчик" error={false} onChange={handleChange} />
        <Field
          as={Input}
          name={`${name}.generalContractor`}
          label="Генподрядчик"
          error={false}
          onChange={handleChange}
        />
        <Field
          as={Input}
          name={`${name}.constructionObject`}
          label="Объект строительства"
          error={false}
          onChange={handleChange}
        />
      </Grid>
      <Grid marginTop={5} templateColumns="1fr 230px 160px 170px 140px 110px 140px">
        <GridItem>
          <Field as={Input} name={`${name}.workTiming`} label="Сроки работы" error={false} onChange={handleChange} />
        </GridItem>
        <GridItem marginRight={0}>
          <Field
            as={Checkbox}
            name={`${name}.isInformationBoard`}
            checked={values.isInformationBoard}
            label="Информационный щит"
            error={false}
            onChange={handleChange}
          />
        </GridItem>
        <GridItem marginRight={0}>
          <Field
            as={Checkbox}
            name={`${name}.isInformationBoardNMEPD`}
            checked={values.isInformationBoardNMEPD}
            label="Щит ДПиООС"
            error={false}
            onChange={handleChange}
          />
        </GridItem>
        <GridItem marginRight={0}>
          <Field
            as={Checkbox}
            name={`${name}.isWorkDone`}
            checked={values.isWorkDone}
            label="Велись работы"
            error={false}
            onChange={handleChange}
          />
        </GridItem>
        <GridItem marginRight={0}>
          <Field
            as={Checkbox}
            name={`${name}.isViolation`}
            checked={values.isViolation}
            label="Нарушение"
            error={false}
            onChange={handleChange}
          />
        </GridItem>
        <GridItem marginRight={0}>
          <Field
            as={Checkbox}
            name={`${name}.isRepeat`}
            checked={values.isRepeat}
            label="Повтор"
            error={false}
            onChange={handleChange}
          />
        </GridItem>
        <GridItem marginRight={0}>
          <Field
            as={Checkbox}
            name={`${name}.isRenovation`}
            checked={values.isRenovation}
            label="Реновация"
            error={false}
            onChange={handleChange}
          />
        </GridItem>
      </Grid>
      <Grid marginBottom={2} gap={2} templateRows="repeat(2, 1fr)">
        <Grid templateColumns="repeat(2, 1fr)" marginTop={5}>
          <CustomAutocomplete
            value={values.raidResultText}
            name={`${name}.raidResultText`}
            label="Результат рейда"
            options={raidResultId}
          />
          {values.id ? (
            <>
              {noisePointId && taskId && noisePointId ? (
                <Flex marginTop={2}>
                  <Button
                    leftIcon={<DownloadIcon />}
                    variant="ghost"
                    color="PrimaryButton.500"
                    size="sm"
                    p={0}
                    marginLeft={10}
                    onClick={() => onProtocolDownload(noisePointId)}
                  >
                    Скачать протокол
                  </Button>
                  <Button
                    leftIcon={<ViewIcon2 />}
                    variant="ghost"
                    color="PrimaryButton.500"
                    size="sm"
                    p={0}
                    marginLeft={10}
                    onClick={() =>
                      unbindProtocol({}, { url: `appeal/v10/points/${noisePointId}/break/${noiseProtocolId}` })
                    }
                  >
                    Отвязать протокол
                  </Button>
                </Flex>
              ) : (
                <Button
                  leftIcon={<ViewIcon2 />}
                  variant="ghost"
                  color="PrimaryButton.500"
                  size="sm"
                  p={0}
                  marginLeft={10}
                  marginRight="auto"
                  onClick={onProtocolOpen}
                >
                  Привязать протокол
                </Button>
              )}
            </>
          ) : null}
        </Grid>
        {values.pointTypeId === garbageBurningPoint ? (
          <Flex>
            <Field
              as={Checkbox}
              name={`${name}.isGarbagePileFound`}
              label="Обнаружены навалы мусора"
              checked={values.isGarbagePileFound}
              error={false}
            />
            <Field
              as={Checkbox}
              name={`${name}.isBurningSmellFound`}
              label="Обнаружено сжигание мусора"
              checked={values.isBurningSmellFound}
              error={false}
            />
          </Flex>
        ) : null}
        <Field
          as={Textarea}
          name={`${name}.report`}
          checked={values.report}
          label="Комментарий"
          error={false}
          onChange={handleChange}
        />
        <Button
          variant="ghost"
          color="PrimaryButton.500"
          size="sm"
          p={0}
          marginLeft={3}
          marginRight="auto"
          marginTop={5}
          onClick={() => onOpen()}
        >
          Вложения
        </Button>
      </Grid>
      <TaskAttachments
        isOpen={isOpen}
        onClose={onClose}
        objectType={UploadObjectType.REPORT_OBSERVATION_POINT}
        uploadItemId={values.id && values.id}
        media={currentMedia}
        setMedia={setCurrentMedia}
        handleSubmit={() => {
          onMediaSave(currentMedia, index);
        }}
      />
      <Modal
        isOpen={isProtocolsOpen}
        onClose={onProtocolClose}
        width="100%"
        customCloseButton={
          <Button
            onClick={onProtocolClose}
            marginTop={5}
            marginRight="auto"
            variant="ghost"
            color="gray"
            marginLeft="20px"
            leftIcon={<Close />}
          >
            Закрыть
          </Button>
        }
      >
        <Grid width="100%">
          <Heading marginBottom={10}>Привязка протокола</Heading>
          <ReportSetProtocolModal
            customClose={customClose}
            type="Шум"
            pointId={values.id}
            field={`${name}.pelNoiseProtocolId`}
            protocolIdAdder={protocolIdAdder}
          />
        </Grid>
      </Modal>
    </Box>
  );
};
export default NoiseReportObservationPlace;
