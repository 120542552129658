import { createTouchedErrors } from 'features/create-touched-errors';
import { FormikProvider, useFormik } from 'formik';
import { useRequest } from 'hooks/useRequest';
import { useHistory } from 'react-router-dom';
import FieldsForm from 'pages/analytics/forms/BottomSoil/AnalyticsBottomSoilFieldsForm';
import { useCallback, useState } from 'react';
import { updateFiles } from 'api/services/media';
import { IUploadMedia } from 'components/common/FileUploadDragAndDrop/types';
import { useObjectType } from 'hooks/useObjectType';
import { IBottomSoilDTO } from 'pages/data/Water/BottomSoil/consts/types';
import { BottomSoilApiPaths } from 'pages/data/Water/BottomSoil/consts/apiPaths';
import { bottomSoilInitialEmptyValues } from './initialEmptyValues';
import { useEnhancedNotification } from 'hooks';
import { ANALYTICS_LAB_PATHS } from 'pages/analytics/consts/AnalyticsTableRoutes';
import { AnalyticsBottomSoilSchema } from 'models/schemas/analytics/analyticsBottomSoilSchema';
import { useGoBackWithFallback } from 'lib/utils/goBackWithFallback';
import { useDictionary } from 'hooks/useDictionary';

export const AnalyticsBottomSoilAdd = () => {
  const { onSuccessCreate, onErrorCreate } = useEnhancedNotification();
  const { dictionaries, isDictionariesLoading } = useDictionary({ url: BottomSoilApiPaths.dictionaries });
  const { backWithFallback } = useGoBackWithFallback();
  const history = useHistory();
  const [media, setMedia] = useState<IUploadMedia[]>([]);

  const objectType = useObjectType(dictionaries);

  const onSuccess = useCallback(
    (response: IBottomSoilDTO | null) => {
      if (response) {
        onSuccessCreate();

        updateFiles(response.id, objectType, media)
          .then(() => {
            backWithFallback('/analytics');
          })
          .catch((error: Error) => {
            onErrorCreate(error.message);

            history.replace(`${ANALYTICS_LAB_PATHS.water.bottomSoil.edit}/${response.id}`);
          });
      } else {
        onErrorCreate();
      }
    },
    [backWithFallback, history, media, objectType, onErrorCreate, onSuccessCreate],
  );

  const { post } = useRequest<IBottomSoilDTO>(BottomSoilApiPaths.root, onSuccess, onErrorCreate);

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    initialValues: bottomSoilInitialEmptyValues,
    onSubmit: (values) => {
      void post(values);
    },
    validationSchema: AnalyticsBottomSoilSchema,
  });

  return (
    <FormikProvider
      value={{
        ...formik,
        errors: createTouchedErrors(formik.errors, formik.touched),
      }}
    >
      <FieldsForm
        dictionaries={dictionaries}
        onCancel={() => {
          backWithFallback('/analytics');
        }}
        isLoading={isDictionariesLoading}
        onMediaChange={setMedia}
        media={media}
      />
    </FormikProvider>
  );
};
