import React, { useCallback, useMemo, useState } from 'react';
import { DataHeader, Filters } from 'pages/data/MASKSH/Data/components';
import { IFilterValues, Modal } from 'components/common';
import {
  MASKSHApiMeteoPackagesList,
  MASKSHApiPackageMeteo,
  MASKSHInstructions,
  MASKSHMeteo,
  MASKSHMeteoAdd,
  MASKSHMeteoEdit,
  MASKSHMeteoPackages,
} from 'pages/data/MASKSH/consts/menu';
import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import { useMeteoDictionaries } from 'pages/data/MASKSH/Data/Meteo/hooks/useMeteoDictionaries';
import subsystemIdsMap from 'constants/subsystemIdsMap';
import { usePeriodsDict } from 'features/PeriodsButtons/usePeriodsDict';
import { meteoBreadcrumbs } from 'pages/data/MASKSH/Data/const';
import { MaterialTable } from 'features/material-table/MaterialTableMini';
import { TableTabs } from 'pages/data/MASKSH/Data/components/TableTabs';
import { addFiltersToColumns, ColumnWithFilter } from 'lib/utils/tableFilter';
import { IMeteoPackageListItem, tableLocale } from 'pages/data/MASKSH/consts';
import { TableFilterTypeEnum } from 'components/table/filters/TableFilter';
import { DateTimeTableCell } from 'pages/data/MASKSH/Data/components/DateTimeTableCell';
import { Column } from '@material-table/core';
import { useHistory } from 'react-router-dom';
import { useAvailability, useDisclosure, useHandleDeleteData, useList } from 'hooks';
import { paramIds } from './consts';
import { createLocalDateDDMMYYYYWithTime } from 'lib/create-date';
import { COMMON_LOCALE } from 'constants/common';
import { UploadFileModalContent } from 'features/UploadFileDragAndDrop/UploadFileModalContent';
import { DeleteIcon, EditIcon } from 'components/icons';
import { getFilteredQuery } from '../../hooks';
import { useQuery } from 'hooks/useQuery';
import { DEFAULT_PAGE_SIZE_NEW_TABLE } from '../../../../../constants/tables';
import { FullPageWrapper } from '../../../../../components/table/FullPageWrapper';

const MeteoPackages: React.FC = () => {
  const { isExecute, isWrite, isDelete } = useAvailability(subsystemIdsMap.masksh);
  useUpdateBreadcrumbs(meteoBreadcrumbs);
  const query = useQuery();
  const { push } = useHistory();
  const { stations, parameters, objects, isLoading: isDictionariesLoading } = useMeteoDictionaries();

  const [filtersFromGraphic, setFiltersFromGraphic] = useState<Partial<IFilterValues> | null>(null);
  const { result: periods } = usePeriodsDict(`auth/v10/user/period/${subsystemIdsMap.masksh}`);

  const {
    isLoading: isListLoading,
    response,
    setFilters,
    toggleOrder,
    onPageChange,
    filterDTO,
    downloadAsFile,
    onSearchText,
    errorText,
    refetch,
    savedFiltersFromCache,
    selectedIndexes,
    renderOnlySelectedCheckbox,
    onSubmitFilters,
    onSelectionChange,
    materialTableProps,
    saveFilters,
  } = useList<IMeteoPackageListItem, keyof IMeteoPackageListItem>(MASKSHApiMeteoPackagesList, {
    initialOrderFieldKey: '-packageDate',
    exportSubsystemName: 'masksh_meteo',
    preventFirstFetch: true,
    saveFiltersState: true,
    selectionKey: 'packageId',
    defaultPageSize: DEFAULT_PAGE_SIZE_NEW_TABLE,
    resetDataOnNextRequest: true,
  });

  const isLoading = useMemo(() => isDictionariesLoading || isListLoading, [isDictionariesLoading, isListLoading]);

  const { ModalComponent, handleOpenModal } = useHandleDeleteData({ refetch });

  const onRowDelete = useCallback(
    (_, rowData: IMeteoPackageListItem | IMeteoPackageListItem[]) => {
      if (!Array.isArray(rowData)) {
        handleOpenModal({
          url: `${MASKSHApiPackageMeteo}/${rowData.packageId}`,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleOpenModal],
  );

  const columns = useMemo<ColumnWithFilter<IMeteoPackageListItem>[]>(
    () => [
      {
        title: tableLocale.columns.recieved,
        field: 'source',
        filterType: TableFilterTypeEnum.string,
      },
      {
        title: tableLocale.columns.user,
        field: 'user',
        filterType: TableFilterTypeEnum.string,
      },
      {
        title: tableLocale.columns.packageNumber,
        field: 'packageNumber',
        filterType: TableFilterTypeEnum.number,
      },
      {
        title: tableLocale.columns.packageDate,
        field: 'packageDate',
        render: (row) => <DateTimeTableCell datestring={row.packageDate} />,
      },
      {
        title: tableLocale.columns.stationId,
        field: 'stationName',
        filterType: TableFilterTypeEnum.string,
      },
      {
        title: tableLocale.columns.placeId,
        field: 'placeName',
        filterType: TableFilterTypeEnum.string,
      },
      {
        title: tableLocale.columns.instructionNumber,
        field: 'instructionNumber',
        filterType: TableFilterTypeEnum.string,
        render: (row) => (
          <button
            type="button"
            onClick={() => push(`${MASKSHInstructions}/view/${row.instructionId}`, { url: MASKSHMeteo })}
            style={{
              color: 'blue',
            }}
          >
            {row.instructionNumber}
          </button>
        ),
      },
      {
        title: tableLocale.columns.measureDate,
        field: 'measureDate',
        render: (row) => <DateTimeTableCell datestring={row.measureDate} />,
      },
      {
        title: 'Температура воздуха AVG, гр Ц',
        field: `${paramIds.temp}_AVG_val`,
        render: (row) => row.values.find((item) => item.parameterId === paramIds.temp && item.type === 'AVG')?.value,
        filterType: TableFilterTypeEnum.number,
      },
      {
        title: 'Температура воздуха MIN, гр Ц',
        field: `${paramIds.temp}_MIN_val`,
        render: (row) => row.values.find((item) => item.parameterId === paramIds.temp && item.type === 'MIN')?.value,
        filterType: TableFilterTypeEnum.number,
      },
      {
        title: 'Температура воздуха MIN. Дата/время',
        field: `${paramIds.temp}_MIN_dt`,
        render: (row) => {
          const date =
            row.values.find((item) => item.parameterId === paramIds.temp && item.type === 'MIN')?.date || null;
          return createLocalDateDDMMYYYYWithTime(date);
        },
      },
      {
        title: 'Температура воздуха MAX, гр Ц',
        field: `${paramIds.temp}_MAX_val`,
        render: (row) => row.values.find((item) => item.parameterId === paramIds.temp && item.type === 'MAX')?.value,
        filterType: TableFilterTypeEnum.number,
      },
      {
        title: 'Температура воздуха MAX. Дата/время',
        field: `${paramIds.temp}_MAX_dt`,
        render: (row) => {
          const date =
            row.values.find((item) => item.parameterId === paramIds.temp && item.type === 'MAX')?.date || null;
          return createLocalDateDDMMYYYYWithTime(date);
        },
      },
      {
        title: 'Влажность AVG, %',
        field: `${paramIds.moisture}_AVG_val`,
        render: (row) =>
          row.values.find((item) => item.parameterId === paramIds.moisture && item.type === 'AVG')?.value,
        filterType: TableFilterTypeEnum.number,
      },
      {
        title: 'Влажность MIN, %',
        field: `${paramIds.moisture}_MIN_val`,
        render: (row) =>
          row.values.find((item) => item.parameterId === paramIds.moisture && item.type === 'MIN')?.value,
        filterType: TableFilterTypeEnum.number,
      },
      {
        title: 'Влажность MIN. Дата/время',
        field: `${paramIds.moisture}_MIN_dt`,
        render: (row) => {
          const date =
            row.values.find((item) => item.parameterId === paramIds.moisture && item.type === 'MIN')?.date || null;
          return createLocalDateDDMMYYYYWithTime(date);
        },
      },
      {
        title: 'Влажность MAX, %',
        field: `${paramIds.moisture}_MAX_val`,
        render: (row) =>
          row.values.find((item) => item.parameterId === paramIds.moisture && item.type === 'MAX')?.value,
        filterType: TableFilterTypeEnum.number,
      },
      {
        title: 'Влажность MAX. Дата/время',
        field: `${paramIds.moisture}_MAX_dt`,
        render: (row) => {
          const date =
            row.values.find((item) => item.parameterId === paramIds.moisture && item.type === 'MAX')?.date || null;
          return createLocalDateDDMMYYYYWithTime(date);
        },
      },
      {
        title: 'Средняя скорость ветра, м/с',
        field: `${paramIds.windSpeed}_AVG_val`,
        render: (row) =>
          row.values.find((item) => item.parameterId === paramIds.windSpeed && item.type === 'AVG')?.value,
        filterType: TableFilterTypeEnum.number,
      },
      {
        title: 'Скорость ветра MIN, м/с',
        field: `${paramIds.windSpeed}_MIN_val`,
        render: (row) =>
          row.values.find((item) => item.parameterId === paramIds.windSpeed && item.type === 'MIN')?.value,
        filterType: TableFilterTypeEnum.number,
      },
      {
        title: 'Скорость ветра MIN. Дата/время',
        field: `${paramIds.windSpeed}_MIN_dt`,
        render: (row) => {
          const date =
            row.values.find((item) => item.parameterId === paramIds.windSpeed && item.type === 'MIN')?.date || null;
          return createLocalDateDDMMYYYYWithTime(date);
        },
      },
      {
        title: 'Скорость ветра MAX, м/с',
        field: `${paramIds.windSpeed}_MAX_val`,
        render: (row) =>
          row.values.find((item) => item.parameterId === paramIds.windSpeed && item.type === 'MAX')?.value,
        filterType: TableFilterTypeEnum.number,
      },
      {
        title: 'Скорость ветра MAX. Дата/время',
        field: `${paramIds.windSpeed}_MAX_dt`,
        render: (row) => {
          const date =
            row.values.find((item) => item.parameterId === paramIds.windSpeed && item.type === 'MAX')?.date || null;
          return createLocalDateDDMMYYYYWithTime(date);
        },
      },
      {
        title: 'Атмосферное давление, мм.рт.ст',
        field: `${paramIds.atmospherePressure}_ABS_val`,
        render: (row) =>
          row.values.find((item) => item.parameterId === paramIds.atmospherePressure && item.type === 'ABS')?.value,
        filterType: TableFilterTypeEnum.number,
      },
      {
        title: 'Направление ветра, гр. пл. уг.',
        field: `${paramIds.windDirection}_ABS_val`,
        render: (row) =>
          row.values.find((item) => item.parameterId === paramIds.windDirection && item.type === 'ABS')?.value,
        filterType: TableFilterTypeEnum.number,
      },
      {
        title: 'Осадки, мм',
        field: `${paramIds.precipitation}_ABS_val`,
        render: (row) =>
          row.values.find((item) => item.parameterId === paramIds.precipitation && item.type === 'ABS')?.value,
        filterType: TableFilterTypeEnum.number,
      },
    ],
    [push],
  );

  const columnsWithFilters = useMemo<Column<IMeteoPackageListItem>[]>(
    () => addFiltersToColumns({ filters: filterDTO.filters, setFilters, columns }),
    [filterDTO.filters, setFilters, columns],
  );

  const { isOpen, onClose, onOpen } = useDisclosure();

  return (
    <FullPageWrapper>
      <DataHeader
        addPath={`${MASKSHMeteoAdd}/packages`}
        onOpenUpload={onOpen}
        defaultFiltersOpen={!!savedFiltersFromCache}
        isWrite={isWrite}
      >
        <Filters
          periods={periods}
          filters={filterDTO.filters}
          filtersFromGraphic={filtersFromGraphic}
          setFiltersFromGraphic={setFiltersFromGraphic}
          isLoading={isLoading}
          isDisabled={isLoading}
          objects={objects}
          stations={stations}
          parameters={parameters}
          showParameterFilter={false}
          onSubmit={(values) => {
            onSubmitFilters(Object.values(values));
          }}
        >
          {renderOnlySelectedCheckbox}
        </Filters>
      </DataHeader>

      <MaterialTable
        isNotExecute={isExecute}
        topBarLeftContent={
          <TableTabs
            tabs={[
              {
                name: 'Пакеты',
                path: MASKSHMeteoPackages,
                query: getFilteredQuery(query),
              },
              {
                name: 'Значения измерений',
                path: MASKSHMeteo,
                query: getFilteredQuery(query),
              },
            ]}
          />
        }
        data={response?.data}
        meta={response?.meta}
        filterDTO={filterDTO}
        toggleOrder={toggleOrder}
        onPageChange={onPageChange}
        columns={columnsWithFilters}
        actions={[
          {
            position: 'row',
            action: (row: IMeteoPackageListItem) => ({
              icon: () => <EditIcon />,
              onClick: () => push(`${MASKSHMeteoEdit}/packages/${row.packageId}`),
              tooltip: 'Редактировать',
              hidden: !isWrite || !row.isEditable,
            }),
          },
          {
            position: 'row',
            action: (row: IMeteoPackageListItem) => ({
              icon: () => <DeleteIcon />,
              onClick: () => onRowDelete(undefined, row),
              tooltip: 'Удалить',
              hidden: !isDelete || !row.isEditable,
            }),
          },
        ]}
        isLoading={isLoading || isDictionariesLoading}
        options={{
          search: true,
        }}
        onSearch={onSearchText}
        downloadAsFile={downloadAsFile}
        errorText={errorText}
        selectedIndexes={selectedIndexes}
        onSelectionChange={onSelectionChange}
        saveFilters={saveFilters}
        {...materialTableProps}
        showTotalCount
      />

      {ModalComponent}

      <Modal isOpen={isOpen} onClose={onClose} header={COMMON_LOCALE.uploadDataFromFile}>
        <UploadFileModalContent
          createTypesPath={() => `${MASKSHApiPackageMeteo}/load/types`}
          createUploadPath={(typeId) => `${MASKSHApiPackageMeteo}/load?typeId=${typeId}`}
          onClose={onClose}
          refetch={refetch}
        />
      </Modal>
    </FullPageWrapper>
  );
};

export { MeteoPackages };
