import React, { useState } from 'react';
import { COMMON_LOCALE } from 'constants/common';
import { Button, DateInput, Input, Select } from 'components/common';
import { Flex } from '@chakra-ui/react';
import { Field, useFormikContext } from 'formik';
import { Device, Options } from 'pages/equipment/Devices/types';
import { fieldsLocale } from 'pages/equipment/Devices/consts';
import { createLocalDateISO8601 } from 'lib/create-date';
import { AddIcon } from 'components/icons';
import { useEnhancedNotification } from 'hooks';

interface FormFieldsProps {
  onCancel: () => void;
  isDisabled: boolean;
  modelsOptions?: Options;
  statusesOptions?: Options;
  stations?: Options;
  suppliers?: Options
}

export const DeviceFormFields: React.FC<FormFieldsProps> = ({
  onCancel,
  modelsOptions,
  statusesOptions,
  isDisabled,
  stations,
  suppliers
}) => {
  const { onValidationError } = useEnhancedNotification();
  const [isShowStations, setIsShowStations] = useState(false);
  const { handleSubmit, errors, setFieldValue } = useFormikContext<Device>();

  return (
    <Flex flexDirection="column" gap={3}>
      <Field
        as={Input}
        name="deviceName"
        label={fieldsLocale.deviceName}
        error={errors.deviceName}
        isDisabled={isDisabled}
      />
      <Field
        as={Select}
        name="modelIds"
        multiple
        label={fieldsLocale.modelName}
        options={modelsOptions}
        error={errors.modelIds}
      />
      <Field
        as={Select}
        name="supplierId"
        label={fieldsLocale.supplier}
        options={suppliers}
        error={errors.supplierId}
      />
      <Field
        as={Input}
        name="equipmentInvent"
        label={fieldsLocale.inventNumber}
        error={errors.equipmentInvent}
        isDisabled={isDisabled}
      />
      <Field
        as={Input}
        name="equipmentSerial"
        label={fieldsLocale.serialNumber}
        error={errors.equipmentSerial}
        isDisabled={isDisabled}
      />
      <Field as={Select} name="status" label={fieldsLocale.status} options={statusesOptions} error={errors.status} />
      <Field
        as={DateInput}
        name="localDate"
        label={fieldsLocale.localDate}
        error={errors.localDate}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setFieldValue('localDate', createLocalDateISO8601(e.target.value));
        }}
        isDisabled={isDisabled}
      />
      <Field
        as={DateInput}
        name="warrantyEndDate"
        label={fieldsLocale.warrantyEndDate}
        error={errors.warrantyEndDate}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setFieldValue('warrantyEndDate', createLocalDateISO8601(e.target.value));
        }}
      />

      <Button
        leftIcon={<AddIcon />}
        variant="ghost"
        onClick={() => {
          setIsShowStations(true);
        }}
        size="sm"
        p={0}
        alignSelf="start"
      >
        Добавить на станцию
      </Button>
      {
        (isShowStations || isDisabled) && (
          <Field
            as={Select}
            name="stationId"
            label={fieldsLocale.stationName}
            options={stations}
          />
        )
      }
      <Flex>
        <Button
          onClick={() => {
            if (Object.keys(errors).length > 0) {
              onValidationError();
            }
            handleSubmit();
          }}
        >
          {COMMON_LOCALE.save}
        </Button>
        <Button onClick={onCancel} variant="ghost" marginRight={5}>
          {COMMON_LOCALE.cancel}
        </Button>
      </Flex>
    </Flex>
  );
};
