import React from 'react';
import { PageHeader, RoutesList } from 'components/common';
import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import appLocale from 'constants/appLocale';
import { NSIReagentsRootPath } from 'pages/dictionaries/NSIReagents/paths';
import { nsiReagentsMenu } from 'features/WithSideBar/menus/nsiMenu';
import { Flex, Heading } from '@chakra-ui/react';

const NSIReagentsList: React.FC = () => {
  useUpdateBreadcrumbs([{ breadcrumb: appLocale.nsi.reagents.title, path: NSIReagentsRootPath }]);

  const routes =
    nsiReagentsMenu.children?.map(({ url, name }) => ({
      path: url || '',
      breadcrumb: name,
    })) || [];

  return (
    <Flex flexDirection="column">
      <PageHeader>
        <Heading>{appLocale.nsi.reagents.title}</Heading>
      </PageHeader>
      <RoutesList routes={routes} />
    </Flex>
  );
};

export default NSIReagentsList;
