import { Flex, Grid } from '@chakra-ui/react';
import { Checkbox, Input, Select } from 'components/common';
import React, { useEffect } from 'react';
import { Field, FormikProvider, useFormik } from 'formik';
import { IFilterItem, TDictionaryItemDictionary } from 'models';
import FilterFormControls from 'components/form/FilterFormControls';
import combineFilters from 'lib/utils/combineFilters';

// import { TDictionaryItemDictionary } from 'models';

interface IProps {
  setFilters: (filters: any) => void;
  statuses?: TDictionaryItemDictionary[];
  districts?: TDictionaryItemDictionary[];
  regions?: TDictionaryItemDictionary[];
  areaTypes?: TDictionaryItemDictionary[];
  subsystems?: TDictionaryItemDictionary[];
  isLoading: boolean;
  filters: IFilterItem[];
}

const filterInitValues: Record<string, IFilterItem> = {
  name: {
    key: 'name',
    operation: 'CONTAINS',
    value: '',
  },
  okrug: {
    key: 'okrug',
    operation: 'EQ',
    value: '',
  },
  rayon: {
    key: 'rayon',
    operation: 'EQ',
    value: '',
  },
  number: {
    key: 'number',
    operation: 'EQ',
    value: '',
  },
  status: {
    key: 'status',
    operation: 'EQ',
    value: '',
  },
  address: {
    key: 'address',
    operation: 'CONTAINS',
    value: '',
  },
  type: {
    key: 'type',
    operation: 'EQ',
    value: '',
  },
  deleted: {
    key: 'deleted',
    operation: 'EQ',
    value: false,
  },
  subsystem: {
    key: 'subsystemId',
    operation: 'EQ',
    value: null,
  },
};

export const GreenspacesPlatformsFilter = ({
  setFilters,
  statuses,
  areaTypes,
  regions,
  districts,
  subsystems,
  isLoading,
  filters,
}: IProps) => {
  useEffect(() => {
    const initialFilters = combineFilters(filterInitValues, filters);
    Object.keys(initialFilters).forEach((key) => {
      setFieldValue(`${key}.value`, initialFilters[key].value);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = (values: any) => {
    const filters = Object.values(values);
    setFilters(filters);
  };

  const {
    resetForm,
    values,
    errors,
    getFieldMeta,
    getFieldHelpers,
    getFieldProps,
    handleReset,
    submitForm,
    setFieldValue,
    ...formik
  } = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    initialValues: filterInitValues,
    onSubmit,
  });

  return (
    <FormikProvider
      value={{
        resetForm,
        values,
        errors,
        getFieldMeta,
        getFieldHelpers,
        getFieldProps,
        handleReset,
        submitForm,
        setFieldValue,
        ...formik,
      }}
    >
      <Flex>
        <Grid width="100%" maxWidth="1200px" gap={2} templateColumns="repeat(4, 1fr)">
          <Field as={Select} name="subsystem.value" label="Раздел" options={subsystems} />

          <Field as={Input} name="name.value" label="Наименование" />

          <Field as={Select} name="okrug.value" label="Округ" options={regions} />

          <Field as={Select} name="rayon.value" label="Район" options={districts} />

          <Field as={Input} name="address.value" label="Адрес" />

          <Field as={Select} name="type.value" label="Тип территории насаждения" options={areaTypes} />

          <Field as={Select} name="status.value" label="Статус" options={statuses} />

          <Field as={Checkbox} name="deleted.value" label="Показать удаленные" />
        </Grid>
        <FilterFormControls
          onReset={() => {
            resetForm();
            setFilters([{ key: 'deleted', operation: 'EQ', value: false }]);
          }}
          onSubmit={submitForm}
          isLoading={isLoading}
          isDisabled={isLoading}
        />
      </Flex>
    </FormikProvider>
  );
};
