import { IFormattedGraphData, IGraphResponse, IGraphSettings } from 'pages/analyticsReports/CombinedCharts/types';
import { GraphLineOptions } from 'pages/analyticsReports/CombinedCharts/GraphSettingsForm';
import { initialGraphOptions, initialGraphSettingsEmptyValues } from 'pages/analyticsReports/CombinedCharts/const';
import { cloneDeep } from 'lodash';
import { ChartOptions } from 'chart.js';

interface IMakeYAxisOption {
  position: 'left' | 'right'; // TODO: type
  title: string;
  showGridline: boolean;
}
const getDefaultYAxis = ({ position = 'left', title, showGridline }: IMakeYAxisOption) => ({
  type: 'linear',
  display: true,
  position,
  title: {
    text: title,
    display: true,
  },
  gridLines: {
    display: showGridline,
  },
});

export type DatasetAxisIndex = Record<number, number>;

interface IMakeInitialOptions {
  graphResponse: IGraphResponse;
  maxPdkIndex?: number[];
}

export const makeInitialGraphSettings = ({ graphResponse, maxPdkIndex }: IMakeInitialOptions): IGraphSettings => {
  const options: IGraphSettings = cloneDeep(initialGraphSettingsEmptyValues);

  options.title = graphResponse.title;

  graphResponse.datasets.forEach((dataset, index) => {
    options.graphs.push({
      color: graphResponse.colors[index],
      form: GraphLineOptions.simple,
      borderWidth: 2,
      position: index === 0 ? 'left' : 'right',
      text: graphResponse.labels[index],
      maxPdkOption: maxPdkIndex && maxPdkIndex.includes(index) ? false : undefined,
    });
  });

  return options;
};

interface IMakeGraphOptions {
  graphResponse: IGraphResponse;
  graphSettings: IGraphSettings;
  datasetAxisIndex?: DatasetAxisIndex;
  initialOptions?: ChartOptions & { spanGaps?: boolean };
}

export const makeGraphOptions = ({
  graphResponse,
  graphSettings,
  datasetAxisIndex,
  initialOptions,
}: IMakeGraphOptions) => {
  const options: any = cloneDeep(initialOptions || initialGraphOptions);

  options.plugins.title.text = graphSettings.title;

  graphResponse.datasets.forEach((i, index) => {
    const axisIndex = datasetAxisIndex?.[index] || index + 1;
    options.scales[`y${axisIndex}`] = {
      ...getDefaultYAxis({
        position: graphSettings.graphs[index].position,
        title: graphSettings.graphs[index].text,
        showGridline: axisIndex === 1,
      }),
    };
    options.scales.x = {
      ...options.scales.x,
      stacked: true,
      grid: {
        offset: true
      },
      offset: false,
      time: {
        ...options.scales.x.time,
        minUnit: 'minute'
      }
    };
  });

  return options;
};

interface IFormatGraphDataForRender {
  graphResponse: IGraphResponse;
  graphSettings: IGraphSettings;
  datasetAxisIndex?: DatasetAxisIndex;
  maxPdkIndex?: number[];
}
export const formatGraphDataForRender = ({
  graphResponse,
  graphSettings,
  datasetAxisIndex,
  maxPdkIndex,
}: IFormatGraphDataForRender): IFormattedGraphData[] => {
  const formattedGraphData: IFormattedGraphData[] = [];

  graphResponse.datasets.forEach((dataset, index) => {
    formattedGraphData.push({
      label: graphSettings.graphs[index].text,
      data: dataset,
      borderColor: graphSettings.graphs[index].color,
      backgroundColor: graphSettings?.graphs[index].color,
      yAxisID: `y${datasetAxisIndex?.[index] || index + 1}`,
      borderWidth: graphSettings.graphs[index].borderWidth,
      barThickness: graphSettings.graphs[index].barThickness,
      ...getGraphLineOptions(graphSettings.graphs[index]?.form),
    });

    if (maxPdkIndex?.includes(index) && graphSettings?.graphs[index].maxPdkOption) {
      const secondGraphData: any = [];

      dataset.forEach((point) => {
        secondGraphData.push({
          id: point.id,
          x: point.x,
          y: point.y1,
        });
      });

      formattedGraphData.push({
        label: `${graphSettings?.graphs[index].text} ПДК максимальный`,
        data: secondGraphData,
        pointRadius: 0,
        borderColor: 'red',
        backgroundColor: 'red',
        yAxisID: `y${datasetAxisIndex?.[index] || index + 1}`,
        borderWidth: graphSettings.graphs[index].borderWidth,
        barThickness: graphSettings.graphs[index].barThickness,
        ...getGraphLineOptions(graphSettings.graphs[index].form),
      });
    }
  });

  return formattedGraphData;
};

export const getGraphLineOptions = (option: GraphLineOptions): { borderDash?: [number, number]; fill: boolean } => {
  switch (option) {
    case GraphLineOptions.dashed:
      return {
        borderDash: [50, 5],
        fill: false,
      };
    case GraphLineOptions.filled:
      return {
        fill: true,
        borderDash: [0, 0]
      };
    case GraphLineOptions.simple:
      return {
        fill: false,
        borderDash: [0, 0]
      };
  }
};
