import { Button, DateInput, FormWrapper, Input, LoaderItem, Select } from 'components/common';
import { Box, Flex, Grid, Heading } from '@chakra-ui/react';
import { getSelectOptionsFromDictionaryByNameWithTransformation } from 'features/get-select-options-from-dictionary';
import { Field, useFormikContext } from 'formik';
import { AtmosphereMonitoringItem } from 'hooks/atmosphere/Monitoring/useAtmosphereMonitoringList';
import { createDateFromResponse, createDateToRequestWithTimezone } from 'lib/create-date';
import React, { useMemo } from 'react';
import { FieldFormProps } from 'types/forms';
import subsystemIdsMap from 'constants/subsystemIdsMap';
import { useAvailability } from 'hooks';
import isRequiredInSchema from 'lib/utils/forms/isReqiuredInSchema';
import { AtmosphereMonitoringSchema } from 'models/schemas/atmosphere/monitoring';
import { getFilteredOptionsByPrivileges } from 'features/getFilteredOptionsByPrivileges';
import { TDictionaryItemDictionary } from 'models';

const FieldsForm = ({ onCancel, dictionaries, isLoading }: FieldFormProps) => {
  const { isAvailable } = useAvailability(subsystemIdsMap.atmosphere);

  const { handleChange, setFieldValue, values, errors, handleSubmit } = useFormikContext<AtmosphereMonitoringItem>();

  const stationsName = useMemo(
    () => getSelectOptionsFromDictionaryByNameWithTransformation(dictionaries?.data, 'stations', 'name'),
    [dictionaries],
  );

  const paramNumbers = useMemo(
    () => getSelectOptionsFromDictionaryByNameWithTransformation(dictionaries?.data, 'params', 'title'),
    [dictionaries],
  );

  const filteredStationsNames = useMemo(
    () => getFilteredOptionsByPrivileges(stationsName as TDictionaryItemDictionary[], 'isEditable'),
    [stationsName],
  );

  const filteredParamNumbers = useMemo(
    () => getFilteredOptionsByPrivileges(paramNumbers as TDictionaryItemDictionary[], 'isEditable'),
    [paramNumbers],
  );

  if (isLoading) {
    return <LoaderItem />;
  }

  return (
    <Box flexDirection="column">
      <FormWrapper>
        <Grid marginTop="30px" gap="15px">
          <Heading variant="h2">Источник измерения</Heading>
          <Field
            as={Select}
            name="stationId"
            label="Источник"
            value={values.stationId}
            options={filteredStationsNames}
            onChange={handleChange}
            error={errors.stationId}
            isDisabled={!isAvailable}
            isRequired={isRequiredInSchema(AtmosphereMonitoringSchema, 'stationId')}
          />
          <Heading variant="h2">Измеряемый параметр</Heading>
          <Field
            as={Select}
            name="paramId"
            label="Параметр"
            value={values.paramId}
            options={filteredParamNumbers}
            onChange={handleChange}
            error={errors.paramId}
            isDisabled={!isAvailable}
            isRequired={isRequiredInSchema(AtmosphereMonitoringSchema, 'paramId')}
          />
          <Field as={Input} name="unitName" label="Ед. измерения" value={values.unitName} />
          <Heading variant="h2">Результат измерения</Heading>
          <Field
            as={Input}
            name="avg.strValue"
            label="Значение среднее"
            value={values.avg.strValue}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFieldValue('avg.strValue', e.target.value);
            }}
            error={errors.avg?.strValue}
            isDisabled={!isAvailable}
            isRequired={isRequiredInSchema(AtmosphereMonitoringSchema, 'avg.fields.strValue')}
          />
          <DateInput
            name="avg.valDateTime"
            label="Дата"
            value={createDateFromResponse(values.avg.valDateTime)}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setFieldValue('avg.valDateTime', createDateToRequestWithTimezone(e.target.value))
            }
            showTimeInput
            error={errors.avg?.valDateTime}
            isDisabled={!isAvailable}
            isRequired={isRequiredInSchema(AtmosphereMonitoringSchema, 'avg.fields.valDateTime')}
          />

          <Field
            as={Input}
            name="min.strValue"
            label="Значение минимальное"
            value={values.min.strValue}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFieldValue('min.strValue', e.target.value);
            }}
            error={errors.min?.strValue}
            isDisabled={!isAvailable}
            isRequired={isRequiredInSchema(AtmosphereMonitoringSchema, 'min.fields.strValue')}
          />
          <DateInput
            name="min.valDateTime"
            label="Дата"
            value={createDateFromResponse(values.min.valDateTime)}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setFieldValue('min.valDateTime', createDateToRequestWithTimezone(e.target.value))
            }
            showTimeInput
            error={errors.min?.valDateTime}
            isDisabled={!isAvailable}
            isRequired={isRequiredInSchema(AtmosphereMonitoringSchema, 'min.fields.valDateTime')}
          />

          <Field
            as={Input}
            name="max.strValue"
            label="Значение максимальное"
            value={values.max.strValue}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFieldValue('max.strValue', e.target.value);
            }}
            error={errors.max?.strValue}
            isDisabled={!isAvailable}
            isRequired={isRequiredInSchema(AtmosphereMonitoringSchema, 'max.fields.strValue')}
          />
          <DateInput
            name="max.valDateTime"
            label="Дата"
            value={createDateFromResponse(values.max.valDateTime)}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setFieldValue('max.valDateTime', createDateToRequestWithTimezone(e.target.value))
            }
            showTimeInput
            error={errors.max?.valDateTime}
            isRequired={isRequiredInSchema(AtmosphereMonitoringSchema, 'max.fields.valDateTime')}
            isDisabled={!isAvailable}
          />
          <Flex mb={4}>
            <Button onClick={onCancel} marginRight={20}>
              Отмена
            </Button>
            <Button onClick={() => handleSubmit()} variant="solid" disabled={!isAvailable || isLoading}>
              Сохранить
            </Button>
          </Flex>
        </Grid>
      </FormWrapper>
    </Box>
  );
};

export default React.memo(FieldsForm);
