import { UploadObjectType } from 'api/services/media';

interface WPZParameters {
  filthiness: number; // Степень захламления
  densityErosionalPattern: number; // Густота эрозионной сети
  meadowedArea: number; // Площадь залуженных участков
  scrubArea: number; // Площадь кустарниковой растительности
  treeAndScrubArea: number; // Площадь древесной и древесно-кустарниковой растительности
}

export const WPZParameterKeys: Array<keyof WPZParameters> = [
  'filthiness',
  'densityErosionalPattern',
  'meadowedArea',
  'scrubArea',
  'treeAndScrubArea',
];

export interface WPZDto extends WPZParameters {
  isEditable: boolean;
  id: string; // Идентификатор записи
  deleted: boolean; // Признак удаления
  objectType: UploadObjectType | null;
  waterObject: string; // водный объект
  waterObjectId: string | null; // водный объект

  areaCode: string; // Код участка
  areaId: string | null; // Идентификатор участка
  date: string; // Дата обследования
  district: string; // Район
  districtId: string | null; // Идентификатор района
  oopt: string | null; // ООПТ
  region: string | null; // Округ
  regionId: string | null; // Идентификатор округа

  siteConditionId: string | null; // Общее состояние участка водоохранной зоны
  siteCondition: string | null;
}

export interface WPZArea {
  areaCode: string;
  areaName: string;
  district: string;
  id: string;
  oopt: string;
  region: string;
  waterObjectId: string;
}
