import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import { IUseListMaterialTableProps, IWithFileDownload, useHandleDeleteData } from 'hooks';
import { TFilterDTO, TFiltersArray } from 'models';
import { createLocalDateDDMMYYYY } from 'lib/create-date';
import { MaterialTable } from 'features/material-table/MaterialTableMini';
import { GeoecologicalRootPath } from 'pages/data/GeoecologicalProcesses/consts';
import { CellArea, CellPoint, CellValue } from './Cells';
import FilePreviewModalWrapper from 'components/common/FilePreviewModal/FilePreviewModalWrapper';
import useFilePreviewModalWrapperData from 'components/common/FilePreviewModal/hooks/useFilePreviewModalWrapperData';
import { DeleteIcon, EditIcon, ImageIcon } from 'components/icons';
import {
  DangerProcessItem,
  DangerProcessItems,
} from 'pages/data/GeoecologicalProcesses/DangerouslyGeologicalProcesses/DangerProcess/types';
import { Column } from '@material-table/core';
import { addFiltersToColumns, ColumnWithFilter } from 'lib/utils/tableFilter';
import { TableFilterTypeEnum } from 'components/table/filters/TableFilter';
import { templatesObject } from 'constants/templates';
import { PropActions } from 'features/material-table/components/Actions';

interface DangerProcessTableProps {
  dangerProccessList: DangerProcessItems | null;
  refetchCB: () => void;
  toggleOrder: (fieldKey: string) => void;
  onPageChange: (page: number, pageSize: number) => void;
  downloadAsFile: IWithFileDownload;
  setFilters: (filters: TFiltersArray<keyof DangerProcessItem>) => void;
  filterDTO: TFilterDTO;
  filters: TFilterDTO<keyof DangerProcessItem>['filters'];
  onSelectionChange: (checkedRows: DangerProcessItem[]) => void;
  selectedIndexes: number[];
  isLoading: boolean;
  isReadonly?: boolean;
  isExecute?: boolean;
  isWrite?: boolean;
  isNoAction?: boolean;
  allAvailable?: boolean;
  saveFilters: () => void;
  errorText?: string;
  materialTableProps: IUseListMaterialTableProps;
}

export const DangerProcessTable: React.FC<DangerProcessTableProps> = ({
  dangerProccessList,
  refetchCB,
  toggleOrder,
  onPageChange,
  downloadAsFile,
  setFilters,
  filterDTO,
  selectedIndexes,
  onSelectionChange,
  filters,
  isLoading,
  isExecute,
  isWrite,
  isNoAction,
  allAvailable,
  saveFilters,
  errorText,
  materialTableProps,
}) => {
  const history = useHistory();
  const { open, ...filePreviewData } = useFilePreviewModalWrapperData();

  const { ModalComponent, handleOpenModal } = useHandleDeleteData({ refetch: refetchCB });
  const columns = useMemo<ColumnWithFilter<DangerProcessItem>[]>(
    () => [
      {
        title: 'Дата',
        field: 'measuringDate',
        render: (row) => createLocalDateDDMMYYYY(row.measuringDate),
      },
      {
        title: 'Участок',
        field: 'areaCode',
        render: (row) => CellArea(row.areaCode, row.areaId),
        filterType: TableFilterTypeEnum.number,
      },
      {
        title: 'Точка наблюдения',
        field: 'pointCode',
        render: (row) => CellPoint(row.pointCode, row.pointId),
        filterType: TableFilterTypeEnum.number,
      },
      {
        title: 'Описание точки наблюдения',
        field: 'value',
        render: (row) => CellValue(row.value),
        filterType: TableFilterTypeEnum.string,
      },
    ],
    [],
  );

  const columnsWithFilters = useMemo<Array<Column<DangerProcessItem>>>(
    () => addFiltersToColumns({ filters, setFilters, columns }),
    [columns, filters, setFilters],
  );

  const initialActions: PropActions<DangerProcessItem[]> = useMemo(() => (
    [
      {
        position: 'row',
        action: (row: any) => ({
          icon: () => <EditIcon />,
          onClick: () =>
            history.push(`${GeoecologicalRootPath}/monitoring/danger/dangerprocess/edit/row/${row.measuringId}`),
          tooltip: 'Редактировать',
          hidden: !row.isEditable,
        }),
      },
      {
        position: 'row',
        action: (row: any) => ({
          icon: () => <DeleteIcon />,
          onClick: () =>
            handleOpenModal({
              url: `/geoecology/v10/geoecology/dangerprocess/entry/${row.measuringId}`,
            }),
          tooltip: 'Удалить',
          hidden: !row.isEditable,
        }),
      },
      {
        position: 'row',
        action: (row: any) => ({
          icon: () => <ImageIcon />,
          onClick: () => open(row.measuringId, row.objectType),
          tooltip: 'Показать изображения',
          hidden: !row.objectType,
        }),
      },
    ]),
    [handleOpenModal, history, open]);

  // @ts-ignore
  const actions = useMemo<PropActions<any[]>>(() => {
    if (allAvailable || isNoAction || isWrite) {
      return initialActions;
    }
    if (!(allAvailable || isNoAction || isWrite)) {
      return initialActions.filter((action: any) => action.type !== 'delete');
    }
  }, [isWrite, initialActions, isNoAction, allAvailable]);

  return (
    <>
      <MaterialTable
        saveFilters={saveFilters}
        isNotExecute={isExecute || isNoAction}
        columns={columnsWithFilters}
        toggleOrder={toggleOrder}
        onPageChange={onPageChange}
        filterDTO={filterDTO}
        data={dangerProccessList?.data}
        meta={dangerProccessList?.meta}
        actions={actions}
        downloadAsFile={downloadAsFile}
        setFilters={setFilters}
        downloadTemplate={[templatesObject.inspection]}
        onSelectionChange={onSelectionChange}
        selectedIndexes={selectedIndexes}
        isLoading={isLoading}
        errorText={errorText}
        {...materialTableProps}
        showTotalCount
      />
      {ModalComponent}

      <FilePreviewModalWrapper {...filePreviewData} />
    </>
  );
};
