import { useRequest } from 'hooks/useRequest';
import { useEffect } from 'react';
import { MeteoDataApiRoot } from 'pages/data/Meteo/menu';

interface MeteoUploadTypes {
  types: Array<{
    name: string;
    value: string | number;
  }>;
}

export const useMeteoUploadTypes = () => {
  const { get, result, isLoading, error } = useRequest<MeteoUploadTypes>(`${MeteoDataApiRoot}/load/types/2`);

  useEffect(() => {
    get();
  }, [get]);

  return { result, isLoading, error };
};
