import { DateInput, FormWrapper } from 'components/common';
import { Box, Grid } from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import React, { Fragment, useEffect, useMemo } from 'react';
import { FieldFormProps } from 'types/forms';
import subsystemIdsMap from 'constants/subsystemIdsMap';
import { useAvailability } from 'hooks';
import { IncidentRecordingFormSchema } from 'models/schemas/incidentRecording/IncidentRecordingFormSchema';
import { IncidentRecordingItem } from 'pages/IncidentRecording/types';
import appLocale from 'constants/appLocale';
import { IRenderFieldInterface, useFormField } from 'hooks/forms/useFormField';
import isRequiredInSchema from 'lib/utils/forms/isReqiuredInSchema';
import { createDateFromResponse, createUTCTime } from 'lib/create-date';
import { getSelectOptionsFromDictionaryByName } from 'features/get-select-options-from-dictionary';

export const IncidentRecordingFormFields = ({ children, dictionaries }: React.PropsWithChildren<FieldFormProps>) => {
  const { isAvailable } = useAvailability(subsystemIdsMap.dutyOfficer);

  const { setFieldValue, values, errors, initialValues } = useFormikContext<IncidentRecordingItem>();

  const districts = useMemo(
    () => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'districts'),
    [dictionaries],
  );
  const admAreas = useMemo(() => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'admAreas'), [dictionaries]);
  const types = useMemo(
    () => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'types', false, initialValues.incidentTypeId),
    [dictionaries?.data, initialValues.incidentTypeId],
  );
  const sources = useMemo(
    () => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'sources', false, initialValues.sourceTypeId),
    [dictionaries?.data, initialValues.sourceTypeId],
  );

  const { renderField } = useFormField<IncidentRecordingItem>({
    schema: IncidentRecordingFormSchema,
    labelLocalization: appLocale.incidentRecording,
  });

  const districtOptions = districts?.filter((item) => item.parent === values.admAreaId) || [];
  const fields: IRenderFieldInterface<IncidentRecordingItem>[] = [
    {
      name: 'address',
      type: 'input',
      isDisabled: !isAvailable,
    },
    { name: 'admAreaId', type: 'select', options: admAreas, isDisabled: !isAvailable },
    {
      name: 'districtId',
      type: 'select',
      options: districtOptions,
      isDisabled: !isAvailable || !values.admAreaId || districtOptions.length === 0,
    },
    { name: 'incidentTypeId', type: 'select', options: types, isDisabled: !isAvailable },
    { name: 'description', type: 'input', isDisabled: !isAvailable },
    { name: 'actions', type: 'input', isDisabled: !isAvailable },
    { name: 'sourceTypeId', type: 'select', options: sources, isDisabled: !isAvailable },
  ];

  useEffect(() => {
    setFieldValue('districtId', null);
  }, [setFieldValue, values.admAreaId]);

  return (
    <Box flexDirection="column">
      <FormWrapper>
        <Grid marginTop="30px" gap="15px">
          <DateInput
            name="incidentDateTime"
            label={appLocale.incidentRecording.incidentDateTime}
            value={createDateFromResponse(values.incidentDateTime)}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setFieldValue('incidentDateTime', createUTCTime(e.target.value))
            }
            showTimeInput
            error={errors.incidentDateTime}
            isDisabled={!isAvailable}
            isRequired={isRequiredInSchema(IncidentRecordingFormSchema, 'incidentDateTime')}
          />

          {fields.map((field) => (
            <Fragment key={field.name}>{renderField(field)}</Fragment>
          ))}

          {children}
        </Grid>
      </FormWrapper>
    </Box>
  );
};
