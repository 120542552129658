import { TableFilterTypeEnum } from 'components/table/filters/TableFilter';
import { createLocalDateDDMMYYYY, createLocalDateDDMMYYYYWithTime } from 'lib/create-date';
import { ColumnWithFilter } from 'lib/utils/tableFilter';
import { QualityItem } from 'hooks/geo/Quality/useQualityList';
import { DefaultTableCellSize } from 'components/table/DefaultTableCellSize';

const subsoilColumns: ColumnWithFilter<QualityItem>[] = [
  {
    title: '№ участка недр',
    field: 'areaNumber',
    filterType: TableFilterTypeEnum.number,
  },
  {
    title: 'Наименование участка недр',
    field: 'areaName',
    filterType: TableFilterTypeEnum.string,
  },
  {
    title: 'Координата участка X',
    field: 'latitude',
    filterType: TableFilterTypeEnum.number,
  },
  {
    title: 'Координата участка Y',
    field: 'longitude',
    filterType: TableFilterTypeEnum.number,
  },
  {
    title: 'Адрес',
    field: 'address',
    filterType: TableFilterTypeEnum.string,
    render: (row) => <DefaultTableCellSize>{row.address}</DefaultTableCellSize>,
  },
  {
    title: 'Недропользователь',
    field: 'subsoilUserName',
    filterType: TableFilterTypeEnum.string,
    render: (row) => <DefaultTableCellSize>{row.subsoilUserName}</DefaultTableCellSize>,
  },
  {
    title: 'Объём добычи полезного ископаемого',
    field: 'productionVolume',
    filterType: TableFilterTypeEnum.string,
  },
  {
    title: 'Вид полезного ископаемого',
    field: 'mineralResourceType',
    filterType: TableFilterTypeEnum.string,
  },
  {
    title: 'Номер лицензии',
    field: 'licenceNumber',
    filterType: TableFilterTypeEnum.string,
  },
  {
    title: 'Дата регистрации лицензии',
    field: 'licenceRegistrationDate',
    render: (row) => createLocalDateDDMMYYYY(row.licenceRegistrationDate),
  },
  {
    title: 'Дата окончания действия лицензии',
    field: 'licenceEndDate',
    render: (row) => createLocalDateDDMMYYYY(row.licenceEndDate),
  },
  {
    title: 'Статус лицензии',
    field: 'licenceStatus',
    filterType: TableFilterTypeEnum.string,
  },
  {
    title: 'Участок месторождения',
    field: 'fieldAreaName',
    filterType: TableFilterTypeEnum.string,
  },
  {
    title: 'Месторождение',
    field: 'fieldName',
    filterType: TableFilterTypeEnum.string,
  },
  {
    title: 'Инстанция',
    field: 'authorityName',
    filterType: TableFilterTypeEnum.string,
  },
  {
    title: 'Номер протокола',
    field: 'protocolNumber',
    filterType: TableFilterTypeEnum.string,
  },
  {
    title: 'Дата протокола',
    field: 'protocolDate',
    render: (row) => createLocalDateDDMMYYYY(row.protocolDate),
  },
  {
    title: 'Кол-во запасов кат. A',
    field: 'stockA',
    filterType: TableFilterTypeEnum.number,
  },
  {
    title: 'Кол-во запасов кат. B',
    field: 'stockB',
    filterType: TableFilterTypeEnum.number,
  },
  {
    title: 'Кол-во запасов кат. C1',
    field: 'stockC1',
    filterType: TableFilterTypeEnum.number,
  },
  {
    title: 'Кол-во запасов кат. C2',
    field: 'stockC2',
    filterType: TableFilterTypeEnum.number,
  },
  {
    title: 'Дата и время загрузки',
    field: 'dataProcessing',
    render: (row) => createLocalDateDDMMYYYYWithTime(row.dataProcessing),
  },
];

export default subsoilColumns;
