import { IFilterValues } from './types';

const base = 'reporting/v10';
const baseReporting = 'reporting/v10/reporting';
const baseReports = 'reporting/v10/reports';

interface IBaseQuery {
  from?: string;
  to?: string;
  subSystemId: string;
  templateId: string;
}
interface sourcesQuery extends IBaseQuery {
  paramsIds?: string[];
}
interface parametersQuery extends IBaseQuery {
  sourcesIds?: string[];
}

export const apiPaths = {
  device: {
    all: `${baseReports}/device/chart-all`,
    oneById: `${baseReports}/device/chart-by-id`,
    tableById: `${baseReports}/device/table-by-id`,
  },
  station: {
    all: `${baseReports}/station/chart-all`,
    oneById: `${baseReports}/station/chart-by-id`,
    tableById: `${baseReports}/station/table-by-id`,
  },
  reporting: {
    dictionaries: () => `${base}/dictionaries`,
    parameters: ({ subSystemId, templateId }: parametersQuery) =>
      `${baseReporting}/parameters?subSystemId=${subSystemId}&templateId=${templateId}`,
    sources: ({ subSystemId, templateId }: sourcesQuery) =>
      `${baseReporting}/sources?subSystemId=${subSystemId}&templateId=${templateId}`,
    parametersSpecified: ({ subSystemId, templateId, sourcesIds, from, to }: parametersQuery) => {
      let query = getArrayQueryFromFilters({ sources: sourcesIds, params: [] });
      const dateQuery = getDateQueryFromFilters(from, to);
      query = (dateQuery ? query + dateQuery : query).slice(1, -1);
      return `${baseReporting}/valueParameters?subSystemId=${subSystemId}&templateId=${templateId}&${query}`;
    },
    sourcesSpecified: ({ subSystemId, templateId, paramsIds, from, to }: sourcesQuery) => {
      let query = getArrayQueryFromFilters({ sources: [], params: paramsIds });
      const dateQuery = getDateQueryFromFilters(from, to);
      query = (dateQuery ? query + dateQuery : query).slice(1, -1);
      return `${baseReporting}/valueSources?subSystemId=${subSystemId}&templateId=${templateId}&${query}`;
    },
    values: (filters: IFilterValues | null) => {
      let query = getArrayQueryFromFilters(
        {
          sources: filters?.sources,
          params: filters?.parameters,
          registryIds: filters?.registryIds,
          initialData: filters?.initialData,
          valueIds: filters?.valueIds,
        }
      );
      const dateQuery = getDateQueryFromFilters(filters?.startDate, filters?.stopDate);
      query = (dateQuery ? query + dateQuery : query).slice(0, -1);
      return `${baseReporting}/values${query}`;
    },
  }
};

interface IArr {
  sources?: string[];
  params?: string[];
  registryIds?: string[];
  valueIds?: string[];
  initialData?: boolean;
}
function getArrayQueryFromFilters({
  sources = [],
  params = [],
  registryIds = [],
  valueIds = [],
  initialData = false
}: IArr) {
  let query = '?';
  sources.forEach((sourceId) => {
    query += `sourceIds=${sourceId}&`;
  });
  params.forEach((sourceId) => {
    query += `parameterIds=${sourceId}&`;
  });
  registryIds.forEach((registryId) => {
    query += `registryIds=${registryId}&`;
  });
  valueIds.forEach((valueId) => {
    query += `valueIds=${valueId}&`;
  });
  if (initialData) {
    query += `initialData=${initialData}&`;
  }
  return query;
}

function getDateQueryFromFilters(from?: string, to?: string) {
  const startDateQuery = from ? `from=${from}&` : '';
  const stopDateQuery = to ? `to=${to}&` : '';
  return startDateQuery + stopDateQuery;
}
