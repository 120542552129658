import { Field, FormikProvider, useFormik } from 'formik';
import React, { useMemo } from 'react';
import { Checkbox, ContentContainer, DateInput, Input, Select } from 'components/common';
import { Box, Grid, GridItem } from '@chakra-ui/react';
import { DictOptions } from '../../../consts/types';
import { createDateFromResponse, createLocalDateISO8601, createUTCTime } from 'lib/create-date';
import { researchTypeAir, researchTypeNoise } from 'pages/citizenAppeal';
import { createTouchedErrors } from 'features/create-touched-errors';
import { ComplaintSearchSchema } from 'models/schemas/citizenAppeal/complaint';
import FilterFormControls from 'components/form/FilterFormControls';

interface FilterProps {
  isLoading?: boolean;
  isDisabled?: boolean;
  onSubmit: (values: any) => void;
  areas: DictOptions[];
  districts: DictOptions[];
  incidentTypes: DictOptions[];
  setFilters: (filters: any) => void;
  noiseSourceTypeId: DictOptions[];
  odorSourceTypeId: DictOptions[];
}

const initialValues = {
  dateTimeMin: {
    key: 'assignmentExecution',
    operation: 'GTE',
    value: null,
  },
  dateTimeMax: {
    key: 'assignmentExecution',
    operation: 'LTE',
    value: null,
  },
  assignmentDateMin: {
    key: 'assignmentDate',
    operation: 'GTE',
    value: null,
  },
  assignmentDateMax: {
    key: 'assignmentDate',
    operation: 'LTE',
    value: null,
  },
  complaintDateMin: {
    key: 'complaintDate',
    operation: 'GTE',
    value: null,
  },
  complaintDateMax: {
    key: 'complaintDate',
    operation: 'LTE',
    value: null,
  },
  incidentNoiseTypeId: {
    key: 'incidentNoiseTypeId',
    operation: 'EQ',
    value: '',
  },
  incidentAreaId: {
    key: 'incidentAreaId',
    operation: 'EQ',
    value: '',
  },
  incidentDistrictId: {
    key: 'incidentDistrictId',
    operation: 'EQ',
    value: '',
  },
  complaintCitizenFio: {
    key: 'complaintCitizenFio',
    operation: 'CONTAINS',
    value: '',
  },
  complaintStatusId: {
    key: 'complaintStatusId',
    operation: 'EQ',
    value: '',
  },
  complaintCitizenEmail: {
    key: 'complaintCitizenEmail',
    operation: 'EQ',
    value: '',
  },
  complaintCitizenPhone: {
    key: 'complaintCitizenPhone',
    operation: 'EQ',
    value: '',
  },
  complaintCitizenAddress: {
    key: 'complaintCitizenAddress',
    operation: 'CONTAINS',
    value: '',
  },
  assignmentNumber: {
    key: 'assignmentNumber',
    operation: 'EQ',
    value: '',
  },
  complaintNumber: {
    key: 'complaintNumber',
    operation: 'EQ',
    value: '',
  },
  incidentAddress: {
    key: 'incidentAddress',
    operation: 'CONTAINS',
    value: '',
  },
  incidentTypeId: {
    key: 'incidentTypeId',
    operation: 'EQ',
    value: '',
  },
  showDisabled: {
    key: 'deleted',
    operation: 'EQ',
    value: false,
  },
  incidentRenovationSign: {
    key: 'incidentRenovationSign',
    operation: 'EQ',
    value: null,
  },
  incidentNoiseSourceTypeId: {
    key: 'incidentNoiseSourceTypeId',
    operation: 'EQ',
    value: null,
  },
  incidentOdorSourceTypeId: {
    key: 'incidentOdorSourceTypeId',
    operation: 'EQ',
    value: null,
  }
};

export const ComplaintsFilter = ({
  isLoading,
  isDisabled,
  onSubmit,
  areas,
  districts,
  noiseSourceTypeId,
  odorSourceTypeId,
  incidentTypes,
  setFilters
}: FilterProps): JSX.Element => {
  const {
    values,
    errors,
    getFieldMeta,
    getFieldHelpers,
    getFieldProps,
    handleReset,
    submitForm,
    setFieldValue,
    ...formik
  } = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    initialValues,
    validationSchema: ComplaintSearchSchema(),
    onSubmit,
  });

  const sourceTypes = useMemo(() => {
    if (values.incidentTypeId.value === researchTypeNoise) {
      return noiseSourceTypeId;
    }
    if (values.incidentTypeId.value === researchTypeAir) {
      return odorSourceTypeId;
    }
    return [...noiseSourceTypeId, ...odorSourceTypeId];
  }, [noiseSourceTypeId, odorSourceTypeId, values]);

  const resetFilters = (e: any) => {
    handleReset(e);
    const filters = Object.values(initialValues);
    setFilters(filters);
  };

  return (
    <ContentContainer margin={0} padding={0}>
      <FormikProvider
        value={{
          values,
          getFieldMeta,
          getFieldHelpers,
          getFieldProps,
          handleReset,
          submitForm,
          setFieldValue,
          ...formik,
          errors: createTouchedErrors(errors, formik.touched)
        }}
      >
        <Box marginTop={5} marginBottom={3}>
          <Grid marginBottom={1} gap={2} templateRows="repeat(1, 1fr)" templateColumns="repeat(6, 1fr)">
            <GridItem rowStart={1} rowEnd={1} colStart={1} colSpan={1}>
              <p>Дата поручения:</p>
            </GridItem>
            <GridItem rowStart={1} rowEnd={1} colStart={3} colSpan={1}>
              <p>Дата и время обращения:</p>
            </GridItem>
            <GridItem rowStart={1} rowEnd={1} colStart={5} colSpan={1}>
              <p>Срок:</p>
            </GridItem>
          </Grid>
          <Grid marginBottom={1} gap={2} templateRows="repeat(1, 2fr)" templateColumns="repeat(6, 1fr)">
            <GridItem rowStart={1} rowEnd={1} colStart={1} colSpan={1}>
              <Field
                as={DateInput}
                name="assignmentDateMin.value"
                label="От"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setFieldValue('assignmentDateMin.value', createUTCTime(e.target.value));
                }}
              />
            </GridItem>
            <GridItem rowStart={1} rowEnd={1} colStart={2} colSpan={1}>
              <Field
                as={DateInput}
                name="assignmentDateMax.value"
                label="До"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setFieldValue('assignmentDateMax.value', createUTCTime(e.target.value));
                }}
              />
            </GridItem>
            <GridItem rowStart={1} rowEnd={1} colStart={3} colSpan={1}>
              <Field
                as={DateInput}
                showTimeInput
                name="complaintDateMin.value"
                label="От"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setFieldValue('complaintDateMin.value', createUTCTime(e.target.value));
                }}
              />
            </GridItem>
            <GridItem rowStart={1} rowEnd={1} colStart={4} colSpan={1}>
              <Field
                as={DateInput}
                showTimeInput
                name="complaintDateMax.value"
                label="До"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setFieldValue('complaintDateMax.value', createUTCTime(e.target.value));
                }}
              />
            </GridItem>
            <GridItem rowStart={1} rowEnd={1} colStart={5} colSpan={1}>
              <Field
                as={DateInput}
                showTimeInput
                name="dateTimeMin.value"
                label="От"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setFieldValue('dateTimeMin.value', createLocalDateISO8601(e.target.value));
                }}
              />
            </GridItem>
            <GridItem rowStart={1} rowEnd={1} colStart={6} colSpan={1}>
              <Field
                as={DateInput}
                showTimeInput
                name="dateTimeMax.value"
                label="До"
                value={createDateFromResponse(values.dateTimeMax.value)}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setFieldValue('dateTimeMax.value', createLocalDateISO8601(e.target.value));
                }}
              />
            </GridItem>
            <GridItem rowStart={1} rowEnd={1} colStart={7} colSpan={2}>
              <FilterFormControls
                onReset={resetFilters}
                onSubmit={submitForm}
                isLoading={isLoading}
                isDisabled={isDisabled}
              />
            </GridItem>
          </Grid>
          <Grid gap={2} templateRows="repeat(3, 2fr)" templateColumns="repeat(4, 275px)">
            <GridItem rowStart={1} rowEnd={1} colStart={1} colSpan={1}>
              <Field
                as={Input}
                name="assignmentNumber.value"
                label="Номер поручения"
                value={values.assignmentNumber.value}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setFieldValue('assignmentNumber.value', e.target.value);
                }}
              />
            </GridItem>
            <GridItem rowStart={1} rowEnd={1} colStart={2} colSpan={1}>
              <Field
                as={Input}
                name="complaintNumber.value"
                label="Номер обращения"
                value={values.complaintNumber.value}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setFieldValue('complaintNumber.value', e.target.value);
                }}
              />
            </GridItem>
            <GridItem rowStart={1} rowEnd={1} colStart={3} colSpan={1}>
              <Field
                as={Select}
                label="Тема обращения"
                name="incidentTypeId.value"
                options={incidentTypes}
                error={errors?.incidentTypeId?.value}
              />
            </GridItem>
            <GridItem rowStart={1} rowEnd={1} colStart={4} colSpan={1}>
              <Field
                as={Select}
                options={sourceTypes}
                name={values.incidentTypeId.value === researchTypeNoise ?
                  'incidentNoiseSourceTypeId.value'
                  : 'incidentOdorSourceTypeId.value'}
                label="Тип источника"
                error={errors?.incidentNoiseSourceTypeId?.value}
              />
            </GridItem>
            <GridItem rowStart={2} rowEnd={2} colStart={1} colSpan={2}>
              <Field
                as={Input}
                name="complaintCitizenFio.value"
                label="ФИО Заявителя"
                value={values.complaintCitizenFio.value}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setFieldValue('complaintCitizenFio.value', e.target.value);
                }}
              />
            </GridItem>
            <GridItem rowStart={2} rowEnd={2} colStart={3} colSpan={1}>
              <Field
                as={Input}
                name="complaintCitizenEmail.value"
                label="Контакты (aaa@mail.ru)"
                value={values.complaintCitizenEmail.value}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setFieldValue('complaintCitizenEmail.value', e.target.value);
                }}
              />
            </GridItem>
            <GridItem rowStart={2} rowEnd={2} colStart={4} colSpan={1}>
              <Field
                as={Input}
                name="complaintCitizenPhone.value"
                label="Контакты: +7(916) 111 1111"
                value={values.complaintCitizenPhone.value}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setFieldValue('complaintCitizenPhone.value', e.target.value);
                }}
              />
            </GridItem>
            <GridItem rowStart={3} rowEnd={3} colStart={1} colSpan={1}>
              <Field
                as={Input}
                name="complaintCitizenAddress.value"
                label="Адрес заявителя"
                value={values.complaintCitizenAddress.value}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setFieldValue('complaintCitizenAddress.value', e.target.value);
                }}
              />
            </GridItem>
            <GridItem rowStart={3} rowEnd={3} colStart={2} colSpan={1}>
              <Field
                as={Input}
                name="incidentAddress.value"
                label="Адрес происшествия"
                value={values.incidentAddress.value}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setFieldValue('incidentAddress.value', e.target.value);
                }}
              />
            </GridItem>
            <GridItem rowStart={3} rowEnd={3} colStart={3} colSpan={1}>
              <Field as={Select} options={areas} name="incidentAreaId.value" label="Округ" />
            </GridItem>
            <GridItem rowStart={3} rowEnd={3} colStart={4} colSpan={1}>
              <Field as={Select} options={districts} name="incidentDistrictId.value" label="Район" />
            </GridItem>
            <GridItem rowStart={3} rowEnd={3} colStart={5} colSpan={1}>
              <Field
                as={Checkbox}
                checked={values.incidentRenovationSign.value}
                name="incidentRenovationSign.value"
                label="Реновация"
              />
            </GridItem>
          </Grid>
        </Box>
      </FormikProvider>
    </ContentContainer>
  );
};
