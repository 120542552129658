import { FormHeader, LoaderItem } from 'components/common';
import { ERROR_MESSAGES, SUCCESS_MESSAGES } from 'constants/messages';
import { createTouchedErrors } from 'features/create-touched-errors';
import { FormikProvider, useFormik } from 'formik';
import { useNotifications } from 'hooks/useNotifications';
import { useRequest } from 'hooks/useRequest';
import moment from 'moment';

import { useHistory } from 'react-router-dom';
import { ReperSchema } from 'models/schemas/dangerprocess/danger';
import { GeoecologicalRootPath } from 'pages/data/GeoecologicalProcesses/consts';
import FieldsForm from './FieldsForm';
import React, { useCallback, useState } from 'react';
import { ReperItem } from 'hooks/geo/Reper/useGeoReperList';
import { IUploadMedia } from 'components/common/FileUploadDragAndDrop/types';
import { updateFiles } from 'api/services/media';
import { ApiError } from 'models';
import { useObjectType } from 'hooks/useObjectType';
import { formatErrorMessage } from 'lib/utils/formatErrorMessage';
import { useGoBackWithFallback } from 'lib/utils/goBackWithFallback';
import { useDictionary } from 'hooks/useDictionary';

export const initialEmptyValues = {
  absoluteMarkZ: '',
  changeableValue: '',
  geoPSourceType: '',
  geodeticCoordinateX: '',
  geodeticCoordinateY: '',
  id: '',
  regArea: '',
  regSource: '',
  valDateTime: moment().format(),
};

export const AddReperRecord = () => {
  const { backWithFallback } = useGoBackWithFallback();
  const history = useHistory();
  const { isDictionariesLoading, dictionaries } = useDictionary({
    url: '/geoecology/v10/geoecology/reper/dictionaries',
  });
  const objectType = useObjectType(dictionaries);

  const { successNotify, errorNotify } = useNotifications();
  const [media, setMedia] = useState<IUploadMedia[]>([]);

  const onSuccess = (response: ReperItem | null) => {
    if (!response) {
      onError();
      return;
    }

    successNotify({
      key: 'file/create/success',
      message: SUCCESS_MESSAGES.CREATE,
    });

    updateFiles(response.id, objectType, media)
      .then(() => {
        backWithFallback(`${GeoecologicalRootPath}/monitoring/danger/reper`);
      })
      .catch((error: Error) => {
        onError(error.message);
        history.replace(`${GeoecologicalRootPath}/monitoring/danger/reper/edit/row/${response.id}`, response);
      });
  };

  const onError = useCallback(
    (message?: string | null | ApiError) => {
      const msg = formatErrorMessage(message);
      errorNotify({
        key: msg || 'file/create/error',
        message: msg || ERROR_MESSAGES.CREATE,
      });
    },
    [errorNotify],
  );

  const { post, isLoading: isPostLoading } = useRequest<ReperItem>(
    '/geoecology/v10/geoecology/reper',
    onSuccess,
    onError,
  );

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    initialValues: initialEmptyValues,
    validationSchema: ReperSchema,
    onSubmit: (values) => post(values),
  });

  if (isPostLoading || isDictionariesLoading) {
    return <LoaderItem />;
  }

  return (
    <FormikProvider
      value={{
        ...formik,
        errors: createTouchedErrors(formik.errors, formik.touched),
      }}
    >
      <FormHeader
        onBackClick={() => backWithFallback('/data/geoecological-processes/monitoring/danger/reper')}
        header="Добавление результатов измерения"
      />
      <FieldsForm
        dictionaries={dictionaries}
        onSubmit={() => {}}
        onCancel={() => backWithFallback(`${GeoecologicalRootPath}/monitoring/danger/reper`)}
        media={media}
        onMediaChange={setMedia}
      />
    </FormikProvider>
  );
};
