// import { SelectedCheckboxIcon, UnselectedCheckboxIcon } from 'components/icons';
import { createLocalDateDDMMYYYY } from 'lib/create-date';
import { map } from 'lodash';
import { Column } from '@material-table/core';
import { TDictionaryItemDictionary } from 'models';
import { useMemo } from 'react';
import { GreenPlantsAreaLabels } from '../../Form/Tabs/Common/constants';
import { IFloraAreaItem } from '../types';
import { ColumnWithFilter } from 'lib/utils/tableFilter';
import { TableFilterTypeEnum } from 'components/table/filters/TableFilter';
import { DefaultTableCellSize } from 'components/table/DefaultTableCellSize';

type Tmap = TDictionaryItemDictionary<string | (Record<string, any> & string), string | (Record<string, any> & string)>;
type TResult = ColumnWithFilter<IFloraAreaItem>;

export const floraAreaListParametersToColumns = (
  dictionary?: TDictionaryItemDictionary<string | (Record<string, any> & string),
    string | (Record<string, any> & string)>[],
) =>
  map<Tmap, TResult>(dictionary, (item) => ({
    title: item.title,
    field: `${item.parameterId}`,
    render(row) {
      if (item.parameterId) {
        let value = '';
        if (Array.isArray(row.parameters[item.parameterId])) {
          // @ts-ignore
          const values = row.parameters[item.parameterId].map((param) => param.value).filter((val) => val !== 'true' && val !== 'false').join(', ');
          value = values;
        }
        return <DefaultTableCellSize>{value}</DefaultTableCellSize>;
      }
    },
  }));

export const useAreaFloraListColumns = (
  dictionary?: TDictionaryItemDictionary<string | (Record<any, string> & string),
    string | (Record<any, string> & string)>[],
): Array<Column<IFloraAreaItem>> => {
  const extra = useMemo(() => floraAreaListParametersToColumns(dictionary), [dictionary]);

  return useMemo<Array<ColumnWithFilter<IFloraAreaItem>>>(
    () => [
      {
        title: 'Дата обследования',
        field: 'date',
        render: (row) => {
          if (row.date) {
            return createLocalDateDDMMYYYY(row.date);
          }

          return '-';
        },
      },
      {
        title: GreenPlantsAreaLabels.areaNumber,
        field: 'areaNumber',
        render: (row) => (row.areaNumber ? row.areaNumber : '-'),
        filterType: TableFilterTypeEnum.number
      },
      {
        title: 'Округ',
        field: 'okrugName',
      },
      {
        title: 'Район',
        field: 'rayonName',
      },
      {
        title: GreenPlantsAreaLabels.address,
        field: 'address',
        render: (row) => <DefaultTableCellSize>{(row.address ? row.address : '-')}</DefaultTableCellSize>,
      },
      {
        title: 'Тип территории',
        field: 'areaTypeName',
      },
      {
        title: GreenPlantsAreaLabels.landmark,
        field: 'landmark',
        render: (row) => <DefaultTableCellSize>{(row.landmark ? row.landmark : '-')}</DefaultTableCellSize>,
      },
      ...extra,
    ],
    [extra],
  );
};
