import {
  createGreenPlantsAddUrl,
  DeicingGreenPlantsRootPath,
  DeicingStatements,
  GreenPlantsLawnsUrl,
} from 'pages/routes/PGRGreenPlants';
import { GreenPlantsForm } from '../../Form/GreenPlantsForm';
import { greenPlantsFormInitialValues, GreenPlantsFormTypes } from '../../Form/types';
import { useLawnsDictionaries } from '../useLawnsDictionaries';
import { useLawnsAddItem } from './useLawnAddItem';
import { useObjectType } from 'hooks/useObjectType';
import { useCallback, useState } from 'react';
import { IUploadMedia } from 'components/common/FileUploadDragAndDrop/types';
import { useNotifications } from 'hooks';
import { useHistory } from 'react-router-dom';
import { ERROR_MESSAGES } from 'constants/messages';
import { TGreenPlantsForm } from 'pages/data/GreenPlants/Form/types';
import { updateFiles } from 'api/services/media';
import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import appLocale from 'constants/appLocale';
import { ReagentsRootPath } from '../../../../consts';
import { useGoBackWithFallback } from 'lib/utils/goBackWithFallback';

const withAddLawns = (Component: typeof GreenPlantsForm) => () => {
  useUpdateBreadcrumbs([
    { breadcrumb: appLocale.common.data, path: '/data' },
    {
      breadcrumb: appLocale.subsystems.reagents,
      path: ReagentsRootPath,
    },
    {
      breadcrumb: 'Воздействие на зеленые насаждения',
      path: DeicingGreenPlantsRootPath,
    },
    {
      breadcrumb: 'Ведомости',
      path: DeicingStatements,
    },
    {
      breadcrumb: 'Газоны',
      path: `${DeicingStatements}/lawns`,
    },
    {
      breadcrumb: 'Добавление рузультатов',
      path: createGreenPlantsAddUrl('lawns'),
    },
  ]);

  const { backWithFallback } = useGoBackWithFallback();
  const type = 'lawns';

  const defaultIndex = Object.values(GreenPlantsFormTypes).indexOf(type);

  const { addLawns, isLoading } = useLawnsAddItem();

  const { dictionaries, result } = useLawnsDictionaries();
  const objectType = useObjectType(result);
  const [media, setMedia] = useState<IUploadMedia[]>([]);
  const { errorNotify } = useNotifications();
  const { replace } = useHistory();

  const onError = useCallback(
    (message?: string | null) => {
      errorNotify({
        key: message || 'file/create/error',
        message: message || ERROR_MESSAGES.CREATE,
      });
    },
    [errorNotify]
  );

  const onSubmit = useCallback(
    async (values: Partial<TGreenPlantsForm>) => {
      const response = await addLawns(values.lawns);

      if ('registryId' in response) {
        await updateFiles(response.registryId, objectType, media)
          .then(() => {
            backWithFallback(GreenPlantsLawnsUrl);
          })
          .catch((error: Error) => {
            onError(error.message);
            replace(
              `${DeicingGreenPlantsRootPath}/lawns/edit/${response.registryId}?openAttachmentsTab=true`,
              response
            );
          });
      }
    },
    [addLawns, backWithFallback, media, objectType, onError, replace]
  );

  return (
    <Component
      header="Добавление результатов"
      defaultIndex={defaultIndex}
      initialValues={greenPlantsFormInitialValues}
      type={type}
      isSubmitting={isLoading}
      onSubmit={onSubmit}
      lawnsDictionaries={dictionaries}
      media={media}
      setMedia={setMedia}
      backUrl={GreenPlantsLawnsUrl}
    />
  );
};

export { withAddLawns };
