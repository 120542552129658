import { IDayItem, IManagerPeriod } from '../types';
import { Box, CloseButton, Flex, Grid, Spinner, Text } from '@chakra-ui/react';
import DataCard from './DataCard';
import React, { useCallback, useMemo, useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import './carousel.css';

interface IProps {
  title: string
  subtitle?: string
  data?: IDayItem[]
  icon: React.ReactNode
  subsystem: string
  isMeteo?: boolean
  period?: IManagerPeriod
  isLoading?: boolean
  cols?: number
  rowSubtitle?: Partial<Record<IManagerPeriod, string[]>>
}

const ManagerDataPanel = ({
  title, data, icon, subsystem, isMeteo, period, isLoading, subtitle, cols, rowSubtitle
}: IProps) => {
  const [open, setOpen] = useState(false);

  const onCardClick = useCallback(() => {
    setOpen(true);
  }, []);

  const isInversion = useCallback((item: IDayItem) => subsystem === 'meteo' && item.inversion, [subsystem]);

  const inversionItem = useMemo(() => {
    if (subsystem === 'meteo') {
      return data?.find(isInversion) || null;
    }
    return null;
  }, [subsystem, data, isInversion]);

  const renderEmissions = useCallback(() => {
    if (isLoading) {
      return (
        <Flex justifyContent="center" alignItems="center" minHeight="98px">
          <Spinner color="PrimaryButton.500" />
        </Flex>
      );
    }

    if (data && data.length > 0) {
      return (
        <Flex height="100%" minHeight="98px" flexGrow={1} gap="10px" flexDir="column">
          <Text mx="auto" align="center">Превышение на предприятиях</Text>

          <Box
            minHeight={0}
            height="100%"
            width="100%"
          >
            <Carousel
              showIndicators={false}
              showStatus={false}
            >
              {data?.map((val) => (
                <Flex
                  height="100%"
                  justifyContent="center"
                  alignItems="center"
                  background="rgba(0,0,0,0.1)"
                  px="8px"
                  borderRadius="4px"
                >
                  <Text height="max-content" fontWeight="700" fontSize="20px" lineHeight="50px">
                    {val.industryName}
                  </Text>
                </Flex>
              ))}
            </Carousel>
          </Box>
        </Flex>
      );
    }

    return (
      <Flex minHeight="98px" py="20px" flexGrow={1} alignItems="center" justifyContent="center" flexDirection="column">
        <Text fontWeight="700" fontSize="20px" lineHeight="30px">
          НЕТ превышений
        </Text>
      </Flex>
    );
  }, [data, isLoading]);

  const renderCarousel = useCallback(() => {
    if (!inversionItem) return null;

    return (
      <Box height="100%" width="100%">
        <Carousel
          showIndicators={false}
          showStatus={false}
        >
          {inversionItem.values?.map((val) => (
            <Flex
              height="100%"
              justifyContent="center"
              alignItems="center"
              background={val.color}
            >
              {val.value}
            </Flex>
          ))}
        </Carousel>
      </Box>
    );
  }, [inversionItem]);

  const divideIntoChunks = useCallback((chunkSize: number, array?: Array<IDayItem>): Array<IDayItem[] | undefined> => {
    if (!array) return [];

    const chunks: Array<IDayItem>[] = [];

    for (let i = 0; i < array.length; i += chunkSize) {
      const chunk = array.slice(i, i + chunkSize);
      chunks.push(chunk);
    }
    return chunks;
  }, []);

  const renderData = useCallback(() => {
    if (subsystem === 'emissions') {
      return renderEmissions();
    }

    if (!open) {
      if (subsystem === 'air') {
        const _cols = cols || ((data && data.length > 8) || period === 'year' ? 8 : 4);
        const [firstPart, secondPart] = divideIntoChunks(_cols, data);

        return (
          <Flex flexGrow={1} flexDirection="column" gap="8px">
            {period && rowSubtitle ? rowSubtitle[period]?.[0] : null}
            {firstPart && (
              <Grid width="100%" gridTemplateColumns={`repeat(${_cols}, 1fr)`} gridGap="8px">
                {firstPart?.map((item, index) => (
                  <DataCard
                    key={index}
                    item={item}
                    isMeteo={isMeteo}
                  />
                ))}
              </Grid>
            )}
            {period && rowSubtitle ? rowSubtitle[period]?.[1] : null}
            {secondPart && (
              <Grid width="100%" gridTemplateColumns={`repeat(${_cols}, 1fr)`} gridGap="8px">
                {secondPart?.map((item, index) => (
                  <DataCard
                    key={index}
                    item={item}
                    isMeteo={isMeteo}
                  />
                ))}
              </Grid>
            )}
          </Flex>
        );
      }

      const gridTemplateColumns = subsystem === 'water' ? 'repeat(6, 1fr)' : 'repeat(4, 1fr)';

      return (
        <Flex flexGrow={1} flexDirection="column" gap="8px">
          {period && rowSubtitle ? rowSubtitle[period]?.[0] : null}
          <Grid width="100%" gridTemplateColumns={gridTemplateColumns} gridGap="8px">
            {data?.map((item, index) => (
              <DataCard
                onClick={isInversion(item) && item.values ? onCardClick : undefined}
                key={index}
                item={item}
                isMeteo={isMeteo}
              />
            ))}
          </Grid>
        </Flex>
      );
    }

    if (open) {
      return (
        <Flex flexGrow={1} gap="10px">
          {renderCarousel()}
        </Flex>
      );
    }

    return null;
  }, [
    data,
    subsystem,
    period,
    isInversion,
    isMeteo,
    onCardClick,
    open,
    renderCarousel,
    renderEmissions,
    cols,
    divideIntoChunks,
    rowSubtitle
  ]);

  const content = useMemo(() => renderData(), [renderData]);

  return (
    <Flex
      width="100%"
      flexDirection="column"
      boxShadow="0px 5px 10px rgba(0, 0, 0, 0.1)"
      borderRadius="4px"
      p="10px"
      minHeight="100%"
    >
      <Flex justifyContent="space-between" alignItems="center" mb="20px">
        <Flex flexDirection="column">
          <Flex gap="16px" alignItems="center">
            {icon}
            <Text fontWeight="700" fontSize="18px" lineHeight="30px">{title}</Text>
          </Flex>

          {subtitle && (
            <Text mt="12px" width="100%" align="left" fontWeight="500" fontSize="14px" lineHeight="18px" height="18px">{subtitle}</Text>
          )}
        </Flex>

        {open && (
          <CloseButton onClick={() => setOpen(false)} />
        )}
      </Flex>

      {content}
    </Flex>
  );
};

export default ManagerDataPanel;
