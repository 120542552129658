import { Flex } from '@chakra-ui/react';
import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import appLocale from 'constants/appLocale';

const Cartography = () => {
  useUpdateBreadcrumbs([{ breadcrumb: appLocale.subsystems.cartography, path: '/cartography' }]);

  return (
    <Flex height="100%">
      <iframe title="map" width="100%" height="100%" src={process.env.REACT_APP_CARTOGRAPHY_MAP_URL} />
    </Flex>
  );
};

export default Cartography;
