import { IFormikPointValues, IPoint, IPointValue } from './types';
import { TDictionaryItemDictionary } from 'models';

const dictToData = (dictItem: TDictionaryItemDictionary) => ({
  name: dictItem?.name,
  parameterId: dictItem?.value,
  type: dictItem?.type,
  value: null,
});

export const pointDynamicValuesCreator = (dictionary: any, index: number = 0) => ({
  left: dictToData(dictionary.find((dictItem: TDictionaryItemDictionary) => dictItem.value === '77976cf9-8db5-4c4a-a476-35a5b1b978cb')), // Эквивалентный шум
  right: dictToData(dictionary.find((dictItem: TDictionaryItemDictionary) => dictItem.value === 'f795cab4-7032-4f65-b588-e110f8f9ab23')), // Максимальный шум
  index,
});

// айди должны идти по порядку
export const extraParametersIds = {
  // Измеренные значения
  dynamic: [
    '062f8de1-b31c-491c-884a-5c633c545566',
    '7f782cb9-8942-4c19-84f2-30c6ec056e95',
    'cd9abf39-be72-4cfa-aa29-4fd124e70173',
    '84c3fad7-a85d-4854-95ac-070759f1cfe5',
    '5a042222-c4c0-42db-8df7-c1e283be06dc',
    '80272ad3-40dd-495c-86e4-3aca0d3b4e23',
    '67d65437-a499-44ce-ae90-92ae1b8d0b46',
    '3401c2d5-b30b-4f07-bc65-ff50420d817a',
    '1c5fc322-f090-47b9-a883-e82ec93e2f18',
    '98ab6e75-a493-4791-9efa-a717aa5cae4e',
  ],
  // Средний уровень звука и звукового давления
  averageSoundAndPressure: [
    'bdea5073-f4c8-487c-a5c1-3e98ab4ebdff',
    'de4b3cef-d273-41b9-b49d-e4accb40fdae',
    '377cffeb-24c2-4db2-acac-d6e39b5ecb4d',
    '0167c510-eb43-48d6-9b91-1cc1feb9e962',
    '4abd8849-78fa-49eb-8ef0-432d2f0f0a4a',
    '89c262c0-4ae3-419b-8d2b-f100d5fd4517',
    'f735f369-4e78-4fc0-b105-4270392ad9fc',
    'b08761e6-3b7f-45d5-a446-c2f6c74f1f08',
    '8215dd23-4303-452e-b89c-9675fe372748',
    '418b1d53-81c5-4187-b209-27a9b06d0e67',
  ],
  // Коррекции (К1, К2, К3, К4, К5)
  correction: [
    '0128f49e-802d-4a22-af72-7d731b155fbb',
    '13fe9de1-b9a3-4584-9b29-a1a7172b1647',
    '21fed257-1d4d-41f9-82c5-339305082650',
    'd9dab7e4-1051-4b4a-b004-7d01515e3a47',
    'bf1f4ef8-6752-4931-ba55-1290c6260969',
    '78b84497-b916-4763-90f6-a05fdb7cd3da',
    'dc530904-58e6-4cab-8c77-781a1f5fc223',
    '74d869bf-e497-4619-8277-c3f717df625f',
    '32235d8c-ddb2-4976-a14c-ee34e1ed5b7b',
    '17b2a95e-9cfa-4304-a737-df4dab3bcc42',
  ],
  // Откорректированные уровни звукового давления и уровень звука
  correctedSoundAndPressure: [
    'f9f9e596-b98e-4bbf-a929-0a053ab1355a',
    '97d3151c-64d4-42db-945d-2d69d45221d1',
    '31e63649-a66c-4bcf-809d-81ae1a7ec9c5',
    '3b32a2f7-a3df-4abf-ae81-d5c91f677107',
    '74d7684c-6983-470d-be8b-e1b7cdcb22b7',
    '6b8ab224-c8d3-4b1f-86f0-67a9fb1f5f39',
    '4fef0dea-fffd-4458-983d-c5718d4c5e5f',
    '6d38d29c-e305-441d-adab-ddf75f57198e',
    '0c8e4965-338e-4d1c-8c40-0af41d4ade5b',
    'ee7de451-1c40-414e-8d31-932c00b33213',
  ],
  // Расширенная неопределенность
  ambiguity: [
    'a033ee21-5451-4b00-a149-c97ebecbf5da',
    '7a3f8f15-5514-402d-ba92-9b96f6d8f3e4',
    '4659f2e5-1aa8-468e-9b9c-b789fa845d4f',
    'b9d92cb6-c0e7-4300-9604-a3a7b4c4d164',
    '026b0ef0-1b86-43de-a168-89eb6ba4b9e3',
    '115b2e2c-801d-493e-b7cb-bca60b256647',
    'e006d477-968f-42b3-be2c-a537cc162be9',
    '18af8ebc-1bed-4c62-9ed9-928485bb433f',
    '8c8960e7-5481-4315-9b44-bb9f8990af26',
    'a85d3ea8-9d7a-4be0-82de-6d78db2567d4',
  ],
  // Оценочные уровни звукового давления и уровень звука
  estimated: [
    'e5583d78-4d28-42b6-be6f-f41f1bb7217a',
    '6698aee3-2f90-4188-b89c-a81d1ed0ba7b',
    '6776886f-f854-4c80-8949-09b50a6a7592',
    '8664214d-2b3c-4bee-99a2-2b2018d6da42',
    '004fcde3-4d41-49f4-bdeb-dcee3b63748b',
    '23303f19-cd5b-43cc-88d1-6e9b87423e72',
    'd917fd99-5f1b-4254-8faf-4098253cc1ef',
    '8db38d35-35b2-4f74-a0a3-a0a556502c1c',
    'e37af25d-63d1-42d7-a8e9-5310a0b36e9f',
    '0fbdc8b2-7aaf-45e3-9e59-9d4abdbc176f',
  ],
  // Уровни звукового давления и звука по СанПиН 1.2.3685-21 (табл. 5.35)
  sanPin: [
    '3c87518c-6ea9-49ce-8040-1fe3a578ca39',
    '21b845d8-2a13-410e-a4cb-6bc55cfe2d24',
    '719f6d03-7dfa-4a04-a224-7457cf2f3b36',
    'f0f11498-b60e-4319-9840-a6623f54a822',
    '9ea2a6bc-9e9c-4b7a-a6e9-fa11c3f0167c',
    '72477652-af6c-4a00-928c-0381c7e9fd8f',
    '12d35859-0912-4d0e-a12e-8c4057503ea3',
    'd3f0d1f2-beba-4770-a6db-eeb665720dcd',
    'ee9fc33c-4e46-481a-af7f-4312832cb93c',
    'd185e15a-2bd3-4480-afe1-b1d106558753',
  ],
};

export const pointCreator = (dictionary: TDictionaryItemDictionary[], pointNumber: number = 1, point?: IPoint) => {
  const pointValues: IFormikPointValues = {
    correctionEq: {},
    correctionMax: {},
    correctedEq: {},
    correctedMax: {},
    extendedEq: {},
    extentedMax: {},
    estimatedEq: {},
    estimatedMax: {},
    admissibleEq: {},
    admissible: {},
    averageEq: {},
    averageMax: {},
  };

  // TODO: переделать построение параметров!
  (point?.values || dictionary)?.forEach((item: TDictionaryItemDictionary | IPointValue) => {
    switch (item.parameterId || item.value) {
      case 'cc072818-a0f4-4bb9-9c14-12ec921f351c': // Коррекции (К1,К2,К3,К4,К5) (эквивалентный уровень звука)
        pointValues.correctionEq = point ? item : dictToData(item);
        break;
      case '3dada458-8fdc-4aae-80c4-4fc23d12557d': // Коррекции (К1,К2,К3,К4,К5) (максимальный уровень звука)
        pointValues.correctionMax = point ? item : dictToData(item);
        break;
      case '325eae9c-0b86-4d47-a38e-de29f6f8d547': // Откорректированный средний уровень эквивалентного уровня звука
        pointValues.correctedEq = point ? item : dictToData(item);
        break;
      case '6d0eac71-d3ce-470b-9200-daeedc5b3729': // Откорректированный средний уровень максимального уровня звука
        pointValues.correctedMax = point ? item : dictToData(item);
        break;
      case '59550119-73d0-46d4-9974-ef5ff5e4f3f8': // Расширенная неопределенность (максимальный уровень звука)
        pointValues.extentedMax = point ? item : dictToData(item);
        break;
      case 'df8d55a3-12ac-434e-840c-728966b25484': // Расширенная неопределенность (эквивалентный уровень звука)
        pointValues.extendedEq = point ? item : dictToData(item);
        break;
      case 'e62325b3-de78-4a22-9f67-9a22b3034e46': // Оценочный уровень звука (эквивалентный уровень звука)
        pointValues.estimatedEq = point ? item : dictToData(item);
        break;
      case '27446dba-10a8-4a29-b4c2-dbe3e843bbe1': // Оценочный уровень звука (максимальный уровень звука)
        pointValues.estimatedMax = point ? item : dictToData(item);
        break;
      case '2eaa9392-bb7d-4c25-ac20-fa843ed131e1': // Допустимые уровни звука (эквивалентный уровень звука)
        pointValues.admissibleEq = point ? item : dictToData(item);
        break;
      case '5b520dab-2c15-45a4-a326-53a22ed3404d': // Допустимые уровни звука (максимальный уровень звука)
        pointValues.admissible = point ? item : dictToData(item);
        break;
      case '56db952b-7867-41a8-8431-32361693a583': // Средний по измеренным значениям (эквивалентный уровень звука)
        pointValues.averageEq = point ? item : dictToData(item);
        break;
      case 'c371617f-1043-4cf7-bd83-fd849ebb2ba8': // Средний по измеренным значениям (максимальный уровень звука)
        pointValues.averageMax = point ? item : dictToData(item);
        break;
    }
  });

  return {
    endDatetime: point?.endDatetime || null,
    id: point?.id || null,
    pointNumber: point?.pointNumber || pointNumber,
    startDatetime: point?.startDatetime || null,
    values: pointValues,
    dynamicValues: point?.dynamicValues || [pointDynamicValuesCreator(dictionary)],
    dynamicExtraValues: (point?.dynamicExtraValues && point?.dynamicExtraValues.length > 0) ?
      point.dynamicExtraValues : [{
        index: 1,
        extraValues: [],
      }],
    extraValues: point?.extraValues || []
  };
};
