import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import { WaterRouteRoot, WaterRouteStateRoot } from '../routes';
import appLocale from 'constants/appLocale';

export const WaterState = () => {
  useUpdateBreadcrumbs([
    { breadcrumb: 'Данные', path: '/data' },
    {
      breadcrumb: 'Водные объекты',
      path: WaterRouteRoot,
    },
    {
      breadcrumb: appLocale.subsystems.wpz,
      path: WaterRouteStateRoot,
    },
  ]);
  return <div>State</div>;
};
