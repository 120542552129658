import * as React from 'react';
import { useCallback, useMemo, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { Icon, IconButton, Stack } from '@chakra-ui/react';
import DatePicker, { registerLocale } from 'react-datepicker';
import ru from 'date-fns/locale/ru';
import { ReactComponent as Calendar } from 'components/icons/calendar.svg';
import { Input } from 'components/common';
import moment from 'moment';
import { ArrowDownIcon } from 'components/icons';
import { Select } from '@chakra-ui/select';
import { FormikFieldProps } from '../types';
import { DateInputContainer } from './styles';
import CustomTimeInput from 'components/common/DateInput/components/CustomTimeInput';
import { SelectClearIcon } from 'components/common/Select/components';
// @ts-ignore
import buildLocalizeFn from 'date-fns/locale/_lib/buildLocalizeFn';
import { monthValues } from './monthsLocale';
import { createDateToRequestForFilter } from 'lib/create-date';

// @ts-ignore
ru.localize.month = buildLocalizeFn({
  values: monthValues,
  defaultWidth: 'wide',
  defaultFormattingWidth: 'wide',
});

registerLocale('ru', ru);

export interface DateInputProps {
  label: string;
  showTimeInput?: boolean;
  showTimeInputWithSeconds?: boolean;
  isDisabled?: boolean;
  showYearPicker?: boolean;
  minDate?: Date;
  maxDate?: Date;
  isMonthPicker?: boolean;
  isRequired?: boolean;
  dateFieldTo?: string;
  dateFieldFrom?: string;
}

export const DateInput: React.FC<DateInputProps & Partial<FormikFieldProps>> = ({
  isDisabled,
  name,
  onChange,
  value,
  label,
  showTimeInput,
  showTimeInputWithSeconds,
  error,
  onBlur,
  showYearPicker,
  minDate,
  maxDate,
  isMonthPicker,
  isRequired,
  dateFieldFrom,
  dateFieldTo,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const handleChange = useCallback(
    (innerValue: Date | null) => {
      let value = innerValue ? moment(innerValue)
        .format() : null;
      if (!showTimeInput && !showTimeInputWithSeconds) {
        setOpen(false);

        if (name === dateFieldFrom) {
          value = createDateToRequestForFilter(value);
        }
        if (name === dateFieldTo) {
          value = createDateToRequestForFilter(value, 'end');
        }
      }

      if (onChange) {
        onChange({ target: { value, name } } as React.BaseSyntheticEvent);
      }
    },
    [name, onChange, showTimeInput, showTimeInputWithSeconds, dateFieldTo, dateFieldFrom],
  );

  const onTimeChange = (date: Date, time: string) => {
    const [hh, mm, ss] = time.split(':');
    const targetDate = date instanceof Date ? date : new Date();
    targetDate.setHours(Number(hh) || 0, Number(mm) || 0, (showTimeInputWithSeconds && Number(ss)) || 0);
    handleChange(targetDate);
  };

  let dateFormat = 'dd.MM.yyyy';
  if (showTimeInput) {
    dateFormat = 'dd.MM.yyyy HH:mm';
  }
  if (showTimeInputWithSeconds) {
    dateFormat = 'dd.MM.yyyy HH:mm:ss';
  }
  if (showYearPicker) {
    dateFormat = 'yyyy';
  }
  if (isMonthPicker) {
    dateFormat = 'MMM';
  }

  const selectedDate = React.useMemo(() => {
    if (!value) return null;

    const m = moment(value);

    if (m.isValid()) {
      return m.toDate();
    }

    return null;
  }, [value]);

  const rangeOfYears = (start: number, end: number) =>
    Array(end - start + 1)
      .fill(start)
      .map((year, index) => year + index);
  const years = rangeOfYears(
    minDate ? new Date(minDate).getFullYear() : 2000,
    maxDate ? new Date(maxDate).getFullYear() : new Date().getFullYear() + 1,
  );

  const inputMask = useMemo(() => {
    if (isMonthPicker) {
      return undefined;
    }
    if (showYearPicker) {
      return '9999';
    }
    if (showTimeInputWithSeconds) {
      return '99.99.9999 99:99:99';
    }
    if (showTimeInput) {
      return '99.99.9999 99:99';
    }

    return '99.99.9999';
  }, [isMonthPicker, showTimeInput, showTimeInputWithSeconds, showYearPicker]);

  const todayDate = useMemo(() => {
    const currentDate = moment()
      .startOf('day')
      .format();
    return new Date(currentDate);
  }, []);

  const todayButtonDisabled = useMemo<boolean>(() => {
    if (minDate && maxDate) {
      const lessThenMin = todayDate.getTime() < new Date(minDate).getTime();
      const moreThenMax = todayDate.getTime() > new Date(maxDate).getTime();
      return lessThenMin || moreThenMax;
    }

    return false;
  }, [minDate, maxDate, todayDate]);

  return (
    <DateInputContainer>
      <DatePicker
        minDate={minDate ? new Date(minDate) : null}
        maxDate={maxDate ? new Date(maxDate) : null}
        onBlur={onBlur}
        name={name}
        id={`data-picker-for-${name}`}
        onChange={handleChange}
        showTimeInput={showTimeInput || showTimeInputWithSeconds}
        customTimeInput={(
          <CustomTimeInput
            onTimeChange={onTimeChange}
            dateValue={selectedDate}
            showTimeInputWithSeconds={showTimeInputWithSeconds}
          />
        )}
        selected={selectedDate}
        locale="ru"
        popperClassName={showYearPicker ? '' : 'month'}
        dateFormat={dateFormat}
        disabled={isDisabled}
        open={open}
        onYearChange={handleChange}
        onClickOutside={() => setOpen(false)}
        onInputClick={() => setOpen(true)}
        showYearPicker={showYearPicker}
        showMonthYearPicker={isMonthPicker}
        showTwoColumnMonthYearPicker={isMonthPicker}
        renderCustomHeader={
          showYearPicker
            ? undefined
            : ({ date, changeYear, decreaseMonth, increaseMonth, prevMonthButtonDisabled, nextMonthButtonDisabled }) =>
            (isMonthPicker ? null : (
              <div
                style={{
                  margin: 10,
                  height: 40,
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                }}
              >
                <button
                  type="button"
                  onClick={decreaseMonth}
                  disabled={prevMonthButtonDisabled}
                  className="react-datepicker__navigation react-datepicker__navigation--previous"
                />

                <span
                  style={{
                    textTransform: 'capitalize',
                    fontWeight: 500,
                    marginRight: 5,
                    marginLeft: 16,
                  }}
                >
                  {date.toLocaleString('ru', { month: 'long' })}
                </span>
                <Select
                  value={date.getFullYear()}
                  onChange={({ target: { value } }) => {
                    const newDate = moment(selectedDate || undefined)
                      .set('year', parseInt(value));
                    if (selectedDate === null) {
                      newDate.set({ hours: 0, minutes: 0, seconds: 0 });
                    }
                    changeYear(parseInt(value));
                    setTimeout(() => handleChange(new Date(newDate.format())), 0);
                  }}
                  variant="unstyled"
                  width="fit-content"
                  color="select.border.primary"
                  icon={<ArrowDownIcon />}
                  iconSize="16"
                  focusBorderColor="select.border.primary"
                >
                  {years.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </Select>
                <button
                  style={{ top: 5 }}
                  onClick={() => {
                    handleChange(todayDate);
                    setOpen(false);
                    // HACK: sometimes class react-datepicker-ignore-onclickoutside is not removed from input
                    // and datepicker doesn't close when clicking on other date input
                    const className = 'react-datepicker-ignore-onclickoutside';
                    const list = document.getElementsByClassName(className);
                    for (let i = 0; i < list.length; i++) {
                      list[i].className = list[i].className.replace(className, '');
                    }
                  }}
                  type="button"
                  className="react-datepicker__today-button"
                  disabled={todayButtonDisabled}
                >
                  Сегодня
                </button>
                <button
                  type="button"
                  onClick={increaseMonth}
                  disabled={nextMonthButtonDisabled}
                  className="react-datepicker__navigation react-datepicker__navigation--next"
                />
              </div>
            ))
        }
        autoComplete="off"
        customInput={(
          <Input
            aria-autocomplete="none"
            mask={inputMask}
            label={label}
            error={error}
            isClearable={false}
            isRequired={isRequired}
            InputRightElement={(
              <Stack mr={-2} direction="row" spacing={0} alignItems="center">
                {value !== null && value !== undefined && !isDisabled && (
                  <IconButton
                    aria-label="clear-icon"
                    variant="ghost"
                    minW={8}
                    maxW={8}
                    minH={8}
                    maxH={8}
                    p={1}
                    isDisabled={isDisabled}
                    onClick={(e) => {
                      e.stopPropagation();

                      handleChange(null);
                    }}
                  >
                    <SelectClearIcon clearColor="select.clear.default" />
                  </IconButton>
                )}
                <IconButton
                  aria-label="calendar-icon"
                  variant="ghost"
                  minW={8}
                  maxW={8}
                  minH={8}
                  maxH={8}
                  onClick={() => setOpen(true)}
                  isDisabled={isDisabled}
                >
                  <Icon minW={5} maxW={5} minH={5} maxH={5} as={Calendar} fill="icon.secondary" />
                </IconButton>
              </Stack>
            )}
          />
        )}
        todayButton={null}
      />
    </DateInputContainer>
  );
};
