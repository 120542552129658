import { BottomSoilParametersKeys } from 'pages/data/Water/BottomSoil/consts/types';
import { ANALYTICS_STATUS } from 'pages/analytics/forms/const';

export const bottomSoilInitialEmptyValues = {
  protocolNumber: '',
  protocolDate: null,
  consumerId: null,
  researchBasis: '',
  researchObject: '',
  supportingDocuments: '',
  sampleNumber: '',
  address: '',
  samplePlace: '',
  sampleDate: null,
  organizationId: null,
  explanationFromConsumer: '',
  sampleReceiveDate: null,
  researchDateStart: null,
  researchDateEnd: null,
  sampleLaboratoryNumber: '',
  explanation: '',
  status: ANALYTICS_STATUS.DRAFT,
  laboratory: true,
  sign: null,
  links: null,

  ...BottomSoilParametersKeys.reduce((obj, key) => Object.assign(obj, { [key]: null }), {}),
};
