import { BottomSoilParametersKeys, IBottomSoilDTO } from 'pages/data/Water/BottomSoil/consts/types';
import appLocale from 'constants/appLocale';
import { createLocalDateDDMMYYYY } from 'lib/create-date';
import { ColumnWithFilter } from 'lib/utils/tableFilter';
import { TableFilterTypeEnum } from 'components/table/filters/TableFilter';

export const bottomSoilTableColumns: Array<ColumnWithFilter<IBottomSoilDTO>> = [
  {
    title: appLocale.bottomSoil.protocolNumber,
    field: 'protocolNumber',
    filterType: TableFilterTypeEnum.string,
  },
  {
    title: appLocale.bottomSoil.sampleDate,
    field: 'sampleDate',
    render: (row) => createLocalDateDDMMYYYY(row.sampleDate),
  },
  {
    title: appLocale.bottomSoil.waterObject,
    field: 'waterObject',
    filterType: TableFilterTypeEnum.string,
  },
  {
    title: appLocale.bottomSoil.source,
    field: 'source',
    filterType: TableFilterTypeEnum.string,
  },
  {
    title: appLocale.bottomSoil.region,
    field: 'region',
    filterType: TableFilterTypeEnum.string,
  },
  {
    title: appLocale.bottomSoil.district,
    field: 'district',
    filterType: TableFilterTypeEnum.string,
  },
  {
    title: appLocale.bottomSoil.organizations,
    field: 'organization',
    filterType: TableFilterTypeEnum.string,
  },
  {
    title: appLocale.bottomSoil.consumer,
    field: 'consumer',
    filterType: TableFilterTypeEnum.string,
  },
  ...BottomSoilParametersKeys.map((key) => ({
    title: appLocale.bottomSoil[key],
    field: `${key}.numValue`,
    filterType: TableFilterTypeEnum.number,
    render: (row: IBottomSoilDTO) => row[key].strValue
  })),
];
