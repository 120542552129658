import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import appLocale from 'constants/appLocale';
import { NSIRootPath } from '../../../const/routes';
import { GeoecologyAPIPath, GeoecologyDictPath, GeologicalProcessesPath } from '../../constants/constants';
import {
  BreadcrumbButton,
  Button,
  ContentContainer,
  DateInput,
  Input,
  LoaderItem,
  PageHeader,
  Select,
} from 'components/common';
import { Flex, Grid, GridItem, Heading, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import React, { FC, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Field, FormikProvider, useFormik } from 'formik';
import { useEnhancedNotification, useNotifications, useRequest, UseRequestReturnType } from 'hooks';
import { getSelectOptionsFromDictionaryByName } from 'features/get-select-options-from-dictionary';
import { SUCCESS_MESSAGES } from 'constants/messages';
import * as yup from 'yup';
import { numberError, requiredError } from 'models/schemas/constans';
import { IInclinometerRowData } from '../../constants/types';
import { latitudeValidation } from 'models/schemas/location';
import { AsyncAddressSelect } from 'components/common/AsyncAddressSelect/AsyncAddressSelect';
import { useGoBackWithFallback } from 'lib/utils/goBackWithFallback';
import { useDictionary } from 'hooks/useDictionary';

export const EditForm: FC = () => {
  const { id, type } = useParams<{ id: string; type: string }>();
  const { backWithFallback } = useGoBackWithFallback();

  useUpdateBreadcrumbs([
    {
      breadcrumb: appLocale.mainPage.nsi,
      path: NSIRootPath,
    },
    {
      breadcrumb: 'Опасные геоэкологические процессы',
      path: `${GeologicalProcessesPath}/${type}`,
    },
  ]);

  const { successNotify } = useNotifications();
  const { onErrorCreate, onErrorEdit } = useEnhancedNotification();

  const onSuccess = () => {
    successNotify({
      key: `${type}/edit/success`,
      message: id ? SUCCESS_MESSAGES.EDIT : SUCCESS_MESSAGES.CREATE,
    });
    backWithFallback(`${GeologicalProcessesPath}/${type}`);
  };

  const onError = (error: any) => {
    if (id) {
      onErrorEdit(error);
    }
    onErrorCreate(error);
  };

  const {
    result,
    get,
    isLoading: isDataLoading,
  }: UseRequestReturnType = useRequest(`/nsi/v10/sources/geoecology/${type}/${id}`);
  const { post } = useRequest('/nsi/v10/sources/geoecology/create', onSuccess, onError);
  const { put } = useRequest('/nsi/v10/sources/geoecology', onSuccess, onError);
  const {
    result: InclinometerResult,
    get: getInclinometer,
    isLoading: isInclinometerLoading,
  } = useRequest<IInclinometerRowData[]>(`${GeoecologyAPIPath}/info/inclinometer/${id}`);

  useEffect(() => {
    if (id) {
      get();
      getInclinometer();
    }
  }, [id, get, getInclinometer]);

  const onSubmit = (values: any) => {
    if (id) {
      put({ ...values, id });
    } else {
      post({ ...values });
    }
  };

  const DangerGeologicalSchema = yup.object().shape({
    status: type !== 'observationArea' ? yup.string().nullable().required(requiredError) : yup.string().nullable(),
    number: yup.number().nullable().required(requiredError),
    name: type !== 'observationArea' ? yup.string().nullable() : yup.string().nullable().required(requiredError),
    areaId: type !== 'observationArea' ? yup.string().nullable().required(requiredError) : yup.string().nullable(),
    setupDate:
      type !== 'observationArea' && type !== 'observationPoint'
        ? yup.date().nullable().required(requiredError)
        : yup.string().nullable(),
    areaType: type === 'observationArea' ? yup.string().nullable().required(requiredError) : yup.string().nullable(),
    step: yup.number().nullable().typeError(numberError),
    ...latitudeValidation,
  });

  const initialValues = {
    number: result?.number || '',
    name: result?.name || '',
    status: result?.status || 'ACTIVE',
    latitude: result?.latitude || '',
    longitude: result?.longitude || '',
    okrugId: result?.okrugId || '',
    rayonId: result?.rayonId || '',
    landmark: result?.landmark || '',
    address: result?.address || '',
    area: result?.area || '',
    polygon: result?.polygon || '',
    step: result?.step || '',
    itemsNumber: result?.itemsNumber || '',
    setupDate: result?.setupDate || '',
    type,
    id: id || '',
    areaId: result?.areaId || '',
    areaType: result?.areaType || '',
  };

  const { dictionaries, isDictionariesLoading } = useDictionary({ url: GeoecologyDictPath });
  const districts = useMemo(() => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'rayons'), [dictionaries]);
  const regions = useMemo(() => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'okrugs'), [dictionaries]);
  const statuses = useMemo(() => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'statuses'), [dictionaries]);
  const areas = useMemo(() => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'areas'), [dictionaries]);
  const areaTypes = useMemo(
    () =>
      getSelectOptionsFromDictionaryByName(dictionaries?.data, 'areaTypes', false, initialValues.areaType as string),
    [dictionaries?.data, initialValues.areaType],
  );

  const {
    values,
    errors,
    getFieldMeta,
    getFieldHelpers,
    getFieldProps,
    handleReset,
    submitForm,
    setFieldValue,
    ...formik
  } = useFormik({
    validationSchema: DangerGeologicalSchema,
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: false,
    validateOnBlur: true,
    onSubmit,
    initialValues,
  });

  return (
    <>
      <ContentContainer margin={0} padding={0}>
        <FormikProvider
          value={{
            values,
            errors,
            getFieldMeta,
            getFieldHelpers,
            getFieldProps,
            handleReset,
            submitForm,
            setFieldValue,
            ...formik,
          }}
        >
          {isDataLoading || isInclinometerLoading || isDictionariesLoading ? (
            <LoaderItem />
          ) : (
            <>
              <PageHeader>
                <BreadcrumbButton onClick={() => backWithFallback(`${GeologicalProcessesPath}/${type}`)}>
                  Вернуться назад
                </BreadcrumbButton>
                <Flex>
                  <Heading>{`${id ? 'Редактирование' : 'Добавление'} справочных данных`}</Heading>
                  <Flex marginLeft="auto">
                    <Button marginRight={5} onClick={() => backWithFallback(`${GeologicalProcessesPath}/${type}`)}>
                      Отмена
                    </Button>
                    <Button onClick={submitForm}>Сохранить</Button>
                  </Flex>
                </Flex>
              </PageHeader>
              <Tabs marginTop={10}>
                <TabList>
                  <Tab>Общие сведения</Tab>
                  <Tab>Местоположение</Tab>
                </TabList>
                <TabPanels>
                  <TabPanel>
                    <Grid gap={2} templateRows="repeat(1, 1fr)" templateColumns="repeat(1, 0.5fr)">
                      {type === 'observationArea' ? (
                        <>
                          <Field as={Input} name="name" label="Название участка" error={errors?.name} />
                          <Field
                            as={Input}
                            name="number"
                            label="Номер участка"
                            error={errors?.number}
                            isDisabled={!!id}
                          />
                          <Field as={Input} name="area" label="Площадь участка" />
                          <Field
                            as={Select}
                            name="areaType"
                            label="Тип процесса на участке"
                            options={areaTypes}
                            error={errors?.areaType}
                          />
                        </>
                      ) : null}
                      {type === 'observationPoint' ? (
                        <>
                          <Field
                            as={Input}
                            name="number"
                            label="Номер точки наблюдения"
                            error={errors?.number}
                            isDisabled={!!id}
                          />
                          <Field
                            as={Select}
                            name="areaId"
                            label="Участок наблюдения"
                            options={areas}
                            error={errors?.areaId}
                          />
                        </>
                      ) : null}
                      {type === 'inclinometer' ? (
                        <>
                          <Field
                            as={Input}
                            name="number"
                            label="Номер инклинометра"
                            error={errors?.number}
                            isDisabled={!!id}
                          />
                          {InclinometerResult?.length || result?.status === 'DISABLED' ? null : (
                            <>
                              <Field as={Input} name="itemsNumber" label="Количество глубин" />
                              <Field as={Input} name="step" label="Шаг" error={errors?.step} />
                            </>
                          )}
                          <Field
                            as={Select}
                            name="areaId"
                            label="Участок наблюдения"
                            options={areas}
                            error={errors?.areaId}
                          />
                          <Field as={DateInput} name="setupDate" label="Дата установки" error={errors?.setupDate} />
                        </>
                      ) : null}
                      {type === 'reper' ? (
                        <>
                          <Field as={Input} name="number" label="Код репера" error={errors?.number} isDisabled={!!id} />
                          <Field
                            as={Select}
                            name="areaId"
                            label="Участок наблюдения"
                            options={areas}
                            error={errors?.areaId}
                          />
                          <Field as={DateInput} name="setupDate" label="Дата установки" error={errors?.setupDate} />
                        </>
                      ) : null}
                      {type === 'mark' ? (
                        <>
                          <Field as={Input} name="number" label="Код марки" error={errors?.number} isDisabled={!!id} />
                          <Field
                            as={Select}
                            name="areaId"
                            label="Участок наблюдения"
                            options={areas}
                            error={errors?.areaId}
                          />
                          <Field as={DateInput} name="setupDate" label="Дата установки" error={errors?.setupDate} />
                        </>
                      ) : null}
                      {type !== 'observationArea' ? (
                        <>
                          <Field as={Select} name="status" label="Статус" options={statuses} error={errors?.status} />
                        </>
                      ) : null}
                    </Grid>
                  </TabPanel>
                  <TabPanel>
                    <Grid gap={2} templateRows="repeat(1, 1fr)" templateColumns="repeat(1, 0.5fr)">
                      {type === 'observationArea' ? (
                        <>
                          <GridItem>
                            <Field as={Select} name="rayonId" label="Район" options={districts} />
                          </GridItem>
                          <GridItem>
                            <Field as={Select} name="okrugId" label="Округ" options={regions} />
                          </GridItem>
                          <GridItem>
                            <Field as={AsyncAddressSelect} name="address" label="Адрес" />
                          </GridItem>
                          <GridItem>
                            <Field as={Input} name="polygon" label="Полигон" />
                          </GridItem>
                        </>
                      ) : null}
                      {type === 'mark' || type === 'reper' || type === 'inclinometer' ? (
                        <>
                          <GridItem>
                            <Field as={Input} name="landmark" label="Адресный ориентир" />
                          </GridItem>
                        </>
                      ) : null}
                      <GridItem>
                        <Field
                          as={Input}
                          name="latitude"
                          label="Широта"
                          error={errors?.latitude}
                          isDisabled={type === 'observationArea'}
                        />
                      </GridItem>
                      <GridItem>
                        <Field
                          as={Input}
                          name="longitude"
                          label="Долгота"
                          error={errors?.longitude}
                          isDisabled={type === 'observationArea'}
                        />
                      </GridItem>
                    </Grid>
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </>
          )}
        </FormikProvider>
      </ContentContainer>
    </>
  );
};
