import { ERROR_MESSAGES, SUCCESS_MESSAGES } from 'constants/messages';
import { createTouchedErrors } from 'features/create-touched-errors';
import { FormikProvider, useFormik } from 'formik';
import { useNotifications } from 'hooks/useNotifications';
import { useRequest } from 'hooks/useRequest';
import { concentrationApiPath, consentrationPath, IConcentraionItem } from '../../consts';
import { ConcentrationFields } from './ConcentrationFields';
import { PDKSchema } from 'models/schemas';
import { LoaderItem } from 'components/common';
import { useGoBackWithFallback } from 'lib/utils/goBackWithFallback';
import { useQuery } from 'hooks/useQuery';
import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import { nsiPdkBreadcrumbs } from 'pages/dictionaries/NSIPdk/consts/breadcrumbs';
import { useLocation } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';
import { useEnhancedNotification } from 'hooks';
import { useDictionary } from 'hooks/useDictionary';

const initialValues: Partial<IConcentraionItem> = {
  chem_desc: '',
  chemical_id: null,
  deleted: false,
  natural_environment: null,
  pdkEndDate: null,
  pdkKind: null,
  pdkMax: 0,
  pdkMin: 0,
  pdkStartDate: null,
  pdk_document: '',
  pdk_status: 'CURRENT',
  prim: '',
};

export const AddConcentration = () => {
  useUpdateBreadcrumbs(nsiPdkBreadcrumbs);
  const { archive } = useQuery<{ archive?: string }>();
  const { backWithFallback } = useGoBackWithFallback();
  const { dictionaries, isDictionariesLoading } = useDictionary({ url: '/nsi/v10/substance/dictionaries' });
  const { successNotify, errorNotify } = useNotifications();
  const { onGetError } = useEnhancedNotification();
  const location = useLocation();
  const [initialState, setInitialState] = useState<Partial<IConcentraionItem>>(initialValues);

  const {
    get,
    isLoading: isItemLoading,
  } = useRequest<IConcentraionItem>(
    `nsi/v10/pdk/substance/pdk/getById/${location.state?.id}`,
    (result) => {
      if (result) {
        setInitialState((prev) => ({
          ...prev,
          chemical_id: result.chemical_id,
          chem_desc: result.chem_desc,
          natural_environment: result.natural_environment,
          pdkKind: result.pdkKind,
          prim: result.prim,
        }));
      }
    },
    onGetError,
  );

  const isLoading = useMemo(() => isItemLoading || isDictionariesLoading, [isDictionariesLoading, isItemLoading]);

  useEffect(() => {
    if (location.state?.id) {
      get();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSuccess = () => {
    successNotify({
      key: 'file/add/success',
      message: SUCCESS_MESSAGES.CREATE,
    });
    backWithFallback(consentrationPath);
  };

  const onError = (error: any) => {
    const message = error?.descriptions[0]?.message || '';
    errorNotify({
      key: 'file/add/error',
      message: `${ERROR_MESSAGES.CREATE}. ${message}`,
    });
  };

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    validationSchema: PDKSchema,
    initialValues: initialState,
    onSubmit: (values) => {
      post(values);
    },
  });

  const { post } = useRequest<IConcentraionItem, any>(
    `${concentrationApiPath}${archive ? '/archive' : ''}`, onSuccess, onError,
  );

  if (isLoading) {
    return (
      <LoaderItem />
    );
  }

  return (
    <FormikProvider
      value={{
        ...formik,
        errors: createTouchedErrors(formik.errors, formik.touched),
      }}
    >
      <ConcentrationFields dictionaries={dictionaries?.data} />
    </FormikProvider>
  );
};
