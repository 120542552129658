import React, { memo, useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Column } from '@material-table/core';

import { transformFiltersArray } from 'features/Filter/transformFiltersArray';
import { createDateToRequestWithTimezone, createLocalDateDDMMYYYYWithTime } from 'lib/create-date';
import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import { MaterialTable } from 'features/material-table/MaterialTableMini';
import { useAvailability, useDisclosure } from 'hooks';
import { useList } from 'hooks/useList';
import { SubsystemHeader } from 'components/common';
import appLocale from 'constants/appLocale';
import { AISWeatherLocales } from './constants';
import {
  IAISWeatherItem,
  IAISWeatherPackage,
  TAISWeatherFilterTypes,
  TAISWeatherTFilterDTO,
} from './useAISWeatherList';
import { AISWeatherFilter } from './AISWeatherFilter';
import { DeleteAISWeatherItemModal } from './DeleteAISWeatherItemModal';
import { addFiltersToColumns, ColumnWithFilter } from 'lib/utils/tableFilter';
import { TableFilterTypeEnum } from 'components/table/filters/TableFilter';
import { Modal } from 'components/common/Modals';
import { AISWeatherRootPath } from 'pages/data/Meteo/menu';
import { PropActions } from 'features/material-table/components/Actions';
import subsystemIdsMap from 'constants/subsystemIdsMap';
import { usePeriodsDict } from 'features/PeriodsButtons/usePeriodsDict';
import { UploadFileModalContent } from 'features/UploadFileDragAndDrop/UploadFileModalContent';
import { DEFAULT_PAGE_SIZE_NEW_TABLE } from '../../../../constants/tables';
import { FullPageWrapper } from '../../../../components/table/FullPageWrapper';

const AISWeather = memo(() => {
  const { isWrite, isExecute, isNoRights, allAvailable, isAvailable } = useAvailability(subsystemIdsMap.meteoAis);

  const history = useHistory();

  const { isOpen: isOpenLoad, onClose: onCloseLoad, onOpen: onOpenLoad } = useDisclosure();
  useUpdateBreadcrumbs([
    { breadcrumb: appLocale.common.data, path: '/data' },
    {
      breadcrumb: appLocale.subsystems.meteo,
      path: '/data/meteo',
    },
    {
      breadcrumb: 'Метеоданные',
      path: '/data/meteo/data',
    },
    {
      breadcrumb: 'АИС Погода',
      path: AISWeatherRootPath,
    },
  ]);

  const [deleteItemId, setDeleteItemId] = useState('');

  const deleteItemDisclosure = useDisclosure();

  const onRowDelete = useCallback(
    (_, rowData: IAISWeatherItem | IAISWeatherItem[]) => {
      if (rowData && !Array.isArray(rowData)) {
        setDeleteItemId(rowData.id);
        deleteItemDisclosure.onOpen();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [deleteItemDisclosure.onOpen]
  );

  const selectionKey: keyof IAISWeatherItem = 'id';

  const {
    refetch,
    isLoading: isListLoading,
    response,
    toggleOrder,
    setFilters,
    onPageChange,
    filterDTO,
    downloadAsFile,
    onSearchText,
    saveFilters,
    errorText,
    selectedIndexes,
    renderOnlySelectedCheckbox,
    onSubmitFilters,
    onSelectionChange,
    materialTableProps,
  } = useList<IAISWeatherPackage, TAISWeatherFilterTypes>('/meteo/v10/ais/packages', {
    initialOrderFieldKey: '-date',
    preventFirstFetch: true,
    exportSubsystemName: 'meteo_ais-weather',
    selectionKey,
    saveFiltersState: true,
    defaultPageSize: DEFAULT_PAGE_SIZE_NEW_TABLE,
    resetDataOnNextRequest: true,
  });

  const columns = useMemo<Array<ColumnWithFilter<IAISWeatherPackage>>>(
    () => [
      {
        title: AISWeatherLocales.number,
        field: 'number',
        filterType: TableFilterTypeEnum.number,
      },
      {
        title: AISWeatherLocales.date,
        field: 'date',
        render: (row) => createLocalDateDDMMYYYYWithTime(row.date || ''),
      },
      {
        title: AISWeatherLocales.fileName,
        field: 'fileName',
        filterType: TableFilterTypeEnum.string,
      },
    ],
    []
  );

  const columnsWithFilters = useMemo<Array<Column<IAISWeatherPackage>>>(
    () => addFiltersToColumns({ filters: filterDTO.filters, setFilters, columns }),
    [columns, filterDTO.filters, setFilters]
  );

  const initialActions = useMemo<PropActions<IAISWeatherItem[]>>(
    () => [
      {
        type: 'edit',
        onClick: (e, item: any) => {
          history.push(`${AISWeatherRootPath}/edit/row/${item.id}`);
        },
      },
      { type: 'delete', onClick: onRowDelete },
    ],
    [history, onRowDelete]
  );

  // @ts-ignore
  const actions = useMemo<PropActions<any[]>>(() => {
    if (allAvailable || isNoRights || isWrite) {
      return initialActions;
    }
    if (!(allAvailable || isNoRights || isWrite)) {
      return initialActions.filter((action: any) => action.type !== 'delete');
    }
  }, [isWrite, initialActions, isNoRights, allAvailable]);

  const isLoading = useMemo(() => isListLoading, [isListLoading]);

  const { result: periods } = usePeriodsDict(`auth/v10/user/period/${subsystemIdsMap.meteoAis}`);

  return (
    <FullPageWrapper>
      <SubsystemHeader
        title="АИС Погода"
        addButtonProps={{
          onClick: () => {
            history.push(`${AISWeatherRootPath}/add`);
          },
          isDisabled: !isAvailable,
        }}
        onUploadClick={isAvailable ? onOpenLoad : undefined}
        filtersComponent={
          <AISWeatherFilter
            filters={filterDTO?.filters || []}
            periods={periods}
            isLoading={isLoading}
            onSubmit={({ dateFrom, dateTo, ...values }) => {
              onSubmitFilters(
                transformFiltersArray<TAISWeatherTFilterDTO['filters']>(
                  Object.values({
                    ...values,
                    dateFrom: {
                      ...dateFrom,
                      value: createDateToRequestWithTimezone(String(dateFrom.value)),
                    },
                    dateTo: {
                      ...dateTo,
                      value: createDateToRequestWithTimezone(String(dateTo.value)),
                    },
                    deleted: {
                      key: 'deleted',
                      operation: 'EQ',
                      value: false,
                    },
                  })
                )
              );
            }}
          >
            {renderOnlySelectedCheckbox}
          </AISWeatherFilter>
        }
      />

      <Modal isOpen={isOpenLoad} onClose={onCloseLoad} header="Загрузка данных из файла">
        <UploadFileModalContent
          createUploadPath={() => '/meteo/v10/ais/load'}
          types={[
            {
              name: 'Пакетный ручной ввод измерений',
              value: 'ais',
            },
          ]}
          onClose={onCloseLoad}
          refetch={refetch}
        />
      </Modal>

      <MaterialTable
        saveFilters={saveFilters}
        isNotExecute={isExecute || isNoRights}
        data={response?.data}
        meta={response?.meta}
        toggleOrder={toggleOrder}
        onPageChange={onPageChange}
        filterDTO={filterDTO}
        options={{
          search: true,
        }}
        isLoading={isLoading}
        onSearch={onSearchText}
        columns={columnsWithFilters}
        actions={actions}
        downloadAsFile={downloadAsFile}
        selectedIndexes={selectedIndexes}
        onSelectionChange={onSelectionChange}
        setFilters={setFilters}
        errorText={errorText}
        {...materialTableProps}
        showTotalCount
      />

      <DeleteAISWeatherItemModal {...deleteItemDisclosure} id={deleteItemId} refetch={refetch} />
    </FullPageWrapper>
  );
});

export { AISWeather };
