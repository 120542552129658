import * as yup from 'yup';
import { ValidationError } from 'yup';
import { integerNumberError, numberError, requiredError } from '../constans';

export const TaskSchema = yup
  .object()
  .shape({
    typeId: yup.string().nullable().required(requiredError),
    reasonId: yup.string().nullable().required(requiredError),
    startDate: yup.string().nullable().required(requiredError),
    shiftId: yup.string().nullable().required(requiredError),
    priorityId: yup.string().nullable(),
    regions: yup.array().of(yup.string().typeError(requiredError).required(requiredError)),
    districts: yup.array().of(yup.string().typeError(requiredError).required(requiredError)),
    targetTime: yup.string().nullable(),
    territoryName: yup.string().required(requiredError),
    points: yup.array().of(
      yup.object().shape({
        dotLatitude: yup.number().nullable().typeError(numberError),
        dotLongitude: yup.number().nullable().typeError(numberError),
        expectedTime: yup.number().nullable().typeError(numberError),
        pointNumber: yup.number().integer(integerNumberError).nullable().typeError(numberError),
      })
    ),
  })
  .test((values) => {
    const errors = values?.points?.map((item, index) => {
      if (item.typeId === 'c1555e6e-3397-46da-8d3c-9ab2cf0d2217') {
        try {
          const multipolygon = JSON.parse(item.evalObjCoordinateArray);
          if (Array.isArray(multipolygon) && Array.isArray(multipolygon[0])) {
            if (
              multipolygon[0].some(
                (item) =>
                  !Array.isArray(item) || !Number.isFinite(item[0]) || !Number.isFinite(item[1]) || !(item.length === 2)
              )
            ) {
              return new ValidationError(
                'Некорректное значение мультиполигона',
                '',
                `points[${index}].evalObjCoordinateArray`
              );
            }
          } else {
            return new ValidationError(
              'Некорректное значение мультиполигона',
              '',
              `points[${index}].evalObjCoordinateArray`
            );
          }
        } catch {
          if (item.evalObjCoordinateArray) {
            return new ValidationError(
              'Некорректное значение мультиполигона',
              '',
              `points[${index}].evalObjCoordinateArray`
            );
          }
        }
      }
    });
    if (errors) {
      return new ValidationError(errors as yup.ValidationError[]);
    }
    return true;
  });

export const NoiseMainReport = yup
  .object()
  .shape({
    raidNumber: yup.string().required(requiredError).nullable(),
    raidDate: yup.string().required(requiredError).nullable(),
    raidTypeId: yup.string().required(requiredError).nullable(),
    taskTerritoryName: yup.string().required(requiredError).nullable(),
    taskReasonId: yup.string().required(requiredError).nullable(),
    taskDistrictIds: yup.array().of(yup.string().required(requiredError).nullable()),
    taskRegionIds: yup.array().of(yup.string().required(requiredError).nullable()),
    points: yup.array().of(
      yup.object().shape({
        pointTypeId: yup.string().required(requiredError).nullable(),
        pointLatitude: yup.number().nullable().typeError(numberError),
        pointLongitude: yup.number().nullable().typeError(numberError),
      })
    ),
  })
  .test((values) => {
    const errors = values?.points?.map((item, index) => {
      if (item.pointTypeId === 'c1555e6e-3397-46da-8d3c-9ab2cf0d2217') {
        try {
          const multipolygon = JSON.parse(item.pointCoordinateArray);
          if (Array.isArray(multipolygon) && Array.isArray(multipolygon[0])) {
            if (
              multipolygon[0].some(
                (item) =>
                  !Array.isArray(item) || !Number.isFinite(item[0]) || !Number.isFinite(item[1]) || !(item.length === 2)
              )
            ) {
              return new ValidationError(
                'Некорректное значение мультиполигона',
                '',
                `points[${index}].pointCoordinateArray`
              );
            }
          } else {
            return new ValidationError(
              'Некорректное значение мультиполигона',
              '',
              `points[${index}].pointCoordinateArray`
            );
          }
        } catch {
          if (item.pointCoordinateArray) {
            return new ValidationError(
              'Некорректное значение мультиполигона',
              '',
              `points[${index}].pointCoordinateArray`
            );
          }
        }
      }
    });
    if (errors) {
      return new ValidationError(errors as yup.ValidationError[]);
    }
    return true;
  });

export const AtmoMainReport = yup
  .object()
  .shape({
    raidNumber: yup.string().required(requiredError).nullable(),
    raidDate: yup.string().required(requiredError).nullable(),
    raidTypeId: yup.string().required(requiredError).nullable(),
    taskTerritoryName: yup.string().required(requiredError).nullable(),
    taskReasonId: yup.string().required(requiredError).nullable(),
    taskDistrictIds: yup.array().of(yup.string().required(requiredError).nullable()),
    taskRegionIds: yup.array().of(yup.string().required(requiredError).nullable()),
    raidShiftId: yup.string().required(requiredError).nullable(),
    points: yup.array().of(
      yup.object().shape({
        pointTypeId: yup.string().required(requiredError).nullable(),
        pointLatitude: yup.number().nullable().typeError(numberError),
        pointLongitude: yup.number().nullable().typeError(numberError),
      })
    ),
  })
  .test((values) => {
    const errors = values?.points?.map((item, index) => {
      if (item.pointTypeId === 'c1555e6e-3397-46da-8d3c-9ab2cf0d2217') {
        try {
          const multipolygon = JSON.parse(item.pointCoordinateArray);
          if (Array.isArray(multipolygon) && Array.isArray(multipolygon[0])) {
            if (
              multipolygon[0].some(
                (item) =>
                  !Array.isArray(item) || !Number.isFinite(item[0]) || !Number.isFinite(item[1]) || !(item.length === 2)
              )
            ) {
              return new ValidationError(
                'Некорректное значение мультиполигона',
                '',
                `points[${index}].pointCoordinateArray`
              );
            }
          } else {
            return new ValidationError(
              'Некорректное значение мультиполигона',
              '',
              `points[${index}].pointCoordinateArray`
            );
          }
        } catch {
          if (item.pointCoordinateArray) {
            return new ValidationError(
              'Некорректное значение мультиполигона',
              '',
              `points[${index}].pointCoordinateArray`
            );
          }
        }
      }
    });
    if (errors) {
      return new ValidationError(errors as yup.ValidationError[]);
    }
    return true;
  });

export const garbageBurningReportsSchema = yup.object().shape({
  points: yup.array().of(
    yup.object().shape({
      pointLatitude: yup.number().nullable().typeError(numberError),
      pointLongitude: yup.number().nullable().typeError(numberError),
    })
  ),
});
