import React from 'react';
import { PageHeader, RoutesList } from 'components/common';
import { Flex, Heading } from '@chakra-ui/react';
import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import appLocale from 'constants/appLocale';
import { wpzMenu } from '../../reportsAnalyticsMenu';
import { useRoutesList } from 'hooks/useRoutesList';

export const WaterShoreAndProtection = () => {
  useUpdateBreadcrumbs([
    { breadcrumb: appLocale.subsystems.analyticsReports, path: '/reports-analytics' },
    { breadcrumb: appLocale.subsystems.water, path: '/reports-analytics/water' },
    { breadcrumb: appLocale.subsystems.wpz, path: '/reports-analytics/water/water-state/protections' },
  ]);

  const routes = useRoutesList(wpzMenu);

  return (
    <Flex flexDirection="column">
      <PageHeader>
        <Heading>{`${appLocale.subsystems.wpz}`}</Heading>
      </PageHeader>
      <RoutesList routes={routes} />
    </Flex>
  );
};
