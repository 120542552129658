import { Box, Flex, Grid, GridItem, Heading } from '@chakra-ui/react';
import { Button, Checkbox, DateInput, Input, Modal, Select, Textarea } from 'components/common';
import { Field, FieldArrayRenderProps, FormikHandlers, useFormikContext } from 'formik';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { TDictionaryItemDictionary } from 'models';
import { DisplayUserItem } from 'pages/admin/models/UserItem';
import { usePaginatedLoader } from 'pages/admin/typedLoader';
import { baseAdminUserUrl } from 'pages/admin/User/consts';
import { IMainObservationPlace } from '../../../NoiseReport/types';
import {
  getPathsBySubsystemName,
  getSubsystemHumanReadableName,
  SubsystemName,
} from 'pages/analytics/consts/AnalyticsTableRoutes';
import { PelFTPData } from '../../PelFTPData';
import { useDisclosure } from 'hooks/useDisclosure';
import { AddIcon, DeleteIcon, DownloadIcon, EditIcon, ViewIcon2 } from 'components/icons';
import { IUploadMedia } from 'components/common/FileUploadDragAndDrop/types';
import { TaskAttachments } from 'pages/citizenAppeal/CitizensAppealsRecord/components/TaskAttachments';
import { UploadObjectType } from 'api/services/media';
import { useEnhancedNotification, useNotifications, useRequest } from 'hooks';
import { ReportProtocolGetDto } from 'pages/analytics/Raids/types';
import { ExtensionToMimeTypeEnum } from 'lib/utils/files/getFileExtensionByMimeType';
import { downloadFileFromResponse } from 'lib/utils/files/downloadFile';
import { ERROR_MESSAGES, SUCCESS_MESSAGES } from 'constants/messages';
import { Close } from '@material-ui/icons';
import { ReportSetProtocolModal } from '../../../../citizenAppeal/CitizensAppealsRecord/components/CompletionReports/reportSetProtocolModal';
import { garbageBurningPoint } from '../../../../citizenAppeal';
import { CustomAutocomplete } from '../../../../../components/common/Autocomplete';
import { AsyncAddressSelect } from 'components/common/AsyncAddressSelect/AsyncAddressSelect';
import { createUTCTime } from 'lib/create-date';

interface ObservationPlaceProps {
  name: string;
  values: IMainObservationPlace;
  onRemovePoint: ReturnType<FieldArrayRenderProps['handleRemove']>;
  handleChange: FormikHandlers['handleChange'];
  pointTypeId?: TDictionaryItemDictionary[];
  sourceTypes?: TDictionaryItemDictionary[];
  raidResultId?: string[];
  pelStations?: TDictionaryItemDictionary[];
  taskId?: string;
  media?: IUploadMedia[];
  onMediaSave: (media: IUploadMedia[], index: number) => void;
  index: number;
  errors: any;
  uid: string;
  browserId: string;
  onSave: () => void;
}

const BurningJournalObservationPlace: React.FunctionComponent<ObservationPlaceProps> = ({
  name,
  values,
  onRemovePoint,
  handleChange,
  pointTypeId,
  sourceTypes,
  raidResultId,
  taskId,
  pelStations,
  media = [],
  onMediaSave,
  index,
  errors,
  uid,
  browserId,
  onSave
}) => {
  const { setFieldValue } = useFormikContext();
  const { errorNotify } = useNotifications();
  const { onDownloadError } = useEnhancedNotification();
  const [currentMedia, setCurrentMedia] = useState<IUploadMedia[]>(media);
  const { get: getUserList, result } = usePaginatedLoader<DisplayUserItem>(baseAdminUserUrl);
  useEffect(() => {
    getUserList();
  }, [getUserList]);
  const protocolIdAdder = (field: string, id: string | null) => {
    setFieldValue(field, id);
  };
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { isOpen: isAttachmentOpen, onClose: onAttachmentClose, onOpen: onAttachmentOpen } = useDisclosure();

  const executors = useMemo(
    () =>
      result?.data.map((user) => ({
        name: user.fullName,
        value: user.id,
      })) || [],
    [result],
  );

  const onProtocolNotFound = useCallback(() => {
    errorNotify({
      message: 'Протокол не найден',
      key: 'error/protocol/notfound',
    });
  }, [errorNotify]);

  const onFileResponseSuccess = useCallback((response) => {
    downloadFileFromResponse({
      response,
      name: `Шаблон протокола_${getSubsystemHumanReadableName(SubsystemName.pelAtmosphere)}`,
    });
  }, []);

  const { get: getProtocolForPoint } = useRequest<ReportProtocolGetDto>('', undefined, onProtocolNotFound);
  const { get: download } = useRequest<any>('', onFileResponseSuccess, onDownloadError);
  const onProtocolDownload = useCallback(
    async (ai: boolean, pointId: string) => {
      const protocol = await getProtocolForPoint({
        url: `/appeal/v10/points/${pointId}/${ai ? 'ai' : 'pel'}/protocols`,
      });
      if (protocol && 'id' in protocol && protocol.id) {
        void download({
          url: getPathsBySubsystemName(SubsystemName.pelAtmosphere)?.download(protocol.id),
          headers: { ACCEPT: ExtensionToMimeTypeEnum.docx },
          responseType: 'blob',
        });
      }
    },
    [download, getProtocolForPoint],
  );

  const { successNotify: successRegister, errorNotify: errorRegister } = useNotifications();

  const onSuccessRegister = (answer: number | null) => {
    successRegister({
      key: 'file/create/success',
      message: SUCCESS_MESSAGES.POINT_REGISTER,
    });
    if (answer) {
      setFieldValue(`${name}.protocolNumber`, answer);
    }
  };

  const onErrorRegister = () => {
    errorRegister({
      key: 'file/create/error',
      message: ERROR_MESSAGES.POINT_REGISTER,
    });
  };

  const { isOpen: isProtocolsOpen, onOpen: onProtocolOpen, onClose: onProtocolClose } = useDisclosure();
  const { isOpen: isAIProtocolsOpen, onOpen: onAIProtocolOpen, onClose: onAIProtocolClose } = useDisclosure();

  const customClose = (closeFunc: () => void) => {
    closeFunc();
  };

  const { get: protocolRegister } = useRequest<number>(
    '/appeal/v10/report-points/next-protocol-number',
    onSuccessRegister,
    onErrorRegister,
  );

  const { successNotify: unbindSuccess, errorNotify: unbindError } = useNotifications();
  const { successNotify: unbindAiSuccess, errorNotify: unbindAiError } = useNotifications();

  const successUnbindProtocol = () => {
    unbindSuccess({
      key: 'token/update/success',
      message: 'Протокол успешно отвязан',
    });
    protocolIdAdder(`${name}.pelAirProtocolId`, null);
  };

  const errorUnbindProtocol = () => {
    unbindError({
      key: 'token/update/success',
      message: 'Ошибка при отвязке протокола',
    });
  };

  const successUnbindAiProtocol = () => {
    unbindAiSuccess({
      key: 'token/update/success',
      message: 'Протокол успешно отвязан',
    });
    protocolIdAdder(`${name}.aiAirProtocolId`, null);
  };

  const errorUnbindAiProtocol = () => {
    unbindAiError({
      key: 'token/update/success',
      message: 'Ошибка при отвязке протокола',
    });
  };

  const { put: unbindAiProtocol } = useRequest('', successUnbindAiProtocol, errorUnbindAiProtocol);
  const { put: unbindProtocol } = useRequest('', successUnbindProtocol, errorUnbindProtocol);

  const { post } = useRequest('/mapsclient/v10/sendCommand');

  const isArea = useMemo(
    () => pointTypeId?.find((item) => item.value === values.pointTypeId)?.name === 'Область обследования',
    [pointTypeId, values.pointTypeId],
  );

  const startEditing = useCallback(() => {
    post({
      browserId,
      command: 'startEditing',
      data: {
        uid,
        layerTemplateId: '1121430612',
      },
    });
  }, [browserId, post, uid]);

  const addFeature = useCallback(
    (id, name) => {
      post({
        browserId,
        command: 'addFeature',
        data: {
          uid,
          layerTemplateId: '1121430612',
          geometryType: isArea ? 'Polygon' : 'Point',
          feature: {
            type: 'Feature',
            properties: {
              id,
              name,
            },
          },
        },
      });
    },
    [browserId, isArea, post, uid],
  );

  // HACK: add callback to onSave and save only one task?
  const ref = useRef<null | 'ai' | 'pel'>(null);
  useEffect(() => {
    if (ref.current !== null && values.id) {
      if (ref.current === 'ai') {
        onAIProtocolOpen();
      } else if (ref.current === 'pel') {
        onProtocolOpen();
      }
      ref.current = null;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.id]);

  const saveThenOpenProtocol = useCallback((type: 'pel' | 'ai') => {
    ref.current = type;
    onSave();
  }, [onSave]);

  return (
    <Box bg="GhostWhite" padding="1rem">
      <Flex alignItems="center">
        <Heading as="h3" size="md">
          Место наблюдения
        </Heading>
        <Button
          leftIcon={<DeleteIcon />}
          variant="ghost"
          color="PrimaryButton.500"
          size="sm"
          p={0}
          marginRight="auto"
          marginLeft={5}
          onClick={onRemovePoint}
        />
        <Button
          leftIcon={<EditIcon />}
          variant="ghost"
          color="PrimaryButton.500"
          size="sm"
          p={0}
          marginLeft="auto"
          onClick={() => {
            window.scrollTo(0, 0);
            startEditing();
          }}
          isDisabled={isArea ? !values.pointCoordinateArray : !(values.pointLongitude || values.pointLatitude)}
        >
          Редактировать координаты на карте
        </Button>
        <Button
          leftIcon={<AddIcon />}
          variant="ghost"
          color="PrimaryButton.500"
          size="sm"
          p={0}
          marginLeft={5}
          onClick={() => {
            window.scrollTo(0, 0);
            addFeature(values.id || values.temporaryId, values.pointNumber);
          }}
          isDisabled={isArea ? !!values.pointCoordinateArray : !!values.pointLongitude || !!values.pointLatitude}
        >
          Добавить координаты на карте
        </Button>
      </Flex>
      <Grid templateColumns="repeat(4, 1fr)" gap={5} marginTop={5}>
        <GridItem>
          <Field
            as={Input}
            name={`${name}.pointNumber`}
            label="№ точки"
            value={values.pointNumber}
            error={errors?.points && errors.points[index]?.pointNumber}
            onChange={handleChange}
          />
        </GridItem>
        <GridItem>
          <Field
            as={Select}
            label="Тип выезда"
            name={`${name}.pointTypeId`}
            options={pointTypeId}
            onChange={(e: any) => {
              setFieldValue(`${name}.pointLatitude`, '');
              setFieldValue(`${name}.pointLongitude`, '');
              setFieldValue(`${name}.pointCoordinateArray`, '');
              handleChange(e);
            }}
            error={errors?.points && errors.points[index]?.pointTypeId}
          />
        </GridItem>
        <GridItem>
          <Field
            as={Input}
            label="Внутренний номер протокола"
            name={`${name}.protocolNumber`}
            onChange={handleChange}
          />
        </GridItem>
        <GridItem>
          <Button
            leftIcon={<ViewIcon2 />}
            variant="ghost"
            color="PrimaryButton.500"
            size="sm"
            p={0}
            onClick={() => protocolRegister()}
            marginLeft="auto"
            isDisabled={!!values.protocolNumber}
            marginTop={2}
          >
            Регистрация номера протокола
          </Button>
        </GridItem>
      </Grid>
      <Grid marginBottom={2} templateColumns="repeat(4, 1fr)" gap={5} marginTop={5}>
        <Field
          as={Select}
          name={`${name}.pelStationNumber`}
          label="№ станции ПЭЛ"
          error={false}
          options={pelStations}
          value={values.pelStationNumber}
          onChange={handleChange}
        />
        <Field
          as={DateInput}
          name={`${name}.measurementTimeFrom`}
          label="Время измерений с"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setFieldValue(e.target.name, createUTCTime(e.target.value))
          }
          error={false}
          showTimeInput
        />
        <Field
          as={DateInput}
          name={`${name}.measurementTimeTo`}
          label="Время измерений до"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setFieldValue(e.target.name, createUTCTime(e.target.value))
          }
          error={false}
          showTimeInput
        />
        <Field
          as={Select}
          name={`${name}.odorSourceTypeId`}
          label="Тип источника"
          options={sourceTypes}
          onChange={handleChange}
          error={errors?.points && errors.points[index]?.odorSourceTypeId}
        />
      </Grid>
      <Grid marginBottom={2} templateColumns="repeat(4, 1fr)" gap={5} marginTop={5}>
        {pointTypeId?.find((item) => item.value === values.pointTypeId)?.name !== 'Область обследования' ? (
          <>
            <GridItem colStart={1} colEnd={3}>
              <Field
                as={AsyncAddressSelect}
                name={`${name}.pointAddress`}
                label="Адресный ориентир"
                error={false}
                onChange={handleChange}
              />
            </GridItem>
            <GridItem>
              <Field
                as={Input}
                name={`${name}.pointLatitude`}
                label="Широта"
                value={values.pointLatitude}
                error={errors?.points && errors.points[index]?.pointLatitude}
                onChange={handleChange}
              />
            </GridItem>
            <GridItem>
              <Field
                as={Input}
                name={`${name}.pointLongitude`}
                label="Долгота"
                value={values.pointLongitude}
                error={errors?.points && errors.points[index]?.pointLongitude}
                onChange={handleChange}
              />
            </GridItem>
          </>
        ) : (
          <>
            <GridItem colStart={1} colEnd={3}>
              <Field
                as={Input}
                name={`${name}.pointAddress`}
                label="Адресный ориентир"
                error={false}
                onChange={handleChange}
              />
            </GridItem>
            <GridItem colStart={3} colEnd={5}>
              <Field
                as={Input}
                name={`${name}.pointCoordinateArray`}
                label="Массив координат контура"
                value={values.pointCoordinateArray}
                error={errors?.points && errors.points[index]?.pointCoordinateArray}
                onChange={handleChange}
              />
            </GridItem>
          </>
        )}
      </Grid>
      <Grid gap={5} templateColumns="repeat(2, 1fr)" marginTop={5}>
        <CustomAutocomplete
          value={values.raidResultText}
          name={`${name}.raidResultText`}
          label="Результат рейда"
          options={raidResultId}
        />
        <Field
          as={Select}
          name={`${name}.protocolExecutorUserId`}
          label="Исполнитель"
          options={executors}
          onChange={handleChange}
          error={false}
        />
      </Grid>
      <Grid gap={5} templateColumns="repeat(4, 1fr)">
        <Grid templateRows="repeat(4, 1fr)">
          <Field
            as={Checkbox}
            name={`${name}.isSetPelProtocol`}
            label="Данные измерений ПЭЛ"
            checked={values.isSetPelProtocol}
            error={false}
          />
          <Field as={Input} name={`${name}.pelProtocolNumber`} label="№ протокола ПЭЛ" error={false} />
          <Field
            as={DateInput}
            name={`${name}.pelProtocolDate`}
            label="Дата протокола ПЭЛ"
            error={false}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setFieldValue(e.target.name, createUTCTime(e.target.value))
            }
          />

          {values.id && taskId && values.pelAirProtocolId ? (
            <Flex>
              <Button
                leftIcon={<ViewIcon2 />}
                variant="ghost"
                color="PrimaryButton.500"
                size="sm"
                p={0}
                onClick={() => {
                  unbindProtocol({}, { url: `appeal/v10/points/${values.id}/break/${values.pelAirProtocolId}` });
                }}
              >
                Отвязать протокол (ПЭЛ)
              </Button>
              <Button
                leftIcon={<DownloadIcon />}
                variant="ghost"
                color="PrimaryButton.500"
                size="sm"
                p={0}
                marginLeft={5}
                marginRight={12}
                onClick={() => onProtocolDownload(false, values.id)}
              />
            </Flex>
          ) : (
            <Button
              leftIcon={<ViewIcon2 />}
              variant="ghost"
              color="PrimaryButton.500"
              size="sm"
              p={0}
              marginRight="auto"
              onClick={values.id ? onProtocolOpen : () => saveThenOpenProtocol('pel')}
            >
              Привязать протокол (ПЭЛ)
            </Button>
          )}

        </Grid>
        <Grid templateRows="repeat(4, 1fr)">
          <Field
            as={Checkbox}
            name={`${name}.isSetAiProtocol`}
            label="Данные измерений АИ"
            checked={values.isSetAiProtocol}
            error={false}
          />
          <Field as={Input} name={`${name}.aiProtocolNumber`} label="№ протокола АИ" error={false} />
          <Field
            as={DateInput}
            name={`${name}.aiProtocolDate`}
            label="Дата протокола АИ"
            error={false}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setFieldValue(e.target.name, createUTCTime(e.target.value))
            }
          />

          {values.id && taskId && values.aiAirProtocolId ? (
            <Flex>
              <Button
                leftIcon={<ViewIcon2 />}
                variant="ghost"
                color="PrimaryButton.500"
                size="sm"
                p={0}
                onClick={() => {
                  unbindAiProtocol({}, { url: `appeal/v10/points/${values.id}/break/${values.aiAirProtocolId}` });
                }}
              >
                Отвязать протокол (АИ)
              </Button>
              <Button
                leftIcon={<DownloadIcon />}
                variant="ghost"
                color="PrimaryButton.500"
                size="sm"
                p={0}
                marginLeft={5}
                onClick={() => onProtocolDownload(true, values.id)}
              />
            </Flex>
          ) : (
            <Button
              leftIcon={<ViewIcon2 />}
              variant="ghost"
              color="PrimaryButton.500"
              size="sm"
              p={0}
              marginRight="auto"
              onClick={values.id ? onAIProtocolOpen : () => saveThenOpenProtocol('ai')}
            >
              Привязать протокол (АИ)
            </Button>
          )}

        </Grid>
        <Grid templateRows="repeat(4, 1fr)">
          <Field
            as={Checkbox}
            name={`${name}.hasExcess`}
            label="Превышение есть"
            checked={values.hasExcess}
            error={false}
          />
        </Grid>
        {values.pointTypeId === garbageBurningPoint ? (
          <Grid templateRows="repeat(4, 1fr)">
            <Field
              as={Checkbox}
              name={`${name}.isGarbagePileFound`}
              label="Обнаружены навалы мусора"
              checked={values.isGarbagePileFound}
              error={false}
            />
            <Field
              as={Checkbox}
              name={`${name}.isBurningSmellFound`}
              label="Обнаружено сжигание мусора"
              checked={values.isBurningSmellFound}
              error={false}
            />
          </Grid>
        ) : null}
      </Grid>
      <Button
        leftIcon={<ViewIcon2 />}
        variant="ghost"
        color="PrimaryButton.500"
        size="sm"
        p={0}
        onClick={() => onOpen()}
      >
        Смотреть данные фтп
      </Button>
      <Grid marginTop={5}>
        <Field as={Textarea} name={`${name}.report`} label="Комментарий" error={false} />
      </Grid>
      {/* Алексей новосельцев */}
      {/* <MaterialTable */}
      {/*  columns={[ */}
      {/*    { */}
      {/*      title: 'Наименование вещества', */}
      {/*      field: 'substanceName', */}
      {/*    }, */}
      {/*    { */}
      {/*      title: 'Результат измерений', */}
      {/*      field: 'measurmentResult', */}
      {/*    }, */}
      {/*    { */}
      {/*      title: 'ПДК', */}
      {/*      field: 'pdk', */}
      {/*    }, */}
      {/*  ]} */}
      {/* /> */}
      {/* <Grid marginTop={3} gap={2} templateColumns="repeat(4, 1fr)"> */}
      {/*  <Button>Добавить вещество</Button> */}
      {/*  <Button>Удалить вещество</Button> */}
      {/* </Grid> */}
      <Grid marginTop={5}>
        <Button
          variant="ghost"
          color="PrimaryButton.500"
          size="sm"
          p={0}
          marginRight="auto"
          onClick={() => onAttachmentOpen()}
        >
          Вложения
        </Button>
      </Grid>
      <TaskAttachments
        isOpen={isAttachmentOpen}
        onClose={onAttachmentClose}
        objectType={UploadObjectType.REPORT_OBSERVATION_POINT}
        uploadItemId={values.id && values.id}
        media={currentMedia}
        setMedia={setCurrentMedia}
        handleSubmit={() => {
          onMediaSave(currentMedia, index);
        }}
      />
      <PelFTPData
        stations={pelStations}
        isOpen={isOpen}
        onClose={onClose}
        stationId={values.pelStationNumber}
        fromDate={values.measurementTimeFrom}
        toDate={values.measurementTimeTo}
      />
      <Modal
        isOpen={isProtocolsOpen}
        onClose={onProtocolClose}
        width="100%"
        customCloseButton={
          <Button
            onClick={onProtocolClose}
            marginTop={5}
            marginRight="auto"
            variant="ghost"
            color="gray"
            marginLeft="20px"
            leftIcon={<Close />}
          >
            Закрыть
          </Button>
        }
      >
        <Grid width="100%">
          <Heading marginBottom={10}>Привязка протокола</Heading>
          <ReportSetProtocolModal
            customClose={() => customClose(onProtocolClose)}
            type="Воздух"
            pointId={values.id}
            field={`${name}.pelAirProtocolId`}
            protocolIdAdder={protocolIdAdder}
          />
        </Grid>
      </Modal>
      <Modal
        isOpen={isAIProtocolsOpen}
        onClose={onAIProtocolClose}
        width="100%"
        customCloseButton={
          <Button
            onClick={onAIProtocolClose}
            marginTop={5}
            marginRight="auto"
            variant="ghost"
            color="gray"
            marginLeft="20px"
            leftIcon={<Close />}
          >
            Закрыть
          </Button>
        }
      >
        <Grid width="100%">
          <Heading marginBottom={10}>Привязка протокола</Heading>
          <ReportSetProtocolModal
            customClose={() => customClose(onAIProtocolClose)}
            type="Воздух"
            pointId={values.id}
            field={`${name}.aiAirProtocolId`}
            protocolIdAdder={protocolIdAdder}
          />
        </Grid>
      </Modal>
    </Box>
  );
};
export default BurningJournalObservationPlace;
