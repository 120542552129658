import { useRequest } from 'hooks/useRequest';
import { TDictionariesArray } from 'models/dictionaries';
import { useUtilDictionaries } from 'hooks/useUtilDictionaries';
import { useEffect } from 'react';
import { MeteoDataApiRoot } from 'pages/data/Meteo/menu';

type DictionariesNames =
  | 'sources'
  | 'sourceTypes'
  | 'parameters'
  | 'heights'
  | 'calculationOperations'
  | 'calculationFields'
  | 'allHeights'
  | 'stations';

type SourceTypes = 'ASKZA' | 'ASKZV' | 'PROFILEMER' | 'NOISE' | 'INCLINOMETR' | 'ACTINOMETR' | 'VIRTUAL';

type IMeteoIDictionaries = {
  data: TDictionariesArray<DictionariesNames, SourceTypes, SourceTypes>;
};

export const useMeteoDictionaries = (typeId = '2') => {
  const { get, result, isLoading, error } = useRequest<IMeteoIDictionaries>(
    `${MeteoDataApiRoot}/dictionaries/${typeId}`,
  );

  useEffect(() => {
    get();
  }, [get]);

  const dictionaries = useUtilDictionaries(result?.data ?? []);

  return {
    ...dictionaries,
    isLoading,
    error,
  };
};
