import { Field, useFormikContext } from 'formik';
import { IFilterItem, TDictionariesArray } from 'models';
import { DateInput, Select } from 'components/common';
import { Grid, GridItem } from '@chakra-ui/react';
import appLocale from 'constants/appLocale';
import React, { useMemo } from 'react';
import { createUTCTime } from 'lib/create-date';
import { getSelectOptionsFromDictionaryByName } from 'features/get-select-options-from-dictionary';
import FilterFormControls from 'components/form/FilterFormControls';

interface IProps {
  dictionaries: { data: TDictionariesArray } | null;
}

export const FormFields = ({ dictionaries }: IProps) => {
  const { submitForm, setFieldValue, handleReset, values, errors } = useFormikContext<{ [p: string]: IFilterItem }>();

  const reports = useMemo(
    () => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'reports'),
    [dictionaries],
  );

  const statuses = useMemo(
    () => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'statuses'),
    [dictionaries],
  );

  return (
    <>
      <Grid gap="12px" rowGap="8px" templateColumns="repeat(4, 0.25fr)">
        <Field
          as={DateInput}
          name="startDate.value"
          label={appLocale.common.from}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setFieldValue('startDate.value', createUTCTime(e.target.value))
          }
          showTimeInput
          error={errors.startDate?.value}
        />

        <Field
          as={DateInput}
          name="endDate.value"
          label={appLocale.common.to}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setFieldValue('endDate.value', createUTCTime(e.target.value))
          }
          showTimeInput
          error={errors.endDate?.value}
        />

        <Field
          as={Select}
          name="reportName.value"
          label="Наименование отчета"
          options={reports}
          value={values.reportName.value}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFieldValue('reportName.value', e.target.value)}
        />

        <Field
          as={Select}
          name="uploadStatusName.value"
          label="Статус выгрузки"
          options={statuses}
          value={values.uploadStatusName.value}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFieldValue('uploadStatusName.value', e.target.value)}
        />

        <GridItem rowStart={1} colStart={5}>
          <FilterFormControls
            onReset={(e) => {
              handleReset(e);
              setTimeout(submitForm, 0);
            }}
            onSubmit={submitForm}
          />
        </GridItem>
      </Grid>
    </>
  );
};
