import React, { useMemo } from 'react';
import { fieldsLocale } from '../../../locale';
import { IFilterItem, TFilterDTO, TFiltersArray } from 'models';
import combineFilters from 'lib/utils/combineFilters';
import { createInitialValuesToFormik, createValuesToRequest } from 'features/Filter/utils';
import { Filter } from 'features/Filter';
import { transformFiltersArray } from 'features/Filter/transformFiltersArray';

interface FilterProps {
  isLoading?: boolean;
  isDisabled?: boolean;
  onSubmit: (values: any) => void;
  filters: TFiltersArray;
}

type FilterListInitialValues = Record<'stationName' | 'deleted',
  TFilterDTO<'stationName' | 'deleted'>['filters'][number]>;

const initialValues: FilterListInitialValues = {
  stationName: {
    key: 'stationName',
    operation: 'CONTAINS',
    value: '',
  },
  deleted: {
    key: 'deleted',
    operation: 'EQ',
    value: false,
  },
};

export const StationsTableFilter = ({ isLoading, isDisabled, onSubmit, filters }: FilterProps) => {
  const presetValues = useMemo<{ key: string; value: IFilterItem['value'] }[]>(() => {
    const preset: { key: string; value: IFilterItem['value'] }[] = [];
    const initialFilters = combineFilters(initialValues, filters);
    Object.keys(initialFilters)
      .forEach((key) => {
        preset.push({ key: `${key}`, value: initialFilters[key].value });
      });
    return preset;
  }, [filters]);

  const initialValuesToFormik = createInitialValuesToFormik(initialValues);

  return (
    <Filter<typeof initialValuesToFormik>
      isLoading={isLoading}
      isDisabled={isDisabled}
      onSubmit={(values) => {
        const v = createValuesToRequest(initialValues, values);
        const filters = transformFiltersArray<TFilterDTO<'stationName' | 'deleted'>['filters']>(
          Object.values(v),
        );
        return onSubmit(filters);
      }}
      initialValues={initialValuesToFormik}
      mainFields={[
        {
          type: 'TEXT',
          name: 'stationName',
          label: fieldsLocale.stationName,
        },
      ]}
      presetValues={presetValues}
    />
  );
};
