import { useRequest } from 'hooks/useRequest';
import { FormikProvider, useFormik } from 'formik';
import { createTouchedErrors } from 'features/create-touched-errors';

import { useLocation } from 'react-router-dom';
import { FormHeader, LoaderItem } from 'components/common';

import React, { useMemo, useState } from 'react';
import { getSelectOptionsFromDictionaryByName } from 'features/get-select-options-from-dictionary';
import { GroundwaterMonitoringSchema } from 'models/schemas/groundwaters/monitoring';
import { GeoecologicalRootPath } from 'pages/data/GeoecologicalProcesses/consts';
import { initialEmptyValues } from './AddMonitoringResult';
import FieldsForm from './FieldsForm';
import { IUploadMedia } from 'components/common/FileUploadDragAndDrop/types';
import { updateFiles } from 'api/services/media';
import { useObjectType } from 'hooks/useObjectType';
import { MonitoringItem } from 'pages/data/GeoecologicalProcesses/GeologicalProcessesGroundwater/Monitoring/types';
import subsystemIdsMap from 'constants/subsystemIdsMap';
import { useAvailability } from 'hooks/useAvailability';
import { useGoBackWithFallback } from 'lib/utils/goBackWithFallback';
import { useDictionary } from 'hooks/useDictionary';
import { useEnhancedNotification } from 'hooks';

export const EditMonitoringResult = () => {
  const { backWithFallback } = useGoBackWithFallback();
  const { isWrite, isNoRights } = useAvailability(subsystemIdsMap.geoecologyRoot);
  const location = useLocation<MonitoringItem>();
  const { isDictionariesLoading, dictionaries } = useDictionary({
    url: '/geoecology/v10/geoecology/watersource/monitoring/dictionaries',
  });
  const [media, setMedia] = useState<IUploadMedia[]>([]);

  const objectType = useObjectType(dictionaries);

  const editedObject = location.state;

  const { onSuccessEdit, onErrorEdit } = useEnhancedNotification();

  const onSuccess = () => {
    onSuccessEdit();

    updateFiles(editedObject.id, objectType, media)
      .then(() => {
        backWithFallback(`${GeoecologicalRootPath}/monitoring/water/examination`);
      })
      .catch((error: Error) => {
        onErrorEdit(error.message);
      });
  };

  const { put, isLoading: isPutLoading } = useRequest(
    '/geoecology/v10/geoecology/watersource/monitoring',
    onSuccess,
    onErrorEdit,
  );

  const ecologicalStates = useMemo(
    () => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'ecologicalStates'),
    [dictionaries],
  );

  const capturing = useMemo(
    () => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'capturingStates'),
    [dictionaries],
  );

  const initialValues = {
    ...initialEmptyValues,
    ...editedObject,
    ecologicalState: {
      ...editedObject.ecologicalState,
      value: ecologicalStates.find((el) => el.name === editedObject.ecologicalState?.value)?.value || '',
    },
    capturingState: {
      ...editedObject.capturingState,
      value: capturing.find((el) => el.name === editedObject.capturingState?.value)?.value || '',
    },
  };

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    initialValues,
    validationSchema: GroundwaterMonitoringSchema,
    onSubmit: (values) => put(values),
  });

  if (isPutLoading || isDictionariesLoading) {
    return <LoaderItem />;
  }

  return (
    <FormikProvider
      value={{
        ...formik,
        errors: createTouchedErrors(formik.errors, formik.touched),
      }}
    >
      <FormHeader
        onBackClick={() => backWithFallback(`${GeoecologicalRootPath}/monitoring/water/examination`)}
        header="Редактирование результатов обследования"
      />
      <FieldsForm
        isWrite={isWrite || isNoRights}
        dictionaries={dictionaries}
        onCancel={() => backWithFallback(`${GeoecologicalRootPath}/monitoring/water/examination`)}
        media={media}
        onMediaChange={setMedia}
        uploadItemId={editedObject.id}
        isEditMode
      />
    </FormikProvider>
  );
};
