import { useList } from 'hooks';
import { useEffect, useState } from 'react';
import { TFiltersArray } from 'models';
import { MaterialTable } from 'features/material-table/MaterialTableMini';
import { EquipmentReportsPathsAPI } from '../EquipmentReportsPathsAPI';
import { useDeviceTableColumns } from '../hooks/useDeviceTableColumns';
import { Options } from 'pages/equipment/Devices/types';
import { DEFAULT_PAGE_SIZE_NEW_TABLE } from '../../../../constants/tables';

interface IProps {
  filters: TFiltersArray;
  statuses?: Options;
}

export const EquipmentReportTableView = ({ filters, statuses }: IProps) => {
  const {
    response,
    isLoading,
    setFilters,
    toggleOrder,
    filterDTO,
    refetch,
    onPageChange,
    onSearchText,
    errorText,
  } = useList<any>(
    EquipmentReportsPathsAPI.table,
    { initialOrderFieldKey: '', preventAutoFetchData: true, defaultPageSize: DEFAULT_PAGE_SIZE_NEW_TABLE }
  );

  useEffect(() => {
    if (filters.length) {
      setFilters(filters);
    }
  }, [filters, setFilters]);

  useEffect(() => {
    // startDate and endDate filters
    if (filterDTO.filters.length) {
      refetch();
    }
  }, [filterDTO.filters.length, refetch]);
  const columns = useDeviceTableColumns();

  const responseData = response?.data;

  const [tableData, setTableData] = useState([]);
  useEffect(() => {
    if (responseData && statuses) {
      const newData = responseData.map((item) => {
        const foundStatus = statuses.find((status) => status.value === item.statusId);
        // @ts-ignore
        if (foundStatus) {
          item.statusId = foundStatus.name;
        }

        return item;
      });
      // @ts-ignore
      setTableData(newData);
    }
  }, [responseData, statuses]);

  return (
    <MaterialTable
      filterDTO={filterDTO}
      data={tableData}
      meta={response?.meta}
      columns={columns}
      toggleOrder={toggleOrder}
      onPageChange={onPageChange}
      options={{
        search: true,
        selection: false,
      }}
      isLoading={isLoading}
      onSearch={onSearchText}
      errorText={errorText}
    />
  );
};
