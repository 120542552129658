import React, { useEffect, useState } from 'react';
import { Tab, TabList, Tabs } from '@chakra-ui/react';
import { DefaultText } from 'components/common';
import { useHistory, useRouteMatch } from 'react-router-dom';

interface IProps {
  tabs: { name: string; path: string; query?: string }[];
}

export const TableTabs: React.FC<IProps> = ({ tabs }) => {
  const { path } = useRouteMatch();
  const { push } = useHistory();
  const [index, setIndex] = useState<number>();

  useEffect(() => {
    setIndex(tabs.findIndex((tab) => tab.path === path));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (index !== undefined && tabs[index].path !== path) {
      push({
        pathname: tabs[index].path,
        search: tabs[index].query || undefined
      });
    }
  }, [index, path, push, tabs]);

  return (
    <Tabs index={index} onChange={setIndex}>
      <TabList>
        {tabs.map((tab) => tab.name)
          .map((tab, i) => (
            <Tab transition="none" key={i} height="30px">
              <DefaultText>{tab}</DefaultText>
            </Tab>
          ))}
      </TabList>
    </Tabs>
  );
};
