import React from 'react';
import { Checkbox, Input, Select } from 'components/common';
import { Field } from 'formik';
import { IDictionarySchemaField } from '../../const/types';

interface Props {
  field: IDictionarySchemaField;
  fieldKey: string;
  error: string;
  value?: string | number | null | boolean;
  isDisabled?: boolean;
}

export const FormField: React.FC<Props> = ({ field, fieldKey, error, value = '', isDisabled }) => {
  switch (field.field_type) {
    case 'number': {
      return (
        <Field isDisabled={isDisabled} as={Input} name={`value[${fieldKey}]`} label={field.title} type="number" error={error} value={value} />
      );
    }
    case 'text': {
      return (
        <Field isDisabled={isDisabled} as={Input} name={`value[${fieldKey}]`} label={field.title} type="text" error={error} value={value} />
      );
    }
    case 'select': {
      return (
        <Field
          isDisabled={isDisabled}
          as={Select}
          name={`value[${fieldKey}]`}
          label={field.title}
          options={field.options?.map((textVal) => ({
            value: textVal,
            name: textVal,
          }))}
          value={value}
          error={error}
        />
      );
    }
    case 'boolean': {
      return (
        <Field
          isDisabled={isDisabled}
          as={Checkbox}
          name={`value[${fieldKey}]`}
          label={field.title}
          isChecked={value}
          error={error}
        />
      );
    }
    default: {
      return null;
    }
  }
};
