import { PortalPopover } from 'components/common';
import { Flex } from '@chakra-ui/react';
import { ErrorTableTdBackdrop } from 'features/material-table/components/ErrorTableTdBackdrop';
import { dangerProcessPopoverProps } from '../constants';
import { DangerProcessAreaPortalPopover } from '../components/DangerProcessAreaPortalPopover';
import { DangerProcessPointPortalPopover } from '../components/DangerProcessPointPortalPopover';
import { FilePreviewFieldValue } from 'models';

export const CellArea = (label: string | number | null, id: string, error?: string | null) => {
  if (error) {
    return <ErrorTableTdBackdrop>{error}</ErrorTableTdBackdrop>;
  }

  return (
    <Flex position="relative" height="100%" p={0}>
      <DangerProcessAreaPortalPopover label={label} id={id} />
    </Flex>
  );
};

export const CellPoint = (label: string, id: string, error?: string | null) => {
  if (error) {
    return <ErrorTableTdBackdrop>{error}</ErrorTableTdBackdrop>;
  }

  return (
    <Flex position="relative" height="100%" p={0}>
      <DangerProcessPointPortalPopover label={label} id={id} />
    </Flex>
  );
};

export const CellValue = (value: FilePreviewFieldValue['value'], error?: string | null) => {
  if (error) {
    return <ErrorTableTdBackdrop>{error}</ErrorTableTdBackdrop>;
  }

  return (
    <PortalPopover
      variant="ghost"
      padding={0}
      {...dangerProcessPopoverProps}
      textDecoration="none"
      textDecorationStyle="unset"
      label={value?.toString() || ''}
      PopoverProps={{
        placement: 'bottom-start',
      }}
      style={{
        fontSize: 'inherit',
        lineHeight: 'inherit',
      }}
    >
      <Flex padding={4} fontSize="16px" lineHeight="18px" whiteSpace="initial" wordBreak="break-all">{value}</Flex>
    </PortalPopover>
  );
};
