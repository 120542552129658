import React, { useCallback, useState } from 'react';
import { Spinner } from '@chakra-ui/react';
import { ERROR_MESSAGES, SUCCESS_MESSAGES } from 'constants/messages';
import { useNotifications } from 'hooks/useNotifications';
import { useHistory, useParams } from 'react-router-dom';
import { DeviceForm } from 'pages/equipment/Devices/components/DeviceForm';
import { DeviceLayout } from 'pages/equipment/Devices/components/DeviceLayout';
import { FormContainer } from 'pages/equipment/Devices/components/DeviceForm/FormContainer';
import { useDevice } from 'pages/equipment/Devices/hooks/useDevice';
import { DeviceVerifications } from 'pages/equipment/Devices/components/DeviceVerifications';
import { useEnhancedNotification, useRequest } from 'hooks';
import { Device } from 'pages/equipment/Devices/types';
import { useEquipmentDeviceBreadcrumbs } from './hooks/useEquipmentDeviceBreadcrumbs';
import { IUploadMedia } from 'components/common/FileUploadDragAndDrop/types';
import { updateFiles, UploadObjectType } from 'api/services/media';

export const EditDevice: React.FC = () => {
  const { push } = useHistory();
  const { onErrorEdit } = useEnhancedNotification();
  const [media, setMedia] = useState<IUploadMedia[]>([]);
  const { id: deviceId } = useParams<{ id: string }>();
  useEquipmentDeviceBreadcrumbs();
  const { successNotify, errorNotify } = useNotifications();

  const [value, setValue] = React.useState(0);
  const { newData } = useParams<{ newData: string }>();
  const handleChange = (newValue: number) => {
    setValue(newValue);
  };

  const onGetDeviceError = useCallback(() => {
    errorNotify({
      key: 'file/edit/error',
      message: ERROR_MESSAGES.GET,
    });
  }, [errorNotify]);
  const { data, isLoading } = useDevice(deviceId, onGetDeviceError);

  const onSuccess = () => {
    if (data) {
      updateFiles(data.id, UploadObjectType.EQUIPMENT_DEVICE, media)
        .then(() => {
          successNotify({
            key: 'file/edit/success',
            message: SUCCESS_MESSAGES.CREATE,
          });
        })
        .catch((error: any) => {
          onErrorEdit(error.message);
        });
    } else {
      onErrorEdit();
    }
  };
  const onError = () =>
    errorNotify({
      key: 'file/edit/error',
      message: ERROR_MESSAGES.CREATE,
    });
  const { put } = useRequest<Device>('/equipment/v10/equipments/instruments', onSuccess, onError);
  const onSubmit = useCallback(
    (item: Partial<Device> | null) => {
      if (!item || !item.id) {
        return;
      }

      put({
        deviceId: item.id,
        status: item.status,
        stationId: item.stationId,
        modelIds: item.modelIds,
        supplierId: item.supplierId,
        warrantyEndDate: item.warrantyEndDate,
      });
    },
    [put]
  );

  return (
    <DeviceLayout
      title={`${newData === 'true' ? 'Добавление' : 'Редактирование'} ${value === 1 ? 'поверки' : 'прибора'}`}
      device={
        isLoading || !data ? (
          <Spinner />
        ) : (
          <FormContainer>
            <DeviceForm isDisabled />
          </FormContainer>
        )
      }
      verification={<DeviceVerifications deviceId={deviceId} />}
      value={value}
      handleChange={handleChange}
      onMediaChange={setMedia}
      media={media}
      uploadItemId={data?.id}
      onSubmit={onSubmit}
      initialValues={data || {}}
      onCancel={() => {
        push('/equipment/devices');
      }}
    />
  );
};
