import { Box, Flex, FlexProps } from '@chakra-ui/react';

interface LabelTextProps extends FlexProps {
  label: string;
}

export const LabelText: React.FC<LabelTextProps> = ({ label, flexDirection = 'column', children, ...props }) => (
  <Flex flexDirection={flexDirection} alignItems="center" {...props}>
    <Box width="100%">{label}</Box>
    <Box width="100%">{children}</Box>
  </Flex>
);
