import { MaterialTable } from 'features/material-table/MaterialTableMini';
import { useUploadFileProvider } from 'features/UploadedFileProvider';
import { Column } from '@material-table/core';
import { useMemo } from 'react';
import { MeteoDataOtherOstankinoMeasure } from './types';

interface MeteoDataOtherOstankinoPreviewProps {
  typeId: string;
}

const MeteoDataOtherOstankinoPreview: React.FC<MeteoDataOtherOstankinoPreviewProps> = () => {
  const { preview } = useUploadFileProvider<MeteoDataOtherOstankinoMeasure>();

  const columns = useMemo<Column<MeteoDataOtherOstankinoMeasure>[]>(
    () => [
      {
        title: 'Дата и время',
        field: 'date',
        render: (row) => row.date,
      },
      { title: 'Группа', field: 'group' },
      { title: 'Тип', field: 'type' },
      { title: 'Точка градиента', field: 'gradientPoint.value' },
      { title: 'Параметр', field: 'parameter.value' },
      { title: 'Станция', field: 'station.value' },
    ],
    [],
  );

  return (
    <MaterialTable
      data={preview?.result?.measures}
      columns={columns}
    />
  );
};

export { MeteoDataOtherOstankinoPreview };
