import { Flex, Spinner } from '@chakra-ui/react';
import React from 'react';

interface IChartNoData {
  isLoading: boolean;
  title?: string;
}

export const ChartNoData = ({ isLoading, title = 'Выберите период и запросите данные' }: IChartNoData) => (
  <>
    {isLoading ? (
      <Flex py={5} justifyContent="center">
        <Spinner />
      </Flex>
    ) : (
      <Flex py={5} justifyContent="center">
        {title}
      </Flex>
    )}
  </>
);
