import { FormWrapper, Input, Select } from 'components/common';
import { Box, Grid, Heading } from '@chakra-ui/react';
import { Field, useFormikContext } from 'formik';
import React, { useMemo } from 'react';
import { IReportProtocolForm } from '../types';
import { TDictionariesArray } from 'models';
import { getSelectRichOptionsFromDictionaryByName } from 'features/get-select-options-from-dictionary';
import { createLocalDateDDMMYYYYWithTime } from 'lib/create-date';

interface IProps {
  dictionaries: {
    data: TDictionariesArray;
  } | null;
}

export const AnalyticsPelNoiseDeviceTab = ({ dictionaries }: IProps) => {
  const { values } = useFormikContext<IReportProtocolForm>();

  const pribors = useMemo(
    () => getSelectRichOptionsFromDictionaryByName(dictionaries?.data, 'pribors'),
    [dictionaries],
  );

  const selectedDevice = pribors.find((device) => device.value === values.deviceId);

  return (
    <Box>
      <FormWrapper>
        <Grid marginTop={5} templateColumns="repeat(1, 1fr)" gap={5}>
          <Heading size="md" marginBottom={5}>
            Сведения о примененных средствах измерений уровней шума
          </Heading>
          <Field
            as={Select}
            name="deviceId"
            options={pribors}
            label="Наименование средства измерений"
          />

          <Field
            as={Select}
            name="deviceId"
            options={pribors.map((device) => ({
              ...device,
              name: device.serialNumber,
            }))}
            label="Заводской номер"
          />

          <Field
            as={Input}
            value={selectedDevice?.documentNumber || ''}
            label="Номер свидетельства о поверке"
            isDisabled
          />
          <Field
            as={Input}
            value={selectedDevice?.date ? createLocalDateDDMMYYYYWithTime(selectedDevice?.date) : ''}
            label="Поверка действительна до"
            isDisabled
          />
        </Grid>
      </FormWrapper>
    </Box>
  );
};
