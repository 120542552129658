import { Box, Flex, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { Button, LoaderItem } from 'components/common';
import { useFormikContext } from 'formik';
import React, { useRef } from 'react';
import { usePaginatedLoader } from 'pages/admin/typedLoader';
import { DepartmentItem } from 'pages/admin/models/DepartmentItem';
import { baseAdminUrl } from 'pages/admin';
import { UserFormObj } from '../UserFormObj';
import { UserRolesForm } from './UserRolesForm';
import { UserCommonForm } from './UserCommonForm';
import { useNotifications } from 'hooks/useNotifications';
import { OrganizationItem } from 'pages/admin/OrganizationItem';

export interface FieldFormProps {
  onCancel: () => void;
  isLoading: boolean | undefined;
}

const FieldsForm: React.FC<FieldFormProps> = ({ onCancel, isLoading }) => {
  const { setFieldValue, values, errors, touched, handleSubmit, isValid } = useFormikContext<UserFormObj>();
  const { errorNotify } = useNotifications();

  const rolesRef: any = useRef();

  const { result: departmentList, isLoading: isDepartmentListLoading } = usePaginatedLoader<DepartmentItem>(
    `${baseAdminUrl}/department`,
  );
  const { result: organizationList, isLoading: isOrganizationListLoading } = usePaginatedLoader<OrganizationItem>(
    `${baseAdminUrl}/organization`
  );
  const departments = departmentList?.data.map((d) => ({
    name: `${d.name} (${d.code})`,
    value: d.id,
  }));
  const organizations = organizationList?.data.map((o) => ({
    name: `${o.name}`,
    value: o.id
  }));

  if (isLoading || isDepartmentListLoading || isOrganizationListLoading) {
    return <LoaderItem />;
  }

  return (
    <Box flexDirection="column">
      <Tabs isLazy variant="enclosed" margin={1}>
        <TabList>
          <Tab>Общие сведения</Tab>
          <Tab>Роли</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            {/* Общие свойства */}
            <UserCommonForm
              touched={touched}
              departments={departments || []}
              organizations={organizations || []}
              values={values}
              errors={errors}
              setFieldValue={setFieldValue}
            />
          </TabPanel>
          <TabPanel>
            {/* Роли пользователя */}
            <UserRolesForm
              rolesRef={rolesRef}
              values={values}
              setFieldValue={setFieldValue}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>

      <Flex>
        <Button
          onClick={() => {
            if (!isValid) {
              errorNotify({
                key: 'user-save-error',
                message: 'Пожалуйста, проверьте правильность введенных данных',
              });
            }
            handleSubmit();
          }}
          variant="solid"
          margin={3}
        >
          Сохранить
        </Button>
        <Button onClick={onCancel} margin={3} paddingLeft={6} paddingRight={6}>
          Отмена
        </Button>
      </Flex>
    </Box>
  );
};

export default React.memo(FieldsForm);
