export enum ExtensionToMimeTypeEnum {
  'csv' = 'text/csv',
  'xlsx' = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'pdf' = 'application/pdf',
  'docx' = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
}

const FileExportExtensions = {
  [ExtensionToMimeTypeEnum.csv]: '.csv',
  [ExtensionToMimeTypeEnum.xlsx]: '.xlsx',
  [ExtensionToMimeTypeEnum.pdf]: '.pdf',
  [ExtensionToMimeTypeEnum.docx]: '.docx',
};

const getFileExtensionByMimeType = (type: ExtensionToMimeTypeEnum): string => FileExportExtensions[type];

export default getFileExtensionByMimeType;
