import { VideoCamerasPath } from '../../consts/consts';
import { MaterialTable } from 'features/material-table/MaterialTableMini';
import React, { useMemo, useState } from 'react';
import { ERROR_MESSAGES, REMOVE_CONFIRMATION, SUCCESS_MESSAGES } from 'constants/messages';
import { IUseListMaterialTableProps, useDisclosure, useNotifications, useRequest } from 'hooks';
import { ConfirmModal } from 'components/common';
import { useHistory } from 'react-router-dom';
import { ListResponse } from 'models/api/list';
import { ICamItem } from '../types/types';
import { TFilterDTO } from 'models';
import { useDictionary } from 'hooks/useDictionary';
import { DefaultTableCellSize } from '../../../../components/table/DefaultTableCellSize';

interface IProps {
  refetch: () => void;
  response: ListResponse<ICamItem> | null;
  toggleOrder: (key: string) => void;
  filterDTO: TFilterDTO;
  onPageChange: (page: number, pageSize: number) => void;
  materialTableProps: IUseListMaterialTableProps;
  isWrite: boolean;
}

export const CamTable = (
  {
    refetch,
    response,
    toggleOrder,
    filterDTO,
    onPageChange,
    materialTableProps,
    isWrite,
  }: IProps
) => {
  const [removedItem, setRemovedItem] = useState('');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const history = useHistory();

  const { successNotify, errorNotify } = useNotifications();

  const { cameraTypes } = useDictionary({ url: '/appeal/v10/cameras/dictionaries' });

  const onSuccess = () => {
    onClose();
    refetch();
    successNotify({
      key: 'file/delete/success',
      message: SUCCESS_MESSAGES.DELETE,
    });
  };
  const onError = () => {
    onClose();
    errorNotify({
      key: 'file/delete/error',
      message: ERROR_MESSAGES.DELETE,
    });
  };

  const { remove } = useRequest('/appeal/v10/cameras', onSuccess, onError);

  const columns = useMemo(() => [
    {
      title: '№ камеры',
      field: 'number',
    },
    {
      title: 'Вид камеры',
      field: 'type',
      render: (row: any) => cameraTypes?.find((type) => type.value === row.type)?.name
    },
    {
      title: 'Широта',
      field: 'latitude',
    },
    {
      title: 'Долгота',
      field: 'longitude',
    },
    {
      title: 'Адрес расположения',
      field: 'address',
      render: (row) => <DefaultTableCellSize>{row.address}</DefaultTableCellSize>,
    },
    {
      title: 'Описание',
      field: 'description',
    },
    {
      title: 'Фактическое направление обзора',
      field: 'viewingAngle',
    },
  ], [cameraTypes]);

  return (
    <>
      <MaterialTable
        data={response?.data}
        meta={response?.meta}
        toggleOrder={toggleOrder}
        onPageChange={onPageChange}
        filterDTO={filterDTO}
        columns={columns}
        actions={[
          {
            type: 'edit',
            onClick: (_e, item) => {
              history.push(`${VideoCamerasPath}/edit/${item.id}`, item);
            },
            hidden: !isWrite
          },
          {
            type: 'delete',
            onClick: (_e, item) => {
              onOpen();
              setRemovedItem(item.id);
            },
            hidden: !isWrite
          },
        ]}
        {...materialTableProps}
      />

      <ConfirmModal
        isOpen={isOpen}
        onClose={onClose}
        onClick={() =>
          remove({
            url: `/appeal/v10/cameras/${removedItem}`,
          })
        }
        header={REMOVE_CONFIRMATION}
      />
    </>
  );
};
