import { Flex, Stack } from '@chakra-ui/react';
import { LabelText, PortalPopover } from 'components/common';
import { ErrorDescriptions } from 'features/error-descriptions-v-stack';
import { useRequest } from 'hooks/useRequest';
import { FC, memo } from 'react';
import { soilPopoverProps } from '../constants';

interface ObservationPointProps {
  label?: string;
  id: string;
}

interface ISoilPointData {
  latitude: string;
  longitude: string;
  funcZone: string;
  funcZoneType: string;
  notice: string;
}

interface ICellObservationPoint {
  label: string;
  id: string;
}

export const ObservationPointPopover: FC<ObservationPointProps> = memo(({ label, id }) => {
  const {
    get,
    result: pointData,
    isLoading,
    error
  } = useRequest<ISoilPointData>(`/soilpollution/v10/soilpollution/dictionaries/${id}`);

  return (
    <PortalPopover
      onOpen={() => {
        get({ params: { id } });
      }}
      label={label}
      isLoading={isLoading}
      style={{
        fontSize: 'inherit',
        lineHeight: 'inherit',
        height: 'fit-content',
      }}
      {...soilPopoverProps}
    >
      <Stack spacing="15px" padding="20px" fontSize="14px" whiteSpace="initial">
        {pointData?.latitude && <LabelText label="Широта:">{pointData.latitude}</LabelText>}
        {pointData?.longitude && <LabelText label="Долгота:">{pointData.longitude}</LabelText>}
        {pointData?.funcZone && <LabelText label="Функциональная зона:">{pointData.funcZone}</LabelText>}
        {pointData?.funcZoneType && <LabelText label="Тип функциональной зоны:">{pointData.funcZoneType}</LabelText>}
        {pointData?.notice && <LabelText label="Примечание:">{pointData.notice}</LabelText>}

        {error && 'descriptions' in error && <ErrorDescriptions spacing={15} descriptions={error.descriptions} />}
      </Stack>

    </PortalPopover>
  );
});

export const CellObservationPoint: FC<ICellObservationPoint> = ({ label, id }) => (
  <Flex position="relative" height="100%">
    <ObservationPointPopover label={label} id={id} />
  </Flex>
);
