import React from 'react';
import { PageHeader, RoutesList } from 'components/common';
import appLocale from 'constants/appLocale';
import { MASKSHInstructions, MASKSHNoisePackages, MASKSHRoot } from 'pages/data/MASKSH/consts';
import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import { Flex, Heading } from '@chakra-ui/react';

export const MASKSHMenu: React.FC = () => {
  useUpdateBreadcrumbs([
    { breadcrumb: appLocale.common.data, path: '/data' },
    {
      breadcrumb: appLocale.subsystems.masksh,
      path: MASKSHRoot,
    },
  ]);
  return (
    <Flex flexDirection="column">
      <PageHeader>
        <Heading>{appLocale.subsystems.masksh}</Heading>
      </PageHeader>
      <RoutesList
        routes={[
          {
            path: MASKSHNoisePackages,
            breadcrumb: 'Данные мониторинга',
          },
          {
            path: MASKSHInstructions,
            breadcrumb: 'Поручения',
          },
        ]}
      />
    </Flex>
  );
};
