import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { NODGreenplantsItem } from './types';
import appLocale from 'constants/appLocale';
import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import { addFiltersToColumns, ColumnWithFilter } from 'lib/utils/tableFilter';
import { TableFilterTypeEnum } from 'components/table/filters/TableFilter';
import { useList } from 'hooks';
import { useSavedFilters } from 'hooks/useSaveFilters';
import { IFilterItem, TFilterDTO, TFiltersArray } from 'models';
import { Column } from '@material-table/core';
import { useToolbarActions } from '../utils/toolbarActions';
import { createValuesToRequest } from 'features/Filter/utils';
import { SubsystemHeader } from 'components/common';
import { MaterialTable } from 'features/material-table/MaterialTableMini';
import { NODFilter } from '../components/NODFilter';
import { yearValidationSchema } from '../components/NODValidationSchemas';
import { filtersToValues, valuesToFilters } from '../utils';
import { useNodRights } from '../utils/useNodRights';
import { subsystemCodesMap } from 'constants/subsystemIdsMap';
import { useGoToNODUploadLog } from 'hooks/useGoToNODUploadLog';
import { DEFAULT_PAGE_SIZE_NEW_TABLE } from '../../../../constants/tables';
import { FullPageWrapper } from '../../../../components/table/FullPageWrapper';
import { DefaultTableCellSize } from '../../../../components/table/DefaultTableCellSize';

const NODGreenplantsBreadcrumbs = [
  { breadcrumb: appLocale.subsystems.analyticsReports, path: '/reports-analytics' },
  { breadcrumb: appLocale.analyticsReports.nod, path: '/reports-analytics/nod' },
  { breadcrumb: appLocale.analyticsReports.greenplants, path: '/reports-analytics/nod/greenplants' },
];

const NODGreenplants = () => {
  const { isExecute } = useNodRights(subsystemCodesMap.nodReportsOtherCodes.greenplants);
  const [presetValues, setPresetValues] = useState<{ key: string; value: IFilterItem['value'] }[]>();
  const { filters, saveFilters } = useSavedFilters<NODGreenplantsItem>('nod', `nod${9}`);

  const {
    response,
    setFilters,
    filterDTO,
    isLoading: isItemsLoading,
    onPageChange,
    resetData,
    downloadAsFile,
    toggleOrder,
    errorText,
    selectedIndexes,
    renderOnlySelectedCheckbox,
    onSelectionChange,
    setSelectedIds,
    onSubmitFilters,
  } = useList<NODGreenplantsItem, keyof NODGreenplantsItem>('/ehd/v10/v1/catalog-fetcher/reports/9', {
    preventFirstFetch: true,
    initialOrderFieldKey: '-period',
    selectionKey: 'id',
    disableUxMetadata: true,
    defaultPageSize: DEFAULT_PAGE_SIZE_NEW_TABLE,
    resetDataOnNextRequest: true,
  });

  const getCurrentFilter = useCallback(
    () => filterDTO.filters.find((filter) => filter.key !== 'deleted'),
    [filterDTO.filters]
  );

  useUpdateBreadcrumbs(NODGreenplantsBreadcrumbs);

  const columns = useMemo<ColumnWithFilter<NODGreenplantsItem>[]>(
    () => [
      {
        title: 'Код',
        field: 'ehdCode',
        width: 50,
      },
      {
        title: 'Период наблюдения',
        field: 'period',
        width: 50,
      },
      {
        title: 'Административный округ',
        field: 'admArea',
        filterType: TableFilterTypeEnum.string,
      },
      {
        title: 'Район',
        field: 'district',
        filterType: TableFilterTypeEnum.string,
      },
      {
        title: 'Адрес',
        field: 'address',
        filterType: TableFilterTypeEnum.string,
        render: ({ address }) => <DefaultTableCellSize>{address}</DefaultTableCellSize>,
      },
      {
        title: 'Хорошее состояние, %',
        field: 'goodCondition',
      },
      {
        title: 'Удовлетворительное состояние, %',
        field: 'middleCondition',
      },
      {
        title: 'Неудовлетворительное состояние, %',
        field: 'badCondition',
      },
    ],
    []
  );

  const columnsWithFilters = useMemo<Array<Column<NODGreenplantsItem>>>(
    () =>
      addFiltersToColumns({
        filters: filterDTO.filters,
        setFilters,
        columns,
      }),
    [filterDTO.filters, setFilters, columns]
  );

  const onClear = () => {
    onSubmitFilters([]);
    resetData();
    const emptyFilters = {
      ...filterDTO,
      filters: [],
    };
    setFilters([]);
    saveFilters({
      data: emptyFilters,
    });
  };

  const onSubmit = (values: any) => {
    const v: NODGreenplantsItem = createValuesToRequest<any, any>(initialValues, values);
    const filters = Object.values(v);
    onSubmitFilters(valuesToFilters(filters));
  };

  const selectedIds = useMemo(() => {
    const idValues = filters?.data.filters.find((item: any) => item.key === 'id')?.value;
    if (Array.isArray(idValues)) {
      return idValues as string[];
    }
  }, [filters]);

  useEffect(() => {
    if (getCurrentFilter()) {
      saveFilters({
        data: filterDTO,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getCurrentFilter]);

  useEffect(() => {
    if (filters != null && filters.data) {
      setPresetValues(filtersToValues(filters.data.filters) as TFiltersArray);
      setSelectedIds(selectedIds);
      setFilters(filters.data.filters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, setPresetValues, setFilters, saveFilters]);

  const { toolBarActions } = useToolbarActions(9, filterDTO, response?.data.length === 0);

  const initialValues = {
    year: {
      key: 'year',
      operation: 'EQ',
      value: '',
    },
    id: {
      key: 'id',
      operation: 'IN',
      value: [],
    },
  };

  const { goToButtonText, goToButtonProps } = useGoToNODUploadLog(
    NODGreenplantsBreadcrumbs,
    9,
    appLocale.analyticsReports.greenplants,
    '/reports-analytics/nod/greenplants'
  );

  return (
    <FullPageWrapper>
      <SubsystemHeader
        title={appLocale.analyticsReports.greenplants}
        anyButtonProps={goToButtonProps}
        anyButtonText={goToButtonText}
        filtersComponent={
          <NODFilter
            presetValues={presetValues || []}
            initialValues={initialValues}
            onClear={onClear}
            onSubmit={onSubmit}
            mainFields={[
              {
                type: 'DATE',
                name: 'year',
                label: 'Год',
                showYearPicker: true,
              },
            ]}
            validationSchema={yearValidationSchema}
          >
            {renderOnlySelectedCheckbox}
          </NODFilter>
        }
        defaultFiltersOpen
      />
      <MaterialTable
        isLoading={isItemsLoading}
        filterDTO={filterDTO as TFilterDTO}
        columns={columnsWithFilters}
        data={response?.data}
        meta={response?.meta}
        downloadAsFile={downloadAsFile}
        toolbarActions={toolBarActions}
        onPageChange={onPageChange}
        toggleOrder={toggleOrder}
        selectedIndexes={selectedIndexes}
        onSelectionChange={onSelectionChange}
        options={{
          selection: true,
          toolbar: isExecute,
        }}
        errorText={errorText}
        printLoggerUrl="/ehd/v10/v1/catalog-fetcher/reports/9/print"
      />
    </FullPageWrapper>
  );
};

export default NODGreenplants;
