import { SelectOption } from 'components/common';

export enum ANALYTICS_STATUS {
  APPROVED = 'APPROVED',
  DRAFT = 'DRAFT',
}
export const ANALYTICS_STATUSES: SelectOption[] = [
  {
    name: 'Утвержден',
    value: ANALYTICS_STATUS.APPROVED
  },
  {
    name: 'Черновик',
    value: ANALYTICS_STATUS.DRAFT
  }
];
