import { Field, useFormikContext } from 'formik';
import { Input, Select } from 'components/common';
import { Grid } from '@chakra-ui/react';
import { ChangeEvent } from 'react';

import { IFormIASKZVPackage } from '../../types';
import { askzvLocales } from '../../locales';
import { DictionariesItem } from 'models';

interface ITab {
  isLoading: boolean;
  fire: DictionariesItem;
  door: DictionariesItem;
  event: DictionariesItem;
}
export const TechTab = ({ isLoading, fire, door, event }: ITab) => {
  const { setFieldValue, values } = useFormikContext<IFormIASKZVPackage>();
  return (
    <Grid gap={2}>
      <Field
        as={Input}
        label={askzvLocales.waterLevel}
        name="waterLevel"
        value={values.waterLevel}
      />
      <Field
        as={Input}
        label={askzvLocales.pressure1}
        name="pressure1"
        value={values.pressure1}
      />
      <Field
        as={Input}
        label={askzvLocales.pressure2}
        name="pressure2"
        value={values.pressure2}
      />
      <Field
        as={Input}
        label={askzvLocales.flow}
        name="flow"
        value={values.flow}
      />
      <Field
        as={Input}
        label={askzvLocales.probeNumber}
        name="probeNumber"
        value={values.probeNumber}
      />
      <Field
        as={Select}
        name="eventId"
        label={askzvLocales.eventId}
        value={values.eventId}
        options={event}
        onChange={(e: ChangeEvent<HTMLSelectElement>) => {
          setFieldValue('eventId', e.target.value);
        }}
        isDisabled={isLoading}
      />
      <Field
        as={Select}
        name="doorId"
        label={askzvLocales.doorId}
        value={values.doorId}
        options={door}
        onChange={(e: ChangeEvent<HTMLSelectElement>) => {
          setFieldValue('doorId', e.target.value);
        }}
        isDisabled={isLoading}
      />
      <Field
        as={Select}
        name="fireId"
        label={askzvLocales.fireId}
        value={values.fireId}
        options={fire}
        onChange={(e: ChangeEvent<HTMLSelectElement>) => {
          setFieldValue('fireId', e.target.value);
        }}
        isDisabled={isLoading}
      />
    </Grid>
  );
};
