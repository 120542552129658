import React, { useMemo } from 'react';
import { FieldsForm } from './FieldsForm';
import { FormikProvider, useFormik, useFormikContext } from 'formik';
import { TDictionariesArray } from 'models/dictionaries';
import { useRequest } from 'hooks/useRequest';
import { useNotifications } from 'hooks/useNotifications';
import { ADD_INCIDENT_ERROR, ADD_INCIDENT_SUCCESS, AppealValues, Complaint } from 'pages/citizenAppeal';
import { createTouchedErrors } from 'features/create-touched-errors';
import { IncidentShema } from 'models/schemas';
import { getIncidentEditEndpoint, incidentAdd } from '../../../consts/apiPaths';
import { getSelectRichOptionsFromDictionaryByName } from 'features/get-select-options-from-dictionary';

interface Props {
  dictionaries?: TDictionariesArray;
  complaint: Complaint | null;
  setIncident: (incident: Complaint | null) => void;
  incident: Complaint | null;
}

export const AddIncidentForm = ({ dictionaries, complaint, setIncident, incident }: Props) => {
  const { values: appealValues } = useFormikContext<AppealValues>();

  const { successNotify, errorNotify } = useNotifications();

  const onSuccess = (incident: Complaint | null) => {
    successNotify({
      key: 'file/add/success',
      message: ADD_INCIDENT_SUCCESS,
    });
    setIncident(incident);
    formik.setFieldValue('complaintId', complaint?.complaintId);
  };

  const onError = () =>
    errorNotify({
      key: 'file/add/error',
      message: ADD_INCIDENT_ERROR,
    });

  const { post } = useRequest(incidentAdd, onSuccess, onError);
  const { put } = useRequest(getIncidentEditEndpoint(incident?.complaintId), onSuccess, onError);

  const assignmentStatuses = useMemo(
    () => getSelectRichOptionsFromDictionaryByName(dictionaries, 'assignmentStatuses'),
    [dictionaries]
  );

  const initialEmptyValues = {
    assignmentExecutor: '',
    complaintStatusId: assignmentStatuses?.find((status) => status.name === 'В работе')?.value,
    complaintComment: '',
    complaintId: appealValues.complaintId,
    incidents: [
      {
        incidentAddress: '',
        incidentAreaId: '',
        incidentCompletionSign: false,
        incidentDistrictId: '',
        incidentId: null,
        incidentLatitude: '',
        incidentLongitude: '',
        incidentNmuSign: false,
        incidentNoNeedingSign: false,
        incidentNoiseTypeId: '',
        incidentOdorTypeId: '',
        incidentPlanningSign: false,
        incidentRedirectionSign: false,
        incidentRenovationSign: false,
        incidentRepetitionSign: false,
        incidentSourceId: '',
        incidentTypeId: '',
        incidentNoiseSourceTypeId: '270c7688-1326-44e9-b929-05fad0d7565f',
      },
    ],
  };

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    validationSchema: IncidentShema(dictionaries),
    initialValues: { ...initialEmptyValues, ...incident },
    onSubmit: (values: any) => {
      if (incident) {
        put(values);
      } else {
        post(values);
      }
    },
  });

  return (
    <FormikProvider
      value={{
        ...formik,
        errors: createTouchedErrors(formik.errors, formik.touched),
      }}
    >
      <FieldsForm appealValues={appealValues} dictionaries={dictionaries} />
    </FormikProvider>
  );
};
