import React, { useState } from 'react';
import { createTouchedErrors } from 'features/create-touched-errors';
import { FormikProvider, useFormik } from 'formik';
import { useRequest } from 'hooks/useRequest';
import { useHistory } from 'react-router-dom';
import AnalyticsSoilDeicingFieldsForm from 'pages/analytics/forms/Soil/AnalyticsSoilDeicingFieldsForm';
import { IUploadMedia } from 'components/common/FileUploadDragAndDrop/types';
import { updateFiles } from 'api/services/media';
import { useObjectType } from 'hooks/useObjectType';
import { ANALYTICS_LAB_PATHS } from 'pages/analytics/consts/AnalyticsTableRoutes';
import { initialEmptyValues } from './initialEmptyValues';
import { SoilDeicingItem } from 'pages/data/PGR/Soil';
import { ReagentsSoilApiDictionaries, ReagentsSoilApiRoot } from 'pages/data/PGR';
import { AnalyticsPGRSoilSchema } from 'models/schemas/analytics/analyticsSoilSchema';
import { useEnhancedNotification } from 'hooks';
import { useGoBackWithFallback } from 'lib/utils/goBackWithFallback';
import { useDictionary } from 'hooks/useDictionary';

export const AnalyticsAddSoilDeicingReport = () => {
  const { dictionaries, isDictionariesLoading } = useDictionary({ url: ReagentsSoilApiDictionaries });
  const history = useHistory();
  const { backWithFallback } = useGoBackWithFallback();
  const { onSuccessCreate, onErrorCreate } = useEnhancedNotification();
  const [media, setMedia] = useState<IUploadMedia[]>([]);
  const objectType = useObjectType(dictionaries);

  const onSuccess = (response: SoilDeicingItem | null) => {
    if (!response) {
      onErrorCreate();
      return;
    }

    onSuccessCreate();

    updateFiles(response.registryId, objectType, media)
      .then(() => {
        backWithFallback('/analytics');
      })
      .catch((error: Error) => {
        onErrorCreate(error.message);
        history.replace(`${ANALYTICS_LAB_PATHS.reagents.soil.edit}/${response.registryId}?openAttachmentsTab=true`);
      });
  };

  const {
    post,
    isLoading
  } = useRequest<SoilDeicingItem, any>(`${ReagentsSoilApiRoot}?laboratory=true`, onSuccess, onErrorCreate);

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    initialValues: initialEmptyValues,
    onSubmit: (values) => post(values),
    validationSchema: AnalyticsPGRSoilSchema,
  });

  return (
    <FormikProvider
      value={{
        ...formik,
        errors: createTouchedErrors(formik.errors, formik.touched),
      }}
    >
      <AnalyticsSoilDeicingFieldsForm
        dictionaries={dictionaries}
        onCancel={() => {
          backWithFallback('/analytics');
        }}
        isLoading={isDictionariesLoading || isLoading}
        onMediaChange={setMedia}
        media={media}
      />
    </FormikProvider>
  );
};
