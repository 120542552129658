import React from 'react';
import { Modal } from 'components/common';
import { MaterialTable } from 'features/material-table/MaterialTableMini';
import { Filter } from './Filter';
import { GeoecologyAPIPath, GeologicalProcessesPath } from '../../constants/constants';
import { useHistory } from 'react-router';
import { useList } from 'hooks';
import FilePreviewModalWrapper from 'components/common/FilePreviewModal/FilePreviewModalWrapper';
import { UploadFileModalContent } from 'features/UploadFileDragAndDrop/UploadFileModalContent';
import useFilePreviewModalWrapperData from 'components/common/FilePreviewModal/hooks/useFilePreviewModalWrapperData';
import { TDictionaryItemDictionary } from 'models';
import { TTableColumns } from '../../constants/types';
import { FullPageWrapper } from '../../../../../components/table/FullPageWrapper';
import { DEFAULT_PAGE_SIZE_NEW_TABLE } from '../../../../../constants/tables';

interface IProps {
  type: string;
  tableColumns: TTableColumns;
  districts: TDictionaryItemDictionary[];
  regions: TDictionaryItemDictionary[];
  statuses: TDictionaryItemDictionary[];
  areas: TDictionaryItemDictionary[];
  isOpenLoad: boolean;
  onCloseLoad: () => void;
  isDelete: boolean;
  isWrite: boolean;
  renderSubsystemHeader: (filterComponent: React.ReactNode) => React.ReactNode;
}

export const DangerProcessesTab = ({
  type,
  tableColumns,
  districts,
  regions,
  statuses,
  areas,
  isOpenLoad,
  onCloseLoad,
  isWrite,
  renderSubsystemHeader,
}: IProps) => {
  const {
    response,
    setFilters,
    onPageChange,
    isLoading,
    toggleOrder,
    filterDTO,
    refetch,
    onSearchText,
    downloadAsFile,
    errorText,
    materialTableProps,
  } = useList(`${GeoecologyAPIPath}/list/${type}`, {
    initialOrderFieldKey: 'number',
    saveFiltersState: true,
    defaultPageSize: DEFAULT_PAGE_SIZE_NEW_TABLE,
    resetDataOnNextRequest: true,
  });

  const history = useHistory();
  const tableEditButtonClick = (id: string) => history.push(`${GeologicalProcessesPath}/${type}/edit/${id}`);

  const { open, ...filePreviewData } = useFilePreviewModalWrapperData();

  return (
    <FullPageWrapper>
      {renderSubsystemHeader(
        <Filter
          type={type}
          setFilters={setFilters}
          districts={districts}
          regions={regions}
          statuses={statuses}
          areas={areas}
          isLoading={isLoading}
          filters={filterDTO.filters}
        />
      )}

      <MaterialTable
        data={response?.data}
        meta={response?.meta}
        filterDTO={filterDTO}
        toggleOrder={toggleOrder}
        options={{
          search: true,
        }}
        isLoading={isLoading}
        onSearch={onSearchText}
        onPageChange={onPageChange}
        columns={tableColumns}
        downloadAsFile={downloadAsFile}
        actions={
          [
            {
              type: 'edit',
              onClick: (_e, row) => {
                tableEditButtonClick(row.id);
              },
              disabled: !isWrite,
              hidden: !isWrite,
            },
          ]
        }
        showTotalCount
        errorText={errorText}
        {...materialTableProps}
      />

      <Modal isOpen={isOpenLoad} onClose={onCloseLoad} header="Загрузка данных из файла">
        <UploadFileModalContent
          createTypesPath={() => ''}
          createUploadPath={(typeId) => `${GeoecologyAPIPath}/file/${typeId}`}
          types={[
            {
              name: 'Геологические процессы',
              value: type,
            },
          ]}
          onClose={onCloseLoad}
          refetch={refetch}
        />
      </Modal>

      <FilePreviewModalWrapper {...filePreviewData} />
    </FullPageWrapper>
  );
};
