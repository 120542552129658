import { LabelText, PortalPopover, PortalPopoverProps } from 'components/common';
import { Flex, Stack } from '@chakra-ui/react';
import { ErrorTableTdBackdrop } from 'features/material-table/components/ErrorTableTdBackdrop';
import { ErrorDescriptions } from 'features/error-descriptions-v-stack';
import { useRequest } from 'hooks';

const groundwaterPopoverProps: PortalPopoverProps = {
  variant: 'ghost',
  textDecoration: 'underline',
  textDecorationStyle: 'dotted',
  padding: 0,
  PopoverProps: {
    placement: 'bottom-start',
  },
};

interface GenericProps {
  mouthMark: string | number | null;
  address: string | null;
  aquafierName: string | null;
  latitude: number | null;
  longitude: number | null;
}

interface Props {
  label: string | number | null;
  id: string | null;
  url: string;
  error?: string | null;
}

export function CellObservationPoint<T extends GenericProps>({ label, id, url, error }: Props) {
  const { get, result, error: apiError, isLoading } = useRequest<T>(url);
  if (error) {
    return <ErrorTableTdBackdrop>{error}</ErrorTableTdBackdrop>;
  }
  if (!id) return null;

  return (
    <Flex position="relative" height="100%">
      <PortalPopover
        onOpen={() => get({ params: { id } })}
        label={label ? String(label) : undefined}
        isLoading={isLoading}
        style={{
          fontSize: 'inherit',
          lineHeight: 'inherit',
          height: 'fit-content',
        }}
        {...groundwaterPopoverProps}
      >
        <Stack spacing="15px" padding="20px" fontSize="14px" whiteSpace="initial">
          {result?.mouthMark && <LabelText label="Абсолютная отметка устья, м:">{result.mouthMark}</LabelText>}
          {result?.address && <LabelText label="Адресная привязка:">{result.address}</LabelText>}
          {result?.aquafierName && <LabelText label="Вредоносный горизонт:">{result.aquafierName}</LabelText>}
          {result?.latitude && <LabelText label="Широта:">{result.latitude}</LabelText>}
          {result?.longitude && <LabelText label="Долгота:">{result.longitude}</LabelText>}

          {apiError && 'descriptions' in apiError && (
            <ErrorDescriptions spacing={15} descriptions={apiError.descriptions} />
          )}
        </Stack>
      </PortalPopover>
    </Flex>
  );
}
