import { Button, Input } from 'components/common';
import { LightModeWrapper } from 'components/common/LightModeWrapper/LightModeWrapper';
import { Field, Form, FormikProvider, useFormik } from 'formik';
import { initialPasswordResetValues as initialValues } from 'pages/auth/config';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { apiAuthPaths } from 'api/services/auth';
import { useNotifications, useRequest } from 'hooks';
import { validateEmail } from 'lib/utils/validators/emailValidator';
import { authAppPaths } from 'pages/auth/appPaths';
import { Flex, Grid, Heading } from '@chakra-ui/react';
import { ApiError } from '../../models';
import { formatErrorMessage } from '../../lib/utils/formatErrorMessage';

export const RememberPasswordDialog: React.FC = () => {
  const { errorNotify, successNotify } = useNotifications();
  const onError = (message?: string | null | ApiError) => {
    errorNotify({
      key: 'login/error',
      message: formatErrorMessage(message) || 'Ошибка при сбросе пароля',
    });
  };
  const onSuccess = () => {
    successNotify({
      key: 'login/success',
      message: 'Ссылка для изменения пароля направлена на почту',
    });
    push('/');
  };
  const { post: resetPassword, isLoading } = useRequest(apiAuthPaths.password.resetPassword, onSuccess, onError);

  const { push } = useHistory();
  const useFormikProps = {
    enableReinitialize: true,
    validateOnChange: false,
    validateOnMount: false,
    validateOnBlur: true,
    initialValues,
    onSubmit: (values: any) => resetPassword(values),
  };

  const { submitForm, values, setFieldValue, errors, touched, ...formik } = useFormik(useFormikProps);
  const handleCancel = () => push(authAppPaths.login);

  const emailFieldValidate = (value: string) => validateEmail(value, {});

  const canSave = values.email && errors.email == null;
  return (
    <Flex
      minHeight="100vh"
      display="flex"
      alignItems="center"
      flexDir="column"
      bgPosition="center"
      bgRepeat="no-repeat"
      bgSize="cover"
      bgImage="url('./login-bg-img.png')"
    >
      <Flex direction="column" minHeight="100vh" maxW="500px" justifyContent="space-between">
        <Flex flexGrow={1} alignItems="center" justifyContent="center">
          <Flex maxH="520px" direction="column" minWidth="375px" bgColor="white" p={10}>
            <Flex mb={8} alignItems="center" justifyContent="center">
              <Heading my={2} size="lg" color="black">
                Сбросить пароль учетной записи
              </Heading>
            </Flex>
            <FormikProvider
              value={{
                submitForm,
                values,
                setFieldValue,
                errors,
                touched,
                ...formik,
              }}
            >
              <Form>
                <Flex direction="column">
                  <Grid borderColor="black" gap={2} color="black">
                    <Field
                      variant="outline"
                      as={Input}
                      label="Email пользователя"
                      name="email"
                      type="email"
                      validate={emailFieldValidate}
                      error={errors.email}
                    />
                    {errors.email && touched.email && <div>{errors.email}</div>}
                    <LightModeWrapper>
                      <Button
                        mt={2}
                        variant="solid"
                        colorScheme="PrimaryButton"
                        onClick={submitForm}
                        isLoading={isLoading}
                        isFullWidth
                        disabled={!canSave}
                      >
                        Отправить запрос
                      </Button>
                      <Button mt={2} variant="outline" onClick={handleCancel} isFullWidth>
                        Отмена
                      </Button>
                    </LightModeWrapper>
                  </Grid>
                </Flex>
              </Form>
            </FormikProvider>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};
