import { Route, Switch } from 'react-router-dom';
import { FilePreviewMeasuresRouting } from 'features/UploadedFileProvider/FilePreviewMeasuresRouting';
import { AdminRoutes } from 'pages/admin/routes';
import { GeoecologicalProcesses } from 'pages/routes/GeoecologicalProcesses';
import { Home } from '../home';
import { AtmosphereList } from '../data/Atmosphere/AtmosphereList';
import { AddAtmosphereMonitoringResult } from '../data/Atmosphere/Monitoring/Forms/AddMonitoringResult';
import { Soil } from '../data/Soil/Soil';
import { AddSoilResult } from '../data/Soil/Soil/Forms/AddSoilResult';
import { EditSoilResult } from '../data/Soil/Soil/Forms/EditSoilResult';

import { AddSubsoilReport } from '../data/Subsoil/Subsoil/Forms/AddSubsoilReport';
import { EditSubsoilReport } from '../data/Subsoil/Subsoil/Forms/EditSubsoilReport';
import { Subsoil } from '../data/Subsoil/Subsoil';
import { MeteoRoutes } from './Meteo';
import { MeteoActinometryRoutes } from './MeteoActinometry';
import { MeteoASKZARoutes } from './MeteoASKZA';
import { MeteoMsz4Routes } from './MeteoMsz4';
import { ForecastRoutes } from './Forecast';
import { GreenPlantsRoutes } from './GreenPlants';
import { GreenPlantsRoutes as PGRGreenPlantsRoutes } from './PGRGreenPlants';
import { ReagentsRoutes } from './PGR';
import { NSIRoutes } from './NSI';
import { MASKSHRoutes } from './MASKSH';
import { AISWeatherRoutes } from './AISWeather';
import { IndustrialEmissionsList } from '../data/IndustrialEmissions/IndustrialEmissionsList';
import { IndustrialEmissionsMonitoring } from '../data/IndustrialEmissions/MonitoringData';
import { EquipmentRouter } from './EquipmentRouter';
import {
  AddAppealPath,
  Appeals,
  CitizenAppealsMenu,
  CitizenAppealsTableViewEditPath,
  CitizenAppealsTableViewPath,
  CitizensAppealsPath,
  editJournalAtmoBreadCrumbs,
  editJournalNoiseBreadCrumbs,
  RaidJournalAtmoAdd,
  RaidJournalNoiseAdd,
  RaidJournalPathAtmo,
  RaidJournalPathNoise,
  taskFormAtmoBreadcrumb,
  TaskFormerPath,
  taskFormNoiseBreadcrumb,
  VideoCamerasPath,
} from 'pages/citizenAppeal';
import { CitizensAppealList } from 'pages/citizenAppeal/CitizensAppealsRecord';
import { AddAppeal } from 'pages/citizenAppeal/CitizensAppealsRecord/AppealForms';
import { EditAppeal } from 'pages/citizenAppeal/CitizensAppealsRecord/AppealForms/EditAppeal';
import { RaidJournalAtmo } from 'pages/citizenAppeal/RaidJournal';

import { AnalyticsLabRoutes } from 'pages/routes/AnalyticsLabRoutes';
import { WaterRoutes } from './Water';

import { WithSetup } from 'features/WithSetup';
import { DataList } from 'pages/data/DataList';
import { authAppPaths } from '../auth/appPaths';
import { ForbiddenPage } from '../auth/Forbidden';
import { LoginPage } from '../auth/Login';
import Cartography from '../cartography/Cartography';
import { PDKRoutes } from './PDKRoutes';
import { InclinometerItemPreview } from 'pages/data/GeoecologicalProcesses/DangerouslyGeologicalProcesses/Inclinometer/LoadPreview/types';
import { GeologicalProcessesPath, GroundwaterPath } from '../dictionaries/GeologicalProcesses/constants/constants';
import { Groundwater } from '../dictionaries/GeologicalProcesses/Groundwater';
import { EditGroundwaterData } from '../dictionaries/GeologicalProcesses/Groundwater/EditGroundwaterData';
import { DangerGeologicalProcesses } from '../dictionaries/GeologicalProcesses/DangerGeologicalProcesses';
import { EditForm } from '../dictionaries/GeologicalProcesses/DangerGeologicalProcesses/EditForms/EditForm';
import { InclinometerPage } from '../dictionaries/GeologicalProcesses/DangerGeologicalProcesses/InclinometersPage';
import { AnalyticsReportsRoutes } from 'pages/routes/AnalyticsReportsRoutes';
import { VideoCameras } from '../citizenAppeal/VideoCameras';
import { EditCam } from '../citizenAppeal/VideoCameras/EditCam';
import Notifications from 'pages/Notifications/Notifications';
import ManagerRoutes from 'pages/routes/ManagerRoutes';

import PlanningPelRoutes from './PlanningPel';
import { TaskPreparing } from 'pages/citizenAppeal/CitizensAppealsRecord/components';
import {
  ATMO_RAID_LIST_PATH,
  atmoRaidTableBreadcrumb,
  NOISE_RAID_LIST_PATH,
  noiseRaidTableBreadcrumb,
  PELAtmoAdd,
  PELAtmoBreadcrumb,
  PELNoiseAdd,
  PELNoiseBreadcrumb,
  PELTableView,
  tableViewToReportBreadcrumb,
  tableViewToReportPath,
  TASK_FORMER_PATH,
  taskFormerBreadCrumbs,
} from 'pages/pel/constants';
import { PELRaidsJournal } from '../pel/Raids/PELRaidsJournal';
import { CitizenAppealsTableViewNoise } from '../citizenAppeal/CitizenAppealsTableView';
import PdkExcessJournalRoutes from 'pages/routes/PdkExcessJournalRoutes';
import { Journal } from 'pages/admin/Journal/Journal';
import { PelRaidsTableView } from '../pel/PelRaidsTableView';
import {
  CitizenAppelasTableViewAirTaskBreadcrumb,
  CitizenAppelasTableViewBreadcrumb,
  CitizenAppelasTableViewNoiseTaskBreadcrumb,
} from '../citizenAppeal/CitizenAppealsTableView/Consts/breadcrumbs';
import { MeteoNMURoutes } from './MeteoNMURoutes';
import { PDV } from '../data/IndustrialEmissions/PDV';
import { AddPDV } from '../data/IndustrialEmissions/PDV/AddPDV';

import {
  Industrial_PDV_AddPath,
  Industrial_PDV_EditPath,
  Industrial_PDV_RootPath,
} from '../data/IndustrialEmissions/PDV/consts';
import { ChangePasswordDialog } from 'pages/personal/ChangePasswordDialog';
import { RememberPasswordDialog } from 'pages/personal/RememberPasswordDialog';
import { SetPasswordByEmailDialog } from 'pages/personal/SetPasswordByEmailDialog';
import {
  Industrial_PDV_NoSource_AddPath,
  Industrial_PDV_NoSource_EditPath,
  Industrial_PDV_NoSource_RootPath,
} from 'pages/data/IndustrialEmissions/PDVNoSource/consts';
import { PDVNoSource } from 'pages/data/IndustrialEmissions/PDVNoSource';
import { AddPDVNoSource } from 'pages/data/IndustrialEmissions/PDVNoSource/AddPDV';
import { AtmosphereMonitoring } from 'pages/data/Atmosphere/Monitoring/AtmosphereMonitoring';
import { ProtocolsList } from 'pages/protocols/ProtocolsList';
import { AtmosphereMonitoringParameters } from 'pages/protocols/ASKZA/AtmosphereMonitoringParameters';
import { ProtocolsWaterList } from 'pages/protocols/Water/ProtocolsWaterList';
import { ProtocolsWaterASKZV } from 'pages/protocols/Water/ASKZV/ProtocolsWaterASKZV';
import { BurningRegistrationJournal } from 'pages/burningRegistrationJournal/BurningRegistrationJournal';
import { BurningRegistrationJournalPaths } from 'pages/burningRegistrationJournal/const';
import { BurningRegistrationJournalForm } from 'pages/burningRegistrationJournal/Forms/BurningRegistrationJournalForm';
import { IncidentRecording } from 'pages/IncidentRecording/IncidentRecording';
import { IncidentRecordingForm } from 'pages/IncidentRecording/Forms/IncidentRecordingForm';
import { IncidentRecordingPaths } from 'pages/IncidentRecording/const';
import { CitizenAppealsTableViewAir } from 'pages/citizenAppeal/CitizenAppealsTableView/CitizenAppealsTableViewAir';
import { RaidJournalNoise } from 'pages/citizenAppeal/RaidJournal/RaidJournalNoise';
import { DataEntryLog } from '../admin/DataEntryLog';
import { dataEntryLogBreadcrumb } from '../admin/DataEntryLog/consts/consts';
import SentryDashBoard from '../SentryDashboard/SentryDashboard';

export const Routes = () => (
  <>
    <Switch>
      <Route exact path={authAppPaths.login} component={LoginPage} />
      <Route exact path={authAppPaths.changePassword} component={ChangePasswordDialog} />
      <Route exact path={authAppPaths.resetPassword} component={RememberPasswordDialog} />
      <Route path={authAppPaths.changePasswordByEmail} component={SetPasswordByEmailDialog} />
      <WithSetup>
        <Route exact path={authAppPaths.forbidden} component={ForbiddenPage} />
        <Route path="/" exact component={Home} />
        <Route path="/data" exact component={DataList} />
        <MeteoRoutes />
        <MeteoActinometryRoutes />
        <MeteoNMURoutes />
        <WaterRoutes />
        <MeteoASKZARoutes />
        <MeteoMsz4Routes />
        <ForecastRoutes />
        <GreenPlantsRoutes />
        <ReagentsRoutes />
        <NSIRoutes />
        <AdminRoutes />
        <MASKSHRoutes />
        <PGRGreenPlantsRoutes />
        <AISWeatherRoutes />
        <GeoecologicalProcesses />
        <EquipmentRouter />
        <AnalyticsLabRoutes />
        <PDKRoutes />
        {/* ATMOSPHERE MONITORING */}
        <Route path="/data/atmosphere" exact component={AtmosphereList} />
        <Route path="/data/atmosphere/monitoring" exact component={AtmosphereMonitoring} />
        <Route path="/data/atmosphere/monitoring/add" exact component={AddAtmosphereMonitoringResult} />
        <FilePreviewMeasuresRouting rootPath="/data/atmosphere/monitoring" />
        {/* SOIL POLLUTION */}
        <Route path="/data/soil" exact component={Soil} />
        <Route path="/data/soil/add" exact component={AddSoilResult} />
        <Route path="/data/soil/edit/row/:id" component={EditSoilResult} />
        <FilePreviewMeasuresRouting rootPath="/data/atmosphere/monitoring" />
        {/* SUBSOIL */}
        <Route path="/data/subsoil" exact component={Subsoil} />
        <Route path="/data/subsoil/add" exact component={AddSubsoilReport} />
        <Route path="/data/subsoil/edit/row/:id" component={EditSubsoilReport} />
        <FilePreviewMeasuresRouting rootPath="/data/atmosphere/monitoring" />

        <Route path="/data/industrial-emissions" exact component={IndustrialEmissionsList} />
        <Route path="/data/industrial-emissions/monitoring" exact component={IndustrialEmissionsMonitoring} />
        <FilePreviewMeasuresRouting<InclinometerItemPreview> rootPath="/data/geoecological-processes/monitoring/danger/inclinometer" />

        {/* PDV */}
        <Route exact path={Industrial_PDV_RootPath} component={PDV} />
        <Route exact path={Industrial_PDV_AddPath} component={AddPDV} />
        <Route exact path={`${Industrial_PDV_EditPath}/:id`} component={AddPDV} />

        {/* PDV no sources */}
        <Route exact path={Industrial_PDV_NoSource_RootPath} component={PDVNoSource} />
        <Route exact path={Industrial_PDV_NoSource_AddPath} component={AddPDVNoSource} />
        <Route exact path={`${Industrial_PDV_NoSource_EditPath}/:id`} component={AddPDVNoSource} />

        <Route path={CitizensAppealsPath} exact component={CitizenAppealsMenu} />
        <Route path={Appeals} exact component={CitizensAppealList} />
        <Route path={AddAppealPath} exact component={AddAppeal} />
        <Route path={`${CitizensAppealsPath}/edit/:id`} exact component={EditAppeal} />

        <Route path={RaidJournalPathAtmo} exact render={() => <RaidJournalAtmo />} />
        <Route path={RaidJournalPathNoise} exact render={() => <RaidJournalNoise />} />
        <Route
          path={`${RaidJournalPathAtmo}/edit/:id`}
          exact
          render={() => <TaskPreparing breadcrumbs={editJournalAtmoBreadCrumbs} />}
        />
        <Route
          path={`${RaidJournalPathNoise}/edit/:id`}
          exact
          render={() => <TaskPreparing breadcrumbs={editJournalNoiseBreadCrumbs} />}
        />

        <Route path={TaskFormerPath} exact component={TaskPreparing} />

        <Route path={`${TaskFormerPath}/:taskId`} exact component={TaskPreparing} />
        <Route path={VideoCamerasPath} exact component={VideoCameras} />
        <Route path={`${VideoCamerasPath}/edit/:id`} exact component={EditCam} />
        <Route path={`${VideoCamerasPath}/add`} exact component={EditCam} />
        <Route
          path={NOISE_RAID_LIST_PATH}
          exact
          render={() => <PELRaidsJournal noise breadcrumb={noiseRaidTableBreadcrumb} />}
        />
        <Route
          path={ATMO_RAID_LIST_PATH}
          exact
          render={() => <PELRaidsJournal breadcrumb={atmoRaidTableBreadcrumb} />}
        />
        <Route path={PELTableView} exact component={PelRaidsTableView} />
        <Route path={`${PELTableView}/:type`} exact component={PelRaidsTableView} />
        <Route path={`${CitizenAppealsTableViewPath}/noise`} exact component={CitizenAppealsTableViewNoise} />
        <Route path={`${CitizenAppealsTableViewPath}/air`} exact component={CitizenAppealsTableViewAir} />
        <Route
          path={`${CitizenAppealsTableViewPath}/edit/:id`}
          exact
          render={() => <EditAppeal breadcrumbs={CitizenAppelasTableViewBreadcrumb} />}
        />

        <Route
          path={`${CitizenAppealsTableViewEditPath}/air`}
          exact
          render={() => <TaskPreparing breadcrumbs={CitizenAppelasTableViewAirTaskBreadcrumb} />}
        />
        <Route
          path={`${CitizenAppealsTableViewEditPath}/noise`}
          exact
          render={() => <TaskPreparing breadcrumbs={CitizenAppelasTableViewNoiseTaskBreadcrumb} />}
        />

        <Route path={RaidJournalAtmoAdd} exact render={() => <TaskPreparing breadcrumbs={taskFormAtmoBreadcrumb} />} />
        <Route
          path={RaidJournalNoiseAdd}
          exact
          render={() => <TaskPreparing breadcrumbs={taskFormNoiseBreadcrumb} />}
        />

        <Route path={PELNoiseAdd} exact render={() => <TaskPreparing breadcrumbs={PELNoiseBreadcrumb} />} />
        <Route path={PELAtmoAdd} exact render={() => <TaskPreparing breadcrumbs={PELAtmoBreadcrumb} />} />

        <Route
          path={tableViewToReportPath}
          exact
          render={() => <TaskPreparing breadcrumbs={tableViewToReportBreadcrumb} />}
        />
        <FilePreviewMeasuresRouting<InclinometerItemPreview> rootPath="/data/geoecological-processes/monitoring/danger/inclinometer" />

        {/* GeologicalProcesses */}
        <Route path={GroundwaterPath} exact component={Groundwater} />
        <Route path={`${GroundwaterPath}/add`} exact component={EditGroundwaterData} />
        <Route path={`${GroundwaterPath}/edit/:id`} exact component={EditGroundwaterData} />
        <Route path={`${GeologicalProcessesPath}/:type`} exact component={DangerGeologicalProcesses} />
        <Route path={`${GeologicalProcessesPath}/:type/add`} exact component={EditForm} />
        <Route path={`${GeologicalProcessesPath}/:type/edit/:id`} exact component={EditForm} />
        <Route path={`${GeologicalProcessesPath}/inclinometers/:number/:id`} exact component={InclinometerPage} />
        {/* Cartography */}
        <Route path="/cartography" exact component={Cartography} />
        <PlanningPelRoutes />
        <Route path={TASK_FORMER_PATH} exact render={() => <TaskPreparing breadcrumbs={taskFormerBreadCrumbs} />} />

        <AnalyticsReportsRoutes />

        <Route path="/notifications" exact component={Notifications} />

        <ManagerRoutes />

        {/* SENTRY PDK */}
        <PdkExcessJournalRoutes />
        {/* SENTRY JOURNAL */}
        <Route path="/sentry/journal" exact render={() => <Journal isSentry />} />
        {/* SENTRY DASHBOARD */}
        <Route path="/sentry/dashboard" exact render={() => <SentryDashBoard />} />

        {/* PROTOCOLS */}
        <Route path="/protocols" exact component={ProtocolsList} />
        <Route path="/protocols/atmosphere" exact component={AtmosphereMonitoringParameters} />
        <Route path="/protocols/water" exact component={ProtocolsWaterList} />
        <Route path="/protocols/water/askzv" exact component={ProtocolsWaterASKZV} />

        {/* Burning Registration Journal */}
        <Route path={BurningRegistrationJournalPaths.root} exact component={BurningRegistrationJournal} />
        <Route path={BurningRegistrationJournalPaths.add} exact component={BurningRegistrationJournalForm} />
        <Route path={BurningRegistrationJournalPaths.edit(':id')} exact component={BurningRegistrationJournalForm} />

        {/* Incident Recording */}
        <Route path={IncidentRecordingPaths.root} exact component={IncidentRecording} />
        <Route path={IncidentRecordingPaths.add} exact component={IncidentRecordingForm} />
        <Route path={IncidentRecordingPaths.edit(':id')} exact component={IncidentRecordingForm} />

        {/* Data entry log */}
        <Route path="/entryLogData" exact render={() => <DataEntryLog breadcrumbs={dataEntryLogBreadcrumb} />} />
      </WithSetup>
    </Switch>
  </>
);
