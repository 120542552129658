import { TPlant } from '../../usePlantsByAreaId';
import { GreenPlantsAreaLabels } from '../Common/constants';

const GreenPlantsPlantDtoLabels = {
  plantId: 'ID насаждения',
  plantType: 'Тип насаждения',
  plantNumber: '№ насаждения',
};

const GreenPlantsPlantLabels: Record<keyof TPlant, string> = {
  areaId: GreenPlantsAreaLabels.areaId,
  breed: 'Порода',
  date: GreenPlantsAreaLabels.date,
  id: '',
  number: GreenPlantsPlantDtoLabels.plantNumber,
  type: GreenPlantsPlantDtoLabels.plantType,
};

export { GreenPlantsPlantLabels, GreenPlantsPlantDtoLabels };
