import { PageHeader, RoutesList } from 'components/common';
import {
  NSIEmissionsIndustriesRootPath,
  NSIEmissionsSourcesRootPath,
} from 'pages/dictionaries/NSIEmissions/consts/paths';
import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import { NSIEmissionsListBreadcrumbs } from 'pages/dictionaries/NSIEmissions/consts/breadcrumbs';
import { Flex, Heading } from '@chakra-ui/react';

export const NSIEmissionsList = () => {
  useUpdateBreadcrumbs(NSIEmissionsListBreadcrumbs);

  return (
    <Flex flexDirection="column">
      <PageHeader>
        <Heading>Промышленные выбросы в атмосферу</Heading>
      </PageHeader>
      <RoutesList
        routes={[
          {
            path: NSIEmissionsSourcesRootPath,
            breadcrumb: 'Источники загрязнения воздуха',
          },
          {
            path: NSIEmissionsIndustriesRootPath,
            breadcrumb: 'Предприятия',
          },
        ]}
      />
    </Flex>
  );
};
