import { Field, useFormikContext } from 'formik';
import { IReportProtocolForm } from '../types';
import { FormWrapper, Textarea } from 'components/common';
import { Box, Grid, Heading } from '@chakra-ui/react';
import React from 'react';

export const Conclusion = () => {
  const { handleChange } = useFormikContext<IReportProtocolForm>();
  return (
    <Box>
      <FormWrapper>
        <Grid marginTop={5} templateColumns="repeat(1, 1fr)">
          <Heading size="md" marginBottom={5}>
            Заключение
          </Heading>
          <Field as={Textarea} name="conclusion" label="Заключение" onChange={handleChange} />
        </Grid>
      </FormWrapper>
    </Box>
  );
};
