import { Grid } from '@chakra-ui/react';
import { Field, useFormikContext } from 'formik';
import { Input, Select } from 'components/common';
import React from 'react';
import { TDictionaryItemDictionary } from 'models';
import { AsyncAddressSelect } from 'components/common/AsyncAddressSelect/AsyncAddressSelect';

interface IProps {
  districts: TDictionaryItemDictionary[];
  regions: TDictionaryItemDictionary[];
}

export const Location = ({ districts, regions }: IProps) => {
  const { errors } = useFormikContext<any>();

  return (
    <Grid gap={2} templateColumns="repeat(1, 450px)">
      <Field as={Select} name="rayonId" label="Район" options={districts} error={errors.rayonId} />
      <Field as={Select} name="okrugId" label="Округ" options={regions} error={errors.okrugId} />
      <Field as={Input} name="landmark" label="Адресный ориентир" error={errors.landmark} />
      <Field as={AsyncAddressSelect} name="address" label="Адрес" error={errors.address} />
      <Field as={Input} name="latitude" label="Широта" error={errors.latitude} />
      <Field as={Input} name="longitude" label="Долгота" error={errors.longitude} />
    </Grid>
  );
};
