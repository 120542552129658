import { FormWrapper } from 'components/common';
import { Box, Grid } from '@chakra-ui/react';
import React, { Fragment } from 'react';
import { useFormikContext } from 'formik';
import { IReportProtocolForm } from 'pages/analytics/Raids/types';
import { ANALYTICS_STATUS } from 'pages/analytics/forms/const';
import { IRenderFieldInterface, useFormField } from 'hooks/forms/useFormField';
import appLocale from 'constants/appLocale';
import { AnalyticsPelAiAtmosphereSchema } from 'models/schemas/analytics/analyticsPelAiAtmosphereSchema';

interface IProps {
  isEditMode?: boolean;
}

export const AnalyticsAiAtmosphereMeteoProbe = ({ isEditMode }: IProps) => {
  const { values } = useFormikContext<IReportProtocolForm>();

  const { renderField } = useFormField<IReportProtocolForm>({
    schema: AnalyticsPelAiAtmosphereSchema,
    labelLocalization: { ...appLocale.analytics.fields, ...appLocale.analytics.pelAi.fields },
  });

  // while status is hidden assume status as draft
  let fieldsIsDisabled = values.status === ANALYTICS_STATUS.APPROVED && !!isEditMode;
  fieldsIsDisabled = false;

  const fields: IRenderFieldInterface<IReportProtocolForm>[] = [
    { name: 'sampleActNumber', isDisabled: fieldsIsDisabled },
    { name: 'sampleRegNumber', isDisabled: fieldsIsDisabled },
    { name: 'samplePlace', isDisabled: fieldsIsDisabled },
    { name: 'methodology', isDisabled: fieldsIsDisabled },
    { name: 'sampleStartDatetime', type: 'datetimeinput', isDisabled: fieldsIsDisabled },
    { name: 'sampleEndDatetime', type: 'datetimeinput', isDisabled: fieldsIsDisabled },
    { name: 'sampleReceiveDatetime', type: 'datetimeinput', isDisabled: fieldsIsDisabled },
    { name: 'researchStartDatetime', type: 'dateinput', isDisabled: fieldsIsDisabled },
    { name: 'researchEndDatetime', type: 'dateinput', isDisabled: fieldsIsDisabled },
  ];
  return (
    <Box flexDirection="column">
      <FormWrapper>
        <Grid marginTop="30px" gap="15px">
          {fields.map((field) => (
            <Fragment key={field.name}>{renderField(field)}</Fragment>
          ))}
        </Grid>
      </FormWrapper>
    </Box>
  );
};
