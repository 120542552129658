import React, { useCallback } from 'react';
import { useRequest } from 'hooks/useRequest';
import { useHistory } from 'react-router';
import { Modal } from 'components/common/Modals';
import { useDisclosure } from 'hooks';
import { Button } from 'components/common';
import { COMMON_LOCALE } from 'constants/common';
import { useDeviceVerifications } from 'pages/equipment/Devices/hooks/useDeviceVerifications';
import {
  DeviceVerificationsTable,
  DeviceVerificationsTableProps,
} from 'pages/equipment/Devices/components/DeviceVerificationsTable';
import { VerificationForm } from 'pages/equipment/Devices/components/VerificationForm';
import { FormContainer as VerificationFormContainer } from 'pages/equipment/Devices/components/VerificationForm/FormContainer';
import { DeviceVerification } from 'pages/equipment/Devices/types';
import { Flex, Heading } from '@chakra-ui/react';

type OnDelete = DeviceVerificationsTableProps['onDelete'];

export interface DeviceVerificationsProps {
  deviceId: string;
}

export const DeviceVerifications = ({ deviceId }: DeviceVerificationsProps) => {
  const { data, isLoading, refetch } = useDeviceVerifications(deviceId);

  const history = useHistory();
  const onBack = useCallback(() => {
    history.push('/equipment/Devices');
  }, [history]);

  // edit parameter
  const { isOpen, onClose, onOpen } = useDisclosure();
  const onCancel = useCallback(() => {
    onClose();
  }, [onClose]);
  const onSuccess = useCallback(() => {
    refetch();
    onClose();
  }, [onClose, refetch]);
  const {
    post: create,
  } = useRequest<DeviceVerification>(`/equipment/v10/equipments/verifications/device/${deviceId}`, onSuccess);
  const onSubmit = useCallback((item: Partial<DeviceVerification>) => {
    create(item);
  }, [create]);

  // delete parameter
  const { remove } = useRequest('', () => {
    refetch();
  });
  const onDelete = useCallback<OnDelete>((item) => {
    remove({
      url: `/equipment/v10/equipments/verifications/${item.id}`,
    });
  }, [remove]);

  return (
    <>
      <Heading size="md" mb={4}>Свидетельства о поверке прибора</Heading>
      <DeviceVerificationsTable
        data={data}
        isLoading={isLoading}
        onDelete={onDelete}
      />

      <Flex marginTop={5}>
        <Button
          onClick={onBack}
          variant="ghost"
          marginRight={5}
        >
          {COMMON_LOCALE.cancel}
        </Button>

        <Button
          onClick={onOpen}
        >
          Добавить поверку
        </Button>

      </Flex>

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        header="Укажите сведения о поверке"
      >
        <VerificationFormContainer>
          <VerificationForm
            initialValues={{
              deviceId,
              date: null,
              dateExpired: null,
              dateStart: null,
              name: null,
            }}
            onCancel={onCancel}
            onSubmit={onSubmit}
          />
        </VerificationFormContainer>
      </Modal>
    </>
  );
};
