import { useCallback, useMemo, useState } from 'react';
import { FormikProvider, useFormik } from 'formik';

import { createTouchedErrors } from 'features/create-touched-errors';
import { useRequest } from 'hooks/useRequest';

import { SurfaceWaterFormFields } from './Fields';
import { initialEmptyValues } from './configs';
import { apiPathSurfaceWater } from '../apiPath';
import { WaterRouteSurfaceWatersRoot } from '../../routes';
import { useEnhancedNotification } from 'hooks';
import { WaterSurfaceSchema } from 'models/schemas/water';
import { ApiError } from 'models';
import { useObjectType } from 'hooks/useObjectType';
import { updateFiles } from 'api/services/media';
import { ISurfaceWaterLoadData } from '../types';
import { IUploadMedia } from 'components/common/FileUploadDragAndDrop/types';
import { formatErrorMessage } from 'lib/utils/formatErrorMessage';
import { useNotifications } from 'hooks/useNotifications';
import { ERROR_MESSAGES } from 'constants/messages';
import { LoaderItem } from 'components/common';
import { useGoBackWithFallback } from 'lib/utils/goBackWithFallback';
import { useDictionary } from 'hooks/useDictionary';
import { getSelectOptionsFromDictionaryByName } from 'features/get-select-options-from-dictionary';

export const AddSurfaceWaterForm = () => {
  const { backWithFallback } = useGoBackWithFallback();
  const [media, setMedia] = useState<IUploadMedia[]>([]);

  const { dictionaries, isDictionariesLoading: isLoading } = useDictionary({ url: apiPathSurfaceWater.dict });
  const sources = useMemo(
    () => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'sources'),
    [dictionaries?.data],
  );
  const waterObjects = useMemo(
    () => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'waterObjects'),
    [dictionaries?.data],
  );
  const organizations = useMemo(
    () => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'organizations', false),
    [dictionaries?.data],
  );
  const parameters = useMemo(
    () => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'parameters'),
    [dictionaries?.data],
  );

  const { onSuccessCreate } = useEnhancedNotification();
  const { errorNotify } = useNotifications();

  const objectType = useObjectType(dictionaries);

  const onError = useCallback(
    (error: ApiError | string | null) => {
      const message = formatErrorMessage(error);
      errorNotify({
        key: message || 'file/add/error',
        message: message || ERROR_MESSAGES.CREATE,
      });
    },
    [errorNotify],
  );

  const onSuccess = (response: ISurfaceWaterLoadData | null) => {
    if (response) {
      onSuccessCreate();
      updateFiles(response.id, objectType, media)
        .then(() => {
          backWithFallback(`${WaterRouteSurfaceWatersRoot}/main-data`);
        })
        .catch((error: Error) => {
          // TODO: redirect on error to edit
          onError(error.message);
        });
    }
  };

  const { post, isLoading: isPostLoading } = useRequest(apiPathSurfaceWater.root, onSuccess, onError);

  const useFormikProps = useMemo(
    () => ({
      enableReinitialize: true,
      validateOnChange: true,
      validateOnMount: true,
      validateOnBlur: true,
      validationSchema: WaterSurfaceSchema,
      initialValues: { ...initialEmptyValues },
      onSubmit: (values: any) => {
        const valuesToPost = { ...values };
        valuesToPost.values = valuesToPost.values.filter((val: any) => Boolean(val?.value));
        void post(valuesToPost);
      },
    }),
    [post],
  );

  const formik = useFormik(useFormikProps);

  if (isLoading || isPostLoading) {
    return <LoaderItem />;
  }

  return (
    <div>
      <FormikProvider
        value={{
          ...formik,
          errors: createTouchedErrors(formik.errors, formik.touched),
        }}
      >
        <SurfaceWaterFormFields
          parameters={parameters}
          objectType={objectType}
          sources={sources}
          waterObjects={waterObjects}
          organizations={organizations}
          isLoading={isLoading}
          media={media}
          onMediaChange={setMedia}
        />
      </FormikProvider>
    </div>
  );
};
