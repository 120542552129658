import { Checkbox } from 'components/common';
import { FilterField } from 'features/Filter/FilterFields';

export const FilterCheckbox = ({
  touched,
  initialValue,
  initialTouched,
  setError,
  setTouched,
  setValue,
  initialError,
  ...props
}: FilterField) => <Checkbox padding={0} {...props} />;
