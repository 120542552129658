import * as React from 'react';
import { Header } from 'components/common';
import { Divider, Flex, forwardRef, IconButton, IconButtonProps, Text, useDisclosure } from '@chakra-ui/react';
import { LeftSideMenu } from 'features/WithSideBar/components';
import { EGFDEMLogo, MenuFold } from 'components/icons';
import { StyledIcon } from 'components/common/StyledIcon/StyledIcon';
import { LightModeWrapper } from 'components/common/LightModeWrapper/LightModeWrapper';
import { useHistory } from 'react-router-dom';

const SidebarToggler: React.FC<IconButtonProps> = forwardRef((props, ref) => (
  <IconButton
    ref={ref}
    {...props}
    minWidth="16px"
    width="16px"
    minHeight="16px"
    height="16px"
    icon={<StyledIcon viewBox="0 0 16 16" boxSize={4} icon={<MenuFold />} />}
  />
));

export const WithSidebar: React.FC = ({ children }) => {
  const history = useHistory();
  const { isOpen, onToggle: _onToggle } = useDisclosure({ defaultIsOpen: localStorage.getItem('side_menu_open') !== 'false' });

  const onToggle = () => {
    localStorage.setItem('side_menu_open', String(!isOpen));
    _onToggle();
  };

  const menuWidth = isOpen ? '261px' : '57px';

  return (
    <Flex width="100%" justifyContent="space-between" background="inherit">
      <Flex
        id="sidebar"
        flexDirection="column"
        width={menuWidth}
        boxShadow="0px -4px 4px rgba(0, 0, 0, 0.25), 0px -4px 4px rgba(0, 0, 0, 0.1);"
        zIndex={1}
        minHeight="100vh"
        paddingRight="1px"
      >
        <Flex
          p="16px"
          flexDirection="row"
          justifyContent="flex-start"
          alignItems="center"
          height="60px"
          onClick={() => history.push('/')}
          cursor="pointer"
        >
          <StyledIcon icon={<EGFDEMLogo />} />
          {isOpen && (
            <Text fontSize={18} fontWeight="bold" ml={3}>
              ЕГФДЭМ
            </Text>
          )}
        </Flex>
        <Divider color="border.default" />
        <Flex
          px="16px"
          py="26px"
          flexDirection="column"
          height="auto"
          justifyContent="center"
          alignItems={isOpen ? 'flex-start' : 'center'}
          transform={isOpen ? 'scaleX(-1)' : undefined}
        >
          <SidebarToggler aria-label="main-sidebar-toggler" onClick={onToggle} />
        </Flex>
        <Divider color="border.default" />
        <Flex width={menuWidth}>
          <LeftSideMenu isOpen={isOpen} />
        </Flex>
      </Flex>

      <Flex
        id="content-wrapper"
        flexDirection="column"
        width={`calc(100% - ${menuWidth} - 1px)`}
        minHeight="100vh"
        zIndex={1}
        background="inherit"
      >
        <Header />
        <LightModeWrapper>{children}</LightModeWrapper>
      </Flex>
    </Flex>
  );
};
