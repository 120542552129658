import { Modal } from 'components/common/Modals';
import { ICalculatePreviewResponse, ICalculatePreviewResponseitem } from 'lib/calculations/types';
import React, { useState } from 'react';
import { Box, Flex, Heading, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { Button } from '../Button';
import moment from 'moment';
import { Line } from 'react-chartjs-2';

import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

interface Props {
  onSubmit: () => void;
  onClose: () => void;
  data: ICalculatePreviewResponse;
}

function CalculationPreview({ onClose, data, onSubmit }: Props) {
  const [tab, setTab] = useState(0);

  const renderRow = (row: ICalculatePreviewResponseitem) => (
    <Box display="flex" justifyContent="space-between" key={row.id} borderBottom="1px solid #E2E8F0" py={2}>
      <Box width="150px" px={1}>
        {moment(row.datetime).format('HH:mm, DD.MM.YYYY')}
      </Box>
      <Box width="200px" px={1}>
        {row.stationName}
      </Box>
      <Box width="200px" px={1}>
        {row.parameterName}
      </Box>
      {row.type && (
        <Box width="150px" px={1}>
          {row.type}
        </Box>
      )}
      <Box width="150px" px={1}>
        {row.value}
      </Box>
    </Box>
  );

  const renderHeader = (hasType?: boolean) => (
    <Box display="flex" justifyContent="space-between" borderBottom="1px solid #E2E8F0" py={2}>
      <Box width="150px" px={1}>
        Дата и время
      </Box>
      <Box width="200px" px={1}>
        Наименование станции
      </Box>
      <Box width="200px" px={1}>
        Параметр
      </Box>
      {hasType && (
        <Box width="150px" px={1}>
          Тип
        </Box>
      )}
      <Box width="150px" px={1}>
        Значение
      </Box>
    </Box>
  );

  const beforeOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: true,
        text: 'До обработки',
      },
    },
    scales: {
      x: {
        type: 'time',
        time: {
          tooltipFormat: 'DD.MM.YYYY HH:mm:ss',
          minUnit: 'second',
          displayFormats: {
            second: 'DD.MM HH:mm',
            minute: 'DD.MM HH:mm',
            hour: 'DD.MM HH:mm',
            day: 'DD.MM HH:mm',
            week: 'DD.MM',
            month: 'MM.YYYY',
            quarter: 'MM.YYYY',
            year: 'YYYY',
          },
        },
      },
    },
  };

  const colorScheme = [
    '#25CCF7',
    '#FD7272',
    '#54a0ff',
    '#00d2d3',
    '#1abc9c',
    '#2ecc71',
    '#3498db',
    '#9b59b6',
    '#34495e',
    '#16a085',
    '#27ae60',
    '#2980b9',
    '#8e44ad',
    '#2c3e50',
    '#f1c40f',
    '#e67e22',
    '#e74c3c',
    '#ecf0f1',
    '#95a5a6',
    '#f39c12',
    '#d35400',
    '#c0392b',
    '#bdc3c7',
    '#7f8c8d',
    '#55efc4',
    '#81ecec',
    '#74b9ff',
    '#a29bfe',
    '#dfe6e9',
    '#00b894',
    '#00cec9',
    '#0984e3',
    '#6c5ce7',
    '#ffeaa7',
    '#fab1a0',
    '#ff7675',
    '#fd79a8',
    '#fdcb6e',
    '#e17055',
    '#d63031',
    '#feca57',
    '#5f27cd',
    '#54a0ff',
    '#01a3a4',
  ];

  const afterOptions = {
    ...beforeOptions,
    plugins: {
      ...beforeOptions.plugins,
      title: {
        display: true,
        text: 'После обработки',
      },
    },
  };

  const getDataset = (data: ICalculatePreviewResponseitem[], prefix: string) => {
    const lines: any = {};
    const stations = data.map((item) => item.stationName);
    const parameters = data.map((item) => item.parameterName);
    const types = data.map((item) => item.type);

    stations.forEach((stationName) => {
      parameters.forEach((parameterName) => {
        types.forEach((type, index) => {
          const key = `${prefix} ${stationName}${parameterName ? ` ${parameterName}` : ''}${type ? ` ${type}` : ''}`;

          if (!lines[key]) {
            const items = data.filter(
              (item) =>
                item.stationName === stationName &&
                item.parameterName === parameterName &&
                item.type === type &&
                item.value !== null,
            );
            lines[key] = {
              label: key,
              data: items.map((item) => ({
                x: new Date(item.datetime).getTime(),
                y: Number(item.value),
              })),
              borderColor: colorScheme[index],
            };
          }
        });
      });
    });

    return lines;
  };

  const dataBefore = {
    datasets: [...Object.values(getDataset(data.before, 'До'))],
  };
  const dataAfter = {
    datasets: [...Object.values(getDataset(data.after, 'После '))],
  };

  return (
    <Modal isOpen onClose={onClose}>
      <Flex width="90vw" flexDirection="column">
        <Flex flexDirection="column">
          <Heading mb={8}>Обработка данных</Heading>
          <Tabs index={tab} onChange={setTab}>
            <TabList>
              <Tab>Таблица</Tab>
              <Tab>График</Tab>
            </TabList>
          </Tabs>
        </Flex>

        <Tabs index={tab} onChange={setTab}>
          <TabPanels>
            <TabPanel minWidth="80vw">
              {data.before.length ? (
                <>
                  <Heading mb={4} size="md">
                    До обработки
                  </Heading>
                  {renderHeader(!!data.before[0]?.type)}
                  {data.before.map(renderRow)}
                </>
              ) : null}

              {data.after.length ? (
                <>
                  <Heading mb={4} mt={12} size="md">
                    После обработки
                  </Heading>
                  {renderHeader(!!data.before[0]?.type)}
                  {data.after.map(renderRow)}
                </>
              ) : null}

              {data.denied.length ? (
                <>
                  <Heading mb={4} mt={12} size="md">
                    Невозможно обработать
                  </Heading>
                  {renderHeader()}
                  {data.denied.map(renderRow)}
                </>
              ) : null}
            </TabPanel>

            <TabPanel>
              <Flex gap="16px" minHeight="60vh">
                <Box width="50%">
                  {/* @ts-ignore */}
                  <Line id="LineChart" data={dataBefore} options={beforeOptions} />
                </Box>
                <Box width="50%">
                  {/* @ts-ignore */}
                  <Line id="LineChart1" data={dataAfter} options={afterOptions} />
                </Box>
              </Flex>
            </TabPanel>
          </TabPanels>
        </Tabs>

        <Flex marginTop={4} justifyContent="flex-end">
          <Button variant="outline" onClick={onClose} marginRight={2}>
            Отмена
          </Button>
          <Button onClick={onSubmit} variant="solid" isDisabled={!data.after.length}>
            Сохранить данные
          </Button>
        </Flex>
      </Flex>
    </Modal>
  );
}

export default CalculationPreview;
