import { Field, FormikProvider, useFormik } from 'formik';
import React, { useEffect, useMemo, useState } from 'react';

import { DateInput, IFilterValues, Select } from 'components/common';
import { Flex, Grid } from '@chakra-ui/react';
import { createDateFromResponse, createDateToRequestForFilter, createDateToRequestWithTimezone } from 'lib/create-date';
import { TDictionariesArray } from 'models/dictionaries';
import {
  getSelectOptionsFromDictionaryByName,
  getSelectOptionsFromDictionaryByNameWithParent,
} from 'features/get-select-options-from-dictionary';
import { PeriodsButtons } from 'features/PeriodsButtons';

import { IPeriod } from 'features/PeriodsButtons/types';
import { usePeriods } from 'features/PeriodsButtons/usePeriods';
import combineFilters from 'lib/utils/combineFilters';
import { IFilterItem, TFiltersArray } from 'models';
import { MomentInput } from 'moment';
import { FiltersDatepickersSchema } from 'models/schemas/filtersDatepickersSchema/FiltersDatepickersSchema';
import FilterFormControls from 'components/form/FilterFormControls';

interface FilterProps {
  isLoading?: boolean;
  filtersFromGraphic: Partial<IFilterValues> | null;
  setFiltersFromGraphic: (value: Partial<IFilterValues> | null) => void;
  isDisabled?: boolean;
  dictionaries: {
    data: TDictionariesArray;
  } | null;
  onSubmit: (values: any) => void;
  children: React.ReactNode;
  periods: IPeriod[] | null;
  filters?: TFiltersArray;
}

const initialEmptyValues: Record<string, IFilterItem<string>> = {
  measuringDateMin: {
    key: 'valDateTime',
    operation: 'GTE',
    value: null,
  },
  measuringDateMax: {
    key: 'valDateTime',
    operation: 'LTE',
    value: null,
  },
  areaId: {
    key: 'areaId',
    operation: 'EQ',
    value: null,
  },
  pointId: {
    key: 'pointId',
    operation: 'EQ',
    value: null,
  },
  // showDisabled - value равно '' если чекбокс включен и нужно получить с Backend все значения (этот объект удалиться при отправке)
  // value равно false - если нужно показать только неудаленные записи
  showDisabled: {
    key: 'deleted',
    operation: 'EQ',
    value: false,
  },
  periodId: {
    key: 'periodId',
    operation: 'EQ',
    value: null,
  },
};

export const ReperFilter = ({
  setFiltersFromGraphic,
  filtersFromGraphic,
  isLoading,
  isDisabled,
  onSubmit,
  dictionaries,
  children,
  periods,
  filters = [],
  ...props
}: FilterProps) => {
  const savedPeriodId = useMemo(
    () => (filters.find((item) => item.key === 'periodId')?.value as string) || '',
    [filters]
  );

  const [periodId, setPeriodId] = useState<string>(savedPeriodId);

  const { getDatesFromPeriod, dates } = usePeriods(periodId, periods);

  const submitPeriod = (id: string) => {
    const dates = getDatesFromPeriod(id);
    setFieldValue('periodId.value', id);
    setFieldValue('measuringDateMin.value', createDateToRequestWithTimezone(dates[0]));
    setFieldValue('measuringDateMax.value', createDateToRequestWithTimezone(dates[1]));
    setFieldTouched('measuringDateMin.value');
    setPeriodId(id);
  };

  const {
    values,
    errors,
    getFieldMeta,
    getFieldHelpers,
    getFieldProps,
    handleReset,
    submitForm,
    setFieldValue,
    setValues,
    setFieldTouched,
    ...formik
  } = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    validationSchema: FiltersDatepickersSchema([
      { dateFromFieldName: 'measuringDateMin', dateToFieldName: 'measuringDateMax' },
    ]),
    initialValues: initialEmptyValues,
    onSubmit,
  });

  useEffect(() => {
    setValues(combineFilters(initialEmptyValues, filters));
  }, [filters, setValues]);

  const areas = useMemo(() => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'areas'), [dictionaries]);

  const points = useMemo(() => {
    const points = getSelectOptionsFromDictionaryByNameWithParent(dictionaries?.data, 'points');

    if (values.areaId.value) {
      return points.filter((item) => item.parent === values.areaId.value);
    }

    return points;
  }, [dictionaries, values.areaId.value]);
  useEffect(() => {
    if (filtersFromGraphic) {
      if (filtersFromGraphic.startDate) {
        setFieldValue('dateTimeMin.value', filtersFromGraphic.startDate, true);
      }
      if (filtersFromGraphic.stopDate) {
        setFieldValue('dateTimeMax.value', filtersFromGraphic.stopDate, true);
      }
    }
    setFiltersFromGraphic(null);
  }, [filtersFromGraphic, setFieldValue, setFiltersFromGraphic]);

  return (
    <FormikProvider
      value={{
        values,
        errors,
        getFieldMeta,
        getFieldHelpers,
        getFieldProps,
        handleReset,
        submitForm,
        setFieldValue,
        setValues,
        setFieldTouched,
        ...formik,
      }}
    >
      <Flex flexDirection="row" justifyContent="space-between">
        <Grid gap="12px" rowGap="8px" gridTemplateColumns="repeat(2, 175px) 225px 225px" {...props}>
          <Field
            as={DateInput}
            name="measuringDateMin.value"
            label="От"
            maxDate={dates?.[1]}
            minDate={dates?.[0]}
            isDisabled={!periodId}
            value={createDateFromResponse(values.measuringDateMin.value as MomentInput)}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFieldValue('measuringDateMin.value', createDateToRequestForFilter(e.target.value));
            }}
            showTimeInput={false}
            error={errors.measuringDateMin?.value}
          />
          <Field
            as={DateInput}
            name="measuringDateMax.value"
            label="До"
            maxDate={dates?.[1]}
            minDate={dates?.[0]}
            isDisabled={!periodId}
            value={createDateFromResponse(values.measuringDateMax.value as MomentInput)}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFieldValue('measuringDateMax.value', createDateToRequestForFilter(e.target.value, 'end'));
            }}
            showTimeInput={false}
            error={errors.measuringDateMax?.value}
          />
          <Field
            as={Select}
            name="areaId.value"
            label="Участок"
            options={areas}
            value={values.areaId.value}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFieldValue('areaId.value', e.target.value);
              setFieldValue('pointId.value', null);
            }}
            error={errors.areaId?.value}
          />
          <Field
            as={Select}
            name="pointId.value"
            label="Точка наблюдения"
            options={points}
            value={values.pointId.value}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFieldValue('pointId.value', e.target.value);
            }}
            error={errors.pointId?.value}
          />

          {children}
        </Grid>

        <FilterFormControls
          onReset={(e) => {
            handleReset(e);
            setPeriodId('');
            setTimeout(submitForm, 0);
          }}
          onSubmit={submitForm}
          isDisabled={isDisabled}
          isLoading={isLoading}
        />
      </Flex>

      <PeriodsButtons periodId={periodId} periods={periods} submitPeriod={submitPeriod} />
    </FormikProvider>
  );
};
