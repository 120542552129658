import React, { useMemo } from 'react';
import { Flex } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import { IUseListMaterialTableProps, IWithFileDownload, useHandleDeleteData } from 'hooks';

import { TDictionariesArray, TFilterDTO, TFiltersArray } from 'models';
import { createLocalDateDDMMYYYY } from 'lib/create-date';
import { MaterialTable } from 'features/material-table/MaterialTableMini';
import { SoilItems, SoilListItem } from '../const/types';
import { CellObservationPoint } from './Cells';
import { DeleteIcon, EditIcon, ImageIcon } from 'components/icons';
import useFilePreviewModalWrapperData from 'components/common/FilePreviewModal/hooks/useFilePreviewModalWrapperData';
import FilePreviewModalWrapper from 'components/common/FilePreviewModal/FilePreviewModalWrapper';
import { Column } from '@material-table/core';
import { addFiltersToColumns, ColumnWithFilter } from 'lib/utils/tableFilter';
import { TableFilterTypeEnum } from 'components/table/filters/TableFilter';
import { PropActions } from 'features/material-table/components/Actions';
import { getSelectOptionsFromDictionaryByName } from 'features/get-select-options-from-dictionary';
import { DefaultTableCellSize } from '../../../../../components/table/DefaultTableCellSize';

interface SoilTableProps {
  soilList: SoilItems | null;
  refetchCB: () => void;
  toggleOrder: (fieldKey: string) => void;
  onPageChange: (page: number, pageSize: number) => void;
  filters: TFilterDTO<keyof SoilListItem>['filters'];
  setFilters: (filters: TFiltersArray<keyof SoilListItem>) => void;
  downloadAsFile: IWithFileDownload;
  selectedIndexes?: number[];
  onSelectionChange?: (data: SoilListItem[], rowData?: SoilListItem | undefined) => void;
  saveFilters?: () => void;
  onSearch?: (searchFields: string[], searchText: string) => void;
  filterDTO: TFilterDTO;
  isReadonly?: boolean;
  isExecute?: boolean;
  isWrite?: boolean;
  dictionaries: {
    data: TDictionariesArray;
  } | null;
  isLoading: boolean;
  errorText?: string;
  materialTableProps: IUseListMaterialTableProps;
}

export const SoilTable: React.FC<SoilTableProps> = ({
  soilList,
  refetchCB,
  toggleOrder,
  onPageChange,
  downloadAsFile,
  filters,
  setFilters,
  selectedIndexes,
  onSelectionChange,
  saveFilters,
  onSearch,
  filterDTO,
  isExecute,
  isWrite,
  dictionaries,
  isLoading,
  errorText,
  materialTableProps,
}) => {
  const history = useHistory();
  const { open, ...filePreviewData } = useFilePreviewModalWrapperData();

  const listingParameters = useMemo(
    () => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'listingParameters'),
    [dictionaries],
  );

  // TODO: вынести в отдельный модуль рендеринг полей из параметров
  const columnParameters = useMemo<Array<ColumnWithFilter<SoilListItem>>>(() => {
    const fields: Array<ColumnWithFilter<SoilListItem>> = [];
    listingParameters.forEach((parameter) => {
      const { parameterId } = parameter;
      if (!parameterId) {
        return;
      }
      switch (parameter.fieldType) {
        case 'NUMERIC':
          fields.push({
            title: parameter.title,
            field: parameterId,
            filterType: TableFilterTypeEnum.number,
            render: (row) => row.parameters[parameterId]?.[0]?.value,
          });
          return;
        case 'BOOL':
          fields.push({
            title: parameter.title,
            field: parameterId,
            filterType: TableFilterTypeEnum.string,
            render: (row) =>
              // eslint-disable-next-line no-nested-ternary
              (row.parameters[parameterId]?.[0]?.value
                ? row.parameters[parameterId]?.[0]?.value === 'true'
                  ? 'Да'
                  : 'Нет'
                : ''),
          });
          return;
        case 'DIC':
          fields.push({
            title: parameter.title,
            field: parameterId,
            filterType: TableFilterTypeEnum.string,
            render: (row) => <DefaultTableCellSize>{row.parameters[parameterId]?.[0].value}</DefaultTableCellSize>,
          });
          return;
        case 'DICLIST':
          fields.push({
            title: parameter.title,
            field: parameterId,
            filterType: TableFilterTypeEnum.string,
            render: (row) =>
              <DefaultTableCellSize>
                {row.parameters[parameterId]
                  ?.filter((v) => !!v.value)
                  .map((value) => value.value)
                  .join(', ')}
              </DefaultTableCellSize>
          });
      }
    });
    return fields;
  }, [listingParameters]);

  const columns = useMemo<ColumnWithFilter<SoilListItem>[]>(
    () => [
      {
        title: 'Дата протокола',
        field: 'protocolDate',
        render: (row) => <Flex>{row.protocolDate && createLocalDateDDMMYYYY(row.protocolDate)}</Flex>,
      },
      {
        title: 'Номер протокола',
        field: 'protocolNumber',
        filterType: TableFilterTypeEnum.string,
      },
      {
        title: 'Дата пробы',
        field: 'probeDate',
        render: (row) => <Flex>{createLocalDateDDMMYYYY(row.probeDate)}</Flex>,
      },
      {
        title: 'Площадка',
        field: 'source',
        render: (row: SoilListItem) => <CellObservationPoint label={row.source} id={row.sourceId} />,
        filterType: TableFilterTypeEnum.number,
      },
      {
        title: 'Округ',
        field: 'region',
        filterType: TableFilterTypeEnum.string,
      },
      {
        title: 'Район',
        field: 'district',
        filterType: TableFilterTypeEnum.string,
      },
      {
        title: 'Адрес',
        field: 'address',
        filterType: TableFilterTypeEnum.string,
        render: (row) => (
          <DefaultTableCellSize>
            {row.address}
          </DefaultTableCellSize>
        )
      },
      {
        title: 'Функциональная зона',
        field: 'funcZone',
        filterType: TableFilterTypeEnum.string,
        render: (row) => (
          <DefaultTableCellSize>
            {row.funcZone}
          </DefaultTableCellSize>
        )
      },
      {
        title: 'Тип функциональной зоны',
        field: 'funcZoneType',
        filterType: TableFilterTypeEnum.string,
        render: (row) => (
          <DefaultTableCellSize>
            {row.funcZoneType}
          </DefaultTableCellSize>
        )
      },
      ...columnParameters,
    ],
    [columnParameters],
  );

  const columnsWithFilters = useMemo<Array<Column<SoilListItem>>>(
    () => addFiltersToColumns({ filters, setFilters, columns }),
    [columns, filters, setFilters],
  );

  const { ModalComponent: DeleteModal, handleOpenModal } = useHandleDeleteData({ refetch: refetchCB });

  const initialActions = useMemo<PropActions<SoilListItem[]>>(
    () => [
      {
        position: 'row',
        action: (row: SoilListItem) => ({
          icon: () => <EditIcon />,
          onClick: () => history.push(`/data/soil/edit/row/${row.id}`, row),
          tooltip: 'Редактировать',
          hidden: !isWrite || !row.isEditable,
        }),
      },
      {
        position: 'row',
        action: (row: SoilListItem) => ({
          icon: () => <DeleteIcon />,
          onClick: () => handleOpenModal({ url: `/soilpollution/v10/soilpollution/${row.id}` }),
          tooltip: 'Удалить',
          hidden: !isWrite || !row.isEditable,
        }),
      },
      {
        position: 'row',
        action: (row: any) => ({
          icon: () => <ImageIcon />,
          onClick: () => open(row.id, row.objectType),
          tooltip: 'Показать изображения',
          hidden: !row.objectType,
        }),
      },
    ],
    [isWrite, history, handleOpenModal, open]
  );

  return (
    <>
      <MaterialTable
        isNotExecute={isExecute}
        toggleOrder={toggleOrder}
        onPageChange={onPageChange}
        filterDTO={filterDTO}
        data={soilList?.data}
        meta={soilList?.meta}
        columns={columnsWithFilters}
        actions={initialActions}
        downloadAsFile={downloadAsFile}
        selectedIndexes={selectedIndexes}
        onSelectionChange={onSelectionChange}
        saveFilters={saveFilters}
        setFilters={setFilters}
        options={{
          search: true,
        }}
        onSearch={onSearch}
        isLoading={isLoading}
        errorText={errorText}
        {...materialTableProps}
        showTotalCount
      />

      {DeleteModal}

      <FilePreviewModalWrapper {...filePreviewData} />
    </>
  );
};
