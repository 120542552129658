import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import appLocale from 'constants/appLocale';
import { SubsystemHeader } from 'components/common';
import { IncidentRecordingFilter } from 'pages/IncidentRecording/IncidentRecordingFilter';
import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import {
  IncidentRecordingApiRoot,
  incidentRecordingBreadcrumbs,
  incidentRecordingColumns,
  IncidentRecordingPaths,
} from 'pages/IncidentRecording/const';
import { MaterialTable } from 'features/material-table/MaterialTableMini';
import { usePeriodsDict } from 'features/PeriodsButtons/usePeriodsDict';
import subsystemIdsMap from 'constants/subsystemIdsMap';
import { useAvailability, useHandleDeleteData, useList } from 'hooks';
import { useDictionary } from 'hooks/useDictionary';
import { PropActions } from 'features/material-table/components/Actions';
import { IncidentRecordingItem, TIncidentRecordingFilterType } from 'pages/IncidentRecording/types';
import { Column } from '@material-table/core';
import { addFiltersToColumns } from 'lib/utils/tableFilter';
import { ImageIcon } from 'components/icons';
import useFilePreviewModalWrapperData from 'components/common/FilePreviewModal/hooks/useFilePreviewModalWrapperData';
import FilePreviewModalWrapper from 'components/common/FilePreviewModal/FilePreviewModalWrapper';
import { DEFAULT_PAGE_SIZE_NEW_TABLE } from '../../constants/tables';
import { FullPageWrapper } from '../../components/table/FullPageWrapper';

export const IncidentRecording = () => {
  const {
    isWrite, isExecute, isNoRights, allAvailable, isAvailable,
  } = useAvailability(subsystemIdsMap.dutyOfficer);

  const { push } = useHistory();
  useUpdateBreadcrumbs(incidentRecordingBreadcrumbs);

  const { dictionaries, isDictionariesLoading } = useDictionary(
    { url: `${IncidentRecordingApiRoot}/dictionaries` },
  );

  const { result: periods } = usePeriodsDict(`auth/v10/user/period/${subsystemIdsMap.dutyOfficer}`);

  const {
    refetch,
    isLoading: isListLoading,
    response,
    toggleOrder,
    setFilters,
    onPageChange,
    filterDTO,
    downloadAsFile,
    saveFilters,
    onSearchText,
    errorText,
    selectedIndexes,
    renderOnlySelectedCheckbox,
    onSubmitFilters,
    onSelectionChange,
    materialTableProps,
  } = useList<IncidentRecordingItem, TIncidentRecordingFilterType>(
    `${IncidentRecordingApiRoot}/list`,
    {
      initialOrderFieldKey: '-incidentDateTime',
      exportSubsystemName: 'incident_recording',
      preventFirstFetch: true,
      selectionKey: 'id',
      saveFiltersState: true,
      defaultPageSize: DEFAULT_PAGE_SIZE_NEW_TABLE,
      resetDataOnNextRequest: true,
    },
  );

  const { ModalComponent: ConfirmDeleteModal, handleOpenModal } = useHandleDeleteData({ refetch });

  const { open, ...filePreviewData } = useFilePreviewModalWrapperData();

  const actions = useMemo<PropActions<IncidentRecordingItem[]>>(() => {
    const initialActions: PropActions<IncidentRecordingItem[]> = [
      {
        position: 'row',
        action: (row: any) => ({
          icon: () => <ImageIcon />,
          onClick: () => open(row.id, row.objectType),
          tooltip: 'Показать изображения',
          hidden: !row.objectType,
        }),
      },
      {
        type: 'edit',
        onClick: (_e, item) => {
          if (!Array.isArray(item)) {
            const editPath = IncidentRecordingPaths.edit(item.id);
            if (editPath) {
              push(editPath);
            }
          }
        },
      },
      {
        type: 'delete',
        onClick: (_e, item) => {
          if (!Array.isArray(item)) {
            handleOpenModal({ url: `${IncidentRecordingApiRoot}/${item.id}` });
          }
        },
      },
    ];

    if (allAvailable || isNoRights || isWrite) {
      return initialActions;
    }

    return initialActions.filter((action: any) => action.type !== 'delete');
  }, [allAvailable, isNoRights, isWrite, open, push, handleOpenModal]);

  const columnsWithFilters = useMemo<Column<IncidentRecordingItem>[]>(
    () => addFiltersToColumns<IncidentRecordingItem, TIncidentRecordingFilterType>({
      filters: filterDTO.filters,
      setFilters,
      columns: incidentRecordingColumns,
    }),
    [filterDTO.filters, setFilters],
  );

  const isLoading = useMemo(() => isListLoading || isDictionariesLoading, [isDictionariesLoading, isListLoading]);

  return (
    <FullPageWrapper>
      <SubsystemHeader
        title={appLocale.subsystems.incidentRecording}
        addButtonProps={{
          onClick: () => push(IncidentRecordingPaths.add),
          isDisabled: !isAvailable,
        }}
        filtersComponent={
          <IncidentRecordingFilter
            savedPeriodId={filterDTO?.filters.find((item) => item.key === 'periodId')?.value as string}
            periods={periods}
            dictionaries={dictionaries}
            isLoading={isLoading}
            onSubmit={onSubmitFilters}
            filters={filterDTO.filters}
          >
            {renderOnlySelectedCheckbox}
          </IncidentRecordingFilter>
        }
      />

      <MaterialTable
        isNotExecute={isExecute || isNoRights}
        data={response?.data}
        meta={response?.meta}
        toggleOrder={toggleOrder}
        onPageChange={onPageChange}
        filterDTO={filterDTO}
        columns={columnsWithFilters}
        actions={actions}
        options={{
          search: true,
        }}
        isLoading={isLoading}
        onSearch={onSearchText}
        downloadAsFile={downloadAsFile}
        selectedIndexes={selectedIndexes}
        onSelectionChange={onSelectionChange}
        saveFilters={saveFilters}
        setFilters={setFilters}
        errorText={errorText}
        {...materialTableProps}
      />

      {ConfirmDeleteModal}

      <FilePreviewModalWrapper {...filePreviewData} />
    </FullPageWrapper>
  );
};
