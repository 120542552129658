import { MaterialTable } from 'features/material-table/MaterialTableMini';
import { DeviceVerification } from 'pages/equipment/Devices/types';
import { deviceVerificationLocale, statuses } from 'pages/equipment/Devices/consts';
import { createLocalDateDDMMYYYY } from 'lib/create-date';
import { DefaultText } from 'components/common';
import { isExpired } from 'pages/equipment/Devices/lib';
import { DeleteIcon } from 'components/icons';
import React from 'react';

export interface DeviceVerificationsTableProps {
  data?: any;
  isLoading: boolean;
  onDelete: (item: DeviceVerification) => void;
}

export const DeviceVerificationsTable = ({ data, isLoading, onDelete }: DeviceVerificationsTableProps) => (
  <MaterialTable
    data={data}
    columns={[
      {
        title: deviceVerificationLocale.name,
        field: 'name',
      },
      {
        title: deviceVerificationLocale.dateStart,
        field: 'dateStart',
        render: (row) => createLocalDateDDMMYYYY(row.dateStart)
      },
      {
        title: deviceVerificationLocale.dateExpired,
        field: 'dateExpired',
        render: (row) => createLocalDateDDMMYYYY(row.dateExpired)
      },
      {
        title: deviceVerificationLocale.status,
        render: (row) => {
          const isDateExpired = isExpired(row.dateExpired);
          const isActual = row.status === 'ACTUAL';
          if (isActual && isDateExpired) {
            return (
              <DefaultText color="red" textColor="red">
                {statuses[row.status]}
              </DefaultText>
            );
          }
          return statuses[row.status] || '';
        }
      },
    ]}
    actions={[
      {
        position: 'row',
        action: (rowData) => ({
          hidden: rowData.status === 'EXPIRED',
          icon: () => <DeleteIcon />,
          tooltip: 'Удалить',
          onClick: () => {
            if (Array.isArray(rowData)) {
              return;
            }
            onDelete(rowData);
          },
        }),
      }
    ]}
    isLoading={isLoading}
  />
);
