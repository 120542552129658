import { Column } from '@material-table/core';
import TableFilter, { TableFilterTypeEnum } from 'components/table/filters/TableFilter';
import { TFiltersArray } from 'models';
import React from 'react';
import { SelectOption } from 'components/common';

export interface ColumnWithFilter<T extends object> extends Column<T> {
  filterName?: string;
  filterType?: TableFilterTypeEnum;
}

interface IAddFiltersToColumns<T extends object, Keys = string> {
  filters: TFiltersArray<Keys>;
  setFilters: (filters: TFiltersArray<Keys>) => void;
  columns: Array<ColumnWithFilter<T>>;
  selectOptions?: Map<Keys, SelectOption[]>;
}

export function addFiltersToColumns<T extends object, Keys = string>({
  filters,
  setFilters,
  columns,
  selectOptions,
}: IAddFiltersToColumns<T, Keys>): Array<Column<T>> {
  return columns.map((column) => {
    const { filterName, filterType, ...rest } = column;

    if (filterType === undefined) {
      return { ...rest };
    }

    const name = (filterName || rest.field) as unknown as Keys;

    return {
      ...rest,
      filterComponent: () => {
        if (filterName || rest.field) {
          return (
            <TableFilter
              setFilters={setFilters}
              filters={filters}
              type={filterType}
              // TODO: refactor
              fieldKey={name}
              selectOptions={(selectOptions && name && selectOptions.get(name)) || undefined}
            />
          );
        }
        return <div />;
      },
    };
  });
}
