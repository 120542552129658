import { Filter } from 'features/Filter';
import { createInitialValuesToFormik, createValuesToRequest } from 'features/Filter/utils';
import React, { useMemo } from 'react';
import {
  TAtmosphereMonitoringFilterDTO,
  TAtmosphereMonitoringFilterType,
} from 'pages/data/Atmosphere/Monitoring/types';
import { IFilterItem, TDictionariesArray, TFiltersArray } from 'models';
import { IFilterValues } from 'components/common';
import { getSelectOptionsFromDictionaryByNameWithTransformation } from 'features/get-select-options-from-dictionary';
import combineFilters from 'lib/utils/combineFilters';
import { transformFiltersArray } from 'features/Filter/transformFiltersArray';
import { createDateToRequestWithTimezone } from 'lib/create-date';
import { IPeriod } from 'features/PeriodsButtons/types';
import { SchemaForCustomFilter } from 'models/schemas/filtersDatepickersSchema/FiltersDatepickersSchema';

type FilterListInitialValues = Record<
  'dateTimeMin' | 'dateTimeMax' | 'paramId' | 'stationId' | 'deleted' | 'periodId' | 'initialData',
  TAtmosphereMonitoringFilterDTO['filters'][number]
>;

const initialValues: FilterListInitialValues = {
  dateTimeMin: {
    key: 'avg.valDateTime',
    operation: 'GTE',
    value: null,
  },
  dateTimeMax: {
    key: 'avg.valDateTime',
    operation: 'LTE',
    value: null,
  },
  paramId: {
    key: 'paramId',
    operation: 'EQ',
    value: null,
  },
  stationId: {
    key: 'stationId',
    operation: 'EQ',
    value: null,
  },
  deleted: {
    key: 'deleted',
    operation: 'EQ',
    value: false,
  },
  periodId: {
    key: 'periodId',
    operation: 'EQ',
    value: null,
  },
  initialData: {
    key: 'initialData',
    operation: 'EQ',
    value: false,
  }
};

interface FilterProps {
  dictionaries: {
    data: TDictionariesArray;
  } | null;
  isLoading: boolean;
  onSubmit: (values: TFiltersArray<TAtmosphereMonitoringFilterType>) => TFiltersArray<TAtmosphereMonitoringFilterType>;
  filtersFromGraphic: Partial<IFilterValues> | null;
  setFiltersFromGraphic: (value: Partial<IFilterValues> | null) => void;
  filters: IFilterItem[];
  periods: IPeriod[] | null;
  savedPeriodId?: string;
  setGraphInitialFilters?: (filters: any) => void;
}

const initialValuesToFormik = createInitialValuesToFormik(initialValues);

function AtmosphereFilter({
  setFiltersFromGraphic,
  filtersFromGraphic,
  dictionaries,
  isLoading,
  onSubmit,
  children,
  filters,
  periods,
  savedPeriodId,
  setGraphInitialFilters,
}: React.PropsWithChildren<FilterProps>) {
  const stationsName = useMemo(
    () => getSelectOptionsFromDictionaryByNameWithTransformation(dictionaries?.data, 'stations', 'name'),
    [dictionaries]
  );

  const paramNumbers = useMemo(
    () => getSelectOptionsFromDictionaryByNameWithTransformation(dictionaries?.data, 'params', 'title'),
    [dictionaries]
  );

  const presetValues = useMemo<{ key: string; value: IFilterItem['value'] }[]>(() => {
    const preset: { key: string; value: IFilterItem['value'] }[] = [];
    const initialFilters = combineFilters(initialValues, filters);
    Object.keys(initialFilters).forEach((key) => {
      preset.push({ key: `${key}`, value: initialFilters[key].value });
    });
    return preset;
  }, [filters]);

  return (
    <Filter<typeof initialValuesToFormik>
      savedPeriodId={savedPeriodId}
      dateFieldFrom="dateTimeMin"
      dateFieldTo="dateTimeMax"
      periods={periods}
      isLoading={isLoading}
      isDisabled={isLoading}
      setFiltersFromGraphic={setFiltersFromGraphic}
      filtersFromGraphic={filtersFromGraphic}
      onSubmit={(values) => {
        const { dateTimeMin, dateTimeMax, ...rest } = createValuesToRequest(initialValues, values);
        const filters = transformFiltersArray<TAtmosphereMonitoringFilterDTO['filters']>(
          Object.values({
            ...rest,
            dateTimeMin: {
              ...dateTimeMin,
              value: createDateToRequestWithTimezone(String(dateTimeMin.value)),
            },
            dateTimeMax: {
              ...dateTimeMax,
              value: createDateToRequestWithTimezone(String(dateTimeMax.value)),
            },
          })
        );
        return onSubmit(filters);
      }}
      setGraphInitialFilters={setGraphInitialFilters}
      initialValues={initialValuesToFormik}
      mainFields={[
        {
          type: 'DATE',
          name: 'dateTimeMin',
          label: 'От',
          showTimeInput: true,
        },
        {
          type: 'DATE',
          name: 'dateTimeMax',
          label: 'До',
          showTimeInput: true,
        },
        {
          type: 'SELECT',
          name: 'stationId',
          label: 'Станция',
          options: stationsName,
        },
        {
          type: 'SELECT',
          name: 'paramId',
          label: 'Параметр',
          options: paramNumbers,
        },
        {
          type: 'CHECKBOX',
          name: 'initialData',
          label: 'Исходные данные',
        },
      ]}
      presetValues={presetValues}
      validationSchema={() =>
        SchemaForCustomFilter([{ dateFromFieldName: 'dateTimeMin', dateToFieldName: 'dateTimeMax' }])
      }
    >
      {children}
    </Filter>
  );
}

export { AtmosphereFilter };
