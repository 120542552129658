import { Button, FormWrapper, Input, LoaderItem, Select } from 'components/common';
import { Box, Flex, Grid } from '@chakra-ui/react';
import { Field, useFormikContext } from 'formik';
import React from 'react';
import { DepartmentItem } from '../../models/DepartmentItem';
import { DisplayUserItem } from '../../models/UserItem';
import { usePaginatedLoader } from '../../typedLoader';
import { baseAdminUserUrl } from '../../User/consts';

export interface FieldFormProps {
  onCancel: () => void;
  isLoading: boolean;
}

const FieldsForm = (props: FieldFormProps) => {
  const { onCancel, isLoading } = props;
  const { handleChange, setFieldValue, values, errors, handleSubmit } = useFormikContext<DepartmentItem>();

  const { result: userList, isLoading: isUsersLoading } = usePaginatedLoader<DisplayUserItem>(baseAdminUserUrl);

  const users = userList?.data.map((us: DisplayUserItem) => ({
    name: us.fullName,
    value: us.id as string,
  }));

  if (isLoading || isUsersLoading) {
    return <LoaderItem />;
  }

  return (
    <Box flexDirection="column">
      <FormWrapper>
        <Grid marginTop="30px" gap="15px">
          <Field
            as={Input}
            name="code"
            label="Внешний код"
            value={values.code}
            onChange={handleChange}
            error={errors.code}
          />
          <Field as={Input} name="name" label="Отдел" value={values.name} onChange={handleChange} error={errors.name} />
          <Field
            as={Select}
            name="managerId"
            label="Пользователь"
            value={values.managerId}
            options={users || []}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              setFieldValue('managerId', e.target.value);
            }}
            error={errors.managerId}
          />
          <Flex>
            <Button onClick={onCancel} marginRight={20}>
              Отмена
            </Button>
            <Button onClick={() => handleSubmit()} variant="solid">
              Сохранить
            </Button>
          </Flex>
        </Grid>
      </FormWrapper>
    </Box>
  );
};

export default React.memo(FieldsForm);
