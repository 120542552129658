import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { IForecastPreviewItem } from 'pages/data/Meteo/Forecast/Data/types';
import { DefaultText, PortalPopover } from 'components/common';
import { createLocalDateDDMMYYYY } from 'lib/create-date';
import Divider from '@material-ui/core/Divider';
import { TableMenu } from './TableMenu';
import { Box, Flex } from '@chakra-ui/react';

interface Props {
  data: Array<IForecastPreviewItem> | undefined;
}

export const PreviewCustomTable = ({ data = [] }: Props) => (
  <TableContainer
    component={Paper}
    style={{ maxHeight: 1000, alignSelf: 'center', maxWidth: '95%', marginTop: '20px' }}
  >
    <TableMenu />
    <Table stickyHeader>
      <TableHead>
        <TableRow>
          <TableCell rowSpan={2} style={{ fontWeight: 'bold' }}>
            Метеопараметр
          </TableCell>
          {
              data.map((item) => (
                <TableCell rowSpan={2} style={{ fontWeight: 'bold' }}>
                  <Flex flexDirection="column">
                    <Box marginBottom={1}>
                      {item.dayPart.value}
                    </Box>
                    <Divider />
                    <Box marginTop={1}>
                      {createLocalDateDDMMYYYY(item.measureDate)}
                    </Box>
                  </Flex>
                </TableCell>
              ))
            }
        </TableRow>
      </TableHead>
      <TableBody>
        {
          data[0].parameters.map((param) => (
            <TableRow>
              <TableCell>{param.parameter.value}</TableCell>
              {
                data.map((item) => {
                  const idx = item.parameters.findIndex((val) => val.parameter.key === param.parameter.key);
                  const value = item.parameters[idx]?.value.value || '-';
                  const isError = Boolean(item.parameters[idx]?.value.error);
                  return (
                    <TableCell>
                      {
                        isError ? (
                          <PortalPopover
                            label={value}
                            color="red"
                            textColor="red"
                            variant="ghost"
                            textDecoration="underline"
                            textDecorationStyle="dotted"
                          >
                            <DefaultText>
                              {item.parameters[idx]?.value.error}
                            </DefaultText>
                          </PortalPopover>
                        ) : (
                          value
                        )
                      }
                    </TableCell>
                  );
                })
              }
            </TableRow>
          ))
        }
      </TableBody>
    </Table>
  </TableContainer>
);
