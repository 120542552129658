import { ErrorDescriptions } from 'features/error-descriptions-v-stack';
import { memo } from 'react';
import { LabelText, PortalPopover } from 'components/common';
import { useGeoDangerProcessDistrictData } from 'hooks/geo/DangerProcess/useGeoDangerProcessDistrictData';
import { Stack } from '@chakra-ui/react';
import { dangerProcessPopoverProps } from '../constants';

interface DangerProcessAreaPortalPopoverProps {
  label?: string | number | null;
  id: string;
}

export const DangerProcessAreaPortalPopover: React.FC<DangerProcessAreaPortalPopoverProps> = memo(({ label, id }) => {
  const { get, result, error, isLoading } = useGeoDangerProcessDistrictData();

  return (
    <PortalPopover
      onOpen={() => get({ params: { id } })}
      label={typeof label === 'string' ? label : ''}
      isLoading={isLoading}
      {...dangerProcessPopoverProps}
      style={{
        fontSize: 'inherit',
        lineHeight: 'inherit',
      }}
    >
      <Stack spacing="15px" padding="20px" fontSize="16px" lineHeight="18px" whiteSpace="initial" wordBreak="break-all">
        {result?.code && <LabelText label="Код участка:">{result.code}</LabelText>}
        {result?.name && <LabelText label="Наименование:">{result.name}</LabelText>}
        {result?.geoDistrictType && <LabelText label="Тип участка:">{result.geoDistrictType}</LabelText>}
        {result?.address && <LabelText label="Адресная привязка:">{result.address}</LabelText>}

        {error && 'descriptions' in error && <ErrorDescriptions spacing={15} descriptions={error.descriptions} />}
      </Stack>
    </PortalPopover>
  );
});
