import { Field, FormikContextType, FormikErrors, FormikValues, useFormikContext } from 'formik';
import { Button, Input, Select } from 'components/common';
import React from 'react';
import { Flex, Grid } from '@chakra-ui/react';
import { TDictionaryItemDictionary } from 'models';
import { useGoBackWithFallback } from 'lib/utils/goBackWithFallback';
import { permissibleNoiseLevelPath } from '../../consts/consts';

interface IProps {
  territoryTypes: TDictionaryItemDictionary[];
  submitForm: (values: FormikValues) => void;
}

export const PermissibleNoiseLevelFormFields = ({ territoryTypes, submitForm }: IProps) => {
  const { errors }: FormikContextType<FormikErrors<FormikValues>> = useFormikContext();
  const { backWithFallback } = useGoBackWithFallback();
  return (
    <Grid marginTop={30} marginLeft={30} gap={2} templateColumns="repeat(1, 0.5fr)">
      <Field as={Input} name="territoryName" label="Наименование" error={errors?.territoryName} isRequired />
      <Field
        as={Select}
        name="territoryType"
        label="Тип территории"
        options={territoryTypes}
        error={errors?.territoryType}
        isRequired
      />
      <Field
        as={Input}
        name="documentName"
        label="Наименование нормативного документа"
        error={errors?.documentName}
        isRequired
      />
      <Field as={Input} name="eqNight" label="Эквивалентные уровни звука, ночь" error={errors?.eqNight} isRequired />
      <Field as={Input} name="eqDay" label="Эквивалентные уровни звука, день" error={errors?.eqDay} isRequired />
      <Field as={Input} name="maxNight" label="Максимальные уровни звука, ночь" error={errors?.maxNight} isRequired />
      <Field as={Input} name="maxDay" label="Максимальные уровни звука, день" error={errors?.maxDay} isRequired />
      <Flex marginRight="auto">
        <Button marginRight={5} onClick={() => backWithFallback(permissibleNoiseLevelPath)}>
          Отмена
        </Button>
        <Button onClick={submitForm}>Сохранить</Button>
      </Flex>
    </Grid>
  );
};
