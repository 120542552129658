import * as React from 'react';
import { Route } from 'react-router-dom';
import Analytics from 'pages/analytics/Analytics';
import { ANALYTICS_LAB_PATHS } from 'pages/analytics/consts/AnalyticsTableRoutes';
import AnalyticsPelNoiseAdd from 'pages/analytics/Raids/PelNoise/AnalyticsPelNoiseAdd';
import AnalyticsPelNoiseEdit from 'pages/analytics/Raids/PelNoise/AnalyticsPelNoiseEdit';
import AnalyticsPelAtmosphereAdd from 'pages/analytics/Raids/Pel/PelAtmoshphere/AnalyticsPelAtmosphereAdd';
import AnalyticsPelAtmosphereEdit from 'pages/analytics/Raids/Pel/PelAtmoshphere/AnalyticsPelAtmosphereEdit';
import AnalyticsAiAtmosphereAdd from 'pages/analytics/Raids/Pel/PelAiAtmoshphere/AnalyticsAiAtmosphereAdd';
import AnalyticsAiAtmosphereEdit from 'pages/analytics/Raids/Pel/PelAiAtmoshphere/AnalyticsAiAtmosphereEdit';
import { AnalyticsSurfaceWaterAdd } from 'pages/analytics/forms/SurfaceWater/AnalyticsSurfaceWaterAdd';
import { AnalyticsSurfaceWaterEdit } from 'pages/analytics/forms/SurfaceWater/AnalyticsSurfaceWaterEdit';
import { AnalyticsBottomSoilAdd } from 'pages/analytics/forms/BottomSoil/AnalyticsBottomSoilAdd';
import { AnalyticsBottomSoilEdit } from 'pages/analytics/forms/BottomSoil/AnalyticsBottomSoilEdit';
import { AnalyticsAddDeicingWashoutReport } from 'pages/analytics/forms/Washout/AnalyticsAddDeicingWashoutReport';
import { AnalyticsEditDeicingWashoutReport } from 'pages/analytics/forms/Washout/AnalyticsEditDeicingWashoutReport';
import { AnalyticsEditDeicingVegReport } from 'pages/analytics/forms/VegetativeState/AnalyticsEditDeicingVegReport';
import { AnalyticsAddDeicingVegReport } from 'pages/analytics/forms/VegetativeState/AnalyticsAddDeicingVegReport';
import { AnalyticsAddDeicingSnowReport } from 'pages/analytics/forms/Snow/AnalyticsAddDeicingSnowReport';
import { AnalyticsEditDeicingSnowReport } from 'pages/analytics/forms/Snow/AnalyticsEditDeicingSnowReport';
import { AnalyticsAddSoilDeicingReport } from 'pages/analytics/forms/Soil/AnalyticsAddSoilDeicingReport';
import { AnalyticsEditDeicingSoilReport } from 'pages/analytics/forms/Soil/AnalyticsEditDeicingSoilReport';
import { analyticToReportBreadcrumb, analyticToReportPath } from '../analytics/consts/consts';
import { TaskPreparing } from '../citizenAppeal/CitizensAppealsRecord/components';

export const AnalyticsLabRoutes: React.FC = () => (
  <>
    <Route path="/analytics" exact component={Analytics} />
    <Route
      path={analyticToReportPath}
      exact
      render={() => <TaskPreparing breadcrumbs={analyticToReportBreadcrumb} />}
    />

    {/* WASHOUT */}
    <Route path={ANALYTICS_LAB_PATHS.reagents.washout.add} exact render={() => <AnalyticsAddDeicingWashoutReport />} />
    <Route
      path={`${ANALYTICS_LAB_PATHS.reagents.washout.edit}/:id`}
      exact
      render={() => <AnalyticsEditDeicingWashoutReport />}
    />

    {/* VEGETATION */}
    <Route path={ANALYTICS_LAB_PATHS.reagents.vegetation.add} exact render={() => <AnalyticsAddDeicingVegReport />} />
    <Route
      path={`${ANALYTICS_LAB_PATHS.reagents.vegetation.edit}/:id`}
      exact
      render={() => <AnalyticsEditDeicingVegReport />}
    />

    {/*  SNOW */}
    <Route path={ANALYTICS_LAB_PATHS.reagents.snow.add} exact render={() => <AnalyticsAddDeicingSnowReport />} />
    <Route
      path={`${ANALYTICS_LAB_PATHS.reagents.snow.edit}/:id`}
      exact
      render={() => <AnalyticsEditDeicingSnowReport />}
    />

    {/*  SOIL */}
    <Route path={ANALYTICS_LAB_PATHS.reagents.soil.add} exact render={() => <AnalyticsAddSoilDeicingReport />} />
    <Route
      path={`${ANALYTICS_LAB_PATHS.reagents.soil.edit}/:id`}
      exact
      render={() => <AnalyticsEditDeicingSoilReport />}
    />

    {/* BOTTOM SOIL */}
    <Route path={ANALYTICS_LAB_PATHS.water.bottomSoil.add} exact render={() => <AnalyticsBottomSoilAdd />} />
    <Route path={`${ANALYTICS_LAB_PATHS.water.bottomSoil.edit}/:id`} exact render={() => <AnalyticsBottomSoilEdit />} />

    {/* SURFACE_WATERS */}
    <Route path={ANALYTICS_LAB_PATHS.water.surfaceWaters.add} exact render={() => <AnalyticsSurfaceWaterAdd />} />
    <Route
      path={`${ANALYTICS_LAB_PATHS.water.surfaceWaters.edit}/:id`}
      exact
      render={() => <AnalyticsSurfaceWaterEdit />}
    />

    {/* PEL */}
    <Route path={ANALYTICS_LAB_PATHS.raids.pel.add} exact component={AnalyticsPelAtmosphereAdd} />
    <Route path={`${ANALYTICS_LAB_PATHS.raids.pel.edit}/:id`} exact component={AnalyticsPelAtmosphereEdit} />

    <Route path={ANALYTICS_LAB_PATHS.raids.ai.add} exact component={AnalyticsAiAtmosphereAdd} />
    <Route path={`${ANALYTICS_LAB_PATHS.raids.ai.edit}/:id`} exact component={AnalyticsAiAtmosphereEdit} />

    <Route path={ANALYTICS_LAB_PATHS.raids.pelNoise.add} exact component={AnalyticsPelNoiseAdd} />
    <Route path={`${ANALYTICS_LAB_PATHS.raids.pelNoise.edit}/:id`} exact component={AnalyticsPelNoiseEdit} />
  </>
);
