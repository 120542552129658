import { subsystemCodesMap } from 'constants/subsystemIdsMap';
import { ReportSmall } from 'features/WithSideBar/icons';
import { IncidentRecordingPaths } from 'pages/IncidentRecording/const';
import appLocale from 'constants/appLocale';

export const incidentRecordingMenu = {
  id: IncidentRecordingPaths.root,
  name: appLocale.subsystems.incidentRecording,
  url: IncidentRecordingPaths.root,
  otherCode: subsystemCodesMap.incidentsRecording,
  icon: <ReportSmall />, // TODO: change
};
