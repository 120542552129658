export type NSIReagentsDictionaryKeys =
  | 'types'
  | 'statuses'
  | 'rayons'
  | 'okrugs'
  | 'probes'
  | 'offsets'
  | 'areaTypes'
  | 'areaLocations'
  | 'lifeBreeds'
  | 'lifeForms'
  | 'pgrAreaTypes'
  | 'areas'
  | 'pgrSourceType'
  | 'areasForTrees'
  | 'areasForLawn';

export enum NSIReagentsListType {
  'area' = 'area',
  'lawn' = 'lawn',
  'pgr' = 'pgr',
  'tree' = 'tree',
}

type NSIReagentsStatus = 'ACTIVE' | 'DISABLED';

export interface NSIReagentsListItem {
  id: string;
  // Идентификатор источника данных

  type: NSIReagentsListType;
  // Что за тип источника для противогололедных реагентов

  name: string;
  // Наименование

  shortName: string;
  // Краткое наименование

  number: number | string;
  // Номер

  okrugId: string | null;
  // Идентификатор округа

  rayonId: string | null;
  // Идентификатор района

  latitude: number | string;
  // Широта

  longitude: number | string;
  // Долгота

  address: string;
  // Адрес

  landmark: string;
  // Адресный ориентир

  status: NSIReagentsStatus | null;
  // Статус

  greenStartDate: string | null;
  // Дата высадки

  startDate: string | null;
  // Дата начала действия

  stopDate: string | null;
  // Дата завершения действия

  description: string;
  // Примечание

  areaSquare: number;
  // Площадь

  deleted: boolean;
  // Признак того, что запись была удалена

  parentAreaId: string | null;
  // Номер площадки

  greenAreaTypeId: string | null;
  // Тип территории насаждений

  pgrAreaTypeId: string | null;
  // Тип территории

  greenAreaLocationId: string | null;
  // Месторасположение площадки

  greenPlantLifeBreedId: string;
  // Порода

  greenPlantLifeFormId: string;
  // Жизненная форма

  pgrSourceTypeId: string | null;
  // Жизненная форма

  probeAreaOffsets: string[] | null;
  // Перечисление отступов

  probeDepths: string[] | null;
  // Перечисление глубин

  areaNumber: number | string | null;
  // Номер участка

  subsystemId: string | null;
}
