import { Box, Flex, Grid, GridItem, Heading, Stack } from '@chakra-ui/react';
import { Button, Checkbox, Input, Select, Textarea } from 'components/common';
import { Field, FieldArrayRenderProps, FormikHandlers, FormikValues } from 'formik';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { TDictionaryItemDictionary } from 'models';
import { DisplayUserItem } from 'pages/admin/models/UserItem';
import { usePaginatedLoader } from 'pages/admin/typedLoader';
import { baseAdminUserUrl } from 'pages/admin/User/consts';
import { AddIcon, DeleteIcon, EditIcon } from 'components/icons';
import { IUploadMedia } from 'components/common/FileUploadDragAndDrop/types';
import { TaskAttachments } from 'pages/citizenAppeal/CitizensAppealsRecord/components/TaskAttachments';
import { UploadObjectType } from 'api/services/media';
import { useDisclosure } from 'hooks/useDisclosure';
import { useRequest } from 'hooks';

interface ObservationPlaceProps {
  name: string;
  values: FormikValues;
  onRemovePoint: ReturnType<FieldArrayRenderProps['handleRemove']>;
  handleChange: FormikHandlers['handleChange'];
  garbagePileTypeId?: TDictionaryItemDictionary[];
  type: string;
  burningSmellTypeId?: TDictionaryItemDictionary[];
  media?: IUploadMedia[];
  onMediaSave: (media: IUploadMedia[], idx: number) => void;
  index: number;
  uid: string;
  browserId: string;
  errors: any;
}

const GarbageJournalObservationPlace: React.FunctionComponent<ObservationPlaceProps> = ({
  name,
  values,
  onRemovePoint,
  handleChange,
  garbagePileTypeId,
  type,
  burningSmellTypeId,
  media = [],
  onMediaSave,
  index,
  uid,
  browserId,
  errors,
}) => {
  const [currentMedia, setCurrentMedia] = useState<IUploadMedia[]>(media);
  const isGarbage = type === 'garbage';
  const { get: getUserList, result } = usePaginatedLoader<DisplayUserItem>(baseAdminUserUrl);

  const { isOpen, onClose, onOpen } = useDisclosure();

  useEffect(() => {
    getUserList();
  }, [getUserList]);

  const executors = useMemo(
    () =>
      result?.data.map((user) => ({
        name: user.fullName,
        value: user.id,
      })) || [],
    [result]
  );

  const { post } = useRequest('/mapsclient/v10/sendCommand');

  const startEditing = useCallback(() => {
    post({
      browserId,
      command: 'startEditing',
      data: {
        uid,
        layerTemplateId: '1121430612',
      },
    });
  }, [browserId, post, uid]);

  const addFeature = useCallback(
    (id, name) => {
      post({
        browserId,
        command: 'addFeature',
        data: {
          uid,
          layerTemplateId: '1121430612',
          geometryType: 'Point',
          feature: {
            type: 'Feature',
            properties: {
              id,
              name,
            },
          },
        },
      });
    },
    [browserId, post, uid]
  );

  return (
    <Box bg="GhostWhite" padding="1rem">
      <Flex alignItems="center">
        <Heading as="h3" size="md">
          Место наблюдения
        </Heading>
        <Button
          leftIcon={<DeleteIcon />}
          variant="ghost"
          color="PrimaryButton.500"
          size="sm"
          p={0}
          onClick={onRemovePoint}
          marginLeft={5}
        />
        <Button
          leftIcon={<EditIcon />}
          variant="ghost"
          color="PrimaryButton.500"
          size="sm"
          p={0}
          marginLeft="auto"
          onClick={() => {
            window.scrollTo(0, 0);
            startEditing();
          }}
          isDisabled={!(values.pointLatitude || values.pointLongitude)}
        >
          Редактировать координаты на карте
        </Button>
        <Button
          leftIcon={<AddIcon />}
          variant="ghost"
          color="PrimaryButton.500"
          size="sm"
          p={0}
          marginLeft={5}
          onClick={() => {
            window.scrollTo(0, 0);
            addFeature(values.id || values.temporaryId, values.pointNumber);
          }}
          isDisabled={!!values.pointLatitude || !!values.pointLongitude}
        >
          Добавить координаты на карте
        </Button>
      </Flex>
      <Grid templateColumns="repeat(6, 1fr)" gap={10} marginTop={5}>
        <GridItem colSpan={2}>
          <Field
            as={Input}
            name={`${name}.pointNumber`}
            label="№ точки"
            value={values.pointNumber}
            onChange={handleChange}
          />
        </GridItem>
        <GridItem>
          <Field
            as={Checkbox}
            label="Повтор"
            name={`${name}.isRepeat`}
            checked={values.isRepeat}
            onChange={handleChange}
          />
        </GridItem>
      </Grid>
      <Grid templateColumns="repeat(4, 1fr)" gap={10} marginTop={5}>
        <GridItem>
          <Field
            as={Select}
            name={`${name}.typeId`}
            label={isGarbage ? 'Тип навала' : 'Тип гарей'}
            options={isGarbage ? garbagePileTypeId : burningSmellTypeId}
            onChange={handleChange}
          />
        </GridItem>
        <GridItem>
          <Field
            as={Input}
            name={`${name}.pointLatitude`}
            label="Широта"
            value={values.pointLatitude}
            onChange={handleChange}
            error={errors?.points && errors.points[index]?.pointLatitude}
          />
        </GridItem>
        <GridItem>
          <Field
            as={Input}
            name={`${name}.pointLongitude`}
            label="Долгота"
            value={values.pointLongitude}
            onChange={handleChange}
            error={errors?.points && errors.points[index]?.pointLongitude}
          />
        </GridItem>
        <GridItem>
          <Field
            as={Select}
            options={executors}
            name={`${name}.protocolExecutorUserId`}
            label="Исполнитель"
            onChange={handleChange}
          />
        </GridItem>
      </Grid>

      <Stack direction="column" spacing={5} marginY={5}>
        <Field as={Input} name={`${name}.pointAddress`} label="Адресный ориентир" onChange={handleChange} />
        <Field as={Input} name={`${name}.industrialZoneName`} label="Наименование промзоны" onChange={handleChange} />
        <Field
          as={Textarea}
          label="Комментарий"
          name={`${name}.report`}
          value={values.report}
          onChange={handleChange}
        />
      </Stack>
      <Button
        variant="ghost"
        color="PrimaryButton.500"
        size="sm"
        p={0}
        marginLeft={3}
        marginRight="auto"
        marginTop={5}
        onClick={() => onOpen()}
      >
        Вложения
      </Button>
      <TaskAttachments
        isOpen={isOpen}
        onClose={onClose}
        objectType={UploadObjectType.JOURNAL_OBSERVATION_POINT}
        uploadItemId={values.id && values.id}
        media={currentMedia}
        setMedia={setCurrentMedia}
        handleSubmit={() => {
          onMediaSave(currentMedia, index);
        }}
      />
    </Box>
  );
};
export default GarbageJournalObservationPlace;
