import { NSIRootPath } from '../../const/routes';

export const UnitRootPath = `${NSIRootPath}/measurment/units`;
export const UnitAddRootPath = `${UnitRootPath}/add`;
export const UnitEditRootPath = `${UnitRootPath}/edit/:id`;

export const unitApiRootPath = '/nsi/v10/parameters';
export const unitApiListPath = `${unitApiRootPath}/list/units`;
export const createUnitApiPath = `${unitApiRootPath}/create/unit`;
export const editUnitApiPath = `${unitApiRootPath}/update/unit`;
export const getUnitApiPath = (id?: string) => `${unitApiRootPath}/get/unit/${id}`;
export const unitDictsApiPath = `${unitApiRootPath}/dictionaries`;
