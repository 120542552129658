import { SubsystemHeader } from 'components/common';
import React, { memo } from 'react';
import { NavTabList } from 'features/NavTabList';

import { useHistory } from 'react-router-dom';
import { MASKSHMeteo, MASKSHMeteoPackages, MASKSHNoise, MASKSHNoisePackages, MASKSHTechnical, MASKSHTechnicalPackages } from 'pages/data/MASKSH/consts/menu';
import { tableLocale } from 'pages/data/MASKSH/consts';
import { useQuery } from 'hooks/useQuery';
import { getFilteredQuery } from '../../hooks';

interface Props {
  addPath?: string;
  onOpenUpload?: () => void;
  defaultFiltersOpen?: boolean;
  isWrite: boolean;
}

export const DataHeader: React.FC<Props> = memo(({ addPath, onOpenUpload, children, defaultFiltersOpen, isWrite }) => {
  const history = useHistory();
  const query = useQuery();

  const addButtonProps = addPath
    ? {
      onClick: () => addPath && history.push(addPath),
      isDisabled: !isWrite,
    }
    : undefined;

  return (
    <SubsystemHeader
      onUploadClick={isWrite ? onOpenUpload : undefined}
      title={tableLocale.title}
      addButtonProps={addButtonProps}
      filtersComponent={children}
      defaultFiltersOpen={defaultFiltersOpen}
      navigationTabs={
        <NavTabList
          tabs={[
            {
              label: 'Звуковые параметры',
              path: MASKSHNoisePackages,
              searchPath: MASKSHNoise,
              query: getFilteredQuery(query),
            },
            {
              label: 'Метеоданные',
              path: MASKSHMeteoPackages,
              searchPath: MASKSHMeteo,
              query: getFilteredQuery(query),
            },
            {
              label: 'Технические данные',
              path: MASKSHTechnicalPackages,
              searchPath: MASKSHTechnical,
              query: getFilteredQuery(query),
            },
          ]}
        // findByStart
        />
      }
    />
  );
},);
