import { MaterialTable } from 'features/material-table/MaterialTableMini';
import React, { useMemo, useState } from 'react';
import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import { useDisclosure, useList, useNotifications, useRequest } from 'hooks';
import { FormikProvider, useFormik } from 'formik';
import { TFiltersArray } from 'models';
import { INMUListItem } from './types';
import appLocale from 'constants/appLocale';
import { ConfirmModal, DefaultText, SubsystemHeader } from 'components/common';
import { Grid, GridItem } from '@chakra-ui/react';
import { Filter } from './Filter';
import { useHistory } from 'react-router-dom';
import { ERROR_MESSAGES, REMOVE_A_FEW_CONFIRMATION, REMOVE_CONFIRMATION, SUCCESS_MESSAGES } from 'constants/messages';
import { useGetRightsBySubsystem } from 'hooks/useGetRightsBySubsystem';
import { FiltersDatepickersSchema } from 'models/schemas/filtersDatepickersSchema/FiltersDatepickersSchema';

const initialValues = {
  minDate: {
    key: 'date',
    operation: 'GTE',
    value: '',
  },
  maxDate: {
    key: 'date',
    operation: 'LTE',
    value: '',
  },
};

export const AdverseMeteorogicalCond = () => {
  useUpdateBreadcrumbs([
    { breadcrumb: appLocale.common.data, path: '/data' },
    {
      breadcrumb: appLocale.subsystems.meteo,
      path: '/data/meteo',
    },
    {
      breadcrumb: 'Журнал НМУ',
      path: '/data/meteo/nmu',
    },
  ]);

  const { userRights } = useGetRightsBySubsystem();

  const analysWrite = useMemo(
    () => !!userRights?.find((item) => item.code === 'SUBSYSTEM__NMU.METEO_ANALYSIS_WRITE'),
    [userRights]
  );
  const forecastWrite = useMemo(
    () => !!userRights?.find((item) => item.code === 'SUBSYSTEM__NMU.METEO_PROGNOSIS_WRITE'),
    [userRights]
  );

  const [removedItem, setRemovedItem] = useState<string[]>(['']);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const deleteAFew = useDisclosure();
  const history = useHistory();
  const { successNotify, errorNotify } = useNotifications();
  const [selectedIdx, setSelectedIdx] = useState<string[] | []>([]);

  const onSuccess = () => {
    onClose();
    deleteAFew.onClose();
    refetch();
    total.refetch();
    successNotify({
      key: 'file/delete/success',
      message: SUCCESS_MESSAGES.DELETE,
    });
  };
  const onError = () => {
    onClose();
    deleteAFew.onClose();
    errorNotify({
      key: 'file/delete/error',
      message: removedItem ? ERROR_MESSAGES.DELETE : ERROR_MESSAGES.DELETE_A_FEW,
    });
  };

  const { remove, removeArray } = useRequest('/meteo/v10/meteo/nmu', onSuccess, onError);
  const { response, toggleOrder, onPageChange, filterDTO, setFilters, refetch, downloadAsFile, errorText } =
    useList<INMUListItem>('/meteo/v10/meteo/nmu/list', { initialOrderFieldKey: '-date' });
  const total = useList('/meteo/v10/meteo/nmu/total', { initialOrderFieldKey: '-date' });

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    validationSchema: FiltersDatepickersSchema([{ dateFromFieldName: 'minDate', dateToFieldName: 'maxDate' }]),
    initialValues,
    onSubmit: (values: any) => {
      const filters: TFiltersArray = Object.values(values);
      setFilters([...filters, { key: 'deleted', operation: 'EQ', value: false }]);
      total.setFilters([...filters, { key: 'deleted', operation: 'EQ', value: false }]);
    },
  });

  const rightsChecker = (date: string, type: string, cb: () => void) => {
    if (
      (new Date(date).getTime() > new Date().getTime() && !forecastWrite) ||
      (new Date(date).getTime() < new Date().getTime() && !analysWrite)
    ) {
      errorNotify({
        key: `nmu/${type}/error`,
        message: `У вас нет прав на ${type === 'edit' ? 'изменение' : 'удаление'} данной записи`,
      });
      return;
    }
    return cb();
  };

  return (
    <FormikProvider
      value={{
        ...formik,
      }}
    >
      <SubsystemHeader
        title="Журнал НМУ"
        filtersComponent={<Filter />}
        addButtonProps={{
          onClick: () => history.push('/data/meteo/nmu/add'),
          isDisabled: !analysWrite && !forecastWrite
        }}
      />
      <Grid gap={5} padding={5}>
        <GridItem>
          <DefaultText fontWeight="bold" marginBottom={2}>
            Итого
          </DefaultText>
          <MaterialTable
            data={total.response?.data}
            meta={total.response?.meta}
            toggleOrder={total.toggleOrder}
            onPageChange={total.onPageChange}
            filterDTO={total.filterDTO}
            downloadAsFile={total.downloadAsFile}
            columns={[
              {
                title: 'Год/месяц',
                field: 'monthYear',
              },
              {
                title: 'Количество дней с НМУ',
                field: 'count',
              },
            ]}
            options={{
              selection: false,
              pageSizeOptions: [5, 10, 12],
              maxBodyHeight: '300px',
              emptyRowsWhenPaging: false,
            }}
          />
        </GridItem>
        <GridItem>
          <DefaultText fontWeight="bold" marginBottom={2}>
            Данные НМУ
          </DefaultText>
          <MaterialTable
            data={response?.data}
            meta={response?.meta}
            toggleOrder={toggleOrder}
            onPageChange={onPageChange}
            filterDTO={filterDTO}
            downloadAsFile={downloadAsFile}
            options={{
              pageSizeOptions: [5, 10, 15, 31],
            }}
            removeChecked={() => {
              deleteAFew.onOpen();
              setRemovedItem(selectedIdx);
            }}
            columns={[
              {
                title: 'Дата',
                field: 'date',
              },
              {
                title: 'Сведения о НМУ',
                field: 'gradeName',
              },
            ]}
            actions={[
              {
                type: 'edit',
                onClick: (_e, item) => {
                  rightsChecker(item.date, 'edit', () => history.push(`/data/meteo/nmu/add/${item.id}`));
                },
              },
              {
                type: 'delete',
                onClick: (_e, item) => {
                  rightsChecker(item.date, 'delete', () => {
                    onOpen();
                    setRemovedItem([item.id]);
                  });
                },
              },
            ]}
            onSelectionChange={(data) => setSelectedIdx(data.map((item) => item.id))}
            errorText={errorText}
          />

          <ConfirmModal
            isOpen={isOpen}
            onClose={onClose}
            onClick={() =>
              remove({
                url: `/meteo/v10/meteo/nmu/${removedItem}`,
              })
            }
            header={REMOVE_CONFIRMATION}
          />
          <ConfirmModal
            isOpen={deleteAFew.isOpen}
            onClose={deleteAFew.onClose}
            onClick={() => (removeArray ? removeArray(selectedIdx, { url: '/meteo/v10/meteo/nmu/' }) : {})}
            header={REMOVE_A_FEW_CONFIRMATION}
          />
        </GridItem>
      </Grid>
    </FormikProvider>
  );
};
