import moment from 'moment';

export function isExpired(datestring: string) {
  return moment().isAfter(moment(datestring).endOf('day'));
}

export type ListOrder = 'ascending' | 'descending';
export function createOrderKey<T>(value: keyof T, order: ListOrder) {
  if (order === 'descending') {
    return `-${String(value)}`;
  }

  return value;
}
