import { useDisclosure } from '@chakra-ui/react';
import React, { useCallback, useMemo, useState } from 'react';
import { ProtocolModal } from 'components/common/Modals/ProtocolModal';
import { Column } from '@material-table/core';

interface IUseProtocolModal<T> {
  onClose?: () => void;
  dateKey?: keyof T;
  additionalData?: { key: keyof T; label: string }[];
  baseUrl?: string;
  previewColumns?: Column<any>[];
  secondBaseUrl?: string;
}

function useProtocolModal<T extends { valueVersion?: number; valueId: string }>({
  onClose,
  dateKey,
  additionalData,
  baseUrl,
  previewColumns,
  secondBaseUrl,
}: IUseProtocolModal<T>) {
  const [row, setRow] = useState<T>();
  const disclosure = useDisclosure();

  const modalOnClose = useCallback(() => {
    if (onClose) {
      onClose();
    }

    disclosure.onClose();
    setRow(undefined);
  }, [disclosure, onClose]);

  const protocolModal = useMemo(() => {
    if (!row) return null;

    return (
      <ProtocolModal
        date={dateKey ? row[dateKey] : undefined}
        additionalData={additionalData?.map((data) => ({ label: data.label, value: row[data.key] }))}
        id={row.valueId}
        isOpen={disclosure.isOpen}
        onClose={modalOnClose}
        baseUrl={baseUrl}
        previewColumns={previewColumns}
        secondBaseUrl={secondBaseUrl}
      />
    );
  }, [row, dateKey, additionalData, disclosure.isOpen, modalOnClose, baseUrl, previewColumns, secondBaseUrl]);

  const openModal = useCallback<(row: T) => void>(
    (row) => {
      setRow(row);
      disclosure.onOpen();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []);

  return {
    openModal,
    protocolModal,
  };
}

export default useProtocolModal;
