import { Grid, GridItem } from '@chakra-ui/react';
import { Field } from 'formik';
import { Input } from 'components/common';
import React from 'react';
// import { TDictionaryItemDictionary } from '../../../../../../models';

// interface IProps {

// }

export const OtherInformation = () => (
  <>
    <Grid gap={2} templateRows="repeat(6, 1fr)" templateColumns="repeat(6, 2fr)">
      <GridItem rowStart={1} rowEnd={1} colStart={1} colSpan={3}>
        <Field as={Input} name="landmark" label="Примечание" />
      </GridItem>
      <GridItem rowStart={2} rowEnd={2} colStart={1} colSpan={3}>
        <Field as={Input} name="address" label="Площадь" />
      </GridItem>
    </Grid>
  </>
);
