import React from 'react';

import { createInitialValuesToFormik, createValuesToRequest } from 'features/Filter/utils';
import { Filter } from 'features/Filter';
import { TProtocolForecastTFilterDTO } from 'pages/data/Meteo/ProtocolForecast/types';
import appLocale from 'constants/appLocale';
import { SchemaForCustomFilter } from 'models/schemas/filtersDatepickersSchema/FiltersDatepickersSchema';

type FilterInitialValues = Record<
  'dateFrom' | 'dateTo' | 'deleted' | 'showOnlyChangedRows',
  TProtocolForecastTFilterDTO['filters'][number]
>;

const initialValues: FilterInitialValues = {
  dateFrom: {
    key: 'valueDate',
    operation: 'GTE',
    value: null,
  },
  dateTo: {
    key: 'valueDate',
    operation: 'LTE',
    value: null,
  },
  deleted: {
    key: 'deleted',
    operation: 'EQ',
    value: false
  },
  showOnlyChangedRows: {
    key: 'showOnlyChangedRows',
    operation: 'EQ',
    value: false,
  },
};

interface Props {
  isLoading: boolean;
  onSubmit: (values: FilterInitialValues) => void;
}

const initialValuesToFormik = createInitialValuesToFormik(initialValues);

function ProtocolForecastFilter({
  isLoading,
  onSubmit,
  children
}: React.PropsWithChildren<Props>) {
  return (
    <Filter<typeof initialValuesToFormik>
      isLoading={isLoading}
      isDisabled={isLoading}
      onSubmit={(values) => {
        onSubmit(createValuesToRequest(initialValues, values));
      }}
      initialValues={initialValuesToFormik}
      mainFields={[
        {
          type: 'DATE',
          name: 'dateFrom',
          label: appLocale.common.from,
          showTimeInput: false,
        },
        {
          type: 'DATE',
          name: 'dateTo',
          label: appLocale.common.to,
          showTimeInput: false,
        },
        {
          type: 'CHECKBOX',
          name: 'showOnlyChangedRows',
          label: appLocale.common.showOnlyChangedRows,
        },
        {
          type: 'CHECKBOX',
          name: 'deleted',
          label: appLocale.common.showDeleted,
        },
      ]}
      validationSchema={() =>
        SchemaForCustomFilter([{ dateFromFieldName: 'dateFrom', dateToFieldName: 'dateTo' }])
      }
    >
      {children}
    </Filter>
  );
}

export default ProtocolForecastFilter;
