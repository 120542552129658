import React, { createContext, FC, useCallback, useContext, useMemo, useState } from 'react';
import { FilePreview } from 'models';
import { FormikProvider, useFormik } from 'formik';
import { filter, get } from 'lodash';
import { useHistory } from 'react-router-dom';
import appLocale from 'constants/appLocale';
import { UploadFileSettingsProvider, useUploadFileSettingsProvider } from './UploadFileSettings';
import { useFilePreviewApply } from './useFilePreviewApply';
import { IUseFilePreviewReturnType, useFilePreview } from './useFilePreview';
import { useUploadFileLoadTypes } from './useUploadFileLoadTypes';
import { useFilePreviewValidate, UseFilePreviewValidateReturnType } from './useFilePreviewValidate';
import { InputFileMetadata } from './types';
import { useUploadFileNotify } from './useUploadFileNotify';

interface UploadFileCtx<Measure = Record<string, unknown>> {
  inputFileMetadata: InputFileMetadata;
  setInputFileMetadata: React.Dispatch<React.SetStateAction<InputFileMetadata | undefined>>;
  setMeasuresFilter: React.Dispatch<React.SetStateAction<TMeasuresFilter | null>>;
  types: ReturnType<typeof useUploadFileLoadTypes>;
  preview: IUseFilePreviewReturnType<Measure>;
  validate: UseFilePreviewValidateReturnType<Measure>;
  apply: ReturnType<typeof useFilePreviewApply> & {
    handleApplyFilePreview(refetch?: () => void): void;
  };

  reset(...rest: Partial<Array<'inputFileMetadata' | 'preview' | 'uploadFileSettings'>>): void;
}

type TMeasuresFilter = 'all' | 'errors' | 'valid';

const uploadFileCtx = createContext<Partial<UploadFileCtx<any>>>({});

function useUploadFileProvider<Measure = Record<string, unknown>>() {
  return useContext<Partial<UploadFileCtx<Measure>>>(uploadFileCtx);
}

const useClearUploadFile = () => {
  const { reset } = useUploadFileProvider();
  return useCallback(
    (...rest: Partial<Array<'inputFileMetadata' | 'preview' | 'uploadFileSettings'>>) => {
      if (reset) {
        return reset(...rest);
      }
    },
    [reset],
  );
};

const defaultFilePreview: FilePreview = {
  fileId: '',
  measures: [],
  date: null,
  countErrors: 0,
  instructionId: 1,
  hasErrors: false,
  errors: [],
  fileName: '',
  fileProcessingStart: '',
  fileSize: null,
  fileType: '',
  errorCount: 0,

  // TODO: temp - needs backend change to measures all fields below
  watersourceMonitoringFileDtoList: [],
  reperFileDtoList: [],
};

const useUploadFileInputFileMetadata = () => {
  const { inputFileMetadata, setInputFileMetadata } = useUploadFileProvider();
  return { inputFileMetadata, setInputFileMetadata };
};

const UploadFileProvider: FC = ({ children, ...props }) => {
  const history = useHistory();

  const { uploadFileSettings, setUploadFileSettings } = useUploadFileSettingsProvider();

  const types = useUploadFileLoadTypes(uploadFileSettings?.typesPath);

  const notify = useUploadFileNotify();

  const {
    getFilePreview,
    result: previewResult,
    ...preview
  } = useFilePreview(uploadFileSettings?.previewPath, (data) => {
    const dataWithErrors = data?.measures ? data?.measures.filter((item) => item.hasErrors) : [];
    const isAnyError = dataWithErrors.length > 0;
    notify('preview')({
      errors: data?.errors,
      hasErrors: data?.hasErrors || isAnyError,
    });
  });

  const validate = useFilePreviewValidate(uploadFileSettings?.validatePath, (data) =>
    notify(
      'validate',
      appLocale.fileUploadModal.file_validate_success,
      appLocale.fileUploadModal.file_validate_error,
    )({ errors: data?.errors, hasErrors: data?.hasErrors }));

  const apply = useFilePreviewApply(uploadFileSettings?.applyPath, (data) =>
    notify('apply', appLocale.fileUploadModal.file_apply_success)({ errors: data?.errors }));

  const [inputFileMetadata, setInputFileMetadata] = useState<InputFileMetadata | undefined>(undefined);

  const [measuresFilter, setMeasuresFilter] = useState<TMeasuresFilter | null>(null);

  const formik = useFormik<FilePreview>({
    initialValues: previewResult ?? defaultFilePreview,
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    onSubmit: () => {},
  });

  const handleApplyFilePreview = useCallback(async (refetch?: () => void) => {
    if (previewResult) {
      try {
        const result = await validate.filePreviewValidate(previewResult);

        if (get(result, 'hasErrors')) {
          return;
        }
      } catch (e) {
        console.error(e);
      }

      try {
        await apply.applyFilePreview({ ...previewResult, instructionId: 1 });

        if (refetch) {
          refetch();
        }

        if (uploadFileSettings?.redirectPath) {
          history.push(uploadFileSettings?.redirectPath);
        }
      } catch (e) {
        console.error(e);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apply, previewResult, validate.filePreviewValidate]);

  const filteredPreview = useMemo<FilePreview | null>(() => {
    if (!previewResult) return null;

    if (measuresFilter === 'all') {
      return previewResult;
    }

    if (measuresFilter === 'errors') {
      return {
        ...previewResult,
        measures: filter(previewResult?.measures, (measure) => Boolean(measure.hasErrors)),
      };
    }

    if (measuresFilter === 'valid') {
      return {
        ...previewResult,
        measures: filter(previewResult?.measures, (measure) => Boolean(!measure.hasErrors)),
      };
    }

    return previewResult;
  }, [measuresFilter, previewResult]);

  const reset = useCallback(
    (...rest) => {
      if (setInputFileMetadata && rest.includes('inputFileMetadata')) {
        setInputFileMetadata(undefined);
      }

      if (preview && rest.includes('preview')) {
        preview.reset();
      }

      if (setUploadFileSettings && rest.includes('uploadFileSettings')) {
        setUploadFileSettings(undefined);
      }
    },
    [preview, setInputFileMetadata, setUploadFileSettings],
  );
  return (
    <uploadFileCtx.Provider
      value={{
        inputFileMetadata,
        setInputFileMetadata,
        setMeasuresFilter,
        reset,
        types,
        preview: { getFilePreview, ...preview, result: filteredPreview },
        validate,
        apply: { ...apply, handleApplyFilePreview },
      }}
      {...props}
    >
      <FormikProvider value={formik}>{children}</FormikProvider>
    </uploadFileCtx.Provider>
  );
};

const UploadFileCombineProviders: FC = ({ children }) => (
  <UploadFileSettingsProvider>
    <UploadFileProvider>
      {children}
      {' '}
    </UploadFileProvider>
  </UploadFileSettingsProvider>
);

export type { UploadFileCtx };

export {
  uploadFileCtx,
  useUploadFileProvider,
  useClearUploadFile,
  useUploadFileInputFileMetadata,
  UploadFileProvider,
  UploadFileCombineProviders,
};
