import { TDictionaryItemDictionary } from 'models';

export const stationsToFilters = (values: any[], stations?: TDictionaryItemDictionary[]) => {
  const stationId = values.find((val) => val.key === 'stationId')?.value;
  return values
    .map((item) => {
      if (item.key === 'stationId') {
        if (!item.value) {
          return undefined;
        }

        return {
          ...item,
          value: item.value !== null ? [item.value] : [],
        };
      }

      if (item.key === 'stationName') {
        if (!stationId) {
          return undefined;
        }
        const stationName = stations?.find((station) => station.value === stationId)?.name;
        return {
          ...item,
          value: stationName ? [stationName] : [],
        };
      }
      return item;
    })
    .filter((value) => Boolean(value));
};

export const filtersToStations = (values: any[], stations?: TDictionaryItemDictionary[]) =>
  values
    .map((item) => {
      if (item.key === 'stationId') {
        return {
          ...item,
          value: item.value && Array.isArray(item.value) ? item.value[0] : null,
        };
      }

      if (item.key === 'stationName') {
        const station = values.find((val) => val.key === 'stationId')?.value;
        const stationId = station && Array.isArray(station) ? station[0] : '';
        const stationName = stations?.find((station) => station.value === stationId)?.name;

        return {
          ...item,
          value: stationName ? [stationName] : [],
        };
      }
    })
    .filter((v) => Boolean(v));
