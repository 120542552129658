import React, { useMemo } from 'react';
import { SubsystemHeader } from 'components/common';
import { MaterialTable } from 'features/material-table/MaterialTableMini';
import { useInitializerUploadSettings } from 'features/UploadedFileProvider/UploadFileSettings';
import { useAvailability } from 'hooks';
import { useList } from 'hooks/useList';
import { Column } from '@material-table/core';
import { addFiltersToColumns, ColumnWithFilter } from 'lib/utils/tableFilter';
import { TableFilterTypeEnum } from 'components/table/filters/TableFilter';
import subsystemIdsMap from 'constants/subsystemIdsMap';
import { AtmosphereFilter } from './AtmosphereFilter/AtmosphereFilter';
import { usePeriodsDict } from 'features/PeriodsButtons/usePeriodsDict';
import { AtmosphereMonitoringProtocolItem } from 'pages/protocols/ASKZA/types';
import VersionCell from 'components/table/VersionCell';
import useProtocolModal from 'hooks/useProtocolModal';
import { useDictionary } from 'hooks/useDictionary';
import { restorePreviewColumns } from 'components/common/Modals/ProtocolModal';
import appLocale from 'constants/appLocale';
import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import { DEFAULT_PAGE_SIZE_NEW_TABLE } from '../../../constants/tables';
import { DateTimeTableCell } from '../../data/Water/components/DateTimeTableCell';
import { FullPageWrapper } from '../../../components/table/FullPageWrapper';

export const AtmosphereMonitoringParameters = () => {
  useUpdateBreadcrumbs([
    {
      breadcrumb: appLocale.protocols.title,
      path: '/protocols',
    },
    { breadcrumb: appLocale.subsystems.askza, path: '/protocols/atmosphere' },
  ]);

  const { isExecute, isNoRights } = useAvailability(subsystemIdsMap.atmosphere);

  useInitializerUploadSettings(
    useMemo(
      () => ({
        typesPath: '/atmosphere/v10/atmosphere/load/types',
        previewPath: '/atmosphere/v10/atmosphere/load/preview',
        validatePath: '/atmosphere/v10/atmosphere/load/validate',
        applyPath: '/atmosphere/v10/atmosphere/load/apply',
        redirectPath: '/data/atmosphere/monitoring',
      }),
      []
    )
  );

  const { dictionaries } = useDictionary({ url: '/atmosphere/v10/atmosphere/dictionaries' });

  const {
    refetch,
    isLoading: isLoadingAtmosphereMonitoringList,
    response,
    toggleOrder,
    setFilters,
    onPageChange,
    filterDTO,
    downloadAsFile,
    saveFilters,
    onSearchText,
    errorText,
    selectedIndexes,
    renderOnlySelectedCheckbox,
    onSubmitFilters,
    onSelectionChange,
    materialTableProps,
  } = useList<AtmosphereMonitoringProtocolItem, keyof AtmosphereMonitoringProtocolItem>(
    '/atmosphere/v10/atmosphere/list/values',
    {
      initialOrderFieldKey: '-valueDate',
      exportSubsystemName: 'askza',
      preventFirstFetch: true,
      selectionKey: 'valueId',
      defaultPageSize: DEFAULT_PAGE_SIZE_NEW_TABLE,
      resetDataOnNextRequest: true,
    }
  );

  const { openModal, protocolModal } = useProtocolModal<AtmosphereMonitoringProtocolItem>({
    onClose: refetch,
    dateKey: 'valueDate',
    baseUrl: '/atmosphere/v10/atmosphere',
    previewColumns: restorePreviewColumns.askza,
    secondBaseUrl: '/atmosphere/v10/atmosphere'
  });

  const columns = useMemo<Array<ColumnWithFilter<AtmosphereMonitoringProtocolItem>>>(
    () => [
      {
        title: 'Версия',
        field: 'version',
        render: (row) => (
          <>
            <VersionCell row={row} onClick={openModal} />
          </>
        ),
        width: 50,
        filterType: TableFilterTypeEnum.number,
      },
      {
        title: 'Дата и время',
        field: 'valueDate',
        render: (row) => <DateTimeTableCell inline datestring={row.valueDate} />,
      },
      {
        title: 'Станция',
        field: 'stationName',
        filterType: TableFilterTypeEnum.string,
      },
      {
        title: 'Ед. измерения',
        field: 'unitName',
        filterType: TableFilterTypeEnum.string,
      },
      {
        title: 'Параметр',
        field: 'parameterName',
        filterType: TableFilterTypeEnum.string,
      },
      {
        title: 'Тип',
        field: 'valueType',
        render: (row) => {
          switch (row.valueType) {
            case 'AVG':
              return 'Среднее';
            case 'MIN':
              return 'Минимальное';
            case 'MAX':
              return 'Максимальное';
            default:
              return row.valueType;
          }
        },
      },
      {
        title: 'Значение',
        field: 'value',
        filterType: TableFilterTypeEnum.number,
      },
    ],
    [openModal]
  );

  const columnsWithFilters = useMemo<Array<Column<AtmosphereMonitoringProtocolItem>>>(
    () => addFiltersToColumns({ filters: filterDTO.filters, setFilters, columns }),
    [columns, filterDTO.filters, setFilters]
  );

  const { result: periods } = usePeriodsDict(`auth/v10/user/period/${subsystemIdsMap.atmosphere}`);

  const isLoading = isLoadingAtmosphereMonitoringList;

  return (
    <FullPageWrapper>
      <SubsystemHeader
        title="Данные мониторинга состояния атмосферного воздуха"
        filtersComponent={
          <AtmosphereFilter
            savedPeriodId={filterDTO?.filters.find((item) => item.key === 'periodId')?.value as string}
            periods={periods}
            dictionaries={dictionaries}
            isLoading={isLoading}
            onSubmit={onSubmitFilters}
            filters={filterDTO.filters}
            filtersFromGraphic={null}
            setFiltersFromGraphic={console.log}
          >
            {renderOnlySelectedCheckbox}
          </AtmosphereFilter>
        }
      />

      <MaterialTable
        isNotExecute={isExecute || isNoRights}
        data={response?.data}
        meta={response?.meta}
        toggleOrder={toggleOrder}
        onPageChange={onPageChange}
        filterDTO={filterDTO}
        columns={columnsWithFilters}
        options={{
          search: true,
        }}
        isLoading={isLoading}
        onSearch={onSearchText}
        downloadAsFile={downloadAsFile}
        selectedIndexes={selectedIndexes}
        onSelectionChange={onSelectionChange}
        processingParamsUrl="/atmosphere/v10/atmosphere/dictionaries/dependent-parameters"
        saveFilters={saveFilters}
        setFilters={setFilters}
        errorText={errorText}
        {...materialTableProps}
      />

      {protocolModal}
    </FullPageWrapper>
  );
};
