import { Flex, Text } from '@chakra-ui/react';

export const ManagerDashboardMap = () => (
  <Flex
    width="100%"
    flexDirection="column"
    boxShadow="0px 5px 10px rgba(0, 0, 0, 0.1)"
    borderRadius="4px"
    border="1px solid #D4D9DC"
  >
    <Flex pl="20px" py="16px" alignItems="center">
      <Text fontWeight="700" fontSize="18px" lineHeight="30px">Картография</Text>
    </Flex>
    <iframe
      title="map"
      width="100%"
      height="577px"
      src={`${process.env.REACT_APP_DASHBOARD_CARTOGRAPHY_MAP_URL}/?roleId=e7efcea4-2eb3-49a9-a4ab-cb2575f15c83`}
    />
  </Flex>
);
