export const fieldsLocale: { [key: string]: string } = {
  territoryType: 'Тип территории',
  territoryNumber: 'Площадка',
  address: 'Адрес',
  distance2Road: 'Расстояние от дороги',
  depth: 'Глубина',
  selectionDate: 'Дата отбора',
  protocolNumber: 'Протокол',
  region: 'Округ',
  district: 'Район',
};

export const soilLocale = {
  soil: 'Почва',
};
