import React from 'react';
import { PageHeader, RoutesList } from 'components/common';
import appLocale from 'constants/appLocale';
import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import { meteoMenu } from 'pages/data/Meteo/menu';
import { Flex, Heading } from '@chakra-ui/react';
import { useRoutesList } from 'hooks/useRoutesList';

export const MeteoMenu: React.FC = () => {
  useUpdateBreadcrumbs([
    { breadcrumb: appLocale.common.data, path: '/data' },
    { breadcrumb: appLocale.subsystems.meteo, path: '/data/meteo' },
  ]);
  const routes = useRoutesList(meteoMenu);
  return (
    <Flex flexDirection="column">
      <PageHeader>
        <Heading>{appLocale.subsystems.meteo}</Heading>
      </PageHeader>
      <RoutesList routes={routes} />
    </Flex>
  );
};
