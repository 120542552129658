import * as yup from 'yup';
import { numberError, requiredError, shouldBeMoreThan } from '../constans';
import { WPZParameterKeys } from 'pages/data/Water/WPZRoot/WPZ/consts/types';

const parametersFields = WPZParameterKeys.reduce(
  (object, key) => ({ ...object, [key]: yup.number().nullable().typeError(numberError).min(0, shouldBeMoreThan(0)) }),
  {}
);

export const WPZSchema = yup.object()
  .shape({
    areaId: yup.string().required(requiredError).typeError(requiredError),
    date: yup.string().typeError(requiredError).required(requiredError),
    waterObjectId: yup.string().typeError(requiredError).required(requiredError),
    ...parametersFields
  });
