import { Field, useFormikContext } from 'formik';
import { Button, DateInput, FormWrapper, Input, Select } from 'components/common';
import { Box, Flex, Grid } from '@chakra-ui/react';
import { createDateFromResponse, createUTCTime } from 'lib/create-date';
import React, { useMemo } from 'react';
import {
  getSelectOptionsFromDictionaryByName,
  getSelectOptionsFromDictionaryByNameWithTransformation
} from 'features/get-select-options-from-dictionary';
import { IPDVNoSourceItem } from '../../types';
import { Industrial_PDV_NoSource_RootPath } from '../../consts';
import { usePDVDictionaries } from 'pages/data/IndustrialEmissions/PDV/usePDVDictionaries';
import { useGoBackWithFallback } from 'lib/utils/goBackWithFallback';

export const AddFormFields = () => {
  const { handleSubmit, errors, setFieldValue, values } = useFormikContext<IPDVNoSourceItem>();
  const { backWithFallback } = useGoBackWithFallback();

  const { result: PDVDictionaries } = usePDVDictionaries();

  const parameters = useMemo(
    () => getSelectOptionsFromDictionaryByName(PDVDictionaries?.data, 'parameters')
      .map((item) => ({
        ...item,
        name: item.title,
        value: item.parameterId,
      })),
    [PDVDictionaries],
  );

  const parametersCode = useMemo(() => (
    parameters
      .filter((item) => Boolean(item.code))
      .map((item) => ({
        ...item,
        name: item.code,
      }))
  ), [parameters]);

  const industries = useMemo(
    () => getSelectOptionsFromDictionaryByNameWithTransformation(PDVDictionaries?.data, 'industries', 'title'),
    [PDVDictionaries]
  );

  return (
    <Box flexDirection="column" mb={4}>
      <FormWrapper>
        <Grid marginTop="30px" gap="15px">
          <Field as={Select} options={industries} name="industryId" label="Предприятие" error={errors.industryId} />
          <Field as={Select} options={parameters} name="parameterId" label="Параметр" error={errors.parameterId} />
          <Field
            as={Select}
            options={parametersCode}
            name="parameterId"
            label="Код параметра"
            isDisabled
          />
          <Field as={Input} name="oneTimeEmission" label="Разовый выброс, г/сек" error={errors.oneTimeEmission} />
          <Field as={Input} name="nmu1" label="НМУ 1" error={errors.nmu1} />
          <Field as={Input} name="nmu2" label="НМУ 2" error={errors.nmu2} />
          <Field as={Input} name="nmu3" label="НМУ 3" error={errors.nmu3} />
          <Field as={Input} name="totalEmission" label="Общий выброс, т/г" error={errors.totalEmission} />
          <Field as={Input} name="error" label="Погрешность, %" error={errors.error} />
          <DateInput
            name="date"
            label="Дата и время начала периода"
            value={createDateFromResponse(values.startDate)}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFieldValue('startDate', createUTCTime(e.target.value));
            }}
            error={errors.startDate}
            showTimeInput
          />
          <DateInput
            name="date"
            label="Дата и время окончания периода"
            value={createDateFromResponse(values.endDate)}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFieldValue('endDate', createUTCTime(e.target.value));
            }}
            error={errors.endDate}
            showTimeInput
          />
          <Flex justifyContent="space-between">
            <Button onClick={() => backWithFallback(Industrial_PDV_NoSource_RootPath)}>Отмена</Button>
            <Button onClick={() => handleSubmit()} variant="solid">
              Сохранить
            </Button>
          </Flex>
        </Grid>
      </FormWrapper>
    </Box>
  );
};
