import ApiTokenForm from 'pages/admin/ApiToken/Forms/ApiTokenForm';
import { FormHeader } from 'components/common';
import { useHistory, useParams } from 'react-router-dom';
import { useEnhancedNotification, useRequest } from 'hooks';
import { FormikProvider, useFormik } from 'formik';
import { createTouchedErrors } from 'features/create-touched-errors';
import { adminApiTokenSchema } from 'models/schemas/admin/apiToken';
import { useEffect } from 'react';
import { IApiToken } from 'pages/admin/ApiToken/types';

const initialEmptyValues = {
  description: '',
  expires: '',
  active: true,
};

const ApiTokenEdit = () => {
  const { push } = useHistory();
  const { onErrorEdit, onSuccessEdit } = useEnhancedNotification();

  const { id } = useParams<{ id: string }>();
  const { put, isLoading: isEditLoading } = useRequest(
    `/admin/v10/admin/api-token/${id}`,
    () => {
      onSuccessEdit();
      push('/admin/apiToken');
    },
    onErrorEdit
  );

  const { isLoading, result, get } = useRequest<IApiToken>(`/admin/v10/admin/api-token/${id}`);

  useEffect(() => {
    void get();
  }, [get]);

  const initialValues = {
    ...initialEmptyValues,
    ...result,
  };

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    initialValues,
    onSubmit: (values) => put(values),
    validationSchema: adminApiTokenSchema,
  });

  return (
    <FormikProvider
      value={{
        ...formik,
        errors: createTouchedErrors(formik.errors, formik.touched),
      }}
    >
      <FormHeader url="/admin/apiToken" header="Редактирование записи" />
      <ApiTokenForm onCancel={() => push('/admin/apiToken')} isLoading={isLoading || isEditLoading} />
    </FormikProvider>
  );
};

export default ApiTokenEdit;
