export const sulfateIon = 'Сульфат-ион';

export const initialEmptyValues = {
  consumerId: '',
  organizationId: '',
  protocolNumber: '',
  sampleDate: '',
  sourceId: '',
  waterObjectId: '',
  values: []
};
