import { useCallback, useEffect } from 'react';
import { useRequest } from '../../useRequest';
import { ReperItems } from './useGeoReperList';

export function useGeoReperItem(id: string) {
  const { get, isLoading, result, error } = useRequest<ReperItems>(`/geoecology/v10/geoecology/reper/registry/${id}`);

  const getGeoReperItem = useCallback(() => get(), [get]);

  useEffect(() => {
    getGeoReperItem();
  }, [getGeoReperItem]);

  return {
    getGeoReperItem,
    isLoading,
    result,
    error,
  };
}
