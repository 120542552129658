import html2canvas from 'html2canvas';
import { FormikProvider, useFormik } from 'formik';
import { createTouchedErrors } from 'features/create-touched-errors';
import React from 'react';
import { jsPDF as JsPDF } from 'jspdf';
import { SendToMailValidationSchema } from 'models/schemas/sendToMailValidationSchema/sendToMailValidationSchema';
import { FormFields } from './formFields';
import { Modal } from '../../Modals';
import { IncomingHttpHeaders } from 'http';
import { useNotifications, useRequest } from 'hooks';
import { formatErrorMessage } from '../../../../lib/utils/formatErrorMessage';
import { IFormikUploadValues } from 'components/common/GraphicDropdownMenu/SendToMailModal/formFields/FormFields';

interface IProps {
  onClose: () => void;
  uploadType?: string | null;
  setUploadType?: (type: string | null) => void;
  filename: string;
  headers?: IncomingHttpHeaders | null | undefined;
  isOpen: boolean;
  resetHeaders?: () => void;
}

const initialFormikValues: IFormikUploadValues = {
  recipientsEmails: [''],
  template: 'REPORT',
  attachmentsContents: null,
  attachmentsReferences: null,
};

export const SendToMailModal = ({
  onClose,
  filename,
  uploadType,
  setUploadType,
  headers,
  isOpen,
  resetHeaders,
}: IProps) => {
  const { successNotify, errorNotify } = useNotifications();

  const onSuccess = () => {
    successNotify({
      key: 'sendToMail/success',
      message: 'Сообщение успешно отправлено',
    });
    customClose();
  };

  const onError = (message: string | null) => {
    const msg = formatErrorMessage(message);
    errorNotify({
      key: 'sendToMail/error',
      message: msg || 'Ошибка при отправке сообщения',
    });
  };

  const { post } = useRequest('notification/v10/notifications/attachments', onSuccess, onError);

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    validationSchema: SendToMailValidationSchema,
    initialValues: initialFormikValues,
    onSubmit: (values) => {
      post(values);
    },
  });

  const { setFieldValue, submitForm, resetForm } = formik;

  const customClose = () => {
    resetForm();
    if (resetHeaders) {
      resetHeaders();
    }
    setTimeout(() => onClose(), 0);
  };

  const uploadFile = async () => {
    if (!uploadType || !setUploadType) return;
    html2canvas(document.getElementById('LineChart') as HTMLElement).then((canvas) => {
      if (uploadType === 'png') {
        setFieldValue('attachmentsContents', [
          {
            name: filename,
            content: canvas.toDataURL('image/png', 1),
          },
        ]);
      } else {
        const pdf = new JsPDF({ orientation: 'landscape' });
        const width = pdf.internal.pageSize.getWidth();
        pdf.addImage(canvas, 'png', 5, 5, width - 10, 140);

        setFieldValue('attachmentsContents', [
          {
            name: filename,
            content: `data:application/pdf;base64,${btoa(pdf.output())}`,
          },
        ]);
      }
      submitForm();
    });
  };

  const sendMediaId = () => {
    setFieldValue('attachmentsReferences', [
      {
        name: filename,
        mediaId: headers?.location,
      },
    ]);
    submitForm();
  };

  return (
    <FormikProvider
      value={{
        ...formik,
        errors: createTouchedErrors(formik.errors, formik.touched),
      }}
    >
      <Modal isOpen={isOpen} onClose={customClose}>
        <FormFields
          uploadFile={uploadFile}
          sendMediaId={sendMediaId}
          uploadType={uploadType}
          customClose={customClose}
          isDisabled={!headers?.location}
        />
      </Modal>
    </FormikProvider>
  );
};
