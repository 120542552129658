import React, { memo, useCallback } from 'react';
import { ConfirmModal } from 'components/common/Modals';
import { REMOVE_CONFIRMATION, SUCCESS_MESSAGES } from 'constants/messages';

import { Disclosure } from 'hooks';
import { useNotifications } from 'hooks/useNotifications';
import { useRequest } from 'hooks/useRequest';
import { map } from 'lodash';
import { GreenPlantsApiRootPath } from 'pages/routes/GreenPlants';

interface DeleteGreenPlantsItemProps extends Disclosure {
  type: 'flora' | 'lawns';
  id: string | null;

  refetch(): void;
}

const DeleteGreenPlantsItem: React.FC<DeleteGreenPlantsItemProps> = memo(({ type, id, refetch, isOpen, onClose }) => {
  const { successNotify, errorNotify } = useNotifications();

  const onSuccess = useCallback(() => {
    onClose();

    successNotify({
      key: `${GreenPlantsApiRootPath}/${type}/delete/${id}/success`,
      message: SUCCESS_MESSAGES.DELETE,
    });

    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, onClose, refetch, successNotify]);

  const onError = useCallback(
    (error?: { descriptions: Array<{ message: string }> } | null) => {
      onClose();

      if (error?.descriptions) {
        errorNotify({
          key: `${GreenPlantsApiRootPath}/${type}/delete/${id}/error`,
          message: map(error.descriptions, (desc) => desc.message).join('\n'),
        });
      } else {
        errorNotify({
          key: `${GreenPlantsApiRootPath}/${type}/delete/${id}/error`,
          message: 'Ошибка при удалении записи',
        });
      }

      refetch();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [errorNotify, id, onClose, refetch],
  );

  const { remove, isLoading } = useRequest<undefined, { descriptions: Array<{ message: string }> }>(
    `${GreenPlantsApiRootPath}/${type}/${id}`,
    onSuccess,
    onError,
  );

  return (
    <ConfirmModal
      isOpen={isOpen}
      onClose={onClose}
      onClick={() => remove()}
      isLoading={isLoading}
      header={REMOVE_CONFIRMATION}
    />
  );
});

export type { DeleteGreenPlantsItemProps };

export { DeleteGreenPlantsItem };
