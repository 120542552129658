import { AxiosRequestConfig } from 'axios';
import { useRequest, UseRequestReturnType } from 'hooks/useRequest';
import { ApiError, FilePreview } from 'models';
import { useCallback } from 'react';
import { useNotifications } from 'hooks/useNotifications';
import { ERROR_MESSAGES } from 'constants/messages';

interface IUseFilePreviewReturnType<Measure = Record<string, unknown>>
  extends Omit<UseRequestReturnType<FilePreview<Measure>>, 'post' | 'get' | 'remove' | 'put'> {
  getFilePreview: (
    file: FormData,
    config: AxiosRequestConfig
  ) => Promise<FilePreview<Measure> | ApiError | null> | undefined;
}

interface UseFilePreview {
  <Measure = Record<string, unknown>>(
    url?: string,
    successHandler?: (data: FilePreview<Measure> | null) => void
  ): IUseFilePreviewReturnType<Measure>;
}

const useFilePreview: UseFilePreview = (url = '', successHandler) => {
  const { errorNotify } = useNotifications();

  const errorHandler = (data: ApiError | null) => {
    errorNotify({
      key: 'api/error',
      message: ERROR_MESSAGES.UPLOAD + (data?.status ? `: ${data.status}` : ''),
    });
  };

  const { post, result, error, isLoading, reset } = useRequest(url, successHandler, errorHandler);

  const getFilePreview = useCallback(
    (...parameters: Parameters<typeof post>) => {
      if (url) {
        return post(parameters[0], parameters[1]);
      }
    },
    [post, url],
  );
  return {
    getFilePreview,
    result,
    error,
    isLoading,
    reset,
  };
};
export type { IUseFilePreviewReturnType, UseFilePreview };
export { useFilePreview };
