import React, { useMemo } from 'react';
import { FormHeader } from 'components/common';
import { createTouchedErrors } from 'features/create-touched-errors';
import { FormikProvider, useFormik } from 'formik';
import { useAskzaDictionary } from 'hooks/askza/useAskzaDictionary';
import { useRequest } from 'hooks/useRequest';
import FieldsForm from './FieldsForm';
import { ASKZACommonSchema } from 'models/schemas/atmosphere/askzaCommon';
import { useGoBackWithFallback } from 'lib/utils/goBackWithFallback';
import { useEnhancedNotification } from 'hooks';

export const initialEmptyValues = {
  avg: {
    id: '',
    datetime: '',
    value: '',
  },
  max: {
    id: '',
    datetime: '',
    value: '',
  },
  min: {
    id: '',
    datetime: '',
    value: '',
  },
  parameterId: '',
  stationId: '',
};

interface IAddAtmosphereMonitoringResult {
  rootPath: string;
  apiRootPath: string;
  checkSourcePriv?: boolean;
}

export const AddASKZAResult: React.FC<IAddAtmosphereMonitoringResult> = ({
  rootPath,
  apiRootPath,
  checkSourcePriv = false,
}) => {
  const { backWithFallback } = useGoBackWithFallback();

  const dictNumber = useMemo(() => {
    switch (rootPath) {
      case '/data/meteo/data/msz4':
        return '8';
      case '/data/meteo/data/actinometry':
        return '9';
      default:
        return '6'; // askza
    }
  }, [rootPath]);

  const { isLoading: isDictionariesLoading, result: dictionaries } = useAskzaDictionary(dictNumber);

  const { onSuccessCreate, onErrorCreate } = useEnhancedNotification();

  const onSuccess = () => {
    onSuccessCreate();
    backWithFallback(rootPath);
  };

  const { post, isLoading: isPostLoading } = useRequest(apiRootPath, onSuccess, onErrorCreate);

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    initialValues: initialEmptyValues,
    validationSchema: ASKZACommonSchema,
    onSubmit: (values) => post(values),
  });

  return (
    <FormikProvider
      value={{
        ...formik,
        errors: createTouchedErrors(formik.errors, formik.touched),
      }}
    >
      <FormHeader onBackClick={() => backWithFallback(rootPath)} header="Добавление результатов измерения" />
      <FieldsForm
        dictionaries={dictionaries}
        onCancel={() => backWithFallback(rootPath)}
        isLoading={isDictionariesLoading || isPostLoading}
        checkSourcePriv={checkSourcePriv}
      />
    </FormikProvider>
  );
};
