import { SelectedCheckboxIcon, UnselectedCheckboxIcon } from 'components/icons';
import { cloneDeep } from 'lodash';
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ListResponseMeta } from 'models/api/list';
import { DEFAULT_PAGE_OPTIONS_NEW_TABLE, DEFAULT_PAGE_SIZE, DEFAULT_PAGE_SIZE_NEW_TABLE } from 'constants/tables';
import { IWithFileDownload, useDisclosure } from 'hooks';
import tableLocalization, {
  getEmptyDataSourceMessage,
} from 'features/material-table/components/const/tableLocalization';
import MaterialTableCore, {
  Column,
  MaterialTableProps as MaterialTableCoreProps,
  MTableCell,
  MTableHeader,
  Options,
} from '@material-table/core';
import { Toolbar, ToolBarAction } from './components/Toolbar';
import {
  TableDataProcessingMini,
  TableDataProcessingProp
} from './components/TableDataProcessing/TableDataProcessingMini';
import { createTableActions, PropActions } from './components/Actions';
import { ExtensionToMimeTypeEnum } from 'lib/utils/files/getFileExtensionByMimeType';
import tableIcons from 'features/material-table/components/const/tableIcons';
import { IFilterItem, TFilterDTO } from 'models';
import { makeStyles } from '@material-ui/core/styles';
import Pagination from 'features/material-table/components/Pagination/Pagination';
import { Box } from '@chakra-ui/react';
import { miniTableStyle } from './const';

interface MaterialTableProps<Data extends any[], Columns extends Array<Column<Data[number]>>>
  extends Omit<MaterialTableCoreProps<Data[number]>, 'data' | 'actions'> {
  columns: Columns;
  meta?: ListResponseMeta; // TODO: should be required?
  onPageChange?: (page: number, pageSize: number) => void;
  onSelectionChange?: (data: Data[number][], rowData?: Data[number] | undefined) => void;
  downloadAsFile?: IWithFileDownload;
  getHeaders?: (type: ExtensionToMimeTypeEnum, headersXLSXUrl?: string) => Promise<any>;
  headersXLSXUrl?: string;
  data?: Data;
  actions?: PropActions<Data>;
  dataProcessing?: TableDataProcessingProp;
  topBarLeftContent?: React.ReactNode;
  toggleOrder?: (fieldKey: string) => void;
  detailPanel?: (rowData: any) => React.ReactNode;
  selectedIndexes?: number[];
  saveFilters?: () => void;
  setFilters?: (filters: IFilterItem<Data[number]>[]) => void;
  options?: Options<Data>;
  onSearch?: (searchFields: string[], searchText: string) => void;
  downloadTemplate?: string[];
  downloadCSV?: (type: ExtensionToMimeTypeEnum) => void;
  filterDTO?: TFilterDTO;
  toolbarActions?: ToolBarAction[];
  fieldsToSearch?: string[];
  isNotExecute?: boolean;
  processingParamsUrl?: string;
  parametersKeys?: {
    sourceId?: string[] | string | null;
    dateFrom: string | null;
    dateTo: string | null;
    sourceIds?: string[];
  };
  onOpenUploadModal?: () => void;
  removeChecked?: () => void;
  showTotalCount?: boolean;
  errorText?: string;
  onColumnHide?: (column: string, hidden: boolean) => void;
  hiddenColumns?: string[];
  isWrite?: boolean;
  printLoggerUrl?: string;
}

const useStyles = makeStyles({
  // @ts-ignore
  root: miniTableStyle
});

// TODO: switch from material-table
function Table<Data extends any[]>(
  props: React.PropsWithChildren<MaterialTableProps<Data, Array<Column<Data[number]>>>>
) {
  const classes = useStyles({ sorting: props?.options?.sorting || !!props.toggleOrder });
  const {
    data,
    columns,
    isLoading,
    downloadAsFile,
    dataProcessing,
    actions,
    toggleOrder,
    topBarLeftContent,
    meta,
    onPageChange,
    detailPanel,
    onSelectionChange,
    totalCount: customCount,
    selectedIndexes,
    saveFilters,
    setFilters,
    options,
    onSearch,
    downloadTemplate,
    downloadCSV,
    filterDTO,
    toolbarActions,
    fieldsToSearch,
    isNotExecute = true,
    parametersKeys,
    processingParamsUrl,
    getHeaders,
    headersXLSXUrl,
    onOpenUploadModal,
    removeChecked,
    showTotalCount,
    errorText,
    onColumnHide,
    hiddenColumns,
    isWrite = true,
    printLoggerUrl,
  } = props;
  const extendedFilterKeys = useMemo(
    () => columns.filter((column) => !!column.filterComponent).map((column) => column.field),
    [columns]
  );

  const extendedFiltersInitialState = useMemo(
    () => filterDTO?.filters?.some((filter) => extendedFilterKeys.includes(filter.key) && filter.value),
    [extendedFilterKeys, filterDTO]
  );

  const {
    isOpen: isOpenDataProcessing,
    onClose: dataProcessingOnClose,
    onOpen: dataProcessingOnOpen,
  } = useDisclosure();

  useEffect(() => {
    // open extended filters if there is values
    if (extendedFiltersInitialState) {
      setShowExtendedFilters(extendedFiltersInitialState);
    }
  }, [extendedFiltersInitialState]);

  const [showExtendedFilters, setShowExtendedFilters] = useState(extendedFiltersInitialState);

  const tableRef = useRef<any>(null);

  // bug in material table https://github.com/mbrn/material-table/issues/1480
  useEffect(() => {
    if (meta) {
      tableRef.current.dataManager.changePageSize(meta.limit || DEFAULT_PAGE_SIZE);
    }
  }, [meta]);

  // use when selected rows is controlled outside of component
  useEffect(() => {
    if (selectedIndexes !== undefined && tableRef && data && data.length > 0) {
      data.forEach((row, index) => {
        if (selectedIndexes.includes(index)) {
          tableRef.current.dataManager.changeRowSelected(true, [index]);
        } else {
          tableRef.current.dataManager.changeRowSelected(false, [index]);
        }
      });
      tableRef.current.updater.enqueueForceUpdate(tableRef.current);
    }
  });

  const handleRowClick = (
    event?: React.MouseEvent<Element, MouseEvent> | undefined,
    rowData?: Data[number] | undefined
  ) => {
    // Клик по чекбоксу ставит состояние checked  в нужной строке
    // Не работает с чекбоксом, который отвечает за все чекбоксы
    if (rowData && event) {
      const target = event.target as HTMLDivElement;
      const index = target?.closest?.('tr')?.getAttribute?.('index');
      const id = index ?? rowData.tableData.id;
      tableRef.current.dataManager.changeRowSelected(!rowData.tableData.checked, [id]);
      tableRef.current.updater.enqueueForceUpdate(tableRef.current);
      tableRef.current.onSelectionChange(rowData);
    }
  };

  const handleSortOrderChange = useCallback(
    (columnIndex) => {
      if (toggleOrder) {
        const fieldKey = columns[columnIndex].field;
        if (fieldKey && typeof fieldKey === 'string') {
          toggleOrder(fieldKey);
        }
      } else {
        console.error('sorting not implemented');
      }
    },
    [toggleOrder, columns]
  );

  const page = useMemo(() => {
    if (!meta) return 0;
    return meta.offset / meta.limit;
  }, [meta]);

  const totalCount = useMemo(() => {
    if (!meta) return 0;
    if (customCount !== undefined) return customCount;
    return meta.totalCount;
  }, [meta, customCount]);

  const currentOrderIndex: [number, 'asc' | 'desc' | undefined] = useMemo(() => {
    const orderKey = filterDTO?.orders[0];
    const index = columns.findIndex((c) => c.field === orderKey || `-${String(c.field)}` === orderKey);

    let direction: 'asc' | 'desc' | undefined;
    if (orderKey) {
      direction = orderKey[0] === '-' ? 'desc' : 'asc';
    }

    return [index, direction];
  }, [columns, filterDTO]);

  // if filterComponent is not defined do not render default filterComponent
  const _columns = useMemo(
    () =>
      columns.map((column) => ({
        ...column,
        title: (
          <Box maxWidth={200} wordBreak="break-word" width="max-content">
            {column.title}
          </Box>
        ),
        hidden: hiddenColumns?.includes(String(column.field)),
        filterComponent: column.filterComponent || (() => <div />),
        // HACK: https://github.com/material-table-core/core/issues/384
        customSort: () => true,
      })),
    [columns, hiddenColumns]
  );

  const toggleExtendedFilters = useCallback(() => {
    const showExtendedFiltersUpdate = !showExtendedFilters;

    // on close extended filters - clear extended filters and refetch
    if (!showExtendedFiltersUpdate && setFilters && filterDTO?.filters) {
      setFilters(filterDTO?.filters.filter((filter) => !extendedFilterKeys.includes(filter.key)));
    }
    setShowExtendedFilters(showExtendedFiltersUpdate);
  }, [extendedFilterKeys, filterDTO, setFilters, showExtendedFilters]);

  const defaultOptions = {
    actionsCellStyle: {
      position: 'sticky',
      right: -20,
      background: 'linear-gradient(to right, rgb(255 255 255 / 43%) 0%,rgb(255 255 255) 20%)',
      alignItems: 'flex-end',
    },
    loadingType: 'overlay',
    actionsColumnIndex: -1,
    columnsButton: true,
    search: false,
    sorting: !!toggleOrder,
    showTitle: false,
    toolbar: true,
    paging: !!meta,
    paginationPosition: 'top',
    pageSize: meta?.limit || DEFAULT_PAGE_SIZE_NEW_TABLE,
    pageSizeOptions: DEFAULT_PAGE_OPTIONS_NEW_TABLE,
    draggable: false,
    filtering: showExtendedFilters,
    editable: true,
    headerSelectionProps: {
      color: 'primary',
      disableRipple: true,
      checkedIcon: <SelectedCheckboxIcon />,
      icon: <UnselectedCheckboxIcon />,
      indeterminate: false,
      style: { padding: '0' },
    },
    selection: true,
    selectionProps: () => ({
      color: 'primary',
      disableRipple: true,
      checkedIcon: <SelectedCheckboxIcon />,
      icon: <UnselectedCheckboxIcon />,
      style: { padding: '0' },
    }),
    tableLayout: 'auto',
    rowStyle: () => ({
      // '@media print': {
      display: 'none !important',
      // }
    }),
    doubleHorizontalScroll: true,
    overflowY: 'visible',
    idSynonym: 'special_non_existent_key_to_prevent_id_duplicates_to_break_table',
    emptyRowWhenPaging: false,
  };

  const targetOptions = Object.assign(defaultOptions, options);

  const handleSearch = (columns: Column<Data[number]>[], searchString: string) => {
    if (onSearch != null) {
      const searchFields = !fieldsToSearch
        ? columns.map((c: Column<Data[number]>) => `${String(c?.field)}`)
        : fieldsToSearch;
      onSearch(searchFields, searchString);
    }
  };

  const emptyDataSourceMessage = useMemo(() =>
    (isLoading ? '' : getEmptyDataSourceMessage({ data, errorText })),
    [isLoading, data, errorText]);

  // HACK: table rerender causes Toolbar menu close
  const menuDisclosure = useDisclosure();
  const hideColumnsDisclosure = useDisclosure();
  const downloadFileTypeDisclosure = useDisclosure();
  const uploadFileTypeDisclosure = useDisclosure();

  const [changedColumnTitle, setChangedColumnTitle] = useState<string>('');

  return (
    <div className={classes.root}>
      <MaterialTableCore<Data[number]>
        key={`table${meta?.limit || ''}`}
        style={{
          width: 'inherit',
          display: 'flex',
          flexDirection: 'column-reverse',
          boxShadow: 'none',
          height: '100%',
          justifyContent: 'flex-end',
          minHeight: 0,
        }}
        tableRef={tableRef}
        data={data || []}
        // @ts-ignore
        columns={_columns}
        detailPanel={detailPanel}
        icons={tableIcons}
        components={{
          Pagination: (props) => <Pagination
            {...props}
            count={totalCount}
            rowsPerPage={meta?.limit || DEFAULT_PAGE_SIZE}
            showTotalCount={showTotalCount}
          />,
          Toolbar: (props) => (
            <Toolbar
              onOpenUploadModal={onOpenUploadModal}
              isNotExecute={isNotExecute}
              filterDTO={filterDTO}
              downloadAsFile={downloadAsFile}
              getHeaders={getHeaders}
              headersXLSXUrl={headersXLSXUrl}
              isDataProcessing={Boolean(dataProcessing)}
              handleOpenTableDataProcessing={dataProcessingOnOpen}
              columns={props.columns}
              handleHiddenColumn={(column, next) => {
                setChangedColumnTitle(column.title as string);
                // external hidden columns control
                if (onColumnHide) {
                  onColumnHide(String(column.field), next);
                  return;
                }
                props.onColumnsChanged(column, next);
              }}
              toggleExtendedFilters={toggleExtendedFilters}
              showExtendedFiltersControl={extendedFilterKeys.length > 0}
              topBarLeftContent={topBarLeftContent}
              saveFilters={saveFilters}
              search={targetOptions.search}
              onSearch={(searchString: string) =>
                handleSearch(
                  props.columns.filter((c: Column<Data[number]>) => !c.hidden),
                  searchString
                )
              }
              downloadTemplate={downloadTemplate}
              toolbarActions={toolbarActions}
              downloadCSV={downloadCSV}
              removeChecked={removeChecked}
              menuDisclosure={menuDisclosure}
              hideColumnsDisclosure={hideColumnsDisclosure}
              downloadFileTypeDisclosure={downloadFileTypeDisclosure}
              uploadFileTypeDisclosure={uploadFileTypeDisclosure}
              changedColumnTitle={changedColumnTitle}
              setChangedColumnTitle={setChangedColumnTitle}
              isWrite={isWrite}
              printLoggerUrl={printLoggerUrl}
            />
          ),
          Header: (props) => (
            <MTableHeader
              {...props}
              onOrderChange={handleSortOrderChange}
              orderBy={currentOrderIndex[0]}
              orderDirection={currentOrderIndex[1]}
              // HACK: somehow selected count is equal to 20 instead of 10 on select all
              selectedCount={
                selectedIndexes
                  ? tableRef.current?.dataManager.data.filter((row: any) => row.tableData.checked).length
                  : props.selectedCount
              }
            />
          ),
          Cell: (props) => (
            <MTableCell
              {...props}
              style={{
                background: props.value?.error ? 'red' : 'initial',
              }}
            />
          ),
        }}
        onRowClick={handleRowClick}
        isLoading={isLoading}
        totalCount={totalCount}
        page={page}
        onPageChange={onPageChange}
        options={targetOptions}
        actions={cloneDeep(createTableActions<Data>(actions))}
        localization={tableLocalization({
          emptyDataSourceMessage,
          showTotalCount
        })}
        onSelectionChange={onSelectionChange}
      />

      <TableDataProcessingMini
        isLoading={Boolean(isLoading)}
        isOpen={isOpenDataProcessing}
        onClose={dataProcessingOnClose}
        operands={dataProcessing?.operands}
        operations={dataProcessing?.operations}
        onSubmit={dataProcessing?.onSubmit}
        parametersUrl={processingParamsUrl}
        parametersKeys={parametersKeys}
        dataProcessing={dataProcessing}
      />
    </div>
  );
}

export const MaterialTable = memo(Table);
