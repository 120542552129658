import { Grid } from '@chakra-ui/react';
import { Field, FormikContextType, FormikErrors, FormikValues, useFormikContext } from 'formik';
import { DateInput, Select } from 'components/common';
import React from 'react';
import { TDictionaryItemDictionary } from 'models';
import { createDateToRequestWithTimezone } from 'lib/create-date';

interface IProps {
  statuses: TDictionaryItemDictionary[];
  areasForLawn: TDictionaryItemDictionary[];
  isEditMode: boolean
}

export const GeneralInformation = ({ statuses, areasForLawn, isEditMode }: IProps) => {
  const { errors, values, setFieldValue }: FormikContextType<FormikErrors<FormikValues>> = useFormikContext();

  return (
    <Grid gap={2} templateColumns="repeat(1, 1fr)">
      <Field as={Select} name="parentAreaId" label="Номер площадки" options={areasForLawn} error={errors?.parentAreaId} isDisabled={isEditMode} />
      <Field as={Select} name="status" label="Статус" options={statuses} error={errors?.status} />

      <DateInput
        name="startDate"
        label="Начало действия"
        error={errors.startDate}
        value={values.startDate}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setFieldValue('startDate', createDateToRequestWithTimezone(e.target.value))
        }
        showTimeInput
      />

      <DateInput
        name="stopDate"
        label="Окончание действия"
        error={errors.stopDate}
        value={values.stopDate}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setFieldValue('stopDate', createDateToRequestWithTimezone(e.target.value))
        }
        showTimeInput
      />
    </Grid>
  );
};
