import { IFilterItem } from 'models';
import appLocale from '../../../../constants/appLocale';

export const initialEntryLogFilters: { [s: string]: IFilterItem } = {
  dateTimeMin: {
    key: 'logDate',
    operation: 'GTE',
    value: '',
  },
  dateTimeMax: {
    key: 'logDate',
    operation: 'LTE',
    value: '',
  },
  subsystem: {
    key: 'subsystem',
    operation: 'EQ',
    value: '',
  },
  loglevel: {
    key: 'level',
    operation: 'EQ',
    value: '',
  },
  function: {
    key: 'function',
    operation: 'EQ',
    value: '',
  },
};

export const dataEntryLogBreadcrumb = [
  {
    breadcrumb: appLocale.admin.dataEntryLog,
    path: '/entryLogData',
  },
];
