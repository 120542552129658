import React, { useState } from 'react';
import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { DefaultText, FormHeader } from 'components/common';
import { AddAppealForm } from './Appeal/AddAppealForm';
import { ADD_APPEAL_ERROR, ADD_APPEAL_SUCCESS, Appeals, CitizensAppealsPath, Complaint } from 'pages/citizenAppeal';
import { AddIncidentForm } from './Incident/AddIncidentForm';
import { AddResponseForm } from './Response/AddResponseForm';
import { FormikProvider, useFormik } from 'formik';
import { useRequest } from 'hooks/useRequest';
import { createTouchedErrors } from 'features/create-touched-errors';
import { useNotifications } from 'hooks/useNotifications';
import { ComplaintSchema } from 'models/schemas';
import { complaintsAdd, getComplaintEndpoint } from '../../consts/apiPaths';
import { useGoBackWithFallback } from 'lib/utils/goBackWithFallback';
import { useDictionary } from 'hooks/useDictionary';

const AddAppeal: React.FC = () => {
  const { backWithFallback } = useGoBackWithFallback();
  const [isIncidentDisabled, setIsIncidentDisabled] = useState(true);
  const [complaint, setComplaint] = useState<Complaint | null>(null);
  const [incident, setIncident] = useState<Complaint | null>(null);

  const { successNotify, errorNotify } = useNotifications();
  const { dictionaries } = useDictionary({ url: '/appeal/v10/appeals/dictionaries' });

  const onSuccess = (complaint: Complaint | null) => {
    successNotify({
      key: 'file/add/success',
      message: ADD_APPEAL_SUCCESS,
    });
    setIsIncidentDisabled(false);
    setComplaint(complaint);
    formik.setFieldValue('complaintId', complaint?.complaintId);
  };
  const onError = () =>
    errorNotify({
      key: 'file/add/error',
      message: ADD_APPEAL_ERROR,
    });

  const { post } = useRequest(complaintsAdd, onSuccess, onError);
  const { put } = useRequest(getComplaintEndpoint(complaint?.complaintId), onSuccess, onError);

  const initialEmptyValues = {
    assignmentDate: '',
    assignmentDeadline: '',
    assignmentExecution: '',
    assignmentNumber: '',
    assignmentUrl: '',
    complaintAnswerOnPaper: false,
    complaintCitizenAddress: '',
    complaintCitizenEmail: '',
    complaintCitizenFio: '',
    complaintCitizenPhone: '',
    complaintDate: '',
    complaintId: '',
    complaintNumber: '',
    complaintOriginId: '',
    complaintText: '',
    questions: [''],
  };

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    validationSchema: ComplaintSchema,
    initialValues: { ...initialEmptyValues, ...complaint },
    onSubmit: (values: any) => {
      if (complaint) {
        put(values);
      } else {
        post(values);
      }
    },
  });

  useUpdateBreadcrumbs([
    {
      breadcrumb: 'Учет обращений граждан',
      path: CitizensAppealsPath,
    },
  ]);
  return (
    <>
      <FormikProvider
        value={{
          ...formik,
          errors: createTouchedErrors(formik.errors, formik.touched),
        }}
      >
        <Tabs>
          <FormHeader onBackClick={() => backWithFallback(Appeals)} header="Учет обращений граждан">
            <TabList>
              <Tab>
                <DefaultText>Обращение</DefaultText>
              </Tab>
              <Tab isDisabled={isIncidentDisabled}>
                <DefaultText>Происшествие</DefaultText>
              </Tab>
              <Tab isDisabled={isIncidentDisabled}>
                <DefaultText>Ответ</DefaultText>
              </Tab>
            </TabList>
          </FormHeader>
          <TabPanels>
            <TabPanel>
              <AddAppealForm dictionaries={dictionaries?.data} />
            </TabPanel>
            <TabPanel>
              <AddIncidentForm
                incident={incident}
                dictionaries={dictionaries?.data}
                complaint={complaint}
                setIncident={setIncident}
              />
            </TabPanel>
            <TabPanel>
              <AddResponseForm dictionaries={dictionaries?.data} incident={incident} complaint={complaint} />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </FormikProvider>
    </>
  );
};

export { AddAppeal };
