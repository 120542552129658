import { ANALYTICS_STATUS } from 'pages/analytics/forms/const';

export const initialEmptyValues = {
  protocol: '',
  protocolDate: null,
  consumerId: null,
  researchBasis: '',
  researchObject: '',
  supportingDocuments: '',
  sampleNumber: '',
  address: '',
  latitude: '',
  longitude: '',
  date: null,
  organizationId: null,
  explanationFromConsumer: '',
  sampleReceiveDate: null,
  researchDateStart: null,
  researchDateEnd: null,
  sampleLaboratoryNumber: '',
  explanation: '',
  status: ANALYTICS_STATUS.DRAFT,
  laboratory: true,
  sign: null,
  links: null,
  parameters: [],
};
