import { IFilterItem } from 'models';
import { cloneDeep } from 'lodash';

/**
 * @summary заполняет initialFilters по совпадению key и operation из filters
 * @param initialFilters
 * @param filters
 */
function combineFilters<Keys = string>(
  initialFilters: Record<string, IFilterItem<Keys>>,
  filters: IFilterItem<Keys>[]
): Record<string, IFilterItem<Keys>> {
  const _initialFilters = cloneDeep(initialFilters);

  Object.keys(_initialFilters).forEach((key) => {
    const filter = filters.find(
      (filter) => `${filter.key}${filter.operation}` === `${_initialFilters[key].key}${_initialFilters[key].operation}`
    );
    if (filter) {
      _initialFilters[key] = filter;
    }
  });

  return _initialFilters;
}

export default combineFilters;
