import { Box, Flex, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { AttachmentsTabContent, Button, DefaultText, FormHeader, FormWrapper, LoaderItem } from 'components/common';
import React, { useMemo } from 'react';
import { useLocation } from 'react-router';
import { COMMON_LOCALE } from 'constants/common';
import { CommonTab } from './CommonTab';
import { ParametersTab } from './ParametersTab';
import { WashoutDeicingItem } from '../useWashoutDicingList';
import { useQuery } from 'hooks/useQuery';
import { useFormikContext } from 'formik';
import { useAvailability, useEnhancedNotification } from 'hooks';
import { useObjectType } from 'hooks/useObjectType';
import appLocale from 'constants/appLocale';
import { FieldFormProps } from 'types/forms';
import subsystemIdsMap from 'constants/subsystemIdsMap';

const FieldsForm = ({
  onCancel,
  dictionaries,
  isLoading,
  media,
  onMediaChange,
  uploadItemId,
}: FieldFormProps) => {
  const { isAvailable } = useAvailability(subsystemIdsMap.pgrRoot);

  const location = useLocation();
  const isEditMode = location.pathname.includes('/edit');
  const title = useMemo(
    () => (isEditMode ? COMMON_LOCALE.text.edit_research_result : COMMON_LOCALE.text.addition_research_result),
    [isEditMode]
  );
  const { openAttachmentsTab } = useQuery();
  const { handleSubmit, values, errors } = useFormikContext<WashoutDeicingItem>();
  const { onValidationError } = useEnhancedNotification();
  const submitIsDisabled = useMemo(
    () => [...values.parameters].map((val) => val.value).filter((val) => !!val).length === 0,
    [values.parameters]
  );
  const objectType = useObjectType(dictionaries);

  const renderControls = useMemo(
    () => (
      <Flex>
        <Button onClick={onCancel} marginRight={20}>
          {COMMON_LOCALE.cancel}
        </Button>
        <Button
          isDisabled={!isAvailable || submitIsDisabled}
          onClick={() => {
            if (Object.keys(errors).length > 0) {
              onValidationError();
            }
            handleSubmit();
          }}
          variant="solid"
        >
          {COMMON_LOCALE.save}
        </Button>
      </Flex>
    ),
    [errors, handleSubmit, onCancel, onValidationError, submitIsDisabled, isAvailable]
  );

  if (isLoading) {
    return <LoaderItem />;
  }

  return (
    <Box flexDirection="column">
      <Tabs defaultIndex={openAttachmentsTab ? 2 : 0}>
        <FormHeader onBackClick={onCancel} header={title}>
          <TabList>
            {[appLocale.common.generalInformation, appLocale.common.indicators, appLocale.common.attachments].map(
              (tab, i) => (
                <Tab key={i}>
                  <DefaultText cursor="pointer">{tab}</DefaultText>
                </Tab>
              )
            )}
          </TabList>
        </FormHeader>
        <TabPanels>
          <TabPanel>
            <CommonTab dictionaries={dictionaries} />
          </TabPanel>
          <TabPanel>
            <ParametersTab isWrite={isAvailable} dictionaries={dictionaries}>
              {renderControls}
            </ParametersTab>
          </TabPanel>
          <TabPanel>
            <FormWrapper>
              {isAvailable && (
                <AttachmentsTabContent
                  objectType={objectType}
                  media={media}
                  onMediaChange={onMediaChange}
                  uploadItemId={uploadItemId}
                >
                  {renderControls}
                </AttachmentsTabContent>
              )}
            </FormWrapper>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default React.memo(FieldsForm);
