import { UploadFileType } from 'components/common/FileUploadDragAndDrop/FileUploadDragAndDrop';

const getAcceptedTypes = (type: UploadFileType | UploadFileType[]): [string, string[]] => {
  function getByType(type: UploadFileType): [string, string[]] {
    switch (type) {
      case UploadFileType.image: {
        return ['.jpg,.png,.jpeg,.gif', ['image/gif', 'image/jpeg', 'image/png', 'image/bmp']];
      }
      case UploadFileType.pdf: {
        return ['.pdf', ['application/pdf']];
      }
      case UploadFileType.doc: {
        return [
          '.doc,.docx',
          ['application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'],
        ];
      }
      case UploadFileType.txt: {
        return ['.txt', ['text/plain']];
      }
    }
  }

  if (Array.isArray(type)) {
    const fileExtensions = type
      .map(getByType)
      .map((el) => el[0])
      .join(',');
    const mimeTypes = type
      .map(getByType)
      .map((el) => el[1])
      .flat();

    return [fileExtensions, mimeTypes];
  }

  return getByType(type);
};

export default getAcceptedTypes;

// server accepted file types that are not utilized here

// export const ACCEPTED_FILE_EXTENSIONS = [
//   'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
//
//   'application/xml',
//   'text/csv',
//
//   'application/json',
//
//   'application/zip',
//   'application/x-7z-compressed',
//
//   'audio/mpeg'
// ];
