import { useCallback, useMemo } from 'react';
import { IFilterValuesDefault } from 'components/common';
import { IFilterItem, TFilterOperations, TFiltersArray } from 'models';
import { createUTCTime } from 'lib/create-date';

interface IUseDefaultFilters {
  filters: TFiltersArray;
  dateKey?: string;
  paramId?: string;
  stationId?: string;
  initialData?: string;
  filterSourceValue?: (value: any) => string | undefined;
}

/**
 * @summary hook for get default filters from filters arr
 */
export const useDefaultFilters = ({
  filters,
  dateKey = 'date',
  paramId = 'paramId',
  stationId = 'stationId',
  initialData,
  filterSourceValue,
}: IUseDefaultFilters): IFilterValuesDefault => {
  const findItemPredicate = useCallback(
    (operation: TFilterOperations) => (item: IFilterItem) => item.operation === operation && item.key === dateKey,
    [dateKey],
  );

  const getFilterDateValue = useCallback(
    (filters, operation: TFilterOperations) => {
      const filterStartDate = filters.find(findItemPredicate(operation));
      if (filterStartDate) {
        return createUTCTime(filterStartDate.value) as string;
      }
      return '';
    },
    [findItemPredicate],
  );

  const parameterValue = useMemo(() => filters.find((filter) => filter.key === paramId)?.value, [filters, paramId]);
  const sourceValue = useMemo(
    () => filters.find((filter) => filter.key === stationId)?.value, [filters, stationId],
  );
  const initialDataValue = useMemo(
    () => filters.find((filter) => filter.key === initialData)?.value, [filters, initialData],
  );

  const paramsIds = useMemo(() => (parameterValue ? ({ parameters: [parameterValue] }) : {}), [parameterValue]);
  const sourceIds = useMemo(() => {
    if (!sourceValue) return {};

    if (filterSourceValue) {
      return ({ sources: [filterSourceValue(sourceValue)] });
    }

    return ({ sources: [sourceValue] });
  }, [filterSourceValue, sourceValue]);

  const startDate = useMemo<string>(() => getFilterDateValue(filters, 'GTE'),
    [filters, getFilterDateValue]);
  const stopDate = useMemo<string>(() => getFilterDateValue(filters, 'LTE'),
    [filters, getFilterDateValue]);

  return useMemo(() => ({ startDate, stopDate, ...paramsIds, ...sourceIds, initialData: Boolean(initialDataValue) }),
    [paramsIds, sourceIds, startDate, stopDate, initialDataValue]);
};
