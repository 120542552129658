import { useEffect } from 'react';

import { useRequest } from 'hooks/useRequest';
import { useNotifications } from 'hooks/useNotifications';

import { apiPaths } from '../../apiPaths';
import { SelectOption } from '../../../Select/types';

interface UseFiltersDict {
  subSystemId: string;
  templateId: string;
  sourcesIds?: string[];
  from?: string;
  to?: string;
  isSpecified?: boolean;
}
export const useParamsDict = ({ subSystemId, templateId, sourcesIds, isSpecified, from, to }: UseFiltersDict) => {
  const methodName = isSpecified ? 'parametersSpecified' : 'parameters';
  const {
    get: getParams,
    error: paramsError,
    result: params,
    isLoading: isLoadingParams,
    reset: resetParameters,
  } = useRequest<SelectOption[]>(apiPaths.reporting[methodName]({ subSystemId, templateId, sourcesIds, from, to }));

  const { errorNotify } = useNotifications();

  useEffect(() => {
    if (paramsError) {
      errorNotify({
        message: 'Ошибка получения параметров',
        key: 'graphic_params_error',
      });
      resetParameters();
    }
  }, [paramsError, errorNotify, resetParameters]);

  return {
    isLoadingParams,
    hasErrorParams: !!paramsError,
    params,
    getParams,
  };
};
