import { Box, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import {
  AttachmentsTabContent,
  DefaultText,
  FieldsFormControls,
  FormHeader,
  FormWrapper,
  LoaderItem,
} from 'components/common';
import React, { useState } from 'react';
import { useLocation } from 'react-router';
import { SoilListItem } from '../const/types';
import { CommonTab } from './CommonTab';
import { MeasuringTab } from './MeasuringTab';
import { useQuery } from 'hooks/useQuery';
import { useFormikContext } from 'formik';
import { useObjectType } from 'hooks/useObjectType';
import appLocale from 'constants/appLocale';
import { FieldFormProps } from 'types/forms';
import subsystemIdsMap from 'constants/subsystemIdsMap';
import { useAvailability } from 'hooks';
import { useEnhancedNotification } from 'hooks/useEnhancedNotification';

interface SoilFieldFormProps extends FieldFormProps {
  uploadItemId?: string;
}

const FieldsForm = ({
  onCancel,
  dictionaries,
  isLoading,
  media,
  onMediaChange,
  uploadItemId
}: SoilFieldFormProps) => {
  const { onValidationError } = useEnhancedNotification();

  const { isAvailable } = useAvailability(subsystemIdsMap.soilpollution);

  const location = useLocation();
  const isEdit = location.pathname.includes('edit');
  const { openAttachmentsTab } = useQuery();
  const { handleSubmit, errors } = useFormikContext<SoilListItem>();

  const objectType = useObjectType(dictionaries);
  const [tabIndex, setTabIndex] = useState(openAttachmentsTab ? 2 : 0);

  if (isLoading) {
    return <LoaderItem />;
  }

  const onNextTab = () => setTabIndex((prev) => prev + 1);

  const onSubmit = () => {
    if (Object.keys(errors).length > 0) {
      onValidationError();
    }
    handleSubmit();
  };

  return (
    <Box flexDirection="column">
      <Tabs
        index={tabIndex}
        onChange={(index) => setTabIndex(index)}
      >
        <FormHeader onBackClick={onCancel} header={`${isEdit ? 'Редактирование' : 'Добавление'} результатов протокола`}>
          <TabList>
            {[
              appLocale.common.generalInformation,
              appLocale.common.indicators,
              appLocale.common.attachments,
            ].map((tab, i) => (
              <Tab key={i}>
                <DefaultText cursor="pointer">{tab}</DefaultText>
              </Tab>
            ))}
          </TabList>
        </FormHeader>
        <TabPanels>
          <TabPanel>
            <CommonTab dictionaries={dictionaries}>
              <FieldsFormControls
                onCancel={onCancel}
                handleSubmit={onSubmit}
                onNextTab={onNextTab}
              />
            </CommonTab>
          </TabPanel>

          <TabPanel>
            <MeasuringTab isWrite={isAvailable} dictionaries={dictionaries}>
              <FieldsFormControls
                onCancel={onCancel}
                handleSubmit={onSubmit}
                onNextTab={onNextTab}
                showSave
                isSaveDisabled={!isAvailable}
              />
            </MeasuringTab>
          </TabPanel>

          <TabPanel>
            {
              isAvailable && (
                <FormWrapper>
                  <AttachmentsTabContent
                    objectType={objectType}
                    media={media}
                    onMediaChange={onMediaChange}
                    uploadItemId={uploadItemId}
                  >
                    <FieldsFormControls onCancel={onCancel} handleSubmit={onSubmit} showSave />
                  </AttachmentsTabContent>
                </FormWrapper>
              )
            }
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default React.memo(FieldsForm);
