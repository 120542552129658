import { TFiltersArray } from 'models/filter';
import { yupInstance } from 'yupInstance';

const Schema = yupInstance.array().of(
  yupInstance.object({
    key: yupInstance.string().nullable(false).required(),
    operation: yupInstance.string().nullable(false).required(),
    value: yupInstance
      .mixed()
      .transform((value: number | string | boolean | Array<string>) => {
        if (value === undefined) {
          return null;
        }

        if (value === null) {
          return null;
        }

        if (typeof value === 'number') {
          return value;
        }

        if (typeof value === 'boolean') {
          return value;
        }

        if (typeof value === 'string') {
          if (value === '') {
            return null;
          }

          const int = Number(value);

          if (!isNaN(int)) {
            return int;
          }

          if (value !== '') {
            return value;
          }

          return null;
        }

        if (Array.isArray(value)) {
          if (value.length === 0) {
            return null;
          }

          return value;
        }

        return null;
      })
      .default(null),
  })
);

const transformFiltersArray = <T extends TFiltersArray>(filters: T): T =>
  // @ts-ignore
  Schema.validateSync(filters);
export { transformFiltersArray };
