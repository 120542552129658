import { NSIRootPath } from 'pages/dictionaries/const/routes';

export const NSIEmissionsApiPath = '/nsi/v10/sources/emissions';

export const NSIEmissionsRootPath = `${NSIRootPath}/emissions`;

export const NSIEmissionsSourcesRootPath = `${NSIEmissionsRootPath}/sources`;
export const NSIEmissionsSourcesAddPath = `${NSIEmissionsSourcesRootPath}/add`;
export const NSIEmissionsSourcesEditPath = `${NSIEmissionsSourcesRootPath}/edit`;

export const NSIEmissionsIndustriesRootPath = `${NSIEmissionsRootPath}/industries`;
export const NSIEmissionsIndustriesAddPath = `${NSIEmissionsIndustriesRootPath}/add`;
export const NSIEmissionsIndustriesEditPath = `${NSIEmissionsIndustriesRootPath}/edit`;
