import { useRequest } from '../../hooks';
import { useEffect } from 'react';
import { IPeriod } from './types';

export const usePeriodsDict = (path: string) => {
  const { get, isLoading, result, error } = useRequest<IPeriod[]>(path);

  useEffect(() => {
    get();
  }, [get]);

  return { isLoading, result, error };
};
