import { createTouchedErrors } from 'features/create-touched-errors';
import { FormikProvider, useFormik } from 'formik';
import { RemoveDeviceFromStation } from 'pages/equipment/Stations/types';
import { FormFields } from 'pages/equipment/Stations/components/RemoveDeviceForm/FormFields';
import * as yup from 'yup';
import { requiredError } from 'models/schemas/constans';

export * from 'pages/equipment/Stations/components/RemoveDeviceForm/FormContainer';

interface RemoveDeviceFormProps {
  initialValues: Partial<RemoveDeviceFromStation>;
  onSubmit: (values: Partial<RemoveDeviceFromStation>) => void;
  onCancel: () => void;
  submitDisabled: boolean;
}

export const RemoveDeviceForm = ({ initialValues, ...props }: RemoveDeviceFormProps) => {
  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    initialValues,
    validationSchema: yup.object()
      .shape({
        dateEnd: yup.string()
          .nullable()
          .required(requiredError),
      }),
    onSubmit: props.onSubmit,
  });

  return (
    <FormikProvider
      value={{
        ...formik,
        errors: createTouchedErrors(formik.errors, formik.touched),
      }}
    >
      <FormFields
        onCancel={props.onCancel}
        submitIsDisabled={props.submitDisabled}
      />
    </FormikProvider>
  );
};
