import React, { useCallback } from 'react';
import { Button, SubsystemHeader } from 'components/common';
import { useHistory } from 'react-router-dom';
import { HStack } from '@chakra-ui/react';
import { FTPSettings } from 'pages/admin/FTP';
import appLocale from 'constants/appLocale';

interface FTPSettingsHeader {
  url: string;
  active: string;
  filtersComponent: React.ReactNode;
}

export const ftpSettingHeaderTabs = [
  {
    label: appLocale.admin.ftpServers,
    value: 'servers',
  },
  {
    label: appLocale.admin.ftpCatalogs,
    value: 'catalogs',
  },
];

export const FTPHeader = ({ url, active, filtersComponent }: FTPSettingsHeader) => {
  const history = useHistory();
  const handleAddButtonClick = useCallback(() => {
    history.push(`${history.location.pathname}${url}`);
  }, [history, url]);

  return (
    <SubsystemHeader
      title={appLocale.admin.ftpProtocolSettings}
      addButtonProps={{ onClick: handleAddButtonClick }}
      filtersComponent={filtersComponent}
      addButtonText={active === 'servers' ? 'Добавить сервер' : 'Добавить каталог'}
      navigationTabs={(
        <HStack spacing="10px">
          {ftpSettingHeaderTabs.map(({ label, value }) => (
            <Button
              key={value}
              colorScheme="blue"
              backgroundColor={value === active ? '#F5FAFF' : 'white'}
              borderColor="#D4D9DC"
              textColor="black"
              variant="outline"
              onClick={() => history.push(`${FTPSettings}/${value}`)}
            >
              {label}
            </Button>
          ))}
        </HStack>
      )}
    />
  );
};
