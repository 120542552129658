import * as yup from 'yup';
import { requiredError } from '../constans';
import { latitudeValidation } from 'models/schemas/location';

export const NsiReagentsPgrSchema = yup.object().shape({
  number: yup.number().nullable().required(requiredError),
  name: yup.string().nullable().required(requiredError),
  pgrSourceTypeId: yup.string().nullable().required(requiredError),
  startDate: yup.string().nullable().required(requiredError),

  okrugId: yup.string().nullable().required(requiredError),
  rayonId: yup.string().nullable().required(requiredError),
  address: yup.string().nullable().required(requiredError),
  landmark: yup.string().nullable().required(requiredError),
  ...latitudeValidation,
});
