import styled from 'styled-components/macro';

export const DateInputContainer = styled.div`
  position: relative;

  width: 100%;

  .react-datepicker-wrapper {
    width: 100%;
  }

  .react-datepicker__input-time-container {
    text-align: center;
    margin: 0 0 16px 0;
    padding-top: 12px;
    border-top: 1px solid #d4d9dc;

    .react-datepicker-time__caption {
      display: none;
    }

    .react-datepicker-time__input-container .react-datepicker-time__input {
      margin-left: 0;

      input {
        outline: none;
        width: 24px;

        ::placeholder {
          color: black;
        }
      }
    }
  }

  .react-datepicker__day--selected {
    border-radius: 2px;
    background: #f5faff;
    color: #208cff;
    border-color: #f5faff;
  }

  .react-datepicker__day--keyboard-selected {
    border-radius: inherit;
    background-color: inherit;
    color: inherit;
  }

  .react-datepicker__day {
    border-radius: 2px;
    line-height: 35px;
    margin: 5px 12px;
  }

  .react-datepicker__day:hover {
    border-radius: 2px;
    background: #f5faff;
    color: #208cff;
  }

  .react-datepicker-popper {
    z-index: 11;
    left: -1px !important;
    width: fit-content;
  }

  .react-datepicker__navigation {
    outline: none;
  }

  .react-datepicker__month-container {
    float: none;
  }

  .react-datepicker__day--outside-month {
    color: #6e7478;
  }

  .react-datepicker {
    background-color: #fff;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    border: none;
    border-radius: unset;
    font-size: 16px;
  }

  .react-datepicker__triangle {
    display: none;
  }

  .react-datepicker__day {
    margin: 5px 16px;
    outline: none;
  }

  .react-datepicker__day--today {
    border-bottom: 2px solid orange;
    font-weight: bold;
  }

  .react-datepicker__day-name {
    margin: 8px 16px;
    height: 24px;
    text-transform: capitalize;
    color: #6e7478;

    &:nth-child(6),
    &:nth-child(7) {
      color: #ff3434;
    }
  }

  .react-datepicker__day-names {
    height: 40px;
    border-color: #d4d9dc;
    background-color: #f2f8ff;
  }

  .react-datepicker__header {
    background-color: transparent;
    padding-top: 0;
  }

  .react-datepicker__current-month {
    padding: 20px 190px 20px 40px;
    text-align: left;
    text-transform: capitalize;
  }

  .react-datepicker__today-button {
    border-radius: 2px;
    position: absolute;
    top: 15px;
    right: 75px;
    padding: 7px 15px;
    font-size: 13px;
    font-weight: normal;
    font-family: 'Proxima';
    line-height: 1.1;
    background-color: #fff;
    border: 1px solid #d4d9dc;

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }

  .react-datepicker__navigation {
    top: 5px;
    width: 30px;
    height: 30px;
    border: 1px solid #d4d9dc;
    border-radius: 2px;
    align-items: center;
    justify-content: center;
    display: flex;
  }

  .react-datepicker__navigation--next {
    &:hover {
      &::after {
        border-color: #208cff;
      }
    }

    &::after {
      content: '';
      border: solid #000000;
      border-width: 0 2px 2px 0;
      display: inline-block;
      padding: 3px;
      transform: rotate(-45deg);
    }
  }

  .react-datepicker__navigation--previous {
    &:hover {
      &::after {
        border-color: #208cff;
      }
    }

    &::after {
      content: '';
      border: solid #000000;
      border-width: 0 2px 2px 0;
      display: inline-block;
      padding: 3px;
      transform: rotate(135deg);
    }
  }

  .react-datepicker-popper.month {
    .react-datepicker__navigation--next {
      right: 40px;
      padding-right: 3px;
    }

    .react-datepicker__navigation--previous {
      left: auto;
      right: 164px;
      padding-left: 3px;
    }
  }

  .react-datepicker-year-header {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .react-datepicker__day--weekend {
    color: #6e7478;
  }
`;
