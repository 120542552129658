import { WPZParameterKeys } from 'pages/data/Water/WPZRoot/WPZ/consts/types';

export const wpzInitialEmptyValues = {
  areaId: null,
  date: '',
  siteConditionsId: null,
  district: '',
  region: '',
  oopt: '',
  ...WPZParameterKeys.reduce((obj, key) => Object.assign(obj, { [key]: '' }), {}),
};
