import React from 'react';
import { createTouchedErrors } from 'features/create-touched-errors';
import { FormikProvider, useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import { FieldsForm } from './FieldsForm';
import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import appLocale from 'constants/appLocale';
import { configError, DataSourcesList, FTPRootPath, FTPSettings } from 'pages/admin/FTP';
import { baseAdminUrl } from 'pages/admin';
import { useRequest } from 'hooks/useRequest';
import { FormHeader } from 'components/common';
import { ERROR_MESSAGES, SUCCESS_MESSAGES } from 'constants/messages';
import { useNotifications } from 'hooks/useNotifications';
import { DataSourceSchema } from 'models/schemas/admin/ftpSettings';
import { Box } from '@chakra-ui/react';

export const initialEmptyValues = {
  authLogin: '',
  name: '',
  direction: false,
  status: '',
  authPass: '',
  ftpCheckDuring: '',
  ftpPath: '',
  ip: '',
  isDeleted: false,
  port: '',
  type: '',
  cron: '',
  servers: [
    {
      serverId: null,
      order: 1,
    },
  ],
};

export const AddCatalog = () => {
  const { successNotify, errorNotify } = useNotifications();
  useUpdateBreadcrumbs([
    {
      breadcrumb: appLocale.admin.main,
      path: '/admin',
    },
    {
      breadcrumb: appLocale.admin.ftp,
      path: FTPRootPath,
    },
    {
      breadcrumb: appLocale.admin.ftpSettings,
      path: `${FTPSettings}/catalogs`,
    },
  ]);
  const onSuccess = () => {
    history.push(`${FTPSettings}/catalogs`);
    successNotify({
      key: 'file/add/success',
      message: SUCCESS_MESSAGES.CREATE,
    });
  };

  const onError = (error: any) => {
    /* Заглушка под определенную ошибку */
    if (error.descriptions[0].message === configError) {
      onSuccess();
      return;
    }
    errorNotify({
      key: 'file/add/error',
      message: `${ERROR_MESSAGES.CREATE}. ${error.descriptions[0].message}.`,
    });
  };

  const { post } = useRequest<DataSourcesList, any>(`${baseAdminUrl}/exchange-config`, onSuccess, onError);

  const history = useHistory();

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    validationSchema: DataSourceSchema,
    initialValues: initialEmptyValues,
    onSubmit: (values) => {
      post(values);
    },
  });

  return (
    <FormikProvider
      value={{
        ...formik,
        errors: createTouchedErrors(formik.errors, formik.touched),
      }}
    >
      <Box>
        <FormHeader url={`${FTPSettings}/catalogs`} header="Добавление нового каталога" />
        <FieldsForm
          onCancel={() => {
            history.push(`${FTPSettings}/catalogs`);
          }}
        />
      </Box>
    </FormikProvider>
  );
};
