import { Flex, Icon, Stack, Text } from '@chakra-ui/react';
import { ErrorIcon } from 'components/icons';
import { map } from 'lodash';
import { FilePreviewErrors } from 'models';

interface FilePreviewValidationProps {
  errors?: FilePreviewErrors;
}

export const FilePreviewValidation: React.FC<FilePreviewValidationProps> = ({ errors = [] }) => (
  <Stack my={2} fontWeight={500} color="text.secondary">
    {map(errors, (error, i) => (
      <Flex key={i} alignItems="center">
        <Icon as={ErrorIcon} stroke="text.error" />
        <Text ml={2} isTruncated color="text.error">
          {error.error}
        </Text>
      </Flex>
    ))}
  </Stack>
);
