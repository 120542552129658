import * as yup from 'yup';
import { numberError, requiredError } from '../constans';

export const meteoSchema = yup.object().shape({
  date: yup.string().nullable().required(requiredError),
  sourceId: yup.string().nullable().required(requiredError),
  period: yup.number().nullable().typeError(numberError),
  insideTemperature: yup.number().nullable().typeError(numberError),
  voltage: yup.number().nullable().typeError(numberError),
  coefficient1: yup.number().nullable().typeError(numberError),
  coefficient2: yup.number().nullable().typeError(numberError),
  coefficient3: yup.number().nullable().typeError(numberError),
  coefficient4: yup.number().nullable().typeError(numberError),
  door: yup.number().nullable().typeError(numberError),
  temperatureAvg: yup.number().nullable().typeError(numberError),
  temperatureMin: yup.number().nullable().typeError(numberError),
  temperatureMax: yup.number().nullable().typeError(numberError),
  airPressure: yup.number().nullable().typeError(numberError),
  rainfall: yup.number().nullable().typeError(numberError),
  humidityAvg: yup.number().nullable().typeError(numberError),
  humidityMax: yup.number().nullable().typeError(numberError),
  humidityMin: yup.number().nullable().typeError(numberError),
  windDirection: yup.number().nullable().typeError(numberError),
  windSpeedAvg: yup.number().nullable().typeError(numberError),
  windSpeedMax: yup.number().nullable().typeError(numberError),
  windSpeedMin: yup.number().nullable().typeError(numberError),
});
