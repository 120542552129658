import { ERROR_MESSAGES } from 'constants/messages';
import { createTouchedErrors } from 'features/create-touched-errors';
import { FormikProvider, useFormik } from 'formik';
import { useNotifications } from 'hooks/useNotifications';
import { useRequest } from 'hooks/useRequest';
import { useHistory } from 'react-router-dom';
import FieldsForm from './FieldsForm';
import React, { useCallback, useState } from 'react';
import { updateFiles } from 'api/services/media';
import { IUploadMedia } from 'components/common/FileUploadDragAndDrop/types';
import { useObjectType } from 'hooks/useObjectType';
import { WaterRoutesWPZ } from 'pages/data/Water/routes';
import { ApiError } from 'models';
import { formatErrorMessage } from 'lib/utils/formatErrorMessage';
import { useEnhancedNotification } from 'hooks';
import { WPZDto } from 'pages/data/Water/WPZRoot/WPZ/consts/types';
import { WaterWPZApiPaths } from 'pages/data/Water/WPZRoot/WPZ/consts/apiPaths';
import { WPZSchema } from 'models/schemas/water/wpz';
import { wpzInitialEmptyValues } from 'pages/data/Water/WPZRoot/WPZ/WPZForms/initialEmptyValues';
import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import { formBreadcrumbs } from 'pages/data/Water/WPZRoot/WPZ/consts/breadcrumbs';
import { useGoBackWithFallback } from 'lib/utils/goBackWithFallback';
import { useDictionary } from 'hooks/useDictionary';
import { LoaderItem } from '../../../../../../components/common';

export const WPZAdd = () => {
  useUpdateBreadcrumbs(formBreadcrumbs(WaterRoutesWPZ.add));
  const { backWithFallback } = useGoBackWithFallback();
  const { onSuccessCreate } = useEnhancedNotification();
  const { dictionaries, isDictionariesLoading } = useDictionary({ url: WaterWPZApiPaths.dictionaries });

  const history = useHistory();
  const { errorNotify } = useNotifications();
  const [media, setMedia] = useState<IUploadMedia[]>([]);

  const objectType = useObjectType(dictionaries);

  const onError = useCallback(
    (message?: ApiError | string | null) => {
      const msg = formatErrorMessage(message);
      errorNotify({
        key: msg || 'file/add/error',
        message: msg || ERROR_MESSAGES.CREATE,
      });
    },
    [errorNotify],
  );

  const onSuccess = useCallback(
    (response: WPZDto | null) => {
      if (response) {
        onSuccessCreate();

        updateFiles(response.id, objectType, media)
          .then(() => {
            backWithFallback(WaterRoutesWPZ.root);
          })
          .catch((error: Error) => {
            onError(error.message);

            history.replace(WaterRoutesWPZ.edit(response.id));
          });
      } else {
        onError();
      }
    },
    [backWithFallback, history, media, objectType, onError, onSuccessCreate],
  );

  const { post, isLoading: isPostLoading } = useRequest<WPZDto>(WaterWPZApiPaths.root, onSuccess, onError);

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    initialValues: wpzInitialEmptyValues,
    onSubmit: (values) => {
      void post(values);
    },
    validationSchema: WPZSchema,
  });

  if (isPostLoading || isDictionariesLoading) {
    return <LoaderItem />;
  }

  return (
    <FormikProvider
      value={{
        ...formik,
        errors: createTouchedErrors(formik.errors, formik.touched),
      }}
    >
      <FieldsForm
        dictionaries={dictionaries}
        onCancel={() => {
          backWithFallback(WaterRoutesWPZ.root);
        }}
        onMediaChange={setMedia}
        media={media}
      />
    </FormikProvider>
  );
};
