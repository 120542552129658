import { useParams } from 'react-router-dom';
import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import { useNotifications, useRequest } from 'hooks';
import { ERROR_MESSAGES, SUCCESS_MESSAGES } from 'constants/messages';
import React, { useEffect } from 'react';
import { FormikProvider, useFormik } from 'formik';
import { FormHeader, LoaderItem } from 'components/common';
import { createTouchedErrors } from 'features/create-touched-errors';
import { AddFormFields } from './AddFormFields';
import { NsiPDVSchema } from 'models/schemas/nsiEmissions/NsiPDVSchema';
import {
  Industrial_PDV_AddPath,
  Industrial_PDV_Breadcrumbs,
  Industrial_PDV_EditPath,
  Industrial_PDV_RootPath,
  PDV_ApiPath,
} from '../consts';
import { IPDVItem } from '../types';
import { useGoBackWithFallback } from 'lib/utils/goBackWithFallback';

export const AddPDV = () => {
  const { backWithFallback } = useGoBackWithFallback();
  const { id } = useParams<{ id: string }>();
  useUpdateBreadcrumbs([
    ...Industrial_PDV_Breadcrumbs,
    {
      breadcrumb: `${id ? 'Редактирование' : 'Добавление'} ПДВ`,
      path: id ? `${Industrial_PDV_EditPath}/${id}` : Industrial_PDV_AddPath,
    },
  ]);

  const { successNotify, errorNotify } = useNotifications();

  const onSuccess = () => {
    successNotify({
      key: `nmu/${id ? 'edit' : 'add'}/success`,
      message: id ? SUCCESS_MESSAGES.EDIT : SUCCESS_MESSAGES.CREATE,
    });
    backWithFallback(Industrial_PDV_RootPath);
  };
  const onError = () =>
    errorNotify({
      key: `nmu/${id ? 'edit' : 'add'}/error`,
      message: id ? ERROR_MESSAGES.EDIT : ERROR_MESSAGES.CREATE,
    });

  const { post, isLoading: isPostLoading } = useRequest(PDV_ApiPath, onSuccess, onError);
  const { get, result, isLoading: isGetLoading } = useRequest<IPDVItem>(`${PDV_ApiPath}/${id}`);
  const { put, isLoading: isPutLoading } = useRequest(`${PDV_ApiPath}/${id}`, onSuccess, onError);

  useEffect(() => {
    if (id) {
      get();
    }
  }, [id, get]);

  const initialValues = {
    endDate: result?.endDate || '',
    error: result?.error || null,
    industryId: result?.industryId || null,
    nmu1: result?.nmu1 || null,
    nmu2: result?.nmu2 || null,
    nmu3: result?.nmu3 || null,
    oneTimeEmission: result?.oneTimeEmission || null,
    parameterId: result?.parameterId || '',
    sourceId: result?.sourceId || '',
    startDate: result?.startDate || '',
    totalEmission: result?.totalEmission || null,
    totalPlusError: result?.totalPlusError || null,
  };

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    initialValues,
    validationSchema: NsiPDVSchema,
    onSubmit: (values) => {
      if (id) {
        put(values);
      } else {
        post(values);
      }
    },
  });

  if (isPutLoading || isPostLoading || isGetLoading) {
    return <LoaderItem />;
  }

  return (
    <>
      <FormHeader
        onBackClick={() => backWithFallback(Industrial_PDV_RootPath)}
        header={`${id ? 'Редактирование' : 'Добавление'} ПДВ`}
      />
      <FormikProvider
        value={{
          ...formik,
          errors: createTouchedErrors(formik.errors, formik.touched),
        }}
      >
        <AddFormFields />
      </FormikProvider>
    </>
  );
};
