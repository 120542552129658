import { SelectedCheckboxIcon, UnselectedCheckboxIcon } from 'components/icons';
import { createLocalDateDDMMYYYY } from 'lib/create-date';
import { map } from 'lodash';
import { TDictionaryItemDictionary } from 'models';
import React, { useMemo } from 'react';
import { ILawnDto } from './useLawnsList';
import { ColumnWithFilter } from 'lib/utils/tableFilter';
import { TableFilterTypeEnum } from 'components/table/filters/TableFilter';
import { parameterToParameterIdMap } from 'features/parametersMap';

type Tmap = TDictionaryItemDictionary<string | (Record<any, string> & string), string | (Record<any, string> & string)>;
type TResult = ColumnWithFilter<ILawnDto>;

export const lawnsTypesMap: Record<string, TableFilterTypeEnum> = {
  cereals: TableFilterTypeEnum.string,
  howManyCereals: TableFilterTypeEnum.string,
  countCereals: TableFilterTypeEnum.number,
  weeds: TableFilterTypeEnum.string,
  howManyWeeds: TableFilterTypeEnum.string,
  countWeeds: TableFilterTypeEnum.number,
  moss: TableFilterTypeEnum.string,
  howManyMosses: TableFilterTypeEnum.string,
  countMosses: TableFilterTypeEnum.number,
  projCover: TableFilterTypeEnum.number,
  baldPatches: TableFilterTypeEnum.number,
  lawnColor: TableFilterTypeEnum.string,
  squareWithYellowGrass: TableFilterTypeEnum.number,
  denseGrass: TableFilterTypeEnum.string,
  heightGrass: TableFilterTypeEnum.number,
  turfFelt: TableFilterTypeEnum.string,
  diseases: TableFilterTypeEnum.string,
  howManyDiseases: TableFilterTypeEnum.number,
  pests: TableFilterTypeEnum.string,
  howManyPests: TableFilterTypeEnum.number,
  otherDamage: TableFilterTypeEnum.string,
  garbage: TableFilterTypeEnum.string,
  garbageScope: TableFilterTypeEnum.string,
  decorativeLawns: TableFilterTypeEnum.string,
  microrelief: TableFilterTypeEnum.string,
  recommendations: TableFilterTypeEnum.string,
};

export const useLawnsListColumns = (
  dictionary?: TDictionaryItemDictionary<string | (Record<string, any> & string),
    string | (Record<string, any> & string)>[],
) => {
  const extra = useMemo(
    () => map<Tmap, TResult>(dictionary, (item) => {
      const name = item.parameterId && parameterToParameterIdMap[item.parameterId];
      return {
        title: item.title,
        field: `${item.parameterId}`,
        render(row) {
          if (item.parameterId) {
            let value = '';
            let checkboxEl: JSX.Element | null = null;
            if (Array.isArray(row.parameters[item.parameterId])) {
              // @ts-ignore
              const values = row.parameters[item.parameterId].map((param) => param.value).filter((val) => val !== 'true' && val !== 'false');
              if (values.length > 1) {
                value = values.map((item: string, index: number) => (<p key={index}>{`${item}, `}</p>));
              } else if (values.length === 1 && values[0]) {
                // eslint-disable-next-line prefer-destructuring
                value = values[0];
              } else {
                value = '';
              }
              // @ts-ignore
              const checkboxes = row.parameters[item.parameterId].filter((rowParam: any) => rowParam.value === 'true' || rowParam.value === 'false');
              if (checkboxes.length > 0) {
                checkboxEl = checkboxes[0].value === 'true' ?
                  React.createElement(SelectedCheckboxIcon) : React.createElement(UnselectedCheckboxIcon);
              }
            }
            if (checkboxEl) {
              return checkboxEl;
            }
            return value;
          }
        },
        filterType: name && lawnsTypesMap[name],
      };
    }), [dictionary],
  );

  return useMemo<Array<ColumnWithFilter<ILawnDto>>>(
    () => [
      {
        title: 'Дата обновления информации',
        field: 'date',
        render: (row) => {
          if (row.date) {
            return createLocalDateDDMMYYYY(row.date);
          }

          return '-';
        },
      },
      {
        title: 'Уникальный номер площадки',
        field: 'areaNumber',
        render: (row) => (row.areaNumber ? row.areaNumber : '-'),
      },
      {
        title: 'Округ',
        field: 'okrugName',
      },
      {
        title: 'Район',
        field: 'rayonName',
      },
      {
        title: 'Тип территории',
        field: 'areaTypeName',
      },
      {
        title: '№ Газона',
        field: 'lawnName',
        render: (row) => (row.lawnName ? row.lawnName : '-'),
        filterType: TableFilterTypeEnum.string,
      },
      ...extra,
    ],
    [extra],
  );
};
