import { useParams } from 'react-router-dom';
import React, { useCallback, useMemo } from 'react';
import { Flex, Heading } from '@chakra-ui/react';
import { Breadcrumb, PageHeader } from 'components/common';
import { useInitializerUploadSettings } from 'features/UploadedFileProvider/UploadFileSettings';
import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import { useUploadFileProvider } from 'features/UploadedFileProvider';
import { LoadPreviewToolbar } from 'features/LoadPreviewToolbar';
import appLocale from 'constants/appLocale';
import { IForecastPreviewItem } from 'pages/data/Meteo/Forecast/Data/types';
import { getTemplate } from 'pages/data/Meteo/Forecast/Data/ForecastLoadPreview/Views/getTemplate';
import { ForecastRootPath } from 'pages/data/Meteo/menu';

function ForecastLoadPreview() {
  const { typeId } = useParams<{ typeId: string }>();

  const { validate, apply, preview } = useUploadFileProvider<IForecastPreviewItem>();

  useUpdateBreadcrumbs([
    { breadcrumb: appLocale.common.data, path: '/data' },
    {
      breadcrumb: appLocale.subsystems.meteo,
      path: '/data/meteo',
    },
    {
      breadcrumb: 'Прогноз',
      path: ForecastRootPath,
    },
    {
      breadcrumb: 'Предварительный просмотр данных из файла',
      path: `${ForecastRootPath}/load/preview/${typeId}`,
    },
  ]);

  useInitializerUploadSettings(
    useMemo(() => {
      if (typeId) {
        return {
          typesPath: '/meteo/v10/forecast/load/types',
          previewPath: `/meteo/v10/forecast/load/preview/${typeId}`,
          validatePath: `/meteo/v10/forecast/load/validate/${typeId}`,
          applyPath: `/meteo/v10/forecast/load/apply/${typeId}`,
          redirectPath: ForecastRootPath,
        };
      }

      return {
        typesPath: '/meteo/v10/forecast/load/types',
        previewPath: '',
        validatePath: '',
        applyPath: '',
        redirectPath: ForecastRootPath,
      };
    }, [typeId])
  );

  const isAnyErrors = useMemo(
    () =>
      Boolean(
        preview?.result?.measures.filter((val) => val.hasErrors) &&
          preview?.result?.measures.filter((val) => val.hasErrors).length > 0
      ),
    [preview]
  );

  const onSubmit = useCallback(() => {
    if (apply?.handleApplyFilePreview) {
      apply.handleApplyFilePreview();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apply?.handleApplyFilePreview]);

  const Component = getTemplate(Number(typeId));

  return (
    <Flex flexDirection="column">
      <PageHeader>
        <Breadcrumb to={ForecastRootPath}>Вернуться назад</Breadcrumb>
        <Heading variant="h1">Предварительный просмотр загружаемых данных</Heading>
        <LoadPreviewToolbar
          isSaveDisabled={isAnyErrors}
          onSubmit={onSubmit}
          isLoading={Boolean(validate?.isLoading) || Boolean(apply?.isLoading)}
          fileName={preview?.result?.fileName ?? ''}
        />
      </PageHeader>
      {Component && <Component data={preview?.result?.measures} />}
    </Flex>
  );
}

export { ForecastLoadPreview };
