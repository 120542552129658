import React, { useMemo, useState } from 'react';
import { IFilterValues, SubsystemHeader } from 'components/common';
import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import { MaterialTable } from 'features/material-table/MaterialTableMini';
import { TFiltersArray } from 'models';
import { createLocalDateDDMMYYYYWithTime } from 'lib/create-date';
import { useList } from 'hooks/useList';
import appLocale from 'constants/appLocale';
import { IndustrialRootPath, MonitoringDataPath } from '..';
import { TodayFilter } from './IndustrialFilter';
import subsystemIdsMap from 'constants/subsystemIdsMap';
import { usePeriodsDict } from 'features/PeriodsButtons/usePeriodsDict';
import { Column } from '@material-table/core';
import { useDictionary } from 'hooks/useDictionary';
import { DEFAULT_PAGE_SIZE_NEW_TABLE } from '../../../../constants/tables';
import { FullPageWrapper } from '../../../../components/table/FullPageWrapper';
import { ExtensionToMimeTypeEnum } from 'lib/utils/files/getFileExtensionByMimeType';
import { useAvailability } from '../../../../hooks';

export interface ListItem {
  areaId: string;
  areaName: string;
  date: string;
  deleted: boolean;
  emissionId: string;
  emissionName: string;
  id: string;
  paramId: string;
  paramName: string;
  paramType: string;
  paramUnit: string;
  paramValue: string;
  periodDate: string;
}

const IndustrialEmissionsMonitoring: React.FC = () => {
  useUpdateBreadcrumbs([
    { breadcrumb: appLocale.common.data, path: '/data' },
    {
      breadcrumb: 'Мониторинг пром. выбросов',
      path: IndustrialRootPath,
    },
    {
      breadcrumb: 'Данные мониторинга',
      path: MonitoringDataPath,
    },
  ]);

  const { isExecute } = useAvailability(subsystemIdsMap.emissions);

  const { dictionaries } = useDictionary({ url: '/industrialemissions/v10/emissions/dictionaries' });
  const {
    isLoading: isEmissionsListLoading,
    response,
    setFilters,
    toggleOrder,
    onPageChange,
    filterDTO,
    downloadAsFile,
    saveFilters,
    errorText,
    materialTableProps,
  } = useList<ListItem>('/industrialemissions/v10/emissions/list', {
    initialOrderFieldKey: '-periodDate',
    exportSubsystemName: 'industrial',
    preventFirstFetch: true,
    downloadUrl: {
      [ExtensionToMimeTypeEnum.xlsx]: '/industrialemissions/v10/emissions/list/export',
      [ExtensionToMimeTypeEnum.csv]: '/industrialemissions/v10/emissions/list/export',
    },
    defaultPageSize: DEFAULT_PAGE_SIZE_NEW_TABLE,
    resetDataOnNextRequest: true,
  });

  const { result: periods } = usePeriodsDict(`auth/v10/user/period/${subsystemIdsMap.emissions}`);

  const filteredPeriods = useMemo(() => periods?.filter((item) => item.code !== 'FOREVER'), [periods]);

  const [filtersFromGraphic, setFiltersFromGraphic] = useState<Partial<IFilterValues> | null>(null);

  const columns = useMemo<Column<ListItem>[]>(
    () => [
      {
        title: 'Предприятие',
        field: 'areaName',
      },
      {
        title: 'Источник выброса',
        field: 'emissionSourceName',
      },
      {
        title: 'Выход из котла',
        field: 'emissionNumber',
        render: (row) => row.emissionName,
      },
      {
        title: 'Параметр',
        field: 'paramName',
      },
      {
        title: 'Время измерения',
        field: 'date',
        render: (row) => createLocalDateDDMMYYYYWithTime(row.date),
      },
      {
        title: 'Тип измерения',
        field: 'paramType',
      },
      {
        title: 'Значение',
        field: 'paramValue',
      },
      {
        title: 'ед. изм',
        field: 'paramUnit',
      },
    ],
    [],
  );

  return (
    <FullPageWrapper>
      <SubsystemHeader
        title="Мониторинг промышленных выбросов в атмосферу"
        filtersComponent={
          <TodayFilter
            periods={filteredPeriods || []}
            filters={filterDTO?.filters || []}
            filtersFromGraphic={filtersFromGraphic}
            setFiltersFromGraphic={setFiltersFromGraphic}
            dictionaries={dictionaries}
            onSubmit={(values) => {
              const filters: TFiltersArray = Object.values(values);
              setFilters(filters);
            }}
          />
        }
      />

      <MaterialTable
        saveFilters={saveFilters}
        filterDTO={filterDTO}
        data={response?.data}
        meta={response?.meta}
        toggleOrder={toggleOrder}
        onPageChange={onPageChange}
        isLoading={isEmissionsListLoading}
        columns={columns}
        downloadAsFile={downloadAsFile}
        errorText={errorText}
        {...materialTableProps}
        showTotalCount
        isNotExecute={isExecute}
      />
    </FullPageWrapper>
  );
};

export { IndustrialEmissionsMonitoring };
