import React, { useMemo, useState } from 'react';
import { DataHeader, Filters } from 'pages/data/MASKSH/Data/components';
import { IFilterValues, Modal } from 'components/common';
import {
  MASKSHApiNoiseList,
  MASKSHApiPackageNoise,
  MASKSHInstructions,
  MASKSHNoise,
  MASKSHNoiseAdd,
  MASKSHNoiseEdit,
  MASKSHNoisePackages,
} from 'pages/data/MASKSH/consts/menu';
import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import { useNoiseDictionaries } from 'pages/data/MASKSH/Data/Noise/hooks/useNoiseDictionaries';
import subsystemIdsMap from 'constants/subsystemIdsMap';
import { usePeriodsDict } from 'features/PeriodsButtons/usePeriodsDict';
import { noiseBreadcrumbs } from 'pages/data/MASKSH/Data/const';
import { MaterialTable } from 'features/material-table/MaterialTableMini';
import { TableTabs } from 'pages/data/MASKSH/Data/components/TableTabs';
import { useHistory } from 'react-router-dom';
import { useAvailability, useDisclosure, useList } from 'hooks';
import { INoiseMeasurement, tableLocale } from 'pages/data/MASKSH/consts';
import { Column } from '@material-table/core';
import { TableFilterTypeEnum } from 'components/table/filters/TableFilter';
import { DateTimeTableCell } from 'pages/data/MASKSH/Data/components/DateTimeTableCell';
import { addFiltersToColumns } from 'lib/utils/tableFilter';
import { COMMON_LOCALE } from 'constants/common';
import { UploadFileModalContent } from 'features/UploadFileDragAndDrop/UploadFileModalContent';
import { EditIcon } from 'components/icons';
import { useQuery } from 'hooks/useQuery';
import { getFilteredQuery } from '../../hooks';
import { FullPageWrapper } from '../../../../../components/table/FullPageWrapper';
import { DEFAULT_PAGE_SIZE_NEW_TABLE } from '../../../../../constants/tables';

const Noise = () => {
  const query = useQuery();
  const { isExecute, isWrite } = useAvailability(subsystemIdsMap.masksh);
  useUpdateBreadcrumbs(noiseBreadcrumbs);
  const { push } = useHistory();
  const { stations, parameters, objects, isLoading: isDictionariesLoading, packageReceived } = useNoiseDictionaries();

  const [filtersFromGraphic, setFiltersFromGraphic] = useState<Partial<IFilterValues> | null>(null);
  const { result: periods } = usePeriodsDict(`auth/v10/user/period/${subsystemIdsMap.masksh}`);

  const {
    isLoading: isListLoading,
    response,
    setFilters,
    toggleOrder,
    onPageChange,
    filterDTO,
    downloadAsFile,
    onSearchText,
    errorText,
    savedFiltersFromCache,
    refetch,
    selectedIndexes,
    renderOnlySelectedCheckbox,
    onSubmitFilters,
    onSelectionChange,
    materialTableProps,
    saveFilters,
  } = useList<INoiseMeasurement, keyof INoiseMeasurement>(MASKSHApiNoiseList, {
    initialOrderFieldKey: '-packageDate',
    exportSubsystemName: 'masksh_noise',
    preventFirstFetch: true,
    saveFiltersState: true,
    selectionKey: 'valueId',
    defaultPageSize: DEFAULT_PAGE_SIZE_NEW_TABLE,
    resetDataOnNextRequest: true,
  });

  const columns = useMemo<Array<Column<INoiseMeasurement>>>(
    () => [
      {
        title: tableLocale.columns.recieved,
        field: 'received',
        filterType: TableFilterTypeEnum.select,
        render: (row) => packageReceived?.find((item) => item.value === row.received)?.name,
      },
      {
        title: tableLocale.columns.user,
        field: 'user',
        filterType: TableFilterTypeEnum.string,
      },
      {
        title: tableLocale.columns.packageNumber,
        field: 'packageNumber',
        filterType: TableFilterTypeEnum.number,
      },
      {
        title: tableLocale.columns.packageDate,
        field: 'packageDate',
        render: (row) => <DateTimeTableCell inline datestring={row.packageDate} />,
      },
      {
        title: tableLocale.columns.stationId,
        field: 'stationName',
        filterType: TableFilterTypeEnum.string,
      },
      {
        title: tableLocale.columns.placeId,
        field: 'placeName',
        filterType: TableFilterTypeEnum.string,
      },
      {
        title: tableLocale.columns.parameterId,
        field: 'parameterName',
        filterType: TableFilterTypeEnum.string,
      },
      {
        title: tableLocale.columns.type,
        field: 'type',
        render: (row) => row.type || '-',
        filterType: TableFilterTypeEnum.string,
      },
      {
        title: tableLocale.columns.unitId,
        field: 'unitName',
        filterType: TableFilterTypeEnum.string,
      },
      {
        title: tableLocale.columns.measureDate,
        field: 'measureDate',
        render: (row) => <DateTimeTableCell inline datestring={row.measureDate} />,
      },
      {
        title: tableLocale.columns.valueStr,
        // valueStr is better for render, sorting is working only by value
        field: 'value',
        render: (row) => row.valueStr,
        filterType: TableFilterTypeEnum.number,
      },
      {
        title: tableLocale.columns.instructionNumber,
        field: 'instructionNumber',
        filterType: TableFilterTypeEnum.string,
        render: (row) => (
          <button
            type="button"
            onClick={() => push(`${MASKSHInstructions}/view/${row.instructionId}`, { url: MASKSHNoise })}
            style={{
              color: 'blue'
            }}
          >
            {row.instructionNumber}
          </button>
        )
      },
    ],
    [packageReceived, push],
  );

  const selectOptions = useMemo(() => {
    const map = new Map();
    map.set('received', packageReceived);
    return map;
  }, [packageReceived]);

  const columnsWithFilters = useMemo<Array<Column<INoiseMeasurement>>>(
    () => addFiltersToColumns({ filters: filterDTO.filters, setFilters, columns, selectOptions }),
    [filterDTO.filters, setFilters, columns, selectOptions],
  );

  const isLoading = useMemo(() => isDictionariesLoading || isListLoading, [isDictionariesLoading, isListLoading]);

  const { isOpen, onClose, onOpen } = useDisclosure();

  return (
    <FullPageWrapper>
      <DataHeader
        addPath={MASKSHNoiseAdd}
        onOpenUpload={onOpen}
        defaultFiltersOpen={!!savedFiltersFromCache}
        isWrite={isWrite}
      >
        <Filters
          filters={filterDTO.filters}
          periods={periods}
          filtersFromGraphic={filtersFromGraphic}
          setFiltersFromGraphic={setFiltersFromGraphic}
          isLoading={isLoading}
          isDisabled={isLoading}
          objects={objects}
          stations={stations}
          parameters={parameters}
          showParameterFilter
          onSubmit={(values) => {
            onSubmitFilters(Object.values(values));
          }}
        >
          {renderOnlySelectedCheckbox}
        </Filters>
      </DataHeader>

      <MaterialTable
        isNotExecute={isExecute}
        topBarLeftContent={
          <TableTabs
            tabs={[
              {
                name: 'Пакеты',
                path: MASKSHNoisePackages,
                query: getFilteredQuery(query),
              },
              {
                name: 'Значения измерений',
                path: MASKSHNoise,
                query: getFilteredQuery(query),
              },
            ]}
          />
        }
        data={response?.data}
        meta={response?.meta}
        filterDTO={filterDTO}
        toggleOrder={toggleOrder}
        onPageChange={onPageChange}
        columns={columnsWithFilters}
        actions={[
          {
            position: 'row',
            action: (row: INoiseMeasurement) => ({
              icon: () => <EditIcon />,
              onClick: () => push(`${MASKSHNoiseEdit}/${row.packageId}`),
              tooltip: 'Редактировать',
              hidden: !isWrite || !row.isEditable,
            }),
          },
        ]}
        isLoading={isLoading || isDictionariesLoading}
        options={{
          search: true,
        }}
        onSearch={onSearchText}
        downloadAsFile={downloadAsFile}
        errorText={errorText}
        selectedIndexes={selectedIndexes}
        onSelectionChange={onSelectionChange}
        saveFilters={saveFilters}
        {...materialTableProps}
        showTotalCount
      />

      <Modal isOpen={isOpen} onClose={onClose} header={COMMON_LOCALE.uploadDataFromFile}>
        <UploadFileModalContent
          createTypesPath={() => `${MASKSHApiPackageNoise}/load/types`}
          createUploadPath={(typeId) => `${MASKSHApiPackageNoise}/load?typeId=${typeId}`}
          onClose={onClose}
          refetch={refetch}
        />
      </Modal>
    </FullPageWrapper>
  );
};

export { Noise };
