import { SelectOption } from 'components/common';
import { Filter } from 'features/Filter';
import { createInitialValuesToFormik, createValuesToRequest } from 'features/Filter/utils';
import React, { useMemo } from 'react';
import { TProtocolActinometryFilterDTO } from 'pages/data/Meteo/Protocol/ProtocolActinometry/types';
import appLocale from 'constants/appLocale';
import { IPeriod } from 'features/PeriodsButtons/types';
import { IFilterItem } from 'models';
import combineFilters from 'lib/utils/combineFilters';
import { SchemaForCustomFilter } from 'models/schemas/filtersDatepickersSchema/FiltersDatepickersSchema';

type FilterInitialValues = Record<
  'dateFrom' | 'dateTo' | 'sourceId' | 'parameterId' | 'deleted' | 'periodId' | 'showOnlyChangedRows',
  TProtocolActinometryFilterDTO['filters'][number]
>;

const initialValues: FilterInitialValues = {
  dateFrom: {
    key: 'valueDate',
    operation: 'GTE',
    value: '',
  },
  dateTo: {
    key: 'valueDate',
    operation: 'LTE',
    value: '',
  },
  parameterId: {
    key: 'parameterId',
    operation: 'EQ',
    value: '',
  },
  sourceId: {
    key: 'sourceId',
    operation: 'EQ',
    value: '',
  },
  deleted: {
    key: 'deleted',
    operation: 'EQ',
    value: false
  },
  periodId: {
    key: 'periodId',
    operation: 'EQ',
    value: null,
  },
  showOnlyChangedRows: {
    key: 'showOnlyChangedRows',
    operation: 'EQ',
    value: false,
  }
};

interface Props {
  isLoading: boolean;
  onSubmit: (values: FilterInitialValues) => void;
  stations: SelectOption[];
  parameters: SelectOption[];
  periods: IPeriod[] | null;
  filters: IFilterItem[];
}

const initialValuesToFormik = createInitialValuesToFormik(initialValues);

function ProtocolActinometryFilter({
  isLoading,
  onSubmit,
  children,
  stations,
  periods,
  parameters,
  filters
}: React.PropsWithChildren<Props>) {
  const presetValues = useMemo<{ key: string, value: IFilterItem['value'] }[]>(() => {
    const preset: { key: string, value: IFilterItem['value'] }[] = [];
    const initialFilters = combineFilters(initialValues, filters);
    Object.keys(initialFilters).forEach((key) => {
      preset.push({ key: `${key}`, value: initialFilters[key].value });
    });
    return preset;
  }, [filters]);

  return (
    <Filter<typeof initialValuesToFormik>
      dateFieldFrom="dateFrom"
      dateFieldTo="dateTo"
      periods={periods}
      isLoading={isLoading}
      isDisabled={isLoading}
      onSubmit={(values) => {
        onSubmit(createValuesToRequest(initialValues, values));
      }}
      initialValues={initialValuesToFormik}
      mainFields={[
        {
          type: 'DATE',
          name: 'dateFrom',
          label: appLocale.common.from,
          showTimeInput: true,
        },
        {
          type: 'DATE',
          name: 'dateTo',
          label: appLocale.common.to,
          showTimeInput: true,
        },
        {
          type: 'SELECT',
          name: 'sourceId',
          label: 'Станция',
          options: stations,
        },
        {
          type: 'SELECT',
          name: 'parameterId',
          label: 'Параметр',
          options: parameters,
        },
        {
          type: 'CHECKBOX',
          name: 'showOnlyChangedRows',
          label: appLocale.common.showOnlyChangedRows,
        },
        {
          type: 'CHECKBOX',
          name: 'deleted',
          label: appLocale.common.showDeleted,
        },
      ]}
      savedPeriodId={filters.find((item) => item.key === 'periodId')?.value as string || ''}
      presetValues={presetValues}
      validationSchema={() =>
        SchemaForCustomFilter([{ dateFromFieldName: 'dateFrom', dateToFieldName: 'dateTo' }])
      }
    >
      {children}
    </Filter>
  );
}

export { ProtocolActinometryFilter };
