import React from 'react';
import { Flex, Grid } from '@chakra-ui/react';
import { Button, DateInput, DefaultText, Input, Modal, Select } from 'components/common';
import { Field, useFormikContext } from 'formik';
import { Complaint, PreparedAnswer } from 'pages/citizenAppeal';
import { useDisclosure } from 'hooks/useDisclosure';
import { InfoIcon } from 'components/icons';

interface Props {
  complaint: Complaint | null;
  complaintComment?: string;
  isLoading: boolean;
  onClose: () => void;
}

export const ResponseAnswer = ({
  complaint,
  complaintComment,
  isLoading,
  onClose: onCloseModal
}: Props) => {
  const { handleSubmit, errors } = useFormikContext<PreparedAnswer>();
  const templateTypes = [{ name: 'С заголовком', value: 'WITH_HEADER' }, { name: 'Без заголовка', value: 'WITHOUT_HEADER' }];
  const { onOpen, isOpen, onClose } = useDisclosure();

  return (
    <Flex flexDirection="column" width="100%">
      <DefaultText fontWeight="bold">Подготовка ответа на обращение</DefaultText>
      <Grid gap={3} templateRows="repeat(7, 1fr)">
        <Field
          as={Input}
          disabled
          label="Комментарии к обращению:"
          name="complaintComment"
          value={complaintComment}
        />
        <Field
          as={Select}
          options={templateTypes}
          name="templateType"
          label="Выбрать шаблон ответа:"
          error={errors?.templateType}
        />
        <Field
          label="ФИО Заявителя:"
          as={Input}
          name="complaintCitizenFio"
          disabled
          value={complaint?.complaintCitizenFio}
        />
        <Field
          label="Номер обращения"
          as={Input}
          name="complaintNumber"
          disabled
          value={complaint?.complaintNumber}
        />
        <Field
          as={DateInput}
          name="complaintDate"
          label="Дата и время обращения"
          isDisabled
          value={complaint?.complaintDate}
        />
        <Field
          as={Input}
          name="templateQuestion"
          label="Формулировка вопроса"
          error={errors?.templateQuestion}
        />
        <Field
          as={Input}
          name="templateAnswer"
          label="Сутевая часть ответа"
          error={errors?.templateAnswer}
        />
        <Field
          as={Input}
          name="employeePosition"
          label="Должность"
          error={errors?.employeePosition}
        />
        <Field
          as={Input}
          name="employeeFio"
          label="ФИО"
          error={errors?.employeeFio}
        />
        <Field
          as={Input}
          name="assigmentExecutorPhone"
          label="Телефон"
          error={errors?.assigmentExecutorPhone}
        />
      </Grid>
      <Button
        leftIcon={<InfoIcon />}
        marginTop={3}
        marginLeft={2}
        variant="link"
        onClick={() => onOpen()}
      >
        Посмотреть шаблон документа
      </Button>
      <Flex>
        <Button
          marginRight={3}
          onClick={() => {
            onCloseModal();
          }}
        >
          Отмена
        </Button>
        <Button
          isLoading={isLoading}
          onClick={() => {
            handleSubmit();
            // setAnswerValue(`answers[${index}].answerText`, values.templateAnswer);
          }}
        >
          Сохранить
        </Button>
      </Flex>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
      >
        <img src="/prepared-answer.png" alt="Шаблон" />
      </Modal>
    </Flex>
  );
};
