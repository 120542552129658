import { useMemo } from 'react';
import { FormikProvider, useFormik } from 'formik';

import { createTouchedErrors } from 'features/create-touched-errors';
import { useNotifications } from 'hooks/useNotifications';
import { useRequest } from 'hooks/useRequest';
import { ERROR_MESSAGES, SUCCESS_MESSAGES } from 'constants/messages';

import { MeteoPackageFormFields } from 'pages/data/MASKSH/Data/Meteo/MeteoPackageForm/Form';
import { MASKSHApiPackageMeteoCreate, MASKSHMeteo } from 'pages/data/MASKSH/consts';
import { useMASKSHDictionary } from 'pages/data/MASKSH/hooks';
import { initialEmptyValues } from 'pages/data/MASKSH/Data/Meteo/MeteoPackageForm/configs';
import { ApiError } from 'models';
import { formatErrorMessage } from 'lib/utils/formatErrorMessage';
import { useGoBackWithFallback } from 'lib/utils/goBackWithFallback';
import { meteoSchema } from 'models/schemas/MASKSH/meteoSchema';
import { replaceEmptyStringsWithNull } from 'lib/utils/replaceEmptyStringsWithNull';

interface IProps {
  listPath?: string;
}

export const AddMeteoPackageForm = ({ listPath = MASKSHMeteo }: IProps) => {
  const { backWithFallback } = useGoBackWithFallback();
  const { isLoading, result } = useMASKSHDictionary('meteo');
  const { successNotify, errorNotify } = useNotifications();

  const onSuccess = () => {
    successNotify({
      key: 'meteoPackage/add/success',
      message: SUCCESS_MESSAGES.CREATE,
    });
    backWithFallback(listPath);
  };
  const onError = (error: ApiError | string | null) => {
    const message = formatErrorMessage(error);
    errorNotify({
      key: message || 'meteoPackage/add/error',
      message: message || ERROR_MESSAGES.CREATE,
    });
  };

  const { post, isLoading: isPostLoading } = useRequest(MASKSHApiPackageMeteoCreate, onSuccess, onError);

  const useFormikProps = useMemo(
    () => ({
      enableReinitialize: true,
      validateOnChange: true,
      validateOnMount: true,
      validateOnBlur: true,
      validationSchema: meteoSchema,
      initialValues: initialEmptyValues,
      onSubmit: (values: any) => {
        void post(replaceEmptyStringsWithNull(values));
      },
    }),
    [post],
  );

  const formik = useFormik(useFormikProps);

  return (
    <div>
      <FormikProvider
        value={{
          ...formik,
          errors: createTouchedErrors(formik.errors, formik.touched),
        }}
      >
        <MeteoPackageFormFields listPath={listPath} isLoading={isLoading || isPostLoading} dictionaries={result} />
      </FormikProvider>
    </div>
  );
};
