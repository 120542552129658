import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { IFilterValues } from 'components/common';
import { ConfirmModal, Modal } from 'components/common/Modals';
import { ERROR_MESSAGES, REMOVE_CONFIRMATION, SUCCESS_MESSAGES } from 'constants/messages';
import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import { getSelectOptionsFromDictionaryByName } from 'features/get-select-options-from-dictionary';
import { MaterialTable } from 'features/material-table/MaterialTableMini';
import { useAvailability, useDisclosure, useEnhancedNotification } from 'hooks';
import { useNotifications } from 'hooks/useNotifications';
import { useRequest } from 'hooks/useRequest';
import { filter, map } from 'lodash';
import { TFiltersArray } from 'models';
import { useHistory } from 'react-router';
import { Column } from '@material-table/core';
import { useList } from 'hooks/useList';
import appLocale from 'constants/appLocale';
import { SnowFilter } from './SnowFilter';
import { SnowDeicingItem } from './useSnowDeicingList';
import { fieldsLocale } from './consts';
import { ReagentsApiRootPath, ReagentsRootPath } from '../consts';
import useFilePreviewModalWrapperData from 'components/common/FilePreviewModal/hooks/useFilePreviewModalWrapperData';
import { DeleteIcon, EditIcon, EyeViewIcon, ImageIcon } from 'components/icons';
import FilePreviewModalWrapper from 'components/common/FilePreviewModal/FilePreviewModalWrapper';
import { addFiltersToColumns, ColumnWithFilter } from 'lib/utils/tableFilter';
import { TableFilterTypeEnum } from 'components/table/filters/TableFilter';
import { UploadFileModalContent } from 'features/UploadFileDragAndDrop/UploadFileModalContent';
import subsystemIdsMap from 'constants/subsystemIdsMap';
import { PropActions } from 'features/material-table/components/Actions';
import { usePeriodsDict } from 'features/PeriodsButtons/usePeriodsDict';
import { downloadFileFromResponse } from 'lib/utils/files/downloadFile';
import { getSubsystemHumanReadableName, SubsystemName } from 'pages/analytics/consts/AnalyticsTableRoutes';
import { IAnalyticsItem } from 'pages/analytics/consts/types';
import { ExtensionToMimeTypeEnum } from 'lib/utils/files/getFileExtensionByMimeType';
import { HeaderWithAnalytics } from 'pages/data/components/HeaderWithAnalytics';
import { createLocalDateDDMMYYYYWithTime } from 'lib/create-date';
import { CellWithType } from 'components/table/CellWithType';
import { useDictionary } from 'hooks/useDictionary';
import { DEFAULT_PAGE_SIZE_NEW_TABLE } from '../../../../constants/tables';
import { FullPageWrapper } from '../../../../components/table/FullPageWrapper';
import { DefaultTableCellSize } from 'components/table/DefaultTableCellSize';

const Snow = memo(() => {
  const { isWrite, isExecute, isNoRights, allAvailable, isAvailable, isReadOnly } = useAvailability(
    subsystemIdsMap.pgrSnow
  );
  const [downloadReportItem, setDownloadReportItem] = useState<IAnalyticsItem>();
  const { onDownloadError } = useEnhancedNotification();
  const onFileResponseSuccess = useCallback(
    (response) => {
      if (response instanceof Blob && downloadReportItem) {
        downloadFileFromResponse({
          response,
          name: `Шаблон протокола_${getSubsystemHumanReadableName(SubsystemName.reagentsSnow)}`,
        });
        setDownloadReportItem(undefined);
      }
    },
    [downloadReportItem],
  );

  const _onDownloadError = useCallback(() => {
    onDownloadError();
    setDownloadReportItem(undefined);
  }, [onDownloadError]);

  const { get: downloadReport } = useRequest<Blob>('', onFileResponseSuccess, _onDownloadError);

  useEffect(() => {
    if (downloadReportItem) {
      void downloadReport({
        url: `/api/reporting/v10/templates/fill/snow/${downloadReportItem.registryId}`,
        headers: { ACCEPT: ExtensionToMimeTypeEnum.docx },
        responseType: 'blob',
      });
    }
  }, [downloadReport, downloadReportItem]);

  const history = useHistory();

  useUpdateBreadcrumbs([
    { breadcrumb: appLocale.common.data, path: '/data' },
    {
      breadcrumb: appLocale.subsystems.reagents,
      path: '/data/reagents',
    },
    {
      breadcrumb: 'Воздействие на снег',
      path: '/data/reagents/snow/main-data',
    },
  ]);

  const { dictionaries } = useDictionary({ url: '/deicing/v10/deicing/snow/dictionaries' });
  const { open, ...filePreviewData } = useFilePreviewModalWrapperData();
  const [removedItems, setRemovedItems] = useState('');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { successNotify, errorNotify } = useNotifications();

  const {
    refetch,
    isLoading: isSnowListLoading,
    response,
    toggleOrder,
    setFilters,
    onPageChange,
    filterDTO,
    downloadAsFile,
    saveFilters,
    onSearchText,
    errorText,
    selectedIndexes,
    renderOnlySelectedCheckbox,
    onSubmitFilters,
    onSelectionChange,
    materialTableProps,
  } = useList<SnowDeicingItem, keyof SnowDeicingItem>('/deicing/v10/deicing/snow/list', {
    initialOrderFieldKey: '-date',
    preventFirstFetch: true,
    exportSubsystemName: 'pgr_snow',
    selectionKey: 'registryId',
    saveFiltersState: true,
    defaultPageSize: DEFAULT_PAGE_SIZE_NEW_TABLE,
    resetDataOnNextRequest: true,
  });

  const { isOpen: isOpenLoad, onClose: onCloseLoad, onOpen: onOpenLoad } = useDisclosure();

  const onSuccess = () => {
    onClose();
    refetch();
    successNotify({
      key: 'file/delete/success',
      message: SUCCESS_MESSAGES.DELETE,
    });
  };

  const onError = () =>
    errorNotify({
      key: 'file/delete/error',
      message: ERROR_MESSAGES.DELETE,
    });

  const { remove } = useRequest('/atmosphere/v10/atmosphere', onSuccess, onError);

  const dictData = dictionaries?.data;
  const parameters = useMemo(() => getSelectOptionsFromDictionaryByName(dictData, 'parameters1'), [dictData]);

  const columns: Array<ColumnWithFilter<SnowDeicingItem>> = useMemo(
    () => [
      {
        title: fieldsLocale.date,
        field: 'date',
        render: (row) => createLocalDateDDMMYYYYWithTime(row.date),
      },
      {
        title: fieldsLocale.season,
        field: 'season',
        filterType: TableFilterTypeEnum.string,
      },
      {
        title: fieldsLocale.stage,
        field: 'stage',
        filterType: TableFilterTypeEnum.string,
      },
      {
        title: fieldsLocale.protocol,
        field: 'protocolNumber',
        filterType: TableFilterTypeEnum.string,
      },
      {
        title: fieldsLocale.pointId,
        field: 'distance',
        filterType: TableFilterTypeEnum.string,
      },
      {
        title: fieldsLocale.territoryType,
        field: 'territoryType',
        filterType: TableFilterTypeEnum.string,
      },
      {
        title: fieldsLocale.territorySubType,
        field: 'territorySubType',
        filterType: TableFilterTypeEnum.string,
      },
      {
        title: fieldsLocale.areaName,
        field: 'areaName',
      },
      {
        title: fieldsLocale.district,
        field: 'region',
        filterType: TableFilterTypeEnum.string,
      },
      {
        title: fieldsLocale.region,
        field: 'district',
        filterType: TableFilterTypeEnum.string,
      },
      {
        title: fieldsLocale.address,
        field: 'address',
        filterType: TableFilterTypeEnum.string,
        render: (row) => <DefaultTableCellSize>{row.address}</DefaultTableCellSize>,
      },

      ...map(parameters, ({ title, parameterId }) => ({
        title,
        field: parameterId,
        render: (row: SnowDeicingItem) => {
          const item = filter(row.parameters, (el) => el.parameterId === parameterId)[0];
          if (!item) {
            return null;
          }
          return <CellWithType type={item.type} value={item.value} />;
        },
        filterType: TableFilterTypeEnum.number,
      })),
    ],
    [parameters],
  );

  const columnsWithFilters = useMemo<Array<Column<SnowDeicingItem>>>(
    () => addFiltersToColumns({ filters: filterDTO.filters, setFilters, columns }),
    [columns, filterDTO.filters, setFilters],
  );

  const [filtersFromGraphic, setFiltersFromGraphic] = useState<Partial<IFilterValues> | null>(null);

  const onReportDownload = useCallback(async (_e, item) => {
    if (!Array.isArray(item)) {
      setDownloadReportItem(item);
    }
  }, []);

  const initialActions = useMemo<PropActions<SnowDeicingItem[]>>(
    () => [
      {
        position: 'row',
        action: (row: SnowDeicingItem) => ({
          icon: () => <EditIcon />,
          onClick: () => history.push(`${ReagentsRootPath}/snow/edit/row/${row.registryId}`),
          tooltip: 'Редактировать',
          hidden: row.laboratory === true || !row.isEditable,
        }),
      },
      {
        position: 'row',
        action: (row: SnowDeicingItem) => ({
          icon: () => <DeleteIcon />,
          onClick: () => {
            onOpen();
            setRemovedItems(row.registryId);
          },
          tooltip: 'Удалить',
          hidden: row.laboratory === true || !row.isEditable,
        }),
      },
      {
        position: 'row',
        action: (row: any) => ({
          icon: () => <ImageIcon />,
          onClick: () => open(row.registryId, row.objectType),
          tooltip: 'Показать изображения',
          hidden: !row.objectType || row.laboratory === true,
        }),
      },
      {
        position: 'row',
        action: (row) => ({
          icon: () => <EyeViewIcon />,
          onClick: onReportDownload,
          tooltip: 'Скачать',
          hidden: row.laboratory === null || row.laboratory === false,
        }),
      },
    ],
    [history, onOpen, open, onReportDownload],
  );

  // @ts-ignore
  const actions = useMemo<PropActions<any[]>>(() => {
    if (allAvailable || isNoRights || isWrite) {
      return initialActions;
    }
    if (!(allAvailable || isNoRights || isWrite)) {
      return initialActions.filter((action: any) => action.type !== 'delete');
    }
  }, [isWrite, initialActions, isNoRights, allAvailable]);

  const { result: periods } = usePeriodsDict(`auth/v10/user/period/${subsystemIdsMap.pgrSnow}`);

  return (
    <FullPageWrapper>
      <HeaderWithAnalytics
        allAvailable={allAvailable}
        isWrite={isWrite}
        isReadonly={isReadOnly}
        isExecute={isExecute}
        isNoRights={isNoRights}
        active="main-data"
        title="Мониторинг воздействия ПГР на снег"
        rootPath="/data/reagents/snow"
        mainDataTitle="Свои протоколы"
        analyticsDataTitle="Протоколы аналитической лаборатории"
        addButtonProps={{
          onClick: () => history.push(`${ReagentsRootPath}/snow/add`),
          isDisabled: !isAvailable,
        }}
        onOpenLoad={onOpenLoad}
      >
        <SnowFilter
          periods={periods}
          filtersFromGraphic={filtersFromGraphic}
          setFiltersFromGraphic={setFiltersFromGraphic}
          isLoading={isSnowListLoading}
          isDisabled={isSnowListLoading}
          dictionaries={dictionaries}
          onSubmit={(values) => {
            const filters: TFiltersArray<keyof SnowDeicingItem> = Object.values(values);
            onSubmitFilters(filters);
          }}
          filters={filterDTO.filters}
        >
          {renderOnlySelectedCheckbox}
        </SnowFilter>
      </HeaderWithAnalytics>
      <MaterialTable
        isNotExecute={isExecute || isNoRights}
        filterDTO={filterDTO}
        data={response?.data}
        meta={response?.meta}
        columns={columnsWithFilters}
        toggleOrder={toggleOrder}
        onPageChange={onPageChange}
        actions={actions}
        isLoading={isSnowListLoading}
        options={{
          search: true,
        }}
        onSearch={onSearchText}
        downloadAsFile={downloadAsFile}
        saveFilters={saveFilters}
        setFilters={setFilters}
        errorText={errorText}
        selectedIndexes={selectedIndexes}
        onSelectionChange={onSelectionChange}
        {...materialTableProps}
        showTotalCount
      />

      <ConfirmModal
        isOpen={isOpen}
        onClose={onClose}
        onClick={() =>
          remove({
            url: `/deicing/v10/deicing/snow/${removedItems}`,
          })
        }
        header={REMOVE_CONFIRMATION}
      />

      <Modal isOpen={isOpenLoad} onClose={onCloseLoad} header="Загрузка данных из файла">
        <UploadFileModalContent
          createTypesPath={() => `${ReagentsApiRootPath}/snow/load/types`}
          createUploadPath={() => `${ReagentsApiRootPath}/snow/load`}
          types={[
            {
              name: 'Данные ПГР (снег)',
              value: 'snow',
            },
          ]}
          onClose={onCloseLoad}
          refetch={refetch}
        />
      </Modal>
      <FilePreviewModalWrapper {...filePreviewData} />
    </FullPageWrapper>
  );
});

export { Snow };
