import { useRequest } from 'hooks/useRequest';

export interface GeoecologyDangerprocessPointData {
  code: string | null;
  deleted: boolean;
  geoDistrictId: string | null;
  id: string | null;
  latitude: number | null;
  longitude: number | null;
}

export function useGeoDangerProcessPointData() {
  const { get, result, error, isLoading } = useRequest<GeoecologyDangerprocessPointData>(
    '/geoecology/v10/geoecology/dangerprocess/pointdata',
  );
  return {
    get,
    result,
    error,
    isLoading,
  };
}

export default useGeoDangerProcessPointData;
