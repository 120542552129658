import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import { EquipmentLocale } from '../../locale';
import { EquipmentsAppRoutes } from '../../equipmentsAppRoutes';
import { modelsLocale } from '../locale';

export const useEquipmentModelsBreadcrumbs = () => {
  useUpdateBreadcrumbs([
    {
      breadcrumb: EquipmentLocale.root,
      path: EquipmentsAppRoutes.root,
    },
    {
      breadcrumb: modelsLocale.models,
      path: EquipmentsAppRoutes.models.root,
    },
  ]);
};
