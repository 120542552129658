import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import { SubsystemHeader } from 'components/common';
import { useHistory } from 'react-router-dom';
import { useList } from 'hooks';
import { MaterialTable } from 'features/material-table/MaterialTableMini';
import React, { useMemo } from 'react';
import { PropActions } from 'features/material-table/components/Actions';
import { Column } from '@material-table/core';
import { addFiltersToColumns, ColumnWithFilter } from 'lib/utils/tableFilter';
import { transformFiltersArray } from 'features/Filter/transformFiltersArray';
import appLocale from 'constants/appLocale';
import { NSIReagentsApiPath, NSIReagentsLawnsPaths, NSIReagentsRootPath } from 'pages/dictionaries/NSIReagents/paths';
import { useDictionary } from 'hooks/useDictionary';
import {
  NSIReagentsDictionaryKeys,
  NSIReagentsListItem,
  NSIReagentsListType,
} from 'pages/dictionaries/NSIReagents/types';
import { TableFilterTypeEnum } from 'components/table/filters/TableFilter';
import NSIReagentsLawnsFilters from 'pages/dictionaries/NSIReagents/NSIReagentsLawns/NSIReagentsLawnsFilters';
import { subsystemCodesMap } from 'constants/subsystemIdsMap';
import { useCustomRights } from 'hooks/useCustomRights';
import { FullPageWrapper } from '../../../../components/table/FullPageWrapper';
import { DEFAULT_PAGE_SIZE_NEW_TABLE } from '../../../../constants/tables';
import { DefaultTableCellSize } from '../../../../components/table/DefaultTableCellSize';

const NSIReagentsLawns = () => {
  useUpdateBreadcrumbs([
    { breadcrumb: appLocale.nsi.reagents.title, path: NSIReagentsRootPath },
    { breadcrumb: appLocale.nsi.reagents.lawns.title, path: NSIReagentsLawnsPaths.root },
  ]);
  const { push } = useHistory();

  const { isWrite } = useCustomRights(subsystemCodesMap.nsi.pgr.lawns);

  const { isDictionariesLoading, okrugs, rayons, statuses, areasForLawn } = useDictionary<NSIReagentsDictionaryKeys>({
    url: `${NSIReagentsApiPath}/dictionaries`,
  });

  const {
    isLoading: isListLoading,
    response,
    toggleOrder,
    setFilters,
    onPageChange,
    filterDTO,
    onSearchText,
    downloadAsFile,
    errorText,
    materialTableProps,
  } = useList<NSIReagentsListItem>(`${NSIReagentsApiPath}/list/${NSIReagentsListType.lawn}`, {
    initialOrderFieldKey: '-areaNumber',
    saveFiltersState: true,
    defaultPageSize: DEFAULT_PAGE_SIZE_NEW_TABLE,
    resetDataOnNextRequest: true,
  });

  const actions = useMemo<PropActions<NSIReagentsListItem[]>>(
    () => [
      {
        type: 'edit',
        onClick: (e, item: any) => {
          push(NSIReagentsLawnsPaths.edit(item.id));
        },
        disabled: !isWrite,
        hidden: !isWrite,
      },
    ],
    [isWrite, push]
  );

  const columns = useMemo<ColumnWithFilter<NSIReagentsListItem>[]>(
    () => [
      {
        title: 'Номер площадки',
        field: 'areaNumber',
      },
      {
        title: 'Номер газона',
        field: 'number',
      },
      {
        title: 'Округ',
        field: 'okrugId',
        render: (row) => okrugs?.find((source) => source.value === row.okrugId)?.name,
      },
      {
        title: 'Район',
        field: 'rayonId',
        render: (row) => rayons?.find((source) => source.value === row.rayonId)?.name,
      },
      {
        title: 'Адрес',
        field: 'address',
        render: (row) => <DefaultTableCellSize>{row.address}</DefaultTableCellSize>,
      },
      {
        title: 'Адресный ориентир',
        field: 'landmark',
        filterType: TableFilterTypeEnum.string,
        render: (row) => <DefaultTableCellSize>{row.landmark}</DefaultTableCellSize>,
      },
      {
        title: 'Статус',
        field: 'status',
        render: (row) => statuses?.find((source) => source.value === row.status)?.name,
      },
    ],
    [okrugs, rayons, statuses]
  );

  const columnsWithFilters = useMemo<Array<Column<NSIReagentsListItem>>>(
    () => addFiltersToColumns({ filters: filterDTO.filters, setFilters, columns }),
    [columns, filterDTO.filters, setFilters]
  );

  const isLoading = useMemo(() => isListLoading || isDictionariesLoading, [isDictionariesLoading, isListLoading]);

  return (
    <FullPageWrapper>
      <SubsystemHeader
        title={appLocale.nsi.reagents.lawns.title}
        addButtonProps={{
          onClick: () => push(NSIReagentsLawnsPaths.add),
          isDisabled: !isWrite,
        }}
        filtersComponent={
          <NSIReagentsLawnsFilters
            isLoading={isLoading}
            onSubmit={(val) => setFilters(transformFiltersArray(Object.values(val)))}
            okrugs={okrugs}
            rayons={rayons}
            statuses={statuses}
            areas={areasForLawn}
            filters={filterDTO.filters}
          />
        }
      />

      <MaterialTable
        data={response?.data}
        meta={response?.meta}
        toggleOrder={toggleOrder}
        filterDTO={filterDTO}
        onPageChange={onPageChange}
        columns={columnsWithFilters}
        actions={actions}
        isLoading={isLoading}
        setFilters={setFilters}
        options={{ search: true, selection: false }}
        onSearch={onSearchText}
        downloadAsFile={downloadAsFile}
        showTotalCount
        errorText={errorText}
        {...materialTableProps}
      />
    </FullPageWrapper>
  );
};

export default NSIReagentsLawns;
