import { NSIReagentsListItem } from 'pages/dictionaries/NSIReagents/types';
import {
  getInitialFormTabInitialEmptyValues,
} from 'components/common/LocationFormTab/locationFormTabInitialEmptyValues';
import { locationFieldKeys } from 'pages/dictionaries/NSIReagents/NSIReagentsLawns/locationFieldKeys';

const initialNSIReagentsLawnsEmptyValues: Partial<NSIReagentsListItem> = {
  parentAreaId: null,
  greenAreaTypeId: null,
  status: null,
  startDate: null,
  stopDate: null,

  ...getInitialFormTabInitialEmptyValues(locationFieldKeys),
};

export default initialNSIReagentsLawnsEmptyValues;
