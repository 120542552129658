import { HStack } from '@chakra-ui/react';
import { Button } from 'components/common';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { WaterRoutesGroundCoast, WaterRoutesWPZ } from 'pages/data/Water/routes';

const paths = [
  {
    label: 'Дно и берега',
    path: WaterRoutesGroundCoast.root,
  },
  {
    label: 'Водоохранные зоны',
    path: WaterRoutesWPZ.root,
  },
];

interface Props {
  activePath: string
}

const WPZSwitch = ({ activePath }: Props) => {
  const history = useHistory();
  return (
    <HStack spacing="10px">
      {paths.map(({ label, path }) => (
        <Button
          key={path}
          colorScheme="blue"
          backgroundColor={path === activePath ? 'PrimaryButton.500' : 'white'}
          color={path === activePath ? 'white' : 'PrimaryButton.500'}
          variant={path === activePath ? 'solid' : 'outline'}
          onClick={() => history.push(path)}
          height="30px"
        >
          {label}
        </Button>
      ))}
    </HStack>
  );
};

export default WPZSwitch;
