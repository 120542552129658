import { MaterialTable } from 'features/material-table/MaterialTableMini';
import React, { useCallback, useMemo } from 'react';
import { IFTPPattern, IParameter, ISource } from '../../Consts/types';
import { ColumnWithFilter } from 'lib/utils/tableFilter';
import { Grid } from '@chakra-ui/react';
import { useFormikContext } from 'formik';

interface IProps {
  allSources: ISource[] | null;
  sourceId: string;
  setSelectedIds: React.Dispatch<React.SetStateAction<string[] | undefined>>;
}

export const DataPatternDetailPanel = ({ allSources, sourceId, setSelectedIds }: IProps) => {
  const { values, setFieldValue } = useFormikContext<IFTPPattern>();

  const dataWithCheckedProp = useMemo(
    () =>
      allSources
        ?.find((source) => sourceId === source.id)
        ?.parameters.map((item) => {
        if (values.content[sourceId]?.includes(item.parameterId)) {
          return { ...item, tableData: { checked: true } };
        }
        return item;
      }),
    [allSources, sourceId, values.content],
  );

  const columns = useMemo<ColumnWithFilter<IParameter>[]>(
    () => [
      {
        title: 'код параметра',
        field: 'code',
      },
      {
        title: 'Название',
        field: 'fullName',
      },
      {
        title: 'Краткое наименование',
        field: 'name',
      },
      {
        title: 'Назначение параметра',
        field: 'purpose',
      },
    ],
    [],
  );

  const onSelectionChange = useCallback(
    (data: IParameter[]) => {
      setFieldValue(
        `content[${sourceId}]`,
        data.map((item) => item.parameterId),
      );
      if (data.length) {
        if (!Object.keys(values.content).includes(sourceId)) {
          setSelectedIds([...Object.keys(values.content), sourceId]);
        }
      } else {
        setSelectedIds(Object.keys(values.content).filter((key) => key !== sourceId));
      }
    },
    [setFieldValue, setSelectedIds, sourceId, values.content],
  );

  return (
    <Grid marginTop={5} marginBottom={5} paddingLeft={10} paddingRight={10} width="100%">
      <MaterialTable
        data={dataWithCheckedProp}
        columns={columns}
        options={{
          selection: true,
          toolbar: false,
        }}
        onSelectionChange={onSelectionChange}
        errorText="Параметры для данной станции отсутствуют"
      />
    </Grid>
  );
};
