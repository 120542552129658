import { IFilterItem, TDictionaryItemDictionary, TFilterDTO, TFiltersArray } from 'models';
import React, { useMemo } from 'react';
import combineFilters from 'lib/utils/combineFilters';
import { createInitialValuesToFormik, createValuesToRequest } from 'features/Filter/utils';
import { transformFiltersArray } from 'features/Filter/transformFiltersArray';
import { Filter } from 'features/Filter';
import { IPermissibleNoiseLevelItem } from '../types/types';

type FilterListInitialValues = Record<
  'territoryName' | 'territoryType',
  TFilterDTO<Partial<keyof IPermissibleNoiseLevelItem>>['filters'][number]
>;

const initialValues: FilterListInitialValues = {
  territoryName: {
    key: 'territoryName',
    operation: 'CONTAINS',
    value: '',
  },
  territoryType: {
    key: 'territoryType',
    operation: 'EQ',
    value: null,
  },
};

interface FilterProps {
  isLoading: boolean;
  onSubmit: (values: TFiltersArray) => TFiltersArray;
  filters: IFilterItem[];
  territoryTypes?: TDictionaryItemDictionary[];
}

const initialValuesToFormik = createInitialValuesToFormik(initialValues);

export const PermissibleNoiseLevelFilter = ({
  isLoading,
  onSubmit,
  filters,
  territoryTypes,
}: React.PropsWithChildren<FilterProps>) => {
  const presetValues = useMemo<{ key: string; value: IFilterItem['value'] }[]>(() => {
    const preset: { key: string; value: IFilterItem['value'] }[] = [];
    const initialFilters = combineFilters(initialValues, filters);
    Object.keys(initialFilters).forEach((key) => {
      preset.push({ key: `${key}`, value: initialFilters[key].value });
    });
    return preset;
  }, [filters]);
  return (
    <Filter<typeof initialValuesToFormik>
      isLoading={isLoading}
      isDisabled={isLoading}
      onSubmit={(values) => {
        const v = createValuesToRequest(initialValues, values);
        const filters = transformFiltersArray<TFilterDTO<Partial<keyof IPermissibleNoiseLevelItem>>['filters']>(
          Object.values(v)
        );
        return onSubmit(filters);
      }}
      initialValues={initialValuesToFormik}
      mainFields={[
        {
          type: 'TEXT',
          name: 'territoryName',
          label: 'Наименование',
        },
        {
          type: 'SELECT',
          name: 'territoryType',
          label: 'Тип территории',
          options: territoryTypes,
        },
      ]}
      presetValues={presetValues}
    />
  );
};
