import { apiPathsWaterRoot } from 'pages/data/Water/apiPath';

const BottomSoilBaseApiPath = `${apiPathsWaterRoot}/water/bottomsoil`;

export const BottomSoilApiPaths = {
  list: `${BottomSoilBaseApiPath}/list`,
  dictionaries: `${BottomSoilBaseApiPath}/dictionaries`,
  root: `${BottomSoilBaseApiPath}`,
  getOrDelete: (id: string) => `${BottomSoilBaseApiPath}/${id}`,
  getWaterObjectSources: (id: string) => `${apiPathsWaterRoot}/water/surfacewater/dictionaries/water_objects/${id}`,
  getSourceData: (id: string) => `${apiPathsWaterRoot}/water/surfacewater/dictionaries/sources/${id}`
};
