import React, { memo, useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Column } from '@material-table/core';
import moment from 'moment';
import { Flex } from '@chakra-ui/react';
import { ButtonGraphicLineReporting, IFilterValues, useDefaultFilters } from 'components/common';
import { useAvailability, useDisclosure } from 'hooks';
import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import { MaterialTable } from 'features/material-table/MaterialTableMini';
import { transformFiltersArray } from 'features/Filter/transformFiltersArray';
import { createDateToRequestForFilter } from 'lib/create-date';
import { Modal } from 'components/common/Modals';
import { useList } from 'hooks/useList';
import appLocale from 'constants/appLocale';
import { DeleteForecastItemModal } from 'pages/data/Meteo/Forecast/Data/DeleteForecastItemModal';
import { ForecastFilter } from 'pages/data/Meteo/Forecast/Data/ForecastFilter';
import { IForecastItem, TForecastFilterTypes, TForecastTFilterDTO } from 'pages/data/Meteo/Forecast/Data/types';
import { forecastLocales } from 'pages/data/Meteo/Forecast/Data/consts/locale';
import { ForecastHeader } from 'pages/data/Meteo/Forecast/ForecastHeader';
import { addFiltersToColumns, ColumnWithFilter } from 'lib/utils/tableFilter';
import { ForecastRootPath } from 'pages/data/Meteo/menu';
import subsystemIdsMap from 'constants/subsystemIdsMap';
import graphicsTemplateIdsMap from 'constants/graphicsTemplateIdsMap';
import { PropActions } from 'features/material-table/components/Actions';
import { UploadFileModalContent } from 'features/UploadFileDragAndDrop/UploadFileModalContent';
import { DEFAULT_PAGE_SIZE_NEW_TABLE } from '../../../../../constants/tables';
import { FullPageWrapper } from '../../../../../components/table/FullPageWrapper';

const Forecast = memo(() => {
  const { isWrite, isExecute, isNoRights, allAvailable, isAvailable } = useAvailability(subsystemIdsMap.meteoRoot);

  const history = useHistory();

  useUpdateBreadcrumbs([
    { breadcrumb: appLocale.common.data, path: '/data' },
    {
      breadcrumb: appLocale.subsystems.meteo,
      path: '/data/meteo',
    },
    {
      breadcrumb: 'Прогноз',
      path: '/data/meteo/forecast',
    },
  ]);

  const { isOpen: isOpenLoad, onClose: onCloseLoad, onOpen: onOpenLoad } = useDisclosure();

  const [deleteItemId, setDeleteItemId] = useState('');

  const deleteItemDisclosure = useDisclosure();

  const onRowDelete = useCallback(
    (_, rowData: IForecastItem | IForecastItem[]) => {
      if (rowData && !Array.isArray(rowData)) {
        setDeleteItemId(rowData.id);
        deleteItemDisclosure.onOpen();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [deleteItemDisclosure.onOpen]
  );

  const {
    refetch,
    isLoading: isListLoading,
    response,
    toggleOrder,
    setFilters,
    onPageChange,
    filterDTO,
    onSearchText,
    errorText,
    selectedIndexes,
    renderOnlySelectedCheckbox,
    onSubmitFilters,
    onSelectionChange,
    materialTableProps,
  } = useList<IForecastItem, TForecastFilterTypes>('/meteo/v10/forecast/list', {
    initialOrderFieldKey: '-forecastDate',
    saveFiltersState: true,
    preventFirstFetch: true,
    selectionKey: 'id',
    defaultPageSize: DEFAULT_PAGE_SIZE_NEW_TABLE,
    resetDataOnNextRequest: true,
  });

  const columns = useMemo<Array<ColumnWithFilter<IForecastItem>>>(
    () => [
      {
        title: forecastLocales.forecastDate,
        field: 'forecastDate',
        hidden: false,
        render: ({ forecastDate }) => {
          const date = moment(forecastDate);
          return (
            <Flex flexDirection="column">
              <span>{date.format('DD.MM.YYYY')}</span>
            </Flex>
          );
        },
      },
      {
        title: forecastLocales.createDate,
        field: 'forecastSaveDate',
        hidden: false,
        render: ({ forecastSaveDate }) => {
          const date = moment(forecastSaveDate);
          return (
            <Flex flexDirection="column">
              <span>{date.format('DD.MM.YYYY HH:mm')}</span>
            </Flex>
          );
        },
      },
    ],
    []
  );

  const columnsWithFilters = useMemo<Array<Column<IForecastItem>>>(
    () => addFiltersToColumns({ filters: filterDTO.filters, setFilters, columns }),
    [columns, filterDTO.filters, setFilters]
  );

  const defaultFilters = useDefaultFilters({ filters: filterDTO.filters, dateKey: 'forecastDate' });

  const [filtersFromGraphic, setFiltersFromGraphic] = useState<Partial<IFilterValues> | null>(null);

  const initialActions = useMemo<PropActions<IForecastItem[]>>(
    () => [
      {
        type: 'edit',
        onClick: (e, item: any) => {
          history.push(`${ForecastRootPath}/edit/${item.id}`);
        },
      },
      { type: 'delete', onClick: onRowDelete },
    ],
    [history, onRowDelete]
  );

  // @ts-ignore
  const actions = useMemo<PropActions<any[]>>(() => {
    if (allAvailable || isNoRights || isWrite) {
      return initialActions;
    }
    if (!(allAvailable || isNoRights || isWrite)) {
      return initialActions.filter((action: any) => action.type !== 'delete');
    }
  }, [isWrite, initialActions, isNoRights, allAvailable]);

  return (
    <FullPageWrapper>
      <ForecastHeader
        onOpenLoad={isAvailable ? onOpenLoad : undefined}
        addButtonProps={{
          onClick: () => {
            history.push(`${ForecastRootPath}/add`);
          },
          isDisabled: !isAvailable,
        }}
        isAvailable={isAvailable}
      >
        <ForecastFilter
          filtersFromGraphic={filtersFromGraphic}
          setFiltersFromGraphic={setFiltersFromGraphic}
          isLoading={isListLoading}
          onSubmit={({ dateFrom, dateTo, ...values }) => {
            onSubmitFilters(
              transformFiltersArray<TForecastTFilterDTO['filters']>(
                Object.values({
                  ...values,
                  dateFrom: {
                    ...dateFrom,
                    value: createDateToRequestForFilter(String(dateFrom.value), 'start'),
                  },
                  dateTo: {
                    ...dateTo,
                    value: createDateToRequestForFilter(String(dateTo.value), 'end'),
                  },
                  deleted: {
                    key: 'deleted',
                    operation: 'EQ',
                    value: false,
                  },
                })
              )
            );
          }}
          filters={filterDTO.filters}
        >
          {renderOnlySelectedCheckbox}
        </ForecastFilter>
      </ForecastHeader>

      <Modal isOpen={isOpenLoad} onClose={onCloseLoad} header="Загрузка данных из файла">
        <UploadFileModalContent
          createUploadPath={() => '/meteo/v10/forecast/load/full'}
          onClose={onCloseLoad}
          refetch={refetch}
          types={[
            {
              value: '1',
              name: 'Данные Прогноз'
            }
          ]}
        />
      </Modal>

      <MaterialTable
        isNotExecute={isExecute || isNoRights}
        data={response?.data}
        meta={response?.meta}
        toggleOrder={toggleOrder}
        onPageChange={onPageChange}
        filterDTO={filterDTO}
        isLoading={isListLoading}
        columns={columnsWithFilters}
        actions={actions}
        selectedIndexes={selectedIndexes}
        onSelectionChange={onSelectionChange}
        setFilters={setFilters}
        options={{
          search: true,
        }}
        onSearch={onSearchText}
        topBarLeftContent={
          <ButtonGraphicLineReporting
            subSystemId={subsystemIdsMap.forecast}
            templateId={graphicsTemplateIdsMap.forecast}
            defaultFilters={defaultFilters}
            setFiltersFromGraphic={setFiltersFromGraphic}
          />
        }
        errorText={errorText}
        {...materialTableProps}
      />

      <DeleteForecastItemModal {...deleteItemDisclosure} id={deleteItemId} refetch={refetch} />
    </FullPageWrapper>
  );
});

export { Forecast };
