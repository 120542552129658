import { useCallback, useMemo } from 'react';
import {
  Box,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
} from '@chakra-ui/react';
import TableStringFilter from 'components/table/filters/TableStringFilter';
import { IFilterItem, TFilterOperations, TFiltersArray } from 'models';
import appLocale from 'constants/appLocale';
import { ArrowDownIcon } from 'components/icons';
import TableNumberFilter from 'components/table/filters/TableNumberFilter';
import { SelectOption } from 'components/common';
import TableSelectFilter from 'components/table/filters/TableSelectFilter';

export enum TableFilterTypeEnum {
  'number',
  'string',
  'select',
}

interface Props<Keys> {
  filters: TFiltersArray<Keys>;
  setFilters: (filters: TFiltersArray<Keys>) => void;
  fieldKey: Keys;
  type: TableFilterTypeEnum;
  selectOptions?: SelectOption[];
}

function TableFilter<Keys = string>({ filters, setFilters, fieldKey, type, selectOptions }: Props<Keys>) {
  const filter = useMemo(() => filters.filter((filter) => filter.key === fieldKey), [fieldKey, filters]);

  const onSubmit = useCallback(
    (operand: TFilterOperations, firstValue: string, secondValue?: string) => {
      const updatedFilters = filters.filter((filter) => filter.key !== fieldKey);
      let value: IFilterItem['value'];
      if (['NOT_BETWEEN', 'BETWEEN'].includes(operand) && secondValue) {
        value = [firstValue, secondValue].map((val) => parseFloat(String(val)))
          .sort((a, b) => (a > b ? 1 : -1));
      } else {
        value = firstValue;
      }

      updatedFilters.push({
        key: fieldKey,
        value,
        operation: operand,
      });

      setFilters(updatedFilters);
    },
    [fieldKey, filters, setFilters],
  );

  const onClear = useCallback(() => {
    setFilters(filters.filter((filter) => filter.key !== fieldKey));
  }, [fieldKey, filters, setFilters]);

  const renderTextValue = useMemo(
    () =>
      filter.map((filter, index) => {
        const operandShort = appLocale.common.operations.short[filter.operation as TFilterOperations];
        if (filter.value === undefined || filter.value === null) return;

        if (type === TableFilterTypeEnum.select) {
          return (
            <div key={index}>
              {operandShort}
              &nbsp;
              {selectOptions?.find((option) => option.value === filter.value)?.name}
            </div>
          );
        }

        if (['NOT_BETWEEN', 'BETWEEN'].includes(filter.operation) && Array.isArray(filter.value)) {
          const values = filter.value.map((val) => parseFloat(String(val)))
            .sort((a, b) => (a > b ? 1 : -1));
          return <div key={index}>{`${operandShort} от ${values[0]} до ${values[1]}`}</div>;
        }

        return <div key={index}>{`${operandShort} ${filter.value}`}</div>;
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filter, selectOptions, type],
  );

  const popupWidth = useMemo(() => {
    switch (type) {
      case TableFilterTypeEnum.select:
        return 350;
      case TableFilterTypeEnum.number:
        return 600;
      case TableFilterTypeEnum.string:
        return 400;
    }
  }, [type]);

  const renderFilter = useMemo(() => {
    switch (type) {
      case TableFilterTypeEnum.string:
        return <TableStringFilter onSubmit={onSubmit} onClear={onClear} filter={filter} />;
      case TableFilterTypeEnum.number:
        return <TableNumberFilter onSubmit={onSubmit} onClear={onClear} filter={filter} />;
      case TableFilterTypeEnum.select: {
        if (!selectOptions) return null;

        return (
          <TableSelectFilter onSubmit={onSubmit} onClear={onClear} filter={filter} selectOptions={selectOptions} />
        );
      }
    }
  }, [filter, onClear, onSubmit, selectOptions, type]);

  if (!renderFilter) return null;

  return (
    <Popover isLazy>
      <PopoverTrigger>
        <Box minH={5} alignItems="center" cursor="pointer" display="flex">
          <Box marginRight={2}>
            <ArrowDownIcon />
          </Box>
          {renderTextValue}
        </Box>
      </PopoverTrigger>

      <PopoverContent boxShadow="none !important" width={popupWidth}>
        <PopoverArrow />
        <PopoverHeader height={8}>
          <PopoverCloseButton />
        </PopoverHeader>

        <PopoverBody>{renderFilter}</PopoverBody>
      </PopoverContent>
    </Popover>
  );
}

export default TableFilter;
