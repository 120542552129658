import { useMemo } from 'react';
import { ParameterType } from 'pages/data/PGR/types';

interface IProps {
  type?: ParameterType;
  value: string | number | null;
}

export const CellWithType = ({ type, value }: IProps) => {
  const typeString = useMemo(() => {
    switch (type) {
      case 'LESS':
        return '<';
      case 'MORE':
        return '>';
      default:
        return null;
    }
  }, [type]);

  return <>{typeString}{value}</>;
};
