import React, { memo, useMemo } from 'react';

import { createInitialValuesToFormik, createValuesToRequest } from 'features/Filter/utils';
import { Filter } from 'features/Filter';
import { TForecastTFilterDTO } from 'pages/data/Meteo/Forecast/Data/types';
import { IFilterValues } from 'components/common';
import appLocale from 'constants/appLocale';
import { SchemaForCustomFilter } from 'models/schemas/filtersDatepickersSchema/FiltersDatepickersSchema';
import { IFilterItem } from 'models';
import combineFilters from 'lib/utils/combineFilters';

type FilterForecastListInitialValues = Record<'dateFrom' | 'dateTo', TForecastTFilterDTO['filters'][number]>;

const initialValues: FilterForecastListInitialValues = {
  dateFrom: {
    key: 'forecastDate',
    operation: 'GTE',
    value: null,
  },
  dateTo: {
    key: 'forecastDate',
    operation: 'LTE',
    value: null,
  },
};

interface ForecastFilterProps {
  isLoading: boolean;
  onSubmit: (values: FilterForecastListInitialValues) => void;
  filtersFromGraphic: Partial<IFilterValues> | null;
  setFiltersFromGraphic: (value: Partial<IFilterValues> | null) => void;
  filters: IFilterItem[];
}

const initialValuesToFormik = createInitialValuesToFormik(initialValues);

function ForecastFilterComponent({
  isLoading,
  setFiltersFromGraphic,
  filtersFromGraphic,
  onSubmit,
  children,
  filters,
}: React.PropsWithChildren<ForecastFilterProps>) {
  const presetValues = useMemo<{ key: string, value: IFilterItem['value'] }[]>(() => {
    const preset: { key: string, value: IFilterItem['value'] }[] = [];
    const initialFilters = combineFilters(initialValues, filters);
    Object.keys(initialFilters).forEach((key) => {
      preset.push({ key: `${key}`, value: initialFilters[key].value });
    });
    return preset;
  }, [filters]);

  return (
    <Filter<typeof initialValuesToFormik>
      isLoading={isLoading}
      isDisabled={isLoading}
      onSubmit={(values) => {
        onSubmit(createValuesToRequest(initialValues, values));
      }}
      initialValues={initialValuesToFormik}
      setFiltersFromGraphic={setFiltersFromGraphic}
      filtersFromGraphic={filtersFromGraphic}
      mainFields={[
        {
          type: 'DATE',
          name: 'dateFrom',
          label: appLocale.common.from,
          showTimeInput: false,
        },
        {
          type: 'DATE',
          name: 'dateTo',
          label: appLocale.common.to,
          showTimeInput: false,
        },
      ]}
      validationSchema={() =>
        SchemaForCustomFilter([{ dateFromFieldName: 'dateFrom', dateToFieldName: 'dateTo' }])
      }
      presetValues={presetValues}
    >
      {children}
    </Filter>
  );
}

const ForecastFilter = memo(ForecastFilterComponent);

export { ForecastFilter };
