import { Button, FormWrapper, Input, Select } from 'components/common';
import { Box, Flex, Grid, Heading, HStack } from '@chakra-ui/react';
import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { TDictionariesArray } from 'models/dictionaries';
import { IReportProtocolForm, IReportProtocolFormValue, TypeEnum } from 'pages/analytics/Raids/types';
import { Field, FieldArray, useFormikContext } from 'formik';
import { DeleteIcon } from 'components/icons';
import { CommaNumberInput } from '../../../../../components/common/Input/comma-number-input';
import { MoreLessNumberInput } from 'components/common/Input/more-less-number-input';

interface IProps {
  dictionaries: {
    data: TDictionariesArray;
  } | null;
  isAi?: boolean;
}

export const AnalyticsPelAtmosphereParameters: FC<IProps> = ({ dictionaries, children, isAi }) => {
  const { values, setFieldValue } = useFormikContext<IReportProtocolForm>();

  const meteoParametersIds = useMemo(
    () =>
      dictionaries?.data
        .find((dictionary) => dictionary.name === (isAi ? 'airAiMeteoParameters' : 'airMeteoParameters'))
        ?.dictionary?.map((item) => item.value) || [],
    [dictionaries, isAi]
  );

  const airParameters = useMemo(
    () =>
    (dictionaries
      ? dictionaries.data
        .find((dictionary) => dictionary.name === (isAi ? 'airAiParameters' : 'airParameters'))
        ?.dictionary?.filter((parameter) => !meteoParametersIds.includes(parameter.value))
      : []),
    [dictionaries, isAi, meteoParametersIds]
  );

  const addParameter = useCallback(() => {
    setFieldValue('parameters', [...values.parameters, {}]);
  }, [setFieldValue, values.parameters]);

  const onAddAvgParameter = useCallback(
    (index: number, parameter: IReportProtocolFormValue) => {
      setFieldValue(`parameters[${index}].avg`, { type: TypeEnum.AVG, parameterId: parameter.parameterId });
    },
    [setFieldValue]
  );

  const onAddErrParameter = useCallback(
    (index: number, parameter: IReportProtocolFormValue) => {
      setFieldValue(`parameters[${index}].error`, {
        type: TypeEnum.ERR,
        parameterId: parameter.parameterId,
        value: ''
      });
    },
    [setFieldValue]
  );

  // delete error parameter on condition
  useEffect(() => {
    values.parameters.forEach((parameter, index) => {
      if (!isAi && parameter.error && parameter.avg?.value && parameter.avg?.type !== TypeEnum.AVG) {
        setFieldValue(`parameters[${index}].error`, undefined);
      }

      if (isAi && parameter.error) {
        const hasValue = values.parameters[index].values?.[0].value;
        const valueIsABS = values.parameters[index].values?.[0].type === TypeEnum.ABS;
        if (!hasValue || !valueIsABS) {
          setFieldValue(`parameters[${index}].error`, undefined);
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.parameters, isAi]);

  const isAddErrButtonDisabled = useCallback((index: number) => (isAi ?
      values.parameters[index].values?.[0].type !== TypeEnum.ABS ||
      !values.parameters[index].values?.[0].value ||
      values.parameters[index].error !== undefined :
      values.parameters[index].avg?.type !== TypeEnum.AVG ||
      !values.parameters[index].avg?.value ||
      values.parameters[index].error !== undefined), [isAi, values.parameters]);

  return (
    <Box flexDirection="column">
      <FormWrapper>
        <Grid marginTop="30px" gap="15px">
          <Heading variant="h2">Данные обследования</Heading>

          {values.parameters.map((parameter, index) => (
            <Box key={index}>
              <Field
                as={Select}
                name={`parameters[${index}].parameterId`}
                label="Показатель"
                options={airParameters}
                onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                  const parameter = airParameters?.find((p) => p.value === event.target.value);
                  setFieldValue(`parameters[${index}].parameterId`, event.target.value);
                  setFieldValue(`parameters[${index}].type`, parameter?.type);
                  if (!values.parameters[index].values) {
                    setFieldValue(`parameters[${index}].values`, ['']);
                  }
                }}
              />

              {parameter.parameterId && (
                <Field
                  as={Input}
                  label="ПДКмр"
                  value={airParameters?.find((p) => p.value === parameter.parameterId)?.maxPdk}
                  isDisabled
                />
              )}

              <FieldArray name={`parameters[${index}].values`}>
                {({ remove }) => (
                  <Box flexDirection="column" width="100%">
                    {parameter.values?.map((value: any, idx: number) => (
                      <Flex mt={2} alignItems="start" key={idx}>
                        <Field
                          as={MoreLessNumberInput}
                          name={`parameters[${index}].values[${idx}]`}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setFieldValue(`parameters[${index}].values[${idx}].value`, e.target.value);
                          }}
                          label="Значение"
                        />
                        {parameter.values && parameter.values.length > 1 && (
                          <Button leftIcon={<DeleteIcon />} onClick={() => remove(idx)} variant="ghost" size="sm" />
                        )}
                      </Flex>
                    ))}
                  </Box>
                )}
              </FieldArray>

              {values.parameters[index].avg !== undefined && (
                <Box mt={2}>
                  <Field
                    label="Среднее значение"
                    as={MoreLessNumberInput}
                    name={`parameters[${index}].avg`}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setFieldValue(`parameters[${index}].avg.value`, e.target.value);
                    }}
                    isAVG
                  />
                </Box>
              )}

              {(
                values.parameters[index].avg?.type === TypeEnum.AVG ||
                values.parameters[index].values?.[0].type === TypeEnum.ABS
              ) &&
                values.parameters[index].error !== undefined && (
                  <Box mt={2}>
                    <Field as={CommaNumberInput} name={`parameters[${index}].error.value`} label="Показатель точности" />
                  </Box>
                )}

              <HStack mt={2}>
                {!isAi && (
                  <>
                    <Button
                      onClick={() => {
                        setFieldValue(`parameters[${index}].values`, [...(values.parameters[index].values || []), {
                          parameterId: parameter.parameterId,
                        }]);
                      }}
                      variant="ghost"
                      size="sm"
                    >
                      Добавить значение
                    </Button>

                    <Button
                      onClick={() => onAddAvgParameter(index, parameter)}
                      variant="ghost"
                      size="sm"
                      isDisabled={values.parameters[index].avg !== undefined}
                    >
                      Добавить среднее значение
                    </Button>
                  </>
                )}

                <Button
                  onClick={() => onAddErrParameter(index, parameter)}
                  variant="ghost"
                  size="sm"
                  isDisabled={isAddErrButtonDisabled(index)}
                >
                  Добавить показатель точности
                </Button>
              </HStack>
            </Box>
          ))}

          <HStack mt={5}>
            <Button onClick={() => addParameter()} marginRight="auto">
              Добавить показатель
            </Button>
          </HStack>

          {children}
        </Grid>
      </FormWrapper>
    </Box>
  );
};
