import { useRequest } from 'hooks/useRequest';
import { useEffect } from 'react';
import { PaginatedItems } from './models/PaginatedItems';

export function useTypedLoader<T>(address: string) {
  const { get, isLoading, result, error } = useRequest<T>(address);
  useEffect(() => {
    get();
  }, [get]);

  return {
    get,
    isLoading,
    result,
    error,
  };
}

export function usePaginatedLoader<T>(address: string) {
  return useTypedLoader<PaginatedItems<T>>(address);
}
