import { UploadObjectType } from 'api/services/media';
import { ANALYTICS_STATUS } from 'pages/analytics/forms/const';

interface IBottomSoilParameter {
  strValue: string;
}
export type TBottomSoilParameters = {
  chrome: IBottomSoilParameter;
  copper: IBottomSoilParameter;
  lead: IBottomSoilParameter;
  manganese: IBottomSoilParameter;
  mercury: IBottomSoilParameter;
  nickel: IBottomSoilParameter;
  organicSubstance: IBottomSoilParameter;
  petrochemicals: IBottomSoilParameter;
  zinc: IBottomSoilParameter;
  phIndex: IBottomSoilParameter;
};

export const BottomSoilParametersKeys: Array<keyof TBottomSoilParameters> = [
  'chrome',
  'copper',
  'lead',
  'manganese',
  'mercury',
  'nickel',
  'organicSubstance',
  'petrochemicals',
  'zinc',
  'phIndex',
];

export const parameterIdToKeyMap: Record<string, string> = {
  '15621f73-7730-4d6d-aae5-ef0fd53df7b0': 'chrome',
  '2bab5200-9acc-4b7d-9c70-50b16e5d0513': 'copper',
  '4bf31b50-2a52-4280-974a-ad94e540814d': 'lead',
  'c20b4123-ba4d-439d-acf2-5ece055b1fcd': 'manganese',
  'cdbe3d8e-1cee-40b8-9598-d6dd8307de08': 'mercury',
  'b832c67b-c132-4fe9-8cb8-22d7152a35ba': 'nickel',
  'd9e5a982-483f-458f-8b04-5f4b2c61f8a3': 'organicSubstance',
  '7c764bc4-4698-401c-8afd-621feea8ccaa': 'petrochemicals',
  'ef6b41ac-a34c-4b2c-be96-f0f8dde2943f': 'zinc',
  'c9824b24-aaa5-44e0-87b2-dd10b37a45f9': 'phIndex',
};

interface IBottomSoilCreateDTO extends TBottomSoilParameters {
  sourceId: string | null;
  waterObject: string | null;

  protocolNumber: string;
  // Номер протокола
  protocolDate: string;
  // Дата протокола
  consumerId: string | null;
  // Заказчик
  researchBasis: string;
  // Основание для проведения исследований
  researchObject: string;
  // Объект исследования
  supportingDocuments: string;
  // Сопроводительная документация
  sampleNumber: string;
  // Шифр пробы
  latitude: number | string;
  longitude: number | string;
  // Координаты
  samplePlace: string;
  // Точка отбора пробы
  address: string;
  // Место отбора пробы (адрес)
  sampleDate: string;
  // Дата и время отбора пробы
  organizationId: string | null;
  // Организация, выполнившая отбор проб
  explanation: string;
  // Дополнительная информация
  explanationFromConsumer: string;
  // Дополнительная информация от заказчика
  sampleReceiveDate: string;
  // Дата и время получения проб
  researchDateStart: string;
  // Дата начала проведения исследований
  researchDateEnd: string;
  // Дата окончания проведения исследований
  sampleLaboratoryNumber: string;
  // Регистрационный номер пробы в лаборатории
  status: ANALYTICS_STATUS;
  // Статус
}

export interface IBottomSoilDTO extends IBottomSoilCreateDTO {
  isEditable: boolean;
  id: string;

  district: string;
  region: string;
  consumer: string;
  organization: string;

  source: string;
  laboratory: boolean;
  objectType: UploadObjectType | null;

  waterObjectId: string;
  deleted: boolean;
}

export interface WaterObjectResponse {
  district: string;
  id: string;
  latitude: number;
  longitude: number;
  region: string;
  waterObject: string;
}
