import { Box, Flex, Text, Tooltip } from '@chakra-ui/react';
import { IDayItem } from '../types';

interface IProps {
  item: IDayItem
  isMeteo?: boolean
  onClick?: () => void
}

const DataCard = ({ item, isMeteo, onClick }: IProps) => {
  const bgColor = item.color || '#D4D9DC';

  return (
    <Tooltip label={isMeteo ? undefined : item.indicatorFullName} bg="#fff" color="#000">
      <Flex
        flexDirection="column"
        borderColor={bgColor}
        borderStyle="solid"
        borderRadius="8px"
        borderWidth="2px"
        py="12px"
        width="100%"
        cursor={onClick ? 'pointer' : 'initial'}
        onClick={onClick}
      >
        <Text
          align="center"
          fontWeight={isMeteo ? 600 : 700}
          fontSize={isMeteo ? '12px' : '14px'}
          lineHeight={isMeteo ? '14px' : '16px'}
          height="28px"
        >
          {item.indicatorName}
        </Text>
        <Flex
          height="32px"
          alignItems="center"
          justifyContent="center"
        >
          {!isMeteo && (
            <Box background={bgColor} width="10px" height="10px" borderRadius="50%" mr={2} />
          )}

          <Text
            fontWeight={800}
            fontSize={isMeteo ? '12px' : '14px'}
            lineHeight="16px"
            textAlign="center"
          >
            {item.value}
          </Text>
        </Flex>

        <Text
          color={isMeteo ? '#208CFF' : '#6E7478'}
          fontWeight={500}
          fontSize="10px"
          lineHeight="12px"
          textAlign="center"
          mt="10px"
        >
          {item.caption}
        </Text>
      </Flex>
    </Tooltip>
  );
};

export default DataCard;
