import React, { useCallback, useState } from 'react';
import { createTouchedErrors } from 'features/create-touched-errors';
import { FormikProvider, useFormik } from 'formik';
import { useRequest } from 'hooks/useRequest';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { ReagentsWashoutRootPath } from 'pages/data/PGR';
import { WashoutSchema } from 'models/schemas/pgr';
import FieldsForm from './FieldsForm';
import { IUploadMedia } from 'components/common/FileUploadDragAndDrop/types';
import { updateFiles } from 'api/services/media';
import { WashoutDeicingItem } from 'pages/data/PGR/GreenPlants/Washout/useWashoutDicingList';
import { useObjectType } from 'hooks/useObjectType';
import { AddFormProps } from 'types/forms';
import { splitTypesAndValues } from 'lib/utils/forms/valueTypeHelper';
import { useGoBackWithFallback } from 'lib/utils/goBackWithFallback';
import { useDictionary } from 'hooks/useDictionary';
import { useEnhancedNotification } from 'hooks';

export const initialEmptyValues = {
  address: '', // Адресс
  areaId: null, // Площадка id
  areaName: '', // Площадка - название
  date: moment().format(),
  deleted: false,
  distance: null,
  district: null, // Округ
  parameters: [],
  pointId: null,
  pointName: '',
  distanceId: '',
  protocolNumber: '',
  protocolDate: moment().format(),
  region: null, // Регион
  registryId: null,
  territoryType: null,
};

export const AddDeicingWashoutReport = ({
  listPath = ReagentsWashoutRootPath,
  editPath = `${ReagentsWashoutRootPath}/edit/row`,
}: AddFormProps) => {
  const { backWithFallback } = useGoBackWithFallback();
  const { isDictionariesLoading, dictionaries } = useDictionary({ url: '/deicing/v10/deicing/washout/dictionaries' });
  const history = useHistory();
  const { onSuccessCreate, onErrorCreate } = useEnhancedNotification();
  const [media, setMedia] = useState<IUploadMedia[]>([]);
  const objectType = useObjectType(dictionaries);

  const onSuccess = (response: WashoutDeicingItem | null) => {
    if (response) {
      onSuccessCreate();

      updateFiles(response.registryId, objectType, media)
        .then(() => {
          backWithFallback(`${listPath}/main-data`);
        })
        .catch((error: Error) => {
          onErrorCreate(error.message);
          history.replace(`${editPath}/${response.registryId}?openAttachmentsTab=true`, response);
        });
    } else {
      onErrorCreate();
    }
  };

  const { post, isLoading } = useRequest<WashoutDeicingItem, any>(
    '/deicing/v10/deicing/washout',
    onSuccess,
    onErrorCreate,
  );

  const onSubmit = useCallback(
    (values: typeof initialEmptyValues) => {
      post({
        ...values,
        parameters: splitTypesAndValues(values.parameters),
      });
    },
    [post],
  );

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    initialValues: initialEmptyValues,
    onSubmit,
    validationSchema: WashoutSchema,
  });

  return (
    <FormikProvider
      value={{
        ...formik,
        errors: createTouchedErrors(formik.errors, formik.touched),
      }}
    >
      <FieldsForm
        dictionaries={dictionaries}
        onCancel={() => {
          backWithFallback(`${listPath}/main-data`);
        }}
        isLoading={isDictionariesLoading || isLoading}
        onMediaChange={setMedia}
        media={media}
      />
    </FormikProvider>
  );
};
