import React, { ReactNode, useMemo } from 'react';
import { IFilterItem, TDictionariesArray, TFiltersArray } from 'models';
import { IUploadItem } from '../../types';
import { Field, FormikProvider, useFormik } from 'formik';
import { Select } from 'components/common';
import { Flex, Grid } from '@chakra-ui/react';
import { getSelectOptionsFromDictionaryByName } from 'features/get-select-options-from-dictionary';
import FilterFormControls from 'components/form/FilterFormControls';

interface IProps {
  dictionaries: { data: TDictionariesArray } | null;
  onSubmitFilters: (filters: TFiltersArray<keyof IUploadItem>) => void;
  children: ReactNode;
}

const initialValues: Record<string, IFilterItem<string>> = {
  uploadStatusName: {
    key: 'uploadStatus',
    operation: 'EQ',
    value: '',
  },
};

export const Filter = ({ children, onSubmitFilters, dictionaries }: IProps) => {
  const statuses = useMemo(() => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'detailsStatuses'), [dictionaries]);
  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    initialValues,
    onSubmit: (values: any) => {
      const filters: TFiltersArray<keyof IUploadItem> = Object.values(values);
      onSubmitFilters(filters);
    },
  });

  const { values, submitForm, handleReset } = formik;
  return (
    <FormikProvider
      value={{
        ...formik,
      }}
    >
      <Flex>
        <Grid width="100%" maxWidth="1200px" gap={5} templateColumns="repeat(4, 0.25fr)">
          <Field
            as={Select}
            name="uploadStatusName.value"
            label="Статус выгрузки"
            options={statuses}
            value={values.uploadStatusName.value}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            formik.setFieldValue('uploadStatusName.value', e.target.value)
          }
          />

          {children}
        </Grid>
        <FilterFormControls
          onReset={(e) => {
          handleReset(e);
          void submitForm();
        }}
          onSubmit={submitForm}
        />
      </Flex>
    </FormikProvider>
  );
};
