import { useRequest } from 'hooks/useRequest';
import { useEffect } from 'react';

export type TSubsystemItem = {
  deleted: boolean;
  id: string;
  module: string;
  name: string;
  parentId: string;
};

export const useUserSubsystems = (userId?: string) => {
  const { get, result, isLoading } = useRequest<TSubsystemItem[]>('/auth/v10/user/subsystem');

  useEffect(() => {
    if (userId) {
      get();
    }
  }, [get, userId]);

  return {
    get,
    result,
    isLoading
  };
};
