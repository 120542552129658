import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import appLocale from 'constants/appLocale';
import { NSIRootPath } from '../../const/routes';
import { GeoecologyDictPath, GroundwaterPath } from '../constants/constants';
import { SubsystemHeader } from 'components/common';
import React, { FC, useMemo } from 'react';
import { GroundwaterFilter } from './Filter';
import { MaterialTable } from 'features/material-table/MaterialTableMini';
import { useHistory } from 'react-router';
import { useList } from 'hooks';
import { getSelectOptionsFromDictionaryByName } from 'features/get-select-options-from-dictionary';
import { subsystemCodesMap } from 'constants/subsystemIdsMap';
import { Column } from '@material-table/core';
import { useCustomRights } from 'hooks/useCustomRights';
import { useDictionary } from 'hooks/useDictionary';
import { FullPageWrapper } from '../../../../components/table/FullPageWrapper';
import { DefaultTableCellSize } from '../../../../components/table/DefaultTableCellSize';
import { DEFAULT_PAGE_SIZE_NEW_TABLE } from 'constants/tables';

export const Groundwater: FC = () => {
  useUpdateBreadcrumbs([
    {
      breadcrumb: appLocale.mainPage.nsi,
      path: NSIRootPath,
    },
    {
      breadcrumb: appLocale.subsystems.groundwater,
      path: GroundwaterPath,
    },
  ]);

  const { isWrite } = useCustomRights(subsystemCodesMap.nsi.geologicalProcesses.groundWater);

  const { dictionaries } = useDictionary({ url: GeoecologyDictPath });
  const districts = useMemo(() => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'rayons'), [dictionaries]);
  const regions = useMemo(() => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'okrugs'), [dictionaries]);
  const statuses = useMemo(() => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'statuses'), [dictionaries]);
  const aquifers = useMemo(() => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'aquifers'), [dictionaries]);
  const waterTypes = useMemo(
    () => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'waterTypes'),
    [dictionaries],
  );

  const {
    response,
    setFilters,
    onPageChange,
    isLoading,
    toggleOrder,
    filterDTO,
    onSearchText,
    downloadAsFile,
    errorText,
    materialTableProps,
  } = useList('/nsi/v10/sources/geoecology/list/groundwater', {
    initialOrderFieldKey: 'number',
    saveFiltersState: true,
    defaultPageSize: DEFAULT_PAGE_SIZE_NEW_TABLE,
    resetDataOnNextRequest: true,
  });

  const history = useHistory();

  const handleAddButtonClick = () => history.push(`${GroundwaterPath}/add`);
  const tableEditButtonClick = (id: string) => history.push(`${GroundwaterPath}/edit/${id}`);

  const columns = useMemo<Column<any>[]>(
    () => [
      {
        title: 'Номер объекта',
        field: 'number',
      },
      {
        title: 'Тип объекта',
        field: 'objectTypeName',
      },
      {
        title: 'Название объекта',
        field: 'name',
      },
      {
        title: 'Водоносный горизонт',
        field: 'aquiferName',
        render: (row) => <DefaultTableCellSize>{row.aquiferName}</DefaultTableCellSize>,
      },
      {
        title: 'Округ',
        field: 'okrugName',
      },
      {
        title: 'Район',
        field: 'rayonName',
      },
      {
        title: 'Адрес',
        field: 'address',
        render: (row) => <DefaultTableCellSize>{row.address}</DefaultTableCellSize>,
      },
      {
        title: 'Адресный ориентир',
        field: 'landmark',
        render: (row) => <DefaultTableCellSize>{row.landmark}</DefaultTableCellSize>,
      },
      {
        title: 'Статус',
        field: 'status',
        render: (row: any) => row.statusName,
      },
    ],
    [],
  );

  return (
    <FullPageWrapper>
      <SubsystemHeader
        title={appLocale.subsystems.groundwater}
        addButtonProps={{ onClick: handleAddButtonClick, isDisabled: !isWrite }}
        filtersComponent={
          <GroundwaterFilter
            setFilters={setFilters}
            districts={districts}
            regions={regions}
            statuses={statuses}
            aquifers={aquifers}
            waterTypes={waterTypes}
            filters={filterDTO.filters}
          />
        }
      />

      <MaterialTable
        data={response?.data}
        meta={response?.meta}
        filterDTO={filterDTO}
        toggleOrder={toggleOrder}
        options={{
          search: true,
          showTitle: true,
          toolbar: true,
        }}
        isLoading={isLoading}
        onSearch={onSearchText}
        onPageChange={onPageChange}
        downloadAsFile={downloadAsFile}
        columns={columns}
        actions={[
          {
            type: 'edit',
            onClick: (_e, row) => {
              tableEditButtonClick(row.id);
            },
            disabled: !isWrite,
            hidden: !isWrite,
          },
        ]}
        showTotalCount
        errorText={errorText}
        {...materialTableProps}
      />
    </FullPageWrapper>
  );
};
