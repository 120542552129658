import { DateInput, FormWrapper, Input, Select } from 'components/common';
import { Box, Grid } from '@chakra-ui/react';
import React, { useEffect, useMemo } from 'react';
import { Field, useFormikContext } from 'formik';
import { createDateFromResponse, createDateToRequestWithTimezone } from 'lib/create-date';
import appLocale from 'constants/appLocale';
import { TDictionariesArray } from 'models';
import { getSelectOptionsFromDictionaryByName } from 'features/get-select-options-from-dictionary';
import { IGroundCoastFormDto } from 'pages/data/Water/WPZRoot/GroundCoast/consts/types';
import { getFilteredOptionsByPrivileges } from 'features/getFilteredOptionsByPrivileges';

interface CommonTabProps {
  dictionaries: {
    data: TDictionariesArray;
  } | null;
  isEditMode?: boolean;
}

export const CommonTab = ({ isEditMode, dictionaries }: CommonTabProps) => {
  const { setFieldValue, values, errors, setFieldTouched } = useFormikContext<IGroundCoastFormDto>();

  const waterObjects = useMemo(
    () =>
      getFilteredOptionsByPrivileges(
        getSelectOptionsFromDictionaryByName(dictionaries?.data, 'waterObjects'),
        'isEditable',
        'value',
        values.waterObjectId,
      ),
    [dictionaries?.data, values.waterObjectId],
  );

  // areas are filtered by waterObject
  const areas = useMemo(() => {
    const areas = getFilteredOptionsByPrivileges(
      getSelectOptionsFromDictionaryByName(dictionaries?.data, 'areas'),
      'isEditable',
      'value',
      values.areaId,
    );
    if (values.waterObjectId && !isEditMode) {
      return areas.filter((area) => area.parent === values.waterObjectId);
    }
    return areas;
  }, [dictionaries?.data, isEditMode, values.areaId, values.waterObjectId]);

  useEffect(() => {
    const areaRecord = areas.filter((area) => area.value === values.areaId)[0] || {};
    setFieldValue('district', areaRecord.district || '');
    setFieldValue('region', areaRecord.region || '');
    setFieldValue('oopt', areaRecord.oopt || '');
  }, [values.areaId, areas, setFieldValue]);

  return (
    <Box flexDirection="column">
      <FormWrapper>
        <Grid marginTop="30px" gap="15px">
          <Field
            as={DateInput}
            name="date"
            label={appLocale.groundCoast.date}
            value={createDateFromResponse(values.date)}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFieldTouched(e.target.name);
              setFieldValue('date', createDateToRequestWithTimezone(e.target.value), true);
            }}
            error={errors?.date}
            onBlur={(e: any) => {
              setFieldTouched(e.target.name);
            }}
            showTimeInput
          />

          <Field
            as={Select}
            name="waterObjectId"
            options={waterObjects}
            label={appLocale.bottomSoil.waterObject}
            isDisabled={isEditMode}
            error={errors?.waterObjectId}
            onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
              setFieldValue('waterObjectId', event.target.value);
              setFieldValue('areaId', null);
            }}
          />

          <Field
            as={Select}
            name="areaId"
            options={areas}
            label={appLocale.groundCoast.area}
            isDisabled={isEditMode || !values.waterObjectId}
            error={errors?.areaId}
          />

          <Field
            as={Select}
            name="areaId"
            options={areas.map((area) => ({ ...area, name: area.areaCode || '' }))}
            label={appLocale.groundCoast.areaCode}
            isDisabled={isEditMode || !values.waterObjectId}
            error={errors?.areaId}
          />

          <Field
            as={Input}
            label={appLocale.common.region}
            name="region"
            value={values.region}
            disabled
            error={errors?.region}
          />

          <Field
            as={Input}
            label={appLocale.common.district}
            name="district"
            value={values.district}
            disabled
            error={errors?.district}
          />

          <Field
            as={Input}
            label={appLocale.groundCoast.oopt}
            name="oopt"
            value={values.oopt}
            disabled
            error={errors?.oopt}
          />
        </Grid>
      </FormWrapper>
    </Box>
  );
};
