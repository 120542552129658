import { SubsystemHeader } from 'components/common';
import { useHistory } from 'react-router-dom';
import { memo } from 'react';
import appLocale from 'constants/appLocale';

interface SubsoilHeaderProps {
  url: string;
  onOpenLoad(): void;
  isReadonly?: boolean;
  isWrite?: boolean;
  isNoRights?: boolean;
  isExecute?: boolean;
  allAvailable?: boolean;
}

export const SubsoilHeader: React.FC<SubsoilHeaderProps> = memo(({
  onOpenLoad,
  children,
  url,
  isWrite,
  isNoRights,
  allAvailable
}) => {
  const isAvailable = allAvailable || isNoRights || isWrite;
  const history = useHistory();

  const handleAddButtonClick = () => history.push(`${history.location.pathname}${url}`);

  return (
    <SubsystemHeader
      title={appLocale.subsystems.subsoil}
      addButtonProps={
        {
          onClick: handleAddButtonClick,
          isDisabled: !isAvailable
        }
      }
      onUploadClick={isAvailable ? onOpenLoad : undefined}
      filtersComponent={children}
    />
  );
});
