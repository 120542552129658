import { Stack, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { AttachmentsTabContent, StyledTab } from 'components/common';
import React, { memo } from 'react';

import { AreaForm } from '../Common';
import { LawnsCerealsWeedsMossesForm } from './LawnsCerealsWeedsMossesForm';
import { LawnsCoatingForm } from './LawnsCoatingForm';
import { LawnsDamageForm } from './LawnsDamageForm';
import { LawnsOtherForm } from './LawnsOtherForm';

import { ILawnsTabPanelProps } from './types';
import { UploadObjectType } from 'api/services/media';

export const LawnsTabPanel: React.FC<ILawnsTabPanelProps> = memo(
  ({
    entity,
    lawnsDictionaries = {
      areas: [],
      lawnParameters: [],
      areaParameters: [],
      lawns: [],
      objectType: [],
    },
    area,
    isLoading,
    children,
    media,
    setMedia,
    registryId,
  }) => {
    const { areaParameters = [], lawnParameters = [], areas = [], objectType = [] } = lawnsDictionaries;

    if (entity === 'lawns') {
      return (
        <Tabs>
          <TabList>
            <StyledTab>Площадка</StyledTab>
            <StyledTab>Злаки, сорняки, мхи</StyledTab>
            <StyledTab>Покрытие</StyledTab>
            <StyledTab>Повреждения</StyledTab>
            <StyledTab>Прочее</StyledTab>
            <StyledTab>Вложения</StyledTab>
          </TabList>
          <TabPanels>
            <TabPanel>
              {/* // @ts-ignore */}
              <AreaForm
                entity={entity}
                area={area}
                areas={areas}
                isLoading={isLoading}
              />
              {children}
            </TabPanel>
            <TabPanel>
              <LawnsCerealsWeedsMossesForm
                entity="lawns"
                isLoading={isLoading}
                lawnParameters={lawnParameters}
                areaParameters={areaParameters}
              />
              {children}
            </TabPanel>
            <TabPanel>
              <LawnsCoatingForm
                entity="lawns"
                isLoading={isLoading}
                lawnParameters={lawnParameters}
                areaParameters={areaParameters}
              />
              {children}
            </TabPanel>
            <TabPanel>
              <LawnsDamageForm
                entity="lawns"
                isLoading={isLoading}
                lawnParameters={lawnParameters}
                areaParameters={areaParameters}
              />
              {children}
            </TabPanel>
            <TabPanel>
              <Stack spacing="30px">
                <LawnsOtherForm
                  entity="lawns"
                  isLoading={isLoading}
                  lawnParameters={lawnParameters}
                  areaParameters={areaParameters}
                />
                {children}
              </Stack>
            </TabPanel>
            <TabPanel>
              <Stack spacing="30px">
                <AttachmentsTabContent
                  objectType={objectType[0]?.value as UploadObjectType}
                  media={media}
                  onMediaChange={setMedia}
                  uploadItemId={registryId}
                />
                {children}
              </Stack>
            </TabPanel>
          </TabPanels>
        </Tabs>
      );
    }

    return null;
  }
);
