import React, { useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { FormikProvider, useFormik } from 'formik';
import { createTouchedErrors } from 'features/create-touched-errors';
import { useRequest } from 'hooks/useRequest';
import { Breadcrumb, LoaderItem, PageHeader } from 'components/common';
import { Heading } from '@chakra-ui/react';
import { ERROR_MESSAGES, SUCCESS_MESSAGES } from 'constants/messages';
import { useNotifications } from 'hooks/useNotifications';
import { Form } from './Form';
import { useNSIDictionary } from 'pages/dictionaries/hooks/useNSIDictionary';
import { NSIApiRouteRoot, NSIRootPath } from '../../const/routes';
import { getInitialValues, prepareDictionaryItemForCreateOrUpdate, validate } from './formHelpers';
import { IPrepareResponce } from '../../const/types';
import { DictionaryModalWrapper } from './DictionaryModalWrapper';

export const DictionaryAddItem: React.FC = () => {
  const { dictionaryId } = useParams<{ dictionaryId: string }>();
  const { dictionary, isLoading } = useNSIDictionary(dictionaryId);
  const { successNotify, errorNotify } = useNotifications();
  const history = useHistory();

  const onSuccess = () => {
    successNotify({
      key: 'record/add/success',
      message: SUCCESS_MESSAGES.CREATE,
    });
    history.push(`${NSIRootPath}/${dictionaryId}`);
  };

  const onError = () => {
    errorNotify({
      key: 'record/add/error',
      message: ERROR_MESSAGES.CREATE,
    });
  };

  const onPrepareError = () => {
    errorNotify({
      key: 'prepare/edit/error',
      message: 'Ошибка при проверке наличия дублей',
    });
  };

  const { post: prepare, result: prepareResult } = useRequest<IPrepareResponce>(
    `${NSIApiRouteRoot}/${dictionaryId}/item/prepare`,
    undefined,
    onPrepareError
  );

  const { post } = useRequest(`${NSIApiRouteRoot}/${dictionaryId}/item`, onSuccess, onError);

  const initialValues = useMemo(() => getInitialValues(dictionary), [dictionary]);

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    validate: (values) => validate(values, dictionary?.schema.required),
    initialValues,
    onSubmit: (values) => {
      const data = prepareDictionaryItemForCreateOrUpdate(dictionaryId, values);
      void prepare(data);
    },
  });

  if (isLoading || !dictionary) {
    return <LoaderItem />;
  }

  return (
    <FormikProvider
      value={{
        ...formik,
        errors: createTouchedErrors(formik.errors, formik.touched),
      }}
    >
      <PageHeader>
        <Breadcrumb to={`${NSIRootPath}/${dictionaryId}`}>Вернуться назад</Breadcrumb>
        <Heading>Добавление записи</Heading>
      </PageHeader>
      <Form properties={dictionary?.schema.properties} />
      <DictionaryModalWrapper prepareResponse={prepareResult} queryFunc={post} />
    </FormikProvider>
  );
};
