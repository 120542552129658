import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import { WaterRouteRoot, WaterRouteSurfaceWatersRoot } from '../routes';
import { useRequest } from 'hooks';
import { TDictionaryItemDictionary } from 'models';
import { useCallback, useEffect, useMemo } from 'react';
import { createLocalDateDDMMYYYY } from 'lib/create-date';
import { ISurfaceWaterLoadData, SourceInfo } from './types';
import { SurfaceWaterLocales } from './locales';
import { filter, map } from 'lodash';
import { ColumnWithFilter } from 'lib/utils/tableFilter';
import { TableFilterTypeEnum } from 'components/table/filters/TableFilter';

export const useColumnsSurfaceWater = (parameters?: TDictionaryItemDictionary[]) => {
  const idsForStringFilters = useMemo(
    () => ['7720794a-c0fb-4e2b-bfa9-d25f181de842', 'da9e2b00-7cdc-4657-bba0-1843516204ef'],
    []
  );

  const columns = useMemo<Array<ColumnWithFilter<ISurfaceWaterLoadData>>>(
    () => [
      {
        title: SurfaceWaterLocales.protocolNumber,
        field: 'protocolNumber',
        filterType: TableFilterTypeEnum.number,
      },
      {
        title: SurfaceWaterLocales.sampleDate,
        field: 'sampleDate',
        render: (row: any) => createLocalDateDDMMYYYY(row.sampleDate),
      },
      {
        title: SurfaceWaterLocales.waterObject,
        field: 'waterObject',
        filterType: TableFilterTypeEnum.string,
      },
      {
        title: SurfaceWaterLocales.source,
        field: 'source',
        filterType: TableFilterTypeEnum.string,
      },
      {
        title: SurfaceWaterLocales.region,
        field: 'region',
        filterType: TableFilterTypeEnum.string,
      },
      {
        title: SurfaceWaterLocales.district,
        field: 'district',
        filterType: TableFilterTypeEnum.string,
      },
      {
        title: SurfaceWaterLocales.organization,
        field: 'organization',
        filterType: TableFilterTypeEnum.string,
      },
      ...map(parameters, ({ name, value, parent }) => ({
        title: name,
        field: value,
        render: (row: ISurfaceWaterLoadData) => filter(row.values, (el) => el.parameterId === value && el.unitId === parent)[0]?.value || '',
        filterType: idsForStringFilters.includes(value) ? TableFilterTypeEnum.string : TableFilterTypeEnum.number,
        sorting: false
      })),
    ],
    [idsForStringFilters, parameters]
  );
  return columns;
};

export const useBreadcrumbsSurfaceWater = () => {
  useUpdateBreadcrumbs([
    { breadcrumb: 'Данные', path: '/data' },
    {
      breadcrumb: 'Водные объекты',
      path: WaterRouteRoot,
    },
    {
      breadcrumb: 'Поверхностные воды',
      path: `${WaterRouteSurfaceWatersRoot}/main-data`,
    },
  ]);
};

export const useSourceInfo = (url: string, isLaborarory?: boolean) => {
  const { get, isLoading, result, error } = useRequest<SourceInfo>(url);
  const params = { laboratory: true };

  const getSource = useCallback(() => {
    if (isLaborarory) {
      get({ params });
    } else {
      get();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [get, isLaborarory]);

  useEffect(() => {
    getSource();
  }, [getSource]);

  return {
    isLoading,
    result,
    error,
    get,
  };
};
