import { Button } from 'components/common';
import { Flex } from '@chakra-ui/react';

interface Props {
  filterByPeriod: (amount: number, dimensionValue: any) => void;
}

export const PeriodsFilters = ({ filterByPeriod }: Props) => {
  const periods = [
    {
      title: 'За неделю',
      dimensionValue: 'd',
      amount: 7,
    },
    {
      title: 'За месяц',
      dimensionValue: 'months',
      amount: 1,
    },
    {
      title: 'За год',
      dimensionValue: 'years',
      amount: 1,
    },
    {
      title: 'За 2 года',
      dimensionValue: 'years',
      amount: 2,
    },
  ];

  return (
    <Flex
      flexDirection="row"
      justifyContent="space-between"
      width="fit-content"
    >
      {
        periods.map((period, idx) => (
          <Button
            variant="outline"
            color="#208CFF"
            key={`${period.title}${idx}`}
            onClick={() => filterByPeriod(period.amount, period.dimensionValue)}
          >
            {period.title}
          </Button>
        ))
      }
    </Flex>
  );
};
