import { Modal } from 'components/common/Modals';
import { IFilterValues } from 'components/common';
import { ReperRowItem } from 'hooks/geo/Reper/useGeoReperList';
import React, { useState } from 'react';
import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import { TFiltersArray } from 'models';
import { useAvailability, useDisclosure } from 'hooks';
import { useList } from 'hooks/useList';
import appLocale from 'constants/appLocale';
import { GeoecologicalRootPath } from 'pages/data/GeoecologicalProcesses/consts';
import { DangerouslyGeologicalProcessesHeader } from '../components';
import { ReperFilter } from './ReperFilter';
import { ReperTable } from './Table';
import { UploadFileModalContent } from 'features/UploadFileDragAndDrop/UploadFileModalContent';
import subsystemIdsMap from 'constants/subsystemIdsMap';
import { usePeriodsDict } from 'features/PeriodsButtons/usePeriodsDict';
import { useDictionary } from 'hooks/useDictionary';
import { DEFAULT_PAGE_SIZE_NEW_TABLE } from '../../../../../constants/tables';
import { FullPageWrapper } from '../../../../../components/table/FullPageWrapper';

export const Reper = () => {
  const { isReadOnly, isWrite, isExecute, isNoRights, allAvailable } = useAvailability(subsystemIdsMap.geoecologyRoot);

  useUpdateBreadcrumbs([
    { breadcrumb: appLocale.common.data, path: '/data' },
    {
      breadcrumb: appLocale.subsystems.geoecologicalprocesses,
      path: GeoecologicalRootPath,
    },
    {
      breadcrumb: 'Опасные геологические процессы',
      path: `${GeoecologicalRootPath}/monitoring/danger/reper`,
    },
  ]);

  const { dictionaries } = useDictionary({ url: '/geoecology/v10/geoecology/reper/dictionaries' });

  const {
    refetch,
    isLoading: isLoadingReperList,
    response,
    setFilters,
    toggleOrder,
    onPageChange,
    filterDTO,
    downloadAsFile,
    saveFilters,
    errorText,
    selectedIndexes,
    renderOnlySelectedCheckbox,
    onSubmitFilters,
    onSelectionChange,
    materialTableProps,
  } = useList<ReperRowItem, keyof ReperRowItem>('/geoecology/v10/geoecology/reper/list', {
    initialOrderFieldKey: '-valDateTime',
    preventFirstFetch: true,
    exportSubsystemName: 'reper',
    saveFiltersState: true,
    selectionKey: 'id',
    defaultPageSize: DEFAULT_PAGE_SIZE_NEW_TABLE,
    resetDataOnNextRequest: true,
  });

  const { isOpen: isOpenLoad, onClose: onCloseLoad, onOpen: onOpenLoad } = useDisclosure();

  const [filtersFromGraphic, setFiltersFromGraphic] = useState<Partial<IFilterValues> | null>(null);
  const { result: periods } = usePeriodsDict(`auth/v10/user/period/${subsystemIdsMap.dangerprocesses}`);
  return (
    <FullPageWrapper>
      <DangerouslyGeologicalProcessesHeader
        allAvailable={allAvailable}
        isReadonly={isReadOnly}
        isExecute={isExecute}
        isNoRights={isNoRights}
        isWrite={isWrite}
        onOpenLoad={onOpenLoad}
        active="reper"
      >
        <ReperFilter
          filters={filterDTO?.filters || []}
          periods={periods}
          isLoading={isLoadingReperList}
          isDisabled={isLoadingReperList}
          dictionaries={dictionaries}
          filtersFromGraphic={filtersFromGraphic}
          setFiltersFromGraphic={setFiltersFromGraphic}
          onSubmit={(values) => {
            const filters: TFiltersArray<keyof ReperRowItem> = Object.values(values);
            onSubmitFilters(filters);
          }}
        >
          {renderOnlySelectedCheckbox}
        </ReperFilter>
      </DangerouslyGeologicalProcessesHeader>

      <Modal isOpen={isOpenLoad} onClose={onCloseLoad} header="Загрузка данных из файла">
        <UploadFileModalContent
          createTypesPath={() => '/geoecology/v10/geoecology/reper/load/types'}
          createUploadPath={() => '/geoecology/v10/geoecology/reper/load'}
          types={[
            {
              name: 'Данные измерений реперов и марок',
              value: 'reper',
            },
          ]}
          onClose={onCloseLoad}
          refetch={refetch}
        />
      </Modal>

      <ReperTable
        isExecute={isExecute}
        isWrite={isWrite}
        isReadonly={isReadOnly}
        isNoAction={isNoRights}
        setFilters={setFilters}
        refetch={refetch}
        reperList={response}
        toggleOrder={toggleOrder}
        onPageChange={onPageChange}
        filterDTO={filterDTO}
        filters={filterDTO.filters}
        downloadAsFile={downloadAsFile}
        selectedIndexes={selectedIndexes}
        onSelectionChange={onSelectionChange}
        isLoading={isLoadingReperList}
        saveFilters={saveFilters}
        errorText={errorText}
        materialTableProps={materialTableProps}
      />
    </FullPageWrapper>
  );
};
