import { FormWrapper, Input, Select, Textarea } from 'components/common';
import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { Field, useFormikContext } from 'formik';
import appLocale from 'constants/appLocale';
import { TDictionariesArray } from 'models';
import { getSelectOptionsFromDictionaryByName } from 'features/get-select-options-from-dictionary';
import { GroundCoastListDTO, IGroundCoastFormDto, StvorDto } from 'pages/data/Water/WPZRoot/GroundCoast/consts/types';
import { Box, Grid, Heading, SimpleGrid } from '@chakra-ui/react';

interface Props {
  dictionaries: {
    data: TDictionariesArray;
  } | null;
  isEditMode?: boolean;
  editedObject?: Partial<GroundCoastListDTO>;
}

export const ParametersTab: FC<Props> = ({ children, dictionaries, isEditMode, editedObject }) => {
  const { values, errors, setFieldValue } = useFormikContext<IGroundCoastFormDto>();

  const siteConditions = useMemo(
    () =>
      getSelectOptionsFromDictionaryByName(dictionaries?.data, 'siteConditions', false, editedObject?.siteConditionId),
    [dictionaries?.data, editedObject?.siteConditionId],
  );

  const stvory = useMemo(() => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'stvory'), [dictionaries]);

  const fillStvorList = useCallback(
    (areaId: string) => {
      const filteredStvory = stvory.filter((stvor) => stvor.parent === areaId);

      setFieldValue('stvorList', [
        ...filteredStvory.map((stvor) => ({
          stvorId: stvor.value,
          stvorCode: parseInt(stvor.name),
          coastlinePosition: '',
          latitude: '',
          longitude: '',
          altitude: '',
        })),
      ]);
    },
    [setFieldValue, stvory],
  );

  const resetStvorList = useCallback(() => {
    setFieldValue('stvorList', []);
  }, [setFieldValue]);

  useEffect(() => {
    if (isEditMode) return;

    if (values.areaId) {
      fillStvorList(values.areaId);
    } else {
      resetStvorList();
    }
  }, [fillStvorList, isEditMode, resetStvorList, values.areaId]);

  const stvoryToRender = useMemo<StvorDto[]>(
    () =>
      // sort by stvor name
      values.stvorList.sort((a, b) => a.stvorCode - b.stvorCode),
    [values.stvorList],
  );

  const getIndex = useCallback(
    (stvorId: string) => values.stvorList.findIndex((value) => value.stvorId === stvorId),
    [values.stvorList],
  );

  return (
    <Box flexDirection="column">
      <FormWrapper>
        <Grid marginTop="30px" gap="15px" marginBottom={20}>
          {stvoryToRender.map((stvor) => {
            const index = getIndex(stvor.stvorId);

            return (
              <Box mb={4} key={index}>
                <Heading mb={2} size="md">
                  {`Створ №${stvor.stvorCode}`}
                </Heading>
                <SimpleGrid templateColumns="225px repeat(3, 200px)" columns={4} spacingX="16px">
                  <Field
                    as={Input}
                    label={appLocale.groundCoast.coastlinePosition}
                    name={`stvorList[${index}].coastlinePosition`}
                    value={values.stvorList[index]?.coastlinePosition}
                    type="number"
                  />
                  <Field
                    as={Input}
                    label={appLocale.groundCoast.latitude}
                    name={`stvorList[${index}].latitude`}
                    value={values.stvorList[index]?.latitude}
                    type="number"
                  />
                  <Field
                    as={Input}
                    label={appLocale.groundCoast.longitude}
                    name={`stvorList[${index}].longitude`}
                    value={values.stvorList[index]?.longitude}
                    type="number"
                  />
                  <Field
                    as={Input}
                    label={appLocale.groundCoast.altitude}
                    name={`stvorList[${index}].altitude`}
                    value={values.stvorList[index]?.altitude}
                    type="number"
                  />
                </SimpleGrid>
              </Box>
            );
          })}

          <Field
            key="groundMark"
            as={Input}
            label={appLocale.groundCoast.groundMark}
            name="groundMark"
            value={values.groundMark}
            error={errors.groundMark}
          />

          <Field
            as={Select}
            name="siteConditionId"
            options={siteConditions}
            label={appLocale.groundCoast.siteCondition}
            error={errors?.siteCondition}
          />

          <Heading>{appLocale.groundCoast.dangerProcessDescriptionTitle}</Heading>
          <Field
            key="dangerProcessDescription"
            as={Textarea}
            label={appLocale.groundCoast.dangerProcessDescription}
            name="dangerProcessDescription"
            value={values.dangerProcessDescription}
            error={errors.dangerProcessDescription}
            resize="none"
          />

          {children}
        </Grid>
      </FormWrapper>
    </Box>
  );
};
