import { useCallback } from 'react';
import { useHistory } from 'react-router';
import { DeviceModelsTable, DeviceModelsTableProps } from 'pages/equipment/Models/components/DeviceModelsTable';
import { useEquipmentModelsBreadcrumbs } from './hooks/useEquipmentModelsBreadcrumbs';
import { FullPageWrapper } from '../../../components/table/FullPageWrapper';

type OnEdit = DeviceModelsTableProps['onEdit'];

export const DeviceModels = () => {
  useEquipmentModelsBreadcrumbs();
  const { push } = useHistory();

  const onEdit = useCallback<OnEdit>((item) => {
    push(`/equipment/models/edit/${item.id}/false`, item);
  }, [push]);

  return (
    <FullPageWrapper>
      <DeviceModelsTable
        onEdit={onEdit}
      />
    </FullPageWrapper>
  );
};
