import { useCallback, useMemo } from 'react';

import {
  IChart,
  IChartChartEvent,
  IChartLegendItem,
  IChartOptions,
  IChartOptionsPlugins,
  IChartOptionsPluginsLegend,
  IChartOptionsScales,
  IChartPluginsZoom,
} from '../../types';
import { debounce } from 'lodash';

interface IUseOptions {
  updateData: (value: IChart) => void;
  yScales: IChartOptionsScales;
}

type DeepPartial<T> = {
  [P in keyof T]?: DeepPartial<T[P]>;
};

export const useOptionsForChart = ({ updateData, yScales }: IUseOptions): IChartOptions => {
  const handleResize = useCallback(
    ({ chart }: { chart: IChart; }) => {
      updateData(chart);
    },
    [updateData]
  );

  const handleResizeComplete = useMemo(() =>
    debounce(handleResize, 500),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []);

  const onLegendClick = useCallback((_e: IChartChartEvent, legendItem: IChartLegendItem, legend) => {
    const index = legendItem.datasetIndex;
    const ci = legend.chart;
    if (ci.isDatasetVisible(index)) {
      ci.hide(index);
      legendItem.hidden = true;
    } else {
      ci.show(index);
      legendItem.hidden = false;
    }
  }, []);

  const zoomOptions: DeepPartial<IChartPluginsZoom> = useMemo(
    () => ({
      zoom: {
        wheel: {
          enabled: true,
          modifierKey: 'alt',
        },
        drag: {
          enabled: true,
          backgroundColor: 'rgba(225,225,225,0.5)',
          borderColor: 'rgba(225,225,225)',
          borderWidth: 2,
          threshold: 10,
        },
        onZoomComplete: handleResizeComplete,
        mode: 'x',
      },
      pan: {
        enabled: true,
        modifierKey: 'shift',
        mode: 'x',
        onPanComplete: handleResizeComplete,
      },
    }),
    [handleResizeComplete]
  );

  const legend: Partial<IChartOptionsPluginsLegend> = useMemo(
    () => ({
      position: 'bottom',
      align: 'start',
      onClick: onLegendClick,
    }),
    [onLegendClick]
  );
  // @ts-ignore
  const plugins: IChartOptionsPlugins = useMemo(
    () => ({
      zoom: zoomOptions,
      // @ts-ignore
      stacked100: { enable: true },
      legend,
      htmlLegend: {
        containerID: 'legend-container',
      },
      decimation: {
        enabled: false,
        threshold: 10,
      },
      tooltip: {
        position: 'nearest',
      },
    }),
    [legend, zoomOptions]
  );

  const scales: IChartOptionsScales = useMemo(
    () => ({
      ...yScales,
      x: {
        type: 'time',
        time: {
          tooltipFormat: 'DD.MM.YYYY HH:mm:ss',
          minUnit: 'second',
          displayFormats: {
            second: 'mm:ss',
            minute: 'HH:mm',
            hour: 'DD.MM HH:mm',
            day: 'DD.MM HH:mm',
            week: 'DD.MM',
            month: 'MM.YYYY',
            quarter: 'MM.YYYY',
            year: 'YYYY',
          },
        },
      },
    }),
    [yScales]
  );

  return useMemo(
    () => ({
      responsive: true,
      maintainAspectRatio: false,
      animation: false,
      interaction: {
        mode: 'point',
        intersect: false,
      },
      parsing: false,
      events: ['mousemove', 'mouseout', 'click'],
      plugins,
      scales,
    }),
    [plugins, scales]
  );
};
