import { map } from 'lodash';
import React, { memo, useCallback } from 'react';

import { ConfirmModal } from 'components/common/Modals';
import { ERROR_MESSAGES, REMOVE_CONFIRMATION, SUCCESS_MESSAGES } from 'constants/messages';
import { Disclosure } from 'hooks';
import { useNotifications } from 'hooks/useNotifications';
import { useRequest } from 'hooks/useRequest';

interface DeleteMaskshItemModalProps extends Disclosure {
  id: string;
  url: string;

  refetch(): void;
}

const DeleteRowModal: React.FC<DeleteMaskshItemModalProps> = memo(({ id, url, refetch, isOpen, onClose }) => {
  const { successNotify, errorNotify } = useNotifications();

  const onSuccess = useCallback(() => {
    onClose();

    successNotify({
      key: `masksh/item/${id}/delete/success`,
      message: SUCCESS_MESSAGES.DELETE,
    });

    refetch();
  }, [id, onClose, refetch, successNotify]);

  const onError = useCallback(
    (error?: { descriptions: Array<{ message: string }> } | null) => {
      onClose();

      if (error?.descriptions) {
        errorNotify({
          key: `masksh/item/${id}/delete/error`,
          message: map(error.descriptions, (desc) => desc.message).join('\n'),
        });
      } else {
        errorNotify({
          key: `masksh/item/${id}/delete/error`,
          message: ERROR_MESSAGES.DELETE,
        });
      }

      refetch();
    },
    [errorNotify, id, onClose, refetch],
  );

  const { remove, isLoading } = useRequest<any, { descriptions: Array<{ message: string }> }>(url, onSuccess, onError);

  return (
    <ConfirmModal
      isOpen={isOpen}
      onClose={onClose}
      onClick={() =>
        remove({
          url,
        })
      }
      isLoading={isLoading}
      header={REMOVE_CONFIRMATION}
    />
  );
});

export { DeleteRowModal };
