import React, { FC, useMemo } from 'react';
import { DateInput, FormWrapper, Input, Select } from 'components/common';

import { getSelectOptionsFromDictionaryByName } from 'features/get-select-options-from-dictionary';
import { Field, useFormikContext } from 'formik';
import { TDictionariesArray } from 'models/dictionaries';
import { NSIWaterItem } from 'pages/dictionaries/NSIWater/types';
import { createDateFromResponse, createDateToRequestWithTimezone } from 'lib/create-date';
import { Box, Grid } from '@chakra-ui/react';

interface IProps {
  dictionaries: {
    data: TDictionariesArray;
  } | null;
  editedObject?: any;
  isEditMode?: boolean;
}

const NSIWaterSitesGeneralInformationTab: FC<IProps> = ({ dictionaries, children, editedObject, isEditMode }) => {
  const { setFieldValue, values, errors } = useFormikContext<NSIWaterItem>();

  const statuses = useMemo(() => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'statuses'), [dictionaries]);

  const waterObjects = useMemo(
    () => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'waterObjects'),
    [dictionaries],
  );
  const waterAreasOopt = useMemo(
    () =>
      getSelectOptionsFromDictionaryByName(dictionaries?.data, 'waterAreasOopt', false, editedObject?.oopt as string),
    [dictionaries?.data, editedObject?.oopt],
  );
  const waterAreasSubsystems = useMemo(
    () => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'waterAreasSubsystems'),
    [dictionaries],
  );

  return (
    <Box flexDirection="column">
      <FormWrapper>
        <Grid marginTop="30px" gap="15px">
          <Field
            as={Select}
            name="subsystemId"
            label="Выбрать модуль подсистемы"
            options={waterAreasSubsystems}
            error={errors.subsystemId}
          />

          <Field
            as={Select}
            name="oopt"
            label="Особо охраняемая природная территория"
            options={waterAreasOopt}
            error={errors.oopt}
          />

          <Field
            as={Select}
            name="waterId"
            label="Водный объект (Название)"
            options={waterObjects}
            error={errors.waterId}
          />

          <Field as={Input} name="name" label="Наименование участка" error={errors.name} />

          <Field as={Input} name="code" label="Код участка" error={errors.code} isDisabled={isEditMode} />

          <Field as={Input} name="areaSquare" label="Площадь" error={errors.areaSquare} />

          <Field as={Select} name="status" label="Статус" options={statuses} error={errors.status} />

          <DateInput
            name="startDate"
            label="Начало действия"
            value={createDateFromResponse(values.startDate)}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setFieldValue('startDate', createDateToRequestWithTimezone(e.target.value))
            }
            error={errors.startDate}
          />

          <DateInput
            name="stopDate"
            label="Окончание действия"
            value={createDateFromResponse(values.stopDate)}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setFieldValue('stopDate', createDateToRequestWithTimezone(e.target.value))
            }
            error={errors.stopDate}
          />

          {children}
        </Grid>
      </FormWrapper>
    </Box>
  );
};

export default NSIWaterSitesGeneralInformationTab;
