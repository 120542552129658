import { ILocationFormTab } from 'components/common/LocationFormTab/types';

const locationFormTabInitialEmptyValues: ILocationFormTab = {
  okrugId: null,
  rayonId: null,
  address: '',
  landmark: '',
  latitude: '',
  longitude: '',
};

export function getInitialFormTabInitialEmptyValues(keys?: Array<keyof ILocationFormTab>): Partial<ILocationFormTab> {
  if (!keys) {
    return locationFormTabInitialEmptyValues;
  }

  // @ts-ignore
  return Object.fromEntries(Object.entries(locationFormTabInitialEmptyValues).filter(([key]) => keys.includes(key)));
}

export default locationFormTabInitialEmptyValues;
