import { IFilterItem, TDictionariesArray, TFiltersArray } from 'models';
import React from 'react';
import { FormikProvider, useFormik } from 'formik';
import { IFTPPattern } from '../Consts/types';
import { DataPatternFormFields } from './DataPatternFormFields';

const initialValues: Record<string, IFilterItem<string>> = {
  templateName: {
    key: 'templateName',
    operation: 'CONTAINS',
    value: '',
  },
  subsystemId: {
    key: 'subsystemId',
    operation: 'EQ',
    value: '',
  },
  deleted: {
    key: 'deleted',
    operation: 'EQ',
    value: false,
  }
};

interface IProps {
  dictionaries: { data: TDictionariesArray } | null;
  onSubmitFilters: (filters: TFiltersArray<keyof IFTPPattern>) => void;
}

export const DataPatternFilter = ({ dictionaries, onSubmitFilters }: IProps) => {
  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    initialValues,
    onSubmit: (values: any) => {
      const filters: TFiltersArray<keyof IFTPPattern> = Object.values(values);
      onSubmitFilters(filters);
    },
  });
  return (
    <FormikProvider
      value={{
        ...formik,
      }}
    >
      <DataPatternFormFields dictionaries={dictionaries} />
    </FormikProvider>
  );
};
