import { useRequest } from 'hooks';
import { useCallback } from 'react';
import { RightItem } from 'pages/admin/models/RightItem';

export const useUserRights = () => {
  const { get, result: userRights, isLoading: isRightsLoading } = useRequest<RightItem[]>('/auth/v10/user/right');

  const refetch = useCallback(() => {
    void get();
  }, [get]);

  return {
    get,
    userRights,
    refetch,
    isRightsLoading,
  };
};
