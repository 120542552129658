import appLocale from 'constants/appLocale';

export const analyticToReportPath = '/analytics/report';

export const analyticToReportBreadcrumb = [
  {
    breadcrumb: appLocale.subsystems.analyticsLab,
    path: '/analytics',
  },
  {
    breadcrumb: 'Подготовка заданий',
    path: '/analytics/report',
  },
];
