import { FormWrapper } from 'components/common';
import { Grid } from '@chakra-ui/react';
import React, { Fragment, useEffect, useMemo } from 'react';
import { useFormikContext } from 'formik';
import { IBottomSoilDTO } from 'pages/data/Water/BottomSoil/consts/types';
import appLocale from 'constants/appLocale';
import { TDictionariesArray } from 'models';
import { getSelectOptionsFromDictionaryByName } from 'features/get-select-options-from-dictionary';
import { IRenderFieldOrGroup, useFormField } from 'hooks/forms/useFormField';
import { AnalyticsBottomSoilSchema } from 'models/schemas/analytics/analyticsBottomSoilSchema';
import { ANALYTICS_STATUS } from 'pages/analytics/forms/const';

interface CommonTabProps {
  dictionaries: {
    data: TDictionariesArray;
  } | null;
  isEditMode?: boolean;
}

export const AnalyticsBottomSoilCommonTab = ({ isEditMode, dictionaries }: CommonTabProps): JSX.Element => {
  const { values, setFieldValue } = useFormikContext<IBottomSoilDTO>();

  const { render } = useFormField<IBottomSoilDTO>({
    schema: AnalyticsBottomSoilSchema,
    labelLocalization: { ...appLocale.analytics.fields, ...appLocale.analytics.bottomSoil.fields },
  });

  const organizations = useMemo(
    () => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'organizations'),
    [dictionaries]
  );

  const waterObjects = useMemo(
    () => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'waterObjects'),
    [dictionaries]
  );

  const sources = useMemo(
    () =>
      getSelectOptionsFromDictionaryByName(dictionaries?.data, 'sources').filter(
        (item) => item.parent === values.waterObjectId
      ),
    [dictionaries, values.waterObjectId]
  );

  useEffect(() => {
    const source = sources.find((item) => item.value === values.sourceId);
    if (source && source.parent !== values.waterObjectId) {
      setFieldValue('sourceId', null);
    }

    // бекенд не сохраняет waterObjectId
    if (sources && !values.waterObjectId && source?.parent) {
      setFieldValue('waterObjectId', source?.parent);
    }
  }, [setFieldValue, sources, values.sourceId, values.waterObjectId]);

  // while status is hidden assume status as draft
  let fieldsIsDisabled = values.status === ANALYTICS_STATUS.APPROVED && !!isEditMode;
  fieldsIsDisabled = false;

  const fields: IRenderFieldOrGroup<IBottomSoilDTO>[] = [
    { name: 'protocolNumber', isDisabled: fieldsIsDisabled },

    { name: 'protocolDate', type: 'dateinput', isDisabled: fieldsIsDisabled },

    { name: 'consumerId', type: 'select', options: organizations, isDisabled: fieldsIsDisabled },

    { name: 'researchBasis', isDisabled: fieldsIsDisabled },

    { name: 'researchObject', isDisabled: fieldsIsDisabled },

    { name: 'supportingDocuments', isDisabled: fieldsIsDisabled },

    { name: 'sampleNumber', isDisabled: fieldsIsDisabled },

    {
      group: true,
      name: appLocale.analytics.fields.address,
      children: [
        { name: 'waterObjectId', type: 'select', options: waterObjects, isDisabled: fieldsIsDisabled },

        { name: 'sourceId', type: 'select', options: sources, isDisabled: fieldsIsDisabled || !values.waterObjectId },
      ],
    },

    { name: 'samplePlace', isDisabled: fieldsIsDisabled },

    { name: 'sampleDate', type: 'datetimeinput', isDisabled: fieldsIsDisabled },

    { name: 'organizationId', type: 'select', options: organizations, isDisabled: fieldsIsDisabled },

    { name: 'explanationFromConsumer', isDisabled: fieldsIsDisabled },

    { name: 'sampleReceiveDate', type: 'datetimeinput', isDisabled: fieldsIsDisabled },

    { name: 'researchDateStart', type: 'dateinput', isDisabled: fieldsIsDisabled },

    { name: 'researchDateEnd', type: 'dateinput', isDisabled: fieldsIsDisabled },

    { name: 'sampleLaboratoryNumber', isDisabled: fieldsIsDisabled },

    { name: 'explanation', isDisabled: fieldsIsDisabled },
  ];

  return (
    <FormWrapper>
      <Grid marginTop="30px" gap="15px">
        {fields.map((field) => (
          <Fragment key={field.name}>{render(field)}</Fragment>
        ))}
      </Grid>
    </FormWrapper>
  );
};
