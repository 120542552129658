import { apiPathsWaterRoot } from '../apiPath';

const root = `${apiPathsWaterRoot}/water/surfacewater`;

export const apiPathSurfaceWater = {
  root,
  measurements: `${root}/value_list`,
  list: `${root}/list`,
  apply: `${root}/apply`,
  preview: `${root}/preview`,
  types: `${root}/types`,
  validate: `${root}/validate`,
  dict: `${root}/dictionaries`,
  station: (stationId: string) => `${root}/dictionaries/stations/${stationId}`,
  sourceInfo: (id: string) => `${root}/dictionaries/sources/${id}`,
};
