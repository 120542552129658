import { AxiosRequestConfig } from 'axios';
import { useRequest, UseRequestReturnType } from 'hooks/useRequest';
import { ApiError, FilePreviewErrors } from 'models';
import { useCallback } from 'react';
import { useNotifications } from 'hooks/useNotifications';
import { ERROR_MESSAGES } from 'constants/messages';

export interface FilePreviewApplyResponse {
  errors: FilePreviewErrors;
  result: boolean;
}

interface UseFilePreviewApplyReturnType
  extends Omit<UseRequestReturnType<FilePreviewApplyResponse>, 'get' | 'post' | 'put' | 'remove'> {
  applyFilePreview: (
    body?: Record<string, unknown> | FormData | undefined,
    config?: AxiosRequestConfig | undefined
  ) => Promise<FilePreviewApplyResponse | ApiError | null> | undefined;
}

interface UseFilePreviewApply {
  (url?: string, successHandler?: (data: FilePreviewApplyResponse | null) => void): UseFilePreviewApplyReturnType;
}

const useFilePreviewApply: UseFilePreviewApply = (url = '', successHandler) => {
  const { errorNotify } = useNotifications();

  const errorHandler = (data: ApiError | null) => {
    errorNotify({
      key: 'api/error',
      message: ERROR_MESSAGES.UPLOAD + (data?.status ? `: ${data.status}` : ''),
    });
  };

  const { post, result, error, isLoading, reset } = useRequest<FilePreviewApplyResponse>(
    url,
    successHandler,
    errorHandler,
  );

  const applyFilePreview = useCallback(
    (...parameters: Parameters<typeof post>) => {
      if (url) {
        return post(parameters[0], parameters[1]);
      }
    },
    [post, url],
  );

  return {
    applyFilePreview,
    result,
    error,
    isLoading,
    reset,
  };
};

export type { UseFilePreviewApplyReturnType, UseFilePreviewApply };
export { useFilePreviewApply };
