import { AddIcon, ArrowDownIcon, DownloadIcon } from 'components/icons';
import { Breadcrumb, Button, DefaultText } from 'components/common';
import React, { memo, PropsWithChildren, useState } from 'react';
import appLocale from 'constants/appLocale';
import { Box, ButtonProps, Flex, Heading } from '@chakra-ui/react';

export interface SubsystemHeaderProps {
  onUploadClick?: () => void;
  title?: string;
  titleMultiline?: boolean,
  subTitle?: string;
  addButtonText?: string;
  addButtonProps?: ButtonProps;
  anyButtonText?: string;
  anyButtonProps?: ButtonProps;
  filtersComponent?: React.ReactNode;
  navigationTabs?: React.ReactNode;
  defaultFiltersOpen?: boolean;
  onTitleClick?: () => void;
  backPath?: string;
  isUploadDisabled?: boolean;
  isFilterDisabled?: boolean;
  titleSize?: string;
}

const _SubsystemHeader = ({
  subTitle,
  title,
  titleMultiline = false,
  onUploadClick,
  children,
  addButtonText = appLocale.actions.addRecord,
  addButtonProps,
  anyButtonText,
  anyButtonProps,
  filtersComponent,
  navigationTabs,
  defaultFiltersOpen,
  onTitleClick,
  backPath,
  isUploadDisabled,
  isFilterDisabled,
  titleSize,
}: PropsWithChildren<SubsystemHeaderProps>) => {
  const [filtersOpen, setFiltersOpen] = useState(Boolean(defaultFiltersOpen));

  return (
    <>
      <Box
        padding="4px 8px"
        backgroundColor="panel.background.default"
        borderBottom="1px solid"
        borderBottomColor="panel.border.default"
        alignItems="center"
        width="100%"
      >
        {backPath && <Breadcrumb to={backPath}>Вернуться назад</Breadcrumb>}

        <Flex
          justifyContent="space-between"
          alignItems={navigationTabs ? 'flex-start' : 'center'}
          flexDirection={navigationTabs ? 'column' : 'row'}
        >
          {title ? (
            <Heading
              style={{
                whiteSpace: titleMultiline ? 'initial' : 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                paddingRight: '8px',
                cursor: onTitleClick ? 'pointer' : 'initial',
              }}
              onClick={onTitleClick}
              size={titleSize}
            >
              {title}
            </Heading>
          ) : null}

          {subTitle ? (
            <DefaultText fontWeight="bold">
              {subTitle}
            </DefaultText>
          ) : null}

          {(addButtonProps || onUploadClick || filtersComponent || navigationTabs || anyButtonProps) && (
            <Flex width={navigationTabs ? '100%' : 'auto'} marginTop={0} gap="12px">
              {navigationTabs && <Box marginRight="auto">{navigationTabs}</Box>}

              {anyButtonProps && (
                <Button
                  variant="ghost"
                  color="PrimaryButton.500"
                  size="sm"
                  p={0}
                  {...anyButtonProps}
                >
                  {anyButtonText}
                </Button>
              )}

              {addButtonProps && (
                <Button
                  leftIcon={<AddIcon />}
                  variant="ghost"
                  color="PrimaryButton.500"
                  size="sm"
                  p={0}
                  {...addButtonProps}
                >
                  {addButtonText}
                </Button>
              )}

              {onUploadClick && (
                <Button
                  leftIcon={<DownloadIcon />}
                  variant="ghost"
                  color="PrimaryButton.500"
                  size="sm"
                  onClick={onUploadClick}
                  isDisabled={isUploadDisabled}
                  p={0}
                >
                  {appLocale.actions.uploadFromFile}
                </Button>
              )}

              {filtersComponent && (
                <Button
                  rightIcon={<ArrowDownIcon style={{ transform: `rotate(${filtersOpen ? '180deg' : '0deg'})` }} />}
                  variant="ghost"
                  color="PrimaryButton.500"
                  size="sm"
                  onClick={() => setFiltersOpen((prevState) => !prevState)}
                  p={0}
                  isDisabled={isFilterDisabled}
                >
                  Фильтры
                </Button>
              )}
            </Flex>
          )}
        </Flex>
      </Box>

      {children && (
        <Box
          backgroundColor="panel.background.default"
          borderBottom="1px solid"
          borderBottomColor="panel.border.default"
          padding="4px 8px"
        >
          {children}
        </Box>
      )}

      {filtersOpen && filtersComponent && (
        <Box
          padding="4px 8px"
          backgroundColor="panel.background.default"
          borderBottom="1px solid"
          borderBottomColor="panel.border.default"
          alignItems="center"
        >
          {filtersComponent}
        </Box>
      )}
    </>
  );
};

export const SubsystemHeader = memo(_SubsystemHeader);
