import { useParams } from 'react-router-dom';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { IUploadMedia } from 'components/common/FileUploadDragAndDrop/types';
import { FormikProvider, useFormik } from 'formik';
import { createTouchedErrors } from 'features/create-touched-errors';
import { ApiError } from 'models';
import { useEnhancedNotification, useNotifications, useRequest } from 'hooks';
import { useObjectType } from 'hooks/useObjectType';
import { formatErrorMessage } from 'lib/utils/formatErrorMessage';
import { ERROR_MESSAGES } from 'constants/messages';
import { updateFiles } from 'api/services/media';
import { LoaderItem } from 'components/common';
import { AnalyticsPelNoiseFormFields } from 'pages/analytics/Raids/PelNoise/AnalyticsPelNoiseFormFields';
import { IReportProtocolForm, IReportProtocolFormValue, ReportProtocolGetDto } from 'pages/analytics/Raids/types';
import { initialNoiseEmptyValues } from './initialNoiseEmptyValues';
import { IFormikPoint, IPoint } from './types';
import { pointCreator } from './Utils';
import { getSelectOptionsFromDictionaryByName } from 'features/get-select-options-from-dictionary';
import { generateUUID } from 'lib/utils/generateUUID';
import { useGoBackWithFallback } from 'lib/utils/goBackWithFallback';
import { useDictionary } from 'hooks/useDictionary';

const AnalyticsPelNoiseEdit = () => {
  const [browserId] = useState(generateUUID());
  const { id } = useParams<{ id: string }>();

  const { backWithFallback } = useGoBackWithFallback();

  const [media, setMedia] = useState<IUploadMedia[]>([]);

  const { isDictionariesLoading, objectType, dictionaries } = useDictionary({ url: '/appeal/v10/protocols/dictionaries' });
  const _objectType = useObjectType(objectType);

  const { onSuccessCreate } = useEnhancedNotification();
  const { errorNotify } = useNotifications();

  const noiseParameters = useMemo(
    () => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'noiseParameters'),
    [dictionaries]
  );

  const { get, result: protocol } = useRequest<ReportProtocolGetDto>(`/appeal/v10/protocols/noise/${id}`);
  useEffect(() => {
    void get();
  }, [get]);

  const onError = useCallback(
    (error: ApiError | string | null) => {
      const message = formatErrorMessage(error);
      errorNotify({
        key: message || 'file/add/error',
        message: message || ERROR_MESSAGES.EDIT,
      });
    },
    [errorNotify]
  );

  const onSuccess = (response: ReportProtocolGetDto | null) => {
    if (response?.id) {
      onSuccessCreate();
      updateFiles(response.id, _objectType, media)
        .then(() => {
          backWithFallback('/analytics');
        })
        .catch((error: Error) => {
          onError(error.message);
        });
    }
  };

  const { put } = useRequest('', onSuccess, onError);

  const initialValues = useMemo(() => {
    const _values: Record<string, IReportProtocolFormValue> = {};
    protocol?.meteoValues?.forEach((value: any) => {
      if (value.parameterId) {
        _values[value.parameterId] = value;
      }
    });

    return {
      ...initialNoiseEmptyValues,
      ...protocol,
      points: protocol?.points?.map((point: IPoint) => pointCreator(noiseParameters, 0, point)),
      meteoValues: _values,
    };
  }, [protocol, noiseParameters]);

  const formik = useFormik<IReportProtocolForm>({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnMount: false,
    validateOnBlur: false,
    initialValues,
    onSubmit: (values: any) => {
      void put(
        {
          ...values,
          meteoValues: Object.values(values.meteoValues),
          points: values.points.map((point: IFormikPoint) => ({
            ...point,
            values: [...Object.values(point.values)],
          })),
        },
        { url: '/appeal/v10/protocols/noise/edit' }
      );
    },
  });

  if (isDictionariesLoading) {
    return <LoaderItem />;
  }

  return (
    <div>
      <FormikProvider
        value={{
          ...formik,
          errors: createTouchedErrors(formik.errors, formik.touched),
        }}
      >
        <AnalyticsPelNoiseFormFields
          dictionaries={dictionaries}
          media={media}
          onMediaChange={setMedia}
          objectType={_objectType}
          isLoading={isDictionariesLoading}
          browserId={browserId}
          isEditMode
        />
      </FormikProvider>
    </div>
  );
};

export default AnalyticsPelNoiseEdit;
