import { Filter } from 'features/Filter';
import { createInitialValuesToFormik, createValuesToRequest } from 'features/Filter/utils';
import React, { useMemo } from 'react';
import { IFilterItem, TDictionaryItemDictionary, TFilterDTO } from 'models';
import combineFilters from 'lib/utils/combineFilters';
import { transformFiltersArray } from 'features/Filter/transformFiltersArray';
import { ISOKFireJournalListItem } from 'pages/analyticsReports/Reports/SOKReportList/SOKFireJournalReport/types';

type FilterListInitialValues = Record<'dateTimeMin' | 'dateTimeMax' | 'admAreaId' | 'districtId' | 'cameraType' | 'deleted',
  TFilterDTO<Partial<keyof ISOKFireJournalListItem>>['filters'][number]>;

interface FilterProps {
  isLoading: boolean;
  onSubmit: (values: TFilterDTO<Partial<keyof ISOKFireJournalListItem>>['filters']) => void;
  filters: IFilterItem[];
  cameraTypes?: TDictionaryItemDictionary[];
  admAreas?: TDictionaryItemDictionary[];
  districts?: TDictionaryItemDictionary[];
}

const initialValues: FilterListInitialValues = {
  dateTimeMin: {
    key: 'date',
    operation: 'GTE',
    value: null,
  },
  dateTimeMax: {
    key: 'date',
    operation: 'LTE',
    value: null,
  },
  districtId: {
    key: 'districtId',
    operation: 'IN',
    value: null,
  },
  admAreaId: {
    key: 'admAreaId',
    operation: 'IN',
    value: null,
  },
  cameraType: {
    key: 'cameraType',
    operation: 'IN',
    value: null,
  },
  deleted: {
    key: 'deleted',
    operation: 'EQ',
    value: false,
  },
};

const initialValuesToFormik = createInitialValuesToFormik(initialValues);

function SOKFireJournalReportFilter(
  {
    isLoading,
    onSubmit,
    children,
    filters,
    cameraTypes,
    admAreas,
    districts,
  }: React.PropsWithChildren<FilterProps>
) {
  const presetValues = useMemo<{ key: string, value: IFilterItem['value'] }[]>(() => {
    const preset: { key: string, value: IFilterItem['value'] }[] = [];
    const initialFilters = combineFilters(initialValues, filters);
    Object.keys(initialFilters)
      .forEach((key) => {
        preset.push({ key: `${key}`, value: initialFilters[key].value });
      });
    return preset;
  }, [filters]);

  return (
    <Filter<typeof initialValuesToFormik>
      isLoading={isLoading}
      isDisabled={isLoading}
      onSubmit={(values) => {
        const v = createValuesToRequest(initialValues, values);
        const filters = transformFiltersArray<TFilterDTO<Partial<keyof ISOKFireJournalListItem>>['filters']>(
          Object.values(v),
        );
        onSubmit(filters);
      }}
      initialValues={initialValuesToFormik}
      mainFields={[
        {
          type: 'DATE',
          name: 'dateTimeMin',
          label: 'От',
        },
        {
          type: 'DATE',
          name: 'dateTimeMax',
          label: 'До',
        },
        {
          type: 'MULTI_SELECT',
          name: 'admAreaId',
          label: 'Округ',
          options: admAreas,
        },
        {
          type: 'MULTI_SELECT',
          name: 'districtId',
          label: 'Район',
          options: districts,
        },
        {
          type: 'MULTI_SELECT',
          name: 'cameraType',
          label: 'Вид камеры',
          options: cameraTypes,
        },
      ]}
      presetValues={presetValues}
    >
      {children}
    </Filter>
  );
}

export { SOKFireJournalReportFilter };
