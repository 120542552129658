import { FormHeader } from 'components/common';
import { ERROR_MESSAGES, SUCCESS_MESSAGES } from 'constants/messages';
import { createTouchedErrors } from 'features/create-touched-errors';
import { FormikProvider, useFormik } from 'formik';
import { useNotifications } from 'hooks/useNotifications';
import { useRequest } from 'hooks/useRequest';
import { ReportSchema } from 'models/schemas/admin/report';

import { useHistory, useParams } from 'react-router';
import { ReportItem } from '../../models/ReportItem';
import { useTypedLoader } from '../../typedLoader';
import { baseAdminReportUrl } from '../consts';
import FieldsForm from './FieldsForm';

export const EditReportForm = () => {
  const history = useHistory();

  const { id } = useParams<{
    id: string;
  }>();

  const { isLoading: isReportLoading, result: currentReport } = useTypedLoader<ReportItem>(
    `${baseAdminReportUrl}/${id}`
  );

  const initialValues = { ...currentReport };

  const { successNotify, errorNotify } = useNotifications();

  const successHandler = () => {
    successNotify({
      key: 'file/create/success',
      message: SUCCESS_MESSAGES.EDIT,
    });
    history.push('/admin/report');
  };
  const errorHandler = (error: any) => {
    errorNotify({
      key: 'file/create/error',
      message: `${ERROR_MESSAGES.EDIT}. ${typeof error === 'string' ? error : ''}`,
    });
  };

  const { put: updateReport } = useRequest(`${baseAdminReportUrl}/${id}`, successHandler, errorHandler);

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    initialValues,
    validationSchema: ReportSchema,
    onSubmit: (values) => updateReport(values),
  });

  return (
    <FormikProvider
      value={{
        ...formik,
        errors: createTouchedErrors(formik.errors, formik.touched),
      }}
    >
      <FormHeader url="/admin/report" header={`Редактирование отчета: '${currentReport?.name}''`} />
      <FieldsForm onCancel={() => history.push('/admin/report')} isLoading={isReportLoading} />
    </FormikProvider>
  );
};
