import { FormHeader } from 'components/common';
import { createTouchedErrors } from 'features/create-touched-errors';
import { FormikProvider, useFormik } from 'formik';
import { useNotifications } from 'hooks/useNotifications';
import { useRequest } from 'hooks/useRequest';
import { useHistory } from 'react-router';
import { ERROR_MESSAGES, SUCCESS_MESSAGES } from 'constants/messages';
import moment from 'moment';
import { createLocalDateISO8601 } from 'lib/create-date';
import { UserSchema } from 'models/schemas/admin/user';
import { baseAdminUserUrl } from '../consts';
import FieldsForm from './FieldsForm';
import { usePaginatedLoader } from '../../typedLoader';
import { DisplayUserItem } from '../../models/UserItem';
import { UserFormObj } from '../UserFormObj';

const initialValues: UserFormObj = {
  user: {
    code: '',
    name: '',
    surName: '',
    middleName: '',
    type: '',
    status: '',
    email: '',
    phone: '',
    departmentId: undefined,
    dateAccountCreation: createLocalDateISO8601(moment().format()),
    dateAccountDeactivation: createLocalDateISO8601(moment().format()),
    id: undefined,
    changePasswordRequest: true,
    deleted: false,
    organizationId: undefined,
    lastLogin: ''
  },
  roles: [],
  subsystems: [],
  rights: [],
  bans: [],
  userReports: [],
  userDictionaries: [],
  periods: [],
  deniedDistricts: [],
  deniedSources: [],
  parametersDeny: [],
};

export const AddUserForm = () => {
  const history = useHistory();
  const { successNotify, errorNotify } = useNotifications();

  const { isLoading: isUsersLoading } = usePaginatedLoader<DisplayUserItem>(baseAdminUserUrl);

  const successHandler = () => {
    successNotify({
      key: 'file/create/success',
      message: SUCCESS_MESSAGES.CREATE,
    });
    history.push('/admin/user');
  };
  const errorHandler = (error: any) =>
    errorNotify({
      key: 'file/create/error',
      message: `${ERROR_MESSAGES.CREATE}. ${typeof error === 'string' ? error : ''}`, // TODO - Переделать позже
    });

  const uri: string = `${baseAdminUserUrl}/full`;
  const { post: createUser, isLoading: isUserSaving } = useRequest(uri, successHandler, errorHandler);

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    validationSchema: UserSchema,
    initialValues,
    onSubmit: async (values: UserFormObj) => {
      const rawUser = {
        ...values.user,
        rights: [...values.userDictionaries.map((v:any) => v.rightId),
          ...values.userReports.map((v:any) => v.rightId),
          ...values.rights
        ],
        periods: values.periods.map((p: any) => ({
          periodId: p.id,
          subsystemId: p.subsystem,
        })),
        bans: values.bans,
        roles: values.roles,
        subsystems: values.subsystems,
        deniedDistiricts: values.deniedDistricts,
        deniedSources: values.deniedSources,
        deniedParams: values.parametersDeny
      };

      await createUser(rawUser);
    },
  });

  const isLoading = isUsersLoading || isUserSaving;

  return (
    <FormikProvider
      value={{
        ...formik,
        errors: createTouchedErrors(formik.errors, formik.touched),
      }}
    >
      <FormHeader url="/admin/user" header="Добавление учетной записи пользователя" />
      <FieldsForm
        onCancel={() => history.push('/admin/user')}
        isLoading={isLoading}
      />
    </FormikProvider>
  );
};
