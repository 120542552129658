import { GreenPlantsApiRootPath, GreenPlantsRootPath } from 'pages/routes/GreenPlants';
import { useNotifications } from 'hooks/useNotifications';
import { useRequest } from 'hooks/useRequest';
import { forEach, reduce, values } from 'lodash';
import { ApiError } from 'models';
import { useCallback } from 'react';
import { SUCCESS_MESSAGES } from 'constants/messages';
import { TGreenPlantsForm, TParamsToPost } from 'pages/data/GreenPlants/Form/types';

const useLawnsAddItem = () => {
  const { successNotify, errorNotify } = useNotifications();

  const { result, isLoading, error, post } = useRequest<any, ApiError & { descriptions: Array<{ message: string }> }>(
    `${GreenPlantsApiRootPath}/lawns`,
    () => {
      successNotify({
        key: `${GreenPlantsRootPath}/lawns/add/success`,
        message: SUCCESS_MESSAGES.CREATE,
      });
    },
    (error) => {
      forEach(error?.descriptions, (desc) => {
        errorNotify({
          key: `${GreenPlantsRootPath}/lawns/add/error`,
          message: desc.message,
        });
      });
    }
  );

  const addLawns = useCallback(
    (request?: TGreenPlantsForm['lawns']) => {
      const parameters = reduce(
        values(request?.parameters),
        (acc, current) => {
          if (current) {
            if (current.parameterId) {
              let currentValues = [];
              if (Array.isArray(current.value)) {
                currentValues = current.value.map((val, idx) => {
                  const values: TParamsToPost = {
                    parameterId: current.parameterId,
                    value: val,
                  };
                  if (current.dictionaryItemId && current.dictionaryItemId[idx]) {
                    values.dictionaryItemId = current.dictionaryItemId[idx];
                  }
                  return {
                    value: val,
                    dictionaryItemId: current.dictionaryItemId ? current.dictionaryItemId[idx] : null,
                    parameterId: current.parameterId,
                  };
                });
              } else {
                currentValues = [
                  {
                    dictionaryItemId: current.dictionaryItemId ? current.dictionaryItemId[0] : null,
                    value: current.value,
                    parameterId: current.parameterId,
                  },
                ];
                if (current.dictionaryItemId && current.dictionaryItemId[0]) {
                  const [dictionaryItemId] = current.dictionaryItemId;
                  currentValues[0].dictionaryItemId = dictionaryItemId;
                }
              }
              return { ...acc, [current.parameterId]: currentValues };
            }
          }
          return acc;
        },
        {}
      );
      return post({ ...request, parameters });
    },
    [post]
  );

  return { addLawns, result, isLoading, error };
};

export { useLawnsAddItem };
