import { ERROR_MESSAGES } from 'constants/messages';
import { createTouchedErrors } from 'features/create-touched-errors';
import { FormikProvider, useFormik } from 'formik';
import { useNotifications } from 'hooks/useNotifications';
import { useRequest } from 'hooks/useRequest';
import { useParams } from 'react-router-dom';
import FieldsForm from './FieldsForm';
import React, { useCallback, useEffect, useState } from 'react';
import { updateFiles } from 'api/services/media';
import { IUploadMedia } from 'components/common/FileUploadDragAndDrop/types';
import { useObjectType } from 'hooks/useObjectType';
import { WaterRoutesBottomSoil } from 'pages/data/Water/routes';
import { IBottomSoilDTO } from 'pages/data/Water/BottomSoil/consts/types';
import { ApiError } from 'models';
import { formatErrorMessage } from 'lib/utils/formatErrorMessage';
import { BottomSoilSchema } from 'models/schemas/water/bottomsoil';
import { BottomSoilApiPaths } from 'pages/data/Water/BottomSoil/consts/apiPaths';
import { bottomSoilInitialEmptyValues } from 'pages/data/Water/BottomSoil/BottomSoilForms/initialEmptyValues';
import { useEnhancedNotification } from 'hooks';
import { useGoBackWithFallback } from 'lib/utils/goBackWithFallback';
import { useDictionary } from 'hooks/useDictionary';
import { LoaderItem } from '../../../../../components/common';

export const BottomSoilEdit = () => {
  const { backWithFallback } = useGoBackWithFallback();
  const { id } = useParams<{ id: string }>();
  const { dictionaries, isDictionariesLoading } = useDictionary({ url: BottomSoilApiPaths.dictionaries });
  const objectType = useObjectType(dictionaries);
  const { errorNotify } = useNotifications();
  const { onSuccessEdit } = useEnhancedNotification();
  const [media, setMedia] = useState<IUploadMedia[]>([]);
  const [editedObject, setEditedObject] = useState<Partial<IBottomSoilDTO>>({ ...bottomSoilInitialEmptyValues });

  const onItemLoad = useCallback((response) => {
    if (response) {
      setEditedObject(response);
    }
  }, []);

  const { get, isLoading } = useRequest<IBottomSoilDTO>(BottomSoilApiPaths.getOrDelete(id), onItemLoad);

  useEffect(() => {
    void get();
  }, [get]);

  const onError = useCallback(
    (message?: ApiError | string | null) => {
      const msg = formatErrorMessage(message);
      errorNotify({
        key: msg || 'file/edit/error',
        message: msg || ERROR_MESSAGES.EDIT,
      });
    },
    [errorNotify],
  );

  const onSuccess = useCallback(() => {
    onSuccessEdit();
    if (editedObject.id) {
      updateFiles(editedObject.id, objectType, media)
        .then(() => {
          backWithFallback(`${WaterRoutesBottomSoil.root}/main-data`);
        })
        .catch((error: Error) => {
          onError(error.message);
        });
    }
  }, [backWithFallback, editedObject.id, media, objectType, onError, onSuccessEdit]);

  const { put, isLoading: isPutLoading } = useRequest<IBottomSoilDTO>(BottomSoilApiPaths.root, onSuccess, onError);

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    initialValues: editedObject,
    onSubmit: (values) => {
      void put(values);
    },
    validationSchema: BottomSoilSchema,
  });

  if (isPutLoading || isDictionariesLoading) {
    return <LoaderItem />;
  }

  return (
    <FormikProvider
      value={{
        ...formik,
        errors: createTouchedErrors(formik.errors, formik.touched),
      }}
    >
      <FieldsForm
        dictionaries={dictionaries}
        onCancel={() => {
          backWithFallback(`${WaterRoutesBottomSoil.root}/main-data`);
        }}
        isLoading={isDictionariesLoading || isLoading}
        onMediaChange={setMedia}
        media={media}
        uploadItemId={id}
        isEditMode
        url={WaterRoutesBottomSoil.root}
        editedObject={editedObject}
      />
    </FormikProvider>
  );
};
