import React, { useMemo } from 'react';
import { Field, FieldArray, Form, useFormikContext } from 'formik';
import { Box, Flex, Grid, GridItem, Heading, Stack } from '@chakra-ui/react';
import { Button, DateInput, Input, LoaderItem, Select } from 'components/common';
import BurningJournalObservationPlace from './BurningJournalObservationPlace';
import { IMainObservationPlace, IMainReport } from '../../NoiseReport/types';
import { getSelectOptionsFromDictionaryByName } from 'features/get-select-options-from-dictionary';
import { appealRoot, tasksDictionariesPath } from 'pages/citizenAppeal';
import { AddIcon } from 'components/icons';
import { IUploadMedia } from 'components/common/FileUploadDragAndDrop/types';
import { MapForPels } from 'features/PelsMap/MapForPels';
import { createUTCTime } from 'lib/create-date';
import { useGoBackWithFallback } from 'lib/utils/goBackWithFallback';
import { useDictionary } from 'hooks/useDictionary';

interface IProps {
  taskId?: string;
  onSave: () => void;
  media: IUploadMedia[][];
  onMediaSave: (media: IUploadMedia[], idx: number) => void;
  backPath: string;
  browserId: string;
}

const BurningJournalForm = ({ taskId, onSave, media, onMediaSave, backPath, browserId }: IProps) => {
  const { backWithFallback } = useGoBackWithFallback();
  const { values, handleChange, errors, setFieldValue, initialValues } = useFormikContext<IMainReport>();
  const { dictionaries, isDictionariesLoading: isPelDictsLoading } = useDictionary({ url: tasksDictionariesPath });
  const { dictionaries: appealDictionary, isDictionariesLoading: isAppealsDictsLoading } = useDictionary({
    url: '/appeal/v10/appeals/dictionaries',
  });
  const districts = useMemo(
    () => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'districts'),
    [dictionaries],
  );
  const regions = useMemo(() => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'regions'), [dictionaries]);
  const odorSourceTypeId = useMemo(
    () =>
      getSelectOptionsFromDictionaryByName(
        appealDictionary?.data,
        'odorSourceTypeId',
        false,
        initialValues.points.map((item) => item.odorSourceTypeId),
      ),
    [appealDictionary?.data, initialValues.points],
  );

  const filteredRegions = useMemo(
    () => regions.slice().filter((item) => item.parent === values.taskDistrictIds[0]),
    [regions, values.taskDistrictIds],
  );

  const {
    taskTypes,
    raidTypeId,
    taskReasonId,
    taskPriorityId,
    raidResultId,
    raidShiftId,
    pelStations,
    dictionaries: reportsDicts,
    isDictionariesLoading: isReportsDictsLoading,
  } = useDictionary({ url: `${appealRoot}/reports/dictionaries` });

  const pointTypeId = useMemo(
    () =>
      getSelectOptionsFromDictionaryByName(
        reportsDicts?.data,
        'pointTypeId',
        false,
        initialValues.points.map((item) => item.pointTypeId),
      ),
    [initialValues.points, reportsDicts?.data],
  );

  const formattedRaidResultIds = useMemo(
    () =>
      raidResultId
        ?.filter(
          (item) =>
            item.deleted !== true || initialValues.points.map((item) => item.raidResultText).includes(item.name),
        )
        .map((item) => `${item.name}${item.deleted ? ' (удал.)' : ''}`),
    [initialValues.points, raidResultId],
  );

  const createPoint = (): IMainObservationPlace => ({
    pointAddress: '',
    workTiming: '',
    id: '',
    pointLongitude: '',
    isRepeat: false,
    aiProtocolNumber: '',
    pointNumber: '',
    pelProtocolDate: '',
    isInformationBoard: false,
    isInformationBoardNMEPD: false,
    isSetPelProtocol: false,
    constructionObject: '',
    pelProtocolNumber: '',
    protocolExecutorUserId: '',
    report: '',
    measurementTimeTo: null,
    objectTypeId: '',
    aiProtocolDate: '',
    pointLatitude: '',
    generalContractor: '',
    raidResultId: '',
    isSetAiProtocol: false,
    isWorkDone: false,
    hasExcess: false,
    territoryTypeId: '',
    isViolation: false,
    pointCoordinateArray: '',
    protocolNumber: '',
    measurementTimeFrom: null,
    pointTypeId: '',
    protocolDate: '',
    noiseCharacterId: '',
    customer: '',
    pelStationNumber: null,
    isGarbagePileFound: false,
    isBurningSmellFound: false,
    temporaryId: '',
    raidResultText: '',
  });

  return (
    <Form>
      {isAppealsDictsLoading || isReportsDictsLoading || isPelDictsLoading ? (
        <LoaderItem />
      ) : (
        <>
          <Grid templateColumns="repeat(4, 1fr)" gap={10} marginY={5}>
            <Field as={Input} name="raidNumber" label="№ рейда" onChange={handleChange} error={errors?.raidNumber} />
            <GridItem>
              <Field
                as={Input}
                name="taskNumber"
                label="№ задания"
                value={values.taskNumber}
                onChange={handleChange}
                error={errors?.taskNumber}
              />
            </GridItem>
            <Field
              as={DateInput}
              name="raidDate"
              label="Дата проведения рейда"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setFieldValue(e.target.name, createUTCTime(e.target.value))
              }
              error={errors?.raidDate}
            />
            <Field
              as={Select}
              name="raidShiftId"
              label="Время суток"
              options={raidShiftId}
              onChange={handleChange}
              error={errors?.raidShiftId}
            />
          </Grid>
          <Heading as="h3" size="md">
            Территория наблюдения:
          </Heading>

          <Grid templateColumns="1fr 2fr" gap={10} marginY={5}>
            <GridItem>
              <Stack direction="column" spacing={5}>
                <Field
                  as={Input}
                  name="taskTerritoryName"
                  label="Наименование территории"
                  value={values.taskTerritoryName}
                  onChange={handleChange}
                  error={errors?.taskTerritoryName}
                />
                <Field
                  as={Select}
                  name="raidTypeId"
                  label="Тип исследования"
                  value={values.raidTypeId}
                  options={raidTypeId}
                  onChange={handleChange}
                  error={errors?.raidTypeId}
                  isDisabled
                />
                <Field
                  as={Select}
                  name="taskTypeId"
                  label="Тип рейда"
                  value={values.taskTypeId}
                  options={taskTypes}
                  onChange={handleChange}
                  error={errors?.taskTypeId}
                />
                <Field
                  as={Select}
                  name="taskReasonId"
                  label="Основание"
                  value={values.taskReasonId}
                  options={taskReasonId}
                  onChange={handleChange}
                  error={errors?.taskReasonId}
                />
                <Field
                  as={DateInput}
                  name="taskDeadlineDate"
                  label="Срок исполнения"
                  showTimeInput
                  value={values.taskDeadlineDate}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setFieldValue(e.target.name, createUTCTime(e.target.value))
                  }
                  error={errors?.taskDeadlineDate}
                />
                <Field
                  as={Select}
                  name="taskPriorityId"
                  label="Приоритет"
                  options={taskPriorityId}
                  onChange={handleChange}
                  error={errors?.taskPriorityId}
                />
                <Field
                  as={Select}
                  name="taskDistrictIds[0]"
                  label="Округ"
                  value={values.taskDistrictIds[0]}
                  options={districts}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setFieldValue('taskDistrictIds[0]', e.target.value);
                    setFieldValue('taskRegionIds[0]', null);
                  }}
                  error={errors?.taskDistrictIds && errors?.taskDistrictIds[0]}
                />
                <Field
                  as={Select}
                  name="taskRegionIds[0]"
                  label="Район"
                  value={values.taskRegionIds[0]}
                  options={filteredRegions}
                  onChange={handleChange}
                  error={errors?.taskRegionIds && errors?.taskRegionIds[0]}
                />
                <Field as={Input} name="taskTimeRange" label="Комментарий" onChange={handleChange} error={false} />
              </Stack>
            </GridItem>
            <Box ml={2} width="auto" height="600px">
              <MapForPels browserId={browserId} uid={values.id} isReports pointTypeId={pointTypeId} />
            </Box>
          </Grid>
          <FieldArray name="points">
            {({ push, handleRemove }) => (
              <Stack direction="column" spacing={5}>
                {values.points?.map((point, i) => (
                  <BurningJournalObservationPlace
                    media={media[i]}
                    onMediaSave={onMediaSave}
                    pelStations={pelStations}
                    sourceTypes={odorSourceTypeId}
                    raidResultId={formattedRaidResultIds}
                    pointTypeId={pointTypeId}
                    name={`points[${i}]`}
                    key={i}
                    onRemovePoint={handleRemove(i)}
                    values={point}
                    handleChange={handleChange}
                    taskId={taskId}
                    index={i}
                    errors={errors}
                    browserId={browserId}
                    uid={values.id}
                    onSave={onSave}
                  />
                ))}
                <Grid>
                  <GridItem justifySelf="flex-start">
                    <Button
                      leftIcon={<AddIcon />}
                      variant="ghost"
                      color="PrimaryButton.500"
                      size="sm"
                      p={0}
                      onClick={() => push(createPoint())}
                    >
                      Добавить место наблюдения
                    </Button>
                  </GridItem>
                </Grid>
              </Stack>
            )}
          </FieldArray>
          <Flex marginTop={5}>
            <Button marginRight={5} onClick={() => backWithFallback(backPath)}>
              Отмена
            </Button>
            <Button onClick={onSave}>Сохранить</Button>
          </Flex>
        </>
      )}
    </Form>
  );
};

export default BurningJournalForm;
