import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import { IMeasureUnitItem, UnitAddRootPath, unitApiListPath, unitLocales, UnitRootPath } from './consts';
import appLocale from 'constants/appLocale';
import { NSIRootPath } from 'pages/dictionaries/const/routes';
import { MaterialTable } from 'features/material-table/MaterialTableMini';
import { useList } from 'hooks/useList';
import { useHistory } from 'react-router';
import { SubsystemHeader } from 'components/common/SubsystemHeader';
import { subsystemCodesMap } from 'constants/subsystemIdsMap';
import React from 'react';
import { useCustomRights } from 'hooks/useCustomRights';
import { FullPageWrapper } from '../../../components/table/FullPageWrapper';
import { DEFAULT_PAGE_SIZE_NEW_TABLE } from '../../../constants/tables';

export const MeasureUnits = () => {
  const history = useHistory();

  const { isWrite } = useCustomRights(subsystemCodesMap.nsi.units);

  useUpdateBreadcrumbs([
    { breadcrumb: appLocale.mainPage.nsi, path: NSIRootPath },
    {
      breadcrumb: unitLocales.unitsDict,
      path: UnitRootPath,
    },
  ]);

  const {
    response,
    toggleOrder,
    onPageChange,
    filterDTO,
    onSearchText,
    isLoading,
    downloadAsFile,
    materialTableProps,
  } = useList<IMeasureUnitItem>(unitApiListPath, {
    saveFiltersState: true,
    defaultPageSize: DEFAULT_PAGE_SIZE_NEW_TABLE,
    resetDataOnNextRequest: true,
  });

  const columns = [
    {
      title: unitLocales.name,
      field: 'name',
    },
    {
      title: unitLocales.shortName,
      field: 'shortName',
    },
    {
      title: unitLocales.code,
      field: 'code',
    },
    {
      title: unitLocales.localName,
      field: 'localName',
    },
    {
      title: unitLocales.intName,
      field: 'intName',
    },
    {
      title: unitLocales.localCode,
      field: 'localCode',
    },
    {
      title: unitLocales.intCode,
      field: 'intCode',
    },
  ];

  return (
    <FullPageWrapper>
      <SubsystemHeader
        title={unitLocales.unitsDict}
        addButtonProps={{
          onClick: () => {
            history.push(UnitAddRootPath);
          },
          isDisabled: !isWrite,
        }}
      />
      <MaterialTable
        data={response?.data || []}
        meta={response?.meta}
        toggleOrder={toggleOrder}
        onPageChange={onPageChange}
        isLoading={isLoading}
        filterDTO={filterDTO}
        options={{
          search: true,
        }}
        downloadAsFile={downloadAsFile}
        onSearch={onSearchText}
        columns={columns}
        actions={[
          {
            type: 'edit',
            onClick: (_e, item) => {
              if (!Array.isArray(item)) {
                history.push(`${UnitRootPath}/edit/${item.id}`);
              }
            },
            disabled: !isWrite,
            hidden: !isWrite,
          },
        ]}
        showTotalCount
        {...materialTableProps}
      />
    </FullPageWrapper>
  );
};
