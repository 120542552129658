import * as yup from 'yup';
import { numberError, requiredError, stationNameValidationError } from 'models/schemas/constans';
import { latitudeValidation } from 'models/schemas/location';

export const nsiAtmosphericAirSchema = yup.object().shape({
  name: yup.string().matches(/^[А-яЁё0-9№.()«»,]+(?: [А-яЁё0-9№.()«»,]+)*$/g, stationNameValidationError),
  status: yup.string().nullable().required(requiredError),
  stationType: yup.string().nullable().required(requiredError),
  stationId: yup.string().nullable().required(requiredError),
  areaTypeId: yup.string().nullable().required(requiredError),
  number: yup.number().nullable().typeError(numberError),
  startDate: yup.string().nullable().required(requiredError),
  stopDate: yup.string().nullable().required(requiredError),
  ...latitudeValidation,
});
