import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import { useList } from 'hooks/useList';
import appLocale from 'constants/appLocale';
import { GeoecologicalRootPath } from 'pages/data/GeoecologicalProcesses/consts';
import { GeologicalProcessesGroundwaterHeader } from '../../components';
import { MonitoringValueItem } from 'pages/data/GeoecologicalProcesses/GeologicalProcessesGroundwater/Protocol/types';
import {
  ProtocolTable,
} from 'pages/data/GeoecologicalProcesses/GeologicalProcessesGroundwater/Protocol/components/ProtocolTable';
import {
  GroundwaterProtocolFilter,
} from 'pages/data/GeoecologicalProcesses/GeologicalProcessesGroundwater/Protocol/components/GroundwaterProtocolFilter';
import subsystemIdsMap from 'constants/subsystemIdsMap';
import { useAvailability } from 'hooks';
import { usePeriodsDict } from 'features/PeriodsButtons/usePeriodsDict';
import { useDictionary } from 'hooks/useDictionary';
import { DEFAULT_PAGE_SIZE_NEW_TABLE } from '../../../../../../constants/tables';
import { FullPageWrapper } from '../../../../../../components/table/FullPageWrapper';

export const ProtocolMonitoring = () => {
  const { isExecute, isNoRights, isAvailable } = useAvailability(subsystemIdsMap.geoecologyRoot);

  useUpdateBreadcrumbs([
    { breadcrumb: appLocale.common.data, path: '/data' },
    {
      breadcrumb: appLocale.subsystems.geoecologicalprocesses,
      path: `${GeoecologicalRootPath}`,
    },
    {
      breadcrumb: `${appLocale.common.dataProtocol} (${appLocale.subsystems.groundwater})`,
      path: `${GeoecologicalRootPath}/monitoring/protocol/water/examination`,
    },
  ]);

  const {
    refetch,
    isLoading: isMonitoringListLoading,
    response,
    setFilters,
    toggleOrder,
    onPageChange,
    filterDTO,
    downloadAsFile,
    saveFilters,
    errorText,
    materialTableProps,
  } = useList<MonitoringValueItem>('/geoecology/v10/geoecology/watersource/monitoring/list/values', {
    initialOrderFieldKey: '-measuringDate',
    preventFirstFetch: true,
    defaultPageSize: DEFAULT_PAGE_SIZE_NEW_TABLE,
    resetDataOnNextRequest: true,
  });

  const { dictionaries } = useDictionary({ url: '/geoecology/v10/geoecology/watersource/monitoring/dictionaries' });
  const { result: periods } = usePeriodsDict(`auth/v10/user/period/${subsystemIdsMap.geoGroundWater}`);
  return (
    <FullPageWrapper>
      <GeologicalProcessesGroundwaterHeader
        showAdd={false}
        baseUrl={`${GeoecologicalRootPath}/monitoring/protocol/water`}
        active="examination"
        title={`${appLocale.common.dataProtocol} (${appLocale.subsystems.groundwater})`}
      >
        <GroundwaterProtocolFilter
          filters={filterDTO?.filters || []}
          periods={periods}
          isLoading={isMonitoringListLoading}
          isDisabled={isMonitoringListLoading}
          dictionaries={dictionaries}
          onSubmit={(values) => {
            setFilters(Object.values(values));
          }}
        />
      </GeologicalProcessesGroundwaterHeader>

      <ProtocolTable
        saveFilters={saveFilters}
        isAvailable={isAvailable}
        isExecute={isExecute || isNoRights}
        filters={filterDTO.filters}
        setFilters={setFilters}
        refetch={refetch}
        monitoringList={response}
        toggleOrder={toggleOrder}
        filterDTO={filterDTO}
        onPageChange={onPageChange}
        downloadAsFile={downloadAsFile}
        isLoading={isMonitoringListLoading}
        errorText={errorText}
        baseUrl="/geoecology/v10/geoecology/watersource/monitoring"
        materialTableProps={materialTableProps}
      />
    </FullPageWrapper>
  );
};
