import { useEffect } from 'react';
import { useRequest } from 'hooks';
import { apiAuthPaths } from 'api/services/auth';

type Actions = 'READ' | 'WRITE' | 'EXECUTE' | 'DELETE';

export type TReport = {
  actions?: Actions[];
  code: string;
  id?: string;
  link: string;
  name: string;
  subsystemId?: string;
};

export const useGetSubsystemReport = (id: string, userId?: string) => {
  const {
    get,
    isLoading: isReportLoading,
    result: subsystemReports,
  } = useRequest<TReport[] | null>(`${apiAuthPaths.user.report}/${id}`);

  useEffect(() => {
    if (userId) {
      get();
    }
  }, [get, userId]);

  return {
    get,
    subsystemReports,
    isReportLoading,
  };
};
