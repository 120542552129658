import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import appLocale from 'constants/appLocale';
import { SubsystemHeader } from 'components/common';
import React, { useMemo } from 'react';
import { MaterialTable } from 'features/material-table/MaterialTableMini';
import { useHandleDeleteData, useList } from 'hooks';
import { subsystemCodesMap } from 'constants/subsystemIdsMap';
import { Column } from '@material-table/core';
import { useCustomRights } from 'hooks/useCustomRights';
import {
  dangerScaleRootBreadcrumbs,
  NSIDangerScaleApiRoot,
  NSIDangerScalePaths,
} from 'pages/dictionaries/NSIDangerScale/const';
import { INSIDangerScaleListItem } from 'pages/dictionaries/NSIDangerScale/types';
import { NSIDangerScaleFilter } from 'pages/dictionaries/NSIDangerScale/NSIDangerScaleFilter';
import { useHistory } from 'react-router-dom';
import { FullPageWrapper } from '../../../components/table/FullPageWrapper';
import { DEFAULT_PAGE_SIZE_NEW_TABLE } from '../../../constants/tables';

export const NSIDangerScale = () => {
  useUpdateBreadcrumbs(dangerScaleRootBreadcrumbs);
  const history = useHistory();
  const { isWrite } = useCustomRights(subsystemCodesMap.nsi.dangerScale);

  const {
    response,
    onPageChange,
    isLoading,
    toggleOrder,
    filterDTO,
    onSearchText,
    errorText,
    downloadAsFile,
    selectedIndexes,
    onSelectionChange,
    onSubmitFilters,
    renderOnlySelectedCheckbox,
    refetch,
    materialTableProps,
  } = useList<INSIDangerScaleListItem, keyof INSIDangerScaleListItem>(
    `${NSIDangerScaleApiRoot}/search`,
    {
      saveFiltersState: true,
      selectionKey: 'id',
      defaultPageSize: DEFAULT_PAGE_SIZE_NEW_TABLE,
      resetDataOnNextRequest: true,
    },
  );

  const { ModalComponent: ConfirmDeleteModal, handleOpenModal } = useHandleDeleteData({ refetch });

  const columns = useMemo<Column<INSIDangerScaleListItem>[]>(
    () => [
      {
        title: appLocale.nsi.dangerScale.fields.dangerScale,
        field: 'dangerScale',
      },
      {
        title: appLocale.nsi.dangerScale.fields.dutyScale,
        field: 'dutyScale',
      },
      {
        title: appLocale.nsi.dangerScale.fields.range,
        field: 'rangeText',
        sorting: false,
      },
    ],
    [],
  );

  const handleAddButtonClick = () => history.push(NSIDangerScalePaths.add);
  const tableEditButtonClick = (id: string) => history.push(NSIDangerScalePaths.edit(id));

  return (
    <FullPageWrapper>
      <SubsystemHeader
        title={appLocale.nsi.dangerScale.title}
        titleMultiline
        addButtonProps={{
          isDisabled: !isWrite,
          onClick: handleAddButtonClick,
        }}
        filtersComponent={
          <NSIDangerScaleFilter
            filters={filterDTO.filters}
            isLoading={isLoading}
            onSubmit={(values) => {
              onSubmitFilters(Object.values(values));
            }}
          >
            {renderOnlySelectedCheckbox}
          </NSIDangerScaleFilter>
        }
      />

      <MaterialTable
        data={response?.data}
        meta={response?.meta}
        onSearch={onSearchText}
        filterDTO={filterDTO}
        toggleOrder={toggleOrder}
        onPageChange={onPageChange}
        isLoading={isLoading}
        downloadAsFile={downloadAsFile}
        options={{
          search: true,
        }}
        columns={columns}
        actions={[
          {
            type: 'edit',
            onClick: (_e, row) => {
              tableEditButtonClick(row.id);
            },
            disabled: !isWrite,
            hidden: !isWrite,
          },
          {
            type: 'delete',
            onClick: (e, row) => handleOpenModal({ url: `${NSIDangerScaleApiRoot}/${row.id}` }),
            disabled: !isWrite,
            hidden: !isWrite,
          },
        ]}
        showTotalCount
        errorText={errorText}
        selectedIndexes={selectedIndexes}
        onSelectionChange={onSelectionChange}
        {...materialTableProps}
      />

      {ConfirmDeleteModal}
    </FullPageWrapper>
  );
};
