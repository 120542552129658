import React from 'react';
import { Box } from '@chakra-ui/react';
import { InputFileMetadata } from 'features/UploadedFileProvider/types';
import { FileItem } from './FileItem';

interface IInputFileMetadataControllerProps {
  inputFileMetadata?: InputFileMetadata;
  setInputFileMetadata: React.Dispatch<React.SetStateAction<InputFileMetadata | undefined>>;
}

interface IInputFileMetadataController {
  (
    props: React.PropsWithChildren<IInputFileMetadataControllerProps>
  ): React.ReactElement<IInputFileMetadataControllerProps> | null;
}

export const InputFileMetadataController: IInputFileMetadataController = ({
  inputFileMetadata,
  setInputFileMetadata,
}) => {
  if (inputFileMetadata) {
    return (
      <Box margin="25px 0 10px 0">
        <FileItem
          key={inputFileMetadata.frontId}
          name={inputFileMetadata.name}
          percentage={inputFileMetadata.percentage}
          handleRemove={() => setInputFileMetadata(undefined)}
        />
      </Box>
    );
  }

  return null;
};
