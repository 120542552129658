import * as yup from 'yup';
import { numberError, requiredError } from '../constans';

export const QualitySchema = yup.object().shape({
  measuringDate: yup.string().required(requiredError).nullable(),
  waterSourceType: yup.string().required(requiredError).nullable(),
  sourceId: yup.string().required(requiredError).nullable(),
  measures: yup.array().of(
    yup.object().shape({
      name: yup.string().typeError(requiredError).required(requiredError),
      value: yup.number().typeError(numberError).required(requiredError),
      code: yup.string().typeError(requiredError).required(requiredError),
    })
  ),
});
