import { NotificationSource } from 'pages/admin/models/NotificationSource';
import * as yup from 'yup';
import { ERRORS } from 'constants/errors';
import { IManagerPeriod } from 'pages/Manager/types';

export const notificationTypeLocale: Record<keyof typeof NotificationSource, string> = {
    DATA: 'Некорректные данные',
    PDK: 'Превышение ПДК',
    MOSECOM: 'Прекращение поступления данных на сайт mosecom.mos.ru',
    SENSOR: 'Отклонение температуры внутри АСКЗА от рабочего диапазона',
    FTP: 'Непоступление данных с FTP-сервера и отсутствие соединения со станцией',
    NMU: 'Наличие неблагоприятных метеорологических условий',
    COMPLAINT: 'Превышение заданного предела количества жалоб',
    USER: 'Служебные',
    BOUNDARY: 'Отклонение от граничных значений',
};

export const weekMS = 7 * 24 * 60 * 60 * 1000;

export const graphValidationSchema = yup.object().shape({
    from: yup.number().nullable().required(ERRORS.required),
    to: yup.number().nullable().required(ERRORS.required),
}).test((values) => {
    let errors: yup.ValidationError[] = [];
    if (values.to && values.from && values.to - values.from > weekMS) {
        errors = [new yup.ValidationError('Выбранный период должен быть не больше недели', '', 'from'), new yup.ValidationError('Выбранный период должен быть не больше недели', '', 'to')];
    }
    if (errors.length > 0) {
        return new yup.ValidationError(errors);
    }
    return true;
});

export const airSentrySubtitles: Partial<Record<IManagerPeriod, string[]>> = {
    now: ['Максимальное значение измерения'],
    custom: ['Максимальное значение измерения за период', 'Максимальное среднесуточное значение измерения за период'],
};

export const meteoSentrySubtitles: Partial<Record<IManagerPeriod, string[]>> = {
    now: ['Актуальные метеоданные']
};

export const waterSentrySubtitles: Partial<Record<IManagerPeriod, string[]>> = {
    custom: ['Максимальное среднее значение измерений за период']
};
