import appLocale from 'constants/appLocale';
import { ReportSmall } from 'features/WithSideBar/icons';
import { RenderTree } from 'features/WithSideBar/types';
import subsystemIdsMap, { subsystemCodesMap } from 'constants/subsystemIdsMap';
import { Reports } from '../home/icons';

export const combinedChatsMenu: RenderTree = {
  name: appLocale.analytics.combinedCharts.title,
  id: '/reports-analytics/chart-combination',
  url: '/reports-analytics/chart-combination',
  otherCode: subsystemCodesMap.analyticReports.combineCharts.combineChartsRoot,
  children: [
    {
      name: appLocale.analytics.combinedCharts.askzaEmissions.title,
      id: '/reports-analytics/chart-combination/askza-emissions',
      url: '/reports-analytics/chart-combination/askza-emissions',
      otherCode: subsystemCodesMap.analyticReports.combineCharts.emissions,
    },
    {
      name: appLocale.analytics.combinedCharts.askzaMeteo.title,
      id: '/reports-analytics/chart-combination/askza-meteo',
      url: '/reports-analytics/chart-combination/askza-meteo',
      otherCode: subsystemCodesMap.analyticReports.combineCharts.meteo,
    },
    {
      name: appLocale.analytics.combinedCharts.askzaProfilemer.title,
      id: '/reports-analytics/chart-combination/askza-profilimer',
      url: '/reports-analytics/chart-combination/askza-profilimer',
      otherCode: subsystemCodesMap.analyticReports.combineCharts.profilemers,
    },
    {
      name: appLocale.analytics.combinedCharts.askzaInversion.title,
      id: '/reports-analytics/chart-combination/askza-inversion',
      url: '/reports-analytics/chart-combination/askza-inversion',
      otherCode: subsystemCodesMap.analyticReports.combineCharts.inversion,
    },
    {
      name: appLocale.analytics.combinedCharts.askzaPrecipitation.title,
      id: '/reports-analytics/chart-combination/askza-precipitation',
      url: '/reports-analytics/chart-combination/askza-precipitation',
      otherCode: subsystemCodesMap.analyticReports.combineCharts.precipitation,
    },

    // {
    //   name: appLocale.analytics.combinedCharts.profilemer.title,
    //   id: '/reports-analytics/chart-combination/profilemer',
    //   url: '/reports-analytics/chart-combination/profilemer',
    // }
  ],
};

export const nodMenu: RenderTree = {
  name: appLocale.analyticsReports.nod,
  id: '/reports-analytics/nod',
  url: '/reports-analytics/nod',
  otherCode: subsystemCodesMap.nodReportsOtherCodes.nodRoot,
  children: [
    {
      id: '/reports-analytics/nod/stations',
      name: appLocale.analyticsReports.airStations,
      url: '/reports-analytics/nod/stations',
      otherCode: subsystemCodesMap.nodReportsOtherCodes.automaticAirStations,
    },
    {
      id: '/reports-analytics/nod/water-objects',
      name: appLocale.analyticsReports.waterObjects,
      url: '/reports-analytics/nod/water-objects',
      otherCode: subsystemCodesMap.nodReportsOtherCodes.waterObjects,
    },
    {
      id: '/reports-analytics/nod/atmosphere-data',
      name: appLocale.analyticsReports.atmosphereData,
      url: '/reports-analytics/nod/atmosphere-data',
      otherCode: subsystemCodesMap.nodReportsOtherCodes.atmosphereData,
    },
    {
      id: '/reports-analytics/nod/journal-nmu',
      name: appLocale.analyticsReports.journalNmu,
      url: '/reports-analytics/nod/journal-nmu',
      otherCode: subsystemCodesMap.nodReportsOtherCodes.nmu,
    },
    {
      id: '/reports-analytics/nod/danger',
      name: appLocale.analyticsReports.danger,
      url: '/reports-analytics/nod/danger',
      otherCode: subsystemCodesMap.nodReportsOtherCodes.danger,
    },
    {
      id: '/reports-analytics/nod/air-quality-parameters',
      name: appLocale.analyticsReports.airQuality,
      url: '/reports-analytics/nod/air-quality-parameters',
      otherCode: subsystemCodesMap.nodReportsOtherCodes.airQuality,
    },
    {
      id: '/reports-analytics/nod/water-quality-parameters',
      name: appLocale.analyticsReports.waterParameters,
      url: '/reports-analytics/nod/water-quality-parameters',
      otherCode: subsystemCodesMap.nodReportsOtherCodes.waterParams,
    },
    {
      id: '/reports-analytics/nod/melnoise',
      name: appLocale.analyticsReports.noiseMonitoring,
      url: '/reports-analytics/nod/melnoise',
      otherCode: subsystemCodesMap.nodReportsOtherCodes.noiseMonitoring,
    },
    {
      id: '/reports-analytics/nod/greenplants',
      name: appLocale.analyticsReports.greenplants,
      url: '/reports-analytics/nod/greenplants',
      otherCode: subsystemCodesMap.nodReportsOtherCodes.greenplants,
    },
    {
      id: '/reports-analytics/nod/soil',
      name: appLocale.analyticsReports.soil,
      url: '/reports-analytics/nod/soil',
      otherCode: subsystemCodesMap.nodReportsOtherCodes.soil,
    },
    {
      id: '/reports-analytics/nod/geo-spring',
      name: appLocale.analyticsReports.geoSpring,
      url: '/reports-analytics/nod/geo-spring',
      otherCode: subsystemCodesMap.nodReportsOtherCodes.geoSpring,
    },
    {
      id: '/reports-analytics/nod/water-concentration',
      name: appLocale.analyticsReports.waterMonitoring,
      url: '/reports-analytics/nod/water-concentration',
      otherCode: subsystemCodesMap.nodReportsOtherCodes.waterMonitoring,
    },
    {
      id: '/reports-analytics/nod/atmosphere-pollution',
      name: appLocale.analyticsReports.askzaMonthAvg,
      url: '/reports-analytics/nod/atmosphere-pollution',
      otherCode: subsystemCodesMap.nodReportsOtherCodes.askzaMonthAvg,
    },
    {
      id: '/reports-analytics/nod/water-observation-point',
      name: appLocale.analyticsReports.waterObservation,
      url: '/reports-analytics/nod/water-observation-point',
      otherCode: subsystemCodesMap.nodReportsOtherCodes.waterObservation,
    },
    {
      id: '/reports-analytics/nod/geo-danger-proccess',
      name: appLocale.analyticsReports.geoDangerProccess,
      url: '/reports-analytics/nod/geo-danger-proccess',
      otherCode: subsystemCodesMap.nodReportsOtherCodes.geoDanger,
    },
  ],
};

export const dataPublishingMenu: RenderTree = {
  name: appLocale.analyticsReports.dataPublishing,
  id: '/reports-analytics/data-publishing',
  url: '/reports-analytics/data-publishing',
  otherCode: subsystemCodesMap.analyticReports.dataPublishing.dataPublishingRoot,
  children: [
    {
      name: appLocale.analyticsReports.questionnaire,
      id: '/reports-analytics/data-publishing/questionnaire',
      url: '/reports-analytics/data-publishing/questionnaire',
      otherCode: subsystemCodesMap.analyticReports.dataPublishing.questionnaire,
    },
  ],
};

export const wpzMenu: RenderTree = {
  name: appLocale.subsystems.wpz,
  id: '/reports-analytics/water/water-state/protections',
  url: '/reports-analytics/water/water-state/protections',
  otherCode: subsystemCodesMap.analyticReports.waterObjects.wpz.wpzRoot,
  children: [
    {
      name: 'Водоохранные зоны',
      // eslint-disable-next-line max-len
      id: `/reports-analytics/water/water-state/protections/waterProtectionZone/${subsystemIdsMap.waterProtectionZone}`,
      // eslint-disable-next-line max-len
      url: `/reports-analytics/water/water-state/protections/waterProtectionZone/${subsystemIdsMap.waterProtectionZone}`,
      otherCode: subsystemCodesMap.analyticReports.waterObjects.wpz.wpz,
    },
    {
      name: 'Дно и берега',
      id: `/reports-analytics/water/water-state/protections/bottomAndShore/${subsystemIdsMap.groundcoast}`,
      url: `/reports-analytics/water/water-state/protections/bottomAndShore/${subsystemIdsMap.groundcoast}`,
      otherCode: subsystemCodesMap.analyticReports.waterObjects.wpz.bottomAndShore,
    },
  ],
};

export const waterObjectsMenu: RenderTree = {
  name: 'Водные объекты',
  id: '/reports-analytics/water',
  url: '/reports-analytics/water',
  otherCode: subsystemCodesMap.analyticReports.waterObjects.waterObjectsRoot,
  children: [
    {
      name: 'АСКЗВ',
      id: `/reports-analytics/water/askzv/${subsystemIdsMap.waterAskzv}`,
      url: `/reports-analytics/water/askzv/${subsystemIdsMap.waterAskzv}`,
      otherCode: subsystemCodesMap.analyticReports.waterObjects.ASKZV,
    },
    {
      name: 'Донный грунт',
      id: `/reports-analytics/water/bottom-soil/${subsystemIdsMap.bottomSoil}`,
      url: `/reports-analytics/water/bottom-soil/${subsystemIdsMap.bottomSoil}`,
      otherCode: subsystemCodesMap.analyticReports.waterObjects.bottomSoil,
    },
    {
      name: 'Поверхностные воды',
      id: `/reports-analytics/water/surface/${subsystemIdsMap.surfaceWater}`,
      url: `/reports-analytics/water/surface/${subsystemIdsMap.surfaceWater}`,
      otherCode: subsystemCodesMap.analyticReports.waterObjects.surfaceWater,
    },
    wpzMenu,
  ],
};

export const pgrMenu: RenderTree = {
  name: 'ПГР',
  id: '/reports-analytics/pgr',
  url: '/reports-analytics/pgr',
  otherCode: subsystemCodesMap.analyticReports.PGR.pgrRoot,
  children: [
    {
      name: 'Вегетативные части',
      id: `/reports-analytics/pgr/vegs/${subsystemIdsMap.pgrVegetative}`,
      url: `/reports-analytics/pgr/vegs/${subsystemIdsMap.pgrVegetative}`,
      otherCode: subsystemCodesMap.analyticReports.PGR.vegetative,
    },
    {
      name: 'Зеленые насаждения',
      id: `/reports-analytics/pgr/greenplants/${subsystemIdsMap.pgrFlora}`,
      url: `/reports-analytics/pgr/greenplants/${subsystemIdsMap.pgrFlora}`,
      otherCode: subsystemCodesMap.analyticReports.PGR.greenplants,
    },
    {
      name: 'Почвы',
      id: `/reports-analytics/pgr/soil/${subsystemIdsMap.pgrSoil}`,
      url: `/reports-analytics/pgr/soil/${subsystemIdsMap.pgrSoil}`,
      otherCode: subsystemCodesMap.analyticReports.PGR.soil,
    },
    {
      name: 'Смывы',
      id: `/reports-analytics/pgr/washouts/${subsystemIdsMap.pgrWashouts}`,
      url: `/reports-analytics/pgr/washouts/${subsystemIdsMap.pgrWashouts}`,
      otherCode: subsystemCodesMap.analyticReports.PGR.washout,
    },
    {
      name: 'Снежный покров',
      id: `/reports-analytics/pgr/snow/${subsystemIdsMap.pgrSnow}`,
      url: `/reports-analytics/pgr/snow/${subsystemIdsMap.pgrSnow}`,
      otherCode: subsystemCodesMap.analyticReports.PGR.snow,
    },
  ],
};

// TODO: change icon
export const reportsAnalyticsMenu: RenderTree = {
  name: appLocale.subsystems.analyticsReports,
  id: '/reports-analytics',
  url: '/reports-analytics',
  icon: <ReportSmall />,
  homepageIcon: <Reports />,
  children: [
    {
      name: appLocale.analyticsReports.admin,
      id: `/reports-analytics/admin/${subsystemIdsMap.admin}`,
      url: `/reports-analytics/admin/${subsystemIdsMap.admin}`,
      otherCode: subsystemCodesMap.analyticReports.administration,
    },
    dataPublishingMenu,
    {
      name: 'АСКЗА',
      id: `/reports-analytics/atmosphere/${subsystemIdsMap.atmosphere}`,
      url: `/reports-analytics/atmosphere/${subsystemIdsMap.atmosphere}`,
      otherCode: subsystemCodesMap.analyticReports.ASKZA,
    },
    {
      name: 'Бюллетени',
      id: `/reports-analytics/bulletins/${subsystemIdsMap.bulletins}`,
      url: `/reports-analytics/bulletins/${subsystemIdsMap.bulletins}`,
      otherCode: subsystemCodesMap.analyticReports.bulletins,
    },
    waterObjectsMenu,
    {
      name: 'Зеленые насаждения',
      id: `/reports-analytics/greenplants/${subsystemIdsMap.greenPlantsRoot}`,
      url: `/reports-analytics/greenplants/${subsystemIdsMap.greenPlantsRoot}`,
      otherCode: subsystemCodesMap.analyticReports.greenPlants,
    },
    {
      name: 'Личный кабинет оперативного дежурного СОК',
      id: `/reports-analytics/duty-office/${subsystemIdsMap.dutyOfficer}`,
      url: `/reports-analytics/duty-office/${subsystemIdsMap.dutyOfficer}`,
      otherCode: subsystemCodesMap.analyticReports.dutyOfficer,
    },
    {
      name: 'М-АСКШ',
      id: `/reports-analytics/masksh/${subsystemIdsMap.maskshRoot}`,
      url: `/reports-analytics/masksh/${subsystemIdsMap.maskshRoot}`,
      otherCode: subsystemCodesMap.analyticReports.MASKSH,
    },
    {
      name: 'Метео',
      id: `/reports-analytics/meteo/${subsystemIdsMap.meteoRoot}`,
      url: `/reports-analytics/meteo/${subsystemIdsMap.meteoRoot}`,
      otherCode: subsystemCodesMap.analyticReports.meteo,
    },
    nodMenu,
    pgrMenu,
    {
      name: 'Почвы',
      id: `/reports-analytics/soil/${subsystemIdsMap.soilpollution}`,
      url: `/reports-analytics/soil/${subsystemIdsMap.soilpollution}`,
      otherCode: subsystemCodesMap.analyticReports.soil,
    },
    {
      name: 'Промвыбросы',
      id: `/reports-analytics/emissions/${subsystemIdsMap.emissions}`,
      url: `/reports-analytics/emissions/${subsystemIdsMap.emissions}`,
      otherCode: subsystemCodesMap.analyticReports.emissions,
    },
    {
      name: 'ПЭЛ',
      id: `/reports-analytics/pel/${subsystemIdsMap.pelRoot}`,
      url: `/reports-analytics/pel/${subsystemIdsMap.pelRoot}`,
      otherCode: subsystemCodesMap.analyticReports.PEL,
    },
    combinedChatsMenu,
    {
      name: 'Учет обращений граждан',
      id: `/reports-analytics/appeals/${subsystemIdsMap.appealsRoot}`,
      url: `/reports-analytics/appeals/${subsystemIdsMap.appealsRoot}`,
      otherCode: subsystemCodesMap.analyticReports.appeals,
    },
  ],
};
