import { Button, Input, Loader } from 'components/common';
import { LightModeWrapper } from 'components/common/LightModeWrapper/LightModeWrapper';
import { Field, Form, FormikProvider, useFormik } from 'formik';
import { initialChangePasswordValues as initialValues } from 'pages/auth/config';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { apiAuthPaths } from 'api/services/auth';
import { useNotifications, useRequest } from 'hooks';
import { formatErrorMessage } from 'lib/utils/formatErrorMessage';
import { ApiError } from 'models';
import { authAppPaths } from 'pages/auth/appPaths';
import { Flex, Grid, Heading } from '@chakra-ui/react';
import * as yup from 'yup';
import { ValidationError } from 'yup';
import { requiredError } from '../../models/schemas/constans';

export const SetPasswordByEmailDialog: React.FC = () => {
  const { errorNotify, successNotify } = useNotifications();
  const linkParts = window.location.toString().split('/');
  const link = linkParts[linkParts.length - 1];
  const { push } = useHistory();

  const onError = (message?: string | null | ApiError) => {
    errorNotify({
      key: 'login/error/change',
      message: formatErrorMessage(message) || 'Ошибка при изменении пароля',
    });
  };

  const onSuccess = () => {
    successNotify({
      key: 'login/success/change',
      message: 'Пароль успешно изменен',
    });
    push('/');
  };

  const { post: changePassword, isLoading } = useRequest(
    `${apiAuthPaths.password.changePassword}/${link}`,
    onSuccess,
    onError,
  );
  const onCheckError = (message?: string | null | ApiError) => {
    setFieldError('repeatPassword', formatErrorMessage(message) || undefined);
    setFieldError('password', formatErrorMessage(message) || undefined);
  };

  const onCheckSuccess = () => {
    changePassword({ password: values.password });
  };

  const { post: checkPasswordRules, isLoading: isCheckLoading } = useRequest(
    `${apiAuthPaths.password.validatePassword}/${link}`,
    onCheckSuccess,
    onCheckError,
  );

  const { setFieldError, setErrors, submitForm, values, setFieldValue, errors, touched, ...formik } = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnMount: false,
    validateOnBlur: false,
    initialValues,
    validationSchema: yup
      .object()
      .shape({
        password: yup.string().nullable().required(requiredError),
        repeatPassword: yup.string().nullable().required(requiredError),
      })
      .test((values) => {
        if (!!values.password && !!values.repeatPassword && values.password !== values.repeatPassword) {
          return new ValidationError('Пароли не совпадают', '', 'repeatPassword');
        }
        return true;
      }),
    onSubmit: (values) => checkPasswordRules({ password: values.password }),
  });
  const handleCancel = () => push(authAppPaths.login);

  if (isCheckLoading || isLoading) {
    return <Loader />;
  }

  return (
    <Flex
      minHeight="100vh"
      display="flex"
      alignItems="center"
      flexDir="column"
      bgPosition="center"
      bgRepeat="no-repeat"
      bgSize="cover"
      bgImage="url('./login-bg-img.png')"
    >
      <Flex direction="column" minHeight="100vh" maxW="500px" justifyContent="space-between">
        <Flex flexGrow={1} alignItems="center" justifyContent="center">
          <Flex maxH="520px" direction="column" minWidth="375px" bgColor="white" p={10}>
            <Flex mb={8} alignItems="center" justifyContent="center">
              <Heading my={2} size="lg" color="black">
                Изменить пароль учетной записи
              </Heading>
            </Flex>
            <FormikProvider
              value={{
                submitForm,
                values,
                setFieldValue,
                setFieldError,
                errors,
                touched,
                setErrors,
                ...formik,
              }}
            >
              <Form>
                <Flex direction="column">
                  <Grid borderColor="black" gap={2} color="black">
                    <Field
                      variant="outline"
                      as={Input}
                      label="Новый пароль"
                      name="password"
                      type="password"
                      error={errors.password}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setErrors({});
                        setFieldValue('password', e.target.value);
                      }}
                    />
                    <Field
                      variant="outline"
                      as={Input}
                      label="Повторение пароля"
                      name="repeatPassword"
                      type="password"
                      error={errors.repeatPassword}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setErrors({});
                        setFieldValue('repeatPassword', e.target.value);
                      }}
                    />
                    <LightModeWrapper>
                      <Button mt={2} variant="solid" colorScheme="PrimaryButton" onClick={submitForm} isFullWidth>
                        Сохранить изменения
                      </Button>
                      <Button mt={2} variant="outline" onClick={handleCancel} isFullWidth>
                        Отмена
                      </Button>
                    </LightModeWrapper>
                  </Grid>
                </Flex>
              </Form>
            </FormikProvider>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};
