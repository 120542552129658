import { useMemo } from 'react';

interface Options {
  updateData: any;
}
export const useOptionsForChart = ({ updateData }: Options) => {
  const options = useMemo(
    () => ({
      animation: false,
      responsive: true,
      indexAxis: 'y',
      plugins: {
        htmlLegend: {
          containerID: 'legend-container',
        },
        legend: {
          display: false,
        },
        tooltip: {
          // borderWidth is inherited by tooltip which is huge on "gantt" chart
          callbacks: {
            labelColor(context: any) {
              return {
                ...context.dataset,
                borderWidth: 2,
                borderRadius: 2,
              };
            },
          }
        },
        zoom: {
          zoom: {
            wheel: {
              enabled: true,
              modifierKey: 'alt',
            },
            drag: {
              enabled: true,
              modifierKey: 'ctrl',
              backgroundColor: 'rgba(225,225,225,0.5)',
              borderColor: 'rgba(225,225,225)',
              borderWidth: 2,
              threshold: 10,
            },
            onZoomComplete: updateData,
            mode: 'x',
          },
          pan: {
            enabled: true,
            modifierKey: 'shift',
            mode: 'x',
            onPanComplete: updateData,
          },
        },
      },
      scales: {
        y: {
          type: 'category',
          offset: true,
          grid: {
            display: false,
          },
        },
        x: {
          type: 'time',
          time: {
            tooltipFormat: 'DD.MM.YYYY HH:mm:ss',
            minUnit: 'second',
            displayFormats: {
              second: 'mm:ss',
              minute: 'HH:mm',
              hour: 'DD HH',
              day: 'MM-DD',
              week: 'MM-DD',
              month: 'YYYY-MM',
              quarter: 'YYYY.Q',
              year: 'YYYY',
            },
          },
        },
      },
    }),
    [updateData]
  );

  return options;
};
