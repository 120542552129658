import { SubsystemHeader } from 'components/common';
import React, { memo } from 'react';
import { NavTabList } from 'features/NavTabList';
import { GreenPlantsFloraUrl, GreenPlantsLawnsUrl } from 'pages/routes/GreenPlants';

interface GreenPlantsDataHeaderProps {
  onOpenLoad(): void;
  handleAdd(): void;
  isReadonly?: boolean;
  isWrite?: boolean;
  isNoRights?: boolean;
  isExecute?: boolean;
  allAvailable?: boolean;
}

export const GreenPlantsDataHeader: React.FC<GreenPlantsDataHeaderProps> = memo(
  ({
    handleAdd,
    onOpenLoad,
    children,
    isWrite,
    isNoRights,
    allAvailable
  }) => {
    const isAvailable = allAvailable || isNoRights || isWrite;
    return (
      <SubsystemHeader
        title="Данные по зеленым насаждениям"
        addButtonProps={{ onClick: handleAdd, isDisabled: !isAvailable }}
        onUploadClick={isAvailable ? onOpenLoad : undefined}
        navigationTabs={(
          <NavTabList
            tabs={[
              {
                label: 'Деревья и кустарники',
                path: GreenPlantsFloraUrl,
              },
              {
                label: 'Газоны',
                path: GreenPlantsLawnsUrl,
              },
            ]}
          />
  )}
        filtersComponent={children}
      />
    );
  },
);
