import { NSIRootPath } from '../../const/routes';
import { IFilterItem } from 'models';

export const GeologicalProcessesRootPath = `${NSIRootPath}/geological-processes`;
export const GeologicalProcessesPath = `${GeologicalProcessesRootPath}/danger-geological-processes`;
export const GroundwaterPath = `${GeologicalProcessesRootPath}/groundwater`;

export const GeoecologyAPIPath = `${NSIRootPath}/v10/sources/geoecology`;
export const GeoecologyDictPath = `${GeoecologyAPIPath}/dictionaries`;
export const GroundwaterAPIPath = `${GeoecologyAPIPath}/groundwater`;

export const groundwaterInitialValues: Record<string, IFilterItem> = {
  type: {
    key: 'type',
    operation: 'EQ',
    value: '',
  },
  name: {
    key: 'name',
    operation: 'EQ',
    value: '',
  },
  aquafer: {
    key: 'aquafer',
    operation: 'EQ',
    value: '',
  },
  okrug: {
    key: 'okrug',
    operation: 'EQ',
    value: '',
  },
  rayon: {
    key: 'rayon',
    operation: 'EQ',
    value: '',
  },
  address: {
    key: 'address',
    operation: 'CONTAINS',
    value: '',
  },
  status: {
    key: 'status',
    operation: 'EQ',
    value: '',
  },
  deleted: {
    key: 'deleted',
    operation: 'EQ',
    value: false,
  },
};

export const geoecologicalInitialValues: Record<string, IFilterItem> = {
  okrug: {
    key: 'okrug',
    operation: 'EQ',
    value: null,
  },
  rayon: {
    key: 'rayon',
    operation: 'EQ',
    value: null,
  },
  address: {
    key: 'address',
    operation: 'CONTAINS',
    value: '',
  },
  status: {
    key: 'status',
    operation: 'EQ',
    value: '',
  },
  name: {
    key: 'name',
    operation: 'CONTAINS',
    value: '',
  },
  number: {
    key: 'number',
    operation: 'EQ',
    value: '',
  },
  deleted: {
    key: 'deleted',
    operation: 'EQ',
    value: false,
  },
  areaId: {
    key: 'areaId',
    operation: 'EQ',
    value: '',
  },
};

export const observationAndLandmarkColumns = [
  {
    title: 'Участок наблюдения',
    field: 'name',
  },
  {
    title: 'Адресный ориентир',
    field: 'landmark',
  },
];

export const markAndReperColumns = [
  {
    title: 'Номер ПИК',
    field: 'number',
  },
  ...observationAndLandmarkColumns
];

export const tabComparisonObject: { [key: string]: number } = {
  observationArea: 0,
  observationPoint: 1,
  inclinometer: 2,
  mark: 3,
  reper: 4,
};
