import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  useDisclosure,
} from '@chakra-ui/react';
import { Button } from 'components/common';
import { useRequest } from 'hooks';
import { DisplayUserItem } from 'pages/admin/models/UserItem';
import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { baseAdminUserResetPasswordUrl } from '../consts';
import { LightModeWrapper } from '../../../../components/common/LightModeWrapper/LightModeWrapper';

const SUCCESS_MESSAGE = 'Пароль был сброшен, ссылка отправлена на email пользователя.';
const ERROR_MESSAGE = 'Ошибка при попытке сброса пароля: ';

export const ResetUserPasswordDialog = forwardRef((props, ref) => {
  const cancelRef = useRef<any>();
  const [message, setMessage] = useState('');

  const { post: resetUserPassword } = useRequest<any>(baseAdminUserResetPasswordUrl);
  const {
    isOpen: isOpenAlert,
    onOpen: onOpenAlert,
    onClose: onCloseAlert
  } = useDisclosure();

  useImperativeHandle(ref, () => ({
    resetPassword: async (user: DisplayUserItem) => {
      const domain = '';
      const response = await resetUserPassword({
        domain
      }, {
        url: `${baseAdminUserResetPasswordUrl}/${user.id}`
      });
      const error = response?.descriptions ? response.descriptions[0]?.message : null;
      setMessage(error == null ? `${SUCCESS_MESSAGE}` : `${ERROR_MESSAGE} ${error}`);
      onOpenAlert();
    }
  }));

  return (
    <AlertDialog
      motionPreset="slideInBottom"
      onClose={onCloseAlert}
      isOpen={isOpenAlert}
      leastDestructiveRef={cancelRef}
      isCentered
    >
      <LightModeWrapper>
        <AlertDialogContent>
          <AlertDialogHeader>Сброс пароля</AlertDialogHeader>
          <AlertDialogBody>
            {message}
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onCloseAlert}>ОК</Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </LightModeWrapper>
    </AlertDialog>
  );
});
