import { FilePreviewErrors } from 'models';
import { Box } from '@chakra-ui/react';
import { InputFileMetadata } from 'features/UploadedFileProvider/types';
import React from 'react';
import { FilePreviewValidation } from '../Validation';
import { FileItem } from '../FileItem';

interface IInputFileMetadataControllerProps {
  inputFileMetadata?: InputFileMetadata;
  errors: FilePreviewErrors;
}

interface IInputFileMetadataController {
  (
    props: React.PropsWithChildren<IInputFileMetadataControllerProps>,
  ): React.ReactElement<IInputFileMetadataControllerProps> | null;
}

export const InputFileMetadataController: IInputFileMetadataController = ({ inputFileMetadata, errors }) => {
  if (inputFileMetadata) {
    return (
      <>
        <Box margin="25px 0 10px 0">
          <FileItem
            key={inputFileMetadata.frontId}
            name={inputFileMetadata.name}
            percentage={inputFileMetadata.percentage}
          />
        </Box>
        <FilePreviewValidation errors={errors} />
      </>
    );
  }

  return null;
};
