import { Stack } from '@chakra-ui/react';
import { Field, useFormikContext } from 'formik';
import type { TGreenPlantsForm } from 'pages/data/GreenPlants/Form/types';
import { Input, Select } from 'components/common';
import React, { useCallback } from 'react';
import type { TFLoraDictionaries } from 'pages/data/GreenPlants/Flora/useFloraDictionaries';
import { dictParametersToMap } from 'features/parametersMap';
import {
  getSelectOptionsFromDictionaryByName
} from '../../../../../../../../features/get-select-options-from-dictionary';
import { get } from 'lodash';

interface Props {
  entity: string;
  areaParameters: TFLoraDictionaries['areaParameters'];
}

export const LawnsAreaMeasurments = ({ entity, areaParameters }: Props) => {
  const { values, setFieldValue } = useFormikContext<TGreenPlantsForm>();

  const parameters = dictParametersToMap(areaParameters);

  const getDictionariesIds = (ids: string[], parameters: Record<string, any>) => {
    const dictionaryIds: string[] = [];
    // TODO: simplify
    ids.forEach((id) => {
      Object.keys(parameters).forEach((dictionaryItemId) => {
        if (id === dictionaryItemId) {
          dictionaryIds.push(dictionaryItemId);
        }
      });
    });
    return dictionaryIds;
  };

  const getOptionsByName = useCallback((name: string) => getSelectOptionsFromDictionaryByName(
    areaParameters,
    name,
    false,
    get(values, `${entity}.parameters.${name}.ids`)
  ), [areaParameters, values, entity]);

  return (
    <Stack>
      <Field
        multiple
        as={Select}
        options={getOptionsByName('lawnType')}
        name={`${entity}.parameters.lawnType.dictionaryItemId`}
        label="Тип газона на площадке наблюдения"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          if (Array.isArray(e.target.value)) {
            setFieldValue(`${entity}.parameters.lawnType.dictionaryItemId`,
              getDictionariesIds(e.target.value, parameters.lawnType.values));
            setFieldValue(`${entity}.parameters.lawnType.value`, e.target.value);
            setFieldValue(`${entity}.parameters.lawnType.parameterId`, parameters.lawnType?.parameterId);
          }
        }}
      />
      <Field
        as={Select}
        label="Состояние газона на площадке наблюдения"
        options={getOptionsByName('lawnState')}
        name={`${entity}.parameters.lawnState.dictionaryItemId[0]`}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          if (e.target.value) {
            setFieldValue(`${entity}.parameters.lawnState.dictionaryItemId`,
              getDictionariesIds([e.target.value], parameters.lawnState.values));
            setFieldValue(`${entity}.parameters.lawnState.value`, [e.target.value]);
            setFieldValue(`${entity}.parameters.lawnState.parameterId`, parameters.lawnState?.parameterId);
          }
        }}
      />
      <Field
        multiple
        as={Select}
        label="Цветники"
        options={getOptionsByName('hasFlowerGarden')}
        name={`${entity}.parameters.hasFlowerGarden.dictionaryItemId`}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          if (Array.isArray(e.target.value)) {
            setFieldValue(`${entity}.parameters.hasFlowerGarden.dictionaryItemId`,
              getDictionariesIds(e.target.value, parameters.hasFlowerGarden.values));
            setFieldValue(`${entity}.parameters.hasFlowerGarden.value`, e.target.value);
            setFieldValue(`${entity}.parameters.hasFlowerGarden.parameterId`, parameters.hasFlowerGarden?.parameterId);
          }
        }}
      />
      <Field
        as={Select}
        label="Состояние цветников"
        options={getOptionsByName('flowerGardenState')}
        name={`${entity}.parameters.flowerGardenState.dictionaryItemId[0]`}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          if (e.target.value) {
            setFieldValue(`${entity}.parameters.flowerGardenState.dictionaryItemId`,
              getDictionariesIds([e.target.value], parameters.flowerGardenState.values));
            setFieldValue(`${entity}.parameters.flowerGardenState.value`,
              [e.target.value]);
            setFieldValue(`${entity}.parameters.flowerGardenState.parameterId`,
              parameters.flowerGardenState?.parameterId);
          }
        }}
      />
      <Field
        as={Input}
        label="Количество деревьев, шт."
        name={`${entity}.parameters.treeCount.value[0]`}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setFieldValue(`${entity}.parameters.treeCount.value`,
            [e.target.value]);
          setFieldValue(`${entity}.parameters.treeCount.parameterId`,
            parameters.treeCount?.parameterId);
        }}
      />
      <Field
        as={Input}
        label="Количество кустарников, шт."
        name={`${entity}.parameters.shrubCount.value[0]`}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setFieldValue(`${entity}.parameters.shrubCount.value`,
            [e.target.value]);
          setFieldValue(`${entity}.parameters.shrubCount.parameterId`,
            parameters.shrubCount?.parameterId);
        }}
      />
    </Stack>
  );
};
