import { SubsystemHeader } from 'components/common';

import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import { RoleList } from './RoleList';
import appLocale from 'constants/appLocale';
import React from 'react';
import { useHistory } from 'react-router';
import { FullPageWrapper } from '../../../components/table/FullPageWrapper';

export const Roles: React.FC = () => {
  useUpdateBreadcrumbs([
    { breadcrumb: appLocale.admin.main, path: '/admin' },
    {
      breadcrumb: appLocale.admin.roles,
      path: '/admin/role',
    },
  ]);

  const { push } = useHistory();

  return (
    <FullPageWrapper>
      <SubsystemHeader
        title="Управление ролями"
        addButtonProps={{ onClick: () => push('/admin/role/add') }}
      />

      <RoleList />
    </FullPageWrapper>
  );
};
