import { NSIRootPath } from '../const/routes';
import appLocale from 'constants/appLocale';

export const NMU_ApiPath = 'industrialemissions/v10/nmu';

export const NSI_NMU_RootPath = `${NSIRootPath}/NMU`;
export const NSI_NMU_AddPath = `${NSI_NMU_RootPath}/add`;
export const NSI_NMU_EditPath = `${NSI_NMU_RootPath}/edit`;

export const NSI_NMU_Breadcrumbs = [
  {
    breadcrumb: appLocale.mainPage.nsi,
    path: NSIRootPath,
  },
  {
    breadcrumb: 'Периоды НМУ',
    path: NSI_NMU_RootPath,
  },
];
