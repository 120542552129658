import { Field, FormikProvider, useFormik } from 'formik';
import React, { useEffect, useMemo } from 'react';

import { Select } from 'components/common';
import { TDictionariesArray } from 'models/dictionaries';
import { getSelectOptionsFromDictionaryByName } from 'features/get-select-options-from-dictionary';
import { IFilterItem } from 'models';
import combineFilters from 'lib/utils/combineFilters';
import { paramsLocale, typesDict } from 'pages/dictionaries/Parameters/consts';
import FilterFormControls from 'components/form/FilterFormControls';
import { Flex, Grid } from '@chakra-ui/react';

interface FilterProps {
  isLoading?: boolean;
  isDisabled?: boolean;
  dictionaries: {
    data: TDictionariesArray;
  } | null;
  onSubmit: (values: any) => void;
  filters: IFilterItem[];
}

const initialValues: Record<string, IFilterItem> = {
  subsystemId: {
    key: 'subsystemId',
    operation: 'EQ',
    value: null,
  },
  moduleId: {
    key: 'moduleId',
    operation: 'EQ',
    value: null,
  },
  purposeId: {
    key: 'purposeId',
    operation: 'EQ',
    value: null,
  },
  type: {
    key: 'type',
    operation: 'EQ',
    value: null,
  },
  // showDisabled - value равно '' если чекбокс включен и нужно получить с Backend все значения (этот объект удалиться при отправке)
  // value равно false - если нужно показать только неудаленные записи
  showDisabled: {
    key: 'deleted',
    operation: 'EQ',
    value: false,
  },
};

export const ParametersFilter = ({ isLoading, isDisabled, onSubmit, dictionaries, filters }: FilterProps) => {
  const {
    values,
    errors,
    getFieldMeta,
    getFieldHelpers,
    getFieldProps,
    handleReset,
    submitForm,
    setFieldValue,
    ...formik
  } = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    initialValues,
    onSubmit,
  });

  const subsystems = useMemo(
    () => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'subsystems'),
    [dictionaries]
  );

  const purposes = useMemo(() => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'purposes'), [dictionaries]);

  const modules = useMemo(() => {
    const modulesDict = getSelectOptionsFromDictionaryByName(dictionaries?.data, 'modules');
    if (values.subsystemId.value) {
      return modulesDict.filter((module) => module.parent === values.subsystemId.value);
    }
    return modulesDict;
  }, [dictionaries, values]);

  useEffect(() => {
    const initialFilters = combineFilters(initialValues, filters);
    Object.keys(initialFilters).forEach((key) => {
      setFieldValue(`${key}.value`, initialFilters[key].value);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormikProvider
      value={{
        values,
        errors,
        getFieldMeta,
        getFieldHelpers,
        getFieldProps,
        handleReset,
        submitForm,
        setFieldValue,
        ...formik,
      }}
    >
      <Flex>
        <Grid width="100%" maxWidth="1200px" gap="20px" gridTemplateColumns="repeat(4, 1fr)">
          <Field
            as={Select}
            name="subsystemId.value"
            label={paramsLocale.subsystem}
            options={subsystems}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFieldValue('subsystemId.value', e.target.value);
              if (e.target.value !== values.subsystemId.value) {
                setFieldValue('moduleId.value', '');
              }
            }}
          />

          <Field
            as={Select}
            name="moduleId.value"
            label={paramsLocale.module}
            options={modules}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const rootSubsystemId = modules.find((module) => module.value === e.target.value)?.parent || '';

              setFieldValue('moduleId.value', e.target.value);
              setFieldValue('subsystemId.value', rootSubsystemId);
            }}
          />

          <Field as={Select} name="purposeId.value" options={purposes} label={paramsLocale.purpose} />
          <Field as={Select} name="type.value" options={typesDict} label={paramsLocale.type} />
        </Grid>

        <FilterFormControls
          onReset={(e) => {
            handleReset(e);
            void submitForm();
          }}
          onSubmit={submitForm}
          isLoading={isLoading}
          isDisabled={isDisabled}
        />
      </Flex>
    </FormikProvider>
  );
};
