import { useEffect, useMemo, useState } from 'react';

import { useRequest } from 'hooks/useRequest';
import { useNotifications } from 'hooks/useNotifications';
import { IChartData, IFilterValues, IServerData } from '../../types';

import { getDatasetsFromServerData } from '../helpers';
import { apiPaths } from '../../apiPaths';
import { ERROR_MESSAGES } from 'constants/messages';
import { getDatasetsLength } from '../helpers/getDatasetsLength';

interface IData {
  filters: IFilterValues | null;
  shouldUpdate: boolean;
  setShouldUpdate?: (value: boolean) => void;
  selectedIds?: string[];
  selectedKey?: 'registryIds' | 'valueIds';
}

export const useDataForChart = ({ filters, shouldUpdate, setShouldUpdate, selectedIds, selectedKey = 'registryIds' }: IData) => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<IChartData | null>(null);

  const _filters = useMemo(() =>
    (selectedIds ? { ...filters, [selectedKey]: selectedIds } : filters),
    [filters, selectedIds, selectedKey]);

  // @ts-ignore
  const path = apiPaths.reporting.values(_filters);
  const { errorNotify, successNotify } = useNotifications();
  const { get, reset, error, isLoading: isLoadingRequest } = useRequest<IServerData>(path);

  useEffect(() => {
    if (error) {
      setData(null);
      reset();
    }
  }, [error, reset, setData]);

  useEffect(() => {
    if (!shouldUpdate) return;

    if (!filters || filters.parameters?.length === undefined) {
      setData(null);
      return;
    }

    const getData = async () => {
      const newData = await get();
      const datasets = getDatasetsFromServerData(newData, filters.parameters as string[]);
      return {
        datasets,
      };
    };

    (async () => {
      try {
        setIsLoading(true);
        const data = await getData();
        setData(data);
        const num = getDatasetsLength(data);
        successNotify({
          message: `Получено ${num} т.`,
          key: `${num}32n`,
        });
      } catch (e) {
        setData(null);
        const message = e instanceof Error ? e.message : ERROR_MESSAGES.GET_DATA;
        errorNotify({
          message,
          key: 'ewio03',
        });
      } finally {
        setIsLoading(false);
        if (setShouldUpdate) {
          setShouldUpdate(false);
        }
      }
    })();
  }, [setData, filters, get, errorNotify, successNotify, shouldUpdate, setShouldUpdate]);

  return { data, setData, isLoading: isLoadingRequest || isLoading, path, reset };
};
