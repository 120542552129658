import { FormContainer, RemoveDeviceForm } from 'pages/equipment/Stations/components/RemoveDeviceForm';
import { useCallback } from 'react';
import { RemoveDeviceFromStation, StationDeviceItem } from 'pages/equipment/Stations/types';
import { useRequest } from 'hooks';

export interface RemoveDeviceProps {
  item: StationDeviceItem;
  onCancel: () => void;
  onSuccess: () => void;
}

export const RemoveDevice = ({ item, onSuccess, onCancel }: RemoveDeviceProps) => {
  const { remove } = useRequest(`/equipment/v10/equipments/stations/devices/${item.id}`, onSuccess);
  const onSubmitForm = useCallback((data: Partial<RemoveDeviceFromStation>) => {
    remove({
      params: {
        dateEnd: data.dateEnd,
      },
    });
  }, [remove]);

  return (
    <FormContainer>
      <RemoveDeviceForm
        initialValues={{
          deviceId: item.id,
          dateEnd: null,
        }}
        onSubmit={onSubmitForm}
        onCancel={onCancel}
        submitDisabled={false}
      />
    </FormContainer>
  );
};
