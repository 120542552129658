import { Flex, Grid } from '@chakra-ui/react';
import { Checkbox, Input, Select } from 'components/common';
import React, { useEffect } from 'react';
import { Field, FormikProvider, useFormik } from 'formik';
import { IFilterItem, TDictionaryItemDictionary } from 'models';
import { groundwaterInitialValues } from '../../constants/constants';
import FilterFormControls from 'components/form/FilterFormControls';
import combineFilters from 'lib/utils/combineFilters';

interface IProps {
  districts: TDictionaryItemDictionary[];
  regions: TDictionaryItemDictionary[];
  statuses: TDictionaryItemDictionary[];
  aquifers: TDictionaryItemDictionary[];
  waterTypes: TDictionaryItemDictionary[];
  setFilters: (filters: any) => void;
  filters: IFilterItem[];
}

export const GroundwaterFilter = ({
  districts,
  regions,
  statuses,
  aquifers,
  waterTypes,
  setFilters,
  filters,
}: IProps) => {
  useEffect(() => {
    const initialFilters = combineFilters(groundwaterInitialValues, filters);
    Object.keys(initialFilters).forEach((key) => {
      setFieldValue(`${key}.value`, initialFilters[key].value);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = (values: any) => {
    const filters = Object.values(values);
    setFilters(filters);
  };

  const {
    resetForm,
    values,
    errors,
    getFieldMeta,
    getFieldHelpers,
    getFieldProps,
    handleReset,
    submitForm,
    setFieldValue,
    ...formik
  } = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    initialValues: groundwaterInitialValues,
    onSubmit,
  });

  return (
    <FormikProvider
      value={{
        resetForm,
        values,
        errors,
        getFieldMeta,
        getFieldHelpers,
        getFieldProps,
        handleReset,
        submitForm,
        setFieldValue,
        ...formik,
      }}
    >
      <Flex>
        <Grid width="100%" maxWidth="1200px" gap={2} templateColumns="repeat(4, 1fr)">
          <Field as={Select} name="type.value" label="Тип объекта" options={waterTypes} />

          <Field as={Input} name="name.value" label="Название объекта" />

          <Field as={Select} name="aquafer.value" label="Водоносный горизонт" options={aquifers} />

          <Field as={Select} name="okrug.value" label="Округ" options={regions} />

          <Field as={Select} name="rayon.value" label="Район" options={districts} />

          <Field as={Input} name="address.value" label="Адрес" />

          <Field as={Select} name="status.value" label="Статус" options={statuses} />

          <Field as={Checkbox} name="deleted.value" label="Показать удаленные" />
        </Grid>

        <FilterFormControls
          onReset={() => {
            resetForm();
            setFilters([{ key: 'deleted', operation: 'EQ', value: false }]);
          }}
          onSubmit={submitForm}
        />
      </Flex>
    </FormikProvider>
  );
};
