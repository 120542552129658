import { ANALYTICS_STATUS } from 'pages/analytics/forms/const';

export const initialEmptyValues = {
  protocol: '',
  protocolDate: null,
  consumerId: null,
  researchObject: '',
  address: '',
  samplePlace: '',
  organizationId: null,
  methodology: '',
  sampleAct: '',
  sampleLaboratoryNumber: '',
  samplePeriodStart: null,
  samplePeriodEnd: null,
  sampleReceiveDate: null,
  researchDateStart: null,
  researchDateEnd: null,
  explanation: '',
  researchBasis: '',

  status: ANALYTICS_STATUS.DRAFT,
  laboratory: true,
  sign: null,
  links: null,
  values: [],
};
