import { Box, Flex, Link, Text } from '@chakra-ui/react';
import { useJournalLog } from 'lib/utils/journalLog/useJournalLog';
import React from 'react';

interface IProps {
  title: string;
  links: {
    name: string;
    path: string;
  }[]
}

export const LinksCard = ({ title, links }: IProps) => {
  const { logExternalUrl } = useJournalLog();

  return (
    <Box
      width="100%"
      boxShadow="0px 5px 10px rgba(0, 0, 0, 0.1)"
      borderRadius="4px"
      border="1px solid #D4D9DC"
    >
      <Flex pl="20px" py="16px" alignItems="center" borderBottom="1px solid #D4D9DC">
        <Text fontWeight="700" fontSize="18px" lineHeight="30px">{title}</Text>
      </Flex>

      <Flex px="20px" pt="25px" pb="20px" flexDirection="column">
        {links.map((link) => (
          <Link
            key={link.name}
            href={link.path}
            onClick={() => logExternalUrl(link.path, link.name)}
            isExternal
            textDecoration="underline"
            marginBottom="20px"
          >
            {link.name}
          </Link>
        ))}
      </Flex>
    </Box>
  );
};
