import { FieldArray, useFormikContext } from 'formik';
import { map } from 'lodash';
import { FilePreview, FilePreviewBase } from 'models';
import React from 'react';
import { Route } from 'react-router-dom';

interface ChildrenProps {
  index: number;
}

interface FilePreviewMeasuresRoutingProps {
  rootPath: string;
  children?: React.ComponentType<ChildrenProps>;
}

export function FilePreviewMeasuresRouting<T extends FilePreviewBase>({
  rootPath,
  children: Children,
}: React.PropsWithChildren<FilePreviewMeasuresRoutingProps>) {
  const { values } = useFormikContext<Partial<FilePreview<T>>>();

  return (
    <FieldArray
      name="measures"
      render={() => (
        <>
          {map(values.measures, (_, i) => (
            <Route key={i} exact path={`${rootPath}/load/preview/row/${i}`}>
              {Children && <Children index={i} />}
            </Route>
          ))}
        </>
      )}
    />
  );
}
