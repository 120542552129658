import React, { FC, useMemo } from 'react';
import { Checkbox, FormWrapper, Input, Select } from 'components/common';

import { getSelectOptionsFromDictionaryByName } from 'features/get-select-options-from-dictionary';
import { Field, useFormikContext } from 'formik';
import { TDictionariesArray } from 'models/dictionaries';
import { NSIWaterItem } from 'pages/dictionaries/NSIWater/types';
import { Box, Grid } from '@chakra-ui/react';

interface IProps {
  dictionaries: {
    data: TDictionariesArray;
  } | null;
  editedObject?: any;
}

const NSIWaterReservoirsGeneralInformationTab: FC<IProps> = ({ dictionaries, children, editedObject }) => {
  const { errors, values } = useFormikContext<NSIWaterItem>();

  const waterObjectKinds = useMemo(
    () =>
      getSelectOptionsFromDictionaryByName(
        dictionaries?.data,
        'waterObjectKinds',
        false,
        editedObject?.waterKindId as string,
      ),
    [dictionaries?.data, editedObject?.waterKindId],
  );

  const waterObjectTypes = useMemo(
    () =>
      getSelectOptionsFromDictionaryByName(
        dictionaries?.data,
        'waterObjectTypes',
        false,
        editedObject?.waterObjectTypes as string,
      ),
    [dictionaries?.data, editedObject?.waterObjectTypes],
  );
  const waterObjectsBasin = useMemo(
    () => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'waterObjectsBasin'),
    [dictionaries],
  );
  const waterObjectsControl = useMemo(
    () =>
      getSelectOptionsFromDictionaryByName(
        dictionaries?.data,
        'waterObjectsControl',
        false,
        editedObject?.waterControlId as string,
      ),
    [dictionaries?.data, editedObject?.waterControlId],
  );

  return (
    <Box flexDirection="column">
      <FormWrapper>
        <Grid marginTop="30px" gap="15px">
          <Field as={Input} name="waterObjectCode" label="Код водного объекта" error={errors.waterObjectCode} />

          <Field as={Input} name="name" label="Наименование водоема" error={errors.name} />

          <Field
            as={Select}
            name="waterTypeId"
            label="Тип водоема"
            options={waterObjectTypes}
            error={errors.waterTypeId}
          />

          <Field
            as={Select}
            name="waterKindId"
            label="Тип водоема (водоток)"
            options={waterObjectKinds}
            error={errors.waterKindId}
          />

          <Field
            as={Select}
            name="waterBasinId"
            label="Принадлежность к бассейну водного объекта"
            options={waterObjectsBasin}
            error={errors.waterBasinId}
          />

          <Field
            as={Select}
            name="waterControlId"
            label="Уровень государственного контроля"
            options={waterObjectsControl}
            error={errors.waterControlId}
          />

          <Field as={Checkbox} name="uploadToUds" label="Выгружать в ГИС ЕХД" checked={values.uploadToUds} />

          {children}
        </Grid>
      </FormWrapper>
    </Box>
  );
};

export default NSIWaterReservoirsGeneralInformationTab;
