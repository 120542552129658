import { Stack } from '@chakra-ui/react';
import { Input, Select, SelectOption } from 'components/common';
import { Field, useFormikContext } from 'formik';
import { find, map } from 'lodash';
import React, { memo, useMemo } from 'react';

import { TGreenPlantsForm } from 'pages/data/GreenPlants/Form/types';
import { GreenPlantsPlantDtoLabels, GreenPlantsPlantLabels } from './constants';
import { IFloraTabPanelProps } from './types';
import { useLocation } from 'react-router';
import isRequiredInSchema from 'lib/utils/forms/isReqiuredInSchema';
import { useGreenPlantsSchema } from 'models/schemas/pgr';

interface TFloraNumberFormProps {
  entity: IFloraTabPanelProps['entity'];
  isLoading: IFloraTabPanelProps['isLoading'];
  plants: IFloraTabPanelProps['plants'];
}

const FloraNumberForm: React.FC<TFloraNumberFormProps> = memo(({ entity, plants, isLoading }) => {
  const ctx = useFormikContext<TGreenPlantsForm>();
  const { GreenPlantsSchema } = useGreenPlantsSchema(entity);

  const location = useLocation();
  const isEditMode = location.pathname.includes('/edit');

  const plant = useMemo(
    () => find(plants, (plant) => plant.id === ctx.values.flora.plantId),
    [ctx.values.flora.plantId, plants],
  );

  const plantsOptions: SelectOption[] = useMemo(
    () =>
      map(plants, ({ number, breed, id }) => ({
        name: `${number} - ${breed}`,
        value: id || '-',
      })),
    [plants],
  );

  return (
    <Stack>
      <Field
        isDisabled={isLoading || isEditMode}
        as={Select}
        name={`${entity}.plantId`}
        label={GreenPlantsPlantDtoLabels.plantId}
        options={plantsOptions}
        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
          ctx.setFieldValue(`${entity}.plantId`, e.target.value);
        }}
        isRequired={isRequiredInSchema(GreenPlantsSchema, `${entity}.fields.plantId`)}
        error={ctx.errors && ctx.errors[entity]?.plantId}
      />
      <Input readOnly isDisabled value={plant?.type ?? ''} label={GreenPlantsPlantLabels.type} />
      <Input readOnly isDisabled value={plant?.breed ?? ''} label={GreenPlantsPlantLabels.breed} />
      <Input readOnly isDisabled value={plant?.date ?? ''} label={GreenPlantsPlantLabels.date} />
    </Stack>
  );
});

export { FloraNumberForm };
