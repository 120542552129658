import { Box, Flex, Grid } from '@chakra-ui/react';
import { Button, FormWrapper, Input, Select } from 'components/common';
import { Field, FieldArray, useFormikContext } from 'formik';
import React, { useCallback, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { uniq } from 'lodash';
import { IDictionarySchema } from '../../const/types';
import { FormField } from './FormField';
import { NSIRootPath } from '../../const/routes';
import { useNSIDictionary } from 'pages/dictionaries/hooks/useNSIDictionary';

export interface FormValues {
  id?: string;
  name: string;
  shortName: string;
  aliases: string[] | null;
  value: { [key: string]: string | number | boolean | null };
  comment: string;
}

interface Props {
  properties: IDictionarySchema['properties'];
  oldAliases?: string[] | null;
}

export const Form: React.FC<Props> = ({ properties = {}, oldAliases }) => {
  const history = useHistory();
  const { handleSubmit, errors, values, setFieldValue } = useFormikContext<FormValues>();
  const { dictionaryId } = useParams<{ dictionaryId: string }>();
  const { dictionary } = useNSIDictionary(dictionaryId);
  const isReadonly = Boolean(dictionary?.effectiveRights.find((right) => right === 'READ'));
  const isExecute = Boolean(dictionary?.effectiveRights.find((right) => right === 'EXECUTE'));
  const isWrite = Boolean(dictionary?.effectiveRights.find((right) => right === 'WRITE'));
  const isNoRights = !isReadonly && !isExecute && !isWrite;
  const allAvailable = isReadonly && isWrite && isExecute;
  const isAvailable = allAvailable || isNoRights || isWrite;

  const [newAliasOptions, setNewAliasOptions] = useState<string[]>([]);
  const aliasOptions = uniq(newAliasOptions.concat(values.aliases || [])).map((textVal) => ({
    value: textVal,
    name: textVal,
    isDisabled: oldAliases?.includes(textVal),
  }));

  const onAliasAdd = useCallback(
    (value: string) => {
      setNewAliasOptions((prev) => uniq([...prev, value]));
      setFieldValue('aliases', uniq([...(values.aliases || []), value]));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [values],
  );

  return (
    <Box flexDirection="column">
      <FormWrapper>
        <Grid marginTop="30px" gap="15px" marginBottom={20}>
          <Field isDisabled={!isAvailable} as={Input} name="name" label="Имя" error={errors.name} value={values.name} />
          <Field
            as={Input}
            name="shortName"
            label="Сокращенное имя"
            isDisabled={!isAvailable}
            error={errors.shortName}
            value={values.shortName}
          />
          <Field
            as={Select}
            name="aliases"
            label="Алиасы"
            options={aliasOptions}
            value={values.aliases}
            error={errors.aliases}
            isDisabled={!isAvailable}
            multiple
            onAddItem={onAliasAdd}
          />
          <FieldArray name="value">
            {() => (
              <>
                {Object.keys(properties).map((key) => (
                  <FormField
                    key={key}
                    isDisabled={!isAvailable}
                    field={properties[key]}
                    fieldKey={key}
                    error={(errors.value && errors.value[key]) || ''}
                    value={values.value[key]}
                  />
                ))}
              </>
            )}
          </FieldArray>
          <Field as={Input} name="comment" label="Примечание" />
          <Flex>
            <Button
              onClick={() => {
                history.push(`${NSIRootPath}/${dictionaryId}`);
              }}
              marginRight={20}
            >
              Отмена
            </Button>
            <Button
              onClick={() => {
                handleSubmit();
              }}
              variant="solid"
              isDisabled={!isAvailable}
            >
              Сохранить
            </Button>
          </Flex>
        </Grid>
      </FormWrapper>
    </Box>
  );
};
