import PlanningPel from 'pages/pel';
import RaidDetail from 'pages/pel/RaidDetail';
import { Route } from 'react-router-dom';
import { PEL_ROOT_PATH, RAID_DETAIL_PATH } from '../pel/constants';

const PlanningPelRoutes: React.FunctionComponent = () => (
  <>
    <Route exact path={PEL_ROOT_PATH} component={PlanningPel} />

    <Route exact path={RAID_DETAIL_PATH} component={RaidDetail} />
  </>
);

export default PlanningPelRoutes;
