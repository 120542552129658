import { FormHeader } from 'components/common';
import { createTouchedErrors } from 'features/create-touched-errors';
import { FormikProvider, useFormik } from 'formik';
import { useRequest } from 'hooks/useRequest';
import FieldsForm from './FieldsForm';
import { AtmosphereMonitoringSchema } from 'models/schemas/atmosphere/monitoring';
import { useGoBackWithFallback } from 'lib/utils/goBackWithFallback';
import { useDictionary } from 'hooks/useDictionary';
import { useEnhancedNotification } from 'hooks';

export const initialEmptyValues = {
  avg: {
    id: '',
    valDateTime: '',
    strValue: '',
    valVersion: 0,
  },
  max: {
    id: '',
    valDateTime: '',
    strValue: '',
    valVersion: 0,
  },
  min: {
    id: '',
    valDateTime: '',
    strValue: '',
    valVersion: 0,
  },
  paramId: '',
  stationId: '',
};

export const AddAtmosphereMonitoringResult = () => {
  const { backWithFallback } = useGoBackWithFallback();

  const { isDictionariesLoading, dictionaries } = useDictionary({ url: '/atmosphere/v10/atmosphere/dictionaries' });

  const { onSuccessCreate, onErrorCreate } = useEnhancedNotification();

  const onSuccess = () => {
    onSuccessCreate();
    backWithFallback('/data/atmosphere/monitoring');
  };

  const { post, isLoading } = useRequest('/atmosphere/v10/atmosphere', onSuccess, onErrorCreate);

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    initialValues: initialEmptyValues,
    onSubmit: (values) => post(values),
    validationSchema: AtmosphereMonitoringSchema,
  });

  return (
    <FormikProvider
      value={{
        ...formik,
        errors: createTouchedErrors(formik.errors, formik.touched),
      }}
    >
      <FormHeader
        onBackClick={() => backWithFallback('/data/atmosphere/monitoring')}
        header="Добавление результатов измерения"
      />
      <FieldsForm
        dictionaries={dictionaries}
        onSubmit={() => {}}
        onCancel={() => backWithFallback('/data/atmosphere/monitoring')}
        isLoading={isDictionariesLoading || isLoading}
      />
    </FormikProvider>
  );
};
