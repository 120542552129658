import { RoutesList } from 'components/common';
import { stationsLocale } from '../Stations/locale';
import { modelsLocale } from '../Models/locale';
import { devicesLocale } from 'pages/equipment/Devices/consts';
// import { reportsLocale } from '../Reports/locale';
import { EquipmentsAppRoutes } from '../equipmentsAppRoutes';
import { useEquipmentBreadcrumbs } from '../hooks/useEquipmentBreadcrumbs';
import { reportsLocale } from 'pages/equipment/Reports/locale';

export const EquipmentList = () => {
  useEquipmentBreadcrumbs();

  return (
    <RoutesList
      routes={[
        {
          breadcrumb: modelsLocale.models,
          path: EquipmentsAppRoutes.models.root,
        },
        {
          breadcrumb: reportsLocale.reports,
          path: EquipmentsAppRoutes.reports.root,
        },
        {
          breadcrumb: devicesLocale.devices,
          path: EquipmentsAppRoutes.devices.root,
        },
        {
          breadcrumb: stationsLocale.stations,
          path: EquipmentsAppRoutes.stations.root,
        },
      ]}
    />
  );
};
