import React, { useMemo } from 'react';
import { Field, FormikProvider, useFormik } from 'formik';
import { Input, Select } from 'components/common';
import { Flex, Grid, GridItem } from '@chakra-ui/react';
import { IFilterItem, TDictionariesArray } from 'models';
import { getSelectOptionsFromDictionaryByName } from 'features/get-select-options-from-dictionary';
import FilterFormControls from 'components/form/FilterFormControls';

interface Props {
  onSubmit: (values: any) => void;
  dictionaries: {
    data: TDictionariesArray;
  } | null;
  isLoading: boolean;
}

const initialValues: Record<string, IFilterItem> = {
  name: {
    key: 'name',
    operation: 'CONTAINS',
    value: null,
  },
  ip: {
    key: 'ip',
    operation: 'EQ',
    value: null,
  },
  status: {
    key: 'status',
    operation: 'EQ',
    value: '',
  },
  showDisabled: {
    key: 'deleted',
    operation: 'EQ',
    value: false,
  },
};

export const ServersFilter = ({ onSubmit, dictionaries, isLoading }: Props) => {
  const {
    values,
    errors,
    getFieldMeta,
    getFieldHelpers,
    getFieldProps,
    handleReset,
    submitForm,
    setFieldValue,
    ...formik
  } = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    initialValues,
    onSubmit,
  });

  const onReset = (e: any) => {
    handleReset(e);
    submitForm();
  };

  const statuses = useMemo(() => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'status'), [dictionaries]);

  return (
    <FormikProvider
      value={{
        values,
        errors,
        getFieldMeta,
        getFieldHelpers,
        getFieldProps,
        handleReset,
        submitForm,
        setFieldValue,
        ...formik,
      }}
    >
      <Flex flexDirection="row" justifyContent="space-between">
        <Grid marginRight="auto" marginLeft={1} gap={3} templateColumns="repeat(3, 1fr)">
          <GridItem rowStart={1} rowEnd={1} colStart={1} colSpan={1}>
            <Field
              as={Input}
              name="name.value"
              label="FTP-сервер"
            />
          </GridItem>
          <GridItem rowStart={1} rowEnd={1} colStart={2} colSpan={1}>
            <Field
              as={Select}
              name="status.value"
              options={statuses}
              label="Статус"
            />
          </GridItem>
          <GridItem rowStart={1} rowEnd={1} colStart={3} colSpan={1}>
            <Field
              as={Input}
              name="ip.value"
              label="Адреc"
            />
          </GridItem>
        </Grid>

        <FilterFormControls
          onReset={onReset}
          onSubmit={submitForm}
          isLoading={isLoading}
          isDisabled={isLoading}
        />
      </Flex>
    </FormikProvider>
  );
};
