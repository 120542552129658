import { RenderTree } from 'features/WithSideBar/types';
import { consentrationPath, pdkLocales, pdkRootPath, substancesPath } from 'pages/dictionaries/NSIPdk/consts';
import { subsystemCodesMap } from 'constants/subsystemIdsMap';

export const pdkLeftMenu: RenderTree = {
  name: pdkLocales.main,
  id: pdkRootPath,
  url: pdkRootPath,
  otherCode: subsystemCodesMap.nsi.pdk.pdkRoot,
  children: [
    {
      name: pdkLocales.substances,
      id: substancesPath,
      url: substancesPath,
      otherCode: subsystemCodesMap.nsi.pdk.substances,
    },
    {
      name: pdkLocales.concentration,
      id: consentrationPath,
      url: consentrationPath,
      otherCode: subsystemCodesMap.nsi.pdk.pdk,
    },
  ],
};
