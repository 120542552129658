import { TabList, Tabs } from '@chakra-ui/react';
import { StyledTab } from 'components/common';
import { find, findIndex, map } from 'lodash';
import React, { useMemo } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';

interface NavTabListProps {
  tabs: Array<{ label: string; path: string; isDisabled?: boolean, searchPath?: string, query?: string }>;
  findByStart?: boolean;
  tabIndex?: number;
}

const NavTabList: React.FC<NavTabListProps> = ({ tabs, findByStart, tabIndex }) => {
  const { path } = useRouteMatch();

  const currentIndex = useMemo(
    () =>
      findIndex(
        tabs,
        find(tabs, (tab) => {
          if (findByStart) {
            return path.startsWith(tab.path);
          }
          return tab.searchPath === path || tab.path === path;
        })
      ),
    [path, tabs, findByStart]
  );

  return (
    <Tabs variant="unstyled" defaultIndex={tabIndex || currentIndex}>
      <TabList>
        {map(tabs, ({ label, path, isDisabled = false, query }) => (
          <StyledTab
            isDisabled={isDisabled}
            key={path}
            as={Link}
            to={path + (query || '')}
            height="30px"
          >
            {label}
          </StyledTab>
        ))}
      </TabList>
    </Tabs>
  );
};

export { NavTabList };
