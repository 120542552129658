import { useMemo } from 'react';
import { useHistory } from 'react-router';
import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import { Appeals, CitizensAppealsPath } from 'pages/citizenAppeal';
import { CitizenAppealFilter } from './Filter/Filter';
import { MaterialTable } from 'features/material-table/MaterialTableMini';
import { useList } from 'hooks/useList';
import { CitizenAppealListItem } from '../consts/types';
import { TFiltersArray } from 'models';
import { getSelectRichOptionsFromDictionaryByName } from 'features/get-select-options-from-dictionary';
import { ComplaintsHeader } from 'pages/citizenAppeal/CitizensAppealsRecord/components/ComplaintsHeader';
import { citizenAppealsColumns } from 'pages/citizenAppeal/CitizensAppealsRecord/citizenAppealsColumns';
import { useDictionary } from 'hooks/useDictionary';
import { useHandleDeleteData } from 'hooks';
import { tableIcons } from '../../admin/components/CustomTable';
import { subsystemCodesMap } from 'constants/subsystemIdsMap';
import { useCustomRights } from 'hooks/useCustomRights';
import { DEFAULT_PAGE_SIZE_NEW_TABLE } from '../../../constants/tables';
import { FullPageWrapper } from '../../../components/table/FullPageWrapper';

const initialBradcrumbs = [
  {
    breadcrumb: 'Учет обращений граждан',
    path: CitizensAppealsPath,
  },
  {
    breadcrumb: 'Обращения граждан',
    path: Appeals,
  },
];

interface IProps {
  breadcrumbs?: { path: string; breadcrumb: string }[];
}

export const CitizensAppealList = ({ breadcrumbs = initialBradcrumbs }: IProps) => {
  const { isWrite, isExecute } = useCustomRights(subsystemCodesMap.citizenAppeals.appeals);
  useUpdateBreadcrumbs(breadcrumbs);

  const { dictionaries, isDictionariesLoading } = useDictionary({ url: '/appeal/v10/appeals/dictionaries' });

  const sources = useMemo(
    () => getSelectRichOptionsFromDictionaryByName(dictionaries?.data, 'sources'),
    [dictionaries],
  );
  const areas = useMemo(() => getSelectRichOptionsFromDictionaryByName(dictionaries?.data, 'areas'), [dictionaries]);
  const districts = useMemo(
    () => getSelectRichOptionsFromDictionaryByName(dictionaries?.data, 'districts'),
    [dictionaries],
  );
  const incidentTypes = useMemo(
    () => getSelectRichOptionsFromDictionaryByName(dictionaries?.data, 'incidentTypes'),
    [dictionaries],
  );
  const noiseSourceTypeId = useMemo(
    () => getSelectRichOptionsFromDictionaryByName(dictionaries?.data, 'noiseSourceTypeId'),
    [dictionaries],
  );
  const odorSourceTypeId = useMemo(
    () => getSelectRichOptionsFromDictionaryByName(dictionaries?.data, 'odorSourceTypeId'),
    [dictionaries],
  );
  const history = useHistory();

  const {
    isLoading: isAppealListLoading,
    response,
    setFilters,
    toggleOrder,
    onPageChange,
    filterDTO,
    onSearchText,
    errorText,
    materialTableProps,
    refetch,
  } = useList<CitizenAppealListItem>('appeal/v10/appeals/list', {
    initialOrderFieldKey: '-assignmentExecution',
    saveFiltersState: true,
    defaultPageSize: DEFAULT_PAGE_SIZE_NEW_TABLE,
    resetDataOnNextRequest: true,
  });

  const { ModalComponent, handleOpenModal } = useHandleDeleteData({ refetch });

  return (
    <FullPageWrapper>
      <ComplaintsHeader isWrite={isWrite}>
        <CitizenAppealFilter
          sources={sources}
          areas={areas}
          districts={districts}
          noiseSourceTypeId={noiseSourceTypeId}
          odorSourceTypeId={odorSourceTypeId}
          incidentTypes={incidentTypes}
          onSubmit={(values) => {
            const filters: TFiltersArray = Object.values(values);
            setFilters(filters);
          }}
          setFilters={setFilters}
          filters={filterDTO.filters}
          isLoading={isDictionariesLoading || isAppealListLoading}
        />
      </ComplaintsHeader>

      <MaterialTable
        isNotExecute={isExecute}
        data={response?.data || []}
        meta={response?.meta}
        filterDTO={filterDTO}
        toggleOrder={toggleOrder}
        options={{
          search: true,
          selection: false,
        }}
        isLoading={isAppealListLoading}
        onSearch={onSearchText}
        onPageChange={onPageChange}
        columns={citizenAppealsColumns}
        actions={[
          {
            position: 'row',
            action: (row) => ({
              icon: tableIcons.Edit,
              tooltip: 'Открыть',
              onClick: () => {
                history.push(`${CitizensAppealsPath}/edit/${row.complaintId}`, { isWrite });
              },
            }),
          },
          {
            position: 'row',
            action: (row) => ({
              icon: tableIcons.Delete,
              tooltip: 'Удалить',
              onClick: () => {
                if (!row?.complaintId) return;
                handleOpenModal({ url: `/appeal/v10/appeals/complaints/${row.complaintId}` });
              },
              hidden: row.deleted || !isWrite,
            }),
          },
        ]}
        errorText={errorText}
        {...materialTableProps}
      />
      {ModalComponent}
    </FullPageWrapper>
  );
};
