import { useMemo, useState } from 'react';
import { Box, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { DefaultText, FormHeader } from 'components/common';
import { ISubstanceValues, pdkLocales, substancesPath } from '../../consts';
import { SubstanceСharacteristics } from './Tabs/Сharacteristics';
import { SubstanceParameters } from './Tabs/Parameters';
import { useQuery } from 'hooks/useQuery';
import { TDictionariesArray } from 'models';
import { useGoBackWithFallback } from 'lib/utils/goBackWithFallback';

interface Props {
  isEditMode?: boolean;
  dictionaries?: TDictionariesArray;
  editedObject?: ISubstanceValues | null;
}

export const SubstanceFields = ({ isEditMode, dictionaries, editedObject }: Props) => {
  const { backWithFallback } = useGoBackWithFallback();

  const { openAttachmentsTab } = useQuery();
  const [tabIndex, setTabIndex] = useState(openAttachmentsTab ? 2 : 0);

  const title = useMemo(
    () => (isEditMode ? pdkLocales.common.substanceEditing : pdkLocales.common.substanceAdding),
    [isEditMode],
  );
  const onCancel = () => {
    backWithFallback(substancesPath);
  };

  const onNextTab = () => setTabIndex((prev) => prev + 1);

  return (
    <Box flexDirection="column">
      <Tabs index={tabIndex} onChange={(index) => setTabIndex(index)}>
        <FormHeader onBackClick={onCancel} header={title}>
          <TabList>
            {[pdkLocales.common.characteristics, pdkLocales.common.params].map((tab, i) => (
              <Tab key={i}>
                <DefaultText cursor="pointer">{tab}</DefaultText>
              </Tab>
            ))}
          </TabList>
        </FormHeader>
        <TabPanels>
          <TabPanel>
            <SubstanceСharacteristics
              isEditMode={isEditMode}
              dictionaries={dictionaries}
              onNextTab={onNextTab}
              onCancel={onCancel}
              editedObject={editedObject}
            />
          </TabPanel>
          <TabPanel>
            <SubstanceParameters dictionaries={dictionaries} onCancel={onCancel} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};
