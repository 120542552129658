import React, { FC, useMemo } from 'react';
import { DateInput, FormWrapper, Input, Select } from 'components/common';

import { getSelectOptionsFromDictionaryByName } from 'features/get-select-options-from-dictionary';
import { Field, useFormikContext } from 'formik';
import { createDateFromResponse, createDateToRequestForFilter, createDateToRequestWithTimezone } from 'lib/create-date';
import { TDictionariesArray } from 'models/dictionaries';
import { NSIReagentsListItem } from 'pages/dictionaries/NSIReagents/types';
import { Box, Grid } from '@chakra-ui/react';

interface IProps {
  dictionaries: {
    data: TDictionariesArray;
  } | null;
  editedObject?: any;
  isEditMode?: boolean
}

const NSIReagentsTreesGeneralInformationTab: FC<IProps> = ({ dictionaries, children, editedObject, isEditMode }) => {
  const { setFieldValue, values, errors } = useFormikContext<NSIReagentsListItem>();

  const statuses = useMemo(() => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'statuses'), [dictionaries]);

  const areasForTrees = useMemo(
    () => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'areasForTrees'),
    [dictionaries],
  );

  const areaLocations = useMemo(
    () =>
      getSelectOptionsFromDictionaryByName(
        dictionaries?.data,
        'areaLocations',
        false,
        editedObject?.greenAreaLocationId,
      ),
    [dictionaries?.data, editedObject?.greenAreaLocationId],
  );

  const lifeBreeds = useMemo(
    () =>
      getSelectOptionsFromDictionaryByName(
        dictionaries?.data,
        'lifeBreeds',
        false,
        editedObject?.greenPlantLifeBreedId,
      ),
    [dictionaries?.data, editedObject?.greenPlantLifeBreedId],
  );

  const lifeForms = useMemo(
    () =>
      getSelectOptionsFromDictionaryByName(dictionaries?.data, 'lifeForms', false, editedObject?.greenPlantLifeFormId),
    [dictionaries?.data, editedObject?.greenPlantLifeFormId],
  );

  const areaTypes = useMemo(
    () => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'areaTypes', false, editedObject?.greenAreaTypeId),
    [dictionaries?.data, editedObject?.greenAreaTypeId],
  );

  return (
    <Box flexDirection="column">
      <FormWrapper>
        <Grid marginTop="30px" gap="15px">
          <Field
            as={Select}
            name="parentAreaId"
            label="Номер площадки"
            value={values.parentAreaId}
            options={areasForTrees}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              setFieldValue('parentAreaId', e.target.value);
            }}
            error={errors.parentAreaId}
            isDisabled={isEditMode}
          />

          <Field
            as={Select}
            name="greenAreaTypeId"
            label="Тип территории"
            value={values.greenAreaTypeId}
            options={areaTypes}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              setFieldValue('greenAreaTypeId', e.target.value);
            }}
            error={errors.greenAreaTypeId}
          />

          <Field as={Input} type="number" name="number" label="Номер растения" error={errors.number} isDisabled={isEditMode} />

          <Field
            as={Select}
            name="greenPlantLifeBreedId"
            label="Порода"
            value={values.greenPlantLifeBreedId}
            options={lifeBreeds}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              setFieldValue('greenPlantLifeBreedId', e.target.value);
            }}
            error={errors.greenPlantLifeBreedId}
          />

          <Field
            as={Select}
            name="greenPlantLifeFormId"
            label="Жизненная форма"
            value={values.greenPlantLifeFormId}
            options={lifeForms}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              setFieldValue('greenPlantLifeFormId', e.target.value);
            }}
            error={errors.greenPlantLifeFormId}
          />

          <Field
            as={Select}
            name="greenAreaLocationId"
            label="Местоположение растительной группировки"
            value={values.greenAreaLocationId}
            options={areaLocations}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              setFieldValue('greenAreaLocationId', e.target.value);
            }}
            error={errors.greenAreaLocationId}
          />

          <Field
            as={Select}
            name="status"
            label="Статус"
            value={values.status}
            options={statuses}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              setFieldValue('status', e.target.value);
            }}
            error={errors.status}
          />

          <DateInput
            name="greenStartDate"
            label="Дата"
            value={createDateFromResponse(values.greenStartDate)}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setFieldValue('greenStartDate', createDateToRequestForFilter(e.target.value, 'end'))
            }
            error={errors.greenStartDate}
          />

          <DateInput
            name="startDate"
            label="Начало действия"
            value={createDateFromResponse(values.startDate)}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setFieldValue('startDate', createDateToRequestWithTimezone(e.target.value))
            }
            error={errors.startDate}
            showTimeInput
          />

          <DateInput
            name="stopDate"
            label="Окончание действия"
            value={createDateFromResponse(values.stopDate)}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setFieldValue('stopDate', createDateToRequestWithTimezone(e.target.value))
            }
            error={errors.stopDate}
            showTimeInput
          />

          {children}
        </Grid>
      </FormWrapper>
    </Box>
  );
};

export default NSIReagentsTreesGeneralInformationTab;
