import React from 'react';
import { Button } from 'components/common';
import { Flex } from '@chakra-ui/react';

interface FieldsFormControlsProps {
  onCancel: any;
  handleSubmit?: any;
  onNextTab?: () => void;
  showSave?: boolean;
  isSaveDisabled?: boolean;
}

export const FieldsFormControls = ({
  onCancel,
  handleSubmit,
  onNextTab,
  showSave = false,
  isSaveDisabled,
}: FieldsFormControlsProps) => (
  <Flex marginBottom={30}>
    <Button onClick={onCancel} marginRight="auto">
      Отмена
    </Button>

    {onNextTab && (
      <Button ml={1} onClick={onNextTab} variant="solid">
        Далее
      </Button>
    )}

    {handleSubmit && showSave && (
      <Button isDisabled={isSaveDisabled} onClick={() => handleSubmit()} marginLeft={2} variant="solid">
        Сохранить
      </Button>
    )}
  </Flex>
);
