import { Field, useFormikContext } from 'formik';
import { Button, Input, Select, SelectOption } from 'components/common';
import { Grid, GridItem } from '@chakra-ui/react';
import { VideoCamerasPath } from '../../consts/consts';
import { ICamItem } from '../types/types';
import { useGoBackWithFallback } from 'lib/utils/goBackWithFallback';
import {
  AsyncAddressSelect,
} from 'components/common/AsyncAddressSelect/AsyncAddressSelect';

interface IProps {
  cameraTypes?: SelectOption[];
  admAreas?: SelectOption[];
  districts?: SelectOption[];
}

export const EditForm = ({ cameraTypes, admAreas, districts }: IProps) => {
  const { handleSubmit, errors } = useFormikContext<ICamItem>();
  const { backWithFallback } = useGoBackWithFallback();

  return (
    <>
      <Grid maxWidth={1200} gap={3} marginTop={5} marginLeft={5} marginRight={5}>
        <Grid gap={3} templateColumns="repeat(3, 1fr)">
          <Field as={Input} name="number" label="№ камеры" error={errors?.number} />
          <Field as={Input} name="latitude" label="Широта" error={errors?.latitude} />
          <Field as={Input} name="longitude" label="Долгота" error={errors?.longitude} />
        </Grid>
        <Grid gap={3}>
          <Field
            as={AsyncAddressSelect}
            name="address"
            label="Адрес расположения"
          />
        </Grid>
        <Grid gap={3}>
          <Field as={Select} options={admAreas} name="areaId" label="Округ" />
        </Grid>
        <Grid gap={3}>
          <Field as={Select} options={districts} name="regionId" label="Район" />
        </Grid>
        <Grid gap={3}>
          <Field as={Input} name="description" label="Описание" />
        </Grid>
        <Grid gap={3}>
          <Field as={Input} name="viewingAngle" label="Фактическое направление обзора" error={errors?.viewingAngle} />
        </Grid>
        <Grid gap={3}>
          <Field as={Select} options={cameraTypes} name="type" label="Вид камеры" error={errors?.type} />
        </Grid>

        <Grid gap={3} templateColumns="repeat(6, 1fr)">
          <GridItem colEnd={1} colStart={0}>
            <Button onClick={() => backWithFallback(VideoCamerasPath)}>Отменить</Button>
          </GridItem>
          <GridItem colEnd={2} colStart={1}>
            <Button onClick={() => handleSubmit()}>Сохранить</Button>
          </GridItem>
        </Grid>
      </Grid>
    </>
  );
};
