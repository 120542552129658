import { Modal } from 'components/common/Modals';
import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import { useAvailability, useDisclosure } from 'hooks';
import { TFiltersArray } from 'models';
import { useList } from 'hooks/useList';
import appLocale from 'constants/appLocale';
import { GeoecologicalRootPath } from 'pages/data/GeoecologicalProcesses/consts';
import { GeologicalProcessesGroundwaterHeader } from '../components';
import { MonitoringFilter } from './MonitoringFilter';
import { MonitoringTable } from './Table';
import { MonitoringItem } from 'pages/data/GeoecologicalProcesses/GeologicalProcessesGroundwater/Monitoring/types';
import { UploadFileModalContent } from 'features/UploadFileDragAndDrop/UploadFileModalContent';
import subsystemIdsMap from 'constants/subsystemIdsMap';
import { usePeriodsDict } from 'features/PeriodsButtons/usePeriodsDict';
import { useDictionary } from 'hooks/useDictionary';
// import { ExtensionToMimeTypeEnum } from 'lib/utils/files/getFileExtensionByMimeType';
import { DEFAULT_PAGE_SIZE_NEW_TABLE } from '../../../../../constants/tables';
import { FullPageWrapper } from '../../../../../components/table/FullPageWrapper';
import { ExtensionToMimeTypeEnum } from 'lib/utils/files/getFileExtensionByMimeType';

export const Monitoring = () => {
  const { isWrite, isReadOnly, isExecute, isNoRights, allAvailable } = useAvailability(subsystemIdsMap.geoecologyRoot);

  useUpdateBreadcrumbs([
    { breadcrumb: appLocale.common.data, path: '/data' },
    {
      breadcrumb: appLocale.subsystems.geoecologicalprocesses,
      path: `${GeoecologicalRootPath}`,
    },
    {
      breadcrumb: appLocale.subsystems.groundwater,
      path: `${GeoecologicalRootPath}/monitoring/water/examination`,
    },
  ]);

  const { isOpen: isOpenLoad, onClose: onCloseLoad, onOpen: onOpenLoad } = useDisclosure();

  const {
    refetch,
    isLoading: isMonitoringListLoading,
    response,
    setFilters,
    toggleOrder,
    onPageChange,
    filterDTO,
    downloadAsFile,
    saveFilters,
    errorText,
    selectedIndexes,
    renderOnlySelectedCheckbox,
    onSubmitFilters,
    onSelectionChange,
    materialTableProps,
  } = useList<MonitoringItem, keyof MonitoringItem>('/geoecology/v10/geoecology/watersource/monitoring/list', {
    initialOrderFieldKey: '-measuringDate',
    preventFirstFetch: true,
    exportSubsystemName: 'examination',
    saveFiltersState: true,
    selectionKey: 'id',
    downloadUrl: {
      [ExtensionToMimeTypeEnum.csv]: '/geoecology/v10/geoecology/watersource/monitoring/list/export',
      [ExtensionToMimeTypeEnum.xlsx]: '/geoecology/v10/geoecology/watersource/monitoring/list/export',
    },
    defaultPageSize: DEFAULT_PAGE_SIZE_NEW_TABLE,
    resetDataOnNextRequest: true,
  });

  const { dictionaries } = useDictionary({ url: '/geoecology/v10/geoecology/watersource/monitoring/dictionaries' });
  const { result: periods } = usePeriodsDict(`auth/v10/user/period/${subsystemIdsMap.geoGroundWater}`);

  return (
    <FullPageWrapper>
      <GeologicalProcessesGroundwaterHeader
        onOpenLoad={onOpenLoad}
        active="examination"
        allAvailable={allAvailable}
        isNoRights={isNoRights}
        isWrite={isWrite}
      >
        <MonitoringFilter
          filters={filterDTO?.filters || []}
          periods={periods}
          isLoading={isMonitoringListLoading}
          isDisabled={isMonitoringListLoading}
          dictionaries={dictionaries}
          onSubmit={(values) => {
            const filters: TFiltersArray<keyof MonitoringItem> = Object.values(values);
            onSubmitFilters(filters);
          }}
        >
          {renderOnlySelectedCheckbox}
        </MonitoringFilter>
      </GeologicalProcessesGroundwaterHeader>

      <Modal isOpen={isOpenLoad} onClose={onCloseLoad} header="Загрузка данных из файла">
        <UploadFileModalContent
          createTypesPath={() => '/geoecology/v10/geoecology/watersource/monitoring/load/types'}
          createUploadPath={(typeId) => `/geoecology/v10/geoecology/watersource/monitoring/load?typeId=${typeId}`}
          types={[
            {
              value: '2e378680-31e5-46b7-943c-d59635cef02e',
              name: 'Данные состояния водоисточников (Колодцы)',
            },
            {
              value: '7c2b0b1a-a76e-4eb8-a66d-b86c28b8f1d3',
              name: 'Данные состояния водоисточников (Родники)',
            },
          ]} // На случай если не подгуржаются типы
          onClose={onCloseLoad}
          refetch={refetch}
        />
      </Modal>

      <MonitoringTable
        allAvailable={allAvailable}
        isExecute={isExecute}
        isWrite={isWrite}
        isReadonly={isReadOnly}
        isNoAction={isNoRights}
        filters={filterDTO.filters}
        setFilters={setFilters}
        refetch={refetch}
        monitoringList={response}
        toggleOrder={toggleOrder}
        filterDTO={filterDTO}
        onPageChange={onPageChange}
        downloadAsFile={downloadAsFile}
        selectedIndexes={selectedIndexes}
        onSelectionChange={onSelectionChange}
        saveFilters={saveFilters}
        isLoading={isMonitoringListLoading}
        errorText={errorText}
        materialTableProps={materialTableProps}
      />
    </FullPageWrapper>
  );
};
