import React, { memo, useMemo } from 'react';
import { Button, SubsystemHeader } from 'components/common';

import { useHistory } from 'react-router-dom';
import { ButtonProps, HStack } from '@chakra-ui/react';

interface HeaderProps {
  active: string;
  onOpenLoad?: () => void;
  isReadonly?: boolean;
  isWrite?: boolean;
  isNoRights?: boolean;
  isExecute?: boolean;
  allAvailable?: boolean;
  title: string;
  addButtonProps?: ButtonProps;
  rootPath: string;
  mainDataTitle: string;
  analyticsDataTitle: string;
}

export const HeaderWithAnalytics: React.FC<HeaderProps> = memo(
  ({
    onOpenLoad, active, children, isWrite,
    isNoRights, allAvailable, title, addButtonProps, rootPath, mainDataTitle, analyticsDataTitle
  }) => {
    const isAvailable = allAvailable || isNoRights || isWrite;
    const history = useHistory();
    const headerTabs = useMemo(() => ([
      {
        label: mainDataTitle,
        value: 'main-data',
      },
      {
        label: analyticsDataTitle,
        value: 'analytics-data',
      },
    ]), [mainDataTitle, analyticsDataTitle]);

    return (
      <SubsystemHeader
        onUploadClick={isAvailable ? onOpenLoad : undefined}
        title={title}
        addButtonProps={addButtonProps}
        filtersComponent={children}
        navigationTabs={(
          <HStack spacing="0px">
            {headerTabs.map(({ label, value }) => (
              <Button
                key={value}
                colorScheme="blue"
                marginRight={1}
                backgroundColor={value === active ? 'PrimaryButton.500' : 'white'}
                color={value === active ? 'white' : 'PrimaryButton.500'}
                variant={value === active ? 'solid' : 'outline'}
                onClick={() => history.push(`${rootPath}/${value}`)}
                height="30px"
              >
                {label}
              </Button>
            ))}
          </HStack>
        )}
      />
    );
  }
);
