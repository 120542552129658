import type { ChangeEvent } from 'react';
import { Field, useFormikContext } from 'formik';

import { DateInput, FormWrapper, Input } from 'components/common';

import { createDateToRequestWithTimezone as createDateToRequest } from 'lib/create-date';

import { fieldsNoiseLocale, INoisePackageForm, tabsLocale } from 'pages/data/MASKSH/consts';
import { Grid, Heading } from '@chakra-ui/react';

export const NoisePackageFormNoiseTab = () => {
  const { setFieldValue, values, errors } = useFormikContext<INoisePackageForm>();

  return (
    <FormWrapper>
      <Grid gap={2}>
        <Heading mb={2} size="md">
          {tabsLocale.noise}
        </Heading>
        <Field as={Input} label={fieldsNoiseLocale.noiseMin} name="noiseMin" value={values.noiseMin} error={errors.noiseMin} />
        <Field
          as={DateInput}
          name="noiseMinDate"
          label={fieldsNoiseLocale.noiseMinDate}
          value={values.noiseMinDate !== values.date ? values.noiseMinDate : null}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setFieldValue('noiseMinDate', createDateToRequest(e.target.value))
          }
          showTimeInput
          error={errors.noiseMinDate}
        />

        <Field
          as={Input}
          label={fieldsNoiseLocale.noiseMax}
          name="noiseMax"
          value={values.noiseMax}
          isRequired
          error={errors.noiseMax}
        />
        <Field
          as={DateInput}
          name="noiseMaxDate"
          label={fieldsNoiseLocale.noiseMaxDate}
          value={values.noiseMaxDate !== values.date ? values.noiseMaxDate : null}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setFieldValue('noiseMaxDate', createDateToRequest(e.target.value))
          }
          showTimeInput
          error={errors.noiseMaxDate}
        />

        <Field
          as={Input}
          label={fieldsNoiseLocale.noiseEquivalent}
          name="noiseEquivalent"
          value={values.noiseEquivalent}
          isRequired
          error={errors.noiseEquivalent}
        />
      </Grid>
    </FormWrapper>
  );
};
