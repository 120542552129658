import { useFormikContext } from 'formik';
import { FieldsFormControls, FormHeader, LoaderItem } from 'components/common';
import { FieldFormProps } from 'types/forms';
import React from 'react';
import NSIWaterReservoirsGeneralInformationTab from 'pages/dictionaries/NSIWater/NSIWaterReservoirs/NSIWaterReservoirsForms/NSIWaterReservoirsGeneralInformationTab';
import { NSIWaterItem } from 'pages/dictionaries/NSIWater/types';
import { Box } from '@chakra-ui/react';

const NSIWaterReservoirsFormFields = ({
  onCancel,
  dictionaries,
  isLoading,
  isEditMode,
  editedObject,
}: FieldFormProps) => {
  const { handleSubmit } = useFormikContext<NSIWaterItem>();

  const title = isEditMode ? 'Редактирование источника' : 'Добавление источника';

  if (isLoading) {
    return <LoaderItem />;
  }

  return (
    <Box flexDirection="column">
      <FormHeader onBackClick={onCancel} header={title} />
      <NSIWaterReservoirsGeneralInformationTab dictionaries={dictionaries} editedObject={editedObject}>
        <FieldsFormControls onCancel={onCancel} handleSubmit={handleSubmit} showSave />
      </NSIWaterReservoirsGeneralInformationTab>
    </Box>
  );
};

export default NSIWaterReservoirsFormFields;
