import React, { useMemo } from 'react';
import { FormikProvider, useFormik } from 'formik';
import { useParams } from 'react-router-dom';
import { createTouchedErrors } from 'features/create-touched-errors';
import { useRequest } from 'hooks/useRequest';
import { useAISWeatherRecord } from 'hooks/aisWeather/useAISWeatherRecord';
import FieldsForm from './FieldsForm';
import { AISWeatherRootPath, MeteoDataApiRoot } from 'pages/data/Meteo/menu';
import { aisWeatherSchema } from 'models/schemas/meteo/aisWeatherSchema';
import { AISTabs } from 'pages/data/Meteo/AISWeather/AISWeather/const';
import { getSelectOptionsFromDictionaryByName } from 'features/get-select-options-from-dictionary';
import { useGoBackWithFallback } from 'lib/utils/goBackWithFallback';
import { useDictionary } from 'hooks/useDictionary';
import { useEnhancedNotification } from 'hooks';

export function EditAISWeather() {
  const { backWithFallback } = useGoBackWithFallback();
  const { dictionaries, isDictionariesLoading } = useDictionary({ url: `${MeteoDataApiRoot}/dictionaries/5` });

  const { id } = useParams<{
    id: string;
  }>();
  const { onSuccessEdit, onErrorEdit } = useEnhancedNotification();

  const { result, isLoading } = useAISWeatherRecord(id);

  const onSuccess = () => {
    onSuccessEdit();
    backWithFallback(AISWeatherRootPath);
  };

  const { put, isLoading: isUpdatingRecord } = useRequest('/meteo/v10/ais', onSuccess, onErrorEdit);

  const stationsDict = useMemo(
    () => new Map(getSelectOptionsFromDictionaryByName(dictionaries?.data, 'stations').map((v) => [v.name, v.value])),
    [dictionaries],
  );

  const formattedResponse = useMemo(() => {
    if (!result || !dictionaries) return {};

    const stations = new Array(AISTabs.length);

    AISTabs.forEach((tabName, index) => {
      const stationId = stationsDict.get(tabName);
      stations[index] = result?.stations?.find((item) => item.stationId === stationId);
    });

    return {
      ...result,
      stations,
    };
  }, [dictionaries, result, stationsDict]);

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    validationSchema: aisWeatherSchema,
    initialValues: formattedResponse,
    onSubmit: (values) => put(values),
  });

  return (
    <FormikProvider
      value={{
        ...formik,
        errors: createTouchedErrors(formik.errors, formik.touched),
      }}
    >
      <FieldsForm
        isEdit
        dictionaries={dictionaries}
        onCancel={() => {
          backWithFallback(AISWeatherRootPath);
        }}
        isLoading={isDictionariesLoading || isLoading || isUpdatingRecord}
      />
    </FormikProvider>
  );
}
