import React from 'react';
import { Button } from 'components/common';
import { Box, Flex, Text } from '@chakra-ui/react';

export type FileItemProps = {
  name?: string;
  percentage?: number;
  handleRemove(): void;
};

const _FileItem: React.FC<FileItemProps> = ({ name = '', percentage = 0, handleRemove }) => (
  <Box width="100%">
    <Flex width="inherit" flexDirection="row" alignItems="center" justifyContent="space-between">
      <Box width="80%" fontSize={16} lineHeight="20px" alignItems="flex-start">
        <Text isTruncated width="inherit" whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
          {name}
        </Text>
      </Box>
      <Button variant="link" colorScheme="ErrorButton" onClick={handleRemove} p={2} hidden={!name}>
        Удалить
      </Button>
    </Flex>
    <Box borderRadius={3} width="100%" height={2} margin="7px 0 4px 0" color="menu.hover">
      <Box
        width={`${percentage}%`}
        height={2}
        borderRadius={3}
        transition="width 500ms ease-in-out"
        backgroundColor="menu.active"
      />
    </Box>
  </Box>
);

export const FileItem = React.memo(_FileItem);
