import { useParams } from 'react-router';
import { editUnitApiPath, IMeasureUnitItem, UnitRootPath, useGetItem } from '../consts';
import { UnitFields } from './UnitFields';
import { createTouchedErrors } from 'features/create-touched-errors';
import { FormikProvider, useFormik } from 'formik';
import { ERROR_MESSAGES, SUCCESS_MESSAGES } from 'constants/messages';
import { UnitsSchema } from 'models';
import { useNotifications } from 'hooks/useNotifications';
import { useRequest } from 'hooks/useRequest';
import { LoaderItem } from 'components/common';
import { useGoBackWithFallback } from 'lib/utils/goBackWithFallback';

const initialValues = {
  id: '',
  name: '',
  shortName: '',
  code: '',
  localName: '',
  localCode: '',
  intCode: '',
  intName: '',
  errors: [],
};

export const EditUnit = () => {
  const { backWithFallback } = useGoBackWithFallback();
  const { id } = useParams<{
    id: string;
  }>();
  const { result, isLoading } = useGetItem(id);

  const { successNotify, errorNotify } = useNotifications();

  const onSuccess = () => {
    successNotify({
      key: 'file/edit/success',
      message: SUCCESS_MESSAGES.EDIT,
    });
    backWithFallback(UnitRootPath);
  };

  const onError = () => {
    errorNotify({
      key: 'file/edit/error',
      message: ERROR_MESSAGES.EDIT,
    });
  };

  const { put } = useRequest<IMeasureUnitItem, any>(editUnitApiPath, onSuccess, onError);

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    validationSchema: UnitsSchema,
    initialValues: { ...initialValues, ...result },
    onSubmit: (values) => {
      put(values);
    },
  });

  if (isLoading) {
    return (
      <LoaderItem />
    );
  }
  return (
    <FormikProvider
      value={{
        ...formik,
        errors: createTouchedErrors(formik.errors, formik.touched),
      }}
    >
      <UnitFields isEditMode />
    </FormikProvider>
  );
};
