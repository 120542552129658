import type { ChangeEvent } from 'react';
import { Field, useFormikContext } from 'formik';

import { Button, DateInput, FormWrapper, Input } from 'components/common';

import { createDateToRequestWithTimezone as createDateToRequest } from 'lib/create-date';

import { COMMON_LOCALE } from 'constants/common';
import { fieldsMeteoLocale, IMeteoPackageForm, tabsLocale } from 'pages/data/MASKSH/consts';
import { Box, Grid, Heading } from '@chakra-ui/react';
import { useGoBackWithFallback } from 'lib/utils/goBackWithFallback';
import { useEnhancedNotification } from 'hooks';

interface IProps {
  listPath?: string;
  isLoading: boolean;
}

export const MeteoPackageFormIndicatorsTab = ({ listPath, isLoading }: IProps) => {
  const { backWithFallback } = useGoBackWithFallback();
  const { setFieldValue, values, submitForm, errors } = useFormikContext<IMeteoPackageForm>();
  const { onValidationError } = useEnhancedNotification();

  const onSubmit = () => {
    if (Object.keys(errors).length > 0) {
      onValidationError();
    }
    submitForm();
  };

  return (
    <FormWrapper>
      <Grid gap={2}>
        <Heading mb={2} size="md">
          {tabsLocale.indicators}
        </Heading>
        <Heading mb={2} size="sm">
          Температура
        </Heading>
        <Field
          as={Input}
          label={fieldsMeteoLocale.temperatureAvg}
          name="temperatureAvg"
          value={values.temperatureAvg}
          error={errors.temperatureAvg}
        />
        <Field
          as={Input}
          label={fieldsMeteoLocale.temperatureMin}
          name="temperatureMin"
          value={values.temperatureMin}
          error={errors.temperatureMin}
        />
        <Field
          as={DateInput}
          name="temperatureMinDate"
          label={fieldsMeteoLocale.temperatureMinDate}
          value={values.temperatureMinDate !== values.date ? values.temperatureMinDate : null}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setFieldValue('temperatureMinDate', createDateToRequest(e.target.value))
          }
          showTimeInput
          error={errors.temperatureMinDate}
        />
        <Field
          as={Input}
          label={fieldsMeteoLocale.temperatureMax}
          name="temperatureMax"
          value={values.temperatureMax}
          error={errors.temperatureMax}
        />
        <Field
          as={DateInput}
          name="temperatureMaxDate"
          label={fieldsMeteoLocale.temperatureMaxDate}
          value={values.temperatureMaxDate !== values.date ? values.temperatureMaxDate : null}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setFieldValue('temperatureMaxDate', createDateToRequest(e.target.value))
          }
          showTimeInput
          error={errors.temperatureMaxDate}
        />

        <Field as={Input} label={fieldsMeteoLocale.airPressure} name="airPressure" value={values.airPressure} error={errors.airPressure} />
        <Field as={Input} label={fieldsMeteoLocale.rainfall} name="rainfall" value={values.rainfall} error={errors.rainfall} />
        <Heading mt={2} mb={2} size="sm">
          Влажность
        </Heading>
        <Field as={Input} label={fieldsMeteoLocale.humidityAvg} name="humidityAvg" value={values.humidityAvg} error={errors.humidityAvg} />
        <Field as={Input} label={fieldsMeteoLocale.humidityMax} name="humidityMax" value={values.humidityMax} error={errors.humidityMax} />
        <Field
          as={DateInput}
          name="humidityMaxDate"
          label={fieldsMeteoLocale.humidityMaxDate}
          value={values.humidityMaxDate !== values.date ? values.humidityMaxDate : null}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setFieldValue('humidityMaxDate', createDateToRequest(e.target.value))
          }
          showTimeInput
          error={errors.humidityMaxDate}
        />

        <Field as={Input} label={fieldsMeteoLocale.humidityMin} name="humidityMin" value={values.humidityMin} error={errors.humidityMin} />
        <Field
          as={DateInput}
          name="humidityMinDate"
          label={fieldsMeteoLocale.humidityMinDate}
          value={values.humidityMinDate !== values.date ? values.humidityMinDate : null}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setFieldValue('humidityMinDate', createDateToRequest(e.target.value))
          }
          showTimeInput
          error={errors.humidityMinDate}
        />

        <Heading mt={2} mb={2} size="sm">
          Направление ветра
        </Heading>
        <Field as={Input} label={fieldsMeteoLocale.windDirection} name="windDirection" value={values.windDirection} error={errors.windDirection} />
        <Field as={Input} label={fieldsMeteoLocale.windSpeedAvg} name="windSpeedAvg" value={values.windSpeedAvg} error={errors.windSpeedAvg} />
        <Field as={Input} label={fieldsMeteoLocale.windSpeedMax} name="windSpeedMax" value={values.windSpeedMax} error={errors.windSpeedMax} />
        <Field
          as={DateInput}
          name="windSpeedMaxDate"
          label={fieldsMeteoLocale.windSpeedMaxDate}
          value={values.windSpeedMaxDate !== values.date ? values.windSpeedMaxDate : null}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setFieldValue('windSpeedMaxDate', createDateToRequest(e.target.value))
          }
          showTimeInput
          error={errors.windSpeedMaxDate}
        />

        <Field as={Input} label={fieldsMeteoLocale.windSpeedMin} name="windSpeedMin" value={values.windSpeedMin} error={errors.windSpeedMin} />
        <Field
          as={DateInput}
          name="windSpeedMinDate"
          label={fieldsMeteoLocale.windSpeedMinDate}
          value={values.windSpeedMinDate !== values.date ? values.windSpeedMinDate : null}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setFieldValue('windSpeedMinDate', createDateToRequest(e.target.value))
          }
          showTimeInput
          error={errors.windSpeedMinDate}
        />

        <Box mt={3}>
          <Button marginRight={20} onClick={() => backWithFallback(listPath)} variant="solid">
            {COMMON_LOCALE.cancel}
          </Button>
          <Button isDisabled={isLoading} onClick={onSubmit} variant="solid">
            {COMMON_LOCALE.save}
          </Button>
        </Box>
      </Grid>
    </FormWrapper>
  );
};
