import { useCallback } from 'react';
import { useFormikContext } from 'formik';
import { AttachmentsTabContent, FormWrapper, LoaderItem, TabListHeader } from 'components/common';
import { Grid, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { SurfaceWaterParametersTab } from 'pages/data/Water/SurfaceWater/Forms/Tabs/SurfaceWaterParametersTab';
import { CommonTab } from './Tabs/CommonTab';
import { COMMON_LOCALE } from 'constants/common';

import { WaterRouteSurfaceWatersRoot } from '../../routes';
import { DictionariesItem, TDictionaryItemDictionary } from 'models';
import { IUploadMedia } from 'components/common/FileUploadDragAndDrop/types';
import FormControls from 'components/common/FormControls';
import { UploadObjectType } from 'api/services/media';
import { useEnhancedNotification } from 'hooks';
import { useGoBackWithFallback } from 'lib/utils/goBackWithFallback';

interface IProps {
  isLoading: boolean;
  sources: TDictionaryItemDictionary[];
  waterObjects: TDictionaryItemDictionary[];
  organizations: TDictionaryItemDictionary[];
  isEditMode?: boolean;
  isCopyMode?: boolean;
  objectType: UploadObjectType | null;
  onMediaChange: (media: IUploadMedia[]) => void;
  media: IUploadMedia[];
  uploadItemId?: string;
  parameters?: TDictionaryItemDictionary[];
}

const tabsNames = [
  COMMON_LOCALE.commonData,
  COMMON_LOCALE.indicators,
  COMMON_LOCALE.attachments
];

export const SurfaceWaterFormFields = ({
  isLoading,
  sources,
  waterObjects,
  organizations,
  isEditMode,
  isCopyMode,
  objectType,
  media,
  uploadItemId,
  onMediaChange,
  parameters,
}: IProps) => {
  const { submitForm, handleSubmit, errors } = useFormikContext<any>();
  const { backWithFallback } = useGoBackWithFallback();
  const { onValidationError } = useEnhancedNotification();

  const handleCancel = useCallback(() => {
    backWithFallback(`${WaterRouteSurfaceWatersRoot}/main-data`);
  }, [backWithFallback]);
  const header = `${isEditMode ? 'Редактирование' : 'Добавление'} результатов протокола`;

  const onFormSubmit = useCallback(() => {
    if (Object.keys(errors).length > 0) {
      onValidationError();
    }
    handleSubmit();
  }, [errors, handleSubmit, onValidationError]);

  if (isLoading) {
    return <LoaderItem />;
  }
  return (
    <Grid>
      <Tabs isLazy>
        <TabListHeader onBackClick={handleCancel} header={header} tabNames={tabsNames} />
        <TabPanels>
          <TabPanel>
            <CommonTab
              isEditMode={isEditMode}
              sources={sources}
              waterObjects={waterObjects}
              organizations={organizations as unknown as DictionariesItem}
            />
          </TabPanel>
          <TabPanel>
            <SurfaceWaterParametersTab
              parameters={parameters}
              isCopyMode={isCopyMode}
              isLoading={isLoading}
              handleCancel={handleCancel}
              submitForm={submitForm}
              isEditMode={isEditMode}
            />
          </TabPanel>
          <TabPanel>
            <FormWrapper>
              <AttachmentsTabContent
                objectType={objectType}
                media={media}
                onMediaChange={onMediaChange}
                uploadItemId={uploadItemId}
              >
                <FormControls onCancel={handleCancel} handleSubmit={onFormSubmit} submitIsDisabled={false} />
              </AttachmentsTabContent>
            </FormWrapper>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Grid>
  );
};
