import { MASKSHMeteo, MASKSHNoise, MASKSHRoot, MASKSHTechnical } from 'pages/data/MASKSH/consts';
import appLocale from 'constants/appLocale';

export const MASKSHNavigationTabs = [
  {
    label: 'Звуковые параметры',
    path: MASKSHNoise,
  },
  {
    label: 'Метеоданные',
    path: MASKSHMeteo,
  },
  {
    label: 'Технические данные',
    path: MASKSHTechnical,
  },
];

export const noiseBreadcrumbs = [
  { breadcrumb: appLocale.common.data, path: '/data' },
  {
    breadcrumb: appLocale.subsystems.masksh,
    path: MASKSHRoot,
  },
  {
    breadcrumb: 'Данные мониторинга',
    path: MASKSHNoise,
  },
];

export const meteoBreadcrumbs = [
  { breadcrumb: appLocale.common.data, path: '/data' },
  {
    breadcrumb: appLocale.subsystems.masksh,
    path: MASKSHRoot,
  },
  {
    breadcrumb: 'Данные мониторинга',
    path: MASKSHMeteo,
  },
];
