import { useCallback, useEffect, useState } from 'react';

import { useRequest } from 'hooks/useRequest';
import { useNotifications } from 'hooks/useNotifications';
import { IChartData } from '../../types';

import { apiPaths } from '../../apiPaths';
import { ERROR_MESSAGES } from 'constants/messages';
import { modifyDataFromServer } from '../helpers';
import { IGanttChartType, IServerDataGantt } from '../types';

// TODO: delete later
// const day = (num: number) => num * 86400000;

// const mockDataFromServerDevice: IServerDataGantt = {
//   meta: {
//     id: 'erger',
//     name: 'Мерялка',
//   },
//   dataset: [
//     {
//       station: 'Бесединский мост', // тут можно кидать объект с id и name
//       status: 'use', // любой id, по которому станет понятно, какой статус
//       data: [day(0), day(3)], // timestamp начала и timestamp конца
//     },
//     {
//       station: 'Склад', // тут можно кидать объект с id и name
//       status: 'reserve', // любой id, по которому станет понятно, какой статус
//       data: [day(3), day(4)], // timestamp начала и timestamp конца
//     },
//     {
//       station: 'Марьино', // тут можно кидать объект с id и name
//       status: 'verification', // любой id, по которому станет понятно, какой статус
//       data: [day(4), day(50)], // timestamp начала и timestamp конца
//     },
//     {
//       station: 'Марьино', // тут можно кидать объект с id и name
//       status: 'reserve', // любой id, по которому станет понятно, какой статус
//       data: [day(50), day(55)], // timestamp начала и timestamp конца
//     },
//     {
//       station: 'Бесединский мост', // тут можно кидать объект с id и name
//       status: 'use', // любой id, по которому станет понятно, какой статус
//       data: [day(55), day(80)], // timestamp начала и timestamp конца
//     },
//   ],
// };
//
// const mockDataFromServerStation: IServerDataGantt = {
//   meta: {
//     id: 'idOfStation',
//     name: 'Станция1',
//   },
//   dataset: [
//     {
//       device: 'device1',
//       status: 'use',
//       data: [day(0), day(3)],
//     },
//     {
//       device: 'device3',
//       status: 'reserve',
//       data: [day(3), day(4)],
//     },
//     {
//       device: 'device2',
//       status: 'verification',
//       data: [day(4), day(50)],
//     },
//     {
//       device: 'device3',
//       status: 'reserve',
//       data: [day(50), day(55)],
//     },
//     {
//       device: 'device1',
//       status: 'use',
//       data: [day(55), day(80)],
//     },
//   ],
// };

interface IUseDataForChartGanttDevices {
  type: IGanttChartType;
  body: any;
}

export function useDataForChartGanttDevices({ type, body }: IUseDataForChartGanttDevices) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<IChartData | null>(null);

  const path = type === 'device' ? apiPaths.device.oneById : apiPaths.station.oneById;
  const { errorNotify } = useNotifications();
  const { post, reset, error, isLoading: isLoadingRequest, result } = useRequest<IServerDataGantt[]>(path);

  useEffect(() => {
    if (error) {
      setData(null);
      reset();
    }
  }, [error, reset, setData]);

  const loadData = useCallback(() => {
    if (!body) return;
    // HACK: temp error fix
    setData(null);
    const getData = async () => {
      const newData = await post(body);
      // @ts-ignore
      return modifyDataFromServer(newData[0], type);
    };

    (async () => {
      try {
        setIsLoading(true);
        const data = await getData();
        // @ts-ignore
        setData(data);
      } catch (e) {
        setData(null);
        const message = e instanceof Error ? e.message : ERROR_MESSAGES.GET_DATA;
        errorNotify({
          message,
          key: 'ewio01',
        });
      } finally {
        setIsLoading(false);
      }
    })();
  }, [body, errorNotify, post, type]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  return { data, setData, isLoading: isLoadingRequest || isLoading, path, initialData: result, refetch: loadData };
}
