import { Link } from 'react-router-dom';
import { Box, Flex, Link as ExternalLink } from '@chakra-ui/react';
import { useJournalLog } from 'lib/utils/journalLog/useJournalLog';

interface RoutesListProps {
  routes: Array<{ path: string; breadcrumb: string; isExternal?: boolean }>;
}

export const RoutesList: React.FC<RoutesListProps> = ({ routes }) => {
  const { logExternalUrl } = useJournalLog();

  return (
    <Flex padding={20} flexDirection="column">
      {routes.map(({ path, breadcrumb, isExternal }) => {
        if (!isExternal) {
          return (
            <Box as={Link} marginTop={10} color="text.primary" key={path} to={path}>
              {breadcrumb}
            </Box>
          );
        }
        return (
          <ExternalLink
            marginTop={10}
            href={path}
            isExternal
            onClick={() => logExternalUrl(path, breadcrumb)}
            color="#208CFF"
          >
            {breadcrumb}
          </ExternalLink>
        );
      })}
    </Flex>
  );
};
