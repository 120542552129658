export type NSIWaterDictionaryKeys =
  | 'types'
  | 'statuses'
  | 'rayons'
  | 'okrugs'
  | 'waterObjectKinds'
  | 'waterObjectTypes'
  | 'waterObjectsControl'
  | 'waterAreasOopt'
  | 'waterSvoriTypes'
  | 'waterAreas'
  | 'waterObjects'
  | 'waterStvori'
  | 'waterObjectsBasin'
  | 'waterStvoriSubsystems'
  | 'waterAreasSubsystems';
export enum NSIWaterItemType {
  area = 'area',
  reservoir = 'reservoir',
  stvor = 'stvor',
}

export interface NSIWaterItem {
  subModul: string;
  // ?

  id: string;
  // Идентификатор источника данных

  type: NSIWaterItemType;
  // Что за тип источника - участок, створ, водоем или источник измерений

  name: string;
  // Наименование

  shortName: string;
  // Краткое наименование

  number: number | string;
  // Номер

  orderNumber: number | string;
  // Порядковый номер на источнике

  okrugId: string | null;
  // Идентификатор округа

  rayonId: string | null;
  // Идентификатор района

  latitude: string | number;
  // Широта

  longitude: string | number;
  // Долгота

  address: string;
  // Адрес

  landmark: string;
  // Адресный ориентир

  status: string | null;
  // Статус

  latitudeStart: string | number;
  // Начало участка. Широта

  longitudeStart: string | number;
  // Начало участка. Долгота

  latitudeEnd: string | number;
  // Окончание участка. Широта

  longitudeEnd: string | number;
  // Окончание участка. Долгота

  code: string;
  // Код участка

  areaSquare: number | string;
  // Площадь

  areaId: string | null;
  // Участок наблюдения

  waterId: string | null;
  // Водный объект

  oopt: string | null;
  // Особо охраняемая природная зона

  subsystemId: string | null;
  // Модуль подсистемы

  length: number;
  // Протяженность участка

  waterTypeId: string | null;
  // Тип водного объекта

  waterKindId: string | null;
  // Тип водоема

  waterBasinId: string | null;
  // Принадлежность к водному бассейну

  waterControlId: string | null;
  // Уровень государственного контроля

  stvorTypeId: string | null;
  // Тип створа

  deleted: boolean;
  // Признак того, что запись была удалена

  startDate: string | null;
  // Дата начала действия

  stopDate: string | null;
  // Дата завершения действия

  waterName: string | null;
  // Наименование водоема

  waterObjectCode?: string | null;
  // Код водного объекта

  uploadToUds: boolean;
}
