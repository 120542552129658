import { Flex, Grid } from '@chakra-ui/react';
import { Field, FormikProvider, useFormik } from 'formik';
import { createTouchedErrors } from 'features/create-touched-errors';
import { Button, Select, SelectOption } from 'components/common';
import React, { useCallback, useMemo } from 'react';
import useASKZAMeteoDictionaries from 'pages/analyticsReports/CombinedCharts/ASKZAMeteo/useASKZAMeteoDictionaries';
import PeriodSelect, {
  LineChartDateForm,
} from 'pages/analyticsReports/CombinedCharts/LineChartComponents/PeriodSelect';
import { SchemaForCustomFilter } from 'models/schemas/filtersDatepickersSchema/FiltersDatepickersSchema';

export interface IASKZAMeteoFilterForm extends LineChartDateForm {
  areaId: string | null;
  firstSourceId: string | null;
  firstParameterId: string | null;
  secondSourceId: string | null;
  secondParameterId: string | null;
  thirdSourceId: string | null;
  thirdParameterId: string | null;
}

const initialEmptyValues: IASKZAMeteoFilterForm = {
  dateFrom: null,
  dateTo: null,
  period: null,
  type: 'AVG',
  firstParameterId: null,
  firstSourceId: null,
  secondParameterId: null,
  secondSourceId: null,
  thirdSourceId: null,
  thirdParameterId: null,
  areaId: null,
};

interface IProps {
  onSubmit: (values: IASKZAMeteoFilterForm) => void;
  isGraphLoading: boolean;
}

const ASKZAMeteoFilter = ({ onSubmit, isGraphLoading }: IProps) => {
  const formik = useFormik<IASKZAMeteoFilterForm>({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    validationSchema: SchemaForCustomFilter([{ dateFromFieldName: 'dateFrom', dateToFieldName: 'dateTo' }]),
    initialValues: initialEmptyValues,
    onSubmit,
  });

  const { post, askzaDictionary, meteoDictionary, isDictionariesLoading } = useASKZAMeteoDictionaries({
    url: '/reporting/v10/graphs/askza-meteo/dictionaries',
  });

  const requestDictionaries = useCallback(() => {
    formik.setFieldValue('areaId', null);
    formik.setFieldValue('firstSourceId', null);
    formik.setFieldValue('firstParameterId', null);
    formik.setFieldValue('secondSourceId', null);
    formik.setFieldValue('secondParameterId', null);
    formik.setFieldValue('thirdSourceId', null);
    formik.setFieldValue('thirdParameterId', null);

    void post({
      type: formik.values.type,
      dateFrom: formik.values.dateFrom,
      dateTo: formik.values.dateTo,
    });
  }, [formik, post]);

  const askzaSourceOptions = useMemo<SelectOption[] | undefined>(
    () =>
      askzaDictionary?.map((source) => ({
        name: source.sourceName,
        value: source.sourceId,
      })),
    [askzaDictionary]
  );

  const askzaParameterOptions = useMemo<SelectOption[] | undefined>(
    () =>
      askzaDictionary
        ?.find((source) => source.sourceId === formik.values.thirdSourceId)
        ?.parameters.map((parameter) => ({
          name: parameter.parameterName,
          value: parameter.parameterId,
        })),
    [askzaDictionary, formik.values.thirdSourceId]
  );

  const meteoSourceOptions = useMemo<SelectOption[] | undefined>(
    () =>
      meteoDictionary?.map((source) => ({
        name: source.sourceName,
        value: source.sourceId,
      })),
    [meteoDictionary]
  );

  const meteoParameterOptionsFirst = useMemo<SelectOption[] | undefined>(
    () =>
      meteoDictionary
        ?.find((source) => source.sourceId === formik.values.firstSourceId)
        ?.parameters.map((parameter) => ({
          name: parameter.parameterName,
          value: parameter.parameterId,
        }))
        .filter((item) => item.value !== formik.values.secondParameterId),
    [formik.values.firstSourceId, formik.values.secondParameterId, meteoDictionary]
  );

  const meteoParameterOptionsSecond = useMemo<SelectOption[] | undefined>(
    () =>
      meteoDictionary
        ?.find((source) => source.sourceId === formik.values.secondSourceId)
        ?.parameters.map((parameter) => ({
          name: parameter.parameterName,
          value: parameter.parameterId,
        }))
        .filter((item) => item.value !== formik.values.firstParameterId),
    [formik.values.firstParameterId, formik.values.secondSourceId, meteoDictionary]
  );

  return (
    <Flex py={2} alignItems="center" width="100%">
      <FormikProvider
        value={{
          ...formik,
          errors: createTouchedErrors(formik.errors, formik.touched),
        }}
      >
        <Flex flexDirection="column" width="100%">
          <PeriodSelect
            requestDictionaries={requestDictionaries}
            isDictionariesLoading={isDictionariesLoading}
            errors={formik.errors}
          />

          {askzaDictionary && meteoDictionary && (
            <Flex mt={2} alignItems="center" width="100%">
              <Grid
                border="1px solid #000"
                p={2}
                backgroundColor="rgb(223, 237, 214)"
                gap="16px"
                templateColumns="repeat(2, 1fr)"
                mr={2}
              >
                <Field
                  as={Select}
                  name="firstSourceId"
                  label="Станция"
                  options={meteoSourceOptions}
                  isDisabled={isDictionariesLoading}
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                    formik.setFieldValue('firstSourceId', e.target.value);
                    formik.setFieldValue('firstParameterId', null);
                  }}
                />
                <Field
                  as={Select}
                  name="firstParameterId"
                  label="Параметр"
                  options={meteoParameterOptionsFirst}
                  isDisabled={!formik.values.firstSourceId}
                />
              </Grid>

              <Grid
                border="1px solid #000"
                p={2}
                backgroundColor="rgb(223, 237, 214)"
                gap="16px"
                templateColumns="repeat(2, 1fr)"
                mr={2}
              >
                <Field
                  as={Select}
                  name="secondSourceId"
                  label="Станция"
                  options={meteoSourceOptions}
                  isDisabled={isDictionariesLoading}
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                    formik.setFieldValue('secondSourceId', e.target.value);
                    formik.setFieldValue('secondParameterId', null);
                  }}
                />
                <Field
                  as={Select}
                  name="secondParameterId"
                  label="Параметр"
                  options={meteoParameterOptionsSecond}
                  isDisabled={!formik.values.secondSourceId}
                />
              </Grid>

              <Grid
                border="1px solid #000"
                p={2}
                backgroundColor="rgb(223, 237, 214)"
                gap="16px"
                templateColumns="repeat(2, 1fr)"
                mr={2}
              >
                <Field
                  as={Select}
                  name="thirdSourceId"
                  label="Станция"
                  options={askzaSourceOptions}
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                    formik.setFieldValue('thirdSourceId', e.target.value);
                    formik.setFieldValue('thirdParameterId', null);
                  }}
                  isDisabled={isDictionariesLoading}
                />
                <Field
                  as={Select}
                  name="thirdParameterId"
                  label="Параметр"
                  options={askzaParameterOptions}
                  isDisabled={!formik.values.thirdSourceId}
                />
              </Grid>

              <Button
                isDisabled={
                  !formik.values.secondParameterId || !formik.values.firstParameterId || !formik.values.thirdParameterId
                }
                ml="auto"
                onClick={formik.submitForm}
                variant="solid"
                isLoading={isDictionariesLoading || isGraphLoading}
                flexShrink={0}
              >
                Построить график
              </Button>
            </Flex>
          )}
        </Flex>
      </FormikProvider>
    </Flex>
  );
};

export default ASKZAMeteoFilter;
