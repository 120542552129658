import { MaterialTable } from 'features/material-table/MaterialTableMini';
import { useUploadFileProvider } from 'features/UploadedFileProvider';
import { Column } from '@material-table/core';
import { useMemo } from 'react';
import { MeteoDataAskzaMeasure } from './types';

interface MeteoDataAskzaPreviewProps {
  typeId: string;
}

const MeteoDataAskzaPreview: React.FC<MeteoDataAskzaPreviewProps> = () => {
  const { preview } = useUploadFileProvider<MeteoDataAskzaMeasure>();

  const columns = useMemo<Column<MeteoDataAskzaMeasure>[]>(
    () => [
      {
        title: 'Дата и время',
        field: 'date',
        render: (row) => row.date,
      },
      { title: 'min', field: 'min.value' },
      { title: 'max', field: 'max.value' },
      { title: 'avg', field: 'avg.value' },
      { title: 'Параметр', field: 'parameter.value' },
      { title: 'Станция', field: 'station.value' },
    ],
    [],
  );

  return (
    <MaterialTable
      data={preview?.result?.measures}
      columns={columns}
    />
  );
};

export { MeteoDataAskzaPreview };
