import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { createTouchedErrors } from 'features/create-touched-errors';
import { FormikProvider, useFormik } from 'formik';
import { useRequest } from 'hooks/useRequest';
import { useLocation, useParams } from 'react-router-dom';
import { ReagentsWashoutRootPath } from 'pages/data/PGR';
import { WashoutSchema } from 'models/schemas/pgr';
import { initialEmptyValues } from './AddDeicingWashoutReport';
import FieldsForm from './FieldsForm';
import { IUploadMedia } from 'components/common/FileUploadDragAndDrop/types';
import { updateFiles } from 'api/services/media';
import { useObjectType } from 'hooks/useObjectType';
import { WashoutDeicingItem } from 'pages/data/PGR/GreenPlants/Washout/useWashoutDicingList';
import { EditFormProps } from 'types/forms';
import { getSelectRichOptionsFromDictionaryByName } from 'features/get-select-options-from-dictionary';
import { splitTypesAndValues } from 'lib/utils/forms/valueTypeHelper';
import { useGoBackWithFallback } from 'lib/utils/goBackWithFallback';
import { useDictionary } from 'hooks/useDictionary';
import { useEnhancedNotification } from 'hooks';

export const EditDeicingWashoutReport = ({ listPath = ReagentsWashoutRootPath }: EditFormProps) => {
  const { backWithFallback } = useGoBackWithFallback();
  const { id } = useParams<{ id: string }>();
  const { isDictionariesLoading, dictionaries } = useDictionary({ url: '/deicing/v10/deicing/washout/dictionaries' });
  const { onSuccessEdit, onErrorEdit } = useEnhancedNotification();
  const location = useLocation();
  const [media, setMedia] = useState<IUploadMedia[]>([]);
  const objectType = useObjectType(dictionaries);
  const [editedObject, setEditObject] = useState<WashoutDeicingItem>();

  const onItemLoad = useCallback((response: WashoutDeicingItem | null) => {
    if (response) {
      setEditObject(response);
    }
  }, []);

  const { get, isLoading: isItemLoading } = useRequest<WashoutDeicingItem>(
    `/deicing/v10/deicing/washout/${id}`,
    onItemLoad,
  );

  useEffect(() => {
    if (location.state) {
      setEditObject(location.state);
    } else {
      void get();
    }
  }, [get, location.state]);

  const onSuccess = () => {
    onSuccessEdit();

    updateFiles(id, objectType, media)
      .then(() => {
        backWithFallback(`${listPath}/main-data`);
      })
      .catch((error: Error) => {
        onErrorEdit(error.message);
      });
  };

  const { put, isLoading } = useRequest<WashoutDeicingItem, any>(
    '/deicing/v10/deicing/washout',
    onSuccess,
    onErrorEdit,
  );
  const areas = getSelectRichOptionsFromDictionaryByName(dictionaries?.data, 'areas');
  const regions = getSelectRichOptionsFromDictionaryByName(dictionaries?.data, 'regions');

  const initialValues = useMemo(() => {
    const areaItem = areas.find((item) => item.value === editedObject?.areaId);
    const region = areaItem?.parent || '';
    const district = regions.find((item) => item.value === region)?.parent || '';

    return {
      ...initialEmptyValues,
      ...editedObject,
      region,
      district,
    };
  }, [areas, regions, editedObject]);

  const onSubmit = useCallback(
    (values: typeof initialValues) => {
      put({
        ...values,
        parameters: splitTypesAndValues(values.parameters),
      });
    },
    [put],
  );

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    initialValues,
    onSubmit,
    validationSchema: WashoutSchema,
  });

  return (
    <FormikProvider
      value={{
        ...formik,
        errors: createTouchedErrors(formik.errors, formik.touched),
      }}
    >
      <FieldsForm
        dictionaries={dictionaries}
        onCancel={() => {
          backWithFallback(`${listPath}/main-data`);
        }}
        isLoading={isDictionariesLoading || isItemLoading || isLoading}
        uploadItemId={id}
        onMediaChange={setMedia}
        media={media}
      />
    </FormikProvider>
  );
};
