import { IManagerPeriod } from './types';

export const periods: IManagerPeriod[] = ['now', 'today', 'week', 'month', 'year'];
export const graphPeriods: IManagerPeriod[] = ['today', 'week', 'month'];

export const periodLocale: Record<IManagerPeriod, string> = {
    now: 'Сейчас',
    today: 'Сегодня',
    week: 'Неделя',
    month: 'Месяц',
    year: 'Год',
    custom: 'Произвольный',
};

export const airSubtitles: Partial<Record<IManagerPeriod, string[]>> = {
    now: ['Максимальное значение измерения'],
    today: ['Максимальное значение измерения за последние сутки', 'Максимальное среднесуточное значение измерения за последние сутки'],
    week: ['Максимальное из среднесуточных значений измерений за последнюю неделю', 'Максимальное среднее значение измерений за последнюю неделю'],
    month: ['Максимальное из среднесуточных значений измерений за последний месяц', 'Максимальное среднее значение измерений за последний месяц'],
    year: ['Максимальное среднее значение измерений за последний год'],
};

export const meteoSubtitles: Partial<Record<IManagerPeriod, string[]>> = {
    now: ['Актуальные метеоданные']
};

export const waterSubtitles: Partial<Record<IManagerPeriod, string[]>> = {
    year: ['Максимальное среднее значение измерений за последний год']
};

export const airGraphSubtitles: Partial<Record<IManagerPeriod, string[]>> = {
    today: ['ИЗА ((CO/ПДКмр+ NO/ПДКмр + NO2/ПДКмр + РМ10/ПДКмр + РМ2,5/ПДКмр) / 5)'],
    week: ['ИЗА средний за сутки ((CO/ПДКсс + NO/ПДКсс + NO2/ПДКсс + РМ10/ПДКсс + РМ2,5/ПДКсс) / 5)'],
    month: ['ИЗА средний за сутки ((CO/ПДКсс + NO/ПДКсс + NO2/ПДКсс + РМ10/ПДКсс + РМ2,5/ПДКсс) / 5)'],
};
