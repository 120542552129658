import { Button } from 'components/common';
import { FilterPresetValue, getStartEndDatesByPreset } from './getStartEndDatesByPreset';
import { Grid } from '@chakra-ui/react';

interface Option {
  presetValue: FilterPresetValue;
  label: string;
}

export interface PresetFieldsProps {
  presetOptions: Option[];
  setFieldValue: (target: string, value: string) => void;
}

export const PresetsFields = ({ presetOptions, setFieldValue }: PresetFieldsProps) => (
  <Grid gridTemplateColumns={`repeat(${presetOptions.length}, 150px)`} columnGap="20px">
    {presetOptions.map(({ presetValue, label }) => (
      <Button
        size="sm"
        key={presetValue}
        onClick={() => {
          const { startDate, endDate } = getStartEndDatesByPreset(presetValue);

          setFieldValue('dateFrom', startDate);
          setFieldValue('dateTo', endDate);
        }}
      >
        {label}
      </Button>
    ))}
  </Grid>
);
