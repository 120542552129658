import React, { useEffect } from 'react';
import { createTouchedErrors } from 'features/create-touched-errors';
import { FormikProvider, useFormik } from 'formik';
import { useHistory, useParams } from 'react-router-dom';
import { FieldsForm } from './FieldsForm';
import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import appLocale from 'constants/appLocale';
import { configError, FTPRootPath, FTPSettings, ServersConfig } from 'pages/admin/FTP';
import { useRequest } from 'hooks/useRequest';
import { baseAdminUrl } from 'pages/admin';
import { FormHeader, LoaderItem } from 'components/common';
import { Box } from '@chakra-ui/react';
import { useNotifications } from 'hooks/useNotifications';
import { ERROR_MESSAGES, SUCCESS_MESSAGES } from 'constants/messages';
import { ServerSchema } from 'models/schemas/admin/ftpSettings';
import { initialEmptyValues } from './AddServer';

export const EditServerForm = () => {
  const history = useHistory();
  const { successNotify, errorNotify } = useNotifications();
  const { id } = useParams<{
    id: string;
  }>();
  const { get, result: serverValues, isLoading } = useRequest<ServersConfig>(`${baseAdminUrl}/ftp-config/${id}`);

  useEffect(() => {
    if (id) {
      get();
    }
  }, [get, id]);

  useUpdateBreadcrumbs([
    {
      breadcrumb: appLocale.admin.main,
      path: '/admin',
    },
    {
      breadcrumb: appLocale.admin.ftp,
      path: FTPRootPath,
    },
    {
      breadcrumb: appLocale.admin.ftpSettings,
      path: `${FTPSettings}/servers`,
    },
  ]);

  const onSuccess = () => {
    history.push(`${FTPSettings}/servers`);
    successNotify({
      key: 'file/edit/success',
      message: SUCCESS_MESSAGES.EDIT,
    });
  };

  const onError = (error: any) => {
    /* Заглушка под определенную ошибку */
    if (error.descriptions[0].message === configError) {
      onSuccess();
      return;
    }
    errorNotify({
      key: 'file/edit/error',
      message: ERROR_MESSAGES.EDIT,
    });
  };

  const { put } = useRequest<ServersConfig, any>(`${baseAdminUrl}/ftp-config`, onSuccess, onError);

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    validationSchema: ServerSchema,
    initialValues: { ...initialEmptyValues, ...serverValues },
    onSubmit: (values) => {
      put(values);
    },
  });

  if (isLoading) {
    return <LoaderItem />;
  }

  return (
    <FormikProvider
      value={{
        ...formik,
        errors: createTouchedErrors(formik.errors, formik.touched),
      }}
    >
      <Box>
        <FormHeader url={`${FTPSettings}/servers`} header="Редактирование сервера" />
        <FieldsForm
          onCancel={() => {
            history.push(`${FTPSettings}/servers`);
          }}
        />
      </Box>
    </FormikProvider>
  );
};
