import { Field, FormikProvider, useFormik } from 'formik';

import { Checkbox, DateInput, IFilterValues, Input, Select, useSetFiltersFromGraphic } from 'components/common';
import { Flex, Grid } from '@chakra-ui/react';
import { createDateFromResponse, createDateToRequestWithTimezone } from 'lib/create-date';
import { COMMON_LOCALE } from 'constants/common';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { IPeriod } from 'features/PeriodsButtons/types';

import { PeriodsButtons } from 'features/PeriodsButtons';
import { usePeriods } from 'features/PeriodsButtons/usePeriods';
import combineFilters from 'lib/utils/combineFilters';
import { IFilterItem, TDictionaryItemDictionary, TFiltersArray } from 'models';
import { MomentInput } from 'moment';
import { FiltersDatepickersSchema } from 'models/schemas/filtersDatepickersSchema/FiltersDatepickersSchema';
import FilterFormControls from 'components/form/FilterFormControls';
import { askzvLocales } from 'pages/data/Water/ASKZV/locales';

interface FilterProps {
  isLoading?: boolean;
  isDisabled?: boolean;
  parameters: TDictionaryItemDictionary[] | undefined;
  sources: TDictionaryItemDictionary[] | undefined;
  onSubmit: (values: any) => void;
  periods: IPeriod[] | null;
  filters?: TFiltersArray;
  filtersFromGraphic?: Partial<IFilterValues> | null;
  setFiltersFromGraphic?: (value: Partial<IFilterValues> | null) => void;
}

const initialEmptyValues: Record<string, IFilterItem<string>> = {
  dateTimeMin: {
    key: 'measuringDatetime',
    operation: 'GTE',
    value: null,
  },
  dateTimeMax: {
    key: 'measuringDatetime',
    operation: 'LTE',
    value: null,
  },
  version: {
    key: 'version',
    operation: 'EQ',
    value: '',
  },
  packageNumber: {
    key: 'packageNumber',
    operation: 'EQ',
    value: '',
  },
  stationId: {
    key: 'stationId',
    operation: 'EQ',
    value: null,
  },
  parameterId: {
    key: 'parameterId',
    operation: 'EQ',
    value: null,
  },
  value: {
    key: 'value',
    operation: 'EQ',
    value: '',
  },
  deleted: {
    key: 'deleted',
    operation: 'EQ',
    value: false,
  },
  periodId: {
    key: 'periodId',
    operation: 'EQ',
    value: null,
  },
};

export const WaterASKZVFilters: FC<FilterProps> = ({
  isLoading,
  isDisabled,
  onSubmit,
  parameters,
  sources,
  children,
  periods,
  filters = [],
  setFiltersFromGraphic,
  filtersFromGraphic,
}) => {
  const savedPeriodId = useMemo(
    () => (filters.find((item) => item.key === 'periodId')?.value as string) || '',
    [filters]
  );

  const [periodId, setPeriodId] = useState<string>(savedPeriodId);

  const { getDatesFromPeriod, dates } = usePeriods(periodId, periods);

  const submitPeriod = (id: string) => {
    const dates = getDatesFromPeriod(id);
    setFieldValue('periodId.value', id);
    setFieldValue('dateTimeMin.value', createDateToRequestWithTimezone(dates[0]));
    setFieldValue('dateTimeMax.value', createDateToRequestWithTimezone(dates[1]));
    setFieldTouched('dateTimeMin.value');
    setPeriodId(id);
  };

  const { values, errors, setValues, handleReset, submitForm, setFieldValue, setFieldTouched, ...formik } = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    validationSchema: FiltersDatepickersSchema([{ dateFromFieldName: 'dateTimeMin', dateToFieldName: 'dateTimeMax' }]),
    initialValues: initialEmptyValues,
    onSubmit,
  });

  useSetFiltersFromGraphic({
    dateKeyMin: 'dateTimeMin.value',
    dateKeyMax: 'dateTimeMax.value',
    setFiltersFromGraphic,
    filtersFromGraphic,
    setFieldValue,
    onChange: submitForm,
  });

  useEffect(() => {
    setValues(combineFilters(initialEmptyValues, filters));
  }, [filters, setValues]);

  const resetFilters = (e: any) => {
    handleReset(e);
    setPeriodId('');
    setTimeout(() => formik.handleSubmit(), 0);
  };

  return (
    <FormikProvider
      value={{
        values,
        errors,
        handleReset,
        submitForm,
        setFieldValue,
        setValues,
        setFieldTouched,
        ...formik,
      }}
    >
      <Grid>
        <Flex>
          <Grid width="100%" maxWidth="1200px" gap={5} templateColumns="repeat(2, 220px) 1fr 1fr">
            <Field
              as={DateInput}
              name="dateTimeMin.value"
              label={COMMON_LOCALE.from}
              value={createDateFromResponse(values.dateTimeMin.value as MomentInput)}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setFieldValue('dateTimeMin.value', createDateToRequestWithTimezone(e.target.value));
              }}
              showTimeInput
              error={errors.dateTimeMin?.value}
              maxDate={dates?.[1]}
              minDate={dates?.[0]}
              isDisabled={!periodId}
            />
            <Field
              as={DateInput}
              name="dateTimeMax.value"
              label={COMMON_LOCALE.to}
              value={createDateFromResponse(values.dateTimeMax.value as MomentInput)}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setFieldValue('dateTimeMax.value', createDateToRequestWithTimezone(e.target.value));
              }}
              showTimeInput
              error={errors.dateTimeMax?.value}
              maxDate={dates?.[1]}
              minDate={dates?.[0]}
              isDisabled={!periodId}
            />

            <Field
              as={Select}
              options={parameters}
              isDisabled={!parameters?.length}
              name="parameterId.value"
              label={askzvLocales.filters.parameter}
            />

            <Field
              as={Select}
              name="stationId.value"
              label={askzvLocales.filters.stationName}
              options={sources}
              isDisabled={!sources?.length}
            />

            <Field
              as={Select}
              name="stationId.value"
              label={askzvLocales.filters.stationNumber}
              options={sources?.map((st) => ({ ...st, name: st.parent }))}
              isDisabled={!sources?.length}
            />

            <Field as={Input} name="value.value" label={askzvLocales.filters.value} type="number" />

            <Field as={Checkbox} name="deleted.value" label="Показать удаленные" />

            {children}
          </Grid>

          <FilterFormControls
            onReset={resetFilters}
            onSubmit={submitForm}
            isDisabled={isDisabled}
            isLoading={isLoading}
          />
        </Flex>

        <PeriodsButtons periodId={periodId} periods={periods} submitPeriod={submitPeriod} />
      </Grid>
    </FormikProvider>
  );
};
