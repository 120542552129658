const SUCCESS_MESSAGES = {
  EDIT: 'Запись успешно отредактирована',
  CREATE: 'Запись успешно сохранена',
  CREATE_MULTIPLE: 'Запись(и) успешно сохранена(ы)',
  DELETE: 'Запись успешно удалена',
  DELETE_MULTIPLE: 'Запись(и) успешно удалена(ы)',
  GET: 'Данные успешно получены',
  BIND: 'Обращение успешно привязано',
  UNBIND: 'Обращение успешно отвязано',
  STATUS_INPROGRESS: 'Задание успешно передано в работу',
  STATUS_DONE: 'Задание успешно выполнено',
  COPY_TASK: 'Задание успешно скопировано',
  UPDATE_STATUS: 'Статус поверки успешно обновлен',
  PLANNED: 'Задание успешно запланировано',
  POINT_REGISTER: 'Номер успешно зарегистрирован',
  TEMPLATE: 'Шаблон успешно применен',
  RESTORE: 'Запись успешно восстановлена'
};

const ERROR_MESSAGES = {
  UNAUTHORIZED: 'Ошибка аутентификации',
  FORBIDDEN: 'Ошибка авторизации',
  EDIT: 'Ошибка при редактировании записи',
  CREATE: 'Ошибка при создании записи',
  DELETE: 'Ошибка при удалении записи',
  DELETE_A_FEW: 'Ошибка при удалении записей',
  GET: 'Ошибка при получении записи',
  GET_DATA: 'Ошибка при получении данных',
  NO_DATA: 'Нет данных по вашему запросу',
  UPLOAD: 'Ошибка при загрузке файла',
  UPLOAD_VALIDATE: 'Ошибка при валидации файла',
  LOGIN: 'Неверная пара Email/пароль. Проверьте данные и попробуйте еще раз',
  FILE_EXTENSION_NOT_ALLOWED: 'Нельзя загрузить файл такого типа',
  UNBIND: 'Ошибка при отвязке обращения',
  BIND: 'Ошибка при привязке обращения',
  STATUS_INPROGRESS: 'Ошибка при передаче задания в работу',
  STATUS_DONE: 'Ошибка при подтверждении выполнения',
  COPY_TASK: 'Ошибка при копировании задания',
  DOWNLOAD: 'Ошибка при скачивании файла',
  UPDATE_STATUS: 'Ошибка при обновлении статуса поверки',
  PLANNED: 'Ошибка при планировании задания',
  POINT_REGISTER: 'Ошибка при регистрации номера',
  TEMPLATE: 'Ошибка при применении шаблона',
  DEPENDENT_PARAMS_VALIDATION: 'Необходимо отфильтровать заполнить фильтр',
  DEPENDENT_PARAMS_GET: 'Ошибка при получении параметров',
  CALCULATE: 'Ошибка при обработке данных',
  DICTIONARY_ERROR: 'Ошибка. Невалидные справочные данные.',
  NOD_UPLOAD_LOG_ERROR: 'Отчет о выгрузке не найден',
  RESTORE: 'Ошибка при восстановлении записи',
  GET_DICT_ERROR: 'Ошибка при получении справочника'
};

const REMOVE_CONFIRMATION = 'Вы действительно хотите удалить запись?';
const REMOVE_A_FEW_CONFIRMATION = 'Вы действительно хотите удалить выбранные записи?';
const REMOVE_PACK_CONFIRMATION = 'Вы действительно хотите удалить запись? Будет удален весь пакет данных.';
const UNBIND_CONFIRMATION = 'Вы действительно хотите отвязать обращение?';
const BLOCK_CONFIRMATION = {
  ACTIVE: 'Вы действительно хотите разблокировать сервер?',
  BLOCKED: 'Вы действительно хотите заблокировать сервер?',
};

const BLOCK_SUCCESS = 'Сервер успешно заблокирован';
const BLOCK_ERROR = 'Ошибка при блокировке сервера';
const UNBLOCK_SUCCESS = 'Сервер успешно разблокирован';
const UNBLOCK_ERROR = 'Ошибка при разблокировке сервера';

export {
  SUCCESS_MESSAGES,
  ERROR_MESSAGES,
  REMOVE_CONFIRMATION,
  REMOVE_PACK_CONFIRMATION,
  BLOCK_CONFIRMATION,
  BLOCK_SUCCESS,
  BLOCK_ERROR,
  UNBLOCK_SUCCESS,
  UNBLOCK_ERROR,
  UNBIND_CONFIRMATION,
  REMOVE_A_FEW_CONFIRMATION
};
