import { Checkbox } from 'components/common';
import { ChangeEvent, useCallback } from 'react';

interface Props {
  onChange: (checked: boolean) => void;
  checked: boolean;
}

function TableSelectedFilter({ onChange, checked }: Props) {
  const _onChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.checked);
  }, [onChange]);

  return <Checkbox height={45} padding={0} checked={checked} onChange={_onChange} label="Только выделенные" />;
}

export default TableSelectedFilter;
