import { Autocomplete } from '@material-ui/lab';
import { Input } from './Input';
import { useFormikContext } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

interface IProps {
  value: string;
  name: string;
  label: string;
  options?: string[];
}

const useAutocompleteStyles = makeStyles({
  paper: {
    borderRadius: '4px',
    boxShadow: '0 2px 8px rgb(0 0 0 / 15%)',
    wordWrap: 'break-word',
    overflowY: 'scroll',
    overflowX: 'hidden',
    maxHeight: '300px',
  },
  option: {
    display: 'inherit',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontFamily: 'Proxima',
    fontWeight: 500,
    color: 'black',
    '&:onBlur': {
      backgroundColor: 'inherit',
    },
    '& li': {
      textOverflow: 'ellipsis',
    },
    '&[data-focus=true]': {
      backgroundColor: 'inherit',
    },
    '&:hover': {
      whiteSpace: 'inherit',
      overflow: 'inherit',
      textOverflow: 'inherit',
      backgroundColor: '#f2f8ff',
    },
    '&:active': {
      whiteSpace: 'inherit',
      overflow: 'inherit',
      textOverflow: 'inherit',
      backgroundColor: '#f2f8ff',
    },
    '&[aria-selected=true]': {
      backgroundColor: '#f2f8ff',
    },
  },
  listbox: {
    overflow: 'hidden',
  },
});

export const CustomAutocomplete = ({ value, name, label, options }: IProps) => {
  const { setFieldValue } = useFormikContext();
  const classes = useAutocompleteStyles();
  return (
    <>
      <Autocomplete
        options={options || []}
        freeSolo
        onChange={(e, value: string | null) => setFieldValue(name, value)}
        classes={classes}
        renderInput={(params) => (
          <div ref={params.InputProps.ref}>
            <Input
              {...params.inputProps}
              type="text"
              value={value}
              name={name}
              label={label}
              paddingRight={8}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFieldValue(name, e.target.value)}
            />
          </div>
        )}
      />
    </>
  );
};
