import {
  IReportProtocolForm,
  IReportProtocolFormValue,
  ReportProtocolReqDto,
  TypeEnum,
} from 'pages/analytics/Raids/types';
import { initialEmptyValues } from 'pages/analytics/Raids/initialEmptyValues';

export const formatFormForRequest = (values: IReportProtocolForm): ReportProtocolReqDto => {
  const { parameters, ...rest } = values;

  return { ...rest, values: formatParameters(values) };
};

const formatParameters = (v: IReportProtocolForm): ReportProtocolReqDto['values'] => {
  const parametersIds = v.parameters.map((parameter) => parameter.parameterId);
  const values = [...Object.values(v.values).filter((value) => !parametersIds.includes(value.parameterId))];

  v.parameters.forEach((param) => {
    if (!param.values) return;

    if (param.avg) {
      values.push({
        id: param.avg.id || '',
        parameterId: param.parameterId,
        type: param.avg.type,
        value: param.avg.value,
      });
    }

    if (param.error) {
      values.push({
        id: param.error.id || '',
        parameterId: param.parameterId,
        type: TypeEnum.ERR,
        value: param.error.value,
      });
    }

    param.values.forEach((value) => {
      values.push({
        id: value.id || '',
        parameterId: param.parameterId,
        type: value.type || TypeEnum.ABS,
        value: value.value,
      });
    });
  });

  return values;
};

export const formatRequestToForm = (
  protocol: ReportProtocolReqDto | null,
  meteoParametersIds: string[]
): IReportProtocolForm => {
  const _values: Record<string, IReportProtocolFormValue> = {};
  const _parameters: Record<string, IReportProtocolFormValue> = {};

  protocol?.values?.forEach((value) => {
    if (!value.parameterId) return;
    if (meteoParametersIds.includes(value.parameterId)) {
      _values[value.parameterId] = value;
    } else {
      if (value.type === TypeEnum.ABS || value.type === TypeEnum.MORE || value.type === TypeEnum.LESS) {
        const prevParameterValues = _parameters[value.parameterId]?.values;
        let val: IReportProtocolFormValue['values'] = value ? [value] : [];
        if (prevParameterValues) {
          val = [...prevParameterValues, ...val];
        }

        _parameters[value.parameterId] = {
          id: value.id,
          parameterId: value.parameterId,
          ..._parameters[value.parameterId],
          values: val,
        };
      }

      if (value.type && [TypeEnum.AVG, TypeEnum.AVGLESS, TypeEnum.AVGMORE].includes(value.type)) {
        _parameters[value.parameterId] = {
          ..._parameters[value.parameterId],
          avg: value,
        };
      }

      if (value.type === TypeEnum.ERR) {
        _parameters[value.parameterId] = {
          ..._parameters[value.parameterId],
          error: value,
        };
      }
    }
  });

  return {
    ...initialEmptyValues,
    ...protocol,
    values: _values,
    parameters: Object.values(_parameters),
  };
};
