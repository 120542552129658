export const statuses = [{ name: 'Текущая', value: 'CURRENT' }, { name: 'Применяется', value: 'APPLIES' }];

export const pdkLocales = {
  main: 'Предельно допустимая концентрация (Каталог)',
  common: {
    substanceAdding: 'Добавление справочных данных',
    substanceEditing: 'Редактирование справочных данных',
    characteristics: 'Основные характеристики вещества',
    params: 'Параметры веществ',
    substanceSearch: 'Введите поисковый запрос',
    paramsAdding: 'Добавление параметров измерения веществ',
    addMeasurmentParam: 'Добавить параметр измерения'
  },
  substances: 'Вещества',
  concentration: 'Предельно допустимая концентрация',
  substancesColumns: {
    substanceName: 'Наименование вещества',
    shortName: 'Краткое наименование',
    formule: 'Химическая формула',
    dangerClass: 'Класс опасности вещества',
    substanceGroup: 'Группа веществ',
    status: 'Статус',
    notChemical: 'Не вещество'
  },
  substanceConcentration: 'Концентрация Озона',
  measuringParam: 'Параметр измерения',
  distributionEnvironment: 'Среда распространения',
  naturalEnvironment: 'Природная среда распространения вещества',
  pdkKind: 'Вид ПДК',
  pdkMin: 'Значение ПДК min',
  pdkMax: 'Значение ПДК max',
  measurmentUnit: 'Единица измерения',
  pdkDocument: 'Рег. Номер регламентирующего документа',
  pdkPrim: 'Примечание',
  pdkStartDate: 'Начало действия',
  pdkEndDate: 'Окончание действия'
};
