import { Stack } from '@chakra-ui/react';
import { Input, Select } from 'components/common';
import { dictParametersToMap } from 'features/parametersMap';
import { Field, useFormikContext } from 'formik';
import { concat, get, isNull } from 'lodash';
import {
  TLawnLawnParameteresDictionariesItemNames,
  TLawnsDictionaries,
} from 'pages/data/GreenPlants/Lawns/useLawnsDictionaries';
import React, { useCallback } from 'react';
import { TGreenPlantsForm } from 'pages/data/GreenPlants/Form/types';
import { ILawnsTabPanelProps } from './types';
import { getDictionaryId } from 'pages/data/GreenPlants/utils';
import { formatNumberInput } from 'lib/utils/forms/formatNumberInput';
import { getSelectOptionsFromDictionaryByName } from '../../../../../../features/get-select-options-from-dictionary';

interface TLawnsCoatingFormProps {
  entity: ILawnsTabPanelProps['entity'];
  isLoading: ILawnsTabPanelProps['isLoading'];
  areaParameters: TLawnsDictionaries['areaParameters'];
  lawnParameters: TLawnsDictionaries['lawnParameters'];
}

const LawnsCoatingForm: React.FC<TLawnsCoatingFormProps> = (
  {
    entity,
    isLoading,
    lawnParameters = [],
    areaParameters = [],
  }
) => {
  const ctx = useFormikContext<TGreenPlantsForm>();

  const parameters = dictParametersToMap(concat(lawnParameters, areaParameters));

  const inputOnChange = useCallback(
    (value: string | number | null, key: TLawnLawnParameteresDictionariesItemNames) => {
      ctx.setFieldValue(`${entity}.parameters.${key}`, {
        ...ctx.values.lawns.parameters[key],
        parameterId: parameters[key]?.parameterId,
        value: isNull(value) ? null : [value],
      });
    },
    [ctx, entity, parameters]
  );

  const getOptionsByName = useCallback((name: string) => getSelectOptionsFromDictionaryByName(
    [...lawnParameters, ...areaParameters],
    name,
    false,
    get(ctx.values, `${entity}.parameters.${name}.ids`)
  ), [areaParameters, ctx.values, entity, lawnParameters]);

  return (
    <Stack>
      <Field
        isDisabled={isLoading}
        as={Input}
        type="number"
        name={`${entity}.parameters.projCover.value[0]`}
        label={parameters.projCover?.title}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          inputOnChange(formatNumberInput(e.target.value), 'projCover');
        }}
        error={get(ctx.errors.lawns?.parameters?.projCover, 'value')}
      />

      <Field
        isDisabled={isLoading}
        as={Input}
        type="number"
        name={`${entity}.parameters.baldPatches.value[0]`}
        label={parameters.baldPatches?.title}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          inputOnChange(formatNumberInput(e.target.value), 'baldPatches');
        }}
        error={get(ctx.errors.lawns?.parameters?.baldPatches, 'value')}
      />

      <Field
        isDisabled={isLoading}
        as={Select}
        options={getOptionsByName('lawnColor')}
        name={`${entity}.parameters.lawnColor.dictionaryItemId[0]`}
        label={parameters.lawnColor?.title}
        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
          if (e?.target) {
            ctx.setFieldValue(`${entity}.parameters.lawnColor.dictionaryItemId`, [
              getDictionaryId(e.target.value, parameters.lawnColor.values),
            ]);
            ctx.setFieldValue(`${entity}.parameters.lawnColor.value`, [e.target.value]);
            ctx.setFieldValue(`${entity}.parameters.lawnColor.parameterId`, parameters.lawnColor?.parameterId);
          }
        }}
      />

      <Field
        isDisabled={isLoading}
        as={Input}
        type="number"
        name={`${entity}.parameters.squareWithYellowGrass.value[0]`}
        label={parameters.squareWithYellowGrass?.title}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          inputOnChange(formatNumberInput(e.target.value), 'squareWithYellowGrass');
        }}
        error={get(ctx.errors.lawns?.parameters?.squareWithYellowGrass, 'value')}
      />

      <Field
        isDisabled={isLoading}
        as={Select}
        options={getOptionsByName('denseGrass')}
        name={`${entity}.parameters.denseGrass.dictionaryItemId[0]`}
        label={parameters.denseGrass?.title}
        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
          if (e?.target) {
            ctx.setFieldValue(`${entity}.parameters.denseGrass.dictionaryItemId`, [
              getDictionaryId(e.target.value, parameters.denseGrass.values),
            ]);
            ctx.setFieldValue(`${entity}.parameters.denseGrass.value`, [e.target.value]);
            ctx.setFieldValue(`${entity}.parameters.denseGrass.parameterId`, parameters.denseGrass?.parameterId);
          }
        }}
      />
      <Field
        isDisabled={isLoading}
        as={Input}
        name={`${entity}.parameters.heightGrass.value[0]`}
        label={parameters.heightGrass?.title}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          inputOnChange(formatNumberInput(e.target.value), 'heightGrass');
        }}
        error={get(ctx.errors.lawns?.parameters?.heightGrass, 'value')}
      />
      <Field
        isDisabled={isLoading}
        as={Select}
        options={getOptionsByName('turfFelt')}
        name={`${entity}.parameters.turfFelt.dictionaryItemId[0]`}
        label={parameters.turfFelt?.title}
        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
          if (e?.target) {
            ctx.setFieldValue(`${entity}.parameters.turfFelt.dictionaryItemId`, [
              getDictionaryId(e.target.value, parameters.turfFelt.values),
            ]);
            ctx.setFieldValue(`${entity}.parameters.turfFelt.value`, [e.target.value]);
            ctx.setFieldValue(`${entity}.parameters.turfFelt.parameterId`, parameters.turfFelt?.parameterId);
          }
        }}
      />
    </Stack>
  );
};

export { LawnsCoatingForm };
