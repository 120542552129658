import React, { useEffect, useState } from 'react';
import FormComponent from 'pages/data/Meteo/Forecast/Data/forms/FormComponent';
import { useParams } from 'react-router';
import { useForecastRecord } from 'pages/data/Meteo/Forecast/Data/useForecastRecord';
import { useNotifications } from 'hooks/useNotifications';
import { useRequest } from 'hooks/useRequest';

import { FormikProvider, useFormik } from 'formik';
import moment from 'moment';
import { ForecastBaseURL, ForecastRootPath } from 'pages/data/Meteo/menu';
import { useGoBackWithFallback } from 'lib/utils/goBackWithFallback';
import { LoaderItem } from '../../../../../../components/common';

const ForecastEditItem = () => {
  const { backWithFallback } = useGoBackWithFallback();
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const { id } = useParams<{
    id: string;
  }>();

  const { isLoading: isForecastLoading, result: forecastResult }: any = useForecastRecord(id);

  const initialValues = { ...forecastResult };

  const forecastDate = moment(forecastResult?.date).toDate();

  const { successNotify, errorNotify } = useNotifications();

  const successHandler = () => {
    successNotify({
      key: 'file/create/success',
      message: 'Запись успешно обновлена',
    });
    backWithFallback(ForecastRootPath);
  };
  const errorHandler = () =>
    errorNotify({
      key: 'file/create/error',
      message: 'Ошибка при обновлении записи',
    });

  const { put, isLoading } = useRequest(`${ForecastBaseURL}`, successHandler, errorHandler);

  const form = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    initialValues,
    // TODO: uncomment when validation will be need
    // validationSchema: ForecastSchema,
    onSubmit: (values) => put(values),
  });

  useEffect(() => {
    setIsDisabled(form.dirty);
  }, [form.dirty]);

  if (isLoading || isForecastLoading) {
    return <LoaderItem />;
  }

  return (
    <FormikProvider value={{ ...form }}>
      <FormComponent
        forecastDate={forecastDate}
        isDisabled={isDisabled}
        isLoading={isForecastLoading || isLoading}
        editedObject={forecastResult}
      />
    </FormikProvider>
  );
};

export { ForecastEditItem };
