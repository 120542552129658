import { Field, useFormikContext } from 'formik';
import { Button, FormWrapper, Input } from 'components/common';
import { COMMON_LOCALE } from 'constants/common';
import { fieldsNoiseLocale, INoisePackageForm, MASKSHNoise, tabsLocale } from 'pages/data/MASKSH/consts';
import { Box, Grid, Heading } from '@chakra-ui/react';
import { useGoBackWithFallback } from 'lib/utils/goBackWithFallback';
import { useEnhancedNotification } from 'hooks';

interface IProps {
  listPath?: string;
  isLoading: boolean;
}

export const NoisePackageFormOctavesTab = ({ isLoading, listPath = MASKSHNoise }: IProps) => {
  const { values, submitForm, errors } = useFormikContext<INoisePackageForm>();
  const { backWithFallback } = useGoBackWithFallback();
  const { onValidationError } = useEnhancedNotification();

  const onSubmit = () => {
    if (Object.keys(errors).length > 0) {
      onValidationError();
    }
    submitForm();
  };

  return (
    <FormWrapper>
      <Grid gap={2}>
        <Heading mb={2} size="md">
          {tabsLocale.octaves}
        </Heading>
        <Field as={Input} label={fieldsNoiseLocale.octave1Value} name="octave1Value" value={values.octave1Value} error={errors.octave1Value} />
        <Field as={Input} label={fieldsNoiseLocale.octave2Value} name="octave2Value" value={values.octave2Value} error={errors.octave2Value} />
        <Field as={Input} label={fieldsNoiseLocale.octave3Value} name="octave3Value" value={values.octave3Value} error={errors.octave3Value} />
        <Field as={Input} label={fieldsNoiseLocale.octave4Value} name="octave4Value" value={values.octave4Value} error={errors.octave4Value} />
        <Field as={Input} label={fieldsNoiseLocale.octave5Value} name="octave5Value" value={values.octave5Value} error={errors.octave5Value} />
        <Field as={Input} label={fieldsNoiseLocale.octave6Value} name="octave6Value" value={values.octave6Value} error={errors.octave6Value} />
        <Field as={Input} label={fieldsNoiseLocale.octave7Value} name="octave7Value" value={values.octave7Value} error={errors.octave7Value} />
        <Field as={Input} label={fieldsNoiseLocale.octave8Value} name="octave8Value" value={values.octave8Value} error={errors.octave8Value} />
        <Field as={Input} label={fieldsNoiseLocale.octave9Value} name="octave9Value" value={values.octave9Value} error={errors.octave9Value} />

        <Box mt={4}>
          <Button marginRight={20} onClick={() => backWithFallback(listPath)} variant="solid">
            {COMMON_LOCALE.cancel}
          </Button>
          <Button isDisabled={isLoading} onClick={onSubmit} variant="solid">
            {COMMON_LOCALE.save}
          </Button>
        </Box>
      </Grid>
    </FormWrapper>
  );
};
