import { Field, FormikProvider, useFormik } from 'formik';
import { DateInput, Select } from 'components/common';
import { Flex, Grid } from '@chakra-ui/react';
import { createLocalDateISO8601 } from 'lib/create-date';
import { COMMON_LOCALE } from 'constants/common';
import { Options } from 'pages/equipment/Devices/types';
import { fieldsLocale } from 'pages/equipment/Devices/consts';
import { FiltersDatepickersSchema } from 'models/schemas/filtersDatepickersSchema/FiltersDatepickersSchema';
import FilterFormControls from 'components/form/FilterFormControls';
import React from 'react';

interface FilterProps {
  onSubmit: (values: any) => void;
  statuses?: Options;
}

const initialValues = {
  dateStart: {
    key: 'dateStart',
    operation: 'GTE',
    value: null,
  },
  dateEnd: {
    key: 'dateStart',
    operation: 'LTE',
    value: null,
  },
  statusId: {
    key: 'statusId',
    operation: 'EQ',
    value: null,
  },
};

export const EquipmentReportTableFilter = ({ onSubmit, statuses }: FilterProps) => {
  const {
    values,
    errors,
    getFieldMeta,
    getFieldHelpers,
    getFieldProps,
    isValid,
    handleReset,
    submitForm,
    setFieldValue,
    ...formik
  } = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    validationSchema: FiltersDatepickersSchema([{ dateFromFieldName: 'dateStart', dateToFieldName: 'dateEnd' }]),
    initialValues,
    onSubmit,
  });

  return (
    <FormikProvider
      value={{
        values,
        errors,
        getFieldMeta,
        getFieldHelpers,
        getFieldProps,
        isValid,
        handleReset,
        submitForm,
        setFieldValue,
        ...formik,
      }}
    >
      <Flex>
        <Grid width="100%" maxWidth="1200px" templateColumns="repeat(3, 1fr)" gap={5}>
          <Field
            as={DateInput}
            name="dateStart.value"
            label={COMMON_LOCALE.from}
            value={values.dateStart.value}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFieldValue('dateStart.value', createLocalDateISO8601(e.target.value));
            }}
            error={errors.dateStart?.value}
          />
          <Field
            as={DateInput}
            name="dateEnd.value"
            label={COMMON_LOCALE.to}
            value={values.dateEnd.value}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFieldValue('dateEnd.value', createLocalDateISO8601(e.target.value));
            }}
            error={errors.dateEnd?.value}
          />

          <Field as={Select} options={statuses} name="statusId.value" label={fieldsLocale.statusId} />
        </Grid>

        <FilterFormControls
          onReset={(e) => {
            handleReset(e);
            void submitForm();
          }}
          onSubmit={submitForm}
        />
      </Flex>
    </FormikProvider>
  );
};
