import { BottomSoilParametersKeys } from 'pages/data/Water/BottomSoil/consts/types';

export const bottomSoilInitialEmptyValues = {
  consumerId: null,
  organizationId: null,
  sourceId: null,
  waterObject: null,
  protocolDate: '',
  protocolNumber: '',
  sampleDate: '',

  district: '',
  latitude: '',
  longitude: '',
  region: '',

  ...BottomSoilParametersKeys.reduce((obj, key) => Object.assign(obj, { [key]: { strValue: '' } }), {}),
};
