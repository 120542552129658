import { Box, Grid, Heading, Text } from '@chakra-ui/react';

import React from 'react';
import FileUploadDragAndDrop, { UploadFileType } from 'components/common/FileUploadDragAndDrop/FileUploadDragAndDrop';
import { UploadObjectType } from 'api/services/media';
import { IUploadMedia } from 'components/common/FileUploadDragAndDrop/types';

interface Props {
  objectType: UploadObjectType | null;
  uploadItemId?: string;
  onMediaChange?: (media: IUploadMedia[]) => void;
  media?: IUploadMedia[];
  heading?: string;
  description?: string;
  uploadFileTypes?: UploadFileType[] | UploadFileType;
  isWrite?: boolean
}

export const AttachmentsTabContent: React.FC<Props> = ({
  objectType,
  media,
  onMediaChange,
  uploadItemId,
  children,
  heading = 'Дополнительно',
  description = 'Вы можете загрузить фотографию/документ:',
  uploadFileTypes = [UploadFileType.image, UploadFileType.pdf],
  isWrite
}) => (
  <Box flexDirection="column" justifyContent="center">
    <Grid maxWidth="500px" margin="30px 0px 20px 0px" gap="15px">
      <Box maxWidth="500px">
        <Heading mt={5} mb={2} size="md">
          {heading}
        </Heading>
        <Text>{description}</Text>
        <FileUploadDragAndDrop
          media={media}
          type={uploadFileTypes}
          onMediaChange={onMediaChange!}
          objectType={objectType}
          id={uploadItemId}
          isWrite={isWrite}
        />
      </Box>
      {children}
    </Grid>
  </Box>
);
