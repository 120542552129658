import { IReportProtocolForm } from 'pages/analytics/Raids/types';
import { ANALYTICS_STATUS } from 'pages/analytics/forms/const';

export const initialEmptyValues: IReportProtocolForm = {
  taskId: null,
  observationPointId: null,
  values: {},
  parameters: [],

  protocolNumber: '',
  protocolDate: null,
  customerId: null,
  researchObject: '',
  address: '',
  sampleAddress: '',
  samplePlace: '',
  contractorId: null,
  methodology: '',
  sampleActNumber: '',
  sampleRegNumber: '',
  sampleStartDatetime: null,
  sampleEndDatetime: null,
  sampleReceiveDatetime: null,
  researchStartDatetime: null,
  researchEndDatetime: null,
  researchDateStart: null,
  researchDateEnd: null,
  extraInfoValue: '',
  researchBasis: '',
  supportingDocuments: '',
  status: ANALYTICS_STATUS.DRAFT,
  laboratory: true,
  sign: null,
  links: null,
};
