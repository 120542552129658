import { memo } from 'react';
import { DeviceTable } from 'pages/equipment/Devices/components/DeviceTable';
import { useEquipmentDeviceBreadcrumbs } from './hooks/useEquipmentDeviceBreadcrumbs';

export const Devices = memo(() => {
  useEquipmentDeviceBreadcrumbs();

  return <DeviceTable />;
});

Devices.displayName = 'Devices';
