import * as yup from 'yup';
import { requiredError } from '../constans';

export const RightSchema = yup.object().shape({
  name: yup.string().typeError(requiredError).required(requiredError),
  code: yup
    .string()
    .required(requiredError)
    .matches(/^[/^[A-z0-9]+$/, 'Допускаются только английские буквы'),
  action: yup.string().typeError(requiredError).required(requiredError),
  objectType: yup.string().typeError(requiredError).required(requiredError),
  subsystemId: yup.string().typeError(requiredError).required(requiredError),
});
