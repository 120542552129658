import { useCallback, useContext, useMemo } from 'react';
import { UserInfoContext } from '../features/AuthProvider';
import { RenderTree } from '../features/WithSideBar/types';
import { cloneDeep } from 'lodash';
import appLocale from '../constants/appLocale';
import { IRoleCode } from '../api/services/auth';

export const useFilteredMenu = (menu: RenderTree[]) => {
  const { userRoles, userSubsystems, customRights, chartCombinationReports } = useContext(UserInfoContext);

  const roleCodes = useMemo(() => userRoles.map((role) => role.code), [userRoles]);
  const userSubsystemsIds = useMemo(() => userSubsystems?.map((subsystem) => subsystem.id), [userSubsystems]);
  const customRightsCodes = useMemo(() => customRights?.map((right) => right.code), [customRights]);

  const filterMenu = useCallback(
    (menu, roleCodes: IRoleCode[], userSubsystemsIds: string[], customRightsCodes: string[]) => {
      const items = cloneDeep(menu);

      const updatedMenu: RenderTree[] = [];

      items.forEach((menuItem: RenderTree) => {
        if (menuItem.subsystemId && !userSubsystemsIds?.includes(menuItem.subsystemId)) {
          return;
        }

        if (menuItem.otherCode && !customRightsCodes?.find((right) => right.includes(menuItem.otherCode as string))) {
          return;
        }

        if (
          menuItem.dependentPrivileges &&
          !menuItem.dependentPrivileges.find((privilege) =>
            customRightsCodes.find((right) => right.includes(privilege)))
        ) {
          return;
        }

        if (menuItem.children) {
          menuItem.children = filterMenu(menuItem.children, roleCodes, userSubsystemsIds, customRightsCodes);
        }

        if (menuItem.name === appLocale.subsystems.analyticsReports) {
          menuItem.children = menuItem.children?.map((item) => {
            if (item.name === appLocale.analytics.combinedCharts.title) {
              item.children = [
                ...(item.children || []),
                ...(chartCombinationReports || []).map((report) => ({
                  name: report.name.trim(),
                  id: report.link,
                  url: report.link,
                })),
              ].sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1));
            }

            return item;
          });
        }
        // filter empty menus that don't have children and dedicated page ( url )
        if (menuItem.children?.length === 0 && menuItem.name !== appLocale.mainPage.nsi) {
          return;
        }

        updatedMenu.push(menuItem);
      });

      return updatedMenu;
    },
    [chartCombinationReports],
  );

  return { filteredMenu: filterMenu(menu, roleCodes, userSubsystemsIds || [], customRightsCodes || []) };
};
