import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { DeleteIcon, EditIcon, ImageIcon } from 'components/icons';
import { TFiltersArray } from 'models';
import { MaterialTable } from 'features/material-table/MaterialTableMini';
import {
  WaterRouteAskzvAddPackages,
  WaterRouteAskzvMeasurments,
  WaterRouteAskzvPackages,
  WaterRouteAskzvRoot,
} from '../../routes';
import { useAvailability, useHandleDeleteData, useList } from 'hooks';
import { useBreadcrumbsWaterASKZV, useMeasurementsTableColumns, usePackagesTableColumns } from '../hooks';
import { IASKZVPackage } from '../types';
import { apiPathAskzv } from '../apiPath';
import useFilePreviewModalWrapperData from 'components/common/FilePreviewModal/hooks/useFilePreviewModalWrapperData';
import FilePreviewModalWrapper from 'components/common/FilePreviewModal/FilePreviewModalWrapper';
import { WaterASKZVFilters } from 'pages/data/Water/ASKZV/WaterASKZVFilters';
import { Column } from '@material-table/core';
import { addFiltersToColumns } from 'lib/utils/tableFilter';
import { usePeriodsDict } from 'features/PeriodsButtons/usePeriodsDict';
import subsystemIdsMap from 'constants/subsystemIdsMap';
import { WaterASKZVHeader } from '../WaterASKZVHeader';
import { TableTabs } from 'pages/data/MASKSH/Data/components/TableTabs';
import { useDictionary } from 'hooks/useDictionary';
import { getSelectOptionsFromDictionaryByName } from 'features/get-select-options-from-dictionary';
import useCalculations from 'hooks/useCalculations';
import { apiPathsWaterRoot } from '../../apiPath';
import { operations } from 'lib/calculations/operations';
import { createUTCTime } from 'lib/create-date';
import { DEFAULT_PAGE_SIZE_NEW_TABLE } from 'constants/tables';
import { FullPageWrapper } from 'components/table/FullPageWrapper';

export const ASKZVPackages = () => {
  useBreadcrumbsWaterASKZV();
  const { push } = useHistory();

  const { isWrite, isExecute } = useAvailability(subsystemIdsMap.waterAskzv);

  const { dictionaries, isDictionariesLoading } = useDictionary({ url: apiPathAskzv.dict });
  const sources = useMemo(() => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'sources'), [dictionaries]);
  const fire = useMemo(() => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'fire'), [dictionaries]);
  const door = useMemo(() => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'door'), [dictionaries]);
  const event = useMemo(() => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'event'), [dictionaries]);
  const parameters = useMemo(
    () => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'calcParameters'),
    [dictionaries],
  );

  const {
    isLoading: isItemsLoading,
    response,
    toggleOrder,
    setFilters,
    onPageChange,
    refetch,
    filterDTO,
    downloadAsFile,
    onSearchText,
    saveFilters,
    errorText,
    selectedIndexes,
    renderOnlySelectedCheckbox,
    onSubmitFilters,
    onSelectionChange,
    materialTableProps,
    selectedIds,
  } = useList<IASKZVPackage, keyof IASKZVPackage>(apiPathAskzv.packages, {
    initialOrderFieldKey: 'measuringDatetime',
    exportSubsystemName: 'askzv_package',
    preventFirstFetch: true,
    saveFiltersState: true,
    selectionKey: 'id',
    defaultPageSize: DEFAULT_PAGE_SIZE_NEW_TABLE,
    resetDataOnNextRequest: true,
  });

  const columns = usePackagesTableColumns();
  const columnsWithFilters = useMemo<Array<Column<IASKZVPackage>>>(
    () => addFiltersToColumns({ filters: filterDTO.filters, setFilters, columns }),
    [columns, filterDTO.filters, setFilters],
  );

  const [enhancedData, setEnhancedData] = useState<any[] | null>(null);

  const { open, ...filePreviewData } = useFilePreviewModalWrapperData();

  const { ModalComponent, handleOpenModal } = useHandleDeleteData({ refetch });

  const responseData = response?.data;

  const prepareRowForEdit = useCallback(
    (dataRow: IASKZVPackage) => {
      const fireItem = fire?.find((dict) => dict.name === dataRow.fireId);
      if (fireItem) {
        dataRow.fireId = fireItem.value;
      }
      const eventItem = event?.find((dict) => dict.name === dataRow.eventId);
      if (eventItem) {
        dataRow.eventId = eventItem.value;
      }
      const doorItem = door?.find((dict) => dict.name === dataRow.doorId);
      if (doorItem) {
        dataRow.doorId = doorItem.value;
      }
      return { ...dataRow };
    },
    [door, event, fire],
  );

  useEffect(() => {
    if (responseData && event && fire && door) {
      const newData = responseData.map((dataRow) => {
        if (dataRow.eventId) {
          const foundItem = event.find((dict) => dict.value === dataRow.eventId);
          if (foundItem) {
            dataRow.eventId = foundItem.name;
          }
        }
        if (dataRow.fireId) {
          const foundItem = fire.find((dict) => dict.value === dataRow.fireId);
          if (foundItem) {
            dataRow.fireId = foundItem.name;
          }
        }
        if (dataRow.doorId) {
          const foundItem = door.find((dict) => dict.value === dataRow.doorId);
          if (foundItem) {
            dataRow.doorId = foundItem.name;
          }
        }

        return dataRow;
      });
      setEnhancedData(newData);
    } else {
      setEnhancedData(null);
    }
  }, [door, event, fire, responseData]);

  // calculations
  const { calculatePreview, isCalculationLoading, renderDriftPreview, renderPreview } = useCalculations({
    apiPath: `${apiPathsWaterRoot}/water/askzv`,
    previewPath: `${apiPathsWaterRoot}/water/askzv/packages`,
    driftPath: `${apiPathsWaterRoot}/water/askzv/packages`,
    refetch,
    columns: useMeasurementsTableColumns(),
  });

  const onSubmitDataProcessing = useCallback(
    (values) => {
      void calculatePreview(
        {
          value: values.value,
          operation: values.operationId,
          parameterIds: values.parameterIds || null,
          criteria: filterDTO,
        },
        { ...values, ids: selectedIds || [], includeParameterId: true },
      );
    },
    [calculatePreview, filterDTO, selectedIds],
  );

  const dataProcessing = useMemo(() => {
    const operands = [{ value: 'value', name: 'Значение' }];

    return {
      operands,
      operations,
      onSubmit: onSubmitDataProcessing,
      parameters,
    };
  }, [onSubmitDataProcessing, parameters]);

  const isLoading = useMemo(
    () => isItemsLoading || isDictionariesLoading || isCalculationLoading,
    [isCalculationLoading, isDictionariesLoading, isItemsLoading],
  );

  const { result: periods } = usePeriodsDict(`auth/v10/user/period/${subsystemIdsMap.waterAskzv}`);

  const parametersKeys = useMemo(() => {
    const dateFrom =
      (filterDTO?.filters.find((item) => item.key === 'measuringDatetime' && item.operation === 'GTE')
        ?.value as string) || '';
    const dateTo =
      (filterDTO?.filters.find((item) => item.key === 'measuringDatetime' && item.operation === 'LTE')
        ?.value as string) || '';
    return {
      sourceId: (filterDTO?.filters.find((item) => item.key === 'sourceId')?.value as string) || null,
      dateFrom: createUTCTime(dateFrom),
      dateTo: createUTCTime(dateTo),
    };
  }, [filterDTO.filters]);

  return (
    <FullPageWrapper>
      <WaterASKZVHeader
        addPath={WaterRouteAskzvAddPackages}
        refetch={refetch}
        navigationTabs={
          <TableTabs
            tabs={[
              {
                name: 'Значения измерений',
                path: WaterRouteAskzvMeasurments,
              },
              {
                name: 'Пакеты',
                path: WaterRouteAskzvPackages,
              },
            ]}
          />
        }
        isWrite={isWrite}
        filtersComponent={
          <WaterASKZVFilters
            filters={filterDTO?.filters || []}
            periods={periods}
            parameters={parameters}
            isPackagesView={false}
            sources={sources}
            isLoading={false}
            isDisabled={false}
            onSubmit={(values) => {
              const filters: TFiltersArray<keyof IASKZVPackage> = Object.values(values);
              onSubmitFilters(filters);
            }}
          >
            {renderOnlySelectedCheckbox}
          </WaterASKZVFilters>
        }
      />

      <MaterialTable
        saveFilters={saveFilters}
        filterDTO={filterDTO}
        data={enhancedData || []}
        meta={response?.meta}
        columns={columnsWithFilters}
        toggleOrder={toggleOrder}
        onPageChange={onPageChange}
        actions={[
          {
            position: 'row',
            action: (row) => ({
              icon: () => <EditIcon />,
              onClick: () => {
                if (!Array.isArray(row)) {
                  const editedObject = prepareRowForEdit(row);
                  push(`${WaterRouteAskzvRoot}/packages/edit/${editedObject.id}`, { editedObject });
                }
              },
              tooltip: 'Редактировать',
              hidden: !row.isEditable,
            }),
          },
          {
            position: 'row',
            action: (row) => ({
              icon: () => <DeleteIcon />,
              onClick: () => {
                if (!Array.isArray(row)) {
                  handleOpenModal({ url: `${apiPathAskzv.root}/${row.id}` });
                }
              },
              tooltip: 'Удалить',
              hidden: !row.isEditable,
            }),
          },
          {
            position: 'row',
            action: (row) => ({
              icon: () => <ImageIcon />,
              onClick: () => open(row.id, row.objectType),
              tooltip: 'Показать изображения',
              hidden: !row.objectType,
            }),
          },
        ]}
        isLoading={isLoading || isDictionariesLoading}
        options={{
          search: true,
        }}
        onSearch={onSearchText}
        downloadAsFile={downloadAsFile}
        selectedIndexes={selectedIndexes}
        onSelectionChange={onSelectionChange}
        errorText={errorText}
        dataProcessing={dataProcessing}
        processingParamsUrl="/water/v10/water/askzv/dictionaries/dependent-parameters"
        parametersKeys={parametersKeys}
        isNotExecute={isExecute}
        isWrite={isWrite}
        {...materialTableProps}
        showTotalCount
      />

      {ModalComponent}

      {renderPreview}

      {renderDriftPreview}

      <FilePreviewModalWrapper {...filePreviewData} />
    </FullPageWrapper>
  );
};
