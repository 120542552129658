import { IFilterItem, TDictionariesArray } from 'models';
import { Field, useFormikContext } from 'formik';
import { Checkbox, DateInput, Select } from 'components/common';
import { Flex, Grid, GridItem } from '@chakra-ui/react';
import React, { useMemo } from 'react';
import { createLocalDateISO8601, createUTCTime } from 'lib/create-date';
import { getSelectRichOptionsFromDictionaryByName } from 'features/get-select-options-from-dictionary';
import FilterFormControls from 'components/form/FilterFormControls';

interface IProps {
  raidsDictionaries: { data: TDictionariesArray } | null;
  subType: number;
  type: number;
  isLoading: boolean;
}

export const TableViewFilterFields = ({ subType, raidsDictionaries, type, isLoading }: IProps) => {
  const raidShiftId = useMemo(
    () => getSelectRichOptionsFromDictionaryByName(raidsDictionaries?.data, 'raidShiftId'),
    [raidsDictionaries],
  );
  const taskTypesNoise = useMemo(
    () => getSelectRichOptionsFromDictionaryByName(raidsDictionaries?.data, 'taskTypesNoise'),
    [raidsDictionaries],
  );
  const taskTypesAir = useMemo(
    () => getSelectRichOptionsFromDictionaryByName(raidsDictionaries?.data, 'taskTypesAir'),
    [raidsDictionaries],
  );
  const noiseSourceTypeId = useMemo(
    () => getSelectRichOptionsFromDictionaryByName(raidsDictionaries?.data, 'noiseSourceTypeId'),
    [raidsDictionaries],
  );
  const odorSourceTypeId = useMemo(
    () => getSelectRichOptionsFromDictionaryByName(raidsDictionaries?.data, 'odorSourceTypeId'),
    [raidsDictionaries],
  );
  const pointTypeId = useMemo(
    () => getSelectRichOptionsFromDictionaryByName(raidsDictionaries?.data, 'pointTypeId'),
    [raidsDictionaries],
  );

  const { values, submitForm, setFieldValue, handleReset, errors } = useFormikContext<{ [p: string]: IFilterItem }>();

  return (
    <Flex direction="row" justifyContent="space-between">
      <Flex direction="column" flexGrow={1}>
        <Grid gap="12px" rowGap="8px" templateColumns="repeat(4, 1fr)">
          {subType !== 2 ? (
            <>
              <GridItem>
                <Field
                  as={DateInput}
                  name="raidDateTimeMin.value"
                  label="Дата рейда от"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setFieldValue('raidDateTimeMin.value', createLocalDateISO8601(e.target.value))
                  }
                  error={errors.raidDateTimeMin?.value}
                />
              </GridItem>
              <GridItem>
                <Field
                  as={DateInput}
                  name="raidDateTimeMax.value"
                  label="Дата рейда до"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setFieldValue('raidDateTimeMax.value', createLocalDateISO8601(e.target.value))
                  }
                  error={errors.raidDateTimeMax?.value}
                />
              </GridItem>
              <GridItem>
                <Field
                  as={DateInput}
                  name="executionPeriodMin.value"
                  label="Срок исполнения от"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setFieldValue('executionPeriodMin.value', createLocalDateISO8601(e.target.value))
                  }
                  error={errors.executionPeriodMin?.value}
                />
              </GridItem>
              <GridItem>
                <Field
                  as={DateInput}
                  name="executionPeriodMax.value"
                  label="Срок исполнения до"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setFieldValue('executionPeriodMax.value', createLocalDateISO8601(e.target.value))
                  }
                  error={errors.executionPeriodMax?.value}
                />
              </GridItem>
            </>
          ) : null}
          {subType === 1 ? (
            <>
              {!type ? (
                <>
                  <GridItem>
                    <Field
                      as={DateInput}
                      name="protocolDateFrom.value"
                      label="Дата протокола от"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setFieldValue('protocolDateFrom.value', createLocalDateISO8601(e.target.value))
                      }
                      error={errors.protocolDateFrom?.value}
                    />
                  </GridItem>
                  <GridItem>
                    <Field
                      as={DateInput}
                      name="protocolDateTo.value"
                      label="Дата протокола до"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setFieldValue('protocolDateTo.value', createLocalDateISO8601(e.target.value))
                      }
                      error={errors.protocolDateTo?.value}
                    />
                  </GridItem>
                </>
              ) : null}
              <GridItem>
                <Field
                  as={DateInput}
                  name="startMeasurementsFrom.value"
                  label="Начало измерений от"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setFieldValue(e.target.name, createUTCTime(e.target.value))
                  }
                  showTimeInput
                  error={errors.startMeasurementsFrom?.value}
                />
              </GridItem>
              <GridItem>
                <Field
                  as={DateInput}
                  name="startMeasurementsTo.value"
                  label="Начало измерений до"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setFieldValue(e.target.name, createUTCTime(e.target.value))
                  }
                  showTimeInput
                  error={errors.startMeasurementsTo?.value}
                />
              </GridItem>
              <GridItem>
                <Field
                  as={DateInput}
                  name="endMeasurementsFrom.value"
                  label="Окончание измерений от"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setFieldValue(e.target.name, createUTCTime(e.target.value))
                  }
                  showTimeInput
                  error={errors.endMeasurementsFrom?.value}
                />
              </GridItem>
              <GridItem>
                <Field
                  as={DateInput}
                  name="endMeasurementsTo.value"
                  label="Окончание измерений до"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setFieldValue(e.target.name, createUTCTime(e.target.value))
                  }
                  showTimeInput
                  error={errors.endMeasurementsTo?.value}
                />
              </GridItem>
              {type ? (
                <>
                  <GridItem>
                    <Field
                      as={DateInput}
                      name="pelProtocolDateFrom.value"
                      label="Дата протокола ПЭЛ от"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setFieldValue('pelProtocolDateFrom.value', createLocalDateISO8601(e.target.value))
                      }
                      error={errors.pelProtocolDateFrom?.value}
                    />
                  </GridItem>
                  <GridItem>
                    <Field
                      as={DateInput}
                      name="pelProtocolDateTo.value"
                      label="Дата протокола ПЭЛ до"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setFieldValue('pelProtocolDateTo.value', createLocalDateISO8601(e.target.value))
                      }
                      error={errors.pelProtocolDateTo?.value}
                    />
                  </GridItem>
                  <GridItem>
                    <Field
                      as={DateInput}
                      name="aiProtocolDateFrom.value"
                      label="Дата протокола АИ от"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setFieldValue('aiProtocolDateFrom.value', createLocalDateISO8601(e.target.value))
                      }
                      error={errors.aiProtocolDateFrom?.value}
                    />
                  </GridItem>
                  <GridItem>
                    <Field
                      as={DateInput}
                      name="aiProtocolDateTo.value"
                      label="Дата протокола АИ до"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setFieldValue('aiProtocolDateTo.value', createLocalDateISO8601(e.target.value))
                      }
                      error={errors.aiProtocolDateTo?.value}
                    />
                  </GridItem>
                </>
              ) : null}
            </>
          ) : null}
          {subType === 2 ? (
            <>
              <GridItem>
                <Field
                  as={DateInput}
                  name="edoAgreementDateMin.value"
                  label="Дата Согла от"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setFieldValue('edoAgreementDateMin.value', createLocalDateISO8601(e.target.value))
                  }
                  error={errors.edoAgreementDateMin?.value}
                />
              </GridItem>
              <GridItem>
                <Field
                  as={DateInput}
                  name="edoAgreementDateMax.value"
                  label="Дата Согла до"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setFieldValue('edoAgreementDateMax.value', createLocalDateISO8601(e.target.value))
                  }
                  error={errors.edoAgreementDateMax?.value}
                />
              </GridItem>
              <GridItem>
                <Field
                  as={DateInput}
                  name="incomingLetterDateMin.value"
                  label="Входящая дата письма от"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setFieldValue('incomingLetterDateMin.value', createLocalDateISO8601(e.target.value))
                  }
                  error={errors.incomingLetterDateMin?.value}
                />
              </GridItem>
              <GridItem>
                <Field
                  as={DateInput}
                  name="incomingLetterDateMax.value"
                  label="Входящая дата письма до"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setFieldValue('incomingLetterDateMax.value', createLocalDateISO8601(e.target.value))
                  }
                  error={errors.incomingLetterDateMax?.value}
                />
              </GridItem>
              <GridItem>
                <Field
                  as={DateInput}
                  name="outgoingLetterDateMin.value"
                  label="Исходящая дата письма от"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setFieldValue('outgoingLetterDateMin.value', createLocalDateISO8601(e.target.value))
                  }
                  error={errors.outgoingLetterDateMin?.value}
                />
              </GridItem>
              <GridItem>
                <Field
                  as={DateInput}
                  name="outgoingLetterDateMax.value"
                  label="Исходящая дата письма до"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setFieldValue('outgoingLetterDateMax.value', createLocalDateISO8601(e.target.value))
                  }
                  error={errors.outgoingLetterDateMax?.value}
                />
              </GridItem>
              <GridItem>
                <Field
                  as={DateInput}
                  name="incomingAnswerDateMin.value"
                  label="Входящая дата ответа от"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setFieldValue('incomingAnswerDateMin.value', createLocalDateISO8601(e.target.value))
                  }
                  error={errors.incomingAnswerDateMin?.value}
                />
              </GridItem>
              <GridItem>
                <Field
                  as={DateInput}
                  name="incomingAnswerDateMax.value"
                  label="Входящая дата ответа до"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setFieldValue('incomingAnswerDateMax.value', createLocalDateISO8601(e.target.value))
                  }
                  error={errors.incomingAnswerDateMax?.value}
                />
              </GridItem>
              <GridItem>
                <Field
                  as={DateInput}
                  name="outgoingAnswerDateMin.value"
                  label="Исходящая дата ответа от"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setFieldValue('outgoingAnswerDateMin.value', createLocalDateISO8601(e.target.value))
                  }
                  error={errors.outgoingAnswerDateMin?.value}
                />
              </GridItem>
              <GridItem>
                <Field
                  as={DateInput}
                  name="outgoingAnswerDateMax.value"
                  label="Исходящая дата ответа до"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setFieldValue('outgoingAnswerDateMax.value', createLocalDateISO8601(e.target.value))
                  }
                  error={errors.outgoingAnswerDateMax?.value}
                />
              </GridItem>
            </>
          ) : null}
        </Grid>
        <Grid gap="12px" rowGap="8px" marginTop={2} templateColumns="repeat(4, 1fr)">
          {subType !== 2 ? (
            <>
              <GridItem>
                <Field as={Select} name="raidShiftId.value" label="Время суток" options={raidShiftId} />
              </GridItem>
            </>
          ) : null}
          {!subType && !type ? (
            <>
              <GridItem>
                <Field as={Select} name="researchTypeId.value" label="Тип исследования" options={taskTypesNoise} />
              </GridItem>
            </>
          ) : null}
          {!subType && type ? (
            <>
              <GridItem>
                <Field as={Select} name="taskTypeId.value" label="Тип обследования" options={taskTypesAir} />
              </GridItem>
            </>
          ) : null}
          {subType === 1 ? (
            <>
              {!type ? (
                <>
                  <GridItem>
                    <Field as={Select} name="taskTypeId.value" label="Тип рейда" options={taskTypesNoise} />
                  </GridItem>
                  <GridItem>
                    <Field as={Select} name="noiseSourceTypeId.value" label="Тип источника" options={noiseSourceTypeId} />
                  </GridItem>
                </>
              ) : (
                <>
                  <GridItem>
                    <Field as={Select} name="pointTypeId.value" label="Тип выезда" options={pointTypeId} />
                  </GridItem>
                  <GridItem>
                    <Field as={Select} name="odorSourceTypeId.value" label="Тип источника" options={odorSourceTypeId} />
                  </GridItem>
                </>
              )}
            </>
          ) : null}
        </Grid>
        <Grid gap="12px" rowGap="8px" templateColumns="repeat(5, 1fr)">
          {!subType ? (
            <>
              <GridItem>
                <Field
                  as={Checkbox}
                  checked={values.hasProtocol.value}
                  name="hasProtocol.value"
                  label="Наличие протокола"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setFieldValue('hasProtocol.value', e.target.checked || '')
                  }
                />
              </GridItem>
            </>
          ) : null}
          {subType !== 2 ? (
            <>
              <GridItem>
                {!type && subType ? (
                  <Field
                    as={Checkbox}
                    checked={values.isViolation.value}
                    name="isViolation.value"
                    label="Превышения"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setFieldValue('isViolation.value', e.target.checked || '')
                    }
                  />
                ) : (
                  <Field
                    as={Checkbox}
                    checked={values.hasExcess.value}
                    name="hasExcess.value"
                    label="Превышения"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setFieldValue('hasExcess.value', e.target.checked || '')
                    }
                  />
                )}
              </GridItem>
              {!type ? (
                <GridItem>
                  <Field
                    as={Checkbox}
                    checked={values.renovation.value}
                    name="renovation.value"
                    label="Реновация"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setFieldValue('renovation.value', e.target.checked || '')
                    }
                  />
                </GridItem>
              ) : null}
            </>
          ) : null}
          {subType === 1 ? (
            <>
              {!type ? (
                <>
                  <GridItem>
                    <Field
                      as={Checkbox}
                      checked={values.isRepeat.value}
                      name="isRepeat.value"
                      label="Повтор"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setFieldValue('isRepeat.value', e.target.checked || '')
                      }
                    />
                  </GridItem>
                  <GridItem>
                    <Field
                      as={Checkbox}
                      checked={values.isSetProtocol.value}
                      name="isSetProtocol.value"
                      label="Наличие протокола"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setFieldValue('isSetProtocol.value', e.target.checked || '')
                      }
                    />
                  </GridItem>
                </>
              ) : (
                <>
                  <GridItem>
                    <Field
                      as={Checkbox}
                      checked={values.protocolPEL.value}
                      name="protocolPEL.value"
                      label="Наличие протокола ПЭЛ"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setFieldValue('protocolPEL.value', e.target.checked || '')
                      }
                    />
                  </GridItem>
                  <GridItem>
                    <Field
                      as={Checkbox}
                      checked={values.protocolAI.value}
                      name="protocolAI.value"
                      label="Наличие протокола АИ"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setFieldValue('protocolAI.value', e.target.checked || '')
                      }
                    />
                  </GridItem>
                </>
              )}
            </>
          ) : null}
          {subType === 2 ? (
            <>
              <GridItem>
                <Field
                  as={Checkbox}
                  checked={values.answer.value}
                  name="answer.value"
                  label="Ответ"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setFieldValue('answer.value', e.target.checked || '')
                  }
                />
              </GridItem>
            </>
          ) : null}
        </Grid>
      </Flex>

      <FilterFormControls
        onReset={(e) => {
          handleReset(e);
          setTimeout(submitForm, 0);
        }}
        onSubmit={submitForm}
        isLoading={isLoading}
      />
    </Flex>
  );
};
