import { ERROR_MESSAGES } from 'constants/messages';
import { createTouchedErrors } from 'features/create-touched-errors';
import { FormikProvider, useFormik } from 'formik';
import { useNotifications } from 'hooks/useNotifications';
import { useRequest } from 'hooks/useRequest';
import { useParams } from 'react-router-dom';
import FieldsForm from './FieldsForm';
import React, { useCallback, useEffect, useState } from 'react';
import { updateFiles } from 'api/services/media';
import { IUploadMedia } from 'components/common/FileUploadDragAndDrop/types';
import { useObjectType } from 'hooks/useObjectType';
import { WaterRoutesGroundCoast } from 'pages/data/Water/routes';
import { ApiError } from 'models';
import { formatErrorMessage } from 'lib/utils/formatErrorMessage';
import { useEnhancedNotification } from 'hooks';
import { GroundCoastListDTO } from 'pages/data/Water/WPZRoot/GroundCoast/consts/types';
import { GroundCoastApiPath } from 'pages/data/Water/WPZRoot/GroundCoast/consts/apiPaths';
import { GroundCoastSchema } from 'models/schemas/water/groundcoast';
import { groundCoastInitialEmptyValues } from 'pages/data/Water/WPZRoot/GroundCoast/GroundCoastForms/initialEmptyValues';
import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import { formBreadcrumbs } from 'pages/data/Water/WPZRoot/GroundCoast/consts/breadcrumbs';
import { useGoBackWithFallback } from 'lib/utils/goBackWithFallback';
import { useDictionary } from 'hooks/useDictionary';
import { LoaderItem } from '../../../../../../components/common';

export const GroundCoastEdit = () => {
  const { backWithFallback } = useGoBackWithFallback();
  const { packageId } = useParams<{ packageId: string }>();
  useUpdateBreadcrumbs(formBreadcrumbs(WaterRoutesGroundCoast.edit(packageId)));
  const { dictionaries, isDictionariesLoading } = useDictionary({ url: GroundCoastApiPath.dictionaries });
  const objectType = useObjectType(dictionaries);
  const { errorNotify } = useNotifications();
  const { onSuccessEdit } = useEnhancedNotification();
  const [media, setMedia] = useState<IUploadMedia[]>([]);
  const [editedObject, setEditedObject] = useState<Partial<GroundCoastListDTO>>({
    ...groundCoastInitialEmptyValues,
  });

  const onItemLoad = useCallback((response) => {
    if (response) {
      setEditedObject(response);
    }
  }, []);

  const { get, isLoading } = useRequest<GroundCoastListDTO>(GroundCoastApiPath.getOrDelete(packageId), onItemLoad);

  useEffect(() => {
    void get();
  }, [get]);

  const onError = useCallback(
    (message?: ApiError | string | null) => {
      const msg = formatErrorMessage(message);
      errorNotify({
        key: msg || 'file/edit/error',
        message: msg || ERROR_MESSAGES.EDIT,
      });
    },
    [errorNotify],
  );

  const onSuccess = useCallback(() => {
    onSuccessEdit();

    updateFiles(packageId, objectType, media)
      .then(() => {
        backWithFallback(WaterRoutesGroundCoast.root);
      })
      .catch((error: Error) => {
        onError(error.message);
      });
  }, [backWithFallback, media, objectType, onError, onSuccessEdit, packageId]);

  const { put, isLoading: isPutLoading } = useRequest<GroundCoastListDTO>(GroundCoastApiPath.root, onSuccess, onError);

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    initialValues: editedObject,
    onSubmit: (values) => {
      void put({ ...values });
    },
    validationSchema: GroundCoastSchema,
  });

  if (isPutLoading || isDictionariesLoading || isLoading) {
    return <LoaderItem />;
  }

  return (
    <FormikProvider
      value={{
        ...formik,
        errors: createTouchedErrors(formik.errors, formik.touched),
      }}
    >
      <FieldsForm
        dictionaries={dictionaries}
        onCancel={() => {
          backWithFallback(WaterRoutesGroundCoast.root);
        }}
        onMediaChange={setMedia}
        media={media}
        uploadItemId={packageId}
        isEditMode
        editedObject={editedObject}
      />
    </FormikProvider>
  );
};
