import { Field, FormikProvider, useFormik } from 'formik';
import React, { FC } from 'react';
import { DateInput, Input, Select } from 'components/common';
import { Flex, Grid } from '@chakra-ui/react';
import { createDateFromResponse, createDateToRequestForFilter } from 'lib/create-date';
import { TDictionariesArray } from 'models/dictionaries';
import { getSelectOptionsFromDictionaryByNameWithParent } from 'features/get-select-options-from-dictionary';
import { FiltersDatepickersSchema } from 'models/schemas/filtersDatepickersSchema/FiltersDatepickersSchema';
import FilterFormControls from 'components/form/FilterFormControls';

interface FilterProps {
  isLoading?: boolean;
  isDisabled?: boolean;
  dictionaries: {
    data: TDictionariesArray;
  } | null;
  onSubmit: (values: any) => void;
}

const initialValues = {
  odorDateMin: {
    key: 'odorDate',
    operation: 'GTE',
    value: null,
  },
  odorDateMax: {
    key: 'odorDate',
    operation: 'LTE',
    value: null,
  },
  odorType: {
    key: 'odorType',
    operation: 'IN',
    value: null,
  },
  frequency: {
    key: 'frequency',
    operation: 'IN',
    value: null,
  },
  districtId: {
    key: 'districtId',
    operation: 'IN',
    value: null,
  },
  regionId: {
    key: 'regionId',
    operation: 'IN',
    value: null,
  },
  address: {
    key: 'address',
    operation: 'CONTAINS',
    value: '',
  },
  // showDisabled - value равно '' если чекбокс включен и нужно получить с Backend все значения (этот объект удалиться при отправке)
  // value равно false - если нужно показать только неудаленные записи
  showDisabled: {
    key: 'deleted',
    operation: 'EQ',
    value: false,
  },
};

export const TodayFilter: FC<FilterProps> = ({ isLoading, isDisabled, onSubmit, dictionaries, children }) => {
  const {
    values,
    getFieldMeta,
    getFieldHelpers,
    getFieldProps,
    handleReset,
    submitForm,
    setFieldValue,
    errors,
    ...formik
  } = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    validationSchema: FiltersDatepickersSchema([{ dateFromFieldName: 'odorDateMin', dateToFieldName: 'odorDateMax' }]),
    initialValues,
    onSubmit,
  });

  const odorDictionary = getSelectOptionsFromDictionaryByNameWithParent(dictionaries?.data, 'odorDictionary');
  const frequencyDictionary = getSelectOptionsFromDictionaryByNameWithParent(dictionaries?.data, 'frequencyDictionary');
  const regionsDictionary = getSelectOptionsFromDictionaryByNameWithParent(dictionaries?.data, 'regionsDictionary');
  const districtsDictionary = getSelectOptionsFromDictionaryByNameWithParent(dictionaries?.data, 'districtsDictionary');

  return (
    <FormikProvider
      value={{
        values,
        getFieldMeta,
        getFieldHelpers,
        getFieldProps,
        handleReset,
        submitForm,
        setFieldValue,
        errors,
        ...formik,
      }}
    >
      <Grid gap="20px">
        <Flex flexDirection="row" justifyContent="space-between">
          <Grid width="100%" maxWidth="1200px" gap="10px" gridTemplateColumns="repeat(4, 1fr)">
            <Field
              as={DateInput}
              name="odorDateMin.value"
              label="От"
              value={createDateFromResponse(values.odorDateMin.value)}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setFieldValue('odorDateMin.value', createDateToRequestForFilter(e.target.value));
              }}
              error={errors.odorDateMin?.value}
            />

            <Field
              as={DateInput}
              name="odorDateMax.value"
              label="До"
              value={createDateFromResponse(values.odorDateMax.value)}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setFieldValue('odorDateMax.value', createDateToRequestForFilter(e.target.value, 'end'));
              }}
              error={errors.odorDateMax?.value}
            />

            <Field as={Select} name="odorType.value" label="Характер запаха" options={odorDictionary} multiple />

            <Field
              as={Select}
              name="frequency.value"
              label="Периодичность появления запахов"
              options={frequencyDictionary}
              multiple
            />

            <Field as={Select} name="districtId.value" label="Округ" options={districtsDictionary} multiple />

            <Field as={Select} name="regionId.value" label="Район" options={regionsDictionary} multiple />

            <Field as={Input} name="address.value" label="Адрес" options={frequencyDictionary} />

            {children}
          </Grid>

          <FilterFormControls
            onReset={(e) => {
              handleReset(e);
              setTimeout(submitForm, 0);
            }}
            onSubmit={submitForm}
            isLoading={isLoading}
            isDisabled={isDisabled}
          />
        </Flex>
      </Grid>
    </FormikProvider>
  );
};
