import { SurfaceWaterFilters } from './components';
import { useBreadcrumbsSurfaceWater, useColumnsSurfaceWater } from './hooks';
import { SurfaceWaterLocales } from 'pages/data/Water/SurfaceWater/locales';
import { WaterRouteSurfaceWatersAdd, WaterRouteSurfaceWatersRoot } from 'pages/data/Water/routes';
import { useHistory } from 'react-router-dom';
import { MaterialTable } from 'features/material-table/MaterialTableMini';
import { apiPathSurfaceWater } from 'pages/data/Water/SurfaceWater/apiPath';
import { DeleteIcon, EditIcon, EyeViewIcon, ImageIcon } from 'components/icons';
import FilePreviewModalWrapper from 'components/common/FilePreviewModal/FilePreviewModalWrapper';
import {
  useAvailability,
  useDisclosure,
  useEnhancedNotification,
  useHandleDeleteData,
  useList,
  useRequest,
} from 'hooks';
import { ISurfaceWaterLoadData } from 'pages/data/Water/SurfaceWater/types';
import useFilePreviewModalWrapperData from 'components/common/FilePreviewModal/hooks/useFilePreviewModalWrapperData';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { filtersInitialValues } from 'pages/data/Water/SurfaceWater/components/filtersInitialValues';
import { FormikProvider, useFormik } from 'formik';
import { Column } from '@material-table/core';
import { addFiltersToColumns } from 'lib/utils/tableFilter';
import { usePeriodsDict } from 'features/PeriodsButtons/usePeriodsDict';
import subsystemIdsMap from 'constants/subsystemIdsMap';
import combineFilters from 'lib/utils/combineFilters';
import { downloadFileFromResponse } from 'lib/utils/files/downloadFile';
import { getSubsystemHumanReadableName, SubsystemName } from 'pages/analytics/consts/AnalyticsTableRoutes';
import { IAnalyticsItem } from 'pages/analytics/consts/types';
import { ExtensionToMimeTypeEnum } from 'lib/utils/files/getFileExtensionByMimeType';
import { HeaderWithAnalytics } from 'pages/data/components/HeaderWithAnalytics';
import { FiltersDatepickersSchema } from 'models/schemas/filtersDatepickersSchema/FiltersDatepickersSchema';
import { Modal } from 'components/common';
import { UploadFileModalContent } from 'features/UploadFileDragAndDrop/UploadFileModalContent';
import { useDictionary } from 'hooks/useDictionary';
import { getSelectOptionsFromDictionaryByName } from 'features/get-select-options-from-dictionary';
import { DEFAULT_PAGE_SIZE_NEW_TABLE } from '../../../../constants/tables';
import { FullPageWrapper } from '../../../../components/table/FullPageWrapper';

export const WaterSurfaceWaters = () => {
  useBreadcrumbsSurfaceWater();
  const { push } = useHistory();
  const { dictionaries, isDictionariesLoading } = useDictionary({ url: apiPathSurfaceWater.dict });
  const waterObjects = useMemo(
    () => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'waterObjects'),
    [dictionaries?.data],
  );
  const organizations = useMemo(
    () => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'organizations', false),
    [dictionaries?.data],
  );
  const regions = useMemo(
    () => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'regions'),
    [dictionaries?.data],
  );
  const districts = useMemo(
    () => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'districts'),
    [dictionaries?.data],
  );
  const parameters = useMemo(
    () => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'parameters'),
    [dictionaries?.data],
  );
  const sources = useMemo(
    () => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'sources'),
    [dictionaries?.data],
  );
  const [downloadReportItem, setDownloadReportItem] = useState<IAnalyticsItem>();
  const { onDownloadError } = useEnhancedNotification();
  const { isWrite, isExecute, isNoRights, allAvailable, isReadOnly, isDelete } = useAvailability(
    subsystemIdsMap.surfaceWater,
  );
  const onFileResponseSuccess = useCallback(
    (response) => {
      if (response instanceof Blob && downloadReportItem) {
        downloadFileFromResponse({
          response,
          name: `Шаблон протокола_${getSubsystemHumanReadableName(SubsystemName.waterSurfaceWater)}`,
        });
        setDownloadReportItem(undefined);
      }
    },
    [downloadReportItem],
  );

  const _onDownloadError = useCallback(() => {
    onDownloadError();
    setDownloadReportItem(undefined);
  }, [onDownloadError]);

  const { get: downloadReport } = useRequest<Blob>('', onFileResponseSuccess, _onDownloadError);

  useEffect(() => {
    if (downloadReportItem) {
      void downloadReport({
        url: `/api/reporting/v10/templates/fill/surfacewater/${downloadReportItem.id}`,
        headers: { ACCEPT: ExtensionToMimeTypeEnum.docx },
        responseType: 'blob',
      });
    }
  }, [downloadReport, downloadReportItem]);
  const {
    isLoading,
    response,
    refetch,
    toggleOrder,
    setFilters,
    onPageChange,
    filterDTO,
    downloadAsFile,
    onSearchText,
    saveFilters,
    errorText,
    selectedIndexes,
    renderOnlySelectedCheckbox,
    onSubmitFilters,
    onSelectionChange,
    materialTableProps,
  } = useList<ISurfaceWaterLoadData, keyof ISurfaceWaterLoadData>(apiPathSurfaceWater.list, {
    initialOrderFieldKey: '-datetime',
    preventFirstFetch: true,
    exportSubsystemName: 'surface-waters',
    selectionKey: 'id',
    saveFiltersState: true,
    defaultPageSize: DEFAULT_PAGE_SIZE_NEW_TABLE,
    resetDataOnNextRequest: true,
  });

  const { ModalComponent, handleOpenModal } = useHandleDeleteData({ refetch });

  const history = useHistory();

  const columns = useColumnsSurfaceWater(parameters);
  const columnsWithFilters = useMemo<Array<Column<ISurfaceWaterLoadData>>>(
    () => addFiltersToColumns({ filters: filterDTO.filters, setFilters, columns }),
    [columns, filterDTO.filters, setFilters],
  );

  const { open, ...filePreviewData } = useFilePreviewModalWrapperData();

  const responseData = response?.data;

  const _onSubmit = useCallback(
    (values) => {
      onSubmitFilters(Object.values(values));
    },
    [onSubmitFilters],
  );

  const initialValues = useMemo(() => {
    const filters = filterDTO?.filters || [];
    return combineFilters(filtersInitialValues, filters);
  }, [filterDTO]);

  const formikProps = useMemo(
    () => ({
      enableReinitialize: true,
      validateOnChange: true,
      validateOnMount: true,
      validateOnBlur: true,
      validationSchema: FiltersDatepickersSchema([
        { dateFromFieldName: 'dateTimeMin', dateToFieldName: 'dateTimeMax' },
      ]),
      initialValues,
      onSubmit: _onSubmit,
    }),
    [_onSubmit, initialValues],
  );

  const formik = useFormik(formikProps);

  const { result: periods } = usePeriodsDict(`auth/v10/user/period/${subsystemIdsMap.surfaceWater}`);

  const onReportDownload = useCallback(async (_e, item) => {
    if (!Array.isArray(item)) {
      setDownloadReportItem(item);
    }
  }, []);

  const { isOpen: isOpenLoad, onClose: onCloseLoad, onOpen: onOpenLoad } = useDisclosure();

  return (
    <FullPageWrapper>
      <FormikProvider
        value={{
          ...formik,
        }}
      >
        <HeaderWithAnalytics
          addButtonProps={{
            onClick: () => push(WaterRouteSurfaceWatersAdd),
            isDisabled: !isWrite
          }}
          active="main-data"
          title={SurfaceWaterLocales.title}
          rootPath={WaterRouteSurfaceWatersRoot}
          mainDataTitle="Протоколы сторонних организаций"
          analyticsDataTitle="Протоколы аналитической лаборатории"
          onOpenLoad={onOpenLoad}
          allAvailable={allAvailable}
          isWrite={isWrite}
          isReadonly={isReadOnly}
          isExecute={isExecute}
          isNoRights={isNoRights}
        >
          <SurfaceWaterFilters
            filters={filterDTO?.filters || []}
            periods={periods}
            regions={regions}
            waterObjects={waterObjects}
            organizations={organizations}
            districts={districts}
            sources={sources}
            formik={formik}
            onSubmit={_onSubmit}
            isLoading={isDictionariesLoading}
          >
            {renderOnlySelectedCheckbox}
          </SurfaceWaterFilters>
        </HeaderWithAnalytics>
      </FormikProvider>
      <MaterialTable
        isNotExecute={isExecute}
        saveFilters={saveFilters}
        filterDTO={filterDTO}
        data={responseData}
        meta={response?.meta}
        columns={columnsWithFilters}
        toggleOrder={toggleOrder}
        onPageChange={onPageChange}
        options={{
          search: true,
        }}
        isLoading={isLoading}
        onSearch={onSearchText}
        actions={[
          {
            position: 'row',
            action: (row) => ({
              icon: () => <EditIcon />,
              onClick: (_e, item: any) => {
                history.push(`${WaterRouteSurfaceWatersRoot}/edit/${item.id}`, item);
              },
              hidden: row.laboratory === true || !isWrite || !row.isEditable,
            }),
          },
          {
            position: 'row',
            action: (row) => ({
              icon: () => <DeleteIcon />,
              onClick: (_e, item: any) => {
                handleOpenModal({ url: `${apiPathSurfaceWater.root}/${item.id}` });
              },
              hidden: row.laboratory === true || !isDelete || !row.isEditable,
            }),
          },
          {
            position: 'row',
            action: (row) => ({
              icon: () => <ImageIcon />,
              onClick: () => open(row.id, row.objectType),
              tooltip: 'Показать изображения',
              hidden: !row.objectType || row.laboratory === true,
            }),
          },
          {
            position: 'row',
            action: (row) => ({
              icon: () => <EyeViewIcon />,
              onClick: onReportDownload,
              tooltip: 'Скачать',
              hidden: row.laboratory === null || row.laboratory === false,
            }),
          },
        ]}
        downloadAsFile={downloadAsFile}
        selectedIndexes={selectedIndexes}
        onSelectionChange={onSelectionChange}
        errorText={errorText}
        {...materialTableProps}
        showTotalCount
      />

      <FilePreviewModalWrapper {...filePreviewData} />

      {ModalComponent}

      <Modal isOpen={isOpenLoad} onClose={onCloseLoad} header="Загрузка данных из файла">
        <UploadFileModalContent
          createTypesPath={() => '/water/v10/water/surfacewater/types'}
          createUploadPath={() => '/water/v10/water/surfacewater/apply'}
          onClose={onCloseLoad}
          refetch={refetch}
        />
      </Modal>
    </FullPageWrapper>
  );
};
