import moment from 'moment';

export type FilterPresetValue = 'today' | 'last_two_days' | 'last_week' | 'last_month';

const PRESET_MAP = {
  today: moment(new Date(Date.now())).startOf('day'),
  last_two_days: moment(new Date(Date.now())).subtract(1, 'day').startOf('day'),
  last_week: moment(new Date(Date.now())).startOf('isoWeek'),
  last_month: moment(new Date(Date.now())).startOf('month'),
};

export const getStartEndDatesByPreset = (preset: FilterPresetValue) => {
  const endDate = moment().format();
  const startDate = PRESET_MAP[preset].format('YYYY-MM-DDTHH:mm:ss');

  return {
    startDate,
    endDate,
  };
};
