import { Field, useFormikContext } from 'formik';
import { Checkbox, DateInput, Input, Select, SelectOption } from 'components/common';
import isRequiredInSchema from 'lib/utils/forms/isReqiuredInSchema';
import React, { ChangeEvent, Fragment } from 'react';
import { createDateToRequestWithTimezone } from 'lib/create-date';
import { get } from 'lodash';
import { Box, Grid, Heading } from '@chakra-ui/react';
import {
  AsyncAddressSelect,
  IAsyncAddressSelectOnChange,
} from 'components/common/AsyncAddressSelect/AsyncAddressSelect';

export interface IRenderFieldInterface<T> {
  name: keyof T;
  options?: SelectOption[];
  isDisabled: boolean;
  type?: 'input' | 'numberInput' | 'select' | 'dateinput' | 'datetimeinput' | 'asyncAddressSelect' | 'checkbox';
  props?: {
    asyncSelectOnChange?: (event: IAsyncAddressSelectOnChange) => void
    dateFormatter?: (inp: string) => string | null
    error?: string
  };
}

export interface IRenderGroupInterface<T> {
  group: boolean;
  name: string;
  children: IRenderFieldInterface<T>[];
}

interface IUseFormField {
  schema?: any;
  labelLocalization: Record<string, string>;
}

export type IRenderFieldOrGroup<T> = IRenderGroupInterface<T> | IRenderFieldInterface<T>;

export function useFormField<T>({ schema, labelLocalization }: IUseFormField) {
  const { errors, setFieldTouched, setFieldValue, values } = useFormikContext<T>();

  const renderField: (options: IRenderFieldInterface<T>) => React.ReactNode = ({
    name,
    isDisabled,
    options,
    type = 'input',
    props = {},
  }) => {
    const commonProps = {
      label: get(labelLocalization, name),
      name,
      error: props.error || errors?.[name],
      isDisabled,
      isRequired: isRequiredInSchema(schema, String(name)),
    };

    const dateFormatter = props.dateFormatter || createDateToRequestWithTimezone;

    switch (type) {
      case 'input':
        return <Field as={Input} {...commonProps} />;
      case 'numberInput':
        return <Field as={Input} {...commonProps} type="number" />;
      case 'dateinput':
        return (
          <Field
            as={DateInput}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setFieldValue(String(name), dateFormatter(e.target.value));
              setTimeout(() => setFieldTouched(String(name)), 0);
            }}
            {...commonProps}
          />
        );
      case 'datetimeinput':
        return (
          <Field
            as={DateInput}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setFieldValue(String(name), dateFormatter(e.target.value));
              setTimeout(() => setFieldTouched(String(name)), 0);
            }}
            showTimeInput
            {...commonProps}
          />
        );
      case 'select':
        return <Field
          as={Select}
          options={options}
          onFocus={() => setFieldTouched(String(name))}
          {...commonProps}
        />;
      case 'asyncAddressSelect':
        return <Field
          as={AsyncAddressSelect}
          {...commonProps}
          onChange={props.asyncSelectOnChange}
        />;
      case 'checkbox':
        return <Field
          as={Checkbox}
          {...commonProps}
          checked={values[name]}
          width="100%"
        />;
    }
  };

  const renderGroup: (options: IRenderGroupInterface<T>) => React.ReactNode = ({ name, children }) => (
    <Box my={2}>
      <Heading mb={2} size="sm">
        {name}
      </Heading>

      <Grid gap={2} templateColumns="repeat(2, 1fr)">
        {children.map((field, index) => <Fragment key={index}>{renderField(field)}</Fragment>)}
      </Grid>
    </Box>
  );

  const render: (entity: IRenderFieldOrGroup<T>) => React.ReactNode = (entity) => {
    if ('group' in entity) {
      return renderGroup(entity);
    }

    return renderField(entity);
  };

  return {
    renderField,
    renderGroup,
    render,
  };
}
