import { Button } from 'components/common';
import React from 'react';
import { IPeriod } from './types';
import { IFilterItem } from 'models';
import { Flex } from '@chakra-ui/react';

interface IProps {
  periods: IPeriod[] | null;
  submitPeriod: (id: string) => void;
  periodId: IFilterItem['value']; // string | null | undefined;
}

export const PeriodsButtons = ({ periods, submitPeriod, periodId }: IProps) => (
  <Flex flexDirection="row" mt={1} flexWrap="wrap" gap="20px" rowGap="4px">
    {periods?.map(({ name, id }: IPeriod) => (
      <React.Fragment key={id}>
        <Button
          height="20px"
          minWidth="auto"
          px={periodId === id ? 2 : 0}
          py={1}
          variant={periodId === id ? 'outline' : 'ghost'}
          color="PrimaryButton.500"
          onClick={() => submitPeriod(id)}
        >
          {name}
        </Button>
      </React.Fragment>
    ))}
  </Flex>
);
