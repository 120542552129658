import { ASKZA as ASKZACommon } from 'components/common/ASKZACommon/ASKZA';
import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import appLocale from 'constants/appLocale';
import React from 'react';
import subsystemIdsMap from 'constants/subsystemIdsMap';

export const Msz4: React.FC = () => {
  useUpdateBreadcrumbs([
    { breadcrumb: appLocale.common.data, path: '/data' },
    {
      breadcrumb: appLocale.subsystems.meteo,
      path: '/data/meteo',
    },
    {
      breadcrumb: 'Метеоданные',
      path: '/data/meteo/data',
    },
    {
      breadcrumb: 'Данные MCЗ-4',
      path: '/data/meteo/data/msz4',
    },
  ]);

  return (
    <ASKZACommon
      title="Данные MCЗ-4"
      rootPath="/data/meteo/data/msz4"
      apiRootPath="/meteo/v10/meteo/msz4"
      askzaType="8"
      types={[
        {
          value: '8',
          name: 'Набор параметров Метеостанции МСЗ-4 (Метео)',
        },
      ]}
      exportSubsystemName="meteo_msz4"
      subsystemId={subsystemIdsMap.meteoMSZ4}
    />
  );
};
