import { Filter } from 'features/Filter';
import { createInitialValuesToFormik, createValuesToRequest } from 'features/Filter/utils';
import React, { useMemo } from 'react';
import { IFilterItem, TFilterDTO, TFiltersArray } from 'models';
import combineFilters from 'lib/utils/combineFilters';
import { transformFiltersArray } from 'features/Filter/transformFiltersArray';
import appLocale from 'constants/appLocale';

type FilterListInitialValues = Record<'deleted',
  TFilterDTO<'deleted'>['filters'][number]>;

const initialValues: FilterListInitialValues = {
  deleted: {
    key: 'deleted',
    operation: 'EQ',
    value: false,
  },
};

interface FilterProps {
  isLoading: boolean;
  onSubmit: (values: TFiltersArray) => TFiltersArray;
  filters: IFilterItem[];
}

const initialValuesToFormik = createInitialValuesToFormik(initialValues);

function NSIPdkConcentrationFilter({
  isLoading,
  onSubmit,
  filters,
}: React.PropsWithChildren<FilterProps>) {
  const presetValues = useMemo<{ key: string; value: IFilterItem['value'] }[]>(() => {
    const preset: { key: string; value: IFilterItem['value'] }[] = [];
    const initialFilters = combineFilters(initialValues, filters);
    Object.keys(initialFilters)
      .forEach((key) => {
        preset.push({ key: `${key}`, value: initialFilters[key].value });
      });
    return preset;
  }, [filters]);

  return (
    <Filter<typeof initialValuesToFormik>
      isLoading={isLoading}
      isDisabled={isLoading}
      onSubmit={(values) => {
        const v = createValuesToRequest(initialValues, values);
        const filters = transformFiltersArray<TFilterDTO<'deleted'>['filters']>(
          Object.values(v),
        );
        return onSubmit(filters);
      }}
      initialValues={initialValuesToFormik}
      mainFields={[
        {
          type: 'CHECKBOX',
          name: 'deleted',
          label: appLocale.common.showDeleted,
        },
      ]}
      presetValues={presetValues}
    />
  );
}

export { NSIPdkConcentrationFilter };
