import { RenderTree } from 'features/WithSideBar/types';
import appLocale from 'constants/appLocale';
import subsystemIdsMap from 'constants/subsystemIdsMap';
import { Masksh } from '../../../home/icons';

export const MASKSHRoot = '/data/masksh';

export const MASKSHRootMonitoring = `${MASKSHRoot}/monitoring`;

export const MASKSHTechnical = `${MASKSHRootMonitoring}/technical`;
export const MASKSHTechnicalPackages = `${MASKSHTechnical}/packages`;

export const MASKSHNoise = `${MASKSHRootMonitoring}/noise`;
export const MASKSHNoisePackages = `${MASKSHNoise}/packages`;
export const MASKSHNoiseAdd = `${MASKSHNoise}/add`;
export const MASKSHNoiseUpload = `${MASKSHNoise}/upload`;
export const MASKSHNoiseEdit = `${MASKSHNoise}/edit`;

export const MASKSHMeteo = `${MASKSHRootMonitoring}/meteo`;
export const MASKSHMeteoPackages = `${MASKSHMeteo}/packages`;
export const MASKSHMeteoAdd = `${MASKSHMeteo}/add`;
export const MASKSHMeteoUpload = `${MASKSHMeteo}/upload`;
export const MASKSHMeteoEdit = `${MASKSHMeteo}/edit`;

export const MASKSHInstructions = `${MASKSHRoot}/instructions`;
export const MASKSHInstructionsAdd = `${MASKSHInstructions}/add`;
export const MASKSHInstructionsEdit = `${MASKSHInstructions}/edit/:packageId`;
export const MASKSHInstructionsView = `${MASKSHInstructions}/view/:packageId`;

export const MASKSHApiRoot = '/noise/v10';

export const MASKSHApiNoiseList = `${MASKSHApiRoot}/list/noise/values`;
export const MASKSHApiNoisePackagesList = `${MASKSHApiRoot}/list/noise/packages`;
export const MASKSHApiPackageNoise = `${MASKSHApiRoot}/package/noise`;

export const MASKSHApiMeteoList = `${MASKSHApiRoot}/list/meteo/values`;
export const MASKSHApiMeteoPackagesList = `${MASKSHApiRoot}/list/meteo/packages`;
export const MASKSHApiPackageMeteo = `${MASKSHApiRoot}/package/meteo`;

export const MASKSHApiInstructionsList = `${MASKSHApiRoot}/instruction/list`;
export const MASKSHApiInstructionsDictionaries = `${MASKSHApiRoot}/dictionaries`;
export const MASKSHApiInstruction = `${MASKSHApiRoot}/instruction`;

export const MASKSHApiRootPackage = '/noise/v10/package';
export const MASKSHApiRootPackageNoise = `${MASKSHApiRootPackage}/noise`;
export const MASKSHApiPackageInstructionCreate = '/noise/v10/instruction/create';
export const MASKSHApiPackageInstruction = '/noise/v10/instruction';
export const MASKSHApiRootPackageMeteo = `${MASKSHApiRootPackage}/meteo`;
export const MASKSHApiPackageMeteoCreate = `${MASKSHApiRootPackageMeteo}/create`;
export const MASKSHApiPackageNoiseCreate = `${MASKSHApiRootPackageNoise}/create`;

export const MASKSHApiTechnicalList = `${MASKSHApiRoot}/list/tech/values`;
export const MASKSHApiTechnicalPackagesList = `${MASKSHApiRoot}/list/tech/packages`;

export const getMASKSHDictionary = (type: 'meteo' | 'noise' | 'tech') => `${MASKSHApiRoot}/dictionaries/${type}`;

export const maskshMenu: RenderTree = {
  id: MASKSHRoot,
  url: MASKSHRoot,
  name: appLocale.subsystems.masksh,
  subsystemId: subsystemIdsMap.maskshRoot,
  homepageIcon: <Masksh />,
  children: [
    {
      id: MASKSHRootMonitoring,
      url: MASKSHNoisePackages,
      name: 'Данные мониторинга',
      subsystemId: subsystemIdsMap.masksh,
    },
    {
      id: MASKSHInstructions,
      url: MASKSHInstructions,
      name: 'Поручения',
      subsystemId: subsystemIdsMap.masksh,
    },
  ],
};
