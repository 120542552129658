import { NSIReagentsListItem } from 'pages/dictionaries/NSIReagents/types';
import locationFormTabInitialEmptyValues from 'components/common/LocationFormTab/locationFormTabInitialEmptyValues';

const initialNSIReagentsSitesEmptyValues: Partial<NSIReagentsListItem> = {
  number: '',
  name: '',
  greenAreaTypeId: null,
  greenAreaLocationId: null,
  pgrAreaTypeId: null,
  status: null,

  ...locationFormTabInitialEmptyValues,
};

export default initialNSIReagentsSitesEmptyValues;
