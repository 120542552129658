import { AnalyticsData } from 'pages/data/analytics-data';
import subsystemIdsMap from 'constants/subsystemIdsMap';
import { ReagentsWashoutRootPath } from 'pages/data/PGR';

export const WashoutsAnalytics = () => (
  <AnalyticsData
    title="Мониторинг воздействия ПГР смывы"
    rootPath={ReagentsWashoutRootPath}
    subsystemId={subsystemIdsMap.pgrWashouts}
    mainDataTitle="Данные"
    analyticsDataTitle="Протоколы аналитической лаборатории"
  />
);
