import React, { useEffect, useMemo, useState } from 'react';
import { Checkbox, Input, Select } from 'components/common';
import { Flex, Grid, GridItem } from '@chakra-ui/react';
import { Field, useFormikContext } from 'formik';
import { DictOptions, IncidentValues } from '../../../consts/types';
import { TDictionaryItemDictionary } from 'models';

interface Props {
  incidentTypes: DictOptions[];
  areas: DictOptions[];
  districts: DictOptions[];
  noiseSourceTypeId: TDictionaryItemDictionary[];
  odorSourceTypeId: TDictionaryItemDictionary[];
  sources: DictOptions[];
  odorTypes: DictOptions[];
  index: number;
  citizenAddress: string;
  errors: any;
  isWrite?: boolean
}

export const IncidentForms: React.FC<Props> = ({
  incidentTypes,
  areas,
  sources,
  districts,
  odorTypes,
  index,
  noiseSourceTypeId,
  odorSourceTypeId,
  citizenAddress,
  errors,
  isWrite,
}) => {
  const { values, setFieldValue, touched } = useFormikContext<IncidentValues>();
  const isNoise = incidentTypes.find((type) => type.value === values.incidents[index].incidentTypeId)?.name === 'Шум';

  const [isNoiseTheme, setIsNoiseTheme] = useState(
    incidentTypes.find((type) => type.value === values.incidents[index].incidentTypeId)?.name === 'Шум',
  );

  const filteredDistricts = useMemo(
    () =>
      (values.incidents?.[index]?.incidentAreaId
        ? districts.filter((item) => item.parent === values.incidents?.[index]?.incidentAreaId)
        : districts),
    [districts, index, values.incidents],
  );

  useEffect(() => {
    setIsNoiseTheme(isNoise);
  }, [isNoise]);

  return (
    <Flex justifyContent="start">
      <Grid
        marginLeft={2}
        marginBottom={3}
        marginTop={5}
        gap={3}
        templateRows="repeat(8, 1fr)"
        templateColumns="repeat(3, 1fr)"
      >
        <GridItem rowStart={1} rowEnd={1} colStart={1} colEnd={4}>
          <Field
            as={Select}
            label="Тема обращения"
            name={`incidents[${index}].incidentTypeId`}
            options={incidentTypes}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const isNoiseTheme = incidentTypes.find((type) => type.value === e.target.value)?.name === 'Шум';
              setFieldValue(`incidents[${index}].incidentTypeId`, e.target.value);
              if (isNoiseTheme) {
                setFieldValue(`incidents[${index}].incidentOdorTypeId`, '');
              }
              setFieldValue(`incidents[${index}].incidentNoiseSourceTypeId`, '');
              setFieldValue(`incidents[${index}].incidentOdorSourceTypeId`, '');
              setIsNoiseTheme(isNoiseTheme);
            }}
            error={touched?.incidents?.[index]?.incidentTypeId && errors.incidents?.[index]?.incidentTypeId}
            isDisabled={!isWrite}
          />
        </GridItem>
        <GridItem rowStart={2} rowEnd={2} colStart={1} colEnd={2}>
          <Field
            as={Select}
            label="Округ"
            name={`incidents[${index}].incidentAreaId`}
            options={areas}
            error={touched?.incidents?.[index]?.incidentAreaId && errors.incidents?.[index]?.incidentAreaId}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              setFieldValue(`incidents[${index}].incidentAreaId`, e.target.value);
              setFieldValue(`incidents[${index}].incidentDistrictId`, '');
            }}
            isDisabled={!isWrite}
          />
        </GridItem>
        <GridItem rowStart={2} rowEnd={2} colStart={2} colSpan={1}>
          <Field
            as={Select}
            label="Район"
            options={filteredDistricts}
            name={`incidents[${index}].incidentDistrictId`}
            error={touched?.incidents?.[index]?.incidentDistrictId && errors.incidents?.[index]?.incidentDistrictId}
            isDisabled={!filteredDistricts.length || !isWrite}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              setFieldValue(`incidents[${index}].incidentDistrictId`, e.target.value);
              if (e.target.value) {
                setFieldValue(
                  `incidents[${index}].incidentAreaId`,
                  filteredDistricts.find((item) => item.value === e.target.value)?.parent,
                );
              }
            }}
          />
        </GridItem>
        <GridItem rowStart={3} rowEnd={3} colStart={1} colEnd={2}>
          <Field
            as={Input}
            label="Широта"
            name={`incidents[${index}].incidentLatitude`}
            error={touched?.incidents?.[index]?.incidentLatitude && errors.incidents?.[index]?.incidentLatitude}
            isDisabled={!isWrite}
          />
        </GridItem>
        <GridItem rowStart={3} rowEnd={3} colStart={2} colSpan={1}>
          <Field
            as={Input}
            label="Долгота"
            name={`incidents[${index}].incidentLongitude`}
            error={touched?.incidents?.[index]?.incidentLongitude && errors.incidents?.[index]?.incidentLongitude}
            isDisabled={!isWrite}
          />
        </GridItem>
        <GridItem rowStart={4} rowEnd={4} colStart={1} colSpan={4}>
          <Field
            as={Input}
            label="Адрес происшествия"
            name={`incidents[${index}].incidentAddress`}
            error={touched?.incidents?.[index]?.incidentAddress && errors?.incidents?.[index]?.incidentAddress}
            isDisabled={!isWrite}
          />
        </GridItem>
        <GridItem rowStart={5} rowEnd={5} colStart={1} colSpan={4}>
          <Field
            as={Checkbox}
            label="Адрес происшествия совпадает с адресом заявителя"
            checked={citizenAddress === values.incidents[index].incidentAddress}
            onChange={(e: React.BaseSyntheticEvent) => {
              setFieldValue(`incidents[${index}].incidentAddress`, e.target.checked ? citizenAddress : '');
            }}
            isDisabled={!isWrite}
          />
        </GridItem>
        <GridItem rowStart={6} rowEnd={6} colStart={1} colSpan={4}>
          <Field
            as={Select}
            label="Тип источника"
            options={isNoiseTheme ? noiseSourceTypeId : odorSourceTypeId}
            name={
              isNoiseTheme
                ? `incidents[${index}].incidentNoiseSourceTypeId`
                : `incidents[${index}].incidentOdorSourceTypeId`
            }
            error={
              isNoiseTheme
                ? errors?.incidents?.[index]?.incidentNoiseSourceTypeId
                : errors?.incidents?.[index]?.incidentOdorSourceTypeId
            }
            isDisabled={!isWrite}
          />
        </GridItem>
        <GridItem rowStart={7} rowEnd={7} colStart={1} colSpan={4}>
          <Field
            as={Select}
            label="Тип запаха"
            isDisabled={isNoiseTheme || !isWrite}
            options={odorTypes}
            name={`incidents[${index}].incidentOdorTypeId`}
          />
        </GridItem>
        <GridItem rowStart={8} rowEnd={8} colStart={1} colSpan={4}>
          <Field
            as={Select}
            isDisabled={isNoiseTheme || !isWrite}
            label="Предполагаемый источник"
            options={sources}
            name={`incidents[${index}].incidentSourceId`}
            error={errors?.incidents?.[index]?.incidentSourceId}
          />
        </GridItem>
      </Grid>
      <Flex flexDirection="column">
        <Field
          as={Checkbox}
          label="Территории, включенные в план рейдов"
          name={`incidents[${index}].incidentPlanningSign`}
          checked={values.incidents[index].incidentPlanningSign}
          isDisabled={!isWrite}
        />
        <Field
          as={Checkbox}
          label="Реновация"
          name={`incidents[${index}].incidentRenovationSign`}
          checked={values.incidents[index].incidentRenovationSign}
          isDisabled={!isWrite}
        />
        <Field
          as={Checkbox}
          label="Повторная жалоба"
          name={`incidents[${index}].incidentRepetitionSign`}
          checked={values.incidents[index].incidentRepetitionSign}
          isDisabled={!isWrite}
        />
        <Field
          as={Checkbox}
          label="Рейд не нужен"
          name={`incidents[${index}].incidentNoNeedingSign`}
          checked={values.incidents[index].incidentNoNeedingSign}
          isDisabled={!isWrite}
        />
        <Field
          as={Checkbox}
          label="Перенаправление"
          name={`incidents[${index}].incidentRedirectionSign`}
          checked={values.incidents[index].incidentRedirectionSign}
          isDisabled={!isWrite}
        />
        <Field
          as={Checkbox}
          label="Рейд был"
          name={`incidents[${index}].incidentCompletionSign`}
          checked={values.incidents[index].incidentCompletionSign}
          isDisabled={!isWrite}
        />
        <Field
          as={Checkbox}
          label="НМУ"
          name={`incidents[${index}].incidentNmuSign`}
          checked={values.incidents[index].incidentNmuSign}
          isDisabled={!isWrite}
        />
      </Flex>
    </Flex>
  );
};
