import { DateInput, Select } from 'components/common';
import { Grid, GridItem } from '@chakra-ui/react';
import React from 'react';
import { Field, useFormikContext } from 'formik';
import { IFilterItem, TDictionariesArray } from 'models';
import appLocale from 'constants/appLocale';
import { createUTCTime } from 'lib/create-date';
import { getSelectOptionsFromDictionaryByName } from 'features/get-select-options-from-dictionary';
import FilterFormControls from 'components/form/FilterFormControls';

interface IProps {
  dictionaries: { data: TDictionariesArray<string, string, string, string> } | null;
}

export const DataEntryLogFormFields = ({ dictionaries }: IProps) => {
  const { submitForm, handleReset, values, setFieldValue, errors } = useFormikContext<{ [p: string]: IFilterItem }>();
  const levels = getSelectOptionsFromDictionaryByName(dictionaries?.data, 'levels');
  const subsystems = getSelectOptionsFromDictionaryByName(dictionaries?.data, 'subsystems');
  const functions = getSelectOptionsFromDictionaryByName(dictionaries?.data, 'functions');

  return (
    <>
      <Grid gap={5} templateColumns="repeat(5, 1fr)">
        <Field
          as={DateInput}
          name="dateTimeMin.value"
          label={appLocale.common.from}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setFieldValue('dateTimeMin.value', createUTCTime(e.target.value))
          }
          showTimeInput
          error={errors.dateTimeMin?.value}
        />

        <Field
          as={DateInput}
          name="dateTimeMax.value"
          label={appLocale.common.to}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setFieldValue('dateTimeMax.value', createUTCTime(e.target.value))
          }
          showTimeInput
          error={errors.dateTimeMax?.value}
        />

        <Field
          as={Select}
          name="subsystem.value"
          label="Подсистема"
          options={subsystems}
          value={values.subsystem.value}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setFieldValue('subsystem.value', e.target.value);
          }}
        />

        <Field
          as={Select}
          name="loglevel.value"
          label="Уровень логирования"
          options={levels}
          value={values.loglevel.value}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setFieldValue('loglevel.value', e.target.value);
          }}
        />

        <Field
          as={Select}
          name="function.value"
          label="Действие"
          options={functions}
          value={values.function.value}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setFieldValue('function.value', e.target.value);
          }}
        />

        <GridItem rowStart={1} colStart={6}>
          <FilterFormControls
            onReset={(e) => {
              handleReset(e);
              setTimeout(submitForm, 0);
            }}
            onSubmit={submitForm}
          />
        </GridItem>
      </Grid>
    </>
  );
};
