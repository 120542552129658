import { Field, FormikProvider, useFormik } from 'formik';
import React, { useEffect, useMemo } from 'react';
import { Flex, Grid } from '@chakra-ui/react';
import { ContentContainer, DateInput, Select } from 'components/common';
import { createDateFromResponse, createDateToRequestForFilter } from 'lib/create-date';
import {
  getSelectOptionsFromDictionaryByName,
  getSelectRichOptionsFromDictionaryByName,
} from 'features/get-select-options-from-dictionary';
import appLocale from 'constants/appLocale';
import { IFilterItem, TDictionariesArray } from 'models';
import { FiltersDatepickersSchema } from 'models/schemas/filtersDatepickersSchema/FiltersDatepickersSchema';
import FilterFormControls from 'components/form/FilterFormControls';
import combineFilters from 'lib/utils/combineFilters';
import { MomentInput } from 'moment';

interface FilterProps {
  isLoading?: boolean;
  isDisabled?: boolean;
  dictionaries?: TDictionariesArray;
  onSubmit: (values: any) => void;
  withoutSubsystemFilter?: boolean;
  filters: IFilterItem[];
}

const initialValues: Record<string, IFilterItem<string>> = {
  dateTimeMin: {
    key: 'date',
    operation: 'GTE',
    value: null,
  },
  dateTimeMax: {
    key: 'date',
    operation: 'LTE',
    value: null,
  },
  districtId: {
    key: 'districtId',
    operation: 'EQ',
    value: null,
  },
  regionId: {
    key: 'regionId',
    operation: 'EQ',
    value: null,
  },
  subsystemId: {
    key: 'subsystemId',
    operation: 'EQ',
    value: null,
  },
  // showDisabled - value равно '' если чекбокс включен и нужно получить с Backend все значения (этот объект удалиться при отправке)
  // value равно false - если нужно показать только неудаленные записи
  showDisabled: {
    key: 'deleted',
    operation: 'EQ',
    value: false,
  },
};

const AnalyticsTableFilter = ({
  isLoading,
  isDisabled,
  onSubmit,
  dictionaries,
  withoutSubsystemFilter,
  filters,
}: FilterProps) => {
  const {
    values,
    errors,
    getFieldMeta,
    getFieldHelpers,
    getFieldProps,
    handleReset,
    submitForm,
    setFieldValue,
    ...formik
  } = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    validationSchema: FiltersDatepickersSchema([{ dateFromFieldName: 'dateTimeMin', dateToFieldName: 'dateTimeMax' }]),
    initialValues,
    onSubmit,
  });

  useEffect(() => {
    const initialFilters = combineFilters(initialValues, filters);
    Object.keys(initialFilters).forEach((key) => {
      setFieldValue(`${key}.value`, initialFilters[key].value);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const subsystems = useMemo(
    () =>
      getSelectRichOptionsFromDictionaryByName(dictionaries, 'subsystems').map((item) => ({
        ...item,
        name: `${item.name} - ${item.module}`,
      })),
    [dictionaries]
  );

  const districts = useMemo(() => getSelectOptionsFromDictionaryByName(dictionaries, 'districts'), [dictionaries]);

  const regionIds = useMemo(() => {
    const dict = getSelectRichOptionsFromDictionaryByName(dictionaries, 'regions');

    if (values.districtId.value) {
      return dict.filter((el) => el.parent === values.districtId.value);
    }

    return dict;
  }, [dictionaries, values.districtId.value]);

  return (
    <ContentContainer margin={0} padding={0}>
      <FormikProvider
        value={{
          values,
          errors,
          getFieldMeta,
          getFieldHelpers,
          getFieldProps,
          handleReset,
          submitForm,
          setFieldValue,
          ...formik,
        }}
      >
        <Grid gap="12px" rowGap="8px">
          <Flex flexDirection="row" justifyContent="space-between">
            <Grid gap="12px" rowGap="8px" gridTemplateColumns="repeat(2, 175px) 150px 225px 225px">
              <Field
                as={DateInput}
                name="dateTimeMin.value"
                label={appLocale.common.from}
                value={createDateFromResponse(values.dateTimeMin.value as MomentInput)}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setFieldValue('dateTimeMin.value', createDateToRequestForFilter(e.target.value));
                }}
                showTimeInput={false}
                error={errors.dateTimeMin?.value}
              />
              <Field
                as={DateInput}
                name="dateTimeMax.value"
                label={appLocale.common.to}
                value={createDateFromResponse(values.dateTimeMax.value as MomentInput)}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setFieldValue('dateTimeMax.value', createDateToRequestForFilter(e.target.value, 'end'));
                }}
                showTimeInput={false}
                error={errors.dateTimeMax?.value}
              />
              <Field
                as={Select}
                name="districtId.value"
                label={appLocale.analytics.area}
                options={districts}
                value={values.districtId.value}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setFieldValue('districtId.value', e.target.value);
                }}
                error={errors.districtId?.value}
              />
              <Field
                as={Select}
                name="regionId.value"
                label={appLocale.analytics.district}
                options={regionIds}
                value={values.regionId.value}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setFieldValue('regionId.value', e.target.value);
                }}
                error={errors.regionId?.value}
              />
              {!withoutSubsystemFilter && (
                <Field
                  as={Select}
                  name="subsystemId.value"
                  label={appLocale.analytics.subsystem}
                  options={subsystems}
                  value={values.subsystemId.value}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setFieldValue('subsystemId.value', e.target.value);
                  }}
                  error={errors.districtId?.value}
                />
              )}
            </Grid>

            <FilterFormControls
              onReset={(e) => {
                handleReset(e);
                setTimeout(() => submitForm(), 0);
              }}
              onSubmit={submitForm}
              isLoading={isLoading}
              isDisabled={isDisabled}
            />
          </Flex>
        </Grid>
      </FormikProvider>
    </ContentContainer>
  );
};

export default AnalyticsTableFilter;
