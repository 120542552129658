import { apiPathsWaterRoot } from '../apiPath';
import { askzvListViews } from '../consts';

const waterRoot = `${apiPathsWaterRoot}/water/askzv`;

export const apiPathAskzv = {
  root: waterRoot,
  measurements: `${waterRoot}/value_list`,
  packages: `${waterRoot}/list`,
  load: `${waterRoot}/load`,
  calculations: `${waterRoot}/calculations`,
  types: `${waterRoot}/types`,
  list: (view: askzvListViews) => `${waterRoot}/list/${view}`,
  dict: `${waterRoot}/dictionaries`,
  station: (stationId: string) => `${waterRoot}/dictionaries/stations/${stationId}`,
};
