import { SUCCESS_MESSAGES } from 'constants/messages';
import { useNotifications } from 'hooks/useNotifications';
import { useRequest } from 'hooks/useRequest';
import { StationForm } from 'pages/equipment/Stations/components/StationForm';
import { Station } from 'pages/equipment/Stations/types';
import { StationLayout } from 'pages/equipment/Stations/components/StationLayout';
import { StationFormContainer } from 'pages/equipment/Stations/components/StationForm/StationFormContainer';
import { useEquipmentStationsBreadcrumbs } from './hooks/useEquipmentStationsBreadcrumbs';
import { useHistory } from 'react-router-dom';
import { useDictionary } from 'hooks/useDictionary';
import { useEnhancedNotification } from '../../../hooks';

export const AddStation = () => {
  const { push } = useHistory();
  useEquipmentStationsBreadcrumbs();

  const { successNotify } = useNotifications();
  const { onErrorCreate } = useEnhancedNotification();
  const onSuccess = (data: Station | null) => {
    successNotify({
      key: 'file/edit/success',
      message: SUCCESS_MESSAGES.CREATE,
    });
    const url = data ? `/equipment/stations/edit/${data.id}/true` : '/equipment/stations';
    push(url);
  };
  const { post } = useRequest('/equipment/v10/equipments/stations', onSuccess, onErrorCreate);

  const { dictionaries } = useDictionary({ url: '/equipment/v10/equipments/stations/dictionaries' });

  const initialValues: Partial<Station> = {
    stationNumber: null,
    stationName: null,
    stationStatus: null,
    stationType: null,
    stationStartOperation: null,
    stationOwner: null,
    stationEndOperation: null,
    comment: null,
    registrationNumber: null,
  };

  return (
    <StationLayout
      title="Добавление станции"
      station={
        <StationFormContainer>
          <StationForm initialValues={initialValues} onSubmit={post} dictionaries={dictionaries} />
        </StationFormContainer>
      }
    />
  );
};
