import React, { useMemo } from 'react';
import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import {
  AddParamPath,
  IParameterItem,
  ParameterRootPath,
  paramListApiPath,
  paramsLocale,
  useParamsDictionaries,
} from './consts';
import appLocale from 'constants/appLocale';
import { NSIRootPath } from 'pages/dictionaries/const/routes';
import { MaterialTable } from 'features/material-table/MaterialTableMini';
import { useList } from 'hooks/useList';
import { useHistory } from 'react-router';
import { getSelectOptionsFromDictionaryByName } from 'features/get-select-options-from-dictionary';
import { ParametersFilter } from 'pages/dictionaries/Parameters/ParametersFilter';
import { TFiltersArray } from 'models';
import { ParametersHeader } from './ParametersHeader';
import { subsystemCodesMap } from 'constants/subsystemIdsMap';
import { useCustomRights } from 'hooks/useCustomRights';
import { FullPageWrapper } from '../../../components/table/FullPageWrapper';
import { DEFAULT_PAGE_SIZE_NEW_TABLE } from '../../../constants/tables';

export const Parameters = () => {
  const { result: dictionaries, isLoading: isDictLoading } = useParamsDictionaries();

  const { isWrite } = useCustomRights(subsystemCodesMap.nsi.params);

  const units = useMemo(() => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'units'), [dictionaries]);

  const purposes = useMemo(() => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'purposes'), [dictionaries]);

  const history = useHistory();

  useUpdateBreadcrumbs([
    { breadcrumb: appLocale.mainPage.nsi, path: NSIRootPath },
    {
      breadcrumb: paramsLocale.parameters,
      path: ParameterRootPath,
    },
  ]);

  const {
    response,
    toggleOrder,
    onPageChange,
    filterDTO,
    onSearchText,
    isLoading,
    setFilters,
    downloadAsFile,
    materialTableProps,
  } = useList<IParameterItem>(paramListApiPath, {
    saveFiltersState: true,
    defaultPageSize: DEFAULT_PAGE_SIZE_NEW_TABLE,
    resetDataOnNextRequest: true,
  });

  const columns = [
    {
      title: paramsLocale.subsystem,
      field: 'subsystemId',
      render: (row: IParameterItem) => row.subsystem || '',
    },
    {
      title: paramsLocale.module,
      field: 'moduleId',
      render: (row: IParameterItem) => row.module || '',
    },
    {
      title: paramsLocale.fullName,
      field: 'name',
    },
    {
      title: paramsLocale.name,
      field: 'shortName',
    },
    {
      title: paramsLocale.purpose,
      field: 'purpose',
      render: (row: IParameterItem) => purposes.find((item) => item.value === row.purposeId)?.name || '',
    },
    {
      title: paramsLocale.type,
      field: 'type',
    },
    {
      title: paramsLocale.unitId,
      field: 'unitName',
      render: (row: IParameterItem) => units.find((item) => item.value === row.unitId)?.name || '',
    },
    {
      title: paramsLocale.accuracy,
      field: 'accuracy',
    },
    {
      title: paramsLocale.code,
      field: 'code',
    },
    {
      title: paramsLocale.alias,
      field: 'aliases',
      render: (row: IParameterItem) => row.aliases.join(' '),
    },
  ];

  return (
    <FullPageWrapper>
      <ParametersHeader
        title={paramsLocale.parameters}
        addButtonProps={{
          onClick: () => history.push(AddParamPath),
          isDisabled: !isWrite,
        }}
      >
        <ParametersFilter
          dictionaries={dictionaries}
          isLoading={isLoading || isDictLoading}
          onSubmit={(values) => {
            const filters: TFiltersArray<keyof IParameterItem> = Object.values(values);
            setFilters(filters);
          }}
          filters={filterDTO.filters}
        />
      </ParametersHeader>
      <MaterialTable
        data={response?.data || []}
        meta={response?.meta}
        filterDTO={filterDTO}
        isLoading={isLoading || isDictLoading}
        toggleOrder={toggleOrder}
        onPageChange={onPageChange}
        columns={columns}
        options={{
          search: true,
          showTitle: true,
          toolbar: true,
        }}
        onSearch={onSearchText}
        actions={[
          {
            type: 'edit',
            onClick: (_e, item) => {
              if (!Array.isArray(item)) {
                history.push(`${ParameterRootPath}/edit/${item.parameterId}`);
              }
            },
            disabled: !isWrite,
            hidden: !isWrite,
          },
        ]}
        downloadAsFile={downloadAsFile}
        showTotalCount
        {...materialTableProps}
      />
    </FullPageWrapper>
  );
};
