import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import { ConfirmModal, SubsystemHeader } from 'components/common';
import { MaterialTable } from 'features/material-table/MaterialTableMini';
import { ERROR_MESSAGES, REMOVE_CONFIRMATION, SUCCESS_MESSAGES } from 'constants/messages';
import React, { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAvailability, useDisclosure, useList, useNotifications, useRequest } from 'hooks';
import { PropActions } from 'features/material-table/components/Actions';
import { Column } from '@material-table/core';
import { createLocalDateDDMMYYYYWithTime } from 'lib/create-date';
import { PDVFilter } from './Filter';
import { usePDVDictionaries } from './usePDVDictionaries';
import {
  getSelectOptionsFromDictionaryByName,
  getSelectOptionsFromDictionaryByNameWithTransformation
} from 'features/get-select-options-from-dictionary';
import { IPDVItem } from './types';
import { Industrial_PDV_AddPath, Industrial_PDV_Breadcrumbs, Industrial_PDV_EditPath, PDV_ApiPath } from './consts';
import { addFiltersToColumns } from 'lib/utils/tableFilter';
import { TableFilterTypeEnum } from 'components/table/filters/TableFilter';
import subsystemIdsMap from 'constants/subsystemIdsMap';
import { DEFAULT_PAGE_SIZE_NEW_TABLE } from '../../../../constants/tables';
import { FullPageWrapper } from '../../../../components/table/FullPageWrapper';

export const PDV = () => {
  const { isExecute, isWrite, isDelete } = useAvailability(subsystemIdsMap.emissions);
  useUpdateBreadcrumbs(Industrial_PDV_Breadcrumbs);
  const { push } = useHistory();
  const [removedItem, setRemovedItem] = useState<string>('');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { successNotify, errorNotify } = useNotifications();
  const { result: PDVDictionaries } = usePDVDictionaries();
  const sources = useMemo(
    () => getSelectOptionsFromDictionaryByName(PDVDictionaries?.data, 'sources'),
    [PDVDictionaries],
  );
  const parameters = useMemo(
    () => getSelectOptionsFromDictionaryByName(PDVDictionaries?.data, 'parameters'),
    [PDVDictionaries],
  );

  const industries = useMemo(
    () => getSelectOptionsFromDictionaryByNameWithTransformation(PDVDictionaries?.data, 'industries', 'title'),
    [PDVDictionaries]
  );

  const {
    isLoading,
    response,
    toggleOrder,
    setFilters,
    onPageChange,
    filterDTO,
    onSearchText,
    downloadAsFile,
    refetch,
    errorText,
    selectedIndexes,
    renderOnlySelectedCheckbox,
    onSubmitFilters,
    onSelectionChange,
    materialTableProps,
  } = useList<IPDVItem, keyof IPDVItem>(`${PDV_ApiPath}/list`, {
    initialOrderFieldKey: '-startDate',
    selectionKey: 'id',
    saveFiltersState: true,
    preventFirstFetch: true,
    defaultPageSize: DEFAULT_PAGE_SIZE_NEW_TABLE,
    resetDataOnNextRequest: true,
  });

  const onSuccess = () => {
    onClose();
    refetch();
    successNotify({
      key: 'PDV/delete/success',
      message: SUCCESS_MESSAGES.DELETE,
    });
  };
  const onError = () => {
    onClose();
    errorNotify({
      key: 'PDV/delete/error',
      message: ERROR_MESSAGES.DELETE,
    });
  };

  const { remove } = useRequest('', onSuccess, onError);

  const actions = useMemo<PropActions<IPDVItem[]>>(
    () => [
      {
        type: 'edit',
        onClick: (_e, item: any) => {
          push(`${Industrial_PDV_EditPath}/${item.id}`);
        },
        hidden: !isWrite
      },
      {
        type: 'delete',
        onClick: (_e, item: any) => {
          onOpen();
          setRemovedItem(item.id);
        },
        hidden: !isDelete
      },
    ],
    [isDelete, isWrite, onOpen, push],
  );

  const columns = useMemo<Column<IPDVItem>[]>(
    () => [
      {
        title: 'Предприятие',
        field: 'industryName',
        filterType: TableFilterTypeEnum.string,
      },
      {
        title: 'Источник',
        field: 'sourceName',
        filterType: TableFilterTypeEnum.string,
      },
      {
        title: 'Параметр',
        field: 'parameterName',
        filterType: TableFilterTypeEnum.string,
      },
      {
        title: 'Код параметра',
        field: 'parameterCode',
        filterType: TableFilterTypeEnum.string,
      },
      {
        title: 'Разовый выброс г/сек',
        field: 'oneTimeEmission',
        filterType: TableFilterTypeEnum.number,
      },
      {
        title: 'НМУ 1',
        field: 'nmu1',
        filterType: TableFilterTypeEnum.number,
      },
      {
        title: 'НМУ 2',
        field: 'nmu2',
        filterType: TableFilterTypeEnum.number,
      },
      {
        title: 'НМУ 3',
        field: 'nmu3',
        filterType: TableFilterTypeEnum.number,
      },
      {
        title: 'Общий выброс тонн/год',
        field: 'totalEmission',
        filterType: TableFilterTypeEnum.number,
      },
      {
        title: 'Погр. %',
        field: 'error',
        filterType: TableFilterTypeEnum.number,
      },
      {
        title: 'Дата и время начала',
        field: 'startDate',
        render: (row) => createLocalDateDDMMYYYYWithTime(row.startDate),
      },
      {
        title: 'Дата и время окончания',
        field: 'endDate',
        render: (row) => createLocalDateDDMMYYYYWithTime(row.endDate),
      },
    ],
    [],
  );

  const columnsWithFilters = useMemo<Array<Column<IPDVItem>>>(
    () => addFiltersToColumns({ filters: filterDTO.filters, setFilters, columns }),
    [columns, filterDTO.filters, setFilters],
  );

  return (
    <FullPageWrapper>
      <SubsystemHeader
        title="ПДВ по источникам выбросов"
        addButtonProps={{
          onClick: () => push(Industrial_PDV_AddPath),
          isDisabled: !isWrite
        }}
        filtersComponent={
          <PDVFilter
            onSubmitFilters={onSubmitFilters}
            industries={industries}
            sources={sources}
            parameters={parameters}
            filters={filterDTO.filters}
          >
            {renderOnlySelectedCheckbox}
          </PDVFilter>
        }
      />

      <MaterialTable
        isNotExecute={isExecute}
        data={response?.data}
        meta={response?.meta}
        toggleOrder={toggleOrder}
        filterDTO={filterDTO}
        onPageChange={onPageChange}
        columns={columnsWithFilters}
        actions={actions}
        isLoading={isLoading}
        setFilters={setFilters}
        options={{ selection: true }}
        onSearch={onSearchText}
        downloadAsFile={downloadAsFile}
        onSelectionChange={onSelectionChange}
        selectedIndexes={selectedIndexes}
        errorText={errorText}
        {...materialTableProps}
        showTotalCount
      />
      <ConfirmModal
        isOpen={isOpen}
        onClose={onClose}
        onClick={() =>
          remove({
            url: `${PDV_ApiPath}/${removedItem}`,
          })
        }
        header={REMOVE_CONFIRMATION}
      />
    </FullPageWrapper>
  );
};
