import React, { useEffect, useState } from 'react';
import { Button, Input, InputGroup, InputRightElement, Stack } from '@chakra-ui/react';
import { SearchOutlined } from '@material-ui/icons';
import { TFilterDTO } from 'models';
import { SelectClearIcon } from 'components/common/Select/components';
import { Form } from 'formik';

export interface Props {
  onSearch: (searchText: string) => void;
  filterDTO?: TFilterDTO;
  onClearSearch: () => void;
}

export const SearchBar = ({ onSearch, filterDTO, onClearSearch }: Props) => {
  const [value, setValue] = useState('');
  const onSubmit = () => onSearch(value);

  useEffect(() => {
    if (filterDTO?.searchString) {
      setValue(filterDTO.searchString);
    }
  }, [filterDTO]);

  return (
    <Form onSubmit={onSubmit}>
      <InputGroup size="md" height="30px" width="initial" background="#FFF">
        <Input
          pr="4.5rem"
          type="text"
          placeholder="Строка для поиска"
          inputMode="search"
          value={value}
          onChange={(e) => setValue(e.currentTarget.value)}
          height="30px"
        />
        <InputRightElement width="fit-content" height="100%" marginRight={5}>
          <Stack direction="row" spacing={2} pl={2} pr="25px" alignItems="center">
            {value && (
              <SelectClearIcon
                clearColor="red"
                onClick={() => {
                  onClearSearch();
                }}
              />
            )}
          </Stack>
        </InputRightElement>
        <InputRightElement height="30px">
          <Button
            iconSpacing={0}
            variant="ghost"
            marginRight={1}
            leftIcon={<SearchOutlined />}
            height="24px"
            // size="sm"
            type="submit"
          />
        </InputRightElement>
      </InputGroup>
    </Form>
  );
};
