import * as React from 'react';
import { useCallback, useState } from 'react';
import { ClockIcon } from 'components/icons';
import { Box, Flex } from '@chakra-ui/react';
import CustomTimeInputField from 'components/common/DateInput/components/CustomTimeInputField';
import moment from 'moment';

const CustomTimeInput: React.FC<any> = ({ onTimeChange, dateValue, showTimeInputWithSeconds }) => {
  const [h, m, s] = (dateValue ? moment(dateValue)
    .format('HH:mm:ss') : '00:00:00').split(':');
  const [hours, setHours] = useState(h);
  const [minutes, setMinutes] = useState(m);
  const [seconds, setSeconds] = useState(s);

  const addSecondsIfEnabled = useCallback(
    (time: string) => {
      if (!showTimeInputWithSeconds) return time;

      return `${time}:${seconds}`;
    },
    [seconds, showTimeInputWithSeconds],
  );

  const onHoursChange = useCallback(
    (hoursValue: string) => {
      const _hours = hoursValue.slice(0, 2);
      const time = `${_hours}:${minutes || '00'}`;
      setHours(_hours);
      onTimeChange(dateValue, addSecondsIfEnabled(time));
    },
    [addSecondsIfEnabled, dateValue, minutes, onTimeChange],
  );

  const onMinutesChange = useCallback(
    (minutesValue: string) => {
      const _minutes = minutesValue.slice(0, 2);
      const time = `${hours || '00'}:${_minutes}`;
      setMinutes(_minutes);
      onTimeChange(dateValue, addSecondsIfEnabled(time));
    },
    [addSecondsIfEnabled, dateValue, hours, onTimeChange],
  );

  const onSecondsChange = useCallback(
    (secondsValue: string) => {
      const _seconds = secondsValue.slice(0, 2);
      const time = `${hours || '00'}:${minutes || '00'}:${_seconds}`;
      setSeconds(_seconds);
      onTimeChange(dateValue, time);
    },
    [dateValue, hours, minutes, onTimeChange],
  );

  return (
    <Flex alignItems="center">
      <ClockIcon />
      <Box ml="7px" mr="5px">
        <CustomTimeInputField isHours value={hours} onChange={onHoursChange} />
      </Box>

      <CustomTimeInputField isHours={false} value={minutes} onChange={onMinutesChange} />

      {showTimeInputWithSeconds && (
        <Box ml="5px">
          <CustomTimeInputField isHours={false} value={seconds} onChange={onSecondsChange} />
        </Box>
      )}
    </Flex>
  );
};

export default CustomTimeInput;
