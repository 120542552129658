import { FormikProvider, useFormik } from 'formik';
import { IFilterItem, TDictionariesArray, TFiltersArray } from 'models';
import React from 'react';
import { FormFields } from './FormFields';
import { IUploadItem } from '../types';
import { Grid } from '@chakra-ui/react';
import { FiltersDatepickersSchema } from 'models/schemas/filtersDatepickersSchema/FiltersDatepickersSchema';

const initialValues: Record<string, IFilterItem<string>> = {
  startDate: {
    key: 'uploadDatetime',
    operation: 'GTE',
    value: '',
  },
  endDate: {
    key: 'uploadDatetime',
    operation: 'LTE',
    value: '',
  },
  reportName: {
    key: 'reportNumber',
    operation: 'EQ',
    value: '',
  },
  uploadStatusName: {
    key: 'uploadStatus',
    operation: 'EQ',
    value: '',
  },
};

interface IProps {
  dictionaries: { data: TDictionariesArray } | null;
  onSubmitFilters: (filters: TFiltersArray<keyof IUploadItem>) => void;
  children: React.ReactNode;
}

export const NODUploadLogFilter = ({ dictionaries, onSubmitFilters, children }: IProps) => {
  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    validationSchema: FiltersDatepickersSchema([{ dateFromFieldName: 'startDate', dateToFieldName: 'endDate' }]),
    initialValues,
    onSubmit: (values: any) => {
      const filters: TFiltersArray<keyof IUploadItem> = Object.values(values);
      onSubmitFilters(filters);
    },
  });
  return (
    <FormikProvider
      value={{
        ...formik,
      }}
    >
      <FormFields dictionaries={dictionaries} />
      <Grid marginTop={2}>{children}</Grid>
    </FormikProvider>
  );
};
