import React, { useCallback, useState } from 'react';
import { createTouchedErrors } from 'features/create-touched-errors';
import { FormikProvider, useFormik } from 'formik';
import { useRequest } from 'hooks/useRequest';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { PGRSoilSchema } from 'models/schemas/pgr';
import { ReagentsSoilApiDictionaries, ReagentsSoilApiRoot, ReagentsSoilRootPath } from '../../../consts';
import FieldsForm from './FieldsForm';
import { SoilDeicingItem } from '../../consts';
import { IUploadMedia } from 'components/common/FileUploadDragAndDrop/types';
import { updateFiles } from 'api/services/media';
import { useObjectType } from 'hooks/useObjectType';
import { useEnhancedNotification } from 'hooks';
import { splitTypesAndValues } from 'lib/utils/forms/valueTypeHelper';
import { useGoBackWithFallback } from 'lib/utils/goBackWithFallback';
import { useDictionary } from 'hooks/useDictionary';

export const initialEmptyValues = {
  selectionDate: moment().format(),
  address: '',
  depth: '',
  depthId: '',
  distance2Road: '',
  distance2RoadId: '',
  okrug: '',
  protocol: '',
  protocolDate: '',
  rayon: '',
  registryId: '',
  sourceName: '',
  sourceNameId: null,
  territoryType: '',
  values: [],
};

export const AddSoilDeicingReport = () => {
  const listPath = ReagentsSoilRootPath;
  const editPath = `${ReagentsSoilRootPath}/edit/row`;

  const { backWithFallback } = useGoBackWithFallback();
  const { dictionaries, isDictionariesLoading } = useDictionary({ url: ReagentsSoilApiDictionaries });
  const history = useHistory();
  const { onErrorCreate, onSuccessCreate } = useEnhancedNotification();
  const [media, setMedia] = useState<IUploadMedia[]>([]);
  const objectType = useObjectType(dictionaries);

  const onSuccess = (response: SoilDeicingItem | null) => {
    if (!response) {
      onErrorCreate();
      return;
    }

    onSuccessCreate();

    updateFiles(response.registryId, objectType, media)
      .then(() => {
        backWithFallback(`${listPath}/main-data`);
      })
      .catch((error: Error) => {
        onErrorCreate(error.message);
        history.replace(`${editPath}/${response.registryId}?openAttachmentsTab=true`, response);
      });
  };

  const { post, isLoading: isAddLoading } = useRequest<SoilDeicingItem, any>(
    ReagentsSoilApiRoot, onSuccess, onErrorCreate
  );

  const onSubmit = useCallback(
    (values: typeof initialEmptyValues) => {
      post({
        ...values,
        values: splitTypesAndValues(values.values),
      });
    },
    [post],
  );

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    initialValues: initialEmptyValues,
    onSubmit,
    validationSchema: PGRSoilSchema,
  });

  return (
    <FormikProvider
      value={{
        ...formik,
        errors: createTouchedErrors(formik.errors, formik.touched),
      }}
    >
      <FieldsForm
        dictionaries={dictionaries}
        onCancel={() => {
          backWithFallback(`${listPath}/main-data`);
        }}
        backPath={listPath}
        isLoading={isDictionariesLoading || isAddLoading}
        onMediaChange={setMedia}
        media={media}
      />
    </FormikProvider>
  );
};
