import { useMemo } from 'react';
import { FormikProvider, useFormik } from 'formik';

import { createTouchedErrors } from 'features/create-touched-errors';
import { useNotifications } from 'hooks/useNotifications';
import { useRequest } from 'hooks/useRequest';
import { SUCCESS_MESSAGES } from 'constants/messages';

import { NoisePackageFormFields } from 'pages/data/MASKSH/Data/Noise/NoisePackageForm/Form';
import { MASKSHApiPackageNoiseCreate, MASKSHNoise } from 'pages/data/MASKSH/consts';
import { useMASKSHDictionary } from 'pages/data/MASKSH/hooks';
import { initialEmptyValues } from 'pages/data/MASKSH/Data/Noise/NoisePackageForm/configs';
import { useGoBackWithFallback } from 'lib/utils/goBackWithFallback';
import { noiseSchema } from 'models/schemas/MASKSH/noiseSchema';
import { replaceEmptyStringsWithNull } from 'lib/utils/replaceEmptyStringsWithNull';
import { useEnhancedNotification } from 'hooks';

interface IProps {
  listPath?: string;
}

export const AddNoisePackageForm = ({ listPath = MASKSHNoise }: IProps) => {
  const { backWithFallback } = useGoBackWithFallback();
  const { isLoading, result } = useMASKSHDictionary('noise');
  const { successNotify } = useNotifications();
  const { onErrorCreate } = useEnhancedNotification();

  const onSuccess = () => {
    successNotify({
      key: 'noisePackage/add/success',
      message: SUCCESS_MESSAGES.CREATE,
    });
    backWithFallback(listPath);
  };

  const { post, isLoading: isPostLoading } = useRequest(MASKSHApiPackageNoiseCreate, onSuccess, onErrorCreate);

  const useFormikProps = useMemo(
    () => ({
      enableReinitialize: true,
      validateOnChange: true,
      validateOnMount: true,
      validateOnBlur: true,
      initialValues: initialEmptyValues,
      validationSchema: noiseSchema,
      onSubmit: (values: any) => {
        void post(replaceEmptyStringsWithNull(values));
      },
    }),
    [post]
  );

  const formik = useFormik(useFormikProps);

  return (
    <div>
      <FormikProvider
        value={{
          ...formik,
          errors: createTouchedErrors(formik.errors, formik.touched),
        }}
      >
        <NoisePackageFormFields listPath={listPath} isLoading={isLoading || isPostLoading} dictionaries={result} />
      </FormikProvider>
    </div>
  );
};
