import { AttachmentsTabContent, DefaultText, FormHeader, FormWrapper, LoaderItem } from 'components/common';
import { createTouchedErrors } from 'features/create-touched-errors';
import { FormikProvider, useFormik } from 'formik';
import { useRequest } from 'hooks/useRequest';
import { useHistory, useParams } from 'react-router-dom';
import { IncidentRecordingItem } from 'pages/IncidentRecording/types';
import { IncidentRecordingFormSchema } from 'models/schemas/incidentRecording/IncidentRecordingFormSchema';
import { useAvailability, useEnhancedNotification } from 'hooks';
import { IncidentRecordingFormFields } from './IncidentRecordingFormFields';
import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import appLocale from 'constants/appLocale';
import FormControls from 'components/common/FormControls';
import React, { useCallback, useEffect, useState } from 'react';
import {
  IncidentRecordingApiRoot,
  incidentRecordingBreadcrumbs,
  IncidentRecordingPaths,
} from 'pages/IncidentRecording/const';
import subsystemIdsMap from 'constants/subsystemIdsMap';
import { useObjectType } from 'hooks/useObjectType';
import { IUploadMedia } from 'components/common/FileUploadDragAndDrop/types';
import { useDictionary } from 'hooks/useDictionary';
import { updateFiles } from 'api/services/media';
import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import { useGoBackWithFallback } from 'lib/utils/goBackWithFallback';

export const initialEmptyValues: Partial<Record<keyof IncidentRecordingItem, any>> = {
  incidentDateTime: null,
  address: null,
  admAreaId: null,
  districtId: null,
  incidentTypeId: null,
  sourceTypeId: null,
  actions: '',
  description: '',
};

export const IncidentRecordingForm = () => {
  useUpdateBreadcrumbs(incidentRecordingBreadcrumbs);
  const { id } = useParams<{ id: string }>();
  const { replace } = useHistory();
  const { backWithFallback } = useGoBackWithFallback();
  const { isAvailable } = useAvailability(subsystemIdsMap.dutyOfficer);
  const [media, setMedia] = useState<IUploadMedia[]>([]);
  const { dictionaries, isDictionariesLoading } = useDictionary(
    { url: `${IncidentRecordingApiRoot}/dictionaries` },
  );
  const objectType = useObjectType(dictionaries);

  const {
    isLoading: isItemLoading,
    result,
    get,
  } = useRequest<IncidentRecordingItem>(`${IncidentRecordingApiRoot}/${id}`);

  useEffect(() => {
    if (id) {
      get();
    }
  }, [get, id]);

  const initialValues = {
    ...initialEmptyValues,
    ...result,
  };

  const { onValidationError } = useEnhancedNotification();

  const { onErrorCreate, onSuccessCreate, onErrorEdit, onSuccessEdit } = useEnhancedNotification();

  const successHandler = (result: IncidentRecordingItem | null) => {
    if (result?.id) {
      updateFiles(result?.id, objectType, media)
        .then(() => {
          if (id) {
            onSuccessEdit();
          } else {
            onSuccessCreate();
          }
          backWithFallback(IncidentRecordingPaths.root);
        })
        .catch((error: Error) => {
          if (id) {
            onErrorEdit(error.message);
          } else {
            onErrorCreate(error.message);
            replace(IncidentRecordingPaths.edit(result.id));
          }
        });
    }
  };

  const { post, put, isLoading } = useRequest<IncidentRecordingItem>(
    IncidentRecordingApiRoot,
    successHandler,
    onErrorCreate,
  );

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    initialValues,
    onSubmit: (values) => {
      if (id) {
        put(values, { url: `${IncidentRecordingApiRoot}/${id}` });
        return;
      }
      post(values);
    },
    validationSchema: IncidentRecordingFormSchema,
  });

  const onFormSubmit = useCallback(async () => {
    await formik.submitForm();
    if (Object.keys(formik.errors).length > 0) {
      onValidationError();
      Object.keys(formik.errors)
        .forEach((key) => formik.setFieldTouched(key, true));
    }
  }, [formik, onValidationError]);

  const onCancel = useCallback(() => {
    backWithFallback(IncidentRecordingPaths.root);
  }, [backWithFallback]);

  const title = `${id ? 'Редактирование' : 'Добавление'} записи`;

  const submitIsDisabled = isLoading || isDictionariesLoading || isItemLoading;

  if (isDictionariesLoading) {
    return <LoaderItem />;
  }

  return (
    <FormikProvider
      value={{
        ...formik,
        errors: createTouchedErrors(formik.errors, formik.touched),
      }}
    >
      <Tabs>
        <FormHeader
          onBackClick={() => backWithFallback(IncidentRecordingPaths.root)}
          header={title}
        >
          <TabList>
            {[
              appLocale.common.generalInformation,
              appLocale.common.attachments,
            ].map((tab, i) => (
              <Tab key={i}>
                <DefaultText cursor="pointer">{tab}</DefaultText>
              </Tab>
            ))}
          </TabList>
        </FormHeader>

        <TabPanels>
          <TabPanel>
            <IncidentRecordingFormFields
              dictionaries={dictionaries}
              onCancel={() => backWithFallback(IncidentRecordingPaths.root)}
              isEditMode={Boolean(id)}
            >
              <FormControls
                onCancel={onCancel}
                handleSubmit={onFormSubmit}
                submitIsDisabled={submitIsDisabled}
              />
            </IncidentRecordingFormFields>
          </TabPanel>

          <TabPanel>
            <FormWrapper>
              {
                isAvailable && (
                  <AttachmentsTabContent
                    objectType={objectType}
                    media={media}
                    onMediaChange={setMedia}
                    uploadItemId={id}
                  >
                    <FormControls
                      onCancel={onCancel}
                      handleSubmit={onFormSubmit}
                      submitIsDisabled={submitIsDisabled}
                    />
                  </AttachmentsTabContent>
                )
              }
            </FormWrapper>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </FormikProvider>
  );
};
