export enum ReportNames {
  MAIN = 'main',
  ATMOSPHERE = 'atmosphere',
  GARBAGE = 'garbage',
}

export interface AppealValues {
  assignmentDate: string;
  assignmentDeadline: string;
  assignmentExecution: string;
  assignmentNumber: string;
  assignmentUrl: string;
  complaintAnswerOnPaper: boolean;
  complaintCitizenAddress: string;
  complaintCitizenEmail: string;
  complaintCitizenFio: string;
  complaintCitizenPhone: string;
  complaintDate: string;
  complaintId: string;
  complaintNumber: string;
  complaintOriginId: string;
  complaintText: string;
  questions: string[];
}

export interface CitizenAppealListItem {
  assignmentExecution: string;
  assignmentNumber: string;
  complaintStatus: string;
  complaintStatusId: string;
  complaintCitizenFio: string;
  complaintOrigin: string;
  complaintOriginId: string;
  id: string;
  incidentAddress: string;
  incidentArea: string;
  incidentAreaId: string;
  incidentDistrict: string;
  incidentDistrictId: string;
  incidentLatitude: number;
  incidentLongitude: number;
  incidentNoiseType: string;
  incidentNoiseTypeId: string;
}

export interface DictOptions {
  parent?: string;
  name: string;
  value: string;
}

export interface Incident {
  incidentAddress: string;
  incidentAreaId: string;
  incidentCompletionSign: boolean;
  incidentDistrictId: string;
  incidentId: string;
  incidentLatitude: number;
  incidentLongitude: number;
  incidentNmuSign: boolean;
  incidentNoNeedingSign: boolean;
  incidentNoiseTypeId: string;
  incidentOdorTypeId: string;
  incidentPlanningSign: boolean;
  incidentRedirectionSign: boolean;
  incidentRenovationSign: boolean;
  incidentRepetitionSign: boolean;
  incidentSourceId: string | null;
  incidentTypeId: string | null;
  incidentNoiseSourceTypeId: string | null;
  incidentOdorSourceTypeId: string | null;
}

export interface IncidentValues {
  assignmentExecutor: string;
  complaintStatusId: string;
  complaintComment: string;
  complaintId: string;
  incidents: Incident[];
}

export interface ComplaintsRes {
  answerId: string;
  complaintCitizenAddress: string;
  complaintCitizenEmail: string;
  complaintCitizenFio: string;
  complaintCitizenPhone: string;
  complaintNumber: string;
  complaintDate: string;
  complaintId: string;
  complaintText: string;
}

export interface IncidentRes {
  answerComment: string;
  answerId: string;
  answerOnPaper: boolean;
  assignmentExecutor: string;
  complaintStatusId: string;
  complaintId: string;
}

interface Answer {
  answerAddress: string;
  answerAgreementDate: string;
  answerAgreementSet: string;
  answerAssignmentKind: string;
  answerDocDate: string;
  answerDocNumber: string;
  answerId: string;
  answerStoreId: string;
  answerText: string;
  answerUrl: string;
}

export interface AnswerValues {
  complaintStatusId: string;
  answers: Answer[];
  complaintAnswered: boolean;
  complaintId: string;
  answerUrl: string;
}

export interface PreparedAnswer {
  assigmentExecutorPhone: string;
  complaintId: string;
  employeeFio: string;
  employeePosition: string;
  templateAnswer: string;
  templateQuestion: string;
  templateType: 'WITH_HEADER' | 'WITHOUT_HEADER';
}

interface Question {
  questionId: string;
  questionOrderNumber: number;
  questionText: string;
}

export interface Complaint {
  answers?: Answer[];
  assignmentDate: string;
  assignmentDeadline: string;
  assignmentExecution: string;
  assignmentExecutor: string;
  assignmentNumber: string;
  complaintStatusId?: string;
  assignmentUrl: string;
  complaintAnswerOnPaper: boolean;
  complaintAnswered?: boolean;
  complaintCitizenAddress: string;
  complaintCitizenEmail: string;
  complaintCitizenFio: string;
  complaintCitizenPhone: string;
  complaintComment?: string;
  complaintDate: string;
  complaintId: string;
  complaintNumber: string;
  complaintOriginId: string;
  complaintText: string;
  incidents: Incident[];
  questions: Question[] | string[];
}
