import React from 'react';

import { AddAppealPath } from 'pages/citizenAppeal';
import { SubsystemHeader } from 'components/common';
import { useHistory } from 'react-router';

interface IProps {
  children: React.ReactNode;
  isWrite: boolean
}

export const ComplaintsHeader = ({ children, isWrite }: IProps) => {
  const history = useHistory();

  const handleAdd = () => {
    history.push(AddAppealPath);
  };
  return (
    <SubsystemHeader
      title="Учет обращений граждан"
      addButtonProps={{
        onClick: handleAdd,
        isDisabled: !isWrite
      }}
      filtersComponent={children}
    />
  );
};
