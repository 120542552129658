import { Button, Checkbox, DateInput, FormWrapper, Input, Select } from 'components/common';
import { Box, Flex, Grid } from '@chakra-ui/react';
import { Field, useFormikContext } from 'formik';
import React from 'react';
import { createDateFromResponse, createDateToRequestWithTimezone } from 'lib/create-date';
import { IApiToken } from 'pages/admin/ApiToken/types';
import appLocale from 'constants/appLocale';
import { ApiTokenType } from 'pages/admin/models/ApiTokenType';
import { TOption } from 'pages/admin/models/toption';

interface Props {
  onCancel: () => void;
  isLoading?: boolean;
}

const ApiTokenForm = ({ onCancel, isLoading }: Props) => {
  const { setFieldValue, values, errors, handleSubmit, setFieldTouched } = useFormikContext<IApiToken>();
  const types = Object.entries(ApiTokenType)
    .map((v) => ({
      value: v[0],
      name: v[1]
    } as TOption));

  return (
    <Box flexDirection="column">
      <FormWrapper>
        <Grid marginTop="30px" gap="15px">
          <Field as={Input} name="description" label="Описание" value={values.description} error={errors.description} />
          <Field
            as={DateInput}
            name="expires"
            label="Действителен до"
            value={createDateFromResponse(values.expires)}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFieldTouched('expires', true);
              setFieldValue('expires', e.target.value ? createDateToRequestWithTimezone(e.target.value) : '');
            }}
            error={errors.expires}
          />
          <Field
            as={Select}
            label="Тип токена"
            name="type"
            value={values.type}
            options={types}
            error={errors?.type}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              setFieldValue('type', e.target.value);
            }}
          />
          <Field
            as={Checkbox}
            label="Активен"
            name="active"
            checked={values.active}
          />
          <Flex mb={4}>
            <Button onClick={onCancel} marginRight="auto">
              {appLocale.actions.cancel}
            </Button>
            <Button
              onClick={() => handleSubmit()}
              variant="solid"
              disabled={Object.keys(errors).length > 0 || isLoading}
              isLoading={isLoading}
            >
              {appLocale.actions.save}
            </Button>
          </Flex>
        </Grid>
      </FormWrapper>
    </Box>
  );
};

export default ApiTokenForm;
