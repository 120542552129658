import { Actinometry } from 'pages/data/Meteo/Actinometry/Actinometry';
import { AddResult } from 'pages/data/Meteo/Actinometry/Forms/AddResult';
import { WithDidMountResetUploadFile } from 'features/UploadedFileProvider/hocs';
import { Route } from 'react-router-dom';

const MeteoActinometryRoutes = () => (
  <>
    <Route exact path="/data/meteo/data/actinometry" render={() => <WithDidMountResetUploadFile Component={Actinometry} />} />

    <Route exact path="/data/meteo/data/actinometry/add" render={() => <WithDidMountResetUploadFile Component={AddResult} />} />
  </>
);

export { MeteoActinometryRoutes };
