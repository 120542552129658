import React, { useCallback, useState } from 'react';
import { ForecastFormTypes, IForecastDateBox } from 'pages/data/Meteo/Forecast/Data/consts/types';
import {
  createTodayDateDDMMYYYY,
  createTomorrowDateDDMMYYYY,
  createYesterdayDateDDMMYYYY,
} from 'pages/data/Meteo/Forecast/Data/consts/methods';
import { useFormikContext } from 'formik';
import { Box, Flex, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { Button, FormHeader, FormWrapper, LoaderItem } from 'components/common';
import { ForecastItemsForm } from 'pages/data/Meteo/Forecast/Data/forms/ForecastItemsForm/ForecastItemsForm';
import { ForecastRootPath, MeteoDataApiRoot } from 'pages/data/Meteo/menu';
import subsystemIdsMap from 'constants/subsystemIdsMap';
import { useAvailability } from 'hooks';
import { useGoBackWithFallback } from 'lib/utils/goBackWithFallback';
import { useDictionary } from 'hooks/useDictionary';

interface IForecastFormComponent {
  forecastDate?: Date | undefined;
  isDisabled: boolean;
  isLoading?: boolean;
  editedObject?: any;
}

const dateBoxStyles = {
  border: '1px solid #ccc',
  width: '148px',
  height: '48px',
  backgroundColor: '#fff',
  display: 'flex',
  justifyContent: 'center',
  paddingLeft: '20px',
  alignItems: 'start',
};

const DateBoxComponent = ({ date, text }: IForecastDateBox) => (
  <Flex style={dateBoxStyles} flexDirection="column">
    <div>{date}</div>
    <div>{text}</div>
  </Flex>
);

const FormComponent = ({ forecastDate, isDisabled, isLoading, editedObject }: IForecastFormComponent) => {
  const { backWithFallback } = useGoBackWithFallback();
  const { isAvailable } = useAvailability(subsystemIdsMap.meteoRoot);

  const periods = ['lastDay', 'lastNight', 'today', 'tonight', 'tomorrow'];
  const defaultIndex = Object.values(ForecastFormTypes).indexOf(periods[2]);
  const [currentIndex, setCurrentTabIndex] = useState<number>(defaultIndex);

  const currentTabChange = useCallback(setCurrentTabIndex, [setCurrentTabIndex]);

  const { handleSubmit } = useFormikContext();

  const dateBoxesArray: IForecastDateBox[] = [
    {
      id: 1,
      date: `${createYesterdayDateDDMMYYYY(forecastDate)}`,
      text: 'День | Анализ',
    },
    {
      id: 2,
      date: `${createTodayDateDDMMYYYY(forecastDate)}`,
      text: 'Ночь | Анализ',
    },
    {
      id: 3,
      date: `${createTodayDateDDMMYYYY(forecastDate)}`,
      text: 'День | Прогноз',
    },
    {
      id: 4,
      date: `${createTomorrowDateDDMMYYYY(forecastDate)}`,
      text: 'Ночь | Прогноз',
    },
    {
      id: 5,
      date: `${createTomorrowDateDDMMYYYY(forecastDate)}`,
      text: 'День | Прогноз',
    },
  ];

  const { dictionaries } = useDictionary({ url: `${MeteoDataApiRoot}/dictionaries/forecast` });

  if (isLoading) {
    return <LoaderItem />;
  }
  return (
    <>
      <Flex flexDirection="column">
        <FormHeader onBackClick={() => backWithFallback(ForecastRootPath)} header="Добавление протокола" gap={4}>
          <Box>
            <Box d="flex" alignItems="start">
              <Box mr="10">
                <span>Дата протокола: </span>
                <span>{createTodayDateDDMMYYYY(forecastDate)}</span>
              </Box>
            </Box>
          </Box>
        </FormHeader>
        <FormWrapper>
          <Flex flexDirection="column">
            <Tabs defaultIndex={currentIndex} tabIndex={currentIndex} onChange={currentTabChange}>
              <TabList style={{ border: '0px' }}>
                {dateBoxesArray.map((boxTab) => (
                  <Tab key={boxTab.id} style={{ padding: '0px' }}>
                    <DateBoxComponent date={boxTab.date} text={boxTab.text} />
                  </Tab>
                ))}
              </TabList>
              <TabPanels>
                {periods.map((item, idx) => {
                  if (item === periods[currentIndex]) {
                    return (
                      <TabPanel p="0" key={idx}>
                        <ForecastItemsForm entity={item} dictionary={dictionaries} editedObject={editedObject} />
                      </TabPanel>
                    );
                  }
                  return <></>;
                })}
              </TabPanels>
            </Tabs>
            <Flex pt="10" pb="10">
              <Button
                size="lg"
                variant="outline"
                color="PrimaryButton.500"
                mr="5"
                onClick={() => backWithFallback(ForecastRootPath)}
              >
                Отмена
              </Button>
              <Button
                disabled={!isAvailable}
                size="lg"
                variant="solid"
                colorScheme="PrimaryButton"
                isDisabled={!isDisabled}
                onClick={() => handleSubmit()}
              >
                Сохранить
              </Button>
            </Flex>
          </Flex>
        </FormWrapper>
      </Flex>
    </>
  );
};

export default React.memo(FormComponent);
