import { useEffect } from 'react';
import { useRequest } from 'hooks';
import { apiAuthPaths } from 'api/services/auth';
import { RightItem } from 'pages/admin/models/RightItem';

export const useGetRightsBySubsystem = (subsystemId?: string) => {
  const {
    get,
    isLoading: isRightLoading,
    result: userRights,
  } = useRequest<RightItem[] | null>(`${apiAuthPaths.user.right}${subsystemId ? `/${subsystemId}` : ''}`);

  useEffect(() => {
    get();
  }, [get]);

  return {
    get,
    userRights,
    isRightLoading,
  };
};
