export const paramsLocale = {
  parameters: 'Параметры измерений',
  module: 'Модуль',
  code: 'Код (нативный)',
  name: 'Краткое наименование',
  fullName: 'Название',
  dictionary: 'Словарь значений',
  purpose: 'Назначение параметра',
  status: 'Статус',
  searchRequest: 'Введите поисковый запрос',
  paramCharacteristics: 'Основные характеристики параметра',
  substances: 'Вещества',
  substance: 'Вещество',
  subsystem: 'Подсистема',
  paramsAdding: 'Добавление параметров измерений',
  paramsEditing: 'Редактирование параметров измерений',
  substanceAdding: 'Добавление соответствующего вещества',
  unitId: 'Единица измерения',
  type: 'Тип параметра',
  consumer: 'Принадлежность к набору параметров',
  position: 'Позиция параметра в наборе',
  fieldType: 'Тип значения',
  aliasAdding: 'Добавить вариант названия',
  accuracy: 'Точность',
  alias: 'Идентификация',
  mainAttributes: 'Основные атрибуты',
  subsystems: 'Подсистемы',
  naming: 'Наименование',
  subsystemAdding: 'Добавить подсистему',
  codificationVariant: 'вариант кодификации',
  codificationAdding: 'Добавить вариант кодификации',
  unitDescription: 'Особое наименование ед. измерения',
  inFile: 'Загружается из файла',
  inListing: 'Выводится в листинг',
  inProtocol: 'В протокол АИ',
  inExport: 'Экспорт в файл',
  inOutside: 'Экспорт во внешние системы',
};

export const types: Record<string, string> = {
  STRING: 'Строка',
  NUMERIC: 'Число',
  DIC: 'Словарное значение',
  DICLIST: 'Cписок словарных значений',
  BOOL: 'Логическое значение',
};

export const typesDict = [
  {
    name: 'Логическое значение',
    value: 'BOOL',
  },
  {
    name: 'Словарное значение',
    value: 'DIC',
  },
  {
    name: 'Список словарных значений',
    value: 'DICLIST',
  },
  {
    name: 'Строка',
    value: 'STRING',
  },
  {
    name: 'Число',
    value: 'NUMERIC',
  },
];
