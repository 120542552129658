import React, { memo } from 'react';
import { Button, SubsystemHeader } from 'components/common';

import { useHistory } from 'react-router-dom';
import { HStack } from '@chakra-ui/react';
import { GeoecologicalRootPath } from 'pages/data/GeoecologicalProcesses/consts';

interface DangerouslyGeologicalProcessesHeaderProps {
  active: string;

  onOpenLoad(): void;

  isReadonly?: boolean;
  isWrite?: boolean;
  isNoRights?: boolean;
  isExecute?: boolean;
  allAvailable?: boolean;
}

export const dangerouslyGeologicalProcessesHeaderTabs = [
  {
    label: 'Обследование',
    value: 'dangerprocess',
  },
  {
    label: 'Реперы и марки',
    value: 'reper',
  },
  {
    label: 'Инклинометры',
    value: 'inclinometer',
  },
];

export const DangerouslyGeologicalProcessesHeader: React.FC<DangerouslyGeologicalProcessesHeaderProps> = memo(
  ({ onOpenLoad, active, children, isWrite, isNoRights, allAvailable }) => {
    const isAvailable = allAvailable || isNoRights || isWrite;
    const history = useHistory();

    return (
      <SubsystemHeader
        onUploadClick={isAvailable ? onOpenLoad : undefined}
        title="Мониторинг опасных геологических процессов"
        addButtonProps={{
          onClick: () => history.push(`${history.location.pathname}/add`),
          isDisabled: !isAvailable
        }}
        filtersComponent={children}
        navigationTabs={(
          <HStack spacing="0px">
            {dangerouslyGeologicalProcessesHeaderTabs.map(({ label, value }) => (
              <Button
                key={value}
                colorScheme="blue"
                backgroundColor={value === active ? 'PrimaryButton.500' : 'white'}
                color={value === active ? 'white' : 'PrimaryButton.500'}
                variant={value === active ? 'solid' : 'outline'}
                onClick={() => history.push(`${GeoecologicalRootPath}/monitoring/danger/${value}`)}
                height="30px"
              >
                {label}
              </Button>
            ))}
          </HStack>
        )}
      />
    );
  }
);
