import React, { useCallback, useEffect, useState } from 'react';
import { DragAndDropController } from 'features/UploadFileDragAndDrop/DragAndDrop/DragAndDrop/controller';
import { FilePreviewValidation } from 'features/UploadFileDragAndDrop/DragAndDrop/Validation';
import { createInputFileMetadata } from 'features/UploadFileDragAndDrop/DragAndDrop/utils';
import { Box } from '@chakra-ui/react';
import FileItemView from 'features/UploadFileDragAndDrop/DragAndDrop/FileItem/FileItemView';
import { IUploadMedia } from 'components/common/FileUploadDragAndDrop/types';
import { UploadObjectType, useGetMediaById } from 'api/services/media';
import { useDisclosure } from 'hooks';
import FilePreviewModal from 'components/common/FilePreviewModal/FilePreviewModal';
import getAcceptedTypes from 'components/common/FileUploadDragAndDrop/fileTypes';

export enum UploadFileType {
  'image',
  'pdf',
  'doc',
  'txt',
}

interface Props {
  type: UploadFileType[] | UploadFileType;
  onMediaChange: (media: IUploadMedia[]) => void;
  media?: Array<IUploadMedia>;
  id?: string;
  objectType: UploadObjectType | null;
  isWrite?: boolean
}

const FileUploadDragAndDrop: React.FC<Props> = ({ type, onMediaChange, media = [], id, objectType, isWrite }) => {
  const [errors] = useState();
  const [selectedMediaIndex, setSelectedMediaIndex] = useState<number>();
  const { isOpen, onClose, onOpen } = useDisclosure();

  const { uploadedFiles, isLoading } = useGetMediaById(objectType, id);
  useEffect(() => {
    // load uploaded files
    if (id) {
      onMediaChange([...uploadedFiles]);
    }
  }, [id, onMediaChange, uploadedFiles]);

  const onFileAdd = useCallback(
    (file: File) => {
      onMediaChange([
        ...media,
        {
          file,
          onSaveMediaAction: 'upload',
        },
      ]);
    },
    [media, onMediaChange]
  );

  const handleDeleteFile = useCallback(
    (file: IUploadMedia, index: number) => {
      const _media = [...media];
      if (file.id) {
        const idx = _media.findIndex((item) => item.id === file.id);
        _media[idx] = {
          ...media[idx],
          onSaveMediaAction: 'delete',
        };
      } else {
        _media.splice(index, 1);
      }
      onMediaChange(_media);
    },
    [media, onMediaChange]
  );

  const onOpenSlider = (index: number) => {
    setSelectedMediaIndex(index);
    onOpen();
  };

  const [fileExtensions, mimeTypes] = getAcceptedTypes(type);

  const notDeletedMedia = media?.filter((item) => item.onSaveMediaAction !== 'delete');

  return (
    <div>
      <DragAndDropController
        isLoading={isLoading}
        isDisabled={false}
        onChange={onFileAdd}
        acceptedFileExtensions={fileExtensions}
        acceptedFileTypes={mimeTypes}
      />
      {notDeletedMedia.map((mediaItem, index) => {
        const item = createInputFileMetadata(mediaItem.file);
        return (
          <Box key={mediaItem.file.name + index} margin="25px 0 10px 0">
            <FileItemView
              name={(mediaItem?.onSaveMediaAction ? item.name : mediaItem?.originName) || ''}
              percentage={item.percentage || 0}
              onDelete={() => handleDeleteFile(mediaItem, index)}
              file={mediaItem.file}
              onFileClick={() => onOpenSlider(index)}
              isWrite={isWrite}
            />
          </Box>
        );
      })}

      <FilePreviewModal
        isOpen={isOpen}
        onClose={onClose}
        defaultIndex={selectedMediaIndex}
        media={notDeletedMedia?.map((item) => item.file)}
      />
      <FilePreviewValidation errors={errors} />
    </div>
  );
};

export default FileUploadDragAndDrop;
