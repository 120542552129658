import React, { useCallback, useMemo, useState } from 'react';
import { DataHeader, Filters } from 'pages/data/MASKSH/Data/components';
import { IFilterValues, Modal } from 'components/common';
import {
  MASKSHApiNoisePackagesList,
  MASKSHApiPackageNoise,
  MASKSHInstructions,
  MASKSHNoise,
  MASKSHNoiseAdd,
  MASKSHNoiseEdit,
  MASKSHNoisePackages,
} from 'pages/data/MASKSH/consts/menu';
import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import { useNoiseDictionaries } from 'pages/data/MASKSH/Data/Noise/hooks/useNoiseDictionaries';
import subsystemIdsMap from 'constants/subsystemIdsMap';
import { usePeriodsDict } from 'features/PeriodsButtons/usePeriodsDict';
import { noiseBreadcrumbs } from 'pages/data/MASKSH/Data/const';
import { MaterialTable } from 'features/material-table/MaterialTableMini';
import { TableTabs } from 'pages/data/MASKSH/Data/components/TableTabs';
import { useHistory } from 'react-router-dom';
import { useAvailability, useDisclosure, useHandleDeleteData, useList } from 'hooks';
import { INoisePackageListItem, tableLocale } from 'pages/data/MASKSH/consts';
import { addFiltersToColumns, ColumnWithFilter } from 'lib/utils/tableFilter';
import { TableFilterTypeEnum } from 'components/table/filters/TableFilter';
import { DateTimeTableCell } from 'pages/data/MASKSH/Data/components/DateTimeTableCell';
import { Column } from '@material-table/core';
import { paramsIds } from './consts';
import { COMMON_LOCALE } from 'constants/common';
import { UploadFileModalContent } from 'features/UploadFileDragAndDrop/UploadFileModalContent';
import { DeleteIcon, EditIcon } from 'components/icons';
import { useQuery } from 'hooks/useQuery';
import { getFilteredQuery } from '../../hooks';
// import { ExtensionToMimeTypeEnum } from 'lib/utils/files/getFileExtensionByMimeType';
import { FullPageWrapper } from '../../../../../components/table/FullPageWrapper';
import { DEFAULT_PAGE_SIZE_NEW_TABLE } from '../../../../../constants/tables';
import { DefaultTableCellSize } from 'components/table/DefaultTableCellSize';
import { ExtensionToMimeTypeEnum } from 'lib/utils/files/getFileExtensionByMimeType';

const NoisePackages = () => {
  const query = useQuery();
  const { isExecute, isWrite, isDelete } = useAvailability(subsystemIdsMap.masksh);
  useUpdateBreadcrumbs(noiseBreadcrumbs);
  const { push } = useHistory();
  const { stations, parameters, objects, isLoading: isDictionariesLoading } = useNoiseDictionaries();

  const [filtersFromGraphic, setFiltersFromGraphic] = useState<Partial<IFilterValues> | null>(null);
  const { result: periods } = usePeriodsDict(`auth/v10/user/period/${subsystemIdsMap.masksh}`);

  const {
    isLoading: isListLoading,
    response,
    setFilters,
    toggleOrder,
    onPageChange,
    filterDTO,
    downloadAsFile,
    onSearchText,
    errorText,
    refetch,
    selectedIndexes,
    renderOnlySelectedCheckbox,
    onSubmitFilters,
    onSelectionChange,
    savedFiltersFromCache,
    materialTableProps,
    saveFilters,
  } = useList<INoisePackageListItem, keyof INoisePackageListItem>(MASKSHApiNoisePackagesList, {
    initialOrderFieldKey: '-packageDate',
    exportSubsystemName: 'masksh_noise',
    preventFirstFetch: true,
    selectionKey: 'packageId',
    saveFiltersState: true,
    downloadUrl: {
      [ExtensionToMimeTypeEnum.xlsx]: `${MASKSHApiNoisePackagesList}/export`,
      [ExtensionToMimeTypeEnum.csv]: `${MASKSHApiNoisePackagesList}/export`
    },
    defaultPageSize: DEFAULT_PAGE_SIZE_NEW_TABLE,
    resetDataOnNextRequest: true,
  });

  const { ModalComponent, handleOpenModal } = useHandleDeleteData({ refetch });

  const onRowDelete = useCallback(
    (_, rowData: INoisePackageListItem | INoisePackageListItem[]) => {
      if (!Array.isArray(rowData)) {
        handleOpenModal({
          url: `${MASKSHApiPackageNoise}/${rowData.packageId}`,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleOpenModal],
  );

  const findInValuesByParamId = useCallback(
    (row: INoisePackageListItem, paramId: string) => row.values.find((item) => item.parameterId === paramId),
    [],
  );

  const columns = useMemo<ColumnWithFilter<INoisePackageListItem>[]>(() => [
    {
      title: tableLocale.columns.recieved,
      field: 'source',
      filterType: TableFilterTypeEnum.string,
    },
    {
      title: tableLocale.columns.user,
      field: 'user',
      filterType: TableFilterTypeEnum.string,
    },
    {
      title: tableLocale.columns.packageNumber,
      field: 'packageNumber',
      filterType: TableFilterTypeEnum.number,
    },
    {
      title: tableLocale.columns.packageDate,
      field: 'packageDate',
      render: (row) => <DateTimeTableCell inline datestring={row.packageDate} />,
    },
    {
      title: tableLocale.columns.stationId,
      field: 'stationName',
      filterType: TableFilterTypeEnum.string,
    },
    {
      title: tableLocale.columns.placeId,
      field: 'placeName',
      filterType: TableFilterTypeEnum.string,
      render: (row) => <DefaultTableCellSize>{row.placeName}</DefaultTableCellSize>,
    },
    {
      title: tableLocale.columns.instructionNumber,
      field: 'instructionNumber',
      filterType: TableFilterTypeEnum.string,
      render: (row) => (
        <button
          type="button"
          onClick={() => push(`${MASKSHInstructions}/view/${row.instructionId}`, { url: MASKSHNoise })}
          style={{
            color: 'blue'
          }}
        >
          {row.instructionNumber}
        </button>
      )
    },
    {
      title: tableLocale.columns.measureDate,
      field: 'measureDate',
      render: (row) => <DateTimeTableCell inline datestring={row.measureDate} />,
    },
    {
      title: 'Эквивалентный шум, дБА',
      field: `${paramsIds.equivalentNoise}_ABS_val`,
      render: (row) => findInValuesByParamId(row, paramsIds.equivalentNoise)?.value,
      filterType: TableFilterTypeEnum.number,
    },
    {
      title: 'Шум MIN, дБА',
      field: `${paramsIds.noiseMin}_ABS_val`,
      render: (row) => findInValuesByParamId(row, paramsIds.noiseMin)?.value,
      filterType: TableFilterTypeEnum.number,
    },
    {
      title: 'Шум MIN. Дата/время',
      field: `${paramsIds.noiseMin}_ABS_dt`,
      render: (row) => {
        const value = findInValuesByParamId(row, paramsIds.noiseMin);
        return <DateTimeTableCell inline datestring={value?.date} />;
      },
    },
    {
      title: 'Шум MAX, дБА',
      field: `${paramsIds.maxNoise}_ABS_val`,
      render: (row) => findInValuesByParamId(row, paramsIds.maxNoise)?.value,
      filterType: TableFilterTypeEnum.number,
    },
    {
      title: 'Шум MAX. Дата/время',
      field: `${paramsIds.maxNoise}_ABS_dt`,
      render: (row) => {
        const value = findInValuesByParamId(row, paramsIds.maxNoise);
        return <DateTimeTableCell inline datestring={value?.date} />;
      },
    },
    {
      title: 'Октава 31,5 Гц, дБ',
      field: `${paramsIds.gts31}_ABS_val`,
      render: (row) => findInValuesByParamId(row, paramsIds.gts31)?.value,
      filterType: TableFilterTypeEnum.number,
    },
    {
      title: 'Октава 63 Гц, дБ',
      field: `${paramsIds.gts63}_ABS_val`,
      render: (row) => findInValuesByParamId(row, paramsIds.gts63)?.value,
      filterType: TableFilterTypeEnum.number,
    },
    {
      title: 'Октава 125 Гц, дБ',
      field: `${paramsIds.gts125}_ABS_val`,
      render: (row) => findInValuesByParamId(row, paramsIds.gts125)?.value,
      filterType: TableFilterTypeEnum.number,
    },
    {
      title: 'Октава 250 Гц, дБ',
      field: `${paramsIds.gts250}_ABS_val`,
      render: (row) => findInValuesByParamId(row, paramsIds.gts250)?.value,
      filterType: TableFilterTypeEnum.number,
    },
    {
      title: 'Октава 500 Гц, дБ',
      field: `${paramsIds.gts500}_ABS_val`,
      render: (row) => findInValuesByParamId(row, paramsIds.gts500)?.value,
      filterType: TableFilterTypeEnum.number,
    },
    {
      title: 'Октава 1000 Гц, дБ',
      field: `${paramsIds.gts1000}_ABS_val`,
      render: (row) => findInValuesByParamId(row, paramsIds.gts1000)?.value,
      filterType: TableFilterTypeEnum.number,
    },
    {
      title: 'Октава 2000 Гц, дБ',
      field: `${paramsIds.gts2000}_ABS_val`,
      render: (row) => findInValuesByParamId(row, paramsIds.gts2000)?.value,
      filterType: TableFilterTypeEnum.number,
    },
    {
      title: 'Октава 4000 Гц, дБ',
      field: `${paramsIds.gts4000}_ABS_val`,
      render: (row) => findInValuesByParamId(row, paramsIds.gts4000)?.value,
      filterType: TableFilterTypeEnum.number,
    },
    {
      title: 'Октава 8000 Гц, дБ',
      field: `${paramsIds.gts8000}_ABS_val`,
      render: (row) => findInValuesByParamId(row, paramsIds.gts8000)?.value,
      filterType: TableFilterTypeEnum.number,
    },
  ],
    [findInValuesByParamId, push],);

  const columnsWithFilters = useMemo<Column<INoisePackageListItem>[]>(
    () => addFiltersToColumns({ filters: filterDTO.filters, setFilters, columns }),
    [filterDTO.filters, setFilters, columns],
  );

  const isLoading = useMemo(() => isDictionariesLoading || isListLoading, [isDictionariesLoading, isListLoading]);

  const { isOpen, onClose, onOpen } = useDisclosure();

  return (
    <FullPageWrapper>
      <DataHeader
        addPath={`${MASKSHNoiseAdd}/packages`}
        onOpenUpload={onOpen}
        defaultFiltersOpen={!!savedFiltersFromCache}
        isWrite={isWrite}
      >
        <Filters
          filters={filterDTO.filters}
          periods={periods}
          filtersFromGraphic={filtersFromGraphic}
          setFiltersFromGraphic={setFiltersFromGraphic}
          isLoading={isLoading}
          isDisabled={isLoading}
          objects={objects}
          stations={stations}
          parameters={parameters}
          onSubmit={(values) => {
            onSubmitFilters(Object.values(values));
          }}
          showParameterFilter={false}
        >
          {renderOnlySelectedCheckbox}
        </Filters>
      </DataHeader>

      <MaterialTable
        isNotExecute={isExecute}
        topBarLeftContent={
          <TableTabs
            tabs={[
              {
                name: 'Пакеты',
                path: MASKSHNoisePackages,
                query: getFilteredQuery(query),
              },
              {
                name: 'Значения измерений',
                path: MASKSHNoise,
                query: getFilteredQuery(query),
              },
            ]}
          />
        }
        data={response?.data}
        meta={response?.meta}
        filterDTO={filterDTO}
        toggleOrder={toggleOrder}
        onPageChange={onPageChange}
        columns={columnsWithFilters}
        actions={[
          {
            position: 'row',
            action: (row: INoisePackageListItem) => ({
              icon: () => <EditIcon />,
              onClick: () => push(`${MASKSHNoiseEdit}/packages/${row.packageId}`),
              tooltip: 'Редактировать',
              hidden: !isWrite || !row.isEditable,
            }),
          },
          {
            position: 'row',
            action: (row: INoisePackageListItem) => ({
              icon: () => <DeleteIcon />,
              onClick: () => onRowDelete(undefined, row),
              tooltip: 'Удалить',
              hidden: !isDelete || !row.isEditable,
            }),
          },
        ]}
        isLoading={isLoading || isDictionariesLoading}
        options={{
          search: true,
        }}
        onSearch={onSearchText}
        downloadAsFile={downloadAsFile}
        errorText={errorText}
        selectedIndexes={selectedIndexes}
        onSelectionChange={onSelectionChange}
        saveFilters={saveFilters}
        {...materialTableProps}
        showTotalCount
      />

      {ModalComponent}

      <Modal isOpen={isOpen} onClose={onClose} header={COMMON_LOCALE.uploadDataFromFile}>
        <UploadFileModalContent
          createTypesPath={() => `${MASKSHApiPackageNoise}/load/types`}
          createUploadPath={(typeId) => `${MASKSHApiPackageNoise}/load?typeId=${typeId}`}
          onClose={onClose}
          refetch={refetch}
        />
      </Modal>
    </FullPageWrapper>
  );
};

export { NoisePackages };
