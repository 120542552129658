import { FormHeader } from 'components/common';
import { ERROR_MESSAGES, SUCCESS_MESSAGES } from 'constants/messages';
import { createTouchedErrors } from 'features/create-touched-errors';
import { FormikProvider, useFormik } from 'formik';
import { useRequest } from 'hooks';
import { useNotifications } from 'hooks/useNotifications';
import { NotificationSettingSchema } from 'models/schemas/admin/notificationsetting';
import { NotificationSettingsItem } from 'pages/admin/models/NotificationSettingsItem';
import { useHistory } from 'react-router-dom';
import { baseAdminNotificationUrl } from '../const';
import FieldsForm from './FieldsForm';

const initialValues: NotificationSettingsItem = {
  id: '',
  roleId: '',
  roleName: '',
  subsystemId: '',
  subsystemName: '',
  sources: [],
  methods: [],
  deleted: false,
};

export const AddNotificationForm = () => {
  const history = useHistory();
  const { successNotify, errorNotify } = useNotifications();

  const successHandler = () => {
    successNotify({
      key: 'file/create/success',
      message: SUCCESS_MESSAGES.CREATE,
    });
    history.push('/admin/notification');
  };
  const errorHandler = (error: any) =>
    errorNotify({
      key: 'file/create/error',
      message: `${ERROR_MESSAGES.CREATE}. ${typeof error === 'string' ? error : ''}`, // TODO - Переделать позже
    });

  const {
    post: createNotificationSetting,
    isLoading: isSaving
  } = useRequest(baseAdminNotificationUrl, successHandler, errorHandler);

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    validationSchema: NotificationSettingSchema,
    initialValues,
    onSubmit: (values: NotificationSettingsItem) => createNotificationSetting(values),
  });

  return (
    <FormikProvider
      value={{
        ...formik,
        errors: createTouchedErrors(formik.errors, formik.touched),
      }}
    >
      <FormHeader url="/admin/notification" header="Создание настройки уведомлений" />
      <FieldsForm
        onCancel={() => history.push('/admin/notification')}
        isLoading={isSaving}
      />
    </FormikProvider>
  );
};
