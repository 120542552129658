import moment from 'moment';

export const initialEmptyValues = {
  address: '',
  areaId: null,
  areaName: '',
  deleted: false,
  field: '',
  fieldSite: '',
  id: '',
  latitude: '',
  licenceEndDate: moment().format(),
  licenceNumber: '',
  licenceRegistrationDate: moment().format(),
  longitude: '',
  mineralResourceType: '',
  productionVolume: '',
  protocolDate: moment().format(),
  protocolNumber: '',
  stockA: '',
  stockB: '',
  stockC1: '',
  stockC2: '',
  authorityId: null,
  fieldAreaId: null,
  fieldId: null,
  licenceStatusId: null,
  mineralResourceTypeId: null,
  subsoilUserId: null,
};
