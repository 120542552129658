import { Route } from 'react-router-dom';
import { PDK } from 'pages/dictionaries/NSIPdk';
import {
  addConcentrationPath,
  addSubstancePath,
  consentrationPath,
  editConcentrationPath,
  editSubstancePath,
  pdkRootPath,
  substancesPath,
} from 'pages/dictionaries/NSIPdk/consts';
import { Substances } from 'pages/dictionaries/NSIPdk/Substances';
import { AddSubstance } from 'pages/dictionaries/NSIPdk/Substances/Forms/AddSubstance';
import { EditSubstance } from 'pages/dictionaries/NSIPdk/Substances/Forms/EditSubstance';
import { AllowedConcentration } from 'pages/dictionaries/NSIPdk/Concentration';
import { AddConcentration } from 'pages/dictionaries/NSIPdk/Concentration/Forms/AddConcentration';
import { EditConcentration } from 'pages/dictionaries/NSIPdk/Concentration/Forms/EditConcentration';

const PDKRoutes = () => (
  <>
    <Route exact path={pdkRootPath} component={PDK} />
    <Route exact path={substancesPath} component={Substances} />
    <Route exact path={addSubstancePath} component={AddSubstance} />
    <Route exact path={editSubstancePath} component={EditSubstance} />
    <Route exact path={consentrationPath} component={AllowedConcentration} />
    <Route exact path={addConcentrationPath} component={AddConcentration} />
    <Route exact path={editConcentrationPath} component={EditConcentration} />
  </>
);

export { PDKRoutes };
