import { memo, useCallback } from 'react';
import { Icon } from '@chakra-ui/react';

import { Button } from 'components/common';
import { PrintIcon } from 'components/icons';

export const PrintChartButton = memo(({ chartId }: { chartId: string }) => {
  const handlePrint = useCallback(() => {
    const element = document.getElementById(chartId) as HTMLCanvasElement;
    const dataUrl = element.toDataURL(); // attempt to save base64 string to server using this var
    let windowContent = '<!DOCTYPE html>';
    windowContent += '<html>';
    windowContent += '<head><title>График</title></head>';
    windowContent += '<body>';
    windowContent += `<img src="${dataUrl}">`;
    windowContent += '</body>';
    windowContent += '</html>';
    const printWin = window.open('', '', `width=${screen.availWidth},height=${screen.availHeight}`);
    if (printWin) {
      printWin.document.open();
      printWin.document.write(windowContent);
      printWin.document.addEventListener(
        'load',
        () => {
          printWin.focus();
          printWin.print();
          printWin.document.close();
          printWin.close();
        },
        true
      );
    }
  }, [chartId]);

  return (
    <Button
      variant="solid"
      ml={5}
      onClick={handlePrint}
      rightIcon={<Icon __css={{ path: { fill: 'white' } }} as={PrintIcon} />}
    >
      Печать
    </Button>
  );
});
