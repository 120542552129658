import { Field, FormikProvider, useFormik } from 'formik';
import { Flex, Grid } from '@chakra-ui/react';
import { Checkbox, DateInput, IFilterValues, Select, useSetFiltersFromGraphic } from 'components/common';
import { createDateFromResponse, createDateToRequestWithTimezone } from 'lib/create-date';
import { COMMON_LOCALE } from 'constants/common';
import { askzvLocales } from './locales';
import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { IPeriod } from 'features/PeriodsButtons/types';

import { PeriodsButtons } from 'features/PeriodsButtons';
import { usePeriods } from 'features/PeriodsButtons/usePeriods';
import combineFilters from 'lib/utils/combineFilters';
import { IFilterItem, TDictionaryItemDictionary, TFiltersArray } from 'models';
import { MomentInput } from 'moment';
import { FiltersDatepickersSchema } from 'models/schemas/filtersDatepickersSchema/FiltersDatepickersSchema';
import FilterFormControls from 'components/form/FilterFormControls';

interface FilterProps {
  isLoading?: boolean;
  isPackagesView?: boolean;
  isDisabled?: boolean;
  sources: TDictionaryItemDictionary[] | undefined;
  parameters: TDictionaryItemDictionary[] | undefined;
  onSubmit: (values: any) => void | any;
  periods: IPeriod[] | null;
  filters?: TFiltersArray;
  filtersFromGraphic?: Partial<IFilterValues> | null;
  setFiltersFromGraphic?: (value: Partial<IFilterValues> | null) => void;
  setGraphInitialFilters?: (filters: any) => void;
}

const initialEmptyValues: Record<string, IFilterItem<string>> = {
  dateTimeMin: {
    key: 'measuringDatetime',
    operation: 'GTE',
    value: null,
  },
  dateTimeMax: {
    key: 'measuringDatetime',
    operation: 'LTE',
    value: null,
  },
  sourceId: {
    key: 'sourceId',
    operation: 'EQ',
    value: null,
  },
  parameterId: {
    key: 'parameterId',
    operation: 'EQ',
    value: null,
  },
  showDisabled: {
    key: 'deleted',
    operation: 'EQ',
    value: false,
  },
  periodId: {
    key: 'periodId',
    operation: 'EQ',
    value: null,
  },
  initialData: {
    key: 'initialData',
    operation: 'EQ',
    value: false,
  },
};

export const WaterASKZVFilters: FC<FilterProps> = ({
  isLoading,
  isDisabled,
  onSubmit,
  sources,
  parameters,
  isPackagesView,
  children,
  periods,
  filters = [],
  setFiltersFromGraphic,
  filtersFromGraphic,
  setGraphInitialFilters,
}) => {
  const shouldSetGraphInitialFilters = useRef(false);
  const savedPeriodId = useMemo(
    () => (filters.find((item) => item.key === 'periodId')?.value as string) || '',
    [filters]
  );

  const [periodId, setPeriodId] = useState<string>(savedPeriodId);

  const { getDatesFromPeriod, dates } = usePeriods(periodId, periods);

  const submitPeriod = (id: string) => {
    const dates = getDatesFromPeriod(id);
    setFieldValue('periodId.value', id);
    setFieldValue('dateTimeMin.value', createDateToRequestWithTimezone(dates[0]));
    setFieldValue('dateTimeMax.value', createDateToRequestWithTimezone(dates[1]));
    setFieldTouched('dateTimeMin.value');
    setPeriodId(id);
  };

  const { values, errors, setValues, handleReset, submitForm, setFieldValue, setFieldTouched, ...formik } = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    validationSchema: FiltersDatepickersSchema([{ dateFromFieldName: 'dateTimeMin', dateToFieldName: 'dateTimeMax' }]),
    initialValues: initialEmptyValues,
    onSubmit: (values) => {
      const filters = onSubmit(values);
      if (filters && shouldSetGraphInitialFilters.current && setGraphInitialFilters) {
        shouldSetGraphInitialFilters.current = false;
        setGraphInitialFilters(filters);
      }
    },
  });

  useSetFiltersFromGraphic({
    dateKeyMin: 'dateTimeMin.value',
    dateKeyMax: 'dateTimeMax.value',
    setFiltersFromGraphic,
    filtersFromGraphic,
    setFieldValue,
    onChange: submitForm,
  });

  useEffect(() => {
    setValues(combineFilters(initialEmptyValues, filters));
  }, [filters, setValues]);

  const resetFilters = (e: any) => {
    handleReset(e);
    setPeriodId('');
    setTimeout(() => formik.handleSubmit(), 0);
  };

  return (
    <FormikProvider
      value={{
        values,
        errors,
        handleReset,
        submitForm,
        setFieldValue,
        setValues,
        setFieldTouched,
        ...formik,
      }}
    >
      <Grid>
        <Flex>
          <Grid width="100%" maxWidth="1200px" gap="12px" templateColumns="repeat(2, 220px) 1fr 1fr">
            <Field
              as={DateInput}
              name="dateTimeMin.value"
              label={COMMON_LOCALE.from}
              value={createDateFromResponse(values.dateTimeMin.value as MomentInput)}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setFieldValue('dateTimeMin.value', createDateToRequestWithTimezone(e.target.value));
              }}
              showTimeInput
              error={errors.dateTimeMin?.value}
              maxDate={dates?.[1]}
              minDate={dates?.[0]}
              isDisabled={!periodId}
            />
            <Field
              as={DateInput}
              name="dateTimeMax.value"
              label={COMMON_LOCALE.to}
              value={createDateFromResponse(values.dateTimeMax.value as MomentInput)}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setFieldValue('dateTimeMax.value', createDateToRequestWithTimezone(e.target.value));
              }}
              showTimeInput
              error={errors.dateTimeMax?.value}
              maxDate={dates?.[1]}
              minDate={dates?.[0]}
              isDisabled={!periodId}
            />
            <Field
              as={Select}
              options={sources}
              isDisabled={!sources?.length}
              name="sourceId.value"
              label={askzvLocales.filters.sourceId}
            />
            {!isPackagesView && (
              <Field
                as={Select}
                options={parameters}
                isDisabled={!parameters?.length}
                name="parameterId.value"
                label={askzvLocales.filters.parameter}
              />
            )}

            <Field
              as={Checkbox}
              name="initialData.value"
              label="Исходные данные"
              checked={values.initialData?.value}
              height={45}
              padding={0}
            />

            {children}
          </Grid>

          <FilterFormControls
            onReset={resetFilters}
            onSubmit={() => {
              shouldSetGraphInitialFilters.current = true;
              submitForm();
            }}
            isDisabled={isDisabled}
            isLoading={isLoading}
          />
        </Flex>

        <PeriodsButtons periodId={periodId} periods={periods} submitPeriod={submitPeriod} />
      </Grid>
    </FormikProvider>
  );
};
