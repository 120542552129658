import * as yup from 'yup';
import { array } from 'yup';
import { numberError, requiredError, shouldBeMoreThanEq } from '../constans';
import { GreenPlantsFormTypes } from 'pages/data/PGR/GreenPlants/GreenPlants/Form/types';
import { percentage } from 'models/schemas/numbers';

export const useGreenPlantsSchema = (entity: keyof typeof GreenPlantsFormTypes) => {
  const schema = yup.object().shape({
    [entity]: yup.object().shape({
      areaId: yup.string().nullable().required(requiredError),
      plantId: yup.string().nullable(),
      ...schemaForEntity[entity],
    }),
  });

  return {
    GreenPlantsSchema: schema,
  };
};

const schemaForEntity: Record<keyof typeof GreenPlantsFormTypes, any> = {
  area: {
    parameters: yup.object().shape({
      area: yup.object().shape({
        value: array().of(
          yup.number()
            .typeError(numberError)
            .notRequired()
            .nullable()
            .min(0, shouldBeMoreThanEq(0))
        ).nullable(),
      }),
    })
  },
  lawns: {
    lawnId: yup.string().nullable().required(requiredError),
    parameters: yup.object().shape({
      howManyDiseases: yup.object().shape({
        value: array().of(percentage).nullable(),
      }),
      howManyPests: yup.object().shape({
        value: array().of(percentage).nullable(),
      }),
      countCereals: yup.object().shape({
        value: array().of(percentage).nullable(),
      }),
      countWeeds: yup.object().shape({
        value: array().of(percentage).nullable(),
      }),
      countMosses: yup.object().shape({
        value: array().of(percentage).nullable(),
      }),
      baldPatches: yup.object().shape({
        value: array().of(percentage).nullable().required(requiredError),
      }),
      projCover: yup.object().shape({
        value: array().of(percentage).nullable().required(requiredError),
      }),
      squareWithYellowGrass: yup.object().shape({
        value: array().of(percentage).nullable().required(requiredError),
      }),
      heightGrass: yup.object().shape({
        value: array().of(yup.number().typeError(numberError).nullable()).nullable(),
      }),
      lawnColor: yup.object().shape({
        value: array().of(yup.string().nullable().required(requiredError)).nullable().required(requiredError),
      }),
      denseGrass: yup.object().shape({
        value: array().of(yup.string().nullable().required(requiredError)).nullable().required(requiredError),
      }),
      decorativeLawns: yup.object().shape({
        value: array().of(yup.string().nullable().required(requiredError)).nullable().required(requiredError),
      }),
      garbageScope: yup.object().shape({
        value: array().of(yup.string().nullable().required(requiredError)).nullable().required(requiredError),
      }),
    }),
  },
  flora: {
    plantId: yup.string().nullable().required(requiredError),
    parameters: yup.object().shape({
      averageDiameter: yup
        .object()
        .shape({
          value: array().of(yup.number().typeError(numberError)).nullable().required(requiredError),
        })
        .required(requiredError),
      stemHeight: yup
        .object()
        .shape({
          value: array().of(yup.number().typeError(numberError)).nullable().required(requiredError),
        })
        .required(requiredError),
      plantAge: yup
        .object()
        .shape({
          value: array().of(yup.string()).nullable().required(requiredError),
        })
        .required(requiredError),
      plantHeight: yup
        .object()
        .shape({
          value: array().of(yup.number().typeError(numberError)).nullable().required(requiredError),
        })
        .required(requiredError),
      diameter: yup
        .object()
        .shape({
          value: array().of(yup.number().typeError(numberError)).nullable().required(requiredError),
        })
        .required(requiredError),
    }),
  },
};
