import { IReportPoint, ITableContext } from '../../../types';
import React, { useContext } from 'react';
import TableCell from '@material-ui/core/TableCell';
import { TableContext } from 'pages/citizenAppeal/RaidJournal/RaidTable/CustomRaidTable';

interface IProps {
  point: IReportPoint;
}

export const PointLine = ({ point }: IProps) => {
  const { noise } = useContext<ITableContext>(TableContext);
  return (
    <React.Fragment key={point.pointNumber}>
      <TableCell>{point.pointAddress}</TableCell>
      {!noise ? (
        <>
          <TableCell>{point.pelProtocolNumber}</TableCell>
          <TableCell>{point.aiProtocolNumber}</TableCell>
          <TableCell>{point.protocolNumber}</TableCell>
        </>
      ) : (
        <>
          <TableCell>{point.protocolNumber}</TableCell>
        </>
      )}
    </React.Fragment>
  );
};
