import { createTouchedErrors } from 'features/create-touched-errors';
import { FormikProvider, useFormik } from 'formik';
import { useRequest } from 'hooks/useRequest';
import { useEnhancedNotification } from 'hooks';
import NSIWaterSitesFormFields
  from 'pages/dictionaries/NSIWater/NSIWaterSites/NSIWaterSitesForms/NSIWaterSitesFormFields';
import { useDictionary } from 'hooks/useDictionary';
import { NSIWaterApiPath, NSIWaterSitesPaths } from 'pages/dictionaries/NSIWater/paths';
import initialNSIWaterSitesEmptyValues
  from 'pages/dictionaries/NSIWater/NSIWaterSites/NSIWaterSitesForms/initialNSIWaterSitesEmptyValues';
import { NsiWaterSitesSchema } from 'models/schemas/nsiWater/nsiWaterSitesSchema';
import { NSIWaterDictionaryKeys, NSIWaterItemType } from 'pages/dictionaries/NSIWater/types';
import { useGoBackWithFallback } from 'lib/utils/goBackWithFallback';

const NSIWaterSitesAdd = () => {
  const { backWithFallback } = useGoBackWithFallback();
  const { onSuccessCreate, onErrorCreate } = useEnhancedNotification();

  const { isDictionariesLoading, dictionaries } = useDictionary<NSIWaterDictionaryKeys>({
    url: `${NSIWaterApiPath}/dictionaries`,
  });

  const onSuccess = () => {
    onSuccessCreate();
    backWithFallback(NSIWaterSitesPaths.root);
  };

  const { post, isLoading } = useRequest(`${NSIWaterApiPath}/create`, onSuccess, onErrorCreate);

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    initialValues: initialNSIWaterSitesEmptyValues,
    validationSchema: NsiWaterSitesSchema,
    onSubmit: (values) => post({ ...values, type: NSIWaterItemType.area }),
  });

  return (
    <FormikProvider
      value={{
        ...formik,
        errors: createTouchedErrors(formik.errors, formik.touched),
      }}
    >
      <NSIWaterSitesFormFields
        dictionaries={dictionaries}
        onCancel={() => {
          backWithFallback(NSIWaterSitesPaths.root);
        }}
        isLoading={isDictionariesLoading || isLoading}
      />
    </FormikProvider>
  );
};

export default NSIWaterSitesAdd;
