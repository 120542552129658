import { useRequest } from 'hooks/useRequest';

interface IASKZAInversionDictionaryParameter {
  parameterCode: string;
  parameterId: string;
  parameterName: string;
}

interface IASKZAInversionDictionarySource {
  sourceId: string;
  sourceName: string;
  sourceNumber: number;
  parameters: IASKZAInversionDictionaryParameter[];
}

export interface IASKZAInversionDictionariesResponse {
  askzaDictionary: IASKZAInversionDictionarySource[];
  profilemerDictionary: IASKZAInversionDictionarySource[];
}

interface IUseDictionary {
  url: string;
}

function useASKZAInversionDictionaries({ url }: IUseDictionary) {
  const { post, isLoading, result } = useRequest<IASKZAInversionDictionariesResponse>(url);

  return {
    post,
    isDictionariesLoading: isLoading,
    askzaDictionary: result?.askzaDictionary,
    profilemerDictionary: result?.profilemerDictionary,
  };
}

export default useASKZAInversionDictionaries;
