import { Route } from 'react-router';

import {
  WaterRouteAskzvAddMeasurements,
  WaterRouteAskzvAddPackages,
  WaterRouteAskzvEditMeasurements,
  WaterRouteAskzvEditPackages,
  WaterRouteAskzvMeasurments,
  WaterRouteAskzvPackages,
  WaterRouteAskzvPreview,
  WaterRouteRoot,
  WaterRoutesBottomSoil,
  WaterRoutesGroundCoast,
  WaterRouteStateRoot,
  WaterRouteSurfaceWatersAdd,
  WaterRouteSurfaceWatersEdit,
  WaterRouteSurfaceWatersRoot,
  WaterRoutesWPZ,
  WaterWPZListRoot,
} from '../data/Water';
import { WaterRouterList } from '../data/Water/RouterList';
import { WaterState } from '../data/Water/State';
import { WaterBottomSoil } from '../data/Water/BottomSoil';
import { WaterSurfaceWaters } from '../data/Water/SurfaceWater';
import { AddASKZVPackage } from '../data/Water/ASKZV/Forms/Add';
import { EditASKZVPackage } from '../data/Water/ASKZV/Forms/Edit';
import { AddSurfaceWaterForm } from '../data/Water/SurfaceWater/Forms/Add';
import { EditSurfaceWaterForm } from '../data/Water/SurfaceWater/Forms/Edit';
import { BottomSoilAdd } from 'pages/data/Water/BottomSoil/BottomSoilForms/BottomSoilAdd';
import { BottomSoilEdit } from 'pages/data/Water/BottomSoil/BottomSoilForms/BottomSoilEdit';
import { WPZ } from 'pages/data/Water/WPZRoot/WPZ';
import { WPZAdd } from 'pages/data/Water/WPZRoot/WPZ/WPZForms/WPZAdd';
import { WPZEdit } from 'pages/data/Water/WPZRoot/WPZ/WPZForms/WPZEdit';
import { GroundCoast } from 'pages/data/Water/WPZRoot/GroundCoast';
import { WPZRoot } from 'pages/data/Water/WPZRoot';
import GroundCoastAdd from 'pages/data/Water/WPZRoot/GroundCoast/GroundCoastForms/GroundCoastAdd';
import { GroundCoastEdit } from 'pages/data/Water/WPZRoot/GroundCoast/GroundCoastForms/GroundCoastEdit';
import { BottomSoilAnalyticsTab } from 'pages/data/Water/BottomSoil/BottomSoilAnalyticsTab';
import { SurfaceWaterAnalyticsTab } from 'pages/data/Water/SurfaceWater/SurfaceWaterAnalyticsTab';
import { WithDidMountResetUploadFile } from 'features/UploadedFileProvider/hocs';
import { ASKZVMeasurement } from 'pages/data/Water/ASKZV/Tables/ASKZVMeasurements';
import { ASKZVPackages } from 'pages/data/Water/ASKZV/Tables/ASKZVPackages';

export const WaterRoutes = () => (
  <>
    <Route exact path={WaterRouteRoot} component={WaterRouterList} />

    <Route exact path={WaterRouteAskzvMeasurments} component={ASKZVMeasurement} />
    <Route exact path={WaterRouteAskzvPackages} component={ASKZVPackages} />

    <Route
      exact
      path={WaterRouteAskzvAddMeasurements}
      render={() => <AddASKZVPackage
        listPath={WaterRouteAskzvMeasurments}
        previewPath={`${WaterRouteAskzvPreview}/measurements`}
      />}
    />
    <Route
      exact
      path={WaterRouteAskzvEditMeasurements}
      render={() => <EditASKZVPackage
        listPath={WaterRouteAskzvMeasurments}
      />}
    />

    <Route
      exact
      path={WaterRouteAskzvAddPackages}
      render={() => <AddASKZVPackage
        listPath={WaterRouteAskzvPackages}
        previewPath={`${WaterRouteAskzvPreview}/packages`}
      />}
    />
    <Route
      exact
      path={WaterRouteAskzvEditPackages}
      render={() => <EditASKZVPackage
        listPath={WaterRouteAskzvPackages}
      />}
    />

    {/* BOTTOM SOIL START */}

    <Route exact path={`${WaterRoutesBottomSoil.root}/main-data`} component={WaterBottomSoil} />
    <Route exact path={`${WaterRoutesBottomSoil.root}/analytics-data`} component={BottomSoilAnalyticsTab} />
    <Route
      exact
      path={WaterRoutesBottomSoil.add}
      render={() => <WithDidMountResetUploadFile Component={BottomSoilAdd} />}
    />

    <Route
      exact
      path={WaterRoutesBottomSoil.edit(':id')}
      render={() => <WithDidMountResetUploadFile Component={BottomSoilEdit} />}
    />

    {/* BOTTOM SOIL END */}

    <Route exact path={WaterRouteStateRoot} component={WaterState} />
    <Route exact path={`${WaterRouteSurfaceWatersRoot}/main-data`} component={WaterSurfaceWaters} />
    <Route exact path={`${WaterRouteSurfaceWatersRoot}/analytics-data`} component={SurfaceWaterAnalyticsTab} />
    <Route
      exact
      path={WaterRouteSurfaceWatersAdd}
      render={() => <WithDidMountResetUploadFile Component={AddSurfaceWaterForm} />}
    />
    <Route
      exact
      path={WaterRouteSurfaceWatersEdit}
      render={() => <WithDidMountResetUploadFile Component={EditSurfaceWaterForm} />}
    />

    {/* WPZ */}

    <Route exact path={WaterWPZListRoot} component={WPZRoot} />

    <Route exact path={WaterRoutesWPZ.root} component={WPZ} />

    <Route exact path={WaterRoutesWPZ.add} component={WPZAdd} />

    <Route exact path={WaterRoutesWPZ.edit(':id')} component={WPZEdit} />

    {/* WPZ END */}

    {/* GROUND COAST START */}

    <Route exact path={WaterRoutesGroundCoast.root} component={GroundCoast} />

    <Route exact path={WaterRoutesGroundCoast.add} component={GroundCoastAdd} />

    <Route exact path={WaterRoutesGroundCoast.edit(':packageId')} component={GroundCoastEdit} />

    {/* GROUND COAST END */}
  </>
);
