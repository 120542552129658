import { useRequest } from 'hooks/useRequest';
import { useEffect } from 'react';
import { TBaseGreenPlantsFormProperties } from './types';
import { ReagentsApiGreenplants } from 'pages/data/PGR/consts';

type TPlant = {
  areaId: string | null;
  breed: string | null;
  date: string | null;
  id: string | null;
  number: number | null;
  type: string | null;
};

function usePlantsByAreaId(id: TBaseGreenPlantsFormProperties<string>['areaId']) {
  const { get, isLoading, result, error } = useRequest<TPlant[]>(`${ReagentsApiGreenplants}/flora/areas/${id}/plants`);
  useEffect(() => {
    if (id && id !== '') {
      get();
    }
  }, [get, id]);

  return { isLoading, result, error };
}

function usePlantsByAreaIdsArray(ids: string[]) {
  const {
    post,
    isLoading,
    result,
    error,
    reset
  } = useRequest<TPlant[]>(`${ReagentsApiGreenplants}/flora/areas/plants`);
  useEffect(() => {
    if (Array.isArray(ids) && ids.length) {
      post({ ids });
    }
  }, [post, ids]);

  return { isLoading, result, error, reset };
}

export type { TPlant };

export { usePlantsByAreaId, usePlantsByAreaIdsArray };
