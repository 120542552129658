import { ConfirmModal, Modal, SubsystemHeader } from 'components/common';
import appLocale from 'constants/appLocale';
import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import { AnalyticReportsDutyOfficeBreadcrumb } from '../../../consts/Breadcrumbs';
import subsystemIdsMap from 'constants/subsystemIdsMap';
import { MaterialTable } from 'features/material-table/MaterialTableMini';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useEnhancedNotification, useList, useNotifications, useRequest } from 'hooks';
import { Column } from '@material-table/core';
import { UploadFileModalContent } from 'features/UploadFileDragAndDrop/UploadFileModalContent';
import { useDisclosure } from '@chakra-ui/hooks';
import { ISOKEnvironmental } from './types';
import { SOKEnvironmentalFilter } from './SOKEnvironmentalFilter';
import { downloadFileFromResponse } from 'lib/utils/files/downloadFile';
import { ExtensionToMimeTypeEnum } from 'lib/utils/files/getFileExtensionByMimeType';
import { DeleteIcon, DownloadIcon } from 'components/icons';
import { ERROR_MESSAGES, REMOVE_CONFIRMATION, SUCCESS_MESSAGES } from 'constants/messages';
import { createLocalDateDDMMYYYYWithTime } from 'lib/create-date';
import { DEFAULT_PAGE_SIZE_NEW_TABLE } from '../../../../../constants/tables';
import { FullPageWrapper } from '../../../../../components/table/FullPageWrapper';

export const SOKEnvironmentalJournal = () => {
  useUpdateBreadcrumbs([
    ...AnalyticReportsDutyOfficeBreadcrumb,
    {
      breadcrumb: 'Отчет оперативного дежурного СОК',
      path: `/reports-analytics/duty-office/${subsystemIdsMap.dutyOfficer}/SOKEnvironmentalJournal`,
    },
  ]);

  const [downloadReportItem, setDownloadReportItem] = useState<ISOKEnvironmental>();
  const { successNotify, errorNotify } = useNotifications();
  const { onDownloadError } = useEnhancedNotification();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [removedItems, setRemovedItems] = useState('');
  const { isOpen: isDeleteOpen, onOpen: onDeleteOpen, onClose: onDeleteClose } = useDisclosure();

  const onFileResponseSuccess = useCallback(
    (response) => {
      if (response instanceof Blob && downloadReportItem) {
        downloadFileFromResponse({
          response,
          name: downloadReportItem.fileName,
        });
        setDownloadReportItem(undefined);
      }
    },
    [downloadReportItem]
  );

  const _onDownloadError = useCallback(() => {
    onDownloadError();
    setDownloadReportItem(undefined);
  }, [onDownloadError]);

  const { post } = useRequest('', onFileResponseSuccess, _onDownloadError);

  const { response, refetch, filterDTO, isLoading, onPageChange, toggleOrder } = useList<ISOKEnvironmental,
    keyof ISOKEnvironmental>('appeal/v10/sok/report/list', {
      initialOrderFieldKey: '-date',
      defaultPageSize: DEFAULT_PAGE_SIZE_NEW_TABLE,
      resetDataOnNextRequest: true,
    });

  const onSuccess = () => {
    onDeleteClose();
    refetch();
    successNotify({
      key: 'file/delete/success',
      message: SUCCESS_MESSAGES.DELETE,
    });
  };
  const onError = () =>
    errorNotify({
      key: 'file/delete/error',
      message: ERROR_MESSAGES.DELETE,
    });
  const { post: remove } = useRequest('appeal/v10/sok/report/delete', onSuccess, onError);

  const columns = useMemo<Column<ISOKEnvironmental>[]>(
    () => [
      {
        title: 'Дата и время',
        field: 'date',
        render: (row) => createLocalDateDDMMYYYYWithTime(row.date),
      },
      {
        title: 'Наименование файла с отчетом',
        field: 'fileName',
      },
    ],
    []
  );

  const onReportDownload = useCallback(async (_e, item) => {
    if (!Array.isArray(item)) {
      setDownloadReportItem(item);
    }
  }, []);

  useEffect(() => {
    if (downloadReportItem) {
      void post(
        {},
        {
          url: `appeal/v10/sok/report/download/${downloadReportItem.id}`,
          headers: { ACCEPT: ExtensionToMimeTypeEnum.docx },
          responseType: 'blob',
        }
      );
    }
  }, [post, downloadReportItem]);

  return (
    <FullPageWrapper>
      <SubsystemHeader
        title={appLocale.analyticsReports.SOKEnvironmentalJournal}
        titleMultiline
        onUploadClick={onOpen}
      />
      <SOKEnvironmentalFilter />
      <MaterialTable
        isLoading={isLoading}
        filterDTO={filterDTO}
        columns={columns}
        data={response?.data}
        meta={response?.meta}
        onPageChange={onPageChange}
        toggleOrder={toggleOrder}
        options={{
          selection: false,
        }}
        actions={[
          {
            position: 'row',
            action: () => ({
              type: 'download',
              onClick: onReportDownload,
              icon: () => <DownloadIcon />,
              tooltip: 'Скачать',
            }),
          },
          {
            position: 'row',
            action: () => ({
              type: 'delete',
              onClick: (_e, item: any) => {
                if (!Array.isArray(item)) {
                  onDeleteOpen();
                  setRemovedItems(item.id);
                }
              },
              icon: () => <DeleteIcon />,
              tooltip: 'Удалить',
            }),
          },
        ]}
      />
      <Modal isOpen={isOpen} onClose={onClose} header="Загрузка данных из файла">
        <UploadFileModalContent
          createUploadPath={() => '/appeal/v10/sok/report/load'}
          onClose={onClose}
          refetch={refetch}
          types={[
            {
              name: 'Отчет оперативного дежурного СОК',
              value: '-',
            },
          ]}
        />
      </Modal>
      <ConfirmModal
        isOpen={isDeleteOpen}
        onClose={onDeleteClose}
        onClick={() =>
          remove(
            {},
            {
              url: `appeal/v10/sok/report/delete/${removedItems}`,
            }
          )
        }
        header={REMOVE_CONFIRMATION}
      />
    </FullPageWrapper>
  );
};
