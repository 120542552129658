import { useList } from 'hooks';
import { useFormikContext } from 'formik';
import { IContent, IFTPPattern, ISource } from '../Consts/types';
import React, { useEffect, useMemo, useState } from 'react';
import { MaterialTable } from 'features/material-table/MaterialTableMini';
import { ColumnWithFilter } from 'lib/utils/tableFilter';
import { DataPatternDetailPanel } from './DataPatternDetailPanel';
import { tableIcons } from '../../../components/CustomTable';
import { dataPatternRootPath } from '../Consts/consts';
import { Checkbox } from 'components/common';
import { isEqual } from 'lodash';

interface IProps {
  allSources: ISource[] | null;
  children: React.ReactNode;
}

export const DataPatternParamsTab = ({ allSources, children }: IProps) => {
  const { values, setFieldValue } = useFormikContext<IFTPPattern>();

  const {
    response,
    toggleOrder,
    onPageChange,
    filterDTO,
    isLoading,
    setFilters,
    onSearchText,
    selectedIds,
    onSelectionChange,
    selectedIndexes,
    setSelectedIds,
    errorText,
  } = useList<ISource, keyof ISource>(`${dataPatternRootPath}/dictionary/sources/${values.subsystemId}`, {
    initialOrderFieldKey: '-code',
    selectionKey: 'id',
  });

  useEffect(() => {
    setSelectedIds(Object.keys(values.content));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (Array.isArray(selectedIds) && JSON.stringify(Object.keys(values.content)) !== JSON.stringify(selectedIds)) {
      const content: IContent = {};
      allSources
        ?.filter((source) => selectedIds.includes(source.id))
        .forEach((source) => {
          if (!values.content[source.id]) {
            content[source.id] = source.parameters.map((parameter) => parameter.parameterId);
          } else {
            content[source.id] = values.content[source.id];
          }
        });
      setFieldValue('content', content);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedIds]);

  const columns = useMemo<ColumnWithFilter<ISource>[]>(
    () => [
      {
        title: 'Номер станции',
        field: 'code',
      },
      {
        title: 'Название станции',
        field: 'name',
      },
    ],
    [],
  );

  const formattedSources = useMemo(() => {
    const formattedSources: IContent = {};
    allSources?.forEach((source) => {
      formattedSources[source.id] = source.parameters.map((param) => param.parameterId);
    });
    return formattedSources;
  }, [allSources]);

  const isSelectAllChecked = useMemo(() => {
    const firstSortedObject: IContent = {};
    const secondSortedObject: IContent = {};
    Object.keys(values.content).forEach((item) => {
      firstSortedObject[item] = [...values.content[item]].sort();
    });
    Object.keys(formattedSources).forEach((item) => {
      secondSortedObject[item] = [...formattedSources[item]].sort();
    });
    return isEqual(firstSortedObject, secondSortedObject);
  }, [formattedSources, values.content]);

  // hack: prevents the detail panel from collapsing when changing tableData
  const [detailVisibles, setDetailVisibles] = useState<any>({});

  const renderDetailPanel = (data: any) => (
    <DataPatternDetailPanel allSources={allSources} sourceId={data.rowData.id} setSelectedIds={setSelectedIds} />
  );

  const data = useMemo(
    () =>
      response?.data?.map((rowData) => ({
        ...rowData,
        tableData: detailVisibles[rowData.id]
          ? {
            showDetailPanel: renderDetailPanel,
          }
          : {},
      })) ?? [],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [response?.data, detailVisibles],
  );

  return (
    <>
      <Checkbox
        label="Выбрать все"
        checked={isSelectAllChecked}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          if (e.target.checked) {
            setFieldValue('content', formattedSources);
            setSelectedIds(Object.keys(formattedSources));
          } else {
            setFieldValue('content', {});
            setSelectedIds([]);
          }
        }}
      />
      <MaterialTable
        data={data}
        meta={response?.meta}
        toggleOrder={toggleOrder}
        filterDTO={filterDTO}
        onPageChange={onPageChange}
        columns={columns}
        isLoading={isLoading}
        setFilters={setFilters}
        options={{ selection: true, search: true, showDetailPanelIcon: false }}
        onSearch={onSearchText}
        onSelectionChange={onSelectionChange}
        selectedIndexes={selectedIndexes}
        errorText={errorText}
        actions={[
          {
            position: 'row',
            action: (rowData: ISource) => ({
              icon: tableIcons.DetailPanel,
              tooltip: 'Показать параметры',
              onClick: () => setDetailVisibles({ ...detailVisibles, [rowData.id]: !detailVisibles[rowData.id] }),
            }),
          },
        ]}
        detailPanel={(data) => (
          <DataPatternDetailPanel allSources={allSources} sourceId={data.rowData.id} setSelectedIds={setSelectedIds} />
        )}
      />
      {children}
    </>
  );
};
