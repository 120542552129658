import { subsystemCodesMap } from 'constants/subsystemIdsMap';
import { ReportSmall } from 'features/WithSideBar/icons';

export const sentryJournalMenu = {
  id: '/sentry/journal',
  name: 'Журнал действий',
  url: '/sentry/journal',
  otherCode: subsystemCodesMap.sentryJournal,
  icon: <ReportSmall />,
};
