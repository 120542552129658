import { Field, useFormikContext } from 'formik';
import { FormWrapper, Input } from 'components/common';

import { fieldsMeteoLocale, IMeteoPackageForm, tabsLocale } from 'pages/data/MASKSH/consts';
import { Grid, Heading } from '@chakra-ui/react';

export const MeteoPackageFormSelfTab = () => {
  const { values, errors } = useFormikContext<IMeteoPackageForm>();
  return (
    <FormWrapper>
      <Grid gap={2}>
        <Heading mb={2} size="md">
          {tabsLocale.selfDiagnostic}
        </Heading>
        <Field as={Input} label={fieldsMeteoLocale.ip} name="ip" value={values.ip} />
        <Field
          as={Input}
          label={fieldsMeteoLocale.insideTemperature}
          name="insideTemperature"
          value={values.insideTemperature}
          error={errors.insideTemperature}
        />
        <Field as={Input} label={fieldsMeteoLocale.voltage} name="voltage" value={values.voltage} error={errors.voltage} />
        <Field as={Input} label={fieldsMeteoLocale.coefficient1} name="coefficient1" value={values.coefficient1} error={errors.coefficient1} />
        <Field as={Input} label={fieldsMeteoLocale.coefficient2} name="coefficient2" value={values.coefficient2} error={errors.coefficient2} />
        <Field as={Input} label={fieldsMeteoLocale.coefficient3} name="coefficient3" value={values.coefficient3} error={errors.coefficient3} />
        <Field as={Input} label={fieldsMeteoLocale.coefficient4} name="coefficient4" value={values.coefficient4} error={errors.coefficient4} />
        <Field as={Input} label={fieldsMeteoLocale.door} name="door" value={values.door} error={errors.door} />
      </Grid>
    </FormWrapper>
  );
};
