import { Box, Flex, Icon, Text, Textarea } from '@chakra-ui/react';
import { ErrorIcon } from 'components/icons';

interface FilePreviewValidationProps {
  errors?: string[];
}

export const FileLoadValidation: React.FC<FilePreviewValidationProps> = ({ errors = [] }) => {
  const errorsText = errors.map((error) => error).join('\n');
  return (
    <Box>
      <Flex alignItems="center" marginBottom={2}>
        <Icon as={ErrorIcon} stroke="text.error" />
        <Text ml={2} color="text.error">
          Ошибки:
        </Text>
      </Flex>
      <Textarea value={errorsText} border="1 px solid red" height="200px" />
    </Box>
  );
};
