import { useRequest } from 'hooks';
import { useEffect } from 'react';
import { IMainReport } from '../NoiseReport/types';
import { appealRoot, raidPlanned, raidSuccess } from 'pages/citizenAppeal';
import { noiseResearchTypeId } from '../../citizenAppeal/CitizensAppealsRecord/consts';

export const useBurningReport = (taskId?: string, taskStatus?: string, typeId?: string) => {
  const { get, result, isLoading } = useRequest<IMainReport>(`${appealRoot}/tasks/${taskId}/burning-journal`);

  useEffect(() => {
    if (
      ((taskId && taskStatus === raidPlanned) || (taskId && taskStatus === raidSuccess)) &&
      typeId !== noiseResearchTypeId
    ) {
      get();
    }
  }, [get, taskId, taskStatus, typeId]);

  return {
    result,
    isLoading,
    get,
  };
};
