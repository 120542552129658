import { FormWrapper } from 'components/common';
import { Flex, Grid } from '@chakra-ui/react';
import React, { Fragment } from 'react';
import FormControls from 'components/common/FormControls';
import { NSIDangerScalePaths } from 'pages/dictionaries/NSIDangerScale/const';
import { IRenderFieldInterface, useFormField } from 'hooks/forms/useFormField';
import { INSIDangerScaleForm } from 'pages/dictionaries/NSIDangerScale/types';
import { nsiDangerScaleSchema } from 'models/schemas/nsiDangerScale/nsiDangerScaleSchema';
import appLocale from 'constants/appLocale';
import { useGoBackWithFallback } from 'lib/utils/goBackWithFallback';
import { useCustomRights } from 'hooks/useCustomRights';
import { subsystemCodesMap } from 'constants/subsystemIdsMap';
import { useFormikContext } from 'formik';

export const NSIDangerScaleFormFields = () => {
  const { submitForm } = useFormikContext();
  const { backWithFallback } = useGoBackWithFallback();
  const { isWrite } = useCustomRights(subsystemCodesMap.nsi.dangerScale);

  const { renderField } = useFormField<INSIDangerScaleForm>({
    schema: nsiDangerScaleSchema,
    labelLocalization: appLocale.nsi.dangerScale.fields,
  });

  const fieldsIsDisabled = false;

  const fields: IRenderFieldInterface<INSIDangerScaleForm>[] = [
    { name: 'dangerScale', isDisabled: fieldsIsDisabled },
    { name: 'dutyScale', isDisabled: fieldsIsDisabled },
  ];

  return (
    <FormWrapper>
      <Grid marginTop="30px" gap="15px">
        {fields.map((field) => (
          <Fragment key={field.name}>{renderField(field)}</Fragment>
      ))}

        <Flex align="center">
          {renderField(
              { name: 'rangeStart', isDisabled: fieldsIsDisabled },
            )}
          {renderField(
              { name: 'rangeStartInclusive', isDisabled: fieldsIsDisabled, type: 'checkbox' },
            )}
        </Flex>

        <Flex align="center">
          {renderField(
            { name: 'rangeEnd', isDisabled: fieldsIsDisabled },
          )}
          {renderField(
            { name: 'rangeEndInclusive', isDisabled: fieldsIsDisabled, type: 'checkbox' },
          )}
        </Flex>

        <FormControls
          onCancel={() => backWithFallback(NSIDangerScalePaths.root)}
          handleSubmit={submitForm}
          submitIsDisabled={!isWrite}
        />
      </Grid>
    </FormWrapper>
  );
};
