import appLocale from 'constants/appLocale';
import { ATMO_RAID_LIST_PATH, NOISE_RAID_LIST_PATH, PEL_ROOT_PATH, PELTableView } from 'pages/pel/constants';
import { ReportSmall } from 'features/WithSideBar/icons';
import { TABLE_VIEW } from 'pages/citizenAppeal';
import { subsystemCodesMap } from 'constants/subsystemIdsMap';

export const pelRaidsMenu = {
  name: appLocale.pel.main,
  url: PEL_ROOT_PATH,
  id: PEL_ROOT_PATH,
  icon: <ReportSmall />,
  children: [
    {
      name: appLocale.pel.atmosphereRaidList,
      id: ATMO_RAID_LIST_PATH,
      url: ATMO_RAID_LIST_PATH,
      otherCode: subsystemCodesMap.PEL.pelAtmo,
    },
    {
      name: appLocale.pel.noiseRaidList,
      id: NOISE_RAID_LIST_PATH,
      url: NOISE_RAID_LIST_PATH,
      otherCode: subsystemCodesMap.PEL.pelNoise,
    },
    {
      name: TABLE_VIEW,
      url: `${PELTableView}/${0}`,
      id: PELTableView,
      otherCode: subsystemCodesMap.PEL.pelTableView,
    },
  ],
};
