import { memo } from 'react';
import { Box, Button, useDisclosure } from '@chakra-ui/react';
import { IFilterValues } from 'components/common';
import { ChartModal } from './ChartModal';

interface buttonGraphicLineReporting {
  subSystemId: string;
  templateId: string;
  setFiltersFromGraphic: (filters: IFilterValues) => void;
  defaultFilters: {
    startDate: string;
    stopDate: string;
  };
}

export const ButtonGraphicLineReporting = memo(({
  defaultFilters,
  setFiltersFromGraphic,
  templateId,
  subSystemId
}: buttonGraphicLineReporting) => {
  const { isOpen: isOpenGraphic, onClose: onCloseGraphic, onOpen: onOpenGraphic } = useDisclosure();

  return (
    <Box>
      <Button height="30px" variant="solid" size="sm" colorScheme="PrimaryButton" onClick={onOpenGraphic}>
        Открыть график
      </Button>
      {isOpenGraphic && (
        <ChartModal
          setFiltersFromGraphic={setFiltersFromGraphic}
          subSystemId={subSystemId}
          templateId={templateId}
          onClose={onCloseGraphic}
          isOpen={isOpenGraphic}
          defaultFilters={defaultFilters}
        />
      )}
    </Box>
  );
});
