import { AttachmentsTabContent, Button, DateInput, FormWrapper, Select } from 'components/common';
import { Box, Flex, Grid, Heading } from '@chakra-ui/react';
import {
  getSelectOptionsFromDictionaryByName,
  getSelectOptionsFromDictionaryByNameWithParent,
} from 'features/get-select-options-from-dictionary';
import { Field, useFormikContext } from 'formik';
import { createDateFromResponse, createDateToRequestWithTimezone } from 'lib/create-date';
import React, { useMemo, useState } from 'react';
import { useObjectType } from 'hooks/useObjectType';
import { FieldFormProps } from 'types/forms';
import { MonitoringItem } from 'pages/data/GeoecologicalProcesses/GeologicalProcessesGroundwater/Monitoring/types';
import subsystemIdsMap from 'constants/subsystemIdsMap';
import { useAvailability } from 'hooks';

const FieldsForm = ({ onCancel, dictionaries, media, onMediaChange, uploadItemId, isEditMode }: FieldFormProps) => {
  const { isAvailable } = useAvailability(subsystemIdsMap.geoecologyRoot);

  const { handleChange, handleSubmit, setFieldValue, values, errors, initialValues } =
    useFormikContext<MonitoringItem>();
  const [selectedWaterSourceType, setSelectedWaterSourceType] = useState('');

  const objectType = useObjectType(dictionaries);

  const types = useMemo(
    () => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'types', false, initialValues.waterSourceType),
    [dictionaries?.data, initialValues.waterSourceType],
  );

  const watersources = useMemo(() => {
    const dictionary = getSelectOptionsFromDictionaryByNameWithParent(dictionaries?.data, 'watersources');

    if (selectedWaterSourceType) {
      return dictionary.filter(({ parent }) => parent === selectedWaterSourceType);
    }

    return dictionary;
  }, [dictionaries?.data, selectedWaterSourceType]);

  const ecologicalStates = useMemo(
    () =>
      getSelectOptionsFromDictionaryByName(
        dictionaries?.data,
        'ecologicalStates',
        false,
        initialValues.ecologicalState?.value,
      ),
    [dictionaries?.data, initialValues.ecologicalState?.value],
  );

  const capturingStates = useMemo(
    () =>
      getSelectOptionsFromDictionaryByName(
        dictionaries?.data,
        'capturingStates',
        false,
        initialValues.capturingState?.value,
      ),
    [dictionaries?.data, initialValues.capturingState?.value],
  );

  return (
    <Box pb={4} flexDirection="column">
      <FormWrapper>
        <Grid marginTop="30px" gap="15px">
          <Heading variant="h2">Данные обследования</Heading>
          <DateInput
            name="measuringDate"
            label="Дата обследования"
            value={createDateFromResponse(values.measuringDate)}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setFieldValue('measuringDate', createDateToRequestWithTimezone(e.target.value))
            }
            showTimeInput={false}
            isDisabled={isEditMode}
          />
          <Field
            as={Select}
            name="waterSourceType"
            label="Тип пункта наблюдения"
            value={values.waterSourceType}
            options={types}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              setFieldValue('sourceId', '');
              setSelectedWaterSourceType(e.target.value);
              setFieldValue('waterSourceType', e.target.value);
            }}
            error={errors.waterSourceType}
            isDisabled={isEditMode}
          />
          <Field
            as={Select}
            name="sourceId"
            label="Пункт наблюдения"
            value={values.sourceId}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              if (e.target.value) {
                setFieldValue('waterSourceType', watersources.find((el) => el.value === e.target.value)?.parent);
              }
              setFieldValue('sourceId', e.target.value);
            }}
            options={watersources}
            error={errors.sourceId}
            isDisabled={isEditMode}
          />
          <Field
            as={Select}
            name="ecologicalState.value"
            label="Санитарное (экологическое) состояние"
            value={values.ecologicalState?.value}
            options={ecologicalStates}
            onChange={handleChange}
            isDisabled={!isAvailable}
            error={errors.ecologicalState?.value}
          />
          <Field
            as={Select}
            name="capturingState.value"
            label="Состояние каптажа"
            value={values.capturingState?.value}
            options={capturingStates}
            onChange={handleChange}
            isDisabled={!isAvailable}
            error={errors.capturingState?.value}
          />

          {onMediaChange && isAvailable && (
            <AttachmentsTabContent
              objectType={objectType}
              media={media}
              onMediaChange={onMediaChange}
              uploadItemId={uploadItemId}
            />
          )}

          <Flex>
            <Button onClick={onCancel} marginRight="auto">
              Отмена
            </Button>
            <Button isDisabled={!isAvailable} onClick={() => handleSubmit()} variant="solid" type="button">
              Сохранить
            </Button>
          </Flex>
        </Grid>
      </FormWrapper>
    </Box>
  );
};

export default React.memo(FieldsForm);
