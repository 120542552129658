import React from 'react';
import { Box, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { AttachmentsTabContent, DefaultText, FieldsFormControls, FormHeader, FormWrapper } from 'components/common';
import { IUploadMedia } from 'components/common/FileUploadDragAndDrop/types';
import { UploadObjectType } from 'api/services/media';
import { FormikProvider, useFormik } from 'formik';
import { Device } from 'pages/equipment/Devices/types';
import * as yup from 'yup';
import { createTouchedErrors } from 'features/create-touched-errors';
import { useEnhancedNotification } from 'hooks';
import { requiredError } from 'models/schemas/constans';

interface DeviceModelLayoutProps {
  title: string;
  device: React.ReactNode;
  verification?: React.ReactNode;
  value?: number;
  handleChange?: (newValue: number) => void;
  onMediaChange?: (media: IUploadMedia[]) => void;
  media?: IUploadMedia[];
  uploadItemId?: string;
  onCancel: () => void;
  onSubmit: (values: any) => void;
  initialValues: Partial<Device>;
}

export const validationSchema: any = yup.object().shape({
  equipmentSerial: yup.string().required(requiredError),
  equipmentInvent: yup.string().required(requiredError),
  status: yup.string().nullable().required(requiredError),
  deviceName: yup.string().nullable().required(requiredError),
  modelIds: yup.array().of(yup.string()).min(1, requiredError).required(requiredError),
  localDate: yup.string().nullable().required(requiredError),
});

export const DeviceLayout = ({
  title,
  device,
  verification,
  value,
  handleChange,
  media,
  onMediaChange,
  uploadItemId,
  onSubmit,
  onCancel,
  initialValues,
}: DeviceModelLayoutProps) => {
  const { onValidationError } = useEnhancedNotification();

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    validationSchema,
    initialValues: {
      ...initialValues,
      modelIds: initialValues.models?.map((item) => item.id) || [],
    },
    onSubmit,
  });

  return (
    <Box flexDirection="column">
      <Tabs index={value} onChange={handleChange}>
        <FormHeader onBackClick={onCancel} header={title}>
          <TabList>
            <Tab>
              <DefaultText>Прибор</DefaultText>
            </Tab>
            <Tab isDisabled={!verification}>
              <DefaultText>Поверка</DefaultText>
            </Tab>
            <Tab>
              <DefaultText>Вложения</DefaultText>
            </Tab>
          </TabList>
        </FormHeader>
        <TabPanels>
          <TabPanel px={2}>
            <FormikProvider
              value={{
                ...formik,
                errors: createTouchedErrors(formik.errors, formik.touched),
              }}
            >
              {device}
            </FormikProvider>
          </TabPanel>
          <TabPanel px={2}>{verification}</TabPanel>
          <TabPanel px={2}>
            <FormWrapper>
              <AttachmentsTabContent
                objectType={UploadObjectType.EQUIPMENT_DEVICE}
                media={media}
                onMediaChange={onMediaChange}
                uploadItemId={uploadItemId}
              >
                <FieldsFormControls
                  onCancel={onCancel}
                  handleSubmit={() => {
                    if (Object.keys(formik.errors).length > 0) {
                      onValidationError();
                    }
                    formik.handleSubmit();
                  }}
                  showSave
                />
              </AttachmentsTabContent>
            </FormWrapper>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};
