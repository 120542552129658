import { FormikProvider, FormikValues, useFormik } from 'formik';
import map from 'lodash/map';
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Button, IFilterValues, useSetFiltersFromGraphic } from 'components/common';
import { Box, Flex, Grid } from '@chakra-ui/react';
import { FilterItem, RenderFilterFieldsByType } from './FilterFields';
import { PresetFieldsProps, PresetsFields } from './PresetsFields';
import { IFilterItem } from 'models';
import { IPeriod } from '../PeriodsButtons/types';
import { PeriodsButtons } from '../PeriodsButtons';
import { createDateToRequestWithTimezone } from 'lib/create-date';
import { usePeriods } from '../PeriodsButtons/usePeriods';
import FilterFormControls from 'components/form/FilterFormControls';

type TSubmitButtons = {
  resetTitle: string;
  submitTitle: string;
};

type FilterProps<T = any> = {
  isLoading?: boolean;
  isDisabled?: boolean;
  mainFields?: FilterItem[];
  presetFields?: PresetFieldsProps;
  initialValues: T;
  onSubmit: (values: T) => void | any;
  onValues?: (values: T) => void;
  filtersFromGraphic?: Partial<IFilterValues> | null;
  setFiltersFromGraphic?: (value: Partial<IFilterValues> | null) => void;
  presetValues?: { key: string; value: IFilterItem['value'] }[];
  periods?: IPeriod[] | null;
  dateFieldFrom?: string;
  dateFieldTo?: string;
  savedPeriodId?: string;
  isSubmitDisabled?: (values: T) => boolean;
  customSubmitButtons?: TSubmitButtons;
  onClear?(): void;
  validationSchema?: any;
  isValidationOnClick?: boolean;
  setGraphInitialFilters?: (filters: any) => void;
};

const Filter = <T extends FormikValues>({
  initialValues,
  mainFields,
  isLoading,
  isDisabled,
  presetFields,
  onSubmit,
  onValues,
  filtersFromGraphic,
  setFiltersFromGraphic,
  children,
  presetValues,
  periods,
  dateFieldFrom = '',
  dateFieldTo = '',
  savedPeriodId,
  isSubmitDisabled,
  customSubmitButtons,
  onClear,
  validationSchema,
  isValidationOnClick = true,
  setGraphInitialFilters,
}: React.PropsWithChildren<FilterProps<T>>) => {
  const savedPeriod = useMemo(() => savedPeriodId || '', [savedPeriodId]);
  const [periodId, setPeriodId] = useState<string>(savedPeriod);
  const shouldSetGraphInitialFilters = useRef(false);

  const { getDatesFromPeriod, dates } = usePeriods(periodId, periods);

  const submitPeriod = (id: string) => {
    const dates = getDatesFromPeriod(id);
    setFieldValue('periodId', id);
    setFieldValue(dateFieldFrom, createDateToRequestWithTimezone(dates[0]));
    setFieldValue(dateFieldTo, createDateToRequestWithTimezone(dates[1]));
    setFieldTouched(dateFieldFrom);
    setPeriodId(id);
  };

  const {
    values,
    errors,
    getFieldMeta,
    getFieldHelpers,
    getFieldProps,
    handleReset,
    submitForm,
    setFieldValue,
    setFieldTouched,
    ...formik
  } = useFormik<typeof initialValues>({
    enableReinitialize: !isValidationOnClick,
    validateOnChange: !isValidationOnClick,
    validateOnMount: !isValidationOnClick,
    validateOnBlur: !isValidationOnClick,
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      const filters = onSubmit(values);
      if (filters && shouldSetGraphInitialFilters.current && setGraphInitialFilters) {
        shouldSetGraphInitialFilters.current = false;
        setGraphInitialFilters(filters);
      }
    },
  });

  useEffect(() => {
    presetValues?.forEach((value) => {
      if (value.value !== undefined && value.key) {
        setFieldValue(value.key, value.value);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [presetValues]);

  useSetFiltersFromGraphic({
    dateKeyMin: 'dateTimeMin',
    dateKeyMax: 'dateTimeMax',
    setFiltersFromGraphic,
    filtersFromGraphic,
    setFieldValue,
    onChange: submitForm,
  });

  useEffect(() => {
    if (onValues) {
      onValues(values);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  const Actions = useMemo(
    () => (
      <FilterFormControls
        onReset={(e) => {
          handleReset(e);
          setPeriodId('');
          setTimeout(submitForm, 0);
        }}
        onSubmit={() => {
          shouldSetGraphInitialFilters.current = true;
          submitForm();
        }}
        isLoading={isLoading}
        isDisabled={isDisabled}
      />
    ),
    [handleReset, isDisabled, isLoading, submitForm]
  );

  const getFieldAllProps = useCallback(
    (name: string) => ({
      ...getFieldMeta(name),
      ...getFieldProps(name),
      ...getFieldHelpers(name),
    }),
    [getFieldHelpers, getFieldMeta, getFieldProps]
  );

  const mainFieldsMemo = useMemo(
    () =>
      map(mainFields, (field) => ({
        ...field,
        ...getFieldAllProps(field.name),
      })),
    [getFieldAllProps, mainFields]
  );

  const templateComlums = useMemo(() => {
    if (mainFields && mainFields.length < 5 && mainFields?.[0]?.type === 'DATE' && mainFields?.[1]?.type === 'DATE') {
      return 'repeat(2, 220px) 1fr 1fr';
    }
    return '1fr 1fr 1fr 1fr';
  }, [mainFields]);

  return (
    <Box margin={0} padding={0}>
      <FormikProvider
        value={{
          values,
          errors,
          getFieldMeta,
          getFieldHelpers,
          getFieldProps,
          handleReset,
          submitForm,
          setFieldValue,
          setFieldTouched,
          ...formik,
        }}
      >
        <Grid gap="8px">
          {presetFields && <PresetsFields {...presetFields} setFieldValue={setFieldValue} />}
          <Flex flexDirection="row" justifyContent="space-between">
            <Grid gap="8px" width="100%" maxWidth="1200px" gridTemplateColumns={templateComlums}>
              {Boolean(mainFields?.length) && (
                <RenderFilterFieldsByType
                  fields={mainFieldsMemo}
                  maxDate={periods ? dates?.[1] : null}
                  minDate={periods ? dates?.[0] : null}
                  dateFieldFrom={dateFieldFrom}
                  dateFieldTo={dateFieldTo}
                  isDisabled={(periods ? !periodId : false) || !!isDisabled}
                />
              )}
              {children}
            </Grid>
            {Boolean(mainFields?.length) && !customSubmitButtons && Actions}
          </Flex>
        </Grid>

        {customSubmitButtons && (
          <Flex marginTop={2}>
            <Button
              onClick={(e: any) => {
                handleReset(e);
                if (onClear) {
                  onClear();
                }
              }}
              marginRight={4}
            >
              {customSubmitButtons.resetTitle}
            </Button>
            <Button
              onClick={() => formik.handleSubmit()}
              variant="solid"
              disabled={isSubmitDisabled && isSubmitDisabled(values)}
            >
              {customSubmitButtons.submitTitle}
            </Button>
          </Flex>
        )}
        {periods ? <PeriodsButtons periodId={periodId} periods={periods} submitPeriod={submitPeriod} /> : null}
      </FormikProvider>
    </Box>
  );
};

const genericMemo: <T>(component: T) => T = memo;
export const GenericMemoComponent = genericMemo(Filter);
export { GenericMemoComponent as Filter };
