import { Breadcrumb, BreadcrumbButton, PageHeader } from 'components/common';
import React, { FC } from 'react';
import { Grid, Heading } from '@chakra-ui/react';

interface FormHeaderProps {
  url?: string;
  gap?: number;
  state?: any;
  customHeader?: React.ReactNode;
  onBackClick?: () => void;
  header?: string;
}

export const FormHeader: FC<FormHeaderProps> = ({
  url,
  header,
  gap = 8,
  children,
  state,
  customHeader,
  onBackClick,
}) => (
  <PageHeader>
    <Grid padding="0px 110px" gap={gap}>
      {onBackClick && <BreadcrumbButton onClick={onBackClick}>Вернуться назад</BreadcrumbButton>}
      {url && !onBackClick && <Breadcrumb to={{ pathname: url, state }}>Вернуться назад</Breadcrumb>}
      {
          // eslint-disable-next-line
          customHeader ? customHeader : <Heading variant="h1">{header}</Heading>
        }
      {children}
    </Grid>
  </PageHeader>
);
