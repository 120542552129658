import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { BreadcrumbButton, Button, LoaderItem, PageHeader, StyledTab } from 'components/common';
import { Box, Flex, Heading, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { TaskFormer } from './TaskFormer';
import { AttachedAppeals } from './AttachedAppeals';
import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import {
  ADD_MEDIA_ERROR,
  appealRoot,
  Appeals,
  CitizensAppealsPath,
  draftStatus,
  inProgressStatus,
  raidPlanned,
  raidSuccess,
  raisNotSuccess,
  ReportNames,
  taskFormerApiPath,
  TaskFormerPath,
  tasksDictionariesPath,
} from 'pages/citizenAppeal';
import { CompletionReports } from './CompletionReports';
import { FormikProvider, useFormik } from 'formik';
import { useNotifications } from 'hooks/useNotifications';
import { ERROR_MESSAGES, SUCCESS_MESSAGES } from 'constants/messages';
import { useRequest } from 'hooks/useRequest';
import { getSelectOptionsFromDictionaryByName } from 'features/get-select-options-from-dictionary';
import { ITaskFormer } from './types';
import { Letters } from './Letters/Letters';
import { useLocation } from 'react-router-dom';
import { AtmoMainReport, garbageBurningReportsSchema, NoiseMainReport, TaskSchema } from 'models/schemas';
import { initialEmptyValues } from 'pages/pel/constants';
import { IMainReport } from 'pages/pel/NoiseReport/types';
import { useMainReport } from 'pages/pel/NoiseReport/useMainReport';
import { useGarbageReport } from 'pages/pel/GarbageJournal/useGarbageReport';
import { useBurningReport } from 'pages/pel/BurningJournal/useBurningReport';
import { ReportHeader } from 'pages/pel/components';
import { useEnhancedNotification } from 'hooks';
import { CompareIconBlue, ViewIcon2 } from 'components/icons';
import { updateFiles, UploadObjectType } from 'api/services/media';
import { IUploadMedia } from 'components/common/FileUploadDragAndDrop/types';
import { generateUUID } from 'lib/utils/generateUUID';
import { TaskPrepareContext } from './TaskPrepareContext';
import { useGoBackWithFallback } from 'lib/utils/goBackWithFallback';
import { useDictionary } from 'hooks/useDictionary';
import { airResearchTypeId, noiseResearchTypeId } from '../consts';
import { IComplaint } from './AttachedAppeals/types/types';
import { getReportHeading } from 'pages/pel/components/ReportsHeader';
import { cloneDeep } from 'lodash';

interface Props {
  breadcrumbs?: { path: string; breadcrumb: string }[];
}

const initialValues = {
  deleted: false,
  districts: [''],
  id: '',
  mission: '',
  name: '',
  performer: '',
  points: [],
  priority: '',
  priorityId: '',
  raidId: '',
  reason: '',
  reasonId: '',
  regions: [''],
  shift: '',
  shiftId: '',
  startDate: '',
  deadlineDate: '',
  statusName: '',
  status: draftStatus,
  targetTime: '',
  territoryName: '',
  type: '',
  typeId: '',
  taskNumber: '',
  number: '',
  taskTypeId: '',
  taskTypeName: '',
};

const initialBreadcrumbs = [
  {
    breadcrumb: 'Учет обращений граждан',
    path: CitizensAppealsPath,
  },
  {
    breadcrumb: 'Обращения граждан',
    path: Appeals,
  },
  {
    breadcrumb: 'Подготовка заданий',
    path: TaskFormerPath,
  },
];

export const TaskPreparing = ({ breadcrumbs = initialBreadcrumbs }: Props) => {
  const location = useLocation();
  const isNoiseRaid = location.pathname.includes('noise');
  const { backWithFallback } = useGoBackWithFallback();
  const [browserId] = useState(generateUUID());
  const { state: locState } = useLocation();
  const { get: getTask, result: recievedTask } = useRequest<any>(`${taskFormerApiPath}/${locState?.id}`);
  useEffect(() => {
    if (locState?.id) {
      void getTask();
    }
  }, [getTask, locState]);

  const [media, setMedia] = useState<IUploadMedia[][]>([[]]);

  const [reportMedia, setReportMedia] = useState<Record<ReportNames, IUploadMedia[][]>>({
    main: [[]],
    atmosphere: [[]],
    garbage: [[]],
  });

  const [reportIndex, setReportIndex] = useState(0);
  const [subTypeIndex, setSubTypeIndex] = useState(locState?.tabIndex || 0);
  const { successNotify, errorNotify } = useNotifications();
  const { onSuccessEdit, onErrorEdit } = useEnhancedNotification();
  const { successNotify: successTaskUpdate, errorNotify: errorTaskUpdate } = useNotifications();

  const [complaints, setComplaints] = useState<any>([]);

  const [noiseProtocolId, setNoiseProtocolId] = useState<string>('');
  const [noisePointId, setNoisePointId] = useState<string>('');

  const editMessage = useRef<string>();

  // loading complaints for letters form START
  const onComplaintsSuccess = useCallback(
    (response) => {
      if (response?.data) {
        const forSelect = response.data.map((appeal: IComplaint) => ({
          name: appeal.number,
          value: appeal.id,
        }));
        setComplaints(forSelect);
      }
    },
    [setComplaints],
  );

  const { post: getAttachedComplaints } = useRequest(
    `appeal/v10/tasks/${recievedTask?.id}/complaints`,
    onComplaintsSuccess,
  );

  useEffect(() => {
    if (recievedTask?.id && subTypeIndex === 3) {
      void getAttachedComplaints({
        limit: -1,
        offset: 0,
        filters: [
          {
            key: 'deleted',
            operation: 'EQ',
            value: false,
          },
        ],
      });
    }
  }, [getAttachedComplaints, subTypeIndex, recievedTask?.id]);
  // loading complaints for letters form END

  const {
    result: mainReport,
    isLoading: isMainLoading,
    get: getMainReport,
  } = useMainReport(recievedTask?.id, recievedTask?.status);

  useEffect(() => {
    setNoiseProtocolId('');
    setNoisePointId('');
  }, [mainReport]);

  const {
    result: garbageReport,
    isLoading: isGarbageLoading,
    get: getGarbageReport,
  } = useGarbageReport(recievedTask?.id, recievedTask?.status, recievedTask?.typeId);
  const {
    result: burningReport,
    isLoading: isBurnLoading,
    get: getBurningReport,
  } = useBurningReport(recievedTask?.id, recievedTask?.status, recievedTask?.typeId);

  const reloadGarbageBurningReports = () => {
    if (recievedTask?.typeId !== noiseResearchTypeId) {
      void getGarbageReport();
      void getBurningReport();
    }
    void getTask();
  };

  const onUpdateMainReport = (result: ITaskFormer | null) => {
    if (result?.points) {
      mainReportFormik.setFieldValue('points', result.points);
    }
    successNotify({
      key: 'file/edit/success',
      message: SUCCESS_MESSAGES.EDIT,
    });
    reloadGarbageBurningReports();
  };

  const { put: updateMainReport } = useRequest(
    `${appealRoot}/tasks/${recievedTask?.id}/main-report`,
    onUpdateMainReport,
    onErrorEdit,
  );
  const { put: updateGarbageReport } = useRequest(
    `${appealRoot}/tasks/${recievedTask?.id}/garbage-journal`,
    () => onSuccessEdit(),
    onErrorEdit,
  );
  const { put: updateBurningReport } = useRequest(
    `${appealRoot}/tasks/${recievedTask?.id}/burning-journal`,
    () => onSuccessEdit(),
    onErrorEdit,
  );

  const onSuccessTaskCreate = (result: ITaskFormer | null) => {
    successNotify({
      key: 'file/add/success',
      message: editMessage.current || SUCCESS_MESSAGES.CREATE,
    });
    editMessage.current = undefined;
    void getTask({ url: `${taskFormerApiPath}/${result?.id}` });
  };
  const onErrorTaskCreate = () => {
    errorNotify({
      key: 'file/add/error',
      message: editMessage.current ? 'Ошибка при передаче задания в работу' : ERROR_MESSAGES.CREATE,
    });
    editMessage.current = undefined;
  };

  const onSuccessTaskUpdate = (result: ITaskFormer | null) => {
    successTaskUpdate({
      key: 'task/takeToWork/success',
      message: editMessage.current || SUCCESS_MESSAGES.EDIT,
    });
    editMessage.current = undefined;
    void getTask({ url: `${taskFormerApiPath}/${result?.id}` });
    getMainReport();
  };

  const onErrorTaskUpdate = () => {
    errorTaskUpdate({
      key: 'task/takeToWork/success',
      message: editMessage.current ? 'Ошибка при передаче задания в работу' : ERROR_MESSAGES.EDIT,
    });
    editMessage.current = undefined;
  };

  const onCopyTaskSuccess = () => {
    successNotify({
      key: 'file/copy/success',
      message: SUCCESS_MESSAGES.COPY_TASK,
    });
  };

  const onCopyTaskError = () => {
    errorNotify({
      key: 'file/copy/error',
      message: ERROR_MESSAGES.COPY_TASK,
    });
  };

  const formattedBreakpoints = useMemo(() => {
    const isReport = subTypeIndex === 2;
    const br = cloneDeep(breadcrumbs);
    if (isReport) {
      br[br.length - 1] = {
        ...br[br.length - 1],
        breadcrumb: getReportHeading(recievedTask?.type),
      };
    }

    return br;
  }, [breadcrumbs, subTypeIndex, recievedTask?.type]);

  useUpdateBreadcrumbs(formattedBreakpoints);

  const { post } = useRequest(taskFormerApiPath, onSuccessTaskCreate, onErrorTaskCreate);
  const { post: copyStatus } = useRequest(taskFormerApiPath, onCopyTaskSuccess, onCopyTaskError);
  const { put } = useRequest(taskFormerApiPath, onSuccessTaskUpdate, onErrorTaskUpdate);
  const { dictionaries } = useDictionary({ url: tasksDictionariesPath });

  const taskResearchTypes = useMemo(
    () => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'taskResearchTypes', false, initialValues.typeId),
    [dictionaries],
  );
  const pointTypes = useMemo(
    () => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'pointTypes', false),
    [dictionaries],
  );

  const taskReasonsNoise = useMemo(
    () => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'taskReasonsNoise'),
    [dictionaries],
  );

  const taskReasonsAir = useMemo(
    () => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'taskReasonsAir'),
    [dictionaries],
  );

  const taskShiftsNoise = useMemo(
    () => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'taskShiftsNoise'),
    [dictionaries],
  );
  const taskShiftsAir = useMemo(
    () => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'taskShiftsAir'),
    [dictionaries],
  );
  const districts = useMemo(
    () => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'districts', false, recievedTask?.districts || []),
    [dictionaries?.data, recievedTask?.districts],
  );
  const regions = useMemo(
    () => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'regions', false, recievedTask?.regions || []),
    [dictionaries?.data, recievedTask?.regions],
  );
  const taskPriorities = useMemo(
    () => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'taskPriorities'),
    [dictionaries],
  );
  const taskStatuses = useMemo(
    () => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'taskStatuses'),
    [dictionaries],
  );
  const taskTypesNoise = useMemo(
    () => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'taskTypesNoise'),
    [dictionaries],
  );
  const taskTypesAir = useMemo(
    () => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'taskTypesAir'),
    [dictionaries],
  );

  const onMediaError = useCallback(
    (message: string) => {
      errorNotify({
        key: 'file/add-media/error',
        message: `${ADD_MEDIA_ERROR}. ${message}`,
      });
    },
    [errorNotify],
  );

  const onSaveReport = useCallback(
    async (saveReport: () => any, objectType: UploadObjectType, reportType: ReportNames) => {
      const response = await saveReport();
      if (response && response.points) {
        response?.points?.forEach((point: any, idx: number) => {
          if (reportMedia[reportType][idx]) {
            updateFiles(point.id, objectType, reportMedia[reportType][idx])
              .then((response?: IUploadMedia[]) => {
                if (response) {
                  const updatedMedia = reportMedia[reportType];
                  if (response) {
                    updatedMedia[idx] = response;
                  }
                  setReportMedia((prevState) => ({
                    ...prevState,
                    [reportType]: updatedMedia,
                  }));
                }
              })
              .catch((error: Error) => {
                onMediaError(error.message);
              });
          }
        });
      }
    },
    [onMediaError, reportMedia],
  );

  const onSubmit = (values: ITaskFormer) => {
    if (recievedTask?.id) {
      void onSave(() => put(values));
    } else {
      void onSave(() => post(values));
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    validationSchema: TaskSchema,
    initialValues: { ...initialValues, typeId: isNoiseRaid ? noiseResearchTypeId : airResearchTypeId, ...recievedTask },
    onSubmit,
  });
  const onSave = useCallback(
    async (saveTask: () => any) => {
      const response = await saveTask();
      if (response) {
        response.points?.forEach((point: any, idx: number) => {
          if (media[idx]) {
            updateFiles(point.id, UploadObjectType.OBSERVATION_POINT, media[idx])
              .then((response?: IUploadMedia[]) => {
                setMedia((prevState) => {
                  const newMedia = [...prevState];
                  if (response) {
                    newMedia[idx] = response;
                  }
                  return newMedia;
                });
              })
              .catch((error: Error) => {
                onMediaError(error.message);
              });
          }
        });
      }
    },
    [media, onMediaError],
  );
  const mainReportFormik = useFormik<IMainReport>({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    validationSchema: recievedTask?.type === 'Шум' ? NoiseMainReport : AtmoMainReport,
    initialValues: { ...initialEmptyValues, ...mainReport },
    onSubmit: (values: IMainReport) => {
      void onSaveReport(() => updateMainReport(values), UploadObjectType.REPORT_OBSERVATION_POINT, ReportNames.MAIN);
    },
  });

  const garbageReportFormik = useFormik<IMainReport>({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    validationSchema: garbageBurningReportsSchema,
    initialValues: { ...initialEmptyValues, ...garbageReport },
    onSubmit: (values: IMainReport) => {
      void onSaveReport(
        () => updateGarbageReport(values),
        UploadObjectType.JOURNAL_OBSERVATION_POINT,
        ReportNames.GARBAGE,
      );
    },
  });

  const burningReportFormik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    validationSchema: garbageBurningReportsSchema,
    initialValues: { ...initialEmptyValues, ...burningReport },
    onSubmit: (values: IMainReport) => {
      void onSaveReport(
        () => updateBurningReport(values),
        UploadObjectType.JOURNAL_OBSERVATION_POINT,
        ReportNames.ATMOSPHERE,
      );
    },
  });

  const onCopyTask = () => {
    const { values } = formik;
    formik.setFieldValue('status', draftStatus);
    if (recievedTask?.id) {
      void copyStatus({ ...values, status: draftStatus, id: '' });
    }
  };

  const onStatusChange = (status: any) => {
    formik.setFieldValue('status', status);
    let msg: string | undefined;
    if (status === inProgressStatus) {
      msg = 'Задание успешно передано в работу';
    }
    if (status === raidSuccess) {
      msg = 'Выполнение задание подтверждено';
    }

    editMessage.current = msg;
    formik.handleSubmit();
  };

  const isReportAvailable =
    Boolean(recievedTask?.id) &&
    (recievedTask?.status === raidPlanned ||
      recievedTask?.status === raidSuccess ||
      recievedTask?.status === raisNotSuccess);

  return (
    <>
      {(isMainLoading || isBurnLoading || isGarbageLoading) && <LoaderItem />}

      <FormikProvider
        value={{
          ...formik,
        }}
      >
        <TaskPrepareContext.Provider
          value={{
            dictionaries,
            recievedTask,
            complaints,
            setComplaints,
            getMainReport,
            noiseProtocolId,
            noisePointId,
            setNoiseProtocolId,
            setNoisePointId,
          }}
        >
          <Box width="100%">
            {subTypeIndex !== 2 ? (
              <PageHeader>
                <BreadcrumbButton onClick={() => backWithFallback(breadcrumbs[breadcrumbs?.length - 2].path)}>
                  Вернуться назад
                </BreadcrumbButton>
                <Flex justifyContent="space-between" marginBottom={5}>
                  <Heading>Подготовка заданий</Heading>
                  <Flex alignItems="center">
                    <Button
                      leftIcon={<ViewIcon2 />}
                      variant="ghost"
                      color="PrimaryButton.500"
                      size="sm"
                      p={0}
                      onClick={() => onCopyTask()}
                      isDisabled={!recievedTask?.id}
                      marginRight={5}
                    >
                      Копировать задание
                    </Button>
                    <Button
                      leftIcon={<CompareIconBlue />}
                      variant="ghost"
                      color="PrimaryButton.500"
                      size="sm"
                      p={0}
                      onClick={
                        () => onStatusChange(taskStatuses.find((item) => item.value === inProgressStatus)?.value)
                      }
                      isDisabled={formik.values.status !== draftStatus || !!Object.keys(formik.errors).length}
                    >
                      Передать в работу
                    </Button>
                  </Flex>
                </Flex>
              </PageHeader>
            ) : (
              <ReportHeader
                reportType={recievedTask?.type}
                onDone={() => onStatusChange(taskStatuses.find((item) => item.value === raidSuccess)?.value)}
              />
            )}
            <Tabs
              isLazy
              index={subTypeIndex}
              onChange={(index) => {
                setSubTypeIndex(index);
              }}
            >
              <TabList>
                <StyledTab>Постановка задачи</StyledTab>
                <StyledTab isDisabled={!recievedTask}>Привязанные обращения</StyledTab>
                <StyledTab isDisabled={!isReportAvailable}>Отчет о выполнении</StyledTab>
                <StyledTab isDisabled={!recievedTask}>Письма</StyledTab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  <TaskFormer
                    media={media}
                    onMediaDelete={(index: number) => {
                      setMedia((prevState) => {
                        const newMedia = [...prevState];
                        newMedia.splice(index, 1);
                        return newMedia;
                      });
                    }}
                    onMediaSave={(newItem: IUploadMedia[], idx: number) => {
                      setMedia((prevState) => {
                        const newMedia = [...prevState];
                        newMedia[idx] = newItem;
                        return newMedia;
                      });
                      formik.handleSubmit();
                    }}
                    pointTypes={pointTypes}
                    taskResearchTypes={taskResearchTypes}
                    taskReasonsNoise={taskReasonsNoise}
                    taskReasonsAir={taskReasonsAir}
                    taskShiftsAir={taskShiftsAir}
                    taskShiftsNoise={taskShiftsNoise}
                    districts={districts}
                    regions={regions}
                    taskPriorities={taskPriorities}
                    backPath={breadcrumbs[breadcrumbs?.length - 2].path}
                    browserId={browserId}
                    taskTypesAir={taskTypesAir}
                    taskTypesNoise={taskTypesNoise}
                    isNoiseRaid={isNoiseRaid}
                  />
                </TabPanel>
                <TabPanel>
                  <AttachedAppeals id={recievedTask?.id} setComplaints={setComplaints} />
                </TabPanel>
                <TabPanel>
                  <CompletionReports
                    media={reportMedia}
                    setMedia={setReportMedia}
                    reportTypeId={recievedTask?.typeId}
                    taskId={recievedTask?.id}
                    garbageReportFormik={garbageReportFormik}
                    mainReportFormik={mainReportFormik}
                    burningReportFormik={burningReportFormik}
                    reportIndex={reportIndex}
                    setReportIndex={setReportIndex}
                    backPath={breadcrumbs[breadcrumbs?.length - 2].path}
                    browserId={browserId}
                  />
                </TabPanel>
                <TabPanel>{recievedTask ? <Letters task={recievedTask} /> : null}</TabPanel>
              </TabPanels>
            </Tabs>
          </Box>
        </TaskPrepareContext.Provider>
      </FormikProvider>

    </>
  );
};
