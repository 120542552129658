import { SubsystemHeader } from 'components/common';
import { NavTabList } from 'features/NavTabList';
import { GreenPlantsFloraUrl, GreenPlantsLawnsUrl } from 'pages/routes/PGRGreenPlants';
import React, { memo } from 'react';

interface GreenPlantsDataHeaderProps {
  onOpenLoad(): void;
  handleAdd(): void;
  isReadonly?: boolean;
  isWrite?: boolean;
  isNoRights?: boolean;
  isExecute?: boolean;
  allAvailable?: boolean;
  filtersComponent?: React.ReactNode;
}

export const GreenPlantsDataHeader: React.FC<GreenPlantsDataHeaderProps> = memo(
  ({ handleAdd, onOpenLoad, children, isWrite, isNoRights, allAvailable, filtersComponent }) => {
    const isAvailable = allAvailable || isNoRights || isWrite;

    return (
      <>
        <SubsystemHeader
          title="Данные по зеленым насаждениям"
          addButtonProps={{
            onClick: handleAdd,
            isDisabled: !isWrite
          }}
          onUploadClick={isAvailable ? onOpenLoad : undefined}
          navigationTabs={(
            <NavTabList
              tabs={[
                {
                  label: 'Деревья и кустарники',
                  path: GreenPlantsFloraUrl,
                },
                {
                  label: 'Газоны',
                  path: GreenPlantsLawnsUrl,
                },
              ]}
            />
          )}
          filtersComponent={filtersComponent}
        />

        {children}
      </>
    );
  }
);
