import { ASKZA } from 'pages/data/Meteo/ASKZA/ASKZA';
import { WithDidMountResetUploadFile } from 'features/UploadedFileProvider/hocs';
import { AddResult } from 'pages/data/Meteo/ASKZA/Forms/AddResult';
import { Route } from 'react-router-dom';

const MeteoASKZARoutes = () => (
  <>
    <Route exact path="/data/meteo/data/askza" render={() => <WithDidMountResetUploadFile Component={ASKZA} />} />

    <Route exact path="/data/meteo/data/askza/add" render={() => <WithDidMountResetUploadFile Component={AddResult} />} />
  </>
);

export { MeteoASKZARoutes };
