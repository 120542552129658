import { Button, FormWrapper, LoaderItem, Select } from 'components/common';
import { Box, Flex, Grid } from '@chakra-ui/react';
import { Field, useFormikContext } from 'formik';
import { useNotifications } from 'hooks';
import { baseAdminUrl } from 'pages/admin';
import { NotificationSettingsItem } from 'pages/admin/models/NotificationSettingsItem';
import { NotificationSource } from 'pages/admin/models/NotificationSource';
import { NotificationType } from 'pages/admin/models/NotificationType';
import { RoleItem } from 'pages/admin/models/RoleItem';
import { SubsystemItem } from 'pages/admin/models/SubsystemItem';
import { TOption } from 'pages/admin/models/toption';
import { usePaginatedLoader } from 'pages/admin/typedLoader';
import React from 'react';

export interface FieldFormProps {
  onCancel: () => void;
  isLoading: boolean | undefined;
}

const FieldsForm: React.FC<FieldFormProps> = ({ onCancel, isLoading }) => {
  const { setFieldValue, values, errors, handleSubmit, isValid } = useFormikContext<NotificationSettingsItem>();
  const { errorNotify } = useNotifications();

  const { result: roleList, isLoading: isRoleListLoading } = usePaginatedLoader<RoleItem>(`${baseAdminUrl}/role`);
  const users =
    roleList?.data.map((r) => ({
      name: `${r.name} (${r.code})`,
      value: r.id,
    })) || [];

  const { result: subsystems, isLoading: isSubsystemsLoading } = usePaginatedLoader<SubsystemItem>(
    `${baseAdminUrl}/subsystem`,
  );

  const sources = Object.entries(NotificationSource).map(
    (v) =>
      ({
        value: v[0],
        name: v[1],
      } as TOption),
  );

  const methods = Object.entries(NotificationType).map(
    (v) =>
      ({
        value: v[0],
        name: v[1],
      } as TOption),
  );

  const subsystemOptions: TOption[] =
    subsystems?.data.map(
      (s: SubsystemItem) =>
        ({
          value: s.id,
          name: `${s.name} ${s.module ? `(${s.module})` : ''}`,
        } as TOption),
    ) || [];

  const mmap = new Map<string, string>();
  methods.forEach((v: TOption) => mmap.set(v.value, v.name));

  if (isLoading || isRoleListLoading || isSubsystemsLoading) {
    return <LoaderItem />;
  }
  return (
    <Box flexDirection="column">
      <FormWrapper>
        <Grid marginTop={5} marginBottom={5} gap={2}>
          <Field
            as={Select}
            label="Роль"
            name="role"
            value={values.roleId}
            options={users}
            error={errors?.roleId}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              setFieldValue('roleId', e.target.value);
            }}
          />
          <Field
            as={Select}
            label="Тип уведомления"
            name="sources"
            multiple
            options={sources}
            value={values.sources}
            error={errors?.sources}
          />
          <Field
            as={Select}
            options={subsystemOptions}
            value={values.subsystemId}
            label="Подсистема"
            name="subsystem"
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              setFieldValue('subsystemId', e.target.value);
            }}
            error={errors.subsystemId}
          />
          <Field
            as={Select}
            label="Способы уведомления"
            name="methods"
            options={methods}
            value={values.methods}
            multiple
            error={errors?.methods}
          />
          <Flex>
            <Button
              onClick={() => {
                if (!isValid) {
                  errorNotify({
                    key: 'notification-setting-save-error',
                    message: 'Пожалуйста, проверьте правильность введенных данных',
                  });
                }
                handleSubmit();
              }}
              variant="solid"
              margin={3}
            >
              Сохранить
            </Button>
            <Button onClick={onCancel} margin={3} paddingLeft={6} paddingRight={6}>
              Отмена
            </Button>
          </Flex>
        </Grid>
      </FormWrapper>
    </Box>
  );
};

export default React.memo(FieldsForm);
