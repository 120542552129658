import React, { useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { FormikProvider, useFormik } from 'formik';
import { createTouchedErrors } from 'features/create-touched-errors';
import { useRequest } from 'hooks/useRequest';
import { Breadcrumb, LoaderItem, PageHeader } from 'components/common';
import { Heading } from '@chakra-ui/react';
import { useNotifications } from 'hooks/useNotifications';
import { ERROR_MESSAGES, SUCCESS_MESSAGES } from 'constants/messages';
import { Form } from './Form';
import { useNSIDictionary } from 'pages/dictionaries/hooks/useNSIDictionary';
import { NSIApiRouteRoot, NSIRootPath } from '../../const/routes';
import { useNSIDictionaryItem } from 'pages/dictionaries/hooks/useNSIDictionaryItem';
import { getInitialValues, prepareDictionaryItemForCreateOrUpdate, validate } from './formHelpers';
import { IPrepareResponce } from '../../const/types';
import { DictionaryModalWrapper } from './DictionaryModalWrapper';

export const EditDictionaryItem: React.FC = () => {
  const history = useHistory();
  const { dictionaryId, itemId } = useParams<{ dictionaryId: string; itemId: string }>();

  const { dictionary, isLoading } = useNSIDictionary(dictionaryId);
  const { item } = useNSIDictionaryItem(dictionaryId, itemId);
  const { successNotify, errorNotify } = useNotifications();

  const onSuccess = () => {
    successNotify({
      key: 'record/edit/success',
      message: SUCCESS_MESSAGES.EDIT,
    });
    history.push(`${NSIRootPath}/${dictionaryId}`);
  };

  const onError = () => {
    errorNotify({
      key: 'record/edit/error',
      message: ERROR_MESSAGES.EDIT,
    });
  };

  const onPrepareError = () => {
    errorNotify({
      key: 'prepare/edit/error',
      message: 'Ошибка при проверке наличия дублей',
    });
  };

  const { put } = useRequest(`${NSIApiRouteRoot}/${dictionaryId}/item/${itemId}`, onSuccess, onError);
  const { put: prepare, result: prepareResult } = useRequest<IPrepareResponce>(
    `${NSIApiRouteRoot}/${dictionaryId}/item/${itemId}/prepare`,
    undefined,
    onPrepareError
  );

  const initialValues = useMemo(() => {
    const emptyValues = getInitialValues(dictionary);

    return {
      ...emptyValues,
      ...item,
    };
  }, [dictionary, item]);

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    validate: (values) => validate(values, dictionary?.schema.required),
    initialValues,
    onSubmit: (values) => {
      const data = prepareDictionaryItemForCreateOrUpdate(dictionaryId, values);
      void prepare(data);
    },
  });

  if (isLoading || !dictionary) {
    return <LoaderItem />;
  }

  return (
    <FormikProvider
      value={{
        ...formik,
        errors: createTouchedErrors(formik.errors, formik.touched),
      }}
    >
      <PageHeader>
        <Breadcrumb to={`${NSIRootPath}/${dictionaryId}`}>Вернуться назад</Breadcrumb>
        <Heading>Редактирование записи</Heading>
      </PageHeader>
      <Form properties={dictionary?.schema.properties} oldAliases={item?.aliases} />
      <DictionaryModalWrapper prepareResponse={prepareResult} queryFunc={put} />
    </FormikProvider>
  );
};
