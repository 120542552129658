import { useEffect } from 'react';
import { DurationInputArg1, DurationInputArg2 } from 'moment';

import { Flex, Heading } from '@chakra-ui/react';

import { SelectPeriod } from './SelectPeriod';
import { useSelectPeriod } from '../hooks/useSelectPeriod';

import { periods } from '../consts';

interface ICustomPeriodButtons {
  handleSetCustomDate: (amount: DurationInputArg1, unit: DurationInputArg2) => void;
}
export const CustomPeriodSet = ({ handleSetCustomDate }: ICustomPeriodButtons) => {
  const { period, handleChangePeriod } = useSelectPeriod();

  useEffect(() => {
    if (period) handleSetCustomDate(periods[period].amount, periods[period].units);
  }, [handleSetCustomDate, period]);

  return (
    <Flex alignItems="center" mb={3}>
      <Heading size="sm" flexGrow={1}>
        Выбрать промежуток:
      </Heading>
      <SelectPeriod value={period} onChange={handleChangePeriod} predicate="isShowInFilter" />
    </Flex>
  );
};
