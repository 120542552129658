import { PageHeader, RoutesList } from 'components/common';
import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import { Flex, Heading } from '@chakra-ui/react';
import React from 'react';
import { useRoutesList } from 'hooks/useRoutesList';
import { waterRoutesConfigMenu } from '../routesConfig';

export const WaterRouterList = () => {
  useUpdateBreadcrumbs([
    { breadcrumb: 'Данные', path: '/data' },
    {
      breadcrumb: 'Водные объекты',
      path: '/data/water',
    },
  ]);
  const routes = useRoutesList(waterRoutesConfigMenu);
  return (
    <Flex flexDirection="column">
      <PageHeader>
        <Heading>Мониторинг водных объектов</Heading>
      </PageHeader>
      <RoutesList routes={routes} />
    </Flex>
  );
};
