import { useRequest } from 'hooks/useRequest';

interface IASKZAEmissionsDictionaryArea {
  areaId: string;
  areaName: string;
  areaNumber: number;
  sources: IASKZAEmissionsDictionarySource[];
}

interface IASKZAEmissionsDictionarySource {
  sourceId: string;
  sourceName: string;
  sourceNumber: number;
  furnaces: IASKZAEmissionsDictionaryFurnace[];
}

interface IASKZAEmissionsDictionaryFurnace {
  sourceId: string;
  sourceName: string;
  sourceNumber: number;
  parameters: IASKZAEmissionsDictionaryParameter[];
}

interface IASKZAEmissionsDictionaryParameter {
  parameterCode: string;
  parameterId: string;
  parameterName: string;
}

interface IASKZAEmissionsAskzaDictionarySource {
  sourceId: string;
  sourceName: string;
  sourceNumber: number;
  parameters: IASKZAEmissionsDictionaryParameter[];
}

export interface IASKZAEmissionsDictionariesResponse {
  askzaDictionaries: IASKZAEmissionsAskzaDictionarySource[];
  emissionDictionaries: IASKZAEmissionsDictionaryArea[];
}

interface IUseDictionary {
  url: string;
}

function useASKZAEmissionsDictionaries({ url }: IUseDictionary) {
  const { post, isLoading, result } = useRequest<IASKZAEmissionsDictionariesResponse>(url);

  return {
    post,
    isDictionariesLoading: isLoading,
    askzaDictionaries: result?.askzaDictionaries,
    emissionDictionaries: result?.emissionDictionaries,
  };
}

export default useASKZAEmissionsDictionaries;
