import { Paper } from '@material-ui/core';
import Slide from '@material-ui/core/Slide';
import { Button, Input, Select, SelectOption } from 'components/common';
import { Field, FormikProvider, useFormik } from 'formik';
import { FilterCheckbox } from 'features/Filter/components';
import { useRequest } from 'hooks';
import React, { useEffect, useState } from 'react';
import { useNotifications } from 'hooks/useNotifications';
import { ERROR_MESSAGES } from 'constants/messages';
import { Flex, Grid } from '@chakra-ui/react';
import { parametersCalculationSchema, CalculationsSchema } from './schemas';

interface TableDataProcessingDictionaries {
  operands?: SelectOption[];
  operations?: SelectOption[];
}

export interface TableDataProcessingProp extends TableDataProcessingDictionaries {
  onSubmit(values: TableDataProcessingFormValues): void;

  parameters?: SelectOption[];
}

interface TableDataProcessingFormValues {
  operandId: string;
  operationId: string;
  value: string;
  processDependentValues?: boolean;
  firstCoefficient: string;
  secondCoefficient: string;
  dependentParameterIds?: string[];
}

interface TableDataProcessingProps extends TableDataProcessingDictionaries {
  isLoading: boolean;
  isOpen: boolean;

  onClose(): void;

  onSubmit?: (values: TableDataProcessingFormValues) => void;
  parametersUrl?: string;
  parametersKeys?: {
    sourceId?: string | null | string[];
    dateFrom: string | null;
    dateTo: string | null;
    sourceIds?: string[];
  };
  dataProcessing?: TableDataProcessingProp;
}

interface IDependentParameter {
  name: string;
  value: string;
}

export const TableDataProcessingMini: React.FC<TableDataProcessingProps> = ({
  operands = [],
  operations = [],
  isLoading,
  isOpen,
  onClose,
  onSubmit,
  parametersUrl = '',
  parametersKeys = null,
  dataProcessing,
}) => {
  const { errorNotify } = useNotifications();

  const [processDependentValues, setProcessDependentValues] = useState(false);

  const form = useFormik<TableDataProcessingFormValues>({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    initialValues: {
      operandId: '',
      operationId: '',
      value: '',
      firstCoefficient: '',
      secondCoefficient: '',
      dependentParameterIds: [],
    },
    validationSchema: dataProcessing?.parameters ? parametersCalculationSchema : CalculationsSchema,
    onSubmit: (values) => {
      if (onSubmit) {
        onSubmit(values);
      }
    },
  });

  const onError = (error: any) => {
    const isNoSource = parametersKeys && Object.values(parametersKeys).length < 3;
    const errorMessage = error?.descriptions[0]?.message || '';
    const message = isNoSource
      ? ERROR_MESSAGES.DEPENDENT_PARAMS_VALIDATION
      : `${ERROR_MESSAGES.DEPENDENT_PARAMS_GET}. ${errorMessage}`;
    errorNotify({
      key: 'file/get/error',
      message,
    });
  };

  const {
    post,
    result: parameters,
    isLoading: isParameterLoading,
  } = useRequest<IDependentParameter[] | null>(parametersUrl, undefined, onError);

  const { errors, setFieldValue, values } = form;

  useEffect(() => {
    setProcessDependentValues(false);
    setFieldValue('dependentParameterIds', []);
  }, [parametersKeys, setFieldValue]);

  const isDelete = values.operationId === 'delete';

  return (
    <div
      style={{
        position: 'sticky',
        width: '100%',
        left: 0,
        right: 0,
        bottom: 0,
        padding: '0px',
        boxShadow: isOpen ? '1px 1px' : 'none',
        borderTop: '1px solid #E2E8F0',
        zIndex: 1,
      }}
    >
      <Slide direction="up" in={isOpen} mountOnEnter unmountOnExit appear>
        <Paper>
          <Flex padding="8px" flexDirection="column">
            <FormikProvider value={form}>
              {parametersKeys && form.values.operationId !== 'drift' && !isDelete && (
                <Grid mb={2} templateColumns="repeat(2, 1fr)" gap="8px">
                  <FilterCheckbox
                    value={processDependentValues}
                    checked={processDependentValues}
                    type="CHECKBOX"
                    name="processDependentValues"
                    label="Обрабатывать зависимые значения"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setProcessDependentValues(e.target.checked);
                      if (e.target.checked && parametersKeys) {
                        post(parametersKeys);
                      }
                    }}
                  />
                  {processDependentValues && (
                    <Field
                      as={Select}
                      multiple
                      name="dependentParameterIds"
                      label="Параметр"
                      options={parameters || []}
                    />
                  )}
                </Grid>
              )}

              <Grid gridTemplateColumns="repeat(4, 1fr)" gap="20px">
                {dataProcessing?.parameters ? (
                  <>
                    {form.values.operationId === 'drift' ? (
                      <Field
                        as={Select}
                        name="parameterId"
                        label="Первый операнд"
                        options={dataProcessing?.parameters}
                      />
                    ) : (
                      <Field
                        as={Select}
                        name="parameterIds"
                        label="Первый операнд"
                        options={dataProcessing?.parameters}
                        multiple
                      />
                    )}
                  </>
                ) : (
                  <Field as={Select} name="operandId" label="Первый операнд" options={operands} isDisabled={isDelete} />
                )}
                <Field as={Select} name="operationId" label="Операция" options={operations} />

                {!isDelete && (
                  <>
                    {form.values.operationId === 'drift' ? (
                      <>
                        <Field type="number" as={Input} name="firstCoefficient" label="Значение первого коэффицента" />
                        <Field type="number" as={Input} name="secondCoefficient" label="Значение второго коэффицента" />
                      </>
                    ) : (
                      <>
                        <Field as={Input} type="number" name="value" label="Значение второго операнда" />
                      </>
                    )}
                  </>
                )}
              </Grid>
            </FormikProvider>
            <Flex flexDirection="row" justifyContent="space-between" width="fit-content" mt="12px">
              <Button
                variant="outline"
                color="PrimaryButton.500"
                onClick={onClose}
                isLoading={isLoading || isParameterLoading}
                isDisabled={isLoading || isParameterLoading}
              >
                Отмена
              </Button>
              <Button
                marginLeft="20px"
                variant="solid"
                onClick={() => form.handleSubmit()}
                isLoading={isLoading || isParameterLoading}
                isDisabled={isLoading || Object.keys(errors).length > 0 || isParameterLoading}
              >
                Применить
              </Button>
            </Flex>
          </Flex>
        </Paper>
      </Slide>
    </div>
  );
};

export type { TableDataProcessingDictionaries, TableDataProcessingFormValues };
