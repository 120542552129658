import React, { memo } from 'react';
import { FormContainer as ParameterFormContainer } from 'pages/equipment/Models/components/ParameterForm/FormContainer';
import { ParameterForm } from 'pages/equipment/Models/components/ParameterForm';
import { useMutateParameter, useParameter } from 'pages/equipment/Models/hooks/useParameter';

interface ParameterProps {
  modelId: string;
  id?: string;
  onCancel: () => void;
  onOk: () => void;
}

const initialValues = {
  id: '',
  averagePeriod: '',
  genMax: false,
  genMin: false,
  parameterId: '',
  parameterCode: '',
  purposeId: '',
  unitId: '',
  accuracy: '',
  orderNo: '',
  valueFrom: '',
  valueTo: '',
};

export const Parameter: React.FC<ParameterProps> = memo(({ modelId, id, onCancel, onOk }) => {
  const { data } = useParameter(modelId, id);
  const mutate = useMutateParameter(modelId, onOk);

  return (
    <ParameterFormContainer>
      <ParameterForm initialValues={data || initialValues} onSubmit={mutate} onCancel={onCancel} />
    </ParameterFormContainer>
  );
});

Parameter.displayName = 'Wrapper';
