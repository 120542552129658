import sortDefault from './icons/sortDefault.svg';
import sortDesc from './icons/sortDesc.svg';

const rowLineHeight = 14;

export const miniTableStyle = (props?: any) => ({
  minHeight: 0,
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column' as 'column',
  '& .MuiTableSortLabel-root': {
    flexDirection: 'row-reverse' as 'row-reverse',
  },
  '& .MuiTableSortLabel-icon': {
    opacity: 1,
    backgroundImage: `url(${sortDefault})`,
    backgroundSize: 'contain',
    width: 14,
    height: 14,
    margin: 0,
    marginRight: '4px',
  },
  '& .MuiTableSortLabel-active .MuiTableSortLabel-icon': {
    backgroundImage: `url(${sortDesc})`,
  },
  '& .MuiIconButton-colorPrimary:hover, & .MuiIconButton-colorPrimary.Mui-checked:hover': {
    backgroundColor: 'inherit',
  },
  // to disable actions printing
  '@media print': {
    '& .MuiTableCell-paddingNone, &.MuiTableCell-paddingCheckbox': {
      display: 'none !important',
    },
  },
  // scrollbars and fixed header
  '& .MuiPaper-root': {
    padding: '0',
    '& thead': {
      position: 'sticky',
      top: 0,
      zIndex: 1,
    },
    '& .MuiTable-root': {
      fontSize: `${rowLineHeight - 2}px`,
      lineHeight: `${rowLineHeight}px`,
    },
    '& > div:last-child': {
      display: 'flex',
      flexDirection: 'column',
      minHeight: 0,
      flexGrow: 1,

      // hide empty rows
      '& .MuiTableBody-root .MuiTableRow-root:not([index])': {
        height: '0 !important',
        '&:first-child': {
          flexGrow: 1,
        }
      },

      '&  > div': {
        lineHeight: 0,
        '&::-webkit-scrollbar': {
          height: 10,
        },
        '&::-webkit-scrollbar-thumb': {
          background: '#E2E8F0',
        },
      },

      '& > div:last-child': {
        // maxHeight: '80vh',
        '@media print': {
          maxHeight: 'initial'
        },
        minHeight: '200px',
        flexGrow: 1,
        overflowY: 'auto !important',
        padding: '0 8px',
      },
    },
    '& > div:nth-child(2)': {
      '& > div': {
        lineHeight: 0,
      },

      '& .MuiTableBody-root .MuiTableRow-root:not([index])': {
        height: '0 !important',
        '&:first-child': {
          height: '400px !important',
        }
      },
    },
  },
  '& .MuiTableCell-root.MuiTableCell-head': {
    fontSize: `${rowLineHeight - 2}px`,
    lineHeight: `${rowLineHeight}px`,
    padding: '4px 0px 4px 8px',
    maxHeight: '28px',
    '& .MuiIconButton-root': {
      padding: '0px 8px',
      '& svg': {
        height: `${rowLineHeight}px`,
        width: `${rowLineHeight}px`
      }
    },
    '&:first-child': {
      '& .MuiIconButton-root': {
        padding: '0px',
      },
    }
  },
  '& .MuiTableCell-root.MuiTableCell-body': {
    fontSize: `${rowLineHeight - 2}px`,
    lineHeight: `${rowLineHeight}px`,
    padding: props?.sorting !== false ? '4px 0px 4px 26px' : '4px 0px 4px 8px',
    whiteSpace: 'nowrap',
    '&.MuiTableCell-paddingNone': {
      padding: '4px 8px',
    },
    '& .MuiIconButton-root': {
      padding: '0px 8px',
      '& svg': {
        height: `${rowLineHeight}px`,
        width: `${rowLineHeight}px`
      }
    },
    '&:first-child': {
      '& .MuiIconButton-root': {
        padding: '0px',
      },
    }
  },
});
