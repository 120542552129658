import { DateInput, Select, SelectOption } from 'components/common';
import { Field, useFormikContext } from 'formik';
import appLocale from 'constants/appLocale';
import React from 'react';
import { createUTCTime } from 'lib/create-date';
import { IFilterItem, TDictionaryItemDictionary } from 'models';
import FilterFormControls from 'components/form/FilterFormControls';
import { Flex, Grid } from '@chakra-ui/react';

interface IProps {
  industries: SelectOption[];
  sources: TDictionaryItemDictionary[];
  parameters: TDictionaryItemDictionary[];
}

export const PDVFormFields = ({ industries, sources, parameters }: IProps) => {
  const { submitForm, setFieldValue, handleReset, values, errors } = useFormikContext<{ [p: string]: IFilterItem }>();
  return (
    <Flex>
      <Grid width="100%" gap="12px" rowGap="8px" templateColumns="repeat(5, 0.2fr)">
        <Field
          as={DateInput}
          name="startDate.value"
          label={appLocale.common.from}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setFieldValue('startDate.value', createUTCTime(e.target.value))
          }
          showTimeInput
          error={errors.startDate?.value}
        />

        <Field
          as={DateInput}
          name="endDate.value"
          label={appLocale.common.to}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setFieldValue('endDate.value', createUTCTime(e.target.value))
          }
          showTimeInput
          error={errors.endDate?.value}
        />

        <Field
          as={Select}
          name="industryId.value"
          label="Предприятие"
          options={industries}
          value={values.industryId.value}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setFieldValue('industryId.value', e.target.value);
            if (!e.target.value) {
              setFieldValue('sourceId.value', null);
            }
          }}
        />

        <Field
          as={Select}
          name="sourceId.value"
          label="Источник"
          options={sources.filter((s) => s.parent === values.industryId.value)}
          value={values.sourceId.value}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFieldValue('sourceId.value', e.target.value)}
          isDisabled={!values.industryId.value}
        />

        <Field
          as={Select}
          name="parameterId.value"
          label="Параметр"
          options={parameters.map((item) => ({
            ...item,
            name: item.title,
            value: item.parameterId,
          }))}
          value={values.parameterId.value}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFieldValue('parameterId.value', e.target.value)}
        />
      </Grid>

      <FilterFormControls
        onReset={(e) => {
          handleReset(e);
          setTimeout(submitForm, 0);
        }}
        onSubmit={submitForm}
      />
    </Flex>
  );
};
