import React from 'react';
import { Flex } from '@chakra-ui/react';
import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import appLocale from 'constants/appLocale';
import { useRoutesList } from 'hooks/useRoutesList';
import { RoutesList, SubsystemHeader } from 'components/common';
import { dataMenu } from 'features/WithSideBar/consts';

export const DataList: React.FC = () => {
  useUpdateBreadcrumbs([{ breadcrumb: appLocale.common.data, path: '/data' }]);
  const routes = useRoutesList(dataMenu);
  return (
    <Flex flexDirection="column">
      <SubsystemHeader title="Данные" />
      <RoutesList routes={routes} />
    </Flex>
  );
};
