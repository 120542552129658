import { forwardRef, Tab, TabProps, useTheme } from '@chakra-ui/react';

export const StyledTab = forwardRef((props: TabProps, ref) => {
  const theme = useTheme();

  return (
    <Tab
      ref={ref}
      color="#000"
      bg="#fff"
      borderRadius="2px"
      border="1px solid"
      boxShadow="none !important"
      borderColor={`${theme.colors.border.default} !important`}
      _selected={{
        color: '#fff',
        bg: 'PrimaryButton.500',
      }}
      {...props}
    />
  );
});
