import { Flex, Grid } from '@chakra-ui/react';
import { Input, Select } from 'components/common';
import React, { useEffect } from 'react';
import { Field, FormikProvider, useFormik } from 'formik';
import { IFilterItem, TDictionariesArray } from 'models';
import { useUtilDictionaries } from 'hooks/useUtilDictionaries';
import FilterFormControls from 'components/form/FilterFormControls';
import combineFilters from 'lib/utils/combineFilters';

interface IProps {
  setFilters: (filters: any) => void;
  dictionaries: { data: TDictionariesArray } | null;
  isLoading: boolean;
  filters: IFilterItem[];
}

const filterInitValues: Record<string, IFilterItem> = {
  name: {
    key: 'name',
    operation: 'CONTAINS',
    value: '',
  },
  okrugId: {
    key: 'okrugId',
    operation: 'EQ',
    value: null,
  },
  rayonId: {
    key: 'rayonId',
    operation: 'EQ',
    value: null,
  },
  soilSourceTypeId: {
    key: 'soilSourceTypeId',
    operation: 'EQ',
    value: null,
  },
  soilFuncZoneId: {
    key: 'soilFuncZoneId',
    operation: 'EQ',
    value: null,
  },
  soilFuncZoneTypeId: {
    key: 'soilFuncZoneTypeId',
    operation: 'EQ',
    value: null,
  },
  status: {
    key: 'status',
    operation: 'EQ',
    value: null,
  },
};

export const NSISoilFilter = ({ setFilters, dictionaries, isLoading, filters }: IProps) => {
  useEffect(() => {
    const initialFilters = combineFilters(filterInitValues, filters);
    Object.keys(initialFilters)
      .forEach((key) => {
        setFieldValue(`${key}.value`, initialFilters[key].value);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { statuses, rayons, okrugs, sourceTypes, funcZones, funcZoneTypes } = useUtilDictionaries(
    dictionaries?.data || []
  );

  const onSubmit = (values: any) => {
    const filters = Object.values(values);
    setFilters(filters);
  };

  const {
    resetForm,
    values,
    errors,
    getFieldMeta,
    getFieldHelpers,
    getFieldProps,
    handleReset,
    submitForm,
    setFieldValue,
    ...formik
  } = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    initialValues: filterInitValues,
    onSubmit,
  });

  return (
    <FormikProvider
      value={{
        resetForm,
        values,
        errors,
        getFieldMeta,
        getFieldHelpers,
        getFieldProps,
        handleReset,
        submitForm,
        setFieldValue,
        ...formik,
      }}
    >
      <Flex>
        <Grid width="100%" maxWidth="1200px" gap={2} templateColumns="repeat(4, 1fr)">
          <Field as={Input} name="name.value" label="Наименование" />

          <Field as={Select} name="okrugId.value" label="Округ" options={okrugs} />

          <Field as={Select} name="rayonId.value" label="Район" options={rayons} />

          <Field as={Select} name="soilSourceTypeId.value" label="Тип источника наблюдения" options={sourceTypes} />

          <Field as={Select} name="soilFuncZoneId.value" label="Функциональная зона" options={funcZones} />

          <Field as={Select} name="soilFuncZoneTypeId.value" label="Тип функциональной зоны" options={funcZoneTypes} />

          <Field as={Select} name="status.value" label="Статус" options={statuses} />
        </Grid>

        <FilterFormControls
          onReset={() => {
            resetForm();
            setFilters([{ key: 'deleted', operation: 'EQ', value: false }]);
          }}
          onSubmit={submitForm}
          isDisabled={isLoading}
          isLoading={isLoading}
        />
      </Flex>
    </FormikProvider>
  );
};
