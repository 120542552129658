import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import React from 'react';

interface Props {
  file: File;
}
const FileItemViewPDFPreview = ({ file }: Props) => (
  <Document file={file}>
    <Page
      pageNumber={1}
      width={100}
      renderAnnotationLayer={false}
      loading=""
    />
  </Document>
);

export default FileItemViewPDFPreview;
