import { FormValues } from './Form';
import { IDictionary, IDictionaryCreateOrUpdateData } from '../../const/types';

export const validate = (
  values: FormValues,
  requiredValueKeys: string[] = [],
  requiredKeys: Array<keyof FormValues> = ['name']
) => {
  const errors: Partial<FormValues> = {};

  requiredKeys.forEach((key) => {
    if (!values[key] && key !== 'value' && key !== 'id' && key !== 'aliases') {
      errors[key] = 'Не может быть пустым';
    }
  });

  requiredValueKeys.forEach((key) => {
    if (!values.value[key]) {
      if (!errors.value) {
        errors.value = {};
      }
      errors.value[key] = 'Не может быть пустым';
    }
  });

  return errors;
};

export const prepareDictionaryItemForCreateOrUpdate = (
  dictionaryId: string,
  values: FormValues,
): IDictionaryCreateOrUpdateData => {
  Object.keys(values.value).forEach((key) => {
    let value = values.value[key];
    // save empty fields as null except boolean
    if (values.value[key] === false) {
      value = false;
    } else if (!values.value[key]) {
      value = null;
    }

    values.value[key] = value;
  });

  return {
    aliases: values.aliases,
    shortName: values.shortName,
    name: values.name,
    code: '',
    dictionaryId,
    value: JSON.stringify(values.value),
    comment: values.comment
  };
};

export const getInitialValues = (dictionary: IDictionary | null): FormValues => {
  const values: FormValues = {
    name: '',
    shortName: '',
    aliases: [],
    value: {},
    comment: ''
  };

  if (dictionary?.schema.properties) {
    Object.keys(dictionary.schema.properties).forEach((key) => {
      const fieldSchema = dictionary.schema.properties && dictionary.schema.properties[key];
      if (fieldSchema) {
        if (fieldSchema.field_type === 'boolean') {
          values.value[key] = false;
        } else {
          values.value[key] = '';
        }
      }
    });
  }

  return values;
};
