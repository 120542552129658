import React, { useMemo } from 'react';
import { Field, useFormikContext } from 'formik';
import { Button, Input } from 'components/common';
import { SurfaceWaterLocales } from '../../locales';
import { ISurfaceWaterLoadData, TCount, WaterSurfaceParametersKeys } from '../../types';
import { COMMON_LOCALE } from 'constants/common';
import { useDisclosure, useEnhancedNotification } from 'hooks';
import { Modal } from 'components/common/Modals';
import { UploadFileDragAndDrop } from 'features/UploadFileDragAndDrop';
import { WaterRouteStateRoot, WaterSurfaceWatersLoadPreview } from '../../../routes';
import { useInitializerUploadSettings } from 'features/UploadedFileProvider/UploadFileSettings';
import { apiPathSurfaceWater } from '../../apiPath';
import { CountForm } from '../CountForm';
import { TDictionaryItemDictionary } from 'models';
import { Flex, Grid } from '@chakra-ui/react';
import { MoreLessNumberInput } from '../../../../../../components/common/Input/more-less-number-input';
import { get } from 'lodash';

interface ITab {
  isLoading: boolean;
  handleCancel: () => void;
  submitForm: () => void;
  isEditMode?: boolean;
  isCopyMode?: boolean;
  parameters?: TDictionaryItemDictionary[];
  isAnalytics?: boolean;
}

const toxicId = 'da9e2b00-7cdc-4657-bba0-1843516204ef';

export const SurfaceWaterParametersTab = ({
  isLoading,
  handleCancel,
  submitForm,
  isEditMode,
  isCopyMode,
  parameters,
  isAnalytics,
}: ITab) => {
  const { setFieldValue, errors, values } = useFormikContext<ISurfaceWaterLoadData>();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { onValidationError } = useEnhancedNotification();

  useInitializerUploadSettings(
    useMemo(
      () => ({
        typesPath: apiPathSurfaceWater.types,
        previewPath: apiPathSurfaceWater.preview,
        validatePath: apiPathSurfaceWater.validate,
        applyPath: apiPathSurfaceWater.apply,
        redirectPath: WaterRouteStateRoot,
      }),
      [],
    ),
  );

  const onSubmit = () => {
    if (Object.keys(errors).length > 0) {
      onValidationError();
    }
    submitForm();
  };

  const counterParams: Record<string, TCount> = {
    ammoniumIon: {
      leftLabel: SurfaceWaterLocales.ammoniumIon,
      rightLabel: SurfaceWaterLocales.ammoniumIonAzot,
      leftFormula: (value) => value * 1.285714,
      rightFormula: (value) => value * 0.77778,
    },
    nitriteIon: {
      leftLabel: SurfaceWaterLocales.nitriteIon,
      rightLabel: SurfaceWaterLocales.nitrinAzot,
      leftFormula: (value) => value * 3.285714,
      rightFormula: (value) => value * 0.304348,
    },
    nitrateIon: {
      leftLabel: SurfaceWaterLocales.nitrateIon,
      rightLabel: SurfaceWaterLocales.nitratAzot,
      leftFormula: (value) => value * 4.428571,
      rightFormula: (value) => value * 0.225806,
    },
    phosphateIon: {
      leftLabel: SurfaceWaterLocales.phosphateIon,
      rightLabel: SurfaceWaterLocales.phosphatePhosfor,
      leftFormula: (value) => value * 3.064516,
      rightFormula: (value) => value * 0.326316,
    },
  };

  const countParamKeys = ['ammoniumIonAzot', 'nitrinAzot', 'nitratAzot', 'phosphatePhosfor'];
  const isNotCountParam = (param: string) => !countParamKeys.includes(param);

  return (
    <Grid gap={2} width="50%" marginLeft={10}>
      {!isEditMode && !isCopyMode && (
        <Button marginRight="auto" marginLeft={2} variant="link" onClick={onOpen}>
          Загрузить из файла
        </Button>
      )}
      {parameters?.map((param, index) => {
        const paramKey: any = WaterSurfaceParametersKeys.find((key) => SurfaceWaterLocales[key] === param.name);
        if (counterParams[paramKey]) {
          return (
            <CountForm
              key={param.value}
              parameters={parameters}
              setFieldValue={setFieldValue}
              {...counterParams[paramKey]}
              isAnalytics={isAnalytics}
            />
          );
        }
        if (isNotCountParam(paramKey)) {
          const name = `values[${index}]`;
          return (
            <Field
              key={param.value}
              as={isAnalytics && param.value !== toxicId ? MoreLessNumberInput : Input}
              label={param.name}
              name={name}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setFieldValue(`${name}.parameterId`, param.value);
                setFieldValue(`${name}.unitId`, param.parent);
                if (!isAnalytics || param.value === toxicId) {
                  setFieldValue(`${name}.value`, e.target.value);
                }
              }}
              value={get(values, isAnalytics && param.value !== toxicId ? name : `${name}.value`)}
              isDisabled={isLoading}
            />
          );
        }
      })}
      <Flex ml={4} mt={2}>
        <Button onClick={handleCancel} variant="solid">
          {COMMON_LOCALE.cancel}
        </Button>
        <Button
          onClick={onSubmit}
          ml={20}
          variant="solid"
          isDisabled={
            isAnalytics && (!values.values || values.values.filter((val) => val && val.value !== '').length < 1)
          }
        >
          {COMMON_LOCALE.save}
        </Button>
      </Flex>

      <Modal isOpen={isOpen} onClose={onClose} header="Загрузка данных из файла">
        <UploadFileDragAndDrop
          previewPagePath={WaterSurfaceWatersLoadPreview}
          onClose={onClose}
          parametersSave={(preview) => {
            preview?.result.values.forEach((item: any) => {
              const idx = parameters?.map((param) => param.value).indexOf(item.parameterId);
              setFieldValue(`values[${idx}].value`, item.value);
              setFieldValue(`values[${idx}].parameterId`, item.parameterId);
            });
            onClose();
          }}
        />
      </Modal>
    </Grid>
  );
};
