import { consentrationPath, pdkLocales, pdkRootPath, substancesPath } from './consts';
import { PageHeader, RoutesList } from 'components/common';
import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import { Flex, Heading } from '@chakra-ui/react';

export const PDK = () => {
  useUpdateBreadcrumbs([
    {
      breadcrumb: pdkLocales.main,
      path: pdkRootPath,
    },
  ]);
  return (
    <Flex flexDirection="column">
      <PageHeader>
        <Heading>{pdkLocales.main}</Heading>
      </PageHeader>
      <RoutesList
        routes={[
          {
            path: substancesPath,
            breadcrumb: pdkLocales.substances,
          },
          {
            path: consentrationPath,
            breadcrumb: pdkLocales.concentration,
          },
        ]}
      />
    </Flex>
  );
};
