import { createContext, Dispatch, FC, useContext, useEffect, useState } from 'react';

interface Settings {
  typesPath: string;
  previewPath: string;
  validatePath: string;
  applyPath: string;
  redirectPath: string;
}

interface UploadFileSettingsCtx {
  uploadFileSettings: Settings;
  setUploadFileSettings: Dispatch<Settings | undefined>;
}

const uploadFileSettingsCtx = createContext<Partial<UploadFileSettingsCtx>>({});

const useUploadFileSettingsProvider = () => useContext(uploadFileSettingsCtx);

const useSetUploadFileSettings = (settings?: Settings) => {
  const { setUploadFileSettings } = useUploadFileSettingsProvider();

  useEffect(() => {
    if (setUploadFileSettings) {
      setUploadFileSettings(settings);
    }

    return () => {
      if (setUploadFileSettings) {
        setUploadFileSettings(undefined);
      }
    };
  }, [setUploadFileSettings, settings]);
};

const useInitializerUploadSettings = (settings: Settings) => {
  useSetUploadFileSettings(settings);
};

const UploadFileSettingsProvider: FC = (props) => {
  const [uploadFileSettings, setUploadFileSettings] = useState<Settings>();
  return <uploadFileSettingsCtx.Provider value={{ uploadFileSettings, setUploadFileSettings }} {...props} />;
};

export {
  useUploadFileSettingsProvider,
  useSetUploadFileSettings,
  useInitializerUploadSettings,
  UploadFileSettingsProvider,
  uploadFileSettingsCtx,
};
