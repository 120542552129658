import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import { useRequest } from 'hooks/useRequest';
import { useHistory, useParams } from 'react-router-dom';
import { modelsLocale } from './locale';
import { DeviceModelForm } from 'pages/equipment/Models/components/DeviceModelForm';
import { DeviceModelLayout } from 'pages/equipment/Models/components/DeviceModelLayout';
import { FormContainer } from 'pages/equipment/Models/components/DeviceModelForm/FormContainer';
import { useDeviceModel } from 'pages/equipment/Models/hooks/useDeviceModel';
import { DeviceModelParameters } from 'pages/equipment/Models/components/DeviceModelParameters';
import React, { useCallback, useState } from 'react';
import { useEnhancedNotification } from 'hooks';
import { Loader } from 'components/common';

export const EditDeviceModel = () => {
  const { push } = useHistory();
  useUpdateBreadcrumbs([
    {
      breadcrumb: modelsLocale.measureDevices,
      path: '/equipment',
    },
    {
      breadcrumb: modelsLocale.models,
      path: '/equipment/models',
    },
  ]);

  const [tabIndex, setTabIndex] = useState(0);
  const { newData } = useParams<{ newData: string }>();

  const { data, isLoading } = useDeviceModel();
  const { onErrorEdit, onSuccessEdit } = useEnhancedNotification();

  const onSuccess = () => {
    onSuccessEdit();
    push('/equipment/Models');
  };

  const { put } = useRequest(`/equipment/v10/equipments/models/${data?.id}`, onSuccess, onErrorEdit);

  const onCancel = useCallback(() => {
    push('/equipment/models');
  }, [push]);

  if (isLoading || !data) {
    return <Loader />;
  }

  return (
    <DeviceModelLayout
      title={`${newData === 'true' ? 'Добавление' : 'Редактирование'} ${tabIndex === 1 ? 'параметров' : 'модели'}`}
      onBackClick={onCancel}
      model={
        <FormContainer>
          <DeviceModelForm initialValues={data} onSubmit={put} onCancel={onCancel} isDisabled />
        </FormContainer>
      }
      parameters={<DeviceModelParameters />}
      tabIndex={tabIndex}
      setTabIndex={setTabIndex}
    />
  );
};
