import {
  Accordion,
  AccordionItem,
  Box,
  Flex,
  Text,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from '@chakra-ui/react';
import { UserInfoContext } from 'features/AuthProvider';
import { useRequest } from 'hooks';
import { NotificationSource } from 'pages/admin/models/NotificationSource';
import { useEffect, useState, useContext, useCallback } from 'react';
import { notificationTypeLocale } from './const';
import { NotificationBytypeResponse } from './types';
import Notifications from '../Notifications/Notifications';

export const SentryNotifications = () => {
  const [accordeonIndex, setAccordeonIndex] = useState<number>();
  const [notificationsByType, setNotificationsByType] = useState<NotificationBytypeResponse['countByTypes']>();
  const { user } = useContext(UserInfoContext);

  const { get: getUnreadNotificationsByType } = useRequest<NotificationBytypeResponse>(
    `/reporting/v10/dashboard/notifications/unread-count/${user?.id}`,
  );

  const loadUnreadNotifications = useCallback(async () => {
    if (user?.id) {
      const unreadResponse = await getUnreadNotificationsByType();
      if (unreadResponse && 'countByTypes' in unreadResponse) {
        setNotificationsByType(unreadResponse.countByTypes);
      }
    }
  }, [getUnreadNotificationsByType, user]);

  useEffect(() => {
    void loadUnreadNotifications();
  }, [loadUnreadNotifications]);

  const onReadCallback = useCallback((key: keyof typeof NotificationSource) => {
    if (!key) return;

    setNotificationsByType((prev) => (prev ? {
      ...prev,
      [key]: {
        ...(prev[key] ? {
          ...prev[key],
          count: prev[key].count - 1
        } : {})
      }
    } : prev));
  }, []);

  return (
    <Box boxShadow="0px 5px 10px rgba(0, 0, 0, 0.1)" borderRadius="4px" border="1px solid #D4D9DC">
      <Flex pl="16px" py="16px" alignItems="center">
        <Text fontWeight="700" fontSize="18px" lineHeight="30px">
          Уведомления
        </Text>
      </Flex>

      <Accordion index={accordeonIndex} onChange={(index) => setAccordeonIndex(index as number)} allowToggle>
        {(Object.keys(NotificationSource) as Array<keyof typeof NotificationSource>)
          .filter((item) => item !== 'USER' && item !== 'BOUNDARY')
          .map((key, index) => (
            <AccordionItem key={key}>
              <h2>
                <AccordionButton boxShadow="none !important">
                  <Box as="span" flex={1} textAlign="left">
                    {notificationTypeLocale[key]}&nbsp; ({notificationsByType && notificationsByType[key].count})
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel p={0}>
                {accordeonIndex === index && (
                  <Notifications
                    defaultPageSize={5}
                    defaultType={key}
                    onReadCallback={onReadCallback}
                    loadUnreadNotifications={loadUnreadNotifications}
                    isDashboard
                  />
                )}
              </AccordionPanel>
            </AccordionItem>
          ))}
      </Accordion>
    </Box>
  );
};
