import * as yup from 'yup';
import { requiredError, shouldBeLessThan, shouldBeMoreThan } from '../constans';

export const NsiEmissionsIndustriesSchema = yup.object().shape({
  name: yup.string().nullable().required(requiredError),
  number: yup
    .string()
    .nullable()
    .required(requiredError)
    .test('len', 'Номер предприятия не должен превышать 4-х знаков', (val) => !!val && val?.toString().length <= 4),
  status: yup.string().nullable().required(requiredError),
  okrugId: yup.string().nullable().required(requiredError),
  rayonId: yup.string().nullable().required(requiredError),
  address: yup.string().nullable().required(requiredError),
  latitude: yup
    .number()
    .lessThan(90, shouldBeLessThan(90))
    .moreThan(-90, shouldBeMoreThan(-90))
    .typeError('Допустимые символы: "123", "."'),
  longitude: yup
    .number()
    .lessThan(100, shouldBeLessThan(100))
    .moreThan(-100, shouldBeMoreThan(-100))
    .typeError('Допустимые символы: "123", "."'),
});
