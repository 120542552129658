import { Field, useFormikContext } from 'formik';
import { DefaultText, FieldsFormControls, FormHeader, Input, LoaderItem } from 'components/common';
import { FieldFormProps } from 'types/forms';
import React, { useMemo } from 'react';
import { Box, Heading, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import appLocale from 'constants/appLocale';
import NSIWaterSitesGeneralInformationTab from 'pages/dictionaries/NSIWater/NSIWaterSites/NSIWaterSitesForms/NSIWaterSitesGeneralInformationTab';
import LocationFormTab from 'components/common/LocationFormTab/LocationFormTab';
import { getSelectOptionsFromDictionaryByName } from 'features/get-select-options-from-dictionary';
import { NSIWaterItem } from 'pages/dictionaries/NSIWater/types';
import { locationFieldKeys } from 'pages/dictionaries/NSIWater/NSIWaterSites/NSIWaterSitesForms/locationFieldKeys';

const NSIWaterSitesFormFields = ({ onCancel, dictionaries, isLoading, isEditMode, editedObject }: FieldFormProps) => {
  const { handleSubmit, errors } = useFormikContext<NSIWaterItem>();

  const title = isEditMode ? 'Редактирование источника' : 'Добавление источника';

  const okrugs = useMemo(() => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'okrugs'), [dictionaries]);
  const rayons = useMemo(() => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'rayons'), [dictionaries]);

  if (isLoading) {
    return <LoaderItem />;
  }

  return (
    <Box flexDirection="column">
      <Tabs>
        <FormHeader onBackClick={onCancel} header={title}>
          <TabList>
            {[appLocale.common.generalInformation, appLocale.common.location].map((tab, i) => (
              <Tab key={i}>
                <DefaultText cursor="pointer">{tab}</DefaultText>
              </Tab>
            ))}
          </TabList>
        </FormHeader>
        <TabPanels>
          <TabPanel>
            <NSIWaterSitesGeneralInformationTab
              dictionaries={dictionaries}
              editedObject={editedObject}
              isEditMode={isEditMode}
            >
              <FieldsFormControls onCancel={onCancel} handleSubmit={handleSubmit} />
            </NSIWaterSitesGeneralInformationTab>
          </TabPanel>
          <TabPanel>
            <LocationFormTab<NSIWaterItem> okrugs={okrugs} rayons={rayons} showFieldKeys={locationFieldKeys}>
              <Heading size="sm">Начало участка</Heading>
              <Field as={Input} name="latitudeStart" label="Широта" error={errors.latitudeStart} />
              <Field as={Input} name="longitudeStart" label="Долгота" error={errors.longitudeStart} />

              <Heading size="sm">Окончание участка</Heading>
              <Field as={Input} name="latitudeEnd" label="Широта" error={errors.latitudeEnd} />
              <Field as={Input} name="longitudeEnd" label="Долгота" error={errors.longitudeEnd} />

              <FieldsFormControls onCancel={onCancel} handleSubmit={handleSubmit} showSave />
            </LocationFormTab>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default NSIWaterSitesFormFields;
