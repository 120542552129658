import React, { memo, useCallback, useMemo, useRef } from 'react';

import useAutocomplete from '@material-ui/lab/useAutocomplete';

import { find, get, isArray, map } from 'lodash';

import {
  Button,
  Flex,
  FormControl,
  FormControlProps,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  List,
  ListIcon,
  ListItem,
  Stack,
  Text,
  usePopper,
} from '@chakra-ui/react';

import { SelectedCheckboxIcon, UnselectedCheckboxIcon } from 'components/icons';
import { DeleteSelectedItem, SelectClearIcon, SelectExpandIcon } from './components';
import { FormikFieldProps } from '../types';
import { SelectProps, SelectValue } from './types';
import { Truncate } from 'components/common/truncate';
import { arrayNaturalSort, selectOptionNaturalSort } from 'lib/utils/naturalSort';

export const Select: React.FC<
  SelectProps &
  Partial<FormikFieldProps<SelectValue>> &
  { isRequired?: FormControlProps['isRequired']; onFocus?: any; naturalSort?: boolean; }
> = memo(
  ({
    label,
    name,
    multiple = false,
    error,
    isDisabled = false,
    defaultValue,
    value,
    onChange,
    options = [],
    // onBlur,
    isCleared = true,
    arrowColor = 'select.arrow.default',
    clearColor = 'select.clear.default',
    iconWrapperProps = {},
    onAddItem,
    isRequired,
    onFocus,
    naturalSort = true,
  }) => {
    const popper = usePopper({
      gutter: 1,
    });

    const disabledOptions = useMemo(
      () => options?.filter((option) => option.isDisabled).map((item) => item.name),
      [options],
    );

    const isOptionDisabled = useCallback((option) => !!disabledOptions?.includes(option), [disabledOptions]);

    const getOptionLabel = useCallback(
      (option: string) => {
        const opt = find(options, (opt) => opt.value.toString() === option);

        return opt ? String(opt.name) : option;
      },
      [options],
    );

    const handleChange = useCallback(
      (e: React.ChangeEvent<Record<string, unknown>>, v: typeof value) => {
        if (onChange) {
          return onChange({
            ...e,
            target: { ...e.target, id: name, value: v, name },
          });
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [name, onChange, value],
    );

    const _value = useMemo(() => {
      const isMulti = Boolean(multiple);

      if (!isMulti) {
        if (options && !options.length) {
          return null;
        }

        if (typeof value === 'string') {
          return value;
        }

        return null;
      }

      if (isMulti) {
        if (options && !options.length) {
          return [];
        }

        if (Array.isArray(value)) {
          return arrayNaturalSort(value);
        }

        return [];
      }
      return null;
    }, [multiple, options, value]);

    const _defaultValue = useMemo(() => {
      if (typeof defaultValue === 'string') {
        return defaultValue;
      }

      if (Array.isArray(defaultValue)) {
        return defaultValue;
      }
      return null;
    }, [defaultValue]);

    const {
      getRootProps,
      getInputLabelProps,
      getInputProps,
      getOptionProps,
      groupedOptions,
      getTagProps,
      setAnchorEl,
      getClearProps,
      inputValue,
    } = useAutocomplete({
      multiple: Boolean(multiple),
      defaultValue: _defaultValue,
      value: _value,
      options: naturalSort ?
        map(selectOptionNaturalSort(options || []), (opt) => String(opt.value)) :
        (options || []).map((opt) => String(opt.value)),
      id: name,
      disableCloseOnSelect: Boolean(multiple),
      getOptionLabel,
      onChange: handleChange,
      openOnFocus: true,
    });

    // @ts-ignore
    const { ref: inputRef, ...inputProps } = getInputProps();

    const isValidExistValue = (val: SelectProps['value']) => {
      const isMulti = Boolean(multiple);

      if (isMulti) {
        if (isArray(val) && val.length > 0 && val.every((item) => typeof item === 'string')) {
          return true;
        }
        return false;
      }

      if (!isMulti) {
        if (typeof val === 'string' && val !== '') {
          return true;
        }

        return false;
      }
      return false;
    };

    const inputRightElementRef = useRef<HTMLDivElement | null>(null);

    const inputLabelRef = useRef<HTMLLabelElement>(null);

    const inputLabelElementOffsetHeight = inputLabelRef.current?.offsetHeight;

    const inputRigthElementOffsetWidh = inputRightElementRef.current?.offsetWidth;

    const showAddItem = inputValue && onAddItem;

    const _onAddItem = useCallback(
      (value: string) => {
        if (onAddItem) {
          onAddItem(value);
        }
      },
      [onAddItem],
    );

    return (
      <FormControl
        {...getRootProps()}
        width="100%"
        height="max-content"
        _focusWithin={{ label: { transform: 'translate(0px, 0px)' } }}
        isInvalid={Boolean(error)}
        ref={popper.referenceRef}
        isRequired={isRequired}
        onFocus={onFocus}
      >
        <FormLabel
          {...getInputLabelProps()}
          ref={inputLabelRef}
          position="absolute"
          margin={0}
          pointerEvents="none"
          zIndex={10}
          left={4}
          top={1}
          color="select.label.default"
          fontSize="13px"
          transform={`translate(0px, ${isValidExistValue(value) ? '0px' : '11px'})`}
          width="80%"
        >
          {label}
        </FormLabel>
        <InputGroup ref={setAnchorEl}>
          <Input
            {...inputProps}
            ref={inputRef}
            isDisabled={isDisabled}
            size="md"
            height={
              inputLabelElementOffsetHeight && inputLabelElementOffsetHeight > 20
                ? `${inputLabelElementOffsetHeight + 45}px`
                : '45px'
            }
            backgroundColor="select.background.default"
            borderRadius="2px"
            textOverflow="ellipsis"
            overflow="hidden"
            focusBorderColor="select.border.primary"
            errorBorderColor="select.border.error"
            paddingEnd={`${inputRigthElementOffsetWidh}px`}
            paddingInlineEnd={`${inputRigthElementOffsetWidh}px`}
            fontSize="16px"
            lineHeight="20px"
            paddingTop="18px"
            opacity={isDisabled ? '0.7 !important' : undefined}
          />
          <InputRightElement ref={inputRightElementRef} width="fit-content" height="100%">
            <Stack direction="row" spacing={2} pl={2} pr={4} alignItems="center" {...iconWrapperProps}>
              {!isDisabled &&
                !disabledOptions?.length &&
                isCleared &&
                ((!multiple && value) || (value && multiple && value.length > 0)) && (
                  <SelectClearIcon mr={2} clearColor={clearColor} {...getClearProps()} />
                )}

              <SelectExpandIcon cursor="pointer" arrowColor={arrowColor} />
            </Stack>
          </InputRightElement>
        </InputGroup>

        {Boolean(multiple) && (
          <Flex mb={value && value.length > 0 ? 2 : 0} flexWrap="wrap">
            {map(_value, (val, index) => {
              const label = getOptionLabel(val);

              return (
                <Button isDisabled={isDisabled} key={index} size="sm" mt={2} mr={1}>
                  <Truncate>{label}</Truncate>
                  {!isOptionDisabled(val) && <DeleteSelectedItem {...getTagProps({ index })} />}
                </Button>
              );
            })}
          </Flex>
        )}

        {(groupedOptions.length > 0 || showAddItem) && (
          <List
            width="100%"
            position="absolute"
            backgroundColor="#fff"
            overflow="auto"
            maxHeight="250px"
            borderRadius="4px"
            boxShadow="0 2px 8px rgba(0, 0, 0, 0.15)"
            zIndex={11}
            ref={popper.popperRef}
          >
            {map(groupedOptions, (option, index) => {
              const optionsProps = getOptionProps({ option, index });
              return (
                <ListItem
                  {...optionsProps}
                  py={2}
                  px={4}
                  minHeight="40px"
                  cursor="pointer"
                  display="flex"
                  alignItems="center"
                  _hover={{ background: 'select.list.hover' }}
                  _selected={{ background: 'select.list.selected' }}
                  // @ts-ignore
                  onClick={isOptionDisabled(option) ? () => { } : optionsProps.onClick}
                >
                  <ListIcon>
                    {get(optionsProps, 'aria-selected') ? <SelectedCheckboxIcon /> : <UnselectedCheckboxIcon />}
                  </ListIcon>
                  <Text
                    style={{
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      lineHeight: '1rem',
                    }}
                    // untrancate text on hover
                    // testing - may be not viable solution
                    _hover={{
                      height: 'auto',
                      whiteSpace: 'normal !important',
                    }}
                  >
                    {getOptionLabel(option)}
                  </Text>
                </ListItem>
              );
            })}
            {showAddItem && (
              <ListItem
                py={0}
                px={4}
                height="40px"
                cursor="pointer"
                display="flex"
                alignItems="center"
                _hover={{ background: 'select.list.hover' }}
                _selected={{ background: 'select.list.selected' }}
                onClick={() => _onAddItem(inputValue)}
              >
                <Text isTruncated>{`Добавить "${inputValue}"`}</Text>
              </ListItem>
            )}
          </List>
        )}
        <FormErrorMessage>{error}</FormErrorMessage>
      </FormControl>
    );
  },
);
