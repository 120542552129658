import { useDisclosure } from '@chakra-ui/hooks';
import { Modal } from 'components/common/Modals';
import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import { useInitializerUploadSettings } from 'features/UploadedFileProvider/UploadFileSettings';
import { MeasuringItem } from 'hooks/geo/Measuring/useMeasuringList';
import React, { useMemo } from 'react';
import { useList } from 'hooks/useList';
import appLocale from 'constants/appLocale';
import { GeoecologicalRootPath } from 'pages/data/GeoecologicalProcesses/consts';
import { TFiltersArray } from 'models';
import { GeologicalProcessesGroundwaterHeader } from '../components';
import { UploadFileModalContent } from 'features/UploadFileDragAndDrop/UploadFileModalContent';
import { MeasuringFilter } from 'pages/data/GeoecologicalProcesses/GeologicalProcessesGroundwater/Measuring/MeasuringFilter/Filter';
import subsystemIdsMap from 'constants/subsystemIdsMap';
import { useAvailability, useHandleDeleteData } from 'hooks';
import { usePeriodsDict } from 'features/PeriodsButtons/usePeriodsDict';
import { MaterialTable } from 'features/material-table/MaterialTableMini';
import { templatesObject } from 'constants/templates';
import FilePreviewModalWrapper from 'components/common/FilePreviewModal/FilePreviewModalWrapper';
import { useHistory } from 'react-router-dom';
import useFilePreviewModalWrapperData from 'components/common/FilePreviewModal/hooks/useFilePreviewModalWrapperData';
import { addFiltersToColumns } from 'lib/utils/tableFilter';
import { Column } from '@material-table/core';
import { PropActions } from 'features/material-table/components/Actions';
import { DeleteIcon, EditIcon, ImageIcon } from 'components/icons';
import { columns } from './columns';
import { useGraph } from 'pages/data/GeoecologicalProcesses/GeologicalProcessesGroundwater/Measuring/Graph/useGraph';
import { useDictionary } from 'hooks/useDictionary';
import { DEFAULT_PAGE_SIZE_NEW_TABLE } from '../../../../../constants/tables';
import { FullPageWrapper } from '../../../../../components/table/FullPageWrapper';

export const Measuring = () => {
  const { isWrite, isExecute, isNoRights, allAvailable } = useAvailability(subsystemIdsMap.geoecologyRoot);

  useUpdateBreadcrumbs([
    { breadcrumb: appLocale.common.data, path: '/data' },
    {
      breadcrumb: appLocale.subsystems.geoecologicalprocesses,
      path: GeoecologicalRootPath,
    },
    {
      breadcrumb: appLocale.subsystems.groundwater,
      path: `${GeoecologicalRootPath}/monitoring/water/examination`,
    },
  ]);

  useInitializerUploadSettings(
    useMemo(
      () => ({
        typesPath: '/geoecology/v10/geoecology/watersource/measuring/load/types',
        previewPath: '/geoecology/v10/geoecology/watersource/monitoring/load/preview',
        validatePath: '/geoecology/v10/geoecology/watersource/monitoring/load/validate',
        applyPath: '/geoecology/v10/geoecology/watersource/measuring/load/apply',
        redirectPath: `${GeoecologicalRootPath}/monitoring/water/measuring`,
      }),
      [],
    ),
  );

  const { isOpen: isOpenLoad, onClose: onCloseLoad, onOpen: onOpenLoad } = useDisclosure();

  const {
    refetch,
    isLoading: isMeasuringListLoading,
    response,
    setFilters,
    toggleOrder,
    onPageChange,
    filterDTO,
    downloadAsFile,
    saveFilters,
    errorText,
    selectedIndexes,
    renderOnlySelectedCheckbox,
    onSubmitFilters,
    onSelectionChange,
    materialTableProps,
  } = useList<MeasuringItem, keyof MeasuringItem | 'periodId'>(
    '/geoecology/v10/geoecology/watersource/measuring/list',
    {
      initialOrderFieldKey: '-measuringDate',
      preventFirstFetch: true,
      exportSubsystemName: 'measuring',
      saveFiltersState: true,
      selectionKey: 'id',
      defaultPageSize: DEFAULT_PAGE_SIZE_NEW_TABLE,
      resetDataOnNextRequest: true,
    }
  );

  const { dictionaries } = useDictionary({ url: '/geoecology/v10/geoecology/watersource/measuring/dictionaries' });
  const { result: periods } = usePeriodsDict(`auth/v10/user/period/${subsystemIdsMap.geoGroundWater}`);

  const history = useHistory();
  const { open, ...filePreviewData } = useFilePreviewModalWrapperData();

  const { ModalComponent, handleOpenModal } = useHandleDeleteData({ refetch });

  const columnsWithFilters = useMemo<Array<Column<MeasuringItem>>>(
    () => addFiltersToColumns({ filters: filterDTO.filters, setFilters, columns }),
    [filterDTO.filters, setFilters],
  );

  const initialActions: PropActions<MeasuringItem[]> = useMemo(
    () => [
      {
        position: 'row',
        action: (row: any) => ({
          icon: () => <EditIcon />,
          onClick: () =>
            history.push(`/data/geoecological-processes/monitoring/water/measuring/edit/row/${row.id}`, row),
          tooltip: 'Редактировать',
        }),
      },
      {
        position: 'row',
        action: (row: any) => ({
          icon: () => <DeleteIcon />,
          onClick: () => handleOpenModal({ url: `/geoecology/v10/geoecology/watersource/measuring/${row.id}` }),
          tooltip: 'Удалить',
        }),
      },
      {
        position: 'row',
        action: (row: any) => ({
          icon: () => <ImageIcon />,
          onClick: () => open(row.id, row.objectType),
          tooltip: 'Показать изображения',
          hidden: !row.objectType,
        }),
      },
    ],
    [handleOpenModal, history, open],
  );

  // @ts-ignore
  const actions = useMemo<PropActions<any[]>>(() => {
    if (allAvailable || isNoRights || isWrite) {
      return initialActions;
    }
    if (!(allAvailable || isNoRights || isWrite)) {
      return initialActions.filter((action: any) => action.type !== 'delete');
    }
  }, [allAvailable, isNoRights, isWrite, initialActions]);

  const { GraphButton, GraphModal } = useGraph({
    filters: {
      dateFrom: filterDTO.filters.find((item) => item.key === 'measuringDate' && item.operation === 'GTE')?.value,
      dateTo: filterDTO.filters.find((item) => item.key === 'measuringDate' && item.operation === 'LTE')?.value,
      period: filterDTO.filters.find((item) => item.key === 'periodId')?.value,
      typeId: filterDTO.filters.find((item) => item.key === 'waterSourceType')?.value,
      sourceId: filterDTO.filters.find((item) => item.key === 'sourceId')?.value,
    },
    onClose(): void {
    },
    selectedIds: filterDTO.filters.find((item) => item.key === 'id')?.value as string[],
    periods,
  });

  return (
    <FullPageWrapper>
      <GeologicalProcessesGroundwaterHeader
        allAvailable={allAvailable}
        isNoRights={isNoRights}
        onOpenLoad={onOpenLoad}
        isWrite={isWrite}
        active="measuring"
      >
        <MeasuringFilter
          filters={filterDTO?.filters || []}
          periods={periods}
          isLoading={isMeasuringListLoading}
          isDisabled={isMeasuringListLoading}
          dictionaries={dictionaries}
          onSubmit={(values) => {
            const filters: TFiltersArray<keyof MeasuringItem> = Object.values(values);
            onSubmitFilters(filters);
          }}
        >
          {renderOnlySelectedCheckbox}
        </MeasuringFilter>
      </GeologicalProcessesGroundwaterHeader>

      <Modal isOpen={isOpenLoad} onClose={onCloseLoad} header="Загрузка данных из файла">
        <UploadFileModalContent
          createTypesPath={() => '/geoecology/v10/geoecology/watersource/measuring/load/types'}
          createUploadPath={(typeId: string) =>
            `/geoecology/v10/geoecology/watersource/monitoring/load?typeId=${typeId}`
          }
          types={[
            {
              name: 'Данные состояния водоисточников (Колодцы)',
              value: '2e378680-31e5-46b7-943c-d59635cef02e',
            },
            {
              name: 'Данные состояния водоисточников (Скважины)',
              value: 'a5c2a00a-4092-432b-9caa-da24443afd09',
            },
            {
              name: 'Данные состояния водоисточников (Родники)',
              value: '7c2b0b1a-a76e-4eb8-a66d-b86c28b8f1d3',
            },
          ]}
          onClose={onCloseLoad}
          refetch={refetch}
        />
      </Modal>

      <MaterialTable
        saveFilters={saveFilters}
        isNotExecute={isExecute || isNoRights}
        onSelectionChange={onSelectionChange}
        selectedIndexes={selectedIndexes}
        data={response?.data}
        meta={response?.meta}
        toggleOrder={toggleOrder}
        onPageChange={onPageChange}
        filterDTO={filterDTO}
        columns={columnsWithFilters}
        actions={actions}
        downloadAsFile={downloadAsFile}
        setFilters={setFilters}
        downloadTemplate={[...templatesObject.inspectionAndMeasurements, templatesObject.wellMeasurements]}
        isLoading={isMeasuringListLoading}
        topBarLeftContent={<GraphButton isDisabled={response?.data.length === 0} />}
        errorText={errorText}
        {...materialTableProps}
        showTotalCount
      />

      {GraphModal}

      {ModalComponent}

      <FilePreviewModalWrapper {...filePreviewData} />
    </FullPageWrapper>
  );
};
