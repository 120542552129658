import React from 'react';
import { Box, Flex, Grid, GridItem } from '@chakra-ui/react';
import { Field } from 'formik';
import { Checkbox, Input, Textarea } from 'components/common';
import { IComplaint } from '../types/types';

interface IProps {
  data: IComplaint;
}

export const AttachedAppealsInfo = ({ data }: IProps) => (
  <Flex justifyContent="start" marginTop={3}>
    <Grid marginLeft={6} marginBottom={2} gap={1} templateRows="repeat(4, 1fr)" templateColumns="repeat(4, 1fr)">
      <GridItem rowStart={1} rowEnd={1} colStart={1} colSpan={2}>
        <Field as={Input} name="citFio" value={data.citFio || ''} label="ФИО Заявителя" disabled />
      </GridItem>
      <GridItem rowStart={1} rowEnd={1} colStart={3} colSpan={1}>
        <Field as={Input} name="citEmail" value={data.citEmail} label="Контакты (aaa@mail.ru)" disabled />
      </GridItem>
      <GridItem rowStart={1} rowEnd={1} colStart={4} colSpan={1}>
        <Field as={Input} name="citPhone" value={data.citPhone} label="Контакты: +7(916) 111 1111" disabled />
      </GridItem>
      <GridItem rowStart={2} rowEnd={2} colStart={1} colSpan={4}>
        <Field as={Input} name="citAddress" value={data.citAddress} label="Адрес заявителя" disabled />
      </GridItem>
      <GridItem rowStart={3} rowEnd={3} colStart={1} colSpan={4}>
        <Field as={Textarea} name="text" value={data.text} label="Содержание обращения" disabled />
      </GridItem>
      {data.incidents?.map((incident, index) => (
        <React.Fragment key={index}>
          <GridItem colStart={1} colSpan={3} marginTop={5}>
            <Field as={Input} name="incidentAddress" value={incident.address} label="Адрес происшествия" disabled />
          </GridItem>
          <GridItem colStart={4} colSpan={4} marginTop={5}>
            <Checkbox defaultChecked={incident.isRaidComplete} name="isRaidComplete" isDisabled label="Рейд был" />
          </GridItem>
        </React.Fragment>
      ))}
    </Grid>
    <Box display="flex" flexDirection="column" marginLeft={3}>
      <Checkbox
        defaultChecked={data.shouldAnswerOnPaper}
        name="shouldAnswerOnPaper"
        isDisabled
        label="Ответ на бумаге"
      />
      <Checkbox defaultChecked={data.isReplied} name="complaintRepeated" isDisabled label="Повторная жалоба" />
    </Box>
  </Flex>
);
