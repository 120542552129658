import { SubsystemHeader } from 'components/common';
import { FormikProvider, useFormik } from 'formik';
import { GridItem } from '@chakra-ui/react';
import { useHandleDeleteData, useList, useNotifications, useRequest } from 'hooks';
import { MaterialTable } from 'features/material-table/MaterialTableMini';
import React, { Dispatch, useEffect } from 'react';
import { ERROR_MESSAGES, SUCCESS_MESSAGES } from 'constants/messages';
import { AttachedAppealsInfo } from './AttachedAppealsInfo';
import { AttachedAppealsFilter } from './AttachedAppealsFilter';
import { attachedAppealsColumns, AttachedAppealsInitialValues } from './consts/consts';
import { TFiltersArray } from 'models';
import { IComplaint } from './types/types';
import { useDictionary } from 'hooks/useDictionary';

interface IProps {
  id: string | undefined;
  setComplaints: Dispatch<any>;
}

export const AttachedAppeals = ({ id, setComplaints }: IProps) => {
  const { successNotify, errorNotify } = useNotifications();

  const { dictionaries } = useDictionary({ url: '/appeal/v10/appeals/dictionaries' });

  const {
    response: unAppeals,
    toggleOrder: unAppealsTO,
    onPageChange: unAppealsOnPageChange,
    filterDTO: unAppealsFilterDTO,
    refetch: unAppealsRefetch,
    setFilters,
    errorText: unAppealsErrorText,
  } = useList<IComplaint>('appeal/v10/complaints/search/not-bind-to-task');

  const {
    response: appeals,
    toggleOrder: appealsTO,
    onPageChange: appealsOnPageChange,
    filterDTO: appealsFilterDTO,
    refetch: appealsRefetch,
    errorText: appealsErrorText,
  } = useList<IComplaint>(`appeal/v10/tasks/${id}/complaints`);

  useEffect(() => {
    if (appeals) {
      const forSelect = appeals.data.map((appeal: IComplaint) => ({
        name: appeal.number,
        value: appeal.id,
      }));
      setComplaints(forSelect);
    }
  }, [appeals, setComplaints]);

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    initialValues: AttachedAppealsInitialValues,
    onSubmit: (values) => {
      const filters: TFiltersArray = Object.values(values);
      setFilters(filters);
    },
    onReset: () => {
      setFilters([]);
    },
  });

  const onBindSuccess = () => {
    unAppealsRefetch();
    appealsRefetch();
    successNotify({
      key: 'bind/success',
      message: SUCCESS_MESSAGES.BIND,
    });
  };
  const onBindError = () =>
    errorNotify({
      key: 'bind/error',
      message: ERROR_MESSAGES.BIND,
    });

  const { put } = useRequest('/', onBindSuccess, onBindError);

  const refetch = () => {
    appealsRefetch();
    unAppealsRefetch();
  };
  const { handleOpenModal, ModalComponent } = useHandleDeleteData({ refetch });

  return (
    <>
      <FormikProvider
        value={{
          ...formik,
        }}
      >
        <SubsystemHeader
          subTitle="Непривязанные обращения"
          filtersComponent={<AttachedAppealsFilter dictionaries={dictionaries} />}
        />

        <MaterialTable
          data={unAppeals?.data}
          meta={unAppeals?.meta}
          toggleOrder={unAppealsTO}
          onPageChange={unAppealsOnPageChange}
          filterDTO={unAppealsFilterDTO}
          detailPanel={(rowData) => <AttachedAppealsInfo data={rowData.rowData} />}
          columns={attachedAppealsColumns}
          actions={[
            {
              type: 'bind',
              onClick: (_e, row) => {
                put({}, { url: `appeal/v10/tasks/${id}/complaints/${row.id}` });
              },
            },
          ]}
          errorText={unAppealsErrorText}
        />
        <GridItem marginTop={10}>
          <SubsystemHeader
            subTitle="Обращения, привязанные к данной задаче"
          />
          <MaterialTable
            data={appeals?.data}
            meta={appeals?.meta}
            toggleOrder={appealsTO}
            onPageChange={appealsOnPageChange}
            filterDTO={appealsFilterDTO}
            detailPanel={(rowData) => <AttachedAppealsInfo data={rowData.rowData} />}
            columns={attachedAppealsColumns}
            actions={[
              {
                type: 'unbind',
                onClick: (_e, row) => {
                  handleOpenModal({ url: `appeal/v10/tasks/${id}/complaints/${row.id}` });
                },
              },
            ]}
            errorText={appealsErrorText}
          />
        </GridItem>
        {ModalComponent}
      </FormikProvider>
    </>
  );
};
