import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import appLocale from 'constants/appLocale';
import { useHistory, useParams } from 'react-router-dom';
import { Breadcrumb, LoaderItem, PageHeader } from 'components/common';
import { Heading } from '@chakra-ui/react';
import { FormikProvider, useFormik } from 'formik';
import { createTouchedErrors } from 'features/create-touched-errors';
import React, { useEffect } from 'react';
import { useNotifications, useRequest } from 'hooks';
import { INMUListItem } from '../types';
import { FormFields } from './FormFields';
import { ERROR_MESSAGES, SUCCESS_MESSAGES } from 'constants/messages';
import * as yup from 'yup';
import { requiredError } from 'models/schemas/constans';

export const AddAMCRecord = () => {
  const { id } = useParams<{ id: string }>();
  const { successNotify, errorNotify } = useNotifications();
  const history = useHistory();

  useUpdateBreadcrumbs([
    { breadcrumb: appLocale.common.data, path: '/data' },
    {
      breadcrumb: appLocale.subsystems.meteo,
      path: '/data/meteo',
    },
    {
      breadcrumb: 'Журнал НМУ',
      path: '/data/meteo/nmu',
    },
    {
      breadcrumb: id ? 'Редактирование записи НМУ' : 'Добавление записи НМУ',
      path: id ? `/data/meteo/nmu/add/${id}` : '/data/meteo/nmu/add',
    },
  ]);

  const onSuccess = () => {
    successNotify({
      key: 'nmu/success',
      message: id ? SUCCESS_MESSAGES.EDIT : SUCCESS_MESSAGES.CREATE,
    });
    history.push('/data/meteo/nmu');
  };
  const onError = () =>
    errorNotify({
      key: 'nmu/error',
      message: id ? ERROR_MESSAGES.EDIT : ERROR_MESSAGES.CREATE,
    });

  const { post, isLoading: isPostLoading } = useRequest('/meteo/v10/meteo/nmu', onSuccess, onError);
  const { get, result, isLoading: isGetLoading } = useRequest<INMUListItem>(`meteo/v10/meteo/nmu/${id}`);
  const { put, isLoading: isPutLoading } = useRequest(`/meteo/v10/meteo/nmu/${id}`, onSuccess, onError);

  useEffect(() => {
    if (id) {
      get();
    }
  }, [id, get]);

  const initialValues = {
    date: result?.date || '',
    deleted: result?.deleted || false,
    grade: result?.grade || '',
    gradeName: result?.gradeName || null,
    id: result?.id || null,
  };

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    initialValues,
    validationSchema: yup.object().shape({
      grade: yup.string().required(requiredError).nullable(),
      date: yup.string().required(requiredError).nullable(),
    }),
    onSubmit: (values) => {
      if (id) {
        put(values);
      } else {
        post(values);
      }
    },
  });

  if (isPutLoading || isPostLoading || isGetLoading) {
    return <LoaderItem />;
  }

  return (
    <>
      <PageHeader>
        <Breadcrumb to={{ pathname: '/data/meteo/nmu' }}>Вернуться назад</Breadcrumb>
        <Heading marginLeft={4}>
          {`${id ? 'Редактирование' : 'Добавление'}
          записи НМУ`}
        </Heading>
      </PageHeader>
      <FormikProvider
        value={{
          ...formik,
          errors: createTouchedErrors(formik.errors, formik.touched),
        }}
      >
        <FormFields />
      </FormikProvider>
    </>
  );
};
