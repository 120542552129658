import { map } from 'lodash';
import { Box, StackProps, VStack } from '@chakra-ui/react';

interface ErrorDescriptionsProps extends StackProps {
  descriptions: Array<{ message: string | null }> | undefined;
}

export const ErrorDescriptions: React.FC<ErrorDescriptionsProps> = ({ descriptions, ...props }) => (
  <VStack colorScheme="text.error" {...props}>
    {map(descriptions, (desc) => (
      <Box>{desc.message}</Box>
    ))}
  </VStack>
);
