const meteoLocales = {
  measuringDate: 'Дата и время',
  dateFrom: 'От',
  dateTo: 'До',
  sourceId: 'Источник',
  heightId: 'Отметка',
  value: 't °C',
  parameterId: 'Параметр',
  stationId: 'Станция',
};

export { meteoLocales };
