import { As, Button as ChakraButton, ButtonProps, forwardRef } from '@chakra-ui/react';

const Button = forwardRef<ButtonProps, As<any>>(
  ({ colorScheme = 'PrimaryButton', borderRadius = '2px', ...props }, ref) => (
    <ChakraButton ref={ref} {...props} borderRadius={borderRadius} colorScheme={colorScheme} />
  )
);

export type { ButtonProps };
export { Button };
