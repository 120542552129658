import appLocale from 'constants/appLocale';
import { NSIRootPath } from 'pages/dictionaries/const/routes';
import { Book } from 'features/WithSideBar/icons';
import { subsystemCodesMap } from 'constants/subsystemIdsMap';
import {
  GeologicalProcessesPath,
  GeologicalProcessesRootPath,
  GroundwaterPath,
} from 'pages/dictionaries/GeologicalProcesses/constants/constants';
import { AtmosphericAirRootPath } from 'pages/dictionaries/AtmosphericAir/consts';
import {
  GreenspacesAreaPath,
  GreenspacesLawnPath,
  GreenspacesRootPath,
  GreenspacesTreePath,
} from 'pages/dictionaries/Greenspaces/const';
import { NSISoilRootPath } from 'pages/dictionaries/Soil/consts';
import { unitLocales, UnitRootPath } from 'pages/dictionaries/MeasureUnits/consts';
import { ParameterRootPath, paramsLocale } from 'pages/dictionaries/Parameters/consts';
import { pdkLeftMenu } from 'pages/dictionaries/NSIPdk/consts';
import {
  NSIEmissionsIndustriesRootPath,
  NSIEmissionsRootPath,
  NSIEmissionsSourcesRootPath,
} from 'pages/dictionaries/NSIEmissions/consts/paths';
import { RenderTree } from 'features/WithSideBar/types';
import {
  NSIReagentsGroundRootPath,
  NSIReagentsLawnsPaths,
  NSIReagentsRootPath,
  NSIReagentsSitesPaths,
  NSIReagentsTreesPaths,
} from 'pages/dictionaries/NSIReagents/paths';
import {
  NSIWaterGatesPaths,
  NSIWaterReservoirsPaths,
  NSIWaterRootPath,
  NSIWaterSitesPaths,
} from 'pages/dictionaries/NSIWater/paths';
import { NSI_NMU_RootPath } from '../../../pages/dictionaries/NMU/consts';
import { NSI } from '../../../pages/home/icons';
import { NSIDangerScalePaths } from 'pages/dictionaries/NSIDangerScale/const';
import {
  permissibleNoiseLevelPath,
} from '../../../pages/dictionaries/MASKSH/PermissibleNoiseLevel/consts/consts';

export const nsiReagentsMenu: RenderTree = {
  name: appLocale.nsi.reagents.title,
  id: NSIReagentsRootPath,
  url: NSIReagentsRootPath,
  otherCode: subsystemCodesMap.nsi.pgr.pgrRoot,
  children: [
    {
      name: appLocale.nsi.reagents.ground.title,
      id: NSIReagentsGroundRootPath,
      url: NSIReagentsGroundRootPath,
      otherCode: subsystemCodesMap.nsi.pgr.vegetative,
    },
    {
      name: appLocale.nsi.reagents.lawns.title,
      id: NSIReagentsLawnsPaths.root,
      url: NSIReagentsLawnsPaths.root,
      otherCode: subsystemCodesMap.nsi.pgr.lawns,
    },
    {
      name: appLocale.nsi.reagents.trees.title,
      id: NSIReagentsTreesPaths.root,
      url: NSIReagentsTreesPaths.root,
      otherCode: subsystemCodesMap.nsi.pgr.flora,
    },
    {
      name: appLocale.nsi.reagents.sites.title,
      id: NSIReagentsSitesPaths.root,
      url: NSIReagentsSitesPaths.root,
      otherCode: subsystemCodesMap.nsi.pgr.sites,
    },
  ],
};

export const nsiWaterMenu: RenderTree = {
  name: appLocale.nsi.water.title,
  id: NSIWaterRootPath,
  url: NSIWaterRootPath,
  otherCode: subsystemCodesMap.nsi.waterObjects.waterObjectsRoot,
  children: [
    {
      name: appLocale.nsi.water.sites.title,
      id: NSIWaterSitesPaths.root,
      url: NSIWaterSitesPaths.root,
      otherCode: subsystemCodesMap.nsi.waterObjects.observationSites,
    },
    {
      name: appLocale.nsi.water.gates.title,
      id: NSIWaterGatesPaths.root,
      url: NSIWaterGatesPaths.root,
      otherCode: subsystemCodesMap.nsi.waterObjects.observationLines,
    },
    {
      name: appLocale.nsi.water.reservoirs.title,
      id: NSIWaterReservoirsPaths.root,
      url: NSIWaterReservoirsPaths.root,
      otherCode: subsystemCodesMap.nsi.waterObjects.waterObjects,
    },
  ],
};

export const nsiMASKSHMenu: RenderTree = {
  name: 'М-АСКШ',
  id: permissibleNoiseLevelPath,
  url: permissibleNoiseLevelPath,
  otherCode: subsystemCodesMap.nsi.MASKSH.PermissibleNoiseLevel,
  children: [
    {
      name: 'М-АСКШ: Допустимые уровни шума',
      id: permissibleNoiseLevelPath,
      url: permissibleNoiseLevelPath,
      otherCode: subsystemCodesMap.nsi.MASKSH.PermissibleNoiseLevel,
    },
  ],
};

export const nsiMenu: RenderTree = {
  name: appLocale.mainPage.nsi,
  id: NSIRootPath,
  url: NSIRootPath,
  icon: <Book />,
  homepageIcon: <NSI />,
  otherCode: subsystemCodesMap.nsi.nsiRoot,
  children: [
    nsiWaterMenu,
    {
      name: appLocale.subsystems.geoecologicalprocesses,
      id: GeologicalProcessesRootPath,
      otherCode: subsystemCodesMap.nsi.geologicalProcesses.geologicalRoot,
      children: [
        {
          name: 'Опасные геологические процессы',
          id: GeologicalProcessesPath,
          url: `${GeologicalProcessesPath}/observationArea`,
          otherCode: subsystemCodesMap.nsi.geologicalProcesses.dangerGeologicalProcesses,
          dependentPrivileges: [
            subsystemCodesMap.nsi.geologicalProcesses.observationArea,
            subsystemCodesMap.nsi.geologicalProcesses.observationPoint,
            subsystemCodesMap.nsi.geologicalProcesses.inclinometer,
            subsystemCodesMap.nsi.geologicalProcesses.mark,
            subsystemCodesMap.nsi.geologicalProcesses.reper,
          ]
        },
        {
          name: appLocale.subsystems.groundwater,
          id: GroundwaterPath,
          url: GroundwaterPath,
          otherCode: subsystemCodesMap.nsi.geologicalProcesses.groundWater,
        },
      ],
    },
    {
      name: appLocale.nsi.askza.title,
      id: AtmosphericAirRootPath,
      url: AtmosphericAirRootPath,
      otherCode: subsystemCodesMap.nsi.ASKZA,
    },
    {
      name: appLocale.subsystems.greenspaces,
      id: GreenspacesRootPath,
      otherCode: subsystemCodesMap.nsi.greenplants.greenplantsRoot,
      children: [
        {
          name: appLocale.subsystems.greenspacesGrass,
          id: GreenspacesLawnPath,
          url: GreenspacesLawnPath,
          otherCode: subsystemCodesMap.nsi.greenplants.lawns,
        },
        {
          name: appLocale.subsystems.greenspacesTree,
          id: GreenspacesTreePath,
          url: GreenspacesTreePath,
          otherCode: subsystemCodesMap.nsi.greenplants.flora,
        },
        {
          name: appLocale.subsystems.greenspacesPlatforms,
          id: GreenspacesAreaPath,
          url: GreenspacesAreaPath,
          otherCode: subsystemCodesMap.nsi.greenplants.sites,
        },
      ],
    },
    {
      name: appLocale.subsystems.nsiSoil,
      id: NSISoilRootPath,
      url: NSISoilRootPath,
      otherCode: subsystemCodesMap.nsi.soil,
    },
    {
      name: appLocale.nsi.dangerScale.shortTitle,
      id: NSIDangerScalePaths.root,
      url: NSIDangerScalePaths.root,
      otherCode: subsystemCodesMap.nsi.dangerScale,
    },
    {
      name: unitLocales.unitsDict,
      id: UnitRootPath,
      url: UnitRootPath,
      otherCode: subsystemCodesMap.nsi.units,
    },
    {
      name: paramsLocale.parameters,
      id: ParameterRootPath,
      url: ParameterRootPath,
      otherCode: subsystemCodesMap.nsi.params,
    },
    pdkLeftMenu,
    {
      name: 'Промышленные выбросы в атмосферу',
      id: NSIEmissionsRootPath,
      url: NSIEmissionsRootPath,
      otherCode: subsystemCodesMap.nsi.industrialEmissions.industrialEmissionsRoot,
      children: [
        {
          name: 'Источники загрязнения воздуха',
          id: NSIEmissionsSourcesRootPath,
          url: NSIEmissionsSourcesRootPath,
          otherCode: subsystemCodesMap.nsi.industrialEmissions.sourses,
        },
        {
          name: 'Предприятия',
          id: NSIEmissionsIndustriesRootPath,
          url: NSIEmissionsIndustriesRootPath,
          otherCode: subsystemCodesMap.nsi.industrialEmissions.enterprises,
        },
      ],
    },
    nsiReagentsMenu,
    {
      name: 'Периоды НМУ',
      id: NSI_NMU_RootPath,
      url: NSI_NMU_RootPath,
      otherCode: subsystemCodesMap.nsi.nmu,
    },
  ],
};
