import { Grid, GridItem } from '@chakra-ui/react';
import { Field, FormikContextType, FormikErrors, FormikValues, useFormikContext } from 'formik';
import { Input, Select } from 'components/common';
import React from 'react';
import { TDictionaryItemDictionary } from 'models';

interface IProps {
  sourceTypes: TDictionaryItemDictionary[];
  funcZones: TDictionaryItemDictionary[];
  funcZoneTypes: TDictionaryItemDictionary[];
  statuses: TDictionaryItemDictionary[];
}

export const GeneralInformation = ({ statuses, sourceTypes, funcZoneTypes, funcZones }: IProps) => {
  const { errors }: FormikContextType<FormikErrors<FormikValues>> = useFormikContext();

  return (
    <>
      <Grid maxWidth={700} gap={2} templateColumns="repeat(1, 1fr)">
        <GridItem>
          <Field as={Input} name="number" label="Уникальный номер точки" />
        </GridItem>
        <GridItem>
          <Field as={Input} name="name" label="Наименование" />
        </GridItem>
        <GridItem>
          <Field as={Input} name="shortName" label="Наименование кратко" />
        </GridItem>
        <GridItem>
          <Field as={Select} name="status" label="Статус" options={statuses} error={errors?.status} />
        </GridItem>
        <GridItem>
          <Field
            as={Select}
            name="soilSourceTypeId"
            label="Тип источника наблюдения за почвой"
            options={sourceTypes}
            error={errors?.soilSourceTypeId}
          />
        </GridItem>
        <GridItem>
          <Field
            as={Select}
            name="soilFuncZoneId"
            label="Функциональная зона"
            options={funcZones}
            error={errors?.soilFuncZoneId}
          />
        </GridItem>
        <GridItem>
          <Field
            as={Select}
            name="soilFuncZoneTypeId"
            label="Тип функциональной зоны"
            options={funcZoneTypes}
            error={errors?.soilFuncZoneTypeId}
          />
        </GridItem>
      </Grid>
    </>
  );
};
