import React from 'react';
import { PageHeader, RoutesList } from 'components/common';
import { Flex, Heading } from '@chakra-ui/react';
import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import appLocale from 'constants/appLocale';
import { useRoutesList } from 'hooks/useRoutesList';
import { dataPublishingMenu } from '../reportsAnalyticsMenu';

export const DataPublishingList: React.FC = () => {
  useUpdateBreadcrumbs([
    { breadcrumb: appLocale.subsystems.analyticsReports, path: '/reports-analytics' },
    { breadcrumb: appLocale.analyticsReports.dataPublishing, path: '/reports-analytics/data-publishing' },
  ]);

  const routes = useRoutesList(dataPublishingMenu);

  return (
    <Flex flexDirection="column">
      <PageHeader>
        <Heading>{appLocale.analyticsReports.dataPublishing}</Heading>
      </PageHeader>
      <RoutesList routes={routes} />
    </Flex>
  );
};
