import { AnalyticsData } from 'pages/data/analytics-data';
import { WaterRouteSurfaceWatersRoot } from 'pages/data/Water';
import subsystemIdsMap from 'constants/subsystemIdsMap';
import { SurfaceWaterLocales } from 'pages/data/Water/SurfaceWater/locales';

export const SurfaceWaterAnalyticsTab = () => (
  <AnalyticsData
    title={SurfaceWaterLocales.title}
    rootPath={WaterRouteSurfaceWatersRoot}
    subsystemId={subsystemIdsMap.surfaceWater}
    mainDataTitle="Протоколы сторонних организаций"
    analyticsDataTitle="Протоколы аналитической лаборатории"
  />
);
