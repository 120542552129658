import { Checkbox, Input, Select } from 'components/common';
import { Field, FormikProvider, FormikValues, useFormik } from 'formik';
import { Flex, Grid } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { IFilterItem, TDictionaryItemDictionary } from 'models';
import { geoecologicalInitialValues } from '../../../constants/constants';
import FilterFormControls from 'components/form/FilterFormControls';
import combineFilters from 'lib/utils/combineFilters';

interface IProps {
  type: string;
  setFilters: (filters: any) => void;
  districts: TDictionaryItemDictionary[];
  regions: TDictionaryItemDictionary[];
  statuses: TDictionaryItemDictionary[];
  areas: TDictionaryItemDictionary[];
  isLoading: boolean;
  filters: IFilterItem[];
}

export const Filter = ({ type, setFilters, districts, regions, statuses, areas, isLoading, filters }: IProps) => {
  useEffect(() => {
    const initialFilters = combineFilters(geoecologicalInitialValues, filters);
    Object.keys(initialFilters).forEach((key) => {
      setFieldValue(`${key}.value`, initialFilters[key].value);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = (values: FormikValues) => {
    const filters = Object.values(values);
    setFilters(filters);
  };

  const {
    resetForm,
    values,
    errors,
    getFieldMeta,
    getFieldHelpers,
    getFieldProps,
    handleReset,
    submitForm,
    setFieldValue,
    ...formik
  } = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    initialValues: geoecologicalInitialValues,
    onSubmit,
  });

  return (
    <FormikProvider
      value={{
        resetForm,
        values,
        errors,
        getFieldMeta,
        getFieldHelpers,
        getFieldProps,
        handleReset,
        submitForm,
        setFieldValue,
        ...formik,
      }}
    >
      <Flex>
        <Grid gap={2} templateColumns="repeat(4, 1fr)">
          <>
            {type === 'observationArea' && <Field as={Input} name="name.value" label="Название участка" />}
            {type === 'observationPoint' && <Field as={Input} name="number.value" label="Номер точки" />}
            {type === 'inclinometer' && <Field as={Input} name="number.value" label="Номер инклинометра" />}
            {type === 'mark' || (type === 'reper' && <Field as={Input} name="number.value" label="Код объекта" />)}
            {type !== 'observationArea' && (
              <Field as={Select} name="areaId.value" label="Участок наблюдения" options={areas} />
            )}

            <Field as={Select} name="rayon.value" label="Район" options={districts} />

            <Field as={Select} name="okrug.value" label="Округ" options={regions} />

            <Field as={Input} name="address.value" label="Адрес" />

            {type !== 'observationArea' && (
              <>
                <Field as={Select} name="status.value" label="Статус" options={statuses} />

                <Field as={Checkbox} name="deleted.value" label="Показать удаленные" />
              </>
            )}
          </>
        </Grid>

        <FilterFormControls
          onReset={(e) => {
            handleReset(e);
            resetForm();
            setFilters([{ key: 'deleted', operation: 'EQ', value: false }]);
          }}
          onSubmit={submitForm}
          isLoading={isLoading}
          isDisabled={isLoading}
        />
      </Flex>
    </FormikProvider>
  );
};
