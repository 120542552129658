import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import appLocale from 'constants/appLocale';
import { SubsystemHeader } from 'components/common';
import React, { useEffect, useMemo } from 'react';
import { MaterialTable } from 'features/material-table/MaterialTableMini';
import { DataEntryLogFilter } from './Filter';
import { useList, useRequest } from 'hooks';
import { baseAdminUrl } from 'pages/admin';
import { TDictionariesArray } from '../../../models';
import { Column } from '@material-table/core';
import { createLocalDateDDMMYYYYWithTime } from 'lib/create-date';
import { DEFAULT_PAGE_SIZE_NEW_TABLE } from '../../../constants/tables';
import { FullPageWrapper } from '../../../components/table/FullPageWrapper';
import { DefaultTableCellSize } from '../../../components/table/DefaultTableCellSize';

interface Props {
  breadcrumbs?: { path: string; breadcrumb: string }[];
}

const initialBreadcrumbs = [
  { breadcrumb: appLocale.admin.main, path: '/admin' },
  {
    breadcrumb: appLocale.admin.dataEntryLog,
    path: '/admin/dataEntryLog',
  },
];

export const DataEntryLog = ({ breadcrumbs = initialBreadcrumbs }: Props) => {
  useUpdateBreadcrumbs(breadcrumbs);

  const { setFilters, onPageChange, filterDTO, response, isLoading, toggleOrder, onSearchText } = useList(
    `${baseAdminUrl}/journal/load-events`,
    {
      initialOrderFieldKey: '-logDate',
      defaultPageSize: DEFAULT_PAGE_SIZE_NEW_TABLE,
      resetDataOnNextRequest: true,
    },
  );

  const { result: dictionaries, get } = useRequest<{
    data: TDictionariesArray;
  }>(`${baseAdminUrl}/journal/load-events/dictionaries`);

  useEffect(() => {
    if (get) {
      get();
    }
  }, [get]);

  const columns = useMemo<Column<any>[]>(
    () => [
      {
        title: 'Дата/время логирования',
        field: 'logDate',
        render: (row) => createLocalDateDDMMYYYYWithTime(row.logDate),
      },
      {
        title: 'Подсистема',
        field: 'subsystem',
      },
      {
        title: 'Действие',
        field: 'function',
      },
      {
        title: 'Уровень логирования',
        field: 'level',
      },
      {
        title: 'Описание',
        field: 'message',
        render: (row) => <DefaultTableCellSize maxWidth={400}>{row.message}</DefaultTableCellSize>
      },
    ],
    [],
  );

  return (
    <FullPageWrapper>
      <SubsystemHeader
        title={appLocale.admin.dataEntryLog}
        filtersComponent={<DataEntryLogFilter setFilters={setFilters} dictionaries={dictionaries} />}
      />
      <MaterialTable
        data={response?.data}
        meta={response?.meta}
        toggleOrder={toggleOrder}
        onPageChange={onPageChange}
        filterDTO={filterDTO}
        columns={columns}
        isLoading={isLoading}
        onSearch={onSearchText}
        options={{
          selection: false,
          search: true,
        }}
      />
    </FullPageWrapper>
  );
};
