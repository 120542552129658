import { useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FormikProvider, useFormik } from 'formik';

import { createTouchedErrors } from 'features/create-touched-errors';
import { useNotifications } from 'hooks/useNotifications';
import { useRequest } from 'hooks/useRequest';
import { ERROR_MESSAGES, SUCCESS_MESSAGES } from 'constants/messages';

import { InstructionForm } from 'pages/data/MASKSH/Instructions/InstructionsForm/Form';
import {
  IInstructionRecord,
  InsctructionForm,
  MASKSHApiPackageInstructionCreate,
  MASKSHInstructions,
} from 'pages/data/MASKSH/consts';
import { useMASKSHDictionary } from 'pages/data/MASKSH/hooks';
import { initialEmptyValues } from 'pages/data/MASKSH/Instructions/InstructionsForm/configs';
import { IUploadMedia } from 'components/common/FileUploadDragAndDrop/types';
import { useUpdateFiles } from 'api/services/media';
import { useObjectType } from 'hooks/useObjectType';
import { instructionForm } from 'models/schemas/MASKSH/instructionForm';
import { ApiError } from 'models';
import { formatErrorMessage } from 'lib/utils/formatErrorMessage';
import { useGoBackWithFallback } from 'lib/utils/goBackWithFallback';
import { LoaderItem } from '../../../../../components/common';

export const AddInstruction = () => {
  const { backWithFallback } = useGoBackWithFallback();
  const { replace } = useHistory();
  const { result } = useMASKSHDictionary('instruction');
  const { successNotify, errorNotify } = useNotifications();
  const [media, setMedia] = useState<IUploadMedia[]>([]);
  const { updateFiles, isLoading: isUpdateFilesLoading } = useUpdateFiles();
  const objectType = useObjectType(result);

  const onSuccess = (response: IInstructionRecord | null) => {
    if (!response) {
      onError();
      return;
    }

    updateFiles(response.documentId, objectType, media)
      .then(() => {
        successNotify({
          key: 'Instruction/add/success',
          message: SUCCESS_MESSAGES.CREATE,
        });
        backWithFallback(MASKSHInstructions);
      })
      .catch((error: ApiError) => {
        onError(error);
        replace(`${MASKSHInstructions}/edit/${response.id}`, response);
      });
  };

  const onError = useCallback(
    (error?: ApiError | null) => {
      errorNotify({
        key: formatErrorMessage(error) || 'MASKSH/add/error',
        message: formatErrorMessage(error) || ERROR_MESSAGES.CREATE,
      });
    },
    [errorNotify],
  );

  const { post, isLoading } = useRequest<IInstructionRecord, any>(
    MASKSHApiPackageInstructionCreate,
    onSuccess,
    onError,
  );

  const useFormikProps = useMemo(
    () => ({
      enableReinitialize: true,
      validateOnChange: true,
      validateOnMount: true,
      validateOnBlur: true,
      initialValues: initialEmptyValues,
      validationSchema: instructionForm,
      onSubmit: (values: InsctructionForm) => {
        void post(values);
      },
    }),
    [post],
  );

  const formik = useFormik(useFormikProps);

  return (
    <>
      {isLoading || isUpdateFilesLoading ? (
        <>
          <LoaderItem />
        </>
      ) : (
        <div>
          <FormikProvider
            value={{
              ...formik,
              errors: createTouchedErrors(formik.errors, formik.touched),
            }}
          >
            <InstructionForm dictionaries={result} media={media} setMedia={setMedia} />
          </FormikProvider>
        </div>
      )}
    </>
  );
};
