import { useCallback, useMemo, useState } from 'react';
import { useDisclosure, useEnhancedNotification, useRequest } from 'hooks';
import { REMOVE_CONFIRMATION } from 'constants/messages';
import { ConfirmModal } from 'components/common';
import { AxiosRequestConfig } from 'axios';

interface IUseHandleDeleteData {
  refetch: () => void;
  modalTitle?: string;
}

/**
 * hook for delete row
 * @param refetch
 */
export const useHandleDeleteData = ({ refetch, modalTitle }: IUseHandleDeleteData) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [removeOptions, setRemoveOptions] = useState<AxiosRequestConfig>({});

  const { onSuccessDelete, onErrorDelete } = useEnhancedNotification();

  const onSuccess = useCallback(() => {
    onClose();
    refetch();
    onSuccessDelete();
  }, [onClose, onSuccessDelete, refetch]);

  const { remove, isLoading } = useRequest('', onSuccess, onErrorDelete);

  const handleOpenModal = useCallback((removeOptions: AxiosRequestConfig) => {
    onOpen();
    setRemoveOptions(removeOptions);
  }, [onOpen]);

  const handleCloseModal = useCallback(() => {
    onClose();
    setRemoveOptions({});
  }, [onClose]);

  const ModalComponent = useMemo(() => (
    <ConfirmModal
      isOpen={isOpen}
      onClose={handleCloseModal}
      onClick={() =>
        remove(removeOptions)
      }
      header={modalTitle || REMOVE_CONFIRMATION}
      isLoading={isLoading}
    />
  ), [handleCloseModal, isLoading, isOpen, modalTitle, remove, removeOptions]);

  return {
    ModalComponent,
    handleOpenModal,
  };
};
