import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import appLocale from 'constants/appLocale';
import { NSIRootPath } from '../../../const/routes';
import { GeoecologyAPIPath, GeoecologyDictPath, GroundwaterAPIPath, GroundwaterPath } from '../../constants/constants';
import { BreadcrumbButton, Button, ContentContainer, PageHeader } from 'components/common';
import { Flex, Heading, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import React, { FC, useEffect, useMemo } from 'react';
import { GeneralInformation } from './GeneralInformation';
import { Location } from './Location';
import { useParams } from 'react-router-dom';
import { FormikProvider, FormikValues, useFormik } from 'formik';
import { useEnhancedNotification, useNotifications, useRequest, UseRequestReturnType } from 'hooks';
import { getSelectOptionsFromDictionaryByName } from 'features/get-select-options-from-dictionary';
import { SUCCESS_MESSAGES } from 'constants/messages';
import * as yup from 'yup';
import { requiredError } from 'models/schemas/constans';
import { useGoBackWithFallback } from 'lib/utils/goBackWithFallback';
import { useDictionary } from 'hooks/useDictionary';

export const EditGroundwaterData: FC = () => {
  useUpdateBreadcrumbs([
    {
      breadcrumb: appLocale.mainPage.nsi,
      path: NSIRootPath,
    },
    {
      breadcrumb: appLocale.subsystems.groundwater,
      path: GroundwaterPath,
    },
    {
      breadcrumb: 'Добавление справочных данных',
      path: `${GroundwaterPath}/add`,
    },
  ]);

  const { backWithFallback } = useGoBackWithFallback();

  const { id } = useParams<{ id: string }>();

  const { successNotify } = useNotifications();
  const { onErrorCreate, onErrorEdit } = useEnhancedNotification();

  const onSuccess = () => {
    successNotify({
      key: 'groundwater/edit/success',
      message: id ? SUCCESS_MESSAGES.EDIT : SUCCESS_MESSAGES.EDIT,
    });
    backWithFallback(`${GroundwaterPath}`);
  };

  const { result, get }: UseRequestReturnType = useRequest(`${GroundwaterAPIPath}/${id}`);
  const { post } = useRequest(`${GeoecologyAPIPath}/create`, onSuccess, onErrorCreate);
  const { put } = useRequest(GeoecologyAPIPath, onSuccess, onErrorEdit);

  useEffect(() => {
    if (id) {
      get();
    }
  }, [id, get]);

  const onSubmit = (values: FormikValues) => {
    if (id) {
      put({ ...values, id });
    } else {
      post({ ...values });
    }
  };

  const groundWaterSchema = yup.object().shape({
    status: yup.string().nullable().required(requiredError),
    objectType: yup.string().nullable().required(requiredError),
    number: yup.string().nullable().required(requiredError),
  });

  const initialValues = {
    number: result?.number || '',
    objectType: result?.objectType || '',
    aquiferId: result?.aquiferId || null,
    name: result?.name || '',
    status: result?.status || '',
    latitude: result?.latitude || '',
    longitude: result?.longitude || '',
    okrugId: result?.okrugId || null,
    rayonId: result?.rayonId || null,
    landmark: result?.landmark || '',
    address: result?.address || null,
    id: id || null,
    type: 'groundwater',
  };

  const { dictionaries } = useDictionary({ url: GeoecologyDictPath });

  const statuses = useMemo(() => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'statuses'), [dictionaries]);
  const okrugs = useMemo(() => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'okrugs'), [dictionaries]);
  const rayons = useMemo(() => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'rayons'), [dictionaries]);
  const aquifers = useMemo(
    () =>
      getSelectOptionsFromDictionaryByName(dictionaries?.data, 'aquifers', false, initialValues.aquiferId as string),
    [dictionaries?.data, initialValues.aquiferId],
  );
  const waterTypes = useMemo(
    () =>
      getSelectOptionsFromDictionaryByName(dictionaries?.data, 'waterTypes', false, initialValues.objectType as string),
    [dictionaries?.data, initialValues.objectType],
  );

  const {
    values,
    errors,
    getFieldMeta,
    getFieldHelpers,
    getFieldProps,
    handleReset,
    submitForm,
    setFieldValue,
    ...formik
  } = useFormik({
    validationSchema: groundWaterSchema,
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: false,
    validateOnBlur: true,
    initialValues,
    onSubmit,
  });

  return (
    <>
      <ContentContainer margin={0} padding={0}>
        <FormikProvider
          value={{
            values,
            errors,
            getFieldMeta,
            getFieldHelpers,
            getFieldProps,
            handleReset,
            submitForm,
            setFieldValue,
            ...formik,
          }}
        >
          <PageHeader>
            <BreadcrumbButton onClick={() => backWithFallback(GroundwaterPath)}>Вернуться назад</BreadcrumbButton>
            <Flex>
              <Heading marginLeft={4}>{`${id ? 'Редактирование' : 'Добавление'} справочных данных`}</Heading>
              <Flex marginLeft="auto">
                <Button marginRight={5} onClick={() => backWithFallback(GroundwaterPath)}>
                  Отмена
                </Button>
                <Button onClick={submitForm}>Сохранить</Button>
              </Flex>
            </Flex>
          </PageHeader>
          <Tabs marginTop={10}>
            <TabList>
              <Tab>Общие сведения</Tab>
              <Tab>Местоположение</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <GeneralInformation statuses={statuses} aquifers={aquifers} waterTypes={waterTypes} isEditMode={!!id} />
              </TabPanel>
              <TabPanel>
                <Location rayons={rayons} okrugs={okrugs} />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </FormikProvider>
      </ContentContainer>
    </>
  );
};
