import { useNotifications, useRequest } from 'hooks';
import { useCallback } from 'react';
import { ListResponse } from 'models/api/list';
import { ERROR_MESSAGES } from 'constants/messages';
import { IFilterItem } from 'models';

interface IUseGetListItemByIds {
  url: string;
  idKey: string;
  showDeleted?: boolean;
}

export default function useGetListItemByIds<T>({ url, idKey, showDeleted = false }: IUseGetListItemByIds) {
  const { errorNotify } = useNotifications();
  const onError = () =>
    errorNotify({
      key: 'getListByIds',
      message: ERROR_MESSAGES.GET_DATA,
    });

  const { post, isLoading } = useRequest<ListResponse<T>>(url, undefined, onError);
  const getItems = useCallback<(
  ids?: string[],
  initialFilter?:
  IFilterItem[]
  ) => Promise<T[]>>(async (ids, initialFilter = []) => {
      if (!ids || ids.length === 0) return [];

      const filters: IFilterItem[] = [
        {
          key: idKey,
          value: ids,
          operation: 'IN'
        },
        ...initialFilter.filter((item) => item.key !== idKey)
      ];

      if (showDeleted) {
        filters.push({
          key: 'deleted',
          operation: 'EQ',
          value: false,
        });
      }

      const filter = {
        limit: ids.length,
        offset: 0,
        filters,
        orders: [],
      };
      const response = await post(filter);
      if (response && 'data' in response) {
        return response.data;
      }

      return [];
    }, [idKey, post, showDeleted]
    );

  return {
    getItems,
    isLoading
  };
}
