import { useEffect } from 'react';

import { useRequest } from 'hooks/useRequest';
import { useNotifications } from 'hooks/useNotifications';

import { apiPaths } from '../../apiPaths';
import { SelectOption } from '../../../Select/types';

interface UseFiltersDict {
  subSystemId: string;
  templateId: string;
  paramsIds?: string[];
  from?: string;
  to?: string;
  isSpecified?: true;
}
export const useSourcesDict = ({ subSystemId, templateId, paramsIds, from, to, isSpecified }: UseFiltersDict) => {
  const methodName = isSpecified ? 'sourcesSpecified' : 'sources';
  const {
    get: getSources,
    error,
    result: sources,
    isLoading: isLoadingSources,
    reset: resetSources,
  } = useRequest<SelectOption[]>(apiPaths.reporting[methodName]({ subSystemId, templateId, paramsIds, from, to }));

  const { errorNotify } = useNotifications();

  useEffect(() => {
    if (error) {
      errorNotify({
        message: 'Ошибка получения источников',
        key: 'graphic_sources_error',
      });
      resetSources();
    }
  }, [error, errorNotify, resetSources]);

  return {
    isLoadingSources,
    hasErrorSources: !!error,
    sources,
    getSources,
  };
};
