import * as yup from 'yup';
import { numberError, requiredError } from '../constans';

export const NsiPDVNoSourceSchema = yup.object().shape({
  industryId: yup.string().nullable().required(requiredError),
  oneTimeEmission: yup.number().nullable().required(requiredError).typeError(numberError),
  parameterId: yup.string().nullable().required(requiredError),
  startDate: yup.string().nullable().required(requiredError),
  totalEmission: yup.number().nullable().required(requiredError).typeError(numberError),
  error: yup.number().nullable().required(requiredError).typeError(numberError),
  nmu1: yup.number().nullable().required(requiredError).typeError(numberError),
  nmu2: yup.number().nullable().required(requiredError).typeError(numberError),
  nmu3: yup.number().nullable().required(requiredError).typeError(numberError),
});
