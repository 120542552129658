import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import appLocale from 'constants/appLocale';
import { MaterialTable } from 'features/material-table/MaterialTableMini';
import React, { useMemo } from 'react';
import { SubsystemHeader } from 'components/common';
import { Column } from '@material-table/core';
import { createLocalDateDDMMYYYYWithTime } from 'lib/create-date';
import { useList } from 'hooks';
import { NotificationFilter } from './NotificationFilter';
import { addFiltersToColumns } from 'lib/utils/tableFilter';
import { TableFilterTypeEnum } from 'components/table/filters/TableFilter';
import { TruncatedStringPopoverCell } from 'components/table/TruncatedStringPopoverCell';
import { useDictionary } from 'hooks/useDictionary';
import { INotification } from './types';
import { DEFAULT_PAGE_SIZE_NEW_TABLE } from '../../../constants/tables';
import { FullPageWrapper } from '../../../components/table/FullPageWrapper';

export const NotificationJournal = () => {
  useUpdateBreadcrumbs([
    { breadcrumb: appLocale.admin.main, path: '/admin' },
    {
      breadcrumb: appLocale.admin.noticeLog,
      path: '/admin/noticeLog',
    },
  ]);

  const { dictionaries } = useDictionary({ url: '/admin/v10/admin/notifications/dictionaries' });

  const {
    isLoading,
    response,
    toggleOrder,
    setFilters,
    onPageChange,
    filterDTO,
    onSearchText,
    downloadAsFile,
    errorText,
  } = useList<INotification, keyof INotification>('/admin/v10/admin/notifications/list', {
    initialOrderFieldKey: '-date',
    defaultPageSize: DEFAULT_PAGE_SIZE_NEW_TABLE,
    resetDataOnNextRequest: true,
  });

  const columns = useMemo<Column<INotification>[]>(
    () => [
      {
        title: 'Пользователь',
        field: 'username',
      },
      {
        title: 'Время уведомления',
        field: 'date',
        render: (row) => createLocalDateDDMMYYYYWithTime(row.date),
      },
      {
        title: 'Тип уведомления',
        field: 'type',
      },
      {
        title: 'Подсистема',
        field: 'subsystemName',
      },
      {
        title: 'Содержание',
        field: 'message',
        render: (row) => <TruncatedStringPopoverCell value={row.message} isLargeText />,
        filterType: TableFilterTypeEnum.string,
      },
      {
        title: 'Способ уведомления',
        field: 'channels',
        render: (row) => row.channels.join(', '),
      },
    ],
    []
  );

  const columnsWithFilters = useMemo<Array<Column<INotification>>>(
    () => addFiltersToColumns({ filters: filterDTO.filters, setFilters, columns }),
    [filterDTO.filters, setFilters, columns]
  );

  return (
    <FullPageWrapper>
      <SubsystemHeader
        title={appLocale.admin.noticeLog}
        filtersComponent={
          <NotificationFilter onSubmit={(values) => setFilters(Object.values(values))} dictionaries={dictionaries} />
        }
      />
      <MaterialTable
        data={response?.data}
        meta={response?.meta}
        toggleOrder={toggleOrder}
        filterDTO={filterDTO}
        onPageChange={onPageChange}
        columns={columnsWithFilters}
        isLoading={isLoading}
        setFilters={setFilters}
        options={{ selection: true }}
        onSearch={onSearchText}
        downloadAsFile={downloadAsFile}
        errorText={errorText}
      />
    </FullPageWrapper>
  );
};
