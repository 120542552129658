import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import { createGreenPlantsAddUrl, GreenPlantsFloraUrl, GreenPlantsRootPath } from 'pages/routes/GreenPlants';
import appLocale from 'constants/appLocale';
import { GreenPlantsForm } from '../../Form/GreenPlantsForm';
import { greenPlantsFormInitialValues, GreenPlantsFormTypes, TGreenPlantsForm } from '../../Form/types';
import { useFloraAddItem } from './useFloraAddItem';
import { useFloraDictionaries } from '../useFloraDictionaries';
import React, { useCallback, useState } from 'react';
import { updateFiles } from 'api/services/media';
import { IUploadMedia } from 'components/common/FileUploadDragAndDrop/types';
import { ERROR_MESSAGES } from 'constants/messages';
import { useNotifications } from 'hooks';
import { useHistory } from 'react-router-dom';
import { useObjectType } from 'hooks/useObjectType';
import { useGoBackWithFallback } from '../../../../../lib/utils/goBackWithFallback';

const withAddFlora = (Component: typeof GreenPlantsForm) => () => {
  const type = 'flora';
  const defaultIndex = Object.values(GreenPlantsFormTypes).indexOf(type);
  const { addFlora, isLoading } = useFloraAddItem();
  const { dictionaries, result, isLoading: isDictsLoading } = useFloraDictionaries();
  const objectType = useObjectType(result);
  const [media, setMedia] = useState<IUploadMedia[]>([]);
  const { errorNotify } = useNotifications();
  const history = useHistory();
  const { backWithFallback } = useGoBackWithFallback();

  useUpdateBreadcrumbs([
    { breadcrumb: appLocale.common.data, path: '/data' },
    {
      breadcrumb: appLocale.subsystems.greenplants,
      path: GreenPlantsRootPath,
    },
    {
      breadcrumb: 'Деревья и кустарники',
      path: GreenPlantsFloraUrl,
    },
    {
      breadcrumb: 'Добавление обследования',
      path: createGreenPlantsAddUrl('flora'),
    },
  ]);

  const onError = useCallback(
    (message?: string | null) => {
      errorNotify({
        key: message || 'file/create/error',
        message: message || ERROR_MESSAGES.CREATE,
      });
    },
    [errorNotify]
  );

  const onSubmit = useCallback(
    async (values: Partial<TGreenPlantsForm>) => {
      const response = await addFlora(values.flora);

      if ('registryId' in response && dictionaries?.objectType) {
        await updateFiles(response.registryId, objectType, media)
          .then(() => {
            backWithFallback(GreenPlantsFloraUrl);
          })
          .catch((error: Error) => {
            onError(error.message);
            history.push(`${GreenPlantsFloraUrl}/edit/${response.registryId}?openAttachmentsTab=true`, response);
          });
      }
    },
    [addFlora, backWithFallback, dictionaries?.objectType, history, media, objectType, onError]
  );

  return (
    <Component
      header="Добавление результатов"
      defaultIndex={defaultIndex}
      initialValues={greenPlantsFormInitialValues}
      type={type}
      isSubmitting={isLoading || isDictsLoading}
      onSubmit={onSubmit}
      floraDictionaries={dictionaries}
      media={media}
      setMedia={setMedia}
    />
  );
};

export { withAddFlora };
