import appLocale from 'constants/appLocale';
import { RenderTree } from 'features/WithSideBar/types';
import subsystemIdsMap from 'constants/subsystemIdsMap';
import { GeologicalProcesses } from '../../home/icons';

export const GeoecologicalRootPath = '/data/geoecological-processes';

export const geoecologicalProcessesMenu: RenderTree = {
  id: GeoecologicalRootPath,
  name: appLocale.subsystems.geoecologicalprocesses,
  url: GeoecologicalRootPath,
  subsystemId: subsystemIdsMap.geoecologyRoot,
  homepageIcon: <GeologicalProcesses />,
  children: [
    {
      id: `${GeoecologicalRootPath}/monitoring/danger`,
      url: `${GeoecologicalRootPath}/monitoring/danger/dangerprocess`,
      name: 'Опасные геологические процессы',
      subsystemId: subsystemIdsMap.dangerprocesses,
    },
    {
      id: `${GeoecologicalRootPath}/monitoring/water`,
      url: `${GeoecologicalRootPath}/monitoring/water/examination`,
      name: appLocale.subsystems.groundwater,
      subsystemId: subsystemIdsMap.geoGroundWater,
    },
    {
      id: `${GeoecologicalRootPath}/monitoring/protocol/danger`,
      url: `${GeoecologicalRootPath}/monitoring/protocol/danger/dangerprocess`,
      name: `${appLocale.common.dataProtocol} (ОГП)`,
      subsystemId: subsystemIdsMap.dangerprocesses,
    },
    {
      id: `${GeoecologicalRootPath}/monitoring/protocol/water`,
      url: `${GeoecologicalRootPath}/monitoring/protocol/water/examination`,
      name: `${appLocale.common.dataProtocol} (${appLocale.subsystems.groundwater})`,
      subsystemId: subsystemIdsMap.geoGroundWater,
    },
  ],
};
