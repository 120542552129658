import React from 'react';
import { Flora, Lawns } from 'pages/data/PGR/GreenPlants/GreenPlants';
import { GreenPlantsFormTypes } from 'pages/data/PGR/GreenPlants/GreenPlants/Form/types';
import { Route } from 'react-router-dom';
import { AddLawns } from 'pages/data/PGR/GreenPlants/GreenPlants/Lawns/AddLawns/AddLawns';
import { AddFlora } from 'pages/data/PGR/GreenPlants/GreenPlants/Flora/AddFlora/AddFlora';
import { EditFlora } from 'pages/data/PGR/GreenPlants/GreenPlants/Flora/EditFlora/EditFlora';
import { EditLawns } from 'pages/data/PGR/GreenPlants/GreenPlants/Lawns/EditLawns/EditLawns';
import {
  DeicingGreenPlantsRootPath,
  DeicingStatements,
  GreenPlantsFloraUrl,
  GreenPlantsLawnsUrl,
} from 'pages/data/PGR';
import { FloraAreas } from 'pages/data/PGR/GreenPlants/GreenPlants/Areas/FloraAreas/FloraAreas';
import { LawnAreas } from 'pages/data/PGR/GreenPlants/GreenPlants/Areas/LawnAreas/LawnAreas';
import { AddArea } from 'pages/data/PGR/GreenPlants/GreenPlants/Areas/AddArea/AddArea';
import { EditArea } from 'pages/data/PGR/GreenPlants/GreenPlants/Areas/EditArea/EditArea';
import { DeicingGreenPlantsList } from 'pages/data/PGR/GreenPlants/DeicingGreenPlantsList';
import { AreasGreenPlantsList } from '../data/PGR/GreenPlants/AreasGreenPlantsList';
import { WithDidMountResetUploadFile } from 'features/UploadedFileProvider/hocs';

const createGreenPlantsEditUrl = (type: keyof typeof GreenPlantsFormTypes, id: string) =>
  `${DeicingGreenPlantsRootPath}/${type}/edit/${id}`;

const createGreenPlantsAddUrl = (type: keyof typeof GreenPlantsFormTypes) =>
  `${DeicingGreenPlantsRootPath}/${type}/add`;

const createAreasGreenPlantsEditUrl = (type: keyof typeof GreenPlantsFormTypes, id: string) =>
  `${DeicingGreenPlantsRootPath}/areas/${type}/edit/${id}`;

const GreenPlantsRoutes: React.FC = () => (
  <>
    <Route exact path={GreenPlantsFloraUrl} render={() => <WithDidMountResetUploadFile Component={Flora} />} />
    <Route exact path={GreenPlantsLawnsUrl} render={() => <WithDidMountResetUploadFile Component={Lawns} />} />
    <Route
      exact
      path={`${DeicingStatements}/lawns/add`}
      render={() => <WithDidMountResetUploadFile Component={AddLawns} />}
    />
    <Route
      exact
      path={`${DeicingStatements}/flora/add`}
      render={() => <WithDidMountResetUploadFile Component={AddFlora} />}
    />
    <Route exact path={DeicingStatements} component={DeicingGreenPlantsList} />
    <Route
      exact
      path={`${DeicingStatements}/flora/edit/:registryId`}
      render={() => <WithDidMountResetUploadFile Component={EditFlora} />}
    />
    <Route
      exact
      path={`${DeicingStatements}/lawns/edit/:id`}
      render={() => <WithDidMountResetUploadFile Component={EditLawns} />}
    />
    <Route exact path={`${DeicingGreenPlantsRootPath}/areas/:type/edit/:id`} component={EditArea} />
    <Route
      exact
      path={`${DeicingGreenPlantsRootPath}/areas`}
      render={() => <WithDidMountResetUploadFile Component={AreasGreenPlantsList} />}
    />
    <Route
      exact
      path={`${DeicingGreenPlantsRootPath}/areas/flora`}
      render={() => <WithDidMountResetUploadFile Component={FloraAreas} />}
    />
    <Route
      exact
      path={`${DeicingGreenPlantsRootPath}/areas/lawns`}
      render={() => <WithDidMountResetUploadFile Component={LawnAreas} />}
    />
    <Route
      exact
      path={`${DeicingGreenPlantsRootPath}/areas/add`}
      render={() => <WithDidMountResetUploadFile Component={AddArea} />}
    />
  </>
);

export {
  DeicingGreenPlantsRootPath,
  GreenPlantsFloraUrl,
  GreenPlantsLawnsUrl,
  GreenPlantsRoutes,
  createGreenPlantsEditUrl,
  createGreenPlantsAddUrl,
  DeicingStatements,
  createAreasGreenPlantsEditUrl,
};
