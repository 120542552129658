import React, { useCallback } from 'react';
import * as yup from 'yup';
import { createTouchedErrors } from 'features/create-touched-errors';
import { FormikProvider, useFormik } from 'formik';
import { Station } from 'pages/equipment/Stations/types';
import { StationFormFields } from 'pages/equipment/Stations/components/StationForm/StationFormFields';
import { ERRORS } from 'constants/errors';
import { useHistory } from 'react-router-dom';
import { TDictionariesArray } from 'models';

export const validationSchema = yup.object().shape({
  stationNumber: yup.number().nullable().required(ERRORS.required),
  stationName: yup.string().nullable().required(ERRORS.required),
  stationStatus: yup.string().nullable().required(ERRORS.required),
  stationType: yup.string().nullable().required(ERRORS.required),
  stationOwner: yup.string().nullable().required(ERRORS.required),
});

interface StationFormProps {
  initialValues: Partial<Station>;
  onSubmit: (values: Partial<Station>) => void;
  dictionaries: { data: TDictionariesArray<string> } | null;
  isEditMode?: boolean
}

export const StationForm: React.FC<StationFormProps> = ({ initialValues, dictionaries, isEditMode, ...props }) => {
  const { push } = useHistory();

  const onCancel = useCallback(() => {
    push('/equipment/stations');
  }, [push]);

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    validationSchema,
    initialValues,
    onSubmit: props.onSubmit,
  });

  return (
    <FormikProvider
      value={{
        ...formik,
        errors: createTouchedErrors(formik.errors, formik.touched),
      }}
    >
      <StationFormFields onCancel={onCancel} dictionaries={dictionaries} isEditMode={isEditMode} />
    </FormikProvider>
  );
};
