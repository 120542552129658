import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { IUploadMedia } from 'components/common/FileUploadDragAndDrop/types';
import { dataURLtoFile } from 'lib/utils/dataURLtoFile';
import { useMapControls } from 'hooks/map/useMapControls';

export const defaultDataForMaps = {
  layerTemplateId: '1121430612',
  features: [],
};

interface IProps {
  browserId: string;
  onMediaChange?: Dispatch<SetStateAction<IUploadMedia[]>>;
  onPointsChange?: (event: any) => void;
}

const MAP_NAME = 'Карта-схема';
const MapFormField = ({ browserId, onMediaChange, onPointsChange }: IProps) => {
  const { setFieldValue, values } = useFormikContext<{ meta: string }>();
  const { sendCommand } = useMapControls({ browserId });
  const [sendCommandAvailibility, setSendCommandAvailibility] = useState(false);

  const handleEvent = useCallback(
    (event: any) => {
      if (onPointsChange) {
        onPointsChange(event);
        return;
      }
      const metaString = JSON.stringify({ ...event.detail, layerTemplateId: '1121430612' });
      setFieldValue('meta', metaString);
    },
    [onPointsChange, setFieldValue]
  );

  const filterOldMapSchemas = (media: IUploadMedia[]): IUploadMedia[] => {
    // filter unsaved map schemas
    const _media = [...media].filter((item) => item.name !== MAP_NAME);
    // prepare saved for deletion
    return _media.map((item) => ({
      ...item,
      onSaveMediaAction: item?.originName === MAP_NAME ? 'delete' : item.onSaveMediaAction,
    }));
  };

  const onMapScreenshotEvent = useCallback(
    (event: any) => {
      if (!onMediaChange || !event.detail.uri) return;

      const file = dataURLtoFile(event.detail.uri, 'Карта-схема');
      if (!file) return;

      const newImage: IUploadMedia = {
        file,
        onSaveMediaAction: 'upload',
        name: MAP_NAME,
      };

      onMediaChange((media) => (media ? [...filterOldMapSchemas(media), newImage] : [newImage]));
    },
    [onMediaChange]
  );

  useEffect(() => {
    if (sendCommandAvailibility) {
      sendCommand(values.meta);
      if (values.meta) {
        setSendCommandAvailibility(false);
      }
    }
  }, [values.meta, sendCommandAvailibility, sendCommand]);

  useEffect(() => {
    window.document.addEventListener('map-update-layer-data', handleEvent, false);
    window.document.addEventListener('socket-server-connected', () => setSendCommandAvailibility(true), false);
    window.document.addEventListener('map-export-image', onMapScreenshotEvent, false);
    return () => {
      window.document.removeEventListener('map-update-layer-data', handleEvent, false);
      window.document.removeEventListener('socket-server-connected', () => setSendCommandAvailibility(false), false);
      window.document.removeEventListener('map-export-image', onMapScreenshotEvent, false);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <iframe
        title="map"
        width="100%"
        height="100%"
        src={`/api/mapsclient/v10/widget/#/?ui=borderless&browserId=${browserId}`}
      />
    </>
  );
};

export default MapFormField;
