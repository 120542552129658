import { FormWrapper, Input, Select } from 'components/common';
import { Field, useFormikContext } from 'formik';
import React, { useMemo } from 'react';
import { ILocationFormTab } from 'components/common/LocationFormTab/types';
import { TDictionaryItemDictionary } from 'models';
import { Box, Grid } from '@chakra-ui/react';

interface IProps {
  okrugs?: TDictionaryItemDictionary[];
  rayons?: TDictionaryItemDictionary[];
  children: React.ReactNode;
  showFieldKeys?: Array<keyof ILocationFormTab>;
}
function LocationFormTab<T extends ILocationFormTab>({ children, okrugs, rayons, showFieldKeys }: IProps) {
  const { errors, values } = useFormikContext<T>();

  const filteredRayons = useMemo(() => {
    if (values.okrugId && rayons) {
      return rayons.filter((item) => item.parent === values.okrugId);
    }
    return rayons;
  }, [rayons, values.okrugId]);

  return (
    <Box flexDirection="column">
      <FormWrapper>
        <Grid marginTop="30px" gap="15px" marginBottom={20}>
          {(!showFieldKeys || showFieldKeys.includes('okrugId')) && (
            <Field as={Select} name="okrugId" label="Округ" options={okrugs} error={errors.okrugId} />
          )}
          {(!showFieldKeys || showFieldKeys.includes('rayonId')) && (
            <Field as={Select} name="rayonId" label="Район" options={filteredRayons} error={errors.rayonId} />
          )}
          {(!showFieldKeys || showFieldKeys.includes('address')) && (
            <Field as={Input} name="address" label="Адрес" error={errors.address} />
          )}
          {(!showFieldKeys || showFieldKeys.includes('landmark')) && (
            <Field as={Input} name="landmark" label="Адресный ориентир" error={errors.landmark} />
          )}
          {(!showFieldKeys || showFieldKeys.includes('latitude')) && (
            <Field as={Input} name="latitude" label="Широта" error={errors.latitude} />
          )}
          {(!showFieldKeys || showFieldKeys.includes('longitude')) && (
            <Field as={Input} name="longitude" label="Долгота" error={errors.longitude} />
          )}
          {children}
        </Grid>
      </FormWrapper>
    </Box>
  );
}

export default LocationFormTab;
