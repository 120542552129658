import { Column } from '@material-table/core';
import { fieldsLocale } from 'pages/equipment/Stations/locale';

export const stationsTableColumns: Column<any>[] = [
  {
    title: fieldsLocale.stationNumber,
    field: 'stationNumber',
  },
  {
    title: fieldsLocale.stationName,
    field: 'stationName',
  },
  {
    title: fieldsLocale.placement,
    field: 'placement',
  },
  {
    title: fieldsLocale.stationStatus,
    field: 'stationStatusName',
  },
];
