import React, { useCallback, useEffect, useMemo } from 'react';
import { NODDangerItem } from './types';
import appLocale from 'constants/appLocale';
import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import { addFiltersToColumns, ColumnWithFilter } from 'lib/utils/tableFilter';
import { TableFilterTypeEnum } from 'components/table/filters/TableFilter';
import { useList } from 'hooks';
import { useSavedFilters } from 'hooks/useSaveFilters';
import { TFilterDTO } from 'models';
import { Column } from '@material-table/core';
import { useToolbarActions } from '../utils/toolbarActions';
import { SubsystemHeader } from 'components/common';
import { MaterialTable } from 'features/material-table/MaterialTableMini';
import { NODEmptyFilter } from '../components/NODEmptyFilter';
import { useNodRights } from '../utils/useNodRights';
import { subsystemCodesMap } from 'constants/subsystemIdsMap';
import { useGoToNODUploadLog } from 'hooks/useGoToNODUploadLog';
import { DEFAULT_PAGE_SIZE_NEW_TABLE } from '../../../../constants/tables';
import { FullPageWrapper } from '../../../../components/table/FullPageWrapper';

const NODDangerBreadcrumbs = [
  { breadcrumb: appLocale.subsystems.analyticsReports, path: '/reports-analytics' },
  { breadcrumb: appLocale.analyticsReports.nod, path: '/reports-analytics/nod' },
  { breadcrumb: appLocale.analyticsReports.danger, path: '/reports-analytics/nod/danger' },
];

const NODDanger = () => {
  const { isExecute } = useNodRights(subsystemCodesMap.nodReportsOtherCodes.danger);
  const { filters, saveFilters, isFiltersLoading } = useSavedFilters<NODDangerItem>('nod', `nod${5}`);

  const {
    response,
    setFilters,
    filterDTO,
    isLoading: isItemsLoading,
    onPageChange,
    resetData,
    downloadAsFile,
    toggleOrder,
    errorText,
    selectedIndexes,
    renderOnlySelectedCheckbox,
    onSelectionChange,
    setSelectedIds,
    onSubmitFilters,
  } = useList<NODDangerItem, keyof NODDangerItem>('/ehd/v10/v1/catalog-fetcher/reports/5', {
    initialOrderFieldKey: 'pollutionCategory',
    selectionKey: 'id',
    disableUxMetadata: true,
    defaultPageSize: DEFAULT_PAGE_SIZE_NEW_TABLE,
    resetDataOnNextRequest: true,
    preventFirstFetch: true,
  });

  useUpdateBreadcrumbs(NODDangerBreadcrumbs);

  const columns = useMemo<Array<ColumnWithFilter<NODDangerItem>>>(
    () => [
      {
        title: 'Код',
        field: 'ehdCode',
        width: 50,
      },
      {
        title: 'Категория загрязнения почв',
        field: 'pollutionCategory',
        filterType: TableFilterTypeEnum.string,
      },
      {
        title: 'Уровень загрязнения почв',
        field: 'pollutionLevel',
        filterType: TableFilterTypeEnum.string,
      },
      {
        title: 'Величина показателя загрязнения',
        field: 'indexValue',
        filterType: TableFilterTypeEnum.string,
      },
    ],
    []
  );

  const columnsWithFilters = useMemo<Array<Column<NODDangerItem>>>(
    () =>
      addFiltersToColumns({
        filters: filterDTO.filters,
        setFilters,
        columns,
      }),
    [filterDTO.filters, setFilters, columns]
  );

  const selectedIds = useMemo(() => {
    const idValues = filters?.data.filters.find((item: any) => item.key === 'id')?.value;
    if (Array.isArray(idValues)) {
      return idValues as string[];
    }
    return [];
  }, [filters]);

  const onClear = () => {
    onSubmitFilters([]);
    resetData();
    const emptyFilters = {
      ...filterDTO,
      filters: [],
    };
    setFilters([]);
    saveFilters({
      data: emptyFilters,
    });
  };

  const getCurrentFilter = useCallback(
    () => filterDTO.filters.find((filter) => filter.key !== 'deleted'),
    [filterDTO.filters]
  );

  useEffect(() => {
    if (getCurrentFilter()) {
      saveFilters({
        data: filterDTO,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getCurrentFilter]);

  useEffect(() => {
    if (filters != null && filters.data) {
      setSelectedIds(selectedIds);
      setFilters(filters.data.filters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, setFilters, saveFilters]);

  const { toolBarActions } = useToolbarActions(5, filterDTO, response?.data.length === 0);

  const { goToButtonText, goToButtonProps } = useGoToNODUploadLog(
    NODDangerBreadcrumbs,
    5,
    appLocale.analyticsReports.danger,
    '/reports-analytics/nod/danger'
  );

  return (
    <FullPageWrapper>
      <SubsystemHeader
        title={appLocale.analyticsReports.danger}
        anyButtonProps={goToButtonProps}
        anyButtonText={goToButtonText}
        filtersComponent={
          <NODEmptyFilter onClear={onClear} isLoading={isItemsLoading || isFiltersLoading}>
            {renderOnlySelectedCheckbox}
          </NODEmptyFilter>
        }
        defaultFiltersOpen
      />
      <MaterialTable
        isLoading={isItemsLoading}
        filterDTO={filterDTO as TFilterDTO}
        columns={columnsWithFilters}
        data={response?.data}
        meta={response?.meta}
        downloadAsFile={downloadAsFile}
        toolbarActions={toolBarActions}
        onPageChange={onPageChange}
        toggleOrder={toggleOrder}
        selectedIndexes={selectedIndexes}
        onSelectionChange={onSelectionChange}
        options={{
          selection: true,
          toolbar: isExecute,
        }}
        errorText={errorText}
        printLoggerUrl="/ehd/v10/v1/catalog-fetcher/reports/5/print"
      />
    </FullPageWrapper>
  );
};

export default NODDanger;
