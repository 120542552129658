import { useEffect, useMemo, useRef, useState } from 'react';
import { Field, Form, FormikProvider, useFormik } from 'formik';
import { Button, Center, Flex, Grid, Heading, Icon, Link, Text, useTheme } from '@chakra-ui/react';

import { Input } from 'components/common';
import { EGFDEMLogo, SystemLogo } from 'components/icons';
import { COMMON_LOCALE } from 'constants/common';
import { ILogin } from '../types';
import { initialValues } from '../config';
import { useLogin } from 'api/services/auth';
import { LightModeWrapper } from 'components/common/LightModeWrapper/LightModeWrapper';
import { authAppPaths } from '../appPaths';
import { useHistory } from 'react-router';

export const LoginPage = () => {
  const theme = useTheme();
  const color = theme.colors.gray[200];
  const colorHover = theme.colors.gray[800];

  const { login, isLoading } = useLogin();
  const { push } = useHistory();

  const useFormikProps = useMemo(
    () => ({
      enableReinitialize: true,
      validateOnChange: true,
      validateOnMount: true,
      validateOnBlur: true,
      initialValues,
      onSubmit: (values: ILogin) => {
        login(values);
      },
    }),
    [login],
  );

  const { submitForm, values, setFieldValue, ...formik } = useFormik(useFormikProps);

  const [isLoginFilled, setIsLoginFilled] = useState(false);
  const [isPasswordFilled, setIsPasswordFilled] = useState(false);

  const intervalId = useRef<ReturnType<typeof setInterval>>();

  useEffect(() => {
    intervalId.current = setInterval(() => {
      const login = document.getElementById('login');
      const password = document.getElementById('password');
      const isAutofilled = !!document.querySelectorAll('input:-webkit-autofill').length;
      if (login && password && isAutofilled) {
        setIsLoginFilled(login.matches(':-webkit-autofill'));
        setIsPasswordFilled(password.matches(':-webkit-autofill'));
        clearInterval(intervalId.current);
      }
    }, 300);
    return () => {
      clearInterval(intervalId.current);
    };
  }, []);

  const clickListener = () => {
    setIsPasswordFilled(false);
    setIsLoginFilled(false);
    clearInterval(intervalId.current);
  };

  useEffect(() => {
    window.addEventListener('click', clickListener);
    return () => window.removeEventListener('click', clickListener);
  }, []);

  return (
    <Flex
      minHeight="100vh"
      display="flex"
      alignItems="center"
      flexDir="column"
      bgImage="url('./login-bg-img.png')"
      bgPosition="center"
      bgRepeat="no-repeat"
      bgSize="cover"
    >
      <Flex direction="column" minHeight="100vh" maxW="500px" justifyContent="space-between">
        <Flex flexGrow={1} alignItems="center" justifyContent="center">
          <Flex maxH="420px" direction="column" minWidth="375px" bgColor="white" p={10}>
            <Flex mb={8} alignItems="center" justifyContent="center">
              <Icon color="black" h="48px" w="48px" as={EGFDEMLogo} mr={5} />
              <Heading my={2} size="lg" color="black">
                ЕГФДЭМ
              </Heading>
            </Flex>
            <FormikProvider
              value={{
                submitForm,
                values,
                setFieldValue,
                ...formik,
              }}
            >
              <Form className="LoginForm">
                <Grid borderColor="black" gap={2} color="black">
                  <Field
                    variant="outline"
                    _hover={{ borderColor: colorHover }}
                    borderColor={color}
                    as={Input}
                    label={COMMON_LOCALE.login}
                    name="login"
                    id="login"
                    isFilled={isLoginFilled}
                  />
                  <Field
                    variant="outline"
                    _hover={{ borderColor: colorHover }}
                    borderColor={color}
                    as={Input}
                    label={COMMON_LOCALE.password}
                    name="password"
                    type="password"
                    id="password"
                    isFilled={isPasswordFilled}
                  />
                  <LightModeWrapper>
                    <Button
                      mt={2}
                      variant="solid"
                      colorScheme="PrimaryButton"
                      onClick={submitForm}
                      isLoading={isLoading}
                      isFullWidth
                      type="submit"
                    >
                      {COMMON_LOCALE.loginAction}
                    </Button>
                    <Center>
                      <Button
                        backgroundColor="transparent"
                        as={Link}
                        mt={2}
                        onClick={() => push(authAppPaths.resetPassword)}
                      >
                        {COMMON_LOCALE.resetPassword}
                      </Button>
                    </Center>
                  </LightModeWrapper>
                </Grid>
              </Form>
            </FormikProvider>
          </Flex>
        </Flex>
        <Flex mb={4} alignItems="center">
          <Icon as={SystemLogo} mr={2} />
          <Text size="xs" color="white" textShadow="1px 1px 2px black">
            Департамент информационных технологий города Москвы
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};
