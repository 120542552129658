import React, { useState } from 'react';
import { createTouchedErrors } from 'features/create-touched-errors';
import { FormikProvider, useFormik } from 'formik';
import { useRequest } from 'hooks/useRequest';
import { useHistory } from 'react-router-dom';
import FieldsForm from 'pages/analytics/forms/VegetativeState/AnalyticsDeicingVegFieldsForm';
import { IUploadMedia } from 'components/common/FileUploadDragAndDrop/types';
import { VegetationDeicingItem } from 'pages/data/PGR/GreenPlants/VegetativeState/useVegDicingList';
import { updateFiles } from 'api/services/media';
import { useObjectType } from 'hooks/useObjectType';
import { initialEmptyValues } from './initialEmptyValues';
import { ANALYTICS_LAB_PATHS } from 'pages/analytics/consts/AnalyticsTableRoutes';
import { AnalyticsVegStateSchema } from 'models/schemas/analytics/analyticsVegSchema';
import { useEnhancedNotification } from 'hooks';
import { useGoBackWithFallback } from 'lib/utils/goBackWithFallback';
import { useDictionary } from 'hooks/useDictionary';

export const AnalyticsAddDeicingVegReport = () => {
  const { isDictionariesLoading, dictionaries } = useDictionary({ url: '/deicing/v10/deicing/vegetative/dictionaries' });
  const history = useHistory();
  const { backWithFallback } = useGoBackWithFallback();
  const { onSuccessCreate, onErrorCreate } = useEnhancedNotification();
  const [media, setMedia] = useState<IUploadMedia[]>([]);
  const objectType = useObjectType(dictionaries);

  const onSuccess = (response: VegetationDeicingItem | null) => {
    if (response) {
      onSuccessCreate();

      updateFiles(response.registryId, objectType, media)
        .then(() => {
          backWithFallback('/analytics');
        })
        .catch((error: Error) => {
          onErrorCreate(error.message);
          history.replace(
            `${ANALYTICS_LAB_PATHS.reagents.vegetation.edit}/${response.registryId}?openAttachmentsTab=true`,
            response
          );
        });
    } else {
      onErrorCreate();
    }
  };

  const { post } = useRequest<VegetationDeicingItem, any>('/deicing/v10/deicing/vegetative', onSuccess, onErrorCreate);

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    initialValues: initialEmptyValues,
    onSubmit: (values) => post(values),
    validationSchema: AnalyticsVegStateSchema,
  });

  return (
    <FormikProvider
      value={{
        ...formik,
        errors: createTouchedErrors(formik.errors, formik.touched),
      }}
    >
      <FieldsForm
        dictionaries={dictionaries}
        onCancel={() => {
          backWithFallback('/analytics');
        }}
        isLoading={isDictionariesLoading}
        onMediaChange={setMedia}
        media={media}
      />
    </FormikProvider>
  );
};
