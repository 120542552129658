import React, { useMemo } from 'react';
import { askzvLocales } from './locales';
import { DateTimeTableCell } from '../components/DateTimeTableCell';
import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import { WaterRouteAskzvMeasurments, WaterRouteRoot } from '../routes';
import { ColumnWithFilter } from 'lib/utils/tableFilter';
import { IASKZVPackage } from 'pages/data/Water/ASKZV/types';
import { TableFilterTypeEnum } from 'components/table/filters/TableFilter';

export const useMeasurementsTableColumns = () => {
  const columns = useMemo<Array<ColumnWithFilter<IASKZVPackage>>>(
    () => [
      {
        title: askzvLocales.packageNumber,
        field: 'packageNumber',
        filterType: TableFilterTypeEnum.number,
      },
      {
        title: askzvLocales.measuringDatetime,
        field: 'measuringDatetime',
        render: (row) => <DateTimeTableCell datestring={row.measuringDatetime} inline />,
      },
      { title: askzvLocales.stationNumber, field: 'stationNumber', filterType: TableFilterTypeEnum.number },
      { title: askzvLocales.stationName, field: 'stationName', filterType: TableFilterTypeEnum.string },
      {
        title: askzvLocales.latitude,
        field: 'latitude',
        filterType: TableFilterTypeEnum.number,
      },
      {
        title: askzvLocales.longitude,
        field: 'longitude',
        filterType: TableFilterTypeEnum.number,
      },
      {
        title: askzvLocales.parameterName,
        field: 'parameterName',
        filterType: TableFilterTypeEnum.string,
      },
      {
        title: askzvLocales.value,
        field: 'value',
        filterType: TableFilterTypeEnum.number,
      },
    ],
    []
  );
  return columns;
};

export const usePackagesTableColumns = () => {
  const columns = useMemo<Array<ColumnWithFilter<IASKZVPackage>>>(
    () => [
      {
        title: askzvLocales.packageNumber,
        field: 'packageNumber',
        filterType: TableFilterTypeEnum.number,
      },
      {
        title: askzvLocales.measuringDatetime,
        field: 'measuringDatetime',
        render: (row) => <DateTimeTableCell datestring={row.measuringDatetime} inline />,
      },
      {
        title: askzvLocales.stationNumber,
        field: 'stationNumber',
        filterType: TableFilterTypeEnum.number,
      },
      {
        title: askzvLocales.probeNumber,
        field: 'probeNumber',
        filterType: TableFilterTypeEnum.string,
      },
      {
        title: askzvLocales.ammoniumIonicConcentration,
        field: 'ammoniumIonicConcentration',
        filterType: TableFilterTypeEnum.number,
      },
      {
        title: askzvLocales.nitriteIonsConcentration,
        field: 'nitriteIonsConcentration',
        filterType: TableFilterTypeEnum.number,
      },
      {
        title: askzvLocales.chemicalOxygenDemand,
        field: 'chemicalOxygenDemand',
        filterType: TableFilterTypeEnum.number,
      },
      {
        title: askzvLocales.waterLevel,
        field: 'waterLevel',
        filterType: TableFilterTypeEnum.number,
      },
      {
        title: askzvLocales.waterTemperature,
        field: 'waterTemperature',
        filterType: TableFilterTypeEnum.number,
      },
      {
        title: askzvLocales.phIndex,
        field: 'phIndex',
        filterType: TableFilterTypeEnum.number,
      },
      {
        title: askzvLocales.dissolvedOxygen,
        field: 'dissolvedOxygen',
        filterType: TableFilterTypeEnum.number,
      },
      {
        title: askzvLocales.electricalConductivity,
        field: 'electricalConductivity',
        filterType: TableFilterTypeEnum.number,
      },
      {
        title: askzvLocales.phosphateIonsConcentration,
        field: 'phosphateIonsConcentration',
        filterType: TableFilterTypeEnum.number,
      },
      {
        title: askzvLocales.pressure1,
        field: 'pressure1',
        filterType: TableFilterTypeEnum.number,
      },
      {
        title: askzvLocales.pressure2,
        field: 'pressure2',
        filterType: TableFilterTypeEnum.number,
      },
      {
        title: askzvLocales.flow,
        field: 'flow',
        filterType: TableFilterTypeEnum.number,
      },
      {
        title: askzvLocales.doorId,
        field: 'doorId',
        filterType: TableFilterTypeEnum.string,
      },
      {
        title: askzvLocales.eventId,
        field: 'eventId',
        filterType: TableFilterTypeEnum.string,
      },
      {
        title: askzvLocales.fireId,
        field: 'fireId',
        filterType: TableFilterTypeEnum.string,
      },
      {
        title: askzvLocales.ironConcentration,
        field: 'ironConcentration',
        filterType: TableFilterTypeEnum.number,
      },
      {
        title: askzvLocales.manganeseConcentration,
        field: 'manganeseConcentration',
        filterType: TableFilterTypeEnum.number,
      },
    ],
    []
  );
  return columns;
};

export const useBreadcrumbsWaterASKZV = () => {
  useUpdateBreadcrumbs([
    { breadcrumb: 'Данные', path: '/data' },
    {
      breadcrumb: 'Водные объекты',
      path: WaterRouteRoot,
    },
    {
      breadcrumb: 'АСКЗВ',
      path: WaterRouteAskzvMeasurments,
    },
  ]);
};
