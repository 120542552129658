import appLocale from 'constants/appLocale';
import { IncidentRecordingItem } from 'pages/IncidentRecording/types';
import { ColumnWithFilter } from 'lib/utils/tableFilter';
import { createLocalDateDDMMYYYYWithTime } from 'lib/create-date';
import { TableFilterTypeEnum } from 'components/table/filters/TableFilter';

export const IncidentRecordingRootPath = '/incident-recording';
export const IncidentRecordingApiRoot = '/appeal/v10/incident-recording';

export const IncidentRecordingPaths = {
  root: IncidentRecordingRootPath,
  add: `${IncidentRecordingRootPath}/add`,
  edit: (id: string) => `${IncidentRecordingRootPath}/edit/${id}`
};

export const incidentRecordingBreadcrumbs = [
  {
    breadcrumb: appLocale.subsystems.incidentRecording,
    path: IncidentRecordingRootPath,
  },
];

export const incidentRecordingColumns: ColumnWithFilter<IncidentRecordingItem>[] = [
  {
    title: appLocale.incidentRecording.incidentDateTime,
    field: 'incidentDateTime',
    render: (row) => createLocalDateDDMMYYYYWithTime(row.incidentDateTime),
  },
  {
    title: appLocale.incidentRecording.address,
    field: 'address',
    filterType: TableFilterTypeEnum.string,
  },
  {
    title: appLocale.incidentRecording.admAreaId,
    field: 'admAreaName',
    filterType: TableFilterTypeEnum.string,
  },
  {
    title: appLocale.incidentRecording.districtId,
    field: 'districtName',
    filterType: TableFilterTypeEnum.string,
  },
  {
    title: appLocale.incidentRecording.incidentTypeId,
    field: 'incidentTypeName',
    filterType: TableFilterTypeEnum.string,
  },
  {
    title: appLocale.incidentRecording.sourceTypeId,
    field: 'sourceTypeName',
    filterType: TableFilterTypeEnum.string,
  },
];
