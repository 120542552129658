import React, { useMemo } from 'react';
import { ColumnWithFilter } from 'lib/utils/tableFilter';
import { IASKZVPackage } from 'pages/data/Water/ASKZV/types';
import { TableFilterTypeEnum } from 'components/table/filters/TableFilter';
import VersionCell from 'components/table/VersionCell';
import { DateTimeTableCell } from 'pages/data/Water/components/DateTimeTableCell';
import { askzvLocales } from 'pages/data/Water/ASKZV/locales';

interface IUseMeasurementsTableColumns {
  openModal: (row: IASKZVPackage) => void;
}

export const useMeasurementsTableColumns = ({ openModal }: IUseMeasurementsTableColumns) => {
  const columns = useMemo<Array<ColumnWithFilter<IASKZVPackage>>>(
    () => [
      {
        title: 'Версия',
        field: 'version',
        render: (row) => (
          <>
            <VersionCell row={{ ...row, valueVersion: row.version, valueId: row.id }} onClick={openModal} />
          </>
        ),
        width: 50,
        filterType: TableFilterTypeEnum.number,
      },
      {
        title: askzvLocales.measuringDatetime,
        field: 'measuringDatetime',
        render: (row) => <DateTimeTableCell datestring={row.measuringDatetime} />,
      },
      { title: askzvLocales.stationNumber, field: 'stationNumber', filterType: TableFilterTypeEnum.number },
      { title: askzvLocales.stationName, field: 'stationName', filterType: TableFilterTypeEnum.string },
      {
        title: askzvLocales.parameterName,
        field: 'parameterName',
        filterType: TableFilterTypeEnum.string,
      },
      {
        title: askzvLocales.value,
        field: 'value',
        filterType: TableFilterTypeEnum.number,
      },
    ],
    [openModal]
  );
  return columns;
};
