import { DateInput, Input, Select } from 'components/common';
import { Flex, Grid, GridItem } from '@chakra-ui/react';
import { Field, useFormikContext } from 'formik';
import React, { useMemo } from 'react';
import { createLocalDateISO8601, createUTCTime } from 'lib/create-date';
import { getSelectRichOptionsFromDictionaryByName } from 'features/get-select-options-from-dictionary';
import { TDictionariesArray } from 'models';
import FilterFormControls from 'components/form/FilterFormControls';

interface IProps {
  dictionaries: { data: TDictionariesArray } | null;
}

export const AttachedAppealsFilter = ({ dictionaries }: IProps) => {
  const { handleSubmit, handleReset, setFieldValue, values } = useFormikContext<any>();

  const odorTypes = useMemo(
    () => getSelectRichOptionsFromDictionaryByName(dictionaries?.data, 'odorTypes'),
    [dictionaries],
  );
  const incidentTypes = useMemo(
    () => getSelectRichOptionsFromDictionaryByName(dictionaries?.data, 'incidentTypes'),
    [dictionaries],
  );
  const noiseSourceTypeId = useMemo(
    () => getSelectRichOptionsFromDictionaryByName(dictionaries?.data, 'noiseSourceTypeId'),
    [dictionaries],
  );
  const odorSourceTypeId = useMemo(
    () => getSelectRichOptionsFromDictionaryByName(dictionaries?.data, 'odorSourceTypeId'),
    [dictionaries],
  );
  const airId: string | undefined = useMemo(
    () => incidentTypes.find((type) => type.name === 'Воздух')?.value,
    [incidentTypes],
  );
  return (
    <>
      <Flex direction="row" justifyContent="space-between">
        <Flex direction="column" flexGrow={1}>
          <Grid gap={3} templateColumns="repeat(3, 1fr)">
            <GridItem>
              <Field
                as={Input}
                name="number.value"
                label="Номер обращения"
              />
            </GridItem>
            <GridItem>
              <Field
                as={Input}
                name="assignmentNumber.value"
                label="Номер поручения"
              />
            </GridItem>
            <GridItem>
              <Field
                as={Input}
                name="citFio.value"
                label="ФИО Заявителя"
              />
            </GridItem>
          </Grid>
          <Grid gap={3} templateColumns="repeat(2, 0.5fr)" marginTop={5}>
            <GridItem>
              <Field
                as={Input}
                name="citAddress.value"
                label="Адрес заявителя"
              />
            </GridItem>
            <GridItem>
              <Field
                as={Input}
                name="incidentAddress.value"
                label="Адрес происшествия"
              />
            </GridItem>
          </Grid>
          <Grid gap={3} templateColumns="repeat(4, 0.25fr)" marginTop={5}>
            <GridItem>
              <Field
                as={DateInput}
                name="complaintMinDate.value"
                label="Дата/время обращения от"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setFieldValue('complaintMinDate.value', createUTCTime(e.target.value))
                }
                showTimeInput
              />
            </GridItem>
            <GridItem>
              <Field
                as={DateInput}
                name="complaintMaxDate.value"
                label="Дата/время обращения до"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setFieldValue('complaintMaxDate.value', createUTCTime(e.target.value))
                }
                showTimeInput
              />
            </GridItem>
            <GridItem>
              <Field
                as={DateInput}
                name="executionDateMin.value"
                label="Срок исполнения от"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setFieldValue('executionDateMin.value', createLocalDateISO8601(e.target.value))
                }
              />
            </GridItem>
            <GridItem>
              <Field
                as={DateInput}
                name="executionDateMax.value"
                label="Срок исполнения до"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setFieldValue('executionDateMax.value', createLocalDateISO8601(e.target.value))
                }
              />
            </GridItem>
          </Grid>
          <Grid templateColumns="repeat(3, 1fr)" marginTop={5} gap={5}>
            <GridItem>
              <Field
                as={Select}
                name="complaintThemeId.value"
                label="Тема обращения"
                options={incidentTypes}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setFieldValue('odorSourceTypeId.value', '');
                  setFieldValue('odorTypeId.value', '');
                  setFieldValue('noiseSourceTypeId.value', '');
                  setFieldValue('complaintThemeId.value', e.target.value);
                }}
              />
            </GridItem>
            {values.complaintThemeId.value ? (
              <GridItem>
                <Field
                  as={Select}
                  name={values.complaintThemeId.value === airId ? 'odorSourceTypeId.value' : 'noiseSourceTypeId.value'}
                  label="Тип источника"
                  options={values.complaintThemeId.value === airId ? odorSourceTypeId : noiseSourceTypeId}
                />
              </GridItem>
            ) : null}
            {values.complaintThemeId.value === airId ? (
              <GridItem>
                <Field as={Select} name="odorTypeId.value" label="Тип запаха" options={odorTypes} />
              </GridItem>
            ) : null}
          </Grid>
        </Flex>
        <Flex marginLeft={5} flexDirection="row" justifyContent="space-between" width="fit-content">
          <FilterFormControls
            onReset={handleReset}
            onSubmit={() => handleSubmit()}
          />
        </Flex>
      </Flex>
    </>
  );
};
