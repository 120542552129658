import { Select, SelectOption } from 'components/common';
import { TFilterOperations } from 'models';
import { useMemo } from 'react';
import appLocale from 'constants/appLocale';

interface Props {
  options: TFilterOperations[];
  onChange: (value: TFilterOperations) => void;
  selectedOperand?: TFilterOperations;
}
const OperandSelector = ({ options, selectedOperand, onChange }: Props) => {
  const _onChange = (event: any) => {
    onChange(event.target.value);
  };

  const _options = useMemo<SelectOption[]>(() => options.map((option) => ({
    name: appLocale.common.operations.long[option],
    value: option
  })), [options]);

  return <Select label="Операнд" options={_options} value={selectedOperand} onChange={_onChange} />;
};

export default OperandSelector;
