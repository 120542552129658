import React from 'react';
import { PageHeader, RoutesList } from 'components/common';
import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import appLocale from 'constants/appLocale';
import {
  GreenPlantsPlatformUrl,
  GreenPlantsRootPath,
} from 'pages/routes/GreenPlants';
import { Flex, Heading } from '@chakra-ui/react';
import { useRoutesList } from 'hooks/useRoutesList';
import { greenplantsPlatformsMenu } from 'features/WithSideBar/menus/greenplantsMenu';

export const PlatformList = () => {
  useUpdateBreadcrumbs([
    { breadcrumb: appLocale.common.data, path: '/data' },
    {
      breadcrumb: appLocale.subsystems.greenplants,
      path: GreenPlantsRootPath,
    },
    {
      breadcrumb: appLocale.subsystems.platforms,
      path: GreenPlantsPlatformUrl,
    },
  ]);
  const routes = useRoutesList(greenplantsPlatformsMenu);
  return (
    <Flex flexDirection="column">
      <PageHeader>
        <Heading>{appLocale.subsystems.platforms}</Heading>
      </PageHeader>
      <RoutesList routes={routes} />
    </Flex>
  );
};
