import { Checkbox, Flex, Grid, GridItem } from '@chakra-ui/react';
import { Button, Input, LabelText } from 'components/common';
import { DateInput } from 'components/common/DateInput';
import { NumberInput } from 'components/common/Input/number-input';
import { Select } from 'components/common/Select';
import { Field, FormikErrors, FormikTouched } from 'formik';
import { createDateFromResponse, createDateToRequestForFilter, createLocalDateISO8601 } from 'lib/create-date';
import React, { useState } from 'react';
import moment from 'moment';
import { TOption } from '../../models/toption';
import { UserFormObj } from '../UserFormObj';
import { userStatuses } from '../UserStatuses';
import { userTypes } from '../UserTypes';
import { DownloadIcon } from 'components/icons';

interface Props {
  departments: TOption[];
  organizations: TOption[];
  values: UserFormObj;
  errors: FormikErrors<UserFormObj>;
  touched: FormikTouched<UserFormObj>;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
}

export const UserCommonForm: React.FC<Props> = ({
  departments,
  organizations,
  values,
  errors,
  touched,
  setFieldValue,
}) => {
  const isEditMode = location.pathname.includes('/edit');
  const currentDays = moment(values.user?.dateAccountDeactivation).diff(
    moment(values.user?.dateAccountCreation),
    'days',
  );

  const [daysCount, setDaysCount] = useState(String(currentDays));
  const [isMiddleName, setIsMiddleName] = useState(false);

  return (
    <Flex direction="column">
      <Grid marginTop={5} marginBottom={5} gap={2}>
        <Grid templateColumns="repeat(5, 1fr)" gap={2}>
          <GridItem>
            <Field
              as={Select}
              name="user.type"
              label="Тип учетной записи"
              options={userTypes}
              error={touched?.user?.type && errors?.user?.type}
            />
          </GridItem>
          <GridItem>
            <Field
              as={Input}
              name="user.surName"
              label="Фамилия"
              error={touched?.user?.surName && errors?.user?.surName}
            />
          </GridItem>
          <GridItem>
            <Field as={Input} name="user.name" label="Имя" error={touched?.user?.name && errors?.user?.name} />
          </GridItem>
          <GridItem>
            <Field as={Input} disabled={isMiddleName} name="user.middleName" label="Отчество" />
          </GridItem>
          <GridItem>
            <Checkbox
              margin="8px"
              isChecked={isMiddleName}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setIsMiddleName(e.target.checked);
                if (e.target.checked) {
                  setFieldValue('user.middleName', '');
                }
              }}
            >
              Нет отчества
            </Checkbox>
          </GridItem>
        </Grid>
        <Grid templateColumns="repeat(5, 1fr)" gap={2}>
          <GridItem>
            <Field
              as={Select}
              name="user.departmentId"
              label="Отдел"
              value={values.user?.departmentId}
              options={departments}
            />
          </GridItem>
          <GridItem>
            <Field
              as={Select}
              name="user.organizationId"
              label="Организация"
              value={values.user?.organizationId}
              options={organizations}
            />
          </GridItem>
          <GridItem>
            <Field as={Input} name="user.code" label="Логин" error={touched?.user?.code && errors?.user?.code} />
          </GridItem>
          <GridItem>
            <Field as={Input} name="user.email" label="Email" error={touched?.user?.email && errors?.user?.email} />
          </GridItem>
          <GridItem>
            <Field as={Input} name="user.phone" label="Телефон" error={touched?.user?.phone && errors?.user?.phone} />
          </GridItem>
        </Grid>
        <Grid templateColumns="repeat(5, 1fr)" gap={2}>
          <GridItem>
            <DateInput
              name="dateAccountCreation"
              label="Дата активации"
              value={createDateFromResponse(values.user?.dateAccountCreation)}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setFieldValue('user.dateAccountCreation', createLocalDateISO8601(e.target.value));
                const startDate = createDateToRequestForFilter(e.target.value, 'start');
                const endDate = createDateToRequestForFilter(values.user?.dateAccountDeactivation, 'end');
                const days = moment(endDate).diff(moment(startDate), 'days');
                setDaysCount(String(days));
              }}
              showTimeInput={false}
            />
          </GridItem>
          <GridItem>
            <DateInput
              name="dateAccountDeactivation"
              label="Дата деактивации"
              value={createDateFromResponse(values.user?.dateAccountDeactivation)}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setFieldValue('user.dateAccountDeactivation', createLocalDateISO8601(e.target.value));
                const startDate = createDateToRequestForFilter(values.user?.dateAccountCreation, 'start');
                const endDate = createDateToRequestForFilter(e.target.value, 'end');
                const days = moment(endDate).diff(moment(startDate), 'days');
                setDaysCount(String(days));
              }}
              showTimeInput={false}
            />
          </GridItem>
          <GridItem>
            <Field
              label="В днях"
              as={NumberInput}
              value={!isNaN(Number(daysCount)) ? daysCount : ''}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                const newStartDate = moment(values.user?.dateAccountCreation)
                  .add('days', Number(e.target.value))
                  .format();
                setDaysCount(e.target.value);
                setFieldValue('user.dateAccountDeactivation', createLocalDateISO8601(newStartDate));
              }}
            />
          </GridItem>
          <GridItem>
            <Field
              as={Select}
              name="user.status"
              label="Статус"
              options={userStatuses}
              error={touched?.user?.status && errors?.user?.status}
            />
          </GridItem>
        </Grid>
      </Grid>
      <Checkbox
        m={8}
        isChecked={values.user?.changePasswordRequest}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setFieldValue('user.changePasswordRequest', e.target.checked);
        }}
      >
        Требовать смену пароля при следующем входе в систему
      </Checkbox>
      {values.user?.lastLogin && <LabelText label={`Последний вход в систему: ${values.user.lastLogin}`} />}
      {isEditMode && (
        <Button
          variant="link"
          margin={3}
          leftIcon={<DownloadIcon />}
          onClick={() => {
            location.href = `/api/admin/v10/admin/user/${values.user?.id}/report_all`;
          }}
        >
          Сводка по пользователю
        </Button>
      )}
    </Flex>
  );
};
