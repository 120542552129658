import { useAvailability, useDisclosure, useHandleDeleteData, useList } from 'hooks';
import { MaterialTable } from 'features/material-table/MaterialTableMini';
import React, { useMemo } from 'react';
import columns from './columns';
import { PropActions } from 'features/material-table/components/Actions';
import { WaterRoutesGroundCoast } from 'pages/data/Water/routes';
import { DeleteIcon, EditIcon, ImageIcon } from 'components/icons';
import FilePreviewModalWrapper from 'components/common/FilePreviewModal/FilePreviewModalWrapper';
import useFilePreviewModalWrapperData from 'components/common/FilePreviewModal/hooks/useFilePreviewModalWrapperData';
import { useHistory } from 'react-router-dom';
import { GroundCoastApiPath } from 'pages/data/Water/WPZRoot/GroundCoast/consts/apiPaths';
import { GroundCoastTableFilters } from 'pages/data/Water/WPZRoot/GroundCoast/GroundCoastTable/GroundCoastTableFilters';
import { GroundCoastListDTO } from 'pages/data/Water/WPZRoot/GroundCoast/consts/types';
import appLocale from 'constants/appLocale';
import WPZSwitch from 'pages/data/Water/WPZRoot/WPZSwitch';
import { Modal, SubsystemHeader } from 'components/common';
import { Column } from '@material-table/core';
import { addFiltersToColumns } from 'lib/utils/tableFilter';
import { usePeriodsDict } from 'features/PeriodsButtons/usePeriodsDict';
import subsystemIdsMap from 'constants/subsystemIdsMap';
import { UploadFileModalContent } from 'features/UploadFileDragAndDrop/UploadFileModalContent';
import { useDictionary } from 'hooks/useDictionary';
import { getSelectOptionsFromDictionaryByName } from 'features/get-select-options-from-dictionary';
import { DEFAULT_PAGE_SIZE_NEW_TABLE } from 'constants/tables';
import { FullPageWrapper } from 'components/table/FullPageWrapper';

export const GroundCoastTable = () => {
  const history = useHistory();
  const { dictionaries, isDictionariesLoading } = useDictionary({ url: GroundCoastApiPath.dictionaries });
  const regions = useMemo(
    () => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'regions'),
    [dictionaries?.data],
  );
  const areas = useMemo(() => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'areas'), [dictionaries?.data]);
  const stvory = useMemo(
    () => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'stvory'),
    [dictionaries?.data],
  );
  const { open, ...filePreviewData } = useFilePreviewModalWrapperData();
  const { isWrite, isNoRights, allAvailable, isDelete, isExecute } = useAvailability(subsystemIdsMap.groundcoast);

  const {
    toggleOrder,
    onPageChange,
    response,
    setFilters,
    filterDTO,
    refetch,
    isLoading: isItemsLoading,
    downloadAsFile,
    onSearchText,
    saveFilters,
    errorText,
    selectedIndexes,
    renderOnlySelectedCheckbox,
    onSubmitFilters,
    onSelectionChange,
    materialTableProps,
  } = useList<GroundCoastListDTO, keyof GroundCoastListDTO>(GroundCoastApiPath.list, {
    initialOrderFieldKey: '-date',
    preventFirstFetch: true,
    selectionKey: 'id',
    saveFiltersState: true,
    defaultPageSize: DEFAULT_PAGE_SIZE_NEW_TABLE,
    resetDataOnNextRequest: true,
  });

  const isLoading = useMemo(() => isDictionariesLoading || isItemsLoading, [isDictionariesLoading, isItemsLoading]);

  const { ModalComponent: ConfirmDeleteModal, handleOpenModal } = useHandleDeleteData({ refetch });

  const actions = useMemo<PropActions<GroundCoastListDTO[]>>(
    () => [
      {
        position: 'row',
        action: (row) => ({
          icon: () => <EditIcon />,
          onClick: () => history.push(`${WaterRoutesGroundCoast.edit(row.packageId)}`),
          tooltip: 'Редактировать',
          hidden: !isWrite || !row.isEditable,
        }),
      },
      {
        position: 'row',
        action: (row) => ({
          icon: () => <DeleteIcon />,
          onClick: () => handleOpenModal({ url: GroundCoastApiPath.getOrDelete(row.id) }),
          tooltip: 'Удалить',
          hidden: !isDelete || !row.isEditable,
        }),
      },
      {
        position: 'row',
        action: (row) => ({
          icon: () => <ImageIcon />,
          onClick: () => open(row.packageId, row.objectType),
          tooltip: 'Показать изображения',
          hidden: !row.objectType,
        }),
      },
    ],
    [handleOpenModal, history, isDelete, isWrite, open],
  );

  const columnsWithFilters = useMemo<Array<Column<GroundCoastListDTO>>>(
    () => addFiltersToColumns({ filters: filterDTO.filters, setFilters, columns }),
    [filterDTO.filters, setFilters],
  );

  const { result: periods } = usePeriodsDict(`auth/v10/user/period/${subsystemIdsMap.groundcoast}`);

  const { isOpen: isOpenLoad, onClose: onCloseLoad, onOpen: onOpenLoad } = useDisclosure();
  const isAvailable = allAvailable || isNoRights || isWrite;

  return (
    <FullPageWrapper>
      <SubsystemHeader
        title={appLocale.subsystems.wpz}
        addButtonProps={{
          onClick: () => history.push(WaterRoutesGroundCoast.add),
          isDisabled: !isWrite,
        }}
        navigationTabs={<WPZSwitch activePath={WaterRoutesGroundCoast.root} />}
        filtersComponent={
          <GroundCoastTableFilters
            filters={filterDTO?.filters || []}
            periods={periods}
            isLoading={isLoading}
            isDisabled={isLoading}
            regions={regions}
            areas={areas}
            stvory={stvory}
            onSubmit={(FilterValues) => {
              onSubmitFilters(Object.values(FilterValues));
            }}
          >
            {renderOnlySelectedCheckbox}
          </GroundCoastTableFilters>
        }
        onUploadClick={isAvailable ? onOpenLoad : undefined}
      />

      <MaterialTable
        isNotExecute={isExecute}
        saveFilters={saveFilters}
        toggleOrder={toggleOrder}
        onPageChange={onPageChange}
        filterDTO={filterDTO}
        data={response?.data}
        meta={response?.meta}
        columns={columnsWithFilters}
        actions={actions}
        options={{
          search: true,
        }}
        isLoading={isLoading}
        onSearch={onSearchText}
        downloadAsFile={downloadAsFile}
        selectedIndexes={selectedIndexes}
        onSelectionChange={onSelectionChange}
        errorText={errorText}
        {...materialTableProps}
        showTotalCount
      />

      {ConfirmDeleteModal}

      <FilePreviewModalWrapper {...filePreviewData} />

      <Modal isOpen={isOpenLoad} onClose={onCloseLoad} header="Загрузка данных из файла">
        <UploadFileModalContent
          createTypesPath={() => '/water/v10/water/groundcoast/types'}
          createUploadPath={() => '/water/v10/water/groundcoast/apply'}
          onClose={onCloseLoad}
          refetch={refetch}
        />
      </Modal>
    </FullPageWrapper>
  );
};
