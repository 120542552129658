import React from 'react';
import { Box, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { DefaultText, FormHeader } from 'components/common';
import { useHistory } from 'react-router-dom';

interface StationLayoutProps {
  title: string;
  station: React.ReactNode;
  devices?: React.ReactNode;
  parameters?: React.ReactNode;
  value?: number;
  handleChange?: (newValue: number) => void;
}

export const StationLayout: React.FC<StationLayoutProps> = (props) => {
  const { push } = useHistory();

  return (
    <Box flexDirection="column">
      <Tabs index={props.value} onChange={props.handleChange}>
        <FormHeader onBackClick={() => push('/equipment/stations')} header={props.title}>
          <TabList>
            <Tab>
              <DefaultText>Станция</DefaultText>
            </Tab>
            <Tab isDisabled={!props.devices}>
              <DefaultText>Приборы</DefaultText>
            </Tab>
            <Tab isDisabled={!props.parameters}>
              <DefaultText>Параметры</DefaultText>
            </Tab>
          </TabList>
        </FormHeader>
        <TabPanels>
          <TabPanel px={2}>{props.station}</TabPanel>
          <TabPanel px={2}>{props.devices}</TabPanel>
          <TabPanel px={2}>{props.parameters}</TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};
