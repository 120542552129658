import { SelectOption } from '../../components/common';

export const selectOptionNaturalSort = (options: SelectOption[]) => {
  const collator = new Intl.Collator('ru', { numeric: true, sensitivity: 'base', ignorePunctuation: true });
  return [...options].sort((a, b) => collator.compare(a.name, b.name));
};

export const arrayNaturalSort = (options: any[]) => {
  const collator = new Intl.Collator('ru', { numeric: true, sensitivity: 'base', ignorePunctuation: true });
  return [...options].sort((a, b) => collator.compare(a, b));
};
