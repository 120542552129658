import * as yup from 'yup';
import { requiredError } from '../constans';

export const UserSchema = yup.object().shape({
  user: yup.object().shape({
    name: yup.string().typeError(requiredError).required(requiredError),
    type: yup.string().typeError(requiredError).required(requiredError),
    status: yup.string().typeError(requiredError).required(requiredError),
    code: yup.string().typeError(requiredError).required(requiredError),
    surName: yup.string().typeError(requiredError).required(requiredError),
  }),
});
