import { Grid, GridItem } from '@chakra-ui/react';
import { Field } from 'formik';
import { Input, Select } from 'components/common';
import React from 'react';
import { TDictionaryItemDictionary } from 'models';
import { AsyncAddressSelect } from 'components/common/AsyncAddressSelect/AsyncAddressSelect';

interface IProps {
  okrugs: TDictionaryItemDictionary[];
  rayons: TDictionaryItemDictionary[];
}

export const Location = ({ okrugs, rayons }: IProps) => (
  <>
    <Grid gap={2} templateRows="repeat(6, 1fr)" templateColumns="repeat(6, 2fr)">
      <GridItem rowStart={1} rowEnd={1} colStart={1} colSpan={3}>
        <Field as={Select} name="okrugId" label="Округ" options={okrugs} />
      </GridItem>
      <GridItem rowStart={2} rowEnd={2} colStart={1} colSpan={3}>
        <Field as={Select} name="rayonId" label="Район" options={rayons} />
      </GridItem>
      <GridItem rowStart={3} rowEnd={3} colStart={1} colSpan={3}>
        <Field as={Input} name="landmark" label="Адресный ориентир" />
      </GridItem>
      <GridItem rowStart={4} rowEnd={4} colStart={1} colSpan={3}>
        <Field as={AsyncAddressSelect} name="address" label="Адрес" />
      </GridItem>
      <GridItem rowStart={5} rowEnd={5} colStart={1} colSpan={3}>
        <Field as={Input} name="latitude" label="Широта" />
      </GridItem>
      <GridItem rowStart={6} rowEnd={6} colStart={1} colSpan={3}>
        <Field as={Input} label="Долгота" name="longitude" />
      </GridItem>
    </Grid>
  </>
);
