import { useState } from 'react';
import { UploadObjectType } from 'api/services/media';

const useFilePreviewModalWrapperData = () => {
  const [selectedId, setSelectedId] = useState<string>();
  const [objectType, setObjectType] = useState<UploadObjectType | null>(null);

  const onClose = () => {
    setSelectedId(undefined);
    setObjectType(null);
  };

  const open = (id: string, objectType?: UploadObjectType | null) => {
    if (objectType) {
      setSelectedId(id);
      setObjectType(objectType);
    }
  };
  return {
    objectType,
    id: selectedId,
    onClose,
    open,
  };
};

export default useFilePreviewModalWrapperData;
