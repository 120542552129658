import { Button, FormWrapper, Input, LoaderItem, Select } from 'components/common';
import { Box, Flex, Grid } from '@chakra-ui/react';
import { Field, useFormikContext } from 'formik';
import { baseAdminUrl } from 'pages/admin';
import { objectActions } from 'pages/admin/models/objectActions';
import { objectTypes } from 'pages/admin/models/objectTypes';
import { RightItem } from 'pages/admin/models/RightItem';
import { RightObject } from 'pages/admin/models/RightObject';
import { SubsystemItem } from 'pages/admin/models/SubsystemItem';
import { TOption } from 'pages/admin/models/toption';
import { usePaginatedLoader } from 'pages/admin/typedLoader';
import { memo } from 'react';

export interface FieldFormProps {
  onCancel: () => void;
  isLoading: boolean;
}

const FieldsForm = ({ onCancel, isLoading }: FieldFormProps) => {
  const { handleChange, setFieldValue, values, errors, handleSubmit } = useFormikContext<RightItem>();

  const { result: subsystems, isLoading: isSubsystemsLoading } = usePaginatedLoader<SubsystemItem>(
    `${baseAdminUrl}/subsystem/roots`,
  );
  const subsystemOptions: TOption[] =
    subsystems?.data.map((s: SubsystemItem) => ({
      value: s.id,
      name: s.name,
    })) || [];

  const { result: objects, isLoading: isObjectsLoading } = usePaginatedLoader<RightObject>(`${baseAdminUrl}/object`);
  const objectOptions =
    objects?.data.map((s: RightObject) => ({
      name: s.name,
      value: s.id,
      type: s.type,
    })) || [];

  const initialObjects = objectOptions.filter((v: any) => v.type === values?.objectType);

  if (isLoading || isSubsystemsLoading || isObjectsLoading) {
    return <LoaderItem />;
  }

  return (
    <Box flexDirection="column">
      <FormWrapper>
        <Grid marginTop="30px" gap="15px">
          <Field as={Input} name="code" label="Код" value={values.code} onChange={handleChange} error={errors.code} />
          <Field
            as={Input}
            name="name"
            label="Наименование"
            value={values.name}
            onChange={handleChange}
            error={errors.name}
          />
          <Field
            as={Input}
            name="description"
            label="Описание"
            value={values.description}
            onChange={handleChange}
            error={errors.description}
          />
          <Field
            as={Select}
            name="action"
            label="Действие"
            value={values.action}
            options={objectActions}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              setFieldValue('action', e.target.value);
            }}
            error={errors.action}
          />
          <Field
            as={Select}
            name="objectType"
            label="Тип объекта"
            value={values.objectType}
            options={objectTypes}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              const objType = e.target.value;
              setFieldValue('objectType', objType);
            }}
            error={errors.objectType}
          />
          <Field
            as={Select}
            name="objectId"
            label="Объект"
            value={values.objectId}
            options={initialObjects}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              setFieldValue('objectId', e.target.value);
            }}
            error={errors.objectId}
          />
          <Field
            as={Select}
            name="subsystemId"
            label="Подсистема"
            value={values.subsystemId}
            options={subsystemOptions}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              setFieldValue('subsystemId', e.target.value);
            }}
            error={errors.subsystemId}
          />
          <Flex>
            <Button onClick={onCancel} marginRight={20}>
              Отмена
            </Button>
            <Button onClick={() => handleSubmit()} variant="solid">
              Сохранить
            </Button>
          </Flex>
        </Grid>
      </FormWrapper>
    </Box>
  );
};

export default memo(FieldsForm);
