import { filter, find, reduce } from 'lodash';
import { useCallback, useMemo } from 'react';
import { TDictionariesArray, TDictionaryItemDictionaryKeys } from '../models/dictionaries';

type ExtractGenericDictionariesNames<Type> = Type extends TDictionariesArray<infer X> ? X : never;
/* eslint-disable @typescript-eslint/no-unused-vars */
type ExtractGenericDictionariesValues<Type> = Type extends TDictionariesArray<infer X, infer Y, infer Z> ? Z : never;

type TDictionariesArrayObject<T extends TDictionariesArray> = Record<
  ExtractGenericDictionariesNames<T>,
  T[number]['dictionary']
>;

// @ts-ignore
type ValueOf<T extends TDictionariesArray> = T[number]['dictionary'][number];

const dictionariesArrayToObject = <T extends TDictionariesArray>(dictionaries: T) =>
  reduce<T[number], Partial<TDictionariesArrayObject<T>>>(
    dictionaries,
    (acc, current) => ({ ...acc, [current.name]: current.dictionary }),
    {},
  );

const useUtilDictionaries = <Type extends TDictionariesArray>(data: Type) => {
  const dictionaries = useMemo(() => dictionariesArrayToObject<Type>(data), [data]);

  const getItemFromDictionaryByFieldValue = useCallback(
    (
      name: keyof typeof dictionaries,
      field: TDictionaryItemDictionaryKeys,
      value:
        | ExtractGenericDictionariesValues<typeof data>
        | (Record<string, any> & string)
        | (Record<string, any> & number),
    ): ValueOf<typeof data> | null => {
      const item = find(dictionaries[name], (item) => item[field] === value);

      if (item) {
        // @ts-ignore
        return item;
      }

      return null;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data, dictionaries],
  );

  const getItemsFromDictionaryByFieldValue = useCallback(
    (
      name: keyof typeof dictionaries,
      field: TDictionaryItemDictionaryKeys,
      value:
        | ExtractGenericDictionariesValues<typeof data>
        | (Record<string, any> & string)
        | (Record<string, any> & number),
    ) => filter(dictionaries[name], (item) => item[field] === value),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data, dictionaries],
  );

  const getItemsFromDictionaryByParent = useCallback(
    (
      name: ExtractGenericDictionariesNames<typeof data>,
      value:
        | ExtractGenericDictionariesValues<typeof data>
        | (Record<any, string> & string)
        | (Record<any, string> & number),
    ) => getItemsFromDictionaryByFieldValue(name, 'parent', value),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data, getItemsFromDictionaryByFieldValue],
  );

  return {
    ...dictionaries,
    getItemFromDictionaryByFieldValue,
    getItemsFromDictionaryByParent,
    getItemsFromDictionaryByFieldValue,
  };
};

export type { TDictionariesArrayObject };

export { useUtilDictionaries };
