import React, { useEffect } from 'react';
import { createTouchedErrors } from 'features/create-touched-errors';
import { FormikProvider, useFormik } from 'formik';
import { useHistory, useParams } from 'react-router-dom';
import { FieldsForm } from './FieldsForm';
import { DataSourcesList, FTPSettings } from 'pages/admin/FTP';
import { baseAdminUrl } from 'pages/admin';
import { useRequest } from 'hooks/useRequest';
import { FormHeader } from 'components/common';
import { Box } from '@chakra-ui/react';
import { DataSourceSchema } from 'models/schemas/admin/ftpSettings';
import { initialEmptyValues } from 'pages/admin/FTP/FTPsettings/Catalogs/Forms/AddCatalog';
import { Complaint } from 'pages/citizenAppeal';
import { useEnhancedNotification, useNotifications } from 'hooks';

export const EditCatalog = () => {
  const history = useHistory();
  const { onSuccessEdit, onErrorEdit } = useEnhancedNotification();
  const { errorNotify } = useNotifications();
  const { id } = useParams<{ id: string }>();
  const { get, result } = useRequest<Complaint>(`${baseAdminUrl}/exchange-config/${id}`);

  const onServersError = () => {
    errorNotify({
      key: 'edit/serverError',
      message: 'Необходимо заполнить не менее одного сервера',
    });
  };

  useEffect(() => {
    void get();
  }, [get]);

  const onSuccess = () => {
    history.push(`${FTPSettings}/catalogs`);
    onSuccessEdit();
  };

  const { put } = useRequest<DataSourcesList, any>(`${baseAdminUrl}/exchange-config`, onSuccess, onErrorEdit);

  const initialValues = {
    ...initialEmptyValues,
    ...result,
  };

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    validationSchema: DataSourceSchema,
    initialValues,
    onSubmit: (values) => {
      if (values.servers.length) {
        void put(values);
      } else {
        onServersError();
      }
    },
  });

  return (
    <FormikProvider
      value={{
        ...formik,
        errors: createTouchedErrors(formik.errors, formik.touched),
      }}
    >
      <Box>
        <FormHeader url={`${FTPSettings}/catalogs`} header="Редактирование каталога" />
        <FieldsForm
          onCancel={() => {
            history.push(`${FTPSettings}/catalogs`);
          }}
        />
      </Box>
    </FormikProvider>
  );
};
