import React, { useMemo } from 'react';
import { fieldsLocale } from '../../Devices/consts/locale';
import { createLocalDateDDMMYYYY } from '../../../../lib/create-date';

export const useDeviceTableColumns = () => {
  const columns = useMemo(
    () => [
      {
        title: fieldsLocale.modelName,
        field: 'modelName',
        render: (row: any) => row.models?.map((item: any) => item.name).join(', '),
        sorting: false
      },
      {
        title: fieldsLocale.inventNumber,
        field: 'inventNumber',
      },
      {
        title: fieldsLocale.serialNumber,
        field: 'serialNumber',
      },
      {
        title: fieldsLocale.stationName,
        field: 'stationName',
      },
      {
        title: fieldsLocale.stationAddress,
        field: 'stationAddress',
      },

      {
        title: fieldsLocale.docNumber,
        field: 'docNumber',
      },
      {
        title: fieldsLocale.dateStart,
        field: 'dateStart',
        render: (row: { dateStart: string; dateEnd: string }) => <span>{createLocalDateDDMMYYYY(row.dateStart)}</span>,
      },
      {
        title: fieldsLocale.dateEnd,
        field: 'dateEnd',
        render: (row: any) => <span>{createLocalDateDDMMYYYY(row.dateEnd)}</span>,
      },

      {
        title: fieldsLocale.statusId,
        field: 'status',
      },
    ],
    []
  );

  return columns;
};
