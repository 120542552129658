import { SubsystemHeader } from 'components/common';
import { Modal } from 'components/common/Modals';
import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import { transformFiltersArray } from 'features/Filter/transformFiltersArray';

import { MaterialTable } from 'features/material-table/MaterialTableMini';

import { useAvailability, useDisclosure, useHandleDeleteData } from 'hooks';
import { createDateToRequestWithTimezone } from 'lib/create-date';
import React, { memo, useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import { useList } from 'hooks/useList';
import appLocale from 'constants/appLocale';
import { meteoLocales } from './constants';
import { MeteoDataFilter } from './MeteoDataFilter';
import { useMeteoDictionaries } from './useMeteoDictionaries';
import { IMeteoDataItem, TMeteoDataFilterTypes, TMeteoDataTFilterDTO } from 'pages/data/Meteo/MeteoData/types';
import { TableFilterTypeEnum } from 'components/table/filters/TableFilter';
import { addFiltersToColumns, ColumnWithFilter } from 'lib/utils/tableFilter';
import { Column } from '@material-table/core';
import { MeteoDataMonitoringDataPath, MeteoDataProfilemerApiRoot } from 'pages/data/Meteo/menu';
import { operations } from 'lib/calculations/operations';
import useCalculations from 'hooks/useCalculations';
import { PropActions } from 'features/material-table/components/Actions';
import subsystemIdsMap from 'constants/subsystemIdsMap';
import { usePeriodsDict } from 'features/PeriodsButtons/usePeriodsDict';
import { UploadFileModalContent } from 'features/UploadFileDragAndDrop/UploadFileModalContent';
import { useMeteoUploadTypes } from './useMeteoUploadTypes';
import { DeleteIcon } from '../../../../components/icons';
import { ExtensionToMimeTypeEnum } from 'lib/utils/files/getFileExtensionByMimeType';
import { DEFAULT_PAGE_SIZE_NEW_TABLE } from '../../../../constants/tables';
import { FullPageWrapper } from '../../../../components/table/FullPageWrapper';
import { DateTimeTableCell } from '../../Water/components/DateTimeTableCell';

const MeteoData: React.FC = memo(() => {
  const { isWrite, isExecute, isNoRights, allAvailable, isAvailable } = useAvailability(
    subsystemIdsMap.meteoProfilemers,
  );

  const { result: types } = useMeteoUploadTypes();

  const history = useHistory();

  useUpdateBreadcrumbs([
    { breadcrumb: appLocale.common.data, path: '/data' },
    { breadcrumb: appLocale.subsystems.meteo, path: '/data/meteo' },
    {
      breadcrumb: 'Метеоданные',
      path: '/data/meteo/data',
    },
    {
      breadcrumb: 'Профилемеры',
      path: '/data/meteo/data/monitoring',
    },
  ]);

  const { calculationFields, stations } = useMeteoDictionaries();

  const {
    refetch,
    isLoading,
    response,
    toggleOrder,
    setFilters,
    onPageChange,
    filterDTO,
    downloadAsFile,
    onSearchText,
    saveFilters,
    errorText,
    selectedIndexes,
    renderOnlySelectedCheckbox,
    onSubmitFilters,
    onSelectionChange,
    selectedIds,
    materialTableProps,
  } = useList<IMeteoDataItem, TMeteoDataFilterTypes>(`${MeteoDataProfilemerApiRoot}/list`, {
    initialOrderFieldKey: '-measureDate',
    exportSubsystemName: 'meteo_prof',
    preventFirstFetch: true,
    selectionKey: 'id',
    saveFiltersState: true,
    downloadUrl: {
      [ExtensionToMimeTypeEnum.xlsx]: `${MeteoDataProfilemerApiRoot}/list/export`,
      [ExtensionToMimeTypeEnum.csv]: `${MeteoDataProfilemerApiRoot}/list/export`,
    },
    defaultPageSize: DEFAULT_PAGE_SIZE_NEW_TABLE,
    resetDataOnNextRequest: true,
  });

  const { isOpen: isOpenLoad, onClose: onCloseLoad, onOpen: onOpenLoad } = useDisclosure();

  const { ModalComponent, handleOpenModal } = useHandleDeleteData({ refetch });
  const onRowDelete = useCallback(
    (_, rowData: IMeteoDataItem | IMeteoDataItem[]) => {
      if (rowData && !Array.isArray(rowData)) {
        handleOpenModal({ url: `${MeteoDataProfilemerApiRoot}/${rowData.id}` });
      }
    },
    [handleOpenModal],
  );

  const columns = useMemo<Array<ColumnWithFilter<IMeteoDataItem>>>(
    () => [
      {
        title: meteoLocales.measuringDate,
        field: 'measureDate',
        hidden: false,
        render: ({ measureDate }) => <DateTimeTableCell inline datestring={measureDate} />,
      },
      {
        title: meteoLocales.stationId,
        field: 'station',
        hidden: false,
        render: (row) => row.station || '-',
        filterName: 'station',
        filterType: TableFilterTypeEnum.string,
      },
      {
        title: meteoLocales.heightId,
        field: 'height',
        hidden: false,
        render: (row) => (typeof row.height === 'number' ? row.height : 'Внешняя температура'),
        filterType: TableFilterTypeEnum.number,
      },
      {
        title: meteoLocales.value,
        field: 'value',
        hidden: false,
        filterType: TableFilterTypeEnum.number,
      },
    ],
    [],
  );

  // calculations
  const { applyCalculation, isCalculationLoading, renderDriftPreview, calculatePreview, renderPreview } =
    useCalculations({
      onSuccess: refetch,
      previewPath: MeteoDataProfilemerApiRoot,
      apiPath: MeteoDataProfilemerApiRoot,
      columns,
    });

  // data processing
  const onSubmitDataProcessing = useCallback(
    (values) => {
      const obj = {
        ...values,
        ids: selectedIds || [],
      };
      if (values.operationId === 'delete') {
        calculatePreview(
          {
            value: values.value,
            valueType: values.operandId.toUpperCase(),
            operation: values.operationId,
            criteria: filterDTO,
          },
          obj,
        );
        return;
      }
      applyCalculation(obj);
    },
    [selectedIds, applyCalculation, calculatePreview, filterDTO],
  );

  const dataProcessing = useMemo(
    () => ({
      operands: calculationFields,
      operations,
      onSubmit: onSubmitDataProcessing,
    }),
    [calculationFields, onSubmitDataProcessing],
  );

  const columnsWithFilters = useMemo<Array<Column<IMeteoDataItem>>>(
    () => addFiltersToColumns({ filters: filterDTO.filters, setFilters, columns }),
    [columns, filterDTO.filters, setFilters],
  );

  const initialActions = useMemo<PropActions<IMeteoDataItem[]>>(
    () => [
      {
        position: 'row',
        action: (row: IMeteoDataItem) => ({
          icon: () => <DeleteIcon />,
          onClick: () => onRowDelete(undefined, row),
          tooltip: 'Удалить',
          hidden: !row.isEditable,
        }),
      },
    ],
    [onRowDelete],
  );
  // @ts-ignore
  const actions = useMemo<PropActions<any[]>>(() => {
    if (allAvailable || isNoRights || isWrite) {
      return initialActions;
    }
    if (!(allAvailable || isNoRights || isWrite)) {
      return initialActions.filter((action: any) => action.type !== 'delete');
    }
  }, [isWrite, initialActions, isNoRights, allAvailable]);

  const { result: periods } = usePeriodsDict(`auth/v10/user/period/${subsystemIdsMap.meteoProfilemers}`);

  return (
    <FullPageWrapper>
      <SubsystemHeader
        title="Профилемеры"
        addButtonProps={{
          onClick: () => {
            history.push(`${MeteoDataMonitoringDataPath}/add`);
          },
          isDisabled: !isAvailable,
        }}
        onUploadClick={isAvailable ? onOpenLoad : undefined}
        filtersComponent={
          <MeteoDataFilter
            periods={periods}
            filters={filterDTO?.filters || []}
            stations={stations}
            isLoading={isLoading || isCalculationLoading}
            onSubmit={({ dateFrom, dateTo, ...values }) => {
              onSubmitFilters([
                ...transformFiltersArray<TMeteoDataTFilterDTO['filters']>(
                  Object.values({
                    ...values,
                    dateFrom: {
                      ...dateFrom,
                      value: createDateToRequestWithTimezone(String(dateFrom.value)),
                    },
                    dateTo: {
                      ...dateTo,
                      value: createDateToRequestWithTimezone(String(dateTo.value)),
                    },
                    disabled: {
                      key: 'deleted',
                      operation: 'EQ',
                      value: false,
                    },
                  }),
                ),
              ]);
            }}
          >
            {renderOnlySelectedCheckbox}
          </MeteoDataFilter>
        }
      />
      <Modal isOpen={isOpenLoad} onClose={onCloseLoad} header="Загрузка данных из файла">
        <UploadFileModalContent
          createUploadPath={() => `${MeteoDataProfilemerApiRoot}/load`}
          types={types?.types}
          onClose={onCloseLoad}
          refetch={refetch}
        />
      </Modal>
      <MaterialTable
        saveFilters={saveFilters}
        isNotExecute={isExecute || isNoRights}
        data={response?.data}
        meta={response?.meta}
        toggleOrder={toggleOrder}
        onPageChange={onPageChange}
        filterDTO={filterDTO}
        isLoading={isLoading || isCalculationLoading}
        options={{
          search: true,
          showTitle: true,
          toolbar: true,
        }}
        onSearch={onSearchText}
        columns={columnsWithFilters}
        actions={actions}
        dataProcessing={dataProcessing}
        downloadAsFile={downloadAsFile}
        onSelectionChange={onSelectionChange}
        selectedIndexes={selectedIndexes}
        setFilters={setFilters}
        errorText={errorText}
        isWrite={isWrite}
        {...materialTableProps}
        showTotalCount
      />
      {ModalComponent}
      {renderDriftPreview}
      {renderPreview}
    </FullPageWrapper>
  );
});

export { MeteoData };
