import { SelectedCheckboxIcon, UnselectedCheckboxIcon } from 'components/icons';
import { createLocalDateDDMMYYYY } from 'lib/create-date';
import { map } from 'lodash';
import { Column } from '@material-table/core';
import { TDictionaryItemDictionary } from 'models';
import React, { useMemo } from 'react';
import { GreenPlantsAreaLabels } from '../Form/Tabs/Common/constants';
import { IFloraItemDto } from './useFloraList';
import { ColumnWithFilter } from 'lib/utils/tableFilter';
import { TableFilterTypeEnum } from 'components/table/filters/TableFilter';
import { parameterToParameterIdMap } from 'features/parametersMap';

type Tmap = TDictionaryItemDictionary<string | (Record<string, any> & string), string | (Record<string, any> & string)>;
type TResult = ColumnWithFilter<IFloraItemDto>;

export const floraTypesMap: Record<string, TableFilterTypeEnum> = {
  plantGroupType: TableFilterTypeEnum.string,
  plantGroupLocation: TableFilterTypeEnum.string,
  plantGroupCount: TableFilterTypeEnum.number,
  plantAge: TableFilterTypeEnum.select,
  plantHeight: TableFilterTypeEnum.number,
  stemHeight: TableFilterTypeEnum.number,
  diameter: TableFilterTypeEnum.number,
  averageDiameter: TableFilterTypeEnum.number,
  treeTrunkTiltAngle: TableFilterTypeEnum.number,
  // isFirstPlanted: TableFilterTypeEnum.string,
  foliagePercentage: TableFilterTypeEnum.number,
  detailedStateCategory: TableFilterTypeEnum.string,
  stateCategory: TableFilterTypeEnum.string,
  decorativeness: TableFilterTypeEnum.string,
  diseases: TableFilterTypeEnum.string,
  pests: TableFilterTypeEnum.string,
  entomophages: TableFilterTypeEnum.string,
  // emergency: TableFilterTypeEnum.string,
  otherDamage: TableFilterTypeEnum.string,
  other: TableFilterTypeEnum.string,
  recommendations: TableFilterTypeEnum.string,
};

export const floraListParametersToColumns = (
  dictionary?: TDictionaryItemDictionary<string | (Record<string, any> & string),
    string | (Record<string, any> & string)>[],
) =>
  map<Tmap, TResult>(dictionary, (item) => {
    const name = item.parameterId && parameterToParameterIdMap[item.parameterId];
    return {
      title: item.title,
      field: `${item.parameterId}`,
      render(row) {
        if (item.parameterId) {
          let value = '';
          let checkboxEl: JSX.Element | null = null;
          if (Array.isArray(row.parameters[item.parameterId])) {
            // @ts-ignore
            const values = row.parameters[item.parameterId].map((param) => param.value).filter((val) => val !== 'true' && val !== 'false');
            if (values.length > 1) {
              value = values.map((item: string, index: number) => (<p key={index}>{`${item}, `}</p>));
            } else if (values.length === 1 && values[0]) {
              // eslint-disable-next-line prefer-destructuring
              value = values[0];
            } else {
              value = '';
            }
            // @ts-ignore
            const checkboxes = row.parameters[item.parameterId].filter((rowParam: any) => rowParam.value === 'true' || rowParam.value === 'false');
            if (checkboxes.length > 0) {
              checkboxEl = checkboxes[0].value === 'true' ?
                React.createElement(SelectedCheckboxIcon) : React.createElement(UnselectedCheckboxIcon);
            }
          }
          if (checkboxEl) {
            return checkboxEl;
          }
          return value;
        }
      },
      filterType: name && floraTypesMap[name],
    };
  });

export const useFloraListColumns = (
  dictionary?: TDictionaryItemDictionary<string | (Record<any, string> & string),
    string | (Record<any, string> & string)>[],
): Array<Column<IFloraItemDto>> => {
  const extra = useMemo(() => floraListParametersToColumns(dictionary), [dictionary]);

  return useMemo<Array<ColumnWithFilter<IFloraItemDto>>>(
    () => [
      {
        title: 'Дата обновления информации',
        field: 'date',
        render: (row) => {
          if (row.date) {
            return createLocalDateDDMMYYYY(row.date);
          }

          return '-';
        },
      },
      {
        title: GreenPlantsAreaLabels.areaNumber,
        field: 'areaNumber',
        render: (row) => (row.areaNumber ? row.areaNumber : '-'),
      },
      {
        title: 'Округ',
        field: 'okrugName',
      },
      {
        title: 'Район',
        field: 'rayonName',
      },
      {
        title: 'Тип территории',
        field: 'areaTypeName',
      },
      {
        title: GreenPlantsAreaLabels.address,
        field: 'address',
        render: (row) => (row.address ? row.address : '-'),
        filterType: TableFilterTypeEnum.string,
      },
      {
        title: GreenPlantsAreaLabels.plantNumber,
        field: 'plantNumber',
        render: (row) => (row.plantNumber ? row.plantNumber : '-'),
        filterType: TableFilterTypeEnum.number,
      },
      {
        title: GreenPlantsAreaLabels.plantType,
        field: 'plantType',
        render: (row) => (row.plantType ? row.plantType : '-'),
        filterType: TableFilterTypeEnum.string,
      },
      {
        title: GreenPlantsAreaLabels.plantForm,
        field: 'plantForm',
        render: (row) => (row.plantForm ? row.plantForm : '-'),
        filterType: TableFilterTypeEnum.string,
      },
      ...extra,
    ],
    [extra],
  );
};
