import { Button, PageHeader } from 'components/common';
import { StatusIcon } from 'components/icons';
import React from 'react';
import { Flex, Heading } from '@chakra-ui/react';

interface Props {
  reportType?: string;
  onDone: () => void;
}

export const getReportHeading = (reportType?: string) => {
  const noiseOrAir = reportType === 'Шум' ? 'шумовой обстановки' : 'загрязнений воздуха';
  const heading = `Отчет об исследовании ${noiseOrAir}`;
  return heading;
};

export const ReportHeader = ({ reportType, onDone }: Props) => {
  const heading = getReportHeading(reportType);

  return (
    <PageHeader>
      <Flex justifyContent="space-between" alignItems="center" marginBottom={5}>
        <Heading>{heading}</Heading>
        <Button
          leftIcon={<StatusIcon />}
          variant="ghost"
          color="PrimaryButton.500"
          size="sm"
          p={0}
          onClick={() => onDone()}
        >
          Подтвердить выполнение задания
        </Button>
      </Flex>
    </PageHeader>
  );
};
