import * as yup from 'yup';
import { numberError, requiredError } from 'models/schemas/constans';
import moment from 'moment';

export const aisWeatherSchema = yup
  .object()
  .shape({
    packageDatetimeResult: yup.string().nullable().required(requiredError),
    packageDatetime: yup.string().nullable().required(requiredError),
    datetime: yup.string().nullable().required(requiredError),
    stations: yup.array().of(
      yup.object().shape({
        registryDtos: yup.array().of(
          yup.object().shape({
            airPressure: yup.number().typeError(numberError).nullable(),
            airTemperature: yup.number().typeError(numberError).nullable(),
            avgWindSpeed: yup.number().typeError(numberError).nullable(),
            cloudHeight: yup.number().typeError(numberError).nullable(),
            dewPoint: yup.number().typeError(numberError).nullable(),
            instantaneousWind: yup.number().typeError(numberError).nullable(),
            maxWindSpeed: yup.number().typeError(numberError).nullable(),
            phenomena: yup.number().typeError(numberError).nullable(),
            surfaceTemperature: yup.number().typeError(numberError).nullable(),
            visibility: yup.number().typeError(numberError).nullable(),
            wetness: yup.number().typeError(numberError).nullable(),
            windDirection: yup.number().typeError(numberError).nullable(),
          })
        ),
      })
    ),
  })
  .test((values) => {
    if (Math.abs(moment(values.datetime).unix() - moment(values.packageDatetimeResult).unix()) > 60) {
      return new yup.ValidationError(
        'Дата пакета не должна отличаться более чем на минуту от даты сводных измерений',
        '',
        'datetime'
      );
    }
    return true;
  });
