import { IFilterValues } from 'components/common';
import { Modal } from 'components/common/Modals';
import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import { useInitializerUploadSettings } from 'features/UploadedFileProvider/UploadFileSettings';

import { useAvailability, useDisclosure } from 'hooks';
import { InclinometerItem } from 'hooks/geo/Inclinometer/useGeoInclinometerList';
import { TFiltersArray } from 'models';
import React, { useMemo, useState } from 'react';
import { useList } from 'hooks/useList';
import appLocale from 'constants/appLocale';
import { GeoecologicalRootPath } from 'pages/data/GeoecologicalProcesses/consts';
import { DangerouslyGeologicalProcessesHeader } from '../components';
import { InclinometerFilter } from './InclinometerFilter';
import { InclinometerTable } from './Table';
import { UploadFileModalContent } from 'features/UploadFileDragAndDrop/UploadFileModalContent';
import subsystemIdsMap from 'constants/subsystemIdsMap';
import { usePeriodsDict } from 'features/PeriodsButtons/usePeriodsDict';
import { useDictionary } from 'hooks/useDictionary';
import { DEFAULT_PAGE_SIZE_NEW_TABLE } from '../../../../../constants/tables';
import { FullPageWrapper } from '../../../../../components/table/FullPageWrapper';

export const Inclinometer = () => {
  const { isReadOnly, isWrite, isExecute, isNoRights, allAvailable } = useAvailability(subsystemIdsMap.geoecologyRoot);

  useUpdateBreadcrumbs([
    { breadcrumb: appLocale.common.data, path: '/data' },
    {
      breadcrumb: appLocale.subsystems.geoecologicalprocesses,
      path: GeoecologicalRootPath,
    },
    {
      breadcrumb: 'Опасные геологические процессы',
      path: `${GeoecologicalRootPath}/monitoring/danger/inclinometer`,
    },
  ]);

  useInitializerUploadSettings(
    useMemo(
      () => ({
        typesPath: '/geoecology/v10/geoecology/inclinometer/load/types',
        previewPath: '/geoecology/v10/geoecology/inclinometer/load/preview',
        validatePath: '/geoecology/v10/geoecology/inclinometer/load/validate',
        applyPath: '/geoecology/v10/geoecology/inclinometer/load/apply',
        redirectPath: `${GeoecologicalRootPath}/monitoring/danger/inclinometer`,
      }),
      []
    )
  );

  const {
    refetch,
    isLoading: isLoadingInclinometerList,
    response,
    toggleOrder,
    setFilters,
    onPageChange,
    filterDTO,
    downloadAsFile,
    saveFilters,
    errorText,
    selectedIndexes,
    renderOnlySelectedCheckbox,
    onSubmitFilters,
    onSelectionChange,
    materialTableProps,
  } = useList<InclinometerItem, keyof InclinometerItem>('/geoecology/v10/geoecology/inclinometer/list', {
    initialOrderFieldKey: '-valDateTime',
    preventFirstFetch: true,
    exportSubsystemName: 'inclinometer',
    saveFiltersState: true,
    selectionKey: 'id',
    defaultPageSize: DEFAULT_PAGE_SIZE_NEW_TABLE,
    resetDataOnNextRequest: true,
  });

  const { isOpen: isOpenLoad, onClose: onCloseLoad, onOpen: onOpenLoad } = useDisclosure();

  const { dictionaries } = useDictionary({ url: '/geoecology/v10/geoecology/inclinometer/dictionaries' });

  const [filtersFromGraphic, setFiltersFromGraphic] = useState<Partial<IFilterValues> | null>(null);
  const { result: periods } = usePeriodsDict(`auth/v10/user/period/${subsystemIdsMap.dangerprocesses}`);
  return (
    <FullPageWrapper>
      <DangerouslyGeologicalProcessesHeader
        allAvailable={allAvailable}
        isReadonly={isReadOnly}
        isExecute={isExecute}
        isNoRights={isNoRights}
        isWrite={isWrite}
        onOpenLoad={onOpenLoad}
        active="inclinometer"
      >
        <InclinometerFilter
          filters={filterDTO?.filters || []}
          periods={periods}
          filtersFromGraphic={filtersFromGraphic}
          setFiltersFromGraphic={setFiltersFromGraphic}
          isLoading={isLoadingInclinometerList}
          isDisabled={isLoadingInclinometerList}
          dictionaries={dictionaries}
          onSubmit={(values) => {
            const filters: TFiltersArray<keyof InclinometerItem> = Object.values(values);
            onSubmitFilters(filters);
          }}
        >
          {renderOnlySelectedCheckbox}
        </InclinometerFilter>
      </DangerouslyGeologicalProcessesHeader>

      <Modal isOpen={isOpenLoad} onClose={onCloseLoad} header="Загрузка данных из файла">
        <UploadFileModalContent
          createTypesPath={() => '/geoecology/v10/geoecology/inclinometer/load/types'}
          createUploadPath={() => '/geoecology/v10/geoecology/inclinometer/load'}
          types={[
            {
              name: 'Данные измерений инклинометров',
              value: 'inclinometer',
            },
          ]}
          onClose={onCloseLoad}
          refetch={refetch}
        />
      </Modal>

      <InclinometerTable
        saveFilters={saveFilters}
        dictionaries={dictionaries?.data}
        allAvailable={allAvailable}
        isReadonly={isReadOnly}
        isWrite={isWrite}
        isExecute={isExecute}
        isNoRights={isNoRights}
        filters={filterDTO.filters}
        setFilters={setFilters}
        inclinometerList={response}
        isLoading={isLoadingInclinometerList}
        refetch={refetch}
        filterDTO={filterDTO}
        toggleOrder={toggleOrder}
        onPageChange={onPageChange}
        downloadAsFile={downloadAsFile}
        onSelectionChange={onSelectionChange}
        selectedIndexes={selectedIndexes}
        errorText={errorText}
        materialTableProps={materialTableProps}
      />
    </FullPageWrapper>
  );
};
