import * as yup from 'yup';
import { numberError, requiredError } from '../constans';

export const NsiPDVSchema = yup.object().shape({
  industryId: yup.string().nullable().required(requiredError),
  oneTimeEmission: yup.number().nullable().required(requiredError).typeError(numberError),
  parameterId: yup.string().nullable().required(requiredError),
  sourceId: yup.string().nullable().required(requiredError),
  startDate: yup.string().nullable().required(requiredError),
  totalEmission: yup.number().nullable().required(requiredError).typeError(numberError),
});
