import { Filter } from 'features/Filter';
import { createInitialValuesToFormik, createValuesToRequest } from 'features/Filter/utils';
import React, { useMemo } from 'react';
import { IFilterItem, TFilterDTO } from 'models';
import { SelectOption } from 'components/common';
import combineFilters from 'lib/utils/combineFilters';

type TNSIReagentsGroundFilterTypes =
  | 'number'
  | 'pgrSourceTypeId'
  | 'okrugId'
  | 'rayonId'
  | 'address'
  | 'deleted'
  | 'status';
type TNSIReagentsGroundFilterDTO = TFilterDTO<TNSIReagentsGroundFilterTypes>;
type FilterInitialValues = Record<TNSIReagentsGroundFilterTypes, TNSIReagentsGroundFilterDTO['filters'][number]>;

const initialValues: FilterInitialValues = {
  number: {
    key: 'number',
    operation: 'EQ',
    value: '',
  },
  pgrSourceTypeId: {
    key: 'pgrSourceTypeId',
    operation: 'EQ',
    value: null,
  },
  okrugId: {
    key: 'okrugId',
    operation: 'EQ',
    value: null,
  },
  rayonId: {
    key: 'rayonId',
    operation: 'EQ',
    value: null,
  },
  address: {
    key: 'address',
    operation: 'CONTAINS',
    value: '',
  },
  status: {
    key: 'status',
    operation: 'EQ',
    value: null,
  },
  deleted: {
    key: 'deleted',
    operation: 'EQ',
    value: false,
  },
};

interface Props {
  isLoading: boolean;
  onSubmit: (values: FilterInitialValues) => void;
  okrugs?: SelectOption[];
  rayons?: SelectOption[];
  statuses?: SelectOption[];
  pgrSourceType?: SelectOption[];
  filters: TFilterDTO['filters'];
}

const initialValuesToFormik = createInitialValuesToFormik(initialValues);

function NSIReagentsGroundFilters({
  isLoading,
  onSubmit,
  children,
  okrugs = [],
  rayons = [],
  statuses = [],
  pgrSourceType = [],
  filters,
}: React.PropsWithChildren<Props>) {
  const presetValues = useMemo<{ key: string, value: IFilterItem['value'] }[]>(() => {
    const preset: { key: string, value: IFilterItem['value'] }[] = [];
    const initialFilters = combineFilters(initialValues, filters);
    Object.keys(initialFilters).forEach((key) => {
      preset.push({ key: `${key}`, value: initialFilters[key].value });
    });
    return preset;
  }, [filters]);

  return (
    <Filter<typeof initialValuesToFormik>
      isLoading={isLoading}
      isDisabled={isLoading}
      onSubmit={(values) => {
        onSubmit(createValuesToRequest(initialValues, values));
      }}
      initialValues={initialValuesToFormik}
      mainFields={[
        {
          type: 'TEXT',
          name: 'number',
          label: 'Номер участка',
        },
        {
          type: 'SELECT',
          name: 'pgrSourceTypeId',
          label: 'Вид участка',
          options: pgrSourceType,
        },
        {
          type: 'SELECT',
          name: 'okrugId',
          label: 'Округ',
          options: okrugs,
        },
        {
          type: 'SELECT',
          name: 'rayonId',
          label: 'Район',
          options: rayons,
        },
        {
          type: 'TEXT',
          name: 'address',
          label: 'Адрес',
        },
        {
          type: 'SELECT',
          name: 'status',
          label: 'Статус',
          options: statuses,
        },
      ]}
      presetValues={presetValues}
    >
      {children}
    </Filter>
  );
}

export default NSIReagentsGroundFilters;
