import { Field, FormikProvider, useFormik } from 'formik';
import React, { useEffect, useMemo } from 'react';
import { Checkbox, ContentContainer, DateInput, Input, Select } from 'components/common';
import { Grid, GridItem } from '@chakra-ui/react';
import { DictOptions } from '../../consts/types';
import { createDateFromResponse, createLocalDateISO8601, createUTCTime } from 'lib/create-date';
import { createTouchedErrors } from 'features/create-touched-errors';
import { ComplaintSearchSchema } from 'models/schemas/citizenAppeal/complaint';
import { researchTypeAir, researchTypeNoise } from 'pages/citizenAppeal';
import FilterFormControls from 'components/form/FilterFormControls';
import combineFilters from 'lib/utils/combineFilters';
import { IFilterItem } from 'models';
import { MomentInput } from 'moment';

interface FilterProps {
  onSubmit: (values: any) => void;
  areas: DictOptions[];
  districts: DictOptions[];
  incidentTypes: DictOptions[];
  setFilters: (filters: any) => void;
  noiseSourceTypeId: DictOptions[];
  odorSourceTypeId: DictOptions[];
  sources: DictOptions[];
  filters: IFilterItem[];
  isLoading: boolean;
}

const initialValues: Record<string, IFilterItem> = {
  dateTimeMin: {
    key: 'assignmentExecution',
    operation: 'GTE',
    value: null,
  },
  dateTimeMax: {
    key: 'assignmentExecution',
    operation: 'LTE',
    value: null,
  },
  commonIncidentSourceTypeId: {
    key: 'commonIncidentSourceTypeId',
    operation: 'IN',
    value: null,
  },
  incidentAreaId: {
    key: 'incidentAreaId',
    operation: 'IN',
    value: null,
  },
  incidentDistrictId: {
    key: 'incidentDistrictId',
    operation: 'IN',
    value: null,
  },
  complaintCitizenFio: {
    key: 'complaintCitizenFio',
    operation: 'EQ',
    value: '',
  },
  complaintStatusId: {
    key: 'complaintStatusId',
    operation: 'EQ',
    value: '',
  },
  assignmentDateMin: {
    key: 'assignmentDate',
    operation: 'GTE',
    value: null,
  },
  assignmentDateMax: {
    key: 'assignmentDate',
    operation: 'LTE',
    value: null,
  },
  complaintDateMin: {
    key: 'complaintDate',
    operation: 'GTE',
    value: null,
  },
  complaintDateMax: {
    key: 'complaintDate',
    operation: 'LTE',
    value: null,
  },
  complaintNumber: {
    key: 'complaintNumber',
    operation: 'EQ',
    value: '',
  },
  assignmentNumber: {
    key: 'assignmentNumber',
    operation: 'EQ',
    value: '',
  },
  incidentTypeId: {
    key: 'incidentTypeId',
    operation: 'IN',
    value: null,
  },
  complaintCitizenAddress: {
    key: 'complaintCitizenAddress',
    operation: 'EQ',
    value: '',
  },
  incidentAddress: {
    key: 'incidentAddress',
    operation: 'EQ',
    value: '',
  },
  incidentRenovationSign: {
    key: 'incidentRenovationSign',
    operation: 'EQ',
    value: null,
  },
  incidentSourceId: {
    key: 'incidentSourceId',
    operation: 'IN',
    value: null,
  },
  assignmentExecutor: {
    key: 'assignmentExecutor',
    operation: 'CONTAINS',
    value: '',
  },
  deleted: {
    key: 'deleted',
    operation: 'EQ',
    value: false,
  },
};

export const CitizenAppealFilter = ({
  onSubmit,
  areas,
  sources,
  districts,
  incidentTypes,
  setFilters,
  noiseSourceTypeId,
  odorSourceTypeId,
  filters,
  isLoading,
}: FilterProps): JSX.Element => {
  useEffect(() => {
    const initialFilters = combineFilters(initialValues, filters);
    Object.keys(initialFilters).forEach((key) => {
      setFieldValue(`${key}.value`, initialFilters[key].value);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    values,
    errors,
    getFieldMeta,
    getFieldHelpers,
    getFieldProps,
    handleReset,
    submitForm,
    setFieldValue,
    ...formik
  } = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    validationSchema: ComplaintSearchSchema(true),
    initialValues,
    onSubmit,
  });

  const sourceTypes = useMemo(() => {
    if (values.incidentTypeId.value === researchTypeNoise) {
      return noiseSourceTypeId;
    }
    if (values.incidentTypeId.value === researchTypeAir) {
      return odorSourceTypeId;
    }
    return [...noiseSourceTypeId, ...odorSourceTypeId];
  }, [noiseSourceTypeId, odorSourceTypeId, values]);

  const resetFilters = (e: any) => {
    handleReset(e);
    const filters = Object.values(initialValues);
    setFilters(filters);
  };

  return (
    <ContentContainer margin={0} padding={0}>
      <FormikProvider
        value={{
          values,
          getFieldMeta,
          getFieldHelpers,
          getFieldProps,
          handleReset,
          submitForm,
          setFieldValue,
          ...formik,
          errors: createTouchedErrors(errors, formik.touched),
        }}
      >
        <Grid gap={1} templateRows="repeat(1, 1fr)" templateColumns="repeat(7, 1fr)">
          <GridItem rowStart={1} rowEnd={1} colStart={1} colSpan={2}>
            <p>Дата поручения:</p>
          </GridItem>
          <GridItem rowStart={1} rowEnd={1} colStart={3} colSpan={2}>
            <p>Дата и время обращения:</p>
          </GridItem>
        </Grid>
        <Grid gap={2} templateRows="repeat(1, 1fr)" templateColumns="repeat(7, 1fr)">
          <GridItem rowStart={1} rowEnd={1} colStart={1} colSpan={1}>
            <Field
              as={DateInput}
              name="assignmentDateMin.value"
              label="От"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setFieldValue('assignmentDateMin.value', createUTCTime(e.target.value));
              }}
              error={errors.assignmentDateMin?.value}
            />
          </GridItem>
          <GridItem rowStart={1} rowEnd={1} colStart={2} colSpan={1}>
            <Field
              as={DateInput}
              name="assignmentDateMax.value"
              label="До"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setFieldValue('assignmentDateMax.value', createUTCTime(e.target.value));
              }}
              error={errors.assignmentDateMax?.value}
            />
          </GridItem>
          <GridItem rowStart={1} rowEnd={1} colStart={3} colSpan={1}>
            <Field
              as={DateInput}
              showTimeInput
              name="complaintDateMin.value"
              label="От"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setFieldValue('complaintDateMin.value', createUTCTime(e.target.value));
              }}
              error={errors.complaintDateMin?.value}
            />
          </GridItem>
          <GridItem rowStart={1} rowEnd={1} colStart={4} colSpan={1}>
            <Field
              as={DateInput}
              showTimeInput
              name="complaintDateMax.value"
              label="До"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setFieldValue('complaintDateMax.value', createUTCTime(e.target.value));
              }}
              error={errors.complaintDateMax?.value}
            />
          </GridItem>
          <GridItem rowStart={1} rowEnd={1} colStart={5} colSpan={1}>
            <Field
              as={DateInput}
              name="dateTimeMin.value"
              label="Срок (От)"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setFieldValue('dateTimeMin.value', createLocalDateISO8601(e.target.value));
              }}
              error={errors.dateTimeMin?.value}
            />
          </GridItem>
          <GridItem rowStart={1} rowEnd={1} colStart={6} colSpan={1}>
            <Field
              as={DateInput}
              name="dateTimeMax.value"
              label="Срок (До)"
              value={createDateFromResponse(values.dateTimeMax.value as MomentInput)}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setFieldValue('dateTimeMax.value', createLocalDateISO8601(e.target.value));
              }}
              error={errors.dateTimeMax?.value}
            />
          </GridItem>
          <GridItem rowStart={1} rowEnd={1} colStart={7} colSpan={1}>
            <FilterFormControls isLoading={isLoading} onReset={resetFilters} onSubmit={submitForm} />
          </GridItem>
        </Grid>
        <Grid width="90%" marginTop={2} gap={2} templateColumns="repeat(8, fr)">
          <GridItem rowStart={1} rowEnd={1} colStart={1} colSpan={2}>
            <Field
              as={Input}
              name="complaintNumber.value"
              label="Номер обращения"
              value={values.complaintNumber.value}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setFieldValue('complaintNumber.value', e.target.value);
              }}
            />
          </GridItem>
          <GridItem rowStart={1} rowEnd={1} colStart={3} colSpan={2}>
            <Field
              as={Select}
              label="Тема обращения"
              name="incidentTypeId.value"
              options={incidentTypes}
              error={errors?.incidentTypeId?.value}
              multiple
            />
          </GridItem>
          <GridItem rowStart={1} rowEnd={1} colStart={5} colSpan={2}>
            <Field
              as={Select}
              options={sourceTypes}
              name="commonIncidentSourceTypeId.value"
              label="Тип источника"
              multiple
            />
          </GridItem>
          <GridItem rowStart={2} rowEnd={2} colStart={1} colSpan={2}>
            <Field
              as={Input}
              name="assignmentNumber.value"
              label="Номер поручения"
              value={values.assignmentNumber.value}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setFieldValue('assignmentNumber.value', e.target.value);
              }}
            />
          </GridItem>
          <GridItem rowStart={2} rowEnd={2} colStart={3} colSpan={2}>
            <Field as={Select} options={areas} name="incidentAreaId.value" label="Округ" multiple />
          </GridItem>
          <GridItem rowStart={2} rowEnd={2} colStart={5} colSpan={2}>
            <Field as={Select} options={districts} name="incidentDistrictId.value" label="Район" multiple />
          </GridItem>
          <GridItem rowStart={3} rowEnd={3} colStart={1} colSpan={2}>
            <Field as={Input} name="complaintCitizenFio.value" label="ФИО Заявителя" />
          </GridItem>
          <GridItem rowStart={3} rowEnd={3} colStart={3} colSpan={2}>
            <Field
              as={Input}
              name="complaintCitizenAddress.value"
              label="Адрес заявителя"
              value={values.complaintCitizenAddress.value}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setFieldValue('complaintCitizenAddress.value', e.target.value);
              }}
            />
          </GridItem>
          <GridItem rowStart={3} rowEnd={3} colStart={5} colSpan={2}>
            <Field
              as={Input}
              name="incidentAddress.value"
              label="Адрес происшествия"
              value={values.incidentAddress.value}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setFieldValue('incidentAddress.value', e.target.value);
              }}
            />
          </GridItem>

          <GridItem rowStart={3} rowEnd={3} colStart={7} colSpan={1}>
            <Field
              as={Checkbox}
              name="incidentRenovationSign.value"
              checked={values.incidentRenovationSign.value}
              label="Реновация"
            />
          </GridItem>

          <Field
            as={Input}
            name="assignmentExecutor.value"
            label="Исполнитель"
            value={values.assignmentExecutor.value}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFieldValue('assignmentExecutor.value', e.target.value);
            }}
          />

          <Field as={Select} options={sources} name="incidentSourceId.value" label="Предполагаемый источник" multiple />

          <Field as={Checkbox} name="deleted.value" label="Показать удаленные" />
        </Grid>
      </FormikProvider>
    </ContentContainer>
  );
};
