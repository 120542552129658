import blobToFile from 'lib/utils/files/blobToFile';
import makeFileName from 'lib/utils/files/makeFileName';
import { ExtensionToMimeTypeEnum } from 'lib/utils/files/getFileExtensionByMimeType';

const downloadFile = (file: File, name?: string) => {
  const a = document.createElement('a');
  a.href = URL.createObjectURL(file);
  a.download = name || file.name;
  a.click();
};

interface IDownloadFileFromResponse {
  response: any;
  name?: string;
  type?: ExtensionToMimeTypeEnum;
  addDate?: boolean;
}

export const downloadFileFromResponse = ({
  response,
  name,
  type = ExtensionToMimeTypeEnum.docx,
  addDate = false,
}: IDownloadFileFromResponse) => {
  if (response instanceof Blob) {
    const file = blobToFile(response);
    downloadFile(
      file,
      makeFileName({
        type,
        name,
        addDate
      })
    );
  }
};

export default downloadFile;
