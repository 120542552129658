import { createLocalDateDDMMYYYY } from 'lib/create-date';
import { CellObservationPoint } from 'pages/data/GeoecologicalProcesses/GeologicalProcessesGroundwater/components/CellObservationPoint';
import { GeologicalGroundwaterMeasuringObservationPointData } from 'pages/data/GeoecologicalProcesses/GeologicalProcessesGroundwater/Measuring/types';
import { TableFilterTypeEnum } from 'components/table/filters/TableFilter';
import { MeasuringItem } from 'hooks/geo/Measuring/useMeasuringList';
import { ColumnWithFilter } from 'lib/utils/tableFilter';

export const columns: ColumnWithFilter<MeasuringItem>[] = [
  {
    title: 'Дата',
    field: 'measuringDate',
    render: (row) => createLocalDateDDMMYYYY(row.measuringDate),
  },
  {
    title: 'Тип пункта наблюдения',
    field: 'waterSourceTypeName',
  },
  {
    title: 'Пункт наблюдения',
    field: 'sourceCode',
    render: (row) =>
      CellObservationPoint<GeologicalGroundwaterMeasuringObservationPointData>({
        label: row.sourceCode,
        id: row.sourceId,
        url: '/geoecology/v10/geoecology/watersource/measuring/watersourcedata',
      }),
    filterType: TableFilterTypeEnum.number,
  },
  {
    title: 'Дебит, л/с',
    field: 'debit',
    render: (row) => row.debit?.value || '-',
    filterType: TableFilterTypeEnum.number,
  },
  {
    title: 'Глубина до ур., м',
    field: 'depth',
    render: (row) => row.depth?.value || '-',
    filterType: TableFilterTypeEnum.number,
  },
  {
    title: `Температура, ${'\u00b0'}C`,
    field: 'temperature',
    render: (row) => row.temperature?.value || '-',
    filterType: TableFilterTypeEnum.number,
  },
];
