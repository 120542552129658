import { useEffect, useState } from 'react';

export function usePageVisibility() {
  const [isVisible, setIsVisible] = useState(true);
  useEffect(() => {
    function onVisibilityChange(e: any) {
      setIsVisible(e.target.visibilityState === 'visible');
    }
    document.addEventListener('visibilitychange', onVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', onVisibilityChange);
    };
  }, []);
  return { isVisible };
}
