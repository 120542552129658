import { createTouchedErrors } from 'features/create-touched-errors';
import { FormikProvider, useFormik } from 'formik';
import { useRequest } from 'hooks/useRequest';
import { useParams } from 'react-router-dom';
import { useEnhancedNotification } from 'hooks';
import { useEffect } from 'react';
import { useDictionary } from 'hooks/useDictionary';
import { NSIWaterApiPath, NSIWaterSitesPaths } from 'pages/dictionaries/NSIWater/paths';
import initialNSIWaterSitesEmptyValues
  from 'pages/dictionaries/NSIWater/NSIWaterSites/NSIWaterSitesForms/initialNSIWaterSitesEmptyValues';
import NSIWaterSitesFormFields
  from 'pages/dictionaries/NSIWater/NSIWaterSites/NSIWaterSitesForms/NSIWaterSitesFormFields';
import { NsiWaterSitesSchema } from 'models/schemas/nsiWater/nsiWaterSitesSchema';
import { NSIWaterDictionaryKeys, NSIWaterItemType } from 'pages/dictionaries/NSIWater/types';
import { useGoBackWithFallback } from 'lib/utils/goBackWithFallback';

const NSIWaterSitesEdit = () => {
  const { backWithFallback } = useGoBackWithFallback();
  const { id } = useParams<{ id: string }>();

  const { isDictionariesLoading, dictionaries } = useDictionary<NSIWaterDictionaryKeys>({
    url: `${NSIWaterApiPath}/dictionaries`,
  });

  const { isLoading: isItemLoading, result, get } = useRequest(`${NSIWaterApiPath}/${NSIWaterItemType.area}/${id}`);

  useEffect(() => {
    void get();
  }, [get]);

  const { onSuccessEdit, onErrorEdit } = useEnhancedNotification();

  const onSuccess = () => {
    onSuccessEdit();
    backWithFallback(NSIWaterSitesPaths.root);
  };

  const { put, isLoading } = useRequest(NSIWaterApiPath, onSuccess, onErrorEdit);

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    initialValues: { ...initialNSIWaterSitesEmptyValues, ...result },
    validationSchema: NsiWaterSitesSchema,
    onSubmit: (values) => put({ ...values, type: NSIWaterItemType.area }),
  });

  return (
    <FormikProvider
      value={{
        ...formik,
        errors: createTouchedErrors(formik.errors, formik.touched),
      }}
    >
      <NSIWaterSitesFormFields
        dictionaries={dictionaries}
        onCancel={() => {
          backWithFallback(NSIWaterSitesPaths.root);
        }}
        isLoading={isDictionariesLoading || isLoading || isItemLoading}
        isEditMode
        editedObject={result}
      />
    </FormikProvider>
  );
};

export default NSIWaterSitesEdit;
