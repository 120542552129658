import React, { useMemo, useState } from 'react';
import { SelectOption } from 'components/common';
import { QualityItem, QualityItems } from 'hooks/geo/Quality/useQualityList';
import { useHistory } from 'react-router-dom';

import { IUseListMaterialTableProps, IWithFileDownload, useDisclosure } from 'hooks';

import { useRequest } from 'hooks/useRequest';
import { TFilterDTO, TFiltersArray } from 'models';
import { ConfirmModal } from 'components/common/Modals';
import { createLocalDateDDMMYYYY } from 'lib/create-date';
import { MaterialTable } from 'features/material-table/MaterialTableMini';
import { filter, map } from 'lodash';
import { useNotifications } from 'hooks/useNotifications';
import { ERROR_MESSAGES, REMOVE_CONFIRMATION, SUCCESS_MESSAGES } from 'constants/messages';
import { GeoecologicalRootPath } from 'pages/data/GeoecologicalProcesses/consts';
import useFilePreviewModalWrapperData from 'components/common/FilePreviewModal/hooks/useFilePreviewModalWrapperData';
import FilePreviewModalWrapper from 'components/common/FilePreviewModal/FilePreviewModalWrapper';
import { DeleteIcon, EditIcon, ImageIcon } from 'components/icons';
import { addFiltersToColumns, ColumnWithFilter } from 'lib/utils/tableFilter';
import { Column } from '@material-table/core';
import { TableFilterTypeEnum } from 'components/table/filters/TableFilter';
import { CellObservationPoint } from 'pages/data/GeoecologicalProcesses/GeologicalProcessesGroundwater/components/CellObservationPoint';
import { GeologicalGroundwaterQualityObservationPointData } from 'pages/data/GeoecologicalProcesses/GeologicalProcessesGroundwater/Quality/types';
import { templatesObject } from 'constants/templates';
import { PropActions } from 'features/material-table/components/Actions';
import { Flex } from '@chakra-ui/react';

interface QualityTableProps {
  qualityList: QualityItems | null;
  parameters: SelectOption[];
  refetch: () => void;
  toggleOrder: (fieldKey: string) => void;
  onPageChange: (page: number, pageSize: number) => void;
  downloadAsFile: IWithFileDownload;
  setFilters: (filters: TFiltersArray<keyof QualityItem>) => void;
  filters: TFilterDTO<keyof QualityItem>['filters'];
  filterDTO: TFilterDTO;
  onSelectionChange: (checkedRows: QualityItem[]) => void;
  selectedIndexes: number[];
  isReadonly?: boolean;
  isExecute?: boolean;
  isWrite?: boolean;
  isNoRights?: boolean;
  allAvailable?: boolean;
  saveFilters: () => void;
  isLoading: boolean;
  errorText?: string;
  materialTableProps: IUseListMaterialTableProps;
}

export const QualityTable: React.FC<QualityTableProps> = ({
  qualityList,
  parameters,
  refetch,
  filters,
  toggleOrder,
  onPageChange,
  downloadAsFile,
  setFilters,
  filterDTO,
  onSelectionChange,
  selectedIndexes,
  isExecute,
  isWrite,
  isNoRights,
  allAvailable,
  saveFilters,
  isLoading,
  errorText,
  materialTableProps,
}) => {
  const history = useHistory();
  const [removedItem, setRemovedItem] = useState('');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { open, ...filePreviewData } = useFilePreviewModalWrapperData();
  const { successNotify, errorNotify } = useNotifications();

  const onSuccess = () => {
    onClose();
    refetch();
    successNotify({
      key: 'file/delete/success',
      message: SUCCESS_MESSAGES.DELETE,
    });
  };
  const onError = () => {
    onClose();
    errorNotify({
      key: 'file/delete/error',
      message: ERROR_MESSAGES.DELETE,
    });
  };

  const { remove } = useRequest('/geoecology/v10/geoecology/watersource/quality', onSuccess, onError);
  const columns = useMemo<ColumnWithFilter<QualityItem>[]>(
    () => [
      {
        title: 'Дата',
        field: 'measuringDate',
        render: (row) => createLocalDateDDMMYYYY(row.measuringDate),
      },
      {
        title: 'Тип пункта наблюдения',
        field: 'waterSourceTypeName',
        filterType: TableFilterTypeEnum.string,
      },
      {
        title: 'Пункт наблюдения',
        field: 'sourceCode',
        render: (row) =>
          CellObservationPoint<GeologicalGroundwaterQualityObservationPointData>({
            label: row.sourceCode,
            id: row.sourceId,
            url: '/geoecology/v10/geoecology/watersource/quality/watersourcedata',
          }),
        filterType: TableFilterTypeEnum.number,
      },

      ...map(parameters, ({ name, value }) => ({
        title: name,
        field: String(value),
        render: (row: QualityItem) => <Flex>{filter(row.measures, (el) => el.paramId === value)[0]?.value || ''}</Flex>,
        filterType: TableFilterTypeEnum.number,
      })),
    ],
    [parameters],
  );

  const columnsWithFilters = useMemo<Array<Column<QualityItem>>>(
    () => addFiltersToColumns({ filters, setFilters, columns }),
    [columns, filters, setFilters],
  );

  const initialActions = useMemo<PropActions<QualityItem[]>>(
    () => [
      {
        position: 'row',
        action: (row: any) => ({
          icon: () => <EditIcon />,
          onClick: () => history.push(`${GeoecologicalRootPath}/monitoring/water/quality/edit/row/${row.id}`, row),
          tooltip: 'Редактировать',
        }),
      },
      {
        position: 'row',
        action: (row: any) => ({
          icon: () => <DeleteIcon />,
          onClick: () => {
            onOpen();
            setRemovedItem(row.id);
          },
          tooltip: 'Удалить',
        }),
      },
      {
        position: 'row',
        action: (row: any) => ({
          icon: () => <ImageIcon />,
          onClick: () => open(row.id, row.objectType),
          tooltip: 'Показать изображения',
          hidden: !row.objectType,
        }),
      },
    ],
    [history, onOpen, open],
  );

  // @ts-ignore
  const actions = useMemo<PropActions<any[]>>(() => {
    if (allAvailable || isNoRights || isWrite) {
      return initialActions;
    }
    if (!(allAvailable || isNoRights || isWrite)) {
      return initialActions.filter((action: any) => action.type !== 'delete');
    }
  }, [isWrite, initialActions, isNoRights, allAvailable]);

  return (
    <>
      <MaterialTable
        saveFilters={saveFilters}
        isNotExecute={isExecute || isNoRights}
        onSelectionChange={onSelectionChange}
        selectedIndexes={selectedIndexes}
        data={qualityList?.data}
        meta={qualityList?.meta}
        toggleOrder={toggleOrder}
        onPageChange={onPageChange}
        filterDTO={filterDTO}
        columns={columnsWithFilters}
        actions={actions}
        downloadAsFile={downloadAsFile}
        setFilters={setFilters}
        downloadTemplate={[...templatesObject.quality]}
        isLoading={isLoading}
        errorText={errorText}
        {...materialTableProps}
        showTotalCount
      />

      <ConfirmModal
        isOpen={isOpen}
        onClose={onClose}
        onClick={() =>
          remove({
            url: `/geoecology/v10/geoecology/watersource/quality/${removedItem}`,
          })
        }
        header={REMOVE_CONFIRMATION}
      />

      <FilePreviewModalWrapper {...filePreviewData} />
    </>
  );
};
