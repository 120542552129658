import { RenderTree } from 'features/WithSideBar/types';
import { EquipmentsAppRoutes } from './equipmentsAppRoutes';
import { Equipments } from '../../features/WithSideBar/icons';
import appLocale from '../../constants/appLocale';
import { subsystemCodesMap } from 'constants/subsystemIdsMap';
import { Equipment } from '../home/icons';

export const equipmentReportsMenu: RenderTree = {
  id: EquipmentsAppRoutes.reports.root,
  name: appLocale.equipmentPage.reports,
  url: EquipmentsAppRoutes.reports.root,
  otherCode: subsystemCodesMap.equipment.reports,
  children: [
    {
      id: EquipmentsAppRoutes.reports.diagram,
      name: appLocale.equipmentPage.diagram,
      url: EquipmentsAppRoutes.reports.diagramDevices,
      otherCode: subsystemCodesMap.equipment.diagrams,
    },
    {
      id: EquipmentsAppRoutes.reports.table,
      name: appLocale.equipmentPage.table,
      url: EquipmentsAppRoutes.reports.table,
      otherCode: subsystemCodesMap.equipment.tables,
    },
  ],
};

export const equipmentLeftMenu: RenderTree = {
  id: EquipmentsAppRoutes.root,
  name: appLocale.equipmentPage.equipment,
  url: EquipmentsAppRoutes.root,
  icon: <Equipments />,
  homepageIcon: <Equipment />,
  children: [
    {
      id: EquipmentsAppRoutes.models.root,
      name: appLocale.equipmentPage.models,
      url: EquipmentsAppRoutes.models.root,
      otherCode: subsystemCodesMap.equipment.model,
    },
    equipmentReportsMenu,
    {
      id: EquipmentsAppRoutes.devices.root,
      name: appLocale.equipmentPage.devices,
      url: EquipmentsAppRoutes.devices.root,
      otherCode: subsystemCodesMap.equipment.device,
    },
    {
      id: EquipmentsAppRoutes.stations.root,
      name: appLocale.equipmentPage.stations,
      url: EquipmentsAppRoutes.stations.root,
      otherCode: subsystemCodesMap.equipment.station,
    },
  ],
};
