import { Box, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { AttachmentsTabContent, DefaultText, FormHeader, FormWrapper, LoaderItem } from 'components/common';
import React, { useCallback, useMemo } from 'react';
import { COMMON_LOCALE } from 'constants/common';
import { CommonTab } from './CommonTab';
import { ParametersTab } from './ParametersTab';
import { useQuery } from 'hooks/useQuery';
import FormControls from 'components/common/FormControls';
import { useAvailability, useEnhancedNotification } from 'hooks';
import { useFormikContext } from 'formik';
import { SnowDeicingItem } from 'pages/data/PGR/Snow/useSnowDeicingList';
import { useObjectType } from 'hooks/useObjectType';
import appLocale from 'constants/appLocale';
import { FieldFormProps } from 'types/forms';
import subsystemIdsMap from 'constants/subsystemIdsMap';

interface SnowFieldFormProps extends FieldFormProps {
  state?: any;
}

const FieldsForm = ({
  onCancel,
  dictionaries,
  isLoading,
  isEditMode,
  state,
  media,
  onMediaChange,
  uploadItemId,
}: SnowFieldFormProps) => {
  const { isAvailable } = useAvailability(subsystemIdsMap.pgrRoot);

  const title = useMemo(
    () => (isEditMode ? COMMON_LOCALE.text.edit_research_result : COMMON_LOCALE.text.addition_research_result),
    [isEditMode],
  );
  const { openAttachmentsTab } = useQuery();
  const { onValidationError } = useEnhancedNotification();
  const { values, handleSubmit, errors } = useFormikContext<SnowDeicingItem>();
  const objectType = useObjectType(dictionaries);

  const onFormSubmit = useCallback(() => {
    if (Object.keys(errors).length > 0) {
      onValidationError();
    }
    handleSubmit();
  }, [errors, handleSubmit, onValidationError]);

  const submitIsDisabled = useMemo(
    () => [...values.parameters].map((val) => val.value)
      .filter((val) => !!val).length === 0,
    [values.parameters],
  );

  if (isLoading) {
    return <LoaderItem />;
  }

  return (
    <Box flexDirection="column">
      <Tabs defaultIndex={openAttachmentsTab ? 2 : 0}>
        <FormHeader onBackClick={onCancel} state={state} header={title}>
          <TabList>
            {[
              appLocale.common.generalInformation,
              appLocale.common.indicators,
              appLocale.common.attachments,
            ].map((tab, i) => (
              <Tab key={i}>
                <DefaultText cursor="pointer">{tab}</DefaultText>
              </Tab>
            ))}
          </TabList>
        </FormHeader>

        <TabPanels>
          <TabPanel>
            <CommonTab isEditMode={isEditMode} dictionaries={dictionaries} />
          </TabPanel>

          <TabPanel>
            <ParametersTab isWrite={isAvailable} dictionaries={dictionaries}>
              <FormControls
                onCancel={onCancel}
                handleSubmit={onFormSubmit}
                submitIsDisabled={submitIsDisabled || !isAvailable}
              />
            </ParametersTab>
          </TabPanel>

          <TabPanel>
            <FormWrapper>
              {
                isAvailable && (
                  <AttachmentsTabContent
                    objectType={objectType}
                    media={media}
                    onMediaChange={onMediaChange}
                    uploadItemId={uploadItemId}
                  >
                    <FormControls onCancel={onCancel} handleSubmit={onFormSubmit} submitIsDisabled={submitIsDisabled} />
                  </AttachmentsTabContent>
                )
              }
            </FormWrapper>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default React.memo(FieldsForm);
