import React, { useCallback, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { FormikProvider, useFormik } from 'formik';

import { createTouchedErrors } from 'features/create-touched-errors';
import { useRequest } from 'hooks/useRequest';

import { ASKZVFormFields } from './Fields';
import { initialEmptyValues, validationSchema } from './configs';
import { FormHeader, LoaderItem } from 'components/common';
import { apiPathAskzv } from '../apiPath';
import { DictionariesItem } from 'models';
import { useEnhancedNotification } from 'hooks';
import { useGoBackWithFallback } from 'lib/utils/goBackWithFallback';
import { useDictionary } from 'hooks/useDictionary';
import { getSelectOptionsFromDictionaryByName } from 'features/get-select-options-from-dictionary';
import { getFilteredOptionsByPrivileges } from 'features/getFilteredOptionsByPrivileges';

interface IProps {
  listPath: string;
}

export const EditASKZVPackage = ({ listPath }: IProps) => {
  const { backWithFallback } = useGoBackWithFallback();
  const { state } = useLocation();
  const _editedObject = state?.editedObject;
  const editedObject = useMemo(() => _editedObject ?? {}, [_editedObject]);

  const { dictionaries, isDictionariesLoading: isLoading } = useDictionary({ url: apiPathAskzv.dict });
  const sources = useMemo(() => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'sources'), [dictionaries]);

  const filteredSources = useMemo(() => getFilteredOptionsByPrivileges(sources, 'isEditable'), [sources]);

  const fire = useMemo(
    () => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'fire', false, editedObject.fireId),
    [dictionaries?.data, editedObject.fireId],
  );
  const door = useMemo(
    () => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'door', false, editedObject.doorId),
    [dictionaries?.data, editedObject.doorId],
  );
  const event = useMemo(
    () => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'event', false, editedObject.eventId),
    [dictionaries?.data, editedObject.eventId],
  );

  const { onSuccessEdit, onErrorEdit } = useEnhancedNotification();

  const onSuccess = useCallback(() => {
    onSuccessEdit();
    backWithFallback(listPath);
  }, [onSuccessEdit, backWithFallback, listPath]);

  const { put, isLoading: isPutLoading } = useRequest(apiPathAskzv.root, onSuccess, onErrorEdit);

  const useFormikProps = useMemo(
    () => ({
      enableReinitialize: true,
      validateOnChange: true,
      validateOnMount: true,
      validateOnBlur: true,
      validationSchema,
      initialValues: { ...initialEmptyValues, ...editedObject },
      onSubmit: (values: any) => {
        void put(values);
      },
    }),
    [editedObject, put],
  );

  const formik = useFormik(useFormikProps);
  const header = 'Редактирование пакета АСКЗВ';

  if (isPutLoading || isLoading) {
    return <LoaderItem />;
  }

  return (
    <div>
      <FormikProvider
        value={{
          ...formik,
          errors: createTouchedErrors(formik.errors, formik.touched),
        }}
      >
        <FormHeader onBackClick={() => backWithFallback(listPath)} header={header} />
        <ASKZVFormFields
          isEdit
          isLoading={isLoading}
          sources={filteredSources as unknown as DictionariesItem}
          fire={fire as unknown as DictionariesItem}
          door={door as unknown as DictionariesItem}
          event={event as unknown as DictionariesItem}
        />
      </FormikProvider>
    </div>
  );
};
