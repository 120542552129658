import React, { useContext } from 'react';
import { Box, SimpleGrid } from '@chakra-ui/react';
import { MenuColumn } from 'pages/home/components/MenuColumn';
import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import { LoaderItem } from 'components/common';
import { UserInfoContext } from 'features/AuthProvider';
import { Redirect } from 'react-router-dom';
import { AdminMenu } from 'pages/admin';
import { reportsAnalyticsMenu } from 'pages/analyticsReports/reportsAnalyticsMenu';
import { pelRaidsMenu } from 'features/WithSideBar/menus/pelRaidsMenu';
import { sentryJournalMenu } from 'features/WithSideBar/menus/sentryJournalMenu';
import { pdkExcessMenu } from 'features/WithSideBar/menus/pdkExcessMenu';
import { citizenAppealsMenu } from 'features/WithSideBar/menus/citizenAppealsMenu';
import { managerMenu } from 'features/WithSideBar/menus/managerMenu';
import { cartographyMenu } from 'features/WithSideBar/menus/cartographyMenu';
import { analyticsLabMenu } from 'features/WithSideBar/menus/analyticsLabMenu';
import { nsiMenu } from 'features/WithSideBar/menus/nsiMenu';
import { dataMenu } from 'features/WithSideBar/consts';
import { equipmentLeftMenu } from '../equipment/equipmentLeftMenu';
import { NSIRootPath } from '../dictionaries/const/routes';

// interface ReportsCount {
//   [key: string]: number;
// }

export const Home = () => {
  useUpdateBreadcrumbs([]);
  const { isManager, isRolesLoading, isSentry } = useContext(UserInfoContext);

  if (isManager) {
    return <Redirect to="/manager" />;
  }

  if (isSentry) {
    return <Redirect to="/sentry/dashboard" />;
  }

  if (isRolesLoading) {
    return <LoaderItem />;
  }

  return (
    <SimpleGrid columns={4} spacing={10} maxWidth={1280} mt={10} mx="auto">
      <Box>
        <MenuColumn mb={5} menu={dataMenu} />

        <MenuColumn mb={5} menu={cartographyMenu} />
        <MenuColumn mb={5} menu={analyticsLabMenu} />
      </Box>

      <Box>
        <MenuColumn
          mb={5}
          menu={reportsAnalyticsMenu}
          firstItemExtraMarginTop
          hasMorePath="/reports-analytics"
          sliceIndex={5}
        />
        <MenuColumn mb={5} menu={nsiMenu} hasMorePath={NSIRootPath} sliceIndex={5} />
      </Box>

      <Box>
        <MenuColumn mb={5} menu={pelRaidsMenu} />
        <MenuColumn mb={5} menu={equipmentLeftMenu} />

        <MenuColumn mb={5} menu={sentryJournalMenu} />
        <MenuColumn mb={5} menu={pdkExcessMenu} />
        <MenuColumn mb={5} menu={managerMenu} />
      </Box>

      <Box>
        <MenuColumn mb={5} menu={AdminMenu} firstItemExtraMarginTop />

        <MenuColumn mb={5} menu={citizenAppealsMenu} />
      </Box>
    </SimpleGrid>
  );
};
