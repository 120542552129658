import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import appLocale from 'constants/appLocale';
import { NSIRootPath } from '../../../const/routes';
import { BreadcrumbButton, Button, PageHeader } from 'components/common';
import { Flex, Heading, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import React, { FC, useEffect, useMemo } from 'react';
import { GeneralInformation } from './GeneralInformation';
import { Location } from './Location';
import { OtherInformation } from './OtherInformation';
import { useParams } from 'react-router-dom';
import { FormikProvider, FormikValues, useFormik } from 'formik';
import * as yup from 'yup';
import { requiredError } from 'models/schemas/constans';
import { useEnhancedNotification, useNotifications, useRequest, UseRequestReturnType } from 'hooks';
import { getSelectOptionsFromDictionaryByName } from 'features/get-select-options-from-dictionary';
import { SUCCESS_MESSAGES } from 'constants/messages';
import { GreenspacesAPIPath, GreenspacesAreaPath, GreenspacesDictPath } from '../../const';
import { useGoBackWithFallback } from 'lib/utils/goBackWithFallback';
import { useDictionary } from 'hooks/useDictionary';

export const EditGreenspacesPlatformsData: FC = () => {
  useUpdateBreadcrumbs([
    {
      breadcrumb: appLocale.mainPage.nsi,
      path: NSIRootPath,
    },
    {
      breadcrumb: appLocale.subsystems.greenspacesPlatforms,
      path: GreenspacesAreaPath,
    },
  ]);

  const { id } = useParams<{ id: string }>();

  const { backWithFallback } = useGoBackWithFallback();

  const onSuccess = () => {
    successNotify({
      key: 'atmosphere/edit/success',
      message: id ? SUCCESS_MESSAGES.EDIT : SUCCESS_MESSAGES.CREATE,
    });
    backWithFallback(`${GreenspacesAreaPath}`);
  };

  const { onErrorCreate } = useEnhancedNotification();
  const { result, get }: UseRequestReturnType = useRequest(`${GreenspacesAPIPath}/area/${id}`);
  const { post } = useRequest(`${GreenspacesAPIPath}/create`, onSuccess, onErrorCreate);
  const { put } = useRequest(GreenspacesAPIPath, onSuccess, onErrorCreate);

  useEffect(() => {
    if (id) {
      get();
    }
  }, [id, get]);

  const onSubmit = (values: FormikValues) => {
    if (id) {
      put({ ...values, id, type: 'area' });
    } else {
      post({ ...values, type: 'area' });
    }
  };

  const validationSchema = yup.object().shape({
    status: yup.string().nullable().required(requiredError),
    rayonId: yup.string().nullable().required(requiredError),
    okrugId: yup.string().nullable().required(requiredError),
    subsystemId: yup.string().nullable().required(requiredError),
    greenAreaTypeId: yup.string().nullable().required(requiredError),
    greenAreaLocationId: yup.string().nullable().required(requiredError),
    name: yup.string().nullable().required(requiredError),
    number: yup.string().nullable().required(requiredError)
  });

  const { successNotify } = useNotifications();

  const { dictionaries } = useDictionary({ url: GreenspacesDictPath });

  const districts = useMemo(() => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'rayons'), [dictionaries]);
  const regions = useMemo(() => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'okrugs'), [dictionaries]);
  const statuses = useMemo(() => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'statuses'), [dictionaries]);
  const organizations = useMemo(() => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'organizations'), [dictionaries]);
  const areaTypes = useMemo(
    () =>
      getSelectOptionsFromDictionaryByName(dictionaries?.data, 'areaTypes', false, result?.greenAreaTypeId as string),
    [dictionaries?.data, result?.greenAreaTypeId],
  );
  const areaLocations = useMemo(
    () =>
      getSelectOptionsFromDictionaryByName(
        dictionaries?.data,
        'areaLocations',
        false,
        result?.greenAreaLocationId as string,
      ),
    [dictionaries?.data, result?.greenAreaLocationId],
  );
  const subsystems = useMemo(
    () => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'subsystems'),
    [dictionaries],
  );

  const initialValues = {
    ...result,
    number: result?.number || null,
    name: result?.name || null,
    status: result?.status || null,
    latitude: result?.latitude || null,
    longitude: result?.longitude || null,
    okrugId: result?.okrugId || null,
    rayonId: result?.rayonId || null,
    regionId: result?.regionId || null,
    landmark: result?.landmark || null,
    address: result?.address || null,
    startDate: result?.startDate || null,
    stopDate: result?.stopDate || null,
    greenAreaTypeId: result?.greenAreaTypeId || null,
    greenAreaLocationId: result?.greenAreaLocationId || null,
    description: result?.description || null,
    areaSquare: result?.areaSquare || null,
    subsystemId: result?.subsystemId || null,
    organizationId: result?.organizationId || null,
    id: id || null,
  };

  const {
    values,
    errors,
    getFieldMeta,
    getFieldHelpers,
    getFieldProps,
    handleReset,
    submitForm,
    setFieldValue,
    ...formik
  } = useFormik({
    validationSchema,
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: false,
    validateOnBlur: true,
    initialValues,
    onSubmit,
  });

  return (
    <FormikProvider
      value={{
        values,
        errors,
        getFieldMeta,
        getFieldHelpers,
        getFieldProps,
        handleReset,
        submitForm,
        setFieldValue,
        ...formik,
      }}
    >
      <PageHeader>
        <BreadcrumbButton onClick={() => backWithFallback(GreenspacesAreaPath)}>Вернуться назад</BreadcrumbButton>
        <Flex>
          <Heading marginLeft={4}>{`${id ? 'Редактирование' : 'Добавление'} справочных данных`}</Heading>
          <Flex marginLeft="auto">
            <Button marginRight={5} onClick={() => backWithFallback(GreenspacesAreaPath)}>
              Отмена
            </Button>
            <Button onClick={submitForm}>Сохранить</Button>
          </Flex>
        </Flex>
      </PageHeader>
      <Tabs marginTop={10}>
        <TabList>
          <Tab>Общие сведения</Tab>
          <Tab>Местоположение</Tab>
          <Tab>Дополнительная информация</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <GeneralInformation
              subsystems={subsystems}
              statuses={statuses}
              areaTypes={areaTypes}
              areaLocations={areaLocations}
              organizations={organizations}
              isEditMode={!!id}
            />
          </TabPanel>
          <TabPanel>
            <Location districts={districts} regions={regions} />
          </TabPanel>
          <TabPanel>
            <OtherInformation />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </FormikProvider>
  );
};
