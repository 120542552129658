import { memo } from 'react';
import { PreviewCustomTable } from 'pages/data/Meteo/components/PreviewCustomTable';

enum ForecastTemplates {
  'ForecastPreview' = 1,
}

const getTemplate = (typeId: number) => {
  switch (typeId) {
    case ForecastTemplates.ForecastPreview:
      return memo(PreviewCustomTable);
    default:
      return null;
  }
};

export { getTemplate };
