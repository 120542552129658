import { useRequest } from 'hooks';
import { GeoecologyAPIPath, GeologicalProcessesPath, tabComparisonObject } from '../../constants/constants';
import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import appLocale from 'constants/appLocale';
import { NSIRootPath } from '../../../const/routes';
import { useParams } from 'react-router-dom';
import { PageHeader } from 'components/common';
import React, { useEffect } from 'react';
import { IInclinometerRowData, IRowData } from '../../constants/types';
import MaterialTable from '@material-table/core';
import { Grid, Heading } from '@chakra-ui/react';
import { FullPageWrapper } from '../../../../../components/table/FullPageWrapper';

export const InclinometerPage = () => {
  const { id, number } = useParams<{ id: string; number: string }>();
  useUpdateBreadcrumbs([
    {
      breadcrumb: appLocale.mainPage.nsi,
      path: NSIRootPath,
    },
    {
      breadcrumb: 'Опасные геоэкологические процессы',
      path: `${GeologicalProcessesPath}/${Object.keys(tabComparisonObject)[2]}`,
    },
    {
      breadcrumb: `Глубины инклинометра №${number}`,
      path: `${GeologicalProcessesPath}/inclinometers/${number}/${id}`,
    },
  ]);
  const { get, isLoading, result } = useRequest<IInclinometerRowData[]>(`${GeoecologyAPIPath}/info/inclinometer/${id}`);

  useEffect(() => {
    get();
  }, [id, get]);

  const tableColumns = [
    {
      title: 'Номер по порядку',
      field: 'key',
      render: (row: IRowData) => Object.keys(row)[0],
    },
    {
      title: 'Глубина',
      field: 'value',
      render: (row: IRowData) => Object.values(row)[0],
    },
  ];
  return (
    <FullPageWrapper>
      <PageHeader>
        <Heading marginLeft={4}>Глубины инклинометра №{number}</Heading>
      </PageHeader>
      <Grid gap={5} marginTop={5} marginLeft={5}>
        <MaterialTable
          style={{ maxWidth: '50%' }}
          options={{
            toolbar: false,
          }}
          data={result || []}
          isLoading={isLoading}
          columns={tableColumns}
        />
      </Grid>
    </FullPageWrapper>
  );
};
