import { TDictionaryItemDictionary } from '../models';

export const getFilteredOptionsByPrivileges = (
  options: TDictionaryItemDictionary[] | undefined,
  privilegeKey: keyof TDictionaryItemDictionary,
  exceptionKey: keyof TDictionaryItemDictionary = 'value',
  exceptionValue?: string | null,
): TDictionaryItemDictionary[] =>
  options?.filter(
    (optionItem) =>
      optionItem[privilegeKey] || optionItem[privilegeKey] === undefined || optionItem[exceptionKey] === exceptionValue,
  ) || [];
