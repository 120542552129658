import { ChakraProvider } from '@chakra-ui/react';
import { Routes } from 'pages/routes/routes';
import { chakraTheme } from 'theme';
import { useAxiosSetup } from './api/services/auth';
import { UserInfoProvider } from './features/AuthProvider';
import { usePageVisibility } from 'hooks/usePageVisibility';
import { useEffect } from 'react';

const App = () => {
  useAxiosSetup();
  const { isVisible } = usePageVisibility();

  useEffect(() => {
    if (!isVisible) {
      if (document.activeElement instanceof HTMLElement) {
        document.activeElement.blur();
      }
    }
  }, [isVisible]);

  return (
    <ChakraProvider theme={chakraTheme} resetCSS>
      <UserInfoProvider>
        <Routes />
      </UserInfoProvider>
    </ChakraProvider>
  );
};

export default App;
