import type { ChangeEvent } from 'react';
import { useMemo } from 'react';
import { Field, useFormikContext } from 'formik';
import { COMMON_LOCALE } from 'constants/common';
import { DateInput, FormWrapper, Input, Select } from 'components/common';
import { getSelectRichOptionsFromDictionaryByName } from 'features/get-select-options-from-dictionary';
import { createDateToRequestWithTimezone as createDateToRequest } from 'lib/create-date';
import type { TDictionariesArray } from 'models';

import { fieldsMeteoLocale, IMeteoPackageForm } from 'pages/data/MASKSH/consts';
import { Grid, Heading } from '@chakra-ui/react';
import { getFilteredOptionsByPrivileges } from 'features/getFilteredOptionsByPrivileges';

interface IProps {
  isLoading: boolean;
  isEditMode?: boolean;
  dictionaries: {
    data: TDictionariesArray;
  } | null;
}
export const MeteoPackageFormCommonTab = ({ isLoading, isEditMode, dictionaries }: IProps) => {
  const { setFieldValue, values, errors } = useFormikContext<IMeteoPackageForm>();

  const stations = useMemo(
    () =>
      getFilteredOptionsByPrivileges(
        getSelectRichOptionsFromDictionaryByName(dictionaries?.data, 'stations'),
        'isEditable',
        'value',
        values.sourceId,
      ),
    [dictionaries?.data, values.sourceId],
  );

  return (
    <FormWrapper>
      <Grid gap={2}>
        <Heading mt={1} mb={2} size="md">
          {COMMON_LOCALE.commonData}
        </Heading>
        <Field
          as={DateInput}
          name="date"
          label={fieldsMeteoLocale.date}
          value={values.date}
          onChange={(e: ChangeEvent<HTMLInputElement>) => setFieldValue('date', createDateToRequest(e.target.value))}
          showTimeInput
          isDisabled={isLoading || isEditMode}
          isRequired
          error={errors.date}
        />

        <Field
          as={Select}
          name="sourceId"
          label={fieldsMeteoLocale.sourceId}
          value={values.sourceId}
          options={stations}
          onChange={(e: ChangeEvent<HTMLSelectElement>) => {
            setFieldValue('sourceId', e.target.value);
          }}
          isDisabled={isLoading || isEditMode}
          isRequired
          error={errors.sourceId}
        />

        <Heading mt={5} mb={2} size="md">
          {COMMON_LOCALE.configuration}
        </Heading>
        <Field as={Input} label={fieldsMeteoLocale.period} name="period" value={values.period} error={errors.period} />
      </Grid>
    </FormWrapper>
  );
};
