import { useParams } from 'react-router-dom';
import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import appLocale from 'constants/appLocale';
import { NSIRootPath } from '../../../const/routes';
import {
  permissibleNoiseLevelApiRoot,
  permissibleNoiseLevelDicts,
  permissibleNoiseLevelPath,
} from '../consts/consts';
import { useNotifications, useRequest, UseRequestReturnType } from 'hooks';
import { useGoBackWithFallback } from 'lib/utils/goBackWithFallback';
import { ERROR_MESSAGES, SUCCESS_MESSAGES } from 'constants/messages';
import React, { useCallback, useEffect, useMemo } from 'react';
import { getSelectOptionsFromDictionaryByName } from 'features/get-select-options-from-dictionary';
import { FormikProvider, FormikValues, useFormik } from 'formik';
import { createTouchedErrors } from 'features/create-touched-errors';
import { BreadcrumbButton, PageHeader } from 'components/common';
import { Flex, Heading } from '@chakra-ui/react';
import { PermissibleNoiseLevelFormFields } from './PermissibleNoiseLevelFormFields/PermissibleNoiseLevelFormFields';
import { PermissibleNoiseLevelSchema } from 'models/schemas/nsi/PermissibleNoiseLevelSchema';
import { useDictionary } from 'hooks/useDictionary';
import { formatErrorMessage } from 'lib/utils/formatErrorMessage';

export const EditPermissibleNoiseLevel = () => {
  const { id } = useParams<{ id: string }>();
  useUpdateBreadcrumbs([
    { breadcrumb: appLocale.mainPage.nsi, path: NSIRootPath },
    { breadcrumb: appLocale.nsi.MASKSH.permissibleNoiseLevel.title, path: permissibleNoiseLevelPath },
  ]);

  const { successNotify, errorNotify } = useNotifications();

  const { dictionaries } = useDictionary({ url: permissibleNoiseLevelDicts });

  const { backWithFallback } = useGoBackWithFallback();

  const onSuccess = () => {
    successNotify({
      key: 'PermissibleNoiseLevel/edit/success',
      message: id ? SUCCESS_MESSAGES.EDIT : SUCCESS_MESSAGES.CREATE,
    });
    backWithFallback(`${permissibleNoiseLevelPath}`);
  };

  const onError = useCallback(
    (message?: string | null) => {
      errorNotify({
        key: 'PermissibleNoiseLevel/edit/error',
        message: formatErrorMessage(message) || (id ? ERROR_MESSAGES.EDIT : ERROR_MESSAGES.CREATE),
      });
    },
    [errorNotify, id],
  );

  const { result, get }: UseRequestReturnType = useRequest(`${permissibleNoiseLevelApiRoot}/${id}`);
  const { post } = useRequest(permissibleNoiseLevelApiRoot, onSuccess, onError);
  const { put } = useRequest(permissibleNoiseLevelApiRoot, onSuccess, onError);

  const territoryTypes = useMemo(
    () =>
      getSelectOptionsFromDictionaryByName(
        dictionaries?.data,
        'territoryTypes',
        false,
        result?.territoryType as string,
      ),
    [dictionaries?.data, result?.territoryType],
  );

  useEffect(() => {
    if (id) {
      void get();
    }
  }, [id, get]);

  const onSubmit = (values: FormikValues) => {
    if (id) {
      put({ ...values });
    } else {
      post({ ...values });
    }
  };

  const initialValues = {
    ...result,
    territoryName: result?.territoryName || '',
    territoryType: result?.territoryType || '',
    documentName: result?.documentName || '',
    eqNight: result?.eqNight || null,
    eqDay: result?.eqDay || null,
    maxNight: result?.maxNight || null,
    maxDay: result?.maxDay || null,
  };

  const { values, errors, submitForm, ...formik } = useFormik({
    validationSchema: PermissibleNoiseLevelSchema,
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: false,
    validateOnBlur: true,
    initialValues,
    onSubmit,
  });

  return (
    <FormikProvider
      value={{
        values,
        submitForm,
        errors: createTouchedErrors(errors, formik.touched),
        ...formik,
      }}
    >
      <PageHeader>
        <BreadcrumbButton onClick={() => backWithFallback(permissibleNoiseLevelPath)}>Вернуться назад</BreadcrumbButton>
        <Flex>
          <Heading marginLeft={4}>{`${id ? 'Редактирование' : 'Добавление'} справочных данных`}</Heading>
        </Flex>
      </PageHeader>
      <PermissibleNoiseLevelFormFields territoryTypes={territoryTypes} submitForm={submitForm} />
    </FormikProvider>
  );
};
