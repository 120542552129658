import { TableLoader } from 'features/material-table/components/Loader';
import { CustomTable } from 'pages/admin/components/CustomTable';
import { useRequest } from 'hooks/useRequest';
import { RightItem } from '../../models/RightItem';
import { PaginatedItems } from 'pages/admin/models/PaginatedItems';
import { RoleWithRightsItem } from '../../models/RoleItem';
import React, { useState } from 'react';
import { SourceItem } from 'pages/admin/models/SourceItem';
import { Radio, RadioGroup } from '@chakra-ui/react';
import { FilterOperation, SearchCriteria, SearchFilter } from 'models/api/SearchCriteria';

export interface SelectedRows {
  (rowData: any): void;
}

export interface SelectableItem {
  id: string;
  code: string;
  name: string;
  tableData: {
    checked: boolean;
  };
}

interface Props {
  // rights: SelectableItem[] | undefined;
  onSelectedRows: (data: SourceItem[], uncheckedData: SourceItem[]) => void;
  isLoading: boolean;
  values: RoleWithRightsItem;
  selectedSubsystem: string;
  tableRef: any;
  url: string;
}

const actions: any = {
  READ: 'Чтение',
  WRITE: 'Запись',
  EXECUTE: 'Выполнение',
  DELETE: 'Удаление',
};

export const RoleRights: React.FC<Props> = (props: Props) => {
  const [objectType, setObjectType] = useState('DICTIONARY');
  const { onSelectedRows, isLoading, values, selectedSubsystem, tableRef } = props;

  const { url } = props;
  const { get: getRights } = useRequest<PaginatedItems<RightItem>>(url);
  const columns = [
    {
      title: 'Действие',
      field: 'action',
      render: (row: any) => actions[row.action],
    },
    {
      title: 'Наименование',
      field: 'name',
    },
    {
      title: 'Объект доступа',
      field: 'objectName',
    },
    {
      title: 'Подсистема',
      field: 'subsystemName',
    },
    {
      title: 'Расположение',
      field: 'moduleName'
    }
  ];

  const [viewColumns, setViewColumns] = useState(columns.map((c: any) => c.field));

  const prepareQuery = (query: any) => {
    const pq = new SearchCriteria();
    pq.filters = [
      new SearchFilter('obj_type', FilterOperation.EQ, objectType)
    ];

    if (selectedSubsystem) {
      pq.filters.push(new SearchFilter('subsystem_id', FilterOperation.EQ, selectedSubsystem));
    }

    pq.offset = query.page * query.pageSize;
    pq.limit = query.pageSize;
    if (query.search != null && query.search.trim() !== '') {
      pq.searchString = query.search;
    }
    if (query.orderDirection != null && query.orderBy?.field) {
      pq.orders = [`${query.orderDirection === 'desc' ? '-' : ''}${query.orderBy?.field}`];
    }
    pq.searchFields = viewColumns;
    return JSON.stringify(pq);
  };

  const setChecked = (data: any[]) => data.map((right: RightItem) => ({
    ...right,
    tableData: {
      checked: Boolean(values.rights.find((rr: string) => right.id === rr)),
    },
  }));

  const gridOptions = {
    search: true,
    selection: true,
    toolbar: true,
    paging: true,
    paginationType: 'normal',
  };

  const onTypeChange = (value: string) => {
    setObjectType(value);
    tableRef?.current?.onPageChange(undefined, 0);
  };

  const onChangeColumns = (cols: string[]) => setViewColumns(cols);

  return (
    <>
      <RadioGroup mt={10} ml={3} mr={3} value={objectType} onChange={onTypeChange}>
        <Radio value="DICTIONARY" mr={4}>
          Справочник
        </Radio>
        <Radio value="SUBSYSTEM" mr={4}>
          Подсистема
        </Radio>
        <Radio value="PERIOD" mr={4}>
          Периоды
        </Radio>
        <Radio value="SOURCE" mr={4}>
          Источники
        </Radio>
        <Radio value="PARAMETER" mr={4}>
          Параметры
        </Radio>
        <Radio value="REPORT" mr={4}>Отчет</Radio>
        <Radio value="CUSTOM">Остальное</Radio>
      </RadioGroup>
      <TableLoader isLoading={Boolean(isLoading)}>
        <CustomTable
          tableRef={tableRef}
          title="Доступные привилегии"
          queryFunc={getRights}
          useObjQuery
          calculateQueryFunc={prepareQuery}
          postLoadFunc={setChecked}
          columns={columns}
          onSelectionChanged={onSelectedRows}
          onChangeColumns={onChangeColumns}
          options={gridOptions}
          showTotalCount
        />
      </TableLoader>
    </>
  );
};
