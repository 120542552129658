import { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FormikProvider, useFormik } from 'formik';

import { createTouchedErrors } from 'features/create-touched-errors';
import { useRequest } from 'hooks/useRequest';

import { useEnhancedNotification } from 'hooks';
import { ApiError } from 'models';
import { IUploadMedia } from 'components/common/FileUploadDragAndDrop/types';
import { useObjectType } from 'hooks/useObjectType';
import { updateFiles } from 'api/services/media';
import { formatErrorMessage } from 'lib/utils/formatErrorMessage';
import { useNotifications } from 'hooks/useNotifications';
import { ERROR_MESSAGES } from 'constants/messages';
import { LoaderItem } from 'components/common';
import { useSourceInfo } from 'pages/data/Water/SurfaceWater/hooks';
import { apiPathSurfaceWater } from 'pages/data/Water/SurfaceWater/apiPath';
import { initialEmptyValues } from './initialEmptyValues';
import { AnalyticsSurfaceWaterFormFields } from 'pages/analytics/forms/SurfaceWater/AnalyticsSurfaceWaterFormFields';
import { AnalyticsSurfaceWaterSchema } from 'models/schemas/analytics/analyticsSurfaceWaterSchema';
import { useGoBackWithFallback } from 'lib/utils/goBackWithFallback';
import { useDictionary } from 'hooks/useDictionary';
import { getSelectOptionsFromDictionaryByName } from 'features/get-select-options-from-dictionary';

export const AnalyticsSurfaceWaterEdit = () => {
  const { id } = useParams<{ id: string }>();
  const { backWithFallback } = useGoBackWithFallback();

  const [media, setMedia] = useState<IUploadMedia[]>([]);
  const { dictionaries, isDictionariesLoading: isLoading } = useDictionary({ url: apiPathSurfaceWater.dict });

  const laboratoryParameters = useMemo(
    () => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'laboratoryParameters'),
    [dictionaries?.data],
  );

  const { result: waterSourceInfo, isLoading: isWaterLoading } = useSourceInfo(
    `${apiPathSurfaceWater.root}/${id}`,
    true,
  );

  const initialValues = useMemo(() => {
    const values: any = { ...initialEmptyValues, ...waterSourceInfo };
    values.values = laboratoryParameters?.map((param) => {
      const val = waterSourceInfo?.values
        .find((item) => item.parameterId === param.value && item.unitId === param.parent);

      return {
        ...val,
        id: val?.id || null,
        parameterId: param.value,
        value: val?.value || '',
        unitId: param.parent || '',
      };
    });

    return values;
  }, [laboratoryParameters, waterSourceInfo]);

  const { onSuccessEdit } = useEnhancedNotification();
  const { errorNotify } = useNotifications();
  const objectType = useObjectType(dictionaries);

  const onError = useCallback(
    (error: ApiError | string | null) => {
      const message = formatErrorMessage(error);
      errorNotify({
        key: message || 'file/edit/error',
        message: message || ERROR_MESSAGES.EDIT,
      });
    },
    [errorNotify],
  );

  const onSuccess = useCallback(() => {
    onSuccessEdit();
    updateFiles(id, objectType, media)
      .then(() => {
        backWithFallback('/analytics');
      })
      .catch((error: Error) => {
        onError(error.message);
      });
  }, [onSuccessEdit, id, objectType, media, backWithFallback, onError]);

  const { put, isLoading: isPutLoading } = useRequest(apiPathSurfaceWater.root, onSuccess, onError);

  const useFormikProps = useMemo(
    () => ({
      enableReinitialize: true,
      validateOnChange: true,
      validateOnMount: true,
      validateOnBlur: true,
      validationSchema: AnalyticsSurfaceWaterSchema,
      initialValues,
      onSubmit: (values: any) => {
        const valuesToPost = { ...values };
        valuesToPost.values = valuesToPost.values.filter((val: any) => Boolean(val?.value));
        void put(valuesToPost);
      },
    }),
    [initialValues, put],
  );

  const formik = useFormik(useFormikProps);

  if (isLoading || isWaterLoading || isPutLoading) {
    return <LoaderItem />;
  }

  return (
    <div>
      <FormikProvider
        value={{
          ...formik,
          errors: createTouchedErrors(formik.errors, formik.touched),
        }}
      >
        <AnalyticsSurfaceWaterFormFields
          parameters={laboratoryParameters}
          isEditMode
          isLoading={isLoading || isWaterLoading}
          media={media}
          onMediaChange={setMedia}
          objectType={objectType}
          uploadItemId={id}
          dictionaries={dictionaries}
          editedObject={waterSourceInfo}
        />
      </FormikProvider>
    </div>
  );
};
