import moment from 'moment';

export const valuesToFilters = (values: any[]) =>
  values.map((item) => {
    if (item.key === 'year') {
      const year = moment(item.value).year();
      return {
        ...item,
        value: item.value ? year : null,
      };
    }
    if (item.key === 'month') {
      const month = moment(item.value).month() + 1;
      return {
        ...item,
        value: item.value ? month : null,
      };
    }
    return item;
  });

export const filtersToValues = (values: any[]) => (
  values.map((item) => {
    if (item.key === 'year') {
      const year = new Date(item.value, 1, 1);
      return {
        ...item,
        value: item.value ? year : null
      };
    } if (item.key === 'month') {
      const month = new Date(2022, Number(item.value) - 1, 1);
      return {
        ...item,
        value: item.value ? month : null
      };
    }
    return item;
  })
);
