import { Box, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';

import { DefaultText, FormHeader } from 'components/common';

import { TDictionariesArray } from 'models';

import { NoisePackageFormTabs } from 'pages/data/MASKSH/Data/Noise/NoisePackageForm/Form/Tabs';
import { tabsNoiseLocaleList } from 'pages/data/MASKSH/consts';
import { useGoBackWithFallback } from 'lib/utils/goBackWithFallback';

interface INoisePackageFields {
  dictionaries: {
    data: TDictionariesArray;
  } | null;
  isLoading: boolean;
  isEditMode?: boolean;
  listPath: string;
}

export const NoisePackageFormFields = ({ isLoading, dictionaries, isEditMode, listPath }: INoisePackageFields) => {
  const { backWithFallback } = useGoBackWithFallback();
  const header = `${isEditMode ? 'Редактирование' : 'Добавление'} результата измерения`;

  return (
    <Box flexDirection="column">
      <Tabs>
        <FormHeader onBackClick={() => backWithFallback(listPath)} header={header}>
          <TabList>
            {tabsNoiseLocaleList.map((tab, i) => (
              <Tab key={i}>
                <DefaultText cursor="pointer">{tab}</DefaultText>
              </Tab>
            ))}
          </TabList>
        </FormHeader>
        <TabPanels>
          <TabPanel>
            <NoisePackageFormTabs.Common dictionaries={dictionaries} isLoading={isLoading} isEditMode={isEditMode} />
          </TabPanel>
          <TabPanel>
            <NoisePackageFormTabs.Noise />
          </TabPanel>
          <TabPanel>
            <NoisePackageFormTabs.Self />
          </TabPanel>
          <TabPanel>
            <NoisePackageFormTabs.Octaves isLoading={isLoading} listPath={listPath} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};
