import { useRequest } from './useRequest';
import { useNotifications } from './useNotifications';
import { ERROR_MESSAGES } from '../constants/messages';
import { useHistory } from 'react-router-dom';

export const useGoToNODUploadLog = (
  breadcrumbs: { path: string; breadcrumb: string }[],
  reportType: number,
  reportName: string,
  reportPath: string
) => {
  const history = useHistory();
  const { errorNotify } = useNotifications();

  const onSuccess = (data: { id: string } | null) => {
    if (data?.id) {
      history.push(`${reportPath}/view/${data.id}`, { breadcrumbs, reportNumber: reportType, reportName });
    } else {
      errorNotify({
        key: `catalog-uploader-history/latest/${reportType}`,
        message: ERROR_MESSAGES.NOD_UPLOAD_LOG_ERROR,
      });
    }
  };

  const onError = () => {
    errorNotify({
      key: `catalog-uploader-history/latest/${reportType}`,
      message: ERROR_MESSAGES.GET,
    });
  };

  const { get } = useRequest<{ id: string }>(
    `/ehd/v10/catalog-uploader-history/latest/${reportType}`,
    onSuccess,
    onError
  );

  return {
    goToButtonText: 'Отчет о выгрузке НОД',
    goToButtonProps: { onClick: () => get() },
  };
};
