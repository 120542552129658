import React, { useMemo } from 'react';
import { DataHeader, Filters } from 'pages/data/MASKSH/Data/components';
import {
  MASKSHApiTechnicalList,
  MASKSHInstructions,
  MASKSHRoot,
  MASKSHTechnical,
  MASKSHTechnicalPackages,
} from 'pages/data/MASKSH/consts/menu';
import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import appLocale from 'constants/appLocale';
import { useTechnicalDictionaries } from './hooks/useTechnicalDictionaries';
import { usePeriodsDict } from 'features/PeriodsButtons/usePeriodsDict';
import subsystemIdsMap from 'constants/subsystemIdsMap';
import { MaterialTable } from 'features/material-table/MaterialTableMini';
import { useAvailability, useList } from 'hooks';
import { ITechnicalMeasurement, tableLocale } from 'pages/data/MASKSH/consts';
import { Column } from '@material-table/core';
import { TableFilterTypeEnum } from 'components/table/filters/TableFilter';
import { DateTimeTableCell } from 'pages/data/MASKSH/Data/components/DateTimeTableCell';
import { addFiltersToColumns } from 'lib/utils/tableFilter';
import { TableTabs } from 'pages/data/MASKSH/Data/components/TableTabs';
import { useQuery } from 'hooks/useQuery';
import { getFilteredQuery } from '../../hooks';
import { useHistory } from 'react-router';
import { DEFAULT_PAGE_SIZE_NEW_TABLE } from '../../../../../constants/tables';
import { FullPageWrapper } from '../../../../../components/table/FullPageWrapper';

const Technical: React.FC = () => {
  useUpdateBreadcrumbs([
    { breadcrumb: appLocale.common.data, path: '/data' },
    {
      breadcrumb: appLocale.subsystems.masksh,
      path: MASKSHRoot,
    },
    {
      breadcrumb: 'Данные мониторинга',
      path: MASKSHTechnical,
    },
  ]);
  const query = useQuery();
  const { isExecute, isWrite } = useAvailability(subsystemIdsMap.masksh);
  const { push } = useHistory();

  const {
    parameters,
    objects,
    stations,
    isLoading: isDictionariesLoading,
    packageReceived,
  } = useTechnicalDictionaries();

  const { result: periods } = usePeriodsDict(`auth/v10/user/period/${subsystemIdsMap.masksh}`);

  const {
    isLoading: isListLoading,
    response,
    setFilters,
    toggleOrder,
    onPageChange,
    filterDTO,
    downloadAsFile,
    onSearchText,
    errorText,
    savedFiltersFromCache,
    selectedIndexes,
    renderOnlySelectedCheckbox,
    onSubmitFilters,
    onSelectionChange,
    materialTableProps,
    saveFilters,
  } = useList<ITechnicalMeasurement, keyof ITechnicalMeasurement>(
    MASKSHApiTechnicalList,
    {
      initialOrderFieldKey: '-packageDate',
      exportSubsystemName: 'masksh_technical',
      preventFirstFetch: true,
      selectionKey: 'valueId',
      defaultPageSize: DEFAULT_PAGE_SIZE_NEW_TABLE,
      resetDataOnNextRequest: true,
      saveFiltersState: true,
    },
  );
  const columns = useMemo<Array<Column<ITechnicalMeasurement>>>(
    () => [
      {
        title: tableLocale.columns.recieved,
        field: 'received',
        filterType: TableFilterTypeEnum.select,
        render: (row) => packageReceived?.find((item) => item.value === row.received)?.name,
      },
      {
        title: tableLocale.columns.user,
        field: 'user',
        filterType: TableFilterTypeEnum.string,
      },
      {
        title: 'Номер пакета',
        field: 'packageNumber',
        filterType: TableFilterTypeEnum.number,
      },
      {
        title: 'Дата/время формирования пакета',
        field: 'packageDate',
        render: (row) => <DateTimeTableCell inline datestring={row.packageDate} />,
      },
      {
        title: '№ станции',
        field: 'stationName',
        filterType: TableFilterTypeEnum.string,
      },
      {
        title: 'Место наблюдения',
        field: 'placeName',
        filterType: TableFilterTypeEnum.string,
      },
      {
        title: 'Параметр',
        field: 'parameterName',
        filterType: TableFilterTypeEnum.string,
      },
      {
        title: tableLocale.columns.measureDate,
        field: 'measureDate',
        render: (row) => <DateTimeTableCell datestring={row.measureDate} />,
      },
      {
        title: 'Значение',
        field: 'valueStr',
        filterType: TableFilterTypeEnum.string,
        sorting: false,
      },
      {
        title: tableLocale.columns.instructionNumber,
        field: 'instructionNumber',
        filterType: TableFilterTypeEnum.string,
        render: (row) => (
          <button
            type="button"
            onClick={() => push(`${MASKSHInstructions}/view/${row.instructionId}`, { url: MASKSHTechnical })}
            style={{
              color: 'blue'
            }}
          >
            {row.instructionNumber}
          </button>
        )
      },
    ],
    [packageReceived, push],
  );

  const selectOptions = useMemo(() => {
    const map = new Map();
    map.set('received', packageReceived);
    return map;
  }, [packageReceived]);

  const columnsWithFilters = useMemo<Array<Column<ITechnicalMeasurement>>>(
    () => addFiltersToColumns({ filters: filterDTO.filters, setFilters, columns, selectOptions }),
    [filterDTO.filters, setFilters, columns, selectOptions],
  );

  const isLoading = useMemo(() => isDictionariesLoading || isListLoading, [isDictionariesLoading, isListLoading]);

  return (
    <FullPageWrapper>
      <DataHeader defaultFiltersOpen={!!savedFiltersFromCache} isWrite={isWrite}>
        <Filters
          filters={filterDTO.filters}
          periods={periods}
          isLoading={isLoading}
          isDisabled={isLoading}
          objects={objects}
          stations={stations}
          parameters={parameters}
          showParameterFilter
          onSubmit={(values) => {
            onSubmitFilters(Object.values(values));
          }}
        >
          {renderOnlySelectedCheckbox}
        </Filters>
      </DataHeader>

      <MaterialTable
        isNotExecute={isExecute}
        data={response?.data}
        meta={response?.meta}
        filterDTO={filterDTO}
        toggleOrder={toggleOrder}
        onPageChange={onPageChange}
        topBarLeftContent={
          <TableTabs
            tabs={[
              {
                name: 'Пакеты',
                path: MASKSHTechnicalPackages,
                query: getFilteredQuery(query),
              },
              {
                name: 'Значения измерений',
                path: MASKSHTechnical,
                query: getFilteredQuery(query),
              },
            ]}
          />
        }
        columns={columnsWithFilters}
        isLoading={isLoading}
        options={{
          search: true,
        }}
        onSearch={onSearchText}
        downloadAsFile={downloadAsFile}
        errorText={errorText}
        selectedIndexes={selectedIndexes}
        onSelectionChange={onSelectionChange}
        saveFilters={saveFilters}
        {...materialTableProps}
        showTotalCount
      />
    </FullPageWrapper>
  );
};

export { Technical };
