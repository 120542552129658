import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import appLocale from 'constants/appLocale';
import { Button, SubsystemHeader } from 'components/common';
import { MaterialTable } from 'features/material-table/MaterialTableMini';

import { ReloadIcon } from 'components/icons';
import React, { useCallback, useEffect } from 'react';
import { useNotifications, useRequest } from 'hooks';
import { ERROR_MESSAGES, SUCCESS_MESSAGES } from '../../../constants/messages';

export const EXD = () => {
  useUpdateBreadcrumbs([
    { breadcrumb: appLocale.admin.main, path: '/admin' },
    {
      breadcrumb: appLocale.admin.EXD,
      path: '/admin/EXD',
    },
  ]);

  const { successNotify, errorNotify } = useNotifications();

  const onSuccess = () => {
    successNotify({
      key: 'file/create/success',
      message: SUCCESS_MESSAGES.STATUS_DONE,
    });
  };

  const onError = () => {
    errorNotify({
      key: 'file/create/error',
      message: ERROR_MESSAGES.STATUS_DONE,
    });
  };

  const { get, isLoading, result } = useRequest<any>('/ehd/v10/v1/dict-loader/dictionaries');
  const { post: updateSingle } = useRequest<any>('', onSuccess, onError);
  const { post: updateAll } = useRequest<any>('', onSuccess, onError);

  useEffect(() => {
    get();
  }, [get]);

  const onDictUpdate = useCallback(
    (id: string) => {
      void updateSingle({}, { url: `/ehd/v10/v1/dict-loader/dictionaries/${id}/sync` });
    },
    [updateSingle]
  );

  const onAllDictUpdate = useCallback(() => {
    void updateAll({}, { url: '/ehd/v10/v1/dict-loader/dictionaries/sync' });
  }, [updateAll]);

  return (
    <>
      <SubsystemHeader title="Справочники ЕХД" />
      <MaterialTable
        topBarLeftContent={
          <Button
            onClick={() => {
              onAllDictUpdate();
            }}
            variant="outline"
            color="PrimaryButton.500"
            padding={2}
          >
            Синхронизировать все справочники
          </Button>
        }
        data={result || []}
        columns={[
          {
            title: 'ID справочника',
            field: 'id',
          },
          {
            title: 'Имя справочника',
            field: 'name',
          },
        ]}
        actions={[
          {
            position: 'row',
            action: (row) => ({
              icon: () => <ReloadIcon />,
              onClick: () => onDictUpdate(row.id),
              tooltip: 'Синхронизировать справочник',
            }),
          },
        ]}
        isLoading={isLoading}
        options={{
          selection: false,
        }}
      />
    </>
  );
};
