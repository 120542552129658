import React, { useCallback, useEffect, useState } from 'react';
import { useDisclosure } from '@chakra-ui/react';
import { SubsystemHeader } from 'components/common';
import appLocale from 'constants/appLocale';
import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import AnalyticsSelectSubsystemModal from 'pages/analytics/components/AnalyticsSelectSubsystemModal';
import AnalyticsTableFilter from 'pages/analytics/components/AnalyticsTableFilter';
import { TFiltersArray } from 'models';
import { useList } from 'hooks/useList';
import { DeleteIcon, EditIcon, ImageIcon, ViewIcon2 } from 'components/icons';
import { MaterialTable } from 'features/material-table/MaterialTableMini';
import { useHistory } from 'react-router-dom';
import AnalyticsTableColumns from 'pages/analytics/consts/AnalyticsTableColumns';
import { IAnalyticsItem } from 'pages/analytics/consts/types';
import FilePreviewModalWrapper from 'components/common/FilePreviewModal/FilePreviewModalWrapper';
import useFilePreviewModalWrapperData from 'components/common/FilePreviewModal/hooks/useFilePreviewModalWrapperData';
import { getPathsBySubsystemId } from 'pages/analytics/consts/AnalyticsTableRoutes';
import { useEnhancedNotification, useHandleDeleteData, useRequest } from 'hooks';
import { downloadFileFromResponse } from 'lib/utils/files/downloadFile';
import { ExtensionToMimeTypeEnum } from 'lib/utils/files/getFileExtensionByMimeType';
import { analyticToReportPath } from './consts/consts';
import { useDictionary } from 'hooks/useDictionary';
import { subsystemCodesMap } from 'constants/subsystemIdsMap';
import { useCustomRights } from 'hooks/useCustomRights';
import { DEFAULT_PAGE_SIZE_NEW_TABLE } from '../../constants/tables';
import { FullPageWrapper } from '../../components/table/FullPageWrapper';
import { createLocalDateDDMMYYYY } from 'lib/create-date';

const Analytics = () => {
  const { isWrite, isExecute } = useCustomRights(subsystemCodesMap.analyticsLab);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const history = useHistory();
  const { onDownloadError } = useEnhancedNotification();
  const { open, ...filePreviewData } = useFilePreviewModalWrapperData();
  const [downloadReportItem, setDownloadReportItem] = useState<IAnalyticsItem>();

  const onFileResponseSuccess = useCallback(
    (response) => {
      if (response instanceof Blob && downloadReportItem) {
        downloadFileFromResponse({
          response,
          name: `${downloadReportItem.protocolNumber}_${createLocalDateDDMMYYYY(downloadReportItem.date || '')}_${downloadReportItem.subsystemModuleName}`,
        });
        setDownloadReportItem(undefined);
      }
    },
    [downloadReportItem],
  );

  const _onDownloadError = useCallback(() => {
    onDownloadError();
    setDownloadReportItem(undefined);
  }, [onDownloadError]);

  const { get: downloadReport } = useRequest<Blob>('', onFileResponseSuccess, _onDownloadError);

  useEffect(() => {
    if (downloadReportItem) {
      void downloadReport({
        url: getPathsBySubsystemId(downloadReportItem.subsystemId)?.download(downloadReportItem.id),
        headers: { ACCEPT: ExtensionToMimeTypeEnum.docx },
        responseType: 'blob',
      });
    }
  }, [downloadReport, downloadReportItem]);

  useUpdateBreadcrumbs([{ breadcrumb: appLocale.subsystems.analyticsLab, path: '/analytics' }]);

  const { dictionaries } = useDictionary({ url: '/reporting/v10/laboratory/dictionaries' });

  const {
    refetch,
    isLoading,
    response,
    toggleOrder,
    setFilters,
    onPageChange,
    filterDTO,
    downloadAsFile,
    onSearchText,
    errorText,
    materialTableProps,
  } = useList<IAnalyticsItem>('/reporting/v10/laboratory/list', {
    initialOrderFieldKey: '-date',
    exportSubsystemName: 'analytics',
    saveFiltersState: true,
    defaultPageSize: DEFAULT_PAGE_SIZE_NEW_TABLE,
    resetDataOnNextRequest: true,
  });

  const { ModalComponent: ConfirmDeleteModal, handleOpenModal } = useHandleDeleteData({ refetch });

  const onReportDownload = useCallback(async (_e, item) => {
    if (!Array.isArray(item)) {
      setDownloadReportItem(item);
    }
  }, []);

  return (
    <FullPageWrapper>
      <SubsystemHeader
        title={appLocale.subsystems.analyticsLab}
        addButtonProps={{
          onClick: onOpen,
          isDisabled: !isWrite,
        }}
        filtersComponent={
          <AnalyticsTableFilter
            isLoading={isLoading}
            isDisabled={isLoading}
            dictionaries={dictionaries?.data}
            onSubmit={(values) => {
              const filters: TFiltersArray = Object.values(values);
              setFilters(filters);
            }}
            filters={filterDTO.filters}
          />
        }
      />

      <MaterialTable
        isNotExecute={isExecute}
        filterDTO={filterDTO}
        data={response?.data}
        meta={response?.meta}
        columns={AnalyticsTableColumns}
        toggleOrder={toggleOrder}
        onPageChange={onPageChange}
        actions={[
          {
            position: 'row',
            action: (row) => ({
              type: 'edit',
              onClick: () => {
                const editPath = getPathsBySubsystemId(row.subsystemId)?.edit(row.id);
                if (editPath) {
                  history.push(editPath);
                }
              },
              icon: () => <EditIcon />,
              hidden: !isWrite,
            }),
          },
          {
            position: 'row',
            action: (row) => ({
              type: 'delete',
              onClick: () => {
                const deletePath = getPathsBySubsystemId(row.subsystemId)?.delete(row.id);
                if (deletePath) {
                  handleOpenModal({ url: deletePath });
                }
              },
              icon: () => <DeleteIcon />,
              hidden: !row.canDeleted || !isWrite,
            }),
          },
          {
            position: 'row',
            action: (row) => ({
              icon: () => <ImageIcon />,
              onClick: () => open(row.id, row.objectType),
              tooltip: 'Показать изображения',
              hidden: !row.objectType,
            }),
          },
          {
            position: 'row',
            action: (row) => ({
              icon: () => <ViewIcon2 />,
              onClick: () => history.push(analyticToReportPath, { id: row.taskId, tabIndex: 2 }),
              tooltip: 'Перейти к отчету',
              hidden: !row.taskId,
            }),
          },
          {
            type: 'download',
            onClick: onReportDownload,
            disabled: !!downloadReportItem,
          },
        ]}
        downloadAsFile={downloadAsFile}
        options={{
          search: true,
        }}
        isLoading={isLoading}
        onSearch={(searchFields, searchStr) => {
          onSearchText([...searchFields, 'subsystem', 'subsystemModule'], searchStr);
        }}
        errorText={errorText}
        {...materialTableProps}
      />

      {ConfirmDeleteModal}

      <AnalyticsSelectSubsystemModal isOpen={isOpen} onClose={onClose} />

      <FilePreviewModalWrapper {...filePreviewData} />
    </FullPageWrapper>
  );
};

export default Analytics;
