import { createTouchedErrors } from 'features/create-touched-errors';
import { FormikProvider, useFormik } from 'formik';
import { useRequest } from 'hooks/useRequest';
import { useParams } from 'react-router-dom';
import React, { useCallback, useEffect, useState } from 'react';
import { PGRSoilSchema } from 'models/schemas/pgr';
import { initialEmptyValues } from './AddSoilDeicingReport';
import FieldsForm from './FieldsForm';
import { ReagentsSoilApiDictionaries, ReagentsSoilApiRoot, ReagentsSoilRootPath } from '../../../consts';
import { IUploadMedia } from 'components/common/FileUploadDragAndDrop/types';
import { updateFiles } from 'api/services/media';
import { useObjectType } from 'hooks/useObjectType';
import { SoilDeicingItem } from 'pages/data/PGR/Soil/consts';
import { useEnhancedNotification } from 'hooks';
import { addTypeToValue, splitTypesAndValues } from 'lib/utils/forms/valueTypeHelper';
import { useGoBackWithFallback } from 'lib/utils/goBackWithFallback';
import { useDictionary } from 'hooks/useDictionary';

export const EditDeicingSoilReport = () => {
  const { backWithFallback } = useGoBackWithFallback();
  const { id } = useParams<{ id: string }>();
  const { dictionaries, isDictionariesLoading } = useDictionary({ url: ReagentsSoilApiDictionaries });
  const { onErrorEdit, onSuccessEdit } = useEnhancedNotification();
  const [media, setMedia] = useState<IUploadMedia[]>([]);
  const [editedObject, setEditObject] = useState<SoilDeicingItem>();
  const objectType = useObjectType(dictionaries);

  const onItemLoad = useCallback((response: SoilDeicingItem | null) => {
    if (response) {
      response.values = response.values.map((v) => ({ ...v, value: addTypeToValue(v.type, v.value) }));
      setEditObject(response);
    }
  }, []);

  const { get, isLoading: isItemLoading } = useRequest<SoilDeicingItem>(`${ReagentsSoilApiRoot}/${id}`, onItemLoad);

  useEffect(() => {
    void get();
  }, [get]);

  const onSuccess = () => {
    onSuccessEdit();
    updateFiles(initialValues.registryId, objectType, media)
      .then(() => {
        backWithFallback(`${ReagentsSoilRootPath}/main-data`);
      })
      .catch((error: Error) => {
        onErrorEdit(error.message);
      });
  };

  const { put, isLoading: isSaveLoading } = useRequest(ReagentsSoilApiRoot, onSuccess, onErrorEdit);

  const initialValues = {
    ...initialEmptyValues,
    ...editedObject,
  };

  const onSubmit = useCallback(
    (values: typeof initialValues) => {
      put({
        ...values,
        values: splitTypesAndValues(values.values),
      });
    },
    [put],
  );

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    initialValues,
    onSubmit,
    validationSchema: PGRSoilSchema,
  });

  return (
    <FormikProvider
      value={{
        ...formik,
        errors: createTouchedErrors(formik.errors, formik.touched),
      }}
    >
      <FieldsForm
        dictionaries={dictionaries}
        onCancel={() => {
          backWithFallback(`${ReagentsSoilRootPath}/main-data`);
        }}
        isLoading={isDictionariesLoading || isItemLoading || isSaveLoading}
        isEditMode
        uploadItemId={id}
        onMediaChange={setMedia}
        media={media}
      />
    </FormikProvider>
  );
};
