import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import appLocale from 'constants/appLocale';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Tooltip,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

import { Box, Flex, useDisclosure } from '@chakra-ui/react';
import moment from 'moment';
import 'chartjs-adapter-moment';
import 'moment/locale/ru';
import ASKZAPrecipitationFilter, {
  IASKZAPrecipitationFilterForm,
} from 'pages/analyticsReports/CombinedCharts/ASKZAPrecipitation/ASKZAPrecipitationFilter';
import { Modal } from 'components/common';
import { useRequest } from 'hooks';
import { IFormattedGraphData, IGraphResponse, IGraphSettings } from 'pages/analyticsReports/CombinedCharts/types';
import { initialGraphOptionWithCustomFormat } from 'pages/analyticsReports/CombinedCharts/const';
import {
  formatGraphDataForRender,
  makeGraphOptions,
  makeInitialGraphSettings,
} from 'pages/analyticsReports/CombinedCharts/graphUtils';
import GraphSettingsForm from 'pages/analyticsReports/CombinedCharts/GraphSettingsForm';
import { GraphicDropdownMenu } from '../../../../components/common/GraphicDropdownMenu';

ChartJS.register(LinearScale, CategoryScale, BarElement, PointElement, LineElement, Legend, Tooltip);

moment.locale('ru');

const datasetAxisIndex = {
  0: 1,
  1: 2,
};
const maxPdkIndex = [1];
const ASKZAPrecipitation = () => {
  useUpdateBreadcrumbs([
    { breadcrumb: appLocale.subsystems.analyticsReports, path: '/reports-analytics' },
    { breadcrumb: appLocale.analytics.combinedCharts.title, path: '/reports-analytics/chart-combination' },
    {
      breadcrumb: appLocale.analytics.combinedCharts.askzaPrecipitation.title,
      path: '/reports-analytics/chart-combination/askza-precipitation',
    },
  ]);
  const [data, setData] = useState<{ datasets: IFormattedGraphData[] } | null>(null);
  const [options, setOptions] = useState(initialGraphOptionWithCustomFormat);
  const [graphSettings, setGraphSettings] = useState<IGraphSettings | null>(null);

  const chartRef = useRef<any>();

  const onClick = useCallback((event: any) => {
    // TODO: возможно тут в будущем будет переход на запись
    console.log(event);
  }, []);

  const settingsModalDisclosure = useDisclosure();

  const onSuccess = useCallback((graphResponse: IGraphResponse | null) => {
    if (!graphResponse) return;
    // set default graph settings from response
    setGraphSettings(
      makeInitialGraphSettings({
        graphResponse,
        maxPdkIndex,
      }),
    );
  }, []);

  const {
    post,
    result,
    isLoading: isGraphLoading,
  } = useRequest<IGraphResponse>('/reporting/v10/graphs/askza-precipitation', onSuccess);

  useEffect(() => {
    if (result && graphSettings) {
      setData({
        datasets: formatGraphDataForRender({ graphResponse: result, datasetAxisIndex, graphSettings, maxPdkIndex }),
      });
      // set graph options
      setOptions(
        makeGraphOptions({
          graphResponse: result,
          datasetAxisIndex,
          graphSettings,
          initialOptions: initialGraphOptionWithCustomFormat,
        }),
      );
    } else {
      setData(null);
      setOptions(initialGraphOptionWithCustomFormat);
    }
  }, [graphSettings, result]);

  const buildGraph = useCallback(
    (values: IASKZAPrecipitationFilterForm) => {
      setGraphSettings(null);
      setData(null);
      void post(values);
    },
    [post],
  );

  const chart = useMemo(() => {
    if (!data) return null;

    data.datasets[0].type = 'bar';

    return (
      <>
        <Line
          // @ts-ignore
          type="line"
          // @ts-ignore
          options={options}
          id="LineChart"
          data={data}
          ref={chartRef}
          getElementAtEvent={onClick}
          height="200px"
          width="200px"
        />
        <div id="legend-container" />
      </>
    );
  }, [data, onClick, options]);

  return (
    <Flex px="16px" flexDirection="column" height="calc(100vh - 60px - 32px)" position="sticky" top="60px">
      <ASKZAPrecipitationFilter onSubmit={buildGraph} isGraphLoading={isGraphLoading} />
      <Box>
        <GraphicDropdownMenu
          viewOnOpen={settingsModalDisclosure.onOpen}
          isDisabled={!result || !graphSettings}
          filename={appLocale.analytics.combinedCharts.askzaPrecipitation.title}
        />
      </Box>
      <Box flex={1} position="relative">
        {chart}
      </Box>

      {graphSettings && (
        <Modal isOpen={settingsModalDisclosure.isOpen} onClose={settingsModalDisclosure.onClose}>
          <GraphSettingsForm
            onClose={settingsModalDisclosure.onClose}
            setGraphSettings={setGraphSettings}
            datasetAxisIndex={datasetAxisIndex}
            graphSettings={{
              ...graphSettings,
              graphs: graphSettings.graphs.map((i, index) => {
                if (index === 0) {
                  return { ...i, type: 'bar' };
                }
                return i;
              })
            }}
          />
        </Modal>
      )}
    </Flex>
  );
};

export default ASKZAPrecipitation;
