import React, { useCallback, useEffect, useState } from 'react';
import appLocale from 'constants/appLocale';
import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import AnalyticsTableFilter from 'pages/analytics/components/AnalyticsTableFilter';
import { TFiltersArray } from 'models';
import { useList } from 'hooks/useList';
import { MaterialTable } from 'features/material-table/MaterialTableMini';
import AnalyticsTableColumns from 'pages/analytics/consts/AnalyticsTableColumns';
import { IAnalyticsItem } from 'pages/analytics/consts/types';
import { getPathsBySubsystemId, getSubsystemHumanReadableNameById } from 'pages/analytics/consts/AnalyticsTableRoutes';
import { useEnhancedNotification, useHandleDeleteData, useRequest } from 'hooks';
import { downloadFileFromResponse } from 'lib/utils/files/downloadFile';
import { ExtensionToMimeTypeEnum } from 'lib/utils/files/getFileExtensionByMimeType';
import { HeaderWithAnalytics } from 'pages/data/components/HeaderWithAnalytics';
import { useDictionary } from 'hooks/useDictionary';
import { DEFAULT_PAGE_SIZE_NEW_TABLE } from '../../../constants/tables';
import { FullPageWrapper } from '../../../components/table/FullPageWrapper';

interface Props {
  title: string;
  rootPath: string;
  subsystemId: string;
  mainDataTitle: string;
  analyticsDataTitle: string;
}

export const AnalyticsData = ({ title, rootPath, subsystemId, mainDataTitle, analyticsDataTitle }: Props) => {
  const { onDownloadError } = useEnhancedNotification();
  const [downloadReportItem, setDownloadReportItem] = useState<IAnalyticsItem>();

  const onFileResponseSuccess = useCallback(
    (response) => {
      if (response instanceof Blob && downloadReportItem) {
        downloadFileFromResponse({
          response,
          name: `Шаблон протокола_${getSubsystemHumanReadableNameById(downloadReportItem.subsystemId)}`,
        });
        setDownloadReportItem(undefined);
      }
    },
    [downloadReportItem],
  );

  const _onDownloadError = useCallback(() => {
    onDownloadError();
    setDownloadReportItem(undefined);
  }, [onDownloadError]);

  const { get: downloadReport } = useRequest<Blob>('', onFileResponseSuccess, _onDownloadError);

  useEffect(() => {
    if (downloadReportItem) {
      void downloadReport({
        url: getPathsBySubsystemId(downloadReportItem.subsystemId)
          ?.download(downloadReportItem.id),
        headers: { ACCEPT: ExtensionToMimeTypeEnum.docx },
        responseType: 'blob',
      });
    }
  }, [downloadReport, downloadReportItem]);

  useUpdateBreadcrumbs([{ breadcrumb: appLocale.subsystems.analyticsLab, path: '/analytics' }]);

  const { dictionaries } = useDictionary({ url: '/reporting/v10/laboratory/dictionaries' });

  const {
    refetch,
    isLoading,
    response,
    toggleOrder,
    setFilters,
    onPageChange,
    filterDTO,
    downloadAsFile,
    onSearchText,
    errorText,
    materialTableProps,
  } = useList<IAnalyticsItem>(
    '/reporting/v10/laboratory/list',
    {
      initialOrderFieldKey: '-date',
      preventFirstFetch: true,
      saveFiltersState: true,
      defaultPageSize: DEFAULT_PAGE_SIZE_NEW_TABLE,
      resetDataOnNextRequest: true,
    },
  );

  const { ModalComponent: ConfirmDeleteModal } = useHandleDeleteData({ refetch });

  const onReportDownload = useCallback(async (_e, item) => {
    if (!Array.isArray(item)) {
      setDownloadReportItem(item);
    }
  }, []);

  useEffect(() => {
    if (subsystemId) {
      setFilters([
        ...filterDTO.filters,
        {
          key: 'subsystemId',
          operation: 'EQ',
          value: subsystemId,
        },
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subsystemId, setFilters]);

  return (
    <FullPageWrapper>
      <HeaderWithAnalytics
        active="analytics-data"
        title={title}
        rootPath={rootPath}
        mainDataTitle={mainDataTitle}
        analyticsDataTitle={analyticsDataTitle}
      >
        <AnalyticsTableFilter
          isLoading={isLoading}
          isDisabled={isLoading}
          dictionaries={dictionaries?.data}
          withoutSubsystemFilter
          onSubmit={(values) => {
            const filters: TFiltersArray = Object.values(values);
            setFilters([...filters,
            {
              key: 'subsystemId',
              operation: 'EQ',
              value: subsystemId,
            }]);
          }}
          filters={filterDTO.filters}
        />
      </HeaderWithAnalytics>
      <MaterialTable
        filterDTO={filterDTO}
        data={response?.data}
        meta={response?.meta}
        columns={AnalyticsTableColumns}
        toggleOrder={toggleOrder}
        onPageChange={onPageChange}
        actions={[
          {
            type: 'download',
            onClick: onReportDownload,
            disabled: !!downloadReportItem,
          },
        ]}
        downloadAsFile={downloadAsFile}
        options={{
          search: true,
          showTitle: true,
          toolbar: true,
        }}
        isLoading={isLoading}
        onSearch={onSearchText}
        errorText={errorText}
        {...materialTableProps}
      />

      {ConfirmDeleteModal}
    </FullPageWrapper>
  );
};
