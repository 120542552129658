import { Select, SelectOption, SelectValue } from 'components/common';
import { FormikHandlers } from 'formik/dist/types';
import { Box } from '@chakra-ui/react';

const options: SelectOption[] = [
  { name: '10%', value: '0.1' },
  { name: '25%', value: '0.25' },
  { name: '50%', value: '0.5' },
  { name: '75%', value: '0.75' },
  { name: '100%', value: '1' },
];

interface ISelectPercent {
  value: SelectValue;
  onChange: FormikHandlers['handleChange'];
}
export const SelectPercent = ({ value, onChange }: ISelectPercent) => (
  <Box>
    <Select isCleared={false} value={value} onChange={onChange} options={options} />
  </Box>
);
