import { Box, Heading, Text } from '@chakra-ui/react';

export const ForbiddenPage = () => {
  console.warn('ошибка доступа');
  // страница временная, потом выпилим
  // todo
  return (
    <Box w="100%" p={4}>
      <Heading size="md">У вас нет доступа к данной странице</Heading>
      <Text>Обратитесь к администратору для уточнения деталей</Text>
    </Box>
  );
};
