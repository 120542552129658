import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import appLocale from 'constants/appLocale';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Flex, useDisclosure } from '@chakra-ui/react';
import moment from 'moment';
import 'chartjs-adapter-moment';
import 'moment/locale/ru';
import ASKZAInversionFilter, {
  IASKZAInversionFilterForm,
} from 'pages/analyticsReports/CombinedCharts/ASKZAInversion/ASKZAInversionFilter';
import { Modal } from 'components/common';
import { useRequest } from 'hooks';
import { IFormattedGraphData, IGraphPoint, IGraphResponse, IGraphSettings } from 'pages/analyticsReports/CombinedCharts/types';
import {
  formatGraphDataForRender,
  makeInitialGraphSettings,
} from 'pages/analyticsReports/CombinedCharts/graphUtils';
import GraphSettingsForm from 'pages/analyticsReports/CombinedCharts/GraphSettingsForm';
import ReactECharts from 'echarts-for-react';

import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Tooltip,
} from 'chart.js';
import { GraphicDropdownMenu } from 'components/common/GraphicDropdownMenu';

ChartJS.register(LinearScale, CategoryScale, BarElement, PointElement, LineElement, Legend, Tooltip);

moment.locale('ru');

const datasetAxisIndex = {
  0: 1,
  1: 2,
  2: 3,
};
const maxPdkIndex = [0];

const ASKZAInversion = () => {
  useUpdateBreadcrumbs([
    { breadcrumb: appLocale.subsystems.analyticsReports, path: '/reports-analytics' },
    { breadcrumb: appLocale.analytics.combinedCharts.title, path: '/reports-analytics/chart-combination' },
    {
      breadcrumb: appLocale.analytics.combinedCharts.askzaInversion.title,
      path: '/reports-analytics/chart-combination/askza-inversion',
    },
  ]);
  const [data, setData] = useState<{ datasets: IFormattedGraphData[] } | null>(null);
  const [graphSettings, setGraphSettings] = useState<IGraphSettings | null>(null);

  const settingsModalDisclosure = useDisclosure();

  const onSuccess = useCallback((graphResponse: IGraphResponse | null) => {
    if (!graphResponse) return;
    // set default graph settings from response
    setGraphSettings(
      makeInitialGraphSettings({
        graphResponse,
        maxPdkIndex,
      })
    );
  }, []);

  const {
    post,
    result,
    isLoading: isGraphLoading,
  } = useRequest<IGraphResponse>('/reporting/v10/graphs/askza-profilemer/inversion', onSuccess);

  useEffect(() => {
    if (result && graphSettings) {
      const axisIndexes: Record<number, number> = {};
      result.datasets.forEach((_, i) => {
        if (i > 1) {
          axisIndexes[i] = 3;
        } else {
          axisIndexes[i] = i + 1;
        }
      });
      result.axisTitles[2] = 'Высота';

      setData({
        datasets: formatGraphDataForRender({
          graphResponse: result,
          datasetAxisIndex: axisIndexes,
          graphSettings,
          maxPdkIndex,
        }),
      });
    } else {
      setData(null);
    }
  }, [graphSettings, result]);

  const buildGraph = useCallback(
    (values: IASKZAInversionFilterForm) => {
      setGraphSettings(null);
      setData(null);
      void post(values);
    },
    [post]
  );

  const barDataFromatted = useMemo(() => {
    const formatted: any[] = [];
    const barData = result?.datasets.slice(2);
    barData?.forEach((i: IGraphPoint[]) => {
      // @ts-ignore
      const d = i.map((p) => ([p.x[0], p.x[1], p.y[0], p.y[1], p.textIntensityValue, moment(p.x[0]).format('DD.MM.YYYY HH:mm'), moment(p.x[1]).format('DD.MM.YYYY HH:mm'), `${String(p.y[0])}‎`, `${String(p.y[1])}‎`]));
      formatted.push(d);
    });

    return formatted;
  }, [result]);

  const additionalSettings = useMemo(() => {
    const settings = {
      left: 10,
      right: 10,
      rotate: 45,
      offsets: [0, 0, 0]
    };
    let leftCount = 0;
    let rightCount = 0;
    graphSettings?.graphs.slice(0, 3).forEach((item, index) => {
      if (item.position === 'left') {
        leftCount += 1;
        settings.left += 30;
        if (index && leftCount) {
          settings.offsets[index] = (leftCount - 1) * 60;
        }
        return;
      }
      rightCount += 1;
      settings.right += 30;
      if (index && rightCount) {
        settings.offsets[index] = (rightCount - 1) * 60;
      }
    });
    if (settings.left < settings.right) {
      settings.rotate = -45;
    }
    return settings;
  }, [graphSettings?.graphs]);

  const option = useMemo(() => {
    if (!result) return null;
    const xMin = result?.axisSettings.x.min;
    const xMax = result?.axisSettings.x.max;
    const diff = Math.round((xMax - xMin) / 60 / 60 / 1000);

    let xTickCount = 30;
    // 2 дня
    if (diff <= 48) {
      xTickCount = 48;
    }
    // 1 день
    if (diff <= 24) {
      xTickCount = 24;
    }

    const barSeries = barDataFromatted.map((d, index) => {
      const settingsItem = graphSettings?.graphs.slice(2)[index];
      return {
        type: 'custom',
        name: settingsItem?.text,
        color: settingsItem?.color,
        renderItem: (params: any, api: any) => {
          const yValue = api.value(2);
          const yValue1 = api.value(3);
          const start = api.coord([api.value(0), yValue1]);
          const size = api.size([api.value(1) - api.value(0), yValue1 - yValue]);
          const style = api.style();

          return {
            type: 'rect',
            shape: {
              x: start[0],
              y: start[1],
              width: size[0],
              height: size[1]
            },
            style,
          };
        },
        label: {
          show: false,
        },
        dimensions: ['', '', '', '', '', 'От', 'До', 'Высота от', 'Высота до'],
        encode: {
          x: [0, 1],
          y: [2, 3],
          tooltip: [5, 6, 7, 8],
          itemName: 4
        },
        data: d,
        responsive: true,
        maintainAspectRatio: false,
        yAxisIndex: 2
      };
    });

    const lineSeries = [
      {
        type: 'line',
        data: result?.datasets[0].map((i) => (
          [i.x, i.y, graphSettings?.graphs[0].text, moment(i.x).format('DD.MM.YYYY HH:mm')]
        )),
        yAxisIndex: 0,
        dimensions: ['Время', 'Значение'],
        encode: {
          x: [0],
          y: [1],
          tooltip: [3],
          itemName: 1
        },
        color: graphSettings?.graphs[0].color,
        name: graphSettings?.graphs[0].text,
        lineStyle: {
          type: graphSettings?.graphs[0].form === 'dashed' ? 'dashed' : 'solid',
          width: graphSettings?.graphs[0].borderWidth
        },
        areaStyle: graphSettings?.graphs[0].form === 'filled' ? {} : null
      },
      {
        type: 'scatter',
        data: result?.datasets[1].map((i) => (
          [i.x, i.y, graphSettings?.graphs[1].text, moment(i.x).format('DD.MM.YYYY HH:mm')]
        )),
        yAxisIndex: 1,
        dimensions: ['Время', 'Значение'],
        encode: {
          x: [0],
          y: [1],
          tooltip: [3],
          itemName: 1,
        },
        color: graphSettings?.graphs[1].color,
        name: graphSettings?.graphs[1].text,
        symbolSize: 4,
      },
    ];

    const legend = graphSettings?.graphs.map((s) => s.text) || [];

    if (graphSettings?.graphs[0].maxPdkOption) {
      const pdkName = `${graphSettings?.graphs[0].text} ПДК`;
      lineSeries.push({
        type: 'line',
        // @ts-ignore
        data: result?.datasets?.[0].map((i) => (
          [i.x, result?.pdkData?.[0].y, pdkName, moment(i.x).format('DD.MM.YYYY HH:mm')]
        )),
        // @ts-ignore
        itemStyle: {
          opacity: 0 // скрыть точки
        },
        emphasis: {
          itemStyle: {
            opacity: 1 // отобразить точки при наведении
          }
        },
        yAxisIndex: 0,
        dimensions: ['Время', 'Значение'],
        encode: {
          x: [0],
          y: [1],
          tooltip: [3],
          itemName: 1
        },
        color: 'red',
        name: pdkName,
      });
      legend.push(pdkName);
    }

    return {
      grid: {
        left: additionalSettings.left,
        top: '40',
        right: additionalSettings.right,
        bottom: '10%',
        containLabel: true,
      },
      title: {
        text: graphSettings?.title,
        left: 'center'
      },
      legend: {
        bottom: 0,
        data: legend,
      },
      tooltip: {},
      xAxis: {
        type: 'time',
        splitLine: {
          show: true,
        },
        axisLabel: {
          rotate: additionalSettings.rotate,
          hideOverlap: true,
          formatter: (
            (value: any) => {
              let label = '';
              if (value) {
                label = moment(value).format('DD.MM.YYYY HH:mm');
              }
              return label;
            }
          )
        },
        min: result?.axisSettings.x.min,
        max: result?.axisSettings.x.max,
        splitNumber: xTickCount,
        offset: additionalSettings.offsets[0]
      },
      yAxis: [
        {
          type: 'value',
          name: graphSettings?.graphs[0].text,
          nameLocation: 'center',
          borderType: 'solid',
          nameTextStyle: {
            padding: [24, 0, 24, 0]
          },
          axisLine: {
            show: true,
          },
          position: graphSettings?.graphs[0].position,
        },
        {
          type: 'value',
          name: graphSettings?.graphs[1].text,
          position: graphSettings?.graphs[1].position,
          nameLocation: 'center',
          nameTextStyle: {
            padding: [16, 0, 16, 0]
          },
          offset: additionalSettings.offsets[1],
          alignTicks: true,
          axisLine: {
            show: true,
          },
        },
        {
          type: 'value',
          name: 'Высота',
          position: graphSettings?.graphs[2].position,
          nameLocation: 'center',
          nameTextStyle: {
            padding: [24, 0, 24, 0],
          },
          axisLine: {
            show: true,
          },
          axisTick: {
            interval: 2,
          },
          splitNumber: 10,
          min: result?.axisSettings.y[2].min,
          max: result?.axisSettings.y[2].max,
          axisLabel: {
            formatter: ((value: any) => String(value))
          },
          offset: additionalSettings.offsets[2]
        }
      ],
      series: [
        ...barSeries,
        ...lineSeries,
      ]
    };
  }, [result, barDataFromatted, graphSettings, additionalSettings]);

  return (
    <Flex px="16px" flexDirection="column" height="calc(100vh - 60px - 32px)" position="sticky" top="60px">
      <ASKZAInversionFilter onSubmit={buildGraph} isGraphLoading={isGraphLoading} />
      <Box>
        <GraphicDropdownMenu
          viewOnOpen={settingsModalDisclosure.onOpen}
          isDisabled={!result || !graphSettings}
          filename={appLocale.analytics.combinedCharts.askzaInversion.title}
        />
      </Box>

      {result && data && (
        <Box flex={1} position="relative" id="LineChart">
          <ReactECharts
            option={option}
            style={{ height: '100%', width: '100%' }}
          />
        </Box>
      )}

      {graphSettings && (
        <Modal isOpen={settingsModalDisclosure.isOpen} onClose={settingsModalDisclosure.onClose}>
          <GraphSettingsForm
            onClose={settingsModalDisclosure.onClose}
            setGraphSettings={setGraphSettings}
            graphSettings={{
              ...graphSettings,
              graphs: graphSettings.graphs.map((i, index) => {
                if (index === 1) {
                  return { ...i, type: 'scatter' };
                }
                if (index > 1) {
                  return { ...i, type: 'bar' };
                }
                return i;
              })
            }}
            datasetAxisIndex={datasetAxisIndex}
          />
        </Modal>
      )}
    </Flex>
  );
};

export default ASKZAInversion;
