import { Button, FormWrapper, Input, LoaderItem, Select } from 'components/common';
import { Box, Flex, Grid } from '@chakra-ui/react';
import { Field, useFormikContext } from 'formik';
import { baseAdminUrl } from 'pages/admin';
import React from 'react';
import { ReportItem } from '../../models/ReportItem';
import { SubsystemItem } from '../../models/SubsystemItem';
import { TOption } from '../../models/toption';
import { usePaginatedLoader } from '../../typedLoader';

export interface FieldFormProps {
  onCancel: () => void;
  isLoading: boolean;
}

const FieldsForm = ({ onCancel, isLoading }: FieldFormProps) => {
  const { handleChange, setFieldValue, values, errors, handleSubmit } = useFormikContext<ReportItem>();

  const { result: subsystems, isLoading: isSubsystemsLoading } = usePaginatedLoader<SubsystemItem>(
    `${baseAdminUrl}/subsystem`,
  );

  const subsystemOptions: TOption[] =
    subsystems?.data.map(
      (s: SubsystemItem) =>
        ({
          value: s.id,
          name: `${s.name} ${s.module ? `(${s.module})` : ''}`,
        } as TOption),
    ) || [];

  if (isLoading || isSubsystemsLoading) {
    return <LoaderItem />;
  }

  return (
    <Box flexDirection="column">
      <FormWrapper>
        <Grid marginTop="30px" gap="15px">
          <Field as={Input} name="code" label="Код" value={values.code} onChange={handleChange} error={errors.code} />
          <Field
            as={Input}
            name="name"
            label="Наименование"
            value={values.name}
            onChange={handleChange}
            error={errors.name}
          />
          <Field as={Input} name="link" label="Ссылка на отчет" onChange={handleChange} error={errors.link} />
          <Field
            as={Select}
            options={subsystemOptions}
            value={values.subsystemId}
            label="Подсистема"
            name="subsystem"
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              setFieldValue('subsystemId', e.target.value);
            }}
            error={errors.subsystemId}
          />
          <Flex>
            <Button onClick={onCancel} marginRight={20}>
              Отмена
            </Button>
            <Button onClick={() => handleSubmit()} variant="solid">
              Сохранить
            </Button>
          </Flex>
        </Grid>
      </FormWrapper>
    </Box>
  );
};

export default React.memo(FieldsForm);
