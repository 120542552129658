import { Redirect, useHistory } from 'react-router-dom';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { IUploadMedia } from 'components/common/FileUploadDragAndDrop/types';
import { FormikProvider, useFormik } from 'formik';
import { createTouchedErrors } from 'features/create-touched-errors';
import { ApiError } from 'models';
import { useEnhancedNotification, useNotifications, useRequest } from 'hooks';
import { useObjectType } from 'hooks/useObjectType';
import { formatErrorMessage } from 'lib/utils/formatErrorMessage';
import { ERROR_MESSAGES } from 'constants/messages';
import { updateFiles } from 'api/services/media';
import { LoaderItem } from 'components/common';
import { AnalyticsPelNoiseFormFields } from 'pages/analytics/Raids/PelNoise/AnalyticsPelNoiseFormFields';
import { IReportProtocolForm, ReportProtocolGetDto } from 'pages/analytics/Raids/types';
import { initialNoiseEmptyValues } from './initialNoiseEmptyValues';
import { pointCreator } from './Utils';
import { getSelectOptionsFromDictionaryByName } from 'features/get-select-options-from-dictionary';
import { IFormikPoint } from './types';
import { generateUUID } from 'lib/utils/generateUUID';
import { useGoBackWithFallback } from 'lib/utils/goBackWithFallback';
import { useDictionary } from 'hooks/useDictionary';

const AnalyticsPelNoiseAdd = () => {
  const [browserId] = useState(generateUUID());
  const { location } = useHistory<{ observationPointId: string; taskId: string }>();
  const { backWithFallback } = useGoBackWithFallback();
  const [media, setMedia] = useState<IUploadMedia[]>([]);

  const { isDictionariesLoading, objectType, dictionaries } = useDictionary({ url: '/appeal/v10/protocols/dictionaries' });
  const _objectType = useObjectType(objectType);

  const noiseParameters = useMemo(
    () => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'noiseParameters'),
    [dictionaries]
  );

  const meteoParameters = useMemo(
    () => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'alNoiseMeteoParameters'),
    [dictionaries]
  );

  const { onSuccessCreate } = useEnhancedNotification();
  const { errorNotify } = useNotifications();

  const onError = useCallback(
    (error: ApiError | string | null) => {
      const message = formatErrorMessage(error);
      errorNotify({
        key: message || 'file/add/error',
        message: message || ERROR_MESSAGES.CREATE,
      });
    },
    [errorNotify]
  );

  const onSuccess = (response: any | null) => {
    if (response) {
      onSuccessCreate();
      updateFiles(response.id, _objectType, media)
        .then(() => {
          backWithFallback('/analytics');
        })
        .catch((error: Error) => {
          onError(error.message);
        });
    }
  };

  const { post } = useRequest('', onSuccess, onError);

  const formOnSubmit = useCallback(
    (values: any) => {
      void post(
        {
          ...values,
          meteoValues: Object.values(values.meteoValues),
          points: values.points.map((point: IFormikPoint) => ({
            ...point,
            values: [...Object.values(point.values)],
          })),
        },
        { url: '/appeal/v10/protocols/pel/noise' }
      );
    },
    [post]
  );

  const initialMeteoValuesCreator = useMemo(() => {
    const initialObject: any = {};
    meteoParameters.forEach((meteoItem) => {
      initialObject[meteoItem.value] = {
        value: null,
        parameterId: meteoItem.value,
        type: meteoItem.type,
      };
    });
    return initialObject;
  }, [meteoParameters]);

  const useFormikProps = useMemo(
    () => ({
      enableReinitialize: true,
      validateOnChange: false,
      validateOnMount: false,
      validateOnBlur: false,
      initialValues: {
        ...initialNoiseEmptyValues,
        points: [pointCreator(noiseParameters, 1)],
        meteoValues: initialMeteoValuesCreator,
        ...location.state,
      },
      onSubmit: formOnSubmit,
    }),
    [noiseParameters, initialMeteoValuesCreator, location.state, formOnSubmit]
  );

  const formik = useFormik<IReportProtocolForm>(useFormikProps);

  const { get, result: protocolForPoint } = useRequest<ReportProtocolGetDto>('');
  useEffect(() => {
    if (formik.values.observationPointId) {
      void get({ url: `/appeal/v10/points/${formik.values.observationPointId}/pel/protocols` });
    }
  }, [formik.values.observationPointId, get]);

  if (protocolForPoint?.id) {
    return <Redirect to={`/analytics/raids/pel-noise/edit/${protocolForPoint.id}`} />;
  }

  if (isDictionariesLoading) {
    return <LoaderItem />;
  }

  return (
    <div>
      <FormikProvider
        value={{
          ...formik,
          errors: createTouchedErrors(formik.errors, formik.touched),
        }}
      >
        <AnalyticsPelNoiseFormFields
          dictionaries={dictionaries}
          media={media}
          onMediaChange={setMedia}
          objectType={_objectType}
          isLoading={isDictionariesLoading}
          browserId={browserId}
        />
      </FormikProvider>
    </div>
  );
};

export default AnalyticsPelNoiseAdd;
