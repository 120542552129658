import { useRequest } from 'hooks/useRequest';
import { useCallback, useEffect } from 'react';
import { defaultDataForMaps } from 'components/form/MapFormField';
import { IReportProtocolForm } from 'pages/analytics/Raids/types';

interface IUseMapControls {
  browserId: string;
  onScreenshotCallback?: (event: any) => void;
}
export const useMapControls = ({ browserId, onScreenshotCallback }:IUseMapControls) => {
  const { post } = useRequest('/mapsclient/v10/sendCommand');

  useEffect(() => {
    const onScreenShot = (event: any) => {
      if (onScreenshotCallback) {
        onScreenshotCallback(event.detail);
      }
    };

    window.document.addEventListener('map-export-image', onScreenShot, false);
    return () => {
      window.document.removeEventListener('map-export-image', onScreenShot, false);
    };
    // eslint-disable-next-line
  }, []);

  const startEditing = () => {
    void post({
      browserId,
      command: 'startEditing',
      data: {
        layerTemplateId: '1121430612',
      },
    });
  };

  const addFeature = (id: string, name: string) => {
    void post({
      browserId,
      command: 'addFeature',
      data: {
        layerTemplateId: '1121430612',
        geometryType: 'Point',
        feature: {
          type: 'Feature',
          properties: {
            id,
            name,
          },
        },
      },
    });
  };

  const sendCommand = useCallback(async (meta?: string) => {
    const data = meta ? { ...JSON.parse(meta), layerTemplateId: '1121430612' } : defaultDataForMaps;
    await post({
      browserId,
      command: 'query',
      data,
    });
  }, [browserId, post]);

  const pointExistsInMeta = useCallback((id: string, metaString?: string) => {
    try {
      const meta = metaString ? JSON.parse(metaString) : {};
      const point = meta.features?.find((point: any) => point.properties.id === id);
      return !!point;
    } catch (e) {
      return false;
    }
  }, []);

  const getScreenshot = useCallback(
    (meta: IReportProtocolForm['meta']) =>
      post(
        {
          browserId,
          command: 'screenshot',
          data: meta ? JSON.parse(meta) : defaultDataForMaps,
        },
        { url: '/mapsclient/v10/sendCommand' }
      ),
    [browserId, post]
  );

  return {
    addFeature,
    startEditing,
    sendCommand,
    pointExistsInMeta,
    getScreenshot
  };
};
