import { useRequest } from 'hooks';
import { MeteoDataApiRoot } from '../menu';
import { useEffect } from 'react';
import { TDictionariesArray } from 'models';

export function useNMUDictionaries() {
  const { get, isLoading, result, error } = useRequest<{ data: TDictionariesArray }>(
    `${MeteoDataApiRoot}/dictionaries/7`
  );

  useEffect(() => {
    get();
  }, [get]);

  return { isLoading, result, error };
}
