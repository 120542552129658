import { MaterialTable } from 'features/material-table/MaterialTableMini';
import { useUploadFileProvider } from 'features/UploadedFileProvider';
import { Column } from '@material-table/core';
import { useMemo } from 'react';
import { AisPogodaMeasure } from './types';

interface MeteoDataAisPogodaPreviewProps {
  typeId: string;
}

const MeteoDataAisPogodaPreview: React.FC<MeteoDataAisPogodaPreviewProps> = () => {
  const { preview } = useUploadFileProvider<AisPogodaMeasure>();

  const columns = useMemo<Column<AisPogodaMeasure>[]>(
    () => [
      {
        title: 'Дата и время',
        field: 'date',
        render: (row) => row.date,
      },
      { title: 'Станция', field: 'station.value' },
      { title: 'Точка градиента', field: 'gradientPoint.key', render: ((row) => row.gradientPoint?.key || '0') },
      { title: 'Параметр', field: 'parameter.value' },
      { title: 'Значение', field: 'value.key' },
    ],
    [],
  );

  return (
    <MaterialTable
      data={preview?.result?.measures}
      columns={columns}
    />
  );
};

export { MeteoDataAisPogodaPreview };
