import { BottomSoilTableFilters } from 'pages/data/Water/BottomSoil/BottomSoilTable/BottomSoilTableFilters';
import {
  useAvailability,
  useDisclosure,
  useEnhancedNotification,
  useHandleDeleteData,
  useList,
  useRequest,
} from 'hooks';
import { BottomSoilApiPaths } from 'pages/data/Water/BottomSoil/consts/apiPaths';
import { MaterialTable } from 'features/material-table/MaterialTableMini';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { bottomSoilTableColumns } from 'pages/data/Water/BottomSoil/BottomSoilTable/columns';
import { DeleteIcon, EditIcon, EyeViewIcon, ImageIcon } from 'components/icons';
import { WaterRoutesBottomSoil } from 'pages/data/Water/routes';
import { useHistory } from 'react-router-dom';
import { PropActions } from 'features/material-table/components/Actions';
import { IBottomSoilDTO } from 'pages/data/Water/BottomSoil/consts/types';
import useFilePreviewModalWrapperData from 'components/common/FilePreviewModal/hooks/useFilePreviewModalWrapperData';
import FilePreviewModalWrapper from 'components/common/FilePreviewModal/FilePreviewModalWrapper';
import appLocale from 'constants/appLocale';
import { Column } from '@material-table/core';
import { addFiltersToColumns } from 'lib/utils/tableFilter';
import { usePeriodsDict } from 'features/PeriodsButtons/usePeriodsDict';
import subsystemIdsMap from 'constants/subsystemIdsMap';
import { downloadFileFromResponse } from 'lib/utils/files/downloadFile';
import { getSubsystemHumanReadableName, SubsystemName } from 'pages/analytics/consts/AnalyticsTableRoutes';
import { IAnalyticsItem } from 'pages/analytics/consts/types';
import { ExtensionToMimeTypeEnum } from 'lib/utils/files/getFileExtensionByMimeType';
import { HeaderWithAnalytics } from 'pages/data/components/HeaderWithAnalytics';
import { Modal } from 'components/common';
import { UploadFileModalContent } from 'features/UploadFileDragAndDrop/UploadFileModalContent';
import { useDictionary } from 'hooks/useDictionary';
import { getSelectOptionsFromDictionaryByName } from 'features/get-select-options-from-dictionary';
import { DEFAULT_PAGE_SIZE_NEW_TABLE } from '../../../../../constants/tables';
import { FullPageWrapper } from '../../../../../components/table/FullPageWrapper';

export const BottomSoilTable = () => {
  const history = useHistory();
  const { dictionaries, isDictionariesLoading } = useDictionary({ url: BottomSoilApiPaths.dictionaries });
  const regions = useMemo(
    () => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'regions'),
    [dictionaries?.data],
  );
  const organizations = useMemo(
    () => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'organizations'),
    [dictionaries?.data],
  );
  const waterObjects = useMemo(
    () => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'waterObjects'),
    [dictionaries?.data],
  );
  const sources = useMemo(
    () => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'sources'),
    [dictionaries?.data],
  );
  const districts = useMemo(
    () => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'districts'),
    [dictionaries?.data],
  );
  const [downloadReportItem, setDownloadReportItem] = useState<IAnalyticsItem>();
  const { onDownloadError } = useEnhancedNotification();
  const { isWrite, isExecute, isNoRights, allAvailable, isReadOnly, isDelete } = useAvailability(
    subsystemIdsMap.bottomSoil,
  );
  const onFileResponseSuccess = useCallback(
    (response) => {
      if (response instanceof Blob && downloadReportItem) {
        downloadFileFromResponse({
          response,
          name: `Шаблон протокола_${getSubsystemHumanReadableName(SubsystemName.waterBottomSoil)}`,
        });
        setDownloadReportItem(undefined);
      }
    },
    [downloadReportItem],
  );

  const _onDownloadError = useCallback(() => {
    onDownloadError();
    setDownloadReportItem(undefined);
  }, [onDownloadError]);

  const { get: downloadReport } = useRequest<Blob>('', onFileResponseSuccess, _onDownloadError);

  useEffect(() => {
    if (downloadReportItem) {
      void downloadReport({
        url: `/api/reporting/v10/templates/fill/bottomsoil/${downloadReportItem.id}`,
        headers: { ACCEPT: ExtensionToMimeTypeEnum.docx },
        responseType: 'blob',
      });
    }
  }, [downloadReport, downloadReportItem]);

  const {
    toggleOrder,
    onPageChange,
    response,
    setFilters,
    filterDTO,
    refetch,
    isLoading: isItemLoading,
    downloadAsFile,
    onSearchText,
    saveFilters,
    errorText,
    selectedIndexes,
    renderOnlySelectedCheckbox,
    onSubmitFilters,
    onSelectionChange,
    materialTableProps,
  } = useList<IBottomSoilDTO, keyof IBottomSoilDTO>(BottomSoilApiPaths.list, {
    initialOrderFieldKey: '-protocolDate',
    exportSubsystemName: 'bottom',
    preventFirstFetch: true,
    saveFiltersState: true,
    selectionKey: 'id',
    defaultPageSize: DEFAULT_PAGE_SIZE_NEW_TABLE,
    resetDataOnNextRequest: true,
  });

  const { ModalComponent, handleOpenModal } = useHandleDeleteData({ refetch });

  const { open, ...filePreviewData } = useFilePreviewModalWrapperData();

  const isLoading = isDictionariesLoading || isItemLoading;

  const onReportDownload = useCallback(async (_e, item) => {
    if (!Array.isArray(item)) {
      setDownloadReportItem(item);
    }
  }, []);

  const actions = useMemo<PropActions<IBottomSoilDTO[]>>(
    () => [
      {
        position: 'row',
        action: (row) => ({
          icon: () => <EditIcon />,
          onClick: (_e, item: any) => {
            if (!Array.isArray(item)) {
              if (!item) return;
              history.push(`${WaterRoutesBottomSoil.edit(item.id)}`);
            }
          },
          hidden: row.laboratory === true || !isWrite || !row.isEditable,
        }),
      },
      {
        position: 'row',
        action: (row) => ({
          icon: () => <DeleteIcon />,
          onClick: (_e, item: any) => {
            if (!Array.isArray(item)) {
              handleOpenModal({ url: BottomSoilApiPaths.getOrDelete(item.id) });
            }
          },
          hidden: row.laboratory === true || !isDelete || !row.isEditable,
        }),
      },
      {
        position: 'row',
        action: (row) => ({
          icon: () => <ImageIcon />,
          onClick: () => open(row.id, row.objectType),
          tooltip: 'Показать изображения',
          hidden: !row.objectType || row.laboratory === true,
        }),
      },
      {
        position: 'row',
        action: (row) => ({
          icon: () => <EyeViewIcon />,
          onClick: onReportDownload,
          tooltip: 'Скачать',
          hidden: row.laboratory === null || row.laboratory === false,
        }),
      },
    ],
    [isWrite, history, isDelete, handleOpenModal, open, onReportDownload],
  );

  const columnsWithFilters = useMemo<Array<Column<IBottomSoilDTO>>>(
    () => addFiltersToColumns({ filters: filterDTO.filters, setFilters, columns: bottomSoilTableColumns }),
    [filterDTO.filters, setFilters],
  );

  const { result: periods } = usePeriodsDict(`auth/v10/user/period/${subsystemIdsMap.bottomSoil}`);
  const { isOpen: isOpenLoad, onClose: onCloseLoad, onOpen: onOpenLoad } = useDisclosure();

  return (
    <FullPageWrapper>
      <HeaderWithAnalytics
        active="main-data"
        title={appLocale.bottomSoil.title}
        rootPath={WaterRoutesBottomSoil.root}
        mainDataTitle="Протоколы сторонних организаций"
        analyticsDataTitle="Протоколы аналитической лаборатории"
        addButtonProps={{
          onClick: () => history.push(WaterRoutesBottomSoil.add),
          isDisabled: !isWrite,
        }}
        onOpenLoad={onOpenLoad}
        allAvailable={allAvailable}
        isWrite={isWrite}
        isReadonly={isReadOnly}
        isExecute={isExecute}
        isNoRights={isNoRights}
      >
        <BottomSoilTableFilters
          filters={filterDTO?.filters || []}
          periods={periods}
          isLoading={isLoading}
          isDisabled={isLoading}
          onSubmit={(FilterValues) => {
            onSubmitFilters(Object.values(FilterValues));
          }}
          regions={regions}
          organizations={organizations}
          waterObjects={waterObjects}
          sources={sources}
          districts={districts}
        >
          {renderOnlySelectedCheckbox}
        </BottomSoilTableFilters>
      </HeaderWithAnalytics>
      <MaterialTable
        isNotExecute={isExecute}
        saveFilters={saveFilters}
        toggleOrder={toggleOrder}
        onPageChange={onPageChange}
        filterDTO={filterDTO}
        data={response?.data}
        meta={response?.meta}
        columns={columnsWithFilters}
        actions={actions}
        options={{
          search: true,
        }}
        isLoading={isLoading}
        onSearch={onSearchText}
        downloadAsFile={downloadAsFile}
        selectedIndexes={selectedIndexes}
        onSelectionChange={onSelectionChange}
        errorText={errorText}
        {...materialTableProps}
        showTotalCount
      />

      {ModalComponent}

      <FilePreviewModalWrapper {...filePreviewData} />

      <Modal isOpen={isOpenLoad} onClose={onCloseLoad} header="Загрузка данных из файла">
        <UploadFileModalContent
          createTypesPath={() => '/water/v10/water/bottomsoil/types'}
          createUploadPath={() => '/water/v10/water/bottomsoil/apply'}
          onClose={onCloseLoad}
          refetch={refetch}
        />
      </Modal>
    </FullPageWrapper>
  );
};
