import { useMemo } from 'react';
import { Button, DefaultText, FieldsFormControls, Select } from 'components/common';
import { Field, FieldArray, useFormikContext } from 'formik';
import { ISubstanceValues, pdkLocales } from 'pages/dictionaries/NSIPdk/consts';
import { AddIcon, DeleteIcon } from 'components/icons';
import { getSelectOptionsFromDictionaryByName } from 'features/get-select-options-from-dictionary';
import { TDictionariesArray } from 'models/dictionaries';
import { Box, Flex, Grid } from '@chakra-ui/react';

interface Props {
  dictionaries?: TDictionariesArray;
  onCancel: () => void;
}

export const SubstanceParameters = ({ dictionaries, onCancel }: Props) => {
  const { handleSubmit, values, setFieldValue } = useFormikContext<ISubstanceValues>();

  const parameters = useMemo(() => {
    const parameterForChemicals = getSelectOptionsFromDictionaryByName(dictionaries, 'parameterForChemicals');
    return parameterForChemicals;
  }, [dictionaries]);

  return (
    <Grid width="60%" gap={3}>
      <DefaultText fontWeight="bold">
        {pdkLocales.common.paramsAdding}
      </DefaultText>
      <FieldArray name="paramId">
        {
          ({ remove, push }) => (
            <Box flexDirection="column">
              {
                values.paramId && values.paramId.map((value, idx) => (
                  <Flex key={idx} marginBottom={3}>
                    <Field
                      as={Select}
                      name={`paramValue[${idx}]`}
                      options={parameters}
                      label={pdkLocales.measuringParam}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        const value = parameters.find((param) => param.value === e.target.value)?.name;
                        setFieldValue(`paramValue[${idx}]`, value);
                        setFieldValue(`paramId[${idx}]`, e.target.value);
                        setFieldValue(`chemDesc[${idx}]`, value);
                      }}
                    />
                    <Button
                      leftIcon={<DeleteIcon />}
                      onClick={() => {
                        const paramIds = values.paramId
                          .filter((_, index) => index !== idx);
                        setFieldValue('paramId', paramIds);
                        remove(idx);
                      }}
                      variant="ghost"
                    />
                  </Flex>
                ))
              }
              <Button
                onClick={() => push('')}
                leftIcon={<AddIcon />}
                variant="ghost"
                textColor="#208CFF"
              >
                {pdkLocales.common.addMeasurmentParam}
              </Button>
            </Box>
          )
        }
      </FieldArray>
      <Box width="50%">
        <FieldsFormControls
          onCancel={onCancel}
          handleSubmit={handleSubmit}
          showSave
        />
      </Box>
    </Grid>
  );
};
