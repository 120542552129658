import { IFilterValues } from 'components/common';
import { ConfirmModal, Modal } from 'components/common/Modals';
import { ERROR_MESSAGES, REMOVE_CONFIRMATION, SUCCESS_MESSAGES } from 'constants/messages';
import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import { getSelectOptionsFromDictionaryByName } from 'features/get-select-options-from-dictionary';
import { MaterialTable } from 'features/material-table/MaterialTableMini';
import { useAvailability, useDisclosure, useEnhancedNotification } from 'hooks';
import { useNotifications } from 'hooks/useNotifications';
import { useRequest } from 'hooks/useRequest';
import { createLocalDateDDMMYYYY } from 'lib/create-date';
import { filter, map } from 'lodash';
import { TFiltersArray } from 'models';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { COMMON_LOCALE } from 'constants/common';
import { useList } from 'hooks/useList';
import appLocale from 'constants/appLocale';
import { SoilFilter } from './components';
import {
  ReagentsApiRootPath,
  ReagentsRootPath,
  ReagentsSoilApiDictionaries,
  ReagentsSoilApiList,
  ReagentsSoilApiRoot,
  ReagentsSoilRootPath,
} from '../consts';
import { fieldsLocale, SoilDeicingItem } from './consts';
import useFilePreviewModalWrapperData from 'components/common/FilePreviewModal/hooks/useFilePreviewModalWrapperData';
import FilePreviewModalWrapper from 'components/common/FilePreviewModal/FilePreviewModalWrapper';
import { DeleteIcon, EditIcon, EyeViewIcon, ImageIcon } from 'components/icons';
import { Column } from '@material-table/core';
import { addFiltersToColumns, ColumnWithFilter } from 'lib/utils/tableFilter';
import { TableFilterTypeEnum } from 'components/table/filters/TableFilter';
import { UploadFileModalContent } from 'features/UploadFileDragAndDrop/UploadFileModalContent';
import subsystemIdsMap from 'constants/subsystemIdsMap';
import { PropActions } from 'features/material-table/components/Actions';
import { usePeriodsDict } from 'features/PeriodsButtons/usePeriodsDict';
import { downloadFileFromResponse } from 'lib/utils/files/downloadFile';
import { getSubsystemHumanReadableName, SubsystemName } from 'pages/analytics/consts/AnalyticsTableRoutes';
import { IAnalyticsItem } from 'pages/analytics/consts/types';
import { ExtensionToMimeTypeEnum } from 'lib/utils/files/getFileExtensionByMimeType';
import { HeaderWithAnalytics } from 'pages/data/components/HeaderWithAnalytics';
import { CellWithType } from 'components/table/CellWithType';
import { useDictionary } from 'hooks/useDictionary';
import { DEFAULT_PAGE_SIZE_NEW_TABLE } from '../../../../constants/tables';
import { FullPageWrapper } from '../../../../components/table/FullPageWrapper';
import { DefaultTableCellSize } from 'components/table/DefaultTableCellSize';

const Soil: React.FC = memo(() => {
  const { isWrite, isExecute, isNoRights, allAvailable, isReadOnly } = useAvailability(subsystemIdsMap.pgrSoil);

  const history = useHistory();
  const [downloadReportItem, setDownloadReportItem] = useState<IAnalyticsItem>();
  const { onDownloadError } = useEnhancedNotification();
  const onFileResponseSuccess = useCallback(
    (response) => {
      if (response instanceof Blob && downloadReportItem) {
        downloadFileFromResponse({
          response,
          name: `Шаблон протокола_${getSubsystemHumanReadableName(SubsystemName.reagentsSoil)}`,
        });
        setDownloadReportItem(undefined);
      }
    },
    [downloadReportItem],
  );

  const _onDownloadError = useCallback(() => {
    onDownloadError();
    setDownloadReportItem(undefined);
  }, [onDownloadError]);

  const { get: downloadReport } = useRequest<Blob>('', onFileResponseSuccess, _onDownloadError);

  useEffect(() => {
    if (downloadReportItem) {
      void downloadReport({
        url: `/api/reporting/v10/templates/fill/soil/${downloadReportItem.registryId}`,
        headers: { ACCEPT: ExtensionToMimeTypeEnum.docx },
        responseType: 'blob',
      });
    }
  }, [downloadReport, downloadReportItem]);

  useUpdateBreadcrumbs([
    { breadcrumb: COMMON_LOCALE.data, path: '/data' },
    {
      breadcrumb: appLocale.subsystems.reagents,
      path: ReagentsRootPath,
    },
    {
      breadcrumb: 'Воздействие на почву',
      path: `${ReagentsSoilRootPath}/main-data`,
    },
  ]);

  const { dictionaries } = useDictionary({ url: ReagentsSoilApiDictionaries });
  const [removedItems, setRemovedItems] = useState('');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { successNotify, errorNotify } = useNotifications();
  const { open, ...filePreviewData } = useFilePreviewModalWrapperData();

  const { isOpen: isOpenLoad, onClose: onCloseLoad, onOpen: onOpenLoad } = useDisclosure();

  const {
    refetch,
    isLoading: isSoilListLoading,
    response,
    setFilters,
    toggleOrder,
    onPageChange,
    filterDTO,
    downloadAsFile,
    saveFilters,
    onSearchText,
    errorText,
    selectedIndexes,
    renderOnlySelectedCheckbox,
    onSubmitFilters,
    onSelectionChange,
    materialTableProps,
  } = useList<SoilDeicingItem, keyof SoilDeicingItem>(ReagentsSoilApiList, {
    initialOrderFieldKey: '-selectionDate',
    preventFirstFetch: true,
    exportSubsystemName: 'pgr_soil',
    saveFiltersState: true,
    selectionKey: 'registryId',
    defaultPageSize: DEFAULT_PAGE_SIZE_NEW_TABLE,
    resetDataOnNextRequest: true,

  });

  const onSuccess = () => {
    onClose();
    refetch();
    successNotify({
      key: 'file/delete/success',
      message: SUCCESS_MESSAGES.DELETE,
    });
  };

  const onError = () =>
    errorNotify({
      key: 'file/delete/error',
      message: ERROR_MESSAGES.DELETE,
    });

  const { remove } = useRequest('/atmosphere/v10/atmosphere', onSuccess, onError);

  const dictData = dictionaries?.data;
  const parameters = useMemo(() => getSelectOptionsFromDictionaryByName(dictData, 'parameters'), [dictData]);

  const [filtersFromGraphic, setFiltersFromGraphic] = useState<Partial<IFilterValues> | null>(null);
  const columns = useMemo<Array<ColumnWithFilter<SoilDeicingItem>>>(
    () => [
      {
        title: fieldsLocale.selectionDate,
        field: 'selectionDate',
        render: (row) => createLocalDateDDMMYYYY(row.selectionDate),
      },
      {
        title: fieldsLocale.protocolNumber,
        field: 'protocol',
        filterType: TableFilterTypeEnum.string,
      },
      {
        title: fieldsLocale.territoryType,
        field: 'territoryType',
        filterType: TableFilterTypeEnum.string,
      },
      {
        title: fieldsLocale.territoryNumber,
        field: 'sourceName',
      },
      {
        title: fieldsLocale.region,
        field: 'okrug',
        filterType: TableFilterTypeEnum.string,
      },
      {
        title: fieldsLocale.district,
        field: 'rayon',
        filterType: TableFilterTypeEnum.string,
      },
      {
        title: fieldsLocale.address,
        field: 'address',
        filterType: TableFilterTypeEnum.string,
        render: (row) => <DefaultTableCellSize>{row.address}</DefaultTableCellSize>,
      },
      {
        title: fieldsLocale.distance2Road,
        field: 'distance2Road',
        filterType: TableFilterTypeEnum.string,
      },
      {
        title: fieldsLocale.depth,
        field: 'depth',
        filterType: TableFilterTypeEnum.string,
      },

      ...map(parameters, ({ title, parameterId }) => ({
        title,
        field: parameterId,
        render: (row: SoilDeicingItem) => {
          const item = filter(row.values, (el) => el.parameterId === parameterId)[0];
          if (!item) {
            return null;
          }
          return <CellWithType type={item.type} value={item.value} />;
        },
        filterType: TableFilterTypeEnum.number,
      })),
    ],
    [parameters],
  );

  const columnsWithFilters = useMemo<Array<Column<SoilDeicingItem>>>(
    () => addFiltersToColumns({ filters: filterDTO.filters, setFilters, columns }),
    [columns, filterDTO.filters, setFilters],
  );

  const onReportDownload = useCallback(async (_e, item) => {
    if (!Array.isArray(item)) {
      setDownloadReportItem(item);
    }
  }, []);

  const initialActions = useMemo<PropActions<SoilDeicingItem[]>>(
    () => [
      {
        position: 'row',
        action: (row: SoilDeicingItem) => ({
          icon: () => <EditIcon />,
          onClick: () => history.push(`${ReagentsRootPath}/soil/edit/row/${row.registryId}`),
          tooltip: 'Редактировать',
          hidden: !row.isEditable,
        }),
      },
      {
        position: 'row',
        action: (row: SoilDeicingItem) => ({
          icon: () => <DeleteIcon />,
          onClick: () => {
            onOpen();
            setRemovedItems(row.registryId);
          },
          tooltip: 'Удалить',
          hidden: !row.isEditable,
        }),
      },
      {
        position: 'row',
        action: (row: any) => ({
          icon: () => <ImageIcon />,
          onClick: () => open(row.registryId, row.objectType),
          tooltip: 'Показать изображения',
          hidden: !row.objectType || row.laboratory === true,
        }),
      },
      {
        position: 'row',
        action: (row) => ({
          icon: () => <EyeViewIcon />,
          onClick: onReportDownload,
          tooltip: 'Скачать',
          hidden: row.laboratory === null || row.laboratory === false,
        }),
      },
    ],
    [history, onOpen, open, onReportDownload],
  );

  // @ts-ignore
  const actions = useMemo<PropActions<any[]>>(() => {
    if (allAvailable || isNoRights || isWrite) {
      return initialActions;
    }
    if (!(allAvailable || isNoRights || isWrite)) {
      return initialActions.filter((action: any) => action.type !== 'delete');
    }
  }, [isWrite, initialActions, isNoRights, allAvailable]);

  const { result: periods } = usePeriodsDict(`auth/v10/user/period/${subsystemIdsMap.pgrSoil}`);

  const isAvailable = allAvailable || isNoRights || isWrite;
  return (
    <FullPageWrapper>
      <HeaderWithAnalytics
        allAvailable={allAvailable}
        isWrite={isWrite}
        isReadonly={isReadOnly}
        isExecute={isExecute}
        isNoRights={isNoRights}
        active="main-data"
        title="Мониторинг воздействия ПГР на почву"
        rootPath={ReagentsSoilRootPath}
        mainDataTitle="Данные"
        analyticsDataTitle="Протоколы аналитической лаборатории"
        addButtonProps={{
          onClick: () => history.push(`${ReagentsSoilRootPath}/add`),
          isDisabled: !isAvailable,
        }}
        onOpenLoad={onOpenLoad}
      >
        <SoilFilter
          periods={periods}
          filtersFromGraphic={filtersFromGraphic}
          setFiltersFromGraphic={setFiltersFromGraphic}
          isLoading={isSoilListLoading}
          isDisabled={isSoilListLoading}
          dictionaries={dictionaries}
          onSubmit={(FilterValues) => {
            const filters: TFiltersArray<keyof SoilDeicingItem> = Object.values(FilterValues);
            onSubmitFilters(filters);
          }}
          filters={filterDTO.filters}
        >
          {renderOnlySelectedCheckbox}
        </SoilFilter>
      </HeaderWithAnalytics>
      <Modal isOpen={isOpenLoad} onClose={onCloseLoad} header="Загрузка данных из файла">
        <UploadFileModalContent
          createTypesPath={() => `${ReagentsApiRootPath}/soil/load/types`}
          createUploadPath={() => `${ReagentsApiRootPath}/soil/load`}
          types={[
            {
              name: 'Данные ПГР (почва)',
              value: 'soil',
            },
          ]}
          onClose={onCloseLoad}
          refetch={refetch}
        />
      </Modal>

      <MaterialTable
        isNotExecute={isExecute || isNoRights}
        data={response?.data}
        meta={response?.meta}
        toggleOrder={toggleOrder}
        onPageChange={onPageChange}
        filterDTO={filterDTO}
        columns={columnsWithFilters}
        actions={actions}
        options={{
          search: true,
        }}
        onSearch={onSearchText}
        isLoading={isSoilListLoading}
        downloadAsFile={downloadAsFile}
        saveFilters={saveFilters}
        setFilters={setFilters}
        errorText={errorText}
        selectedIndexes={selectedIndexes}
        onSelectionChange={onSelectionChange}
        {...materialTableProps}
        showTotalCount
      />

      <ConfirmModal
        isOpen={isOpen}
        onClose={onClose}
        onClick={() =>
          remove({
            url: `${ReagentsSoilApiRoot}/${removedItems}`,
          })
        }
        header={REMOVE_CONFIRMATION}
      />

      <FilePreviewModalWrapper {...filePreviewData} />
    </FullPageWrapper>
  );
});

export { Soil };
