import React, { memo } from 'react';
import { Disclosure } from 'hooks';
import { Button } from 'components/common';
import { Flex, Modal, ModalContent, ModalHeader, ModalOverlay } from '@chakra-ui/react';
import { LightModeWrapper } from '../LightModeWrapper/LightModeWrapper';

interface ConfirmModalProps {
  isOpen: Disclosure['isOpen'];
  onClose: Disclosure['onClose'];
  header?: string;
  isLoading?: boolean;
  onClick: () => void;
  confirmButtonText?: string;
  confirmButtonColorScheme?: string;
  isDisabled?: boolean
}

export const ConfirmModal: React.FC<ConfirmModalProps> = memo(
  ({
    isOpen, onClose, onClick, header = '', isLoading = false, confirmButtonText = 'Подтвердить',
    confirmButtonColorScheme = 'ErrorButton', isDisabled
  }) => (
    <Modal isOpen={isOpen} onClose={onClose} size="sm" isCentered allowPinchZoom={false}>
      <LightModeWrapper>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{header}</ModalHeader>
          <Flex p={6} justifyContent="space-between">
            <Button
              onClick={(e) => {
                e.stopPropagation();
                onClose();
              }}
              size="sm"
              variant="outline"
              color="PrimaryButton.500"
              isDisabled={isLoading}
              isLoading={isLoading}
            >
              Отмена
            </Button>
            <Button
              size="sm"
              variant="solid"
              colorScheme={confirmButtonColorScheme}
              isLoading={isLoading}
              onClick={(e) => {
                e.stopPropagation();
                onClick();
              }}
              isDisabled={isDisabled}
            >
              {confirmButtonText}
            </Button>
          </Flex>
        </ModalContent>
      </LightModeWrapper>
    </Modal>
  ),
);
