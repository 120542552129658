import { SelectedCheckboxIcon, UnselectedCheckboxIcon } from 'components/icons';
import { cloneDeep } from 'lodash';
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ListResponseMeta } from 'models/api/list';
import { DEFAULT_PAGE_OPTIONS, DEFAULT_PAGE_SIZE } from 'constants/tables';
import { IWithFileDownload, useDisclosure } from 'hooks';
import tableLocalization, {
  getEmptyDataSourceMessage,
} from 'features/material-table/components/const/tableLocalization';
import MaterialTableCore, {
  Column,
  MaterialTableProps as MaterialTableCoreProps,
  MTableCell,
  MTableHeader,
  Options,
} from '@material-table/core';
import { Toolbar, ToolBarAction } from '../material-table/components/Toolbar';
import { createTableActions, PropActions } from '../material-table/components/Actions';
import { ExtensionToMimeTypeEnum } from 'lib/utils/files/getFileExtensionByMimeType';
import tableIcons from 'features/material-table/components/const/tableIcons';
import { IFilterItem, TFilterDTO } from 'models';
import { makeStyles } from '@material-ui/core/styles';
import sortDefault from '../material-table/icons/sortDefault.svg';
import sortDesc from 'features/material-table/icons/sortDesc.svg';
import Pagination from 'features/material-table/components/Pagination/Pagination';

interface MaterialTableProps<Data extends any[], Columns extends Array<Column<Data[number]>>>
  extends Omit<MaterialTableCoreProps<Data[number]>, 'data' | 'actions'> {
  columns: Columns;
  meta?: ListResponseMeta; // TODO: should be required?
  onPageChange?: (page: number, pageSize: number) => void;
  onSelectionChange?: (data: Data[number][], rowData?: Data[number] | undefined) => void;
  downloadAsFile?: IWithFileDownload;
  getHeaders?: (type: ExtensionToMimeTypeEnum, headersXLSXUrl?: string) => Promise<any>;
  headersXLSXUrl?: string;
  data?: Data;
  actions?: PropActions<Data>;
  topBarLeftContent?: React.ReactNode;
  toggleOrder?: (fieldKey: string) => void;
  detailPanel?: (rowData: any) => React.ReactNode;
  selectedIndexes?: number[];
  saveFilters?: () => void;
  setFilters?: (filters: IFilterItem<Data[number]>[]) => void;
  options?: Options<Data>;
  onSearch?: (searchFields: string[], searchText: string) => void;
  downloadTemplate?: string[];
  downloadCSV?: (type: ExtensionToMimeTypeEnum) => void;
  filterDTO?: TFilterDTO;
  toolbarActions?: ToolBarAction[];
  fieldsToSearch?: string[];
  isNotExecute?: boolean;
  onOpenUploadModal?: () => void;
  removeChecked?: () => void;
  showTotalCount?: boolean;
  errorText?: string;
  onColumnHide?: (column: string, hidden: boolean) => void;
  hiddenColumns?: string[];
  isWrite?: boolean;
}

const rowLineHeight = 14;

// sorting icons styles
const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
    minWidth: '1400px',
    '& .MuiTableSortLabel-root': {
      flexDirection: 'row-reverse',
    },
    '& .MuiTableSortLabel-icon': {
      opacity: 1,
      backgroundImage: `url(${sortDefault})`,
      width: 17,
      height: 17,
      margin: 0,
      position: 'absolute',
      left: '-16px',
    },
    '& .MuiTableSortLabel-active .MuiTableSortLabel-icon': {
      backgroundImage: `url(${sortDesc})`,
    },
    '& .MuiIconButton-colorPrimary:hover, & .MuiIconButton-colorPrimary.Mui-checked:hover': {
      backgroundColor: 'inherit',
    },
    // to disable actions printing
    '@media print': {
      '& .MuiTableCell-paddingNone, &.MuiTableCell-paddingCheckbox': {
        display: 'none !important',
      },
    },
    // scrollbars and fixed header
    '& .MuiPaper-root': {
      padding: '0 8px',
      '& thead': {
        position: 'sticky',
        top: 0,
        zIndex: 1,
      },
      '& > div:nth-child(3)': {
        display: 'flex',
        flexDirection: 'column',
        minHeight: 0,
        flexGrow: 1,
      },
      '& > div:nth-child(3) > div:last-child': {
        '@media print': {
          maxHeight: 'initial'
        },
        minHeight: '600px',
        overflowY: 'auto !important',
        flexGrow: 1,
        padding: '0',
      },
      '& > div:nth-child(2) > div': {
        lineHeight: 0,
      },
      '& > div:nth-child(3) > div': {
        lineHeight: 0,
        '&::-webkit-scrollbar': {
          height: 10,
        },
        '&::-webkit-scrollbar-thumb': {
          background: '#E2E8F0',
        },
      },
      fontSize: `${rowLineHeight - 2}px`,
      lineHeight: `${rowLineHeight}px`,
    },
    '& .MuiTableCell-root.MuiTableCell-head': {
      fontSize: `${rowLineHeight - 2}px`,
      lineHeight: `${rowLineHeight}px`,
      padding: '4px 0 4px 16px',
      maxHeight: '28px',
      '& .MuiIconButton-root': {
        padding: '0px 8px',
        '& svg': {
          height: `${rowLineHeight}px`,
          width: `${rowLineHeight}px`
        }
      },
    },
    '& .MuiTableCell-root.MuiTableCell-body': {
      fontSize: `${rowLineHeight - 2}px`,
      lineHeight: `${rowLineHeight}px`,
      padding: '4px 0 4px 16px',
      '&.MuiTableCell-paddingNone': {
        padding: '4px 8px',
      },
      '& .MuiIconButton-root': {
        padding: '0px 8px',
        '& svg': {
          height: `${rowLineHeight}px`,
          width: `${rowLineHeight}px`
        }
      },
    },
  },
}));

// TODO: switch from material-table
function Table<Data extends any[]>(
  props: React.PropsWithChildren<MaterialTableProps<Data, Array<Column<Data[number]>>>>
) {
  const classes = useStyles();
  const {
    data,
    columns,
    isLoading,
    downloadAsFile,
    actions,
    toggleOrder,
    topBarLeftContent,
    meta,
    onPageChange,
    detailPanel,
    onSelectionChange,
    totalCount: customCount,
    selectedIndexes,
    saveFilters,
    setFilters,
    options,
    onSearch,
    downloadTemplate,
    downloadCSV,
    filterDTO,
    toolbarActions,
    fieldsToSearch,
    isNotExecute = true,
    getHeaders,
    headersXLSXUrl,
    onOpenUploadModal,
    removeChecked,
    showTotalCount,
    errorText,
    onColumnHide,
    hiddenColumns,
    isWrite = true,
  } = props;
  const extendedFilterKeys = useMemo(
    () => columns.filter((column) => !!column.filterComponent).map((column) => column.field),
    [columns]
  );

  const extendedFiltersInitialState = useMemo(
    () => filterDTO?.filters?.some((filter) => extendedFilterKeys.includes(filter.key) && filter.value),
    [extendedFilterKeys, filterDTO]
  );

  useEffect(() => {
    // open extended filters if there is values
    if (extendedFiltersInitialState) {
      setShowExtendedFilters(extendedFiltersInitialState);
    }
  }, [extendedFiltersInitialState]);

  const [showExtendedFilters, setShowExtendedFilters] = useState(extendedFiltersInitialState);

  const tableRef = useRef<any>(null);

  // bug in material table https://github.com/mbrn/material-table/issues/1480
  useEffect(() => {
    if (meta) {
      tableRef.current.dataManager.changePageSize(meta.limit || DEFAULT_PAGE_SIZE);
    }
  }, [meta]);

  // use when selected rows is controlled outside of component
  useEffect(() => {
    if (selectedIndexes !== undefined && tableRef && data && data.length > 0) {
      data.forEach((row, index) => {
        if (selectedIndexes.includes(index)) {
          tableRef.current.dataManager.changeRowSelected(true, [index]);
        } else {
          tableRef.current.dataManager.changeRowSelected(false, [index]);
        }
      });
      tableRef.current.updater.enqueueForceUpdate(tableRef.current);
    }
  });

  const handleRowClick = (
    event?: React.MouseEvent<Element, MouseEvent> | undefined,
    rowData?: Data[number] | undefined
  ) => {
    // Клик по чекбоксу ставит состояние checked  в нужной строке
    // Не работает с чекбоксом, который отвечает за все чекбоксы
    if (rowData && event) {
      const target = event.target as HTMLDivElement;
      const index = target?.closest?.('tr')?.getAttribute?.('index');
      const id = index ?? rowData.tableData.id;
      tableRef.current.dataManager.changeRowSelected(!rowData.tableData.checked, [id]);
      tableRef.current.updater.enqueueForceUpdate(tableRef.current);
      tableRef.current.onSelectionChange(rowData);
    }
  };

  const handleSortOrderChange = useCallback(
    (columnIndex) => {
      if (toggleOrder) {
        const fieldKey = columns[columnIndex].field;
        if (fieldKey && typeof fieldKey === 'string') {
          toggleOrder(fieldKey);
        }
      } else {
        console.error('sorting not implemented');
      }
    },
    [toggleOrder, columns]
  );

  const page = useMemo(() => {
    if (!meta) return 0;
    return meta.offset / meta.limit;
  }, [meta]);

  const totalCount = useMemo(() => {
    if (!meta) return 0;
    if (customCount !== undefined) return customCount;
    return meta.totalCount;
  }, [meta, customCount]);

  const currentOrderIndex: [number, 'asc' | 'desc' | undefined] = useMemo(() => {
    const orderKey = filterDTO?.orders[0];
    const index = columns.findIndex((c) => c.field === orderKey || `-${String(c.field)}` === orderKey);

    let direction: 'asc' | 'desc' | undefined;
    if (orderKey) {
      direction = orderKey[0] === '-' ? 'desc' : 'asc';
    }

    return [index, direction];
  }, [columns, filterDTO]);

  // if filterComponent is not defined do not render default filterComponent
  const _columns = useMemo(
    () =>
      columns.map((column) => ({
        ...column,
        hidden: hiddenColumns?.includes(String(column.field)),
        filterComponent: column.filterComponent || (() => <div />),
      })),
    [columns, hiddenColumns]
  );

  const toggleExtendedFilters = useCallback(() => {
    const showExtendedFiltersUpdate = !showExtendedFilters;

    // on close extended filters - clear extended filters and refetch
    if (!showExtendedFiltersUpdate && setFilters && filterDTO?.filters) {
      setFilters(filterDTO?.filters.filter((filter) => !extendedFilterKeys.includes(filter.key)));
    }
    setShowExtendedFilters(showExtendedFiltersUpdate);
  }, [extendedFilterKeys, filterDTO, setFilters, showExtendedFilters]);

  const defaultOptions = {
    actionsCellStyle: {
      position: 'sticky',
      right: -20,
      background: 'linear-gradient(to right, rgb(255 255 255 / 43%) 0%,rgb(255 255 255) 20%)',
      alignItems: 'flex-end',
    },
    loadingType: 'overlay',
    actionsColumnIndex: -1,
    columnsButton: true,
    search: false,
    sorting: !!toggleOrder,
    showTitle: false,
    toolbar: true,
    paging: !!meta,
    paginationPosition: 'top',
    pageSize: meta?.limit || DEFAULT_PAGE_SIZE,
    pageSizeOptions: DEFAULT_PAGE_OPTIONS,
    draggable: false,
    filtering: showExtendedFilters,
    editable: true,
    headerSelectionProps: {
      color: 'primary',
      disableRipple: true,
      checkedIcon: <SelectedCheckboxIcon />,
      icon: <UnselectedCheckboxIcon />,
      indeterminate: false,
      style: { padding: '9px 20px 9px 16px' },
    },
    selection: true,
    selectionProps: () => ({
      color: 'primary',
      disableRipple: true,
      checkedIcon: <SelectedCheckboxIcon />,
      icon: <UnselectedCheckboxIcon />,
      style: { padding: '9px 20px 9px 16px' },
    }),
    tableLayout: 'auto',
    rowStyle: () => ({
      // '@media print': {
      display: 'none !important',
      // }
    }),
    doubleHorizontalScroll: true,
    overflowY: 'visible',
    idSynonym: 'special_non_existent_key_to_prevent_id_duplicates_to_break_table',
  };

  const targetOptions = Object.assign(defaultOptions, options);

  const handleSearch = (columns: Column<Data[number]>[], searchString: string) => {
    if (onSearch != null) {
      const searchFields = !fieldsToSearch
        ? columns.map((c: Column<Data[number]>) => `${String(c?.field)}`)
        : fieldsToSearch;
      onSearch(searchFields, searchString);
    }
  };

  const emptyDataSourceMessage = useMemo(() =>
    getEmptyDataSourceMessage({ data, errorText }),
    [data, errorText]);

  // HACK: table rerender causes Toolbar menu close
  const menuDisclosure = useDisclosure();
  const hideColumnsDisclosure = useDisclosure();
  const downloadFileTypeDisclosure = useDisclosure();
  const uploadFileTypeDisclosure = useDisclosure();

  const [changedColumnTitle, setChangedColumnTitle] = useState<string>('');

  return (
    <div className={classes.root}>
      <MaterialTableCore<Data[number]>
        key={`table${meta?.limit || ''}`}
        style={{
          width: 'inherit',
          display: 'flex',
          flexDirection: 'column-reverse',
          boxShadow: 'none',
          height: '100%',
        }}
        tableRef={tableRef}
        data={data || []}
        columns={_columns}
        detailPanel={detailPanel}
        icons={tableIcons}
        components={{
          Pagination: (props) => <Pagination
            {...props}
            rowsPerPage={meta?.limit || DEFAULT_PAGE_SIZE}
            showTotalCount={showTotalCount}
          />,
          Toolbar: (props) => (
            <Toolbar
              onOpenUploadModal={onOpenUploadModal}
              isNotExecute={isNotExecute}
              filterDTO={filterDTO}
              downloadAsFile={downloadAsFile}
              getHeaders={getHeaders}
              headersXLSXUrl={headersXLSXUrl}
              columns={props.columns}
              handleHiddenColumn={(column, next) => {
                setChangedColumnTitle(column.title as string);
                // external hidden columns control
                if (onColumnHide) {
                  onColumnHide(String(column.field), next);
                  return;
                }
                props.onColumnsChanged(column, next);
              }}
              toggleExtendedFilters={toggleExtendedFilters}
              showExtendedFiltersControl={extendedFilterKeys.length > 0}
              topBarLeftContent={topBarLeftContent}
              saveFilters={saveFilters}
              search={targetOptions.search}
              onSearch={(searchString: string) =>
                handleSearch(
                  props.columns.filter((c: Column<Data[number]>) => !c.hidden),
                  searchString
                )
              }
              downloadTemplate={downloadTemplate}
              toolbarActions={toolbarActions}
              downloadCSV={downloadCSV}
              removeChecked={removeChecked}
              menuDisclosure={menuDisclosure}
              hideColumnsDisclosure={hideColumnsDisclosure}
              downloadFileTypeDisclosure={downloadFileTypeDisclosure}
              uploadFileTypeDisclosure={uploadFileTypeDisclosure}
              changedColumnTitle={changedColumnTitle}
              setChangedColumnTitle={setChangedColumnTitle}
              isWrite={isWrite}
            />
          ),
          Header: (props) => (
            <MTableHeader
              {...props}
              onOrderChange={handleSortOrderChange}
              orderBy={currentOrderIndex[0]}
              orderDirection={currentOrderIndex[1]}
              // HACK: somehow selected count is equal to 20 instead of 10 on select all
              selectedCount={
                selectedIndexes
                  ? tableRef.current?.dataManager.data.filter((row: any) => row.tableData.checked).length
                  : props.selectedCount
              }
            />
          ),
          Cell: (props) => (
            <MTableCell
              {...props}
              style={{
                background: props.value?.error ? 'red' : 'initial',
              }}
            />
          ),
        }}
        onRowClick={handleRowClick}
        isLoading={isLoading}
        totalCount={totalCount}
        page={page}
        onPageChange={onPageChange}
        options={targetOptions}
        actions={cloneDeep(createTableActions<Data>(actions))}
        localization={tableLocalization({
          emptyDataSourceMessage,
          showTotalCount
        })}
        onSelectionChange={onSelectionChange}
      />
    </div>
  );
}

export const MaterialTable = memo(Table);
