import { Button, DateInput, DefaultText, Input, LoaderItem, Select } from 'components/common';
import { Box, Flex, Grid, GridItem } from '@chakra-ui/react';
import { Field, FieldArray, useFormikContext } from 'formik';
import { AddIcon, DeleteIcon } from 'components/icons';
import { ObservationPlace } from './ObservationPlace';
import { ITaskFormer } from './types';
import { ResearchObjects } from './ResearchObjects';
import { TDictionaryItemDictionary } from 'models/dictionaries';
import { garbageBurningPoint, observationPlace, researchPoint, researchTypeAir } from 'pages/citizenAppeal';
import { getSelectOptionsFromDictionaryByName } from 'features/get-select-options-from-dictionary';
import React, { useMemo } from 'react';
import { createLocalDateISO8601 } from 'lib/create-date';
import { IUploadMedia } from 'components/common/FileUploadDragAndDrop/types';
import { MapForPels } from 'features/PelsMap/MapForPels';
import { noiseResearchTypeId } from '../consts';
import { useGoBackWithFallback } from 'lib/utils/goBackWithFallback';
import { useDictionary } from 'hooks/useDictionary';

interface Props {
  taskResearchTypes: TDictionaryItemDictionary[];
  taskReasonsNoise: TDictionaryItemDictionary[];
  taskReasonsAir: TDictionaryItemDictionary[];
  taskShiftsAir: TDictionaryItemDictionary[];
  taskShiftsNoise: TDictionaryItemDictionary[];
  districts: TDictionaryItemDictionary[];
  regions: TDictionaryItemDictionary[];
  taskPriorities: TDictionaryItemDictionary[];
  pointTypes: TDictionaryItemDictionary[];
  taskTypesNoise: TDictionaryItemDictionary[];
  taskTypesAir: TDictionaryItemDictionary[];
  backPath: string;
  onMediaSave: (media: IUploadMedia[], idx: number) => void;
  media: IUploadMedia[][];
  browserId: string;
  onMediaDelete: (index: number) => void;
  isNoiseRaid: boolean;
}

export const TaskFormer = ({
  taskResearchTypes,
  taskReasonsNoise,
  taskReasonsAir,
  taskShiftsAir,
  taskShiftsNoise,
  districts,
  regions,
  taskPriorities,
  pointTypes,
  backPath,
  onMediaSave,
  media,
  browserId,
  onMediaDelete,
  taskTypesNoise,
  taskTypesAir,
  isNoiseRaid,
}: Props) => {
  const { backWithFallback } = useGoBackWithFallback();
  const { values, setFieldValue, errors, touched, handleSubmit, initialValues } = useFormikContext<ITaskFormer>();
  const { dictionaries, isDictionariesLoading } = useDictionary({ url: '/appeal/v10/appeals/dictionaries' });
  const noiseSourceTypeId = useMemo(
    () =>
      getSelectOptionsFromDictionaryByName(
        dictionaries?.data,
        'noiseSourceTypeId',
        false,
        initialValues.points.map((item) => item.noiseSourceTypeId),
      ),
    [dictionaries?.data, initialValues.points],
  );
  const odorSourceTypeId = useMemo(
    () =>
      getSelectOptionsFromDictionaryByName(
        dictionaries?.data,
        'odorSourceTypeId',
        false,
        initialValues.points.map((item) => item.odorSourceTypeId),
      ),
    [dictionaries?.data, initialValues.points],
  );

  const pointId: string | undefined = useMemo(
    () => pointTypes.find((type) => type.name === 'Область обследования')?.value,
    [pointTypes],
  );

  return (
    <>
      {isDictionariesLoading ? (
        <LoaderItem />
      ) : (
        <>
          <Flex>
            <Grid gap={3} marginRight={30} width="50%">
              <DefaultText fontWeight="bold">Территория наблюдения</DefaultText>
              <GridItem>
                <Field as={Input} name="number" label="Номер задания" error={touched.name && errors?.name} />
              </GridItem>
              <GridItem>
                <Field
                  as={Input}
                  name="territoryName"
                  label="Наименование территории"
                  error={touched.territoryName && errors?.territoryName}
                />
              </GridItem>
              <GridItem>
                <Field
                  as={Select}
                  name="typeId"
                  label="Тип исследования"
                  options={taskResearchTypes}
                  error={touched.typeId && errors?.typeId}
                  isDisabled
                />
              </GridItem>
              <Field
                as={Select}
                name="taskTypeId"
                label="Тип рейда"
                value={values.taskTypeId}
                options={isNoiseRaid ? taskTypesNoise : taskTypesAir}
                error={errors?.taskTypeId}
              />
              <GridItem>
                <Field
                  as={Select}
                  name="reasonId"
                  label="Основание"
                  options={values.typeId === noiseResearchTypeId ? taskReasonsNoise : taskReasonsAir}
                  error={touched.reasonId && errors?.reasonId}
                  isDisabled={!values.typeId}
                />
              </GridItem>
              <GridItem>
                <Field
                  as={DateInput}
                  name="startDate"
                  label="Дата выезда"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setFieldValue('startDate', createLocalDateISO8601(e.target.value))
                  }
                  error={touched.startDate && errors?.startDate}
                />
              </GridItem>
              <GridItem>
                <Field
                  as={DateInput}
                  name="deadlineDate"
                  label="Срок исполнения"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setFieldValue('deadlineDate', createLocalDateISO8601(e.target.value))
                  }
                />
              </GridItem>
              <GridItem>
                <Field
                  as={Select}
                  name="shiftId"
                  label="Предполагаемая смена выполнения"
                  options={values.typeId === noiseResearchTypeId ? taskShiftsNoise : taskShiftsAir}
                  error={touched.shiftId && errors?.shiftId}
                  isDisabled={!values.typeId}
                />
              </GridItem>
              <GridItem>
                <Field
                  as={Select}
                  name="priorityId"
                  label="Приоритет"
                  options={taskPriorities}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const priorityValue = taskPriorities.find((item) => item.value === e.target.value)?.name;

                    setFieldValue('priorityId', e.target.value);
                    setFieldValue('priority', priorityValue);
                  }}
                  error={touched.priorityId && errors?.priorityId}
                />
              </GridItem>
              <GridItem>
                <FieldArray name="regions">
                  {({ push, remove }) => (
                    <Flex flexDirection="column">
                      {values.regions?.map((region, idx) => {
                        const filteredRegions = regions.slice().filter((item) => item.parent === values.districts[idx]);
                        return (
                          <Box>
                            <GridItem marginBottom={3}>
                              <Field
                                as={Select}
                                name={`districts[${idx}]`}
                                label="Округ"
                                options={districts}
                                error={touched.districts && errors?.districts && errors?.districts[idx]}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                  setFieldValue(`districts[${idx}]`, e.target.value);
                                  setFieldValue(`regions[${idx}]`, null);
                                }}
                              />
                            </GridItem>
                            <GridItem marginTop={5}>
                              <Flex alignItems="baseline" width="100%">
                                <Box marginBottom={3} width="100%">
                                  <Field
                                    as={Select}
                                    name={`regions[${idx}]`}
                                    label="Район"
                                    options={filteredRegions}
                                    error={touched.regions && errors?.regions && errors?.regions[idx]}
                                    isDisabled={!values.districts[idx] || !filteredRegions.length}
                                  />
                                </Box>
                                <Button
                                  marginLeft="10px"
                                  leftIcon={<DeleteIcon />}
                                  variant="ghost"
                                  color="PrimaryButton.500"
                                  size="sm"
                                  p={0}
                                  disabled={values.regions?.length === 1}
                                  onClick={() => {
                                    const districts = [...values.districts];
                                    districts.splice(idx, 1);
                                    setFieldValue('districts', districts);
                                    remove(idx);
                                  }}
                                />
                              </Flex>
                            </GridItem>
                          </Box>
                        );
                      })}
                      <Button
                        alignSelf="left"
                        marginRight="auto"
                        leftIcon={<AddIcon />}
                        variant="ghost"
                        color="PrimaryButton.500"
                        size="sm"
                        p={0}
                        onClick={() => {
                          const districts = [...values.districts, ''];
                          setFieldValue('districts', districts);
                          push('');
                        }}
                      >
                        Добавить округ и район
                      </Button>
                    </Flex>
                  )}
                </FieldArray>
              </GridItem>
              <GridItem>
                <Field
                  as={Input}
                  name="targetTime"
                  label="Комментарий"
                  error={touched.targetTime && errors?.targetTime}
                />
              </GridItem>
            </Grid>
            <Box ml={2} width="600px" height="600px">
              <MapForPels browserId={browserId} uid={values.id} pointTypeId={pointTypes} />
            </Box>
          </Flex>
          <FieldArray name="points">
            {({ push, remove }) => (
              <>
                {values.points.map((point, idx) => {
                  if (pointId !== point.typeId) {
                    return (
                      <Flex flexDirection="column">
                        <ObservationPlace
                          onMediaSave={onMediaSave}
                          odorSourceTypeId={odorSourceTypeId}
                          noiseSourceTypeId={noiseSourceTypeId}
                          isResearchTypeAir={values.typeId === researchTypeAir}
                          pointTypes={pointTypes}
                          index={idx}
                          media={media[idx]}
                          uploadId={values.points[idx].id && values.points[idx].id}
                          remove={remove}
                          browserId={browserId}
                          uid={values.id}
                          point={point}
                          onMediaDelete={onMediaDelete}
                        />
                      </Flex>
                    );
                  }
                  return (
                    <Flex flexDirection="column">
                      <ResearchObjects
                        media={media[idx]}
                        onMediaSave={onMediaSave}
                        odorSourceTypeId={odorSourceTypeId}
                        noiseSourceTypeId={noiseSourceTypeId}
                        isResearchTypeAir={values.typeId === researchTypeAir}
                        pointTypes={pointTypes}
                        index={idx}
                        uploadId={values.points[idx].id && values.points[idx].id}
                        remove={remove}
                        browserId={browserId}
                        uid={values.id}
                        point={point}
                        onMediaDelete={onMediaDelete}
                      />
                    </Flex>
                  );
                })}
                <Flex marginTop={5}>
                  <Button
                    leftIcon={<AddIcon />}
                    variant="ghost"
                    color="PrimaryButton.500"
                    size="sm"
                    p={0}
                    marginRight={5}
                    onClick={() => {
                      push({
                        address: '',
                        comment: '',
                        dotLatitude: '',
                        dotLongitude: '',
                        evalObjCoordinateArray: '',
                        expectedTime: '',
                        id: '',
                        temporaryId: Math.random(),
                        pointNumber: '',
                        noiseSourceTypeId: '',
                        odorSourceTypeId: '',
                        type: '',
                        typeId: observationPlace,
                      });
                    }}
                  >
                    Добавить объект обследования
                  </Button>
                  <Button
                    leftIcon={<AddIcon />}
                    variant="ghost"
                    color="PrimaryButton.500"
                    size="sm"
                    p={0}
                    marginRight={5}
                    onClick={() => {
                      push({
                        address: '',
                        comment: '',
                        dotLatitude: '',
                        dotLongitude: '',
                        evalObjCoordinateArray: '',
                        expectedTime: '',
                        id: '',
                        pointNumber: '',
                        type: '',
                        typeId: researchPoint,
                        noiseSourceTypeId: '',
                        odorSourceTypeId: '',
                        temporaryId: Math.random(),
                      });
                    }}
                  >
                    Добавить точку измерения
                  </Button>
                  {!isNoiseRaid && (
                    <Button
                      leftIcon={<AddIcon />}
                      variant="ghost"
                      color="PrimaryButton.500"
                      size="sm"
                      p={0}
                      onClick={() => {
                        push({
                          address: '',
                          comment: '',
                          dotLatitude: '',
                          dotLongitude: '',
                          evalObjCoordinateArray: '',
                          expectedTime: '',
                          id: '',
                          pointNumber: '',
                          type: '',
                          typeId: garbageBurningPoint,
                          noiseSourceTypeId: '',
                          odorSourceTypeId: '',
                          temporaryId: Math.random(),
                        });
                      }}
                    >
                      Добавить точку обследования навалов мусора/гарей
                    </Button>
                  )}
                </Flex>
              </>
            )}
          </FieldArray>
          <Flex marginTop={5}>
            <Button marginRight={5} onClick={() => backWithFallback(backPath)}>
              Отмена
            </Button>
            <Button onClick={() => handleSubmit()}>Сохранить</Button>
          </Flex>
        </>
      )}
    </>
  );
};
