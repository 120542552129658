import { ASKZA as ASKZACommon } from 'components/common/ASKZACommon/ASKZA';
import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import appLocale from 'constants/appLocale';
import React from 'react';
import subsystemIdsMap from 'constants/subsystemIdsMap';

export const Actinometry: React.FC = () => {
  useUpdateBreadcrumbs([
    { breadcrumb: appLocale.common.data, path: '/data' },
    {
      breadcrumb: appLocale.subsystems.meteo,
      path: '/data/meteo',
    },
    {
      breadcrumb: 'Метеоданные',
      path: '/data/meteo/data',
    },
    {
      breadcrumb: 'Актинометрия',
      path: '/data/meteo/data/actinometry',
    },
  ]);

  return (
    <ASKZACommon
      title="Данные актинометрии"
      rootPath="/data/meteo/data/actinometry"
      apiRootPath="/meteo/v10/meteo/actinometers"
      askzaType="9"
      types={[
        {
          value: '7',
          name: 'Набор параметров Актинометрия (Метео)',
        },
      ]}
      exportSubsystemName="meteo_actinometry"
      subsystemId={subsystemIdsMap.meteoActinometry}
    />
  );
};
