import { useRequest } from 'hooks/useRequest';
import { useEffect } from 'react';
import { getUnitApiPath } from './paths';
import { IMeasureUnitItem } from './types';

export function useGetItem(id: string) {
  const { get, isLoading, result, error } = useRequest<IMeasureUnitItem>(getUnitApiPath(id));

  useEffect(() => {
    get();
  }, [get]);

  return { isLoading, result, error };
}
