import { NSIReagentsListItem } from 'pages/dictionaries/NSIReagents/types';
import locationFormTabInitialEmptyValues from 'components/common/LocationFormTab/locationFormTabInitialEmptyValues';

const initialNSIReagentsGroundEmptyValues: Partial<NSIReagentsListItem> = {
  number: '',
  name: '',
  shortName: '',
  pgrSourceTypeId: null,
  status: null,
  startDate: null,
  stopDate: null,
  probeAreaOffsets: null,
  probeDepths: null,

  ...locationFormTabInitialEmptyValues,
  pgrAreaTypeId: null,
};

export default initialNSIReagentsGroundEmptyValues;
