import { IFilterItem } from 'models';

export const initialValues: { [s: string]: IFilterItem } = {
  edoAgreementDateMin: {
    key: 'edoAgreementDate',
    operation: 'GTE',
    value: '',
  },
  edoAgreementDateMax: {
    key: 'edoAgreementDate',
    operation: 'LTE',
    value: '',
  },
  pelProtocolDateFrom: {
    key: 'pelProtocolDate',
    operation: 'GTE',
    value: '',
  },
  pelProtocolDateTo: {
    key: 'pelProtocolDate',
    operation: 'LTE',
    value: '',
  },
  aiProtocolDateFrom: {
    key: 'aiProtocolDate',
    operation: 'GTE',
    value: '',
  },
  aiProtocolDateTo: {
    key: 'aiProtocolDate',
    operation: 'LTE',
    value: '',
  },
  startMeasurementsFrom: {
    key: 'measurementTimeFrom',
    operation: 'GTE',
    value: '',
  },
  startMeasurementsTo: {
    key: 'measurementTimeFrom',
    operation: 'LTE',
    value: '',
  },
  endMeasurementsFrom: {
    key: 'measurementTimeTo',
    operation: 'GTE',
    value: '',
  },
  endMeasurementsTo: {
    key: 'measurementTimeTo',
    operation: 'LTE',
    value: '',
  },
  protocolDateFrom: {
    key: 'protocolDate',
    operation: 'GTE',
    value: '',
  },
  protocolDateTo: {
    key: 'protocolDate',
    operation: 'LTE',
    value: '',
  },
  raidDateTimeMin: {
    key: 'raidDate',
    operation: 'GTE',
    value: '',
  },
  raidDateTimeMax: {
    key: 'raidDate',
    operation: 'LTE',
    value: '',
  },
  executionPeriodMin: {
    key: 'taskDeadlineDate',
    operation: 'GTE',
    value: '',
  },
  executionPeriodMax: {
    key: 'taskDeadlineDate',
    operation: 'LTE',
    value: '',
  },
  incomingLetterDateMin: {
    key: 'incomingDate',
    operation: 'GTE',
    value: '',
  },
  incomingLetterDateMax: {
    key: 'incomingDate',
    operation: 'LTE',
    value: '',
  },
  outgoingLetterDateMin: {
    key: 'outgoingDate',
    operation: 'GTE',
    value: '',
  },
  outgoingLetterDateMax: {
    key: 'outgoingDate',
    operation: 'LTE',
    value: '',
  },
  incomingAnswerDateMin: {
    key: 'answerIncomingDate',
    operation: 'GTE',
    value: '',
  },
  incomingAnswerDateMax: {
    key: 'answerIncomingDate',
    operation: 'LTE',
    value: '',
  },
  outgoingAnswerDateMin: {
    key: 'answerOutgoingDate',
    operation: 'GTE',
    value: '',
  },
  outgoingAnswerDateMax: {
    key: 'answerOutgoingDate',
    operation: 'LTE',
    value: '',
  },
  isViolation: {
    key: 'isViolation',
    operation: 'EQ',
    value: '',
  },
  hasExcess: {
    key: 'hasExcess',
    operation: 'EQ',
    value: '',
  },
  renovation: {
    key: 'isRenovation',
    operation: 'EQ',
    value: '',
  },
  hasProtocol: {
    key: 'hasPelProtocol',
    operation: 'EQ',
    value: '',
  },
  isSetProtocol: {
    key: 'isSetProtocol',
    operation: 'EQ',
    value: '',
  },
  objectType: {
    key: 'objectTypeId',
    operation: 'EQ',
    value: '',
  },
  protocolPEL: {
    key: 'isSetPelProtocol',
    operation: 'EQ',
    value: '',
  },
  protocolAI: {
    key: 'isSetAiProtocol',
    operation: 'EQ',
    value: '',
  },
  isRepeat: {
    key: 'isRepeat',
    operation: 'EQ',
    value: '',
  },
  answer: {
    key: 'haveAnswer',
    operation: 'EQ',
    value: '',
  },
  raidShiftId: {
    key: 'raidShiftId',
    operation: 'EQ',
    value: '',
  },
  pointTypeId: {
    key: 'pointTypeId',
    operation: 'EQ',
    value: '',
  },
  odorSourceTypeId: {
    key: 'odorSourceTypeId',
    operation: 'EQ',
    value: '',
  },
  noiseSourceTypeId: {
    key: 'noiseSourceTypeId',
    operation: 'EQ',
    value: '',
  },
  researchTypeId: {
    key: 'researchTypeId',
    operation: 'EQ',
    value: '',
  },
  taskTypeId: {
    key: 'taskTypeId',
    operation: 'EQ',
    value: '',
  }
};
