import { Localization } from '@material-table/core';

interface ITableLocalizationOptions {
  emptyDataSourceMessage?: string;
  showTotalCount?: boolean;
}

const tableLocalization = ({
  emptyDataSourceMessage = 'Данных нет',
  showTotalCount,
}: ITableLocalizationOptions): Localization => ({
  body: {
    emptyDataSourceMessage,
    addTooltip: 'Добавить',
    deleteTooltip: 'Удалить',
    editTooltip: 'Редактировать',
    filterRow: {
      filterTooltip: 'Фильтр',
    },
    editRow: {
      deleteText: 'Вы уверены, что хотите удалить?',
      cancelTooltip: 'Отменить',
      saveTooltip: 'Удалить',
    },
  },
  grouping: {
    placeholder: 'Перетащите заголовки ...',
  },
  header: {
    actions: '',
  },
  pagination: {
    // TODO: revert later
    labelDisplayedRows: showTotalCount ? '{from}-{to} из {count}' : '{from}-{to}',
    labelRowsSelect: 'записей',
    labelRowsPerPage: 'Записей на страницу:',
    firstAriaLabel: 'Первая старница',
    firstTooltip: 'Первая страница',
    previousAriaLabel: 'Предыдущая страница',
    previousTooltip: 'Предыдущая страница',
    nextAriaLabel: 'Следующая страница',
    nextTooltip: 'Следующая страница',
    lastAriaLabel: 'Последняя страница',
    lastTooltip: 'Последняя страница',
  },
  toolbar: {
    addRemoveColumns: 'Добавить или удалить колонки',
    nRowsSelected: '{0} запись(-ей) выбрано',
    showColumnsTitle: 'Показать колонки',
    showColumnsAriaLabel: 'Показать колонки',
    exportTitle: 'Экспортировать',
    exportAriaLabel: 'Экспортировать',
    searchTooltip: 'Поиск',
    searchPlaceholder: 'Поиск',
  },
});

export default tableLocalization;

interface IGetEmptyDataSourceMessage {
  data?: Array<any>,
  errorText?: string
}

export const getEmptyDataSourceMessage = ({ data, errorText }: IGetEmptyDataSourceMessage) => {
  if (errorText) return errorText;

  if (data === undefined) {
    return 'Для отображения данных необходимо задать параметры фильтрации';
  }

  if (data.length === 0) {
    return 'Данных нет';
  }

  return undefined;
};
