import { FormWrapper } from 'components/common';
import { Grid } from '@chakra-ui/react';
import React, { Fragment, useEffect, useMemo } from 'react';
import { getSelectOptionsFromDictionaryByName } from 'features/get-select-options-from-dictionary';
import { useFormikContext } from 'formik';
import { TDictionariesArray } from 'models/dictionaries';

import { useLocation } from 'react-router';
import { WashoutDeicingItem } from 'pages/data/PGR/GreenPlants/Washout/useWashoutDicingList';
import { IRenderFieldInterface, useFormField } from 'hooks/forms/useFormField';
import appLocale from 'constants/appLocale';
import { ANALYTICS_STATUS } from 'pages/analytics/forms/const';
import { AnalyticsWashoutSchema } from 'models/schemas/analytics/analyticsWashoutSechema';

interface CommonTabProps {
  dictionaries: {
    data: TDictionariesArray;
  } | null;
}

export const AnalyticsWashoutCommonTab = ({ dictionaries }: CommonTabProps): JSX.Element => {
  const { values, setFieldValue, initialValues } = useFormikContext<WashoutDeicingItem>();
  const location = useLocation();
  const isEditMode = location.pathname.includes('/edit');

  const { renderField } = useFormField<WashoutDeicingItem>({
    schema: AnalyticsWashoutSchema,
    labelLocalization: { ...appLocale.analytics.fields, ...appLocale.analytics.washout.fields },
  });

  const organizations = useMemo(
    () =>
      getSelectOptionsFromDictionaryByName(dictionaries?.data, 'organizations', false, [
        initialValues.consumerId,
        initialValues.organizationId,
      ]),
    [dictionaries?.data, initialValues.consumerId, initialValues.organizationId],
  );

  const areas = useMemo(() => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'areas'), [dictionaries]);

  const points = useMemo(
    () =>
      getSelectOptionsFromDictionaryByName(dictionaries?.data, 'points').filter(
        (item) => item.parent === values.areaId,
      ),
    [dictionaries, values.areaId],
  );

  useEffect(() => {
    if (values.pointId && dictionaries) {
      const point = points.find((item) => item.value === values.pointId);
      if (point?.parent !== values.areaId) {
        setFieldValue('pointId', null);
      }
    }
  }, [dictionaries, points, setFieldValue, values.areaId, values.pointId]);

  // while status is hidden assume status as draft
  let fieldsIsDisabled = values.status === ANALYTICS_STATUS.APPROVED && !!isEditMode;
  fieldsIsDisabled = false;

  const fields: IRenderFieldInterface<WashoutDeicingItem>[] = [
    { name: 'protocolNumber', isDisabled: fieldsIsDisabled },

    { name: 'protocolDate', type: 'dateinput', isDisabled: fieldsIsDisabled },

    { name: 'consumerId', type: 'select', options: organizations, isDisabled: fieldsIsDisabled },

    { name: 'researchBasis', isDisabled: fieldsIsDisabled },

    { name: 'researchObject', isDisabled: fieldsIsDisabled },

    { name: 'supportingDocuments', isDisabled: fieldsIsDisabled },

    { name: 'sampleNumber', isDisabled: fieldsIsDisabled },

    { name: 'areaId', type: 'select', options: areas, isDisabled: fieldsIsDisabled },

    {
      name: 'pointId',
      type: 'select',
      options: points,
      isDisabled: fieldsIsDisabled,
    },

    { name: 'date', type: 'datetimeinput', isDisabled: fieldsIsDisabled },

    { name: 'organizationId', type: 'select', options: organizations, isDisabled: fieldsIsDisabled },

    { name: 'explanationFromConsumer', isDisabled: fieldsIsDisabled },

    { name: 'sampleReceiveDate', type: 'datetimeinput', isDisabled: fieldsIsDisabled },

    { name: 'researchDateStart', type: 'dateinput', isDisabled: fieldsIsDisabled },

    { name: 'researchDateEnd', type: 'dateinput', isDisabled: fieldsIsDisabled },

    { name: 'sampleLaboratoryNumber', isDisabled: fieldsIsDisabled },

    { name: 'explanation', isDisabled: fieldsIsDisabled },
  ];

  return (
    <FormWrapper>
      <Grid marginTop="30px" gap="15px">
        {fields.map((field) => (
          <Fragment key={field.name}>{renderField(field)}</Fragment>
        ))}
      </Grid>
    </FormWrapper>
  );
};
