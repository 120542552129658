import { FormHeader } from 'components/common';
import { ERROR_MESSAGES, SUCCESS_MESSAGES } from 'constants/messages';

import { createTouchedErrors } from 'features/create-touched-errors';
import { FormikProvider, useFormik } from 'formik';
import { useNotifications } from 'hooks/useNotifications';
import { useRequest } from 'hooks/useRequest';
import { initialEmptyValues } from './initialEmptyValues';
import FieldsForm from './FieldsForm';
import { SubsoilSchema } from 'models/schemas/subsoil/subsoil';
import { useGoBackWithFallback } from 'lib/utils/goBackWithFallback';
import { useDictionary } from 'hooks/useDictionary';

export const AddSubsoilReport = () => {
  const { backWithFallback } = useGoBackWithFallback();

  const { isDictionariesLoading, dictionaries } = useDictionary({ url: '/subsoil/v10/subsoils/dictionaries' });

  const { successNotify, errorNotify } = useNotifications();

  const onSuccess = () => {
    successNotify({
      key: 'file/create/success',
      message: SUCCESS_MESSAGES.CREATE,
    });
    backWithFallback('/data/subsoil');
  };
  const onError = () =>
    errorNotify({
      key: 'file/create/error',
      message: ERROR_MESSAGES.CREATE,
    });

  const { post, isLoading: isPostLoading } = useRequest('/subsoil/v10/subsoils', onSuccess, onError);

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    initialValues: initialEmptyValues,
    validationSchema: SubsoilSchema,
    onSubmit: (values) => post(values),
  });

  return (
    <FormikProvider
      value={{
        ...formik,
        errors: createTouchedErrors(formik.errors, formik.touched),
      }}
    >
      <FormHeader onBackClick={() => backWithFallback('/data/subsoil')} header="Добавление результатов измерения" />
      <FieldsForm
        dictionaries={dictionaries}
        onCancel={() => backWithFallback('/data/subsoil')}
        isLoading={isDictionariesLoading || isPostLoading}
      />
    </FormikProvider>
  );
};
