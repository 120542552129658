import React from 'react';
import { baseAdminRoleUrl } from './const';
import { useHistory } from 'react-router';
import { RoleItem } from '../models/RoleItem';
import { tableIcons } from '../components/CustomTable';
import { useHandleDeleteData, useList, useRequest } from 'hooks';
import { DEFAULT_PAGE_SIZE_NEW_TABLE } from '../../../constants/tables';
import { Column } from '@material-table/core';
import { MaterialTable } from '../../../features/material-table/MaterialTableMini';
import { DefaultTableCellSize } from '../../../components/table/DefaultTableCellSize';

export const RoleList: React.FC = () => {
  const history = useHistory();

  const {
    isLoading: isRoleListLoading,
    response,
    toggleOrder,
    setFilters,
    onPageChange,
    filterDTO,
    onSearchText,
    selectedIndexes,
    onSelectionChange,
    refetch,
  } = useList<RoleItem, keyof RoleItem>(baseAdminRoleUrl, {
    initialOrderFieldKey: '-code',
    defaultPageSize: DEFAULT_PAGE_SIZE_NEW_TABLE,
    resetDataOnNextRequest: true,
    fetchMethod: 'GET',
    initialDeleted: null,
  });

  const { post: uneraseRole, isLoading: isBusy } = useRequest(baseAdminRoleUrl);

  const columns: Column<RoleItem>[] = [
    {
      title: 'Внешний код',
      field: 'code',
    },
    {
      title: 'Наименование',
      field: 'name',
    },
    {
      title: 'Описание',
      field: 'description',
      render: (row) => <DefaultTableCellSize maxWidth={400}>{row.description}</DefaultTableCellSize>
    },
    {
      title: 'Удалена',
      field: 'deleted',
      render: (rowdata) => <input type="checkbox" checked={rowdata.deleted} readOnly />,
      width: 70,
    },
  ];

  const isLoading = isRoleListLoading || isBusy;
  const { ModalComponent: ConfirmDeleteModal, handleOpenModal } = useHandleDeleteData({ refetch });

  return (
    <>
      <MaterialTable
        data={response?.data}
        meta={response?.meta}
        toggleOrder={toggleOrder}
        filterDTO={filterDTO}
        onPageChange={onPageChange}
        columns={columns}
        actions={[
          {
            type: 'edit',
            onClick: (_, row) => {
              if (!Array.isArray(row) && row.id) {
                history.push(`/admin/role/edit/${row.id}`);
              }
            },
          },
          {
            position: 'row',
            action: (item) => ({
              icon: tableIcons.Reload,
              tooltip: 'Снять пометку на удаление',
              onClick: () => {
                if (item?.id == null) return;
                const { id } = item;
                void uneraseRole(undefined, {
                  url: `${baseAdminRoleUrl}/${id}/unerase`,
                }).then(() => refetch());
              },
              hidden: !item.deleted,
            })
          },
          {
            position: 'row',
            action: (item) => ({
              icon: tableIcons.Delete,
              tooltip: 'Удалить',
              onClick: () => {
                handleOpenModal({
                  url: `${baseAdminRoleUrl}/${item.id}`,
                });
              },
              hidden: item.deleted,
            })
          },
        ]}
        isLoading={isLoading || isBusy}
        setFilters={setFilters}
        options={{ selection: false, search: true }}
        onSearch={onSearchText}
        onSelectionChange={onSelectionChange}
        selectedIndexes={selectedIndexes}
        showTotalCount
      />

      {ConfirmDeleteModal}
    </>
  );
};
