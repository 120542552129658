import { useHistory, useParams } from 'react-router-dom';
import React, { useMemo, useState } from 'react';
import { Column } from '@material-table/core';
import { MaterialTable } from 'features/material-table/MaterialTableMini';
import { map } from 'lodash';
import { SubsystemHeader } from 'components/common';
import { ConfirmModal } from 'components/common/Modals';
import { ERROR_MESSAGES, REMOVE_CONFIRMATION, SUCCESS_MESSAGES } from 'constants/messages';
import { useRequest } from 'hooks/useRequest';
import { useDisclosure, useList } from 'hooks';
import { useNotifications } from 'hooks/useNotifications';
import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import appLocale from 'constants/appLocale';
import { NSIApiRouteRoot, NSIRootPath } from '../const/routes';
import { IDictionaryItem, IDictionaryItemsResponse } from '../const/types';
import { useNSIDictionary } from 'pages/dictionaries/hooks/useNSIDictionary';
import { PropActions } from 'features/material-table/components/Actions';
import { DictionaryFilter } from './Filter';
import { DeleteIcon, EditIcon, ReloadIcon } from 'components/icons';
import { FullPageWrapper } from '../../../components/table/FullPageWrapper';
import { DEFAULT_PAGE_SIZE_NEW_TABLE } from '../../../constants/tables';

export const Dictionary: React.FC = () => {
  const history = useHistory();
  const { dictionaryId } = useParams<{ dictionaryId: string }>();
  const [removeItemId, setRemoveItemId] = useState<string>();
  const { successNotify, errorNotify } = useNotifications();

  const { dictionary, isLoading: dictionaryIsLoading } = useNSIDictionary(dictionaryId);
  const isWrite = Boolean(dictionary?.effectiveRights.find((right) => right === 'WRITE'));

  useUpdateBreadcrumbs([
    {
      breadcrumb: appLocale.mainPage.nsi,
      path: NSIRootPath,
    },
    {
      breadcrumb: dictionary?.name || '',
      path: `${NSIRootPath}/${dictionaryId}`,
    },
  ]);

  const {
    isLoading: itemsIsLoading,
    response,
    toggleOrder,
    onPageChange,
    downloadAsFile,
    onSearchText,
    refetch,
    filterDTO,
    errorText,
    setFilters,
  } = useList<IDictionaryItemsResponse>(`${NSIApiRouteRoot}/${dictionaryId}/item/list`, {
    initialOrderFieldKey: '-name',
    defaultPageSize: DEFAULT_PAGE_SIZE_NEW_TABLE,
    resetDataOnNextRequest: true,
  });

  const { isOpen, onOpen, onClose } = useDisclosure();
  const onSuccess = () => {
    onClose();

    successNotify({
      key: 'dictionary/delete/success',
      message: SUCCESS_MESSAGES.DELETE,
    });
  };

  const onError = () =>
    errorNotify({
      key: 'dictionary/delete/error',
      message: ERROR_MESSAGES.DELETE,
    });

  const onRestoreSuccess = () => {
    successNotify({
      key: 'dictionary/restore/success',
      message: SUCCESS_MESSAGES.RESTORE,
    });
    refetch();
  };

  const onRestoreError = () =>
    errorNotify({
      key: 'dictionary/restore/error',
      message: ERROR_MESSAGES.RESTORE,
    });

  const { remove } = useRequest('', onSuccess, onError);
  const { get: restore } = useRequest('', onRestoreSuccess, onRestoreError);

  const dictProps = dictionary?.schema?.properties;
  const columns = useMemo<Column<IDictionaryItem>[]>(() => {
    const properties = dictProps || {};
    const fieldKeys = Object.keys(dictProps || {});

    return [
      {
        title: 'Имя',
        field: 'name',
      },
      {
        title: 'Краткое имя',
        field: 'shortName',
      },
      {
        title: 'Алиасы',
        field: 'aliases',
        render: (rowData) => rowData.aliases?.join(', '),
        sorting: false,
      },
      ...map(fieldKeys, (fieldKey) => ({
        title: properties[fieldKey].title,
        field: `value[${fieldKey}]`,
      })),
      {
        title: 'Примечание',
        field: 'comment',
      },
    ];
  }, [dictProps]);

  const handleAddButtonClick = () => history.push(`${history.location.pathname}/add`);

  const initialActions: PropActions<IDictionaryItem[]> = useMemo(
    () => [
      {
        position: 'row',
        action: (row: IDictionaryItem) => ({
          icon: () => <EditIcon />,
          onClick: () => {
            if (!Array.isArray(row)) {
              history.push(`${NSIRootPath}/${dictionaryId}/${row.id}/edit`);
            }
          },
          tooltip: 'Редактировать',
          hidden: !isWrite || row.deleted,
        }),
      },
      {
        position: 'row',
        action: (row: IDictionaryItem) => ({
          icon: () => <DeleteIcon />,
          onClick: () => {
            if (!Array.isArray(row)) {
              setRemoveItemId(row.id);
              onOpen();
            }
          },
          tooltip: 'Удалить',
          hidden: !isWrite || row.deleted,
        }),
      },
      {
        position: 'row',
        action: (row: IDictionaryItem) => ({
          icon: () => <ReloadIcon />,
          onClick: () => restore({ url: `${NSIApiRouteRoot}/${dictionaryId}/item/${row.id}/restore` }),
          tooltip: 'Восстановить',
          hidden: !isWrite || !row.deleted,
        }),
      },
    ],
    [isWrite, history, dictionaryId, onOpen, restore]
  );

  return (
    <FullPageWrapper>
      <SubsystemHeader
        backPath={NSIRootPath}
        title={dictionary?.name || ''}
        addButtonProps={{
          disabled: !isWrite,
          onClick: handleAddButtonClick,
        }}
        filtersComponent={<DictionaryFilter setFilters={setFilters} />}
      />

      <MaterialTable
        isLoading={itemsIsLoading || dictionaryIsLoading}
        data={response?.data}
        meta={response?.meta}
        columns={columns}
        actions={initialActions}
        options={{
          search: true,
        }}
        onSearch={onSearchText}
        toggleOrder={toggleOrder}
        onPageChange={onPageChange}
        downloadAsFile={downloadAsFile}
        filterDTO={filterDTO}
        showTotalCount
        errorText={errorText}
      />

      <ConfirmModal
        isOpen={isOpen}
        onClose={onClose}
        onClick={() => {
          remove({
            url: `${NSIApiRouteRoot}/${dictionaryId}/item/${removeItemId}`,
            data: {
              dictId: dictionaryId,
              id: removeItemId,
            },
          }).then(() => {
            refetch();
          });
        }}
        header={REMOVE_CONFIRMATION}
      />
    </FullPageWrapper>
  );
};
