import React from 'react';
import { PageHeader, RoutesList } from 'components/common';
import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import appLocale from 'constants/appLocale';
import { protocolsMenu } from 'features/WithSideBar/menus/protocolsMenu';
import { Flex, Heading } from '@chakra-ui/react';
import { useRoutesList } from 'hooks/useRoutesList';

export const ProtocolsList: React.FC = () => {
  useUpdateBreadcrumbs([{ breadcrumb: appLocale.protocols.title, path: '/protocols' }]);

  const routes = useRoutesList(protocolsMenu);

  return (
    <Flex flexDirection="column">
      <PageHeader>
        <Heading>{appLocale.protocols.title}</Heading>
      </PageHeader>
      <RoutesList routes={[...routes]} />
    </Flex>
  );
};
