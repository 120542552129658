import React from 'react';
import { Box, Flex, Link, Text } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import { colors } from 'components/theme/colors';
import { makeStyles } from '@material-ui/styles';

interface Props {
  text: string;
  icon?: React.ReactNode;
  path?: string;
  isBold?: boolean;
  count?: number;
}

interface Styles {
  path?: string;
  isBold?: boolean;
}

const useStyles = makeStyles(() => ({
  container: {
    cursor: ({ path }: Styles) => (path ? 'pointer' : 'initial'),
    color: ({ isBold }: Styles) => (isBold ? 'text.default' : colors.grey[700]),
    '&:hover': {
      color: ({ path }: Styles) => (path ? colors.blue.main : ''),
    },
  },
}));

export const MenuItem: React.FC<Props> = ({ icon, text, path, isBold, count }) => {
  const history = useHistory();
  const classes = useStyles({ path });

  const onClick = () => {
    if (path) {
      history.push(path);
    }
  };

  return (
    <Flex
      className={classes.container}
      cursor={path ? 'pointer' : 'initial'}
      py={isBold ? 6 : 5}
      onClick={onClick}
      alignItems="center"
    >
      {icon && (
        <Box mr={5} width={34} height={isBold ? 30 : 34}>
          {icon}
        </Box>
      )}

      <Text
        fontSize={isBold ? '1.375rem' : '1rem'}
        lineHeight={isBold ? '1.875rem' : '1.25rem'}
        fontWeight={isBold ? 'bold' : 'initial'}
      >
        {text}
        {count && (
          <>
            &nbsp;
            <Link textDecoration="underline" href="/" color="text.primary">
              (
              {count}
              )
            </Link>
          </>
        )}
      </Text>
    </Flex>
  );
};
