import {
  As,
  FormControl,
  FormControlProps,
  FormErrorMessage,
  FormLabel,
  forwardRef,
  Input as ChakraInput,
  InputGroup,
  InputLeftElement,
  InputProps as ChakraInputProps,
  InputRightElement,
  Stack,
} from '@chakra-ui/react';
import isNumber from 'lodash/isNumber';
import { memo, ReactNode, useCallback, useRef } from 'react';
import { FormikFieldProps } from '../types';
import { SelectClearIcon } from 'components/common/Select/components';
import { isNull, isUndefined } from 'lodash';
import InputMask from 'react-input-mask';

export interface InputProps extends ChakraInputProps {
  label?: string;
  InputLeftElement?: ReactNode;
  InputRightElement?: ReactNode;
  isClearable?: boolean;
  isFilled?: boolean;
}

// TODO: some <Field components has isDisabled prop and some disabled - refactor
export const Input = memo(
  forwardRef<
    InputProps &
    Partial<FormikFieldProps> & {
      disabled?: boolean;
      isRequired?: FormControlProps['isRequired'];
      mask?: string;
    },
    As<InputProps & Partial<FormikFieldProps>>
  >(
    (
      {
        InputRightElement: inputRightElement,
        InputLeftElement: inputLeftElement,
        label,
        error,
        size = 'md',
        p = 4,
        isClearable = true,
        isFilled = false,
        ...props
      },
      ref,
    ) => {
      const inputRightElementRef = useRef<HTMLDivElement | null>(null);

      const inputRigthElementClientWidth = inputRightElementRef.current?.clientWidth;

      const inputLeftElementRef = useRef<HTMLDivElement | null>(null);

      const inputLeftElementClientWidth = inputLeftElementRef.current?.clientWidth;

      const { onChange, name, value } = props;

      const handleChange = useCallback(
        (innerValue: string) => {
          if (onChange) {
            onChange({
              target: { value: innerValue, name },
            } as React.BaseSyntheticEvent);
          }
        },
        [name, onChange],
      );

      return (
        <FormControl isInvalid={Boolean(error)} isRequired={props.isRequired}>
          <FormLabel
            position="absolute"
            margin={0}
            pointerEvents="none"
            zIndex={10}
            left={p}
            top={1}
            color="input.label.default"
            fontSize="13px"
            lineHeight="17px"
            transform={
              isNumber(value) ||
                Boolean(value) ||
                isNumber(props.defaultValue) ||
                Boolean(props.defaultValue) ||
                isFilled
                ? 'translate(0px, 0)'
                : 'translate(0px, 11px)'
            }
            _focus={{ transform: 'translate(0px, 0px)' }}
          >
            {label}
          </FormLabel>
          <InputGroup>
            {inputLeftElement && (
              <InputLeftElement ref={inputLeftElementRef} width="fit-content" height="100%">
                {inputLeftElement}
              </InputLeftElement>
            )}
            <ChakraInput
              as={props.mask ? InputMask : undefined}
              ref={ref}
              height="45px"
              backgroundColor="input.background.default"
              borderRadius="2px"
              focusBorderColor="input.border.primary"
              errorBorderColor="input.border.error"
              size={size}
              p={p}
              paddingInlineEnd={inputRigthElementClientWidth ? `${inputRigthElementClientWidth}px` : p}
              paddingEnd={inputRigthElementClientWidth ? `${inputRigthElementClientWidth}px` : p}
              paddingInlineStart={inputLeftElementClientWidth ? `${inputLeftElementClientWidth}px` : p}
              paddingStart={inputLeftElementClientWidth ? `${inputLeftElementClientWidth}px` : p}
              fontSize="16px"
              lineHeight="20px"
              paddingTop="27px"
              opacity={props.disabled || props.isDisabled ? '0.7 !important' : undefined}
              {...props}
              value={isNull(props.value) ? '' : props.value}
            />
            {inputRightElement && (
              <InputRightElement ref={inputRightElementRef} width="fit-content" pr={4} height="100%">
                {inputRightElement}
              </InputRightElement>
            )}
            {isClearable && (
              <InputRightElement ref={inputRightElementRef} width="fit-content" pr={4} height="100%">
                <Stack direction="row" spacing={2} alignItems="center">
                  {((!isNull(value) && value !== '' && !isUndefined(value) && !props.isDisabled && !props.disabled) ||
                    isFilled) && (
                      <SelectClearIcon
                        clearColor="red"
                        onClick={() => {
                          handleChange('');
                        }}
                      />
                    )}
                </Stack>
              </InputRightElement>
            )}
          </InputGroup>
          <FormErrorMessage>{error}</FormErrorMessage>
        </FormControl>
      );
    },
  ),
);
