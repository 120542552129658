import { TDictionariesArray } from 'models';

const forecastPeriods = ['lastDay', 'lastNight', 'today', 'tonight', 'tomorrow'];
const ForecastFormTypes = {
  lastDay: 'lastDay',
  lastNight: 'lastNight',
  today: 'today',
  tonight: 'tonight',
  tomorrow: 'tomorrow',
};

export interface IForecastForm {
  id: string | null;
  date: string | null;
  meteorologicalSituation: string | null;
  maxInversionDegree: number | null;
  surfacePressure: number | null;
  cloudCoverage: string[] | null;
  wetness: number | null;
  activeWeatherIds: string[] | null;
  phenomena: string[] | null;
  umcIdentificationId: string | null;
  windDirection: string[] | null;
  windSpeed: number | null;
  windFlawValues: string | null;
  windDirectionPSA: string[] | null;
  windSpeedPSA: number | null;
  inversionDegree: number | null;
  minInversionOutsideCity: number | null;
  maxInversionOutsideCity: number | null;
  minInversionInCity: number | null;
  maxInversionInCity: number | null;
  inversionDuration: number | null;
  thermalStratificationType: string | null;
  stirringIntensity: string | null;
}

export interface IForecastMainForm {
  [key: string]: IForecastForm;
}

export interface IForecastDateBox {
  id?: number;
  date: string;
  text: string;
}

export interface IForecastAddItemEntity {
  entity: string;
  dictionary?: IForecastDictionary | undefined | null;
  editedObject?: any
}

interface IForecastDictionary {
  data: TDictionariesArray;
}

const forecastFormInitialValues: IForecastMainForm = forecastPeriods.reduce(
  (accum: IForecastMainForm, value: string) => {
    accum[value] = {
      id: null,
      date: null,
      meteorologicalSituation: null,
      maxInversionDegree: null,
      surfacePressure: null,
      cloudCoverage: null,
      wetness: null,
      activeWeatherIds: null,
      phenomena: null,
      umcIdentificationId: null,
      windDirection: null,
      windSpeed: null,
      windFlawValues: null,
      windDirectionPSA: null,
      windSpeedPSA: null,
      inversionDegree: null,
      minInversionOutsideCity: null,
      maxInversionOutsideCity: null,
      minInversionInCity: null,
      maxInversionInCity: null,
      inversionDuration: null,
      thermalStratificationType: null,
      stirringIntensity: null,
    };
    return accum;
  },
  {},
);

export { forecastFormInitialValues, ForecastFormTypes };
