import * as yup from 'yup';
import { ValidationError } from 'yup';
import { requiredError, sumError } from '../constans';
import { FormikValues } from 'formik';

// сумма этих параметров не должна превышать 100
const percentageParameters = [
  '6a1b0f81-8fe3-45ab-b7dd-79d42ca54560',
  '871e53fe-dbb0-4d44-8041-96d84eb0a928',
  '8e4ed7db-af67-45e5-8ac4-2804993ece86',
];

export const soilSchemaRequiredIds = [
  '9e6bbdd8-5d31-42c2-a6d3-5042393f86c7',
  '998c12cf-b755-4854-a0b0-6cfd5864dc04',
  '655f0f1e-1761-4ebe-8c35-4f504ac4f135',
  '8e3f2658-ea61-4d01-abf8-e2b4501ea7d2',
  '6a1b0f81-8fe3-45ab-b7dd-79d42ca54560',
  '871e53fe-dbb0-4d44-8041-96d84eb0a928',
  '8e4ed7db-af67-45e5-8ac4-2804993ece86',
  '2d6a5c27-118a-496c-8c06-dc80eda46478',
  '91b2be9b-9ee1-4909-8305-bceec3dcd7a7',
  '99e2e54d-7b18-498f-8889-6578b4392f50',
  '6185b8b7-e218-4508-85ae-a4595b05776d',
  '2c335f47-f317-4930-b9a9-f0504c406bad',
  'f2ebf1ae-2c5f-4cee-bb58-1178a1d1de0b',
  '6dbbf64b-52b6-4d5a-92d0-dea5996d5c58',
  '930c9d1e-65bf-4f67-b4e5-13dae5cb7f34',
  '4d81cf88-1a32-479d-a7a9-c3be65a13d0c',
  '29df19f0-698e-496c-8a90-f4c1c5a39998',
  'd2996d80-f935-4f4c-8986-aefeda17ef10',
  '0773dd7f-98ea-4bc9-9e66-11099480c207',
  '22a639a1-fe1b-489a-8c4c-0b7e7bdb58f5',
  '01124c55-2f95-4c20-9f18-85db62c2a35e',
  '4f684be9-b25f-4d25-a7cf-6f8b7c1bbe49',
  '203377a0-0fb0-4378-85a1-e7b2b313ee36',
  '4757fa3e-1198-4ed6-8d52-5df01b68d3ae',
  'a2f5492f-4733-4702-ae31-d70064940284',
  '6a47f04e-19e0-4018-a760-f25950c6a8d3',
  '7904e497-49a4-42e6-922b-78d1430fb148',
  'd7e812ca-f5e8-4b53-9fc6-de9dec33282f',
  '37e1a78a-faf9-4c43-b3a2-ae0ded458eab',
  '4ce6fc6b-efd6-433f-9368-59d8d1b44ca6',
  'd243de0c-d8b6-4c4b-bd1c-059c38f7b284',
  '6ff51198-2c4a-4d72-9150-15a6272a0bd5',
  'c9db7ce2-6d34-4c7d-be77-3f980282c715',
  'a8057094-e6e3-4c4b-9dc9-5e8735cc3f66',
  'f9343031-2c5e-4a0a-af4a-23ef2d2c2099',
  '1f421efe-ed47-4fa7-beb2-da2b2a3413b7',
  'e67b4f10-912d-4e60-816b-c48f67e1abbe',
  '90a5fc82-a4f8-4258-b39c-1d95e802c7f1',
  '2dba61f4-abc6-40da-a7b6-68d330d9633b',
  'd534a64e-5d10-46fb-a7f7-5e6ef00442c5',
];

export const SoilFormValidationSchema = () => {
  const shapes: any = {};
  soilSchemaRequiredIds.forEach((parameter) => {
    shapes[parameter] = yup.array()
      .of(
        yup.object()
          .shape({
            value: yup.mixed().required(requiredError)
          })
      ).required(requiredError);
  });

  return yup
    .object()
    .shape({
      protocolNumber: yup.string().required(requiredError),
      protocolDate: yup.string().nullable().required(requiredError),
      sourceId: yup.string().typeError(requiredError).required(requiredError),
      probeDate: yup.string().typeError(requiredError).required(requiredError),
      parameters: yup.object().shape(shapes)
    })
    .test((values: FormikValues) => {
      const percentageValues = [...percentageParameters].map(
        (key) => (values.parameters[key] && values.parameters[key]?.[0].value) || 0
      );

      if (percentageValues.reduce((partialSum, value) => partialSum + (parseFloat(value)), 0) > 100) {
        return new ValidationError(
          percentageParameters.map((key) => new ValidationError(sumError(100), '', `parameters[${key}]`))
        );
      }

      // const emptyIds: string[] = [];
      // soilSchemaRequiredIds.forEach((id) => {
      //   if (!values.parameters[id]?.[0]?.value) {
      //     emptyIds.push(id);
      //   }
      // });

      // if (emptyIds.length > 0) {
      //   return new ValidationError(
      //     emptyIds.map((key) => (new ValidationError(requiredError, '', `parameters[${key}]`)))
      //   );
      // }

      return true;
    });
};
