import React, { useMemo } from 'react';
import { Field, FormikProvider, useFormik } from 'formik';
import { Input, Select } from 'components/common';
import { Flex, Grid, GridItem } from '@chakra-ui/react';
import { IFilterItem, TDictionariesArray } from 'models';
import { getSelectOptionsFromDictionaryByName } from 'features/get-select-options-from-dictionary';
import { FilterCheckbox } from 'features/Filter/components';
import FilterFormControls from 'components/form/FilterFormControls';

interface Props {
  onSubmit: (values: any) => void;
  dictionaries: {
    data: TDictionariesArray;
  } | null;
  isLoading: boolean;
}

const initialValues: Record<string, IFilterItem> = {
  name: {
    key: 'name',
    operation: 'CONTAINS',
    value: null,
  },
  exchanges: {
    key: 'exchangeId',
    operation: 'EQ',
    value: null,
  },
  status: {
    key: 'status',
    operation: 'EQ',
    value: '',
  },
  deleted: {
    key: 'deleted',
    operation: 'EQ',
    value: false,
  },
};

export const FTPUploadFilter = ({ onSubmit, dictionaries, isLoading }: Props) => {
  const exchanges = useMemo(
    () => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'exchanges'),
    [dictionaries],
  );
  const status = useMemo(() => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'status'), [dictionaries]);
  const {
    values,
    errors,
    getFieldMeta,
    getFieldHelpers,
    getFieldProps,
    handleReset,
    submitForm,
    setFieldValue,
    ...formik
  } = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    initialValues,
    onSubmit,
  });

  const onReset = (e: any) => {
    handleReset(e);
    submitForm();
  };

  return (
    <FormikProvider
      value={{
        values,
        errors,
        getFieldMeta,
        getFieldHelpers,
        getFieldProps,
        handleReset,
        submitForm,
        setFieldValue,
        ...formik,
      }}
    >
      <Flex flexDirection="row" justifyContent="space-between">
        <Grid marginRight={2} gap={3} templateColumns="repeat(4, 1fr)">
          <GridItem>
            <Field as={Input} name="name.value" label="Наименование набора данных" />
          </GridItem>
          <GridItem>
            <Field as={Select} name="exchanges.value" options={exchanges} label="Каталог" />
          </GridItem>
          <GridItem>
            <Field as={Select} name="status.value" options={status} label="Статус" />
          </GridItem>
          <GridItem display="flex" alignItems="center">
            <Field
              as={FilterCheckbox}
              name="deleted.value"
              label="Показать удаленные"
            />
          </GridItem>
        </Grid>

        <FilterFormControls
          onReset={onReset}
          onSubmit={submitForm}
          isLoading={isLoading}
          isDisabled={isLoading}
        />
      </Flex>
    </FormikProvider>
  );
};
