import { apiPathsWaterRoot } from 'pages/data/Water/apiPath';

const WaterWPZBaseApiPath = `${apiPathsWaterRoot}/water/wpz`;

export const WaterWPZApiPaths = {
  list: `${WaterWPZBaseApiPath}/list`,
  dictionaries: `${WaterWPZBaseApiPath}/dictionaries`,
  root: `${WaterWPZBaseApiPath}`,
  getOrDelete: (id: string) => `${WaterWPZBaseApiPath}/${id}`,
  // getWaterObjectSources: (id: string) => `${apiPathsWaterRoot}/water/surfacewater/dictionaries/water_objects/${id}`,
  getAreaData: (areaId: string) => `${WaterWPZBaseApiPath}/dictionaries/areas/${areaId}`
};
