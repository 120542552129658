import { Field, useFormikContext } from 'formik';
import { Input } from 'components/common';

import { askzvLocales } from '../../locales';
import { IFormIASKZVPackage } from '../../types';
import { Grid } from '@chakra-ui/react';

export const MeasurementsTab = () => {
  const { values } = useFormikContext<IFormIASKZVPackage>();
  return (
    <Grid gap={2}>
      <Field as={Input} label={askzvLocales.waterTemperature} name="waterTemperature" value={values.waterTemperature} />
      <Field as={Input} label={askzvLocales.phIndex} name="phIndex" value={values.phIndex} />
      <Field
        as={Input}
        label={askzvLocales.electricalConductivity}
        name="electricalConductivity"
        value={values.electricalConductivity}
      />
      <Field as={Input} label={askzvLocales.dissolvedOxygen} name="dissolvedOxygen" value={values.dissolvedOxygen} />
      <Field
        as={Input}
        label={askzvLocales.chemicalOxygenDemand}
        name="chemicalOxygenDemand"
        value={values.chemicalOxygenDemand}
      />
      <Field
        as={Input}
        label={askzvLocales.ammoniumIonicConcentration}
        name="ammoniumIonicConcentration"
        value={values.ammoniumIonicConcentration}
      />
      <Field
        as={Input}
        label={askzvLocales.ironConcentration}
        name="ironConcentration"
        value={values.ironConcentration}
      />
      <Field
        as={Input}
        label={askzvLocales.manganeseConcentration}
        name="manganeseConcentration"
        value={values.manganeseConcentration}
      />
      <Field
        as={Input}
        label={askzvLocales.nitriteIonsConcentration}
        name="nitriteIonsConcentration"
        value={values.nitriteIonsConcentration}
      />
      <Field
        as={Input}
        label={askzvLocales.phosphateIonsConcentration}
        name="phosphateIonsConcentration"
        value={values.phosphateIonsConcentration}
      />
    </Grid>
  );
};
