import { FormWrapper } from 'components/common';
import { Grid } from '@chakra-ui/react';
import React, { Fragment, useEffect, useMemo } from 'react';
import { getSelectOptionsFromDictionaryByName } from 'features/get-select-options-from-dictionary';
import { useFormikContext } from 'formik';
import { TDictionariesArray } from 'models/dictionaries';
import { useLocation } from 'react-router';
import { IRenderFieldInterface, useFormField } from 'hooks/forms/useFormField';
import appLocale from 'constants/appLocale';
import { ANALYTICS_STATUS } from 'pages/analytics/forms/const';
import { VegetationDeicingItem } from 'pages/data/PGR/GreenPlants/VegetativeState/useVegDicingList';
import { AnalyticsVegStateSchema } from 'models/schemas/analytics/analyticsVegSchema';

interface CommonTabProps {
  dictionaries: {
    data: TDictionariesArray;
  } | null;
}

export const AnalyticsDeicingVegCommonTab = ({ dictionaries }: CommonTabProps) => {
  const { values, setFieldValue, initialValues } = useFormikContext<VegetationDeicingItem>();
  const location = useLocation();
  const isEditMode = location.pathname.includes('/edit');

  const { renderField } = useFormField<VegetationDeicingItem>({
    schema: AnalyticsVegStateSchema,
    labelLocalization: { ...appLocale.analytics.fields, ...appLocale.analytics.veg.fields },
  });

  const organizations = useMemo(
    () =>
      getSelectOptionsFromDictionaryByName(dictionaries?.data, 'organizations', false, [
        initialValues.consumerId,
        initialValues.organizationId,
      ]),
    [dictionaries?.data, initialValues.consumerId, initialValues.organizationId],
  );

  const areas = useMemo(() => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'areas'), [dictionaries]);

  const distances2RoadExt = useMemo(
    () =>
      getSelectOptionsFromDictionaryByName(dictionaries?.data, 'distances2RoadExt').filter(
        (item) => item.parent === values.sourceNameId,
      ),
    [dictionaries, values.sourceNameId],
  );

  useEffect(() => {
    if (values.distance2RoadId && dictionaries) {
      const point = distances2RoadExt.find((item) => item.value === values.distance2RoadId);
      if (point?.parent !== values.sourceNameId) {
        setFieldValue('distance2RoadId', null);
      }
    }
  }, [dictionaries, distances2RoadExt, setFieldValue, values.distance2RoadId, values.sourceNameId]);

  // while status is hidden assume status as draft
  let fieldsIsDisabled = values.status === ANALYTICS_STATUS.APPROVED && !!isEditMode;
  fieldsIsDisabled = false;

  const fields: IRenderFieldInterface<VegetationDeicingItem>[] = [
    { name: 'protocol', isDisabled: fieldsIsDisabled },

    { name: 'protocolDate', type: 'dateinput', isDisabled: fieldsIsDisabled },

    { name: 'consumerId', type: 'select', options: organizations, isDisabled: fieldsIsDisabled },

    { name: 'researchBasis', isDisabled: fieldsIsDisabled },

    { name: 'researchObject', isDisabled: fieldsIsDisabled },

    { name: 'supportingDocuments', isDisabled: fieldsIsDisabled },

    { name: 'sampleNumber', isDisabled: fieldsIsDisabled },

    { name: 'sourceNameId', type: 'select', options: areas, isDisabled: fieldsIsDisabled },

    {
      name: 'distance2RoadId',
      type: 'select',
      options: distances2RoadExt,
      isDisabled: fieldsIsDisabled,
    },

    { name: 'selectionDate', type: 'datetimeinput', isDisabled: fieldsIsDisabled },

    { name: 'organizationId', type: 'select', options: organizations, isDisabled: fieldsIsDisabled },

    { name: 'explanationFromConsumer', isDisabled: fieldsIsDisabled },

    { name: 'sampleReceiveDate', type: 'datetimeinput', isDisabled: fieldsIsDisabled },

    { name: 'researchDateStart', type: 'dateinput', isDisabled: fieldsIsDisabled },

    { name: 'researchDateEnd', type: 'dateinput', isDisabled: fieldsIsDisabled },

    { name: 'sampleLaboratoryNumber', isDisabled: fieldsIsDisabled },

    { name: 'explanation', isDisabled: fieldsIsDisabled },
  ];

  return (
    <FormWrapper>
      <Grid marginTop="30px" gap="15px">
        {fields.map((field) => (
          <Fragment key={field.name}>{renderField(field)}</Fragment>
        ))}
      </Grid>
    </FormWrapper>
  );
};
