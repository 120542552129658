import { FilePreviewBase, FilePreviewFieldValue } from 'models';

export interface InclinometerItemPreview extends FilePreviewBase {
  inclinometer: FilePreviewFieldValue;
  depth: FilePreviewFieldValue;
  eastOffset: FilePreviewFieldValue;
  northOffset: FilePreviewFieldValue;
  accuracy: FilePreviewFieldValue;
}

export const parametersNames: Record<any, string> = {
  Y_OFFSET: 'yOffset',
  X_OFFSET: 'xOffset',
  ACCURACY: 'accuracy'
};
