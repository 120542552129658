import { Field, useFormikContext } from 'formik';
import { IFilterItem } from 'models';
import { Checkbox, DateInput } from 'components/common';
import { Flex, Grid, GridItem } from '@chakra-ui/react';
import appLocale from 'constants/appLocale';
import React from 'react';
import { createUTCTime } from 'lib/create-date';
import FilterFormControls from 'components/form/FilterFormControls';

interface IProps {
  isLoading?: boolean;
  isDisabled?: boolean;
}

export const FormFields = ({ isLoading, isDisabled }: IProps) => {
  const { submitForm, setFieldValue, handleReset, errors, values } = useFormikContext<{ [p: string]: IFilterItem }>();

  return (
    <Flex>
      <Grid width="100%" maxWidth="1200px" gap={2} templateColumns="repeat(4, 0.25fr)">
        <Field
          as={DateInput}
          name="startDate.value"
          label={appLocale.common.from}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setFieldValue('startDate.value', createUTCTime(e.target.value))
          }
          showTimeInput
          error={errors.startDate?.value}
        />

        <Field
          as={DateInput}
          name="endDate.value"
          label={appLocale.common.to}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setFieldValue('endDate.value', createUTCTime(e.target.value))
          }
          showTimeInput
          error={errors.endDate?.value}
        />

        <GridItem colSpan={2}>
          <Field
            as={Checkbox}
            label="Показывать системные события"
            name="type.value"
            checked={!!values.type?.value}
            error={errors?.type}
            height="45px"
          />
        </GridItem>
      </Grid>

      <FilterFormControls
        onReset={(e) => {
          handleReset(e);
          setTimeout(submitForm, 0);
        }}
        onSubmit={submitForm}
        isLoading={isLoading}
        isDisabled={isDisabled}
      />
    </Flex>
  );
};
