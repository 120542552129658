import { useCallback, useEffect, useState } from 'react';

import { useSourcesDict } from './useSourcesDict';
import { useParamsDict } from './useParamsDict';

import { FilterTypes } from '../../consts';
import { SelectOption } from '../../../Select/types';

interface IUseClarifyFilters {
  subSystemId: string;
  templateId: string;
  from: string;
  to: string;
  paramsIds?: string[];
  sourcesIds?: string[];
}

export const useFiltersOptions = ({ subSystemId, templateId, from, to, sourcesIds, paramsIds }: IUseClarifyFilters) => {
  // подобный дубляж нужен для сохранения старых данных.
  const [clarifiedSources, setClarifiedSources] = useState<SelectOption[] | null>(null);
  const [clarifiedParams, setClarifiedParams] = useState<SelectOption[] | null>(null);

  const { sources, getSources, isLoadingSources } = useSourcesDict({
    subSystemId,
    isSpecified: true,
    templateId,
    from,
    to,
    paramsIds,
  });

  const { params, getParams, isLoadingParams } = useParamsDict({
    subSystemId,
    isSpecified: true,
    templateId,
    from,
    to,
    sourcesIds,
  });

  const getClarifyOptions = useCallback(
    (typeOptions: keyof typeof FilterTypes) => {
      if (typeOptions === 'sources') {
        void getSources();
      } else {
        void getParams();
      }
    },
    [getParams, getSources]
  );

  useEffect(() => {
    void getSources();
    void getParams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateOptions = useCallback(
    (options: SelectOption[] | null, prevOptions, selectedIds: string[] | undefined) => {
      const newOptions = options ?? [];
      const predicate = (id: string) => (value: SelectOption) => value.value === id;
      if (selectedIds?.length && prevOptions?.length) {
        selectedIds.forEach((id) => {
          // если новые опции не содержат этот ид
          if (!newOptions.some(predicate(id))) {
            const foundItem = prevOptions.find(predicate(id));
            if (foundItem) {
              newOptions.push(foundItem);
            }
          }
        });
      }
      return newOptions;
    },
    []
  );

  useEffect(() => {
    setClarifiedSources((prevSources) => updateOptions(sources, prevSources, sourcesIds));
  }, [sources, sourcesIds, updateOptions]);

  useEffect(() => {
    setClarifiedParams((prevParams) => updateOptions(params, prevParams, paramsIds));
  }, [params, paramsIds, sources, sourcesIds, updateOptions]);

  return {
    sources,
    params,
    clarifiedSources,
    clarifiedParams,
    isLoadingSources,
    isLoadingParams,
    isLoadingClarifiedOptions: isLoadingSources || isLoadingParams,
    getClarifyOptions,
  };
};
