import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { FormikProvider, useFormik } from 'formik';
import { useEnhancedNotification, useRequest } from 'hooks';
import { createTouchedErrors } from 'features/create-touched-errors';
import { useGoBackWithFallback } from 'lib/utils/goBackWithFallback';
import {
  dangerScaleRootBreadcrumbs,
  NSIDangerScaleApiRoot,
  NSIDangerScalePaths,
} from 'pages/dictionaries/NSIDangerScale/const';
import { INSIDangerScaleForm, INSIDangerScaleListItem } from 'pages/dictionaries/NSIDangerScale/types';
import { FormHeader } from 'components/common';
import { nsiDangerScaleSchema } from 'models/schemas/nsiDangerScale/nsiDangerScaleSchema';
import {
  NSIDangerScaleFormFields,
} from 'pages/dictionaries/NSIDangerScale/NSIDangerScaleForm/NSIDangerScaleFormFields';

export const NSIDangerScaleForm = () => {
  useUpdateBreadcrumbs(dangerScaleRootBreadcrumbs);
  const { onSuccessEdit, onSuccessCreate, onErrorCreate, onErrorEdit } = useEnhancedNotification();
  const { backWithFallback } = useGoBackWithFallback();

  const { id } = useParams<{ id: string }>();

  const onSuccess = () => {
    if (id) {
      onSuccessEdit();
    } else {
      onSuccessCreate();
    }

    backWithFallback(NSIDangerScalePaths.root);
  };

  const { result, get } = useRequest<INSIDangerScaleListItem>(`${NSIDangerScaleApiRoot}/${id}`);
  const { post } = useRequest(NSIDangerScaleApiRoot, onSuccess, onErrorCreate);
  const { put } = useRequest(NSIDangerScaleApiRoot, onSuccess, onErrorEdit);

  useEffect(() => {
    if (id) {
      get();
    }
  }, [id, get]);

  const onSubmit = (values: INSIDangerScaleForm) => {
    const body = {
      ...values,
      rangeEnd: parseFloat(values.rangeEnd),
      rangeStart: parseFloat(values.rangeStart),
    };

    if (id) {
      put({ ...body, id });
    } else {
      post({ ...body });
    }
  };

  const initialValues: INSIDangerScaleForm = {
    dangerScale: '',
    dutyScale: '',
    rangeEnd: '',
    rangeEndInclusive: false,
    rangeStart: '',
    rangeStartInclusive: false,
  };

  const {
    errors,
    ...formik
  } = useFormik({
    validationSchema: nsiDangerScaleSchema,
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: false,
    validateOnBlur: true,
    initialValues: result ? {
      ...initialValues,
      ...result,
      rangeStart: result.rangeStart !== null ? String(result.rangeStart) : '',
      rangeEnd: result.rangeEnd !== null ? String(result.rangeEnd) : '',
    } : initialValues,
    onSubmit,
  });

  return (
    <FormikProvider
      value={{
        errors: createTouchedErrors(errors, formik.touched),
        ...formik,
      }}
    >
      <FormHeader
        onBackClick={() => backWithFallback(NSIDangerScalePaths.root)}
        header={`${!id ? 'Добавление' : 'Редактирование'} записи`}
      />

      <NSIDangerScaleFormFields />
    </FormikProvider>
  );
};
