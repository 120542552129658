import { useCallback } from 'react';
import { useNotifications } from 'hooks/useNotifications';
import { useRequest } from 'hooks/useRequest';
import { forEach, reduce, values } from 'lodash';
import { ApiError } from 'models';
import { DeicingGreenPlantsRootPath } from 'pages/routes/PGRGreenPlants';
import { SUCCESS_MESSAGES } from 'constants/messages';
import { ReagentsApiGreenplants } from 'pages/data/PGR/consts';
import { TGreenPlantsForm, TParamsToPost } from 'pages/data/GreenPlants/Form/types';

const useFloraAddItem = () => {
  const { successNotify, errorNotify } = useNotifications();

  const { result, isLoading, error, post } = useRequest<any, ApiError>(
    `${ReagentsApiGreenplants}/flora`,
    () => {
      successNotify({
        key: `${DeicingGreenPlantsRootPath}/flora/add/success`,
        message: SUCCESS_MESSAGES.CREATE,
      });
    },
    (error) => {
      forEach(error?.descriptions, (desc) => {
        errorNotify({
          key: `${DeicingGreenPlantsRootPath}/flora/add/error`,
          message: String(desc.message),
        });
      });
    }
  );

  const addFlora = useCallback(
    (request?: TGreenPlantsForm['flora']) => {
      const parameters = reduce(
        values(request?.parameters),
        (acc, current) => {
          if (current) {
            if (current.parameterId) {
              let currentValues = [];
              if (Array.isArray(current.value)) {
                currentValues = current.value.map((val, idx) => {
                  const values: TParamsToPost = {
                    parameterId: current.parameterId,
                    value: val,
                  };
                  if (current.dictionaryItemId && current.dictionaryItemId[idx]) {
                    values.dictionaryItemId = current.dictionaryItemId[idx];
                  }
                  return {
                    value: val,
                    dictionaryItemId: current.dictionaryItemId ? current.dictionaryItemId[idx] : null,
                    parameterId: current.parameterId,
                  };
                });
              } else {
                currentValues = [
                  {
                    dictionaryItemId: current.dictionaryItemId ? current.dictionaryItemId[0] : null,
                    value: current.value,
                    parameterId: current.parameterId,
                  },
                ];
                if (current.dictionaryItemId && current.dictionaryItemId[0]) {
                  const [dictionaryItemId] = current.dictionaryItemId;
                  currentValues[0].dictionaryItemId = dictionaryItemId;
                }
              }

              return { ...acc, [current.parameterId]: currentValues };
            }
          }
          return acc;
        },
        {}
      );

      return post({ ...request, parameters });
    },
    [post]
  );

  return { addFlora, result, isLoading, error };
};

export { useFloraAddItem };
