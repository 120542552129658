import { useRequest } from 'hooks/useRequest';
import { TDictionariesArray } from 'models/dictionaries';
import { useEffect } from 'react';
import { baseAdminUrl } from 'pages/admin';
import { useUtilDictionaries } from 'hooks/useUtilDictionaries';

export function useCatalogDictionary() {
  const { get, isLoading, result, error } = useRequest<{
    data: TDictionariesArray;
  }>(`${baseAdminUrl}/exchange-config/dictionary`);

  useEffect(() => {
    get();
  }, [get]);

  const { status, direction, servers } = useUtilDictionaries(result?.data ?? []);

  return { isLoading, result, error, status, direction, servers };
}
