import * as React from 'react';
import { ReactNode } from 'react';
import { Checkbox as ChakraCheckbox, forwardRef, StyleProps } from '@chakra-ui/react';
import { FormikFieldProps } from '../types';

export interface CheckboxProps extends StyleProps {
  label?: ReactNode;
  isDisabled?: boolean;
  defaultChecked?: boolean;
}

export const Checkbox: React.FC<CheckboxProps & Partial<FormikFieldProps>> = forwardRef(
  ({ label, onChange, isDisabled = false, defaultChecked = false, ...props }, ref) => (
    <ChakraCheckbox
      p={`${21 / 2}px`}
      ref={ref}
      {...props}
      isChecked={props.checked}
      isDisabled={isDisabled}
      defaultChecked={defaultChecked}
      onChange={(e) => {
        e.stopPropagation();
        if (onChange) {
          onChange(e);
        }
      }}
    >
      {label}
    </ChakraCheckbox>
  ),
);
