import { WaterRouteRoot, WaterWPZListRoot } from 'pages/data/Water/routes';
import appLocale from 'constants/appLocale';

const tableBreadcrumbs = [
  { breadcrumb: 'Данные', path: '/data' },
  {
    breadcrumb: 'Водные объекты',
    path: WaterRouteRoot,
  },
  {
    breadcrumb: appLocale.subsystems.wpz,
    path: WaterWPZListRoot,
  },
];

const formBreadcrumbs = (path: string) => [
  ...tableBreadcrumbs,
  {
    breadcrumb: appLocale.groundCoast.formTitle,
    path
  },
];

export { tableBreadcrumbs, formBreadcrumbs };
