import React from 'react';
import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import { TFiltersArray } from 'models';
import { useList } from 'hooks/useList';
import appLocale from 'constants/appLocale';
import { GeoecologicalRootPath } from 'pages/data/GeoecologicalProcesses/consts';
import {
  DangerouslyGeologicalProcessesProtocolHeader,
} from 'pages/data/GeoecologicalProcesses/DangerouslyGeologicalProcesses/Protocol/components/DangerouslyGeologicalProcessesProtocolHeader';
import { ReperFilter } from './ReperFilter';
import { ValueItem } from 'pages/data/GeoecologicalProcesses/DangerouslyGeologicalProcesses/Protocol/types';
import ProtocolTable from '../components/ProtocolTable';
import subsystemIdsMap from 'constants/subsystemIdsMap';
import { useAvailability } from 'hooks';
import { usePeriodsDict } from 'features/PeriodsButtons/usePeriodsDict';
import { useDictionary } from 'hooks/useDictionary';
import { restorePreviewColumns } from 'components/common/Modals/ProtocolModal';
import { FullPageWrapper } from '../../../../../../components/table/FullPageWrapper';
import { DEFAULT_PAGE_SIZE_NEW_TABLE } from '../../../../../../constants/tables';

export const ProtocolReper = () => {
  const { isExecute, isNoRights, isAvailable } = useAvailability(subsystemIdsMap.geoecologyRoot);

  useUpdateBreadcrumbs([
    { breadcrumb: appLocale.common.data, path: '/data' },
    {
      breadcrumb: appLocale.subsystems.geoecologicalprocesses,
      path: GeoecologicalRootPath,
    },
    {
      breadcrumb: `${appLocale.common.dataProtocol} (ОГП)`,
      path: `${GeoecologicalRootPath}/monitoring/protocol/danger/reper`,
    },
  ]);

  const { dictionaries } = useDictionary({ url: '/geoecology/v10/geoecology/reper/dictionaries' });

  const {
    refetch,
    isLoading: isLoadingReperList,
    response,
    setFilters,
    toggleOrder,
    onPageChange,
    filterDTO,
    downloadAsFile,
    saveFilters,
    errorText,
    materialTableProps,
  } = useList<ValueItem>('/geoecology/v10/geoecology/reper/list/values', {
    initialOrderFieldKey: '-valueDate',
    preventFirstFetch: true,
    defaultPageSize: DEFAULT_PAGE_SIZE_NEW_TABLE,
    resetDataOnNextRequest: true,
  });

  const { result: periods } = usePeriodsDict(`auth/v10/user/period/${subsystemIdsMap.dangerprocesses}`);
  return (
    <FullPageWrapper>
      <DangerouslyGeologicalProcessesProtocolHeader active="reper">
        <ReperFilter
          filters={filterDTO?.filters || []}
          periods={periods}
          isLoading={isLoadingReperList}
          isDisabled={isLoadingReperList}
          dictionaries={dictionaries}
          onSubmit={(values) => {
            const filters: TFiltersArray = Object.values(values);
            setFilters(filters);
          }}
        />
      </DangerouslyGeologicalProcessesProtocolHeader>
      <ProtocolTable
        saveFilters={saveFilters}
        isAvailable={isAvailable}
        isExecute={isExecute || isNoRights}
        filters={filterDTO.filters}
        setFilters={setFilters}
        refetch={refetch}
        list={response}
        toggleOrder={toggleOrder}
        onPageChange={onPageChange}
        filterDTO={filterDTO}
        downloadAsFile={downloadAsFile}
        isLoading={isLoadingReperList}
        errorText={errorText}
        baseUrl="/geoecology/v10/geoecology/reper"
        previewColumns={restorePreviewColumns.geoDangerouseProcesses}
        materialTableProps={materialTableProps}
      />
    </FullPageWrapper>
  );
};
