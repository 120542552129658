import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { SubsystemHeader } from 'components/common';
import { RolePage } from './RolePage';
import { PrivelegesPage } from './PrivelegesPage';
import { useParams } from 'react-router';

export const EditRoleForm = () => {
  const { id } = useParams<{
    id: string;
  }>();
  return (
    <>
      <SubsystemHeader title="Редактирование роли" />
      <Tabs isLazy>
        <TabList>
          <Tab>Роль</Tab>
          <Tab isDisabled={!id}>Привилегии</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <RolePage id={id} />
          </TabPanel>
          <TabPanel>
            <PrivelegesPage id={id} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </>
  );
};
