import { NSIRootPath } from 'pages/dictionaries/const/routes';
import appLocale from 'constants/appLocale';

export const NSIDangerScaleApiRoot = '/nsi/v10/sources/soil/danger_scale';
const NSIDangerScaleRoot = `${NSIRootPath}/danger_scale`;

export const NSIDangerScalePaths = {
  root: NSIDangerScaleRoot,
  add: `${NSIDangerScaleRoot}/add`,
  edit: (id: string) => `${NSIDangerScaleRoot}/edit/${id}`,
};

export const dangerScaleRootBreadcrumbs = [
  {
    breadcrumb: appLocale.mainPage.nsi,
    path: NSIRootPath,
  },
  {
    breadcrumb: appLocale.nsi.dangerScale.title,
    path: NSIDangerScalePaths.root,
  },
];
