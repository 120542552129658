import { NoisePackageFormOctavesTab } from 'pages/data/MASKSH/Data/Noise/NoisePackageForm/Form/Tabs/NoisePackageFormOctavesTab';
import { NoisePackageFormCommonTab } from 'pages/data/MASKSH/Data/Noise/NoisePackageForm/Form/Tabs/NoisePackageFormCommonTab';
import { NoisePackageFormNoiseTab } from 'pages/data/MASKSH/Data/Noise/NoisePackageForm/Form/Tabs/NoisePackageFormNoiseTab';
import { NoisePackageFormSelfTab } from 'pages/data/MASKSH/Data/Noise/NoisePackageForm/Form/Tabs/NoisePackageFormSelfTab';

export const NoisePackageFormTabs = {
  Common: NoisePackageFormCommonTab,
  Noise: NoisePackageFormNoiseTab,
  Self: NoisePackageFormSelfTab,
  Octaves: NoisePackageFormOctavesTab,
};
