import { Text, Tooltip } from '@chakra-ui/react';
import { truncate, TruncateOptions } from 'lodash';
import React from 'react';

interface TableTooltipProps {
  tooltip: string;
  trunacteOptions?: TruncateOptions;
}

const TableTooltip: React.FC<TableTooltipProps> = ({ tooltip, trunacteOptions = { length: 30 } }) => {
  const truncated = truncate(tooltip, trunacteOptions);

  if (tooltip.length > truncated.length) {
    return <Tooltip label={tooltip}>{truncate(tooltip, trunacteOptions)}</Tooltip>;
  }
  return <Text>{tooltip}</Text>;
};

const TableTooltipFileName: React.FC<TableTooltipProps> = ({
  tooltip,
  trunacteOptions = {
    length: 30,
    omission: `..${tooltip.substring(tooltip.lastIndexOf('.'), tooltip.length)}`,
  },
}) => <TableTooltip tooltip={tooltip} trunacteOptions={trunacteOptions} />;

export type { TableTooltipProps };

export { TableTooltip, TableTooltipFileName };
