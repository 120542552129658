import React, { useMemo, useState } from 'react';
import { FTPHeader } from '../components';
import { CatalogFilter } from './Filter/CatalogFilter';
import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import appLocale from 'constants/appLocale';
import { baseAdminUrl } from 'pages/admin';
import { configError, DataSourcesList, FTPRootPath, FTPSettings } from 'pages/admin/FTP';
import { ConfirmModal } from 'components/common/Modals';
import { BLOCK_CONFIRMATION, BLOCK_ERROR, BLOCK_SUCCESS, UNBLOCK_ERROR, UNBLOCK_SUCCESS } from 'constants/messages';
import { useRequest } from 'hooks/useRequest';
import { useNotifications } from 'hooks/useNotifications';
import { useHistory } from 'react-router-dom';
import { useDisclosure, useHandleDeleteData, useList } from 'hooks';
import { MaterialTable } from 'features/material-table/MaterialTableMini';
import { TFiltersArray } from 'models';
import { BlockIcon, UnlockIcon } from 'components/icons';
import { useCatalogDictionary } from './useCatalogDictionary';
import { Column } from '@material-table/core';
import { dictionaryToMap } from 'lib/utils/dictionaryToMap';
import { FullPageWrapper } from '../../../../../components/table/FullPageWrapper';
import { DEFAULT_PAGE_SIZE_NEW_TABLE } from '../../../../../constants/tables';

export const Catalogs = () => {
  const history = useHistory();

  const { result: dictionaries, isLoading: isDictLoading, status, direction } = useCatalogDictionary();
  const statusMap = dictionaryToMap(status);
  const directionMap = dictionaryToMap(direction);

  const [blockItem, setBlockItem] = useState<DataSourcesList>();
  const {
    refetch,
    isLoading: isListLoading,
    response,
    toggleOrder,
    setFilters,
    onPageChange,
    filterDTO,
  } = useList<DataSourcesList>(`${baseAdminUrl}/exchange-config/list`, {
    initialOrderFieldKey: '-name',
    defaultPageSize: DEFAULT_PAGE_SIZE_NEW_TABLE,
    resetDataOnNextRequest: true,
  });

  useUpdateBreadcrumbs([
    {
      breadcrumb: appLocale.admin.main,
      path: '/admin',
    },
    {
      breadcrumb: appLocale.admin.ftp,
      path: FTPRootPath,
    },
    {
      breadcrumb: appLocale.admin.ftpSettings,
      path: `${FTPSettings}/servers`,
    },
  ]);
  const { successNotify, errorNotify } = useNotifications();

  const { isOpen: isBlockOpen, onOpen: onBlockOpen, onClose: onBlockClose } = useDisclosure();

  const onBlockSuccess = () => {
    refetch();
    successNotify({
      key: `file/block/success/${blockItem?.status}`,
      message: !blockItem?.status ? UNBLOCK_SUCCESS : BLOCK_SUCCESS,
    });
  };

  const onBlockError = (error: any) => {
    /* Заглушка под определенную ошибку */
    if (error.descriptions[0].message === configError) {
      onBlockSuccess();
      return;
    }
    errorNotify({
      key: `file/block/error/${blockItem?.status}`,
      message: !blockItem?.status ? UNBLOCK_ERROR : BLOCK_ERROR,
    });
  };

  const { put, isLoading: isBlockLoading } = useRequest(
    `${baseAdminUrl}/exchange-config`,
    onBlockSuccess,
    onBlockError
  );

  const { handleOpenModal, ModalComponent } = useHandleDeleteData({ refetch });

  const onBlock = () => {
    const item = { ...blockItem };
    item.status = !item.status;
    put(item);
    onBlockClose();
  };

  const isLoading = useMemo(
    () => isBlockLoading || isDictLoading || isListLoading,
    [isBlockLoading, isDictLoading, isListLoading]
  );

  const columns = useMemo<Column<DataSourcesList>[]>(
    () => [
      {
        title: 'Наименование источника',
        field: 'name',
        width: 400,
      },
      {
        title: 'Статус',
        field: 'status',
        render: (row) => statusMap.get(String(row.status)),
      },
      {
        title: 'Путь к каталогу',
        field: 'ftpPath',
      },
      {
        title: 'Воздействие',
        field: 'direction',
        render: (row) => directionMap.get(String(row.direction)),
      },
    ],
    [directionMap, statusMap]
  );

  return (
    <FullPageWrapper>
      <FTPHeader
        active="catalogs"
        url="/add"
        filtersComponent={
          <CatalogFilter
            isLoading={isLoading}
            dictionaries={dictionaries}
            onSubmit={(values) => {
              const filters: TFiltersArray = Object.values(values);
              setFilters(filters);
            }}
          />
        }
      />

      <MaterialTable
        filterDTO={filterDTO}
        isLoading={isLoading}
        data={response?.data}
        meta={response?.meta}
        toggleOrder={toggleOrder}
        onPageChange={onPageChange}
        totalCount={response?.meta.totalCount}
        title="Источники данных"
        columns={columns}
        actions={[
          {
            type: 'edit',
            onClick: (_e, item) => {
              history.push(`${FTPSettings}/catalogs/edit/${item.id}`, item);
            },
          },
          {
            type: 'delete',
            onClick: (_e, item) => {
              if (item.id) {
                handleOpenModal({ url: `${baseAdminUrl}/exchange-config/${item.id}` });
              }
            },
          },
          {
            position: 'row',
            action: (row) => ({
              icon: () => (row.status ? <BlockIcon /> : <UnlockIcon />),
              onClick: (_e: any, item: any) => {
                onBlockOpen();
                setBlockItem(item);
              },
              tooltip: row.status ? 'Заблокировать' : 'Разблокировать',
            }),
          },
        ]}
        options={{ selection: false }}
      />

      {ModalComponent}

      <ConfirmModal
        isOpen={isBlockOpen}
        onClose={onBlockClose}
        onClick={onBlock}
        header={!blockItem?.status ? BLOCK_CONFIRMATION.ACTIVE : BLOCK_CONFIRMATION.BLOCKED}
      />
    </FullPageWrapper>
  );
};
