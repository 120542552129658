import * as yup from 'yup';
import { requiredError } from '../constans';

export const ASKZACommonSchema = yup.object().shape({
  parameterId: yup.string().nullable().required(requiredError),
  stationId: yup.string().nullable().required(requiredError),
  avg: yup.object().shape({
    datetime: yup.string().nullable().required(requiredError),
    value: yup.string().nullable().required(requiredError),
    // strValue: yup.number().notOneOf([NaN], numberError).typeError(numberError).required(requiredError),
    // version: yup.number(),
  }),
  max: yup.object().shape({
    datetime: yup.string().nullable().required(requiredError),
    value: yup.string().nullable().required(requiredError),
    // strValue: yup.number().notOneOf([NaN], numberError).typeError(numberError).required(requiredError),
    // version: yup.number(),
  }),
  min: yup.object().shape({
    datetime: yup.string().nullable().required(requiredError),
    value: yup.string().nullable().required(requiredError),
    // strValue: yup.number().notOneOf([NaN], numberError).typeError(numberError).required(requiredError),
    // version: yup.number(),
  }),
});
