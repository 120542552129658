import React, { useEffect, useMemo } from 'react';
import { SubsystemHeader } from 'components/common';
import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import { MaterialTable } from 'features/material-table/MaterialTableMini';
import { createLocalDateDDMMYYYYWithTime } from 'lib/create-date';
import { useList } from 'hooks/useList';
import appLocale from 'constants/appLocale';
import { TodayFilter } from 'pages/analyticsReports/DataPublishing/Questionnaire/Filter';
import { useDictionary } from 'hooks/useDictionary';
import {
  AirQualitySurveyReportDto,
  AirQualitySurveyReportSummaryDto,
} from 'pages/analyticsReports/DataPublishing/Questionnaire/types';
import { Column } from '@material-table/core';
import { useRequest } from 'hooks';
import { Grid, Stat, StatLabel, StatNumber, useDisclosure } from '@chakra-ui/react';
import { SendToMailModal } from '../../../../components/common/GraphicDropdownMenu/SendToMailModal';
import { FullPageWrapper } from '../../../../components/table/FullPageWrapper';
import { DEFAULT_PAGE_SIZE_NEW_TABLE } from '../../../../constants/tables';
import { DefaultTableCellSize } from '../../../../components/table/DefaultTableCellSize';

const Questionnaire = () => {
  useUpdateBreadcrumbs([
    { breadcrumb: appLocale.subsystems.analyticsReports, path: '/reports-analytics' },
    { breadcrumb: appLocale.analyticsReports.dataPublishing, path: '/reports-analytics/data-publishing' },
    { breadcrumb: appLocale.analyticsReports.questionnaire, path: '/reports-analytics/data-publishing/questionnaire' },
  ]);

  const { post, result: summary } = useRequest<AirQualitySurveyReportSummaryDto>(
    '/reporting/v10/moseco/air_quality_survey/report/summary/search',
  );

  const { dictionaries } = useDictionary({ url: '/reporting/v10/moseco/air_quality_survey/report/dictionaries' });

  const {
    isLoading: isEmissionsListLoading,
    response,
    toggleOrder,
    onPageChange,
    filterDTO,
    downloadAsFile,
    headers,
    getHeaders,
    resetHeaders,
    errorText,
    selectedIndexes,
    renderOnlySelectedCheckbox,
    onSubmitFilters,
    onSelectionChange,
  } = useList<AirQualitySurveyReportDto, keyof AirQualitySurveyReportDto>(
    '/reporting/v10/moseco/air_quality_survey/report/list/search',
    {
      initialOrderFieldKey: '-odorDate',
      selectionKey: 'id',
      defaultPageSize: DEFAULT_PAGE_SIZE_NEW_TABLE,
      resetDataOnNextRequest: true,
    },
  );

  useEffect(() => {
    post({
      filters: filterDTO.filters,
      searchFields: filterDTO.searchFields,
      offset: 0,
      searchString: filterDTO.searchString,
    });
  }, [filterDTO, post]);

  const columns = useMemo<Column<AirQualitySurveyReportDto>[]>(
    () => [
      {
        title: 'Дата',
        field: 'odorDate',
      },
      {
        title: 'Округ',
        field: 'districtId',
        render: (row) => row.districtText,
      },
      {
        title: 'Район',
        field: 'regionId',
        render: (row) => row.regionText,
      },
      {
        title: 'Адрес появления запаха',
        field: 'address',
        render: ({ address }) => <DefaultTableCellSize>{address}</DefaultTableCellSize>,
      },
      {
        title: 'Характер запаха',
        field: 'odorType',
        render: (row) => row.odorTypeText,
      },
      {
        title: 'Временной интервал 1',
        field: 'timeInterval1',
        render: (row) => row.timeInterval1Text,
      },
      {
        title: 'Временной интервал 2',
        field: 'timeInterval2',
        render: (row) => row.timeInterval2Text,
      },
      {
        title: 'Периодичность появления запахов',
        field: 'frequency',
        render: (row) => row.frequencyText,
      },
      {
        title: 'Комментарий',
        field: 'additionalComment',
        render: ({ additionalComment }) => <DefaultTableCellSize>{additionalComment}</DefaultTableCellSize>,
      },
      {
        title: 'Тех. столбец',
        field: 'registeredTimestamp',
        render: (row) => createLocalDateDDMMYYYYWithTime(row.registeredTimestamp),
      },
    ],
    [],
  );

  const uploadModalDisclosure = useDisclosure();

  return (
    <FullPageWrapper>
      <SubsystemHeader
        title={appLocale.analyticsReports.questionnaire}
        filtersComponent={
          <TodayFilter
            dictionaries={dictionaries}
            onSubmit={(values) => {
              onSubmitFilters(Object.values(values));
            }}
          >
            {renderOnlySelectedCheckbox}
          </TodayFilter>
        }
      />

      {summary && (
        <Grid templateColumns="repeat(6, 1fr)" paddingLeft={5} paddingRight={5} gap={2} marginTop={2} marginBottom={2}>
          {summary?.data.map((item, index) => (
            <Stat key={index}>
              <StatLabel>{item.odorTypeText}</StatLabel>
              <StatNumber>{item.percentage}%</StatNumber>
            </Stat>
          ))}
          <Stat>
            <StatLabel>Общее количество:</StatLabel>
            <StatNumber>{summary?.totalCount}</StatNumber>
          </Stat>
        </Grid>
      )}

      <MaterialTable
        filterDTO={filterDTO}
        data={response?.data}
        meta={response?.meta}
        toggleOrder={toggleOrder}
        onPageChange={onPageChange}
        isLoading={isEmissionsListLoading}
        columns={columns}
        downloadAsFile={downloadAsFile}
        onSelectionChange={onSelectionChange}
        selectedIndexes={selectedIndexes}
        getHeaders={getHeaders}
        onOpenUploadModal={uploadModalDisclosure.onOpen}
        errorText={errorText}
      />

      <SendToMailModal
        onClose={uploadModalDisclosure.onClose}
        headers={headers}
        isOpen={uploadModalDisclosure.isOpen}
        resetHeaders={resetHeaders}
        filename={appLocale.analyticsReports.questionnaire}
      />
    </FullPageWrapper>
  );
};

export { Questionnaire };
