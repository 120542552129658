import { ANALYTICS_STATUS } from 'pages/analytics/forms/const';

export const initialEmptyValues = {
  protocolNumber: '',
  protocolDate: null,
  consumerId: null,
  researchBasis: '',
  researchObject: '',
  address: '',
  samplePlace: '',
  organizationId: null,
  document: '',
  actNumber: '',
  sampleRegistryNumber: '',
  samplePeriodStart: null,
  samplePeriodEnd: null,
  sampleDate: null,
  researchDateStart: null,
  researchDateEnd: null,
  explanation: '',
  supportingDocuments: '',

  status: ANALYTICS_STATUS.DRAFT,
  laboratory: true,
  sign: null,
  links: null,
  values: [],
};
