import { Flex, Grid, Radio, RadioGroup, VStack } from '@chakra-ui/react';
import { SelectPeriod } from 'components/common/Graphics/components/SelectPeriod';
import EnhancedDateInput from 'components/common/DateInput/EnhancedDateInput';
import { createDateFromResponse, createUTCTime } from 'lib/create-date';
import React, { useEffect } from 'react';
import { Field, FormikErrors, useFormikContext } from 'formik';
import { Button, Select } from 'components/common';
import { useSelectPeriod } from 'components/common/Graphics/hooks/useSelectPeriod';
import moment from 'moment';
import { periods as defaultPeriods } from 'components/common/Graphics/consts';
import { IPeriod } from 'features/PeriodsButtons/types';
import { IASKZAEmissionsFilterForm } from '../ASKZAEmissions/ASKZAEmissionsFilter';

export interface LineChartDateForm {
  dateFrom: string | null;
  dateTo: string | null;
  period: string | null;
  type?: string;
}

interface IProps {
  requestDictionaries: () => void;
  isDictionariesLoading: boolean;
  disableType?: boolean;
  periods?: IPeriod[] | null;
  errors: FormikErrors<IASKZAEmissionsFilterForm>;
}

const PeriodSelect = ({ requestDictionaries, isDictionariesLoading, disableType, periods, errors }: IProps) => {
  const { period, handleChangePeriod } = useSelectPeriod();
  const { values, setFieldValue } = useFormikContext<LineChartDateForm>();

  useEffect(() => {
    if (period) {
      setFieldValue(
        'dateFrom',
        createUTCTime(
          moment().startOf('day').utc().subtract(defaultPeriods[period].amount, defaultPeriods[period].units)
            .format()
        )
      );
      setFieldValue('dateTo', createUTCTime(moment().startOf('day').utc().format()));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [period]);

  const handleChangePeriods = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setFieldValue('period', event.target.value);
    if (event.target.value) {
      const selected = periods?.find((p) => p.id === event.target.value);
      setFieldValue('dateFrom', selected?.begin);
      setFieldValue('dateTo', selected?.end);
    }
  };

  return (
    <Flex border="1px solid #000" p={2} width="100%" backgroundColor="rgb(223, 237, 214)" alignItems="center">
      <Grid mr={2} gap="20px" templateColumns="260px 260px 260px 200px">
        <div
          style={{
            marginTop: '28px',
          }}
        >
          {periods ? (
            <Select
              label="Выберите промежуток"
              options={periods.map((p) => ({ value: p.id, ...p }))}
              onChange={handleChangePeriods}
              value={values.period}
            />
          ) : (
            <SelectPeriod value={period} onChange={handleChangePeriod} predicate="isShowInFilter" />
          )}
        </div>

        <EnhancedDateInput
          name="dateFrom"
          label="От"
          value={createDateFromResponse(values.dateFrom)}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setFieldValue('dateFrom', createUTCTime(e.target.value))
          }
          showTimeInput
          error={errors?.dateFrom}
        />

        <EnhancedDateInput
          name="dateTo"
          label="До"
          value={createDateFromResponse(values.dateTo)}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFieldValue('dateTo', createUTCTime(e.target.value))}
          showTimeInput
          error={errors?.dateTo}
        />

        {!disableType && (
          <Field
            as={RadioGroup}
            name="type"
            onChange={(value: string) => setFieldValue('type', value)}
            style={{
              marginTop: '8px',
            }}
          >
            <VStack alignItems="flex-start">
              <Radio backgroundColor="#fff" value="MAX">
                Максимум
              </Radio>
              <Radio backgroundColor="#fff" value="AVG">
                Среднее
              </Radio>
              <Radio backgroundColor="#fff" value="MIN">
                Минимум
              </Radio>
            </VStack>
          </Field>
        )}
      </Grid>

      <Button
        ml="auto"
        disabled={periods ? false : !values.dateFrom || !values.dateTo || !!errors.dateFrom}
        onClick={requestDictionaries}
        variant="solid"
        isLoading={isDictionariesLoading}
      >
        Запросить данные
      </Button>
    </Flex>
  );
};

export default PeriodSelect;
