import { useHistory, useParams } from 'react-router-dom';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { IUploadMedia } from 'components/common/FileUploadDragAndDrop/types';
import { FormikProvider, useFormik } from 'formik';
import { createTouchedErrors } from 'features/create-touched-errors';
import { useEnhancedNotification, useRequest } from 'hooks';
import { useObjectType } from 'hooks/useObjectType';
import { updateFiles } from 'api/services/media';
import { LoaderItem } from 'components/common';
import { IReportProtocolForm, ReportProtocolGetDto } from 'pages/analytics/Raids/types';
import {
  AnalyticsPelAtmosphereFormFields,
} from 'pages/analytics/Raids/Pel/PelAtmoshphere/AnalyticsPelAtmosphereFormFields';
import { formatFormForRequest, formatRequestToForm } from 'pages/analytics/Raids/utils';
import { ANALYTICS_LAB_PATHS } from 'pages/analytics/consts/AnalyticsTableRoutes';
import { AnalyticsPelAtmosphereSchema } from 'models/schemas/analytics/analyticsPelAtmosphereSchema';
import { generateUUID } from 'lib/utils/generateUUID';
import { useGoBackWithFallback } from 'lib/utils/goBackWithFallback';
import { useDictionary } from 'hooks/useDictionary';

const AnalyticsPelAtmosphereEdit = () => {
  const [browserId] = useState(generateUUID());
  const { id } = useParams<{ id: string }>();
  const { replace } = useHistory();
  const { backWithFallback } = useGoBackWithFallback();
  const [media, setMedia] = useState<IUploadMedia[]>([]);
  const {
    isDictionariesLoading,
    objectType,
    dictionaries
  } = useDictionary({ url: '/appeal/v10/protocols/dictionaries' });

  const meteoParametersIds = useMemo(
    () =>
      dictionaries?.data
        .find((dictionary) => dictionary.name === 'airMeteoParameters')
        ?.dictionary?.map((item) => item.value) || [],
    [dictionaries]
  );
  const _objectType = useObjectType(objectType);

  const { onSuccessEdit, onErrorEdit } = useEnhancedNotification();

  const {
    get,
    result: protocol,
    isLoading: isItemLoading,
  } = useRequest<ReportProtocolGetDto>(`/appeal/v10/protocols/${id}`);

  useEffect(() => {
    void get();
  }, [get]);

  useEffect(() => {
    if (protocol?.ai) {
      replace(`${ANALYTICS_LAB_PATHS.raids.ai.edit}/${protocol.id}`);
    }
  }, [protocol, replace]);

  const onSuccess = (response: ReportProtocolGetDto | null) => {
    if (response?.id) {
      onSuccessEdit();
      updateFiles(response.id, _objectType, media)
        .then(() => {
          backWithFallback('/analytics');
        })
        .catch((error: Error) => {
          onErrorEdit(error.message);
        });
    }
  };

  const { put } = useRequest('', onSuccess, onErrorEdit);

  const initialValues = useMemo<IReportProtocolForm>(
    () =>
      formatRequestToForm(
        // TODO: странности на бекенде - вопросы к Алексею Лахтадыру
        // @ts-ignore
        { ...protocol, latitude: protocol?.sampleLatitude, longitude: protocol?.sampleLongitude },
        meteoParametersIds
      ),
    [meteoParametersIds, protocol]
  );

  const formOnSubmit = useCallback(
    async (values: IReportProtocolForm) => {
      await put(formatFormForRequest(values), { url: '/appeal/v10/protocols' });
    },
    [put]
  );

  const formik = useFormik<IReportProtocolForm>({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    validationSchema: AnalyticsPelAtmosphereSchema,
    initialValues,
    onSubmit: formOnSubmit,
  });

  if (isDictionariesLoading || isItemLoading) {
    return <LoaderItem />;
  }

  return (
    <div>
      <FormikProvider
        value={{
          ...formik,
          errors: createTouchedErrors(formik.errors, formik.touched),
        }}
      >
        <AnalyticsPelAtmosphereFormFields
          dictionaries={dictionaries}
          media={media}
          onMediaChange={setMedia}
          objectType={_objectType}
          isLoading={isDictionariesLoading}
          isEditMode
          browserId={browserId}
        />
      </FormikProvider>
    </div>
  );
};

export default AnalyticsPelAtmosphereEdit;
