import { FieldArray, useFormikContext } from 'formik';
import { Button } from 'components/common';
import { TDictionariesArray } from 'models/dictionaries';
import { IParameterItem, paramsLocale } from '../../consts';
import { AddIcon, DeleteIcon } from 'components/icons';
import { SubsystemItem } from './SubsystemItem';
import { Box, Flex } from '@chakra-ui/react';

interface Props {
  dictionaries?: TDictionariesArray;
  isEditMode?: boolean;
}

export const SubsystemTab = ({ dictionaries, isEditMode }: Props) => {
  const { values, errors } = useFormikContext<IParameterItem>();

  const newSubsystem = {
    subsystemId: '',
    moduleId: '',
    unitId: '',
    unitDescription: '',
    accuracy: '',
    aliases: [],
    inFile: false,
    inListing: false,
    inProtocol: false,
    inExport: false,
    inOutside: false,
    fieldType: '',
    type: '',
  };

  return (
    <FieldArray name="relations">
      {({ push, remove }) => (
        <Box>
          {values.relations.map((relation, idx) => (
            <Flex alignItems="center" marginBottom={3}>
              <SubsystemItem index={idx} dictionaries={dictionaries} errors={errors} isEditMode={isEditMode} />

              {!isEditMode && (
                <Button
                  leftIcon={<DeleteIcon />}
                  onClick={() => remove(idx)}
                  variant="ghost"
                  size="sm"
                  color="PrimaryButton.500"
                />
              )}
            </Flex>
          ))}

          {!isEditMode && (
            <Button
              alignSelf="flex-end"
              leftIcon={<AddIcon />}
              color="PrimaryButton.500"
              onClick={() => push(newSubsystem)}
              variant="ghost"
            >
              {paramsLocale.subsystemAdding}
            </Button>
          )}
        </Box>
      )}
    </FieldArray>
  );
};
