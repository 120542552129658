import { Button, DateInput, FormWrapper, Input } from 'components/common';
import { Box, Flex, Grid, GridItem, Heading } from '@chakra-ui/react';
import React, { Dispatch, SetStateAction, useCallback, useEffect, useMemo } from 'react';
import { Field, useFormikContext } from 'formik';
import { IReportProtocolForm } from '../types';
import { AddIcon, DeleteIcon, OnMap } from 'components/icons';
import { TDictionariesArray } from 'models';
import { getSelectOptionsFromDictionaryByName } from 'features/get-select-options-from-dictionary';
import { extraParametersIds, pointCreator, pointDynamicValuesCreator } from './Utils';
import { createDateFromResponse, createDateToRequestWithTimezone } from 'lib/create-date';
import { ExtraValue, IFormikPoint, IPointDynamicValue, IPointValue } from './types';
import MapFormField from 'components/form/MapFormField';
import { IUploadMedia } from 'components/common/FileUploadDragAndDrop/types';
import { useMapControls } from 'hooks/map/useMapControls';
import NoiseLevels from 'pages/analytics/Raids/PelNoise/components/NoiseLevels';
import { get } from 'lodash';
import { MoreLessNumberInput } from '../../../../components/common/Input/more-less-number-input';

interface IProps {
  dictionaries: {
    data: TDictionariesArray;
  } | null;
  browserId: string;
  onMediaChange?: Dispatch<SetStateAction<IUploadMedia[]>>;
}

export const AnalyticsPelNoiseParameters = ({ dictionaries, browserId, onMediaChange }: IProps) => {
  const { startEditing, addFeature, pointExistsInMeta, sendCommand } = useMapControls({ browserId });
  const { values, setFieldValue } = useFormikContext<IReportProtocolForm>();

  const noiseParameters = useMemo(
    () => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'noiseParameters'),
    [dictionaries]
  );

  const noiseExtraParameters = useMemo(
    () => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'noiseExtraParameters'),
    [dictionaries]
  );

  const addPoint = useCallback(
    (pointNumber) => {
      setFieldValue('points', [...values.points, pointCreator(noiseParameters, pointNumber)]);
    },
    [setFieldValue, values.points, noiseParameters]
  );

  const removePoint = useCallback(
    (index: number) => {
      const newPoints = [...values.points];
      const removed = [...newPoints][index];
      newPoints.splice(index, 1);

      if (values?.meta) {
        const meta = JSON.parse(values.meta);
        const points = meta.features.filter((point: any) => point.properties.id !== `point_${removed.pointNumber}`);
        void sendCommand(JSON.stringify({ ...meta, features: points }));
        setFieldValue('meta', JSON.stringify({ ...meta, features: points }));
      }
      setFieldValue('points', newPoints);
    },
    [sendCommand, setFieldValue, values.meta, values.points]
  );

  const addDynamicValues = (index: number) => {
    setFieldValue(`points[${index}].dynamicValues`, [
      ...values.points[index].dynamicValues,
      pointDynamicValuesCreator(noiseParameters, values.points[index].dynamicValues.length),
    ]);
  };

  const addDynamicExtraValues = (index: number) => {
    setFieldValue(`points[${index}].dynamicExtraValues`, [
      ...values.points[index].dynamicExtraValues,
      {
        index: Math.max(...values.points[index].dynamicExtraValues.map((value: ExtraValue) => value.index), 1) + 1,
        extraValues: [],
      },
    ]);
  };

  const removeDynamicValues = useCallback(
    (index: number, subIndex: number) => {
      const newDynamicValues = values.points[index].dynamicValues;
      newDynamicValues.splice(subIndex, 1);
      setFieldValue(`points[${index}].dynamicValues`, newDynamicValues);

      if (newDynamicValues.length < 2) {
        setFieldValue(`points[${index}].values.averageEq.value`, '');
        setFieldValue(`points[${index}].values.averageMax.value`, '');
      }
    },
    [setFieldValue, values.points]
  );

  const removeDynamicExtraValues = useCallback(
    (index: number, subIndex: number) => {
      const newDynamicValues = values.points[index].dynamicExtraValues;
      newDynamicValues.splice(subIndex, 1);
      setFieldValue(`points[${index}].dynamicExtraValues`, newDynamicValues);

      if (newDynamicValues.length < 2) {
        setFieldValue(
          `points[${index}].extraValues`,
          get(values, `points[${index}].extraValues`)?.filter(
            (item: IPointValue) => !extraParametersIds.averageSoundAndPressure.includes(item.parameterId)
          )
        );
      }
    },
    [setFieldValue, values]
  );

  useEffect(() => {
    if (values.meta) {
      try {
        const meta = JSON.parse(values.meta);
        // filter out points without id or with invalid id
        const mapPoints = meta.features.filter((point: any) => {
          const pointExists = !!values.points.some(
            (p: any) => `${p.pointNumber}` === point.properties.id?.split('_')[1]
          );
          return point.properties.id?.startsWith('point_') && pointExists;
        });

        values.points.forEach((point: { pointNumber: number }, index: number) => {
          const mapPoint = mapPoints.find((p: any) => p.properties.id === `point_${point.pointNumber}`);
          setFieldValue(`points[${index}].longitude`, mapPoint ? mapPoint.geometry.coordinates[0] : null);
          setFieldValue(`points[${index}].latitude`, mapPoint ? mapPoint.geometry.coordinates[1] : null);
        });

        if (mapPoints.length < meta.features.length) {
          void sendCommand(JSON.stringify({ ...meta, features: mapPoints }));
        }
        // eslint-disable-next-line no-empty
      } catch (error) {
        console.error(error);
      }
    }
  }, [sendCommand, setFieldValue, values.meta, values.points]);

  const pointExists = useCallback((id: string) => pointExistsInMeta(id, values.meta), [pointExistsInMeta, values.meta]);

  return (
    <Box flexDirection="column">
      <FormWrapper>
        <Grid marginTop="30px" gap="15px">
          <Heading size="lg">Точки исследования</Heading>
          {values.points?.map((point: IFormikPoint, index: number) => (
            <Box key={index} marginBottom={5}>
              <Flex alignItems="baseline">
                <Heading size="md" marginBottom={2} marginTop={5}>
                  {`Контрольная точка № ${point.pointNumber}`}
                </Heading>

                <Button
                  marginLeft="10px"
                  leftIcon={<DeleteIcon />}
                  variant="ghost"
                  size="sm"
                  p={0}
                  onClick={() => removePoint(index)}
                />
              </Flex>

              <Flex>
                <Field as={Input} name={`points[${index}].latitude`} label="Широта" isDisabled />
                <Field as={Input} name={`points[${index}].longitude`} label="Долгота" isDisabled />
                <Button
                  height={45}
                  onClick={() =>
                    (pointExists(`point_${point.pointNumber}`)
                      ? startEditing()
                      : addFeature(`point_${point.pointNumber}`, `Контрольная точка № ${point.pointNumber}`))
                  }
                  marginLeft={2}
                >
                  <OnMap />
                </Button>
              </Flex>

              <Grid gap={2} templateColumns="repeat(2, 1fr)" marginTop={2}>
                <GridItem colSpan={6}>
                  <Field
                    name={`points[${index}].startDatetime`}
                    as={DateInput}
                    label="Дата/время начала измерений"
                    value={createDateFromResponse(values.points[index].startDatetime)}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setFieldValue(`points[${index}].startDatetime`, createDateToRequestWithTimezone(e.target.value));
                    }}
                    showTimeInput
                  />
                </GridItem>

                <GridItem colSpan={6}>
                  <Field
                    name={`points[${index}].endDatetime`}
                    as={DateInput}
                    label="Дата/время окончания измерений"
                    value={createDateFromResponse(values.points[index].endDatetime)}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setFieldValue(`points[${index}].endDatetime`, createDateToRequestWithTimezone(e.target.value));
                    }}
                    showTimeInput
                  />
                </GridItem>
              </Grid>

              <Heading size="sm" marginBottom={2} marginTop={5}>
                Измеренные значения
              </Heading>
              {point.dynamicValues?.map((valueItem: IPointDynamicValue, subIndex: number) => (
                <React.Fragment key={subIndex}>
                  <Grid gap={2} templateColumns="repeat(13, 1fr)" marginTop={2}>
                    <GridItem colSpan={6}>
                      <Field
                        name={`points[${index}].dynamicValues[${subIndex}].left`}
                        as={MoreLessNumberInput}
                        label="Эквивалентный уровень звука"
                        onChange={null}
                      />
                    </GridItem>

                    <GridItem colSpan={6}>
                      <Field
                        name={`points[${index}].dynamicValues[${subIndex}].right`}
                        as={MoreLessNumberInput}
                        onChange={null}
                        label="Максимальный уровень звука"
                      />
                    </GridItem>

                    {point.dynamicValues.length > 1 && (
                      <GridItem>
                        <Button
                          marginTop={2}
                          leftIcon={<DeleteIcon />}
                          variant="ghost"
                          color="PrimaryButton.500"
                          size="sm"
                          p={0}
                          onClick={() => removeDynamicValues(index, subIndex)}
                        />
                      </GridItem>
                    )}
                  </Grid>
                </React.Fragment>
              ))}

              <Button
                leftIcon={<AddIcon />}
                variant="ghost"
                color="PrimaryButton.500"
                size="sm"
                onClick={() => addDynamicValues(index)}
              >
                Добавить значения
              </Button>

              {point.dynamicExtraValues?.map((valueItem, subIndex: number) => (
                <Grid key={subIndex} templateColumns="1fr 50px" gap={2} marginTop={4}>
                  {subIndex === 0 && (
                    <GridItem colSpan={2}>
                      <Heading size="xs">
                        Уровни звукового давления в дБ в октавных полосах со среднегеометрическими частотами (в Гц)
                      </Heading>
                    </GridItem>
                  )}

                  <NoiseLevels
                    parameterIds={extraParametersIds.dynamic}
                    parametersDictionary={noiseExtraParameters}
                    prefix={`points[${index}].dynamicExtraValues[${subIndex}].extraValues`}
                    hideHeading
                  />

                  {point.dynamicExtraValues.length > 1 && (
                    <Button
                      marginTop={2}
                      leftIcon={<DeleteIcon />}
                      variant="ghost"
                      color="PrimaryButton.500"
                      size="sm"
                      p={0}
                      onClick={() => removeDynamicExtraValues(index, subIndex)}
                    />
                  )}
                </Grid>
              ))}

              <Button
                leftIcon={<AddIcon />}
                variant="ghost"
                color="PrimaryButton.500"
                size="sm"
                onClick={() => addDynamicExtraValues(index)}
              >
                Добавить значения
              </Button>

              <Heading size="sm" marginBottom={2} marginTop={5}>
                Средний по измеренным значениям
              </Heading>
              <Grid gap={2} templateColumns="1fr 1fr">
                <Field
                  name={`points[${index}].values.averageEq`}
                  as={MoreLessNumberInput}
                  onChange={null}
                  label="Эквивалентный уровень звука"
                  isDisabled={point.dynamicValues?.length < 2}
                />

                <Field
                  name={`points[${index}].values.averageMax`}
                  as={MoreLessNumberInput}
                  onChange={null}
                  label="Максимальный уровень звука"
                  isDisabled={point.dynamicValues?.length < 2}
                />

                <GridItem colSpan={2}>
                  <NoiseLevels
                    parameterIds={extraParametersIds.averageSoundAndPressure}
                    parametersDictionary={noiseExtraParameters}
                    prefix={`points[${index}].extraValues`}
                    isDisabled={point.dynamicExtraValues?.length < 2}
                  />
                </GridItem>
              </Grid>

              <Heading size="sm" marginBottom={2} marginTop={5}>
                Коррекции (K1, K2, K3, K4, K5)
              </Heading>
              <Grid gap={2} templateColumns="1fr 1fr">
                <Field
                  name={`points[${index}].values.correctionEq`}
                  as={MoreLessNumberInput}
                  onChange={null}
                  label="Эквивалентный уровень звука"
                />

                <Field
                  name={`points[${index}].values.correctionMax`}
                  as={MoreLessNumberInput}
                  onChange={null}
                  label="Максимальный уровень звука"
                />

                <GridItem colSpan={2}>
                  <NoiseLevels
                    parameterIds={extraParametersIds.correction}
                    parametersDictionary={noiseExtraParameters}
                    prefix={`points[${index}].extraValues`}
                  />
                </GridItem>
              </Grid>

              <Heading size="sm" marginBottom={2} marginTop={5}>
                Откорректированный уровень звука
              </Heading>
              <Grid gap={2} templateColumns="repeat(2, 1fr)">
                <Field
                  name={`points[${index}].values.correctedEq`}
                  as={MoreLessNumberInput}
                  onChange={null}
                  label="Эквивалентный уровень звука"
                />

                <Field
                  name={`points[${index}].values.correctedMax`}
                  as={MoreLessNumberInput}
                  onChange={null}
                  label="Максимальный уровень звука"
                />

                <GridItem colSpan={2}>
                  <NoiseLevels
                    parameterIds={extraParametersIds.correctedSoundAndPressure}
                    parametersDictionary={noiseExtraParameters}
                    prefix={`points[${index}].extraValues`}
                  />
                </GridItem>
              </Grid>

              <Heading size="sm" marginBottom={2} marginTop={5}>
                Расширенная неопределенность
              </Heading>
              <Grid gap={2} templateColumns="repeat(2, 1fr)">
                <Field
                  name={`points[${index}].values.extendedEq`}
                  as={MoreLessNumberInput}
                  onChange={null}
                  label="Эквивалентный уровень звука"
                />

                <Field
                  name={`points[${index}].values.extentedMax`}
                  as={MoreLessNumberInput}
                  onChange={null}
                  label="Максимальный уровень звука"
                />

                <GridItem colSpan={2}>
                  <NoiseLevels
                    parameterIds={extraParametersIds.ambiguity}
                    parametersDictionary={noiseExtraParameters}
                    prefix={`points[${index}].extraValues`}
                  />
                </GridItem>
              </Grid>

              <Heading size="sm" marginBottom={2} marginTop={5}>
                Оценочный уровень звука
              </Heading>
              <Grid gap={2} templateColumns="repeat(2, 1fr)">
                <Field
                  name={`points[${index}].values.estimatedEq`}
                  as={MoreLessNumberInput}
                  onChange={null}
                  label="Эквивалентный уровень звука"
                />

                <Field
                  name={`points[${index}].values.estimatedMax`}
                  as={MoreLessNumberInput}
                  onChange={null}
                  label="Максимальный уровень звука"
                />

                <GridItem colSpan={2}>
                  <NoiseLevels
                    parameterIds={extraParametersIds.estimated}
                    parametersDictionary={noiseExtraParameters}
                    prefix={`points[${index}].extraValues`}
                  />
                </GridItem>
              </Grid>

              <Heading size="sm" marginBottom={2} marginTop={5}>
                Допустимые уровни звука по СанПин 1.2.3685-21 (табл. 5.35)*
              </Heading>
              <Grid gap={2} templateColumns="repeat(2, 1fr)">
                <Field
                  name={`points[${index}].values.admissibleEq`}
                  as={MoreLessNumberInput}
                  onChange={null}
                  label="Эквивалентный уровень звука"
                />

                <Field
                  name={`points[${index}].values.admissible`}
                  as={MoreLessNumberInput}
                  onChange={null}
                  label="Максимальный уровень звука"
                />

                <GridItem colSpan={2}>
                  <NoiseLevels
                    parameterIds={extraParametersIds.sanPin}
                    parametersDictionary={noiseExtraParameters}
                    prefix={`points[${index}].extraValues`}
                  />
                </GridItem>
              </Grid>
            </Box>
          ))}

          <Button
            marginRight="auto"
            leftIcon={<AddIcon />}
            variant="ghost"
            color="PrimaryButton.500"
            size="sm"
            onClick={() => addPoint(Math.max(...values.points.map((p: any) => p.pointNumber), 0) + 1)}
          >
            Добавить точку исследования
          </Button>
        </Grid>

        <Box ml={2} width="600px" height="600px">
          <MapFormField browserId={browserId} onMediaChange={onMediaChange} />
        </Box>
      </FormWrapper>
    </Box>
  );
};
