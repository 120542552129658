import { DateInput, FormWrapper, Input, Select } from 'components/common';
import { Box, Grid } from '@chakra-ui/react';
import React, { useCallback, useMemo } from 'react';
import { Field, useFormikContext } from 'formik';
import { createDateFromResponse, createDateToRequestWithTimezone } from 'lib/create-date';
import appLocale from 'constants/appLocale';
import { TDictionariesArray } from 'models';
import { getSelectOptionsFromDictionaryByName } from 'features/get-select-options-from-dictionary';
import { useRequest } from 'hooks';
import { WPZArea, WPZDto } from 'pages/data/Water/WPZRoot/WPZ/consts/types';
import { WaterWPZApiPaths } from 'pages/data/Water/WPZRoot/WPZ/consts/apiPaths';
import { getFilteredOptionsByPrivileges } from 'features/getFilteredOptionsByPrivileges';

interface CommonTabProps {
  dictionaries: {
    data: TDictionariesArray;
  } | null;
  isEditMode?: boolean;
}

export const CommonTab = ({ isEditMode, dictionaries }: CommonTabProps) => {
  const { setFieldValue, values, errors, setFieldTouched } = useFormikContext<WPZDto>();

  const waterObjects = useMemo(
    () =>
      getFilteredOptionsByPrivileges(
        getSelectOptionsFromDictionaryByName(dictionaries?.data, 'waterObjects'),
        'isEditable',
        'value',
        values.waterObjectId,
      ),
    [dictionaries?.data, values.waterObjectId],
  );

  const onAreaDataSuccess = useCallback(
    (response: WPZArea | null) => {
      if (response) {
        setFieldValue('district', response.district || '');
        setFieldValue('region', response.region || '');
        setFieldValue('oopt', response.oopt || '');
      }
    },
    [setFieldValue],
  );

  const { get: getAreaData } = useRequest<WPZArea>('', onAreaDataSuccess);

  const areas = useMemo(() => {
    const areas = getFilteredOptionsByPrivileges(
      getSelectOptionsFromDictionaryByName(dictionaries?.data, 'areas'),
      'isEditable',
      'value',
      values.areaId,
    );
    return isEditMode ? areas : areas.filter((area) => area.parent === values.waterObjectId);
  }, [dictionaries?.data, isEditMode, values.areaId, values.waterObjectId]);

  const onSourceSelect = useCallback(
    async (event) => {
      const { value } = event.target;
      setFieldValue('areaId', value);

      if (value) {
        void getAreaData({
          url: WaterWPZApiPaths.getAreaData(value),
        });
      } else {
        setFieldValue('district', '');
        setFieldValue('region', '');
        setFieldValue('oopt', '');
      }
    },
    [getAreaData, setFieldValue],
  );

  return (
    <Box flexDirection="column">
      <FormWrapper>
        <Grid marginTop="30px" gap="15px">
          <Field
            as={DateInput}
            name="date"
            label={appLocale.wpz.date}
            value={createDateFromResponse(values.date)}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFieldTouched(e.target.name);
              setFieldValue('date', createDateToRequestWithTimezone(e.target.value), true);
            }}
            error={errors?.date}
            onBlur={(e: any) => {
              setFieldTouched(e.target.name);
            }}
            showTimeInput
          />

          <Field
            as={Select}
            name="waterObjectId"
            options={waterObjects}
            label={appLocale.bottomSoil.waterObject}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFieldValue('waterObjectId', e.target.value, true);
              setFieldValue('areaId', null);
              setFieldValue('district', '');
              setFieldValue('region', '');
              setFieldValue('oopt', '');
            }}
            isDisabled={isEditMode}
            error={errors?.waterObjectId}
          />

          <Field
            as={Select}
            name="areaId"
            options={areas}
            label={appLocale.wpz.area}
            isDisabled={isEditMode || !values.waterObjectId}
            onChange={onSourceSelect}
            error={errors?.areaId}
          />

          <Field
            as={Select}
            name="areaId"
            options={areas.map((area) => ({ ...area, name: area.areaCode || '' }))}
            label={appLocale.wpz.areaCode}
            isDisabled={isEditMode || !values.waterObjectId}
            onChange={onSourceSelect}
            error={errors?.areaId}
          />

          <Field
            as={Input}
            label={appLocale.bottomSoil.region}
            name="region"
            value={values.region || ''}
            disabled
            error={errors?.region}
          />

          <Field
            as={Input}
            label={appLocale.bottomSoil.district}
            name="district"
            value={values.district || ''}
            disabled
            error={errors?.district}
          />

          <Field
            as={Input}
            label={appLocale.bottomSoil.oopt}
            name="oopt"
            value={values.oopt || ''}
            disabled
            error={errors?.oopt}
          />
        </Grid>
      </FormWrapper>
    </Box>
  );
};
