import { Progress } from '@chakra-ui/react';
import React from 'react';

export const TableLoader = (props: React.PropsWithChildren<{ isLoading: boolean }>) => {
  const { isLoading, children } = props;

  if (isLoading) {
    return <Progress size="xs" isIndeterminate color="PrimaryButton.500" />;
  }

  return <>{children}</>;
};
