import { Box, Button, Flex } from '@chakra-ui/react';
import { DateInput, DateInputProps } from 'components/common/DateInput/index';
import moment from 'moment/moment';
import * as React from 'react';
import { FormikFieldProps } from 'components/common/types';

const EnhancedDateInput: React.FC<DateInputProps & Partial<FormikFieldProps>> = (props) => {
  const onAddClick = (value: moment.DurationInputArg1, type: moment.unitOfTime.DurationConstructor) => {
    const date = (props.value && moment(props.value)) || moment();
    date.add(value, type);
    if (props.onChange) {
      props.onChange({ target: { value: date } });
    }
  };

  const onSubtractClick = (value: moment.DurationInputArg1, type: moment.unitOfTime.DurationConstructor) => {
    const date = (props.value && moment(props.value)) || moment();
    date.subtract(value, type);
    if (props.onChange) {
      props.onChange({ target: { value: date } });
    }
  };

  return (
    <Box>
      <Flex pb={1} justifyContent="space-evenly">
        <Button width="70px" size="xs" onClick={() => onSubtractClick(1, 'day')}>
          -1 день
        </Button>
        <Button width="70px" size="xs" onClick={() => onAddClick(1, 'day')}>
          +1 день
        </Button>
      </Flex>
      <DateInput {...props} />
      <Flex pt={1} justifyContent="space-evenly">
        <Button width="70px" size="xs" onClick={() => onSubtractClick(20, 'minute')}>
          -20 минут
        </Button>
        <Button width="70px" size="xs" onClick={() => onAddClick(20, 'minute')}>
          +20 минут
        </Button>
      </Flex>
    </Box>
  );
};

export default EnhancedDateInput;
