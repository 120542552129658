import React from 'react';
import { createTouchedErrors } from 'features/create-touched-errors';
import { FormikProvider, useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import { FieldsForm } from './FieldsForm';
import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import appLocale from 'constants/appLocale';
import { configError, FTPRootPath, FTPSettings, ServersConfig } from 'pages/admin/FTP';
import { useRequest } from 'hooks/useRequest';
import { baseAdminUrl } from 'pages/admin';
import { useNotifications } from 'hooks/useNotifications';
import { ERROR_MESSAGES, SUCCESS_MESSAGES } from 'constants/messages';
import { FormHeader } from 'components/common';
import { Box } from '@chakra-ui/react';
import { ServerSchema } from 'models/schemas/admin/ftpSettings';

export const initialEmptyValues = {
  active: '',
  deleted: false,
  id: '',
  ip: '',
  name: '',
  port: '',
  secure: false,
  timeout: 0,
};

export const AddServerForm = () => {
  const history = useHistory();
  const { successNotify, errorNotify } = useNotifications();
  useUpdateBreadcrumbs([
    {
      breadcrumb: appLocale.admin.main,
      path: '/admin',
    },
    {
      breadcrumb: appLocale.admin.ftp,
      path: FTPRootPath
    },
    {
      breadcrumb: appLocale.admin.ftpSettings,
      path: `${FTPSettings}/servers`,
    },
  ]);

  const onSuccess = () => {
    history.push(`${FTPSettings}/servers`);
    successNotify({
      key: 'file/add/success',
      message: SUCCESS_MESSAGES.CREATE,
    });
  };

  const onError = (error: any) => {
    /* Заглушка под определенную ошибку */
    if (error.descriptions[0].message === configError) {
      onSuccess();
      return;
    }
    errorNotify({
      key: 'file/add/error',
      message: `${ERROR_MESSAGES.CREATE}. ${error.descriptions[0].message}.`,
    });
  };

  const { post } = useRequest<ServersConfig, any>(`${baseAdminUrl}/ftp-config`, onSuccess, onError);

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    validationSchema: ServerSchema,
    initialValues: initialEmptyValues,
    onSubmit: (values) => post(values),
  });

  return (
    <FormikProvider
      value={{
        ...formik,
        errors: createTouchedErrors(formik.errors, formik.touched),
      }}
    >
      <Box>
        <FormHeader url={`${FTPSettings}/servers`} header="Добавление нового сервера" />
        <FieldsForm
          onCancel={() => {
            history.push(`${FTPSettings}/servers`);
          }}
        />
      </Box>
    </FormikProvider>
  );
};
