import React from 'react';
import { FormikProvider, useFormik } from 'formik';

import { useRequest } from 'hooks/useRequest';
import { createTouchedErrors } from 'features/create-touched-errors';
import FieldsForm from './FieldsForm';
import { AISWeatherRootPath, MeteoDataApiRoot } from 'pages/data/Meteo/menu';
import { aisWeatherSchema } from 'models/schemas/meteo/aisWeatherSchema';
import { useGoBackWithFallback } from 'lib/utils/goBackWithFallback';
import { useDictionary } from 'hooks/useDictionary';
import { useEnhancedNotification } from 'hooks';

export const emptyInitialValues = {
  packageDatetime: null,
  packageDatetimeResult: null,
  datetime: null,
  packageId: null,
  stations: [],
};

function AddAISWeather() {
  const { backWithFallback } = useGoBackWithFallback();
  const { dictionaries, isDictionariesLoading } = useDictionary({ url: `${MeteoDataApiRoot}/dictionaries/5` });

  const { onSuccessCreate, onErrorCreate } = useEnhancedNotification();

  const onSuccess = () => {
    backWithFallback(AISWeatherRootPath);
    onSuccessCreate();
  };

  const { post, isLoading: isCreatingRecord } = useRequest('/meteo/v10/ais', onSuccess, onErrorCreate);

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    validationSchema: aisWeatherSchema,
    initialValues: emptyInitialValues,
    onSubmit: (values) => {
      post(values);
    },
  });

  return (
    <FormikProvider
      value={{
        ...formik,
        errors: createTouchedErrors(formik.errors, formik.touched),
      }}
    >
      <FieldsForm
        isEdit={false}
        dictionaries={dictionaries}
        onCancel={() => {
          backWithFallback(AISWeatherRootPath);
        }}
        isLoading={isDictionariesLoading || isCreatingRecord}
      />
    </FormikProvider>
  );
}

export { AddAISWeather };
