import { ChangeEvent, useCallback, useState } from 'react';
import { Flex, FormControl, FormLabel, Heading, Switch } from '@chakra-ui/react';

import { IChart } from '../../types';

interface IViewLinesManage {
  chart: IChart | undefined;
}
export const ViewLinesManage = ({ chart }: IViewLinesManage) => {
  const [isInterpolate, setIsInterpolate] = useState(false);
  const [isAnimation, setIsAnimation] = useState(false);
  const [isPoints, setIsPoints] = useState(true);

  const handleEnableAnimation = useCallback(() => {
    if (chart) {
      chart.options.animation = {
        duration: 1000,
      };
      if (chart.options.transitions?.zoom?.animation) {
        chart.options.transitions.zoom.animation.duration = 500;
      }
      chart.update('normal');
    }
  }, [chart]);

  const handleDisableAnimation = useCallback(() => {
    if (chart) {
      chart.options.animation = false;
      if (chart.options.transitions?.zoom?.animation) {
        chart.options.transitions.zoom.animation.duration = 0;
      }
    }
  }, [chart]);

  const handleEnableInterpolation = useCallback(() => {
    if (chart && chart.options?.elements?.line) {
      chart.options.elements.line.cubicInterpolationMode = 'monotone';
      chart.update('normal');
    }
  }, [chart]);

  const handleDisableInterpolation = useCallback(() => {
    if (chart && chart.options?.elements?.line) {
      chart.options.elements.line.cubicInterpolationMode = 'default';
      chart.update('normal');
    }
  }, [chart]);

  const handleEnablePoints = useCallback(() => {
    if (chart && chart.options.elements?.point) {
      chart.options.elements.point.radius = 3;
      chart.update('normal');
    }
  }, [chart]);

  const handleDisablePoints = useCallback(() => {
    if (chart && chart.options.elements?.point) {
      chart.options.elements.point.radius = 0;
      chart.update('normal');
    }
  }, [chart]);

  const handleChangeInterpolate = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setIsInterpolate(event.target.checked);
      if (event.target.checked) handleEnableInterpolation();
      else handleDisableInterpolation();
    },
    [handleDisableInterpolation, handleEnableInterpolation]
  );

  const handleChangeAnimation = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setIsAnimation(event.target.checked);
      if (event.target.checked) handleEnableAnimation();
      else handleDisableAnimation();
    },
    [handleDisableAnimation, handleEnableAnimation]
  );

  const handleChangePoints = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setIsPoints(event.target.checked);
      if (event.target.checked) handleEnablePoints();
      else handleDisablePoints();
    },
    [handleDisablePoints, handleEnablePoints]
  );

  return (
    <Flex flexDirection="row" alignItems="center" width="100%" flexWrap="wrap">
      <Heading size="md" width="100%" mb={3}>
        Управление внешним видом линий
      </Heading>
      <FormControl display="flex" alignItems="center" my={3}>
        <FormLabel htmlFor="animation" mb="0">
          Анимация
        </FormLabel>
        <Switch isChecked={isAnimation} id="animation" onChange={handleChangeAnimation} />
      </FormControl>
      <FormControl display="flex" alignItems="center" mb={1}>
        <FormLabel htmlFor="interpolate" mb="0">
          Сглаживание
        </FormLabel>
        <Switch isChecked={isInterpolate} id="interpolate" onChange={handleChangeInterpolate} />
      </FormControl>
      <FormControl display="flex" alignItems="center" mb={1}>
        <FormLabel htmlFor="points" mb="0">
          Изначальные точки
        </FormLabel>
        <Switch isChecked={isPoints} id="points" onChange={handleChangePoints} />
      </FormControl>
    </Flex>
  );
};
