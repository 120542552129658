import React, { useCallback, useMemo } from 'react';
import { useFormikContext } from 'formik';
import { AttachmentsTabContent, FormWrapper, TabListHeader } from 'components/common';
import { TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { COMMON_LOCALE } from 'constants/common';

import { TDictionariesArray, TDictionaryItemDictionary } from 'models';
import { IUploadMedia } from 'components/common/FileUploadDragAndDrop/types';
import FormControls from 'components/common/FormControls';
import { UploadObjectType } from 'api/services/media';
import { useEnhancedNotification } from 'hooks';
import { SurfaceWaterParametersTab } from 'pages/data/Water/SurfaceWater/Forms/Tabs/SurfaceWaterParametersTab';
import { AnalyticsSurfaceWaterCommonTab } from 'pages/analytics/forms/SurfaceWater/AnalyticsSurfaceWaterCommonTab';
import { AnalyticsFormWithStatusHeader } from 'pages/analytics/components/AnalyticsFormWithStatusHeader';
import { getSelectOptionsFromDictionaryByName } from 'features/get-select-options-from-dictionary';
import { IAnalyticsLabSurfaceWater } from 'pages/analytics/forms/SurfaceWater/types';
import { SignatoriesTab } from 'pages/analytics/components/SignatoriesTab';
import appLocale from 'constants/appLocale';
import { useGoBackWithFallback } from 'lib/utils/goBackWithFallback';
import { SourceInfo } from '../../../data/Water/SurfaceWater/types';
import { LinksTab } from 'pages/analytics/components/LinksTab';

interface IProps {
  isLoading: boolean;
  isEditMode?: boolean;
  isCopyMode?: boolean;
  objectType: UploadObjectType | null;
  onMediaChange: (media: IUploadMedia[]) => void;
  media: IUploadMedia[];
  uploadItemId?: string;
  parameters?: TDictionaryItemDictionary[];
  dictionaries: {
    data: TDictionariesArray;
  } | null;
  editedObject?: SourceInfo | null;
}

const tabsNames = [
  COMMON_LOCALE.commonData,
  COMMON_LOCALE.indicators,
  appLocale.common.links,
  appLocale.common.signatories,
  COMMON_LOCALE.attachments,
];

export const AnalyticsSurfaceWaterFormFields = ({
  isLoading,
  isEditMode,
  isCopyMode,
  objectType,
  media,
  uploadItemId,
  onMediaChange,
  parameters,
  dictionaries,
  editedObject,
}: IProps) => {
  const { submitForm, errors, setFieldTouched, values } = useFormikContext<IAnalyticsLabSurfaceWater>();
  const { onValidationError } = useEnhancedNotification();
  const { backWithFallback } = useGoBackWithFallback();

  const onCancel = useCallback(() => {
    backWithFallback('/analytics');
  }, [backWithFallback]);

  const title = useMemo(
    () => (isEditMode ? COMMON_LOCALE.text.edit_protocol_result : COMMON_LOCALE.text.add_protocol_result),
    [isEditMode],
  );

  const onFormSubmit = useCallback(async () => {
    await submitForm();
    if (Object.keys(errors).length > 0) {
      onValidationError();
      Object.keys(errors).forEach((key) => setFieldTouched(key, true));
    }
  }, [errors, onValidationError, setFieldTouched, submitForm]);

  const statuses = useMemo(() => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'statuses'), [dictionaries]);

  return (
    <Tabs isLazy>
      <TabListHeader
        onBackClick={onCancel}
        customHeader={<AnalyticsFormWithStatusHeader header={title} statuses={statuses} />}
        tabNames={tabsNames}
      />
      <TabPanels>
        <TabPanel>
          <AnalyticsSurfaceWaterCommonTab
            isEditMode={isEditMode}
            isLoading={isLoading}
            dictionaries={dictionaries}
            editedObject={editedObject}
          />
        </TabPanel>
        <TabPanel>
          <SurfaceWaterParametersTab
            parameters={parameters}
            isCopyMode={isCopyMode}
            isLoading={isLoading}
            handleCancel={onCancel}
            submitForm={submitForm}
            isEditMode={isEditMode}
            isAnalytics
          />
        </TabPanel>

        <TabPanel>
          <LinksTab />
        </TabPanel>

        <TabPanel>
          <SignatoriesTab dictionaries={dictionaries} />
        </TabPanel>
        <TabPanel>
          <FormWrapper>
            <AttachmentsTabContent
              objectType={objectType}
              media={media}
              onMediaChange={onMediaChange}
              uploadItemId={uploadItemId}
            >
              <FormControls
                onCancel={onCancel}
                handleSubmit={onFormSubmit}
                submitIsDisabled={!values.values || values.values.filter((val) => val && val.value !== '').length < 1}
              />
            </AttachmentsTabContent>
          </FormWrapper>
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};
