import { GreenPlantsRootPath } from 'pages/routes/GreenPlants';
import { Redirect } from 'react-router-dom';
import { GreenPlantsForm, IGreenPlantsFormProps } from '../GreenPlantsForm';
import { GreenPlantsFormTypes } from '../types';

const withRedirectUndefinedGreenPlantsType = (Component: typeof GreenPlantsForm) => (props: IGreenPlantsFormProps) => {
  const type = 'flora';

  if (Object.values(GreenPlantsFormTypes).includes(type)) {
    return <Component {...props} />;
  }

  return <Redirect to={`${GreenPlantsRootPath}/${type}`} />;
};

export { withRedirectUndefinedGreenPlantsType };
