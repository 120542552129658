import { Divider, Flex } from '@chakra-ui/react';
import { Button, LabelText } from 'components/common';
import { SystemSelect } from 'components/common/Select/SystemSelect';
import { TableTooltipFileName } from 'features/TableTooltip';
import { filePreviewMeasuresFilters, FilePreviewMeasuresFiltersValues } from 'features/UploadedFileProvider/constans';
import map from 'lodash/map';
import React from 'react';

interface LoadPreviewToolbarProps {
  setMeasuresFilter?: (filter: FilePreviewMeasuresFiltersValues) => void;
  isLoading: boolean;
  fileName: string | null;
  isSaveDisabled?: boolean;
  onSubmit(): void;
}

export const LoadPreviewToolbar: React.FC<LoadPreviewToolbarProps> = ({
  setMeasuresFilter,
  onSubmit,
  isLoading,
  fileName,
  isSaveDisabled = false
}) => {
  const onHandleSave = () => {
    onSubmit();
  };

  return (
    <Flex flexDirection="row" alignItems="center" justifyContent="space-between">
      <LabelText label="Файл: ">
        <Flex ml={2}>
          <TableTooltipFileName tooltip={fileName || '-'} />
        </Flex>
      </LabelText>

      {setMeasuresFilter && (
        <Flex justifyContent="space-between">
          <label>Показать строки:</label>
          <Flex flexDirection="column" marginLeft={5}>
            <SystemSelect
              colorScheme="PrimaryButton"
              color="PrimaryButton.500"
              borderRadius="2px"
              variant="unstyled"
              width="fit-content"
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                if (typeof setMeasuresFilter === 'function') {
                  setMeasuresFilter(e.target.value as FilePreviewMeasuresFiltersValues);
                }
              }}
              size="sm"
              options={map(filePreviewMeasuresFilters, ({ label: name, value }) => ({ name, value }))}
              defaultValue="all"
              background="transparent"
              arrowColor="icon.primary"
              iconWrapperProps={{ pr: 0 }}
            />

            <Divider borderColor="PrimaryButton.500" borderStyle="dashed" />
          </Flex>
        </Flex>
      )}

      <Button
        marginLeft={25}
        padding={0}
        variant="ghost"
        isLoading={isLoading}
        isDisabled={isLoading || isSaveDisabled}
        onClick={onHandleSave}
        color="PrimaryButton.500"
      >
        Сохранить данные
      </Button>
    </Flex>
  );
};
