import { Modal } from 'components/common/Modals/Modal';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useRequest } from 'hooks';
import { Box, Flex, Heading, Table, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';
import { IForecastReportProtocol, IForecastReportResponse } from 'pages/data/Meteo/Forecast/ForecastReport/types';
import { createLocalDateDDMMYYYY, createLocalDateDDMMYYYYWithTime } from 'lib/create-date';
import { Loader } from 'components/common';
import { colors } from 'components/theme/colors';

interface Props {
  id: string;
  isOpen: boolean;
  onClose: () => void;
}

function ForecastReportModal({ id, isOpen, onClose }: Props) {
  const { result, get, isLoading } = useRequest<IForecastReportResponse>(`/meteo/v10/forecast/report/${id}`);

  useEffect(() => {
    if (id) {
      void get();
    }
  }, [get, id]);

  const renderProtocolValue = useCallback(
    (label, value, flexDirection = 'row') => (
      <Flex flexDirection={flexDirection}>
        <Text color="#6E7478" fontSize="sm" fontWeight="400">
          {label}
        </Text>

        <Text display="inline" fontSize="sm" fontWeight="400">
          &nbsp;
          {value}
        </Text>
      </Flex>
    ),
    []
  );

  const renderProtocol = useCallback(
    (protocol: IForecastReportProtocol, index: number) => (
      <Box key={index} marginBottom={8}>
        <Heading marginBottom={4} size="sm">
          Протокол
        </Heading>
        <Flex marginBottom={4}>
          <Flex width="50%" flexDirection="column">
            {renderProtocolValue('Дата протокола:', createLocalDateDDMMYYYY(protocol.prognosisProtocolDate))}
            {renderProtocolValue('Период протокола:', protocol.protocolPeriod)}
          </Flex>
          <Flex width="50%" flexDirection="column">
            {renderProtocolValue(
              'Статус сравнения:',
              protocol.status === 'Успешно' ? (
                protocol.status
              ) : (
                <span style={{ color: colors.red.main }}>{protocol.status}</span>
              )
            )}
            {renderProtocolValue(
              'Достоверность прогноза:',
              protocol.result ? (
                'Прогноз оправдался'
              ) : (
                <span style={{ color: colors.orange.main }}>Прогноз не оправдался</span>
              )
            )}
          </Flex>
        </Flex>
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Значения</Th>
              <Th>Осадки</Th>
              <Th>Временной показатель</Th>
              <Th>Пространственный показатель</Th>
              <Th>Кол-во осадков, мм</Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td>Прогнозные</Td>
              <Td>{protocol.forecastData.valueName}</Td>
              <Td>{protocol.forecastData.timeRange}</Td>
              <Td>{protocol.forecastData.area}</Td>
              <Td>{protocol.forecastData.valueRange}</Td>
            </Tr>
            <Tr>
              <Td>Фактические</Td>
              <Td>{protocol.factData.valueName}</Td>
              <Td>{protocol.factData.timeRange}</Td>
              <Td>{protocol.factData.area}</Td>
              <Td>{protocol.factData.factValue}</Td>
            </Tr>
          </Tbody>
        </Table>
      </Box>
    ),
    [renderProtocolValue]
  );

  const renderHeader = useMemo(
    () => (
      <Box marginBottom={4}>
        <Flex marginBottom={2}>
          <Heading size="md" marginRight="auto">
            {result ? 'Отчет о результатах сравнения' : 'Отчет не найден'}
          </Heading>
          {result && (
            <Flex marginLeft={4}>
              <Box px={2}>
                {renderProtocolValue('Дата прогноза', createLocalDateDDMMYYYY(result.forecastDate), 'column')}
              </Box>
              <Box px={2}>{renderProtocolValue('Период прогноза', result.period, 'column')}</Box>
              <Box px={2}>
                {renderProtocolValue(
                  'Дата и время сравнения',
                  createLocalDateDDMMYYYYWithTime(result.reportDate),
                  'column'
                )}
              </Box>
              <Box px={2}>{renderProtocolValue('Пользователь', result.user, 'column')}</Box>
            </Flex>
          )}
        </Flex>
      </Box>
    ),

    [result, renderProtocolValue]
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose} header={!isLoading && renderHeader} closeButtonPosition="contentTop">
      <Box minW={700} maxW="80vw">
        {isLoading ? <Loader /> : <>{result?.protocols.map((protocol, index) => renderProtocol(protocol, index))}</>}
      </Box>
    </Modal>
  );
}

export default ForecastReportModal;
