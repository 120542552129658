import React, { FC, useMemo } from 'react';
import { DateInput, FormWrapper, Input, Select } from 'components/common';

import { getSelectOptionsFromDictionaryByName } from 'features/get-select-options-from-dictionary';
import { Field, useFormikContext } from 'formik';
import { TDictionariesArray } from 'models/dictionaries';
import { NSIWaterItem } from 'pages/dictionaries/NSIWater/types';
import { createDateFromResponse, createDateToRequestWithTimezone } from 'lib/create-date';
import subsystemIdsMap from 'constants/subsystemIdsMap';
import { Box, Grid } from '@chakra-ui/react';

interface IProps {
  dictionaries: {
    data: TDictionariesArray;
  } | null;
  editedObject?: any;
}

const NSIWaterGatesGeneralInformationTab: FC<IProps> = ({ dictionaries, children, editedObject }) => {
  const { setFieldValue, values, errors } = useFormikContext<NSIWaterItem>();

  const statuses = useMemo(() => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'statuses'), [dictionaries]);

  const waterObjects = useMemo(
    () => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'waterObjects'),
    [dictionaries],
  );
  const waterAreas = useMemo(
    () => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'waterAreas'),
    [dictionaries],
  );
  const waterStvoriSubsystems = useMemo(
    () => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'waterStvoriSubsystems'),
    [dictionaries],
  );
  const waterSvoriTypes = useMemo(
    () =>
      getSelectOptionsFromDictionaryByName(
        dictionaries?.data,
        'waterSvoriTypes',
        false,
        editedObject?.stvorTypeId as string,
      ),
    [dictionaries?.data, editedObject?.stvorTypeId],
  );

  const orderNumberLabel = useMemo(
    () =>
      (values.subsystemId === subsystemIdsMap.bottomSoil || values.subsystemId === subsystemIdsMap.surfaceWater
        ? 'Номер створа'
        : 'Порядковый номер створа на участке'),
    [values],
  );

  const surfaceOrBottomSoil = useMemo(
    () => values.subsystemId === subsystemIdsMap.bottomSoil || values.subsystemId === subsystemIdsMap.surfaceWater,
    [values],
  );

  return (
    <Box flexDirection="column">
      <FormWrapper>
        <Grid marginTop="30px" gap="15px">
          <Field
            as={Select}
            name="subsystemId"
            label="Выбрать модуль подсистемы"
            options={waterStvoriSubsystems}
            error={errors.subsystemId}
          />
          {!surfaceOrBottomSoil && (
            <Field as={Select} name="areaId" label="Участок наблюдения" options={waterAreas} error={errors.areaId} />
          )}

          <Field
            as={Select}
            name="waterId"
            label="Водный объект (Название)"
            options={waterObjects}
            error={errors.waterId}
          />

          <Field as={Input} name="name" label="Наименование створа" error={errors.name} />

          <Field as={Input} name="shortName" label="Сокращенное наименование створа" error={errors.shortName} />

          <Field as={Input} name="number" type="number" label="Идентификатор створа" error={errors.number} />

          <Field
            as={Select}
            name="stvorTypeId"
            label="Тип створа"
            options={waterSvoriTypes}
            error={errors.stvorTypeId}
          />

          <Field as={Select} name="status" label="Статус" options={statuses} error={errors.status} />

          <DateInput
            name="startDate"
            label="Начало действия"
            value={createDateFromResponse(values.startDate)}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setFieldValue('startDate', createDateToRequestWithTimezone(e.target.value))
            }
            error={errors.startDate}
          />

          <DateInput
            name="stopDate"
            label="Окончание действия"
            value={createDateFromResponse(values.stopDate)}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setFieldValue('stopDate', createDateToRequestWithTimezone(e.target.value))
            }
            error={errors.stopDate}
          />

          <Field as={Input} name="orderNumber" type="number" label={orderNumberLabel} error={errors.orderNumber} />

          {children}
        </Grid>
      </FormWrapper>
    </Box>
  );
};

export default NSIWaterGatesGeneralInformationTab;
