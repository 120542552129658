import React, { Dispatch, SetStateAction, useEffect, useMemo, useRef, useState } from 'react';
import { TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { AttachmentsTabContent, FormWrapper, TabListHeader } from 'components/common';
import { IUploadMedia } from 'components/common/FileUploadDragAndDrop/types';
import { useFormikContext } from 'formik';
import { UploadObjectType } from 'api/services/media';
import {
  AnalyticsPelAtmosphereGeneralInformationTab,
} from 'pages/analytics/Raids/Pel/PelAtmoshphere/AnalyticsPelAtmosphereGeneralInformationTab';
import {
  AnalyticsPelAtmosphereMeteoParameters,
} from 'pages/analytics/Raids/Pel/components/AnalyticsPelAtmosphereMeteoParameters';
import {
  AnalyticsPelAtmosphereParameters,
} from 'pages/analytics/Raids/Pel/components/AnalyticsPelAtmosphereParameters';
import { AnalyticsPelAtmosphereMeteoProbe } from 'pages/analytics/Raids/Pel/PelAtmoshphere/AnalyticsPelAtmosphereProbe';
import { IReportProtocolForm } from 'pages/analytics/Raids/types';
import { useRequest } from 'hooks';
import { AnalyticsFormWithStatusHeader } from 'pages/analytics/components/AnalyticsFormWithStatusHeader';
import { getSelectOptionsFromDictionaryByName } from 'features/get-select-options-from-dictionary';
import FormControls from 'components/common/FormControls';
import { COMMON_LOCALE } from 'constants/common';
import { useMapControls } from 'hooks/map/useMapControls';
import { SignatoriesTab } from 'pages/analytics/components/SignatoriesTab';
import { useGoBackWithFallback } from 'lib/utils/goBackWithFallback';
import appLocale from 'constants/appLocale';
import { LinksTab } from 'pages/analytics/components/LinksTab';

interface IProps {
  onMediaChange?: Dispatch<SetStateAction<IUploadMedia[]>>;
  media?: IUploadMedia[];
  objectType: UploadObjectType | null;
  isLoading: boolean;
  dictionaries: any;
  browserId: string;
  isEditMode?: boolean;
}

const AnalyticsPelAtmosphereFormFields = ({
  media,
  onMediaChange,
  objectType,
  isLoading,
  dictionaries,
  isEditMode,
  browserId,
}: IProps) => {
  const { backWithFallback } = useGoBackWithFallback();
  const { values, submitForm } = useFormikContext<IReportProtocolForm>();
  const [tab, setTab] = useState(0);
  const [isScreenshotLoading, setIsScreenshotLoading] = useState(false);
  const nextTab = useRef<number | null>(null);
  const { get: getPoints } = useRequest('');

  useEffect(() => {
    if (values.taskId) {
      void getPoints({ url: `/appeal/v10/reports/${values.taskId}/points` });
    }
  }, [getPoints, values.taskId]);

  const statuses = useMemo(() => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'statuses'), [dictionaries]);

  const title = isEditMode ? COMMON_LOCALE.text.edit_protocol_result : COMMON_LOCALE.text.add_protocol_result;

  const { getScreenshot } = useMapControls({
    browserId,
    onScreenshotCallback: () => {
      if (nextTab.current !== null) {
        setTab(nextTab.current);
        nextTab.current = null;
      }
      setIsScreenshotLoading(false);
    }
  });

  const onTabChange = async (index: number) => {
    if (tab === 0) {
      setIsScreenshotLoading(true);
      await getScreenshot(values.meta);
      nextTab.current = index;
      return;
    }
    setTab(index);
  };

  return (
    <Tabs onChange={onTabChange} index={tab}>
      <TabListHeader
        onBackClick={() => backWithFallback('/analytics')}
        customHeader={<AnalyticsFormWithStatusHeader
          header={title}
          statuses={statuses}
          isLoading={isScreenshotLoading}
        />}
        tabNames={['Общие сведения', 'Проба', 'Метеорологические параметры', 'Показатели',
          appLocale.common.links, 'Подписанты', 'Вложения']}
      />
      <TabPanels>
        <TabPanel>
          <AnalyticsPelAtmosphereGeneralInformationTab
            dictionaries={dictionaries}
            isEditMode={isEditMode}
            browserId={browserId}
            onMediaChange={onMediaChange}
          />
        </TabPanel>
        <TabPanel>
          <AnalyticsPelAtmosphereMeteoProbe isEditMode={isEditMode} />
        </TabPanel>
        <TabPanel>
          <AnalyticsPelAtmosphereMeteoParameters dictionaries={dictionaries} />
        </TabPanel>
        <TabPanel>
          <AnalyticsPelAtmosphereParameters dictionaries={dictionaries}>
            <FormControls onCancel={() => backWithFallback('/analytics')} handleSubmit={submitForm} />
          </AnalyticsPelAtmosphereParameters>
        </TabPanel>
        <TabPanel>
          <LinksTab />
        </TabPanel>
        <TabPanel>
          <SignatoriesTab dictionaries={dictionaries} />
        </TabPanel>
        <TabPanel>
          <FormWrapper>
            <AttachmentsTabContent
              uploadItemId={values.id}
              objectType={objectType}
              media={media}
              onMediaChange={onMediaChange}
            >
              <FormControls
                onCancel={() => backWithFallback('/analytics')}
                handleSubmit={submitForm}
                submitIsDisabled={isLoading}
              />
            </AttachmentsTabContent>
          </FormWrapper>
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};

export { AnalyticsPelAtmosphereFormFields };
