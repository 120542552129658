import { Grid } from '@chakra-ui/react';
import { Field } from 'formik';
import { Input } from 'components/common';
import React from 'react';

export const Location = () => (
  <>
    <Grid gap={2} templateColumns="repeat(1, 1fr)">
      <Field as={Input} name="description" label="Примечание" />
      <Field as={Input} name="landmark" label="Адресный ориентир" />
      <Field as={Input} name="latitude" label="Широта" />
      <Field as={Input} label="Долгота" name="longitude" />
    </Grid>
  </>
);
