export const fieldsLocale: { [key: string]: string } = {
  territoryType: 'Тип территории',
  address: 'Адрес',
  distance: 'Расстояние от дороги',
  areaName: 'Площадка',
  pointId: 'Расстояние от края проезжей части',
  date: 'Дата/время отбора',
  protocol: 'Протокол',
  protocolNumber: 'Номер протокола',
  protocolDate: 'Дата протокола',
  district: 'Округ',
  areaId: 'Участок',
  region: 'Район',
  stage: 'Этап',
  season: 'Сезон',
  territorySubType: 'Подтип территории',
};
