import { Column } from '@material-table/core';
import { createLocalDateDDMMYYYY } from 'lib/create-date';
import { CitizenAppealListItem } from 'pages/citizenAppeal/consts/types';

export const citizenAppealsColumns: Column<CitizenAppealListItem>[] =
  [
    {
      title: '№ Поручения',
      field: 'assignmentNumber',
    },
    {
      title: 'Срок',
      field: 'assignmentExecution',
      render: (row) => createLocalDateDDMMYYYY(row.assignmentExecution),
    },
    {
      title: 'Основание',
      field: 'complaintOrigin',
    },
    {
      title: 'ФИО Заявителя',
      field: 'complaintCitizenFio',
    },
    {
      title: 'Статус',
      field: 'complaintStatus',
    },
  ];
