import { useRequest } from 'hooks/useRequest';
import { useEffect } from 'react';
import { Device } from 'pages/equipment/Devices/types';

function useGetRequest<T>(url: string, onError: () => void) {
  const { get, isLoading, result, error } = useRequest<T>(url, undefined, onError);
  useEffect(() => {
    get();
  }, [get]);

  return {
    isLoading,
    result,
    error,
  };
}

interface UseDeviceResult {
  data?: Device;
  isLoading: boolean;
}

export function useDevice(deviceId: string, onError: () => void): UseDeviceResult {
  const { result, isLoading, } = useGetRequest<Device>(`/equipment/v10/equipments/instruments/${deviceId}`, onError);

  return {
    data: result ?? undefined,
    isLoading,
  };
}
