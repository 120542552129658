import { AttachmentsTabContent, Button } from 'components/common';
import { Modal } from 'components/common/Modals';
import { IUploadMedia } from 'components/common/FileUploadDragAndDrop/types';
import { UploadObjectType } from 'api/services/media';
import { UploadFileType } from 'components/common/FileUploadDragAndDrop/FileUploadDragAndDrop';
import { Flex } from '@chakra-ui/react';

interface Props {
  isOpen: boolean;
  onClose(): void;
  media: IUploadMedia[];
  setMedia: (media: IUploadMedia[]) => void;
  uploadItemId?: string;
  objectType: UploadObjectType;
  handleSubmit(): void;
}

export const TaskAttachments = ({
  isOpen,
  onClose,
  media,
  setMedia,
  uploadItemId,
  objectType,
  handleSubmit
}: Props) => (
  <Modal isOpen={isOpen} onClose={onClose}>
    <AttachmentsTabContent
      objectType={objectType}
      media={media}
      onMediaChange={setMedia}
      uploadItemId={uploadItemId}
      uploadFileTypes={[UploadFileType.image, UploadFileType.doc, UploadFileType.pdf, UploadFileType.txt]}
    >
      <Flex>
        <Button onClick={() => onClose()}>
          Отмена
        </Button>
        <Button
          onClick={() => {
            handleSubmit();
            onClose();
          }}
          marginLeft="20px"
          isDisabled={!media.length}
        >
          Сохранить
        </Button>
      </Flex>
    </AttachmentsTabContent>
  </Modal>
);
