import { FormWrapper, Textarea } from 'components/common';
import { Box, Grid, Heading } from '@chakra-ui/react';
import { Field, useFormikContext } from 'formik';
import React from 'react';
import { IReportProtocolForm } from '../types';

export const Explanations = () => {
  const { handleChange } = useFormikContext<IReportProtocolForm>();
  return (
    <Box>
      <FormWrapper>
        <Grid marginTop={5} templateColumns="repeat(1, 1fr)">
          <Heading size="md" marginBottom={5}>
            Разъяснения
          </Heading>
          <Field as={Textarea} name="explanation" label="Разъяснения" onChange={handleChange} />
        </Grid>
      </FormWrapper>
    </Box>
  );
};
