import { Button, DateInput, FormWrapper, Input, LoaderItem, Select } from 'components/common';
import { Box, Flex, Grid, Heading } from '@chakra-ui/react';
import {
  getSelectOptionsFromDictionaryByName,
  getSelectOptionsFromDictionaryByNameWithTransformation,
  getSelectOptionsWithParentArray,
} from 'features/get-select-options-from-dictionary';
import { Field, useFormikContext } from 'formik';
import { SubsoilItem } from 'hooks/subsoil/useSubsoilList';
import { createDateFromResponse, createDateToRequestWithTimezone } from 'lib/create-date';
import React, { useMemo } from 'react';
import { useLocation } from 'react-router';
import { initialEmptyValues } from './initialEmptyValues';
import { FieldFormProps } from 'types/forms';
import { TDictionariesArray } from 'models';
import { IFieldAreaItem } from '../types';
import subsystemIdsMap from 'constants/subsystemIdsMap';
import { useAvailability } from 'hooks';
import isRequiredInSchema from 'lib/utils/forms/isReqiuredInSchema';
import { SubsoilSchema } from 'models/schemas/subsoil/subsoil';

const FieldsForm = ({ onCancel, dictionaries, isLoading, editedObject }: FieldFormProps) => {
  const { isAvailable } = useAvailability(subsystemIdsMap.subsoilRoot);

  const { setFieldValue, values, errors, handleSubmit, setFieldTouched } = useFormikContext<SubsoilItem>();

  const location = useLocation();
  const isEditMode = location.pathname.includes('/edit');

  const fieldAreas = useMemo(
    () => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'fieldAreas'),
    [dictionaries],
  );

  const filteredFieldAreas = useMemo(
    () => fieldAreas.filter((item) => item.parent === values.fieldId),
    [fieldAreas, values.fieldId],
  );

  const fields = useMemo(
    () =>
      getSelectOptionsWithParentArray(
        dictionaries?.data as TDictionariesArray<string, string, string, IFieldAreaItem[]>,
        'fields',
      ),
    [dictionaries],
  );

  const users = useMemo(() => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'users'), [dictionaries]);

  const authorities = useMemo(
    () => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'authorities'),
    [dictionaries],
  );

  const licenceStatuses = useMemo(
    () =>
      getSelectOptionsFromDictionaryByName(dictionaries?.data, 'licenceStatuses', false, editedObject?.licenceStatusId),
    [dictionaries?.data, editedObject?.licenceStatusId],
  );

  const resourceTypes = useMemo(
    () =>
      getSelectOptionsFromDictionaryByName(
        dictionaries?.data,
        'resourceTypes',
        false,
        editedObject?.mineralResourceTypeId,
      ),
    [dictionaries?.data, editedObject?.mineralResourceTypeId],
  );

  const areas = useMemo(
    () => getSelectOptionsFromDictionaryByNameWithTransformation(dictionaries?.data, 'areas', 'number'),
    [dictionaries],
  );

  if (isLoading) {
    return <LoaderItem />;
  }

  return (
    <Box flexDirection="column">
      <FormWrapper>
        <Grid marginTop="30px" gap="15px">
          <Heading variant="h2">Общие сведения</Heading>
          <Field
            as={Select}
            name="areaId"
            label="№ участка недр"
            value={values.areaId}
            options={areas}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              const selectedArea = dictionaries?.data
                .find((el) => el.name === 'areas')
                ?.dictionary?.find((option) => option.value === e.target.value);
              const selectedField = fields.find(
                (field) => field.value === selectedArea?.parent,
              );

              setFieldValue(e.target.name, e.target.value);
              setTimeout(() => setFieldTouched(e.target.name, true));
              setFieldValue('areaName', selectedArea?.name || initialEmptyValues.areaName);
              setFieldValue('address', selectedArea?.address || initialEmptyValues.address);
              setFieldValue('latitude', selectedArea?.latitude || initialEmptyValues.latitude);
              setFieldValue('longitude', selectedArea?.longitude || initialEmptyValues.longitude);
              setFieldValue('fieldId', selectedField?.value);
              setFieldValue('fieldAreaId', null);
            }}
            error={errors.areaId}
            isDisabled={isEditMode}
            isRequired={isRequiredInSchema(SubsoilSchema, 'areaId')}
          />
          {/* This fields are dependent on areaId field and is set by it */}
          <Field as={Input} name="areaName" label="Наименование участка недр" disabled />
          <Field as={Input} name="address" label="Адрес" disabled />
          <Field as={Input} name="latitude" label="Широта" disabled />
          <Field as={Input} name="longitude" label="Долгота" disabled />

          <Heading variant="h2">Лицензия</Heading>

          <Field
            as={Select}
            name="subsoilUserId"
            label="Недропользователь"
            options={users}
            value={values.subsoilUserId}
          />
          <Field as={Input} name="licenceNumber" label="№ лиценизии" isDisabled={!isAvailable} />
          <DateInput
            name="licenceRegistrationDate"
            label="Дата регистрации лицензии"
            value={createDateFromResponse(values.licenceRegistrationDate)}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const time = createDateToRequestWithTimezone(e.target.value);
              setFieldValue('licenceRegistrationDate', time);
            }}
            showTimeInput={false}
            isDisabled={!isAvailable}
          />
          <DateInput
            name="licenceEndDate"
            label="Дата окончания действия лицензии"
            value={createDateFromResponse(values.licenceEndDate)}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const time = createDateToRequestWithTimezone(e.target.value);
              setFieldValue('licenceEndDate', time);
            }}
            showTimeInput={false}
            isDisabled={!isAvailable}
          />
          <Field
            as={Select}
            name="licenceStatusId"
            label="Статус лицензии"
            options={licenceStatuses}
            isDisabled={!isAvailable}
          />
          <Field
            as={Select}
            name="mineralResourceTypeId"
            label="Вид полезного ископаемого"
            options={resourceTypes}
            isDisabled={!isAvailable}
          />
          <Field
            as={Input}
            name="productionVolume"
            label="Объем добычи полезного ископаемого"
            isDisabled={!isAvailable}
          />

          <Heading variant="h2">Утвержденные запасы</Heading>
          <Field
            as={Select}
            name="fieldAreaId"
            label="Участок месторождения"
            options={filteredFieldAreas}
            isDisabled={!isAvailable}
          />
          <Field as={Select} name="fieldId" label="Месторождение" options={fields} isDisabled />
          <Field as={Select} name="authorityId" label="Инстанция" options={authorities} isDisabled={!isAvailable} />
          <Field
            as={Input}
            name="protocolNumber"
            label="Номер протокола утверждения запасов"
            isDisabled={!isAvailable}
          />
          <DateInput
            name="protocolDate"
            label="Дата протокола утверждения запасов"
            value={createDateFromResponse(values.protocolDate)}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const time = createDateToRequestWithTimezone(e.target.value);
              setFieldValue('protocolDate', time);
            }}
            showTimeInput={false}
            isDisabled={!isAvailable}
          />
          <Field
            as={Input}
            name="stockA"
            label="Кол-во запасов по категории A"
            isDisabled={!isAvailable}
            type="number"
          />
          <Field
            as={Input}
            name="stockB"
            label="Кол-во запасов по категории B"
            isDisabled={!isAvailable}
            type="number"
          />
          <Field
            as={Input}
            name="stockC1"
            label="Кол-во запасов по категории C1"
            isDisabled={!isAvailable}
            type="number"
          />
          <Field
            as={Input}
            name="stockC2"
            label="Кол-во запасов по категории C2"
            isDisabled={!isAvailable}
            type="number"
          />

          <Flex marginBottom={30}>
            <Button onClick={onCancel} marginRight={20}>
              Отмена
            </Button>
            <Button isDisabled={!isAvailable} onClick={() => handleSubmit()} variant="solid">
              Сохранить
            </Button>
          </Flex>
        </Grid>
      </FormWrapper>
    </Box>
  );
};

export default React.memo(FieldsForm);
