import { AnalyticsData } from 'pages/data/analytics-data';
import appLocale from 'constants/appLocale';
import { WaterRoutesBottomSoil } from 'pages/data/Water';
import subsystemIdsMap from 'constants/subsystemIdsMap';

export const BottomSoilAnalyticsTab = () => (
  <AnalyticsData
    title={appLocale.bottomSoil.title}
    rootPath={WaterRoutesBottomSoil.root}
    subsystemId={subsystemIdsMap.bottomSoil}
    mainDataTitle="Протоколы сторонних организаций"
    analyticsDataTitle="Протоколы аналитической лаборатории"
  />
);
