import * as yup from 'yup';
import { numberError, requiredError } from '../constans';

export const PermissibleNoiseLevelSchema = yup.object().shape({
  territoryName: yup.string().nullable().required(requiredError),
  territoryType: yup.string().nullable().required(requiredError),
  documentName: yup.string().nullable().required(requiredError),
  eqNight: yup.number().typeError(numberError).nullable().required(requiredError),
  eqDay: yup.number().typeError(numberError).nullable().required(requiredError),
  maxNight: yup.number().typeError(numberError).nullable().required(requiredError),
  maxDay: yup.number().typeError(numberError).nullable().required(requiredError),
});
