import { Field, FormikProvider, useFormik } from 'formik';
import React, { useEffect, useMemo, useState } from 'react';
import { DateInput, IFilterValues, Select, useSetFiltersFromGraphic } from 'components/common';
import { Flex, Grid } from '@chakra-ui/react';
import { createDateFromResponse, createDateToRequestForFilter, createDateToRequestWithTimezone } from 'lib/create-date';
import { TDictionariesArray } from 'models/dictionaries';
import {
  getSelectOptionsFromDictionaryByName,
  getSelectRichOptionsFromDictionaryByName,
} from 'features/get-select-options-from-dictionary';
import { fieldsLocale } from '../consts';
import { IFilterItem } from 'models';
import combineFilters from 'lib/utils/combineFilters';
import { IPeriod } from 'features/PeriodsButtons/types';
import { PeriodsButtons } from 'features/PeriodsButtons';
import { usePeriods } from 'features/PeriodsButtons/usePeriods';
import { FiltersDatepickersSchema } from 'models/schemas/filtersDatepickersSchema/FiltersDatepickersSchema';
import FilterFormControls from 'components/form/FilterFormControls';

interface FilterProps {
  isLoading?: boolean;
  isDisabled?: boolean;
  filtersFromGraphic: Partial<IFilterValues> | null;
  setFiltersFromGraphic: (value: Partial<IFilterValues> | null) => void;
  dictionaries: {
    data: TDictionariesArray;
  } | null;
  onSubmit: (values: any) => void;
  filters: IFilterItem[];
  periods: IPeriod[] | null;
}

const initialValues: Record<string, IFilterItem> = {
  dateTimeMin: {
    key: 'date',
    operation: 'GTE',
    value: null,
  },
  dateTimeMax: {
    key: 'date',
    operation: 'LTE',
    value: null,
  },
  district: {
    key: 'districtId',
    operation: 'EQ',
    value: null,
  },
  region: {
    key: 'regionId',
    operation: 'EQ',
    value: null,
  },
  areaId: {
    key: 'areaId',
    operation: 'IN',
    value: null,
  },
  // showDisabled - value равно '' если чекбокс включен и нужно получить с Backend все значения (этот объект удалиться при отправке)
  // value равно false - если нужно показать только неудаленные записи
  showDisabled: {
    key: 'deleted',
    operation: 'EQ',
    value: false,
  },
  periodId: {
    key: 'periodId',
    operation: 'EQ',
    value: null,
  },
};

export const WashoutFilter: React.FC<FilterProps> = ({
  isLoading,
  isDisabled,
  onSubmit,
  dictionaries,
  setFiltersFromGraphic,
  filtersFromGraphic,
  filters,
  periods, children,
  ...props
}) => {
  const savedPeriodId = useMemo(
    () => (filters.find((item) => item.key === 'periodId')?.value as string) || '',
    [filters]
  );

  const [periodId, setPeriodId] = useState<string>(savedPeriodId);

  const { getDatesFromPeriod, dates } = usePeriods(periodId, periods);

  const submitPeriod = (id: string) => {
    const dates = getDatesFromPeriod(id);
    setFieldValue('periodId.value', id);
    setFieldValue('dateTimeMin.value', createDateToRequestWithTimezone(dates[0]));
    setFieldValue('dateTimeMax.value', createDateToRequestWithTimezone(dates[1]));
    setFieldTouched('dateTimeMin.value');
    setPeriodId(id);
  };

  const {
    values,
    errors,
    getFieldMeta,
    getFieldHelpers,
    getFieldProps,
    handleReset,
    submitForm,
    setFieldValue,
    setFieldTouched,
    ...formik
  } = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    validationSchema: FiltersDatepickersSchema([{ dateFromFieldName: 'dateTimeMin', dateToFieldName: 'dateTimeMax' }]),
    initialValues,
    onSubmit,
  });

  useEffect(() => {
    const initialFilters = combineFilters(initialValues, filters);
    Object.keys(initialFilters).forEach((key) => {
      setFieldValue(`${key}.value`, initialFilters[key].value);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useSetFiltersFromGraphic({
    dateKeyMin: 'dateTimeMin.value',
    dateKeyMax: 'dateTimeMax.value',
    setFiltersFromGraphic,
    filtersFromGraphic,
    setFieldValue,
  });

  const districts = useMemo(
    () => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'districts'),
    [dictionaries]
  );

  const regions = useMemo(() => {
    const dict = getSelectRichOptionsFromDictionaryByName(dictionaries?.data, 'regions');

    if (values.district.value) {
      return dict.filter((el) => el.parent === values.district.value);
    }

    return dict;
  }, [dictionaries, values.district.value]);

  const areas = useMemo(() => {
    const dict = getSelectRichOptionsFromDictionaryByName(dictionaries?.data, 'areas').map(
      ({ title: name, ...item }) => ({
        ...item,
        name: String(name),
      }),
    );

    if (values.region.value) {
      return dict.filter((el) => el.parent === values.region.value);
    }

    return dict;
  }, [dictionaries, values.region.value]);

  return (
    <FormikProvider
      value={{
        values,
        errors,
        getFieldMeta,
        getFieldHelpers,
        getFieldProps,
        handleReset,
        submitForm,
        setFieldValue,
        setFieldTouched,
        ...formik,
      }}
    >
      <Flex flexDirection="row" justifyContent="space-between">
        <Grid gap="12px" rowGap="8px" gridTemplateColumns="repeat(2, 175px) 225px 225px 175px" {...props}>
          <Field
            as={DateInput}
            maxDate={dates?.[1]}
            minDate={dates?.[0]}
            isDisabled={!periodId}
            name="dateTimeMin.value"
            label="От"
            value={typeof values.dateTimeMin?.value === 'string' && createDateFromResponse(values.dateTimeMin.value)}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFieldValue('dateTimeMin.value', createDateToRequestForFilter(e.target.value));
            }}
            showTimeInput={false}
            error={errors.dateTimeMin?.value}
          />
          <Field
            as={DateInput}
            maxDate={dates?.[1]}
            minDate={dates?.[0]}
            isDisabled={!periodId}
            name="dateTimeMax.value"
            label="До"
            value={typeof values.dateTimeMax?.value === 'string' && createDateFromResponse(values.dateTimeMax.value)}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFieldValue('dateTimeMax.value', createDateToRequestForFilter(e.target.value, 'end'));
            }}
            showTimeInput={false}
            error={errors.dateTimeMax?.value}
          />
          <Field
            as={Select}
            name="district.value"
            label={fieldsLocale.district}
            options={districts}
            value={values.district.value}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFieldValue('district.value', e.target.value);
            }}
            error={errors.district?.value}
          />
          <Field
            as={Select}
            name="region.value"
            label={fieldsLocale.region}
            options={regions}
            value={values.region.value}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFieldValue('region.value', e.target.value);
              setFieldValue('areaId.value', null);
            }}
            error={errors.region?.value}
          />

          <Field
            as={Select}
            multiple
            name="areaId.value"
            label={fieldsLocale.areaName}
            options={areas}
            value={values.areaId.value}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFieldValue('areaId.value', e.target.value);
            }}
            error={errors.areaId?.value}
          />
        </Grid>

        <FilterFormControls
          onReset={(e) => {
            handleReset(e);
            setPeriodId('');
            setTimeout(submitForm, 0);
          }}
          onSubmit={submitForm}
          isLoading={isLoading}
          isDisabled={isDisabled}
        />
      </Flex>
      {children}
      <PeriodsButtons periodId={periodId} periods={periods} submitPeriod={submitPeriod} />
    </FormikProvider>
  );
};
