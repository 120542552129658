import { FormWrapper, Input } from 'components/common';
import { Box, Button, Flex, Grid, Heading } from '@chakra-ui/react';
import React, { Dispatch, Fragment, SetStateAction, useCallback, useEffect, useMemo } from 'react';
import { getSelectOptionsFromDictionaryByName } from 'features/get-select-options-from-dictionary';
import { Field, useFormikContext } from 'formik';
import { TDictionariesArray } from 'models/dictionaries';
import { IReportProtocolForm } from 'pages/analytics/Raids/types';
import { IRenderFieldInterface, useFormField } from 'hooks/forms/useFormField';
import appLocale from 'constants/appLocale';
import { ANALYTICS_STATUS } from 'pages/analytics/forms/const';
import { AnalyticsPelAiAtmosphereSchema } from 'models/schemas/analytics/analyticsPelAiAtmosphereSchema';
import MapFormField from 'components/form/MapFormField';
import { IUploadMedia } from 'components/common/FileUploadDragAndDrop/types';
import { OnMap } from 'components/icons';
import { useMapControls } from 'hooks/map/useMapControls';

interface IProps {
  dictionaries: {
    data: TDictionariesArray;
  } | null;
  browserId: string;
  isEditMode?: boolean;
  onMediaChange?: Dispatch<SetStateAction<IUploadMedia[]>>;
}

export const AnalyticsAiAtmosphereGeneralInformationTab = ({
  dictionaries,
  isEditMode,
  browserId,
  onMediaChange,
}: IProps) => {
  const { values, setFieldValue, initialValues } = useFormikContext<IReportProtocolForm>();

  const { renderField } = useFormField<IReportProtocolForm>({
    schema: AnalyticsPelAiAtmosphereSchema,
    labelLocalization: { ...appLocale.analytics.fields, ...appLocale.analytics.pelAi.fields },
  });

  const organizations = useMemo(
    () =>
      getSelectOptionsFromDictionaryByName(dictionaries?.data, 'organizations', false, [
        initialValues.contractorId,
        initialValues.customerId,
      ]),
    [dictionaries?.data, initialValues.contractorId, initialValues.customerId],
  );

  // while status is hidden assume status as draft
  let fieldsIsDisabled = values.status === ANALYTICS_STATUS.APPROVED && !!isEditMode;
  fieldsIsDisabled = false;

  const fields: IRenderFieldInterface<IReportProtocolForm>[] = [
    { name: 'protocolNumber', isDisabled: fieldsIsDisabled },
    { name: 'protocolDate', type: 'dateinput', isDisabled: fieldsIsDisabled },
    { name: 'customerId', type: 'select', options: organizations, isDisabled: fieldsIsDisabled },
    { name: 'researchObject', isDisabled: fieldsIsDisabled },
    { name: 'address', isDisabled: fieldsIsDisabled },
  ];

  const fieldsSecond: IRenderFieldInterface<IReportProtocolForm>[] = [
    { name: 'contractorId', type: 'select', options: organizations, isDisabled: fieldsIsDisabled },
    { name: 'researchBasis', isDisabled: fieldsIsDisabled },
    { name: 'supportingDocuments', isDisabled: fieldsIsDisabled },
    { name: 'extraInfoValue', isDisabled: fieldsIsDisabled },
  ];

  const { startEditing, addFeature, sendCommand, pointExistsInMeta } = useMapControls({ browserId });

  useEffect(() => {
    if (values.meta) {
      try {
        const meta = JSON.parse(values.meta);
        const point = meta.features.find((point: any) => point.properties.id === 'mappoint');

        setFieldValue('longitude', point ? point.geometry.coordinates[0] : null);
        setFieldValue('latitude', point ? point.geometry.coordinates[1] : null);
        // eslint-disable-next-line no-empty
      } catch (error) {}
    }
  }, [setFieldValue, values.meta]);

  const pointExists = useMemo(() => pointExistsInMeta('mappoint', values.meta), [pointExistsInMeta, values.meta]);

  const onPointsChange = useCallback(
    (event: any) => {
      let features = [...event.detail.features];
      if (features.length > 1 || (features.length === 1 && features[0].properties.id !== 'mappoint')) {
        const feature = features[0];
        features = [
          {
            ...feature,
            properties: {
              ...feature.properties,
              id: 'mappoint',
              name: 'Место проведения измерений',
            },
          },
        ];
        void sendCommand(JSON.stringify({ ...event.detail, features, layerTemplateId: '1121430612' }));
      }

      const metaString = JSON.stringify({ ...event.detail, features, layerTemplateId: '1121430612' });
      setFieldValue('meta', metaString);
    },
    [sendCommand, setFieldValue],
  );

  return (
    <Box flexDirection="column">
      <FormWrapper>
        <Grid marginTop="30px" gap="15px">
          <Heading variant="h2">Данные обследования</Heading>
          {fields.map((field) => (
            <Fragment key={field.name}>{renderField(field)}</Fragment>
          ))}
          <Flex>
            <Field as={Input} name="latitude" label="Широта" isDisabled />
            <Field as={Input} name="longitude" label="Долгота" isDisabled />
            <Button
              height={45}
              onClick={() => (pointExists ? startEditing() : addFeature('mappoint', 'Место проведения измерений'))}
              ml={2}
            >
              <OnMap />
            </Button>
          </Flex>

          {fieldsSecond.map((field) => (
            <Fragment key={field.name}>{renderField(field)}</Fragment>
          ))}
        </Grid>
        <Box ml={2} width="600px" height="600px">
          <MapFormField onPointsChange={onPointsChange} browserId={browserId} onMediaChange={onMediaChange} />
        </Box>
      </FormWrapper>
    </Box>
  );
};
