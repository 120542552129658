import { FC, useContext, useEffect } from 'react';

import { WithSidebar } from '../WithSideBar/WithSidebar';
import { UploadFileCombineProviders } from '../UploadedFileProvider';
import { BreadcrumbsProvider } from '../breadcrumbs-provider';
import { UserInfoContext } from '../AuthProvider';
import { useLocation } from 'react-router-dom';
import { useExpireNotify } from 'api/services/auth';
import { IdleTimeout } from 'components/common/IdleTimeout';

/**
 * @param children - main routing
 * @summary HOC for setup app for authorized user
 */
export const WithSetup: FC = ({ children }) => {
  const { refetchRoles, user } = useContext(UserInfoContext);
  const location = useLocation();
  const deactivated = user?.deactivated || null;

  useExpireNotify(deactivated);

  useEffect(() => {
    refetchRoles();
  }, [location.pathname, refetchRoles]);

  return (
    <BreadcrumbsProvider>
      <WithSidebar>
        <IdleTimeout timeoutMs={user?.inactiveTimeout} />
        <UploadFileCombineProviders>{children}</UploadFileCombineProviders>
      </WithSidebar>
    </BreadcrumbsProvider>
  );
};
