import * as React from 'react';
import { useCallback, useEffect, useMemo } from 'react';
import { Breadcrumbs } from 'components/common';

import { Box, Flex, Switch, useColorMode } from '@chakra-ui/react';
import { LogoutButton } from 'features/WithSideBar/components/Logout';
import { ChangePasswordButton } from 'features/WithSideBar/components/ChangePassword';
import { ReactComponent as BellIcon } from './icons/bell.svg';
import { useHistory } from 'react-router-dom';
import { useRequest } from 'hooks';
import { ListResponse } from 'models/api/list';
import { INotification } from 'pages/Notifications/types';

export const Header: React.FC = () => {
  const { push } = useHistory();
  const { colorMode, toggleColorMode } = useColorMode();

  const { post, result } = useRequest<ListResponse<INotification>>('/notification/v10/notifications');

  useEffect(() => {
    void post({
      limit: 1,
      offset: 0,
      filters: [
        {
          key: 'isRead',
          operation: 'EQ',
          value: false,
        },
        {
          key: 'deleted',
          operation: 'EQ',
          value: false,
        },
      ],
    });
  }, [post]);

  const hasNotifications = useMemo(() => result?.data && result.data.length > 0, [result]);

  const onNotificationsClick = useCallback(() => {
    push('/notifications');
  }, [push]);

  return (
    <Box
      id="topline"
      zIndex={2}
      padding="17.5px 12.5px"
      borderBottom="1px solid"
      borderBottomColor="inherit"
      backgroundColor="inherit"
    >
      <Flex alignItems="center">
        <Breadcrumbs />
        <Flex ml="auto" alignItems="center">
          <Box onClick={onNotificationsClick} cursor="pointer" mr={4} position="relative">
            <BellIcon />
            {hasNotifications && (
              <Box height={2} width={2} background="red" borderRadius="50%" position="absolute" top={0} right={0} />
            )}
          </Box>

          <ChangePasswordButton />
          <LogoutButton />
          <Switch isChecked={colorMode === 'dark'} onChange={toggleColorMode} size="md" />
        </Flex>
      </Flex>
    </Box>
  );
};
