import { IDeviceStatus } from './types';

export const devicesStatusesNames = {
  inUse: 'aebae776-87a0-4759-9106-ee2a04718a32' as IDeviceStatus,
  isReserve: '02a8a58f-c480-41cb-a7ae-aa988fe72ac2' as IDeviceStatus,
  inVerification: '0e655b9f-9851-4d91-8a10-02cab0b3e869' as IDeviceStatus,
  inRepair: '63ed7ed2-cac5-46b3-a70b-b7c58829c7a6' as IDeviceStatus,
} as const;

// @ts-ignore
export const devicesStatuses:Record<IDeviceStatus, { id: IDeviceStatus, name: string, color: string }> = {
  [devicesStatusesNames.isReserve]: {
    id: devicesStatusesNames.isReserve,
    name: 'В резерве',
    color: 'rgb(115,115,115)'
  },
  [devicesStatusesNames.inUse]: {
    id: devicesStatusesNames.inUse,
    name: 'В эксплуатации',
    color: 'rgb(90,174,97)'
  },
  [devicesStatusesNames.inVerification]: {
    id: devicesStatusesNames.inVerification,
    name: 'В поверке',
    color: 'rgb(20,120,120)'
  },
  [devicesStatusesNames.inRepair]: {
    id: devicesStatusesNames.inRepair,
    name: 'В ремонте',
    color: 'rgb(46,241,241)'
  }
};
