import { useHandleDeleteData, useList } from 'hooks';
import { FTPRootPath } from '../consts';
import { MaterialTable } from 'features/material-table/MaterialTableMini';
import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import appLocale from 'constants/appLocale';
import { SubsystemHeader } from 'components/common';
import { DataPatternFilter } from './DataPatternFilter';
import { useDictionary } from 'hooks/useDictionary';
import { ColumnWithFilter } from 'lib/utils/tableFilter';
import { IFTPPattern } from './Consts/types';
import { dataPatternRootPath } from './Consts/consts';
import { DeleteIcon, EditIcon } from '../../../../components/icons';
import { DEFAULT_PAGE_SIZE_NEW_TABLE } from '../../../../constants/tables';
import { FullPageWrapper } from '../../../../components/table/FullPageWrapper';

export const DataPatterns = () => {
  useUpdateBreadcrumbs([
    {
      breadcrumb: appLocale.admin.main,
      path: '/admin',
    },
    {
      breadcrumb: appLocale.admin.ftp,
      path: FTPRootPath,
    },
    {
      breadcrumb: appLocale.admin.ftpDataPatterns,
      path: `${FTPRootPath}/dataPatterns`,
    },
  ]);

  const history = useHistory();

  const {
    isLoading,
    response,
    toggleOrder,
    setFilters,
    onPageChange,
    filterDTO,
    downloadAsFile,
    refetch,
    onSubmitFilters,
  } = useList<IFTPPattern, keyof IFTPPattern>(`${dataPatternRootPath}/list`, {
    initialOrderFieldKey: '-templateName',
    selectionKey: 'id',
    defaultPageSize: DEFAULT_PAGE_SIZE_NEW_TABLE,
    resetDataOnNextRequest: true,
  });

  const { dictionaries } = useDictionary({ url: `${dataPatternRootPath}/dictionary` });

  const { handleOpenModal, ModalComponent } = useHandleDeleteData({ refetch });

  const columns = useMemo<ColumnWithFilter<IFTPPattern>[]>(
    () => [
      {
        title: 'Наименование',
        field: 'templateName',
      },
      {
        title: 'Подсистема',
        field: 'subsystemName',
      },
    ],
    [],
  );

  return (
    <FullPageWrapper>
      <SubsystemHeader
        title={appLocale.admin.ftpDataPatterns}
        addButtonProps={{
          onClick: () => history.push(`${FTPRootPath}/dataPatterns/add`),
        }}
        filtersComponent={<DataPatternFilter dictionaries={dictionaries} onSubmitFilters={onSubmitFilters} />}
      />
      <MaterialTable
        data={response?.data}
        meta={response?.meta}
        toggleOrder={toggleOrder}
        filterDTO={filterDTO}
        onPageChange={onPageChange}
        columns={columns}
        actions={[
          {
            position: 'row',
            action: (row) => ({
              icon: () => <EditIcon />,
              onClick: () => {
                history.push(`${FTPRootPath}/dataPatterns/edit/${row.id}`);
              },
              tooltip: 'Редактировать',
              disabled: row.deleted,
              hidden: row.deleted
            }),
          },
          {
            position: 'row',
            action: (row) => ({
              icon: () => <DeleteIcon />,
              onClick: () => {
                if (row.id) {
                  handleOpenModal({ url: `${dataPatternRootPath}/${row.id}` });
                }
              },
              tooltip: 'Удалить',
              disabled: row.deleted,
              hidden: row.deleted
            }),
          },
        ]}
        isLoading={isLoading}
        setFilters={setFilters}
        options={{ selection: false }}
        downloadAsFile={downloadAsFile}
      />
      {ModalComponent}
    </FullPageWrapper>
  );
};
