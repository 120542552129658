import { Field, FieldHelperProps, FieldInputProps, FieldMetaProps, useFormikContext } from 'formik';
import { map } from 'lodash';
import React, { memo } from 'react';

import { CheckboxProps, DateInput, DateInputProps, Input, InputProps, Select, SelectProps } from 'components/common';
import { SystemSelect, SystemSelectProps } from 'components/common/Select/SystemSelect';
import { FilterCheckbox } from 'features/Filter/components';

export type FilterItem =
  | ({ type: 'DATE'; name: string; } & DateInputProps)
  | ({ type: 'TEXT'; name: string; } & InputProps)
  | ({ type: 'NUMBER'; name: string; } & InputProps)
  | ({ type: 'SELECT'; name: string; parentField?: string; childField?: string; } & SelectProps)
  | ({ type: 'MULTI_SELECT'; name: string; childField?: string; parentField?: string; resetOnChange?: string[]; } & SelectProps)
  | ({ type: 'SYSTEM_SELECT'; name: string; } & SystemSelectProps)
  | ({ type: 'CHECKBOX'; name: string; } & CheckboxProps);

export type FilterField = FilterItem & Partial<FieldMetaProps<any> & FieldInputProps<any> & FieldHelperProps<any>>;

interface RenderFilterFieldsByTypeProps {
  fields: Array<FilterField>;
  isDisabled: boolean;
  maxDate?: string | null;
  minDate?: string | null;
  dateFieldTo?: string;
  dateFieldFrom?: string;
}

interface IRenderFilterFieldsByType {
  (props: React.PropsWithChildren<RenderFilterFieldsByTypeProps>): React.ReactElement<RenderFilterFieldsByTypeProps>;
}

export const RenderFilterFieldsByType = memo<IRenderFilterFieldsByType>(
  ({ fields, minDate, maxDate, isDisabled, dateFieldFrom, dateFieldTo }) => {
    const { values, setFieldValue } = useFormikContext<any>();
    return (
      <>
        {map(fields, ({ type, ...field }) => {
          if (type === 'MULTI_SELECT') {
            // @ts-nocheck
            // TODO: make separate dependant fields type instead of this
            // @ts-ignore
            const _isDisabled = isDisabled || (field.parentField && !values[field.parentField]);
            // @ts-ignore
            // eslint-disable-next-line max-len
            const options = field.parentField ? field.options.filter((item) => item.parent === values[field.parentField]) : field.options;
            // @ts-ignore
            const onChange = field.childField || field.resetOnChange ?
              (event: React.ChangeEvent<HTMLSelectElement>) => {
                if (field.onChange) {
                  field.onChange(event);
                }

                // @ts-ignore
                if (field.childField) {
                  // @ts-ignore
                  setFieldValue(field.childField, null);
                }

                // @ts-ignore
                if (field.resetOnChange) {
                  // @ts-ignore
                  field.resetOnChange.forEach((field: string) => {
                    setFieldValue(field, null);
                  });
                }
              } : field.onChange;
            return <Field key={field.name} as={Select} size="lg" multiple {...field} onChange={onChange} options={options} isDisabled={_isDisabled} />;
          }

          if (type === 'CHECKBOX') {
            return <Field as={FilterCheckbox} key={field.name} checked={field.value} {...field} />;
          }

          if (type === 'DATE') {
            return (
              <Field
                key={field.name}
                as={DateInput}
                size="small"
                {...field}
                maxDate={maxDate}
                minDate={minDate}
                dateFieldFrom={dateFieldFrom}
                dateFieldTo={dateFieldTo}
                isDisabled={isDisabled}
              />
            );
          }

          if (type === 'SELECT') {
            // TODO: make separate dependant fields type instead of this
            // @ts-ignore
            const _isDisabled = isDisabled || (field.parentField && !values[field.parentField]);
            // @ts-ignore
            // eslint-disable-next-line max-len
            const options = field.parentField ? field.options.filter((item) => item.parent === values[field.parentField]) : field.options;

            // @ts-ignore
            const onChange = field.childField ? (event: React.ChangeEvent<HTMLSelectElement>) => {
              if (field.onChange) {
                field.onChange(event);
              }
              // @ts-ignore
              setFieldValue(field.childField, null);
            } : field.onChange;

            return <Field
              key={field.name}
              as={Select}
              size="lg"
              multiple={false}
              isDisabled={_isDisabled}
              {...field}
              options={options}
              onChange={onChange}
            />;
          }

          if (type === 'SYSTEM_SELECT') {
            return (
              <Field key={field.name} as={SystemSelect} size="lg" multiple={false} isDisabled={isDisabled} {...field} />
            );
          }

          if (type === 'TEXT') {
            return <Field key={field.name} as={Input} size="small" isDisabled={isDisabled} {...field} />;
          }

          if (type === 'NUMBER') {
            return <Field key={field.name} as={Input} type="number" size="small" isDisabled={isDisabled} {...field} />;
          }

          return null;
        })}
      </>
    );
  },
);
