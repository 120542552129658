import { Route } from 'react-router-dom';
import { EquipmentsAppRoutes as routes } from '../equipment/equipmentsAppRoutes';

import { EditStation } from 'pages/equipment/Stations/EditStation';
import { AddStation } from 'pages/equipment/Stations/AddStation';
import { Stations } from 'pages/equipment/Stations/Stations';
import { EditDeviceModel } from 'pages/equipment/Models/EditDeviceModel';
import { DeviceModels } from 'pages/equipment/Models/DeviceModels';
import { AddDeviceModel } from 'pages/equipment/Models/AddDeviceModel';
import { EquipmentList } from 'pages/equipment/components/EquipmentList';
import { Devices } from 'pages/equipment/Devices/Devices';
import { AddDevice } from 'pages/equipment/Devices/AddDevice';
import { EditDevice } from 'pages/equipment/Devices/EditDevice';
import { EquipmentReportsDiagram } from '../equipment/Reports/DiagramComponents';
import { EquipmentReportsList } from '../equipment/Reports/components/EquipmentReportsList';
import { EquipmentReportTable } from '../equipment/Reports/TableComponents';

export const EquipmentRouter = () => (
  <>
    <Route path={routes.root} exact component={EquipmentList} />

    <Route path={`${routes.stations.edit}/:newData`} exact component={EditStation} />
    <Route path={routes.stations.add} exact component={AddStation} />
    <Route path={routes.stations.root} exact component={Stations} />

    <Route path={`${routes.models.edit}/:newData`} exact component={EditDeviceModel} />
    <Route path={routes.models.add} exact component={AddDeviceModel} />
    <Route path={routes.models.root} exact component={DeviceModels} />

    <Route path={`${routes.devices.edit}/:newData`} exact component={EditDevice} />
    <Route path={routes.devices.add} exact component={AddDevice} />
    <Route path={routes.devices.root} exact component={Devices} />

    <Route path={routes.reports.root} exact component={EquipmentReportsList} />
    <Route path={routes.reports.table} exact component={EquipmentReportTable} />
    <Route path={routes.reports.diagramDevices} exact component={EquipmentReportsDiagram} />
    <Route path={routes.reports.diagramStations} exact component={EquipmentReportsDiagram} />
  </>
);
