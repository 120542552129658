import { Button, DateInput, Modal, Select } from 'components/common';
import { Field, FormikProvider, useFormik } from 'formik';
import { createDateFromResponse, createDateToRequestWithTimezone } from 'lib/create-date';
import React, { useCallback } from 'react';
import { Grid } from '@chakra-ui/react';
import * as yup from 'yup';
import { requiredError } from 'models/schemas/constans';
import appLocale from 'constants/appLocale';
import { useNotifications, useRequest } from 'hooks';
import moment from 'moment';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  refetch: () => void;
}

const validationSchema = yup.object().shape({
  day: yup.string().nullable().required(requiredError),
  forecastDate: yup.string().nullable().required(requiredError),
});

const ForecastReportAddReportModal = ({ isOpen, onClose, refetch }: Props) => {
  const { errorNotify, successNotify } = useNotifications();
  const { post, isLoading } = useRequest(
    '/meteo/v10/forecast/report',
    () => {
      successNotify({ key: 'data/report/created', message: 'Сравнение создано' });
      refetch();
      onClose();
    },
    (err) => {
      errorNotify({ key: `data/report/create/error/${new Date()}`, message: err?.descriptions[0]?.message || 'Ошибка сравнения' });
    }
  );

  const onSubmit = useCallback(
    async (values) => {
      await post({
        day: values.day === 'day',
        forecastDate: moment(values.forecastDate).format('YYYY-MM-DD')
      });
    },
    [post]
  );
  const {
    values,
    errors,
    getFieldMeta,
    getFieldHelpers,
    getFieldProps,
    handleReset,
    submitForm,
    setFieldValue,
    ...formik
  } = useFormik({
    enableReinitialize: true,
    validateOnBlur: true,
    initialValues: { day: 'day', forecastDate: '' },
    validationSchema,
    onSubmit,
  });

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      header="Укажите дату и период прогноза расчета сравнения"
      closeButtonPosition="contentTop"
    >
      <FormikProvider
        value={{
          values,
          errors,
          getFieldMeta,
          getFieldHelpers,
          getFieldProps,
          handleReset,
          submitForm,
          setFieldValue,
          ...formik,
        }}
      >
        <Grid gap="20px" gridTemplateColumns="repeat(2,  250px)">
          <Field
            as={DateInput}
            name="forecastDate"
            label="Дата прогноза"
            value={createDateFromResponse(values.forecastDate)}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFieldValue('forecastDate', createDateToRequestWithTimezone(e.target.value));
            }}
            error={errors.forecastDate}
          />
          <Field
            as={Select}
            name="day"
            label="Период"
            options={[
              { name: 'День', value: 'day' },
              { name: 'Ночь', value: 'night' },
            ]}
            value={values.day}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFieldValue('day', e.target.value);
            }}
            error={errors.day}
          />

          <Button
            onClick={(e) => {
              handleReset(e);
              refetch();
              onClose();
            }}
            isLoading={isLoading}
            isDisabled={isLoading}
            marginRight="auto"
          >
            {appLocale.actions.cancel}
          </Button>
          <Button
            marginLeft="auto"
            variant="solid"
            onClick={submitForm}
            isLoading={isLoading}
            isDisabled={Object.keys(errors).length > 0}
          >
            Рассчитать
          </Button>
        </Grid>
      </FormikProvider>
    </Modal>
  );
};

export default ForecastReportAddReportModal;
