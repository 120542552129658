import { Flex } from '@chakra-ui/react';
import { createLocalDateDDMMYYYY, createLocalTimeFromDate } from 'lib/create-date';
import React from 'react';

interface Props {
  datestring?: string | null;
  inline?: boolean;
}

// TODO: check similar components
export const DateTimeTableCell: React.FC<Props> = ({ datestring, inline }) => {
  if (!datestring) return null;

  if (inline) {
    return <>{createLocalTimeFromDate(datestring)} {createLocalDateDDMMYYYY(datestring)}</>;
  }

  return (
    <Flex flexDirection="column">
      <span>{createLocalTimeFromDate(datestring)}</span>
      <span>{createLocalDateDDMMYYYY(datestring)}</span>
    </Flex>
  );
};
