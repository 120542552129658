import { FormHeader } from 'components/common';
import { ERROR_MESSAGES, SUCCESS_MESSAGES } from 'constants/messages';
import { createTouchedErrors } from 'features/create-touched-errors';
import { FormikProvider, useFormik } from 'formik';
import { useNotifications, useRequest } from 'hooks';
import { NotificationSettingSchema } from 'models/schemas/admin/notificationsetting';
import { NotificationSettingsItem } from 'pages/admin/models/NotificationSettingsItem';
import { useTypedLoader } from 'pages/admin/typedLoader';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { baseAdminNotificationUrl } from '../const';
import FieldsForm from './FieldsForm';

export const EditNotificationForm = () => {
  const history = useHistory();

  const { id } = useParams<{
    id: string;
  }>();

  const { isLoading: isNotificationLoading, result: currentNotification } = useTypedLoader<NotificationSettingsItem>(
    `${baseAdminNotificationUrl}/${id}`
  );

  const initialValues = { ...currentNotification };

  const { successNotify, errorNotify } = useNotifications();

  const successHandler = () => {
    successNotify({
      key: 'file/create/success',
      message: SUCCESS_MESSAGES.EDIT,
    });
    history.push('/admin/notification');
  };
  const errorHandler = (error: any) => {
    errorNotify({
      key: 'file/create/error',
      message: `${ERROR_MESSAGES.EDIT}. ${typeof error === 'string' ? error : ''}`,
    });
  };

  const { put: updateNotification } = useRequest(`${baseAdminNotificationUrl}/${id}`, successHandler, errorHandler);

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    initialValues,
    validationSchema: NotificationSettingSchema,
    onSubmit: (values) => updateNotification(values),
  });

  return (
    <FormikProvider
      value={{
        ...formik,
        errors: createTouchedErrors(formik.errors, formik.touched),
      }}
    >
      <FormHeader
        url="/admin/notification"
        header={`Редактирование настройки уведомлений: '${currentNotification?.id}''`}
      />
      <FieldsForm onCancel={() => history.push('/admin/notification')} isLoading={isNotificationLoading} />
    </FormikProvider>
  );
};
