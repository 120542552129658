import { PageHeader, RoutesList } from 'components/common';
import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import appLocale from 'constants/appLocale';
import { Flex, Heading } from '@chakra-ui/react';
import { useRoutesList } from '../../hooks/useRoutesList';
import { AdminMenu as AdminMenuRenderTree } from 'pages/admin/consts';

export const AdminMenu: React.FC = () => {
  useUpdateBreadcrumbs([{ breadcrumb: 'Администрирование', path: '/admin' }]);
  const routes = useRoutesList(AdminMenuRenderTree);
  return (
    <Flex flexDirection="column">
      <PageHeader>
        <Heading>{appLocale.admin.main}</Heading>
      </PageHeader>
      <RoutesList
        routes={routes}
      />
    </Flex>
  );
};
