import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import { EquipmentLocale } from '../locale';
import { EquipmentsAppRoutes } from '../equipmentsAppRoutes';

export const useEquipmentBreadcrumbs = () => {
  useUpdateBreadcrumbs([
    {
      breadcrumb: EquipmentLocale.root,
      path: EquipmentsAppRoutes.root,
    },
  ]);
};
