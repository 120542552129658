import { Route } from 'react-router-dom';
import { AdminMenu } from './admin';
import { DepartmentList } from './Department/DepartmentList';
import { Departments } from './Department/Departments';
import { AddDepartmentForm } from './Department/forms/AddDepartmentForm';
import { EditDepartmentForm } from './Department/forms/EditDepartmentForm';
import { AddPeriodForm } from './Period/forms/AddPeriodForm';
import { EditPeriodForm } from './Period/forms/EditPeriodForm';
import { PeriodList } from './Period/PeriodList';
import { Periods } from './Period/Periods';
import { AddReportForm } from './Report/forms/AddReportForm';
import { EditReportForm } from './Report/forms/EditReportForm';
import { ReportList } from './Report/ReportList';
import { Reports } from './Report/Reports';
import { AddRightForm } from './Right/forms/AddRightForm';
import { EditRightForm } from './Right/forms/EditRightForm';
import { RightList } from './Right/RightList';
import { Rights } from './Right/Rights';
import { EditRoleForm } from './Role/forms/EditRoleForm';
import { RoleList } from './Role/RoleList';
import { Roles } from './Role/Roles';
import { AddUserForm } from './User/forms/AddUserForm';
import { EditUserForm } from './User/forms/EditUserForm';
import { UserSyncForm } from './User/forms/UserSyncForm';
import { UserList } from './User/UserList';
import { Users } from './User/Users';
import { Journal } from './Journal/Journal';
import { Catalogs, FTPMenu, FTPRootPath, FTPServers, FTPSettings } from 'pages/admin/FTP';
import { AddServerForm } from 'pages/admin/FTP/FTPsettings/Servers/Forms';
import { AddCatalog, EditCatalog } from 'pages/admin/FTP/FTPsettings/Catalogs/Forms';
import { EditServerForm } from './FTP/FTPsettings/Servers/Forms';
import ApiToken from 'pages/admin/ApiToken/ApiToken';
import ApiTokenAdd from 'pages/admin/ApiToken/Forms/ApiTokenAdd';
import ApiTokenEdit from 'pages/admin/ApiToken/Forms/ApiTokenEdit';
import { FTPUpload } from 'pages/admin/FTP/FTPUpload/FTPUpload/FTPUpload';
import { AddFTPUpload, EditFTPUpload } from 'pages/admin/FTP/FTPUpload/FTPUpload/Forms';
import { EXD } from './EXD';
import { DataEntryLog } from './DataEntryLog';
import { Notifications } from './Notification/Notifications';
import { AddNotificationForm } from './Notification/forms/AddNotificationSettingsForm';
import { EditNotificationForm } from './Notification/forms/EditNotificationSettingsForm';
import { adminNODLogPath } from './NODUploadLog/consts';
import { NODUploadLog } from './NODUploadLog';
import { LineByLineView } from './NODUploadLog/LineByLineView';
import { PasswordComplexitySettings } from './Password/PasswordComplexitySettings';
import { PasswordRequests } from './Password/PasswordRequests';
import { NotificationJournal } from './NotificationJournal';
import { DataPatterns } from 'pages/admin/FTP/DataPatterns';
import { DataPatternEditForm } from './FTP/DataPatterns/DataPatternEditForm';

export const AdminRoutes: React.FC = () => {
  const adminRoot = '/admin';
  const adminUser = `${adminRoot}/user`;
  const adminRole = `${adminRoot}/role`;
  const adminRight = `${adminRoot}/right`;
  const adminPeriod = `${adminRoot}/period`;
  const adminDepartment = `${adminRoot}/department`;
  const adminReport = `${adminRoot}/report`;
  const adminJournal = `${adminRoot}/journal`;
  const dataEntryLog = `${adminRoot}/dataEntryLog`;
  const adminNotification = `${adminRoot}/notification`;
  const adminNotificationJournal = `${adminRoot}/noticeLog`;
  const adminPasswordRequests = `${adminRoot}/passwordRequest`;
  const adminPasswordComplexitySettings = `${adminRoot}/passwordComplexitySettings`;

  return (
    <>
      {/* ADMIN PANEL */}
      <Route path={adminRoot} exact component={AdminMenu} />

      {/* PASSWORD REQUEST LIST */}
      <Route path={adminPasswordRequests} exact component={PasswordRequests} />
      <Route path={adminPasswordComplexitySettings} exact component={PasswordComplexitySettings} />

      {/* ADMIN USERS */}
      <Route path={adminUser} exact component={Users} />
      <Route path={`${adminUser}/list`} exact component={UserList} />
      <Route path={`${adminUser}/sync`} exact component={UserSyncForm} />
      <Route path={`${adminUser}/add`} exact component={AddUserForm} />
      <Route path={`${adminUser}/edit/:id`} exact component={EditUserForm} />

      {/* ADMIN ROLES */}
      <Route path={adminRole} exact component={Roles} />
      <Route path={`${adminRole}/list`} exact component={RoleList} />
      <Route path={`${adminRole}/add`} exact component={EditRoleForm} />
      <Route path={`${adminRole}/edit/:id`} exact component={EditRoleForm} />

      {/* ADMIN RIGHTS */}
      <Route path={adminRight} exact component={Rights} />
      <Route path={`${adminRight}/list`} exact component={RightList} />
      <Route path={`${adminRight}/add`} exact component={AddRightForm} />
      <Route path={`${adminRight}/edit/:id`} exact component={EditRightForm} />

      {/* ADMIN PERIODS */}
      <Route path={adminPeriod} exact component={Periods} />
      <Route path={`${adminPeriod}/list`} exact component={PeriodList} />
      <Route path={`${adminPeriod}/add`} exact component={AddPeriodForm} />
      <Route path={`${adminPeriod}/edit/:id`} exact component={EditPeriodForm} />

      {/* ADMIN DEPARTMENTS */}
      <Route path={adminDepartment} exact component={Departments} />
      <Route path={`${adminDepartment}/list`} exact component={DepartmentList} />
      <Route path={`${adminDepartment}/add`} exact component={AddDepartmentForm} />
      <Route path={`${adminDepartment}/edit/:id`} exact component={EditDepartmentForm} />

      {/* ADMIN REPORTS */}
      <Route path={adminReport} exact component={Reports} />
      <Route path={`${adminReport}/list`} exact component={ReportList} />
      <Route path={`${adminReport}/add`} exact component={AddReportForm} />
      <Route path={`${adminReport}/edit/:id`} exact component={EditReportForm} />
      {/* <Route
        path="/admin/ftp/ftp-settings"
        exact
        component={FTPSettings}
      />
      <Route
        path="/admin/ftp/incoming-traffic"
        exact
        component={IncomingTraffic}
      />
      <Route
        path="/admin/meteo/weather-protocol"
        exact
        component={MeteoProtocol}
      /> */}
      {/* ADMIN JOURNAL */}
      <Route path={adminJournal} exact component={Journal} />

      {/* ADMIN FTP */}
      <Route path={FTPRootPath} exact component={FTPMenu} />
      <Route path={`${FTPSettings}/servers`} exact component={FTPServers} />
      <Route path={`${FTPSettings}/catalogs`} exact component={Catalogs} />
      <Route path={`${FTPSettings}/servers/add`} exact component={AddServerForm} />
      <Route path={`${FTPSettings}/catalogs/add`} exact component={AddCatalog} />
      <Route path={`${FTPSettings}/servers/edit/:id`} exact component={EditServerForm} />
      <Route path={`${FTPSettings}/catalogs/edit/:id`} exact component={EditCatalog} />

      <Route path={`${FTPRootPath}/upload`} exact component={FTPUpload} />
      <Route path={`${FTPRootPath}/upload/add`} exact component={AddFTPUpload} />
      <Route path={`${FTPRootPath}/upload/edit/:id`} exact component={EditFTPUpload} />

      <Route path={`${FTPRootPath}/dataPatterns`} exact component={DataPatterns} />
      <Route path={`${FTPRootPath}/dataPatterns/add`} exact component={DataPatternEditForm} />
      <Route path={`${FTPRootPath}/dataPatterns/edit/:id`} exact component={DataPatternEditForm} />
      {/* ADMIN API TOKENS */}
      <Route path="/admin/apiToken" exact component={ApiToken} />
      <Route path="/admin/apiToken/add" exact component={ApiTokenAdd} />
      <Route path="/admin/apiToken/edit/:id" exact component={ApiTokenEdit} />
      {/* EXD */}
      <Route path="/admin/EXD" exact component={EXD} />
      {/* Data entry log */}
      <Route path={dataEntryLog} exact component={DataEntryLog} />

      {/* ADMIN NOTIFICATIONS */}
      <Route path={adminNotification} exact component={Notifications} />
      <Route path={`${adminNotification}/add`} exact component={AddNotificationForm} />
      <Route path={`${adminNotification}/edit/:id`} exact component={EditNotificationForm} />

      {/* ADMIN NOTIFICATIONS JOURNAL */}
      <Route path={adminNotificationJournal} exact component={NotificationJournal} />

      {/* NOD upload log */}
      <Route path={adminNODLogPath} exact component={NODUploadLog} />
      <Route path={`${adminNODLogPath}/view/:id`} exact component={LineByLineView} />
    </>
  );
};
