import appLocale from 'constants/appLocale';
import subsystemIdsMap from 'constants/subsystemIdsMap';
import { Greenplants } from 'pages/home/icons';
import React from 'react';
import { RenderTree } from '../types';
import {
  GreenPlantsAreasFlora,
  GreenPlantsAreasLawns,
  GreenPlantsFloraUrl,
  GreenPlantsLawnsUrl,
  GreenPlantsPlatformUrl,
  GreenPlantsStatementsUrl,
} from 'pages/routes/GreenPlants';

export const greenplantsPlatformsMenu: RenderTree = {
  name: appLocale.subsystems.greenPlantsAreas,
  id: GreenPlantsPlatformUrl,
  url: GreenPlantsPlatformUrl,
  children: [
    {
      name: 'Деревья и кустарники',
      id: GreenPlantsAreasFlora,
      url: GreenPlantsAreasFlora,
      subsystemId: subsystemIdsMap.greenplantsFlora,
    },
    {
      name: 'Газоны',
      id: GreenPlantsAreasLawns,
      url: GreenPlantsAreasLawns,
      subsystemId: subsystemIdsMap.greenplantsLawns,
    },
  ],
};

export const greenplantsStatesmentsMenu: RenderTree = {
  name: appLocale.subsystems.statements,
  id: GreenPlantsStatementsUrl,
  url: GreenPlantsStatementsUrl,
  children: [
    {
      name: 'Деревья и кустарники',
      id: GreenPlantsFloraUrl,
      url: GreenPlantsFloraUrl,
      subsystemId: subsystemIdsMap.greenplantsFlora,
    },
    {
      name: 'Газоны',
      id: GreenPlantsLawnsUrl,
      url: GreenPlantsLawnsUrl,
      subsystemId: subsystemIdsMap.greenplantsLawns,
    },
  ],
};

export const greenplantsMenu: RenderTree = {
  name: appLocale.subsystems.greenplants,
  id: '/data/greenplants',
  url: '/data/greenplants',
  subsystemId: subsystemIdsMap.greenPlantsRoot, // Корневая подсистемаxwx
  homepageIcon: <Greenplants />,
  children: [greenplantsStatesmentsMenu, greenplantsPlatformsMenu],
};
