import { FormikProvider, useFormik } from 'formik';
import { IFilterItem, TDictionaryItemDictionary, TFiltersArray } from 'models';
import { PDVFormFields } from './FormFields';
import { Grid } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { IPDVItem } from '../types';
import { FiltersDatepickersSchema } from 'models/schemas/filtersDatepickersSchema/FiltersDatepickersSchema';
import combineFilters from 'lib/utils/combineFilters';
import { SelectOption } from 'components/common';

const initialValues: Record<string, IFilterItem<string>> = {
  startDate: {
    key: 'startDate',
    operation: 'GTE',
    value: null,
  },
  endDate: {
    key: 'startDate',
    operation: 'LTE',
    value: null,
  },
  industryId: {
    key: 'industryId',
    operation: 'EQ',
    value: null,
  },
  parameterId: {
    key: 'parameterId',
    operation: 'EQ',
    value: null,
  },
  sourceId: {
    key: 'sourceId',
    operation: 'EQ',
    value: null,
  },
  deleted: {
    key: 'deleted',
    operation: 'EQ',
    value: false,
  },
};

interface IProps {
  onSubmitFilters: (filters: TFiltersArray<keyof IPDVItem>) => void;
  industries: SelectOption[];
  sources: TDictionaryItemDictionary[];
  parameters: TDictionaryItemDictionary[];
  children: React.ReactNode;
  filters: TFiltersArray;
}

export const PDVFilter = ({ onSubmitFilters, industries, sources, parameters, children, filters }: IProps) => {
  const { setValues, ...formik } = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    validationSchema: FiltersDatepickersSchema([{ dateFromFieldName: 'startDate', dateToFieldName: 'endDate' }]),
    initialValues,
    onSubmit: (values: any) => {
      const filters: TFiltersArray<keyof IPDVItem> = Object.values(values);
      onSubmitFilters(filters);
    },
  });

  useEffect(() => {
    setValues(combineFilters(initialValues, filters));
  }, [setValues, filters]);

  return (
    <FormikProvider
      value={{
        setValues,
        ...formik,
      }}
    >
      <PDVFormFields industries={industries} sources={sources} parameters={parameters} />
      <Grid marginTop={2}>{children}</Grid>
    </FormikProvider>
  );
};
