import moment from 'moment';
import getFileExtensionByMimeType, { ExtensionToMimeTypeEnum } from 'lib/utils/files/getFileExtensionByMimeType';

interface IMakeFileName {
  type: ExtensionToMimeTypeEnum;
  name?: string;
  addDate?: boolean;
}
const makeFileName = ({ type, name = 'export', addDate }: IMakeFileName): string => {
  const date = addDate ? `_${moment().format('yyyy-MM-DD_HH-mm')}` : '';
  return `${name}${date}${getFileExtensionByMimeType(type)}`;
};

export default makeFileName;
