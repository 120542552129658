import React, { useMemo } from 'react';
import { Field, useFormikContext } from 'formik';
import { Button, FormHeader, Input } from 'components/common';
import { Grid } from '@chakra-ui/react';
import { IMeasureUnitItem, unitLocales, UnitRootPath } from '../consts';
import { COMMON_LOCALE } from 'constants/common';
import { useGoBackWithFallback } from 'lib/utils/goBackWithFallback';

interface Props {
  isEditMode?: boolean;
}

export const UnitFields = ({ isEditMode }: Props) => {
  const { backWithFallback } = useGoBackWithFallback();
  const { errors, handleSubmit } = useFormikContext<IMeasureUnitItem>();

  const title = useMemo(
    () => (isEditMode ? unitLocales.unitEditing : unitLocales.unitAdding),
    [isEditMode]
  );
  return (
    <>
      <FormHeader onBackClick={() => backWithFallback(UnitRootPath)} header={title} />
      <Grid gap={3} width="50%" margin={5}>
        <Field
          as={Input}
          name="name"
          label={unitLocales.name}
          error={errors?.name}
        />
        <Field
          as={Input}
          name="shortName"
          label={unitLocales.shortName}
          error={errors?.shortName}
        />
        <Field
          as={Input}
          name="code"
          label={unitLocales.code}
        />
        <Field
          as={Input}
          name="localName"
          label={unitLocales.localName}
        />
        <Field
          as={Input}
          name="intName"
          label={unitLocales.intName}
        />
        <Field
          as={Input}
          name="localCode"
          label={unitLocales.localCode}
        />
        <Field
          as={Input}
          name="intCode"
          label={unitLocales.intCode}
        />
        <Button
          width="50%"
          onClick={() => handleSubmit()}
        >
          {COMMON_LOCALE.save}
        </Button>
      </Grid>
    </>
  );
};
