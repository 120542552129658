import { As, FormControlProps, forwardRef } from '@chakra-ui/react';
import React, { memo, useCallback, useMemo } from 'react';
import { FormikFieldProps } from '../types';
import { Input, InputProps } from './input';
import { ParameterType } from '../../../pages/data/PGR/types';
import { useFormikContext } from 'formik';
import { ISurfaceWaterLoadData } from '../../../pages/data/Water/SurfaceWater/types';

export const MoreLessNumberInput = memo(
  forwardRef<InputProps & Partial<FormikFieldProps> & { disabled?: boolean; isRequired?: FormControlProps['isRequired']; mask?: string; isAVG?: boolean; },
    As<InputProps & Partial<FormikFieldProps>>>(
      (
        { value: valueObject, ...props },
        ref,
      ) => {
        const types = useMemo<Record<string, ParameterType>>(() => (
          !props.isAVG ? {
            more: 'MORE',
            less: 'LESS',
            default: 'ABS'
          } :
            {
              more: 'AVGMORE',
              less: 'AVGLESS',
              default: 'AVG'
            }
        ), [props.isAVG]);

        const value = useMemo(() => valueObject?.valueAsString, [valueObject?.valueAsString]);
        const type = useMemo(() => valueObject?.type || types.default, [valueObject?.type, types]);

        const { setFieldValue } = useFormikContext<ISurfaceWaterLoadData>();

        const prepareValueForView = useMemo(() => {
          let typeString = '';
          switch (type) {
            case types.more:
              typeString = 'более ';
              break;
            case types.less:
              typeString = 'менее ';
              break;
            case types.default:
            default:
              typeString = '';
          }

          const val = value !== undefined && value !== null ? String(value) : '';

          return `${typeString}${val.replace('.', ',')}`;
        }, [type, types, value]);

        const extractType = useCallback((value: string | null): [ParameterType, string | null] => {
          let type: ParameterType = types.default;
          if (value && value.length > 0) {
            const firstSymbol = value.split(' ')[0];
            switch (firstSymbol) {
              case '>':
              case 'более':
                type = types.more;
                // eslint-disable-next-line prefer-destructuring
                value = value.split(' ')[1];
                break;
              case 'менее':
              case '<':
                type = types.less;
                // eslint-disable-next-line prefer-destructuring
                value = value.split(' ')[1];
                break;
            }
          }

          return [type, value];
        }, [types]);

        const isValid = useCallback((val: string) => !!String(val)?.match(/^((более|менее)\s)?(\d+)(,\d+)?$/), []);
        const valid = useMemo(() => isValid(prepareValueForView), [prepareValueForView, isValid]);

        const handleChange = useCallback(
          (innerValue: string) => {
            let val = innerValue
              .replace('>', 'более')
              .replace('<', 'менее');

            if (isValid(val)) {
              val = innerValue
                .replace(',', '.');
            }

            const [type, value] = extractType(val);

            if (props.name) {
              setFieldValue(`${props.name}.type`, type);
              setFieldValue(`${props.name}.value`, value);
              setFieldValue(`${props.name}.valueAsString`, value);
            }

            if (props.onChange) {
              props.onChange({
                target: { value, name: props.name },
              } as React.BaseSyntheticEvent);
            }
          },
          [setFieldValue, isValid, props, extractType]
        );

        return (
          <Input
            ref={ref}
            {...props}
            error={props.error || (!valid && prepareValueForView ? 'Значения могут быть только в формета "ЧИСЛО", "более ЧИСЛО" или "менее ЧИСЛО"' : '')}
            value={prepareValueForView}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e.target.value)}
          />
        );
      },
    ),
);
