import * as yup from 'yup';
import { ValidationError } from 'yup';
import { lessThanDate, moreThanDate, requiredError } from 'models/schemas/constans';
import moment from 'moment';

export const PDKSchema = yup.object()
  .shape({
    chemical_id: yup.string()
      .nullable()
      .required(requiredError),
    natural_environment: yup.string()
      .typeError(requiredError)
      .required(requiredError),
    pdkStartDate: yup.string()
      .typeError(requiredError)
      .required(requiredError),
    pdkEndDate: yup.string()
      .typeError(requiredError)
      .required(requiredError),
    pdkKind: yup.string()
      .typeError(requiredError)
      .required(requiredError),
    pdk_document: yup.string()
      .nullable()
      .required(requiredError),
    pdk_status: yup.string()
      .typeError(requiredError)
      .required(requiredError),
  })
  .test((values) => {
    if (values.pdkStartDate && values.pdkEndDate) {
      if (moment(values.pdkStartDate).isAfter(values.pdkEndDate) || values.pdkStartDate === values.pdkEndDate) {
        const dateErrors = [
          new ValidationError(lessThanDate('или равно дате начала'), '', 'pdkEndDate'),
          new ValidationError(moreThanDate('или равно дате окончания'), '', 'pdkStartDate'),
        ];
        return new ValidationError(dateErrors);
      }
    }
    return true;
  });
