import { FormHeader } from 'components/common';
import { ERROR_MESSAGES, SUCCESS_MESSAGES } from 'constants/messages';
import { createTouchedErrors } from 'features/create-touched-errors';
import { FormikProvider, useFormik } from 'formik';
import { useNotifications } from 'hooks/useNotifications';
import { useRequest } from 'hooks/useRequest';
import { DepartmentSchema } from 'models/schemas/admin/department';
import { DepartmentItem } from 'pages/admin/models/DepartmentItem';
import { useHistory } from 'react-router-dom';
import { baseAdminDepartmentUrl } from '../consts';
import FieldsForm from './FieldsForm';

const initialValues: DepartmentItem = {
  id: undefined,
  code: '',
  name: '',
  managerId: undefined,
  deleted: false,
};

export const AddDepartmentForm = () => {
  const history = useHistory();

  const { successNotify, errorNotify } = useNotifications();

  const successHandler = () => {
    successNotify({
      key: 'file/create/success',
      message: SUCCESS_MESSAGES.CREATE,
    });
    history.push('/admin/department');
  };
  const errorHandler = (error: any) => {
    errorNotify({
      key: 'file/create/error',
      message: `${ERROR_MESSAGES.CREATE}. ${typeof error === 'string' ? error : ''}`,
    });
  };
  const { post: createDepartment, isLoading } = useRequest(baseAdminDepartmentUrl, successHandler, errorHandler);

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    initialValues,
    validationSchema: DepartmentSchema,
    onSubmit: (values) => createDepartment(values),
  });

  return (
    <FormikProvider
      value={{
        ...formik,
        errors: createTouchedErrors(formik.errors, formik.touched),
      }}
    >
      <FormHeader url="/admin/department" header="Добавление отдела" />
      <FieldsForm onCancel={() => history.push('/admin/department')} isLoading={isLoading} />
    </FormikProvider>
  );
};
