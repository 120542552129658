import * as yup from 'yup';
import { ValidationError } from 'yup';
import { atLeastOneError, maxSymbols, requiredError } from '../constans';
import { BottomSoilParametersKeys } from 'pages/data/Water/BottomSoil/consts/types';

export const AnalyticsBottomSoilSchema = yup
  .object()
  .shape({
    protocolNumber: yup.string().required(requiredError).nullable().max(15, maxSymbols(15)),
    protocolDate: yup.string().typeError(requiredError).required(requiredError),
    consumerId: yup.string().required(requiredError).nullable(),
    researchBasis: yup.string().required(requiredError).nullable().max(511, maxSymbols(511)),
    researchObject: yup.string().required(requiredError).nullable().max(255, maxSymbols(255)),
    supportingDocuments: yup.string().required(requiredError).nullable().max(255, maxSymbols(255)),
    sampleNumber: yup.string().required(requiredError).nullable().max(15, maxSymbols(15)),
    waterObjectId: yup.string().required(requiredError).nullable(),
    sourceId: yup.string().required(requiredError).nullable(),
    samplePlace: yup.string().required(requiredError).nullable().max(255, maxSymbols(255)),
    sampleDate: yup.string().typeError(requiredError).required(requiredError),
    organizationId: yup.string().required(requiredError).nullable(),
    explanationFromConsumer: yup.string().nullable().max(511, maxSymbols(511)),
    sampleReceiveDate: yup.string().typeError(requiredError).required(requiredError),
    researchDateStart: yup.string().typeError(requiredError).required(requiredError),
    researchDateEnd: yup.string().typeError(requiredError).required(requiredError),
    sampleLaboratoryNumber: yup.string().required(requiredError).nullable().max(15, maxSymbols(15)),
    status: yup.string().required(requiredError).nullable(),
    explanation: yup.string(),
    sign: yup
      .object()
      .shape({
        signers: yup.array().of(
          yup.object().shape({
            action: yup.string().required(requiredError).nullable(),
            position: yup.string().required(requiredError).nullable().max(511, maxSymbols(511)),
            name: yup.string().required(requiredError).nullable().max(255, maxSymbols(255)),
          })
        ),
      })
      .nullable(),
  })
  .test((values) => {
    if (
      !BottomSoilParametersKeys.map((key) => values[key]).some(
        (value) => !!value || (typeof value === 'number' && value >= 0)
      )
    ) {
      return new ValidationError(BottomSoilParametersKeys.map((key) => new ValidationError(atLeastOneError, '', key)));
    }

    return true;
  });
