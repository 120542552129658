import { authAppPaths } from './appPaths';
import { IChangePasswordValues, ILogin, IResetPassword } from './types';

export const initialValues: ILogin = {
  login: '',
};

export const initialPasswordResetValues: IResetPassword = {
  email: '',
  baseUrl: `${window.location.protocol}//${window.location.host}${authAppPaths.changePasswordByEmail}`
};

export const initialChangePasswordValues: IChangePasswordValues = {
  oldPassword: '',
  password: '',
  repeatPassword: ''
};
