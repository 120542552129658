import { Box, Flex, FormLabel, Grid, Heading, HStack, Radio, RadioGroup } from '@chakra-ui/react';
import { Button, Checkbox, Input, Select } from 'components/common';
import React, { useEffect } from 'react';
import { Field, FormikProvider, useFormik } from 'formik';
import { createTouchedErrors } from 'features/create-touched-errors';
import { IGraphSettings } from 'pages/analyticsReports/CombinedCharts/types';
import { DatasetAxisIndex } from 'pages/analyticsReports/CombinedCharts/graphUtils';

interface IProps {
  onClose: () => void;
  setGraphSettings: (settings: IGraphSettings) => void;
  graphSettings: IGraphSettings;
  datasetAxisIndex?: DatasetAxisIndex;
}

export enum GraphLineOptions {
  simple = 'simple',
  dashed = 'dashed',
  filled = 'filled',
}

const lineOptions = [
  {
    name: 'Непрерывная',
    value: GraphLineOptions.simple,
  },
  {
    name: 'Прерывистая',
    value: GraphLineOptions.dashed,
  },
  {
    name: 'Заполненная',
    value: GraphLineOptions.filled,
  },
];

const GraphSettingsForm = ({ onClose, setGraphSettings, graphSettings, datasetAxisIndex }: IProps) => {
  const formik = useFormik<IGraphSettings>({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    initialValues: graphSettings,
    onSubmit: (values) => {
      setGraphSettings({ ...values });
      onClose();
    },
  });

  const { setFieldValue, resetForm } = formik;

  useEffect(() => {
    if (graphSettings) {
      resetForm({ values: graphSettings });
    }
  }, [graphSettings, resetForm, setFieldValue]);

  return (
    <FormikProvider
      value={{
        ...formik,
        errors: createTouchedErrors(formik.errors, formik.touched),
      }}
    >
      <Flex flexDirection="column">
        <Box mb={4}>
          <Heading mb={2} size="sm">
            Общие настройки
          </Heading>

          <Field as={Input} name="title" label="Название графика" />
        </Box>

        {graphSettings.graphs.map((graph, index) => (
          <Box key={index} mb={4}>
            <Heading mb={2} size="sm">
              График
              {' '}
              {index}
            </Heading>

            <Grid mb={2} gap="16px" templateColumns="repeat(3, 1fr)">
              <Flex flex={1} alignItems="center">
                <FormLabel>Цвет</FormLabel>

                <input
                  type="color"
                  name={`graphs[${index}].color`}
                  value={formik.values.graphs[index].color}
                  onChange={(event) => formik.setFieldValue(event.target.name, event.target.value)}
                />
              </Flex>

              {(graphSettings.graphs[index].type !== 'bar' && graphSettings.graphs[index].type !== 'scatter') && (
                <Field as={Select} name={`graphs[${index}].form`} label="Форма линии" options={lineOptions} />
              )}

              {(graphSettings.graphs[index].type !== 'bar' && graphSettings.graphs[index].type !== 'scatter') && (
                <Field
                  as={Input}
                  type="number"
                  name={`graphs[${index}].borderWidth`}
                  label="Толщина линии"
                />
              )}

            </Grid>

            <Field
              mb={2}
              as={RadioGroup}
              name={`graphs[${index}].position`}
              onChange={(value: string) => {
                if (datasetAxisIndex) {
                  const axisKeys = Object.keys(datasetAxisIndex).filter(
                    (key: any) => datasetAxisIndex[key] === datasetAxisIndex[index]
                  );
                  axisKeys?.forEach((axisKey) => {
                    formik.setFieldValue(`graphs[${Object.keys(datasetAxisIndex).indexOf(axisKey)}].position`, value);
                  });
                } else {
                  formik.setFieldValue(`graphs[${index}].position`, value);
                }
              }}
            >
              <HStack justifyContent="flex-start" alignItems="center">
                <Heading mr={2} size="xs">
                  Шкала
                </Heading>
                <Radio backgroundColor="#fff" value="left">
                  Слева
                </Radio>
                <Radio backgroundColor="#fff" value="right">
                  Справа
                </Radio>
              </HStack>
            </Field>

            <Field as={Input} name={`graphs[${index}].text`} label="Особая подпись шкалы" />

            {graph.maxPdkOption !== undefined && (
              <Field
                as={Checkbox}
                name={`graphs[${index}].maxPdkOption`}
                label="Показывать ПДК максимальный разовый"
                checked={formik.values.graphs[index].maxPdkOption}
              />
            )}
          </Box>
        ))}

        <Flex justifyContent="space-between">
          <Button onClick={onClose} variant="solid">
            Отменить
          </Button>

          <Button onClick={formik.submitForm} variant="solid">
            Применить
          </Button>
        </Flex>
      </Flex>
    </FormikProvider>
  );
};

export default GraphSettingsForm;
