import { CompareIcon, DeleteIcon, EditIcon, EyeViewIcon, InfoIcon, ReloadIcon } from 'components/icons';
import { map } from 'lodash';
import { Action } from '@material-table/core';

type PropActionTypes = 'edit' | 'delete' | 'info' | 'reload' | 'block' | 'download' | 'view' | 'bind' | 'unbind';

interface PropAction<Data extends any[]> extends Partial<Action<Data[number]>> {
  type: PropActionTypes;
}

type BaseAction<Data extends any[]> = Omit<PropAction<Data>, 'type'>;

type PropActions<Data extends any[]> =
  Array<PropAction<Data> | { action: (rowData: Data[number]) => Action<Data>; position: string }>;

interface CBaseActionConstructor {
  new<Data extends any[]>(action: BaseAction<Data>): Action<Data[number]>;
}

const CBaseAction: CBaseActionConstructor = class <Data extends any[]>
implements Omit<Action<Data[number]>, 'onClick' | 'hidden'> {
  icon: Action<Data[number]>['icon'] = '';

  tooltip: Action<Data[number]>['tooltip'] = undefined;

  position: Action<Data[number]>['position'] = 'row';

  hidden = false;

  isFreeAction: Action<Data[number]>['isFreeAction'] = undefined;

  iconProps: Action<Data[number]>['iconProps'] = undefined;

  disabled: Action<Data[number]>['disabled'] = false;

  constructor({ icon, tooltip, onClick, position, isFreeAction, iconProps, disabled, hidden }: BaseAction<Data>) {
    if (typeof icon === 'function') {
      this.icon = icon;
    }
    if (typeof tooltip === 'string') {
      this.tooltip = tooltip;
    }
    if (typeof onClick === 'function') {
      this.hidden = false;
      this.onClick = onClick;
    } else {
      this.hidden = true;
    }
    if (typeof position === 'string') {
      this.position = position;
    }

    if (typeof isFreeAction === 'boolean') {
      this.isFreeAction = isFreeAction;
    }
    if (iconProps) {
      this.iconProps = iconProps;
    }
    if (typeof disabled === 'boolean') {
      this.disabled = disabled;
    }
    if (typeof hidden === 'boolean') {
      this.hidden = hidden;
    }
  }

  onClick: Action<Data[number]>['onClick'] = () => void 0;
};

const EditAction: CBaseActionConstructor = class <Data extends any[]> extends CBaseAction<Data> {
  tooltip = 'Редактировать';

  constructor({ ...action }) {
    super(action);
  }

  icon = () => <EditIcon />;
};

const DeleteAction: CBaseActionConstructor = class <Data extends any[]> extends CBaseAction<Data> {
  tooltip = 'Удалить';

  constructor({ ...action }: BaseAction<Data>) {
    super(action);
  }

  icon = () => <DeleteIcon />;
};
const InfoAction: CBaseActionConstructor = class <Data extends any[]> extends CBaseAction<Data> {
  tooltip = 'Подробнее';

  constructor({ ...action }: BaseAction<Data>) {
    super(action);
  }

  icon = () => <InfoIcon />;
};
const ReloadAction: CBaseActionConstructor = class <Data extends any[]> extends CBaseAction<Data> {
  tooltip = 'Перезагрузка';

  constructor({ ...action }: BaseAction<Data>) {
    super(action);
  }

  icon = () => <ReloadIcon />;
};

const BindAction: CBaseActionConstructor = class <Data extends any[]> extends CBaseAction<Data> {
  tooltip = 'Привязать';

  constructor({ ...action }: BaseAction<Data>) {
    super(action);
  }

  icon = () => <CompareIcon />;
};

const UnBindAction: CBaseActionConstructor = class <Data extends any[]> extends CBaseAction<Data> {
  tooltip = 'Отвязать';

  constructor({ ...action }: BaseAction<Data>) {
    super(action);
  }

  icon = () => <DeleteIcon />;
};

const DownloadAction: CBaseActionConstructor = class <Data extends any[]> extends CBaseAction<Data> {
  tooltip = 'Скачать';

  constructor({ ...action }) {
    super(action);
  }

  icon = () => <EyeViewIcon />;
};

const ViewAction: CBaseActionConstructor = class <Data extends any[]> extends CBaseAction<Data> {
  tooltip = 'Открыть';

  constructor({ ...action }) {
    super(action);
  }

  icon = () => <EyeViewIcon />;
};

class SwitchAction<Data extends any[]> {
  action!: Action<Data[number]>;

  constructor(action: PropAction<Data> | undefined) {
    if (action) {
      const { type, ...rest } = action;

      if (type === 'edit') {
        this.action = new EditAction<Data>(rest);
      }
      if (type === 'delete') {
        this.action = new DeleteAction<Data>(rest);
      }
      if (type === 'info') {
        this.action = new InfoAction<Data>(rest);
      }
      if (type === 'reload') {
        this.action = new ReloadAction<Data>(rest);
      }
      if (type === 'bind') {
        this.action = new BindAction<Data>(rest);
      }
      if (type === 'unbind') {
        this.action = new UnBindAction<Data>(rest);
      }
      if (type === 'download') {
        this.action = new DownloadAction<Data>(rest);
      }
      if (type === 'view') {
        this.action = new ViewAction<Data>(rest);
      }
    }
  }
}

const createTableActions = <Data extends any[]>(actions: PropActions<Data> = []) => map(actions, (act) => {
  if ('action' in act) {
    return act;
  }
  const { action } = new SwitchAction(act);

  return action;
});

export type { PropActionTypes, PropAction, PropActions };

export { createTableActions };
