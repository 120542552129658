import { Flex, Text } from '@chakra-ui/react';
import { Reports } from 'pages/home/icons';
import { Button } from 'components/common/Button';
import downloadFile from 'lib/utils/files/downloadFile';
import React from 'react';

interface Props {
  file: File;
}

const FilePreviewMiscDoc = ({ file }: Props) => (
  <Flex
    justifyContent="center"
    alignItems="center"
    flexDirection="column"
    height="100%"
  >
    <Reports />
    <Text>{file.name}</Text>
    <Button
      variant="link"
      colorScheme="PrimaryButton"
      onClick={() => downloadFile(file)}
      p={2}
    >
      Скачать
    </Button>
  </Flex>
);
export default FilePreviewMiscDoc;
