import { useRequest } from 'hooks/useRequest';
import { GreenPlantsApiRootPath } from 'pages/routes/GreenPlants';
import { useEffect } from 'react';
import { TBaseGreenPlantsFormProperties } from './types';

type TPlant = {
  areaId: string | null;
  breed: string | null;
  date: string | null;
  id: string | null;
  number: number | null;
  type: string | null;
};

function usePlantsByAreaId(id: TBaseGreenPlantsFormProperties<string>['areaId']) {
  const { get, isLoading, result, error, reset } = useRequest<TPlant[]>(
    `${GreenPlantsApiRootPath}/flora/areas/${id}/plants`
  );
  useEffect(() => {
    if (id && id !== '') {
      get();
    }
  }, [get, id]);

  return { isLoading, result, error, reset };
}

function usePlantsByAreaIdsArray(ids: string[]) {
  const { post, isLoading, result, error, reset } = useRequest<TPlant[]>('/greenplants/v10/greenplants/flora/areas/plants');
  useEffect(() => {
    if (Array.isArray(ids) && ids.length) {
      post({ ids });
    }
  }, [post, ids]);

  return { isLoading, result, error, reset };
}

export type { TPlant };

export { usePlantsByAreaId, usePlantsByAreaIdsArray };
