import * as yup from 'yup';
import { requiredError } from '../constans';
import { numberWithSign } from 'models/schemas/numbers';

export const PGRSoilSchema = yup.object().shape({
  selectionDate: yup.string().typeError(requiredError).required(requiredError),
  protocol: yup.string().required(requiredError),
  sourceNameId: yup.string().typeError(requiredError).required(requiredError),
  distance2RoadId: yup.string().typeError(requiredError).required(requiredError),
  okrugId: yup.string().typeError(requiredError).required(requiredError),
  rayonId: yup.string().typeError(requiredError).required(requiredError),
  depthId: yup.string().typeError(requiredError).required(requiredError),
  values: yup.array().of(
    yup.object().shape({
      value: numberWithSign.required(requiredError),
    })
  ),
});
