import { useRequest, UseRequestReturnType } from 'hooks/useRequest';
import { useCallback, useEffect } from 'react';
import { DeviceModelParameterTableItem } from 'pages/equipment/Models/types';

function usePostRequest<T>(url: string) {
  const { post, isLoading, result, error } = useRequest<T>(url);
  useEffect(() => {
    post();
  }, [post]);
  const refetch = useCallback(() => {
    post();
  }, [post]);

  return {
    isLoading,
    refetch,
    result,
    error,
  };
}

interface UseParametersResult {
  data: DeviceModelParameterTableItem[];
  isLoading: boolean;
  error: UseRequestReturnType['error'];
  refetch: () => void;
}

export function useParameters(modelId: string): UseParametersResult {
  const { result, isLoading, refetch, error } = usePostRequest<DeviceModelParameterTableItem[]>(
    `/equipment/v10/equipments/parameters/list/${modelId}`,
  );

  return {
    data: result ?? [],
    isLoading,
    error,
    refetch,
  };
}
