import { ChartData } from 'chart.js';

export const getDatasetsLength = (datasets: { datasets: ChartData['datasets'] }) => {
  let length = 0;

  datasets.datasets.forEach((item) => {
    const newValue = length + item.data.length;
    length = newValue;
  });

  return length;
};
