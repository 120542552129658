import { IFilterValues } from 'components/common';
import { Modal } from 'components/common/Modals';
import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import { useAvailability, useDisclosure } from 'hooks';
import { TFiltersArray } from 'models';
import React, { useState } from 'react';
import { useList } from 'hooks/useList';
import appLocale from 'constants/appLocale';
import { SoilHeader } from '../components';
import { SoilFilter } from './SoilFilter';
import { SoilTable } from './Table';
import { SoilListItem } from './const/types';
import { UploadFileModalContent } from 'features/UploadFileDragAndDrop/UploadFileModalContent';
import subsystemIdsMap from 'constants/subsystemIdsMap';
import { usePeriodsDict } from 'features/PeriodsButtons/usePeriodsDict';
import { useDictionary } from 'hooks/useDictionary';
import { FullPageWrapper } from '../../../../components/table/FullPageWrapper';

export const Soil: React.FC = () => {
  const { isWrite, isExecute } = useAvailability(subsystemIdsMap.soilpollution);

  useUpdateBreadcrumbs([
    { breadcrumb: appLocale.common.data, path: '/data' },
    {
      breadcrumb: appLocale.subsystems.soil,
      path: '/data/soil',
    },
  ]);

  const { isOpen: isOpenLoad, onClose: onCloseLoad, onOpen: onOpenLoad } = useDisclosure();

  const {
    refetch,
    isLoading: isLoadingSoilList,
    response,
    toggleOrder,
    setFilters,
    onPageChange,
    filterDTO,
    downloadAsFile,
    saveFilters,
    onSearchText,
    errorText,
    selectedIndexes,
    renderOnlySelectedCheckbox,
    onSubmitFilters,
    onSelectionChange,
    materialTableProps,
  } = useList<SoilListItem, keyof SoilListItem>('/soilpollution/v10/soilpollution/list', {
    initialOrderFieldKey: '-protocolDate',
    preventFirstFetch: true,
    exportSubsystemName: 'soil',
    selectionKey: 'id',
    saveFiltersState: true,
    defaultPageSize: 25,
    resetDataOnNextRequest: true,
  });

  const {
    dictionaries,
    isDictionariesLoading
  } = useDictionary({ url: '/soilpollution/v10/soilpollution/dictionaries' });

  const [filtersFromGraphic, setFiltersFromGraphic] = useState<Partial<IFilterValues> | null>(null);
  const { result: periods } = usePeriodsDict(`auth/v10/user/period/${subsystemIdsMap.soilpollution}`);

  const isLoading = isDictionariesLoading || isLoadingSoilList;

  return (
    <FullPageWrapper>
      <SoilHeader
        isWrite={isWrite}
        onOpenLoad={onOpenLoad}
        url="/add"
      >
        <SoilFilter
          periods={periods}
          filtersFromGraphic={filtersFromGraphic}
          setFiltersFromGraphic={setFiltersFromGraphic}
          isLoading={isLoading}
          isDisabled={isLoading}
          dictionaries={dictionaries}
          onSubmit={(values) => {
            const filters: TFiltersArray<keyof SoilListItem> = Object.values(values);
            onSubmitFilters(filters);
          }}
          filters={filterDTO.filters}
        >
          {renderOnlySelectedCheckbox}
        </SoilFilter>
      </SoilHeader>

      <SoilTable
        isExecute={isExecute}
        isWrite={isWrite}
        soilList={response}
        refetchCB={refetch}
        toggleOrder={toggleOrder}
        filterDTO={filterDTO}
        filters={filterDTO.filters}
        setFilters={setFilters}
        onPageChange={onPageChange}
        downloadAsFile={downloadAsFile}
        selectedIndexes={selectedIndexes}
        onSelectionChange={onSelectionChange}
        saveFilters={saveFilters}
        onSearch={onSearchText}
        dictionaries={dictionaries}
        isLoading={isLoading}
        errorText={errorText}
        materialTableProps={materialTableProps}
      />

      <Modal isOpen={isOpenLoad} onClose={onCloseLoad} header="Загрузка данных из файла">
        <UploadFileModalContent
          createTypesPath={() => '/soilpollution/v10/soilpollution/load/types'}
          createUploadPath={() => '/soilpollution/v10/soilpollution/load'}
          types={[
            {
              name: 'Данные о загрязнениях почв',
              value: 'soilpollution',
            },
          ]}
          onClose={onCloseLoad}
          refetch={refetch}
        />
      </Modal>
    </FullPageWrapper>
  );
};
