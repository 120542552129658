import { Circle, Icon, IconProps } from '@chakra-ui/react';
import { ArrowDownIcon, CrossIcon } from 'components/icons';

interface SelectClearIconProps extends IconProps {
  clearColor: string;
}

const SelectClearIcon: React.FC<SelectClearIconProps> = ({ clearColor, ...props }) => (
  <Icon
    cursor="pointer"
    as={CrossIcon}
    __css={{
      width: '13px',
      height: '13px',
      path: { stroke: clearColor },
    }}
    {...props}
  />
);

export const DeleteSelectedItem = ({ onDelete }: any) => (
  <Circle onClick={onDelete} ml={2} size={6}>
    <Icon
      minW={3}
      maxW={3}
      minH={3}
      maxH={3}
      as={CrossIcon}
      css={{
        path: { stroke: '#000' },
      }}
    />
  </Circle>
);

interface SelectExpandIconProps extends IconProps {
  arrowColor: string;
}

const SelectExpandIcon: React.FC<SelectExpandIconProps & IconProps> = ({ arrowColor, ...props }) => (
  <Icon as={ArrowDownIcon} __css={{ width: '16px', height: '16px', path: { fill: arrowColor } }} {...props} />
);

export { SelectClearIcon, SelectExpandIcon };
