import { Radar } from 'react-chartjs-2';
import { IGraphResponse } from 'pages/analyticsReports/CombinedCharts/types';
import { useMemo } from 'react';
import { Box, Flex } from '@chakra-ui/react';

interface IProps {
  response?: IGraphResponse;
}

export const ASKZAMeteoDiagram = ({ response }: IProps) => {
  const directions = useMemo(
    () => ['С', 'ССВ', 'СВ', 'ВСВ', 'В', 'ВЮВ', 'ЮВ', 'ЮЮВ', 'Ю', 'ЮЮЗ', 'ЮЗ', 'ЗЮЗ', 'З', 'ЗСЗ', 'СЗ', 'ССЗ'],
    []
  );

  const av = {
    labels: directions,
    datasets: [
      {
        label: response?.axisTitles[0],
        data: response?.datasets[0],
        fill: false,
        borderColor: 'rgb(255, 99, 132)',
        pointBackgroundColor: 'rgb(255, 99, 132)',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgb(255, 99, 132)',
      },
    ],
  };

  const max = {
    labels: directions,
    datasets: [
      {
        label: response?.axisTitles[1],
        data: response?.datasets[1],
        fill: false,
        borderColor: 'rgb(255, 99, 132)',
        pointBackgroundColor: 'rgb(255, 99, 132)',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgb(255, 99, 132)',
      },
    ],
  };

  return (
    <Flex width="100%" height="100%">
      <Box width="50%">
        <Radar
          data={av}
          options={{
            spanGaps: true,
            animation: false,
            responsive: true,
            maintainAspectRatio: false,
          }}
          height="200px"
          width="200px"
        />
      </Box>

      <Box width="50%">
        <Radar
          data={max}
          options={{
            spanGaps: true,
            animation: false,
            responsive: true,
            maintainAspectRatio: false,
          }}
          height="200px"
          width="200px"
        />
      </Box>
    </Flex>
  );
};
