import { Field, FormikProvider, useFormik } from 'formik';
import React, { useEffect, useMemo } from 'react';
import { Input, LoaderItem } from 'components/common';
import { Flex, Grid, GridItem } from '@chakra-ui/react';
import { useList, useNotifications, useRequest } from 'hooks';
import { MaterialTable } from 'features/material-table/MaterialTableMini';
import { createLocalDateDDMMYYYY } from 'lib/create-date';
import { CompareIconBlue } from 'components/icons';
import { IReportsData } from '../types';
import { TFiltersArray } from 'models';
import { getSelectRichOptionsFromDictionaryByName } from 'features/get-select-options-from-dictionary';
import FilterFormControls from 'components/form/FilterFormControls';
import { useDictionary } from 'hooks/useDictionary';
import { TableFilterTypeEnum } from 'components/table/filters/TableFilter';
import { Column } from '@material-table/core';
import { addFiltersToColumns } from 'lib/utils/tableFilter';

interface IProps {
  customClose: () => void;
  type: string;
  pointId: string;
  field: string;
  protocolIdAdder: (field: string, id: string | null) => void;
}

export const ReportSetProtocolModal = ({ customClose, type, pointId, field, protocolIdAdder }: IProps) => {
  const { response, filterDTO, toggleOrder, onPageChange, isLoading, setFilters, refetch, errorText } = useList<
    IReportsData[]
  >('appeal/v10/protocols/list', { preventAutoFetchData: true });

  const { dictionaries, isDictionariesLoading } = useDictionary({ url: '/reporting/v10/laboratory/dictionaries' });

  const subsystems = useMemo(
    () => getSelectRichOptionsFromDictionaryByName(dictionaries?.data, 'subsystems'),
    [dictionaries],
  );

  const typeId = useMemo(() => subsystems?.find((subsystem) => subsystem.module === type)?.value, [subsystems, type]);

  const { values, handleReset, submitForm, ...formik } = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    initialValues: {
      address: {
        key: 'address',
        operation: 'CONTAINS',
        value: '',
      },
    },
    onSubmit: (values) => {
      const filters: TFiltersArray = [
        ...Object.values(values),
        {
          key: 'subsystemId',
          operation: 'EQ',
          value: typeId,
        },
        {
          key: 'deleted',
          operation: 'EQ',
          value: false,
        },
      ] as TFiltersArray;
      setFilters(filters);
    },
    onReset: () => {
      setFilters([
        {
          key: 'subsystemId',
          operation: 'EQ',
          value: typeId,
        },
        {
          key: 'deleted',
          operation: 'EQ',
          value: false,
        },
      ]);
    },
  });

  useEffect(() => {
    if (typeId) {
      setFilters([
        {
          key: 'subsystemId',
          operation: 'EQ',
          value: typeId,
        },
        {
          key: 'deleted',
          operation: 'EQ',
          value: false,
        },
      ]);
    }
  }, [typeId, setFilters]);

  useEffect(() => {
    if (filterDTO.filters.find((item) => item.value === typeId)) {
      refetch();
    }
  }, [filterDTO, refetch, typeId]);

  const { successNotify, errorNotify } = useNotifications();

  const successBoundProtocol = () => {
    successNotify({
      key: 'token/update/success',
      message: 'Протокол успешно привязан',
    });
    customClose();
  };

  const errorBoundProtocol = () => {
    errorNotify({
      key: 'token/update/success',
      message: 'Ошибка привязки протокола',
    });
  };

  const { put } = useRequest('', successBoundProtocol, errorBoundProtocol);

  const columns = useMemo<Array<Column<IReportsData>>>(
    () => [
      {
        title: 'Дата протокола',
        field: 'protocolDate',
        render: (row) => createLocalDateDDMMYYYY(row.protocolDate),
      },
      {
        title: 'Номер протокола',
        field: 'protocolNumber',
        filterType: TableFilterTypeEnum.string,
      },
      {
        title: 'Адрес',
        field: 'address',
      },
    ],
    [],
  );

  const columnsWithFilters = useMemo<Array<Column<IReportsData>>>(
    () =>
      addFiltersToColumns({
        filters: filterDTO.filters,
        setFilters,
        columns,
      }),
    [filterDTO.filters, setFilters, columns],
  );

  return (
    <FormikProvider
      value={{
        values,
        handleReset,
        submitForm,
        ...formik,
      }}
    >
      {isDictionariesLoading || isLoading ? (
        <LoaderItem />
      ) : (
        <Flex direction="column">
          <Grid marginBottom={5} templateColumns="repeat(6, 1fr)" gap={5}>
            <GridItem colSpan={5}>
              <Field as={Input} value={values.address.value} name="address.value" label="Наименование территории" />
            </GridItem>

            <FilterFormControls onReset={(e) => handleReset(e)} onSubmit={submitForm} />
          </Grid>

          <MaterialTable
            data={response?.data}
            filterDTO={filterDTO}
            meta={response?.meta}
            toggleOrder={toggleOrder}
            onPageChange={onPageChange}
            isLoading={isLoading}
            columns={columnsWithFilters}
            options={{
              selection: false,
            }}
            actions={[
              {
                position: 'row',
                action: (row) => ({
                  icon: () => <CompareIconBlue />,
                  onClick: () => {
                    protocolIdAdder(field, row.id);
                    put({}, { url: `appeal/v10/points/${pointId}/relation/${row.id}` });
                  },
                  tooltip: 'Привязать протокол',
                }),
              },
            ]}
            errorText={errorText}
          />
        </Flex>
      )}
    </FormikProvider>
  );
};
