import { getInitialFormTabInitialEmptyValues } from 'components/common/LocationFormTab/locationFormTabInitialEmptyValues';
import { NSIWaterItem } from 'pages/dictionaries/NSIWater/types';
import { locationFieldKeys } from 'pages/dictionaries/NSIWater/NSIWaterSites/NSIWaterSitesForms/locationFieldKeys';

const initialNSIWaterSitesEmptyValues: Partial<NSIWaterItem> = {
  subsystemId: null,
  oopt: null,
  waterId: null,
  name: '',
  code: '',
  areaSquare: '',
  status: null,
  startDate: null,
  stopDate: null,

  ...getInitialFormTabInitialEmptyValues(locationFieldKeys),
};

export default initialNSIWaterSitesEmptyValues;
