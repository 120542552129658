import { NSIApiRouteRoot, NSIRootPath } from '../../const/routes';
import { ConfirmModal } from 'components/common';
import React, { useEffect, useMemo } from 'react';
import { IDictionaryCreateOrUpdateData, IPrepareResponce } from '../../const/types';
import { useDisclosure, useNotifications, useRequest } from 'hooks';
import { useHistory, useParams } from 'react-router-dom';
import { ERROR_MESSAGES, SUCCESS_MESSAGES } from 'constants/messages';
import { AxiosRequestConfig } from 'axios';
import { useFormikContext } from 'formik';
import { FormValues } from './Form';
import { prepareDictionaryItemForCreateOrUpdate } from './formHelpers';

interface IProps {
  prepareResponse: IPrepareResponce | null;
  queryFunc: (body?: IDictionaryCreateOrUpdateData | null, config?: AxiosRequestConfig, sendToMail?: boolean) => void;
}

export const DictionaryModalWrapper = ({ prepareResponse, queryFunc }: IProps) => {
  const { dictionaryId } = useParams<{ dictionaryId: string }>();

  const { values } = useFormikContext<FormValues>();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const { successNotify, errorNotify } = useNotifications();
  const history = useHistory();

  const onSuccess = () => {
    successNotify({
      key: 'record/restore/success',
      message: SUCCESS_MESSAGES.RESTORE,
    });
    history.push(`${NSIRootPath}/${dictionaryId}`);
  };

  const onError = () => {
    errorNotify({
      key: 'record/restore}/error',
      message: ERROR_MESSAGES.RESTORE,
    });
    onClose();
  };
  const { get: restore } = useRequest('', onSuccess, onError);

  const modalText = useMemo(() => {
    let text = '';
    if (prepareResponse) {
      if (prepareResponse.foundByName) {
        text = `Запись с именем: ${prepareResponse.foundByName.name}, уже существует`;
        if (prepareResponse.foundByName.deleted) {
          return `${text}. Восстановить запись?`;
        }
        return text;
      }
      if (prepareResponse.foundByAliases.length) {
        return `Алиасы с именами: ${prepareResponse.foundByAliases
          .map((item) => item.alias)
          .join(', ')}, уже существуют, при подтверждении алиасы будут перезаписаны`;
      }
    }
    return text;
  }, [prepareResponse]);

  useEffect(() => {
    if (prepareResponse) {
      if (prepareResponse.foundByName || prepareResponse.foundByAliases.length) {
        onOpen();
      } else {
        queryFunc(prepareDictionaryItemForCreateOrUpdate(dictionaryId, values));
      }
    }
    // eslint-disable-next-line
  }, [prepareResponse]);

  return (
    <ConfirmModal
      isOpen={isOpen}
      onClose={onClose}
      header={modalText}
      onClick={() =>
        (prepareResponse?.foundByName?.deleted
          ? restore({ url: `${NSIApiRouteRoot}/${dictionaryId}/item/${prepareResponse?.foundByName?.item.id}/restore` })
          : queryFunc(prepareDictionaryItemForCreateOrUpdate(dictionaryId, values)))
      }
      isDisabled={!prepareResponse?.foundByName?.deleted && !!prepareResponse?.foundByName}
    />
  );
};
