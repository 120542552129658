import { useRequest } from 'hooks/useRequest';
import useFilterDTO, { changeOrder, removeEmptyValuesFromFilter } from 'hooks/useFilterDTO';
import { useCallback, useEffect, useRef, useState } from 'react';
import { ApiError, TFilterDTO, TFiltersArray } from 'models';
import { ListResponse } from 'models/api/list';
import { downloadFileFromResponse } from 'lib/utils/files/downloadFile';
import { DEFAULT_DOWNLOAD_ROWS_LIMIT } from 'constants/tables';
import { ExtensionToMimeTypeEnum } from 'lib/utils/files/getFileExtensionByMimeType';
import { ERROR_MESSAGES } from 'constants/messages';
import { useNotifications } from 'hooks/useNotifications';
import { IncomingHttpHeaders } from 'http';
import { formatErrorMessage } from '../lib/utils/formatErrorMessage';
import useTableSelection, { IUseTableSelection } from 'hooks/table/useTableSelection';
import { useHistory } from 'react-router-dom';
import { useQuery } from 'hooks/useQuery';
import qs from 'querystring';
import { useUxMetadata } from './useUxMetadata';
import { AxiosRequestConfig } from 'axios';

export interface IWithFileDownload {
  types?: ExtensionToMimeTypeEnum[];
  downloadAsFile: (type: ExtensionToMimeTypeEnum) => void;
}

export interface IUseListMaterialTableProps {
  onColumnHide: (column: string, hidden: boolean) => void;
  hiddenColumns: string[];
}

export interface useListInterface<ListItemInterface, FiltersType = string>
  extends IUseTableSelection<FiltersType, ListItemInterface> {
  refetch: () => void;
  isLoading: boolean;
  response: ListResponse<ListItemInterface> | null;
  error: ApiError | { descriptions: { message: string }[] } | null;
  errorText: string | undefined;
  toggleOrder: (key: string) => void;
  setFilters: (filters: TFiltersArray<FiltersType>) => void;
  onPageChange: (page: number, pageSize: number) => void;
  filterDTO: TFilterDTO<FiltersType>;
  downloadAsFile: IWithFileDownload;
  saveFilters: () => void;
  onSearchText: (searchFields: string[], searchString: string) => void;
  resetData: () => void;
  getHeaders: (type: ExtensionToMimeTypeEnum) => Promise<any>;
  headers: IncomingHttpHeaders | null | undefined;
  resetHeaders: () => void;
  onSubmitFilters: (filters: TFiltersArray<FiltersType>, resetSelectedIds?: boolean) => TFiltersArray<FiltersType>;
  savedFiltersFromCache: boolean;
  materialTableProps: IUseListMaterialTableProps;
}

interface IUseListConfig<ListItemInterface, FiltersType> {
  initialOrderFieldKey?: string;
  preventAutoFetchData?: boolean;
  exportSubsystemName?: string;
  additionalRequestData?: any;
  defaultPageSize?: number;
  preventFirstFetch?: boolean;
  selectionKey?: Extract<FiltersType, keyof ListItemInterface>;
  downloadUrl?: string | Partial<Record<ExtensionToMimeTypeEnum, string>>;
  saveFiltersState?: boolean;
  fetchMethod?: 'GET' | 'POST';
  disableUxMetadata?: boolean;
  restoreSelectedFromUxMetadata?: boolean;
  resetDataOnNextRequest?: boolean;
  initialDeleted?: boolean | null;
}

export function useList<ListItemInterface, FiltersType = string>(
  url: string,
  config: IUseListConfig<ListItemInterface, FiltersType> = {}
): useListInterface<ListItemInterface, FiltersType> {
  const { errorNotify, successNotify } = useNotifications();
  const query = useQuery();
  const { replace, location } = useHistory();
  const { uxMetadata, getUxMetadata, saveUxMetadata, resetUxMetadata } = useUxMetadata(url, window.location.pathname);
  const {
    initialOrderFieldKey,
    preventAutoFetchData,
    exportSubsystemName,
    preventFirstFetch,
    selectionKey,
    downloadUrl,
    saveFiltersState,
    additionalRequestData,
    fetchMethod = 'POST',
    disableUxMetadata,
    restoreSelectedFromUxMetadata,
    resetDataOnNextRequest,
    initialDeleted = false,
  } = config;
  const [errorText, setErrorText] = useState<string>();
  const [response, setResponse] = useState<ListResponse<ListItemInterface> | null>(null);
  const fetchCount = useRef(0);
  const isLoadingUxMetadata = useRef(false);

  const onError = useCallback(
    (error) => {
      void resetUxMetadata();
      const msg = formatErrorMessage(error);
      errorNotify({
        key: `useList/error/${new Date()}`,
        message: msg || ERROR_MESSAGES.GET_DATA,
      });
    },
    [errorNotify, resetUxMetadata]
  );

  const onListError = useCallback(
    (error) => {
      setErrorText('Ошибка загрузки данных');
      setResponse(null);
      onError(error);
    },
    [onError]
  );

  const onListSuccess = useCallback((data: ListResponse<ListItemInterface> | null) => {
    setErrorText(undefined);
    setResponse(data);
  }, []);

  const {
    post,
    get,
    isLoading,
    error,
    reset: resetData,
  } = useRequest<ListResponse<ListItemInterface>>(url, onListSuccess, onListError);
  const { post: download, isLoading: isFileLoading } = useRequest<Blob>(url, undefined, onError);
  const { post: responseHeadersFromPost, headers, reset: resetHeaders } = useRequest<Headers>(url, undefined, onError);

  const {
    filterDTO,
    toggleOrder,
    setFilters,
    onPageChange,
    onSearchText,
    onColumnHide,
    setHiddenColumns,
    hiddenColumns,
    clear,
  } = useFilterDTO<FiltersType>(
    initialOrderFieldKey,
    uxMetadata,
    { defaultPageSize: config.defaultPageSize, initialDeleted }
  );

  const _setFilters = useCallback<(filters: TFilterDTO<FiltersType>['filters'], resetPagination?: boolean) => void>(
    (filters, resetPagination) => {
      if (!restoreSelectedFromUxMetadata) {
        void resetUxMetadata();
      }

      setFilters(filters, resetPagination);
    },
    [setFilters, resetUxMetadata, restoreSelectedFromUxMetadata]);

  const getList = useCallback<(filterDTO: TFilterDTO<FiltersType>) => void>(
    (filterDTO) => {
      const query = { ...(additionalRequestData || {}), ...filterDTO };

      if (saveFiltersState) {
        replace(`${location.pathname}?${qs.stringify({
          q: JSON.stringify(filterDTO),
          hiddenColumns: JSON.stringify(hiddenColumns)
        })}`);
      }

      if (resetDataOnNextRequest) {
        setResponse(null);
      }
      if (fetchMethod === 'GET') {
        void get({
          url: `${url}?${qs.stringify({ params: JSON.stringify(filterDTO) })}`,
        });
      } else {
        void post(query);
      }
    },
    [
      resetDataOnNextRequest,
      hiddenColumns,
      additionalRequestData,
      saveFiltersState,
      fetchMethod,
      replace,
      location.pathname,
      get,
      url,
      post
    ]);

  const applyFiltersAndFetch = useCallback(
    (filters: TFiltersArray<FiltersType>, resetPagination?: boolean): TFiltersArray<FiltersType> => {
      _setFilters(filters, resetPagination);
      if (preventAutoFetchData) {
        void getList({ ...filterDTO, filters: removeEmptyValuesFromFilter(filters) });
      }
      return filters;
    },
    [preventAutoFetchData, _setFilters, getList, filterDTO]
  );

  const tableSelection = useTableSelection<FiltersType, ListItemInterface>({
    setFilters: applyFiltersAndFetch,
    filters: filterDTO.filters,
    filterKey: selectionKey,
    dataIds: selectionKey ? response?.data.map((row) => String(row[selectionKey])) : [],
  });

  const saveFilters = useCallback(() => {
    void saveUxMetadata({ filterDTO, hiddenColumns, selectedIds: tableSelection.selectedIds });
  }, [filterDTO, hiddenColumns, saveUxMetadata, tableSelection.selectedIds]);

  useEffect(() => {
    if (saveFiltersState) {
      replace(`${location.pathname}?${qs.stringify({
        q: JSON.stringify(filterDTO),
        hiddenColumns: JSON.stringify(hiddenColumns)
      })}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hiddenColumns]);

  const loadFromQuery = useCallback(() => {
    if (!Array.isArray(query.q) && query.q) {
      try {
        const filterDTO: TFilterDTO<FiltersType> = JSON.parse(query.q);
        const _hiddenColumns: string[] = query.hiddenColumns ? JSON.parse(<string>query.hiddenColumns) : [];
        setHiddenColumns(_hiddenColumns);
        void clear(filterDTO);

        if (selectionKey) {
          const selected: any = filterDTO.filters.find((filter) => filter.key === selectionKey && filter.operation === 'IN');
          if (selected) {
            tableSelection.setSelectedIds(selected.value || []);
          }
        }

        if (preventAutoFetchData) {
          if (fetchMethod === 'GET') {
            void get({
              url: `${url}?${qs.stringify({ params: JSON.stringify(filterDTO) })}`,
            });
          } else {
            void post(filterDTO);
          }
        }
      } catch (e) {
        console.error(e);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clear, fetchMethod, get, post, preventAutoFetchData, query.q, query.hiddenColumns, url, setHiddenColumns]);

  const loadData = useCallback(async () => {
    if (isLoadingUxMetadata.current) return;
    const savedFilterStateCondition = saveFiltersState && query.q;

    // cached ux metadata and savedFilterStateCondition has priority over preventAutoFetchData
    if (fetchCount.current === 0) {
      if (!disableUxMetadata) {
        isLoadingUxMetadata.current = true;
        const cachedUxMetadata = await getUxMetadata();

        if (cachedUxMetadata) {
          isLoadingUxMetadata.current = false;
          fetchCount.current += 1;
          clear(cachedUxMetadata.filterDTO);
          setHiddenColumns(cachedUxMetadata.hiddenColumns);
          if (restoreSelectedFromUxMetadata) {
            tableSelection.setSelectedIds(cachedUxMetadata.selectedIds);
          }
          return;
        }
      }

      if (savedFilterStateCondition) {
        isLoadingUxMetadata.current = false;
        fetchCount.current += 1;
        loadFromQuery();
        return;
      }
      isLoadingUxMetadata.current = false;
    }

    if (preventAutoFetchData) {
      fetchCount.current += 1;
      return;
    }

    if (!preventFirstFetch) {
      void getList(filterDTO);
    } else if (fetchCount.current > 0) {
      void getList(filterDTO);
    }

    fetchCount.current += 1;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterDTO, getList, preventAutoFetchData, preventFirstFetch, saveFiltersState, url]);

  useEffect(() => {
    void loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterDTO, preventAutoFetchData, preventFirstFetch, saveFiltersState, url]);

  const refetch = useCallback(() => {
    void getList(filterDTO);
  }, [filterDTO, getList]);

  const getHeaders = useCallback(
    (type: ExtensionToMimeTypeEnum, headersXLSXUrl?: string) =>
      responseHeadersFromPost(
        {
          ...filterDTO,
          limit: DEFAULT_DOWNLOAD_ROWS_LIMIT,
          offset: 0,
        },
        { headers: { ACCEPT: type, Prefer: 'respond-async' }, responseType: 'blob', url: headersXLSXUrl || url }
      ),
    [responseHeadersFromPost, filterDTO, url]
  );

  const downloadAsFile = useCallback(
    async (type: ExtensionToMimeTypeEnum) => {
      const config: AxiosRequestConfig = { headers: { ACCEPT: type }, responseType: 'blob' };

      if (downloadUrl) {
        if (typeof downloadUrl === 'string') {
          config.url = downloadUrl;
        } else if (downloadUrl[type]) {
          config.url = downloadUrl[type];
        }
      }

      const response = await download(
        {
          ...filterDTO,
          limit: DEFAULT_DOWNLOAD_ROWS_LIMIT,
          offset: 0,
          hiddenColumns,
        },
        config
      );

      if (downloadUrl && typeof downloadUrl !== 'string' && downloadUrl[type]) {
        successNotify({
          message: 'Отчет формируется. По завершению будет отправлено уведомление в информаторе.',
          key: 'downloadSuccess'
        });
        return;
      }

      downloadFileFromResponse({
        response,
        name: exportSubsystemName ? `export_${exportSubsystemName}` : undefined,
        type,
        addDate: true,
      });
    },
    [download, exportSubsystemName, filterDTO, downloadUrl, successNotify, hiddenColumns]
  );

  const _onPageChange = useCallback(
    (page: number, pageSize: number) => {
      onPageChange(page, pageSize);
      if (preventAutoFetchData) {
        void getList({ ...filterDTO, limit: pageSize, offset: page * pageSize });
      }
    },
    [filterDTO, getList, onPageChange, preventAutoFetchData]
  );

  const _toggleOrder = useCallback(
    (key: string) => {
      toggleOrder(key);
      if (preventAutoFetchData) {
        void getList({ ...filterDTO, orders: changeOrder(filterDTO.orders, key) });
      }
    },
    [filterDTO, getList, preventAutoFetchData, toggleOrder]
  );

  const _onSearchText = useCallback(
    (searchFields: string[], searchString: string) => {
      onSearchText(searchFields, searchString);
      if (preventAutoFetchData) {
        void getList({ ...filterDTO, searchFields, searchString });
      }
    },
    [filterDTO, getList, onSearchText, preventAutoFetchData]
  );

  const onSubmitFilters = useCallback(
    (filters: TFiltersArray<FiltersType>, resetSelectedIds?: boolean): TFiltersArray<FiltersType> =>
      (tableSelection.resetSelection(filters, resetSelectedIds)),
    [tableSelection]
  );

  return {
    refetch,
    isLoading: isLoading || isFileLoading,
    response,
    error,
    errorText,
    toggleOrder: _toggleOrder,
    setFilters: _setFilters,
    onPageChange: _onPageChange,
    filterDTO,
    downloadAsFile: {
      downloadAsFile,
      types: Object.values(ExtensionToMimeTypeEnum) as ExtensionToMimeTypeEnum[],
    },
    saveFilters,
    onSearchText: _onSearchText,
    resetData,
    getHeaders,
    headers,
    resetHeaders,
    onSubmitFilters,
    savedFiltersFromCache: uxMetadata?.filterDTO,
    materialTableProps: {
      onColumnHide,
      hiddenColumns,
    },
    ...tableSelection,
  };
}
