import { FormWrapper, Input } from 'components/common';
import { Field, FieldArray, useFormikContext } from 'formik';
import { TDictionariesArray } from 'models/dictionaries';
import { Box, Grid, GridItem, Heading } from '@chakra-ui/react';
import { COMMON_LOCALE } from 'constants/common';
import React, { useEffect, useMemo } from 'react';
import { SoilDeicingItem } from '../../consts';
import { getSelectOptionsFromDictionaryByName } from 'features/get-select-options-from-dictionary';

interface ParametersTabProps {
  dictionaries: {
    data: TDictionariesArray;
  } | null;
  isWrite?: boolean;
}

export const ParametersTab: React.FC<ParametersTabProps> = ({ children, dictionaries, isWrite }) => {
  const { values, setFieldValue } = useFormikContext<SoilDeicingItem>();

  const parameters = useMemo(
    () => getSelectOptionsFromDictionaryByName(
      dictionaries?.data,
      'parameters',
      false,
      values.values?.map((param) => param.parameterId),
      'parameterId',
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getSelectOptionsFromDictionaryByName, dictionaries],
  );

  useEffect(() => {
    const valueParameterIds = [...values.values].map((val) => val.parameterId);

    // prefills missing parameters
    parameters.forEach((dictVal, index) => {
      // @ts-ignore
      if (!valueParameterIds.includes(dictVal.parameterId)) {
        setFieldValue(`values[${index}]`, {
          value: '',
          name: dictVal.name,
          parameterId: dictVal.parameterId,
          id: dictVal.parameterId,
        });
      }
    });
  }, [parameters, setFieldValue, values.values]);

  return (
    <Box flexDirection="column">
      <FormWrapper>
        <Box marginTop="30px" gap="15px" marginBottom={20}>
          <Heading mb={2} size="md">
            {COMMON_LOCALE.indicators}
          </Heading>
          <FieldArray name="values">
            {(props: any) => (
              <Grid gap={2} templateColumns="repeat(4, 1fr)">
                {values.values.map((el, index) => {
                  const { errors } = props.form;
                  const label = parameters.find((item) => item.parameterId === el.parameterId)?.title;
                  return (
                    <GridItem key={el.name} colSpan={index === 2 ? 4 : 2}>
                      <Field
                        as={Input}
                        error={errors && errors.values && errors.values[index] && errors.values[index].value}
                        name={`values[${index}].value`}
                        label={label}
                        isDisabled={!isWrite}
                      />
                    </GridItem>
                  );
                })}
              </Grid>
            )}
          </FieldArray>
          {children}
        </Box>
      </FormWrapper>
    </Box>
  );
};
