import { LoaderItem, SubsystemHeader } from 'components/common';
import React, { useEffect, useMemo } from 'react';
import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import appLocale from 'constants/appLocale';
import { ActivityLogFilter } from './ActivityLogFilter';
import { useList } from 'hooks';
import { IJournalItem } from './types';
import { baseAdminUrl } from '../consts';
import { Column } from '@material-table/core';
import { JournalItem } from '../models/JournalItem';
import { TableFilterTypeEnum } from 'components/table/filters/TableFilter';
import { addFiltersToColumns } from 'lib/utils/tableFilter';
import { MaterialTable } from 'features/material-table/MaterialTableMini';
import { createLocalDateDDMMYYYYWithTime } from 'lib/create-date';
import { initialValues } from './const';
import { FullPageWrapper } from '../../../components/table/FullPageWrapper';
import { DEFAULT_PAGE_SIZE_NEW_TABLE } from '../../../constants/tables';
import { DefaultTableCellSize } from '../../../components/table/DefaultTableCellSize';

interface IProps {
  isSentry?: boolean;
}

export const Journal = ({ isSentry = false }: IProps) => {
  useUpdateBreadcrumbs(
    isSentry
      ? [
        {
          breadcrumb: appLocale.admin.journal,
          path: '/sentry/journal',
        },
      ]
      : [
        { breadcrumb: appLocale.admin.main, path: '/admin' },
        {
          breadcrumb: appLocale.admin.journal,
          path: '/admin/journal',
        },
      ]
  );

  const { isLoading, response, setFilters, toggleOrder, onPageChange, filterDTO, onSearchText } = useList<IJournalItem>(
    `${baseAdminUrl}/journal/list`,
    {
      initialOrderFieldKey: '-eventDate',
      defaultPageSize: DEFAULT_PAGE_SIZE_NEW_TABLE,
      resetDataOnNextRequest: true,
      preventFirstFetch: true,
    }
  );

  useEffect(() => {
    setFilters(Object.values(initialValues));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = useMemo<Array<Column<JournalItem>>>(
    () => [
      {
        title: 'Дата/время действия',
        field: 'eventDate',
        render: (row) => createLocalDateDDMMYYYYWithTime(row.eventDate),
      },
      {
        title: 'Логин',
        field: 'username',
        filterType: TableFilterTypeEnum.string,
      },
      {
        title: 'Роль',
        field: 'roles',
        filterType: TableFilterTypeEnum.string,
      },
      {
        title: 'ФИО',
        field: 'objectName',
        filterType: TableFilterTypeEnum.string,
      },
      {
        title: 'Подсистема',
        field: 'subsystem',
        filterType: TableFilterTypeEnum.string,
      },
      {
        title: 'Действие',
        field: 'function',
        filterType: TableFilterTypeEnum.string,
      },
      {
        title: 'Описание',
        field: 'message',
        filterType: TableFilterTypeEnum.string,
        render: (row) => <DefaultTableCellSize maxWidth={500}>{row.message}</DefaultTableCellSize>
      },
    ],
    []
  );

  const columnsWithFilters = useMemo<Array<Column<JournalItem>>>(
    () => addFiltersToColumns({ filters: filterDTO.filters, setFilters, columns }),
    [filterDTO.filters, setFilters, columns]
  );

  return (
    <FullPageWrapper>
      <SubsystemHeader
        title={appLocale.admin.journal}
        filtersComponent={
          <ActivityLogFilter
            isLoading={isLoading}
            isDisabled={isLoading}
            onSubmit={(values) => {
              setFilters(Object.values(values));
            }}
          />
        }
      />
      {isLoading ? (
        <LoaderItem />
      ) : (
        <>
          <MaterialTable
            data={response?.data}
            meta={response?.meta}
            filterDTO={filterDTO}
            toggleOrder={toggleOrder}
            onPageChange={onPageChange}
            columns={columnsWithFilters}
            isLoading={isLoading}
            options={{
              search: true,
              showTitle: true,
              toolbar: true,
              selection: false,
            }}
            onSearch={onSearchText}
          />
        </>
      )}
    </FullPageWrapper>
  );
};
