import React, { FC, useMemo } from 'react';
import { DateInput, FormWrapper, Input, Select } from 'components/common';

import { getSelectOptionsFromDictionaryByName } from 'features/get-select-options-from-dictionary';
import { Field, useFormikContext } from 'formik';
import { createDateFromResponse, createDateToRequestWithTimezone } from 'lib/create-date';
import { TDictionariesArray } from 'models/dictionaries';
import { NSIReagentsListItem } from 'pages/dictionaries/NSIReagents/types';
import { Box, Grid } from '@chakra-ui/react';

interface IProps {
  dictionaries: {
    data: TDictionariesArray;
  } | null;
  isEditMode?: boolean;
}

const NSIReagentsLawnsGeneralInformationTab: FC<IProps> = ({ dictionaries, children, isEditMode }) => {
  const { setFieldValue, values, errors } = useFormikContext<NSIReagentsListItem>();

  const statuses = useMemo(() => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'statuses'), [dictionaries]);

  const areasForLawn = useMemo(
    () => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'areasForLawn'),
    [dictionaries],
  );

  return (
    <Box flexDirection="column">
      <FormWrapper>
        <Grid marginTop="30px" gap="15px">
          <Field
            as={Select}
            name="parentAreaId"
            label="Номер площадки"
            options={areasForLawn}
            error={errors.parentAreaId}
            isDisabled={isEditMode}
          />

          <Field as={Input} name="number" label="Номер газона" type="number" error={errors?.number} />

          <Field
            as={Select}
            name="status"
            label="Статус"
            value={values.status}
            options={statuses}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              setFieldValue('status', e.target.value);
            }}
            error={errors.status}
          />

          <DateInput
            name="startDate"
            label="Начало действия"
            value={createDateFromResponse(values.startDate)}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setFieldValue('startDate', createDateToRequestWithTimezone(e.target.value))
            }
            error={errors.startDate}
            showTimeInput
          />

          <DateInput
            name="stopDate"
            label="Окончание действия"
            value={createDateFromResponse(values.stopDate)}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setFieldValue('stopDate', createDateToRequestWithTimezone(e.target.value))
            }
            error={errors.stopDate}
            showTimeInput
          />

          {children}
        </Grid>
      </FormWrapper>
    </Box>
  );
};

export default NSIReagentsLawnsGeneralInformationTab;
