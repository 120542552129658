import { DateInput, FormWrapper, Input, Select } from 'components/common';
import { Box, Grid, Heading } from '@chakra-ui/react';
import React, { useMemo } from 'react';

import {
  getSelectOptionsFromDictionaryByName,
  getSelectRichOptionsFromDictionaryByName,
} from 'features/get-select-options-from-dictionary';
import { Field, useFormikContext } from 'formik';
import { createDateFromResponse, createDateToRequestWithTimezone } from 'lib/create-date';
import { TDictionariesArray } from 'models/dictionaries';

import { useLocation } from 'react-router';
import { WashoutDeicingItem } from '../useWashoutDicingList';
import { fieldsLocale } from '../consts';
import { getFilteredOptionsByPrivileges } from 'features/getFilteredOptionsByPrivileges';

interface CommonTabProps {
  dictionaries: {
    data: TDictionariesArray;
  } | null;
}

export const CommonTab = ({ dictionaries }: CommonTabProps): JSX.Element => {
  const { setFieldValue, values, errors, initialValues } = useFormikContext<WashoutDeicingItem>();
  const location = useLocation();
  const isEditMode = location.pathname.includes('/edit');

  const okrugs = useMemo(() => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'districts'), [dictionaries]);

  const filteredRayons = useMemo(() => {
    const rayons = getSelectOptionsFromDictionaryByName(dictionaries?.data, 'regions');
    if (values.district) {
      return rayons.filter((item) => item.parent === values.district);
    }
    return rayons;
  }, [dictionaries, values]);

  const filteredAreas = useMemo(() => {
    const areas = getFilteredOptionsByPrivileges(
      getSelectRichOptionsFromDictionaryByName(dictionaries?.data, 'areas'),
      'isEditable', 'value', values.areaId
    ).map(({ title: name, ...item }) => ({
      ...item,
      name: String(name),
    }));

    if (values.region) {
      return areas.filter((item) => item.parent === values.region);
    }
    return areas;
  }, [dictionaries, values]);

  const probeOffsets = useMemo(() => {
    const points = getSelectRichOptionsFromDictionaryByName(dictionaries?.data, 'points');

    if (values.areaId) {
      return points.filter((el) => el.parent === values.areaId);
    }

    return points;
  }, [dictionaries, values.areaId]);

  const territoryTypes = useMemo(
    () =>
      getSelectOptionsFromDictionaryByName(dictionaries?.data, 'territoryTypes', false, initialValues.territoryType),
    [dictionaries?.data, initialValues.territoryType],
  );

  return (
    <Box flexDirection="column">
      <FormWrapper>
        <Grid marginTop="30px" gap="15px">
          <Heading variant="h2">Данные обследования</Heading>
          <DateInput
            name="date"
            label={fieldsLocale.date}
            value={createDateFromResponse(values.date)}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setFieldValue('date', createDateToRequestWithTimezone(e.target.value))
            }
            showTimeInput={false}
            isDisabled={isEditMode}
            error={errors?.date}
          />
          <Field
            as={Input}
            label={fieldsLocale.protocolNumber}
            name="protocolNumber"
            value={values.protocolNumber}
            disabled={isEditMode}
            error={errors?.protocolNumber}
            isClearable={!isEditMode}
          />
          <DateInput
            name="protocolDate"
            label={fieldsLocale.protocolDate}
            value={createDateFromResponse(values.protocolDate)}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setFieldValue('protocolDate', createDateToRequestWithTimezone(e.target.value))
            }
            showTimeInput={false}
            isDisabled={isEditMode}
            error={errors?.protocolDate}
          />
          <Field
            as={Select}
            name="areaId"
            label={fieldsLocale.areaId}
            value={values.areaId}
            options={filteredAreas}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              const areaItem = filteredAreas.find((el) => el.value === e.target.value);
              const district = filteredRayons.find((item) => item.value === areaItem?.parent)?.parent || '';
              const region = filteredRayons.find((item) => item.value === areaItem?.parent)?.value || '';

              setFieldValue('areaId', areaItem?.value || '');
              setFieldValue('region', region);
              setFieldValue('district', district);
              setFieldValue('address', areaItem?.address || '');
              setFieldValue('territoryType', areaItem?.territoryType || '');
              setFieldValue('pointId', null);
            }}
            isDisabled={isEditMode}
            error={errors?.areaId}
          />
          <Field
            as={Select}
            name="district"
            label={fieldsLocale.district}
            options={okrugs}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              setFieldValue('district', e.target.value);
              if (!e.target.value) {
                setFieldValue('region', '');
                setFieldValue('areaId', '');
                setFieldValue('address', '');
                setFieldValue('territoryType', '');
              }
            }}
            error={errors?.district}
            isDisabled={isEditMode}
          />
          <Field
            as={Select}
            name="region"
            label="Район"
            options={filteredRayons}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              const district = filteredRayons.find((item) => item.value === e.target.value)?.parent || '';

              setFieldValue('region', e.target.value);
              setFieldValue('district', district);

              if (!e.target.value) {
                setFieldValue('areaId', '');
                setFieldValue('address', '');
                setFieldValue('territoryType', '');
              }
            }}
            isDisabled={isEditMode}
            error={errors?.region}
          />
          <Field
            as={Input}
            name="address"
            label={fieldsLocale.address}
            disabled
            error={errors?.address}
            isClearable={false}
          />
          <Field
            as={Select}
            name="territoryType"
            options={territoryTypes}
            label={fieldsLocale.territoryType}
            isDisabled
            error={errors?.territoryType}
          />
          <Field
            as={Select}
            name="pointId"
            options={probeOffsets}
            label={fieldsLocale.pointId}
            isDisabled={isEditMode || !values.areaId}
            error={errors?.pointId}
          />
        </Grid>
      </FormWrapper>
    </Box>
  );
};
