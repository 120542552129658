import React from 'react';
import { Button } from 'components/common';
import { Flex } from '@chakra-ui/react';
import { ReactComponent as CloseIcon } from 'features/Filter/icons/close.svg';

interface Props {
  onClear: () => void;
  isLoading: boolean;
}

export const NODEmptyFilter: React.FC<Props> = ({
  children,
  onClear,
  isLoading
}) => (
  <Flex alignItems="center">
    {children}
    <Button
      onClick={() => {
        onClear();
      }}
      isLoading={isLoading}
      variant="outline"
      color="PrimaryButton.500"
      padding={0}
      marginLeft={5}
      width="30px"
      height="30px"
      title="Очистить фильтр"
    >
      <CloseIcon />
    </Button>
  </Flex>
);
