import { Box, Flex, Text } from '@chakra-ui/react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useEnhancedNotification, useRequest } from '../../../hooks';
import ReactECharts from 'echarts-for-react';
import moment from 'moment';
import { IGraphPoint, IGraphResponse, IGraphSettings } from 'pages/analyticsReports/CombinedCharts/types';
import { makeInitialGraphSettings } from 'pages/analyticsReports/CombinedCharts/graphUtils';

interface IProps {
  title?: string
  icon?: React.ReactNode
  subtitle?: string
  height?: string
  url?: string | null
}

const ManagerDataGraphInversion = ({ title, icon, subtitle, height, url }: IProps) => {
  const [graphData, setGraphData] = useState<IGraphResponse>();
  const [graphSettings, setGraphSettings] = useState<IGraphSettings | null>(null);
  const { onGetError } = useEnhancedNotification();

  const onSuccess = useCallback((response: IGraphResponse | null) => {
    if (!response) {
      return;
    }
    setGraphData(response);
    // set default graph settings from response
    setGraphSettings(
      makeInitialGraphSettings({
        graphResponse: response
      })
    );
  }, []);

  const { get } = useRequest<IGraphResponse>(
    '/reporting/v10/dashboard/indicators/graph',
    onSuccess,
    onGetError
  );

  useEffect(() => {
    if (url) {
      void get({
        url,
      });
    }
  }, [get, url]);

  const barDataFromatted = useMemo(() => {
    const formatted: any[] = [];
    const barData = graphData?.datasets;
    barData?.forEach((i: IGraphPoint[]) => {
      // @ts-ignore
      const d = i.map((p) => ([p.x[0], p.x[1], p.y[0], p.y[1], p.textIntensityValue, moment(p.x[0]).format('DD.MM.YYYY HH:mm'), moment(p.x[1]).format('DD.MM.YYYY HH:mm'), `${String(p.y[0])}‎`, `${String(p.y[1])}‎`]));
      formatted.push(d);
    });

    return formatted;
  }, [graphData]);

  const option = useMemo(() => {
    const xMin = graphData?.axisSettings.x.min || 0;
    const xMax = graphData?.axisSettings.x.max || 1000;
    const diff = Math.round((xMax - xMin) / 60 / 60 / 1000);

    let xTickCount = 30;
    // 2 дня
    if (diff <= 48) {
      xTickCount = 48;
    }
    // 1 день
    if (diff <= 24) {
      xTickCount = 24;
    }

    const barSeries = barDataFromatted.map((d, index) => (
      {
        type: 'custom',
        name: graphData?.axisTitles[index],
        color: graphData?.colors[index],
        renderItem: (params: any, api: any) => {
          const yValue = api.value(2);
          const yValue1 = api.value(3);
          const start = api.coord([api.value(0), yValue1]);
          const size = api.size([api.value(1) - api.value(0), yValue1 - yValue]);
          const style = api.style();

          return {
            type: 'rect',
            shape: {
              x: start[0],
              y: start[1],
              width: size[0],
              height: size[1]
            },
            style,
          };
        },
        label: {
          show: false,
        },
        dimensions: ['', '', '', '', '', 'От', 'До', 'Высота от', 'Высота до'],
        encode: {
          x: [0, 1],
          y: [2, 3],
          tooltip: [5, 6, 7, 8],
          itemName: 4
        },
        data: d,
        responsive: true,
        maintainAspectRatio: false,
        yAxisIndex: 0
      }
    ));

    const legend = graphSettings?.graphs.map((s) => s.text) || [];

    return {
      grid: {
        left: '20',
        top: '40',
        right: '20',
        bottom: '10%',
        containLabel: true,
      },
      title: {
        text: graphSettings?.title,
        left: 'center'
      },
      legend: {
        top: 0,
        data: legend,
      },
      tooltip: {},
      xAxis: {
        type: 'time',
        splitLine: {
          show: true,
        },
        axisLabel: {
          rotate: 45,
          hideOverlap: true,
          formatter: (
            (value: any) => {
              let label = '';
              if (value) {
                label = moment(value).format('DD.MM HH:mm');
              }
              return label;
            }
          )
        },
        min: graphData?.axisSettings.x.min,
        max: graphData?.axisSettings.x.max,
        splitNumber: xTickCount,
      },
      yAxis: [
        {
          type: 'value',
          name: 'Высота',
          position: graphSettings?.graphs[0].position,
          nameLocation: 'center',
          nameTextStyle: {
            padding: [16, 0, 16, 0],
          },
          axisLine: {
            show: true,
          },
          axisTick: {
            interval: 2,
          },
          splitNumber: 10,
          min: graphData?.axisSettings.y[0].min,
          max: graphData?.axisSettings.y[0].max,
          axisLabel: {
            formatter: ((value: any) => String(value))
          },
        }
      ],
      series: barSeries
    };
  }, [graphData, barDataFromatted, graphSettings]);

  return (
    <Flex
      width="100%"
      flexDirection="column"
      p="20px"
    >
      <Flex justifyContent="space-between" flexDirection="column" mb="12px">
        <Flex gap="16px" alignItems="center">
          {icon}
          <Box height="30px">
            <Text fontWeight="700" fontSize="18px" lineHeight="30px">{title}</Text>
          </Box>
        </Flex>
        {subtitle && (
          <Text mt="12px" width="100%" align="left" fontWeight="500" fontSize="14px" lineHeight="18px">{subtitle}</Text>
        )}
      </Flex>

      <Box style={{ height }}>
        <ReactECharts
          option={option}
          style={{ height: '100%', width: '100%' }}
        />
      </Box>
    </Flex>
  );
};

export default ManagerDataGraphInversion;
