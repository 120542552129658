import * as yup from 'yup';
import { ValidationError } from 'yup';
import {
  DateToLessThanFrom,
  incidentTypeError,
  noSurroundingWhitespacesError,
  numberError,
  requiredError,
  urlError,
} from '../constans';
import { useMemo } from 'react';
import { getSelectRichOptionsFromDictionaryByName } from 'features/get-select-options-from-dictionary';

export const ComplaintSchema = yup.object().shape({
  assignmentDate: yup.string().nullable().required(requiredError),
  assignmentDeadline: yup.string().nullable(),
  assignmentExecution: yup.string().nullable(),
  assignmentNumber: yup.string().typeError(requiredError).required(requiredError),
  complaintCitizenAddress: yup.string().nullable(),
  complaintCitizenEmail: yup.string().nullable(),
  complaintCitizenFio: yup.string().trim(noSurroundingWhitespacesError).strict(true).required(requiredError),
  complaintCitizenPhone: yup.string().nullable(),
  complaintDate: yup.string().nullable().required(requiredError),
  complaintNumber: yup.string().typeError(numberError).required(requiredError),
  complaintOriginId: yup.string().nullable().required(requiredError),
  complaintText: yup.string().required(requiredError),
  questions: yup.array().of(yup.string()),
});

export const IncidentShema = (dict: any) => {
  const incidentTypes = useMemo(() => getSelectRichOptionsFromDictionaryByName(dict, 'incidentTypes'), [dict]);
  return yup.object().shape({
    complaintComment: yup.string().nullable(),
    assignmentExecutor: yup.string().required(requiredError).nullable(),
    complaintStatusId: yup.string().typeError(requiredError).required(requiredError),
    incidents: yup.array().of(
      yup.object().shape({
        incidentTypeId: yup.string().typeError(requiredError).required(requiredError),
        incidentAddress: yup.string().required(requiredError),
        incidentAreaId: yup.string().typeError(requiredError).required(requiredError),
        incidentDistrictId: yup.string().typeError(requiredError).required(requiredError),
        incidentLatitude: yup.number().typeError(numberError).required(requiredError),
        incidentLongitude: yup.number().typeError(numberError).required(requiredError),
        incidentNoiseSourceTypeId: yup.string().when('incidentTypeId', {
          is: incidentTypes.find((type) => type.name === 'Шум')?.value,
          then: (schema) => schema.typeError(requiredError).required(requiredError),
          otherwise: (schema) => schema.notRequired().nullable(),
        }),
        incidentOdorSourceTypeId: yup.string().when('incidentTypeId', {
          is: incidentTypes.find((type) => type.name === 'Воздух')?.value,
          then: (schema) => schema.typeError(requiredError).required(requiredError),
          otherwise: (schema) => schema.notRequired().nullable(),
        }),
      })
    ),
  });
};

export const AnswerSchema = yup.object().shape({
  answers: yup.array().of(
    yup.object().shape({
      answerAddress: yup.string().required(requiredError),
      answerAgreementDate: yup.string().typeError(requiredError).required(requiredError),
      answerAgreementSet: yup.string().nullable(),
      answerAssignmentKind: yup.string().typeError(requiredError).required(requiredError),
      answerDocDate: yup.string().typeError(requiredError).required(requiredError),
      answerDocNumber: yup.string().nullable(),
      answerText: yup.string().required(requiredError),
      answerUrl: yup
        .string()
        .matches(/^(ftp|http|https):\/\/[^ "]+$/, urlError)
        .typeError(urlError),
    })
  ),
});

export const PrepareAnswer = yup.object().shape({
  assigmentExecutorPhone: yup.string().required(requiredError),
  employeeFio: yup.string().required(requiredError),
  employeePosition: yup.string().required(requiredError),
  templateAnswer: yup.string().required(requiredError),
  templateQuestion: yup.string().required(requiredError),
  templateType: yup.string().typeError(requiredError).required(requiredError),
});

export const ComplaintSearchSchema = (isFilter?: boolean) => yup
  .object()
  .shape({
    incidentNoiseSourceTypeId: yup.object().shape({
      value: yup.string().nullable(),
    }),
    incidentOdorSourceTypeId: yup.object().shape({
      value: yup.string().nullable(),
    }),
    ...(isFilter ? {
      incidentTypeId: yup.object().shape({
        value: yup.array().of(yup.string()).nullable(),
      }),
    } : {
      incidentTypeId: yup.object().shape({
        value: yup.string().nullable(),
      }),
    }),
    dateTimeMin: yup.object().shape({
      value: yup.string().nullable(),
    }),
    dateTimeMax: yup.object().shape({
      value: yup.string().nullable(),
    }),
    assignmentDateMin: yup.object().shape({
      value: yup.string().nullable(),
    }),
    assignmentDateMax: yup.object().shape({
      value: yup.string().nullable(),
    }),
    complaintDateMin: yup.object().shape({
      value: yup.string().nullable(),
    }),
    complaintDateMax: yup.object().shape({
      value: yup.string().nullable(),
    }),
  })
  .test((values) => {
    const errorsArray = [];
    if (!values.incidentTypeId.value && values.incidentOdorSourceTypeId.value) {
      errorsArray.push(new yup.ValidationError(incidentTypeError, '', 'incidentTypeId.value'));
    }
    if (values.dateTimeMin.value && values.dateTimeMax.value) {
      if (new Date(values.dateTimeMin.value) > new Date(values.dateTimeMax.value)) {
        errorsArray.push(new ValidationError(DateToLessThanFrom, '', 'dateTimeMin.value'));
      }
    }
    if (values.assignmentDateMin.value && values.assignmentDateMax.value) {
      if (new Date(values.assignmentDateMin.value) > new Date(values.assignmentDateMax.value)) {
        errorsArray.push(new ValidationError(DateToLessThanFrom, '', 'assignmentDateMin.value'));
      }
    }
    if (values.complaintDateMin.value && values.complaintDateMax.value) {
      if (new Date(values.complaintDateMin.value) > new Date(values.complaintDateMax.value)) {
        errorsArray.push(new ValidationError(DateToLessThanFrom, '', 'complaintDateMin.value'));
      }
    }
    if (errorsArray) {
      return new ValidationError(errorsArray as yup.ValidationError[]);
    }
    return true;
  });
