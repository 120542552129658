import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import { CitizensAppealsPath, VideoCamerasPath } from '../../consts/consts';
import { BreadcrumbButton, PageHeader } from 'components/common';
import { Heading } from '@chakra-ui/react';
import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { EditForm } from '../EditForm';
import { FormikProvider, useFormik } from 'formik';
import { useNotifications, useRequest } from 'hooks';
import { ERROR_MESSAGES, SUCCESS_MESSAGES } from 'constants/messages';
import * as yup from 'yup';
import { numberError, requiredError } from 'models/schemas/constans';
import { createTouchedErrors } from 'features/create-touched-errors';
import { ICamItem } from '../types/types';
import { useGoBackWithFallback } from 'lib/utils/goBackWithFallback';
import { useDictionary } from 'hooks/useDictionary';
import { getSelectOptionsFromDictionaryByName } from 'features/get-select-options-from-dictionary';

export const EditCam = () => {
  const { id } = useParams<{ id: string }>();
  const { backWithFallback } = useGoBackWithFallback();
  const { successNotify, errorNotify } = useNotifications();

  useUpdateBreadcrumbs([
    {
      breadcrumb: 'Учет обращений граждан',
      path: CitizensAppealsPath,
    },
    {
      breadcrumb: 'Учет камер видеонаблюдения',
      path: VideoCamerasPath,
    },
    {
      breadcrumb: `${id ? 'Редактирование' : 'Добавление'} камеры видеонаблюдения`,
      path: `${VideoCamerasPath}/${id ? `edit/${id}` : 'add'}`,
    },
  ]);

  const onSuccess = () => {
    successNotify({
      key: 'file/delete/success',
      message: id ? SUCCESS_MESSAGES.EDIT : SUCCESS_MESSAGES.CREATE,
    });
    backWithFallback(VideoCamerasPath);
  };

  const onError = () =>
    errorNotify({
      key: 'file/delete/error',
      message: id ? ERROR_MESSAGES.EDIT : ERROR_MESSAGES.CREATE,
    });

  const { post } = useRequest('/appeal/v10/cameras', onSuccess, onError);
  const { get, result } = useRequest<ICamItem>(`appeal/v10/cameras/${id}`);
  const { put } = useRequest(`/appeal/v10/cameras/${id}`, onSuccess, onError);

  useEffect(() => {
    if (id) {
      get();
    }
  }, [id, get]);

  const CamValidationSchema = yup.object().shape({
    number: yup.number().typeError(numberError).required(requiredError),
    address: yup.string().nullable(),
    latitude: yup.number().typeError(numberError).required(requiredError),
    longitude: yup.number().typeError(numberError).required(requiredError),
    description: yup.string().nullable(),
    viewingAngle: yup.number().typeError(numberError).nullable(),
  });

  const initialValues = {
    number: result?.number || '',
    address: result?.address || '',
    latitude: result?.latitude || '',
    longitude: result?.longitude || '',
    description: result?.description || '',
    viewingAngle: result?.viewingAngle || '',
    id: result?.id || '',
    type: result?.type || '',
    areaId: result?.areaId || '',
    regionId: result?.regionId || ''
  };

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    initialValues,
    validationSchema: CamValidationSchema,
    onSubmit: (values: ICamItem) => {
      if (id) {
        put(values);
      } else {
        post(values);
      }
    },
  });

  const { dictionaries } = useDictionary({ url: '/appeal/v10/cameras/dictionaries' });
  const cameraTypes = useMemo(
    () => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'cameraTypes', false, initialValues.type),
    [dictionaries?.data, initialValues.type],
  );
  const admAreas = useMemo(() => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'admAreas', false), [dictionaries]);
  const districts = useMemo(
    () => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'districts', false),
    [dictionaries],
  );

  return (
    <>
      <PageHeader>
        <BreadcrumbButton onClick={() => backWithFallback(VideoCamerasPath)}>Вернуться назад</BreadcrumbButton>
        <Heading marginLeft={4}>{`${id ? 'Редактирование' : 'Добавление'} камеры видеонаблюдения`}</Heading>
      </PageHeader>
      <FormikProvider
        value={{
          ...formik,
          errors: createTouchedErrors(formik.errors, formik.touched),
        }}
      >
        <EditForm cameraTypes={cameraTypes} admAreas={admAreas} districts={districts} />
      </FormikProvider>
    </>
  );
};
