import { Stack } from '@chakra-ui/react';
import { Field, useFormikContext } from 'formik';
import type { TGreenPlantsForm } from '../../types';
import { Input, Select } from 'components/common';
import React, { useCallback } from 'react';
import type { TFLoraDictionaries } from 'pages/data/GreenPlants/Flora/useFloraDictionaries';
import { dictParametersToMap } from 'features/parametersMap';
import { getDictionariesIds, getDictionaryId } from 'pages/data/GreenPlants/utils';
import { getSelectOptionsFromDictionaryByName } from '../../../../../../features/get-select-options-from-dictionary';
import { get } from 'lodash';

interface Props {
  entity: string;
  areaParameters: TFLoraDictionaries['areaParameters'];
}

export const AreaMeasurments = ({ entity, areaParameters }: Props) => {
  const { errors, values, setFieldValue } = useFormikContext<TGreenPlantsForm>();

  const parameters = dictParametersToMap(areaParameters);

  const getOptionsByName = useCallback((name: string) => getSelectOptionsFromDictionaryByName(
    areaParameters,
    name,
    false,
    get(values, `${entity}.parameters.${name}.ids`)
  ), [areaParameters, values, entity]);

  return (
    <Stack>
      <Field
        as={Input}
        label="Площадь, кв.м"
        name={`${entity}.parameters.area.value[0]`}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setFieldValue(`${entity}.parameters.area.value`,
            [e.target.value]);
          setFieldValue(`${entity}.parameters.area.parameterId`,
            parameters.area?.parameterId);
        }}
        type="number"
        onKeyDown={(e: any) => ['e', 'E'].includes(e.key) && e.preventDefault()}
        error={get(errors.area?.parameters?.area, 'value')}
      />
      <Field
        as={Select}
        label="Наблюдающая организация"
        options={getOptionsByName('organization')}
        name={`${entity}.parameters.organization.dictionaryItemId[0]`}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          if (e?.target) {
            setFieldValue(`${entity}.parameters.organization.dictionaryItemId`, [
              getDictionaryId(e.target.value, parameters.organization.values)
            ]);
            setFieldValue(`${entity}.parameters.organization.value`, [e.target.value]);
            setFieldValue(
              `${entity}.parameters.organization.parameterId`,
              parameters.organization?.parameterId,
            );
          }
        }}
      />
      <Field
        multiple
        as={Select}
        options={getOptionsByName('lawnType')}
        name={`${entity}.parameters.lawnType.dictionaryItemId`}
        label="Тип газона на площадке наблюдения"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          if (Array.isArray(e.target.value)) {
            setFieldValue(`${entity}.parameters.lawnType.dictionaryItemId`,
              getDictionariesIds(e.target.value, parameters.lawnType.values));
            setFieldValue(`${entity}.parameters.lawnType.value`, e.target.value);
            setFieldValue(`${entity}.parameters.lawnType.parameterId`, parameters.lawnType?.parameterId);
          }
        }}
      />
      <Field
        as={Select}
        label="Состояние газона на площадке наблюдения"
        options={getOptionsByName('lawnState')}
        name={`${entity}.parameters.lawnState.dictionaryItemId[0]`}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          if (e?.target) {
            setFieldValue(`${entity}.parameters.lawnState.dictionaryItemId`, [
              getDictionaryId(e.target.value, parameters.lawnState.values)
            ]);
            setFieldValue(`${entity}.parameters.lawnState.value`, [e.target.value]);
            setFieldValue(
              `${entity}.parameters.lawnState.parameterId`,
              parameters.lawnState?.parameterId,
            );
          }
        }}
      />
      <Field
        multiple
        as={Select}
        label="Признак состояния газона"
        options={getOptionsByName('lawnStateType')}
        name={`${entity}.parameters.lawnStateType.dictionaryItemId`}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          if (Array.isArray(e.target.value)) {
            setFieldValue(`${entity}.parameters.lawnStateType.dictionaryItemId`,
              getDictionariesIds(e.target.value, parameters.lawnStateType.values));
            setFieldValue(`${entity}.parameters.lawnStateType.value`, e.target.value);
            setFieldValue(`${entity}.parameters.lawnStateType.parameterId`, parameters.lawnStateType?.parameterId);
          }
        }}
      />
      <Field
        multiple
        as={Select}
        label="Цветники"
        options={getOptionsByName('hasFlowerGarden')}
        name={`${entity}.parameters.hasFlowerGarden.dictionaryItemId`}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          if (Array.isArray(e.target.value)) {
            setFieldValue(`${entity}.parameters.hasFlowerGarden.dictionaryItemId`,
              getDictionariesIds(e.target.value, parameters.hasFlowerGarden.values));
            setFieldValue(`${entity}.parameters.hasFlowerGarden.value`, e.target.value);
            setFieldValue(`${entity}.parameters.hasFlowerGarden.parameterId`, parameters.hasFlowerGarden?.parameterId);
          }
        }}
      />
      <Field
        as={Select}
        label="Состояние цветников"
        options={getOptionsByName('flowerGardenState')}
        name={`${entity}.parameters.flowerGardenState.dictionaryItemId[0]`}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          if (e?.target) {
            setFieldValue(`${entity}.parameters.flowerGardenState.dictionaryItemId`, [
              getDictionaryId(e.target.value, parameters.flowerGardenState.values)
            ]);
            setFieldValue(`${entity}.parameters.flowerGardenState.value`, [e.target.value]);
            setFieldValue(
              `${entity}.parameters.flowerGardenState.parameterId`,
              parameters.flowerGardenState?.parameterId,
            );
          }
        }}
      />
      <Field
        as={Select}
        label="Благоустройство"
        options={getOptionsByName('beautification')}
        name={`${entity}.parameters.beautification.dictionaryItemId[0]`}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          if (e?.target) {
            setFieldValue(`${entity}.parameters.beautification.dictionaryItemId`, [
              getDictionaryId(e.target.value, parameters.beautification.values)
            ]);
            setFieldValue(`${entity}.parameters.beautification.value`, [e.target.value]);
            setFieldValue(
              `${entity}.parameters.beautification.parameterId`,
              parameters.beautification?.parameterId,
            );
          }
        }}
      />
      <Field
        as={Select}
        label="Окружающая застройка"
        options={getOptionsByName('surroundingBuilding')}
        name={`${entity}.parameters.surroundingBuilding.dictionaryItemId[0]`}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          if (e?.target) {
            setFieldValue(`${entity}.parameters.surroundingBuilding.dictionaryItemId`, [
              getDictionaryId(e.target.value, parameters.surroundingBuilding.values)
            ]);
            setFieldValue(`${entity}.parameters.surroundingBuilding.value`, [e.target.value]);
            setFieldValue(
              `${entity}.parameters.surroundingBuilding.parameterId`,
              parameters.surroundingBuilding?.parameterId,
            );
          }
        }}
      />
      <Field
        as={Input}
        label="Количество деревьев, шт."
        name={`${entity}.parameters.treeCount.value[0]`}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setFieldValue(`${entity}.parameters.treeCount.value`,
            [e.target.value]);
          setFieldValue(`${entity}.parameters.treeCount.parameterId`,
            parameters.treeCount?.parameterId);
        }}
      />
      <Field
        as={Input}
        label="Количество кустарников, шт."
        name={`${entity}.parameters.shrubCount.value[0]`}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setFieldValue(`${entity}.parameters.shrubCount.value`,
            [e.target.value]);
          setFieldValue(`${entity}.parameters.shrubCount.parameterId`,
            parameters.shrubCount?.parameterId);
        }}
      />
      <Field
        as={Input}
        label="Количество аварийных элементов, шт."
        name={`${entity}.parameters.emergencyElementCount.value[0]`}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setFieldValue(`${entity}.parameters.emergencyElementCount.value`,
            [e.target.value]);
          setFieldValue(`${entity}.parameters.emergencyElementCount.parameterId`,
            parameters.emergencyElementCount?.parameterId);
        }}
      />
      <Field
        as={Input}
        label="Комментарий"
        name={`${entity}.parameters.Comment.value[0]`}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setFieldValue(`${entity}.parameters.Comment.value`,
            [e.target.value]);
          setFieldValue(`${entity}.parameters.Comment.parameterId`,
            parameters.Comment?.parameterId);
        }}
      />
    </Stack>
  );
};
