import { useEffect } from 'react';
import { IFilterValues } from '../../types';
import { FormikErrors } from 'formik/dist/types';

interface UseSetFiltersFromGraphic {
  dateKeyMin: string;
  dateKeyMax: string;
  filtersFromGraphic?: Partial<IFilterValues> | null;
  setFiltersFromGraphic?: (value: Partial<IFilterValues> | null) => void;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<FormikErrors<any>> | Promise<void>;
  onChange?: () => void;
}
export const useSetFiltersFromGraphic = ({
  dateKeyMin,
  dateKeyMax,
  filtersFromGraphic,
  setFieldValue,
  setFiltersFromGraphic,
  onChange,
}: UseSetFiltersFromGraphic) => {
  useEffect(() => {
    if (filtersFromGraphic) {
      if (filtersFromGraphic?.startDate) {
        setFieldValue(dateKeyMin, filtersFromGraphic.startDate, true);
      }
      if (filtersFromGraphic?.stopDate) {
        setFieldValue(dateKeyMax, filtersFromGraphic.stopDate, true);
      }
      setFiltersFromGraphic?.(null);
      if (onChange) {
        onChange();
      }
    }
  }, [dateKeyMax, dateKeyMin, filtersFromGraphic, onChange, setFieldValue, setFiltersFromGraphic]);
};
