import { ErrorTableTdBackdrop } from 'features/material-table/components/ErrorTableTdBackdrop';
import {
  Flex,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import React from 'react';
import { makeStyles } from '@material-ui/core';

interface Props {
  value: string | number | undefined | null;
  error?: string | null;
  isLargeText?: boolean;
  maxWidth?: number;
  noOfLines?: number;
  width?: number;
  flex?: number;
}

const useStyles = makeStyles(() => (
  {
    text: {
      whiteSpace: 'pre-wrap',
      '& $a': {
        color: '#0000EE',
        textDecoration: 'underline'
      }
    },
  }
));

export const TruncatedStringPopoverCell: React.FC<Props> = ({ value = '', error, isLargeText, maxWidth = 150, width, noOfLines, flex }) => {
  const classes = useStyles();
  const { isOpen, onOpen, onClose } = useDisclosure();
  if (error) {
    return <ErrorTableTdBackdrop>{error}</ErrorTableTdBackdrop>;
  }

  return (
    <Flex
      position={isLargeText ? 'inherit' : 'relative'}
      alignItems="center"
      width="100%"
      height="100%"
    >
      <Popover isLazy isOpen={isOpen} onClose={onClose} placement="bottom">
        <PopoverTrigger>
          {
            flex ? (
              <Text
                onClick={(e) => {
                  e.stopPropagation();
                  onOpen();
                }}
                color="PrimaryButton.500"
                isTruncated={noOfLines === undefined}
                noOfLines={noOfLines}
                flex={flex}
                width={width}
                dangerouslySetInnerHTML={{ __html: value ? String(value) : '' }}
              />
            ) : (
              <Text
                onClick={(e) => {
                  e.stopPropagation();
                  onOpen();
                }}
                color="PrimaryButton.500"
                maxWidth={maxWidth}
                isTruncated={noOfLines === undefined}
                noOfLines={noOfLines}
                width={width}
                dangerouslySetInnerHTML={{ __html: value ? String(value) : '' }}
              />
            )
          }
        </PopoverTrigger>
        <PopoverContent
          maxHeight={isLargeText ? '400px' : 'inherit'}
          overflow={isLargeText ? 'scroll' : 'hidden'}
          paddingTop={3}
          onClick={(e) => e.stopPropagation()}
          marginRight={5}
        >
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverBody>
            <Text className={classes.text} dangerouslySetInnerHTML={{ __html: value ? String(value) : '' }} />
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Flex>
  );
};
