import { FormHeader } from 'components/common';
import { ERROR_MESSAGES, SUCCESS_MESSAGES } from 'constants/messages';
import { createTouchedErrors } from 'features/create-touched-errors';
import { FormikProvider, useFormik } from 'formik';
import { useNotifications, useRequest } from 'hooks';
import { PasswordComplexitySettingsSchema } from 'models/schemas/admin/passwordcomplexity';
import { useTypedLoader } from 'pages/admin/typedLoader';
import { useHistory } from 'react-router-dom';
import { PasswordComplexitySettingsItem } from '../models/PasswordComplexitySettingsItem';
import { baseAdminPasswordComplexityUtl as baseAdminPasswordComplexityUrl } from './const';
import FieldsForm from './FieldsForm';

export const PasswordComplexitySettings: React.FC = () => {
  const history = useHistory();

  const { successNotify, errorNotify } = useNotifications();

  const successHandler = () => {
    successNotify({
      key: 'file/create/success',
      message: SUCCESS_MESSAGES.EDIT,
    });
    history.push('/admin');
  };
  const errorHandler = (error: any) => {
    errorNotify({
      key: 'file/create/error',
      message: `${ERROR_MESSAGES.EDIT}. ${typeof error === 'string' ? error : ''}`,
    });
  };

  const { isLoading, result: currentPasswordSettings } =
    useTypedLoader<PasswordComplexitySettingsItem>(baseAdminPasswordComplexityUrl);

  const initialValues = { ...currentPasswordSettings };

  const { put: updatePasswordComplexity } = useRequest(baseAdminPasswordComplexityUrl, successHandler, errorHandler);

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    initialValues,
    validationSchema: PasswordComplexitySettingsSchema,
    onSubmit: (values) => updatePasswordComplexity(values),
  });

  return (
    <FormikProvider
      value={{
        ...formik,
        errors: createTouchedErrors(formik.errors, formik.touched),
      }}
    >
      <FormHeader url="/admin/passwordComplexitySettings" header="Редактирование настройки политики паролей" />
      <FieldsForm onCancel={() => history.push('/admin/passwordComplexitySettings')} isLoading={isLoading} />
    </FormikProvider>
  );
};
