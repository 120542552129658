import { apiPathsWaterRoot } from 'pages/data/Water/apiPath';

const GroundCoastBaseApiPath = `${apiPathsWaterRoot}/water/groundcoast`;

export const GroundCoastApiPath = {
  list: `${GroundCoastBaseApiPath}/list`,
  dictionaries: `${GroundCoastBaseApiPath}/dictionaries`,
  root: `${GroundCoastBaseApiPath}`,
  getOrDelete: (id: string) => `${GroundCoastBaseApiPath}/${id}`,
  getWaterObjectData: (id: string) => `${GroundCoastBaseApiPath}/dictionaries/water_objects/${id}`,
  getAreaData: (areaId: string) => `${GroundCoastBaseApiPath}/dictionaries/areas/${areaId}`
};
