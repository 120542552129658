import { Filter } from 'features/Filter';
import { createInitialValuesToFormik, createValuesToRequest } from 'features/Filter/utils';
import { map } from 'lodash';
import React, { memo, useMemo, useState } from 'react';

import { TFLoraDictionaries } from './useFloraDictionaries';
import { TFloraFilterDTO } from './useFloraList';
import { GreenPlantsAreaLabels } from '../Form/Tabs/Common/constants';
import { usePlantsByAreaIdsArray } from '../Form/usePlantsByAreaId';
import { IFilterValues } from 'components/common';
import { IPeriod } from 'features/PeriodsButtons/types';
import { IFilterItem } from 'models';
import combineFilters from 'lib/utils/combineFilters';
import { SchemaForCustomFilter } from 'models/schemas/filtersDatepickersSchema/FiltersDatepickersSchema';
import { selectOptionNaturalSort } from 'lib/utils/naturalSort';

type FilterMeteoListInitialValues = Record<'dateFrom' | 'dateTo' | 'areaId' | 'plantNumber' | 'okrugId' | 'rayonId',
  TFloraFilterDTO['filters'][number]>;

const initialValues: FilterMeteoListInitialValues = {
  dateFrom: {
    // @ts-ignore
    key: 'date',
    operation: 'GTE',
    value: '',
  },
  dateTo: {
    // @ts-ignore
    key: 'date',
    operation: 'LTE',
    value: '',
  },
  // @ts-ignore
  areaId: { key: 'areaId', operation: 'IN', value: null },
  // @ts-ignore
  plantNumber: { key: 'plantNumber', operation: 'EQ', value: '' },
  okrugId: {
    key: 'okrugId',
    operation: 'IN',
    value: null,
  },
  rayonId: {
    key: 'rayonId',
    operation: 'IN',
    value: null,
  },
  areaTypeId: {
    key: 'areaTypeId',
    operation: 'IN',
    value: null,
  },
  periodId: {
    key: 'periodId',
    operation: 'EQ',
    value: null,
  },
  disabled: {
    key: 'deleted',
    operation: 'EQ',
    value: false,
  }
};

interface FloraFilterProps {
  dictionaries: Partial<TFLoraDictionaries>;
  isLoading: boolean;
  onSubmit: (values: FilterMeteoListInitialValues) => void;
  filtersFromGraphic: Partial<IFilterValues> | null;
  setFiltersFromGraphic: (value: Partial<IFilterValues> | null) => void;
  periods: IPeriod[] | null;
  filters: IFilterItem[];
}

const initialValuesToFormik = createInitialValuesToFormik(initialValues);

function FloraFilterComponent({
  setFiltersFromGraphic,
  filtersFromGraphic,
  dictionaries,
  isLoading,
  periods,
  onSubmit,
  filters,
  children,
}: React.PropsWithChildren<FloraFilterProps>) {
  const { areas = [], rayons = [], okrugs = [], areaType = [] } = dictionaries;

  const [okrugIds, setOkrugIds] = useState<string[]>([]);
  const [rayonIds, setRayonIds] = useState<string[]>([]);
  const [areaIds, setAreaIds] = useState<string[]>([]);

  const { result: plants, reset: resetPlants } = usePlantsByAreaIdsArray(areaIds);

  const filteredRayons = useMemo(() => {
    if (okrugIds && okrugIds.length > 0) {
      return rayons.filter((i) => okrugIds.includes(i.parent || ''));
    }
    return rayons;
  }, [rayons, okrugIds]);

  const filteredAreas = useMemo(() => {
    if (rayonIds && rayonIds.length > 0) {
      return areas.filter((i) => rayonIds.includes(i.parent || ''));
    } if (okrugIds && okrugIds.length > 0) {
      const rIds = filteredRayons.map((i) => i.value);
      return areas.filter((i) => rIds.includes(i.parent || ''));
    }
    return areas;
  }, [areas, rayonIds, okrugIds, filteredRayons]);

  const presetValues = useMemo<{ key: string; value: IFilterItem['value']; }[]>(() => {
    const preset: { key: string; value: IFilterItem['value']; }[] = [];
    const initialFilters = combineFilters(initialValues, filters);
    Object.keys(initialFilters).forEach((key) => {
      preset.push({ key: `${key}`, value: initialFilters[key].value });
    });
    return preset;
  }, [filters]);

  return (
    <Filter<typeof initialValuesToFormik>
      savedPeriodId={filters.find((item) => item.key === 'periodId')?.value as string || ''}
      dateFieldFrom="dateFrom"
      dateFieldTo="dateTo"
      periods={periods}
      isLoading={isLoading}
      isDisabled={isLoading}
      setFiltersFromGraphic={setFiltersFromGraphic}
      filtersFromGraphic={filtersFromGraphic}
      onSubmit={(values) => {
        onSubmit(createValuesToRequest(initialValues, values));
      }}
      onValues={(values) => {
        if (Array.isArray(values.areaId) && values.areaId.length) {
          const areasIds = values.areaId.map((x) => String(x));
          if (areasIds) {
            setAreaIds(areasIds);
          }
        } else {
          resetPlants();
          setAreaIds([]);
        }

        // @ts-ignore
        setOkrugIds(values.okrugId || []);

        // @ts-ignore
        setRayonIds(values.rayonId || []);
      }}
      initialValues={initialValuesToFormik}
      mainFields={[
        {
          type: 'DATE',
          name: 'dateFrom',
          label: 'От',
          showTimeInput: false,
        },
        {
          type: 'DATE',
          name: 'dateTo',
          label: 'До',
          showTimeInput: false,
        },
        {
          type: 'MULTI_SELECT',
          name: 'areaTypeId',
          label: 'Тип территории',
          options: selectOptionNaturalSort(areaType),
        },
        {
          type: 'MULTI_SELECT',
          name: 'okrugId',
          label: 'Округ',
          options: selectOptionNaturalSort(okrugs),
          resetOnChange: ['rayonId', 'areaId', 'plantNumber'],

        },
        {
          type: 'MULTI_SELECT',
          name: 'rayonId',
          label: 'Район',
          options: selectOptionNaturalSort(filteredRayons),
          resetOnChange: ['areaId', 'plantNumber'],
        },
        {
          type: 'MULTI_SELECT',
          name: 'areaId',
          label: GreenPlantsAreaLabels.areaNumber,
          options: selectOptionNaturalSort(filteredAreas),
          childField: 'plantNumber',
        },
        {
          type: 'SELECT',
          name: 'plantNumber',
          label: GreenPlantsAreaLabels.plantNumber,
          options: selectOptionNaturalSort(map(plants, ({ number }) => ({
            name: String(number),
            value: String(number),
          }))),
          isDisabled: !plants?.length,
        },
      ]}
      presetValues={presetValues}
      validationSchema={() =>
        SchemaForCustomFilter([{ dateFromFieldName: 'dateFrom', dateToFieldName: 'dateTo' }])
      }
    >
      {children}
    </Filter>
  );
}

const FloraFilter = memo(FloraFilterComponent);

export { FloraFilter };
