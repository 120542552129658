import { RenderTree } from 'features/WithSideBar/types';
import appLocale from 'constants/appLocale';
import subsystemIdsMap from 'constants/subsystemIdsMap';
import { Askza } from '../../home/icons';

export const atmosphereMenu: RenderTree = {
  id: '/data/atmosphere',
  name: appLocale.subsystems.askza,
  url: '/data/atmosphere',
  subsystemId: subsystemIdsMap.atmosphere,
  homepageIcon: <Askza />,
  children: [
    {
      id: '/data/atmosphere/monitoring',
      url: '/data/atmosphere/monitoring',
      name: 'Данные мониторинга',
      subsystemId: subsystemIdsMap.atmosphere,
    },
  ],
};
