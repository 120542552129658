import { FormWrapper, Input, Select } from 'components/common';
import { Field, useFormikContext } from 'formik';
import React, { FC, useMemo } from 'react';
import { NSIEmissionsListItem } from 'pages/dictionaries/NSIEmissions/consts/types';
import { TDictionariesArray } from 'models';
import { getSelectOptionsFromDictionaryByName } from 'features/get-select-options-from-dictionary';
import { Box, Grid } from '@chakra-ui/react';

interface IProps {
  dictionaries: {
    data: TDictionariesArray;
  } | null;
}
export const LocationTab: FC<IProps> = ({ children, dictionaries }) => {
  const { setFieldValue, values, errors } = useFormikContext<NSIEmissionsListItem>();
  const okrugs = useMemo(() => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'okrugs'), [dictionaries]);
  const rayons = useMemo(() => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'rayons'), [dictionaries]);

  return (
    <Box flexDirection="column">
      <FormWrapper>
        <Grid marginTop="30px" gap="15px" marginBottom={20}>
          <Field
            as={Select}
            name="okrugId"
            label="Округ"
            value={values.okrugId}
            options={okrugs}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              setFieldValue('okrugId', e.target.value);
            }}
            error={errors.okrugId}
            isRequired
          />

          <Field
            as={Select}
            name="rayonId"
            label="Район"
            value={values.rayonId}
            options={rayons}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              setFieldValue('rayonId', e.target.value);
            }}
            error={errors.rayonId}
            isRequired
          />

          <Field
            as={Input}
            name="address"
            label="Адрес"
            error={errors.address}
            isRequired
          />

          <Field
            as={Input}
            name="latitude"
            label="Широта"
            error={errors.latitude}
          />

          <Field
            as={Input}
            name="longitude"
            label="Долгота"
            error={errors.longitude}
          />

          {children}
        </Grid>
      </FormWrapper>
    </Box>
  );
};
