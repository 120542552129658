import * as yup from 'yup';
import { numberError, requiredError } from '../constans';

export const ParamsSchema = yup.object().shape({
  name: yup.string().nullable().required(requiredError),
  fullName: yup.string().nullable().required(requiredError),
  fieldType: yup.string().nullable().required(requiredError),
  code: yup.number().typeError(numberError),
  relations: yup.array().of(
    yup.object().shape({
      subsystemId: yup.string().nullable().required(requiredError),
      unitId: yup.string().nullable().required(requiredError),
      moduleId: yup.string().nullable().required(requiredError),
      accuracy: yup.number().typeError(numberError).nullable(),
    })
  ),
});
