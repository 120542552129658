import { Filter } from 'features/Filter';
import { createInitialValuesToFormik, createValuesToRequest } from 'features/Filter/utils';
import React, { useEffect, useMemo } from 'react';
import { IFilterItem, TDictionaryItemDictionary, TFilterDTO } from 'models';
import combineFilters from 'lib/utils/combineFilters';
import { transformFiltersArray } from 'features/Filter/transformFiltersArray';
import { NotificationType } from 'pages/admin/models/NotificationType';
import { NotificationSource } from 'pages/admin/models/NotificationSource';
import appLocale from 'constants/appLocale';
import { NotificationSettingsItem } from 'pages/admin/models/NotificationSettingsItem';
import { useRequest } from 'hooks';
import { TOption } from 'pages/admin/models/toption';
import { SubsystemItem } from 'pages/admin/models/SubsystemItem';
import { RoleItem } from 'pages/admin/models/RoleItem';

type FilterListInitialValues = Record<
  'sources' | 'methods' | 'deleted' | 'subsystemId' | 'roleId',
  TFilterDTO<keyof NotificationSettingsItem>['filters'][number]
>;

interface FilterProps {
  isLoading: boolean;
  onSubmit: (values: TFilterDTO<keyof NotificationSettingsItem>['filters']) => void;
  filters: IFilterItem[];
}

const initialValues: FilterListInitialValues = {
  roleId: {
    key: 'roleId',
    operation: 'EQ',
    value: null,
  },
  subsystemId: {
    key: 'subsystemId',
    operation: 'EQ',
    value: null,
  },
  sources: {
    key: 'sources',
    operation: 'IN',
    value: null,
  },
  methods: {
    key: 'methods',
    operation: 'IN',
    value: null,
  },
  deleted: {
    key: 'deleted',
    operation: 'EQ',
    value: false,
  },
};

const initialValuesToFormik = createInitialValuesToFormik(initialValues);

function NotificationFilter({ isLoading, onSubmit, children, filters }: React.PropsWithChildren<FilterProps>) {
  const methods = useMemo(() => {
    const map: TDictionaryItemDictionary[] = [];
    Object.entries(NotificationType).forEach((v) =>
      map.push({
        name: v[1],
        value: v[0],
      }));
    return map;
  }, []);

  const sources = useMemo(() => {
    const map: TDictionaryItemDictionary[] = [];
    Object.entries(NotificationSource).forEach((v) =>
      map.push({
        name: v[1],
        value: v[0],
      }));
    return map;
  }, []);

  const { result: roleList, get: getRoles } = useRequest<Record<'data', RoleItem[]>>('/admin/v10/admin/role');
  const { result: subsystems, get: getSubsystems } =
    useRequest<Record<'data', SubsystemItem[]>>('/admin/v10/admin/subsystem');

  useEffect(() => {
    getSubsystems();
    getRoles();
  }, [getRoles, getSubsystems]);

  const subsystemOptions: TOption[] =
    subsystems?.data.map(
      (s: SubsystemItem) =>
        ({
          value: s.id,
          name: `${s.name} ${s.module ? `(${s.module})` : ''}`,
        } as TOption),
    ) || [];

  const roles = roleList?.data.map(
    (r) =>
      ({
        name: `${r.name} (${r.code})`,
        value: r.id,
      } || []),
  );

  const presetValues = useMemo<{ key: string; value: IFilterItem['value'] }[]>(() => {
    const preset: { key: string; value: IFilterItem['value'] }[] = [];
    const initialFilters = combineFilters(initialValues, filters);
    Object.keys(initialFilters).forEach((key) => {
      preset.push({ key: `${key}`, value: initialFilters[key].value });
    });
    return preset;
  }, [filters]);

  return (
    <Filter<typeof initialValuesToFormik>
      isLoading={isLoading}
      isDisabled={isLoading}
      onSubmit={(values) => {
        const v = createValuesToRequest(initialValues, values);
        const filters = transformFiltersArray<TFilterDTO<keyof NotificationSettingsItem>['filters']>(Object.values(v));
        onSubmit(filters);
      }}
      initialValues={initialValuesToFormik}
      mainFields={[
        {
          type: 'SELECT',
          name: 'subsystemId',
          label: 'Подсистема',
          options: subsystemOptions,
        },
        {
          type: 'SELECT',
          name: 'roleId',
          label: 'Роль',
          options: roles,
        },
        {
          type: 'MULTI_SELECT',
          name: 'sources',
          label: 'Источник',
          options: sources,
        },
        {
          type: 'MULTI_SELECT',
          name: 'methods',
          label: 'Способ уведомления',
          options: methods,
        },
        {
          type: 'CHECKBOX',
          name: 'deleted',
          label: appLocale.common.showDeleted,
        },
      ]}
      presetValues={presetValues}
    >
      {children}
    </Filter>
  );
}

export { NotificationFilter };
