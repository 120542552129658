import { Field, useFormikContext } from 'formik';
import { IForecastAddItemEntity, IForecastMainForm } from 'pages/data/Meteo/Forecast/Data/consts/types';
import { Grid, Text } from '@chakra-ui/react';
import { Input } from 'components/common';
import { fieldsLocale } from 'pages/data/Meteo/Forecast/Data/consts/locale';

const ForecastItemAirTForm = ({ entity }: IForecastAddItemEntity): JSX.Element => {
  const { values, handleChange, errors } = useFormikContext<IForecastMainForm>();

  return (
    <>
      <Text fontSize="xl" mt="25">
        Температура воздуха
      </Text>
      <Grid marginTop="15px" gap="15px">
        <Field
          as={Input}
          name={`${entity}.maxInversionDegree`}
          label={fieldsLocale.maxInversionDegree}
          value={values[entity]?.maxInversionDegree}
          onChange={handleChange}
          error={errors[entity]?.maxInversionDegree}
        />
        <Field
          as={Input}
          name={`${entity}.inversionDegree`}
          label={fieldsLocale.inversionDegree}
          value={values[entity]?.inversionDegree}
          onChange={handleChange}
          error={errors[entity]?.inversionDegree}
        />
        <Field
          as={Input}
          name={`${entity}.minInversionOutsideCity`}
          label={fieldsLocale.minInversionOutsideCity}
          value={values[entity]?.minInversionOutsideCity}
          onChange={handleChange}
          error={errors[entity]?.minInversionOutsideCity}
        />
        <Field
          as={Input}
          name={`${entity}.maxInversionOutsideCity`}
          label={fieldsLocale.maxInversionOutsideCity}
          value={values[entity]?.maxInversionOutsideCity}
          onChange={handleChange}
          error={errors[entity]?.maxInversionOutsideCity}
        />
        <Field
          as={Input}
          name={`${entity}.minInversionInCity`}
          label={fieldsLocale.minInversionInCity}
          value={values[entity]?.minInversionInCity}
          onChange={handleChange}
          error={errors[entity]?.minInversionInCity}
        />
        <Field
          as={Input}
          name={`${entity}.maxInversionInCity`}
          label={fieldsLocale.maxInversionInCity}
          value={values[entity]?.maxInversionInCity}
          onChange={handleChange}
          error={errors[entity]?.maxInversionInCity}
        />
        <Field
          as={Input}
          name={`${entity}.inversionDuration`}
          label={fieldsLocale.inversionDuration}
          value={values[entity]?.inversionDuration}
          onChange={handleChange}
          error={errors[entity]?.inversionDuration}
        />
        <Field
          as={Input}
          name={`${entity}.thermalStratificationType`}
          label={fieldsLocale.thermalStratificationType}
          value={values[entity]?.thermalStratificationType}
          onChange={handleChange}
          error={errors[entity]?.thermalStratificationType}
        />
        <Field
          as={Input}
          name={`${entity}.stirringIntensity`}
          label={fieldsLocale.stirringIntensity}
          value={values[entity]?.stirringIntensity}
          onChange={handleChange}
          error={errors[entity]?.stirringIntensity}
        />
      </Grid>
    </>
  );
};

export { ForecastItemAirTForm };
