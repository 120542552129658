import { Box, BoxProps } from '@chakra-ui/react';

const ErrorTableTdBackdrop = (props: BoxProps) => (
  <Box
    position="absolute"
    background="rgba(255, 52, 52, 8%)"
    top="1px"
    right="1px"
    bottom="1px"
    left="1px"
    display="flex"
    align-items="center"
    width="fit-content"
    max-width="100%"
    pointerevents="none"
    fontSize="16px"
    {...props}
  />
);

export { ErrorTableTdBackdrop };
