import { useFormikContext } from 'formik';
import { DefaultText, FieldsFormControls, FormHeader, LoaderItem } from 'components/common';
import { FieldFormProps } from 'types/forms';
import React, { useMemo } from 'react';
import { Box, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import appLocale from 'constants/appLocale';
import LocationFormTab from 'components/common/LocationFormTab/LocationFormTab';
import { getSelectOptionsFromDictionaryByName } from 'features/get-select-options-from-dictionary';
import { NSIReagentsListItem } from 'pages/dictionaries/NSIReagents/types';
import NSIReagentsTreesGeneralInformationTab from 'pages/dictionaries/NSIReagents/NSIReagentsTrees/NSIReagentsTreesForms/NSIReagentsTreesGeneralInformationTab';
import { locationFieldKeys } from 'pages/dictionaries/NSIReagents/NSIReagentsTrees/locationFieldKeys';

const NSIReagentsTreesFormFields = ({
  onCancel,
  dictionaries,
  isLoading,
  isEditMode,
  editedObject,
}: FieldFormProps) => {
  const { handleSubmit } = useFormikContext();

  const title = isEditMode ? 'Редактирование источника' : 'Добавление источника';

  const okrugs = useMemo(() => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'okrugs'), [dictionaries]);
  const rayons = useMemo(() => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'rayons'), [dictionaries]);

  if (isLoading) {
    return <LoaderItem />;
  }

  return (
    <Box flexDirection="column">
      <Tabs>
        <FormHeader onBackClick={onCancel} header={title}>
          <TabList>
            {[appLocale.common.generalInformation, appLocale.common.location].map((tab, i) => (
              <Tab key={i}>
                <DefaultText cursor="pointer">{tab}</DefaultText>
              </Tab>
            ))}
          </TabList>
        </FormHeader>
        <TabPanels>
          <TabPanel>
            <NSIReagentsTreesGeneralInformationTab
              dictionaries={dictionaries}
              editedObject={editedObject}
              isEditMode={isEditMode}
            >
              <FieldsFormControls onCancel={onCancel} handleSubmit={handleSubmit} />
            </NSIReagentsTreesGeneralInformationTab>
          </TabPanel>
          <TabPanel>
            <LocationFormTab<NSIReagentsListItem> okrugs={okrugs} rayons={rayons} showFieldKeys={locationFieldKeys}>
              <FieldsFormControls onCancel={onCancel} handleSubmit={handleSubmit} showSave />
            </LocationFormTab>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default NSIReagentsTreesFormFields;
