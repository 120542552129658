import React, { useMemo } from 'react';
import { DefaultText, FormHeader, LoaderItem } from 'components/common';
import { Box, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { FieldArray } from 'formik';
import { getSelectOptionsFromDictionaryByName } from 'features/get-select-options-from-dictionary';
import { TDictionariesArray } from 'models/dictionaries';
import MastYTab from 'pages/data/Meteo/AISWeather/AISWeather/Forms/Tabs/MastYTab';
import TowerTab from 'pages/data/Meteo/AISWeather/AISWeather/Forms/Tabs/TowerTab';
import ASTab from 'pages/data/Meteo/AISWeather/AISWeather/Forms/Tabs/ASTab';
import subsystemIdsMap from 'constants/subsystemIdsMap';
import { useAvailability } from 'hooks';
import { AISTabs, AISTabsNames } from 'pages/data/Meteo/AISWeather/AISWeather/const';

interface AISWeatherFieldFormProps {
  onCancel: () => void;
  dictionaries: {
    data: TDictionariesArray;
  } | null;
  isLoading: boolean | undefined;
  isEdit: boolean;
}

export default function FieldsForm({
  dictionaries,
  onCancel,
  isLoading,
  isEdit,
}: AISWeatherFieldFormProps) {
  const { isAvailable } = useAvailability(subsystemIdsMap.meteoRoot);

  const stations = useMemo(
    () => new Map(getSelectOptionsFromDictionaryByName(dictionaries?.data, 'stations').map((v) => [v.name, v.value])),
    [dictionaries],
  );

  if (isLoading) {
    return <LoaderItem />;
  }

  return (
    <Box flexDirection="column">
      <Tabs>
        <FormHeader
          onBackClick={onCancel}
          header={`${isEdit ? 'Редактирование' : 'Добавление'} результатов обследования`}
        >
          <TabList>
            {AISTabs.map((value, index) => (
              <Tab key={index}>
                <DefaultText cursor="pointer">{AISTabsNames.get(value)}</DefaultText>
              </Tab>
            ))}
          </TabList>
        </FormHeader>
        <FieldArray name="stations">
          {() => (
            <TabPanels>
              {stations.size > 0 &&
                AISTabs.map((value, index) => {
                  switch (value) {
                    case 'АИС Погода':
                      return (
                        <TabPanel key={index}>
                          <ASTab
                            isAvailable={isAvailable}
                            onCancel={onCancel}
                            name="АИС Погода, сводные"
                            stationIndex={index}
                            stationId={stations.get(value) || ''}
                            dictionaries={dictionaries}
                            isEdit={isEdit}
                          />
                        </TabPanel>
                      );
                    case 'АИС Погода, башня Останкино':
                      return (
                        <TabPanel key={index}>
                          <TowerTab
                            isAvailable={isAvailable}
                            name="АИС Погода, с башни по высотам"
                            stationIndex={index}
                            stationId={stations.get(value) || ''}
                            dictionaries={dictionaries}
                            isEdit={isEdit}
                          />
                        </TabPanel>
                      );
                    default:
                      return (
                        <TabPanel key={index}>
                          <MastYTab
                            isAvailable={isAvailable}
                            name={value}
                            stationIndex={index}
                            stationId={stations.get(value) || ''}
                            dictionaries={dictionaries}
                            isEdit={isEdit}
                          />
                        </TabPanel>
                      );
                  }
                })}
            </TabPanels>
          )}
        </FieldArray>
      </Tabs>
    </Box>
  );
}
