export const askzvLocales = {
  title: 'АСКЗВ',
  filters: {
    dateStart: 'От',
    dateEnd: 'До',
    sourceId: 'Станция',
    parameter: 'Параметр',
    version: 'Версия',
    packageNumber: 'Номер пакета',
    stationNumber: '№ станции',
    stationName: 'Наименование станции',
    value: 'Значение'
  },
  packageNumber: 'Номер пакета',
  datetime: 'Дата и время',
  processingDatetime: 'Дата и время обработки',
  measuringDatetime: 'Дата и время измерения',
  stationNumber: '№ станции',
  //  набор столбцов для измерений
  stationName: 'Наименование станции',
  longitude: 'Долгота',
  latitude: 'Широта',
  parameterName: 'Параметр',
  value: 'Значение',
  //  набор столбцов для пакетов
  waterTemperature: 'Температура воды',
  phIndex: 'Водородный показатель',
  dissolvedOxygen: 'Растворенный кислород',
  ironConcentration: 'Концентрация железа',
  manganeseConcentration: 'Концентрация марганца',
  ammoniumIonicConcentration: 'Концентрация ионов аммония, мг/л',
  chemicalOxygenDemand: 'ХПК, мгO/л',
  doorId: 'Вскрытие',
  electricalConductivity: 'Электропроводность, мСм/см',
  eventId: 'Причина отбора пробы',
  fireId: 'Пожар',
  flow: 'Расход (Q), л/мин',
  nitriteIonsConcentration: 'Концентрация нитрит-ионов, мг/л',
  phosphateIonsConcentration: 'Концентрация фосфат-ионов, мг/л',
  pressure1: 'Давление 1 (P1), кгс/см2',
  pressure2: 'Давление 2 (P2), кгс/см2',
  probeNumber: 'Номер пробы',
  registryId: 'Идентификатор записи',
  stationId: 'Наименование станции',
  waterLevel: 'Уровень воды (H), м',
};
