import { Flex } from '@chakra-ui/react';
import React from 'react';

interface IProps {
  data: boolean | null;
}

export const BooleanCell = ({ data }: IProps) => (
  <Flex flexDirection="column">
    <span>{data ? 'Да' : 'Нет'}</span>
  </Flex>
);
