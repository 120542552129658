const fieldsLocale: { [key: string]: string } = {
  meteorologicalSituation: 'Синоптическая ситуация, фронт',
  surfacePressure: 'Приземное давление, мб',
  cloudCoverage: 'Облачность',
  wetness: 'Влажность, %',
  activeWeatherIds: 'Осадки',
  phenomena: 'Явления',
  umcIdentificationId: 'Идентификация НМУ',
  windDirection: 'Направление ветра V0',
  windSpeed: 'Средняя скорость ветра V0',
  windFlawValues: 'Порывы ветра V0',
  windDirectionPSA: 'Направление ветра в ПСА (V925)',
  windSpeedPSA: 'Средняя скорость переноса в ПСА',
  maxInversionDegree: 'Max величина инверсии,  °C ',
  inversionDegree: 'Величина инверсии за городом, °C',
  minInversionOutsideCity: 'НГ (м) инверсии за городом 3ч',
  maxInversionOutsideCity: 'ВГ (м) инверсии за городом 3ч',
  minInversionInCity: 'НГ (м) инверсии в центре города',
  maxInversionInCity: 'ВГ (м) инверсии в центре города',
  inversionDuration: 'Продолжительность инверсии',
  thermalStratificationType: 'Тип термической стратификации',
  stirringIntensity: 'Интенсивность перемешивания',
};

const forecastLocales = {
  createDate: 'Дата внесения протокола',
  period: 'Период составления протокола',
  dateFrom: 'От',
  dateTo: 'До',
  packageNumber: 'Номер пакета данных',
  nominalDocNumber: 'Условный номер документа',
  forecastDate: 'Прогноз на дату (день)',
  forecastAnalysingStatus: 'Статус анализа прогноза',
  report: 'Отчет',
};

export { fieldsLocale, forecastLocales };
