import React, { useMemo } from 'react';
import { DateInput, FormWrapper, Input, Select } from 'components/common';

import { getSelectOptionsFromDictionaryByName } from 'features/get-select-options-from-dictionary';
import { Field, useFormikContext } from 'formik';
import { createDateFromResponse, createDateToRequestWithTimezone } from 'lib/create-date';
import { TDictionariesArray } from 'models/dictionaries';
import { NSIEmissionsListItem } from 'pages/dictionaries/NSIEmissions/consts/types';
import { Box, Grid } from '@chakra-ui/react';
import isRequiredInSchema from 'lib/utils/forms/isReqiuredInSchema';
import { NsiEmissionsSourcesSchema } from 'models/schemas/nsiEmissions/nsiEmissionsSources';

interface CommonTabProps {
  dictionaries: {
    data: TDictionariesArray;
  } | null;
  children: React.ReactNode;
  isEditMode?: boolean
}

export const GeneralInformationTab = ({ dictionaries, children, isEditMode }: CommonTabProps) => {
  const { setFieldValue, values, errors } = useFormikContext<NSIEmissionsListItem>();
  const industries = useMemo(() => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'industries'), [dictionaries]);

  const statuses = useMemo(() => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'statuses'), [dictionaries]);

  return (
    <Box flexDirection="column">
      <FormWrapper>
        <Grid marginTop="30px" gap="15px">
          <Field
            as={Input}
            name="name"
            label="Наименование"
            error={errors.name}
            isRequired={isRequiredInSchema(NsiEmissionsSourcesSchema, 'name')}
          />
          <Field
            as={Input}
            name="shortName"
            label="Краткое наименование"
            error={errors.shortName}
            isRequired={isRequiredInSchema(NsiEmissionsSourcesSchema, 'shortName')}
          />
          <Field
            as={Input}
            name="number"
            label="Номер"
            error={errors.number}
            isRequired={isRequiredInSchema(NsiEmissionsSourcesSchema, 'number')}
            isDisabled={isEditMode}
          />
          <Field
            as={Select}
            name="industryId"
            label="Предриятие размещения"
            value={values.industryId}
            options={industries}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              setFieldValue('industryId', e.target.value);
            }}
            error={errors.industryId}
            isRequired={isRequiredInSchema(NsiEmissionsSourcesSchema, 'industryId')}
          />
          <Field
            as={Select}
            name="status"
            label="Статус"
            value={values.status}
            options={statuses}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              setFieldValue('status', e.target.value);
            }}
            error={errors.status}
            isRequired={isRequiredInSchema(NsiEmissionsSourcesSchema, 'status')}
          />

          <DateInput
            name="startDate"
            label="Начало действия"
            value={createDateFromResponse(values.startDate)}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setFieldValue('startDate', createDateToRequestWithTimezone(e.target.value))
            }
            error={errors.startDate}
            isRequired={isRequiredInSchema(NsiEmissionsSourcesSchema, 'startDate')}
          />

          <DateInput
            name="stopDate"
            label="Окончание действия"
            value={createDateFromResponse(values.stopDate)}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setFieldValue('stopDate', createDateToRequestWithTimezone(e.target.value))
            }
            error={errors.stopDate}
            isRequired={isRequiredInSchema(NsiEmissionsSourcesSchema, 'stopDate')}
          />
          {children}
        </Grid>
      </FormWrapper>
    </Box>
  );
};
