import { Select } from 'components/common';
import { SelectValue } from 'components/common/Select/types';
import { FormikHandlers } from 'formik/dist/types';
import { IPeriod, periods } from '../consts';
import { useCallback, useMemo } from 'react';

const transformPeriod = (period: { name: string; value: string }) => ({ name: period.name, value: period.value });

interface ISelectPeriod {
  value: SelectValue;
  onChange: FormikHandlers['handleChange'];
  predicate: 'isShowInPeriod' | 'isShowInFilter';
}
export const SelectPeriod = ({ value, onChange, predicate }: ISelectPeriod) => {
  const filterPredicate = useCallback((i: IPeriod) => i[predicate], [predicate]);
  const options = useMemo(() => Object.values(periods)
    .filter(filterPredicate)
    .map(transformPeriod)
    .reverse(), [filterPredicate]);
  return <Select naturalSort={false} label="Выберите промежуток" value={value} onChange={onChange} options={options} />;
};
