import { ObjectType } from 'pages/admin/models/objectTypes';
import { ANALYTICS_STATUS } from 'pages/analytics/forms/const';

export interface IReportProtocolForm extends Omit<ReportProtocolReqDto, 'values'> {
  modelId?: string;
  taskId: string | null;
  parameters: IReportProtocolFormValue[];
  values: Record<string, IReportProtocolFormValue>;
  id?: string;
  protocolNumber?: string;
  sampleEndDatetime?: string | null;
  sampleStartDatetime?: string | null;
  protocolDate?: string | null;
  address?: string;
  samplingEndDatetime?: string;
  samplingStartDatetime?: string;
  signatories?: any;
  points?: any;
  purpose?: string;
  methodology?: string;
  sign?: {
    signers: ISigner[];
  } | null;
  links?: {
    links: ILink[];
  } | null;
  meteoValues?: any;
  customerId: string | null;
  contractorId: string | null;
  sampleReceiveDatetime: string | null;
  researchDateStart: string | null;
  researchDateEnd: string | null;
  researchObject: string;
  researchBasis: string;
  supportingDocuments: string;
  status: ANALYTICS_STATUS | null;
  laboratory: boolean;
  meta?: string;
  deviceId?: string;
}

export interface ISigner {
  name: string;
  position: string;
}

export interface ILink {
  content: string,
  position: string
}

export interface IReportProtocolFormValue extends Value {
  avg?: Value;
  error?: Value;
  values?: Value[];
}

/**
 * Протокол результата исследования
 * @export
 * @interface ReportProtocolReqDto
 */
export interface ReportProtocolReqDto {
  /**
   * true, если тип - \"ПЭЛ-АИ\"
   * @type {boolean}
   * @memberof ReportProtocolReqDto
   */
  ai?: boolean;
  /**
   * Организация, выполнившая отбор проб
   * @type {string}
   * @memberof ReportProtocolReqDto
   */
  contractorId?: string | null;
  /**
   * Заказчик
   * @type {string}
   * @memberof ReportProtocolReqDto
   */
  customerId?: string | null;
  /**
   *
   * @type {string}
   * @memberof ReportProtocolReqDto
   */
  extraInfoValue?: string;
  methodology?: string;
  /**
   *
   * @type {string}
   * @memberof ReportProtocolReqDto
   */
  methodologyValueId?: string;
  /**
   * Идентификатор места наблюдения
   * @type {string}
   * @memberof ReportProtocolReqDto
   */
  observationPointId?: string | null;
  /**
   * Дата и время окончания исследований
   * @type {string}
   * @memberof ReportProtocolReqDto
   */
  researchEndDatetime?: string | null;
  /**
   * Объект исследования
   * @type {string}
   * @memberof ReportProtocolReqDto
   */
  researchObject?: string;
  /**
   * Дата и время начала исследований
   * @type {string}
   * @memberof ReportProtocolReqDto
   */
  researchStartDatetime?: string | null;
  /**
   * Акт отбора проб
   * @type {string}
   * @memberof ReportProtocolReqDto
   */
  sampleActNumber?: string;
  /**
   * Точка проведения измерений
   * @type {string}
   * @memberof ReportProtocolReqDto
   */
  sampleAddress?: string;
  /**
   * Точка проведения измерений
   * @type {string}
   * @memberof ReportProtocolReqDto
   */
  samplePlace?: string;
  /**
   * Дата и время доставки пробы
   * @type {string}
   * @memberof ReportProtocolReqDto
   */
  sampleReceiveDatetime?: string | null;
  /**
   * Регистрационный номер пробы
   * @type {string}
   * @memberof ReportProtocolReqDto
   */
  sampleRegNumber?: string;
  /**
   * Показатели результата исследования
   * @type {Array<Value>}
   * @memberof ReportProtocolReqDto
   */
  values?: Array<Value>;
}

/**
 * Протокол с результатами исследования для отображения
 * @export
 * @interface ReportProtocolGetDto
 */
export interface ReportProtocolGetDto {
  points?: any;
  /**
   * Адрес
   * @type {string}
   * @memberof ReportProtocolGetDto
   */
  address?: string;
  /**
   * Тип протокола - ПЭЛ-АИ
   * @type {boolean}
   * @memberof ReportProtocolGetDto
   */
  ai?: boolean;
  /**
   * Организация заказчика
   * @type {string}
   * @memberof ReportProtocolGetDto
   */
  consumer?: string;
  /**
   * Район
   * @type {string}
   * @memberof ReportProtocolGetDto
   */
  district?: string;
  /**
   * Идентификатор района
   * @type {string}
   * @memberof ReportProtocolGetDto
   */
  districtId?: string;
  /**
   *
   * @type {string}
   * @memberof ReportProtocolGetDto
   */
  extraInfoValue?: string;
  /**
   * Идентификатор записи протокола
   * @type {string}
   * @memberof ReportProtocolGetDto
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof ReportProtocolGetDto
   */
  methodologyValueId?: string;
  /**
   * Тип объекта, к которому загружаются файлы
   * @type {string}
   * @memberof ReportProtocolGetDto
   */
  objectType?: ObjectType;
  /**
   * Место наблюдения
   * @type {string}
   * @memberof ReportProtocolGetDto
   */
  observationPoint?: string;
  /**
   * Идентификатор места наблюдения
   * @type {string}
   * @memberof ReportProtocolGetDto
   */
  observationPointId?: string;
  /**
   * Исполняющая организация
   * @type {string}
   * @memberof ReportProtocolGetDto
   */
  organization?: string;
  /**
   * Дата протокола
   * @type {string}
   * @memberof ReportProtocolGetDto
   */
  protocolDate?: string;
  /**
   * Номер протокола
   * @type {string}
   * @memberof ReportProtocolGetDto
   */
  protocolNumber?: string;
  /**
   * Округ
   * @type {string}
   * @memberof ReportProtocolGetDto
   */
  region?: string;
  /**
   * Идентификатор округа
   * @type {string}
   * @memberof ReportProtocolGetDto
   */
  regionId?: string;
  /**
   * Дата и время окончания исследований
   * @type {string}
   * @memberof ReportProtocolGetDto
   */
  researchEndDatetime?: string;
  /**
   * Дата и время начала исследований
   * @type {string}
   * @memberof ReportProtocolGetDto
   */
  researchStartDatetime?: string;
  /**
   * Акт отбора проб
   * @type {string}
   * @memberof ReportProtocolGetDto
   */
  sampleActNumber?: string;
  /**
   * Дата и время окончания измерений
   * @type {string}
   * @memberof ReportProtocolGetDto
   */
  sampleEndDatetime?: string;
  /**
   * Широта места исследования
   * @type {number}
   * @memberof ReportProtocolGetDto
   */
  sampleLatitude?: number;
  /**
   * Долгота места исследования
   * @type {number}
   * @memberof ReportProtocolGetDto
   */
  sampleLongitude?: number;
  /**
   * Дата и время доставки пробы
   * @type {string}
   * @memberof ReportProtocolGetDto
   */
  sampleReceiveDatetime?: string;
  /**
   * Регистрационный номер пробы
   * @type {string}
   * @memberof ReportProtocolGetDto
   */
  sampleRegNumber?: string;
  /**
   * Дата и время начала измерений
   * @type {string}
   * @memberof ReportProtocolGetDto
   */
  sampleStartDatetime?: string;
  /**
   * Идентификатор отчета/задания
   * @type {string}
   * @memberof ReportProtocolGetDto
   */
  taskId?: string;
  /**
   * Показатели результата исследования
   * @type {Array<Value>}
   * @memberof ReportProtocolGetDto
   */
  values?: Array<Value>;
  /**
   * Точка проведения измерений
   * @type {string}
   * @memberof ReportProtocolGetDto
   */
  samplePlace?: string;
  meteoValues?: any;
}

/**
 * Значение измерения показателя
 * @export
 * @interface Value
 */
interface Value {
  /**
   * Идентификатор записи значения
   * @type {string}
   * @memberof Value
   */
  id?: string;
  /**
   * Наименование параметра
   * @type {string}
   * @memberof Value
   */
  name?: string;
  /**
   * Идентификатор параметра
   * @type {string}
   * @memberof Value
   */
  parameterId?: string;
  /**
   * Тип параметра
   * @type {string}
   * @memberof Value
   */
  type?: TypeEnum;
  /**
   * Значение показателя
   * @type {any}
   * @memberof Value
   */
  value?: any;
}

export enum TypeEnum {
  ABS = <any>'ABS',
  MIN = <any>'MIN',
  MAX = <any>'MAX',
  AVG = <any>'AVG',
  AVGLESS = <any>'AVGLESS',
  AVGMORE = <any>'AVGMORE',
  MORE = <any>'MORE',
  LINK = <any>'LINK',
  GRAD = <any>'GRAD',
  PROG = <any>'PROG',
  NULL = <any>'NULL',
  LESS = <any>'LESS',
  ERR = <any>'ERR',
}
