import { Box, IconButton, Typography, useTheme } from '@material-ui/core';
import { ChevronLeft, ChevronRight, FirstPage, LastPage } from '@material-ui/icons';
import React from 'react';

export function TablePaginationActions({ limit, count, page, rowsPerPage, onPageChange, showTotalCount }: any) {
  const theme = useTheme();

  const handleFirstPageButtonClick = () => {
    onPageChange(limit, 0);
  };

  const handleBackButtonClick = () => {
    onPageChange(limit, page - 1);
  };

  const handleNextButtonClick = () => {
    onPageChange(limit, page + 1);
  };

  const handleLastPageButtonClick = () => {
    // @ts-ignore
    onPageChange(limit, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5, display: 'flex', alignItems: 'center' }}>
      <IconButton
        style={{ height: '30px' }}
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPage /> : <FirstPage />}
      </IconButton>
      <IconButton
        style={{ height: '30px' }}
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <ChevronRight /> : <ChevronLeft />}
      </IconButton>
      <Typography
        variant="caption"
        style={{
          lineHeight: '100%',
          opacity: 0.54
        }}
      >
        {limit * page + 1} - {limit * page + limit}{showTotalCount ? ` из ${count}` : ''}
      </Typography>
      <IconButton
        style={{ height: '30px' }}
        onClick={handleNextButtonClick}
        // @ts-ignore
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <ChevronLeft /> : <ChevronRight />}
      </IconButton>

      {showTotalCount && (
        <IconButton
          style={{ height: '30px' }}
          onClick={handleLastPageButtonClick}
          // @ts-ignore
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === 'rtl' ? <FirstPage /> : <LastPage />}
        </IconButton>
      )}

    </Box>
  );
}
