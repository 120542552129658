import { SUCCESS_MESSAGES } from 'constants/messages';
import { createTouchedErrors } from 'features/create-touched-errors';
import { FormikProvider, useFormik } from 'formik';
import { useNotifications } from 'hooks/useNotifications';
import { useRequest } from 'hooks/useRequest';

import { useHistory } from 'react-router-dom';
import FieldsForm from './FieldsForm';
import { useMemo, useState } from 'react';
import { IUploadMedia } from 'components/common/FileUploadDragAndDrop/types';
import { updateFiles } from 'api/services/media';
import { useObjectType } from 'hooks/useObjectType';
import { SoilFormValidationSchema } from 'models/schemas/soil/soil';
import { useEnhancedNotification } from 'hooks';
import { useGoBackWithFallback } from 'lib/utils/goBackWithFallback';
import { useDictionary } from 'hooks/useDictionary';

export const emptyInitialValues = {
  id: null,
  probeDate: '',
  protocolDate: null,
  sourceId: null,
  protocolNumber: '',
  parameters: {},
};

export const AddSoilResult = (): JSX.Element => {
  const { backWithFallback } = useGoBackWithFallback();
  const { isDictionariesLoading, dictionaries } = useDictionary({ url: '/soilpollution/v10/soilpollution/dictionaries' });
  const history = useHistory();
  const { successNotify } = useNotifications();
  const { onErrorCreate } = useEnhancedNotification();
  const [media, setMedia] = useState<IUploadMedia[]>([]);
  const objectType = useObjectType(dictionaries);

  const onSuccess = (response: string | null) => {
    if (!response) {
      onErrorCreate();
      return;
    }

    successNotify({
      key: 'file/create/success',
      message: SUCCESS_MESSAGES.CREATE,
    });

    updateFiles(response, objectType, media)
      .then(() => {
        backWithFallback('/data/soil');
      })
      .catch((error: Error) => {
        onErrorCreate(error.message);
        history.replace(`/data/soil/edit/row/${response}?openAttachmentsTab=true`, response);
      });
  };

  const { post, isLoading } = useRequest<string, any>('/soilpollution/v10/soilpollution', onSuccess, onErrorCreate);

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    initialValues: emptyInitialValues,
    validationSchema: SoilFormValidationSchema(),
    onSubmit: (values) => post(values),
  });

  const errors = useMemo<typeof formik.errors>(() => {
    const e = createTouchedErrors(formik.errors, formik.touched);
    if (e.parameters) {
      if (!formik.submitCount) {
        Object.keys(e.parameters).forEach((id) => {
          // @ts-ignore
          if (formik.touched?.parameters && !formik.touched?.parameters[id]) {
            // @ts-ignore
            delete e.parameters[id];
          }
        });
      }

      Object.keys(e.parameters).forEach((id) => {
        // @ts-ignore
        if (e.parameters[id] && typeof e.parameters[id] !== 'string') {
          // @ts-ignore
          e.parameters[id] = e.parameters[id][0].value;
        }
      });
    }
    return e;
  }, [formik.touched, formik.errors, formik.submitCount]);

  return (
    <FormikProvider
      value={{
        ...formik,
        errors
      }}
    >
      <FieldsForm
        dictionaries={dictionaries}
        onCancel={() => {
          backWithFallback('/data/soil');
        }}
        isLoading={isDictionariesLoading || isLoading}
        media={media}
        onMediaChange={setMedia}
      />
    </FormikProvider>
  );
};
