import { Stack } from '@chakra-ui/react';
import { Input, Select } from 'components/common';
import { dictParametersToMap } from 'features/parametersMap';
import { Field, useFormikContext } from 'formik';
import { concat, get } from 'lodash';
import { TLawnsDictionaries } from 'pages/data/GreenPlants/Lawns/useLawnsDictionaries';
import React, { useCallback } from 'react';
import { TGreenPlantsForm } from 'pages/data/GreenPlants/Form/types';
import { ILawnsTabPanelProps } from './types';
import { getDictionariesIds, getDictionaryId } from 'pages/data/GreenPlants/utils';
import { getSelectOptionsFromDictionaryByName } from '../../../../../../features/get-select-options-from-dictionary';

interface TLawnsDamageFormProps {
  entity: ILawnsTabPanelProps['entity'];
  isLoading: ILawnsTabPanelProps['isLoading'];
  areaParameters: TLawnsDictionaries['areaParameters'];
  lawnParameters: TLawnsDictionaries['lawnParameters'];
}

const LawnsDamageForm: React.FC<TLawnsDamageFormProps> = (
  {
    entity,
    isLoading,
    lawnParameters = [],
    areaParameters = [],
  }
) => {
  const { errors, ...ctx } = useFormikContext<TGreenPlantsForm>();

  const parameters = dictParametersToMap(concat(lawnParameters, areaParameters));

  const getOptionsByName = useCallback((name: string) => getSelectOptionsFromDictionaryByName(
    [...lawnParameters, ...areaParameters],
    name,
    false,
    get(ctx.values, `${entity}.parameters.${name}.ids`)
  ), [areaParameters, ctx.values, entity, lawnParameters]);

  return (
    <Stack>
      <Field
        isDisabled={isLoading}
        as={Select}
        options={getOptionsByName('diseases')}
        name={`${entity}.parameters.diseases.dictionaryItemId`}
        label={parameters.diseases?.title}
        multiple
        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
          if (Array.isArray(e.target.value)) {
            ctx.setFieldValue(
              `${entity}.parameters.diseases.dictionaryItemId`,
              getDictionariesIds(e.target.value, parameters.diseases.values)
            );
            ctx.setFieldValue(`${entity}.parameters.diseases.value`, e.target.value);
            ctx.setFieldValue(`${entity}.parameters.diseases.parameterId`, parameters.diseases?.parameterId);
          }
        }}
      />
      <Field
        isDisabled={isLoading}
        as={Input}
        name={`${entity}.parameters.howManyDiseases.value[0]`}
        label={parameters.howManyDiseases?.title}
        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
          if (e?.target) {
            ctx.setFieldValue(`${entity}.parameters.howManyDiseases.value`, [e.target.value]);
            ctx.setFieldValue(
              `${entity}.parameters.howManyDiseases.parameterId`,
              parameters.howManyDiseases?.parameterId
            );
          }
        }}
        type="number"
        // @ts-ignore
        error={errors.lawns?.parameters?.howManyDiseases?.value?.[0]}
      />
      <Field
        multiple
        isDisabled={isLoading}
        as={Select}
        options={getOptionsByName('pests')}
        name={`${entity}.parameters.pests.dictionaryItemId`}
        label={parameters.pests?.title}
        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
          if (Array.isArray(e.target.value)) {
            ctx.setFieldValue(
              `${entity}.parameters.pests.dictionaryItemId`,
              getDictionariesIds(e.target.value, parameters.pests.values)
            );
            ctx.setFieldValue(`${entity}.parameters.pests.value`, e.target.value);
            ctx.setFieldValue(`${entity}.parameters.pests.parameterId`, parameters.pests?.parameterId);
          }
        }}
      />
      <Field
        isDisabled={isLoading}
        as={Input}
        name={`${entity}.parameters.howManyPests.value[0]`}
        label={parameters.howManyPests?.title}
        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
          if (e?.target) {
            ctx.setFieldValue(`${entity}.parameters.howManyPests.value`, [e.target.value]);
            ctx.setFieldValue(`${entity}.parameters.howManyPests.parameterId`, parameters.howManyPests?.parameterId);
          }
        }}
        // @ts-ignore
        error={errors.lawns?.parameters?.howManyPests?.value?.[0]}
      />
      <Field
        multiple
        isDisabled={isLoading}
        as={Select}
        options={getOptionsByName('otherDamage')}
        name={`${entity}.parameters.otherDamage.dictionaryItemId`}
        label={parameters.otherDamage?.title}
        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
          if (Array.isArray(e.target.value)) {
            ctx.setFieldValue(
              `${entity}.parameters.otherDamage.dictionaryItemId`,
              getDictionariesIds(e.target.value, parameters.otherDamage.values)
            );
            ctx.setFieldValue(`${entity}.parameters.otherDamage.value`, e.target.value);
            ctx.setFieldValue(`${entity}.parameters.otherDamage.parameterId`, parameters.otherDamage?.parameterId);
          }
        }}
      />
      <Field
        multiple
        isDisabled={isLoading}
        as={Select}
        options={getOptionsByName('garbage')}
        name={`${entity}.parameters.garbage.dictionaryItemId`}
        label={parameters.garbage?.title}
        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
          if (Array.isArray(e.target.value)) {
            ctx.setFieldValue(
              `${entity}.parameters.garbage.dictionaryItemId`,
              getDictionariesIds(e.target.value, parameters.garbage.values)
            );
            ctx.setFieldValue(`${entity}.parameters.garbage.value`, e.target.value);
            ctx.setFieldValue(`${entity}.parameters.garbage.parameterId`, parameters.garbage?.parameterId);
          }
        }}
      />

      <Field
        isDisabled={isLoading}
        as={Select}
        options={getOptionsByName('garbageScope')}
        name={`${entity}.parameters.garbageScope.dictionaryItemId[0]`}
        label={parameters.garbageScope?.title}
        multiple={false}
        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
          if (e?.target) {
            ctx.setFieldValue(`${entity}.parameters.garbageScope.dictionaryItemId`, [
              getDictionaryId(e.target.value, parameters.garbageScope.values),
            ]);
            ctx.setFieldValue(`${entity}.parameters.garbageScope.value`, [e.target.value]);
            ctx.setFieldValue(`${entity}.parameters.garbageScope.parameterId`, parameters.garbageScope?.parameterId);
          }
        }}
      />
    </Stack>
  );
};

export { LawnsDamageForm };
