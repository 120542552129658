import React, { useMemo } from 'react';
import { DateInput, FormWrapper, Input, Select } from 'components/common';
import { Box, Grid, Heading } from '@chakra-ui/react';
import {
  getSelectOptionsFromDictionaryByName,
  getSelectRichOptionsFromDictionaryByName,
} from 'features/get-select-options-from-dictionary';
import { Field, useFormikContext } from 'formik';
import { createDateFromResponse, createDateToRequestWithTimezone } from 'lib/create-date';
import { TDictionariesArray } from 'models/dictionaries';
import { COMMON_LOCALE } from 'constants/common';
import { fieldsLocale, SoilDeicingItem } from '../../consts';
import { getFilteredOptionsByPrivileges } from 'features/getFilteredOptionsByPrivileges';

interface CommonTabProps {
  dictionaries: {
    data: TDictionariesArray;
  } | null;
  isEditMode?: boolean;
}

export const CommonTab: React.FC<CommonTabProps> = ({ dictionaries, isEditMode = false }) => {
  const { setFieldValue, values, errors } = useFormikContext<SoilDeicingItem>();

  const okrugs = useMemo(() => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'okrugs'), [dictionaries]);

  const filteredRayons = useMemo(() => {
    const rayons = getSelectOptionsFromDictionaryByName(dictionaries?.data, 'rayons');
    if (values.okrugId) {
      return rayons.filter((item) => item.parent === values.okrugId);
    }
    return rayons;
  }, [dictionaries, values]);

  const filteredAreas = useMemo(() => {
    const areas = getFilteredOptionsByPrivileges(
      getSelectRichOptionsFromDictionaryByName(dictionaries?.data, 'areas'),
      'isEditable', 'value', values.sourceNameId
    ).map(({ title: name, ...item }) => ({
      ...item,
      name: String(name),
    }));

    if (values.rayonId) {
      return areas.filter((item) => item.parent === values.rayonId);
    }
    return areas;
  }, [dictionaries, values]);

  const distances2RoadExt = useMemo(
    () =>
      getSelectOptionsFromDictionaryByName(dictionaries?.data, 'distances2RoadExt').filter(
        (item) => item.parent === values.sourceNameId,
      ),
    [dictionaries, values.sourceNameId],
  );

  const depthsExt = getSelectOptionsFromDictionaryByName(dictionaries?.data, 'depthsExt').filter(
    (item) => item.parent === values.distance2RoadId,
  );

  return (
    <Box flexDirection="column">
      <FormWrapper>
        <Grid marginTop="30px" gap="15px">
          <Heading size="md">{COMMON_LOCALE.commonData}</Heading>
          <DateInput
            name="selectionDate"
            label={fieldsLocale.selectionDate}
            value={createDateFromResponse(values.selectionDate)}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setFieldValue('selectionDate', createDateToRequestWithTimezone(e.target.value))
            }
            showTimeInput={false}
            isDisabled={isEditMode}
            error={errors?.selectionDate}
          />
          <Field
            as={Input}
            label={fieldsLocale.protocolNumber}
            name="protocol"
            value={values.protocol}
            disabled={isEditMode}
            error={errors?.protocol}
          />

          <Field
            as={Select}
            name="okrugId"
            label={fieldsLocale.region}
            options={okrugs}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              setFieldValue('okrugId', e.target.value);
              setFieldValue('rayonId', null);
              if (!e.target.value) {
                setFieldValue('sourceName', '');
                setFieldValue('address', '');
                setFieldValue('territoryType', '');
              }
            }}
            isDisabled={values.sourceNameId}
            error={errors?.okrugId}
          />

          <Field
            as={Select}
            name="rayonId"
            options={filteredRayons}
            label={fieldsLocale.district}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              const okrugId = filteredRayons.find((item) => item.value === e.target.value)?.parent || '';

              setFieldValue('rayonId', e.target.value);
              setFieldValue('okrugId', okrugId);

              if (!e.target.value) {
                setFieldValue('sourceName', '');
                setFieldValue('address', '');
                setFieldValue('territoryType', '');
              }
            }}
            isDisabled={values.sourceNameId}
            error={
              errors?.rayonId || (filteredAreas.length === 0 && 'В выбранном районе отсутствуют площадки наблюдения')
            }
          />

          <Field
            as={Select}
            name="sourceNameId"
            label={fieldsLocale.territoryNumber}
            options={filteredAreas}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              const areaItem = filteredAreas.find((el) => el.value === e.target.value);
              const okrugId = filteredRayons.find((item) => item.value === areaItem?.parent)?.parent || '';
              const rayonId = filteredRayons.find((item) => item.value === areaItem?.parent)?.value || '';

              setFieldValue('sourceNameId', areaItem?.value);
              setFieldValue('okrugId', okrugId);
              setFieldValue('rayonId', rayonId);
              setFieldValue('address', areaItem?.address || '');
              setFieldValue('territoryType', areaItem?.territoryType || '');
              setFieldValue('depthId', null);
              setFieldValue('distance2RoadId', null);
            }}
            isDisabled={isEditMode}
            error={errors?.sourceNameId}
          />

          {/* This fields are dependent on sourceName field and is set by it */}
          <Field as={Input} name="address" label={fieldsLocale.address} disabled />
          <Field as={Input} name="territoryType" label={fieldsLocale.territoryType} disabled />

          <Field
            as={Select}
            name="distance2RoadId"
            options={distances2RoadExt}
            label={fieldsLocale.distance2Road}
            isDisabled={isEditMode || !values.sourceNameId}
            error={errors?.distance2RoadId}
          />
          <Field
            as={Select}
            name="depthId"
            options={depthsExt}
            label={fieldsLocale.depth}
            isDisabled={isEditMode || !values.distance2RoadId}
            error={errors?.depthId}
          />
        </Grid>
      </FormWrapper>
    </Box>
  );
};
