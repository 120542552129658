import { Flex, Grid } from '@chakra-ui/react';
import { DateInput, Input, Select } from 'components/common';
import React, { useEffect } from 'react';
import { Field, FormikProvider, useFormik } from 'formik';
import { IFilterItem, TDictionaryItemDictionary } from 'models';
import { createLocalDateISO8601 } from 'lib/create-date';
import { FiltersDatepickersSchema } from 'models/schemas/filtersDatepickersSchema/FiltersDatepickersSchema';
import FilterFormControls from 'components/form/FilterFormControls';
import combineFilters from 'lib/utils/combineFilters';

interface IProps {
  districts?: TDictionaryItemDictionary[];
  regions?: TDictionaryItemDictionary[];
  statuses?: TDictionaryItemDictionary[];
  stations?: TDictionaryItemDictionary[];
  setFilters: (filters: any) => void;
  isLoading: boolean;
  filters: IFilterItem[];
}

const filterInitValues: Record<string, IFilterItem> = {
  startDate: {
    key: 'startDate',
    operation: 'GTE',
    value: null,
  },
  stopDate: {
    key: 'stopDate',
    operation: 'LTE',
    value: null,
  },
  number: {
    key: 'number',
    operation: 'EQ',
    value: '',
  },
  stationId: {
    key: 'stationId',
    operation: 'EQ',
    value: '',
  },
  okrug: {
    key: 'okrug',
    operation: 'EQ',
    value: '',
  },
  rayon: {
    key: 'rayon',
    operation: 'EQ',
    value: '',
  },
  address: {
    key: 'address',
    operation: 'CONTAINS',
    value: '',
  },
  status: {
    key: 'status',
    operation: 'EQ',
    value: '',
  },
  deleted: {
    key: 'deleted',
    operation: 'EQ',
    value: false,
  },
};

export const AtmosphereAirFilter = ({
  districts,
  regions,
  statuses,
  setFilters,
  stations,
  isLoading,
  filters,
}: IProps) => {
  useEffect(() => {
    const initialFilters = combineFilters(filterInitValues, filters);
    Object.keys(initialFilters)
      .forEach((key) => {
        setFieldValue(`${key}.value`, initialFilters[key].value);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = (values: any) => {
    const filters = Object.values(values);
    setFilters(filters);
  };

  const {
    resetForm,
    values,
    errors,
    getFieldMeta,
    getFieldHelpers,
    getFieldProps,
    handleReset,
    submitForm,
    setFieldValue,
    ...formik
  } = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    validationSchema: FiltersDatepickersSchema([{ dateFromFieldName: 'startDate', dateToFieldName: 'stopDate' }]),
    initialValues: filterInitValues,
    onSubmit,
  });

  return (
    <FormikProvider
      value={{
        resetForm,
        values,
        errors,
        getFieldMeta,
        getFieldHelpers,
        getFieldProps,
        handleReset,
        submitForm,
        setFieldValue,
        ...formik,
      }}
    >
      <Flex>
        <Grid width="100%" maxWidth="1200px" gap={2} templateColumns="repeat(4, 1fr)">
          <Field as={Select} name="stationId.value" label="Наименование станции" options={stations} />

          <Field as={Input} name="number.value" label="Номер станции" />

          <Field as={Select} name="okrug.value" label="Округ" options={regions} />

          <Field as={Select} name="rayon.value" label="Район" options={districts} />

          <Field as={Input} name="address.value" label="Адрес" />

          <Field as={Select} name="status.value" label="Статус" options={statuses} />

          <Field
            as={DateInput}
            name="startDate.value"
            label="Начало действия"
            // value={createDateFromResponse(values.startDate.value)}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFieldValue('startDate.value', createLocalDateISO8601(e.target.value));
            }}
            error={errors.startDate?.value}
          />

          <Field
            as={DateInput}
            name="stopDate.value"
            label="Окончание действия"
            // value={createDateFromResponse(values.stopDate.value)}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFieldValue('stopDate.value', createLocalDateISO8601(e.target.value));
            }}
            error={errors.stopDate?.value}
          />
        </Grid>

        <FilterFormControls
          onReset={() => {
            resetForm();
            setFilters([{ key: 'deleted', operation: 'EQ', value: false }]);
          }}
          onSubmit={submitForm}
          isLoading={isLoading}
          isDisabled={isLoading}
        />
      </Flex>
    </FormikProvider>
  );
};
