import { Msz4 } from 'pages/data/Meteo/Msz4/Msz4';
import { AddResult } from 'pages/data/Meteo/Msz4/Forms/AddResult';
import { WithDidMountResetUploadFile } from 'features/UploadedFileProvider/hocs';
import { Route } from 'react-router-dom';

const MeteoMsz4Routes = () => (
  <>
    <Route exact path="/data/meteo/data/msz4" render={() => <WithDidMountResetUploadFile Component={Msz4} />} />

    <Route exact path="/data/meteo/data/msz4/add" render={() => <WithDidMountResetUploadFile Component={AddResult} />} />
  </>
);

export { MeteoMsz4Routes };
