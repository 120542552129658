import { SubsystemHeader } from 'components/common/SubsystemHeader';
import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import { AnalyticReportsDutyOfficeBreadcrumb } from 'pages/analyticsReports/consts/Breadcrumbs';
import subsystemIdsMap from 'constants/subsystemIdsMap';
import React, { useCallback, useMemo } from 'react';
import { Column } from '@material-table/core';
import { ISOKFireJournalListItem } from 'pages/analyticsReports/Reports/SOKReportList/SOKFireJournalReport/types';
import { useList, useRequest } from 'hooks';
import { MaterialTable } from 'features/material-table/MaterialTableMini';
import { addFiltersToColumns, ColumnWithFilter } from 'lib/utils/tableFilter';
import { createLocalDateDDMMYYYY, createLocalDateDDMMYYYYWithTime } from 'lib/create-date';
import { useDictionary } from 'hooks/useDictionary';
import { SOKFireJournalReportFilter } from './SOKFireJournalReportFilter';
import { ExtensionToMimeTypeEnum } from 'lib/utils/files/getFileExtensionByMimeType';
import { downloadFileFromResponse } from 'lib/utils/files/downloadFile';
import appLocale from 'constants/appLocale';
import { SendToMailModal } from 'components/common/GraphicDropdownMenu/SendToMailModal';
import { useDisclosure } from '@chakra-ui/react';
import { DEFAULT_PAGE_SIZE_NEW_TABLE } from '../../../../../constants/tables';
import { FullPageWrapper } from 'components/table/FullPageWrapper';
import { DefaultTableCellSize } from 'components/table/DefaultTableCellSize';

const SOKFireJournalReport = () => {
  useUpdateBreadcrumbs([
    ...AnalyticReportsDutyOfficeBreadcrumb,
    {
      breadcrumb: 'Отчет по выявленным при помощи видеокамер фактам сжигания!',
      path: `/reports-analytics/duty-office/${subsystemIdsMap.dutyOfficer}/fire-journal`,
    },
  ]);

  const { identifyInfo, admAreas, districts, executors, cameraTypes } = useDictionary({
    url: '/appeal/v10/fire/journal/dictionaries',
  });

  const {
    response,
    isLoading: isListLoading,
    filterDTO,
    toggleOrder,
    setFilters,
    onPageChange,
    saveFilters,
    onSearchText,
    errorText,
    selectedIndexes,
    renderOnlySelectedCheckbox,
    onSubmitFilters,
    onSelectionChange,
    getHeaders,
    headers,
    resetHeaders,
    materialTableProps,
  } = useList<ISOKFireJournalListItem, keyof ISOKFireJournalListItem>('/appeal/v10/fire/journal/list', {
    initialOrderFieldKey: '-date',
    exportSubsystemName: 'fire_journal',
    selectionKey: 'id',
    saveFiltersState: true,
    defaultPageSize: DEFAULT_PAGE_SIZE_NEW_TABLE,
    resetDataOnNextRequest: true,

  });

  const { post: download } = useRequest('');
  const downloadAsFile = useCallback(
    async (fileType: ExtensionToMimeTypeEnum) => {
      const response = await download(
        { ...filterDTO, limit: -1, offset: 0 },
        {
          url:
            fileType === ExtensionToMimeTypeEnum.xlsx
              ? '/appeal/v10/fire/journal/export'
              : '/appeal/v10/fire/journal/list',
          headers: { ACCEPT: fileType },
          responseType: 'blob',
        }
      );
      downloadFileFromResponse({ response, name: 'export_sok_journal', addDate: true, type: fileType });
    },
    [download, filterDTO]
  );

  const columns = useMemo<ColumnWithFilter<ISOKFireJournalListItem>[]>(
    () => [
      {
        title: 'Дата',
        field: 'date',
        render: (row) => createLocalDateDDMMYYYY(row.date)?.split(' ')[0],
      },
      {
        title: 'Время начала фиксации',
        field: 'start',
        render: (row) => createLocalDateDDMMYYYYWithTime(row.start)?.split(' ')[1],
      },
      {
        title: 'Время окончания фиксации',
        field: 'stop',
        render: (row) => createLocalDateDDMMYYYYWithTime(row.stop)?.split(' ')[1],
      },
      {
        title: 'Вид камеры',
        field: 'cameraType',
        render: (row) => cameraTypes?.find((item) => item.value === row.cameraType)?.name,
      },
      {
        title: 'Название камеры видеонаблюдения и адрес ее расположения',
        field: 'cameraName',
      },
      {
        title: 'Округ',
        field: 'admAreaId',
        render: (row) => admAreas?.find((item) => item.value === row.admAreaId)?.name,
      },
      {
        title: 'Район',
        field: 'districtId',
        render: (row) => districts?.find((item) => item.value === row.districtId)?.name,
      },
      {
        title:
          'Ориентировочный адрес источника (где выявлен факт сжигания (строительных, коммунальных и т.д.), кадастровый номер участка',
        field: 'address',
      },
      {
        title: 'Широта',
        field: 'latitude',
      },
      {
        title: 'Долгота',
        field: 'longitude',
      },
      {
        title: 'Cжигание / задымление / следы сжигания',
        field: 'identifyInfoId',
        render: (row) => identifyInfo?.find((item) => item.value === row.identifyInfoId)?.name,
      },
      {
        title: 'Результаты патрулирования местности',
        field: 'actions',
        render: ({ actions }) => <DefaultTableCellSize>{actions}</DefaultTableCellSize>,
      },
      {
        title: 'Фотоизображение факта происшествия',
        field: 'hasFile',
        render: (row) => (row.hasFile ? 'Файл прикреплен' : 'Файл отсутствует'),
      },
      {
        title: 'Прочие предпринятые меры ',
        field: 'actionsOther',
      },
      {
        title: 'Информация направлена',
        field: 'informationRecipient',
      },
      {
        title: 'Ответственный исполнитель ',
        field: 'executorId',
        render: (row) => executors?.find((item) => item.value === row.executorId)?.name,
      },
      {
        title: 'Пометка "повторный факт"',
        field: 'isRepeat',
        render: (row) => (row.isRepeat ? 'Да' : 'Нет'),
      },
      {
        title: 'Примечание',
        field: 'comment',
      },
    ],
    [admAreas, cameraTypes, districts, executors, identifyInfo]
  );

  const columnsWithFilters = useMemo<Array<Column<ISOKFireJournalListItem>>>(
    () => addFiltersToColumns({ filters: filterDTO.filters, setFilters, columns }),
    [columns, filterDTO.filters, setFilters]
  );

  const uploadModalDisclosure = useDisclosure();

  return (
    <FullPageWrapper>
      <SubsystemHeader
        title={appLocale.analyticsReports.SOKFireJournal}
        filtersComponent={
          <SOKFireJournalReportFilter
            cameraTypes={cameraTypes}
            onSubmit={(values) => onSubmitFilters(values)}
            filters={filterDTO.filters}
            isLoading={isListLoading}
            admAreas={admAreas}
            districts={districts}
          >
            {renderOnlySelectedCheckbox}
          </SOKFireJournalReportFilter>
        }
      />

      <MaterialTable
        toggleOrder={toggleOrder}
        onPageChange={onPageChange}
        filterDTO={filterDTO}
        data={response?.data}
        meta={response?.meta}
        columns={columnsWithFilters}
        saveFilters={saveFilters}
        setFilters={setFilters}
        options={{
          search: true,
        }}
        onSearch={onSearchText}
        isLoading={isListLoading}
        errorText={errorText}
        selectedIndexes={selectedIndexes}
        onSelectionChange={onSelectionChange}
        downloadAsFile={{
          downloadAsFile,
          types: [ExtensionToMimeTypeEnum.xlsx, ExtensionToMimeTypeEnum.csv, ExtensionToMimeTypeEnum.pdf],
        }}
        getHeaders={getHeaders}
        headersXLSXUrl="/appeal/v10/fire/journal/export"
        onOpenUploadModal={uploadModalDisclosure.onOpen}
        {...materialTableProps}
      />
      <SendToMailModal
        onClose={uploadModalDisclosure.onClose}
        headers={headers}
        isOpen={uploadModalDisclosure.isOpen}
        resetHeaders={resetHeaders}
        filename={appLocale.analyticsReports.SOKFireJournal}
      />
    </FullPageWrapper>
  );
};

export default SOKFireJournalReport;
