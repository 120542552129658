import { useCallback, useMemo, useReducer, useState } from 'react';
import { TFilterDTO, TFiltersArray } from 'models';
import { DEFAULT_PAGE_SIZE } from 'constants/tables';

type Reducer<State, Action> = (state: State, action: Action) => State;

function getInitialState(initialState: any, initialOrderFieldKey?: string, initialFilters?: any) {
  if (initialFilters) {
    return initialFilters;
  }
  if (initialOrderFieldKey) {
    return {
      ...initialState,
      orders: initialOrderFieldKey ? [initialOrderFieldKey] : [],
    };
  }
  return initialState;
}

type FilterAction<T> =
  | { type: 'set_orders'; key: string }
  | {
  type: 'set_filters';
  filters: TFilterDTO<T>['filters'];
  initialState: TFilterDTO<T>;
}
  | {
  type: 'set_filters_reset_pagination';
  filters: TFilterDTO<T>['filters'];
  initialState: TFilterDTO<T>;
}
  | { type: 'clear'; initialState: TFilterDTO<T> }
  | { type: 'page_change'; page: number; pageSize: number }
  | { type: 'search_text'; searchFields: string[]; searchString: string };

export function changeOrder(orders: string[], key: string) {
  const _orders = new Set(orders);
  const descKey = `-${key}`;
  if (_orders.has(key)) {
    // asc -> desc
    _orders.delete(key);
    _orders.add(descKey);
  } else {
    // void -> asc
    _orders.add(key);
  }
  return [Array.from(_orders).reverse()[0]].filter(Boolean);
}

export function removeEmptyValuesFromFilter<T>(filters: TFilterDTO<T>['filters']) {
  return filters.filter((filterItem) => filterItem.value !== '');
}

function filterReducer<T>(state: TFilterDTO<T>, action: FilterAction<T>) {
  switch (action.type) {
    case 'set_orders': {
      return {
        ...state,
        // HACK: sort only by last field needs refactoring
        orders: changeOrder(state.orders, action.key),
      };
    }
    case 'set_filters': {
      return {
        ...state,
        filters: removeEmptyValuesFromFilter(action.filters),
      };
    }
    case 'set_filters_reset_pagination': {
      return {
        ...state,
        offset: 0,
        filters: removeEmptyValuesFromFilter(action.filters),
      };
    }
    case 'page_change': {
      return {
        ...state,
        limit: action.pageSize,
        offset: action.page * action.pageSize,
      };
    }
    case 'clear':
      return action.initialState;
    case 'search_text': {
      return {
        ...state,
        searchFields: action.searchFields,
        searchString: action.searchString,
      };
    }
  }
}

function useFilterDTO<T>(
  orderKey?: string,
  initialFilters?: any,
  options?: { defaultPageSize?: number, initialDeleted: boolean | null }
): {
  filterDTO: TFilterDTO<T>;
  toggleOrder: (key: string) => void;
  setFilters: (filters: TFilterDTO<T>['filters'], resetPagination?: boolean) => void;
  clear: (state?: any) => void;
  onPageChange: (page: number, pageSize: number) => void;
  onSearchText: (searchFields: string[], searchString: string) => void;
  onColumnHide: (column: string, hidden: boolean) => void;
  setHiddenColumns: (columns: string[]) => void;
  hiddenColumns: string[];
} {
  const [hiddenColumns, setHiddenColumns] = useState<string[]>([]);
  const initialState = useMemo(
    () => ({
      limit: options?.defaultPageSize || DEFAULT_PAGE_SIZE,
      offset: 0,
      filters: [
        {
          key: 'deleted',
          operation: 'EQ',
          value: options?.initialDeleted,
        },
      ],
      orders: [],
      searchFields: [],
      searchString: '',
    }),
    [options]
  );

  const [filterDTO, setFilterDTO] = useReducer<Reducer<TFilterDTO<T>, FilterAction<T>>>(
    filterReducer,
    getInitialState(initialState, orderKey, initialFilters)
  );

  const toggleOrder = useCallback(
    (key: string) => {
      setFilterDTO({
        type: 'set_orders',
        key,
      });
    },
    [setFilterDTO]
  );

  const setFilters = useCallback(
    (filters: TFiltersArray<T>, resetPagination?: boolean) => {
      const _filters = filters.map((filter) => {
        // для того чтобы пустой фильтр отправлялся как null иначе возвращает пустой список
        if (filter.operation === 'IN' && Array.isArray(filter.value) && filter.value.length === 0) {
          return {
            ...filter,
            value: null,
          };
        }
        return filter;
      });

      setFilterDTO({
        // on all filter changes pagination will be reset - set_filters to revert
        type: resetPagination ? 'set_filters_reset_pagination' : 'set_filters_reset_pagination',
        filters: _filters,
        initialState: getInitialState(initialState, orderKey),
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [orderKey]
  );

  const clear = useCallback((state?: any) => {
    setFilterDTO({
      type: 'clear',
      initialState: state || getInitialState(initialState, orderKey),
    });
  }, [orderKey, initialState]);

  const onPageChange = useCallback((page: number, pageSize: number) => {
    setFilterDTO({ type: 'page_change', page, pageSize });
  }, []);

  const onSearchText = useCallback((searchFields: string[], searchString: string) => {
    setFilterDTO({ type: 'search_text', searchFields, searchString });
  }, []);

  const onColumnHide = useCallback((column, hide) => {
    setHiddenColumns((prev) => {
      if (hide) {
        return [...prev, column];
      }
      return [...prev].filter((name) => name !== column);
    });
  }, [setHiddenColumns]);

  return {
    filterDTO,
    toggleOrder,
    clear,
    setFilters,
    onPageChange,
    onSearchText,
    onColumnHide,
    hiddenColumns,
    setHiddenColumns,
  };
}

export default useFilterDTO;
