import { Flex, Grid } from '@chakra-ui/react';
import { Field, FormikProvider, useFormik } from 'formik';
import { createTouchedErrors } from 'features/create-touched-errors';
import { Button, Select, SelectOption } from 'components/common';
import React, { useCallback, useMemo } from 'react';
import useASKZAEmissionsDictionaries
  from 'pages/analyticsReports/CombinedCharts/ASKZAEmissions/useASKZAEmissionsDictionaries';
import PeriodSelect, {
  LineChartDateForm,
} from 'pages/analyticsReports/CombinedCharts/LineChartComponents/PeriodSelect';
import { SchemaForCustomFilter } from 'models/schemas/filtersDatepickersSchema/FiltersDatepickersSchema';

export interface IASKZAEmissionsFilterForm extends LineChartDateForm {
  areaId: string | null;
  firstSourceId: string | null;
  firstFurnaceId: string | null;
  firstParameterId: string | null;
  secondSourceId: string | null;
  secondParameterId: string | null;
}

const initialEmptyValues: IASKZAEmissionsFilterForm = {
  dateFrom: null,
  dateTo: null,
  period: null,
  type: 'AVG',
  firstParameterId: null,
  firstFurnaceId: null,
  firstSourceId: null,
  secondParameterId: null,
  secondSourceId: null,
  areaId: null,
};

interface IProps {
  onSubmit: (values: IASKZAEmissionsFilterForm) => void;
  isGraphLoading: boolean;
}

const ASKZAEmissionsFilter = ({ onSubmit, isGraphLoading }: IProps) => {
  const formik = useFormik<IASKZAEmissionsFilterForm>({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    validationSchema: SchemaForCustomFilter([{ dateFromFieldName: 'dateFrom', dateToFieldName: 'dateTo' }]),
    initialValues: initialEmptyValues,
    onSubmit: (values) => {
      onSubmit({ ...values, firstSourceId: values.firstFurnaceId });
    },
  });

  const { post, askzaDictionaries, emissionDictionaries, isDictionariesLoading } = useASKZAEmissionsDictionaries({
    url: '/reporting/v10/graphs/askza-emission/dictionaries',
  });

  const requestDictionaries = useCallback(() => {
    formik.setFieldValue('areaId', null);
    formik.setFieldValue('firstSourceId', null);
    formik.setFieldValue('firstFurnaceId', null);
    formik.setFieldValue('firstParameterId', null);
    formik.setFieldValue('secondSourceId', null);
    formik.setFieldValue('secondParameterId', null);

    void post({
      type: formik.values.type,
      dateFrom: formik.values.dateFrom,
      dateTo: formik.values.dateTo,
    });
  }, [formik, post]);

  const askzaSourceOptions = useMemo<SelectOption[] | undefined>(
    () =>
      askzaDictionaries?.map((source) => ({
        name: source.sourceName,
        value: source.sourceId,
      })),
    [askzaDictionaries]
  );

  const askzaParameterOptions = useMemo<SelectOption[] | undefined>(
    () =>
      askzaDictionaries
        ?.find((source) => source.sourceId === formik.values.secondSourceId)
        ?.parameters.map((parameter) => ({
          name: parameter.parameterName,
          value: parameter.parameterId,
        })),
    [askzaDictionaries, formik.values.secondSourceId]
  );

  const emissionsAreaOptions = useMemo<SelectOption[] | undefined>(
    () =>
      emissionDictionaries?.map((area) => ({
        name: area.areaName,
        value: area.areaId,
      })),
    [emissionDictionaries]
  );

  const emissionsSourceOptions = useMemo<SelectOption[] | undefined>(
    () =>
      emissionDictionaries
        ?.find((area) => area.areaId === formik.values.areaId)
        ?.sources.map((source) => ({
          name: source.sourceName,
          value: source.sourceId,
        })),
    [emissionDictionaries, formik.values.areaId]
  );

  const emissionsFurnaceOptions = useMemo<SelectOption[] | undefined>(
    () =>
      emissionDictionaries
        ?.find((area) => area.areaId === formik.values.areaId)
        ?.sources.find((source) => source.sourceId === formik.values.firstSourceId)
        ?.furnaces.map((furnace) => ({
          name: furnace.sourceName,
          value: furnace.sourceId,
        })),
    [emissionDictionaries, formik.values.areaId, formik.values.firstSourceId]
  );

  const emissionsParameterOptions = useMemo<SelectOption[] | undefined>(
    () =>
      emissionDictionaries
        ?.find((area) => area.areaId === formik.values.areaId)
        ?.sources.find((source) => source.sourceId === formik.values.firstSourceId)
        ?.furnaces.find((source) => source.sourceId === formik.values.firstFurnaceId)
        ?.parameters.map((parameter) => ({
          name: parameter.parameterName,
          value: parameter.parameterId,
        })),
    [emissionDictionaries, formik.values.areaId, formik.values.firstFurnaceId, formik.values.firstSourceId]
  );

  return (
    <Flex py={2} alignItems="center" width="100%">
      <FormikProvider
        value={{
          ...formik,
          errors: createTouchedErrors(formik.errors, formik.touched),
        }}
      >
        <Flex flexDirection="column" width="100%">
          <PeriodSelect
            requestDictionaries={requestDictionaries}
            isDictionariesLoading={isDictionariesLoading}
            errors={formik.errors}
          />

          {askzaDictionaries && emissionDictionaries && (
            <Flex mt={2} alignItems="center" width="100%">
              <Grid
                border="1px solid #000"
                p={2}
                backgroundColor="rgb(223, 237, 214)"
                gap="16px"
                templateColumns="repeat(4, 1fr)"
                mr={2}
              >
                <Field
                  as={Select}
                  name="areaId"
                  label="Предприятие"
                  options={emissionsAreaOptions}
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                    formik.setFieldValue('areaId', e.target.value);
                    formik.setFieldValue('firstSourceId', null);
                    formik.setFieldValue('firstFurnaceId', null);
                    formik.setFieldValue('firstParameterId', null);
                  }}
                  isDisabled={isDictionariesLoading}
                />

                <Field
                  as={Select}
                  name="firstSourceId"
                  label="Источник"
                  options={emissionsSourceOptions}
                  isDisabled={!formik.values.areaId}
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                    formik.setFieldValue('firstSourceId', e.target.value);
                    formik.setFieldValue('firstFurnaceId', null);
                    formik.setFieldValue('firstParameterId', null);
                  }}
                />

                <Field
                  as={Select}
                  name="firstFurnaceId"
                  label="Тип источника"
                  options={emissionsFurnaceOptions}
                  isDisabled={!formik.values.firstSourceId}
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                    formik.setFieldValue('firstFurnaceId', e.target.value);
                    formik.setFieldValue('firstParameterId', null);
                  }}
                />

                <Field
                  as={Select}
                  name="firstParameterId"
                  label="Параметр"
                  options={emissionsParameterOptions}
                  isDisabled={!formik.values.firstSourceId}
                />
              </Grid>

              <Grid
                border="1px solid #000"
                p={2}
                backgroundColor="rgb(223, 237, 214)"
                gap="16px"
                templateColumns="repeat(2, 1fr)"
                mr={2}
              >
                <Field
                  as={Select}
                  name="secondSourceId"
                  label="Станция"
                  options={askzaSourceOptions}
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                    formik.setFieldValue('secondSourceId', e.target.value);
                    formik.setFieldValue('secondParameterId', null);
                  }}
                  isDisabled={isDictionariesLoading}
                />
                <Field
                  as={Select}
                  name="secondParameterId"
                  label="Параметр"
                  options={askzaParameterOptions}
                  isDisabled={!formik.values.secondSourceId}
                />
              </Grid>

              <Button
                isDisabled={!formik.values.secondParameterId || !formik.values.firstParameterId}
                ml="auto"
                onClick={formik.submitForm}
                variant="solid"
                isLoading={isDictionariesLoading || isGraphLoading}
              >
                Построить график
              </Button>
            </Flex>
          )}
        </Flex>
      </FormikProvider>
    </Flex>
  );
};

export default ASKZAEmissionsFilter;
