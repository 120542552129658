import React from 'react';
import {
  DeicingGreenPlantsRootPath,
  DeicingStatements,
  GreenPlantsFloraUrl,
  GreenPlantsLawnsUrl,
} from 'pages/routes/PGRGreenPlants';
import { RoutesList, SubsystemHeader } from 'components/common';
import { Flex } from '@chakra-ui/react';
import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import appLocale from 'constants/appLocale';
import { ReagentsRootPath } from 'pages/data/PGR';

export const DeicingGreenPlantsList = () => {
  useUpdateBreadcrumbs([
    { breadcrumb: appLocale.common.data, path: '/data' },
    {
      breadcrumb: appLocale.subsystems.reagents,
      path: ReagentsRootPath,
    },
    {
      breadcrumb: 'Воздействие на зеленые насаждения',
      path: DeicingGreenPlantsRootPath,
    },
    {
      breadcrumb: 'Ведомости',
      path: DeicingStatements,
    },
  ]);
  return (
    <Flex flexDirection="column">
      <SubsystemHeader title="Ведомости" />
      <RoutesList
        routes={[
          {
            path: GreenPlantsFloraUrl,
            breadcrumb: 'Деревья и кустарники',
          },
          {
            path: GreenPlantsLawnsUrl,
            breadcrumb: 'Газоны',
          },
        ]}
      />
    </Flex>
  );
};
