import { Filter } from 'features/Filter';
import { createInitialValuesToFormik, createValuesToRequest } from 'features/Filter/utils';
import React, { useMemo } from 'react';
import { IFilterItem, TFilterDTO } from 'models';
import { SelectOption } from 'components/common';
import combineFilters from 'lib/utils/combineFilters';

type TNSIReagentsReservoirsFilterTypes =
  | 'name'
  | 'waterControlId'
  | 'waterTypeId'
  | 'waterKindId'
  | 'waterBasinId'
  | 'deleted';
type TNSIReagentsReservoirsFilterDTO = TFilterDTO<TNSIReagentsReservoirsFilterTypes>;
type FilterInitialValues = Record<TNSIReagentsReservoirsFilterTypes, TNSIReagentsReservoirsFilterDTO['filters'][number]>;

const initialValues: FilterInitialValues = {
  name: {
    key: 'name',
    operation: 'CONTAINS',
    value: '',
  },
  waterTypeId: {
    key: 'waterTypeId',
    operation: 'EQ',
    value: null,
  },
  waterKindId: {
    key: 'waterKindId',
    operation: 'EQ',
    value: null,
  },
  waterBasinId: {
    key: 'waterBasinId',
    operation: 'EQ',
    value: null,
  },
  waterControlId: {
    key: 'waterControlId',
    operation: 'EQ',
    value: null,
  },
  deleted: {
    key: 'deleted',
    operation: 'EQ',
    value: false,
  },
};

interface Props {
  isLoading: boolean;
  onSubmit: (values: FilterInitialValues) => void;
  waterObjectTypes?: SelectOption[];
  waterObjectKind?: SelectOption[];
  waterObjectsBasin?: SelectOption[];
  waterObjectsControl?: SelectOption[];
  filters: TFilterDTO['filters'];
}

const initialValuesToFormik = createInitialValuesToFormik(initialValues);

function NSIWaterReservoirsFilters({
  isLoading,
  onSubmit,
  children,
  waterObjectKind = [],
  waterObjectsBasin = [],
  waterObjectsControl = [],
  waterObjectTypes = [],
  filters,
}: React.PropsWithChildren<Props>) {
  const presetValues = useMemo<{ key: string; value: IFilterItem['value'] }[]>(() => {
    const preset: { key: string; value: IFilterItem['value'] }[] = [];
    const initialFilters = combineFilters(initialValues, filters);
    Object.keys(initialFilters).forEach((key) => {
      preset.push({ key: `${key}`, value: initialFilters[key].value });
    });
    return preset;
  }, [filters]);

  return (
    <Filter<typeof initialValuesToFormik>
      isLoading={isLoading}
      isDisabled={isLoading}
      onSubmit={(values) => {
        onSubmit(createValuesToRequest(initialValues, values));
      }}
      initialValues={initialValuesToFormik}
      mainFields={[
        {
          type: 'TEXT',
          name: 'name',
          label: 'Наименование',
        },
        {
          type: 'SELECT',
          name: 'waterBasinId',
          label: 'Бассейн',
          options: waterObjectsBasin,
        },
        {
          type: 'SELECT',
          name: 'waterTypeId',
          label: 'Тип водоема',
          options: waterObjectTypes,
        },
        {
          type: 'SELECT',
          name: 'waterKindId',
          label: 'Водоток',
          options: waterObjectKind,
        },
        {
          type: 'SELECT',
          name: 'waterControlId',
          label: 'Уровень госконтроля',
          options: waterObjectsControl,
        },
      ]}
      presetValues={presetValues}
    >
      {children}
    </Filter>
  );
}

export default NSIWaterReservoirsFilters;
