import { GreenPlantsApiRootPath } from 'pages/routes/GreenPlants';
import { useRequest } from 'hooks/useRequest';
import { IFloraItemDto } from './useFloraList';

const useFloraListItem = (registryId: IFloraItemDto['registryId']) => {
  const { get, result, isLoading, error } = useRequest<IFloraItemDto>(`${GreenPlantsApiRootPath}/flora/${registryId}`);

  return { get, result, isLoading, error };
};

export { useFloraListItem };
