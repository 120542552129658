import * as yup from 'yup';

export const PdkJournalSchema = yup.object().shape({
  date: yup.string().nullable(),
  address: yup.string().nullable(),
  areaId: yup.string().nullable(),
  stationId: yup.string().nullable(),
  otherReporting: yup.string().nullable(),
  comment: yup.string().nullable(),
  description: yup.string().nullable(),

  pdkH2s: yup.number().nullable(),
  pdkO3: yup.number().nullable(),
  pdkNO2: yup.number().nullable(),
  pdkNO: yup.number().nullable(),
  pdkSO2: yup.number().nullable(),
  pdkCO: yup.number().nullable(),
  pdkPM10: yup.number().nullable(),
  pdkPM25: yup.number().nullable(),
  pdkNH3: yup.number().nullable(),
});
