import { IChart } from './types';

export const getOrCreateLegendList = (chart: IChart, id: string) => {
  const legendContainer = document.getElementById(id);
  let listContainer = legendContainer?.querySelector?.('ul');

  if (!listContainer) {
    listContainer = document.createElement('ul');
    listContainer.style.display = 'flex';
    listContainer.style.flexDirection = 'row';
    listContainer.style.margin = '0';
    listContainer.style.padding = '0';
    legendContainer!.appendChild(listContainer);
  }

  return listContainer;
};
