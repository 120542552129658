import { IFilterItem, TDictionariesArray } from 'models';
import React from 'react';
import { FormikProvider, useFormik } from 'formik';
import { FiltersDatepickersSchema } from 'models/schemas/filtersDatepickersSchema/FiltersDatepickersSchema';
import { NotificationFormFields } from './NotificationFormFields';

const initialValues: Record<string, IFilterItem<string>> = {
  startDate: {
    key: 'date',
    operation: 'GTE',
    value: '',
  },
  endDate: {
    key: 'date',
    operation: 'LTE',
    value: '',
  },
  user: {
    key: 'userId',
    operation: 'EQ',
    value: '',
  },
  subsystem: {
    key: 'subsystemId',
    operation: 'EQ',
    value: '',
  },
  type: {
    key: 'type',
    operation: 'EQ',
    value: '',
  },
  method: {
    key: 'channels',
    operation: 'EQ',
    value: '',
  },
};

interface IProps {
  onSubmit: (values: any) => void;
  dictionaries: { data: TDictionariesArray<string> } | null;
}

export const NotificationFilter = ({ onSubmit, dictionaries }: IProps) => {
  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    validationSchema: FiltersDatepickersSchema([{ dateFromFieldName: 'startDate', dateToFieldName: 'endDate' }]),
    initialValues,
    onSubmit
  });
  return (
    <FormikProvider
      value={{
        ...formik,
      }}
    >
      <NotificationFormFields dictionaries={dictionaries} />
    </FormikProvider>
  );
};
