import { Field, FormikProvider, useFormik } from 'formik';
import { Flex, Grid } from '@chakra-ui/react';
import { DateInput, Select } from 'components/common';
import { createDateFromResponse, createDateToRequestWithTimezone } from 'lib/create-date';
import { COMMON_LOCALE } from 'constants/common';
import appLocale from 'constants/appLocale';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { IPeriod } from 'features/PeriodsButtons/types';
import { PeriodsButtons } from 'features/PeriodsButtons';
import { usePeriods } from 'features/PeriodsButtons/usePeriods';
import combineFilters from 'lib/utils/combineFilters';
import { IFilterItem, TDictionaryItemDictionary, TFiltersArray } from 'models';
import { MomentInput } from 'moment';
import { FiltersDatepickersSchema } from 'models/schemas/filtersDatepickersSchema/FiltersDatepickersSchema';
import FilterFormControls from 'components/form/FilterFormControls';

interface Props {
  isLoading: boolean;
  isDisabled: boolean;
  waterObjects: TDictionaryItemDictionary[] | undefined;
  organizations: TDictionaryItemDictionary[] | undefined;
  regions: TDictionaryItemDictionary[] | undefined;
  sources: TDictionaryItemDictionary[] | undefined;
  districts: TDictionaryItemDictionary[] | undefined;
  onSubmit: (values: any) => void;
  periods: IPeriod[] | null;
  filters?: TFiltersArray;
}

const initialEmptyValues: Record<string, IFilterItem<string>> = {
  protocolDateMin: {
    key: 'protocolDate',
    operation: 'GTE',
    value: null,
  },
  protocolDateMax: {
    key: 'protocolDate',
    operation: 'LTE',
    value: null,
  },
  sourceId: {
    key: 'sourceId',
    operation: 'EQ',
    value: null,
  },
  regionId: {
    key: 'regionId',
    operation: 'EQ',
    value: null,
  },
  districtId: {
    key: 'districtId',
    operation: 'EQ',
    value: null,
  },
  waterObjectId: {
    key: 'waterObjectId',
    operation: 'EQ',
    value: null,
  },
  consumerId: {
    key: 'consumerId',
    operation: 'EQ',
    value: null,
  },
  organizationId: {
    key: 'organizationId',
    operation: 'EQ',
    value: null,
  },
  showDisabled: {
    key: 'deleted',
    operation: 'EQ',
    value: false,
  },
  periodId: {
    key: 'periodId',
    operation: 'EQ',
    value: null,
  }
};

export const BottomSoilTableFilters: FC<Props> = ({
  isLoading,
  isDisabled,
  onSubmit,
  organizations,
  waterObjects,
  regions,
  sources,
  districts,
  children,
  periods,
  filters = []
}) => {
  const savedPeriodId = useMemo(() => (
    filters.find((item) => item.key === 'periodId')?.value as string || ''
  ), [filters]);

  const [periodId, setPeriodId] = useState<string>(savedPeriodId);

  const { getDatesFromPeriod, dates } = usePeriods(periodId, periods);

  const submitPeriod = (id: string) => {
    const dates = getDatesFromPeriod(id);
    setFieldValue('periodId.value', id);
    setFieldValue('protocolDateMin.value', createDateToRequestWithTimezone(dates[0]));
    setFieldValue('protocolDateMax.value', createDateToRequestWithTimezone(dates[1]));
    setFieldTouched('protocolDateMin.value');
    setPeriodId(id);
  };

  const {
    values,
    errors,
    getFieldMeta,
    getFieldHelpers,
    getFieldProps,
    handleReset,
    submitForm,
    setFieldValue,
    setValues,
    setFieldTouched,
    ...formik
  } = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    validationSchema: FiltersDatepickersSchema([
      { dateFromFieldName: 'protocolDateMin', dateToFieldName: 'protocolDateMax' },
    ]),
    initialValues: initialEmptyValues,
    onSubmit,
  });

  useEffect(() => {
    setValues(combineFilters(initialEmptyValues, filters));
  }, [filters, setValues]);

  const resetFilters = (e: any) => {
    setPeriodId('');
    handleReset(e);
    setTimeout(() => submitForm(), 0);
  };

  return (
    <Grid gap="20px">
      <Flex flexDirection="row" justifyContent="space-between">
        <FormikProvider
          value={{
            values,
            errors,
            getFieldMeta,
            getFieldHelpers,
            getFieldProps,
            handleReset,
            submitForm,
            setFieldValue,
            setValues,
            setFieldTouched,
            ...formik,
          }}
        >
          <Grid gap="12px" rowGap="8px">
            <Grid templateColumns="repeat(4, 1fr)" gap="12px" rowGap="8px">
              <Field
                as={DateInput}
                name="dateTimeMin.value"
                label={COMMON_LOCALE.from}
                value={createDateFromResponse(values.protocolDateMin.value as MomentInput)}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setFieldValue(
                    'protocolDateMin.value',
                    createDateToRequestWithTimezone(e.target.value),
                  );
                }}
                showTimeInput
                error={errors.protocolDateMin?.value}
                maxDate={dates?.[1]}
                minDate={dates?.[0]}
                isDisabled={!periodId}
              />

              <Field
                as={DateInput}
                name="dateTimeMax.value"
                label={COMMON_LOCALE.to}
                value={createDateFromResponse(values.protocolDateMax.value as MomentInput)}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setFieldValue(
                    'protocolDateMax.value',
                    createDateToRequestWithTimezone(e.target.value),
                  );
                }}
                showTimeInput
                error={errors.protocolDateMax?.value}
                maxDate={dates?.[1]}
                minDate={dates?.[0]}
                isDisabled={!periodId}
              />

              <Field
                as={Select}
                options={sources}
                isDisabled={!sources?.length}
                name="sourceId.value"
                label={appLocale.bottomSoil.source}
              />

              <Field
                as={Select}
                options={districts}
                isDisabled={!districts?.length}
                name="districtId.value"
                label={appLocale.bottomSoil.district}
              />
              <Field
                as={Select}
                options={regions}
                isDisabled={!regions?.length}
                name="regionId.value"
                label={appLocale.bottomSoil.region}
              />

              <Field
                as={Select}
                options={waterObjects}
                isDisabled={!waterObjects?.length}
                name="waterObjectId.value"
                label={appLocale.bottomSoil.waterObject}
              />

              <Field
                as={Select}
                options={organizations}
                isDisabled={!organizations?.length}
                name="organizationId.value"
                label={appLocale.bottomSoil.organizations}
              />

              <Field
                as={Select}
                options={organizations}
                isDisabled={!organizations?.length}
                name="consumerId.value"
                label={appLocale.bottomSoil.consumer}
              />
            </Grid>

            {children}
          </Grid>

          <FilterFormControls
            onReset={resetFilters}
            onSubmit={submitForm}
            isDisabled={isDisabled}
            isLoading={isLoading}
          />
        </FormikProvider>
      </Flex>

      <PeriodsButtons periodId={periodId} periods={periods} submitPeriod={submitPeriod} />
    </Grid>
  );
};
