import { every, isArray, isNull } from 'lodash';
import { TDictionaryItemDictionary } from 'models';
import { parameterToParameterIdMap } from 'features/parametersMap';

export function normalizeExistParameters<
  T extends Record<
    string,
    Partial<{
      code: string | null;
      id: string | null;
      name: string | null;
      parameterId: string | null;
      value: string | string[] | number | number[] | null;
    }>
  >,
  D extends TDictionaryItemDictionary[]
>(parameters: Partial<T> = {}, ...dictionaries: (D | undefined)[]) {
  const result: typeof parameters = {};

  const isArrays = every(dictionaries, (collection) => isArray(collection));

  if (!isArrays) {
    return result;
  }

  if (dictionaries) {
    if (parameters) {
      // eslint-disable-next-line no-restricted-syntax
      for (const prop in parameters) {
        const existParameter = parameters[prop];
        const paramName = parameterToParameterIdMap[prop];
        if (Array.isArray(existParameter) && paramName) {
          Object.assign(result, {
            [paramName]: {
              value: existParameter.map((param) => param.value).filter((val) => !isNull(val)),
              parameterId: prop,
              dictionaryItemId: existParameter.map((param) => param.dictionaryItemId).filter((id) => Boolean(id)),
              ids: existParameter.map((param) => param.id).filter((id) => Boolean(id)),
            },
          });
        }
      }
    }
  }

  return result;
}
