import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import appLocale from 'constants/appLocale';
import { NSIRootPath } from '../../const/routes';
import { NSISoilAPIPath, NSISoilDictPath, NSISoilRootPath } from '../consts/paths';
import { BreadcrumbButton, Button, PageHeader } from 'components/common';
import { Flex, Heading, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import React, { FC, useEffect, useMemo } from 'react';
import { GeneralInformation } from './GeneralInformation';
import { Location } from './Location';
import { OtherInformation } from './OtherInformation';
import { useParams } from 'react-router-dom';
import { FormikProvider, FormikValues, useFormik } from 'formik';
import * as yup from 'yup';
import { requiredError } from 'models/schemas/constans';
import { useEnhancedNotification, useNotifications, useRequest, UseRequestReturnType } from 'hooks';
import { getSelectOptionsFromDictionaryByName } from 'features/get-select-options-from-dictionary';
import { SUCCESS_MESSAGES } from 'constants/messages';
import { createTouchedErrors } from 'features/create-touched-errors';
import { useGoBackWithFallback } from 'lib/utils/goBackWithFallback';
import { useDictionary } from 'hooks/useDictionary';

export const EditNSISoilData: FC = () => {
  useUpdateBreadcrumbs([
    {
      breadcrumb: appLocale.mainPage.nsi,
      path: NSIRootPath,
    },
    {
      breadcrumb: appLocale.subsystems.nsiSoil,
      path: NSISoilRootPath,
    },
  ]);

  const { id } = useParams<{ id: string }>();

  const { backWithFallback } = useGoBackWithFallback();

  const { successNotify } = useNotifications();
  const { onErrorCreate, onErrorEdit } = useEnhancedNotification();

  const onSuccess = () => {
    successNotify({
      key: 'atmosphere/edit/success',
      message: id ? SUCCESS_MESSAGES.EDIT : SUCCESS_MESSAGES.CREATE,
    });
    backWithFallback(`${NSISoilRootPath}`);
  };

  const { result, get }: UseRequestReturnType = useRequest(`${NSISoilAPIPath}/${id}`);
  const { post } = useRequest(`${NSISoilAPIPath}/create`, onSuccess, onErrorCreate);
  const { put } = useRequest(NSISoilAPIPath, onSuccess, onErrorEdit);

  useEffect(() => {
    if (id) {
      get();
    }
  }, [id, get]);

  const onSubmit = (values: FormikValues) => {
    if (id) {
      put({ ...values, id });
    } else {
      post({ ...values });
    }
  };

  const validationSchema = yup.object().shape({
    status: yup.string().nullable().required(requiredError),
    soilSourceTypeId: yup.string().nullable().required(requiredError),
    soilFuncZoneId: yup.string().nullable().required(requiredError),
    soilFuncZoneTypeId: yup.string().nullable().required(requiredError),
    okrugId: yup.string().nullable().required(requiredError),
    rayonId: yup.string().nullable().required(requiredError),
  });

  const { dictionaries } = useDictionary({ url: NSISoilDictPath });

  const statuses = useMemo(() => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'statuses'), [dictionaries]);
  const rayons = useMemo(() => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'rayons'), [dictionaries]);
  const okrugs = useMemo(() => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'okrugs'), [dictionaries]);
  const sourceTypes = useMemo(
    () =>
      getSelectOptionsFromDictionaryByName(
        dictionaries?.data,
        'sourceTypes',
        false,
        result?.soilSourceTypeId as string,
      ),
    [dictionaries?.data, result?.soilSourceTypeId],
  );
  const funcZones = useMemo(
    () =>
      getSelectOptionsFromDictionaryByName(dictionaries?.data, 'funcZones', false, result?.soilFuncZoneId as string),
    [dictionaries?.data, result?.soilFuncZoneId],
  );
  const funcZoneTypes = useMemo(
    () =>
      getSelectOptionsFromDictionaryByName(
        dictionaries?.data,
        'funcZoneTypes',
        false,
        result?.soilFuncZoneTypeId as string,
      ),
    [dictionaries?.data, result?.soilFuncZoneTypeId],
  );

  const initialValues = {
    number: '',
    name: '',
    shortName: '',
    status: '',
    latitude: '',
    longitude: '',
    soilAreaParentId: '',
    landmark: '',
    address: '',
    soilFuncZoneId: null,
    soilFuncZoneTypeId: null,
    soilSourceTypeId: null,
    okrugId: null,
    rayonId: null,
    ...result,
    id: id || null,
  };

  const { submitForm, ...formik } = useFormik({
    validationSchema,
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: false,
    validateOnBlur: true,
    initialValues,
    onSubmit,
  });

  return (
    <>
      <FormikProvider
        value={{
          submitForm,
          ...formik,
          errors: createTouchedErrors(formik.errors, formik.touched),
        }}
      >
        <PageHeader>
          <BreadcrumbButton onClick={() => backWithFallback(NSISoilRootPath)}>Вернуться назад</BreadcrumbButton>
          <Flex>
            <Heading marginLeft={4}>{`${id ? 'Редактирование' : 'Добавление'} справочных данных`}</Heading>
            <Flex marginLeft="auto">
              <Button marginRight={5} onClick={() => backWithFallback(NSISoilRootPath)}>
                Отмена
              </Button>
              <Button onClick={submitForm}>Сохранить</Button>
            </Flex>
          </Flex>
        </PageHeader>
        <Tabs marginTop={10}>
          <TabList>
            <Tab>Общие сведения</Tab>
            <Tab>Расположение</Tab>
            <Tab>Дополнительная информация</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <GeneralInformation
                sourceTypes={sourceTypes}
                funcZones={funcZones}
                funcZoneTypes={funcZoneTypes}
                statuses={statuses}
              />
            </TabPanel>
            <TabPanel>
              <Location districts={rayons} regions={okrugs} />
            </TabPanel>
            <TabPanel>
              <OtherInformation />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </FormikProvider>
    </>
  );
};
