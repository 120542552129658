import React, { FC, useMemo, useState } from 'react';
import { Field } from 'formik';

import { DateInput, Select } from 'components/common';
import { createDateFromResponse, createDateToRequestWithTimezone } from 'lib/create-date';
import { COMMON_LOCALE } from 'constants/common';
import { SurfaceWaterLocales } from '../locales';
import { TDictionaryItemDictionary } from 'models/dictionaries';
import { filtersInitialValues } from 'pages/data/Water/SurfaceWater/components/filtersInitialValues';
import { IPeriod } from 'features/PeriodsButtons/types';
import { PeriodsButtons } from 'features/PeriodsButtons';
import { usePeriods } from 'features/PeriodsButtons/usePeriods';
import { TFiltersArray } from 'models';
import FilterFormControls from 'components/form/FilterFormControls';
import { Flex, Grid } from '@chakra-ui/react';
import appLocale from 'constants/appLocale';

interface FilterProps {
  onSubmit: (values: any) => void;
  waterObjects: TDictionaryItemDictionary[] | undefined;
  organizations: TDictionaryItemDictionary[] | undefined;
  regions: TDictionaryItemDictionary[] | undefined;
  districts: TDictionaryItemDictionary[] | undefined;
  sources: TDictionaryItemDictionary[] | undefined;
  formik: any;
  periods: IPeriod[] | null;
  filters?: TFiltersArray;
  isLoading: boolean
}

export const SurfaceWaterFilters: FC<FilterProps> = ({
  onSubmit,
  waterObjects,
  organizations,
  regions,
  districts,
  sources,
  formik,
  periods,
  children,
  filters = [],
  isLoading
}) => {
  const savedPeriodId = useMemo(() => (
    filters.find((item) => item.key === 'periodId')?.value as string || ''
  ), [filters]);

  const [periodId, setPeriodId] = useState<string>(savedPeriodId);

  const { getDatesFromPeriod, dates } = usePeriods(periodId, periods);

  const submitPeriod = (id: string) => {
    const dates = getDatesFromPeriod(id);
    setFieldValue('periodId.value', id);
    setFieldValue('dateTimeMin.value', createDateToRequestWithTimezone(dates[0]));
    setFieldValue('dateTimeMax.value', createDateToRequestWithTimezone(dates[1]));
    setFieldTouched('dateTimeMin.value');
    setPeriodId(id);
  };

  const { setFieldValue, values, handleReset, submitForm, errors, setFieldTouched } = formik;

  const filteredSources = useMemo(() => {
    if (!values.waterObjectId?.value) {
      return sources;
    }
    return sources?.filter((item) => item.parent === values.waterObjectId.value);
  }, [sources, values.waterObjectId?.value]);

  return (
    <Grid gap="12px" rowGap="8px">
      <Flex flexDirection="row" justifyContent="space-between">
        <Grid width="100%" maxWidth="1200px" gap="12px" rowGap="8px" templateColumns="repeat(4, 1fr)">
          <Field
            as={DateInput}
            name="dateTimeMin.value"
            label={COMMON_LOCALE.from}
            value={createDateFromResponse(values.dateTimeMin.value)}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFieldValue('dateTimeMin.value', createDateToRequestWithTimezone(e.target.value));
            }}
            showTimeInput
            maxDate={dates?.[1]}
            minDate={dates?.[0]}
            isDisabled={!periodId}
            error={errors.dateTimeMin?.value}
          />
          <Field
            as={DateInput}
            name="dateTimeMax.value"
            label={COMMON_LOCALE.to}
            value={createDateFromResponse(values.dateTimeMax.value)}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFieldValue('dateTimeMax.value', createDateToRequestWithTimezone(e.target.value));
            }}
            showTimeInput
            maxDate={dates?.[1]}
            minDate={dates?.[0]}
            isDisabled={!periodId}
            error={errors.dateTimeMax?.value}
          />

          <Field
            as={Select}
            options={waterObjects}
            name="waterObjectId.value"
            label={SurfaceWaterLocales.waterObject}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              setFieldValue('waterObjectId.value', e.target.value);
              setFieldValue('sourceId.value', null);
            }}
          />

          <Field
            as={Select}
            options={filteredSources}
            name="sourceId.value"
            label={appLocale.bottomSoil.source}
          />

          <Field as={Select} options={regions} name="regionId.value" label={COMMON_LOCALE.region} />

          <Field as={Select} options={districts} name="districtId.value" label={COMMON_LOCALE.district} />

          <Field as={Select} options={organizations} name="organizationId.value" label={COMMON_LOCALE.organization} />

          {children}
        </Grid>

        <FilterFormControls
          onReset={(e) => {
            handleReset(e);
            setPeriodId('');
            setTimeout(() => onSubmit(filtersInitialValues), 0);
          }}
          onSubmit={submitForm}
          isDisabled={isLoading}
          isLoading={isLoading}
        />
      </Flex>
      <PeriodsButtons periodId={periodId} periods={periods} submitPeriod={submitPeriod} />
    </Grid>
  );
};
