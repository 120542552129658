import * as yup from 'yup';
import { requiredError, numberError, shouldBeMoreThan, shouldBeLessThan } from '../constans';

export const NsiWaterReservoirsSchema = yup.object().shape({
  waterObjectCode: yup.number().typeError(numberError).nullable()
    .min(0, shouldBeMoreThan(0))
    .max(999, shouldBeLessThan(1000))
    .test(
      'no-leading-zero',
      'Не может начинаться с 0',
      (value, context: any) => Boolean(context.originalValue && !String(context.originalValue).startsWith('0')) || !context.originalValue
    ),
  name: yup.string().nullable().required(requiredError),
  waterKindId: yup.string().nullable().required(requiredError),
  waterTypeId: yup.string().nullable().required(requiredError),
  waterBasinId: yup.string().nullable().required(requiredError),
  waterControlId: yup.string().nullable().required(requiredError),
});
