import { Button, DateInput, FormWrapper, Select } from 'components/common';
import { Field, useFormikContext } from 'formik';
import { createDateFromResponse, createUTCTime } from 'lib/create-date';
import React from 'react';
import { NMUListItem } from '../../types';
import { NSI_NMU_RootPath } from '../../consts';
import { Box, Flex, Grid } from '@chakra-ui/react';
import { useGoBackWithFallback } from 'lib/utils/goBackWithFallback';

const grades = [
  {
    name: 1,
    value: 'nmu_1',
  },
  {
    name: 2,
    value: 'nmu_2',
  },
  {
    name: 3,
    value: 'nmu_3',
  },
];

export const AddNMUFormFields = () => {
  const { handleSubmit, errors, setFieldValue, values } = useFormikContext<NMUListItem>();

  const { backWithFallback } = useGoBackWithFallback();

  return (
    <Box flexDirection="column" mb={4}>
      <FormWrapper>
        <Grid marginTop="30px" gap="15px">
          <Field as={Select} options={grades} name="grade" label="Степень НМУ" error={errors.grade} />
          <DateInput
            name="date"
            label="Дата и время начала периода"
            value={createDateFromResponse(values.startDate)}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFieldValue('startDate', createUTCTime(e.target.value));
            }}
            error={errors.startDate}
            showTimeInput
          />
          <DateInput
            name="date"
            label="Дата и время окончания периода"
            value={createDateFromResponse(values.endDate)}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFieldValue('endDate', createUTCTime(e.target.value));
            }}
            error={errors.endDate}
            showTimeInput
          />
          <Flex justifyContent="space-between">
            <Button onClick={() => backWithFallback(NSI_NMU_RootPath)}>Отмена</Button>
            <Button onClick={() => handleSubmit()} variant="solid">
              Сохранить
            </Button>
          </Flex>
        </Grid>
      </FormWrapper>
    </Box>
  );
};
