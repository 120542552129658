import { Button, Input, Select } from 'components/common';
import { Flex, Grid, GridItem } from '@chakra-ui/react';
import { Field, useFormikContext } from 'formik';
import { UploadConfig } from 'pages/admin/FTP';
import { useFTPUploadDictionary } from 'pages/admin/FTP/FTPUpload/FTPUpload/useFTPUploadDictionary';
import React, { useMemo } from 'react';
import { getSelectOptionsFromDictionaryByNameWithTransformation } from 'features/get-select-options-from-dictionary';

interface Props {
  onCancel: () => void;
  isEditMode?: boolean;
}

export const FieldsForm = ({ onCancel, isEditMode }: Props) => {
  const { handleSubmit, errors, values, setFieldValue } =
    useFormikContext<UploadConfig>();
  const { result: dictionaries } = useFTPUploadDictionary();
  const status = useMemo(
    () => getSelectOptionsFromDictionaryByNameWithTransformation(dictionaries?.data, 'status', 'name'),
    [dictionaries]
  );
  const uploadTemplates = useMemo(
    () => getSelectOptionsFromDictionaryByNameWithTransformation(dictionaries?.data, 'uploadTemplates', 'name'),
    [dictionaries]
  );
  const exchanges = useMemo(
    () => getSelectOptionsFromDictionaryByNameWithTransformation(dictionaries?.data, 'exchanges', 'name'),
    [dictionaries]
  );
  return (
    <Flex maxWidth="700px" padding="20px 130px" flexDirection="column">
      <Grid gap={4} templateColumns="repeat(1, 1fr)">
        <GridItem>
          <Field as={Input} name="name" label="Наименование набора данных" disabled={isEditMode} error={errors?.name} />
        </GridItem>
        <GridItem>
          <Field as={Select} name="templateId" options={uploadTemplates} label="Шаблон" error={errors?.templateId} />
        </GridItem>
        <GridItem>
          <Field
            as={Select}
            name="exchangeId"
            options={exchanges}
            label="Каталог"
            error={errors?.exchangeId}
          />
        </GridItem>
        <GridItem>
          <Field
            as={Select}
            name="status"
            options={status}
            label="Статус"
            error={errors?.status}
            value={values.status !== null ? String(values.status) : values.status}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFieldValue('status', e.target.value !== null ? e.target.value === 'true' : null);
            }}
          />
        </GridItem>
      </Grid>
      <Flex justifyContent="space-between" marginTop={4}>
        <Button onClick={onCancel}>Отмена</Button>
        <Button onClick={() => handleSubmit()}>
          Сохранить
        </Button>
      </Flex>
    </Flex>
  );
};
