import moment from 'moment';
import { useRequest } from 'hooks/useRequest';
import { FormikProvider, useFormik } from 'formik';
import { createTouchedErrors } from 'features/create-touched-errors';
import { GroundwaterMonitoringSchema } from 'models/schemas/groundwaters/monitoring';
import { useHistory } from 'react-router-dom';
import { FormHeader, LoaderItem } from 'components/common';
import { GeoecologicalRootPath } from 'pages/data/GeoecologicalProcesses/consts';
import FieldsForm from './FieldsForm';
import React, { useState } from 'react';
import { IUploadMedia } from 'components/common/FileUploadDragAndDrop/types';
import { updateFiles } from 'api/services/media';
import { useObjectType } from 'hooks/useObjectType';
import { MonitoringItem } from 'pages/data/GeoecologicalProcesses/GeologicalProcessesGroundwater/Monitoring/types';
import { useGoBackWithFallback } from 'lib/utils/goBackWithFallback';
import { useDictionary } from 'hooks/useDictionary';
import { useEnhancedNotification } from 'hooks';

export const initialEmptyValues = {
  capturingState: {
    measuringId: null,
    value: '',
  },
  ecologicalState: {
    measuringId: null,
    value: '',
  },
  measuringDate: moment().format(),
  sourceCode: '',
  sourceId: '',
  waterSourceType: '',
  waterSourceTypeName: '',
  deleted: false,
  fileIds: null,
  id: '',
};

export const AddMonitoringResult = () => {
  const { backWithFallback } = useGoBackWithFallback();
  const history = useHistory();
  const { isDictionariesLoading, dictionaries } = useDictionary({
    url: '/geoecology/v10/geoecology/watersource/monitoring/dictionaries',
  });
  const { onSuccessCreate, onErrorCreate } = useEnhancedNotification();
  const [media, setMedia] = useState<IUploadMedia[]>([]);

  const objectType = useObjectType(dictionaries);

  const onSuccess = (response: MonitoringItem | null) => {
    if (!response) {
      onErrorCreate();
      return;
    }
    onSuccessCreate();

    updateFiles(response.id, objectType, media)
      .then(() => {
        backWithFallback(`${GeoecologicalRootPath}/monitoring/water/examination`);
      })
      .catch((error: Error) => {
        onErrorCreate(error.message);
        history.replace(`${GeoecologicalRootPath}/monitoring/water/examination/edit/row/${response.id}`, response);
      });
  };

  const { post, isLoading: isPostLoading } = useRequest<MonitoringItem, any>(
    '/geoecology/v10/geoecology/watersource/monitoring',
    onSuccess,
    onErrorCreate,
  );

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    initialValues: initialEmptyValues,
    validationSchema: GroundwaterMonitoringSchema,
    onSubmit: (values) => post(values),
  });

  if (isPostLoading || isDictionariesLoading) {
    return <LoaderItem />;
  }

  return (
    <FormikProvider
      value={{
        ...formik,
        errors: createTouchedErrors(formik.errors, formik.touched),
      }}
    >
      <FormHeader
        onBackClick={() => backWithFallback(`${GeoecologicalRootPath}/monitoring/water/examination`)}
        header="Добавление результатов обследования"
      />
      <FieldsForm
        dictionaries={dictionaries}
        onCancel={() => backWithFallback(`${GeoecologicalRootPath}/monitoring/water/examination`)}
        media={media}
        onMediaChange={setMedia}
      />
    </FormikProvider>
  );
};
