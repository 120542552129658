import * as yup from 'yup';
import { requiredError } from '../constans';
import { numberWithSign } from 'models/schemas/numbers';

export const SnowSchema = yup.object().shape({
  stageId: yup.string().nullable().required(requiredError),
  seasonId: yup.string().nullable().required(requiredError),
  date: yup.string().typeError(requiredError).required(requiredError),
  protocolNumber: yup.string().required(requiredError),
  areaId: yup.string().typeError(requiredError).required(requiredError),
  districtId: yup.string().required(requiredError),
  regionId: yup.string().required(requiredError),
  address: yup.string().required(requiredError),
  territoryType: yup.string().typeError(requiredError).required(requiredError),
  pointId: yup.string().typeError(requiredError).required(requiredError),
  parameters: yup.array().of(
    yup.object().shape({
      value: numberWithSign,
    })
  ),
});
