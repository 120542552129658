import React, { useMemo } from 'react';
import { Field, FieldArray, FormikErrors, useFormikContext } from 'formik';
import { Box, Flex, Grid, GridItem } from '@chakra-ui/react';
import { Button, Checkbox, DateInput, DefaultText, Input, Select, Textarea } from 'components/common';
import { Appeals, AppealValues, Complaint } from 'pages/citizenAppeal';
import { TDictionariesArray } from 'models/dictionaries';
import { getSelectOptionsFromDictionaryByName } from 'features/get-select-options-from-dictionary';
import { createDateFromResponse, createLocalDateISO8601, createUTCTime } from 'lib/create-date';
import { AddIcon, DeleteIcon } from 'components/icons';
import { useGoBackWithFallback } from 'lib/utils/goBackWithFallback';

interface Props {
  dictionaries?: TDictionariesArray;
  values: AppealValues;
  handleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
  setFieldValue: (name: string, value: any) => void;
  errors: FormikErrors<AppealValues>;
  isWrite?: boolean;
}

export const FieldsForm = ({ dictionaries, values, handleSubmit, setFieldValue, errors, isWrite = true }: Props) => {
  const { initialValues } = useFormikContext<Complaint>();
  const complainOrigins = useMemo(
    () =>
      getSelectOptionsFromDictionaryByName(dictionaries, 'complaintOrigins', false, initialValues.complaintOriginId),
    [dictionaries, initialValues.complaintOriginId],
  );
  const { backWithFallback } = useGoBackWithFallback();

  return (
    <Box marginRight={4} width="80%">
      <Grid
        marginLeft={3}
        marginBottom={3}
        marginTop={5}
        gap={3}
        templateRows="repeat(1, 1fr)"
        templateColumns="repeat(4, 1fr)"
      >
        <GridItem colStart={1} colEnd={3} rowStart={1} rowEnd={1}>
          <Field
            as={Input}
            label="Ссылка на документ МЭДО"
            name="assignmentUrl"
            error={errors.assignmentUrl}
            isDisabled={!isWrite}
          />
        </GridItem>
      </Grid>
      <DefaultText marginLeft={2} marginBottom={2} fontWeight="bold" fontSize="18px">
        Поручение
      </DefaultText>
      <Grid
        marginLeft={2}
        marginBottom={3}
        marginTop={5}
        gap={3}
        templateRows="repeat(2, 1fr)"
        templateColumns="repeat(4, 1fr)"
      >
        <GridItem rowStart={1} rowEnd={1} colStart={1} colEnd={2}>
          <Field
            as={DateInput}
            name="assignmentDate"
            label="Дата поручения"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFieldValue('assignmentDate', createUTCTime(e.target.value));
            }}
            error={errors.assignmentDate}
            isDisabled={!isWrite}
          />
        </GridItem>
        <GridItem rowStart={1} rowEnd={1} colStart={2} colSpan={1}>
          <Field
            as={Input}
            label="Номер поручения"
            name="assignmentNumber"
            error={errors.assignmentNumber}
            isDisabled={!isWrite}
          />
        </GridItem>
        <GridItem rowStart={1} rowEnd={1} colStart={3} colSpan={1}>
          <Field
            as={DateInput}
            name="assignmentExecution"
            label="Срок"
            value={createDateFromResponse(values.assignmentExecution)}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFieldValue('assignmentExecution', createLocalDateISO8601(e.target.value));
            }}
            error={errors.assignmentExecution}
            isDisabled={!isWrite}
          />
        </GridItem>
        <GridItem rowStart={1} rowEnd={1} colStart={4} colSpan={1}>
          <Field
            as={DateInput}
            name="assignmentDeadline"
            label="Срок ДПиООС"
            value={createDateFromResponse(values.assignmentDeadline)}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFieldValue('assignmentDeadline', createLocalDateISO8601(e.target.value));
            }}
            error={errors.assignmentDeadline}
            isDisabled={!isWrite}
          />
        </GridItem>
        <GridItem rowStart={2} rowEnd={2} colStart={1} colSpan={4}>
          <Field
            as={Select}
            label="Основание"
            options={complainOrigins}
            name="complaintOriginId"
            error={errors.complaintOriginId}
            isDisabled={!isWrite}
          />
        </GridItem>
      </Grid>
      <DefaultText marginLeft={2} marginBottom={2} fontWeight="bold" fontSize="18px">
        Заявитель
      </DefaultText>
      <Grid
        marginLeft={2}
        marginBottom={3}
        marginTop={5}
        gap={3}
        templateRows="repeat(2, 1fr)"
        templateColumns="repeat(4, 1fr)"
      >
        <GridItem rowStart={1} rowEnd={1} colStart={1} colEnd={3}>
          <Field
            as={Input}
            label="ФИО Заявителя"
            name="complaintCitizenFio"
            error={errors.complaintCitizenFio}
            isDisabled={!isWrite}
          />
        </GridItem>
        <GridItem rowStart={1} rowEnd={1} colStart={3} colSpan={1}>
          <Field
            as={Input}
            label="Контакты: aaa@mail.ru"
            name="complaintCitizenEmail"
            error={errors.complaintCitizenEmail}
            isDisabled={!isWrite}
          />
        </GridItem>
        <GridItem rowStart={1} rowEnd={1} colStart={4} colSpan={1}>
          <Field
            as={Input}
            label="Контакты: +7(916) 111 1111"
            name="complaintCitizenPhone"
            error={errors.complaintCitizenPhone}
            isDisabled={!isWrite}
          />
        </GridItem>
        <GridItem rowStart={2} rowEnd={2} colStart={1} colSpan={4}>
          <Field
            as={Input}
            label="Адрес заявителя"
            name="complaintCitizenAddress"
            error={errors.complaintCitizenAddress}
            isDisabled={!isWrite}
          />
        </GridItem>
      </Grid>
      <DefaultText marginLeft={2} marginBottom={2} fontWeight="bold" fontSize="18px">
        Обращение
      </DefaultText>
      <Grid
        marginLeft={2}
        marginBottom={3}
        marginTop={5}
        gap={3}
        templateRows="repeat(1, 1fr)"
        templateColumns="repeat(4, 1fr)"
      >
        <GridItem rowStart={1} rowEnd={1} colStart={1} colEnd={1}>
          <Field
            as={Input}
            label="Номер обращения"
            name="complaintNumber"
            error={errors.complaintNumber}
            isDisabled={!isWrite}
          />
        </GridItem>
        <GridItem rowStart={1} rowEnd={1} colStart={2} colSpan={1}>
          <Field
            as={DateInput}
            name="complaintDate"
            label="Дата и время обращения"
            showTimeInput
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFieldValue('complaintDate', createUTCTime(e.target.value));
            }}
            error={errors.complaintDate}
            isDisabled={!isWrite}
          />
        </GridItem>
        <GridItem rowStart={1} rowEnd={1} colStart={3} colSpan={4}>
          <Field
            as={Checkbox}
            checked={values.complaintAnswerOnPaper}
            label="Ответ на бумаге"
            name="complaintAnswerOnPaper"
            isDisabled={!isWrite}
          />
        </GridItem>
      </Grid>
      <DefaultText marginLeft={2} marginBottom={2} fontWeight="bold" fontSize="18px">
        Содержание обращения:
      </DefaultText>
      <Grid
        marginLeft={2}
        marginBottom={3}
        marginTop={5}
        gap={3}
        templateRows="repeat(1, 1fr)"
        templateColumns="repeat(4, 1fr)"
      >
        <GridItem rowStart={1} rowEnd={1} colStart={1} colSpan={4}>
          <Field
            as={Textarea}
            placeholder="Текст обращения"
            name="complaintText"
            error={errors.complaintText}
            isDisabled={!isWrite}
          />
        </GridItem>
      </Grid>
      <DefaultText marginLeft={2} marginBottom={2} fontWeight="bold" fontSize="18px">
        Вопрос ЭДО
      </DefaultText>
      <Grid
        marginLeft={2}
        marginBottom={3}
        marginTop={5}
        gap={3}
        templateRows="repeat(1, 1fr)"
        templateColumns="repeat(4, 1fr)"
      >
        <FieldArray name="questions">
          {({ push, remove }) => (
            <Box flexDirection="column" width="100%">
              {values.questions &&
                values.questions.map((value, idx) => (
                  <Flex alignItems="start" key={idx}>
                    <Box marginBottom={3} width="800px">
                      <Field
                        as={Input}
                        key={idx}
                        name={`questions[${idx}]`}
                        label={`Вопрос ${idx + 1}`}
                        value={value}
                        error={errors.questions?.[idx]}
                        isDisabled={!isWrite}
                      />
                    </Box>
                    <Button leftIcon={<DeleteIcon />} onClick={() => remove(idx)} variant="ghost" size="sm" isDisabled={!isWrite} />
                  </Flex>
                ))}
              <Button
                marginLeft={0}
                leftIcon={<AddIcon />}
                onClick={() => push('')}
                variant="ghost"
                size="sm"
                color="PrimaryButton.500"
                isDisabled={!isWrite}
              >
                Добавить вопрос
              </Button>
            </Box>
          )}
        </FieldArray>
      </Grid>
      <Flex>
        <Button marginRight={3} onClick={() => backWithFallback(Appeals)}>
          Отмена
        </Button>
        <Button onClick={() => handleSubmit()} isDisabled={!isWrite}>Сохранить</Button>
      </Flex>
    </Box>
  );
};
