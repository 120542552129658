import * as yup from 'yup';
import { requiredError } from '../constans';
import { latitudeValidation } from 'models/schemas/location';

export const NsiReagentsSitesSchema = yup.object().shape({
  subsystemId: yup.string().nullable().required(requiredError),
  number: yup.number().nullable().required(requiredError),
  name: yup.string().nullable().required(requiredError),
  greenAreaTypeId: yup.string().nullable().required(requiredError),
  greenAreaLocationId: yup.string().nullable().required(requiredError),
  pgrAreaTypeId: yup.string().nullable().required(requiredError),
  status: yup.string().nullable().required(requiredError),

  okrugId: yup.string().nullable().required(requiredError),
  rayonId: yup.string().nullable().required(requiredError),
  address: yup.string().nullable(),
  landmark: yup.string().nullable(),
  ...latitudeValidation,
});
