export const fieldsLocale = {
  modelName: 'Модель',
  inventNumber: 'Инвентарный номер',
  serialNumber: 'Серийный номер',
  dateExpired: 'Срок истечения поверки',
  dateStart: 'Дата поверки прибора',
  dateEnd: 'Дата истечения срока поверки',
  docNumber: 'Номер свидетельства',
  localDate: 'Дата ввода в эксплуатацию',
  status: 'Статус',
  statusId: 'Статус',
  stationName: 'Станция',
  stationAddress: 'Адрес станции',
  deviceName: 'Имя устройства',
  checkStatus: 'Статус поверки',
  statusName: 'Статус прибора',
  station: 'Добавить на станцию',
  supplier: 'Поставщик',
  warrantyEndDate: 'Дата окончания гарантии'
};

export const devicesLocale = {
  measureDevices: 'Измерительные приборы',
  devices: 'Приборы',
};

export const deviceVerificationLocale = {
  name: 'Номер свидетельства о поверке',
  date: 'Дата выдачи свидетельства',
  dateStart: 'Начало действия поверки',
  dateExpired: 'Окончание действия поверки',
  status: 'Статус',
};

export const statusLocale: Record<string, string> = {
  IDLE: 'истёк',
  INWORK: 'действует',
  BROKEN: 'не поверен',
};

export const statuses: Record<string, string> = {
  ACTUAL: 'Актуальная',
  EXPIRED: 'Истекла'
};

export const messages = {
  update: 'Вы уверены, что хотите обновить статус?'
};
