import { useRequest } from 'hooks/useRequest';

interface IASKZAProfilemerDictionaryParameter {
  parameterCode: string;
  parameterId: string;
  parameterName: string;
}

interface IASKZAProfilemerDictionarySource {
  sourceId: string;
  sourceName: string;
  sourceNumber: number;
  parameters: IASKZAProfilemerDictionaryParameter[];
}

export interface IASKZAProfilemerDictionariesResponse {
  askzaDictionary: IASKZAProfilemerDictionarySource[];
  profilemerDictionary: IASKZAProfilemerDictionarySource[];
}

interface IUseDictionary {
  url: string;
}

function useASKZAProfilemerDictionaries({ url }: IUseDictionary) {
  const { post, isLoading, result } = useRequest<IASKZAProfilemerDictionariesResponse>(url);

  return {
    post,
    isDictionariesLoading: isLoading,
    askzaDictionary: result?.askzaDictionary,
    profilemerDictionary: result?.profilemerDictionary,
  };
}

export default useASKZAProfilemerDictionaries;
