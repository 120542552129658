import React, { useEffect, useMemo, useState } from 'react';
import { INODWater } from './types';
import appLocale from 'constants/appLocale';
import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import { addFiltersToColumns, ColumnWithFilter } from 'lib/utils/tableFilter';
import { TableFilterTypeEnum } from 'components/table/filters/TableFilter';
import { useList } from 'hooks';
import { debounce } from 'lodash';
import { IFilterItem, TFilterDTO, TFiltersArray } from 'models';
import { Column } from '@material-table/core';
import { useToolbarActions } from '../utils/toolbarActions';
import { createValuesToRequest } from 'features/Filter/utils';
import { SubsystemHeader } from 'components/common';
import { MaterialTable } from 'features/material-table/MaterialTableMini';
import { NODFilter } from '../components/NODFilter';
import { yearAndMonthValidationSchema } from '../components/NODValidationSchemas';
import { filtersToValues, valuesToFilters } from '../utils';
import { useNodRights } from '../utils/useNodRights';
import { subsystemCodesMap } from 'constants/subsystemIdsMap';
import { useGoToNODUploadLog } from 'hooks/useGoToNODUploadLog';
import { DEFAULT_PAGE_SIZE_NEW_TABLE } from '../../../../constants/tables';
import { FullPageWrapper } from '../../../../components/table/FullPageWrapper';

const NODWaterConcentraionBreadcrumbs = [
  { breadcrumb: appLocale.subsystems.analyticsReports, path: '/reports-analytics' },
  { breadcrumb: appLocale.analyticsReports.nod, path: '/reports-analytics/nod' },
  { breadcrumb: appLocale.analyticsReports.waterMonitoring, path: '/reports-analytics/nod/water-concentration' },
];

export const NODWaterConcentraion = () => {
  useUpdateBreadcrumbs(NODWaterConcentraionBreadcrumbs);
  const { isExecute } = useNodRights(subsystemCodesMap.nodReportsOtherCodes.waterMonitoring);
  const [presetValues, setPresetValues] = useState<{ key: string; value: IFilterItem['value'] }[]>();

  const {
    response,
    setFilters,
    filterDTO,
    isLoading: isItemsLoading,
    onPageChange,
    resetData,
    downloadAsFile,
    toggleOrder,
    errorText,
    selectedIndexes,
    renderOnlySelectedCheckbox,
    onSelectionChange,
    setSelectedIds,
    onSubmitFilters,
    saveFilters,
    selectedIds,
  } = useList<INODWater, keyof INODWater>('/ehd/v10/v1/catalog-fetcher/reports/12', {
    preventFirstFetch: true,
    initialOrderFieldKey: '-period',
    selectionKey: 'id',
    restoreSelectedFromUxMetadata: true,
    disableUxMetadata: false,
    defaultPageSize: DEFAULT_PAGE_SIZE_NEW_TABLE,
    resetDataOnNextRequest: true,
  });

  const saveFilterDebounce = useMemo(() => debounce(async () => {
    saveFilters();
  }, 500, { trailing: true, leading: false }), [saveFilters]);

  const columns = useMemo<Array<ColumnWithFilter<INODWater>>>(
    () => [
      {
        title: 'Код',
        field: 'ehdCode',
      },
      {
        title: 'Период измерения',
        field: 'period',
      },
      {
        title: 'Тип водного объекта',
        field: 'freshwaterBodyType',
        filterType: TableFilterTypeEnum.string,
      },
      {
        title: 'Название водного объекта',
        field: 'freshwaterBodyName',
        filterType: TableFilterTypeEnum.string,
      },
      {
        title: 'Название створа наблюдений',
        field: 'samplingSiteName',
        filterType: TableFilterTypeEnum.string,
      },
      {
        title: 'Параметр',
        field: 'parameterName',
        filterType: TableFilterTypeEnum.string,
      },
      {
        title: 'Среднемесячная концентрация',
        field: 'avgConcentration',
      },
      {
        title: 'Среднемесячная концентрация в долях ПДКрыб-хоз',
        field: 'avgConcentrationForFish',
      },
      {
        title: 'Среднемесячная концентрация в долях ПДКкульт-быт',
        field: 'avgConcentrationForRecreation',
      },
      {
        title: 'Код створа',
        field: 'idSs',
        filterType: TableFilterTypeEnum.string,
      },
    ],
    []
  );

  const columnsWithFilters = useMemo<Array<Column<INODWater>>>(
    () =>
      addFiltersToColumns({
        filters: filterDTO.filters,
        setFilters,
        columns,
      }),
    [filterDTO.filters, setFilters, columns]
  );

  const onClear = () => {
    onSubmitFilters([]);
    resetData();
    setFilters([]);
    saveFilterDebounce();
  };

  useEffect(() => {
    saveFilterDebounce();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterDTO.filters, selectedIds]);

  const onSubmit = (values: any) => {
    const v: any = createValuesToRequest<any, any>(initialValues, values);

    const tmp = valuesToFilters(Object.values(v));
    const y = tmp.find((item) => item.key === 'year');
    const m = tmp.find((item) => item.key === 'month');

    const newSelectedIds = selectedIds?.map((item) => {
      if (y === undefined || m === undefined) {
        return item;
      }
      const arr = item.split('/');
      arr[0] = `${y.value}-${m.value < 10 ? `0${m.value}` : m.value}`;

      return arr.join('/');
    });

    // change ids in filter
    if (v.id?.value) {
      v.id.value = newSelectedIds;
    }

    setSelectedIds(newSelectedIds);
    const filters = Object.values(v);
    onSubmitFilters(valuesToFilters(filters), false);
  };

  useEffect(() => {
    const _filters = filterDTO.filters.filter((filter) => filter.key !== 'deleted') || null;
    if (_filters != null) {
      setPresetValues(filtersToValues(_filters) as TFiltersArray);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterDTO.filters]);

  const { toolBarActions } = useToolbarActions(12, filterDTO, response?.data.length === 0);

  const initialValues = {
    year: {
      key: 'year',
      operation: 'EQ',
      value: null,
    },
    month: {
      key: 'month',
      operation: 'EQ',
      value: null,
    },
    id: {
      key: 'id',
      operation: 'IN',
      value: null,
    },
  };

  const { goToButtonText, goToButtonProps } = useGoToNODUploadLog(
    NODWaterConcentraionBreadcrumbs,
    12,
    appLocale.analyticsReports.waterMonitoring,
    '/reports-analytics/nod/water-concentration'
  );

  return (
    <FullPageWrapper>
      <SubsystemHeader
        title={appLocale.analyticsReports.waterMonitoring}
        anyButtonProps={goToButtonProps}
        anyButtonText={goToButtonText}
        filtersComponent={
          <NODFilter
            presetValues={presetValues || []}
            initialValues={initialValues}
            onClear={onClear}
            onSubmit={onSubmit}
            mainFields={[
              {
                type: 'DATE',
                name: 'year',
                label: 'Год',
                showYearPicker: true,
              },
              {
                type: 'DATE',
                name: 'month',
                label: 'Месяц',
                isMonthPicker: true,
              },
            ]}
            validationSchema={yearAndMonthValidationSchema}
          >
            {renderOnlySelectedCheckbox}
          </NODFilter>
        }
        defaultFiltersOpen
      />
      <MaterialTable
        isLoading={isItemsLoading}
        filterDTO={filterDTO as TFilterDTO}
        columns={columnsWithFilters}
        data={response?.data}
        meta={response?.meta}
        downloadAsFile={downloadAsFile}
        toolbarActions={toolBarActions}
        onPageChange={onPageChange}
        toggleOrder={toggleOrder}
        selectedIndexes={selectedIndexes}
        onSelectionChange={onSelectionChange}
        options={{
          selection: true,
          toolbar: isExecute,
        }}
        errorText={errorText}
        printLoggerUrl="/ehd/v10/v1/catalog-fetcher/reports/12/print"
      />
    </FullPageWrapper>
  );
};
