import { isEqual } from 'lodash';
import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';

export interface BreadcrumbsRoute {
  breadcrumb: string;
  path: string;
  isLoading?: boolean;
}

interface Context {
  breadcrumbs: BreadcrumbsRoute[];
  updateBreadcrumbs: (nextBreadcrumbs: BreadcrumbsRoute[]) => void;
}

const context = createContext<Partial<Context>>({});

export const useBreadcrumbs = () => {
  const ctx = useContext(context);

  return ctx;
};

export const useUpdateBreadcrumbs = (breadcrumbs?: BreadcrumbsRoute[]) => {
  const { updateBreadcrumbs } = useBreadcrumbs();

  useEffect(() => {
    if (typeof updateBreadcrumbs === 'function' && breadcrumbs) {
      updateBreadcrumbs(breadcrumbs);
    }
  }, [breadcrumbs, updateBreadcrumbs]);
};

export const BreadcrumbsProvider: React.FC = (props) => {
  const [breadcrumbs, setBreadcrumbs] = useState<BreadcrumbsRoute[]>([]);

  const updateBreadcrumbs = useCallback(
    (nextBreadcrumbs: BreadcrumbsRoute[]) => {
      if (isEqual(breadcrumbs, nextBreadcrumbs)) {
        return;
      }

      setBreadcrumbs(nextBreadcrumbs);
    },
    [breadcrumbs],
  );

  return <context.Provider value={{ breadcrumbs, updateBreadcrumbs }} {...props} />;
};
