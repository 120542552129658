import { useRequest } from 'hooks/useRequest';
import { useUtilDictionaries } from 'hooks/useUtilDictionaries';
import { TDictionariesArray } from 'models';
import { useEffect, useMemo } from 'react';
import { GreenPlantsApiRootPath } from 'pages/routes/GreenPlants';
import { cloneDeep, get, set } from 'lodash';
import { parameterToParameterIdMap } from '../../../../features/parametersMap';

type TDictionaries = 'areaType' | 'areaParameters' | 'lawnParameters' | 'areas' | 'lawns' | 'objectType' | 'rayons' | 'okrugs';

type TLawnAreasParametersDictionariesItemNames =
  | 'lawnType'
  | 'hasFlowerGarden'
  | 'lawnStateType'
  | 'lawnState'
  | 'flowerGardenState'
  | 'shrubCount'
  | 'treeCount';

export type TLawnLawnParameteresDictionariesItemNames =
  | 'cereals'
  | 'howManyCereals'
  | 'moss'
  | 'howManyMosses'
  | 'lawnColor'
  | 'weeds'
  | 'howManyWeeds'
  | 'garbageScope'
  | 'microrelief'
  | 'denseGrass'
  | 'turfFelt'
  | 'decorativeLawns'
  | 'garbage'
  | 'howManyPests'
  | 'howManyDiseases'
  | 'heightGrass'
  | 'squareWithYellowGrass'
  | 'baldPatches'
  | 'projCover'
  | 'countMosses'
  | 'countWeeds'
  | 'countCereals'
  | 'otherDamage'
  | 'pests'
  | 'diseases'
  | 'recommendations';

type TLawnDictionariesNames = TLawnAreasParametersDictionariesItemNames | TLawnLawnParameteresDictionariesItemNames;

type TLawnsDictionariesArray = TDictionariesArray<TDictionaries, TLawnDictionariesNames>;

type TLawnsDictionaries = Record<TDictionaries, TLawnsDictionariesArray[number]['dictionary']>;

const nestedDictsKeys = ['areaParameters', 'lawnParameters'];

const useLawnsDictionaries = () => {
  const { get: getDictionaries, isLoading, result, error } = useRequest<{
    data: TLawnsDictionariesArray;
  }>(`${GreenPlantsApiRootPath}/lawns/dictionaries`);

  const {
    getItemFromDictionaryByFieldValue,
    getItemsFromDictionaryByFieldValue,
    getItemsFromDictionaryByParent,
    ...dictionaries
  } = useUtilDictionaries(result?.data ?? []);

  const formattedDictionaries = useMemo(() => {
    const dicts = cloneDeep(dictionaries);
    Object.keys(dicts).forEach((dictionaryKey) => {
      if (nestedDictsKeys.includes(dictionaryKey)) {
        const dict = get(dicts, dictionaryKey).map((dict: any) => (
          {
            ...dict,
            name: parameterToParameterIdMap[dict.parameterId],
            dictionary: Object.values(dict.values),
          }
        ));
        set(dicts, dictionaryKey, dict);
      }
    });

    return dicts;
  }, [dictionaries]);

  useEffect(() => {
    void getDictionaries();
  }, [getDictionaries]);

  return {
    getItemFromDictionaryByFieldValue,
    getItemsFromDictionaryByFieldValue,
    getItemsFromDictionaryByParent,
    isLoading,
    dictionaries: formattedDictionaries,
    result,
    error,
  };
};

export { useLawnsDictionaries };

export type { TLawnsDictionariesArray, TLawnDictionariesNames, TLawnsDictionaries };
