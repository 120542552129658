export const unitLocales = {
  unitsDict: 'Единицы измерения',
  unitAdding: 'Добавление единиц измерений',
  unitEditing: 'Редактирование единиц измерений',
  name: 'Наименование единицы измерения',
  shortName: 'Сокращенное наименование',
  code: 'Код',
  localName: 'Наименование для РФ',
  intName: 'Наименование международное',
  localCode: 'Код РФ',
  intCode: 'Международный код',
  searchRequest: 'Введите поисковый запрос'
};
