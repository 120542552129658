import { SUCCESS_MESSAGES } from 'constants/messages';
import { useNotifications } from 'hooks/useNotifications';
import { useRequest } from 'hooks/useRequest';
import { forEach } from 'lodash';
import { ApiError } from 'models';
import { useCallback } from 'react';
import { MeteoDataMonitoringDataPath, MeteoDataProfilemerApiRoot } from 'pages/data/Meteo/menu';
import { useGoBackWithFallback } from 'lib/utils/goBackWithFallback';

interface MeteoDataMeasurement {
  sourceId: string;
  id: string | null;
  value: number | string;
}

interface MeteoDataAddItemRequest {
  dateTime: string | null;
  measurements: MeteoDataMeasurement[];
  stationId: string;
  sourceTypeId?: string;
}

function useMeteoDataAddItem() {
  const { backWithFallback } = useGoBackWithFallback();

  const { successNotify, errorNotify } = useNotifications();

  const { post, isLoading, result, error } = useRequest<
  MeteoDataAddItemRequest,
  { descriptions: { message: string }[] } & ApiError
  >(
    `${MeteoDataProfilemerApiRoot}`,
    () => {
      successNotify({
        key: 'meteoDataAddItem',
        message: SUCCESS_MESSAGES.CREATE,
      });
      backWithFallback(MeteoDataMonitoringDataPath);
    },
    (err) => {
      if (err?.descriptions) {
        forEach(err.descriptions, (desc, index) => {
          errorNotify({
            key: `meteoDataAddItem/${index}`,
            message: desc.message || 'Неизвестная ошибка.',
          });
        });
      }
    },
  );

  const meteoDataAddItem = useCallback((request: MeteoDataAddItemRequest) => post(request), [post]);
  return {
    meteoDataAddItem,
    isLoading,
    result,
    error,
  };
}

export type { MeteoDataAddItemRequest, MeteoDataMeasurement };

export { useMeteoDataAddItem };
