import React, { Fragment, useEffect, useMemo } from 'react';
import { FormWrapper } from 'components/common';

import { getSelectOptionsFromDictionaryByName } from 'features/get-select-options-from-dictionary';
import { useFormikContext } from 'formik';
import { TDictionariesArray } from 'models/dictionaries';
import { Grid } from '@chakra-ui/react';
import { SoilDeicingItem } from 'pages/data/PGR/Soil';
import { IRenderFieldInterface, useFormField } from 'hooks/forms/useFormField';
import appLocale from 'constants/appLocale';
import { ANALYTICS_STATUS } from 'pages/analytics/forms/const';
import { AnalyticsPGRSoilSchema } from 'models/schemas/analytics/analyticsSoilSchema';

interface CommonTabProps {
  dictionaries: {
    data: TDictionariesArray;
  } | null;
  isEditMode?: boolean;
}

export const AnalyticsSoilDeicingCommonTab: React.FC<CommonTabProps> = ({ dictionaries, isEditMode = false }) => {
  const { values, setFieldValue, initialValues } = useFormikContext<SoilDeicingItem>();

  const { renderField } = useFormField<SoilDeicingItem>({
    schema: AnalyticsPGRSoilSchema,
    labelLocalization: appLocale.analytics.fields,
  });

  const organizations = useMemo(
    () =>
      getSelectOptionsFromDictionaryByName(dictionaries?.data, 'organizations', false, [
        initialValues.consumerId,
        initialValues.organizationId,
      ]),
    [dictionaries?.data, initialValues.consumerId, initialValues.organizationId],
  );

  const areas = useMemo(() => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'areas'), [dictionaries]);

  const distances2RoadExt = useMemo(
    () => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'distances2RoadExt'),
    [dictionaries],
  );

  const filteredDistances2RoadExt = useMemo(
    () => distances2RoadExt.filter((item) => item.parent === values.sourceNameId),
    [distances2RoadExt, values.sourceNameId],
  );

  const depthsExt = useMemo(
    () => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'depthsExt'),
    [dictionaries],
  );

  const filteredDepthsExt = useMemo(
    () => depthsExt.filter((item) => item.parent === values.distance2RoadId),
    [depthsExt, values.distance2RoadId],
  );

  // while status is hidden assume status as draft
  let fieldsIsDisabled = values.status === ANALYTICS_STATUS.APPROVED && !!isEditMode;
  fieldsIsDisabled = false;

  useEffect(() => {
    if (values.distance2RoadId && dictionaries) {
      const point = distances2RoadExt.find((item) => item.value === values.distance2RoadId);
      if (point?.parent !== values.sourceNameId) {
        setFieldValue('distance2RoadId', null);
      }
    }
  }, [dictionaries, distances2RoadExt, setFieldValue, values.distance2RoadId, values.sourceNameId]);

  useEffect(() => {
    if (values.depthId && dictionaries) {
      const point = depthsExt.find((item) => item.value === values.depthId);
      if (point?.parent !== values.distance2RoadId) {
        setFieldValue('depthId', null);
      }
    }
  }, [depthsExt, dictionaries, setFieldValue, values.depthId, values.distance2RoadId]);

  const fields: IRenderFieldInterface<SoilDeicingItem>[] = [
    { name: 'protocol', isDisabled: fieldsIsDisabled },

    { name: 'protocolDate', type: 'dateinput', isDisabled: fieldsIsDisabled },

    { name: 'consumerId', type: 'select', options: organizations, isDisabled: fieldsIsDisabled },

    { name: 'researchBasis', isDisabled: fieldsIsDisabled },

    { name: 'researchObject', isDisabled: fieldsIsDisabled },

    { name: 'address', isDisabled: fieldsIsDisabled },

    { name: 'samplePlace', isDisabled: fieldsIsDisabled },

    { name: 'organizationId', type: 'select', options: organizations, isDisabled: fieldsIsDisabled },

    { name: 'methodology', isDisabled: fieldsIsDisabled },

    { name: 'sampleAct', isDisabled: fieldsIsDisabled },

    { name: 'sampleLaboratoryNumber', isDisabled: fieldsIsDisabled },

    { name: 'selectionDate', type: 'datetimeinput', isDisabled: fieldsIsDisabled },

    { name: 'sampleReceiveDate', type: 'datetimeinput', isDisabled: fieldsIsDisabled },

    { name: 'researchDateStart', type: 'dateinput', isDisabled: fieldsIsDisabled },

    { name: 'researchDateEnd', type: 'dateinput', isDisabled: fieldsIsDisabled },

    { name: 'explanation', isDisabled: fieldsIsDisabled },

    { name: 'supportingDocuments', isDisabled: fieldsIsDisabled },

    { name: 'sourceNameId', type: 'select', options: areas, isDisabled: fieldsIsDisabled },

    {
      name: 'distance2RoadId',
      type: 'select',
      options: filteredDistances2RoadExt,
      isDisabled: fieldsIsDisabled || !values.sourceNameId,
    },

    {
      name: 'depthId',
      type: 'select',
      options: filteredDepthsExt,
      isDisabled: fieldsIsDisabled || !values.distance2RoadId,
    },
  ];

  return (
    <FormWrapper>
      <Grid marginTop="30px" gap="15px">
        {fields.map((field) => (
          <Fragment key={field.name}>{renderField(field)}</Fragment>
        ))}
      </Grid>
    </FormWrapper>
  );
};
