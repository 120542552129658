import * as yup from 'yup';
import { maxSymbols, numberError, requiredError } from '../constans';

export const AnalyticsSnowSchema = yup.object().shape({
  protocolNumber: yup.string().required(requiredError).nullable().max(15, maxSymbols(15)),
  protocolDate: yup.string().typeError(requiredError).required(requiredError),
  consumerId: yup.string().required(requiredError).nullable(),
  areaId: yup.string().required(requiredError).nullable(),
  pointId: yup.string().required(requiredError).nullable(),
  organizationId: yup.string().required(requiredError).nullable(),
  methodology: yup.string().required(requiredError).nullable().max(511, maxSymbols(511)),
  sampleAct: yup.string().required(requiredError).nullable().max(63, maxSymbols(63)),
  sampleLaboratoryNumber: yup.number().typeError(numberError).required(requiredError).nullable(),
  samplePeriodStart: yup.string().typeError(requiredError).required(requiredError),
  samplePeriodEnd: yup.string().typeError(requiredError).required(requiredError),
  sampleReceiveDate: yup.string().typeError(requiredError).required(requiredError),
  researchDateStart: yup.string().typeError(requiredError).required(requiredError),
  researchDateEnd: yup.string().typeError(requiredError).required(requiredError),
  explanation: yup.string(),
  researchBasis: yup.string().nullable().max(255, maxSymbols(255)),
  supportingDocuments: yup.string().nullable().max(255, maxSymbols(255)),
  status: yup.string().required(requiredError).nullable(),

  parameters: yup.array().of(
    yup.object().shape({
      value: yup.number().typeError(numberError).nullable().positive('Должно быть положительным'),
    })
  ),
  sign: yup
    .object()
    .shape({
      signers: yup.array().of(
        yup.object().shape({
          action: yup.string().required(requiredError).nullable(),
          position: yup.string().required(requiredError).nullable().max(511, maxSymbols(511)),
          name: yup.string().required(requiredError).nullable().max(255, maxSymbols(255)),
        })
      ),
    })
    .nullable(),
});
