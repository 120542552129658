import { Button, DateInput, FormWrapper, Select } from 'components/common';
import { Field, useFormikContext } from 'formik';
import { useHistory } from 'react-router-dom';
import { INMUListItem } from '../../types';
import { createDateFromResponse, createLocalDateISO8601 } from 'lib/create-date';
import React, { useMemo } from 'react';
import { useNMUDictionaries } from '../../useNMUDictionaries';
import { getSelectOptionsFromDictionaryByName } from 'features/get-select-options-from-dictionary';
import { useGetRightsBySubsystem } from 'hooks/useGetRightsBySubsystem';
import { Box, Flex, Grid } from '@chakra-ui/react';

export const FormFields = () => {
  const { handleSubmit, errors, setFieldValue, values } = useFormikContext<INMUListItem>();
  const history = useHistory();
  const { userRights } = useGetRightsBySubsystem();

  const analysWrite = useMemo(
    () => !!userRights?.find((item) => item.code === 'SUBSYSTEM__NMU.METEO_ANALYSIS_WRITE'),
    [userRights]
  );
  const forecastWrite = useMemo(
    () => !!userRights?.find((item) => item.code === 'SUBSYSTEM__NMU.METEO_PROGNOSIS_WRITE'),
    [userRights]
  );

  const rightsCHeckerForDatepicker = useMemo(() => {
    if (analysWrite && !forecastWrite) {
      return {
        maxDate: new Date(),
        minDate: undefined,
      };
    }
    if (forecastWrite && !analysWrite) {
      return {
        maxDate: undefined,
        minDate: new Date(),
      };
    }
    return {
      maxDate: undefined,
      minDate: undefined,
    };
  }, [analysWrite, forecastWrite]);

  const { result } = useNMUDictionaries();

  const types = useMemo(() => getSelectOptionsFromDictionaryByName(result?.data, 'nmuGrade'), [result]);

  return (
    <Box flexDirection="column" mb={4}>
      <FormWrapper>
        <Grid marginTop="30px" gap="15px">
          <DateInput
            name="date"
            label="Дата"
            maxDate={rightsCHeckerForDatepicker?.maxDate}
            minDate={rightsCHeckerForDatepicker?.minDate}
            value={createDateFromResponse(values.date)}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFieldValue('date', createLocalDateISO8601(e.target.value));
            }}
            error={errors.date}
          />
          <Field as={Select} options={types} name="grade" label="Тип НМУ" error={errors.grade} />

          <Flex justifyContent="space-between">
            <Button onClick={() => history.push('/data/meteo/nmu')}>Отмена</Button>
            <Button onClick={() => handleSubmit()} variant="solid">
              Сохранить
            </Button>
          </Flex>
        </Grid>
      </FormWrapper>
    </Box>
  );
};
