import { useHistory, useParams } from 'react-router-dom';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { IUploadMedia } from 'components/common/FileUploadDragAndDrop/types';
import { FormikProvider, useFormik } from 'formik';
import { createTouchedErrors } from 'features/create-touched-errors';
import { ApiError } from 'models';
import { useEnhancedNotification, useNotifications, useRequest } from 'hooks';
import { useObjectType } from 'hooks/useObjectType';
import { formatErrorMessage } from 'lib/utils/formatErrorMessage';
import { ERROR_MESSAGES } from 'constants/messages';
import { updateFiles } from 'api/services/media';
import { LoaderItem } from 'components/common';
import { IReportProtocolForm, ReportProtocolGetDto } from 'pages/analytics/Raids/types';
import { formatFormForRequest, formatRequestToForm } from 'pages/analytics/Raids/utils';
import {
  AnalyticsAiAtmosphereFormFields,
} from 'pages/analytics/Raids/Pel/PelAiAtmoshphere/AnalyticsAiAtmosphereFormFields';
import { ANALYTICS_LAB_PATHS } from 'pages/analytics/consts/AnalyticsTableRoutes';
import { AnalyticsPelAiAtmosphereSchema } from 'models/schemas/analytics/analyticsPelAiAtmosphereSchema';
import { generateUUID } from 'lib/utils/generateUUID';
import { useGoBackWithFallback } from 'lib/utils/goBackWithFallback';
import { useDictionary } from 'hooks/useDictionary';

const AnalyticsAiAtmosphereEdit = () => {
  const [browserId] = useState(generateUUID());
  const { id } = useParams<{ id: string }>();
  const { replace } = useHistory();
  const { backWithFallback } = useGoBackWithFallback();
  const [media, setMedia] = useState<IUploadMedia[]>([]);

  const {
    isDictionariesLoading,
    objectType,
    dictionaries
  } = useDictionary({ url: '/appeal/v10/protocols/dictionaries' });
  const meteoParametersIds = useMemo(
    () =>
      dictionaries?.data
        .find((dictionary) => dictionary.name === 'airAiMeteoParameters')
        ?.dictionary?.map((item) => item.value) || [],
    [dictionaries]
  );
  const _objectType = useObjectType(objectType);

  const { onSuccessCreate } = useEnhancedNotification();
  const { errorNotify } = useNotifications();

  const {
    get,
    result: protocol,
    isLoading: isItemLoading,
  } = useRequest<ReportProtocolGetDto>(`/appeal/v10/protocols/${id}`);
  useEffect(() => {
    void get();
  }, [get]);

  useEffect(() => {
    if (protocol?.ai === false) {
      replace(`${ANALYTICS_LAB_PATHS.raids.pel.edit}/${protocol.id}`);
    }
  }, [protocol, replace]);

  const onError = useCallback(
    (error: ApiError | string | null) => {
      const message = formatErrorMessage(error);
      errorNotify({
        key: message || 'file/add/error',
        message: message || ERROR_MESSAGES.EDIT,
      });
    },
    [errorNotify]
  );

  const onSuccess = (response: ReportProtocolGetDto | null) => {
    if (response?.id) {
      onSuccessCreate();
      updateFiles(response.id, _objectType, media)
        .then(() => {
          backWithFallback('/analytics');
        })
        .catch((error: Error) => {
          onError(error.message);
        });
    }
  };

  const { put } = useRequest('', onSuccess, onError);

  const initialValues = useMemo<IReportProtocolForm>(
    () =>
      formatRequestToForm(
        // TODO: странности на бекенде - вопросы к Алексею Лахтадыру
        // @ts-ignore
        { ...protocol, latitude: protocol?.sampleLatitude, longitude: protocol?.sampleLongitude },
        meteoParametersIds
      ),
    [meteoParametersIds, protocol]
  );

  const formik = useFormik<IReportProtocolForm>({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    validationSchema: AnalyticsPelAiAtmosphereSchema,
    initialValues,
    onSubmit: (values) => {
      void put(formatFormForRequest(values), { url: '/appeal/v10/protocols' });
    },
  });

  if (isDictionariesLoading || isItemLoading) {
    return <LoaderItem />;
  }

  return (
    <div>
      <FormikProvider
        value={{
          ...formik,
          errors: createTouchedErrors(formik.errors, formik.touched),
        }}
      >
        <AnalyticsAiAtmosphereFormFields
          dictionaries={dictionaries}
          media={media}
          onMediaChange={setMedia}
          objectType={_objectType}
          isLoading={isDictionariesLoading}
          browserId={browserId}
          isEditMode
        />
      </FormikProvider>
    </div>
  );
};

export default AnalyticsAiAtmosphereEdit;
