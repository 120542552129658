import { Field, useFormikContext } from 'formik';
import { Input } from 'components/common';
import { Flex, Grid } from '@chakra-ui/react';
import React from 'react';
import FilterFormControls from 'components/form/FilterFormControls';

export const Filter = () => {
  const { handleSubmit, handleReset } = useFormikContext();

  return (
    <Flex>
      <Grid width="100%" maxWidth="600px" gap="12px" rowGap="8px" templateColumns="repeat(2, 1fr)">
        <Field as={Input} name="number.value" label="№ камеры" />
        <Field as={Input} name="address.value" label="Адрес" />
      </Grid>

      <FilterFormControls
        onReset={handleReset}
        onSubmit={() => handleSubmit()}
      />
    </Flex>
  );
};
