import { useRequest } from 'hooks/useRequest';
import { useCallback, useMemo, useState } from 'react';
import {
  CalculationsRequest,
  CalculationValues,
  DriftPreviewRequest,
  ICalculatePreviewRequest,
} from 'lib/calculations/types';
import DriftPreview from 'components/common/DriftPreview/DriftPreview';
import { Column } from '@material-table/core';
import { useNotifications } from 'hooks/useNotifications';
import { ERROR_MESSAGES } from 'constants/messages';
import CalculationPreview from '../components/common/CalculationPreview/CalculationPreview';
import { useEnhancedNotification } from 'hooks';

interface UseCalculations<T extends object> {
  onSuccess?: () => void;
  apiPath: string;
  previewPath?: string;
  driftPath?: string;
  columns?: Column<T>[];
  refetch?: () => void;
}

function useCalculations<T extends object>({
  onSuccess,
  apiPath,
  driftPath,
  previewPath,
  columns,
  refetch,
}: UseCalculations<T>) {
  const [driftRequest, setDriftRequest] = useState<DriftPreviewRequest>();
  const [preview, setPreview] = useState();
  const [calculateRequest, setCalculateRequest] = useState<ICalculatePreviewRequest>();
  const [operationId, setOperationId] = useState<CalculationValues['operationId']>();
  const { errorNotify } = useNotifications();
  const { onSuccessCreate, onSuccessDelete } = useEnhancedNotification();

  const onError = (error: any) => {
    const errorMessage = error?.descriptions[0]?.message || '';
    errorNotify({
      key: 'file/get/error',
      message: `${ERROR_MESSAGES.CALCULATE}. ${errorMessage}`,
    });
  };

  const apiRoutes = useMemo(
    () => ({
      calculation: `${apiPath}/calculations`,
      driftPreview: `${driftPath || apiPath}/drift/preview`,
      drift: `${driftPath || apiPath}/drift`,
      preview: `${previewPath}/calculate/preview`,
      calculate: `${previewPath}/calculate`,
      delete: `${previewPath}/bulk-deletion`,
      deletePreview: `${previewPath}/bulk-deletion/preview`,
    }),
    [apiPath, previewPath, driftPath],
  );

  const { post, isLoading, result, error } = useRequest(apiRoutes.calculation, onSuccess, onError);

  const { post: apply } = useRequest(
    apiRoutes.calculation,
    () => {
      if (onSuccess) {
        onSuccess();
      }
      onSuccessCreate(true);
    },
    onError,
  );
  const { post: del } = useRequest(
    apiRoutes.calculation,
    () => {
      if (onSuccess) {
        onSuccess();
      }
      onSuccessDelete(true);
    },
    onError,
  );

  const calculation = useCallback(
    (request: CalculationsRequest) => {
      void post(request);
    },
    [post],
  );

  const onDriftPreview = useCallback((request: DriftPreviewRequest) => {
    setDriftRequest(request);
  }, []);

  const onDriftPreviewClose = useCallback(() => {
    setOperationId(undefined);
    setDriftRequest(undefined);
    if (onSuccess) {
      onSuccess();
    } else if (refetch) {
      refetch();
    }
  }, [onSuccess, refetch]);

  const renderDriftPreview = useMemo(() => {
    if (!columns || !driftRequest) return null;

    return (
      <DriftPreview apiRoutes={apiRoutes} driftRequest={driftRequest} columns={columns} onClose={onDriftPreviewClose} />
    );
  }, [apiRoutes, columns, driftRequest, onDriftPreviewClose]);

  const calculatePreview = useCallback(
    async (request: ICalculatePreviewRequest, values: CalculationValues) => {
      setOperationId(values.operationId);
      if (values.operationId === 'drift') {
        let data: DriftPreviewRequest = { ids: values.ids, k1: values.firstCoefficient, k2: values.secondCoefficient };
        if (values.includeParameterId) {
          data = { ...data, parameterId: values.parameterId };
        }
        void onDriftPreview(data);
        return;
      }
      let response;
      if (values.operationId === 'delete') {
        response = await post(
          { ...request, dependentParameterIds: values.dependentParameterIds || [] },
          { url: apiRoutes.deletePreview },
        );
      } else {
        response = await post(
          { ...request, dependentParameterIds: values.dependentParameterIds || [] },
          { url: apiRoutes.preview },
        );
      }

      if (response.deleted) {
        response.before = response.deleted;
        response.after = response.deleted.map((x: any) => ({ ...x, value: '-' }));
      }

      if (response.before) {
        setPreview(response);
        setCalculateRequest({ ...request, dependentParameterIds: values.dependentParameterIds || [] });
      }
    },
    [apiRoutes, onDriftPreview, post],
  );

  const applyCalculation = useCallback(
    (values: CalculationValues) => {
      if (values.ids.length === 0) return;

      if (values.operationId !== 'drift') {
        void calculation({
          ids: values.ids,
          operation: values.operationId,
          processDependentValues: values.processDependentValues,
          value: values.value,
          dependentParameterIds: values.dependentParameterIds || [],
        });
      } else {
        void onDriftPreview({ ids: values.ids, k1: values.firstCoefficient, k2: values.secondCoefficient });
      }
    },
    [calculation, onDriftPreview],
  );

  const calculateApply = useCallback(
    async (request?: ICalculatePreviewRequest) => {
      if (!request) return;

      if (operationId === 'delete') {
        await del(request, { url: apiRoutes.delete });
      } else {
        await apply(request, { url: apiRoutes.calculate });
      }

      setOperationId(undefined);
      setPreview(undefined);
      setCalculateRequest(undefined);
      if (refetch) {
        refetch();
      }
    },
    [apiRoutes.calculate, apiRoutes.delete, apply, del, operationId, refetch],
  );

  const renderPreview = useMemo(() => {
    if (!preview) return null;

    return (
      <CalculationPreview
        onSubmit={() => calculateApply(calculateRequest)}
        onClose={() => {
          setPreview(undefined);
          setOperationId(undefined);
        }}
        data={preview}
      />
    );
  }, [calculateApply, calculateRequest, preview]);

  return {
    applyCalculation,
    isCalculationLoading: isLoading,
    result,
    error,
    renderDriftPreview,
    calculatePreview,
    renderPreview,
  };
}

export default useCalculations;
