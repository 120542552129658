import React, { useMemo } from 'react';
import * as yup from 'yup';
import { createTouchedErrors } from 'features/create-touched-errors';
import { FormikProvider, useFormik } from 'formik';
import { DeviceModelParameter } from 'pages/equipment/Models/types';
import { FormFields } from 'pages/equipment/Models/components/ParameterForm/FormFields';
import { useDictionary } from 'hooks/useDictionary';
import { getSelectOptionsFromDictionaryByName } from 'features/get-select-options-from-dictionary';
import { requiredError } from 'models/schemas/constans';

export const validationSchema = yup.object().shape({
  orderNo: yup.number().required(requiredError),
  parameterId: yup.string().nullable().required(requiredError),
  purposeId: yup.string().nullable().required(requiredError),
  unitId: yup.string().nullable().required(requiredError),
  parameterCode: yup.string().nullable().required(requiredError),
  accuracy: yup.string().nullable().required(requiredError),
  averagePeriod: yup.string().nullable().required(requiredError),
});

interface ParameterFormProps {
  initialValues: Partial<DeviceModelParameter> | null;
  onSubmit: (values: Partial<DeviceModelParameter>) => void;
  onCancel: () => void;
}

export const ParameterForm: React.FC<ParameterFormProps> = ({ initialValues, ...props }) => {
  const { dictionaries } = useDictionary({ url: '/equipment/v10/equipments/parameters/dictionaries' });
  const parameters = useMemo(
    () => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'parameters'),
    [dictionaries?.data],
  );

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    validationSchema,
    initialValues:
      {
        ...initialValues,
        parameterCode: parameters?.find((item) => item.value === initialValues?.parameterId)?.title || '',
      } || {},
    onSubmit: props.onSubmit,
  });

  return (
    <FormikProvider
      value={{
        ...formik,
        errors: createTouchedErrors(formik.errors, formik.touched),
      }}
    >
      <FormFields onCancel={props.onCancel} parametersOptions={parameters} dictionaries={dictionaries} />
    </FormikProvider>
  );
};
