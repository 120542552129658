import { FormWrapper } from 'components/common';
import { Box, Grid, Heading } from '@chakra-ui/react';
import React, { FC, useCallback, useMemo } from 'react';
import { TDictionariesArray } from 'models/dictionaries';
import { Field, useFormikContext } from 'formik';
import { IReportProtocolForm } from 'pages/analytics/Raids/types';
import { TemperatureInput } from '../../../../../components/common/Input/temperature-input';
import { CommaNumberInput } from '../../../../../components/common/Input/comma-number-input';

interface IProps {
  dictionaries: {
    data: TDictionariesArray;
  } | null;
  isAi?: boolean;
}

export const AnalyticsPelAtmosphereMeteoParameters: FC<IProps> = ({ dictionaries, isAi }) => {
  const { setFieldValue } = useFormikContext<IReportProtocolForm>();
  const meteoParameters = useMemo(
    () => dictionaries?.data
      .find((dictionary) => dictionary.name === (isAi ? 'airAiMeteoParameters' : 'airMeteoParameters'))?.dictionary,
    [dictionaries?.data, isAi]
  );

  const parameterOnChange = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>, parameter) => {
      setFieldValue(`values[${parameter.value}].type`, parameter.type);
      setFieldValue(`values[${parameter.value}].value`, event.target.value);
      setFieldValue(`values[${parameter.value}].parameterId`, parameter.value);
    },
    [setFieldValue]
  );

  const temperatureIds = ['0d2a579b-555a-4678-abec-616a93c86c59', 'd8fb3b15-09bc-47f0-9143-2f0189241768'];

  return (
    <Box flexDirection="column">
      <FormWrapper>
        <Grid marginTop="30px" gap="15px">
          <Heading variant="h2">Данные обследования</Heading>
          {meteoParameters?.map((parameter) => (
            <Field
              key={parameter.value}
              as={temperatureIds.includes(parameter.value) ? TemperatureInput : CommaNumberInput}
              name={`values[${parameter.value}].value`}
              label={parameter.name}
              type={parameter.type === 'NUMERIC' ? 'number' : 'text'}
              onChange={(event: React.ChangeEvent<HTMLSelectElement>) => parameterOnChange(event, parameter)}
            />
          ))}
        </Grid>
      </FormWrapper>
    </Box>
  );
};
