import appLocale from 'constants/appLocale';
import { TABLE_VIEW } from '../citizenAppeal';

const appealsRootApiPath = '/appeal/v10/';
export const raidsJournalApi = `${appealsRootApiPath}/raids/list`;

export const PEL_ROOT_PATH = '/pel';

export const TASK_FORMER_PATH = `${PEL_ROOT_PATH}/add`;
export const RAID_LIST_PATH = `${PEL_ROOT_PATH}/raids`;
export const NOISE_RAID_LIST_PATH = `${PEL_ROOT_PATH}/noiseraids`;
export const ATMO_RAID_LIST_PATH = `${PEL_ROOT_PATH}/atmosphereraids`;
export const RAID_DETAIL_PATH = `${RAID_LIST_PATH}/:id`;
export const RAID_REPORT_PATH = `${RAID_DETAIL_PATH}/report`;
export const PROGRESS_REPORT_PATH = `${RAID_REPORT_PATH}/progress`;
export const GARBAGE_REPORT_PATH = `${RAID_REPORT_PATH}/garbage`;
export const BURNING_REPORT_PATH = `${RAID_REPORT_PATH}/burning`;
export const PELNoiseAdd = `${NOISE_RAID_LIST_PATH}/add`;
export const PELAtmoAdd = `${ATMO_RAID_LIST_PATH}/add`;
export const PELTableView = `${PEL_ROOT_PATH}/PELTableView`;
export const tableViewToReportPath = `${PEL_ROOT_PATH}/PELTableView/report/view`;

export const PELNoiseBreadcrumb = [
  {
    breadcrumb: appLocale.pel.main,
    path: PEL_ROOT_PATH,
  },
  {
    breadcrumb: appLocale.pel.noiseRaidList,
    path: NOISE_RAID_LIST_PATH,
  },
  {
    breadcrumb: 'Подготовка заданий',
    path: PELNoiseAdd,
  },
];

export const PELAtmoBreadcrumb = [
  {
    breadcrumb: appLocale.pel.main,
    path: PEL_ROOT_PATH,
  },
  {
    breadcrumb: appLocale.pel.atmosphereRaidList,
    path: ATMO_RAID_LIST_PATH,
  },
  {
    breadcrumb: 'Подготовка заданий',
    path: PELAtmoAdd,
  },
];

export const taskFormerBreadCrumbs = [
  {
    breadcrumb: appLocale.pel.main,
    path: PEL_ROOT_PATH,
  },
  {
    breadcrumb: 'Подготовка заданий',
    path: TASK_FORMER_PATH,
  },
];

export const noiseRaidTableBreadcrumb = [
  {
    breadcrumb: appLocale.pel.main,
    path: PEL_ROOT_PATH,
  },
  {
    breadcrumb: appLocale.pel.noiseRaidList,
    path: NOISE_RAID_LIST_PATH,
  },
];

export const atmoRaidTableBreadcrumb = [
  {
    breadcrumb: appLocale.pel.main,
    path: PEL_ROOT_PATH,
  },
  {
    breadcrumb: appLocale.pel.atmosphereRaidList,
    path: ATMO_RAID_LIST_PATH,
  },
];

export const tableViewToReportBreadcrumb = [
  {
    breadcrumb: appLocale.pel.main,
    path: PEL_ROOT_PATH,
  },
  {
    breadcrumb: TABLE_VIEW,
    path: PELTableView,
  },
  {
    breadcrumb: 'Подготовка заданий',
    path: tableViewToReportPath,
  },
];

export enum RaidType {
  atmosphere = 0,
  noise = 1,
}

export const initialEmptyValues = {
  taskTypeId: '',
  taskTypeName: '',
  id: '',
  raidDate: '',
  raidShiftId: '',
  taskTimeRange: '',
  taskDistrictIds: [''],
  raidNumber: 0,
  taskRegionIds: [''],
  taskTerritoryName: '',
  taskDeadlineDate: '',
  taskReasonId: '',
  taskPriorityId: '',
  raidTypeId: '',
  points: [
    {
      pointAddress: '',
      hz250: 0,
      hz125: 0,
      workTiming: '',
      id: '',
      pointLongitude: 0,
      isRepeat: false,
      aiProtocolNumber: '',
      pointNumber: 0,
      hz1000: 0,
      pelProtocolDate: '',
      hz8000: 0,
      hz500: 0,
      equivalentNoise: 0,
      isInformationBoard: false,
      isInformationBoardNMEPD: false,
      isSetPelProtocol: false,
      constructionObject: '',
      pelProtocolNumber: '',
      protocolExecutorUserId: '',
      maxNoise: 0,
      report: '',
      hz63: 0,
      measurementTimeTo: null,
      objectTypeId: '',
      aiProtocolDate: '',
      hz2000: 0,
      isRenovation: false,
      pointLatitude: 0,
      generalContractor: '',
      raidResultId: '',
      isSetAiProtocol: false,
      isWorkDone: false,
      hasExcess: false,
      territoryTypeId: '',
      hz31: 0,
      hz4000: 0,
      isViolation: false,
      pointCoordinateArray: '',
      protocolNumber: 0,
      measurementTimeFrom: null,
      pointTypeId: '',
      protocolDate: '',
      noiseCharacterId: '',
      customer: '',
      pelStationNumber: null,
      temporaryId: '',
      raidResultText: '',
    },
  ],
};

export const noiseId = 'c94886b9-7c1e-4b46-b290-51fe0e94060f';
