import { Button, Checkbox, DateInput, Input, Select, Textarea } from 'components/common';
import { Grid, GridItem } from '@chakra-ui/react';
import { Field, useFormikContext } from 'formik';
import { ILetter } from '../../types/types';
import React, { useContext, useMemo } from 'react';
import { getSelectOptionsFromDictionaryByName } from 'features/get-select-options-from-dictionary';
import { useAdminDictionaries } from '../../useAdminDictionaries';
import { TaskPrepareContext } from '../../../TaskPrepareContext';
import { createLocalDateISO8601 } from 'lib/create-date';

interface IProps {
  customClose: () => void;
  isDetailPanel: boolean | undefined;
}

export const LettersFormFields = ({ customClose, isDetailPanel }: IProps) => {
  const { complaints, dictionaries } = useContext(TaskPrepareContext);
  const authorities = useMemo(
    () => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'authorities'),
    [dictionaries]
  );
  const { result } = useAdminDictionaries();
  const { values, handleSubmit, setFieldValue } = useFormikContext<ILetter>();

  return (
    <>
      <Grid gap={3}>
        <Grid gap={3} templateColumns="repeat(3, 1fr)">
          <GridItem>
            <Field as={Input} name="territoryName" label="Наименование территории" isDisabled />
          </GridItem>
          <GridItem>
            <Field as={Select} name="authorityId" label="Структура" options={authorities} />
          </GridItem>
          <GridItem>
            <Field
              as={Select}
              name="complaintIds"
              label="Жалобы"
              multiple
              options={complaints.length ? complaints : []}
            />
          </GridItem>
        </Grid>
        <Grid gap={3} templateColumns="repeat(2, 1fr)">
          <GridItem>
            <Field as={Input} name="reason" label="Нарушение" />
          </GridItem>
          <GridItem>
            <Field
              as={Select}
              name="executorUserId"
              label="Исполнитель"
              options={result?.data?.map((item) => ({
                name: item.name,
                value: item.id,
              }))}
            />
          </GridItem>
        </Grid>
        <Field as={Textarea} name="appealEssence" label="Суть обращения" />
        <Grid gap={3} templateColumns="repeat(4, 1fr)">
          <GridItem>
            <Field as={Input} name="edoAgreementNumber" label="№ согла" />
          </GridItem>
          <GridItem>
            <Field
              as={DateInput}
              name="edoAgreementDate"
              label="От"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setFieldValue('edoAgreementDate', createLocalDateISO8601(e.target.value))
              }
            />
          </GridItem>
          <GridItem>
            <Field as={Input} name="outgoingNumber" label="Исходящий №" />
          </GridItem>
          <GridItem>
            <Field
              as={DateInput}
              name="outgoingDate"
              label="От"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setFieldValue('outgoingDate', createLocalDateISO8601(e.target.value))
              }
            />
          </GridItem>
          <GridItem>
            <Field
              as={Input}
              name="incomingNumber"
              label="Входящий № структуры"
            />
          </GridItem>
          <GridItem>
            <Field
              as={DateInput}
              name="incomingDate"
              label="От"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setFieldValue('incomingDate', createLocalDateISO8601(e.target.value))
              }
            />
          </GridItem>
        </Grid>
        <Grid gap={3}>
          <GridItem>
            <Field as={Checkbox} name="haveAnswer" checked={values.haveAnswer} label="Ответ" />
          </GridItem>
        </Grid>
      </Grid>
      <Grid gap={3} templateColumns="repeat(4, 1fr)">
        <GridItem>
          <Field as={Input} name="answer.outgoingNumber" label="Исходящий №" />
        </GridItem>
        <GridItem>
          <Field
            as={DateInput}
            name="answer.outgoingDate"
            label="От"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setFieldValue('answer.outgoingDate', createLocalDateISO8601(e.target.value))
            }
          />
        </GridItem>
        <GridItem>
          <Field as={Input} name="answer.incomingNumber" label="Входящий №" />
        </GridItem>
        <GridItem>
          <Field
            as={DateInput}
            name="answer.incomingDate"
            label="От"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setFieldValue('answer.incomingDate', createLocalDateISO8601(e.target.value))
            }
          />
        </GridItem>
      </Grid>
      <Grid marginTop={3} gap={3}>
        <Field as={Textarea} name="answer.takenActions" label="Принятые меры" />
        <Field as={Textarea} name="answer.content" label="Содержание ответа" />
        <Grid gap={3} templateColumns="repeat(2, 1fr)">
          <GridItem>
            <Field as={Input} name="answer.imposed" label="Наложено" />
          </GridItem>
          <GridItem>
            <Field as={Input} name="answer.collected" label="Взыскано" />
          </GridItem>
        </Grid>
        <Field as={Textarea} name="answer.stage" label="Стадия" />
        <Field as={Textarea} name="note" label="Примечания" />
        <Grid templateColumns="repeat(6, 1fr)" gap={5}>
          {isDetailPanel ? null : <Button onClick={customClose}>Отмена</Button>}
          <Button onClick={() => handleSubmit()}>Сохранить</Button>
        </Grid>
      </Grid>
    </>
  );
};
