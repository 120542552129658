import { createTouchedErrors } from 'features/create-touched-errors';
import { FormikProvider, useFormik } from 'formik';
import { useNotifications } from 'hooks/useNotifications';
import { useRequest } from 'hooks/useRequest';
import { useLocation } from 'react-router-dom';
import { useGeoInclinometerRecord } from 'hooks/geo/Inclinometer/useGeoInclinometerRecord';
import React, { useCallback, useMemo, useState } from 'react';
import { ERROR_MESSAGES, SUCCESS_MESSAGES } from 'constants/messages';
import { InclinometerSchema } from 'models/schemas/dangerprocess/danger';
import { GeoecologicalRootPath } from 'pages/data/GeoecologicalProcesses/consts';
import { initialEmptyValues } from './AddInclinometerResult';
import FieldsForm from './FieldsForm';
import { updateFiles } from 'api/services/media';
import { InclinometerItem } from 'hooks/geo/Inclinometer/useGeoInclinometerList';
import { IUploadMedia } from 'components/common/FileUploadDragAndDrop/types';
import { useObjectType } from 'hooks/useObjectType';
import { formatErrorMessage } from 'lib/utils/formatErrorMessage';
import { useGoBackWithFallback } from 'lib/utils/goBackWithFallback';
import { useDictionary } from 'hooks/useDictionary';
import { LoaderItem } from '../../../../../../components/common';

export const EditInclinometerResult = () => {
  const { backWithFallback } = useGoBackWithFallback();
  const location = useLocation();

  const editedObject = location.state;
  const { valDateTime, pointId } = editedObject;
  const { isDictionariesLoading, dictionaries } = useDictionary({
    url: '/geoecology/v10/geoecology/inclinometer/dictionaries',
  });
  const { result, isLoading } = useGeoInclinometerRecord(pointId, valDateTime);
  const [media, setMedia] = useState<IUploadMedia[]>([]);

  const initialValues = useMemo(
    () => ({
      ...initialEmptyValues,
      ...result,
      valDateTime: result?.date,
    }),
    [result],
  );

  const { successNotify, errorNotify } = useNotifications();

  const onSuccess = () => {
    successNotify({
      key: 'file/delete/success',
      message: SUCCESS_MESSAGES.EDIT,
    });

    updateFiles(editedObject.packageId, objectType, media)
      .then(() => {
        backWithFallback(`${GeoecologicalRootPath}/monitoring/danger/inclinometer`);
      })
      .catch((error: Error) => {
        onError(error.message);
      });
  };

  const objectType = useObjectType(dictionaries);

  const onError = useCallback(
    (message?: string | null) => {
      errorNotify({
        key: 'file/edit/error',
        message: formatErrorMessage(message) || ERROR_MESSAGES.EDIT,
      });
    },
    [errorNotify],
  );

  const { put, isLoading: isPutLoading } = useRequest<InclinometerItem[], any>(
    '/geoecology/v10/geoecology/inclinometer',
    onSuccess,
    onError,
  );

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    initialValues,
    validationSchema: InclinometerSchema,
    onSubmit: (values) => put(values),
  });

  if (isDictionariesLoading || isLoading || isPutLoading) {
    return <LoaderItem />;
  }

  return (
    <FormikProvider
      value={{
        ...formik,
        errors: createTouchedErrors(formik.errors, formik.touched),
      }}
    >
      <FieldsForm
        dictionaries={dictionaries}
        onCancel={() => {
          backWithFallback(`${GeoecologicalRootPath}/monitoring/danger/inclinometer`);
        }}
        media={media}
        onMediaChange={setMedia}
        uploadItemId={editedObject.packageId}
        isEditMode
      />
    </FormikProvider>
  );
};
