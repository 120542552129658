import { DeviceForm } from 'pages/equipment/Stations/components/DeviceForm';
import { Flex } from '@chakra-ui/react';
import { useCallback, useMemo, useState } from 'react';
import { useAvailableDevices } from 'pages/equipment/Stations/hooks/useAvailableDevices';
import { DevicesTable, DevicesTableProps } from 'pages/equipment/Stations/components/AddDevices/DevicesTable';
import { AddDeviceToStation } from 'pages/equipment/Stations/types';

type OnSelect = DevicesTableProps['onSelect'];

export interface AddDevicesProps {
  stationId: string;
  onSubmit: (item: any) => void;
  onCancel: () => void;
  isSubmitLoading: boolean;
}

export const AddDevices = ({ stationId, onCancel, onSubmit, isSubmitLoading }: AddDevicesProps) => {
  const { data, isLoading } = useAvailableDevices();
  const [ids, setIds] = useState<string[] | undefined>();
  const onSelect = useCallback<OnSelect>((ids) => {
    setIds(ids);
  }, []);
  const isDisabled = useMemo(() => !Array.isArray(ids) || ids.length === 0, [ids]);
  const onSubmitForm = useCallback((values: Partial<AddDeviceToStation>) => {
    onSubmit({
      ...values,
      ids,
    });
  }, [ids, onSubmit]);

  const selectedIndexes = useMemo<number[]>(() => {
    const arr: number[] = [];
    if (!data || !ids) return arr;
    const _ids = data?.map((item) => item.id);
    return _ids.map((e, i) => (ids.includes(e) ? i : null)).filter((i) => i !== null) as unknown as number[];
  }, [data, ids]);

  return (
    <div>
      <DevicesTable
        data={data}
        isLoading={isLoading}
        onSelect={onSelect}
        selectedIndexes={selectedIndexes}
      />
      <Flex marginTop={5}>
        <DeviceForm
          initialValues={{
            stationId,
            dateStart: null,
          }}
          onSubmit={onSubmitForm}
          onCancel={onCancel}
          isSubmitLoading={isSubmitLoading}
          submitIsDisabled={isDisabled}
        />
      </Flex>
    </div>
  );
};
