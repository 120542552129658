import { useCallback, useMemo, useState } from 'react';
import { FormikProvider, useFormik } from 'formik';

import { createTouchedErrors } from 'features/create-touched-errors';
import { useRequest } from 'hooks/useRequest';

import { useEnhancedNotification } from 'hooks';
import { ApiError } from 'models';
import { useObjectType } from 'hooks/useObjectType';
import { updateFiles } from 'api/services/media';
import { IUploadMedia } from 'components/common/FileUploadDragAndDrop/types';
import { formatErrorMessage } from 'lib/utils/formatErrorMessage';
import { useNotifications } from 'hooks/useNotifications';
import { ERROR_MESSAGES } from 'constants/messages';
import { LoaderItem } from 'components/common';
import { apiPathSurfaceWater } from 'pages/data/Water/SurfaceWater/apiPath';
import { initialEmptyValues } from './initialEmptyValues';
import { AnalyticsSurfaceWaterFormFields } from 'pages/analytics/forms/SurfaceWater/AnalyticsSurfaceWaterFormFields';
import { IAnalyticsLabSurfaceWater } from 'pages/analytics/forms/SurfaceWater/types';
import { AnalyticsSurfaceWaterSchema } from 'models/schemas/analytics/analyticsSurfaceWaterSchema';
import { useGoBackWithFallback } from 'lib/utils/goBackWithFallback';
import { useDictionary } from 'hooks/useDictionary';
import { getSelectOptionsFromDictionaryByName } from 'features/get-select-options-from-dictionary';

export const AnalyticsSurfaceWaterAdd = () => {
  const [media, setMedia] = useState<IUploadMedia[]>([]);
  const { backWithFallback } = useGoBackWithFallback();
  const { dictionaries, isDictionariesLoading: isLoading } = useDictionary({ url: apiPathSurfaceWater.dict });

  const laboratoryParameters = useMemo(
    () => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'laboratoryParameters'),
    [dictionaries?.data],
  );

  const { onSuccessCreate } = useEnhancedNotification();
  const { errorNotify } = useNotifications();

  const objectType = useObjectType(dictionaries);

  const onError = useCallback(
    (error: ApiError | string | null) => {
      const message = formatErrorMessage(error);
      errorNotify({
        key: message || 'file/add/error',
        message: message || ERROR_MESSAGES.CREATE,
      });
    },
    [errorNotify],
  );

  const onSuccess = (response: IAnalyticsLabSurfaceWater | null) => {
    if (response) {
      onSuccessCreate();
      updateFiles(response.id, objectType, media)
        .then(() => {
          backWithFallback('/analytics');
        })
        .catch((error: Error) => {
          onError(error.message);
        });
    }
  };

  const { post, isLoading: isPostLoading } = useRequest(apiPathSurfaceWater.root, onSuccess, onError);

  const useFormikProps = useMemo(
    () => ({
      enableReinitialize: true,
      validateOnChange: true,
      validateOnMount: true,
      validateOnBlur: true,
      validationSchema: AnalyticsSurfaceWaterSchema,
      initialValues: { ...initialEmptyValues },
      onSubmit: (values: any) => {
        const valuesToPost = { ...values };
        valuesToPost.values = valuesToPost.values.filter((val: any) => Boolean(val?.value));
        void post(valuesToPost);
      },
    }),
    [post],
  );

  const formik = useFormik(useFormikProps);

  if (isLoading || isPostLoading) {
    return <LoaderItem />;
  }

  return (
    <div>
      <FormikProvider
        value={{
          ...formik,
          errors: createTouchedErrors(formik.errors, formik.touched),
        }}
      >
        <AnalyticsSurfaceWaterFormFields
          parameters={laboratoryParameters}
          dictionaries={dictionaries}
          objectType={objectType}
          isLoading={isLoading}
          media={media}
          onMediaChange={setMedia}
        />
      </FormikProvider>
    </div>
  );
};
