import { useRequest } from 'hooks/useRequest';
import { TDictionariesArray } from 'models/dictionaries';
import { useEffect } from 'react';
import { getMASKSHDictionary } from 'pages/data/MASKSH/consts/menu';
import { useUtilDictionaries } from 'hooks/useUtilDictionaries';

type TInstructionsDictionariesNames = 'stations' | 'parameters' | 'units' | 'objects' | 'packageReceived';

export function useTechnicalDictionaries() {
  const path = getMASKSHDictionary('tech');
  const { get, isLoading, result, error } = useRequest<{
    data: TDictionariesArray<TInstructionsDictionariesNames>;
  }>(path);

  useEffect(() => {
    void get();
  }, [get]);

  const { stations, parameters, units, objects, packageReceived } = useUtilDictionaries(result?.data ?? []);

  return {
    units,
    stations,
    parameters,
    objects,
    isLoading,
    result,
    error,
    packageReceived
  };
}
