import { WithDidMountResetUploadFile } from 'features/UploadedFileProvider/hocs';
import { withCheckExistPreview } from 'features/UploadedFileProvider/withCheckExistPreview';
import { MeteoDataMenu } from 'pages/data/Meteo/MeteoDataMenu';
import { MeteoDataAddItem } from 'pages/data/Meteo/MeteoData/MeteoDataAddItem';
import { MeteoDataLoadPreview } from 'pages/data/Meteo/MeteoData/MeteoDataLoadPreview/MeteoDataLoadPreview';
import { Route } from 'react-router-dom';
import { MeteoMenu } from 'pages/data/Meteo/MeteoMenu';
import { MeteoData } from 'pages/data/Meteo/MeteoData';
import { MeteoProtocolMenu } from 'pages/data/Meteo/Protocol/MeteoProtocoMenu';
import { MeteoDataMonitoringDataPath, MeteoDataMonitoringRootPath, MeteoDataRootPath } from 'pages/data/Meteo/menu';
import { ProtocolMeteoData } from 'pages/data/Meteo/Protocol/ProtocolMeteoData/ProtocolMeteoData';
import { ProtocolAISWeather } from 'pages/data/Meteo/Protocol/ProtocolAISWeather/ProtocolAISWeather';
import { ProtocolActinometry } from 'pages/data/Meteo/Protocol/ProtocolActinometry/ProtocolActinometry';
import { ProtocolASKZA } from 'pages/data/Meteo/Protocol/ProtocolASKZA/ProtocolASKZA';
import { ProtocolMsz4 } from 'pages/data/Meteo/Protocol/ProtocolMsz4/ProtocolMsz4';

const MeteoRoutes = () => (
  <>
    {/* MeteoMenu Data routes */}
    <Route exact path={MeteoDataRootPath} render={() => <WithDidMountResetUploadFile Component={MeteoMenu} />} />
    <Route
      exact
      path={MeteoDataMonitoringRootPath}
      render={() => <WithDidMountResetUploadFile Component={MeteoDataMenu} />}
    />

    <Route
      exact
      path={`${MeteoDataMonitoringDataPath}`}
      render={() => <WithDidMountResetUploadFile Component={MeteoData} />}
    />
    <Route
      exact
      path={`${MeteoDataMonitoringDataPath}/load/preview/:typeId`}
      component={withCheckExistPreview(MeteoDataLoadPreview, MeteoDataMonitoringDataPath)}
    />

    <Route
      exact
      path={`${MeteoDataMonitoringDataPath}/add`}
      render={() => <WithDidMountResetUploadFile Component={MeteoDataAddItem} />}
    />

    {/* Protocols */}
    <Route exact path={`${MeteoDataRootPath}/protocol`} component={MeteoProtocolMenu} />
    <Route exact path={`${MeteoDataRootPath}/protocol/monitoring`} component={ProtocolMeteoData} />
    <Route exact path={`${MeteoDataRootPath}/protocol/ais-weather`} component={ProtocolAISWeather} />
    <Route exact path={`${MeteoDataRootPath}/protocol/actinometry`} component={ProtocolActinometry} />
    <Route exact path={`${MeteoDataRootPath}/protocol/askza`} component={ProtocolASKZA} />
    <Route exact path={`${MeteoDataRootPath}/protocol/msz4`} component={ProtocolMsz4} />
  </>
);

export { MeteoRoutes };
