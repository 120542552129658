import { SubsystemHeader } from 'components/common';
import appLocale from 'constants/appLocale';
import { useEnhancedNotification, useList, useNotifications, useRequest } from 'hooks';
import { IApiTokenList } from 'pages/admin/ApiToken/types';
import { MaterialTable } from 'features/material-table/MaterialTableMini';
import { useCallback, useMemo } from 'react';
import adminApiTokenColumns from 'pages/admin/ApiToken/adminApiTokenColumns';
import { useHistory } from 'react-router-dom';
import { ReloadIcon } from 'components/icons';
import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import { DEFAULT_PAGE_SIZE_NEW_TABLE } from '../../../constants/tables';
import { FullPageWrapper } from '../../../components/table/FullPageWrapper';

const ApiToken = () => {
  useUpdateBreadcrumbs([
    { breadcrumb: appLocale.admin.main, path: '/admin' },
    {
      breadcrumb: appLocale.admin.apiToken,
      path: '/admin/apiToken',
    },
  ]);

  const { successNotify } = useNotifications();
  const { onErrorEdit } = useEnhancedNotification();
  const { push } = useHistory();
  const {
    isLoading: isListLoading,
    response,
    toggleOrder,
    onPageChange,
    filterDTO,
    downloadAsFile,
    refetch,
  } = useList<IApiTokenList>('/admin/v10/admin/api-token/list', {
    initialOrderFieldKey: '-expires',
    defaultPageSize: DEFAULT_PAGE_SIZE_NEW_TABLE,
    resetDataOnNextRequest: true,
  });

  const onAddButtonClick = useCallback(() => {
    push('/admin/apiToken/add');
  }, [push]);

  const { post, isLoading: isUpdateLoading } = useRequest(
    '',
    () => {
      successNotify({
        key: 'token/update/success',
        message: 'Токен обновлен',
      });
      refetch();
    },
    onErrorEdit
  );

  const onTokenUpdate = useCallback(
    (id: string) => {
      void post({}, { url: `/admin/v10/admin/api-token/update-token/${id}` });
    },
    [post]
  );

  const isLoading = useMemo(() => isListLoading || isUpdateLoading, [isListLoading, isUpdateLoading]);
  return (
    <FullPageWrapper>
      <SubsystemHeader title={appLocale.admin.apiToken} addButtonProps={{ onClick: onAddButtonClick }} />
      <MaterialTable
        filterDTO={filterDTO}
        data={response?.data}
        meta={response?.meta}
        toggleOrder={toggleOrder}
        onPageChange={onPageChange}
        columns={adminApiTokenColumns}
        actions={[
          {
            type: 'edit',
            onClick: (_e, item) => {
              if (!Array.isArray(item)) {
                push(`/admin/apiToken/edit/${item.id}`);
              }
            },
          },
          {
            position: 'row',
            action: (row) => ({
              icon: () => <ReloadIcon />,
              onClick: () => onTokenUpdate(row.id),
              tooltip: 'Обновить токен',
            }),
          },
        ]}
        isLoading={isLoading}
        downloadAsFile={downloadAsFile}
        options={{
          selection: false,
        }}
        showTotalCount
      />
    </FullPageWrapper>
  );
};

export default ApiToken;
