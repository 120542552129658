import * as yup from 'yup';
import { ValidationError } from 'yup';
import { lessThanDate, maxSymbols, moreThanDate, numberError, requiredError } from '../constans';
import moment from 'moment';

export const AnalyticsSurfaceWaterSchema = yup
  .object()
  .shape({
    protocolNumber: yup.string().required(requiredError).nullable().max(15, maxSymbols(15)),
    protocolDate: yup.string().typeError(requiredError).required(requiredError),
    consumerId: yup.string().required(requiredError).nullable(),
    researchBasis: yup.string().required(requiredError).nullable().max(255, maxSymbols(255)),
    researchObject: yup.string().required(requiredError).nullable().max(255, maxSymbols(255)),
    waterObjectId: yup.string().required(requiredError).nullable(),
    sourceId: yup.string().required(requiredError).nullable(),
    samplePlace: yup.string().required(requiredError).nullable().max(255, maxSymbols(255)),
    organizationId: yup.string().required(requiredError).nullable(),
    document: yup.string().required(requiredError).nullable().max(511, maxSymbols(511)),
    actNumber: yup.string().required(requiredError).nullable().max(63, maxSymbols(63)),
    sampleRegistryNumber: yup.number().typeError(numberError).required(requiredError),
    samplePeriodStart: yup.string().typeError(requiredError).required(requiredError),
    samplePeriodEnd: yup.string().typeError(requiredError).required(requiredError),
    sampleDate: yup.string().typeError(requiredError).required(requiredError),
    researchDateStart: yup.string().typeError(requiredError).required(requiredError),
    researchDateEnd: yup.string().typeError(requiredError).required(requiredError),
    explanation: yup.string(),
    supportingDocuments: yup.string().nullable(),
    status: yup.string().required(requiredError).nullable(),
    sign: yup
      .object()
      .shape({
        signers: yup.array().of(
          yup.object().shape({
            action: yup.string().required(requiredError).nullable(),
            position: yup.string().required(requiredError).nullable().max(511, maxSymbols(511)),
            name: yup.string().required(requiredError).nullable().max(255, maxSymbols(255)),
          })
        ),
      })
      .nullable(),
  })
  .test((values) => {
    const dateErrors = [];
    if (values.sampleDate && values.protocolDate) {
      if (moment(values.sampleDate, 'YYYY.MM.DD').isAfter(moment(values.protocolDate, 'YYYY.MM.DD'))) {
        dateErrors.push(...[
          new ValidationError(lessThanDate('даты отбора'), '', 'protocolDate'),
          new ValidationError(moreThanDate('даты протокола'), '', 'sampleDate'),
        ]);
      }
    }
    if (values.samplePeriodStart && values.samplePeriodEnd) {
      if (moment(values.samplePeriodStart).isAfter(values.samplePeriodEnd)) {
        dateErrors.push(...[
          new ValidationError(lessThanDate('даты окончания'), '', 'samplePeriodStart'),
          new ValidationError(moreThanDate('даты начала'), '', 'samplePeriodEnd'),
        ]);
      }
    }
    return new ValidationError(dateErrors);
  });
