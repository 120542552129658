import { createTouchedErrors } from 'features/create-touched-errors';
import { FormikProvider, useFormik } from 'formik';
import { useRequest } from 'hooks/useRequest';
import { useParams } from 'react-router-dom';
import { useEnhancedNotification } from 'hooks';
import { useEffect } from 'react';
import { useDictionary } from 'hooks/useDictionary';
import { NSIReagentsDictionaryKeys, NSIReagentsListType } from 'pages/dictionaries/NSIReagents/types';
import { NSIReagentsApiPath, NSIReagentsTreesPaths } from 'pages/dictionaries/NSIReagents/paths';
import { NsiReagentsTreesSchema } from 'models/schemas/nsiReagents/nsiReagentsTreesSchema';
import initialNSIReagentsTreesEmptyValues
  from 'pages/dictionaries/NSIReagents/NSIReagentsTrees/NSIReagentsTreesForms/initialNSIReagentsTreesEmptyValues';
import NSIReagentsTreesFormFields
  from 'pages/dictionaries/NSIReagents/NSIReagentsTrees/NSIReagentsTreesForms/NSIReagentsTreesFormFields';
import { useGoBackWithFallback } from 'lib/utils/goBackWithFallback';

const NSIReagentsTreesEdit = () => {
  const { backWithFallback } = useGoBackWithFallback();
  const { id } = useParams<{ id: string }>();

  const { isDictionariesLoading, dictionaries } = useDictionary<NSIReagentsDictionaryKeys>({
    url: `${NSIReagentsApiPath}/dictionaries`,
  });

  const {
    isLoading: isItemLoading,
    result,
    get,
  } = useRequest(`${NSIReagentsApiPath}/${NSIReagentsListType.tree}/${id}`);

  useEffect(() => {
    void get();
  }, [get]);

  const { onSuccessEdit, onErrorEdit } = useEnhancedNotification();

  const onSuccess = () => {
    onSuccessEdit();
    backWithFallback(NSIReagentsTreesPaths.root);
  };

  const { put, isLoading } = useRequest(NSIReagentsApiPath, onSuccess, onErrorEdit);

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    initialValues: { ...initialNSIReagentsTreesEmptyValues, ...result },
    validationSchema: NsiReagentsTreesSchema,
    onSubmit: (values) => put({ ...values, type: NSIReagentsListType.tree }),
  });

  return (
    <FormikProvider
      value={{
        ...formik,
        errors: createTouchedErrors(formik.errors, formik.touched),
      }}
    >
      <NSIReagentsTreesFormFields
        dictionaries={dictionaries}
        onCancel={() => {
          backWithFallback(NSIReagentsTreesPaths.root);
        }}
        isLoading={isDictionariesLoading || isLoading || isItemLoading}
        isEditMode
        editedObject={result}
      />
    </FormikProvider>
  );
};

export default NSIReagentsTreesEdit;
