import { Dispatch, SetStateAction, useEffect, useMemo } from 'react';
import { Line } from 'react-chartjs-2';
import { Divider, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { useOptionsForChart } from '../hooks/useOptionsForChart';
import { useYScales } from '../hooks/useYScales';
import { AxisXManage } from './AxisXManage';
import { AxisYManage } from './AxisYManage';
import { DataDecimation } from './DataDecimation';
import { TimeBoundaries } from '../../components/TimeBoundaries';
import { ChartMetaButtons } from './ChartMetaButtons';
import { ViewLinesManage } from './ViewLinesManage';
import type { IChartData, IFilterValues } from '../../types';
import { registerPluginsChartJs } from '../helpers';
import { LineChartPlugins } from '../plugins';
import { useMinMaxDate } from '../../hooks/useMinMaxDate';

import moment from 'moment';
import 'chartjs-adapter-moment';
import 'moment/locale/ru';
import { useChartRef } from '../../hooks/useChartRef';

moment.locale('ru');

interface IChartProps {
  data: IChartData;
  filters: IFilterValues;
  setFilters: Dispatch<SetStateAction<IFilterValues>>;
  setStop: (value: string) => void;
  setStart: (value: string) => void;
}

registerPluginsChartJs();

export const Chart = ({ data, filters, setFilters, setStop, setStart }: IChartProps) => {
  const { controlChartRef, chart } = useChartRef();
  const { updateData, minDate, setMaxDate, setMinDate, maxDate } = useMinMaxDate();

  // effect for  clear data
  useEffect(
    () => () => {
      setMaxDate(null);
      setMinDate(null);
    },
    [setMaxDate, setMinDate]
  );

  const yScales = useYScales({ data, filters });
  const options = useOptionsForChart({ updateData, yScales });

  const LineComponent = useMemo(
    () => (
      <>
        {/* @ts-ignore */}
        <Line id="LineChart" plugins={LineChartPlugins} ref={controlChartRef} data={data} options={options} />
        <div id="legend-container" />
      </>
    ),
    [controlChartRef, data, options]
  );

  return (
    <>
      {LineComponent}
      <Divider />
      <TimeBoundaries minDate={minDate} maxDate={maxDate} />
      <ChartMetaButtons
        setStop={setStop}
        setStart={setStart}
        setFilters={setFilters}
        filters={filters}
        chart={chart}
      />
      <Tabs isFitted variant="enclosed" size="sm">
        <TabList>
          <Tab>Ось X</Tab>
          <Tab>Ось Y</Tab>
          <Tab>Количество точек</Tab>
          <Tab>Внешний вид</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <AxisXManage chart={chart} updateData={updateData} />
          </TabPanel>
          <TabPanel>
            <AxisYManage chart={chart} />
          </TabPanel>
          <TabPanel>
            <DataDecimation chart={chart} />
          </TabPanel>
          <TabPanel>
            <ViewLinesManage chart={chart} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </>
  );
};
