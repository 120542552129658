import { IFilterItem } from 'models';

export const initialValues: Record<string, IFilterItem> = {
    startDate: {
        key: 'eventDate',
        operation: 'GTE',
        value: null,
    },
    endDate: {
        key: 'eventDate',
        operation: 'LTE',
        value: null,
    },
    type: {
        key: 'type',
        operation: 'EQ',
        value: null,
    },
};
