import { Field, useFormikContext } from 'formik';
import { IReportProtocolForm } from '../types';
import { FormWrapper, Input, Textarea } from 'components/common';
import React from 'react';
import { Box, Grid, GridItem, Heading } from '@chakra-ui/react';

export const MainSources = () => {
  const { handleChange } = useFormikContext<IReportProtocolForm>();
  return (
    <Box>
      <FormWrapper>
        <Grid marginTop={5} templateColumns="repeat(1, 1fr)" gap={5}>
          <Heading size="md" marginBottom={5}>
            Основные источники и характер шума
          </Heading>
          <GridItem>
            <Field
              as={Textarea}
              name="noiseSourcesAndCharacterValue"
              label="Основные источники и характер шума"
              onChange={handleChange}
            />
          </GridItem>
          <GridItem>
            <Field as={Input} name="noiseCharacterValue" label="Характер шума" onChange={handleChange} />
          </GridItem>
        </Grid>
      </FormWrapper>
    </Box>
  );
};
