import { FormikProvider, useFormik } from 'formik';
import React from 'react';
import { initialEntryLogFilters } from '../consts/consts';
import { DataEntryLogFormFields } from './FormFields';
import { TDictionariesArray, TFiltersArray } from 'models';
import { FiltersDatepickersSchema } from 'models/schemas/filtersDatepickersSchema/FiltersDatepickersSchema';

interface IProps {
  setFilters: (filters: TFiltersArray<string>) => void;
  dictionaries: { data: TDictionariesArray<string, string, string, string> } | null;
}

export const DataEntryLogFilter = ({ setFilters, dictionaries }: IProps) => {
  const { resetForm, values, errors, handleReset, submitForm, setFieldValue, ...formik } = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    validationSchema: FiltersDatepickersSchema([
      { dateFromFieldName: 'dateTimeMin', dateToFieldName: 'dateTimeMax' },
    ]),
    initialValues: initialEntryLogFilters,
    onSubmit: (values) => setFilters(Object.values(values)),
  });
  return (
    <>
      <FormikProvider
        value={{
          resetForm,
          values,
          errors,
          handleReset,
          submitForm,
          setFieldValue,
          ...formik,
        }}
      >
        <DataEntryLogFormFields dictionaries={dictionaries} />
      </FormikProvider>
    </>
  );
};
