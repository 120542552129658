import { Field, FormikProvider, useFormik } from 'formik';
import { Flex, Grid } from '@chakra-ui/react';
import { Checkbox, DateInput, IFilterValues, Input, Select, useSetFiltersFromGraphic } from 'components/common';
import { createDateFromResponse, createDateToRequestForFilter, createDateToRequestWithTimezone } from 'lib/create-date';
import { COMMON_LOCALE } from 'constants/common';
import { tableLocale } from 'pages/data/MASKSH/consts';
import { IFilterItem, TDictionaryItemDictionary, TFiltersArray } from 'models';
import React, { useEffect, useMemo, useState } from 'react';
import { IPeriod } from 'features/PeriodsButtons/types';
import { PeriodsButtons } from 'features/PeriodsButtons';
import { usePeriods } from 'features/PeriodsButtons/usePeriods';
import combineFilters from 'lib/utils/combineFilters';
import { MomentInput } from 'moment';
import { FiltersDatepickersSchema } from 'models/schemas/filtersDatepickersSchema/FiltersDatepickersSchema';
import FilterFormControls from 'components/form/FilterFormControls';

interface FilterProps {
  isLoading?: boolean;
  isDisabled?: boolean;
  stations: TDictionaryItemDictionary<string, string>[] | undefined;
  objects: TDictionaryItemDictionary<string, string>[] | undefined;
  parameters: TDictionaryItemDictionary<string, string>[] | undefined;
  filtersFromGraphic?: Partial<IFilterValues> | null;
  setFiltersFromGraphic?: (value: Partial<IFilterValues> | null) => void;
  onSubmit: (values: any) => void;
  showParameterFilter: boolean;
  periods: IPeriod[] | null;
  filters: TFiltersArray;
}

const initialEmptyValues: Record<string, IFilterItem<string>> = {
  dateTimeMin: {
    key: 'packageDate',
    operation: 'GTE',
    value: null,
  },
  dateTimeMax: {
    key: 'packageDate',
    operation: 'LTE',
    value: null,
  },
  packageNumber: {
    key: 'packageNumber',
    operation: 'EQ',
    value: '',
  },
  stationId: {
    key: 'stationId',
    operation: 'EQ',
    value: '',
  },
  sourceId: {
    key: 'sourceId',
    operation: 'EQ',
    value: '',
  },
  parameterId: {
    key: 'parameterId',
    operation: 'EQ',
    value: '',
  },
  // showDisabled - value равно '' если чекбокс включен и нужно получить с Backend все значения (этот объект удалиться при отправке)
  // value равно false - если нужно показать только неудаленные записи
  showDisabled: {
    key: 'deleted',
    operation: 'EQ',
    value: false,
  },
  periodId: {
    key: 'periodId',
    operation: 'EQ',
    value: null,
  },
  initialData: {
    key: 'initialData',
    operation: 'EQ',
    value: false,
  },
};

export const Filters: React.FC<FilterProps> = ({
  isLoading,
  isDisabled,
  setFiltersFromGraphic,
  filtersFromGraphic,
  onSubmit,
  stations,
  objects,
  parameters,
  showParameterFilter,
  periods,
  filters,
  children,
  ...props
}) => {
  const savedPeriodId = useMemo(
    () => (filters.find((item) => item.key === 'periodId')?.value as string) || '',
    [filters],
  );

  const [periodId, setPeriodId] = useState<string>(savedPeriodId);

  const { getDatesFromPeriod, dates } = usePeriods(periodId, periods);

  const submitPeriod = (id: string) => {
    const dates = getDatesFromPeriod(id);
    setFieldValue('periodId.value', id);
    setFieldValue('dateTimeMin.value', createDateToRequestWithTimezone(dates[0]));
    setFieldValue('dateTimeMax.value', createDateToRequestWithTimezone(dates[1]));
    setFieldTouched('dateTimeMin.value');
    setPeriodId(id);
  };

  const { values, errors, setValues, handleReset, submitForm, setFieldValue, setFieldTouched, ...formik } = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    validationSchema: FiltersDatepickersSchema([{ dateFromFieldName: 'dateTimeMin', dateToFieldName: 'dateTimeMax' }]),
    initialValues: initialEmptyValues,
    onSubmit,
  });

  useEffect(() => {
    setValues(combineFilters(initialEmptyValues, filters));
  }, [filters, setValues]);

  useSetFiltersFromGraphic({
    dateKeyMin: 'dateTimeMin.value',
    dateKeyMax: 'dateTimeMax.value',
    setFiltersFromGraphic,
    filtersFromGraphic,
    setFieldValue,
  });

  return (
    <FormikProvider
      value={{
        values,
        errors,
        setValues,
        handleReset,
        submitForm,
        setFieldValue,
        setFieldTouched,
        ...formik,
      }}
    >
      <Flex flexDirection="row" justifyContent="space-between">
        <Grid gap="12px" rowGap="8px" gridTemplateColumns="repeat(2, 175px) 1fr 1fr 1fr" {...props}>
          <Field
            as={DateInput}
            name="dateTimeMin.value"
            label={COMMON_LOCALE.from}
            value={createDateFromResponse(values.dateTimeMin.value as MomentInput)}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFieldValue('dateTimeMin.value', createDateToRequestForFilter(e.target.value));
            }}
            showTimeInput={false}
            error={errors.dateTimeMin?.value}
            maxDate={dates?.[1]}
            minDate={dates?.[0]}
            isDisabled={!periodId}
          />
          <Field
            as={DateInput}
            name="dateTimeMax.value"
            label={COMMON_LOCALE.to}
            value={createDateFromResponse(values.dateTimeMax.value as MomentInput)}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFieldValue('dateTimeMax.value', createDateToRequestForFilter(e.target.value, 'end'));
            }}
            showTimeInput={false}
            error={errors.dateTimeMax?.value}
            maxDate={dates?.[1]}
            minDate={dates?.[0]}
            isDisabled={!periodId}
          />
          <Field as={Select} options={stations} name="stationId.value" label={tableLocale.columns.stationId} />
          <Field as={Select} options={objects} name="sourceId.value" label={tableLocale.columns.sourceId} />
          <Field as={Input} name="packageNumber.value" label={tableLocale.columns.packageNumber} />
          {showParameterFilter && (
            <Field as={Select} options={parameters} name="parameterId.value" label={tableLocale.columns.parameterId} />
          )}

          <Field
            as={Checkbox}
            name="initialData.value"
            label="Исходные данные"
            checked={values.initialData?.value}
            height={45}
            padding={0}
          />

          {children}
        </Grid>

        <FilterFormControls
          onReset={(e) => {
            handleReset(e);
            setPeriodId('');
            setTimeout(submitForm, 0);
          }}
          onSubmit={submitForm}
          isDisabled={isDisabled}
          isLoading={isLoading}
        />
      </Flex>
      <PeriodsButtons periodId={periodId} periods={periods} submitPeriod={submitPeriod} />
    </FormikProvider>
  );
};
