import React from 'react';
import { PageHeader, RoutesList } from 'components/common';
import { Flex, Heading } from '@chakra-ui/react';
import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import appLocale from 'constants/appLocale';
import { reportsAnalyticsMenu } from './reportsAnalyticsMenu';
import { useRoutesList } from 'hooks/useRoutesList';

export const AnalyticsReportsList: React.FC = () => {
  useUpdateBreadcrumbs([{ breadcrumb: appLocale.subsystems.analyticsReports, path: '/reports-analytics' }]);

  const routes = useRoutesList(reportsAnalyticsMenu);

  return (
    <Flex flexDirection="column">
      <PageHeader>
        <Heading>{appLocale.subsystems.analyticsReports}</Heading>
      </PageHeader>
      <RoutesList routes={routes} />
    </Flex>
  );
};
