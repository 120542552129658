import { useList } from 'hooks/useList';
import { IFloraAreaItem, TFloraAreaFilterType } from '../types';
import { ReagentsApiGreenplants, ReagentsRootPath } from 'pages/data/PGR/consts';
import { DeicingGreenPlantsRootPath } from 'pages/routes/PGRGreenPlants';
import { useAreaFloraListColumns } from '../FloraAreas/useAreaListColumns';
import { useLawnsDictionaries } from '../../Lawns/useLawnsDictionaries';
import { AreaHeader } from '../components/AreaHeader';
import { useAvailability, useHandleDeleteData } from 'hooks';
import subsystemIdsMap from 'constants/subsystemIdsMap';
import { PropActions } from 'features/material-table/components/Actions';
import React, { useMemo } from 'react';
import { useHistory } from 'react-router';
import { MaterialTable } from 'features/material-table/MaterialTableMini';
import { AreaLawnsFilter } from './LawnFilter';
import { usePeriodsDict } from 'features/PeriodsButtons/usePeriodsDict';
import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import appLocale from 'constants/appLocale';
import { DeleteIcon, EditIcon } from 'components/icons';
import { DEFAULT_PAGE_SIZE_NEW_TABLE } from '../../../../../../../constants/tables';
import { FullPageWrapper } from '../../../../../../../components/table/FullPageWrapper';

export const LawnAreas = () => {
  useUpdateBreadcrumbs([
    { breadcrumb: appLocale.common.data, path: '/data' },
    {
      breadcrumb: appLocale.subsystems.reagents,
      path: ReagentsRootPath,
    },
    {
      breadcrumb: 'Воздействие на зеленые насаждения',
      path: DeicingGreenPlantsRootPath,
    },
    {
      breadcrumb: 'Площадки',
      path: `${DeicingGreenPlantsRootPath}/areas`,
    },
    {
      path: `${DeicingGreenPlantsRootPath}/areas/lawns`,
      breadcrumb: 'Газоны',
    },
  ]);
  const history = useHistory();
  const { isWrite, isExecute, isNoRights, allAvailable, isDelete } = useAvailability(subsystemIdsMap.pgrLawn);
  const {
    refetch,
    response,
    isLoading: isListLoading,
    filterDTO,
    toggleOrder,
    setFilters,
    onPageChange,
    downloadAsFile,
    saveFilters,
    onSearchText,
    errorText,
    selectedIndexes,
    onSelectionChange,
    renderOnlySelectedCheckbox,
    materialTableProps,
  } = useList<IFloraAreaItem, TFloraAreaFilterType>(`${ReagentsApiGreenplants}/lawns/areas/list`, {
    initialOrderFieldKey: '-date',
    preventFirstFetch: true,
    exportSubsystemName: 'pgr_areas',
    saveFiltersState: true,
    selectionKey: 'registryId',
    defaultPageSize: DEFAULT_PAGE_SIZE_NEW_TABLE,
    resetDataOnNextRequest: true,
  });

  const { dictionaries } = useLawnsDictionaries();
  const columns = useAreaFloraListColumns(dictionaries.areaParameters);

  const { ModalComponent, handleOpenModal } = useHandleDeleteData({ refetch });

  const initialActions = useMemo<PropActions<IFloraAreaItem[]>>(
    () => [
      {
        position: 'row',
        action: (row: IFloraAreaItem) => ({
          icon: () => <EditIcon />,
          onClick: () => history.push(`${DeicingGreenPlantsRootPath}/areas/lawns/edit/${row.registryId}`, row),
          tooltip: 'Редактировать',
          hidden: !isWrite || !row.isEditable,
        }),
      },
      {
        position: 'row',
        action: (row: IFloraAreaItem) => ({
          icon: () => <DeleteIcon />,
          onClick: () => handleOpenModal({ url: `${ReagentsApiGreenplants}/lawns/${row.registryId}` }),
          tooltip: 'Удалить',
          hidden: !isDelete || !row.isEditable,
        }),
      },
    ],
    [isWrite, isDelete, history, handleOpenModal]
  );

  const { result: periods } = usePeriodsDict(`auth/v10/user/period/${subsystemIdsMap.pgrLawn}`);

  return (
    <FullPageWrapper>
      <AreaHeader
        allAvailable={allAvailable}
        isWrite={isWrite}
        isNoRights={isNoRights}
        handleAdd={() => {
          history.push(`${DeicingGreenPlantsRootPath}/areas/add`, { type: 'lawns' });
        }}
      >
        <AreaLawnsFilter
          periods={periods}
          dictionaries={dictionaries}
          isLoading={isListLoading}
          filters={filterDTO.filters}
          onSubmit={(values) => {
            setFilters(Object.values(values));
          }}
        >
          {renderOnlySelectedCheckbox}
        </AreaLawnsFilter>
      </AreaHeader>

      <MaterialTable
        actions={initialActions}
        isNotExecute={isExecute || isNoRights}
        data={response?.data}
        meta={response?.meta}
        columns={columns}
        filterDTO={filterDTO}
        isLoading={isListLoading}
        toggleOrder={toggleOrder}
        onPageChange={onPageChange}
        onSearch={onSearchText}
        downloadAsFile={downloadAsFile}
        selectedIndexes={selectedIndexes}
        onSelectionChange={onSelectionChange}
        saveFilters={saveFilters}
        setFilters={setFilters}
        errorText={errorText}
        {...materialTableProps}
        showTotalCount
      />

      {ModalComponent}
    </FullPageWrapper>
  );
};
