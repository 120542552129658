import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { IFilterValues } from 'components/common';
import { ConfirmModal, Modal } from 'components/common/Modals';
import { ERROR_MESSAGES, REMOVE_CONFIRMATION, SUCCESS_MESSAGES } from 'constants/messages';
import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import { getSelectOptionsFromDictionaryByName } from 'features/get-select-options-from-dictionary';
import { MaterialTable } from 'features/material-table/MaterialTableMini';
import { useAvailability, useDisclosure, useEnhancedNotification } from 'hooks';
import { useNotifications } from 'hooks/useNotifications';
import { useRequest } from 'hooks/useRequest';
import { createLocalDateDDMMYYYY } from 'lib/create-date';
import { filter, map } from 'lodash';
import { TFiltersArray } from 'models';
import { useHistory } from 'react-router';
import { useList } from 'hooks/useList';
import appLocale from 'constants/appLocale';
import { VegetationDeicingItem } from './useVegDicingList';

import { VegetativeFilter } from './VegetativeFilter';

import { ReagentsApiRootPath, ReagentsGreenPlantsRootPath } from '../..';
import { fieldsLocale } from './consts';
import { DeicingGreenPlantsRootPath } from 'pages/routes/PGRGreenPlants';
import FilePreviewModalWrapper from 'components/common/FilePreviewModal/FilePreviewModalWrapper';
import { DeleteIcon, EditIcon, EyeViewIcon, ImageIcon } from 'components/icons';
import useFilePreviewModalWrapperData from 'components/common/FilePreviewModal/hooks/useFilePreviewModalWrapperData';
import { UploadFileModalContent } from 'features/UploadFileDragAndDrop/UploadFileModalContent';
import { addFiltersToColumns, ColumnWithFilter } from 'lib/utils/tableFilter';
import { Column } from '@material-table/core';
import { TableFilterTypeEnum } from 'components/table/filters/TableFilter';
import subsystemIdsMap from 'constants/subsystemIdsMap';
import { PropActions } from 'features/material-table/components/Actions';
import { usePeriodsDict } from 'features/PeriodsButtons/usePeriodsDict';
import { downloadFileFromResponse } from 'lib/utils/files/downloadFile';
import { getSubsystemHumanReadableName, SubsystemName } from 'pages/analytics/consts/AnalyticsTableRoutes';
import { IAnalyticsItem } from 'pages/analytics/consts/types';
import { ExtensionToMimeTypeEnum } from 'lib/utils/files/getFileExtensionByMimeType';
import { ReagentsRootPath, ReagentsVegsRootPath } from 'pages/data/PGR';
import { HeaderWithAnalytics } from 'pages/data/components/HeaderWithAnalytics';
import { CellWithType } from 'components/table/CellWithType';
import { useDictionary } from 'hooks/useDictionary';
import { DEFAULT_PAGE_SIZE_NEW_TABLE } from '../../../../../constants/tables';
import { FullPageWrapper } from '../../../../../components/table/FullPageWrapper';
import { DefaultTableCellSize } from 'components/table/DefaultTableCellSize';

// eslint-disable-next-line react/display-name
const VegetativeState = memo(() => {
  const { isReadOnly, isWrite, isExecute, isNoRights, allAvailable } = useAvailability(subsystemIdsMap.pgrVegetative);

  const history = useHistory();
  const { open, ...filePreviewData } = useFilePreviewModalWrapperData();
  const [downloadReportItem, setDownloadReportItem] = useState<IAnalyticsItem>();
  const { onDownloadError } = useEnhancedNotification();
  const onFileResponseSuccess = useCallback(
    (response) => {
      if (response instanceof Blob && downloadReportItem) {
        downloadFileFromResponse({
          response,
          name: `Шаблон протокола_${getSubsystemHumanReadableName(SubsystemName.reagentsVegetation)}`,
        });
        setDownloadReportItem(undefined);
      }
    },
    [downloadReportItem],
  );

  const _onDownloadError = useCallback(() => {
    onDownloadError();
    setDownloadReportItem(undefined);
  }, [onDownloadError]);

  const { get: downloadReport } = useRequest<Blob>('', onFileResponseSuccess, _onDownloadError);

  useEffect(() => {
    if (downloadReportItem) {
      void downloadReport({
        url: `/api/reporting/v10/templates/fill/vegetative/${downloadReportItem.registryId}`,
        headers: { ACCEPT: ExtensionToMimeTypeEnum.docx },
        responseType: 'blob',
      });
    }
  }, [downloadReport, downloadReportItem]);

  useUpdateBreadcrumbs([
    { breadcrumb: appLocale.common.data, path: '/data' },
    {
      breadcrumb: appLocale.subsystems.reagents,
      path: ReagentsRootPath,
    },
    {
      breadcrumb: appLocale.pgr.effects_on_greenplants,
      path: DeicingGreenPlantsRootPath,
    },
    {
      breadcrumb: 'Вегетативные части зеленых насаждений',
      path: `${ReagentsVegsRootPath}/main-data`,
    },
  ]);

  const { dictionaries } = useDictionary({ url: '/deicing/v10/deicing/vegetative/dictionaries' });
  const [removedItems, setRemovedItems] = useState('');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { successNotify, errorNotify } = useNotifications();

  const { isOpen: isOpenLoad, onClose: onCloseLoad, onOpen: onOpenLoad } = useDisclosure();

  const {
    refetch,
    isLoading: isVegsListLoading,
    response,
    setFilters,
    toggleOrder,
    onPageChange,
    filterDTO,
    downloadAsFile,
    saveFilters,
    onSearchText,
    errorText,
    selectedIndexes,
    renderOnlySelectedCheckbox,
    onSubmitFilters,
    onSelectionChange,
    materialTableProps,
  } = useList<VegetationDeicingItem, keyof VegetationDeicingItem>('/deicing/v10/deicing/vegetative/list', {
    initialOrderFieldKey: '-selectionDate',
    preventFirstFetch: true,
    exportSubsystemName: 'vegs',
    saveFiltersState: true,
    selectionKey: 'registryId',
    defaultPageSize: DEFAULT_PAGE_SIZE_NEW_TABLE,
    resetDataOnNextRequest: true,
  });

  const onSuccess = () => {
    onClose();
    refetch();
    successNotify({
      key: 'file/delete/success',
      message: SUCCESS_MESSAGES.DELETE,
    });
  };
  const onError = () =>
    errorNotify({
      key: 'file/delete/error',
      message: ERROR_MESSAGES.DELETE,
    });
  const { remove } = useRequest('/atmosphere/v10/atmosphere', onSuccess, onError);

  const dictData = dictionaries?.data;
  const parameters = useMemo(() => getSelectOptionsFromDictionaryByName(dictData, 'parameters'), [dictData]);

  const [filtersFromGraphic, setFiltersFromGraphic] = useState<Partial<IFilterValues> | null>(null);

  const columns = useMemo<ColumnWithFilter<VegetationDeicingItem>[]>(() => [
    {
      title: fieldsLocale.selectionDate,
      field: 'selectionDate',
      render: (row) => createLocalDateDDMMYYYY(row.selectionDate),
    },
    {
      title: fieldsLocale.protocol,
      field: 'protocol',
      render: (row) => row.protocol,
      filterType: TableFilterTypeEnum.string,
    },
    {
      title: fieldsLocale.protocolDate,
      field: 'protocolDate',
      render: (row) => createLocalDateDDMMYYYY(row.protocolDate) || '',
    },
    {
      title: fieldsLocale.territoryType,
      field: 'territoryType',
      filterType: TableFilterTypeEnum.string,
    },
    {
      title: fieldsLocale.sourceName,
      field: 'sourceName',
    },
    {
      title: fieldsLocale.okrug,
      field: 'okrug',
      filterType: TableFilterTypeEnum.string,
    },
    {
      title: fieldsLocale.rayon,
      field: 'rayon',
      filterType: TableFilterTypeEnum.string,
    },
    {
      title: fieldsLocale.address,
      field: 'address',
      filterType: TableFilterTypeEnum.string,
      render: (row) => <DefaultTableCellSize>{row.address}</DefaultTableCellSize>,
    },
    {
      title: 'Расстояние от дороги, м.',
      field: 'distance2Road',
      filterType: TableFilterTypeEnum.string,
    },
    ...map(parameters, ({ title, parameterId }) => ({
      title,
      field: parameterId,
      render: (row: VegetationDeicingItem) => {
        const item = filter(row.values, (el) => el.parameterId === parameterId)[0];
        if (!item) {
          return null;
        }
        return <CellWithType type={item.type} value={item.value} />;
      },
      filterType: TableFilterTypeEnum.number,
    })),
  ], [parameters]);

  const columnsWithFilters = useMemo<Array<Column<VegetationDeicingItem>>>(
    () => addFiltersToColumns({ filters: filterDTO.filters, setFilters, columns }),
    [columns, filterDTO.filters, setFilters],
  );

  const onReportDownload = useCallback(async (_e, item) => {
    if (!Array.isArray(item)) {
      setDownloadReportItem(item);
    }
  }, []);

  const initialActions = useMemo<PropActions<VegetationDeicingItem[]>>(
    () => [
      {
        position: 'row',
        action: (row) => ({
          icon: () => <EditIcon />,
          onClick: () => {
            history.push(`${ReagentsGreenPlantsRootPath}/vegs/edit/row/${row.registryId}`);
          },
          hidden: row.laboratory === true || !row.isEditable,
        }),
      },
      {
        position: 'row',
        action: (row) => ({
          icon: () => <DeleteIcon />,
          onClick: () => {
              onOpen();
              setRemovedItems(row.registryId);
          },
          hidden: row.laboratory === true || !row.isEditable,
        }),
      },
      {
        position: 'row',
        action: (row: any) => ({
          icon: () => <ImageIcon />,
          onClick: () => open(row.registryId, row.objectType),
          tooltip: 'Показать изображения',
          hidden: !row.objectType || row.laboratory === true,
        }),
      },
      {
        position: 'row',
        action: (row) => ({
          icon: () => <EyeViewIcon />,
          onClick: onReportDownload,
          tooltip: 'Скачать',
          hidden: row.laboratory === null || row.laboratory === false,
        }),
      },
    ],
    [history, open, setRemovedItems, onOpen, onReportDownload],
  );

  // @ts-ignore
  const actions = useMemo<PropActions<any[]>>(() => {
    if (allAvailable || isNoRights || isWrite) {
      return initialActions;
    }
    if (!(allAvailable || isNoRights || isWrite)) {
      return initialActions.filter((action: any) => action.type !== 'delete');
    }
  }, [isWrite, initialActions, isNoRights, allAvailable]);

  const { result: periods } = usePeriodsDict(`auth/v10/user/period/${subsystemIdsMap.pgrVegetative}`);

  return (
    <FullPageWrapper>
      <HeaderWithAnalytics
        allAvailable={allAvailable}
        isWrite={isWrite}
        isReadonly={isReadOnly}
        isExecute={isExecute}
        isNoRights={isNoRights}
        active="main-data"
        title="Мониторинг воздействия ПГР на вегетативные части зеленых насаждений"
        rootPath={ReagentsVegsRootPath}
        mainDataTitle="Данные"
        analyticsDataTitle="Протоколы аналитической лаборатории"
        addButtonProps={{
          onClick: () => history.push(`${ReagentsVegsRootPath}/add`),
          isDisabled: !(allAvailable || isNoRights || isWrite),
        }}
        onOpenLoad={onOpenLoad}
      >
        <VegetativeFilter
          periods={periods}
          filtersFromGraphic={filtersFromGraphic}
          setFiltersFromGraphic={setFiltersFromGraphic}
          isLoading={isVegsListLoading}
          isDisabled={isVegsListLoading}
          dictionaries={dictionaries}
          onSubmit={(values) => {
            const filters: TFiltersArray<keyof VegetationDeicingItem> = Object.values(values);
            onSubmitFilters(filters);
          }}
          filters={filterDTO.filters}
        >
          {renderOnlySelectedCheckbox}
        </VegetativeFilter>
      </HeaderWithAnalytics>

      <MaterialTable
        isNotExecute={isExecute || isNoRights}
        data={response?.data}
        meta={response?.meta}
        filterDTO={filterDTO}
        toggleOrder={toggleOrder}
        onPageChange={onPageChange}
        columns={columnsWithFilters}
        actions={actions}
        isLoading={isVegsListLoading}
        options={{
          search: true,
          showTitle: true,
          toolbar: true,
        }}
        onSearch={onSearchText}
        downloadAsFile={downloadAsFile}
        saveFilters={saveFilters}
        setFilters={setFilters}
        errorText={errorText}
        selectedIndexes={selectedIndexes}
        onSelectionChange={onSelectionChange}
        {...materialTableProps}
        showTotalCount
      />

      <ConfirmModal
        isOpen={isOpen}
        onClose={onClose}
        onClick={() =>
          remove({
            url: `/deicing/v10/deicing/vegetative/${removedItems}`,
          })
        }
        header={REMOVE_CONFIRMATION}
      />

      <Modal isOpen={isOpenLoad} onClose={onCloseLoad} header="Загрузка данных из файла">
        <UploadFileModalContent
          createTypesPath={() => `${ReagentsApiRootPath}/vegetative/load/types`}
          createUploadPath={() => `${ReagentsApiRootPath}/vegetative/load`}
          types={[
            {
              name: 'Данные ПГР (вегетатив)',
              value: 'vegetative',
            },
          ]}
          onClose={onCloseLoad}
          refetch={refetch}
        />
      </Modal>

      <FilePreviewModalWrapper {...filePreviewData} />
    </FullPageWrapper>
  );
});

export { VegetativeState };
