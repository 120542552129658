import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { TFilterDTO, TFiltersArray } from 'models';
import { IUseListMaterialTableProps, IWithFileDownload, useHandleDeleteData } from 'hooks';

import { createLocalDateDDMMYYYY } from 'lib/create-date';
import { MaterialTable } from 'features/material-table/MaterialTableMini';
import { Column } from '@material-table/core';
import FilePreviewModalWrapper from 'components/common/FilePreviewModal/FilePreviewModalWrapper';
import { DeleteIcon, EditIcon, ImageIcon } from 'components/icons';
import useFilePreviewModalWrapperData from 'components/common/FilePreviewModal/hooks/useFilePreviewModalWrapperData';
import {
  GeologicalGroundwaterMeasuringObservationPointData,
  MonitoringItem,
  MonitoringItems,
} from 'pages/data/GeoecologicalProcesses/GeologicalProcessesGroundwater/Monitoring/types';
import { addFiltersToColumns, ColumnWithFilter } from 'lib/utils/tableFilter';
import { TableFilterTypeEnum } from 'components/table/filters/TableFilter';
import { CellObservationPoint } from 'pages/data/GeoecologicalProcesses/GeologicalProcessesGroundwater/components/CellObservationPoint';
import { templatesObject } from 'constants/templates';
import { PropActions } from 'features/material-table/components/Actions';

interface MonitoringTableProps {
  monitoringList: MonitoringItems | null;
  refetch: () => void;
  toggleOrder: (fieldKey: string) => void;
  onPageChange: (page: number, pageSize: number) => void;
  downloadAsFile: IWithFileDownload;
  filters: TFilterDTO<keyof MonitoringItem>['filters'];
  setFilters: (filters: TFiltersArray<keyof MonitoringItem>) => void;
  filterDTO: TFilterDTO;
  onSelectionChange: (checkedRows: MonitoringItem[]) => void;
  selectedIndexes: number[];
  isReadonly?: boolean;
  isExecute?: boolean;
  isWrite?: boolean;
  isNoAction?: boolean;
  allAvailable?: boolean;
  saveFilters: () => void;
  isLoading: boolean;
  errorText?: string;
  materialTableProps: IUseListMaterialTableProps;
}

export const MonitoringTable: React.FC<MonitoringTableProps> = ({
  monitoringList,
  refetch,
  toggleOrder,
  onPageChange,
  downloadAsFile,
  filters,
  setFilters,
  filterDTO,
  onSelectionChange,
  selectedIndexes,
  isExecute,
  isWrite,
  isNoAction,
  allAvailable,
  saveFilters,
  isLoading,
  errorText,
  materialTableProps,
}) => {
  const history = useHistory();
  const { open, ...filePreviewData } = useFilePreviewModalWrapperData();

  const { ModalComponent, handleOpenModal } = useHandleDeleteData({ refetch });
  const initialActions: PropActions<MonitoringItem[]> = useMemo(
    () => [
      {
        position: 'row',
        action: (row: any) => ({
          icon: () => <EditIcon />,
          onClick: () =>
            history.push(`/data/geoecological-processes/monitoring/water/examination/edit/row/${row.id}`, row),
          tooltip: 'Редактировать',
        }),
      },
      {
        position: 'row',
        action: (row: any) => ({
          icon: () => <DeleteIcon />,
          onClick: () =>
            handleOpenModal({
              url: `/geoecology/v10/geoecology/watersource/monitoring/${row.id}`,
            }),
          tooltip: 'Удалить',
        }),
      },
      {
        position: 'row',
        action: (row: any) => ({
          icon: () => <ImageIcon />,
          onClick: () => open(row.id, row.objectType),
          tooltip: 'Показать изображения',
          hidden: !row.objectType,
        }),
      },
    ],
    [handleOpenModal, history, open],
  );

  // @ts-ignore
  const actions = useMemo<PropActions<any[]>>(() => {
    if (allAvailable || isNoAction || isWrite) {
      return initialActions;
    }
    if (!(allAvailable || isNoAction || isWrite)) {
      return initialActions.filter((action: any) => action.type !== 'delete');
    }
  }, [isWrite, initialActions, isNoAction, allAvailable]);

  const columns = useMemo<Array<ColumnWithFilter<MonitoringItem>>>(
    () => [
      {
        title: 'Дата',
        field: 'measuringDate',
        render: (row) => createLocalDateDDMMYYYY(row.measuringDate),
      },
      {
        title: 'Тип пункта наблюдения',
        field: 'waterSourceType', // sorting only works for waterSourceType
        render: (row) => row.waterSourceTypeName,
      },
      {
        title: 'Пункт наблюдения',
        field: 'sourceCode',
        render: (row) =>
          CellObservationPoint<GeologicalGroundwaterMeasuringObservationPointData>({
            label: row.sourceCode,
            id: row.sourceId,
            url: '/geoecology/v10/geoecology/watersource/monitoring/watersourcedata',
          }),
        filterType: TableFilterTypeEnum.number,
      },
      {
        title: 'Санитарное состояние',
        field: 'ecologicalState',
        render: (row) => row.ecologicalState?.value,
        filterType: TableFilterTypeEnum.string,
        cellStyle: {
          width: '210px',
        },
      },
      {
        title: 'Состояние каптажа',
        field: 'capturingState',
        render: (row) => row.capturingState?.value || '-',
        filterType: TableFilterTypeEnum.string,
        cellStyle: {
          width: '210px',
        },
      },
    ],
    [],
  );

  const columnsWithFilters = useMemo<Array<Column<MonitoringItem>>>(
    () => addFiltersToColumns({ filters, setFilters, columns }),
    [columns, filters, setFilters],
  );

  return (
    <>
      <MaterialTable
        saveFilters={saveFilters}
        isNotExecute={isExecute || isNoAction}
        onSelectionChange={onSelectionChange}
        selectedIndexes={selectedIndexes}
        data={monitoringList?.data}
        meta={monitoringList?.meta}
        toggleOrder={toggleOrder}
        filterDTO={filterDTO}
        onPageChange={onPageChange}
        columns={columnsWithFilters}
        actions={actions}
        downloadAsFile={downloadAsFile}
        downloadTemplate={[...templatesObject.inspectionAndMeasurements]}
        isLoading={isLoading}
        errorText={errorText}
        {...materialTableProps}
        showTotalCount
      />

      {ModalComponent}

      <FilePreviewModalWrapper {...filePreviewData} />
    </>
  );
};
