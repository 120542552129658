import { Button, SubsystemHeader } from 'components/common';
import React, { memo } from 'react';
import { useHistory } from 'react-router-dom';
import { HStack } from '@chakra-ui/react';
import { GeoecologicalRootPath } from 'pages/data/GeoecologicalProcesses/consts';

interface GeologicalProcessesGroundwaterHeaderProps {
  active: string;
  onOpenLoad?: () => void;
  baseUrl?: string;
  showAdd?: boolean;
  title?: string;
  children?: React.ReactNode;
  isWrite?: boolean;
  isNoRights?: boolean;
  allAvailable?: boolean;
}

export const geologicalProcessesGroundwaterTabs = [
  {
    label: 'Обследование',
    value: 'examination',
  },
  {
    label: 'Замеры',
    value: 'measuring',
  },
  {
    label: 'Качество',
    value: 'quality',
  },
];

export const GeologicalProcessesGroundwaterHeader = memo(
  ({
    onOpenLoad,
    active,
    baseUrl = `${GeoecologicalRootPath}/monitoring/water`,
    showAdd = true,
    title = 'Мониторинг подземных вод',
    children,
    isWrite,
    isNoRights,
    allAvailable
  }: GeologicalProcessesGroundwaterHeaderProps) => {
    const history = useHistory();
    const isAvailable = allAvailable || isNoRights || isWrite;

    return (
      <SubsystemHeader
        title={title}
        onUploadClick={isAvailable ? onOpenLoad : undefined}
        addButtonProps={
          showAdd
            ? {
              onClick: () => history.push(`${history.location.pathname}/add`),
              isDisabled: !isAvailable
            }
            : undefined
        }
        filtersComponent={children}
        navigationTabs={(
          <HStack spacing="0px">
            {geologicalProcessesGroundwaterTabs.map(({ label, value }) => (
              <Button
                key={value}
                colorScheme="blue"
                backgroundColor={value === active ? 'PrimaryButton.500' : 'white'}
                color={value === active ? 'white' : 'PrimaryButton.500'}
                variant={value === active ? 'solid' : 'outline'}
                onClick={() => history.push(`${baseUrl}/${value}`)}
                height="30px"
              >
                {label}
              </Button>
            ))}
          </HStack>
        )}
      />
    );
  }
);
