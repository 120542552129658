import { ERROR_MESSAGES, SUCCESS_MESSAGES } from 'constants/messages';
import { createTouchedErrors } from 'features/create-touched-errors';
import { FormikProvider, useFormik } from 'formik';
import { useNotifications } from 'hooks/useNotifications';
import { useRequest } from 'hooks/useRequest';
import { SubstanceFields } from './SubstanceFields';
import { SubstanceSchema } from 'models/schemas';
import { ISubstanceValues, substanceApiPath, substancesPath } from '../../consts';
import { LoaderItem } from 'components/common';
import { useGoBackWithFallback } from 'lib/utils/goBackWithFallback';
import { useDictionary } from 'hooks/useDictionary';

export const initialEmptyValues = {
  id: '',
  chemName: '',
  chemFormula: '',
  chemShortName: '',
  dangerClass: '',
  notChemical: false,
  paramId: [''],
  paramValue: [''],
};

export const AddSubstance = () => {
  const { backWithFallback } = useGoBackWithFallback();

  const { dictionaries, isDictionariesLoading } = useDictionary({ url: '/nsi/v10/substance/dictionaries' });

  const { successNotify, errorNotify } = useNotifications();
  const onSuccess = () => {
    successNotify({
      key: 'file/add/success',
      message: SUCCESS_MESSAGES.CREATE,
    });
    backWithFallback(substancesPath);
  };

  const onError = () => {
    errorNotify({
      key: 'file/add/error',
      message: ERROR_MESSAGES.CREATE,
    });
  };
  const { post } = useRequest<ISubstanceValues, any>(substanceApiPath, onSuccess, onError);

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    validationSchema: SubstanceSchema,
    initialValues: initialEmptyValues,
    onSubmit: (values) => {
      post(values);
    },
  });

  if (isDictionariesLoading) {
    return (
      <LoaderItem />
    );
  }

  return (
    <FormikProvider
      value={{
        ...formik,
        errors: createTouchedErrors(formik.errors, formik.touched),
      }}
    >
      <SubstanceFields dictionaries={dictionaries?.data} />
    </FormikProvider>
  );
};
