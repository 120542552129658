import { Box, Flex, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { AttachmentsTabContent, Button, DefaultText, FormHeader, FormWrapper } from 'components/common';
import React, { useMemo } from 'react';
import { CommonTab } from './CommonTab';
import { ParametersTab } from './ParametersTab';
import { useFormikContext } from 'formik';
import { QualityItem } from 'hooks/geo/Quality/useQualityList';
import { useObjectType } from 'hooks/useObjectType';
import appLocale from 'constants/appLocale';
import { useQuery } from 'hooks/useQuery';
import { FieldFormProps } from 'types/forms';
import subsystemIdsMap from 'constants/subsystemIdsMap';
import { useAvailability } from 'hooks';

const FieldsForm = ({ onCancel, dictionaries, isEditMode, media, onMediaChange, uploadItemId }: FieldFormProps) => {
  const { isAvailable } = useAvailability(subsystemIdsMap.geoecologyRoot);

  const { handleSubmit, values } = useFormikContext<QualityItem>();
  const objectType = useObjectType(dictionaries);
  const { openAttachmentsTab } = useQuery();

  const submitIsDisabled = useMemo(
    () => [...values.measures].filter((val) => !!val.code && !!val.name && !!val.value).length === 0,
    [values.measures],
  );

  const renderControls = useMemo(
    () => (
      <Flex>
        <Button onClick={onCancel} marginRight={20}>
          Отмена
        </Button>
        <Button onClick={() => handleSubmit()} variant="solid" isDisabled={submitIsDisabled || !isAvailable}>
          Сохранить
        </Button>
      </Flex>
    ),
    [onCancel, submitIsDisabled, isAvailable, handleSubmit],
  );

  const title = isEditMode ? 'Редактирование результатов обследования' : 'Добавление результатов обследования';

  return (
    <Box flexDirection="column">
      <Tabs defaultIndex={openAttachmentsTab ? 2 : 0}>
        <FormHeader onBackClick={onCancel} header={title}>
          <TabList>
            {[appLocale.common.generalInformation, appLocale.common.indicators, appLocale.common.attachments].map(
              (tab, i) => (
                <Tab key={i}>
                  <DefaultText cursor="pointer">{tab}</DefaultText>
                </Tab>
              ),
            )}
          </TabList>
        </FormHeader>
        <TabPanels>
          <TabPanel>
            <CommonTab dictionaries={dictionaries} />
          </TabPanel>
          <TabPanel>
            <ParametersTab isWrite={isAvailable} dictionaries={dictionaries}>
              {renderControls}
            </ParametersTab>
          </TabPanel>
          {isAvailable && (
            <TabPanel>
              <FormWrapper>
                <AttachmentsTabContent
                  objectType={objectType}
                  media={media}
                  onMediaChange={onMediaChange}
                  uploadItemId={uploadItemId}
                >
                  {renderControls}
                </AttachmentsTabContent>
              </FormWrapper>
            </TabPanel>
          )}
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default React.memo(FieldsForm);
