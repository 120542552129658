import * as yup from 'yup';
import { ValidationError } from 'yup';
import { atLeastOneError, requiredError } from '../constans';
import { BottomSoilParametersKeys } from 'pages/data/Water/BottomSoil/consts/types';
import { numberWithTextSign } from 'models/schemas/numbers';

const parametersFields = BottomSoilParametersKeys.reduce(
  (object, key) => ({ ...object, [key]: yup.object().shape({ strValue: numberWithTextSign }) }),
  {}
);

export const BottomSoilSchema = yup.object()
  .shape({
    protocolNumber: yup.string().required(requiredError).nullable(),
    sampleDate: yup.string().typeError(requiredError).required(requiredError).nullable(),
    waterObject: yup.string().required(requiredError).nullable(),
    ...parametersFields
  }).test((values) => {
    if (
      !BottomSoilParametersKeys.map((key) => values[key])
        .some(
          (value) => !!value
        )
    ) {
      return new ValidationError(BottomSoilParametersKeys.map((key) => new ValidationError(atLeastOneError, '', key)));
    }

    return true;
  });
