import { FormikProvider, useFormik } from 'formik';
import { createTouchedErrors } from 'features/create-touched-errors';
import FieldsForm from './FieldsForm';
import { groundCoastInitialEmptyValues } from './initialEmptyValues';
import { useEnhancedNotification, useNotifications, useRequest } from 'hooks';
import { GroundCoastApiPath } from 'pages/data/Water/WPZRoot/GroundCoast/consts/apiPaths';
import React, { useCallback, useState } from 'react';
import { ApiError } from 'models';
import { formatErrorMessage } from 'lib/utils/formatErrorMessage';
import { ERROR_MESSAGES } from 'constants/messages';
import { updateFiles } from 'api/services/media';
import { WaterRoutesGroundCoast } from 'pages/data/Water/routes';
import { useHistory } from 'react-router-dom';
import { IUploadMedia } from 'components/common/FileUploadDragAndDrop/types';
import { useObjectType } from 'hooks/useObjectType';
import { GroundCoastListDTO, IGroundCoastFormDto } from 'pages/data/Water/WPZRoot/GroundCoast/consts/types';
import { GroundCoastSchema } from 'models/schemas/water/groundcoast';
import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import { formBreadcrumbs } from 'pages/data/Water/WPZRoot/GroundCoast/consts/breadcrumbs';
import { useGoBackWithFallback } from 'lib/utils/goBackWithFallback';
import { useDictionary } from 'hooks/useDictionary';
import { LoaderItem } from '../../../../../../components/common';

const GroundCoastAdd = () => {
  useUpdateBreadcrumbs(formBreadcrumbs(WaterRoutesGroundCoast.add));
  const { backWithFallback } = useGoBackWithFallback();
  const history = useHistory();
  const { errorNotify } = useNotifications();
  const [media, setMedia] = useState<IUploadMedia[]>([]);
  const { onSuccessCreate } = useEnhancedNotification();

  const { dictionaries, isDictionariesLoading } = useDictionary({ url: GroundCoastApiPath.dictionaries });
  const objectType = useObjectType(dictionaries);

  const onError = useCallback(
    (message?: ApiError | string | null) => {
      const msg = formatErrorMessage(message);
      errorNotify({
        key: msg || 'file/add/error',
        message: msg || ERROR_MESSAGES.CREATE,
      });
    },
    [errorNotify],
  );

  const onSuccess = useCallback(
    (response: GroundCoastListDTO | null) => {
      if (response) {
        onSuccessCreate();

        updateFiles(response.packageId, objectType, media)
          .then(() => {
            backWithFallback(WaterRoutesGroundCoast.root);
          })
          .catch((error: Error) => {
            onError(error.message);

            history.push(WaterRoutesGroundCoast.edit(response.packageId));
          });
      } else {
        onError();
      }
    },
    [backWithFallback, history, media, objectType, onError, onSuccessCreate],
  );

  const { post, isLoading: isPostLoading } = useRequest<GroundCoastListDTO>(
    GroundCoastApiPath.root,
    onSuccess,
    onError,
  );

  const formik = useFormik<IGroundCoastFormDto>({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    initialValues: groundCoastInitialEmptyValues,
    onSubmit: (values) => {
      void post(values);
    },
    validationSchema: GroundCoastSchema,
  });

  if (isPostLoading || isDictionariesLoading) {
    return <LoaderItem />;
  }

  return (
    <FormikProvider
      value={{
        ...formik,
        errors: createTouchedErrors(formik.errors, formik.touched),
      }}
    >
      <FieldsForm
        dictionaries={dictionaries}
        onCancel={() => {
          backWithFallback(WaterRoutesGroundCoast.root);
        }}
        onMediaChange={setMedia}
        media={media}
      />
    </FormikProvider>
  );
};

export default GroundCoastAdd;
