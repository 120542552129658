import { useRequest } from 'hooks/useRequest';
import { useEffect } from 'react';
import { TDictionariesArray } from 'models/dictionaries';
import { PDV_ApiPath } from './consts';

export function usePDVDictionaries() {
  const { get, isLoading, result, error } = useRequest<{
    data: TDictionariesArray;
  }>(`${PDV_ApiPath}/dictionaries`);

  useEffect(() => {
    void get();
  }, [get]);
  return {
    isLoading,
    result,
    error,
    get,
  };
}
