import { ApiError } from 'models';

export const formatErrorMessage = (message?: ApiError | string | null): string | null => {
  if (message === null || message === undefined || typeof message === 'string') {
    return message || null;
  }

  return (
    message.error ||
    message.descriptions
      ?.map((msg) => msg.message)
      .filter((msg) => !!msg)
      .join(', ')
  );
};
