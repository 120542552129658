import React, { useCallback, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { FormikProvider, useFormik } from 'formik';

import { createTouchedErrors } from 'features/create-touched-errors';
import { useRequest } from 'hooks/useRequest';

import { ASKZVFormFields } from './Fields';
import { initialEmptyValues, validationSchema } from './configs';
import { FormHeader, LoaderItem } from 'components/common';
import { apiPathAskzv } from '../apiPath';
import { DictionariesItem } from 'models';
import { useEnhancedNotification } from 'hooks';
import { useGoBackWithFallback } from 'lib/utils/goBackWithFallback';
import { useDictionary } from 'hooks/useDictionary';
import { getSelectOptionsFromDictionaryByName } from 'features/get-select-options-from-dictionary';
import { getFilteredOptionsByPrivileges } from 'features/getFilteredOptionsByPrivileges';

interface IProps {
  listPath: string;
  previewPath: string;
}

export const AddASKZVPackage = ({ listPath, previewPath }: IProps) => {
  const { backWithFallback } = useGoBackWithFallback();

  // чтобы добавить запись из файла
  const { state } = useLocation();
  const _initialValues = state?.initialValues;
  const initialValues = useMemo(() => _initialValues ?? {}, [_initialValues]);
  const metaData = state?.meta;

  const { dictionaries, isDictionariesLoading: isLoading } = useDictionary({ url: apiPathAskzv.dict });
  const sources = useMemo(() => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'sources'), [dictionaries]);
  const filteredSources = useMemo(() => getFilteredOptionsByPrivileges(sources, 'isEditable'), [sources]);
  const fire = useMemo(() => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'fire', false), [dictionaries]);
  const door = useMemo(() => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'door', false), [dictionaries]);
  const event = useMemo(() => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'event', false), [dictionaries]);

  const { onSuccessCreate, onErrorCreate } = useEnhancedNotification();

  const onSuccess = useCallback(() => {
    onSuccessCreate();
    backWithFallback(listPath);
  }, [onSuccessCreate, backWithFallback, listPath]);

  const { post, isLoading: isPostLoading } = useRequest(apiPathAskzv.root, onSuccess, onErrorCreate);

  const useFormikProps = useMemo(
    () => ({
      enableReinitialize: true,
      validateOnChange: true,
      validateOnMount: true,
      validateOnBlur: true,
      validationSchema,
      initialValues: { ...initialEmptyValues, ...initialValues },
      onSubmit: (values: any) => {
        void post(values);
      },
    }),
    [initialValues, post],
  );

  const formik = useFormik(useFormikProps);
  const header = 'Добавление пакета АСКЗВ';

  if (isPostLoading || isLoading) {
    return <LoaderItem />;
  }

  return (
    <div>
      <FormikProvider
        value={{
          ...formik,
          errors: createTouchedErrors(formik.errors, formik.touched),
        }}
      >
        <FormHeader onBackClick={() => backWithFallback(listPath)} header={header} />
        <ASKZVFormFields
          isAddFromFile={metaData?.isAddFromFile}
          isLoading={isLoading}
          sources={filteredSources as unknown as DictionariesItem}
          fire={fire as unknown as DictionariesItem}
          door={door as unknown as DictionariesItem}
          event={event as unknown as DictionariesItem}
          previewPath={previewPath}
        />
      </FormikProvider>
    </div>
  );
};
