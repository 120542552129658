import React, { useState } from 'react';
import { createTouchedErrors } from 'features/create-touched-errors';
import { FormikProvider, useFormik } from 'formik';
import { useRequest } from 'hooks/useRequest';
import { useLocation } from 'react-router-dom';
import { QualitySchema } from 'models/schemas/groundwaters/quality';
import { GeoecologicalRootPath } from 'pages/data/GeoecologicalProcesses/consts';
import { initialEmptyValues } from './AddQualityResults';
import FieldsForm from './FieldsForm';
import { IUploadMedia } from 'components/common/FileUploadDragAndDrop/types';
import { QualityItem } from 'hooks/geo/Quality/useQualityList';
import { useObjectType } from 'hooks/useObjectType';
import { useUpdateFiles } from 'api/services/media';
import { useGoBackWithFallback } from 'lib/utils/goBackWithFallback';
import { useDictionary } from 'hooks/useDictionary';
import { useEnhancedNotification } from 'hooks';
import { LoaderItem } from '../../../../../../components/common';

export const EditQualityResult = () => {
  const { backWithFallback } = useGoBackWithFallback();
  const location = useLocation();
  const { isDictionariesLoading, dictionaries } = useDictionary({
    url: '/geoecology/v10/geoecology/watersource/quality/dictionaries',
  });
  const [media, setMedia] = useState<IUploadMedia[]>([]);
  const objectType = useObjectType(dictionaries);
  const { updateFiles, isLoading } = useUpdateFiles();

  // TODO: it would be better to use getById from backend (not exists)
  const editedObject = location.state;

  const initialValues = {
    ...initialEmptyValues,
    ...editedObject,
    measures: editedObject.measures.filter((measure: any) => !!measure.id), // empty measures is passed here with null as id
  };

  const { onSuccessEdit, onErrorEdit } = useEnhancedNotification();

  const onSuccess = () => {
    onSuccessEdit();

    updateFiles(editedObject.id, objectType, media)
      .then(() => {
        backWithFallback(`${GeoecologicalRootPath}/monitoring/water/quality`);
      })
      .catch((error: Error) => {
        onErrorEdit(error.message);
      });
  };

  const { put, isLoading: isPutLoading } = useRequest<QualityItem>(
    '/geoecology/v10/geoecology/watersource/quality',
    onSuccess,
    onErrorEdit,
  );

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    initialValues,
    validationSchema: QualitySchema,
    onSubmit: (values) => put(values),
  });

  if (isPutLoading || isDictionariesLoading || isLoading) {
    return <LoaderItem />;
  }

  return (
    <FormikProvider
      value={{
        ...formik,
        errors: createTouchedErrors(formik.errors, formik.touched),
      }}
    >
      <FieldsForm
        dictionaries={dictionaries}
        onCancel={() => backWithFallback(`${GeoecologicalRootPath}/monitoring/water/quality`)}
        isEditMode
        media={media}
        onMediaChange={setMedia}
        uploadItemId={editedObject.id}
      />
    </FormikProvider>
  );
};
