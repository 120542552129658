import React from 'react';
import { PageHeader, RoutesList } from 'components/common';
import { Flex, Heading } from '@chakra-ui/react';
import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import appLocale from 'constants/appLocale';
import { useRoutesList } from 'hooks/useRoutesList';
import { waterObjectsMenu } from '../../reportsAnalyticsMenu';

export const WaterReports = () => {
  useUpdateBreadcrumbs([
    { breadcrumb: appLocale.subsystems.analyticsReports, path: '/reports-analytics' },
    { breadcrumb: appLocale.subsystems.water, path: '/reports-analytics/water' },
  ]);

  const routes = useRoutesList(waterObjectsMenu);

  return (
    <Flex flexDirection="column">
      <PageHeader>
        <Heading>Отчеты Водные объекты</Heading>
      </PageHeader>
      <RoutesList routes={routes} />
    </Flex>
  );
};
