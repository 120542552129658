import { Button, FormWrapper, Input, Modal } from 'components/common';
import React, { PropsWithChildren, useMemo } from 'react';
import { Field, useFormikContext } from 'formik';
import appLocale from 'constants/appLocale';
import {
  BottomSoilParametersKeys,
  IBottomSoilDTO,
  parameterIdToKeyMap,
} from 'pages/data/Water/BottomSoil/consts/types';
import { useDisclosure } from 'hooks';
import { UploadFileDragAndDrop } from 'features/UploadFileDragAndDrop';
import { useInitializerUploadSettings } from 'features/UploadedFileProvider/UploadFileSettings';
import { WaterRoutesBottomSoil } from 'pages/data/Water/routes';
import { Box, Grid } from '@chakra-ui/react';
import { MoreLessNumberInput } from '../../../../../../components/common/Input/more-less-number-input';

interface IProps {
  isEditMode: boolean;
  isAnalytics?: boolean;
}

export const ParametersTab = ({
  children,
  isEditMode,
  isAnalytics = false,
}: PropsWithChildren<IProps>) => {
  const { errors, setFieldValue, values } = useFormikContext<IBottomSoilDTO>();
  const { isOpen, onOpen, onClose } = useDisclosure();

  useInitializerUploadSettings(
    useMemo(
      () => ({
        typesPath: '/water/v10/water/bottomsoil/types',
        previewPath: '/water/v10/water/bottomsoil/preview',
        validatePath: '',
        applyPath: '',
        redirectPath: WaterRoutesBottomSoil.root,
      }),
      []
    )
  );

  return (
    <Box flexDirection="column">
      <FormWrapper>
        <Grid marginTop="30px" gap="15px" marginBottom={20}>
          {!isAnalytics && !isEditMode && (
            <Button marginRight="auto" marginLeft={2} variant="link" onClick={onOpen}>
              Загрузить из файла
            </Button>
          )}

          {BottomSoilParametersKeys.map((parameterName) => (
            <Field
              key={`${parameterName}`}
              as={isAnalytics ? MoreLessNumberInput : Input}
              label={appLocale.bottomSoil[parameterName]}
              name={`${parameterName}`}
              value={isAnalytics ? {
                ...values[parameterName],
                value: values[parameterName]?.strValue,
                valueAsString: values[parameterName]?.strValue
              } : values[parameterName]?.strValue}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setFieldValue(`${parameterName}.strValue`, e.target.value);
              }}
              error={errors[parameterName]?.strValue}
            />
          ))}

          {children}
        </Grid>
      </FormWrapper>

      <Modal isOpen={isOpen} onClose={onClose} header="Загрузка данных из файла">
        <UploadFileDragAndDrop
          onClose={onClose}
          parametersSave={(preview) => {
            preview?.result.values.forEach((item: { parameterId: string, value: any }) => {
              const key = parameterIdToKeyMap[item.parameterId];
              if (key) {
                setFieldValue(`${key}.strValue`, item.value);
              }
            });
            onClose();
          }}
        />
      </Modal>
    </Box>
  );
};
