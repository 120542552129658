import { useCallback, useMemo, useRef } from 'react';
import { useForceUpdate } from 'hooks';
import { IChart } from '../types';

export const useChartRef = () => {
  const chartRef = useRef<IChart>();
  const chart = chartRef.current;

  const forceUpdate = useForceUpdate();

  const controlChartRef = useCallback(
    (Chart) => {
      if (Chart !== null && !chartRef.current) {
        chartRef.current = Chart;

        forceUpdate();
      }
    },
    [chartRef, forceUpdate]
  );

  return useMemo(() => ({ controlChartRef, chartRef, chart }), [chart, chartRef, controlChartRef]);
};
