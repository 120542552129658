import { MaterialTable } from 'features/material-table/MaterialTableMini';
import { TableTooltipFileName } from 'features/TableTooltip';
import { useUploadFileProvider } from 'features/UploadedFileProvider';
import { Column } from '@material-table/core';
import { FilePreview } from 'models';
import React, { useMemo } from 'react';

type TMeteoDataPackagesPreviewMeasure = {
  date: string;
  hasErrors: boolean;
  id: string | null;
  file: {
    error: string | null;
    key: string | null;
    value: string | null;
  };
  template: {
    error: string | null;
    key: string | null;
    value: string | null;
  };
};

type TMeteoDataPackagesPreview = FilePreview<TMeteoDataPackagesPreviewMeasure>;

const MeteoDataPackagesPreview = () => {
  const { preview } = useUploadFileProvider<TMeteoDataPackagesPreviewMeasure>();

  const columns = useMemo<Column<TMeteoDataPackagesPreviewMeasure>[]>(
    () => [
      {
        title: 'Дата и время',
        field: 'date',
        render: (row) => row.date,
      },
      {
        title: 'Файл',
        field: 'file',
        render: (row) => <TableTooltipFileName tooltip={String(row.file.value)} />,
      },
      {
        title: 'Шаблон',
        field: 'template',
        render: (row) => row.template.value,
      },
      {
        title: 'Статус',
        render: (row) => row.file.error || row.file.error || 'Разархивирован',
      },
    ],
    [],
  );

  return <MaterialTable data={preview?.result?.measures} columns={columns} />;
};

export type { TMeteoDataPackagesPreview };

export { MeteoDataPackagesPreview };
