import { createDateToRequestWithTimezone } from 'lib/create-date';
import { TFloraPlantsParametersDictionariesItemNames } from 'pages/data/GreenPlants/Flora/useFloraDictionaries';

const GreenPlantsFormTypes = {
  area: 'area',
  flora: 'flora',
  lawns: 'lawns',
};

type PartialRecord<K extends keyof any, T> = {
  [P in K]?: T;
};

type Parameter<TParametersDictionariesItemNames extends string> = Partial<{
  code: string | null;
  id: string | null;
  name: TParametersDictionariesItemNames | null;
  parameterId: string | null;
  value: string | string[] | number | number[] | null;
  dictionaryItemId: string[];
  ids: string[];
}>;

interface TBaseGreenPlantsFormProperties<TParametersDictionariesItemNames extends string> {
  areaId: string | null;
  areaNumber: number | null;
  date: string | null;
  deleted: boolean;
  registryId: string | null;
  parameters: PartialRecord<TParametersDictionariesItemNames, Parameter<TParametersDictionariesItemNames>>;
  plantForm?: string | null;
  plantId?: string | null;
  plantNumber?: number | null;
  plantType?: string | null;
  lawnId?: string | null;
  lawnName?: number | null;
  address?: string | null;
  landmark?: string | null;
  square?: number | null;
  organization?: string | null;
}

interface IFloraFormDto extends TBaseGreenPlantsFormProperties<TFloraPlantsParametersDictionariesItemNames> {
  plantForm: string | null;
  plantId: string | null;
  plantNumber: number | null;
  plantType: string | null;
}

interface ILawnFormDto extends TBaseGreenPlantsFormProperties<string> {
  lawnId: string | null;
  lawnName: number | null;
  lawnAddress: string | null;
}

type TGreenPlantsForm = {
  lawns: ILawnFormDto;
  lawnSiteDto: TBaseGreenPlantsFormProperties<string>;
  flora: IFloraFormDto;
  area: TBaseGreenPlantsFormProperties<string>;
};

export type TParamsToPost = {
  id?: string;
  parameterId?: string | null;
  value?: string | number;
  dictionaryItemId?: string;
};

const greenPlantsFormInitialValues: TGreenPlantsForm = {
  lawns: {
    areaId: null,
    areaNumber: null,
    plantForm: null,
    plantType: null,
    date: createDateToRequestWithTimezone(new Date(Date.now())),
    deleted: false,
    lawnId: null,
    lawnName: null,
    parameters: {},
    registryId: null,
    lawnAddress: null,
  },
  flora: {
    areaId: null,
    areaNumber: null,
    date: createDateToRequestWithTimezone(new Date(Date.now())),
    deleted: false,
    parameters: {},
    plantForm: null,
    plantId: null,
    plantNumber: null,
    plantType: null,
    registryId: null,
  },
  lawnSiteDto: {
    areaId: null,
    areaNumber: null,
    plantForm: null,
    plantType: null,
    date: createDateToRequestWithTimezone(new Date(Date.now())),
    deleted: false,
    parameters: {},
    registryId: null,
  },
  area: {
    areaId: null,
    areaNumber: null,
    plantForm: null,
    plantType: null,
    date: createDateToRequestWithTimezone(new Date(Date.now())),
    deleted: false,
    parameters: {},
    registryId: null,
  }
};

export type { TBaseGreenPlantsFormProperties };

export { GreenPlantsFormTypes, greenPlantsFormInitialValues };
