import { ReportSmall } from 'features/WithSideBar/icons';
import appLocale from 'constants/appLocale';
import { subsystemCodesMap } from 'constants/subsystemIdsMap';
import { RenderTree } from '../types';

export const waterMenu: RenderTree = {
  id: '/protocols/water',
  url: '/protocols/water',
  name: appLocale.subsystems.water,
  otherCode: subsystemCodesMap.dataProcessingProtocol.ASKZV,
  children: [
    {
      id: '/protocols/water/askzv',
      url: '/protocols/water/askzv',
      name: appLocale.protocols.askzvShort,
      otherCode: subsystemCodesMap.dataProcessingProtocol.ASKZV,
    },
  ],
};

export const protocolsMenu: RenderTree = {
  id: '/protocols',
  name: appLocale.protocols.titleShort,
  url: '/protocols',
  icon: <ReportSmall />, // TODO: change icon
  children: [
    {
      id: '/protocols/atmosphere',
      url: '/protocols/atmosphere',
      name: appLocale.subsystems.askza,
      otherCode: subsystemCodesMap.dataProcessingProtocol.ASKZA,
    },
    waterMenu,
  ],
};
