export const userStatuses = [
  {
    value: 'ACTIVE',
    name: 'Активен',
  },
  {
    value: 'LOCKED',
    name: 'Заблокирован',
  },
];
