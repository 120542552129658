import { createTouchedErrors } from 'features/create-touched-errors';
import { FormikProvider, useFormik } from 'formik';
import { useRequest } from 'hooks/useRequest';
import FieldsForm from 'pages/dictionaries/NSIEmissions/NSIEmissionsIndustries/NSIEmissionsIndustriesForms/NSIEmissionsIndustriesFormFields';
import { NSIEmissionsApiPath, NSIEmissionsIndustriesRootPath } from 'pages/dictionaries/NSIEmissions/consts/paths';
import { useEnhancedNotification } from 'hooks';
import { NsiEmissionsIndustriesSchema } from 'models/schemas/nsiEmissions/NsiEmissionsIndustriesSchema';
import { useGoBackWithFallback } from 'lib/utils/goBackWithFallback';
import { useDictionary } from 'hooks/useDictionary';

export const initialEmptyValues = {
  name: '',
  shortName: '',
  number: '',
  industryId: null,
  status: null,
  okrugId: null,
  rayonId: null,
  address: '',
  latitude: '',
  longitude: '',
};

const NSIEmissionsIndustriesAdd = () => {
  const { dictionaries, isDictionariesLoading } = useDictionary({ url: `${NSIEmissionsApiPath}/dictionaries` });
  const { backWithFallback } = useGoBackWithFallback();
  const { onSuccessCreate, onErrorCreate } = useEnhancedNotification();

  const onSuccess = () => {
    onSuccessCreate();
    backWithFallback(NSIEmissionsIndustriesRootPath);
  };

  const { post, isLoading } = useRequest(`${NSIEmissionsApiPath}/create`, onSuccess, onErrorCreate);

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    initialValues: initialEmptyValues,
    validationSchema: NsiEmissionsIndustriesSchema,
    onSubmit: (values) => post({ ...values, type: 'industry' }),
  });

  return (
    <FormikProvider
      value={{
        ...formik,
        errors: createTouchedErrors(formik.errors, formik.touched),
      }}
    >
      <FieldsForm
        dictionaries={dictionaries}
        onCancel={() => {
          backWithFallback(NSIEmissionsIndustriesRootPath);
        }}
        isLoading={isDictionariesLoading || isLoading}
      />
    </FormikProvider>
  );
};

export default NSIEmissionsIndustriesAdd;
