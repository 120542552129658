import React from 'react';
import { tableIcons } from '../components/CustomTable';
import { PasswordRequestItem } from '../models/PasswordRequestItem';
import { baseAdminPasswordRequestUrl } from './const';
import moment from 'moment';
import { useHandleDeleteData, useList } from '../../../hooks';
import { DEFAULT_PAGE_SIZE_NEW_TABLE } from '../../../constants/tables';
import { Column } from '@material-table/core';
import { MaterialTable } from '../../../features/material-table/MaterialTableMini';

moment.locale('ru');
const timeFormat = 'DD.MM.YYYY hh:mm:ss Z';

export const PasswordRequestList: React.FC = () => {
  const {
    isLoading,
    response,
    toggleOrder,
    setFilters,
    onPageChange,
    filterDTO,
    onSearchText,
    selectedIndexes,
    onSelectionChange,
    refetch,
  } = useList<PasswordRequestItem, keyof PasswordRequestItem>(baseAdminPasswordRequestUrl, {
    initialOrderFieldKey: '-created',
    defaultPageSize: DEFAULT_PAGE_SIZE_NEW_TABLE,
    resetDataOnNextRequest: true,
    fetchMethod: 'GET',
    initialDeleted: null,
  });

  const columns: Column<PasswordRequestItem>[] = [
    {
      title: 'Время запроса',
      field: 'created',
      render: (rowdata) =>
        (
          <div>{moment(rowdata.created, 'X').format(timeFormat)}</div>
        )
    },
    {
      title: 'Время обработки',
      field: 'applied',
      render: (rowdata) =>
        (
          <div>{rowdata.applied ? moment(rowdata.applied, 'X').format(timeFormat) : ''}</div>
        )
    },
    {
      title: 'Время устаревания',
      field: 'expires',
      render: (rowdata) =>
        (
          <div>{rowdata.expires ? moment(rowdata.expires, 'X').format(timeFormat) : ''}</div>
        )
    },
    {
      title: 'Имя пользователя',
      field: 'userName',
    },
    {
      title: 'Ссылка',
      field: 'link',
    }
  ];

  const { ModalComponent: ConfirmDeleteModal, handleOpenModal } = useHandleDeleteData({ refetch });

  return (
    <>
      <MaterialTable
        data={response?.data}
        meta={response?.meta}
        toggleOrder={toggleOrder}
        filterDTO={filterDTO}
        onPageChange={onPageChange}
        columns={columns}
        actions={[
          {
            position: 'row',
            action: (item) => ({
              icon: tableIcons.Delete,
              tooltip: 'Удалить',
              onClick: () => {
                handleOpenModal({
                  url: `${baseAdminPasswordRequestUrl}/${item.id}`,
                });
              },
              hidden: item.deleted,
            })
          },
        ]}
        isLoading={isLoading}
        setFilters={setFilters}
        options={{ selection: false, search: true }}
        onSearch={onSearchText}
        onSelectionChange={onSelectionChange}
        selectedIndexes={selectedIndexes}
      />

      {ConfirmDeleteModal}
    </>
  );
};
