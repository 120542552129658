import React, { memo, useCallback, useEffect } from 'react';
import { Flex, Stack } from '@chakra-ui/react';
import { Field, useFormikContext } from 'formik';
import { concat, get, isNull } from 'lodash';
import { Checkbox, Input, Select } from 'components/common';
import { dictParametersToMap } from 'features/parametersMap';
import type { TFLoraDictionaries } from 'pages/data/GreenPlants/Flora/useFloraDictionaries';
import { TFloraPlantsParametersDictionariesItemNames } from 'pages/data/GreenPlants/Flora/useFloraDictionaries';

import type { IFloraTabPanelProps } from './types';
import { TGreenPlantsForm } from 'pages/data/GreenPlants/Form/types';
import { getDictionaryId } from 'pages/data/GreenPlants/utils';
import isRequiredInSchema from 'lib/utils/forms/isReqiuredInSchema';
import { useGreenPlantsSchema } from 'models/schemas/pgr';
import { formatNumberInput } from 'lib/utils/forms/formatNumberInput';
import {
  getSelectOptionsFromDictionaryByName
} from '../../../../../../../../features/get-select-options-from-dictionary';

interface TFloraMeasurementsFormProps {
  entity: IFloraTabPanelProps['entity'];
  isLoading: IFloraTabPanelProps['isLoading'];
  areaParameters: TFLoraDictionaries['areaParameters'];
  plantParameters: TFLoraDictionaries['plantParameters'];
}

const FloraMeasurementsForm: React.FC<TFloraMeasurementsFormProps> = memo(
  ({ entity, isLoading, areaParameters = [], plantParameters = [] }) => {
    const { values, setFieldValue, errors, touched } = useFormikContext<TGreenPlantsForm>();
    const { GreenPlantsSchema } = useGreenPlantsSchema(entity);

    const parameters = dictParametersToMap(concat(areaParameters, plantParameters));
    const pId = parameters?.isFirstPlanted?.parameterId;
    const valuesValue = values?.flora.parameters?.isFirstPlanted?.value;
    useEffect(() => {
      /**
       * отдельно ставим значения для чекбоксов, потому что бэкенд их не обрабатывает корректно
       */
        // если нет значения
      const shouldSetDefaultValueInCheckbox = valuesValue == null && pId;
      if (shouldSetDefaultValueInCheckbox) {
        setFieldValue(`${entity}.parameters.isFirstPlanted.value`, false);
        setFieldValue(`${entity}.parameters.isFirstPlanted.parameterId`, pId);
      }
      // если есть значение
      const stringValue = String(valuesValue);
      if (stringValue === 'Да') {
        setFieldValue(`${entity}.parameters.isFirstPlanted.value`, true);
      }
      if (stringValue === 'Нет') {
        setFieldValue(`${entity}.parameters.isFirstPlanted.value`, false);
      }
    }, [entity, pId, setFieldValue, valuesValue]);

    const inputOnChange = useCallback(
      (value: string | number | null, key: TFloraPlantsParametersDictionariesItemNames) => {
        setFieldValue(`${entity}.parameters.${key}`, {
          ...values.flora.parameters[key],
          parameterId: parameters[key]?.parameterId,
          value: isNull(value) ? null : [value],
        });
      },
      [entity, parameters, setFieldValue, values]
    );

    const selectOnChange = useCallback(
      (value: string, key: TFloraPlantsParametersDictionariesItemNames) => {
        setFieldValue(`${entity}.parameters.${key}`, {
          ...values.flora.parameters[key],
          parameterId: parameters[key]?.parameterId,
          value: value ? [value] : null,
          dictionaryItemId: [getDictionaryId(value, parameters[key].values)],
        });
      },
      [entity, parameters, setFieldValue, values]
    );

    const getOptionsByName = useCallback((name: string) => getSelectOptionsFromDictionaryByName(
      [...areaParameters, ...plantParameters],
      name,
      false,
      get(values, `${entity}.parameters.${name}.ids`)
    ), [areaParameters, plantParameters, values, entity]);

    return (
      <Stack>
        <Field
          isDisabled={isLoading}
          as={Select}
          options={getOptionsByName('plantGroupType')}
          name={`${entity}.parameters.plantGroupType.dictionaryItemId[0]`}
          label={parameters.plantGroupType?.title}
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
            selectOnChange(e.target.value, 'plantGroupType');
          }}
        />
        <Field
          isDisabled={isLoading}
          as={Select}
          options={getOptionsByName('plantGroupLocation')}
          name={`${entity}.parameters.plantGroupLocation.dictionaryItemId[0]`}
          label={parameters.plantGroupLocation?.title}
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
            selectOnChange(e.target.value, 'plantGroupLocation');
          }}
        />
        <Field
          isDisabled={isLoading}
          as={Input}
          name={`${entity}.parameters.plantGroupCount.value[0]`}
          label={parameters.plantGroupCount?.title}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            inputOnChange(formatNumberInput(e.target.value), 'plantGroupCount');
          }}
        />
        <Field
          isDisabled={isLoading}
          as={Select}
          options={getOptionsByName('plantAge')}
          name={`${entity}.parameters.plantAge.dictionaryItemId[0]`}
          label={parameters.plantAge?.title}
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
            selectOnChange(e.target.value, 'plantAge');
          }}
          isRequired={isRequiredInSchema(GreenPlantsSchema, `${entity}.fields.parameters.fields.plantAge`)}
          error={touched.flora?.parameters?.plantAge && get(errors.flora?.parameters?.plantAge, 'value')}
        />
        <Field
          isDisabled={isLoading}
          as={Input}
          name={`${entity}.parameters.plantHeight.value[0]`}
          label={parameters.plantHeight?.title}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            inputOnChange(formatNumberInput(e.target.value), 'plantHeight');
          }}
          isRequired={isRequiredInSchema(GreenPlantsSchema, `${entity}.fields.parameters.fields.plantHeight`)}
          error={touched.flora?.parameters?.plantHeight && get(errors.flora?.parameters?.plantHeight, 'value')}
        />

        <Field
          isDisabled={isLoading}
          as={Input}
          name={`${entity}.parameters.stemHeight.value[0]`}
          label={parameters.stemHeight?.title}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            inputOnChange(formatNumberInput(e.target.value), 'stemHeight');
          }}
          isRequired={isRequiredInSchema(GreenPlantsSchema, `${entity}.fields.parameters.fields.stemHeight`)}
          error={touched.flora?.parameters?.stemHeight && get(errors.flora?.parameters?.stemHeight, 'value')}
        />
        <Field
          isDisabled={isLoading}
          as={Input}
          name={`${entity}.parameters.diameter.value[0]`}
          label={parameters.diameter?.title}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            inputOnChange(formatNumberInput(e.target.value), 'diameter');
          }}
          isRequired={isRequiredInSchema(GreenPlantsSchema, `${entity}.fields.parameters.fields.diameter`)}
          error={touched.flora?.parameters?.diameter && get(errors.flora?.parameters?.diameter, 'value')}
        />
        <Field
          isDisabled={isLoading}
          as={Input}
          name={`${entity}.parameters.averageDiameter.value[0]`}
          label={parameters.averageDiameter?.title}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            inputOnChange(formatNumberInput(e.target.value), 'averageDiameter');
          }}
          isRequired={isRequiredInSchema(GreenPlantsSchema, `${entity}.fields.parameters.fields.averageDiameter`)}
          error={touched.flora?.parameters?.averageDiameter && get(errors.flora?.parameters?.averageDiameter, 'value')}
        />
        <Field
          isDisabled={isLoading}
          as={Input}
          name={`${entity}.parameters.treeTrunkTiltAngle.value[0]`}
          label={parameters.treeTrunkTiltAngle?.title}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            inputOnChange(formatNumberInput(e.target.value), 'treeTrunkTiltAngle');
          }}
        />

        <Flex>
          <Field
            isDisabled={isLoading}
            as={Checkbox}
            checked={
              Array.isArray(values.flora.parameters?.isFirstPlanted?.value)
                ? values.flora.parameters?.isFirstPlanted?.value[0] === 'true'
                : values.flora.parameters?.isFirstPlanted?.value === 'true'
            }
            pl={0}
            name={`${entity}.parameters.isFirstPlanted.value[0]`}
            label={parameters.isFirstPlanted?.title}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              if (e?.target) {
                setFieldValue(`${entity}.parameters.isFirstPlanted.value`, [e.target.checked ? 'true' : 'false']);
                setFieldValue(
                  `${entity}.parameters.isFirstPlanted.parameterId`,
                  parameters.isFirstPlanted?.parameterId
                );
              }
            }}
          />
        </Flex>
      </Stack>
    );
  }
);

export { FloraMeasurementsForm };
