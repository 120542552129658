import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import React, { createContext } from 'react';
import { createTheme, Paper, Table, TableBody, TableContainer, TableHead, ThemeProvider } from '@material-ui/core';
import { IDay, IRaid, ITableContext } from '../types';
import { TDictionariesArray } from 'models';
import { ListResponse } from 'models/api/list';
import { TableMenu } from './TableMenu';
import { RaidTableHead } from './TableHead';
import { raidRowCounter } from './TableFragments/Utils/Utils';
import { RaidLine } from './TableFragments/RaidLine';
import { Box, Flex } from '@chakra-ui/react';
import { IWithFileDownload } from 'hooks';
import Pagination from 'features/material-table/components/Pagination/Pagination';
import { AxiosRequestConfig } from 'axios';
import { LoaderItem } from 'components/common';

interface ICustomRaidTableProps {
  data: ListResponse<IDay> | null;
  onPageChange: (page: number, pageSize: number) => void;
  noise?: boolean;
  PEL?: boolean;
  page: number;
  limit: number;
  tasksDictionaries: { data: TDictionariesArray } | null;
  toggleOrder: (key: string) => void;
  orders: string[];
  downloadAsFile: IWithFileDownload;
  refetch: () => void;
  handleOpenModal?: (removeOptions: AxiosRequestConfig<any>) => void;
  isWrite: boolean;
  isExecute: boolean;
  isLoading?: boolean;
}

// @ts-ignore
export const TableContext = createContext<ITableContext>();

const theme = createTheme({
  overrides: {
    MuiTableContainer: {
      root: {
        borderTop: 'none',
        boxShadow: 'none',
      }
    },
    MuiTableCell: {
      root: {
        paddingTop: 2,
        paddingBottom: 2,
        fontSize: '12px',
        lineHeight: '14px',
        '& svg': {
          height: '14px',
          width: '14px'
        }
      },
    },
    MuiIconButton: {
      root: {
        padding: '0px 8px',
      },
    },
  },
});

export const CustomRaidTable = ({
  data,
  onPageChange,
  noise,
  PEL,
  page,
  limit,
  tasksDictionaries,
  toggleOrder,
  orders,
  downloadAsFile,
  refetch,
  handleOpenModal,
  isWrite,
  isExecute,
  isLoading
}: ICustomRaidTableProps) => (
  <>
    <TableContext.Provider
      value={{
        PEL,
        noise,
        tasksDictionaries,
        refetch,
        handleOpenModal,
        isWrite,
      }}
    >
      <ThemeProvider theme={theme}>
        <div
          style={{
            boxShadow:
              '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
            minHeight: 0,
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
          }}
        >
          <TableMenu downloadAsFile={downloadAsFile} isExecute={isExecute} />
          {isLoading ? <Box flexGrow={1}><LoaderItem /></Box> : (
            <TableContainer
              component={Paper}
              style={{
                maxHeight: 1600,
                borderTop: 'none',
                boxShadow: 'none',
                flexGrow: 1,
              }}
            >
              <Table stickyHeader>
                <TableHead>
                  <RaidTableHead orders={orders} PEL={PEL} noise={noise} toggleOrder={toggleOrder} />
                </TableHead>
                {data?.data.length ? (
                  <TableBody>
                    {data.data.map((dayItem: IDay, index: number) => {
                      const dayRowSpan = dayItem.raids
                        .reduce((acc: number, curr: IRaid) => acc + raidRowCounter(curr.tasks, PEL), 0) + 1;
                      return (
                        <React.Fragment key={index}>
                          <TableRow>
                            <TableCell rowSpan={dayRowSpan}>{dayItem.day}</TableCell>
                            <TableCell rowSpan={dayRowSpan}>{new Date(dayItem.date).toLocaleDateString()}</TableCell>
                          </TableRow>
                          {dayItem.raids.map((raid: IRaid, index) => (
                            <React.Fragment key={index}>
                              <RaidLine shift={raid.shift} tasks={raid.tasks} id={raid.id} number={raid.number} />
                            </React.Fragment>
                          ))}
                        </React.Fragment>
                      );
                    })}
                  </TableBody>
                ) : (
                  <TableBody>
                    <TableRow>
                      <TableCell colSpan={16}>
                        <Flex justifyContent="center">Нет данных</Flex>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          )}

          <Pagination
            onPageChange={(e, page) => onPageChange(page, limit)}
            onRowsPerPageChange={(e) => onPageChange(0, parseInt(e.target.value))}
            count={data?.meta.totalCount || 0}
            page={page}
            rowsPerPage={limit}
          />
        </div>
      </ThemeProvider>
    </TableContext.Provider>
  </>
);
