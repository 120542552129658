import { createTouchedErrors } from 'features/create-touched-errors';
import { FormikProvider, useFormik } from 'formik';
import { useRequest } from 'hooks/useRequest';
import { useParams } from 'react-router-dom';
import FieldsForm from 'pages/dictionaries/NSIEmissions/NSIEmissionsIndustries/NSIEmissionsIndustriesForms/NSIEmissionsIndustriesFormFields';
import { NSIEmissionsApiPath, NSIEmissionsIndustriesRootPath } from 'pages/dictionaries/NSIEmissions/consts/paths';
import { useEnhancedNotification } from 'hooks';
import { useEffect } from 'react';
import { initialEmptyValues } from 'pages/dictionaries/NSIEmissions/NSIEmissionsSources/NSIEmissionsSourcesForms/NSIEmissionsSourcesAdd';
import { NsiEmissionsIndustriesSchema } from 'models/schemas/nsiEmissions/NsiEmissionsIndustriesSchema';
import { useGoBackWithFallback } from 'lib/utils/goBackWithFallback';
import { useDictionary } from 'hooks/useDictionary';

const NSIEmissionsIndustriesEdit = () => {
  const { backWithFallback } = useGoBackWithFallback();
  const { id } = useParams<{ id: string }>();
  const { dictionaries, isDictionariesLoading } = useDictionary({ url: `${NSIEmissionsApiPath}/dictionaries` });
  const { isLoading: isItemLoading, result, get } = useRequest(`${NSIEmissionsApiPath}/industry/${id}`);

  useEffect(() => {
    void get();
  }, [get]);

  const { onSuccessEdit, onErrorEdit } = useEnhancedNotification();

  const onSuccess = () => {
    onSuccessEdit();
    backWithFallback(NSIEmissionsIndustriesRootPath);
  };

  const { put, isLoading } = useRequest(NSIEmissionsApiPath, onSuccess, onErrorEdit);

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    initialValues: { ...initialEmptyValues, ...result },
    validationSchema: NsiEmissionsIndustriesSchema,
    onSubmit: (values) => put({ ...values, type: 'industry' }),
  });

  return (
    <FormikProvider
      value={{
        ...formik,
        errors: createTouchedErrors(formik.errors, formik.touched),
      }}
    >
      <FieldsForm
        dictionaries={dictionaries}
        onCancel={() => {
          backWithFallback(NSIEmissionsIndustriesRootPath);
        }}
        isLoading={isDictionariesLoading || isLoading || isItemLoading}
        isEditMode
        editedObject={result}
      />
    </FormikProvider>
  );
};

export default NSIEmissionsIndustriesEdit;
