import React, { useCallback, useState } from 'react';
import { useRequest } from 'hooks/useRequest';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router';
import { Modal } from 'components/common/Modals';
import { useDisclosure, useEnhancedNotification } from 'hooks';
import { useParameters } from 'pages/equipment/Models/hooks/useParameters';
import {
  DeviceModelParametersTable,
  DeviceModelParametersTableProps,
} from 'pages/equipment/Models/components/DeviceModelParametersTable';
import { Button } from 'components/common';
import { Parameter } from 'pages/equipment/Models/components/DeviceModelParameters/Parameter';
import { COMMON_LOCALE } from 'constants/common';
import { Flex } from '@chakra-ui/react';

type OnDelete = DeviceModelParametersTableProps['onDelete'];
type OnEdit = DeviceModelParametersTableProps['onEdit'];

export const DeviceModelParameters: React.FC = () => {
  // TODO: refactor!
  const { id: modelId } = useParams<{ id: string }>();
  const { data, isLoading, refetch } = useParameters(modelId);

  const history = useHistory();
  const onBack = useCallback(() => {
    history.push('/equipment/models');
  }, [history]);

  const { onSuccessCreate } = useEnhancedNotification();

  // edit parameter
  const [id, setId] = useState<string | undefined>();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const onCancel = useCallback(() => {
    setId(undefined);
    onClose();
  }, [onClose]);
  const onSubmit = useCallback(() => {
    onSuccessCreate();
    refetch();
    setId(undefined);
    onClose();
  }, [onClose, onSuccessCreate, refetch]);
  const onEdit = useCallback<OnEdit>(
    (item) => {
      setId(item.id);
      onOpen();
    },
    [onOpen]
  );

  // add parameter
  const onClickAdd = useCallback(() => {
    setId(undefined);
    onOpen();
  }, [onOpen]);

  // delete parameter
  const { remove } = useRequest('', () => {
    refetch();
  });
  const onDelete = useCallback<OnDelete>(
    (item) => {
      remove({
        url: `/equipment/v10/equipments/parameters/${item.id}`,
      });
    },
    [remove]
  );

  return (
    <>
      <DeviceModelParametersTable data={data} isLoading={isLoading} onEdit={onEdit} onDelete={onDelete} />

      <Flex marginTop={5}>
        <Button onClick={onClickAdd}>Добавить параметр</Button>
        <Button onClick={onBack} variant="ghost" marginRight={5}>
          {COMMON_LOCALE.cancel}
        </Button>
      </Flex>

      <Modal isOpen={isOpen} onClose={onClose} header="Характеристики параметра модели">
        <Parameter modelId={modelId} id={id} onCancel={onCancel} onOk={onSubmit} />
      </Modal>
    </>
  );
};
