import { Box, BoxProps } from '@chakra-ui/react';
import React from 'react';

export const ContainerWidth = [1100, 1450];

export const ContentContainer: React.FC<BoxProps> = ({ children, ...props }) => (
  <Box maxWidth={ContainerWidth} position="relative" margin="auto" padding="0px 20px" transition="0.2s ease" {...props}>
    {children}
  </Box>
);
