export const WaterRouteRoot = '/data/water';

export const WaterRouteAskzvRoot = `${WaterRouteRoot}/askzv`;
export const WaterRouteAskzvMeasurments = `${WaterRouteAskzvRoot}/measurements`;
export const WaterRouteAskzvPackages = `${WaterRouteAskzvRoot}/packages`;

export const WaterRouteAskzvAddMeasurements = `${WaterRouteAskzvRoot}/measurements/add`;
export const WaterRouteAskzvEditMeasurements = `${WaterRouteAskzvRoot}/measurements/edit/:packageId`;
export const WaterRouteAskzvEditPackages = `${WaterRouteAskzvRoot}/packages/edit/:packageId`;
export const WaterRouteAskzvAddPackages = `${WaterRouteAskzvRoot}/packages/add`;

export const WaterRouteAskzvUpload = `${WaterRouteAskzvRoot}/upload`;
export const WaterRouteAskzvPreview = `${WaterRouteAskzvRoot}/load/preview`;

const WaterRouteBottomSoilBase = `${WaterRouteRoot}/bottom-soil`;
export const WaterRoutesBottomSoil = {
  root: WaterRouteBottomSoilBase,
  add: `${WaterRouteBottomSoilBase}/add`,
  edit: (id: string) => `${WaterRouteBottomSoilBase}/edit/${id}`,
};

export const WaterRouteSurfaceWatersRoot = `${WaterRouteRoot}/surface-waters`;
export const WaterRouteSurfaceWatersAdd = `${WaterRouteSurfaceWatersRoot}/add`;
export const WaterRouteSurfaceWatersEdit = `${WaterRouteSurfaceWatersRoot}/edit/:id`;
export const WaterSurfaceWatersLoadPreview = `${WaterRouteSurfaceWatersRoot}/load/preview`;
export const WaterRouteStateRoot = `${WaterRouteRoot}/state`;

export const WaterWPZListRoot = `${WaterRouteRoot}/wpz`;
const WaterWPZRootPath = `${WaterWPZListRoot}/zone`;
export const WaterRoutesWPZ = {
  root: WaterWPZRootPath,
  add: `${WaterWPZRootPath}/add`,
  edit: (id: string) => `${WaterWPZRootPath}/edit/${id}`,
};

const WaterRouteGroundCoastBase = `${WaterWPZListRoot}/groundcoast`;
export const WaterRoutesGroundCoast = {
  root: WaterRouteGroundCoastBase,
  add: `${WaterRouteGroundCoastBase}/add`,
  edit: (id: string) => `${WaterRouteGroundCoastBase}/edit/${id}`,
};
