import { FormWrapper } from 'components/common';
import { Box, Grid, Heading } from '@chakra-ui/react';
import React, { FC, useMemo } from 'react';
import { TDictionariesArray } from 'models/dictionaries';
import { Field, useFormikContext } from 'formik';
import { IReportProtocolForm } from 'pages/analytics/Raids/types';
import { CommaNumberInput } from '../../../../components/common/Input/comma-number-input';
import { TemperatureInput } from '../../../../components/common/Input/temperature-input';

interface IProps {
  dictionaries: {
    data: TDictionariesArray;
  } | null;
}

export const AnalyticsPelNoiseMeteoParameters: FC<IProps> = ({ dictionaries }) => {
  const { setFieldValue } = useFormikContext<IReportProtocolForm>();
  const alNoiseMeteoParameters = useMemo(
    () =>
      dictionaries?.data
        .find((dictionary) => dictionary.name === 'alNoiseMeteoParameters')
        ?.dictionary?.sort((a, b) => (a.name > b.name ? 1 : -1)),
    [dictionaries]
  );

  const temperatureIds = ['8f3df7a1-5dd4-4371-9996-8f3f26f9f8f1'];

  return (
    <Box flexDirection="column">
      <FormWrapper>
        <Grid marginTop="30px" gap="15px">
          <Heading variant="h2">Данные обследования</Heading>
          {alNoiseMeteoParameters?.map((parameter) => (
            <Field
              key={parameter.value}
              as={temperatureIds.includes(parameter.value) ? TemperatureInput : CommaNumberInput}
              name={`meteoValues[${parameter.value}].value`}
              label={parameter.name}
              onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
                setFieldValue(`meteoValues[${parameter.value}].value`, event.target.value)
              }
            />
          ))}
        </Grid>
      </FormWrapper>
    </Box>
  );
};
