import * as yup from 'yup';
import { numberError, requiredError } from '../constans';

export const noiseSchema = yup.object().shape({
  date: yup.string().nullable().required(requiredError),
  sourceId: yup.string().nullable().required(requiredError),
  operationMode: yup.number().nullable().typeError(numberError),
  calculationMode: yup.number().nullable().typeError(numberError),
  noiseMin: yup.number().nullable().typeError(numberError),
  noiseMax: yup.number().nullable().typeError(numberError).required(requiredError),
  noiseEquivalent: yup.number().nullable().typeError(numberError).required(requiredError),
  insideTemperature: yup.number().nullable().typeError(numberError),
  voltage: yup.number().nullable().typeError(numberError),
  coefficient1: yup.number().nullable().typeError(numberError),
  coefficient2: yup.number().nullable().typeError(numberError),
  coefficient3: yup.number().nullable().typeError(numberError),
  coefficient4: yup.number().nullable().typeError(numberError),
  door: yup.number().nullable().typeError(numberError),
  octave1Value: yup.number().nullable().typeError(numberError),
  octave2Value: yup.number().nullable().typeError(numberError),
  octave3Value: yup.number().nullable().typeError(numberError),
  octave4Value: yup.number().nullable().typeError(numberError),
  octave5Value: yup.number().nullable().typeError(numberError),
  octave6Value: yup.number().nullable().typeError(numberError),
  octave7Value: yup.number().nullable().typeError(numberError),
  octave8Value: yup.number().nullable().typeError(numberError),
  octave9Value: yup.number().nullable().typeError(numberError),
});
