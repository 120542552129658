import { useRequest } from 'hooks/useRequest';
import { useEffect } from 'react';
import { dictionariesApiPath, getParamApiPath } from './paths';
import { TDictionariesArray } from 'models/dictionaries';
import { IParameterItem } from './types';

export function useParamsDictionaries() {
  const { get, isLoading, result, error } = useRequest<{
    data: TDictionariesArray;
  }>(dictionariesApiPath);

  useEffect(() => {
    get();
  }, [get]);

  return { isLoading, result, error };
}

export function useParamItem(id: string) {
  const { get, isLoading, result, error } = useRequest<IParameterItem>(getParamApiPath(id));

  useEffect(() => {
    get();
  }, [get]);

  return { isLoading, result, error };
}
