import { MaterialTable } from 'features/material-table/MaterialTableMini';
import { useUploadFileProvider } from 'features/UploadedFileProvider';
import { Column } from '@material-table/core';
import { useMemo } from 'react';
import { MeteoDataZeroOstankinoMeasure } from './types';

interface MeteoDataZeroOstankinoPreviewProps {
  typeId: string;
}

const MeteoDataZeroOstankinoPreview: React.FC<MeteoDataZeroOstankinoPreviewProps> = () => {
  const { preview } = useUploadFileProvider<MeteoDataZeroOstankinoMeasure>();

  const columns = useMemo<Column<MeteoDataZeroOstankinoMeasure>[]>(
    () => [
      {
        title: 'Дата и время',
        field: 'date',
        render: (row) => row.date,
      },
      { title: 'Группа', field: 'group' },
      { title: 'Тип', field: 'type' },
      { title: 'Параметр', field: 'parameter.value' },
      { title: 'Станция', field: 'station.value' },
      {
        title: 'Значение',
        field: 'value',
        /**
         * логика такая - значение берется из value, если оно пустое -
         * тогда из key. Параметр и станция работают так же, но
         * там в key находятся id, поэтому там проще просто ничего не показывать.
         */
        render: (row) => row.value.value ?? row.value.key,
      },
    ],
    [],
  );

  return (
    <MaterialTable
      data={preview?.result?.measures}
      columns={columns}
    />
  );
};

export { MeteoDataZeroOstankinoPreview };
