import { SubsystemHeader } from 'components/common';
import { FormikProvider, useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import { CitizensAppealsPath, VideoCamerasPath } from '../consts/consts';
import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import React, { useEffect } from 'react';
import { Filter } from './Filter';
import { useList } from 'hooks';
import { CamTable } from './CamTable';
import { ICamItem } from './types/types';
import combineFilters from 'lib/utils/combineFilters';
import { IFilterItem } from 'models';
import { subsystemCodesMap } from 'constants/subsystemIdsMap';
import { useCustomRights } from 'hooks/useCustomRights';
import { DEFAULT_PAGE_SIZE_NEW_TABLE } from '../../../constants/tables';
import { FullPageWrapper } from '../../../components/table/FullPageWrapper';

const initialValues: Record<string, IFilterItem> = {
  number: {
    key: 'number',
    operation: 'EQ',
    value: '',
  },
  address: {
    key: 'address',
    operation: 'CONTAINS',
    value: '',
  },
};

export const VideoCameras = () => {
  const { isWrite } = useCustomRights(subsystemCodesMap.citizenAppeals.appealsCameras);
  useUpdateBreadcrumbs([
    {
      breadcrumb: 'Учет обращений граждан',
      path: CitizensAppealsPath,
    },
    {
      breadcrumb: 'Учет камер видеонаблюдения',
      path: VideoCamerasPath,
    },
  ]);

  const {
    response,
    toggleOrder,
    onPageChange,
    filterDTO,
    refetch,
    setFilters,
    materialTableProps,
  } = useList<ICamItem>('appeal/v10/cameras/search', {
    saveFiltersState: true,
    defaultPageSize: DEFAULT_PAGE_SIZE_NEW_TABLE,
    resetDataOnNextRequest: true,
  });

  const { setValues, ...formik } = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    initialValues,
    onSubmit: (values: any) => {
      setFilters(Object.values(values));
    },
    onReset: () => {
      setFilters([]);
    },
  });

  useEffect(() => {
    setValues(combineFilters(initialValues, filterDTO.filters));
  }, [filterDTO.filters, setValues]);

  const history = useHistory();

  return (
    <FullPageWrapper>
      <FormikProvider
        value={{
          setValues,
          ...formik,
        }}
      >
        <SubsystemHeader
          title="Форма учета камер видеонаблюдения"
          addButtonProps={{
            onClick: () => history.push(`${VideoCamerasPath}/add`),
            isDisabled: !isWrite,
          }}
        >
          <Filter />
        </SubsystemHeader>
        <CamTable
          isWrite={isWrite}
          refetch={refetch}
          response={response}
          toggleOrder={toggleOrder}
          filterDTO={filterDTO}
          onPageChange={onPageChange}
          materialTableProps={materialTableProps}
        />
      </FormikProvider>
    </FullPageWrapper>
  );
};
