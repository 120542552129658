import { IManagerPeriod, TGraphResponse } from '../types';
import { Box, Flex, Text } from '@chakra-ui/react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useEnhancedNotification, useRequest } from '../../../hooks';
import { Line } from 'react-chartjs-2';

interface IProps {
  title?: string
  icon?: React.ReactNode
  subtitle?: string
  height?: string
  url?: string | null
  yAxisTitle?: string
  period?: IManagerPeriod
  rowSubtitle?: Partial<Record<IManagerPeriod, string[]>>
}

const ManagerDataGraph = ({ title, icon, subtitle, height, url, yAxisTitle, period, rowSubtitle }: IProps) => {
  const [graphData, setGraphData] = useState<TGraphResponse>();
  const { onGetError } = useEnhancedNotification();

  const onSuccess = useCallback((response: TGraphResponse | null) => {
    if (!response) {
      return;
    }
    setGraphData(response);
  }, []);

  const { get } = useRequest<TGraphResponse>(
    '/reporting/v10/dashboard/indicators/graph',
    onSuccess,
    onGetError
  );

  useEffect(() => {
    if (url) {
      void get({
        url,
      });
    }
  }, [get, url]);

  const datasets = useMemo(() => ({ datasets: graphData || [] }), [graphData]);
  const opts = options({
    height, yAxisTitle, period, title: (period && rowSubtitle?.[period]?.[0]) || undefined
  });

  return (
    <Flex
      width="100%"
      flexDirection="column"
      p="20px"
    >
      <Flex justifyContent="space-between" flexDirection="column" mb="12px">
        <Flex gap="16px" alignItems="center" height="34px">
          {icon}
          <Box height="30px">
            <Text fontWeight="700" fontSize="18px" lineHeight="30px">{title}</Text>
          </Box>
        </Flex>
        {subtitle && (
          <Text mt="12px" width="100%" align="left" fontWeight="500" fontSize="14px" lineHeight="18px">{subtitle}</Text>
        )}
      </Flex>

      <Box style={{ height }}>
        {/* @ts-ignore */}
        <Line data={datasets} options={opts} />
      </Box>
    </Flex>
  );
};

interface IOptions { height?: string, yAxisTitle?: string, period?: IManagerPeriod, title?: string }
const options = ({
  height,
  yAxisTitle,
  period,
  title
}: IOptions) => ({
  responsive: true,
  maintainAspectRatio: !height,
  plugins: {
    legend: {
      position: 'top' as const,
    },
    title: {
      text: title || '',
      display: true,
    },
  },
  scales: {
    x: {
      type: 'time',
      time: {
        tooltipFormat: 'DD.MM.YYYY HH:mm:ss',
        minUnit: period && ['week', 'month', 'year'].includes(period) ? 'day' : 'second',
        displayFormats: {
          second: 'DD.MM HH:mm',
          minute: 'DD.MM HH:mm',
          hour: 'DD.MM HH:mm',
          day: 'DD.MM',
          week: 'DD.MM',
          month: 'MM.YYYY',
          quarter: 'MM.YYYY',
          year: 'YYYY',
        },
      }
    },
    y: {
      title: {
        display: true,
        text: yAxisTitle
      }
    }
  },
});

export default ManagerDataGraph;
