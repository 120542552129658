import { useRequest, UseRequestReturnType } from 'hooks/useRequest';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { DeviceModel } from 'pages/equipment/Models/types';

function useGetRequest<T>(url: string) {
  const { get, isLoading, result, error } = useRequest<T>(url);
  useEffect(() => {
    get();
  }, [get]);

  return {
    isLoading,
    result,
    error,
  };
}

interface UseDeviceModelResult {
  data?: DeviceModel;
  isLoading: boolean;
  error: UseRequestReturnType['error'];
}

export function useDeviceModel(): UseDeviceModelResult {
  // TODO: refactor!!!
  const { id: modelId } = useParams<{ id: string }>();
  const { result, isLoading, error } = useGetRequest<DeviceModel>(`/equipment/v10/equipments/models/${modelId}`);

  return {
    data: result ?? undefined,
    isLoading,
    error,
  };
}
