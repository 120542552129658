import { FormHeader } from 'components/common';
import { createTouchedErrors } from 'features/create-touched-errors';
import { FormikProvider, useFormik } from 'formik';
import { useNotifications } from 'hooks/useNotifications';
import { useRequest } from 'hooks/useRequest';

import { useHistory, useParams } from 'react-router';
import { ERROR_MESSAGES, SUCCESS_MESSAGES } from 'constants/messages';
import { UserSchema } from 'models/schemas/admin/user';
import { ReportAccessItem } from 'pages/admin/models/ReportAccessItem';
import { baseAdminUserUrl } from '../consts';
import FieldsForm from './FieldsForm';
import { SubsystemItem } from '../../models/SubsystemItem';
import { usePaginatedLoader, useTypedLoader } from '../../typedLoader';
import { DictionaryAccessItem } from '../../models/DictionaryAccessItem';
import { DisplayUserItem, UserItem } from '../../models/UserItem';
import { UserDeniedDistrictItem } from '../../models/UserDeniedDistrictItem';
import { UserSubsystem } from '../../models/UserSubsystem';
import { UserRightItem } from '../../models/UserRightItem';
import { UserPeriodItem } from '../../models/UserPeriodItem';
import { UserDeniedSourceItem } from '../../models/UserDeniedSourceItem';
import { UserRoleItem } from '../../models/UserRoleItem';
import { UserFormObj } from '../UserFormObj';
import { ParamsDeny } from 'hooks/admin/useUserParamsDeny';
import { useUserRights } from 'hooks';

export const EditUserForm = () => {
  const history = useHistory();
  const { refetch: resetRights } = useUserRights();
  const { id } = useParams<{ id: string }>();

  const { result: allUsers, isLoading: isUsersLoading } = usePaginatedLoader<DisplayUserItem>(baseAdminUserUrl);
  const currentUser = allUsers?.data?.find((u: UserItem) => u.id === id);

  // user loaders
  const baseUserUrl = `${baseAdminUserUrl}/${id}`;
  const { result: userSubsystems, isLoading: isUserSubsystemsLoading } = useTypedLoader<UserSubsystem[]>(
    `${baseUserUrl}/subsystem`
  );
  const { result: userRoles, isLoading: isUserRolesLoading } = useTypedLoader<UserRoleItem[]>(`${baseUserUrl}/role`);
  const { result: userRights, isLoading: isUserRightsLoading } = useTypedLoader<UserRightItem[]>(
    `${baseUserUrl}/right`
  );
  const { result: userBans, isLoading: isUserBansLoading } = useTypedLoader<UserRightItem[]>(
    `${baseUserUrl}/denied_right`
  );
  const { result: userPeriods, isLoading: isUserPeriodsLoading } = useTypedLoader<UserPeriodItem[]>(
    `${baseUserUrl}/period`
  );
  const { result: userDeniedSources, isLoading: isUserDeniedSourcesLoading } = useTypedLoader<UserDeniedSourceItem[]>(
    `${baseUserUrl}/source_deny`
  );
  const { result: userDeniedDistricts, isLoading: isUserDeniedDistrictsLoading } = useTypedLoader<
    UserDeniedDistrictItem[]
  >(`${baseUserUrl}/district_deny`);
  const { result: parametersDeny, isLoading: isParamLoading } = useTypedLoader<ParamsDeny[]>(
    `${baseUserUrl}/parameter_deny`
  );
  const { result: userDictionaries, isLoading: isDictionaryAccessLoading } = useTypedLoader<DictionaryAccessItem[]>(
    `${baseUserUrl}/dict`
  );
  const { result: userReports, isLoading: isUserReportsLoading } = useTypedLoader<ReportAccessItem>(
    `${baseUserUrl}/report`
  );

  const initialValues: any = {
    user: currentUser,
    subsystems: userSubsystems?.map((s: SubsystemItem) => s.id) || [],
    roles: userRoles?.map((r: UserRoleItem) => r.id) || [],
    rights: userRights?.map((r: UserRightItem) => r.id) || [],
    bans: userBans?.map((r: UserRightItem) => r.id) || [],
    periods: userPeriods?.map((r: UserPeriodItem) => ({ id: r.id, subsystem: r.subsystemId })) || [],
    deniedSources: userDeniedSources?.map((s: UserDeniedSourceItem) => s.id) || [],
    deniedDistricts: userDeniedDistricts?.map((s: UserDeniedDistrictItem) => s.districtId),
    userDictionaries,
    userReports,
    parametersDeny: parametersDeny?.map((item) => item.parameterId) || [],
  };
  const { successNotify, errorNotify } = useNotifications();

  // TODO: вынести отдельно
  const successHandler = () => {
    // обновить права - тк они могли быть отредактированными - лучше получать из ответа на сохранение
    if (resetRights) {
      resetRights();
    }
    successNotify({
      key: 'file/create/success',
      message: SUCCESS_MESSAGES.EDIT,
    });
    history.push('/admin/user');
  };
  const errorHandler = (error: any) =>
    errorNotify({
      key: 'file/create/error',
      message: `${ERROR_MESSAGES.EDIT}. ${typeof error === 'string' ? error : ''}`,
    });

  const { put: updateUser, isLoading: isUserUpdating } = useRequest(
    `${baseAdminUserUrl}/${id}/full`,
    successHandler,
    errorHandler
  );

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validationSchema: UserSchema,
    initialValues,
    onSubmit: async (values: UserFormObj) => {
      const user = {
        ...values.user,
        rights: [...values.rights],
        periods: values.periods.map((p: any) => ({
          periodId: p.id,
          subsystemId: p.subsystem,
        })),
        bans: values.bans,
        roles: values.roles,
        subsystems: values.subsystems,
        deniedDistiricts: values.deniedDistricts,
        deniedSources: values.deniedSources,
        deniedParams: values.parametersDeny,
      };
      await updateUser(user);
    },
  });

  const isLoading =
    isUserRolesLoading ||
    isUsersLoading ||
    isUserSubsystemsLoading ||
    isUserRightsLoading ||
    isUserBansLoading ||
    isUserPeriodsLoading ||
    isUserDeniedSourcesLoading ||
    isUserDeniedDistrictsLoading ||
    isDictionaryAccessLoading ||
    isUserReportsLoading ||
    isParamLoading ||
    isUserUpdating;

  return (
    <FormikProvider
      value={{
        ...formik,
        errors: createTouchedErrors(formik.errors, formik.touched),
      }}
    >
      <FormHeader url="/admin/user" header={`Редактирование учетной записи: "${currentUser?.fullName || ''}"`} />
      <FieldsForm onCancel={() => history.push('/admin/user')} isLoading={isLoading} />
    </FormikProvider>
  );
};
