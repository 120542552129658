import React from 'react';
import { PageHeader, RoutesList } from 'components/common';
import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import appLocale from 'constants/appLocale';
import { nsiWaterMenu } from 'features/WithSideBar/menus/nsiMenu';
import { NSIWaterRootPath } from 'pages/dictionaries/NSIWater/paths';
import { Flex, Heading } from '@chakra-ui/react';
import { useRoutesList } from 'hooks/useRoutesList';

const NSIWaterList: React.FC = () => {
  useUpdateBreadcrumbs([{ breadcrumb: appLocale.nsi.water.title, path: NSIWaterRootPath }]);

  const routes = useRoutesList(nsiWaterMenu);

  return (
    <Flex flexDirection="column">
      <PageHeader>
        <Heading>{appLocale.nsi.water.title}</Heading>
      </PageHeader>
      <RoutesList routes={routes} />
    </Flex>
  );
};

export default NSIWaterList;
