import { Field, FormikProvider, useFormik } from 'formik';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Select } from 'components/common';
import { Box, Flex } from '@chakra-ui/react';
import { useRequest } from 'hooks/useRequest';
import { InputFileMetadata } from 'features/UploadedFileProvider/types';
import { AxiosRequestConfig } from 'axios';
import { useNotifications } from 'hooks/useNotifications';
import { UploadFileModalContentDragAndDrop } from 'features/UploadFileDragAndDrop/UploadFileModalContent/UploadFileModalContentDragAndDrop';
import { FileLoadValidation } from './FileLoadValidation';
import { FileValidateErrors } from 'models';

// TODO допилить проброс typeId в реквест preview при необходимости

export interface UploadFileModalContentProps {
  types?: Array<{
    name: string;
    value: string | number;
  }>;
  createTypesPath?: (typeId: string) => string;
  createUploadPath: (typeId: string) => string;
  refetch?: () => void;

  onClose(): void;
}

const RootComponent: React.FC<UploadFileModalContentProps> = ({
  types,
  onClose,
  createTypesPath,
  createUploadPath,
  refetch,
}) => {
  const [errors, setErrors] = useState<FileValidateErrors | null>(null);

  const [request, setRequest] = React.useState<{
    formData: FormData;
    config: AxiosRequestConfig;
  }>();

  const [inputFileMetadata, setInputFileMetadata] = React.useState<InputFileMetadata>();

  const formik = useFormik<{ typeId: string }>({
    initialValues: { typeId: '' },
    onSubmit: () => undefined,
  });

  const { get, result, isLoading } = useRequest<{
    types: Array<{ name: string; value: string | number }>;
  }>(createTypesPath ? createTypesPath(formik.values.typeId) : '');

  const { successNotify, errorNotify } = useNotifications();

  const success = useCallback(
    (data: any) => {
      const message = typeof data === 'string' ? data : 'Файл успешно загружен.';
      successNotify({
        key: '/upload/success',
        message,
      });

      onClose();

      if (typeof refetch === 'function') {
        refetch();
      }
    },
    [successNotify, onClose, refetch],
  );

  const error = useCallback(
    (error: FileValidateErrors | null) => {
      setErrors(error);
      errorNotify({
        key: '/upload/error',
        message: 'Файл содержит ошибки.',
      });
    },
    [errorNotify],
  );

  const { post, isLoading: isPostLoading } = useRequest(createUploadPath(formik.values.typeId), success, error);

  const reqConfig = request?.config;
  const reqFormData = request?.formData;
  const onSubmit = useCallback(() => {
    post(reqFormData, reqConfig);
  }, [post, reqConfig, reqFormData]);

  useEffect(() => {
    if (!result && createTypesPath) {
      void get();
    }
  }, [get, result, createTypesPath]);

  const resultTypes = result?.types;
  const extTypes = useMemo(() => {
    if (types) {
      return types;
    }

    if (resultTypes) {
      return resultTypes;
    }

    return [];
  }, [resultTypes, types]);

  useEffect(() => {
    if (extTypes.length === 1) {
      formik.setFieldValue('typeId', extTypes[0].value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.setFieldValue, extTypes]);

  useEffect(
    () => () => {
      setRequest(undefined);
      setInputFileMetadata(undefined);
    },
    [],
  );

  return (
    <Flex flexDirection="column" width="500px">
      <FormikProvider value={formik}>
        <Field as={Select} label="Шаблон" name="typeId" options={extTypes} isDisabled={extTypes.length === 1} />
      </FormikProvider>

      <UploadFileModalContentDragAndDrop
        inputFileMetadata={inputFileMetadata}
        setInputFileMetadata={setInputFileMetadata}
        isDisabled={!formik.values.typeId}
        onChangeAsFormData={(formData, config) => {
          setRequest({ formData, config });
        }}
      />

      <Flex justifyContent="space-between">
        <Button onClick={onClose} variant="outline" color="PrimaryButton.500">
          Отмена
        </Button>
        <Button
          isDisabled={Boolean(!inputFileMetadata) || !formik.values.typeId}
          onClick={onSubmit}
          isLoading={isLoading || isPostLoading}
        >
          Загрузить
        </Button>
      </Flex>

      {errors?.descriptions && (
        <Box marginTop={5}>
          <FileLoadValidation errors={errors?.descriptions.map((error) => error.message)} />
        </Box>
      )}
    </Flex>
  );
};

const UploadFileModalContent = RootComponent;

export { UploadFileModalContent };
