const operations = [
  { value: 'set', name: 'Приравнять' },
  { value: 'minus', name: 'Вычесть' },
  { value: 'plus', name: 'Прибавить' },
  { value: 'divide', name: 'Разделить' },
  { value: 'multiply', name: 'Умножить' },
  { value: 'drift', name: 'Дрейф' },
  { value: 'delete', name: 'Удалить' }
];

export { operations };
