import * as yup from 'yup';
import { shouldBeLessThan, shouldBeMoreThan } from 'models/schemas/constans';

// бекенд не работает с 3-хзначными числами - требование аналитиков
export const latitudeValidation = {
  latitude: yup.number().lessThan(90, shouldBeLessThan(90)).moreThan(-90, shouldBeMoreThan(-90))
    .typeError('Допустимые символы: "123", "."'),
  longitude: yup.number().lessThan(100, shouldBeLessThan(100)).moreThan(-100, shouldBeMoreThan(-100))
    .typeError('Допустимые символы: "123", "."'),
};
