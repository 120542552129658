import { Flex, Heading, Spinner } from '@chakra-ui/react';
import { SelectOption } from 'components/common';
import React from 'react';
import { useFormikContext } from 'formik';

interface IProps {
  header: string;
  statuses: SelectOption[];
  isLoading?: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const AnalyticsFormWithStatusHeader = ({ header, isLoading, statuses }: IProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { errors } = useFormikContext<{ status: string }>();

  return (
    <Flex>
      <Heading variant="h1">
        {header}
        {isLoading && <Spinner ml={2} color="PrimaryButton.500" />}
      </Heading>
      {/* temporary hidden field */}
      {/* <Box */}
      {/*  style={{ width: '300px', marginLeft: 'auto' }} */}
      {/* > */}
      {/*  <Field */}
      {/*    as={Select} */}
      {/*    name="status" */}
      {/*    label={appLocale.analytics.fields.status} */}
      {/*    options={statuses} */}
      {/*    error={errors?.status} */}
      {/*    isRequired */}
      {/*  /> */}
      {/* </Box> */}
    </Flex>
  );
};
