import { Filter } from 'features/Filter';
import { createInitialValuesToFormik, createValuesToRequest } from 'features/Filter/utils';
import React, { useMemo } from 'react';
import {
  TAtmosphereMonitoringFilterDTO,
  TAtmosphereMonitoringFilterType,
} from 'pages/data/Atmosphere/Monitoring/types';
import { IFilterItem, TDictionariesArray, TDictionaryItemDictionary, TFiltersArray } from 'models';
import { IFilterValues } from 'components/common';
import { getSelectOptionsFromDictionaryByNameWithTransformation } from 'features/get-select-options-from-dictionary';
import combineFilters from 'lib/utils/combineFilters';
import { transformFiltersArray } from 'features/Filter/transformFiltersArray';
import { createDateToRequestWithTimezone } from 'lib/create-date';

type FilterListInitialValues = Record<
  'dateTimeMin' | 'dateTimeMax' | 'paramId' | 'stationId' | 'deleted',
  TAtmosphereMonitoringFilterDTO['filters'][number]
>;

interface FilterProps {
  dictionaries: {
    data: TDictionariesArray;
  } | null;
  isLoading: boolean;
  onSubmit: (values: TFiltersArray<TAtmosphereMonitoringFilterType>) => void;
  filtersFromGraphic: Partial<IFilterValues> | null;
  setFiltersFromGraphic: (value: Partial<IFilterValues> | null) => void;
  filters: IFilterItem[];
  stations?: TDictionaryItemDictionary[];
}

const initialValues: FilterListInitialValues = {
  dateTimeMin: {
    key: 'avg.valDateTime',
    operation: 'GTE',
    value: null,
  },
  dateTimeMax: {
    key: 'avg.valDateTime',
    operation: 'LTE',
    value: null,
  },
  paramId: {
    key: 'paramId',
    operation: 'EQ',
    value: '',
  },
  stationId: {
    key: 'stationId',
    operation: 'EQ',
    value: '',
  },
  deleted: {
    key: 'deleted',
    operation: 'EQ',
    value: false,
  },
};

const initialValuesToFormik = createInitialValuesToFormik(initialValues);

function AtmosphereFilter({
  setFiltersFromGraphic,
  filtersFromGraphic,
  dictionaries,
  isLoading,
  onSubmit,
  children,
  filters,
  stations
}: React.PropsWithChildren<FilterProps>) {
  const paramNumbers = useMemo(
    () =>
      getSelectOptionsFromDictionaryByNameWithTransformation(
        dictionaries?.data,
        'params',
        'title'
      ),
    [dictionaries]
  );

  const presetValues = useMemo<{ key: string, value: IFilterItem['value'] }[]>(() => {
    const preset: { key: string, value: IFilterItem['value'] }[] = [];
    const initialFilters = combineFilters(initialValues, filters);
    Object.keys(initialFilters).forEach((key) => {
      preset.push({ key: `${key}`, value: initialFilters[key].value });
    });
    return preset;
  }, [filters]);

  return (
    <Filter<typeof initialValuesToFormik>
      isLoading={isLoading}
      isDisabled={isLoading}
      setFiltersFromGraphic={setFiltersFromGraphic}
      filtersFromGraphic={filtersFromGraphic}
      onSubmit={(values) => {
        const { dateTimeMin, dateTimeMax, ...rest } = createValuesToRequest(initialValues, values);
        const filters = transformFiltersArray<TAtmosphereMonitoringFilterDTO['filters']>(
          Object.values({
            ...rest,
            dateTimeMin: {
              ...dateTimeMin,
              value: createDateToRequestWithTimezone(String(dateTimeMin.value)),
            },
            dateTimeMax: {
              ...dateTimeMax,
              value: createDateToRequestWithTimezone(String(dateTimeMax.value)),
            },
          }),
        );
        onSubmit(filters);
      }}
      initialValues={initialValuesToFormik}
      mainFields={[
        {
          type: 'DATE',
          name: 'dateTimeMin',
          label: 'От',
          showTimeInput: true,
        },
        {
          type: 'DATE',
          name: 'dateTimeMax',
          label: 'До',
          showTimeInput: true,
        },
        {
          type: 'SELECT',
          name: 'stationId',
          label: 'Станция',
          options: stations,
        },
        {
          type: 'SELECT',
          name: 'paramId',
          label: 'Параметр',
          options: paramNumbers,
        },
        // {
        //   type: 'CHECKBOX',
        //   name: 'deleted',
        //   label: appLocale.common.showDeleted,
        // },
      ]}
      presetValues={presetValues}
    >
      {children}
    </Filter>
  );
}

export { AtmosphereFilter };
