import { Button, useDisclosure } from '@chakra-ui/react';
import { Modal } from 'components/common';
import { useCallback } from 'react';
import Graph from 'pages/data/GeoecologicalProcesses/GeologicalProcessesGroundwater/Measuring/Graph/Graph';
import { IPeriod } from 'features/PeriodsButtons/types';

interface UseGraph {
  periods: IPeriod[] | null;
  selectedIds?: string[];
  filters: any;
  onClose: () => void;
}
/* eslint-disable */
export const useGraph = ({ selectedIds, filters, periods, onClose }: UseGraph) => {
  const modalDisclosure = useDisclosure();

  const onModalOpen = useCallback(() => {
    modalDisclosure.onOpen();
  }, [modalDisclosure]);

  const GraphButton = ({ isDisabled }: { isDisabled: boolean }) => (
    <Button variant="solid" size="sm" colorScheme="PrimaryButton" onClick={onModalOpen} isDisabled={isDisabled}>
      Открыть график
    </Button>
  );

  const GraphModal = (
    <Modal closeButtonText="Табличное представление" isOpen={modalDisclosure.isOpen} onClose={modalDisclosure.onClose} width="100%">
      <Graph selectedIds={selectedIds} initialFilters={filters} periods={periods} />
    </Modal>
  );

  return {
    GraphButton,
    GraphModal,
  };
};
