import { Tab, TabList } from '@chakra-ui/react';
import { FormHeader } from './FormHeader';
import { DefaultText } from 'components/common';
import React from 'react';

interface Props {
  url?: string;
  tabNames: string[];
  header?: string;
  customHeader?: React.ReactNode;
  onBackClick?: () => void;
}
export const TabListHeader = ({ url, header, tabNames, customHeader, onBackClick }: Props) => (
  <FormHeader onBackClick={onBackClick} url={url} header={header} customHeader={customHeader}>
    <TabList>
      {
        tabNames.map((tab, i) => (
          <Tab key={i}>
            <DefaultText cursor="pointer">{tab}</DefaultText>
          </Tab>
        ))
      }
    </TabList>
  </FormHeader>
);
