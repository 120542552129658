import * as yup from 'yup';
import { requiredError } from '../constans';
import { latitudeValidation } from 'models/schemas/location';

export const NsiReagentsLawnsSchema = yup.object().shape({
  parentAreaId: yup.string().nullable().required(requiredError),
  number: yup.number().nullable().required(requiredError),
  status: yup.string().nullable().required(requiredError),

  landmark: yup.string().nullable().required(requiredError),
  ...latitudeValidation,
});
