import React, { useMemo } from 'react';
import { SubsystemHeader } from 'components/common';
import { FTPUploadFilter } from 'pages/admin/FTP/FTPUpload/FTPUpload/Filter/FTPUploadFilter';
import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';
import appLocale from 'constants/appLocale';
import { baseAdminUrl } from 'pages/admin';
import { DataSourcesList, FTPRootPath, UploadConfig } from 'pages/admin/FTP';
import { useHistory } from 'react-router-dom';
import { useHandleDeleteData, useList } from 'hooks';
import { MaterialTable } from 'features/material-table/MaterialTableMini';
import { TFiltersArray } from 'models';
import { useFTPUploadDictionary } from 'pages/admin/FTP/FTPUpload/FTPUpload/useFTPUploadDictionary';
import { Column } from '@material-table/core';
import { dictionaryToMap } from 'lib/utils/dictionaryToMap';
import { DEFAULT_PAGE_SIZE_NEW_TABLE } from '../../../../../constants/tables';
import { FullPageWrapper } from '../../../../../components/table/FullPageWrapper';

export const FTPUpload = () => {
  const { push } = useHistory();

  const {
    result: dictionaries,
    isLoading: isDictLoading,
    exchanges,
    status,
    uploadTemplates,
  } = useFTPUploadDictionary();
  const exchangeMap = dictionaryToMap(exchanges);
  const statusMap = dictionaryToMap(status);
  const uploadMap = dictionaryToMap(uploadTemplates);

  const {
    refetch,
    isLoading: isListLoading,
    response,
    toggleOrder,
    setFilters,
    onPageChange,
    filterDTO,
  } = useList<DataSourcesList>(`${baseAdminUrl}/upload-config/list`, {
    initialOrderFieldKey: '-name',
    defaultPageSize: DEFAULT_PAGE_SIZE_NEW_TABLE,
    resetDataOnNextRequest: true,
  });

  useUpdateBreadcrumbs([
    {
      breadcrumb: appLocale.admin.main,
      path: '/admin',
    },
    {
      breadcrumb: appLocale.admin.ftp,
      path: FTPRootPath,
    },
    {
      breadcrumb: appLocale.admin.ftpUpload,
      path: `${FTPRootPath}/upload`,
    },
  ]);

  const { handleOpenModal, ModalComponent } = useHandleDeleteData({ refetch });

  const columns = useMemo<Column<UploadConfig>[]>(
    () => [
      {
        title: 'Наименование',
        field: 'name',
      },
      {
        title: 'Каталог',
        field: 'exchangeId',
        render: (row) => exchangeMap.get(row.exchangeId),
      },
      {
        title: 'Шаблон выгрузки',
        field: 'templateId',
        render: (row) => uploadMap.get(row.templateId),
      },
      {
        title: 'Статус',
        field: 'status',
        render: (row) => statusMap.get(String(row.status)),
      },
    ],
    [exchangeMap, statusMap, uploadMap],
  );

  const isLoading = useMemo(() => isDictLoading || isListLoading, [isDictLoading, isListLoading]);

  return (
    <FullPageWrapper>
      <SubsystemHeader
        title="Выгрузка данных на FTP-сервер"
        addButtonProps={{ onClick: () => push(`${FTPRootPath}/upload/add`) }}
        filtersComponent={
          <FTPUploadFilter
            isLoading={isLoading}
            dictionaries={dictionaries}
            onSubmit={(values) => {
              const filters: TFiltersArray = Object.values(values);
              setFilters(filters);
            }}
          />
        }
      />

      <MaterialTable
        filterDTO={filterDTO}
        isLoading={isLoading}
        data={response?.data}
        meta={response?.meta}
        toggleOrder={toggleOrder}
        onPageChange={onPageChange}
        totalCount={response?.meta.totalCount}
        title="Источники данных"
        columns={columns}
        actions={[
          {
            type: 'edit',
            onClick: (_e, item) => {
              push(`${FTPRootPath}/upload/edit/${item.id}`, item);
            },
          },
          {
            type: 'delete',
            onClick: (_e, item) => {
              if (item.id) {
                handleOpenModal({ url: `${baseAdminUrl}/upload-config/${item.id}` });
              }
            },
          },
        ]}
        options={{ selection: false }}
      />

      {ModalComponent}
    </FullPageWrapper>
  );
};
