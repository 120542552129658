import appLocale from 'constants/appLocale';
import { WaterRouteRoot, WaterRoutesBottomSoil } from 'pages/data/Water/routes';
import { BottomSoilTable } from 'pages/data/Water/BottomSoil/BottomSoilTable/BottomSoilTable';
import { useUpdateBreadcrumbs } from 'features/breadcrumbs-provider';

export const WaterBottomSoil = () => {
  useUpdateBreadcrumbs([
    { breadcrumb: 'Данные', path: '/data' },
    {
      breadcrumb: 'Водные объекты',
      path: WaterRouteRoot,
    },
    {
      breadcrumb: appLocale.subsystems.bottomSoil,
      path: `${WaterRoutesBottomSoil.root}/main-data`,
    },
  ]);

  return <BottomSoilTable />;
};
