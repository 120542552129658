import { FormWrapper } from 'components/common';
import { FC, useMemo } from 'react';
import { getSelectOptionsFromDictionaryByName } from 'features/get-select-options-from-dictionary';
import { TDictionariesArray } from 'models';
import FormFieldFromDictionary from 'components/form/FormFieldFromDictionary';
import { Box, Grid, Heading } from '@chakra-ui/react';
import { soilSchemaRequiredIds } from 'models/schemas/soil/soil';

interface Props {
  isWrite?: boolean;
  dictionaries: {
    data: TDictionariesArray;
  } | null;
}

export const MeasuringTab: FC<Props> = ({ children, isWrite, dictionaries }) => {
  const commonParameters = useMemo(
    () => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'commonParameters'),
    [dictionaries]
  );

  const agroChemicalParameters = useMemo(
    () => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'agroChemicalParameters'),
    [dictionaries]
  );

  const pollutantParameters = useMemo(
    () => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'pollutantParameters'),
    [dictionaries]
  );

  const onExtractParameters = useMemo(
    () => getSelectOptionsFromDictionaryByName(dictionaries?.data, 'onExtractParameters'),
    [dictionaries]
  );

  const disabledFieldIds = ['912864ef-dec8-4d54-8a55-f0648ab40c7f'];

  return (
    <Box flexDirection="column">
      <FormWrapper width="600px">
        <Grid marginTop="30px" gap="15px">
          <Heading variant="h2">Общие показатели</Heading>
          <Grid gridTemplateColumns="repeat(2, 1fr)" gap="15px">
            {commonParameters.map((field) => (
              <FormFieldFromDictionary
                key={field.parameterId}
                field={field}
                isDisabled={!isWrite || disabledFieldIds.includes(String(field.parameterId))}
                isRequired={Boolean(field.parameterId && soilSchemaRequiredIds.includes(field.parameterId))}
              />
            ))}
          </Grid>

          <Heading variant="h2">Агрохимические показатели</Heading>

          <Grid gridTemplateColumns="repeat(2, 1fr)" gap="15px">
            {agroChemicalParameters.map((field) => (
              <FormFieldFromDictionary
                key={field.parameterId}
                field={field}
                isDisabled={!isWrite}
                isRequired={Boolean(field.parameterId && soilSchemaRequiredIds.includes(field.parameterId))}
              />
            ))}
          </Grid>

          <Heading variant="h2">Загрязняющие вещества</Heading>

          <Grid gridTemplateColumns="repeat(2, 1fr)" gap="15px">
            {pollutantParameters.map((field) => (
              <FormFieldFromDictionary
                key={field.parameterId}
                field={field}
                isDisabled={!isWrite}
                isRequired={Boolean(field.parameterId && soilSchemaRequiredIds.includes(field.parameterId))}
              />
            ))}
          </Grid>

          <Heading variant="h2">В водной вытяжке</Heading>

          <Grid gridTemplateColumns="repeat(2, 1fr)" gap="15px">
            {onExtractParameters.map((field) => (
              <FormFieldFromDictionary
                key={field.parameterId}
                field={field}
                isDisabled={!isWrite}
                isRequired={Boolean(field.parameterId && soilSchemaRequiredIds.includes(field.parameterId))}
              />
            ))}
          </Grid>

          {children}
        </Grid>
      </FormWrapper>
    </Box>
  );
};
