import React, { useCallback, useMemo, useState } from 'react';
import { FieldsForm } from './FieldsForm';
import { FormikProvider, useFormik, useFormikContext } from 'formik';
import {
  ADD_MEDIA_ERROR,
  ADD_MEDIA_SUCCESS,
  ADD_RESPONSE_SUCCESS,
  AppealValues,
  Complaint,
  EDIT_RESPONSE_ERROR,
  getAnswerEditEndpoint,
} from 'pages/citizenAppeal';
import { TDictionariesArray } from 'models/dictionaries';
import { useRequest } from 'hooks/useRequest';
import { useNotifications } from 'hooks/useNotifications';
import { createTouchedErrors } from 'features/create-touched-errors';
import { IUploadMedia } from 'components/common/FileUploadDragAndDrop/types';
import { updateFiles, UploadObjectType } from 'api/services/media';

interface Props {
  dictionaries?: TDictionariesArray;
  incident: Complaint | null;
  complaint: Complaint | null;
  isWrite?: boolean
}

export const EditResponseForm = ({ dictionaries, incident, complaint, isWrite }: Props) => {
  const [media, setMedia] = useState<IUploadMedia[][]>([[]]);
  const { values: appealValues } = useFormikContext<AppealValues>();
  const { successNotify, errorNotify } = useNotifications();

  const onMediaSuccess = useCallback(() => {
    successNotify({
      key: 'file/add-media/success',
      message: ADD_MEDIA_SUCCESS,
    });
  }, [successNotify]);

  const onMediaError = useCallback((message: string) => {
    errorNotify({
      key: 'file/add-media/error',
      message: `${ADD_MEDIA_ERROR}. ${message}`
    });
  }, [errorNotify]);

  const onSuccess = () => {
    successNotify({
      key: 'file/edit/success',
      message: ADD_RESPONSE_SUCCESS,
    });
  };

  const onError = () =>
    errorNotify({
      key: 'file/edit/error',
      message: EDIT_RESPONSE_ERROR,
    });

  const { put } = useRequest(getAnswerEditEndpoint(complaint?.complaintId), onSuccess, onError);

  const initialEmptyValues = useMemo(() => ({
    answers: [
      {
        answerAddress: '',
        answerAgreementDate: '',
        answerAgreementSet: '',
        answerAssignmentKind: '',
        answerDocDate: '',
        answerDocNumber: '',
        answerId: '',
        answerStoreId: '',
        answerText: '',
        answerUrl: ''
      }
    ],
    complaintAnswered: false,
    complaintId: '',
    complaintStatusId: ''
  }), []);

  const initialValues = useMemo(() => {
    const editedValues: any = { ...initialEmptyValues };
    if (complaint) {
      Object
        .entries(complaint)
        .forEach((resItem: [string, any]) => {
          const key = resItem[0];
          const val = resItem[1];
          if (editedValues[key] !== undefined) {
            editedValues[key] = val;
          }
        });
    }
    return editedValues;
  }, [complaint, initialEmptyValues]);

  const onSaveAnswer = useCallback(async (values: any) => {
    const response = await put(values);
    // @ts-ignore
    if (response && response.answers) {
      // @ts-ignore
      response?.answers?.forEach((answer, idx) => {
        if (media[idx]) {
          updateFiles(answer.answerId, UploadObjectType.ANSWER, media[idx])
            .then((response?: IUploadMedia[]) => {
              setMedia((prevState) => {
                const newMedia = [...prevState];
                if (response) {
                  newMedia[idx] = response;
                }
                return newMedia;
              });
              onMediaSuccess();
            })
            .catch((error: Error) => {
              onMediaError(error.message);
            });
        }
      });
    }
  }, [media, onMediaError, onMediaSuccess, put]);

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    initialValues,
    onSubmit: (values: any) => {
      onSaveAnswer(values);
    },
  });

  return (
    <FormikProvider
      value={{
        ...formik,
        errors: createTouchedErrors(formik.errors, formik.touched),
      }}
    >
      <FieldsForm
        setMedia={setMedia}
        media={media}
        appealValues={appealValues}
        dictionaries={dictionaries}
        incident={incident}
        complaint={complaint}
        isWrite={isWrite}
      />
    </FormikProvider>
  );
};
