import { Stack } from '@chakra-ui/react';
import { Input, Select } from 'components/common';
import { dictParametersToMap } from 'features/parametersMap';
import { Field, useFormikContext } from 'formik';
import { concat, get, isNull } from 'lodash';
import {
  TLawnLawnParameteresDictionariesItemNames,
  TLawnsDictionaries,
} from 'pages/data/GreenPlants/Lawns/useLawnsDictionaries';
import React, { useCallback } from 'react';
import { TGreenPlantsForm } from 'pages/data/GreenPlants/Form/types';
import { ILawnsTabPanelProps } from './types';
import { getDictionariesIds, getDictionaryId } from 'pages/data/GreenPlants/utils';
import { formatNumberInput } from 'lib/utils/forms/formatNumberInput';
import { getSelectOptionsFromDictionaryByName } from '../../../../../../features/get-select-options-from-dictionary';

interface TLawnsCerealsWeedsMossesFormProps {
  entity: ILawnsTabPanelProps['entity'];
  isLoading: ILawnsTabPanelProps['isLoading'];
  areaParameters: TLawnsDictionaries['areaParameters'];
  lawnParameters: TLawnsDictionaries['lawnParameters'];
}

const LawnsCerealsWeedsMossesForm: React.FC<TLawnsCerealsWeedsMossesFormProps> = (
  {
    entity,
    isLoading,
    lawnParameters = [],
    areaParameters = [],
  }
) => {
  const ctx = useFormikContext<TGreenPlantsForm>();

  const parameters = dictParametersToMap(concat(lawnParameters, areaParameters));

  const inputOnChange = useCallback(
    (value: string | number | null, key: TLawnLawnParameteresDictionariesItemNames) => {
      ctx.setFieldValue(`${entity}.parameters.${key}`, {
        ...ctx.values.lawns.parameters[key],
        parameterId: parameters[key]?.parameterId,
        value: isNull(value) ? null : [value],
      });
    },
    [ctx, entity, parameters]
  );

  const getOptionsByName = useCallback((name: string) => getSelectOptionsFromDictionaryByName(
    [...lawnParameters, ...areaParameters],
    name,
    false,
    get(ctx.values, `${entity}.parameters.${name}.ids`)
  ), [areaParameters, ctx.values, entity, lawnParameters]);

  return (
    <Stack>
      <Stack>
        <Field
          isDisabled={isLoading}
          as={Select}
          options={getOptionsByName('cereals')}
          name={`${entity}.parameters.cereals.dictionaryItemId`}
          label={parameters.cereals?.title}
          multiple
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
            if (Array.isArray(e.target.value)) {
              ctx.setFieldValue(`${entity}.parameters.cereals.dictionaryItemId`,
                getDictionariesIds(e.target.value, parameters.cereals.values));
              ctx.setFieldValue(`${entity}.parameters.cereals.value`, e.target.value);
              ctx.setFieldValue(`${entity}.parameters.cereals.parameterId`, parameters.cereals?.parameterId);
            }
          }}
        />

        <Field
          isDisabled={isLoading}
          as={Select}
          options={getOptionsByName('howManyCereals')}
          name={`${entity}.parameters.howManyCereals.dictionaryItemId[0]`}
          label={parameters.howManyCereals?.title}
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
            if (e?.target) {
              ctx.setFieldValue(`${entity}.parameters.howManyCereals.dictionaryItemId`, [
                getDictionaryId(e.target.value, parameters.howManyCereals.values)
              ]);
              ctx.setFieldValue(`${entity}.parameters.howManyCereals.value`, [e.target.value]);
              ctx.setFieldValue(
                `${entity}.parameters.howManyCereals.parameterId`,
                parameters.howManyCereals?.parameterId,
              );
            }
          }}
        />

        <Field
          isDisabled={isLoading}
          as={Input}
          name={`${entity}.parameters.countCereals.value[0]`}
          label={parameters.countCereals?.title}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            inputOnChange(formatNumberInput(e.target.value), 'countCereals');
          }}
          error={get(ctx.errors.lawns?.parameters?.countCereals, 'value')}
        />
      </Stack>

      <Stack>
        <Field
          isDisabled={isLoading}
          as={Select}
          options={getOptionsByName('weeds')}
          name={`${entity}.parameters.weeds.dictionaryItemId`}
          label={parameters.weeds?.title}
          multiple
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
            if (Array.isArray(e.target.value)) {
              ctx.setFieldValue(`${entity}.parameters.weeds.dictionaryItemId`,
                getDictionariesIds(e.target.value, parameters.weeds.values));
              ctx.setFieldValue(`${entity}.parameters.weeds.value`, e.target.value);
              ctx.setFieldValue(`${entity}.parameters.weeds.parameterId`, parameters.weeds?.parameterId);
            }
          }}
        />

        <Field
          isDisabled={isLoading}
          as={Select}
          options={getOptionsByName('howManyWeeds')}
          name={`${entity}.parameters.howManyWeeds.dictionaryItemId[0]`}
          label={parameters.howManyWeeds?.title}
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
            if (e?.target) {
              ctx.setFieldValue(`${entity}.parameters.howManyWeeds.dictionaryItemId`, [
                getDictionaryId(e.target.value, parameters.howManyWeeds.values)
              ]);
              ctx.setFieldValue(`${entity}.parameters.howManyWeeds.value`, [e.target.value]);
              ctx.setFieldValue(`${entity}.parameters.howManyWeeds.parameterId`, parameters.howManyWeeds?.parameterId);
            }
          }}
        />

        <Field
          isDisabled={isLoading}
          as={Input}
          name={`${entity}.parameters.countWeeds.value[0]`}
          label={parameters.countWeeds?.title}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            inputOnChange(formatNumberInput(e.target.value), 'countWeeds');
          }}
          error={get(ctx.errors.lawns?.parameters?.countWeeds, 'value')}
        />
      </Stack>

      <Stack>
        <Field
          isDisabled={isLoading}
          as={Select}
          options={getOptionsByName('moss')}
          name={`${entity}.parameters.moss.dictionaryItemId`}
          label={parameters.moss?.title}
          multiple
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
            if (Array.isArray(e.target.value)) {
              ctx.setFieldValue(`${entity}.parameters.moss.dictionaryItemId`,
                getDictionariesIds(e.target.value, parameters.moss.values));
              ctx.setFieldValue(`${entity}.parameters.moss.value`, e.target.value);
              ctx.setFieldValue(`${entity}.parameters.moss.parameterId`, parameters.moss?.parameterId);
            }
          }}
        />

        <Field
          isDisabled={isLoading}
          as={Select}
          options={getOptionsByName('howManyMosses')}
          name={`${entity}.parameters.howManyMosses.dictionaryItemId[0]`}
          label={parameters.howManyMosses?.title}
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
            if (e?.target) {
              ctx.setFieldValue(`${entity}.parameters.howManyMosses.dictionaryItemId`, [
                getDictionaryId(e.target.value, parameters.howManyMosses.values)
              ]);
              ctx.setFieldValue(`${entity}.parameters.howManyMosses.value`, [e.target.value]);
              ctx.setFieldValue(
                `${entity}.parameters.howManyMosses.parameterId`,
                parameters.howManyMosses?.parameterId,
              );
            }
          }}
        />

        <Field
          isDisabled={isLoading}
          as={Input}
          name={`${entity}.parameters.countMosses.value[0]`}
          label={parameters.countMosses?.title}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            inputOnChange(formatNumberInput(e.target.value), 'countMosses');
          }}
          error={get(ctx.errors.lawns?.parameters?.countMosses, 'value')}
        />
      </Stack>
    </Stack>
  );
};

export { LawnsCerealsWeedsMossesForm };
