import { useRequest, UseRequestReturnType } from 'hooks/useRequest';
import { useEffect } from 'react';

interface Resp {
  types: Array<{ name: string; value: string | number }>;
}

type UseUploadFileLoadTypesReturnType = Omit<UseRequestReturnType<Resp>, 'post' | 'get' | 'put' | 'remove'>;

interface UseUploadFileLoadTypes {
  (url?: string): UseUploadFileLoadTypesReturnType;
}

const useUploadFileLoadTypes: UseUploadFileLoadTypes = (url = '') => {
  const { get, result, error, isLoading, reset } = useRequest<Resp>(url);

  useEffect(() => {
    if (url) {
      get();
    }
  }, [get, url]);

  return { result, error, isLoading, reset };
};

export type { UseUploadFileLoadTypesReturnType, UseUploadFileLoadTypes };
export { useUploadFileLoadTypes };
