import { Route } from 'react-router-dom';
import { AdverseMeteorogicalCond } from '../data/Meteo/AdverseMeteorogicalCond';
import { AddAMCRecord } from '../data/Meteo/AdverseMeteorogicalCond/AddAMCRecord';

export const MeteoNMURoutes = () => (
  <>
    <Route exact path="/data/meteo/nmu" component={AdverseMeteorogicalCond} />
    <Route exact path="/data/meteo/nmu/add" component={AddAMCRecord} />
    <Route exact path="/data/meteo/nmu/add/:id" component={AddAMCRecord} />
  </>
);
