import React, { useCallback, useEffect, useState } from 'react';
import { createTouchedErrors } from 'features/create-touched-errors';
import { FormikProvider, useFormik } from 'formik';
import { useRequest } from 'hooks/useRequest';
import { useLocation, useParams } from 'react-router-dom';
import { initialEmptyValues } from './initialEmptyValues';
import FieldsForm from 'pages/analytics/forms/VegetativeState/AnalyticsDeicingVegFieldsForm';
import { IUploadMedia } from 'components/common/FileUploadDragAndDrop/types';
import { updateFiles } from 'api/services/media';
import { useObjectType } from 'hooks/useObjectType';
import { ParameterRecord, VegetationDeicingItem } from 'pages/data/PGR/GreenPlants/VegetativeState/useVegDicingList';
import { AnalyticsVegStateSchema } from 'models/schemas/analytics/analyticsVegSchema';
import { useEnhancedNotification } from 'hooks';
import { useGoBackWithFallback } from 'lib/utils/goBackWithFallback';
import { useDictionary } from 'hooks/useDictionary';

type Value = {
  id: string;
  value: string;
  parameterId?: string;
};

export const AnalyticsEditDeicingVegReport = () => {
  const { id } = useParams<{ id: string }>();
  const {
    isDictionariesLoading,
    dictionaries
  } = useDictionary({ url: '/deicing/v10/deicing/vegetative/dictionaries' });
  const { backWithFallback } = useGoBackWithFallback();
  const location = useLocation();
  const [media, setMedia] = useState<IUploadMedia[]>([]);
  const objectType = useObjectType(dictionaries);
  const [editedObject, setEditObject] = useState<VegetationDeicingItem>();
  const { onErrorEdit, onSuccessEdit } = useEnhancedNotification();

  const onItemLoad = useCallback((response: VegetationDeicingItem | null) => {
    if (response) {
      setEditObject(response);
    }
  }, []);

  const {
    get,
    isLoading: isItemLoading
  } = useRequest<VegetationDeicingItem>(`/deicing/v10/deicing/vegetative/${id}?laboratory=true`, onItemLoad);

  useEffect(() => {
    if (location.state) {
      setEditObject(location.state);
    } else {
      void get();
    }
  }, [get, location.state]);

  const onSuccess = () => {
    updateFiles(id, objectType, media)
      .then(() => {
        onSuccessEdit();
        backWithFallback('/analytics');
      })
      .catch((error: Error) => {
        onErrorEdit(error.message);
      });
  };

  const { put } = useRequest<VegetationDeicingItem, any>('/deicing/v10/deicing/vegetative/protocol', onSuccess, onErrorEdit);

  const initialValues = {
    ...initialEmptyValues,
    ...editedObject,
    values: editedObject?.values.map((item) => ({
      ...item,
      name: item.parameterName || ''
    })) || [] as ParameterRecord[]
  };

  const onFormSubmit = async (values: VegetationDeicingItem) => {
    if (!editedObject) return;

    const vals = [...editedObject.values
      .map((listVal: Value, idx: number) => {
        // @ts-ignore
        const { parameterName, ...val } = listVal;
        if (values.values[idx].id === listVal.id && listVal.value !== values.values[idx].value) {
          return {
            ...val,
            id: listVal.id,
            value: values.values[idx].value,
            type: values.values[idx].type,
          };
        }
        return val;
      })];

    const valuesToEdit = {
      ...values,
      values: vals
    };

    // TODO: backend send unnecessary field
    if (valuesToEdit.versionValues) {
      delete valuesToEdit.versionValues;
    }

    await put(valuesToEdit);
  };

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    // @ts-ignore
    initialValues,
    onSubmit: onFormSubmit,
    validationSchema: AnalyticsVegStateSchema,
  });

  return (
    <FormikProvider
      value={{
        ...formik,
        errors: createTouchedErrors(formik.errors, formik.touched),
      }}
    >
      <FieldsForm
        dictionaries={dictionaries}
        onCancel={() => {
          backWithFallback('/analytics');
        }}
        isLoading={isDictionariesLoading || isItemLoading}
        uploadItemId={id}
        onMediaChange={setMedia}
        media={media}
      />
    </FormikProvider>
  );
};
